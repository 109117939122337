import {
    Component,
    SimpleChanges,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    ViewChild,
    OnChanges,
    ChangeDetectorRef,
    OnInit,
    AfterViewInit,
    OnDestroy,
    SimpleChange,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BasinStormResult, SliicerCaseStudy } from 'app/shared/models/sliicer';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { DesignStormItem } from 'app/shared/models/sliicer/design-storm';
import * as Utils from './q-i-graph.utils';
import { finalize } from 'rxjs/operators';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import * as Highcharts from 'highcharts';
import { BasinQvi, BasinQviGroup, Mode, QVI_CHART_INFO } from '../basin-qvi-stats/basin-qvi-model';
import { DesignStormData, RegressionLineData } from './q-i-graph.utils';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { StringUtils } from 'app/shared/utils/string-utils';
import { Observable, Subscription } from 'rxjs';
import { QvsIConfigurations } from 'app/shared/models/sliicer/results/storm-events';
import { QVI_GROUP_ALL_STORMS, QVI_GROUP_ALL_STORMS_CONFIG } from 'app/shared/models/sliicer/basins';
import { UNITS } from 'app/shared/models/units';
import _ from 'lodash';

const stormColor = '#0000FF';
const stormFillColor = '#FFFC00';

const MODE_GROSS = 'Gross';
const MODE_NET = 'Net';

enum HIGHCHARTS_SYMBOLS {
    'circle' = '●',
    'diamond' = '♦',
    'square' = '■',
    'triangle' = '▲',
    'triangle-down' = '▼',
}

interface QviPoint {
    configurationGroups: string[],
    x: number,
    y: number,
    totalRain: number,
    date: string,
    labelrank: number,
    dataLabels: {
        allowOverlap: boolean,
        verticalAlign: string,
        align: string,
        enabled: boolean,
    }
}

interface QviData {
    key: string;
    groups: string;
    data: QviPoint[];
}

@Component({
    selector: 'ads-q-i-graph',
    templateUrl: './q-i-graph.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QIGraphComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit {
    @Input() public customerId: number;
    @Input() public caseStudyId: string;
    @Input() public selectedBasin: string;
    @Input() public selectedStormId: number;
    @Input() public xFieldAccessor: string;
    @Input() public yFieldAccessor: string;
    @Input() public comparisonType: string;
    @Input() public isGross: boolean;
    @Input() public showRegressionLine = true;
    @Input() public showDates = true;
    @Input() public showTooltips = true;
    @Input() public showStormHighlight = true;
    @Input() public xAxisLabel: string;
    @Input() public yAxisLabel: string;
    @Input() public volumePerTime: boolean;
    @Input() public measurePerHour: boolean;
    @Input() public designStorms: DesignStormItem[];
    @Input() public isLoading: boolean;
    @Input() public overrideUseAltReg: boolean;
    @Input() public qviChartIndex: number;
    @Input() public excludedStormIds: { [key: string]: number[] } = {};
    @Input() public excludeStormEvent: Observable<void>;
    @ViewChild('chart') public chartElement: Highcharts.ChartObject;

    public haveData = false;
    public loadingStormBasinResults = false;
    public caseStudy: SliicerCaseStudy;
    public chartData: Highcharts.Chart = null;

    public basinStats: Map<string, BasinStormResult[]> = new Map();
    private allQviResults: BasinQvi[];
    public qviResults: BasinQvi[];

    private subscriptions: Array<Subscription> = [];
    private qvsiConfig: QvsIConfigurations;

    constructor(
        private dateUtilService: DateutilService,
        private translateService: TranslateService,
        private sliicerService: SliicerService,
        private utilService: UiUtilsService,
        private cdr: ChangeDetectorRef,
        private statusCodeService: StatusCodeService
    ) { }

    public ngOnInit() {
        this.applyTranslations();
        const themeSub = this.statusCodeService.userInfoThemeBS.subscribe(theme => {
            if (theme) {
                StringUtils.setHighChartTheme(this.chartData);
            } else {
                StringUtils.setHighchartWhiteTheme(this.chartData);
            }
        });

        this.subscriptions.push(this.excludeStormEvent.subscribe(() => {
            this.plotQVersusIGraph(this.qviResults);
            this.utilService.safeChangeDetection(this.cdr);
        }));

        this.subscriptions.push(
            this.sliicerService.studyDetailsData$.subscribe((caseStudyDetails: SliicerCaseStudy) => {
                this.caseStudy = caseStudyDetails;
            })
        );

        this.subscriptions.push(themeSub);

        this.subscriptions.push(this.sliicerService.qvsiSelectedConfig.subscribe(x => {
            if (!x || x.afterupdate) return;
            this.qvsiConfig = x.conf;
            if (!this.qvsiConfig.name || this.qvsiConfig.name === QVI_GROUP_ALL_STORMS) {
                this.qvsiConfig = null;
            }

            const isAllStromsGroupSelected = (!this.qvsiConfig || this.qvsiConfig.name === QVI_GROUP_ALL_STORMS);
            if (!isAllStromsGroupSelected) {
                this.overrideUseAltReg = false;
            }

            this.plotQVersusIGraph(this.qviResults);
            this.utilService.safeChangeDetection(this.cdr);
        }));
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions = [];
    }

    public ngAfterViewInit() {
        // This can happen because we don't always have the DOM when the API results return
        // and Highcharts needs it.
        this.fetchBasinQvIDataAndPlot();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedBasin || (changes.isLoading && !changes.isLoading.currentValue)) {
            this.fetchData();
            this.utilService.safeChangeDetection(this.cdr);
        } else if (
            (changes.selectedStormId && this.checkChange(changes.selectedStormId)) ||
            (changes.isGross && this.checkChange(changes.isGross)) ||
            (changes.xFieldAccessor && this.checkChange(changes.xFieldAccessor)) ||
            (changes.yFieldAccessor && this.checkChange(changes.yFieldAccessor)) ||
            (changes.showRegressionLine && this.checkChange(changes.showRegressionLine)) ||
            (changes.showDates && this.checkChange(changes.showDates)) ||
            (changes.showTooltips && this.checkChange(changes.showTooltips)) ||
            (changes.showStormHighlight && this.checkChange(changes.showStormHighlight)) ||
            (changes.volumePerTime && this.checkChange(changes.volumePerTime)) ||
            (changes.measurePerHour && this.checkChange(changes.measurePerHour)) ||
            (changes.designStorms && this.checkChange(changes.designStorms)) ||
            (changes.excludedStormIds && this.checkChange(changes.excludedStormIds)) ||
            (changes.overrideUseAltReg && this.checkChange(changes.overrideUseAltReg)) ||
            (changes.useAltReg && this.checkChange(changes.useAltReg)) ||
            (changes.qviChartIndex && this.checkChange(changes.qviChartIndex))
        ) {
            this.qviResults = this.allQviResults ? this.allQviResults.filter(v => !!v.result) : null;
            this.plotQVersusIGraph(this.qviResults);
        }

        if (this.chartData && changes.xAxisLabel) {
            const currentMeasurePerHour = changes.measurePerHour
                ? changes.measurePerHour.currentValue
                : this.measurePerHour;
            const measure = currentMeasurePerHour
                ? this.dateUtilService.unitOfMeasurePerHour.getValue()
                : this.dateUtilService.unitOfMeasure.getValue();
            const text = `${changes.xAxisLabel.currentValue} (${measure})`;
            this.chartData.xAxis[0].axisTitle.attr({ text: text });
        }

        if (this.chartData && changes.yAxisLabel) {
            const currentVolumePerTime = changes.volumePerTime
                ? changes.volumePerTime.currentValue
                : this.volumePerTime;
            const volume = currentVolumePerTime
                ? this.dateUtilService.peakVolumeUnit.getValue()
                : this.dateUtilService.volumneUnit.getValue();
            const text = `${changes.yAxisLabel.currentValue} (${volume})`;
            this.chartData.yAxis[0].axisTitle.attr({ text: text });
        }
    }

    private checkChange(change: SimpleChange) {
        if (!change) {
            return false;
        }

        return !_.isEqual(change.currentValue, change.previousValue);
    }

    public redraw() {
        if (!this.chartData) {
            return;
        }
        setTimeout(() => {
            this.chartData.reflow();
        }, 0);
    }

    private applyTranslations(): void {
        // FIXME
    }

    private flowFractionDigits(isMetric: boolean): number {
        let flowFractionDigits = (isMetric && this.volumePerTime) ? 1 : 3;

        const customerUnit = this.dateUtilService.customerUnit.getValue() || '';

        if(customerUnit === UNITS.CFS  && !this.volumePerTime)
        {
            flowFractionDigits = 0;
        }

        return flowFractionDigits;
    }

    public fetchData() {
        if (!this.selectedBasin) {
            return;
        }
        this.loadingStormBasinResults = true;
        this.sliicerService
            .getAllStormBasinResults(this.customerId, this.caseStudyId, this.selectedBasin)
            .pipe(
                finalize(() => {
                    this.loadingStormBasinResults = false;
                    this.utilService.safeChangeDetection(this.cdr);
                }),
            )
            .subscribe((results: BasinStormResult[]) => {
                if (results && results.length > 0) {
                    // filter out duplicates
                    const storms: BasinStormResult[] = [];

                    results.forEach((s) => {
                        if (s.stormId && !storms[s.stormId]) storms[s.stormId] = s;
                    });

                    results = storms;
                    this.basinStats = this.groupBasinStats(results);
                } else {
                    this.basinStats = new Map();
                }
                this.loadingStormBasinResults = false;
                this.fetchBasinQvIDataAndPlot();
            });
    }

    public groupBasinStats(stats: BasinStormResult[]) {
        const hasSeasons = this.sliicerService.hasSeasons();
        const hasYears = this.sliicerService.hasYears();
        const hasRegimes = this.sliicerService.hasRegimes();

        if (hasSeasons && hasYears && hasRegimes) {
            return this.groupByKeys(stats, ['year', 'regime', 'season']);
        }

        if (hasSeasons && hasYears) {
            return this.groupByKeys(stats, ['year', 'season']);
        }

        if (hasRegimes && hasYears) {
            return this.groupByKeys(stats, ['year', 'regime']);
        }

        if (hasSeasons && hasRegimes) {
            return this.groupByKeys(stats, ['regime', 'season']);
        }

        if (hasSeasons) {
            return this.groupByKeys(stats, ['season']);
        }

        if (hasYears) {
            return this.groupByKeys(stats, ['year']);
        }

        if (hasRegimes) {
            return this.groupByKeys(stats, ['regime']);
        }

        const defaultMap = new Map();
        defaultMap.set('', stats);

        return defaultMap;
    }

    private groupByKeys(stats: BasinStormResult[], keys: string[]) {
        const groupedStats = new Map<string, BasinStormResult[]>();

        stats.forEach((stat: BasinStormResult) => {
            const key = keys
                .filter((v) => stat[v])
                .reduce((acc, key) => {
                    if (acc) {
                        return (acc += ',' + stat[key]);
                    }

                    return (acc += stat[key]);
                }, '');

            const item = groupedStats.get(key);
            if (!item) {
                groupedStats.set(key, [stat]);
            } else {
                item.push(stat);
            }
        });

        return groupedStats;
    }

    private getOptions(chartSeriesData: any[], xAxis: any[], yAxis: any[], toolTip: {}): Highcharts.Chart {
        const self = this;
        return new Highcharts.Chart(<any>{
            chart: {
                type: 'scatter',
                renderTo: 'qvi-chart',
                ignoreHiddenSeries: false,
                zoomType: '',
                marginBottom: 60,
                marginTop: 30,
            },
            title: { text: '' },
            exporting: { enabled: false },
            rangeSelector: { inputEnabled: false },
            tooltip: toolTip,
            credits: { enabled: false },
            navigator: { enabled: false },
            scrollbar: { enabled: false },
            series: chartSeriesData,
            xAxis: xAxis,
            yAxis: yAxis,
            legend: {
                enabled: true,
                align: 'center',
                verticalAlign: 'top',
                layout: 'horizontal',
                symbolPadding: 0,
                symbolWidth: 0,
                symbolHeight: 0,
                squareSymbol: false,
                useHTML: true,
                labelFormatter: function () {
                    if (this.chart.series) {
                        const qviGroup = this.name;

                        let symbol = HIGHCHARTS_SYMBOLS.circle;
                        for (const s of this.chart.series) {
                            if (
                                s.userOptions
                                && s.userOptions.qviGroup
                                && Object.values(s.userOptions.qviGroup).some((g) => {
                                    return g === qviGroup
                                })
                            ) {
                                symbol = HIGHCHARTS_SYMBOLS[s.symbol];
                            }
                        }
                        return `<span style='font-size: 20px; padding-right: 4px; color: ${this.color}'>${symbol}</span><span>zzz${this.name}</span>`;
                    } else {
                        return ``;
                    }

                },
            },
            plotOptions: {
                line: {
                    lineWidth: 1,
                },
                series: {
                    dataGrouping: {
                        enabled: false,
                    },
                    pointWidth: 3,
                    label: {
                        enabled: false,
                    },
                    dataLabels: {
                        enabled: true,
                        color: stormColor,
                        formatter: function () {
                            return this.point.date ? `<span style="fill: ${this.point.color}">${this.point.date}</span>` : this.point.key;
                        },
                    },
                    events: {
                        legendItemClick: function(event) {
                            const name = event.target.name;
                            const serie = event.target.chart.series.find(s => s.options.parentName === name);
                            serie.setVisible(!serie.visible);
                        }
                    },
                },
            },
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 3000,
                        },
                    },
                ],
            },
        });
    }

    public fetchBasinQvIDataAndPlot() {
        this.sliicerService
            .getQviBasinResults(this.customerId, this.caseStudyId, this.selectedBasin)
            .subscribe((qviResults: BasinQvi[]) => {
                this.allQviResults = qviResults;
                this.qviResults = qviResults.filter(v => !!v.result);

                this.plotQVersusIGraph(this.qviResults);
            });
    }

    private computeHaveData(data: QviData[]): boolean {
        return data && data.length > 0 && data.some(d => d.data && d.data.length > 0);
    }

    public plotQVersusIGraph(qviResults: BasinQvi[]): Highcharts.Chart {
        if (!this.chartElement || !this.chartElement.nativeElement) {
            return;
        }

        const that = this;
        const data = this.pullXvsYData();
        this.haveData = this.computeHaveData(data);

        const chartSeriesData = [];

        const designStormData = this.getDesignStormData(qviResults, this.designStorms, this.xFieldAccessor);
        const regressions = this.generateRegressionLines(qviResults, data, designStormData);

        regressions.forEach((v) => {
            chartSeriesData.push({
                type: 'line',
                color: v.color ? v.color : v.isAlternate ? Utils.COLOR_ALTERNATE_REGRESSION_LINE : Utils.COLOR_REGRESSION_LINE,
                marker: {
                    enabled: false,
                },
                enableMouseTracking: false,
                name: v.name,
                data: v.data,
                visible: this.showRegressionLine,
                showInLegend: true,
            });
        });
        data.forEach((v) => {
            let symbolIndex = null;
            for (let i = 0; i < regressions.length; i++) {
                const r = regressions[i];
                if (v.groups && Object.values(v.groups).includes(r.name)) {
                    symbolIndex = (i + 1) % Object.keys(HIGHCHARTS_SYMBOLS).length;
                    break;
                }
            }
            const symbol = symbolIndex !== null ? Object.keys(HIGHCHARTS_SYMBOLS)[symbolIndex] : Object.keys(HIGHCHARTS_SYMBOLS)[0];
            chartSeriesData.push({
                name: v.key,
                data: v.data,
                color: !this.qvsiConfig ? stormColor : this.getConfigColor(v.data[0]),
                showInLegend: false,
                qviGroup: v.groups,
                marker: {
                    symbol: symbol
                }
            });
        });
        designStormData.forEach((d) => {
            chartSeriesData.push({
                name: d.name,
                data: d.data,
                showInLegend: false,
            });
        });

        const isAllStorms = !this.qvsiConfig
        for(const v of this.qviResults) {
            if(!this.filterQvi(v)) continue;

            for(const projection of v.projections) {
                const useAltRegression = (isAllStorms && v.result.useAlt) ? this.overrideUseAltReg : false;

                const y = useAltRegression ? projection.altProjection : projection.projection;
                const yIntercept = useAltRegression ? 0 : v.result.coeff1;
                const slope = useAltRegression ? v.result.altSlope : v.result.coeff2;

                // y = mx + b ; where x is Rain, b is y-intercept, and m is slope.
                // x = (y - b) / m
                const xIntersect = slope === 0 ? 0 : (y - yIntercept) / slope;

                const configName = (this.qvsiConfig ? v.configurationGroup : BasinQviGroup.AllStorms)
                chartSeriesData.push({
                    type: 'line',
                    color: this.qvsiConfig ? this.qvsiConfig.groups.find(x => x.name === v.configurationGroup).color
                         : useAltRegression ? Utils.COLOR_ALTERNATE_REGRESSION_LINE : Utils.COLOR_REGRESSION_LINE,
                    name: projection.designStormName,
                    parentName: configName,
                    data: [
                        {
                            x: 0,
                            y: y
                        },
                        {
                            x: xIntersect,
                            y: y
                        },
                        {
                            x: xIntersect,
                            y: 0
                        }
                    ],
                    marker: {
                        enabled: false,
                    },
                    enableMouseTracking: true,
                    showInLegend: false,
                })
            }
        }

        const isMetric = this.dateUtilService.isCustomerUnitsMetric.getValue();
        const rainFractionDigits = isMetric ? 1 : 2;
        const flowFractionDigits = this.flowFractionDigits(isMetric);

        const rainUnit = this.measurePerHour ? this.dateUtilService.unitOfMeasurePerHour.getValue() : this.dateUtilService.unitOfMeasure.getValue();
        const volumeUnit = this.volumePerTime ? this.dateUtilService.peakVolumeUnit.getValue() : this.dateUtilService.volumneUnit.getValue();
        const chartXAxis = [
            {
                title: {
                    text: `${this.xAxisLabel} (${rainUnit})`,
                },
                startOnTick: true,
                endOnTick: true,
                showFirstLabel: true,
                showLastLabel: true,
                min: 0,
                alignTicks: true,
                labels: {
                    formatter: function () {
                        return Number.parseFloat(this.value).toFixed(rainFractionDigits);
                    },
                },
            },
        ];

        const chartYAxis = [
            {
                title: {
                    text: `${this.yAxisLabel} (${volumeUnit})`,
                },
                opposite: false,
                alignTicks: true,
                startOnTick: true,
                endOnTick: true,
                showFirstLabel: true,
                showLastLabel: true,
                labels: {
                    formatter: function () {
                        return Number.parseFloat(this.value).toFixed(flowFractionDigits);
                    },
                },
            },
        ];

        const chartToolTipOption = {
            // headerFormat: '<b>{series.name}</b><br>',
            // pointFormat: '{point.x}, {point.y}',
            enabled: this.showTooltips,
            shared: true,
            useHTML: true,
            positioner:  function (labelWidth: number, labelHeight: number, point: { plotX: number; plotY: number }) {
                const horizontalOffset = 70;
                const verticalOffset = 5;
                return {
                    x: horizontalOffset,
                    y: verticalOffset,
                };
            },
            style: { 'z-index': 9999 },
            formatter: function () {
                return Utils.qvsiTooltipFormatter.call(this, rainFractionDigits, flowFractionDigits, rainUnit, volumeUnit, that)
            },
            shape: 'rect', // make sure the line (present for the default type 'callout') is not shown
            split: false, // for some reason without this option (although it's false by default) the positioner doesn't work
        };
        this.chartData = this.getOptions(chartSeriesData, chartXAxis, chartYAxis, chartToolTipOption);

        setTimeout(() => {
            this.chartData.setSize(null, null, false);
        }, 0);
    }

    private getConfigColor(point) {
        let color = stormColor
        if (point) {
            const group = this.qvsiConfig.groups.find(x => x.name === point.configurationGroups[this.qvsiConfig.name]);
            if (group) {
                color = group.color;
            }
        }
        return color;
    }

    private getDesignStormData(
        qviResults: BasinQvi[],
        designStorms: DesignStormItem[],
        xFieldAccessor: string,
    ): DesignStormData[] {
        return !qviResults ? [] : qviResults.filter((q) => this.isGross ? q.mode === MODE_GROSS : q.mode === MODE_NET).map((q) => {
            let regression: {
                slope: number;
                intercept: number
            };

            const isAllStorms =  q.group === BasinQviGroup.AllStorms;
            if (q.result.coeff1 < 0) {
                regression = { slope: q.result.coeff2, intercept: q.result.coeff1 };
            } else {
                regression = (isAllStorms && this.overrideUseAltReg) || (!isAllStorms && q.result.useAlt)
                    ? { slope: q.result.altSlope, intercept: 0 }
                    : { slope: q.result.coeff2, intercept: q.result.coeff1 };
            }

            return {
                name: this.getDesignStormSeriesName(q),
                data: Utils.getDesignStormsData(designStorms, regression, xFieldAccessor),
            };
        });
    }

    private getDesignStormSeriesName(key: BasinQvi): string {
        return this.qviKeyName(key) + ' Design Storm';
    }

    private qviKeyName(key: BasinQvi): string {
        let ret = key.group as string;
        if (key.configuration) {
            ret += ': ' + key.configuration;
        }
        return ret;
    }

    private filterQvi(q: BasinQvi) {
        const qviType = QVI_CHART_INFO[this.qviChartIndex].comparisonType;

        return (this.isGross ? q.mode === MODE_GROSS : q.mode === MODE_NET)
            && q.type === qviType
            && q.configuration === (this.qvsiConfig ? this.qvsiConfig.name : '')
            && q.group === (this.qvsiConfig ? 'UserDefined' : BasinQviGroup.AllStorms)
            && (this.qvsiConfig ? this.qvsiConfig.groups.map(x => x.name).indexOf(q.configurationGroup) !== -1 : q.configurationGroup === undefined)
    }

    private generateRegressionLines(
        qviResults: BasinQvi[],
        dataArray: any,
        designStormData: DesignStormData[],
    ): RegressionLineData[] {
        const minX = 0;
        let maxX = 0;
        dataArray.forEach((dataObject) => {
            const data = dataObject.data;
            if (data.length && data[data.length - 1].x > maxX) {
                maxX = data[data.length - 1].x;
            }
        });
        designStormData.forEach((dataObject) => {
            dataObject.data.forEach((d) => {
                if (d.x > maxX) {
                    maxX = d.x;
                }
            });
        });

        const qviType = QVI_CHART_INFO[this.qviChartIndex].comparisonType;
        const qviFilteredResults = !qviResults ? [] :
            qviResults.filter(
                (q) => this.filterQvi(q)
            );

        const isAllStorms = !this.qvsiConfig;

        return qviFilteredResults.map((v) => {
            const { result } = v;
            const useAltRegression = (isAllStorms && v.result.useAlt) ? this.overrideUseAltReg : false;

            const slope  = useAltRegression ? result.altSlope : result.coeff2;
            const yIntercept = useAltRegression ? 0 : result.coeff1;

            const minY = slope * minX + yIntercept;
            const maxY = slope * maxX + yIntercept;

            return {
                name: (this.qvsiConfig ? v.configurationGroup : BasinQviGroup.AllStorms),
                isAlternate: useAltRegression,
                color: this.qvsiConfig ? this.qvsiConfig.groups.find(x => x.name === v.configurationGroup).color : undefined,
                data: [
                    { x: minX, y: minY },
                    { x: maxX, y: maxY },
                ],
            };
        });
    }

    private findValidStormDate(basinStats: BasinStormResult): string {
        const properties = ['stormStartTime', 'rainPeakTime'];
        for (let i = 0; i < properties.length; i++) {
            const value = basinStats[properties[i]];
            if (value !== null && value !== '' && !value.startsWith('0001')) {
                return value;
            }
        }
        return null;
    }

    private checkIfStormDayIsBlocked(stormDate: string) {
        if (!stormDate) return false;

        const blockedDays = this.caseStudy?.overrides?.rainfallMonitorBlockDays;

        if (!blockedDays || !blockedDays.length) return false;

        const stormDateToCheck: number = new Date(stormDate).setHours(0, 0, 0);

        return blockedDays.some((bd) => bd.days.some(v => new Date(v).getTime() === stormDateToCheck));
    }

    private pullXvsYData(): QviData[] {
        const dateFormat = this.dateUtilService.dateFormat.getValue();
        const graphData = [];
        this.basinStats.forEach((points: BasinStormResult[], key: string) => {
            if (!points) return;

            // filtering the duplicates
            const stormIds = new Set(points.map(v => v.stormId));
            points = points.filter(v => {
                if (stormIds.has(v.stormId)) {
                    stormIds.delete(v.stormId);

                    return true;
                }

                return false;
            });

            // #32375 Season or Regime name cannot be same as Configured Group name, otherwise data is mixed up
            const name = '___' + key;
            let formattedPoints = [];
            const qviGroups = {};
            points.forEach((item) => {
                // WPS: HACK
                // Sometimes stormStartTime is not specified?!
                let stormDate = this.findValidStormDate(item);

                const isManuallyAdded = this.caseStudy?.overrides?.addedStormEvents?.find((x) => x.stormStartTime === item.stormStartTime);
                if (stormDate) {
                    stormDate = moment(+moment(stormDate).startOf('day').valueOf()).format(dateFormat);
                } else {
                    stormDate = item.stormId.toString();
                }

                const isExcluded = !isManuallyAdded && this.excludedStormIds[this.selectedBasin] && this.excludedStormIds[this.selectedBasin].find((id) => id === item.stormId);
                if (!isExcluded) {
                    if (!this.qvsiConfig || (item.configurationGroups && item.configurationGroups[this.qvsiConfig.name])) {
                        // #33338 Q vs i Graphs - remove storms that are below the y=0 axis from all 8 Q vs i graphs
                        // #33592 remove excluded storms
                        const yVal = !isNaN(item[this.yFieldAccessor]) ? item[this.yFieldAccessor]: 0;
                        if(yVal >= 0) {
                            const xVal = !isNaN(item[this.xFieldAccessor]) ? item[this.xFieldAccessor]: 0;
                            formattedPoints.push({
                                // The Gross values are always present according to the
                                // QvIChartInfo model, so default to that
                                x: xVal,
                                y: yVal,
                                totalRain: item.totalRain,
                                date: stormDate,
                                id: item.stormId,
                                configurationGroups: item.configurationGroups
                            });
                        }
                    }
                    if (item.configurationGroups) {
                        for (const k of Object.keys(item.configurationGroups)) {
                            if (!qviGroups[k]) {
                                qviGroups[k] = item.configurationGroups[k];
                            }
                        }
                    }
                }
            });

            if (formattedPoints.length > 0) {
                formattedPoints.sort((a, b) => (a.x > b.x ? 1 : -1));
                formattedPoints = this.styleChartPoints(formattedPoints);

                graphData.push({ key: name, groups: qviGroups, data: formattedPoints });
            }
        });
        return graphData;
    }

    private styleChartPoints(points): QviPoint[] {
        if (points.length === 0) {
            return points;
        }
        const styledPoints = [];
        let index = 0;
        points.forEach((p) => {
            const vertical = index++ % 2 === 0 ? 'top' : 'bottom';

            // Only show dates if we want to display them
            const currentPoint = {
                configurationGroups: p.configurationGroups,
                x: p.x,
                y: p.y,
                totalRain: p.totalRain,
                date: p.date,
                labelrank: 1,
                dataLabels: {
                    allowOverlap: true,
                    verticalAlign: vertical,
                    align: 'left',
                    enabled: this.showDates,
                },
            };

            // If this is the selected storm add a marker
            if (p.id === this.selectedStormId && this.showStormHighlight) {
                // In case of overlap, favors the selected storm
                currentPoint.labelrank = 2;

                // A thick line around the selected storm point, it counts
                // as a new path
                currentPoint['marker'] = {
                    radius: 8,
                    lineColor: stormFillColor,
                    lineWidth: 5,
                };
            } else {
                currentPoint['marker'] = {};
            }

            styledPoints.push(currentPoint);
        });

        return styledPoints;
    }
}
