<div id="editStormEventsDialog">
    <div mat-dialog-title class="popupHeader headerContent" [ngClass]="{ 'withIcon left': action != 'BASE' }">
        {{ 'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.TITLES.' + action | translate }}
        <mat-icon *ngIf="action != 'BASE'" [matTooltip]="'COMMON.BACK_TEXT' | translate" (click)="setAction('BASE')">
            arrow_back
        </mat-icon>
    </div>
    <div class="dialogContent">
        <app-loader [isLoading]="showLoader"></app-loader>
        <div mat-dialog-content class="dialogBody">
            <ng-container *ngIf="action == 'BASE'">
                <mat-table #table [dataSource]="dataSource">
                    <ng-container matColumnDef="selectItem">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <!-- US #30401 TASK #30466 SLiiCER - Deleting and adding a storm features - DELETE this functionality from the UI -->
                            <!-- <mat-checkbox
                                [disabled]="
                                    element['label'] == 'removed' || (element['label'] == 'added' && !element['saved'])
                                "
                                [checked]="element['stormStartTime'] == selectedStormEvent['stormStartTime']"
                                (change)="selectStormEvent(element)"
                            ></mat-checkbox> -->
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="startDateTime">
                        <mat-header-cell *matHeaderCellDef
                            >{{ 'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.TABLE_HEADERS.START_DATE_TIME' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div>
                                <div>{{ element['stormStartTime'] | date: customerDateTimeFormat }}</div>
                                <div class="smallerText" *ngIf="element['label']">
                                    <span>{{
                                        'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.STORM_EVENT_LABELS.' +
                                            element['label'].toUpperCase() | translate
                                    }}</span>
                                    <span class="unsaved" *ngIf="!element['saved']">{{
                                        ' (' +
                                            ('SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.STORM_EVENT_LABELS.UNSAVED'
                                                | translate) +
                                            ')'
                                    }}</span>
                                </div>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="totalStormDuration">
                        <mat-header-cell *matHeaderCellDef
                            >{{ 'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.TABLE_HEADERS.TOTAL_STORM_DURATION' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="row-center">
                            {{ element['totalStormDuration'] | number: '1.0-1' }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="season">
                        <mat-header-cell *matHeaderCellDef
                            >{{ 'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.TABLE_HEADERS.SEASON' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.seasonView }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="regime">
                        <mat-header-cell *matHeaderCellDef
                            >{{ 'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.TABLE_HEADERS.REGIME' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.regimeView }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <button *ngIf="element.stormId" mat-icon-button (click)="editStorm(element)">
                                <mat-icon>edit</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="stormEventsDisplayedColumns"></mat-header-row>
                    <mat-row
                        *matRowDef="let row; columns: stormEventsDisplayedColumns"
                        [ngClass]="row['label'] ? row['label'] + 'StormEvent' : ''"
                    >
                    </mat-row>
                </mat-table>
            </ng-container>
            <ng-container *ngIf="action == 'NEW'">
                <div class="stormEventFormContainer">
                    <div class="stormEventFormElement">
                        <mat-form-field>
                            <input
                                matInput
                                [(ngModel)]="newStormDate"
                                [matDatepicker]="newStormDatePicker"
                                [min]="caseStudyStartDate"
                                [max]="caseStudyEndDate"
                                [placeholder]="'SLIICER.COMMON.STORM_EVENT.START_DATE_LABEL' | translate"
                                required
                            />
                            <mat-datepicker-toggle matSuffix [for]="newStormDatePicker"> </mat-datepicker-toggle>
                            <mat-datepicker #newStormDatePicker></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="stormEventFormElement">
                        <app-timepicker
                            [timeFormat]="timeFormat"
                            (notifyTimeChange)="setStormEventTime($event)"
                            allowKeyboardInput="true"
                        >
                        </app-timepicker>
                    </div>
                </div>
            </ng-container>
        </div>
        <div mat-dialog-actions>
            <button *ngIf="action == 'BASE'" mat-button (click)="closeDialog()">
                {{ 'SLIICER.COMMON.CANCEL_BUTTON_TITLE' | translate }}
            </button>
            <button *ngIf="action == 'NEW'" mat-button (click)="setAction('BASE')">
                {{ 'SLIICER.COMMON.CANCEL_BUTTON_TITLE' | translate }}
            </button>
            <ng-container *ngIf="action == 'NEW'">
                <button mat-button [disabled]="!newStormDate || !newStormTime" (click)="saveStormEvent()">
                    {{ 'SLIICER.COMMON.OK_BUTTON_TITLE' | translate }}
                </button>
            </ng-container>
            <ng-container *ngIf="action == 'BASE'">
                <button mat-button (click)="updateOverrides()">
                    {{ 'SLIICER.COMMON.OK_BUTTON_TITLE' | translate }}
                </button>
                <!-- US #30401 TASK #30466 SLiiCER - Deleting and adding a storm features - DELETE this functionality from the UI -->
                <!-- <button mat-button [disabled]="action == 'NEW'" (click)="setAction('NEW')">
                    {{ 'SLIICER.COMMON.NEW_BUTTON_TITLE' | translate }}
                </button> -->
                <!-- <button
                    mat-button
                    [disabled]="!selectedStormEvent['stormStartTime']"
                    (click)="deleteStormEvent(selectedStormEvent['stormStartTime'])"
                >
                    {{ 'SLIICER.COMMON.DELETE_BUTTON_TITLE' | translate }}
                </button> -->
            </ng-container>
        </div>
    </div>
</div>
