export class ModemSetup {
    public provider: string;
    public username: string;
    public password: string;
    public authenticationType: number;
}

export class ModemSetupItem extends ModemSetup {
    public predefined: boolean;
}

export enum AuthenticationType {
    None = 0,
    PAP = 1,
    CHAP = 2,
}
