<div class="customDialogLayer">
    <div class="customDialog">
        <div class="customDialogPosition">
            <div id="resonForEditId">
                <div class="popupHeaderWithButton" mat-dialog-title>
                    <div class="auto-margin pad-l-15">
                        {{ 'LOCATION_DASHBOARD.DATA_EDITING.EDIT_SESSION_COMMENTS' | translate }}
                    </div>
                    <div class="app-flex-filler"></div>
                </div>
                <div mat-dialog-content class="eachTabContent">
                    <p class="approve-label" *ngIf="approvalDateControl.value">
                        Data is approved on {{ approvalDateControl.value | date: data.customDateFormat }}
                    </p>
                    <p class="approve-label" *ngIf="!approvalDateControl.value">
                        Please select a date the data is approved through
                    </p>
                    <div>
                        <mat-form-field class="full-width">
                            <mat-label>Date</mat-label>
                            <input
                                [formControl]="approvalDateControl"
                                (dateChange)="approvalDateChange($event)"
                                [max]="today"
                                [matDatepicker]="approveDte"
                                class="datepickerStyles"
                                matInput
                            />
                            <mat-datepicker-toggle matSuffix [for]="approveDte"></mat-datepicker-toggle>
                            <mat-datepicker #approveDte></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div mat-dialog-actions>
                    <div class="app-flex-filler"></div>
                    <div class="auto-margin">
                        <button mat-dialog-close mat-button>{{ 'COMMON.CANCEL_BUTTON' | translate }}</button>
                    </div>
                    <div class="auto-margin">
                        <button (click)="submit()" mat-button>{{ 'COMMON.APPROVE_BTN' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
