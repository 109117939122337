import {
    Component,
    ViewEncapsulation,
    Input,
    ViewChild,
    SimpleChanges,
    Output,
    EventEmitter,
    OnChanges,
    OnInit,
} from '@angular/core';
import * as _ from 'underscore';
import { StormDefinition } from 'app/shared/models/sliicer/customer-rainfall-profile';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { ChangesAction } from 'app/pages/sliicer/shared/components/updates-widget/updates-widget.models';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';

const metricUnits = ['mm', 'in'];
const metricvalues = ['mm', 'inches'];

interface StormSettingsModel {
    stormValue?: string;
    minTime?: number;
}

@Component({
    selector: 'ads-storm-settings',
    templateUrl: './storm-settings.component.html',
    styleUrls: ['./storm-settings.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class StormSettingsComponent implements OnInit, OnChanges {
    @Input() public customerId: number;
    @Input() public isStudyLocked: boolean;
    @Input() public shouldUpdate: boolean;
    @Input() public changesAction: ChangesAction;
    @Input() public originalStormDefinition: StormDefinition;
    @Output() public checkChanges: EventEmitter<string> = new EventEmitter();
    @ViewChild('stormDefnitionForm') public stormDefnitionForm;

    // Public variables
    public minRainfallPrevOneDay: number;
    public minRainfallPrevThreeDays: number;
    public minRainfallPrevFiveDays: number;
    public unitValue: string;
    public unitText: string;
    public formValues: StormSettingsModel = {};
    public initialFormValues: StormSettingsModel = {};

    public isMetric: boolean;
    public numericWith2DecimalPlaces = REGEX_CONFIG.numericWith2DecimalPlaces;
    public numericWith1DecimalPlaces = REGEX_CONFIG.numericWith1DecimalPlaces;
    public isPositiveInteger = REGEX_CONFIG.isPositiveInteger;

    public error = false;

    constructor(private dateUtilService: DateutilService) {}

    public ngOnInit() {
        this.isMetric = this.dateUtilService.isCustomerUnitsMetric.getValue();
        this.unitValue = this.isMetric ? metricUnits[0] : metricUnits[1];
        this.unitText = this.isMetric ? metricvalues[0] : metricvalues[1];
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.shouldUpdate) {
            this.getStormDefinition(this.originalStormDefinition);
        } else if (changes.changesAction && this.getChangeValue()) {
            if (this.changesAction.action === 'undoChanges') {
                this.getStormDefinition(this.originalStormDefinition);
                this.stormDefnitionForm.form.markAsUntouched();
                this.stormDefnitionForm.form.markAsPristine();
                this.checkChanges.emit('');
            }
        }
    }

    public modelChange() {
        let isError = false;
        for(const c of Object.values(this.stormDefnitionForm.form.controls)) {
            isError = isError || (c as {errors: boolean}).errors;
        }

        this.error = isError;
        this.checkChanges.emit('');
    }

    public getStormDefinition(sd: StormDefinition) {
        if (sd) {
            this.isMetric = this.dateUtilService.isCustomerUnitsMetric.getValue();

            this.formValues.stormValue = sd.minRainfallThreshold ? (this.isMetric ? Number(sd.minRainfallThreshold).toFixed(1) : Number(sd.minRainfallThreshold).toFixed(2)) : '0';
            this.formValues.minTime = sd.minimumTimeBetweenEventsMinutes;
        }

        this.initialFormValues = { ...this.formValues };
    }

    public getChanges(): StormDefinition {
        const stormDefinition: StormDefinition = {
            minRainfallThreshold: this.formValues.stormValue?.toString(),
            minimumTimeBetweenEventsMinutes: this.formValues.minTime,
            docType: 'stormDefinition',
            id: this.originalStormDefinition.id,
            pk: this.customerId,
        };
        const stormDefinitionEqual = _.isEqual(this.originalStormDefinition, stormDefinition);

        if (!stormDefinitionEqual) {
            return this.saveStormDefinition();
        }
        return null;
    }

    public saveStormDefinition(): StormDefinition {
        if(this.formValues && this.formValues.stormValue)
        {
            this.formValues.stormValue = this.isMetric ? Number(this.formValues.stormValue).toFixed(1):Number(this.formValues.stormValue).toFixed(2);
        }
        const stormDefinition: StormDefinition = {
            minRainfallThreshold: this.formValues.stormValue?.toString(),
            minimumTimeBetweenEventsMinutes: this.formValues.minTime,
        };
        
        return stormDefinition;
    }

    public getChangeValue(): boolean {
        return !_.isEqual(this.formValues, this.initialFormValues) && !this.error;
    }
}
