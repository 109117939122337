import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BEconfig, ConfigTemplate, GetConfigTemplate, SaveConfigData } from 'app/shared/models/data-edit';
import { ConfigService } from 'app/shared/services/config.service';

@Component({
    selector: 'app-configuration-save-dialog',
    templateUrl: './configuration-save-dialog.component.html',
    styleUrls: ['./configuration-save-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ConfigurationSaveDialogComponent implements OnInit {
    public configName = '';
    public showDuplicateNameError = false;
    private allConfigs: GetConfigTemplate<unknown>[];

    constructor(private dialogRef: MatDialogRef<ConfigurationSaveDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: SaveConfigData, private configService: ConfigService) { }

    ngOnInit(): void {
        this.configService.getAllConfigs(this.data.cid, this.data.type, this.data.partitionSuffixFilter).subscribe((configs) => this.allConfigs = configs);
    }

    public onNameInput() {
        this.showDuplicateNameError = (this.allConfigs && !!this.allConfigs.find(v => v.name.trim() === this.configName.trim()));
    }

    public close() {
        this.dialogRef.close(null);
    }
    
    public save() {
        const configPayload: ConfigTemplate<BEconfig> = {
            configName: this.configName.trim(),
            configType: this.data.type,
            settings: this.data.settings,
            partitionSuffixFilter: this.data.partitionSuffixFilter
        };

        this.configService.saveConfig(configPayload, this.data.cid).subscribe(data => this.dialogRef.close(true));
    }
}
