import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-sliicer-marketing-screen',
    templateUrl: './sliicer-marketing-screen.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliicerMarketingScreenComponent {
    constructor(public dialogRef: MatDialogRef<SliicerMarketingScreenComponent>) {}

    public close(value: boolean) {
        this.dialogRef.close({ success: value });
    }
}
