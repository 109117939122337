import { Injectable } from '@angular/core';
import { Config } from 'app/shared/services/config';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RainfallFreqService {

  constructor(private http: HttpClient) { }

  public getRainFalls(params): Observable<unknown> {
    return this.http.get(`${Config.urls.rainfallFreq}?cid=${params.cid}&start=${params.start}&end=${params.end}`).pipe(
      map((result) => {
        return result;
      }),
    );
  }

  public getRainFallReport(params): Observable<unknown> {
    return this.http.post(`${Config.urls.rainfallFreqReport}?cid=${params.cid}`, params).pipe(
      map((result) => {
        return result;
      }),
    );
  }
}
