<div id="customerDetailLocationsEditorId">
    <mat-card-header>
        <div class="auto-margin search-section">
            <mat-form-field class="full-width edit-cus-locs-search-hint">
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <input
                    matInput
                    type="text"
                    placeholder="{{ 'COMMON.SEARCH_TEXT' | translate }}"
                    [formControl]="searchString"
                />
            </mat-form-field>
        </div>
        <div class="app-flex-filler"></div>
        <div class="auto-margin pad-r-15">
            <mat-icon
                matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                class="backIcon edit-cus-locs-filter-hint"
                [ngClass]="{ active: displayFilters }"
                (click)="showHideFilter()"
                >filter_list</mat-icon
            >
        </div>
    </mat-card-header>

    <mat-card-content>
        <app-loader [isLoading]="isLoading"></app-loader>

        <div class="filterSection" *ngIf="displayFilters">
            <!-- <div class="eachSection">
        <mat-form-field>
          <mat-select placeholder="{{'CUSTOMER_EDITOR.ASSIGN_LOCATIONS.LOCATION_NAME' | translate}}">
            <mat-option *ngFor="let each of locationNameList" [value]="each.value">
              {{ each.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->
            <div class="eachSection">
                <mat-form-field class="full-width edit-cus-filters-series-hint">
                    <mat-select
                        placeholder="{{ 'CUSTOMER_EDITOR.ASSIGN_LOCATIONS.MONITOR_SERIES' | translate }}"
                        [(ngModel)]="selectedMonitorSeries"
                        (selectionChange)="applyFilters()"
                    >
                        <mat-option *ngFor="let each of monitorSeriesList" [value]="each.value">
                            {{ each.text }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="eachSection">
                <mat-form-field class="full-width edit-cus-filters-group-hint">
                    <mat-select
                        placeholder="{{ 'CUSTOMER_EDITOR.ASSIGN_LOCATIONS.LOCATION_GROUP' | translate }}"
                        [(ngModel)]="selectedLocationGrp"
                        (selectionChange)="applyFilters()"
                    >
                        <mat-option *ngFor="let each of locationGroupList | orderBy: 'text'" [value]="each.value">
                            {{ each.text }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="eachSection">
                <mat-form-field class="full-width edit-cus-filters-status-hint">
                    <mat-select
                        placeholder="{{ 'CUSTOMER_EDITOR.ASSIGN_LOCATIONS.ACTIVE_INACTIVE' | translate }}"
                        [(ngModel)]="selectedActiveLocation"
                        (selectionChange)="applyFilters()"
                    >
                        <mat-option *ngFor="let each of activeInactiveList" [value]="each.value">
                            {{ each.text }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="eachSection">
                <mat-form-field class="full-width edit-cus-filters-sensors-hint">
                    <mat-select
                        multiple="true"
                        placeholder="{{ 'COMMON.SENSORS' | translate }}"
                        [(ngModel)]="selectedSensors"
                        (selectionChange)="applyFilters()"
                    >
                        <mat-option *ngFor="let each of sensorsList" [value]="each">
                            {{ each }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="eachSection">
                <mat-form-field class="full-width edit-cus-filters-wdo-hint">
                    <mat-select
                        placeholder="{{ 'CUSTOMER_EDITOR.ASSIGN_LOCATIONS.WDO' | translate }}"
                        [(ngModel)]="selectedwdo"
                        (selectionChange)="applyFilters()"
                    >
                        <mat-option *ngFor="let each of wdoList" [value]="each.value">
                            {{ each.text }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="eachSection">
                <mat-form-field class="full-width edit-cus-filters-composite-hint">
                    <mat-select
                        placeholder="{{ 'HOME.MAP.LEGEND.MAP_COMPOSITE_LOCATION' | translate }}"
                        [(ngModel)]="selectedComposite"
                        (selectionChange)="applyFilters()"
                    >
                        <mat-option *ngFor="let each of compositeList | orderBy: 'text'" [value]="each.value">
                            {{ each.text }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div id="customerDetailLocationsEditorPageTable">
            <mat-table #tableLocation [dataSource]="locationDataSourceNew" matSort>
                <ng-container matColumnDef="locationName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="edit-cus-locs-name-hint">{{
                        'CUSTOMER_EDITOR.ASSIGN_LOCATIONS.LOCATION_NAME' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngClass]="{ selected: element.highlight }"
                        >{{ element.locationName }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="monitorSeries">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="edit-cus-locs-series-hint">{{
                        'CUSTOMER_EDITOR.ASSIGN_LOCATIONS.MONITOR_SERIES' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngClass]="{ selected: element.highlight }"
                        >{{ element.monitorSeries }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="serialNumber">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="edit-cus-locs-serial-hint">{{
                        'CUSTOMER_EDITOR.ASSIGN_LOCATIONS.SERIAL_NUMBER' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngClass]="{ selected: element.highlight }"
                        >{{ element.serialNumber }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="edit-cus-locs-desc-hint">{{
                        'CUSTOMER_EDITOR.ASSIGN_LOCATIONS.DESCRIPTION' | translate
                    }}</mat-header-cell>
                    <mat-cell
                        class="description-space"
                        *matCellDef="let element"
                        [ngClass]="{ selected: element.highlight }"
                        >{{ element.description }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sensors">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="edit-cus-locs-sensors-hint">{{
                        'COMMON.SENSORS' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngClass]="{ selected: element.highlight }"
                        >{{ element.sensors }}
                    </mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="lastActDt">
          <mat-header-cell *matHeaderCellDef>{{'CUSTOMER_EDITOR.ASSIGN_LOCATIONS.LAST_ACTIVATE_DATE' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.description}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastFirmUpd">
          <mat-header-cell *matHeaderCellDef>{{'CUSTOMER_EDITOR.ASSIGN_LOCATIONS.LAST_FIRMWARE_UPDATE' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element">{{element.description}} </mat-cell>
        </ng-container> -->
                <ng-container matColumnDef="locationGroupName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="edit-cus-locs-group-hint">{{
                        'CUSTOMER_EDITOR.ASSIGN_LOCATIONS.LOCATION_GROUP' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngClass]="{ selected: element.highlight }"
                        >{{ element.locationGroupName }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef class="edit-cus-locs-status-hint">{{
                        'CUSTOMER_EDITOR.ASSIGN_LOCATIONS.ACTIVE_INACTIVE' | translate
                    }}</mat-header-cell>
                    <mat-cell
                        *matCellDef="let element"
                        class="custom-disabled-View"
                        [ngClass]="{ selected: element.highlight }"
                    >
                        {{ locationStatuses[element.status] }}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="activeMonitor">
                    <mat-header-cell *matHeaderCellDef class="edit-cus-locs-activate-hint">{{
                        'CUSTOMER_EDITOR.ASSIGN_LOCATIONS.ACTIVE_MONITOR' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngClass]="{ selected: element.highlight }">
                        <button
                            class="flatButton"
                            mat-button
                            [class.flatButtonDisabled]="
                                !(
                                    element.monitorSeries &&
                                    (element.monitorSeries.toLowerCase().indexOf('rainalert iii') >= 0 ||
                                        element.monitorSeries.toLowerCase().indexOf('echo') >= 0 ||
                                        element.monitorSeries.toLowerCase().indexOf('triton+') >= 0)
                                ) || element.locationTypeID === 3
                            "
                            [disabled]="
                                !(
                                    element.monitorSeries &&
                                    (element.monitorSeries.toLowerCase().indexOf('rainalert iii') >= 0 ||
                                        element.monitorSeries.toLowerCase().indexOf('echo') >= 0 ||
                                        element.monitorSeries.toLowerCase().indexOf('triton+') >= 0)
                                ) || element.locationTypeID === 3
                            "
                            (click)="activeMonitor(element)"
                        >
                            <img class="img-icon-24" src="/assets/images/activate.png" />
                        </button>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="locationTypeID">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="edit-cus-locs-composite-hint">{{
                        'HOME.MAP.LEGEND.MAP_COMPOSITE_LOCATION' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngClass]="{ selected: element.highlight }">
                        <span *ngIf="element.locationTypeID == 1">{{ 'COMMON.NO_TEXT' | translate }}</span>
                        <span *ngIf="element.locationTypeID !== 1">{{ 'COMMON.YES_TEXT' | translate }}</span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="wdo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="edit-cus-locs-wdo-hint">{{
                        'CUSTOMER_EDITOR.ASSIGN_LOCATIONS.WDO' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element" [ngClass]="{ selected: element.highlight }">
                        <button
                            class="flatButton"
                            mat-button
                            (click)="openWetDry(element)"
                            [disabled]="
                                element.wdo === 'N/A' ||
                                (element.monitorSeries &&
                                    (element.monitorSeries.toLowerCase() === 'rainalert iii' ||
                                        element.monitorSeries.toLowerCase() === 'rainalert ii' ||
                                        element.monitorSeries === '3600'))
                            "
                            [class.flatButtonDisabled]="
                                element.wdo === 'N/A' ||
                                (element.monitorSeries &&
                                    (element.monitorSeries.toLowerCase() === 'rainalert iii' ||
                                        element.monitorSeries.toLowerCase() === 'rainalert ii' ||
                                        element.monitorSeries === '3600'))
                            "
                        >
                            {{ element.wdo }}
                        </button>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="editLoc">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element" class="text-right" [ngClass]="{ selected: element.highlight }">
                        <mat-icon class="edit-cus-locs-edit-hint" (click)="editLocation(element)">mode_edit</mat-icon>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="customerDtlLocEdtrColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: customerDtlLocEdtrColumns"></mat-row>
            </mat-table>
        </div>
    </mat-card-content>
    <mat-card-actions>
        <div class="auto-margin">
            <button
                mat-fab
                color="accent"
                id="addNewLocation"
                class="add-new-location-top-value edit-cus-locs-add-hint"
                matTooltip="{{ 'COMMON.ADD_LOCATION_TEXT' | translate }}"
                matTooltipPosition="above"
                [matMenuTriggerFor]="menu"
                #addNewLocationTrigger="matMenuTrigger"
            >
                <mat-icon *ngIf="!addNewLocationTrigger.menuOpen">add_location</mat-icon>
                <mat-icon *ngIf="addNewLocationTrigger.menuOpen">close</mat-icon>
            </button>
            <mat-menu #menu="matMenu" yPosition="above" xPosition="after" [overlapTrigger]="false">
                <button mat-menu-item (click)="addCompositeLocation()">
                    <mat-icon>add_circle</mat-icon>
                    <span>{{ 'HOME.MAP.LEGEND.MAP_COMPOSITE_LOCATION' | translate }}</span>
                </button>
                <button mat-menu-item (click)="addTritonLocation()">
                    <mat-icon>add_location</mat-icon>
                    <span>{{ 'HOME.MAP.LEGEND.ADD_NEW_LOCATION' | translate }}</span>
                </button>
            </mat-menu>
        </div>

        <div class="app-flex-filler"></div>
        <div class="auto-margin" [hidden]="totalPaginationLength <= 10">
            <mat-paginator
                [length]="totalPaginationLength"
                [pageIndex]="0"
                [pageSize]="10"
                [pageSizeOptions]="[10, 25, 100]"
            >
            </mat-paginator>
        </div>
    </mat-card-actions>
</div>
