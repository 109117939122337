import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import {
    IComponentCustomizedConfirmation,
    IComponentCustomizedConfirmationResult,
} from 'app/shared/models/customized-confirmation-box';

@Component({
    selector: 'app-data-delete',
    templateUrl: './data-delete.component.html',
    styleUrls: ['./data-delete.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataDeleteComponent implements OnInit {
    /**
     * Represents inpus for Confirmation Box
     */
    public conformationDataInput: IComponentCustomizedConfirmation;

    public deleteConfirmationText: string;
    public deleteConfirmationmessage: string;
    public deleteConfirmationoktext: string;
    public deleteConfirmationcanceltext: string;

    /**
     * Represents show Confirmation box flag
     */
    public showConfirmation: boolean;

    constructor(public deleteDialog: MatDialogRef<DataDeleteComponent>, private translate: TranslateService) {}

    public ngOnInit() {
        this.translate.get('LOCATION_DASHBOARD.DELETE_DATA.DELETE_DATA_TITLE').subscribe((res) => {
            this.deleteConfirmationText = res;
        });
        this.translate.get('LOCATION_DASHBOARD.DELETE_DATA.DELETE_DATA_MESSAGE').subscribe((res) => {
            this.deleteConfirmationmessage = res;
        });
        this.translate.get('LOCATION_DASHBOARD.DELETE_DATA.DELETE_OK_TEXT').subscribe((res) => {
            this.deleteConfirmationoktext = res;
        });
        this.translate.get('LOCATION_DASHBOARD.DELETE_DATA.DELETE_CANCEL_TEXT').subscribe((res) => {
            this.deleteConfirmationcanceltext = res;
        });
    }
    /**
     * Close dialoge box method
     * @param permissionsUpdated
     */
    public cancel(permissionsUpdated?: boolean) {
        this.deleteDialog.close({ success: permissionsUpdated });
    }
    public delete() {
        // open confirmation dialog
        this.conformationDataInput = {
            title: this.deleteConfirmationText,
            message: this.deleteConfirmationmessage,
            okText: this.deleteConfirmationoktext,
            cancelText: this.deleteConfirmationcanceltext,
        };

        // display confirmaton dialog
        this.showConfirmation = true;
    }
    /**
     * Represents the Confirmation Action of updation in email notification subscriptions
     * @param confirmation -confirmation response
     */
    public listenConfirmation(confirmation: IComponentCustomizedConfirmationResult) {
        if (confirmation.whichButtonPressed === 'ok') {
            this.showConfirmation = false;
            this.deleteDialog.close({ success: false });
        } else {
            this.showConfirmation = false;
            this.deleteDialog.close({ success: false });
        }
    }
}
