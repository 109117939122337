import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'app/pages/admin/users.service';
import { TritonConfiguration } from 'app/shared/models/monitorsetting';
import { BatteryVoltage } from '../../location-card.constants';

@Component({
    selector: 'app-rainalert-info',
    templateUrl: './rainalert-info.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RainalertInfoComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() monitorConfigs: TritonConfiguration;
    @Input() sampleRate: number;
    public isMonitorEditor = false;
    public batteryVoltage = BatteryVoltage;
    constructor(private translateService: TranslateService, private usersService: UsersService) {}

    ngOnInit() {
        this.isMonitorEditor = this.usersService.isMonitorEditor.getValue();
        if (this.monitorConfigs) {
            this.form.patchValue(this.monitorConfigs);
        }
        this.form.patchValue({ sensor: this.translateService.instant('ADD_EDIT.RAIN') });
    }
}
