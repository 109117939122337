import { Injectable } from '@angular/core';
import { LocationDashboardFilterData } from 'app/shared/models/location-dashboard-filter-data';
import { BehaviorSubject } from 'rxjs';
import { Selectable } from '../models/selectable';
import { HydrographTickInterval } from '../models/view-data';

@Injectable()
export class DynamicFilterWidgetService {
    public locationDashboardFilterData: BehaviorSubject<LocationDashboardFilterData>;
    public location: BehaviorSubject<number>;
    public locationName: string;
    public customerId: number;
    public highchartsList: number[];
    public startDate: any;
    public endDate: any;
    public dateFormat: string;
    public timeFormat: string;
    public isDashboardEmpty: boolean;
    public containerIndex: number;
    public tickIntervalSlabType: HydrographTickInterval;
    public preselectedLocationItems: BehaviorSubject<Array<Selectable>>;
    // to disaply the message when dashboard is empty
    public dynamicDashboardEmpty = new BehaviorSubject<boolean>(true);
    constructor() {
        this.isDashboardEmpty = true;
        this.containerIndex = 0;
        this.locationDashboardFilterData = new BehaviorSubject<LocationDashboardFilterData>(null);
        this.location = new BehaviorSubject<number>(0);
        this.preselectedLocationItems = new BehaviorSubject<Array<Selectable>>(null);
    }

    public getSelectedLocationName() {
        return this.locationName;
    }

    public setSelectedLocationName(location: string) {
        this.locationName = location;
    }

    public getCustomerId() {
        return this.customerId;
    }

    public setCustomerId(customer: number) {
        this.customerId = customer;
    }

    public getStartDate() {
        return this.startDate;
    }

    public setStartDate(date: string) {
        this.startDate = date;
    }

    public getEndDate() {
        return this.endDate;
    }

    public setEndDate(date: string) {
        this.endDate = date;
    }

    public getDateFormat() {
        return this.dateFormat;
    }

    public setDateFormat(date: string) {
        this.dateFormat = date;
    }

    public getTimeFormat() {
        return this.timeFormat;
    }

    public setTimeFormat(date: string) {
        this.timeFormat = date;
    }

    public getisEmptyDashboardState() {
        return this.isDashboardEmpty;
    }

    public setisEmptyDashboardState(state: boolean) {
        this.isDashboardEmpty = state;
    }

    public getcontainerIndex() {
        return this.containerIndex;
    }

    public setcontainerIndex(index: number) {
        this.containerIndex = index;
    }

    public getTickIntervalSlabType() {
        return this.tickIntervalSlabType;
    }

    public setTickIntervalSlabType(slabType: HydrographTickInterval) {
        this.tickIntervalSlabType = slabType;
    }
}
