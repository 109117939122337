import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'app/pages/admin/users.service';
import { CollectionWidgetScheduleComponent } from 'app/pages/collection-widget-schedule/collection-widget-schedule.component';
import { DataCollectionComponent } from 'app/pages/data-collection/data-collection.component';
import { MonitorSettingsComponent } from 'app/shared/components/map/monitor-settings/monitor-settings.component';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { forkJoin, Observable } from 'rxjs';
import { Subscription } from 'rxjs';

import { CustomerDetailLocationsEditorComponent } from '../../../../pages/customer-editor/customer-detail-locations-editor/customer-detail-locations-editor.component';
import {
    DefinedInstallationType,
    InstallationType,
    INSTALLATION_TYPE,
    LocationDetails,
    LocationDetailsModel,
    MonitorSeriesUI,
} from 'app/shared/models/location-details';
import { MapService } from 'app/shared/services/map.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { CollectService } from 'app/shared/services/collect.service';
import { LocationDashboardService } from 'app/shared/services/location-dashboard.service';
import { ScheduleCollectionService } from 'app/shared/services/schedule-collection.service';
import { LocationService } from 'app/shared/services/location.service';
import { MathService } from 'app/shared/services/math.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { LocationArgs, Locations } from 'app/shared/models/locations';
import { customerLocationGroupQueryParam, customerQueryParam } from 'app/shared/models/customer';
import { Coordinate } from 'app/shared/models/coordinate';
import { ScheduleCollection } from 'app/shared/models/schedule-collection';
import {
    ChannelLocationUpdate,
    FlumeLocationUpdate,
    LocationUpdate,
    PipeLocationUpdate,
    RainLocationUpdate,
    WeirLocationUpdate,
} from 'app/shared/models/add-location';
import { AddLocationChannel, Entries } from 'app/shared/models/add-location-channel';
import { AddLocationPipes } from 'app/shared/models/add-location-pipes';
import { map, startWith } from 'rxjs/operators';
import { RAIN_DEFAULT_INSTALLATION_TYPE } from 'app/shared/constant';

@Component({
    selector: 'app-marker-location-details',
    templateUrl: './marker-location-details.component.html',
    styleUrls: ['./marker-location-details.component.scss'],
})
export class MarkerLocationDetailsComponent implements OnInit, OnChanges, OnDestroy {
    @Output() public closeMapLocationDetailPopup = new EventEmitter<boolean>();
    @Output() public locationHasUpdated = new EventEmitter<LocationDetailsModel>();
    @Input() public locationDetails: LocationDetailsModel;
    @Input() public customerId: number;
    @Input() public assignedRainGauge: Array<Locations>;
    @Input() public allLocations: Array<Locations>;

    @ViewChild('seriesAutoInput', { read: MatAutocompleteTrigger }) public seriesAuto: MatAutocompleteTrigger;
    @ViewChild('AssignedRainGaugeInput', { read: MatAutocompleteTrigger }) public auto: MatAutocompleteTrigger;

    /**
     * Represents the pre loader state.
     */
    public isLoading = true;
    public isValidLocationName: boolean;
    public isCollecting: boolean;
    public message = 'Collect has been initiated successfully.';
    public actionLabel = 'Dismiss';
    public locations: Locations;
    public schedules: ScheduleCollection[];
    public locationDetailsModel: LocationDetails;
    public isActives: Object;
    public editMode = false;
    public visibleGeneralWidget = true;
    public visibleCollectionWidget: boolean;
    public visibleInstallationWidget: boolean;
    public visibleDataWidget: boolean;
    public showLocationEditor = true;
    public isActiveLocation: boolean;
    public activateState = false;
    private customerID: number;
    private subscriptions = new Array<Subscription>();
    public instalationTypeUI: Observable<Array<InstallationType>>;
    public pipe = new AddLocationPipes();
    public instalationType: string;

    public flumeRange: number;
    public flumeCapacity: number;
    public flumeWidth: number;
    public flumeManholeDepth: number;

    public weirRange: number;
    public weirCapacity: number;
    public weirWidth: number;
    public weirConstant: number;
    public weirLength: number;
    public weirBreadth: number;
    // added for monitor series auto-complete for edit mode.
    public monitorSeriesUI = new Array<MonitorSeriesUI>();
    public seriesCtrl = new FormControl();
    public filteredSeries: Observable<Array<MonitorSeriesUI>>;
    public isFlumeMandatory: boolean;
    public isSecondMontoringPoint: boolean;
    public pipeHeightPlaceHolder = 'Pipe Height (in)';
    public manholeDepthPlaceHolder: string;
    public pipeWidthPlaceHolder: string;
    public flumeRangePlaceHolder: string;
    public widthPlaceHolder: string;
    public heightPlaceHolder: string;
    public lengthPlaceHolder: string;
    public breadthPlaceHolder: string;
    public depthPlaceHolder: string;
    public capacityPlaceHolder: string;
    public quantityPlaceHolder: string;

    /**
     * Represents the state of the previously held number of channel entries.
     */
    public previousNumberOfEntries: number;

    /**
     * Represents the collection of counts for channel installation type.
     */
    public numberOfTimes: Array<number>;

    /**
     * Reg Ex. Pattern for latitude
     */
    public latitudePattern = REGEX_CONFIG.latitudePattern;

    /**
     * Reg Ex. Pattern for longitude
     */
    public longitudePattern = REGEX_CONFIG.longitudePattern;

    /**
     * Reg Ex. Pattern for ip address
     */
    public ipaddressPattern = REGEX_CONFIG.ipaddressPattern;

    /**
     * Reg Ex. Pattern for numeric Decimal value 2Place
     */
    public numericDecimal2PlacePattern = REGEX_CONFIG.numericDecimal2PlacePattern;

    /**
     * Reg Ex. Pattern for Inputs should be numeric values between 0 and 100000 with up to 2 decimal places
     */
    public installationValuePattern = REGEX_CONFIG.installationValuePattern;

    /**
     * Reg Ex. Patther for inputs to exclude hyphens and underscores
     */
    public locationNameValuePattern = REGEX_CONFIG.alphaNumericWitHyphen_Pattern;

    /**
     * Reg Ex. Pattern for inputs to include Special Symbols as per User Story 8728
     */
    public alphaNumericWitSpecialSymbols_Pattern = REGEX_CONFIG.alphaNumericWitSpecialSymbols_Pattern;

    /**
     * Reg Ex. Pattern for alpha numeric
     */
    public alphaNumericPattern = REGEX_CONFIG.alphaNumericPattern;

    /**
     * Reg Ex. Pattern for limiting channel entries from 2 to 257
     */
    public channelEntriesPattern = REGEX_CONFIG.channelEntriesLimit;

    /**
     * Represents the rain guage FormControl.
     */
    public assignedRainGaugeCtrl = new FormControl();

    /**
     * Represents the filtered Assigned Rain guage.
     */
    public filteredassignedRainGauge: Array<Locations>;

    /**
     * Represents the number of entities input into a channel installation input.
     */
    public numberOfEntries: number;

    /**
     * represent channel depth
     */
    public depth = new Array<number>();

    /**
     * represent channel quantity
     */
    public quantity = new Array<number>();

    /**
     * represent model to post channel data
     */
    public channel: AddLocationChannel;

    /**
     * Reg Ex. Pattern for Inputs should be numeric values between 0 and 100 with up to 6 decimal places
     */
    public depthQuantityPattern = REGEX_CONFIG.numeric0_100With6DecimalPlacesPattern;

    /**
     * Represents location group id
     */
    public locationGroupId: number;

    /**
     * Represents location name
     */
    public oldLocationName: string;
    /**
     * To disable schedule button based on permission
     */
    public disableSchedule: boolean;

    /**
     * To describe pipe height placeholder based on translate
     */
    public pipeHeightPlaceholderFromJson: string;

    public manholeDepthPlaceholderFromJson: string;

    public pipeWidthPlaceholderFromJson: string;

    public fumeRangePlaceholderFromJson: string;

    public widthPlaceholderFromJson: string;

    public heightPlaceholderFromJson: string;

    public lengthPlaceholderFromJson: string;

    public breathPlaceholderFromJson: string;

    public depthPlaceholderFromJson: string;

    public capacityPlaceholderFromJson: string;

    public quantityPlaceholderFromJson: string;

    public metricUnitsFromJson: string;

    public usUnitsFromJson: string;

    /**
     * To describe pipe diameter placeholder based on translate
     */
    public pipeDiameterPlaceholderFromJson: string;

    /**
     * location update success message based on translate
     */
    public locationUpdateSuccessMessage: string;

    /**
     * location update error message based on translate
     */
    public locationUpdateErrorMessage: string;

    /**
     *dismiss button text in snackbar based on translate
     */
    public dismissButton: string;

    /**
     * location location already schedule message based on translate
     */
    public locationScheduleMessage: string;
    public yesText: string;
    public noText: string;

    public includeActiveLocation: number;
    public installationTypeId: number;

    public removeMargin: boolean;

    constructor(
        private collectService: CollectService,
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private locationDashboardService: LocationDashboardService,
        private scheduleCollectionService: ScheduleCollectionService,
        private locationService: LocationService,
        private activatedRoute: ActivatedRoute,
        private mathService: MathService,
        private usersService: UsersService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private statusCodeService: StatusCodeService,
        private uiUtilsService: UiUtilsService,
        private translate: TranslateService,
        private zone: NgZone,
        private dateutilService: DateutilService,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
        private mapService: MapService,
        @Optional() public dialogRef: MatDialogRef<CustomerDetailLocationsEditorComponent>,
    ) {
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.PIPE_HEIGHT').subscribe((res: string) => {
            this.pipeHeightPlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.PIPE_DIAMETER').subscribe((res: string) => {
            this.pipeDiameterPlaceholderFromJson = res;
        });
        translate.get('COMMON.LOCATION_UPDATE_MESSAGE').subscribe((res: string) => {
            this.locationUpdateSuccessMessage = res;
        });
        translate.get('COMMON.LOCATION_UPDATE_ERROR').subscribe((res: string) => {
            this.locationUpdateErrorMessage = res;
        });
        translate.get('HOME.COLLECTION_WIDGET.SNACKBAR_TEXT').subscribe((res: string) => {
            this.locationScheduleMessage = res;
        });
        translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismissButton = res;
        });
        translate.get('COMMON.YES_TEXT').subscribe((res: string) => {
            this.yesText = res;
        });
        translate.get('COMMON.NO_TEXT').subscribe((res: string) => {
            this.noText = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.COLLECT_INITIATE_MSG').subscribe((res: string) => {
            this.message = res;
        });
        translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.actionLabel = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.MANHOLE_DEPTH').subscribe((res: string) => {
            this.manholeDepthPlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.PIPE_WIDTH').subscribe((res: string) => {
            this.pipeWidthPlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.RAIN_PLACEHOLDER').subscribe((res: string) => {
            this.fumeRangePlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.WIDTH').subscribe((res: string) => {
            this.widthPlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.HEIGHT').subscribe((res: string) => {
            this.heightPlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.LENGTH').subscribe((res: string) => {
            this.lengthPlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.BREADTH').subscribe((res: string) => {
            this.breathPlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.DEPTH').subscribe((res: string) => {
            this.depthPlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.CAPACITY_PLACEHOLDER').subscribe((res: string) => {
            this.capacityPlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.QUANTITY').subscribe((res: string) => {
            this.quantityPlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.METRIC_UNITS').subscribe((res: string) => {
            this.metricUnitsFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.US_UNITS').subscribe((res: string) => {
            this.usUnitsFromJson = res;
        });
    }

    /**
     * Framework level hook.
     */
    public ngOnInit() {
        if (!this.locationDetails && this.data.openEditDialogFromCustomerEdit) {
            this.removeMargin = true;
            this.customerId = this.data.CustomerId;
            this.customerID = this.data.CustomerId;
            this.allLocations = this.data.locationList;
            this.assignedRainGauge = this.data.assignedRainGauge;
            this.getcurrentLocationDetails(this.data.location);
            this.editLocationDetails();
        } else {
            this.showMarkerLocationDetail();
        }
    }

    public showMarkerLocationDetail() {
        const activeInactiveLocationQueryParam = 'lt';
        // to check schedule collection permission
        this.subscriptions.push(
            this.usersService.isScheduleCollectAllowed.subscribe((response) => {
                this.disableSchedule = response;
            }),
        );
        // subscribe to route params
        this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            this.includeActiveLocation = Number(params.get(activeInactiveLocationQueryParam));
            this.customerID = Number(params.get(customerQueryParam));
            this.locationGroupId = Number(params.get(customerLocationGroupQueryParam));
        });

        // Storing default value of location name
        this.oldLocationName = this.locationDetailsModel.locationName;

        // get the monitor-series.
        this.subscriptions.push(
            this.locationService.getMonitorSeries().subscribe(
                (monitors: Array<MonitorSeriesUI>) => this.getlatesLocationDetails(monitors),
                () => {
                    // Error Block to handle Errors
                },
            ),
        );
        if (this.locationDetailsModel.assignedRainGaugeLocationName) {
            // fetch assigned rain gauge location from locations array
            this.filteredassignedRainGauge = this.assignedRainGauge.filter(
                (location) => location.locationName === this.locationDetailsModel.assignedRainGaugeLocationName,
            );
        } else {
            this.filteredassignedRainGauge = this.assignedRainGauge;
        }
    }

    /**
     * Framework level hook.
     */
    public ngOnChanges() {
        this.initializedLocationDetails();
    }

    public changeRainGaugeInput(selectableLocation: string) {
        this.assignedRainGaugeCtrl.setErrors({
            invalid: false,
        });
        if (typeof selectableLocation === 'string') {
            this.filteredassignedRainGauge = this.assignedRainGauge.filter((item) =>
                item.locationName.toLowerCase().startsWith(selectableLocation.toLowerCase()),
            );
        }

        // validating if user entered value exists in assigned raing guages array
        const isValidRainGauge = this.assignedRainGauge.filter(
            (item) => item.locationName.toLocaleLowerCase() === selectableLocation.toLocaleLowerCase(),
        );
        this.assignedRainGaugeCtrl.setErrors({
            invalid: isValidRainGauge.length === 0,
        });
    }

    /**
     * use it on scroll
     * @param e - Event
     */
    public onScroll(e: Event) {
        if (this.seriesAuto) {
            // hide series auto pannel
            this.seriesAuto.closePanel();
        }

        if (this.auto) {
            // hide assigned rain gauge auto pannel
            this.auto.closePanel();
        }
    }

    /**
     * Framework level hook.
     */
    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    private filterSeries(seriesName: string) {
        return this.monitorSeriesUI.filter(
            (series) => series.modelName.toLowerCase().indexOf(seriesName.toLowerCase()) === 0,
        );
    }

    // tslint:disable-next-line:cyclomatic-complexity
    private initializedLocationDetails() {
        this.locationDetailsModel = <LocationDetails>{};
        this.locationDetailsModel.description = this.locationDetails.locationDetails.description;
        this.locationDetailsModel.manholeAddress = this.locationDetails.locationDetails.manholeAddress;
        this.locationDetailsModel.coordinate = <Coordinate>{};
        this.locationDetailsModel.coordinate.latitude = this.locationDetails.locationDetails.coordinate.latitude;
        this.locationDetailsModel.coordinate.longitude = this.locationDetails.locationDetails.coordinate.longitude;
        this.locationDetailsModel.isActive = this.locationDetails.locationDetails.isActive;
        this.locationDetailsModel.series = this.locationDetails.locationDetails.series;
        this.locationDetailsModel.serialNumber = this.locationDetails.locationDetails.serialNumber;
        this.locationDetailsModel.ipaddress = this.locationDetails.locationDetails.ipaddress;
        this.locationDetailsModel.locationID = this.locationDetails.locationDetails.locationID;
        this.locationDetailsModel.locationName = this.locationDetails.locationDetails.locationName;
        this.locationDetailsModel.assignedRainGaugeLocationId =
            this.locationDetails.locationDetails.assignedRainGaugeLocationId;
        this.locationDetailsModel.assignedRainGaugeLocationName =
            this.locationDetails.locationDetails.assignedRainGaugeLocationName;
        this.locationDetailsModel.installationHeight = this.locationDetails.locationDetails.installationHeight;

        // rounding width due to backend calculations with some installation types (i.e.,)
        this.locationDetailsModel.installationHeight = this.mathService.round(
            this.locationDetails.locationDetails.installationHeight,
        );
        this.locationDetailsModel.installationWidth = this.mathService.round(
            this.locationDetails.locationDetails.installationWidth,
        );
        this.locationDetailsModel.installationType = this.locationDetails.locationDetails.installationType;
        this.locationDetailsModel.installationShape = this.locationDetails.locationDetails.installationShape;
        this.locationDetailsModel.installationShapeTypeID =
            this.locationDetails.locationDetails.installationShapeTypeID;
        this.locationDetailsModel.depthUnit = this.locationDetails.locationDetails.depthUnit;
        this.locationDetailsModel.flowUnit = this.locationDetails.locationDetails.flowUnit;

        if (this.locationDetailsModel.installationType === 'Rain Gauge') {
            this.instalationType = RAIN_DEFAULT_INSTALLATION_TYPE;
        } else if (
            this.locationDetailsModel.installationType &&
            this.locationDetails &&
            this.locationDetails.locationDetails.installationShapeTypeID > 0
        ) {
            this.instalationType =
                this.locationDetailsModel.installationType + ': ' + this.locationDetailsModel.installationShapeTypeID;
        }

        // check if this is a channel
        if (this.locationDetailsModel.installationShapeTypeID === 500 && this.locationDetails.locationDetails.entries) {
            // set number of entries to be equal to how many entries are in input object
            this.previousNumberOfEntries = this.numberOfEntries = this.locationDetails.locationDetails.entries.length;

            // set counter for form fields
            this.numberOfTimes = this.numberOfTimes = Array(this.locationDetails.locationDetails.entries.length).fill(
                0,
            );

            // setup depth aray
            this.depth = new Array<number>();

            // setup quantity array
            this.quantity = new Array<number>();

            // push to depth and quantity arrays the values from input object
            this.locationDetails.locationDetails.entries.forEach((entry: Entries) => {
                this.depth.push(entry.depth);
                this.quantity.push(entry.quantity);
            });
        }
        const isMetricUnit = this.dateutilService.isCustomerUnitsMetric.getValue();
        const depthUnit = isMetricUnit ? this.metricUnitsFromJson : this.usUnitsFromJson;

        this.pipeHeightPlaceHolder = this.pipeHeightPlaceholderFromJson + ' (' + depthUnit + ')';
        this.manholeDepthPlaceHolder = this.manholeDepthPlaceholderFromJson + ' (' + depthUnit + ')';
        this.pipeWidthPlaceHolder = this.pipeWidthPlaceholderFromJson + ' (' + depthUnit + ')';
        this.flumeRangePlaceHolder = this.fumeRangePlaceholderFromJson + ' (' + depthUnit + ')';
        this.widthPlaceHolder = this.widthPlaceholderFromJson + ' (' + depthUnit + ')';
        this.heightPlaceHolder = this.heightPlaceholderFromJson + ' (' + depthUnit + ')';
        this.lengthPlaceHolder = this.lengthPlaceholderFromJson + ' (' + depthUnit + ')';
        this.breadthPlaceHolder = this.breadthPlaceHolder + ' (' + depthUnit + ')';
        this.depthPlaceHolder = this.depthPlaceholderFromJson + ' (' + depthUnit + ')';
        this.capacityPlaceHolder = this.capacityPlaceHolder + ' (' + this.locationDetailsModel.flowUnit + ')';
        this.quantityPlaceHolder = this.quantityPlaceholderFromJson + ' (' + this.locationDetailsModel.flowUnit + ')';

        this.isActiveLocation = this.locationDetailsModel.isActive;
        this.locationDetailsModel.range = this.locationDetails.locationDetails.range;
        this.locationDetailsModel.capacity = this.locationDetails.locationDetails.capacity;
        this.locationDetailsModel.width = this.locationDetails.locationDetails.width;
        this.locationDetailsModel.manholedepth = this.locationDetails.locationDetails.manholedepth;
        this.locationDetailsModel.length = this.locationDetails.locationDetails.length;
        this.locationDetailsModel.breadth = this.locationDetails.locationDetails.breadth;
        this.locationDetailsModel.coefficient = this.locationDetails.locationDetails.coefficient;

        if (this.locationDetailsModel.installationType === INSTALLATION_TYPE.FLUME) {
            this.isFlumeMandatory =
                this.locationDetailsModel.installationShapeTypeID === 405 ||
                this.locationDetailsModel.installationShapeTypeID === 406;
        }

        if (this.locationDetailsModel.installationType === INSTALLATION_TYPE.CHANNEL) {
            this.locationDetailsModel.entries = this.locationDetails.locationDetails.entries;
        }

        if (this.locationDetailsModel.locationName.includes('(2)')) {
            this.isSecondMontoringPoint = true;
        }
        this.oldLocationName = this.locationDetailsModel.locationName;
    }

    public editLocationDetails() {
        this.isValidLocationName = true;
        this.editMode = true;
        this.showLocationEditor = false;
        this.toggleSeries();
    }
    public saveLocationDetails() {
        this.isLoading = true;
        this.toggleSeries();
        this.showLocationEditor = true;

        if (this.locationDetailsModel.assignedRainGaugeLocationName) {
            // fetch assigned rain gauge location from locations array
            const assignedRainGauge = this.assignedRainGauge.filter(
                (location) => location.locationName === this.locationDetailsModel.assignedRainGaugeLocationName,
            );

            // ensure the existence of assigned rain gauge
            if (assignedRainGauge && assignedRainGauge.length < 1) {
                this.assignedRainGaugeCtrl.setErrors({
                    invalid: true,
                });
                this.isLoading = false;
                this.editMode = true;
                this.showLocationEditor = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
                return;
            } else {
                // sets the location id to  assigned rain gauge location details  parameter
                // as we are geting the array object for assign rain gauge so we are retrieving the location id from default zero index
                this.locationDetailsModel.assignedRainGaugeLocationId = assignedRainGauge[0].locationId;
            }
            this.editMode = false;
        } else {
            this.locationDetailsModel.assignedRainGaugeLocationId = null;
        }

        // below is based on the data in `installation-type.json` file in
        // this project and will be removed once the API's are consolidated.

        // get shape id
        const shapeTypeId = this.locationDetailsModel.installationShapeTypeID;

        // setup pipe location updates
        if (shapeTypeId > 199 && shapeTypeId < 300) {
            this.updatePipeLocation();
            return;
        }

        // setup weir location updates
        if (shapeTypeId > 299 && shapeTypeId < 400) {
            this.updateWeirLocation();
            return;
        }

        // setup flume location updates
        if (shapeTypeId > 399 && shapeTypeId < 500) {
            this.updateFlumeLocation();
            return;
        }

        // setup channel location updates
        if (shapeTypeId === 500) {
            this.updateChannelLocation();
            return;
        }

        // default to rain location updates
        this.updateRainLocation();

        if (this.data && this.data.openEditDialogFromCustomerEdit) {
            this.dialogRef.close({ success: true });
        }
    }

    public emitCloseMapLocationDetailPopup() {
        if (this.data && this.data.openEditDialogFromCustomerEdit) {
            this.dialogRef.close({ success: false });
        } else {
            this.closeMapLocationDetailPopup.emit(true);
        }
    }

    public activateLocation() {
        if (
            this.locationDetailsModel.coordinate.latitude === undefined ||
            this.locationDetailsModel.coordinate.latitude === null
        ) {
            this.locationDetailsModel.coordinate.latitude = 0;
        }
        if (
            this.locationDetailsModel.coordinate.longitude === undefined ||
            this.locationDetailsModel.coordinate.longitude === null
        ) {
            this.locationDetailsModel.coordinate.longitude = 0;
        }
        if (this.locationDetailsModel.ipaddress === undefined || this.locationDetailsModel.ipaddress === null) {
            this.locationDetailsModel.ipaddress = '';
        }
        this.dialog.open(MonitorSettingsComponent, {
            disableClose: true,
            data: {
                customerId: this.customerID,
                LocationName: this.locationDetailsModel.locationName,
                Description: this.locationDetailsModel.description,
                ManholeAddress: this.locationDetailsModel.manholeAddress,
                Latitude: this.locationDetailsModel.coordinate.latitude,
                Longitude: this.locationDetailsModel.coordinate.longitude,
                IsActiveLocation: this.locationDetailsModel.isActive,
                MonitorSeries: this.locationDetailsModel.series,
                SerialNumber: this.locationDetailsModel.serialNumber,
                IpAddress: this.locationDetailsModel.ipaddress,
                locationID: this.locationDetailsModel.locationID,
                instalationType: this.locationDetailsModel.installationType,
            },
        });
    }

    public collectLocation(locationID: number) {
        this.isLoading = true;
        let handleBackCollect = true;
        this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            // get updated customer id
            const currentCustomerID = Number(params.get(customerQueryParam));
            this.subscriptions.push(
                this.locationService.getLocationDetails(currentCustomerID, locationID).subscribe((result) => {
                    let lastCollectDate;
                    const dateFormat = this.dateutilService.dateFormat.getValue().toUpperCase();
                    if (result) {
                        if (dateFormat === 'MM/DD/YYYY' || dateFormat === 'YYYY/MM/DD') {
                            lastCollectDate = new Date(result.lastCollectedDate);
                        } else if (dateFormat === 'DD/MM/YYYY') {
                            lastCollectDate = new Date(
                                String(result.lastCollectedDate).replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3-$2-$1'),
                            );
                        }
                    } else {
                        lastCollectDate = '';
                    }
                    if (handleBackCollect) {
                        handleBackCollect = false;
                        this.isLoading = false;
                        const collectLocationDialogRef = this.dialog.open(DataCollectionComponent, {
                            disableClose: true,
                            data: lastCollectDate,
                        });

                        collectLocationDialogRef.afterClosed().subscribe((response) => {
                            // if response true that denoted that collect button press from data-collection will be called
                            if (response && response.success && response.startDate && response.endDate) {
                                const startdate = new Date(
                                    response.startDate.getTime() - response.startDate.getTimezoneOffset() * 60000,
                                ).toISOString();
                                const endDate = new Date(
                                    response.endDate.getTime() - response.endDate.getTimezoneOffset() * 60000,
                                ).toISOString();
                                this.isCollecting = true;
                                const collectSubscription = this.collectService
                                    .collectLocation(
                                        this.customerID,
                                        locationID,
                                        startdate.split('.')[0],
                                        endDate.split('.')[0],
                                    )
                                    .subscribe(
                                        () => {
                                            this.isCollecting = false;
                                            this.snackBar.open(this.message, this.actionLabel, {
                                                duration: 10000,
                                            });
                                        },
                                        () => {
                                            this.isCollecting = false;
                                            this.subscriptions.push(
                                                this.statusCodeService.statusCode409.subscribe((response: boolean) => {
                                                    if (response) {
                                                        this.snackBar.open(
                                                            this.locationScheduleMessage,
                                                            this.actionLabel,
                                                            {
                                                                panelClass: 'custom-error-snack-bar',
                                                            },
                                                        );
                                                    }
                                                }),
                                            );
                                        },
                                    );
                                this.subscriptions.push(collectSubscription);
                            }
                        });
                    }
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }),
            );
        });
    }

    /**
     * Method which opens the schedule collection editor popup
     */
    public scheduleCollection() {
        this.disableSchedule = false;
        // intialize location parameter
        const locationArgs = <LocationArgs>{
            customerId: this.customerID,
            locationGroupId: this.locationGroupId,
        };

        const locationsObservable: Observable<Locations> = this.locationDashboardService.getLocationsList(locationArgs);

        const schedulesObservable: Observable<ScheduleCollection[]> =
            this.scheduleCollectionService.getScheduleCollection(this.customerID);

        const locationsAndSchedulesSubscription = forkJoin(locationsObservable, schedulesObservable).subscribe(
            (result: [Locations, ScheduleCollection[]]) => {
                this.dialog
                    .open(CollectionWidgetScheduleComponent, {
                        disableClose: true,
                        data: {
                            locations: result[0],
                            schedules: result[1],
                        },
                    })
                    .afterClosed()
                    .subscribe(() => {
                        this.disableSchedule = true;
                    });
            },
        );
        this.subscriptions.push(locationsAndSchedulesSubscription);
    }

    /**
     * Responsiblel for setting setting the installation type for the location.
     */
    public updateInstallationType() {
        this.locationDetailsModel.installationShapeTypeID = this.getInstallationType();

        if (this.locationDetailsModel.installationType === INSTALLATION_TYPE.CHANNEL) {
            this.locationDetailsModel.entries = this.locationDetails.locationDetails.entries;
        }
    }

    /**
     * Envoked when a selection event occurs on the Monitor Series AutoComplete.
     * @param event An instance of MatAutocompleteSelectedEvent.
     */
    public seriesSelectedHandler(event: MatAutocompleteSelectedEvent) {
        // find associated monitor
        const monitorId = this.getMonitorSeriesId(event.option.value);

        this.locationDetailsModel.installationShape = undefined;
        this.instalationTypeUI = undefined;
        this.instalationType = undefined;

        // re-setup installation type drop down.
        this.setupInstallationTypeDropDown(monitorId);
    }

    /*
     * Handles the 'blur' event for channel entries input.
     */
    public onBlurEntriesHandler(event: Event) {
        // get input entries count
        const entriesCount = Number((<HTMLInputElement>event.target).value);

        // ensure that previous entries are set
        this.previousNumberOfEntries = this.previousNumberOfEntries ? this.previousNumberOfEntries : entriesCount;

        // scenario: user has change to the same number
        // previous count and current count are equal, exit immediately
        if (this.previousNumberOfEntries === entriesCount && this.depth.length > 0) {
            return;
        }

        // ensure valid numbers (as per story)
        if (entriesCount < 2 || entriesCount > 257) {
            return;
        }

        // set array for view
        this.numberOfTimes = Array(entriesCount).fill(0);

        // scenario: user adds an entry
        if (this.previousNumberOfEntries < entriesCount) {
            this.depth.push(undefined);
            this.quantity.push(undefined);
        }

        // scenario: user has removed amount of entries
        // previous count is less than new count
        if (this.previousNumberOfEntries > entriesCount) {
            // get difference
            let difference = this.previousNumberOfEntries - entriesCount;

            do {
                this.depth.pop();
                this.quantity.pop();
            } while (--difference > 0);
        }

        this.previousNumberOfEntries = entriesCount;
    }

    public validateDuplicateCustomerName() {
        if (this.locationDetailsModel.locationName === '') {
            this.isValidLocationName = true;
            return;
        }

        if (this.locationDetailsModel && this.locationDetailsModel.locationName && this.allLocations) {
            this.locationDetailsModel.locationName = this.locationDetailsModel.locationName.trim();
            if (
                this.allLocations.find(
                    (location: Locations) =>
                        location.locationName.toLowerCase() === this.locationDetailsModel.locationName.toLowerCase(),
                ) &&
                this.oldLocationName !== this.locationDetailsModel.locationName
            ) {
                this.isValidLocationName = false;
            } else {
                this.locationDetailsModel.locationName =
                    this.locationDetailsModel.locationName.charAt(0).toUpperCase() +
                    this.locationDetailsModel.locationName.slice(1);
                this.isValidLocationName = true;
            }
        }
    }

    /**
     * Represents the cancel button click handler.
     * @param event The click MouseEvent.
     */
    public cancelFormEditHandler(event: Event) {
        if (this.data && this.data.openEditDialog) {
            this.closeMapLocationDetailPopup.emit(true);
        } else if (this.data && this.data.openEditDialogFromCustomerEdit) {
            this.dialogRef.close({ success: false });
        }

        event.preventDefault();

        this.showLocationEditor = true;
        this.editMode = false;
        this.seriesCtrl.disable();
        this.assignedRainGaugeCtrl.disable();
        this.locationDetailsModel.series = this.locationDetails.locationDetails.series;
        this.getlatesLocationDetails(this.monitorSeriesUI);
    }

    /**
     * Updates Rain location.
     */
    private updateRainLocation(): void {
        const updatedLocation: RainLocationUpdate = {
            description: this.locationDetailsModel.description,
            ipaddress: this.locationDetailsModel.ipaddress,
            isactive: this.isActiveLocation,
            locationid: this.locationDetailsModel.locationID,
            locationname: this.locationDetailsModel.locationName,
            latitude: this.locationDetailsModel.coordinate.latitude,
            longitude: this.locationDetailsModel.coordinate.longitude,
            manholeaddress: this.locationDetailsModel.manholeAddress,
            manholedepth: this.locationDetailsModel.manholedepth,
            series: this.locationDetailsModel.series,
            serialnumber: this.locationDetailsModel.serialNumber,
            rain: {
                installationID: this.locationDetailsModel.installationId,
                shapeType: this.locationDetailsModel.installationShapeTypeID,
            },
        };

        // update locations and subscribe to result
        const locUpdateSub = this.locationService
            .updatedRainLocation(this.customerId, updatedLocation)
            .subscribe((res) => {
                if (res.status === 200) {
                    this.notifyOfUpdatedLocation(updatedLocation);
                } else {
                    this.notifyOfUpdateLocationError(updatedLocation);
                }
                this.subscriptions.push(locUpdateSub);
            });
    }

    /**
     * Updates Pipe and other locations.
     */
    private updatePipeLocation(): void {
        const updatedLocation: PipeLocationUpdate = {
            description: this.locationDetailsModel.description,
            ipaddress: this.locationDetailsModel.ipaddress,
            isactive: this.isActiveLocation,
            locationid: this.locationDetailsModel.locationID,
            locationname: this.locationDetailsModel.locationName,
            latitude: this.locationDetailsModel.coordinate.latitude,
            longitude: this.locationDetailsModel.coordinate.longitude,
            manholeaddress: this.locationDetailsModel.manholeAddress,
            manholedepth: this.locationDetailsModel.manholedepth,
            series: this.locationDetailsModel.series,
            serialnumber: this.locationDetailsModel.serialNumber,
            assignedraingaugeid: this.locationDetailsModel.assignedRainGaugeLocationId,
            pipe: {
                hc: this.pipe.hc,
                installationID: this.locationDetails.locationDetails.installationId,
                height: this.locationDetailsModel.installationHeight,
                shapeType: this.locationDetailsModel.installationShapeTypeID,
                width: this.locationDetailsModel.installationWidth,
            },
        };

        // update locations and subscribe to result
        const locUpdateSub = this.locationService.updatePipeLocation(this.customerID, updatedLocation).subscribe(
            () => this.notifyOfUpdatedLocation(updatedLocation),
            (error) => {
                this.locationUpdateErrorMessage = error.error.responseMessage;
                this.notifyOfUpdateLocationError(updatedLocation);
            },
        );

        // mark for removal
        this.subscriptions.push(locUpdateSub);
    }

    /**
     * method to be used in future for channel installation update
     */
    private updateChannelLocation(): void {
        const updatedLocation: ChannelLocationUpdate = {
            description: this.locationDetailsModel.description,
            ipaddress: this.locationDetailsModel.ipaddress,
            isactive: this.isActiveLocation,
            locationid: this.locationDetailsModel.locationID,
            locationname: this.locationDetailsModel.locationName,
            latitude: this.locationDetailsModel.coordinate.latitude,
            longitude: this.locationDetailsModel.coordinate.longitude,
            manholeaddress: this.locationDetailsModel.manholeAddress,
            manholedepth: this.locationDetailsModel.manholedepth,
            series: this.locationDetailsModel.series,
            serialnumber: this.locationDetailsModel.serialNumber,
            assignedraingaugeid: this.locationDetailsModel.assignedRainGaugeLocationId,
            channel: {
                installationId: this.locationDetailsModel.installationId,
                entries: new Array<Entries>(),
            },
        };

        this.depth.forEach((entryValue: number, index: number) => {
            const entry = <Entries>{ depth: entryValue, quantity: this.quantity[index] };

            // if both are empty, exit immediately
            if (!entry.depth && !entry.quantity) {
                return;
            }

            updatedLocation.channel.entries.push(entry);
        });

        // update locations and subscribe to result
        const locUpdateSub = this.locationService.updateChannelLocation(this.customerID, updatedLocation).subscribe(
            () => this.notifyOfUpdatedLocation(updatedLocation),
            () => this.notifyOfUpdateLocationError(updatedLocation),
        );

        // mark for removal
        this.subscriptions.push(locUpdateSub);
    }

    /**
     * Updates Flume location.
     */
    private updateFlumeLocation(): void {
        const updatedLocation: FlumeLocationUpdate = {
            description: this.locationDetailsModel.description,
            ipaddress: this.locationDetailsModel.ipaddress,
            isactive: this.isActiveLocation,
            locationid: this.locationDetailsModel.locationID,
            locationname: this.locationDetailsModel.locationName,
            latitude: this.locationDetailsModel.coordinate.latitude,
            longitude: this.locationDetailsModel.coordinate.longitude,
            manholeaddress: this.locationDetailsModel.manholeAddress,
            manholedepth: this.locationDetailsModel.manholedepth,
            series: this.locationDetailsModel.series,
            serialnumber: this.locationDetailsModel.serialNumber,
            assignedraingaugeid: this.locationDetailsModel.assignedRainGaugeLocationId,
            flume: {
                capacity: this.locationDetailsModel.capacity,
                installationID: this.locationDetailsModel.installationShapeTypeID,
                range: this.locationDetailsModel.range,
                shapeType: this.locationDetailsModel.installationShapeTypeID,
                width: this.locationDetailsModel.width,
            },
        };

        // update locations and subscribe to result
        const locUpdateSub = this.locationService.updatedFlumeLocation(this.customerId, updatedLocation).subscribe(
            () => this.notifyOfUpdatedLocation(updatedLocation),
            () => this.notifyOfUpdateLocationError(updatedLocation),
        );
        // mark for removal
        this.subscriptions.push(locUpdateSub);
    }

    /**
     * Updates Flume location.
     */
    private updateWeirLocation(): void {
        const updatedLocation: WeirLocationUpdate = {
            description: this.locationDetailsModel.description,
            ipaddress: this.locationDetailsModel.ipaddress,
            isactive: this.isActiveLocation,
            locationid: this.locationDetailsModel.locationID,
            locationname: this.locationDetailsModel.locationName,
            latitude: this.locationDetailsModel.coordinate.latitude,
            longitude: this.locationDetailsModel.coordinate.longitude,
            manholeaddress: this.locationDetailsModel.manholeAddress,
            manholedepth: this.locationDetailsModel.manholedepth,
            series: this.locationDetailsModel.series,
            serialnumber: this.locationDetailsModel.serialNumber,
            assignedraingaugeid: this.locationDetailsModel.assignedRainGaugeLocationId,
            weir: {
                breadth: this.locationDetailsModel.breadth,
                capacity: this.locationDetailsModel.capacity,
                coefficient: this.locationDetailsModel.coefficient,
                height: this.locationDetailsModel.installationHeight,
                installationID: this.locationDetailsModel.installationShapeTypeID,
                length: this.locationDetailsModel.length,
                range: this.locationDetailsModel.range,
                shapeType: this.locationDetailsModel.installationShapeTypeID,
            },
        };

        // update locations and subscribe to result
        const locUpdateSub = this.locationService.updatedWeirLocation(this.customerId, updatedLocation).subscribe(
            () => this.notifyOfUpdatedLocation(updatedLocation),
            () => this.notifyOfUpdateLocationError(updatedLocation),
        );

        // mark for removal
        this.subscriptions.push(locUpdateSub);
    }

    /**
     * Disabled UI elements.
     */
    private toggleSeries() {
        if (this.editMode) {
            this.seriesCtrl.enable();
            this.assignedRainGaugeCtrl.enable();
        } else {
            this.seriesCtrl.disable();
            this.assignedRainGaugeCtrl.disable();
        }
    }

    /**
     * Updates component with messages and emissions of sucessful location update.
     * @param locationId Represents the location surrogate identifier.
     */
    private notifyOfUpdatedLocation(location: LocationUpdate): void {
        // notify parent
        this.locationHasUpdated.emit(<LocationDetailsModel>{
            locationDetails: <LocationDetails>{
                locationID: location.locationid,
                locationName: location.locationname,
                isActive: location.isactive,
                coordinate: {
                    latitude: location.latitude,
                    longitude: location.longitude,
                },
            },
        });

        this.isLoading = false;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        // setup success message
        this.snackBar.open(`${location.locationname} ${this.locationUpdateSuccessMessage}`, this.dismissButton, <
            MatSnackBarConfig
        >{
            duration: 10000,
        });

        if (this.data && this.data.openEditDialogFromCustomerEdit) {
            this.dialogRef.close({ success: true });
        }
    }

    /**
     * Updates component with messages and emission of unsuccessfull location update.
     * @param locationName Represents the name of the location.
     */
    private notifyOfUpdateLocationError(location: LocationUpdate): void {
        // notify parent with null value so that parent can perform actions
        this.locationHasUpdated.emit(<LocationDetailsModel>{
            locationDetails: <LocationDetails>{
                locationID: location.locationid,
                isActive: location.isactive,
                coordinate: {
                    latitude: location.latitude,
                    longitude: location.longitude,
                },
            },
        });

        // notify of failure
        this.snackBar.open(`"${this.locationUpdateErrorMessage}" "${location.locationname}".`, this.dismissButton, {
            panelClass: 'custom-error-snack-bar',
        });
    }

    /**
     * Determines the activation status.
     */
    private determineActivationStatus() {
        // get series name
        const seriesName = this.locationDetailsModel.series.toLowerCase();

        // set activation status
        this.activateState = seriesName.toLowerCase().indexOf('rainalert iii') >= 0 || seriesName.indexOf('echo') >= 0;
    }

    private getInstallationType(): number {
        const colonIndex = this.instalationType.indexOf(':');
        return Number(this.instalationType.substr(colonIndex + 2));
    }

    /**
     * Retrieves the monitor identifier object based on the monitor name.
     * @param monitorSeriesName The name of the monitor series.
     */
    private getMonitorSeriesId(monitorSeriesName: string): number {
        // find associated monitor
        const selectedMonitor = this.monitorSeriesUI.find(
            (monitor: MonitorSeriesUI) => monitor.modelName === monitorSeriesName,
        );

        return selectedMonitor ? selectedMonitor.monitorSeriesID : undefined;
    }

    /**
     * Responsible for setting up the location edit form.
     * @param monitors Represents a collection of monitors.
     */
    private getlatesLocationDetails(monitors: Array<MonitorSeriesUI>) {
        this.monitorSeriesUI = monitors;

        // filter monitor series and limit installation type based on selection
        this.filteredSeries = this.seriesCtrl.valueChanges.pipe(startWith(null)).pipe(
            map((seriesName: string) => {
                // if selected value is a rain gauge then filter the list to show only other rain gauges
                if (this.locationDetails.locationDetails.series.toLowerCase().indexOf('rainalert') > -1) {
                    return this.monitorSeriesUI.filter(
                        (monitor: MonitorSeriesUI) => monitor.modelName.toLowerCase().indexOf('rainalert') > -1,
                    );
                }

                return seriesName ? this.filterSeries(seriesName) : this.monitorSeriesUI.slice();
            }),
        );

        // enable disable of the series
        this.toggleSeries();

        // get selected monitor identifier
        const monitorId = this.getMonitorSeriesId(this.locationDetailsModel.series);

        // get installation type based on the series
        this.setupInstallationTypeDropDown(monitorId);

        this.isActives = [
            { value: true, text: this.yesText },
            { value: false, text: this.noText },
        ];

        this.initializedLocationDetails();

        this.determineActivationStatus();

        this.isLoading = false;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    private setupInstallationTypeDropDown(monitorId: number) {
        // set the appropriate installation types
        this.instalationTypeUI = this.locationService.getInstallationType(monitorId);

        // default the installation type id
        if (!(this.data && this.data.openEditDialogFromCustomerEdit)) {
            this.locationDetailsModel.installationShapeTypeID = 0;
        }
    }

    public goLocationDashboard() {
        const customerId = isNaN(this.customerID) ? 0 : this.customerID;
        this.router.navigate(['/pages/menuDashboard/viewData'], {
            queryParams: {
                c: customerId,
                lg: this.locationGroupId,
                lid: this.locationDetailsModel.locationID,
                lt: this.includeActiveLocation,
            },
            relativeTo: this.activatedRoute,
        });
    }

    public setCircularWidth() {
        if (this.locationDetailsModel.installationShapeTypeID === 200) {
            this.locationDetailsModel.installationWidth = this.locationDetailsModel.installationHeight;
        }
    }

    public setCircularHeight() {
        if (this.locationDetailsModel.installationShapeTypeID === 200) {
            this.locationDetailsModel.installationHeight = this.locationDetailsModel.installationWidth;
        }
    }

    public getcurrentLocationDetails(locationId: number) {
        this.isLoading = true;
        this.locationDetailsModel = <LocationDetails>{};
        this.locationDetailsModel.coordinate = <Coordinate>{};
        this.locationDetailsModel.series = '';

        this.mapService
            .getMarkerLocationDetails(locationId, this.customerId)
            .pipe(
                map((result) => {
                    // if result exists then we map it to required object

                    return result;
                }),
            )
            .subscribe((result: any) => {
                if (!(result === undefined || result.locationID === undefined || result.locationID === 0)) {
                    this.isLoading = false;

                    this.locationDetailsModel.series = result.series;
                    this.locationDetailsModel.serialNumber = result.serialNumber;
                    this.locationDetailsModel.isActive = result.isActive;
                    this.locationDetailsModel.description = result.description;
                    this.locationDetailsModel.manholeAddress = result.manholeAddress;
                    this.locationDetailsModel.coordinate = <Coordinate>{};
                    this.locationDetailsModel.coordinate.latitude = result.latitude;
                    this.locationDetailsModel.coordinate.longitude = result.longitude;
                    this.locationDetailsModel.locationID = result.locationID;
                    this.locationDetailsModel.locationName = result.locationName;
                    this.locationDetailsModel.assignedRainGaugeLocationId = result.assignedRainGaugeLocationId;
                    this.locationDetailsModel.installationType = result.installationType;
                    this.locationDetailsModel.installationId = result.installationID;
                    this.locationDetailsModel.depthUnit = result.depthUnit;
                    this.locationDetailsModel.flowUnit = result.flowUnit;
                    this.locationDetailsModel.locationType = result.components ? 3 : 1;
                    this.locationDetailsModel.components = result.components;

                    this.getInstallationTypeId(result.installationType);

                    this.locationDetailsModel.installationShape = result.installationShape;
                    this.locationDetailsModel.ipaddress = result.connectionString;
                    this.locationDetailsModel.range = result.range;
                    this.locationDetailsModel.capacity = result.capacity;
                    this.locationDetailsModel.width = result.width;
                    this.locationDetailsModel.installationShapeTypeID = Number(result.installationShapeTypeID);
                    this.locationDetailsModel.length = result.length;
                    this.locationDetailsModel.breadth = result.breadth;
                    this.locationDetailsModel.coefficient = result.coefficient;
                    this.locationDetailsModel.assignedRainGaugeLocationName = result.assignedRainGaugeLocationName;
                    this.locationDetailsModel.entries = result.entries;

                    this.locationDetailsModel.installationHeight = this.mathService.round(result.height);
                    this.locationDetailsModel.installationWidth = this.mathService.round(result.width);
                    this.locationDetailsModel.manholedepth = result.manholeDepth;

                    this.isActiveLocation = this.locationDetailsModel.isActive;

                    // setting autocomplet list to be shown intially on modal load when opened from location details page
                    if (this.locationDetailsModel.assignedRainGaugeLocationName) {
                        // fetch assigned rain gauge location from locations array
                        this.filteredassignedRainGauge = this.assignedRainGauge.filter(
                            (location) =>
                                location.locationName === this.locationDetailsModel.assignedRainGaugeLocationName,
                        );
                    } else {
                        this.filteredassignedRainGauge = this.assignedRainGauge;
                    }

                    //  this.locationDetailsModel = <LocationDetails>result;

                    this.locationDetails = <LocationDetailsModel>{
                        visibleLocations: this.allLocations.map((loc: Locations) => {
                            if (loc) {
                                return loc.locationId;
                            }
                        }),
                        locationDetails: this.locationDetailsModel,
                    };

                    if (this.locationDetailsModel.installationType === 'Rain Gauge') {
                        this.instalationType = RAIN_DEFAULT_INSTALLATION_TYPE;
                    } else if (
                        this.locationDetailsModel.installationType &&
                        this.locationDetails &&
                        this.locationDetails.locationDetails &&
                        this.locationDetails.locationDetails.installationShapeTypeID > 0
                    ) {
                        this.instalationType =
                            this.locationDetailsModel.installationType +
                            ': ' +
                            this.locationDetailsModel.installationShapeTypeID;
                    }

                    // check if this is a channel
                    if (
                        this.locationDetailsModel.installationShapeTypeID === 500 &&
                        this.locationDetails &&
                        this.locationDetails.locationDetails &&
                        this.locationDetails.locationDetails.entries
                    ) {
                        // set number of entries to be equal to how many entries are in input object
                        this.previousNumberOfEntries = this.numberOfEntries =
                            this.locationDetails.locationDetails.entries.length;

                        // set counter for form fields
                        this.numberOfTimes = this.numberOfTimes = Array(
                            this.locationDetails.locationDetails.entries.length,
                        ).fill(0);

                        // setup depth aray
                        this.depth = new Array<number>();

                        // setup quantity array
                        this.quantity = new Array<number>();

                        // push to depth and quantity arrays the values from input object
                        this.locationDetails.locationDetails.entries.forEach((entry: Entries) => {
                            this.depth.push(entry.depth);
                            this.quantity.push(entry.quantity);
                        });
                    }

                    // get the monitor-series.
                    this.subscriptions.push(
                        this.locationService.getMonitorSeries().subscribe(
                            (monitors: Array<MonitorSeriesUI>) => this.getlatesLocationDetails(monitors),
                            () => {
                                // Error Block to handle Errors
                            },
                        ),
                    );

                    this.locationDetailsModel.manholedepth = this.locationDetails.locationDetails.manholedepth;

                    const isMetricUnit = this.dateutilService.isCustomerUnitsMetric.getValue();
                    const depthUnit = isMetricUnit ? this.metricUnitsFromJson : this.usUnitsFromJson;

                    this.pipeHeightPlaceHolder = this.pipeHeightPlaceholderFromJson + ' (' + depthUnit + ')';
                    this.manholeDepthPlaceHolder = this.manholeDepthPlaceholderFromJson + ' (' + depthUnit + ')';
                    this.pipeWidthPlaceHolder = this.pipeWidthPlaceholderFromJson + ' (' + depthUnit + ')';
                    this.flumeRangePlaceHolder = this.fumeRangePlaceholderFromJson + ' (' + depthUnit + ')';
                    this.widthPlaceHolder = this.widthPlaceholderFromJson + ' (' + depthUnit + ')';
                    this.heightPlaceHolder = this.heightPlaceholderFromJson + ' (' + depthUnit + ')';
                    this.lengthPlaceHolder = this.lengthPlaceholderFromJson + ' (' + depthUnit + ')';
                    this.breadthPlaceHolder = this.breadthPlaceHolder + ' (' + depthUnit + ')';
                    this.depthPlaceHolder = this.depthPlaceholderFromJson + ' (' + depthUnit + ')';
                    this.capacityPlaceHolder =
                        this.capacityPlaceHolder + ' (' + this.locationDetailsModel.flowUnit + ')';
                    this.quantityPlaceHolder =
                        this.quantityPlaceholderFromJson + ' (' + this.locationDetailsModel.flowUnit + ')';

                    this.isLoading = false;
                    // get selected monitor identifier
                    const monitorId = this.getMonitorSeriesId(this.locationDetailsModel.series);

                    // get installation type based on the series
                    this.instalationTypeUI = this.locationService.getInstallationType(monitorId);
                    this.isActives = [
                        { value: true, text: this.yesText },
                        { value: false, text: this.noText },
                    ];
                }
            });
    }

    /**
     *
     * @param installationType - get the installationTypeId based on installationType
     */
    private getInstallationTypeId(installationType: string) {
        // API returning "Rain Gauge" for "RainGauge" hence added below check.
        if (installationType === 'Rain Gauge') {
            this.installationTypeId = 5;
        } else {
            for (const definedInstallationType in DefinedInstallationType) {
                if (definedInstallationType && DefinedInstallationType[definedInstallationType] === installationType) {
                    this.installationTypeId = parseInt(definedInstallationType, 10);
                }
            }
        }

        this.setupRainGaugeLocations();
    }

    private setupRainGaugeLocations() {
        // check if rain guage locations are already filled and if so exit immediately
        if (this.assignedRainGauge && this.assignedRainGauge.length > 0) {
            return;
        }

        // check if locations exist
        if (this.allLocations) {
            // make the filtered array for assignedRainGauge.
            this.assignedRainGauge = this.allLocations.filter((location) => {
                if (location && location.series && location.series.toLowerCase().indexOf('rainalert') > -1) {
                    return location;
                }
            });
        }
    }
}
