<div id="gis-layers">
    <mat-card-header>
        <div class="search-section auto-margin">FTP Configuration:</div>
        <div class="app-flex-filler"></div>
    </mat-card-header>
    <mat-card-content>
        <app-loader [isLoading]="loadingState"></app-loader>
        <!-- FTP Server table -->
        <div id="ftpTable">
            <mat-table #table [dataSource]="ftpDataSource" matSort class="edit-cus-ftp-table-hint">
                <!-- FTP Server table column 1 -->
                <ng-container matColumnDef="protocol">
                    <mat-header-cell *matHeaderCellDef>Protocol</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ protocols[element.protocol] }}</mat-cell>
                </ng-container>
                <!-- FTP Server table column 2 -->
                <ng-container matColumnDef="server">
                    <mat-header-cell *matHeaderCellDef>FTP Server</mat-header-cell>
                    <mat-cell class="description-space" *matCellDef="let element">{{ element.server }} </mat-cell>
                </ng-container>
                <!-- FTP Server table edit icon -->
                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef class="fixCellWidth"></mat-header-cell>
                    <mat-cell *matCellDef="let element" class="text-right">
                        <mat-icon matTooltip="Edit" (click)="editFTPServer(element)">mode_edit</mat-icon>
                        <mat-icon matTooltip="Delete" (click)="deleteFTPServer(element.guid)">delete</mat-icon>
                    </mat-cell>
                </ng-container>
                <!-- FTP Server table delete icon -->

                <mat-header-row *matHeaderRowDef="ftpDescriptionColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: ftpDescriptionColumns"></mat-row>
            </mat-table>
        </div>
    </mat-card-content>
    <mat-card-actions>
        <div class="auto-margin">
            <button
                mat-button
                color="accent"
                class="edit-cus-ftp-add-hint"
                matTooltip="{{ 'CUSTOMER_EDITOR.GIS_ADD_LAYER' | translate }}"
                matTooltipPosition="above"
                (click)="addNewFTPServer()"
            >
                Add More
            </button>
        </div>
        <div class="app-flex-filler"></div>
        <div class="paginator">
            <mat-paginator
                #paginator
                [length]="100"
                [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]"
            ></mat-paginator>
        </div>
    </mat-card-actions>
</div>
