import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { DryDayData } from 'app/shared/models/sliicer';
import { DryDayDiurnalGraphComponent } from './dry-day-diurnal-graph/dry-day-diurnal-graph.component';

@Component({
    selector: 'app-dry-days',
    templateUrl: './dry-days.component.html',
    styleUrls: ['./dry-days.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DryDaysComponent implements OnChanges {
    @Input() public caseStudyId: string;
    @Input() public isStudyLocked : boolean;
    @Input() public customerId: number;
    @Input() public telemetryKey: string;
    @Input() public stepLength: number;
    @Input() public basinDryDays: DryDayData;
    @Input() public selectedBasin: string;
    @Input() public isDryDaysEditActive: boolean;
    @Input() public daysLoading: boolean;
    @Input() public selectDryDays: {day: string, selected: boolean};
    @Output() public weekDaySelection = new EventEmitter<{ selectedDay: string; remove?: boolean }>();
    @Output() public dryDayRemoved = new EventEmitter<string>();
    @ViewChild(DryDayDiurnalGraphComponent) private dryDayDiurnalGraphComponent: DryDayDiurnalGraphComponent;

    public hasDryDayData: boolean;

    /**
     * Should the tooltips be shown.
     */

    constructor() {
        this.hasDryDayData = false;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.basinDryDays) {
            this.hasDryDayData = this.basinDryDays && this.basinDryDays.dayOfWeekGroupDays !== null;
        }

        if(changes.selectDryDays) {
            const val = changes.selectDryDays.currentValue;
            this.selectDryDays = val;
        }
    }

    /**
     * Dry day selection event
     */
    public onDaySelection({ selectedDay, remove = false }) {
        this.weekDaySelection.emit({ selectedDay, remove });
    }

    /**
     * Dry day removed from the graph event handler.
     */
    public onDryDayRemoved(selectedDay: string) {
        this.dryDayRemoved.emit(selectedDay);
    }
}
