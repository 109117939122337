import { Pipe, PipeTransform } from '@angular/core';
import { Either, isLeft, Left } from 'fp-ts/es6/Either';

@Pipe({
    name: 'isLeft',
})
export class IsLeftPipe implements PipeTransform {
    public transform<TL, TR>(value: Either<TL, TR>): value is Left<TL, TR> {
        return isLeft(value);
    }
}

// for type narrowing in a template like so: *ngIf="eitherVal | asLeft; let leftVal"
@Pipe({
    name: 'asLeft',
})
export class AsLeftPipe implements PipeTransform {
    public transform<TL, TR>(value: Either<TL, TR>): Left<TL, TR> | null {
        return isLeft(value) ? value : null;
    }
}
