import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Subscription } from 'rxjs';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { StatusCodeService } from '../../../shared/services/status-code.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomerService } from 'app/shared/services/customer.service';
import { GetLocationsRainGaugeResponse, GetRainGaugeResponse } from 'app/shared/models/customer';
import { Selectable } from 'app/shared/models/selectable';
@Component({
    selector: 'app-edit-wet-dry-overflow',
    templateUrl: './edit-wet-dry-overflow.component.html',
    styleUrls: ['./edit-wet-dry-overflow.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditWetDryOverflowComponent implements OnInit, OnDestroy {
    private subscriptions = new Array<Subscription>();

    // Represents all the rain gauges to be shown in the rain gauges dropdown
    public rainGaugesOptions = new Array<GetRainGaugeResponse>();

    // Represents the selected rain gauge ID in rain gauges dropdown
    public selectedRainGaugeID: number;

    // Holds all the updated locations with their added rain gauge ids
    private allUpdatedLocationsArray = new Array<any>();

    // Holds all the parameters for the selected rain gauge like rgid & rgname
    private selectedRainGaugeArray = new Array<GetRainGaugeResponse>();

    // Rain Gauges API returns all the series but as per the requirement only RainAlert II
    // and RainAlert III needs to be shown in Custom Alarms Rain Gauges Dropdown
    // allowedRainGaugesOptions - Holds only RainAlert II and RainAlert III rain gauges
    private allowedRainGaugesOptions = new Array<GetLocationsRainGaugeResponse>();

    // allAddedRainGaugeIDsArray - which holds all the rain gauges added for each location
    private allAddedRainGaugeIDsArray = new Array<GetRainGaugeResponse>();

    // Loader for custom alarms
    public customAlarmsLoadingState: boolean;

    // This boolean property helps in not sending calls to put api when there is a duplicate rain gauge id being added to a location
    public duplicateRainGaugeState: boolean;

    // Shows the location name that is being edited
    public selectedLocationName: string;

    //
    public selectableRainGauges: Array<Selectable>;

    public selectedRainGauges = new Array<Selectable>();

    /**
     * Reg Ex. Pattern for only numeric values
     */
    public numericPattern = REGEX_CONFIG.numericDecimal2PlacePattern;

    public numeric1_72Pattern = REGEX_CONFIG.numeric1_72Pattern;

    public isApplyButtonEnabled: boolean;

    public gaugeErrMsg: string;
    public alarmUpdateSuccess: string;
    public alarmUpdateErr: string;
    public dismissText: string;

    constructor(
        private customerService: CustomerService,
        public refDialog: MatDialogRef<EditWetDryOverflowComponent>,
        private snackBar: MatSnackBar,
        public statusCodeService: StatusCodeService,
        @Inject(MAT_DIALOG_DATA) public customAlarmsData: any,
        private translate: TranslateService,
    ) {}

    public ngOnInit() {
        this.translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismissText = res;
        });
        this.translate.get('CUSTOMER_EDITOR.EDIT_WET_DRY_OVERFLOW.RAIN_GAUGE_ADD_ERR').subscribe((res: string) => {
            this.gaugeErrMsg = res;
        });
        this.translate.get('CUSTOMER_EDITOR.EDIT_WET_DRY_OVERFLOW.CUSTOMER_ALARM_SUCCESS').subscribe((res: string) => {
            this.alarmUpdateSuccess = res;
        });
        this.translate.get('CUSTOMER_EDITOR.EDIT_WET_DRY_OVERFLOW.CUSTOMER_ALARM_ERR').subscribe((res: string) => {
            this.alarmUpdateErr = res;
        });

        // Setting selected location name for data being passed from custom alarms summary page
        this.selectedLocationName = this.customAlarmsData.location.locname;

        const regexRainUnit = new RegExp(this.numericPattern);
        regexRainUnit.test(this.customAlarmsData.threshold);

        const regexPeriod = new RegExp(this.numeric1_72Pattern);
        regexPeriod.test(this.customAlarmsData.period);

        this.isApplyButtonEnabled =
            regexRainUnit.test(this.customAlarmsData.threshold) && regexPeriod.test(this.customAlarmsData.period);

        // Looping through this.customAlarmsData.rainGauges array & pushing only
        // RainAlert II & RainAlert III values to allowedRainGaugesOptions
        if (this.customAlarmsData.rainGauges.length > 0) {
            const rainGaugesArray = this.customAlarmsData.rainGauges;
            rainGaugesArray.forEach((locationInfo, index) => {
                if (locationInfo.series === 'RainAlert II' || locationInfo.series === 'RainAlert III') {
                    this.allowedRainGaugesOptions.push(locationInfo);
                    this.customAlarmsData.rainGauges = this.allowedRainGaugesOptions;
                }
            });
        }

        // From the allowedRainGauges creating a Array for rainGauge Dropdown Data
        this.allowedRainGaugesOptions.forEach((rainGauge, index) => {
            const rainGauageData = {
                rgid: rainGauge.locationId,
                rgname: rainGauge.locationName,
            };
            this.rainGaugesOptions.push(rainGauageData);
        });

        // Selecting the first rain gauge id from the list
        this.selectedRainGaugeID = this.rainGaugesOptions[0].rgid;
        this.selectedRainGaugeArray.push(this.rainGaugesOptions[0]);
        const checkedGauges = [];
        if (
            this.customAlarmsData.location.hasOwnProperty('adsrgids') &&
            this.customAlarmsData.location.adsrgids.length > 0
        ) {
            this.customAlarmsData.location.adsrgids.forEach((gauge, index) => {
                checkedGauges.push({
                    id: gauge.rgid,
                    name: gauge.rgname,
                    isChecked: true,
                });
            });
        }
        this.setupRainGaugesDropdown();
        this.selectRainGauges(checkedGauges);
    }

    /**
     * Handles the custom alarm type selection change event.
     * @param alarmType The surrogate identifier for a alarm type.
     */
    public onRainGaugeTypeChange(rainGaugeType: number) {
        this.selectedRainGaugeArray = [];
        this.selectedRainGaugeID = rainGaugeType;

        // Looping through rainGauges Dropwdown & pushing only the selected rain gauge details
        this.rainGaugesOptions.forEach((rainGauge, index) => {
            if (rainGauge.rgid === this.selectedRainGaugeID) {
                this.selectedRainGaugeArray.push(rainGauge);
            }
        });
    }

    public selectedNewRainGauges(rainGauges) {
        this.selectRainGauges(rainGauges.filter((x) => x.isChecked));
        // this.isFormPristine = false;
        // this.disableSave = this.validateForm();
    }

    private setupRainGaugesDropdown() {
        this.selectableRainGauges = this.customAlarmsData['rainGauges'].map(
            (rainGauge: GetLocationsRainGaugeResponse) =>
                <Selectable>{
                    id: rainGauge.locationId,
                    name: rainGauge.locationName,
                    isChecked: false,
                    /* isChecked: this.data.schedules ? this.data.schedules
        .filter(item => item.name === this.scheduleName)
        .some(loc => loc.id === location.locationId) : false */
                },
        );
    }

    private selectRainGauges(rainGauges?: Array<Selectable>) {
        if (!rainGauges || (rainGauges && rainGauges.length === 0)) {
            this.selectedRainGauges = new Array<Selectable>();
            this.selectableRainGauges.forEach((rainGauge) => {
                rainGauge.isChecked = false;
            });
            return;
        }
        this.selectableRainGauges.forEach((rainGauge) => {
            rainGauge.isChecked = rainGauges.some((x) => x.id === rainGauge.id);
        });
        this.selectedRainGauges = rainGauges;
    }

    /**
     * updateLocationInfo method makes put call & updates the location info
     */
    public updateLocationInfo() {
        // Showing the Loader
        this.customAlarmsLoadingState = true;
        this.duplicateRainGaugeState = false;

        // Creating the Request Parameters for PUT API
        this.customAlarmsData.locs.forEach((location, index) => {
            // As per Task 9855 NWS Latitude & Longitude have been removed from the UI
            // but we need to pass undefined so that this parameter is not passed to the API
            location.nwslat = undefined;
            location.nwslong = undefined;

            // When No Rain Gauges are added to a location it returns undefined hence in that case create a empty rainGaugeArray
            if (!location.adsrgids) {
                location.adsrgids = new Array<GetRainGaugeResponse>();
                this.allAddedRainGaugeIDsArray = location.adsrgids;
                const idArr = [];
                if (location.lid === this.customAlarmsData.location.lid) {
                    this.selectedRainGauges.forEach((selectedGauge, i) => {
                        idArr.push(selectedGauge.id);
                    });
                }
                this.allAddedRainGaugeIDsArray = idArr;
            } else {
                this.allAddedRainGaugeIDsArray = [];
                const uniqueArray = [];

                // Push selectedRainGaugeArray Object to location.adsrgids array only for the edited location
                if (location.lid === this.customAlarmsData.location.lid) {
                    location.adsrgids = [];
                    this.selectedRainGauges.forEach((selectedGauge, i) => {
                        location.adsrgids.push({
                            rgid: selectedGauge.id,
                            rgname: selectedGauge.name,
                        });
                    });
                }

                // Check for removing duplicate raingauge ids
                location.adsrgids.filter((item) => {
                    const i = uniqueArray.findIndex((x) => x.rgid === item.rgid);
                    if (i >= 0 && location.lid === this.customAlarmsData.location.lid) {
                        // snack bar message
                        const simpleSnackBarRef = this.snackBar.open(
                            `${this.gaugeErrMsg} ${this.selectedLocationName}.`,
                            this.dismissText,
                        );
                        setTimeout(simpleSnackBarRef.dismiss.bind(simpleSnackBarRef), 10000);
                        this.duplicateRainGaugeState = true;
                        this.customAlarmsLoadingState = false;
                        this.close(false);
                        location.adsrgids.pop();
                        return;
                    }

                    // Only add to uniqueArray if there are no duplicates of rain gauge id
                    if (i <= -1) {
                        uniqueArray.push(item);
                    }
                    return null;
                });

                // Put API only needs the rain gauge ids hence extracting it from uniqueArray
                for (let i = 0; i < uniqueArray.length; i++) {
                    this.allAddedRainGaugeIDsArray.push(uniqueArray[i].rgid);
                }

                // }
            }

            const data = {
                locationID: location.lid,
                adsrgids: this.allAddedRainGaugeIDsArray,
                nwslat: location.nwslat,
                nwslong: location.nwslat,
            };
            this.allUpdatedLocationsArray.push(data);
        });

        const customAlarms = {
            enabled: true,
            eid: this.customAlarmsData.eid,
            threshold: this.customAlarmsData.threshold,
            period: this.customAlarmsData.period,
            locs: this.allUpdatedLocationsArray,
        };

        // If a location's enabled property is set to false, we need to call post api to make it enabled
        // & from there on call the put api to update any particular location
        if (!this.customAlarmsData.enabled) {
            const enableCustomAlarmsSubscription = this.customerService
                .enableCustomAlarms(customAlarms, this.customAlarmsData.cid)
                .subscribe(
                    (result) => {
                        // snack bar message
                        const simpleSnackBarRef = this.snackBar.open(
                            `${this.alarmUpdateSuccess} ${this.selectedLocationName}.`,
                            this.dismissText,
                        );
                        setTimeout(simpleSnackBarRef.dismiss.bind(simpleSnackBarRef), 10000);

                        // Set value for custom alarms page
                        this.statusCodeService.customAlarmIdInEditScreen.next(this.customAlarmsData.location.lid);

                        // hide the loader
                        this.customAlarmsLoadingState = false;

                        // close the dialog
                        this.close(true);
                    },
                    (error) => {
                        // snack bar message when error occured
                        /* let simpleSnackBarRef = this.snackBar.open(
            `Error in updating Custom Alarms for location ${this.selectedLocationName}`, 'Dismiss');
          setTimeout(simpleSnackBarRef.dismiss.bind(simpleSnackBarRef), 3000); */
                        this.snackBar.open(`${this.alarmUpdateErr} ${this.selectedLocationName}`, this.dismissText, {
                            panelClass: 'custom-error-snack-bar',
                        });
                    },
                );

            this.subscriptions.push(enableCustomAlarmsSubscription);
        } else if (!this.duplicateRainGaugeState) {
            const updateCustomAlarmsSubscriptions = this.customerService
                .updateCustomAlarms(customAlarms, this.customAlarmsData.cid)
                .subscribe(
                    (result) => {
                        // snack bar message
                        const simpleSnackBarRef = this.snackBar.open(
                            `${this.alarmUpdateSuccess} ${this.selectedLocationName}`,
                            this.dismissText,
                        );
                        setTimeout(simpleSnackBarRef.dismiss.bind(simpleSnackBarRef), 10000);

                        // Set value for custom alarms page
                        this.statusCodeService.customAlarmIdInEditScreen.next(this.customAlarmsData.location.lid);

                        // hide the loader
                        this.customAlarmsLoadingState = false;

                        // close the dialog
                        this.close(true);
                    },
                    (error) => {
                        // snack bar message when error occured
                        /* let simpleSnackBarRef =
        this.snackBar.open(`Error in updating Custom Alarms for location ${this.selectedLocationName}`, 'Dismiss');
        setTimeout(simpleSnackBarRef.dismiss.bind(simpleSnackBarRef), 3000); */
                        this.snackBar.open(`${this.alarmUpdateErr} ${this.selectedLocationName}`, this.dismissText, {
                            panelClass: 'custom-error-snack-bar',
                        });
                    },
                );

            this.subscriptions.push(updateCustomAlarmsSubscriptions);
        }
    }

    /**
     * Destroy the subscription data
     */
    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /**
     * Close dialoge box method
     * @param permissionsUpdated
     */
    public close(permissionsUpdated?: boolean) {
        this.refDialog.close({ success: permissionsUpdated });
    }
}
