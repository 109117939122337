import { Pipe, PipeTransform } from '@angular/core';
import { isNone, isSome, Option, Some } from 'fp-ts/es6/Option';

@Pipe({
    name: 'isSome',
})
export class IsSomePipe implements PipeTransform {
    public transform<T>(value: Option<T>): value is Some<T> {
        return isSome(value);
    }
}

@Pipe({
    name: 'isNull',
})
export class IsNullPipe implements PipeTransform {
    public transform(value: number | null): boolean {
        return null !== value;
    }
}

// for type narrowing in a template like so: *ngIf="optionValue | asSome; let someValue"
@Pipe({
    name: 'asSome',
})
export class AsSomePipe implements PipeTransform {
    public transform<T>(value: Option<T>): Some<T> | null {
        return isSome(value) ? value : null;
    }
}

@Pipe({
    name: 'isNone',
})
export class IsNonePipe implements PipeTransform {
    public transform(value: Option<unknown>): boolean {
        return isNone(value);
    }
}
