import {
    ChangeDetectorRef,
    Component,
    OnInit,
    ViewEncapsulation,
    Input,
    Output,
    EventEmitter,
    OnDestroy,
} from '@angular/core';
import { FileUploader, FileUploaderOptions, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { SharedService } from 'app/shared/services/shared.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { Subscription } from 'rxjs';
import { SliicerCaseStudy } from 'app/shared/models/sliicer';
import { FlowloadFileUploadService } from './flowload-file-upload.service';
import { VaultUploadResult } from 'app/shared/models/vault';

@Component({
    selector: 'app-flowload-file-upload',
    templateUrl: './flowload-file-upload.component.html',
    styleUrls: ['./flowload-file-upload.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FlowloadFileUploadComponent implements OnInit, OnDestroy {
    /**
     * Represents output property of the uploaded file status.
     */
    //TODO: WPS - Add a type here
    @Output() private flowLoadFileUploaded = new EventEmitter();

    /**
     * Represents the third party uploading library.
     */
    public uploader: FileUploader;

    /**
     * Indicates whether a file over event has been performed.
     */
    public hasBaseDropZoneOver = false;

    /**
     * Indicates whether the maximum amount of files has been exceeded
     */
    public maxFilesExceeded = false;

    /**
     * Indicates whether a file drop event has been performed.
     */
    public hasFileDropped = false;

    /**
     * Indicates how many files were
     */
    public selectedFileCount = 0;

    /**
     * Indicates how many failed uploads occured.
     */
    public uploadFailCount = 0;

    /**
     * Indicates how many files were uploaded successfully.
     */
    public uploadedFileCount = 0;

    /**
     * Indicates whether all uploads have finished.
     */
    public hasFinished = false;

    /**
     * Represnts a colleciton of uploads that failed.
     */
    public failedUploads = new Array<string>();

    /**
     * Represents the collection of files that will be uploaded.
     */
    public overwritableFiles: Array<VaultUploadResult>;

    /**
     * Represents the results of the upload. This is used to track the files
     * that are going to be uploaded and their state post upload attempt.
     */
    private uploadResults = new Array<VaultUploadResult>();

    /**
     * Flag to check if uploaded file has correct Mime Type
     */
    public isWrongMimeType = false;

    /**
     * Flag to check if uploaded file has correct file extension
     */
    public isWrongFileExtension = false;

    /*
     * Represents whether progress bar is showing or not.
     */
    public showProgress = false;

    /**
     * file Upload display message on popup
     */
    public displayMessage: string;

    /**
     * Indicates whether uploaded file size is greater than 20 MB or not
     */
    public isMaxFileSize = false;

    public caseStudyId = '';
    private customerId = 0;
    private vaultFolder = '';

    private subscriptions = new Array<Subscription>();

    constructor(
        private changeDetector: ChangeDetectorRef,
        private sharedService: SharedService,
        private uiUtilsService: UiUtilsService,
        private sliicerService: SliicerService,
        public flowloadFileUploadService: FlowloadFileUploadService,
    ) {
        // by default set to empty the uploaded popup text message
        this.sharedService.setFileUploadMessage('');
        this.setupUploadingHandlers();
    }

    /**
     * Framework level licecycle hook.
     */
    public ngOnInit(): void {
        // set file upload message
        this.sharedService.currentMessage.subscribe((response) => (this.displayMessage = response)).unsubscribe();
        this.subscriptions.push(
            this.sliicerService.studyDetailsData$.subscribe((caseStudyData: SliicerCaseStudy) => {
                this.customerId = caseStudyData.customerId;
                this.vaultFolder = caseStudyData.meta.studyVaultName;
                this.caseStudyId = caseStudyData.id;
            }),
        );

        // getting ads token from session storage which was set by main resolver
        this.uploader = new FileUploader(<FileUploaderOptions>{
            allowedFileType: ['application'],
            maxFileSize: 2048 * 1024 * 1024, // 2GB
            autoUpload: false,
            disableMultipart: true,
            itemAlias: 'files',
            removeAfterUpload: true,
            method: 'PUT',
            headers: [{ name: 'x-ms-blob-type', value: 'BlockBlob' }],
        });

        // File uploader method to handle error before file upload
        this.uploader.onWhenAddingFileFailed = (item: any, filter: any) => this.onWhenAddingFileFailed(item, filter);
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscripton) => subscripton.unsubscribe());
    }

    /**
     * Method to check file extension and showing error on wrong file type
     * @param item file to be uploaded
     * @param filter file uploader filters
     */
    public onWhenAddingFileFailed(item: any, filter: any) {
        if (filter.name === 'mimeType') {
            this.isWrongMimeType = true;
        } else if (filter.name === 'fileType') {
            this.isWrongFileExtension = true;
        } else if (filter.name === 'fileSize') {
            this.isMaxFileSize = true;
        }
        this.hasFileDropped = false;
        this.hasFinished = true;
        return;
    }

    /**
     * Handles the drag-over event for the target area.
     * @param isFileOver Indicates whether a file is over the drop zone.
     */
    public fileOverBase(isFileOver: boolean): void {
        // set the state of hover
        this.hasBaseDropZoneOver = isFileOver;
    }

    private handleUpload(files: FileList): void {
        // reset error message for maximum number of files limit
        this.resetErrorMsg(false);

        // reset the current state
        this.resetState();

        // ensure that there are files, if not, reset state and exit immediatly
        if (files.length < 1) {
            return this.resetState();
        }

        if (files.length > 25) {
            this.resetErrorMsg(true);
            return this.resetState();
        }

        // set files dropped
        this.hasFileDropped = true;

        for (let i = 0; i < files.length; i++) {
            const file = files.item(i);
            const filePath = this.vaultFolder + '/' + file.name;
            const collection = [file];
            this.sliicerService.getDirectUploadUrl(this.customerId, filePath).subscribe(
                (sasUrl: string) => {
                    this.uploader.options.url = sasUrl;
                    this.uploader.addToQueue(collection);
                    this.handleOverwrite();
                },
                () => {
                    // TODO: handle
                },
            );
        }
    }

    /**
     * Handles the drop files event.
     * @param files Represents the dropped files.
     */
    public fileDrop(files: FileList): void {
        if (files == null || this.isWrongMimeType || this.isWrongFileExtension) {
            this.isWrongMimeType = false;
            this.isWrongFileExtension = false;
            this.isMaxFileSize = false;
            return;
        }
        // Need to kill the queue because we will be manually adding them
        this.uploader.queue = [];
        this.handleUpload(files);
    }

    /**
     * Handles the change event for manually selecting files.
     * @param event Represents the input elemetn change event.
     */
    public fileSelectChangeHandler(event: MouseEvent): void {
        // get the reference to the target element
        const inputElement = <HTMLInputElement>event.target;
        const files: FileList = inputElement.files;
        this.handleUpload(files);
        // reset the value of the input element so as to allow for retry of the same file upload
        inputElement.value = null;
    }

    /**
     * Resets the error message for maximum files exceeded
     */
    public resetErrorMsg(isMaximumFilesExceeded: boolean): void {
        this.maxFilesExceeded = isMaximumFilesExceeded;
    }
    /**
     * Resets the common state of the file upload variables.
     */
    public resetState(): void {
        // reset uploadProgress array
        this.flowloadFileUploadService.resetImport(this.caseStudyId);

        // reset drop state
        this.hasFileDropped = false;

        // reset finished state to
        this.hasFinished = false;

        // reset the error state
        this.failedUploads = new Array<string>();

        // reset file count states
        this.selectedFileCount = this.uploadedFileCount = this.uploadFailCount = 0;

        // repoint array
        this.uploadResults = new Array<VaultUploadResult>();

        // reset errors
        this.isMaxFileSize = false;
        this.isWrongFileExtension = false;
        this.isWrongMimeType = false;
    }

    /**
     * handler for done button on file overwrite dialog
     * @param files files checked for overwrite
     */
    public uploadeOverwriteComplete(files: Array<VaultUploadResult>): void {
        this.overwritableFiles = files;
    }

    private handleOverwrite(): void {
        if (this.isWrongMimeType || this.isWrongFileExtension) {
            this.isWrongMimeType = false;
            this.isWrongFileExtension = false;
            return;
        }

        if (this.uploader.queue.length) {
            this.uploader.uploadAll();
            this.flowloadFileUploadService.setImportActive(this.caseStudyId, true);
            this.flowloadFileUploadService.setProgressBars(this.uploader, this.caseStudyId);
            this.setupUploadingHandlers();
        }
    }

    /**
     * This will set progressBar for each file and will listen and adjust the progress value
     */

    /**
     * Handles setting up handlers for the file uploader.
     */
    private setupUploadingHandlers(): void {
        // set context of this as hooks will be processed in the context of the ng2-file-upload library
        // and so this will point to that instance
        const that = this;

        // set hook for erroring on an upload of an item
        FileUploader.prototype.onErrorItem = (
            item: FileItem,
            response: string,
            status: number,
            headers: ParsedResponseHeaders,
        ): void => {
            // increment the failed amount of uploads
            that.uploadFailCount = that.uploadFailCount + 1;

            that.updateUploadAttempt(item, Number(status));
        };

        // set hook for notification of upload completion
        FileUploader.prototype.onCompleteAll = (): void => {
            // sett the uploaded file count to be the difference of the selected files and the actual uploaded files
            that.uploadedFileCount = that.selectedFileCount - that.uploadFailCount;

            // set upload finished state to true
            that.hasFinished = true;

            // check if errors exist
            that.failedUploads = that.uploadResults
                .filter((result: VaultUploadResult) => !result.isSuccess)
                .map((result: VaultUploadResult) => {
                    if (!result.isSuccess) {
                        return result.fileName;
                    }
                });

            this.uiUtilsService.safeChangeDetection(this.changeDetector);

            // if there are errors
            if (that.failedUploads.length > 0) {
                return;
            }

            // finish upload
            that.resetState();
        };

        // set hoook for updating each file pre-upload
        FileUploader.prototype.onBeforeUploadItem = (item: FileItem) => {
            // don't send credentials as angular framework will take care of this
            item.withCredentials = false;
        };

        // set hook for the completion of an item
        FileUploader.prototype.onCompleteItem = (
            item: FileItem,
            response: string,
            status: number,
            headers: ParsedResponseHeaders,
        ): void => {
            this.flowLoadFileUploaded.emit(response);
            this.updateUploadAttempt(item, Number(status));
        };
    }

    /**
     * Updates a collection of upload attempts and sets their success rate.
     * @param fileItem The file item to use for updating the upload attempt.
     * @param httpStatusResult The HTTP status code returned during the upload attempt.
     */
    private updateUploadAttempt(fileItem: FileItem, httpStatusResult: number): void {
        // iterate over all of the attempted uploads
        for (let i = 0; i < this.uploadResults.length; i++) {
            // get attepmted file reference
            const file: VaultUploadResult = this.uploadResults[i];

            // if the names of the completed item don't match, move on
            if (file.fileName.toLowerCase() !== fileItem.file.name.toLowerCase()) {
                continue;
            }

            // set success rate
            file.isSuccess = httpStatusResult < 299;
        }
    }
}
