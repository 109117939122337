import { Injectable, OnDestroy } from '@angular/core';
import { AdalService } from 'app/shared/services/auth-adal.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Config } from 'app/shared/services/config';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TokenResponse } from 'app/shared/models/adsToken';

export const ADS_TOKEN_KEY = 'adsToken';
export const ADS_REFRESH_TOKEN_KEY = 'adsRefreshToken';
export const REMEMBER_ME_KEY = 'rememberMe';

@Injectable()
export class AuthService implements OnDestroy {
    /**
     * Represents the redirection url.
     */
    public redirectUrl: string;

    public callOnceFlag = true;

    public adsToken: string;

    public gotTheToken$ = new BehaviorSubject<boolean>(false);

    public setErrorCountFlag = false;

    public idleLogout = false;

    // #40551 Cannot be BehaviourSubject because of architecture #40642
    public isUserLogged$ = new Subject<boolean>();

    private subscriptions: Subscription[] = [];

    constructor(
        public http: HttpClient,
        private adalService: AdalService
    ) {
        this.init();
        this.subscriptions.push(
            this.adalService.isUserLogged$.subscribe(isLogged => this.isUserLogged$.next(isLogged))
        );
    }

    /**
     * Gets the Active Directory configuration object.
     */
    private get adalConfig() {
        return environment.adalConfig;
    }

    /**
     * Gets a value indicating whether the user is authenticated or not.
     */
    public get isAuthenticated() {
        return this.adalService.userInfo.isAuthenticated;
    }

    /**
     * Gets a value indicating whether the user is authenticated or not.
     */
    public get isAuthenticatedNormal() {
        const normalToken = sessionStorage.getItem(ADS_TOKEN_KEY);
        return Boolean(normalToken);
    }

    /**
     * Gets the authentication token by itself.
     */
    public get authToken() {
        const adalToken = sessionStorage.getItem('adal.idtoken');
        return adalToken;
    }

    /**
     * Gets the Bearer Authentication Token.
     */
    public get bearerToken() {
        return `Bearer ${this.authToken}`;
    }

    /**
     * Gets the user object
     */
    public get user() {
        /**
         * Gets the user object from session Storage
         */
        if (sessionStorage.getItem('adsToken') !== null && sessionStorage.getItem('adsRole') !== null) {
            return {
                name: sessionStorage.getItem('firstName'),
                email: sessionStorage.getItem('userEmail'),
                id: sessionStorage.getItem('userId'),
                isIdex: sessionStorage.getItem('isIdex') === 'true'
            };
        } else if (!this.isAuthenticated && sessionStorage.getItem(ADS_TOKEN_KEY)) {
            // added to fix topnav bar issue
            this.logout();
            // return null;
        } else if (this.adalService.userInfo.profile) {
            // return authenticated user object
            // todo: Make into type
            return {
                name: this.adalService.userInfo.profile['name'],
                email: this.adalService.userInfo.profile['email'],
                id: this.adalService.userInfo.profile['id'],
                isIdex: sessionStorage.getItem('isIdex') === 'true'
            };
        }
    }

    /**
     * Initializes the authentication services
     */
    public init() {
        this.adalService.init(this.adalConfig);
        this.adalService.handleWindowCallback();
        this.adalService.getUser();
    }

    /**
     * Responsible for authenticating the user.
     */
    public login() {
        this.idleLogout = false;
        this.adalService.login();
    }

    /**
     * Responsible for de-authenticating the user.
     */
    public logout(keepRefreshToken = false) {
        if(!this.adalService.userInfo.isAuthenticated) {
            this.isUserLogged$.next(false);
        }
        this.adalService.logOut(keepRefreshToken);
    }

    public get userName() {
        return this.user.email;
    }

    public get userID() {
        return this.user.id;
    }

    public callRefreshToken() {
        const token = localStorage.getItem(ADS_REFRESH_TOKEN_KEY);
        return this.http.post<TokenResponse>(Config.urls.refreshToken, { token }).pipe(
            tap((response) => {
                const tokenResponse = response.token;
                const refreshToken = response.refreshToken;

                sessionStorage.setItem('adsRole', response.roles.toString());
                sessionStorage.setItem(ADS_TOKEN_KEY, tokenResponse);
                localStorage.setItem(ADS_REFRESH_TOKEN_KEY, refreshToken);
            })
        );
    }

    public normalUserLoggedIn() {
        this.isUserLogged$.next(true);
    }
    /**
     * Responsible for authenticating the normal user.
     */
    public authenticateNormalUser(userName: string, password: string) {
        const data = {
            userName: userName,
            password: password,
        };
        return this.http.post<TokenResponse>(Config.urls.userAuthentication, data);
    }

    // Responsible for non Authenticated resetting the password.
    public nonAuthResetPassword(token: string, userName: string, email: string, newPassword: string) {
        const data = {
            token: token,
            user_name: userName,
            new_password: newPassword,
            email: email
        };
        return this.http.post(Config.urls.nonAuthResetPassword, data);
    }

    // Responsible for non Authenticated resetting the password.
    public resetPassword(token: string, userName: string, email: string, newPassword: string) {
        const data = {
            token: token,
            user_name: userName,
            new_password: newPassword,
            email: email
        };
        return this.http.post(Config.urls.resetPassword, data);
    }

    /**
     * Responsible for check the user active/inactive.
     */
    public checkToken(token: string) {
        const data = {
            token: token,
        };
        return this.http.post(Config.urls.adsToken, token);
    }

    // Responsible for non Authenticated generating token.
    public nonAuthGenerateToken(userName, email, googleReCaptchaResponse) {
        const resetDetails = {
            username: userName,
            email: email,
            recaptcha: {
                googleReCaptchaResponse: googleReCaptchaResponse,
            },
        };

        return this.http.post(Config.urls.nonAuthGenerateToken, resetDetails);
    }

    // Responsible for generating token.
    public generateToken() {
        return this.http.post(Config.urls.generateToken, null);
    }

    public ngOnDestroy(): void {
        for(const sub of this.subscriptions) {
            sub.unsubscribe();
        }
        this.subscriptions = [];
    }
}
