<div id="vaultLocationUploadDialog">
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'VAULT.VAULT_UPLOAD_LOCATION.LOCATION_UPLOAD_TITLE' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <mat-dialog-content>
        <div class="file-upload-dialog">
            <div
                ng2FileDrop
                [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver, 'upload-errors': failedUploads.length > 0 }"
                (fileOver)="fileOverBase($event)"
                (onFileDrop)="fileDrop($event)"
                (click)="resetState()"
                [uploader]="uploader"
                class="my-drop-zone"
            >
                <input id="files" name="files" type="file" (change)="fileSelectChangeHandler($event)" multiple />
                <label for="files">
                    <div *ngIf="!hasFinished && failedUploads.length < 1">
                        <div *ngIf="!hasFileDropped">
                            <div class="uploadIcon">
                                <mat-icon>cloud_upload</mat-icon>
                            </div>
                            <div class="uploadMessage">{{ displayMessage }}</div>
                            <span class="smallLink">{{ 'VAULT.CLICK_BROWSE' | translate }}</span>
                        </div>

                        <app-loader [isLoading]="hasFileDropped && !hasFinished"></app-loader>
                    </div>

                    <div class="error-upload-box" *ngIf="failedUploads.length > 0">
                        <p>{{ uploadFailCount }}/{{ selectedFileCount }} {{ 'VAULT.FILE_UPLOAD_ERR' | translate }}</p>
                        <mat-list *ngIf="hasFinished && failedUploads.length > 0">
                            <mat-list-item *ngFor="let failedFile of failedUploads">{{ failedFile }}</mat-list-item>
                        </mat-list>
                    </div>
                    <div class="error-upload-box" *ngIf="isWrongFileType">
                        <p>{{ 'VAULT.VAULT_UPLOAD_LOCATION.LOCATION_FILE_PATTERN_ERR' | translate }}</p>
                        <mat-list>
                            <mat-list-item *ngFor="let failedFile of failedUploads">{{ failedFile }}</mat-list-item>
                        </mat-list>
                    </div>
                    <div class="error-upload-box" *ngIf="isMaxFileSize">
                        <p>{{ 'VAULT.FILE_SIZE_ERR' | translate }}</p>
                        <mat-list>
                            <mat-list-item *ngFor="let failedFile of failedUploads">{{ failedFile }}</mat-list-item>
                        </mat-list>
                    </div>
                </label>
            </div>
            <div class="danger" *ngIf="maxFilesExceeded">
                {{ 'VAULT.FILE_SELECTION_ERR' | translate }}
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button mat-dialog-close>{{ 'COMMON.CLOSE' | translate }}</button>
        </div>
    </mat-dialog-actions>
</div>
