import { Component, ViewEncapsulation, Inject, SimpleChanges } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmationButton, ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import moment from 'moment';
import { DataEditService } from 'app/shared/services/data-edit.service';
import { Subscription } from 'rxjs';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { TranslateService } from '@ngx-translate/core';
import {  IComponentDialog, IComponentDialogConfirmationResult } from 'app/shared/models/comopnent-cofirmation';

const formatDate = (format: string) => (m: Date) => moment(m).format(format);

export interface RecalculateEntitiesComponentRequest {
    startDate: Date,
    endDate: Date,
    locationId: number,
    customerId: number,
    isRawVelRecalculated: Boolean
}
export interface RecalculateEntitiesComponentResponse {
    success: boolean;
    startDate: Date,
    endDate: Date,
    locationId: number,
    customerId: number
}

@Component({
    selector: 'app-recalculate-entities',
    templateUrl: './recalculate-entities.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})
export class RecalculateEntitiesComponent {

    public maxDateValidationMsg: string;
    public errorMsg: string;
    public dateFormat: string;
    public minDateError: boolean;
    public cancelMsg: string;
    public recalQuantityConfirmationMessage: string;
    public recalcConfirmationTitle: string;
    public recalcQuantityMsgTitle: string
    public okMsg: string;
    private subscriptions = new Subscription();
    public minDate: Date = new Date("Novemeber 01, 2023 00:00:00");

    constructor(
        public dialogRef: MatDialogRef<RecalculateEntitiesComponent>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: RecalculateEntitiesComponentRequest,
        private dataEditService: DataEditService,
        private dateutilService: DateutilService,
        private snackBar: MatSnackBar,
        public translate: TranslateService,
    ) {}

    ngOnInit() {
        this.maxDateValidationMsg = this.translate.instant('COMMON.MAX_RECORD_DATE_VALIDATION');
        this.cancelMsg = this.translate.instant('COMMON.CANCEL');
        this.okMsg = this.translate.instant('COMMON.OK');
        this.recalQuantityConfirmationMessage = this.translate.instant('COMMON.RECALCULATE_CONFIRMATION_MSG');
        this.recalcConfirmationTitle = this.data.isRawVelRecalculated ? this.translate.instant('COMMON.RECALC_RAWVEL_DIALOG_TITLE') : this.translate.instant('COMMON.RECALC_QUANTITY_DIALOG_TITLE');
        this.recalcQuantityMsgTitle = this.translate.instant('COMMON.RECALCULATE_QUANTITY_CONFIRMATION_TITLE'); 
        const dateFormatSubs = this.dateutilService.dateFormat.subscribe(() => {
            this.dateFormat = String(this.dateutilService.getFormat().toUpperCase());
        });
        this.subscriptions.add(dateFormatSubs);
    }

    public dateChange(event: SimpleChanges) {
        const startDate = event.startDate.currentValue;
        this.minDateError = false;
        this.errorMsg = '';
        if(startDate < this.minDate){
            this.minDateError = true;
            this.errorMsg = this.maxDateValidationMsg + formatDate(this.dateFormat)(this.minDate) + '.';
        }
    }

    submit() {
        if(!this.data.isRawVelRecalculated){
            const dialogRef = this.dialog.open<ConfirmationDialogComponent,IComponentDialog,IComponentDialogConfirmationResult>(ConfirmationDialogComponent, {
                data: {
                    title: this.recalcQuantityMsgTitle,
                    message: this.recalQuantityConfirmationMessage,
                    cancelText: this.cancelMsg,
                    okText: this.okMsg,
                }
            });
    
            dialogRef.afterClosed().subscribe((res) => {
                if (res.whichButtonWasPressed === ConfirmationButton.cancel) {
                    return;
                }
                else{
                    this.dialogRef.close({
                        success: true,
                        ...this.data
                    });
                }
            });
        }
        else{
            this.dialogRef.close({
                success: true,
                ...this.data
            });
        }
        
    }
}
