export interface BatteryStatusReportArgs {
    CustomerID: number;
    LocationGroupID?: number;
    LocationID?: number[];
    Status?: string;
    IncludeInactiveLocations?: boolean;
    Sort: string;
    PageSize: number;
    StartPage: number;
    SearchValue: string;
}

export interface BatteryStatus {
    locationID: number;
    locationName: string;
    day: string;
    status: string;
    voltage: number;
    sparkLineOptions?: Object;
    seriesName?: string;
}

export class BatteryStatusList {
    public batteryStatusList: Array<BatteryStatus>;
    public batteryGraphStatusList: Array<BatteryGraph>;
    public count: number;
}

export class BatteryGraph {
    public status: string;
    public count: number;
}

export enum BatteryStatusEnum {
    Good = 'GOOD',
    Low = 'LOW',
    Critical = 'CRITICAL'
}
