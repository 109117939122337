<div id="slicerDialog">
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'SLICER_PAGE.ADD_NEWSTUDY' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <app-loader [isLoading]="isLoadingState"></app-loader>
    <form (ngSubmit)="initializeCaseStudy(addNewStudyForm.value)" #addNewStudyForm="ngForm" novalidate>
        <div mat-dialog-content>
            <div class="field top custom-error">
                <mat-form-field class="full-width">
                    <input
                        matInput
                        placeholder="{{ 'SLICER_PAGE.STUDY_NAME' | translate }}"
                        required
                        maxlength="64"
                        name="name"
                        #name="ngModel"
                        ngModel
                        (ngModelChange)="studyNameChange($event)"
                    />
                </mat-form-field>
                <div class="custom-error-message" *ngIf="existingStudyNameError">
                    {{ 'SLICER_PAGE.STUDY_NAME_EXISTS_ERROR' | translate }}
                </div>
                <mat-form-field class="full-width">
                    <input
                        matInput
                        placeholder="{{ 'SLICER_PAGE.DESCRIPTION' | translate }}"
                        name="description"
                        maxlength="64"
                        #description="ngModel"
                        ngModel
                    />
                </mat-form-field>
            </div>
        </div>
        <div mat-dialog-actions>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button mat-dialog-close>{{ 'SLIICER.COMMON.CANCEL_BUTTON_TITLE' | translate }}</button>
            </div>
            <div class="auto-margin">
                <button type="submit" [disabled]="!addNewStudyForm.valid || existingStudyNameError" mat-button>
                    {{ 'SLIICER.COMMON.SAVE_BUTTON_TITLE' | translate }}
                </button>
            </div>
        </div>
    </form>
</div>
