import { Component, OnInit, Inject, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef, LegacySimpleSnackBar as SimpleSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { CustomerService } from 'app/shared/services/customer.service';
import { MonitorSettingService } from 'app/shared/services/monitor-setting.service';
import { LocationService } from 'app/shared/services/location.service';
import { Subscription } from 'rxjs';
import { StringUtils } from 'app/shared/utils/string-utils';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { customerQueryParam } from 'app/shared/models/customer';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { MapService } from 'app/shared/services/map.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { EchoConfiguration, MonitorSetting, RainAlertConfiguration } from 'app/shared/models/monitorsetting';
import { AddLocation } from 'app/shared/models/add-location';

@Component({
    selector: 'monitor-settings',
    templateUrl: './monitor-settings.component.html',
    styleUrls: ['./monitor-settings.component.scss'],
})
export class MonitorSettingsComponent implements OnInit, OnDestroy {
    private subscriptions = new Array<Subscription>();
    private echoResponse: any;
    private response: any;
    private activeResponse: any;
    private customerID: number;
    public activateLocationLoadingState: boolean;

    public monitorSetting = new MonitorSetting();
    public addLocation = new AddLocation();
    /**
     * Reg Ex. Pattern for numeric Decimal value 2Place
     */
    public numericDecimal2PlacePattern = REGEX_CONFIG.numericDecimal2PlacePattern;
    /**
     * Reg Ex. Pattern for numeric Decimal value 2Place
     */
    public numeric1_1440Pattern = REGEX_CONFIG.numeric1_1440Pattern;
    /**
     * Reg Ex. Pattern for ip address
     */
    public ipaddressPattern = REGEX_CONFIG.ipaddressPattern;
    /**
     * Reg Ex. rainPerTip to validate the numeric value between 0 to 10000 with 6 decimal places
     */
    public rainPerTipPattern = REGEX_CONFIG.rainPerTip;
    /**
     * Reg Ex. Pattern for ip address
     */
    public numeric1_24Pattern = REGEX_CONFIG.numeric1_24Pattern;

    /**
     * To describe add location with name snackbar message based on translate
     */
    public addLocationWithNameMsg: string;

    /**
     * To describe adding location error message based on translate
     */
    public addLocationErroMsg: string;

    /**
     * location update success message based on translate
     */
    public locationConfigErrorSnackbar: string;

    /**
     * location update error message based on translate
     */
    public echoConfigErrorSnackbar: string;

    /**
     * location update error message based on translate
     */
    public locationActivationErrorSnackbar: string;

    /**
     * server error message based on translate
     */
    public serverErrorSnackBar: string;

    /**
     *dismiss button text in snackbar based on translate
     */
    public dismissButton: string;

    constructor(
        public dialogRef: MatDialogRef<MonitorSettingsComponent>,
        private customerService: CustomerService,
        private monitorSettingService: MonitorSettingService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private locationService: LocationService,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private uiUtilsService: UiUtilsService,
        private translate: TranslateService,
        private mapService: MapService,
        private statusCodeService: StatusCodeService,
        private route: ActivatedRoute,
    ) {
        const customerIDSuscription = this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            this.customerID = Number(params.get(customerQueryParam));
        });
        this.subscriptions.push(customerIDSuscription);

        translate.get('HOME.MAP.MONITOR_SETTINGS.ADDED_SUCCESS').subscribe((res: string) => {
            this.addLocationWithNameMsg = res;
        });
        translate.get('HOME.MAP.MONITOR_SETTINGS.ADDED_ERROR').subscribe((res: string) => {
            this.addLocationErroMsg = res;
        });
        translate.get('HOME.MAP.MONITOR_SETTINGS.ERROR_LOCATION_CONF_MSG').subscribe((res: string) => {
            this.locationConfigErrorSnackbar = res;
        });
        translate.get('HOME.MAP.MONITOR_SETTINGS.ECHO_ERROR_MSG').subscribe((res: string) => {
            this.echoConfigErrorSnackbar = res;
        });
        translate.get('HOME.MAP.MONITOR_SETTINGS.LOC_ACTIVATION_ERROR').subscribe((res: string) => {
            this.locationActivationErrorSnackbar = res;
        });
        translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismissButton = res;
        });
        translate.get('COMMON.SERVER_ERROR_MSG').subscribe((res: string) => {
            this.serverErrorSnackBar = res;
        });
    }

    public ngOnInit() {
        this.statusCodeService.addNewCustomer.subscribe((addNewCustomerFlag: boolean) => {
            // get current query params for customer
            if (addNewCustomerFlag) {
                this.route.queryParams.subscribe((params) => {
                    this.customerID = params['c'];
                });
            } else {
                this.route.queryParams.subscribe((params) => {
                    if (params['cid']) {
                        this.customerID = params['cid'];
                    }
                });
            }
        });
        this.startMonitorSettings();
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public checkRainIntensityReqd() {
        if (this.monitorSetting.logintensity || this.monitorSetting.logukintensity) {
            this.monitorSetting.isRainIntensity = true;
        } else {
            this.monitorSetting.isRainIntensity = false;
        }
    }
    /**
     * This Function Will Validate Input Field of Monitor Settings
     * It will remove all inValid valus such as e, whiteSpace from Input field
     * * */

    public inputValidation(controlName?: string, value?: any) {
        if (controlName === 'manholedepth') {
            this.monitorSetting.manholedepth = StringUtils.validTwoDecimalPlaces(value);
        } else if (controlName === 'physicaloffset') {
            this.monitorSetting.physicaloffset = StringUtils.validTwoDecimalPlaces(value);
        } else if (controlName === 'lowlevelthreshold') {
            this.monitorSetting.lowlevelthreshold = StringUtils.validTwoDecimalPlaces(value);
        } else if (controlName === 'highlevelthreshold') {
            this.monitorSetting.highlevelthreshold = StringUtils.validTwoDecimalPlaces(value);
        } else if (controlName === 'highhighthreshold') {
            this.monitorSetting.highhighthreshold = StringUtils.validTwoDecimalPlaces(value);
        } else if (controlName === 'tiltthreshold') {
            this.monitorSetting.tiltthreshold = StringUtils.validTwoDecimalPlaces(value);
        } else if (controlName === 'batterylowthreshold') {
            this.monitorSetting.batterylowthreshold = StringUtils.validTwoDecimalPlaces(value);
        }
    }

    public emitMonitorSettings() {
        this.dialogRef.close({ success: false });
    }

    public activateLocation() {
        this.activateLocationLoadingState = true;

        if (this.monitorSetting.datadeliveryipaddress === undefined) {
            this.monitorSetting.datadeliveryipaddress = '';
        }

        if (this.monitorSetting.intensityinterval === undefined) {
            this.monitorSetting.intensityinterval = 60;
        }

        const rainAlertConfiguration: RainAlertConfiguration = {
            rainpertip: this.monitorSetting.rainpertip,
            logintensity: this.monitorSetting.logintensity || false,
            logukintensity: this.monitorSetting.logukintensity || false,
            datalogmode: this.monitorSetting.datalogmode,
            intensityinterval: this.monitorSetting.intensityinterval,
            alarmenable: this.monitorSetting.alarmenable || false,
            alarmthreshold: this.monitorSetting.alarmthreshold || 0,
            alarmreturntonormal: this.monitorSetting.alarmreturntonormal || 0,
            serialnumber: this.monitorSetting.serialnumber,
            ipaddress: this.monitorSetting.ipaddress,
            locationid: this.monitorSetting.locationid,
            samplerate: this.monitorSetting.samplerate,
            timezone: this.monitorSetting.timezone,
            usedaylightsaving: this.monitorSetting.usedaylightsaving || true,
            prismdeliveryipaddress: this.monitorSetting.datadeliveryipaddress,
            prismdeliverynormal: this.monitorSetting.datadeliverynormal,
            prismdeliveryfast: this.monitorSetting.datadeliveryfast,
            storetemperature: this.monitorSetting.storetemperature || false,
            storebattery: this.monitorSetting.storebattery,
        };

        const subscriptionRainAlert = this.monitorSettingService
            .rainAlert(rainAlertConfiguration, this.customerID)
            .subscribe(
                (res) => {
                    this.response = res;
                    this.activateLocationLoadingState = false;
                    if (this.response !== undefined && this.response !== '') {
                        const snackbarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(
                            this.response.responseMessage,
                            'Dismiss',
                        );
                        setTimeout(snackbarRef.dismiss.bind(snackbarRef), 10000);
                        this.activateMonitor(
                            this.customerID,
                            this.monitorSetting.locationid,
                            this.data.SerialNumber,
                            this.data.IpAddress,
                        );
                    } else {
                        /* let snackbarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open('Error in location configuration.', 'Dismiss');
          setTimeout(snackbarRef.dismiss.bind(snackbarRef), 3000); */
                        this.snackBar.open(this.locationConfigErrorSnackbar, this.dismissButton, {
                            panelClass: 'custom-error-snack-bar',
                        });
                    }
                },
                (error) => {
                    // disable the loader
                    this.activateLocationLoadingState = false;

                    // display the snack message
                    /* this.snackBar.open('Error at server end.', 'Dismiss', {
          duration: 3000
        }); */
                    this.snackBar.open(this.serverErrorSnackBar, this.dismissButton, {
                        panelClass: 'custom-error-snack-bar',
                    });
                },
            );
        this.subscriptions.push(subscriptionRainAlert);
    }

    // tslint:disable-next-line:cyclomatic-complexity
    public echoMonitorConfiguration() {
        this.activateLocationLoadingState = true;

        this.monitorSetting.batterylowthreshold = this.monitorSetting.batterylowthreshold
            ? this.monitorSetting.batterylowthreshold
            : 0;

        if (this.monitorSetting.datadeliveryipaddress === undefined) {
            this.monitorSetting.datadeliveryipaddress = '';
        }

        const echoConfiguration: EchoConfiguration = {
            fastrate: this.monitorSetting.fastrate || 0,
            manholedepth: this.monitorSetting.manholedepth || 0,
            pipeheight: this.monitorSetting.pipeHeight || 0,
            physicaloffset: this.monitorSetting.physicaloffset || 0,
            unidepthtoaverage: this.monitorSetting.unidepthtoaverage || 0,
            lowlevelenable: this.monitorSetting.lowlevelenable || false,
            lowlevelthreshold: this.monitorSetting.lowlevelthreshold || 0,
            fullpipeenable: this.monitorSetting.fullpipeenable || false,
            highlevelenable: this.monitorSetting.highlevelenable || false,
            highlevelthreshold: this.monitorSetting.highlevelthreshold || 0,
            highhighenable: this.monitorSetting.highhighenable || false,
            highhighthreshold: this.monitorSetting.highhighthreshold || 0,
            overflowenable: this.monitorSetting.overflowenable || false,
            tiltenable: this.monitorSetting.tiltenable || false,
            tiltthreshold: this.monitorSetting.tiltthreshold || 0,
            batterylowenable: this.monitorSetting.batterylowenable || false,
            batterylowthreshold: this.monitorSetting.batterylowthreshold || 0,
            serialNumber: this.monitorSetting.serialnumber || '',
            ipaddress: this.monitorSetting.ipaddress || '',
            locationid: this.monitorSetting.locationid || 0,
            samplerate: this.monitorSetting.samplerate || 0,
            timezone: this.monitorSetting.timezone || '',
            usedaylightsaving: this.monitorSetting.usedaylightsaving || true,
            prismdeliveryipaddress: this.monitorSetting.datadeliveryipaddress,
            prismdeliverynormal: this.monitorSetting.datadeliverynormal || 0,
            prismdeliveryfast: this.monitorSetting.datadeliveryfast || 0,
            storetemperature: this.monitorSetting.storetemperature || false,
            storebattery: this.monitorSetting.storebattery || 1,
            modem: this.monitorSetting.modem,
        };

        const subscriptionMonitorConf = this.monitorSettingService
            .echoMonitorConfiguration(echoConfiguration, this.customerID)
            .subscribe(
                (res) => {
                    this.echoResponse = res;
                    this.activateLocationLoadingState = false;
                    if (this.echoResponse !== undefined && this.echoResponse !== '') {
                        const snackbarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(
                            this.echoResponse.responseMessage,
                            'Dismiss',
                        );
                        setTimeout(snackbarRef.dismiss.bind(snackbarRef), 3000);
                        this.activateMonitor(
                            this.customerID,
                            this.monitorSetting.locationid,
                            this.data.SerialNumber,
                            this.data.IpAddress,
                        );
                    } else {
                        /* let snackbarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open('Error in ECHO configuration.', 'Dismiss');
          setTimeout(snackbarRef.dismiss.bind(snackbarRef), 3000); */
                        this.snackBar.open(this.echoConfigErrorSnackbar, this.dismissButton, {
                            panelClass: 'custom-error-snack-bar',
                        });
                    }
                },
                (error) => {
                    // disable the loader
                    this.activateLocationLoadingState = false;

                    // display the snack message
                    /* this.snackBar.open('Error at server end.', 'Dismiss', {
          duration: 3000
        }); */
                    this.snackBar.open(this.serverErrorSnackBar, this.dismissButton, {
                        panelClass: 'custom-error-snack-bar',
                    });
                },
            );
        this.subscriptions.push(subscriptionMonitorConf);
    }

    /**
     * set battery low value to default i.e; 6.00
     * @param event checkbox change event
     */
    public setBatteryLow(event: MatCheckboxChange) {
        if (event.checked && this.data.MonitorSeries.toLocaleUpperCase() === 'ECHO') {
            this.monitorSetting.batterylowthreshold = 6.0;
        } else {
            this.monitorSetting.batterylowthreshold = 0;
        }
    }

    private activateMonitor(customerId: number, locationId: number, serialNumber: string, ipAddress: string) {
        this.activateLocationLoadingState = true;
        const subscriptionActivate = this.monitorSettingService
            .activateLocation(customerId, locationId, serialNumber, ipAddress)
            .subscribe(
                (result) => {
                    this.activeResponse = result;
                    this.activateLocationLoadingState = false;
                    if (this.activeResponse !== undefined && this.activeResponse !== '') {
                        const snackbarRefAct: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(
                            this.activeResponse.responseMessage,
                            this.dismissButton,
                        );
                        setTimeout(snackbarRefAct.dismiss.bind(snackbarRefAct), 10000);
                        this.emitMonitorSettings();
                    } else {
                        /* let snackbarRefAct: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open('Error in location activation.', 'Dismiss');
          setTimeout(snackbarRefAct.dismiss.bind(snackbarRefAct), 3000); */
                        this.snackBar.open(this.locationActivationErrorSnackbar, this.dismissButton, {
                            panelClass: 'custom-error-snack-bar',
                        });
                    }
                },
                (error) => this.handleMonitorError(error),
            );
        this.subscriptions.push(subscriptionActivate);
    }

    private handleMonitorError(error: any) {
        console.log(error);
        this.activateLocationLoadingState = false;
        if (error._body.type === 'error') {
            const snackbarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(
                this.serverErrorSnackBar,
                this.dismissButton,
            );
        } else {
            /* let snackbarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(JSON.parse(error._body).responseMessage, 'Dismiss');
      setTimeout(snackbarRef.dismiss.bind(snackbarRef), 3000); */
            this.snackBar.open(JSON.parse(error._body).responseMessage, this.dismissButton, {
                panelClass: 'custom-error-snack-bar',
            });
        }
    }

    // open location detail pop up when marker is clicked
    public getMarkerLocationDetails(data: any) {
        const customerId = this.data.customerId;
        const location = this.data.locationDetail;

        const locationsSubscription = this.mapService
            .getMarkerLocationDetails(location.locationId, customerId)
            .subscribe(
                (result: any) => {
                    if (result) {
                        this.data = {
                            customerId: customerId,
                            LocationName: result.locationName,
                            Description: result.description,
                            ManholeAddress: result.manholeAddress,
                            Latitude: result.coordinate ? result.coordinate.latitude : '',
                            Longitude: result.coordinate ? result.coordinate.latitude : '',
                            IsActiveLocation: result.isActive,
                            MonitorSeries: result.series,
                            SerialNumber: result.serialNumber,
                            IpAddress: result.ipaddress,
                            locationID: result.locationID,
                            instalationType: result.installationType,
                        };
                        this.startMonitorSettings();
                    }
                },
                (error) => {},
            );
        this.subscriptions.push(locationsSubscription);
    }

    public startMonitorSettings() {
        this.monitorSetting.monitorSeries = this.data.MonitorSeries.toLocaleUpperCase();
        this.monitorSetting.rainExceedingFlag = true;
        this.monitorSetting.logintensity = false;
        this.monitorSetting.logukintensity = false;
        this.monitorSetting.usedaylightsaving = false;
        this.monitorSetting.datadeliveryfastrate = 0;
        this.monitorSetting.datadeliverynormalrate = 0;
        this.monitorSetting.alarmenable = false;

        this.monitorSetting.lowlevelenable = false;
        this.monitorSetting.fullpipeenable = false;
        this.monitorSetting.highlevelenable = false;
        this.monitorSetting.highhighenable = false;
        this.monitorSetting.overflowenable = false;
        this.monitorSetting.tiltenable = false;
        this.monitorSetting.batterylowenable = false;
        this.monitorSetting.pipeHeight = 0;

        this.monitorSetting.datadeliverynormal = 0;
        this.monitorSetting.datadeliveryfast = 0;
        this.monitorSetting.unidepthtoaverage = 1;

        this.monitorSetting.SampleRateUI = [
            { value: 60, text: '1 minute' },
            { value: 120, text: '2 minutes' },
            { value: 300, text: '5 minutes' },
            { value: 900, text: '15 minutes' },
            { value: 1800, text: '30 minutes' },
            { value: 3600, text: '1 hour' },
            { value: 7200, text: '2 hours' },
            { value: 43200, text: '12 hours' },
            { value: 86400, text: '24 hours' },
        ];
        this.monitorSetting.NormalRate = [
            { value: 900, text: '15 minutes' },
            { value: 3600, text: '1 hour' },
            { value: 7200, text: '2 hours' },
            { value: 10800, text: '3 hours' },
            { value: 14400, text: '4 hours' },
            { value: 21600, text: '6 hours' },
            { value: 28800, text: '8 hours' },
            { value: 43200, text: '12 hours' },
            { value: 86400, text: '24 hours' },
        ];
        this.monitorSetting.FastRate = [
            { value: 300, text: '5 minutes' },
            { value: 900, text: '15 minutes' },
            { value: 3600, text: '1 hour' },
            { value: 7200, text: '2 hours' },
            { value: 10800, text: '3 hours' },
            { value: 14400, text: '4 hours' },
            { value: 21600, text: '6 hours' },
            { value: 28800, text: '8 hours' },
            { value: 43200, text: '12 hours' },
            { value: 86400, text: '24 hours' },
        ];
        this.monitorSetting.DataLogMode = [
            { value: 0, text: 'Synchronous' },
            { value: 1, text: 'Asynchronous' },
        ];
        this.customerService.getTimeZone().subscribe(
            (res) => {
                this.monitorSetting.TimeZone = res;
            },
            (error) => {
                // error block
            },
        );

        if (this.data.locationID === -1) {
            if (this.data.instalationType === 'Pipe') {
                this.addLocation.pipe = this.data.typeOfInstallation;
            } else if (this.data.instalationType === 'Rain') {
                this.addLocation.rain = this.data.typeOfInstallation;
            } else if (this.data.instalationType === 'Flume') {
                this.addLocation.flume = this.data.typeOfInstallation;
            }
            this.addLocation.locationName = this.data.LocationName;
            this.addLocation.description = this.data.Description || '';
            this.addLocation.manholeAddress = this.data.ManholeAddress || '';
            this.addLocation.latitude = this.data.Latitude || 0;
            this.addLocation.longitude = this.data.Longitude || 0;
            this.addLocation.isActive = this.data.IsActiveLocation;
            this.addLocation.series = this.data.MonitorSeries;
            this.addLocation.serialNumber = this.data.SerialNumber;
            this.addLocation.IPAddress = this.data.IpAddress;
            this.addLocation.manholedepth = this.data.manholedepth;

            // Creating location based on provided input at add location form.
            const subscriptionAddLocation = this.locationService
                .addLocation(this.data.customerId, this.addLocation, this.data.instalationType)
                .subscribe(
                    (res) => {
                        this.monitorSetting.locationid = res;
                        if (this.monitorSetting.locationid !== undefined && this.monitorSetting.locationid > 0) {
                            const snackbarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(
                                this.data.LocationName + this.addLocationWithNameMsg,
                                this.dismissButton,
                            );
                            setTimeout(snackbarRef.dismiss.bind(snackbarRef), 10000);
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                        } else {
                            /* let snackbarRef: MatSnackBarRef<SimpleSnackBar> =
              this.snackBar.open('Unable to added location, please try again.', 'Dismiss');
              setTimeout(snackbarRef.dismiss.bind(snackbarRef), 3000); */
                            this.snackBar.open(this.addLocationErroMsg, this.dismissButton, {
                                panelClass: 'custom-error-snack-bar',
                            });
                        }
                    },
                    (error) => {
                        // error block
                    },
                );
            this.subscriptions.push(subscriptionAddLocation);
        } else if (this.data.locationID > 0) {
            this.monitorSetting.locationid = this.data.locationID;
        }
        this.monitorSetting.customerId = this.customerID;
        this.monitorSetting.serialnumber = this.data.SerialNumber;
        this.monitorSetting.ipaddress = this.data.IpAddress;

        this.monitorSetting.echoFastRate = [
            { value: 60, text: '1 minute' },
            { value: 120, text: '2 minutes' },
            { value: 300, text: '5 minutes' },
            { value: 900, text: '15 minutes' },
            { value: 1800, text: '30 minutes' },
            { value: 3600, text: '1 hour' },
            { value: 7200, text: '2 hours' },
            { value: 43200, text: '12 hours' },
            { value: 86400, text: '24 hours' },
        ];
        this.monitorSetting.echoNormalRate = [
            { value: 900, text: '15 minutes' },
            { value: 1800, text: '30 minutes' },
            { value: 3600, text: '1 hour' },
            { value: 7200, text: '2 hours' },
            { value: 10800, text: '3 hours' },
            { value: 14400, text: '4 hours' },
            { value: 21600, text: '6 hours' },
            { value: 28800, text: '8 hours' },
            { value: 43200, text: '12 hours' },
            { value: 86400, text: '24 hours' },
        ];
        this.monitorSetting.echoDataFastRate = [
            { value: 900, text: '15 minutes' },
            { value: 1800, text: '30 minutes' },
            { value: 3600, text: '1 hour' },
            { value: 7200, text: '2 hours' },
            { value: 10800, text: '3 hours' },
            { value: 14400, text: '4 hours' },
            { value: 21600, text: '6 hours' },
            { value: 28800, text: '8 hours' },
            { value: 43200, text: '12 hours' },
            { value: 86400, text: '24 hours' },
        ];

        // make the storeTemperature false - default
        this.monitorSetting.storetemperature = false;
        this.monitorSetting.storeBatteryVoltageUI = [
            { value: 0, text: 'Store at Sample Rate' },
            { value: 1, text: 'Store at Midnight' },
        ];

        // set Store at Midnight as default selected value on UI.
        this.monitorSetting.storebattery = this.monitorSetting.storeBatteryVoltageUI[1].value;
    }
}
