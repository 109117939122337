<div id="gis-layers">
    <mat-card-header>
        <div class="search-section auto-margin">Available Services:</div>
        <div class="app-flex-filler"></div>
    </mat-card-header>
    <mat-card-content>
        <!-- GIS layer table -->
        <div id="gispagetable">
            <mat-table #table [dataSource]="gisDataSource" matSort>
                <!-- GIS layer table column 1 -->
                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef>Service Type</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ mapTypeDisplayNames[element.serviceType] }}</mat-cell>
                </ng-container>
                <!-- GIS layer table column 2 -->
                <ng-container matColumnDef="url">
                    <mat-header-cell *matHeaderCellDef>Service Url</mat-header-cell>
                    <mat-cell class="description-space" *matCellDef="let element">{{ element.url }} </mat-cell>
                </ng-container>
                <!-- GIS layer table edit icon -->
                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef class="fixCellWidth"></mat-header-cell>
                    <mat-cell *matCellDef="let element" class="text-right">
                        <mat-icon matTooltip="Edit" class="edit-cus-gis-edit-hint" (click)="editGISService(element)">mode_edit</mat-icon>
                        <mat-icon matTooltip="Delete" (click)="deleteGISService(element.serviceId)">delete</mat-icon>
                    </mat-cell>
                </ng-container>
                <!-- GIS layer table delete icon -->

                <mat-header-row *matHeaderRowDef="gisDescriptionColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: gisDescriptionColumns"></mat-row>
            </mat-table>
        </div>
    </mat-card-content>
    <!-- GIS layer table add location icon -->
    <mat-card-actions>
        <div class="auto-margin">
            <button
                mat-button
                class="edit-cus-gis-add-hint"
                color="accent"
                matTooltip="{{ 'CUSTOMER_EDITOR.GIS_ADD_LAYER' | translate }}"
                matTooltipPosition="above"
                (click)="addNewGISService()"
            >
                Add More
            </button>
        </div>
        <div class="app-flex-filler"></div>
        <div class="paginator" *ngIf="false"></div>
        <mat-paginator *ngIf="false" [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
    </mat-card-actions>
    <!-- Manhole Service Detail -->
    <!-- Hide this until can be fixed
	<h3>Manhole</h3>
	<div class="custom-25p">
		<div class="each-Item">
			<mat-form-field class="full-width">
				<mat-select placeholder="Select Service" [(ngModel)]="gisManholeServiceId"
					(selectionChange)="manholeLayerload()" class="full-width" required>
					<mat-option *ngFor="let service1 of gisDataSource" [value]="service1">{{service1.url}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="each-Item">
			<mat-form-field class="full-width">
				<mat-select placeholder="Layer" [(ngModel)]="manholeLayer" (selectionChange)="manholeAttrLoad()"
					class="full-width" required>
					<mat-option *ngFor="let layer of manholeLayerLists" [value]="layer.name">{{layer.title}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="each-Item">
			<mat-form-field class="full-width">
				<mat-select placeholder="Pipe Joint Attribute" [(ngModel)]="manholeAttr" class="full-width" required>
					<mat-option *ngFor="let attr of manholeAttrList" [value]="attr.name">{{attr.name}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
	-->

    <!-- Pipe Service Detail -->
    <!-- Hide this until can be fixed
	<h3>Pipe:</h3>
	<div class="custom-25p">
		<div class="each-Item">
			<mat-form-field class="full-width">
				<mat-select placeholder="Layer" [(ngModel)]="gisPipeServiceId" class="full-width"
					(selectionChange)="pipeLayerload()" required>
					<mat-option *ngFor="let service2 of gisDataSource" [value]="service2">{{service2.url}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="each-Item">
			<mat-form-field class="full-width">
				<mat-select placeholder="Layer" [(ngModel)]="pipeLayer" (selectionChange)="pipeAttr1Load()"
					class="full-width" required>
					<mat-option *ngFor="let pipelayers of pipeLayerList" [value]="pipelayers.name">{{pipelayers.title}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="each-Item">
			<mat-form-field class="full-width">
				<mat-select placeholder="Pipe Join Attribute" [(ngModel)]="pipeAttr" class="full-width" required>
					<mat-option *ngFor="let attr1 of pipeAttrList" [value]="attr1.name">{{attr1.name}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="each-Item">
			<mat-form-field class="full-width">
				<mat-select placeholder="Pipe Join Attribute" [(ngModel)]="pipeAttr1" class="full-width" required>
					<mat-option *ngFor="let attr2 of pipeAttrList" [value]="attr2.name">{{attr2.name}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
	-->

    <!-- Basin Service Detail -->
    <!-- Hide this until can be fixed
	<h3>Basin:</h3>
	<div class="custom-25p">
		<div class="each-Item">
			<mat-form-field class="full-width">
				<mat-select placeholder="Service" [(ngModel)]="gisBasinServiceId" class="full-width"
					(selectionChange)="basinLayerload()" required>
					<mat-option *ngFor="let service3 of gisDataSource" [value]="service3">{{service3.url}}</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="each-Item">
			<mat-form-field class="full-width">
				<mat-select placeholder="Layer" [(ngModel)]="basinLayer" class="full-width" required>
					<mat-option *ngFor="let basinlayer of basinLayerList" [value]="basinlayer.name">{{basinlayer.title}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
	-->

    <!-- Card Action for Saving Detail -->
    <mat-card-actions>
        <div class="auto-margin action-right">
            <button
                mat-button
                color="accent"
                matTooltip="{{ 'CUSTOMER_EDITOR.SAVE_GIS_SETTINGS' | translate }}"
                matTooltipPosition="above"
                (click)="saveGISLayerMappingData()"
            >
                Save
            </button>
        </div>
        <div class="app-flex-filler"></div>
        <div class="paginator" *ngIf="false"></div>
        <mat-paginator *ngIf="false" [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
        </mat-paginator>
    </mat-card-actions>
</div>
