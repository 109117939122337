import { Component, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LocationCardService } from '../../services/location-card.service';
import { LocationFormBuilder } from '../../services/location-form-builder';
import { MonitorSeriesNames } from '../../location-card.constants';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';

@Component({
    selector: 'app-installation-type-info',
    templateUrl: './installation-type-info.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})

export class InstallationTypeInfoComponent {
    @Input() form: FormGroup;
    @Input() type: any;
    @Input() installationType: number;
    @Input() monitorSeries: string;
    @Output() pipeHeightChange = new EventEmitter();

    public isMetric: Boolean;

    public REGEX_CONFIG = REGEX_CONFIG;

    public MonitorSeriesNames = MonitorSeriesNames;
    constructor(
        private locationFormBuilder: LocationFormBuilder,
        public translateService: TranslateService,
        public locationCardService: LocationCardService
    ) {}

    ngOnInit() {
        this.isMetric = this.locationCardService.isMetric;

			if (this.form && this.form.value && this.form.value.entries && this.form.value.entries.length) {
				this.form.value.entries.forEach((v, i) => this.onInputChange(i));
			}
    }

    getEntriesControls() {
        return this.form.get('entries') as FormArray;
    }

    public onPipeValueChange(event, isHeight = false) {
        if (this.installationType !== 200) {
            return;
        }

        const { value } = event.target;
        const installationValue = this.type.value;

        const installationSeries = installationValue.match(/(\d+)/)[0];

        if (installationSeries === '200') {
            this.form.patchValue({ height: value, width: value });
        }

        if (isHeight) {
            this.pipeHeightChange.emit(value);
        }
    }

    public onEntitiesChange(value: string | number) {
        value = Number(value);
        if (typeof value !== 'number' || isNaN(value) || value < 2 || value > 257) {
          return;
        }

        const entries = this.getEntriesControls();
        const currentLength = entries.length ?? 0;
        const shouldAdd = currentLength < value;

        if (shouldAdd) {
          const difference = value - currentLength;

          for (let i = 0; i < difference; i++) {
            entries.push(this.locationFormBuilder.generateEntitiesInfo(this.isMetric));
          }
        } else {
          const difference = currentLength - value;

          for (let i = 0; i < difference; i++) {
            entries.removeAt(currentLength - (i + 1));
          }
        }
    }

	  // used to enforce decimal values to channel depth and quantity, 1 for metric and 2 for us standart
    onInputChange(index: number) {
      const entries = this.getEntriesControls();

			if (!entries || !entries.controls[index]) {
				return;
			}

			const depthValue = entries.value[index].depth;
			const quantityValue = entries.value[index].quantity;

			if (!isNaN(Number(depthValue)) && depthValue) {
        entries.controls[index].patchValue({ depth: Number(depthValue).toFixed(this.isMetric ? 1 : 2) });
			}

      if (!isNaN(Number(quantityValue)) && quantityValue) {
        entries.controls[index].patchValue({ quantity: Number(quantityValue).toFixed(this.isMetric ? 1 : 3) });
			}
    }
}
