<div id="percentFullReportId" class="contentLayout">
    <mat-card class="percent-full-content">
        <app-loader [isLoading]="percentFullLoading"></app-loader>

        <mat-card-header>
            <div class="auto-margin percentTitleHint">
                {{ 'REPORT.PERCENT_FULL.OVERVIEW_TITLE' | translate }}
            </div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin custom-flat-button">
                <button
                    class="percentFullDownloadHint"
                    (click)="downloadCSV()"
                    mat-button
                    [disabled]="!percentFullReportData || percentFullReportData?.percentFullValues.length === 0"
                >
                    {{ 'COMMON.DOWNLOAD_BTN' | translate }}
                </button>
            </div>
            <div class="auto-margin pad-r-15">
                <mat-icon
                    matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                    class="backIcon percentFullFilterHint"
                    [ngClass]="{ active: displayFilters }"
                    (click)="showReportFilter()"
                    >filter_list</mat-icon
                >
            </div>
        </mat-card-header>

        <div id="percentFullReportFilterId">
            <app-widget-filters
                (sendDataToWidget)="notifyReport($event)"
                [customerID]="customerID"
                [locationGroupID]="locationGroupID"
                [filterSettings]="percentFullFilterSettings"
                [includeInactiveLocations]="includeInactiveLocations"
                [hidden]="!displayFilters"
            >
            </app-widget-filters>
        </div>
        <mat-card-content>
            <div *ngIf="showPercentageFullReport">
                <div class="tableScrollWraper">
                    <table
                        matSort
                        (matSortChange)="sortPercentFullData($event)"
                        *ngIf="percentFullReportData?.percentFullValues?.length > 0"
                        class="
                            light-table light-table-bordered light-table-hover light-table-responsive-vertical
                            black-theme-table
                        "
                    >
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th class="text-align-center percent-yesterday-hint" colspan="2" *ngIf="dateFormat">
                                    {{ yesterDayDate | date: dateFormat }}
                                </th>
                                <th class="text-align-center percent-date-column-hint" colspan="2" *ngIf="dateFormat">
                                    {{ startDate | date: dateFormat }} - {{ endDate | date: dateFormat }}
                                </th>
                            </tr>
                            <tr>
                                <th mat-sort-header="Location">{{ 'COMMON.LOCATION_TEXT' | translate }}</th>
                                <th>{{ 'REPORT.PERCENT_FULL.INSTALLATION_TYPE' | translate }}</th>
                                <th class="text-right">{{ 'REPORT.PERCENT_FULL.AVERAGE_TEXT' | translate }}</th>
                                <th class="text-right">{{ 'REPORT.PERCENT_FULL.MAXIMUM_TEXT' | translate }}</th>
                                <th class="text-right">{{ 'REPORT.PERCENT_FULL.AVERAGE_TEXT' | translate }}</th>
                                <th class="text-right">{{ 'REPORT.PERCENT_FULL.MAXIMUM_TEXT' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let percentFullValue of pageData; let i = index">
                                <td class="word-break" [ngClass]="{ 'percent-item-hint': i === 0 }">
                                    {{ percentFullValue.locationName }}
                                </td>
                                <td class="word-break">{{ getInstallationType(percentFullValue) }}</td>
                                <td class="text-right">
                                    {{
                                        percentFullValue.spans[0].average !== 0
                                            ? percentFullValue.spans[0].average
                                            : '-'
                                    }}
                                </td>
                                <td class="text-right">
                                    {{
                                        percentFullValue.spans[0].maximum !== 0
                                            ? percentFullValue.spans[0].maximum
                                            : '-'
                                    }}
                                </td>
                                <td class="text-right">
                                    {{
                                        percentFullValue.spans[2].average !== 0
                                            ? percentFullValue.spans[2].average
                                            : '-'
                                    }}
                                </td>
                                <td class="text-right">
                                    {{
                                        percentFullValue.spans[2].maximum !== 0
                                            ? percentFullValue.spans[2].maximum
                                            : '-'
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <mat-paginator
                    [length]="percentFullReportData.percentFullValues.length"
                    [pageSize]="10"
                    (page)="onPaginateChange($event)"
                    [pageSizeOptions]="[10, 25, 100]"
                    *ngIf="percentFullReportData.percentFullValues.length > 10"
                >
                </mat-paginator>
            </div>
            <p
                *ngIf="!showPercentageFullReport && !percentFullLoading && isCustomerMessage"
                class="noPerfectFullDataFound"
            >
                {{ 'REPORT.NO_DATA_IN_OVERVIEW' | translate }}
            </p>
            <p
                *ngIf="!showPercentageFullReport && !percentFullLoading && !isCustomerMessage"
                class="noPerfectFullDataFound"
            >
                {{ 'REPORT.NO_DATA_MESSAGE' | translate }}
            </p>
        </mat-card-content>
    </mat-card>
</div>
