import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'customTimePipe',
})
export class CustomTimePipe implements PipeTransform {
    public transform(value: number): string {
        if (value > 0 && value / 60 < 1) {
            if (value === 1) {
                return `${value} Minute`;
            }
            return `${value} Minutes`;
        } else if (value > 0 && value / 60 < 24) {
            if (value / 60 === 1) {
                return `${value / 60} Hour`;
            }
            return `${value / 60} Hours`;
        } else {
            if (value / 60 === 24) {
                return `${value / (60 * 24)} Day`;
            }
            return `${value / (60 * 24)} Days`;
        }
    }
}
