<div id="terms-dialog">
    <div class="title" mat-dialog-title>
        <img src="/assets/images/new_prism_logo.png" alt="ADS PRISM" class="logo" />
        <div class="mediumHeaderText">{{ 'ABOUT_US.HEADER' | translate }}</div>
    </div>
    <div mat-dialog-content>
        <p>{{ 'TERMS.DESCRIPTION' | translate }}</p>
        <form [formGroup]="termsForm">
            <mat-checkbox formControlName="userAgreement">
                {{ 'TERMS.I_AGREE' | translate }}
                <a href="https://www.adsenv.com/prismuseragreement/" target="_blank">{{
                    'COMMON.USER_AGREEMENT' | translate
                }}</a>
            </mat-checkbox>
            <mat-checkbox formControlName="privacyPolicy">
                {{ 'TERMS.I_AGREE' | translate }}
                <a href="https://www.adsenv.com/prismprivacypolicy/" target="_blank">{{
                    'COMMON.PRIVACY_POLICY' | translate
                }}</a>
            </mat-checkbox>
        </form>
    </div>
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button [disabled]="termsForm.invalid" (click)="onClose()">
                {{ 'COMMON.AGREE' | translate }}
            </button>
        </div>
    </div>
</div>
