<div id="navBarContainer" *ngIf="menuItem.show">
    <a
        mat-button
        *ngIf="hasExternalLink"
        class="each-nav-item"
        (click)="clicked($event)"
        [href]="menuItem.link"
        [class.active]="selectedItem === menuItem.title"
    >
        <mat-icon *ngIf="showIcon">{{ menuItem.icon }}</mat-icon>
        <span class="title">{{ menuItem.title | translate }}</span>
    </a>

    <a
        mat-button
        *ngIf="!hasLink && !hasChildren && !hasQuery && !hasExternalLink"
        class="each-nav-item"
        (click)="clicked($event)"
        [class.active]="selectedItem === menuItem.title"
    >
        <mat-icon *ngIf="showIcon">{{ menuItem.icon }}</mat-icon>
        <span class="title">{{ menuItem.title | translate }}</span>
    </a>

    <a
        mat-button
        *ngIf="hasLink && !hasChildren && !hasQuery && !hasExternalLink && menuItem.isAdmin"
        class="each-nav-item"
        [class.active]="selectedItem === menuItem.title"
        [ngClass]="{ flowBalance: menuItem.title === 'NAVIGATION.FLOW_BALANCE' }"
        (toggle)="(true)"
        (click)="clicked($event)"
    >
        <mat-icon *ngIf="showIcon">{{ menuItem.icon }}</mat-icon>
        <span class="title" [ngClass]="{ 'no-text-case': menuItem.title === 'NAVIGATION.SLICER' }"
            >{{ menuItem.title | translate }}<span *ngIf="menuItem.title === 'NAVIGATION.SLICER'">&reg;</span></span
        >
    </a>

    <a
        mat-button
        *ngIf="hasLink && !hasChildren && hasQuery && !hasExternalLink && menuItem.isAdmin"
        class="each-nav-item"
        [class.active]="selectedItem === menuItem.title"
        (toggle)="(true)"
        (click)="clicked($event)"
    >
        <mat-icon *ngIf="showIcon">{{ menuItem.icon }}</mat-icon>
        <span class="title" [ngClass]="{ 'no-text-case': menuItem.title === 'NAVIGATION.SLICER' }"
            >{{ menuItem.title | translate }}<span *ngIf="menuItem.title === 'NAVIGATION.SLICER'">&reg;</span></span
        >
    </a>

    <a
        mat-button
        *ngIf="hasChildren && menuItem.isAdmin && menuItem.title === 'NAVIGATION.DASHBOARDS'"
        class="each-nav-item custom-nav-dropdown"
        [class.active]="selectedItem === 'menuDashboard'"
    >
        <mat-icon *ngIf="showIcon">{{ menuItem.icon }}</mat-icon>
        <span class="title">{{ menuItem.title | translate }}</span>
        <span class="app-flex-filler"></span>
        <mat-icon *ngIf="!menuItem.showOnly">arrow_drop_down</mat-icon>
        <i class="material-icons" *ngIf="!menuItem.showOnly"></i>

        <div class="custom-nav-dropdown-content">
            <app-nav-item
                *ngFor="let menuItemChild of menuItem.children"
                [menuItem]="menuItemChild"
                [level]="level + 1"
                [parent]="this"
            ></app-nav-item>
        </div>
    </a>

    <a
        mat-button
        *ngIf="hasChildren && menuItem.isAdmin && menuItem.title === 'NAVIGATION.REPORTS'"
        class="each-nav-item custom-nav-dropdown"
        [class.active]="selectedItem === 'report'"
    >
        <mat-icon *ngIf="showIcon">{{ menuItem.icon }}</mat-icon>
        <span class="title">{{ menuItem.title | translate }}</span>
        <span class="app-flex-filler"></span>
        <mat-icon *ngIf="!menuItem.showOnly">arrow_drop_down</mat-icon>
        <i class="material-icons" *ngIf="!menuItem.showOnly"></i>
        <div class="custom-nav-dropdown-content">
            <app-nav-item
                *ngFor="let menuItemChild of menuItem.children"
                [menuItem]="menuItemChild"
                [level]="level + 1"
                [parent]="this"
            ></app-nav-item>
        </div>
    </a>
    <a
        mat-button
        *ngIf="hasChildren && menuItem.isAdmin && menuItem.title === 'NAVIGATION.ANALYTICS'"
        class="each-nav-item custom-nav-dropdown"
        [class.active]="selectedItem === 'sliicer'"
    >
        <mat-icon *ngIf="showIcon">{{ menuItem.icon }}</mat-icon>
        <span class="analyticSvgIcon"></span>
        <span class="title">{{ menuItem.title | translate }}</span>
        <span class="app-flex-filler"></span>
        <mat-icon *ngIf="!menuItem.showOnly">arrow_drop_down</mat-icon>
        <i class="material-icons" *ngIf="!menuItem.showOnly"></i>
        <div class="custom-nav-dropdown-content">
            <app-nav-item
                *ngFor="let menuItemChild of menuItem.children"
                [menuItem]="menuItemChild"
                [level]="level + 1"
                [parent]="this"
            ></app-nav-item>
        </div>
    </a>
    <a
        mat-button
        *ngIf="hasChildren && menuItem.isAdmin && menuItem.title === 'NAVIGATION.ADMIN'"
        class="each-nav-item custom-nav-dropdown"
        [class.active]="selectedItem === 'admin'"
    >
        <mat-icon *ngIf="showIcon">{{ menuItem.icon }}</mat-icon>
        <span class="title">{{ menuItem.title | translate }}</span>
        <span class="app-flex-filler"></span>
        <mat-icon *ngIf="!menuItem.showOnly">arrow_drop_down</mat-icon>
        <i class="material-icons" *ngIf="!menuItem.showOnly"></i>
        <div class="custom-nav-dropdown-content">
            <app-nav-item
                *ngFor="let menuItemChild of menuItem.children"
                [menuItem]="menuItemChild"
                [level]="level + 1"
                [parent]="this"
            ></app-nav-item>
        </div>
    </a>
</div>
