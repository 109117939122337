import { Component, ViewEncapsulation, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IFlowLoadDialog } from 'app/shared/models/flow-load-dialog';
import { Locations } from 'app/shared/models/locations';

@Component({
    selector: 'app-sliicer-import-file-dialog',
    templateUrl: './sliicer-import-file-dialog.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliicerImportFileDialogComponent {
    public assignedRainGauge = new Array<Locations>();
    public studyPeriod: string;
    public startDate: Date;
    public endDate: Date;
    public stepLength: string;
    public flowMonitorCount: string;
    public rainMonitorCount: string;
    public daysInStudy: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: IFlowLoadDialog,
        private dialogRef: MatDialogRef<SliicerImportFileDialogComponent>,
    ) {
        this.studyPeriod = data.studyPeriod;
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        this.stepLength = data.stepLength;
        this.flowMonitorCount = data.flowMonitorCount;
        this.rainMonitorCount = data.rainMonitorCount;
        this.daysInStudy = data.daysInStudy;
    }

    /**
     * Close Dialog
     */
    public close(action) {
        if (action) {
            this.dialogRef.close({ success: true });
        } else {
            this.dialogRef.close({ success: false });
        }
    }
}
