import { Injectable } from '@angular/core';
import { Config } from './config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AnomalyReasonsResponse, AutoScrubSummary, AutoScrubSummaryReportArgs } from '../models/auto-scrub-summary';
import { AutoDetectDetectCountArgs, AutoScrubDetails } from '../models/auto-scrub-details';
import { AnomaliesCount } from '../models/AnomaliesCount';
import { map } from 'rxjs/operators';

@Injectable()
export class AutoScrubSummaryService {
    public autoScrubDetails: AutoScrubSummary[];
    public autoScrubSummaryDetails: AutoScrubSummary[];
    public auroScrubHydroDetails: AutoScrubDetails[];

    /*
     * @autoScrubSummaryDetailsSorted varibale to store current sorted data of table.
     */
    public autoScrubSummaryDetailsSorted = new Array<AutoScrubSummary>();

    constructor(public http: HttpClient) {}

    public getAutoScrubSummary(params: AutoScrubSummaryReportArgs) {
        // creating HttpParams for HttpClient request
        let httpParams: HttpParams = new HttpParams();

        // setting all the existing key value of param to HttpParams
        Object.keys(params).forEach(function (key) {
            if (key !== 'locationGroupID') {
                httpParams = httpParams.append(key, params[key]);
            }
        });

        if (params.locationGroupID > 0) {
            httpParams = httpParams.append('LocationGroupID', params.locationGroupID.toString());
        }

        return this.http.get<AutoScrubSummary[]>(Config.urls.anomaliesSummary, { params: httpParams }).pipe(
            map((res) => {
                this.autoScrubDetails = <AutoScrubSummary[]>res;
                return this.autoScrubDetails;
            }),
        );
    }

    public postAnomaliesRefresh(customerId: number) {
        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.append('cid', customerId.toString());
        return this.http.post<string>(Config.urls.anomaliesReferesh, { params: httpParams }).pipe(
            map((res) => {
                return res;
            }),
        );
    }

    public getAutoScrubDetails(locationId: number, customerId: number, startDate: any, endDate: any) {
        // creating HttpParams for HttpClient request

        let httpParams: HttpParams = new HttpParams();

        httpParams = httpParams.append('locationId', locationId.toString());
        httpParams = httpParams.append('customerId', customerId.toString());
        httpParams = httpParams.append('PageSize', '1000000000');
        httpParams = httpParams.append('StartPage', '1');

        if (startDate && endDate) {
            httpParams = httpParams.append('StartDate', startDate);
            httpParams = httpParams.append('EndDate', endDate);
        }

        return this.http.get<Object>(Config.urls.anomalies, { params: httpParams }).pipe(
            map((res) => {
                this.auroScrubHydroDetails = res['listAnomalies'];
                return this.auroScrubHydroDetails;
            }),
        );
    }

    // get the tile count for auto-detect.
    public getAutoDetectCount(params: AutoDetectDetectCountArgs) {
        // creating HttpParams for HttpClient request

        let httpParams: HttpParams = new HttpParams();
        // setting all the existing key value of param to HttpParams

        Object.keys(params).forEach(function (key) {
            httpParams = httpParams.append(key, params[key]);
        });

        return this.http.get<AnomaliesCount>(Config.urls.anomaliesCount, { params: httpParams }).pipe(
            map((res) => {
                const anomaliesCount: AnomaliesCount = {
                    requireReview: res.requireReview || 0,
                    suggestedReview: res.suggestedReview || 0,
                };
                return anomaliesCount;
            }),
        );
    }

    public getAnomaliesReasons() {
        return this.http.get<AnomalyReasonsResponse>(Config.serviceUrl + Config.urls.reasonedAnomalies).pipe(
            map((response: AnomalyReasonsResponse) => {
                return response.payload;
            }),
        );
    }
}
