import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewEncapsulation,
    ChangeDetectorRef,
    OnInit,
    ViewChild,
    OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';

const highcharts = require('highcharts');
import * as Highcharts from 'highcharts';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { StringUtils } from '../../../../shared/utils/string-utils';
import { StatusCodeService } from '../../../../shared/services/status-code.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { RAIN } from 'app/shared/constant';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-uptime',
    templateUrl: './uptime.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class UptimeComponent implements OnInit, OnDestroy, OnChanges {
    public uptimeChartContainer = 'uptimegraph-chart';
    @Input() public data: Array<any>;

    @Input() public locationId: number;

    @Input() public reportLocationName: string;

    @Input() public isLoading: boolean;

    @Input() public dateFormat: string;

    @Input() public oneDayStartDate: Date;

    @Input() public oneDayEndDate: Date;

    @Input() private isRainGauge: boolean;

    @Input() private entityIds: number[];

    @ViewChild('chartElement') public chartElement: Highcharts.stockChart;

    public customerDateFormat: string;

    private uptimeChartDataRounded: number;

    private subscriptions: Array<Subscription> = [];

    public set uptimeChartData(data: number) {
        this.uptimeChartDataRounded = Math.round(data);
    }
    public get uptimeChartData(): number {
        return this.uptimeChartDataRounded;
    }

    public uptimeChartOptions: any;

    constructor(
        private router: Router,
        private changeDetectorReference: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private dateutilService: DateutilService,
        private statusCodeService: StatusCodeService,
    ) {}

    public ngOnInit() {
        this.uptimeChartContainer = 'uptimegraph-chart' + '_' + Date.now();
        // subscribe to changes in dateFormat
        this.dateutilService.dateFormat.subscribe((newDateFormat) => {
            this.customerDateFormat = newDateFormat;
        });
        // this.customerDateFormat = this.dateutilService.getFormat();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        // ensure data property
        if (this.data) {
            this.initUptimeChart();
            this.isLoading = false;
            this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions = [];
    }

    /**
     * Initializes uptime chart
     */
    public initUptimeChart() {
        if (!this.data) {
            this.isLoading = false;
            return;
        }

        this.isLoading = true;
        this.uptimeChartData = null;
        if (this.data && this.data.length > 0) {
            if (this.isRainGauge) {
                for (const item of this.data[0].uptimeDays[0].uptimeValues) {
                    if (item.uptime !== undefined && item.entityName.toLowerCase() === RAIN.toLowerCase()) {
                        this.uptimeChartData = item.uptime;
                        break;
                    }
                }
            } else {
                for (const item of this.data[0].uptimeDays[0].uptimeValues) {
                    if (item.uptime !== undefined && item.entityName.toLowerCase() !== RAIN.toLowerCase()) {
                        if (!this.uptimeChartData || item.uptime < this.uptimeChartData) {
                            this.uptimeChartData = item.uptime;
                        }
                    }
                }
            }
            this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);

            let uptimeColor = 'rgb(0, 204, 106)';

            if (this.uptimeChartData < 50) {
                uptimeColor = '#b71c1c'; // red
            } else if (this.uptimeChartData < 75) {
                uptimeColor = '#e65100'; // orange
            } else if (this.uptimeChartData < 89) {
                uptimeColor = '#fbc02d'; // yellow
            }
            let titleColor = '#000';
            const themeSub = this.statusCodeService.userInfoThemeBS.subscribe((response: boolean) => {
                if (response) {
                    StringUtils.setHighChartTheme(this.uptimeChartOptions);
                    titleColor = '#fff';
                } else {
                    StringUtils.setHighchartWhiteTheme(this.uptimeChartOptions);
                    titleColor = '#000';
                }
            });
            this.subscriptions.push(themeSub);

            if (this.uptimeChartData > 0) {
                this.uptimeChartOptions = new Highcharts.Chart({
                    exporting: {
                        enabled: false,
                    },
                    chart: {
                        renderTo: !this.chartElement.nativeElement
                            ? this.uptimeChartOptions.chart.renderTo
                            : this.chartElement.nativeElement,
                        type: 'solidgauge',
                        height: 220,
                    },
                    credits: {
                        enabled: false,
                    },
                    title: {
                        text: this.uptimeChartData + ' %',
                        style: {
                            fontSize: '24px',
                            color: titleColor,
                        },
                        verticalAlign: 'middle',
                        y: 10,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    pane: {
                        startAngle: 0,
                        endAngle: 360,
                        background: [
                            {
                                outerRadius: '112%',
                                innerRadius: '88%',
                                backgroundColor: highcharts
                                    .Color(highcharts.getOptions().colors[0])
                                    .setOpacity(0.3)
                                    .get(),
                                borderWidth: 0,
                            },
                        ],
                    },

                    yAxis: {
                        min: 0,
                        max: 100,
                        lineWidth: 0,
                        tickPositions: [],
                    },

                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                enabled: false,
                            },
                            linecap: 'round',
                            stickyTracking: false,
                            rounded: true,
                        },
                    },

                    series: [
                        {
                            data: [
                                {
                                    color: uptimeColor,
                                    radius: '112%',
                                    innerRadius: '88.5%',
                                    y: this.uptimeChartData,
                                },
                            ],
                        },
                    ],
                });
            }
        }
    }

    /**
     * Navigate to the location details page for uptime report
     * @param event
     * @param id    - location ID
     */
    public navigateToReport(reportName: string) {
        this.router.navigate(['/pages/report/' + reportName + '/' + this.locationId + '/details'], {
            queryParams: {
                ln: this.reportLocationName,
                entityIds: this.entityIds,
            },
            queryParamsHandling: 'merge',
        });
    }
}
