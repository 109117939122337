<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodePadding
        [ngClass]="{ active: node.id === nodeId }"
        matTooltip="{{ node.name }}"
        matTooltipPosition="right"
    >
        <!-- use a disabled button to provide padding for tree leaf -->
        <button mat-icon-button disabled><mat-icon>folder</mat-icon></button>
        <button mat-flat-button (click)="selectDirectory(node?.id)">
            {{ node.name }}
        </button>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node
        *matTreeNodeDef="let node; when: hasChild"
        matTreeNodePadding
        [ngClass]="{ active: node.id === nodeId }"
        matTooltip="{{ node.name }}"
        matTooltipPosition="right"
    >
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
                {{ treeControl.isExpanded(node) ? 'folder_open' : 'folder' }}
            </mat-icon>
        </button>
        <button mat-flat-button (click)="selectDirectory(node?.id)">
            {{ node.name }}
        </button>
    </mat-tree-node>
</mat-tree>
