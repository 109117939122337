import { Component, OnInit, ViewEncapsulation, Inject, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LocationArgs, LocationData, RainLocation } from 'app/shared/models/locations';
import { Selectable } from 'app/shared/models/selectable';
import { UsersService } from '../admin/users.service';
import { AddEventDialogData, EventModel, EventTypes, EVENT_TYPES } from 'app/shared/models/event';
import { EventService } from 'app/shared/services/event.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { MatLegacySelectionListChange as MatSelectionListChange } from '@angular/material/legacy-list';
import { MultiSelectAutoCompleteComponent } from 'app/shared/components/multi-select/multi-select-auto-complete/multi-select-auto-complete.component';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { DatePipe } from '@angular/common';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { combineLatest, Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { TranslateService } from '@ngx-translate/core';

const DEFAULT_PARENT_EVENT = { desc: 'None', guid: null };

@Component({
    selector: 'app-add-event-dialog',
    templateUrl: './add-event-dialog.component.html',
    styleUrls: ['./add-event-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AddEventDialogComponent implements OnInit {
    @ViewChild(MultiSelectAutoCompleteComponent) private lidsSelect: MultiSelectAutoCompleteComponent;
    public form: FormGroup;
    public eventTypes = EVENT_TYPES.filter(v => v.id !== EventTypes.Rain);
    public locationsList: Selectable[] = [];
    public rainLocations: Selectable[]= [];
    public preselectedLocations: Selectable[] = [];
    public selectableLocations: Selectable[] = [];
    public parentList: EventModel[] = [];
    public childrenList: { guid: string, desc: string, isChecked: boolean }[] = [];
    public isEditMode = false;
    public customDateFormat: string;

    // clone values, to not mutate the original ones
    private eventOnEdit: EventModel;
    private allEvents: EventModel[] = [];
    constructor(public dialogRef: MatDialogRef<AddEventDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AddEventDialogData,
        private formBuilder: FormBuilder, private usersService: UsersService,
        private eventService: EventService, private snackbarService: SnackBarNotificationService,
        private dateutilService: DateutilService, private datePipe: DatePipe, private translateService: TranslateService,
        private uiUtilsService: UiUtilsService) { }

    ngOnInit(): void {
        const dateFormat = this.dateutilService.getFormat();
        const is12HourFormat = this.dateutilService.timeFormat.getValue() !== 'hh:mm:ss';
        this.customDateFormat = is12HourFormat ? `${dateFormat}, ${'hh:mm a'}` : `${dateFormat}, ${'HH:mm'}`;
        this.createForm();

        if (this.data.event) {
            this.eventOnEdit = JSON.parse(JSON.stringify(this.data.event));
            if (this.eventOnEdit.children) {
                this.eventOnEdit.children = (this.eventOnEdit.children as EventModel[]).map(v => v.guid);
            }
            this.form.patchValue(this.eventOnEdit);
            this.isEditMode = true;

            if (this.data.event.etype === EventTypes.Rain) {
                this.eventTypes = EVENT_TYPES;
            }
        }
        this.allEvents = JSON.parse(JSON.stringify(this.data.events));
        const duration = this.eventService.getDuration(this.form.getRawValue());
        this.form.patchValue({ duration });
        this.getLocations();
        this.setParentChildrenList();
    }

    public dateChange(event: SimpleChanges) {
        this.form.patchValue({ start: event.startDate.currentValue, end: event.endDate.currentValue });

        const duration = this.eventService.getDuration(this.form.getRawValue());
        this.form.patchValue({ duration });
        this.form.markAsDirty();
        this.setParentChildrenList();
    }

    public handleSelectedLocations(locations: Selectable[]) {
        this.preselectedLocations = locations.filter(v => v.isChecked);
        this.form.patchValue({ lids: this.preselectedLocations.map(v => v.id) });
        this.form.markAsDirty();
    }

    public childrenSelection(event: MatSelectionListChange) {
        const { selected, value } = event.options[0];
        const currentlySelected = (this.form.get('children').value as string[]);

        this.form.patchValue({ children: selected ? [...currentlySelected, value] : currentlySelected.filter(v => v !== value) });
        this.form.markAsDirty();
        this.setParentChildrenList();
    }

    public saveEvent() {
        const parents = this.checkIfEventDatesOverlapChildren();
        const formData = this.form.getRawValue();
        const requestPayload = { ...formData };
        requestPayload.start = this.dateutilService.getLocalDateFromUTCDate(requestPayload.start);
        requestPayload.end = this.dateutilService.getLocalDateFromUTCDate(requestPayload.end);
        if (this.eventOnEdit) {
            requestPayload.guid = this.eventOnEdit.guid;
        }

        let saveRequest$: Observable<null>;
        const selfSave$ = this.eventService.createEvent(this.data.customerId, requestPayload);

        if (parents.length) {
            const parentsRequests$ = parents.map(v => this.eventService.createEvent(this.data.customerId, v));
            saveRequest$ = combineLatest(parentsRequests$).pipe(switchMap(() => selfSave$));
        } else {
            saveRequest$ = selfSave$;
        }

        const creationFailedText = this.translateService.instant('COMMON.CREATION_FAILED');
        const closeText = this.translateService.instant('COMMON.CLOSE');
        saveRequest$.subscribe(
            () => this.dialogRef.close(true),
            () => {
                this.snackbarService.raiseNotification(creationFailedText, closeText, {}, true, 4000)
            }
        );
    }

    public eventTypeChange(event: MatSelectChange) {
        const isRainEvent = event.value === 1;
        this.selectableLocations = isRainEvent ? [...this.rainLocations] : [...this.locationsList];
        this.refreshLocationSelector();
    }

    public setParentChildrenList() {
        const selectedParentId = (this.form.get('parent').value as string);
        const selectedChildren = (this.form.get('children').value as string[]);

        const { events } = this.data;
        const event = this.eventOnEdit;
        const currentEvent = event ? event.guid : null;

        this.parentList = events.filter(v => !selectedChildren.includes(v.guid) && v.guid !== currentEvent);

        // need to filter parent events that happened within 48 hours before the (event or its children) or after
        const formValues = this.form.getRawValue();
        const twoDays = 1000 * 60 * 60 * 48;

        let start = new Date(formValues.start).getTime();
        let end = new Date(formValues.end).getTime();

        formValues.children.forEach(childId => {
            const child = this.allEvents.find(v => v.guid === childId);
            const childStart = new Date(child.start).getTime();
            const childEnd = new Date(child.end).getTime();

            if (childStart < start) {
                start = childStart;
            }
            if (childEnd > end) {
                end = childEnd;
            }
        });

        this.parentList = this.parentList.filter((v: EventModel) => {
            const parentStart = new Date(v.start).getTime();
            const parentEnd = new Date(v.end).getTime();

            const withinTwoDates = parentStart >= (start - twoDays) && parentStart <= start && parentEnd >= end && parentEnd <= (end + twoDays);
            return withinTwoDates; 
        });

        if (selectedParentId) {
            const isStillSelected = this.parentList.find(v => v.guid === selectedParentId);
            this.form.patchValue({ parent: isStillSelected ? isStillSelected.guid : null });
        }

        this.parentList.unshift(DEFAULT_PARENT_EVENT);

        const eventStart = new Date(formValues.start).getTime();
        const eventEnd = new Date(formValues.end).getTime();
        this.childrenList = events.filter(v => {
            const notSelfOrParent = v.guid !== selectedParentId && v.guid !== currentEvent;
            const childStart = new Date(v.start).getTime();
            const childEnd = new Date(v.end).getTime();

            const withinTwoDates = childStart >= (eventStart - twoDays) && childStart <= eventStart && childEnd >= eventEnd && childEnd <= (eventEnd + twoDays);
            return notSelfOrParent && withinTwoDates; 
        }).map(v => ({ guid: v.guid, desc: v.desc, isChecked: selectedChildren.includes(v.guid) }));

        this.form.patchValue({ children: this.childrenList.filter(v => v.isChecked).map(v => v.guid) });
    }

    private getLocations() {
        const locationArgs = <LocationArgs>{
            customerId: this.data.customerId,
            IncludeInactiveLocations: this.data.includeInactiveLocations,
            locationGroupId: this.data.locationGroupId
        };

        const rainLocations$ = this.usersService.getRainLocations(this.data.customerId,this.data.includeInactiveLocations);
        const allLocations$ = this.usersService.getLocationsList(locationArgs);

        combineLatest([rainLocations$, allLocations$]).pipe(first())
            .subscribe(([rainLocs, allLocs]: [RainLocation[], LocationData[]]) => {
                rainLocs = rainLocs ? rainLocs : [];
                allLocs = allLocs ? allLocs : [];
                // gather all rain locations
                const rainLocations = rainLocs.map(v => ({ name: v.locationName, id: v.locationId }));
                // remove duplicates
                this.rainLocations = rainLocations.filter((loc, i) => i === rainLocations.findIndex(v => v.id === loc.id));
                this.locationsList = allLocs.map(v => ({ name: v.locationName, id: v.locationId }));

                this.rainLocations.sort((a,b) => a.name > b.name ? 1 : -1);
                this.locationsList.sort((a,b) => a.name > b.name ? 1 : -1);
                
                const preselectedItemsIds = (this.form.get('lids').value as number[]);
                const isRainEvent = (this.form.get('etype').value as number) === EventTypes.Rain;
                const selectable = isRainEvent ? [...this.rainLocations] : [...this.locationsList];
                
                this.preselectedLocations = selectable.filter(v => preselectedItemsIds.includes(v.id));
                this.eventTypeChange({ value: this.form.value.etype, source: null });
            });
    }

    // need to manually triger its refresh because it unselects all values for some reason
    // we cannot change auto selector's logic, because its used all over the app
    private refreshLocationSelector() {
        const dismissText = this.translateService.instant('COMMON.DISMISS_TEXT');
        const notificationMessage = this.translateService.instant('HOME.EVENT_WIDGET.NON_RAIN_EVENTS_UNSELECTED_MESSAGE');
        setTimeout(() => {
            this.preselectedLocations.forEach(v => v.isChecked = true);
            const toRemove = this.preselectedLocations.filter(v => !(this.selectableLocations.find(i => i.id === v.id))).map(v => v.id);
            if (toRemove.length) {
                this.snackbarService.raiseNotification(notificationMessage, dismissText, {}, true, 4000);
            }
            this.preselectedLocations = this.preselectedLocations.filter(v => !toRemove.includes(v.id));
            this.form.patchValue({ lids: this.preselectedLocations.map(v => v.id) });
            this.lidsSelect.updateOnSelectedChange();

            this.lidsSelect.placeholder = this.lidsSelect.initPlaceholderText;
            this.uiUtilsService.safeChangeDetection(this.lidsSelect.cdr);
        }, 0);
    }

    private createForm() {
        const start = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7, 0, 0, 0);
        this.form = this.formBuilder.group({
            desc: [this.datePipe.transform(start, this.customDateFormat), Validators.required],
            etype: [0, Validators.required],
            duration: [null],
            start: [start, Validators.required],
            end: [new Date(), Validators.required],
            lids: [[], Validators.required],
            parent: [null],
            children: [[]]
        });

        if (!this.data.hasPermission) {
            this.form.disable();
        }
    }

    private checkIfEventDatesOverlapChildren(): EventModel[] {
        const formValues = this.form.getRawValue();

        let start = new Date(formValues.start).getTime();
        let end = new Date(formValues.end).getTime();

        let isDateChanged = false;
        formValues.children.forEach(childId => {
            const child = this.allEvents.find(v => v.guid === childId);
            const childStart = new Date(child.start).getTime();
            const childEnd = new Date(child.end).getTime();

            if (childStart < start) {
                start = childStart;
                isDateChanged = true;
            }
            if (childEnd > end) {
                end = childEnd;
                isDateChanged = true;
            }
        });

        const parents = this.eventService.getParentsForEvent(formValues, this.allEvents); 
        if (isDateChanged && parents.length) {
            parents.forEach(parent => {
                const parentStart = new Date(parent.start).getTime();
                const parentEnd = new Date(parent.end).getTime();

                if (start < parentStart) {
                    parent.start = this.dateutilService.getLocalDateFromUTCDate(new Date(start));
                }

                if (end > parentEnd) {
                    parent.end = this.dateutilService.getLocalDateFromUTCDate(new Date(end));
                }
            });

        }
        this.form.patchValue({ start: new Date(start), end: new Date(end) });
        return parents;
    }
}
