<div id="locationGroupEditorDialog">
    <app-customized-confirmation-box
        *ngIf="showConfirmation"
        [confirmationData]="conformationDataInput"
        (listenConfirmation)="listenConfirmation($event)"
    ></app-customized-confirmation-box>

    <div class="popupHeader" mat-dialog-title>
        <span *ngIf="hideGroupSection" class="width-24px">
            <mat-icon matTooltip="{{ 'COMMON.BACK_TEXT' | translate }}" (click)="exitLocationGroup($event)"
                >arrow_back</mat-icon
            >
        </span>
        <span>{{ locationGroupHeader }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <div [hidden]="hideGroupSection">
        <div mat-dialog-content id="locationGroupEditorTable">
            <app-loader [isLoading]="locationGLoadingState"></app-loader>

            <mat-form-field class="eachItem" class="topPadding">
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <input
                    matInput
                    type="text"
                    placeholder="{{ 'LOCATION_GROUP_EDITOR.SEARCH_PLACEHOLDER' | translate }}"
                    [formControl]="searchString"
                />
            </mat-form-field>

            <ng-template [ngIf]="!locationGroupDataSource">
                <span class="notabledataFound">No location groups found.</span>
            </ng-template>
            <mat-table #table [dataSource]="locationGroupDataSource" matSort>
                <!-- Location Column -->

                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let locationGroup" [ngClass]="{ selected: locationGroup.isSelected }">
                        <div
                            [ngClass]="{ customLongName: validateLocationgroupNameLength(locationGroup.name, 48) }"
                            [title]="validateLocationgroupNameLength(locationGroup.name, 48) ? locationGroup.name : ''"
                        >
                            {{ locationGroup.name }}
                        </div>
                    </mat-cell>
                </ng-container>

                <!-- edit Delete Location Column -->
                <ng-container matColumnDef="editDeleteLocation">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell
                        *matCellDef="let locationGroup"
                        class="text-right"
                        [ngClass]="{ selected: locationGroup.isSelected }"
                    >
                        <mat-icon (click)="updateLocationGroup(locationGroup)">mode_edit</mat-icon>
                        <mat-icon (click)="showDeleteConfirmationLocationGroup(locationGroup)">delete</mat-icon>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="locationGroupDisplayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: locationGroupDisplayedColumns"></mat-row>
            </mat-table>
        </div>
        <div mat-dialog-actions>
            <div class="auto-margin" [hidden]="!(totalPaginationLength > 10)">
                <mat-paginator
                    #paginator
                    [length]="totalPaginationLength"
                    [pageIndex]="0"
                    [pageSize]="10"
                    [pageSizeOptions]="[10, 25, 100]"
                >
                </mat-paginator>
            </div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button (click)="emitCloseLocationGroupPopup()">{{ 'COMMON.CLOSE' | translate }}</button>
            </div>
            <div class="auto-margin">
                <button mat-button (click)="createLocationGroup()">
                    {{ 'LOCATION_GROUP_EDITOR.ADD_LOCATION_GROUP' | translate }}
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="hideGroupSection" class="relPosition">
        <app-loader [isLoading]="locationGLoadingState"></app-loader>

        <div mat-dialog-content id="locationGroupsEditor" class="addLocationGrupAction">
            <form #locationGroupForm="ngForm" class="top">
                <div class="custom-error">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            id="addLocationGroupNameInput"
                            name="addLocationGroupName"
                            [(ngModel)]="locationGroupName"
                            placeholder="{{ 'LOCATION_GROUP_EDITOR.GROUP_NAME_PLACEHOLDER' | translate }}"
                            required
                            maxlength="100"
                            #addLocationGroupNameInput="ngModel"
                            [pattern]="alphaNumericPattern"
                            (ngModelChange)="validateUpdateBtnForGroupField()"
                        />
                    </mat-form-field>
                    <div class="custom-error-message">
                        <span *ngIf="addLocationGroupNameInput?.errors?.pattern">{{
                            'COMMON.ADDRESS_ERROR' | translate
                        }}</span>
                        <span *ngIf="isDuplicateLocationName">{{
                            'LOCATION_GROUP_EDITOR.LOCATION_GROUP_ALREADY_EXISTS' | translate
                        }}</span>
                    </div>
                </div>
                <div class="custom-error">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            id="addLocationGroupDescriptionInput"
                            name="addLocationGroupDescription"
                            [(ngModel)]="locationGroupDescription"
                            placeholder="{{ 'LOCATION_GROUP_EDITOR.GROUP_DESCRIPTION' | translate }}"
                            maxlength="100"
                            #addLocationGroupDescriptionInput="ngModel"
                            [pattern]="alphaNumericPattern"
                            (ngModelChange)="validateUpdateBtnForGroupField()"
                        />
                    </mat-form-field>
                    <span class="custom-error-message" *ngIf="addLocationGroupDescriptionInput?.errors?.pattern">{{
                        'LOCATION_GROUP_EDITOR.DESCRIPTION_PATTERN_ERR' | translate
                    }}</span>
                </div>

                <app-multi-select-auto-complete
                    [items]="filteredLocations"
                    [preselectedItems]="selectedLocation"
                    placeholder="{{ 'COMMON.LOCATIONS' | translate }}"
                    (currentlySelectedItems)="selectedNewLocations($event)"
                    [minDisplayLimit]="2"
                    [maxDisplayLimit]="filteredLocations.length"
                ></app-multi-select-auto-complete>
                <div class="pad-t-6" *ngIf="!isValidForCheckboxList || !hideLocationMsg">
                    <span class="mat-error">{{ 'LOCATION_GROUP_EDITOR.LOCATION_SELECT_ERR' | translate }}</span>
                </div>
            </form>
        </div>
        <div mat-dialog-actions>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button (click)="emitCloseLocationGroupPopup()">{{ 'COMMON.CLOSE' | translate }}</button>
            </div>
            <div class="auto-margin">
                <button
                    mat-button
                    (click)="saveLocationGroup()"
                    [disabled]="
                        isDuplicateLocationName ||
                        !hideLocationMsg ||
                        !locationGroupForm.valid ||
                        (disableUpdate && (locationGroupForm.pristine || validateGroupName))
                    "
                >
                    {{ 'COMMON.SAVE_BUTTON' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
