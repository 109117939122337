import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Inject,
    ChangeDetectorRef,
    OnDestroy,
    ViewChildren,
    QueryList,
    ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import {
    DataEditType,
    DEPTH_DISPLAY_GROUP,
    VELOCITY_DISPLAY_GROUP,
    FLOW_DISPLAY_GROUP,
    RAIN_DISPLAY_GROUP,
    DEPTH_DISPLAY_GROUP_NAME,
    VELOCITY_DISPLAY_GROUP_NAME,
    FLOW_DISPLAY_GROUP_NAME,
    RAIN_DISPLAY_GROUP_NAME,
    QCONTINUITY_ENTITY,
    RAIN_ENTITY,
    VELOCITY_ENTITY,
    DEPTH_ENTITY,
    QUANTITY_ENTITY,
    RAW_VELOCITY_ENTITY,
    VELOCITY,
    DEPTH,
    RAIN,
    QUANTITY,
    RAW_VELOCITY,
    ELEVATION,
    ELEVATION_ENTITY,
    ELEVATION_DISPLAY_GROUP,
    ELEVATION_DISPLAY_GROUP_NAME,
    Q_A1_ENTITY,
    Q_A2_ENTITY,
    Q_A1,
    Q_A2,
} from 'app/shared/constant';
import { Subscription } from 'rxjs';
import { DataEditService } from 'app/shared/services/data-edit.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { EntityService } from 'app/shared/services/entity.service';
import { LocationService } from 'app/shared/services/location.service';
import { EntitySelectorEntity, EntitySelectorObject } from 'app/shared/models/entities';
import { Locations } from 'app/shared/models/locations';
import { LocationEntitiesData, LocationListArgs } from 'app/shared/models/locations-entities-data';
import { BEconfig, BEconfigCondition, ConfigTableData, DataEditingCurveTypes, DataEditPreviewParams, FormulaEntity, GetConfigTemplate, SaveConfigData, UpdatePointForSG } from 'app/shared/models/data-edit';
import { SeparateWindowHydrographService } from 'app/shared/services/separate-window-hydrograph.service';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { ConfigurationTableComponent } from 'app/shared/components/configuration-table/configuration-table.component';
import { ConfigurationSaveDialogComponent } from 'app/shared/components/configuration-save-dialog/configuration-save-dialog.component';
import { EntitySelectorComponent } from 'app/shared/components/entity-selector/entity-selector.component';
import { ConfigService } from 'app/shared/services/config.service';
import { SeparateWindowActionTypes } from 'app/shared/models/view-data';

const ENTITY_TO_EDIT_VARIABLE_NAME = 'ENTITY_TO_EDIT';
const ENTITY_TO_BASE_OF_VARIABLE_NAME = 'ENTITY_TO_COPY';
const VALUE = 'VALUE';
const ENTITY = 'ENTITY';
const EQUAL = 'EQUAL';
const FLAG_STRING = 'FLAG';
const UNFLAG_STRING = 'UNFLAG';
const LEAVE_ORIGINAL_CODE = -999999;
const FLAG_CODE = -999998;
const UNFLAG_CODE = -999997;

export interface BlockDataEditorDialogData {
    minDate?: Date;
    maxDate?: Date;
    startDate?: Date;
    endDate?: Date;
    locationName?: string;
    locations?: Locations[];
    customerId?: number;
    locationId?: number;
    isInverted?: boolean;
    curve?: DataEditingCurveTypes;
    isRawVelExistsForLocation?: boolean;
    isElevationExistsForLocation?: boolean
};

type Condition = {
    locationId: number;
    entities: EntitySelectorEntity[];
    operator: string;
    value: number;
};

@Component({
    templateUrl: './block-data-editor.component.html',
    styleUrls: ['./block-data-editor.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BlockDataEditorComponent implements OnInit, OnDestroy {
    @ViewChildren('conditionEntities') conditionEntitySelectors: QueryList<EntitySelectorComponent>;
    @ViewChild('entityToEditSelector') toEditEntitySelector: EntitySelectorComponent;
    @ViewChild('entityToBaseOfSelector') toBaseEntitySelector: EntitySelectorComponent;
    public showpageHint = false;
    public form: FormGroup;
    public isLoading = false;
    public endDate: Date;
    public startDate: Date;
    public recentConfigs: GetConfigTemplate<BEconfig>[] = [];
    public readonly minDate: Date;
    public readonly maxDate: Date;
    public readonly locationName: string;
    public readonly operators = ['=', '>', '<', '>=', '<='];
    private isInverted: boolean;
    private curve: DataEditingCurveTypes;

    public entitiesToEdit: EntitySelectorObject[] = [
        {
            entities: [
                { name: DEPTH, id: DEPTH_ENTITY, isChecked: false, groupId: DEPTH_DISPLAY_GROUP, isANSR: false },
            ],
            groupName: DEPTH_DISPLAY_GROUP_NAME,
            groupId: DEPTH_DISPLAY_GROUP,
        },
        {
            entities: [
                {
                    name: VELOCITY,
                    id: VELOCITY_ENTITY,
                    isChecked: false,
                    groupId: VELOCITY_DISPLAY_GROUP,
                    isANSR: false,
                },
            ],
            groupName: VELOCITY_DISPLAY_GROUP_NAME,
            groupId: VELOCITY_DISPLAY_GROUP,
        },
        {
            entities: [
                {
                    name: QUANTITY,
                    id: QUANTITY_ENTITY,
                    isChecked: false,
                    groupId: FLOW_DISPLAY_GROUP,
                    isANSR: false,
                },
                {
                    id: Q_A1_ENTITY, 
                    name: Q_A1,
                    isChecked: false,
                    groupId: FLOW_DISPLAY_GROUP,
                    isANSR: false,
                },
                { 
                    id: Q_A2_ENTITY,
                    name: Q_A2,
                    isChecked: false,
                    groupId: FLOW_DISPLAY_GROUP,
                    isANSR: false,
                },
            ],
            groupName: FLOW_DISPLAY_GROUP_NAME,
            groupId: FLOW_DISPLAY_GROUP,
        },
        {
            entities: [{ name: RAIN, id: RAIN_ENTITY, isChecked: false, groupId: RAIN_DISPLAY_GROUP, isANSR: false }],
            groupName: RAIN_DISPLAY_GROUP_NAME,
            groupId: RAIN_DISPLAY_GROUP,
        },
    ];
    public readonly locations: Locations[];
    public readonly actions = [
        { label: 'Add', value: '+' },
        { label: 'Subtract', value: '-' },
        { label: 'Multiply', value: '*' },
        { label: 'Divide', value: '/' },
        { label: 'Equal', value: EQUAL },
        { label: 'Flag', value: FLAG_STRING },
        { label: 'Unflag', value: UNFLAG_STRING },
    ];
    public readonly VALUE = VALUE;
    public readonly ENTITY = ENTITY;
    public readonly FLAG = FLAG_STRING;
    public readonly UNFLAG = UNFLAG_STRING;

    private customerId;
    public locationId;
    private subscriptions: Array<Subscription> = [];
    private unitEntities: {
        [key: string]: string;
    } = {};

    public entitiesByLocation: { [key: number]: EntitySelectorObject[] } = {};
    public ansrEntitiesByLocation: { [key: number]: EntitySelectorObject[] } = {};

    public loadedConfig: GetConfigTemplate<BEconfig>;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: BlockDataEditorDialogData,
        public dialogRef: MatDialogRef<BlockDataEditorComponent>,
        private dataEditingService: DataEditService,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private dateUtilService: DateutilService,
        private fb: FormBuilder,
        private entityService: EntityService,
        private locationService: LocationService,
        private sepWinHGService: SeparateWindowHydrographService,
        private domOperationService: DomOperationUtilsService,
        private dialog: MatDialog,
        private configService: ConfigService
    ) {
        this.minDate = data.minDate;
        this.maxDate = data.maxDate;
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        this.locationName = data.locationName;
        this.locations = data.locations;
        this.customerId = data.customerId;
        this.locationId = data.locationId;
        this.isInverted = data.isInverted;
        this.curve = data.curve;

        this.isInverted = data.isInverted;
        this.curve = data.curve;

        this.form = this.fb.group({
            conditions: this.fb.array([this.createCondition()]),
            entityToEdit: new FormControl([], Validators.required),
            action: new FormControl(null, Validators.required),
            typeToBaseOf: new FormControl(null, Validators.required),
            entityToBaseOf: new FormControl([], Validators.required),
            valueToCopy: new FormControl(null, Validators.required),
        });

        if (data.isRawVelExistsForLocation) {
            const velocityGroup = this.entitiesToEdit.find(v => v.groupId === VELOCITY_DISPLAY_GROUP);

            velocityGroup.entities.push({
                name: RAW_VELOCITY,
                id: RAW_VELOCITY_ENTITY,
                isChecked: false,
                groupId: VELOCITY_DISPLAY_GROUP,
                isANSR: false,
            });
        }

        if(data.isElevationExistsForLocation) {
            this.entitiesToEdit.push({
                entities: [
                    {
                        name: ELEVATION,
                        id: ELEVATION_ENTITY,
                        isChecked: false,
                        groupId: ELEVATION_DISPLAY_GROUP,
                        isANSR: false,
                    },
                ],
                groupName: ELEVATION_DISPLAY_GROUP_NAME,
                groupId: ELEVATION_DISPLAY_GROUP,
            })

        }
    }

    public get action() {
        return this.form.controls.action;
    }

    public get typeToBaseOf() {
        return this.form.controls.typeToBaseOf;
    }

    public get conditions() {
        return this.form.controls.conditions as FormArray;
    }

    public get entityToEdit() {
        return this.form.controls.entityToEdit;
    }

    public get valueToCopy() {
        return this.form.controls.valueToCopy;
    }

    public get entityToBaseOf() {
        return this.form.controls.entityToBaseOf;
    }

    public ngOnInit() {
        this.domOperationService.showpageHint.subscribe((isShowPageHint) => {
            this.showpageHint = isShowPageHint;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });

        const actionSub = this.action.valueChanges.subscribe((newAction) => {
            this.checkActionAndDisableEnableInputs();
        });

        this.subscriptions.push(actionSub);

        const typeSub = this.typeToBaseOf.valueChanges.subscribe((newType) => {
            if (this.ENTITY === newType) {
                this.entityToBaseOf.enable();
                this.valueToCopy.disable();
            } else if (this.VALUE === newType) {
                this.entityToBaseOf.disable();
                this.valueToCopy.enable();
            } else {
                this.valueToCopy.disable();
                this.valueToCopy.enable();
            }
        });

        this.subscriptions.push(typeSub);
        this.isLoading = true;
        this.uiUtilsService.safeChangeDetection(this.cdr);

        const entitiesSub = this.locationService
            .getLocationData(<LocationListArgs>{ cid: this.customerId, IncludeInactiveLocations: false })
            .subscribe((entityData: LocationEntitiesData | never) => {
                this.unitEntities = entityData.d
                    .map((x) => x.g)
                    .reduce((acc, groups) => [...acc, ...groups], [])
                    .reduce(
                        (acc, group) => ({
                            ...acc,
                            [group.id]: group.unit,
                        }),
                        {},
                    );

                this.entitiesByLocation = entityData.l.reduce((acc, val) => {
                    const groups = this.entityService.getSelectedDevices(entityData, val.s, val.t);
                    if (!groups || !groups[0] || !groups[0].g) { return acc; }

                    const entityGroups = [
                        ...this.entityService.seriesEntityGroupToEntitySelectorObject([...groups[0].g]),
                    ];

                    return {
                        ...acc,
                        [val.lid]: entityGroups,
                    };
                }, {});

                this.ansrEntitiesByLocation = entityData.l.reduce((acc, val) => {
                    const entityGroups = [
                        ...this.entityService.ansrEntityGroupToEntitySelectorObject([...val.ae], [], true),
                    ];

                    return {
                        ...acc,
                        [val.lid]: entityGroups,
                    };
                }, {});

                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            });
        this.subscriptions.push(entitiesSub);
        this.getRecentConfigs();
    }

    public ngOnDestroy() {
        if (this.subscriptions) {
            this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        }
        this.subscriptions = [];
    }

    public cancel(result = false, edits = []) {
        this.dialogRef.close({ result, edits });
    }

    public onCloseDateMenu() {}

    public filterByGroupId(entityGroups: EntitySelectorObject[], groupId: number) {
        return entityGroups.filter((group) => group.groupId === groupId);
    }

    public openConfigurationDialog() {
        const data: ConfigTableData = {
            configType: 'be',
            cid: this.customerId,
            lid: this.locationId,
            partitionSuffixFilter: '_' + this.locationId
        }
        this.dialog.open(ConfigurationTableComponent, {
            disableClose: true,
            data,
            panelClass: 'no-padding-nor-overflow-relative-dialog',
        }).afterClosed().subscribe((data: GetConfigTemplate<BEconfig> | null) => {
            this.getRecentConfigs();

            if (!data) return;
            this.loadConfiguration(data);
        });
    }

    private loadConfiguration(data: GetConfigTemplate<BEconfig>) {
        this.loadedConfig = data;
        this.startDate = new Date(data.settings.start);
        this.endDate = new Date(data.settings.end);
        this.clearConditions();
        this.form.patchValue(data.settings);
        this.checkActionAndDisableEnableInputs();
        // populate entities
        if (data.settings.conditions.length && data.settings.conditions[0].entities.length) {
            data.settings.conditions.forEach((item: BEconfigCondition, index: number) => {
                const selector = this.conditionEntitySelectors.get(index);
                if (!item.entities.length) {
                    if (selector) {
                        selector.clearSelected();
                    }

                    return;
                }

                const entity = item.entities[0];
                if (selector && this.conditions.at(index)) {
                    this.selectEntityFromSelectorComponent(selector, entity);
                } else {
                    this.createConditionRowAndPopulate(item, index);
                }
            });
        } else {
            this.conditions.push(this.createCondition());
        }
        if (data.settings.entityToEdit && data.settings.entityToEdit.length) {
            const entity = data.settings.entityToEdit[0];
            this.selectEntityFromSelectorComponent(this.toEditEntitySelector, entity);
        } else if (data.settings.entityToEdit.length === 0) {
            this.toEditEntitySelector.clearSelected();
        }

        // need to wait for render
        setTimeout(() => {
            if (this.toBaseEntitySelector && data.settings.entityToBaseOf && data.settings.entityToBaseOf.length && this.typeToBaseOf.value === ENTITY) {
                const entity = data.settings.entityToBaseOf[0];
                this.selectEntityFromSelectorComponent(this.toBaseEntitySelector, entity);
                this.form.patchValue({ entityToBaseOf: [entity] });
            } else if (this.toBaseEntitySelector && (!data.settings.entityToBaseOf || !data.settings.entityToBaseOf.length) && this.typeToBaseOf.value === ENTITY) {
                this.toBaseEntitySelector.clearSelected();
            }

            this.checkActionAndDisableEnableInputs();
        }, 0);
    }

    private checkActionAndDisableEnableInputs() {
        const actionValue = this.action.value;
        if ([FLAG_STRING, UNFLAG_STRING].includes(actionValue)) {
            this.typeToBaseOf.disable();
            this.entityToBaseOf.disable();
            this.valueToCopy.disable();
        } else {
            this.typeToBaseOf.enable();
        }
    }

    private clearConditions() {
        while(this.conditions.length !== 0) {
            this.conditions.removeAt(0);
        }
    }

    private selectEntityFromSelectorComponent(selector: EntitySelectorComponent, entity: EntitySelectorEntity) {
        selector.onSelectionChange(entity.groupId, entity.id, entity.isANSR, true);
    }

    private createConditionRowAndPopulate(condition: BEconfigCondition, index: number) {
        this.conditions.push(this.createCondition());
        this.conditions.at(index).patchValue(condition);

        // need to wait for render
        setTimeout(() => {
            const entity = condition.entities[0];
            this.selectEntityFromSelectorComponent(this.conditionEntitySelectors.get(index), entity);
            this.conditions.at(index).patchValue({ entities: [entity] });
        }, 0);
    }

    public saveConfiguration() {
        const settings = {
            start: this.startDate,
            end: this.endDate,
            ...this.form.getRawValue()
        }
        const data: SaveConfigData = {
            cid: this.customerId,
            lid: this.locationId,
            type: 'be',
            settings,
            partitionSuffixFilter: '_' + this.locationId
        };
        this.dialog.open(ConfigurationSaveDialogComponent, {
            disableClose: true,
            data,
            panelClass: 'no-padding-nor-overflow-relative-dialog',
        }).afterClosed().subscribe(() => this.getRecentConfigs());
    }

    public getRecentConfigs() {
        this.configService.getRecentConfigs<BEconfig[]>(this.customerId, 'be', '_' + this.locationId)
            .subscribe((data: GetConfigTemplate<BEconfig>[]) => this.recentConfigs = data);
    }

    public loadRecentConfig(id: string) {
        const config = this.recentConfigs.find(v => v.id === id);

        if (config) {
            this.configService.onRecentConfigLoaded(this.customerId, 'be', '_' + this.locationId, config.id).subscribe(() => this.getRecentConfigs());
            this.loadConfiguration(config);
        }
    }

    private createCondition(): FormGroup {
        const condition = {
            entities: [[], Validators.required],
            locationId: [this.locationId, Validators.required],
            operator: [null, Validators.required],
            value: [null, Validators.required],
        };
        return this.fb.group(condition);
    }

    public addCondition(): void {
        this.conditions.push(this.createCondition());
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public removeCondition(conditionIndex: number) {
        this.conditions.removeAt(conditionIndex);
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public apply() {
        this.isLoading = true;
        const equation = this.getEquation();
        const formulaEntities = this.getFormulaEntities();

        const query: DataEditPreviewParams = {
            dataEditType: DataEditType.BlockEditor,
            start: this.dateUtilService.getLocalDateFromUTCDate(this.startDate).toJSON(),
            end: this.dateUtilService.getLocalDateFromUTCDate(this.endDate).toJSON(),
            snapPoints: [],
            updatePoints: [],
            bestFit: [],
            temporaryFlags: [],
            depthOnY: this.isInverted ? false : true,
            editingCurve: this.curve,
            blockEntity: {
                startDate: this.dateUtilService.getLocalDateFromUTCDate(this.startDate).toJSON(),
                endDate: this.dateUtilService.getLocalDateFromUTCDate(this.endDate).toJSON(),
                formulaEntities,
                equation,
                unitId: 0,
                calculatedEntityId: this.entityToEdit.value[0].id,
                name: '',
                description: '',
                displayGroupId: 0,
                formulaType: 0,
            },
        };

        const dataEditSub = this.dataEditingService
            .dataEditPreview(this.customerId, this.locationId, query)
            .subscribe((res) => {
                const edits = this.onBlockEditorResponse(res);
                // #33293 if action is flag then we do not notify scattergraph, it will be handled within data edit preview response
                const isFlagAction = this.action.value === FLAG_STRING;
                this.isLoading = false;
                this.sepWinHGService.dispatchAction({
                    type: SeparateWindowActionTypes.blockEditor,
                    payload: { hg: res, sg: edits }
                });
                this.uiUtilsService.safeChangeDetection(this.cdr);
                this.cancel(true, isFlagAction ? [] : edits);
            });

        this.subscriptions.push(dataEditSub);
    }

    // need to inform scattergraph
    private onBlockEditorResponse(res: { d?: { [key: number]: string }[] }): UpdatePointForSG[] {
        if (!res) return [];

        return Object.keys(res.d).reduce((acc, stamp) => {
            const dataValue = (res.d[stamp] as string).substr(1, res.d[stamp].length - 2);

            const dividedByEid = dataValue.split(';').map(v => {
                const [eid, value, flagged] = v.split(':');

                return { stamp: Number(stamp) * 1000, id: Number(eid), value: Number(value), flagged };
            });

            return [...acc, ...dividedByEid.filter(v => v.id === DEPTH_ENTITY || v.id === VELOCITY_ENTITY)];
        }, []);
    }

    public getUnitName(entities: EntitySelectorEntity[]): string {
        if (
            entities &&
            entities[0] &&
            this.unitEntities[entities[0].groupId] &&
            this.unitEntities[entities[0].groupId].length
        ) {
            return `(${this.unitEntities[entities[0].groupId]})`;
        }
        return '';
    }

    private getFormulaEntities(): FormulaEntity[] {
        const result = [
            ...this.conditions.controls.map(
                (
                    {
                        value: {
                            entities: [{ id }],
                            locationId,
                        },
                    }: { value: Condition },
                    index: number,
                ) => ({
                    locationId,
                    entityId: id,
                    variableName: this.indexToVariableName(index),
                    orderId: 0,
                }),
            ),
            {
                locationId: this.locationId,
                entityId: this.entityToEdit.value[0].id,
                variableName: ENTITY_TO_EDIT_VARIABLE_NAME,
                orderId: 0,
            },
        ];

        if (this.typeToBaseOf.value === ENTITY) {
            result.push({
                locationId: this.locationId,
                entityId: this.entityToBaseOf.value[0].id,
                variableName: ENTITY_TO_BASE_OF_VARIABLE_NAME,
                orderId: 0,
            });
        }

        return result;
    }

    private getValueOrEntityBase(): string {
        if (this.typeToBaseOf.value === VALUE) {
            return this.valueToCopy.value;
        } else if (this.typeToBaseOf.value === ENTITY) {
            return ENTITY_TO_BASE_OF_VARIABLE_NAME;
        }
    }

    private getEquation(): string {
        const conditions = this.conditions.controls.map(
            ({ value }: { value: Condition }, index: number) =>
                `${this.indexToVariableName(index)} ${value.operator} ${value.value}`,
        );

        const baseForCalculation = this.getValueOrEntityBase();

        switch (this.action.value) {
            case '+':
            case '-':
            case '*':
            case '/':
                return `if(${conditions.join(' && ')}, ${ENTITY_TO_EDIT_VARIABLE_NAME} ${
                    this.action.value
                } ${baseForCalculation}, ${ENTITY_TO_EDIT_VARIABLE_NAME})`;
            case EQUAL:
                return `if(${conditions.join(' && ')}, ${baseForCalculation}, ${ENTITY_TO_EDIT_VARIABLE_NAME})`;
            case FLAG_STRING:
                return `if(${conditions.join(' && ')}, ${FLAG_CODE}, ${LEAVE_ORIGINAL_CODE})`;
            case UNFLAG_STRING: {
                return `if(${conditions.join(' && ')}, ${UNFLAG_CODE}, ${LEAVE_ORIGINAL_CODE})`;
            }
            default:
                // Unsupported action
                return '';
        }
    }

    /* 0 -> A, 25 -> Z 26 -> BZ (should be AZ, but it's whatever), and so on */
    private indexToVariableName(index: number) {
        if (index === 0) {
            return 'A';
        }

        let remaining = index;
        let result = '';

        while (remaining !== 0) {
            const code = remaining % 25 || 25;
            remaining -= code;
            result += String.fromCharCode(code + 65);
        }

        return result;
    }
}
