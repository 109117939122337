import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { SliicerCaseStudy, RainfallEvent } from 'app/shared/models/sliicer';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { RainMeasurements } from 'app/shared/models/sliicer/results/rainfall-events';
import { MathService } from 'app/shared/services/math.service';

@Component({
    selector: 'app-rainfall-max-return',
    templateUrl: './max-return.component.html',
    styleUrls: ['./max-return.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaxReturnComponent implements OnInit {
    private unit: string;
    private rainPrecision: number;
    public hasRainData = false;
    public rainEvents: any[];
    public rainfallMonitors: string[];
    public allColumns: string[];
    public dateFormat: string;

    constructor(
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private dateutilService: DateutilService,
        private sliicerService: SliicerService,
        private mathService: MathService,
    ) {}

    public ngOnInit() {
        this.dateFormat = this.dateutilService.getFormat() || '';
        this.dateFormat += ' ' + this.dateutilService.getTimeFormatWithoutSeconds();
        if (this.dateutilService.isCustomerUnitsMetric.getValue()) {
            this.unit = 'mm';
            this.rainPrecision = 1;
        } else {
            this.unit = 'in';
            this.rainPrecision = 2;
        }

        this.sliicerService.studyDetailsData$.subscribe((caseStudy: SliicerCaseStudy) => {
            this.loadRainfallEvents(caseStudy.customerId, caseStudy.id);
        });
    }

    private loadRainfallEvents(customerId: number, caseStudyId: string): void {
        this.sliicerService.getRainfallEvents(customerId, caseStudyId).subscribe(
            (result) => {
                if (result && result.rainfalls) {
                    this.rainEvents = this.getTableData(result.rainfalls);
                } else {
                    this.rainEvents = [];
                }
                this.hasRainData = this.rainEvents.length > 0;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            () => {
                this.rainEvents = [];
                this.hasRainData = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
    }

    private getTableData(rainEvents: RainfallEvent[]): any[] {
        const resultData = [];
        this.rainfallMonitors = [];
        this.allColumns = ['rainfallDate'];

        rainEvents.forEach((re: RainfallEvent) => {
            const row = [];
            row['rainfallDate'] = re.rainStartTime;
            re.rainGaugeMeasurements.forEach((m) => {
                if (!this.rainfallMonitors.includes(m.rainGauge)) {
                    this.rainfallMonitors.push(m.rainGauge);
                    this.allColumns.push(m.rainGauge);
                }
                // find maximum value in m.rainMeasurements[].frequency
                const indexOfMaxReturn = m.rainMeasurements.reduce(
                    (iMax, x, i, arr) => (x.frequency > arr[iMax].frequency ? i : iMax),
                    0,
                );
                row[m.rainGauge] = this.getReturnFrequencyString(m.rainMeasurements[indexOfMaxReturn]);
            });
            resultData.push(row);
        });

        this.allColumns = this.mathService.sortArrayExceptOne(this.allColumns,'rainfallDate');
            
        return resultData;
    }

    private getReturnFrequencyString(measurement: RainMeasurements): string {
        if (measurement.frequencyName === undefined || measurement.frequencyName === 'NaN-months') {
            return '-';
        }
        return `${measurement.frequencyName}, ${measurement.duration} minutes, ${measurement.rainTotal.toFixed(
            this.rainPrecision,
        )} ${this.unit}`;
    }
}
