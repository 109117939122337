<div id="locationDetailsId" [ngClass]="{ removeMargin: removeMargin }">
    <app-loader [isLoading]="isLoading"></app-loader>
    <div class="popupHeader" mat-dialog-title>
        <span class="word-break">
            {{ locationDetails?.locationDetails?.locationName }}
        </span>
        <span class="app-flex-filler"></span>
        <span>
            <mat-icon (click)="emitCloseMapLocationDetailPopup()">close</mat-icon>
        </span>
    </div>
    <div>
        <div class="generalContainer">
            <form #locationDetailsForm="ngForm" name="locationDetailsForm" novalidate>
                <div mat-dialog-content (scroll)="onScroll($event)">
                    <!-- END Location Details -->
                    <div
                        class="custom-error"
                        [ngClass]="{
                            'pad-b-15':
                                editMode && (LocationName.hasError('pattern') || LocationName.hasError('maxLength'))
                        }"
                    >
                        <mat-form-field class="full-width top">
                            <input
                                matInput
                                ng-cloak
                                placeholder="{{ 'COMMON.LOCATION_NAME' | translate }}"
                                [(ngModel)]="locationDetailsModel.locationName"
                                name="LocationName"
                                [pattern]="alphaNumericWitSpecialSymbols_Pattern"
                                maxlength="63"
                                #LocationName="ngModel"
                                (ngModelChange)="validateDuplicateCustomerName()"
                                [disabled]="!editMode || isSecondMontoringPoint"
                                required
                            />
                        </mat-form-field>
                        <div class="custom-error-message">
                            <span *ngIf="editMode && isValidLocationName === false"
                                >{{ 'COMMON.LOCATION_NAME' | translate }}
                                {{ 'COMMON.EXISTS_ALREADY' | translate }}</span
                            >
                            <span
                                *ngIf="
                                    editMode && (LocationName.hasError('pattern') || LocationName.hasError('maxLength'))
                                "
                                >{{ 'COMMON.LOCATION_NAME_DESCRIPTION_MANHOLE_ADDRESS_ERROR' | translate }}</span
                            >
                        </div>
                    </div>
                    <mat-form-field class="full-width auto-location-select">
                        <input
                            matInput
                            placeholder="{{ 'COMMON.MONITOR_SERIES_PLACEHOLDER' | translate }}"
                            aria-label="Monitor Series"
                            maxlength="50"
                            #seriesAutoInput
                            [matAutocomplete]="seriesAuto"
                            [(ngModel)]="locationDetailsModel.series"
                            [formControl]="seriesCtrl"
                            [disabled]="!editMode"
                            required
                        />
                        <mat-autocomplete
                            #seriesAuto="matAutocomplete"
                            (optionSelected)="seriesSelectedHandler($event)"
                        >
                            <mat-option *ngFor="let series of filteredSeries | async" [value]="series.modelName">
                                <span>{{ series.modelName }}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            placeholder="{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.MONITOR_SERIAL_NO' | translate }}"
                            name="locationDetailsModel.serialNumber"
                            [(ngModel)]="locationDetailsModel.serialNumber"
                            [disabled]="!editMode"
                            maxlength="50"
                            required
                        />
                    </mat-form-field>
                    <div class="pad-b-20 active-location-selection">
                        {{ 'COMMON.IS_ACTIVE_LABEL' | translate }}&nbsp;
                        <mat-radio-group
                            [(ngModel)]="isActiveLocation"
                            name="IsActiveLocation"
                            [disabled]="!editMode"
                            required
                            ngDefaultControl
                        >
                            <span></span>
                            <mat-radio-button *ngFor="let isActive of isActives" [value]="isActive.value">{{
                                isActive.text
                            }}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="custom-error">
                        <mat-form-field class="full-width">
                            <input
                                [matTooltip]="
                                    editMode ? 'IP address must be in the following format: xxx.xxx.xxx.xxx' : ''
                                "
                                matInput
                                id="locationDetailsIpaddressInput"
                                placeholder="{{ 'COMMON.IP_ADDRESS_PLACEHOLDER' | translate }}"
                                name="locationDetailsModel.ipaddress"
                                [(ngModel)]="locationDetailsModel.ipaddress"
                                [disabled]="!editMode"
                                #locationDetailsIpaddressInput="ngModel"
                                [pattern]="ipaddressPattern"
                                maxlength="100"
                                required
                            />
                        </mat-form-field>
                        <span class="custom-error-message" *ngIf="locationDetailsIpaddressInput?.errors?.pattern"
                            >{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.IP_ADDRESS_ERROR' | translate }}
                        </span>
                    </div>
                    <div
                        class="custom-error"
                        [ngClass]="{ 'pad-b-15': locationDetailsDescriptionInput?.errors?.pattern }"
                    >
                        <mat-form-field class="full-width">
                            <input
                                matInput
                                placeholder="{{ 'COMMON.LOCATION_DESCRIPTION' | translate }}"
                                id="locationDetailsDescriptionInput"
                                #locationDetailsDescriptionInput="ngModel"
                                [pattern]="alphaNumericWitSpecialSymbols_Pattern"
                                name="locationDetailsModel.description"
                                [(ngModel)]="locationDetailsModel.description"
                                [disabled]="!editMode"
                                maxlength="256"
                            />
                        </mat-form-field>
                        <div class="custom-error-message" *ngIf="locationDetailsDescriptionInput?.errors?.pattern">
                            {{ 'COMMON.LOCATION_NAME_DESCRIPTION_MANHOLE_ADDRESS_ERROR' | translate }}
                        </div>
                    </div>
                    <div
                        class="custom-error"
                        [ngClass]="{ 'pad-b-15': locationDetailsManholeAddressInput?.errors?.pattern }"
                    >
                        <mat-form-field class="full-width">
                            <input
                                matInput
                                placeholder="{{ 'COMMON.MANHOLE_ADDRESS_TEXT' | translate }}"
                                id="locationDetailsManholeAddressInput"
                                #locationDetailsManholeAddressInput="ngModel"
                                [pattern]="alphaNumericWitSpecialSymbols_Pattern"
                                name="locationDetailsModel.manholeAddress"
                                [(ngModel)]="locationDetailsModel.manholeAddress"
                                [disabled]="!editMode"
                            />
                        </mat-form-field>
                        <div class="custom-error-message" *ngIf="locationDetailsManholeAddressInput?.errors?.pattern">
                            {{ 'COMMON.LOCATION_NAME_DESCRIPTION_MANHOLE_ADDRESS_ERROR' | translate }}
                        </div>
                    </div>
                    <div class="custom-50p custom-error">
                        <div class="each-Item">
                            <mat-form-field class="full-width">
                                <input
                                    [matTooltip]="
                                        editMode
                                            ? 'Latitude must be between -90 and 90 degrees with maximum 8 Decimal precision'
                                            : ''
                                    "
                                    matInput
                                    id="locationDetailsLatitudeInput"
                                    placeholder="{{ 'COMMON.LATITUDE_LABEL' | translate }}"
                                    name="locationDetailsModel.coordinate.latitude"
                                    [(ngModel)]="locationDetailsModel.coordinate.latitude"
                                    [disabled]="!editMode"
                                    #locationDetailsLatitudeInput="ngModel"
                                    [pattern]="latitudePattern"
                                    required
                                />
                            </mat-form-field>
                        </div>
                        <div class="each-Item">
                            <mat-form-field class="full-width">
                                <input
                                    [matTooltip]="
                                        editMode
                                            ? 'Longitude must be between -180 and 180 degrees with maximum 8 Decimal precision'
                                            : ''
                                    "
                                    matInput
                                    id="locationDetailsLongitudeInput"
                                    placeholder="{{ 'COMMON.LONGITUDE_LABEL' | translate }}"
                                    name="locationDetailsModel.coordinate.longitude"
                                    [(ngModel)]="locationDetailsModel.coordinate.longitude"
                                    [disabled]="!editMode"
                                    #locationDetailsLongitudeInput="ngModel"
                                    [pattern]="longitudePattern"
                                    required
                                />
                            </mat-form-field>
                        </div>
                        <div
                            class="custom-error-message"
                            *ngIf="
                                locationDetailsLatitudeInput?.errors?.pattern ||
                                locationDetailsLongitudeInput?.errors?.pattern
                            "
                        >
                            {{ 'COMMON.LONGITUDE_ERROR' | translate }}
                        </div>
                    </div>
                    <div class="custom-error">
                        <div *ngIf="locationDetailsModel.series.toLowerCase().indexOf('rainalert') < 0">
                            <mat-form-field>
                                <input
                                    matInput
                                    placeholder="{{ 'COMMON.RAIN_GAUAGE' | translate }}"
                                    aria-label="Assigned Rain Gauge"
                                    maxlength="50"
                                    [matAutocomplete]="auto"
                                    [disabled]="!editMode"
                                    id="AssignedRainGaugeInput"
                                    #AssignedRainGaugeInput="ngModel"
                                    [(ngModel)]="locationDetailsModel.assignedRainGaugeLocationName"
                                    (ngModelChange)="changeRainGaugeInput($event)"
                                    name="locationDetailsModel.assignedRainGaugeLocationName"
                                />

                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option
                                        *ngFor="let rainGauge of filteredassignedRainGauge"
                                        [value]="rainGauge.locationName"
                                    >
                                        <span>{{ rainGauge.locationName }}</span>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>
                        <div class="custom-error-message" *ngIf="assignedRainGaugeCtrl?.errors?.invalid">
                            {{ 'HOME.MAP.RAIN_GAUGE_REQUIRED_VALIDATION' | translate }}
                        </div>
                    </div>
                    <!-- END Location Details -->

                    <!-- BEGIN Installation Details -->

                    <div class="customersDropdown full-width">
                        <mat-form-field>
                            <mat-select
                                placeholder="{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.INSTALLATION_TYPE' | translate }}"
                                name="instalationType"
                                [(ngModel)]="instalationType"
                                class="full-width"
                                (selectionChange)="updateInstallationType()"
                                [disabled]="!editMode"
                                required
                                ngDefaultControl
                            >
                                <mat-option
                                    *ngFor="let instalationType of instalationTypeUI | async"
                                    [value]="instalationType.value"
                                    selected="instalationType.value.indexOf(locationDetailsModel.installationShape)"
                                    >{{ instalationType.text }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div
                        class="custom-error"
                        *ngIf="
                            locationDetailsModel.installationShapeTypeID > 0 &&
                            locationDetailsModel.installationShapeTypeID !== 800
                        "
                    >
                        <mat-form-field class="pad-t-6 full-width">
                            <input
                                matInput
                                placeholder="{{ manholeDepthPlaceHolder }}"
                                id="ManholedepthInput"
                                name="locationDetailsModel.manholedepth"
                                [(ngModel)]="locationDetailsModel.manholedepth"
                                #ManholedepthInput="ngModel"
                                maxlength="100"
                                [pattern]="installationValuePattern"
                                [disabled]="!editMode"
                            />
                        </mat-form-field>
                        <div class="custom-error-message" *ngIf="ManholedepthInput?.errors?.pattern">
                            {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                        </div>
                    </div>

                    <!-- PIPE -->
                    <div
                        *ngIf="
                            locationDetailsModel.installationShapeTypeID > 199 &&
                            locationDetailsModel.installationShapeTypeID < 300
                        "
                    >
                        <div class="custom-error">
                            <mat-form-field class="pad-t-6 full-width">
                                <input
                                    matInput
                                    placeholder="{{ pipeHeightPlaceHolder }}"
                                    id="PipeHeightInput"
                                    #PipeHeightInput="ngModel"
                                    name="locationDetailsModel.installationHeight"
                                    [(ngModel)]="locationDetailsModel.installationHeight"
                                    (keyup)="setCircularWidth()"
                                    maxlength="100"
                                    required
                                    [pattern]="installationValuePattern"
                                    [disabled]="!editMode"
                                />
                            </mat-form-field>
                            <div class="custom-error-message" *ngIf="PipeHeightInput?.errors?.pattern">
                                {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                            </div>
                        </div>
                        <div class="custom-error">
                            <mat-form-field class="pad-t-6 full-width">
                                <input
                                    matInput
                                    placeholder="{{ pipeWidthPlaceHolder }}"
                                    id="PipeWidthInput"
                                    #PipeWidthInput="ngModel"
                                    name="locationDetailsModel.installationWidth"
                                    [(ngModel)]="locationDetailsModel.installationWidth"
                                    (keyup)="setCircularHeight()"
                                    maxlength="100"
                                    required
                                    [pattern]="installationValuePattern"
                                    [disabled]="!editMode"
                                />
                            </mat-form-field>
                            <div class="custom-error-message" *ngIf="PipeWidthInput?.errors?.pattern">
                                {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                            </div>
                        </div>
                    </div>

                    <!-- Flume -->
                    <div
                        *ngIf="
                            locationDetailsModel.installationShapeTypeID > 399 &&
                            locationDetailsModel.installationShapeTypeID < 500
                        "
                    >
                        <div class="custom-error">
                            <mat-form-field class="pad-t-6 full-width">
                                <input
                                    matInput
                                    placeholder="{{ flumeRangePlaceHolder }}"
                                    id="FlumeRangeInput"
                                    #FlumeRangeInput="ngModel"
                                    name="FlumeRange"
                                    [(ngModel)]="locationDetailsModel.range"
                                    required
                                    [pattern]="installationValuePattern"
                                    [disabled]="!editMode"
                                />
                            </mat-form-field>
                            <div class="custom-error-message" *ngIf="FlumeRangeInput?.errors?.pattern">
                                {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                            </div>
                        </div>
                        <div class="custom-error">
                            <mat-form-field class="pad-t-6 full-width">
                                <input
                                    matInput
                                    placeholder="{{ capacityPlaceHolder }}"
                                    id="FlumeCapacityInput"
                                    #FlumeCapacityInput="ngModel"
                                    name="FlumeCapacity"
                                    [(ngModel)]="locationDetailsModel.capacity"
                                    required
                                    [pattern]="installationValuePattern"
                                    [disabled]="!editMode"
                                />
                            </mat-form-field>
                            <div class="custom-error-message" *ngIf="FlumeCapacityInput?.errors?.pattern">
                                {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                            </div>
                        </div>
                        <div class="custom-error">
                            <mat-form-field class="pad-t-6 full-width">
                                <input
                                    matInput
                                    placeholder="{{ widthPlaceHolder }}"
                                    id="FlumeWidthInput"
                                    #FlumeWidthInput="ngModel"
                                    name="FlumeWidth"
                                    [(ngModel)]="locationDetailsModel.width"
                                    [pattern]="installationValuePattern"
                                    [disabled]="!editMode"
                                    [required]="
                                        locationDetailsModel.installationShapeTypeID === 405 ||
                                        locationDetailsModel.installationShapeTypeID === 406
                                    "
                                />
                            </mat-form-field>
                            <div class="custom-error-message" *ngIf="FlumeWidthInput?.errors?.pattern">
                                {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                            </div>
                        </div>
                    </div>

                    <!-- Weir -->
                    <div
                        *ngIf="
                            locationDetailsModel.installationShapeTypeID > 299 &&
                            locationDetailsModel.installationShapeTypeID < 400
                        "
                    >
                        <div class="custom-50p">
                            <div
                                class="each-Item custom-error"
                                [ngClass]="{ 'pad-b-15': WeirRangeInput?.errors?.pattern }"
                            >
                                <mat-form-field class="pad-t-6 full-width">
                                    <input
                                        matInput
                                        placeholder="{{ flumeRangePlaceHolder }}"
                                        id="WeirRangeInput"
                                        #WeirRangeInput="ngModel"
                                        name="WeirRange"
                                        [(ngModel)]="locationDetailsModel.range"
                                        required
                                        [pattern]="installationValuePattern"
                                        [disabled]="!editMode"
                                    />
                                </mat-form-field>
                                <div class="custom-error-message" *ngIf="WeirRangeInput?.errors?.pattern">
                                    {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                                </div>
                            </div>
                            <div
                                class="each-Item custom-error"
                                [ngClass]="{ 'pad-b-15': WeirCapacityInput?.errors?.pattern }"
                            >
                                <mat-form-field class="pad-t-6 full-width">
                                    <input
                                        matInput
                                        placeholder="{{ capacityPlaceHolder }}"
                                        id="WeirCapacityInput"
                                        #WeirCapacityInput="ngModel"
                                        name="WeirCapacity"
                                        [(ngModel)]="locationDetailsModel.capacity"
                                        required
                                        [pattern]="installationValuePattern"
                                        [disabled]="!editMode"
                                    />
                                </mat-form-field>
                                <div class="custom-error-message" *ngIf="WeirCapacityInput?.errors?.pattern">
                                    {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                                </div>
                            </div>
                        </div>
                        <div class="custom-50p">
                            <div
                                class="each-Item custom-error"
                                [ngClass]="{ 'pad-b-15': WeirHeightInput?.errors?.pattern }"
                            >
                                <mat-form-field class="pad-t-6 full-width">
                                    <input
                                        matInput
                                        placeholder="{{ heightPlaceHolder }}"
                                        id="WeirHeightInput"
                                        #WeirHeightInput="ngModel"
                                        name="WeirHeight"
                                        [(ngModel)]="locationDetailsModel.installationHeight"
                                        required
                                        [pattern]="installationValuePattern"
                                        [disabled]="!editMode"
                                    />
                                </mat-form-field>
                                <div class="custom-error-message" *ngIf="WeirHeightInput?.errors?.pattern">
                                    {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                                </div>
                            </div>

                            <div
                                class="each-Item custom-error"
                                [ngClass]="{ 'pad-b-15': WeirConstantInput?.errors?.pattern }"
                            >
                                <mat-form-field class="pad-t-6 full-width">
                                    <input
                                        matInput
                                        placeholder="{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.CONSTANT' | translate }}"
                                        id="WeirConstantInput"
                                        #WeirConstantInput="ngModel"
                                        name="WeirConstant"
                                        [(ngModel)]="locationDetailsModel.coefficient"
                                        required
                                        [pattern]="installationValuePattern"
                                        [disabled]="!editMode"
                                    />
                                </mat-form-field>
                                <div class="custom-error-message" *ngIf="WeirConstantInput?.errors?.pattern">
                                    {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                                </div>
                            </div>
                        </div>
                        <div class="custom-50p">
                            <div class="each-Item custom-error">
                                <mat-form-field
                                    class="pad-t-6 full-width"
                                    [ngClass]="{ 'pad-b-15': WeirLengthInput?.errors?.pattern }"
                                >
                                    <input
                                        matInput
                                        placeholder="{{ lengthPlaceHolder }}"
                                        id="WeirLengthInput"
                                        #WeirLengthInput="ngModel"
                                        name="WeirLength"
                                        [(ngModel)]="locationDetailsModel.length"
                                        required
                                        [pattern]="installationValuePattern"
                                        [disabled]="!editMode"
                                    />
                                </mat-form-field>
                                <div class="custom-error-message" *ngIf="WeirLengthInput?.errors?.pattern">
                                    {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                                </div>
                            </div>
                            <div
                                class="each-Item custom-error"
                                [ngClass]="{ 'pad-b-15': WeirBreadthInput?.errors?.pattern }"
                            >
                                <mat-form-field class="pad-t-6 full-width">
                                    <input
                                        matInput
                                        placeholder="{{ breadthPlaceHolder }}"
                                        id="WeirBreadthInput"
                                        #WeirBreadthInput="ngModel"
                                        name="WeirBreadth"
                                        [(ngModel)]="locationDetailsModel.breadth"
                                        required
                                        [pattern]="installationValuePattern"
                                        [disabled]="!editMode"
                                    />
                                </mat-form-field>
                                <div class="custom-error-message" *ngIf="WeirBreadthInput?.errors?.pattern">
                                    {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Channel -->
                    <div
                        *ngIf="
                            locationDetailsModel.installationShapeTypeID > 499 &&
                            locationDetailsModel.installationShapeTypeID < 600
                        "
                    >
                        <div class="custom-error">
                            <mat-form-field class="pad-t-6 full-width">
                                <input
                                    matInput
                                    placeholder="{{
                                        'HOME.MAP.MARKER_LOCATION_DETAIL.CHANNEL_ENTRIES_LABEL' | translate
                                    }}"
                                    id="ChannelNoEntries"
                                    #ChannelNoEntries="ngModel"
                                    name="NoEntries"
                                    [(ngModel)]="numberOfEntries"
                                    maxlength="3"
                                    required
                                    (blur)="onBlurEntriesHandler($event)"
                                    [disabled]="!editMode"
                                    [pattern]="channelEntriesPattern"
                                />
                            </mat-form-field>
                            <div
                                class="custom-error-message"
                                *ngIf="ChannelNoEntries?.errors?.pattern || numberOfEntries === 0"
                            >
                                {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.CHANNEL_ENTRY_ERROR' | translate }}
                            </div>
                        </div>

                        <div
                            *ngFor="let entry of numberOfTimes; let i = index"
                            [attr.data-index]="i"
                            class="custom-50p"
                        >
                            <div
                                class="each-Item custom-error"
                                [ngClass]="{ 'pad-b-15': MinimumDepth?.errors?.pattern }"
                            >
                                <mat-form-field class="pad-t-6 full-width">
                                    <input
                                        matInput
                                        placeholder="{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.DEPTH' | translate }}"
                                        [(ngModel)]="depth[i]"
                                        name="MinimumDepth"
                                        id="MinimumDepth"
                                        #MinimumDepth="ngModel"
                                        maxlength="10"
                                        required
                                        [pattern]="depthQuantityPattern"
                                        [disabled]="!editMode"
                                        [attr.id]="'Depth' + depth[i]"
                                        value="0"
                                        name="MinimumDepth{{ i }}"
                                    />
                                </mat-form-field>
                                <div class="custom-error-message" *ngIf="MinimumDepth?.errors?.pattern">
                                    {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.SIX_PRECISION_ERROR' | translate }}
                                </div>
                            </div>
                            <div
                                class="each-Item custom-error"
                                [ngClass]="{ 'pad-b-15': MaximumDepth?.errors?.pattern }"
                            >
                                <mat-form-field class="pad-t-6 full-width">
                                    <input
                                        matInput
                                        placeholder="{{ quantityPlaceHolder }}"
                                        [(ngModel)]="quantity[i]"
                                        name="MaximumDepth"
                                        id="MaximumDepth"
                                        #MaximumDepth="ngModel"
                                        maxlength="10"
                                        required
                                        [pattern]="depthQuantityPattern"
                                        [disabled]="!editMode"
                                        [attr.id]="'Quantity' + quantity[i]"
                                        value="0"
                                        name="MaximumDepth{{ i }}"
                                    />
                                </mat-form-field>
                                <div class="custom-error-message" *ngIf="MaximumDepth?.errors?.pattern">
                                    {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.SIX_PRECISION_ERROR' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- END Installation Details -->
                </div>

                <div mat-dialog-actions>
                    <div class="app-flex-filler"></div>

                    <div class="edit-controls">
                        <div class="app-flex-filler"></div>
                        <div class="pre-edit-controls auto-margin">
                            <span
                                matTooltip="{{ 'HOME.MAP.LOCATION_DASHBOARD_TOOLTIP' | translate }}"
                                matTooltipPosition="above"
                            >
                                <button
                                    *ngIf="showLocationEditor"
                                    mat-button
                                    class="lightBackGround"
                                    type="button"
                                    (click)="goLocationDashboard()"
                                >
                                    <mat-icon class="mat-24">show_chart</mat-icon>
                                </button>
                            </span>
                            <span
                                matTooltip="{{ 'HOME.COLLECTION_WIDGET.COLLECT_BUTTON' | translate }}"
                                matTooltipPosition="above"
                                [ngClass]="{ collecting: isCollecting }"
                            >
                                <button
                                    *ngIf="showLocationEditor"
                                    [disabled]="isCollecting"
                                    mat-button
                                    class="lightBackGround"
                                    type="button"
                                    (click)="collectLocation(locationDetailsModel.locationID)"
                                >
                                    <mat-icon class="mat-24" *ngIf="!isCollecting">system_update_alt</mat-icon>
                                    <mat-progress-spinner
                                        mode="indeterminate"
                                        diameter="15"
                                        *ngIf="isCollecting"
                                        class="map-loc-details-collect"
                                    ></mat-progress-spinner>
                                </button>
                            </span>

                            <span
                                matTooltip="{{ 'HOME.MAP.SCHEDULR_COLLECTION_TOOLTIP' | translate }}"
                                matTooltipPosition="above"
                            >
                                <button
                                    [disabled]="!disableSchedule"
                                    *ngIf="showLocationEditor"
                                    mat-button
                                    class="lightBackGround"
                                    type="button"
                                    (click)="scheduleCollection()"
                                >
                                    <mat-icon class="mat-24">update</mat-icon>
                                </button>
                            </span>

                            <span matTooltip="{{ 'COMMON.EDIT_TOOLTIP' | translate }}" matTooltipPosition="above">
                                <button
                                    *ngIf="showLocationEditor"
                                    mat-button
                                    class="lightBackGround"
                                    type="button"
                                    (click)="editLocationDetails()"
                                >
                                    <mat-icon class="mat-24">mode_edit</mat-icon>
                                </button>
                            </span>

                            <span
                                matTooltip="{{
                                    'HOME.MAP.MARKER_LOCATION_DETAIL.ACTIVATE_MONITOR_TOOLTIP' | translate
                                }}"
                                matTooltipPosition="above"
                            >
                                <button
                                    *ngIf="showLocationEditor"
                                    [disabled]="!activateState || isSecondMontoringPoint"
                                    type="button"
                                    mat-button
                                    class="lightBackGround"
                                    (click)="activateLocation()"
                                >
                                    <mat-icon class="mat-24">format_color_text</mat-icon>
                                </button>
                            </span>
                        </div>
                        <div class="textTypeButton auto-margin">
                            <button
                                *ngIf="!showLocationEditor"
                                mat-button
                                (click)="cancelFormEditHandler($event); locationDetailsForm.form.markAsPristine()"
                            >
                                {{ 'COMMON.CANCEL_BUTTON' | translate }}
                            </button>
                            <button
                                *ngIf="!showLocationEditor"
                                mat-button
                                type="submit"
                                (click)="saveLocationDetails()"
                                [disabled]="
                                    !locationDetailsForm.form.valid ||
                                    locationDetailsForm.form.pristine ||
                                    assignedRainGaugeCtrl?.errors?.invalid ||
                                    (locationDetailsModel.installationShapeTypeID > 499 &&
                                        locationDetailsModel.installationShapeTypeID < 600 &&
                                        (depth.length < 2 || quantity.length < 2))
                                "
                            >
                                {{ 'COMMON.SAVE_BUTTON' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
