import { SelectableGroup } from './selectable';
import { AnnotationSettings, HydrographTickInterval } from './view-data';
import { ConfirmationEntitiesEnum } from './view-data-filter';

/**
 * Used as part of helping to identify the device; add as needed.
 */
export enum SeriesType {
    Unknown = 0,
    Echo,
    RainAlertI,
    RainAlertII,
    RainAlertIII,
    Triton,
    TritonPlus,
}

export class LocationDashboardFilterData {
    public locationIDs?: Array<number>;
    public startDate?: any;
    public endDate?: any;
    public entities?: SelectableGroup[];
    public customerID?: number;
    public locationName?: string;
    public tickInterval?: HydrographTickInterval;
    public annotationsSettings?: AnnotationSettings;
    public isCompositeLoc?: boolean;
    public blockageStatus?: Array<number>;
    public summarizeInterval?: number;
    public isResetScale?: boolean;
    public sgEntities?: SelectableGroup[];
    public editMode?: string;
    public confirmationEntity?: ConfirmationEntitiesEnum;

    /**
     * This property is meant to be used a filter to indicate if an
     * "Echo" device is present. The reason for its existance is to
     * lessen the amount of types that the series is parsed to find
     * out what kind of series the device is.
     */
    public seriesType?: SeriesType;
}
