<mat-card-content id="batteryWidgetId" class="widgetFixPagination">
    <app-widget-filters
        (sendDataToWidget)="notifyWidget($event)"
        [hidden]="!showBatteryStatusSearch"
        [filterSettings]="filterSettings"
        [customerID]="customerID"
        [locationGroupID]="locationGroupID"
        [toggleState]="toggleState"
        [includeInactiveLocations]="includeInactiveLocations"
    >
    </app-widget-filters>

    <app-loader [isLoading]="batteryWidgetLoadingState"></app-loader>
    <div
        [hidden]="!isdataAvailableUponFilter"
        *ngIf="batteryStatusData?.batteryStatusList?.length > 0"
        id="battery-chart"
    >
        <div [chart]="options" id="chart"></div>
    </div>
    <div
        *ngIf="batteryWidgetData?.length == 0 || !batteryWidgetData || !isdataAvailableUponFilter"
        class="notabledataFound custom-table"
    >
        <span
            >{{ 'COMMON.NO_TEXT' | translate }}
            <span *ngIf="!showBatteryStatusSearch">{{ 'HOME.BATTERY_WIDGET.CRITICAL_LOW_TEXT' | translate }} </span>
            {{ 'HOME.BATTERY_WIDGET.BATTERY_FOUND_TEXT' | translate }}
        </span>
    </div>
    <div
        [hidden]="batteryWidgetData?.length == 0 || !batteryWidgetData || !isdataAvailableUponFilter"
        id="batteryLeaderBoardTable"
        [ngClass]="{'filtersOff': !showBatteryStatusSearch, 'filtersOn': showBatteryStatusSearch }"
        class="widget-table"
    >
        <mat-table #table [dataSource]="batteryWidgetDataSource" matSort>
            <!-- LOCATION -->
            <ng-container matColumnDef="locationName">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="battery-sm-location-hint"
                    >{{ 'COMMON.LOCATION_COLUMN_TITLE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let batteryStatus; let i = index">
                    <div
                        class="nowrap-white-space fullScreenShow"
                        [ngClass]="{
                            customLongName: validateLocationNameLength(batteryStatus.locationName, 48),
                            'battery-locations-hint': i === 0
                        }"
                        [title]="
                            validateLocationNameLength(batteryStatus.locationName, 48) ? batteryStatus.locationName : ''
                        "
                        [innerHTML]="batteryStatus.locationName | sanitize: 'html'"
                    ></div>
                    <a
                        class="nowrap-white-space fullScreenHide"
                        [ngClass]="{ customLongName: validateLocationNameLength(batteryStatus.locationName, 25) }"
                        [title]="
                            validateLocationNameLength(batteryStatus.locationName, 25) ? batteryStatus.locationName : ''
                        "
                        (click)="getMarkerLocationDetails(batteryStatus.locationID)"
                        [innerHTML]="batteryStatus.locationName | sanitize: 'html'"
                    ></a>
                </mat-cell>
            </ng-container>

            <!-- seriesName -->
            <ng-container matColumnDef="seriesName">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="battery-sm-series-hint">
                    {{ 'HOME.BATTERY_WIDGET.SERIES_COLUMN_TITLE' | translate }}</mat-header-cell
                >
                <mat-cell *matCellDef="let batteryStatus">
                    <span>{{ batteryStatus.seriesName }}</span>
                </mat-cell>
            </ng-container>

            <!-- VOLTAGE (V) -->
            <ng-container matColumnDef="voltage">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="text-right fixCellWidth battery-sm-voltage-hint">
                    {{ 'HOME.BATTERY_WIDGET.VOLTAGE_COLUMN_TITLE' | translate }}</mat-header-cell
                >
                <mat-cell *matCellDef="let batteryStatus" class="text-right">
                    <span
                        matTooltip="{{ batteryStatus.day }}"
                        matTooltipPosition="above"
                        class="boxLabel"
                        [ngClass]="{
                            'green-bg': batteryStatus.status == 'GOOD',
                            'orange-bg': batteryStatus.status == 'LOW',
                            'red-bg': batteryStatus.status == 'CRITICAL'
                        }"
                        >{{ batteryStatus.voltage | number: '1.2-2' }}</span
                    >
                </mat-cell>
            </ng-container>

            <!-- Date -->
            <ng-container matColumnDef="day">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="text-right battery-sm-date-hint">
                    {{ 'HOME.BATTERY_WIDGET.TIME_STAMP_TITLE' | translate }}</mat-header-cell
                >
                <mat-cell *matCellDef="let batteryStatus" class="text-right">
                    <span>{{ batteryStatus.day | customDatePipe: dateFormat }}</span>
                </mat-cell>
            </ng-container>
            <!-- Status -->
            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="fix-cell-status-width">
                    {{ 'HOME.BATTERY_WIDGET.STATUS_COLUMN_TITLE' | translate }}</mat-header-cell
                >
                <mat-cell *matCellDef="let batteryStatus">
                    <span>{{ batteryStatus.status }}</span>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="batteryWidgetDisplayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: batteryWidgetDisplayedColumns"></mat-row>
        </mat-table>
    </div>
</mat-card-content>
<div class="widgetFooter" [ngClass]="{ 'footer-on-hint': availablePageHint }">
    <div class="auto-margin" [hidden]="totalPaginationLength <= 25 || !isdataAvailableUponFilter">
        <mat-paginator
            #batteryWidgetPaginator
            [length]="totalPaginationLength"
            [pageIndex]="0"
            [pageSize]="25"
            [pageSizeOptions]="[10, 25, 100]"
            (page)="changePage($event)"
        >
        </mat-paginator>
    </div>
    <div class="app-flex-filler"></div>
    <div [hidden]="toggleState || !isdataAvailableUponFilter" class="auto-margin custom-flat-button">
        <button class="uptimeOverviewDownloadHint battery-download-hint" (click)="downloadCSV()" mat-button>
            {{ 'COMMON.DOWNLOAD_BTN' | translate }}
        </button>
    </div>
    <div class="auto-margin battery-buy-hint battery-sm-buy-now-hint" [hidden]="!isdataAvailableUponFilter">
        <button mat-button (click)="navigate()">{{ 'COMMON.BUY_NOW_BUTTON' | translate }}</button>
    </div>
</div>
