<div class="notification-dashboard">
    <div class="notification-details mat-box-shadow">
        <div class="title-bar alarm-title-bar">
            <mat-icon class="backIcon" (click)="backToAllNotifications()">arrow_back</mat-icon>
            <div *ngIf="notificationId" class="title">
                {{ 'COMMON.EDIT' | translate }} - {{ PAGE_TEXT.NOTIFICATION_TYPES_TEXT.DAILY }}
            </div>
            <div *ngIf="!notificationId" class="title">
                {{ 'COMMON.ADD_TEXT' | translate }} - {{ PAGE_TEXT.NOTIFICATION_TYPES_TEXT.DAILY }}
            </div>
        </div>

        <div class="alarm-title-bar">
            <mat-form-field class="full-width">
                <input
                    #nameField
                    matInput
                    (focusout)="checkName()"
                    placeholder="{{ PAGE_TEXT.COLUMN_NAMES.NAME }}"
                    [disabled]="subscribeOnly || selectedIndex === 4"
                    [(ngModel)]="notificationObject.name"
                    maxlength="25"
                    ngDefaultControl
                    required
                />
            </mat-form-field>
            <mat-form-field class="full-width nd-standard-description">
                <input
                    #descField
                    matInput
                    placeholder="{{ PAGE_TEXT.COLUMN_NAMES.DESCRIPTION }}"
                    [disabled]="subscribeOnly"
                    [(ngModel)]="notificationObject.desc"
                    maxlength="100"
                    ngDefaultControl
                />
            </mat-form-field>
            <div class="input-design">
                <span>{{ PAGE_TEXT.COLUMN_NAMES.ENABLE }}</span>
                <mat-slide-toggle class="nd-standard-enable"
                    #enabledToggle
                    [checked]="notificationObject.enabled"
                    [disabled]="subscribeOnly"
                    (change)="toggleNotificationEnabled($event)"
                ></mat-slide-toggle>
            </div>
        </div>

        <mat-tab-group
            *ngIf="notificationObject && subscribeOnly !== undefined"
            [selectedIndex]="selectedIndex"
            (selectedTabChange)="tabChanged($event)"
            dynamicHeight
        >
            <mat-tab label="{{ PAGE_TEXT.ALARM_PAGES_TABS.LOCATIONS }}" [disabled]="disableTab(0)">
                <div *ngIf="!loadingItems.locations" class="location-tab">
                    <div class="tab-header">
                        <div class="min-width">
                            <mat-checkbox [checked]="allLocationsChecked" (change)="checkAllLocations()"></mat-checkbox>
                            {{ 'COMMON.SELECT_ALL' | translate }}
                        </div>
                        <mat-form-field class="full-width">
                            <mat-icon matPrefix class="search-icon">search</mat-icon>
                            <input
                                matInput
                                type="text"
                                placeholder="{{ 'COMMON.SEARCH_TEXT' | translate }}"
                                [(ngModel)]="locationSearchString"
                                (input)="locationSetSearchAndGroup()"
                                ngDefaultControl
                            />
                        </mat-form-field>
                        <mat-form-field class="min-width nd-location-select">
                            <mat-select
                                [(ngModel)]="selectedLocationGroup"
                                (ngModelChange)="locationSetSearchAndGroup()"
                                ngDefaultControl
                                placeholder="{{ 'COMMON.LOCATION_GROUP' | translate }}"
                            >
                                <mat-option *ngFor="let customer of locationGroupOptions" [value]="customer">{{
                                    customer
                                }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="location-list">
                        <div class="loc" *ngFor="let location of dispLocationsOptions">
                            <mat-checkbox
                                [checked]="location.checked"
                                (change)="updateLocation(location)"
                            ></mat-checkbox>
                            {{ location.name }}
                        </div>
                        <div *ngIf="dispLocationsOptions.length === 0">{{ PAGE_TEXT.NO_LOCATIONS }}</div>
                    </div>
                </div>
                <div *ngIf="loadingItems.locations" class="main-section">
                    <mat-progress-spinner diameter="25" mode="indeterminate" color="primary"></mat-progress-spinner>
                </div>
            </mat-tab>
            <mat-tab label="{{ PAGE_TEXT.ALARM_PAGES_TABS.CONFIGURATION }}" [disabled]="disableTab(1)">
                <div class="configuration-tab">
                    <div class="col">
                        <div>
                            <mat-slide-toggle class="nd-standard-communication"
                                [checked]="notificationTypeChecked(NotificationDailyReportDRIDS.COMMUNICATION_REPORT)"
                                (change)="notificationTypeToggle(NotificationDailyReportDRIDS.COMMUNICATION_REPORT)"
                                >{{ PAGE_TEXT.CONFIGURATION_PAGES_TABS.COMMUNICATION_ISSUES }}
                            </mat-slide-toggle>
                            <div
                                class="desc"
                                [innerHTML]="PAGE_TEXT.CONFIGURATION_PAGES_TABS.COMMUNICATION_ISSUES_DESC"
                            ></div>
                        </div>
                        <div>
                            <mat-slide-toggle class="nd-standard-low_battery"
                                [checked]="notificationTypeChecked(NotificationDailyReportDRIDS.LOW_BATTERY)"
                                (change)="notificationTypeToggle(NotificationDailyReportDRIDS.LOW_BATTERY)"
                                >{{ PAGE_TEXT.CONFIGURATION_PAGES_TABS.LOW_BATTERY }}
                            </mat-slide-toggle>
                            <div class="desc" [innerHTML]="PAGE_TEXT.CONFIGURATION_PAGES_TABS.LOW_BATTERY_DESC"></div>
                        </div>
                    </div>
                    <div class="col">
                        <h4 class="no-margin text-top-space">{{ PAGE_TEXT.CONFIGURATION_PAGES_TABS.SELECT_DAYS }}</h4>
                        <div class="days-container">
                            <mat-checkbox class="nd-standard-select_days"
                                *ngFor="let day of listOfDays"
                                (change)="daySelect(day)"
                                [(checked)]="day.isSelected"
                                name="chkboxday"
                                required
                            >
                                <div class="each-day">{{ day.text }}</div>
                            </mat-checkbox>
                        </div>
                        <div class="custom-error">
                            <mat-form-field class="nd-standard-report_at">
                                <span>
                                    <input
                                        type="text"
                                        matInput
                                        [value]="notificationObject.time"
                                        (change)="checkTimeValue($event)"
                                        placeholder="{{ PAGE_TEXT.CONFIGURATION_PAGES_TABS.SEND_REPORT_AT }}"
                                        tabindex="-1"
                                        class="datepickerStyles"
                                        required
                                        appDatetimepickerPosition
                                        [matDatetimepickerComp]="collectTimePicker"
                                    />
                                </span>
                                <input
                                    matInput
                                    hidden
                                    [matDatetimepicker]="collectTimePicker"
                                    [value]="collectTime"
                                    (dateInput)="onDateInput($event)"
                                    (dateChange)="onDateChange($event)"
                                    [format]="12"
                                    placeholder="{{ PAGE_TEXT.CONFIGURATION_PAGES_TABS.SEND_REPORT_AT }}"
                                    tabindex="-1"
                                />
                                <mat-datetimepicker-toggle
                                    matSuffix
                                    [for]="collectTimePicker"
                                ></mat-datetimepicker-toggle>
                                <mat-datetimepicker #collectTimePicker type="time"></mat-datetimepicker>
                            </mat-form-field>
                            <div class="custom-error-message" style="margin-left: 15px">
                                <span *ngIf="timeError">{{ 'HOME.WIDGET_SCHEDULE.INVALID_TIME' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{ PAGE_TEXT.ALARM_PAGES_TABS.NOTIFICATION_TREE }}" [disabled]="disableTab(2)">
                <div class="tree-tab">
                    <div class="new-button">
                        <button
                            mat-button
                            [disabled]="notificationObject.levels && notificationObject.levels.length > 0"
                            (click)="addNotificationLevel()"
                        >
                            <mat-icon>add</mat-icon>{{ PAGE_TEXT.ADD_LEVEL }}
                        </button>
                    </div>
                    <table
                        *ngIf="notificationObject.levels.length > 0"
                        mat-table
                        [dataSource]="notificationObject.levels"
                    >
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef>{{ PAGE_TEXT.COLUMN_NAMES.NAME }}</th>
                            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                        </ng-container>
                        <ng-container matColumnDef="delay">
                            <th mat-header-cell *matHeaderCellDef>{{ PAGE_TEXT.COLUMN_NAMES.DELAY }}</th>
                            <td mat-cell *matCellDef="let element">{{ element.delay }}</td>
                        </ng-container>
                        <ng-container matColumnDef="userCount">
                            <th mat-header-cell *matHeaderCellDef>{{ PAGE_TEXT.COLUMN_NAMES.USER_COUNT }}</th>
                            <td mat-cell *matCellDef="let element">{{ element.users.length }}</td>
                        </ng-container>
                        <ng-container matColumnDef="enable">
                            <th mat-header-cell *matHeaderCellDef>{{ PAGE_TEXT.COLUMN_NAMES.ENABLE }}</th>
                            <td mat-cell *matCellDef="let element">
                                <mat-slide-toggle
                                    #enabledLevelToggle
                                    [checked]="element.enabled"
                                    (change)="toggleNotificationLevelEnabled($event, element)"
                                >
                                </mat-slide-toggle>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="options">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button (click)="editNotificationLevel(element)">
                                    <mat-icon matTooltip="Edit">mode_edit</mat-icon>
                                </button>
                                <button mat-icon-button (click)="deleteNotificationLevel(element)">
                                    <mat-icon matTooltip="Delete">delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="notificationTreeColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: notificationTreeColumns"></tr>
                    </table>
                    <div *ngIf="notificationObject.levels.length === 0">{{ PAGE_TEXT.NO_LEVELS }}</div>
                </div>
            </mat-tab>
            <mat-tab label="{{ PAGE_TEXT.ALARM_PAGES_TABS.VALIDATION }}" [disabled]="disableTab(3)">
                <div *ngIf="!loadingItems.validations" class="validation-tab">
                    <div *ngIf="validationInfo.errors.length > 0 || validationInfo.fieldErrors.length > 0">
                        <div class="tab-header">
                            <div><mat-icon class="error">cancel</mat-icon>{{ PAGE_TEXT.ERRORS }}</div>
                            <mat-icon *ngIf="!showErrors" (click)="showErrors = true">arrow_drop_down</mat-icon>
                            <mat-icon *ngIf="showErrors" (click)="showErrors = false">arrow_drop_up</mat-icon>
                        </div>
                        <div *ngIf="showErrors && validationInfo.fieldErrors.length > 0" class="text">
                            <ul>
                                <li *ngFor="let item of validationInfo.fieldErrors">{{ item.message }}</li>
                            </ul>
                        </div>
                        <div *ngIf="showErrors && validationInfo.errors.length > 0">
                            <div class="items" *ngFor="let item of validationInfo.errors">
                                <a class="loc nd-errors" (click)="openLocationCard(item.lid)">{{ item.location }}</a>
                                <div class="text nd-text">
                                    <ul>
                                        <li *ngFor="let itm of item.items">
                                            <span
                                                *ngIf="itm.error !== NotificationValidationError.AlarmNotConfigured"
                                                >{{ itm.text }}</span
                                            >
                                        </li>
                                    </ul>
                                    <a class="nd-remove"
                                        matTooltip="{{ PAGE_TEXT.ERROR_TEXT.REMOVE_LOCATION }}"
                                        (click)="removeLocationFromList(item.lid)"
                                        >{{ PAGE_TEXT.REMOVE_LOCATION }}</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="validationInfo.warnings.length > 0 || validationInfo.fieldWarnings.length > 0">
                        <div class="tab-header">
                            <div>
                                <mat-icon class="warning" fontSet="material-icons-round">warning</mat-icon
                                >{{ PAGE_TEXT.WARNINGS }}
                            </div>
                            <mat-icon *ngIf="!showWarnings" (click)="showWarnings = true">arrow_drop_down</mat-icon>
                            <mat-icon *ngIf="showWarnings" (click)="showWarnings = false">arrow_drop_up</mat-icon>
                        </div>
                        <div *ngIf="showWarnings && validationInfo.fieldWarnings.length > 0" class="text">
                            <ul>
                                <li *ngFor="let item of validationInfo.fieldWarnings">{{ item.message }}</li>
                            </ul>
                        </div>
                        <div *ngIf="showWarnings && validationInfo.warnings.length > 0">
                            <div class="items" *ngFor="let item of validationInfo.warnings">
                                <div class="loc">{{ item.location }}</div>
                                <div class="text">
                                    <ul>
                                        <li *ngFor="let itm of item.items">{{ itm.text }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="
                            validationInfo.warnings.length === 0 &&
                            validationInfo.fieldWarnings.length === 0 &&
                            validationInfo.errors.length === 0 &&
                            validationInfo.fieldErrors.length === 0
                        "
                    >
                        {{ PAGE_TEXT.NO_ERRORS_WARNINGS }}
                    </div>
                </div>
                <div *ngIf="loadingItems.validations" class="main-section">
                    <mat-progress-spinner diameter="25" mode="indeterminate" color="primary"></mat-progress-spinner>
                </div>
            </mat-tab>
            <mat-tab label="{{ PAGE_TEXT.ALARM_PAGES_TABS.CONFIRM_CONFIGURATIONS }}" [disabled]="disableTab(4)">
                <div class="confirmation-tab">
                    <div class="col">
                        <p class="nd-summary-location">{{ PAGE_TEXT.CONFIRMATION_TEXT.THESE_LOCATIONS }}</p>
                        <mat-selection-list class="confirm-list" *ngIf="locationsOptions">
                            <mat-list-option
                                *ngFor="let loc of locationsOptions | onlyCheckedOptions"
                                [disabled]="true"
                                [selected]="loc.checked"
                                >{{ loc.name }}</mat-list-option
                            >
                        </mat-selection-list>
                    </div>
                    <div class="col configuration nd-summary-alarm">
                        <div class="col-conf">
                            <div>
                                <mat-slide-toggle
                                    [checked]="
                                        notificationTypeChecked(NotificationDailyReportDRIDS.COMMUNICATION_REPORT)
                                    "
                                    [disabled]="true"
                                    >{{ PAGE_TEXT.CONFIGURATION_PAGES_TABS.COMMUNICATION_ISSUES }}</mat-slide-toggle
                                >
                                <div
                                    class="desc"
                                    [innerHTML]="PAGE_TEXT.CONFIGURATION_PAGES_TABS.COMMUNICATION_ISSUES_DESC"
                                ></div>
                            </div>
                            <div>
                                <mat-slide-toggle
                                    [checked]="notificationTypeChecked(NotificationDailyReportDRIDS.LOW_BATTERY)"
                                    [disabled]="true"
                                    >{{ PAGE_TEXT.CONFIGURATION_PAGES_TABS.LOW_BATTERY }}</mat-slide-toggle
                                >
                                <div
                                    class="desc"
                                    [innerHTML]="PAGE_TEXT.CONFIGURATION_PAGES_TABS.LOW_BATTERY_DESC"
                                ></div>
                            </div>
                        </div>
                        <div class="col-conf">
                            <h4 class="no-margin text-top-space">
                                {{ PAGE_TEXT.CONFIGURATION_PAGES_TABS.SELECT_DAYS }}
                            </h4>
                            <div class="days-container">
                                <mat-checkbox
                                    *ngFor="let day of listOfDays"
                                    [checked]="day.isSelected"
                                    name="chkboxday"
                                    [disabled]="true"
                                >
                                    <div class="each-day">{{ day.text }}</div>
                                </mat-checkbox>
                            </div>
                            <div>
                                <mat-form-field>
                                    <span>
                                        <input
                                            matInput
                                            [value]="notificationObject.time"
                                            [disabled]="true"
                                            placeholder="{{ PAGE_TEXT.CONFIGURATION_PAGES_TABS.SEND_REPORT_AT }}"
                                            tabindex="-1"
                                            class="datepickerStyles"
                                        />
                                    </span>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <p class="nd-summary-levels">{{ PAGE_TEXT.CONFIRMATION_TEXT.ESCALATION }}</p>
                        <div class="table-wrap">
                            <table>
                                <tr>
                                    <th>{{ PAGE_TEXT.COLUMN_NAMES.NAME }}</th>
                                    <th>{{ PAGE_TEXT.COLUMN_NAMES.DELAY }}</th>
                                    <th>{{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.USERS }}</th>
                                    <th *ngIf="subscribeOnly">{{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.ALARM }}</th>
                                    <th *ngIf="subscribeOnly">{{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.RTN }}</th>
                                </tr>
                                <ng-container *ngFor="let level of notificationObject.levels; index as i">
                                    <tr>
                                        <td class="user-col">
                                            <mat-icon
                                                *ngIf="
                                                    !subscribeOnly &&
                                                    level.users.length > 0 &&
                                                    !confirmationShowUsers[i]
                                                "
                                                (click)="confirmationShowUsers[i] = true"
                                                >arrow_right</mat-icon
                                            >
                                            <mat-icon
                                                *ngIf="
                                                    !subscribeOnly && level.users.length > 0 && confirmationShowUsers[i]
                                                "
                                                (click)="confirmationShowUsers[i] = false"
                                                >arrow_drop_down</mat-icon
                                            >
                                            {{ level.name }}
                                        </td>
                                        <td>{{ level.delay }}</td>
                                        <td>{{ level.users.length }}</td>
                                        <td *ngIf="subscribeOnly">
                                            <mat-icon
                                                *ngIf="!subscribeUser[i].alarm"
                                                matTooltip="Subscribe"
                                                (click)="updateSubscription(i, true)"
                                                >email</mat-icon
                                            >
                                            <mat-icon
                                                *ngIf="subscribeUser[i].alarm"
                                                matTooltip="Unsubscribe"
                                                (click)="updateSubscription(i, true)"
                                                >unsubscribe</mat-icon
                                            >
                                        </td>
                                        <td *ngIf="subscribeOnly">
                                            <mat-icon
                                                *ngIf="!subscribeUser[i].rtn"
                                                matTooltip="Subscribe"
                                                (click)="updateSubscription(i, false)"
                                                >email</mat-icon
                                            >
                                            <mat-icon
                                                *ngIf="subscribeUser[i].rtn"
                                                matTooltip="Unsubscribe"
                                                (click)="updateSubscription(i, false)"
                                                >unsubscribe</mat-icon
                                            >
                                        </td>
                                    </tr>
                                    <tr *ngIf="confirmationShowUsers[i]">
                                        <td class="user-row" colspan="3">
                                            {{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.USERS }}:
                                            <p *ngFor="let usr of convertIDtoUserNames(level ? level.users : [])">
                                                {{ usr }}
                                            </p>
                                        </td>
                                    </tr>
                                </ng-container>
                            </table>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>

        <div *ngIf="loadingItems.page" class="main-section">
            <mat-progress-spinner diameter="25" mode="indeterminate" color="primary"></mat-progress-spinner>
        </div>

        <div class="alarm-footer-bar" *ngIf="!subscribeOnly">
            <button class="footer-back" (click)="uiChangeTab(true, false)" *ngIf="selectedIndex !== 0" mat-button>
                {{ 'COMMON.BACK_TEXT' | translate }}
            </button>
            <button class="footer-next"
                (click)="uiChangeTab(true, true)"
                *ngIf="selectedIndex !== 4"
                [disabled]="!allowNext(selectedIndex)"
                mat-button
            >
                {{ 'COMMON.NEXT_BTN' | translate }}
            </button>
            <button (click)="confirm()" *ngIf="selectedIndex === 4" mat-button>
                {{ 'COMMON.CONFIRM_BTN' | translate }}
            </button>
        </div>
    </div>
</div>
