import { DepthPreferences, VelocityPreferences } from "../components/location-card/location-card.constants";

export enum DeviceTypeCode {
    PeakCombo1 = 232,
    AVGated1 = 233,
    SurfaceCombo1 = 234,
    TritonSmartBat1 = 235,
    Modem = 236,
    PeakCombo2 = 237,
    AVGated2 = 238,
    SurfaceCombo2 = 239,
    TritonSmartBat2 = 240,
    PeakSL1 = 241,
    PeakSL2 = 242,
    DataDelivery = 243,
    FtpDiagnostics = 244,
    TritonAnalog = 245,
    Clock = 246,
    XIO = 247,
    Notification = 248,
    LongRangeDepth1 = 249,
    LongRangeDepth2 = 250,
    EchoRain = 251,
    EchoAdvanced = 252,
    EchoDataDelivery = 253,
    EchoClock = 254,
    EchoAlarm1 = 255,
    EchoAlarm2 = 256,
    Inclinometer = 257,
    FlowLoss1 = 258,
    FlowLoss2 = 259,
    EchoBattery = 10000,
    EchoTemperature = 10001,
    EchoNotification = 10002,
    EchoModem = 10003,
    ForeSiteRadar = 20002
}

export enum DepthDeviceType {
    UpwardCh1,
    UpwardCh2,
    LongRangeCh1,
    LongRangeCh2,
    UltrasonicCh1,
    UltrasonicCh2,
    PressureCh1,
    PressureCh2,
    Auto = 100
}

export enum DepthDeviceName {
    UpwardCh1 = 'UpwardCh1',
    UpwardCh2 = 'UpwardCh2',
    LongRangeCh1 = 'LongRangeCh1',
    LongRangeCh2 = 'LongRangeCh2',
    UltrasonicCh1 = 'UltrasonicCh1',
    UltrasonicCh2 = 'UltrasonicCh2',
    PressureCh1 = 'PressureCh1',
    PressureCh2 = 'PressureCh2',
    Auto = 'Auto'
}

export enum VelocityDeviceType {
    PeakCh1,
    PeakCh2,
    SurfaceCh1,
    SurfaceCh2,
    AvGatedCh1,
    AvGatedCh2,
    Auto = 100
}

export enum VelocityDeviceName {
    PeakCh1 = 'PeakCh1',
    PeakCh2 = 'PeakCh2',
    SurfaceCh1 = 'SurfaceCh1',
    SurfaceCh2 = 'SurfaceCh2',
    AvGatedCh1 = 'AvGatedCh1',
    AvGatedCh2 = 'AvGatedCh2',
    Auto = 'Auto'
}

export enum SensorNames {
    peakCombo1 = 'AV|Max / Peak Combo 1',
    avGated1 = 'AV|Gated 1',
    smart1 = 'ParaDepth / Smart Depth 1',
    surface1 = 'ParaFlow / Surface Combo 1',
    lrd1 = 'Long Range Depth 1',
    peakCombo2 = 'AV|Max / Peak Combo 2',
    avGated2 = 'AV|Gated 2',
    smart2 = 'ParaDepth / Smart Depth 2',
    surface2 = 'ParaFlow / Surface Combo 2',
    lrd2 = 'Long Range Depth 2',
    radar = 'Radar',
}

export const SensorToDepthPreferencesMap: Map<string, string[]> = new Map([
    [SensorNames.avGated1, [DepthDeviceName.Auto, DepthDeviceName.UpwardCh1, DepthDeviceName.PressureCh1]],
    [SensorNames.avGated2, [DepthDeviceName.Auto, DepthDeviceName.UpwardCh2, DepthDeviceName.PressureCh2]],

    [SensorNames.peakCombo1, [DepthDeviceName.Auto, DepthDeviceName.UpwardCh1, DepthDeviceName.PressureCh1]],
    [SensorNames.peakCombo2, [DepthDeviceName.Auto, DepthDeviceName.UpwardCh2, DepthDeviceName.PressureCh2]],

    [SensorNames.surface1, [DepthDeviceName.Auto, DepthDeviceName.UltrasonicCh1]],
    [SensorNames.surface2, [DepthDeviceName.Auto, DepthDeviceName.UltrasonicCh2]],

    [SensorNames.smart1, [DepthDeviceName.Auto, DepthDeviceName.UltrasonicCh1]],
    [SensorNames.smart2, [DepthDeviceName.Auto, DepthDeviceName.UltrasonicCh2]],

    [SensorNames.lrd1, [DepthDeviceName.Auto, DepthDeviceName.LongRangeCh1]],
    [SensorNames.lrd2, [DepthDeviceName.Auto, DepthDeviceName.LongRangeCh2]],
]);

export const SensorToVelocityPreferencesMap: Map<string, string[]> = new Map([
    [SensorNames.avGated1, [VelocityDeviceName.Auto, VelocityDeviceName.AvGatedCh1]],
    [SensorNames.avGated2, [VelocityDeviceName.Auto, VelocityDeviceName.AvGatedCh2]],

    [SensorNames.peakCombo1, [VelocityDeviceName.Auto, VelocityDeviceName.PeakCh1]],
    [SensorNames.peakCombo2, [VelocityDeviceName.Auto, VelocityDeviceName.PeakCh2]],

    [SensorNames.surface1, [VelocityDeviceName.Auto, VelocityDeviceName.SurfaceCh1]],
    [SensorNames.surface2, [VelocityDeviceName.Auto, VelocityDeviceName.SurfaceCh2]],
]);

export namespace DeviceTypeCode {
    export function getDeviceTypeName(code: DeviceTypeCode): string {
        switch (code) {
            case DeviceTypeCode.PeakCombo1:
                return 'Peak Combo 1';
            case DeviceTypeCode.AVGated1:
                return 'AV|Gated 1';
            case DeviceTypeCode.SurfaceCombo1:
                return 'Surface Combo 1';
            case DeviceTypeCode.TritonSmartBat1:
                return 'Smart Depth 1';
            case DeviceTypeCode.PeakCombo2:
                return 'Peak Combo 2';
            case DeviceTypeCode.AVGated2:
                return 'AV|Gated 2';
            case DeviceTypeCode.SurfaceCombo2:
                return 'Surface Combo 2';
            case DeviceTypeCode.TritonSmartBat2:
                return 'Smart Depth 2';
            case DeviceTypeCode.LongRangeDepth1:
                return 'Long Range Depth 1';
            case DeviceTypeCode.LongRangeDepth2:
                return 'Long Range Depth 2';
            default:
                return;
        }
    }
    export function getDeviceTypeCode(name: string): DeviceTypeCode {
        switch (name) {
            case 'Peak Combo 1':
            case 'AV|Max 1':
            case 'AV|Max / Peak Combo 1':
                return DeviceTypeCode.PeakCombo1;
            case 'AV|Gated 1':
                return DeviceTypeCode.AVGated1;
            case 'Surface Combo 1':
            case 'ParaFlow 1':
            case 'ParaFlow / Surface Combo 1':
                return DeviceTypeCode.SurfaceCombo1;
            case 'Smart Depth 1':
            case 'ParaDepth 1':
            case 'ParaDepth / Smart Depth 1':
                return DeviceTypeCode.TritonSmartBat1;
            case 'Peak Combo 2':
            case 'AV|Max 2':
            case 'AV|Max / Peak Combo 2':
                return DeviceTypeCode.PeakCombo2;
            case 'AV|Gated 2':
                return DeviceTypeCode.AVGated2;
            case 'Surface Combo 2':
            case 'ParaFlow 2':
            case 'ParaFlow / Surface Combo 2':
                return DeviceTypeCode.SurfaceCombo2;
            case 'Smart Depth 2':
            case 'ParaDepth 2':
            case 'ParaDepth / Smart Depth 2':
                return DeviceTypeCode.TritonSmartBat2;
            case 'Long Range Depth 1':
                return DeviceTypeCode.LongRangeDepth1;
            case 'Long Range Depth 2':
                return DeviceTypeCode.LongRangeDepth2;
            case 'Radar':
                return DeviceTypeCode.ForeSiteRadar;
            default:
                return;
        }
    }

    export function getDepthPreferenceBySensor(sensorName: string) {
        const depthNames = SensorToDepthPreferencesMap.get(sensorName);

        return depthNames.map((name: string) => DepthPreferences.get(name));
    }

    export function getVelocityPreferenceBySensor(sensorName: string) {
        const velNames = SensorToVelocityPreferencesMap.get(sensorName);

        return velNames ? velNames.map((name: string) => VelocityPreferences.get(name)) : null;
    }
}
