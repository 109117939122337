import { Injectable } from '@angular/core';
import { Config } from './config';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { APIDataLocationsGetLocations } from '../models/customer-location-detail';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class CustomerDetailLocationService {
    constructor(public http: HttpClient, public activatedRoute: ActivatedRoute) {}

    public getLocationSummary(customerId: number, includeInactiveLocations?: boolean) {
        return this.http
            .get<APIDataLocationsGetLocations>(
                Config.serviceUrl + Config.urls.customerLocationsSummary + `${customerId}&includeInactiveLocations=${includeInactiveLocations}`,
            )
            .pipe(
                map((res) => {
                    if (res) {
                        return res;
                    }
                }),
            );
    }

    public editLocation(customerId: number, locationId: number, activeState: boolean) {
        const postfixLocation = '&locationId=';
        const postfixUriActive = '&isActive=';

        const headers = new Headers();
        headers.append('Content-Type', 'charset=utf-8');

        const url = Number(customerId) + postfixLocation + Number(locationId) + postfixUriActive + activeState;

        const res = this.http.put<any>(Config.serviceUrl + Config.urls.customerLocationActivation + url, {
            headers: headers,
        });

        catchError((error) => {
            console.log(error.json());
            return res;
        });
        return res;
    }
}
