import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UnitOfMeasureType } from 'app/shared/constant';
import { Customer } from 'app/shared/models/customer';
import { CustomerService } from 'app/shared/services/customer.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { PrimarySensors, SecondarySensors } from '../location-card.constants';

export const MAX_VELOCITY_METRIC_DEFAULT_VALUE = 0.5;
export const MAX_VELOCITY_NON_METRIC_DEFAULT_VALUE = 2;

const TRITON_DIALOG_WIDTH = 830;
const TRITON_DIALOG_HEIGHT = 1000;

const COMPOSITE_DIALOG_WIDTH = 630;
const COMPOSITE_DIALOG_HEIGHT = 550;

@Injectable()
export class LocationCardService {
    public isMetric: boolean;
    public currentCustomer: Customer;

    public elevationUnit: string;
    public depthUnit: string;
    public tempUnit: string;
    public flowUnit: string;

    public maxVelocityDefaultValue: number;
    public minVelocityRange: number;
    public maxVelocityRange: number;
    public comportValues = ['None', 'Inclinometer', 'Sampler'];
    constructor(
        private customerService: CustomerService,
        private translateService: TranslateService,
        private dateUtilService: DateutilService,
    ) {}

    public getCustomerSetting() {
        this.currentCustomer = this.customerService.customer;

        this.isMetric =
            this.currentCustomer.unitsType && Number(this.currentCustomer.unitsType) === UnitOfMeasureType.METRIC;
        this.setMeasuringUnits();
    }

    public addDepthUnitToPlaceholder(placeholder: string, isElevation: boolean = false) {
        // If installation type is Foresite ELEVATION then use feet or meters
        return isElevation ? `${placeholder} (${this.elevationUnit})` : `${placeholder} (${this.depthUnit})`;
    }

    public addFlowUnitToPlaceholder(placeholder: string) {
        return `${placeholder} (${this.flowUnit})`;
    }

    public addTempUnitToPlaceholder(placeholder: string) {
        return `${placeholder} (${this.tempUnit})`;
    }

    public filterMonitorConfigs(configs) {
        return configs.devices.filter((v) => [...PrimarySensors, ...SecondarySensors].find((i) => v.name === i.value));
    }

    public checkLocationCardPosition(isTritonDialog: boolean, { top, left }: { top: string; left: string }) {
        const locationPositionY = top.replace('px', '');
        const locationPositionX = left.replace('px', '');

        return this.getLocationCardPosition(isTritonDialog, {  locationPositionX, locationPositionY});
    }

    public getLocationCardPosition(isTritonDialog: boolean, { locationPositionY, locationPositionX }: { locationPositionY: string; locationPositionX: string; }) {
        const dialogHeight = isTritonDialog ? TRITON_DIALOG_HEIGHT : COMPOSITE_DIALOG_HEIGHT;
        const dialogWidth = isTritonDialog ? TRITON_DIALOG_WIDTH : COMPOSITE_DIALOG_WIDTH;

        let xPosition = (Number(locationPositionX) < 0) ? 0 : Number(locationPositionX);
        let yPosition = (Number(locationPositionY) < 0) ? 0 : Number(locationPositionY);


        if (xPosition > (window.innerWidth - dialogWidth)) {
            xPosition = window.innerWidth - dialogWidth;
        }

        if (yPosition > (window.innerHeight - dialogHeight)) {
            yPosition = window.innerHeight - dialogHeight;
        }

        return { top: yPosition + 'px', left: xPosition + 'px' };
    }

    /**
     * Convert to date time with respect to date format
     * @param inputDate
     */
    public convertDate(apiDate: string): string {
        if (apiDate) {
            const dateFormat = this.dateUtilService.dateFormat.getValue().toUpperCase();
            let datePart = '';
            let timePart = '';
            const dateObj = new Date(apiDate);
            const y = dateObj.getFullYear();
            let m = '' + (dateObj.getMonth() + 1),
                d = '' + dateObj.getDate(),
                h = dateObj.getHours(),
                mm = dateObj.getMinutes().toString();

            if (m.length < 2) m = '0' + m;
            if (d.length < 2) d = '0' + d;
            if (mm.length < 2) mm = '0' + mm;

            if (dateFormat === 'MM/DD/YYYY') {
                datePart = m + '/' + d + '/' + y;
            } else if (dateFormat === 'YYYY/MM/DD') {
                datePart = y + '/' + m + '/' + d;
            } else if (dateFormat === 'DD/MM/YYYY') {
                datePart = d + '/' + m + '/' + y;
            }

            const timeFormat = this.dateUtilService.timeFormat.getValue().toUpperCase();

            if (timeFormat === 'H:MM:SS TT') {
                const ampm = h >= 12 ? 'pm' : 'am';
                h = h % 12;
                h = h ? h : 12;
                if (mm < '10') mm = '0' + mm;
                timePart = h + ':' + mm + ' ' + ampm;
            } else {
                timePart = h + ':' + mm;
            }
            return datePart + ' ' + timePart;
        } else {
            return '';
        }
    }

    private setMeasuringUnits() {
        const celciusTemp = this.translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.CELSIUS_TEMP_UNITS');
        const fahrenheitTemp = this.translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.FAHRENHEIT_TEMP_UNITS');
        const metricUnits = this.translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.METRIC_UNITS');
        const usUnits = this.translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.US_UNITS');
        const elevationMetricUnits = this.translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.METER_UNITS');
        const elevationStandardUnits = this.translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.FEET_UNITS');

        this.tempUnit = this.isMetric ? celciusTemp : fahrenheitTemp;
        this.depthUnit = this.isMetric ? metricUnits : usUnits;
        this.elevationUnit = this.isMetric ? elevationMetricUnits : elevationStandardUnits;
        this.flowUnit = this.dateUtilService.volumnePerTimeUnit.getValue();

        this.maxVelocityDefaultValue = this.isMetric
            ? MAX_VELOCITY_METRIC_DEFAULT_VALUE
            : MAX_VELOCITY_NON_METRIC_DEFAULT_VALUE;
        this.minVelocityRange = this.isMetric ? 0.5 : 2;
        this.maxVelocityRange = this.isMetric ? 9 : 30;
    }
}
