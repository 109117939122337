import { Injectable } from '@angular/core';
import { StormPeriodAdjustment } from 'app/pages/sliicer/sliicer-case-study-details/flow-monitor/flow-monitor.model';
import { StormSettingsDialogData } from 'app/pages/sliicer/sliicer-case-study-details/flow-monitor/storm-events/storm-settings-dialog/storm-settings-dialog.utils';
import { BasinStormSettings, Overrides, PrecompensationType, StormSettings } from 'app/shared/models/sliicer';
import { ExpandedStormEvent } from 'app/shared/models/sliicer/results/storm-events';
import * as _ from 'underscore';

@Injectable({
    providedIn: 'root',
})
export class StormHelperService {
    public applyStormEdit(
        overrides: Overrides,
        stormOriginal: ExpandedStormEvent | StormPeriodAdjustment,
        storm: StormSettingsDialogData,
        selectedBasin: string,
    ): Overrides {
        const basinStormSettings: BasinStormSettings = {
            basinName: selectedBasin,
            stormId: storm.adjustment.stormId,
            precompLength: storm.adjustment.precompPeriodLength,
            precompType: storm.precompType.isSome() ? storm.precompType.value : PrecompensationType.None,
            season: storm.season,
            regime: storm.regime,
            altPrecompStart: storm.altPrecompStart,
            altPrecompEnd: storm.altPrecompEnd,
            deattachPrecomp: storm.deattachPrecomp,
            exclude: storm.exclude
        };

        const existingBasinIndex = overrides.basinStormSettings ? overrides.basinStormSettings.findIndex(
            (x) => x.stormId === storm.adjustment.stormId && x.basinName === selectedBasin,
        ) : null;
        if (overrides.basinStormSettings && existingBasinIndex !== -1) {
            overrides.basinStormSettings[existingBasinIndex] = basinStormSettings;
        } else if (overrides.basinStormSettings){
            overrides.basinStormSettings.push(basinStormSettings);
        }

        const existingStormSettingsIndex = overrides.stormSettings ? overrides.stormSettings.findIndex(
            (x) => x.stormId === storm.adjustment.stormId,
        ) : null;
        if (
            overrides.stormSettings &&
            (stormOriginal.stormStartTime !== storm.adjustment.stormStartTime ||
            stormOriginal.stormPeriodLength !== storm.adjustment.stormPeriodLength ||
            stormOriginal.recovery1PeriodLength !== storm.adjustment.recovery1PeriodLength ||
            stormOriginal.recovery2PeriodLength !== storm.adjustment.recovery2PeriodLength)
        ) {
            const stormSettings: StormSettings = {
                stormId: storm.adjustment.stormId,
                stormStartTime: storm.adjustment.stormStartTime,
                stormPeriodLength: storm.adjustment.stormPeriodLength,
                recovery1PeriodLength: storm.adjustment.recovery1PeriodLength,
                recovery2PeriodLength: storm.adjustment.recovery2PeriodLength,
                rainEndTime: stormOriginal.rainEndTime,
                rainStartTime: stormOriginal.rainStartTime
            };

            if (existingStormSettingsIndex !== -1) {
                overrides.stormSettings[existingStormSettingsIndex] = stormSettings;
            } else {
                overrides.stormSettings.push(stormSettings);
            }
        }

        return overrides;
    }
}
