import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
    ChangeDetectorRef,
    ViewChild,
    OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';

import { PercentFullData } from 'app/shared/models/percent-full';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';

import * as Highcharts from 'highcharts';
import { StringUtils } from '../../../../shared/utils/string-utils';
import { StatusCodeService } from '../../../../shared/services/status-code.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { Subscription } from 'rxjs';
const highchartsMore = require('highcharts/highcharts-more');
highchartsMore(Highcharts);
const highchartsSolidGauge = require('highcharts/modules/solid-gauge');
highchartsSolidGauge(Highcharts);

@Component({
    selector: 'app-percent-full',
    templateUrl: './percent-full.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class PercentFullComponent implements OnInit, OnDestroy, OnChanges {
    public percentFullChartContainer: any;

    @Input() public locationId: number;

    @Input() public data: PercentFullData;

    @Input() public isLoading: boolean;

    @Input() public dateFormat: string;

    @Input() public oneDayStartDate: Date;

    @Input() public oneDayEndDate: Date;

    @ViewChild('chartElement') public chartElement: Highcharts.stockChart;

    public customerDateFormat: string;

    public percentReportData: any[];

    public percentFullChartOptions: any;

    public percentFullChartData: number;

    private subscriptions: Array<Subscription> = [];

    constructor(
        private router: Router,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private dateutilService: DateutilService,
        private statusCodeService: StatusCodeService,
    ) {}

    public ngOnInit() {
        this.percentFullChartContainer = 'percentGraph' + Date.now();
        // subscribe to changes in dateFormat
        this.dateutilService.dateFormat.subscribe((newDateFormat) => {
            this.customerDateFormat = newDateFormat;
        });
        // this.customerDateFormat = this.dateutilService.getFormat();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        // ensure data property
        if (this.data) {
            // Checks if the screen is smaller than 1025 or its an iPad Device
            if (window.innerWidth < 1025 || window.navigator.userAgent.indexOf('iPad') > 0) {
                // parameters for ipad screen
                this.initPercentFullChart('110%', '60%', 60);
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            } else {
                // parameters for screens bigger than iPad
                this.initPercentFullChart('130%', '75%', 75);
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            }
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions = [];
    }

    /**
     * Initializes percent full chart
     */
    public initPercentFullChart(size: string, innerRadius: string, seriesInnerRadius: number) {
        if (!this.data) {
            this.isLoading = false;
            return;
        }

        this.isLoading = true;
        this.percentFullChartData = null;

        if (this.data && this.data.percentFullValues && this.data.percentFullValues.length > 0) {
            if (this.data.percentFullValues[0] && this.data.percentFullValues[0]['spans']) {
                this.percentFullChartData = this.data.percentFullValues[0]['spans'][0]['maximum'];
            }

            this.uiUtilsService.safeChangeDetection(this.cdr);
            let textformat =
                '<div style = "text-align:center" *ngIf="this.percentFullChartData">' +
                '<span style = "font-size:24px;color: black;">{y:.0f} %</span>';

            const themeSub = this.statusCodeService.userInfoThemeBS.subscribe((response: boolean) => {
                if (response) {
                    StringUtils.setHighChartTheme();
                    StringUtils.setHighChartTheme(this.percentFullChartOptions);
                    textformat =
                        '<div style = "text-align:center" *ngIf="this.percentFullChartData">' +
                        '<span style = "font-size:24px;color: white;">{y:.0f} %</span>';
                } else {
                    StringUtils.setHighchartWhiteTheme();
                    StringUtils.setHighchartWhiteTheme(this.percentFullChartOptions);
                    textformat =
                        '<div style = "text-align:center" *ngIf="this.percentFullChartData">' +
                        '<span style = "font-size:24px;color: black;">{y:.0f} %</span>';
                }
                if(this.percentFullChartOptions && this.percentFullChartOptions.series && this.percentFullChartOptions.series.length) {
                    this.percentFullChartOptions.series[0].update({
                        dataLabels: {
                            format: textformat
                        }
                    });
                }
            });
            this.subscriptions.push(themeSub);

            if (this.percentFullChartData > 0) {
                this.percentFullChartOptions = new Highcharts.Chart(<any>{
                    chart: {
                        renderTo: !this.chartElement.nativeElement
                            ? this.percentFullChartOptions.chart.renderTo
                            : this.chartElement.nativeElement,
                        type: 'solidgauge',
                        height: 220,
                    },
                    exporting: {
                        enabled: false,
                    },
                    title: {
                        text: null,
                    },
                    credits: {
                        enabled: false,
                    },
                    pane: {
                        center: ['50%', '85%'],
                        size: size,
                        startAngle: -90,
                        endAngle: 90,
                        background: {
                            backgroundColor: '#EEE',
                            innerRadius: innerRadius,
                            outerRadius: '100%',
                            shape: 'arc',
                        },
                    },

                    tooltip: {
                        enabled: false,
                    },
                    yAxis: {
                        min: 0,
                        max: 100,
                        stops: [
                            [0 / 100, '#00cc6a'],
                            [25 / 100, '#00cc6a'],
                            [26 / 100, '#fbc02d'],
                            [50 / 100, '#fbc02d'],
                            [51 / 100, '#e65100'],
                            [75 / 100, '#e65100'],
                            [76 / 100, '#b71c1c'],
                        ],
                        lineWidth: 0,
                        tickAmount: 2,
                        minorTickInterval: 1000,
                        title: {
                            y: -70,
                        },
                        labels: {
                            y: 16,
                        },
                    },
                    plotOptions: {
                        solidgauge: {
                            dataLabels: {
                                y: 5,
                                borderWidth: 0,
                                useHTML: true,
                            },
                        },
                    },
                    series: [
                        {
                            name: 'Percentage Full',
                            data: [this.percentFullChartData],
                            type: 'solidgauge',
                            dataLabels: {
                                format: textformat,
                            },
                            radius: 100,
                            innerRadius: seriesInnerRadius,
                        },
                    ],
                });
            }
        }
    }

    /**
     * Navigate to the overview of percent full report
     * @param reportName report name tobe generated
     */
    public navigateToReport(reportName: string) {
        this.router.navigate(['/pages/report/' + reportName + '/overview'], {
            queryParamsHandling: 'preserve',
        });
    }
}
