export class AddLocationRain {
    public installationID: number;
    public installationType = 'Pipe';
    public shapeType: string;
    public installationDesc1: string;
    public installationDesc2: string;
    public installationDesc3: string;
    public installationDesc4: string;
    public installationDesc5: string;
    public lookupDesc: string;
    public monitoringPoint: number;
    public wasCreatedFromImport: boolean;

    constructor() {
        this.installationID = 0;
        this.installationType = 'RainGauge';
        this.shapeType = 'RainGauge';
        this.installationDesc1 = 'Rain';
        this.installationDesc2 = '';
        this.installationDesc3 = '';
        this.installationDesc4 = '';
        this.installationDesc5 = '';
        this.lookupDesc = 'Nice';
        this.monitoringPoint = 1;
        this.wasCreatedFromImport = false;
    }
}
