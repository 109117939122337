export enum BasinQvsiProperties {
    basin = "Basin",
    qvsiGroup = "Q Vs I Group",
    mode = 'Mode',
    useAlternate = 'Use Alternate',
    type = 'Type',
    n = 'N',
    r2 = 'R2',
    slope = 'Slope',
    yIntercept = 'Y-Intercept'
}

const BasinQvsiNumberProperties = ['N', 'R2', 'Slope', 'Y-Intercept'];

export const csvToJSON = (text: string, quoteChar = '"', delimiter = ',') => {
    const rows = text.split("\n");
    const headers = rows[0].split(",");

    const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, 'gs');

    const match = line => [...line.matchAll(regex)]
        .map(m => m[2])
        .slice(0, -1);

    let lines = text.split('\n');
    const heads = headers ?? match(lines.shift());
    lines = lines.slice(1);

    return lines.map(line => {
        return match(line).reduce((acc, cur, i) => {
            // replace blank matches with `null`
            const val = cur.length <= 0 ? null : Number(cur) || cur;
            const key = heads[i] ?? `{i}`;
            return { ...acc, [key]: val };
        }, {});
    });
}

export const createPivotMapping = (data: Object): { [key: string]: { type: string; format?: string; interval?: string } } => {

    return Object.keys(data).reduce((result, key) => {
        const value = data[key];

        const isNumber = BasinQvsiNumberProperties.includes(key);

        const type = isNumber ? 'number' : 'string';
        result[key] = { type };

        return result;
    }, {});
}

export const getBasinQvsiPivotOptions = () => {
    return {
        showAggregationLabels: false,
        showAggregations: false,
        expandAll: true,
        grid: {
            type: "flat",
            showGrandTotals: "off",
            showTotals: "off",
        }
    }
}

export const getKeyByProp = (basinQvsi, prop: BasinQvsiProperties) => {
    return Object.keys(basinQvsi).find(v => v.includes(prop));
}


const BASIN_QVSI_SLICE_ROWS = [
    BasinQvsiProperties.basin
];

const BASIN_QVSI_COLUMNS = [];

const BASIN_QVSI_MEASURES = [
    BasinQvsiProperties.qvsiGroup,
    BasinQvsiProperties.mode,
    BasinQvsiProperties.useAlternate,
    BasinQvsiProperties.type,
    BasinQvsiProperties.n,
    BasinQvsiProperties.r2,
    BasinQvsiProperties.slope,
    BasinQvsiProperties.yIntercept
];

export const getBasinQvsiPivotSlice = (basinQvsi) => {
    return {
        rows: BASIN_QVSI_SLICE_ROWS.map(prop => ({ uniqueName: getKeyByProp(basinQvsi, prop) })),
        columns: [{uniqueName: "[Measures]"}, ...(BASIN_QVSI_COLUMNS.map(prop => ({ uniqueName: getKeyByProp(basinQvsi, prop) })))],
        measures: BASIN_QVSI_MEASURES.map(prop => ({ uniqueName: getKeyByProp(basinQvsi, prop) })),
        expandAll: true
    };
}
