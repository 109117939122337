<div id="slicerImportVaultStudyDialog">
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'SLIICER_TABLE.STUDY_VAULT_DIALOG.STUDY_VAULT_DIALOG_TITLE' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <app-loader [isLoading]="isLoadingState"></app-loader>
    <div mat-dialog-content>
        <!-- sliicer study vault import table -->
        <div id="sliicerVaultTable" *ngIf="!showImport">
            <mat-table #table [dataSource]="slicerVaultDataSource" matSort>
                <ng-container matColumnDef="fileName">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'SLIICER_TABLE.STUDY_VAULT_DIALOG.VALUT_TABLE_HEADER_1' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-radio-button
                            value="{{ element.uri }}"
                            (change)="fileSelected($event)"
                            [disabled]="element.uri?.toLowerCase().indexOf('flowload') <= -1"
                        ></mat-radio-button>
                        <span>{{ element.fileName }} </span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="fileSize">
                    <mat-header-cell *matHeaderCellDef
                        >{{ 'SLIICER_TABLE.STUDY_VAULT_DIALOG.VALUT_TABLE_HEADER_3' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.file_size }} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="modifiedDate">
                    <mat-header-cell *matHeaderCellDef
                        >{{ 'SLIICER_TABLE.STUDY_VAULT_DIALOG.VALUT_TABLE_HEADER_4' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.modifiedDate }} </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="sliicerVaultColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: sliicerVaultColumns"></mat-row>
            </mat-table>
        </div>
        <!-- choose from vault -->
        <div *ngIf="showImport" class="content-space">
            <p class="text-style">{{ 'SLIICER_TABLE.STUDY_VAULT_DIALOG.VALUT_DIALOG_CONTENT' | translate }}</p>
            <div class="custom-50p">
                <div class="input-text-width">
                    <mat-form-field class="full-width">
                        <input
                            class="text-style"
                            matInput
                            placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_FLOW_MONITOR' | translate }}"
                            value="{{ flowMonitorString }}"
                        />
                    </mat-form-field>
                </div>
                <div class="input-text-width">
                    <mat-form-field class="full-width">
                        <input
                            class="text-style"
                            matInput
                            placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_TABLE_HEADER_2' | translate }}"
                            value="{{ rainfallMonitorString }}"
                        />
                    </mat-form-field>
                </div>
            </div>
            <div>
                <mat-form-field class="full-width">
                    <input
                        class="text-style"
                        matInput
                        placeholder="{{ 'SLIICER_TABLE.STUDY_VAULT_DIALOG.VALUT_TABLE_STUDY_PERIOD' | translate }}"
                        value="{{ studyDate }}"
                    />
                </mat-form-field>
            </div>
            <div class="custom-50p">
                <div class="input-text-width">
                    <mat-form-field class="full-width">
                        <input
                            class="text-style"
                            matInput
                            placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_DAYSINSTUDY' | translate }}"
                            value="{{ studyPeriod }}"
                        />
                    </mat-form-field>
                </div>
                <div class="input-text-width">
                    <mat-form-field class="full-width">
                        <input
                            class="text-style"
                            matInput
                            placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_LENGTH' | translate }}"
                            value="{{ stepLength }}"
                        />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button [disabled]="isLoadingImport" (click)="close(false)">
                {{ 'SLIICER.COMMON.CANCEL_BUTTON_TITLE' | translate }}
            </button>
        </div>
        <div class="auto-margin" *ngIf="!showImport">
            <button mat-button (click)="loadConfigData()" [disabled]="disabledNext">
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_NEXT_BUTTON' | translate }}
            </button>
        </div>
        <div class="auto-margin" *ngIf="showImport">
            <button mat-button (click)="importCaseStudy()" [disabled]="disabledImport || isLoadingImport">
                {{ 'SLICER_PAGE.IMPORT_BUTTON' | translate }}
            </button>
        </div>
    </div>
</div>
