
export const QVI_GROUP_ALL_STORMS = 'All Storms';
export const QVI_GROUP_ALL_STORMS_CONFIG =  {name: QVI_GROUP_ALL_STORMS, description: '', groups: []};

export interface BasinDefinition {
    basins: Basin[];
    monitorDistributionMethod: MonitorDistributedMethod;
}

export interface BasinFiltersRequest {
    basinName: string;
    filters: BasinFilter;
}


export enum MonitorDistributedMethod {
    UserDefined = 'UserDefined',
    Closest = 'Closest',
    InverseDistance = 'InverseDistance',
    InverseDistanceSquared = 'InverseDistanceSquared',
}

export interface Basin {
    name: string;
    outputFlowMonitor: string;
    area?: string;
    length?: string;
    footprint?: string;
    centroid?: Coordinate;
    upstream?: Upstream[];
    weights: RainfallWeight[];
    changedName?: string;
    filters?: BasinFilter;
}

export interface BasinFilter {
    regimes?: string[];
    seasons?: string[];
    years?: number[];
    qvsiGroup?: string;
}
export interface BasinNetwork {
    basin: string;
    outletFlow: string;
    upStream: number;
    changedName: string;
}

export type BasinRainfall = { basinNames: string } & { [key: string]: number };

export interface Coordinate {
    latitude: number;
    longitude: number;
}

export interface Upstream {
    name: string;
    delay?: number;
    split?: number;
}

export interface RainfallWeight {
    rainfallMonitorName: string;
    weight: number;
}

export interface RainfallDistributionPreview {
    [basinName: string]: { meter: string; rainGauge: string; weight: number }[]
}
