import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs';
import * as adalLib from 'adal-angular';
import { adal } from 'adal-angular';
import User = adal.User;
import { Router } from '@angular/router';
import { BehaviorSubject, bindCallback, Observable, Subject } from 'rxjs';
import { DomOperationUtilsService } from '../utils/dom-operation-utils.service';
import { THEMES } from '../models/users-permission';
import { ADS_REFRESH_TOKEN_KEY } from './auth.service';

export interface OAuthData {
    isAuthenticated: boolean;
    userName: string;
    loginError: string;
    profile: any;
}

/**
 * This object is taken from the "ng2-adal" library which in turn relies on
 * the "adal-angular" library. Neither library was ready for Angular 5 at
 * the time of this writing so I took the code out of the library and am using
 * it here; keep in mind it replies on HttpModule which is deprecated as of
 * Angular5, so at some point when they update the libraries, we will have to
 * re-incorporate them into our code.
 *
 * https://github.com/sureshchahal/angular2-adal
 *
 * - Tomek - 11/17/2017
 */

@Injectable()
export class AdalService {
    // #40551 Cannot be BehaviourSubject because of architecture #40642
    public isUserLogged$ = new Subject<boolean>();
    private adalContext: adal.AuthenticationContext;
    private oauthData: OAuthData = {
        isAuthenticated: false,
        userName: '',
        loginError: '',
        profile: {},
    };
    constructor(
        private router: Router,
        private domUtils: DomOperationUtilsService
        ) {}
    public init(configOptions: adal.Config) {
        if (!configOptions) {
            throw new Error('You must set config, when calling init.');
        }

        // redirect and logout_redirect are set to current location by default
        const existingHash = window.location.hash;
        let pathDefault = window.location.href;
        if (existingHash) {
            pathDefault = pathDefault.replace(existingHash, '');
        }

        if(pathDefault && pathDefault.includes('welcome'))
        {
            pathDefault = configOptions.redirectUri;
        }

        configOptions.redirectUri = pathDefault || configOptions.redirectUri ;
        configOptions.postLogoutRedirectUri = configOptions.postLogoutRedirectUri || pathDefault;

        if(pathDefault && pathDefault.includes('?'))
        {
            sessionStorage.setItem('params',pathDefault.substring(pathDefault.indexOf('?')+1));
        }

        // create instance with given config
        this.adalContext = adalLib.inject(configOptions);

        window['AuthenticationContext'] = this.adalContext.constructor;

        // loginresource is used to set authenticated status
        this.updateDataFromCache(this.adalContext.config.loginResource);
    }

    public get config(): adal.Config {
        return this.adalContext.config;
    }

    public get userInfo(): OAuthData {
        return this.oauthData;
    }

    public login(): void {
        this.adalContext.login();
    }

    public loginInProgress(): boolean {
        return this.adalContext.loginInProgress();
    }

    public logOut(keepRefreshToken = false): void {
        this.domUtils.setCustomTheme(THEMES.lightTheme);
        sessionStorage.clear();

        if (!keepRefreshToken) {
            localStorage.removeItem(ADS_REFRESH_TOKEN_KEY);
        }

        // only show the Microsoft logout screen if they logged in through Microsoft AAD
        if (sessionStorage.getItem('adal.idtoken') !== null) {
            this.adalContext.logOut();
        } else {
            this.router.navigate(['welcome']);
        }

        this.isUserLogged$.next(false);

        // added check for null customer
        // if (sessionStorage.getItem('adsRole') !== null) {
        //     // Rmoving items from sessionstorage on logout
        //     // sessionStorage.removeItem('adsToken');
        //     // sessionStorage.removeItem('adsRole');
        //     // sessionStorage.removeItem('firstName');
        //     // sessionStorage.removeItem('lastName');
        //     // sessionStorage.removeItem('userEmail');
        //     this.adalContext.logOut();
        //     sessionStorage.clear();
        //     this.router.navigate(['/welcome']);
        // } else {
        //     this.adalContext.logOut();
        //     sessionStorage.removeItem('adsToken');
        //     sessionStorage.removeItem('adsRole');

        // }
    }

    public handleWindowCallback(): void {
        const hash = window.location.hash;
        if (this.adalContext.isCallback(hash)) {
            const requestInfo = this.adalContext.getRequestInfo(hash);
            this.adalContext.saveTokenFromHash(requestInfo);
            if (requestInfo.requestType === this.adalContext.REQUEST_TYPE.LOGIN) {
                this.updateDataFromCache(this.adalContext.config.loginResource);
            } else if (requestInfo.requestType === this.adalContext.REQUEST_TYPE.RENEW_TOKEN) {
                this.adalContext.callback = window.parent['callBackMappedToRenewStates'][requestInfo.stateResponse];
            }

            if (requestInfo.stateMatch) {
                if (typeof this.adalContext.callback === 'function') {
                    if (requestInfo.requestType === this.adalContext.REQUEST_TYPE.RENEW_TOKEN) {
                        // Idtoken or Accestoken can be renewed
                        if (requestInfo.parameters['access_token']) {
                            this.adalContext.callback(
                                this.adalContext._getItem(this.adalContext.CONSTANTS.STORAGE.ERROR_DESCRIPTION),
                                requestInfo.parameters['access_token'],
                            );
                        } else if (requestInfo.parameters['id_token']) {
                            this.adalContext.callback(
                                this.adalContext._getItem(this.adalContext.CONSTANTS.STORAGE.ERROR_DESCRIPTION),
                                requestInfo.parameters['id_token'],
                            );
                        } else if (requestInfo.parameters['error']) {
                            this.adalContext.callback(
                                this.adalContext._getItem(this.adalContext.CONSTANTS.STORAGE.ERROR_DESCRIPTION),
                                null,
                            );
                            this.adalContext._renewFailed = true;
                        }
                    }
                }
            }
        }
    }

    public getCachedToken(resource: string): string {
        return this.adalContext.getCachedToken(resource);
    }

    public acquireToken(resource: string) {
        // tslint:disable-next-line:variable-name
        const _this = this; // save outer this for inner function

        let errorMessage: string;
        return bindCallback(acquireTokenInternal, function (token: string) {
            if (!token && errorMessage) {
                throw errorMessage;
            }
            return token;
        })();

        function acquireTokenInternal(cb: Function): string {
            let s = '';

            _this.adalContext.acquireToken(resource, (error: string, tokenOut: string) => {
                if (error) {
                    _this.adalContext.error('Error when acquiring token for resource: ' + resource, error);
                    errorMessage = error;
                    cb(<string>null);
                } else {
                    cb(tokenOut);
                    s = tokenOut;
                }
            });
            return s;
        }
    }

    public getUser(): Observable<adal.User> {
        return bindCallback((cb: (u: adal.User) => User) => {
            this.adalContext.getUser(function (error: string, user: adal.User) {
                if (error) {
                    this.adalContext.error('Error when getting user', error);
                    cb(null);
                } else {
                    cb(user);
                }
            });
        })();
    }

    public clearCache(): void {
        this.adalContext.clearCache();
    }

    public clearCacheForResource(resource: string): void {
        this.adalContext.clearCacheForResource(resource);
    }

    public info(message: string): void {
        this.adalContext.info(message);
    }

    public verbose(message: string): void {
        this.adalContext.verbose(message);
    }

    public GetResourceForEndpoint(url: string): string {
        return this.adalContext.getResourceForEndpoint(url);
    }

    public refreshDataFromCache() {
        this.updateDataFromCache(this.adalContext.config.loginResource);
    }

    private updateDataFromCache(resource: string): void {
        const token = this.adalContext.getCachedToken(resource);
        // #40551 Only place we know when Microsoft authentication state did change.
        const wasAuthenticated = this.oauthData.isAuthenticated;
        this.oauthData.isAuthenticated = token !== null && token.length > 0;
        if(wasAuthenticated !== this.oauthData.isAuthenticated) {
            this.isUserLogged$.next(this.oauthData.isAuthenticated);
        }
        const user = this.adalContext.getCachedUser() || { userName: '', profile: undefined };
        this.oauthData.userName = user.userName;
        this.oauthData.profile = user.profile;
        this.oauthData.loginError = this.adalContext.getLoginError();
    }
}
