import { Injectable, OnDestroy } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { Config } from '../../../shared/services/config';
import { Subscription } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
    activeInactiveLocationQueryParam,
    customerLocationGroupQueryParam,
    customerQueryParam,
} from 'app/shared/models/customer';
import {
    DailyReportExportParams,
    DailyReportSettings,
    DailySummaryReport,
    DailySummaryDetailsReportArgs,
    IDailySummaryLocationDetail,
} from 'app/shared/models/daily-summary';
import { map } from 'rxjs/operators';

@Injectable()
export class DailySummaryReportService implements OnDestroy {
    private customerID: number;
    private locationGroupId: number;
    private subscriptions = new Array<Subscription>();
    private startDate: Date;
    private endDate: Date;

    /**
     * Represents the state of active and inactive locations
     * True will represent the both (active and inactive) locations
     * False will represent only active locations
     */
    public includeInactiveLocations: boolean;

    constructor(private http: HttpClient, private activatedRoute: ActivatedRoute) {
        this.subscriptions.push(
            this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
                this.customerID = Number(params.get(customerQueryParam));
                this.locationGroupId = Number(params.get(customerLocationGroupQueryParam));

                // get updated locations
                this.includeInactiveLocations = Boolean(Number(params.get(activeInactiveLocationQueryParam)));
            }),
        );
    }

    /**
     * Retrieves all the locations for the given client for daily summary overview.
     */
    public getDailySummaryReport(params: DailySummaryDetailsReportArgs): Observable<any> {
        return this.http.post<DailySummaryReport>(Config.urls.dataSummaryReportOverview, params).pipe(
            map((result: DailySummaryReport) => {
                return <DailySummaryReport>{
                    headers: result.headers,
                    data: result.data,
                    synopsis: result.synopsis,
                    totalRows: result.totalRows,
                };
            }),
        );
    }

    /**
     * Retrieves daily summary report details for a specific set of parameters
     * @param params  Customer ID, location group ID, location ID(s), entity ID(s), start, end, sort, page size and start page.
     */
    public getLocationDetails(params: DailySummaryDetailsReportArgs): Observable<IDailySummaryLocationDetail> {
        // creating HttpParams for HttpClient request

        let httpParams: HttpParams = new HttpParams();

        // setting all the existing key value of param to HttpParams
        Object.keys(params).forEach(function (key) {
            if (params[key] !== undefined) {
                if (key === 'EntityIds') {
                    params.EntityIds.forEach((element) => {
                        httpParams = httpParams.append(key, element.toString());
                    });
                } else {
                    httpParams = httpParams.append(key, params[key]);
                }
            }
        });

        return this.http.post<IDailySummaryLocationDetail>(Config.urls.dataSummaryReportDetails, params).pipe(
            map((result: IDailySummaryLocationDetail) => {
                return <IDailySummaryLocationDetail>{
                    report: result,
                };
            }),
        );
    }

    /**
     * Retrieves daily summary row data for a specific set of parameters
     * @param params  Customer ID, location group ID, location ID(s), entity ID(s), start, end, sort, page size and start page.
     */
    public getLocationGraphData(params: DailySummaryDetailsReportArgs, entityIds: number[]): Observable<IDailySummaryLocationDetail> {
        if (params.End) {
            params.End += ' 23:59:59';
        }
        // creating HttpParams for HttpClient request

        let httpParams: HttpParams = new HttpParams();

        // setting all the existing key value of param to HttpParams

        Object.keys(params).forEach(function (key) {
            httpParams = httpParams.append(key, params[key]);
        });

        entityIds.forEach((v) => {
            httpParams = httpParams.append('EntityIds', String(v));
        });

        return this.http.get(Config.urls.dataSummaryReportGraph, { params: httpParams }).pipe(
            map(
                (result) => {
                    return result;
                },
                (error) => {
                    return EMPTY;
                },
            ),
        );
    }

    /**
     * Unsubscribe to existing subscriptions to avoid memory leaks.
     */
    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe);
    }

    public saveSettings(settings: DailyReportSettings) {
        return this.http.post(Config.urls.dataSummaryReportSettings, settings);
    }

    public loadSettings(): Observable<DailyReportSettings> {
        return this.http.get<DailyReportSettings>(Config.urls.dataSummaryReportSettings);
    }

    public export(exportSettings, selectedEntities?, selectedLocations?, locationGroupId?): Observable<string> {
        const params: DailyReportExportParams = {};

        params.customerId = exportSettings.cid;
        params.locationGroupId = locationGroupId;
        params.locationIds = selectedLocations;
        params.start = exportSettings.startDate;
        params.end = exportSettings.endDate;
        params.entityIds = selectedEntities;
        params.includeInactiveLocations = false;

        params.singleFile = !(exportSettings.singleOrMultiple === 1);

        return this.http.post<string>(Config.urls.dataSummaryReportExport, params);
    }
}
