<div id="customersPageId" class="contentLayout">
    <mat-card>
        <mat-card-header>
            <div class="auto-margin">{{ 'ADMIN.ADMIN_CUSTOMER.TITLE' | translate }}</div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin"></div>
            <div class="auto-margin pad-r-15 cus-filter-hint" *ngIf="isMatTableVisible">
                <mat-icon matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}" (click)="showCustomersFilters()">
                    filter_list</mat-icon
                >
            </div>
        </mat-card-header>
        <mat-card-content>
            <div class="filterSection" *ngIf="showCustomerSearch && isMatTableVisible">
                <div class="eachSection">
                    <mat-form-field class="full-width cus-filter-feature-hint">
                        <mat-select
                            placeholder="{{ 'CUSTOMER_EDITOR.ASSIGNED_FEATURES' | translate }}"
                            multiple
                            (selectionChange)="getCustomerByFeatures($event.value)"
                        >
                            <mat-option *ngFor="let each of featureList" [value]="each.id">
                                {{ each.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="eachSection">
                    <mat-form-field class="full-width cus-filter-inactive-hint">
                        <mat-label>{{ 'CUSTOMER_EDITOR.ACTIVE_INACTIVE' | translate }}</mat-label>
                        <mat-select (selectionChange)="onSelection($event.value)">
                            <mat-option *ngFor="let each of activeInactiveList" [value]="each.text">
                                {{ each.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="search-section">
                <mat-form-field class="full-width cus-search-hint">
                    <mat-icon matPrefix class="search-icon">search</mat-icon>
                    <input
                        matInput
                        type="text"
                        placeholder="{{ 'CUSTOMER_EDITOR.SEARCH_CUSTOMER' | translate }}"
                        [formControl]="searchString"
                    />
                </mat-form-field>
            </div>
            <app-loader [isLoading]="isLoading"></app-loader>
            <div [hidden]="!isMatTableVisible">
                <div id="customersPageTable">
                    <mat-table #table [dataSource]="customerDataSource" matSort>
                        <!-- CUSTOMER NAME Column -->
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header class="cus-name-hint"
                                >{{ 'COMMON.CUSTOMER_NAME_TEXT' | translate }}
                            </mat-header-cell>
                            <mat-cell *matCellDef="let customer" [ngClass]="{ selected: customer.highlightRow }">
                                {{ customer.name }}
                            </mat-cell>
                        </ng-container>

                        <!-- CUSTOMER ACTIVE LOCATIONS Column -->
                        <ng-container matColumnDef="activeLocations">
                            <mat-header-cell *matHeaderCellDef class="text-right cus-locs-hint" mat-sort-header>{{
                                'CUSTOMER_EDITOR.ACTIVE_LOCATIONS' | translate
                            }}</mat-header-cell>
                            <mat-cell
                                *matCellDef="let customer"
                                class="text-right"
                                [ngClass]="{ selected: customer.highlightRow }"
                            >
                                {{ customer.activeLocations }}
                            </mat-cell>
                        </ng-container>

                        <!-- CUSTOMER ACTIVE LOCATIONS Column -->
                        <ng-container matColumnDef="inactiveLocations">
                            <mat-header-cell *matHeaderCellDef class="text-right cus-inactive-locs-hint" mat-sort-header>{{
                                'CUSTOMER_EDITOR.INACTIVE_LOCATIONS' | translate
                            }}</mat-header-cell>
                            <mat-cell
                                *matCellDef="let customer"
                                class="text-right"
                                [ngClass]="{ selected: customer.highlightRow }"
                            >
                                {{ customer.inactiveLocations }}
                            </mat-cell>
                        </ng-container>

                        <!-- CUSTOMER FEATURES Column -->
                        <ng-container matColumnDef="features">
                            <mat-header-cell *matHeaderCellDef class="cus-feats-hint"
                                >{{ 'CUSTOMER_EDITOR.ASSIGNED_FEATURES' | translate }}
                            </mat-header-cell>
                            <mat-cell
                                *matCellDef="let customer"
                                class="featuresList"
                                [ngClass]="{ selected: customer.highlightRow }"
                            >
                                <div *ngFor="let feature of customer.features">
                                    {{ feature.name }}
                                    <span>,</span>
                                </div>
                            </mat-cell>
                        </ng-container>

                        <!-- CUSTOMER Active/Inactive toggle Column -->
                        <ng-container matColumnDef="active">
                            <mat-header-cell *matHeaderCellDef class="cus-inactive-hint"
                                >{{ 'CUSTOMER_EDITOR.ACTIVE_INACTIVE' | translate }}
                            </mat-header-cell>
                            <mat-cell
                                *matCellDef="let customer"
                                class="custom-disabled-View"
                                [ngClass]="{ selected: customer.highlightRow }"
                            >
                                <mat-slide-toggle
                                    [color]="'accent'"
                                    [checked]="customer.active"
                                    [disabled]="dontShowActiveInactiveTab"
                                    (change)="activateCustomer(customer, customer.id)"
                                >
                                </mat-slide-toggle>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="editCustomer">
                            <mat-header-cell *matHeaderCellDef class="fixCellWidth cus-edit-hint"></mat-header-cell>
                            <mat-cell
                                *matCellDef="let customer"
                                class="text-right"
                                [ngClass]="{ selected: customer.highlightRow }"
                            >
                                <mat-icon
                                    matTooltip="{{ 'COMMON.EDIT_TOOLTIP' | translate }}"
                                    matTooltipPosition="left"
                                    (click)="updateCustomer(customer, customer.id)"
                                    >mode_edit</mat-icon
                                >
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="customerFilterColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: customerFilterColumns"></mat-row>
                    </mat-table>
                </div>
            </div>
            <div class="notabledataFound custom-table" *ngIf="!isMatTableVisible">
                {{ 'ADMIN.NO_DATA_AVAILABLE' | translate }}
            </div>
        </mat-card-content>
        <mat-card-actions [ngClass]="{ 'card-actions-on-hint': availablePageHint }">
            <div class="auto-margin">
                <button
                    (click)="openCreateCustomerEditor()"
                    [disabled]="!addNewCustomerVisible"
                    mat-fab
                    color="accent"
                    id="addNewCustomer"
                    class="fab-button-Style cus-add-hint"
                    matTooltip="{{ 'CUSTOMER_EDITOR.ADD_CUSTOMER_TOOLTIP' | translate }}"
                    matTooltipPosition="above"
                >
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin cus-paginator-hint" [hidden]="totalPaginationLength < 10" [hidden]="!isMatTableVisible">
                <mat-paginator
                    [length]="totalPaginationLength"
                    [pageIndex]="0"
                    [pageSize]="25"
                    (page)="onPageChange($event)"
                    [pageSizeOptions]="[10, 25, 100]"
                >
                </mat-paginator>
            </div>
        </mat-card-actions>
    </mat-card>
</div>
