<div class="filters-container">
    <div class="event-filter-item event-filter-locations-hint" matTooltip="{{ 'HOME.EVENT_WIDGET.LOCATION_FILTER_TOOLTIP' | translate }}">
        <app-multi-select-auto-complete
            [items]="filteredLocations"
            [preselectedItems]="filterOptions.selectedLocations"
            [isShowAll]="true"
            (currentlySelectedItems)="handleSelectedLocations($event)"
            placeholder="{{ 'COMMON.LOCATIONS' | translate }}"
        ></app-multi-select-auto-complete>
    </div>

    <div class="event-filter-item event-filter-date-hint" matTooltip="{{ 'HOME.EVENT_WIDGET.DATE_FILTER_TOOLTIP' | translate }}">
        <app-date-range-picker
            [(startDate)]="filterOptions.startDate"
            [dateRangeType]="'date'"
            [(endDate)]="filterOptions.endDate"
            (emitClose)="dateCloseEvent.next(null)"
            (isDateChanged)="isDateChanged = $event"
        ></app-date-range-picker>
    </div>

    <div class="event-filter-item event-filter-type-hint" matTooltip="{{ 'HOME.EVENT_WIDGET.TYPE_FILTER_TOOLTIP' | translate }}">
        <app-multi-select-auto-complete
            [items]="filteredTypes"
            [preselectedItems]="filterOptions.selectedTypes"
            [isShowAll]="true"
            (currentlySelectedItems)="handleTypeChange($event)"
            placeholder="{{ 'COMMON.TYPES' | translate }}"
        ></app-multi-select-auto-complete>
    </div>

    <div class="duration-filter" matTooltip="{{ 'HOME.EVENT_WIDGET.DURATION_FILTER_TOOLTIP' | translate }}">
        <app-duration-picker 
            class="event-filter-duration-hint"
            [filterOptions]="filterOptions"
            (emitClose)="durationClose($event)"
        ></app-duration-picker>
    </div>
</div>
