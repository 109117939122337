import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UpdateInfo, UpdatesAction } from './updates-widget.models';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { IComponentDialog, IComponentDialogConfirmationResult } from 'app/shared/models/comopnent-cofirmation';

@Injectable({
    providedIn: 'root',
})
export class UpdatesWidgetService {
    @Output() updatesAction = new EventEmitter<UpdatesAction>();
    public updateApplyStatus = new BehaviorSubject<boolean>(false);
    public updates = false;
    public updatesModel = '';
    public updatesLoader = false;
    public updatesInfo: Array<UpdateInfo> = [];

    public errors = {
        settings: false,
        rainfall: false
    }
    constructor(private dialog: MatDialog, private translate: TranslateService) { }

    public setUpdatesInfo(info: Array<UpdateInfo>, model: string) {
        this.updatesInfo = info;
        if (this.updatesInfo.length > 0) {
            this.setUpdates(true);
            this.updatesModel = model;
        } else {
            this.setUpdates(false);
            this.updatesModel = '';
        }
    }

    public routeChangeRequested(): void { }

    public setUpdatesAction(action: UpdatesAction): void {
        this.updatesAction.emit(action);
    }

    /********************************************************************************/
    /* PRIVATE                                                                      */
    /********************************************************************************/
    private setUpdates(value: boolean): void {
        this.updates = value;
    }

    public warningDialogClearErrors() {
        this.errors.settings = false;
        this.errors.rainfall = false;
    }

    public warningDialogContainErrors() {
        return this.errors.settings || this.errors.rainfall;
    }

    public warningDialogOpen(): Observable<IComponentDialogConfirmationResult> {
        const message = this.errors.settings
            ? this.translate.instant('SLIICER.COMMON.SETTINGS_NAVIGATE_AWAY_WARNING')
            : this.translate.instant('SLIICER.COMMON.RAINFALL_NAVIGATE_AWAY_WARNING');

        return this.dialog.open<ConfirmationDialogComponent, IComponentDialog, IComponentDialogConfirmationResult>(ConfirmationDialogComponent, {
            data: {
                title: this.translate.instant('SLIICER.COMMON.WARNING'),
                message: message,
                cancelText: this.translate.instant('SLIICER.COMMON.CANCEL_BUTTON_TITLE'),
                okText: this.translate.instant('SLIICER.COMMON.CONFIRM'),
                messageClass: 'danger'
            },
            disableClose: true,
        }).afterClosed();
    }
}
