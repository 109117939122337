<mat-card-content class="widgetFixPagination">
    <!-- <app-loader [isLoading]="batteryWidgetLoadingState"></app-loader> -->
    <app-event-filters *ngIf="showEventSearch" [events]="eventsForFilters" [filterOptions]="filterOptions" [locations]="locations" (optionsChange)="optionsChange($event)">
    </app-event-filters>
    <div *ngIf="!paginatedEvents || !paginatedEvents.length" class="notabledataFound custom-table">
        <span>{{ 'COMMON.NO_DATA_AVAILABLE' | translate }}
        </span>
    </div>

    <div id="eventWidgetTable" [ngClass]="{'mt-100': availablePageHint && showEventSearch}">
        <mat-table #table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)">

            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef>
                    <span matTooltip="{{ 'HOME.EVENT_WIDGET.DESC_TOOLTIP' | translate }}" class="event-description-hint">{{
                        'HOME.EVENT_WIDGET.DESCRIPTION' | translate }}</span>
                </mat-header-cell>
                <mat-cell *matCellDef="let item" [ngClass]="{ 'expandable-column': item.expandable || item.level }">
                    <button class="tree-toggle-btn" mat-icon-button *ngIf="(item.expandable || item.level)" [style.visibility]="!item.expandable ? 'hidden' : ''" [style.marginLeft.px]="item.level * 16" (click)="treeControl.toggle(item)">

                        <mat-icon (click)="treeControl.toggle(item)" class="mat-icon-rtl-mirror">
                            {{treeControl.isExpanded(item) ? 'expand_more' : 'chevron_right'}}
                        </mat-icon>
                    </button>
                    <span matTooltip="{{ item.desc }}"> {{ item.desc ? item.desc : '-' }} </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef>
                    <span matTooltip="{{ 'HOME.EVENT_WIDGET.TYPE_TOOLTIP' | translate }}" class="event-type-hint">
                        {{ 'HOME.EVENT_WIDGET.TYPE' | translate }}
                    </span>
                </mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <span> {{ item.displayType }} </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="locations">
                <mat-header-cell *matHeaderCellDef>
                    <span matTooltip="{{ 'HOME.EVENT_WIDGET.LOCS_TOOLTIP' | translate }}" class="event-locations-hint">
                        {{ 'HOME.EVENT_WIDGET.LOCATIONS_HEADER' | translate }}
                    </span>
                </mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <span> {{ item?.lids?.length }} </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="startedAt">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span matTooltip="{{ 'HOME.EVENT_WIDGET.STARTED_TOOLTIP' | translate }}" class="event-started-at-hint">
                        {{ 'HOME.EVENT_WIDGET.STARTED_AT_HEADER' | translate }}
                    </span>
                </mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <span> {{ item.start | date: customerDateFormat }} </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="duration">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    <span matTooltip="{{ 'HOME.EVENT_WIDGET.DURATION_TOOLTIP' | translate }}" class="event-duration-hint">
                        {{ 'HOME.EVENT_WIDGET.DURATION_HEADER' | translate }}
                    </span>
                </mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <span> {{ item?.duration }} </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="gis">
                <mat-header-cell *matHeaderCellDef>
                    <span matTooltip="{{ 'HOME.EVENT_WIDGET.GIS_TOOLTIP' | translate }}">
                        {{ 'HOME.EVENT_WIDGET.GIS' | translate }}
                    </span>
                </mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <div class="event-actions">
                        <button mat-icon-button (click)="setGISevent(item)" matTooltip="{{ 'HOME.EVENT_WIDGET.GIS_TOOLTIP' | translate }}">
                            <mat-icon color="primary" class="event-gis-hint">public</mat-icon>
                        </button>
                        <button mat-icon-button (click)="editEvent(item)" matTooltip="{{ 'HOME.EVENT_WIDGET.EDIT_TOOLTIP' | translate }}">
                            <mat-icon class="event-edit-hint">edit</mat-icon>
                        </button>
                        <button mat-icon-button *ngIf="userPermission" (click)="deleteEvent(item)" matTooltip="{{ 'HOME.EVENT_WIDGET.DELETE_TOOLTIP' | translate }}">
                            <mat-icon class="event-delete-hint">delete</mat-icon>
                        </button>
                    </div>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="toggleState ? tileColumns : reportColumns"></mat-header-row>
            <mat-row (click)="treeControl.toggle(row)" [ngClass]="{'highlight': highlightedEventGuid === row.guid}" *matRowDef="let row; columns: (toggleState ? tileColumns : reportColumns)"></mat-row>
        </mat-table>
    </div>
</mat-card-content>
<div class="widget-footer" [ngClass]="{'event-footer-on-hint': availablePageHint, 'w-29': toggleState && availablePageHint, 'w-96': availablePageHint && !toggleState}">
    <div class="auto-margin">
        <mat-paginator class="event-paginator-hint" [length]="filteredTree.length" [pageIndex]="0" (page)="pageEvent($event)" [pageSize]="25" [pageSizeOptions]="[25, 50, 100]">
        </mat-paginator>
    </div>
</div>
