import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { StatusCodeService } from '../shared/services/status-code.service';

import { UsersService } from 'app/pages/admin/users.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { StringUtils } from 'app/shared/utils/string-utils';
import { GISService } from 'app/shared/services/gis-service';
import { first, flatMap } from 'rxjs/operators';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { GetPermissionsResponseCustomer, GetPermissionsResponseUser, THEMES } from 'app/shared/models/users-permission';
import { CustomerService } from 'app/shared/services/customer.service';
import { ADS_REFRESH_TOKEN_KEY, AuthService, REMEMBER_ME_KEY } from 'app/shared/services/auth.service';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { LocationGroup } from 'app/shared/models/location-group';
import { AppCustomer, customerLocationGroupQueryParam, customerQueryParam } from 'app/shared/models/customer';
import { ILocationFeature, UsersInfo } from 'app/shared/models/users';
import { IComponentDialog } from 'app/shared/models/comopnent-cofirmation';
import { Config } from 'app/shared/services/config';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { LocationData, Locations } from 'app/shared/models/locations';

const BLACKSCREENID = 4;
const WHITESCREENID = 2;
@Injectable()
export class MainResolverService  {
    private urlCustomerId: number;

    constructor(
        private customerService: CustomerService,
        private userService: UsersService,
        private locationGroupService: LocationGroupService,
        private authService: AuthService,
        private statusCodeService: StatusCodeService,
        private http: HttpClient,
        private matDialog: MatDialog,
        public translate: TranslateService,
        public router: Router,
        private domOperationUtilsService: DomOperationUtilsService,
        public gisService: GISService,
    ) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AppCustomer> {
        // set default language
        this.translate.setDefaultLang('en-us');
        this.translate.use('en-us');

        // check if ads token is already present in the session storage added condition for null customer
        if (sessionStorage.getItem('adsRole') || sessionStorage.getItem('adsRole') === '') {
            // get potentially selected customer id
            const selectedCustomerId = Number(route.queryParamMap.get(customerQueryParam));
            this.customerService.fetchCustomerId(selectedCustomerId);

            this.urlCustomerId = selectedCustomerId;

            // get potentially selected location group
            const selectedLocationGroup = Number(route.queryParamMap.get(customerLocationGroupQueryParam));

            this.authService.gotTheToken$.next(true);

            return this.userService.getUserPermissions().pipe(
                mergeMap((permissions: GetPermissionsResponseUser) => {
                    // Setting user details in session storage
                    if (permissions.payload.firstName) {
                        sessionStorage.setItem('firstName', permissions.payload.firstName);
                    }
                    if (permissions.payload.lastName) {
                        sessionStorage.setItem('lastName', permissions.payload.lastName);
                    }
                    if (permissions.payload.userEmail) {
                        sessionStorage.setItem('userEmail', permissions.payload.userEmail);
                    }
                    if (permissions.payload.roles) {
                        sessionStorage.setItem('adsRole', permissions.payload.roles.toString());
                    }
                    if (permissions.payload.isActive) {
                        sessionStorage.setItem('isActive', permissions.payload.isActive.toString());
                    }
                    if (permissions.payload.isIdex) {
                        sessionStorage.setItem('isIdex', permissions.payload.isIdex.toString());
                    }

                    if (permissions.payload.userID) {
                        sessionStorage.setItem('userId', permissions.payload.userID);
                    }
                    this.userService.userPhoneNumber.next(permissions.payload.phoneNumber);
                    const tokenResponse = [sessionStorage.getItem('adsRole')];

                    // set the logged in user role
                    if (permissions.payload.roles.length > 0) {
                        this.customerService.loggedInUserRole.next(permissions.payload.roles);
                    }

                    const userInfomation = <UsersInfo>{
                        firstName: permissions.payload.firstName || '',
                        lastName: permissions.payload.lastName || '',
                        userEmail: permissions.payload.userEmail || '',
                        secondaryEmail: permissions.payload.secondaryEmail || '',
                        features: permissions.payload.features,
                        isIdex: permissions.payload.isIdex
                    };

                    // set default theme
                    if (permissions.payload.theme === THEMES.darkTheme) {
                        this.domOperationUtilsService.setCustomTheme(THEMES.themeColorDark);
                        this.statusCodeService.userInfoThemeBS.next(true);
                        this.statusCodeService.selectedMapTypeId.next(BLACKSCREENID);
                    } else {
                        this.statusCodeService.selectedMapTypeId.next(WHITESCREENID);
                    }

                    return this.setupCustomersForUser(
                        selectedCustomerId,
                        selectedLocationGroup,
                        permissions.payload.customers,
                        tokenResponse,
                        userInfomation,
                        permissions.payload.locations
                    );
                }),
            );
        } else {
            // get potentially selected customer id
            const selectedCustomerId = Number(route.queryParamMap.get(customerQueryParam));
            this.urlCustomerId = selectedCustomerId;
            // get potentially selected location group
            const selectedLocationGroup = Number(route.queryParamMap.get(customerLocationGroupQueryParam));

            const adalToken = this.authService.authToken;

            const requestParams = { token: adalToken };

            //  get the ads Custom Token from mauth service using adad token
            return this.http.post<any>(Config.serviceUrl + Config.urls.adsToken, requestParams).pipe(
                mergeMap((tokenResponse) => {
                    sessionStorage.setItem('adsToken', tokenResponse.token);
                    
                    if (sessionStorage.getItem(REMEMBER_ME_KEY)) {
                        sessionStorage.removeItem(REMEMBER_ME_KEY);
                        localStorage.setItem(ADS_REFRESH_TOKEN_KEY, tokenResponse.refreshToken);
                    }

                    this.authService.gotTheToken$.next(true);
                    return this.userService.getUserPermissions().pipe(
                        mergeMap((permissions: GetPermissionsResponseUser) => {
                            sessionStorage.setItem('firstName', permissions.payload.firstName);
                            sessionStorage.setItem('lastName', permissions.payload.lastName);
                            sessionStorage.setItem('userEmail', permissions.payload.userEmail);
                            sessionStorage.setItem('adsRole', permissions.payload.roles.toString());
                            sessionStorage.setItem('isActive', permissions.payload.isActive.toString());
                            sessionStorage.setItem('isIdex', permissions.payload.isIdex.toString());
                            sessionStorage.setItem('userId', permissions.payload.userID);

                            this.userService.userPhoneNumber.next(permissions.payload.phoneNumber);
                            // set the logged in user role
                            if (permissions.payload.roles.length > 0) {
                                this.customerService.loggedInUserRole.next(permissions.payload.roles);
                            }

                            const userInfomation = <UsersInfo>{
                                firstName: permissions.payload.firstName || '',
                                lastName: permissions.payload.lastName || '',
                                userEmail: permissions.payload.userEmail || '',
                                secondaryEmail: permissions.payload.secondaryEmail || '',
                                features: permissions.payload.features,
                                isIdex: permissions.payload.isIdex
                            };

                            // set default theme
                            if (permissions.payload.theme === THEMES.darkTheme) {
                                this.domOperationUtilsService.setCustomTheme(THEMES.themeColorDark);
                                this.statusCodeService.userInfoThemeBS.next(true);
                                this.statusCodeService.selectedMapTypeId.next(BLACKSCREENID);
                            } else {
                                this.statusCodeService.selectedMapTypeId.next(WHITESCREENID);
                            }

                            return this.setupCustomersForUser(
                                selectedCustomerId,
                                selectedLocationGroup,
                                permissions.payload.customers,
                                tokenResponse.roles,
                                userInfomation
                            );
                        }),
                    );
                }),
                catchError((error) => {
                    if (error instanceof HttpErrorResponse) {
                        if (error.status === 401) {
                            StringUtils.clearSessionStorage();
                            this.router.navigate(['welcome'], {
                                queryParams: { error: 'yes', active: 'no' },
                            });
                        }
                    } else {
                        // To handle exceptions in permission API and prevent annonymous user to enter in the application
                        this.matDialog
                            .open(ConfirmationDialogComponent, {
                                disableClose: true,
                                data: <IComponentDialog>{
                                    title: 'ATTENTION',
                                    message: `Please contact ADS Support Center 256-430-3366 (inside the U.S.), 877-237-9585 (toll-free).`,
                                    cancelText: '',
                                    okText: 'OK',
                                    hidebackContent: true,
                                },
                            })
                            .afterClosed()
                            .subscribe(() => {
                                this.authService.logout();
                            });
                    }
                    return of(null);
                }),
            );
        }
    }

    /**
     * Uses the selected customer and the collection of customers to deliver a clean object for the app to use.
     * @param selectedCustomerId the currently selected customer surrogate identifier.
     * @param selectedLocationGroup the currently selected location group.
     * @param customers the collection of customers.
     */
    private setupCustomersForUser(
        selectedCustomerId: number,
        selectedLocationGroup: number,
        customers: Array<GetPermissionsResponseCustomer>,
        userRole: Array<string>,
        userDetails: UsersInfo,
        locations?: Array<LocationData>,
    ): Observable<AppCustomer> {
        return this.gisService.gisUserSettingsSubject$.pipe(
            first((x) => !!x),
            flatMap((userSettings) => {
                // mergeMap(userSettings => {

                const appCustomer = <AppCustomer>{
                    customers: customers,
                    userRole: userRole,
                    firstName: userDetails.firstName,
                    lastName: userDetails.lastName,
                    userEmail: userDetails.userEmail,
                    secondaryEmail: userDetails.secondaryEmail,
                    features: userDetails.features,
                    isIdex: userDetails.isIdex,
                    locations: locations
                };

                // ensure args
                if (!customers || customers.length < 1) {
                    return of(appCustomer);
                }

                // get selected customer; or favorite; or just the first customer on the list
                let chosenCustomerId = selectedCustomerId || userSettings.lastViewedCustomerId || customers[0].customer.customerID;

                // bug 9937 resolved when 'none' role of user has different cutomers change by admin users
                if (customers.length === 1 && this.urlCustomerId !== customers[0].customer.customerID) {
                    chosenCustomerId = customers[0].customer.customerID;
                    this.statusCodeService.currentCustomerId.next(chosenCustomerId);
                } else {
                    const choosenCustomer = customers.find((x) => x.customer.customerID === chosenCustomerId);
                    const defaultCustomer = choosenCustomer ? choosenCustomer : customers.find((x) => x.defaultCustomer);
                    // more than one customer but have one default
                    if (defaultCustomer) {
                        this.statusCodeService.currentCustomerId.next(defaultCustomer.customer.customerID);
                    } else {
                        // make default first one from list
                        this.statusCodeService.currentCustomerId.next(customers[0].customer.customerID);
                    }
                }

                // mark customers for navigation purpose
                this.customerService.updatedGetCustomersWithLocations(chosenCustomerId, customers);

                if (!userSettings) {
                    userSettings = {
                        id: 0,
                        userId: 0,
                        locationsIsActive: true,
                        languageSelection: 'en-us',
                        sidebarIsOn: true,
                        sidebarIsOn2: true,
                        baseMapId: null,
                        isMapPanelOn: true,
                        isLayersOn: true,
                        isActive: true,
                        selectedLocationGroupId: 1,
                        locationPositionX: '',
                        locationPositionY: '',
                        isLayersSelectable: true,
                        activeLayersTabNo: 0,
                        isSidebarPinOn: true,
                        isSidebarPinOn2: true,
                        showLocationLabels: true,
                        lastViewedCustomerId: chosenCustomerId,
                        layersParameterJson: '',
                        customerSettings: [],
                    };
                    this.gisService.updateGisUserSettings(userSettings).subscribe((res: any) => {});
                }

                if (!userSettings.languageSelection) {
                    this.translate.use('en-us');
                } else {
                    this.translate.use(userSettings.languageSelection);
                }

                // get location for first customer
                return this.locationGroupService.getLocationGroups(chosenCustomerId).pipe(
                    map((locations: { [key: string]: Array<LocationGroup> }) => {
                        // setup location groups placeholder
                        appCustomer.locationGroups =
                            locations && locations.locationGroups ? locations.locationGroups : [];

                        // mark active location groups
                        this.locationGroupService.markActiveLocationGroup(
                            selectedLocationGroup,
                            appCustomer.locationGroups,
                        );

                        // setup return result
                        return appCustomer;
                    }),
                );
            }),
        );
    }
}
