import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-edit-admin-user-dialog',
    templateUrl: './edit-admin-user-dialog.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAdminUserDialogComponent {
    constructor(public dialogRef: MatDialogRef<EditAdminUserDialogComponent>) {}
}
