// tslint:disable-next-line:no-var-keyword
export const Global = {
    pipeElements: [],
    analysisDates: [],
    analyses: [],

    // Existing variables.//
    // currentProfileDBFileName,
    // chartHyd,
    // chartScatter,
    // locations,
    dayGroupIds: [],
    tabState: 'meter',
    // myStartDate,
    studyStartDateMilliseconds: 0,
    // numBaseSeries,
    // myStorms,
    // myDryDays,
    // chartStorm,
    lastId: 10,
    animationIntervalId: null,
    maxDepth: 0,
    numXAxisDivs: 5,
    pipeDiam: 0,
    percentPipe: 50,
    scaleFactor: 1,
    mouseMode: 'zoom',
    hydrographNavigatorScrollMouseDown: false,
    codeSelecting: false,
    pipeGraph: null,
    maxDepthScale: 0,
    maxVel: 0,
    maxPlotVel: 0,
    maxQ: 0,
    dateIndex: [],
    dateTimeIndex: [],
    dvRawData: [],
    dvScrubbedData: [],
    markerData: [],
    reviewedData: [],
    compareData: [],
    unsavedEdits: 0,
    showCompare: false,
    showSnapped: false,
    plotBandsShown: false,
    showCursorWorm: false,
    cursorScattergraphWormShown: false,
    lastExtremes: null,
    zoomStartIndex: 0,
    zoomEndIndex: 0,
    redrawCount: 0,
    wormVisible: false,
    // timerScatterWorm,
    hydOverCount: 0,
    hydOutCount: 0,
    ssCurve: [],
    ssCurves: [],
    cvCurve: [],
    cvCurve07: [],
    cvCurve15: [],
    isoQCurves: [],
    numIsoQCurves: 12,
    isoQDepthIndex: 0,

    ssCurvePlotted: false,
    isoQCurvesPlotted: false,
    isoFroudeLinesPlotted: false,
    scatterCurvesPlotted: [false, false],
    initialScattergraphWorm: [
        [-1, -1],
        [-1, -1],
        [-1, -1],
        [-1, -1],
        [-1, -1],
        [-1, -1],
        [-1, -1],
        [-1, -1],
        [-1, -1],
        [-1, -1],
        [-1, -1],
        [-1, -1],
        [-1, -1],
        [-1, -1],
        [-1, -1],
    ],
    myColors: [
        'rgba(255,0,0,1)',
        'rgba(248,103,7,1)',
        'rgba(244,151,9,1)',
        'rgba(241,213,14,1)',
        'rgba(202,234,21,1)',
        'rgba(133,228,27,1)',
        'rgba(36,219,50,1)',
        'rgba(37,218,150,1)',
        'rgba(38,208,217,1)',
        'rgba(124,64,191,1)',
        'rgba(223,32,194,1)',
        'rgba(188,52,203,1)',
    ],
    colorGoodData: 'rgba(175, 190, 175, 1)',
    colorRapidChange: 'rgba(150, 165, 150, 1)',
    colorAutoScrubD: 'rgba( 35,209,217,1)',
    colorAutoScrubV: 'rgba( 74, 62,226,1)',
    colorSnapDQuestion: 'rgba(143,245, 39,1)',
    colorSnapVQuestion: 'rgba(224, 35,208,1)',
    colorBadData: 'rgba(184, 0, 0, 1)',
    colorCV: 'rgba(255, 0, 0, 1)',
    colorSS: 'rgba(0, 0, 255, 1)',
    colorIsoQ1: 'rgba(0, 255, 0, .3)',
    colorIsoQ2: 'rgba(0, 128, 0, .3)',

    // cyan
    colorPri0: 'rgba( 23, 47,198,1)',
    colorPri1: 'rgba(120,134,219,1)',
    colorPri2: 'rgba( 72, 91,209,1)',
    colorPri3: 'rgba( 13, 31,145,1)',
    colorPri4: 'rgba(  8, 21,100,1)',
    // blue
    colorSec0: 'rgba(199,  0,175,1)',
    colorSec1: 'rgba(220,106,206,1)',
    colorSec2: 'rgba(210, 52,190,1)',
    colorSec3: 'rgba(146,  0,128,1)',
    colorSec4: 'rgba(101,  0, 89,1)',
    // green
    colorTir0: 'rgba(  0,219,  0,1)',
    colorTir1: 'rgba(112,232,112,1)',
    colorTir2: 'rgba( 56,225, 56,1)',
    colorTir3: 'rgba(  0,169,  0,1)',
    colorTir4: 'rgba(  0,117,  0,1)',

    // Blue
    colorBlue0: 'rgba( 21, 59,197,1)',
    colorBlue1: 'rgba(118, 140, 218, 1)',
    colorBlue2: 'rgba(70, 99, 208, 1)',
    colorBlue3: 'rgba(12, 40, 143, 1)',
    colorBlue4: 'rgba(7, 27, 99, 1)',
    // Purple
    colorPurple0: 'rgba(156, 5, 193, 1)',
    colorPurple1: 'rgba(194, 107, 216, 1)',
    colorPurple2: 'rgba(175, 55, 205, 1)',
    colorPurple3: 'rgba(112, 3, 139, 1)',
    colorPurple4: 'rgba(78, 2, 96, 1)',

    colorGreen0: 'rgba(21, 222, 0, 1)',
    colorGreen1: 'rgba(124, 234, 113, 1)',
    colorGreen2: 'rgba(73, 228, 57, 1)',
    colorGreen3: 'rgba(16, 173, 0, 1)',
    colorGreen4: 'rgba(11, 120, 0, 1)',

    colorGold0: 'rgba(255, 153, 0, 1)',
    colorGold1: 'rgba(255, 202, 123, 1)',
    colorGold2: 'rgba(255, 178, 63, 1)',
    colorGold3: 'rgba(211, 126, 0, 1)',
    colorGold4: 'rgba(146, 87, 0, 1)',
};
