<app-loader [isLoading]="isLoading"></app-loader>

<fm-pivot
    #pivot
    [hidden]="noData"
    [height]="'100%'"
    [toolbar]="true"
    [report]="report"
    (beforetoolbarcreated)="customizeToolbar($event)"
    [licenseKey]="license"
    >
</fm-pivot>
<div *ngIf="noData">{{ 'SLIICER.FLOW_TAB.NO_STORMS_TO_DISPLAY' | translate }}</div>
