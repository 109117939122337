/* helper function for debuggin positions on browser tools

window['checkPosition'] = (
            selector,
            selectorHint,
            selectorHintPositionX,
            adjustselectorHintPositionX,
            selectorHintPositionY,
            adjustselectorHintPositionY
        ) =>  {
            const outlet = document.querySelector(selectorHint);
            const parent = document.querySelector(selector);
            const selectorPositions = parent.getBoundingClientRect();
            const width = document.querySelector('body').clientWidth;
            const scroll = document.scrollingElement.scrollTop;

            if (selectorHintPositionY === 'TOP') {
                outlet.style.top = selectorPositions.top + adjustselectorHintPositionY + scroll + 'px';
            } else {
                outlet.style.bottom = selectorPositions.bottom + adjustselectorHintPositionY + 'px';
            }

            if (selectorHintPositionX === 'RIGHT') {
                outlet.style.right = width - selectorPositions.left + adjustselectorHintPositionX + 'px';
            } else {
                outlet.style.left = selectorPositions.left + adjustselectorHintPositionX + 'px';
            }
        }
*/

import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import Highcharts from 'highcharts';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { THEMES } from '../models/users-permission';
import { ScheduleCollectionService } from '../services/schedule-collection.service';
import { delay, first } from 'rxjs/operators';
import { FeaturelistDatabase } from 'app/homemapdashboard/location-feature-property-list/location-feature-property-list.component';
import { WIDGET_PARAM } from '../models/customer';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AdsPrismVaultLocationExportComponent } from 'app/pages/vault/vault-location-export/vault-location-export.component';
import { DateutilService } from '../services/dateutil.service';
import { I } from '@angular/cdk/keycodes';
const NONE = 'none';
const EDIT_VIEW_DATA = 'EDIT_VIEW_DATA';
const EDIT_VIEW_DATA_ROllBACK = 'EDIT_VIEW_DATA_ROllBACK';

const LOCATION_DETAIL_HINT = 'LOCATION_DETAIL_HINT';

export enum XALIGN {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT',
    ANCHORRIGHT = 'ANCHORRIGHT'
}

enum PAGE_TYPE {
    standard,
    ansr,
    daily
}
@Injectable()
export class DomOperationUtilsService {
    public showpageHint = new BehaviorSubject<boolean>(false);
    public selectedHintPageName = new BehaviorSubject<string>('noHintclass');
    public selectedGisTab = new BehaviorSubject(0);
    public homeDashboardTabChangeComplete = new Subject();
    public populateDemoLocationForHomepage = new Subject();
    public showHomepageHintF = new BehaviorSubject<boolean>(false);
    public showHomepageHintS = new BehaviorSubject<boolean>(false);
    public showLocationDashBoardpageHint = new BehaviorSubject<boolean>(false);
    public showLocationDetailScreenNo = new BehaviorSubject<number>(0);

    // customer editor params
    public editCustomerTabIndex = new BehaviorSubject(0);
    public locationFiltersOn = new BehaviorSubject(false);
    public rainfallTabIndex = new BehaviorSubject(0);
    public ddfTabIndex = new BehaviorSubject(0);
    public gisDialogOpened = new BehaviorSubject(false);
    public gisDialogAuthMethod = new BehaviorSubject<'basic' | 'custom'>(null);
    public ftpDialogOpened = new BehaviorSubject(false);

    public deDialogOpened = new BehaviorSubject(false);
    public openDEDialog = new Subject();
    public openCloseScheduleDialog = new Subject();
    public openCloseDailyDialog = new Subject();
    public homeDashboardChangeOverlay = new Subject();
    public isHomePageWidgetToggled = true;
    public firstTabHint = true;
    public homePageSelectedWidget = WIDGET_PARAM.ALL;
    public hintPageName = NONE;
    public isHintOverlayOpen = false;
    public currentScreenNo = 0;

    public dailyDialogOpened = false;
    public blockEditorOpened = false;
    public customDashboardDialogOpened = false;
    public rollbackDialog = false;
    public rollbackFilterOpened = true;
    public customDashboardDialogGraphType = new BehaviorSubject('0');
    // to open custom dashboard dialog (true will open, false will close)
    public openCustomDashboardDialog = new Subject<boolean>();
    public editUserSelectedTab = 0;
    public tabChangeInProgress = true;
    public homePageTabOverlays = ['homePageIconHint', 'homePageSecondTab', 'homePageThirdTab', 'homePageFourthTab'];
    public vaultOverlayNumber = new BehaviorSubject(0);
    public vaultDialog: MatDialogRef<AdsPrismVaultLocationExportComponent>;
    public showNotificationDashBoardOverlay = new BehaviorSubject<boolean>(false);
    public selectedStandarAlarmTab = new BehaviorSubject(0);
    constructor(
        @Inject(DOCUMENT) private angularDocument: Document,
        private router: Router,
        public scheduleCollectionService: ScheduleCollectionService,
        public feat_database: FeaturelistDatabase,
        private dateutilService: DateutilService
    ) {
        this.selectedGisTab.subscribe(() => (this.tabChangeInProgress = true));
        this.selectedStandarAlarmTab.subscribe(() => (this.tabChangeInProgress = true));
    }

    /**
     * Used for scroll up and down
     * @param id - html containet id
     */
    public scrollToTop(id: string): void {
        // check if edge browser or not
        if (window.navigator.userAgent.indexOf('Edge') > 0 && id === 'html') {
            id = 'body';
        }

        // check if iPad device or not
        if (window.navigator.userAgent.indexOf('iPad') > 0 && id === 'html') {
            id = 'body';
        }

        // get html content for specific querySelector
        const routerOutletComponent: HTMLElement = <HTMLElement>this.angularDocument.querySelector(id);

        if (routerOutletComponent) {
            // Scrool to top Position
            routerOutletComponent.scrollTop = 0;
        }
    }

    public scrollToRight(selector: string) {
        const selectElement: HTMLElement = <HTMLElement>this.angularDocument.querySelector(selector);
        if (selectElement) {
            selectElement.scrollLeft = selectElement.scrollWidth;
        }
    }

    /**
     * Used for scroll up and down
     * @param id - html containet id
     * @param position direction up/down
     */
    public scrollToUpDown(id: string, position: string): void {
        // check if edge browser or not
        if (window.navigator.userAgent.indexOf('Edge') > 0 && id === 'html') {
            id = 'body';
        }

        // check if iPad device or not
        if (window.navigator.userAgent.indexOf('iPad') > 0 && id === 'html') {
            id = 'body';
        }

        // get html content for specific querySelector
        const routerOutletComponent: HTMLElement = <HTMLElement>this.angularDocument.querySelector(id);

        // calculate the body height
        const docHeight: number = this.angularDocument.querySelector('body').clientHeight;

        if (routerOutletComponent) {
            if (position.toUpperCase() === 'DOWN') {
                // Scrool to bottom Position
                routerOutletComponent.scrollTop = docHeight;
            } else {
                // Scrool to top Position
                routerOutletComponent.scrollTop = 0;
            }
        }
    }

    public setCustomTheme(theame: string) {
        const htmlComponent: HTMLElement = <HTMLElement>this.angularDocument.querySelector('html');
        const bodyComponent: HTMLElement = <HTMLElement>this.angularDocument.querySelector('#appBodyContentId');

        if (theame === THEMES.themeColorDark) {
            htmlComponent.style.backgroundColor = '#616161';
            bodyComponent.classList.add('blackTheme');
        } else {
            htmlComponent.style.backgroundColor = '#ECECEC';
            bodyComponent.classList.remove('blackTheme');
        }
    }
    /**
     * Update Page Hint Page Size
     * @param id
     * @param page
     */
    public updateHintPageHeight(id: string, page: string): void {
        const routerOutletComponent: HTMLElement = <HTMLElement>this.angularDocument.querySelector(id);
        const htmlHeight: number = this.angularDocument.querySelector('html').clientHeight;
        let pageHeight: number = this.angularDocument.querySelector('body').clientHeight;
        if (htmlHeight > pageHeight) {
            pageHeight = htmlHeight - 3;
        }

        if (routerOutletComponent) {
            if (page.toUpperCase() === 'SHOW') {
                routerOutletComponent.style.height = pageHeight + 'px';
                routerOutletComponent.style.visibility = 'visible';
                this.showpageHint.next(true);
                this.showPageHintContent();
            } else {
                routerOutletComponent.style.height = '0px';
                routerOutletComponent.style.visibility = 'hidden';
                this.showpageHint.next(false);
            }
        }
    }

    /**
     * set page Hint Containt
     */
    public showPageHintContent() {
        const url = this.router.url;
        let selectedHintPageNameValue = '';
        this.selectedHintPageName.subscribe((result: string) => {
            selectedHintPageNameValue = result;
        });
        switch (true) {
            case url.indexOf('/dashboard') > -1:
                // to do for dashBoard Page
                let selectedTab = this.selectedGisTab.getValue();
                if (selectedTab === 3) {
                    selectedTab = 2;
                    this.selectedGisTab.next(selectedTab);
                }
                this.pickHomepageHintOverlay(selectedTab);
                this.homeDashboardTabChangeComplete.next(null);
                break;
            case url.indexOf('custom-dashboard') > -1:
                if (this.customDashboardDialogOpened) {
                    this.selectedHintPageName.next('custom-dashboard-dialog-hint');
                    this.customDashboardDialog();
                } else {
                    this.selectedHintPageName.next('custom-dashboard-hint');
                    this.customDashboard();
                }
                break;
            case url.indexOf('viewData') > -1:
                if (this.blockEditorOpened) {
                    this.selectedHintPageName.next('blockEditorDialogHint');
                    this.blockEditorDialogHint();
                } else if (url.indexOf('viewDataDynamic') > -1) {
                    this.selectedHintPageName.next('viewDataDynamicHint');
                    this.viewDataDynamic();
                } else if (this.rollbackDialog) {
                    this.selectedHintPageName.next('location-dashBoard-edit-hint_rollback');
                    this.locationDashBoardEditHintRollback();
                } else if (this.hintPageName === EDIT_VIEW_DATA) {
                    if (this.deDialogOpened.getValue()) {
                        this.selectedHintPageName.next('de-dialog-hint');
                        this.locationDashboardAcceptEditdialog();
                    } else {
                        this.selectedHintPageName.next('location-dashBoard-edit-hint');
                        this.locationDashBoardEditHint();
                    }
                } else {
                    // to do for Location Dash Board Page
                    this.selectedHintPageName.next('locationDashboardHint');
                    this.locationDashBoardHint();
                }
                break;
            case url.indexOf('schedule-collect') > -1:
                if (this.scheduleCollectionService.addDialogIsOpened) {
                    this.selectedHintPageName.next('scheduleDialogHint');
                    this.scheduleDialogHint();
                } else {
                    this.selectedHintPageName.next('scheduleDashboardHint');
                    this.scheduleCollectHint();
                }
                break;
            case url.indexOf('pages/customers') > -1: {
                this.selectedHintPageName.next('customer-editor-hint');
                this.customerEditor();
                break;
            }
            case url.indexOf('pages/customerEditor/details') > -1: {
                this.pickCustomerEditorOverlay();
                break;
            }
            case url.indexOf('vault') > -1:
                if (selectedHintPageNameValue !== 'noHintVaultclass') {
                    if (this.vaultDialog) {
                        this.vaultOverlayNumber.next(4);
                    } else {
                        this.vaultOverlayNumber.next(0);
                    }
                    this.pickVaultHint();
                }

                break;

            case url.includes('report/data-editing-audit'): {
                this.selectedHintPageName.next('data-editing-audit-hint');
                this.dataEditingAudit();
                break;
            }
            case url.indexOf('report/audit') > -1: {
                this.selectedHintPageName.next('audit-log-hint');
                this.auditLog();
            }
            case url.indexOf('report/daily-summary') > -1:
                // to do for report Page
                if (url.indexOf('overview') > -1) {
                    this.selectedHintPageName.next('dailySummaryOverviewHint');
                    if (this.dailyDialogOpened) {
                        this.selectedHintPageName.next('dailyDialogHint');
                        this.dailyDialogHint();
                    } else {
                        this.selectedHintPageName.next('dailySummaryOverviewHint');
                        this.dailySummaryOverview();
                    }
                } else if (url.indexOf('details') > -1) {
                    this.selectedHintPageName.next('dailySummaryDetailsHint');
                    this.dailySummaryDetails();
                }
                break;
            case url.indexOf('report/percent-full/overview') > -1:
                // to do for report Page
                this.selectedHintPageName.next('percentFullHint');
                this.percentFullReport();
                break;
            case url.indexOf('report/uptime') > -1:
                // to do for report Page
                if (url.indexOf('overview') > -1) {
                    this.selectedHintPageName.next('uptimeOverviewHint');
                    this.uptimeOverview();
                } else if (url.indexOf('details') > -1) {
                    this.selectedHintPageName.next('uptimeDetailsHint');
                    this.uptimeDetails();
                }
                break;
            case url.indexOf('admin') > -1:
                // to do for admin Page
                if (url.indexOf('details') > -1 && url.indexOf('registeruser') > -1) {
                    this.selectedHintPageName.next('adminDetailsHint');
                    this.adminDetails();
                } else if (url.indexOf('details') > -1) {
                    this.pickEditUserHint();
                } else {
                    this.selectedHintPageName.next('users-page-hint');
                    this.showUsersPage();
                }
                break;
            case url.indexOf('customerEditor') > -1:
                // to do for admin Page
                if (url.indexOf('details') > -1) {
                    this.selectedHintPageName.next('customerEditorDetailsHint');
                    this.customerEditorDetails();
                } else {
                    this.selectedHintPageName.next('customerEditorScreenHint');
                    this.customerEditorScreen();
                }
                break;
            case url.indexOf('viewLocationDetails') > -1:
                this.selectedHintPageName.next('view-location-details-hint');
                this.hintPageName = LOCATION_DETAIL_HINT;
                this.showLocationDashboardDetailHintOverlay();
                break;
            case url.includes('report/flowMonitoringReport'): {
                this.selectedHintPageName.next('hint-flow-monitoring-report');
                this.flowMonitoringReport();
                break;
            }
            case url.includes('menuDashboard/notifications'): {
                const selectedTab = this.selectedStandarAlarmTab.getValue();
                if (url.includes('standard')) {
                    switch (selectedTab) {
                        case 0:
                            this.selectedHintPageName.next('hint-notification-dashboard-report-standard');
                            this.notificationDashboardReportStandard();
                            break;
                        case 1:
                            this.selectedHintPageName.next('hint-notification-dashboard-report-standard-alarms');
                            this.notificationDashboardReportStandardAlarms();
                            break;
                        case 2:
                            this.selectedHintPageName.next('hint-notification-dashboard-report-standard-notification-tree');
                            this.notificationDashboardReportStandardNotificationTree();
                            break;
                        case 3:
                            this.selectedHintPageName.next('hint-notification-dashboard-report-standard-validation');
                            this.notificationDashboardReportStandardValidation();
                            break;
                        case 4:
                            this.selectedHintPageName.next('hint-notification-dashboard-report-alarms-confirm');
                            this.notificationDashboardReportAlarmsConfirm(PAGE_TYPE.standard);
                            break;
                        default:
                            break;
                    }
                }
                else if (url.includes('ansr')) {
                    switch (selectedTab) {
                        case 0:
                            this.selectedHintPageName.next('hint-notification-dashboard-report-standard');
                            this.notificationDashboardReportStandard();
                            break;
                        case 1:
                            this.selectedHintPageName.next('hint-notification-dashboard-report-ANSR-alarms');
                            this.notificationDashboardReportAnsrAlarms();
                            break;
                        case 2:
                            this.selectedHintPageName.next('hint-notification-dashboard-report-standard-notification-tree');
                            this.notificationDashboardReportStandardNotificationTree();
                            break;
                        case 3:
                            this.selectedHintPageName.next('hint-notification-dashboard-report-standard-validation');
                            this.notificationDashboardReportStandardValidation();
                            break;
                        case 4:
                            this.selectedHintPageName.next('hint-notification-dashboard-report-alarms-confirm');
                            this.notificationDashboardReportAlarmsConfirm(PAGE_TYPE.ansr);
                            break;
                        default:
                            break;
                    }
                } else if (url.includes('daily')) {
                    switch (selectedTab) {
                        case 0:
                            this.selectedHintPageName.next('hint-notification-dashboard-report-standard');
                            this.notificationDashboardReportStandard();
                            break;
                        case 1:
                            this.selectedHintPageName.next('hint-notification-dashboard-report-daily');
                            this.notificationDashboardReportDaily();
                            break;
                        case 2:
                            this.selectedHintPageName.next('hint-notification-dashboard-report-standard-notification-tree');
                            this.notificationDashboardReportStandardNotificationTree();
                            break;
                        case 3:
                            this.selectedHintPageName.next('hint-notification-dashboard-report-standard-validation');
                            this.notificationDashboardReportStandardValidation();
                            break;
                        case 4:
                            this.selectedHintPageName.next('hint-notification-dashboard-report-alarms-confirm');
                            this.notificationDashboardReportAlarmsConfirm(PAGE_TYPE.daily);
                            break;
                        default:
                            break;
                    }
                } else {
                    this.selectedHintPageName.next('hint-notification-dashboard-report');
                    this.notificationDashboardReport();
                }
                break;
            }
            default:
            // do nothing;
        }
    }

    public pickHomepageHintOverlay(overlayIndex: number) {
        if (!this.isHomePageWidgetToggled && this.homePageSelectedWidget) {
            switch (this.homePageSelectedWidget) {
                case WIDGET_PARAM.DATA_COMMUNICATION: {
                    this.selectedHintPageName.next('data-communication-hint');
                    this.dataCommunication();
                    break;
                }
                case WIDGET_PARAM.BATTERY: {
                    this.selectedHintPageName.next('battery-status-hint');
                    this.batteryStatusExpanded();
                    break;
                }
                case WIDGET_PARAM.ALARM: {
                    this.selectedHintPageName.next('alarms-widget-hint');
                    this.alarmWidgetExpanded();
                    break;
                }
                case WIDGET_PARAM.EVENT: {
                    this.selectedHintPageName.next('event-widget-expanded-hint');
                    this.eventWidgetExpanded();
                    break;
                }
                case WIDGET_PARAM.AUTO_REVIEW: {
                    this.selectedHintPageName.next('auto-review-widget-hint');
                    this.autoReviewHints();
                    break;
                }
            }

            return;
        }

        this.selectedHintPageName.next(this.homePageTabOverlays[overlayIndex]);
        this.homeDashboardTabChangeComplete.pipe(delay(1000), first()).subscribe(() => {
            this.tabChangeInProgress = false;
            switch (overlayIndex) {
                case 0:
                    if (this.firstTabHint === true ||
                        ![WIDGET_PARAM.ALARM, WIDGET_PARAM.BATTERY, WIDGET_PARAM.DATA_COMMUNICATION].includes(this.homePageSelectedWidget)
                    ) {
                        this.topNavHint();
                    } else if (this.firstTabHint === false && this.isHomePageWidgetToggled) {
                        switch (this.homePageSelectedWidget) {
                            case WIDGET_PARAM.ALARM: {
                                this.selectedHintPageName.next('alarms-sm-widget-hint');
                                this.alarmWidgetCollapsed();
                                break;
                            }
                            case WIDGET_PARAM.BATTERY: {
                                this.selectedHintPageName.next('battery-sm-widget-hint');
                                this.batteryStatusCollapsed();
                                break;
                            }
                            case WIDGET_PARAM.DATA_COMMUNICATION: {
                                this.selectedHintPageName.next('dataComm-sm-widget-hint');
                                this.dataCommStatusCollapsed();
                                break;
                            }
                        }
                    }
                    break;
                case 1:
                    this.showLayersOverlayHomepage();
                    break;
                case 2:
                    this.showToolsOverlayHomepage();
                    break;
                case 3:
                    this.showToolsSecondOverlay();
                    break;
            }
        });
    }

    public pickVaultHint() {
        switch (this.vaultOverlayNumber.getValue()) {
            case 0: {
                this.selectedHintPageName.next('vaultPageHint');
                this.vaultPageHint();
                break;
            } case 1: {
                this.selectedHintPageName.next('vaultSecondHint');
                this.vaultSecondHint(false);
                break;
            } case 2: {
                this.selectedHintPageName.next('vaultSecondListHint');
                this.vaultSecondHint(true);
                break;
            } case 3: {
                this.selectedHintPageName.next('vaultThirdHint');
                this.vaultThirdHint();
                break;
            } case 4: {
                this.selectedHintPageName.next('vaultDialogHint');
                this.vaultDialogHint();
                break;
            }
        }
    }

    public percentFullReport() {
        this.showHintWaitRender('.percentTitleHint', '.percentTitleHintArrow', 'LEFT', 60, 'TOP', -65, 'percentFullHint');
        this.showHintWaitRender('.percentTitleHint', '.percentTitleHintText', 'LEFT', -5, 'TOP', -110, 'percentFullHint');

        this.showHintWaitRender('.percentFullDownloadHint', '.percentFullDownloadHintArrow', 'RIGHT', 5, 'TOP', -45, 'percentFullHint');
        this.showHintWaitRender('.percentFullDownloadHint', '.percentFullDownloadHintText', 'RIGHT', 25, 'TOP', -85, 'percentFullHint');

        this.showHintWaitRender('.percentFullFilterHint', '.percentFullFilterHintArrow', 'RIGHT', -20, 'TOP', -65, 'percentFullHint');
        this.showHintWaitRender('.percentFullFilterHint', '.percentFullFilterHintText', 'RIGHT', -60, 'TOP', -125, 'percentFullHint');

        this.showHintWaitRender('.percent-yesterday-hint', '.percent-yesterday-hint-arrow', 'LEFT', 70, 'TOP', 45, 'percentFullHint');
        this.showHintWaitRender('.percent-yesterday-hint', '.percent-yesterday-hint-text', 'LEFT', 40, 'TOP', 115, 'percentFullHint');

        this.showHintWaitRender('.percent-date-column-hint', '.percent-date-column-hint-arrow', 'LEFT', 70, 'TOP', 45, 'percentFullHint');
        this.showHintWaitRender('.percent-date-column-hint', '.percent-date-column-hint-text', 'LEFT', 40, 'TOP', 115, 'percentFullHint');

        this.showHintWaitRender('.percent-item-hint', '.percent-item-hint-arrow', 'LEFT', 90, 'TOP', 1, 'percentFullHint');
        this.showHintWaitRender('.percent-item-hint', '.percent-item-hint-text', 'LEFT', 150, 'TOP', 30, 'percentFullHint');

        this.showHintWaitRender('.percent-locations-hint', '.percent-locations-hint-arrow', 'LEFT', 1, 'TOP', -50, 'percentFullHint');
        this.showHintWaitRender('.percent-locations-hint', '.percent-locations-hint-text', 'RIGHT', 15, 'TOP', -80, 'percentFullHint');

        this.showHintWaitRender('.percent-date-hint', '.percent-date-hint-arrow', 'LEFT', 30, 'TOP', 55, 'percentFullHint');
        this.showHintWaitRender('.percent-date-hint', '.percent-date-hint-text', 'LEFT', 90, 'TOP', 95, 'percentFullHint');
    }

    public auditLog() {
        this.showHintWaitRender('.audit-title-hint', '.audit-title-hint-arrow', 'LEFT', 25, 'TOP', -65, 'audit-log-hint');
        this.showHintWaitRender('.audit-title-hint', '.audit-title-hint-text', 'LEFT', -15, 'TOP', -145, 'audit-log-hint');

        this.showHintWaitRender('.audit-download-hint', '.audit-download-hint-arrow', 'LEFT', -60, 'TOP', -55, 'audit-log-hint');
        this.showHintWaitRender('.audit-download-hint', '.audit-download-hint-text', 'RIGHT', 20, 'TOP', -115, 'audit-log-hint');

        this.showHintWaitRender('.audit-filter-hint', '.audit-filter-hint-arrow', 'RIGHT', -20, 'TOP', -65, 'audit-log-hint');
        this.showHintWaitRender('.audit-filter-hint', '.audit-filter-hint-text', 'RIGHT', -45, 'TOP', -115, 'audit-log-hint');

        this.showHintWaitRender('.audit-search-hint', '.audit-search-hint-arrow', 'LEFT', 210, 'TOP', 30, 'audit-log-hint');
        this.showHintWaitRender('.audit-search-hint', '.audit-search-hint-text', 'LEFT', 260, 'TOP', 80, 'audit-log-hint');

        this.showHintWaitRender('.audit-date-hint', '.audit-date-hint-arrow', 'LEFT', 135, 'TOP', -60, 'audit-log-hint');
        this.showHintWaitRender('.audit-date-hint', '.audit-date-hint-text', 'LEFT', 150, 'TOP', -110, 'audit-log-hint');

        this.showHintWaitRender('.audit-locations-hint', '.audit-locations-hint-arrow', 'LEFT', 40, 'TOP', -70, 'audit-log-hint');
        this.showHintWaitRender('.audit-locations-hint', '.audit-locations-hint-text', 'LEFT', 35, 'TOP', -130, 'audit-log-hint');

        this.showHintWaitRender('.audit-loc-group-hint', '.audit-loc-group-hint-arrow', 'LEFT', 230, 'TOP', 55, 'audit-log-hint');
        this.showHintWaitRender('.audit-loc-group-hint', '.audit-loc-group-hint-text', 'LEFT', 275, 'TOP', 80, 'audit-log-hint');

        this.showHintWaitRender('.audit-item-hint', '.audit-item-hint-arrow', 'LEFT', 150, 'TOP', 20, 'audit-log-hint');
        this.showHintWaitRender('.audit-item-hint', '.audit-item-hint-text', 'LEFT', 190, 'TOP', 75, 'audit-log-hint');
    }

    public dataCommunication() {
        this.scrollToTop('html');
        this.showHintWaitRender('.data-comm-filter-hint', '.data-comm-filter-hint-arrow', 'LEFT', -15, 'TOP', -60, 'data-communication-hint');
        this.showHintWaitRender('.data-comm-filter-hint', '.data-comm-filter-hint-text', 'RIGHT', -40, 'TOP', -100, 'data-communication-hint');

        this.showHintWaitRender('.data-comm-full-hint', '.data-comm-full-hint-arrow', 'LEFT', -30, 'TOP', -50, 'data-communication-hint');
        this.showHintWaitRender('.data-comm-full-hint', '.data-comm-full-hint-text', 'RIGHT', -40, 'TOP', -130, 'data-communication-hint');

        this.showHintWaitRender('.data-comm-close-hint', '.data-comm-close-hint-arrow', 'LEFT', -15, 'TOP', -60, 'data-communication-hint');
        this.showHintWaitRender('.data-comm-close-hint', '.data-comm-close-hint-text', 'RIGHT', -45, 'TOP', -140, 'data-communication-hint');

        this.showHintWaitRender('.data-comm-title-hint', '.data-comm-title-hint-arrow', 'LEFT', 40, 'TOP', -60, 'data-communication-hint');
        this.showHintWaitRender('.data-comm-title-hint', '.data-comm-title-hint-text', 'LEFT', 5, 'TOP', -120, 'data-communication-hint');

        this.showHintWaitRender('.data-comm-headers-hint', '.data-comm-headers-hint-arrow', 'LEFT', 230, 'TOP', 65, 'data-communication-hint');
        this.showHintWaitRender('.data-comm-headers-hint', '.data-comm-headers-hint-text', 'LEFT', 70, 'TOP', 125, 'data-communication-hint');

        this.showHintWaitRender('.mat-column-failureAttempts', '.data-comm-failed-hint-arrow', 'LEFT', 0, 'TOP', -50, 'data-communication-hint');
        this.showHintWaitRender('.mat-column-failureAttempts', '.data-comm-failed-hint-text', 'LEFT', -20, 'TOP', -100, 'data-communication-hint');

        this.showHintWaitRender('.mat-column-totalAttempts', '.data-comm-attempted-hint-arrow', 'LEFT', 30, 'TOP', 0, 'data-communication-hint');
        this.showHintWaitRender('.mat-column-totalAttempts', '.data-comm-attempted-hint-text', 'LEFT', 100, 'TOP', 0, 'data-communication-hint');

        this.showHintWaitRender('.data-comm-item-hint', '.data-comm-item-hint-arrow', 'LEFT', 60, 'TOP', 20, 'data-communication-hint');
        this.showHintWaitRender('.data-comm-item-hint', '.data-comm-item-hint-text', 'LEFT', 125, 'TOP', 50, 'data-communication-hint');

        this.showHintWaitRender('.data-comm-schedule-hint', '.data-comm-schedule-hint-arrow', 'RIGHT', 15, 'TOP', -30, 'data-communication-hint');
        this.showHintWaitRender('.data-comm-schedule-hint', '.data-comm-schedule-hint-text', 'RIGHT', 65, 'TOP', -40, 'data-communication-hint');

        this.showHintWaitRender('.data-comm-collect-hint', '.data-comm-collect-hint-arrow', 'RIGHT', -25, 'TOP', -55, 'data-communication-hint');
        this.showHintWaitRender('.data-comm-collect-hint', '.data-comm-collect-hint-text', 'RIGHT', -90, 'TOP', -160, 'data-communication-hint');

        this.showHintWaitRender('.data-comm-date-hint', '.data-comm-date-hint-arrow', 'LEFT', 200, 'TOP', -50, 'data-communication-hint');
        this.showHintWaitRender('.data-comm-date-hint', '.data-comm-date-hint-text', 'LEFT', 150, 'TOP', -120, 'data-communication-hint');

        this.showHintWaitRender('.data-comm-series-hint', '.data-comm-series-hint-arrow', 'RIGHT', -50, 'TOP', -40, 'data-communication-hint');
        this.showHintWaitRender('.data-comm-series-hint', '.data-comm-series-hint-text', 'LEFT', 0, 'TOP', -100, 'data-communication-hint');

        this.showHintWaitRender(
            '.data-comm-locations-hint',
            '.data-comm-locations-hint-arrow',
            'LEFT',
            280,
            'TOP',
            10,
            'data-communication-hint');
        this.showHintWaitRender('.data-comm-locations-hint', '.data-comm-locations-hint-text', 'LEFT', 80, 'TOP', 20, 'data-communication-hint');

        this.showHintWaitRender(
            '.data-comm-footer-schedule-hint',
            '.data-comm-footer-schedule-hint-arrow',
            'RIGHT',
            -10,
            'TOP',
            -35,
            'data-communication-hint');
        this.showHintWaitRender(
            '.data-comm-footer-schedule-hint',
            '.data-comm-footer-schedule-hint-text',
            'RIGHT',
            40,
            'TOP',
            -65,
            'data-communication-hint');

        this.showHintWaitRender(
            '.data-comm-footer-download-hint',
            '.data-comm-footer-download-hint-arrow',
            'RIGHT',
            10,
            'TOP',
            -5,
            'data-communication-hint');
        this.showHintWaitRender(
            '.data-comm-footer-download-hint',
            '.data-comm-footer-download-hint-text',
            'RIGHT',
            50,
            'TOP',
            20,
            'data-communication-hint');
    }

    public alarmWidgetCollapsed() {
        this.showHintWaitRender('.alarm-sm-title-hint', '.alarm-sm-title-hint-arrow', 'LEFT', 40, 'TOP', -60, 'alarms-sm-widget-hint');
        this.showHintWaitRender('.alarm-sm-title-hint', '.alarm-sm-title-hint-text', 'LEFT', 20, 'TOP', -130, 'alarms-sm-widget-hint');

        this.showHintWaitRender('.alarm-sm-expand-hint', '.alarm-sm-expand-hint-arrow', 'LEFT', 20, 'TOP', -60, 'alarms-sm-widget-hint');
        this.showHintWaitRender('.alarm-sm-expand-hint', '.alarm-sm-expand-hint-text', 'LEFT', 100, 'TOP', -90, 'alarms-sm-widget-hint');

        this.showHintWaitRender('.alarm-sm-close-hint', '.alarm-sm-close-hint-arrow', 'LEFT', 35, 'TOP', -30, 'alarms-sm-widget-hint');
        this.showHintWaitRender('.alarm-sm-close-hint', '.alarm-sm-close-hint-text', 'LEFT', 105, 'TOP', -40, 'alarms-sm-widget-hint');

        this.showHintWaitRender('.alarm-sm-status-hint', '.alarm-sm-status-hint-arrow', 'LEFT', 20, 'TOP', 40, 'alarms-sm-widget-hint');
        this.showHintWaitRender('.alarm-sm-status-hint', '.alarm-sm-status-hint-text', 'LEFT', 20, 'TOP', 120, 'alarms-sm-widget-hint');

        this.showHintWaitRender('.alarm-sm-type-hint', '.alarm-sm-type-hint-arrow', 'LEFT', 50, 'TOP', -35, 'alarms-sm-widget-hint');
        this.showHintWaitRender('.alarm-sm-type-hint', '.alarm-sm-type-hint-text', 'LEFT', 100, 'TOP', -80, 'alarms-sm-widget-hint');

        this.showHintWaitRender('.alarm-sm-date-hint', '.alarm-sm-date-hint-arrow', 'LEFT', 90, 'TOP', 25, 'alarms-sm-widget-hint');
        this.showHintWaitRender('.alarm-sm-date-hint', '.alarm-sm-date-hint-text', 'LEFT', 120, 'TOP', 100, 'alarms-sm-widget-hint');

        this.showHintWaitRender('.alarm-sm-location-hint', '.alarm-sm-location-hint-arrow', 'LEFT', 70, 'TOP', 15, 'alarms-sm-widget-hint');
        this.showHintWaitRender('.alarm-sm-location-hint', '.alarm-sm-location-hint-text', 'LEFT', 120, 'TOP', 75, 'alarms-sm-widget-hint');

        this.showHintWaitRender('.alarm-sm-graph-hint', '.alarm-sm-graph-hint-arrow', 'LEFT', 65, 'TOP', 5, 'alarms-sm-widget-hint');
        this.showHintWaitRender('.alarm-sm-graph-hint', '.alarm-sm-graph-hint-text', 'LEFT', 125, 'TOP', 10, 'alarms-sm-widget-hint');

        this.showHintWaitRender('.alarm-sm-map-hint', '.alarm-sm-map-hint-arrow', 'LEFT', 520, 'TOP', 180, 'alarms-sm-widget-hint');
        this.showHintWaitRender('.alarm-sm-map-hint', '.alarm-sm-map-hint-text', 'LEFT', 500, 'TOP', 250, 'alarms-sm-widget-hint');
    }

    public batteryStatusCollapsed() {
        this.showHintWaitRender('.battery-sm-title-hint', '.battery-sm-title-hint-arrow', 'LEFT', 40, 'TOP', -70, 'battery-sm-widget-hint');
        this.showHintWaitRender('.battery-sm-title-hint', '.battery-sm-title-hint-text', 'LEFT', 20, 'TOP', -120, 'battery-widget-hint');

        this.showHintWaitRender('.battery-sm-filter-hint', '.battery-sm-filter-hint-arrow', 'LEFT', 30, 'TOP', -60, 'battery-sm-widget-hint');
        this.showHintWaitRender('.battery-sm-filter-hint', '.battery-sm-filter-hint-text', 'LEFT', 90, 'TOP', -80, 'battery-widget-hint');

        this.showHintWaitRender('.battery-sm-expand-hint', '.battery-sm-expand-hint-arrow', 'LEFT', 30, 'TOP', -40, 'battery-sm-widget-hint');
        this.showHintWaitRender('.battery-sm-expand-hint', '.battery-sm-expand-hint-text', 'LEFT', 90, 'TOP', -40, 'battery-widget-hint');

        this.showHintWaitRender('.battery-sm-close-hint', '.battery-sm-close-hint-arrow', 'LEFT', 40, 'TOP', -10, 'battery-sm-widget-hint');
        this.showHintWaitRender('.battery-sm-close-hint', '.battery-sm-close-hint-text', 'LEFT', 100, 'TOP', 15, 'battery-widget-hint');

        this.showHintWaitRender('.battery-sm-location-hint', '.battery-sm-location-hint-arrow', 'LEFT', 20, 'TOP', 40, 'battery-sm-widget-hint');
        this.showHintWaitRender('.battery-sm-location-hint', '.battery-sm-location-hint-text', 'LEFT', 0, 'TOP', 120, 'battery-widget-hint');

        this.showHintWaitRender('.battery-sm-series-hint', '.battery-sm-series-hint-arrow', 'LEFT', 70, 'TOP', 40, 'battery-sm-widget-hint');
        this.showHintWaitRender('.battery-sm-series-hint', '.battery-sm-series-hint-text', 'LEFT', 110, 'TOP', 120, 'battery-widget-hint');

        this.showHintWaitRender('.battery-sm-voltage-hint', '.battery-sm-voltage-hint-arrow', 'LEFT', 100, 'TOP', 40, 'battery-sm-widget-hint');
        this.showHintWaitRender('.battery-sm-voltage-hint', '.battery-sm-voltage-hint-text', 'LEFT', 160, 'TOP', 70, 'battery-widget-hint');

        this.showHintWaitRender('.battery-sm-date-hint', '.battery-sm-date-hint-arrow', 'LEFT', 90, 'TOP', 0, 'battery-sm-widget-hint');
        this.showHintWaitRender('.battery-sm-date-hint', '.battery-sm-date-hint-text', 'LEFT', 150, 'TOP', 25, 'battery-widget-hint');

        this.showHintWaitRender('.battery-sm-buy-now-hint', '.battery-sm-buy-now-hint-arrow', 'LEFT', 90, 'TOP', -25, 'battery-sm-widget-hint');
        this.showHintWaitRender('.battery-sm-buy-now-hint', '.battery-sm-buy-now-hint-text', 'LEFT', 160, 'TOP', -40, 'battery-widget-hint');

        this.showHintWaitRender('.battery-sm-location-filter-hint', '.battery-sm-location-filter-hint-arrow', 'LEFT', 440, 'TOP', 0, 'battery-sm-widget-hint');
        this.showHintWaitRender('.battery-sm-location-filter-hint', '.battery-sm-location-filter-hint-text', 'LEFT', 500, 'TOP', 40, 'battery-widget-hint');

        this.showHintWaitRender('.battery-sm-status-filter-hint', '.battery-sm-status-filter-hint-arrow', 'LEFT', 440, 'TOP', 0, 'battery-sm-widget-hint');
        this.showHintWaitRender('.battery-sm-status-filter-hint', '.battery-sm-status-filter-hint-text', 'LEFT', 500, 'TOP', 40, 'battery-widget-hint');
    }

    public dataCommStatusCollapsed() {
        this.showHintWaitRender('.data-comm-title-hint', '.dcomm-title-hint-arrow', 'LEFT', 100, 'TOP', -45, 'dataComm-sm-widget-hint');
        this.showHintWaitRender('.data-comm-title-hint', '.dcomm-title-hint-text', 'LEFT', 155, 'TOP', -60, 'dataComm-sm-widget-hint');

        this.showHintWaitRender('.data-comm-full-hint', '.dcomm-full-hint-arrow', 'LEFT', 30, 'TOP', -45, 'dataComm-sm-widget-hint');
        this.showHintWaitRender('.data-comm-full-hint', '.dcomm-full-hint-text', 'LEFT', 80, 'TOP', -80, 'dataComm-sm-widget-hint');

        this.showHintWaitRender('.data-comm-close-hint', '.dcomm-close-hint-arrow', 'LEFT', 30, 'TOP', -25, 'dataComm-sm-widget-hint');
        this.showHintWaitRender('.data-comm-close-hint', '.dcomm-close-hint-text', 'LEFT', 90, 'TOP', -30, 'dataComm-sm-widget-hint');

        this.showHintWaitRender('.collection-action-btn-sc', '.dcomm-location_s_c-hint-arrow', 'LEFT', 20, 'TOP', 0, 'dataComm-sm-widget-hint');
        this.showHintWaitRender('.collection-action-btn-sc', '.dcomm-location_s_c-hint-text', 'LEFT', 80, 'TOP', 0, 'dataComm-sm-widget-hint');

        this.showHintWaitRender('.collection-action-btn-c', '.dcomm-location_c-hint-arrow', 'LEFT', 20, 'TOP', -40, 'dataComm-sm-widget-hint');
        this.showHintWaitRender('.collection-action-btn-c', '.dcomm-location_c-hint-text', 'LEFT', 70, 'TOP', -70, 'dataComm-sm-widget-hint');

        this.showHintWaitRender('.data-comm-footer-schedule-hint', '.dcomm-schedule-hint-arrow', 'LEFT', 100, 'TOP', 0, 'dataComm-sm-widget-hint');
        this.showHintWaitRender('.data-comm-footer-schedule-hint', '.dcomm-schedule-hint-text', 'LEFT', 160, 'TOP', 40, 'dataComm-sm-widget-hint');

        this.showHintWaitRender('.data-comm-footer-collect-all-hint', '.dcomm-collect-all-hint-arrow', 'LEFT', 100, 'TOP', -30, 'dataComm-sm-widget-hint');
        this.showHintWaitRender('.data-comm-footer-collect-all-hint', '.dcomm-collect-all-hint-text', 'LEFT', 160, 'TOP', -50, 'dataComm-sm-widget-hint');
    }

    public alarmWidgetExpanded() {
        this.showHintWaitRender('.alarm-title-hint', '.alarm-title-hint-arrow', 'LEFT', 100, 'TOP', -25, 'alarms-widget-hint');
        this.showHintWaitRender('.alarm-title-hint', '.alarm-title-hint-text', 'LEFT', 160, 'TOP', -30, 'alarms-widget-hint');

        this.showHintWaitRender('.alarm-filter-hint', '.alarm-filter-hint-arrow', 'LEFT', -60, 'TOP', -5, 'alarms-widget-hint');
        this.showHintWaitRender('.alarm-filter-hint', '.alarm-filter-hint-text', 'LEFT', -200, 'TOP', 40, 'alarms-widget-hint');

        this.showHintWaitRender('.alarm-collapse-hint', '.alarm-collapse-hint-arrow', 'LEFT', -35, 'TOP', -50, 'alarms-widget-hint');
        this.showHintWaitRender('.alarm-collapse-hint', '.alarm-collapse-hint-text', 'LEFT', -200, 'TOP', -55, 'alarms-widget-hint');

        this.showHintWaitRender('.alarm-close-hint', '.alarm-close-hint-arrow', 'LEFT', -10, 'TOP', -65, 'alarms-widget-hint');
        this.showHintWaitRender('.alarm-close-hint', '.alarm-close-hint-text', 'LEFT', -100, 'TOP', -100, 'alarms-widget-hint');

        this.showHintWaitRender('.alarm-status-hint', '.alarm-status-hint-arrow', 'LEFT', 50, 'TOP', 15, 'alarms-widget-hint');
        this.showHintWaitRender('.alarm-status-hint', '.alarm-status-hint-text', 'LEFT', 120, 'TOP', 45, 'alarms-widget-hint');

        this.showHintWaitRender('.alarm-type-hint', '.alarm-type-hint-arrow', 'LEFT', 50, 'TOP', -20, 'alarms-widget-hint');
        this.showHintWaitRender('.alarm-type-hint', '.alarm-type-hint-text', 'LEFT', 120, 'TOP', -25, 'alarms-widget-hint');

        this.showHintWaitRender('.alarm-date-hint', '.alarm-date-hint-arrow', 'LEFT', 20, 'TOP', 35, 'alarms-widget-hint');
        this.showHintWaitRender('.alarm-date-hint', '.alarm-date-hint-text', 'LEFT', 90, 'TOP', 70, 'alarms-widget-hint');

        this.showHintWaitRender('.alarm-location-hint', '.alarm-location-hint-arrow', 'LEFT', 50, 'TOP', 15, 'alarms-widget-hint');
        this.showHintWaitRender('.alarm-location-hint', '.alarm-location-hint-text', 'LEFT', 120, 'TOP', 45, 'alarms-widget-hint');

        this.showHintWaitRender('.alarm-location-card-hint', '.alarm-location-card-hint-arrow', 'LEFT', 20, 'TOP', 100, 'alarms-widget-hint');
        this.showHintWaitRender('.alarm-location-card-hint', '.alarm-location-card-hint-text', 'LEFT', 5, 'TOP', 175, 'alarms-widget-hint');

        this.showHintWaitRender('.alarm-graph-hint', '.alarm-graph-hint-arrow', 'LEFT', 50, 'TOP', 70, 'alarms-widget-hint');
        this.showHintWaitRender('.alarm-graph-hint', '.alarm-graph-hint-text', 'LEFT', 120, 'TOP', 100, 'alarms-widget-hint');

        this.showHintWaitRender('.alarm-download-hint', '.alarm-download-hint-arrow', 'LEFT', 0, 'TOP', -50, 'alarms-widget-hint');
        this.showHintWaitRender('.alarm-download-hint', '.alarm-download-hint-text', 'LEFT', -180, 'TOP', -55, 'alarms-widget-hint');
    }

    public batteryStatusExpanded() {
        this.showHintWaitRender('.battery-title-hint', '.battery-title-hint-arrow', 'LEFT', 20, 'TOP', -60, 'battery-status-hint');
        this.showHintWaitRender('.battery-title-hint', '.battery-title-hint-text', 'LEFT', 5, 'TOP', -140, 'battery-status-hint');

        this.showHintWaitRender('.battery-filter-hint', '.battery-filter-hint-arrow', 'LEFT', -40, 'TOP', -55, 'battery-status-hint');
        this.showHintWaitRender('.battery-filter-hint', '.battery-filter-hint-text', 'RIGHT', -40, 'TOP', -105, 'battery-status-hint');

        this.showHintWaitRender('.battery-fullscreen-hint', '.battery-fullscreen-hint-arrow', 'LEFT', -40, 'TOP', -55, 'battery-status-hint');
        this.showHintWaitRender('.battery-fullscreen-hint', '.battery-fullscreen-hint-text', 'RIGHT', -35, 'TOP', -90, 'battery-status-hint');

        this.showHintWaitRender('.battery-close-hint', '.battery-close-hint-arrow', 'LEFT', -20, 'TOP', -70, 'battery-status-hint');
        this.showHintWaitRender('.battery-close-hint', '.battery-close-hint-text', 'RIGHT', -25, 'TOP', -130, 'battery-status-hint');

        this.showHintWaitRender('.battery-locations-hint', '.battery-locations-hint-arrow', 'LEFT', 90, 'TOP', -15, 'battery-status-hint');
        this.showHintWaitRender('.battery-locations-hint', '.battery-locations-hint-text', 'LEFT', 155, 'TOP', 5, 'battery-status-hint');

        this.showHintWaitRender('.battery-download-hint', '.battery-download-hint-arrow', 'RIGHT', -15, 'TOP', -45, 'battery-status-hint');
        this.showHintWaitRender('.battery-download-hint', '.battery-download-hint-text', 'RIGHT', 10, 'TOP', -95, 'battery-status-hint');

        this.showHintWaitRender('.battery-buy-hint', '.battery-buy-hint-arrow', 'RIGHT', -50, 'TOP', -55, 'battery-status-hint');
        this.showHintWaitRender('.battery-buy-hint', '.battery-buy-hint-text', 'RIGHT', -110, 'TOP', -160, 'battery-status-hint');

        this.showHintWaitRender(
            '.battery-filter-status-hint',
            '.battery-filter-status-hint-arrow',
            'LEFT',
            55,
            'TOP',
            5,
            'battery-status-hint');
        this.showHintWaitRender(
            '.battery-filter-status-hint',
            '.battery-filter-status-hint-text',
            'LEFT',
            125,
            'TOP',
            40,
            'battery-status-hint');

        this.showHintWaitRender(
            '.battery-locations-filter-hint',
            '.battery-locations-filter-hint-arrow',
            'LEFT',
            70,
            'TOP',
            -20,
            'battery-status-hint');
        this.showHintWaitRender(
            '.battery-locations-filter-hint',
            '.battery-locations-filter-hint-text',
            'LEFT',
            135,
            'TOP',
            -40,
            'battery-status-hint');
    }

    public resetHintPageName() {
        if (!(this.router.url.indexOf('viewData') > -1 && this.hintPageName === EDIT_VIEW_DATA)) {
            this.hintPageName = NONE;
        }
    }

    public pickEditUserHint() {
        switch (this.editUserSelectedTab) {
            case 0:
                this.editUserDetails();
                break;
            case 1:
                this.editUserRole();
                break;
            case 2:
                this.editUserCustomer();
                break;
            case 3:
                this.editUserLocation();
                break;
            case 4:
                this.editUserFeatures();
                break;
        }
    }

    public customDashboard() {
        this.showpageHintIcon('.cd-print-hint', '.cd-print-hint-arrow', 'RIGHT', 10, 'TOP', -30);
        this.showpageHintIcon('.cd-print-hint', '.cd-print-hint-text', 'RIGHT', 60, 'TOP', -50);

        this.showpageHintIcon('.cd-add-hint', '.cd-add-hint-arrow', 'RIGHT', -40, 'TOP', -50);
        this.showpageHintIcon('.cd-add-hint', '.cd-add-hint-text', 'RIGHT', -95, 'TOP', -70);

        this.showpageHintIcon('.cd-full-hint', '.cd-full-hint-arrow', 'RIGHT', 5, 'TOP', -30);
        this.showpageHintIcon('.cd-full-hint', '.cd-full-hint-text', 'RIGHT', 55, 'TOP', -50);

        this.showpageHintIcon('.cd-edit-hint', '.cd-edit-hint-arrow', 'RIGHT', 0, 'TOP', 10);
        this.showpageHintIcon('.cd-edit-hint', '.cd-edit-hint-text', 'RIGHT', 35, 'TOP', 50);

        this.showpageHintIcon('.cd-delete-hint', '.cd-delete-hint-arrow', 'RIGHT', -20, 'TOP', 20);
        this.showpageHintIcon('.cd-delete-hint', '.cd-delete-hint-text', 'RIGHT', -35, 'TOP', 90);

        this.showpageHintIcon('.highcharts-range-selector-group', '.cd-zoom-hint-arrow', 'LEFT', 50, 'TOP', 30);
        this.showpageHintIcon('.highcharts-range-selector-group', '.cd-zoom-hint-text', 'LEFT', -60, 'TOP', 100);

        this.showpageHintIcon('.highcharts-range-selector-group', '.cd-tracer-hint-arrow', 'LEFT', 250, 'TOP', 30);
        this.showpageHintIcon('.highcharts-range-selector-group', '.cd-tracer-hint-text', 'LEFT', 210, 'TOP', 100);
    }

    public customDashboardDialog() {
        this.showHintWaitRender('.cd-hg-hint', '.cd-hg-hint-arrow', 'RIGHT', 15, 'TOP', -30, 'custom-dashboard-dialog-hint');
        this.showHintWaitRender('.cd-hg-hint', '.cd-hg-hint-text', 'RIGHT', 0, 'TOP', -60, 'custom-dashboard-dialog-hint');

        this.showHintWaitRender('.cd-sg-hint', '.cd-sg-hint-arrow', 'RIGHT', 10, 'TOP', -30, 'custom-dashboard-dialog-hint');
        this.showHintWaitRender('.cd-sg-hint', '.cd-sg-hint-text', 'RIGHT', 60, 'TOP', -50, 'custom-dashboard-dialog-hint');

        this.showHintWaitRender('.cd-dialog-date-hint', '.cd-dialog-date-hint-arrow', 'RIGHT', 10, 'TOP', 0, 'custom-dashboard-dialog-hint');
        this.showHintWaitRender('.cd-dialog-date-hint', '.cd-dialog-date-hint-text', 'RIGHT', 60, 'TOP', 0, 'custom-dashboard-dialog-hint');

        this.showHintWaitRender('.cd-dialog-name-hint', '.cd-dialog-name-hint-arrow', 'RIGHT', 10, 'TOP', -30, 'custom-dashboard-dialog-hint');
        this.showHintWaitRender('.cd-dialog-name-hint', '.cd-dialog-name-hint-text', 'RIGHT', 70, 'TOP', -30, 'custom-dashboard-dialog-hint');

        this.showHintWaitRender('.cd-dialog-rolling-hint', '.cd-dialog-rolling-hint-arrow', 'RIGHT', -110, 'TOP', -30, 'custom-dashboard-dialog-hint');
        this.showHintWaitRender('.cd-dialog-rolling-hint', '.cd-dialog-rolling-hint-text', 'LEFT', 130, 'TOP', -50, 'custom-dashboard-dialog-hint');

        this.showHintWaitRender('.cd-dialog-entity-hint', '.cd-dialog-entity-hint-arrow', 'RIGHT', 10, 'TOP', 20, 'custom-dashboard-dialog-hint');
        this.showHintWaitRender('.cd-dialog-entity-hint', '.cd-dialog-entity-hint-text', 'RIGHT', 20, 'TOP', 60, 'custom-dashboard-dialog-hint');

        this.showHintWaitRender('.cd-dialog-color-hint', '.cd-dialog-color-hint-arrow', 'RIGHT', -20, 'TOP', -50, 'custom-dashboard-dialog-hint');
        this.showHintWaitRender('.cd-dialog-color-hint', '.cd-dialog-color-hint-text', 'RIGHT', -20, 'TOP', -60, 'custom-dashboard-dialog-hint');

        this.showHintWaitRender('.cd-dialog-delete-hint', '.cd-dialog-delete-hint-arrow', 'LEFT', 50, 'TOP', 0, 'custom-dashboard-dialog-hint');
        this.showHintWaitRender('.cd-dialog-delete-hint', '.cd-dialog-delete-hint-text', 'LEFT', 110, 'TOP', 20, 'custom-dashboard-dialog-hint');

        this.showHintWaitRender('.cd-dialog-add-more-hint', '.cd-dialog-add-more-hint-arrow', 'RIGHT', 20, 'TOP', 0, 'custom-dashboard-dialog-hint');
        this.showHintWaitRender('.cd-dialog-add-more-hint', '.cd-dialog-add-more-hint-text', 'RIGHT', 40, 'TOP', 20, 'custom-dashboard-dialog-hint');

        this.showHintWaitRender('.cd-dialog-y-entity-hint', '.cd-dialog-y-entity-hint-arrow', 'RIGHT', 10, 'TOP', 35, 'custom-dashboard-dialog-hint');
        this.showHintWaitRender('.cd-dialog-y-entity-hint', '.cd-dialog-y-entity-hint-text', 'RIGHT', 35, 'TOP', 60, 'custom-dashboard-dialog-hint');

        this.showHintWaitRender('.cd-dialog-x-entity-hint', '.cd-dialog-x-entity-hint-arrow', 'RIGHT', 0, 'TOP', 50, 'custom-dashboard-dialog-hint');
        this.showHintWaitRender('.cd-dialog-x-entity-hint', '.cd-dialog-x-entity-hint-text', 'RIGHT', 20, 'TOP', 70, 'custom-dashboard-dialog-hint');

        this.showHintWaitRender('.cd-dialog-data-averag-hint', '.cd-dialog-data-averag-hint-arrow', 'RIGHT', 20, 'TOP', -10, 'custom-dashboard-dialog-hint');
        this.showHintWaitRender('.cd-dialog-data-averag-hint', '.cd-dialog-data-averag-hint-text', 'RIGHT', 10, 'TOP', -30, 'custom-dashboard-dialog-hint');

        this.showHintWaitRender('.cd-dialog-close-hint', '.cd-dialog-close-hint-arrow', 'RIGHT', 20, 'TOP', 0, 'custom-dashboard-dialog-hint');
        this.showHintWaitRender('.cd-dialog-close-hint', '.cd-dialog-close-hint-text', 'RIGHT', 60, 'TOP', 30, 'custom-dashboard-dialog-hint');

        this.showHintWaitRender('.cd-dialog-save-hint', '.cd-dialog-save-hint-arrow', 'LEFT', 70, 'TOP', 0, 'custom-dashboard-dialog-hint');
        this.showHintWaitRender('.cd-dialog-save-hint', '.cd-dialog-save-hint-text', 'LEFT', 130, 'TOP', 30, 'custom-dashboard-dialog-hint');

        this.showHintWaitRender('.cd-dialog-location-hint', '.cd-dialog-location-hint-arrow', 'RIGHT', 20, 'TOP', -15, 'custom-dashboard-dialog-hint');
        this.showHintWaitRender('.cd-dialog-location-hint', '.cd-dialog-location-hint-text', 'RIGHT', 40, 'TOP', -30, 'custom-dashboard-dialog-hint');
    }

    public editUserDetails() {
        this.selectedHintPageName.next('edit-user-details');

        this.showpageHintIcon('.userDetailsHint', '.userDetailsHint-arrow', 'LEFT', 120, 'TOP', -30);
        this.showpageHintIcon('.userDetailsHint', '.userDetailsHint-text', 'LEFT', 185, 'TOP', -70);

        this.showpageHintIcon('.activate-user-hint', '.activate-user-hint-arrow', 'RIGHT', 25, 'TOP', -30);
        this.showpageHintIcon('.activate-user-hint', '.activate-user-hint-text', 'RIGHT', 85, 'TOP', -50);

        this.showHintWaitRender('.active-hint', '.active-hint-arrow', 'LEFT', 40, 'TOP', 365, 'edit-user-details', 'vw');
        this.showHintWaitRender('.active-hint', '.active-hint-text', 'LEFT', 30, 'TOP', 430, 'edit-user-details', 'vw');

        this.showHintWaitRender('.save-user-hint', '.save-user-hint-arrow', 'RIGHT', 15, 'TOP', 0, 'edit-user-details');
        this.showHintWaitRender('.save-user-hint', '.save-user-hint-text', 'RIGHT', 65, 'TOP', 20, 'edit-user-details');
    }

    public editUserRole() {
        this.selectedHintPageName.next('edit-user-role');

        this.showpageHintIcon('.assignRolesUserHint', '.assignRolesUserHint-arrow', 'LEFT', 110, 'TOP', -30);
        this.showpageHintIcon('.assignRolesUserHint', '.assignRolesUserHint-text', 'LEFT', 175, 'TOP', -70);

        this.showHintWaitRender('.roles-hint', '.roles-hint-arrow', 'LEFT', 170, 'TOP', 30, 'edit-user-role');
        this.showHintWaitRender('.roles-hint', '.roles-hint-text', 'LEFT', 230, 'TOP', -10, 'edit-user-role');
    }

    public editUserCustomer() {
        this.selectedHintPageName.next('edit-user-customer');

        this.showpageHintIcon('.assignCustomersUserHint', '.assignCustomersUserHint-arrow', 'LEFT', 145, 'TOP', -30);
        this.showpageHintIcon('.assignCustomersUserHint', '.assignCustomersUserHint-text', 'LEFT', 210, 'TOP', -70);

        this.showHintWaitRender('.customer-search-hint', '.customer-search-hint-arrow', 'RIGHT', 35, 'TOP', -10, 'edit-user-customer');
        this.showHintWaitRender('.customer-search-hint', '.customer-search-hint-text', 'RIGHT', 80, 'TOP', -30, 'edit-user-customer');

        this.showHintWaitRender('.customer-list-hint', '.customer-list-hint-arrow', 'LEFT', 110, 'TOP', -70, 'edit-user-customer');
        this.showHintWaitRender('.customer-list-hint', '.customer-list-hint-text', 'LEFT', 55, 'TOP', -180, 'edit-user-customer');
    }

    public editUserLocation() {
        this.selectedHintPageName.next('edit-user-location');

        this.showpageHintIcon('.assignLocationsUserHint', '.assignLocationsUserHint-arrow', 'LEFT', 135, 'TOP', -30);
        this.showpageHintIcon('.assignLocationsUserHint', '.assignLocationsUserHint-text', 'LEFT', 200, 'TOP', -70);

        this.showHintWaitRender('.select-all-hint', '.select-all-hint-arrow', 'LEFT', 100, 'TOP', -25, 'edit-user-location');
        this.showHintWaitRender('.select-all-hint', '.select-all-hint-text', 'LEFT', 165, 'TOP', -40, 'edit-user-location');

        this.showHintWaitRender('.customer-select-hint', '.customer-select-hint-arrow', 'LEFT', 250, 'TOP', -60, 'edit-user-location');
        this.showHintWaitRender('.customer-select-hint', '.customer-select-hint-text', 'LEFT', 190, 'TOP', -130, 'edit-user-location');

        this.showHintWaitRender('.location-list-hint', '.location-list-hint-arrow', 'LEFT', 225, 'TOP', 250, 'edit-user-location');
        this.showHintWaitRender('.location-list-hint', '.location-list-hint-text', 'LEFT', 150, 'TOP', 320, 'edit-user-location');
    }

    public editUserFeatures() {
        this.selectedHintPageName.next('edit-user-features');

        this.showpageHintIcon('.assignFeaturesUserHint', '.assignFeaturesUserHint-arrow', 'LEFT', 135, 'TOP', -30);
        this.showpageHintIcon('.assignFeaturesUserHint', '.assignFeaturesUserHint-text', 'LEFT', 200, 'TOP', -70);

        this.showHintWaitRender('.features-hint', '.features-hint-arrow', 'LEFT', 125, 'TOP', -65, 'edit-user-features');
        this.showHintWaitRender('.features-hint', '.features-hint-text', 'LEFT', 80, 'TOP', -120, 'edit-user-features');
    }

    public showUsersPage() {
        this.showpageHintIcon('.search-users-hint', '.search-users-hint-arrow', 'LEFT', 200, 'TOP', -60);
        this.showpageHintIcon('.search-users-hint', '.search-users-hint-text', 'LEFT', 155, 'TOP', -135);

        this.showpageHintIcon('.user-status-hint', '.user-status-hint-arrow', 'RIGHT', 25, 'TOP', 0);
        this.showpageHintIcon('.user-status-hint', '.user-status-hint-text', 'RIGHT', 85, 'TOP', 20);

        this.showpageHintIcon('.user-edit-hint', '.user-edit-hint-arrow', 'RIGHT', 25, 'TOP', -40);
        this.showpageHintIcon('.user-edit-hint', '.user-edit-hint-text', 'RIGHT', 65, 'TOP', -90);

        this.showpageHintIcon('.user-add-hint', '.user-add-hint-arrow', 'LEFT', 75, 'TOP', 0);
        this.showpageHintIcon('.user-add-hint', '.user-add-hint-text', 'LEFT', 140, 'TOP', -10);
    }

    public showToolsSecondOverlay() {
        this.showHintWaitRender('.monitor-name-hint', '.monitor-name-hint-arrow', 'RIGHT', -60, 'TOP', -70, 'homePageFourthTab');
        this.showHintWaitRender('.monitor-name-hint', '.monitor-name-hint-text', 'RIGHT', -200, 'TOP', -140, 'homePageFourthTab');

        this.showHintWaitRender('.edit-mon-hint', '.edit-mon-hint-arrow', 'RIGHT', -10, 'TOP', 25, 'homePageFourthTab');
        this.showHintWaitRender('.edit-mon-hint', '.edit-mon-hint-text', 'RIGHT', -45, 'TOP', 80, 'homePageFourthTab');

        this.showHintWaitRender('.child-search-hint', '.child-search-hint-arrow', 'RIGHT', -80, 'TOP', 20, 'homePageFourthTab');
        this.showHintWaitRender('.child-search-hint', '.child-search-hint-text', 'RIGHT', -210, 'TOP', 90, 'homePageFourthTab');

        this.showHintWaitRender('.add-mon-hint', '.add-mon-hint-arrow', 'RIGHT', -45, 'TOP', -60, 'homePageFourthTab');
        this.showHintWaitRender('.add-mon-hint', '.add-mon-hint-text', 'RIGHT', -195, 'TOP', -130, 'homePageFourthTab');

        this.showHintWaitRender('.mon-search-hint', '.mon-search-hint-arrow', 'RIGHT', -85, 'TOP', 0, 'homePageFourthTab');
        this.showHintWaitRender('.mon-search-hint', '.mon-search-hint-text', 'RIGHT', -445, 'TOP', 20, 'homePageFourthTab');

        this.showHintWaitRender('.prop-items-hint', '.prop-items-hint-arrow', 'LEFT', 31, 'TOP', 50, 'homePageFourthTab', 'vw');
        this.showHintWaitRender('.prop-items-hint', '.prop-items-hint-text', 'LEFT', 35, 'TOP', 70, 'homePageFourthTab', 'vw');
    }

    /**
     * Show Top nav Hint
     */
    public topNavHint() {
        this.showpageHintIcon('.customer-selector-hint', '.customer-selector-hint-arrow', 'RIGHT', 25, 'TOP', 0);
        this.showpageHintIcon('.customer-selector-hint', '.customer-selector-hint-text', 'RIGHT', 85, 'TOP', 0);

        this.showpageHintIcon('.location-group-hint', '.location-group-hint-arrow', 'RIGHT', 20, 'TOP', 55);
        this.showpageHintIcon('.location-group-hint', '.location-group-hint-text', 'RIGHT', 75, 'TOP', 90);

        this.showpageHintIcon('.active-inactive-hint', '.active-inactive-hint-arrow', 'RIGHT', 10, 'TOP', 55);
        this.showpageHintIcon('.active-inactive-hint', '.active-inactive-hint-text', 'RIGHT', 30, 'TOP', 90);

        this.showpageHintIcon('.notification-hint', '.notification-hint-arrow', 'LEFT', -10, 'TOP', 70);
        this.showpageHintIcon('.notification-hint', '.notification-hint-text', 'LEFT', -45, 'TOP', 140);

        this.showpageHintIcon('.user-hint', '.user-hint-arrow', 'RIGHT', -70, 'TOP', 70);
        this.showpageHintIcon('.user-hint', '.user-hint-text', 'RIGHT', -140, 'TOP', 140);

        this.showpageHintIcon('.pin-hint', '.pin-hint-arrow', 'RIGHT', 10, 'TOP', -30);
        this.showpageHintIcon('.pin-hint', '.pin-hint-text', 'RIGHT', 70, 'TOP', -70);

        this.showHintWaitRender('.widgets-hint', '.widgets-hint-arrow', 'LEFT', 100, 'TOP', -5, 'homePageIconHint');
        this.showHintWaitRender('.widgets-hint', '.widgets-hint-text', 'LEFT', 160, 'TOP', 10, 'homePageIconHint');

        this.showHintWaitRender('.auto-review-hint', '.auto-review-hint-arrow', 'LEFT', 31, 'TOP', 0, 'homePageIconHint', 'vw');
        this.showHintWaitRender('.auto-review-hint', '.auto-review-hint-text', 'LEFT', 35, 'TOP', 1, 'homePageIconHint', 'vw');

        this.showHintWaitRender('.blockage-predict-hint', '.blockage-predict-hint-arrow', 'LEFT', 31, 'TOP', 0, 'homePageIconHint', 'vw');
        this.showHintWaitRender('.blockage-predict-hint', '.blockage-predict-hint-text', 'LEFT', 35, 'TOP', 1, 'homePageIconHint', 'vw');

        this.showHintWaitRender('.data-comm-hint', '.data-comm-hint-arrow', 'LEFT', 31, 'TOP', 0, 'homePageIconHint', 'vw');
        this.showHintWaitRender('.data-comm-hint', '.data-comm-hint-text', 'LEFT', 35, 'TOP', 1, 'homePageIconHint', 'vw');

        this.showHintWaitRender('.battery-hint', '.battery-hint-arrow', 'LEFT', 31, 'TOP', 0, 'homePageIconHint', 'vw');
        this.showHintWaitRender('.battery-hint', '.battery-hint-text', 'LEFT', 35, 'TOP', 1, 'homePageIconHint', 'vw');

        this.showHintWaitRender('.alarm-hint', '.alarm-hint-arrow', 'LEFT', 31, 'TOP', 0, 'homePageIconHint', 'vw');
        this.showHintWaitRender('.alarm-hint', '.alarm-hint-text', 'LEFT', 35, 'TOP', -15, 'homePageIconHint', 'vw');

        this.showHintWaitRender('.event-widget-hint', '.event-widget-hint-arrow', 'LEFT', 31, 'TOP', 0, 'homePageIconHint', 'vw');
        this.showHintWaitRender('.event-widget-hint', '.event-widget-hint-text', 'LEFT', 35, 'TOP', 15, 'homePageIconHint', 'vw');

        // blockage predict widget hints
        if (this.isHomePageWidgetToggled && this.homePageSelectedWidget === WIDGET_PARAM.BLOCKAGE_PREDICT) {
            this.showHintWaitRender('.blockage-filter-hint', '.blockage-filter-hint-arrow', 'LEFT', 55, 'TOP', -10, 'homePageIconHint');
            this.showHintWaitRender('.blockage-filter-hint', '.blockage-filter-hint-text', 'LEFT', 110, 'TOP', 10, 'homePageIconHint');

            this.showHintWaitRender('.blockage-close-hint', '.blockage-close-hint-arrow', 'RIGHT', 20, 'TOP', -50, 'homePageIconHint');
            this.showHintWaitRender('.blockage-close-hint', '.blockage-close-hint-text', 'RIGHT', -120, 'TOP', -80, 'homePageIconHint');

            this.showHintWaitRender('.blockage-location-hint', '.blockage-location-hint-arrow', 'LEFT', 20, 'TOP', 40, 'homePageIconHint');
            this.showHintWaitRender('.blockage-location-hint', '.blockage-location-hint-text', 'LEFT', 20, 'TOP', 100, 'homePageIconHint');

            this.showHintWaitRender('.blockage-date-hint', '.blockage-date-hint-arrow', 'LEFT', -30, 'TOP', -40, 'homePageIconHint');
            this.showHintWaitRender('.blockage-date-hint', '.blockage-date-hint-text', 'LEFT', -110, 'TOP', -90, 'homePageIconHint');

            this.showHintWaitRender('.blockage-status-hint', '.blockage-status-hint-arrow', 'LEFT', 35, 'TOP', 40, 'homePageIconHint');
            this.showHintWaitRender('.blockage-status-hint', '.blockage-status-hint-text', 'LEFT', 20, 'TOP', 100, 'homePageIconHint');

            this.showHintWaitRender('.blockage-dtrend-hint', '.blockage-dtrend-hint-arrow', 'LEFT', 80, 'TOP', 20, 'homePageIconHint');
            this.showHintWaitRender('.blockage-dtrend-hint', '.blockage-dtrend-hint-text', 'LEFT', 120, 'TOP', 50, 'homePageIconHint');

            this.showHintWaitRender('.blockage-filter-start-hint', '.blockage-filter-start-hint-arrow', 'LEFT', 80, 'TOP', -30, 'homePageIconHint');
            this.showHintWaitRender('.blockage-filter-start-hint', '.blockage-filter-start-hint-text', 'LEFT', 20, 'TOP', -80, 'homePageIconHint');

            this.showHintWaitRender('.blockage-filter-end-hint', '.blockage-filter-end-hint-arrow', 'LEFT', 80, 'TOP', 10, 'homePageIconHint');
            this.showHintWaitRender('.blockage-filter-end-hint', '.blockage-filter-end-hint-text', 'LEFT', 140, 'TOP', 20, 'homePageIconHint');

            this.showHintWaitRender('.blockage-filter-locations-hint', '.blockage-filter-locations-hint-arrow', 'LEFT', 50, 'TOP', 0, 'homePageIconHint');
            this.showHintWaitRender('.blockage-filter-locations-hint', '.blockage-filter-locations-hint-text', 'LEFT', 100, 'TOP', 0, 'homePageIconHint');

            this.showHintWaitRender('.blockage-filter-status-hint', '.blockage-filter-status-hint-arrow', 'LEFT', 180, 'TOP', 0, 'homePageIconHint');
            this.showHintWaitRender('.blockage-filter-status-hint', '.blockage-filter-status-hint-text', 'LEFT', 230, 'TOP', 10, 'homePageIconHint');

            this.showHintWaitRender('.blockage-paginator-hint', '.blockage-paginator-hint-arrow', 'LEFT', 30, 'TOP', -10, 'homePageIconHint', 'vw');
            this.showHintWaitRender('.blockage-paginator-hint', '.blockage-paginator-hint-text', 'LEFT', 34, 'TOP', -25, 'homePageIconHint', 'vw');
        }

        if (this.isHomePageWidgetToggled && this.homePageSelectedWidget === WIDGET_PARAM.AUTO_REVIEW) {
            this.scrollToTop('#ar-widget-container');
            this.showHintWaitRender('.ar-filter-hint', '.ar-filter-hint-arrow', 'LEFT', -30, 'TOP', -60, 'homePageIconHint');
            this.showHintWaitRender('.ar-filter-hint', '.ar-filter-hint-text', 'LEFT', -230, 'TOP', -90, 'homePageIconHint');

            this.showHintWaitRender('.ar-full-hint', '.ar-full-hint-arrow', 'LEFT', -20, 'TOP', -60, 'homePageIconHint');
            this.showHintWaitRender('.ar-full-hint', '.ar-full-hint-text', 'LEFT', -100, 'TOP', -105, 'homePageIconHint');

            this.showHintWaitRender('.ar-full-close-hint', '.ar-full-close-hint-arrow', 'LEFT', -10, 'TOP', 70, 'homePageIconHint');
            this.showHintWaitRender('.ar-full-close-hint', '.ar-full-close-hint-text', 'LEFT', -45, 'TOP', 140, 'homePageIconHint');

            this.showHintWaitRender('.ar-close-hint', '.ar-close-hint-arrow', 'LEFT', 35, 'TOP', -20, 'homePageIconHint');
            this.showHintWaitRender('.ar-close-hint', '.ar-close-hint-text', 'LEFT', 100, 'TOP', -10, 'homePageIconHint');

            this.showHintWaitRender('.ar-start-hint', '.ar-start-hint-arrow', 'LEFT', 50, 'TOP', -40, 'homePageIconHint');
            this.showHintWaitRender('.ar-start-hint', '.ar-start-hint-text', 'LEFT', -5, 'TOP', -90, 'homePageIconHint');

            this.showHintWaitRender('.ar-end-hint', '.ar-end-hint-arrow', 'LEFT', 65, 'TOP', -40, 'homePageIconHint');
            this.showHintWaitRender('.ar-end-hint', '.ar-end-hint-text', 'LEFT', 5, 'TOP', -90, 'homePageIconHint');

            this.showHintWaitRender('.ar-locations-hint', '.ar-locations-hint-arrow', 'LEFT', 20, 'TOP', -20, 'homePageIconHint');
            this.showHintWaitRender('.ar-locations-hint', '.ar-locations-hint-text', 'LEFT', 80, 'TOP', -40, 'homePageIconHint');

            this.showHintWaitRender('.ar-reasons-hint', '.ar-reasons-hint-arrow', XALIGN.ANCHORRIGHT, -20, 'TOP', -20, 'homePageIconHint');
            this.showHintWaitRender('.ar-reasons-hint', '.ar-reasons-hint-text', XALIGN.ANCHORRIGHT, 50, 'TOP', -20, 'homePageIconHint');

            this.showHintWaitRender('.ar-lc-hint', '.ar-lc-hint-arrow', 'LEFT', 20, 'TOP', -35, 'homePageIconHint');
            this.showHintWaitRender('.ar-lc-hint', '.ar-lc-hint-text', 'LEFT', -5, 'TOP', -150, 'homePageIconHint');

            this.showHintWaitRender('.ar-date-hint', '.ar-date-hint-arrow', 'LEFT', 35, 'TOP', -30, 'homePageIconHint');
            this.showHintWaitRender('.ar-date-hint', '.ar-date-hint-text', 'LEFT', 57, 'TOP', -135, 'homePageIconHint');

            this.showHintWaitRender('.ar-status-hint', '.ar-status-hint-arrow', 'LEFT', 40, 'TOP', -30, 'homePageIconHint');
            this.showHintWaitRender('.ar-status-hint', '.ar-status-hint-text', 'LEFT', 70, 'TOP', -130, 'homePageIconHint');

            this.showHintWaitRender('.ar-error-hint', '.ar-error-hint-arrow', 'LEFT', 50, 'TOP', -30, 'homePageIconHint');
            this.showHintWaitRender('.ar-error-hint', '.ar-error-hint-text', 'LEFT', 112, 'TOP', -110, 'homePageIconHint');

            this.showHintWaitRender('.ar-reason-hint', '.ar-reason-hint-arrow', 'LEFT', 70, 'TOP', -20, 'homePageIconHint');
            this.showHintWaitRender('.ar-reason-hint', '.ar-reason-hint-text', 'LEFT', 125, 'TOP', -20, 'homePageIconHint');

            this.showHintWaitRender('.ar-graph-hint', '.ar-graph-hint-arrow', 'LEFT', 55, 'TOP', -5, 'homePageIconHint');
            this.showHintWaitRender('.ar-graph-hint', '.ar-graph-hint-text', 'LEFT', 120, 'TOP', 20, 'homePageIconHint');

            this.showHintWaitRender('.ar-paginator-hint', '.ar-paginator-hint-arrow', 'LEFT', 280, 'TOP', 0, 'homePageIconHint');
            this.showHintWaitRender('.ar-paginator-hint', '.ar-paginator-hint-text', 'LEFT', 340, 'TOP', 38, 'homePageIconHint');
        }

        if (this.isHomePageWidgetToggled && this.homePageSelectedWidget === WIDGET_PARAM.EVENT) {
            // events widget hints
            this.showHintWaitRender('.add-event-hint', '.add-event-hint-arrow', 'LEFT', -45, 'TOP', -60, 'homePageIconHint');
            this.showHintWaitRender('.add-event-hint', '.add-event-hint-text', 'LEFT', -95, 'TOP', -80, 'homePageIconHint');

            this.showHintWaitRender('.event-filters-hint', '.event-filters-hint-arrow', 'LEFT', -40, 'TOP', -50, 'homePageIconHint');
            this.showHintWaitRender('.event-filters-hint', '.event-filters-hint-text', 'LEFT', -95, 'TOP', -110, 'homePageIconHint');

            this.showHintWaitRender('.event-full-hint', '.event-full-hint-arrow', 'LEFT', 20, 'TOP', -40, 'homePageIconHint');
            this.showHintWaitRender('.event-full-hint', '.event-full-hint-text', 'LEFT', -25, 'TOP', -85, 'homePageIconHint');

            // this.showHintWaitRender('.event-full-close-hint', '.event-full-close-hint-arrow', 'LEFT', 30, 'TOP', -80, 'homePageIconHint');
            // this.showHintWaitRender('.event-full-close-hint', '.event-full-close-hint-text', 'LEFT', 75, 'TOP', -145, 'homePageIconHint');

            this.showHintWaitRender('.event-close-hint', '.event-close-hint-arrow', 'LEFT', 30, 'TOP', -10, 'homePageIconHint');
            this.showHintWaitRender('.event-close-hint', '.event-close-hint-text', 'LEFT', 85, 'TOP', 15, 'homePageIconHint');

            this.showHintWaitRender('.event-description-hint', '.event-description-hint-arrow', 'LEFT', 0, 'TOP', -60, 'homePageIconHint');
            this.showHintWaitRender('.event-description-hint', '.event-description-hint-text', 'LEFT', -10, 'TOP', -100, 'homePageIconHint');

            // this.showHintWaitRender('.event-type-hint', '.event-type-hint-arrow', 'LEFT', 30, 'TOP', -80, 'homePageIconHint');
            // this.showHintWaitRender('.event-type-hint', '.event-type-hint-text', 'LEFT', 75, 'TOP', -145, 'homePageIconHint');

            this.showHintWaitRender('.event-locations-hint', '.event-locations-hint-arrow', 'LEFT', -20, 'TOP', 5, 'homePageIconHint');
            this.showHintWaitRender('.event-locations-hint', '.event-locations-hint-text', 'LEFT', -95, 'TOP', 55, 'homePageIconHint');

            this.showHintWaitRender('.event-started-at-hint', '.event-started-at-hint-arrow', 'LEFT', -30, 'TOP', -45, 'homePageIconHint');
            this.showHintWaitRender('.event-started-at-hint', '.event-started-at-hint-text', 'LEFT', -105, 'TOP', -65, 'homePageIconHint');

            this.showHintWaitRender('.event-duration-hint', '.event-duration-hint-arrow', 'LEFT', -20, 'TOP', 15, 'homePageIconHint');
            this.showHintWaitRender('.event-duration-hint', '.event-duration-hint-text', 'LEFT', -65, 'TOP', 75, 'homePageIconHint');

            this.showHintWaitRender('.event-gis-hint', '.event-gis-hint-arrow', 'LEFT', -25, 'TOP', 25, 'homePageIconHint');
            this.showHintWaitRender('.event-gis-hint', '.event-gis-hint-text', 'LEFT', -75, 'TOP', 85, 'homePageIconHint');

            this.showHintWaitRender('.event-edit-hint', '.event-edit-hint-arrow', 'LEFT', 20, 'TOP', 10, 'homePageIconHint');
            this.showHintWaitRender('.event-edit-hint', '.event-edit-hint-text', 'LEFT', 60, 'TOP', 60, 'homePageIconHint');

            this.showHintWaitRender('.event-delete-hint', '.event-delete-hint-arrow', 'LEFT', 30, 'TOP', -10, 'homePageIconHint');
            this.showHintWaitRender('.event-delete-hint', '.event-delete-hint-text', 'LEFT', 90, 'TOP', 35, 'homePageIconHint');

            this.showHintWaitRender('.event-filter-locations-hint', '.event-filter-locations-hint-arrow', 'LEFT', 200, 'TOP', 0, 'homePageIconHint');
            this.showHintWaitRender('.event-filter-locations-hint', '.event-filter-locations-hint-text', 'LEFT', 255, 'TOP', 25, 'homePageIconHint');

            this.showHintWaitRender('.event-filter-date-hint', '.event-filter-date-hint-arrow', 'LEFT', 30, 'TOP', -20, 'homePageIconHint');
            this.showHintWaitRender('.event-filter-date-hint', '.event-filter-date-hint-text', 'LEFT', 90, 'TOP', -45, 'homePageIconHint');

            this.showHintWaitRender('.event-filter-type-hint', '.event-filter-type-hint-arrow', 'LEFT', 30, 'TOP', 5, 'homePageIconHint');
            this.showHintWaitRender('.event-filter-type-hint', '.event-filter-type-hint-text', 'LEFT', 85, 'TOP', 15, 'homePageIconHint');

            this.showHintWaitRender('.event-filter-duration-hint', '.event-filter-duration-hint-arrow', 'RIGHT', -70, 'TOP', 0, 'homePageIconHint');
            this.showHintWaitRender('.event-filter-duration-hint', '.event-filter-duration-hint-text', 'RIGHT', -300, 'TOP', 25, 'homePageIconHint');

            this.showHintWaitRender('.event-paginator-hint', '.event-paginator-hint-arrow', 'LEFT', 30, 'TOP', -10, 'homePageIconHint', 'vw');
            this.showHintWaitRender('.event-paginator-hint', '.event-paginator-hint-text', 'LEFT', 34, 'TOP', -30, 'homePageIconHint', 'vw');
        }

    }


    public eventWidgetExpanded() {
        this.showpageHintIcon('.add-event-hint', '.add-event-widget-hint-arrow', 'LEFT', -45, 'TOP', -50);
        this.showpageHintIcon('.add-event-hint', '.add-event-widget-hint-text', 'LEFT', -95, 'TOP', -75);

        this.showpageHintIcon('.event-filters-hint', '.event-filters-widget-hint-arrow', 'LEFT', -40, 'TOP', -50);
        this.showpageHintIcon('.event-filters-hint', '.event-filters-widget-hint-text', 'LEFT', -95, 'TOP', -110);

        this.showpageHintIcon('.event-full-hint', '.event-full-widget-hint-arrow', 'LEFT', 20, 'TOP', -40);
        this.showpageHintIcon('.event-full-hint', '.event-full-widget-hint-text', 'LEFT', -25, 'TOP', -85);

        this.showpageHintIcon('.event-full-close-hint', '.event-full-close-widget-hint-arrow', 'LEFT', 0, 'TOP', -60);
        this.showpageHintIcon('.event-full-close-hint', '.event-full-close-widget-hint-text', 'LEFT', 15, 'TOP', -145);

        this.showpageHintIcon('.event-close-hint', '.event-close-widget-hint-arrow', 'LEFT', -20, 'TOP', -60);
        this.showpageHintIcon('.event-close-hint', '.event-close-widget-hint-text', 'LEFT', -130, 'TOP', -125);

        this.showpageHintIcon('.event-description-hint', '.event-description-widget-hint-arrow', 'LEFT', 0, 'TOP', -60);
        this.showpageHintIcon('.event-description-hint', '.event-description-widget-hint-text', 'LEFT', -25, 'TOP', -100);

        this.showpageHintIcon('.event-type-hint', '.event-type-widget-hint-arrow', 'LEFT', 0, 'TOP', -65);
        this.showpageHintIcon('.event-type-hint', '.event-type-widget-hint-text', 'LEFT', -15, 'TOP', -90);

        this.showpageHintIcon('.event-locations-hint', '.event-locations-widget-hint-arrow', 'LEFT', -20, 'TOP', 5);
        this.showpageHintIcon('.event-locations-hint', '.event-locations-widget-hint-text', 'LEFT', -95, 'TOP', 55);

        this.showpageHintIcon('.event-started-at-hint', '.event-started-at-widget-hint-arrow', 'LEFT', -30, 'TOP', -45);
        this.showpageHintIcon('.event-started-at-hint', '.event-started-at-widget-hint-text', 'LEFT', -105, 'TOP', -65);

        this.showpageHintIcon('.event-duration-hint', '.event-duration-widget-hint-arrow', 'LEFT', -20, 'TOP', 15);
        this.showpageHintIcon('.event-duration-hint', '.event-duration-widget-hint-text', 'LEFT', -65, 'TOP', 75);

        this.showpageHintIcon('.event-gis-hint', '.event-gis-widget-hint-arrow', 'LEFT', -25, 'TOP', 25);
        this.showpageHintIcon('.event-gis-hint', '.event-gis-widget-hint-text', 'LEFT', -75, 'TOP', 85);

        this.showpageHintIcon('.event-edit-hint', '.event-edit-widget-hint-arrow', 'LEFT', -40, 'TOP', -40);
        this.showpageHintIcon('.event-edit-hint', '.event-edit-widget-hint-text', 'LEFT', -105, 'TOP', -35);

        this.showpageHintIcon('.event-delete-hint', '.event-delete-widget-hint-arrow', 'LEFT', 30, 'TOP', -10);
        this.showpageHintIcon('.event-delete-hint', '.event-delete-widget-hint-text', 'LEFT', 90, 'TOP', 35);

        this.showpageHintIcon('.event-filter-locations-hint', '.event-filter-locations-widget-hint-arrow', 'LEFT', 200, 'TOP', 0);
        this.showpageHintIcon('.event-filter-locations-hint', '.event-filter-locations-widget-hint-text', 'LEFT', 255, 'TOP', 25);

        this.showpageHintIcon('.event-filter-date-hint', '.event-filter-date-widget-hint-arrow', 'LEFT', 30, 'TOP', -20);
        this.showpageHintIcon('.event-filter-date-hint', '.event-filter-date-widget-hint-text', 'LEFT', 90, 'TOP', -30);

        this.showpageHintIcon('.event-filter-type-hint', '.event-filter-type-widget-hint-arrow', 'LEFT', 30, 'TOP', 5);
        this.showpageHintIcon('.event-filter-type-hint', '.event-filter-type-widget-hint-text', 'LEFT', 85, 'TOP', 15);

        this.showpageHintIcon('.event-filter-duration-hint', '.event-filter-duration-widget-hint-arrow', 'RIGHT', -70, 'TOP', 30);
        this.showpageHintIcon('.event-filter-duration-hint', '.event-filter-duration-widget-hint-text', 'RIGHT', -150, 'TOP', 90);

        this.showpageHintIcon('.mat-paginator-page-size', '.event-paginator-widget-hint-arrow', 'RIGHT', 10, 'TOP', -10);
        this.showpageHintIcon('.mat-paginator-page-size', '.event-paginator-widget-hint-text', 'RIGHT', -35, 'TOP', -30);
    }

    public pickCustomerEditorOverlay() {
        const currentTab = this.editCustomerTabIndex.getValue();

        switch (currentTab) {
            case 0: {
                this.selectedHintPageName.next('edit-cus-details');
                this.editCusDetails();
                break;
            }
            case 2: {
                this.selectedHintPageName.next('edit-cus-features');
                this.editCusFeatures();
                break;
            }
            case 3: {
                this.selectedHintPageName.next('edit-cus-users');
                this.editCusUsers();
                break;
            }
            case 4: {
                this.pickEditCustomerLocationOverlay();
                break;
            }
            case 5: {
                this.pickEditCustomerRainfallOverlay();
                break;
            }
            case 6: {
                this.pickEditCustomerGISoverlay();
                break;
            }
            case 7: {
                this.pickEditCustomerFTPoverlay();
                break;
            }
        }
    }

    private pickEditCustomerLocationOverlay() {
        if (this.locationFiltersOn.getValue()) {
            this.selectedHintPageName.next('edit-cus-locaitons-filters');
            this.editCusLocationsFilters();
        } else {
            this.selectedHintPageName.next('edit-cus-locations');
            this.editCusLocations();
        }
    }

    private pickEditCustomerRainfallOverlay() {
        switch (this.rainfallTabIndex.getValue()) {
            case 0: {
                this.selectedHintPageName.next('edit-cus-rainfall-dry-day');
                this.editCusDryDay();
                break;
            }
            case 1: {
                this.selectedHintPageName.next('edit-cus-rainfall-storm-event');
                this.editCusStormEvent();
                break;
            }
            case 2: {
                this.selectedHintPageName.next('edit-cus-rainfall-infiltration');
                this.editCusInfiltration();
                break;
            }
            case 3: {
                if (this.ddfTabIndex.getValue() === 0) {
                    this.selectedHintPageName.next('edit-cus-rainfall-ddf-data');
                    this.editCusDDFdata();
                } else {
                    this.selectedHintPageName.next('edit-cus-rainfall-ddf-graph');
                    this.editCusDDFgraph();
                }
                break;
            }
        }
    }

    private pickEditCustomerGISoverlay() {
        if (!this.gisDialogOpened.getValue()) {
            this.selectedHintPageName.next('edit-cus-gis');
            this.editCusGIS();

            return;
        }

        const authMethod = this.gisDialogAuthMethod.getValue() || 'basic';
        this.gisDialogAuthMethod.next(authMethod);

        if (authMethod === 'basic') {
            this.selectedHintPageName.next('edit-cus-gis-basic');
            this.editCusGISbasic();
        } else {
            this.selectedHintPageName.next('edit-cus-gis-custom');
            this.editCusGIScustom();
        }
    }

    private pickEditCustomerFTPoverlay() {
        if (this.ftpDialogOpened.getValue()) {
            this.selectedHintPageName.next('edit-cus-ftp-add');
            this.editCusFtpAdd();
        } else {
            this.selectedHintPageName.next('edit-cus-ftp');
            this.editCusFTP();
        }
    }

    public editCusDetails() {
        this.showpageHintIcon('.edit-cus-name-hint', '.edit-cus-name-hint-arrow', 'LEFT', 40, 'TOP', -65);
        this.showpageHintIcon('.edit-cus-name-hint', '.edit-cus-name-hint-text', 'LEFT', 30, 'TOP', -90);

        this.showpageHintIcon('.edit-cus-short-name-hint', '.edit-cus-short-name-hint-arrow', 'LEFT', 10, 'TOP', -65);
        this.showpageHintIcon('.edit-cus-short-name-hint', '.edit-cus-short-name-hint-text', 'LEFT', -20, 'TOP', -110);

        this.showpageHintIcon('.edit-cus-ttl-hint', '.edit-cus-ttl-hint-arrow', 'LEFT', 20, 'TOP', -65);
        this.showpageHintIcon('.edit-cus-ttl-hint', '.edit-cus-ttl-hint-text', 'LEFT', -20, 'TOP', -110);

        this.showpageHintIcon('.edit-cus-units-hint', '.edit-cus-units-hint-arrow', 'LEFT', 90, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-units-hint', '.edit-cus-units-hint-text', 'LEFT', 150, 'TOP', -20);

        this.showpageHintIcon('.edit-cus-date-format-hint', '.edit-cus-date-format-hint-arrow', 'LEFT', 90, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-date-format-hint', '.edit-cus-date-format-hint-text', 'LEFT', 150, 'TOP', -20);

        this.showpageHintIcon('.edit-cus-time-format-hint', '.edit-cus-time-format-hint-arrow', 'LEFT', 70, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-time-format-hint', '.edit-cus-time-format-hint-text', 'LEFT', 130, 'TOP', -20);

        this.showpageHintIcon('.edit-cus-timezone-hint', '.edit-cus-timezone-hint-arrow', 'LEFT', 90, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-timezone-hint', '.edit-cus-timezone-hint-text', 'LEFT', 150, 'TOP', -20);

        this.showpageHintIcon('.edit-cus-work-order-hint', '.edit-cus-work-order-hint-arrow', 'LEFT', 90, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-work-order-hint', '.edit-cus-work-order-hint-text', 'LEFT', 150, 'TOP', -20);

        this.showpageHintIcon('.edit-cus-idle-time-hint', '.edit-cus-idle-time-hint-arrow', 'LEFT', 50, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-idle-time-hint', '.edit-cus-idle-time-hint-text', 'LEFT', 110, 'TOP', -20);

        this.showpageHintIcon('.edit-cus-daylight-hint', '.edit-cus-daylight-hint-arrow', 'LEFT', 180, 'TOP', -30);
        this.showpageHintIcon('.edit-cus-daylight-hint', '.edit-cus-daylight-hint-text', 'LEFT', 240, 'TOP', -30);

        this.showpageHintIcon('.edit-cus-con-num-hint', '.edit-cus-con-num-hint-arrow', 'LEFT', 90, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-con-num-hint', '.edit-cus-con-num-hint-text', 'LEFT', 150, 'TOP', -20);

        this.showpageHintIcon('.edit-cus-subs-hint', '.edit-cus-subs-hint-arrow', 'LEFT', 90, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-subs-hint', '.edit-cus-subs-hint-text', 'LEFT', 150, 'TOP', -20);

        this.showpageHintIcon('.edit-cus-orig-start-hint', '.edit-cus-orig-start-hint-arrow', 'LEFT', 90, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-orig-start-hint', '.edit-cus-orig-start-hint-text', 'LEFT', 150, 'TOP', -20);

        this.showpageHintIcon('.edit-cus-curr-start-hint', '.edit-cus-curr-start-hint-arrow', 'LEFT', 90, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-curr-start-hint', '.edit-cus-curr-start-hint-text', 'LEFT', 150, 'TOP', -20);

        this.showpageHintIcon('.edit-cus-curr-end-hint', '.edit-cus-curr-end-hint-arrow', 'LEFT', 90, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-curr-end-hint', '.edit-cus-curr-end-hint-text', 'LEFT', 150, 'TOP', -20);

        this.showpageHintIcon('.edit-cus-da-hint', '.edit-cus-da-hint-arrow', 'LEFT', 90, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-da-hint', '.edit-cus-da-hint-text', 'LEFT', 150, 'TOP', -20);

        this.showpageHintIcon('.edit-cus-reg-man-hint', '.edit-cus-reg-man-hint-arrow', 'LEFT', 90, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-reg-man-hint', '.edit-cus-reg-man-hint-text', 'LEFT', 150, 'TOP', -20);

        this.showpageHintIcon('.edit-cus-sales-hint', '.edit-cus-sales-hint-arrow', 'LEFT', 90, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-sales-hint', '.edit-cus-sales-hint-text', 'LEFT', 150, 'TOP', -20);

        this.showpageHintIcon('.edit-cus-pm-hint', '.edit-cus-pm-hint-arrow', 'LEFT', 90, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-pm-hint', '.edit-cus-pm-hint-text', 'LEFT', 150, 'TOP', -20);

        this.showpageHintIcon('.edit-cus-inactive-on-hint', '.edit-cus-inactive-on-hint-arrow', 'LEFT', 90, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-inactive-on-hint', '.edit-cus-inactive-on-hint-text', 'LEFT', 150, 'TOP', -20);

        this.showpageHintIcon('.edit-cus-details-add-hint', '.edit-cus-details-add-hint-arrow', 'LEFT', 70, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-details-add-hint', '.edit-cus-details-add-hint-text', 'LEFT', 130, 'TOP', -20);

        this.showpageHintIcon('.edit-cus-save-hint', '.edit-cus-save-hint-arrow', 'LEFT', -50, 'TOP', -25);
        this.showpageHintIcon('.edit-cus-save-hint', '.edit-cus-save-hint-text', 'LEFT', -120, 'TOP', -40);

        this.showpageHintIcon('.edit-cus-rough-hint', '.edit-cus-rough-hint-arrow', 'LEFT', -60, 'TOP', 5);
        this.showpageHintIcon('.edit-cus-rough-hint', '.edit-cus-rough-hint-text', 'LEFT', -210, 'TOP', 30);
    }

    public editCusFeatures() {
        this.showpageHintIcon('.edit-cus-feats-hint', '.edit-cus-feats-hint-arrow', 'LEFT', 100, 'TOP', -55);
        this.showpageHintIcon('.edit-cus-feats-hint', '.edit-cus-feats-hint-text', 'LEFT', 70, 'TOP', -100);

        this.showpageHintIcon('.edit-cus-feats-apply-hint', '.edit-cus-feats-apply-hint-arrow', 'LEFT', -50, 'TOP', -25);
        this.showpageHintIcon('.edit-cus-feats-apply-hint', '.edit-cus-feats-apply-hint-text', 'LEFT', -180, 'TOP', -40);
    }

    public editCusUsers() {
        this.scrollToTop('html');
        this.showpageHintIcon('.edit-cus-users-search-hint', '.edit-cus-users-search-hint-arrow', 'LEFT', 90, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-users-search-hint', '.edit-cus-users-search-hint-text', 'LEFT', 150, 'TOP', -30);

        this.showpageHintIcon('.edit-cus-users-assigned-hint', '.edit-cus-users-assigned-hint-arrow', 'LEFT', -60, 'TOP', -55);
        this.showpageHintIcon('.edit-cus-users-assigned-hint', '.edit-cus-users-assigned-hint-text', 'LEFT', -180, 'TOP', -100);

        this.showpageHintIcon('.edit-cus-users-check-hint', '.edit-cus-users-check-hint-arrow', 'LEFT', -5, 'TOP', 20);
        this.showpageHintIcon('.edit-cus-users-check-hint', '.edit-cus-users-check-hint-text', 'LEFT', -40, 'TOP', 80);

        this.showpageHintIcon('.edit-cus-users-username-hint', '.edit-cus-users-username-hint-arrow', 'LEFT', 40, 'TOP', 40);
        this.showpageHintIcon('.edit-cus-users-username-hint', '.edit-cus-users-username-hint-text', 'LEFT', 50, 'TOP', 100);

        this.showpageHintIcon('.edit-cus-users-email-hint', '.edit-cus-users-email-hint-arrow', 'LEFT', 40, 'TOP', 40);
        this.showpageHintIcon('.edit-cus-users-email-hint', '.edit-cus-users-email-hint-text', 'LEFT', 50, 'TOP', 100);

        this.showpageHintIcon('.edit-cus-users-name-hint', '.edit-cus-users-name-hint-arrow', 'LEFT', 20, 'TOP', 40);
        this.showpageHintIcon('.edit-cus-users-name-hint', '.edit-cus-users-name-hint-text', 'LEFT', 10, 'TOP', 100);

        this.showpageHintIcon('.edit-cus-users-role-hint', '.edit-cus-users-role-hint-arrow', 'LEFT', 20, 'TOP', 40);
        this.showpageHintIcon('.edit-cus-users-role-hint', '.edit-cus-users-role-hint-text', 'LEFT', 20, 'TOP', 100);

        this.showpageHintIcon('.edit-cus-users-login-hint', '.edit-cus-users-login-hint-arrow', 'LEFT', 40, 'TOP', 40);
        this.showpageHintIcon('.edit-cus-users-login-hint', '.edit-cus-users-login-hint-text', 'LEFT', 50, 'TOP', 100);

        this.showpageHintIcon('.edit-cus-users-feats-hint', '.edit-cus-users-feats-hint-arrow', 'LEFT', 40, 'TOP', 40);
        this.showpageHintIcon('.edit-cus-users-feats-hint', '.edit-cus-users-feats-hint-text', 'LEFT', 50, 'TOP', 100);

        this.showpageHintIcon('.edit-cus-users-status-hint', '.edit-cus-users-status-hint-arrow', 'LEFT', 0, 'TOP', 35);
        this.showpageHintIcon('.edit-cus-users-status-hint', '.edit-cus-users-status-hint-text', 'LEFT', -40, 'TOP', 100);

        this.showpageHintIcon('.edit-cus-users-edit-hint', '.edit-cus-users-edit-hint-arrow', 'LEFT', -20, 'TOP', 20);
        this.showpageHintIcon('.edit-cus-users-edit-hint', '.edit-cus-users-edit-hint-text', 'LEFT', -60, 'TOP', 80);

        this.showpageHintIcon('.edit-cus-users-add-hint', '.edit-cus-users-add-hint-arrow', 'LEFT', 70, 'TOP', -10);
        this.showpageHintIcon('.edit-cus-users-add-hint', '.edit-cus-users-add-hint-text', 'LEFT', 130, 'TOP', -20);

        this.showpageHintIcon('.edit-cus-users-paginator-hint', '.edit-cus-users-paginator-hint-arrow', 'LEFT', -50, 'TOP', -10);
        this.showpageHintIcon('.edit-cus-users-paginator-hint', '.edit-cus-users-paginator-hint-text', 'LEFT', -120, 'TOP', -25);

        this.showpageHintIcon('.edit-cus-users-save-hint', '.edit-cus-users-save-hint-arrow', 'LEFT', -10, 'TOP', -40);
        this.showpageHintIcon('.edit-cus-users-save-hint', '.edit-cus-users-save-hint-text', 'LEFT', -150, 'TOP', -70);
    }

    public editCusLocations() {
        this.scrollToTop('html');
        this.showpageHintIcon('.edit-cus-locs-search-hint', '.edit-cus-locs-search-hint-arrow', 'LEFT', 90, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-locs-search-hint', '.edit-cus-locs-search-hint-text', 'LEFT', 150, 'TOP', -30);

        this.showpageHintIcon('.edit-cus-locs-filter-hint', '.edit-cus-locs-filter-hint-arrow', 'LEFT', -55, 'TOP', -30);
        this.showpageHintIcon('.edit-cus-locs-filter-hint', '.edit-cus-locs-filter-hint-text', 'LEFT', -200, 'TOP', -40);

        this.showpageHintIcon('.edit-cus-locs-name-hint', '.edit-cus-locs-name-hint-arrow', 'LEFT', 30, 'TOP', 50);
        this.showpageHintIcon('.edit-cus-locs-name-hint', '.edit-cus-locs-name-hint-text', 'LEFT', 30, 'TOP', 110);

        this.showpageHintIcon('.edit-cus-locs-series-hint', '.edit-cus-locs-series-hint-arrow', 'LEFT', 30, 'TOP', 50);
        this.showpageHintIcon('.edit-cus-locs-series-hint', '.edit-cus-locs-series-hint-text', 'LEFT', 30, 'TOP', 110);

        this.showpageHintIcon('.edit-cus-locs-serial-hint', '.edit-cus-locs-serial-hint-arrow', 'LEFT', 30, 'TOP', 50);
        this.showpageHintIcon('.edit-cus-locs-serial-hint', '.edit-cus-locs-serial-hint-text', 'LEFT', 10, 'TOP', 110);

        this.showpageHintIcon('.edit-cus-locs-desc-hint', '.edit-cus-locs-desc-hint-arrow', 'LEFT', 30, 'TOP', 50);
        this.showpageHintIcon('.edit-cus-locs-desc-hint', '.edit-cus-locs-desc-hint-text', 'LEFT', 30, 'TOP', 110);

        this.showpageHintIcon('.edit-cus-locs-sensors-hint', '.edit-cus-locs-sensors-hint-arrow', 'LEFT', 30, 'TOP', 50);
        this.showpageHintIcon('.edit-cus-locs-sensors-hint', '.edit-cus-locs-sensors-hint-text', 'LEFT', 30, 'TOP', 110);

        this.showpageHintIcon('.edit-cus-locs-group-hint', '.edit-cus-locs-group-hint-arrow', 'LEFT', 30, 'TOP', 50);
        this.showpageHintIcon('.edit-cus-locs-group-hint', '.edit-cus-locs-group-hint-text', 'LEFT', 30, 'TOP', 110);

        this.showpageHintIcon('.edit-cus-locs-status-hint', '.edit-cus-locs-status-hint-arrow', 'LEFT', 30, 'TOP', 50);
        this.showpageHintIcon('.edit-cus-locs-status-hint', '.edit-cus-locs-status-hint-text', 'LEFT', 0, 'TOP', 110);

        this.showpageHintIcon('.edit-cus-locs-activate-hint', '.edit-cus-locs-activate-hint-arrow', 'LEFT', 0, 'TOP', 50);
        this.showpageHintIcon('.edit-cus-locs-activate-hint', '.edit-cus-locs-activate-hint-text', 'LEFT', -30, 'TOP', 110);

        this.showpageHintIcon('.edit-cus-locs-composite-hint', '.edit-cus-locs-composite-hint-arrow', 'LEFT', 30, 'TOP', 50);
        this.showpageHintIcon('.edit-cus-locs-composite-hint', '.edit-cus-locs-composite-hint-text', 'LEFT', 20, 'TOP', 110);

        this.showpageHintIcon('.edit-cus-locs-wdo-hint', '.edit-cus-locs-wdo-hint-arrow', 'LEFT', 0, 'TOP', 45);
        this.showpageHintIcon('.edit-cus-locs-wdo-hint', '.edit-cus-locs-wdo-hint-text', 'LEFT', -30, 'TOP', 110);

        this.showpageHintIcon('.edit-cus-locs-edit-hint', '.edit-cus-locs-edit-hint-arrow', 'LEFT', 0, 'TOP', 25);
        this.showpageHintIcon('.edit-cus-locs-edit-hint', '.edit-cus-locs-edit-hint-text', 'LEFT', 0, 'TOP', 90);

        this.showpageHintIcon('.edit-cus-locs-add-hint', '.edit-cus-locs-add-hint-arrow', 'LEFT', 70, 'TOP', -15);
        this.showpageHintIcon('.edit-cus-locs-add-hint', '.edit-cus-locs-add-hint-text', 'LEFT', 130, 'TOP', -30);
    }

    public editCusLocationsFilters() {
        this.scrollToTop('html');
        this.updateLocationDashboardDetailHintOverlayHeight();
        this.showpageHintIcon('.edit-cus-filters-series-hint', '.edit-cus-filters-series-hint-arrow', 'LEFT', 90, 'TOP', -35);
        this.showpageHintIcon('.edit-cus-filters-series-hint', '.edit-cus-filters-series-hint-text', 'LEFT', 50, 'TOP', -80);

        this.showpageHintIcon('.edit-cus-filters-group-hint', '.edit-cus-filters-group-hint-arrow', 'LEFT', 90, 'TOP', -35);
        this.showpageHintIcon('.edit-cus-filters-group-hint', '.edit-cus-filters-group-hint-text', 'LEFT', 50, 'TOP', -80);

        this.showpageHintIcon('.edit-cus-filters-status-hint', '.edit-cus-filters-status-hint-arrow', 'LEFT', 90, 'TOP', -35);
        this.showpageHintIcon('.edit-cus-filters-status-hint', '.edit-cus-filters-status-hint-text', 'LEFT', 50, 'TOP', -80);

        this.showpageHintIcon('.edit-cus-filters-sensors-hint', '.edit-cus-filters-sensors-hint-arrow', 'LEFT', 90, 'TOP', -35);
        this.showpageHintIcon('.edit-cus-filters-sensors-hint', '.edit-cus-filters-sensors-hint-text', 'LEFT', 50, 'TOP', -80);

        this.showpageHintIcon('.edit-cus-filters-wdo-hint', '.edit-cus-filters-wdo-hint-arrow', 'LEFT', 90, 'TOP', 45);
        this.showpageHintIcon('.edit-cus-filters-wdo-hint', '.edit-cus-filters-wdo-hint-text', 'LEFT', 50, 'TOP', 110);

        this.showpageHintIcon('.edit-cus-filters-composite-hint', '.edit-cus-filters-composite-hint-arrow', 'LEFT', 90, 'TOP', 45);
        this.showpageHintIcon('.edit-cus-filters-composite-hint', '.edit-cus-filters-composite-hint-text', 'LEFT', 50, 'TOP', 110);
    }

    public editCusFTP() {
        this.showpageHintIcon('.edit-cus-ftp-table-hint', '.edit-cus-ftp-table-hint-arrow', 'LEFT', 140, 'TOP', -65);
        this.showpageHintIcon('.edit-cus-ftp-table-hint', '.edit-cus-ftp-table-hint-text', 'LEFT', 120, 'TOP', -110);

        this.showpageHintIcon('.edit-cus-ftp-add-hint', '.edit-cus-ftp-add-hint-arrow', 'LEFT', 100, 'TOP', -25);
        this.showpageHintIcon('.edit-cus-ftp-add-hint', '.edit-cus-ftp-add-hint-text', 'LEFT', 160, 'TOP', -40);
    }

    public editCusFtpAdd() {
        this.showHintWaitRender('.edit-cus-ftp-protocol-hint', '.edit-cus-ftp-protocol-hint-arrow', 'LEFT', 60, 'TOP', -15, 'edit-cus-ftp-add');
        this.showHintWaitRender('.edit-cus-ftp-protocol-hint', '.edit-cus-ftp-protocol-hint-text', 'LEFT', 120, 'TOP', -30, 'edit-cus-ftp-add');

        this.showHintWaitRender('.edit-cus-ftp-mode-hint', '.edit-cus-ftp-mode-hint-arrow', 'LEFT', 60, 'TOP', -15, 'edit-cus-ftp-add');
        this.showHintWaitRender('.edit-cus-ftp-mode-hint', '.edit-cus-ftp-mode-hint-text', 'LEFT', 120, 'TOP', -30, 'edit-cus-ftp-add');

        this.showHintWaitRender('.edit-cus-ftp-host-hint', '.edit-cus-ftp-host-hint-arrow', 'LEFT', 60, 'TOP', -15, 'edit-cus-ftp-add');
        this.showHintWaitRender('.edit-cus-ftp-host-hint', '.edit-cus-ftp-host-hint-text', 'LEFT', 120, 'TOP', -30, 'edit-cus-ftp-add');

        this.showHintWaitRender('.edit-cus-ftp-username-hint', '.edit-cus-ftp-username-hint-arrow', 'LEFT', 80, 'TOP', -15, 'edit-cus-ftp-add');
        this.showHintWaitRender('.edit-cus-ftp-username-hint', '.edit-cus-ftp-username-hint-text', 'LEFT', 140, 'TOP', -30, 'edit-cus-ftp-add');

        this.showHintWaitRender('.edit-cus-ftp-password-hint', '.edit-cus-ftp-password-hint-arrow', 'LEFT', 80, 'TOP', -15, 'edit-cus-ftp-add');
        this.showHintWaitRender('.edit-cus-ftp-password-hint', '.edit-cus-ftp-password-hint-text', 'LEFT', 140, 'TOP', -30, 'edit-cus-ftp-add');

        this.showHintWaitRender('.edit-cus-ftp-port-hint', '.edit-cus-ftp-port-hint-arrow', 'LEFT', 60, 'TOP', -15, 'edit-cus-ftp-add');
        this.showHintWaitRender('.edit-cus-ftp-port-hint', '.edit-cus-ftp-port-hint-text', 'LEFT', 120, 'TOP', -30, 'edit-cus-ftp-add');

        this.showHintWaitRender('.edit-cus-ftp-folder-hint', '.edit-cus-ftp-folder-hint-arrow', 'LEFT', 90, 'TOP', -15, 'edit-cus-ftp-add');
        this.showHintWaitRender('.edit-cus-ftp-folder-hint', '.edit-cus-ftp-folder-hint-text', 'LEFT', 150, 'TOP', -30, 'edit-cus-ftp-add');

        this.showHintWaitRender('.edit-cus-ftp-time-hint', '.edit-cus-ftp-time-hint-arrow', 'LEFT', 110, 'TOP', -35, 'edit-cus-ftp-add');
        this.showHintWaitRender('.edit-cus-ftp-time-hint', '.edit-cus-ftp-time-hint-text', 'LEFT', 170, 'TOP', -50, 'edit-cus-ftp-add');

        this.showHintWaitRender('.edit-cus-ftp-import-hint', '.edit-cus-ftp-import-hint-arrow', 'LEFT', 80, 'TOP', -15, 'edit-cus-ftp-add');
        this.showHintWaitRender('.edit-cus-ftp-import-hint', '.edit-cus-ftp-import-hint-text', 'LEFT', 140, 'TOP', -30, 'edit-cus-ftp-add');

        this.showHintWaitRender('.edit-cus-ftp-close-hint', '.edit-cus-ftp-close-hint-arrow', 'LEFT', -40, 'TOP', -10, 'edit-cus-ftp-add');
        this.showHintWaitRender('.edit-cus-ftp-close-hint', '.edit-cus-ftp-close-hint-text', 'LEFT', -120, 'TOP', -30, 'edit-cus-ftp-add');

        this.showHintWaitRender('.edit-cus-ftp-save-hint', '.edit-cus-ftp-save-hint-arrow', 'LEFT', 90, 'TOP', 0, 'edit-cus-ftp-add');
        this.showHintWaitRender('.edit-cus-ftp-save-hint', '.edit-cus-ftp-save-hint-text', 'LEFT', 150, 'TOP', 20, 'edit-cus-ftp-add');
    }

    public editCusGIS() {
        this.showpageHintIcon('.edit-cus-gis-edit-hint', '.edit-cus-gis-edit-hint-arrow', 'LEFT', 60, 'TOP', -10);
        this.showpageHintIcon('.edit-cus-gis-edit-hint', '.edit-cus-gis-edit-hint-text', 'LEFT', 120, 'TOP', 20);

        this.showpageHintIcon('.edit-cus-gis-add-hint', '.edit-cus-gis-add-hint-arrow', 'LEFT', 95, 'TOP', -5);
        this.showpageHintIcon('.edit-cus-gis-add-hint', '.edit-cus-gis-add-hint-text', 'LEFT', 155, 'TOP', 30);
    }

    public editCusGISbasic() {
        this.showHintWaitRender('.edit-cus-gis-type-hint', '.edit-cus-gis-type-hint-arrow', 'LEFT', 90, 'TOP', -15, 'edit-cus-gis-basic');
        this.showHintWaitRender('.edit-cus-gis-type-hint', '.edit-cus-gis-type-hint-text', 'LEFT', 150, 'TOP', -30, 'edit-cus-gis-basic');

        this.showHintWaitRender('.edit-cus-gis-mapping-hint', '.edit-cus-gis-mapping-hint-arrow', 'LEFT', 120, 'TOP', -15, 'edit-cus-gis-basic');
        this.showHintWaitRender('.edit-cus-gis-mapping-hint', '.edit-cus-gis-mapping-hint-text', 'LEFT', 180, 'TOP', -20, 'edit-cus-gis-basic');

        this.showHintWaitRender('.edit-cus-gis-auth-hint', '.edit-cus-gis-auth-hint-arrow', 'LEFT', -70, 'TOP', -15, 'edit-cus-gis-basic');
        this.showHintWaitRender('.edit-cus-gis-auth-hint', '.edit-cus-gis-auth-hint-text', 'LEFT', -175, 'TOP', -20, 'edit-cus-gis-basic');

        this.showHintWaitRender('.edit-cus-gis-username-hint', '.edit-cus-gis-username-hint-arrow', 'LEFT', 75, 'TOP', -15, 'edit-cus-gis-basic');
        this.showHintWaitRender('.edit-cus-gis-username-hint', '.edit-cus-gis-username-hint-text', 'LEFT', 130, 'TOP', -30, 'edit-cus-gis-basic');

        this.showHintWaitRender('.edit-cus-gis-password-hint', '.edit-cus-gis-password-hint-arrow', 'LEFT', -40, 'TOP', 25, 'edit-cus-gis-basic');
        this.showHintWaitRender('.edit-cus-gis-password-hint', '.edit-cus-gis-password-hint-text', 'LEFT', -120, 'TOP', 70, 'edit-cus-gis-basic');

        this.showHintWaitRender('.edit-cus-gis-token-hint', '.edit-cus-gis-token-hint-arrow', 'LEFT', -70, 'TOP', 5, 'edit-cus-gis-basic');
        this.showHintWaitRender('.edit-cus-gis-token-hint', '.edit-cus-gis-token-hint-text', 'LEFT', -220, 'TOP', 30, 'edit-cus-gis-basic');
    }

    public editCusGIScustom() {
        this.showHintWaitRender('.edit-cus-gis-type-hint', '.edit-cus-gis-type-hint-arrow-custom', 'LEFT', 90, 'TOP', -15, 'edit-cus-gis-custom');
        this.showHintWaitRender('.edit-cus-gis-type-hint', '.edit-cus-gis-type-hint-text-custom', 'LEFT', 150, 'TOP', -30, 'edit-cus-gis-custom');

        this.showHintWaitRender('.edit-cus-gis-mapping-hint', '.edit-cus-gis-mapping-hint-arrow-custom', 'LEFT', 120, 'TOP', -15, 'edit-cus-gis-custom');
        this.showHintWaitRender('.edit-cus-gis-mapping-hint', '.edit-cus-gis-mapping-hint-text-custom', 'LEFT', 180, 'TOP', -20, 'edit-cus-gis-custom');

        this.showHintWaitRender('.edit-cus-gis-auth-hint', '.edit-cus-gis-auth-hint-arrow-custom', 'LEFT', -70, 'TOP', -15, 'edit-cus-gis-custom');
        this.showHintWaitRender('.edit-cus-gis-auth-hint', '.edit-cus-gis-auth-hint-text-custom', 'LEFT', -175, 'TOP', -20, 'edit-cus-gis-custom');

        this.showHintWaitRender('.edit-cus-gis-params-hint', '.edit-cus-gis-params-hint-arrow-custom', 'LEFT', 30, 'TOP', 25, 'edit-cus-gis-custom');
        this.showHintWaitRender('.edit-cus-gis-params-hint', '.edit-cus-gis-params-hint-text-custom', 'LEFT', 80, 'TOP', 60, 'edit-cus-gis-custom');

        this.showHintWaitRender('.edit-cus-gis-token-hint', '.edit-cus-gis-token-hint-arrow-custom', 'LEFT', -70, 'TOP', 5, 'edit-cus-gis-custom');
        this.showHintWaitRender('.edit-cus-gis-token-hint', '.edit-cus-gis-token-hint-text-custom', 'LEFT', -220, 'TOP', 30, 'edit-cus-gis-custom');
    }

    public editCusDryDay() {
        this.scrollToTop('html');
        this.updateLocationDashboardDetailHintOverlayHeight();

        this.showHintWaitRender('.edit-cus-dry-tab-hint', '.edit-cus-dry-tab-hint-arrow', 'LEFT', 70, 'TOP', -35, 'edit-cus-rainfall-dry-day');
        this.showHintWaitRender('.edit-cus-dry-tab-hint', '.edit-cus-dry-tab-hint-text', 'LEFT', 130, 'TOP', -50, 'edit-cus-rainfall-dry-day');

        this.showHintWaitRender('.edit-cus-dry-one-hint', '.edit-cus-dry-one-hint-arrow', 'LEFT', 150, 'TOP', -10, 'edit-cus-rainfall-dry-day');
        this.showHintWaitRender('.edit-cus-dry-one-hint', '.edit-cus-dry-one-hint-text', 'LEFT', 210, 'TOP', -20, 'edit-cus-rainfall-dry-day');

        this.showHintWaitRender('.edit-cus-dry-three-hint', '.edit-cus-dry-three-hint-arrow', 'LEFT', 150, 'TOP', -10, 'edit-cus-rainfall-dry-day');
        this.showHintWaitRender('.edit-cus-dry-three-hint', '.edit-cus-dry-three-hint-text', 'LEFT', 210, 'TOP', -20, 'edit-cus-rainfall-dry-day');

        this.showHintWaitRender('.edit-cus-dry-five-hint', '.edit-cus-dry-five-hint-arrow', 'LEFT', 150, 'TOP', -10, 'edit-cus-rainfall-dry-day');
        this.showHintWaitRender('.edit-cus-dry-five-hint', '.edit-cus-dry-five-hint-text', 'LEFT', 210, 'TOP', -20, 'edit-cus-rainfall-dry-day');

        this.showHintWaitRender('.edit-cus-dry-min-hint', '.edit-cus-dry-min-hint-arrow', 'LEFT', 150, 'TOP', -10, 'edit-cus-rainfall-dry-day');
        this.showHintWaitRender('.edit-cus-dry-min-hint', '.edit-cus-dry-min-hint-text', 'LEFT', 210, 'TOP', -20, 'edit-cus-rainfall-dry-day');

        this.showHintWaitRender('.edit-cus-dry-max-hint', '.edit-cus-dry-max-hint-arrow', 'LEFT', 150, 'TOP', -10, 'edit-cus-rainfall-dry-day');
        this.showHintWaitRender('.edit-cus-dry-max-hint', '.edit-cus-dry-max-hint-text', 'LEFT', 210, 'TOP', -20, 'edit-cus-rainfall-dry-day');
    }

    public editCusStormEvent() {
        this.scrollToTop('html');
        this.updateLocationDashboardDetailHintOverlayHeight();

        this.showHintWaitRender('.edit-cus-storm-tab-hint', '.edit-cus-storm-tab-hint-arrow', 'LEFT', 60, 'TOP', -60, 'edit-cus-rainfall-storm-event');
        this.showHintWaitRender('.edit-cus-storm-tab-hint', '.edit-cus-storm-tab-hint-text', 'LEFT', 80, 'TOP', -100, 'edit-cus-rainfall-storm-event');

        this.showHintWaitRender('.edit-cus-storm-rain-hint', '.edit-cus-storm-rain-hint-arrow', 'LEFT', 150, 'TOP', -10, 'edit-cus-rainfall-storm-event');
        this.showHintWaitRender('.edit-cus-storm-rain-hint', '.edit-cus-storm-rain-hint-text', 'LEFT', 210, 'TOP', -20, 'edit-cus-rainfall-storm-event');

        this.showHintWaitRender('.edit-cus-storm-storm-hint', '.edit-cus-storm-storm-hint-arrow', 'LEFT', 150, 'TOP', -10, 'edit-cus-rainfall-storm-event');
        this.showHintWaitRender('.edit-cus-storm-storm-hint', '.edit-cus-storm-storm-hint-text', 'LEFT', 210, 'TOP', -20, 'edit-cus-rainfall-storm-event');
    }

    public editCusInfiltration() {
        this.scrollToTop('html');
        this.updateLocationDashboardDetailHintOverlayHeight();

        this.showHintWaitRender('.edit-cus-base-method-hint', '.edit-cus-base-method-hint-arrow', 'LEFT', 140, 'TOP', 5, 'edit-cus-rainfall-infiltration');
        this.showHintWaitRender('.edit-cus-base-method-hint', '.edit-cus-base-method-hint-text', 'LEFT', 200, 'TOP', 30, 'edit-cus-rainfall-infiltration');
    }

    public editCusDDFdata() {
        setTimeout(() => {
            this.scrollToTop('html');
            this.updateLocationDashboardDetailHintOverlayHeight();
        }, 500);

        this.showHintWaitRender('.edit-cus-ddf-data-tab-hint', '.edit-cus-ddf-data-tab-hint-arrow', 'LEFT', 200, 'TOP', -85, 'edit-cus-rainfall-ddf-data');
        this.showHintWaitRender('.edit-cus-ddf-data-tab-hint', '.edit-cus-ddf-data-tab-hint-text', 'LEFT', 50, 'TOP', -70, 'edit-cus-rainfall-ddf-data');

        this.showHintWaitRender('.edit-cus-ddf-data-upload-hint', '.edit-cus-ddf-data-upload-hint-arrow', 'LEFT', -40, 'TOP', 30, 'edit-cus-rainfall-ddf-data');
        this.showHintWaitRender('.edit-cus-ddf-data-upload-hint', '.edit-cus-ddf-data-upload-hint-text', 'LEFT', -190, 'TOP', 80, 'edit-cus-rainfall-ddf-data');

        this.showHintWaitRender('.edit-cus-ddf-data-table-hint', '.edit-cus-ddf-data-table-hint-arrow', 'LEFT', -60, 'TOP', 95, 'edit-cus-rainfall-ddf-data');
        this.showHintWaitRender('.edit-cus-ddf-data-table-hint', '.edit-cus-ddf-data-table-hint-text', 'LEFT', -200, 'TOP', 120, 'edit-cus-rainfall-ddf-data');

        this.showHintWaitRender('.edit-cus-ddf-data-add-hint', '.edit-cus-ddf-data-add-hint-arrow', 'LEFT', 20, 'TOP', -55, 'edit-cus-rainfall-ddf-data');
        this.showHintWaitRender('.edit-cus-ddf-data-add-hint', '.edit-cus-ddf-data-add-hint-text', 'LEFT', 10, 'TOP', -100, 'edit-cus-rainfall-ddf-data');

        this.showHintWaitRender('.edit-cus-ddf-data-delete-hint', '.edit-cus-ddf-data-delete-hint-arrow', 'LEFT', 60, 'TOP', -55, 'edit-cus-rainfall-ddf-data');
        this.showHintWaitRender('.edit-cus-ddf-data-delete-hint', '.edit-cus-ddf-data-delete-hint-text', 'LEFT', 60, 'TOP', -100, 'edit-cus-rainfall-ddf-data');

        this.showHintWaitRender('.edit-cus-ddf-data-graph-hint', '.edit-cus-ddf-data-graph-hint-arrow', 'LEFT', 65, 'TOP', -10, 'edit-cus-rainfall-ddf-data');
        this.showHintWaitRender('.edit-cus-ddf-data-graph-hint', '.edit-cus-ddf-data-graph-hint-text', 'LEFT', 125, 'TOP', 10, 'edit-cus-rainfall-ddf-data');
    }

    public editCusDDFgraph() {
        this.scrollToTop('html');
        this.updateLocationDashboardDetailHintOverlayHeight();

        this.showHintWaitRender('.edit-cus-ddf-graph-tab-hint', '.edit-cus-ddf-graph-tab-hint-arrow', 'LEFT', 60, 'TOP', -15, 'edit-cus-rainfall-ddf-graph');
        this.showHintWaitRender('.edit-cus-ddf-graph-tab-hint', '.edit-cus-ddf-graph-tab-hint-text', 'LEFT', 120, 'TOP', 20, 'edit-cus-rainfall-ddf-graph');

        this.showHintWaitRender('.edit-cus-ddf-graph-tooltip-hint', '.edit-cus-ddf-graph-tooltip-hint-arrow', 'LEFT', 30, 'TOP', -45, 'edit-cus-rainfall-ddf-graph');
        this.showHintWaitRender('.edit-cus-ddf-graph-tooltip-hint', '.edit-cus-ddf-graph-tooltip-hint-text', 'LEFT', 0, 'TOP', -90, 'edit-cus-rainfall-ddf-graph');

        this.showHintWaitRender('.edit-cus-ddf-graph-chart-hint', '.edit-cus-ddf-graph-chart-hint-arrow', 'LEFT', 60, 'TOP', -15, 'edit-cus-rainfall-ddf-graph');
        this.showHintWaitRender('.edit-cus-ddf-graph-chart-hint', '.edit-cus-ddf-graph-chart-hint-text', 'LEFT', -100, 'TOP', -30, 'edit-cus-rainfall-ddf-graph');

        this.showHintWaitRender('.highcharts-exporting-group', '.edit-cus-ddf-graph-options-hint-arrow', 'LEFT', -60, 'TOP', -30, 'edit-cus-rainfall-ddf-graph');
        this.showHintWaitRender('.highcharts-exporting-group', '.edit-cus-ddf-graph-options-hint-text', 'LEFT', -200, 'TOP', -50, 'edit-cus-rainfall-ddf-graph');
    }

    public customerEditor() {
        this.showpageHintIcon('.cus-filter-hint', '.cus-filter-hint-arrow', 'LEFT', -60, 'TOP', -30);
        this.showpageHintIcon('.cus-filter-hint', '.cus-filter-hint-text', 'LEFT', -200, 'TOP', -50);

        this.showpageHintIcon('.cus-filter-feature-hint', '.cus-filter-feature-hint-arrow', 'LEFT', 80, 'TOP', -20);
        this.showpageHintIcon('.cus-filter-feature-hint', '.cus-filter-feature-hint-text', 'LEFT', 140, 'TOP', -40);

        this.showpageHintIcon('.cus-filter-inactive-hint', '.cus-filter-inactive-hint-arrow', 'LEFT', 90, 'TOP', -20);
        this.showpageHintIcon('.cus-filter-inactive-hint', '.cus-filter-inactive-hint-text', 'LEFT', 150, 'TOP', -40);

        this.showpageHintIcon('.cus-search-hint', '.cus-search-hint-arrow', 'LEFT', 90, 'TOP', 0);
        this.showpageHintIcon('.cus-search-hint', '.cus-search-hint-text', 'LEFT', 150, 'TOP', 30);

        this.showpageHintIcon('.cus-name-hint', '.cus-name-hint-arrow', 'LEFT', 40, 'TOP', 35);
        this.showpageHintIcon('.cus-name-hint', '.cus-name-hint-text', 'LEFT', 0, 'TOP', 100);

        this.showpageHintIcon('.cus-locs-hint', '.cus-locs-hint-arrow', 'LEFT', 60, 'TOP', 35);
        this.showpageHintIcon('.cus-locs-hint', '.cus-locs-hint-text', 'LEFT', 20, 'TOP', 100);

        this.showpageHintIcon('.cus-inactive-locs-hint', '.cus-inactive-locs-hint-arrow', 'LEFT', 80, 'TOP', 20);
        this.showpageHintIcon('.cus-inactive-locs-hint', '.cus-inactive-locs-hint-text', 'LEFT', 90, 'TOP', 70);

        this.showpageHintIcon('.cus-feats-hint', '.cus-feats-hint-arrow', 'LEFT', 115, 'TOP', 5);
        this.showpageHintIcon('.cus-feats-hint', '.cus-feats-hint-text', 'LEFT', 170, 'TOP', 40);

        this.showpageHintIcon('.cus-inactive-hint', '.cus-inactive-hint-arrow', 'LEFT', -60, 'TOP', -30);
        this.showpageHintIcon('.cus-inactive-hint', '.cus-inactive-hint-text', 'LEFT', -150, 'TOP', -60);

        this.showpageHintIcon('.cus-edit-hint', '.cus-edit-hint-arrow', 'LEFT', 5, 'TOP', 65);
        this.showpageHintIcon('.cus-edit-hint', '.cus-edit-hint-text', 'LEFT', -120, 'TOP', 120);

        this.showpageHintIcon('.cus-add-hint', '.cus-add-hint-arrow', 'LEFT', 20, 'TOP', -70);
        this.showpageHintIcon('.cus-add-hint', '.cus-add-hint-text', 'LEFT', 30, 'TOP', -110);

        this.showpageHintIcon('.cus-paginator-hint', '.cus-paginator-hint-arrow', 'LEFT', -60, 'TOP', -10);
        this.showpageHintIcon('.cus-paginator-hint', '.cus-paginator-hint-text', 'RIGHT', -50, 'TOP', -30);
    }

    public autoReviewHints() {
        this.scrollToTop('#ar-widget-container');
        this.showpageHintIcon('.ar-filter-hint', '.ar-filter-hint-arrow', 'LEFT', -60, 'TOP', -30);
        this.showpageHintIcon('.ar-filter-hint', '.ar-filter-hint-text', 'LEFT', -150, 'TOP', -60);

        this.showpageHintIcon('.ar-full-hint', '.ar-full-hint-arrow', 'LEFT', -50, 'TOP', -5);
        this.showpageHintIcon('.ar-full-hint', '.ar-full-hint-text', 'LEFT', -185, 'TOP', 50);

        this.showpageHintIcon('.ar-full-close-hint', '.ar-full-close-hint-arrow', 'LEFT', -40, 'TOP', -50);
        this.showpageHintIcon('.ar-full-close-hint', '.ar-full-close-hint-text', 'LEFT', -100, 'TOP', -120);

        this.showpageHintIcon('.ar-close-hint', '.ar-close-hint-arrow', 'LEFT', -40, 'TOP', -50);
        this.showpageHintIcon('.ar-close-hint', '.ar-close-hint-text', 'LEFT', -155, 'TOP', -120);

        this.showpageHintIcon('.ar-start-hint', '.ar-start-hint-arrow', 'LEFT', 90, 'TOP', -20);
        this.showpageHintIcon('.ar-start-hint', '.ar-start-hint-text', 'LEFT', -5, 'TOP', -60);

        this.showpageHintIcon('.ar-end-hint', '.ar-end-hint-arrow', 'LEFT', 50, 'TOP', -20);
        this.showpageHintIcon('.ar-end-hint', '.ar-end-hint-text', 'LEFT', 105, 'TOP', -30);

        this.showpageHintIcon('.ar-locations-hint', '.ar-locations-hint-arrow', 'LEFT', 40, 'TOP', 0);
        this.showpageHintIcon('.ar-locations-hint', '.ar-locations-hint-text', 'LEFT', 105, 'TOP', 20);

        this.showpageHintIcon('.ar-reasons-hint', '.ar-reasons-hint-arrow', 'LEFT', 130, 'TOP', 10);
        this.showpageHintIcon('.ar-reasons-hint', '.ar-reasons-hint-text', 'LEFT', 180, 'TOP', 40);

        this.showpageHintIcon('.ar-lc-hint', '.ar-lc-hint-arrow', 'LEFT', 30, 'TOP', 40);
        this.showpageHintIcon('.ar-lc-hint', '.ar-lc-hint-text', 'LEFT', 15, 'TOP', 110);

        this.showpageHintIcon('.ar-date-hint', '.ar-date-hint-arrow', 'LEFT', -15, 'TOP', -40);
        this.showpageHintIcon('.ar-date-hint', '.ar-date-hint-text', 'LEFT', -95, 'TOP', -110);

        this.showpageHintIcon('.ar-status-hint', '.ar-status-hint-arrow', 'LEFT', 40, 'TOP', 30);
        this.showpageHintIcon('.ar-status-hint', '.ar-status-hint-text', 'LEFT', 45, 'TOP', 100);

        this.showpageHintIcon('.ar-error-hint', '.ar-error-hint-arrow', 'LEFT', 45, 'TOP', 20);
        this.showpageHintIcon('.ar-error-hint', '.ar-error-hint-text', 'LEFT', 85, 'TOP', 65);

        this.showpageHintIcon('.ar-reason-hint', '.ar-reason-hint-arrow', 'LEFT', 65, 'TOP', -30);
        this.showpageHintIcon('.ar-reason-hint', '.ar-reason-hint-text', 'LEFT', 120, 'TOP', -35);

        this.showpageHintIcon('.ar-graph-hint', '.ar-graph-hint-arrow', 'LEFT', -10, 'TOP', 30);
        this.showpageHintIcon('.ar-graph-hint', '.ar-graph-hint-text', 'LEFT', -100, 'TOP', 90);

        this.showpageHintIcon('.ar-paginator-hint', '.ar-paginator-hint-arrow', 'LEFT', 40, 'TOP', -60);
        this.showpageHintIcon('.ar-paginator-hint', '.ar-paginator-hint-text', 'LEFT', -105, 'TOP', -90);
    }

    public showLayersOverlayHomepage() {
        this.showHintWaitRender('.more-options-hint', '.more-options-menu-hint', 'LEFT', 60, 'TOP', 20, 'homePageSecondTab');

        this.showHintWaitRender('.layers-hint', '.layers-hint-arrow', 'RIGHT', -50, 'TOP', -60, 'homePageSecondTab');
        this.showHintWaitRender('.layers-hint', '.layers-hint-text', 'LEFT', 10, 'TOP', -130, 'homePageSecondTab');

        this.showHintWaitRender('.basemap-hint', '.basemap-hint-arrow', 'RIGHT', -170, 'TOP', 0, 'homePageSecondTab');
        this.showHintWaitRender('.basemap-hint', '.basemap-hint-text', 'RIGHT', -400, 'TOP', 5, 'homePageSecondTab');

        this.showHintWaitRender('.add-monitor-hint', '.add-monitor-hint-arrow', 'LEFT', 40, 'TOP', 25, 'homePageSecondTab');
        this.showHintWaitRender('.add-monitor-hint', '.add-monitor-hint-text', 'LEFT', 80, 'TOP', 45, 'homePageSecondTab');

        this.showHintWaitRender('.monitor-hint', '.monitor-hint-arrow', 'LEFT', 95, 'TOP', 0, 'homePageSecondTab');
        this.showHintWaitRender('.monitor-hint', '.monitor-hint-text', 'LEFT', 150, 'TOP', 15, 'homePageSecondTab');

        this.showHintWaitRender('.zoom-in-hint', '.zoom-in-hint-arrow', 'LEFT', 205, 'TOP', 20, 'homePageSecondTab');
        this.showHintWaitRender('.zoom-in-hint', '.zoom-in-hint-text', 'LEFT', 245, 'TOP', 75, 'homePageSecondTab');

        this.showHintWaitRender('.transparency-hint', '.transparency-hint-arrow', 'LEFT', 130, 'TOP', 60, 'homePageSecondTab');
        this.showHintWaitRender('.transparency-hint', '.transparency-hint-text', 'LEFT', 150, 'TOP', 135, 'homePageSecondTab');

        this.showHintWaitRender('.feat-select-hint', '.feat-select-hint-arrow', 'LEFT', 5, 'TOP', 45, 'homePageSecondTab');
        this.showHintWaitRender('.feat-select-hint', '.feat-select-hint-text', 'LEFT', 10, 'TOP', 110, 'homePageSecondTab');

        this.showHintWaitRender('.show-legend-hint', '.show-legend-hint-arrow', 'LEFT', -20, 'TOP', 40, 'homePageSecondTab');
        this.showHintWaitRender('.show-legend-hint', '.show-legend-hint-text', 'LEFT', -40, 'TOP', 105, 'homePageSecondTab');
    }

    public showToolsOverlayHomepage() {
        this.showHintWaitRender('.selection-menu', '.selection-menu-mock', 'LEFT', 45, 'TOP', 80, 'homePageThirdTab');
        this.showHintWaitRender('.measure-menu', '.measure-menu-mock', 'LEFT', 240, 'TOP', 50, 'homePageThirdTab');

        this.showHintWaitRender('.tools-hint', '.tools-hint-arrow', 'LEFT', 20, 'TOP', -70, 'homePageThirdTab');
        this.showHintWaitRender('.tools-hint', '.tools-hint-text', 'LEFT', -40, 'TOP', -155, 'homePageThirdTab');

        this.showpageHintIcon('.search-loc-hint', '.search-loc-hint-arrow', 'LEFT', 110, 'TOP', 40);
        this.showpageHintIcon('.search-loc-hint', '.search-loc-hint-text', 'LEFT', 40, 'TOP', 100);

        this.showpageHintIcon('.map-btn-hint', '.map-btn-hint-arrow', 'LEFT', 30, 'TOP', -80);
        this.showpageHintIcon('.map-btn-hint', '.map-btn-hint-text', 'LEFT', 75, 'TOP', -145);

        this.showHintWaitRender('.measure-area-hint', '.measure-area-hint-arrow', 'LEFT', 215, 'TOP', -10, 'homePageThirdTab');
        this.showHintWaitRender('.measure-area-hint', '.measure-area-hint-text', 'LEFT', 265, 'TOP', -65, 'homePageThirdTab');

        this.showHintWaitRender('.measure-length-hint', '.measure-length-hint-arrow', 'LEFT', 215, 'TOP', 10, 'homePageThirdTab');
        this.showHintWaitRender('.measure-length-hint', '.measure-length-hint-text', 'LEFT', 270, 'TOP', 20, 'homePageThirdTab');

        this.showHintWaitRender('.measure-clear-hint', '.measure-clear-hint-arrow', 'LEFT', 200, 'TOP', 50, 'homePageThirdTab');
        this.showHintWaitRender('.measure-clear-hint', '.measure-clear-hint-text', 'LEFT', 230, 'TOP', 110, 'homePageThirdTab');

        this.showHintWaitRender('.clear-tool-selection-hint', '.clear-tool-selection-hint-arrow', 'LEFT', 5, 'TOP', -50, 'homePageThirdTab');
        this.showHintWaitRender('.clear-tool-selection-hint', '.clear-tool-selection-hint-text', 'LEFT', -20, 'TOP', -120, 'homePageThirdTab');

        this.showHintWaitRender('.select-feat-hint', '.select-feat-hint-arrow', 'LEFT', 150, 'TOP', -70, 'homePageThirdTab');
        this.showHintWaitRender('.select-feat-hint', '.select-feat-hint-text', 'LEFT', 90, 'TOP', -190, 'homePageThirdTab');

        this.showHintWaitRender('.select-poly-hint', '.select-poly-hint-arrow', 'LEFT', 200, 'TOP', 15, 'homePageThirdTab');
        this.showHintWaitRender('.select-poly-hint', '.select-poly-hint-text', 'LEFT', 195, 'TOP', 80, 'homePageThirdTab');

        this.showHintWaitRender('.select-radius-hint', '.select-radius-hint-arrow', 'LEFT', 30, 'TOP', 60, 'homePageThirdTab');
        this.showHintWaitRender('.select-radius-hint', '.select-radius-hint-text', 'LEFT', -10, 'TOP', 120, 'homePageThirdTab');

        this.showHintWithoutCalc(
            '.map-overview-hint',
            '.map-overview-hint-arrow',
            'LEFT',
            31,
            'BOTTOM',
            50,
            true,
            'vw',
        );
        this.showHintWithoutCalc(
            '.map-overview-hint',
            '.map-overview-hint-text',
            'LEFT',
            32,
            'BOTTOM',
            130,
            true,
            'vw',
        );

        this.showHintWithoutCalc('.map-scale-hint', '.map-scale-hint-arrow', 'RIGHT', 140, 'BOTTOM', 70, true);
        this.showHintWithoutCalc('.map-scale-hint', '.map-scale-hint-text', 'RIGHT', 30, 'BOTTOM', 140, true);
    }

    /**
     * show Scheduling Dashboard hint
     */
    public scheduleCollectHint() {
        this.angularDocument.querySelector('.schedule-dashboard-hint-overlay').classList.remove('hide');

        this.showHintWaitRender('.schedule-title-hint', '.schedule-title-hint-arrow', 'RIGHT', -235, 'TOP', -30, 'scheduleDashboardHint');
        this.showHintWaitRender('.schedule-title-hint', '.schedule-title-hint-text', 'LEFT', 250, 'TOP', -40, 'scheduleDashboardHint');

        this.showHintWaitRender('.add-schedule-hint', '.add-schedule-hint-arrow', 'RIGHT', 25, 'TOP', -30, 'scheduleDashboardHint');
        this.showHintWaitRender('.add-schedule-hint', '.add-schedule-hint-text', 'RIGHT', 85, 'TOP', -50, 'scheduleDashboardHint');

        this.showHintWaitRender('.filter-hint', '.filter-hint-arrow', 'RIGHT', -20, 'TOP', -80, 'scheduleDashboardHint');
        this.showHintWaitRender('.filter-hint', '.filter-hint-text', 'RIGHT', -40, 'TOP', -130, 'scheduleDashboardHint');

        this.showHintWaitRender('.name-filter-hint', '.name-filter-hint-arrow', 'RIGHT', -150, 'TOP', 70, 'scheduleDashboardHint');
        this.showHintWaitRender('.name-filter-hint', '.name-filter-hint-text', 'RIGHT', -270, 'TOP', 140, 'scheduleDashboardHint');

        this.showHintWaitRender('.week-days-hint', '.week-days-hint-arrow', 'RIGHT', -125, 'TOP', 70, 'scheduleDashboardHint');
        this.showHintWaitRender('.week-days-hint', '.week-days-hint-text', 'RIGHT', -220, 'TOP', 140, 'scheduleDashboardHint');

        this.showHintWaitRender('.frequency-hint', '.frequency-hint-arrow', 'RIGHT', -80, 'TOP', 70, 'scheduleDashboardHint');
        this.showHintWaitRender('.frequency-hint', '.frequency-hint-text', 'RIGHT', -150, 'TOP', 140, 'scheduleDashboardHint');

        this.showHintWaitRender('.edit-schedule-hint', '.edit-schedule-hint-arrow', 'RIGHT', 25, 'TOP', 0, 'scheduleDashboardHint');
        this.showHintWaitRender('.edit-schedule-hint', '.edit-schedule-hint-text', 'RIGHT', 80, 'TOP', 20, 'scheduleDashboardHint');

        this.showHintWaitRender('.delete-hint', '.delete-hint-arrow', 'RIGHT', -20, 'TOP', 50, 'scheduleDashboardHint');
        this.showHintWaitRender('.delete-hint', '.delete-hint-text', 'RIGHT', -55, 'TOP', 110, 'scheduleDashboardHint');
    }

    public blockEditorDialogHint() {
        this.showHintWaitRender('.block-editor-dialog-header-hint', '.block-editor-dialog-header-hint-arrow', 'RIGHT', 0, 'TOP', -10, 'blockEditorDialogHint');
        this.showHintWaitRender('.block-editor-dialog-header-hint', '.block-editor-dialog-header-hint-text', 'RIGHT', -20, 'TOP', -50, 'blockEditorDialogHint');

        this.showHintWaitRender('.block-editor-dialog-date-range-hint', '.block-editor-dialog-date-range-hint-arrow', 'RIGHT', 0, 'TOP', 5, 'blockEditorDialogHint');
        this.showHintWaitRender('.block-editor-dialog-date-range-hint', '.block-editor-dialog-date-range-hint-text', 'RIGHT', 30, 'TOP', 45, 'blockEditorDialogHint');

        this.showHintWaitRender('.block-editor-dialog-condition-location-hint', '.block-editor-dialog-condition-location-hint-arrow', 'RIGHT', -115, 'TOP', -55, 'blockEditorDialogHint');
        this.showHintWaitRender('.block-editor-dialog-condition-location-hint', '.block-editor-dialog-condition-location-hint-text', 'LEFT', 10, 'TOP', -70, 'blockEditorDialogHint');

        this.showHintWaitRender('.block-editor-dialog-condition-entity-hint', '.block-editor-dialog-condition-entity-hint-arrow', 'RIGHT', -115, 'TOP', -55, 'blockEditorDialogHint');
        this.showHintWaitRender('.block-editor-dialog-condition-entity-hint', '.block-editor-dialog-condition-entity-hint-text', 'LEFT', 40, 'TOP', -90, 'blockEditorDialogHint');

        this.showHintWaitRender('.block-editor-dialog-condition-operator-hint', '.block-editor-dialog-condition-operator-hint-arrow', 'RIGHT', -85, 'TOP', -55, 'blockEditorDialogHint');
        this.showHintWaitRender('.block-editor-dialog-condition-operator-hint', '.block-editor-dialog-condition-operator-hint-text', 'LEFT', 20, 'TOP', -80, 'blockEditorDialogHint');

        this.showHintWaitRender('.block-editor-dialog-condition-value-hint', '.block-editor-dialog-condition-value-hint-arrow', 'RIGHT', -115, 'TOP', -55, 'blockEditorDialogHint');
        this.showHintWaitRender('.block-editor-dialog-condition-value-hint', '.block-editor-dialog-condition-value-hint-text', 'LEFT', 45, 'TOP', -65, 'blockEditorDialogHint');

        this.showHintWaitRender('.block-editor-dialog-entity-hint', '.block-editor-dialog-entity-hint-arrow', 'RIGHT', -25, 'TOP', -55, 'blockEditorDialogHint');
        this.showHintWaitRender('.block-editor-dialog-entity-hint', '.block-editor-dialog-entity-hint-text', 'LEFT', -150, 'TOP', -55, 'blockEditorDialogHint');

        this.showHintWaitRender('.block-editor-dialog-action-hint', '.block-editor-dialog-action-hint-arrow', 'RIGHT', -115, 'TOP', -55, 'blockEditorDialogHint');
        this.showHintWaitRender('.block-editor-dialog-action-hint', '.block-editor-dialog-action-hint-text', 'LEFT', 60, 'TOP', -65, 'blockEditorDialogHint');

        this.showHintWaitRender('.block-editor-dialog-valueentity-hint', '.block-editor-dialog-valueentity-hint-arrow', 'RIGHT', -50, 'TOP', -55, 'blockEditorDialogHint');
        this.showHintWaitRender('.block-editor-dialog-valueentity-hint', '.block-editor-dialog-valueentity-hint-text', 'LEFT', -100, 'TOP', -60, 'blockEditorDialogHint');

        this.showHintWaitRender('.block-editor-dialog-entityvalue-hint', '.block-editor-dialog-entityvalue-hint-arrow', 'LEFT', 0, 'TOP', 50, 'blockEditorDialogHint');
        this.showHintWaitRender('.block-editor-dialog-entityvalue-hint', '.block-editor-dialog-entityvalue-hint-text', 'LEFT', -210, 'TOP', 70, 'blockEditorDialogHint');

        this.showHintWaitRender('.block-editor-dialog-add-hint', '.block-editor-dialog-add-hint-arrow', 'LEFT', 110, 'TOP', -30, 'blockEditorDialogHint');
        this.showHintWaitRender('.block-editor-dialog-add-hint', '.block-editor-dialog-add-hint-text', 'LEFT', 170, 'TOP', -50, 'blockEditorDialogHint');

        this.showHintWaitRender('.block-editor-dialog-apply-hint', '.block-editor-dialog-apply-hint-arrow', 'LEFT', 80, 'TOP', -30, 'blockEditorDialogHint');
        this.showHintWaitRender('.block-editor-dialog-apply-hint', '.block-editor-dialog-apply-hint-text', 'LEFT', 140, 'TOP', -50, 'blockEditorDialogHint');

        this.showHintWaitRender('.block-editor-dialog-close-hint', '.block-editor-dialog-close-hint-arrow', 'LEFT', -35, 'TOP', 30, 'blockEditorDialogHint');
        this.showHintWaitRender('.block-editor-dialog-close-hint', '.block-editor-dialog-close-hint-text', 'LEFT', -195, 'TOP', 70, 'blockEditorDialogHint');
    }

    public scheduleDialogHint() {
        this.angularDocument.querySelector('.schedule-dashboard-hint-overlay').classList.add('hide');

        this.showHintWaitRender('.dialog-name-hint', '.schedule-dialog-name-hint-arrow', 'LEFT', 200, 'TOP', -65, 'scheduleDialogHint');
        this.showHintWaitRender('.dialog-name-hint', '.schedule-dialog-name-hint-text', 'LEFT', 160, 'TOP', -115, 'scheduleDialogHint');

        this.showHintWaitRender('.dialog-search-hint', '.schedule-dialog-search-hint-arrow', 'LEFT', 300, 'TOP', -10, 'scheduleDialogHint');
        this.showHintWaitRender('.dialog-search-hint', '.schedule-dialog-search-hint-text', 'LEFT', 360, 'TOP', -30, 'scheduleDialogHint');

        this.showHintWaitRender(
            '.dialog-locations-hint',
            '.schedule-dialog-locations-hint-arrow',
            'RIGHT',
            40,
            'TOP',
            15,
            'scheduleDialogHint');
        this.showHintWaitRender('.dialog-locations-hint', '.schedule-dialog-locations-hint-text', 'RIGHT', 90, 'TOP', -30, 'scheduleDialogHint');

        this.showHintWaitRender('.dialog-days-hint', '.schedule-dialog-days-hint-arrow', 'RIGHT', 40, 'TOP', -10, 'scheduleDialogHint');
        this.showHintWaitRender('.dialog-days-hint', '.schedule-dialog-days-hint-text', 'RIGHT', 90, 'TOP', -30, 'scheduleDialogHint');

        this.showHintWaitRender('.dialog-collect-hint', '.schedule-dialog-collect-hint-arrow', 'RIGHT', 40, 'TOP', 5, 'scheduleDialogHint');
        this.showHintWaitRender('.dialog-collect-hint', '.schedule-dialog-collect-hint-text', 'RIGHT', 90, 'TOP', 40, 'scheduleDialogHint');

        this.showHintWaitRender(
            '.dialog-frequency-hint',
            '.schedule-dialog-frequency-hint-arrow',
            'LEFT',
            295,
            'TOP',
            -20,
            'scheduleDialogHint');
        this.showHintWaitRender(
            '.dialog-frequency-hint',
            '.schedule-dialog-frequency-hint-text',
            'LEFT',
            360,
            'TOP',
            -120,
            'scheduleDialogHint');

        this.showHintWaitRender('.dialog-event-hint', '.schedule-dialog-event-hint-arrow', 'RIGHT', 30, 'TOP', 15, 'scheduleDialogHint');
        this.showHintWaitRender('.dialog-event-hint', '.schedule-dialog-event-hint-text', 'RIGHT', 80, 'TOP', 50, 'scheduleDialogHint');

        this.showHintWaitRender('.dialog-cancel-hint', '.schedule-dialog-cancel-hint-arrow', 'RIGHT', 20, 'TOP', -25, 'scheduleDialogHint');
        this.showHintWaitRender('.dialog-cancel-hint', '.schedule-dialog-cancel-hint-text', 'RIGHT', 70, 'TOP', -25, 'scheduleDialogHint');

        this.showHintWaitRender('.dialog-save-hint', '.schedule-dialog-save-hint-arrow', 'LEFT', 85, 'TOP', -20, 'scheduleDialogHint');
        this.showHintWaitRender('.dialog-save-hint', '.schedule-dialog-save-hint-text', 'LEFT', 150, 'TOP', -25, 'scheduleDialogHint');
    }

    public dailyDialogHint() {
        this.showHintWaitRender('.daily-type-hint', '.daily-type-hint-arrow', 'RIGHT', 35, 'TOP', -30, 'dailyDialogHint');
        this.showHintWaitRender('.daily-type-hint', '.daily-type-hint-text', 'RIGHT', 70, 'TOP', -50, 'dailyDialogHint');

        this.showHintWaitRender(
            '.daily-dialog-locations-hint',
            '.daily-dialog-locations-hint-arrow',
            'RIGHT',
            40,
            'TOP',
            15,
            'dailyDialogHint');
        this.showHintWaitRender(
            '.daily-dialog-locations-hint',
            '.daily-dialog-locations-hint-text',
            'RIGHT',
            80,
            'TOP',
            40,
            'dailyDialogHint');

        this.showHintWaitRender('.daily-dialog-entity-hint', '.daily-dialog-entity-hint-arrow', 'RIGHT', 40, 'TOP', 15, 'dailyDialogHint');
        this.showHintWaitRender('.daily-dialog-entity-hint', '.daily-dialog-entity-hint-text', 'RIGHT', 90, 'TOP', 55, 'dailyDialogHint');

        this.showHintWaitRender('.daily-dialog-date-hint', '.daily-dialog-date-hint-arrow', 'LEFT', 365, 'TOP', -15, 'dailyDialogHint');
        this.showHintWaitRender('.daily-dialog-date-hint', '.daily-dialog-date-hint-text', 'LEFT', 430, 'TOP', -35, 'dailyDialogHint');

        this.showHintWaitRender('.daily-dialog-cancel-hint', '.daily-dialog-cancel-hint-arrow', 'RIGHT', 5, 'TOP', 35, 'dailyDialogHint');
        this.showHintWaitRender('.daily-dialog-cancel-hint', '.daily-dialog-cancel-hint-text', 'RIGHT', 30, 'TOP', 70, 'dailyDialogHint');

        this.showHintWaitRender('.daily-dialog-export-hint', '.daily-dialog-export-hint-arrow', 'LEFT', 70, 'TOP', 35, 'dailyDialogHint');
        this.showHintWaitRender('.daily-dialog-export-hint', '.daily-dialog-export-hint-text', 'LEFT', 140, 'TOP', 70, 'dailyDialogHint');
    }

    public locationDashboardSecondHint() {
        this.updateLocationDashboardDetailHintOverlayHeight();

        this.showHintWaitRender('.hg-filter-hint', '.hg-filter-hint-arrow', 'LEFT', -40, 'TOP', -55, 'locationDashboardSecondHint');
        this.showHintWaitRender('.hg-filter-hint', '.hg-filter-hint-text', 'RIGHT', -15, 'TOP', -115, 'locationDashboardSecondHint');

        this.showHintWaitRender('.hg-new-tab-hint', '.hg-new-tab-hint-arrow', 'LEFT', -55, 'TOP', -10, 'locationDashboardSecondHint');
        this.showHintWaitRender('.hg-new-tab-hint', '.hg-new-tab-hint-text', 'RIGHT', 50, 'TOP', 0, 'locationDashboardSecondHint');

        this.showHintWaitRender('.hg-new-tab-close-hint', '.hg-new-tab-close-hint-arrow', 'LEFT', -55, 'TOP', -10, 'locationDashboardSecondHint');
        this.showHintWaitRender('.hg-new-tab-close-hint', '.hg-new-tab-close-hint-text', 'RIGHT', 50, 'TOP', 0, 'locationDashboardSecondHint');

        this.showHintWaitRender('.hg-full-hint', '.hg-full-hint-arrow', 'LEFT', 1, 'TOP', -60, 'locationDashboardSecondHint');
        this.showHintWaitRender('.hg-full-hint', '.hg-full-hint-text', 'LEFT', 25, 'TOP', -140, 'locationDashboardSecondHint');

        this.showHintWaitRender('.sg-full-hint', '.sg-full-hint-arrow', 'LEFT', -20, 'TOP', -60, 'locationDashboardSecondHint');
        this.showHintWaitRender('.sg-full-hint', '.sg-full-hint-text', 'RIGHT', -40, 'TOP', -135, 'locationDashboardSecondHint');

        this.showHintWaitRender('.ld-data-table-hint', '.ld-data-table-hint-arrow', 'LEFT', 50, 'TOP', -50, 'locationDashboardSecondHint', 'vw');
        this.showHintWaitRender('.ld-data-table-hint', '.ld-data-table-hint-text', 'LEFT', 40, 'TOP', -100, 'locationDashboardSecondHint', 'vw');

        this.showHintWaitRender('.sg-select-hint', '.sg-select-hint-arrow', 'RIGHT', 15, 'TOP', -25, 'locationDashboardSecondHint');
        this.showHintWaitRender('.sg-select-hint', '.sg-select-hint-text', 'RIGHT', 70, 'TOP', -50, 'locationDashboardSecondHint');

        this.showHintWaitRender('.sg-clear-hint', '.sg-clear-hint-arrow', 'RIGHT', -5, 'TOP', 15, 'locationDashboardSecondHint');
        this.showHintWaitRender('.sg-clear-hint', '.sg-clear-hint-text', 'RIGHT', 10, 'TOP', 70, 'locationDashboardSecondHint');

        this.showHintWaitRender('.sg-zoom-hint', '.sg-zoom-hint-arrow', 'LEFT', -20, 'TOP', -70, 'locationDashboardSecondHint');
        this.showHintWaitRender('.sg-zoom-hint', '.sg-zoom-hint-text', 'LEFT', -100, 'TOP', -170, 'locationDashboardSecondHint');

        this.showHintWaitRender('.sg-flag-hint', '.sg-flag-hint-arrow', 'RIGHT', -5, 'TOP', 15, 'locationDashboardSecondHint');
        this.showHintWaitRender('.sg-flag-hint', '.sg-flag-hint-text', 'RIGHT', 10, 'TOP', 70, 'locationDashboardSecondHint');

        this.showHintWaitRender('.sg-unflag-hint', '.sg-unflag-hint-arrow', 'RIGHT', -10, 'TOP', -40, 'locationDashboardSecondHint');
        this.showHintWaitRender('.sg-unflag-hint', '.sg-unflag-hint-text', 'RIGHT', -40, 'TOP', -85, 'locationDashboardSecondHint');

        this.showHintWaitRender('.sg-block-hint', '.sg-block-hint-arrow', 'RIGHT', -10, 'TOP', 20, 'locationDashboardSecondHint');
        this.showHintWaitRender('.sg-block-hint', '.sg-block-hint-text', 'RIGHT', 1, 'TOP', 70, 'locationDashboardSecondHint');

        this.showHintWaitRender('.sg-snap-hint', '.sg-snap-hint-arrow', 'LEFT', -15, 'TOP', 30, 'locationDashboardSecondHint');
        this.showHintWaitRender('.sg-snap-hint', '.sg-snap-hint-text', 'LEFT', -95, 'TOP', 100, 'locationDashboardSecondHint');

        this.showHintWaitRender('.sg-curve-hint', '.sg-curve-hint-arrow', 'RIGHT', 15, 'TOP', 5, 'locationDashboardSecondHint');
        this.showHintWaitRender('.sg-curve-hint', '.sg-curve-hint-text', 'RIGHT', 70, 'TOP', 25, 'locationDashboardSecondHint');

        this.showHintWaitRender('.sg-tolerance-hint', '.sg-tolerance-hint-arrow', 'RIGHT', -10, 'TOP', 30, 'locationDashboardSecondHint');
        this.showHintWaitRender('.sg-tolerance-hint', '.sg-tolerance-hint-text', 'RIGHT', 30, 'TOP', 80, 'locationDashboardSecondHint');

        this.showHintWaitRender('.sg-tol-select-hint', '.sg-tol-select-hint-arrow', 'RIGHT', -20, 'TOP', 30, 'locationDashboardSecondHint');
        this.showHintWaitRender('.sg-tol-select-hint', '.sg-tol-select-hint-text', 'RIGHT', -70, 'TOP', 80, 'locationDashboardSecondHint');

        this.showHintWaitRender('.sg-generation-hint', '.sg-generation-hint-arrow', 'LEFT', 25, 'TOP', 40, 'locationDashboardSecondHint');
        this.showHintWaitRender('.sg-generation-hint', '.sg-generation-hint-text', 'LEFT', 10, 'TOP', 105, 'locationDashboardSecondHint');

        this.showHintWaitRender('.sg-sync-hint', '.sg-sync-hint-arrow', 'LEFT', 30, 'TOP', 40, 'locationDashboardSecondHint');
        this.showHintWaitRender('.sg-sync-hint', '.sg-sync-hint-text', 'LEFT', 60, 'TOP', 90, 'locationDashboardSecondHint');

        this.showHintWaitRender('.highcharts-range-selector-group', '.hg-zoom-arrow', 'LEFT', 140, 'TOP', 30, 'locationDashboardSecondHint');
        this.showHintWaitRender('.highcharts-range-selector-group', '.hg-zoom-text', 'LEFT', 30, 'TOP', 100, 'locationDashboardSecondHint');

        this.showHintWaitRender('.highcharts-range-selector-group', '.hg-tracer-arrow', 'LEFT', 320, 'TOP', 30, 'locationDashboardSecondHint');
        this.showHintWaitRender('.highcharts-range-selector-group', '.hg-tracer-text', 'LEFT', 280, 'TOP', 100, 'locationDashboardSecondHint');

        this.showHintWaitRender('.highcharts-legend', '.highcharts-legend-arrow', 'LEFT', 270, 'TOP', 30, 'locationDashboardSecondHint');
        this.showHintWaitRender('.highcharts-legend', '.highcharts-legend-text', 'LEFT', 150, 'TOP', 100, 'locationDashboardSecondHint');

        // #37337 Legend is separate component while chart is canvas. Anchor it to legend
        this.showHintWaitRender('.highcharts-legend', '.highcharts-navigator-arrow', 'LEFT', 120, 'TOP', -120, 'locationDashboardSecondHint');
        this.showHintWaitRender('.highcharts-legend', '.highcharts-navigator-text', 'LEFT', 20, 'TOP', -200, 'locationDashboardSecondHint');
    }

    /**
     * show Location DashBoard hint
     */
    public locationDashBoardHint() {
        this.updateLocationDashboardDetailHintOverlayHeight();

        this.showHintWaitRender('.ld-series-hint', '.ld-series-hint-arrow', 'LEFT', 5, 'TOP', -40, 'locationDashboardHint');
        this.showHintWaitRender('.ld-series-hint', '.ld-series-hint-text', 'LEFT', 30, 'TOP', -90, 'locationDashboardHint');

        this.showHintWaitRender('.ld-location-hint', '.ld-location-hint-arrow', 'LEFT', 25, 'TOP', -60, 'locationDashboardHint');
        this.showHintWaitRender('.ld-location-hint', '.ld-location-hint-text', 'LEFT', 1, 'TOP', -90, 'locationDashboardHint');

        this.showHintWaitRender('.ld-selected-hint', '.ld-selected-hint-arrow', 'LEFT', 5, 'TOP', 35, 'locationDashboardHint');
        this.showHintWaitRender('.ld-selected-hint', '.ld-selected-hint-text', 'LEFT', -20, 'TOP', 100, 'locationDashboardHint');

        this.showHintWaitRender('.ld-rain-hint', '.ld-rain-hint-arrow', 'LEFT', 15, 'TOP', 35, 'locationDashboardHint');
        this.showHintWaitRender('.ld-rain-hint', '.ld-rain-hint-text', 'LEFT', 20, 'TOP', 90, 'locationDashboardHint');

        this.showHintWaitRender('.ld-search-hint', '.ld-search-hint-arrow', 'LEFT', -10, 'TOP', -65, 'locationDashboardHint');
        this.showHintWaitRender('.ld-search-hint', '.ld-search-hint-text', 'RIGHT', -40, 'TOP', -110, 'locationDashboardHint');

        this.showHintWaitRender('.ld-date-hint', '.ld-date-hint-arrow', 'LEFT', 40, 'TOP', -50, 'locationDashboardHint');
        this.showHintWaitRender('.ld-date-hint', '.ld-date-hint-text', 'LEFT', 10, 'TOP', -110, 'locationDashboardHint');

        this.showHintWaitRender('.ld-entity-hint', '.ld-entity-hint-arrow', 'LEFT', -25, 'TOP', 35, 'locationDashboardHint');
        this.showHintWaitRender('.ld-entity-hint', '.ld-entity-hint-text', 'RIGHT', -30, 'TOP', 95, 'locationDashboardHint');

        this.showHintWaitRender('.ld-work-hint', '.ld-work-hint-arrow', 'LEFT', 5, 'TOP', -40, 'locationDashboardHint');
        this.showHintWaitRender('.ld-work-hint', '.ld-work-hint-text', 'RIGHT', -40, 'TOP', -120, 'locationDashboardHint');

        this.showHintWaitRender('.ld-approve-hint', '.ld-approve-hint-arrow', 'LEFT', 20, 'TOP', 35, 'locationDashboardHint');
        this.showHintWaitRender('.ld-approve-hint', '.ld-approve-hint-text', 'LEFT', -50, 'TOP', 90, 'locationDashboardHint');

        this.showHintWaitRender('.ld-edit-hint', '.ld-edit-hint-arrow', 'LEFT', -25, 'TOP', -35, 'locationDashboardHint');
        this.showHintWaitRender('.ld-edit-hint', '.ld-edit-hint-text', 'RIGHT', 25, 'TOP', -60, 'locationDashboardHint');

        this.showHintWaitRender('.ld-filter-hint', '.ld-filter-hint-arrow', 'LEFT', -30, 'TOP', 25, 'locationDashboardHint');
        this.showHintWaitRender('.ld-filter-hint', '.ld-filter-hint-text', 'RIGHT', -40, 'TOP', 80, 'locationDashboardHint');

        this.showHintWaitRender('.ld-options-hint', '.ld-options-hint-arrow', 'LEFT', -30, 'TOP', -45, 'locationDashboardHint');
        this.showHintWaitRender('.ld-options-hint', '.ld-options-hint-text', 'RIGHT', 10, 'TOP', -100, 'locationDashboardHint');

        this.showHintWaitRender('.ld-entity-hg-hint', '.ld-entity-hg-hint-arrow', 'LEFT', 40, 'TOP', 30, 'locationDashboardHint');
        this.showHintWaitRender('.ld-entity-hg-hint', '.ld-entity-hg-hint-text', 'LEFT', -30, 'TOP', 100, 'locationDashboardHint');

        this.showHintWaitRender('.ld-entity-sg-hint', '.ld-entity-sg-hint-arrow', 'LEFT', 80, 'TOP', 40, 'locationDashboardHint');
        this.showHintWaitRender('.ld-entity-sg-hint', '.ld-entity-sg-hint-text', 'LEFT', 20, 'TOP', 100, 'locationDashboardHint');

        this.showHintWaitRender('.ld-date-select-hint', '.ld-date-select-hint-arrow', 'LEFT', 200, 'TOP', 40, 'locationDashboardHint');
        this.showHintWaitRender('.ld-date-select-hint', '.ld-date-select-hint-text', 'LEFT', 100, 'TOP', 100, 'locationDashboardHint');

        this.showHintWaitRender('.ld-average-hint', '.ld-average-hint-arrow', 'LEFT', 150, 'TOP', 50, 'locationDashboardHint');
        this.showHintWaitRender('.ld-average-hint', '.ld-average-hint-text', 'LEFT', -10, 'TOP', 115, 'locationDashboardHint');
    }

    public locationDashBoardEditHint() {
        this.scrollToTop('html');
        this.updateLocationDashboardDetailHintOverlayHeight();
        this.showpageHintIcon('.de-topbar-hint', '.de-topbar-hint-arrow', 'LEFT', -55, 'TOP', 15);
        this.showpageHintIcon('.de-topbar-hint', '.de-topbar-hint-text', 'LEFT', -200, 'TOP', 40);

        this.showpageHintIcon('.de-hg-entity-hint', '.de-hg-entity-hint-arrow', 'LEFT', 15, 'TOP', -45);
        this.showpageHintIcon('.de-hg-entity-hint', '.de-hg-entity-hint-text', 'LEFT', -10, 'TOP', -70);

        this.showpageHintIcon('.de-hg-zoom-hint', '.de-hg-zoom-hint-arrow', 'LEFT', -20, 'TOP', -60);
        this.showpageHintIcon('.de-hg-zoom-hint', '.de-hg-zoom-hint-text', 'LEFT', -50, 'TOP', -80);

        this.showpageHintIcon('.de-hg-drag-hint', '.de-hg-drag-hint-arrow', 'LEFT', 5, 'TOP', 35);
        this.showpageHintIcon('.de-hg-drag-hint', '.de-hg-drag-hint-text', 'LEFT', -20, 'TOP', 100);

        this.showpageHintIcon('.de-hg-flag-hint', '.de-hg-flag-hint-arrow', 'LEFT', 10, 'TOP', -55);
        this.showpageHintIcon('.de-hg-flag-hint', '.de-hg-flag-hint-text', 'LEFT', -80, 'TOP', -110);

        this.showpageHintIcon('.de-hg-clear-hint', '.de-hg-clear-hint-arrow', 'LEFT', 20, 'TOP', -55);
        this.showpageHintIcon('.de-hg-clear-hint', '.de-hg-clear-hint-text', 'LEFT', 20, 'TOP', -65);

        this.showpageHintIcon('.de-hg-multiple-hint', '.de-hg-multiple-hint-arrow', 'LEFT', 30, 'TOP', -45);
        this.showpageHintIcon('.de-hg-multiple-hint', '.de-hg-multiple-hint-text', 'LEFT', 70, 'TOP', -60);

        this.showpageHintIcon('.de-hg-copy-hint', '.de-hg-copy-hint-arrow', 'LEFT', -25, 'TOP', 25);
        this.showpageHintIcon('.de-hg-copy-hint', '.de-hg-copy-hint-text', 'LEFT', -80, 'TOP', 80);

        this.showpageHintIcon('.de-hg-interpolate-hint', '.de-hg-interpolate-hint-arrow', 'LEFT', -15, 'TOP', 35);
        this.showpageHintIcon('.de-hg-interpolate-hint', '.de-hg-interpolate-hint-text', 'LEFT', -60, 'TOP', 100);

        this.showpageHintIcon('.highcharts-range-selector-group', '.de-highcharts-range-selector-group-arrow', 'LEFT', 475, 'TOP', 45);
        this.showpageHintIcon('.highcharts-range-selector-group', '.de-highcharts-range-selector-group-text', 'LEFT', 475, 'TOP', 110);

        this.showpageHintIcon('.hg-filter-hint', '.de-hg-filter-hint-arrow', 'LEFT', -20, 'TOP', -60);
        this.showpageHintIcon('.hg-filter-hint', '.de-hg-filter-hint-text', 'LEFT', -40, 'TOP', -100);

        this.showpageHintIcon('.hg-new-tab-hint', '.de-hg-new-tab-hint-arrow', 'LEFT', -45, 'TOP', -40);
        this.showpageHintIcon('.hg-new-tab-hint', '.de-hg-new-tab-hint-text', 'LEFT', -140, 'TOP', -80);

        this.showpageHintIcon('.hg-new-tab-close-hint', '.de-hg-new-tab-close-hint-arrow', 'LEFT', -45, 'TOP', -40);
        this.showpageHintIcon('.hg-new-tab-close-hint', '.de-hg-new-tab-close-hint-text', 'LEFT', -140, 'TOP', -80);

        this.showpageHintIcon('.hg-full-hint', '.de-hg-full-hint-arrow', 'LEFT', -20, 'TOP', -60);
        this.showpageHintIcon('.hg-full-hint', '.de-hg-full-hint-text', 'LEFT', -40, 'TOP', -180);
    }

    public locationDashBoardEditScattergraphHint() {
        this.scrollToTop('html');
        this.updateLocationDashboardDetailHintOverlayHeight();
        this.showpageHintIcon('.sg-full-hint', '.de-sg-full-hint-arrow', 'LEFT', -20, 'TOP', -60);
        this.showpageHintIcon('.sg-full-hint', '.de-sg-full-hint-text', 'RIGHT', -20, 'TOP', -125);

        this.showpageHintIcon('.sg-select-hint', '.de-sg-select-hint-arrow', 'RIGHT', 15, 'TOP', -25);
        this.showpageHintIcon('.sg-select-hint', '.de-sg-select-hint-text', 'RIGHT', 70, 'TOP', -50);

        this.showpageHintIcon('.sg-clear-hint', '.de-sg-clear-hint-arrow', 'RIGHT', -5, 'TOP', 15);
        this.showpageHintIcon('.sg-clear-hint', '.de-sg-clear-hint-text', 'RIGHT', -20, 'TOP', 65);

        this.showpageHintIcon('.sg-zoom-hint', '.de-sg-zoom-hint-arrow', 'LEFT', -20, 'TOP', -70);
        this.showpageHintIcon('.sg-zoom-hint', '.de-sg-zoom-hint-text', 'LEFT', -100, 'TOP', -90);

        this.showpageHintIcon('.sg-flag-hint', '.de-sg-flag-hint-arrow', 'RIGHT', -5, 'TOP', 15);
        this.showpageHintIcon('.sg-flag-hint', '.de-sg-flag-hint-text', 'RIGHT', 10, 'TOP', 70);

        this.showpageHintIcon('.sg-unflag-hint', '.de-sg-unflag-hint-arrow', 'RIGHT', -10, 'TOP', -40);
        this.showpageHintIcon('.sg-unflag-hint', '.de-sg-unflag-hint-text', 'RIGHT', -40, 'TOP', -85);

        this.showpageHintIcon('.sg-block-hint', '.de-sg-block-hint-arrow', 'RIGHT', -10, 'TOP', 20);
        this.showpageHintIcon('.sg-block-hint', '.de-sg-block-hint-text', 'RIGHT', 1, 'TOP', 70);

        this.showpageHintIcon('.sg-snap-hint', '.de-sg-snap-hint-arrow', 'LEFT', -15, 'TOP', 30);
        this.showpageHintIcon('.sg-snap-hint', '.de-sg-snap-hint-text', 'LEFT', -95, 'TOP', 100);

        this.showpageHintIcon('.sg-curve-hint', '.de-sg-curve-hint-arrow', 'RIGHT', -45, 'TOP', -45);
        this.showpageHintIcon('.sg-curve-hint', '.de-sg-curve-hint-text', 'RIGHT', -130, 'TOP', -130);

        this.showpageHintIcon('.sg-tolerance-hint', '.de-sg-tolerance-hint-arrow', 'RIGHT', -10, 'TOP', 30);
        this.showpageHintIcon('.sg-tolerance-hint', '.de-sg-tolerance-hint-text', 'RIGHT', -20, 'TOP', 70);

        this.showpageHintIcon('.sg-tol-select-hint', '.de-sg-tol-select-hint-arrow', 'RIGHT', -20, 'TOP', 30);
        this.showpageHintIcon('.sg-tol-select-hint', '.de-sg-tol-select-hint-text', 'RIGHT', -20, 'TOP', 80);

        this.showpageHintIcon('.sg-generation-hint', '.de-sg-generation-hint-arrow', 'LEFT', 25, 'TOP', 40);
        this.showpageHintIcon('.sg-generation-hint', '.de-sg-generation-hint-text', 'LEFT', 10, 'TOP', 105);

        this.showpageHintIcon('.sg-sync-hint', '.de-sg-sync-hint-arrow', 'LEFT', 30, 'TOP', 40);
        this.showpageHintIcon('.sg-sync-hint', '.de-sg-sync-hint-text', 'LEFT', 60, 'TOP', 90);

        this.showpageHintIcon('.cancel-button-hint', '.de-cancel-hint-arrow', 'LEFT', -55, 'TOP', -25);
        this.showpageHintIcon('.cancel-button-hint', '.de-cancel-hint-text', 'LEFT', -120, 'TOP', -40);

        this.showpageHintIcon('.accept-button-hint', '.de-accept-hint-arrow', 'LEFT', -25, 'TOP', 30);
        this.showpageHintIcon('.accept-button-hint', '.de-accept-hint-text', 'LEFT', -120, 'TOP', 80);
    }

    public locationDashboardAcceptEditdialog() {
        this.scrollToTop('html');
        this.showpageHintIcon('.de-dialog-entities-hint', '.de-dialog-entities-hint-arrow', 'LEFT', 20, 'TOP', -60);
        this.showpageHintIcon('.de-dialog-entities-hint', '.de-dialog-entities-hint-text', 'LEFT', -20, 'TOP', -105);

        this.showpageHintIcon('.de-dialog-reason-hint', '.de-dialog-reason-hint-arrow', 'LEFT', -50, 'TOP', 10);
        this.showpageHintIcon('.de-dialog-reason-hint', '.de-dialog-reason-hint-text', 'LEFT', -300, 'TOP', 25);

        this.showpageHintIcon('.de-dialog-comment-hint', '.de-dialog-comment-hint-arrow', 'LEFT', 20, 'TOP', -10);
        this.showpageHintIcon('.de-dialog-comment-hint', '.de-dialog-comment-hint-text', 'LEFT', 80, 'TOP', -15);

        this.showpageHintIcon('.de-dialog-approve-hint', '.de-dialog-approve-hint-arrow', 'LEFT', 200, 'TOP', 0);
        this.showpageHintIcon('.de-dialog-approve-hint', '.de-dialog-approve-hint-text', 'LEFT', 260, 'TOP', 20);

        this.showpageHintIcon('.de-dialog-cancel-hint', '.de-dialog-cancel-hint-arrow', 'LEFT', -50, 'TOP', -5);
        this.showpageHintIcon('.de-dialog-cancel-hint', '.de-dialog-cancel-hint-text', 'RIGHT', 20, 'TOP', 25);

        this.showpageHintIcon('.de-dialog-accept-hint', '.de-dialog-accept-hint-arrow', 'LEFT', 20, 'TOP', 30);
        this.showpageHintIcon('.de-dialog-accept-hint', '.de-dialog-accept-hint-text', 'LEFT', 10, 'TOP', 95);
    }

    public locationDashBoardEditHintRollback() {
        this.scrollToTop('html');
        this.scrollToTop('#rollback-content');
        this.updateLocationDashboardDetailHintOverlayHeight();

        if (this.rollbackFilterOpened) {
            this.showHintWaitRender('.entities-filter', '.roll-back-hint-arrow', 'LEFT', 0, 'TOP', -40, 'location-dashBoard-edit-hint_rollback');
            this.showHintWaitRender('.entities-filter', '.roll-back-hint-text', 'LEFT', -60, 'TOP', -70, 'location-dashBoard-edit-hint_rollback');

            this.showHintWaitRender('.date-range-filter', '.range-hint-arrow', 'LEFT', 0, 'TOP', -40, 'location-dashBoard-edit-hint_rollback');
            this.showHintWaitRender('.date-range-filter', '.range-hint-text', 'LEFT', -50, 'TOP', -70, 'location-dashBoard-edit-hint_rollback');
        } else {
            this.hideItems(['.roll-back-hint-arrow', '.roll-back-hint-text', '.range-hint-arrow', '.range-hint-text']);
        }

        this.showHintWaitRender('.mat-checkbox', '.check-hint-arrow', 'LEFT', -25, 'TOP', -70, 'location-dashBoard-edit-hint_rollback');
        this.showHintWaitRender('.mat-checkbox', '.check-hint-text', 'LEFT', -80, 'TOP', -95, 'location-dashBoard-edit-hint_rollback');

        this.showHintWaitRender('.cdk-column-timestamp', '.timestamp-hint-arrow', 'LEFT', 30, 'TOP', -45, 'location-dashBoard-edit-hint_rollback');
        this.showHintWaitRender('.cdk-column-timestamp', '.timestamp-hint-text', 'LEFT', 40, 'TOP', -85, 'location-dashBoard-edit-hint_rollback');

        this.showHintWaitRender('.cdk-column-depth', '.depth-hint-arrow', 'LEFT', 100, 'TOP', 40, 'location-dashBoard-edit-hint_rollback');
        this.showHintWaitRender('.cdk-column-depth', '.depth-hint-text', 'LEFT', 50, 'TOP', 105, 'location-dashBoard-edit-hint_rollback');

        this.showHintWaitRender('.cdk-column-velocity', '.velocity-hint-arrow', 'LEFT', 75, 'TOP', -45, 'location-dashBoard-edit-hint_rollback');
        this.showHintWaitRender('.cdk-column-velocity', '.velocity-hint-text', 'LEFT', 25, 'TOP', -80, 'location-dashBoard-edit-hint_rollback');

        this.showHintWaitRender('.cdk-column-quantity', '.quantity-hint-arrow', 'LEFT', 75, 'TOP', 35, 'location-dashBoard-edit-hint_rollback');
        this.showHintWaitRender('.cdk-column-quantity', '.quantity-hint-text', 'LEFT', 0, 'TOP', 95, 'location-dashBoard-edit-hint_rollback');

        this.showHintWaitRender('.cdk-column-rain', '.rain-hint-arrow', 'LEFT', 75, 'TOP', -50, 'location-dashBoard-edit-hint_rollback');
        this.showHintWaitRender('.cdk-column-rain', '.rain-hint-text', 'LEFT', 5, 'TOP', -80, 'location-dashBoard-edit-hint_rollback');

        this.showHintWaitRender('.cdk-column-performedBy', '.performed-hint-arrow', 'LEFT', 0, 'TOP', -40, 'location-dashBoard-edit-hint_rollback');
        this.showHintWaitRender('.cdk-column-performedBy', '.performed-hint-text', 'LEFT', -50, 'TOP', -80, 'location-dashBoard-edit-hint_rollback');

        this.showHintWaitRender('.cdk-column-comments', '.comments-hint-arrow', 'LEFT', 0, 'TOP', -50, 'location-dashBoard-edit-hint_rollback');
        this.showHintWaitRender('.cdk-column-comments', '.comments-hint-text', 'LEFT', -40, 'TOP', -80, 'location-dashBoard-edit-hint_rollback');

        this.showHintWaitRender('.paginator-hint', '.paging-hint-arrow', 'RIGHT', 250, 'TOP', 45, 'location-dashBoard-edit-hint_rollback');
        this.showHintWaitRender('.paginator-hint', '.paging-hint-text', 'RIGHT', 145, 'TOP', 110, 'location-dashBoard-edit-hint_rollback');

        this.showHintWaitRender('.close-btn', '.close-hint-arrow', 'LEFT', 0, 'TOP', 35, 'location-dashBoard-edit-hint_rollback');
        this.showHintWaitRender('.close-btn', '.close-hint-text', 'LEFT', -120, 'TOP', 100, 'location-dashBoard-edit-hint_rollback');

        this.showHintWaitRender('.revert-btn', '.revert-hint-arrow', 'LEFT', 40, 'TOP', 30, 'location-dashBoard-edit-hint_rollback');
        this.showHintWaitRender('.revert-btn', '.revert-hint-text', 'LEFT', 0, 'TOP', 90, 'location-dashBoard-edit-hint_rollback');
    }

    public locationDashboardDetailHintOverlay(isNext: boolean): void {
        if (isNext) {
            if (this.currentScreenNo < 5) {
                this.currentScreenNo++;
            }
        } else {
            if (this.currentScreenNo > 0) {
                this.currentScreenNo--;
            }
        }
        this.showLocationDetailScreenNo.next(this.currentScreenNo);
    }

    public updateLocationDashboardDetailHintOverlayHeight() {
        const routerOutletComponent: HTMLElement = <HTMLElement>this.angularDocument.querySelector('#hintOverlay');
        const htmlHeight: number = this.angularDocument.querySelector('html').clientHeight;
        let pageHeight: number = this.angularDocument.querySelector('body').clientHeight;
        if (htmlHeight > pageHeight) {
            pageHeight = htmlHeight - 3;
        }
        if (routerOutletComponent) {
            routerOutletComponent.style.height = pageHeight + 'px';
            routerOutletComponent.style.visibility = 'visible';
        }
    }

    public isScrollable() {
        const htmlHeight: number = this.angularDocument.querySelector('html').clientHeight;
        const pageHeight: number = this.angularDocument.querySelectorAll('mat-tab-group')[1].clientHeight + this.angularDocument.querySelector('header').clientHeight;
        return htmlHeight > pageHeight ? false : true;
    }

    public scrollDataComm(direction) {
        const pageHeight: number = this.angularDocument.querySelectorAll('mat-tab-group')[1].clientHeight;
        if (direction) {
            this.angularDocument.querySelector('mat-drawer').firstElementChild.scrollTo({ top: pageHeight, behavior: 'smooth' })
        } else {
            this.angularDocument.querySelector('mat-drawer').firstElementChild.scrollTo({ top: 0, behavior: 'smooth' })
        }

    }

    public showLocationDashboardDetailHintOverlay(): void {
        this.updateLocationDashboardDetailHintOverlayHeight();
        this.scrollToTop('html');
        this.selectedHintPageName.next('noHintclass');
        // need to check the condition after note section inplimentation complited
        this.delay(500).then((a) => {
            switch (this.currentScreenNo) {
                case 0:
                    this.selectedHintPageName.next('view-location-details-hint');
                    this.viewLocationDetails();
                    break;
                case 1:
                    this.selectedHintPageName.next('view-location-associated-entity-hint');
                    this.viewLocationAssociatedEntity();
                    break;
                case 2:
                    this.selectedHintPageName.next('view-location-confirmation-points-hint');
                    this.viewLocationConfirmationPoints();
                    break;
                case 3:
                    this.selectedHintPageName.next('view-location-files-hint');
                    this.viewLocationFiles();
                    break;
                case 4:
                    this.selectedHintPageName.next('view-location-flow-balance-hint');
                    this.viewLocationFlowBalance();
                    break;
                case 5:
                    this.selectedHintPageName.next('view-location-advanced-hint');
                    this.viewAdvancedTab();
                    break;

                default:
                // do nothing;
            }
        });
    }

    public viewLocationDetails() {
        this.showpageHintIcon('.edit-button-hint', '.edit-button-hint-arrow', 'LEFT', 9, 'TOP', -70);
        this.showpageHintIcon('.edit-button-hint', '.edit-button-hint-text', 'LEFT', -60, 'TOP', -109);
        this.showpageHintIcon('.collect-button-hint', '.collect-button-hint-arrow', 'LEFT', 30, 'TOP', 35);
        this.showpageHintIcon('.collect-button-hint', '.collect-button-hint-text', 'LEFT', -5, 'TOP', 100);
        this.showpageHintIcon(
            '.schedule-collect-button-hint',
            '.schedule-collect-button-hint-arrow',
            'LEFT',
            75,
            'TOP',
            35,
        );
        this.showpageHintIcon(
            '.schedule-collect-button-hint',
            '.schedule-collect-button-hint-text',
            'LEFT',
            0,
            'TOP',
            100,
        );
        this.showpageHintIcon('.export-button-hint', '.export-button-hint-arrow', 'LEFT', 15, 'TOP', 35);
        this.showpageHintIcon('.export-button-hint', '.export-button-hint-text', 'LEFT', -90, 'TOP', 100);
        this.showpageHintIcon('.percent-full-hint', '.percent-full-hint-arrow', 'LEFT', 45, 'TOP', 25);
        this.showpageHintIcon('.percent-full-hint', '.percent-full-hint-text', 'LEFT', 5, 'TOP', 90);
        this.showpageHintIcon('.daily-summary-hint', '.daily-summary-hint-arrow', 'LEFT', 45, 'TOP', 25);
        this.showpageHintIcon('.daily-summary-hint', '.daily-summary-hint-text', 'LEFT', -25, 'TOP', 90);
        this.showpageHintIcon('.uptime-hint', '.uptime-hint-arrow', 'LEFT', 25, 'TOP', 25);
        this.showpageHintIcon('.uptime-hint', '.uptime-hint-text', 'LEFT', -55, 'TOP', 90);
        this.showpageHintIcon('.details-hint', '.details-hint-arrow', 'LEFT', 80, 'TOP', -10);
        this.showpageHintIcon('.details-hint', '.details-hint-text', 'LEFT', 145, 'TOP', 20);
    }
    public viewLocationAssociatedEntity() {
        this.showpageHintIcon('.copy-entity-hint', '.copy-entity-hint-arrow', 'LEFT', 20, 'TOP', -65);
        this.showpageHintIcon('.copy-entity-hint', '.copy-entity-hint-text', 'LEFT', -100, 'TOP', -120);
        this.showpageHintIcon('.add-entity-hint', '.add-entity-hint-arrow', 'LEFT', 10, 'TOP', -70);
        this.showpageHintIcon('.add-entity-hint', '.add-entity-hint-text', 'LEFT', -35, 'TOP', -110);
        this.showpageHintIcon('.ansr-hint', '.ansr-hint-arrow', 'LEFT', 130, 'TOP', -10);
        this.showpageHintIcon('.ansr-hint', '.ansr-hint-text', 'LEFT', 195, 'TOP', 20);

        this.showpageHintIcon('.ansr-edit-hint', '.ansr-edit-hint-arrow', 'RIGHT', 20, 'TOP', -10);
        this.showpageHintIcon('.ansr-edit-hint', '.ansr-edit-hint-text', 'RIGHT', 85, 'TOP', 30);

        this.showpageHintIcon('.ansr-delete-hint', '.ansr-delete-hint-arrow', 'LEFT', -15, 'TOP', 35);
        this.showpageHintIcon('.ansr-delete-hint', '.ansr-delete-hint-text', 'LEFT', -30, 'TOP', 105);
    }
    public viewLocationConfirmationPoints() {
        this.showpageHintIcon(
            '.download-confirmation-hint',
            '.download-confirmation-hint-arrow',
            'RIGHT',
            -100,
            'TOP',
            -80,
        );
        this.showpageHintIcon(
            '.download-confirmation-hint',
            '.download-confirmation-hint-text',
            'RIGHT',
            -150,
            'TOP',
            -105,
        );
        this.showpageHintIcon('.add-confirmation-hint', '.add-confirmation-hint-arrow', 'RIGHT', 15, 'TOP', -25);
        this.showpageHintIcon('.add-confirmation-hint', '.add-confirmation-hint-text', 'RIGHT', 70, 'TOP', -25);

        this.showpageHintIcon('.confirmation-edit-hint', '.confirmation-edit-hint-arrow', 'RIGHT', 20, 'TOP', -10);
        this.showpageHintIcon('.confirmation-edit-hint', '.confirmation-edit-hint-text', 'RIGHT', 85, 'TOP', 30);

        this.showpageHintIcon('.confirmation-delete-hint', '.confirmation-delete-hint-arrow', 'LEFT', -15, 'TOP', 35);
        this.showpageHintIcon('.confirmation-delete-hint', '.confirmation-delete-hint-text', 'LEFT', -30, 'TOP', 105);

        this.showpageHintIcon('.confirmation-hint', '.confirmation-hint-arrow', 'LEFT', 180, 'TOP', -10);
        this.showpageHintIcon('.confirmation-hint', '.confirmation-hint-text', 'LEFT', 240, 'TOP', 30);
    }
    public viewAdvancedTab() {
        this.showpageHintIcon('.advanced-hint', '.advanced-hint-arrow', 'LEFT', 110, 'TOP', -15);
        this.showpageHintIcon('.advanced-hint', '.advanced-hint-text', 'LEFT', 140, 'TOP', 25);

        this.showpageHintIcon('.advanced-filter-hint', '.advanced-filter-hint-arrow', 'LEFT', -60, 'TOP', -30);
        this.showpageHintIcon('.advanced-filter-hint', '.advanced-filter-hint-text', 'LEFT', -330, 'TOP', -35);

        this.showpageHintIcon('.diagnostics-hint', '.diagnostics-hint-arrow', 'LEFT', 20, 'TOP', -75);
        this.showpageHintIcon('.diagnostics-hint', '.diagnostics-hint-text', 'LEFT', -130, 'TOP', -120);

        this.showpageHintIcon('.date-picker-hint', '.date-picker-hint-arrow', 'LEFT', 150, 'TOP', -80);
        this.showpageHintIcon('.date-picker-hint', '.date-picker-hint-text', 'LEFT', 90, 'TOP', -120);

        this.showpageHintIcon('.date-time-hint', '.date-time-hint-arrow', 'LEFT', 110, 'TOP', -15);
        this.showpageHintIcon('.date-time-hint', '.date-time-hint-text', 'LEFT', 150, 'TOP', 25);

        this.showpageHintIcon('.user-name-hint', '.user-name-hint-arrow', 'LEFT', 0, 'TOP', 35);
        this.showpageHintIcon('.user-name-hint', '.user-name-hint-text', 'LEFT', -60, 'TOP', 100);

        this.showpageHintIcon('.result-hint', '.result-hint-arrow', 'LEFT', 85, 'TOP', -15);
        this.showpageHintIcon('.result-hint', '.result-hint-text', 'LEFT', 130, 'TOP', 25);
    }
    public viewLocationFiles() {
        this.showpageHintIcon('.create-folder-hint', '.create-folder-hint-arrow', 'LEFT', 0, 'TOP', 40);
        this.showpageHintIcon('.create-folder-hint', '.create-folder-hint-text', 'LEFT', -80, 'TOP', 102);
        this.showHintWithoutHorizontalCalc(
            '.show-hide-subfolders-hint',
            '.show-hide-subfolders-hint-arrow',
            'RIGHT',
            25,
            'TOP',
            50,
        );
        this.showHintWithoutHorizontalCalc(
            '.show-hide-subfolders-hint',
            '.show-hide-subfolders-hint-text',
            'RIGHT',
            5,
            'TOP',
            112,
        );
        this.showpageHintIcon('.upload-file-hint', '.upload-file-hint-arrow', 'LEFT', 23, 'TOP', -70);
        this.showpageHintIcon('.upload-file-hint', '.upload-file-hint-text', 'LEFT', -150, 'TOP', -90);
        this.showpageHintIcon('.files-hint', '.files-hint-arrow', 'LEFT', 80, 'TOP', -10);
        this.showpageHintIcon('.files-hint', '.files-hint-text', 'LEFT', 145, 'TOP', 30);

        this.showpageHintIcon('.file-share-hint', '.file-share-hint-arrow', 'RIGHT', 15, 'TOP', -20);
        this.showpageHintIcon('.file-share-hint', '.file-share-hint-text', 'RIGHT', 80, 'TOP', -30);

        this.showpageHintIcon('.file-download-hint', '.file-download-hint-arrow', 'LEFT', -15, 'TOP', 35);
        this.showpageHintIcon('.file-download-hint', '.file-download-hint-text', 'LEFT', -30, 'TOP', 105);

        this.showpageHintIcon('.file-import-hint', '.file-import-hint-arrow', 'LEFT', 5, 'TOP', 35);
        this.showpageHintIcon('.file-import-hint', '.file-import-hint-text', 'LEFT', 25, 'TOP', 105);

        this.showpageHintIcon('.file-delete-hint', '.file-delete-hint-arrow', 'LEFT', 45, 'TOP', -20);
        this.showpageHintIcon('.file-delete-hint', '.file-delete-hint-text', 'LEFT', 105, 'TOP', -20);
    }
    public viewLocationNotes() {
        this.showpageHintIcon('.add-note-hint', '.add-note-hint-arrow', 'RIGHT', 15, 'TOP', -20);
        this.showpageHintIcon('.add-note-hint', '.add-note-hint-text', 'RIGHT', 60, 'TOP', -30);
        this.showpageHintIcon('.filter-hint', '.filter-hint-arrow', 'LEFT', -5, 'TOP', -65);
        this.showpageHintIcon('.filter-hint', '.filter-hint-text', 'LEFT', -60, 'TOP', -120);
        this.showpageCalculateHintIcon('.notes-hint', '.notes-hint-arrow', 'LEFT', 100, 'TOP', -30);
        this.showpageCalculateHintIcon('.notes-hint', '.notes-hint-text', 'LEFT', 140, 'TOP', 30);
    }
    public viewLocationFlowBalance() {
        this.showpageHintIcon('.generate-button-hint', '.generate-button-hint-arrow', 'RIGHT', 15, 'TOP', -22);
        this.showpageHintIcon('.generate-button-hint', '.generate-button-hint-text', 'RIGHT', 55, 'TOP', -24);
        this.showpageHintIcon('.flow-balance-edit-hint', '.flow-balance-edit-hint-arrow', 'RIGHT', -40, 'TOP', 40);
        this.showpageHintIcon('.flow-balance-edit-hint', '.flow-balance-edit-hint-text', 'RIGHT', -5, 'TOP', 105);
        this.showpageHintIcon('.flow-balance-hint', '.flow-balance-hint-arrow', 'LEFT', 190, 'TOP', -15);
        this.showpageHintIcon('.flow-balance-hint', '.flow-balance-hint-text', 'LEFT', 250, 'TOP', 15);
    }

    /**
     * dynamic page Hint
     */
    public viewDataDynamic() {
        this.showpageHintIcon('#addDynamicDb', '.addNewDynamicDbHintArrow', 'RIGHT', -15, 'TOP', -65);
        this.showpageHintIcon('#addDynamicDb', '.addNewDynamicDbHintText', 'RIGHT', -10, 'TOP', -90);
    }
    /**
     * Vault Page Hint Position
     */
    public vaultPageHint() {
        this.showHintWaitRender('.vault-folders-hint', '.vault-folders-hint-arrow', 'LEFT', 10, 'TOP', -60, 'vaultPageHint');
        this.showHintWaitRender('.vault-folders-hint', '.vault-folders-hint-text', 'LEFT', -5, 'TOP', -115, 'vaultPageHint');

        this.showHintWaitRender('.vault-hide-folders-hint', '.vault-hide-folders-hint-arrow', 'LEFT', 15, 'TOP', -55, 'vaultPageHint');
        this.showHintWaitRender('.vault-hide-folders-hint', '.vault-hide-folders-hint-text', 'LEFT', 25, 'TOP', -85, 'vaultPageHint');

        this.showHintWaitRender('.vault-breadcrumb-hint', '.vault-breadcrumb-hint-arrow', 'LEFT', 60, 'TOP', -30, 'vaultPageHint');
        this.showHintWaitRender('.vault-breadcrumb-hint', '.vault-breadcrumb-hint-text', 'LEFT', 130, 'TOP', -55, 'vaultPageHint');

        this.showHintWaitRender('.vault-search-hint', '.vault-search-hint-arrow', 'LEFT', 40, 'TOP', 5, 'vaultPageHint');
        this.showHintWaitRender('.vault-search-hint', '.vault-search-hint-text', 'LEFT', 105, 'TOP', 35, 'vaultPageHint');

        this.showHintWaitRender('.vault-create-hint', '.vault-create-hint-arrow', 'RIGHT', 5, 'TOP', -5, 'vaultPageHint');
        this.showHintWaitRender('.vault-create-hint', '.vault-create-hint-text', 'RIGHT', 60, 'TOP', 20, 'vaultPageHint');

        this.showHintWaitRender('.vault-hide-sub-hint', '.vault-hide-sub-hint-arrow', 'LEFT', 115, 'TOP', -50, 'vaultPageHint');
        this.showHintWaitRender('.vault-hide-sub-hint', '.vault-hide-sub-hint-text', 'LEFT', 1, 'TOP', -110, 'vaultPageHint');

        this.showHintWaitRender('.vault-list-hint', '.vault-list-hint-arrow', 'LEFT', 40, 'TOP', 30, 'vaultPageHint');
        this.showHintWaitRender('.vault-list-hint', '.vault-list-hint-text', 'LEFT', 20, 'TOP', 95, 'vaultPageHint');

        this.showHintWaitRender('.vault-delete-hint', '.vault-delete-hint-arrow', 'LEFT', 45, 'TOP', 5, 'vaultPageHint');
        this.showHintWaitRender('.vault-delete-hint', '.vault-delete-hint-text', 'LEFT', 100, 'TOP', 30, 'vaultPageHint');
    }

    public vaultSecondHint(isList: boolean) {
        const pageName = isList ? 'vaultSecondListHint' : 'vaultSecondHint';
        this.showHintWaitRender('.vault-toggle-hint', '.vault-toggle-hint-arrow', 'LEFT', 15, 'TOP', -70, pageName);
        this.showHintWaitRender('.vault-toggle-hint', '.vault-toggle-hint-text', 'LEFT', -95, 'TOP', -125, pageName);

        this.showHintWaitRender('.vault-top-move-hint', '.vault-top-move-hint-arrow', 'RIGHT', 5, 'TOP', -5, pageName);
        this.showHintWaitRender('.vault-top-move-hint', '.vault-top-move-hint-text', 'RIGHT', 50, 'TOP', 35, pageName);

        this.showHintWaitRender('.vault-top-delete-hint', '.vault-top-delete-hint-arrow', 'LEFT', 10, 'TOP', 25, pageName);
        this.showHintWaitRender('.vault-top-delete-hint', '.vault-top-delete-hint-text', 'RIGHT', -50, 'TOP', 75, pageName);

        this.showHintWaitRender('.vault-top-download-hint', '.vault-top-download-hint-arrow', 'LEFT', -40, 'TOP', -45, pageName);
        this.showHintWaitRender('.vault-top-download-hint', '.vault-top-download-hint-text', 'RIGHT', 30, 'TOP', -100, pageName);

        this.showHintWaitRender('.vault-top-import-hint', '.vault-top-import-hint-arrow', 'LEFT', -10, 'TOP', -35, pageName);
        this.showHintWaitRender('.vault-top-import-hint', '.vault-top-import-hint-text', 'RIGHT', -150, 'TOP', -95, pageName);

        this.showHintWaitRender('.vault-file-check-hint', '.vault-file-check-hint-arrow', 'RIGHT', 10, 'TOP', -35, pageName);
        this.showHintWaitRender('.vault-file-check-hint', '.vault-file-check-hint-text', 'RIGHT', 60, 'TOP', -75, pageName);

        this.showHintWaitRender('.vault-file-icon-hint', '.vault-file-icon-hint-arrow', 'LEFT', 20, 'TOP', (isList ? -60 : -40), pageName);
        this.showHintWaitRender('.vault-file-icon-hint', '.vault-file-icon-hint-text', 'LEFT', -50, 'TOP', (isList ? - 150 : -130), pageName);

        this.showHintWaitRender('.vault-file-share-hint', '.vault-file-share-hint-arrow', 'RIGHT', 1, 'TOP', (isList ? -5 : -35), pageName);
        this.showHintWaitRender('.vault-file-share-hint', '.vault-file-share-hint-text', 'RIGHT', (isList ? 20 : 1), 'TOP', (isList ? -25 : -105), pageName);

        this.showHintWaitRender('.vault-file-download-hint', '.vault-file-download-hint-arrow', 'LEFT', (isList ? -20 : -35), 'TOP', (isList ? 20 : 10), pageName);
        this.showHintWaitRender('.vault-file-download-hint', '.vault-file-download-hint-text', 'LEFT', (isList ? -155 : -215), 'TOP', (isList ? 70 : 20), pageName);

        this.showHintWaitRender('.vault-file-import-hint', '.vault-file-import-hint-arrow', 'LEFT', 20, 'TOP', -40, pageName);
        this.showHintWaitRender('.vault-file-import-hint', '.vault-file-import-hint-text', 'LEFT', -40, 'TOP', -100, pageName);

        this.showHintWaitRender('.vault-file-delete-hint', '.vault-file-delete-hint-arrow', 'LEFT', (isList ? 10 : 40), 'TOP', (isList ? 25 : -25), pageName);
        this.showHintWaitRender('.vault-file-delete-hint', '.vault-file-delete-hint-text', 'LEFT', (isList ? -25 : 100), 'TOP', (isList ? 85 : -35), pageName);
    }

    public vaultThirdHint() {
        this.showHintWaitRender('.vault-upload-hint', '.upload-menu-mock', 'RIGHT', -30, 'TOP', 40, 'vaultThirdHint');
        this.showHintWaitRender('.vault-export-hint', '.export-menu-mock', 'LEFT', 50, 'TOP', 40, 'vaultThirdHint');

        this.showHintWaitRender('.vault-upload-file-hint', '.vault-upload-file-hint-arrow', 'LEFT', 20, 'TOP', -70, 'vaultThirdHint');
        this.showHintWaitRender('.vault-upload-file-hint', '.vault-upload-file-hint-text', 'LEFT', -75, 'TOP', -115, 'vaultThirdHint');

        this.showHintWaitRender('.vault-upload-location-hint', '.vault-upload-location-hint-arrow', 'RIGHT', 10, 'TOP', -20, 'vaultThirdHint');
        this.showHintWaitRender('.vault-upload-location-hint', '.vault-upload-location-hint-text', 'RIGHT', 55, 'TOP', -50, 'vaultThirdHint');

        this.showHintWaitRender('.vault-upload-data-hint', '.vault-upload-data-hint-arrow', 'RIGHT', 10, 'TOP', 5, 'vaultThirdHint');
        this.showHintWaitRender('.vault-upload-data-hint', '.vault-upload-data-hint-text', 'RIGHT', 55, 'TOP', 35, 'vaultThirdHint');

        this.showHintWaitRender('.vault-export-new-hint', '.vault-export-new-hint-arrow', 'LEFT', 30, 'TOP', -70, 'vaultThirdHint');
        this.showHintWaitRender('.vault-export-new-hint', '.vault-export-new-hint-text', 'LEFT', -50, 'TOP', -125, 'vaultThirdHint');

        this.showHintWaitRender('.vault-export-load-hint', '.vault-export-load-hint-arrow', 'RIGHT', 1, 'TOP', 35, 'vaultThirdHint');
        this.showHintWaitRender('.vault-export-load-hint', '.vault-export-load-hint-text', 'RIGHT', -20, 'TOP', 115, 'vaultThirdHint');

        this.showHintWaitRender('.vault-export-recent-hint', '.vault-export-recent-hint-arrow', 'LEFT', 40, 'TOP', 65, 'vaultThirdHint');
        this.showHintWaitRender('.vault-export-recent-hint', '.vault-export-recent-hint-text', 'LEFT', -60, 'TOP', 135, 'vaultThirdHint');
    }

    public vaultDialogHint() {
        this.showHintWaitRender('.vault-dialog-type-hint', '.vault-dialog-type-hint-arrow', 'LEFT', 30, 'TOP', -35, 'vaultDialogHint');
        this.showHintWaitRender('.vault-dialog-type-hint', '.vault-dialog-type-hint-text', 'RIGHT', -110, 'TOP', -90, 'vaultDialogHint');

        this.showHintWaitRender('.vault-dialog-inactive-hint', '.vault-dialog-inactive-hint-arrow', 'LEFT', 55, 'TOP', -30, 'vaultDialogHint');
        this.showHintWaitRender('.vault-dialog-inactive-hint', '.vault-dialog-inactive-hint-text', 'LEFT', 110, 'TOP', -60, 'vaultDialogHint');

        this.showHintWaitRender('.vault-dialog-locations-hint', '.vault-dialog-locations-hint-arrow', 'LEFT', 35, 'TOP', -1, 'vaultDialogHint');
        this.showHintWaitRender('.vault-dialog-locations-hint', '.vault-dialog-locations-hint-text', 'LEFT', 95, 'TOP', -10, 'vaultDialogHint');

        this.showHintWaitRender('.vault-dialog-entity-hint', '.vault-dialog-entity-hint-arrow', 'RIGHT', 10, 'TOP', -25, 'vaultDialogHint');
        this.showHintWaitRender('.vault-dialog-entity-hint', '.vault-dialog-entity-hint-text', 'RIGHT', 15, 'TOP', -80, 'vaultDialogHint');

        this.showHintWaitRender('.vault-dialog-date-hint', '.vault-dialog-date-hint-arrow', 'LEFT', 85, 'TOP', -15, 'vaultDialogHint');
        this.showHintWaitRender('.vault-dialog-date-hint', '.vault-dialog-date-hint-text', 'LEFT', 140, 'TOP', -30, 'vaultDialogHint');

        this.showHintWaitRender('.vault-dialog-timestamp-hint', '.vault-dialog-timestamp-hint-arrow', 'RIGHT', 25, 'TOP', -20, 'vaultDialogHint');
        this.showHintWaitRender('.vault-dialog-timestamp-hint', '.vault-dialog-timestamp-hint-text', 'RIGHT', 75, 'TOP', -75, 'vaultDialogHint');

        this.showHintWaitRender('.vault-dialog-value-hint', '.vault-dialog-value-hint-arrow', 'LEFT', 85, 'TOP', -15, 'vaultDialogHint');
        this.showHintWaitRender('.vault-dialog-value-hint', '.vault-dialog-value-hint-text', 'LEFT', 140, 'TOP', -25, 'vaultDialogHint');

        this.showHintWaitRender('.vault-dialog-rounding-hint', '.vault-dialog-rounding-hint-arrow', 'LEFT', 55, 'TOP', -15, 'vaultDialogHint');
        this.showHintWaitRender('.vault-dialog-rounding-hint', '.vault-dialog-rounding-hint-text', 'LEFT', 110, 'TOP', -15, 'vaultDialogHint');

        this.showHintWaitRender('.vault-dialog-file-type-hint', '.vault-dialog-file-type-hint-arrow', 'RIGHT', 10, 'TOP', 20, 'vaultDialogHint');
        this.showHintWaitRender('.vault-dialog-file-type-hint', '.vault-dialog-file-type-hint-text', 'RIGHT', 55, 'TOP', 35, 'vaultDialogHint');

        this.showHintWaitRender('.vault-dialog-multiple-hint', '.vault-dialog-multiple-hint-arrow', 'LEFT', 85, 'TOP', -15, 'vaultDialogHint');
        this.showHintWaitRender('.vault-dialog-multiple-hint', '.vault-dialog-multiple-hint-text', 'LEFT', 140, 'TOP', -40, 'vaultDialogHint');

        this.showHintWaitRender('.vault-dialog-entity-col-hint', '.vault-dialog-entity-col-hint-arrow', 'RIGHT', 10, 'TOP', 15, 'vaultDialogHint');
        this.showHintWaitRender('.vault-dialog-entity-col-hint', '.vault-dialog-entity-col-hint-text', 'RIGHT', 25, 'TOP', 75, 'vaultDialogHint');

        this.showHintWaitRender('.vault-dialog-average-hint', '.vault-dialog-average-hint-arrow', 'LEFT', 85, 'TOP', 5, 'vaultDialogHint');
        this.showHintWaitRender('.vault-dialog-average-hint', '.vault-dialog-average-hint-text', 'LEFT', 140, 'TOP', 25, 'vaultDialogHint');

        this.showHintWaitRender('.vault-dialog-download-hint', '.vault-dialog-download-hint-arrow', 'LEFT', 70, 'TOP', 5, 'vaultDialogHint');
        this.showHintWaitRender('.vault-dialog-download-hint', '.vault-dialog-download-hint-text', 'LEFT', 130, 'TOP', 30, 'vaultDialogHint');

        this.showHintWaitRender('.vault-dialog-settings-hint', '.vault-dialog-settings-hint-arrow', 'LEFT', 20, 'TOP', 55, 'vaultDialogHint');
        this.showHintWaitRender('.vault-dialog-settings-hint', '.vault-dialog-settings-hint-text', 'LEFT', -50, 'TOP', 125, 'vaultDialogHint');

        this.showHintWaitRender('.vault-dialog-close-hint', '.vault-dialog-close-hint-arrow', 'LEFT', 10, 'TOP', 55, 'vaultDialogHint');
        this.showHintWaitRender('.vault-dialog-close-hint', '.vault-dialog-close-hint-text', 'LEFT', -55, 'TOP', 125, 'vaultDialogHint');

        this.showHintWaitRender('.vault-dialog-export-hint', '.vault-dialog-export-hint-arrow', 'LEFT', 90, 'TOP', -5, 'vaultDialogHint');
        this.showHintWaitRender('.vault-dialog-export-hint', '.vault-dialog-export-hint-text', 'LEFT', 150, 'TOP', 25, 'vaultDialogHint');
    }

    public dataEditingAudit() {
        this.showHintWaitRender('.data-audit-title-hint', '.data-audit-title-hint-arrow', 'LEFT', 40, 'TOP', -65, 'data-editing-audit-hint');
        this.showHintWaitRender('.data-audit-title-hint', '.data-audit-title-hint-text', 'LEFT', -15, 'TOP', -125, 'data-editing-audit-hint');

        this.showHintWaitRender('.data-audit-export-hint', '.data-audit-export-hint-arrow', 'LEFT', -45, 'TOP', -45, 'data-editing-audit-hint');
        this.showHintWaitRender('.data-audit-export-hint', '.data-audit-export-hint-text', 'RIGHT', 40, 'TOP', -85, 'data-editing-audit-hint');

        this.showHintWaitRender('.data-audit-filter-hint', '.data-audit-filter-hint-arrow', 'LEFT', -15, 'TOP', -60, 'data-editing-audit-hint');
        this.showHintWaitRender('.data-audit-filter-hint', '.data-audit-filter-hint-text', 'RIGHT', -90, 'TOP', -85, 'data-editing-audit-hint');

        this.showHintWaitRender('.data-audit-date-hint', '.data-audit-date-hint-arrow', 'LEFT', 240, 'TOP', -45, 'data-editing-audit-hint');
        this.showHintWaitRender('.data-audit-date-hint', '.data-audit-date-hint-text', 'LEFT', 270, 'TOP', -95, 'data-editing-audit-hint');

        this.showHintWaitRender('.data-audit-entity-hint', '.data-audit-entity-hint-arrow', 'LEFT', 100, 'TOP', 5, 'data-editing-audit-hint');
        this.showHintWaitRender('.data-audit-entity-hint', '.data-audit-entity-hint-text', 'LEFT', 155, 'TOP', 40, 'data-editing-audit-hint');

        this.showHintWaitRender('.data-audit-location-hint', '.data-audit-location-hint-arrow', 'LEFT', 70, 'TOP', 5, 'data-editing-audit-hint');
        this.showHintWaitRender('.data-audit-location-hint', '.data-audit-location-hint-text', 'LEFT', 130, 'TOP', 40, 'data-editing-audit-hint');

        this.showHintWaitRender(
            '.data-audit-edited-on-hint',
            '.data-audit-edited-on-hint-arrow',
            'LEFT',
            120,
            'TOP',
            5,
            'data-editing-audit-hint');
        this.showHintWaitRender(
            '.data-audit-edited-on-hint',
            '.data-audit-edited-on-hint-text',
            'LEFT',
            175,
            'TOP',
            40,
            'data-editing-audit-hint');

        this.showHintWaitRender('.data-audit-edited-by-hint', '.data-audit-edited-by-hint-arrow', 'LEFT', 70, 'TOP', 5, 'data-editing-audit-hint');
        this.showHintWaitRender(
            '.data-audit-edited-by-hint',
            '.data-audit-edited-by-hint-text',
            'LEFT',
            130,
            'TOP',
            40,
            'data-editing-audit-hint');

        this.showHintWaitRender('.data-audit-comment-hint', '.data-audit-comment-hint-arrow', 'LEFT', 70, 'TOP', 5, 'data-editing-audit-hint');
        this.showHintWaitRender('.data-audit-comment-hint', '.data-audit-comment-hint-text', 'LEFT', 130, 'TOP', 40, 'data-editing-audit-hint');

        this.showHintWaitRender('.data-audit-actions-hint', '.data-audit-actions-hint-arrow', 'LEFT', 45, 'TOP', 30, 'data-editing-audit-hint');
        this.showHintWaitRender('.data-audit-actions-hint', '.data-audit-actions-hint-text', 'LEFT', -35, 'TOP', 95, 'data-editing-audit-hint');

        this.showHintWaitRender('.data-audit-item-hint', '.data-audit-item-hint-arrow', 'LEFT', 70, 'TOP', 10, 'data-editing-audit-hint');
        this.showHintWaitRender('.data-audit-item-hint', '.data-audit-item-hint-text', 'LEFT', 130, 'TOP', 40, 'data-editing-audit-hint');
    }

    public uptimeOverview() {
        this.showHintWaitRender('.uptime-title-hint', '.uptime-title-hint-arrow', 'LEFT', 40, 'TOP', -65, 'uptimeOverviewHint');
        this.showHintWaitRender('.uptime-title-hint', '.uptime-title-hint-text', 'LEFT', -15, 'TOP', -115, 'uptimeOverviewHint');

        this.showHintWaitRender('.uptime-download-hint', '.uptime-download-hint-arrow', 'LEFT', -55, 'TOP', -35, 'uptimeOverviewHint');
        this.showHintWaitRender('.uptime-download-hint', '.uptime-download-hint-text', 'RIGHT', 40, 'TOP', -55, 'uptimeOverviewHint');

        this.showHintWaitRender('.uptime-filter-hint', '.uptime-filter-hint-arrow', 'LEFT', -20, 'TOP', -60, 'uptimeOverviewHint');
        this.showHintWaitRender('.uptime-filter-hint', '.uptime-filter-hint-text', 'RIGHT', -45, 'TOP', -110, 'uptimeOverviewHint');

        this.showHintWaitRender('.uptime-loc-filter-hint', '.uptime-loc-filter-hint-arrow', 'LEFT', 140, 'TOP', -40, 'uptimeOverviewHint');
        this.showHintWaitRender('.uptime-loc-filter-hint', '.uptime-loc-filter-hint-text', 'LEFT', 170, 'TOP', -105, 'uptimeOverviewHint');

        this.showHintWaitRender(
            '.uptime-entity-filter-hint',
            '.uptime-entity-filter-hint-arrow',
            'LEFT',
            70,
            'TOP',
            10,
            'uptimeOverviewHint');
        this.showHintWaitRender(
            '.uptime-entity-filter-hint',
            '.uptime-entity-filter-hint-text',
            'LEFT',
            140,
            'TOP',
            40,
            'uptimeOverviewHint');

        this.showHintWaitRender('.uptime-date-filter-hint', '.uptime-date-filter-hint-arrow', 'LEFT', 40, 'TOP', 10, 'uptimeOverviewHint');
        this.showHintWaitRender('.uptime-date-filter-hint', '.uptime-date-filter-hint-text', 'LEFT', 100, 'TOP', 35, 'uptimeOverviewHint');

        this.showHintWaitRender('.uptime-location-hint', '.uptime-location-hint-arrow', 'LEFT', 30, 'TOP', 130, 'uptimeOverviewHint');
        this.showHintWaitRender('.uptime-location-hint', '.uptime-location-hint-text', 'LEFT', -10, 'TOP', 200, 'uptimeOverviewHint');

        this.showHintWaitRender('.uptime-entity-hint', '.uptime-entity-hint-arrow', 'LEFT', 30, 'TOP', 70, 'uptimeOverviewHint');
        this.showHintWaitRender('.uptime-entity-hint', '.uptime-entity-hint-text', 'LEFT', 75, 'TOP', 125, 'uptimeOverviewHint');

        this.showHintWaitRender('.uptime-average-hint', '.uptime-average-hint-arrow', 'LEFT', 35, 'TOP', 25, 'uptimeOverviewHint');
        this.showHintWaitRender('.uptime-average-hint', '.uptime-average-hint-text', 'LEFT', 80, 'TOP', 70, 'uptimeOverviewHint');

        this.showHintWaitRender('.uptime-day-hint', '.uptime-day-hint-arrow', 'LEFT', 30, 'TOP', 20, 'uptimeOverviewHint');
        this.showHintWaitRender('.uptime-day-hint', '.uptime-day-hint-text', 'LEFT', 85, 'TOP', 40, 'uptimeOverviewHint');
    }

    public dailySummaryOverview() {
        this.showHintWaitRender('.daily-locations-hint', '.daily-locations-hint-arrow', 'LEFT', 70, 'TOP', -50, 'dailySummaryOverviewHint');
        this.showHintWaitRender('.daily-locations-hint', '.daily-locations-hint-text', 'LEFT', 130, 'TOP', -80, 'dailySummaryOverviewHint');

        this.showHintWaitRender('.daily-entity-hint', '.daily-entity-hint-arrow', 'LEFT', 230, 'TOP', -15, 'dailySummaryOverviewHint');
        this.showHintWaitRender('.daily-entity-hint', '.daily-entity-hint-text', 'LEFT', 290, 'TOP', -40, 'dailySummaryOverviewHint');

        this.showHintWaitRender('.daily-date-picker-hint', '.daily-date-picker-hint-arrow', 'RIGHT', 5, 'TOP', 5, 'dailySummaryOverviewHint');
        this.showHintWaitRender('.daily-date-picker-hint', '.daily-date-picker-hint-text', 'RIGHT', 55, 'TOP', 25, 'dailySummaryOverviewHint');

        this.showHintWaitRender('.daily-title-hint', '.daily-title-hint-arrow', 'LEFT', 100, 'TOP', 20, 'dailySummaryOverviewHint');
        this.showHintWaitRender('.daily-title-hint', '.daily-title-hint-text', 'LEFT', 130, 'TOP', 65, 'dailySummaryOverviewHint');

        this.showHintWaitRender('.daily-hide-tile-hint', '.daily-hide-tile-hint-arrow', 'RIGHT', 1, 'TOP', 35, 'dailySummaryOverviewHint', 'vw');
        this.showHintWaitRender('.daily-hide-tile-hint', '.daily-hide-tile-hint-text', 'RIGHT', 2, 'TOP', 95, 'dailySummaryOverviewHint', 'vw');

        this.showHintWaitRender('.daily-download-hint', '.daily-download-hint-arrow', 'RIGHT', 15, 'TOP', 5, 'dailySummaryOverviewHint');
        this.showHintWaitRender('.daily-download-hint', '.daily-download-hint-text', 'RIGHT', 55, 'TOP', 30, 'dailySummaryOverviewHint');

        this.showHintWaitRender('.daily-tiles-hint', '.daily-tiles-hint-arrow', 'LEFT', 100, 'TOP', 25, 'dailySummaryOverviewHint');
        this.showHintWaitRender('.daily-tiles-hint', '.daily-tiles-hint-text', 'LEFT', 165, 'TOP', 50, 'dailySummaryOverviewHint');

        this.showHintWaitRender('.daily-table-hint', '.daily-table-hint-arrow', 'LEFT', 230, 'TOP', 30, 'dailySummaryOverviewHint');
        this.showHintWaitRender('.daily-table-hint', '.daily-table-hint-text', 'LEFT', 80, 'TOP', -15, 'dailySummaryOverviewHint');

        this.showHintWaitRender('.daily-values-hint', '.daily-values-hint-arrow', 'LEFT', 350, 'TOP', 30, 'dailySummaryOverviewHint');
        this.showHintWaitRender('.daily-values-hint', '.daily-values-hint-text', 'LEFT', 410, 'TOP', 40, 'dailySummaryOverviewHint');
    }

    public dailySummaryDetails() {
        this.showpageHintIcon(
            '.dailySummaryDetailsFilterHint',
            '.dailySummaryDetailsFilterHintArrow',
            'RIGHT',
            -20,
            'TOP',
            30,
        );
        this.showpageHintIcon(
            '.dailySummaryDetailsFilterHint',
            '.dailySummaryDetailsFilterHintText',
            'RIGHT',
            -15,
            'TOP',
            100,
        );

        this.showpageHintIcon(
            '.dailySummaryDetailsDownloadHint',
            '.dailySummaryDetailsDownloadHintArrow',
            'RIGHT',
            15,
            'TOP',
            0,
        );
        this.showpageHintIcon(
            '.dailySummaryDetailsDownloadHint',
            '.dailySummaryDetailsDownloadHintText',
            'RIGHT',
            80,
            'TOP',
            30,
        );
    }

    public uptimeDetails() {
        this.showpageHintIcon('.uptimeDetailsFilterHint', '.uptimeDetailsFilterHintArrow', 'RIGHT', -20, 'TOP', 30);
        this.showpageHintIcon('.uptimeDetailsFilterHint', '.uptimeDetailsFilterHintText', 'RIGHT', -15, 'TOP', 100);

        this.showpageHintIcon('.uptimeDetailsDownloadHint', '.uptimeDetailsDownloadHintArrow', 'RIGHT', 15, 'TOP', 0);
        this.showpageHintIcon('.uptimeDetailsDownloadHint', '.uptimeDetailsDownloadHintText', 'RIGHT', 80, 'TOP', 30);
    }

    public adminScreen() {
        this.showpageHintIcon('#addNewUser', '.addNewUserHintArrow', 'LEFT', 15, 'TOP', -65);
        this.showpageHintIcon('#addNewUser', '.addNewUserHintText', 'LEFT', -10, 'TOP', -90);
    }

    public adminDetails() {
        this.showpageHintIcon('.userDetailsHint', '.userDetailsHintArrow', 'LEFT', 15, 'TOP', 40);
        this.showpageHintIcon('.userDetailsHint', '.userDetailsHintText', 'LEFT', -10, 'TOP', 110);

        this.showpageHintIcon('.assignRolesUserHint', '.assignRolesUserHintArrow', 'LEFT', 15, 'TOP', -65);
        this.showpageHintIcon('.assignRolesUserHint', '.assignRolesUserHintText', 'LEFT', -10, 'TOP', -90);

        this.showpageHintIcon('.assignCustomersUserHint', '.assignCustomersUserHintArrow', 'LEFT', 15, 'TOP', 40);
        this.showpageHintIcon('.assignCustomersUserHint', '.assignCustomersUserHintText', 'LEFT', -10, 'TOP', 110);

        this.showpageHintIcon('.assignLocationsUserHint', '.assignLocationsUserHintArrow', 'LEFT', 15, 'TOP', -65);
        this.showpageHintIcon('.assignLocationsUserHint', '.assignLocationsUserHintText', 'LEFT', -10, 'TOP', -110);

        this.showpageHintIcon('.assignFeaturesUserHint', '.assignFeaturesUserHintArrow', 'LEFT', 15, 'TOP', 40);
        this.showpageHintIcon('.assignFeaturesUserHint', '.assignFeaturesUserHintText', 'LEFT', -10, 'TOP', 110);
    }

    public customerEditorScreen() {
        this.showpageHintIcon('#addNewUser', '.addNewUserHintArrow', 'LEFT', 15, 'TOP', -65);
        this.showpageHintIcon('#addNewUser', '.addNewUserHintText', 'LEFT', -10, 'TOP', -90);
    }

    public customerEditorDetails() {
        this.showpageHintIcon('.userDetailsHint', '.userDetailsHintArrow', 'LEFT', 15, 'TOP', 40);
        this.showpageHintIcon('.userDetailsHint', '.userDetailsHintText', 'LEFT', -10, 'TOP', 110);

        this.showpageHintIcon('.assignRolesUserHint', '.assignRolesUserHintArrow', 'LEFT', 15, 'TOP', -65);
        this.showpageHintIcon('.assignRolesUserHint', '.assignRolesUserHintText', 'LEFT', -10, 'TOP', -90);

        this.showpageHintIcon('.assignCustomersUserHint', '.assignCustomersUserHintArrow', 'LEFT', 15, 'TOP', 40);
        this.showpageHintIcon('.assignCustomersUserHint', '.assignCustomersUserHintText', 'LEFT', -10, 'TOP', 110);

        this.showpageHintIcon('.assignLocationsUserHint', '.assignLocationsUserHintArrow', 'LEFT', 15, 'TOP', -65);
        this.showpageHintIcon('.assignLocationsUserHint', '.assignLocationsUserHintText', 'LEFT', -10, 'TOP', -110);

        this.showpageHintIcon('.assignFeaturesUserHint', '.assignFeaturesUserHintArrow', 'LEFT', 15, 'TOP', 40);
        this.showpageHintIcon('.assignFeaturesUserHint', '.assignFeaturesUserHintText', 'LEFT', -10, 'TOP', 110);
    }

    public notificationDashboardReport() {
        this.showHintWaitRender('.nd-hint', '.notification-dashboard-hint-arrow', 'LEFT', 94, 'TOP', -70, 'notificationDashBoardOverlay');
        this.showHintWaitRender('.nd-hint', '.notification-dashboard-hint-text', 'LEFT', 150, 'TOP', -90, 'notificationDashBoardOverlay');

        this.showHintWaitRender('.nd-hint-add_notification', '.add_notification-hint-arrow', 'LEFT', 20, 'TOP', -55, 'notificationDashBoardOverlay');
        this.showHintWaitRender('.nd-hint-add_notification', '.add_notification-hint-text', 'LEFT', 20, 'TOP', -67, 'notificationDashBoardOverlay');

        this.showHintWaitRender('.nd-hint-filter_notification', '.filter_notification-hint-arrow', 'LEFT', -10, 'TOP', 30, 'notificationDashBoardOverlay');
        this.showHintWaitRender('.nd-hint-filter_notification', '.filter_notification-hint-text', 'LEFT', -125, 'TOP', 110, 'notificationDashBoardOverlay');

        this.showHintWaitRender('.nd-hint-name_notification', '.Name-filter_notification-hint-arrow', 'LEFT', 50, 'TOP', -10, 'notificationDashBoardOverlay');
        this.showHintWaitRender('.nd-hint-name_notification', '.Name-filter_notification-hint-text', 'LEFT', 110, 'TOP', -15, 'notificationDashBoardOverlay');

        this.showHintWaitRender('.nd-hint-name_notification', '.users_notification-hint-arrow', 'LEFT', 120, 'TOP', 60, 'notificationDashBoardOverlay');
        this.showHintWaitRender('.nd-hint-name_notification', '.users_notification-hint-text', 'LEFT', 185, 'TOP', 70, 'notificationDashBoardOverlay');

        this.showHintWaitRender('.nd-hint-name_notification', '.alarm_type_notification-hint-arrow', 'LEFT', 160, 'TOP', 125, 'notificationDashBoardOverlay');
        this.showHintWaitRender('.nd-hint-name_notification', '.alarm_type_notification-hint-text', 'LEFT', 220, 'TOP', 150, 'notificationDashBoardOverlay');

        this.showHintWaitRender('.nd-hint-location_notification', '.location_Filter_Type_notification-hint-arrow', 'LEFT', 120, 'TOP', 30, 'notificationDashBoardOverlay');
        this.showHintWaitRender('.nd-hint-location_notification', '.location_Filter_Type_notification-hint-text', 'LEFT', 110, 'TOP', 100, 'notificationDashBoardOverlay');

        this.showHintWaitRender('.nd-buttons-actions', '.reset_notification-hint-arrow', 'LEFT', -30, 'TOP', 20, 'notificationDashBoardOverlay');
        this.showHintWaitRender('.nd-buttons-actions', '.reset_notification-hint-text', 'LEFT', -130, 'TOP', 80, 'notificationDashBoardOverlay');

        this.showHintWaitRender('.nd-buttons-actions', '.apply_notification-hint-arrow', 'LEFT', 55, 'TOP', -55, 'notificationDashBoardOverlay');
        this.showHintWaitRender('.nd-buttons-actions', '.apply_notification-hint-text', 'LEFT', -140, 'TOP', -60, 'notificationDashBoardOverlay');

        this.showHintWaitRender('.nd-table-name', '.Name_notification-hint-arrow', 'LEFT', 70, 'TOP', -10, 'notificationDashBoardOverlay');
        this.showHintWaitRender('.nd-table-name', '.Name_notification-hint-text', 'LEFT', 125, 'TOP', 0, 'notificationDashBoardOverlay');

        this.showHintWaitRender('.nd-table-description', '.Description_notification-hint-arrow', 'LEFT', 80, 'TOP', -10, 'notificationDashBoardOverlay');
        this.showHintWaitRender('.nd-table-description', '.Description_notification-hint-text', 'LEFT', 140, 'TOP', 0, 'notificationDashBoardOverlay');

        this.showHintWaitRender('.nd-table-type', '.Type_notification-hint-arrow', 'LEFT', 40, 'TOP', -10, 'notificationDashBoardOverlay');
        this.showHintWaitRender('.nd-table-type', '.Type_notification-hint-text', 'LEFT', 100, 'TOP', -15, 'notificationDashBoardOverlay');

        this.showHintWaitRender('.nd-table-list', '.list_notification-hint-arrow', 'LEFT', 0, 'TOP', 0, 'notificationDashBoardOverlay');
        this.showHintWaitRender('.nd-table-list', '.list_notification-hint-text', 'LEFT', 0, 'TOP', 0, 'notificationDashBoardOverlay');

        this.showHintWaitRender('.nd-table-enable', '.enable_notification-hint-arrow', 'LEFT', 0, 'TOP', -40, 'notificationDashBoardOverlay');
        this.showHintWaitRender('.nd-table-enable', '.enable_notification-hint-text', 'LEFT', 60, 'TOP', -80, 'notificationDashBoardOverlay');

        this.showHintWaitRender('.nd-table-edit', '.edit_notification-hint-arrow', 'LEFT', -30, 'TOP', 20, 'notificationDashBoardOverlay');
        this.showHintWaitRender('.nd-table-edit', '.edit_notification-hint-text', 'LEFT', -70, 'TOP', 65, 'notificationDashBoardOverlay');

        this.showHintWaitRender('.nd-table-delete', '.delete_notification-hint-arrow', 'LEFT', 30, 'TOP', 20, 'notificationDashBoardOverlay');
        this.showHintWaitRender('.nd-table-delete', '.delete_notification-hint-text', 'LEFT', 75, 'TOP', 60, 'notificationDashBoardOverlay');
    }

    public notificationDashboardReportStandard() {
        this.showHintWaitRender('.alarm-title-bar', '.nd-common-Name-hint-arrow', 'LEFT', 30, 'TOP', 20, 'nd-common-locations');
        this.showHintWaitRender('.alarm-title-bar', '.nd-common-Name-hint-text', 'LEFT', 30, 'TOP', 10, 'nd-common-locations');

        this.showHintWaitRender('.nd-standard-description', '.nd-common-Description-hint-arrow', 'LEFT', 30, 'TOP', -40, 'nd-common-locations');
        this.showHintWaitRender('.nd-standard-description', '.nd-common-Description-hint-text', 'LEFT', 30, 'TOP', -70, 'nd-common-locations');

        this.showHintWaitRender('.nd-standard-enable', '.nd-common-Enable-hint-arrow', 'LEFT', -50, 'TOP', -30, 'nd-common-locations');
        this.showHintWaitRender('.nd-standard-enable', '.nd-common-Enable-hint-text', 'LEFT', -250, 'TOP', -50, 'nd-common-locations');

        this.showHintWaitRender('.search-icon', '.nd-common-Search-hint-arrow', 'LEFT', 30, 'TOP', -40, 'nd-common-locations');
        this.showHintWaitRender('.search-icon', '.nd-common-Search-hint-text', 'LEFT', 0, 'TOP', -50, 'nd-common-locations');

        this.showHintWaitRender('.nd-location-select', '.nd-common-Location_Group-hint-arrow', 'LEFT', 30, 'TOP', 20, 'nd-common-locations');
        this.showHintWaitRender('.nd-location-select', '.nd-common-Location_Group-hint-text', 'LEFT', -30, 'TOP', 65, 'nd-common-locations');

        this.showHintWaitRender('.location-list', '.nd-common-Locations-hint-arrow', 'LEFT', 30, 'TOP', 20, 'nd-common-locations');
        this.showHintWaitRender('.location-list', '.nd-common-Locations-hint-text', 'LEFT', 90, 'TOP', 30, 'nd-common-locations');

        this.showHintWaitRender('.footer-next', '.nd-common-Next-hint-arrow', 'LEFT', 70, 'TOP', -30, 'nd-common-locations');
        this.showHintWaitRender('.footer-next  ', '.nd-common-Next-hint-text', 'LEFT', 130, 'TOP', -30, 'nd-common-locations');
    }

    public notificationDashboardReportAnsrAlarms() {
        this.showHintWaitRender('.alarm-title-bar', '.nd-ANSR-alarms-Name-hint-arrow', 'LEFT', 30, 'TOP', 20, 'nd-ANSR-alarms');
        this.showHintWaitRender('.alarm-title-bar', '.nd-ANSR-alarms-Name-hint-text', 'LEFT', 30, 'TOP', 10, 'nd-ANSR-alarms');

        this.showHintWaitRender('.nd-standard-description', '.nd-ANSR-alarms-Description-hint-arrow', 'LEFT', 30, 'TOP', -40, 'nd-ANSR-alarms');
        this.showHintWaitRender('.nd-standard-description', '.nd-ANSR-alarms-Description-hint-text', 'LEFT', 30, 'TOP', -70, 'nd-ANSR-alarms');

        this.showHintWaitRender('.nd-standard-enable', '.nd-ANSR-alarms-Enable-hint-arrow', 'LEFT', -50, 'TOP', -30, 'nd-ANSR-alarms');
        this.showHintWaitRender('.nd-standard-enable', '.nd-ANSR-alarms-Enable-hint-text', 'LEFT', -250, 'TOP', -50, 'nd-ANSR-alarms');


        this.showHintWaitRender('.nd-ANSR-source', '.nd-ANSR-alarms-Source_Alarm-hint-arrow', 'LEFT', 100, 'TOP', -20, 'nd-ANSR-alarms');
        this.showHintWaitRender('.nd-ANSR-source', '.nd-ANSR-alarms-Source_Alarm-hint-text', 'LEFT', 170, 'TOP', -20, 'nd-ANSR-alarms');

        this.showHintWaitRender('.nd-ANSR-entity', '.nd-ANSR-alarms-Entity-hint-arrow', 'LEFT', 60, 'TOP', -30, 'nd-ANSR-alarms');
        this.showHintWaitRender('.nd-ANSR-entity', '.nd-ANSR-alarms-Entity-hint-text', 'LEFT', 70, 'TOP', -30, 'nd-ANSR-alarms');

        this.showHintWaitRender('.nd-ANSR-variable', '.nd-ANSR-alarms-Variable-hint-arrow', 'LEFT', 70, 'TOP', -30, 'nd-ANSR-alarms');
        this.showHintWaitRender('.nd-ANSR-variable', '.nd-ANSR-alarms-Variable-hint-text', 'LEFT', 130, 'TOP', -30, 'nd-ANSR-alarms');

        this.showHintWaitRender('.nd-ANSR-add', '.nd-ANSR-alarms-Add-hint-arrow', 'LEFT', -40, 'TOP', -40, 'nd-ANSR-alarms');
        this.showHintWaitRender('.nd-ANSR-add', '.nd-ANSR-alarms-Add-hint-text', 'LEFT', -120, 'TOP', -110, 'nd-ANSR-alarms');

        this.showHintWaitRender('.nd-ANSR-equation', '.nd-ANSR-alarms-equation-hint-arrow', 'LEFT', 110, 'TOP', -0, 'nd-ANSR-alarms');
        this.showHintWaitRender('.nd-ANSR-equation', '.nd-ANSR-alarms-equation-hint-text', 'LEFT', 170, 'TOP', -0, 'nd-ANSR-alarms');

        this.showHintWaitRender('.footer-back', '.nd-ANSR-alarms-Back-hint-arrow', 'LEFT', -40, 'TOP', 0, 'nd-ANSR-alarms');
        this.showHintWaitRender('.footer-back', '.nd-ANSR-alarms-Back-hint-text', 'LEFT', -210, 'TOP', 30, 'nd-ANSR-alarms');

        this.showHintWaitRender('.footer-next', '.nd-ANSR-alarms-Next-hint-arrow', 'LEFT', 70, 'TOP', 0, 'nd-ANSR-alarms');
        this.showHintWaitRender('.footer-next  ', '.nd-ANSR-alarms-Next-hint-text', 'LEFT', 130, 'TOP', 30, 'nd-ANSR-alarms');
    }
    public notificationDashboardReportAlarmsConfirm(pageType: PAGE_TYPE) {
        this.showHintWaitRender('.alarm-title-bar', '.nd-alarms-summary-name-hint-arrow', 'LEFT', 30, 'TOP', 20, 'nd-alarms-confirm');
        this.showHintWaitRender('.alarm-title-bar', '.nd-alarms-summary-name-hint-text', 'LEFT', 30, 'TOP', 10, 'nd-alarms-confirm');

        this.showHintWaitRender('.nd-standard-description', '.nd-alarms-summary-description-hint-arrow', 'LEFT', 30, 'TOP', -40, 'nd-alarms-confirm');
        this.showHintWaitRender('.nd-standard-description', '.nd-alarms-summary-description-hint-text', 'LEFT', 30, 'TOP', -70, 'nd-alarms-confirm');

        this.showHintWaitRender('.nd-summary-location', '.nd-alarms-summary-location-hint-arrow', 'LEFT', 40, 'TOP', 25, 'nd-alarms-confirm');
        this.showHintWaitRender('.nd-summary-location', '.nd-alarms-summary-location-hint-text', 'LEFT', 0, 'TOP', 90, 'nd-alarms-confirm');

        this.showHintWaitRender('.nd-summary-levels', '.nd-alarms-summary-levels-hint-arrow', 'LEFT', 40, 'TOP', 25, 'nd-alarms-confirm');
        this.showHintWaitRender('.nd-summary-alarm', '.nd-alarms-summary-alarm-hint-arrow', 'LEFT', 150, 'TOP', 25, 'nd-alarms-confirm');
        switch (pageType) {
            case PAGE_TYPE.ansr:
                this.angularDocument.querySelector('.nd-ANSR-alarms-summary-alarm-hint-text').classList.remove('hide');
                this.showHintWaitRender('.nd-summary-alarm', '.nd-ANSR-alarms-summary-alarm-hint-text', 'LEFT', 70, 'TOP', 90, 'nd-alarms-confirm');

                this.angularDocument.querySelector('.nd-alarms-summary-levels-hint-text').classList.remove('hide');
                this.showHintWaitRender('.nd-summary-levels', '.nd-alarms-summary-levels-hint-text', 'LEFT', 0, 'TOP', 90, 'nd-alarms-confirm');

                // Make sure other items are hidden. If you don't refresh and change between alarm pages
                // you get overlapping text from previous pages still showing
                this.angularDocument.querySelector('.nd-daily-alarms-summary-alarm-hint-text').classList.add('hide');
                this.angularDocument.querySelector('.nd-standard-alarms-summary-alarm-hint-text').classList.add('hide');
                this.angularDocument.querySelector('.nd-daily-alarms-summary-levels-hint-text').classList.add('hide');
                break;
            case PAGE_TYPE.daily:
                this.angularDocument.querySelector('.nd-daily-alarms-summary-alarm-hint-text').classList.remove('hide');
                this.showHintWaitRender('.nd-summary-alarm', '.nd-daily-alarms-summary-alarm-hint-text', 'LEFT', 70, 'TOP', 90, 'nd-alarms-confirm');

                this.angularDocument.querySelector('.nd-daily-alarms-summary-levels-hint-text').classList.remove('hide');
                this.showHintWaitRender('.nd-summary-levels', '.nd-daily-alarms-summary-levels-hint-text', 'LEFT', 0, 'TOP', 90, 'nd-alarms-confirm');

                this.angularDocument.querySelector('.nd-ANSR-alarms-summary-alarm-hint-text').classList.add('hide');
                this.angularDocument.querySelector('.nd-standard-alarms-summary-alarm-hint-text').classList.add('hide');
                this.angularDocument.querySelector('.nd-alarms-summary-levels-hint-text').classList.add('hide');
                break;
            case PAGE_TYPE.standard:
                this.angularDocument.querySelector('.nd-standard-alarms-summary-alarm-hint-text').classList.remove('hide');
                this.showHintWaitRender('.nd-summary-alarm', '.nd-standard-alarms-summary-alarm-hint-text', 'LEFT', 70, 'TOP', 90, 'nd-alarms-confirm');

                this.angularDocument.querySelector('.nd-alarms-summary-levels-hint-text').classList.remove('hide');
                this.showHintWaitRender('.nd-summary-levels', '.nd-alarms-summary-levels-hint-text', 'LEFT', 0, 'TOP', 90, 'nd-alarms-confirm');

                this.angularDocument.querySelector('.nd-ANSR-alarms-summary-alarm-hint-text').classList.add('hide');
                this.angularDocument.querySelector('.nd-daily-alarms-summary-alarm-hint-text').classList.add('hide');
                this.angularDocument.querySelector('.nd-daily-alarms-summary-levels-hint-text').classList.add('hide');
                break;
            default:
                break;
        }
    }

    public notificationDashboardReportDaily() {
        this.showHintWaitRender('.alarm-title-bar', '.nd-daily-report-Name-hint-arrow', 'LEFT', 30, 'TOP', 20, 'nd-daily-report');
        this.showHintWaitRender('.alarm-title-bar', '.nd-daily-report-Name-hint-text', 'LEFT', 30, 'TOP', 10, 'nd-daily-report');

        this.showHintWaitRender('.nd-standard-description', '.nd-daily-report-Description-hint-arrow', 'LEFT', 30, 'TOP', -40, 'nd-daily-report');
        this.showHintWaitRender('.nd-standard-description', '.nd-daily-report-Description-hint-text', 'LEFT', 30, 'TOP', -70, 'nd-daily-report');

        this.showHintWaitRender('.nd-standard-enable', '.nd-daily-report-Enable-hint-arrow', 'LEFT', -50, 'TOP', -30, 'nd-daily-report');
        this.showHintWaitRender('.nd-standard-enable', '.nd-daily-report-Enable-hint-text', 'LEFT', -250, 'TOP', -50, 'nd-daily-report');

        this.showHintWaitRender('.nd-standard-communication', '.nd-daily-report-communication-hint-arrow', 'LEFT', 50, 'TOP', -40, 'nd-daily-report');
        this.showHintWaitRender('.nd-standard-communication', '.nd-daily-report-communication-hint-text', 'LEFT', 120, 'TOP', -70, 'nd-daily-report');

        this.showHintWaitRender('.nd-standard-low_battery', '.nd-daily-report-low_battery-hint-arrow', 'LEFT', 50, 'TOP', 10, 'nd-daily-report');
        this.showHintWaitRender('.nd-standard-low_battery', '.nd-daily-report-low_battery-hint-text', 'LEFT', 110, 'TOP', 50, 'nd-daily-report');

        this.showHintWaitRender('.nd-standard-select_days', '.nd-daily-report-select_days-hint-arrow', 'LEFT', 90, 'TOP', -40, 'nd-daily-report');
        this.showHintWaitRender('.nd-standard-select_days', '.nd-daily-report-select_days-hint-text', 'LEFT', 150, 'TOP', -50, 'nd-daily-report');

        this.showHintWaitRender('.nd-standard-report_at', '.nd-daily-report-report_at-hint-arrow', 'LEFT', 40, 'TOP', -25, 'nd-daily-report');
        this.showHintWaitRender('.nd-standard-report_at', '.nd-daily-report-report_at-hint-text', 'LEFT', 110, 'TOP', 50, 'nd-daily-report');

        this.showHintWaitRender('.footer-back', '.nd-daily-report-Back-hint-arrow', 'LEFT', -40, 'TOP', 0, 'nd-daily-report');
        this.showHintWaitRender('.footer-back', '.nd-daily-report-Back-hint-text', 'LEFT', -210, 'TOP', 30, 'nd-daily-report');

        this.showHintWaitRender('.footer-next', '.nd-daily-report-Next-hint-arrow', 'LEFT', 70, 'TOP', 0, 'nd-daily-report');
        this.showHintWaitRender('.footer-next  ', '.nd-daily-report-Next-hint-text', 'LEFT', 130, 'TOP', 30, 'nd-daily-report');
    }

    public notificationDashboardReportStandardAlarms() {
        this.showHintWaitRender('.alarm-title-bar', '.nd-common-alarms-Name-hint-arrow', 'LEFT', 30, 'TOP', 20, 'nd-common-alarms');
        this.showHintWaitRender('.alarm-title-bar', '.nd-common-alarms-Name-hint-text', 'LEFT', 30, 'TOP', 10, 'nd-common-alarms');

        this.showHintWaitRender('.nd-standard-description', '.nd-common-alarms-Description-hint-arrow', 'LEFT', 30, 'TOP', -40, 'nd-common-alarms');
        this.showHintWaitRender('.nd-standard-description', '.nd-common-alarms-Description-hint-text', 'LEFT', 30, 'TOP', -70, 'nd-common-alarms');

        this.showHintWaitRender('.nd-standard-enable', '.nd-common-alarms-Enable-hint-arrow', 'LEFT', -50, 'TOP', -30, 'nd-common-alarms');
        this.showHintWaitRender('.nd-standard-enable', '.nd-common-alarms-Enable-hint-text', 'LEFT', -250, 'TOP', -50, 'nd-common-alarms');

        this.showHintWaitRender('.search-icon', '.nd-common-alarms-Search-hint-arrow', 'LEFT', 30, 'TOP', -40, 'nd-common-alarms');
        this.showHintWaitRender('.search-icon', '.nd-common-alarms-Search-hint-text', 'LEFT', 0, 'TOP', -50, 'nd-common-alarms');

        this.showHintWaitRender('.firstrow', '.nd-common-alarms-Alarm_List-hint-arrow', 'LEFT', 30, 'TOP', 20, 'nd-common-alarms');
        this.showHintWaitRender('.firstrow', '.nd-common-alarms-Alarm_List-hint-text', 'LEFT', -30, 'TOP', 65, 'nd-common-alarms');

        this.showHintWaitRender('.footer-back', '.nd-common-alarms-Back-hint-arrow', 'LEFT', -40, 'TOP', 0, 'nd-common-alarms');
        this.showHintWaitRender('.footer-back', '.nd-common-alarms-Back-hint-text', 'LEFT', -210, 'TOP', 30, 'nd-common-alarms');

        this.showHintWaitRender('.footer-next', '.nd-common-alarms-Next-hint-arrow', 'LEFT', 70, 'TOP', 0, 'nd-common-alarms');
        this.showHintWaitRender('.footer-next', '.nd-common-alarms-Next-hint-text', 'LEFT', 130, 'TOP', 30, 'nd-common-alarms');
    }

    public notificationDashboardReportStandardNotificationTree() {
        this.showHintWaitRender('.alarm-title-bar', '.nd-notification-tree-Name-hint-arrow', 'LEFT', 30, 'TOP', 20, 'nd-notification-tree');
        this.showHintWaitRender('.alarm-title-bar', '.nd-notification-tree-Name-hint-text', 'LEFT', 30, 'TOP', 10, 'nd-notification-tree');

        this.showHintWaitRender('.nd-standard-description', '.nd-notification-tree-Description-hint-arrow', 'LEFT', 30, 'TOP', -40, 'nd-notification-tree');
        this.showHintWaitRender('.nd-standard-description', '.nd-notification-tree-Description-hint-text', 'LEFT', 30, 'TOP', -70, 'nd-notification-tree');

        this.showHintWaitRender('.nd-standard-enable', '.nd-notification-tree-Enable-hint-arrow', 'LEFT', -50, 'TOP', -30, 'nd-notification-tree');
        this.showHintWaitRender('.nd-standard-enable', '.nd-notification-tree-Enable-hint-text', 'LEFT', -250, 'TOP', -50, 'nd-notification-tree');

        this.showHintWaitRender('.std-add-level', '.nd-notification-tree-Add_Level-hint-arrow', 'LEFT', -50, 'TOP', -10, 'nd-notification-tree');
        this.showHintWaitRender('.std-add-level', '.nd-notification-tree-Add_Level-hint-text', 'LEFT', -250, 'TOP', -10, 'nd-notification-tree');

        this.showHintWaitRender('.std-name', '.nd-notification-tree-Level_Name-hint-arrow', 'LEFT', 30, 'TOP', 100, 'nd-notification-tree');
        this.showHintWaitRender('.std-name', '.nd-notification-tree-Level_Name-hint-text', 'LEFT', 0, 'TOP', 165, 'nd-notification-tree');

        this.showHintWaitRender('.std-delay', '.nd-notification-tree-Delay-hint-arrow', 'LEFT', -50, 'TOP', -10, 'nd-notification-tree');
        this.showHintWaitRender('.std-delay', '.nd-notification-tree-Delay-hint-text', 'LEFT', -250, 'TOP', -20, 'nd-notification-tree');

        this.showHintWaitRender('.std-users', '.nd-notification-tree-User_Count-hint-arrow', 'LEFT', -50, 'TOP', -10, 'nd-notification-tree');
        this.showHintWaitRender('.std-users', '.nd-notification-tree-User_Count-hint-text', 'LEFT', -250, 'TOP', -20, 'nd-notification-tree');

        this.showHintWaitRender('.std-enable', '.nd-notification-tree-Enable_Level-hint-arrow', 'LEFT', -50, 'TOP', -10, 'nd-notification-tree');
        this.showHintWaitRender('.std-enable', '.nd-notification-tree-Enable_Level-hint-text', 'LEFT', -250, 'TOP', -20, 'nd-notification-tree');

        this.showHintWaitRender('.std-edit', '.nd-notification-tree-Edit-hint-arrow', 'LEFT', 0, 'TOP', -50, 'nd-notification-tree');
        this.showHintWaitRender('.std-edit', '.nd-notification-tree-Edit-hint-text', 'LEFT', -35, 'TOP', -75, 'nd-notification-tree');

        this.showHintWaitRender('.std-delete', '.nd-notification-tree-delete-hint-arrow', 'LEFT', 20, 'TOP', -50, 'nd-notification-tree');
        this.showHintWaitRender('.std-delete', '.nd-notification-tree-delete-hint-text', 'LEFT', 40, 'TOP', -60, 'nd-notification-tree');

        this.showHintWaitRender('.footer-back', '.nd-notification-tree-Back-hint-arrow', 'LEFT', -40, 'TOP', 0, 'nd-notification-tree');
        this.showHintWaitRender('.footer-back', '.nd-notification-tree-Back-hint-text', 'LEFT', -230, 'TOP', 30, 'nd-notification-tree');

        this.showHintWaitRender('.footer-next', '.nd-notification-tree-Next-hint-arrow', 'LEFT', 70, 'TOP', 0, 'nd-notification-tree');
        this.showHintWaitRender('.footer-next', '.nd-notification-tree-Next-hint-text', 'LEFT', 130, 'TOP', 30, 'nd-notification-tree');
    }

    public notificationDashboardReportStandardValidation() {
        this.showHintWaitRender('.alarm-title-bar', '.nd-validation-Name-hint-arrow', 'LEFT', 30, 'TOP', 20, 'nd-validation');
        this.showHintWaitRender('.alarm-title-bar', '.nd-validation-Name-hint-text', 'LEFT', 30, 'TOP', 10, 'nd-validation');

        this.showHintWaitRender('.nd-standard-description', '.nd-validation-Description-hint-arrow', 'LEFT', 30, 'TOP', -40, 'nd-validation');
        this.showHintWaitRender('.nd-standard-description', '.nd-validation-Description-hint-text', 'LEFT', 30, 'TOP', -70, 'nd-validation');

        this.showHintWaitRender('.nd-standard-enable', '.nd-validation-Enable-hint-arrow', 'LEFT', -50, 'TOP', -30, 'nd-validation');
        this.showHintWaitRender('.nd-standard-enable', '.nd-validation-Enable-hint-text', 'LEFT', -250, 'TOP', -50, 'nd-validation');

        this.showHintWaitRender('.nd-errors', '.nd-validation-Location-hint-arrow', 'LEFT', 0, 'TOP', -55, 'nd-validation');
        this.showHintWaitRender('.nd-errors', '.nd-validation-Location-hint-text', 'LEFT', 0, 'TOP', -115, 'nd-validation');

        this.showHintWaitRender('.nd-text', '.nd-validation-List-hint-arrow', 'LEFT', 70, 'TOP', -40, 'nd-validation');
        this.showHintWaitRender('.nd-text', '.nd-validation-List-hint-text', 'LEFT', 70, 'TOP', -60, 'nd-validation');

        this.showHintWaitRender('.nd-remove', '.nd-validation-Remove_from_notification-hint-arrow', 'LEFT', 0, 'TOP', -50, 'nd-validation');
        this.showHintWaitRender('.nd-remove', '.nd-validation-Remove_from_notification-hint-text', 'LEFT', -200, 'TOP', -60, 'nd-validation');

        this.showHintWaitRender('.footer-back', '.nd-validation-Back-hint-arrow', 'LEFT', -40, 'TOP', 0, 'nd-validation');
        this.showHintWaitRender('.footer-back', '.nd-validation-Back-hint-text', 'LEFT', -245, 'TOP', 30, 'nd-validation');

        this.showHintWaitRender('.footer-next', '.nd-validation-Next-hint-arrow', 'LEFT', 70, 'TOP', 0, 'nd-validation');
        this.showHintWaitRender('.footer-next', '.nd-validation-Next-hint-text', 'LEFT', 130, 'TOP', 30, 'nd-validation');
    }

    public flowMonitoringReport() {
        this.showpageHintIcon('.fmrHintFFlowMonitoringReport', '.fmrHintFFlowMonitoringReportArrow', 'LEFT', 105, 'TOP', -70);
        this.showpageHintIcon('.fmrHintFFlowMonitoringReport', '.fmrHintFlowMonitoringReportText', 'LEFT', 150, 'TOP', -90);

        this.showpageHintIcon('.fmrHintLocationGroup', '.fmrHintLocationGroupArrow', 'LEFT', 235, 'TOP', -65);
        this.showpageHintIcon('.fmrHintLocationGroup', '.fmrHintLocationGroupText', 'LEFT', 280, 'TOP', -70);

        this.showpageHintIcon('.fmrHintLocationGroupList', '.fmrHintLocationGroupListArrow', 'LEFT', 550, 'TOP', -25);
        this.showpageHintIcon('.fmrHintLocationGroupList', '.fmrHintLocationGroupListText', 'LEFT', 245, 'TOP', -35);

        this.showpageHintIcon('.fmrHintIncludeInactiveLocations', '.fmrHintIncludeInactiveLocationsArrow', 'LEFT', 50, 'TOP', -70);
        this.showpageHintIcon('.fmrHintIncludeInactiveLocations', '.fmrHintIncludeInactiveLocationsText', 'LEFT', -50, 'TOP', -150);

        this.showpageHintIcon('.fmrHintSearch', '.fmrHintSearchArrow', 'LEFT', 50, 'TOP', -70);
        this.showpageHintIcon('.fmrHintSearch', '.fmrHintSearchText', 'LEFT', 0, 'TOP', -140);

        this.showpageHintIcon('.fmrHintDateRange', '.fmrHintDateRangeArrow', 'LEFT', 40, 'TOP', -20);
        this.showpageHintIcon('.fmrHintDateRange', '.fmrHintDateRangeText', 'LEFT', 100, 'TOP', -20);

        this.showpageHintIcon('.fmrHintDataAveraging', '.fmrHintDataAveragingArrow', 'LEFT', 40, 'TOP', -20);
        this.showpageHintIcon('.fmrHintDataAveraging', '.fmrHintDataAveragingText', 'LEFT', 100, 'TOP', -25);

        this.showpageHintIcon('.fmrHintGroupBy', '.fmrHintGroupByArrow', 'LEFT', 40, 'TOP', -15);
        this.showpageHintIcon('.fmrHintGroupBy', '.fmrHintGroupByText', 'LEFT', 100, 'TOP', -10);

        this.showpageHintIcon('.fmrHintEntities', '.fmrHintEntitiesArrow', 'LEFT', 40, 'TOP', -20);
        this.showpageHintIcon('.fmrHintEntities', '.fmrHintEntitiesText', 'LEFT', 100, 'TOP', -20);

        this.showpageHintIcon('.fmrHintSectionsToBeExported', '.fmrHintSectionsToBeExportedArrow', 'LEFT', 40, 'TOP', -20);
        this.showpageHintIcon('.fmrHintSectionsToBeExported', '.fmrHintSectionsToBeExportedText', 'LEFT', 100, 'TOP', -40);

        this.showpageHintIcon('.fmrHintExportFormat', '.fmrHintExportFormatArrow', 'LEFT', 40, 'TOP', 0);
        this.showpageHintIcon('.fmrHintExportFormat', '.fmrHintExportFormatText', 'LEFT', 100, 'TOP', 35);

        this.showpageHintIcon('.fmrHintSGOptions', '.fmrHintSGOptionsArrow', 'LEFT', 445, 'TOP', 20);
        this.showpageHintIcon('.fmrHintSGOptions', '.fmrHintSGOptionsText', 'LEFT', 500, 'TOP', 45);

        this.showpageHintIcon('.fmrHintExport', '.fmrHintExportArrow', 'LEFT', -60, 'TOP', -30);
        this.showpageHintIcon('.fmrHintExport', '.fmrHintExportText', 'LEFT', -490, 'TOP', -35);
    }
    /**
     * adjust page hint icon
     * @param selector
     * @param selectorHint
     * @param selectorHintPositionX
     * @param adjustselectorHintPositionX
     * @param selectorHintPositionY
     * @param adjustselectorHintPositionY
     */
    public showpageHintIcon(
        selector: string,
        selectorHint: string,
        selectorHintPositionX: string,
        adjustselectorHintPositionX: number,
        selectorHintPositionY: string,
        adjustselectorHintPositionY: number,
        noWidth = false,
    ): void {
        const routerOutletComponent: HTMLElement = <HTMLElement>this.angularDocument.querySelector(selectorHint);
        if (routerOutletComponent) {
            if (!this.angularDocument.querySelector(selector)) {
                routerOutletComponent.style.display = NONE;
                return;
            }

            const selectorPosition = this.angularDocument.querySelector(selector).getBoundingClientRect();
            const pageHeight: number = this.angularDocument.querySelector('body').clientHeight;
            const pageWidth: number = this.angularDocument.querySelector('body').clientWidth;

            if (selectorPosition.width === 0 && !noWidth) {
                routerOutletComponent.style.display = NONE;
                return;
            }
            routerOutletComponent.style.display = 'block';
            routerOutletComponent.style.top = 'unset';
            routerOutletComponent.style.bottom = 'unset';
            routerOutletComponent.style.left = 'unset';
            routerOutletComponent.style.right = 'unset';

            if (selectorHintPositionY === 'TOP') {
                routerOutletComponent.style.top = selectorPosition.top + adjustselectorHintPositionY + 'px';
            } else {
                routerOutletComponent.style.bottom = selectorPosition.bottom + adjustselectorHintPositionY + 'px';
            }
            if (selectorHintPositionX === 'RIGHT') {
                routerOutletComponent.style.right =
                    pageWidth - selectorPosition.left + adjustselectorHintPositionX + 'px';
            } else {
                routerOutletComponent.style.left = selectorPosition.left + adjustselectorHintPositionX + 'px';
            }
        }
    }

    public hideItems(selectors: string[]) {
        selectors.forEach(item => {
            const element: HTMLElement = <HTMLElement>this.angularDocument.querySelector(item);
            element.style.display = NONE;
        });
    }

    public showHintWaitRender(
        selector: string,
        selectorHint: string,
        selectorHintPositionX: string,
        adjustselectorHintPositionX: number,
        selectorHintPositionY: string,
        adjustselectorHintPositionY: number,
        selectedPage: string,
        xUnit = 'px', noWidth = false
    ): void {
        if (this.selectedHintPageName.getValue() === selectedPage && this.showpageHint.getValue()) {
            setTimeout(() => {
                this.showHintWaitRender(
                    selector,
                    selectorHint,
                    selectorHintPositionX,
                    adjustselectorHintPositionX,
                    selectorHintPositionY,
                    adjustselectorHintPositionY,
                    selectedPage,
                    xUnit,
                );
            }, 1000);
        }
        const routerOutletComponent: HTMLElement = <HTMLElement>this.angularDocument.querySelector(selectorHint);
        const parentElement = this.angularDocument.querySelector(selector);
        if (routerOutletComponent) {
            if (!parentElement) {
                routerOutletComponent.style.display = NONE;
                return;
            }

            const selectorPosition = parentElement.getBoundingClientRect();
            const pageHeight: number = this.angularDocument.querySelector('body').clientHeight;
            const pageWidth: number = this.angularDocument.querySelector('body').clientWidth;

            if (selectorPosition.width === 0 && !noWidth) {
                routerOutletComponent.style.display = NONE;
                return;
            }
            routerOutletComponent.style.display = 'block';
            routerOutletComponent.style.top = 'unset';
            routerOutletComponent.style.bottom = 'unset';
            routerOutletComponent.style.left = 'unset';
            routerOutletComponent.style.right = 'unset';
            const scrollTopValue = this.angularDocument.scrollingElement.scrollTop;
            if (selectorHintPositionY === 'TOP') {
                routerOutletComponent.style.top = selectorPosition.top + adjustselectorHintPositionY + scrollTopValue + 'px';
            } else {
                routerOutletComponent.style.bottom = selectorPosition.bottom + adjustselectorHintPositionY + 'px';
            }
            if (selectorHintPositionX === XALIGN.ANCHORRIGHT) {
                routerOutletComponent.style.left =
                    xUnit === 'px'
                        ? selectorPosition.right + adjustselectorHintPositionX + xUnit
                        : adjustselectorHintPositionX + xUnit;
            } else if (selectorHintPositionX === XALIGN.RIGHT) {
                routerOutletComponent.style.right =
                    xUnit === 'px'
                        ? pageWidth - selectorPosition.left + adjustselectorHintPositionX + xUnit
                        : adjustselectorHintPositionX + xUnit;
            } else {
                routerOutletComponent.style.left =
                    xUnit === 'px'
                        ? selectorPosition.left + adjustselectorHintPositionX + xUnit
                        : adjustselectorHintPositionX + xUnit;
            }
        }
    }

    public showHintWithoutHorizontalCalc(
        selector: string,
        selectorHint: string,
        selectorHintPositionX: string,
        adjustselectorHintPositionX: number,
        selectorHintPositionY: string,
        adjustselectorHintPositionY: number,
    ): void {
        const routerOutletComponent: HTMLElement = <HTMLElement>this.angularDocument.querySelector(selectorHint);
        if (routerOutletComponent) {
            if (!this.angularDocument.querySelector(selector)) {
                routerOutletComponent.style.display = NONE;
                return;
            }

            const selectorPosition = this.angularDocument.querySelector(selector).getBoundingClientRect();
            const pageHeight: number = this.angularDocument.querySelector('body').clientHeight;
            const pageWidth: number = this.angularDocument.querySelector('body').clientWidth;

            if (selectorPosition.width === 0) {
                routerOutletComponent.style.display = NONE;
                return;
            }
            routerOutletComponent.style.display = 'block';
            routerOutletComponent.style.top = 'unset';
            routerOutletComponent.style.bottom = 'unset';
            routerOutletComponent.style.left = 'unset';
            routerOutletComponent.style.right = 'unset';

            if (selectorHintPositionY === 'TOP') {
                routerOutletComponent.style.top = selectorPosition.top + adjustselectorHintPositionY + 'px';
            } else {
                routerOutletComponent.style.bottom = selectorPosition.bottom + adjustselectorHintPositionY + 'px';
            }
            if (selectorHintPositionX === 'RIGHT') {
                routerOutletComponent.style.right = adjustselectorHintPositionX + 'px';
            } else {
                routerOutletComponent.style.left = adjustselectorHintPositionX + 'px';
            }
        }
    }

    public showHintWithoutCalc(
        selector: string,
        selectorHint: string,
        selectorHintPositionX: string,
        adjustselectorHintPositionX: number,
        selectorHintPositionY: string,
        adjustselectorHintPositionY: number,
        noWidth = false,
        xUnit = 'px',
    ): void {
        const routerOutletComponent: HTMLElement = <HTMLElement>this.angularDocument.querySelector(selectorHint);
        if (routerOutletComponent) {
            if (!this.angularDocument.querySelector(selector)) {
                routerOutletComponent.style.display = NONE;
                return;
            }

            const selectorPosition = this.angularDocument.querySelector(selector).getBoundingClientRect();

            if (selectorPosition.width === 0 && !noWidth) {
                routerOutletComponent.style.display = NONE;
                return;
            }
            routerOutletComponent.style.display = 'block';
            routerOutletComponent.style.top = 'unset';
            routerOutletComponent.style.bottom = 'unset';
            routerOutletComponent.style.left = 'unset';
            routerOutletComponent.style.right = 'unset';

            if (selectorHintPositionY === 'TOP') {
                routerOutletComponent.style.top = adjustselectorHintPositionY + 'px';
            } else {
                routerOutletComponent.style.bottom = adjustselectorHintPositionY + 'px';
            }
            if (selectorHintPositionX === 'RIGHT') {
                routerOutletComponent.style.right = adjustselectorHintPositionX + xUnit;
            } else {
                routerOutletComponent.style.left = adjustselectorHintPositionX + xUnit;
            }
        }
    }

    /**
     * Adjust hint text position
     * @param selector
     * @param selectorHint
     * @param selectorHintPositionX
     * @param adjustselectorHintPositionX
     * @param selectorHintPositionY
     * @param adjustselectorHintPositionY
     */
    public showpageCalculateHintIcon(
        selector: string,
        selectorHint: string,
        selectorHintPositionX: string,
        adjustselectorHintPositionX: number,
        selectorHintPositionY: string,
        adjustselectorHintPositionY: number,
    ): void {
        const routerOutletComponent: HTMLElement = <HTMLElement>this.angularDocument.querySelector(selectorHint);
        if (routerOutletComponent) {
            if (!this.angularDocument.querySelector(selector)) {
                routerOutletComponent.style.display = NONE;
                return;
            }

            const selectorPosition = this.angularDocument.querySelector(selector).getBoundingClientRect();
            const pageHeight: number = this.angularDocument.querySelector('body').clientHeight;
            const pageWidth: number = this.angularDocument.querySelector('body').clientWidth;
            if (selectorPosition.width === 0) {
                routerOutletComponent.style.display = NONE;
                return;
            }
            routerOutletComponent.style.display = 'block';
            routerOutletComponent.style.top = 'unset';
            routerOutletComponent.style.bottom = 'unset';
            routerOutletComponent.style.left = 'unset';
            routerOutletComponent.style.right = 'unset';

            if (selectorHintPositionY === 'TOP') {
                routerOutletComponent.style.top =
                    selectorPosition.top + selectorPosition.height + adjustselectorHintPositionY + 'px';
            } else {
                routerOutletComponent.style.bottom = selectorPosition.bottom + adjustselectorHintPositionY + 'px';
            }
            if (selectorHintPositionX === 'RIGHT') {
                routerOutletComponent.style.right =
                    pageWidth - selectorPosition.left + adjustselectorHintPositionX + 'px';
            } else {
                routerOutletComponent.style.left =
                    selectorPosition.left + selectorPosition.width / 2 + adjustselectorHintPositionX + 'px';
            }
        } else {
            routerOutletComponent.style.display = NONE;
        }
    }
    private delay(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
}
