import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Config } from './config';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface CollectionEdited {
    customerId: number;
}

@Injectable()
export class CollectService {
    public collectionEdited = new BehaviorSubject<CollectionEdited>(null);
    constructor(public http: HttpClient) {}

    public collectLocation(
        customerId: number,
        locationId: number,
        startDate?: string,
        endDate?: string,
    ): Observable<unknown> {
        const url =
            Config.serviceUrl +
            Config.urls.collections +
            customerId +
            `&locationId=${locationId}&start=${startDate}&end=${endDate}`;

        return this.http.post<any>(url, '').pipe(
            map((response) => {
                this.collectionEdited.next({ customerId });
                return response;
            }),
        );
    }

    public collectAllLocation(customerId: number, locationId: number): Observable<unknown> {
        const url = Config.serviceUrl + Config.urls.collections + customerId + `&locationId=${locationId}`;

        return this.http.post<any>(url, '').pipe(
            map((response) => {
                this.collectionEdited.next({ customerId });
                return response;
            }),
        );
    }

    public collectAllSchedule(customerId: number): Observable<unknown> {
        const url = Config.serviceUrl + Config.urls.collectall + `?customerId=${customerId}`;

        return this.http.post<any>(url, '').pipe(
            map((response) => {
                this.collectionEdited.next({ customerId });
                return response;
            }),
        );
    }
}
