export const SNACK_BAR_NOTIFICATION_TIMEOUT = 10000;

export interface LocationGroupMonitorSummary {
    fetched: boolean;
    locationGroupId: number;
    locationGroupName: string;
    flowMonitorNames: Array<MonitorName>;
    rainfallMonitorNames: Array<MonitorName>;
    levelMonitorNames: Array<MonitorName>;
    suggestedStartDate?: string;
    suggestedEndDate?: string;
}

export interface MonitorCounts {
    locationGroupId: number;
    locationGroupName: string;
    flowMonitors?: Array<MonitorName>;
    levelMonitors?: Array<MonitorName>;
    rainMonitors?: Array<MonitorName>;
}

export interface MonitorName {
    id?: number;
    name?: string;
    earliestReading?: string;
    latestReading?: string;
}

export interface Measurement {
    y: number;
    x: string;
}
export interface SeriesData {
    seriesName: string;
    data: Array<Measurement>;
    plotUnit?: string;
}

export interface MultiSeriesData {
    multiSeriesName: string;
    series: Array<SeriesData>;
}

export interface BlockDaysElement {
    id: string;
    from: string;
    to: string;
    color?: string;
    zIndex?: number;
}
