<div id="userPreferencesDialog">
    <div [innerHTML]="dropdownLocation"></div>
    <form #userDetailsForm="ngForm">
        <app-customized-confirmation-box
            *ngIf="showConfirmation"
            [confirmationData]="conformationDataInput"
        ></app-customized-confirmation-box>
        <div class="popupHeader" mat-dialog-title>
            <span> {{ 'USER_PREFERENCES.TITLE' | translate }}</span>
            <span class="app-flex-filler"></span>
        </div>
        <app-loader [isLoading]="userPreferenceLoding"></app-loader>

        <div mat-dialog-content class="userPreferencesContainer">
            <div>
                <mat-accordion>
                    <mat-expansion-panel expanded="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ 'USER_PREFERENCES.USER_DETAIL_PANEL' | translate }}
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="custom-50p">
                            <div class="custom-field custom-error">
                                <mat-form-field class="full-width">
                                    <input
                                        matInput
                                        placeholder="{{ 'COMMON.FIRST_NAME_TEXT' | translate }}"
                                        id="firstNameInput"
                                        #firstNameInput="ngModel"
                                        name="firstName"
                                        [(ngModel)]="firstName"
                                        maxlength="20"
                                        [pattern]="firstNamePattern"
                                        required
                                    />
                                </mat-form-field>
                                <div class="custom-error-message" *ngIf="firstNameInput?.errors?.pattern">
                                    {{ 'COMMON.FIRST_NAME_VALIDATION' | translate }}
                                </div>
                            </div>
                            <div class="custom-field custom-error">
                                <mat-form-field class="full-width">
                                    <input
                                        matInput
                                        placeholder="{{ 'COMMON.LAST_NAME_TEXT' | translate }}"
                                        id="lastNameInput"
                                        #lastNameInput="ngModel"
                                        name="lastName"
                                        [(ngModel)]="lastName"
                                        maxlength="20"
                                        [pattern]="lastNamePattern"
                                        required
                                    />
                                </mat-form-field>
                                <div class="custom-error-message" *ngIf="lastNameInput?.errors?.pattern">
                                    {{ 'COMMON.LAST_NAME_VALIDATION' | translate }}
                                </div>
                            </div>
                        </div>
                        <div class="custom-error">
                            <mat-form-field class="full-width">
                                <div class="toggleContainer">
                                    <div class="inputfield">
                                        <input
                                            matInput
                                            placeholder="{{ 'USER_PREFERENCES.PRIMARY_EMAIL' | translate }}"
                                            id="primaryEmailInput"
                                            #primaryEmailInput="ngModel"
                                            (focusout)="
                                                primaryEmailInput?.errors?.pattern || primaryEmailAddress === ''
                                                    ? (userNotification.notificationPrimaryEmail = false)
                                                    : null
                                            "
                                            name="primaryEmailAddress"
                                            [(ngModel)]="primaryEmailAddress"
                                            maxlength="64"
                                            [pattern]="emailPattern"
                                            required
                                        />
                                    </div>
                                    <div class="togglefield">
                                        <div>{{ 'COMMON.NOTIFICATIONS_TEXT' | translate }}</div>
                                        <mat-slide-toggle
                                            name="userNotification.notificationPrimaryEmail"
                                            [(ngModel)]="userNotification.notificationPrimaryEmail"
                                            [disabled]="
                                                primaryEmailInput?.errors?.pattern ||
                                                !primaryEmailAddress ||
                                                primaryEmailAddress === ''
                                            "
                                        ></mat-slide-toggle>
                                    </div>
                                </div>
                            </mat-form-field>
                            <div class="custom-error-message" *ngIf="primaryEmailInput?.errors?.pattern">
                                {{ 'COMMON.EMAIL_ID_VALIDATION' | translate }}
                            </div>
                            <div class="custom-error-message" *ngIf="primaryEmailInput?.errors?.required">
                                {{ 'COMMON.VALIDATION.REQUIRED' | translate }}
                            </div>
                        </div>
                        <div class="custom-error">
                            <mat-form-field class="full-width">
                                <div class="toggleContainer">
                                    <div class="inputfield">
                                        <input
                                            matInput
                                            placeholder="{{ 'USER_PREFERENCES.SECONDARY_EMAIL' | translate }}"
                                            id="secondaryEmailInput"
                                            #secondaryEmailInput="ngModel"
                                            (focusout)="
                                                secondaryEmailInput?.errors?.pattern || secondaryEmailAddress === ''
                                                    ? (userNotification.notificationSecondaryEmail = false)
                                                    : null
                                            "
                                            name="secondaryEmailAddress"
                                            [(ngModel)]="secondaryEmailAddress"
                                            maxlength="64"
                                            [pattern]="emailPattern"
                                        />
                                    </div>
                                    <div class="togglefield">
                                        <div>{{ 'COMMON.NOTIFICATIONS_TEXT' | translate }}</div>
                                        <mat-slide-toggle
                                            name="userNotification.notificationSecondaryEmail"
                                            [(ngModel)]="userNotification.notificationSecondaryEmail"
                                            [disabled]="
                                                secondaryEmailInput?.errors?.pattern ||
                                                !secondaryEmailAddress ||
                                                secondaryEmailAddress === ''
                                            "
                                        ></mat-slide-toggle>
                                    </div>
                                </div>
                            </mat-form-field>
                            <div class="custom-error-message" *ngIf="secondaryEmailInput?.errors?.pattern">
                                {{ 'COMMON.EMAIL_ID_VALIDATION' | translate }}
                            </div>
                        </div>
                        <div class="custom-error">
                            <div class="full-width toggleContainer custom-phone-input"
                            [ngClass]="{ error: userDetailsForm.form.controls['phoneNumber'] && userDetailsForm.form.controls['phoneNumber'].touched && userDetailsForm.form.controls['phoneNumber'].invalid }">
                                <label class="custom-phone-label">{{ 'COMMON.PHONE_NUMBER' | translate }}</label>
                                <div class="inputfield" matTooltip="{{ 'USER_PREFERENCES.PHONE_NUMBER_TOOLTIP' | translate }}">
                                    <ngx-intl-tel-input
                                        [cssClass]="'phone-input'"
                                        [preferredCountries]="[CountryISO.UnitedStates]"
                                        [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true"
                                        [searchCountryFlag]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                        [selectedCountryISO]="CountryISO.UnitedStates"
                                        [phoneValidation]="true"
                                        name="phoneNumber"
                                        #userPrefPhoneNumberInput="ngModel"
                                        [(ngModel)]="phoneNumberObject">
                                    </ngx-intl-tel-input>
                                </div>
                                <div class="togglefield">
                                    <div>{{ 'COMMON.NOTIFICATIONS_TEXT' | translate }}</div>
                                    <mat-slide-toggle
                                        #phoneNotificationsToggle
                                        name="userNotification.notificationSMS"
                                        [checked]="userNotification.notificationSMS"
                                        [disabled]="phoneNumberObject === null || (phoneNumberObject && phoneNumberObject.e164Number === '') ||
                                            (userPrefPhoneNumberInput && userPrefPhoneNumberInput.invalid)"
                                        (click)="phoneToggleChange($event)"
                                    ></mat-slide-toggle>
                                </div>
                            </div>
                            <div class="custom-error-message phone-input-error"
                                *ngIf="userPrefPhoneNumberInput && userPrefPhoneNumberInput.touched && userPrefPhoneNumberInput.invalid">
                                {{ 'USER_PREFERENCES.PHONE_NUMBER_VALIDATION' | translate }}
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{ 'USER_PREFERENCES.USER_SEETINGS' | translate }}
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="annotationList">
                            <mat-slide-toggle
                                [color]="'accent'"
                                [checked]="isDarkThemeEnabled"
                                (change)="onChange($event)"
                            >
                                {{ 'USER_PREFERENCES.DARK_THEME' | translate }}
                            </mat-slide-toggle>
                        </div>

                        <div class="annotationList">
                            <mat-slide-toggle
                                [color]="'accent'"
                                [checked]="includeRainLocations"
                                (change)="onIncludeRainLocsToggle($event)"
                            >
                            <div class="include-rain-container">
                                <span>{{ 'USER_PREFERENCES.INCLUDE_RAIN_LOCS' | translate }}</span>
                                <span class="additional-info">{{ 'USER_PREFERENCES.CUMULATIVE_RAINFALL' | translate }}</span>
                                <span class="additional-info">{{ 'USER_PREFERENCES.DAILY_RAINFALL' | translate }}</span>
                            </div>
                            </mat-slide-toggle>
                        </div>

                        <div class="annotationList custom-50p">
                            <div class="custom-field custom-error">
                                <label> {{ 'USER_PREFERENCES.LANGUAGE_OPTIONS_LABEL' | translate }}</label>
                            </div>
                            <div class="custom-field custom-error">
                                <mat-form-field>
                                    <mat-select
                                        placeholder="{{ 'USER_PREFERENCES.SELECT_LANGUAGE_OPTIONS' | translate }}"
                                        [(value)]="selectedLang"
                                    >
                                        <mat-option *ngFor="let language of languagesList" [value]="language.value">
                                            {{ language.viewValue }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="annotationList custom-50p">
                            <div class="custom-field custom-error">
                                <label> {{ 'USER_PREFERENCES.RAINFALL_WIDGET_DEFAULT_TIME_PERIOD' | translate }}</label>
                            </div>
                            <div class="custom-field custom-error">
                                <mat-form-field>
                                    <mat-select
                                        placeholder="{{ 'USER_PREFERENCES.RAINFALL_WIDGET_DEFAULT_TIME_PERIOD' | translate }}"
                                        [(value)]="quickSpanCumulativeRain"
                                        (selectionChange)="onChangeQuickSpanCumulativeRain()"
                                    >
                                        <mat-option *ngFor="let range of quickRangesOptions" [value]="range.id">
                                            {{ range.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>

        <div mat-dialog-actions>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button (click)="emitUserPreferences()">{{ 'COMMON.CLOSE' | translate }}</button>
            </div>
            <div class="auto-margin">
                <button
                    mat-button
                    [disabled]="
                        !isModified() ||
                        userDetailsForm.form.invalid ||
                        (userDetailsForm.form.pristine &&
                            !isChangeQuickSpanCumulativeRain &&
                            !isAlarmUserPreferencesChange &&
                            !isThemeChangeFlag &&
                            !isIncludeRainLocsFlag &&
                            !isAutoCorrectChangeFlag &&
                            !isMarkersFlageChange)
                    "
                    (click)="saveUserPreferences()"
                >
                    {{ 'COMMON.APPLY_BTN' | translate }}
                </button>
            </div>
        </div>
    </form>
</div>
