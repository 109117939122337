<div class="custom-graph-container graph-container-ah">
    <div id="storm-decomp-chart" class="dynamicGraphAH">
        <div #chart [chart]="chartData"></div>
        <div *ngIf="isLoading" class="notabledataFound custom-table print-nodata-space">
            <span>{{ 'SLIICER.COMMON.LOADING' | translate }}</span>
        </div>
    </div>
    <div *ngIf="(!haveStorms || !haveData) && !isLoading" class="notabledataFound custom-table print-nodata-space">
        <span>{{ 'SLIICER.FLOW_TAB.NO_STORMS_TO_DISPLAY' | translate }}</span>
    </div>
</div>
