<div id="markerCompositeLocationId">
    <div class="popupHeader" mat-dialog-title>
        <span>{{ locationDetails.locationName }}</span>
        <span class="app-flex-filler"></span>
        <span>
            <mat-icon (click)="openLocationEditor($event)">edit</mat-icon>
        </span>
    </div>
    <div mat-dialog-content>
        <div id="markerCompositeLocationTable">
            <mat-table [dataSource]="dataSource">
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'HOME.MAP.MARKER_COMPOSITE.NAME_COLUMN' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element" class="word-break"> {{ element.name }} </mat-cell>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="flow">
                    <mat-header-cell *matHeaderCellDef> {{ 'HOME.MAP.FLOW_PLACEHOLDER' | translate }} </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{ element.flow }} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="editLoc">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element" class="text-right">
                        <mat-icon (click)="editLocation(element)">mode_edit</mat-icon>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
            </mat-table>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="emitCloseMapCompositeLocationsPopup(false)">
                {{ 'COMMON.CLOSE' | translate }}
            </button>
        </div>
    </div>
</div>
