<div class="location-gps-dialog">
    <div mat-dialog-title class="popupHeader">Find Me - Error</div>
    <div mat-dialog-content class="dialog-content">
        <p>
            There was an error finding your location. This is likely due to the browser settings. Please follow these
            easy steps to enable this feature:
        </p>
        <ol>
            <li>Click the lock on the address bar next to the adsprism.com</li>
            <li>Click Site Settings</li>
            <li>Next to the Location option, select Allow from the drop down</li>
        </ol>
    </div>

    <div mat-dialog-actions class="dialog-actions-buttons">
        <button mat-button class="mat-button" mat-dialog-close>{{ 'COMMON.CLOSE' | translate }}</button>
    </div>
</div>
