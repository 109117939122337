<button
    id="customDateSelector"
    mat-button
    class="date-picker-hint"
    [disabled]="disabled"
    (menuClosed)="checkDateValidation()"
    [matMenuTriggerFor]="dateRangeMenu"
    #trigger="matMenuTrigger"
    [ngClass]="buttonClasses"
>
    <span *ngIf="!label" class="date-range-content">{{ 'LOCATION_DASHBOARD.DATE_RANGE_SPAN' | translate }}</span>
    <span *ngIf="label" class="date-range-content">{{ label | translate }}</span>
    <span class="date-range-hint date-value">
        {{ startDate | date: customerDateFormat }} -
        {{ endDate | date: customerDateFormat }}
    </span>
    <mat-icon>arrow_drop_down</mat-icon>
</button>
<mat-menu #dateRangeMenu="matMenu" xPosition="before" yPosition="below" [overlapTrigger]="false" class="customDateMenu">
    <div class="customDateMenuContaint">
        <div class="dateArea" *ngIf="!disableInputs" (click)="$event.stopPropagation()">
            <div class="header">
                {{ 'LOCATION_DASHBOARD.CUSTOM_DATE' | translate }} - {{ customerDateFormat?.toUpperCase() }}
            </div>
            <div>
                <mat-form-field class="full-width">
                    <input
                        #startDateInput
                        matInput
                        name="startDate"
                        [value]="displayStartDate | date: customerDateFormat"
                        placeholder="{{ 'COMMON.FROM' | translate }}"
                        (focusout)="inputCheckStartDateChange($event)"
                        tabindex="-1"
                        class="datepickerStyles"
                        [min]="minDate"
                        [max]="maxDate"
                        [disabled]="disabled"
                        appDatetimepickerPosition
                        [matDatetimepickerComp]="startDte"
                    />
                    <input
                        matInput
                        hidden
                        name="startDate"
                        [matDatetimepicker]="startDte"
                        [max]="maxDate"
                        [(ngModel)]="displayStartDate"
                        [value]="startDate | date: customerDateFormat"
                        placeholder="{{ 'COMMON.FROM' | translate }}"
                        required
                        tabindex="-1"
                        (dateInput)="calendarCheckStartDateChange($event)"
                        (dateChange)="calendarCheckStartDateChange($event)"
                        [min]="minDate"
                        [max]="maxDate"
                        [disabled]="disabled"
                    />
                    <mat-datetimepicker-toggle
                        matSuffix
                        [for]="startDte"
                        [disabled]="disabled"
                    ></mat-datetimepicker-toggle>
                    <mat-datetimepicker #startDte [type]="type" [disabled]="disabled"></mat-datetimepicker>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field class="full-width">
                    <input
                        #endtDateInput
                        matInput
                        name="endDate"
                        [value]="displayEndDate | date: customerDateFormat"
                        placeholder="{{ 'COMMON.TO' | translate }}"
                        (focusout)="inputCheckEndDateChange($event)"
                        tabindex="-1"
                        class="datepickerStyles"
                        [min]="minDate"
                        [max]="maxDate"
                        [disabled]="disabled"
                        appDatetimepickerPosition
                        [matDatetimepickerComp]="endDte"
                    />
                    <input
                        matInput
                        hidden
                        name="endDate"
                        [matDatetimepicker]="endDte"
                        [max]="maxDate"
                        [(ngModel)]="displayEndDate"
                        [value]="endDate | date: customerDateFormat"
                        placeholder="{{ 'COMMON.TO' | translate }}"
                        required
                        tabindex="-1"
                        (dateInput)="calendarCheckEndDateChange($event)"
                        (dateChange)="calendarCheckEndDateChange($event)"
                        [min]="minDate"
                        [max]="maxDate"
                        [disabled]="disabled"
                    />
                    <mat-datetimepicker-toggle
                        matSuffix
                        [for]="endDte"
                        [disabled]="disabled"
                    ></mat-datetimepicker-toggle>
                    <mat-datetimepicker
                        #endDte
                        disabled="false"
                        [type]="type"
                        [disabled]="disabled"
                    ></mat-datetimepicker>
                </mat-form-field>
            </div>
            <div class="mat-error full-width">
                <div *ngIf="displayEndDateErrMsg && !invalidEndDate && !invalidDateRange">
                    {{ 'COMMON.INVALID_TO_DATE_FORMAT' | translate }}
                </div>
                <div *ngIf="invalidEndDate">{{ 'COMMON.FROM_TO_DATE_ERROR' | translate }}</div>
                <div *ngIf="invalidStartDate">{{ 'COMMON.INVALID_START_DATE' | translate }}</div>
                <div *ngIf="displayStartDateErrMsg && !invalidStartDate && !invalidDateRange">
                    {{ 'COMMON.INVALID_FROM_DATE_FORMAT' | translate }}
                </div>
                <div *ngIf="invalidRange">{{ 'COMMON.INVALID_RANGE' | translate | replace: '%':maxDayRange }}</div>
            </div>
        </div>
        <div class="dateSelectorArea">
            <div class="header">{{ 'LOCATION_DASHBOARD.QUICK_RANGES' | translate }}</div>
            <div class="custom-50p-button">
                <button
                    class="date-ranges"
                    *ngIf="!startFromMonth && oneDayAvailable"
                    mat-menu-item
                    [disabled]="disabled"
                    (click)="getDataForSetTimeSpan(QUICK_DATE_RANGES.TODAY, $event)"
                >
                    {{ 'LOCATION_DASHBOARD.ONE_DAY_BTN' | translate }}
                </button>
                <button
                    class="date-ranges"
                    *ngIf="!startFromMonth && oneWeekAvailable"
                    mat-menu-item
                    [disabled]="disabled"
                    (click)="getDataForSetTimeSpan(QUICK_DATE_RANGES.LAST_WEEK, $event)"
                >
                    {{ 'LOCATION_DASHBOARD.ONE_WEEK_BTN' | translate }}
                </button>
                <button
                    class="date-ranges"
                    *ngIf="lastMonthAvailable"
                    [disabled]="disabled"
                    mat-menu-item
                    (click)="getDataForSetTimeSpan(QUICK_DATE_RANGES.PREVIOUS_MONTH, $event)"
                >
                    {{ 'LOCATION_DASHBOARD.LAST_MONTH_BTN' | translate }}
                </button>
                <button
                    class="date-ranges"
                    *ngIf="oneMonthAvailable"
                    [disabled]="disabled"
                    mat-menu-item
                    (click)="getDataForSetTimeSpan(QUICK_DATE_RANGES.LAST_MONTH, $event)"
                >
                    {{ 'LOCATION_DASHBOARD.ONE_MONTH_BTN' | translate }}
                </button>
                <button
                    class="date-ranges"
                    *ngIf="threeMonthsAvailable"
                    [disabled]="disabled"
                    mat-menu-item
                    (click)="getDataForSetTimeSpan(QUICK_DATE_RANGES.LAST_THREE_MONTHS, $event)"
                >
                    {{ 'LOCATION_DASHBOARD.THREE_MONTHS_BTN' | translate }}
                </button>
                <button
                    class="date-ranges"
                    *ngIf="sixMonthsAvailable"
                    [disabled]="disabled"
                    mat-menu-item
                    (click)="getDataForSetTimeSpan(QUICK_DATE_RANGES.LAST_SIX_MONTHS, $event)"
                >
                    {{ 'LOCATION_DASHBOARD.SIX_MONTHS_BTN' | translate }}
                </button>
                <button
                    class="date-ranges"
                    *ngIf="oneYearAvailable"
                    [disabled]="disabled"
                    mat-menu-item
                    (click)="getDataForSetTimeSpan(QUICK_DATE_RANGES.LAST_YEAR, $event)"
                >
                    {{ 'LOCATION_DASHBOARD.ONE_YEAR_BTN' | translate }}
                </button>
            </div>
        </div>
    </div>
</mat-menu>
