import { Injectable } from '@angular/core';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { Config } from 'app/shared/services/config';
import { HttpClient } from '@angular/common/http';
import { BuildNumbers } from '../models/build-numbers';
import { map } from 'rxjs/operators';

@Injectable()
export class AboutUsService {
    constructor(public http: HttpClient) {}

    public getBuildNumbers() {
        const uiBuildNumber = this.http.get<any>(Config.urls.uiBuildNumber);
        const apiBuildNumber = this.http.get<BuildNumbers>(Config.urls.apiBuildNumber);

        return forkJoin(uiBuildNumber, apiBuildNumber).pipe(
            map(
                (result) => {
                    const buildNumbers: BuildNumbers = {
                        // check for build number from VSTS
                        ui: result[0].build === '#{Build.BuildNumber}#' ? '--' : result[0].build,
                        build: result[1].build,
                        node: result[1].node,
                        region: result[1].region,
                    };
                    return buildNumbers;
                },
                (error) => {
                    return EMPTY;
                },
            ),
        );
    }
}
