import { Component } from '@angular/core';

@Component({
    selector: 'location-steps-gps',
    templateUrl: './location-steps-gps.component.html',
    styleUrls: ['./location-steps-gps.component.scss'],
})
export class LocationStepsGPSComponent {
    constructor() {}
}
