import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';

/**
 * @sanitize pipe will use as reuseable pipe through the application for XSS preventation.
 * It need to call from UI with respective type.
 * Ex . <div [innerHtml]="testHtmlScript | sanitize: 'html'"></div>
 */

@Pipe({
    name: 'sanitize',
})
export class SanitizePipe implements PipeTransform {
    /**
     * Injected DomSanitizer service in constructor.
     */

    constructor(protected sanitizer: DomSanitizer) {}

    public transform(value: any, type?: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
        switch (type) {
            case 'html':
                return this.sanitizer.bypassSecurityTrustHtml(value);

            case 'style':
                return this.sanitizer.bypassSecurityTrustStyle(value);

            case 'script':
                return this.sanitizer.bypassSecurityTrustScript(value);

            case 'url':
                return this.sanitizer.bypassSecurityTrustUrl(value);

            case 'resourceUrl':
                return this.sanitizer.bypassSecurityTrustResourceUrl(value);

            default:
                throw new Error(`Invalid type specified: ${type}`);
        }
    }
}
