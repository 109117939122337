import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { EventFilters, EventModel } from 'app/shared/models/event';
import { Selectable } from 'app/shared/models/selectable';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-event-filters',
    templateUrl: './event-filters.component.html',
    styleUrls: ['./event-filters.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EventFiltersComponent implements OnInit, OnDestroy, OnChanges {
    @Input() filterOptions: EventFilters;
    @Input() locations: Selectable[];
    @Input() events: EventModel[];

    @Output() optionsChange = new EventEmitter<EventFilters>();

    public filteredLocations: Selectable[] = [];
    public filteredTypes: Selectable[] = [];

    public isDateChanged = false;
    public dateCloseEvent = new Subject();
    private subscriptions = new Subscription();
    constructor() { }

    ngOnInit(): void {
        this.subscriptions.add(this.dateCloseEvent.pipe(debounceTime(300))
            .subscribe(() => this.isDateChanged ? this.optionsChange.emit({...this.filterOptions, datesChange: true}) : null));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.events && changes.events.currentValue && this.locations && this.filterOptions) {
            this.setFilteredValues(changes.events.currentValue);
        }
    }

    public handleSelectedLocations(event: Selectable[]) {
        const selected = event.filter(v => v.isChecked);
        const isEqual = this.filterOptions.selectedLocations.length === selected.length &&
            this.filterOptions.selectedLocations.every(v => selected.find(i => i.id === v.id));

        this.filterOptions.datesChange = false;
        if (!isEqual) {
            this.optionsChange.emit({ ...this.filterOptions, selectedLocations: event.filter(v => v.isChecked) });
        }
    }

    public handleTypeChange(event: Selectable[]) {
        const selected = event.filter(v => v.isChecked);
        const isEqual = this.filterOptions.selectedTypes.length === selected.length &&
            this.filterOptions.selectedTypes.every(v => selected.find(i => i.id === v.id));

        this.filterOptions.datesChange = false;
        if (!isEqual) {
            this.optionsChange.emit({ ...this.filterOptions, selectedTypes: event.filter(v => v.isChecked) });
        }
    }

    public durationClose(event: EventFilters) {
        const isEqual = Object.keys(event).every(v => String(this.filterOptions[v]) === String(event[v]));

        if (!isEqual) {
            this.optionsChange.emit(event);
        }
    }

    private setFilteredValues(events: EventModel[]) {
        const locationsIds: number[] = events.reduce((acc, curr) => ([...acc, ...curr.lids]), []);
        const eventTypes: number[] = events.map(v => v.etype);

        const filteredLocs = this.locations.filter(v => locationsIds.includes(v.id));
        const filteredTypes = this.filterOptions.types.filter(v => eventTypes.includes(v.id));
        const selectedLocsIds = this.filterOptions.selectedLocations.map(v => v.id);
        const selectedTypes = this.filterOptions.selectedTypes.map(v => v.id);

        this.filteredLocations = filteredLocs.map(v => ({ ...v, isChecked: selectedLocsIds.includes(v.id) }));
        this.filteredLocations.sort((a, b) => a.name.localeCompare(b.name));
        this.filteredTypes = filteredTypes.map(v => ({ ...v, isChecked: selectedTypes.includes(v.id) }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
