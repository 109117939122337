import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { TranslateService } from '@ngx-translate/core';
import { ConfigTableData, ConfigTemplate, GetConfigTemplate } from 'app/shared/models/data-edit';
import { ConfigService } from 'app/shared/services/config.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

const ONE_MINUTE = 1000 * 60;

@Component({
    selector: 'app-configuration-table',
    templateUrl: './configuration-table.component.html',
    styleUrls: ['./configuration-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ConfigurationTableComponent implements OnInit {
    public displayedColumns = ['name', 'createdBy', 'editedBy', 'lastEdited', 'actions'];
    public configs$: Observable<GetConfigTemplate<unknown>[]>;
    public paginatedConfigs$: Observable<GetConfigTemplate<unknown>[]>;
    private paginatorSubject = new BehaviorSubject({ pageIndex: 0, pageSize: 10 });
    public selectedConfig: GetConfigTemplate<unknown>;

    public customDateFormat: string;

    public editConfigValidateFn: (name: string, currentValue: string) => boolean;
    public editConfigErrorText: string;
    constructor(private dialogRef: MatDialogRef<ConfigurationTableComponent>,
        private configService: ConfigService,
        @Inject(MAT_DIALOG_DATA) private data: ConfigTableData,
        private dateUtilService: DateutilService,
        private translateService: TranslateService) {}

    ngOnInit(): void {
        const dateFormat = this.dateUtilService.getFormat();
        const is12HourFormat = this.dateUtilService.timeFormat.getValue() !== 'hh:mm:ss';
        this.customDateFormat = is12HourFormat ? `${dateFormat}, ${'hh:mm a'}` : `${dateFormat}, ${'HH:mm'}`;

        this.getConfigs();

        this.editConfigErrorText = this.translateService.instant('COMMON.CONFIG_DUPLICATE_NAME_ERROR');
    }

    public cancel() {
        this.dialogRef.close(null);
    }

    public editConfig<T>(config: GetConfigTemplate<T>, newName: string) {
        if (!newName) return;

        const configPayload: ConfigTemplate<T> = {
            configName: newName,
            configType: this.data.configType,
            partitionSuffixFilter: this.data.partitionSuffixFilter,
            id: config.id,
            existingConfigId: config.id,
            settings: config.settings
        };

        this.configService.saveConfig(configPayload, this.data.cid).subscribe(() => this.getConfigs());
    }

    public deleteConfig<T>(config: GetConfigTemplate<T>) {
        const { cid, configType, partitionSuffixFilter } = this.data;
        this.configService.deleteConfig(cid, configType, partitionSuffixFilter, config.id).subscribe(() => this.getConfigs());
    }

    public load() {
        this.dialogRef.close(this.selectedConfig);
    }

    public pageEvent(event: PageEvent) {
        this.paginatorSubject.next({ pageIndex: event.pageIndex, pageSize: event.pageSize });
    }

    private getConfigs() {
        this.configs$ = this.configService.getAllConfigs(this.data.cid, this.data.configType, this.data.partitionSuffixFilter).pipe(
            tap((configs) => this.editConfigValidateFn = (name: string, currentValue: string) => !!configs.find(v => v.name.trim() === name.trim() && name.trim() !== currentValue.trim())),
            map((configs: GetConfigTemplate<unknown>[]) => configs.map((config: GetConfigTemplate<unknown>) => {
                const lastEdited = new Date(config.lastEdited);
                const lastEditedFormattedToUserTimezone = new Date(lastEdited.getTime() - lastEdited.getTimezoneOffset() * ONE_MINUTE);

                return { ...config, lastEdited: lastEditedFormattedToUserTimezone };
            }))
        );
        this.paginatedConfigs$ = combineLatest([this.configs$, this.paginatorSubject]).pipe(
            map(([configs, { pageSize, pageIndex }]: [GetConfigTemplate<unknown>[], { pageSize: number; pageIndex: number }]) => {
                return configs.slice(pageIndex * pageSize, pageSize + (pageIndex * pageSize))
            })
        )
    }
}
