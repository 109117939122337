import {
    Directive,
    Input,
    forwardRef,
} from '@angular/core';

import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';
import * as _ from 'underscore';

@Directive({
    selector: "[validateFn][ngModel]",
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => ValidateFnDirective),
        multi: true
    }]
})
export class ValidateFnDirective implements Validator {
    @Input() validateFn: ValidatorFn; //same name as the select

    validate(control: AbstractControl): ValidationErrors | null {
        console.log('validate')
        return this.validateFn(control);
    }
}
