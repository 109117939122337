import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { UsersService } from 'app/pages/admin/users.service';
import { TritonConfiguration } from 'app/shared/models/monitorsetting';
import { LocationFormBuilder } from '../../services/location-form-builder';

@Component({
    selector: 'app-rain-alarm-info',
    templateUrl: './rain-alarm-info.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})
export class RainAlarmInfoComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() monitorConfigs: TritonConfiguration;
    public isMonitorEditor = false;
    constructor(private locationFormBuilder: LocationFormBuilder, private usersService: UsersService) {}

    ngOnInit() {
        this.isMonitorEditor = this.usersService.isMonitorEditor.getValue();
        const keys = ['alarmEnable', 'alarmReturnToNormal', 'alarmThreshold'];
        if (this.monitorConfigs && keys.every((v) => this.monitorConfigs[v.toLowerCase()] !== undefined)) {
            keys.forEach((v) => this.form.patchValue({ [v]: this.monitorConfigs[v.toLowerCase()] }));

            this.onRainAlarmChange({ checked: this.form.get('alarmEnable').value } as any);
        }
    }

    onRainAlarmChange(event: MatSlideToggleChange) {
        this.locationFormBuilder.enableDisableControls(this.form, !event.checked, [
            'alarmThreshold',
            'alarmReturnToNormal',
        ]);

        if (event.checked) {
            this.form.get('alarmThreshold').setValidators(Validators.required);
            this.form.get('alarmReturnToNormal').setValidators(Validators.required);
        } else {
            this.form.get('alarmThreshold').clearValidators();
            this.form.get('alarmReturnToNormal').clearValidators();
        }
    }
}
