import { Injectable } from '@angular/core';
import { Config } from './config';
import { DateService } from './date.service';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CrowdCoreService {
    constructor(public http: HttpClient, private dateService: DateService) {}

    // method returns  { customerId, locationId, timestamp, urlToImage, imageId }
    public getImages() {
        return this.http.get(Config.urls.crowdcoreJudgements);
    }

    public getStatus() {
        return this.http.get(Config.urls.crowdcoreStatus);
    }
    /* (`GET /api/judgements/top5`) */
    public getTopScores() {
        return this.http.get(Config.urls.crowdcoreTopScore);
    }

    public getSubmissions(startDate: Date, endDate: Date) {
        const start = this.dateService.formatDate(startDate);
        const end = this.dateService.formatDate(endDate);
        const url = `${Config.urls.submissionsCount}?startDate=${start}&endDate=${end}`;
        console.log(url);
        return this.http.get<Object>(url);
    }

    public postInput(imageInfo: Object) {
        const res = this.http.post(Config.urls.crowdcoreJudgements, imageInfo);
        catchError((error) => {
            console.dir(error.json());
            return res;
        });
        return res;

        /*
         this.http.post('https://jsonplaceholder.typicode.com/posts', imageInfo)
             .map( res => res.json()
         );
         .map(
             (res:Response) => res.json()
         )
         .subscribe(
             (res: Response) => { console.log(res); }
         )
         */
    }
}
