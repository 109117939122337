import {
    NotificationDailyReport,
    NotificationDailyReportDRIDS,
    NotificationDetailsLite,
    NotificationLevel,
    NotificationLocationOptions,
    NotificationType,
} from 'app/shared/models/notifications';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { NotificationDashboardService } from 'app/shared/services/notification.service';
import { UsersService } from 'app/pages/admin/users.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { BaseNotificationForm } from 'app/shared/components/base-notification-form/base-notification-form.component';
import moment from 'moment';
import { isEqual } from 'lodash';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { LocationService } from 'app/shared/services/location.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MapService } from 'app/shared/services/map.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DefinedInstallationType, SlimLocationDetails } from 'app/shared/models/location-details';
import { GISService } from 'app/shared/services/gis-service';
import { NotificationTreeComponentData } from 'app/shared/components/notification-tree/notification-tree.component';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { LocationCardService } from 'app/shared/components/location-card/services/location-card.service';
import { MatLegacySlideToggle as MatSlideToggle } from '@angular/material/legacy-slide-toggle';

class ListOfDaysItem {
    public text: string;
    public apiValue: number;
    public isSelected = true;
}

@Component({
    selector: 'standard-alarm',
    templateUrl: './daily-report-notifications.component.html',
    styleUrls: ['./../alarms.component.scss', './../notification-dashboard.component.scss'],
})
export class DailyReportNotificationsComponent extends BaseNotificationForm<NotificationDailyReport> {
    @ViewChild('enabledToggle') public enabledToggle: MatSlideToggle = null;
    @ViewChild('nameField') public nameField: ElementRef;

    listOfDays: ListOfDaysItem[] = [
        { text: 'Su', apiValue: 0, isSelected: true },
        { text: 'M', apiValue: 1, isSelected: true },
        { text: 'Tu', apiValue: 2, isSelected: true },
        { text: 'W', apiValue: 3, isSelected: true },
        { text: 'Th', apiValue: 4, isSelected: true },
        { text: 'F', apiValue: 5, isSelected: true },
        { text: 'Sa', apiValue: 6, isSelected: true },
    ];
    backupListOfDays: ListOfDaysItem[];

    public is12HourFormat = false;
    public collectTime: string;
    public timeError = false;
    public NotificationDailyReportDRIDS = NotificationDailyReportDRIDS;

    constructor(
        translate: TranslateService,
        locationGroupService: LocationGroupService,
        locationService: LocationService,
        customerService: CustomerService,
        notificationService: NotificationDashboardService,
        usersService: UsersService,
        mapService: MapService,
        matDialog: MatDialog,
        snackBar: MatSnackBar,
        activatedRoute: ActivatedRoute,
        router: Router,
        gisService: GISService,
        private dateutilService: DateutilService,
        domOperationUtilsService: DomOperationUtilsService,
        locationCardService: LocationCardService
    ) {
        super(
            translate,
            locationGroupService,
            locationService,
            customerService,
            notificationService,
            usersService,
            mapService,
            matDialog,
            snackBar,
            activatedRoute,
            router,
            gisService,
            domOperationUtilsService,
            locationCardService
        );
    }
    public ngOnInit() {
        if (this.subscribeOnly) {
          this.selectedIndex = 4;
        } else if (this.notificationId) {
            // Admin and edit notification
            this.selectedIndex = 0;
        } else {
            // Admin and new notification
            this.selectedIndex = 0;
        }
        this.subscriptions.push(
            this.dateutilService.timeFormat.subscribe((value) => {
                this.is12HourFormat = value !== 'hh:mm:ss';
            }),
        );
    }

    nameFieldGet() {
        return this.nameField;
    }

    notificationModelEmpty(): NotificationDailyReport {
        return {
            type: NotificationType.DAILY_REPORT,
            name: null,
            desc: null,
            enabled: true,
            levels: [],
            lids: [],
            aids: [],
            days: '',
            time: '',
            drids: [],
        };
    }
    initNewNotification() {
        this.updateTime('7:00 am');
        this.notificationObject.drids = [
            NotificationDailyReportDRIDS.COMMUNICATION_REPORT,
            NotificationDailyReportDRIDS.LOW_BATTERY,
        ];
    }

    onNotificationLoadFinished() {
        this.backupListOfDays = JSON.parse(JSON.stringify(this.listOfDays));
    }

    initExistingNotification(res: NotificationDailyReport) {
        const daysArr = res.days.split(',');
        this.listOfDays.forEach((day) => {
            day.isSelected = daysArr.includes(day.apiValue.toString());
        });
        this.updateTime(res.time);
    }

    locationsFilter(
        locations: SlimLocationDetails[],
        locationGroups: any,
        selectedLocs: number[],
    ): NotificationLocationOptions[] {

        let out=[];
        let locationsInGroups = [];

        if (locationGroups !== undefined) {
            // Add locations that are in location groups.
            locationGroups.forEach(locGrp => {
                locGrp.locations.forEach(loc => {
                    // MP2 check. Slim locations do not return this. Change it here if it will be added in future
                    if (loc.installationType === DefinedInstallationType.Composite || (loc.name && loc.name.endsWith('(2)'))) {
                        return;
                    }

                    locationsInGroups.push(loc.locationID);
                    out.push({
                        id: loc.locationID,
                        name: loc.name,
                        checked: selectedLocs.includes(loc.locationID),
                        group: locGrp.name,
                    });
                });
            });
        }

        // Add locations that are not in a location group.
        locations.forEach(loc => {
            if (loc.installationType === DefinedInstallationType.Composite ||
                (loc.locationName && loc.locationName.endsWith('(2)')) ||
                locationsInGroups.includes(loc. locationId)
            ) {
                return;
            }

            out.push({
                id: loc.locationId,
                name: loc.locationName,
                checked: selectedLocs.includes(loc.locationId),
                group: undefined,
            });
        });

        return out.sort((l1, l2) => (l1.name && l2.name ? l1.name.localeCompare(l2.name) : l1.name ? -1 : 1));
    }

    allowSecondTab(): boolean {
        const anySubject = this.notificationObject.drids.length > 0;
        const anyDay = this.listOfDays.some((x) => x.isSelected);
        const isTime = !this.timeError;
        return anySubject && anyDay && isTime;
    }

    /** @override */
    public checkAllRequiredInputs(): boolean {
        return super.checkAllRequiredInputs();
    }

    /** @Override */
    public getNotificationDialogOptions(names: string[], element?: NotificationLevel): NotificationTreeComponentData {
        return {
            users: this.userOptions,
            selectedUsers: element ? element.users : [],
            name: 'Notifications',
            names: names,
            delay: element ? element.delay : 0,
            enabled: element ? element.enabled : true,
            nameReadonly: true,
        };
    }

    public daySelect(day: ListOfDaysItem) {
        day.isSelected = !day.isSelected;
    }

    notificationTypeChecked(toggle: NotificationDailyReportDRIDS) {
        return this.notificationObject.drids.includes(toggle);
    }

    notificationTypeToggle(toggle: NotificationDailyReportDRIDS) {
        if (this.notificationTypeChecked(toggle)) {
            const index = this.notificationObject.drids.indexOf(toggle);
            this.notificationObject.drids.splice(index, 1);
        } else {
            this.notificationObject.drids.push(toggle);
        }
    }

    public onDateInput(event) {
        const { value } = event;

        const timeMoment = this.is12HourFormat ? moment(value, ['h:mm A'], true) : moment(value, ['HH:mm'], true);
        this.timeError = !timeMoment.isValid();

        this.updateTime(value);
    }

    public onDateChange(event) {
        const { value } = event;

        this.updateTime(value);
    }

    private updateTime(time: string | Date) {
        const timeMoment = moment(time);
        const hours = +(timeMoment.isValid() ? timeMoment : moment(time, ['h:mm A'])).format('HH');
        let mins = (timeMoment.isValid() ? timeMoment : moment(time, ['h:mm A'])).format('mm');

        const isAm = hours < 12;

        if (String(mins).length === 1) {
            mins = '0' + mins;
        }

        if (this.is12HourFormat) {
            this.notificationObject.time = `${isAm ? hours : (hours == 12 ? 12: hours - 12)}:${mins} ${isAm ? 'AM' : 'PM'}`;
        } else {
            this.notificationObject.time = `${hours}:${mins}`;
        }
    }

    checkTimeValue(event) {
        const { value } = event.target;
        const timeMoment = this.is12HourFormat ? moment(value, ['h:mm A'], true) : moment(value, ['HH:mm'], true);
        this.timeError = !timeMoment.isValid();

        if (!this.timeError) {
            this.notificationObject.time = timeMoment.format(this.is12HourFormat ? 'h:mm A' : 'HH:mm');
        }
    }

    /** @override */
    public confirm() {
        this.notificationObject.days = this.listOfDays
            .filter((x) => x.isSelected)
            .map((x) => x.apiValue)
            .join(',');

        super.confirm();
    }

    /** @override */
    public backToAllNotifications() {
        if (isEqual(this.backupListOfDays, this.listOfDays)) {
            super.backToAllNotifications();
        } else {
            this.backToAllLeavePopup();
        }
    }
}
