<div id="flow-balance-report" class="contentLayout">
    <div
        class="flex-container margin-b-15 locationsMenuContainer"
        [class.activeLocationSearch]="showLocationSearch"
        *ngIf="isFlowBalanceVisible"
    >
        <div id="locationsMenu">
            <app-loader [isLoading]="isLoading"></app-loader>
            <mat-tab-group (selectedTabChange)="changeLocation($event)" [selectedIndex]="selectedTabIndex">
                <mat-tab
                    *ngFor="let location of selectableLocations"
                    label="{{ location.name }}"
                    [disabled]="locationId === 0"
                >
                    <ng-template mat-tab-label>
                        <mat-icon *ngIf="location.isComposite">add_circle</mat-icon>
                        {{ location.name }}
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
        <div class="app-flex-filler"></div>
        <div class="location-selected" *ngIf="showLocationSearch">
            <app-auto-complete
                [items]="selectableLocations"
                [preselectedItem]="preselectedLocation"
                placeholder="{{ 'COMMON.LOCATIONS' | translate }}"
                [isShowAll]="false"
                (selectedItems)="changeLocationDropdown($event)"
            ></app-auto-complete>
            <div class="validation">
                <span [hidden]="locationId > 0 || isLoading">{{
                    'COMMON.LOCATION_SELECT_VALIDATION' | translate
                }}</span>
            </div>
        </div>
        <div class="auto-margin">
            <button
                id="locationDbSearch"
                class="filterFlatIconButton activeBackGround"
                [class.active]="showLocationSearch"
                mat-button
                matTooltip="{{ 'COMMON.SEARCH_LOC_PLACEHOLDER' | translate }}"
                (click)="toggeleSearch()"
            >
                <mat-icon>search</mat-icon>
            </button>
        </div>
    </div>
    <mat-card>
        <mat-card-header *ngIf="isFlowBalanceVisible">
            <div class="auto-margin">
                {{ 'FLOW_BALANCE.FLOW_BALANCE' | translate }}
            </div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin custom-flat-button">
                <button
                    class="edit"
                    mat-button
                    (click)="redirectToFlowbalanceReport()"
                    [disabled]="!isFlowBalanceDataAvailable"
                >
                    {{ 'FLOW_BALANCE.GENERATE_REPORT' | translate }}
                </button>
            </div>
            <div class="auto-margin custom-flat-button">
                <button class="edit" mat-button (click)="openFlowBalance()">
                    {{ 'COMMON.EDIT_TOOLTIP' | translate }}
                </button>
            </div>
        </mat-card-header>
        <mat-card-content>
            <div id="locationpagetable">
                <app-loader [isLoading]="isFlowBalanceData"></app-loader>
                <div class="notabledataFound custom-table" *ngIf="!isFlowBalanceDataAvailable && isFlowBalanceVisible">
                    {{ 'COMMON.NO_DATA_AVAILABLE' | translate }}
                </div>
                <mat-table [dataSource]="flowBalanceDataSource" *ngIf="isFlowBalanceDataAvailable">
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef
                            >{{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.NAME' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let entity">{{ entity.flowBalanceLocation }} </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="flow">
                        <mat-header-cell *matHeaderCellDef>{{ 'COMMON.FLOW' | translate }} </mat-header-cell>
                        <mat-cell *matCellDef="let entity">
                            <span *ngIf="entity.flowType === 1">+</span>
                            <span *ngIf="entity.flowType === 2">-</span>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="flowBalanceColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: flowBalanceColumns"></mat-row>
                </mat-table>
            </div>
        </mat-card-content>
        <mat-card-content>
            <div class="page-marketing-screen" *ngIf="!isFlowBalanceVisible">
                <img src="assets/images/flow.png" alt="flow" />
                <h3 class="width-30-p auto-margin">
                    {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.FLOW_BALANCE_SETUP' | translate }}
                </h3>
                <p class="width-30-p auto-margin">
                    {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.FLOW_BALANCE_GAUGE' | translate }}
                </p>
                <button mat-button class="contact-button" (click)="contactUs()">
                    {{ 'COMMON.CONTACT_US' | translate }}
                </button>
            </div>
        </mat-card-content>
        <div class="customFooter">
            <div class="app-flex-filler"></div>
            <div class="auto-margin pad-r-15" *ngIf="isFlowBalanceDataAvailable">
                + {{ 'FLOW_BALANCE.UPSTREAM' | translate }}
            </div>
            <div class="auto-margin pad-r-15" *ngIf="isFlowBalanceDataAvailable">
                - {{ 'FLOW_BALANCE.OVERFLOW' | translate }}
            </div>
        </div>
    </mat-card>
</div>
