/* tslint:disable:all */
import { Component } from '@angular/core';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';

@Component({
    selector: 'c-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    /**
     * Used to check for scroll option check
     */
    public scrollToUp: boolean;

    constructor(private domOperationUtilsService: DomOperationUtilsService) {}

    /**
     * Used for scroll up and down
     */
    public scrollToUpDown(): void {
        if (this.scrollToUp) {
            // calls for scroll to Up
            this.domOperationUtilsService.scrollToUpDown('html', 'UP');
        } else {
            // calls for scroll to Down
            this.domOperationUtilsService.scrollToUpDown('html', 'DOWN');
        }

        // scroll flag value
        this.scrollToUp = !this.scrollToUp;
    }
}
