<div>
    <div class="custom-flat-button">
        <button mat-button [matMenuTriggerFor]="diagnosticsMenu">
            <ng-content></ng-content>
        </button>
        <mat-menu #diagnosticsMenu="matMenu" class="diagnostics-menu">
            <form
                class="diagnostics-menu-form"
                [formGroup]="diagnosticsGroup"
                (ngSubmit)="startDiagnostics()"
                (click)="$event.stopPropagation()"
            >
                <ul class="option-list">
                    <li>
                        <mat-checkbox formControlName="ultrasonicDepth">{{
                            'DIAGNOSTICS.ULTRASONIC_DEPTH_READING' | translate
                        }}</mat-checkbox>
                    </li>
                    <li>
                        <mat-checkbox formControlName="pressureDepth">{{
                            'DIAGNOSTICS.PRESSURE_DEPTH_READING' | translate
                        }}</mat-checkbox>
                    </li>
                    <li>
                        <mat-checkbox formControlName="velocity">{{
                            'DIAGNOSTICS.VELOCITY_READING' | translate
                        }}</mat-checkbox>
                    </li>
                    <li>
                        <mat-checkbox formControlName="battery">{{
                            'DIAGNOSTICS.BATTERY_READING' | translate
                        }}</mat-checkbox>
                    </li>
                    <li>
                        <mat-checkbox formControlName="firmware">{{
                            'DIAGNOSTICS.FIRMWARE_VERSION' | translate
                        }}</mat-checkbox>
                    </li>
                    <li>
                        <mat-checkbox formControlName="accelerometer">{{
                            'DIAGNOSTICS.ACCELEROMETER' | translate
                        }}</mat-checkbox>
                    </li>
                </ul>
                <div class="custom-flat-button start-button">
                    <button
                        mat-button
                        type="submit"
                        [disabled]="
                            diagnosticsGroup.invalid ||
                            diagnosticsGroup.disabled ||
                            diagnosesRunning['id' + locationId + customerId]
                        "
                    >
                        {{ 'COMMON.START' | translate }}
                    </button>
                </div>
            </form>
        </mat-menu>
    </div>
</div>
