import {
    Component,
    ViewChild,
    OnInit,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    ChangeDetectorRef,
    OnDestroy,
} from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { FilterDataSource } from 'app/shared/components/paging/filter-data-source';
import { StatusCodeService } from '../../../shared/services/status-code.service';
import { GenerateApiKeyComponent } from '../generate-api-key/generate-api-key.component';
import { ApiKey } from 'app/shared/models/api-key';
import { ApiKeyService } from '../../../shared/services/api-key.service';
import { ActivatedRoute } from '@angular/router';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'app-api-key-dashboard',
    templateUrl: './api-key-dashboard.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiKeyDashboardComponent implements OnInit, OnDestroy {
    public selectedCustomerID: number;
    public apiKeys = new Array<ApiKey>();

    public apiKeySummary = new Array<ApiKey>();
    private subscriptions = new Array<Subscription>();
    public apiKeyDataChange: BehaviorSubject<ApiKey[]> = new BehaviorSubject<ApiKey[]>([]);
    public initialData = new BehaviorSubject<ApiKey[]>([]);
    public apiKeyDataSource: FilterDataSource | null;
    public totalPaginationLength: number;
    @ViewChild(MatPaginator) public userPaginator: MatPaginator;
    @ViewChild(MatSort) public userSort: MatSort;
    public apiKeyFilterColumns = ['description', 'key', 'lastUsed', 'active', 'deleteApiKey'];
    public deleteApiKeyTitle: string;
    public deleteApiKeyMessage: string;
    public deleteApiKeyCancelText: string;
    public deleteApiKeyOkText: string;
    public deleteApiKeySuccessMessage: string;

    /**
     * Indicates the loading state of the component.
     */
    public isLoading: boolean;

    constructor(
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog,
        private apiKeyService: ApiKeyService,
        private route: ActivatedRoute,
        private uiUtilsService: UiUtilsService,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private statusCodeService: StatusCodeService,
    ) {}
    /**
     * Function to open a dialog for api key creation.
     */
    public createApiKey() {
        this.dialog
            .open(GenerateApiKeyComponent, {
                disableClose: true,
                data: {},
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.success) {
                    this.isLoading = true;
                    this.getApiKeySummary();
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }
            });
    }

    public ngOnInit() {
        this.translate.get('ADMIN.API_KEY_DASHBOARD.DELETE_API_KEY_TITLE').subscribe((res) => {
            this.deleteApiKeyTitle = res;
        });
        this.translate.get('ADMIN.API_KEY_DASHBOARD.DELETE_API_KEY_MESSAGE').subscribe((res) => {
            this.deleteApiKeyMessage = res;
        });
        this.translate.get('ADMIN.API_KEY_DASHBOARD.CANCEL_TEXT').subscribe((res) => {
            this.deleteApiKeyCancelText = res;
        });
        this.translate.get('ADMIN.API_KEY_DASHBOARD.OK_TEXT').subscribe((res) => {
            this.deleteApiKeyOkText = res;
        });
        this.translate.get('ADMIN.API_KEY_DASHBOARD.DELETE_API_KEY_SUCCESS_MESSAGE').subscribe((res) => {
            this.deleteApiKeySuccessMessage = res;
        });
        this.isLoading = true;
        this.totalPaginationLength = 0;
        this.statusCodeService.addNewCustomer.subscribe((addNewCustomerFlag: boolean) => {
            // get current query params for customer
            if (addNewCustomerFlag) {
                this.route.queryParams.subscribe((params) => {
                    this.selectedCustomerID = params['c'];
                });
            } else {
                this.route.queryParams.subscribe((params) => {
                    this.selectedCustomerID = params['cid'];
                });
            }
        });

        this.getApiKeySummary();
        this.statusCodeService.statusCodeFlag.subscribe((statusCodeFlagValue) => {
            if (statusCodeFlagValue === true) {
                this.isLoading = false;
            }
        });
    }

    /**
     * Function to get all api keys for customer
     */
    public getApiKeySummary() {
        this.apiKeys = [];
        // get all customer summary
        const apiKeysummarySubscription = this.apiKeyService.getApiKeySummary(this.selectedCustomerID).subscribe(
            (apiKeySummaryData: ApiKey[]) => {
                if (apiKeySummaryData) {
                    this.isLoading = false;
                    this.apiKeys = apiKeySummaryData;
                    this.generateUserTable();
                } else if (apiKeySummaryData == null) {
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                    this.generateUserTable();
                }
            },
            (error) => {
                this.isLoading = false;
            },
        );
        this.subscriptions.push(apiKeysummarySubscription);
    }

    /**
     * Method generates a table of api keys
     */
    public generateUserTable() {
        this.apiKeyDataSource = null;
        this.apiKeyDataChange = new BehaviorSubject<ApiKey[]>([]);
        this.apiKeys.forEach((apiKey, index) => {
            const apiKeyCopiedData = this.apiKeySummary;

            const data = {
                cid: apiKey.cid,
                key: apiKey.key,
                lastUsed: apiKey.lastUsed,
                active: apiKey.active,
                description: apiKey.description,
            };
            apiKeyCopiedData.push(data);
            this.apiKeyDataChange.next(apiKeyCopiedData);
        });
        this.apiKeyDataSource = new FilterDataSource(
            this.apiKeyDataChange,
            this.apiKeys,
            this.userPaginator,
            this.userSort,
            this.apiKeyFilterColumns,
        );
        this.userPaginator.pageIndex = 0;
        this.totalPaginationLength = this.apiKeys.length;
        this.isLoading = false;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public onChange(e: MatSlideToggleChange, keyDetail: ApiKey) {
        this.isLoading = true;
        let activeState = 'true';
        if (e.checked === true) {
            activeState = 'true';
        } else {
            activeState = 'false';
        }

        const editApiKeySubscription = this.apiKeyService
            .editApiKey(this.selectedCustomerID, keyDetail, e.checked)
            .subscribe(
                (response) => {
                    if (response) {
                        this.isLoading = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                        this.getApiKeySummary();
                    } else {
                        this.isLoading = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                        this.getApiKeySummary();
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                    this.getApiKeySummary();
                },
            );

        this.subscriptions.push(editApiKeySubscription);
    }

    public deleteApiKey(keyDetail: ApiKey) {
        this.dialog
            .open(ConfirmationDialogComponent, {
                data: {
                    title: this.deleteApiKeyTitle,
                    message: this.deleteApiKeyMessage,
                    cancelText: this.deleteApiKeyCancelText,
                    okText: this.deleteApiKeyOkText,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                if (res.whichButtonWasPressed === 'ok') {
                    this.isLoading = true;

                    const deleteApiKeySubscription = this.apiKeyService
                        .deleteApiKey(this.selectedCustomerID, keyDetail)
                        .subscribe(
                            (response) => {
                                this.isLoading = false;
                                this.snackBar.open(this.deleteApiKeySuccessMessage, 'Dismiss', { duration: 10000 });

                                this.uiUtilsService.safeChangeDetection(this.cdr);
                                this.getApiKeySummary();
                            },
                            (error) => {
                                this.isLoading = false;
                                this.uiUtilsService.safeChangeDetection(this.cdr);
                                this.getApiKeySummary();
                            },
                        );

                    this.subscriptions.push(deleteApiKeySubscription);
                }
            });
    }

    /**
     * Destroy the subscription data
     */
    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
