import { Component, Input, OnInit, ChangeDetectorRef, Output, EventEmitter, OnDestroy, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBarRef as MatSnackBarRef, LegacySimpleSnackBar as SimpleSnackBar, MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MonitorSettingsComponent } from 'app/shared/components/map/monitor-settings/monitor-settings.component';
import { Subscription } from 'rxjs';
import { AddLocationRain } from 'app/shared/models/add-location-rain';
import { AddLocationWeir } from 'app/shared/models/add-location-weir';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { AddLocationFlume } from 'app/shared/models/add-location-flume';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { StringUtils } from 'app/shared/utils/string-utils';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { AddTritonLocationService } from '../add-edit-location/add-triton-location-service';
import { LocationService } from 'app/shared/services/location.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { LocationDashboardService } from 'app/shared/services/location-dashboard.service';
import { InstallationType, INSTALLATION_TYPE } from 'app/shared/models/location-details';
import { MonitorAddLocationSelectable, MonitorIdentifier } from 'app/shared/models/monitorsetting';
import { Customer, customerQueryParam } from 'app/shared/models/customer';
import { LocationArgs, Locations } from 'app/shared/models/locations';
import { AddLocationChannel, Entries } from 'app/shared/models/add-location-channel';
import { AddLocation, AddLocationModalData } from 'app/shared/models/add-location';
import { map, startWith } from 'rxjs/operators';
@Component({
    selector: 'app-add-location',
    templateUrl: './add-location.component.html',
    styleUrls: ['./add-location.component.scss'],
})
export class AddLocationComponent implements OnInit, OnDestroy {
    @Input() public routedFromCustomerEditor: boolean;
    @Input() public customerID: number;
    @Input() public rainGaugeLocations: Array<Locations>;
    @Output() public closeLocationEditor = new EventEmitter<boolean>();

    public isActives: Array<{ value: boolean; text: string }>;
    public result: any;
    public monitorSeriesUI: Array<MonitorAddLocationSelectable>;
    public addLocationObject = new AddLocation();
    private finalCustomerID: number;
    private subscriptions = new Array<Subscription>();
    public installationTypeUI: Array<InstallationType>;
    public installationType: string;
    public addLocationLoadingState: boolean;
    public rain = new AddLocationRain();
    public weir = new AddLocationWeir();
    public installationShape: string;
    public typeOfInstallation: Object;
    public locations: Array<Locations>;
    public isValidLocationName = true;
    public flume = new AddLocationFlume();
    public isMandatory = false;
    public selectedInstallationType: string;
    public isLoading: boolean;

    /**
     * Represents the chosen rain gauge.
     */
    public chosenRainGauge: string;

    /**
     * Represents the rain guage FormControl.
     */
    public assignedRainGaugeCtrl = new FormControl();

    /**
     * Represents the filtered Assigned Rain guage.
     */
    public filteredassignedRainGauge: Observable<Array<Locations>>;

    /**
     * represent model to post channel data
     */
    public channel: AddLocationChannel;

    /**
     * Reg Ex. Pattern for latitude
     */
    public latitudePattern = REGEX_CONFIG.latitudePattern;

    /**
     * Reg Ex. Pattern for longitude
     */
    public longitudePattern = REGEX_CONFIG.longitudePattern;

    /**
     * Reg Ex. Pattern for numeric Decimal value 2Place
     */
    public alphaNumericWitHyphen_Pattern = REGEX_CONFIG.alphaNumericWitHyphen_Pattern;

    /**
     * Reg Ex. Pattern for ip address
     */
    public ipaddressPattern = REGEX_CONFIG.ipaddressPattern;

    /**
     * Reg Ex. Pattern for numeric Decimal value 2Place
     */
    public numericDecimal2PlacePattern = REGEX_CONFIG.numericDecimal2PlacePattern;

    /**
     * Reg Ex. Pattern for Inputs should be numeric values between 0 and 100000 with up to 2 decimal places
     */
    public installationValuePattern = REGEX_CONFIG.installationValuePattern;

    /**
     * Reg Ex. Pattern for alpha numeric
     */
    public alphaNumericPattern = REGEX_CONFIG.alphaNumericPattern;

    /**
     * Reg Ex. Pattern for inputs to include Special Symbols as per User Story 8728
     */
    public alphaNumericWitSpecialSymbols_Pattern = REGEX_CONFIG.alphaNumericWitSpecialSymbols_Pattern;

    /**
     * Reg Ex. Pattern for Inputs should be numeric values between 0 and 100 with up to 6 decimal places
     */
    public depthQuantityPattern = REGEX_CONFIG.numeric0_100With6DecimalPlacesPattern;

    /**
     * represent channel depth
     */
    public depth = new Array<number>();

    /**
     * represent channel quantity
     */
    public quantity = new Array<number>();

    /**
     * input value for Number of Entries
     */
    public numberOfEntries: number;

    /**
     * array to loop input values for depth and quantity depending upon numberOfEntries
     */
    public numberOfTimes: Array<number>;

    /**
     * Reg Ex. Pattern for limiting channel entries from 2 to 257
     */
    public channelEntriesPattern = REGEX_CONFIG.channelEntriesLimit;

    /**
     * Hidden On Activate Location
     */
    public hiddenOnActivate: boolean;
    /**
     * To hide width when circular
     */
    // public isCircular = false;
    public pipeHeight = 'Pipe Height (in)';

    /**
     * To describe pipe height placeholder based on translate
     */
    public pipeHeightPlaceholderFromJson: string;

    /**
     * To describe pipe diameter placeholder based on translate
     */
    public pipeDiameterPlaceholderFromJson: string;

    /**
     * add location success message based on translate
     */
    public locationAddedSuccessMessage: string;

    /**
     * add location error message based on translate
     */
    public locationAddedErrorMessage: string;

    /**
     *dismiss button text in snackbar based on translate
     */
    public dismissButton: string;

    public manholeDepthPlaceholderFromJson: string;

    public pipeWidthPlaceholderFromJson: string;

    public fumeRangePlaceholderFromJson: string;

    public widthPlaceholderFromJson: string;

    public heightPlaceholderFromJson: string;

    public lengthPlaceholderFromJson: string;

    public breathPlaceholderFromJson: string;

    public depthPlaceholderFromJson: string;

    public capacityPlaceholderFromJson: string;

    public quantityPlaceholderFromJson: string;

    public metricUnitsFromJson: string;

    public usUnitsFromJson: string;

    public yestText: string;
    public noText: string;

    public pipeHeightPlaceHolder = 'Pipe Height (in)';
    public manholeDepthPlaceHolder: string;
    public pipeWidthPlaceHolder: string;
    public flumeRangePlaceHolder: string;
    public widthPlaceHolder: string;
    public heightPlaceHolder: string;
    public lengthPlaceHolder: string;
    public breadthPlaceHolder: string;
    public depthPlaceHolder: string;
    public capacityPlaceHolder: string;
    public quantityPlaceHolder: string;

    constructor(
        public dialogRef: MatDialogRef<AddLocationComponent>,
        private locationService: LocationService,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private customerService: CustomerService,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private locationDashboardService: LocationDashboardService,
        @Inject(MAT_DIALOG_DATA) private data: AddLocationModalData,
        private uiUtilsService: UiUtilsService,
        private translate: TranslateService,
        private dateutilService: DateutilService,
        public addTritonLocationService: AddTritonLocationService,
    ) {
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.PIPE_HEIGHT').subscribe((res: string) => {
            this.pipeHeightPlaceholderFromJson = res;
            this.pipeHeight = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.PIPE_DIAMETER').subscribe((res: string) => {
            this.pipeDiameterPlaceholderFromJson = res;
        });
        translate.get('COMMON.LOCATION_ADDED_MESSAGE').subscribe((res: string) => {
            this.locationAddedSuccessMessage = res;
        });
        translate.get('COMMON.LOCATION_ADDED_ERROR').subscribe((res: string) => {
            this.locationAddedErrorMessage = res;
        });
        translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismissButton = res;
        });
        translate.get('COMMON.YES_TEXT').subscribe((res: string) => {
            this.yestText = res;
        });
        translate.get('COMMON.NO_TEXT').subscribe((res: string) => {
            this.noText = res;
        });

        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.MANHOLE_DEPTH').subscribe((res: string) => {
            this.manholeDepthPlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.PIPE_WIDTH').subscribe((res: string) => {
            this.pipeWidthPlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.RAIN_PLACEHOLDER').subscribe((res: string) => {
            this.fumeRangePlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.WIDTH').subscribe((res: string) => {
            this.widthPlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.HEIGHT').subscribe((res: string) => {
            this.heightPlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.LENGTH').subscribe((res: string) => {
            this.lengthPlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.BREADTH').subscribe((res: string) => {
            this.breathPlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.DEPTH').subscribe((res: string) => {
            this.depthPlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.CAPACITY_PLACEHOLDER').subscribe((res: string) => {
            this.capacityPlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.QUANTITY').subscribe((res: string) => {
            this.quantityPlaceholderFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.METRIC_UNITS').subscribe((res: string) => {
            this.metricUnitsFromJson = res;
        });
        translate.get('HOME.MAP.MARKER_LOCATION_DETAIL.US_UNITS').subscribe((res: string) => {
            this.usUnitsFromJson = res;
        });
    }

    public ngOnInit() {
        this.isLoading = true;
        this.isActives = [
            { value: true, text: this.yestText },
            { value: false, text: this.noText },
        ];

        this.monitorSeriesUI = <Array<MonitorAddLocationSelectable>>[
            { monitorSeriesID: MonitorIdentifier.TritonPlus, value: 'TRITON+', text: 'TRITON+' },
            { monitorSeriesID: MonitorIdentifier.Echo, value: 'ECHO', text: 'ECHO' },
            { monitorSeriesID: MonitorIdentifier.RainAlertIII, value: 'RainAlert III', text: 'RainAlert III' }
        ];

        // check if add location opened from CustomerEditor
        if (this.data.isFromCustomerEditor) {
            // set customer editor's customer id to add new location for that customer
            this.finalCustomerID = this.data.customerId;
        } else {
            // get updated customer id
            this.activatedRoute.queryParamMap.subscribe(
                (params: ParamMap) => (this.finalCustomerID = Number(params.get(customerQueryParam))),
            );
        }

        this.customerService.getCustomerById(this.finalCustomerID).subscribe((response: Customer) => {
            // setting customer units
            // checking units are metric or non metric
            const isMetric: boolean = response.unitsType && Number(response.unitsType) === 2;
            const depthUnit = isMetric ? this.metricUnitsFromJson : this.usUnitsFromJson;
            this.pipeHeightPlaceHolder = `${this.pipeHeightPlaceholderFromJson} (${depthUnit})`;
            this.manholeDepthPlaceHolder = `${this.manholeDepthPlaceholderFromJson} (${depthUnit})`;
            this.pipeWidthPlaceHolder = `${this.pipeWidthPlaceholderFromJson} (${depthUnit})`;
            this.flumeRangePlaceHolder = `${this.fumeRangePlaceholderFromJson} (${depthUnit})`;
            this.widthPlaceHolder = `${this.widthPlaceholderFromJson} (${depthUnit})`;
            this.heightPlaceHolder = `${this.heightPlaceholderFromJson} (${depthUnit})`;
            this.lengthPlaceHolder = `${this.lengthPlaceholderFromJson} (${depthUnit})`;
            this.breadthPlaceHolder = `${this.breadthPlaceHolder} (${depthUnit})`;
            this.depthPlaceHolder = `${this.depthPlaceholderFromJson} (${depthUnit})`;
            this.isLoading = false;
        });
        this.getLocations();

        this.resetForm();
        this.addLocationObject.isActive = this.isActives[0].value;

        // check if rain gauge has been added already and if so, exit immediatly
        if (this.rainGaugeLocations && this.rainGaugeLocations.length > 0) {
            return;
        }

        // assign rain gauge
        this.rainGaugeLocations = this.data.rainGaugeLocations;

        // setup rain gauge control
        this.searchableAssignedRainGauge();
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.cdr.detach();
    }

    public onChangeInstallationType(instalationType: InstallationType) {
        // handle circular pipe and hide the width height will be diameter
        /* if (instalationType.text && instalationType.text.split(' ').length > 1
      && instalationType.text.split(' ')[1].trim().toLocaleUpperCase() === 'CIRCULAR') {
      this.isCircular = true;
      this.pipeHeight = this.pipeDiameterPlaceholderFromJson;
    } else {
      this.isCircular = false;
      this.pipeHeight = this.pipeHeightPlaceholderFromJson;
    } */
        this.pipeHeight = this.pipeHeightPlaceholderFromJson;
        const instalationTypeValue = instalationType.value;
        this.selectedInstallationType = instalationTypeValue.substr(0, instalationTypeValue.indexOf(' '));
        this.installationShape = instalationTypeValue.substr(instalationTypeValue.indexOf(' ') + 1);
        this.selectedInstallationType = this.selectedInstallationType.substr(
            0,
            this.selectedInstallationType.length - 1,
        );

        if (this.selectedInstallationType === INSTALLATION_TYPE.PIPE) {
            this.addTritonLocationService.pipe.shapeType = this.installationShape;
            this.typeOfInstallation = this.addTritonLocationService.pipe;
            // added conditions to either match width or height values when required on option change to pipe circular
            if (!this.addTritonLocationService.pipe.width) {
                this.setCircularWidth();
            } else if (!this.addTritonLocationService.pipe.height) {
                this.setCircularHeight();
            } else {
                this.setCircularWidth();
            }
        } else if (this.selectedInstallationType === INSTALLATION_TYPE.RAIN) {
            this.rain.shapeType = this.installationShape;
            this.typeOfInstallation = this.rain;
        } else if (this.selectedInstallationType === INSTALLATION_TYPE.WEIR) {
            this.weir.shapeType = this.installationShape;
            this.typeOfInstallation = this.weir;
        } else if (this.selectedInstallationType === INSTALLATION_TYPE.FLUME) {
            this.flume.shapeType = this.installationShape;
            this.typeOfInstallation = this.flume;
            if (this.installationShape === '405' || this.installationShape === '406') {
                this.isMandatory = true;
            } else {
                this.isMandatory = false;
            }
        } else if (this.selectedInstallationType === INSTALLATION_TYPE.CHANNEL) {
            this.channel = {
                shapeType: this.installationShape,
            };
            this.channel.entries = new Array<Entries>();
            this.typeOfInstallation = this.channel;
        }
    }

    /**
     * This Function Will Validate Input Field for serialNumber
     * It will remove all  whiteSpace from Input field
     */
    public inputValidation(controlName?: string, value?: any) {
        if (controlName === 'serialNumber') {
            this.addLocationObject.serialNumber = value.replace(/\s/g, '');
        } else if (controlName === 'manholedepth') {
            this.addLocationObject.manholedepth = value.replace(/\s/g, '');
        }
    }

    public emitAddLocation(value: boolean) {
        // if the editor is opened from customer editor hide it upon closing
        // if value is true, then refresh map
        if (this.routedFromCustomerEditor) {
            this.closeLocationEditor.emit();
            // otherwise close the dialog
        } else {
            this.dialogRef.close({ success: value });
        }
    }

    public activateLocation() {
        if (this.addLocationObject.latitude === undefined || this.addLocationObject.latitude === null) {
            this.addLocationObject.latitude = 0;
        }
        if (this.addLocationObject.longitude === undefined || this.addLocationObject.longitude === null) {
            this.addLocationObject.longitude = 0;
        }
        this.dialog
            .open(MonitorSettingsComponent, {
                disableClose: true,
                data: {
                    customerId: this.finalCustomerID,
                    LocationName: this.addLocationObject.locationName,
                    Description: this.addLocationObject.description,
                    ManholeAddress: this.addLocationObject.manholeAddress,
                    Latitude: this.addLocationObject.latitude,
                    Longitude: this.addLocationObject.longitude,
                    IsActiveLocation: this.addLocationObject.isActive,
                    MonitorSeries: this.addLocationObject.series,
                    SerialNumber: this.addLocationObject.serialNumber,
                    IpAddress: this.addLocationObject.IPAddress,
                    manholedepth: this.addLocationObject.manholedepth || 0,
                    locationID: -1,
                    instalationType: this.selectedInstallationType,
                    typeOfInstallation: this.typeOfInstallation,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                // set true because at this state new location is alreay added, need to refresh
                this.dialogRef.close({ success: true });
            });
        this.hiddenOnActivate = true;
    }

    public addLocation() {
        this.addLocationLoadingState = true;
        if (this.selectedInstallationType === INSTALLATION_TYPE.PIPE) {
            // width will be same in case of circular pipe as per #10133
            this.addLocationObject.pipe = this.addTritonLocationService.pipe;
            this.addLocationObject.pipe.width = this.addLocationObject.pipe.width || this.addLocationObject.pipe.height;
        } else if (this.selectedInstallationType === INSTALLATION_TYPE.RAIN) {
            this.addLocationObject.rain = this.rain;
        } else if (this.selectedInstallationType === INSTALLATION_TYPE.FLUME) {
            this.addLocationObject.flume = this.flume;
        } else if (this.selectedInstallationType === INSTALLATION_TYPE.WEIR) {
            this.addLocationObject.weir = this.weir;
        } else if (this.selectedInstallationType === INSTALLATION_TYPE.CHANNEL) {
            for (let i = 0; i < this.depth.length; i++) {
                const params = { depth: this.depth[i], quantity: this.quantity[i] };
                this.channel.entries.push(params);
            }
            this.channel.range = Math.max(...this.quantity);
            this.addLocationObject.channel = this.channel;
        }

        // get location id for chosen rain gauge
        if (this.chosenRainGauge) {
            // find rain gauge location
            const locFindResult = this.rainGaugeLocations.filter(
                (loc: Locations) => loc.locationName === this.chosenRainGauge,
            );

            if (locFindResult && locFindResult.length < 1) {
                this.assignedRainGaugeCtrl.setErrors({
                    required: true,
                });
                this.addLocationLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
                return;
            }

            if (this.assignedRainGaugeCtrl.hasError) {
                this.assignedRainGaugeCtrl.setErrors(undefined);
            }

            // assign location as raing gauge
            this.addLocationObject.assignedRainGaugeId =
                locFindResult && locFindResult.length > 0 ? locFindResult[0].locationId : undefined;
        }

        this.subscriptions.push(
            this.locationService
                .addLocation(this.finalCustomerID, this.addLocationObject, this.selectedInstallationType)
                .subscribe(
                    (res) => {
                        this.result = res;
                        this.addLocationLoadingState = false;
                        if (this.result > -1) {
                            const snackbarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(
                                this.addLocationObject.locationName + this.locationAddedSuccessMessage,
                                this.dismissButton,
                            );
                            setTimeout(snackbarRef.dismiss.bind(snackbarRef), 10000);
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                            this.resetForm();

                            // initialize location parameter
                            const locationArgs = <LocationArgs>{
                                customerId: this.finalCustomerID,
                            };

                            //  if this is the first location added update the customer list for the dropdown
                            this.locationDashboardService.getLocationsList(locationArgs).subscribe((response) => {
                                if (response.length === 1) {
                                    this.customerService.getCustomers(true);
                                }
                            });
                            this.emitAddLocation(true);
                        } else {
                            const snackbarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(
                                this.locationAddedErrorMessage,
                                this.dismissButton,
                            );
                        }
                    },
                    (error) => (this.addLocationLoadingState = false),
                ),
        );
    }

    public resetForm() {
        this.addLocationObject = {
            locationName: null,
            description: null,
            manholeAddress: null,
            latitude: null,
            longitude: null,
            isActive: false,
            series: null,
            serialNumber: null,
            IPAddress: null,
            pipe: null,
            assignedRainGaugeId: undefined,
        };

        if (this.customerID && this.customerID > 0) {
            this.finalCustomerID = this.customerID;
        }
    }

    /**
     * This method checks the validity of location name. Duplicate location name  are not allowed on map.
     * @param locationName represents input location name
     */
    public validateDuplicateLocationName(locationName: string) {
        if (locationName && this.locations) {
            locationName = locationName.trim();
            if (
                this.locations.find(
                    (location: Locations) => location.locationName.toLowerCase() === locationName.toLowerCase(),
                )
            ) {
                this.isValidLocationName = false;
            } else {
                this.addLocationObject.locationName = locationName.charAt(0).toUpperCase() + locationName.slice(1);
                this.isValidLocationName = true;
            }
        }
    }

    /**
     * This function capitalized the string value with maximum one space in between word
     */
    public locationDescriptionValidation() {
        this.addLocationObject.description = StringUtils.capitalizedStringWithSpace(this.addLocationObject.description);
    }

    /**
     * This function capitalized the string value with maximum one space in between word
     */
    public manholeAddressValidation() {
        this.addLocationObject.manholeAddress = StringUtils.capitalizedStringWithSpace(
            this.addLocationObject.manholeAddress,
        );
    }

    /**
     * Triggered when the monitor series is updates
     * @param items Represents the name of the series type.
     */
    public onChangeMonitorSeries(items: string) {
        // width will be show by default if monitor series changes
        // this.isCircular = false;
        // ensure args
        if (!items) {
            return;
        }

        // find associated installation
        const installationId = this.monitorSeriesUI.find(
            (monitor: MonitorAddLocationSelectable) => monitor.text === items,
        ).monitorSeriesID;

        // setup installation types
        this.locationService
            .getInstallationType(installationId)
            .subscribe((types: Array<InstallationType>) => (this.installationTypeUI = types));
    }

    /*
     * method to display depth and quantity textboxes depending upon numberOfEntries
     */
    public addNewFields() {
        this.numberOfTimes = Array(+this.numberOfEntries).fill(0);
    }

    private getLocations() {
        if (this.finalCustomerID) {
            // initialize location parameter
            const locationArgs = <LocationArgs>{
                customerId: this.finalCustomerID,
                IncludeInactiveLocations: true,
            };

            this.subscriptions.push(
                this.locationDashboardService.getLocationsList(locationArgs).subscribe((result) => {
                    if (result) {
                        this.locations = <Locations[]>result;
                        this.locations.sort(this.uiUtilsService.sortLocations);
                        this.locationService.locationIDs = this.locations.map((element) => element.locationId);
                    }
                }),
            );
        }
    }

    /**
     * Function will use to search value based on provided input by user.
     */
    private searchableAssignedRainGauge() {
        this.filteredassignedRainGauge = this.assignedRainGaugeCtrl.valueChanges.pipe(startWith(null)).pipe(
            map((rainGauge) => {
                return rainGauge ? this.filterAssignedRainGauge(rainGauge) : this.rainGaugeLocations.slice();
            }),
        );
    }

    /**
     * Function will use to filter value of locations.
     * @param assignedRainGauge
     */
    private filterAssignedRainGauge(assignedRainGauge: string) {
        return this.rainGaugeLocations.filter(
            (rainGauge) => rainGauge.locationName.toLowerCase().indexOf(assignedRainGauge.toLowerCase()) === 0,
        );
    }

    public setCircularWidth() {
        if (this.addTritonLocationService.pipe.shapeType === '200') {
            this.addTritonLocationService.pipe.width = this.addTritonLocationService.pipe.height;
        }
    }

    public setCircularHeight() {
        if (this.addTritonLocationService.pipe.shapeType === '200') {
            this.addTritonLocationService.pipe.height = this.addTritonLocationService.pipe.width;
        }
    }
}
