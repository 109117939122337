<div id="ftp-add-edit-dialog" style="overflow: hidden">
    <div class="popupHeader" mat-dialog-title>
        <span *ngIf="!editMode">{{ 'ADMIN.FTP_SWEEP.ADD_CONFIGURATION' | translate }}</span>
        <span *ngIf="editMode">{{ 'ADMIN.FTP_SWEEP.EDIT_CONFIGURATION' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <form [formGroup]="ftpForm" (submit)="ftpSave()">
        <div class="custom-field">
            <mat-form-field class="each-Item edit-cus-ftp-protocol-hint">
                <mat-label>{{ 'ADMIN.FTP_SWEEP.PROTOCOL' | translate }}</mat-label>
                <mat-select required formControlName="protocol">
                    <mat-option *ngFor="let protocol of protocols" [value]="protocol.value">
                        {{ protocol.viewValue }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="custom-field">
            <mat-form-field class="each-Item edit-cus-ftp-mode-hint">
                <mat-label>{{ 'ADMIN.FTP_SWEEP.MODE' | translate }}</mat-label>
                <mat-select required formControlName="passive">
                    <mat-option *ngFor="let mode of modes" [value]="mode.value">
                        {{ mode.viewValue }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="custom-field">
            <mat-form-field class="each-Item edit-cus-ftp-host-hint">
                <mat-label>{{ 'ADMIN.FTP_SWEEP.HOST' | translate }}</mat-label>
                <input required formControlName="server" matInput placeholder="{{ 'ADMIN.FTP_SWEEP.HOST_PLACEHOLDER' | translate }}" />
            </mat-form-field>
            <span
                class="error"
                *ngIf="(ftpForm.controls['server'].dirty || ftpForm.controls['server'].touched) &&
                    ftpForm.controls['server']?.errors?.pattern && !ftpForm.controls['server']?.errors?.maxlength"
            >
                {{ 'ADMIN.FTP_SWEEP.HOST_ERROR' | translate }}
            </span>
            <span
                class="error"
                *ngIf="(ftpForm.controls['server'].dirty || ftpForm.controls['server'].touched) &&
                    ftpForm.controls['server']?.errors?.maxlength"
            >
                {{ 'ADMIN.FTP_SWEEP.HOST_MAX_CHAR_LENGTH_ERROR' | translate }}
            </span>
        </div>
        <div class="custom-field">
            <mat-form-field class="each-Item edit-cus-ftp-username-hint">
                <mat-label>{{ 'ADMIN.FTP_SWEEP.USERNAME' | translate }}</mat-label>
                <input required formControlName="username" matInput placeholder="{{ 'ADMIN.FTP_SWEEP.USERNAME_PLACEHOLDER' | translate }}" />
            </mat-form-field>
            <span class="error" *ngIf="ftpForm.get('username').dirty && ftpForm.get('username')?.errors?.pattern">
                {{ 'ADMIN.FTP_SWEEP.QUOTE_ERROR' | translate }}
            </span>
            <span class="error" *ngIf="ftpForm.get('username').dirty && (ftpForm.get('username')?.errors?.maxlength || ftpForm.get('username')?.errors?.required)">
                {{ 'ADMIN.FTP_SWEEP.USERNAME_ERROR' | translate }}
            </span>
        </div>
        <div class="custom-field">
            <mat-form-field class="each-Item edit-cus-ftp-password-hint">
                <mat-label>{{ 'ADMIN.FTP_SWEEP.PASSWORD' | translate }}</mat-label>
                <input required formControlName="password" matInput placeholder="{{ 'ADMIN.FTP_SWEEP.PASSWORD_PLACEHOLDER' | translate }}" />
            </mat-form-field>
            <span class="error" *ngIf="ftpForm.get('password').dirty && ftpForm.get('password')?.errors?.pattern">
                {{ 'ADMIN.FTP_SWEEP.QUOTE_ERROR' | translate }}
            </span>
            <span class="error" *ngIf="ftpForm.get('password').dirty && (ftpForm.get('password')?.errors?.maxlength || ftpForm.get('password')?.errors?.required)">
                {{ 'ADMIN.FTP_SWEEP.PASSWORD_ERROR' | translate }}
            </span>
        </div>
        <div class="custom-field">
            <mat-form-field class="each-Item edit-cus-ftp-port-hint">
                <mat-label>{{ 'ADMIN.FTP_SWEEP.PORT' | translate }}</mat-label>
                <input required formControlName="port" matInput type="number" placeholder="{{ 'ADMIN.FTP_SWEEP.PORT_PLACEHOLDER' | translate }}" />
            </mat-form-field>
            <span
                class="error"
                *ngIf="
                    ftpForm.controls['port'].invalid &&
                    (ftpForm.controls['port'].dirty || ftpForm.controls['port'].touched)
                "
            >
                {{ 'ADMIN.FTP_SWEEP.PORT_ERROR' | translate }}
            </span>
        </div>
        <div class="custom-field">
            <mat-form-field class="each-Item edit-cus-ftp-folder-hint">
                <mat-label>{{ 'ADMIN.FTP_SWEEP.FTP' | translate }}</mat-label>
                <input formControlName="folder" matInput placeholder="{{ 'ADMIN.FTP_SWEEP.FTP_PLACEHOLDER' | translate }}" />
            </mat-form-field>
            <span
                class="error double"
                *ngIf="
                    ftpForm.controls['folder'].invalid &&
                    (ftpForm.controls['folder'].dirty || ftpForm.controls['folder'].touched)
                "
            >
                {{ 'ADMIN.FTP_SWEEP.FTP_ERROR' | translate }}
            </span>
        </div>
        <div class="custom-50p">
            <mat-label class="edit-cus-ftp-time-hint">{{ 'ADMIN.FTP_SWEEP.TIME_INTERVAL' | translate }}</mat-label>
            <div class="timer">
                <mat-form-field>
                    <mat-select required formControlName="hour">
                        <mat-option *ngFor="let hour of hours" [value]="hour"> {{ hour }} {{ 'ADMIN.FTP_SWEEP.HOURS' | translate }} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            :
            <div class="timer">
                <mat-form-field>
                    <mat-select required formControlName="minute">
                        <mat-option *ngFor="let minute of minutes" [value]="minute"> {{ minute }} {{ 'ADMIN.FTP_SWEEP.MINUTES' | translate }} </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="custom-field">
            <mat-form-field class="each-Item edit-cus-ftp-import-hint">
                <mat-label>{{ 'ADMIN.FTP_SWEEP.IMPORT_FORMAT' | translate }}</mat-label>
                <mat-select required formControlName="format">
                    <mat-option [value]="0"> {{ 'ADMIN.FTP_SWEEP.IMPORT_FORMAT_OPTION_1' | translate }} </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
    <div mat-dialog-actions>
        <button mat-button class="edit-cus-ftp-close-hint" (click)="close(false)">{{ 'COMMON.CLOSE' | translate }}</button>
        <button mat-button [disabled]="ftpForm.invalid || saving" class="edit-cus-ftp-save-hint" (click)="ftpSave()">
            {{ 'COMMON.SAVE_BUTTON' | translate }}
        </button>
    </div>
</div>
