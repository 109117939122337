import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Config } from 'app/shared/services/config';
import {
    UserSync,
    GetUsersResponse,
    GetPermissionsResponseUser,
    GetPermissionsResponseCustomer,
    UserInfo,
    GetPermissionsResponseFeature,
} from 'app/shared/models/users-permission';
import { AuthService } from 'app/shared/services/auth.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CustomerService } from 'app/shared/services/customer.service';
import { CustomerUser } from 'app/shared/models/customer-user';
import { UserPreferencesDateQuickSpan, UserSettings } from 'app/shared/models/user-settings';
import { IFeatures, IRoles, UserAdminData } from 'app/shared/models/users';
import { DefaultResponse } from 'app/shared/models/default-response';
import { LocationArgs, Locations, RainLocation, TimeSpanNumeric } from 'app/shared/models/locations';
import { Selectable } from 'app/shared/models/selectable';
import { UserSubscription } from 'app/shared/models/notifications';
import { AlarmUserPreference, EditUserRequest, UserSettingsAdminUpdate } from 'app/shared/models/user-preferences';
import { CustomerFeature } from 'app/shared/models/customer';
import { environment } from 'app/environments/environment';
import { combineLatest } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { DateService, MINUTE_TO_MS } from 'app/shared/services/date.service';
const EDITING_AUDIT_ALLOWED = 119;

export const FEATURE_IDS = {
    AUTO_REVIEW: 100,
    BLOCKAGE_PREDTICTION: 101,
    CUSTOMER_EDITOR: 102,
    SCHEDULE_COLLECTS: 103,
    ALARM_MAINTENANCE: 104,
    LOCATION_IMPORT: 105,
    REGISTER_USER: 106,
    API_KEYS: 107,
    WET_DRY_OVERFLOW: 108,
    CUSTOMER_EVENT_EDITOR: 126,
    USER_EVENT_EDITOR: 127,
    /** Is data editing allowed for USER, should be checked in with 118 (customer has data editing feature) */
    BASIC_DATA_EDITING: 109,
    SLIICER_CUSTOMER_FEATURE: 110,
    SLIICER_USER_FEATURE: 111,
    ADVANCED_REPORTS: 112,
    ANSR_ENGINE: 113,
    MONITOR_CONFIGURATION: 114,
    // TODO: NOT USED: KML_LAYERS: 115,
    DATA_EDIT_COMMENTS: 116,
    ADVANCED_DATA_EDITING: 117,
    /** Is data editing allowed for CUSTOMER (has customer this feature) */
    DATA_EDITING_ALLOWED_CUSTOMER: 118,
    EDITING_AUDIT_ALLOWED: 119,
    VAULT_DELETE: 120,
    APPROVED_DATA_ONLY: 121,
    MANAGE_CONFIRMATION_POINTS: 124,
    RAW_DATA: 125,
    EVENTS: 126,
    EVENT_EDITOR: 127,
    CUMULATIVE_RAIN: 128
};

export const USER_ROLES = {
    ADMIN: 'Admin',
    CUSTOMER_ADMIN: 'CustomerAdmin',
    NONE: 'None',
    CUSTOMER_USER_MANAGER: 'CustomerUserManager',
};

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    private adminUserResponse: GetUsersResponse;
    private getAllUsersForCustomerResponse: GetUsersResponse;
    public isFlowBalanceReport: boolean;
    public isVaultDelete = new BehaviorSubject<boolean>(false);
    private cacheAlarms = {};

    /**
     * Represents a collection of members whom are allowed to see the User Sync button.
     */
    private allowedSyncMembers: Array<string>;

    /**
     * Represents favorite customer
     */
    public defaultCustomer = new BehaviorSubject<number>(null);
    /** Represents updated user Information */
    public userID = new BehaviorSubject<string>(null);
    public userEmail = new BehaviorSubject<string>(null);
    public userRoles = new BehaviorSubject<string[]>([]);
    /** Basic user information: ID, name, email, ... */
    public userInfo$ = new BehaviorSubject<UserInfo>(null);
    /** IMPORTANT: Only for edit purposes */
    public userInformation = new BehaviorSubject<EditUserRequest>(null);
    public userPhoneNumber = new BehaviorSubject<string>(null);
    public passwordExpirationDays = new BehaviorSubject<number>(null);
    /**
     * Flag to show/hide location import feature
     */
    public isLocationImportAllowed = new BehaviorSubject<boolean>(false);
    /**
     * Flag to show/hide schedule collect feature
     */
    public isScheduleCollectAllowed = new BehaviorSubject<boolean>(false);
    /**
     * Flag to show/hide AlamMaintenance feature
     */
    public isAlarmMaintenanceAllowed = new BehaviorSubject<boolean>(false);
    /**
     * to track customer editor permission
     */
    public isCustomerEditor = new BehaviorSubject<boolean>(false);

    /**
     * to track register user permission
     */
    public isRegisterUserFeatureAllowed = new BehaviorSubject<boolean>(false);
    /**
     * represents secondary email
     */
    public secondaryEmail: string;

    // USER BASED PERMISSIONS
    /** Flag to show/hide Basic Data Editing feature */
    public isRawDataEditingAllowed = new BehaviorSubject<boolean>(false);
    public isEventEditorOnUserAllowed = new BehaviorSubject<boolean>(false);
    public isMonitorEditor = new BehaviorSubject<boolean>(false);
    public isDataEditingAuditReportAllowed = new BehaviorSubject<boolean>(false);
    public isApprovedDataOnly = new BehaviorSubject<boolean>(false);
    public isAddConfirmationAllowed = new BehaviorSubject<boolean>(false);
    public isManageConfirmationPointsAllowed = new BehaviorSubject<boolean>(false);

    // CUSTOMER BASED PERMISSIONS
    public isDataEditCommentsAllowed = new BehaviorSubject<CustomerFeature>({ value: false });
    public isEventEditorOnCustomerAllowed = new BehaviorSubject<CustomerFeature>({ value: false });
    public isAdvancedDataEditingAllowed = new BehaviorSubject<CustomerFeature>({ value: false });
    public isAdvancedReportsAllowed = new BehaviorSubject<CustomerFeature>({ value: false });
    public isANSREngine = new BehaviorSubject<CustomerFeature>({ value: false });
    public isAutoReviewAllowed = new BehaviorSubject<CustomerFeature>({ value: false });
    public isBlockagePredictionAllowed = new BehaviorSubject<CustomerFeature>({ value: false });
    public isCumulativeRainAllowed = new BehaviorSubject<CustomerFeature>({ value: false });

    // MIXED PERMISSIONS (BOTH USER AND CUSTOMER)
    public isBasicDataEditingAllowed = new BehaviorSubject<boolean>(false);
    public isSliicerAllowed = new BehaviorSubject<CustomerFeature>({ value: false });

    public userSettings = new BehaviorSubject<UserSettings>(null);
    public staticTracerSetting = new BehaviorSubject(false);
    public userFeatures$ = new BehaviorSubject<GetPermissionsResponseFeature[]>(null);

    constructor(
        public http: HttpClient,
        public authService: AuthService,
        private customerService: CustomerService,
        private dateService: DateService
        ) {
        // Listen to updates of customer. User permissions has to be combined with customer ones to figure where user has access to
        this.customerService.currentCustomerFeatures.subscribe((customerFeatures) => {
            this.applyPermissions(this.userFeatures$.getValue(), customerFeatures);
        });
    }

    /**
     * userName - will return the loged in username
     */
    public get userName() {
        return this.authService.userName;
    }

    public changePrimaryEmail(newEmail: string) {
        const curUserInfo = this.userInfo$.getValue();
        curUserInfo.userEmail = newEmail;
        this.userInfo$.next(curUserInfo);
    }

    public refreshToken() {
        return this.http.post<Array<DefaultResponse<Object>>>(Config.urls.refresh, {}).pipe(
            map(
                (result) => {
                    return result;
                },
                () => {
                    return EMPTY;
                },
            ),
        );
    }

    public applyPermissions(
        userFeatures?: GetPermissionsResponseFeature[],
        customerFeatures?: GetPermissionsResponseFeature[],
    ) {
        /** Check if current Subject value is different from new one, if yes then do NEXT on Subject */
        const notifyOnUserFeatureChange = (sub: BehaviorSubject<boolean>, newVal) => {
            if (sub.getValue() !== newVal) sub.next(newVal);
        };
        const notifyOnCustomerFeatureChange = (
            sub: BehaviorSubject<CustomerFeature>,
            newVal?: GetPermissionsResponseFeature,
        ) => {
            if (sub.getValue().value && !newVal) {
                sub.next({ value: false });
            } else if (!sub.getValue().value && newVal) {
                sub.next({ value: true, viewable: newVal.viewable, isbeta: newVal.isbeta });
            }
        };

        if (userFeatures) {
            notifyOnUserFeatureChange(
                this.isCustomerEditor,
                userFeatures.some((x) => x.id === FEATURE_IDS.CUSTOMER_EDITOR),
            );
            notifyOnUserFeatureChange(
                this.isScheduleCollectAllowed,
                userFeatures.some((x) => x.id === FEATURE_IDS.SCHEDULE_COLLECTS),
            );
            notifyOnUserFeatureChange(
                this.isAlarmMaintenanceAllowed,
                userFeatures.some((x) => x.id === FEATURE_IDS.ALARM_MAINTENANCE),
            );
            notifyOnUserFeatureChange(
                this.isLocationImportAllowed,
                userFeatures.some((x) => x.id === FEATURE_IDS.LOCATION_IMPORT),
            );
            notifyOnUserFeatureChange(
                this.isRegisterUserFeatureAllowed,
                userFeatures.some((x) => x.id === FEATURE_IDS.REGISTER_USER),
            );
            notifyOnUserFeatureChange(
                this.isRawDataEditingAllowed,
                userFeatures.some((x) => x.id === FEATURE_IDS.RAW_DATA),
            );
            notifyOnUserFeatureChange(
                this.isEventEditorOnUserAllowed,
                userFeatures.some((x) => x.id === FEATURE_IDS.USER_EVENT_EDITOR),
            );
            notifyOnUserFeatureChange(
                this.isAddConfirmationAllowed,
                userFeatures.some((x) => x.id === FEATURE_IDS.MANAGE_CONFIRMATION_POINTS),
            );
            notifyOnUserFeatureChange(
                this.isApprovedDataOnly,
                userFeatures.some((x) => x.id === FEATURE_IDS.APPROVED_DATA_ONLY),
            );
            notifyOnUserFeatureChange(
                this.isMonitorEditor,
                userFeatures.some((x) => x.id === FEATURE_IDS.MONITOR_CONFIGURATION),
            );
            notifyOnUserFeatureChange(
                this.isVaultDelete,
                userFeatures.some((x) => x.id === FEATURE_IDS.VAULT_DELETE),
            );
            notifyOnUserFeatureChange(
                this.isDataEditingAuditReportAllowed,
                userFeatures.some((x) => x.id === FEATURE_IDS.EDITING_AUDIT_ALLOWED),
            );
            notifyOnUserFeatureChange(
                this.isManageConfirmationPointsAllowed,
                userFeatures.some((x) => x.id === FEATURE_IDS.MANAGE_CONFIRMATION_POINTS),
            );

        }

        if (customerFeatures) {
            notifyOnCustomerFeatureChange(
                this.isAdvancedReportsAllowed,
                customerFeatures.find((x) => x.id === FEATURE_IDS.ADVANCED_REPORTS),
            );
            notifyOnCustomerFeatureChange(
                this.isEventEditorOnCustomerAllowed,
                customerFeatures.find((x) => x.id === FEATURE_IDS.CUSTOMER_EVENT_EDITOR),
            );
            notifyOnCustomerFeatureChange(
                this.isANSREngine,
                customerFeatures.find((x) => x.id === FEATURE_IDS.ANSR_ENGINE),
            );
            notifyOnCustomerFeatureChange(
                this.isAutoReviewAllowed,
                customerFeatures.find((x) => x.id === FEATURE_IDS.AUTO_REVIEW),
            );
            notifyOnCustomerFeatureChange(
                this.isBlockagePredictionAllowed,
                customerFeatures.find((x) => x.id === FEATURE_IDS.BLOCKAGE_PREDTICTION),
            );
            notifyOnCustomerFeatureChange(
                this.isAdvancedDataEditingAllowed,
                customerFeatures.find((x) => x.id === FEATURE_IDS.ADVANCED_DATA_EDITING),
            );
            notifyOnCustomerFeatureChange(
                this.isDataEditCommentsAllowed,
                customerFeatures.find((x) => x.id === FEATURE_IDS.DATA_EDIT_COMMENTS),
            );
            notifyOnCustomerFeatureChange(
                this.isCumulativeRainAllowed,
                customerFeatures.find((x) => x.id === FEATURE_IDS.CUMULATIVE_RAIN),
            );

        }

        if (userFeatures && customerFeatures) {
            notifyOnUserFeatureChange(
                this.isBasicDataEditingAllowed,
                customerFeatures.some((x) => x.id === FEATURE_IDS.DATA_EDITING_ALLOWED_CUSTOMER) &&
                    userFeatures.some((x) => x.id === FEATURE_IDS.BASIC_DATA_EDITING),
            );

            if (environment.enableSliicer) {
                notifyOnCustomerFeatureChange(
                    this.isSliicerAllowed,
                    customerFeatures.some((x) => x.id === FEATURE_IDS.SLIICER_CUSTOMER_FEATURE) &&
                        userFeatures.some((x) => x.id === FEATURE_IDS.SLIICER_USER_FEATURE)
                        ? customerFeatures.find((x) => x.id === FEATURE_IDS.SLIICER_CUSTOMER_FEATURE)
                        : null,
                );
            }
        }
    }

    /**
     * get user's permissions
     * @param uid - user ID
     */
    public getUserPermissions(uid?: string): Observable<GetPermissionsResponseUser> {
        if (uid) {
            return this.http.get<GetPermissionsResponseUser>(`${Config.urls.permission}/${uid}`);
        } else {
            return combineLatest([
                this.http.get<GetPermissionsResponseUser>(`${Config.urls.permissionv2}`),
                this.customerService.getCustomers(),
            ]).pipe(
                map(([res, customers]) => {
                    const response = { payload: res } as GetPermissionsResponseUser;

                    if (!uid) {
                        // customers[0].isCurrentlySelected = true;
                        response.payload.customers = customers.map((x) => {
                            return { customer: x };
                        }) as any;
                        if (response.customers) {
                            // TODO: figure out if this ever happens
                            this.customerService.userCustomersWithLocations.next(response.customers);
                            this.customerService.userCustomers.next(response.customers);
                        } else {
                            this.customerService.userCustomersWithLocations.next(
                                new Array<GetPermissionsResponseCustomer>(),
                            );
                        }

                        this.userInfo$.next({
                            userID: response.payload.userID,
                            firstName: response.payload.firstName,
                            lastName: response.payload.lastName,
                            userEmail: response.payload.userEmail,
                            secondaryEmail: response.payload.secondaryEmail,
                            phoneNumber: response.payload.phoneNumber,
                            isIdex: response.payload.isIdex
                        });

                        if (response.payload.userID) {
                            this.userID.next(response.payload.userID);
                        }
                        if (response.payload.userEmail) {
                            this.userEmail.next(response.payload.userEmail);
                        }
                        if (response.payload.roles) {
                            this.userRoles.next(response.payload.roles);
                        }

                        this.applyPermissions(
                            response.payload.features,
                            this.customerService.currentCustomerFeatures.getValue(),
                        );

                        if (response.payload.features) {
                            this.userFeatures$.next(response.payload.features);
                        }

                        if (response.payload.customers) {
                            this.customerService.userCustomers.next(response.payload.customers);
                        }
                    }

                    return response;
                }),
            );
        }
    }

    /**
     * get all user notification
     * @param customerId
     */
    public getAlarmUserPreferenceNotification(customerId: number): Observable<UserSubscription[]> {
        return this.http
            .get<UserSubscription[]>(Config.serviceUrl + Config.urls.notifications + `?customerId=${customerId}`)
            .pipe(
                map(
                    (result) => {
                        return result;
                    },
                    (error) => {
                        return EMPTY;
                    },
                ),
            );
    }

    public get customerAlarmsCache() {
        return this.cacheAlarms;
    }

    public set customerAlarmsCache(val: any) {
        this.cacheAlarms[val.cid] = val.res;
    }

    /**
     * Saves the default customer.
     * @param userID the current user surrogate identifier.
     * @param defaultCustomer the default customer preference.
     */
    public editUser(userdetails: EditUserRequest): Observable<DefaultResponse<any>> {
        return this.http.put<DefaultResponse<{}>>(Config.serviceUrl + Config.urls.editUser, userdetails).pipe(
            map(
                (result) => {
                    this.defaultCustomer.next(userdetails.defaultCustomer);
                    this.userInformation.next(userdetails);
                    return result;
                },
                (error) => {
                    return EMPTY;
                },
            ),
        );
    }
    public getCustomerUsers(
        customerId: number,
        excludeLoggedInUser: boolean = null,
        pageSize: number = null,
        pageIndex: number = null,
        searchValue: string = null,
        includeDisabled = true,
    ): Observable<GetUsersResponse> {
        pageSize = !isNaN(pageSize) ? Number(pageSize) : 25;
        pageIndex = !!pageIndex ? Number(pageIndex) : 1;
        searchValue = !!searchValue ? searchValue : '';
        const excludeLoggedInUserQString = excludeLoggedInUser
            ? `&excludeLoggedInUser=true`
            : '&excludeLoggedInUser=false';
        const url =
            Config.serviceUrl +
            `${Config.urls.getCustomerUsers}?customerId=${customerId}${excludeLoggedInUserQString}&pageSize=${pageSize}&startPage=${pageIndex}&searchValue=${searchValue}&includeDisabled=${includeDisabled}`;
        return this.http.get(url).pipe(
            map((res: GetUsersResponse) => {
                this.getAllUsersForCustomerResponse = res;
                return this.getAllUsersForCustomerResponse;
            }),
        );
    }

    /**
     * a save Alarm User Preference Notification
     * @param userPreferenceParams
     */
    public saveUserPreferenceNotification(
        userEmail: string,
        customerId: number,
        alarmUserPreferenceUIRequest: Array<AlarmUserPreference>,
    ) {
        return this.http
            .post(
                Config.serviceUrl + Config.urls.notifications + `userEmail=${userEmail}&customerId=${customerId}`,
                alarmUserPreferenceUIRequest,
            )
            .pipe(
                map(
                    (result) => {
                        return result;
                    },
                    (error) => {
                        return EMPTY;
                    },
                ),
            );
    }

    public adminSaveUserPreferenceNotification(guid: string, userSettingsAdminUpdate: UserSettingsAdminUpdate) {
        return this.http
            .post(Config.urls.userSettingsAdminUpdate + `?updateUserId=${guid}`, userSettingsAdminUpdate)
            .pipe(
                map(
                    (result) => {
                        return result;
                    },
                    (error) => {
                        return EMPTY;
                    },
                ),
            );
    }

    public adminUpdateUser(guid: string, userData: UserAdminData) {
        return this.http.put(Config.urls.usersAdminUpdate + `?updateUserId=${guid}`, userData).pipe(
            map(
                (result) => {
                    return result;
                },
                (error) => {
                    return EMPTY;
                },
            ),
        );
    }
    /**
     * getAllUsers will return list of all users from backend.
     */
    public getAllUsers(
        pageSize: number = null,
        pageIndex: number = null,
        searchValue: string = null,
        selectedCustomerId: number = null,
    ): Observable<GetUsersResponse> {
        pageSize = !!pageSize ? Number(pageSize) : 25;
        pageIndex = !!pageIndex ? Number(pageIndex) : 1;
        searchValue = !!searchValue ? searchValue : '';
        selectedCustomerId = !!selectedCustomerId ? selectedCustomerId : null;
        const url = `${Config.urls.usersSummary}?pageSize=${pageSize}&startPage=${pageIndex}&searchValue=${searchValue}&customerId=${selectedCustomerId}`;
        return this.http.get<GetUsersResponse>(url).pipe(
            map((res: GetUsersResponse) => {
                this.adminUserResponse = res;
                return this.adminUserResponse;
            }),
        );
    }
    public getAllUsersWithoutPagination(
        searchValue: string = null,
        selectedCustomerId: number = null,
    ): Observable<GetUsersResponse> {
        searchValue = !!searchValue ? searchValue : '';
        selectedCustomerId = !!selectedCustomerId ? selectedCustomerId : null;
        const url = `${Config.urls.usersSummary}?&searchValue=${searchValue}&customerId=${selectedCustomerId}`;
        return this.http.get<GetUsersResponse>(url).pipe(
            map((res: GetUsersResponse) => {
                this.adminUserResponse = res;
                return this.adminUserResponse;
            }),
        );
    }
    public getAllUsersBulk(searchValue = '') {
        if (!searchValue) {
            searchValue = '';
        }
        const url = `${Config.urls.usersSummary}`;
        return this.http.get<GetUsersResponse>(url + `?pageSize=${10}&startPage=${1}&searchValue=${searchValue}`).pipe(
            mergeMap((res: GetUsersResponse) => {
                return this.http
                    .get<GetUsersResponse>(url + `?pageSize=${res.count}&startPage=${1}&searchValue=${searchValue}`)
                    .pipe(
                        map((res: GetUsersResponse) => {
                            this.adminUserResponse = res;
                            return this.adminUserResponse;
                        }),
                    );
            }),
        );
    }
    /**
     * Determines if the current user matches the predefined list of names.
     * Temporary functionality until full user permissions and users update API is completed.
     *
     * @param userName User name to compare to.
     */
    public isAllowedSync(): Observable<boolean> {
        // ensure args
        if (!this.userName) {
            return of(false);
        }

        // if allowed users are stored in memory, use that list
        if (this.allowedSyncMembers && this.allowedSyncMembers.length > 0) {
            return of(
                this.allowedSyncMembers.some(
                    (qualifiedUserName: string) => qualifiedUserName.toLowerCase() === this.userName.toLowerCase(),
                ),
            );
        }

        // get fresh list of users
        return this.http
            .get(Config.urls.userSyncPermissions)
            .pipe(tap((permissions: UserSync) => (this.allowedSyncMembers = permissions.permittedUsers)))
            .pipe(
                map((permissions: UserSync) =>
                    permissions.permittedUsers.some((qualifiedUserName: string) => {
                        return qualifiedUserName.toLowerCase() === this.userName.toLowerCase();
                    }),
                ),
            );
    }

    /**
     * Initializes the synchronization of users.
     */
    public syncUsers(): Observable<boolean> {
        return this.http.get<boolean>(Config.urls.userSync);
    }

    public getAllFeatures(): Observable<Array<IFeatures>> {
        return this.http.get<Array<IFeatures>>(Config.serviceUrl + Config.urls.features);
    }

    public getAllRoles(): Observable<Array<IRoles>> {
        return this.http.get<Array<IRoles>>(Config.serviceUrl + Config.urls.roles);
    }
    public postDataToServer(uid, objectToSend): Observable<Array<DefaultResponse<object>>> {
        return this.http.post<Array<DefaultResponse<object>>>(Config.urls.permission + `/` + `${uid}`, objectToSend);
    }
    public assignUsers(customerId: number, users: CustomerUser): Observable<Array<DefaultResponse<object>>> {
        return this.http.put<Array<DefaultResponse<object>>>(
            Config.serviceUrl + Config.urls.assignUsers + `?cid=${customerId}`,
            users,
        );
    }
    /**
     * Below method would be used to register user
     * @param First Name Last Name User Name and User role
     */
    public registerUser(registerUserObject): Observable<string> {
        this.secondaryEmail = registerUserObject.secondaryEmail
            ? '&SecondaryEmail=' + registerUserObject.secondaryEmail
            : '';
        const userRole = '&UserRole=' + registerUserObject.userRole;
        const url =
            Config.urls.registerUser +
            `Email=${registerUserObject.userEmail}&UserName=${registerUserObject.userName}&FirstName=${registerUserObject.firstName}&LastName=${registerUserObject.lastName}&IsADD${registerUserObject.isAAD}` +
            this.secondaryEmail +
            userRole;
        return this.http.post<string>(`${Config.serviceUrl}${url}`, {});
    }

    /**
     * Below method would be used to fetch the inactive and active locations
     * @param params represents the location service paramaters
     */
    public getLocationsList(params: LocationArgs): Observable<Locations[]> {
        // creating HttpParams for HttpClient request

        let httpParams: HttpParams = new HttpParams();

        // setting all the existing key value of param to HttpParams
        Object.keys(params).forEach(function (key) {
            httpParams = httpParams.append(key, params[key]);
        });
        let query = '';
        if (params.customerId > 0) {
            query += `CID=${params.customerId}`;
        }
        if (params.uid) {
            query += `&UID=${params.uid}`;
        }
        if (params.IncludeInactiveLocations != undefined) {
            query += `&IncludeInactiveLocations=${params.IncludeInactiveLocations}`;
        }
        if (params.locationGroupId != undefined) {
            query += `&LocationGroupId=${params.locationGroupId}`;
        }
        query += `&PageSize=-1&StartPage=1`;
        return this.http.get<any>(`${Config.urls.locationsList}?${query}`);
    }

    /**
     * Below method would be used to update the flowbalance details
     * @param theme
     */
    public flowBalanceDetails(customerId: number, locationId: number, flowBalanceArgs: Selectable[]) {
        return this.http.post<string>(
            `${Config.urls.flowBalance}?cid=${customerId}&lid=${locationId}`,
            flowBalanceArgs,
        );
    }

    public getRainLocations(
        cid: number,
        includeInactiveLocations: boolean,
        locationGroupId: number = null,
        validateLocations = false,
    ): Observable<RainLocation[]> {
        const settings = this.userSettings.getValue();
        const { includeRainLocations }  = settings;
        const locationGroupPart = locationGroupId !== null ? `&locationGroupId=${locationGroupId}` : '';
        return this.http.get<RainLocation[]>(
            Config.urls.rainLocations
            + `?cid=${cid}&includeRainLocations=${includeRainLocations}&includeInactiveLocations=${includeInactiveLocations}&validateLocations=${validateLocations}${locationGroupPart}`
        );
    }

    /**
     * Below method would be used to update user theme preference
     * @param theme
     */
    public updateUserSettingsTheme(theme: string) {
        return this.http.post(Config.urls.userSettingsTheme, JSON.stringify(theme)).pipe(
            map(
                (result) => {
                    return result;
                },
                (error) => {
                    return EMPTY;
                },
            ),
        );
    }
    /**
     * Activates / deactivate customers
     */
    public activateStatus(userID: string, activeState: boolean) {
        const headers = new Headers();
        headers.append('Content-Type', 'charset=utf-8');
        const url = `?userID=${userID}&isActive=${activeState}`;
        const res = this.http.put<any>(Config.serviceUrl + Config.urls.activateUser + url, { headers: headers });
        res.pipe(
            catchError(() => {
                return res;
            }),
        );
        return res;
    }

    public getUserSettings(): Observable<UserSettings> {
        return this.http.get<UserSettings>(`${Config.urls.userSettings}`).pipe(catchError((err) => of({})));
    }

    public adminGetUserSettings(guid: string): Observable<UserSettings> {
        return this.http.get<UserSettings>(`${Config.urls.userSettingsAdminGet}?updateUserId=${guid}`);
    }

    public getUsrPermissions(uid: string): Observable<GetPermissionsResponseUser> {
        return this.http.get<GetPermissionsResponseUser>(`${Config.urls.userPermission}/${uid}`);
    }

    public adminGetUser(guid: string): Observable<UserAdminData> {
        return this.http.get<UserAdminData>(`${Config.urls.usersAdminGet}?userId=${guid}`);
    }
    public updateUserSettings(settings: UserSettings): Observable<Object> {
        return this.http.post(`${Config.urls.userSettings}`, settings).pipe(
            map(
                () => {
                    return EMPTY;
                },
                () => {
                    return EMPTY;
                },
            ),
        );
    }

    public getStaticTracerSetting(): Observable<boolean> {
        return this.http.get<boolean>(Config.urls.staticTracer);
    }

    public updateStaticTracerSetting(newValue: boolean) {
        return this.http.post(Config.urls.staticTracer, newValue);
    }

    public resetUserPassword(username): Observable<Object> {
        return this.http.post(`${Config.urls.usersResetPassword}`, { user_name: username });
    }

    public checkUsernameExist(username: string) {
        return this.http.get(`${Config.urls.checkUsernameExists}?username=${username}`);
    }

    public checkUserEmailExists(emailaddress: string) {
        return this.http.get(`${Config.urls.checkUserEmailExists}?emailaddress=${emailaddress}`);
    }

    public getThemeName(isDarkThemeEnabled: boolean) {
        return isDarkThemeEnabled ? 'dark' : 'light';
    }

    public getQuickSpanCumulativeRainSetting() {
        return this.userSettings.getValue().quickSpanCumulativeRain ?? UserPreferencesDateQuickSpan.OneMonth;
    }

    public getQuickSpanCumulativeRainDateRange(): {startDate: Date, endDate: Date} {
        const quickSpanCumulativeRain = this.getQuickSpanCumulativeRainSetting();

        const out = {
            startDate: null,
            endDate: null
        }
        const now = new Date();
        out.endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
        switch (quickSpanCumulativeRain) {
            case UserPreferencesDateQuickSpan.OneDay:
                out.startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0);
                out.endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59);
                break;
            case UserPreferencesDateQuickSpan.OneWeek:
                out.startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7, 0, 0, 0);
                break;
            case UserPreferencesDateQuickSpan.LastMonth:
                out.startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1, 0, 0, 0);
                out.endDate = new Date(
                    now.getFullYear(),
                    now.getMonth() - 1,
                    this.dateService.getDaysInMonth(now.getMonth()-1, now.getFullYear()),
                    23, 59, 59
                );
                break;
            default: case UserPreferencesDateQuickSpan.OneMonth:
                out.startDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate(), 0, 0, 0);
                break;
            case UserPreferencesDateQuickSpan.ThreeMonths:
                out.startDate = new Date(now.getFullYear(), now.getMonth() - 3, now.getDate(), 0, 0, 0);
                break;
            case UserPreferencesDateQuickSpan.SixMonths:
                out.startDate = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate(), 0, 0, 0);
                break;
            case UserPreferencesDateQuickSpan.OneYear:
                out.startDate = new Date(now.getFullYear(), now.getMonth() - 12, now.getDate(), 0, 0, 0);
                break;
        }

        return out;
    }

    public convertDateRangeToUTCString(dateRange: {startDate: Date, endDate: Date}) {
        const now = new Date();
        const startDate = new Date(dateRange.startDate.getTime() - now.getTimezoneOffset() * MINUTE_TO_MS);
        const endDate = new Date(dateRange.endDate.getTime() - now.getTimezoneOffset() * MINUTE_TO_MS);

        return {
            startDate: startDate.toISOString().replace('T', ' '),
            endDate: endDate.toISOString().replace('T', ' ')
        }
    }
}
