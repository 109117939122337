<div mat-dialog-actions>
    <div class="pre-edit-controls auto-margin">
        <span matTooltip="{{ 'COMMON.ACTIVATE_BUTTON' | translate }}" matTooltipPosition="above" class="auto-margin">
            <button
                mat-button
                class="btn-activate"
                type="button"
                [disabled]="form.invalid || !data.editMode || isActivateButtonDisabled() || !isMonitorCanAddEdit || isUSGSseriesSelected()"
                [ngClass]="{ 'active-deactivate': form.valid && data.editMode && isMonitorCanAddEdit && !isUSGSseriesSelected() && !isActivateButtonDisabled()}"
                (click)="activateLocation.emit()"
            >
                <img class="img-icon-24 enable" src="/assets/images/active.png" />
                <img class="img-icon-24 disable" src="/assets/images/active_disable.png" />
            </button>
        </span>
        <ng-container *ngIf="isHomeMap && data.editMode">
            <span matTooltip="{{ 'HOME.MAP.LOCATION_DASHBOARD_TOOLTIP' | translate }}" matTooltipPosition="above">
                <button mat-button type="button" (click)="openLocationDashboard.emit()">
                    <mat-icon class="mat-24">show_chart</mat-icon>
                </button>
            </span>
            <span matTooltip="{{ 'HOME.COLLECTION_WIDGET.COLLECT_BUTTON' | translate }}" matTooltipPosition="above">
                <button mat-button type="button" (click)="collectLocationClicked.emit()" [disabled]="isUSGSseriesSelected()">
                    <mat-icon class="mat-24">system_update_alt</mat-icon>
                </button>
            </span>
            <span matTooltip="{{ 'HOME.MAP.SCHEDULR_COLLECTION_TOOLTIP' | translate }}" matTooltipPosition="above">
                <button mat-button type="button" (click)="scheduleCollectClicked.emit()" [disabled]="isUSGSseriesSelected()">
                    <mat-icon class="mat-24">update</mat-icon>
                </button>
            </span>
        </ng-container>
        <span
            matTooltip="{{ 'COMMON.EXPORT_TEXT' | translate }}"
            matTooltipPosition="above"
            *ngIf="data.editMode && !data.isFromNotificationDash"
        >
            <button mat-button type="button" (click)="exportLocationClicked.emit()" [disabled]="isUSGSseriesSelected()">
                <mat-icon class="mat-24">get_app</mat-icon>
            </button>
        </span>
        <span
            matTooltip="{{ 'DIAGNOSTICS.DIAGNOSTICS' | translate }}"
            matTooltipPosition="above"
            *ngIf="showLocationDiagnostics && !data.isFromNotificationDash"
        >
            <app-diagnostics-menu
                [customerId]="data.customerId"
                [locationId]="data.locationDetails.locationID"
                [deviceTypes]="deviceTypes"
                [monitorSeriesType]="data.locationDetails.series"
            >
                <img class="img-icon-24" src="/assets/images/diagnostic.jpg" />
            </app-diagnostics-menu>
        </span>
    </div>

    <div class="app-flex-filler"></div>
    <div class="auto-margin">
        <button mat-button (click)="cancelClicked.emit(true)">{{ 'COMMON.CANCEL' | translate }}</button>
    </div>

    <div class="auto-margin save-close">
        <button
            mat-button
            (click)="save.emit({ shouldClose: true, isActivationRequired: false })"
            [disabled]="form.invalid || form.pristine"
        >
            {{ 'COMMON.SAVE_CLOSE_BUTTON' | translate }}
        </button>
        <button class="options" mat-button [matMenuTriggerFor]="menu" [disabled]="form.invalid || form.pristine">
            <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before" class="options-content">
            <button mat-menu-item (click)="save.emit({ shouldClose: false, isActivationRequired: false })">
                {{ 'COMMON.SAVE_BUTTON' | translate }}
            </button>
        </mat-menu>
    </div>
</div>
