import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ChangeDetectionStrategy,
    ViewEncapsulation,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { PercentFullReportService } from '../percent-full-report.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import {
    activeInactiveLocationQueryParam,
    customerLocationGroupQueryParam,
    customerQueryParam,
} from 'app/shared/models/customer';
import { PercentFullReportArgs } from 'app/shared/models/percentage-full-report';
import { WidgetFilterData } from 'app/shared/models/widget-filter-data';
import { PercentFull } from 'app/shared/models/percent-full';

@Component({
    selector: 'app-percent-full-details',
    templateUrl: './percent-full-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})

/**
 * Percent full details report
 */
export class PercentFullDetailsComponent implements OnInit, OnDestroy {
    public locationName: any;
    public customerID: number;
    public locationGroupID: number;
    public percentFullLoading: boolean;
    public percentFullDetailsData: PercentFull;
    public startDate: Date;
    public endDate: Date;
    public displayFilters: boolean;

    private subscriptions = new Array<Subscription>();
    private locationID: number;

    /**
     * date format on x axis label
     */
    public dateFormat: string;

    /**
     * Represents the state of active and inactive locations
     * True will represent the both (active and inactive) locations
     * False will represent only active locations
     */
    public includeInactiveLocations: boolean;

    constructor(
        private route: ActivatedRoute,
        private changeDetector: ChangeDetectorRef,
        private percentFullReportService: PercentFullReportService,
        private router: Router,
        private dateUtilService: DateutilService,
        private uiUtilsService: UiUtilsService,
    ) {}

    public ngOnInit() {
        this.route.params.subscribe((params) => (this.locationID = +params['id']));

        this.route.queryParamMap.subscribe((params: ParamMap) => {
            // get location group
            this.locationGroupID = Number(params.get(customerLocationGroupQueryParam));

            // get location name
            this.locationName = params.get('ln');

            // get updated locations
            this.includeInactiveLocations = Boolean(Number(params.get(activeInactiveLocationQueryParam)));

            if (!this.customerID) {
                this.customerID = Number(params.get(customerQueryParam));
                this.generateReportWithDefaultParams();

                // subscribe to changes in dateformat
                const subscription = this.dateUtilService.dateFormat.subscribe(() => {
                    // Getting customer dateformat from dateUtil Service
                    this.dateFormat = this.dateUtilService.getFormat();
                });
                this.subscriptions.push(subscription);
            } else {
                this.router.navigate(['../../'], {
                    queryParamsHandling: 'preserve',
                    relativeTo: this.route,
                });
            }
        });
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
    }

    public showReportFilter() {
        this.displayFilters = !this.displayFilters;
    }

    public notifyReport(filtersData: WidgetFilterData) {
        let startDate = null;
        let endDate = null;
        if (filtersData.startDate) {
            startDate = [
                filtersData.startDate.getMonth() + 1,
                filtersData.startDate.getDate(),
                filtersData.startDate.getFullYear(),
            ].join('/');
        }
        if (filtersData.endDate) {
            endDate = [
                filtersData.endDate.getMonth() + 1,
                filtersData.endDate.getDate(),
                filtersData.endDate.getFullYear(),
            ].join('/');
        }

        const args = <PercentFullReportArgs>{
            CustomerId: filtersData.customerID,
            LocationIds: [this.locationID],
            EntityIds: filtersData.entityIDs,
            Start: startDate,
            End: endDate,
            IncludeInactiveLocations: this.includeInactiveLocations || undefined,
        };
        this.generatePercentFullDetailsReport(args);
    }

    private generateReportWithDefaultParams() {
        this.endDate = new Date();
        this.endDate.setDate(this.endDate.getDate() - 1);
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 7);

        const startDay = this.startDate.getDate();
        const startMonth = this.startDate.getMonth();
        const startYear = this.startDate.getFullYear();

        const endDay = this.endDate.getDate();
        const endMonth = this.endDate.getMonth();
        const endYear = this.endDate.getFullYear();

        const args = <PercentFullReportArgs>{
            CustomerId: this.customerID,
            LocationGroupId: this.locationGroupID,
            LocationIds: [this.locationID],
            Start: [startMonth + 1, startDay, startYear].join('-'),
            End: [endMonth + 1, endDay, endYear].join('-'),
            IncludeInactiveLocations: this.includeInactiveLocations || undefined,
        };
        this.generatePercentFullDetailsReport(args);
    }

    /**
     * Generate percent full details data for current customer and location
     */

    private generatePercentFullDetailsReport(args: PercentFullReportArgs) {
        this.percentFullLoading = true;

        this.percentFullReportService.getPercentFullReport(args).subscribe(
            (response) => {
                if (response && response['percentFullValues'] && response['percentFullValues'].length > 0) {
                    this.percentFullDetailsData = response['percentFullValues'][0];
                }

                this.percentFullLoading = false;

                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            (error) => {
                this.percentFullLoading = false;
            },
        );
    }
}
