export const OTHER_DISPLAY_GROUP = -100000000;
export const SCALAR_OTHER_DISPLAY_GROUP = -1000;
export const TEMP_VOLTAGE_DISPLAY_GROUP = -8;
export const DEPTH_VELOCITY_DISPLAY_GROUP = -5;
export const FLOW_RAIN_DISPLAY_GROUP = -3;
export const INVALID_DISPLAY_GROUP = 0;
export const SCALAR_DISPLAY_GROUP = 1;
export const ANGLE_DISPLAY_GROUP = 23;
export const DEPTH_DISPLAY_GROUP = 2;
export const RAIN_DISPLAY_GROUP = 3;
export const RAIN_INTENSITY_GROUP = 4;
export const VELOCITY_DISPLAY_GROUP = 5;
export const FLOW_DISPLAY_GROUP = 6;
export const TEMPERATURE_DISPLAY_GROUP = 7;
export const VOLTAGE_DISPLAY_GROUP = 8;
export const PRESSK_DISPLAY_GROUP = 11;
export const FEET_DISPLAY_GROUP = 19;
export const ELEVATION_DISPLAY_GROUP = 24;
export const OTHER_DISPLAY_GROUP_CONST = 1000;

export const PIPE_HEIGHT_ANNOTATION = 100;
export const MANHOLE_DEPTH_ANNOTATION = 104;

export const DEPTH_DISPLAY_GROUP_NAME = 'Depth Entities';
export const VELOCITY_DISPLAY_GROUP_NAME = 'Velocity Entities';
export const FLOW_DISPLAY_GROUP_NAME = 'Flow Entities';
export const RAIN_DISPLAY_GROUP_NAME = 'Rain Entities';
export const ELEVATION_DISPLAY_GROUP_NAME = 'Elevation Entities';

export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_START_PAGE = 1;
export const DEFAULT_CSV_PAGE_SIZE = -1;
export const DEFAULT_CSV_START_PAGE = -1;

export const DEPTH_VELOCITY = 'DepthVelocity';
export const FLOW_RAIN = 'FlowRain';
export const TEMP_VOLTAGE = 'TemperatureVoltage';
export const SCALAR_OTHER = 'ScalarOther';
export const DEPTH = 'Depth';
export const VELOCITY = 'Velocity';
export const RAW_VELOCITY = 'Raw Velocity';
export const FLOW = 'Flow';
export const RAIN = 'Rain';
export const QUANTITY = 'Quantity';
export const Q_A1 = 'Q_A1';
export const Q_A2 = 'Q_A2';
export const ELEVATION = 'Elevation';
export const RAINFINAL = 'Rainfinal';
export const TEMPERATURE = 'Temperature';
export const QFINAL = 'QFinal';
export const QCONTINUITY = 'QContinuity';
export const QFLUME = 'QFlume';
export const QLOOKUP = 'QLookup';
export const QWEIR = 'QWeir';
export const VOLTS = 'Volts';
export const SCALAR = 'Scalar';
export const OTHER = 'Other';
export const VOLTAGE = 'Voltage';
export const UNIDEPTH = 'Depth';
export const C_DEPTH = 'CONFIRMATION DEPTH';
export const C_VELOCITY = 'CONFIRMATION VELOCITY';
export const C_FLOW = 'CONFIRMATION FLOW';
export const TEMP = 'Temp';
export const STANDARD_FLOW_ENTITIES = 'Standard Flow Entities';

export const IDLE_TIMEOUT_SECONDS = 60;
export const IDLE_MESSAGE_MINUTES = 15;

export const LOW = 'Low';
export const MEDIUM = 'Medium';
export const HIGH = 'High';

export const VELOCITY_ENTITY = 4202;
export const RAW_VELOCITY_ENTITY = 5201;
export const RAIN_ENTITY = 2123;
export const RAIN_FINAL_ENTITY = 3123;
export const DEPTH_ENTITY = 4122;
export const QCONTINUITY_ENTITY = 3302;
export const QFLUME_ENTITY = 3304;
export const QLOOKUP_ENTITY = 3306;
export const QWEIR_ENTITY = 3320;
export const QUANTITY_ENTITY = 3303;
export const Q_A1_ENTITY = 5301;
export const Q_A2_ENTITY = 5302;
export const FLOW_ENTITY = 2303;
export const TEMPERATURE_ENTITY = 4404;
export const SILT_ENTITY = 1707;
export const R_FINAL_ENTITY = 3123;
export const V_FINAL_ENTITY = 2203;
export const Q_FINAL_ENTITY = 2303;
export const BTY_LOW_ENTITY = 6602;
export const PRESSK_ENTITY = 1706;
export const CROSS_DEPTH_ENTITY = 6615;
export const CROSS_VELOCITY_ENTITY = 6616;
export const HIGH_HIGH_ENTITY = 6609;
export const HIGH_LEVEL_ENTITY = 6610;
export const LOW_FLOW_ENTITY = 6620;
export const LOW_LEVEL_ENTITY = 6611;
export const OVERFLOW_ENTITY = 6631;
export const RAIN_ALERT_ENTITY = 6613;
export const RAIN_TIPS_ENTITY = 6622;
export const SUBMERGED_ENTITY = 6621;
export const TILT_ENTITY = 6623;
export const LEVEL_ENTITY = 2143;
export const AQUIFER_LEVEL_ENTITY = 2144;
export const ELEVATION_ENTITY = 2145;
export const SERIES_ONE = 'Series 1';
export const NOTIFICATION_TIMEOUT = 10000;
export const DFINAL_ENTITY = 2102;
export const FLOW_METRIC_UNIT = 23;
export const FLOW_NON_METRIC_UNIT = 20;
export const LOCATION_NAME = 'locationName';
export const MONITOR_SERIES = 'monitorSeries';
export const FAILURE_ATTEMPTS = 'failureAttempts';
export const TOTAL_ATTEMPTS = 'totalAttempts';
export const SUCCESS_ATTEMPTS = 'successPercent';
export const LAST_COLLECTED_DATE = 'lastCollectedDate';
export const LAST_SCHEDULED_COLLECTION = 'lastScheduledCollection';
export const LAST_MANUAL_COLLECTION = 'lastManualCollection';
export const LAST_ATTEMPTED_DATE = 'lastAttemptedDate';
export const LAST_FAILED_COLLECTION = 'lastFailedCollection';
export const NEXT_SCHEDULED_COLLECTION = 'nextScheduledCollection';
export const ACTION = 'action';
export const CUSTOM_SNACKBAR_ERROR = 'custom-error-snack-bar';
export const RAIN_DEFAULT_INSTALLATION_TYPE = 'Rain';

export enum UnitOfMeasureType {
    CFS = 1,
    METRIC = 2,
    MGD = 3,
}

export enum EntityType {
    PEAKVEL_1 = 4203,
    PEAKVEL_3 = 4204,
    GATEDVEL_2 = 4205,
    SURFACEVEL_3 = 4206
}

export enum Series {
    ChannelStandard = 500,
    Elevation = 1000,
}

export enum EntityGroupId {
    Depth = 2,
    Velocity = 5,
}
// Percentfull report installation types
export const COMPOSITE = 'Composite';
export const PIPE = 'Pipe';
export const ROUND = 'Round';

export const GATEDVEL =  "GATEDVEL_2";

// SLiiCER constants

export const SLI_WEEKDAY_GROUP_NAME = 'Weekdays';
export const SLI_WEEKEND_GROUP_NAME = 'Weekends';

export enum DataEditType {
    EntityUpdate = 0,
    ScattergraphFlag,
    ScattergraphSnap,
    ContinuousUpdate,
    DataInterpolate = 4,
    BlockEditor = 5,
    RollbackEditor = 6,
    GainTableUpdate = 7,
}

// SLiiCER Graph colors

export const SLI_COLOR_RAINFALL = '#1F1F9C';
export const SLI_COLOR_RAINFALL_MONITORS = [
    SLI_COLOR_RAINFALL,
    '#87008D',
    '#DC1E5A',
    '#F17925',
    '#CCAA14',
    '#A57A55',
    '#78A64A',
    '#37A996',
];

export const SLI_COLOR_FLOW = '#268030';
export const SLI_COLOR_NET_FLOW = '#CCAA14';
export const SLI_COLOR_UPSTREAMS = ['#F17925', '#004753', '#763A81', '#3A9DA0', '#4B4C4E'];

export const SLI_COLOR_DECOMP_GROSS_Q = SLI_COLOR_FLOW;
export const SLI_COLOR_DECOMP_GROSS_II = '#F17925';
export const SLI_COLOR_DECOMP_NET_II = '#CCAA14';
export const SLI_COLOR_MODELEDPRECOMP = '#79150D';

export const SLI_COLOR_WEEKGROUP_WEEKDAY = '#00FF00';
export const SLI_COLOR_WEEKGROUP_WEEKEND = '#0000FF';
export const SLI_COLOR_WEEKGROUP_WEEKDAY_TRACES = '#B6D77D';
export const SLI_COLOR_WEEKGROUP_WEEKEND_TRACES = '#94BAF9';
export const SLI_COLOR_WEEKGROUP_HIGHLIGHT = '#469cdbc0';

export const SLI_OCOLOR_WEEKGROUP_WEEKDAY = '#D6F79D60';
export const SLI_OCOLOR_WEEKGROUP_WEEKEND = '#B4DCF960';
export const SLI_OCOLOR_PRECOMP = '#80808040';
export const SLI_OCOLOR_STORM_PERIOD = '#C77BBA5A';
export const SLI_OLCOLOR_STORM_PERIOD = '#F7C75E';
export const SLI_OCOLOR_STORM = '#C77BBA40';
export const SLI_OCOLOR_RECOVERY1 = '#A84E9540';
export const SLI_OCOLOR_RECOVERY2 = '#873C7740';
export const SLI_WEEKGROUP_COLORS = [
    {
        lineColor: '#D6F79D',
        overlayColor: '#D6F79DC0',
    },
    {
        lineColor: '#B4DCF9',
        overlayColor: '#B4DCF9C0',
    },
    {
        lineColor: '#8A58A1',
        overlayColor: '#8A58A160',
    },
    {
        lineColor: '#C73611',
        overlayColor: '#C7361160',
    },
    {
        lineColor: '#F2552C',
        overlayColor: '#F2552C60',
    },
    {
        lineColor: '#F6D155',
        overlayColor: '#F6D15560',
    },
    {
        lineColor: '#660000',
        overlayColor: '#66000060',
    },
];

export const RAIN_HEXCODE_FLAGGED = '#FF0000';
export const RAIN_HEXCODE_UNFLAGGED = '#1F1F9C';
export const ORANGE_COLOR_HEX = '#FFAD26';
export const RED_COLOR_HEX = '#FF0000';
export const BROWN_COLOR = '#A52A2A';
export const DEFAULT_MARKER_COLOR = '#7798BF';
export const ANNOTATION_MARKER_COLOR = '#ffb264';
export const ANNOTATION_IGNORED_MARKER_COLOR = '#FF0000';
export const EDIT_COLOR = '#FE00CC';


export const HG_COLOR_PIPE_HEIGHT = '#FF3D00';
export const HG_COLOR_MANHOLE_DEPTH = 'blue';
export const HG_COLOR_SILT = '#471100';


// PDF URLs for ANSR ENGINE
export const ANSR_FORMULA_CALC = '/assets/files/Formula%20Calculation.pdf';
export const ANSR_CUMULATIVE_CALC = '/assets/files/Cumulative%20Calculation.pdf';
export const ANSR_MANNING_CALC = '/assets/files/Manning%20Calculation.pdf';
export const ANSR_DELTA_CALC = '/assets/files/Delta%20Calculation.pdf';
export const ANSR_REPEAT_CALC = '/assets/files/Repeat%20Calculation.pdf';
export const ANSR_INTERPOLATE_CALC = '/assets/files/Interpolate%20Calculation.pdf';
export const ANSR_ROLLING_AVG_CALC = '/assets/files/Rolling%20Average%20Calculation.pdf';
export const ANSR_STEP_CALC = '/assets/files/Step%20Calculation.pdf';

// FLOW LOSS
export const DEFAULT_FLOW_LOSS_PERCENTAGE = 25;
export const FLOW_LOSS_TYPE_FLOW_DEVICE_ID = 258;
export const FLOW_LOSS_TYPE_DEPTH_DEVICE_ID = 259;

export const DEFAULT_RAIN_III_UK_SAMPLE_RATE = 120; // 2 'minutes'

export const DEFAULT_APN_PROVIDER = 'ADSENV.COM';

// TRITON SAMPLER CONFIG
export const TRITON_SAMPLER = 'Sampler';
export const TRITON_SAMPLER_DEVICE_ID = 4;

export const DATA_QUALITY_COLORS = [
    '#FF0000',
    '#FFFF00',
    '#FF00FF',
    '#0000ff',
    '#808000',
    '#008080',
    '#000080',
    '#800000',
    '#008000',
    '#00ff00',
];

export const OLMAPPROJECTION = 'EPSG:3857';
export const ADS_MONITOR_LAYER = 'geonode:Locations_CORE';
export const ADS_MONITOR_LAYER_DETAIL = 'geonode:Locations_CORE_details';
export const ADS_MONITOR_LAYER_BOUND = 'geonode:Locations_CORE_bndry';
export const ADS_LAYER_BBOX = '-20037508.34, -20037508.34, 20037508.34, 20037508.34';

export const GPD_TO_MPD_DIVIDER = 1000000;
export const LPD_TO_LPS_DIVIDER = 86400;
export const MM_TO_INCHES_CONVERSION = 0.0393701;

export const RAIN_ENTITY_FULL = {
    color: "#000000",
    displayGroup: 3,
    displayGroupName: "Rain Entities",
    displayGroupPrecendece: 5,
    entityDisplayName: "Rain",
    entityId: 2123,
    entityName: "RAIN",
    entityShortDisplayName: "Rain",
    flowDataType: 3,
    isApprovedData: false,
    isPreDefined: true,
    isRain: false,
    rate: 1,
    readings: [],
    storageUnitCategory: 2,
    summaryMethod: 3,
    totallingMethod: 2
}

// TODO: Replace it with enum, possibly HttpStatusCode.Conflict when we will update Angular https://angular.io/api/common/http/HttpErrorResponse
export const HTTP_STATUS_CODE_CONFLICT = 409;
export const HTTP_STATUS_CODE_CONCURRENCY = 412;
