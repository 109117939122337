import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[adsPopupWindow]',
})
export class AdsPopupWindowDirective {
    @Input() public popupElement: HTMLElement;
    @Input() public popupTitle = 'Untitled';
    @Input() public showMenuBar = false;
    @Input() public showToolBar = false;
    @Input() public showStatusBar = false;
    @Input() public showLocationBar = false;
    @Input() public showScrollBars = true;
    @Input() public popupHeight = 400;
    @Input() public popupWidth = 800;
    @Input() public isComponentElement = false;
    constructor() {}

    @HostListener('click')
    public onClick() {
        const win = window.open(
            '',
            this.popupTitle,
            `toolbar=${this.convert(this.showToolBar)},
      location=${this.convert(this.showLocationBar)},
      status=${this.convert(this.showStatusBar)},
      menubar=${this.convert(this.showMenuBar)},
      scrollbars=${this.convert(this.showScrollBars)},
      width=${this.popupWidth},
      height=${this.popupHeight}`,
        );
        let innerHtml: string;
        if (!this.isComponentElement) {
            innerHtml = this.popupElement.innerHTML;
        } else if (this.isComponentElement && this.popupElement['element']['nativeElement']) {
            innerHtml = this.popupElement['element']['nativeElement'].innerHTML;
        } else {
            innerHtml = `<div>No content</div>`;
        }

        if (innerHtml) {
            win.document.head.title = this.popupTitle;
            win.document.body.innerHTML = innerHtml;
        } else {
            setTimeout(() => {
                win.close();
            }, 2000);
        }
    }

    private convert(flag: boolean) {
        return flag ? 'yes' : 'no';
    }
}
