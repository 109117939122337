import { Injectable, EventEmitter } from '@angular/core';
import { Config } from 'app/shared/services/config';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { LocationDetails, MonitorSeriesUI } from '../models/location-details';
import { HttpClient } from '@angular/common/http';
import { TritonLocation } from '../models/TritonLocation';
import { TritonConfiguration } from '../models/monitorsetting';
import { CacheService } from './cache.service';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class TritonLocationService {
    public locationsChange: EventEmitter<number[]> = new EventEmitter<number[]>();
    public locationChange: EventEmitter<number> = new EventEmitter<number>();
    private _locationId: number;
    private _locationIDs: number[];

    public activationComplete$ = new Subject();
    constructor(public http: HttpClient, public cacheService: CacheService) {}

    // getter and setter for location Id
    public set locationId(locationId: number) {
        this._locationId = locationId;
        this.locationChange.emit(locationId);
    }

    public get locationId(): number {
        return this._locationId;
    }

    public set locationIDs(locationIDs: number[]) {
        this._locationIDs = locationIDs;
        this.locationsChange.emit(locationIDs);
    }

    public get locationIDs(): number[] {
        return this._locationIDs;
    }

    public addLocation(customerId: number, addLocation: any, type?: string) {
        const url = Config.urls.locationsCreate + '?customerId=' + customerId;

        const locations = Array.isArray(addLocation) ? addLocation : [addLocation];

        const res = this.http.post<number>(url, locations);
        res.pipe(
            catchError((error) => {
                console.dir(error.json());
                return res;
            }),
        );
        this.cacheService.removeFromCache(Config.urls.locationsList);
        return res;
    }

    public updateLocation(customerId: number, addLocation: TritonLocation[]) {
        const res = this.http.put<number>(Config.urls.locationsUpdate + '?customerId=' + customerId, addLocation);
        res.pipe(
            catchError((error) => {
                console.dir(error.json());
                return res;
            }),
        );
        this.cacheService.removeFromCache(Config.urls.locationsList);
        return res;
    }

    /**
     * get monitor-series api.
     */
    public getMonitorSeries(): Observable<Array<MonitorSeriesUI>> {
        return this.http.get<Array<MonitorSeriesUI>>(Config.urls.monitorSeries);
    }

    /**
     * Method returns location details for a particular location for a customer
     * @param customerID - customer ID
     * @param locationID - locationg ID
     */
    public getLocationDetails(customerID: number, locationID: number) {
        return this.http.get<LocationDetails>(`${Config.urls.locationDetails}/${customerID}/${locationID}`);
    }

    public tritonMonitorConfiguration(tritonConfiguration: TritonConfiguration, customerID: number) {
        return this.http.post<any>(Config.urls.configureTritonAlert + `${customerID}`, tritonConfiguration).pipe(
            map(
                (result) => {
                    return result;
                },
                (error) => {
                    return EMPTY;
                },
            ),
        );
    }

    public activateLocation(customerId: number, locationid: number) {
        return this.http
            .post<Object>(
                Config.serviceUrl +
                    Config.urls.activatedTritonMonitor +
                    `${customerId}&lid=${locationid}
`,
                {},
            )
            .pipe(
                tap(() => this.activationComplete$.next(null)),
                catchError((error) => {
                    // console.dir(error.json());
                    return throwError(error);
                }),
            );
    }

    public getTritonMonitorConfiguration(locationId: number, customerID: number) {
        return this.http.get<TritonConfiguration>(Config.urls.configureTritonAlert + `${customerID}` + `&lid=${locationId}`).pipe(
            map(
                (result) => {
                    return result;
                },
                (error) => {
                    return EMPTY;
                },
            ),
        );
    }
}
