import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { NgForm, FormControl } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'app/pages/admin/users.service';
import { AnomalyReason } from 'app/shared/models/auto-scrub-summary';
import { customerQueryParam, activeInactiveLocationQueryParam } from 'app/shared/models/customer';
import { Entities, ReplaceEntity } from 'app/shared/models/entities';
import { LocationGroup } from 'app/shared/models/location-group';
import { LocationArgs, LocationStatus } from 'app/shared/models/locations';
import { Selectable, SelectableBlockagePrediction, SelectableGroup } from 'app/shared/models/selectable';
import { HydrographTickInterval } from 'app/shared/models/view-data';
import { FilterSettings, WidgetFilterData } from 'app/shared/models/widget-filter-data';
import { OrderByPipe } from 'app/shared/pipes/order-by-pipe';
import { AlarmService } from 'app/shared/services/alarm.service';
import { AutoScrubSummaryService } from 'app/shared/services/auto-scrub-summary.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { EntityService } from 'app/shared/services/entity.service';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { LocationService } from 'app/shared/services/location.service';
import { SharedService } from 'app/shared/services/shared.service';
import { StringUtils } from 'app/shared/utils/string-utils';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';

import { MultiSelectGroupComponent } from '../multi-select/multi-select-group/multi-select-group.component';

const ROUTEPATH = ':id/details';
const UNKNOWN = 'Unknown';
const NOBLOCKAGE = 'NoBlockage';
const DEVELOPING = 'DevelopingBlockage';
const ADVANCED = 'AdvancedBlockage';

@Component({
    selector: 'app-widget-filters',
    templateUrl: './widget-filters.component.html',
    styleUrls: ['./widget-filters.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetFiltersComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild(MatDatepicker) public datepicker: MatDatepicker<Date>;
    public locations: Array<Selectable>;
    public locationsGroup: Array<SelectableGroup>;
    public daySpanErrorMessage: string;
    public isDaySpanValid: boolean;
    public widgetFilterData = new WidgetFilterData();
    public entities: Array<SelectableGroup>;
    public maxDate = new Date();
    public batteryStatuses = new Array<Selectable>();
    public alarmStatuses: Array<Selectable>;
    public alarmTypes = new Array<Selectable>();
    public monitorSeries = new Array<Selectable>();
    public users = new Array<Object>();
    public errThresholds: Array<Object>;
    public anomaliesReasons = new Array<Selectable>();
    private isInitialCall = true;
    public preselectedBatteryStatus: Selectable;
    public customerId: number;
    public locationId: number;
    public routePath: string;
    public blockStatus: Array<SelectableBlockagePrediction> = [];
    public unknown: string;
    public noBlockage: string;
    public developing: string;
    public advanced: string;
    public customerDateFormat: string;
    public dashText: string;
    public startDateData = new FormControl(
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7, 0, 0, 0),
    );

    @Input() public customerID: number;
    @Input() public locationGroupID: number;
    @Input() public filterSettings: FilterSettings;
    @Input() public toggleState: boolean;
    @Input() public replaceEntities: Array<ReplaceEntity>;
    @Input() public datePickerType = 'date'; // date picker type { date | datetime } default set as date only.
    @Input() public enableDateRangeLimit = true;
    @Input() public filterByMonitor = false;
    @Input() public limitStartDate = true;
    /**
     * Represents the state of active and inactive locations
     * True will represent the both (active and inactive) locations
     * False will represent only active locations
     */
    @Input() public includeInactiveLocations: boolean;

    @Output() public sendDataToWidget = new EventEmitter<WidgetFilterData>();

    private subscriptions = new Array<Subscription>();
    public isStartDateInvalid = false;
    public isEndDateInvalid = false;
    public startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7, 0, 0, 0);
    public endDate = new Date();

    // Below variable will be used to access the form state
    @ViewChild('widgetFiltersForm') public widgetFiltersForm: NgForm;
    // Represents low level (which has only 0 value) precendence entities
    private lowPrecendenceEntities: Array<Entities> = new Array<Entities>();
    // Variable which represents the filtered entity collection
    public filteredEntities: Array<SelectableGroup> = new Array<SelectableGroup>();

    public isLoading = false;
    private stringUtils: StringUtils = new StringUtils();
    public startEndDateError: string;
    public startDateError: string;
    public endDateError: string;
    public dateError: string;
    public startEndDiffError: string;
    public unacknowledgedText: string;
    public activeText: string;
    public acknowledgedText: string;
    public clearedText: string;

    public lowBatteryAlarm: string;
    public highHighAlarm: string;
    public highLevelAlarm: string;
    public lowLevelAlarm: string;
    public rainAlarm: string;
    public lowFlowAlarm: string;
    public submergedAlarm: string;
    public tiltAlarm: string;
    public fullPipeAlarm: string;
    public overflowAlarm: string;
    public criticalBatteryStatus: string;
    public lowBatteryStatus: string;
    public goodBatteryStatus: string;
    public unknownBatteryStatus: string;
    public alarmText: string;
    public locationGroupList: Array<Selectable> = [];
    public locationGroups: Array<LocationGroup> = [];
    public locationArgs: LocationArgs;
    public displayStartDateErrMsg: boolean;
    public displayEndDateErrMsg: boolean;
    public invalidEndDate: boolean;
    public invalidStartDate: boolean;
    public tickInterval: HydrographTickInterval;
    /**
     * Variable which represents the selected entity collection
     */
    public selectedEntities = new Array<SelectableGroup>();
    @ViewChild('entitiesGroupMultiselect') public entitiesGroupMultiselect: MultiSelectGroupComponent;
    preselectedStatus: { name: string; id: number }[];
    constructor(
        private usersService: UsersService,
        private autoScrubSummaryService: AutoScrubSummaryService,
        private dateutilService: DateutilService,
        private activatedRoute: ActivatedRoute,
        private alarmService: AlarmService,
        private entityService: EntityService,
        private sharedService: SharedService,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private locationService: LocationService,
        private locationGroupService: LocationGroupService,
        private dateUtilService: DateutilService,
        private translate: TranslateService,
    ) {}

    // Lifecycle hook that is called after data-bound properties of a directive are initialized
    public ngOnInit() {
        this.resetDateFilters();
        this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            this.customerId = Number(params.get(customerQueryParam));
            this.locationId = this.activatedRoute.snapshot.params['id'];
            this.routePath = this.activatedRoute.snapshot.routeConfig.path;
            this.includeInactiveLocations = Boolean(Number(params.get(activeInactiveLocationQueryParam)));
        });
        const translateKeys: Array<string> = [
            'HOME.WIDGET_FILTER.START_END_DATE_ERROR',
            'HOME.WIDGET_FILTER.START_DATE_ERROR',
            'HOME.WIDGET_FILTER.END_DATE_ERROR',
            'COMMON.DATE_ERROR',
            'HOME.WIDGET_FILTER.START_END_DIFF_ERROR',
            'COMMON.ACTIVE_CHECKBOX',
            'COMMON.UNACKNOWLEDGED_TEXT',
            'COMMON.ACKNOWLEDGED_TEXT',
            'COMMON.CLEARED_TEXT',
            'COMMON.LOW_BATTERY_ALARM',
            'COMMON.HIGH_HIGH_ALARAM',
            'COMMON.HIGH_LEVEL_ALARM',
            'COMMON.LOW_LEVEL_ALARM',
            'COMMON.RAIN_ALARM',
            'COMMON.LOW_FLOW_ALARM',
            'COMMON.SUBMERGED_ALARM',
            'COMMON.TILT_ALARM',
            'COMMON.FULL_PIPE_ALARM',
            'COMMON.ALARAM_TEXT',
            'COMMON.OVERFLOW_ALARM',
            'COMMON.CRITICAL_BATTERY_STATUS',
            'COMMON.GOOD_BATTERY_STATUS',
            'COMMON.LOW_BATTERY_STATUS',
            'COMMON.UNKNOWN_BATTERY_STATUS',
            'HOME.BLOCKAGE_PREDICTION_TILE.BLOCK_STATUS_UNKNOWN',
            'HOME.BLOCKAGE_PREDICTION_TILE.BLOCK_STATUS_NO_BLOCKAGE',
            'HOME.BLOCKAGE_PREDICTION_TILE.BLOCK_STATUS_DEVELOPING',
            'HOME.BLOCKAGE_PREDICTION_TILE.BLOCK_STATUS_ADVANCED',
        ];
        this.translate.get(translateKeys).subscribe((translateValues) => {
            if (!translateValues) {
                return;
            }
            this.startEndDateError = translateValues['HOME.WIDGET_FILTER.START_END_DATE_ERROR'];
            this.startDateError = translateValues['HOME.WIDGET_FILTER.START_DATE_ERROR'];
            this.endDateError = translateValues['HOME.WIDGET_FILTER.END_DATE_ERROR'];
            this.dateError = translateValues['COMMON.DATE_ERROR'];
            this.startEndDiffError = translateValues['HOME.WIDGET_FILTER.START_END_DIFF_ERROR'];
            this.activeText = translateValues['COMMON.ACTIVE_CHECKBOX'];
            this.unacknowledgedText = translateValues['COMMON.UNACKNOWLEDGED_TEXT'];
            this.acknowledgedText = translateValues['COMMON.ACKNOWLEDGED_TEXT'];
            this.clearedText = translateValues['COMMON.CLEARED_TEXT'];
            this.lowBatteryAlarm = translateValues['COMMON.LOW_BATTERY_ALARM'];
            this.highHighAlarm = translateValues['COMMON.HIGH_HIGH_ALARAM'];
            this.highLevelAlarm = translateValues['COMMON.HIGH_LEVEL_ALARM'];
            this.lowLevelAlarm = translateValues['COMMON.LOW_LEVEL_ALARM'];
            this.rainAlarm = translateValues['COMMON.RAIN_ALARM'];
            this.lowFlowAlarm = translateValues['COMMON.LOW_FLOW_ALARM'];
            this.submergedAlarm = translateValues['COMMON.SUBMERGED_ALARM'];
            this.tiltAlarm = translateValues['COMMON.TILT_ALARM'];
            this.fullPipeAlarm = translateValues['COMMON.FULL_PIPE_ALARM'];
            this.alarmText = translateValues['COMMON.ALARAM_TEXT'];
            this.overflowAlarm = translateValues['COMMON.OVERFLOW_ALARM'];
            this.criticalBatteryStatus = translateValues['COMMON.CRITICAL_BATTERY_STATUS'];
            this.goodBatteryStatus = translateValues['COMMON.GOOD_BATTERY_STATUS'];
            this.lowBatteryStatus = translateValues['COMMON.LOW_BATTERY_STATUS'];
            this.unknownBatteryStatus = translateValues['COMMON.UNKNOWN_BATTERY_STATUS'];
            this.blockStatus = [
                {
                    id: 1,
                    backendName: NOBLOCKAGE,
                    name: translateValues['HOME.BLOCKAGE_PREDICTION_TILE.BLOCK_STATUS_NO_BLOCKAGE'],
                },
                {
                    id: 2,
                    backendName: DEVELOPING,
                    name: translateValues['HOME.BLOCKAGE_PREDICTION_TILE.BLOCK_STATUS_DEVELOPING'],
                },
                {
                    id: 3,
                    backendName: ADVANCED,
                    name: translateValues['HOME.BLOCKAGE_PREDICTION_TILE.BLOCK_STATUS_ADVANCED'],
                },
                {
                    id: 0,
                    backendName: UNKNOWN,
                    name: translateValues['HOME.BLOCKAGE_PREDICTION_TILE.BLOCK_STATUS_UNKNOWN'],
                },
            ];
        });

        this.preselectedStatus = [{ name: this.activeText, id: 0 }];

        this.alarmStatuses = [
            { name: this.activeText, id: 0 },
            { name: this.unacknowledgedText, id: 1 },
            { name: this.acknowledgedText, id: 2 },
            { name: this.clearedText, id: 3 },
        ];

        const monitorSeriesSubscription = this.locationService.getMonitorSeries().subscribe((result) => {
            if (result) {
                this.monitorSeries.push(
                    ...result.map((monitor) => {
                        return { id: monitor.monitorSeriesID, name: monitor.modelName };
                    }),
                );
            }
        });
        this.subscriptions.push(monitorSeriesSubscription);

        const alarmTypeSubscription = this.alarmService.getAlarmsTypes().subscribe((result) => {
            if (result) {
                result.forEach((alarm) => {
                    this.alarmTypes.push({ id: alarm.id, name: alarm.description });
                });
            }
        });

        this.subscriptions.push(alarmTypeSubscription);

        this.subscriptions.push(
            this.sharedService.isEntityRequired.subscribe((response: boolean) => {
                if (response) {
                    this.loadEntities();
                }
            }),
        );
        this.errThresholds = [{ value: '10', text: '>5%' }];
        this.batteryStatuses = [
            { name: this.criticalBatteryStatus, id: 0 },
            { name: this.goodBatteryStatus, id: 1 },
            { name: this.lowBatteryStatus, id: 2 },
            { name: this.unknownBatteryStatus, id: 3 },
        ];

        //  subscribe to customer date format change
        const dateFormatSubscription = this.dateutilService.dateFormat.subscribe((newDateFormat) => {
            this.resetFilters();
        });
        this.subscriptions.push(dateFormatSubscription);

        //  subscribe to customer time format change
        const timeFormatSubscription = this.dateutilService.timeFormat.subscribe((newTimeFormat) => {
            this.resetFilters();
        });
        this.subscriptions.push(timeFormatSubscription);

        this.dateUtilService.dateFormat.subscribe((newDateFormat) => {
            this.customerDateFormat =
                this.datePickerType === 'datetime'
                    ? `${String(this.dateUtilService.getFormat())} ${this.dateUtilService.getTimeFormat()}`
                    : this.dateUtilService.getFormat();
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.filterSettings && this.filterSettings.displayBatteryStatusItems) {
            this.preselectedBatteryStatus = this.batteryStatuses.find(
                (item) => item.name === this.filterSettings.preselectedBatteryStatus,
            );
            if (this.preselectedBatteryStatus) {
                this.widgetFilterData.batteryStatus = this.preselectedBatteryStatus.name;
            }
        }

        if (changes.filterSettings && changes.filterSettings.currentValue && changes.filterSettings.previousValue &&
            changes.filterSettings.currentValue.displayInactiveLocations !== changes.filterSettings.previousValue.displayInactiveLocations) {
            this.loadLocations();
        }

        if (
            (changes.customerID && changes.customerID.currentValue && changes.customerID.currentValue > 0) ||
            changes.locationGroupID
        ) {
            this.resetFilters();

            if (this.filterSettings.displayLocations) {
                this.loadLocations();
            }

            if (this.filterSettings.displayLocationGroup) {
                this.loadLocationGroup();
            }


            if (this.filterSettings.displayAnomaliesReasons) {
                this.autoScrubSummaryService.getAnomaliesReasons().subscribe((response: Array<AnomalyReason>) => {
                    this.anomaliesReasons = new Array<Selectable>();
                    response.forEach((item) => {
                        this.anomaliesReasons.push({ id: item.bitFlag, name: item.reason });
                    });
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                });
            }

            if (this.filterSettings.skipInitialCall) {
                return;
            }

            this.notifyWidget();
        }
        if (changes.toggleState) {
            this.toggleState = changes.toggleState.currentValue;
        }

        // below validation to filter location based on global selection change.
        if (changes.includeInactiveLocations) {
            this.loadLocations();
            this.loadLocationGroup();
        }
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    // Loading the entities from API
    private loadEntities(): void {
        this.filteredEntities = new Array<SelectableGroup>();
        let entityObservable: Observable<Entities[]>;
        if (
            this.customerID &&
            this.locationId &&
            (this.routePath.toLowerCase() === ROUTEPATH.toLowerCase() || this.filterByMonitor)
        ) {
            entityObservable = this.entityService.getEntitiesByCustomer(this.customerID, this.locationId);
        } else {
            entityObservable = this.entityService.getEntities();
        }
        const entitySubscription = entityObservable.subscribe(
            (entities) => {
                // sort the entities
                entities = this.stringUtils.sortEntities(entities);

                // alphabatically sort the low precedence entities based on entity name
                const filterPipe = new OrderByPipe();
                this.lowPrecendenceEntities = filterPipe.transform(this.lowPrecendenceEntities, 'entityName', false);

                // append low precedence entities with actual entites array which have only high precedence entities
                entities = entities.concat(this.lowPrecendenceEntities);

                entities.forEach((entity: Entities, index) => {
                    this.filteredEntities.push({
                        name: entity.entityName,
                        id: entity.entityId,
                        groupName: entity.displayGroupName,
                        groupId: entity.displayGroup,
                        isChecked: this.stringUtils.defaultSelectedEntities(entity, this.replaceEntities),
                    });
                });
                this.entities = this.filteredEntities;

                if (!this.filterSettings.skipInitialCall) {
                    this.selectedEntitiesList(this.entities.filter((item) => item.isChecked));
                    this.selectedEntities = this.entities.filter((item) => item.isChecked);
                } else {
                    this.widgetFilterData.entityIDs = this.entities.filter((item) => item.isChecked).map((x) => x.id);
                }
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                this.widgetFilterData.entityIDs = [];
            },
        );
        this.subscriptions.push(entitySubscription);
    }

    public onStartDateChanged(event) {
        this.maxDate = new Date();
        if (!event.value && !this.filterSettings.dateRangeOptional) {
            this.isDateRequireCheck();
            return;
        }
        this.isStartDateInvalid = false;
        this.isDaySpanValid = true;
        this.daySpanErrorMessage = null;

        // condition for checking input date greater than minimum date (Jan 1, 2000) and smaller than maximum date (current date)
        if (event.value > this.maxDate || event.value < this.dateutilService.minDate) {
            this.isStartDateInvalid = true;
            return false;
        }

        if (this.dateValidation() && this.filterSettings.displayDateRanges) {
            this.notifyWidget();
        }
    }

    public onEndDateChanged(event) {
        this.maxDate = new Date();
        if (!event.value && !this.filterSettings.dateRangeOptional) {
            this.isDateRequireCheck();
            return;
        }
        this.isEndDateInvalid = false;
        this.isDaySpanValid = true;
        this.daySpanErrorMessage = null;

        // condition for checking input date greater than minimum date (Jan 1, 2000) and smaller than maximum date (current date)
        if (event.value > this.maxDate || event.value < this.dateutilService.minDate) {
            this.isEndDateInvalid = true;
            return false;
        }

        if (this.dateValidation() && this.filterSettings.displayDateRanges) {
            this.notifyWidget();
        }
    }

    /**
     * Method to show require field error message for start and end date
     */
    private isDateRequireCheck(): void {
        if (
            !this.widgetFilterData.startDate &&
            !this.widgetFilterData.endDate &&
            this.widgetFiltersForm.controls.startDate.touched &&
            this.widgetFiltersForm.controls.endDate.touched
        ) {
            this.daySpanErrorMessage = this.startEndDateError;
        } else if (!this.widgetFilterData.startDate && this.widgetFiltersForm.controls.startDate.touched) {
            this.daySpanErrorMessage = this.startDateError;
        } else if (!this.widgetFilterData.endDate && this.widgetFiltersForm.controls.endDate.touched) {
            this.daySpanErrorMessage = this.endDateError;
        }
        this.isDaySpanValid = false;
        this.isEndDateInvalid = false;
        this.isStartDateInvalid = false;
    }

    private dateValidation() {
        this.daySpanErrorMessage = null;
        if (this.widgetFilterData.startDate && this.widgetFilterData.endDate) {
            const startDate = this.widgetFilterData.startDate;
            const endDate = this.widgetFilterData.endDate;
            if (this.datePickerType === 'date') {
                startDate.setHours(0, 0, 0, 0);
                endDate.setHours(0, 0, 0, 0);
            }
            this.isDaySpanValid = true;

            if (startDate > endDate) {
                this.isDaySpanValid = false;
                this.daySpanErrorMessage = this.dateError;
                return false;
            }

            // if date range limit set as true then check for the date range should not be more than one month.
            if (this.enableDateRangeLimit) {
                const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
                const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
                if (diffDays > 30) {
                    this.isDaySpanValid = false;
                    this.daySpanErrorMessage = this.startEndDiffError;
                    return false;
                }
            }
            return true;
        } else if (
            this.filterSettings.dateRangeOptional &&
            !this.widgetFilterData.startDate &&
            !this.widgetFilterData.endDate
        ) {
            return true;
        }
        return false;
    }

    public loadLocations(): void {
        if (this.filterSettings.displayLocations) {
            // initialize location parameter
            this.locationArgs = {
                customerId: this.customerID,
                locationGroupId: this.locationGroupID,
                IncludeInactiveLocations: this.includeInactiveLocations,
            };
            // Fetch the locations for selected customer
            const subscription = this.usersService.getLocationsList(this.locationArgs).subscribe(
                (res) => {
                    if (res && res.length) {
                        const locationToSelectable = ({ locationId: id, locationName: name }) => ({
                            id,
                            name,
                        });

                        const viewableLocations = res.filter(v => v.viewable);
                        const activeLocations = viewableLocations.filter(v => (v.status === LocationStatus.Active || v.status === LocationStatus.Maintenance));

                        const allLocations = (this.filterSettings.displayInactiveLocations && this.includeInactiveLocations) ?
                            viewableLocations.map(locationToSelectable) : activeLocations.map(locationToSelectable);

                        const locationsMP1Only = activeLocations
                            .filter((location) => location.monitoringPoint === 1)
                            .map(locationToSelectable);

                        const locations = this.filterSettings.onlyDisplayMP1Locations ? locationsMP1Only : allLocations;

                        const filterPipe = new OrderByPipe();
                        this.locations = filterPipe.transform(locations, 'name', false);
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    }
                },
                () => {
                    // error block
                },
            );
            this.subscriptions.push(subscription);
        }
    }

    public selectedLocations(locations: Array<Selectable>) {
        if(_.isEqual(this.widgetFilterData.locationIDs, locations.map(x => x.id))) {
            return;
        }

        this.widgetFilterData.locationIDs = [];

        // ensure display entities
        if (!this.filterSettings.displayLocations) {
            return;
        }
        locations.forEach((location) => {
            if (location.isChecked || this.filterSettings.singleLocationSelect) {
                this.widgetFilterData.locationIDs.push(location.id);
            }
        });

        // TODO: Element do not know there is blur() method available on it. TS solution did not pass compilation on Azure
        (document.activeElement as any).blur();

        this.setDateControlState();

        if (this.filterSettings.singleLocationSelect && locations.length === 0 && this.isInitialCall) {
            this.isInitialCall = false;
        }
        this.notifyWidget();
    }

    public loadLocationGroup(): void {
        if (this.filterSettings.displayLocationGroup) {
            this.locationGroupList = new Array<Selectable>();
            const subscription = this.locationGroupService.getLocationGroups(this.customerID).subscribe((groups) => {
                if (groups && groups.locationGroups) {
                    groups.locationGroups.forEach((locationGroup: LocationGroup) => {
                        this.locationGroupList.push(<Selectable>{
                            id: locationGroup.locationGroupID,
                            name: locationGroup.name,
                        });
                    });
                    this.locationGroupList.push(<Selectable>{ id: 0, name: 'All' });
                    this.locationGroupList = new OrderByPipe().transform(this.locationGroupList, 'name', false);
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                } else {
                    this.locationGroups = [];
                }
                this.uiUtilsService.safeChangeDetection(this.cdr);
            });
            this.subscriptions.push(subscription);
        }
    }

    public selectedLocationsGroup(locationsGroup: Array<Selectable>) {
        // ensure display entities
        if (!this.filterSettings.displayLocations) {
            return;
        }

        this.widgetFilterData.locationGroupId = locationsGroup.map((location) => location.id);

        this.setDateControlState();

        if (this.filterSettings.singleLocationGroupSelect && locationsGroup.length === 0 && this.isInitialCall) {
            this.isInitialCall = false;
            return;
        }

        this.notifyWidget();
    }

    public selectedMonitors(monitors: Array<any>) {
        if (!this.filterSettings.displayLocations) {
            return;
        }
        this.widgetFilterData.monitorIds = monitors.map((monitor) => monitor.id);
        this.setDateControlState();
        if (this.filterSettings.singleMonitorSelect && monitors.length === 0 && this.isInitialCall) {
            this.isInitialCall = false;
            return;
        }
        this.notifyWidget();
    }
    public selectedBlockStatus(blockIds: Array<SelectableBlockagePrediction>) {
        // ensure display entities
        if (!this.filterSettings.displayblockStatus) {
            return;
        }
        this.widgetFilterData.blockageStatus = [];
        blockIds.every((s) => {
            if (s.isChecked) {
                this.widgetFilterData.blockageStatus.push(s.backendName);
            }
            return true;
        });
        this.setDateControlState();
        if (
            this.widgetFilterData.blockageStatus &&
            this.widgetFilterData.blockageStatus.length === 0 &&
            this.isInitialCall
        ) {
            this.isInitialCall = false;
            return;
        }
        this.notifyWidget();
    }

    public selectedEntitiesList(entities: Array<SelectableGroup>) {
        // ensure display entities
        if (!this.filterSettings.displayEntities) {
            return;
        }

        // check for entity selection requirement and if required, ensure at least one is selected.
        if (this.filterSettings.isEntitiesRequired && entities.filter((x) => x.isChecked).length < 1) {
            // exit immediately
            return;
        }

        this.widgetFilterData.entityIDs = entities.filter((item) => item.isChecked).map((item) => item.id);
        this.selectedEntities = entities.filter((item) => item.isChecked);
        this.notifyWidget();
    }

    public selectBatteryStatus(event: Array<Selectable>): void {
        this.widgetFilterData.batteryStatus = null;

        if (event && event.length > 0) {
            this.widgetFilterData.batteryStatus = event[0].name;
        }

        this.notifyWidget();
    }

    public selectAnomalyReason(event: Array<Selectable>): void {
        this.widgetFilterData.anomalyReason = null;

        if (event && event.length > 0) {
            this.widgetFilterData.anomalyReason = event[0].id;
        }

        this.notifyWidget();
    }

    public selectedAlarmStatus(event: Array<Selectable>): void {
        this.widgetFilterData.alarmStatus = [];
        if (event && event.length > 0) {
            event.forEach((x) => {
                if (x.isChecked) {
                    this.widgetFilterData.alarmStatus.push(x.id);
                }
            });
        }
        this.setDateControlState();
        this.notifyWidget();
    }

    public selectedAlarmType(event: Array<Selectable>): void {
        this.widgetFilterData.alarmType = [];
        if (event && event.length > 0) {
            event.forEach((x) => {
                if (x.isChecked) {
                    this.widgetFilterData.alarmType.push(x.id);
                }
            });
        }
        this.setDateControlState();
        this.notifyWidget();
    }

    /* functionality to be added later */
    public selectedUsers(event) {
        // TODO: potential future implementation
    }

    private notifyWidget(): void {
        if (!this.dateValidation()) {
            return;
        }
        if (
            this.customerID > 0 &&
            (this.filterSettings.dateRangeOptional || (!this.filterSettings.dateRangeOptional && this.dateValidation()))
        ) {
            this.sendDataToWidget.emit(this.widgetFilterData);
        }
    }

    private resetDateFilters(): void {
        if (!this.widgetFilterData) {
            this.widgetFilterData = new WidgetFilterData();
        }
        this.widgetFilterData.startDate = new Date();
        this.widgetFilterData.startDate.setDate(
            this.widgetFilterData.startDate.getDate() - (this.filterSettings.dateSpanPastWeek ? 7 : 1),
        );
        // end date
        this.widgetFilterData.endDate = new Date();
        if (this.datePickerType === 'datetime') {
            this.widgetFilterData.startDate.setHours(0, 0, 0, 0);
        } else {
            this.widgetFilterData.endDate.setDate(this.widgetFilterData.endDate.getDate() - 1);
        }
    }
    private resetFilters(): void {
        this.resetDateFilters();
        // set start date and end date to empty for alarm widget filter
        if (this.filterSettings.setAlarmEmptyDate) {
            this.widgetFilterData.startDate = null;
            this.widgetFilterData.endDate = null;
        }
        if (this.errThresholds) {
            this.widgetFilterData.errThreshold = this.errThresholds[0]['value'];
        }
        this.isDaySpanValid = true;
        this.widgetFilterData.customerID = this.customerID;
        this.widgetFilterData.alarmStatus = [0];
        this.widgetFilterData.alarmType = [0];
        // reset entities data, default 4 entities (UNIDEPTH, RAIN,QCONTINUITY,VELOCITY) will be selected
        this.entities = [];
        this.widgetFilterData.entityIDs = this.entities.filter((item) => item.isChecked).map((item) => item.id);
    }

    /**
     * Below function sets the touched property to true for start date and end date form fields
     */
    private setDateControlState(): void {
        // validate if start date field exist
        if (this.widgetFiltersForm.controls.startDate) {
            this.widgetFiltersForm.controls.startDate.markAsTouched();
        }

        // validate if end date field exist
        if (this.widgetFiltersForm.controls.endDate) {
            this.widgetFiltersForm.controls.endDate.markAsTouched();
        }
    }

    public startDateChange(event: MatDatepickerInputEvent<Date>) {
        event.value = !event.value ? new Date(event.target.value) : event.value;
        if (!event.value) {
            this.displayStartDateErrMsg = true;
            this.uiUtilsService.safeChangeDetection(this.cdr);
            return;
        }
        // if (this.menuTrigger !== undefined) {
        //   this.menuTrigger.closeMenu();
        // }
        this.displayStartDateErrMsg = false;
        this.startDate = event.value;

        this.monthDifference(String(this.startDate), String(this.endDate));
        this.notifyWidget;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public endDateChange(event: MatDatepickerInputEvent<Date>) {
        event.value = !event.value ? new Date(event.target.value) : event.value;
        if (!event.value) {
            this.displayEndDateErrMsg = true;
            this.uiUtilsService.safeChangeDetection(this.cdr);
            return;
        }
        // if (this.menuTrigger !== undefined) {
        //   this.menuTrigger.closeMenu();
        // }
        this.displayEndDateErrMsg = false;
        this.endDate = event.value;

        this.monthDifference(this.startDate.toString(), event.value.toString());
        this.notifyWidget();
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public clearInputField(date) {
        this.widgetFilterData[date] = null;
        this.notifyWidget();
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public monthDifference(startDateValue, endDateValue) {
        // ensure args
        if (!startDateValue || !endDateValue) {
            return;
        }

        const startDate = new Date(startDateValue);
        const endDate = new Date(endDateValue);

        // ensure end date is greater
        if (endDate < startDate) {
            this.invalidEndDate = true;
            return;
        }

        this.invalidEndDate = false;

        const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (!(this.invalidStartDate || this.invalidEndDate)) {
            this.tickInterval = HydrographTickInterval.daily;

            if (diffDays > 30) {
                this.tickInterval = HydrographTickInterval.Weekly;
            }
            if (diffDays > 90) {
                this.tickInterval = HydrographTickInterval.Monthly;
            }
        }
    }
}
