export class ScheduleCollection {
    public scheduleId: number;
    public name: string;
    public frequency: number;
    public alarmingFrequency: number;
    public daysToCollect: Array<number>;
    public locations: Array<number>;
    public collectionDays: Array<number>;
    public isSelected: boolean;
    public collectTime: string;
    constructor() {
        // set default values for schedule collection parameters
        this.name = '';
        this.frequency = 0;
        this.alarmingFrequency = 0;
        this.daysToCollect = new Array<number>();
        this.locations = new Array<number>();
        this.collectionDays = new Array<number>();
        this.isSelected = false;
        this.collectTime = '';
    }
}
