import { Component, OnDestroy, ChangeDetectorRef, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IDLE_TIMEOUT_SECONDS } from 'app/shared/constant';
import { Subscription, Observable } from 'rxjs';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
    selector: 'ads-user-idle',
    templateUrl: './user-idle.component.html',
    styleUrls: ['./user-idle.component.scss'],
})
export class UserIdleComponent implements OnDestroy {
    public counter = IDLE_TIMEOUT_SECONDS;
    private subscriptions = new Array<Subscription>();
    constructor(
        private authService: AuthService,
        private dialogRef: MatDialogRef<UserIdleComponent>,
        private uiUtilsService: UiUtilsService,
        private cdr: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: { timer: Observable<number> },
    ) {
        const timerSub = data.timer.subscribe((count) => {
            this.counter = IDLE_TIMEOUT_SECONDS - count;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });

        this.subscriptions.push(timerSub);
    }

    public close() {
        this.dialogRef.close();
    }

    public logOut() {
        this.dialogRef.close();
        this.authService.idleLogout = true;
        this.authService.logout(true);
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }
}
