import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    EventEmitter,
    Output,
} from '@angular/core';
import { IComponentCustomizedConfirmationResult } from 'app/shared/models/customized-confirmation-box';

@Component({
    selector: 'app-customized-confirmation-box',
    templateUrl: './customized-confirmation-box.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomizedConfirmationBoxComponent implements OnInit {
    /**
     * Represents Confirmation Box Title
     */
    public title: string;

    /**
     * Represents Confirmation Box Message Text
     */
    public message: string;

    /**
     * Represents Confirmation cancel Text
     */
    public cancelText: string;

    /**
     * Represents Confirmation ok Text
     */
    public okText: string;

    /**
     * Represents the data of confirmation box.
     */
    @Input() public confirmationData;

    /**
     * Represents the response of confirmation box.
     */
    @Output() public listenConfirmation = new EventEmitter<IComponentCustomizedConfirmationResult>();

    /**
     * display Add comment Flag
     */
    public showAddComments: boolean;

    public comments: string;

    /**
     * Framework LifeCycle Hook
     */
    public ngOnInit() {
        this.title = this.confirmationData.title;
        this.message = this.confirmationData.message;
        this.cancelText = this.confirmationData.cancelText;
        this.okText = this.confirmationData.okText;
        if (this.confirmationData.enterComment) {
            this.showAddComments = this.confirmationData.enterComment;
        }
        this.comments = '';
    }

    /**
     * Represents the cancel action of confirmation box.
     */
    public cancelAndClose() {
        this.listenConfirmation.emit(<IComponentCustomizedConfirmationResult>{
            whichButtonPressed: 'cancel',
            comment: '',
        });
    }

    /**
     * Represents the confirm action of Confirmation Box.
     */
    public okAndClose() {
        this.listenConfirmation.emit(<IComponentCustomizedConfirmationResult>{
            whichButtonPressed: 'ok',
            comment: this.comments,
        });
    }
}
