<mat-expansion-panel [expanded]="true" [ngClass]="{ 'basic-info-section-error': form.invalid }">
    <mat-expansion-panel-header class="mat-expasion-header-background">
        <mat-panel-title class="input-text">
            {{ 'ADD_EDIT.ADD_EDIT_INFORMATION' | translate }}
        </mat-panel-title>
        <mat-panel-description *ngIf="form.invalid && (form | customErrorCount)">
            <mat-icon>warning</mat-icon>
            <span>
                {{ form | customErrorCount }}
                {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.TRITON_REQUIRED_ERRORS_TXT' | translate }}
            </span>
        </mat-panel-description>
        <mat-panel-description *ngIf="form.invalid && !(form | customErrorCount)">
            <mat-icon>warning</mat-icon> <span> </span>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <form [formGroup]="form">
        <div [class.disable-field]="!isMonitorEditor">
            <div class="custom-50p">
                <div class="custom-width">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_NAME' | translate }}"
                            formControlName="locationName"
                            (change)="onLocationNameChange()"
                            required
                        />
                    </mat-form-field>
                    <div class="mat-error">
                        <span *ngIf="form.get('locationName').errors?.dupName"
                            >{{ 'COMMON.LOCATION_NAME' | translate }}
                            {{ 'COMMON.EXISTS_ALREADY' | translate }}
                        </span>
                        <span *ngIf="form.get('locationName').errors?.pattern">
                            {{ 'COMMON.LOCATION_NAME_DESCRIPTION_MANHOLE_ADDRESS_ERROR' | translate }}</span
                        >
                    </div>
                </div>
                <div class="custom-width">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'COMMON.MONITOR_SERIES_PLACEHOLDER' | translate }}"
                            required
                            formControlName="series"
                            class="full-width"
                            (selectionChange)="onChangeMonitorSeries($event, true)"
                        >
                            <mat-option *ngFor="let series of monitorSeries" [value]="series.value">
                                {{ series.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="custom-50p">
                <!-- #34913 allow string values for Fore Site monitors -->
                <div class="custom-width" *ngIf="form.get('series').value?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && form.get('series').value?.toUpperCase() !== MonitorSeriesNames.ForeSITE_Flex">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_SERIAL' | translate }}"
                            required
                            id="SerialNumber"
                            formControlName="serialnumber"
                            (input)="validateSerialNumber('serialnumber')"
                            maxlength="5"
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('serialnumber').errors?.pattern">
                        {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.SERIAL_NUMBER_ERROR' | translate }}
                    </div>
                </div>
                <div class="custom-width" *ngIf="form.get('series').value?.toUpperCase() === MonitorSeriesNames.ForeSITE_UL || form.get('series').value?.toUpperCase() === MonitorSeriesNames.ForeSITE_Flex">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_SERIAL_STRING' | translate }}"
                            required
                            id="SerialNumber"
                            formControlName="serialnumber"
                            (input)="validateSerialNumber('serialnumber')"
                            maxlength="10"
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('serialnumber').errors?.pattern">
                        {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.SERIAL_STRING_ERROR' | translate }}
                    </div>
                </div>


                <div class="custom-width slide-active-display">
                    <mat-form-field class="field-status">
                        <mat-label>Status</mat-label>
                        <mat-select formControlName="status" (selectionChange)="statusChange.emit($event)">
                            <mat-option *ngFor="let status of locationStatuses" [value]="status.key">
                                {{ status.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field
                        class="field-status-hours"
                        *ngIf="form.get('status').value === LocationStatus.Maintenance"
                    >
                        <mat-label>{{ 'CUSTOMER_EDITOR.HOURS' | translate }}</mat-label>
                        <mat-select
                            [placeholder]="'CUSTOMER_EDITOR.HOURS' | translate"
                            formControlName="maintenanceInterval"
                        >
                            <mat-option *ngFor="let hour of locationMaintenanceHours" [value]="hour">
                                {{ hour }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span class="field-status-info" *ngIf="form.get('status').value === LocationStatus.Maintenance">
                        {{ 'CUSTOMER_EDITOR.RETURN_TO_ACTIVE' | translate }}<br />{{
                            !form.get('maintenanceEnd').value ? 'unknown' : form.get('maintenanceEnd').value
                        }}
                    </span>
                </div>
            </div>
            <div class="custom-50p" *ngIf="form.get('series').value?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && form.get('series').value?.toUpperCase() !== MonitorSeriesNames.ForeSITE_Flex">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'COMMON.COMMUNICATION_TYPE_PLACEHOLDER' | translate }}"
                            class="full-width"
                            formControlName="communicationType"
                            (selectionChange)="onCommunicationTypeChange($event)"
                        >
                            <mat-option *ngFor="let series of communicationTypes" [value]="series.monitorSeriesID">
                                {{ series.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_IP' | translate }}"
                            id="IPAddress"
                            formControlName="ipaddress"
                            required
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('ipaddress').errors?.pattern">
                        {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.IP_ADDRESS_ERROR' | translate }}
                    </div>
                </div>
            </div>

            <div class="custom-50p">
                <div class="custom-width custom-flat-button slide-active-display" *ngIf="form.get('series').value?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && form.get('series').value?.toUpperCase() !== MonitorSeriesNames.ForeSITE_Flex">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'COMMON.MODEM_PROVIDER' | translate }}"
                            formControlName="apnProvider"
                        >
                            <mat-option *ngFor="let modem of modemProviders" [value]="modem.name">{{
                                modem.name
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span
                        matTooltip="{{ 'COMMON.EDIT_TOOLTIP' | translate }}"
                        *ngIf="isAdmin"
                        matTooltipPosition="above"
                        (click)="editModemSetup()"
                    >
                        <button mat-button class="no-margin">
                            <mat-icon class="mat-24">edit</mat-icon>
                        </button>
                    </span>
                </div>
                <div class="custom-width">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_LOCATION' | translate }}"
                            formControlName="description"
                            id="locationDesc"
                            (change)="capitalizeValue('description')"
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('description').errors?.pattern">
                        {{ 'COMMON.LOCATION_NAME_DESCRIPTION_MANHOLE_ADDRESS_ERROR' | translate }}
                    </div>
                </div>
            </div>

            <div class="custom-50p">
                <div class="custom-width">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_ASSIGNED' | translate }}"
                            aria-label="Assigned Rain Gauge"
                            [matAutocomplete]="auto"
                            formControlName="assignedRainGauge"
                        />
                        <mat-icon class="clickable" matTooltip="{{'COMMON.CLEAR_TOOLTIP' | translate}}" matSuffix (click)="clearAssignedRainGauge($event)">clear</mat-icon>
                        <mat-autocomplete (optionSelected)="rainGaugeSelected($event)" (opened)="rainGaugeOpened()"
                            #auto="matAutocomplete" (closed)="form.patchValue({ assignedRainGauge: selectedRainGauge })">
                            <mat-option
                                *ngFor="let rainGauge of filteredAssignedRainGauge"
                                [value]="rainGauge.locationName"
                            >
                                <span>{{ rainGauge.locationName }}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('assignedRainGauge').invalid">
                        <span>{{ 'HOME.MAP.RAIN_GAUGE_REQUIRED_VALIDATION' | translate }}</span>
                    </div>
                </div>
                <div class="custom-width" *ngIf="form.get('series').value !== 'RAINALERT III' &&
                    form.get('series').value?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL &&
                    form.get('manholedepth') !== null">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            formControlName="manholedepth"
                            [placeholder]="
                                locationCardService.addDepthUnitToPlaceholder(
                                    translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.MANHOLE_DEPTH')
                                )
                            "
                            [ngClass]="{ 'ng-invalid': form.get('manholedepth').invalid }"
                            (change)="removeSpaces('manholedepth'); manholeDepthChange.emit(true)"
                        />
                    </mat-form-field>
                    <div
                        class="mat-error"
                        *ngIf="form.get('manholedepth').errors?.pattern && !locationCardService.isMetric"
                    >
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                    </div>
                    <div
                        class="mat-error"
                        *ngIf="form.get('manholedepth').errors?.pattern && locationCardService.isMetric"
                    >
                        {{ 'COMMON.NUMERIC_PRECISION_ERROR_METRIC' | translate }}
                    </div>
                    <div class="mat-error" *ngIf="form.get('manholedepth').invalid">
                        {{ 'COMMON.MAN_HOLE_DEPTH_PIPE_HEIGHT_ERROR' | translate }}
                    </div>
                </div>
            </div>

            <div class="custom-50p">
                <div class="custom-width">
                    <!-- #34913 manhole address to address -->
                    <mat-form-field class="full-width" *ngIf="form.get('series').value?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && form.get('series').value?.toUpperCase() !== MonitorSeriesNames.ForeSITE_Flex">
                        <input
                            matInput
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_MANHOLE_ADDRESS' | translate }}"
                            id="manholeAddress"
                            formControlName="manholeaddress"
                            (change)="capitalizeValue('manholeaddress')"
                        />
                    </mat-form-field>
                    <mat-form-field class="full-width" *ngIf="form.get('series').value?.toUpperCase() === MonitorSeriesNames.ForeSITE_UL || form.get('series').value?.toUpperCase() === MonitorSeriesNames.ForeSITE_Flex">
                        <input
                            matInput
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_ADDRESS' | translate }}"
                            id="manholeAddress"
                            formControlName="manholeaddress"
                            (change)="capitalizeValue('manholeaddress')"
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('manholeaddress').errors?.pattern">
                        {{ 'COMMON.LOCATION_NAME_DESCRIPTION_MANHOLE_ADDRESS_ERROR' | translate }}
                    </div>
                </div>
                <div class="custom-width">
                    <div class="custom-50p">
                        <div class="custom-width">
                            <mat-form-field class="full-width">
                                <input
                                    matInput
                                    placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_LATITUDE' | translate }}"
                                    [matTooltipShowDelay]="1000"
                                    id="latitude"
                                    formControlName="latitude"
                                />
                            </mat-form-field>
                        </div>
                        <div class="custom-width">
                            <mat-form-field class="full-width">
                                <input
                                    matInput
                                    placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_LONGITUDE' | translate }}"
                                    [matTooltipShowDelay]="1000"
                                    id="longitude"
                                    formControlName="longitude"
                                />
                            </mat-form-field>
                        </div>
                        <img class="move-icon" [hidden]="!data.enableSelectOnMap" (click)="onMoveIconClick()" src="/assets/images/icons/move_icon.png" />
                    </div>
                    <div
                        class="mat-error"
                        *ngIf="form.get('latitude').errors?.pattern || form.get('longitude').errors?.pattern"
                    >
                        {{ 'COMMON.LONGITUDE_ERROR' | translate }}
                    </div>
                </div>
            </div>

            <div class="custom-50p" *ngIf="form.get('series').value?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && form.get('series').value?.toUpperCase() !== MonitorSeriesNames.ForeSITE_Flex">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_SAMPLE_RATE' | translate }}"
                            class="full-width"
                            formControlName="samplerate"
                            required
                            (selectionChange)="resetSampleRates(false)"
                        >
                            <mat-option *ngFor="let sampleRate of rateList" [value]="sampleRate.value"
                                >{{ sampleRate.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_FAST_SAMPLE_RATE' | translate }}"
                            class="full-width"
                            formControlName="fastrate"
                            required
                            (selectionChange)="resetSampleRates(true)"
                        >
                            <mat-option *ngFor="let sampleRate of rateList" [value]="sampleRate.value"
                                >{{ sampleRate.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('fastrate')?.errors?.rateError || form.get('samplerate')?.errors?.rateError">
                      {{ 'COMMON.TRITON_SAMPLE_RATE_ERROR' | translate }}
                    </div>
                </div>
            </div>

            <div class="custom-50p pad-b-15" *ngIf="form.get('series').value?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL && form.get('series').value?.toUpperCase() !== MonitorSeriesNames.ForeSITE_Flex">
                <div class="each-Item">
                    <div class="full-width">
                        <span class="toggle toggle-text">{{ 'ADD_EDIT.CLOCK_SYNCHRONIZATON' | translate }}</span>
                        <mat-slide-toggle formControlName="synctime"></mat-slide-toggle>
                    </div>
                </div>
                <div class="each-Item">
                    <div class="full-width">
                        <span class="toggle toggle-text">{{ 'ADD_EDIT.DAYLIGHT_SAVING' | translate }}</span>
                        <mat-slide-toggle formControlName="usedaylightsaving"></mat-slide-toggle>
                    </div>
                </div>
            </div>

            <div class="each-Item">
                <mat-form-field class="full-width">
                    <input
                        matInput
                        placeholder="Installation Date"
                        (change)="onInstallationDateChange($event)"
                        [value]="form.get('installationDate').value | date: dateFormat"
                        tabindex="-1"
                        class="datepickerStyles"
                        [disabled]="form.get('installationDate').disabled"
                        appDatetimepickerPosition
                        [matDatetimepickerComp]="installationDte"
                    />
                    <input matInput hidden formControlName="installationDate" [matDatetimepicker]="installationDte" />
                    <mat-datetimepicker-toggle
                        matSuffix
                        [for]="installationDte"
                        [disabled]="form.get('installationDate').disabled"
                    ></mat-datetimepicker-toggle>
                    <mat-datetimepicker #installationDte timeInterval="1" type="date"></mat-datetimepicker>
                </mat-form-field>
            </div>

            <div class="custom-50p">
                <div class="each-Item" *ngIf="form.get('series').value !== 'RAINALERT III'">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.INSTALLATION_TYPE' | translate }}"
                            required
                            class="full-width"
                            formControlName="installationType"
                            (selectionChange)="onInstallationTypeChange($event)"
                        >
                            <mat-option
                                *ngFor="
                                    let installationTypeUIInstance of installationTypes
                                        | filterByUnpickable: form.get('installationType').value
                                "
                                [value]="installationTypeUIInstance"
                                >{{ installationTypeUIInstance.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item" *ngIf="form.get('series').value?.toUpperCase() === MonitorSeriesNames.ForeSITE_UL || form.get('series').value?.toUpperCase() === MonitorSeriesNames.ForeSITE_Flex">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_SAMPLE_RATE' | translate }}"
                            class="full-width"
                            formControlName="samplerate"
                            required
                            (selectionChange)="resetSampleRates(false)"
                        >
                            <mat-option *ngFor="let sampleRate of foreSiteRateList" [value]="sampleRate.value"
                                >{{ sampleRate.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item" *ngIf="form.get('series').value?.toLowerCase().indexOf('rainalert') < 0 &&
                    form.get('series').value?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL &&
                    form.get('series').value?.toUpperCase() !== MonitorSeriesNames.ForeSITE_Flex">
                    <mat-form-field class="full-width">
                        <mat-select
                            placeholder="{{ 'COMMON.QFINALTEXT' | translate }}"
                            formControlName="qFinalEntityID"
                        >
                            <mat-option *ngFor="let f of flowEntities" [value]="f.entityId">{{
                                f.entityName
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <app-installation-type-info
                *ngIf="form.get('installationForm')"
                [form]="form.get('installationForm')"
                [type]="form.get('installationType')?.value"
                [installationType]="installationTypeSeries"
                [monitorSeries]="selectedMonitorSeries"
                (pipeHeightChange)="pipeHeightChange.emit({ value: $event, isMp2: false })"
            ></app-installation-type-info>

            <div class="tags-container">
                <div class="tags-header">
                    <p>{{ 'ADD_EDIT.INPUT_TAG_TITLE' | translate }}:</p>

                    <button mat-button [disabled]="tags.controls.length >= 64" color="primary" type="button" (click)="addNewTag()">{{ 'COMMON.ADD_MORE' | translate }}</button>
                </div>
                <ng-container formArrayName="customProperties" *ngFor="let tagGroup of tags?.controls; let i = index">
                    <div class="tags-content">
                        <div class="custom-50p" [formGroupName]="i">
                            <div class="custom-width">
                                <mat-form-field class="full-width">
                                    <input
                                        matInput
                                        #tagNameInput
                                        placeholder="{{ 'ADD_EDIT.INPUT_TAG_NAME' | translate }}"
                                        formControlName="key"
                                        [matAutocomplete]="tagNameAutocomplete"
                                        (input)="onTagInput(tagNameInput.value, i)"
                                        (focus)="onTagInput(tagNameInput.value, i)"
                                    />
                                    <mat-autocomplete
                                        #tagNameAutocomplete="matAutocomplete"
                                        (optionSelected)="onTagSelected($event.option.value, i)"
                                    >
                                        <mat-option
                                            *ngFor="let option of tagsAutocomplete"
                                            [value]="option"
                                        >
                                            {{ option }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <div class="mat-error" *ngIf="tagGroup.get('key')?.errors?.unique">
                                    {{ 'ADD_EDIT.DUPLICATE_TAG_NAME_ERROR' | translate }}
                                </div>
                                <div class="mat-error" *ngIf="tagGroup.get('key')?.errors?.maxlength">
                                    {{ 'ADD_EDIT.TAG_TEXT_LENGTH_ERROR' | translate }}
                                </div>
                            </div>
                            <div class="custom-width">
                                <mat-form-field class="full-width">
                                    <input
                                        matInput
                                        placeholder="{{ 'ADD_EDIT.INPUT_TAG_VALUE' | translate }}"
                                        formControlName="value"
                                    />
                                </mat-form-field>
                                <div class="mat-error" *ngIf="tagGroup.get('value')?.errors?.maxlength">
                                    {{ 'ADD_EDIT.TAG_TEXT_LENGTH_ERROR' | translate }}
                                </div>
                            </div>
                        </div>
                        <button mat-icon-button type="button" (click)="deleteTag(i)"><mat-icon>delete</mat-icon></button>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
</mat-expansion-panel>
