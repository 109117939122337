<div
    #slidercontainer
    class="custom-slider"
    *ngIf="!hideSliders && !isStudyLocked && stormPeriodsMinMax && optionsPrecomp.ceil && optionsPrecomp.floor && !isRefreshing"
>
    <span
        *ngIf="stormPeriods.deattachPrecomp"
        id="slider-1"
        [ngClass]="{deattached: !stormPeriods.deattachPrecomp}"
    >
        <ngx-slider
            [options]="optionsPrecomp"
            [(value)]="model.precompStart"
            [(highValue)]="model.stormStart"
            (userChangeEnd)="emitValues($event, 'precompStart', false)"
        >
        </ngx-slider>
    </span>
    <span
        *ngIf="!stormPeriods.deattachPrecomp"
        id="slider-1"
        [ngClass]="{deattached: !stormPeriods.deattachPrecomp}"
    >
        <ngx-slider
            [options]="optionsPrecomp"
            [(value)]="model.precompStart"
            [(highValue)]="model.precompEnd"
            (userChangeEnd)="emitValues($event, 'precompStart', false)"
        >
        </ngx-slider>
    </span>
    <span
        id="slider-2"
        [ngClass]="{deattached: stormPeriods.deattachPrecomp}"
    >
        <ngx-slider
            [options]="optionsStorm"
            [value]="model.stormStart"
            [(highValue)]="model.recovery1Start"
            (userChangeEnd)="emitValues($event, 'stormStart', true)"
        >
        </ngx-slider>
    </span>
    <span id="slider-3">
        <ngx-slider
            [options]="optionsRecovery1"
            [value]="model.recovery1Start"
            [(highValue)]="model.recovery2Start"
            (userChangeEnd)="emitValues($event, 'recovery1Start', false)"
        >
        </ngx-slider>
    </span>
    <span id="slider-4">
        <ngx-slider
            [options]="optionsRecovery2"
            [value]="model.recovery2Start"
            [(highValue)]="model.recovery2End"
            (userChangeEnd)="emitValues($event, 'recovery2Start', false)"
        >
        </ngx-slider>
    </span>
    <div></div>
</div>
