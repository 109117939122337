<mat-expansion-panel [ngClass]="{ 'data-delivery-section-error': form.invalid }">
    <mat-expansion-panel-header class="mat-expasion-header-background">
        <mat-panel-title class="input-text">
            {{ 'ADD_EDIT.ADD_EDIT_DATA_DELIVERY' | translate }}
        </mat-panel-title>
        <mat-panel-description *ngIf="form.invalid && (form | customErrorCount)">
            <mat-icon>warning</mat-icon>
            <span>
                {{ form | customErrorCount }}
                {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.TRITON_REQUIRED_ERRORS_TXT' | translate }}
            </span>
        </mat-panel-description>
        <mat-panel-description *ngIf="form.invalid && !(form | customErrorCount)">
            <mat-icon>warning</mat-icon> <span> </span>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <form [formGroup]="form">
        <div [class.disable-field]="!isMonitorEditor">


            <div *ngIf="form.get('prismdeliveryipaddress')">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <input
                            matInput
                            placeholder="{{ 'ADD_EDIT.IP_ADDRESS' | translate }}"
                            formControlName="prismdeliveryipaddress"
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('prismdeliveryipaddress').errors?.pattern">
                        <span>{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.IP_DNS_ERROR' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="custom-50p" *ngIf="form.get('prismdeliverynormal') && form.get('prismdeliveryfast')">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_NORMAL_RATE' | translate }}"
                            formControlName="prismdeliverynormal"
                            class="full-width"
                            (selectionChange)="resetRates(false)"
                        >
                            <mat-option *ngFor="let frequency of normalDeliveryFrequencyList" [value]="frequency.value">
                                {{ frequency.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            formControlName="prismdeliveryfast"
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_FAST_RATE' | translate }}"
                            class="full-width"
                            (selectionChange)="resetRates(true)"
                        >
                            <mat-option *ngFor="let frequency of normalDeliveryFrequencyList" [value]="frequency.value">
                                {{ frequency.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>

                    <div class="mat-error" *ngIf="form.get('prismdeliverynormal').errors?.rateError || form.get('prismdeliveryfast').errors?.rateError">
                        {{ 'COMMON.TRITON_DELIVERY_RATE_ERROR' | translate }}
                    </div>
                </div>
            </div>


            <div class="custom-50p" *ngIf="form.get('normalIntervalHours') && form.get('fastModeEnabled')">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_NORMAL_RATE' | translate }}"
                            formControlName="normalIntervalHours"
                            class="full-width"
                        >
                            <mat-option *ngFor="let frequency of normalDeliveryFrequencyList" [value]="frequency.value">
                                {{ frequency.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            formControlName="fastModeEnabled"
                            placeholder="{{ 'ADD_EDIT.INPUT_PLACEHOLDER_FAST_RATE' | translate }}"
                            class="full-width"
                        >
                            <mat-option *ngFor="let frequency of fastDeliveryList" [value]="frequency.value">
                                {{ frequency.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
</mat-expansion-panel>
