<div class="vault-delete-action">
    <div mat-dialog-title>
        <span>{{ 'COMMON.DELETE_TITLE' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <app-loader [isLoading]="isLoading"></app-loader>
    <div mat-dialog-content>
        <span>{{ 'COMMON.DELETE_CONFIRMATION_DIALOG_START' | translate }}</span>
        <b>{{ selectedFiles.length }}</b>
        <span>{{ 'COMMON.DELETE_CONFIRMATION_DIALOG_END' | translate }}</span>
        <ul>
            <li *ngFor="let file of fileNameList">{{ file }}</li>
        </ul>
    </div>
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="close()">{{ 'COMMON.TEST_NO' | translate }}</button>
        </div>
        <div class="auto-margin">
            <button mat-button (click)="delete()">{{ 'COMMON.TEST_YES' | translate }}</button>
        </div>
    </div>
</div>
