import { FormulaEntity } from './data-edit';

export interface UserSubscription {
    UserID: string;
    SubscriptionType: number;
    Threshold: number;
    LocationID: number;
    Preference: string;
}

export interface NotificationSummary {
    id: string;
    name: string;
    description: string;
    type: NotificationRouteAlarmType;
    enabled: boolean;
    subscribeLevels?: { name: string; delay: number; alarm: boolean; rtn: boolean }[];
}
/** Category of notifiation, refers to types: ALARM=NotificationAlarmType and NOTIFICATION=NotificationType */
export enum NotificationCategory {
    ALARM = 0,
    NOTIFICATION = 1,
}
/** Notification Alarm types. IMPORTANT: Values are routes within application. */
export enum NotificationRouteAlarmType {
    Standard = 'STANDARD',
    ANSR = 'ANSR',
}
/** Notification types - it's different category then NotificationAlarmType and is separated from them. IMPORTANT: Values are routes within application. */
export enum NotificationRouteType {
    DAILY = 'DAILY',
}
export interface NotificationLocationOptions {
    id: number;
    name: string;
    checked: boolean;
    group: string;
}
export interface NotificationWDORainGaugeLocationOptions {
    id: number;
    name: string;
    checked: boolean;
}
export interface NotificationAlarmOptions {
    id: number;
    name: string;
    checked: boolean;
}
export interface NotificationValidation {
    fieldErrors: {
        field: string;
        message: string;
    }[];
    warnings: {
        location: string;
        lid: number;
        items: { error: number; type: number; text: string }[];
    }[];
    errors: {
        location: string;
        lid: number;
        items: { error: number; type: number; text: string }[];
    }[];
}
export interface NotificationDetailsLite {
    name: string;
    desc?: string;
    levels: NotificationLevel[];
    lids: number[];
    guid?: string;
    aids?: number[];
    ansr?: NotificationANSR;
    /**  0 is default, and is Alarm. 1 is Daily Report */
    type?: NotificationType;
    enabled: boolean;
}
export enum NotificationType {
    ALARM = 0,
    DAILY_REPORT = 1,
}
export interface NotificationLevel {
    name: string;
    delay: number;
    level?: number;
    enabled?: boolean;
    users?: NotificationUser[];
}
interface NotificationANSR {
    ents: Array<NotificationFormulaEntity>;
    equation: string;
    name: string;
    description: string;
    formulaType: number;
}
export interface NotificationDailyReport extends NotificationDetailsLite {
    /** Days for days of week, it is a string.  0=Sunday, 1=Monday, 3=Wed. */
    days?: string;
    time?: string;
    // 0 is Communication Report, 1 is Low Battery report
    drids?: NotificationDailyReportDRIDS[];
}
export enum NotificationDailyReportDRIDS {
    COMMUNICATION_REPORT = 0,
    LOW_BATTERY = 1,
}

interface NotificationFormulaEntity {
    locationId: number;
    entityId: number;
    variableName: string;
    orderId?: number;
    timeInterval?: { days: number; hours: number; minutes: number };
    manningRoughness?: number;
    manningSlope?: number;
    manningVariableRoughness?: boolean;
}
export interface NotificationUser {
    id: string;
    dispName?: string;
    admin?: boolean;
    alarm?: boolean;
    rtn?: boolean;
}
export interface NotificationResponseValidation {
    isError: boolean;
    message: string;
    fieldErrors: any[];
    payload: any;
    count: number;
}

export enum NotificationValidationError {
    AlarmInvalid,
    AlarmNotConfigured,
    DuplicateNotification,
    ConfigurationInvalid,
    CommunicationTypeInvalid,
}
