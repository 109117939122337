import {
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';
import { UpdatesWidgetService } from '../../shared/components/updates-widget/updates-widget.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { Settings } from 'app/shared/models/sliicer';
import _ from 'lodash';
import { UpdateInfo, UpdatesAction } from '../../shared/components/updates-widget/updates-widget.models';
import { RainfallDdfComponent } from './rainfall-ddf/rainfall-ddf.component';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { DdfTable } from 'app/shared/models/sliicer/customer-rainfall-profile';

const UPDATES_RAINFALL_MODEL_TAG = 'UPDATES_RAINFALL_MODEL';
const UPDATES_RAINFALL_MODEL = "RainfallModel";

@Component({
    selector: 'app-rainfall-monitor',
    templateUrl: './rainfall-monitor.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})
export class SliicerRainfallMonitorComponent implements OnInit, OnDestroy {
    @ViewChild('rainfallDdfComponent', { static: true }) public rainfallDdfComponent: RainfallDdfComponent;

    @Input() public customerId: number;
    @Input() public caseStudyId: string;

    public isUpdateAvailable = false;
    public ddfTableIsValid = true;

    private caseStudySettings: Settings;
    private ddfTableUpdateData: DdfTable[];

    private subscriptions: Subscription[] = [];

    private dismissBtn: string;
    private savedDdfText : string;
    private failedDdfText : string;

    constructor(
        private updatesWidgetService: UpdatesWidgetService,
        private snackBarNotificationService: SnackBarNotificationService,
        private translate: TranslateService,
        private sliicerService: SliicerService
    ) {

        this.savedDdfText = this.translate.instant('CUSTOMER_EDITOR.RAIN_PROFILE.DDF.DDF_CREATION_SUCCESSFUL');
        this.failedDdfText = this.translate.instant('CUSTOMER_EDITOR.RAIN_PROFILE.DDF.DDF_CREATION_FAILED');
    }

    public ngOnInit() {
        this.updatesWidgetService.errors.rainfall = false;

        this.sliicerService.studyDetailsData$.subscribe((caseStudy) => {
            this.caseStudySettings = caseStudy.settings;
        });

        this.subscriptions.push(
            this.updatesWidgetService.updatesAction.subscribe((action) => {
                if (this.updatesWidgetService.updatesModel === UPDATES_RAINFALL_MODEL) {
                    switch (action) {
                        default:
                            break;
                        case UpdatesAction.undoChanges:
                            this.undoChanges();
                            break;
                        case UpdatesAction.applyChanges:
                            this.applyChanges();
                            break;
                    }
                }
            })
        );
    }

    private undoChanges() {
        this.rainfallDdfComponent.undoChanges();
        this.ddfTableUpdateData = null;
        this.checkUpdatesInfo();
    }

    private applyChanges() {
        this.caseStudySettings.ddfData.ddfTable = this.ddfTableUpdateData;
        this.sliicerService.saveDepthDurationForStudy(
            this.customerId,
            this.caseStudyId,
            this.caseStudySettings).subscribe(() => {
                    this.rainfallDdfComponent.applyChanges();
                    this.rainfallDdfComponent.setLoading(false);

                    this.ddfTableUpdateData = null;
                    this.sliicerService.showToast(this.savedDdfText);
                    this.checkUpdatesInfo();
                },
                (error) => {
                    this.rainfallDdfComponent.setLoading(false);
                    this.snackBarNotificationService.raiseNotification(this.failedDdfText, this.dismissBtn, {}, false);
                    this.checkUpdatesInfo();
                },
            )
    }

    private checkUpdatesInfo() {
        const ddfTableUpdate = this.ddfTableUpdateData && !_.isEqual(this.ddfTableUpdateData, this.caseStudySettings.ddfData.ddfTable);

        const updatesInfo: UpdateInfo[] = [];

        if(ddfTableUpdate) {
            updatesInfo.push({title: UPDATES_RAINFALL_MODEL_TAG, values: []});
        }

        this.isUpdateAvailable = updatesInfo.length > 0;
        if(this.isUpdateAvailable) {
            this.updatesWidgetService.setUpdatesInfo(updatesInfo, UPDATES_RAINFALL_MODEL);
        } else {
            this.clearUpdatesInfo();
        }
    }

    private clearUpdatesInfo() {
        this.updatesWidgetService.updatesLoader = false;
        this.updatesWidgetService.setUpdatesInfo([], '');
    }

    public ddfTableChange(ddfTable: DdfTable[]) {
        this.ddfTableIsValid = ddfTable.every(row => row.durationMinutes)
        this.updatesWidgetService.errors.rainfall = !this.ddfTableIsValid;


        this.ddfTableUpdateData = this.ddfTableIsValid ? ddfTable : null;

        this.checkUpdatesInfo();
    }

    public ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.subscriptions = [];
    }
}
