import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ADS_REFRESH_TOKEN_KEY, AuthService } from './auth.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard  {
    constructor(private authService: AuthService, private router: Router) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        const url: string = state.url;
        if (sessionStorage.getItem('adsToken') != null) {
            return true;
        } else if (localStorage.getItem(ADS_REFRESH_TOKEN_KEY) != null) {
            return this.authService.callRefreshToken().pipe(map(() => true))
        } else {
            return this.checkLogin(url);
        }
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        return this.canActivate(route, state);
    }

    public checkLogin(url: string): boolean {
        if (this.authService.isAuthenticated) {
            if(!url.toString().includes('?') && sessionStorage.getItem('params') != null)
            {
                window.location.href =url+"?"+sessionStorage.getItem('params');
            }
            return true;
        }
        // Store the attempted URL for redirecting
        this.authService.redirectUrl = url;
        // Navigate to the login page
        this.router.navigate(['/welcome']);
        return false;
    }
}
