import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { AlarmGraphComponent } from 'app/pages/alarm-graph/alarm-graph.component';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import * as Highcharts from 'highcharts';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs';

import { FilterDataSource } from '../../shared/components/paging/filter-data-source';
import { UsersService } from '../admin/users.service';
import { first } from 'rxjs/operators';
import { BlockagePredictionService } from 'app/shared/services/blockage-prediction.service';
import { LocationService } from 'app/shared/services/location.service';
import {
    BlockagePredictionLocationData,
    BlockagePredictionResults,
    BlockagePredictionSpanArgs,
} from 'app/shared/models/blockage-prediction';
import { LocationDashboardFilterData } from 'app/shared/models/location-dashboard-filter-data';
import { GraphData } from 'app/shared/models/graph-data';
@Component({
    selector: 'app-blockage-prediction-widget',
    templateUrl: './blockage-prediction-widget.component.html',
    styleUrls: ['./blockage-prediction-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class BlockagePredictionWidgetComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public showBlockagePredictionSearch: boolean;
    @Input() public customerID: number;
    @Input() public locationGroupID: number;
    @Input() public customerLocationsLoaded: boolean;
    @Input() public includeInactiveLocations: boolean;
    @Input() public dateFormat: string;
    @Input() public timeFormat: string;
    @Output() public showMapLocationForBlockagePrediction: EventEmitter<Array<number>> = new EventEmitter<
        Array<number>
    >();
    @Output() private emitWidgetData = new EventEmitter();

    @ViewChild(MatPaginator, {static: true}) public blockagePredictionPaginator: MatPaginator;
    @ViewChild(MatSort) public blockagePredictionSort: MatSort;
    @ViewChild('filter') public blockagePredictionFilter: ElementRef;

    public blockagePredictionDetails: Array<BlockagePredictionLocationData> = [];
    public showPagination = true;
    public blockagePredictionDisplayedColumns = ['locationName', 'date', 'blockStatus', 'depthTrend'];
    public blockagePredictionFilterColumns = ['locationName', 'date', 'blockStatus'];
    public blockagePredictionDataSource: FilterDataSource | null;
    public blockagePredictionDataChange: BehaviorSubject<BlockagePredictionLocationData[]> = new BehaviorSubject<
        BlockagePredictionLocationData[]
    >([]);
    public unknown: string;
    public noBlockage: string;
    public developing: string;
    public advanced: string;
    public totalPaginationLength = 0;
    public isPaginationRequired = false;
    public blockagePredictionLoadingState: boolean;
    private subscriptions = new Array<Subscription>();
    private startDate = null;
    private endDate = null;
    private locationId = [0];
    private blockageStatus = [];

    /**
     * Variable which validates API state of the first initially loaded blockage prediction widget locations on map
     */
    public blockagePredictionAPIState = true;
    public rawDataActive: boolean;

    public get blockagePredictionData(): BlockagePredictionLocationData[] {
        return this.blockagePredictionDataChange.value;
    }

    constructor(
        private translate: TranslateService,
        private blockagePredictionService: BlockagePredictionService,
        private locationService: LocationService,
        private element: ElementRef,
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog,
        private uiUtilsService: UiUtilsService,
        private usersService: UsersService,
    ) {}
    public ngOnInit() {
        this.rawDataActive = this.usersService.isRawDataEditingAllowed.getValue();
        const translateKeys: Array<string> = [
            'HOME.BLOCKAGE_PREDICTION_TILE.BLOCK_STATUS_UNKNOWN',
            'HOME.BLOCKAGE_PREDICTION_TILE.BLOCK_STATUS_NO_BLOCKAGE',
            'HOME.BLOCKAGE_PREDICTION_TILE.BLOCK_STATUS_DEVELOPING',
            'HOME.BLOCKAGE_PREDICTION_TILE.BLOCK_STATUS_ADVANCED',
        ];
        this.translate.get(translateKeys).subscribe((translateValues) => {
            if (!translateValues) {
                return;
            }
            this.unknown = translateValues['HOME.BLOCKAGE_PREDICTION_TILE.BLOCK_STATUS_UNKNOWN'];
            this.noBlockage = translateValues['HOME.BLOCKAGE_PREDICTION_TILE.BLOCK_STATUS_NO_BLOCKAGE'];
            this.developing = translateValues['HOME.BLOCKAGE_PREDICTION_TILE.BLOCK_STATUS_DEVELOPING'];
            this.advanced = translateValues['HOME.BLOCKAGE_PREDICTION_TILE.BLOCK_STATUS_ADVANCED'];
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.customerLocationsLoaded) {
            this.blockagePredictionAPIState = true;
            if(this.blockagePredictionPaginator) {
                this.blockagePredictionPaginator.pageIndex = 0;
            }
            this.fetchBlockagePredictionData();
        } else if (
            (changes.dateFormat && changes.dateFormat.previousValue !== changes.dateFormat.currentValue) ||
            (changes.timeFormat && changes.timeFormat.previousValue !== changes.timeFormat.currentValue)
        ) {
            this.fetchBlockagePredictionData();
        }
    }

    /**
     * The widget is getting notified from browser that there are some
     * filtering decisions being made.
     */
    public notifyWidget(filtersData: LocationDashboardFilterData): void {
        if (filtersData.startDate) {
            this.startDate = [
                filtersData.startDate.getMonth() + 1,
                filtersData.startDate.getDate(),
                filtersData.startDate.getFullYear(),
            ].join('-');
            this.locationId = [0];
        }
        if (filtersData.endDate) {
            this.endDate = [
                filtersData.endDate.getMonth() + 1,
                filtersData.endDate.getDate(),
                filtersData.endDate.getFullYear(),
            ].join('-');
            this.locationId = [0];
        }

        if (filtersData.blockageStatus) {
            this.blockageStatus = filtersData.blockageStatus;
            this.locationId = [0];
        }

        if (filtersData.locationIDs && filtersData.locationIDs.length > 0) {
            this.locationId = filtersData.locationIDs;
        }

        this.blockagePredictionPaginator.pageIndex = 0;
        this.fetchBlockagePredictionData();
    }

    private fetchBlockagePredictionData(): void {
        const args = <BlockagePredictionSpanArgs>{
            customerId: this.customerID,
            locationGroupId: this.locationGroupID,
            index: this.blockagePredictionPaginator.pageIndex,
            pageSize: this.blockagePredictionPaginator.pageSize,
            start: this.startDate,
            end: this.endDate,
            locationId: this.locationId || undefined,
            includeInactiveLocations: this.includeInactiveLocations,
            blockageStatus: this.blockageStatus,
        };

        const shouldResetPagination = args.index == 0;

        this.blockagePredictionLoadingState = true;
        this.uiUtilsService.safeChangeDetection(this.cdr);

        const subscriptionGetBlockagePredictionWidget = this.blockagePredictionService
            .getWidgetBlockagePredictionResults(args)
            .subscribe(
                (response: BlockagePredictionResults) => {
                    if (response) {
                        this.blockagePredictionDetails = response.locationBlockages;

                        if (shouldResetPagination) {
                            this.totalPaginationLength = response.totalPredictionCount;
                            this.isPaginationRequired =
                                this.blockagePredictionDetails.length > 0 &&
                                this.totalPaginationLength > this.blockagePredictionPaginator.pageSize;
                        }

                        this.loadSparkLine();
                        this.generateBlockagePredictionTable(args);

                        /**
                         * Below Logic emits the blockage prediction widget locations to parent component(i.e.landing page)
                         * to display the locations on map. This code will also avoid the multiple API call to fetch the blockage prediction data.
                         */
                        if (this.blockagePredictionAPIState) {
                            this.showMapLocationForBlockagePrediction.emit(
                                this.blockagePredictionDetails.map((element) => element.locationId),
                            );
                        }
                    }

                    this.blockagePredictionAPIState = false;
                    this.blockagePredictionLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
                (error) => {
                    this.blockagePredictionLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
            );

        this.subscriptions.push(subscriptionGetBlockagePredictionWidget);
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public generateBlockagePredictionTable(args: BlockagePredictionSpanArgs) {
        this.blockagePredictionLoadingState = true;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        this.blockagePredictionDataChange = new BehaviorSubject<BlockagePredictionLocationData[]>([]);
        if (this.blockagePredictionDetails.length > 0) {
            this.blockagePredictionDataChange.next(this.blockagePredictionDetails);
        }

        this.blockagePredictionPaginator.pageIndex = args.index;

        this.blockagePredictionDataSource = new FilterDataSource(
            this.blockagePredictionDataChange,
            this.blockagePredictionData,
            this.blockagePredictionPaginator,
            this.blockagePredictionSort,
            this.blockagePredictionFilterColumns,
            true,
        );
        this.blockagePredictionLoadingState = false;
        this.emitWidgetData.emit(this.blockagePredictionDataChange.getValue());
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public getMarkerLocationDetails(locationId: number): void {
        this.locationService.locationId = locationId;
    }

    // on next and previous button click of paginator in customer list
    public onPageChange(event): void {
        this.fetchBlockagePredictionData();
    }

    public loadSparkLine() {
        setTimeout(() => {
            this.displayDepthAndStatusGraph();
        }, 1);
    }

    public displayDepthAndStatusGraph() {
        Highcharts.SparkLine = function (a, b, c) {
            const hasRenderToArg = typeof a === 'string' || a.nodeName;
            let options = arguments[hasRenderToArg ? 1 : 0];
            const defaultOptions = {
                exporting: {
                    enabled: false,
                },
                chart: {
                    renderTo: (options.chart && options.chart.renderTo) || this,
                    backgroundColor: null,
                    borderWidth: 0,
                    type: 'area',
                    margin: [2, 0, 2, 0],
                    width: 150,
                    height: 20,
                    style: {
                        overflow: 'visible',
                    },

                    // small optimalization, saves 1-2 ms each sparkline
                    skipClone: true,
                },
                title: {
                    text: '',
                },
                credits: {
                    enabled: false,
                },
                xAxis: {
                    labels: {
                        enabled: false,
                    },
                    title: {
                        text: null,
                    },
                    startOnTick: false,
                    endOnTick: false,
                    tickPositions: [],
                    gridLineColor: 'black',
                    lineColor: 'white',
                    minorGridLineColor: 'black',
                    tickColor: 'black',
                },
                yAxis: {
                    endOnTick: false,
                    startOnTick: false,
                    labels: {
                        enabled: false,
                    },
                    title: {
                        text: null,
                    },
                    tickPositions: [0],
                },
                legend: {
                    enabled: false,
                },
                tooltip: {
                    hideDelay: 0,
                    outside: true,
                    shared: true,
                    style: {
                        display: 'none',
                    },
                },
                plotOptions: {
                    series: {
                        animation: false,
                        lineWidth: 1,
                        shadow: false,
                        states: {
                            hover: {
                                enabled: false,
                            },
                        },
                        marker: {
                            radius: 1,
                            states: {
                                hover: {
                                    enabled: false,
                                },
                            },
                        },
                        fillOpacity: 0.25,
                    },
                    column: {
                        negativeColor: 'green',
                        color: '#f0ad4e',
                        borderColor: 'silver',
                    },
                    area: {
                        fillColor: 'transparent',
                    },
                },
            };

            options = Highcharts.merge(defaultOptions, options);

            return hasRenderToArg ? new Highcharts.Chart(a, options, c) : new Highcharts.Chart(options, b);
        };

        // retireve node ist or use default object
        const rows: Array<Element> = this.element.nativeElement.querySelectorAll('span[data-sparkline]') || {};

        for (let i = 0; i < rows.length; i += 1) {
            // get reference to the row
            const row: Element = rows[i];

            // ensure the row has attrbute data
            if (!row.hasAttributes() && row.attributes['data-sparkline'] === undefined) {
                return;
            }

            // get the chart data from the nodes data attribute
            const stringdata: string = row.attributes['data-sparkline'].value || '';

            // ensure that data was retrieved from data attribute
            if (stringdata !== '') {
                // convert string data to array - not yet sure why we have to do this step but perhaps this is being reused somehow???
                const arr: Array<string> = stringdata.split('; ');

                // convert the data in the array to floating points
                const data: Array<number> = arr[0].split(',').map((value: string) => Number(value));

                // setup chart
                const chart = {
                    renderTo: row,
                };

                // if arr has a second element, set the value of the second element to the type of the chart
                if (arr[1]) {
                    chart['type'] = arr[1];
                }

                // build chart
                // tslint:disable-next-line:no-unused-expression
                new Highcharts.SparkLine({
                    chart: chart,
                    series: [
                        {
                            name: '',
                            data: data,
                            pointStart: 1,
                        },
                    ],
                    tooltip: {
                        headerFormat: '<span style="font-size: 10px">' + 'Depth Trend:</span><br/>',
                        pointFormat: '<b>{point.y}</b>',
                    },
                });
            }
        }
    }

    /**
     * Below function opens the hydrograph while clicking on graph icon
     * @param locationId represents blockage prediction's location id
     * @param locationName represents blockage prediction's location name
     * @param timestamp represents blockage prediction's date
     */
    public openHydrograph(locationId: number, locationName: string, timestamp: string): void {
        // open graph dialog modal
        const dialogRef = this.dialog.open(AlarmGraphComponent, {
            disableClose: true,
            data: <GraphData>{
                locationId: locationId,
                locationName: locationName,
                eventDate: timestamp,
                timeFormat: this.timeFormat,
            },
        });

        dialogRef
            .afterOpened()
            .pipe(first())
            .subscribe(() => {
                dialogRef.componentInstance.loadGraphData();
            });
    }

    /**
     * //checks if the name length is more than specific length
     */
    public validateLocationNameLength(str: string, strLength: number): boolean {
        if ((str + '').length > strLength) {
            return true;
        } else {
            return false;
        }
    }
}
