import { Injectable } from '@angular/core';
import { EventTypes } from '../models/event';
import { BehaviorSubject, Observable } from 'rxjs';
import { RED_COLOR_HEX, DEFAULT_MARKER_COLOR, DATA_QUALITY_COLORS, EDIT_COLOR, ORANGE_COLOR_HEX, ANNOTATION_MARKER_COLOR, ANNOTATION_IGNORED_MARKER_COLOR } from '../constant';

@Injectable()
export class HydrographNEWService {

    // #14192 entity colors as observable
    private entityColors$: BehaviorSubject<{[key: number]: string}> = new BehaviorSubject([]);

    constructor() {}
    public getMarkerTypes() {
        return {
            normal: {
                fillColor: undefined,
                symbol: undefined,
                radius: null,
                enabled: false,
                states: {
                    hover: {
                        symbol: 'diamond',
                        radius: 4,
                        enabled: true,
                        fillColor: undefined,
                    },
                },
            },
            marked: {
                fillColor: undefined,
                symbol: 'diamond',
                radius: 4,
                enabled: true,
            },
            flagged: {
                fillColor: RED_COLOR_HEX,
                symbol: 'diamond',
                radius: 4,
                enabled: true,
            },
            selected: {
                // TODO: Temporary fill color to be different then others, since LC color lines not points
                // fillColor: 'blue',
                fillColor: '#FF8C00',
                symbol: 'diamond',
                radius: 4,
                enabled: true,
            },
            snapped: {
                fillColor: EDIT_COLOR,
                symbol: 'diamond',
                radius: 4,
                enabled: true,
            },
            corrected: {
                fillColor: EDIT_COLOR,
                symbol: `diamond`,
                radius: 4,
                enabled: true,
            },
            correctedOnDragAndDrop: {
                fillColor: ORANGE_COLOR_HEX,
                symbol: `diamond`,
                radius: 6,
                enabledThreshold: 5,
                enabled: true,
            },
            interpolated: {
                fillColor: EDIT_COLOR,
                symbol: `diamond`,
                radius: 4,
                enabled: true,
            },
            annotation: {
                1: {
                    // Depth
                    fillColor: ANNOTATION_MARKER_COLOR,
                    symbol: `triangle`,
                    radius: 5,
                    enabled: true,
                },
                2: {
                    // Velocity
                    fillColor: ANNOTATION_MARKER_COLOR,
                    symbol: `circle`,
                    radius: 5,
                    enabled: true,
                },
                3: {
                    // Quantity or flow
                    fillColor: ANNOTATION_MARKER_COLOR,
                    symbol: `square`,
                    radius: 5,
                    enabled: true,
                },
                4: {
                    // Original (pre-edit) data
                    fillColor: ANNOTATION_MARKER_COLOR,
                    symbol: `circle`,
                    radius: 5,
                    enabled: true,
                },
            },
            flaggedAnnotation: {
                1: {
                    // Depth
                    fillColor: ANNOTATION_IGNORED_MARKER_COLOR,
                    symbol: `triangle`,
                    radius: 5,
                    enabled: true,
                },
                2: {
                    // Velocity
                    fillColor: ANNOTATION_IGNORED_MARKER_COLOR,
                    symbol: `circle`,
                    radius: 5,
                    enabled: true,
                },
                3: {
                    // Quantity or flow
                    fillColor: ANNOTATION_IGNORED_MARKER_COLOR,
                    symbol: `square`,
                    radius: 5,
                    enabled: true,
                }
            }
        };
    }

    public getEventsTheme(eventType: EventTypes): { color: string, eventSymbol: string} {
        const out = {
            color: '',
            eventSymbol: ''
        }
        switch(eventType) {
            default:
            case EventTypes.General: out.color = '#4e7e5b'; break;
            case EventTypes.Rain: out.color = '#00a8f0';  break;
            case EventTypes.Hydraulic: out.color = '#fdd93b';  break;
            case EventTypes.SiteCondition: out.color = '#7e5b4e';  break;
            case EventTypes.WorkOrder: out.color = '#1de9b6';  break;
            case EventTypes.CSO: out.color = '#fb8c00'; break;
        }

        switch(eventType) {
            default:
            case EventTypes.General: out.eventSymbol = 'url(../../assets/images/event_general.png)'; break;
            case EventTypes.Rain: out.eventSymbol = 'url(../../assets/images/event_rain.png)'; break;
            case EventTypes.Hydraulic: out.eventSymbol = 'url(../../assets/images/event_hydraulic.png)'; break;
            case EventTypes.SiteCondition: out.eventSymbol = 'url(../../assets/images/event_sitecondition.png)'; break;
            case EventTypes.WorkOrder: out.eventSymbol = 'url(../../assets/images/event_workorder.png)'; break;
            case EventTypes.CSO: out.eventSymbol = 'url(../../assets/images/event_cso.png)'; break;
        }

        return out;
    }

    public getXAxisIntervalFormat(min: number, max: number) {
        const oneHour = 60 * 60 * 1000; // in milliseconds
        const oneDay = oneHour * 24; // in milliseconds
        const xRange = max - min;

        let tickInterval = oneDay;
        let labelFormat = {};

        // Adjust how the x axis is defined based on time range displayed
        // Bracketed sections taken from previous instance of hydrograph
        if (xRange > oneDay * 90) {
            // Over 3 months
            tickInterval = oneDay * 30;
            labelFormat = { month: '%b' };
        } else if (xRange > oneDay * 27) {
            // Over 1 month
            tickInterval = oneDay * 7;
            labelFormat = { week: '%e. %b' };
        } else if (xRange > oneDay * 7) {
            // Over 1 week
            tickInterval = oneDay;
            labelFormat = { day: '%e. %b', hour: '%H:%M' };
        } else if (xRange > oneDay * 3.5) {
            tickInterval = oneDay;
            labelFormat = { day: '%e. %b', hour: '%H:%M' };
        } else if (xRange > oneDay * 2.75) {
            tickInterval = oneHour * 8;
            labelFormat = { day: '%e. %b', hour: '%H:%M' };
        } else if (xRange > oneDay * 1.75) {
            tickInterval = oneHour * 6;
            labelFormat = { day: '%e. %b', hour: '%H:%M' };
        } else if (xRange > oneDay * 1.25) {
            tickInterval = oneHour * 4;
            labelFormat = { day: '%e. %b', hour: '%H:%M' };
        } else if (xRange >= oneDay) {
            tickInterval = oneHour * 3;
            labelFormat = { day: '%e. %b', hour: '%H:%M' };
        } else if (xRange > oneHour * 14) {
            // Here down is all < 1 day
            tickInterval = oneHour * 2;
            labelFormat = { day: '%e. %b', hour: '%H:%M' };
        } else if (xRange > oneHour * 6.75) {
            tickInterval = oneHour;
            labelFormat = { day: '%e. %b', hour: '%H:%M' };
        } else if (xRange > oneHour * 3.5) {
            tickInterval = oneHour / 2;
            labelFormat = { day: '%e. %b', hour: '%H:%M', minute: '%H:%M' };
        } else {
            // xRange <= oneHour * 3.5
            tickInterval = oneHour / 4;
            labelFormat = { day: '%e. %b', hour: '%H:%M', minute: '%H:%M' };
        }

        return {
            interval: tickInterval,
            format: labelFormat,
        };
    }
    public getDataQualityColor(value: number) {
        return DATA_QUALITY_COLORS[this.getDataQualityIndex(value)];
    }

    public getDataQualityIndex(value: number) {
        // Choose color based on percentage
        // If no value is passed in, set percentage to 100 (we assume quality is good)
        // Value is range from 0-15
        const percentage = value ? (value * 100) / 15 : 100;

        if (percentage < 11) {
            //  0 - 10 %
            return 0;
        } else if (percentage < 21) {
            //  11 - 20 %
            return 1;
        } else if (percentage < 31) {
            //  21 - 30 %
            return 2;
        } else if (percentage < 41) {
            //  31 - 40 %
            return 3;
        } else if (percentage < 51) {
            //  41 - 50 %
            return 4;
        } else if (percentage < 61) {
            //  51 - 60 %
            return 5;
        } else if (percentage < 71) {
            //  61 - 70 %
            return 6;
        } else if (percentage < 81) {
            //  71 - 80 %
            return 7;
        } else if (percentage < 91) {
            //  81 - 90 %
            return 8;
        } else if (percentage < 101) {
            //  91 - 100 %
            return 9;
        }

        return 9;
    }

    entityColorsSubscription() {
        return this.entityColors$;
    }

    entityColorGet(entityId: number): string {
        const colors = this.entityColors$.getValue();
        if(!colors || !colors[entityId]) {
            return '#aeaeae';
        }

        return this.entityColorParse(entityId, colors[entityId]);
    }

    entityColorsSet(entityColors: {[key: number]: string}) {
        this.entityColors$.next(entityColors);
    }

    entityColorParse(entityId: number, color: string) {
        if(entityId < 0) {
            return '#eb5910';
        }
        return color;
    }
}
