<button
    id="customDateSelector"
    class="date-range-picker-button date-picker-hint"
    mat-button
    [disabled]="disabled"
    (menuClosed)="onCloseMenu()"
    [matMenuTriggerFor]="dateRangeMenu"
    (click)="openMenuEvent()"
    #trigger="matMenuTrigger"
    [ngClass]="buttonClasses"
    [tabindex]="autoFocus ? 0 : -1"
>
    <span
        *ngIf="!label"
        class="date-range-content"
    >{{ 'LOCATION_DASHBOARD.DATE_RANGE_SPAN' | translate }}</span>
    <span
        *ngIf="label"
        class="date-range-content"
    >{{ label | translate }}</span>
    <span class="date-range-hint date-value">
        {{ startDate | date: (dateRangeType === 'datetime' ? customerDateFormatLowerCase : dateFormat) }} -
        {{ endDate | date: (dateRangeType === 'datetime' ? customerDateFormatLowerCase : dateFormat) }}
    </span>
    <mat-icon>arrow_drop_down</mat-icon>
</button>
<mat-menu
    class="date-range-picker-menu customDateMenu"
    #dateRangeMenu="matMenu"
    xPosition="before"
    yPosition="below"
>
    <div class="custom-date-menu-containt customDateMenuContaint">
        <div
            class="date-area"
            *ngIf="!disableInputs"
            (click)="$event.stopPropagation()"
        >
            <div class="header">
                <span class="header__label">{{ 'LOCATION_DASHBOARD.CUSTOM_DATE' | translate }}</span> - {{ dateRangeType === 'datetime' ? customDateFormat : (dateFormat | lowercase) }}
            </div>
            <div>
                <mat-form-field
                    id="inputField"
                    class="full-width"
                >
                    <input
                        #startDateInput
                        matInput
                        name="startDate"
                        appDatetimepickerPosition
                        [matDatetimepickerComp]="startDatePicker"
                        placeholder="{{ 'COMMON.FROM' | translate }}"
                        required
                        (change)="onStartDateChange($event)"
                        [value]="
                            displayStartDate
                                | date: (dateRangeType === 'datetime' ? customerDateFormatLowerCase : dateFormat)
                        "
                        tabindex="-1"
                        class="datepickerStyles"
                        [min]="minDate"
                        [max]="maxDate"
                        [disabled]="disabled"
                    />
                    <input
                        matInput
                        hidden
                        name="startDate"
                        [(ngModel)]="displayStartDate"
                        [matDatetimepicker]="startDatePicker"
                        (dateChange)="onStartDateChange($event)"
                        required
                        tabindex="-1"
                        (dateInput)="calendarCheckStartDateChange($event)"
                        (dateChange)="calendarCheckStartDateChange($event)"
                        [min]="minDate"
                        [max]="maxDate"
                        [disabled]="disabled"
                    />
                    <mat-datetimepicker-toggle
                        matSuffix
                        [type]="'type'"
                        [for]="startDatePicker"
                        [disabled]="disabled"
                    > </mat-datetimepicker-toggle>
                    <mat-datetimepicker
                        #startDatePicker
                        timeInterval="1"
                        [type]="dateRangeType"
                        [min]="minDate"
                        [max]="maxDate"
                    >
                    </mat-datetimepicker>
                </mat-form-field>
            </div>
            <div>
                <mat-form-field
                    id="inputField"
                    class="full-width"
                >
                    <input
                        #endDateInput
                        matInput
                        placeholder="{{ 'COMMON.TO' | translate }}"
                        required
                        appDatetimepickerPosition
                        [matDatetimepickerComp]="endDatePicker"
                        (change)="onEndDateChange($event)"
                        tabindex="-1"
                        [value]="
                            displayEndDate
                                | date: (dateRangeType === 'datetime' ? customerDateFormatLowerCase : dateFormat)
                        "
                        [min]="minDate"
                        [max]="maxDate"
                        [disabled]="disabled"
                    />
                    <input
                        matInput
                        hidden
                        #endDateInput="ngModel"
                        name="endDate"
                        [(ngModel)]="displayEndDate"
                        [matDatetimepicker]="endDatePicker"
                        (dateChange)="onEndDateChange($event)"
                        [min]="minDate"
                        [max]="maxDate"
                        [disabled]="disabled"
                    />
                    <mat-datetimepicker-toggle
                        matSuffix
                        [for]="endDatePicker"
                        [disabled]="disabled"
                    > </mat-datetimepicker-toggle>
                    <mat-datetimepicker
                        #endDatePicker
                        timeInterval="1"
                        [type]="dateRangeType"
                        [min]="minDate"
                        [max]="maxDate"
                        [disabled]="disabled"
                    >
                    </mat-datetimepicker>
                </mat-form-field>
            </div>
            <div class="mat-error full-width">
                <div *ngIf="displayEndDateErrMsg && !invalidEndDate">
                    {{ 'COMMON.INVALID_TO_DATE_FORMAT' | translate }}
                </div>
                <div *ngIf="invalidEndDate">{{ 'COMMON.FROM_TO_DATE_ERROR' | translate }}</div>
                <div *ngIf="displayStartDateErrMsg">{{ 'COMMON.INVALID_FROM_DATE_FORMAT' | translate }}</div>
                <div *ngIf="invalidRange">{{ 'COMMON.INVALID_RANGE' | translate | replace: '%':maxDayRange }}</div>
                <div *ngIf="displayStartDateBoundaryErr">
                    {{ startDateBoundaryErrorMsg ? startDateBoundaryErrorMsg : ('COMMON.START_DATE_OUTSIDE_BOUNDARY' | translate) }}
                </div>
                <div *ngIf="displayEndDateBoundaryErr">
                    {{ endDateBoundaryErrorMsg ? endDateBoundaryErrorMsg : ('COMMON.END_DATE_OUTSIDE_BOUNDARY' | translate) }}
                </div>
            </div>
        </div>
        <div
            *ngIf="showQuickRanges"
            class="date-selector-area"
            (click)="showQuickRangesClick($event)"
        >
            <div class="header capitalize-text">{{ 'LOCATION_DASHBOARD.QUICK_RANGES' | translate }}</div>
            <div class="quick-range-button">
                <button
                    *ngIf="!startFromMonth && oneDayAvailable"
                    class="date-ranges"
                    mat-menu-item
                    (click)="getDataForSetTimeSpan(QUICK_DATE_RANGES.TODAY)"
                >
                    {{ 'LOCATION_DASHBOARD.ONE_DAY_BTN' | translate }}
                </button>
                <button
                    *ngIf="!startFromMonth && oneWeekAvailable"
                    class="date-ranges"
                    mat-menu-item
                    (click)="getDataForSetTimeSpan(QUICK_DATE_RANGES.LAST_WEEK)"
                >
                    {{ 'LOCATION_DASHBOARD.ONE_WEEK_BTN' | translate }}
                </button>
                <button
                    *ngIf="lastMonthAvailable"
                    class="date-ranges"
                    mat-menu-item
                    (click)="getDataForSetTimeSpan(QUICK_DATE_RANGES.PREVIOUS_MONTH)"
                >
                    {{ 'LOCATION_DASHBOARD.LAST_MONTH_BTN' | translate }}
                </button>
                <button
                    *ngIf="oneMonthAvailable"
                    class="date-ranges"
                    mat-menu-item
                    (click)="getDataForSetTimeSpan(QUICK_DATE_RANGES.LAST_MONTH)"
                >
                    {{ 'LOCATION_DASHBOARD.ONE_MONTH_BTN' | translate }}
                </button>
                <button
                    *ngIf="threeMonthsAvailable"
                    class="date-ranges"
                    mat-menu-item
                    [disabled]="disabled"
                    (click)="getDataForSetTimeSpan(QUICK_DATE_RANGES.LAST_THREE_MONTHS)"
                >
                    {{ 'LOCATION_DASHBOARD.THREE_MONTHS_BTN' | translate }}
                </button>
                <button
                    *ngIf="sixMonthsAvailable"
                    class="date-ranges"
                    mat-menu-item
                    (click)="getDataForSetTimeSpan(QUICK_DATE_RANGES.LAST_SIX_MONTHS)"
                >
                    {{ 'LOCATION_DASHBOARD.SIX_MONTHS_BTN' | translate }}
                </button>
                <button
                    *ngIf="oneYearAvailable"
                    class="date-ranges"
                    mat-menu-item
                    [disabled]="disabled"
                    (click)="getDataForSetTimeSpan(QUICK_DATE_RANGES.LAST_YEAR)"
                >
                    {{ 'LOCATION_DASHBOARD.ONE_YEAR_BTN' | translate }}
                </button>
            </div>
        </div>
    </div>
    <div
        class="range-text"
        [class.range-text--narrow]="!showQuickRanges"
        *ngIf="minimumDataDate && maximumDataDate && showAvailableDates"
    >
        <span class="range-text__label">{{ 'LOCATION_DASHBOARD.AVAILABLE_DATA_RANGE' | translate }}</span> {{ minimumDataDate | date: (dateRangeType === 'datetime' ? customerDateFormatLowerCase : dateFormat) }} - {{ maximumDataDate | date: (dateRangeType === 'datetime'
        ? customerDateFormatLowerCase : dateFormat) }}
    </div>
</mat-menu>
