import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AppQueryParams, customerLocationGroupQueryParam } from 'app/shared/models/customer';
import {
    CompositeLocationComponentModel,
    LocationDetails,
    LocationDetailsModel,
} from 'app/shared/models/location-details';
import { Locations } from 'app/shared/models/locations';
import { GISService } from 'app/shared/services/gis-service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { Subscription } from 'rxjs';

import { CompositeLocComponent } from '../composite-loc/composite-loc.component';
import { LocationCardService } from '../../location-card/services/location-card.service';

export interface DataElement {
    name: string;
    flow: string;
    locationID: number;
}

@Component({
    selector: 'app-marker-composite-loc-details',
    templateUrl: './marker-composite-loc-details.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarkerCompositeLocDetailsComponent implements OnInit {
    @Output() public closeMapCompositeLocationsPopup: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() public locationDetails: LocationDetails;
    @Input() public allLocations: Array<Locations>;
    @Input() public customerId: number;
    @Output() public compositeLocationsUpdated = new EventEmitter<LocationDetailsModel>();
    public locationGroupId: number;
    private subscriptions = new Array<Subscription>();

    // public dataSource: DataElement[] = [];

    public displayedColumns: string[] = ['name', 'flow', 'editLoc'];
    public locComponentDetails = new Array<CompositeLocationComponentModel>();
    public dataSource: MatTableDataSource<DataElement>;
    constructor(
        public dialog: MatDialog,
        private changeDetectorRef: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private gisService: GISService,
        private locationCardService: LocationCardService
    ) {}

    public ngOnInit() {
        //  subscribe to customer and location group changes
        const activatedRouteSubscription = this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            this.locationGroupId = Number(params.get(customerLocationGroupQueryParam));
        });
        this.subscriptions.push(activatedRouteSubscription);
    }

    public emitCloseMapCompositeLocationsPopup(value: boolean) {
        this.closeMapCompositeLocationsPopup.emit(false);
    }

    // tslint:disable-next-line:use-life-cycle-interface
    public ngOnChanges(changes: SimpleChanges) {
        this.dataSource = new MatTableDataSource<DataElement>();
        this.dataSource.filteredData.length = 0;

        this.locComponentDetails = this.locationDetails.components;
        this.locComponentDetails.forEach((entity: CompositeLocationComponentModel, index) => {
            const flowName = this.getFlowName(entity.operator);
            const location = this.allLocations.find((x) => x.locationId === entity.locationID);
            if (location) {
                const locationName = location.locationName;

                this.dataSource.filteredData.push({
                    name: locationName,
                    flow: flowName,
                    locationID: entity.locationID,
                });
            }
        });
        this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
    }

    public getFlowName(operator: number) {
        let flowType = '';
        switch (operator) {
            case 0:
                flowType = 'none';
                break;
            case 1:
                flowType = 'Add';
                break;
            case 2:
                flowType = 'Subtract';
                break;
            default:
                flowType = 'none';
        }
        return flowType;
    }

    public openLocationEditor(evt) {
        const dialogOptions: MatDialogConfig = {
            disableClose: true,
            data: [this.locationDetails, this.allLocations, this.customerId],
            hasBackdrop: false,
        };

        if (this.gisService.locationCardPosition) {
            dialogOptions.position = this.locationCardService.checkLocationCardPosition(false, this.gisService.locationCardPosition);
        }
        this.dialog
            .open(CompositeLocComponent, dialogOptions)
            .afterClosed()
            .subscribe((res) => {
                // if user add new location successfully, then refresh map.
                if (res && res.success) {
                    this.compositeLocationsUpdated.emit(<LocationDetailsModel>{
                        locationDetails: this.locationDetails,
                    });
                }
            });
    }

    public editLocation(location: CompositeLocationComponentModel) {
        const parameters = <AppQueryParams>{
            c: this.customerId,
            lid: location.locationID,
            lg: this.locationGroupId,
        };
        this.router.navigate(['/pages/viewLocationDetails'], {
            queryParams: parameters,
        });
    }
}
