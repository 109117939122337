export class MathUtils {
    /**
     * Use Haversine formula to determine the distance between two lat/long points
     * See https://en.wikipedia.org/wiki/Haversine_formula and
     * https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula
     * @param lat1
     * @param lon1
     * @param lat2
     * @param lon2
     */
    public static getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number): number {
        const R = 6371; // Radius of the earth in km
        const dLat = MathUtils.deg2rad(lat2 - lat1); // deg2rad below
        const dLon = MathUtils.deg2rad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(MathUtils.deg2rad(lat1)) *
                Math.cos(MathUtils.deg2rad(lat2)) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c; // Distance in km
        return d;
    }

    /**
     * Convert degrees into radians
     * @param deg
     */
    public static deg2rad(deg: number): number {
        return deg * (Math.PI / 180);
    }

    /**
     * Math.min and Math.max has limitations. (stack size exceed on Math.min function arguments) happens when array is too large.
     */
    public static getMathMaxMin(data: number[], isMin = true) {
        let value = isMin ? Number.MAX_SAFE_INTEGER : 0;

        for (const i of data) {
            if ((isMin && i < value) || (!isMin && i > value)) {
                value = i;
            }
        }

        return value;
    }
    public static getMin(data: number[]) {
        return MathUtils.getMathMaxMin(data, true);
    }
    public static getMax(data: number[]) {
        return MathUtils.getMathMaxMin(data, false);
    }


    // checks if numbers are equal by rounding to common decimal
    // also covers the case when difference is 0.01 because of rounding
    public static compareWithMinimalDecimal(a: number, b: number, decimals: number = 3) {
        if ((a === undefined || a === null) || (b === undefined || b === null)) {
            return true;
        }

        return Math.abs(a - b) < 0.01;
    }
}
