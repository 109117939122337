<div *ngIf="showCustomRanges" class="hydrographScaleOptions">
    <form #customRangeForm="ngForm">
        <div *ngFor="let opt of customRanges; let i = index">
            <div class="manualRanges">
                <div class="opt-label">{{ opt.name }}</div>
                <div class="ranges">
                    <mat-form-field>
                        <input
                            matInput
                            type="number"
                            placeholder="{{ translations.MIN_SCALE_PLACEHOLDER }}"
                            name="{{ opt.name }}_min"
                            [(ngModel)]="opt.min"
                            [disabled]="opt.autoOpt === true"
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <input
                            matInput
                            type="number"
                            placeholder="{{ translations.MAX_SCALE_PLACEHOLDER }}"
                            name="{{ opt.name }}_max"
                            [(ngModel)]="opt.max"
                            [disabled]="opt.autoOpt === true"
                        />
                    </mat-form-field>
                    <!-- Blank div whether or not auto slider exists to maintain spacing -->
                    <div class="slider">
                        <mat-slide-toggle
                            *ngIf="opt.autoOpt === true || opt.autoOpt === false"
                            (change)="changeAutoOptSlider(opt.dataGroupId, i, $event)"
                            [checked]="opt.autoOpt"
                        >
                        {{ translations.RAIN_AUTO_TEXT }}
                        </mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="mat-error" *ngIf="!validateCustomRanges(opt)">
                {{ translations.INVALID_SCALE }}
            </div>
        </div>

        <div class="buttonRow">
            <button mat-button (click)="uiReset()">Reset</button>
            <button mat-button (click)="uiApply()" [disabled]="!validateCustomRanges()">Apply</button>
        </div>
    </form>
</div>
