import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Inject,
    ViewChild,
    ChangeDetectorRef,
    ElementRef,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { CompositeLocationService } from 'app/shared/services/composite-location.service';
import { Subscription } from 'rxjs';
import { CompositeLocationItem } from 'app/shared/models/CompositeLocationItem';
import { Observable } from 'rxjs';
import { LocationArgs, Locations, LocationStatus } from 'app/shared/models/locations';
import { Selectable } from 'app/shared/models/selectable';
import { FormControl, NgForm } from '@angular/forms';
import { CompositeLocationComponentModel } from 'app/shared/models/location-details';
import { UsersService } from 'app/pages/admin/users.service';
import { StatusCodeService } from '../../../../shared/services/status-code.service';
import { TranslateService } from '@ngx-translate/core';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { GISService } from 'app/shared/services/gis-service';
import { first } from 'rxjs/operators';
import { gisUserSettings } from 'app/shared/models/gis-service-list';
import sortBy from 'lodash/sortBy';
import { UiService } from 'app/shared/services/ui.service';
import { LocationCardService } from '../../location-card/services/location-card.service';

@Component({
    selector: 'app-composite-loc',
    templateUrl: './composite-loc.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompositeLocComponent implements OnInit {
    public addCompositeLocationLoadingState: boolean;
    /**
     * Reg Ex. Pattern for latitude
     */
    public latitudePattern = REGEX_CONFIG.latitudePattern;

    /**
     * Reg Ex. Pattern for longitude
     */
    public longitudePattern = REGEX_CONFIG.longitudePattern;

    /**
     * Reg Ex. Pattern for numeric Decimal value 2Place
     */
    public alphaNumericWitSpecialSymbols_Pattern = REGEX_CONFIG.alphaNumericWitSpecialSymbols_Pattern;

    /**
     * Reg Ex. Pattern for alpha numeric
     */
    public alphaNumericPattern = REGEX_CONFIG.alphaNumericPattern;

    public maxScaleValuePattern = REGEX_CONFIG.numericDecimal2PlacePattern;
    private subscriptions = new Array<Subscription>();

    public isValidLocationName = true;

    public activeLocation = true;

    public customerId: number;
    public editLocationId: number;

    public compositeLocationName: string;
    public compositeLocationAddress: string;
    public compositeLocationAddressLatitude = 0;
    public compositeLocationAddressLongitude = 0;

    public locationNameLoc = new Array<string>();
    public locationId = new Array<number>();

    public flowLoc = new Array<string>();
    public flowId = new Array<number>();

    public flowStringArray = new Array<string>();

    public multiplierLoc = new Array<string>();
    public locationNameLoc2: number;
    public flowLoc2: string;
    public multiplierLoc2: number;

    public locArray = new Array<CompositeLocationItem>();
    public filteredLocations: Observable<Locations[]>;
    public selectableLocations: Array<Selectable>;
    public flowTypes = new Array<Selectable>();
    /**
     * input value for Number of Entries
     */
    public numberOfEntries: number;

    /**
     * array to loop input values for depth and quantity depending upon numberOfEntries
     */
    public numberOfTimes: Array<number>;

    public locationControl: FormControl = new FormControl();

    public allLocations = new Array<Locations>();
    @ViewChild('FlowDirection') public flowDirectionId;
    @ViewChild('dialog') dialogDiv: ElementRef<HTMLDivElement>;
    public isCompositeLocationCountInvalid: boolean;
    public isLocationAdded = new Array<boolean>();
    public isLocationSearched = new Array<boolean>();

    public isDuplicateLocationSelected: boolean;
    public isValidLocationSelected: boolean;
    public isFirstLocationSelected: boolean;
    public locationName = new Array<string>();
    public isLoadingState: boolean;
    public locationLabel: string;
    public locationTitle: string;
    public installationValuePattern = REGEX_CONFIG.installationValuePattern;
    public editedLocation: number;
    @ViewChild('addCompositeLocationForm') public compositeForm: NgForm;
    public addNewCompositeLocation: string;
    public compositeLocationLabel: string;
    public allLocationsList = new Array<Locations>();

    public oldLocationName: string;
    public enableSelectOnMap: false;
    public invalidMultiplier = false;

    constructor(
        public dialogRef: MatDialogRef<CompositeLocComponent>,
        public compositeLocationService: CompositeLocationService,
        private statusCodeService: StatusCodeService,
        private usersService: UsersService,
        @Inject(MAT_DIALOG_DATA) public locationData: any,
        public snackBar: MatSnackBar,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private gisService: GISService,
        private uiService: UiService,
        private locationCardService: LocationCardService
    ) {
        translate.get('HOME.MAP.COMPOSITE_LOCATION.TITLE').subscribe((res: string) => {
            this.addNewCompositeLocation = res;
        });
        translate.get('HOME.MAP.LEGEND.MAP_COMPOSITE_LOCATION').subscribe((res: string) => {
            this.compositeLocationLabel = res;
        });
    }

    public ngOnInit() {
        this.dialogRef.afterOpened().subscribe(() => {this.uiService.disable()});
        this.dialogRef.afterClosed().subscribe(() => {this.uiService.enable()});

        this.translate.get('HOME.MAP.COMPOSITE_LOCATION.TITLE').subscribe((res) => {
            this.locationTitle = res;
        });
        if (this.locationData && typeof this.locationData === 'number') {
            this.customerId = this.locationData;
        } else if (this.locationData && this.locationData.customerId) {
            this.customerId = this.locationData.customerId;
            this.enableSelectOnMap = this.locationData.enableSelectOnMap;
            this.locationData = this.customerId;
        } else if (this.locationData) {
            this.customerId = this.locationData[2];
            this.enableSelectOnMap = this.locationData[0].enableSelectOnMap;
        }

        // initialize location parameter
        const locationArgs = <LocationArgs>{
            customerId: this.customerId,
            locationGroupId: 0,
        };
        this.numberOfEntries = 2;
        this.numberOfTimes = Array(+this.numberOfEntries).fill(0);
        this.flowLoc[0] = 'Add';
        this.flowId[0] = 1;
        this.flowLoc[1] = 'Add';
        this.flowId[1] = 1;
        this.flowStringArray[0] = 'Add';
        this.flowStringArray[1] = 'Add';

        this.isCompositeLocationCountInvalid = false;
        this.isLocationAdded[0] = false;
        this.isLocationAdded[1] = false;
        this.isLocationSearched[0] = true;
        this.isLocationSearched[1] = true;
        this.isLoadingState = false;
        this.multiplierLoc[0] = Number(1).toFixed(1);
        this.multiplierLoc[1] = Number(1).toFixed(1);

        this.isDuplicateLocationSelected = false;
        this.isValidLocationSelected = false;
        this.isFirstLocationSelected = false;

        const activeInactiveHandler = this.statusCodeService.activeInactiveHandler.getValue();

        const locationsObservableService = this.usersService.getLocationsList(locationArgs).subscribe(
            (result: Array<Locations>) => {
                if (result) {
                    if (activeInactiveHandler) {
                        this.setLocationsOnTemplate(result.filter((x) => x.viewable));
                    } else {
                        this.setLocationsOnTemplate(
                            result.filter(
                                (x) =>
                                    x.viewable &&
                                    (x.status === LocationStatus.Active || x.status === LocationStatus.Maintenance),
                            ),
                        );
                    }
                    this.allLocationsList = result; // #11146 created new list since this.alllocations is filtered and contains partial locations
                }
            },
            (error) => {
                // Empty block
            },
        );

        this.subscriptions.push(locationsObservableService);

        this.flowTypes = [
            {
                id: 1,
                name: 'ADD',
                isChecked: false,
            },
            {
                id: 2,
                name: 'SUBTRACT',
                isChecked: false,
            },
        ];
        if (this.locationData && typeof this.locationData === 'number') {
            this.customerId = this.locationData;
            this.locationLabel = this.addNewCompositeLocation;
        } else if (this.locationData) {
            this.locationLabel = this.compositeLocationLabel;
            const locationDetail = this.locationData[0];
            const allLocations = this.locationData[1];
            this.isValidLocationSelected = true;
            this.isFirstLocationSelected = true;
            this.compositeLocationName = locationDetail.locationName;
            this.oldLocationName = this.compositeLocationName;
            this.compositeLocationAddress = locationDetail.description;
            this.editLocationId = locationDetail.locationID;
            if (locationDetail.coordinate) {
                this.compositeLocationAddressLatitude =
                    locationDetail.coordinate.latitude === 0 ? undefined : locationDetail.coordinate.latitude;
                this.compositeLocationAddressLongitude =
                    locationDetail.coordinate.longitude === 0 ? undefined : locationDetail.coordinate.longitude;
            }
            this.activeLocation =
                locationDetail.status === LocationStatus.Active || locationDetail.status === LocationStatus.Maintenance;
            this.numberOfEntries = locationDetail.components.length;
            this.numberOfTimes = Array(+this.numberOfEntries).fill(0);
            locationDetail.components.forEach((entity: CompositeLocationComponentModel, index) => {
                const location = allLocations.find((x) => x.locationId === entity.locationID);
                if (location) {
                    this.locationNameLoc[index] = location.locationName;
                    this.locationId[index] = location.locationId;
                    this.locationName[index] = location.locationName + '1';
                }
                this.flowLoc[index] = this.getFlowName(entity.operator);
                this.flowId[index] = entity.operator;
                this.flowStringArray[index] = this.getFlowName(entity.operator);

                this.multiplierLoc[index] = String(entity.multiplier);
                this.isLocationSearched[index] = true;
            });
        }
    }

    public setLocationsOnTemplate(locations: Array<Locations>) {
        this.allLocations = locations.filter(
            (x) => x.series !== 'RainAlert II' && x.series !== 'RainAlert III' && x.locationType !== 3,
        );
        this.selectableLocations = sortBy(this.allLocations, (x) => x.locationName).map(
            (loc: Locations) => <Selectable>{ id: loc.locationId, name: loc.locationName },
        );
        this.selectableLocations = sortBy(this.selectableLocations, (x) => x.name);
        if (this.locationData && typeof this.locationData === 'number') {
            // this.locationNameLoc[0] = this.selectableLocations[0].name;
            // this.locationId[0] = this.selectableLocations[0].id;
        }
    }

    public displayWith(value: any): string {
        return typeof value === 'string' ? value : value == null ? '' : value.name;
    }

    public changeLocationDropdown(selectables: any, index: number) {
        // ensure something is selected
        if (!selectables) {
            const filteredLocations = this.allLocations.filter((item) =>
                item.locationName.toLowerCase().startsWith(selectables),
            );
            this.selectableLocations = sortBy(filteredLocations, (x) => x.locationName).map(
                (loc: Locations) => <Selectable>{ id: loc.locationId, name: loc.locationName },
            );
            this.isLocationSearched[index] = true;
            this.isLocationAdded[index] = false;
            return;
        }

        if (typeof selectables === 'string') {
            this.isValidLocationSelected = false;

            const filteredLocations = this.allLocations.filter((item) =>
                item.locationName.toLowerCase().startsWith(selectables.toLowerCase()),
            );
            this.selectableLocations = sortBy(filteredLocations, (x) => x.locationName).map(
                (loc: Locations) => <Selectable>{ id: loc.locationId, name: loc.locationName },
            );
            const searchedLocations = this.allLocations.filter(
                (item) => item.locationName.toLowerCase().valueOf() === selectables.toLowerCase().valueOf(),
            );

            if (searchedLocations && searchedLocations.length > 0) {
                this.isLocationSearched[index] = true;
                this.isDuplicateLocationSelected = false;
            } else {
                this.isLocationSearched[index] = false;
                this.isLocationAdded[index] = false;
                this.isDuplicateLocationSelected = true;
                this.locationId[index] = -1;
                this.isValidLocationSelected = false;
            }
            // if selected location is different than already exist.
        } else if (this.locationId[index] !== selectables.id) {
            let locationIdToRemove = -1;
            // remove validation in case similar location is deleted
            if (!this.isLocationAdded[index]) {
                locationIdToRemove = this.locationId[index];
            }
            // if location is already added
            if (this.locationId.indexOf(selectables.id) > -1) {
                this.isLocationAdded[index] = true;
                this.isDuplicateLocationSelected = true;
                this.isLocationSearched[index] = true;
            } else {
                this.isLocationAdded[index] = false;
                this.isDuplicateLocationSelected = false;
                this.isLocationSearched[index] = true;
                this.locationNameLoc[index] = selectables.name;
            }

            // reset validation for location with similar id.
            if (locationIdToRemove >= 0 && this.locationId.indexOf(locationIdToRemove) !== index) {
                this.isLocationAdded[this.locationId.indexOf(locationIdToRemove)] = false;
            } else if (locationIdToRemove >= 0) {
                this.isLocationAdded[
                    this.locationId.indexOf(locationIdToRemove, this.locationId.indexOf(locationIdToRemove) + 1)
                ] = false;
            }

            this.locationId[index] = selectables.id;
            this.locationName[index] = selectables.name + '1';
            if (this.isFirstLocationSelected) {
                if (this.locationId.indexOf(-1) < 0) {
                    this.isValidLocationSelected = true;
                }
            } else {
                this.isFirstLocationSelected = true;
            }

            if (this.isLocationAdded.indexOf(true) > -1) {
                this.isDuplicateLocationSelected = true;
            } else {
                this.isDuplicateLocationSelected = false;
            }
        } else {
            //  this.isLocationAdded[index] = false;
            // this.isDuplicateLocationSelected = false;
            this.isLocationSearched[index] = true;
        }
    }

    public changeFlowDropdown(selectables: number, index: number) {
        // ensure something is selected
        if (!selectables) {
            return;
        }

        if (selectables === 1) {
            this.flowLoc[index] = 'Add';
            this.flowId[index] = 1;
            this.flowStringArray[index] = 'Add';
        } else if (selectables === 2) {
            this.flowLoc[index] = 'Subtract';
            this.flowId[index] = 2;
            this.flowStringArray[index] = 'Subtract';
        }
    }

    public emitAddCompositeLocation(value: boolean) {
        this.dialogRef.close({ success: value });
    }

    public validateFormMultiplierValues() {
        const multiplierKeys = Object.keys(this.compositeForm.controls).filter(v => v.includes('multiplierLoc'));
        this.invalidMultiplier = multiplierKeys.some(v => {
            const value = Number(this.compositeForm.controls[v].value);

            return value <= 0 || value > 25.99;
        });
    }

    public addCompositeLocation() {
        this.isCompositeLocationCountInvalid = this.numberOfEntries > 1 ? false : true;
        if (this.isCompositeLocationCountInvalid) {
            return;
        }
        this.isLoadingState = true;

        this.numberOfTimes.forEach((count: number, index) => {
            const location = new CompositeLocationItem();
            location.locationID = Number(this.locationId[index]);
            location.operator = this.flowStringArray[index];
            location.multiplier = Number(this.multiplierLoc[index]);
            this.locArray.push(location);
        });

        this.compositeLocationAddressLatitude =
            this.compositeLocationAddressLatitude !== undefined
                ? Number(this.compositeLocationAddressLatitude)
                : this.compositeLocationAddressLatitude;

        this.compositeLocationAddressLongitude =
            this.compositeLocationAddressLongitude !== undefined
                ? Number(this.compositeLocationAddressLongitude)
                : this.compositeLocationAddressLongitude;

        if (this.locationData && typeof this.locationData === 'number') {
            const createCompositeLocSubscription = this.compositeLocationService
                .createCompositeLocation(
                    this.customerId,
                    this.compositeLocationName,
                    this.compositeLocationAddress,
                    this.compositeLocationAddressLatitude,
                    this.compositeLocationAddressLongitude,
                    this.activeLocation ? LocationStatus.Active : LocationStatus.Inactive,
                    JSON.parse(JSON.stringify(this.locArray)),
                )
                .subscribe(
                    (response) => {
                        if (response) {
                            this.isLoadingState = false;
                            this.snackBar.open('Location created Successfully.', 'Dismiss', {
                                duration: 10000,
                            });
                            this.dialogRef.close({ success: true });
                        }
                    },
                    (error) => {
                        this.isLoadingState = false;

                        this.dialogRef.close({ success: false });
                        if (error.status === 500) {
                            this.snackBar.open('Failed to create location.', 'Dismiss');
                        }
                    },
                );

            this.subscriptions.push(createCompositeLocSubscription);
        } else if (this.locationData) {
            const updateCompositeLocSubscription = this.compositeLocationService
                .updateCompositeLocation(
                    this.customerId,
                    this.compositeLocationName,
                    this.compositeLocationAddress,
                    Number(this.compositeLocationAddressLatitude),
                    Number(this.compositeLocationAddressLongitude),
                    this.activeLocation ? LocationStatus.Active : LocationStatus.Inactive,
                    this.editLocationId,
                    JSON.parse(JSON.stringify(this.locArray)),
                )
                .subscribe(
                    (response) => {
                        this.isLoadingState = false;

                        this.snackBar.open('Location updated Successfully.', 'Dismiss', {
                            duration: 10000,
                        });
                        this.dialogRef.close({ success: true });
                    },
                    (error) => {
                        this.isLoadingState = false;

                        this.dialogRef.close({ success: false });
                        if (error.status === 500) {
                            this.snackBar.open('Failed to update location.', 'Dismiss');
                        }
                    },
                );

            this.subscriptions.push(updateCompositeLocSubscription);
        }
    }

    public validateDuplicateLocationName(locationName: string) {
        // #11146 added validation method to check duplication
        if (locationName && this.allLocationsList) {
            locationName = locationName.trim();
            if (
                this.allLocationsList.find(
                    (location: Locations) => location.locationName.toLowerCase() === locationName.toLowerCase(),
                ) &&
                locationName !== this.oldLocationName
            ) {
                this.isValidLocationName = false;
            } else {
                this.isValidLocationName = true;
            }
        }
    }

    public addMoreLocation() {
        const filteredLocations = this.allLocations;
        this.selectableLocations = sortBy(filteredLocations, (x) => x.locationName).map(
            (loc: Locations) => <Selectable>{ id: loc.locationId, name: loc.locationName },
        );

        this.flowId[this.numberOfEntries] = 1;
        this.flowLoc[this.numberOfEntries] = 'Add';
        this.flowStringArray[this.numberOfEntries] = 'Add';
        this.multiplierLoc[this.numberOfEntries] = Number(1).toFixed(1);

        this.isLocationAdded[this.numberOfEntries] = false;
        this.isLocationSearched[this.numberOfEntries] = true;

        this.numberOfEntries++;
        this.numberOfTimes = Array(+this.numberOfEntries).fill(0);
        this.isValidLocationSelected = false;
        if (this.isCompositeLocationCountInvalid) {
            this.isCompositeLocationCountInvalid = this.numberOfEntries > 1 ? false : true;
        }
    }

    public remveLocation(index: number) {
        let locationIdToRemove = -1;
        // remove validation in case similar location is deleted
        if (!this.isLocationAdded[index]) {
            locationIdToRemove = this.locationId[index];
        }

        this.locationNameLoc.splice(index, 1);
        this.locationId.splice(index, 1);
        this.locationName.splice(index, 1);

        this.flowLoc.splice(index, 1);
        this.flowId.splice(index, 1);

        this.flowStringArray.splice(index, 1);

        this.multiplierLoc.splice(index, 1);

        this.isLocationAdded.splice(index, 1);
        this.isLocationSearched.splice(index, 1);

        // delete entry from list.
        this.numberOfEntries--;
        this.numberOfTimes = Array(this.numberOfEntries).fill(0);

        // if (this.isLocationAdded[index]) {
        //   this.isLocationAdded[index] = false;
        // }

        // reset validation for location with similar id.
        if (locationIdToRemove >= 0) {
            this.isLocationAdded[this.locationId.indexOf(locationIdToRemove)] = false;
        }
        // set valaidation true in case all locations are valid.
        if (this.locationId.indexOf(-1) < 0) {
            this.isValidLocationSelected = true;
            //  this.isDuplicateLocationSelected = false;
        }

        // set duplicate validarion on basis of array of boolean for locations added.
        if (this.isLocationAdded.indexOf(true) > 0) {
            this.isDuplicateLocationSelected = true;
        } else {
            this.isDuplicateLocationSelected = false;
        }

        // mark the change detection.
        this.uiUtilsService.safeChangeDetection(this.cdr);
        this.cdr.markForCheck();

        this.compositeForm.form.markAsDirty();
    }

    public onMoveIconClick() {
        this.uiService.enableMap();
        this.gisService.coordinatePickerFromMap.pipe(first()).subscribe((data: { lat: number; lon: number }) => {
            this.compositeLocationAddressLatitude = Number(data.lat.toFixed(4));
            this.compositeLocationAddressLongitude = Number(data.lon.toFixed(4));

            this.compositeForm.controls['Latitude'].markAsDirty();
            this.compositeForm.controls['Longitude'].markAsDirty();

            this.uiService.disableMap();
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
    }

    public async dragEvent() {
        const { x: locationPositionX, y: locationPositionY } = this.dialogDiv.nativeElement.getClientRects()[0] as any;

        const currentSettings = await this.gisService.gisUserSettingsGet();
        this.gisService
            .updateGisUserSettings({ ...currentSettings, locationPositionX, locationPositionY })
            .subscribe(() => {
                this.gisService.locationCardPosition = this.locationCardService.getLocationCardPosition(false, { locationPositionX, locationPositionY });
            });
    }

    public getFlowName(operator: number) {
        let flowType = '';
        switch (operator) {
            case 0:
                flowType = 'none';
                break;
            case 1:
                flowType = 'Add';
                break;
            case 2:
                flowType = 'Subtract';
                break;
            default:
                flowType = 'none';
        }
        return flowType;
    }
}
