import { Component, OnInit, ViewEncapsulation, Input, OnDestroy } from '@angular/core';
import { AbstractControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'app/pages/admin/users.service';
import { TritonConfiguration } from 'app/shared/models/monitorsetting';
import { Config } from 'app/shared/services/config';
import { Subject, Subscription } from 'rxjs';
import { MonitorSeriesNames } from '../../location-card.constants';
import { SampleRateElement } from '../../models/location-card.model';

@Component({
    selector: 'app-triton-data-delivery',
    templateUrl: './triton-data-delivery.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})
export class TritonDataDeliveryComponent implements OnInit, OnDestroy {
    @Input() form: FormGroup;
    @Input() monitorConfigs: TritonConfiguration;
    @Input() monitorSeriesChange: Subject<string>;

    public isMonitorEditor = false;
    public normalDeliveryFrequencyList: Array<SampleRateElement> = [];
    public fastDeliveryList = [];

    private subscriptions: Subscription[] = [];
    constructor(private translateService: TranslateService, private usersService: UsersService) {}

    ngOnInit() {
        this.isMonitorEditor = this.usersService.isMonitorEditor.getValue();
        const offStaticText = this.translateService.instant('COMMON.OFF_TEXT');
        const minutesStaticText = this.translateService.instant('CALCULATED_ENTITY_DIALOG.MINUTES');
        const hoursStaticText = this.translateService.instant('CALCULATED_ENTITY_DIALOG.HOURS');

        if (this.monitorConfigs) {
            this.form.patchValue({ ...this.monitorConfigs });
        }

        this.setValidators();

        this.subscriptions.push(
            this.monitorSeriesChange.subscribe((series: string) => {
                if (series?.toUpperCase() === MonitorSeriesNames.ForeSITE_UL || series?.toUpperCase() === MonitorSeriesNames.ForeSITE_Flex) {
                    this.normalDeliveryFrequencyList = [
                        { id: 4, text: `1 ${hoursStaticText}`, value: 1 },
                        { id: 5, text: `2 ${hoursStaticText}`, value: 2 },
                        { id: 6, text: `3 ${hoursStaticText}`, value: 3 },
                        { id: 7, text: `4 ${hoursStaticText}`, value: 4 },
                        { id: 8, text: `6 ${hoursStaticText}`, value: 6 },
                        { id: 9, text: `8 ${hoursStaticText}`, value: 8 },
                        { id: 10, text: `12 ${hoursStaticText}`, value: 12 },
                        { id: 11, text: `24 ${hoursStaticText}`, value: 24 },
                    ];

                    this.fastDeliveryList = [
                        { id: 1, text: offStaticText, value: false },
                        { id: 3, text: `15 ${minutesStaticText}`, value: true },
                    ];
                } else {
                    this.normalDeliveryFrequencyList = [
                        { id: 1, text: offStaticText, value: 0 },
                        { id: 2, text: `5 ${minutesStaticText}`, value: 300 },
                        { id: 3, text: `15 ${minutesStaticText}`, value: 900 },
                        { id: 4, text: `1 ${hoursStaticText}`, value: 3600 },
                        { id: 5, text: `2 ${hoursStaticText}`, value: 7200 },
                        { id: 6, text: `3 ${hoursStaticText}`, value: 10800 },
                        { id: 7, text: `4 ${hoursStaticText}`, value: 14400 },
                        { id: 8, text: `6 ${hoursStaticText}`, value: 21600 },
                        { id: 9, text: `8 ${hoursStaticText}`, value: 28800 },
                        { id: 10, text: `12 ${hoursStaticText}`, value: 43200 },
                        { id: 11, text: `24 ${hoursStaticText}`, value: 86400 },
                    ];
                }

                setTimeout(() => {
                    if (this.monitorConfigs) {
                        this.form.patchValue({ ...this.monitorConfigs });
                    }
                }, 0);
            })
        )
    }

    private setValidators() {
        const ipControl = this.form.get('prismdeliveryipaddress');
        const fast = this.form.get('prismdeliveryfast');
        const normal = this.form.get('prismdeliverynormal');

        if (ipControl) {
            ipControl.setValidators([Validators.required, Validators.maxLength(100), this.prismDeliveryValidator.bind(this)]);
        }

        if (fast) {
            fast.setValidators([Validators.required, this.dataDeliveryValidator.bind(this)]);
        }

        if (normal) {
            normal.setValidators([Validators.required, this.dataDeliveryValidator.bind(this)]);
        }

    }

    private prismDeliveryValidator(control: AbstractControl) {
      if (!control || !control.value) return null;

      const ipPattern = new RegExp('^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$');
      const dnsPattern = new RegExp('^(([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9\-]*[a-z0-9])(:[0-9]+)?$');

      if (!ipPattern.test(control.value) && !dnsPattern.test(control.value)) {
        return { pattern: true };
      }

      return null;

    }

    public resetRates(isFastControl: boolean) {
        if(isFastControl) {
            const sampleControl = this.form.get('prismdeliverynormal');
            sampleControl.reset(sampleControl.value);
        } else {
            const fastControl = this.form.get('prismdeliveryfast');
            fastControl.reset(fastControl.value);
        }
    }

    private dataDeliveryValidator(): {[key: string]: boolean} | null {
        const fastControl = this.form.get('prismdeliveryfast');
        const sampleControl = this.form.get('prismdeliverynormal');

        if (fastControl.value === null || sampleControl.value === null
            // OFF values
            || fastControl.value === 0 || sampleControl.value === 0) {
          return null;
        }

        if (fastControl.value > sampleControl.value) {
          return { rateError: true };
        }

        return null;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(v => v.unsubscribe());
    }
}
