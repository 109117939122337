import {
    Component,
    OnInit,
    ChangeDetectorRef,
    Input,
    ViewChild,
    OnChanges,
    SimpleChanges,
    OnDestroy,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { SliicerCaseStudy } from 'app/shared/models/sliicer';
import { MonitorName } from 'app/shared/models/sliicer-data';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { SliicerStudyDailogComponent } from '../sliicer-study-dialog-component/sliicer-study-dialog.component';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { AppQueryParams, caseStudyQueryParam, customerQueryParam } from 'app/shared/models/customer';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap, skip, first } from 'rxjs/operators';
import { AddNewStudyDialogData } from 'app/shared/models/sliicer/case-study';
import { USER_ROLES, UsersService } from 'app/pages/admin/users.service';


enum ColumnNames {
    StudyDate = 'StudyDate',
    StudyLocations = 'StudyLocations'
}

const CLAIM_STUDY_COL_NAME = 'claimStudy';

@Component({
    selector: 'ads-sliicer-study',
    templateUrl: './sliicer-study-dashboard.component.html',
    styleUrls: ['./sliicer-study-dashboard.component.scss'],
})
export class SliicerStudyDashboardComponent implements OnInit, OnChanges, OnDestroy {
    @Input('customerId') public customerId: number;
    @Input('isBetaFlag') public isBetaFlag: boolean;
    @ViewChild(MatPaginator) public caseStudyPaginator: MatPaginator;
    @ViewChild(MatSort) public caseStudySort: MatSort;

    public caseStudyDataChange = new BehaviorSubject([]);
    public initialCaseStudyData: BehaviorSubject<SliicerCaseStudy[]> = new BehaviorSubject<SliicerCaseStudy[]>([]);
    private subscriptions = new Array<Subscription>();

    public caseStudyDescriptionColumns = [
        'name',
        'Description',
        'Author',
        ColumnNames.StudyLocations,
        ColumnNames.StudyDate,
        'modifiedDate',
        'daysInStudy',
        'cloneStudy',
        'deleteStudy',
    ];

    public caseStudyNameColumns = [
        'name',
        'description',
        'createdBy',
        ColumnNames.StudyLocations,
        ColumnNames.StudyDate,
        'lastModified',
        'daysInStudy',
        'cloneStudy',
        'deleteStudy',
    ];

    // data source for the Material table
    public caseStudyDataSource;

    public showStudyDashboard: boolean;
    public isLoadingState: boolean;
    public totalPaginationLength = 0;
    public caseStudySearchString = new FormControl();

    public listMyStudiesOnly = false;
    public customerDateFormat: string;

    // Translations
    public caseStudyCallFailureTxt: string;
    public deleteCaseStudyText: string;
    public cloneCaseStudyText: string;
    public deleteCaseStudySuccessfulTxt: string;
    public deleteCaseStudyFailureTxt: string;
    public cloneCaseStudySuccessfulTxt: string;
    public cloneCaseStudyFailureTxt: string;
    public cancelText: string;
    public okText: string;
    public okTextClone: string;
    public titleText: string;
    public titleTextClone: string;
    private flowMonitorsString: string;
    private flowMonitorString: string;
    private rainfallMonitorsString: string;
    private rainfallMonitorString: string;
    private openDialog: boolean;

    public get SliicerCaseStudyData() {
        return this.caseStudyDataChange.value;
    }

    public showClaimStudy = false;

    /**
       filter values from sliicer study dahboard
    **/
    constructor(
        private dialog: MatDialog,
        private utilService: UiUtilsService,
        private translate: TranslateService,
        public cdr: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private sliicerService: SliicerService,
        private router: Router,
        private dateutilService: DateutilService,
        private usersService: UsersService
    ) {
        const caseStudySub: Subscription = this.caseStudySearchString.valueChanges
            .pipe(debounceTime(400), distinctUntilChanged(), skip(1))
            .subscribe((res: string) => {
                this.filterCaseStudies(res);
            });
        const dateFormatSub: Subscription = this.dateutilService.dateFormat.subscribe((newDateFormat) => {
            // Getting customer dateformat from dateUtil Service
            this.customerDateFormat = this.dateutilService.getFormat();
        });

        this.subscriptions.push(caseStudySub, dateFormatSub);
    }

    /**
     * Oninit lifecycle
     */
    public ngOnInit() {
        const roleSubs = this.usersService.userRoles.subscribe((roles: string[]) => {
            const showClaimStudy = roles[0] === USER_ROLES.ADMIN || roles[0] === USER_ROLES.CUSTOMER_ADMIN;
            this.addClaimColumn(showClaimStudy);
        });

        this.subscriptions.push(roleSubs);
        this.applyTranslations(this.translate);
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscripton) => subscripton.unsubscribe());
    }

    /**
     * Onchanges lifecycle to getcasestudies for each customer from api
     */
    public ngOnChanges(changes: SimpleChanges) {
        this.getCaseStudies();
    }

    private applyTranslations(translateService: TranslateService) {
        const translateKeys: Array<string> = [
            'SLIICER_TABLE.SLIICER_NO_RECORDS_FOUND_TEXT',
            'SLIICER_TABLE.SLIICER_DELETE_DIALOG_CONTENT',
            'SLIICER_TABLE.SLIICER_DELETE_SUCCESS_TEXT',
            'SLIICER_TABLE.SLIICER_DELETE_FAILURE_TEXT',
            'SLIICER_TABLE.SLIICER_CLONE_DIALOG_CONTENT',
            'SLIICER_TABLE.SLIICER_CLONE_SUCCESS_TEXT',
            'SLIICER_TABLE.SLIICER_CLONE_FAILURE_TEXT',
            'SLIICER.COMMON.DELETE_BUTTON_TITLE',
            'SLIICER.COMMON.CANCEL_BUTTON_TITLE',
            'SLIICER.COMMON.CLONE_BUTTON_TITLE',
            'SLIICER_TABLE.SLIICER_CLONESTUDY',
            'SLIICER_TABLE.SLIICER_DIALOG_TITLE',
            'SLIICER_TABLE.SLIICER_DIALOG_TITLE_CLONE',
            'COMMON.FLOW_MONITORS',
            'COMMON.FLOW_MONITOR',
            'COMMON.RAINFALL_MONITORS',
            'COMMON.RAINFALL_MONITOR',
        ];

        translateService.get(translateKeys).subscribe((translateValues) => {
            if (!translateValues) {
                return;
            }
            this.caseStudyCallFailureTxt = translateValues['SLIICER_TABLE.SLIICER_NO_RECORDS_FOUND_TEXT'];
            this.deleteCaseStudyText = translateValues['SLIICER_TABLE.SLIICER_DELETE_DIALOG_CONTENT'];
            this.cloneCaseStudyText = translateValues['SLIICER_TABLE.SLIICER_CLONE_DIALOG_CONTENT'];
            this.deleteCaseStudySuccessfulTxt = translateValues['SLIICER_TABLE.SLIICER_DELETE_SUCCESS_TEXT'];
            this.cloneCaseStudySuccessfulTxt = translateValues['SLIICER_TABLE.SLIICER_CLONE_SUCCESS_TEXT'];
            this.deleteCaseStudyFailureTxt = translateValues['SLIICER_TABLE.SLIICER_DELETE_FAILURE_TEXT'];
            this.cloneCaseStudyFailureTxt = translateValues['SLIICER_TABLE.SLIICER_CLONE_FAILURE_TEXT'];
            this.cancelText = translateValues['SLIICER.COMMON.CANCEL_BUTTON_TITLE'];
            this.okText = translateValues['SLIICER.COMMON.DELETE_BUTTON_TITLE'];
            this.okTextClone = translateValues['SLIICER.COMMON.CLONE_BUTTON_TITLE'];
            this.titleText = translateValues['SLIICER_TABLE.SLIICER_DIALOG_TITLE'];
            this.titleTextClone = translateValues['SLIICER_TABLE.SLIICER_DIALOG_TITLE_CLONE'];
            this.flowMonitorsString = translateValues['COMMON.FLOW_MONITORS'];
            this.flowMonitorString = translateValues['COMMON.FLOW_MONITOR'];
            this.rainfallMonitorsString = translateValues['COMMON.RAINFALL_MONITORS'];
            this.rainfallMonitorString = translateValues['COMMON.RAINFALL_MONITOR'];
        });
    }

    private addClaimColumn(showClaim: boolean) {
        if (showClaim === this.showClaimStudy) {
            return;
        }

        this.showClaimStudy = showClaim;
        if (showClaim) {
            this.caseStudyDescriptionColumns.push(CLAIM_STUDY_COL_NAME);
            this.caseStudyNameColumns.push(CLAIM_STUDY_COL_NAME);
        } else {
            this.caseStudyDescriptionColumns = this.caseStudyDescriptionColumns.filter(v => v !== CLAIM_STUDY_COL_NAME);
            this.caseStudyNameColumns = this.caseStudyNameColumns.filter(v => v !== CLAIM_STUDY_COL_NAME);
        }
    }

    /**
       Method to create new case study for user
    **/
    public addNewSliicerStudy() {
        const studies = this.initialCaseStudyData.getValue();
        const dialogData: AddNewStudyDialogData = { customerId: this.customerId, existingStudies: studies.map(v => ({ id: v.id, name: v.meta.name })) };
        this.dialog
            .open(SliicerStudyDailogComponent, {
                disableClose: true,
                data: dialogData,
            })
            .afterClosed()
            .subscribe((res) => {
                if (res && res.success) {
                    this.setUpCaseStudyDetailRoute(res.caseStudyDetails);
                }
            });
        this.utilService.safeChangeDetection(this.cdr);
    }

    /**
       Method to generate case study Mat table if available for customer or user
    **/
    public generateCaseStudyTable(caseStudies: SliicerCaseStudy[]) {
        if (!caseStudies || caseStudies.length === 0) {
            this.caseStudyDataSource = null;
            this.caseStudyDataChange = new BehaviorSubject([]);
            this.totalPaginationLength = 0;
            this.utilService.safeChangeDetection(this.cdr);
            return;
        }

        const caseStudyData = [];
        for (const studyData of caseStudies) {
            let startDate = null;
            let endDate = null;
            let fm = [];
            let rm = [];
            if (studyData.config) {
                startDate = studyData.config.startDate;
                endDate = studyData.config.endDate;
                fm = studyData.config.flowMonitors;
                rm = studyData.config.rainfallMonitors;
            }
            const sliicerCaseStudy = {
                id: studyData.id,
                customerId: studyData.customerId,
                name: studyData.meta.name,
                telemetryDatabaseName: studyData.meta.telemetryDatabaseName,
                description: studyData.meta.desc ? studyData.meta.desc : '',
                createdOn: studyData.meta.created,
                createdBy: studyData.meta.authors ? studyData.meta.authors[0].name : '',
                daysInStudy: SliicerService.GetDaysInStudy(startDate, endDate),
                StudyLocations: this.getMonitiorsDetails(fm, rm),
                StudyDate: this.sliicerService.getStudyDate(startDate, endDate, this.customerDateFormat),
                lastModified: studyData.meta.lastModified,
                editable: studyData.editable,
                locked: studyData.meta.locked,
                isNonAuthorReadOnly: studyData.meta.allowRead,
            };

            caseStudyData.push(sliicerCaseStudy);
        }
        this.caseStudyDataChange.next(caseStudyData);

        this.utilService.safeChangeDetection(this.cdr);
        this.resetPageIndex();
        this.caseStudyDataSource = new MatTableDataSource(this.SliicerCaseStudyData);
        this.caseStudyDataSource.paginator = this.caseStudyPaginator;
        this.totalPaginationLength = this.SliicerCaseStudyData.length;

        this.sortApply();

        if (this.caseStudySort) {
            this.caseStudySort.sort(<MatSortable>{
                id: ColumnNames.StudyDate,
                start: 'desc',
            });
            this.caseStudySort.direction = 'desc';
            this.caseStudyDataSource.sort = this.caseStudySort;
        }
    }

    private sortApply() {
        // #22449 ignore uppercase while sorting strings
        this.caseStudyDataSource.sortingDataAccessor = (data, sortHeaderId: string): string => {
            const columnIndex = this.caseStudyDescriptionColumns.indexOf(sortHeaderId);
            const columnName = columnIndex || columnIndex === 0 ? this.caseStudyNameColumns[columnIndex] : undefined;

            const val = columnName ? data[columnName] : undefined;

            if (columnName === ColumnNames.StudyDate) {
                // #32976 Study Date sort function doesn't sort studies in the correct order
                if (val.length > 10) {
                    if (this.customerDateFormat === 'dd/MM/yyyy') {
                        const day = val.substr(0, 3);
                        const month = val.substr(3, 3);
                        return new Date(month + day + val.substr(6, 4)).toISOString();
                    }
                    return new Date(val.substr(0, 10)).toISOString();
                }
            }

            if (columnName === ColumnNames.StudyLocations) {
                // #32974 Study Locations sort function doesn't sort studies in the correct order
                return val.replace(/\b\d\b/g, m => `000000${m}`);
            }

            if (typeof val === 'string') {
                return val.toLocaleLowerCase();
            } else if (val === null || val === undefined) {
                return '';
            }

            return data[sortHeaderId];
        };
    }

    /**
       Method to fetch case studies assosiated with customer or user if sliicer permissions enabled
       calls sliicer service to fetch required case studies
    **/
    public getCaseStudies(keepSearchValue = false) {
        this.isLoadingState = true;

        const userId = this.usersService.userID.getValue();
        // #30386 need to make sure that we have user settings loaded

        const caseStudyObservable =  this.usersService.userSettings.pipe(
            filter(v => !!v),
            first(),
            tap((settings) => this.listMyStudiesOnly = (settings.studyAuthorIDFilter && settings.studyAuthorIDFilter.includes(userId))),
            switchMap(() => this.sliicerService.getCaseStudySummary(this.customerId))
        ).subscribe((result: SliicerCaseStudy[]) => {
            this.isLoadingState = false;

            if (keepSearchValue === false) {
                this.caseStudySearchString.setValue('');
            }

            if (!result || !result.length) {
                this.showStudyDashboard = false;
                this.initialCaseStudyData.next([]);
                this.utilService.safeChangeDetection(this.cdr);
                
                return;
            }

            this.initialCaseStudyData.next(result);
            this.showStudyDashboard = true;
            if (this.listMyStudiesOnly) {
                const myStudies = this.filterStudiesByAuthor(result);
                
                this.generateCaseStudyTable(myStudies);
            } else {
                this.generateCaseStudyTable(result);
            }
        },
        (error) => {
            this.isLoadingState = false;
            this.sliicerService.showToast(this.caseStudyCallFailureTxt, true);
            const appQueryParams = <AppQueryParams>{
                c: this.customerId,
            };
            this.router.navigate(['/dashboard'], {
                queryParams: appQueryParams,
                relativeTo: this.activatedRoute,
            });
        });

        this.subscriptions.push(caseStudyObservable);
    }

    public onShowMyStudiesChecked() {
        const studies = this.initialCaseStudyData.getValue();
        this.caseStudySearchString.setValue('', { emitEvent: false });

        if (!this.listMyStudiesOnly) {
            this.generateCaseStudyTable(studies);
        } else {
            const myStudies = this.filterStudiesByAuthor(studies);
            
            this.generateCaseStudyTable(myStudies);
        }

        const userId = this.usersService.userID.getValue();
        const oldSettings = this.usersService.userSettings.getValue();
        let { studyAuthorIDFilter = [] } = oldSettings;

        if (this.listMyStudiesOnly) {
            studyAuthorIDFilter = [...studyAuthorIDFilter, userId];
        } else {
            studyAuthorIDFilter = studyAuthorIDFilter.filter(v => v !== userId);
        }

        // remove duplicates
        oldSettings.studyAuthorIDFilter = studyAuthorIDFilter.filter((v, i) => i === studyAuthorIDFilter.indexOf(v));

        this.usersService.updateUserSettings({ ...oldSettings }).subscribe(() => {
            this.usersService.userSettings.next({ ...oldSettings });
        });
    }

    private filterStudiesByAuthor(studies: SliicerCaseStudy[]): SliicerCaseStudy[] {
        const currentUserId = this.usersService.userID.getValue();
        const myStudies = studies.filter(v => v?.meta?.authors !== undefined && v?.meta?.authors?.some(x => x.id === currentUserId));

        return myStudies;
    }

    /**
       Method to show user searched case studies if avialbale else show blank screen
    **/
    public filterCaseStudies(searchString: string) {
        const currentUserId = this.usersService.userID.getValue();
        this.initialCaseStudyData.subscribe((result) => {
            if (this.listMyStudiesOnly) {
                result = result.filter(v => v?.meta?.authors[0].id === currentUserId);
            }

            if (!searchString || searchString.trim() === '') {
                this.generateCaseStudyTable(result);
            } else {
                searchString = searchString.trim();
                const searchStringLower = searchString ? searchString.toLowerCase() : null;

                const caseStudyData = result.filter((caseStudy) => {
                    return (
                        (caseStudy.meta.name && caseStudy.meta.name.toLowerCase().includes(searchStringLower))
                        || (caseStudy.meta.desc && caseStudy.meta.desc.toLowerCase().includes(searchStringLower))
                        || (
                            caseStudy.meta.authors && caseStudy.meta.authors.length && caseStudy.meta.authors[0].name
                            && caseStudy.meta.authors[0].name.toLowerCase().includes(searchStringLower)
                        )
                    );
                });
                this.resetPageIndex();
                this.generateCaseStudyTable(caseStudyData);
            }
        });
    }

    clearSearch(event) {
        if (event) {
            event.stopPropagation();
        }

        this.caseStudySearchString.setValue('');
        this.filterCaseStudies('');
    }

    /**
       Method to show details of each case study in details route
    **/
    public setUpCaseStudyDetailRoute(caseStudy) {
        if (
            (caseStudy.editable !== undefined && !(caseStudy.editable || caseStudy.isNonAuthorReadOnly)) ||
            this.openDialog
        ) {
            return false;
        }
        const appQueryParams = <AppQueryParams>{
            [customerQueryParam]: this.customerId,
            [caseStudyQueryParam]: caseStudy.id,
        };
        this.router.navigate(['/pages/sliicer/details'], {
            queryParams: appQueryParams,
            relativeTo: this.activatedRoute,
        });
    }

    public cloneCaseStudy(caseStudy) {
        this.openDialog = true;
        this.dialog
            .open(ConfirmationDialogComponent, {
                data: {
                    title: this.titleTextClone,
                    message: this.cloneCaseStudyText + ' ' + caseStudy.name + '?',
                    cancelText: this.cancelText,
                    okText: this.okTextClone,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                this.openDialog = false;
                if (res.whichButtonWasPressed === 'ok') {
                    this.isLoadingState = true;
                    // tslint:disable-next-line:max-line-length
                    this.sliicerService.cloneCaseStudy(caseStudy.customerId, caseStudy.id).subscribe(
                        (result: SliicerCaseStudy) => {
                            this.isLoadingState = false;
                            const t = [...this.initialCaseStudyData.getValue(), result];
                            this.initialCaseStudyData.next(t);
                            this.filterCaseStudies(this.caseStudySearchString.value);
                            this.utilService.safeChangeDetection(this.cdr);
                            this.sliicerService.showToast(this.cloneCaseStudySuccessfulTxt);
                        },
                        (error) => {
                            this.isLoadingState = false;
                            this.utilService.safeChangeDetection(this.cdr);
                            this.sliicerService.showToast(this.cloneCaseStudyFailureTxt, true);
                        },
                    );
                }
            });
    }

    public claimStudy(study) {
        this.openDialog = true;

        const title = this.translate.instant('SLIICER_TABLE.SLIICER_DIALOG_TITLE_CLAIM');
        const message = this.translate.instant('SLIICER_TABLE.SLIICER_CLAIM_DIALOG_CONTENT');
        const okText = this.translate.instant('SLIICER.COMMON.CLAIM_BUTTON_TITLE');

        const successMessage = this.translate.instant('SLIICER_TABLE.SLIICER_CLAIM_SUCCESS_TEXT');
        const errorMessage = this.translate.instant('SLIICER_TABLE.SLIICER_CLAIM_FAILURE_TEXT');
        this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title, message: message + ' ' + study.name + '?',
                cancelText: this.cancelText,
                okText
            }
        }).afterClosed().subscribe((res) => {
            if (!res || res.whichButtonWasPressed !== 'ok') {
                return;
            }

            this.openDialog = false;
            this.isLoadingState = true;
            this.sliicerService.claimCaseStudy(study.customerId, study.id)
                .subscribe(
                    (result) => {
                        this.sliicerService.showToast(successMessage);
                        this.isLoadingState = false;
                        this.getCaseStudies(true);
                    },
                    (error) => {
                        this.sliicerService.showToast(errorMessage, true);
                        this.isLoadingState = false;
                        this.utilService.safeChangeDetection(this.cdr);
                    },
                );
        })
    }

    public deleteCaseStudy(caseStudy) {
        this.openDialog = true;
        this.dialog
            .open(ConfirmationDialogComponent, {
                data: {
                    title: this.titleText,
                    message: this.deleteCaseStudyText + ' ' + caseStudy.name + '?',
                    cancelText: this.cancelText,
                    okText: this.okText,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                this.openDialog = false;
                if (res.whichButtonWasPressed === 'ok') {
                    this.isLoadingState = true;
                    // tslint:disable-next-line:max-line-length
                    this.sliicerService
                        .deleteCaseStudy(caseStudy.customerId, caseStudy.id, caseStudy.telemetryDatabaseName)
                        .subscribe(
                            (result) => {
                                this.isLoadingState = false;
                                const studies = this.initialCaseStudyData.getValue().filter(function (item) {
                                    return item.id !== caseStudy.id;
                                });

                                this.initialCaseStudyData.next(studies);

                                this.filterCaseStudies(this.caseStudySearchString.value);

                                this.utilService.safeChangeDetection(this.cdr);
                                this.sliicerService.showToast(this.deleteCaseStudySuccessfulTxt);
                            },
                            (error) => {
                                this.isLoadingState = false;
                                this.utilService.safeChangeDetection(this.cdr);
                                this.sliicerService.showToast(this.deleteCaseStudyFailureTxt, true);
                            },
                        );
                }
            });
    }

    /**
     * Method to get flow monitors and rainfall monitors included in the case study with locationgroup id's choosen
     * by user at the study creation period
     * @param monitorDetails containing flow,rainfall and level monitors used in case study
     */
    private getMonitiorsDetails(flowMonitors: Array<MonitorName>, rainfallMonitors: Array<MonitorName>) {
        const flowCount = flowMonitors !== null ? flowMonitors.length : 0;
        const rainCount = rainfallMonitors !== null ? rainfallMonitors.length : 0;
        if (flowCount > 0 || rainCount > 0) {
            const flowText =
                flowCount !== 1 ? `${flowCount} ${this.flowMonitorsString}` : `1 ${this.flowMonitorString}`;
            const rainText =
                rainCount !== 1 ? `${rainCount} ${this.rainfallMonitorsString}` : `1 ${this.rainfallMonitorString}`;
            return `${flowText}, ${rainText}`;
        }
        return '-';
    }

    /** Resets the page index if the paginator is available (it might not be if the table isn't available. */
    private resetPageIndex() {
        if (this.caseStudyPaginator && this.caseStudyPaginator.pageIndex) {
            this.caseStudyPaginator.pageIndex = 0;
        }
    }
}
