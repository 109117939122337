import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, EntityUnit } from 'app/shared/models/units';
import { Config } from 'app/shared/services/config';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomGraphs, GraphConfiguration } from './custom-dashboard-model/custom-dashboard.model';

@Injectable()
export class CustomDashboardService {
    constructor(private http: HttpClient) {}

    public getPreDefinedEntities(customerID: number): Observable<Array<EntityUnit>> {
        const getEntitiesExceptCE = Config.getUrl(Config.urls.getPreDefinedEntitiesOnly);
        return this.http
            .get<ApiResponse<Array<EntityUnit>>>(`${getEntitiesExceptCE}?cid=${customerID}`)
            .pipe(map((response: ApiResponse<Array<EntityUnit>>) => response.payload));
    }

    public saveCustomDashboard(cid: number, configuration: GraphConfiguration): Observable<CustomGraphs> {
        // Setting the Parameters.
        const customDashboardURI = Config.getUrl(Config.urls.customDashboard);
        return this.http
            .post<CustomGraphs>(`${customDashboardURI}?cid=${cid}`, configuration)
            .pipe(map((response: CustomGraphs) => response));
    }

    /// Get all configurations by customer Id.
    public fetchCustomDashboardGraph(cid: number): Observable<CustomGraphs> {
        const getcutomGraphs = Config.getUrl(Config.urls.customDashboardGraph);
        return this.http.get<CustomGraphs>(getcutomGraphs + `?cid=${cid}`).pipe(
            map((response: CustomGraphs) => {
                return response;
            }),
        );
    }

    // Get graph by configuration Id.
    public getGraphsByConfigurationId(cid: number, graphId: string) {
        const getcutomGraphs = Config.getUrl(Config.urls.graphsByConfigurationId);
        return this.http.get<Object>(getcutomGraphs + `?cid=${cid}&graphId=${graphId}`).pipe(
            map(
                (result) => {
                    return result;
                },
                (error) => {
                    return EMPTY;
                },
            ),
        );
    }

    // Delete Graph
    public deleteGraphByConfigurationId(cid: number, graphId: string): Observable<any> {
        const getcutomGraphs = Config.getUrl(Config.urls.graphsByConfigurationId);
        return this.http.delete<any>(getcutomGraphs + `?cid=${cid}` + `&graphId=${graphId}`);
    }
}
