import { Component, OnInit, ViewChild, ViewEncapsulation, ChangeDetectorRef, Inject, OnDestroy } from '@angular/core';
import moment from 'moment';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatSort, MatSortable } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { AddTritonLocationService } from '../map/add-edit-location/add-triton-location-service';
import { DataEditService } from '../../services/data-edit.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { EMPTY, Subscription } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators'
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { ListItem } from 'app/shared/models/selected-Item';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { DatePipe } from '@angular/common';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { DataEditPreview, DataEditPreviewParams } from 'app/shared/models/data-edit';
import { UsersService } from 'app/pages/admin/users.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from 'app/shared/services/customer.service';
import { GainTableResponse, MonitorDetails, GainData, GainPoints, EntityData, VelocityGainDialogData } from 'app/shared/models/gain-data';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { DataEditType, EntityType, UnitOfMeasureType, GATEDVEL } from 'app/shared/constant';
import { ConfirmationButton, ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import {  IComponentDialog, IComponentDialogConfirmationResult } from 'app/shared/models/comopnent-cofirmation';
import { RecalculateEntitiesComponent, RecalculateEntitiesComponentRequest, RecalculateEntitiesComponentResponse } from '../recalculate-entities/recalculate-entities.component';
import { DeviceTypeCode } from 'app/shared/enums/device-type-code';
import { SensorType } from 'app/shared/services/monitor-diagnostics.service';

//Request from Kristen to add the min start date for gain table entry
const minDate = '2023-11-01';
const GAIN_EDITOR_ID = 1704;
const GAIN_EDITOR_EDIT_REASON = 'gain editor';
const dateFormat = 'YYYY-MM-DDTHH:mm:ss';
const formatDate = (format: string) => (m: Date) => moment(m).format(format);
const nullDateYear = '0001-01-01T00:00:00';

export interface VelocityGainTableComponentResponse {
    success: boolean;
    data?: DataEditPreview;
    start?: Date;
    end?: Date;
}

@Component({
    selector: 'app-velocity-gain-table',
    templateUrl: './velocity-gain-table.component.html',
    styleUrls: ['./velocity-gain-table.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class VelocityGainTableComponent implements OnInit, OnDestroy {
    @ViewChild('table') private table;
    @ViewChild('selectedDateInput') private dateInput;
    @ViewChild(MatSort) public tableSort: MatSort;
    public dialogData: { customerId: number; locationDetails?: { locationID: number, locationName: string } };
    public gainData: GainData[] = [];
    public entityList: ListItem[] = [];
    public addMore: boolean;
    public enableSave: boolean;
    private originalSource$: GainData[];
    public form: FormGroup;
    public monitorDetails: MonitorDetails[] = [];
    public customerDateFormat: string;
    public userHasPermissionOnEdit = false;
    public customerMomentDateFormat: string;
    private editDic = {};
    public isCustomDateTouched: boolean;
    public updateGainPoints = [];
    public gainPoints : GainPoints[] = [];
    public gainEditorDataSource : any;
    public isLoading = true;
    public selectedEntity: string;
    public errorMsg: string;
    public isMetric: boolean;
    public cancelMsg: string;
    public okMsg: string;
    public deleteOldestRecordMsg: string;
    public dateValidationMsg: string;
    public rawVelEditsMsg: string;
    public recalcMsg: string;
    public contMsg: string;
    public newEntryEntityChangeMsg: string;
    public oldEntryDateValidationMsg: string;
    public maxDateValidationMsg: string;
    public dateFormat: string
    public editMsg: string;
    public isEditMode: boolean;
    public editConfirmationTitle: string;
    public recalcConfirmationTitle: string;
    public deleteConfirmationTitle: string;
    public numericWith2DecimalPlaces = REGEX_CONFIG.numericWith2DecimalPlaces;
    public numericWith1DecimalPlaces = REGEX_CONFIG.numericWith1DecimalPlaces;
    public id = 0;
    public initDataSource: GainData[] ;
    public isOldRecord: boolean;
    public isNewRecord: boolean;
    public addNew = false;
    public dataEditType = DataEditType.GainTableUpdate;
    public selectedDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0);
    public selectedVal: number;
    public GainEditorDescriptionColumns = ['timestamp', 'editedValue', 'applyTo', 'user', 'editDate'];
    public checkUpdate: boolean;
    public gainDataExists: boolean = true;
    public peakVelocity: string;
    public gainValuePrecision: string;
    public gainValuePatternError: string;
    public entityWasEdited = false;
    public formChanged = false;

    private subscriptions = new Subscription();
    constructor(
        public dialogRef: MatDialogRef<VelocityGainTableComponent>,
        public fb: FormBuilder,
        private dialog: MatDialog,
        private dataEditingService: DataEditService,
        @Inject(MAT_DIALOG_DATA) public data: VelocityGainDialogData,
        private addTritonLocationService: AddTritonLocationService,
        private cdr: ChangeDetectorRef,
        private snackBarService: SnackBarNotificationService,
        private dataEditService: DataEditService,
        private dateutilService: DateutilService,
        private uiUtilsService: UiUtilsService,
        private datePipe: DatePipe,
        public translate: TranslateService,
        private customerService: CustomerService,
        private userService: UsersService,
    ) {
        this.data = data;
        this.isEditMode = this.data.showEditMenu;
        this.isMetric = this.customerService.customer && (Number(this.customerService.customer.unitsType) === UnitOfMeasureType.METRIC);
        const editedValuePattern = this.numericWith2DecimalPlaces;
        this.gainValuePrecision = this.isMetric ? '1.1-1' : '1.2-2';
        this.form = this.fb.group({
            timestamp: [{ value: this.selectedDate, disabled: false }, Validators.required],
            applyTo: [{ value:'', disabled: false } , Validators.required],
            editedValue: [{ value:'', disabled: false }, [Validators.required, Validators.pattern(editedValuePattern), Validators.max(9.99)]],
        });

        this.peakVelocity = this.translate.instant('COMMON.PEAK_VELOCITY');
        this.gainValuePatternError = this.translate.instant('COMMON.VELOCITY_GAIN_MAX_ERROR');

    }

    ngOnInit() {
        this.checkUserPermission();
        this.gainEditorDataSource = new MatTableDataSource([]);
        if (this.data) {
            this.dialogData = this.data;
        } else {
            this.dialogData = this.addTritonLocationService.data;
        }

        const dateFormatSubs = this.dateutilService.dateFormat.subscribe(() => {
            this.dateFormat = String(this.dateutilService.getFormat().toUpperCase());
            // Getting customer dateformat from dateUtil Service
            const is12HourFormat = this.dateutilService.timeFormat.getValue() !== 'hh:mm:ss';
            // Getting customer dateformat from dateUtil Service
            this.customerDateFormat =`${String(this.dateutilService.getFormat())} ${is12HourFormat ? 'hh:mm a' : 'HH:mm'}`;

            this.customerMomentDateFormat = `${String(this.dateutilService.getFormat().toUpperCase())} ${is12HourFormat ? 'hh:mm a' : 'HH:mm'}`;

                this.uiUtilsService.safeChangeDetection(this.cdr);
        });

        this.newEntryEntityChangeMsg = this.translate.instant('COMMON.CHANGE_GAIN');
        this.cancelMsg = this.translate.instant('COMMON.CANCEL');
        this.okMsg = this.translate.instant('COMMON.OK');
        this.deleteOldestRecordMsg = this.translate.instant('COMMON.DELETE_OLD_GAIN');
        this.rawVelEditsMsg = this.translate.instant('COMMON.ENTITY_UPDATE');
        this.recalcMsg = this.translate.instant('COMMON.RECALCULATE_MSG');
        this.contMsg = this.translate.instant('COMMON.CONTINUE');
        this.editMsg = this.translate.instant('COMMON.EDIT_GAIN');
        this.deleteConfirmationTitle = this.translate.instant('COMMON.DELETE_GAIN_CONFIRMATION');
        this.editConfirmationTitle = this.translate.instant('COMMON.EDIT_GAIN_CONFIRMATION');
        this.recalcConfirmationTitle = this.translate.instant('COMMON.RECALCULATE_CONFIRMATION_TITLE');
        this.dateValidationMsg = this.translate.instant('COMMON.DATE_VALIDATION');
        this.oldEntryDateValidationMsg = this.translate.instant('COMMON.OLD_RECORD_DATE_VALIDATION');
        this.maxDateValidationMsg = this.translate.instant('COMMON.MAX_RECORD_DATE_VALIDATION');

        this.errorMsg = '';
        this.isMetric = this.dateutilService.isCustomerUnitsMetric.getValue();
        this.subscriptions.add(dateFormatSubs);

        if (this.dialogData &&
            this.dialogData.locationDetails &&
            this.dialogData.locationDetails.locationID &&
            this.dialogData.locationDetails.locationName) {
            this.getVelocityGainValues();
            this.getMonitorDetails();
            this.getEntityData();
        }
        this.isLoading = false;
    }

    public addMoreOpen() {
        this.entityWasEdited = false;
        this.enableSave = false;
        this.form.controls['timestamp'].enable();
        this.form.controls['timestamp'].setValue(this.selectedDate);
        this.addNew = true;
        this.addMore = !this.addMore;
    }

    getEntityData(){
        this.dataEditingService.getAllEntityData(this.dialogData.customerId, this.dialogData.locationDetails.locationID, GAIN_EDITOR_ID).subscribe((res: EntityData) => {
            if (res && ((res.data && res.data.length > 0) || (res.editedData && res.editedData.length > 0))) {
                const source = [];
                const map = new Map();
                res.data.forEach((item) => map.set(item.dateTime, item));
                res.editedData.forEach((item) => map.set(item.dateTime, { ...map.get(item.dateTime), ...item }));
                const mergedArr = Array.from(map.values());
                mergedArr.forEach((d, i) => {
                    if (!d['ignore']) {
                        const obj = {};
                        obj['timestamp'] = d.dateTime;
                        obj['editedValue'] = d.reading;
                        source.push(obj);
                    }
                });

                if(this.originalSource$)
                {
                    this.originalSource$.forEach(a=>a.valExists = false);
                }
            } else {
                this.originalSource$ = [];
            }
            this.isLoading = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
    }

    public getMonitorDetails() {
        this.dataEditService
            .getMonitorDetails(this.dialogData.customerId, this.dialogData.locationDetails.locationID)
            .subscribe((data: MonitorDetails[]) => {
                if (!data) {
                    this.monitorDetails = [];
                    return [];
                }

                const formattedData: MonitorDetails[] = [];
                data.forEach((gainData) =>
                        formattedData.push({
                            device: gainData.device,
                            entityID: gainData.entityID,
                            entityName: gainData.entityName,
                            monitoringPoint: gainData.monitoringPoint,
                            sensor: gainData.sensor
                        }),
                    );

                if (this.dialogData.locationDetails.locationName.includes('(2)')) {
                    this.entityList = formattedData
                        .filter(x => x.monitoringPoint !== 1)
                        .map((b) => { return  {text: b.entityName, id: b.entityID }}).sort();
                    this.monitorDetails = formattedData.filter(x => x.monitoringPoint !== 1);
                }
                else {
                    this.entityList = formattedData
                        .filter(x => x.monitoringPoint !== 2)
                        .map((b) => { return  {text: b.entityName, id: b.entityID }}).sort();
                    this.monitorDetails = formattedData.filter(x => x.monitoringPoint !== 2);
                }

            });
        return [];
    }

    private checkUserPermission() {
        this.userHasPermissionOnEdit = this.userService.isBasicDataEditingAllowed.getValue();

        if (this.userHasPermissionOnEdit) {
            this.GainEditorDescriptionColumns.push('action');
        }
    }

    private getVelocityGainValues() {
        this.dataEditService
            .getGainTable(this.dialogData.customerId, this.dialogData.locationDetails.locationID)
            .subscribe((data: GainTableResponse[]) => {
                if (!data) {
                    this.gainData = [];
                    this.gainDataExists = false;
                    return;
                }

                data[0].g.sort((a, b) => new Date(a.dt).getTime() - new Date(b.dt).getTime());
                const formattedData: GainData[] = [];
                data.forEach((v) => {
                    v.g.forEach((gainData) =>
                        formattedData.push({
                            timestamp: gainData.dt,
                            applyTo: gainData.et,
                            sensor: gainData.v,
                            device: gainData.d,
                            editedValue: gainData.g,
                            mp: gainData.mp,
                            user: gainData.us,
                            editDate: gainData.ed !== nullDateYear ? gainData.ed : null ,
                            id: ++this.id,
                        }),
                    );
                });

                if (this.dialogData.locationDetails.locationName.includes('(2)')) {
                    this.gainEditorDataSource.data = formattedData.filter(x => x.mp === 2);
                }
                else {
                    this.gainEditorDataSource.data = formattedData.filter(x => x.mp === 1);
                }

                this.gainEditorDataSource?.data?.forEach((item) => {
                    item.isOlderRecord = false;
                    if(new Date(item.timestamp) < new Date(new Date(minDate).toISOString().slice(0,-5))){
                       item.isOlderRecord = true;
                    }
                });

                this.initDataSource = this.gainEditorDataSource?.data?.map((x) => Object.assign([], JSON.parse(JSON.stringify(x))));
                this.initDataSource?.forEach(a=>a.valExists = false);
                this.originalSource$  = this.gainEditorDataSource?.data?.map((x) => Object.assign([], JSON.parse(JSON.stringify(x))));
            });
    }

    public checkIfGainEdited() {
        this.enableSave = false;

        if(this.initDataSource && this.gainEditorDataSource && this.gainEditorDataSource.data && this.initDataSource.length !== this.gainEditorDataSource.data.length)
        {
            this.enableSave = true;
        }

        else if((this.initDataSource && !this.gainEditorDataSource.data) || (!this.initDataSource && this.gainEditorDataSource && this.gainEditorDataSource.data))
        {
            this.enableSave = true;
        }

        else if(this.gainEditorDataSource && this.gainEditorDataSource.data && this.initDataSource)
        {
            const gainTableData = this.gainEditorDataSource.data.map((x) => Object.assign([], JSON.parse(JSON.stringify(x))));

            gainTableData?.sort((a, b) => (new Date(a.timestamp).getTime() < new Date(b.timestamp).getTime() ? -1 : 1));

            gainTableData.forEach((item) => {
                item.timestamp = formatDate(dateFormat)(item.timestamp);
            });

            for(let i=0 ; i< gainTableData.length; i++)
            {
                 if(gainTableData[i].timestamp !== this.initDataSource[i].timestamp || gainTableData[i].editedValue !== this.initDataSource[i].editedValue || gainTableData[i].applyTo !== this.initDataSource[i].applyTo)
                 {
                    this.enableSave = true;
                    break;
                 }
            }
        }
    }

    public cancel() {
        this.entityWasEdited = false;
        this.addNew = false;
        this.errorMsg = '';

        if (this.table.dataSource) {
            this.table.renderRows();
        }

        this.form.reset();
        this.addMore = !this.addMore;

        this.checkIfGainEdited();
    }

    public validateDate() {
        const selectedDate = this.form.controls['timestamp'].value;
        const minCheckDate = new Date(minDate).toISOString().slice(0,-5);
        if (this.gainEditorDataSource.data && this.gainEditorDataSource.data.length > 0) {
            const filterData = this.gainEditorDataSource.data.filter(a =>  formatDate(dateFormat)(a.timestamp) === formatDate(dateFormat)(this.form.controls['timestamp'].value) && (this.addNew ||  this.selectedVal === undefined ||  this.gainEditorDataSource.data.map(function(e) { return e.timestamp; }).indexOf(a.timestamp) !== this.selectedVal));
            if(filterData.length > 0) {
                this.errorMsg = this.dateValidationMsg;
                this.form.controls['timestamp'].setValue("");
                return false;
            }
        }

        if(new Date(selectedDate) < new Date(minCheckDate)){
            this.form.controls['timestamp'].setValue("");
            this.errorMsg = this.maxDateValidationMsg + formatDate(this.dateFormat)(new Date(minCheckDate)) + '.';
            return false;
        }

        return true;
    }

    public addRow() {
        this.errorMsg =  '';
        const gainResult = this.gainEditorDataSource.data.map((x) => Object.assign([], JSON.parse(JSON.stringify(x))));
        if (this.addNew) {
            this.isCustomDateTouched = false;
            this.entityWasEdited = false;
            if (this.validateDate()) {
                this.dataEditType = DataEditType.GainTableUpdate;
                let timestampInput: any;
                try {
                    const dateValue = this.dateInput.nativeElement.value;
                    timestampInput = new Date(dateValue);

                    if(this.form.controls['timestamp'].value !== undefined && this.form.controls['timestamp'].value !== null)
                    {
                        timestampInput = formatDate(dateFormat)(this.form.controls['timestamp'].value);
                    }
                } catch (error) {
                    timestampInput = this.form.controls['timestamp'].value;
                }

                   gainResult?.sort((a: GainData, b: GainData) => (new Date(a.timestamp).getTime() < new Date(b.timestamp).getTime() ? -1 : 1));
                    const prevGainData = gainResult.reverse().find((date: GainData) => new Date(date.timestamp).getTime() <= new Date(timestampInput).getTime());

                    if(prevGainData  && prevGainData.applyTo !== this.form.controls['applyTo'].value)
                    {
                        const dialogRef = this.dialog.open<ConfirmationDialogComponent,IComponentDialog,IComponentDialogConfirmationResult>(ConfirmationDialogComponent, {
                            data: {
                                title: this.editConfirmationTitle,
                                message: this.newEntryEntityChangeMsg,
                                cancelText: this.cancelMsg,
                                okText: this.okMsg,
                            }
                        });

                        dialogRef.afterClosed().subscribe((res) => {
                            if (res.whichButtonWasPressed === ConfirmationButton.cancel) {
                                this.checkIfGainEdited();
                                this.addMore = !this.addMore;
                                return;
                            }
                            else{
                                this.updateGainTable();
                            }
                        });
                    }
                    else{
                        this.updateGainTable();
                    }
            }
        } else {
            if (this.entityWasEdited && !this.isNewRecord) {
                const dialogRef = this.dialog.open<ConfirmationDialogComponent,IComponentDialog,IComponentDialogConfirmationResult>(ConfirmationDialogComponent, {
                    data: {
                        title: this.editConfirmationTitle,
                            message: this.editMsg,
                            cancelText: this.cancelMsg,
                            okText: this.okMsg,
                    }
                });
                dialogRef.afterClosed().subscribe((res) => {
                    if (res.whichButtonWasPressed === ConfirmationButton.ok) {
                        this.entityWasEdited = false;
                        this.updateRow();
                    }
                });
            } else {
                this.updateRow();
            }
        }
    }

    public updateGainTable()
    {
        let timestampInput =  this.dateInput.nativeElement.value;
        try {
            const dateValue = this.dateInput.nativeElement.value;
            timestampInput = new Date(dateValue);
            if(this.form.controls['timestamp'].value !== undefined && this.form.controls['timestamp'].value !== null)
            {
                timestampInput = formatDate(dateFormat)(this.form.controls['timestamp'].value);
            }
        } catch (error) {
            timestampInput = this.form.controls['timestamp'].value;
        }
        if (!this.gainEditorDataSource) {
            this.gainEditorDataSource = new MatTableDataSource([
                {
                    timestamp: timestampInput,
                    editedValue: this.form.controls['editedValue'].value,
                    applyTo: this.form.controls['applyTo'].value,
                    user: this.data.user,
                    editDate: formatDate(dateFormat)(new Date()),
                    id: ++this.id
                },
            ]);
        } else {
            this.gainEditorDataSource.data.push({
                timestamp: timestampInput,
                editedValue: this.form.controls['editedValue'].value,
                applyTo: this.form.controls['applyTo'].value,
                user: this.data.user,
                editDate: formatDate(dateFormat)(new Date()),
                id: ++this.id
            });
        }
        this.addNew = false;

        if (this.table.dataSource) {
            this.table.renderRows();
        }

        this.checkIfGainEdited();
        this.form.reset();
        this.addMore = !this.addMore;
    }

    private updateRow() {
        let dictVal: number;
        this.dataEditType = DataEditType.GainTableUpdate;
        if (this.isCustomDateTouched) {
            dictVal= this.editDic[this.form.controls['timestamp'].value];
        }
        else {
            dictVal= this.editDic[formatDate(dateFormat)(this.form.controls['timestamp'].value)];
        }

        this.isCustomDateTouched = false;

        if (this.form.controls['timestamp'].value && dictVal !== undefined) {

            this.gainEditorDataSource.data[dictVal].editedValue =
                this.form.controls['editedValue'].value;

            this.gainEditorDataSource.data[dictVal].applyTo =
                this.form.controls['applyTo'].value;

            this.gainEditorDataSource.data[dictVal].timestamp =
                formatDate(dateFormat)(this.form.controls['timestamp'].value);

            this.gainEditorDataSource.data[dictVal].editDate =
                formatDate(dateFormat)(new Date());

            this.gainEditorDataSource.data[dictVal].user =
                this.data.user;

            if(this.originalSource$ && this.originalSource$.length > dictVal)
            {
                this.originalSource$[dictVal].editedValue = this.form.controls['editedValue'].value;
                this.originalSource$[dictVal].applyTo = this.form.controls['applyTo'].value;
                this.originalSource$[dictVal].timestamp = formatDate(dateFormat)(this.form.controls['timestamp'].value);
                this.originalSource$[dictVal].editDate = formatDate(dateFormat)(new Date());
                this.originalSource$[dictVal].user = this.data.user;
            }

            if (this.table.dataSource) {
                this.table.renderRows();
            }
            this.enableSave = true;
            this.form.reset();
            this.addMore = !this.addMore;
        }
    }

    public onEntityChange(event: string){
        if (event === GATEDVEL && this.form.controls['editedValue'].value === "") {
            this.form.controls['editedValue'].setValue(1);
        }
    }

    public inputCheckStartDateChange(event: Event) {
        this.errorMsg = '' ;
        if (!(event.target as HTMLInputElement).value) {
            this.uiUtilsService.safeChangeDetection(this.cdr);
            this.form.controls['timestamp'].updateValueAndValidity();
            return;
        }

        const date = moment((event.target as HTMLInputElement).value, this.customerMomentDateFormat);

        this.checkStartDateChange(date.isValid ? date.toDate() : new Date('Invalid date'));
    }

    public calendarCheckStartDateChange(event: MatDatepickerInputEvent<Date>) {
        this.formChanged = true;

        if (!event.value) {
            this.uiUtilsService.safeChangeDetection(this.cdr);
            return;
        }

        const date = moment(event.value, this.customerMomentDateFormat);
        this.checkStartDateChange(date.toDate());
    }

    public checkStartDateChange(val: Date) {
        this.errorMsg = '';
        this.addMore = true;
        this.form.controls['timestamp'].setValue(val);
        if(this.isOldRecord && formatDate(dateFormat)(new Date(this.originalSource$[0].timestamp)) <  formatDate(dateFormat)(new Date(val))){
            this.errorMsg = this.oldEntryDateValidationMsg;
            this.form.controls['timestamp'].setValue("");
            return false;
        }

        if(this.validateDate())
        {
            delete this.editDic[this.selectedDate.toString()];
            this.editDic[val.toString()] = this.selectedVal;

            this.isCustomDateTouched = true;
            this.selectedDate = new Date(val);
        }
    }

    public saveGainValues() {
        this.isLoading = true;
        this.gainPoints = [];
        this.updateGainPoints = [];
        const currentDate = formatDate(dateFormat)(new Date()) ;

        this.gainEditorDataSource.data?.sort((a: GainData, b: GainData) => (new Date(a.timestamp).getTime() < new Date(b.timestamp).getTime() ? -1 : 1));

        this.gainEditorDataSource.data.forEach((item : GainData) => {
            const data = this.initDataSource?.filter(x => x.timestamp === item.timestamp && x.applyTo === item.applyTo);
            if (data && data.length > 0) {
                data[0].valExists = true;
            }
        });

        const gainResult = this.initDataSource?.filter(x => x.valExists).sort((a: GainData, b: GainData) => (new Date(a.timestamp).getTime() < new Date(b.timestamp).getTime() ? -1 : 1));
        const delGainData = this.initDataSource?.filter(x => !x.valExists).sort((a: GainData, b: GainData) => (new Date(a.timestamp).getTime() < new Date(b.timestamp).getTime() ? -1 : 1));
        gainResult?.sort((a: GainData, b: GainData) => (new Date(a.timestamp).getTime() < new Date(b.timestamp).getTime() ? -1 : 1));

        this.initDataSource?.filter(x => !x.valExists).forEach((item) =>
        {
            const prevGainData = gainResult.filter((date: GainData) => new Date(date.timestamp).getTime() < new Date(item.timestamp).getTime());
            const deleteGainData = delGainData.filter((date: GainData) => new Date(date.timestamp).getTime() < new Date(item.timestamp).getTime());

            const prevGainIndex = prevGainData ? prevGainData.length - 1 : null;
            const delGainIndex = deleteGainData ? deleteGainData.length - 1 : null;

            item.entityUpdate = true;

            if(prevGainIndex >= 0)
            {
                if(delGainIndex >= 0)
                {
                    if(prevGainData[prevGainIndex].timestamp > deleteGainData[delGainIndex].timestamp)
                    {
                        if(item.applyTo === prevGainData[prevGainIndex].applyTo)
                        {
                            item.entityUpdate = false;
                        }
                    }
                    else
                    {
                        if(item.applyTo === deleteGainData[delGainIndex].applyTo)
                        {
                            item.entityUpdate = false;
                        }
                    }

                }
                else
                {
                    if(item.applyTo === prevGainData[prevGainIndex].applyTo)
                    {
                        item.entityUpdate = false;
                    }
                }
            }

            const data = this.gainEditorDataSource.data.filter(a=> a.timestamp === item.timestamp);

            if(data && data.length > 0 && data[0].applyTo !== item.applyTo)
                item.entityUpdate = true;

        })

        this.gainEditorDataSource.data.forEach((x) => {
                const date =x.timestamp;

                if(this.monitorDetails && this.monitorDetails.length > 0) {
                    const monDetails = this.monitorDetails.filter(a => a.entityName === x.applyTo)[0];

                    this.gainPoints.push({
                        dt: formatDate(dateFormat)(date),
                        g: x.editedValue,
                        et: x.applyTo,
                        mp: monDetails ? monDetails.monitoringPoint : x.mp,
                        s: monDetails ? monDetails.sensor : (x.sensor === this.peakVelocity ? SensorType.VELOCITY : SensorType.SURFACE_VELOCITY) ,
                        d: monDetails ? monDetails.device : DeviceTypeCode.getDeviceTypeCode(x.device) ,
                        ei: monDetails ? monDetails.entityID : Number(EntityType[x.applyTo]),
                        us: this.data.user,
                        ed: !x.editDate || x.editDate === '-' ? currentDate : x.editDate,
                    });
                }

        });

        this.initDataSource?.forEach((x) => {
            const date = new Date(x.timestamp);
            if (x.ignore || !x.valExists) {
                this.dataEditType = DataEditType.GainTableUpdate;
                this.updateGainPoints.push({
                    timeStamp: formatDate(dateFormat)(date),
                    reading: x.editedValue,
                    eid: GAIN_EDITOR_ID,
                    reason: 'gain editor',
                    isEntityUpdate: x.entityUpdate,
                    ignore: true,
                    applyTo: x.applyTo,
                });
            }
        });

        this.gainEditorDataSource.data.forEach((x) => {
            const original = this.initDataSource?.some((j) => new Date(j.timestamp).getTime() === new Date(x.timestamp).getTime() && j.applyTo === x.applyTo && j.editedValue === x.editedValue);
            let entityUpdate = this.initDataSource?.some((j) => new Date(j.timestamp).getTime() === new Date(x.timestamp).getTime() && j.applyTo === x.applyTo && j.editedValue !== x.editedValue);

            if (!original) {
                const date = new Date(x.timestamp);
                const index = Object.keys(EntityType).indexOf(x.applyTo);
                const entityVal = Object.values(EntityType)[index];
                const valIndex = this.gainEditorDataSource.data.indexOf(x);

                if(!entityUpdate && valIndex > 0)
                {
                    if(this.gainEditorDataSource.data[valIndex].applyTo === this.gainEditorDataSource.data[valIndex-1].applyTo)
                    {
                        entityUpdate = true;
                    }
                }

                const deletedData = this.updateGainPoints.filter(a=>a.timeStamp === formatDate(dateFormat)(date));
                if(deletedData && deletedData.length > 0)
                {
                    return;
                }

                if (date.getTime() < new Date().getTime()) {
                    this.updateGainPoints.push({
                        timeStamp: formatDate(dateFormat)(date),
                        reading: x.editedValue,
                        eid: entityVal,
                        reason: 'gain editor',
                        ignore: false,
                        isEntityUpdate: entityUpdate ? false: true,
                    });
                } else {
                    this.updateGainPoints.push({
                        timeStamp: formatDate(dateFormat)(date),
                        reading: x.editedValue,
                        eid: GAIN_EDITOR_ID,
                        reason: 'gain editor',
                        ignore: false,
                        isEntityUpdate: true,
                    });
                }
            }
        });

        const forceUpdateDateRanges: string[][] = [];

        // need to gather the date ranges for force update entries
        // if last entry is a force update, its end date will be null
        const forceUpdateValues = this.updateGainPoints.filter(v => v.isEntityUpdate);
        forceUpdateValues.forEach((item) => {
            const currentTs = item.timeStamp;
            const currentIndex = this.gainEditorDataSource.data.findIndex(v => v.timestamp === item.timeStamp);

            const forceDateRange = [currentTs];

            const nextEntry = this.gainEditorDataSource.data[currentIndex + 1];
            if (nextEntry) {
                forceDateRange.push(nextEntry.timestamp);
            } else {
                forceDateRange.push(null);
            }

            forceUpdateDateRanges.push(forceDateRange);
        });
        this.dataEditService.updateGainTable(
            this.dialogData.customerId, this.dialogData.locationDetails.locationID,
            this.gainPoints
        ).pipe(
            switchMap(() => {
                if (!this.updateGainPoints.length) {
                    this.isLoading = false;
                    this.checkUpdate = true;
                    this.cancelClose(this.checkUpdate);

                    return EMPTY;
                }

                const params: DataEditPreviewParams = {
                    dataEditType: this.dataEditType,
                    start: this.dateutilService.getLocalDateFromUTCDate(this.data.locationDetails.startDate).toJSON(),
                    end: this.dateutilService.getLocalDateFromUTCDate(this.data.locationDetails.endDate).toJSON(),
                    depthOnY: true,
                    bestFit: null,
                    temporaryFlags: null,
                    snapPoints: null,
                    updatePoints: this.updateGainPoints,
                    blockEntity: null,
                };

                return this.dataEditingService.gainTablePreview(
                    this.dialogData.customerId,
                    this.dialogData.locationDetails.locationID,
                    params,
                    this.data.isRawVelSelected ?? false,
                    forceUpdateDateRanges
                ).pipe(
                    catchError(() => {
                        this.isLoading = false;

                        this.snackBarService.raiseNotification(
                            this.translate.instant('COMMON.GAIN_PREVIEW_FAILED'),
                            this.translate.instant('COMMON.CLOSE'),
                            { panelClass: 'custom-error-snack-bar' },
                            false
                        );

                        console.error('Handled error, gain preview');
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                        return EMPTY;
                    })
                );
            }),
            switchMap(() =>
                this.dataEditingService.dataEditSubmit(this.dialogData.customerId, this.dialogData.locationDetails.locationID, GAIN_EDITOR_EDIT_REASON).pipe(
                    catchError(() => {
                        this.isLoading = false;

                        this.snackBarService.raiseNotification(
                            this.translate.instant('COMMON.GAIN_SUBMIT_FAILED'),
                            this.translate.instant('COMMON.CLOSE'),
                            { panelClass: 'custom-error-snack-bar' },
                            false
                        );

                        console.error('Handled error, gain submit');
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                        return EMPTY;
                    })
                )
            )
        ).subscribe((data) => {
            this.isLoading = false;
            this.checkUpdate = true;
            this.cancelClose(this.checkUpdate);
        });
    }

    public editItem(el, i) {
        this.addMore = true;
        this.enableSave = false;
        this.isOldRecord = this.isNewRecord = false;
        this.form.controls['timestamp'].setValue(new Date(el.timestamp));
        this.form.controls['editedValue'].setValue(el.editedValue);
        this.form.controls['applyTo'].setValue(el.applyTo);
        this.selectedEntity = el.applyTo;
        this.selectedDate = new Date(el.timestamp);
        this.selectedVal = i;
        this.editDic[el.timestamp] = i;

        if(this.originalSource$ && i >= this.originalSource$.length){
            this.isNewRecord = true;
        }

        if(i === 0 && !this.isNewRecord){
            this.isOldRecord = true;
        }
    }

    public deleteItem(el, i) {

        const oldestDate = formatDate(dateFormat)(this.originalSource$?.map(e => new Date(e.timestamp)).reduce(function (a, b) { return a < b ? a : b; }));
        const original = this.originalSource$ ? this.originalSource$[i]: null ;
        const updatedDataSourceRec = this.gainEditorDataSource && this.gainEditorDataSource.data ? this.gainEditorDataSource.data[i] : null;
        if(oldestDate === formatDate(dateFormat)(el.timestamp))
        {
            const dialogRef = this.dialog.open<ConfirmationDialogComponent,IComponentDialog,IComponentDialogConfirmationResult>(ConfirmationDialogComponent, {
                data: {
                    title: this.deleteConfirmationTitle,
                    message: this.deleteOldestRecordMsg,
                    cancelText: this.cancelMsg,
                    okText: this.okMsg,
                }
            });

            dialogRef.afterClosed().subscribe((res) => {
                    if (res.whichButtonWasPressed === ConfirmationButton.ok || res.whichButtonWasPressed === ConfirmationButton.cancel) {
                            this.table.renderRows();
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                    }
            });
        }
        else if (original && updatedDataSourceRec) {

            const index = this.gainEditorDataSource.data.indexOf(updatedDataSourceRec);

            if (index > 0 && original.applyTo === this.gainEditorDataSource.data[index-1].applyTo)
            {
                this.enableSave = true;
                this.gainEditorDataSource.data.splice(i, 1);
                this.originalSource$.splice(i, 1);
                this.table.renderRows();
                this.uiUtilsService.safeChangeDetection(this.cdr);
            }
            else
            {
                const dialogRef = this.dialog.open<ConfirmationDialogComponent,IComponentDialog,IComponentDialogConfirmationResult>(ConfirmationDialogComponent, {
                    data:
                    {
                        title: this.deleteConfirmationTitle,
                        message: this.editMsg,
                        cancelText: this.cancelMsg,
                        okText: this.okMsg,
                    }
                });

                dialogRef.afterClosed().subscribe((res) => {
                        if (res.whichButtonWasPressed === ConfirmationButton.ok) {
                            this.enableSave = true;
                            this.gainEditorDataSource.data.splice(i, 1);
                            this.originalSource$.splice(i, 1);
                            this.table.renderRows();
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                        }
                });
            }
        }
        else
        {
            this.gainEditorDataSource.data.splice(i, 1);
            this.table.renderRows();
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }

        this.checkIfGainEdited();
    }

    public cancelClose(update: boolean) {
        if (!this.formChanged || update) {
            this.dialogRef.close({success: update});

            return;
        }

        const title = this.translate.instant('COMMON.WARNING');
        const message = this.translate.instant('COMMON.VELOCITY_GAIN_LEAVE_WARNING');

        const dialogRef = this.dialog.open<ConfirmationDialogComponent,IComponentDialog,IComponentDialogConfirmationResult>(ConfirmationDialogComponent, {
            data: {
                title,
                dangerText: message,
                cancelText: this.cancelMsg,
                okText: this.okMsg,
            }
        });

        dialogRef.afterClosed().subscribe((res: IComponentDialogConfirmationResult) => {
            if (res && res.whichButtonWasPressed === ConfirmationButton.ok) {
                this.dialogRef.close({success: false});
            }
        });
    }

    exportDataToCsv() {
        const options = {
            headers: ['Date\\Time', 'Gain', 'Apply to', 'Modifed By', 'Last Modified On'],
            showLabels: true,
            showTitle: true,
            title: 'Velocity Gain',
        };

        const keys = ['timestamp', 'editedValue', 'applyTo', 'user', 'editDate'];
        const dateFormattedData = this.gainEditorDataSource.data.map((v) => ({
            ...v,
            timestamp: this.datePipe.transform(v.timestamp, this.customerDateFormat),
            editDate: this.datePipe.transform(v.editDate, this.customerDateFormat),
        }));
        const csvData = dateFormattedData.reduce((acc, curr) => {
            return [...acc, keys.map((key) => curr[key])];
        }, []);

        const result = new AngularCsv(csvData, 'Velocity Gain', options);
    }

    recalculateOpen() {
        // const { startDate, endDate, customerId, locationId } = this;
        const customerId = this.data.customerId;
        const locationId = this.data.locationDetails.locationID;
        let startDate = this.data.locationDetails.startDate;
        let endDate = this.data.locationDetails.endDate;
        const isRawVelRecalculated = true;

        if(!startDate) startDate = new Date();
        if(!endDate) endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59);

        const dialogOptions: MatDialogConfig = {
            disableClose: true,
            hasBackdrop: true,
            panelClass: 'no-padding-nor-overflow-relative-dialog',
            data: { startDate, endDate, locationId, customerId, isRawVelRecalculated }
        };
        this.dialog.open<RecalculateEntitiesComponent, RecalculateEntitiesComponentRequest, RecalculateEntitiesComponentResponse>(
            RecalculateEntitiesComponent, dialogOptions
        ).afterClosed().subscribe((confirmRes) => {
            if(!confirmRes.success) return;

            this.dialog.open<ConfirmationDialogComponent,IComponentDialog,IComponentDialogConfirmationResult>(ConfirmationDialogComponent, {
                data: {
                    title: this.recalcConfirmationTitle,
                    message: this.recalcMsg + ' ' + this.contMsg,
                    cancelText: this.cancelMsg,
                    okText: this.okMsg,
                }
            }).afterClosed().subscribe((res) => {
                if (res.whichButtonWasPressed === ConfirmationButton.ok) {
                    this.isLoading = true;
                    const { startDate, endDate, customerId, locationId } = confirmRes;

                    const isoStartDate = this.dateutilService.getEndDateAsTimeZone(startDate);
                    const start = isoStartDate.split('.')[0];
                    const end = this.dateutilService.getEndDateAsTimeZone(endDate);
                    this.isLoading = true;
                    this.dataEditService.recalculateRawVelocity(customerId, locationId, start, end).subscribe(
                        (res) => {
                            const response: VelocityGainTableComponentResponse = {
                                success: true,
                                data: res,
                                start: startDate,
                                end: endDate
                            }
                            this.dialogRef.close(response);
                        },
                        () => {
                            this.snackBarService.raiseNotification(
                                // this.translate.instant('COMMON.GAIN_PREVIEW_FAILED'),
                                'Failed to recalculate entities',
                                this.translate.instant('COMMON.CLOSE'),
                                { panelClass: 'custom-error-snack-bar' },
                                false
                            );
                            this.dialogRef.close({success: false});
                        },
                        () => {
                            this.isLoading = false;
                        }
                    );
                }
            });
        });
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
