<div class="locationDashbordGraphHeader">
    <mat-card-header>
        <div class="auto-margin">
            <span class="scatterGraphHint">{{ 'COMMON.SCATTERGRAPH_TEXT' | translate }}</span>
        </div>
        <div class="app-flex-filler"></div>
    </mat-card-header>
</div>

<app-loader [isLoading]="isLoading"></app-loader>
<mat-card-content>
    <div *ngIf="data; else noChartData">
        <div
            class="bestfit-option"
            *ngIf="annotationSettings.isBestFit && annotationSettings.isToleranceLines && bestFitCurve"
        >
            <div class="auto-margin bestFitRange">
                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.BEST_FIT_RANGE' | translate }}
                <mat-slider
                    [min]="minValue"
                    [max]="maxValue"
                    [(ngModel)]="rangeValue"
                    [step]="stepRange"
                    (input)="onRangeChanged($event)"
                ></mat-slider>
                {{ rangeValue | number: '.1-1' }}
            </div>
            <div class="auto-margin">
                <mat-form-field class="customFormWidth">
                    <mat-select
                        placeholder="Tolerance Position"
                        [(ngModel)]="selected"
                        (ngModelChange)="onRangeChanged()"
                    >
                        <mat-option [value]="1">
                            {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.BEST_FIT_ABOVE' | translate }}
                        </mat-option>
                        <mat-option [value]="2">
                            {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.BEST_FIT_BELOW' | translate }}
                        </mat-option>
                        <mat-option [value]="3">
                            {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.BEST_FIT_BOTH' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="pre-edit-controls auto-margin">
                <button mat-button class="lightBackGround" type="button" [disabled]="true">
                    <mat-icon class="mat-24">call_made</mat-icon>
                </button>
                <button mat-button class="lightBackGround" type="button" [disabled]="true">
                    <mat-icon class="mat-24">vertical_align_center</mat-icon>
                </button>
                <button mat-button class="lightBackGround" type="button" [disabled]="true">
                    <mat-icon class="mat-24">vibration</mat-icon>
                </button>
                <button mat-button class="lightBackGround" type="button" [disabled]="true">
                    <mat-icon class="mat-24">flag</mat-icon>
                </button>
                <button mat-button class="lightBackGround" type="button" [disabled]="true">
                    <mat-icon class="mat-24">outlined_flag</mat-icon>
                </button>
            </div>
        </div>
        <div class="dynamicGraph" [id]="scatterId">
            <div [chart]="scatterOptions" (load)="saveScatterChart($event.context)"></div>
        </div>
    </div>
    <ng-template #noChartData>
        <div class="notabledataFound custom-table print-nodata-space">
            <span>{{ 'LOCATION_DASHBOARD.NO_DATA_IN_GRAPH_MSG' | translate }}</span>
        </div>
    </ng-template>
</mat-card-content>
