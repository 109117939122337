import { Injectable } from '@angular/core';

@Injectable()
export class MathService {
    /**
     * Rounds a number based on the base and precision.
     * This is based on Mozilla's calculation.
     * @param subject The value that will be rounded.
     * @param base The optional base to which to round to, default to 10.
     * @param exponent The precision to which to round to, defaults to 2.
     */
    public round(subject: number, base?: number, exponent?: number): number {
        if (subject == undefined) {
            return;
        }

        // get precission
        const precisionFactor = Math.pow(base || 10, exponent || 2);

        // return result
        return Math.round(subject * precisionFactor) / precisionFactor;
    }

    // This method is used for returning the max value from an array.
    // Math.Max is crashing when parses big arrays (~10⁷ elements).
    public getMaxValueArray(value: any[]): any {
        let max = value[0];
        value.forEach(function (v, i, a) {
            if (v > max) {
                max = v;
            }
        });
        return max;
    }

    // This method is used for returning the max value from an array.
    // Math.Min is crashing when parses big arrays (~10⁷ elements).
    public getMinValueArray(value: any[]): any {
        let min = value[0];
        value.forEach(function (v, i, a) {
            if (v < min) {
                min = v;
            }
        });
        return min;
    }

    public sortArrayExceptOne(array: string[], exceptionalString: string): string[]
    {
        return array?.sort(function (a, b) { if(a === b) return 0; if (a === exceptionalString) return -1; if (b === exceptionalString) return 1; if (a < b) return -1; if (a > b) return 1; return 0; });
    }
}
