import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Inject,
    ChangeDetectorRef,
} from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Overrides } from 'app/shared/models/sliicer';
import { BasinStormSettings, StormEventAdjustment, StormSettings } from 'app/shared/models/sliicer/overrides';
import {
    EditStormEvents,
    ExpandedStormEvent,
    ExpandedStormEventWithPrecomp,
} from 'app/shared/models/sliicer/results/storm-events';
import { PrecompensationType } from 'app/shared/models/sliicer';
import { Regime } from 'app/shared/models/sliicer/settings';
import { DateutilService, TIME_12HOUR } from 'app/shared/services/dateutil.service';
import { DEFAULT_PRECOMPENSATION_LENGTH_MINUTES, SliicerService } from 'app/shared/services/sliicer.service';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { getSeasons, Season } from '../../../study-settings/seasons-settings/seasons-settings.utils';
import { StormSettingsDialogComponent } from '../storm-settings-dialog/storm-settings-dialog.component';
import { StormSettingsDialogData } from '../storm-settings-dialog/storm-settings-dialog.utils';
import { fromNullable } from 'fp-ts/es6/Option';
import { StormPeriodAdjustment } from '../../flow-monitor.model';
import { StormHelperService } from 'app/pages/sliicer/shared/services/stormHelper/storm-helper.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { Time } from 'app/shared/models/time';

const ACTION_BASE = 'BASE';
const ACTION_NEW = 'NEW';

export interface EditStormEventsDialogOutput {
    updated: boolean;
    overrides: Overrides;
}

export interface EditStormEventsDialogUpdateStatus {
    updateAddStormEvents: boolean;
    updateRemoveStormEvents: boolean;
    updateStormSettings: boolean;
    updateBasinStormSettings: boolean;
}

@Component({
    selector: 'app-edit-storm-events',
    templateUrl: './edit-storm-events.component.html',
    styleUrls: ['./edit-storm-events.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditStormEventsComponent implements OnInit {
    public action = ACTION_BASE;
    public dataSource = new MatTableDataSource<ExpandedStormEvent>();
    public stormEvents: Array<ExpandedStormEventWithPrecomp>;
    public stormEventsDisplayedColumns: Array<string> = stormEventsDisplayedColumns;
    public customerDateTimeFormat: string;
    public selectedStormEvent: Object = {};
    public newStormDate: Date;
    public newStormTime = '00:00:00';
    public timeFormat: number;
    public showLoader = false;
    public overrides: Overrides = {
        addedStormEvents: [],
        removedStormEvents: [],
        basinStormSettings: [],
        stormSettings: [],
    };

    public caseStudyStartDate: string;
    public caseStudyEndDate: string;
    public seasons: Season[];
    public regimes: Regime[];
    public defaultPrecomp: PrecompensationType;

    public subscriptions: Array<Subscription> = [];

    constructor(
        public dialogRef: MatDialogRef<EditStormEventsComponent>,
        private matDialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) private data: EditStormEvents,
        private dateutilService: DateutilService,
        private sliicerService: SliicerService,
        private stormHelper: StormHelperService,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
    ) { }

    public ngOnInit() {
        this.setOverrides(this.data);
        this.setStormEvents(this.data);
        this.customerDateTimeFormat = this.sliicerService.dateTimeFormatEditStorms();
        this.timeFormat = this.dateutilService.timeFormat.getValue() === TIME_12HOUR ? 12 : 24;

        this.subscriptions.push(
            this.sliicerService.studyDetailsData$.subscribe((study) => {
                this.caseStudyStartDate = (study.config && study.config.startDate) || '';
                this.caseStudyEndDate = (study.config && study.config.endDate) || '';

                this.newStormDate = new Date(this.caseStudyStartDate);

                this.seasons = getSeasons(study, true);
                this.regimes = study.settings ? study.settings.regimes : [];
                // this.altPrecomStart = study.settings ? study.settings.altPrecomStart : '';
                this.defaultPrecomp = study.settings ? study.settings.defaultPrecompType : null;
            }),
        );
    }

    public setAction(action: string) {
        if (this.action !== action) {
            switch (this.action) {
                case ACTION_NEW:
                    this.resetNewStorm();
                    break;
                case ACTION_BASE:
                default:
                    this.selectStormEvent({});
                    break;
            }
        }
        this.action = action;
    }

    public closeDialog(updateOverride?: EditStormEventsDialogUpdateStatus) {
        const closeDialogData: EditStormEventsDialogOutput = {
            updated: !updateOverride
                ? false
                : updateOverride.updateAddStormEvents ||
                updateOverride.updateRemoveStormEvents ||
                updateOverride.updateStormSettings ||
                updateOverride.updateBasinStormSettings,
            overrides: {},
        };

        if (updateOverride && updateOverride.updateAddStormEvents) {
            closeDialogData.overrides.addedStormEvents = this.overrides.addedStormEvents;
        }

        if (updateOverride && updateOverride.updateRemoveStormEvents) {
            closeDialogData.overrides.removedStormEvents = this.overrides.removedStormEvents;
        }

        if (updateOverride && updateOverride.updateStormSettings) {
            closeDialogData.overrides.stormSettings = this.overrides.stormSettings;
        }

        if (updateOverride && updateOverride.updateBasinStormSettings) {
            closeDialogData.overrides.basinStormSettings = this.overrides.basinStormSettings;
        }

        this.dialogRef.close(closeDialogData);
    }

    private static zeroPadNumber(input: number): string {
        if (!input || input < 0 || input > 59) {
            return '00';
        }
        if (input < 10) {
            return `0${input}`;
        }
        return `${input}`;
    }

    public setStormEventTime(time: Time): void {
        const h = EditStormEventsComponent.zeroPadNumber(time.hour);
        const m = EditStormEventsComponent.zeroPadNumber(time.minute);

        this.newStormTime = `${h}:${m}:00`;
    }

    public saveStormEvent(): void {
        const m = EditStormEventsComponent.zeroPadNumber(this.newStormDate.getMonth() + 1);
        const d = EditStormEventsComponent.zeroPadNumber(this.newStormDate.getDate());

        const maxStormId: number = Math.max.apply(
            Math,
            this.stormEvents.map((x) => x.stormId),
        );

        const isoString = `${this.newStormDate.getFullYear()}-${m}-${d}T${this.newStormTime}`;
        const newStormEvent: StormEventAdjustment = {
            stormStartTime: isoString,
            stormId: maxStormId + 1,
        };
        this.overrideStormEvent('addedStormEvents', newStormEvent);
        this.setStormEvents(this.data);
        this.setAction(ACTION_BASE);
    }

    public selectStormEvent(stormEvent: Object): void {
        if (this.selectedStormEvent['stormStartTime'] === stormEvent['stormStartTime']) {
            this.selectedStormEvent = {};
        } else {
            this.selectedStormEvent = stormEvent;
        }
    }

    public deleteStormEvent(stormStartTime: string): void {
        this.overrideStormEvent('removedStormEvents', { stormStartTime: stormStartTime, stormId: 0 });
        this.selectStormEvent({});
        this.setStormEvents(this.data);
    }

    public updateOverrides(): void {
        const caseStudy = this.sliicerService.caseStudyDetails.getValue();
        const updateOverrides: EditStormEventsDialogUpdateStatus = {
            updateAddStormEvents: false,
            updateRemoveStormEvents: false,
            updateBasinStormSettings: false,
            updateStormSettings: false,
        };

        const checkChangedNotExist = (cso: Overrides, to: Overrides, s: string): boolean => {
            return (!cso || !cso[s]) && to && to[s] && to[s].length > 0;
        };
        const checkChangedExist = (cso: Overrides, to: Overrides, s: string): boolean => {
            return cso && cso[s] && to && to[s] && !_.isEqual(to[s], cso[s]);
        };
        const checkChanged = (cso: Overrides, to: Overrides, s: string): boolean => {
            return checkChangedNotExist(cso, to, s) || checkChangedExist(cso, to, s);
        };

        if (checkChanged(caseStudy.overrides, this.overrides, 'addedStormEvents')) {
            updateOverrides.updateAddStormEvents = true;
        }

        if (checkChanged(caseStudy.overrides, this.overrides, 'removedStormEvents')) {
            updateOverrides.updateRemoveStormEvents = true;
        }

        if (checkChanged(caseStudy.overrides, this.overrides, 'stormSettings')) {
            updateOverrides.updateStormSettings = true;
        }

        if (checkChanged(caseStudy.overrides, this.overrides, 'basinStormSettings')) {
            updateOverrides.updateBasinStormSettings = true;
        }

        this.closeDialog(updateOverrides);
    }

    private checkOverrideStormEvent(key: string, stormData: StormEventAdjustment): void {
        if (this.overrides[key] && this.overrides[key].length > 0) {
            for (let i = 0; i < this.overrides[key].length; i++) {
                if (this.overrides[key][i]['stormStartTime'] === stormData['stormStartTime']) {
                    this.overrides[key].splice(i, 1);
                    i--;
                }
            }
        }
    }

    private overrideStormEvent(key: string, stormData: StormEventAdjustment): void {
        if (this.overrides[key] && !this.sliicerService.stormEventExists(this.overrides[key], stormData)) {
            this.overrides[key].push(stormData);
            let checkKey = '';
            switch (key) {
                case 'addedStormEvents':
                    checkKey = 'removedStormEvents';
                    break;
                case 'removedStormEvents':
                    checkKey = 'addedStormEvents';
                    break;
                default:
                    return;
            }
            this.checkOverrideStormEvent(checkKey, stormData);
        }
    }

    private setOverrides(data: EditStormEvents): void {
        for (const key in this.overrides) {
            if (data[key]) {
                this.overrides[key] = [...data[key]];
            }
        }
    }

    private setStormEvents(dialogData: EditStormEvents): void {
        const adjustedStormEvents = this.sliicerService.getAdjustedStormEvents(
            dialogData['stormEvents'],
            this.overrides['addedStormEvents'],
            this.overrides['removedStormEvents'],
        );
        this.stormEvents = adjustedStormEvents.map((s) => {
            const newS: ExpandedStormEventWithPrecomp = {
                ...s,
                precompValue:
                    (dialogData['basinStormResult'] &&
                        dialogData['basinStormResult']['stormId'] === s.stormId &&
                        dialogData['basinStormResult']['precompDuration']) ||
                    DEFAULT_PRECOMPENSATION_LENGTH_MINUTES,
            };
            return newS;
        });
        this.dataSource = new MatTableDataSource(
            this.getStormEventsData(this.stormEvents, this.data.basinStormSettings, this.data.stormSettings),
        );
    }

    private refreshTableData() {
        this.dataSource.data = this.getStormEventsData(
            this.stormEvents,
            this.data.basinStormSettings,
            this.data.stormSettings,
        );
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    private getStormEventsData(
        stormEvents: Array<ExpandedStormEventWithPrecomp>,
        basinStormSettings: Array<BasinStormSettings>,
        stormSettings: Array<StormSettings>,
    ): Array<ExpandedStormEventWithPrecomp> {
        return stormEvents.map((storm) => {
            if (!storm) return storm;
            const basinSetting = basinStormSettings.find(
                (x) => x.stormId === storm.stormId && x.basinName === this.data.selectedBasin,
            );
            const stormOverride = stormSettings.find((x) => x.stormId === storm.stormId);

            if (stormOverride && stormOverride.stormStartTime) {
                storm.stormStartTime = stormOverride.stormStartTime;
            }

            const stormDate = new Date(stormOverride ? stormOverride.stormStartTime : storm.stormStartTime);
            const year = this.sliicerService.hasYears() ? ' ' + this.sliicerService.yearFromDate(stormDate) : '';

            storm.seasonView =
                basinSetting && basinSetting.season
                    ? basinSetting.season + year
                    : storm.season && storm.season !== ''
                        ? storm.season + year
                        : '';
            storm.regimeView =
                basinSetting && basinSetting.regime
                    ? basinSetting.regime + year
                    : storm.regime && storm.regime !== ''
                        ? storm.regime + year
                        : '';

            storm.totalStormDuration = EditStormEventsComponent.GetTotalStormDuration(
                storm,
                stormOverride,
                basinSetting,
            );
            storm.totalStormDuration = storm.totalStormDuration / 60;

            return storm;
        });
    }

    private static GetTotalStormDuration(
        storm: ExpandedStormEventWithPrecomp,
        override?: StormSettings,
        basin?: BasinStormSettings,
    ): number {
        let totalStormDuration = 0;

        totalStormDuration +=
            override && override.stormPeriodLength ? override.stormPeriodLength : storm.stormPeriodLength;
        totalStormDuration +=
            override && override.recovery1PeriodLength ? override.recovery1PeriodLength : storm.recovery1PeriodLength;
        totalStormDuration +=
            override && override.recovery2PeriodLength ? override.recovery2PeriodLength : storm.recovery2PeriodLength;

        totalStormDuration += basin && basin.precompLength ? basin.precompLength : storm.precompValue;

        return totalStormDuration;
    }

    private resetNewStorm(): void {
        this.newStormDate = new Date(this.caseStudyStartDate);
        this.newStormTime = '00:00:00';
    }

    public stormSettingsMenu(storm: ExpandedStormEvent) {
        const bss = this.data.basinStormSettings.find(
            (x) => x.stormId === storm.stormId && x.basinName === this.data.selectedBasin,
        );
        const ss = this.data.stormSettings.find((x) => x.stormId === storm.stormId);

        const adjustment: StormPeriodAdjustment = {
            stormId: storm.stormId,
            precompPeriodLength: bss && bss.precompLength ? bss.precompLength : 1440,
            stormStartTime: ss && ss.stormStartTime ? ss.stormStartTime : storm.stormStartTime,
            stormPeriodLength: ss && ss.stormPeriodLength ? ss.stormPeriodLength : storm.stormPeriodLength,
            recovery1PeriodLength:
                ss && ss.recovery1PeriodLength ? ss.recovery1PeriodLength : storm.recovery1PeriodLength,
            recovery2PeriodLength:
                ss && ss.recovery2PeriodLength ? ss.recovery2PeriodLength : storm.recovery2PeriodLength,
            altPrecompStart:
                bss && bss.altPrecompStart ? bss.altPrecompStart : undefined,
            altPrecompEnd:
                bss && bss.altPrecompEnd ? bss.altPrecompEnd : undefined,
            deattachPrecomp:
                bss && !bss.deattachPrecomp ? bss.deattachPrecomp : true
        };

        this.matDialog
            .open<StormSettingsDialogComponent, StormSettingsDialogData, StormSettingsDialogData>(
                StormSettingsDialogComponent,
                {
                    data: {
                        adjustment: adjustment,
                        precompType: fromNullable(bss && bss.precompType ? bss.precompType : this.defaultPrecomp),
                        season: bss && bss.season ? bss.season : storm.season,
                        regime: bss && bss.regime ? bss.regime : storm.regime,
                        seasons: this.seasons,
                        regimes: this.regimes
                    },
                },
            )
            .afterClosed()
            .subscribe((res) => {
                if (!res) return;

                this.stormHelper.applyStormEdit(this.overrides, storm, res, this.data.selectedBasin);
                this.data.stormSettings = this.overrides.stormSettings;
                this.data.basinStormSettings = this.overrides.basinStormSettings;

                this.refreshTableData();
            });
    }

    public editStorm(storm: ExpandedStormEvent) {
        this.stormSettingsMenu(storm);
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((x) => x.unsubscribe());
        this.subscriptions = [];
    }
}

const totalStormDurationElements: Array<string> = [
    'precompValue',
    'stormPeriodLength',
    'recovery1PeriodLength',
    'recovery2PeriodLength',
];

const stormEventsDisplayedColumns: Array<string> = [
    'selectItem',
    'startDateTime',
    'totalStormDuration',
    'season',
    'regime',
    'actions',
];
