<div id="site-name-popover">
    <div class="title" [ngClass]="{
        'active-bg': details.status === locationStatusEnum.Active,
        'inactive-bg': details.status ===  locationStatusEnum.Inactive,
        'maintainance-bg': details.status ===  locationStatusEnum.Maintenance,
        'red-alarm-bg': currentAlarm && currentAlarm.state ===  0,
        'orange-alarm-bg': currentAlarm && currentAlarm.state ===  1,
        'green-alarm-ng': currentAlarm && currentAlarm.state === 2
    }">
        {{ details.locationName }}
    </div>
    <div class="content">

        <div class="content-item">
            <div *ngIf="details.serialNumber">
                <p class="label">{{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.SERIAL_NUMBER' | translate }}: </p>
                <p class="value"> {{ details.serialNumber || '-' }} </p>
            </div>
            <div *ngIf="details.connectionString && series !== seriesEnum.usgs && series?.toUpperCase()  !== seriesEnum.ForeSITE_UL.toUpperCase()">
                <p class="label">{{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.IP_ADDRESS' | translate }}:</p>
                <p class="value">{{ details.connectionString }}</p>
            </div>

            <div *ngIf="series === seriesEnum.usgs">
                <p class="label">{{ heightPlaceHolder }}:</p>
                <p class="value">{{ details.height | number : depthDecimal }}</p>
            </div>

            <div *ngIf="series?.toUpperCase()  === seriesEnum.ForeSITE_UL.toUpperCase() ">
                <p class="label">{{ heightPlaceHolder }}:</p>
                <p class="value">{{ details.range | number : depthDecimal }}</p>
            </div>
        </div>

        <div class="content-item">
            <div>
                <p class="label"> {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.TYPE_SHAPE' | translate }}: </p>
                <p class="value"> {{ installationDetails }} </p>
            </div>
            <div *ngIf="series === seriesEnum.echo">
                <p class="label"> {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.MANHOLE_DEPTH' | translate }} ({{details.depthUnit}}): </p>
                <p class="value"> {{ details.manholeDepth | number : depthDecimal }} </p>
            </div>
        </div>

        <!--fields for installation type pipe  -->
        <ng-container *ngIf="details.installationType === 'Pipe' && series !== seriesEnum.usgs">
            <div class="content-item">
                <div>
                    <p class="label">{{ pipeHeightPlaceHolder }}: </p>
                    <p class="value"> {{ details.height | number : depthDecimal }} </p>
                </div>
                <div>
                    <p class="label">{{ pipeWidthPlaceHolder }}:</p>
                    <p class="value">{{ details.width | number : depthDecimal }}</p>
                </div>
            </div>
        </ng-container>

        <!--fields for installation type flume  -->
        <ng-container *ngIf="details.installationType === 'Flume'">
            <div class="content-item">
                <div>
                    <p class="label">{{ flumeRangePlaceHolder }}: </p>
                    <p class="value"> {{ details.range | number : depthDecimal }} </p>
                </div>
                <div>
                    <p class="label">{{ capacityPlaceHolder }}:</p>
                    <p class="value">{{ details.capacity | number : flowDecimal }}</p>
                </div>
                <div>
                    <p class="label">{{ widthPlaceHolder }}:</p>
                    <p class="value">{{ details.width | number : depthDecimal }}</p>
                </div>
            </div>
        </ng-container>

        <!--fields for installation type Weir  -->
        <ng-container *ngIf="details.installationType === 'Weir'">
            <div class="content-item">
                <div>
                    <p class="label">{{ flumeRangePlaceHolder }}: </p>
                    <p class="value"> {{ details.range | number : depthDecimal }} </p>
                </div>
                <div>
                    <p class="label">{{ capacityPlaceHolder }}:</p>
                    <p class="value">{{ details.capacity | number : flowDecimal }}</p>
                </div>
                <div>
                    <p class="label">{{ heightPlaceHolder }}:</p>
                    <p class="value">{{ details.height | number : depthDecimal }}</p>
                </div>
            </div>


            <div class="content-item">
                <div>
                    <p class="label">{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.CONSTANT' | translate }}: </p>
                    <p class="value"> {{ details.coefficient }} </p>
                </div>
                <div>
                    <p class="label">{{ lengthPlaceHolder }}:</p>
                    <p class="value">{{ details.length | number : depthDecimal }}</p>
                </div>
                <div>
                    <p class="label">{{ breadthPlaceHolder }}:</p>
                    <p class="value">{{ details.breadth | number : depthDecimal }}</p>
                </div>
            </div>
        </ng-container>

        <!--fields for installation type channel  -->
        <ng-container *ngIf="details.installationType === 'Channel'">
            <div class="content-item" *ngIf="details.entries">
                <div *ngIf="channelHeight !== null">
                    <p class="label">{{ heightPlaceHolder }}: </p>
                    <p class="value"> {{ channelHeight | number : depthDecimal }} </p>
                </div>
            </div>
        </ng-container>

        <!--fields for installation type elevation  -->
        <ng-container *ngIf="details.installationType === INSTALLATION_TYPE.ELEVATION">
            <div class="content-item">
                <div>
                    <p class="label">{{ referencePointPlaceHolder }}: </p>
                    <p class="value"> {{ details.referencePoint | number : depthDecimal }} </p>
                </div>
                <div>
                    <p class="label">{{ datumPlaceHolder }}: </p>
                    <p class="value"> {{ details.datum }} </p>
                </div>
                <div>
                    <p class="label">{{ physicalOffsetPlaceHolder }}: </p>
                    <p class="value"> {{ details.physicalOffset }} </p>
                </div>
            </div>
        </ng-container>

    </div>
</div>