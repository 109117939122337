import { UsersService } from 'app/pages/admin/users.service';
import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    OnDestroy,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { SliicerMarketingScreenComponent } from '../sliicer-marketing-screen/sliicer-marketing-screen.component';
import { customerQueryParam } from 'app/shared/models/customer';
import { UsersInfo } from 'app/shared/models/users';

@Component({
    selector: 'ads-slicer',
    templateUrl: './sliicer-dashboard.component.html',
    styleUrls: ['./sliicer-dashboard.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliicerDashboardComponent implements OnInit, OnDestroy {
    public customerID: number;
    public slicerPagePermission: boolean;
    private subscriptions = new Array<Subscription>();
    public isLoading: boolean;
    public isBetaFlag: boolean;
    private isMarketingDialogOpen = false; // #22358 for tracking marketing window

    constructor(
        private statusCodeService: StatusCodeService,
        private router: Router,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private utilService: UiUtilsService,
        private cdr: ChangeDetectorRef,
        private locationGroupService: LocationGroupService,
        private usersService: UsersService,
    ) { }

    /**
     * Oninit lifecycle to check for customer change and getslicer permissions
     */
    public ngOnInit() {
        this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            this.isLoading = true;
            const currentCustomerID = Number(params.get(customerQueryParam));

            if (currentCustomerID !== this.customerID && currentCustomerID > 0) {
                this.customerID = currentCustomerID;
            }
            this.slicerPagePermission = false;
            this.getSlicerPermission();
            // disable location group dropdown when sliicerdashboard enabled
            this.locationGroupService.locationGroupDisabledStatus = true;
        });
    }


    /**
     * Method to check slicer feature permissions for customer or user to enable sliicer dashboard / sliicer marketing screen
     */
    public getSlicerPermission() {
        const sliicerPermissionsSub = this.usersService.isSliicerAllowed.subscribe((isAllowed) => {
            this.slicerPagePermission = isAllowed.value;
            // forced to false as per story #31787
            this.isBetaFlag = false;

            this.isLoading = false;
            this.utilService.safeChangeDetection(this.cdr);
            // on change of customer and if current customer/user selected does not have sliicer feature enabled then
            // redirect to home page and show marketing popup
            if (!this.slicerPagePermission) {
                if (this.router.url.indexOf('sliicer') > -1) {
                    this.router
                        .navigate(['/dashboard'], {
                            queryParams: {
                                c: this.customerID,
                            },
                        })
                        .then(() => {
                            // #22358 open if it is not already opened
                            if (!this.isMarketingDialogOpen) {
                                this.isMarketingDialogOpen = true;
                                this.dialog.open(SliicerMarketingScreenComponent, {
                                    disableClose: true,
                                }).afterClosed().subscribe(() => {
                                    this.isMarketingDialogOpen = false;
                                })
                            }
                        });
                }
            }
        });

        this.subscriptions.push(sliicerPermissionsSub);
    }

    public ngOnDestroy() {
        // enable location group drop down on leaving this component
        this.locationGroupService.locationGroupDisabledStatus = false;

        this.subscriptions.forEach(x => x.unsubscribe());
        this.subscriptions = [];
    }
}
