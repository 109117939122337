import { PrecompensationType } from 'app/shared/models/sliicer';

export class FlowTabDialogData {
    public basinName: string;
    public dryDaysAdjusted?: boolean;
    public stormExclusionsAdjusted?: boolean;
    public stormPeriodsAdjusted?: boolean;
}

export class StormInformation {
    public stormId: number;
    public stormDate: string;
    public excluded: boolean;
}

export class ExcludeStormChanged {
    public stormId: number;
    public excluded: boolean;
}

export class PrecompensationChanged {
    public stormId: number;
    public precompType: PrecompensationType;
}

export class StormPeriodAdjustment {
    public stormId?: number;
    public precompPeriodLength: number;
    public stormStartTime: string;
    public stormPeriodLength: number;
    public recovery1PeriodLength: number;
    public recovery2PeriodLength: number;
    public altPrecompStart?: string;
    public altPrecompEnd?: string;
    public deattachPrecomp?: boolean;
    public rainStartTime?: string;
    public rainEndTime?: string;

    public isCreateMode?: boolean;

    public previousStartTime?: string;
}

export class StormPeriodsMinMax {
    public precompDurationMin: number;
    public precompDurationMax: number;

    public stormStartMin: number;
    public stormStartMax: number;

    public recovery1DurationMin: number;
    public recovery1DurationMax: number;

    public recovery2DurationMin: number;
    public recovery2DurationMax: number;
}

export interface SeasonChange {
    stormId: number;
    season: string;
}

export interface RegimeChange {
    stormId: number;
    regime: string;
}
