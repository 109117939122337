import { Component, ViewEncapsulation, ChangeDetectionStrategy, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { FlowMonitoringConfigRequest } from 'app/shared/models/flow-monitoring-config';
import { ERROR_NAME_ALREADY_EXIST, FlowMonitoringService } from 'app/shared/services/flow-monitoring.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';

export enum FmrSaveConfigurationDialogComponentButtons {
    OK = 1,
    CANCEL = 2
}

export interface FmrSaveConfigurationDialogComponentResult {
    name?: string,
    button: FmrSaveConfigurationDialogComponentButtons,
    id: string
}

export interface FmrSaveConfigurationDialogComponentInput {
    name?: string,
    body: FlowMonitoringConfigRequest,
    customerId: number
}

@Component({
  selector: 'app-fmr-save-configuration-dialog',
  templateUrl: './fmr-save-configuration-dialog.component.html',
  styles: [
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FmrSaveConfigurationDialogComponent implements OnInit {

    public name: string;
    public body: FlowMonitoringConfigRequest;
    public customerId: number;

    translations = {
        dismissText: null,
        nameAlreadyExist: null,
        errorOnSave: null
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) data: FmrSaveConfigurationDialogComponentInput,
        private translate: TranslateService,
        private dialogRef: MatDialogRef<FmrSaveConfigurationDialogComponent>,
        private flowMonitoringService: FlowMonitoringService,
        private snackBarService: SnackBarNotificationService
    ) {
        if(data) {
            if(data.name) this.name = data.name;
            if(data.body) this.body = data.body;
            if(data.customerId) this.customerId = data.customerId;
        }
    }

    public applyTranslations() {
        const translateKeys: Array<string> = [
            'COMMON.DISMISS_TEXT',
            'COMMON.NAME_ALREADY_EXISTS',
            'COMMON.ERROR_ON_SAVE'
        ];

        this.translate.get(translateKeys).subscribe((translateValues) => {
            if (!translateValues) {
                return;
            }
            this.translations.nameAlreadyExist = translateValues['COMMON.NAME_ALREADY_EXISTS'];
            this.translations.errorOnSave = translateValues['COMMON.ERROR_ON_SAVE'];
            this.translations.dismissText = translateValues['COMMON.DISMISS_TEXT'];
        });
    }

    ngOnInit(): void {
        this.applyTranslations();
    }

    public onCancel() {
        this.dialogRef.close(<FmrSaveConfigurationDialogComponentResult>{
            button: FmrSaveConfigurationDialogComponentButtons.CANCEL
        });
    }

    public onSave() {
        const namesAreEqual = this.body && this.body.configName === this.name;

        if(!namesAreEqual) {
            this.body.id = undefined;
            this.body.configName = this.name;
        }

        this.flowMonitoringService.configurationsPost(this.customerId, this.body).subscribe(
            (res) => {
                this.dialogRef.close(<FmrSaveConfigurationDialogComponentResult>{
                    button: FmrSaveConfigurationDialogComponentButtons.OK,
                    name: this.name,
                    id: res.id
                });
            },
            (err) => {
                if(err.error === ERROR_NAME_ALREADY_EXIST) {
                    this.snackBarService.raiseNotification(this.translations.nameAlreadyExist, this.translations.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    }, true, 5000);
                } else {
                    this.snackBarService.raiseNotification(this.translations.errorOnSave, this.translations.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    }, true, 5000);
                }
            });
    }

}
