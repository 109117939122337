import { Injectable } from '@angular/core';
import {
    GraphConfiguration,
    GraphOptions,
} from 'app/pages/dashboards/custom-dashboard/custom-dashboard-model/custom-dashboard.model';
import * as Highcharts from 'highcharts';

import { ChartProperty } from '../components/hydro-graph';
import {
    BTY_LOW_ENTITY,
    CROSS_DEPTH_ENTITY,
    DEPTH,
    DEPTH_DISPLAY_GROUP,
    DEPTH_VELOCITY,
    FLOW,
    FLOW_DISPLAY_GROUP,
    OTHER,
    OTHER_DISPLAY_GROUP_CONST,
    PRESSK_DISPLAY_GROUP,
    RAIN,
    RAIN_DISPLAY_GROUP,
    RAIN_ENTITY,
    SCALAR,
    SCALAR_DISPLAY_GROUP,
    SILT_ENTITY,
    TEMPERATURE,
    TEMPERATURE_DISPLAY_GROUP,
    VELOCITY,
    VELOCITY_DISPLAY_GROUP,
    VOLTAGE_DISPLAY_GROUP,
    VOLTS,
} from '../constant';
import { DepthPoint, EntitySeries, YAxis } from '../models/view-data';
import { StringUtils } from '../utils/string-utils';
import { DateutilService } from './dateutil.service';
import { StatusCodeService } from './status-code.service';

const ENTITY_PROP_TYPE = 'Entites';

@Injectable()
export class HydrographService {
    public tooltipTimeFormat: string;
    public xAxisDateFormat: string;
    public customerUnit: string;
    constructor(public dateutilService: DateutilService, private statusCodeService: StatusCodeService) {}

    public prepareSingleGraphData(
        graphConfiguration: GraphConfiguration,
        autoCorrectedTitle: string,
        isDisplayAutoCorrectedData: boolean,
        unitOfMeasure = '',
    ) {
        if (!graphConfiguration) {
            return;
        }
        return this.prepareMultiGraphData([graphConfiguration], autoCorrectedTitle, isDisplayAutoCorrectedData, false);
    }

    public prepareMultiGraphData(
        graphConfigurations: Array<GraphConfiguration>,
        autoCorrectedTitle: string,
        isDisplayAutoCorrectedData: boolean,
        isMultipleOverlayEnabled: boolean,
    ): Array<GraphOptions> {
        if (!graphConfigurations || graphConfigurations.length === 0) {
            return;
        }

        const self = this;
        const graphOptions: Array<GraphOptions> = new Array<GraphOptions>(0);
        this.initializeTootipFormats();

        graphConfigurations.forEach((graphConfiguration) => {
            if (graphConfiguration.series) {
                const startDate = new Date(graphConfiguration.startDate);
                const endDate = new Date(graphConfiguration.endDate);
                const graphOption: GraphOptions = {
                    graphId: graphConfiguration.graphId,
                    name: graphConfiguration.name,
                    startDate: startDate,
                    endDate: endDate,
                };
                const selectedDisplayGroups: Array<number> = new Array<number>(0);
                graphConfiguration.series.forEach((s: any) => {
                    if (s && s.displayGroups && s.displayGroups.length) {
                        s.displayGroups.forEach((group) => {
                            if (selectedDisplayGroups.indexOf(group.id) === -1) {
                                selectedDisplayGroups.push(group.id);
                            }
                        });
                    }
                });

                const seriesData: Array<EntitySeries> = new Array<EntitySeries>(0);
                const isDataQualityEnabled = false;
                graphConfiguration.series.forEach((s) => {
                    if (!s) {
                        return;
                    }
                    const entitiesData: Array<EntitySeries> = new Array<EntitySeries>(0);
                    const dataAveraging = !graphConfiguration.dataAverage ? 0 : Number(graphConfiguration.dataAverage);
                    const seriesItems = this.prepareHydrographSeriesData(
                        s,
                        entitiesData,
                        autoCorrectedTitle,
                        dataAveraging,
                        isDisplayAutoCorrectedData,
                        selectedDisplayGroups,
                    );

                    if (seriesItems && seriesItems.length) {
                        seriesData.push(seriesItems[0]);
                    }
                });

                const yAxises = this.prepareChartYAxisSettings(
                    DEPTH_VELOCITY,
                    seriesData,
                    isDisplayAutoCorrectedData,
                    0,
                    selectedDisplayGroups,
                    false,
                );
                //  if there is only one axis on the chart, add a second one
                if (yAxises.length === 1) {
                    yAxises.push({
                        allowDecimals: false,
                        title: { text: ' ' },
                        opposite: true,
                        top: '0%',
                        height: '100%',
                        min: yAxises[0].min,
                        max: yAxises[0].max,
                        offset: 0,
                        tickAmount: 3,
                    });
                }
                this.statusCodeService.userInfoThemeBS.subscribe((response: boolean) => {
                    if (response) {
                        StringUtils.setHighChartTheme();
                    } else {
                        StringUtils.setHighchartWhiteTheme();
                    }
                });

                const xAxisLabelSettings = this.prepareXAxisLabels(startDate, endDate);
                const chartOptions: ChartProperty = {
                    enableExport: false,
                    chartTitle: null,
                    chartEvents: {
                        redraw: function () {
                            this.redrawCount++;
                        },
                        selection: function (event) {
                            if (event.resetSelection && this.xAxis) {
                                this.xAxis[0].setExtremes(startDate.getTime(), endDate.getTime(), true);
                            }
                        },
                    },
                    chartToolTipOption: {
                        hideDelay: 0,
                        enabled: true,
                        shared: true,
                        crosshairs: true,
                        useHTML: true,
                        split: false,
                        formatter: function () {
                            const tooltipFormatter = new Array<string>();
                            const xDate = new Date(this.points[0].x);
                            const date = Highcharts.dateFormat(
                                self.xAxisDateFormat + self.tooltipTimeFormat,
                                new Date(xDate.getTime() + xDate.getTimezoneOffset() * 60000).getTime(),
                            );
                            if (isMultipleOverlayEnabled) {
                                let tableFormat = `<b>${date}</b><div>`;
                                this.points.forEach((seriesPoint, index) => {
                                    if (seriesPoint.y != null && !seriesPoint.point.hideToolTip) {
                                        let point = `<span><span style="color: ${
                                            seriesPoint.series.color
                                        }">\u25CF</span>
                                            ${seriesPoint.series.options.locationName}: ${StringUtils.upperCase(
                                            seriesPoint.series.name,
                                        )}:
                                            <b> ${seriesPoint.y.toFixed(seriesPoint.series.options.precision)}</b>
                                            (${seriesPoint.series.options.unitOfMeasure})
                                        </span>`;
                                        if (index < this.points.length) {
                                            point += '<br/>';
                                        }
                                        tableFormat = tableFormat + point;
                                    }
                                });
                                tableFormat += '</div>';
                                tooltipFormatter.push(tableFormat);
                            } else {
                                let tableFormat = `<b>${date}</b>`;
                                for (const seriesPoint of this.points) {
                                    if (seriesPoint.y != null && !seriesPoint.point.hideToolTip) {
                                        const point = `<br /><span style="color: ${
                                            seriesPoint.series.color
                                        }">\u25CF</span>
                                        ${StringUtils.upperCase(seriesPoint.series.name)}:
                                        <b> ${seriesPoint.y.toFixed(
                                            seriesPoint.series.options.precision !== 0
                                                ? seriesPoint.series.options.precision
                                                : 2,
                                        )}
                                        </b> ${seriesPoint.series.options.unitOfMeasure}`;
                                        tableFormat += point;
                                    }
                                }
                                tooltipFormatter.push(tableFormat);
                            }
                            return tooltipFormatter;
                        },
                    },
                    chartPlotOptions: {
                        column: {
                            groupPadding: 0,
                            pointPadding: 0,
                            pointWidth: 1,
                            borderWidth: 0,
                            shadow: false,
                            states: {
                                hover: {
                                    enabled: true,
                                    color: '#ffff5a',
                                    lineWidth: 2,
                                    borderColor: '#ffff5a',
                                    borderWidth: 3,
                                },
                            },
                        },
                        line: {
                            lineWidth: 1.5,
                            marker: {
                                enabled: true,
                                radius: 0,
                            },
                            events: {
                                legendItemClick: (event) => {
                                    //     // rescale hydrograph when user toggles visibility of series from legend
                                    //     self.reScaleHydrographEntities(entity, entityData, event.target.name,
                                    //         event.target.data && event.target.data.length > 0 && event.target.visible);
                                    //     if (this.annotationSettings.isDataQuality) {
                                    //         return false;
                                    //     }
                                },
                            },
                        },
                        series: {
                            borderWidth: 0.0,
                            turboThreshold: 1000000000,
                            label: { enabled: false },
                            // events: {
                            //     mouseOver: function (event) {
                            //         this.options.lineWidth = 3;
                            //     },
                            //     mouseOut: function () {
                            //         this.options.lineWidth = 1;
                            //     }
                            // },
                            // point: {
                            //     events: {
                            //         mouseOver: (event) => {
                            //             if (entity === DEPTH_VELOCITY) {
                            //                 this.viewDataService.corelateAdvancedGraphs(event.target.series.chart.container, event.target.x);
                            //             }
                            //         },
                            //         drop: (e) => {
                            //             console.log(e);
                            //         },
                            //         mouseOut: (event) => {
                            //             if (entity === DEPTH_VELOCITY) {
                            //                 this.viewDataService.setChartParameterAdvanced(event.target.x, 1);
                            //             }
                            //         },
                            //         click: (event) => {
                            //             if (event) {
                            //                 // mark or unmark the point as destination if copy/paste mode enabled else continue highlighting data editing point
                            //                 if (self.copyPasteOperation && event.point.series.options.id === self.destinationCopyPasteEntity) {
                            //                     if (self.copyPasteSelectedTimeSpan['destinationTimeStamp'] > 0) {
                            //                         self.unMarkDestinationPoint();
                            //                     }
                            //                     self.startDate = new Date(event.point.options.x + new Date(event.point.options.x).getTimezoneOffset() * 60000);
                            //                     self.validateDestinationCopyTime();
                            //                     self.markDestinationCopyPastePoint();
                            //                     self.uiUtilService.safeChangeDetection(self.changeDetectorRef);
                            //                     return;
                            //                 } else if (self.copyPasteOperation) {
                            //                     return;
                            //                 }
                            //                 if (event.point.series.name === UNIDEPTH || event.point.series.name === VELOCITY
                            //                     || event.point.series.name === RAIN || event.point.series.name === TEMP) {
                            //                     this.dataEditRowSelected = {
                            //                         timeStampSelected: event.point.options.x,
                            //                         rawDataSelected: event.point.options.y,
                            //                         entity: event.point.series.name
                            //                     };
                            //                     this.viewDataService.notifyDataEditingTable.next(this.dataEditRowSelected);
                            //                 }
                            //             }
                            //         }
                            //     }
                            // },
                            allowPointSelect: true,
                            marker: {
                                states: {
                                    select: {
                                        fillColor: undefined,
                                        radius: 6,
                                        lineWidth: 0,
                                    },
                                    hover: {
                                        enabled: true,
                                    },
                                },
                            },
                            states: {
                                hover: {
                                    enabled: true,
                                    lineWidth: 3,
                                },
                            },
                        },
                    },
                    chartXAxis: {
                        min: Date.UTC(
                            startDate.getFullYear(),
                            startDate.getMonth(),
                            startDate.getDate(),
                            startDate.getHours(),
                            startDate.getMinutes(),
                            startDate.getMilliseconds(),
                        ),
                        max: Date.UTC(
                            endDate.getFullYear(),
                            endDate.getMonth(),
                            endDate.getDate(),
                            endDate.getHours(),
                            endDate.getMinutes(),
                            endDate.getMilliseconds(),
                        ),
                        tickInterval: xAxisLabelSettings.interval,
                        dateTimeLabelFormats: xAxisLabelSettings.dateFormat,
                        events: {
                            setExtremes: function (e) {
                                /*if (typeof e.min === 'undefined' && typeof e.max === 'undefined') {
                                    self.viewDataService.resetZoomClicked.next(true);
                                } else {
                                    self.viewDataService.resetZoomClicked.next(false);
                                }*/
                                self.onXAxisZoomChange(e, self, false);
                                /*if (!self.disableFilters) {
                                    let minDate = Date.UTC(self.startDate.getFullYear(), self.startDate.getMonth(),
                                    self.startDate.getDate(), self.startDate.getHours(), self.startDate.getMinutes(),
                                    self.startDate.getMilliseconds());
                                    let maxDate = self.utcGraphDate;
                                    if ((e.max !== maxDate && e.min === minDate) || (e.max === maxDate && e.min !== minDate)
                                    || (e.max !== maxDate && e.min !== minDate)) {
                                        self.viewDataService.highlightAdvancedScatterChartPoints({
                                            'minValue': e.min,
                                            'maxValue': e.max
                                        });
                                    } else {
                                        self.viewDataService.highlightAdvancedScatterChartPoints(null);
                                    }
                                }*/
                            },
                        },
                        labels: {
                            useHTML: true,
                            enabled: true,
                            y: 40,
                            formatter: function () {
                                const currentLabelValue = Highcharts.dateFormat('%e. %b', this.value);
                                if (currentLabelValue == this.axis.defaultLabelFormatter.call(this)) {
                                    return `<span style = "font-weight: bold">${this.axis.defaultLabelFormatter.call(
                                        this,
                                    )} </span>`;
                                } else {
                                    return this.axis.defaultLabelFormatter.call(this);
                                }
                            },
                        },
                        ordinal: false,
                        gridLineWidth: 1,
                        /*plotBands: [
                            {
                                color: this.colors.colorHydPlotBandCursorBefore,
                                from: 0,
                                to: 0,
                                id: 'plotBandCursorWormBefore'
                            },
                            {
                                color: this.colors.colorHydPlotBandCursorAfter,
                                from: 0,
                                to: 0,
                                id: 'plotBandCursorWormAfter'
                            }
                        ],*/
                        type: 'datetime',
                    },
                    chartYAxis: yAxises,
                    chartScrollBarOptions: {
                        enabled: true,
                        liveRedraw: false,
                    },
                    showCredits: false,
                    showNavigator: true,
                    showZoom: true,
                    chartId: graphConfiguration.graphId,
                    showCustomLegend: true,
                    chartCustomLegend: {},
                };

                chartOptions.chartCustomLegend = this.prepareHydrographLegend(
                    isMultipleOverlayEnabled,
                    isDataQualityEnabled,
                );
                chartOptions.chartSeriesData = seriesData;

                if (chartOptions && chartOptions.chartSeriesData[0]) {
                    chartOptions.chartSeriesData[0].chartPlotOptions = { showInNavigator: true };
                }

                // // TODO: depth/velocity entities add annotations series
                // if (this.annotationsValues && this.annotationsValues.length > 0 && entity === DEPTH_VELOCITY) {
                //     this.options.series = this.options.series.concat(this.annotationsValues);
                // }

                if (chartOptions.chartSeriesData.length && !chartOptions.chartSeriesData[0].dashStyle) {
                    // Code to plot the AutoCorrected Series and Plotbands in case Auto Corrected setting is enabled
                    if (isDisplayAutoCorrectedData) {
                        seriesData
                            .filter((e) => e.correctedData)
                            .forEach((correctedSeries) => {
                                chartOptions.chartSeriesData = chartOptions.chartSeriesData.concat(
                                    correctedSeries.correctedData,
                                );
                            });
                    }

                    graphOption.options = chartOptions;
                    graphOptions.push(graphOption);
                }
            }
        });
        return graphOptions;
    }

    // Displays the Hydrograph from entity information
    public prepareHydrographSeriesData(
        graphConfiguration: any,
        entitiesData: Array<EntitySeries>,
        autoCorrectTitle: string,
        dataAverage: number,
        isDisplayAutoCorrectedData: boolean,
        selectedDisplayGroups: Array<number>,
    ): any {
        const seriesData: Array<EntitySeries> = new Array<EntitySeries>(0);
        for (const displayGroup of graphConfiguration.displayGroups) {
            const isDataQuality = false;

            this.processHydrographEntityData(
                graphConfiguration.compactData,
                entitiesData,
                graphConfiguration.entityIds,
                displayGroup,
                [],
                isDisplayAutoCorrectedData,
                dataAverage,
                graphConfiguration.color,
                graphConfiguration.title,
                autoCorrectTitle,
            );

            const timeFormat = this.tooltipTimeFormat === 'h:mm:ss tt' ? ' %l:%M %p' : ' %H:%M';
            const series = this.initializeChartSettings(
                displayGroup,
                entitiesData,
                this.xAxisDateFormat,
                timeFormat,
                isDataQuality,
                selectedDisplayGroups,
            );
            if (series) {
                seriesData.push(...series);
            }
        }
        return seriesData;
    }
    // tslint:disable-next-line: cyclomatic-complexity
    private processHydrographCompactData(
        currentEntityId: number,
        entityIds: Array<number>,
        displayGroup,
        compactData: any,
        annotationSettings,
        dataAveragingInterval: number,
        isDisplayAutoCorrectedData: boolean,
        isRefreshOnEditing = false,
    ) {
        const selectedEntity = entityIds.indexOf(currentEntityId);
        const autoCorrectedEntity = entityIds.indexOf(currentEntityId + 10000);
        const entities = [];
        const autoCorrectedData = [];
        let depthPoint: DepthPoint;
        let correctedPoint: DepthPoint;
        let compactEntityData: string[];
        let compactAutoCorrectedData: string[];
        let index = 0;

        const plotband = {
            color: '',
            fxgrom: null,
            to: null,
            id: 'plotBandAnnomalies',
        };
        // tslint:disable-next-line:forin
        for (const prop in compactData) {
            /* Hydrograph Optimization - Get the Response String having the same entity as display group selected */
            compactEntityData = String(compactData[prop])
                .slice(1, String(compactData[prop]).length - 1)
                .split(';')
                .filter((element) => element.split(':')[0] === selectedEntity.toString());

            /* Hydrograph Optimization - Get the Response String having the same entity as display group selected */
            if (isDisplayAutoCorrectedData) {
                compactAutoCorrectedData = String(compactData[prop])
                    .slice(1, String(compactData[prop]).length - 1)
                    .split(';')
                    .filter((element) => element.split(':')[0] === autoCorrectedEntity.toString());
            }

            let compactValue: string;
            let qValue: any;
            if (compactEntityData && compactEntityData.length) {
                /* Means that the entity is flagged */
                if (
                    compactEntityData &&
                    String(compactEntityData).split(':')[1] &&
                    String(compactEntityData).split(':')[1].indexOf(',') > 0
                ) {
                    compactValue = String(compactEntityData)
                        .split(':')[1]
                        .split(',')[0]
                        .slice(1, String(compactEntityData).split(',')[0].length);

                    qValue = String(compactEntityData).split(':')[1].split(',')[1];
                } else {
                    compactValue = String(compactEntityData).split(':')[1];
                    qValue = 15;
                }

                if (compactEntityData && String(compactEntityData).split(':')[2] && !isRefreshOnEditing) {
                    depthPoint = {
                        x: null,
                        y: null,
                    };
                } else if (compactEntityData && String(compactEntityData).split(':')[2] && isRefreshOnEditing) {
                    depthPoint = {
                        x: new Date(Number(prop)).getTime() * 1000,
                        y: this.prepareCorrectedData(String(compactEntityData).split(':')[1])
                            ? parseFloat(this.prepareCorrectedData(String(compactEntityData).split(':')[1]))
                            : 0,
                        color: '#FF3F33',
                        segmentColor: '#FF3F33',
                    };
                } else {
                    depthPoint = {
                        x: new Date(Number(prop)).getTime() * 1000,
                        y: this.prepareCorrectedData(String(compactEntityData).split(':')[1])
                            ? parseFloat(this.prepareCorrectedData(String(compactEntityData).split(':')[1]))
                            : 0,
                    };
                }

                if (annotationSettings && annotationSettings.isDataQuality) {
                    if (displayGroup.id !== RAIN_DISPLAY_GROUP && displayGroup.id !== VOLTAGE_DISPLAY_GROUP) {
                        depthPoint.segmentColor = this.dataQualityColor(qValue);
                    } else {
                        depthPoint.color = this.dataQualityColor(qValue);
                    }
                }

                if (isDisplayAutoCorrectedData) {
                    if (compactAutoCorrectedData) {
                        /* Means that the entity is having Q- Value not Good */
                        if (
                            String(compactAutoCorrectedData).split(':')[1] &&
                            String(compactAutoCorrectedData).split(':')[1].indexOf(',') > 0
                        ) {
                            compactValue = String(compactAutoCorrectedData)
                                .split(':')[1]
                                .split(',')[0]
                                .slice(1, String(compactAutoCorrectedData).split(',')[0].length);

                            qValue = String(String(compactAutoCorrectedData).split(':')[1].split(',')[2]).slice(0, -1);
                        } else {
                            compactValue = String(compactAutoCorrectedData).split(':')[1];
                            qValue = 15;
                        }

                        correctedPoint = {
                            x: new Date(Number(prop)).getTime() * 1000,
                            y: this.prepareCorrectedData(String(compactAutoCorrectedData).split(':')[1])
                                ? parseFloat(this.prepareCorrectedData(String(compactAutoCorrectedData).split(':')[1]))
                                : 0,
                        };

                        if (annotationSettings && annotationSettings.isDataQuality) {
                            correctedPoint.segmentColor = this.dataQualityColor(qValue);
                        }
                    }

                    depthPoint['index'] = index;

                    /* If the entity data is equal to the Auto Corrected data - means no correction is required */

                    if (depthPoint['y'] === correctedPoint['y'] || correctedPoint['y'] === 0) {
                        correctedPoint['y'] =
                            autoCorrectedData.length > 0 &&
                            autoCorrectedData[index - 1] &&
                            autoCorrectedData[index - 1]['marker']['enabled']
                                ? depthPoint['y']
                                : null;

                        correctedPoint['marker'] = {
                            enabled: false,
                            states: {
                                hover: {
                                    enabled: false,
                                },
                            },
                        };
                        correctedPoint['hideToolTip'] = true;
                        correctedPoint['locationName'] = '';
                        correctedPoint['index'] = index;
                        this.addPlotBands(correctedPoint, plotband);
                    } else {
                        /* If the entity data is not equal to the Auto Corrected data - means correction is required */
                        const marker = {
                            symbol: 'circle',
                            radius: 4,
                            enabled: true,
                        };
                        depthPoint['marker'] = marker;
                        correctedPoint['marker'] = marker;

                        correctedPoint['index'] = index;
                        correctedPoint['locationName'] = '';

                        /*check if previous point was Depth Point then replace its null value from the series to the actual value*/
                        if (
                            autoCorrectedData &&
                            autoCorrectedData.length &&
                            autoCorrectedData[index - 1] &&
                            !autoCorrectedData[correctedPoint['index'] - 1]['marker']['enabled']
                        ) {
                            plotband['from'] = autoCorrectedData[correctedPoint['index'] - 1]['x'];
                            autoCorrectedData[correctedPoint['index'] - 1]['y'] =
                                entities[correctedPoint['index'] - 1]['y'];
                        }
                    }
                }

                if (depthPoint) {
                    entities.push(depthPoint);
                }

                if (
                    displayGroup.id !== VOLTAGE_DISPLAY_GROUP &&
                    currentEntityId !== SILT_ENTITY &&
                    dataAveragingInterval === 0
                ) {
                    StringUtils.addDataGaps(entities, index);
                }

                if (correctedPoint) {
                    autoCorrectedData.push(correctedPoint);
                }
                index++;
            }
        }
        return { Entites: entities, AutoCorrectedData: autoCorrectedData };
    }

    private processHydrographEntityData(
        compactData,
        entitiesData,
        entityIds,
        displayGroup,
        plotBands,
        isDisplayAutoCorrectedData: boolean,
        dataAveragingInterval: number,
        seriesColor: string,
        locationName: string,
        autoCorrectTitle: string,
    ) {
        for (const entityInformation of displayGroup.entities) {
            if (!entitiesData.some((k) => k.id === entityInformation.id)) {
                /* The Corrected Series represents the series of Auto-Corrected Points*/
                const correctedSeries = <EntitySeries>{
                    id: entityInformation.id + 10000,
                    name: `${autoCorrectTitle} ${entityInformation.name} `,
                    showInLegend: false,
                    displayGroupID: displayGroup.id,
                    unitOfMeasure: displayGroup.unit,
                    style: displayGroup.style,
                    data: [],
                    color: '#DAA520',
                    precision: displayGroup.precision,
                    axis: {
                        minimum: 0,
                        maximum: 10,
                    },
                    type: 'line',
                    dashStyle: 'longdash',
                    dataGrouping: { enabled: false },
                    zIndex: 5,
                    lineWidth: 1.5,
                    yAxis: 0,
                    step: false,
                    connectNulls: false,
                    plotBands: new Array<Object>(),
                    turboThreshold: 1000000000,
                    locationName: locationName,
                };

                const entityInformationData = <EntitySeries>{
                    id: entityInformation.id,
                    name: entityInformation.name,
                    showInLegend: null,
                    displayGroupID: displayGroup.id,
                    unitOfMeasure: displayGroup.unit,
                    style: displayGroup.style,
                    data: [],
                    color: seriesColor,
                    precision: displayGroup.precision,
                    axis: {
                        minimum: 0,
                        maximum: 10,
                        label: `${displayGroup.label} (${displayGroup.unit})`,
                    },
                    type:
                        entityInformation.id === 2123 || entityInformation.id === 4902 || displayGroup.id === 8
                            ? 'column'
                            : 'coloredline',
                    dataGrouping: { enabled: false },
                    zIndex: 5,
                    lineWidth: 1.5,
                    yAxis: 0,
                    step: false,
                    connectNulls: false,
                    locationName: locationName,
                };

                const entityDataObject = this.processHydrographCompactData(
                    entityInformation.id,
                    entityIds,
                    displayGroup,
                    compactData,
                    displayGroup.annotations,
                    dataAveragingInterval,
                    isDisplayAutoCorrectedData,
                );

                for (const prop in entityDataObject) {
                    if (prop === ENTITY_PROP_TYPE) {
                        entityInformationData.data = entityDataObject[prop];
                    } else {
                        correctedSeries.data = entityDataObject[prop];
                    }
                }

                if (correctedSeries && correctedSeries.data && correctedSeries.data.length) {
                    entityInformationData.correctedData = entityInformationData.correctedData || <EntitySeries>{};
                    entityInformationData.correctedData.plotBands = [...plotBands];
                    plotBands.length = 0;
                }

                //  do not display in the legend if the data quality annotation is on and it's a column graph type
                entityInformationData.showInLegend =
                    entityInformationData.data.length &&
                    !(
                        displayGroup.annotations.isDataQuality &&
                        (entityInformationData.id === RAIN_ENTITY || entityInformation.id === 4902)
                    );

                entitiesData.push(entityInformationData);
            }
        }
    }

    private initializeChartSettings(
        displayGroup,
        entitiesSeries: EntitySeries[],
        xAxisDateFormat: string,
        tooltipTimeFormat: string,
        isDataQuality: boolean,
        selectedDisplayGroups: Array<number>,
    ): Array<EntitySeries> {
        const seriesData: Array<EntitySeries> = new Array<EntitySeries>(0);
        entitiesSeries.forEach((entity: EntitySeries) => {
            let axisProperty = {
                topOffSet: 0,
                value: 0,
            };
            /*
                Standard Depth Entities (2)
                Standard Velocity Entities (5)
                Standard PressK Entities (11)
            */
            if (
                (entity.displayGroupID === DEPTH_DISPLAY_GROUP ||
                    entity.displayGroupID === VELOCITY_DISPLAY_GROUP ||
                    entity.displayGroupID === PRESSK_DISPLAY_GROUP) &&
                seriesData
            ) {
                // && depthVelocityEntitySeries
                axisProperty = this.prepareChartYAxisPositions(entity.displayGroupID, selectedDisplayGroups);
                entity.yAxis = axisProperty.value;
                // flowRainEntitySeries.push(entity);
                seriesData.push(entity);

                /*
                    Standard Flow Entities (6)
                    Standard Rain Entities (3)
                */
            } else if (
                (entity.displayGroupID === FLOW_DISPLAY_GROUP || entity.displayGroupID === RAIN_DISPLAY_GROUP) &&
                seriesData
            ) {
                //  && flowRainEntitySeries
                entity.yAxis = this.prepareChartYAxisPositions(entity.displayGroupID, selectedDisplayGroups).value;
                // flowRainEntitySeries.push(entity);
                seriesData.push(entity);

                /*  Standard Temperature Entities (7)
                    Standard Battery Voltage Entities (8)
                */
            } else if (
                (entity.displayGroupID === TEMPERATURE_DISPLAY_GROUP ||
                    entity.displayGroupID === VOLTAGE_DISPLAY_GROUP) &&
                seriesData
            ) {
                //  && tempVoltageEntitySeries
                entity.yAxis = this.prepareChartYAxisPositions(entity.displayGroupID, selectedDisplayGroups).value;
                // tempVoltageEntitySeries.push(entity);
                seriesData.push(entity);

                /*
                    Standard Sample Entities (9)
                    Standard Rain Intensity Entities (4)
                    Standard Total Flow Entities (22)
                    Scalar(1) & Others
                */
            } else if (seriesData) {
                // scalarOtherEntitySeries
                entity.yAxis = this.prepareChartYAxisPositions(entity.displayGroupID, selectedDisplayGroups).value;
                seriesData.push(entity);
            }
        });

        //  create series for entity if there is no data for it
        // displayGroup.entities.forEach(entity => {
        //     let entityInformationData = <EntitySeries>{
        //         name: entity.name,
        //         id: entity.id,
        //         displayGroupID: entity.displayGroup,
        //         type: entity.entityId === 2123 ? '' : 'line',
        //         data: [],
        //         showInLegend: !isDataQuality,
        //         color: '#000',
        //         dataGrouping: { enabled: false },
        //         zIndex: 5,
        //         lineWidth: 0,
        //         unitOfMeasure: null,
        //         precision: null,
        //         axis: { minimum: 0, maximum: 10, label: null },
        //         yAxis: 0,
        //         step: false,
        //         marker: {
        //             enabled: true
        //         },
        //         connectNulls: false,
        //         // locationName: this.locationName
        //     };

        //     let nonOtherEntities = [2, 5, 11, 6, 3, 7, 8];

        //     //// check to add other entities selected via entities dropdown to resepective
        //     if ((entity.displayGroup === DEPTH_DISPLAY_GROUP || entity.displayGroup === VELOCITY_DISPLAY_GROUP
        //         || entity.displayGroup === PRESSK_DISPLAY_GROUP)
        //         && entity.entityId !== 1707
        //         && !allSeriesData.DepthVelocity.some(x => x.id === entity.entityId)) {
        //         entityInformationData.yAxis = this.prepareChartYAxisPositions(entity.displayGroup, selectedDisplayGroups);
        //         allSeriesData.DepthVelocity.push(entityInformationData);
        //     } else if ((entity.displayGroup === FLOW_DISPLAY_GROUP || entity.displayGroup === RAIN_DISPLAY_GROUP)
        //         && !allSeriesData.FlowRain.some(x => x.id === entity.entityId)) {
        //         entityInformationData.yAxis = this.prepareChartYAxisPositions(entity.displayGroup, selectedDisplayGroups);
        //         allSeriesData.FlowRain.push(entityInformationData);
        //     } else if ((entity.displayGroup === TEMPERATURE_DISPLAY_GROUP || entity.displayGroup === VOLTAGE_DISPLAY_GROUP)
        //         && !allSeriesData.TemperatureVoltage.some(x => x.id === entity.entityId)) {
        //         entityInformationData.yAxis = this.prepareChartYAxisPositions(entity.displayGroup, selectedDisplayGroups);
        //         allSeriesData.TemperatureVoltage.push(entityInformationData);
        //     } else if (!nonOtherEntities.some(x => x === entity.displayGroup)) {
        //         if (!allSeriesData.ScalarOther.some(x => x.id === entity.entityId)) {
        //             entityInformationData.yAxis = this.prepareChartYAxisPositions(entity.displayGroup, selectedDisplayGroups);
        //             // if (allSeriesData.TemperatureVoltage && allSeriesData.TemperatureVoltage.length > 0) {
        //             //     entityInformationData.yAxis = this.prepareChartYAxisPositions(entity.displayGroup, selectedDisplayGroups);
        //             // } else {
        //             //     entityInformationData.yAxis = this.prepareChartYAxisPositions(entity.displayGroup, selectedDisplayGroups);
        //             // }
        //             allSeriesData.ScalarOther.push(entityInformationData);
        //         } else {
        //             if (allSeriesData.TemperatureVoltage && allSeriesData.TemperatureVoltage.length) {
        //                 allSeriesData.ScalarOther.find(x => x.id === entity.entityId).yAxis =
        //                     this.prepareChartYAxisPositions(entity.displayGroup, selectedDisplayGroups);
        //             }
        //         }
        //     }
        // });

        // let graphData = [];
        // for (let key in allSeriesData) {
        //     if (allSeriesData.hasOwnProperty(key)) {
        //         if (key === DEPTH_VELOCITY || key === FLOW_RAIN || key === TEMP_VOLTAGE || key === SCALAR_OTHER) {
        //             graphData = graphData.concat(allSeriesData[key]);
        //         }
        //     }
        // }

        return seriesData;
    }

    // Method sets the lenend for the hydrograph
    private prepareHydrographLegend(isMultipleOverlayEnabled: boolean, isDataQualityEnabled = false) {
        //  display data quality legend if data quality view is selected
        if (isDataQualityEnabled) {
            const dataQualityColors: Array<string> = [
                '#FF0000',
                '#FFFF00',
                '#FF00FF',
                '#0000ff',
                '#808000',
                '#008080',
                '#000080',
                '#800000',
                '#008000',
                '#00ff00',
            ];
            return {
                useHTML: true,
                align: 'center',
                verticalAlign: 'bottom',
                layout: 'horizontal',
                itemMarginBottom: -30,
                symbolWidth: 0,
                labelFormatter: function () {
                    if (this.index === 0) {
                        let legendHtml = '<table><tr>';
                        let colorRow = '<tr>';
                        dataQualityColors.forEach((color: string, i: number) => {
                            legendHtml += `<td style="background: ${color}; padding: 7px;"></td>`;
                            colorRow += `<td style="padding: 6px;">${i + 1}</td>`;
                        });
                        legendHtml += `</tr>${colorRow}</tr><table>`;
                        return legendHtml;
                    }
                },
            };
        } else {
            //  display regular entities legend
            return {
                enabled: true,
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                squareSymbol: false,
                symbolHeight: 10,
                symbolRadius: 0,
                labelFormatter: function () {
                    return isMultipleOverlayEnabled && this.options.locationName
                        ? this.options.locationName + '(' + StringUtils.upperCase(this.name) + ')'
                        : StringUtils.upperCase(this.name);
                },
            };
        }
    }
    // computes Y-Axis for the Hydrograph entities
    private prepareChartYAxisSettings(
        entity: string,
        entitySeriesData: Array<EntitySeries>,
        isDisplayAutoCorrectedData: boolean,
        annotationValue: number,
        selectedDisplayGroups,
        isRainOnTop = false,
    ): Array<YAxis> {
        const yAxis = new Array<YAxis>();
        const depthVelocityEntitySeriesData = entitySeriesData.some(
            (x) =>
                x.displayGroupID === DEPTH_DISPLAY_GROUP ||
                x.displayGroupID === PRESSK_DISPLAY_GROUP ||
                x.displayGroupID === VELOCITY_DISPLAY_GROUP,
        );

        const flowRainEntitySeriesData = entitySeriesData.some(
            (x) => x.displayGroupID === FLOW_DISPLAY_GROUP || x.displayGroupID === RAIN_DISPLAY_GROUP,
        );

        const tempVoltageEntitySeries = entitySeriesData.some(
            (x) => x.displayGroupID === TEMPERATURE_DISPLAY_GROUP || x.displayGroupID === VOLTAGE_DISPLAY_GROUP,
        );

        const scalarOtherEntitySeriesData = entitySeriesData.some(
            (x) =>
                x.displayGroupID !== DEPTH_DISPLAY_GROUP &&
                x.displayGroupID !== PRESSK_DISPLAY_GROUP &&
                x.displayGroupID !== VELOCITY_DISPLAY_GROUP &&
                x.displayGroupID !== FLOW_DISPLAY_GROUP &&
                x.displayGroupID !== RAIN_DISPLAY_GROUP &&
                x.displayGroupID !== TEMPERATURE_DISPLAY_GROUP &&
                x.displayGroupID !== VOLTAGE_DISPLAY_GROUP,
        );

        if (entity === DEPTH_VELOCITY && depthVelocityEntitySeriesData) {
            yAxis.push(
                this.generateYAxis(
                    DEPTH,
                    entitySeriesData,
                    isDisplayAutoCorrectedData,
                    annotationValue,
                    selectedDisplayGroups,
                    isRainOnTop,
                ),
            );

            yAxis.push(
                this.generateYAxis(
                    VELOCITY,
                    entitySeriesData,
                    isDisplayAutoCorrectedData,
                    annotationValue,
                    selectedDisplayGroups,
                    isRainOnTop,
                ),
            );
        }

        if (entity === DEPTH_VELOCITY && flowRainEntitySeriesData) {
            yAxis.push(
                this.generateYAxis(
                    FLOW,
                    entitySeriesData,
                    isDisplayAutoCorrectedData,
                    annotationValue,
                    selectedDisplayGroups,
                    isRainOnTop,
                ),
            );
            yAxis.push(
                this.generateYAxis(
                    RAIN,
                    entitySeriesData,
                    isDisplayAutoCorrectedData,
                    annotationValue,
                    selectedDisplayGroups,
                    isRainOnTop,
                ),
            );
        }

        if (entity === DEPTH_VELOCITY && tempVoltageEntitySeries) {
            yAxis.push(
                this.generateYAxis(
                    TEMPERATURE,
                    entitySeriesData,
                    isDisplayAutoCorrectedData,
                    annotationValue,
                    selectedDisplayGroups,
                    isRainOnTop,
                ),
            );

            yAxis.push(
                this.generateYAxis(
                    VOLTS,
                    entitySeriesData,
                    isDisplayAutoCorrectedData,
                    annotationValue,
                    selectedDisplayGroups,
                    isRainOnTop,
                ),
            );
        }

        if (entity === DEPTH_VELOCITY && scalarOtherEntitySeriesData) {
            yAxis.push(
                this.generateYAxis(
                    SCALAR,
                    entitySeriesData,
                    isDisplayAutoCorrectedData,
                    annotationValue,
                    selectedDisplayGroups,
                    isRainOnTop,
                ),
            );

            yAxis.push(
                this.generateYAxis(
                    OTHER,
                    entitySeriesData,
                    isDisplayAutoCorrectedData,
                    annotationValue,
                    selectedDisplayGroups,
                    isRainOnTop,
                ),
            );
        }

        /* Remove nulls from yAxis */

        return yAxis.filter((x) => x != null);
    }

    // tslint:disable-next-line:cyclomatic-complexity
    private generateYAxis(
        displayGroup: string,
        entitySeriesData: Array<any>,
        isDisplayAutoCorrectedData: boolean,
        annotationValue: number,
        selectedDisplayGroups: Array<number>,
        isRainOnTop = false,
    ) {
        const yAxis = <YAxis>{
            allowDecimals: true,
            height: '100%',
            offset: 0,
            title: {},
            tickAmount: 3,
        };

        const depthVelocityEntitySeries: Array<EntitySeries> = entitySeriesData.filter(
            (entity) =>
                entity.displayGroupID === DEPTH_DISPLAY_GROUP ||
                entity.displayGroupID === VELOCITY_DISPLAY_GROUP ||
                entity.displayGroupID === PRESSK_DISPLAY_GROUP,
        );

        const flowRainEntitySeries: Array<EntitySeries> = entitySeriesData.filter(
            (entity) => entity.displayGroupID === FLOW_DISPLAY_GROUP || entity.displayGroupID === RAIN_DISPLAY_GROUP,
        );

        const tempVoltageEntitySeries: Array<EntitySeries> = entitySeriesData.filter(
            (entity) =>
                entity.displayGroupID === TEMPERATURE_DISPLAY_GROUP || entity.displayGroupID === VOLTAGE_DISPLAY_GROUP,
        );

        const scalarOtherEntitySeries = entitySeriesData.filter(
            (x) =>
                x.displayGroupID !== DEPTH_DISPLAY_GROUP &&
                x.displayGroupID !== PRESSK_DISPLAY_GROUP &&
                x.displayGroupID !== VELOCITY_DISPLAY_GROUP &&
                x.displayGroupID !== FLOW_DISPLAY_GROUP &&
                x.displayGroupID !== RAIN_DISPLAY_GROUP &&
                x.displayGroupID !== TEMPERATURE_DISPLAY_GROUP &&
                x.displayGroupID !== VOLTAGE_DISPLAY_GROUP,
        );

        const chartPositionSettings = this.findChartAxisPlacement(
            depthVelocityEntitySeries && depthVelocityEntitySeries.length > 0,
            flowRainEntitySeries && flowRainEntitySeries.length > 0,
            tempVoltageEntitySeries && tempVoltageEntitySeries.length > 0,
            scalarOtherEntitySeries && scalarOtherEntitySeries.length > 0,
        );

        let filteredData: Array<EntitySeries>;
        let currentDisplayGroup: number;
        let top: string;

        if (
            displayGroup === DEPTH &&
            selectedDisplayGroups.some((x) => x === DEPTH_DISPLAY_GROUP || x === PRESSK_DISPLAY_GROUP)
        ) {
            currentDisplayGroup = DEPTH_DISPLAY_GROUP;
            filteredData = entitySeriesData.filter(
                (x) => x.displayGroupID === DEPTH_DISPLAY_GROUP || x.displayGroupID === PRESSK_DISPLAY_GROUP,
            );
            yAxis.offset = 0;
            top = `0%`;
        } else if (
            displayGroup === VELOCITY &&
            selectedDisplayGroups.some((x) => x === DEPTH_DISPLAY_GROUP || x === VELOCITY_DISPLAY_GROUP)
        ) {
            currentDisplayGroup = VELOCITY_DISPLAY_GROUP;
            filteredData = entitySeriesData.filter((x) => x.displayGroupID === VELOCITY_DISPLAY_GROUP);
            yAxis.offset = 0;
            top = `0%`;
        } else if (
            displayGroup === FLOW &&
            selectedDisplayGroups.some((x) => x === FLOW_DISPLAY_GROUP || x === RAIN_DISPLAY_GROUP)
        ) {
            currentDisplayGroup = FLOW_DISPLAY_GROUP;
            filteredData = entitySeriesData.filter((x) => x.displayGroupID === FLOW_DISPLAY_GROUP);
            top = selectedDisplayGroups.some((x) => x === DEPTH_DISPLAY_GROUP || x === VELOCITY_DISPLAY_GROUP)
                ? `${chartPositionSettings.topOffset}%`
                : `0%`;
            yAxis.offset = 0;
        } else if (
            displayGroup === RAIN &&
            selectedDisplayGroups.some((x) => x === FLOW_DISPLAY_GROUP || x === RAIN_DISPLAY_GROUP)
        ) {
            currentDisplayGroup = RAIN_DISPLAY_GROUP;
            filteredData = entitySeriesData.filter((x) => x.displayGroupID === RAIN_DISPLAY_GROUP);
            top = selectedDisplayGroups.some((x) => x === DEPTH_DISPLAY_GROUP || x === VELOCITY_DISPLAY_GROUP)
                ? `${chartPositionSettings.topOffset}%`
                : `0%`;
            yAxis.offset = 0;
        } else if (
            displayGroup === TEMPERATURE &&
            selectedDisplayGroups.some((x) => x === TEMPERATURE_DISPLAY_GROUP || x === VOLTAGE_DISPLAY_GROUP)
        ) {
            currentDisplayGroup = TEMPERATURE_DISPLAY_GROUP;
            filteredData = entitySeriesData.filter((x) => x.displayGroupID === TEMPERATURE_DISPLAY_GROUP);
            top =
                selectedDisplayGroups.some((x) => x === FLOW_DISPLAY_GROUP || x === RAIN_DISPLAY_GROUP) &&
                selectedDisplayGroups.some((x) => x === DEPTH_DISPLAY_GROUP || x === VELOCITY_DISPLAY_GROUP)
                    ? `${chartPositionSettings.topOffset * 2}%`
                    : `${chartPositionSettings.topOffset}%`;
        } else if (
            displayGroup === VOLTS &&
            selectedDisplayGroups.some((x) => x === TEMPERATURE_DISPLAY_GROUP || x === VOLTAGE_DISPLAY_GROUP)
        ) {
            currentDisplayGroup = VOLTAGE_DISPLAY_GROUP;
            filteredData = entitySeriesData.filter((x) => x.displayGroupID === VOLTAGE_DISPLAY_GROUP);
            top =
                selectedDisplayGroups.some((x) => x === FLOW_DISPLAY_GROUP || x === RAIN_DISPLAY_GROUP) &&
                selectedDisplayGroups.some((x) => x === DEPTH_DISPLAY_GROUP || x === VELOCITY_DISPLAY_GROUP)
                    ? `${chartPositionSettings.topOffset * 2}%`
                    : `${chartPositionSettings.topOffset}%`;
        } else if (displayGroup === SCALAR) {
            currentDisplayGroup = SCALAR_DISPLAY_GROUP;
            filteredData = this.filterScalarEntitiesData(displayGroup, entitySeriesData);
            if (
                entitySeriesData.filter(
                    (x) => x.displayGroupID === TEMPERATURE_DISPLAY_GROUP || x.displayGroupID === VOLTAGE_DISPLAY_GROUP,
                ) &&
                entitySeriesData.filter(
                    (x) => x.displayGroupID === TEMPERATURE_DISPLAY_GROUP || x.displayGroupID === VOLTAGE_DISPLAY_GROUP,
                )[0]
            ) {
                top = `${chartPositionSettings.topOffset * 3}%`;
            } else {
                top = `${chartPositionSettings.topOffset * 2}%`;
            }
        } else if (displayGroup === OTHER) {
            currentDisplayGroup = OTHER_DISPLAY_GROUP_CONST;
            filteredData = this.filterScalarEntitiesData(OTHER, entitySeriesData);
            if (
                (entitySeriesData.filter((x) => x.displayGroupID === TEMPERATURE_DISPLAY_GROUP) &&
                    entitySeriesData.filter((x) => x.displayGroupID === TEMPERATURE_DISPLAY_GROUP)[0]) ||
                (entitySeriesData.filter((x) => x.displayGroupID === VOLTAGE_DISPLAY_GROUP) &&
                    entitySeriesData.filter((x) => x.displayGroupID === VOLTAGE_DISPLAY_GROUP)[0])
            ) {
                top = `${chartPositionSettings.topOffset * 3}%`;
            } else {
                top = `${chartPositionSettings.topOffset * 2}%`;
            }
        }
        yAxis.top = top;
        yAxis.height = `${chartPositionSettings.height}%`;
        yAxis.title = { text: this.getTitle(filteredData), margin: 20 };
        yAxis.min = this.findMinMaxYAxis(
            currentDisplayGroup,
            filteredData,
            isDisplayAutoCorrectedData,
            annotationValue,
            true,
        );
        yAxis.max =
            this.findMinMaxYAxis(
                currentDisplayGroup,
                filteredData,
                isDisplayAutoCorrectedData,
                annotationValue,
                false,
            ) || 10;
        yAxis.opposite =
            displayGroup === OTHER || displayGroup === VOLTS || displayGroup === RAIN || displayGroup === VELOCITY;
        if (displayGroup === RAIN) {
            yAxis.reversed = isRainOnTop;
        }
        yAxis.startOnTick = true;
        yAxis.endOnTick = true;
        return yAxis;
    }

    // Method returns filtered entities series data that belongs to either Scalar or Other groups
    private filterScalarEntitiesData(displayGroup: string, entitySeriesData: Array<EntitySeries>) {
        //  BTYLOW id 6602, CROSS_DEPTH id 6615, CROSS_VELOCITY id  6616, HIGH_HIGH id 6609, HIGH_LEVEL id 6610,
        //  Low_Flow id 6620, Low_Level 6611, OVERFLOW id 6631, Rain_Alert id 6613, RAINTIPS 6622, SUBMERGED id 6621,
        //  TILT id 6623
        const scalarEntities = [
            BTY_LOW_ENTITY,
            CROSS_DEPTH_ENTITY,
            6616,
            6609,
            6610,
            6620,
            6611,
            6631,
            6613,
            6622,
            6621,
            6623,
        ];
        if (displayGroup === SCALAR) {
            return entitySeriesData.filter(
                (x) => x.displayGroupID === 9 || (x.displayGroupID === 1 && scalarEntities.some((y) => y === x.id)),
            );
        } else if (displayGroup === OTHER) {
            return entitySeriesData.filter(
                (x) =>
                    x.displayGroupID === 4 ||
                    x.displayGroupID === 22 ||
                    (x.displayGroupID === SCALAR_DISPLAY_GROUP && !scalarEntities.some((y) => y === x.id)),
            );
        }
    }

    // tslint:disable-next-line: cyclomatic-complexity
    private prepareChartYAxisPositions(displayGroupID: number, selectedDisplayGroups: Array<number>): any {
        /**
         * Find the chart rendering index to plot the entity data for different-2 display group.
         * This method help to identify the chart index based on selected entities from filter.
         */
        const isDepthDisplay = selectedDisplayGroups.indexOf(DEPTH_DISPLAY_GROUP) > -1;
        const isVelocityDisplay = selectedDisplayGroups.indexOf(VELOCITY_DISPLAY_GROUP) > -1;
        const isRainDisplay = selectedDisplayGroups.indexOf(RAIN_DISPLAY_GROUP) > -1;
        const isFlowDisplay = selectedDisplayGroups.indexOf(FLOW_DISPLAY_GROUP) > -1;
        const isTemperatureDisplay = selectedDisplayGroups.indexOf(TEMPERATURE_DISPLAY_GROUP) > -1;
        const isVoltageDisplay = selectedDisplayGroups.indexOf(VOLTAGE_DISPLAY_GROUP) > -1;
        let value: number;
        let topOffSet: number;
        switch (displayGroupID) {
            case DEPTH_DISPLAY_GROUP:
                topOffSet = isFlowDisplay || isRainDisplay ? 54 : 0;
                value = 0;
                break;
            case VELOCITY_DISPLAY_GROUP:
                value = 1;
                break;
            case FLOW_DISPLAY_GROUP:
                value = isDepthDisplay || isVelocityDisplay ? 2 : 0;
                break;
            case RAIN_DISPLAY_GROUP:
                value = isDepthDisplay || isVelocityDisplay ? 3 : 1;
                break;
            case TEMPERATURE_DISPLAY_GROUP:
                if (isDepthDisplay || isVelocityDisplay) {
                    value = isFlowDisplay || isRainDisplay ? 4 : 2;
                } else {
                    value = isFlowDisplay || isRainDisplay ? 2 : 0;
                }
                break;
            case VOLTAGE_DISPLAY_GROUP:
                if (isDepthDisplay || isVelocityDisplay) {
                    value = isFlowDisplay || isRainDisplay ? 5 : 3;
                } else {
                    value = isFlowDisplay || isRainDisplay ? 3 : 1;
                }
                break;
            case SCALAR_DISPLAY_GROUP:
                if (isDepthDisplay || isVelocityDisplay) {
                    if (isFlowDisplay || isRainDisplay) {
                        value = isTemperatureDisplay || isVoltageDisplay ? 6 : 4;
                    } else {
                        value = isTemperatureDisplay || isVoltageDisplay ? 4 : 2;
                    }
                } else {
                    if (isFlowDisplay || isRainDisplay) {
                        value = isTemperatureDisplay || isVoltageDisplay ? 4 : 2;
                    } else {
                        value = isTemperatureDisplay || isVoltageDisplay ? 2 : 0;
                    }
                }
                break;
            default:
                if (isDepthDisplay || isVelocityDisplay) {
                    if (isFlowDisplay || isRainDisplay) {
                        value = isTemperatureDisplay || isVoltageDisplay ? 7 : 5;
                    } else {
                        value = isTemperatureDisplay || isVoltageDisplay ? 5 : 3;
                    }
                } else {
                    if (isFlowDisplay || isRainDisplay) {
                        value = isTemperatureDisplay || isVoltageDisplay ? 5 : 3;
                    } else {
                        value = isTemperatureDisplay || isVoltageDisplay ? 3 : 1;
                    }
                }
                break;
        }

        return {
            offset: topOffSet,
            value: value,
        };
    }

    private findMinMaxYAxis(
        displayGroupID: number,
        entitySeriesData: Array<EntitySeries>,
        isDisplayAutoCorrectedData: boolean,
        annotationValue: number,
        isMinimumOperation: boolean,
    ) {
        const depthPointsData = new Array<number>();
        const correctedPointsData = new Array<number>();
        depthPointsData.push(0);

        //  if no manual scales are set calculate the maximum entity data value
        if (entitySeriesData && entitySeriesData.length) {
            entitySeriesData
                .filter((item) => item.data.length)
                .forEach((item) => {
                    item.data
                        .filter((itemData) => itemData && itemData.y)
                        .forEach((itemData) => {
                            depthPointsData.push(itemData.y);
                        });

                    if (item.correctedData && item.correctedData.data) {
                        item.correctedData.data
                            .filter((itemData) => itemData && itemData.y)
                            .forEach((itemData) => {
                                correctedPointsData.push(itemData.y);
                            });
                    }
                });
        }

        let value = isMinimumOperation ? Math.min(...depthPointsData) : Math.max(...depthPointsData);

        // //  if the annotations are present include them in calculations of depth/velocity scales
        if (annotationValue > 0 && displayGroupID === DEPTH_DISPLAY_GROUP) {
            value = isMinimumOperation ? Math.min(...[annotationValue, value]) : Math.max(...[annotationValue, value]);
        }

        // //  If Auto-Corrected is enabled then maximum value of the Axis should also consider Corrected Points */
        if (isDisplayAutoCorrectedData && correctedPointsData && correctedPointsData.length) {
            const correctedValue = correctedPointsData.length > 0 ? Math.min(...correctedPointsData) : 0;
            value = isMinimumOperation ? Math.min(...[correctedValue, value]) : Math.max(...[correctedValue, value]);
        }
        return value;
    }

    // Method returns a hex color depending on the data quality value | value : data quality rating
    private dataQualityColor(value: number) {
        if (!value) {
            return;
        }

        const percentage = (value * 100) / 15;
        if (percentage < 11) {
            //  0 - 10 %
            return '#FF0000';
        } else if (percentage < 21) {
            //  11 - 20 %
            return '#FFFF00';
        } else if (percentage < 31) {
            //  21 - 30 %
            return '#FF00FF';
        } else if (percentage < 41) {
            //  31 - 40 %
            return '#0000ff';
        } else if (percentage < 51) {
            //  41 - 50 %
            return '#808000';
        } else if (percentage < 61) {
            //  51 - 60 %
            return '#008080';
        } else if (percentage < 71) {
            //  61 - 70 %
            return '#000080';
        } else if (percentage < 81) {
            //  71 - 80 %
            return '#800000';
        } else if (percentage < 91) {
            //  81 - 90 %
            return '#008000';
        } else if (percentage < 101) {
            //  91 - 100 %
            return '#00ff00';
        }
    }

    private addPlotBands(point, plotband) {
        if (point && point.y) {
            plotband['to'] = point.x;
            const plotBand = { ...plotband };
            // this.plotBands.push(plotBand);
        }
    }

    private prepareCorrectedData(data: string) {
        return data && data.indexOf(',') > 0 ? data.split(',')[0].slice(1, data.split(',')[0].length) : data;
    }

    private getTitle(entitySeriesData: Array<EntitySeries>): string {
        let title: string;
        if (entitySeriesData && entitySeriesData.length) {
            title = entitySeriesData[0].axis.label;
        }
        return title ? title : ''; //When no entitySeriesData is available entity won't be shown in title.
    }

    private initializeTootipFormats() {
        this.xAxisDateFormat = this.dateutilService.getGraphDateFormat();
        this.tooltipTimeFormat = this.dateutilService.timeFormat.getValue() === 'h:mm:ss tt' ? ' %l:%M %p' : ' %H:%M';
    }

    private onXAxisZoomChange(event, self, zoomEnabled: boolean, chartType = 'line') {
        if (zoomEnabled) {
            if (event && event.target.xAxis[0].min && event.target.xAxis[0].max) {
                const startDate = new Date(event.target.xAxis[0].min);
                const endDate = new Date(event.target.xAxis[0].max);
                const settings = self.prepareXAxisLabels(startDate, endDate);
                if (settings) {
                    event.target.options.tickInterval = settings.interval;
                    event.target.options.dateTimeLabelFormats = settings.dateFormat;
                }
            }
        } else {
            if (event && event.min && event.max) {
                const startDate = new Date(event.min);
                const endDate = new Date(event.max);
                const settings = self.prepareXAxisLabels(startDate, endDate);
                if (settings) {
                    event.target.options.tickInterval = settings.interval;
                    event.target.options.dateTimeLabelFormats = settings.dateFormat;
                }
            }
        }
    }

    // tslint:disable-next-line: cyclomatic-complexity
    private prepareXAxisLabels(startDate: Date, endDate: Date) {
        const xAxisCategories = [];
        let dateTimeLabelFormats = {};

        const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * miliseconds
        const oneHour = 3600000;
        const diffDays = Math.abs((startDate.getTime() - endDate.getTime()) / oneDay);
        const diffHours = Math.abs((startDate.getTime() - endDate.getTime()) / oneHour);
        let tickInterval = oneDay;
        if (diffDays > 92) {
            // greater then 3 months
            tickInterval = 24 * 3600 * 1000 * 30;
            dateTimeLabelFormats = {
                month: '%b',
            };
        } else if (diffDays > 31 && diffDays <= 92) {
            // greater then a month and less then 3 months
            tickInterval = 24 * 3600 * 1000 * 7;
            dateTimeLabelFormats = {
                week: '%e. %b',
            };
        } else if (diffDays > 7 && diffDays <= 31) {
            // more then a week, less then a month
            dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
            };
        } else if (diffDays <= 7 && diffDays > 3.75) {
            tickInterval = 12 * 3600 * 1000;
            dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
            };
        } else if (diffDays < 3.75 && diffDays > 2.75) {
            tickInterval = 8 * 3600 * 1000;
            dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
            };
        } else if (diffDays < 2.75 && diffDays > 1.75) {
            tickInterval = 6 * 3600 * 1000;
            dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
            };
        } else if (diffDays < 1.75 && diffDays > 1.25) {
            tickInterval = 4 * 3600 * 1000;
            dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
            };
        } else if (diffDays < 1.25 && diffDays >= 1) {
            tickInterval = 3 * 3600 * 1000;
            dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
            };
        } else if (diffDays < 1 && diffHours < 23 && diffHours > 14) {
            tickInterval = 2 * 3600 * 1000;
            dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
            };
        } else if (diffDays < 1 && diffHours < 14 && diffHours > 6.75) {
            tickInterval = 3600 * 1000;
            dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
            };
        } else if (diffDays < 1 && diffHours < 6.75 && diffHours > 3.5) {
            tickInterval = 1800 * 1000;
            dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
                minute: '%H:%M',
            };
        } else if (diffDays < 1 && diffHours < 3.5) {
            tickInterval = 900 * 1000;
            dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
                minute: '%H:%M',
            };
        }
        return {
            categories: xAxisCategories,
            interval: tickInterval,
            dateFormat: dateTimeLabelFormats,
        };
    }

    private findChartAxisPlacement(
        hasDepthVelocity: boolean,
        hasFlowRain: boolean,
        hasTempVoltage: boolean,
        hasScalerOther: boolean,
    ): any {
        const chartPlacementSettings = {
            topOffset: 0,
            height: 100,
        };

        if (hasFlowRain && hasDepthVelocity && hasTempVoltage && hasScalerOther) {
            chartPlacementSettings.topOffset = 26;
            chartPlacementSettings.height = 21;
            return chartPlacementSettings;
        }

        if (hasFlowRain && hasDepthVelocity) {
            chartPlacementSettings.topOffset = 54;
            chartPlacementSettings.height = 47;
        }

        if (hasTempVoltage) {
            if (hasDepthVelocity) {
                chartPlacementSettings.topOffset = 54;
                chartPlacementSettings.height = 47;
            }

            if (hasFlowRain) {
                chartPlacementSettings.topOffset = 35;
                chartPlacementSettings.height = 30;
            }

            if (hasScalerOther) {
                chartPlacementSettings.topOffset = 26;
                chartPlacementSettings.height = 70;
            }
        }

        if (hasScalerOther) {
            if (hasDepthVelocity) {
                chartPlacementSettings.topOffset = 54;
                chartPlacementSettings.height = 47;
            }

            if (hasFlowRain) {
                chartPlacementSettings.topOffset = 35;
                chartPlacementSettings.height = 30;
            }

            if (hasTempVoltage) {
                chartPlacementSettings.topOffset = 26;
                chartPlacementSettings.height = 21;
            }
        }

        return chartPlacementSettings;
    }
}
