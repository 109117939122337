import { Injectable } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { FileService } from 'app/shared/services/file.service';

@Pipe({
    name: 'extension',
})
@Injectable()
export class FileExtensionPipe implements PipeTransform {
    private readonly empty = '-';

    constructor(private fileService: FileService) {}

    public transform(value: string): string {
        // ensure args
        if (value === undefined || value === '') {
            return this.empty;
        }

        return this.fileService.getFileExtension(value);
    }
}
