<app-loader [isLoading]="isLoading"></app-loader>

<fm-pivot
    #pivot
    [height]="'100%'"
    [toolbar]="true"
    [report]="report"
    (beforetoolbarcreated)="customizeToolbar($event)"
    [licenseKey]="license"
    >
</fm-pivot>