import { FlatTreeControl } from '@angular/cdk/tree';
import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    ChangeDetectorRef,
    Output,
    EventEmitter,
} from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Observable } from 'rxjs';
import { VaultService } from '../vault.service';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { Subscription } from 'rxjs';
import { FileNode, FlatNode, VaultDirectory } from 'app/shared/models/vault';

@Component({
    selector: 'ads-prism-vault-tree',
    templateUrl: './ads-prism-vault-tree.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdsPrismVaultTreeComponent implements OnInit {
    @Input() public vault: Observable<VaultDirectory>;
    @Output() public sendIdSelected = new EventEmitter<string>();
    // Repreents a value indicating whether the root directory is selected.
    public isActive: boolean;

    // Represents the subscriptions to be disposed.
    public nodeId: string;
    private subscriptions = new Array<Subscription>();

    public vaultTreeData = [];

    public nestedNodeMap = new Map<FileNode, FlatNode>();
    public treeControl: FlatTreeControl<FlatNode>;
    public treeFlattener: MatTreeFlattener<FileNode, FlatNode>;
    public dataSource: MatTreeFlatDataSource<FileNode, FlatNode>;

    constructor(
        private domOperationUtilsService: DomOperationUtilsService,
        private vaultService: VaultService,
        private chagneDetector: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
    ) {}

    private getLevel = (node: FlatNode) => node.level;

    private isExpandable = (node: FlatNode) => node.expandable;

    private getChildren = (node: FileNode): FileNode[] => node.sub_directories.sort((a,b) => (!a.name || !b.name ? 0 : a.name === b.name ? 0 : a.name > b.name ? 1 : -1));

    public hasChild = (_: number, nodeData: FlatNode) => nodeData.expandable;

    public hasNoContent = (_: number, nodeData: FlatNode) => nodeData.name === '';

    // Transformer to convert nested node to flat node. Record the nodes in maps for later use.
    private transformer = (node: FileNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.name === node.name ? existingNode : <FlatNode>{};
        flatNode.name = node.name;
        flatNode.id = node.id;
        flatNode.level = level;
        flatNode.expandable = !!node.sub_directories && node.sub_directories.length > 0;
        return flatNode;
    };

    public ngOnInit() {
        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        this.nodeId = '/';
        this.vault.subscribe((res) => {
            if (res) {
                res['id'] = '/';
                res['name'] = 'Root';
                this.vaultTreeData.length = 0;
                this.vaultTreeData.push(res);
                this.dataSource.data = this.vaultTreeData;
                this.expandDefultNode();
            }
        });

        this.vaultService.selectedDirectory.subscribe(
            (selectedNodeId: string) => {
                this.nodeId = selectedNodeId;
                this.uiUtilsService.safeChangeDetection(this.chagneDetector);
            },
            (error) => {
                // Error Block to handle errors
            },
        );
        this.vaultService.BreadcrumbDirectories.subscribe(
            (directories) => {
                this.expandDefultNode();
                if (directories) {
                    directories.forEach((eachItem, index) => {
                        this.expandSelectedNodes(eachItem.id);
                    });
                }
            },
            (error) => {},
        );
    }
    public expandDefultNode() {
        if (this.treeControl && this.treeControl.dataNodes) {
            this.treeControl.collapseAll();
            this.treeControl.expand(this.treeControl.dataNodes.find((d) => d.id === '/'));
            this.nodeId = '/';
        }
        this.uiUtilsService.safeChangeDetection(this.chagneDetector);
    }
    public expandSelectedNodes(id: string) {
        if (this.treeControl && this.treeControl.dataNodes) {
            this.treeControl.expand(this.treeControl.dataNodes.find((d) => d.id === id));
            this.nodeId = id;
        }
        this.uiUtilsService.safeChangeDetection(this.chagneDetector);
    }
    /**
     * Handles the response for the click event of the selected directory.
     * @param id Represents the surrogate id of the selected diretory.
     */
    public selectDirectory(id: string): void {
        this.domOperationUtilsService.scrollToTop('.vault-list');
        this.sendIdSelected.emit(id);
        this.uiUtilsService.safeChangeDetection(this.chagneDetector);
    }
}
