import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewEncapsulation,
    OnDestroy,
} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';

import { AddEditFlowBalanceComponent } from '../../view-data/add-edit-flow-balance/add-edit-flow-balance.component';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { UsersService } from 'app/pages/admin/users.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { LocationService } from 'app/shared/services/location.service';
import { Selectable } from 'app/shared/models/selectable';
import { LocationArgs, Locations } from 'app/shared/models/locations';
import {
    AppQueryParams,
    customerLocationGroupQueryParam,
    customerQueryParam,
    locationIdQueryParam,
} from 'app/shared/models/customer';
import { FlowBalanceDetails } from 'app/shared/models/location-details';
import { ContactUsComponent } from 'app/shared/components/contact-us/contact-us.component';

const ADVANCEREPORTID = 113;
const ADVANCEREPORT = 'AdvanceReport';

@Component({
    selector: 'app-flow-balance-report',
    templateUrl: './flow-balance-report.component.html',
    styleUrls: ['./flow-balance-report.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowBalanceReportComponent implements OnInit, OnDestroy {
    public flowBalanceColumns = ['name', 'flow'];
    /**
     * Represents the selected tab index
     */
    public selectedTabIndex: number;
    /**
     * Preselected location for locations dropdown
     */
    public preselectedLocation: Selectable;
    public selectableLocations: Array<Selectable>;
    public locationId: number;
    public showLocationSearch: boolean;
    public displayStartDateErrMsg: boolean;
    public displayEndDateErrMsg: boolean;
    public invalidDateRange: boolean;
    public invalidStartDate: boolean;
    public invalidEndDate: boolean;
    public locations = new Array<Locations>();
    public locationName: string;
    public locationGroupId: number;
    public includeInactiveLocations = false;
    public selectedLocationId: number;
    public isFlowBalanceVisible: boolean;
    private isCustomerChanged: boolean;
    /**
     * Variable which declares the loading state for location dashboard filter
     */
    public isLoading = false;
    public customerId: number;
    public isFlowBalanceData: boolean;
    public isFlowBalanceDataAvailable: boolean;
    public flowBalanceData = new Array<FlowBalanceDetails>();
    public flowBalanceDataSource: MatTableDataSource<FlowBalanceDetails>;
    public lid: number;
    private subscriptions = [];
    constructor(
        public dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private usersService: UsersService,
        private statusCodeService: StatusCodeService,
        private uiUtilService: UiUtilsService,
        private locationService: LocationService,
        private router: Router,
        private activatedRouter: ActivatedRoute,
    ) {}

    public ngOnInit() {
        this.isLoading = true;
        this.subscriptions.push(
            this.activatedRoute.queryParamMap.subscribe(
                (params: ParamMap) => {
                    this.customerId = Number(params.get(customerQueryParam));
                    this.locationId = Number(params.get(locationIdQueryParam));
                    this.locationGroupId = Number(params.get(customerLocationGroupQueryParam));
                    this.statusCodeService.enableGlobalDropDowns.next(false);

                    const locationArgs = <LocationArgs>{
                        customerId: this.customerId,
                        locationGroupId: this.locationGroupId || 0,
                        IncludeInactiveLocations: this.includeInactiveLocations,
                    };

                    //  get customer's features
                    this.subscriptions.push(
                        this.statusCodeService.userInfo.subscribe((responseData) => {
                            if (responseData) {
                                responseData.customers.forEach((customer) => {
                                    if (customer.customer.customerID === this.customerId) {
                                        const customerFeatures = customer.customer.features;
                                        this.isFlowBalanceVisible =
                                            customerFeatures &&
                                            customerFeatures.some(
                                                (x) => x.id === ADVANCEREPORTID || x.name === ADVANCEREPORT,
                                            );
                                    }
                                });

                                this.subscriptions.push(
                                    this.usersService.getLocationsList(locationArgs).subscribe(
                                        (locations) => {
                                            this.isLoading = false;
                                            this.locations = locations;
                                            this.selectableLocations = this.locations.map(
                                                (loc: Locations) =>
                                                    <Selectable>{
                                                        id: loc.locationId,
                                                        name: loc.locationName,
                                                        isComposite: loc.locationType === 3,
                                                    },
                                            );
                                            this.preselectedLocation = {
                                                id: this.locationId ? this.locationId : this.selectableLocations[0].id,
                                                name: this.selectableLocations[0].name,
                                            };
                                            if (this.isFlowBalanceVisible) {
                                                this.isCustomerChanged = true;
                                                this.getFlowBalanceData();
                                                this.selectedTabIndex = 0;
                                            } else {
                                                this.isFlowBalanceDataAvailable = false;
                                            }
                                            this.uiUtilService.safeChangeDetection(this.cdr);
                                        },
                                        (error) => {
                                            this.isLoading = false;
                                            this.uiUtilService.safeChangeDetection(this.cdr);
                                        },
                                    ),
                                );
                            }
                        }),
                    );
                },
                (error) => {
                    this.isLoading = false;
                    this.uiUtilService.safeChangeDetection(this.cdr);
                },
            ),
        );
    }

    public ngOnDestroy() {
        if (this.subscriptions && this.subscriptions.length) {
            this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        }
    }
    /**
     * Used to get flow balance data from the api
     *
     */
    public getFlowBalanceData() {
        this.isFlowBalanceData = true;
        this.locationService.getFlowBalance(this.customerId, this.preselectedLocation.id).subscribe(
            (result) => {
                if (result) {
                    this.lid = this.preselectedLocation.id;
                    this.locationId = this.lid;
                    this.flowBalanceData = result.filter((x) => x.flowType !== 0);
                    this.isFlowBalanceDataAvailable = this.flowBalanceData.length > 0;
                    this.flowBalanceDataSource = new MatTableDataSource(this.flowBalanceData);
                    this.isFlowBalanceData = false;
                    this.selectedLocationId = this.locationId;
                    this.isCustomerChanged = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }
            },
            (error) => {
                // empty block
                this.isLoading = false;
                this.isFlowBalanceData = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
    }
    public toggeleSearch() {
        if (this.locationId === 0) {
            this.locationId = this.selectedLocationId;
        }
        this.showLocationSearch = !this.showLocationSearch;
    }
    public contactUs() {
        this.dialog.open(ContactUsComponent, {
            disableClose: true,
        });
    }
    /**
     * Handler for location change using tabs
     * @param selectables collection of selected items.
     */
    public changeLocation(tabChangeEvent: MatTabChangeEvent) {
        let foundLocation;
        if (this.locations) {
            foundLocation = this.locations.find((loc: Locations) => loc.locationName === tabChangeEvent.tab.textLabel);
        }
        // map selected item to a location

        if (!foundLocation) {
            return;
        }
        this.locationName = foundLocation.locationName;
        // set location change data
        this.statusCodeService.currentLocationId.next(this.locationId);
        this.preselectedLocation = { id: foundLocation.locationId, name: foundLocation.locationName };
        this.selectedTabIndex = tabChangeEvent.index;
        if (!this.isCustomerChanged) {
            this.getFlowBalanceData();
        }
        this.uiUtilService.safeChangeDetection(this.cdr);
    }

    /**
     * This function will be invoked when any item will be selected from multi select auto complete field.
     * @param locations -> indicates the list of entities
     */
    public changeLocationDropdown(selectables: Array<Selectable>) {
        // ensure something is selected
        if (!selectables || selectables.length < 1) {
            this.locationId = 0;
            return;
        }
        // extract selected items
        const selectedItem = selectables[0];
        // map selected item to a location
        const foundLocation = this.locations.find((location: Locations) => location.locationId === selectedItem.id);
        if (!foundLocation) {
            return;
        }
        this.locationId = foundLocation.locationId;
        this.locationName = foundLocation.locationName;
        this.selectedTabIndex = this.locations.indexOf(foundLocation);
    }
    /**
     * Used to open flow balance pop up
     *
     */
    public openFlowBalance() {
        this.dialog
            .open(AddEditFlowBalanceComponent, {
                disableClose: true,
                data: { data: this.flowBalanceData, lid: this.lid },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.success !== false) {
                    this.getFlowBalanceData();
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }
            });
    }
    public redirectToFlowbalanceReport() {
        this.statusCodeService.redirectedFrom.next('flowBalance');
        this.router.navigate(['/pages/menuDashboard/locationFlowBalance'], {
            queryParams: <AppQueryParams>{
                c: this.customerId,
                lid: this.lid,
            },
            relativeTo: this.activatedRoute,
        });
    }
}
