import { Injectable } from '@angular/core';
import { Config } from './config';
import { EMPTY, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DeviceTypeCode } from '../enums/device-type-code';
import { MONITOR_SERIES_TYPES } from '../models/monitor-series-types';
import { TritonLocationService } from './tritonLocation.service';
import { TritonConfiguration } from '../models/monitorsetting';
import { map } from 'rxjs/operators';

export interface MonitorDiagnosticLogsRequest {
    startTime: Date;
    endTime: Date;
}

interface Reading {
    device: string;
    gain: number;
    maximumSignal: number;
    monitoringPoint: 1 | 2;
    quality: number;
    reading: number;
    sensor: string;
    temperature: number;
}

interface Status {
    basicFaultStatus: number;
    basicProgramFlag: boolean;
    batteryVoltage: number;
    currentMonitorTime: Date;
    dataStorageCapability: number;
    eventsPendingStatus: number;
    firmwareVersion: string;
    localCommunicationsStatus: boolean;
    modelNumber: string;
    monitorTemperature: number;
    newestEntryTime: Date;
    numberOfAbnormalResets: number;
    oldestEntryTime: Date;
    powerStatus: number;
    pressureContainmentStatus: boolean;
    sampleRate: number;
}

export interface MonitorDiagnosticLog {
    user?: string;
    readings: Reading[];
    status: Status;
    wirelessStatus?: WirelessStatus;
}

export interface WirelessStatus {
    signalStrength: number;
}

interface ReadingRequest {
    device: DeviceTypeCode;
    sensor: SensorType;
}
export interface MonitorDiagnosticRequest {
    customerID: number;
    locationID: number;
    readings: ReadingRequest[];
}

export enum SensorType {
    DEFAULT,
    ULTRASONIC,
    VELOCITY,
    PRESSURE,
    SURFACE_VELOCITY,
    TEMPERATURE,
    ACCELEROMETER,
}

@Injectable()
export class MonitorDiagnosticsService {
    constructor(private http: HttpClient, private tritonLocationService: TritonLocationService) {}

    public getDiagnosticLogs(cid: number, lid: number, body?: MonitorDiagnosticLogsRequest) {
        return this.http
            .post<MonitorDiagnosticLog[]>(
                `${Config.serviceUrl}${Config.urls.diagnosticLogs}?cid=${cid}&lid=${lid}`,
                body,
            )
            .pipe(
                map(
                    (result) => result,
                    () => EMPTY,
                ),
            );
    }

    public requestDiagnostics(body: MonitorDiagnosticRequest) {
        return this.http.post<void>(`${Config.serviceUrl}${Config.urls.diagnostics}`, body);
    }

    public getDiagnosticsDetails(
        monitor: MONITOR_SERIES_TYPES,
        locationId: number,
        customerId: number,
    ): Observable<{ showDiagnostics: boolean; deviceTypes: DeviceTypeCode[] }> {
        if (!monitor) {
            return of({
                showDiagnostics: false,
                deviceTypes: [],
            });
        }

        switch (monitor.toLowerCase()) {
            case MONITOR_SERIES_TYPES.TRITON_PLUS.toLowerCase():
                return this.tritonLocationService.getTritonMonitorConfiguration(locationId, customerId).pipe(
                    map((tritonResult: TritonConfiguration) => {
                        if (tritonResult) {
                            return {
                                showDiagnostics: true,
                                deviceTypes: tritonResult.devices.map((device) => device.type),
                            };
                        } else {
                            return {
                                showDiagnostics: false,
                                deviceTypes: [],
                            };
                        }
                    }),
                );
            case MONITOR_SERIES_TYPES.ECHO.toLowerCase():
                return of({
                    showDiagnostics: true,
                    deviceTypes: [DeviceTypeCode.LongRangeDepth1],
                });
            case MONITOR_SERIES_TYPES.RAIN_ALERT_III.toLowerCase():
                return of({
                    showDiagnostics: true,
                    deviceTypes: [DeviceTypeCode.EchoRain],
                });
            default:
                // don't show location diagnostics
                return of({
                    showDiagnostics: false,
                    deviceTypes: [],
                });
        }
    }
}
