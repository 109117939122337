export class GraphScale {
    public min: number;
    public max: number;
}

export class ScaleObject {
    public isShow: boolean;
    public isValid: boolean;
    public scale?: GraphScale;
    public axies = new Array<Object>();
    public defaultScaleSelected: number;
    public enableReset: boolean;
    constructor() {
        this.isShow = false;
        this.isValid = false;
        this.axies = new Array<Object>();
        this.enableReset = false;
        this.scale = new GraphScale();
    }
}

export class GraphTypesOptions {
    public depth: GraphScale;
    public velocity: GraphScale;
    public flow: GraphScale;
    public rain: GraphScale;
    public temperature: GraphScale;
    public voltage: GraphScale;
    public scalar: GraphScale;
    public other: GraphScale;
}

export class ShownAxes {
    public depthVelocity: boolean;
    public flowRain: boolean;
    public temperatureVoltage: boolean;
    public scalarOther: boolean;
}
