import {
    Component,
    ViewChild,
    OnInit,
    OnChanges,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    ViewEncapsulation,
    ElementRef,
    OnDestroy,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import Highcharts from 'highcharts';
import { DayOfWeekGroupDays, DryDayData } from 'app/shared/models/sliicer';
import { Measurement, MultiSeriesData, SeriesData } from 'app/shared/models/sliicer-data';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerUtils } from 'app/shared/utils/sliicer-utils';
import { DiurnalGraphComponent } from '../../../../shared/components/diurnal-graph/diurnal-graph.component';
import { Subscription, combineLatest, of } from 'rxjs';
import { map, catchError, filter, first } from 'rxjs/operators';
import _ from 'lodash';
import { UpdatesWidgetService } from 'app/pages/sliicer/shared/components/updates-widget/updates-widget.service';

const blackColor = '#000';
const removeDataButtonTimeoutLength = 3000;
const UPDATES_MODEL = 'studyFlowMonitor';
const LINE_WIDTH_MAIN = 5;
const LINE_WIDTH_HOVER = 3;
const LINE_WIDTH_TRACE = 1;
const LINE_WIDTH_INFILTRATION = 2;

(function(H) {
    H.wrap(H.Legend.prototype, 'colorizeItem', function(proceed, item, visible) {
        const color = item.color;
        if(item.options.legendColor) item.color = item.options.legendColor;
        proceed.apply(this, Array.prototype.slice.call(arguments, 1));
        item.color = color;
    });
}(Highcharts));

@Component({
    selector: 'app-dry-day-diurnal-graph',
    templateUrl: './dry-day-diurnal-graph.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class DryDayDiurnalGraphComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public caseStudyId: string;
    @Input() public basinDryDays: DryDayData;
    @Input() public selectedBasin: string;
    @Input() public customerId: number;
    @Input() public telemetryKey: string;
    @Input() public stepLength: number;
    @Input() public isDryDaysEditActive: boolean;
    @Input() public selectDryDays: string;
    @Output() public weekDaySelectionEvent = new EventEmitter<{ selectedDay: string; remove?: boolean }>();
    @Output() public dryDayRemoved = new EventEmitter<string>();
    @ViewChild(DiurnalGraphComponent, { static: true }) public dirunalGraphComponent: DiurnalGraphComponent;

    public unitOfMeasure = 'in';
    public infiltrationString: string;
    public tracesString: string;
    public labelDecimal: string;
    public valueDecimal: number;
    public tooltipEnabled = true;
    private yAxisLabel: string;
    private xAxisLabel: string;

    public isStudyLocked: boolean;

    private weekGroupNameLegendIndexMap = new Map<string, number>();
    public weekGroupCount = 0;
    private colorIndexMap = {};

    // TODO: WPS - see TODO around incrementLoading() below
    // private loadingCount = 0;

    /** Button on the chrt to remove data. */
    private removeDataButton: any = null;
    /** Timeout for automatically remove the remove button. */
    private removeDataButtonTimeout: any = null;

    lastSelected: { name: string; visible: boolean }[];
    selectedDateRange: any;
    shownDateRange: number[];
    chartOptions: Highcharts;

    private subscriptions: Subscription[] = [];
    constructor(
        private dateutilService: DateutilService,
        private translate: TranslateService,
        private sliicerService: SliicerService,
        private updatesWidgetService: UpdatesWidgetService,
        private datePipe: DatePipe
    ) {
        const translateKeys: Array<string> = [
            'SLIICER_TABLE.CHARTS.INFILTRATION',
            'SLIICER_TABLE.CHARTS.DAY_TRACES',
            'COMMON.FLOW_RATE',
            'CUSTOMER_EDITOR.TIME_PLACEHOLDER'
        ];
        this.subscriptions.push(this.translate.get(translateKeys.map((k) => k)).subscribe((values: Array<string>) => {
            this.infiltrationString = values['SLIICER_TABLE.CHARTS.INFILTRATION'];
            this.tracesString = values['SLIICER_TABLE.CHARTS.DAY_TRACES'];
            this.yAxisLabel = values['COMMON.FLOW_RATE'];
            this.xAxisLabel = values['CUSTOMER_EDITOR.TIME_PLACEHOLDER'];
        }));
    }

    public discardChanges() {
        this.selectedDryDays = [];
        this.selectDryDays = null;
        this.buildDryDayData(true);
    }

    public ngOnInit() {
        this.selectedDryDaysLength = 0;
        this.selectedDryDays = [];

        this.labelDecimal = this.dateutilService.isCustomerUnitsMetric.getValue() ? '{value:.1f}' : '{value:.2f}';
        this.valueDecimal = this.dateutilService.isCustomerUnitsMetric.getValue() ? 1 : 2;
        this.unitOfMeasure = this.dateutilService.customerUnit.getValue();

        this.subscriptions.push(this.sliicerService.caseStudyEditable.subscribe((editable: boolean) => {
            this.isStudyLocked = !editable;
        }));

        this.subscriptions.push(this.updatesWidgetService.updatesAction.subscribe((action) => {
            switch (action) {
                case 'undoChanges':
                    this.discardChanges();
                    break;
                default:
                case 'applyChanges':
                    break; // do noting
            }
        }));
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.basinDryDays) {
            if (!_.isEqual(changes.basinDryDays.currentValue, changes.basinDryDays.previousValue)) {
                this.selectedDryDaysLength = 0;
                this.selectedDryDays = [];
                this.buildDryDayData(true);
            }
        }

        if(changes.selectDryDays && changes.selectDryDays.currentValue) {
            const val = changes.selectDryDays.currentValue;
            this.selectDryDayTraceByDay(val);
        }

    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(v => v.unsubscribe());
    }

    /**
     * Tooltip enabled button event handler.
     */
    public onTooltipEnabledChanged() {
        this.tooltipEnabled = !this.tooltipEnabled;
        this.dirunalGraphComponent.diurnalGraph.update({
            tooltip: {
                enabled: this.tooltipEnabled,
            },
        });
    }

    /**
     * Chart clicked event handler.
     *
     * Clears the remove data button and clears any
     */
    public onChartClick(event) {
        event.preventDefault();
        this.destroyRemoveDataButton();
    }

    private colorIndexForWeekGroupName(weekGroupName: string): number {
        if (!this.colorIndexMap[weekGroupName]) {
            return 0;
        }
        return this.colorIndexMap[weekGroupName];
    }

    private setColorIndexForWeekGroupName(weekGroupName: string, index: number) {
        this.colorIndexMap[weekGroupName] = index;
    }

    public selectDryDayTraceByDay(day: {day: string, selected: boolean}) {
        // The day sometimes comes in the wrong format
        const date = new Date(day.day);
        let monthPrefix = "";
        let dayPrefix = "";
        if (date.getUTCMonth() < 9) {
            monthPrefix = "0";
        }
        if (date.getUTCDate() < 10) {
            dayPrefix = "0";
        }

        let formattedDay = `${monthPrefix}${date.getUTCMonth()+1}/${dayPrefix}${date.getUTCDate()}/${date.getUTCFullYear()}`

        const serie = this.dirunalGraphComponent.diurnalGraph.series.find(s => s.userOptions.fullName && s.userOptions.fullName === formattedDay);

        if(serie) {
            this.selectDryDayTrace(serie);
        }
    }

    selectedDryDays = [];
    selectedDryDaysLength = 0;

    private selectDryDayTrace(serie, emitChanges = false) {
        const seriesName = serie.userOptions.seriesName;
        const seriesText = seriesName.split(' ');
        const weekGroup = seriesText[0];
        const month = seriesText.length > 2 ? seriesText[1] : '';
        const day = seriesText.length > 2 ? seriesText[2] : seriesText[1];
        const weekGroupName = month !== '' ? weekGroup + ' ' + month : weekGroup;

        if (serie.color === blackColor) {
            const index = this.colorIndexForWeekGroupName(weekGroupName);
            const color = SliicerUtils.weekGroupTracesColor(weekGroupName, index);
            serie.update({ color: color }, true, false);
            delete this.selectedDryDays[seriesName];
            this.selectedDryDaysLength--;
        } else {
            serie.update({ color: blackColor }, true, false);
            this.selectedDryDays[seriesName] = serie;
            this.selectedDryDaysLength++;
        }

        // highlight the day on flow monitor hydrograph
        if(emitChanges) this.weekDaySelectionEvent.emit({ selectedDay: day });
    }

    private selectDryDayTraceBySeriesName(seriesName: string, emitChanges = false) {
        // Only day traces have 'seriesName' defined and we only
        // allow selection of0 day traces
        if (seriesName === undefined) {
            return;
        }

        const serie = this.dirunalGraphComponent.diurnalGraph.series.find(s => s.userOptions.seriesName === seriesName);

        this.selectDryDayTrace(serie, emitChanges);

        this.destroyRemoveDataButton();
    }

    private buildOptions(arr: Array<any>) {
        const units = this.dateutilService.customerUnit.getValue();
        const valueDecimal = this.dateutilService.isCustomerUnitsMetric.getValue() ? 1 : 2;

        const self = this;
        return {
            chart: {
                width: 100,
            },
            rangeSelector : {
                enabled: false,
            },
            navigator: {
                // this is required as a workaround to avoid a but when removing data from the series
                enabled: false,
            },
            credits: {
                enabled: false,
            },
            title: {
                text: '',
            },
            xAxis: {
                title: {
                    text: this.xAxisLabel,
                },
                tickInterval: 3,
                max: 24,
            },
            yAxis: {
                title: {
                    text: `${this.yAxisLabel} (${this.unitOfMeasure})`,
                },
                labels: {
                    format: this.labelDecimal,
                },
            },
            legend: {
                floating: false,
                align: 'right',
            },
            exporting: {
                enabled: false,
            },
            plotOptions: {
                series: {
                    turboThreshold: 0,
                    boostThreshold: 0,
                    dataGrouping: {
                        enabled: false,
                    },
                    states: {
                        hover: {
                            enabled: true,
                            lineWidth: LINE_WIDTH_HOVER
                        },
                        inactive: {
                            opacity: 1
                        }
                    },
                    pointInterval: this.stepLength / 60,
                    label: {
                        connectorAllowed: false,
                    },
                    pointStart: 0,
                    marker: {
                        enabled: false,
                    },
                    cursor: 'default',
                    point: {
                        events: {
                            click: function() {
                                self.selectDryDayTraceBySeriesName(this.series.userOptions.seriesName, true);
                            },
                        }
                    },
                    events: {
                        click: function () {
                            self.selectDryDayTraceBySeriesName(this.userOptions.seriesName, true);
                        },
                        legendItemClick: function () {
                            const plotName: string = this.name;
                            self.legendItemAction(plotName, this);

                            self.destroyRemoveDataButton();

                            return true;
                        }
                    },
                },
            },
            tooltip: {
                enabled: this.tooltipEnabled,
                valueDecimals: this.valueDecimal,
                formatter: function() {
                    const decimals = (units.toLowerCase() === 'mgd' || units.toLowerCase() === 'cfs') ? 3 : valueDecimal;
                    const yPoint = Number(this.y).toFixed(decimals);
                    const prefix = `Hour ${this.x}<br/>`
                    return `${prefix} <span style="color:${this.series.userOptions.originalColor}">\u25CF</span> ${this.series.userOptions.fullName}  <b> ${yPoint} </b> ${units}`
                },
                positioner() {
                    const horizontalOffset = 65;
                    const verticalOffset = 1;

                    return {
                        x: horizontalOffset,
                        y: verticalOffset,
                    };
                },
                shape: 'rect', // make sure the line (present for the default type 'callout') is not shown
            },
            series: arr,
        };
    }

    private legendItemAction(name: string, element) {
        const isBaseInfiltration = name.includes(this.infiltrationString);
        const isDayTrace = name.includes(this.tracesString);

        const groupName = name.replace(this.infiltrationString, '').replace(this.tracesString, '').trim();

        this.updateDryDayPlotsConfig(groupName, isBaseInfiltration, isDayTrace);

        // if current series is not day trace, then its hiding/showing already handled by highcharts
        if (!isDayTrace) {
            return true;
        }

        // for day trace series we need to find all series of the group and apply hiding/showing
        const toShow = !element.visible;

        setTimeout(() => {
            const dayTracesSeries = element.chart.series.filter(v => (v.userOptions.seriesName !== undefined && v.userOptions.seriesName.startsWith(groupName)));
            dayTracesSeries.forEach(v => v.update({ visible: toShow }, false, false));

            element.chart.redraw(false);
        }, 0);
    }

    private updateDryDayPlotsConfig(name: string, isBaseInfiltration: boolean, isDayTrace: boolean) {
        const useSeasons = this.sliicerService.hasSeasons();
        const useRegimes = this.sliicerService.hasRegimes();
        const useYears = this.sliicerService.hasYears();

        this.basinDryDays.dayOfWeekGroupDays.forEach((item: DayOfWeekGroupDays) => {
            const groupName = SliicerUtils.weekGroupName(item, useSeasons, useRegimes, useYears);

            if (groupName !== name) {
                return;
            }

            if (isBaseInfiltration) {
                item.showNetBaseInfiltrationPlot = !item.showNetBaseInfiltrationPlot;
            } else if (isDayTrace) {
                item.showTracePlot = !item.showTracePlot;
            } else {
                item.showGroupPlot = !item.showGroupPlot;
            }
        });

        this.sliicerService.updateDryDayBasin(this.customerId, this.basinDryDays, true).subscribe()
    }

    public removeSelectedDayTraces() {
        if (this.isStudyLocked || !this.isDryDaysEditActive) return;

        const selectedTraces = Object.values(this.selectedDryDays);
        // for each day trace group, there is one line which is holding the legend, if user removes it, we need to rebind the legend to another line of the same group
        const legendBindingDayTraces = selectedTraces.filter(v => v.name && v.name.includes(this.tracesString));

        legendBindingDayTraces.forEach((line => {
            const sameGroupTraces = this.dirunalGraphComponent.diurnalGraph.series.filter(v => v.options.legendName && v.options.legendName === line.name && line.name !== v.name);

            const notRemovedLines = sameGroupTraces.filter(item => !selectedTraces.find(v => v.options.legendName === item.options.legendName && v.options.seriesName === item.options.seriesName));

            if (notRemovedLines.length) {
                notRemovedLines[0].update({ name: line.name, showInLegend: true }, false, false);
            }
        }));

        for(const d of Object.values(selectedTraces)) {
            const day = d.userOptions.fullName;

            this.weekDaySelectionEvent.emit({ selectedDay: day, remove: true });
            d.remove(false, false, false);
            this.dryDayRemoved.emit(day);
        }

        this.dirunalGraphComponent.diurnalGraph.redraw(false);
        this.selectedDryDays = [];
        this.selectedDryDaysLength = 0;
    }

    /**
     * Starts timeout for clearing the remove data button.
     */
    private startRemoveDataButtonTimeout() {
        this.removeDataButtonTimeout = setTimeout(() => this.destroyRemoveDataButton(), removeDataButtonTimeoutLength);
    }

    /**
     * Clears the remove button timeout if it exists.
     */
    private clearRemoveDataButtonTimeout() {
        clearTimeout(this.removeDataButtonTimeout);
    }

    /**
     * Destroys all buttons that remove data series from the hydrograph.
     */
    private destroyRemoveDataButton(): void {
        if (this.removeDataButton && typeof this.removeDataButton.destroy === 'function') {
            this.removeDataButton.destroy();
        }

        this.removeDataButton = null;
    }

    private buildAndPlotDayTraces(dayTracesData: Array<SeriesData>, weekGroupName: string, toPlot?: boolean) {
        const seriesName = weekGroupName + ' ' + this.tracesString;
        const colorIndex = this.colorIndexForWeekGroupName(weekGroupName);
        const legendIndex = this.weekGroupNameLegendIndexMap[weekGroupName] + this.weekGroupCount * 2;
        const color = SliicerUtils.weekGroupColor(weekGroupName, colorIndex);
        dayTracesData.forEach((val, index) => {
            const data = val.data.map((d) => d.y);
            data.push(data[0]);
            if (index === 0 || data.length > 1 || data[0]) {
                const name = index === 0 ? seriesName : val['seriesName'];

                this.dirunalGraphComponent.addSeries(
                    {
                        // Only the first index for group traces show up in the legend and we want it
                        // named well. All others use the seriesName for uniqueness
                        name: name,
                        data: data,
                        boostThreshold: 0,
                        lineWidth: LINE_WIDTH_TRACE,
                        color: color,
                        originalColor: color,
                        showInLegend: index === 0,
                        // HACK: seriesName is not a Highcharts option. It does show up in userOptions
                        // when clicking the series on the chart.
                        seriesName: weekGroupName + ' ' + val['seriesName'],
                        fullName: val['seriesName'],
                        legendColor: color,
                        legendIndex: legendIndex,
                        legendName: seriesName,
                        visible: toPlot,
                        label: {
                            enabled: false
                        },
                    },
                    false,
                );
            }
        });
    }

    private emptyGraph() {
        this.weekGroupNameLegendIndexMap.clear();
        this.weekGroupCount = 0;
        const co = this.buildOptions([]);
        if (this.dirunalGraphComponent) {
            this.dirunalGraphComponent.plotDirunalGraph(co);
        }
    }

    private buildDryDayData(restoreSelected = false) {
        if (!this.basinDryDays) {
            this.emptyGraph();
            return;
        }

        this.lastSelected =
            !restoreSelected ||
            !this.dirunalGraphComponent?.diurnalGraph?.legend?.allItems ||
            this.dirunalGraphComponent.diurnalGraph.legend.allItems.length === 0
                ? null
                : this.dirunalGraphComponent.diurnalGraph.legend.allItems.map((i) => {
                      return {
                          name: i.name,
                          visible: i.visible,
                      };
                  });

        const useSeasons = this.sliicerService.hasSeasons();
        const useRegimes = this.sliicerService.hasRegimes();
        const useYears = this.sliicerService.hasYears();
        this.weekGroupNameLegendIndexMap = SliicerUtils.determineDayGroupLegendIndices(
            this.basinDryDays.dayOfWeekGroupDays,
            useSeasons,
            useRegimes,
            useYears,
        );

        this.weekGroupCount = this.basinDryDays.dayOfWeekGroupDays.length;

        const diurnalData = [];
        this.basinDryDays.dayOfWeekGroupDays.forEach((ele, index) => {
            const weekGroupName = SliicerUtils.weekGroupName(ele, useSeasons, useRegimes, useYears);
            const legendIndex = this.weekGroupNameLegendIndexMap[weekGroupName];

            this.setColorIndexForWeekGroupName(weekGroupName, index);
            const color = SliicerUtils.weekGroupColor(weekGroupName, index);

            const lastVisible = this.lastSelected ? this.lastSelected.find((x) => weekGroupName === x.name) : true;
            diurnalData.push({
                name: weekGroupName,
                fullName: weekGroupName,
                color: color,
                originalColor: color,
                boostThreshold: 0,
                zIndex: 9999 + index,
                lineWidth: LINE_WIDTH_MAIN,
                data: DryDayDiurnalGraphComponent.getDiurnalData(ele),
                legendIndex: legendIndex,
                states: {
                    hover: {
                        enabled: false
                    }
                },
                label: {
                    enabled: false
                },
                visible: ele.showGroupPlot
            });

            const lastVisibleInfiltration = this.lastSelected ? this.lastSelected.find((x) => weekGroupName + ' ' + this.infiltrationString === x.name) : false;
            const seriesName = weekGroupName + ' ' + this.infiltrationString;
            diurnalData.push({
                name: seriesName,
                fullName: seriesName,
                // tslint:disable-next-line: max-line-length
                data: DryDayDiurnalGraphComponent.getInfiltrationArray(ele, this.stepLength),
                lineWidth: LINE_WIDTH_INFILTRATION,
                boostThreshold: 0,
                color: color,
                originalColor: color,
                dashStyle: 'longdashdot',
                visible: ele.showNetBaseInfiltrationPlot,
                legendIndex: legendIndex + this.weekGroupCount,
                label: {
                    enabled: false
                },
            });
        });

        this.chartOptions = this.buildOptions(diurnalData);
        this.dirunalGraphComponent.plotDirunalGraph(this.chartOptions);

        const getDayTracesRequests = this.basinDryDays.dayOfWeekGroupDays
            .filter(v => v.days && v.days.length)
            .map((item: DayOfWeekGroupDays) => {
                const weekGroupName = SliicerUtils.weekGroupName(item, useSeasons, useRegimes, useYears);

                const result = this.sliicerService.basinHgFlowData.pipe(
                    filter(v => !!v),
                    map((flowData: SeriesData) => {
                        // #40470 Cost is now O(n+m), n=item.days.length, m=flowData.data.length
                        const flowDataAssoc = [];
                        for(const d of flowData.data) {
                            const index = new Date(d.x).setHours(0, 0, 0);
                            if(!flowDataAssoc[index]) flowDataAssoc[index] = [];
                            flowDataAssoc[index].push(d);
                        }

                        const series = item.days.map((day: string) => {
                            const dayIndex = new Date(day).getTime();

                            const currentDayData = [...flowDataAssoc[dayIndex]];

                            const seriesName = this.datePipe.transform(day, this.dateutilService.getFormat());
                            return { data: currentDayData, seriesName };
                        });

                        return { series, weekGroupName };
                    }),
                    first()
                )

                return { result, showTracePlot: item.showTracePlot };
            });

        this.subscriptions.push(combineLatest(getDayTracesRequests.map(v => v.result)).subscribe((res) => {
            if (!res) return;

            res.forEach((data, index) => {
                if (data) {
                    this.buildAndPlotDayTraces(data.series, data.weekGroupName, getDayTracesRequests[index].showTracePlot);
                }
            });

            this.dirunalGraphComponent.diurnalGraph.redraw(false);
        }));
    }

    ///
    /// STATIC METHODS
    ///

    /**
     * Get an array of base infiltration points to show from the dry day stats for the given day of week name
     * @param groupData the day of week group days object to find the data in
     * @param stepLength the step length to get correct number of data points
     *                   TODO: WPS - we only need two points since this is a straignt line
     */
    private static getInfiltrationArray(groupData: DayOfWeekGroupDays, stepLength: number) {
        if (groupData && groupData['stats']) {
            const baseInfiltration = groupData['stats']['grossBaseInfiltration'];
            const totalTicks = 24 * (60 / stepLength) + 1;
            const baseInfiltrationArr = [totalTicks];
            for (let i = 0; i < totalTicks; i++) {
                baseInfiltrationArr[i] = baseInfiltration;
            }
            baseInfiltrationArr[totalTicks] = baseInfiltration;
            return baseInfiltrationArr;
        } else {
            return [];
        }
    }

    /**
     * Get the average diurnal graph data from the dry day stats for the given day of week name
     * @param groupData the day of week group days object to find the data in
     */
    private static getDiurnalData(groupData: DayOfWeekGroupDays): number[] {
        if (groupData && groupData.stats && groupData.stats.grossDiurnalCurveData) {
            // TODO: WPS - cache... maybe
            const data = Object.assign([], groupData.stats.grossDiurnalCurveData);
            data.push(data[0]);
            return data;
        } else {
            return [];
        }
    }

    private jumpScroll(direction: boolean) {
        const shownTimeSpan = this.shownDateRange[1] - this.shownDateRange[0];
        let newRange: number[];

        if (direction) {
            // Jump forward
            newRange = [this.shownDateRange[1], this.shownDateRange[1] + shownTimeSpan];
            if (newRange[0] > new Date(this.selectedDateRange[1]).getTime()) {
                return;
            }
        } else {
            // Jump backward
            newRange = [this.shownDateRange[0] - shownTimeSpan, this.shownDateRange[0]];
            if (newRange[1] < new Date(this.selectedDateRange[0]).getTime()) {
                return;
            }
        }
        this.useNavigator(newRange);
    }

    private checkJumpScrollButtonsEnabled() {
        const shownTimeSpan = this.shownDateRange[1] - this.shownDateRange[0];
        const newForwardRange = [this.shownDateRange[1] + 1, this.shownDateRange[1] + 1 + shownTimeSpan];
        const newBackRange = [this.shownDateRange[0] - 1 - shownTimeSpan, this.shownDateRange[0] - 1];

        const defaultClass = 'jump-scroll-btn';
        const disabledClass = ' disabled';

        // Set to required class first
        this.chartOptions.exporting.buttons.forward.className = defaultClass;
        this.chartOptions.exporting.buttons.back.className = defaultClass;

        // Add disabled class if needed
        if (newForwardRange[0] > this.selectedDateRange[1]) {
            this.chartOptions.exporting.buttons.forward.className += disabledClass;
        }
        if (newBackRange[1] < this.selectedDateRange[0]) {
            this.chartOptions.exporting.buttons.back.className += disabledClass;
        }
    }

    private useNavigator(newRanges: number[]) {
        this.shownDateRange = newRanges;
        this.checkJumpScrollButtonsEnabled();
        this.dirunalGraphComponent.diurnalGraph.xAxis[0].setExtremes(newRanges[0], newRanges[1])
    }

    private clickZoomButton(buttonId: number) {
        // Default zoom button action takes time from end of chart
        let min = this.selectedDateRange[0];
        const max = this.selectedDateRange[1];
        const oneDay = 60 * 60 * 24 * 1000;

        const self = this;

        switch (buttonId) {
            case 0: {
                // 1 day
                min = max - oneDay;
                this.chartOptions.rangeSelector.selected = 0;
                break;
            }
            case 1: {
                // 1 week
                min = max - oneDay * 7;
                this.chartOptions.rangeSelector.selected = 1;
                break;
            }
            case 2: {
                // 1 month
                min = max - oneDay * 30;
                this.chartOptions.rangeSelector.selected = 2;
                break;
            }
            case 3:
            default: {
                this.chartOptions.rangeSelector.selected = 3;
                break;
            }
        }
        this.shownDateRange = [min, max];
        this.checkJumpScrollButtonsEnabled();
    }
}
