import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, ParamMap, Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'app/pages/admin/users.service';
import { VELOCITY_ENTITY, DEPTH_ENTITY, RAIN_ENTITY, QUANTITY_ENTITY, Q_A1_ENTITY, Q_A2_ENTITY } from 'app/shared/constant';
import { Paging } from 'app/shared/models/paging.model';
import { OrderByPipe } from 'app/shared/pipes/order-by-pipe';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { StringUtils } from 'app/shared/utils/string-utils';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';

import { DataEditingAuditReportArgs, DataEditingAuditReportItem } from './data-editing-audit-report.model';
import { DatePickerComponent } from 'app/shared/components/date-picker/date-picker.component';
import { MultiSelectAutoCompleteComponent } from 'app/shared/components/multi-select/multi-select-auto-complete/multi-select-auto-complete.component';
import { MultiSelectGroupComponent } from 'app/shared/components/multi-select/multi-select-group/multi-select-group.component';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import moment from 'moment';
import { ViewDataService } from 'app/shared/services/view-data.service';
import { EntityService } from 'app/shared/services/entity.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { DataEditService } from 'app/shared/services/data-edit.service';
import { Selectable, SelectableGroup } from 'app/shared/models/selectable';
import { DataEditReport } from 'app/shared/models/data-edit';
import {
    activeInactiveLocationQueryParam,
    customerLocationGroupQueryParam,
    customerQueryParam,
} from 'app/shared/models/customer';
import { LocationArgs, Locations } from 'app/shared/models/locations';
import { GetUsersResponse } from 'app/shared/models/users-permission';
import { Entities } from 'app/shared/models/entities';
import { DataEditingReasons } from 'app/shared/models/view-data';

@Component({
    selector: 'ads-data-editing-audit-report',
    templateUrl: './data-editing-audit-report.component.html',
    styleUrls: ['./data-editing-audit-report.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AdsDataEditingAuditReportComponent implements OnDestroy, OnInit {
    public isLoading: boolean;
    public enableExport: boolean;
    public customerId: number;
    public locationGroupId: number;
    public pageSize = 50;
    public pageIndex = 0;
    public startDaysRange = 30;
    public isIncludeInactiveLocations: boolean;
    public maxCalenderDate = new Date();
    public reportDataSource: MatTableDataSource<any>;
    public reportDataSourceChange = new BehaviorSubject<Array<DataEditingAuditReportItem>>([]);
    public reportRowCount: number;
    public isRequestProcessing: boolean;
    public reportFilters: DataEditingAuditReportArgs = {
        startTime: new Date(
            Date.UTC(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - this.startDaysRange,
                0,
                0,
                0,
            ),
        ),
        endTime: new Date(
            Date.UTC(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                new Date().getHours(),
                new Date().getMinutes(),
                new Date().getSeconds(),
            ),
        ),
        editedStartTime: new Date(
            Date.UTC(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - this.startDaysRange,
                0,
                0,
                0,
            ),
        ),
        editedEndTime: new Date(
            Date.UTC(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                new Date().getHours(),
                new Date().getMinutes(),
                new Date().getSeconds(),
            ),
        ),
        paging: <Paging>{
            startPage: 0,
            pageSize: this.pageSize,
            sort: 'timestamp',
            sortDirection: 'desc',
            searchValue: null,
        },
    };
    public filterLocations: Array<Selectable> = [];
    public filterEntities: Array<SelectableGroup> = [];
    public filterUsers: Array<any> = [];
    public filterEditReasons: Array<Selectable> = [];
    public editableEntities: Array<number> = [VELOCITY_ENTITY, DEPTH_ENTITY, QUANTITY_ENTITY, RAIN_ENTITY, Q_A1_ENTITY, Q_A2_ENTITY];
    public showFilters: boolean;
    public tableColumns = ['location', 'timestamp', 'entity', 'user', 'editDate', 'reason'];

    // translation variables
    public apiErrorMessage: string;
    public errorActionTitle: string;

    // filter variables
    public isInValidStartDate = false;
    public isInValidEndDate = false;
    public isInValidEditedDate = false;
    @ViewChild(MatPaginator) public matPaginator: MatPaginator;
    @ViewChild(MatSort) public matTableSort: MatSort;
    @ViewChild('adsDatePicker') public adsDatePicker: DatePickerComponent;
    @ViewChild('adsEditedDatePicker') public adsEditedDatePicker: DatePickerComponent;
    @ViewChild('locationSelector') public locationSelector: MultiSelectAutoCompleteComponent;
    @ViewChild('entitiesGroupMultiselect') public entitiesGroupMultiselect: MultiSelectGroupComponent;
    // observables
    private componentSubscriptions: Array<Subscription> = [];
    private filterEntitiesCopy: Array<SelectableGroup> = [];
    public noneUser: boolean;
    private dateFormat: string;
    public dateFormatComplete: string;
    private pageChangeBool: boolean;
    constructor(
        private activatedRoute: ActivatedRoute,
        private changeDetectionRef: ChangeDetectorRef,
        private translateService: TranslateService,
        private uiUtilService: UiUtilsService,
        private dataEditService: DataEditService,
        private notificationService: SnackBarNotificationService,
        private userService: UsersService,
        private entityService: EntityService,
        private statusCodeService: StatusCodeService,
        private viewDataService: ViewDataService,
        private router: Router,
        private dateUtilService: DateutilService,
    ) {}

    public ngOnInit() {
        this.setQueryParams();
        this.applyTranslations();
        this.bindSelectionOptions();
        this.bindDataEditReasons();
        if (this.customerId) {
            this.generateReport();
        }

        this.componentSubscriptions.push(
            this.router.events.subscribe((e: any) => {
                // If it is a NavigationEnd event re-initalise the component
                if (e instanceof NavigationEnd) {
                    this.globalOptionSelectionChange(true);
                }
            }),
        );
    }

    public ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.forEach((subscription) => subscription.unsubscribe());
        }
    }

    public sortData(sort: Sort) {
        this.reportFilters.paging.sort = sort.active === 'editDate' ? 'edittime' : sort.active;
        this.reportFilters.paging.sortDirection = sort.direction;
        this.updateGeneratedReport();
    }

    public toggleFilters() {
        this.showFilters = !this.showFilters;
    }

    private updateGeneratedReport() {
        if (this.isRequestProcessing) {
            return;
        }

        if (this.adsDatePicker) {
            this.reportFilters.startTime = new Date(
                Date.UTC(
                    this.adsDatePicker.startDate.getFullYear(),
                    this.adsDatePicker.startDate.getMonth(),
                    this.adsDatePicker.startDate.getDate(),
                    this.adsDatePicker.startDate.getHours(),
                    this.adsDatePicker.startDate.getMinutes(),
                    0,
                ),
            );
            this.reportFilters.endTime = new Date(
                Date.UTC(
                    this.adsDatePicker.endDate.getFullYear(),
                    this.adsDatePicker.endDate.getMonth(),
                    this.adsDatePicker.endDate.getDate(),
                    this.adsDatePicker.endDate.getHours(),
                    this.adsDatePicker.endDate.getMinutes(),
                    0,
                ),
            );
        }

        if (this.adsEditedDatePicker) {
            this.reportFilters.editedStartTime = new Date(
                Date.UTC(
                    this.adsEditedDatePicker.startDate.getFullYear(),
                    this.adsEditedDatePicker.startDate.getMonth(),
                    this.adsEditedDatePicker.startDate.getDate(),
                    this.adsEditedDatePicker.startDate.getHours(),
                    this.adsEditedDatePicker.startDate.getMinutes(),
                    0,
                ),
            );
            this.reportFilters.editedEndTime = new Date(
                Date.UTC(
                    this.adsEditedDatePicker.endDate.getFullYear(),
                    this.adsEditedDatePicker.endDate.getMonth(),
                    this.adsEditedDatePicker.endDate.getDate(),
                    this.adsEditedDatePicker.endDate.getHours(),
                    this.adsEditedDatePicker.endDate.getMinutes(),
                    0,
                ),
            );
        }

        this.reportFilters.paging.pageSize = this.pageSize;
        this.reportFilters.paging.startPage = this.pageChangeBool ? this.pageIndex : 1;
        this.pageChangeBool = false;
        this.isRequestProcessing = true;
        this.isLoading = true;
        this.dataEditService.dataEditingAuditReport(this.customerId, this.reportFilters).subscribe(
            (result: DataEditReport) => {
                this.enableExport = false;
                if (result === null || !result.count) {
                    if (this.reportDataSource) {
                        this.reportDataSource.data = [];
                        this.reportRowCount = 0;
                        if(this.matPaginator) this.matPaginator.length = this.reportRowCount;
                    }
                    this.completeRequest();
                    return;
                }
                if (this.reportDataSource) {
                    this.enableExport = true;
                    this.reportDataSource.data = result.logs;
                } else {
                    this.enableExport = true;
                    this.reportDataSource = new MatTableDataSource(result.logs);
                    this.reportDataSource.sort = this.matTableSort;
                    this.reportDataSource.paginator = this.matPaginator;
                }
                this.reportRowCount = result.count;
                if(this.matPaginator) this.matPaginator.length = this.reportRowCount;
                this.completeRequest();
            },
            () => {
                this.notificationService.raiseNotification(this.apiErrorMessage, this.errorActionTitle, {
                    panelClass: 'custom-error-snack-bar',
                }, false);
                this.completeRequest();
            },
        );
    }

    public generateReport() {
        if (this.isRequestProcessing) {
            return;
        }

        if (this.adsDatePicker) {
            this.reportFilters.startTime = new Date(
                Date.UTC(
                    this.adsDatePicker.startDate.getFullYear(),
                    this.adsDatePicker.startDate.getMonth(),
                    this.adsDatePicker.startDate.getDate(),
                    this.adsDatePicker.startDate.getHours(),
                    this.adsDatePicker.startDate.getMinutes(),
                    0,
                ),
            );
            this.reportFilters.endTime = new Date(
                Date.UTC(
                    this.adsDatePicker.endDate.getFullYear(),
                    this.adsDatePicker.endDate.getMonth(),
                    this.adsDatePicker.endDate.getDate(),
                    this.adsDatePicker.endDate.getHours(),
                    this.adsDatePicker.endDate.getMinutes(),
                    0,
                ),
            );
        }

        if (this.adsEditedDatePicker) {
            this.reportFilters.editedStartTime = new Date(
                Date.UTC(
                    this.adsEditedDatePicker.startDate.getFullYear(),
                    this.adsEditedDatePicker.startDate.getMonth(),
                    this.adsEditedDatePicker.startDate.getDate(),
                    this.adsEditedDatePicker.startDate.getHours(),
                    this.adsEditedDatePicker.startDate.getMinutes(),
                    0,
                ),
            );
            this.reportFilters.editedEndTime = new Date(
                Date.UTC(
                    this.adsEditedDatePicker.endDate.getFullYear(),
                    this.adsEditedDatePicker.endDate.getMonth(),
                    this.adsEditedDatePicker.endDate.getDate(),
                    this.adsEditedDatePicker.endDate.getHours(),
                    this.adsEditedDatePicker.endDate.getMinutes(),
                    0,
                ),
            );
        }

        this.reportFilters.paging.pageSize = this.pageSize;
        this.reportFilters.paging.startPage = 0;
        this.isRequestProcessing = true;
        this.isLoading = true;
        this.dataEditService.dataEditingAuditReport(this.customerId, this.reportFilters).subscribe(
            (result: DataEditReport) => {
                this.dateFormat = `${String(this.dateUtilService.getFormat()).toUpperCase()}`;
                this.dateFormatComplete = `${this.dateFormat} ${this.dateUtilService.getTimeFormat()}`;
                if (result === null || !result.count) {
                    this.reportDataSource = null;
                    this.reportRowCount = 0;
                    if(this.matPaginator) this.matPaginator.length = this.reportRowCount;
                    this.completeRequest();
                    return;
                }
                if (this.reportDataSource) {
                    this.enableExport = true;
                    this.reportDataSource.data = result.logs;
                } else {
                    this.reportDataSource = new MatTableDataSource(result.logs);
                    this.reportDataSource.sort = this.matTableSort;
                    this.reportDataSource.paginator = this.matPaginator;
                }
                this.reportRowCount = result.count;
                if(this.matPaginator) this.matPaginator.length = this.reportRowCount;
                this.completeRequest();
            },
            () => {
                this.notificationService.raiseNotification(this.apiErrorMessage, this.errorActionTitle, {
                    panelClass: 'custom-error-snack-bar',
                }, false);
                this.completeRequest();
            },
        );
    }

    public applyFilters() {
        this.updateGeneratedReport();
    }

    public resetFilters(refreshReport = true) {
        this.reportFilters = {
            startTime: new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - this.startDaysRange,
                0,
                0,
                0,
            ),
            endTime: new Date(),
            editedStartTime: new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - this.startDaysRange,
                0,
                0,
                0,
            ),
            editedEndTime: new Date(),
            paging: <Paging>{
                startPage: 0,
                pageSize: this.pageSize,
            },
        };
        if (refreshReport) {
            this.updateGeneratedReport();
        }
        if (this.locationSelector) {
            this.locationSelector.clearSelected();
        }
        if (this.entitiesGroupMultiselect) {
            this.entitiesGroupMultiselect.reset(this.filterEntitiesCopy);
        }
    }

    public exportReport() {
        const csvData = new Array<Object>();
        const csvHeaders = ['Location Name', 'Timestamp', 'Entities', 'Edited By', 'Edited On', 'Comment'];

        if (this.reportDataSource && this.reportDataSource.data && this.reportDataSource.data.length > 0) {
            this.reportDataSource.data.forEach((arrayItem, arrayItemIndex) => {
                const newItem = new Array<string>();

                newItem.push(arrayItem.location);
                newItem.push(arrayItem.timestamp);
                newItem.push(arrayItem.entity);
                newItem.push(arrayItem.user);
                newItem.push(arrayItem.editDate);
                newItem.push(arrayItem.reason);

                csvData.push(newItem);
            });

            const options = {
                showLabels: true,
                headers: csvHeaders,
                title: `DATA EDITING AUDIT REPORT from ${moment(this.reportFilters.startTime).format(
                    this.dateFormat,
                )} to ${moment(this.reportFilters.endTime).format(this.dateFormat)}`,
                showTitle: true,
            };

            const result = new AngularCsv(csvData, 'DATA EDITING AUDIT REPORT', options);
        }
    }

    public selectedLocations(event: Array<Selectable>) {
        this.reportFilters.locationIds = event.filter((f) => f.isChecked).map((m) => m.id);
    }

    public selectedEntities(event: Array<Selectable>) {
        this.reportFilters.entityIds = event.filter((f) => f.isChecked).map((m) => m.id);
    }

    public selectedUser(event: Selectable) {
        this.reportFilters.editedBy = event.id;
    }

    public pageChange(event) {
        if (event) {
            this.isLoading = true;
            this.pageIndex = event.pageIndex;
            this.pageSize = event.pageSize;
            if (this.matPaginator) {
                this.matPaginator.pageIndex = this.pageIndex;
                this.matPaginator.pageSize = this.pageSize;
            }
            this.pageChangeBool = true;
            this.updateGeneratedReport();
        }
    }

    private applyTranslations() {
        const translateKeys: Array<string> = ['COMMON.API_FAILURE_MESSAGE', 'COMMON.ERROR_COLUMN_TITLE'];

        this.translateService.get(translateKeys).subscribe((values) => {
            this.apiErrorMessage = values['COMMON.API_FAILURE_MESSAGE'];
            this.errorActionTitle = values['COMMON.ERROR_COLUMN_TITLE'];
        });
    }

    private setQueryParams() {
        this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            this.isIncludeInactiveLocations = Boolean(Number(params.get(activeInactiveLocationQueryParam)));
            this.customerId = Number(params.get(customerQueryParam) || 0);
            this.locationGroupId = Number(params.get(customerLocationGroupQueryParam) || 0);
        });
    }

    private completeRequest() {
        this.isLoading = false;
        this.isRequestProcessing = false;
        this.uiUtilService.safeChangeDetection(this.changeDetectionRef);
    }

    private bindSelectionOptions(refreshReport?: boolean) {
        const locationArgs = <LocationArgs>{
            customerId: this.customerId,
            locationGroupId: this.locationGroupId,
            IncludeInactiveLocations: this.isIncludeInactiveLocations,
        };

        // Fetch the locations and users for selected customer
        const observableLocation = this.userService.getLocationsList(locationArgs);
        const userRole = this.statusCodeService.userInfo.getValue().userRole;
        if (userRole) {
            this.noneUser = userRole[0] === 'None';
        }
        const observableUser = this.noneUser
            ? new Observable()
            : this.userService.getCustomerUsers(this.customerId, false, 0);

        const makeApiCallSubscription = forkJoin(observableLocation, observableUser).subscribe(
            (result: [Array<Locations>, GetUsersResponse]) => {
                if (!result) {
                    return;
                }

                this.bindLocations(result[0]);
                this.bindUsers(result[1]);
                if (refreshReport) {
                    this.generateReport();
                }
            },
            () => {
                this.notificationService.raiseNotification(this.apiErrorMessage, this.errorActionTitle, {
                    panelClass: 'custom-error-snack-bar',
                }, false);
            },
        );

        this.componentSubscriptions.push(makeApiCallSubscription);
    }

    private bindLocations(locations: Array<Locations>) {
        this.filterLocations = [];
        if (locations && locations.length > 0) {
            this.filterLocations.push(
                ...locations.map((m) => {
                    return <Selectable>{
                        id: m.locationId,
                        name: m.locationName,
                    };
                }),
            );
            this.filterLocations = new OrderByPipe().transform(this.filterLocations, 'name', false);
            this.bindEntities();
        }
    }

    private bindEntities() {
        this.filterEntities = [];
        this.filterEntitiesCopy = [];
        this.reportFilters.entityIds = [];
        this.entityService
            .getEntitiesByLocation(
                this.customerId,
                this.filterLocations.map(({ id }) => id),
            )
            .subscribe((entities) => {
                if (entities && entities.length) {
                    const uniqueEntities = StringUtils.applyUniqueAndSort<Array<Entities>>(
                        entities,
                        'entityName',
                        'entityName',
                        true,
                    );

                    uniqueEntities.map((entity) => {
                        if (this.editableEntities.indexOf(entity.entityId) > -1) {
                            this.reportFilters.entityIds.push(entity.entityId);
                            this.filterEntities.push({
                                name: entity.entityName,
                                id: entity.entityId,
                                groupName: entity.displayGroupName,
                                groupId: entity.displayGroup,
                                isChecked: true,
                            });
                        }
                    });

                    const uniqueEntitiesCopy = StringUtils.applyUniqueAndSort<Array<Entities>>(
                        entities,
                        'entityName',
                        'entityName',
                        true,
                    );
                    uniqueEntitiesCopy.map((entity) => {
                        if (this.editableEntities.indexOf(entity.entityId) > -1) {
                            // this.reportFilters.entityIds.push(
                            //     entity.entityId
                            // );
                            this.filterEntitiesCopy.push({
                                name: entity.entityName,
                                id: entity.entityId,
                                groupName: entity.displayGroupName,
                                groupId: entity.displayGroup,
                                isChecked: true,
                            });
                        }
                    });
                }
            });
    }

    private bindUsers(response: GetUsersResponse) {
        this.filterUsers = [];
        if (response && response.payload && response.payload.length) {
            const users = response.payload;
            this.filterUsers.push(
                ...users.map((m) => {
                    return {
                        id: String(m.userID),
                        name: m.userName,
                    };
                }),
            );
            this.uiUtilService.safeChangeDetection(this.changeDetectionRef);
        }
    }

    public bindDataEditReasons() {
        this.filterEditReasons = [];
        this.uiUtilService.safeChangeDetection(this.changeDetectionRef);
        this.viewDataService.DataEditReasons.subscribe(
            (reason: DataEditingReasons) => {
                if (reason) {
                    const reasons: any = reason;
                    this.filterEditReasons.push(
                        ...reasons.map((m) => {
                            return <Selectable>{
                                name: m.reason,
                                id: m.id,
                            };
                        }),
                    );
                }
            },
            () => {
                this.notificationService.raiseNotification(this.apiErrorMessage, this.errorActionTitle, {
                    panelClass: 'custom-error-snack-bar',
                }, false);
            },
        );
    }

    private globalOptionSelectionChange(refreshReport?: boolean) {
        let reloadOptions = false;
        this.statusCodeService.isCustomerChanged.subscribe((flag) => {
            if (flag) {
                reloadOptions = flag;
            }
        });

        this.statusCodeService.isLocationGroupChanged.subscribe((flag) => {
            if (flag) {
                reloadOptions = flag;
            }
        });

        if (reloadOptions) {
            this.setQueryParams();
            this.resetFilters(false);
            this.bindSelectionOptions(refreshReport);
        }
    }
}
