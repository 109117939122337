<div [class]="containerCssClass">
    <div *ngIf="isLoading" class="loader-position">
        <mat-progress-spinner diameter="25" mode="indeterminate" color="primary"></mat-progress-spinner>
    </div>
    <div *ngIf="!showGraph && !isLoading" class="notabledataFound custom-table print-nodata-space">
        <span>{{ 'COMMON.NO_DATA_IN_GRAPH_MSG' | translate }}</span>
    </div>
    <div class="dynamicGraph" #chart [id]="chartId">
        <div *ngIf="showGraph" type="StockChart" [chart]="chartOptions"></div>
    </div>
</div>
