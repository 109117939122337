import { SeriesProperty } from 'app/pages/dashboards/custom-dashboard/custom-dashboard-model/custom-dashboard.model';
import { LocationData } from './locations-entities-data';

export interface ViewDataFilterArgs {
    isExportPrintEnabled: boolean;
    isDisplayExportMenu?: boolean;
    isDisplayEntities?: boolean;
    isDisplayDatePicker?: boolean;
    isScatterDisplayed?: boolean;
    isDisplayDataAveraging?: boolean;
}

export interface SavedConfiguration {
    annotations?: ConfigurationAnnotation;
    cid?: number;
    createdOn?: string;
    guid?: string;
    is_public?: boolean;
    is_writable?: boolean;
    lastViewed?: Date | string;
    locationGroupID?: number;
    name?: string;
    selectedGenerationOptions?: number;
    selectedRange?: number;
    selected_entities?: number[];
    selected_sg_entities?: number[];
    timeSpan?: number;
    ttl?: number;
    useDataAveraging?: number;
    _etag?: string;
    _ts?: number;
}

export interface ConfigurationAnnotation {
    allAlarmThresholdsHighHigh?: boolean;
    allAlarmThresholdsHighFlow?: boolean;
    allAlarmThresholdsHighLevel?: boolean;
    allAlarmThresholdsLowDepth?: boolean;
    bestFitCurve?: boolean;
    confirmationPoints?: boolean;
    dataQuality?: boolean;
    fraudLines?: boolean;
    isoQLines?: boolean;
    manholeDepth?: boolean;
    pipeHeight?: boolean;
    pipeOverlay?: boolean;
    rainOnTop?: boolean;
    scatterInvert?: boolean;
    showEdits?: boolean;
    showToolbar?: boolean;
    silt?: boolean;
    ssCurve?: boolean;
    toleranceLines?: boolean;
    showEvents?: boolean;
}

export interface MultipleLocationsDialogData {
    customerId: number;
    startDate: Date;
    endDate: Date;
    prevData: SeriesProperty[] | null;
    currentLocation: LocationData;
}

export enum ConfirmationEntitiesEnum {
    Average = 0,
    Peak = 1
}
export interface ConfirmationEntities {
    name: string;
    id: ConfirmationEntitiesEnum
}
