<div id="MonitorConfigurationPageId" class="contentLayout">
    <mat-card>
        <mat-card-header>
            <div class="auto-margin">{{ 'REPORT.MONITOR_CONFIGURATION.TITLE' | translate }}</div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin custom-flat-button">
                <button (click)="download()" mat-button>
                    {{ 'COMMON.DOWNLOAD_BTN' | translate }}
                </button>
            </div>
            <div class="auto-margin pad-r-15">
                <mat-icon
                    matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                    class="backIcon"
                    [ngClass]="{ active: showFilters }"
                    (click)="showFilters = !showFilters"
                    >filter_list</mat-icon
                >
            </div>
        </mat-card-header>
        <div class="filters-container" *ngIf="showFilters">
            <div class="custom-width loc-config-filter-item">
                <mat-form-field class="full-width">
                    <mat-select
                        placeholder="{{ 'COMMON.SELECT_TYPE' | translate }}"
                        class="full-width"
                        [value]="selectedType"
                        (selectionChange)="filterTypeChange($event)"
                    >
                        <mat-option *ngFor="let type of filterTypes" [value]="type.id">
                            {{ type.text }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="custom-width loc-config-filter-item" *ngIf="selectedType === filterTypesEnum.LocationGroup">
                <mat-form-field class="full-width">
                    <mat-select
                        placeholder="{{ 'COMMON.LOCATION_GROUP' | translate }}"
                        class="full-width"
                        (selectionChange)="locationGroupChanged($event)"
                    >
                        <mat-option *ngFor="let group of locGroups" [value]="group.id">
                            {{ group.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="custom-width loc-config-filter-item" *ngIf="selectedType === filterTypesEnum.Location">
                <app-multi-select-auto-complete
                    [items]="locations"
                    [isShowAll]="true"
                    (currentlySelectedItems)="locationSelected($event)"
                    placeholder=" {{ 'COMMON.LOCATIONS' | translate }}"
                >
                </app-multi-select-auto-complete>
            </div>

        </div>
        <mat-card-content>
            <app-loader [isLoading]="isLoading"></app-loader>

            <div id="monitorConfigurationTable">
                <mat-table *ngIf="reportDetails.length > 0" #table [dataSource]="reportDetails">
                    <ng-container matColumnDef="locationName" sticky>
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.LOC_NAME' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.locationName || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="mpNumber">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.MP_NUMBER' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.monitoringPointNumber || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="locType">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.LOC_TYPE' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.locationType || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="locShape">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.LOC_SHAPE' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.locationShape || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="locGroup">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.LOC_GROUP' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ getLocationGroupText(element) }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="monitorSeries">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.MONITOR_SERIES' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.monitorSeries || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="serialNumber">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.SERIAL_NUMBER' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.serialNumber || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="ipNumber">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.IP_ADDRESS' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.ipAddress || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="commType">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.COMM_TYPE' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.communicationType || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="sensors">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.SENSORS' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.sensors || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.STATUS' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.isActive ? 'Active' : 'Inactive' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="latitude">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.LATITUDE' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.latitude ? element.latitude.toFixed(4) : '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="longitude">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.LONGITUDE' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.longitude ? element.longitude.toFixed(4) : '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="compositeLocs">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.COMPOSITE_LOC' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.compositeLocation ? 'Yes' : 'No' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="manholeDepth">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.MANHOLE_DEPTH' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.manholeDepth ? element.manholeDepth.toFixed(2) : '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="height">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.HEIGHT' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.height ? element.height.toFixed(2) : '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="width">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.WIDTH' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.width ? element.width.toFixed(2) : '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="capacity">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.CAPACITY' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.capacity || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="silt">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.SILT' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.silt || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="hydraulicCoeff">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.HYDRAULIC_COEFF' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.hydraulicCoefficient || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="vgain">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.VGAIN' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.vgain || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="rainGauge">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.RAIN_GAUGE' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.associatedRainGuage || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="overflow">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.OVERFLOW' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.isOverflow ? 'Yes' : 'No' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="analysisTriggerEvent">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.ANALYSIS_TRIGGER_EVENT' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.analysisTriggerEvent || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="highDepthActivationDate">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.HIGH_DEPTH_DATE' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ (element.alarms && element.alarms.highDepthThresholdActivation) ? (element.alarms.highDepthThresholdActivation | date : customDateFormat) : '--' }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="highDepth">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.HIGH_DEPTH' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.alarms?.highDepthThreshold || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="highHighActivationDate">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.HIGH_HIGH_DATE' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ (element.alarms && element.alarms.highHighThresholdActivation) ? (element.alarms.highHighThresholdActivation | date : customDateFormat) : '--' }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="highFlow">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.HIGH_FLOW' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.alarms?.highFlowThreshold || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="highFlowActivationDate">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.HIGH_FLOW_DATE' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ (element.alarms && element.alarms.highFlowThresholdActivation) ? (element.alarms.highFlowThresholdActivation | date : customDateFormat) : '--' }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="highHigh">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.HIGH_HIGH' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.alarms?.highHighThreshold || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="lowDepthActivationDate">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.LOW_DEPTH_DATE' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{ (element.alarms && element.alarms.lowDepthThresholdActivationDate) ? (element.alarms.lowDepthThresholdActivationDate | date : customDateFormat) : '--' }}
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="lowDepth">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.LOW_DEPTH' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.alarms?.lowDepthThreshold || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="nextCollect">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.NEXT_DATA_COLLECT' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.nextDataCollect || '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="sampleRateNormalSeconds">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.SAMPLE_RATE' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.sampleRateNormalSeconds ? (element.sampleRateNormalSeconds | secondsToSampleRatePipe) : '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="sampleRateFastSeconds">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.FAST_SAMPLE_RATE' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element.sampleRateFastSeconds ? (element.sampleRateFastSeconds | secondsToSampleRatePipe) : '--' }} </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="scheduleInterval">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'REPORT.MONITOR_CONFIGURATION.COLUMN_HEADERS.SCHEDULE_INTERVAL' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let element"> {{ element?.scheduledInterval || '--' }} </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
                </mat-table>

                <div *ngIf="reportDetails.length === 0" class="notabledataFound custom-table">
                    <span>{{ 'REPORT.MONITOR_CONFIGURATION.NO_DATA_MSG' | translate }}</span>
                </div>
            </div>
            <div [hidden]="totalPaginationLength <= 10">
                <mat-paginator
                    #paginator
                    [length]="totalPaginationLength"
                    [pageIndex]="0"
                    [pageSize]="10"
                    (page)="onPaginateChange()"
                    [pageSizeOptions]="[10, 25, 50]"
                >
                </mat-paginator>
            </div>

        </mat-card-content>
    </mat-card>
</div>
