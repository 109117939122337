
export enum StormProperties {
    basin = 'Basin',
    stormId = 'Storm Id',
    stormDate = 'Storm Date',
    excluded = 'Excluded',
    peakRainfall = 'Peak Rainfall',
    totalRainfall = 'Total Rainfall',
    precompType = 'Precompensation Type',
    precompAdjustment = 'Precompensation Adjustment',
    stormDuration = 'Storm Duration',
    stormRainfall = 'Storm Rainfall',
    stormGrossQPeak = 'Storm Gross Q Peak',
    stormGrossQVol = 'Storm Gross Q Volume',
    stormGross2Peak = 'Storm Gross II Peak',
    stormGross2Vol = 'Storm Gross II Volume',
    stormNet2Peak = 'Storm Net II Peak',
    stormNet2Vol = 'Storm Net II Volume',
    eventDuration = 'Event Duration',
    eventRainfall = 'Event Rainfall',
    eventGrossQPeak = 'Event Gross Q Peak',
    eventGrossQVol = 'Event Gross Q Volume',
    eventGross2Peak = 'Event Gross II Peak',
    eventGross2Vol = 'Event Gross II Volume',
    eventNet2Peak = 'Event Net II Peak',
    eventNet2Vol = 'Event Net II Volume',
    rec1GrossQPeak = 'Recovery 1 Gross Q Peak',
    rec1GrossQVol = 'Recovery 1 Gross Q Volume',
    rec1Gross2Peak = 'Recovery 1 Gross II Peak',
    rec1Gross2Vol = 'Recovery 1 Gross II Volume',
    rec1Net2Peak = 'Recovery 1 Net II Peak',
    rec1Net2Vol = 'Recovery 1 Net II Volume',
    rec2GrossQPeak = 'Recovery 2 Gross Q Peak',
    rec2GrossQVol = 'Recovery 2 Gross Q Volume',
    rec2Gross2Peak = 'Recovery 2 Gross II Peak',
    rec2Gross2Vol = 'Recovery 2 Gross II Volume',
    rec2Net2Peak = 'Recovery 2 Net II Peak',
    rec2Net2Vol = 'Recovery 2 Net II Volume',
    area = 'Area',
    sewerLength = 'Sewer Length',
    eventNet2RVal = 'Event Net II R-value',
    stormNet2RVal = 'Storm Net II R-value',
    grossEventPeakFactor = 'Gross Event Peaking Factor',
    eventNet2 = 'Event Net II',
    stormNet2 = 'Storm Net II'
}

const STRING_KEYS = [StormProperties.basin];
const STORM_DATE_KEYS = [StormProperties.stormDate];

const STORM_SLICE_ROWS = [StormProperties.basin];
const STORM_SLICE_COLUMNS = [StormProperties.stormDate];

// there columns should be assigned to the end of the report when downloading
export const STORM_END_COLUMNS = [
    StormProperties.area, StormProperties.sewerLength, StormProperties.eventNet2RVal, 
    StormProperties.stormNet2RVal, StormProperties.eventNet2, StormProperties.stormNet2,
];

export const STORM_SLICE_MEASURES = [
    StormProperties.peakRainfall, StormProperties.precompType, StormProperties.precompAdjustment, StormProperties.stormDuration,
    StormProperties.stormGrossQPeak, StormProperties.stormGrossQVol, StormProperties.stormGross2Peak, StormProperties.stormGross2Vol,
    StormProperties.eventDuration, StormProperties.eventRainfall, StormProperties.eventGrossQPeak, StormProperties.grossEventPeakFactor,
    StormProperties.eventGrossQVol, StormProperties.eventGross2Peak, StormProperties.eventGross2Vol, StormProperties.eventNet2Peak, StormProperties.eventNet2Vol,
    StormProperties.rec1GrossQPeak, StormProperties.rec1GrossQVol, StormProperties.rec1Gross2Peak, StormProperties.rec1Gross2Vol,
    StormProperties.rec1Net2Peak, StormProperties.rec1Net2Vol, StormProperties.rec2GrossQPeak, StormProperties.rec2GrossQVol,
    StormProperties.rec2Gross2Peak, StormProperties.rec2Gross2Vol, StormProperties.rec2Net2Peak, StormProperties.rec2Net2Vol,
    StormProperties.area, StormProperties.sewerLength, StormProperties.eventNet2RVal, StormProperties.eventNet2, StormProperties.stormNet2
];

export const STORM_CHART_MEASURES = [
    StormProperties.totalRainfall, StormProperties.stormRainfall, StormProperties.stormGrossQPeak, StormProperties.stormGrossQVol,
    StormProperties.stormGross2Peak, StormProperties.stormGross2Vol, StormProperties.stormNet2Peak, StormProperties.stormNet2Vol,
    StormProperties.eventRainfall, StormProperties.eventGrossQPeak, StormProperties.grossEventPeakFactor, StormProperties.eventGrossQVol,
    StormProperties.eventGross2Peak, StormProperties.eventGross2Vol, StormProperties.eventNet2Peak, StormProperties.eventNet2Vol,
    StormProperties.eventNet2RVal, StormProperties.stormNet2RVal, StormProperties.eventNet2, StormProperties.stormNet2
];

export const getStormPivotSlice = (storm) => {
    return {
        rows: STORM_SLICE_ROWS.map(prop => ({ uniqueName: getKeyByProp(storm, prop) })),
        columns: STORM_SLICE_COLUMNS.map(prop => ({ uniqueName: getKeyByProp(storm, prop) })),
        measures: STORM_CHART_MEASURES.map(prop => ({ uniqueName: getKeyByProp(storm, prop) }))
    };
}

export const getStormPivotOptions = (dateFormat: string, chartMeasure: string) => {
    return {
        showAggregationLabels: false,
        showAggregations: false,
        expandAll: true,
        viewType: "charts",
        grid: {
            type: "flat",
            showGrandTotals: "off",
            showTotals: "off",
        },
        chart: {
            multipleMeasures: true,
            activeMeasure: { uniqueName: chartMeasure },
        },
    }
}

export const createPivotMapping = (data: Object, dateFormat: string): { [key: string]: { type: string; format?: string; interval?: string } } => {

    return Object.keys(data).reduce((result, key) => {
        const value = data[key];

        let type = typeof value === 'number' ? 'number' : 'string';
        const isString = STRING_KEYS.includes(key as StormProperties);

        if (isString) {
            type = 'string';
        }

        const isDate = STORM_DATE_KEYS.includes(key as StormProperties);

        if (isDate) {
            result[key] = {
                type: "date string",
                format: dateFormat,
                interval: '30s'
            };
        } else {
            result[key] = { type };
        }

        return result;
    }, {});
}

export const csvToJSON = (text: string, quoteChar = '"', delimiter = ',') => {
    const rows = text.split("\n");
    const headers = rows[0].split(",");

    const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, 'gs');

    const match = line => [...line.matchAll(regex)]
        .map(m => m[2])
        .slice(0, -1);

    let lines = text.split('\n');
    const heads = headers ?? match(lines.shift());
    lines = lines.slice(1);

    return lines.map(line => {
        return match(line).reduce((acc, cur, i) => {
            // replace blank matches with `null`
            let val: string | number;

            if(i === 0) {
                // Basin Name
                val = cur;
            } else if (cur.length <= 0) {
            // null rows
                val = null;
            }
            // date rows
            else if (cur.includes('/')) {
                val = cur;
            } 
            // string rows
            else if (isNaN(Number(cur.replace(/,/g, '')))) {
                val = cur;
            }
            // numbers greater than 999 rows
            else {
                val = parseFloat(cur.replace(/,/g, ''));
            }
            const key = heads[i] ?? `{i}`;
            return { ...acc, [key]: val };
        }, {});
    });
}

// accepts the property name and returns storm key that came from API (storm key includes units)
export const getKeyByProp = (storm, prop: StormProperties) => {
    // #38446 need to find last match
    const stormKeys = Object.keys(storm);

    return stormKeys.slice().reverse().find(v => v.includes(prop));
}

export const removeUnits = (input: string): StormProperties => input.replace(/\s*\(.*\)$/, '').trim() as StormProperties;
