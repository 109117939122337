<div class="drydays-container two-section-layer">
    <div class="each-layer stats-graph w50p">
        <app-loader [isLoading]="daysLoading"></app-loader>
        <div class="sli-header">
            <h4 class="graph-heading">
                {{ 'SLIICER.FLOW_TAB.DRY_DAYS.DIURNAL_GRAPH_HEADING' | translate }}
            </h4>
        </div>
        <div>
            <app-dry-day-diurnal-graph
                [caseStudyId]="caseStudyId"
                [customerId]="customerId"
                [telemetryKey]="telemetryKey"
                [stepLength]="stepLength"
                [basinDryDays]="basinDryDays"
                [selectedBasin]="selectedBasin"
                [isDryDaysEditActive]="isDryDaysEditActive"
                [selectDryDays]="selectDryDays"
                (weekDaySelectionEvent)="onDaySelection($event)"
                (dryDayRemoved)="onDryDayRemoved($event)"
            >
            </app-dry-day-diurnal-graph>
        </div>
    </div>
    <div class="each-layer stats-data m-l-20">
        <app-loader [isLoading]="daysLoading"></app-loader>
        <div class="sli-header">
            <h4 class="graph-heading">
                {{ 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.HEADING' | translate }}
            </h4>
        </div>
        <div>
            <app-dry-days-stats [isStudyLocked]="isStudyLocked" [customerId]="customerId" [basinDryDays]="basinDryDays"></app-dry-days-stats>
        </div>
    </div>
</div>
