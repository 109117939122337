import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    ViewChild,
    ViewEncapsulation,
    ChangeDetectorRef,
    OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Entities } from 'app/shared/models/entities';

import { VaultService } from 'app/pages/vault/vault.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { OrderByPipe } from 'app/shared/pipes/order-by-pipe';
import { StringUtils } from 'app/shared/utils/string-utils';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { Time } from 'app/shared/models/time';
import { TranslateService } from '@ngx-translate/core';
import { MultiSelectGroupComponent } from 'app/shared/components/multi-select/multi-select-group/multi-select-group.component';
import * as moment from 'moment';
import { Selectable, SelectableGroup } from 'app/shared/models/selectable';
import { TelemetryService } from 'app/shared/services/telemetry.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { TelemetryExportDialog, TelemetryExportParmas } from 'app/shared/models/telemetry';
import { MultiSelectAutoCompleteComponent } from 'app/shared/components/multi-select/multi-select-auto-complete/multi-select-auto-complete.component';

@Component({
    selector: 'app-telemetry-export',
    templateUrl: './vault-telemetry-export.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class VaultTelemetryExportComponent implements OnInit {
    /**
     * Represents the collection of locations.
     */
    public locations: Array<Selectable>;

    /**
     * Represents the collection of selected locations.
     */
    public selectedLocations = new Array<Selectable>();

    /**
     * Represents the collection of entities.
     */
    public entities: Array<SelectableGroup>;

    /**
     * Represents the collection of selected entities.
     */
    public selectedEntities = new Array<SelectableGroup>();

    /**
     * Represents the maximum date to set the date pickers to.
     */
    public maxDate = new Date();

    /**
     * Represnts the end date form value.
     */
    public endDate: Date;

    /**
     * Represents the start date form value.
     */
    public startDate: Date;

    /**
     * Represents a validaiton message for dates.
     */
    public datesValidationMessage: string;

    /**
     * Represents the loading state of the compenent.
     */
    public isLoading: boolean;

    /**
     * Represents a validaiton message for locations.
     */
    public locationsValidationMessage: string;

    /**
     * Represents a validaiton message for entities.
     */
    public entitiesValidationMessage: string;

    /**
     * Used to check the range of start and end dates.
     */
    public invalidDateRange: boolean;

    /**
     * Represents low level (which has only 0 value) precendence entities
     */
    private lowPrecendenceEntities = new Array<Entities>();

    /**
     * Variable which represents the filtered entity collection
     */
    public filteredEntities = new Array<SelectableGroup>();

    /**
     * Start time of telemetry export
     */
    public startTime: Time;

    /**
     * End time of telemetry export
     */
    public endTime: Time;

    /**
     * Reprensets an instance of the location multiselect component.
     */
    @ViewChild('multiSelectAutoCompleteLocations')
    private multiSelectAutoCompleteLocations: MultiSelectAutoCompleteComponent;

    /**
     * Reprensets an instance of the entity multiselect component.
     */
    @ViewChild('multiSelectAutoCompleteEntities')
    private multiSelectAutoCompleteEntities: MultiSelectAutoCompleteComponent;

    @ViewChild('entitiesGroupMultiselect') public entitiesGroupMultiselect: MultiSelectGroupComponent;

    public isStartDateValid = true;
    public isEndDateValid = true;
    public isDateInvalid = false;
    public invalidStartDate = false;
    public invalidEndDate = false;

    /**
     * Object for getting common methods for loading entities.
     */
    private stringUtils = new StringUtils();
    public timeFormat: number;

    public is12HourFormat = false;
    public dateFormat: string;
    public isDaySpanValid = true;
    public dismissText: string;
    public locationSelectValidationMsg: string;
    public entitySelectValidationMsg: string;
    public dateValidationMsg: string;
    public exportErrMsg: string;
    public noDataMsg: string;
    public exportSuccessMsg: string;
    public dataExports: string;
    public isStatusCodeFlagValue = false;
    public isEntitiesInvalid = false;
    constructor(
        private activatedRoute: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) public dialogData: TelemetryExportDialog,
        private dialogRef: MatDialogRef<VaultTelemetryExportComponent>,
        private snackBar: MatSnackBar,
        private telemetryService: TelemetryService,
        private dateutilService: DateutilService,
        private changeDetectorReference: ChangeDetectorRef,
        private vaultService: VaultService,
        private dateService: DateutilService,
        private statusCodeService: StatusCodeService,
        private uiUtilsService: UiUtilsService,
        private translate: TranslateService,
    ) {
        translate.get('VAULT.VAULT_TELEMETRY.EXPORT.DATE_VALIDATION_MSG').subscribe((res: string) => {
            this.dateValidationMsg = res;
        });
        translate.get('VAULT.VAULT_TELEMETRY.EXPORT.EXPORT_ERR_SNACKBAR_MSG').subscribe((res: string) => {
            this.exportErrMsg = res;
        });
        translate.get('VAULT.VAULT_TELEMETRY.EXPORT.NO_DATA_EXPORT_ERR').subscribe((res: string) => {
            this.noDataMsg = res;
        });
        translate.get('COMMON.LOCATION_SELECT_VALIDATION').subscribe((res: string) => {
            this.locationSelectValidationMsg = res;
        });
        translate.get('COMMON.ENTITY_SELECT_VALIDATION').subscribe((res: string) => {
            this.entitySelectValidationMsg = res;
        });
        translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismissText = res;
        });
        translate.get('COMMON.CSV_EXPORT_SUCCESS').subscribe((res: string) => {
            this.exportSuccessMsg = res;
        });
        translate.get('VAULT.DATAEXPORTS').subscribe((res: string) => {
            this.dataExports = res;
        });
    }

    public ngOnInit() {
        this.locations = this.dialogData.locations;
        this.setEntitiesOnTemplate(this.dialogData.entities);

        this.setupDefaults();

        // Setting Messages for locations and entities validation
        this.locationsValidationMessage = this.locationSelectValidationMsg;
        this.entitiesValidationMessage = this.entitySelectValidationMsg;

        const customerTimeFormat = this.dateutilService.timeFormat.getValue();
        this.timeFormat = customerTimeFormat === 'hh:mm:ss' ? 24 : 12;

        // set time format to UI
        this.dateFormat = this.dateutilService.dateFormat.getValue();
        this.is12HourFormat = this.dateutilService.timeFormat.getValue() !== 'hh:mm:ss' ? true : false;
    }

    /**
     * called on start date time change
     */
    public onChangeStartDateTime() {
        this.dateValidationMessage();
    }

    /**
     * called on end date time change
     */
    public onChangeEndDateTime() {
        this.dateValidationMessage();
    }

    /**
     * This function will set the entities on Entities Template Markup.
     * @param entities -> Array of entities which will be get from Entities API
     */

    private setEntitiesOnTemplate(entities: Array<Entities>) {
        // sort the entities
        entities = this.stringUtils.sortEntities(entities);

        // alphabatically sort the low precedence entities based on entity name
        const filterPipe = new OrderByPipe();
        this.lowPrecendenceEntities = filterPipe.transform(this.lowPrecendenceEntities, 'entityName', false);

        // append low precedence entities with actual entites array which have only high precedence entities
        entities = entities.concat(this.lowPrecendenceEntities);

        entities.forEach((entity: Entities, index) => {
            this.filteredEntities.push({
                name: entity.entityName,
                id: entity.entityId,
                groupName: entity.displayGroupName,
                groupId: entity.displayGroup,
                isChecked: this.stringUtils.defaultSelectedEntities(entity),
            });
        });

        this.entities = this.filteredEntities;
        this.selectedEntities = this.entities.filter((item) => item.isChecked);
        this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
    }

    /**
     * Checks the location is valid
     */
    public locationsInvalid() {
        if (
            this.selectedLocations &&
            this.selectedLocations.length < 1 &&
            this.multiSelectAutoCompleteLocations.searchInput.ngControl.touched
        ) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Checks the entity is valid
     */
    public entitiesInvalid() {
        if (
            this.selectedEntities &&
            this.selectedEntities.length < 1 &&
            this.multiSelectAutoCompleteEntities.searchInput.ngControl.touched
        ) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Handles the emission of the currently selected locations.
     * @param locations Represents the collection of selected locations.
     */
    public handleSelectedLocations(locations: Array<Selectable>): void {
        this.selectedLocations = locations.filter((location: Selectable) => location.isChecked);
    }

    /**
     * Handles the emission of the currently selected entities.
     * @param locations Represents the collection of selected entities.
     */
    public handleSelectedEntities(entities: Array<SelectableGroup>): void {
        this.selectedEntities = entities.filter((entity: SelectableGroup) => entity.isChecked);
        this.isEntitiesInvalid = !(this.selectedEntities.length > 0);
        this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
    }

    /**
     * Handles the form submission.
     * @param event Represents the form submission event.
     */
    public onExportSubmit(event: Event): void {
        // adding time in start and end date to get full record of the day
        // setting with min start time of a day
        //  this.startDate.setHours(0);
        //  this.startDate.setMinutes(0);
        //  this.startDate.setSeconds(0);
        this.startDate.setMilliseconds(0);
        // setting with max end time of a day
        //  this.endDate.setHours(23);
        //  this.endDate.setMinutes(59);
        //  this.endDate.setSeconds(59);
        this.endDate.setMilliseconds(999);

        this.isLoading = true;

        const entitiesSelected =
            this.entities.length === this.selectedEntities.length
                ? new Array<Entities>()
                : this.selectedEntities.map((entity: SelectableGroup) => entity.id);

        // call api with argregated params
        const result = this.telemetryService.exportTelemetryData(<TelemetryExportParmas>{
            endDate: new Date(this.endDate.getTime() - this.endDate.getTimezoneOffset() * 60000).toISOString(),
            eids: entitiesSelected,
            lids: this.selectedLocations.map((location: Selectable) => location.id),
            startDate: new Date(this.startDate.getTime() - this.startDate.getTimezoneOffset() * 60000).toISOString(),
        });

        const exportSubscription = result.subscribe(
            () => {
                this.statusCodeService.statusCodeFlag.subscribe((statusCodeFlagValue) => {
                    this.isStatusCodeFlagValue = statusCodeFlagValue;
                    if (statusCodeFlagValue === false) {
                        this.snackBar.open(this.exportSuccessMsg, this.dismissText, { duration: 10000 });
                    } else {
                        // this.snackBar.open('No data available for the location.', 'Dismiss', { duration: 3000 });
                        this.snackBar.open(this.noDataMsg, this.dismissText, {
                            panelClass: 'custom-error-snack-bar',
                        });
                    }
                });

                // display success message

                // get the latest vault data for current customer to show latest exported file
                this.vaultService
                    .getRootVaultListForCustomer()
                    .subscribe(() => {
                        this.isLoading = false;
                    })
                    .unsubscribe(); // immediately unsubscribe

                // close modal
                const dataExportPath = '/' + this.dataExports + '/';
                this.dialogRef.close();
                // Check if data exist for the locations
                if (this.isStatusCodeFlagValue === false) {
                    this.vaultService.getFilesForDirectory('/dataExports/');
                }
            },
            (err) => {
                // this.snackBar.open('There was a problem exporting the file with the given data set.', 'Dismiss', { duration: 3000 });
                this.snackBar.open(this.exportErrMsg, this.dismissText, {
                    panelClass: 'custom-error-snack-bar',
                });

                this.isLoading = false;

                // Checks the change detector to reflect the loader state on UI
                this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
            },
        );
    }

    public validateDate() {
        if (this.startDate > this.endDate) {
            this.datesValidationMessage = this.dateValidationMsg;
        } else {
            this.datesValidationMessage = '';
        }
    }

    public onDateChanged() {
        this.dateValidation();
    }

    private dateValidation() {
        // if both start and end date present.
        if (this.startDate && this.endDate) {
            this.showDateError();
        } else {
            // any of start and end date present.
            this.showRequiredError();
        }
    }

    private showDateError() {
        const startDate = this.startDate;
        const endDate = this.endDate;
        this.startDate.setHours(this.startTime.hour, this.startTime.minute, 0, 0);
        this.endDate.setHours(this.endTime.hour, this.endTime.minute, 0, 0);
        this.isStartDateValid = false;
        this.isEndDateValid = false;

        // condition for checking input date greater than minimum date (Jan 1, 2000) and smaller than maximum date (current date)
        this.invalidStartDate = startDate > this.dateutilService.maxDate || startDate < this.dateutilService.minDate;
        this.invalidEndDate = endDate > this.dateutilService.maxDate || endDate < this.dateutilService.minDate;

        // Checking date range should not be more than 30 days
        const timeDiff = Math.abs(this.startDate.getTime() - this.endDate.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        this.invalidDateRange = diffDays < 0;

        this.isDateInvalid = startDate > endDate;

        this.isStartDateValid = this.isEndDateValid = !this.isDateInvalid;
    }

    private showRequiredError() {
        this.isDateInvalid = false;
        if (this.startDate) {
            this.isStartDateValid = false;
        } else {
            this.isStartDateValid = true;
        }

        if (this.endDate) {
            this.isEndDateValid = false;
        } else {
            this.isEndDateValid = true;
        }
        return false;
    }

    /**
     * Below function will check the export button validity based on locations, date and entities crieteria such as
     * At least one location and entity should be selected.
     * Start date and end date should not be empty.
     * Start date should not be greater than end date.
     */
    public checkTelemetryExportValidity(): boolean {
        const checkTelemetryExportValidity =
            this.isDateInvalid === true ||
            this.isStartDateValid === false ||
            this.isEndDateValid === false ||
            this.invalidDateRange === true ||
            this.selectedLocations.length < 1 ||
            this.selectedEntities.length < 1;
        return checkTelemetryExportValidity;
    }

    private setupDefaults() {
        // get current date
        const now = new Date();

        // set start/end date as seven day span until today
        this.startDate = moment().subtract(7, 'd').startOf('day').toDate();
        this.startDate.setHours(0, 0, 0, 0);
        this.startTime = this.endTime = <Time>{ hour: 0, minute: 0 };

        // set selected entities
        this.selectedEntities = this.entities.filter((entity: SelectableGroup) => entity.isChecked);

        this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);

        // set today as end date
        this.endDate = now;
        this.endDate.setHours(0, 0, 0, 0);
    }

    public onNotifyTimeChange(date: string, time: Time) {
        if (date.toLowerCase() === 'start') {
            this.startTime = time;
            this.startDate.setHours(time.hour, time.minute, 0, 0);
        }

        if (date.toLowerCase() === 'end') {
            this.endTime = time;
            this.endDate.setHours(time.hour, time.minute, 0, 0);
        }

        this.dateValidation();
    }
    private dateValidationMessage() {
        this.datesValidationMessage = '';
        const today = new Date();
        if (this.startDate && this.endDate) {
            const startDate = this.startDate;
            const endDate = this.endDate;
            this.isDaySpanValid = true;
            if (startDate > endDate) {
                this.isDaySpanValid = false;
                this.datesValidationMessage = 'Start Date should be before End Date.';
            }
            // commented due to 10981
            /*if (today < startDate) {
        this.isDaySpanValid = false;
        this.datesValidationMessage = this.datesValidationMessage + 'Start Date should not be greater than Today.';
      }
      if (today < endDate) {
        this.isDaySpanValid = false;
        this.datesValidationMessage = this.datesValidationMessage + 'End Date should not be greater than Today.';
      }
      */
        }
    }
}
