import { Component, ViewEncapsulation, Input } from '@angular/core';
import { ProgressBar } from './progress-bars.models';

@Component({
    selector: 'progress-bars',
    templateUrl: './progress-bars.component.html',
    styleUrls: ['./progress-bars.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProgressBarsComponent {
    @Input() progressBars: Array<ProgressBar> = [];
    constructor() {}
}
