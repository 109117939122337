<div id="aboutUsDialog">
    <div mat-dialog-title>
        <img src="/assets/images/new_prism_logo.png" alt="ADS PRISM" class="logo" />
        <div class="mediumHeaderText">{{ 'ABOUT_US.HEADER' | translate }}</div>
    </div>
    <div mat-dialog-content>
        <div class="buildDetails">
            <app-loader [isLoading]="buildLoadingState"></app-loader>
            <div class="eachContent">
                <div class="buildNumerText">{{ buildNumbers?.build }}</div>
                <div class="buildTypeText">{{ 'ABOUT_US.API_BUILD' | translate }}</div>
            </div>
            <div class="eachContent">
                <div class="buildNumerText">{{ buildNumbers?.ui }}</div>
                <div class="buildTypeText">{{ 'ABOUT_US.UI_BUILD' | translate }}</div>
            </div>
        </div>
        <div class="terms">
            <span>
                {{ 'ABOUT_US.VIEW' | translate }}
                <a href="https://www.adsenv.com/prismuseragreement/" target="_blank">{{
                    'COMMON.USER_AGREEMENT' | translate
                }}</a>
            </span>
            <span>
                {{ 'ABOUT_US.VIEW' | translate }}
                <a href="https://www.adsenv.com/prismprivacypolicy/" target="_blank">{{
                    'COMMON.PRIVACY_POLICY' | translate
                }}</a>
            </span>
            <br /><br />
            <span> {{ 'ABOUT_US.REGION' | translate }}: {{ buildNumbers?.region }} </span>
            <span> {{ 'ABOUT_US.NODE' | translate }}: {{ buildNumbers?.node }} </span>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="emitAboutUs()">{{ 'COMMON.CLOSE' | translate }}</button>
        </div>
    </div>
</div>
