import {
    Component,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    ViewEncapsulation,
    SimpleChanges,
    ViewChild,
    ElementRef,
    OnInit,
    OnChanges,
    OnDestroy,
} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { StatusCodeService } from '../../services/status-code.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import moment, { Moment } from 'moment';
import { HydrographTickInterval, QUICK_DATE_RANGES, dateRangeValues } from 'app/shared/models/view-data';
import { MatLegacyMenu as MatMenu, MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Subscription } from 'rxjs';

// TODO: We have two very similar components - other one is date-picker.component. UNIFY THEM IN FUTURE ! Both marked with TODO:

@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class DateRangePickerComponent implements OnInit, OnChanges, OnDestroy {
    /** Start date value after validation */
    @Input() public startDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7,
        0,
        0,
        0,
    );
    /** End date value after validation */
    // the other component was getting current time for enddate
    @Input() public endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59);
    @Input() public minimumDataDate: Date;
    @Input() public maximumDataDate: Date;
    @Input() public minDate: Date;
    @Input() public maxDate: Date;
    @Input() public title: string;
    @Input() public showQuickRanges = true;
    @Input() public quickOneDay = true;
    @Input() public quickOneWeek = true;
    @Input() public quickLastMonth = true;
    @Input() public quickOneMonth = true;
    @Input() public quickThreeMonths = true;
    @Input() public quickSixMonths = true;
    @Input() public quickOneYear = true;
    @Input() public dateRangeType = 'datetime';
    @Input() public buttonClasses = [];
    @Input() public hideInput: boolean;
    @Input() public disabled: boolean;
    @Input() public disableInputs = false;
    @Input() public startFromMonth: boolean;
    @Input() public hideHours = false;
    /** Maximum range in days, that is allowed */
    @Input() public maxDayRange?: number = null;
    @Input() public showAvailableDates = true;
    @Input() public oneWeekDoNotIncludeToday = false;
    @Input() public label: string;
    @Input() public selectedTimespan: QUICK_DATE_RANGES = QUICK_DATE_RANGES.LAST_WEEK;
    @Input() public emitCloseOnChanges = true;
    @Input() public autoFocus = true;
    @Input() public startDateBoundaryErrorMsg: string;
    @Input() public endDateBoundaryErrorMsg: string;
    @Input() doNotCalculateQuickRangesAvailability: boolean;
    @Output() public selectedTimespanChange = new EventEmitter<QUICK_DATE_RANGES>();

    @Output() private startDateChange = new EventEmitter<Date>();
    @Output() private endDateChange = new EventEmitter<Date>();
    @Output() private tickIntervalChange = new EventEmitter<number>();
    @Output() private emitClose = new EventEmitter<void>();
    @Output() private emitOpen = new EventEmitter<void>();
    @Output() private emitDateChanged = new EventEmitter<SimpleChanges>();
    @Output() public isValidDateRange = new EventEmitter<boolean>();
    @Output() public selectionChange = new EventEmitter<dateRangeValues>();
    @Output() public isDateChanged = new EventEmitter<boolean>();

    @ViewChild('startDateInput') public startDateInput: ElementRef;
    @ViewChild('endDateInput') public endDateInput: ElementRef;
    @ViewChild('trigger', { static: true }) public menuTrigger: MatMenuTrigger;
    @ViewChild('dateRangeMenu', { static: true }) public dateRangeMenu: MatMenu;

    public displayStartDateErrMsg: boolean;
    public displayEndDateErrMsg: boolean;
    public displayStartDateBoundaryErr: boolean;
    public displayEndDateBoundaryErr: boolean;
    public invalidStartDate: boolean;
    public invalidEndDate: boolean;
    public invalidRange: boolean;
    public customerDateFormat: string;
    public customerDateFormatLowerCase: string;
    public customDateFormat: string;
    public dateFormat: string;
    public isCustomDateTouched: boolean;
    public defaultTimeSpan: QUICK_DATE_RANGES;

    private isStartDateTouched = false;
    private isEndDateTouched = false;

    public oneDayAvailable = false;
    public oneWeekAvailable = false;
    public lastMonthAvailable = false;
    public oneMonthAvailable = false;
    public threeMonthsAvailable = false;
    public sixMonthsAvailable = false;
    public oneYearAvailable = false;

    public QUICK_DATE_RANGES = QUICK_DATE_RANGES;

    /** Start date displayed in component view */
    public displayStartDate = this.startDate;
    /** End date displayed in component view */
    public displayEndDate = this.endDate;
    /** Old value of start date */
    public oldStartDate = this.startDate;
    /** Old value of end date */
    public oldEndDate = this.endDate;


    private dateYMDformat = new RegExp(/\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])/);
    private dateDMYformat = new RegExp(/(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}/);
    private dateMDYformat = new RegExp(/(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}/);

    private subscriptions = new Subscription();
    // #21899 once date range was invalid then should notify both dates
    invalidDifference = false;


    constructor(
        private cdr: ChangeDetectorRef,
        private uiUtilService: UiUtilsService,
        private statusCodeService: StatusCodeService,
        public dateutilService: DateutilService,
    ) { }

    public ngOnInit() {
        const dateSubs = this.dateutilService.dateFormat.subscribe(() => {
            this.dateFormat = this.dateutilService.getFormat();
            if (this.hideHours) {
                this.customerDateFormat = `${this.dateFormat.toUpperCase()}`;
                this.customerDateFormatLowerCase = `${this.dateFormat}`;
                this.customDateFormat = `${this.dateFormat.toLocaleLowerCase()}`;
            } else {
                this.customerDateFormat = `${this.dateFormat.toUpperCase()} ${this.dateutilService.getTimeFormatWithoutSeconds()}`;
                this.customerDateFormatLowerCase = `${this.dateFormat} ${this.dateutilService.getTimeFormatWithoutSeconds()}`;
                const is12HourFormat = this.dateutilService.timeFormat.getValue() !== 'hh:mm:ss';
                this.customDateFormat = `${this.dateFormat.toLocaleLowerCase()}, ${is12HourFormat ? 'hh:mm a' : 'HH:mm' }`;
            }

            this.uiUtilService.safeChangeDetection(this.cdr);
        });

        this.subscriptions.add(dateSubs);

        const closeSubs = this.dateRangeMenu.closed.subscribe(() => {
            this.emitClose.emit();
        });
        this.subscriptions.add(closeSubs);

        if (this.hideInput) {
            this.openMenu();
        }
        if (!this.startDate) {
            this.startDate = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - 7,
                0,
                0,
                0,
            )
        }

        if (!this.endDate) {
            this.endDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59);
        }
        this.setAvailableQuickOptions();
        if (this.defaultTimeSpan && !this.disableInputs) {
            this.getDataForSetTimeSpan(this.defaultTimeSpan);
        } else {
            this.displayStartDate = this.startDate;
            this.displayEndDate = this.endDate;
        }

    }

    public openMenu() {
        this.menuTrigger.openMenu();
        this.maxDate = new Date();
    }

    public openMenuEvent() {
        this.emitOpen.emit();
    }

    public closeMenu() {
        this.menuTrigger.closeMenu();
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.setAvailableQuickOptions();

        if (changes.startDate) {
            this.displayStartDate = changes.startDate.currentValue;
        }

        if (changes.endDate) {
            this.displayEndDate = changes.endDate.currentValue;
        }

        if (changes.selectedTimespan && changes.selectedTimespan.currentValue) {
            this.getDataForSetTimeSpan(changes.selectedTimespan.currentValue, this.emitCloseOnChanges)
        }
    }

    private setAvailableQuickOptions() {
        if (this.doNotCalculateQuickRangesAvailability) return;
    
        const isDataInRange = ({ startDate, endDate }) => {
            const maxDate = this.maxDate ? new Date(new Date(this.maxDate).setHours(23, 59, 59)) : null;

            if (this.minDate && maxDate) {
                return (
                    moment(startDate).isBetween(this.minDate, maxDate, undefined, '[]') &&
                    (moment(endDate).isBetween(this.minDate, maxDate, undefined, '[]') || moment(endDate).isSame(maxDate, 'day'))
                );
            } else if (this.minDate) {
                return moment(startDate).isSameOrAfter(this.minDate, 'day') &&
                       moment(endDate).isSameOrAfter(this.minDate, 'day');
            } else if (maxDate) {
                return moment(startDate).isSameOrBefore(maxDate, 'day') &&
                       (moment(endDate).isSameOrBefore(maxDate, 'day') || moment(endDate).isSame(maxDate, 'day'));
            }
            return true;
        };
    
        // Only enable ranges where there's some data
        this.oneDayAvailable = this.quickOneDay && isDataInRange(this.getTimespan(QUICK_DATE_RANGES.TODAY));
        this.oneWeekAvailable = this.quickOneWeek && isDataInRange(this.getTimespan(QUICK_DATE_RANGES.LAST_WEEK));
        this.lastMonthAvailable = this.quickLastMonth && isDataInRange(this.getTimespan(QUICK_DATE_RANGES.PREVIOUS_MONTH));
        this.oneMonthAvailable = this.quickOneMonth && isDataInRange(this.getTimespan(QUICK_DATE_RANGES.LAST_MONTH));
        this.threeMonthsAvailable = this.quickThreeMonths && isDataInRange(this.getTimespan(QUICK_DATE_RANGES.LAST_THREE_MONTHS));
        this.sixMonthsAvailable = this.quickSixMonths && isDataInRange(this.getTimespan(QUICK_DATE_RANGES.LAST_SIX_MONTHS));
        this.oneYearAvailable = this.quickOneYear && isDataInRange(this.getTimespan(QUICK_DATE_RANGES.LAST_YEAR));
    }

    public onCloseMenu() {
        this.selectionChange.emit({
            startDate: this.startDate,
            endDate: this.endDate,
        });
    }

    public onStartDateChange(event: MatDatepickerInputEvent<Date>) {
        this.onDateChange(event, true);
        this.isStartDateTouched = true;

        setTimeout(() => {
            this.startDateInput.nativeElement.focus();
        }, 200);
    }

    public onEndDateChange(event: MatDatepickerInputEvent<Date>) {
        this.onDateChange(event, false);
        this.isEndDateTouched = true;

        setTimeout(() => {
            this.endDateInput.nativeElement.focus();
        }, 200);
    }

    private checkDateInputValidity(input: string | Date) {
        if (typeof input !== 'string') {
            return true;
        }
        if (this.dateFormat === 'yyyy/MM/dd') {
            return this.dateYMDformat.test(input);
        } else if (this.dateFormat === 'MM/dd/yyyy') {
            return this.dateMDYformat.test(input);
        } else if (this.dateFormat === 'dd/MM/yyyy') {
            return this.dateDMYformat.test(input);
        }
    }

    private checkIfInputIsWithinBounds(date: Moment, isStartDate: boolean) {
        if (isStartDate && this.minDate) {
            return date.isSameOrAfter(moment(this.minDate), 'day');
        }

        if (!isStartDate && this.maxDate) {
            return date.isSameOrBefore(moment(this.maxDate), 'day');
        }
        // no boundaries provided
        return true;
    }

    private onDateChange(event: MatDatepickerInputEvent<Date>, isStartDate: boolean) {
        const isValidDate = this.checkDateInputValidity(event.target.value);
        const newDate = moment(event.target.value, this.customerDateFormat);

        if (!isValidDate || !newDate || !newDate.isValid()) {
            if (isStartDate) {
                this.displayStartDateErrMsg = true;
            } else {
                this.displayEndDateErrMsg = true;
            }
            this.uiUtilService.safeChangeDetection(this.cdr);
            return;
        }
        
        const isWithinBoundaries = this.checkIfInputIsWithinBounds(newDate, isStartDate);
        if (!isWithinBoundaries) {
            if (isStartDate) {
                this.displayStartDateBoundaryErr = true;
            } else {
                this.displayEndDateBoundaryErr = true;
            }

            this.uiUtilService.safeChangeDetection(this.cdr);
            return;
        }

        const startDateTemp = isStartDate ? newDate.toDate() : this.displayStartDate;
        const endDateTemp = !isStartDate ? newDate.toDate() : this.displayEndDate;

        if (isStartDate) {
            this.displayStartDateErrMsg = false;
            this.displayStartDateBoundaryErr = false;
        } else {
            this.displayEndDateErrMsg = false;
            this.displayEndDateBoundaryErr = false;
        }

        if (this.displayStartDate && this.displayEndDate) {
            const checkDifference = this.monthDifference(startDateTemp, endDateTemp);
            if (!checkDifference) {
                this.invalidDifference = true;
                this.uiUtilService.safeChangeDetection(this.cdr);
                return;
            }
        }

        // #21899 once date range was invalid then should notify both dates
        if (isStartDate || this.invalidDifference) {
            this.startDate = startDateTemp;
            this.displayStartDate = this.startDate;
            this.startDateInput.nativeElement.focus();
            this.startDateChange.emit(this.startDate);
        }

        if (!isStartDate || this.invalidDifference) {
            this.endDate = endDateTemp;
            this.displayEndDate = this.endDate;
            this.endDateInput.nativeElement.focus();
            this.endDateChange.emit(this.endDate);
        }

        this.invalidDifference = false;

        if (this.emitDateChanged) {
            this.emitDateChanged.emit({
                startDate: {
                    currentValue: this.startDate,
                    previousValue: this.oldStartDate,
                    firstChange: this.isStartDateTouched === false,
                    isFirstChange: () => this.isStartDateTouched === false,
                },
                endDate: {
                    currentValue: this.endDate,
                    previousValue: this.oldEndDate,
                    firstChange: this.isEndDateTouched === false,
                    isFirstChange: () => this.isEndDateTouched === false,
                },
            });
        }
        this.selectedTimespanChange.emit(null);
        this.oldStartDate = this.startDate;
        this.oldEndDate = this.endDate;
    }

    public getTimespan(timespan: QUICK_DATE_RANGES) {
        const today = new Date();
        switch (timespan) {
            case QUICK_DATE_RANGES.TODAY: {
                return {
                    startDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 0, 0, 0),
                    endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 23, 59, 59),
                };
            }
            case QUICK_DATE_RANGES.LAST_WEEK: {
                return {
                    startDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7, 0, 0, 0),
                    endDate: new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate() - (this.oneWeekDoNotIncludeToday ? 1 : 0),
                        23,
                        59,
                        59,
                    ),
                };
            }
            // this componet check the current date, previous one was looking for 1st day of the month.
            case QUICK_DATE_RANGES.LAST_MONTH: {
                return {
                    startDate: new Date(today.getFullYear(), today.getMonth() - 1, today.getDate(), 0, 0, 0),
                    endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59),
                };
            }
            case QUICK_DATE_RANGES.PREVIOUS_MONTH: {
                return {
                    startDate: new Date(
                        today.getFullYear() - (today.getMonth() > 0 ? 0 : 1),
                        (today.getMonth() - 1 + 12) % 12,
                        1,
                    ),
                    endDate: new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, -1),
                };
            }
            case QUICK_DATE_RANGES.LAST_THREE_MONTHS: {
                return {
                    startDate: new Date(today.getFullYear(), today.getMonth() - 3, today.getDate(), 0, 0, 0),
                    endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59),
                };
            }
            case QUICK_DATE_RANGES.LAST_SIX_MONTHS: {
                return {
                    startDate: new Date(today.getFullYear(), today.getMonth() - 6, today.getDate(), 0, 0, 0),
                    endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59),
                };
            }
            case QUICK_DATE_RANGES.LAST_YEAR: {
                return {
                    startDate: new Date(today.getFullYear(), today.getMonth() - 12, today.getDate(), 0, 0, 0),
                    endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59),
                };
            }
            default: {
                break;
            }
        }
    }

    /** Method to validate start date from datepicker and validate months Differnce */
    public checkStartDateChange(val: Date) {
        this.displayStartDateErrMsg = false;
        this.displayStartDateBoundaryErr = false;

        this.monthDifference(val, this.displayEndDate);

        this.isCustomDateTouched = true;
    }

    public calendarCheckStartDateChange(event: MatDatepickerInputEvent<Date>) {
        if (!event.value) {
            this.displayStartDateErrMsg = true;
            this.isValidDateRange.emit(false);
            this.uiUtilService.safeChangeDetection(this.cdr);
            return;
        }
        const date = moment(event.value, this.customerDateFormat);
        this.checkStartDateChange(date.toDate());
    }

    public showQuickRangesClick(event) {
        if (this.hideInput && event) {
            event.stopPropagation();
        }
    }

    public getDataForSetTimeSpan(timespan: QUICK_DATE_RANGES, emitCloseOnChanges = true) {
        const { startDate, endDate } = this.getTimespan(timespan);
        const today = new Date();
        this.displayStartDate = startDate;
        this.displayEndDate = endDate;
        this.startDate = startDate;
        this.endDate = endDate;
        this.statusCodeService.timeInterval.next(timespan);

        switch (timespan) {
            case QUICK_DATE_RANGES.TODAY: {
                this.tickIntervalChange.emit(HydrographTickInterval.daily);
                this.statusCodeService.setQuickRangeInDateFilter.next(0);
                break;
            }
            case QUICK_DATE_RANGES.LAST_WEEK: {
                this.statusCodeService.setQuickRangeInDateFilter.next(1);
                if (this.oneWeekDoNotIncludeToday) {
                    this.endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 23, 59, 59);
                }
                break;
            }
            case QUICK_DATE_RANGES.LAST_MONTH: {
                this.statusCodeService.setQuickRangeInDateFilter.next(2);
                this.tickIntervalChange.emit(HydrographTickInterval.Weekly);
                break;
            }
            case QUICK_DATE_RANGES.PREVIOUS_MONTH: {
                this.statusCodeService.setQuickRangeInDateFilter.next(7);
                break;
            }
            case QUICK_DATE_RANGES.LAST_THREE_MONTHS: {
                this.statusCodeService.setQuickRangeInDateFilter.next(3);
                this.tickIntervalChange.emit(HydrographTickInterval.ThreeMonths);
                break;
            }
            case QUICK_DATE_RANGES.LAST_SIX_MONTHS: {
                this.statusCodeService.setQuickRangeInDateFilter.next(4);
                this.tickIntervalChange.emit(HydrographTickInterval.SixMonths);
                break;
            }
            case QUICK_DATE_RANGES.LAST_YEAR: {
                this.statusCodeService.setQuickRangeInDateFilter.next(5);
                this.tickIntervalChange.emit(HydrographTickInterval.Year);
                break;
            }
            default: {
                break;
            }
        }

        this.isDateChanged.emit(true);
        this.invalidRange = false;
        this.invalidEndDate = false;
        this.invalidStartDate = false;

        this.selectedTimespan = timespan;
        this.selectedTimespanChange.emit(this.selectedTimespan);

        this.displayStartDate = this.startDate;
        this.displayEndDate = this.endDate;

        this.invalidStartDate = false;
        this.invalidEndDate = false;

        this.startDateChange.emit(this.startDate);
        this.endDateChange.emit(this.endDate);
        this.isValidDateRange.emit(true);
        this.selectionChange.emit({
            startDate: this.startDate,
            endDate: this.endDate,
        });
        this.isValidDateRange.emit(true);
        if (this.emitDateChanged) {
            this.emitDateChanged.emit({
                startDate: {
                    currentValue: this.startDate,
                    previousValue: this.oldStartDate,
                    firstChange: null,
                    isFirstChange: () => null,
                },
                endDate: {
                    currentValue: this.endDate,
                    previousValue: this.oldEndDate,
                    firstChange: null,
                    isFirstChange: () => null,
                },
            });
        }

        this.oldStartDate = this.startDate;
        this.oldEndDate = this.endDate;
        if(emitCloseOnChanges){
            this.closeMenu();
        }
    }

    private dateCheck = (d: Date) => d instanceof Date && !isNaN(d.getTime());

    private monthDifference(startDateValue: Date, endDateValue: Date): boolean {
        // ensure args
        if (!startDateValue || !endDateValue) {
            return;
        }

        const startDate = new Date(startDateValue);
        const endDate = new Date(endDateValue);

        if (!this.dateCheck(startDate)) {
            this.invalidStartDate = true;
            this.startDateChange.emit(null);
            this.isDateChanged.emit(true);
            return;
        } else {
            this.displayStartDate = startDate;
        }
        this.invalidStartDate = false;

        if (!this.dateCheck(endDate)) {
            this.invalidEndDate = true;
            this.endDateChange.emit(null);
            this.isDateChanged.emit(true);
            return;
        } else {
            this.displayEndDate = endDate;
        }
        this.invalidEndDate = false;

        if (endDate < startDate) {
            this.invalidEndDate = true;
            this.isValidDateRange.emit(false);
            return false;
        } else {
            this.invalidEndDate = false;
        }

        const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (this.maxDayRange) {
            if (diffDays > this.maxDayRange) {
                this.invalidRange = true;
                return false;
            } else {
                this.invalidRange = false;
            }
        }

        this.startDate = startDate;
        this.startDateChange.emit(this.startDate);

        this.endDate = endDate;
        this.endDateChange.emit(this.endDate);
        this.isValidDateRange.emit(true);
        this.isDateChanged.emit(true);
        this.isValidDateRange.emit(true);

        this.tickIntervalChange.emit(HydrographTickInterval.daily);

        if (diffDays > 30) {
            this.tickIntervalChange.emit(HydrographTickInterval.Weekly);
        }
        if (diffDays > 90) {
            this.tickIntervalChange.emit(HydrographTickInterval.Monthly);
        }

        return true;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
