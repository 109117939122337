import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, OnDestroy, Input, Output, EventEmitter, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RAIN_ENTITY, RAIN_FINAL_ENTITY, UnitOfMeasureType } from 'app/shared/constant';
import { CumulativeRainRequest } from 'app/shared/models/location-details';
import { MapRain, MapRainColors } from 'app/shared/models/map-view';
import { Selectable } from 'app/shared/models/selectable';
import { IN_TO_MM_FACTOR, MM_TO_MM_FACTOR } from 'app/shared/models/units';
import { QUICK_DATE_RANGES } from 'app/shared/models/view-data';
import { CustomerService } from 'app/shared/services/customer.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { LocationService } from 'app/shared/services/location.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { UsersService } from '../admin/users.service';
import { DateService } from 'app/shared/services/date.service';

export interface CumulativeRainInfo {
    value: number;
    description: string;
}

@Component({
  selector: 'ads-cumulative-rain-widget',
  templateUrl: './cumulative-rain-widget.component.html',
  styleUrls: ['./cumulative-rain-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CumulativeRainWidgetComponent implements OnInit, OnChanges, OnDestroy {
    @Input() filters: boolean;
    @Input() locationGroupID: number;
    @Input() customerID: number;
    @Input() includeInactiveLocations: boolean;

    @Output() cumulativeRainInfo = new EventEmitter<CumulativeRainInfo>();
    @Output() cumulativeRainReport: EventEmitter<MapRain[]> = new EventEmitter();

    cumulativeRainData: MapRain[];
    selectedTimeSpan: QUICK_DATE_RANGES = QUICK_DATE_RANGES.LAST_MONTH;

    public loading = {
        isLoadingLocations: false,
        isLoadingReport: false
    };

    public locations: Selectable[] = [];
    public preSelectedLocations: Selectable[] = [];

    public legendItems = MapRainColors;

    public units = {
        unitTitle: '',
        unitFactor: null,
        unitPrecisionString: '1.2-2'
    }

    public customerDateFormat: string;

    public filterOptions = {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate(), 0, 0, 0),
        endDate: new Date(new Date().setHours(23,59,59,999)),
    };

    public isDateChanged = false;
    public dateCloseEvent = new Subject();
    private subscriptions: Array<Subscription> = [];

    private translations = {
        prefix: ''
    }

    constructor(
        private locationService: LocationService,
        private usersService: UsersService,
        private dateutilService: DateutilService,
        private dateService: DateService,
        private customerService: CustomerService,
        private translateService: TranslateService,
        private uiUtils: UiUtilsService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        const dateRange = this.usersService.getQuickSpanCumulativeRainDateRange();
        this.filterOptions = {
            startDate: dateRange.startDate,
            endDate: dateRange.endDate
        }

        const quickSpanSetting = this.usersService.getQuickSpanCumulativeRainSetting();
        this.selectedTimeSpan = this.dateService.quickSpanUserPreferencesToQuickDateRange(quickSpanSetting);

        this.translations.prefix = this.translateService.instant('HOME.CUMULATIVE_RAIN_TILE.ITEM_TEXT');

        this.subscriptions.push(this.dateutilService.dateFormat.subscribe(() => {
                this.customerDateFormat = this.dateutilService.getFormat();
            })
        );
        this.subscriptions.push(this.customerService.getCustomerUnits(this.customerID).subscribe(() => {
            if(this.customerService.customer.unitsType === UnitOfMeasureType.METRIC) {
                this.units.unitTitle = 'mm';
                this.units.unitFactor = IN_TO_MM_FACTOR;
                this.units.unitPrecisionString = '1.1-1';
            } else {
                this.units.unitTitle = 'in';
                this.units.unitFactor = MM_TO_MM_FACTOR;
                this.units.unitPrecisionString = '1.2-2';
            }
        }));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.locationGroupID || changes.customerID || changes.includeInactiveLocations) {
            this.getLocations();
        }
    }


    private getLocations() {
        this.loading.isLoadingLocations = true;
        this.usersService.getRainLocations(
            this.customerID,
            this.includeInactiveLocations,
            this.locationGroupID,
            true
        ).subscribe((res) => {
            if(res) {
                this.locations = res.map(v => ({ name: v.locationName, id: v.locationId, isChecked: true }));
                this.preSelectedLocations = this.locations;
            }
            this.handleSelectedLocations();
        },
        () => {
            this.loading.isLoadingLocations = false;
            this.uiUtils.safeChangeDetection(this.cdr);
        },
        () => {
            this.loading.isLoadingLocations = false;
            this.uiUtils.safeChangeDetection(this.cdr);
        })
    }

    public userHandleSelectedLocations(event: Selectable[]) {
        this.preSelectedLocations = this.locations.filter(l => l.isChecked);
        this.handleSelectedLocations();
    }

    public handleSelectedLocations() {
        this.generateReport();
    }

    public handleSelectedDateRange() {
        this.generateReport();
    }

    public onSelectedTimespanChange(event) {
        this.selectedTimeSpan = event;
    }

    public clearTimeSpan() {
        this.selectedTimeSpan = null;
    }

    public createDescription(): string {
        let sufix = '';
        if(this.selectedTimeSpan !== null) {
            sufix = this.dateService.quickSpanDescription(this.selectedTimeSpan);
        } else {
            const dateFormat = this.customerDateFormat.toUpperCase();
            sufix =
                `${moment(this.filterOptions.startDate).format(dateFormat)} - ${moment(this.filterOptions.endDate).format(dateFormat)}`;
        }

        return `${this.translations.prefix} ${sufix}`;
    }

    public generateReport() {
        const hasRawDataPermission = this.usersService.isRawDataEditingAllowed.getValue();

        this.loading.isLoadingReport = true;
        const dateRange = this.usersService.convertDateRangeToUTCString(this.filterOptions);
        const req: CumulativeRainRequest = {
            customerId: this.customerID,
            locationIds: this.locations.filter(l => l.isChecked).map(l => l.id),
            start: dateRange.startDate,
            end: dateRange.endDate,
            includeInactiveLocations: this.includeInactiveLocations,
            entityIds: [RAIN_ENTITY]
        };

        if(req.locationIds.length === 0) {
            this.loading.isLoadingReport = false;
            this.cumulativeRainData = [];
            this.cumulativeRainReport.emit(this.cumulativeRainData);
            return;
        }

        this.locationService.rainCumulativeGet(req).subscribe((res) => {
            if(res) {
                this.cumulativeRainData = res.locations.map(loc => {
                    return {
                        lid: loc.locationId,
                        res: loc.rain,
                        runit: res.unitTitle,
                        rtype: null,
                        /**
                         *  graph will be displayed by default, set to false if one do not want to see bar
                         * look at: const graphValue = currentMonitorRainData.showBar !== false ? currentMonitorRainData.res * 30 / max : null;
                         */
                        showBar: false,
                        showEmpty: false
                    }
                });
                this.cumulativeRainInfo.emit({
                    value: res.count,
                    description: this.createDescription()
                })
                this.cumulativeRainReport.emit(this.cumulativeRainData);
            } else {
                this.units.unitFactor = null;
            }
        },
        () => {
            this.units.unitFactor = null;
        },
        () => {
            this.loading.isLoadingReport = false;
            this.uiUtils.safeChangeDetection(this.cdr);
        });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions = [];
    }
}
