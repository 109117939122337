<div id="flowMonitorChangesDialog">
    <div class="popupHeader" mat-dialog-title>{{ 'SLIICER.FLOW_TAB.CHANGES_DIALOG.TITLE' | translate }}</div>
    <div class="dialogBody">
        <p>{{ 'SLIICER.FLOW_TAB.CHANGES_DIALOG.MESSAGE' | translate }}</p>
        <ul class="savedata-list">
            <li *ngIf="data.dryDaysAdjusted">
                {{ 'SLIICER.FLOW_TAB.CHANGES_DIALOG.DRY_DAYS_CHANGED' | translate }}
            </li>
            <li *ngIf="data.stormExclusionsAdjusted">
                {{ 'SLIICER.FLOW_TAB.CHANGES_DIALOG.STORM_EXCLUSIONS_CHANGED' | translate }}
            </li>
            <li *ngIf="data.stormPeriodsAdjusted">
                {{ 'SLIICER.FLOW_TAB.CHANGES_DIALOG.STORM_PERIODS_CHANGED' | translate }}
            </li>
        </ul>
    </div>
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button class="btn-color" (click)="close('cancel')">
                {{ 'SLIICER.COMMON.CANCEL_BUTTON_TITLE' | translate }}
            </button>
            <button mat-button class="btn-color" (click)="close('discard')">
                {{ 'SLIICER.COMMON.DISCARD_BUTTON_TITLE' | translate }}
            </button>
            <button mat-button (click)="close('save')">{{ 'SLIICER.COMMON.SAVE_BUTTON_TITLE' | translate }}</button>
        </div>
    </div>
</div>
