import { Component, Input, Optional, Host, OnInit, Output, EventEmitter } from '@angular/core';
import { SatPopover } from '@ncstate/sat-popover';

@Component({
    selector: 'inline-edit',
    styleUrls: ['inline-edit.component.scss'],
    template: `
        <form (ngSubmit)="onSubmit()">
            <div class="mat-subheading-2">{{title}}</div>

            <mat-form-field>
                <input matInput [maxLength]="maxlength" name="name" [(ngModel)]="name" />
                <mat-hint align="end">{{ name?.length || 0 }}/{{maxlength}}</mat-hint>
            </mat-form-field>
            <div class="mat-error" *ngIf="validateFn(name, value)">
                {{ errorText }}
            </div>

            <div class="actions">
                <button mat-button type="button" color="primary" (click)="onCancel()">CANCEL</button>
                <button mat-button [type]="closeOnSave ? 'submit' : 'button'" color="primary" [disabled]="!name || validateFn(name, value)" (click)="saveClick()">SAVE</button>
            </div>
        </form>
    `,
})
export class InlineEditComponent implements OnInit {
    @Input() public maxlength = 140;
    @Input() public title = 'Add a name';
    @Input() public closeOnSave = true;
    @Input() public validateFn = (name: string, initialValue: string) => false;
    @Input() public errorText = '';
    @Input()
    get value(): string {
        return this._value;
    }
    set value(x: string) {
        this.name = this._value = x;
    }
    private _value = '';

    @Output() public save = new EventEmitter<string>();

    /** Form model for the input. */
    name = '';

    constructor(@Optional() @Host() public popover: SatPopover) {}

    ngOnInit() {
        // subscribe to cancellations and reset form value
        if (this.popover && this.popover.closed) {
            this.popover.closed
            .subscribe((val) => {
                if(val == null) this.name = this.value || '';
            });
        }
    }

    onSubmit() {
        if (this.popover) {
            this.popover.close(this.name);
        }
    }

    onCancel() {
        if (this.popover) {
            this.popover.close();
        }
    }

    saveClick() {
        this.save.emit(this.name);
    }
}
