import { GetPermissionsResponseCustomerPermissions } from './users-permission';

export class UserPreference {
    public rainUserPreferenceUI: { value: string; text: string; isSelected: boolean; SubscriptionType: string }[];
    // public customers?: Array<Customer>;
    public alarmUserPreferenceUI?: Array<RainUserPreferenceUI>;
}

export interface AlarmUserPreferenceUI {
    isSelected: boolean;
    value: string;
    text: string;
    alarmName?: string;
    SubscriptionType: string;
    smsNotification?: boolean;
    emailNotification?: boolean;
    Preference?: number;
}

export interface UserSettingsAdminUpdate {
    notificationPrimaryEmail?: boolean;
    notificationSecondaryEmail?: boolean;
    notificationSMS?: boolean;
    acceptedTerms?: boolean;
    syncZoom?: boolean;
    scattergraphToolbar?: boolean;
}

export interface AlarmUserPreference {
    UserID?: string;
    SubscriptionType?: string;
    Threshold?: number;
    LocationID?: number;
    Preference?: string;
    smsNotification?: boolean;
}

export class EditUserRequest {
    public firstName?: string;
    public lastName?: string;
    public userEmail: string;
    public secondaryEmail?: string;
    public phoneNumber?: string;
    public defaultCustomer?: number;
}

export interface UserPreferencesCustomer {
    permissionID: string;
    defaultCustomer: boolean;
    customer: GetPermissionsResponseCustomerPermissions;
    isSelected?: boolean;
}
export interface RainUserPreferenceUI {
    isSelected: boolean;
    value: string;
    text: string;
    SubscriptionType: string;
}
export interface RainUserPreferenceUI {
    isSelected: boolean;
    value: string;
    text: string;
    SubscriptionType: string;
}
