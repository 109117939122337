import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, ParamMap } from '@angular/router';
import { customerQueryParam } from 'app/shared/models/customer';

@Injectable()
export class VaultTelemetryExportResolverService  {
    constructor(private activatedRoute: ActivatedRoute) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): number {
        let result = 0;
        this.activatedRoute.queryParamMap
            .subscribe((params: ParamMap) => {
                result = Number(params.get(customerQueryParam));
            })
            .unsubscribe();

        return result;
    }
}
