import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    ChangeDetectorRef,
    Input,
    ViewChild,
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { OrderByPipe } from 'app/shared/pipes/order-by-pipe';
import { EMPTY, Subscription } from 'rxjs';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { StringUtils } from 'app/shared/utils/string-utils';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { MatDatetimepickerComponent } from '@mat-datetimepicker/core';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SharedService } from 'app/shared/services/shared.service';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { ScheduleCollectionService } from 'app/shared/services/schedule-collection.service';
import { Selectable } from 'app/shared/models/selectable';
import { LocationGroup } from 'app/shared/models/location-group';
import { ScheduleCollection } from 'app/shared/models/schedule-collection';
import { LocationArgs } from 'app/shared/models/locations';
import { locationIdQueryParam } from 'app/shared/models/customer';
import { map } from 'rxjs/operators';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
const COLLECTION_SCHEDULE_EXISTS = 'Collection Schedule already exists!';
@Component({
    selector: 'ads-prism-add-schedule',
    templateUrl: './ads-prism-add-schedule.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./ads-prism-add-schedule.component.scss'],
})
export class AdsPrismAddScheduleComponent implements OnInit, OnDestroy {
    // Represents a collection of location groups.
    public filteredLocations: Array<Selectable> = [];
    public allLocations: Array<Selectable> = [];
    public locationGroups: Array<LocationGroup> = [];
    public selectedLocations: Array<Selectable> = [];
    public scheduleName: string;
    public frequency: number;
    public collectTime: string;
    public collectTimeValue: string;
    public levelList: any;
    public listOfDays: Array<ListOfDaysItem>;
    public frequenciesList: Array<Object>;
    public eventFrequenciesList: Array<Selectable>;
    public customerId: number;
    @Input() public locationId: number;
    public isLocationGroupSelectDisabled: BehaviorSubject<boolean>;
    public searchText: string;
    public selectAll = false;
    public areAllLocationsScheduled = false;
    public selectedLocationCount: number;
    public placeholder: string;
    public alarmingFrequency: number;
    public collectionAlarmingFrequency: Selectable;
    public hourText: string;
    public minutesText: string;
    public hourlyText: string;
    public locationError: boolean;
    public disableSave = true;
    public isFormPristine: boolean;
    public daysError: boolean;
    public collectionScheduleLoadingState: boolean;
    public dismiss: string;
    public scheduleCreateSuccess: string;
    // retain schedule name to edit
    public oldScheduleName = '';
    public isDuplicateScheduleName: boolean;
    // Represents snackbar message
    public scheduleUpdateSuccess: string;
    public scheduleExists: string;
    public redirectSourceUrl: string;
    public allLocationsAreScheduled: string;
    public homePage = '/dashboard';
    public locationDetailsPage = '/pages/viewLocationDetails';
    public includeActiveLocation: number;
    public allSchedules: Array<ScheduleCollection>;
    public is12HourFormat = false;
    public dateFormat: string;
    public customDateFormat: string;
    public translateKeys: Array<string> = [
        'HOME.WIDGET_SCHEDULE.MINUTES_TEXT',
        'VAULT.VAULT_ITEM_SHARE.HOURS_TEXT',
        'HOME.WIDGET_SCHEDULE.HOURLY_TEXT',
        'COMMON.DISMISS_TEXT',
        'HOME.WIDGET_SCHEDULE.SCHEDULE_CREATE_SUCCESS',
        'HOME.WIDGET_SCHEDULE.SCHEDULE_UPDATE_SUCCESS',
        'HOME.WIDGET_SCHEDULE.SCHEDULE_EXISTS',
        'HOME.WIDGET_SCHEDULE.ALL_LOCATIONS_ARE_SCHEDULED',
    ];

    // private variables
    private componentSubscriptions: Array<Subscription> = [];
    private scheduledLocations: Array<ScheduleCollection>;
    private scheduleIdUpdate: number;
    public isDashboardCommunication = false;
    public isLocationCard = false;
    public isLocationDetails = false;
    public timeError: boolean;
    public showpageHint = false;
    @ViewChild(MatDatetimepickerComponent) public timePicker: MatDatetimepickerComponent<Date>;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AdsPrismAddScheduleComponent>,
        private scheduleCollectionService: ScheduleCollectionService,
        private locationGroupService: LocationGroupService,
        private uiUtilsService: UiUtilsService,
        private translate: TranslateService,
        private sharedService: SharedService,
        private snackBarNotificationService: SnackBarNotificationService,
        private changeDetectorRef: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private snackBar: MatSnackBar,
        private dateutilService: DateutilService,
        private domOperationService: DomOperationUtilsService,
    ) {
        translate.get(this.translateKeys).subscribe((translateValues) => {
            this.hourText = translateValues['VAULT.VAULT_ITEM_SHARE.HOURS_TEXT'];
            this.minutesText = translateValues['HOME.WIDGET_SCHEDULE.MINUTES_TEXT'];
            this.hourlyText = translateValues['HOME.WIDGET_SCHEDULE.HOURLY_TEXT'];
            this.dismiss = translateValues['COMMON.DISMISS_TEXT'];
            this.scheduleCreateSuccess = translateValues['HOME.WIDGET_SCHEDULE.SCHEDULE_CREATE_SUCCESS'];
            this.scheduleUpdateSuccess = translateValues['HOME.WIDGET_SCHEDULE.SCHEDULE_UPDATE_SUCCESS'];
            this.scheduleExists = translateValues['HOME.WIDGET_SCHEDULE.SCHEDULE_EXISTS'];
            this.allLocationsAreScheduled = translateValues['HOME.WIDGET_SCHEDULE.ALL_LOCATIONS_ARE_SCHEDULED'];
        });

        this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            this.locationId = Number(params.get(locationIdQueryParam));
        });
    }

    public ngOnInit() {
        const hintSubs = this.domOperationService.showpageHint.subscribe((data) => {
            this.showpageHint = data;
            this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
        });
        this.componentSubscriptions.push(hintSubs);
        // setup the state of the location group dropdown - default is enabled
        this.isLocationGroupSelectDisabled = this.locationGroupService.isLocationGroupDisabled;
        this.loadDropDown();
        // to set no preselect value

        const timeSubs = this.dateutilService.timeFormat.subscribe((value) => {
            this.is12HourFormat = value !== 'hh:mm:ss';

            if (!this.collectTime && !this.data.isEditMode) {
                this.setCollectTime(new Date());
            }
        });

        this.componentSubscriptions.push(timeSubs);

        this.collectionAlarmingFrequency = null;
        if (this.data) {
            this.scheduledLocations = this.data.schedules || [];
            this.locationId = this.data.locationId;
            const selectedLocation: Array<Selectable> = [];
            if (this.locationId) {
                selectedLocation.push({ id: this.locationId, name: '', isChecked: true });
            }
            if (this.data.isEditMode) {
                this.populateCollectTimeOnEdit();
                this.isFormPristine = true;
                if (this.data.selectedScheduleId) {
                    this.getscheduleData(this.data.selectedScheduleId);
                } else {
                    this.setupLocationGroupDropdown(null, selectedLocation);
                }
            } else {
                this.setupLocationGroupDropdown(null, selectedLocation);
                this.disableSave = this.validateForm();
            }
        }
    }

    populateCollectTimeOnEdit() {
        if (!this.data.schedules) {
            return;
        }

        const schedule = this.data.schedules.find((sch) => sch.scheduleId === this.data.selectedScheduleId);

        if (!schedule || !schedule.collectTime) {
            return;
        }

        const time = schedule.collectTime.split(':');
        const date = new Date();
        date.setHours(time[0]);
        date.setMinutes(time[1]);

        this.setCollectTime(date);
    }

    public loadDropDown() {
        this.frequency = 24;
        this.listOfDays = [
            { text: 'Su', isSelected: true, apiValue: 0 },
            { text: 'M', isSelected: true, apiValue: 1 },
            { text: 'Tu', isSelected: true, apiValue: 2 },
            { text: 'W', isSelected: true, apiValue: 3 },
            { text: 'Th', isSelected: true, apiValue: 4 },
            { text: 'F', isSelected: true, apiValue: 5 },
            { text: 'Sa', isSelected: true, apiValue: 6 },
        ];
        this.frequenciesList = [
            { text: this.hourlyText, value: 1 },
            { text: `${4} ${this.hourText}`, value: 4 },
            { text: `${6} ${this.hourText}`, value: 6 },
            { text: `${8} ${this.hourText}`, value: 8 },
            { text: `${12} ${this.hourText}`, value: 12 },
            { text: `${24} ${this.hourText}`, value: 24 },
        ];
        this.eventFrequenciesList = [
            { name: `${15} ${this.minutesText}`, id: 15 },
            { name: this.hourlyText, id: 1 },
            { name: `${4} ${this.hourText}`, id: 4 },
            { name: `${6} ${this.hourText}`, id: 6 },
            { name: `${8} ${this.hourText}`, id: 8 },
            { name: `${12} ${this.hourText}`, id: 12 },
        ];
    }

    public getscheduleData(scheduleId) {
        this.collectionScheduleLoadingState = true;
        const subscription = this.scheduleCollectionService
            .getSelectedScheduleCollection(scheduleId)
            .subscribe((schedule) => {
                if (schedule) {
                    this.scheduleIdUpdate = schedule.scheduleId;
                    this.scheduleName = this.oldScheduleName = schedule.name;
                    this.selectedLocations = schedule.locations;
                    this.frequency = parseInt(schedule.frequency.split('Hrs')[0], 10);
                    if (schedule.alarmingFrequency.includes('15mins')) {
                        this.alarmingFrequency = 15;
                    } else {
                        this.alarmingFrequency = +schedule.alarmingFrequency.split('Hrs')[0];
                    }
                    if (this.alarmingFrequency) {
                        this.collectionAlarmingFrequency = this.eventFrequenciesList.find(
                            (item) => item.id === this.alarmingFrequency,
                        );
                    }
                    const dayArray: Array<ListOfDaysItem> = [];
                    const days = schedule.collectionDays.split(',');
                    days.forEach((element) => {
                        const items = this.listOfDays.find((x) => x.apiValue === +element);
                        if (items) {
                            dayArray.push(items);
                        }
                    });
                    this.listOfDays.map((item) => {
                        item.isSelected = dayArray.some((x) => x.text === item.text);
                    });
                    this.setupLocationGroupDropdown(scheduleId, schedule.locations);
                    this.disableSave = this.validateForm();
                }
                this.collectionScheduleLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
            });
        this.componentSubscriptions.push(subscription);
    }

    // Represents the Update Action of Schedule Collection
    private editScheduleCollection() {
        this.collectionScheduleLoadingState = true;
        const scheduleCollection: ScheduleCollection = new ScheduleCollection();
        scheduleCollection.name = this.scheduleName;
        scheduleCollection.frequency = this.frequency * 60;
        scheduleCollection.collectTime = this.collectTimeValue;
        scheduleCollection.collectionDays = this.listOfDays
            .filter((element) => element.isSelected)
            .map((element) => element.apiValue);

        scheduleCollection.locations = this.allLocations
            .filter((element) => element.isChecked)
            .map((element) => element.id);
        const editScheduleCollectionSubscription = this.scheduleCollectionService
            .updateScheduleCollection(
                this.scheduleIdUpdate,
                scheduleCollection.locations,
                scheduleCollection.name,
                scheduleCollection.frequency,
                this.alarmingFrequency
                    ? this.alarmingFrequency === 15
                        ? this.alarmingFrequency
                        : this.alarmingFrequency * 60
                    : null,
                scheduleCollection.collectionDays,
                scheduleCollection.collectTime,
            )
            .subscribe(
                (result) => {
                    const displayedMessage = `${this.oldScheduleName} ${this.scheduleUpdateSuccess}`;
                    this.snackBarNotification(displayedMessage, '', true);

                    this.collectionScheduleLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
                    this.sharedService.setCloseFlagValue(true);
                    this.close(true);
                },
                (error) => {
                    this.sharedService.setCloseFlagValue(false);
                    this.collectionScheduleLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
                    this.snackBarNotification(this.scheduleExists, 'custom-error-snack-bar', false);
                },
            );
        this.componentSubscriptions.push(editScheduleCollectionSubscription);
    }

    // select or un-select all check-boxes.
    public toggleSelectAll() {
        this.selectedLocationCount = 0;
        this.selectAll = !this.selectAll;
        this.filteredLocations.map((element) => {
            element.isChecked = this.selectAll;
            this.elementSelect(element.id, this.selectAll);
        });
        this.allLocations.forEach((element) => {
            element.isChecked = this.selectAll;
        });
        this.isFormPristine = false;
        this.disableSave = this.validateForm();
    }

    public elementSelect(id, state) {
        this.filteredLocations.forEach(v => {
            if (v.id === id) v.isChecked = state;
        });

        this.allLocations.forEach(v => {
            if (v.id === id) v.isChecked = state;
        });
    }

    public changeSelecteState(location: Selectable) {
        location.isChecked = !location.isChecked;
        this.elementSelect(location.id, location.isChecked);
        this.isFormPristine = false;
        this.disableSave = this.validateForm();
    }
    public validateDateSelection(day: ListOfDaysItem, flag: boolean) {
        day.isSelected = !day.isSelected;
        this.isFormPristine = false;
        this.disableSave = this.validateForm();
    }

    public validateLocation() {
        if (this.filteredLocations) {
            const locationChecked = this.filteredLocations.filter((selectable: Selectable) => selectable.isChecked);
            this.locationError = !(locationChecked && locationChecked.length > 0);
        }
    }
    public validateDay() {
        if (this.listOfDays) {
            const daysSelected = this.listOfDays.filter((day: ListOfDaysItem) => day.isSelected);
            this.daysError = !(daysSelected && daysSelected.length > 0);
        }
    }

    public validateForm(): boolean {
        this.validateLocation();
        this.validateDay();
        return this.daysError || this.locationError;
    }

    private setupLocations(scheduleId?: number, selectedLocation?: any) {
        this.collectionScheduleLoadingState = true;
        this.searchText = '';
        const locationArgs = <LocationArgs>{
            customerId: this.customerId,
            IncludeInactiveLocations: false,
        };

        const locationsSubscription: Subscription = this.scheduleCollectionService
            .getAvailableLocations(this.customerId, scheduleId)
            .subscribe(
                (locations: Array<Selectable>) => {
                    if (locations && locations.length) {
                        this.filteredLocations = locations;
                        this.allLocations = locations;
                        if (!locationArgs.locationGroupId) {
                            this.filteredLocations = locations.map((location: Selectable) => {
                                return <Selectable>{
                                    id: location.id,
                                    name: location.name,
                                    isChecked: selectedLocation && selectedLocation.some((x) => x.id === location.id),
                                };
                            });

                            this.allLocations.forEach(loc => loc.isChecked = selectedLocation && selectedLocation.some((x) => x.id === loc.id));

                            selectedLocation &&
                                selectedLocation.map((element) => {
                                    const foundSelectedItem = this.filteredLocations.find(
                                        (selectable: Selectable) => selectable.id === element.id,
                                    );
                                    if (foundSelectedItem) {
                                        foundSelectedItem.isChecked = true;
                                    } else {
                                        this.filteredLocations.push({
                                            id: element.id,
                                            name: element.name,
                                            isChecked: true,
                                        });
                                    }
                                });
                        }
                        this.selectAll =
                            this.filteredLocations.length ===
                            this.filteredLocations.filter((selectable: Selectable) => selectable.isChecked).length;
                        this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
                    } else {
                        this.areAllLocationsScheduled = true;
                        this.snackBar.open(this.allLocationsAreScheduled, 'Dismiss', {
                            panelClass: 'custom-error-snack-bar',
                        });
                    }
                    this.collectionScheduleLoadingState = false;
                    this.disableSave = this.validateForm();
                    this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
                },
                (error) => {
                    if (typeof error.json() === 'object' && error.json().hasOwnProperty('responseMessage')) {
                        this.snackBarNotification(error.json().responseMessage, 'custom-error-snack-bar', false);
                        this.collectionScheduleLoadingState = false;
                    }
                },
            );
        this.componentSubscriptions.push(locationsSubscription);
        this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
    }

    private setupLocationGroupDropdown(scheduleId?: number, selectedLocation?: any) {
        this.customerId = this.data.customerId;

        const locationGroupsObservable: Observable<LocationGroup[]> = this.locationGroupService
            .getLocationGroups(this.customerId)
            .pipe(
                map((locationGroups: { [key: string]: Array<LocationGroup> }) => {
                    if (locationGroups) {
                        return locationGroups.locationGroups;
                    } else {
                        EMPTY;
                    }
                }),
            );
        const locationGroupsSubscription: Subscription = locationGroupsObservable.subscribe(
            (result: Array<LocationGroup>) => {
                if (result) {
                    const filterPipe = new OrderByPipe();
                    this.locationGroups = filterPipe.transform(result, 'name', false);
                    this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
                } else {
                    this.locationGroups = [];
                }
            },
            (error) => this.handleError(error),
        );
        this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
        this.setupLocations(scheduleId, selectedLocation);
        this.componentSubscriptions.push(locationGroupsSubscription);
    }

    public close(state) {
        this.dialogRef.close({ success: state });
    }

    public handleError(error: Error) {
        this.locationGroups = [];
    }

    public alarmingFrequencySelected(event) {
        this.isFormPristine = false;
        if (event.length === 0) {
            this.alarmingFrequency = null;
            return;
        }
        this.alarmingFrequency = event[0].id;
        this.disableSave = this.validateForm();
    }

    public saveScheduleCollection() {
        if (this.data.isEditMode) {
            this.editScheduleCollection();
        } else {
            const scheduleCollection: ScheduleCollection = new ScheduleCollection();
            scheduleCollection.name = this.scheduleName;
            scheduleCollection.frequency = this.frequency;
            scheduleCollection.alarmingFrequency = this.alarmingFrequency;
            scheduleCollection.collectTime = this.collectTimeValue;
            scheduleCollection.collectionDays = this.listOfDays
                .filter((element) => element.isSelected)
                .map((element) => element.apiValue);

            scheduleCollection.locations = this.allLocations
                .filter((element) => element.isChecked)
                .map((element) => element.id);
            scheduleCollection.frequency = scheduleCollection.frequency * 60;
            if (scheduleCollection.alarmingFrequency) {
                scheduleCollection.alarmingFrequency =
                    scheduleCollection.alarmingFrequency === 15
                        ? scheduleCollection.alarmingFrequency
                        : scheduleCollection.alarmingFrequency * 60;
            }
            const displayedMessage = `${this.scheduleName} ${this.scheduleCreateSuccess}`;
            this.collectionScheduleLoadingState = true;

            const addScheduleCollectionSubscription = this.scheduleCollectionService
                .postScheduleCollection(this.customerId, scheduleCollection)
                .subscribe(
                    (result) => {
                        this.snackBarNotification(displayedMessage, '', true);
                        this.collectionScheduleLoadingState = false;
                        this.sharedService.setCloseFlagValue(true);
                        this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
                        this.close(true);
                    },
                    (error) => {
                        this.sharedService.setCloseFlagValue(false);
                        this.collectionScheduleLoadingState = false;
                        this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
                        if (error.error) {
                            this.snackBarNotification(error.error, 'custom-error-snack-bar', false);
                            return;
                        }
                        if (error.statusText.includes('Conflict')) {
                            this.snackBarNotification(this.scheduleExists, 'custom-error-snack-bar', false);
                        }
                        this.uiUtilsService.safeChangeDetection(this.changeDetectorRef);
                    },
                );
            this.componentSubscriptions.push(addScheduleCollectionSubscription);
        }
    }

    public locationSetSearchAndGroup() {
        // Next filter by search string
        if (this.searchText && this.searchText !== '') {
            this.filteredLocations = this.allLocations.filter((loc) =>
                loc.name.toLowerCase().includes(this.searchText.toLowerCase())
            );
        } else {
            this.filteredLocations = this.allLocations;
        }
    }

    public snackBarNotification(message: string, notificationClass, auto) {
        this.snackBarNotificationService.raiseNotification(message, this.dismiss, {
            panelClass: notificationClass,
        }, auto);
    }
    /**
     * This function capitalized the string value with maximum one space in between word and
     * Checks for duplicate Schedule Name
     */
    public validateScheduleNameField() {
        this.isFormPristine = false;
        this.scheduleName = this.scheduleName.trim();
        if (this.scheduleName.length > 0) {
            if (
                this.scheduledLocations.find((x) => x.name.toLowerCase() === this.scheduleName.toLowerCase()) &&
                this.oldScheduleName.toLowerCase() !== this.scheduleName.toLowerCase()
            ) {
                this.isDuplicateScheduleName = true;
            } else {
                this.scheduleName = StringUtils.capitalizedStringWithSpace(this.scheduleName);
                this.isDuplicateScheduleName = false;
            }
        }
    }

    public clickInput() {
        this.timePicker.open();
    }

    public onDateInput(event) {
        const { value } = event;

        this.setCollectTime(value);
    }

    public onDateChange(event) {
        const { value } = event;

        this.setCollectTime(value);
    }

    private setCollectTime(time: string | Date) {
        const hours = new Date(time).getHours();
        let mins: number | string = new Date(time).getMinutes();

        const isAm = hours < 13;

        if (String(mins).length === 1) {
            mins = '0' + mins;
        }

        this.collectTimeValue = `${hours}:${mins}`;
        if (this.is12HourFormat) {
            this.collectTime = `${isAm ? hours : hours - 12}:${mins} ${isAm ? 'AM' : 'PM'}`;
        } else {
            this.collectTime = `${hours}:${mins}`;
        }
        this.isFormPristine = false;
        this.disableSave = this.validateForm();
    }

    checkTimeValue(event) {
        const { value } = event.target;
        const timeRegexp = /([0-9]|0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s*([AaPp]?[Mm]?)/gi;

        if (!timeRegexp.test(value)) {
            this.timeError = true;

            return;
        }

        const [time, AmPm] = value.split(' ');
        const [hours, mins] = time.split(':');

        if (!hours || !mins || isNaN(hours) || isNaN(mins) || Number(hours) > 23 || Number(mins) > 59) {
            this.timeError = true;
            return;
        }

        if (!AmPm) {
            this.collectTime = this.collectTimeValue = hours + ':' + mins;
            this.timeError = false;
        } else if (AmPm.toLowerCase() === 'am') {
            this.collectTime = `${hours}:${mins} AM`;
            this.collectTimeValue = hours + ':' + mins;
            this.timeError = false;
        } else if (AmPm.toLowerCase() === 'pm') {
            this.collectTime = `${hours}:${mins} PM`;
            const valueHour = Number(hours) + 12;
            this.collectTimeValue = valueHour + ':' + mins;
            this.timeError = false;
        } else {
            this.timeError = true;
        }
    }

    public ngOnDestroy() {
        if (this.componentSubscriptions && this.componentSubscriptions.length) {
            this.componentSubscriptions.forEach((subscription) => subscription.unsubscribe());
        }
    }
}

class LocalScheduleCollection {
    public scheduleId: number;
    public name: string;
    public frequency: number;
    public alarmingFrequency: number;
    public collectionDays: number[];
    public locations: number[];
    public isSelected: boolean;
}

class ListOfDaysItem {
    public text: string;
    public apiValue: number;
    public isSelected = true;
}
