import { Selectable } from 'app/shared/models/selectable';
import { Entities } from 'app/shared/models/entities';

/**
 * Represents the Telemetry Export object.
 */
export interface TelemetryExportDialog {
    /**
     * Represents the collection of locations.
     */
    locations?: Array<Selectable>;

    /**
     * Represents the collection of entities.
     */
    entities?: Array<Entities>;
}

/**
 * Represents an Entity (i.e., Rain, UNIDepth, etc.).
 */
export interface Entity {
    /**
     * Represents the entity surrogate identifier.
     */
    id: number;

    /**
     * Represents the entity name.
     */
    name: string;
}

/**
 * Represents the object returned by the telemetry validation API.
 */
export interface TelemetryValidationResult {
    customerFullName?: string;
    customerShortName?: string;
    locationName?: string;
    locationID?: number;
    rows?: number;
    startDate?: Date;
    endDate?: Date;
    validation: FileValidationStatus;
    entities?: Array<Entity>;
}

export enum FileValidationStatus {
    NotFound = 'NotFound',
    InvalidLocationHeader = 'InvalidLocationHeader',
    InvalidDataHeader = 'InvalidDataHeader',
    Unknown = 'Unknown',
    Successful = 'Successful',
}

/**
 * Represnts the Vault query parameters for retrieving the vault list.
 */
export interface TelemetryValidator {
    filePath: string;
    cid: number;
}

/**
 * Represents the model for sending telemetry parameters to the API.
 */
export interface TelemetryExportParmas {
    /**
     * Represents the Customer surrogate identifier.
     */
    cid: number;

    /**
     * Represents the telemetry end date.
     */
    endDate: string;

    /**
     * Represents a collection of entity Id's (i.e., Rain, Unidepth, etc.).
     */
    eids: Array<number>;

    /**
     * Represents a collection of locatioin Id's.
     */
    lids: Array<number>;

    /**
     * Represents the telemetry start date.
     */
    startDate: string;

    /**
     * Indicates whether to upload the file to Azure.
     */
    uploadToAzure: boolean;
}
export class VaultExportParmas {
    public includeMissingTimestamps: boolean;
    public missingTimestampValue?: string;
    public interval: number;
    public entityPerColumn: boolean;
    public singleFile: boolean;
    public locationGroupId: number;
    public endDate: string;
    public eIds: Array<number>;
    public lIds: Array<number>;
    public startDate: string;
    public uploadToAzure: boolean;
    public cid?: number;
    public style?: string;
    public useRounding?: boolean;
}
export class VaultEntityParmas {
    public lgid?: number;
    public lids?: Array<number>;
}

export class VaultExcelExportParams {
    public includeMissingTimestamps: boolean;
    public missingTimestampValue?: string;
    public style: string;
    public singleFile: boolean;
    public lgid: number;
    public endDate: string;
    public eids: Array<number>;
    public lids: Array<number>;
    public startDate: string;
    public cid?: number;
    public interval: number;
    public useRounding: boolean;
}
export class ExportSetting {
    public name: string;
    public locationGroupId: number;
    public lids: Array<number>;
    public eids: Array<number>;
    public includeMissingTimestamps: boolean;
    public missingTimestampValue?: string;
    public interval: number;
    public entityPerColumn: boolean;
    public singleFile: boolean;
    public quickSpan: number;
    public startDate: string;
    public endDate: string;
    public exportType: number;
    public excelStyle: number;
    public style?: string;
    public useRounding?: boolean;

    /** Whenever settings are not edit and should be applied. Used when opening popup from external views */
    public forceApplySettings?: boolean;
}

export class VautImportFileSummary {
    public id: string;
    public fileName: string;
    public customerFullName: string;
    public startDate: string;
    public endDate: string;
    public locationName: string;
    public entities: string;
    public isValidationSuccessful: boolean;
    public isChecked: boolean;
    public validation: string;
    public fileType: 0|1|2;
}
