import { DataExportFile, DataExportType, LocationType } from './../services/telemetry.service';
import { IReportRecord } from './report';
import { QUICK_DATE_RANGES } from './view-data';

export interface DailySummaryData {
    // tslint:disable-next-line:no-any
    name?: any;

    // tslint:disable-next-line:no-any
    value?: any;

    isIdentifier?: boolean;
}

export interface Header {
    name?: string;
    colspan?: number;
    group?: number;
    suffix?: string;
    totalSuffix?: string;
}

export interface DailySummaryReport extends IReportRecord {
    headers: Array<Array<Header>>;
    data: Array<Array<DailySummaryData>>;
    synopsis: Array<Synopsis>;
    totalRows: number;
}

export interface IDailySummaryLocationDetail {
    report?: DailySummaryReport;
}

export interface DailySummaryDetailsReportArgs {
    paging?: Paging;
    CustomerId: number;
    LocationGroupId?: number;
    LocationIds?: Array<number>;
    EntityIds?: Array<number>;
    Start?: string;
    End?: string;
    includeInactiveLocations?: boolean;
}

/**
 * used for formating Synopsis data with respect to entity
 */
export interface Synopsis {
    name?: string;
    group?: number;
    total?: string;
    average?: string;
    suffix?: string;
    totalSuffix?: string;
}

export interface Paging {
    sort?: string;
    pageSize?: number;
    startPage?: number;
    searchValue?: string;
}

type NewType = DataExportType;

export interface DailyReportExport {
    cid?: number;

    fileType?: NewType;
    singleOrMultiple: DataExportFile;

    locationType?: LocationType;

    startDate?: string;
    endDate?: string;
}

export interface DailyReportDisplayModel {
    name?: string;
    value?: string;
    id: number;
}

export interface DailyReportSettings {
    eids: Array<number>;
    quickSpan: number;
    customStart?: Date;
    customStop?: Date;
}

export interface DailyReportExportParams {
    customerId?: number;
    locationGroupId?: number;
    locationIds?: number[];
    start?: string;
    end?: string;
    entityIds?: number[];
    includeInactiveLocations?: boolean;

    singleFile?: boolean;
}

export enum DailyQuickSpan {
    OneDay = 1,
    OneWeek,
    OneMonth,
    LastMonth,
    ThreeMonths,
    SixMonths,
    OneYear,
    Custom,
}


export const timespanToAPI = (timeSpan: QUICK_DATE_RANGES): DailyQuickSpan => {
    switch (timeSpan) {
        case QUICK_DATE_RANGES.TODAY:
            return DailyQuickSpan.OneDay;
        case QUICK_DATE_RANGES.LAST_WEEK:
            return DailyQuickSpan.OneWeek;
        case QUICK_DATE_RANGES.LAST_MONTH:
            return DailyQuickSpan.LastMonth;
        case QUICK_DATE_RANGES.PREVIOUS_MONTH:
            return DailyQuickSpan.OneMonth;
        case QUICK_DATE_RANGES.LAST_THREE_MONTHS:
            return DailyQuickSpan.ThreeMonths;
        case QUICK_DATE_RANGES.LAST_SIX_MONTHS:
            return DailyQuickSpan.SixMonths;
        case QUICK_DATE_RANGES.LAST_YEAR:
            return DailyQuickSpan.OneYear;
        default:
            return DailyQuickSpan.Custom;
    }
}

export const timespanFromAPI = (timeSpan: DailyQuickSpan): QUICK_DATE_RANGES => {
    switch (timeSpan) {
        case DailyQuickSpan.OneDay:
            return QUICK_DATE_RANGES.TODAY;
        case DailyQuickSpan.OneWeek:
            return QUICK_DATE_RANGES.LAST_WEEK;
        case DailyQuickSpan.LastMonth:
            return QUICK_DATE_RANGES.LAST_MONTH;
        case DailyQuickSpan.OneMonth:
            return QUICK_DATE_RANGES.PREVIOUS_MONTH;
        case DailyQuickSpan.ThreeMonths:
            return QUICK_DATE_RANGES.LAST_THREE_MONTHS;
        case DailyQuickSpan.SixMonths:
            return QUICK_DATE_RANGES.LAST_SIX_MONTHS;
        case DailyQuickSpan.OneYear:
            return QUICK_DATE_RANGES.LAST_YEAR;
        default:
            return QUICK_DATE_RANGES.LAST_WEEK;
    }
}