<mat-expansion-panel [ngClass]="{ 'echo-section-error': form.invalid }">
    <mat-expansion-panel-header class="mat-expasion-header-background">
        <mat-panel-title class="input-text">
            {{ 'ADD_EDIT.ECHO' | translate }}
        </mat-panel-title>
        <mat-panel-description *ngIf="form.invalid && (form | customErrorCount)">
            <mat-icon>warning</mat-icon>
            <span>
                {{ form | customErrorCount }}
                {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.TRITON_REQUIRED_ERRORS_TXT' | translate }}
            </span>
        </mat-panel-description>
        <mat-panel-description *ngIf="form.invalid && !(form | customErrorCount)">
            <mat-icon>warning</mat-icon> <span> </span>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <form [formGroup]="form">
        <mat-panel-subtitle class="input-text">
            {{ 'ADD_EDIT.ADD_EDIT_SUBTITLE' | translate }}
        </mat-panel-subtitle>
        <div [class.disable-field]="!isMonitorEditor">
            <div class="each-Item">
                <mat-form-field class="full-width">
                    <mat-select
                        placeholder="{{ 'ADD_EDIT.SENSOR' | translate }}"
                        class="full-width"
                        formControlName="sensor"
                    >
                        <mat-option value="{{ 'ADD_EDIT.LONG_RANGE_DEPTH' | translate }}">
                            {{ 'ADD_EDIT.LONG_RANGE_DEPTH' | translate }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="custom-50p">
                <div class="custom-flat-button custom-width slide-active-display">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            formControlName="silt"
                            [placeholder]="siltPlaceholder"
                            readonly
                            [value]="form.get('silt').value | number: '0.2'"
                        />
                    </mat-form-field>
                    <span
                        matTooltip="{{ 'COMMON.SILT_EDITOR' | translate }}"
                        matTooltipPosition="above"
                        *ngIf="userHasPermissionOnEdit || customerHasBasicEditPermission"
                    >
                        <button mat-button class="no-margin" [disabled]="!data.editMode" (click)="openSiltEditor()">
                            <mat-icon class="mat-24">edit</mat-icon>
                        </button>
                    </span>
                    <!-- <div class="mat-error" *ngIf="(siltEchoError && echoSilt.touched)">
                  {{ 'COMMON.TRITON_SILT_PRECISION_ERROR' | translate }}
              </div> -->
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            placeholder="{{ 'ADD_EDIT.HC_VALUE' | translate }}"
                            formControlName="hcValue"
                            [value]="form.get('hcValue').value"
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('hcValue').errors?.pattern">
                        <span>{{ 'ADD_EDIT.HC_VALUE_ERROR_MSG' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-expansion-panel>
