import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUnitsRow, UnitCategoryType } from '../models/units';
import { Config } from './config';
import { Observable } from 'rxjs';

@Injectable()
export class UnitsService {

    constructor(private http: HttpClient) {}

    public getUnitsCategories(customerId: number, categories: UnitCategoryType[]): Observable<Array<ApiUnitsRow>> {
        return this.http.post<Array<ApiUnitsRow>>(`${Config.urls.unitsCategories}?cid=${customerId}`, categories);
    }
}
