import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    ChangeDetectorRef,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { VaultService } from '../vault.service';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { VaultDirectory } from 'app/shared/models/vault';

@Component({
    selector: 'app-vault-nav',
    templateUrl: './vault-nav.component.html',
    styleUrls: ['./vault-nav.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class VaultNavComponent implements OnDestroy, OnInit {
    /**
     * Represents the colleciton of files and folders.
     */
    @Input() public vault: VaultDirectory;

    /**
     * Repreents a value indicating whether the root directory is selected..
     */
    public isActive: boolean;

    /**
     * Represents the subscriptions to be disposed.
     */
    private subscriptions = new Array<Subscription>();

    constructor(
        private domOperationUtilsService: DomOperationUtilsService,
        private vaultService: VaultService,
        private chagneDetector: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
    ) {}

    /**
     * Lifecycle hook that is called when a directive, pipe or service is destroyed.
     */
    public ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    /**
     * Lifecycle hook that is called after data-bound properties of a directive are initialized.
     */
    public ngOnInit() {
        this.subscriptions.push(
            this.vaultService.CurrentDirectory.subscribe((id: string) => {
                this.isActive = id === '/';
                this.uiUtilsService.safeChangeDetection(this.chagneDetector);
            }),
        );
    }

    /**
     * Click handler for selecting root folder.
     */
    public selectRootFolder(): void {
        this.domOperationUtilsService.scrollToTop('html');
        this.vaultService.getFilesForDirectory('/');
    }
}
