<div id="errorPageId" class="contentLayout">
    <mat-card>
        <mat-card-header>
            <div class="auto-margin">{{ 'COMMON.ERROR_COLUMN_TITLE' | translate }}</div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin pad-r-15">
                <mat-icon
                    matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                    class="backIcon"
                    [ngClass]="{ active: displayFilters }"
                    (click)="showFilter()"
                    >filter_list</mat-icon
                >
            </div>
        </mat-card-header>
        <div id="percentFullReportFilterId">
            <app-widget-filters
                (sendDataToWidget)="notifyReport($event)"
                [filterSettings]="{
                    displayDateRanges: true,
                    dateSpanPastWeek: true,
                    minDisplayLimit: 1,
                    maxDisplayLimit: 6,
                    skipInitialCall: true
                }"
                [customerID]="customerID"
                [locationGroupID]="locationGroupID"
                [hidden]="!displayFilters"
            >
            </app-widget-filters>
        </div>
        <mat-card-content>
            <mat-form-field class="eachItem">
                <input
                    matInput
                    type="text"
                    placeholder="{{ 'ADMIN.SEARCH_CUST_PLACEHOLDER' | translate }}"
                    [formControl]="searchString"
                />
            </mat-form-field>
            <app-loader [isLoading]="isLoading"></app-loader>
            <div>
                <div id="customersPageTable">
                    <mat-table #table [dataSource]="customerDataSource" matSort>
                        <!-- CUSTOMER NAME Column -->
                        <ng-container matColumnDef="name">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                                'COMMON.CUSTOMER_NAME_TEXT' | translate
                            }}</mat-header-cell>
                            <mat-cell *matCellDef="let customer" [ngClass]="{ selected: customer.highLight }">
                                {{ customer.name }}
                            </mat-cell>
                        </ng-container>

                        <!-- CUSTOMER ACTIVE LOCATIONS Column -->
                        <ng-container matColumnDef="activeLocations">
                            <mat-header-cell *matHeaderCellDef class="text-right" mat-sort-header>{{
                                'ADMIN.TOTAL_ACTIVE_LOCATIONS' | translate
                            }}</mat-header-cell>
                            <mat-cell
                                *matCellDef="let customer"
                                class="text-right"
                                [ngClass]="{ selected: customer.highLight }"
                            >
                                {{ customer.activeLocations }}
                            </mat-cell>
                        </ng-container>

                        <!-- CUSTOMER ACTIVE LOCATIONS Column -->
                        <ng-container matColumnDef="inactiveLocations">
                            <mat-header-cell *matHeaderCellDef class="text-right" mat-sort-header>{{
                                'ADMIN.TOTLA_INACTIVE_LOCATIONS' | translate
                            }}</mat-header-cell>
                            <mat-cell
                                *matCellDef="let customer"
                                class="text-right"
                                [ngClass]="{ selected: customer.highLight }"
                            >
                                {{ customer.inactiveLocations }}
                            </mat-cell>
                        </ng-container>

                        <!-- CUSTOMER FEATURES Column -->
                        <ng-container matColumnDef="features">
                            <mat-header-cell *matHeaderCellDef>{{
                                'ADMIN.ASSIGN_FEATURES' | translate
                            }}</mat-header-cell>
                            <mat-cell
                                *matCellDef="let customer"
                                class="featuresList"
                                [ngClass]="{ selected: customer.highLight }"
                            >
                                <div *ngFor="let feature of customer.features">
                                    {{ feature.name }}
                                    <span>,</span>
                                </div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="editCustomer">
                            <mat-header-cell *matHeaderCellDef class="fixCellWidth"></mat-header-cell>
                            <mat-cell
                                *matCellDef="let customer"
                                class="text-right"
                                [ngClass]="{ selected: customer.highLight }"
                            >
                                <mat-icon
                                    matTooltip="{{ 'COMMON.EDIT_TOOLTIP' | translate }}"
                                    matTooltipPosition="left"
                                    (click)="editCustomerFeatures(customer)"
                                    >mode_edit</mat-icon
                                >
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="customerFilterColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: customerFilterColumns"></mat-row>
                    </mat-table>
                </div>
            </div>
        </mat-card-content>
        <mat-card-actions>
            <div class="app-flex-filler"></div>
            <div class="auto-margin" [hidden]="totalPaginationLength <= 10">
                <mat-paginator
                    [length]="totalPaginationLength"
                    [pageIndex]="0"
                    [pageSize]="25"
                    [pageSizeOptions]="[10, 25, 100]"
                >
                </mat-paginator>
            </div>
        </mat-card-actions>
    </mat-card>
</div>
