import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FTPService } from 'app/shared/services/ftp-service';
import { FtpSweepModel } from 'app/shared/models/ftp-sweep';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { customerEditorQueryParam } from 'app/shared/models/customer';

const DEFAULT_PROTOCOL_VALUE = 1;

@Component({
    selector: 'app-ads-prism-ftp-add-edit',
    templateUrl: './ads-prism-ftp-add-edit.component.html',
    styleUrls: ['./ads-prism-ftp-add-edit.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdsPrismFtpAddEditComponent implements OnInit {
    public ftpForm: FormGroup;
    public customerId = 0;
    public editMode = false;
    public saving = false;

     // Hide other options for now #22618
    public protocols = [
        // { viewValue: 'SFTP', value: 0 },
        { viewValue: 'FTP', value: 1 },
        // { viewValue: 'FTPS', value: 2 },
    ];

    public modes = [
        { viewValue: 'Active', value: false },
        { viewValue: 'Passive', value: true }
    ]

    public hours = [0, 1, 2, 4, 6, 8, 12, 24];
    public minutes = [0, 5, 10, 15, 30, 45];

    private numberRegEx = /\-?\d*\.?\d{1,2}/;
    private serverNameRegEx = `^(?!-)[a-zA-Z0-9.-]*$`;
    private serverFolderRegEx = `^[a-zA-Z0-9./\\\\?-]*$`;
    constructor(
        public fb: FormBuilder,
        private ftpService: FTPService,
        private activeRoute: ActivatedRoute,
        @Inject(MAT_DIALOG_DATA) public data: FtpSweepModel,
        public refDialog: MatDialogRef<AdsPrismFtpAddEditComponent>,
    ) {
        this.customerId = Number(this.activeRoute.snapshot.queryParamMap.get(customerEditorQueryParam));
        this.ftpForm = this.fb.group({
            protocol: [this.data ? this.data.protocol : DEFAULT_PROTOCOL_VALUE, Validators.required],
            passive: [this.data ? this.data.passive : true, Validators.required],
            server: [
                this.data ? this.data.server : '',
                [
                    Validators.required,
                    Validators.minLength(1),
                    Validators.maxLength(253),
                    Validators.pattern(this.serverNameRegEx),
                ],
            ],
            username: [
                this.data ? this.data.username : '',
                [Validators.required, Validators.minLength(1), Validators.maxLength(256), this.hasQuoteValidator],
            ],
            password: [
                this.data ? this.data.password : '',
                [Validators.required, Validators.minLength(1), Validators.maxLength(256), this.hasQuoteValidator],
            ],
            port: [
                this.data ? this.data.port : '',
                [Validators.required, Validators.pattern(this.numberRegEx), Validators.min(1), Validators.max(65535)],
            ],
            folder: [this.data ? this.data.folder : '', [Validators.pattern(this.serverFolderRegEx)]],
            hour: [this.data ? Math.floor(this.data.interval / 60) : 24],
            minute: [this.data ? Math.round((this.data.interval / 60 - Math.floor(this.data.interval / 60)) * 60) : 0],
            format: [this.data ? this.data.format : 0],
        });
    }

    ngOnInit() {
        this.data ? (this.editMode = true) : (this.editMode = false);
    }

    /**
     * Closing the Popup window
     */
    public close(servicesaved: boolean) {
        this.refDialog.close({ success: servicesaved });
    }

    public ftpSave() {
        this.saving = true;
        const server = this.ftpForm.value;
        if (this.data) {
            server.guid = this.data.guid;
        }
        server.protocol = this.protocols.find((x) => x.value === server.protocol).viewValue;
        server.interval = server.hour * 60 + server.minute;

        delete server.hour;
        delete server.minute;

        this.ftpService.updateFtpServer(this.customerId, server).subscribe(
            (res) => {
                this.close(true);
            },
            (err) => {
                this.saving = false;
            },
        );
    }

    private hasQuoteValidator(control: AbstractControl) {
        if (!control || !control.value) return null;

        if (control.value.includes("'") || control.value.includes('"')) {
            return { pattern: true };
        }

        return null;
    }
}
