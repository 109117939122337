<div class="load-setting">
    <app-loader [isLoading]="isLoading"></app-loader>
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'COMMON.LOAD_SETTING' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <mat-dialog-content>
        <!-- search -->
        <div class="search-section pad-t-12">
            <mat-form-field class="full-width">
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <input
                    matInput
                    placeholder="{{ 'COMMON.SEARCH' | translate }} "
                    [formControl]="searchString"
                />
            </mat-form-field>
        </div>
        <!-- load settings table -->
        <div class="load-setting-table">
            <mat-table #table [dataSource]="loadSettingDataSource" matSort>
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>{{
                        'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.NAME' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="setting-name">
                        <button mat-button (click)="openSetting(element)">
                            {{ element.name }}
                        </button>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef class="fixCellWidth30"></mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <button mat-icon-button (click)="deleteExportSetting(element.name)">
                            <mat-icon matTooltip="{{ 'COMMON.DELETE_BUTTON' | translate }}">delete</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="loadSettingDataSourceColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: loadSettingDataSourceColumns"></mat-row>
            </mat-table>
        </div>
        <div *ngIf="noSetting" class="notabledataFound">
            <span>{{ 'COMMON.NO_DATA_AVAILABLE' | translate }}</span>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="close()">{{ 'COMMON.CANCEL' | translate }}</button>
        </div>
    </mat-dialog-actions>
</div>
