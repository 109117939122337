<div id="customerEditorDialog" [hidden]="!localScheduleCollection">
    <app-customized-confirmation-box
        *ngIf="showConfirmation"
        [confirmationData]="conformationDataInput"
        (listenConfirmation)="listenConfirmation($event)"
    ></app-customized-confirmation-box>

    <div class="popupHeader" mat-dialog-title>
        <span *ngIf="showCreateSchedule" class="width-24px">
            <mat-icon matTooltip="{{ 'COMMON.BACK_TEXT' | translate }}" (click)="exitScheduleCollection($event)"
                >arrow_back</mat-icon
            >
        </span>
        <span>{{ scheduleCollectionHeader }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <div [hidden]="showCreateSchedule">
        <div mat-dialog-content id="scheduleCollectionEditorTable">
            <app-loader [isLoading]="collectionScheduleLoadingState"></app-loader>
            <ng-template [ngIf]="!localScheduleDataSource">
                <span class="notabledataFound">{{ 'HOME.WIDGET_SCHEDULE.NO_SCHEDULE_FOUND' | translate }}</span>
            </ng-template>
            <mat-table #table [dataSource]="localScheduleDataSource" matSort>
                <!-- Schedules Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell
                        *matCellDef="let scheduleCollection"
                        [ngClass]="{ selected: scheduleCollection.isSelected }"
                    >
                        <div
                            [ngClass]="{ customLongName: validateLocationgroupNameLength(scheduleCollection.name, 48) }"
                            [title]="
                                validateLocationgroupNameLength(scheduleCollection.name, 48)
                                    ? scheduleCollection.name
                                    : ''
                            "
                        >
                            {{ scheduleCollection.name }}
                        </div>
                    </mat-cell>
                </ng-container>

                <!-- edit Delete Schedule Collection Column -->
                <ng-container matColumnDef="editDeleteScheduleCollection">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell
                        *matCellDef="let scheduleCollection"
                        class="text-right"
                        [ngClass]="{ selected: scheduleCollection.isSelected }"
                    >
                        <mat-icon (click)="updateScheduleCollection(scheduleCollection)">mode_edit</mat-icon>
                        <mat-icon (click)="showDeleteConfirmationScheduleCollection(scheduleCollection)"
                            >delete</mat-icon
                        >
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="localScheduleDisplayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: localScheduleDisplayedColumns"></mat-row>
            </mat-table>
        </div>
        <div mat-dialog-actions>
            <div class="auto-margin" [hidden]="!(totalPaginationLength > 10)">
                <mat-paginator
                    #paginator
                    [length]="totalPaginationLength"
                    [pageIndex]="0"
                    [pageSize]="10"
                    [pageSizeOptions]="[10, 25, 100]"
                >
                </mat-paginator>
            </div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button (click)="emitScheduleCollection()">{{ 'COMMON.CLOSE' | translate }}</button>
            </div>
            <div class="auto-margin">
                <button mat-button *ngIf="!hideCreateButton" (click)="createScheduleCollection()">
                    {{ 'HOME.WIDGET_SCHEDULE.ADD_SCHEDULE_BTN' | translate }}
                </button>
            </div>
        </div>
    </div>

    <div id="scheduleCollectionEditor" *ngIf="showCreateSchedule">
        <form #scheduleCollectionForm="ngForm">
            <div mat-dialog-content>
                <app-loader [isLoading]="collectionScheduleLoadingState"></app-loader>
                <div class="custom-error">
                    <mat-form-field class="full-width top">
                        <input
                            matInput
                            matTooltip="{{ 'HOME.WIDGET_SCHEDULE.ADD_SCHEDULE_TOOLTIP' | translate }}"
                            id="addScheduleNameInput"
                            placeholder="{{ 'HOME.WIDGET_SCHEDULE.SCHEDULE_NAME_PLACEHOLDER' | translate }}"
                            maxlength="100"
                            [(ngModel)]="scheduleName"
                            name="addScheduleName"
                            #addScheduleNameInput="ngModel"
                            [pattern]="alphaNumericWithSpecialChars"
                            (ngModelChange)="validateScheduleNameField()"
                            required
                        />
                    </mat-form-field>
                    <div class="custom-error-message">
                        <span *ngIf="isDuplicateScheduleName">{{
                            'HOME.WIDGET_SCHEDULE.SCHEDULE_NAME_EXISTS' | translate
                        }}</span>
                        <span *ngIf="addScheduleNameInput?.errors?.pattern">{{
                            'HOME.WIDGET_SCHEDULE.SCHEDULE_NAME_VALIDATION' | translate
                        }}</span>
                        <span *ngIf="addScheduleNameInput?.errors?.maxlength">{{
                            'HOME.WIDGET_SCHEDULE.SCHEDULE_NAME_LENGTH_VALIDATION' | translate
                        }}</span>
                    </div>
                </div>
                <div class="full-width scheduleCollectionLocations">
                    <app-multi-select-auto-complete
                        [items]="selectableLocations"
                        [preselectedItems]="selectedLocations"
                        (currentlySelectedItems)="selectedNewLocations($event)"
                        placeholder="{{ 'COMMON.LOCATIONS' | translate }}"
                    ></app-multi-select-auto-complete>
                </div>
                <div class="daysSection">
                    <div class="daysLabel">{{ 'COMMON.DAYS' | translate }} *</div>
                    <div class="daysContainer">
                        <mat-checkbox
                            *ngFor="let day of listOfDays"
                            [ngModelOptions]="{ standalone: true }"
                            [(ngModel)]="day.isSelected"
                            (ngModelChange)="validateDateSelection(day)"
                            name="chkboxday"
                            required="true"
                        >
                            <div class="eachDay">{{ day.text }}</div>
                        </mat-checkbox>
                    </div>
                </div>
                <div class="full-width">
                    <mat-form-field>
                        <mat-select
                            [(ngModel)]="frequency"
                            placeholder="{{ 'HOME.WIDGET_SCHEDULE.FREQUENCY_PLACEHOLDER' | translate }}"
                            name="displayhourDropDown"
                            class="full-width"
                            required
                        >
                            <mat-option *ngFor="let hour of listOfHours" [value]="hour.value">{{
                                hour.text
                            }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="full-width">
                    <app-auto-complete
                        [items]="alarmFrequencyItems"
                        [preselectedItem]="collectionAlarmingFrequency"
                        placeholder="{{ 'HOME.WIDGET_SCHEDULE.EVENT_COLLECT_RATE' | translate }}"
                        [isShowAll]="false"
                        (selectedItems)="alarmingFrequencySelected($event)"
                    >
                    </app-auto-complete>
                </div>
            </div>
        </form>
        <div mat-dialog-actions>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button (click)="emitScheduleCollection()">{{ 'COMMON.CLOSE' | translate }}</button>
            </div>
            <div class="auto-margin">
                <button
                    mat-button
                    (click)="saveScheduleCollection()"
                    [disabled]="
                        isDuplicateScheduleName ||
                        scheduleCollectionForm.invalid ||
                        (scheduleCollectionForm.pristine && isFormPristine) ||
                        disableSave
                    "
                >
                    {{ 'COMMON.SAVE_BUTTON' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
