export class AddLocationWeir {
    public range: number;
    public capacity: number;
    public length: number;
    public breadth: number;
    public height: number;
    public coefficient: number;
    public installationID: number;
    public installationType = 'Weir';
    public shapeType = 'Unknown';
    public installationDesc1: string;
    public installationDesc2: string;
    public installationDesc3: string;
    public installationDesc4: string;
    public installationDesc5: string;
    public lookupDesc: string;
    public MonitoringPoint: number;
    public wasCreatedFromImport: boolean;

    constructor() {
        this.range = null;
        this.capacity = null;
        this.length = null;
        this.breadth = null;
        this.height = null;
        this.coefficient = null;
        this.installationID = null;
        this.installationType = 'Weir';
        this.shapeType = 'Unknown';
        this.installationDesc1 = '';
        this.installationDesc2 = '';
        this.installationDesc3 = '';
        this.installationDesc4 = '';
        this.installationDesc5 = '';
        this.lookupDesc = '';
        this.wasCreatedFromImport = false;
    }
}
