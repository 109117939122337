import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { UUID } from 'angular2-uuid';
import { AlarmGraphComponent } from 'app/pages/alarm-graph/alarm-graph.component';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs';
import { UsersService } from '../admin/users.service';
import { first } from 'rxjs/operators';
import { AutoScrubSummaryService } from 'app/shared/services/auto-scrub-summary.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { LocationService } from 'app/shared/services/location.service';
import { AutoScrubSummary, AutoScrubSummaryReportArgs } from 'app/shared/models/auto-scrub-summary';
import { WidgetFilterData } from 'app/shared/models/widget-filter-data';
import { GraphData } from 'app/shared/models/graph-data';
import { Customer } from 'app/shared/models/customer';
import { environment } from 'app/environments/environment';

@Component({
    selector: 'app-auto-review-widget',
    templateUrl: './auto-review-widget.component.html',
    styleUrls: ['./auto-review-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutoReviewWidgetComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public autoScrubSummaryDetails: Array<AutoScrubSummary>;
    @Input() public availablePageHint: boolean;
    @Input() public showAutoReviwSearch: boolean;
    @Input() public toggleState: boolean;
    @Input() public customerID: number;
    @Input() public locationGroupID: number;
    @Input() public customerLocationsLoaded: boolean;
    @Input() public includeInactiveLocations: boolean;
    @Input() public dateFormat: string;
    @Input() public timeFormat: string;
    @Output() public showMap: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public showMapLocationForAutoReview: EventEmitter<Array<number>> = new EventEmitter<Array<number>>();
    @Output() public displayedData: EventEmitter<Array<any>> = new EventEmitter<Array<any>>();
    @Output() private emitWidgetData = new EventEmitter();

    public uuid: UUID;
    public setFirstPage = true;

    public autoReviewWidgetLoadingState: boolean;
    private subscriptions = new Array<Subscription>();

    public autoReviewDisplayedColumns = ['locationName', 'dateTime', 'displayStatus', 'error', 'reason', 'graph'];
    public autoReviewFilterColumns = ['locationName', 'dateTime', 'displayStatus', 'error', 'reason'];
    public autoReviewDataSource: MatTableDataSource<AutoScrubSummary>;
    @ViewChild(MatPaginator) public autoReviewPaginator: MatPaginator;
    @ViewChild(MatSort) public autoReviewSort: MatSort;
    @ViewChild('filter') public autoReviewFilter: ElementRef;
    public autoReviewDataChange: BehaviorSubject<AutoScrubSummary[]> = new BehaviorSubject<AutoScrubSummary[]>([]);
    private workOrderLink: string;
    public rawDataActive: boolean;
    public get autoReviewData(): AutoScrubSummary[] {
        return this.autoReviewDataChange.value;
    }
    public totalPaginationLength: number;
    public startDate: Date;
    public endDate: Date;
    public filterSettings: Object;

    /**
     * Variable which validates API state of the first initially loaded auto review widget locations on map
     */
    public autoReviewAPIState = true;

    constructor(
        private domOperationUtilsService: DomOperationUtilsService,
        private autoScrubSummaryService: AutoScrubSummaryService,
        private customerService: CustomerService,
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog,
        private locationService: LocationService,
        private uiUtilsService: UiUtilsService,
        private usersService: UsersService,
    ) {}

    public ngOnInit() {
        this.rawDataActive = this.usersService.isRawDataEditingAllowed.getValue();
        this.filterSettings = {
            skipInitialCall: true,
            displayLocations: true,
            singleLocationSelect: true,
            displayDateRanges: true,
            displayAnomaliesReasons: true,
        };
        const customerSubscription = this.customerService
            .getCustomerById(this.customerID)
            .subscribe((res: Customer) => {
                this.workOrderLink = res.workOrderLink || environment.workOrderLink;
            });

        this.subscriptions.push(customerSubscription);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.customerLocationsLoaded) {
            this.autoReviewAPIState = true;
            this.generateGenericAutoScrubSummary();
        }

        //  if customer date or time format changes re-run the data
        if (
            (changes.dateFormat && changes.dateFormat.previousValue !== changes.dateFormat.currentValue) ||
            (changes.timeFormat && changes.timeFormat.previousValue !== changes.timeFormat.currentValue)
        ) {
            if (!this.autoReviewAPIState) {
                this.generateGenericAutoScrubSummary();
            }
        }
    }

    public getMarkerLocationDetails(locationId: number) {
        this.locationService.locationId = locationId;
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    private generateGenericAutoScrubSummary() {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - 1);
        const endDate = startDate;

        const sDate = [startDate.getMonth() + 1, startDate.getDate(), startDate.getFullYear()].join('-');
        let eDate = [endDate.getMonth() + 1, endDate.getDate(), endDate.getFullYear()].join('-');
        eDate += ' 23:59:59';

        const params = <AutoScrubSummaryReportArgs>{
            customerID: this.customerID,
            locationGroupID: this.locationGroupID || undefined,
            startDate: sDate,
            endDate: eDate,
            IncludeInactiveLocations: this.includeInactiveLocations,
        };

        this.getAutoScrubSummary(params);
    }

    /**
     * Below function would open the hydrograph and scattergraph while clicking on graph icon
     * @param locationId represents auto review widget's location id
     * @param locationName represents auto review widget's location name
     * @param timestamp represents auto review widget's date
     */
    public openHydrograph(locationId: number, locationName: string, timestamp: string, index: number) {
        // Setting sorted data in service
        this.autoScrubSummaryService.autoScrubSummaryDetailsSorted = <AutoScrubSummary[]>this.autoReviewDataSource.sortData(this.autoReviewDataSource.filteredData, this.autoReviewDataSource.sort);

        // selected location index
        const locationIndex = index + this.autoReviewPaginator.pageIndex * this.autoReviewPaginator.pageSize;

        // open graph dialog modal
        const dialogRef = this.dialog.open(AlarmGraphComponent, {
            disableClose: true,
            data: <GraphData>{
                locationId: locationId,
                locationName: locationName,
                eventDate: timestamp,
                isAutoReviewWidget: true,
                showMultiGraph: true,
                timeFormat: this.timeFormat,
                locationIndex: locationIndex,
                workOrderLink: this.workOrderLink,
            },
        });

        dialogRef
            .afterOpened()
            .pipe(first())
            .subscribe(() => {
                dialogRef.componentInstance.loadGraphData();
            });
    }

    public notifyReport(data: WidgetFilterData) {
        const sDate = [data.startDate.getMonth() + 1, data.startDate.getDate(), data.startDate.getFullYear()].join('-');
        let eDate = [data.endDate.getMonth() + 1, data.endDate.getDate(), data.endDate.getFullYear()].join('-');
        eDate += ' 23:59:59';

        const params = <AutoScrubSummaryReportArgs>{
            customerID: data.customerID,
            startDate: sDate,
            endDate: eDate,
            IncludeInactiveLocations: this.includeInactiveLocations,
        };

        if (data.locationIDs && data.locationIDs.length > 0) {
            params.locations = data.locationIDs;
        }

        if (data.anomalyReason) {
            params.reviewReasonBit = data.anomalyReason;
        }

        this.getAutoScrubSummary(params);
    }

    public generateAutoReviewWidgetTable() {
        this.autoReviewDataSource = null;
        this.autoReviewDataChange = new BehaviorSubject<AutoScrubSummary[]>([]);
        for (const autoReviewDetail of this.autoScrubSummaryDetails) {
            const autoReviewCopiedData = this.autoReviewData.slice();
            const data = {
                locationId: autoReviewDetail.locationId,
                locationName: autoReviewDetail.locationName,
                dateTime: autoReviewDetail.aDate,
                displayStatus: autoReviewDetail.status.text,
                error: autoReviewDetail.anomalyPercentage,
                reason: autoReviewDetail.status.reason.reason || '',
                anomalyPercentage: autoReviewDetail.anomalyPercentage,
                aDate: autoReviewDetail.aDate,
                totalCount: autoReviewDetail.totalCount,
                averageScore: autoReviewDetail.averageScore,
                customerName: autoReviewDetail.customerName,
                status: autoReviewDetail.status,
                anomalyCount: autoReviewDetail.anomalyCount,
            };
            autoReviewCopiedData.push(data);
            this.autoReviewDataChange.next(autoReviewCopiedData);
        }

        this.autoReviewPaginator.pageIndex = 0;
        this.autoReviewDataSource = new MatTableDataSource(this.autoReviewData);
        if (this.autoReviewData.length) {
            this.displayedData.emit(this.autoReviewData.map((x) => x.locationId));
        } else {
            this.displayedData.emit(null);
        }

        this.autoReviewDataSource.sort = this.autoReviewSort;
        this.autoReviewDataSource.paginator = this.autoReviewPaginator;
        this.totalPaginationLength = this.autoReviewData.length;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    private getAutoScrubSummary(data: AutoScrubSummaryReportArgs) {
        this.autoScrubSummaryDetails = [];
        this.autoReviewWidgetLoadingState = true;
        const subscriptionGetAutoScrubSummary = this.autoScrubSummaryService.getAutoScrubSummary(data).subscribe(
            (response) => {
                if (response) {
                    this.autoScrubSummaryDetails = <AutoScrubSummary[]>response;
                    if (this.toggleState) {
                        this.showMap.emit(true);
                    }
                }

                /**
                 * Below Logic will emit the auto review widget locations to parent component(i.e.landing page)
                 * to display the locations on map. This code will also avoid the multiple API call to fetch the auto scrub details.
                 */
                this.autoScrubSummaryService.autoScrubSummaryDetails = this.autoScrubSummaryDetails;
                this.emitWidgetData.emit(this.autoScrubSummaryDetails);
                if (this.autoReviewAPIState) {
                    this.showMapLocationForAutoReview.emit(
                        this.autoScrubSummaryDetails.map((element) => element.locationId),
                    );
                }

                this.autoReviewAPIState = false;

                this.autoReviewWidgetLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);

                this.generateAutoReviewWidgetTable();
            },
            (error) => {
                this.autoReviewWidgetLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );

        this.subscriptions.push(subscriptionGetAutoScrubSummary);
    }
    /**
     * on page Change Scroll to Top
     */
    public onPaginateChange() {
        this.domOperationUtilsService.scrollToTop('#autoReviewWidgetId');
    }

    /**
     * //checks if the name length is more than specific length
     */
    public validateLocationNameLength(str: string, strLength: number): boolean {
        if ((str + '').length > strLength) {
            return true;
        } else {
            return false;
        }
    }
}
