import { Component, OnInit, ViewEncapsulation, Input, OnChanges, SimpleChanges, QueryList, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { DayOfWeekGroupDays, DryDayData, SliicerCaseStudy, Overrides } from 'app/shared/models/sliicer';
import { UpdatesWidgetService } from '../../../../shared/components/updates-widget/updates-widget.service';
import { UpdateInfo } from '../../../../shared/components/updates-widget/updates-widget.models';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { Subscription } from 'rxjs';
import { drop } from 'lodash';
import { normalizeTickInterval } from 'highcharts';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { CustomerService } from 'app/shared/services/customer.service';
import { UnitOfMeasureType } from 'app/shared/constant';

const LDM_STRING = 'l/d/m';
const GDP_FT_STRING = 'gpd/ft';
const CFD_FT_STRING = 'cfd/ft'

export interface DryDayProperties {
    key: number;
    translateKey: string;
    propertyName: string;
}

const UPDATES_MODEL = 'drydaystats';
const DAYS_KEY = 0;
const G_ALT = 1;
const G_ALT_YEAR = 2;
const G_ALT_DAY_GROUP = 3;
const G_ALT_SEASON = 4;
const G_ALT_REGIME = 5;
const G_MIN_FLOW_KEY = 6;
const G_AVG_FLOW_KEY = 7;
const G_MAX_FLOW_KEY = 8;
const G_PEAK_KEY = 9;
const G_WW_PROD_KEY = 10;
const G_BI_KEY = 11;
const N_MIN_FLOW_KEY = 12;
const N_AVG_FLOW_KEY = 13;
const N_MAX_FLOW_KEY = 14;
const N_WW_PROD_KEY = 15;
const N_BI_KEY = 16;
const N_WW_PROD_LENGTH_KEY = 17;
const N_MAX_TRACE_DATE_KEY = 18;
const N_MAX_TRACE_STATS_KEY = 19;

const TABLE_DATA: DryDayProperties[] = [
    { key: DAYS_KEY, translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.NUMBER_OF_DAYS', propertyName: '' },
    { key: G_ALT, translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.ALTERNATE', propertyName: '' },
    { key: G_ALT_YEAR, translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.ALTERNATE_YEAR', propertyName: '' },
    { key: G_ALT_DAY_GROUP, translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.ALTERNATE_DAY_GROUP', propertyName: '' },
    { key: G_ALT_SEASON, translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.ALTERNATE_SEASON', propertyName: '' },
    { key: G_ALT_REGIME, translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.ALTERNATE_REGIME', propertyName: '' },
    { key: G_MIN_FLOW_KEY, translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.GROSS_MINIMUM_FLOW', propertyName: '' },
    { key: G_AVG_FLOW_KEY, translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.GROSS_AVERAGE_FLOW', propertyName: '' },
    { key: G_MAX_FLOW_KEY, translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.GROSS_MAXIMUM_FLOW', propertyName: '' },
    {
        key: G_PEAK_KEY,
        translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.GROSS_PEAKING_FACTOR',
        propertyName: '',
    },
    {
        key: G_WW_PROD_KEY,
        translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.GROSS_WASTEWATER_PRODUCTION',
        propertyName: '',
    },
    { key: G_BI_KEY, translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.GROSS_BASE_INFILTRATION', propertyName: '' },
    { key: N_MIN_FLOW_KEY, translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.NET_MINIMUM_FLOW', propertyName: '' },
    { key: N_AVG_FLOW_KEY, translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.NET_AVERAGE_FLOW', propertyName: '' },
    { key: N_MAX_FLOW_KEY, translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.NET_MAXIMUM_FLOW', propertyName: '' },
    {
        key: N_WW_PROD_KEY,
        translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.NET_WASTEWATER_PRODUCTION',
        propertyName: '',
    },
    { key: N_BI_KEY, translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.NET_BASE_INFILTRATION', propertyName: '' },
    {
        key: N_WW_PROD_LENGTH_KEY,
        translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.NET_WASTEWATER_PRODUCTION_PER_LENGTH',
        propertyName: '',
    },
    {
        key: N_MAX_TRACE_DATE_KEY,
        translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.TRACE_MAX_DATE',
        propertyName: '',
    },
    {
        key: N_MAX_TRACE_STATS_KEY,
        translateKey: 'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.TRACE_MAX_STATS',
        propertyName: '',
    },
];

@Component({
    selector: 'app-dry-days-stats',
    templateUrl: './dry-days-stats.component.html',
    styleUrls: ['./dry-days-stats.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class DryDaysStatsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public basinDryDays: DryDayData;
    @Input() public customerId: number;
    @Input() public isStudyLocked: boolean;
    public flowPrecision = '1.3-3';
    public hasStatsData = false;
    private subscriptions = new Array<Subscription>();
    public UpdatedBasinDryDays: DryDayData;
    public updatesList: { propName: string; value?: string; checked?: boolean, index: number }[] = [];
    public successUpdate: string;
    public failureUpdate: string;

    public readonly operators = ['=', '>', '<', '>=', '<='];
    public columnNames: string[] = [];
    public dayGroups: string[] = [];
    public displayColumnKeys: string[] = [];
    public valueKeys: string[] = [];
    public dryDayOverrides?: Overrides;

    public tableData = [];


    public tableDataCopy : {}[] = [];
    public defaultSelectedDayGroup: string = "";
    public defaultSelectedYear: string = "";
    public defaultSelectedSeason: string = "";
    public defaultSelectedRegime: string = "";

    public yearsAvailable = false;
    public yearsDisplayColumnKeys: string[] = [];
    public yearsUniqueKeys: string[] = [];

    public seasonsAvailable = false;
    public seasonsDisplayColumnKeys: string[] = [];
    public seasonsUniqueKeys: string[] = [];

    public regimesAvailable = false;
    public regimesDisplayColumnKeys: string[] = [];
    public regimesUniqueKeys: string[] = [];

    public is12HourFormat = false;
    public dateFormat: string;
    public customDateFormat: string;

    // #29885 - whenever all week groups have any dry days
    public allHaveDryDays = true;

    public G_ALT = G_ALT;
    public G_ALT_YEAR = G_ALT_YEAR;
    public G_ALT_DAY_GROUP = G_ALT_DAY_GROUP;
    public G_ALT_SEASON = G_ALT_SEASON;
    public G_ALT_REGIME = G_ALT_REGIME;

    constructor(
        private dateutilService: DateutilService,
        private translateService: TranslateService,
        private sliicerService: SliicerService,
        public updatesWidgetService: UpdatesWidgetService,
        private customerService: CustomerService
    ) {}

    public ngOnInit() {
        this.updatesList = [];
        this.flowPrecision = this.dateutilService.isCustomerUnitsMetric.getValue() ? '1.1-1' : '1.3-3';
        const flowUnit = this.dateutilService.customerUnit.getValue();
        let wasteWaterUnit: string;

        switch (this.customerService.customer.unitsType) {
            case UnitOfMeasureType.METRIC: { wasteWaterUnit = LDM_STRING; break; }
            case UnitOfMeasureType.CFS: { wasteWaterUnit = CFD_FT_STRING; break; }
            case UnitOfMeasureType.MGD: { wasteWaterUnit = GDP_FT_STRING; break; }
        }

        this.applyTranslation();

        const translateKeys = TABLE_DATA.map((d) => d.translateKey);
        const translateSubs = this.translateService.get(translateKeys).subscribe((values) => {
            for (let i = 0; i < TABLE_DATA.length; i++) {
                TABLE_DATA[i].propertyName = values[TABLE_DATA[i].translateKey];
                if (i === N_WW_PROD_LENGTH_KEY) {
                    TABLE_DATA[i].propertyName += ' (' + wasteWaterUnit + ')';
                } else if (i !== DAYS_KEY && i !== N_MAX_TRACE_DATE_KEY && i !== G_PEAK_KEY && i !== G_ALT && i !== G_ALT_YEAR && i !== G_ALT_DAY_GROUP && i !== G_ALT_SEASON && i !== G_ALT_REGIME)  {
                    TABLE_DATA[i].propertyName += ' (' + flowUnit + ')';
                }
            }
        });
        this.subscriptions.push(translateSubs)

        this.dateFormat = `${String(this.dateutilService.getFormat()).toUpperCase()}`;
        this.is12HourFormat = this.dateutilService.timeFormat.getValue() !== 'hh:mm:ss';
        this.customDateFormat = this.is12HourFormat
            ? `${'hh:mm:ss A'}`
            : `${'HH:mm:ss A'}`;

        this.updateDryDayStatsData();

        this.subscriptions.push(
            this.updatesWidgetService.updatesAction.subscribe((action) => {
                if (this.updatesWidgetService.updatesModel === UPDATES_MODEL) {
                    switch (action) {
                        default:
                            break;
                        case 'undoChanges':
                            this.undoChanges();
                            break;
                        case 'applyChanges':
                            this.updateDryDayData();
                            break;
                    }
                }
            }),
        );
    }

    public applyTranslation() {
        const translateKeys: Array<string> = [
            'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.UPDATE_DRY_DAY_STATS_SUCCESS',
            'SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.UPDATE_DRY_DAY_STATS_FAILURE',
        ];
        this.translateService.get(translateKeys).subscribe((translateValues) => {
            this.successUpdate = translateValues['SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.UPDATE_DRY_DAY_STATS_SUCCESS'];
            this.failureUpdate = translateValues['SLIICER.FLOW_TAB.DRY_DAYS.STATISTICS.UPDATE_DRY_DAY_STATS_FAILURE'];
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.basinDryDays && !changes.basinDryDays.firstChange) {
            this.updateDryDayStatsData();
        }
    }

    public undoChanges() {
        this.updatesList = [];
        this.updateDryDayStatsData();
        const updatesInfo: UpdateInfo[] = [];
        this.updatesWidgetService.setUpdatesInfo(updatesInfo, UPDATES_MODEL);
    }

    private dayOfWeekGroupKey(group: DayOfWeekGroupDays): string {
        let key = group.dayOfWeekGroupName;
        if (this.yearsAvailable) {
            key += '-' + group.year;
        }
        if (this.seasonsAvailable) {
            key += '-' + group.season;
        }
        if (this.regimesAvailable) {
            key += '-' + group.regime;
        }
        return key;
    }

    private updateDryDayData(){
        if (!this.updatesList.length || !this.UpdatedBasinDryDays) {
            return;
        }

        this.updatesList.forEach((updateItem) => {
            const { propName, index, value, checked } = updateItem;
            const currentGroup = this.UpdatedBasinDryDays.dayOfWeekGroupDays[index];

            if (propName === 'Alternate Day of Week Group') {
                currentGroup.altDayType = value;
            }
            if (propName === 'Alternate Regime') {
                currentGroup.altRegime = value;
            }
            if (propName === 'Alternate Season') {
                currentGroup.altSeason = value;
            }
            if (propName === 'Alternate Year') {
                currentGroup.altYear = value;
            }
            if (propName === 'Alternate') {
                currentGroup.useAlt = checked;
            }
        });

        this.updatesList = [];

        this.sliicerService.updateDryDayBasin(this.customerId, this.UpdatedBasinDryDays).subscribe(
            (result) =>
            {
                this.sliicerService.showToast(this.successUpdate);
                const updatesInfo: UpdateInfo[] = [];
                this.updatesWidgetService.setUpdatesInfo(updatesInfo, UPDATES_MODEL);
                //this.basinDryDays = this.UpdatedBasinDryDays;
            },
            () => {
                this.sliicerService.showToast(this.failureUpdate,true);
                const updatesInfo: UpdateInfo[] = [];
                this.updatesWidgetService.setUpdatesInfo(updatesInfo, UPDATES_MODEL);
            },
        );
    }


    private updateDryDayStatsData() {
        this.tableData = [];
        this.tableDataCopy = [];
        this.yearsAvailable = this.sliicerService.hasYears();
        this.seasonsAvailable = this.sliicerService.hasSeasons();
        this.regimesAvailable = this.sliicerService.hasRegimes();
        this.hasStatsData = this.basinDryDays && this.basinDryDays.dayOfWeekGroupDays.length > 0;

        this.valueKeys = [];
        this.displayColumnKeys = ['propertyName'];
        this.columnNames = [];
        this.dayGroups = [];


        if (!this.hasStatsData) {
            return;
        }

        this.yearsDisplayColumnKeys = ['year'];
        this.yearsUniqueKeys = [];

        this.seasonsDisplayColumnKeys = ['season'];
        this.seasonsUniqueKeys = [];

        this.regimesDisplayColumnKeys = ['regime'];
        this.regimesUniqueKeys = [];

        // need to have a hard copy of table data, because we mutate it inside this function
        const tableData = [...TABLE_DATA];

        // build out the value columns
        this.basinDryDays.dayOfWeekGroupDays.forEach((dowg) => {
            const keyName = this.dayOfWeekGroupKey(dowg);
            this.valueKeys.push(keyName);
            this.displayColumnKeys.push(keyName);
            this.columnNames.push(dowg.dayOfWeekGroupName);

            this.defaultSelectedDayGroup =  this.defaultSelectedDayGroup.length == 0? dowg.dayOfWeekGroupName: this.defaultSelectedDayGroup;

            if (this.yearsAvailable && dowg.year) {
                this.yearsDisplayColumnKeys.push('Y'+dowg.year);
                if (!this.yearsUniqueKeys.includes(dowg.year)) {
                    this.defaultSelectedYear =  this.defaultSelectedYear.length == 0? dowg.year: this.defaultSelectedYear;
                    this.yearsUniqueKeys.push(dowg.year);
                }
            }
            if (this.seasonsAvailable && dowg.season) {
                this.seasonsDisplayColumnKeys.push('S'+dowg.season);
                if (!this.seasonsUniqueKeys.includes(dowg.season)) {
                    this.defaultSelectedSeason =  this.defaultSelectedSeason.length == 0? dowg.season: this.defaultSelectedSeason;
                    this.seasonsUniqueKeys.push(dowg.season);
                }
            }
            if (this.regimesAvailable && dowg.regime) {
                this.regimesDisplayColumnKeys.push('R'+dowg.regime);
                if (!this.regimesUniqueKeys.includes(dowg.regime)) {
                    this.defaultSelectedRegime =  this.defaultSelectedRegime.length == 0? dowg.regime: this.defaultSelectedRegime;
                    this.regimesUniqueKeys.push(dowg.regime);
                }
            }
        });
        this.valueKeys = this.valueKeys.filter((v, i) => i === this.valueKeys.indexOf(v));      // remove duplicates
        if (this.yearsDisplayColumnKeys.length === 1) {
            this.yearsAvailable = false;
        }
        if (this.seasonsDisplayColumnKeys.length === 1) {
            this.seasonsAvailable = false;
        }
        if (this.regimesDisplayColumnKeys.length === 1) {
            this.regimesAvailable = false;
        }

        this.dayGroups = this.columnNames.filter((value, index) => this.columnNames.indexOf(value) === index);

        if (!this.yearsAvailable) {
            const altYearIndex = tableData.findIndex((x) => x.key === G_ALT_YEAR);

            if (altYearIndex > 0) {
                tableData.splice(altYearIndex, 1);
            }
        }
        if (!this.seasonsAvailable) {
            const altSeasonIndex = tableData.findIndex((x) => x.key === G_ALT_SEASON);

            if (altSeasonIndex > 0) {
                tableData.splice(altSeasonIndex, 1);
            }
        }
        if (!this.regimesAvailable) {
            const altRegimeIndex = tableData.findIndex((x) => x.key === G_ALT_REGIME);

            if (altRegimeIndex > 0) {
                tableData.splice(altRegimeIndex, 1);
            }
        }

        this.allHaveDryDays = true;
        // build the table, row by row
        tableData.forEach((d) => {
            const rowData = {
                propertyName: d.propertyName,
                values: [],
                font: false,
                checkboxvalues: [],
                precision: [],
                checkboxes: false,
                dropdowns: false,
                yeardropdowns: false,
                daygroupdropdowns: false,
                seasondropdowns: false,
                regimedropdowns: false,
                key: d.key
            };
            let checkboxes = false, yeardropdowns = false, daygroupdropdowns = false, seasondropdowns = false, regimedropdowns = false;

            switch(d.key)
            {
                case G_ALT_DAY_GROUP:
                    daygroupdropdowns = true;
                    break;
                case G_ALT_YEAR:
                    yeardropdowns = true;
                    break;
                case G_ALT_REGIME:
                    regimedropdowns = true;
                    break;
                case G_ALT_SEASON:
                    seasondropdowns = true;
                    break;
                case G_ALT:
                    checkboxes = true;
                    break;
            }

            // add the values for the row
            this.basinDryDays.dayOfWeekGroupDays.forEach((dowg) => {
                let value = undefined;
                let font = false;
                let checkboxvalue = undefined;
                let precision = this.flowPrecision;
                switch (d.key) {
                    case DAYS_KEY:
                        precision = '1.0-0';
                        value = dowg.days.length;
                        this.allHaveDryDays = this.allHaveDryDays && value !== 0;
                        break;
                    case G_ALT:
                        if(dowg.days.length > 0)
                            value = false;
                        else
                            value = true;
                        precision = undefined;
                        checkboxvalue = dowg.useAlt;
                        break;
                    case G_ALT_DAY_GROUP:
                        value = dowg.altDayType;
                        if(dowg.days.length > 0)
                            checkboxvalue = false;
                        else
                            checkboxvalue = true;
                        precision = undefined;
                        break;
                    case G_ALT_YEAR:
                        value = dowg.altYear;
                        if(dowg.days.length > 0)
                            checkboxvalue = false;
                        else
                            checkboxvalue = true;
                        precision = undefined;
                        break;
                    case G_ALT_SEASON:
                        value = dowg.altSeason;
                        if(dowg.days.length > 0)
                            checkboxvalue = false;
                        else
                            checkboxvalue = true;
                        precision = undefined;
                        break;
                    case G_ALT_REGIME:
                        value = dowg.altRegime;
                        if(dowg.days.length > 0)
                            checkboxvalue = false;
                        else
                            checkboxvalue = true;
                        precision = undefined;
                        break;
                    case G_MIN_FLOW_KEY:
                        value = dowg.stats.grossDiurnalMin;
                        break;
                    case G_MAX_FLOW_KEY:
                        value = dowg.stats.grossDiurnalPeak;
                        break;
                    case G_AVG_FLOW_KEY:
                        value = dowg.stats.grossDiurnalAverage;
                        break;
                    case G_BI_KEY:
                        value = dowg.stats.grossBaseInfiltration;
                        break;
                    case G_WW_PROD_KEY:
                        value = dowg.stats.grossWastewater;
                        break;
                    case G_PEAK_KEY:
                        value = dowg.stats.grossPeakingFactor;
                        precision = '1.2-2';
                        break
                    case N_MIN_FLOW_KEY:
                        value = dowg.stats.netDiurnalMin;
                        break;
                    case N_MAX_FLOW_KEY:
                        value = dowg.stats.netDiurnalPeak;
                        break;
                    case N_AVG_FLOW_KEY:
                        value = dowg.stats.netDiurnalAverage;
                        break;
                    case N_BI_KEY:
                        value = dowg.stats.netBaseInfiltration;
                        break;
                    case N_WW_PROD_KEY:
                        value = dowg.stats.netWastewater;
                        break;
                    case N_WW_PROD_LENGTH_KEY:
                        if (this.validNumber(dowg.stats.wastewaterPerLength)) {
                            value = dowg.stats.wastewaterPerLength;

                            if (this.customerService.customer.unitsType === UnitOfMeasureType.CFS) {
                                precision = '1.2-2';
                            }
                        } else {
                            precision = undefined;
                            value = '-';
                        }
                        break;
                    case N_MAX_TRACE_DATE_KEY:
                        font = true;
                        if (dowg.stats.tracePeakTime) {
                            value = moment(dowg.stats.tracePeakTime).format(this.dateFormat)+ ' ' + moment(dowg.stats.tracePeakTime).format(this.customDateFormat);
                        } else {
                            value = '-';
                        }
                        precision = undefined;
                        break;
                    case N_MAX_TRACE_STATS_KEY:
                        value =  dowg.stats.tracePeak;
                        break;
                    default:
                        value = '-';
                        precision = undefined;
                        break;
                }
                rowData.values.push(value);
                rowData.precision.push(precision);
                rowData.font = font;
                rowData.checkboxvalues.push(checkboxvalue);
                rowData.checkboxes = checkboxes;
                rowData.yeardropdowns = yeardropdowns;
                rowData.daygroupdropdowns = daygroupdropdowns;
                rowData.seasondropdowns = seasondropdowns;
                rowData.regimedropdowns = regimedropdowns;
            });

            this.tableData.push(rowData);
        });

        this.tableDataCopy = this.tableData;
    }

    public somethingChanged(updatedValue: MatSelectChange | MatCheckboxChange, object: { propertyName: string }, index: number) {
        this.UpdatedBasinDryDays = this.basinDryDays;

        const updatesInfo: UpdateInfo[] = [];
        if (this.getChangeValue()) {
            DryDaysStatsComponent.AddUpdateInfo(updatesInfo, this.getInfo());
        }

        const updateObject = {
            propName: object.propertyName,
            value: (updatedValue as MatSelectChange).value,
            checked: (updatedValue as MatCheckboxChange).checked,
            index
        };
        this.updatesList.push(updateObject);

        this.updatesWidgetService.setUpdatesInfo(updatesInfo, UPDATES_MODEL);
    }

    private getInfo(): UpdateInfo {
        return { title: 'Settings', values: [] };
    }

    public getChangeValue(): boolean {
        return true;
    }

    private static AddUpdateInfo(updatesInfo: UpdateInfo[], updateInfo: UpdateInfo | UpdateInfo[]) {
        if (updateInfo instanceof Array) {
            for (let i = 0; i < updateInfo.length; i++) {
                updatesInfo.push(updateInfo[i]);
            }
        } else {
            updatesInfo.push(updateInfo);
        }
    }

    public validNumber(value: number): boolean {
        return !(isNaN(value) || value < 0);
    }

    ngOnDestroy(): void {
        this.tableData = [];
        this.subscriptions.forEach(v => v.unsubscribe());
    }
}
