<div class="dialog-container">
    <div class="popupHeader" mat-dialog-title>
        <span> {{ 'COMMON.CONFIGURATIONS' | translate }}</span>
    </div>
    <div mat-dialog-content class="full-width">
        <table mat-table [dataSource]="paginatedConfigs$ | async">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.NAME' | translate }}</th>
                <td mat-cell *matCellDef="let row" class="w-150" [matTooltip]="row.name">
                    {{ (row.name && row.name.length > 100) ? (row.name.slice(0, 100) + '...') : row?.name }}
                </td>
            </ng-container>

            <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.CREATED_BY' | translate }}</th>
                <td mat-cell *matCellDef="let row" class="w-150">{{ row?.userCreated }}</td>
            </ng-container>

            <ng-container matColumnDef="editedBy">
                <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.EDITED_BY' | translate }}</th>
                <td mat-cell *matCellDef="let row" class="w-150">{{ row?.userLastEdited }}</td>
            </ng-container>

            <ng-container matColumnDef="lastEdited">
                <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.LAST_EDITED' | translate }}</th>
                <td mat-cell *matCellDef="let row" class="nowrap w-150">{{ row?.lastEdited | date : customDateFormat }}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element; let j = index" class="text-right w-100">
                    <button mat-icon-button [satPopoverAnchor]="popover" (click)="popover?.open()">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <sat-popover #popover hasBackdrop (closed)="editConfig(element, $event)">
                        <inline-edit
                            [value]="element?.name"
                            maxlength="255"
                            title="{{ 'COMMON.CHANGE_NAME' | translate }}"
                            [validateFn]="editConfigValidateFn"
                            [errorText]="editConfigErrorText"
                        ></inline-edit>
                    </sat-popover>
                    <button mat-icon-button (click)="deleteConfig(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr
                mat-row
                class="pointer"
                *matRowDef="let row; let i = index; columns: displayedColumns"
                (click)="selectedConfig = row"
                [ngClass]="{ hover: selectedConfig && selectedConfig.id === row.id }"
            >
            </tr>
        </table>
        <mat-paginator *ngIf="(configs$ | async)?.length > 0" #paginator (page)="pageEvent($event)" [pageSize]="10" [pageSizeOptions]="[10, 25, 100]" [length]="(configs$ | async)?.length"></mat-paginator>
        <div class="no-rows" *ngIf="(configs$ | async)?.length === 0">
            <span>{{ 'COMMON.NO_CONFIGURATIONS' | translate }}</span>
        </div>
    </div>
    <div mat-dialog-actions class="actions">
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button type="submit" (click)="cancel()">{{ 'COMMON.CANCEL' | translate }}</button>
        </div>
        <div class="auto-margin">
            <button mat-button type="submit" [disabled]="!selectedConfig || (configs$ | async)?.length === 0" (click)="load()">{{ 'COMMON.LOAD' | translate }}</button>
        </div>
    </div>
</div>
