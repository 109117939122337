<div class="contentLayout" id="data-edit-audit-report">
    <mat-card>
        <app-loader [isLoading]="isLoading"></app-loader>
        <mat-card-header>
            <div class="auto-margin">
                <b class="data-audit-title-hint">{{ 'COMMON.DATA_EDITING_AUDIT_REPORT' | translate | uppercase }}</b>
            </div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin custom-flat-button">
                <button mat-button class="data-audit-export-hint" (click)="exportReport()" [disabled]="!enableExport">
                    {{ 'COMMON.EXPORT_BTN' | translate }}
                </button>
            </div>
            <div class="auto-margin pad-r-15">
                <mat-icon
                    matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                    (click)="toggleFilters()"
                    class="backIcon data-audit-filter-hint"
                >
                    filter_list</mat-icon
                >
            </div>
        </mat-card-header>
        <div *ngIf="showFilters" class="data-edit-audit-report-filter">
            <div class="filter-operation">
                <div class="each-filter data-audit-date-hint">
                    <app-date-range-picker #adsDatePicker></app-date-range-picker>
                </div>
                <div class="each-filter data-audit-entity-hint">
                    <app-multi-select-group
                        #entitiesGroupMultiselect
                        [isShowAll]="false"
                        [items]="filterEntities"
                        (currentlySelectedItems)="selectedEntities($event)"
                        placeholder="{{ 'COMMON.ENTITY' | translate }}"
                    >
                    </app-multi-select-group>
                </div>
                <div class="each-filter location-selected data-audit-location-hint">
                    <app-multi-select-auto-complete
                        [items]="filterLocations"
                        #locationSelector
                        [isShowAll]="false"
                        (currentlySelectedItems)="selectedLocations($event)"
                        placeholder=" {{ 'COMMON.LOCATION_NAME' | translate }}"
                        [maxDisplayLimit]="filterLocations?.length"
                    >
                    </app-multi-select-auto-complete>
                </div>
                <div class="each-filter edited-on-date-section data-audit-edited-on-hint">
                    <app-date-range-picker [label]="'COMMON.EDITED_ON'" #adsEditedDatePicker>
                    </app-date-range-picker>
                </div>
                <div class="each-filter data-audit-edited-by-hint" *ngIf="!noneUser">
                    <mat-form-field class="full-width">
                        <mat-select
                            #userSelection
                            placeholder="{{ 'COMMON.EDITED_BY' | translate }}"
                            [(ngModel)]="reportFilters.user"
                        >
                            <mat-option *ngFor="let each of filterUsers" [value]="each.name" name="users">
                                {{ each.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-filter data-audit-comment-hint">
                    <mat-form-field class="full-width">
                        <mat-select
                            placeholder="{{ 'LOCATION_DASHBOARD.DATA_EDITING.COMMENT_TEXT' | translate }}"
                            [(ngModel)]="reportFilters.reason"
                            name="reasons"
                        >
                            <mat-option *ngFor="let each of filterEditReasons" [value]="each.name">
                                {{ each.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="filter-action">
                    <div class="app-flex-filler"></div>
                    <div class="auto-margin data-audit-actions-hint">
                        <button mat-button (click)="resetFilters()">{{ 'COMMON.RESET_BTN' | translate }}</button>
                        <button mat-button (click)="applyFilters()">{{ 'COMMON.APPLY_BTN' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
        <mat-card-content>
            <div *ngIf="reportRowCount > 0">
                <mat-table [dataSource]="reportDataSource" matSort (matSortChange)="sortData($event)">
                    <ng-container matColumnDef="location">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'COMMON.LOCATION_NAME' | translate }}
                        </mat-header-cell>
                        <mat-cell
                            *matCellDef="let column; let i = index"
                            >{{ column.location }}</mat-cell
                        >
                    </ng-container>
                    <ng-container matColumnDef="timestamp">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'COMMON.TIMESTAMP' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let column">{{ column.timestamp }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="entity">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'COMMON.ENTITIES_TEXT' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let column; let i = index" [ngClass]="{ 'data-audit-item-hint': i === 0 }">{{ column.entity | uppercase }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="user">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'COMMON.EDITED_BY' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let column">{{ column.user }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="editDate">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'COMMON.EDITED_ON' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let column">{{ column.editDate }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="reason">
                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                            {{ 'LOCATION_DASHBOARD.DATA_EDITING.COMMENT_TEXT' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let column">{{ column.reason }}</mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
                </mat-table>
                <mat-paginator
                    *ngIf="reportRowCount > 50"
                    [length]="reportRowCount"
                    [pageSize]="pageSize"
                    (page)="pageChange($event)"
                    [pageSizeOptions]="[50, 100, 500, 1000]"
                >
                </mat-paginator>
            </div>
            <div *ngIf="!reportRowCount || reportRowCount === 0" class="notabledataFound custom-table">
                <span>{{ 'REPORT.NO_DATA_MESSAGE' | translate }}</span>
            </div>
        </mat-card-content>
    </mat-card>
</div>
