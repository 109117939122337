import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { Config } from 'app/shared/services/config';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { HttpClient } from '@angular/common/http';
import { AlarmsTotal, GetAlarmsArgs } from '../models/alarms';
import { ActiveAlarm, AlarmLiteResponseItem } from '../models/active-alarm';
import { HydrographArgs } from '../models/hydrograph';
import { OptimizedViewData } from '../models/view-data';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AlarmService {
    public alarmCount: number;
    public currentActiveAlarms = new BehaviorSubject<Array<AlarmLiteResponseItem>>(new Array<AlarmLiteResponseItem>());

    constructor(public http: HttpClient, private dateutilService: DateutilService) {}

    // get the tile count for auto-detect.
    public getAlarmCount(customerId: number, locationGroupId?: number) {
        if (locationGroupId !== undefined && locationGroupId !== null) {
            return this.http
                .get<AlarmsTotal>(
                    Config.serviceUrl +
                        Config.urls.totalActiveAlarms +
                        `${customerId}&LocationGroupID=${locationGroupId}`,
                )
                .pipe(
                    map((res: AlarmsTotal) => {
                        this.alarmCount = res.alarmstotal;
                        return res;
                    }),
                );
        } else {
            return this.http.get<AlarmsTotal>(Config.serviceUrl + Config.urls.totalActiveAlarms + `${customerId}`).pipe(
                map((res: AlarmsTotal) => {
                    this.alarmCount = res.alarmstotal;
                    return res;
                }),
            );
        }
    }

    /**
     * Method returns alarms type for a particular customer
     */
    public getAlarmsTypes() {
        return this.http.get<any[]>(Config.serviceUrl + Config.urls.alarmsType);
    }

    public getAlarms(params: GetAlarmsArgs): Observable<ActiveAlarm[]> {
        if (params.EndTime && !params.EndTime.includes('23:59:59')) {
            params.EndTime = [params.EndTime, '23:59:59'].join(' ');
        }

        const url = `${Config.urls.alarms}?customerId=${params.customerId}`;

        return this.http.post<ActiveAlarm[]>(url, params).pipe(
            map((res) => {
                if (res) {
                    return res;
                } else {
                    return null;
                }
            }),
        );
    }

    public getAlarmsLite(params: GetAlarmsArgs): Observable<AlarmLiteResponseItem[]> {
        if (params.EndTime && !params.EndTime.includes('23:59:59')) {
            params.EndTime = [params.EndTime, '23:59:59'].join(' ');
        }

        const url = `${Config.urls.alarmsLite}?customerId=${params.customerId}`;

        return this.http.post<AlarmLiteResponseItem[]>(url, params).pipe(
            map((res) => {
                if (res) {
                    return res;
                } else {
                    return null;
                }
            }),
        );
    }

    public getHydrograph(customerId: number, locationId: number, params: HydrographArgs) {
        params.start = this.dateutilService.getStartDateAsTimeZone(params.start);
        params.end = this.dateutilService.getEndDateAsTimeZone(new Date(new Date(params.end).setHours(23, 59, 59)));
        return this.http
            .post<OptimizedViewData>(
                Config.serviceUrl + Config.urls.hydrographData + `${customerId}&locationId=${locationId}`,
                params,
            )
            .pipe(
                map((res) => {
                    return res;
                }),
            );
    }

    public updateAlarmStatus(state: number, alarmID: number) {
        if (state === 0) {
            const res = this.http.put<any>(Config.serviceUrl + Config.urls.acknowledgeAlarms + `${alarmID}`, {});

            catchError((error) => {
                console.log(error.json());
                return res;
            });
            return res;
        } else if (state === 1) {
            const res = this.http.put(Config.serviceUrl + Config.urls.clearedAlarms + `${alarmID}`, {});

            catchError((error) => {
                console.log(error.json());
                return res;
            });
            return res;
        }
    }

    /**
     * Methods updates the alarm as having been processed for notification
     * @param alarmID - alarm ID
     */
    public updateAlarmNotificationStatus(alarmID: number) {
        const res = this.http.put(Config.urls.alarmsNotify + `?alarmID=${alarmID}`, {});

        catchError((error) => {
            console.log(error.json());
            return res;
        });
        return res;
    }
} // End of class
