import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewEncapsulation,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { of, Subscription } from 'rxjs';
import { StatusCodeService } from '../../../../shared/services/status-code.service';
import { ScatterGraphEditMenuComponent } from '../scattergraph-edit-menu/scattergraph-edit-menu.component';
import { AdvanceScattergraphComponent } from '../advance-scattergraph/advance-scattergraph.component';
import { Observable } from 'rxjs';
import { ManualScale } from 'app/shared/models/hydrograph';
import moment from 'moment';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { GraphTypes } from 'app/shared/enums/graph-types';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'app/pages/admin/users.service';
import { distinctUntilChanged, filter, map, mergeMap } from 'rxjs/operators';
import { ViewDataService } from 'app/shared/services/view-data.service';
import { DataEditService } from 'app/shared/services/data-edit.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { AnnotationSettings, GenerationOptions } from 'app/shared/models/view-data';
import { LocationEntitiesData } from 'app/shared/models/locations-entities-data';
import { EntityData, ScatterCurve, ScatterData } from 'app/shared/models/scatter-data';
import { LocationDashboardFilterData } from 'app/shared/models/location-dashboard-filter-data';
import { Customer, customerQueryParam, reloadCacheQueryParam } from 'app/shared/models/customer';
import { LocationDetails } from 'app/shared/models/location-details';
import { GISService } from 'app/shared/services/gis-service';
import { DisplayGroupScales } from 'app/shared/models/user-settings';
import { DEPTH_ENTITY, DEPTH, VELOCITY, DEPTH_DISPLAY_GROUP, VELOCITY_DISPLAY_GROUP, VELOCITY_ENTITY, UnitOfMeasureType, RAW_VELOCITY_ENTITY } from 'app/shared/constant';
import { DataEditingCurveTypesShortcuts, DataEditingCurveTypesUIIndexes, DataEditLog, DataEditOriginalValue, DataEditPreview, DataEditStoreActionTypes, SnapDataResponse, StoredEdit, UpdatePointForSG } from 'app/shared/models/data-edit';
import { COLEBROOK_CURVE, LANFEAR_COLL_CURVE, MANNING_DESIGN_CURVE, MANUAL_LINEAR_CURVE, MANUAL_SMOOTH_CURVE, ScattergraphSeriesNames } from '../advance-scattergraph/scatter-graph-constants';
import { deepCloneMap, formatScattergraphPreviewResponse } from '../advance-scattergraph/scatter-graph-utils';
import { ConfirmationEntitiesEnum } from 'app/shared/models/view-data-filter';
import _ from 'lodash';

const ACTIVE_ZOOM = 'activeZoomFlag';

const BEST_FIT = 'Bestfit';
const STEVENS_CURVE = 'Stevens-Schutzbach';
const MANUAL_LINEAR = 'Manual - Linear';
const MANUAL_SMOOTH = 'Manual - Smooth';

@Component({
    selector: 'app-advance-scattergraph-container',
    templateUrl: './advance-scattergraph-container.component.html',
    styleUrls: ['./advance-scattergraph-container.component.scss'],
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdvanceScattergraphContainerComponent implements OnChanges, OnInit, OnDestroy {
    @ViewChild(AdvanceScattergraphComponent, { static: true }) public graph: AdvanceScattergraphComponent;
    @ViewChild(ScatterGraphEditMenuComponent, { static: true }) public editMenu: ScatterGraphEditMenuComponent;

    @Input() public isDataEditingModeEnabled = false;
    @Input() public showCustomRangesForScattergraph: boolean;
    @Input() public data: ScatterData;
    @Input() public isLoading: boolean;
    @Input() public locationDetails: LocationDetails;
    @Input() public hideMenu = false;
    @Input() public editedPoints: UpdatePointForSG[] = [];
    @Input() public dateFormat: string;
    @Input() public flaggedData: ScatterData;
    @Input() public zoomFromHydro: number[];
    @Input() public validPipeTable: boolean;

    @Input() public scatterGraphConfirmationData;
    @Input() public enableAccept;
    @Input() public showCurveEnabled: boolean;
    @Input() public entityData: LocationEntitiesData;
    @Input() public isPrinting: boolean;
    @Output() public enablePreviewChange = new EventEmitter<boolean>();
    @Output() private updateAnnotations = new EventEmitter<AnnotationSettings>();
    @Output() public dataSelectForSnap = new EventEmitter();
    @Output() public scatterResponse = new EventEmitter();
    @Output() public curveChanged = new EventEmitter<string[]>();
    // Represents Date Format For Scatter Graph
    @Input() public scatterDateFormat: string;
    @Input() public selectedEntityIds: number[];

    // Represents Tooltip Date Format
    @Input() public tooltipTimeFormat: string;
    @Input() public annotationSettings: AnnotationSettings;

    @Input() public effectiveRoughness: number;
    @Input() public slope: number;
    @Input() public selectedConfirmationEntity: ConfirmationEntitiesEnum;

    public isDataSelected = false;

    public customerId: number;
    private subscriptions = new Array<Subscription>();
    public customRanges: ManualScale[] = [];


    public RANGE_TEXT;
    public PAGE_TEXT;


    // filter values from dashboard filters
    @Input() public filterValues: LocationDashboardFilterData;

    public snappingPosition: number;
    @Input() public isSnapToCurveButton = true;
    @Input() public locationId: number;

    // #16939 force reload from cache
    private reloadCache = true;

    /** Indexes has to match DataEditingCurveTypesUIIndexes */
    public curves: ScatterCurve[] = [
        {
            name: 'None',
            shortName: 'None',
            displayName: 'None',
        },
        {
            name: 'Best Fit',
            shortName: 'BF',
            displayName: 'BF - Best Fit',
        },
        {
            name: 'Colebrook-White: Regression',
            shortName: 'CWR',
            displayName: 'CWR - Colebrook-White: Regression',
        },
        {
            name: LANFEAR_COLL_CURVE,
            shortName: DataEditingCurveTypesShortcuts.LanfearColl,
            displayName: `${DataEditingCurveTypesShortcuts.LanfearColl} - Manning: Lanfear-Coll`,
        },
        {
            name: MANNING_DESIGN_CURVE,
            shortName: DataEditingCurveTypesShortcuts.ManningDesign,
            displayName: `${DataEditingCurveTypesShortcuts.ManningDesign} - Manning Design`,
        },
        {
            name: 'Stevens-Schutzbach',
            shortName: 'SS',
            displayName: 'SS - Stevens-Schutzbach',
        },
        {
            name: 'Manual Linear',
            shortName: 'MAL',
            displayName: 'MAL - Manual - Linear',
        },
        {
            name: 'Manual Smooth',
            shortName: 'MAS',
            displayName: 'MAS - Manual - Smooth',
        },
    ];
    public selectedCurve = this.curves[0];
    public generationOption = GenerationOptions.CURRENT;
    private dismissBtnText: string;
    private noApprovedDataText: string;
    public syncZoomWithHg = false;
    public savedCurves: string[] = [];
    public savedCurveData;
    public isMetric: boolean;
    avoidDataRecall: boolean;
    constructor(
        private viewDataService: ViewDataService,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private statusCodeService: StatusCodeService,
        private activatedRoute: ActivatedRoute,
        private dataEditService: DataEditService,
        private snackBar: MatSnackBar,
        private dateutilService: DateutilService,
        private translate: TranslateService,
        private usersService: UsersService,
        private gisService: GISService
    ) {
        this.translate.get('LOCATION_DASHBOARD.DASHBOARD_FILTER.NO_APPROVED_DATA').subscribe((res: string) => {
            this.noApprovedDataText = res;
        });
        this.translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismissBtnText = res;
        });
        this.translate.get('HYDROGRAPH_NEW.RANGES').subscribe((res: string) => {
            this.RANGE_TEXT = res;
        });
        this.translate.get('HYDROGRAPH_NEW').subscribe((res: string) => {
            this.PAGE_TEXT = res;
        });
    }

    public ngOnInit() {
        this.viewDataService.scatterMenuItem.next(ACTIVE_ZOOM);
        this.setCustomRangeOptions();
        this.listenUserSettings();
        this.listerForRedoEdits();
        this.listenForUndoEdits();
        const routeSubs = this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            this.customerId = Number(params.get(customerQueryParam));
            // #38792 There is no other possiblity to fix it without serious refactor of HOME MAP and LOCATION GIS QUICK LOOK
            // Since customerId equal to zero is not correct then just finish it here
            if(this.customerId === 0) return;
            this.reloadCache = !(params.get(reloadCacheQueryParam) === '0');
            this.getCurvesList();
            this.getCustomerSettings();
        });
        this.subscriptions.push(routeSubs);
        this.snappingPosition = 1;
    }

    private listenUserSettings() {
        const userSettingsSubs = this.usersService.userSettings
            .pipe(
                filter((v) => !!v),
                map((v) => v.syncZoom),
                distinctUntilChanged(),
            )
            .subscribe((v) => {
                this.syncZoomWithHg = v;
                this.graph.syncZoomWithHg = this.syncZoomWithHg;
                this.graph.zoomFromHydro = this.zoomFromHydro;
                this.graph.displayScatterGraph(this.data);
            });

        const tracerSubs = this.usersService.staticTracerSetting.pipe(distinctUntilChanged())
            .subscribe((isStatic: boolean) => {
                this.graph.isStaticTooltip = isStatic;
                this.graph.displayScatterGraph(this.data);
            });

        this.subscriptions.push(userSettingsSubs, tracerSubs);
    }

    private setCustomRangeOptions() {
        let displayGroupScales = this.viewDataService.scattergraphManualScales;
        displayGroupScales?.forEach((displayGroup) =>{
            if (displayGroup.max === 0 && displayGroup.min === 0) {
                displayGroup.max = null;
                displayGroup.min = null;
            }
        });
        if (!displayGroupScales) {
            displayGroupScales =  [
                {displayGroupId: DEPTH_DISPLAY_GROUP, name: DEPTH, min: null, max: null },
                {displayGroupId: VELOCITY_DISPLAY_GROUP, name: VELOCITY, min: null, max: null}
            ];
        }

        this.customRanges = displayGroupScales;
        this.graph.setManualScales(displayGroupScales);
    }

    public resetManualScales() {
        const displayGroupScales =  [
            {displayGroupId: DEPTH_DISPLAY_GROUP, name: DEPTH, min: null, max: null },
            {displayGroupId: VELOCITY_DISPLAY_GROUP, name: VELOCITY, min: null, max: null}
        ];

        this.customRanges = displayGroupScales;
        this.graph.setManualScales(displayGroupScales);
    }

    public applyManualRanges() {
        const formattedRangesToSave: ManualScale[] = this.customRanges?.map(x => {
            return {
                displayGroupId: x.displayGroupId,
                name: x.name,
                min: x.min,
                max: x.max
            };
        });

        this.viewDataService.scattergraphManualScales = formattedRangesToSave;
        this.graph.setManualScales(formattedRangesToSave);
    }

    private listerForRedoEdits() {
        const redoSubs = this.dataEditService.redoScattergraphEdits.subscribe((storedEdit: StoredEdit) => {
            const { response, action, originalValues, parsedResponse } = storedEdit;

            const { graph } = this;
            graph.editedPoints = graph.editedPoints.filter(p => p.value !== null);

            if (action === DataEditStoreActionTypes.HGedit) {
                const formatted = formatScattergraphPreviewResponse(response);
                [DEPTH_ENTITY, VELOCITY_ENTITY].forEach((entId: number) => {
                    const newEditedPoints = formatted.get(entId);
                    if (newEditedPoints.length === 0) return;

                    newEditedPoints.forEach(point => {
                        const alreadyEdited = this.graph.editedPoints.find(v => v.stamp === point.stamp && v.id === point.id);

                        if (alreadyEdited) {
                            alreadyEdited.value = point.value;
                        } else {
                            this.graph.editedPoints.push(point);
                        }
                    });
                });
            } else if (action === DataEditStoreActionTypes.Snap) {
                this.graph.handleSnapResponse(storedEdit.response, false);
            } else if (action === DataEditStoreActionTypes.Ignore) {
                this.handleIgnoreUnignorePoints(originalValues, true);
            } else if (action === DataEditStoreActionTypes.Unignore) {
                this.handleIgnoreUnignorePoints(originalValues, false);
            } else if (action === DataEditStoreActionTypes.Flag || action === DataEditStoreActionTypes.Unflag) {
                const defaultFlagged = action === DataEditStoreActionTypes.Unflag;
                const allAppliedResponses = this.dataEditService.getAllAppliedEdits();

                graph.hydroFlaggedPoints = graph.hydroFlaggedPoints.filter((p) => {

                    const fromEdits = this.getOriginalFlaggedPoint(allAppliedResponses, p.stamp);

                    if (!fromEdits || fromEdits.flagged === true) return true;

                    if(fromEdits) {
                        if((p.flagged === true && fromEdits.flagged === false)
                            || (p.flagged === false)) {
                                return true;
                            }
                    }

                    return false;
                }).map((p) => {
                    const fromEdits = this.getOriginalFlaggedPoint(allAppliedResponses, p.stamp);
                    return {
                        stamp: p.stamp,
                        flagged: fromEdits ? fromEdits.flagged : defaultFlagged
                    }
                });
            }

            if (response.sgd && response.sgd.length) {
                this.graph.updateDistances(response.sgd);
            }

            if ((response as DataEditPreview).c) {
                const unparsedCurveData = this.unparseCurveData((response as any).c);
                this.updateGlobalCurveData(unparsedCurveData);
                this.graph.graphBuilder.onEditScatterGraph({ c: (response as DataEditPreview).c });
            }

            if ((response as SnapDataResponse).curve) {
                const unparsedCurveData = this.unparseCurveData((response as any).curve);
                this.updateGlobalCurveData(unparsedCurveData);
                this.graph.graphBuilder.onEditScatterGraph({ c: (response as SnapDataResponse).curve });
            }
            this.graph.displayScatterGraph(this.data);
        });

        this.subscriptions.push(redoSubs);
    }

    private updateGlobalCurveData(curveData: string[]) {
        if (this.data && this.data.curves && this.data.curves.length) {
            this.graph.shouldUpdateCurve = true;
            this.data.curves[0].d = [...curveData];
        }
    }

    private unparseCurveData(curveData: { x: number, y: number }[]) {
        return curveData.map((point) => {
            const xValue = this.annotationSettings.isScatterInvert ? point.y : point.x;
            const yValue = this.annotationSettings.isScatterInvert ? point.x : point.y;

            return `[${xValue}:${yValue}]`;
        });
    }

    public validateCustomRanges(opt?: ManualScale): boolean {
        let valid = true;

        if (opt) {
            if (opt.min !== null && opt.max !== null && opt.min >= opt.max) {
                valid = false;
            }
        } else {
            valid = this.customRanges.every(v => {
                const min = v.min | 0;
                const max = v.max | 0;

                return (min === 0 && max === 0) || max > min;
            });
        }
        return valid;
    }

    // #37053 need to reset invalid scales when changing location
    public resetInvalidCustomRanges() {
        let needToApply = false;

        const cached = this.viewDataService.scattergraphManualScales || [];

        this.customRanges = this.customRanges.map((ranges: ManualScale) => {
            const { min, max } = ranges;
            const isBlank = min === null && max === null;

            if (isBlank || max > min) {
                return ranges;
            }

            needToApply = true;

            const fromCache = cached.find(v => v.displayGroupId === ranges.displayGroupId);

            return  fromCache ? fromCache : { ...ranges, max: null, min: null };
        });

        if (needToApply) {
            this.applyManualRanges();
        }
    }

    // used on undoing flagged points need to gather all undone flagged data
    private getOriginalValuesForAllUndoneEdits(currentEditTs: string) {
        // need to reverse to keep current edit values prioritized
        const allEdits = this.dataEditService.getAllUndoneEdits(currentEditTs).map(v => deepCloneMap<number, Map<number, DataEditOriginalValue>>(v.originalValues));
        allEdits.reverse();

        const allOriginalValues: Map<number, Map<number, DataEditOriginalValue>> = new Map();
        allEdits.forEach(entityValuesMap => {
            entityValuesMap.forEach((valuesMap, entId) => {
                if (!allOriginalValues.get(entId)) {
                    allOriginalValues.set(entId, valuesMap);

                    return;
                }

                valuesMap.forEach((value, stamp) => {
                    allOriginalValues.get(entId).set(stamp, value);
                });
            });
        });

        return allOriginalValues;
    }

    private getOriginalFlaggedPoint(originalValues: Map<number, Map<number, DataEditOriginalValue>>, ts: number) {
        if (originalValues.get(DEPTH_ENTITY) && originalValues.get(DEPTH_ENTITY).get(ts)) {
            return originalValues.get(DEPTH_ENTITY).get(ts);
        }

        if (originalValues.get(VELOCITY_ENTITY) && originalValues.get(VELOCITY_ENTITY).get(ts)) {
            return originalValues.get(VELOCITY_ENTITY).get(ts);
        }
    }

    private listenForUndoEdits() {
        const undoSubs = this.dataEditService.undoScattergraphEdits.subscribe((storedEdit: StoredEdit) => {
            const { originalValues, action, originalCurve, originalDistances, snappedPoints, previousSnappedPoints, ts } = storedEdit;
            const { graph } = this;
            if (action === DataEditStoreActionTypes.HGedit && originalValues) {
                [DEPTH_ENTITY, VELOCITY_ENTITY].forEach((entId: number) => {
                    const currentEntityEdits = originalValues.get(entId);
                    if (!currentEntityEdits || currentEntityEdits.size === 0) return;

                    graph.editedPoints = graph.editedPoints.filter((point: UpdatePointForSG) => {
                        const fromEdits = currentEntityEdits.get(point.stamp);

                        if (!fromEdits) return true;

                        if (fromEdits.correctedY === undefined || fromEdits.correctedY === null) return false;

                        point.value = fromEdits.correctedY;
                        return true;
                    });
                });
            } else if (action === DataEditStoreActionTypes.Flag || action === DataEditStoreActionTypes.Unflag) {
                const defaultFlagged = action === DataEditStoreActionTypes.Flag;
                const allOriginalValues = this.getOriginalValuesForAllUndoneEdits(ts);

                graph.hydroFlaggedPoints = graph.hydroFlaggedPoints.filter((p) => {

                    const fromEdits = this.getOriginalFlaggedPoint(allOriginalValues, p.stamp);

                    if (!fromEdits || fromEdits.flagged === true) return true;

                    if(fromEdits) {
                        if((p.flagged === true && fromEdits.flagged === false)
                            || (p.flagged === false)) {
                                return true;
                            }
                    }

                    return false;
                }).map((p) => {
                    const fromEdits = this.getOriginalFlaggedPoint(allOriginalValues, p.stamp);
                    return {
                        stamp: p.stamp,
                        flagged: fromEdits ? fromEdits.flagged : defaultFlagged
                    }
                });

                graph.flaggedPointsData = graph.flaggedPointsData.filter((point: EntityData) => {
                    const fromEdits = this.getOriginalFlaggedPoint(allOriginalValues, point.dateTime as number);

                    if (!fromEdits || fromEdits.flagged === true) return true;

                    return false;
                });
            } else if (action === DataEditStoreActionTypes.Ignore) {
                this.handleIgnoreUnignorePoints(originalValues, false);
            } else if (action === DataEditStoreActionTypes.Unignore) {
                this.handleIgnoreUnignorePoints(originalValues, true);
            }

            if (action === DataEditStoreActionTypes.Snap && snappedPoints && previousSnappedPoints) {
                const toApplyIgnores = this.getAllIgnoreEdits(ts);

                graph.snappedPointsData = snappedPoints;
                graph.previousSnappedPoints = previousSnappedPoints;

                this.dataSelectForSnap.emit({
                    selected: graph.selectedPoints,
                    snapped: graph.previousSnappedPoints.map((v) => v.dateTime),
                  });

                this.graph.displayScatterGraph(this.data);

                toApplyIgnores.forEach(v => this.handleIgnoreUnignorePoints(v.originalValues, true));
            }

            if (originalCurve) {
                this.updateGlobalCurveData(originalCurve);
            }

            if (originalDistances && originalDistances.size > 0) {
                graph.undoDistances(originalDistances);
            }
            this.graph.displayScatterGraph(this.data);
        });

        this.subscriptions.push(undoSubs);
    }

    private getAllIgnoreEdits(latestTs: string) {
        const index = this.dataEditService.storedEdits.findIndex(v => v.ts === latestTs);

        if (index === -1) return [];

        return this.dataEditService.storedEdits.slice(0, index).filter(v => v.action === DataEditStoreActionTypes.Ignore);
    }

    private handleIgnoreUnignorePoints(originalValues: Map<number, Map<number, DataEditOriginalValue>>, isIgnore: boolean) {
        const { graph } = this;
        const depthOriginalValues = originalValues.get(DEPTH_ENTITY);

        const dataPoints = graph.advanceScatteroptions.series.filter(v => v.type === 'scatter').map(v => v.data).reduce((acc, curr) => {
            return [...acc, ...(curr ? curr.filter(v => depthOriginalValues.get(v.dateTime as number)) : [])];
        }, []);

        if (isIgnore) {
            let ignored: EntityData[] = [...this.graph.ignoredPointsData, ...dataPoints];
            ignored = ignored.filter((v, i) => v.dateTime && ignored.findIndex((x: EntityData) => x.dateTime === v.dateTime) === i); //remove duplicate flag points
            graph.ignoredPointsData = ignored;
        } else {
            graph.ignoredPointsData = graph.ignoredPointsData.filter(
                (v) => !dataPoints.find((i: EntityData) => i.dateTime === v.dateTime),
            );
        }

        this.graph.ignoredSeriesSet();
        this.graph.ignoredSeriesNotify();

    }

    private getCustomerSettings() {
        if (!this.customerId) return;

        this.gisService.getCustomerSettings(this.customerId, this.reloadCache).subscribe((data: Customer) => {
          this.isMetric = data.unitsType && Number(data.unitsType) === UnitOfMeasureType.METRIC;
        })
    }

    // tslint:disable-next-line:cyclomatic-complexity
    public ngOnChanges(changes: SimpleChanges): void {
        this.onChange(changes);
    }

    public updateAnnotationSettings(newSettings: Partial<AnnotationSettings>) {
        this.annotationSettings = { ...this.annotationSettings, ...newSettings };
    }

    public onAnnotationSettingsChange(annotationSettings: AnnotationSettings) {
        this.savedCurveData = null;
        this.updateAnnotationSettings(annotationSettings);
        this.updateAnnotations.emit(this.annotationSettings);
    }

    // tslint:disable-next-line:cyclomatic-complexity
    public onChange(changes: SimpleChanges) {
        // Whenever should recreate SG - We want it to happens just once, including all the changes
        let shouldDisplaySG = false;

        if (changes.locationId) {
            this.isLoading = false;
        }
        this.graph.isoQlines.length = 0;
        this.graph.froudeLines.length = 0;

        if(changes.selectedEntityIds && !_.isEqual(changes.selectedEntityIds.currentValue, changes.selectedEntityIds.previousValue)) {
            // #40507 Clear ignored points on entity change
            this.dataEditService.sgIgnoredPointsData$.next([]);
        }
        // ensure data property

        if (changes.zoomFromHydro && !changes.zoomFromHydro.firstChange && !_.isEqual(this.graph.zoomFromHydro, changes.zoomFromHydro.currentValue)) {
            this.graph.zoomFromHydro = changes.zoomFromHydro.currentValue;
            shouldDisplaySG = true;
        }
        if (changes.data && !changes.data.currentValue) {
            return;
        }

        if (changes.selectedConfirmationEntity) {
            this.graph.selectedConfirmationEntity = changes.selectedConfirmationEntity.currentValue;
            shouldDisplaySG = true;
        }

        if (changes.isDataEditingModeEnabled) {
            const isEdit = changes.isDataEditingModeEnabled.currentValue;
            this.graph.isDataEditingModeEnabled = isEdit;
            this.graph.clearSeries(true, isEdit, false);
            if (isEdit) {
                this.updateAnnotationSettings({ isCurveEnabled: true });
                this.checkSelectedCurveValue();
            } else {
                this.graph.editedDistances = new Map();
            }
            shouldDisplaySG = true;
        }

        if (changes.editedPoints && changes.editedPoints.currentValue) {
            this.graph.editedPoints = changes.editedPoints.currentValue;
            shouldDisplaySG = true;
        }

        // Hiding filters on location change
        if (changes.annotationSettings) {
            this.checkCurveValuesOnAnnotationsChange(changes.annotationSettings);
        }

        let shouldNextObservables = false;
        if (
            changes.annotationSettings &&
            changes.annotationSettings.currentValue &&
            changes.annotationSettings.previousValue &&
            changes.annotationSettings.currentValue !== changes.annotationSettings.previousValue
        ) {
            const { annotationSettings: a } = changes;
            this.onAnnotationChange(a.previousValue, a.currentValue);
        } else if (this.data) {
            if (this.avoidDataRecall) {
                this.avoidDataRecall = false;

                if(!shouldDisplaySG) {
                    return;
                }
            } else {
                shouldDisplaySG = true;
                if (!this.isDataEditingModeEnabled) {
                    shouldNextObservables = true;
                }
            }
        }

        if(shouldDisplaySG) {
            this.graph.displayScatterGraph(this.data);

            if(shouldNextObservables) {
                this.viewDataService.acceptDataEditing.next(false);
                this.statusCodeService.enableGlobalDropDowns.next(false);
            }
        }
    }

    // todo need some other solution here, it has a duplicate in scattergraph edit menu component
    private checkSelectedCurveValue() {
        if (this.graph.savedCurve) {
            return;
        }

        if (this.annotationSettings.isBestFit) {
            this.selectedCurve = this.curves[DataEditingCurveTypesUIIndexes.BestFit];
        } else if (this.annotationSettings.isCWRcurve) {
            this.selectedCurve = this.curves[DataEditingCurveTypesUIIndexes.Colebrook];
        } else if (this.annotationSettings.isSSCurve) {
            this.selectedCurve = this.curves[DataEditingCurveTypesUIIndexes.StevensSchutzbach];
        } else if (this.annotationSettings.isManualLinear) {
            this.selectedCurve = this.curves[DataEditingCurveTypesUIIndexes.ManualLinear];
        } else if (this.annotationSettings.isManualSmooth) {
            this.selectedCurve = this.curves[DataEditingCurveTypesUIIndexes.ManualSmooth];
        } else if (this.annotationSettings.isManningDesign) {
            this.selectedCurve = this.curves[DataEditingCurveTypesUIIndexes.ManningDesign];
        } else if (this.annotationSettings.isLanfearColl) {
            this.selectedCurve = this.curves[DataEditingCurveTypesUIIndexes.LanfearColl];
        } else { // No curve
            this.selectedCurve = this.curves[DataEditingCurveTypesUIIndexes.None];
        }
    }

    public onAnnotationChange(prevAnnotations: AnnotationSettings, currentAnnotations: AnnotationSettings) {
        const shouldCallApi = this.checkApiCallNeededForAnnotation(prevAnnotations, currentAnnotations);

        if (prevAnnotations.isScatterInvert !== currentAnnotations.isScatterInvert) {
            this.editMenu.updateValuesOnInvertAxis();
        }
        if (shouldCallApi) {
            this.viewDataService.filterValues.value.annotationsSettings = currentAnnotations;
            this.annotationSettings = { ...currentAnnotations };
            this.getScatterGraphData(this.viewDataService.filterValues.getValue());
        } else {
            this.annotationSettings = { ...currentAnnotations };
            this.graph.annotationSettings = { ...currentAnnotations };
            this.graph.displayScatterGraph(this.data);
        }
    }

    public storeRawvelEdits(edits: Map<number, DataEditOriginalValue>) {
        this.graph.rawvelEdits = edits;
    }

    // Called on revert changes from roll back editor
    public onRevertChanges(points: DataEditLog[]) {
        const velEntity = this.selectedEntityIds.includes(VELOCITY_ENTITY) ? VELOCITY_ENTITY : RAW_VELOCITY_ENTITY;
        // need to filter points to have only depth/velocity, then gather all timestamps to one Map
        const filteredMappedPointsByEntities = points.filter(v => v.entityID === DEPTH_ENTITY || v.entityID === velEntity);
        const filteredMappedPoints = filteredMappedPointsByEntities.reduce((acc, curr) => {
            // #32416 Cannot use JS Date here, we need real UTC time (including timezone time changes)
            return acc.set(moment.utc(curr.timestamp).unix() * 1000, true);
        }, new Map<number, boolean>());

        const filterFunc = (point: EntityData) => {
            // #32416 Cannot use JS Date here, we need real UTC time (including timezone time changes)
            const pointTimeStamp = moment.utc(point.dateTime).unix() * 1000;
            return !(filteredMappedPoints.get(pointTimeStamp));
        }

        // #32416 Reverted points cannot be flagged
        this.graph.hydroFlaggedPoints = this.graph.hydroFlaggedPoints.filter((p) => !filteredMappedPoints.get(p.stamp));
        this.graph.flaggedPoints = this.graph.flaggedPoints.filter((p) => !filteredMappedPoints.get(p.stamp));

        // No need to recall displayScattergraph function cause revert will trigger API request and then display function
        if (this.graph && filteredMappedPoints.size > 0) {
            this.graph.previousSnappedPoints = this.graph.previousSnappedPoints.filter(filterFunc);
            this.graph.snappedPointsData = this.graph.snappedPointsData.filter(filterFunc);
            this.graph.flaggedPointsData = this.graph.flaggedPointsData.filter(filterFunc);
        }
    }

    public onGenerationOptionsChange(generationOption: GenerationOptions) {
        this.isLoading = true;
        this.generationOption = generationOption;
        this.getDateRangeBasedOnMostRecentApproval(
            this.viewDataService.filterValues.getValue(),
            this.generationOption,
        ).subscribe((result) => {
            if (result) {
                const { startDate, endDate } = result;

                const { startDate: dStart, endDate: dEnd } = this.viewDataService.filterValues.getValue();
                const formattedDistanceStart = this.dateutilService.getLocalDateFromUTCDate(new Date(dStart));
                const formattedDistanceEnd = this.dateutilService.getLocalDateFromUTCDate(new Date(dEnd));

                const distancesStart = formattedDistanceStart.toISOString().slice(0,-5);
                const distancesEnd = formattedDistanceEnd.toISOString().slice(0,-5);

                this.viewDataService
                    .getScatterGraphHistoricalCurve(
                        this.customerId,
                        this.locationId,
                        startDate,
                        endDate,
                        [...this.selectedEntityIds].reverse(),
                        this.annotationSettings.isBestFit ? [GraphTypes.bestfit] : [GraphTypes.stevensSchutzbach],
                        true, distancesStart, distancesEnd
                    )
                    .subscribe((historicalCurve) => {
                        this.data.curves = historicalCurve;
                        this.graph.displayScatterGraph(this.data);
                        this.graph.updateDistances(historicalCurve[0].distances);
                        this.isLoading = false;
                    });
            } else {
                this.generationOption = GenerationOptions.CURRENT;
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
                this.snackBar.open(this.noApprovedDataText, this.dismissBtnText, {
                    panelClass: 'custom-error-snack-bar',
                });
            }
        });
    }

    private checkApiCallNeededForAnnotation(prev: AnnotationSettings, curr: AnnotationSettings) {
        if (!prev || !curr) {
            return true;
        }

        const keys = ['isBestFit', 'isFroude', 'isIsoQ', 'isSSCurve', 'isPipeOverlay', 'isCWRcurve', 'isManningDesign', 'isLanfearColl'];
        return keys.some(v => (!!prev[v] !== !!curr[v]) && curr[v]);
    }

    public onSelectedCurveChange(newCurve) {
        const current = this.curves.find((v) => v.name === newCurve.name);

        this.savedCurveData = null;
        this.graph.savedCurve = null;
        this.selectedCurve = current;
        this.graph.editedDistances = new Map();
        this.viewDataService.scatterMenuItem.next(ACTIVE_ZOOM);
    }

    private checkCurveValuesOnAnnotationsChange(annotationSettings: {
        currentValue: AnnotationSettings;
        previousValue: AnnotationSettings;
    }) {
        const { currentValue, previousValue } = annotationSettings;
        const keys = ['isBestFit', 'isSSCurve', 'isToleranceLines', 'isManualSmooth', 'isManualLinear'];

        if (!currentValue || !previousValue) {
            return;
        }

        // code checks if any key differs
        const isDiffers = keys.some((key) => currentValue[key] !== previousValue[key]);

        const currentCurve = this.editMenu ? this.editMenu.selectedCurve : null;

        if (isDiffers && (!currentCurve || (typeof currentCurve !== 'string' && currentCurve.name === this.curves[0].name))) {
            this.viewDataService.scatterToleranceRangeValue = 0;
        }
    }

    private updateChartSeries(key: string, curves: any) {
        this.graph.chart.series
            .filter((x: { name: string }) => x.name === key)[0]
            .update(
                {
                    data: this.graph.generateSeriesDataForUpdate(this.annotationSettings.isScatterInvert, curves, key),
                },
                true,
            );
    }

    public ngOnDestroy() {
        this.viewDataService.advancedCharts[1] = null;
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public onHGflagPoints(points: number[]) {
        const toUnflag = this.graph.hydroFlaggedPoints.filter(v => !points.includes(v.stamp))

        if (toUnflag.length) {
            this.graph.flaggedPointsData = this.graph.flaggedPointsData.filter(v => !toUnflag.filter(p => p.stamp === v.dateTime as number));
        }
        this.graph.hydroFlaggedPoints = points.map(p => {
            return {
                stamp: p,
                flagged: true
            }
        });
    }

    public onSaveCurve(curveName: string) {
        const depthOnY = this.annotationSettings.isScatterInvert ? false : true;

        let lineName = "";
        let smoothCurve = false;
        if (this.annotationSettings.isBestFit) { lineName = BEST_FIT; }
        else if (this.annotationSettings.isSSCurve) { lineName = STEVENS_CURVE; }
        else if (this.annotationSettings.isCWRcurve) { lineName = COLEBROOK_CURVE; }
        else if (this.annotationSettings.isLanfearColl) { lineName = LANFEAR_COLL_CURVE; }
        else if (this.annotationSettings.isManningDesign) { lineName = MANNING_DESIGN_CURVE; }
        else if (this.annotationSettings.isManualLinear) { lineName = MANUAL_LINEAR_CURVE; }
        else if (this.annotationSettings.isManualSmooth) {
            lineName = MANUAL_SMOOTH_CURVE;
            smoothCurve = true;
        }
        const line = this.graph.advanceScatteroptions.series.find((v) => v.name === lineName);
        const editable = this.viewDataService.isCurveEditable;

        if (!line) return;

        const formattedData = (line.data as any).map((v: any) => `[${v.x}:${v.y}]`);
        this.viewDataService
            .createNewCurveOptions(this.customerId, this.locationId, curveName, formattedData, depthOnY, smoothCurve, editable)
            .subscribe(() => {
                this.viewDataService.scatterManualCurveSelectedPoints.next([]);
                this.getCurvesList(null, true);
            });
    }

    public getCurvesList(locationId: number = null, loadLast = false) {
        this.savedCurveData = null;
        const lid = locationId ? locationId : this.locationId;
        if (!lid || lid === -1) {
            return;
        }
        this.viewDataService.getSavedCurves(this.customerId, lid).subscribe((data: string[]) => {
            this.savedCurves = data || [];

            if (loadLast && this.savedCurves.length > 0) {
                this.onSavedCurveSelected(this.savedCurves[this.savedCurves.length - 1]);
            }

            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
    }

    public checkSelectedCurveOnLocationChange() {
        this.viewDataService.scatterManualCurveSelectedPoints.next([]);
        this.viewDataService.scatterToleranceRangeValue = 0;
        if (!this.curves.includes(this.selectedCurve)) {
            this.selectedCurve = this.curves[0];
        }
    }

    public onSavedCurveDeleted(curveName: string) {
        if (this.savedCurveData && this.savedCurveData[0].name === curveName) {
            this.savedCurveData = null;
            this.graph.savedCurve = null;
            this.checkSelectedCurveOnLocationChange();
            this.graph.displayScatterGraph(this.data);
        }

        this.getCurvesList(null, true);
    }

    public onSavedCurveSelected(curveName) {
        this.selectedCurve = curveName;
        this.viewDataService.scatterMenuItem.next(ACTIVE_ZOOM);
        this.graph.editedDistances = new Map();
        const filters = this.viewDataService.filterValues.getValue();

        const startDate = (filters.startDate ? this.dateutilService.getLocalDateFromUTCDate(filters.startDate) : filters.startDate);
        const endDate = (filters.endDate ? this.dateutilService.getLocalDateFromUTCDate(filters.endDate) : filters.endDate);

        const startStr = startDate ? startDate.toISOString().slice(0,-5) : null;
        const endStr = endDate ? endDate.toISOString().slice(0,-5) : null;

        const depthOnY = this.annotationSettings.isScatterInvert ? false : true;

        this.viewDataService
            .getSavedCurveData(
                this.customerId,
                this.locationId,
                startStr,
                endStr,
                this.selectedCurve as any,
                depthOnY,
                depthOnY ? [...this.selectedEntityIds].reverse() : this.selectedEntityIds,
            )
            .subscribe((data) => {
                if (!data || !data[0]) return;

                const dyFromRes = data[0].depthOnY;
                // need to invert, because data is already inverted on the API side
                if (depthOnY === false && dyFromRes === true) {
                    data[0].depthOnY = false;
                } else if (depthOnY === true && dyFromRes === false) {
                    data[0].depthOnY = true;
                }
                this.annotationSettings.isBestFit = false;
                this.annotationSettings.isSSCurve = false;
                this.annotationSettings.isManualLinear = data[0].editable && !data[0].smoothCurve;
                this.annotationSettings.isManualSmooth = data[0].editable && data[0].smoothCurve;

                this.viewDataService.isCurveEditable = data[0].editable;

                this.graph.annotationSettings = this.annotationSettings;
                this.savedCurveData = data;
                this.updateAnnotations.emit(this.annotationSettings);

                this.graph.savedCurve = this.savedCurveData;
                this.graph.displayScatterGraph(this.data);
                this.graph.updateDistances(this.savedCurveData[0].distances);
            });
    }

    private getDateRangeBasedOnMostRecentApproval(
        filters: LocationDashboardFilterData,
        generationOption: GenerationOptions,
    ): Observable<{ startDate: Date; endDate: Date } | null> {
        switch (generationOption) {
            case GenerationOptions.CURRENT:
                const startDate = this.dateutilService.getLocalDateFromUTCDate(filters.startDate);
                const endDate = this.dateutilService.getLocalDateFromUTCDate(filters.endDate);
                return of({ startDate, endDate });
            case GenerationOptions.LAST_30:
            case GenerationOptions.LAST_60:
            case GenerationOptions.LAST_90:
                const days =
                    generationOption === GenerationOptions.LAST_30
                        ? 30
                        : generationOption === GenerationOptions.LAST_60
                        ? 60
                        : 90;

                return this.dataEditService.mostRecentApproval(this.customerId, [this.locationId]).pipe(
                    mergeMap((result) => {
                        if (!result) {
                            return of(null);
                        }
                        return of({
                            startDate: this.dateutilService.getLocalDateFromUTCDate(
                                moment(result[0].approvedThrough).subtract(days, 'd').toDate(),
                            ),
                            endDate: this.dateutilService.getLocalDateFromUTCDate(
                                moment(result[0].approvedThrough).toDate(),
                            ),
                        });
                    }),
                );
                break;
            default:
            // not supported
        }
    }

    /**
     * Retrieves scattergraph data for the current location dashboard filters.
     * @param filters Represents the location dashboard filters.
     */
    public getScatterGraphData(filters: LocationDashboardFilterData, overrideScatterGraphConfirmationData?, clearRawvel = true): void {
        if (!this.selectedEntityIds || !this.selectedEntityIds.length || this.selectedEntityIds.length < 2) return;

        if (clearRawvel) {
            this.graph.rawvelEdits = null;
        }
        this.isLoading = true;

        const tempFlags = this.graph.flaggedPointsData.map((v) => v.dateTime) as number[];
        const tempFlagsStr = tempFlags.map((x) => new Date(x).toISOString().split('.')[0]);

        const uniqueTempFlags = [...new Set(tempFlagsStr)];

        if(!this.annotationSettings) this.annotationSettings = {} as AnnotationSettings;

        // if scattergraph data was refetched during DE with non-confirmed edits (i.e changing LD date range inside DE), we will need to refresh SG data after DE session
        if (this.isDataEditingModeEnabled && this.dataEditService.storedEdits.length) {
            this.graph.shouldUpdateCurve = true;
        }

        const entityIds = this.viewDataService.sortSelectedEntities(
            this.selectedEntityIds,
            this.annotationSettings.isScatterInvert,
        );

        if(!entityIds || !entityIds.length || entityIds.length != 2){
            return;
        }

        const subscription = this.viewDataService
            .getScatterGraphOptimized(
                this.customerId,
                filters.locationIDs[0],
                filters.startDate,
                filters.endDate,
                filters.annotationsSettings.isPipeOverlay ? true : false,
                this.annotationSettings.isPipeHeight,
                this.annotationSettings.isManholeDepth,
                this.annotationSettings.isIsoQ,
                this.annotationSettings.isBestFit,
                this.annotationSettings.isSSCurve,
                this.annotationSettings.isCWRcurve,
                this.annotationSettings.isManningDesign,
                this.annotationSettings.isLanfearColl,
                this.annotationSettings.isFroude,
                uniqueTempFlags,
                overrideScatterGraphConfirmationData ? overrideScatterGraphConfirmationData : this.scatterGraphConfirmationData,
                entityIds,
                filters.summarizeInterval,
                this.annotationSettings.isSilt,
                this.reloadCache
            )
            .subscribe(
                (scatterResult: ScatterData) => {
                    // late response, user is viewing another location
                    if (this.locationId !== Number(filters.locationIDs[0])) {
                        return;
                    }
                    if (this.graph) {
                        this.graph.latestGainEditResults = null;
                    }
                    this.scatterResponse.emit(scatterResult);
                    if (!scatterResult) {
                        /** Handling in case we have 204 No content in Response */
                        this.data = null;
                        this.isLoading = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                        return;
                    }
                    this.graph.editedDistances = new Map();
                    this.avoidDataRecall = true;
                    this.data = <ScatterData>scatterResult;
                    this.data.confirmations = scatterResult.c;
                    this.generationOption = GenerationOptions.CURRENT;
                    this.graph.displayScatterGraph(this.data);
                    this.isLoading = false;
                },
                (error) => {
                    this.data = null;
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
            );

        this.subscriptions.push(subscription);
    }

    public onApplySnapToCurve() {
        this.isDataSelected = false;

        this.graph.applySnapToCurve();
    }

    public onCurveChanged(curve: string[]) {
        this.curveChanged.emit(curve);
    }

    public updateCurve() {
        let start, end;
        if (this.syncZoomWithHg && this.zoomFromHydro && this.zoomFromHydro.length) {
            [start, end] = this.zoomFromHydro;
        } else {
            const filters = this.viewDataService.filterValues.getValue();
            start = filters.startDate;
            end = filters.endDate;
        }

        this.graph.getHistoricalCurve(start, end, this.annotationSettings.isBestFit);
    }

    public rangeChanged() {
        this.graph.selected = this.editMenu.selected;
        this.graph.plotToleranceLines(this.viewDataService.scatterToleranceRangeValue, this.editMenu.selected);
    }

    public onDataSelect(isSelected: boolean) {
        this.isDataSelected = isSelected;
    }

    public setLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }
}
