import { Injectable } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';

@Injectable({
    providedIn: 'root',
})
export class FlowloadFileUploadService {
    public importActive: Object = {};
    public importProgressBars: Object = {};
    constructor() {}

    public setImportActive(caseStudyId: string, value: boolean): void {
        this.importActive[caseStudyId] = value;
    }

    public resetImport(caseStudyId: string): void {
        this.setImportActive(caseStudyId, false);
        this.importProgressBars[caseStudyId] = [];
    }

    public setProgressBars(uploader: FileUploader, caseStudyId: string): void {
        this.importProgressBars[caseStudyId] = [];
        for (let i = 0; i < uploader.queue.length; i++) {
            this.importProgressBars[caseStudyId].push({ name: uploader.queue[i]['file']['name'], progress: 0 });
        }
        uploader.onProgressItem = (file: FileItem, progress: any) => {
            const progressElement = this.importProgressBars[caseStudyId].find(
                (up) => up['name'] == file['file']['name'],
            );
            if (progressElement) {
                progressElement['progress'] = progress;
            }
        };
    }
}
