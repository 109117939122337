import {
    Component,
    ViewEncapsulation,
    OnInit,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    OnChanges,
    SimpleChanges,
    ChangeDetectionStrategy,
    OnDestroy,
} from '@angular/core';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { Subscription } from 'rxjs';
import { FEATURE_IDS, UsersService } from 'app/pages/admin/users.service';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { CustomerService } from 'app/shared/services/customer.service';
import { ViewDataService } from 'app/shared/services/view-data.service';
import { AnnotationSettings } from 'app/shared/models/view-data';
import { ScatterCurve, ScatterData } from 'app/shared/models/scatter-data';
import { GetPermissionsResponseCustomerPermissions } from 'app/shared/models/users-permission';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SaveCurveDialogComponent } from 'app/shared/components/save-curve-dialog/save-curve-dialog.component';
import { first } from 'rxjs/operators';
import { SeparateWindowHydrographService } from 'app/shared/services/separate-window-hydrograph.service';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { CurveOptionsDialogComponent } from '../curve-options-dialog/curve-options-dialog.component';
import { LocationDetails } from 'app/shared/models/location-details';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { ScatterGraphBuilder } from '../advance-scattergraph/scattergraph-builder.service';
import { DataEditService } from 'app/shared/services/data-edit.service';
import { DataEditingCurveTypesShortcuts, DataEditingCurveTypesUIIndexes } from 'app/shared/models/data-edit';
import { TranslateService } from '@ngx-translate/core';
import { Customer } from 'app/shared/models/customer';

const ACTIVE_ZOOM = 'activeZoomFlag';
const MANUAL_CURVE = 'manualCurveAdd';

@Component({
    selector: 'app-scattergraph-edit-menu',
    templateUrl: './scattergraph-edit-menu.component.html',
    styleUrls: ['./scattergraph-edit-menu.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ScatterGraphEditMenuComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public isDataEditingModeEnabled = false;
    @Input() public isLoading: boolean;
    @Input() public locationDetails: LocationDetails;
    @Input() public isMetric: boolean;
    @Output() private updateAnnotationSettings = new EventEmitter<AnnotationSettings>();
    @Output() private emitChange = new EventEmitter();
    @Output() private emitApplySnapToCurve = new EventEmitter();
    @Output() private rangeChanged = new EventEmitter();
    @Output() private emitSelectData = new EventEmitter();
    @Output() private emitClearSelection = new EventEmitter();
    @Output() private emitDisableZoom = new EventEmitter();
    @Output() private setLoading = new EventEmitter<boolean>();
    @Output() private updateCurveValue = new EventEmitter();
    @Output() private emitGenerationOptionsChange = new EventEmitter();
    @Output() private updateCurve = new EventEmitter();
    @Output() private emitSaveCurve = new EventEmitter();
    @Output() private emitSavedCurveSelected = new EventEmitter();
    @Output() private emitSavedCurveDeleted = new EventEmitter();
    @Input() public annotationSettings: AnnotationSettings;
    @Input() public isPrinting: boolean;
    @Input() public data: ScatterData;
    @Input() public savedCurve: string;
    @Input() public customerId: number;
    @Input() public locationId: number;
    @Input() public isDataSelected;
    @Input() public isPointsSelected: boolean;
    @Input() public selectedCurve: ScatterCurve | string;
    @Input() public curves: ScatterCurve[];
    @Input() public syncZoomWithHg: boolean;
    @Input() public savedCurves: string[];
    @Input() public generationOption = 0;
    @Input() public validPipeTable: boolean;

    public selected = 3;

    // public isCurveEditable = false;
    private currentCustomer: Customer;
    private userHasEditPermission = false;
    private customerHasBasicEditPermisstion = false;
    public customerHasAdvancedEditPermission = false;
    private subscriptions = new Subscription();
    loadingSavedSettings: boolean;
    public selectedHintPageName: string;
    public showCurveOptions = false;
    public displayedListOfCurves;

    public disableUndo = true;
    public disableRedo = true;
    public originalSelectedCurve: ScatterCurve | string;

    public translations = {
        curvePipeTableInvalid: '',
        curveSlopeEmpty: '',
        curveRoughnessEmpty: '',
        curveEffectiveRoughnessEmpty: ''
    }
    constructor(
        private uiUtilsService: UiUtilsService,
        private cdr: ChangeDetectorRef,
        private customerService: CustomerService,
        private userService: UsersService,
        private dialog: MatDialog,
        public viewDataService: ViewDataService,
        private graphBuilder: ScatterGraphBuilder,
        public sepWinHG: SeparateWindowHydrographService,
        private domOperationUtilsService: DomOperationUtilsService,
        public dataEditService: DataEditService,
        private translate: TranslateService
    ) {
        this.userHasEditPermission = this.userService.userFeatures$.getValue()
            .some(x => x.id === FEATURE_IDS.BASIC_DATA_EDITING);
        this.applyTranslation();
    }

    private applyTranslation() {
        this.translate.get('LOCATION_DASHBOARD.CURVE_PIPE_TABLE_INVALID').subscribe((res: string) => {
            this.translations.curvePipeTableInvalid = res;
        });
        this.translate.get('LOCATION_DASHBOARD.CURVE_SLOPE_EMPTY').subscribe((res: string) => {
            this.translations.curveSlopeEmpty = res;
        });
        this.translate.get('LOCATION_DASHBOARD.CURVE_ROUGHNESS_EMPTY').subscribe((res: string) => {
            this.translations.curveRoughnessEmpty = res;
        });
        this.translate.get('LOCATION_DASHBOARD.CURVE_EFFECTIVE_ROUGHNESS_EMPTY').subscribe((res: string) => {
            this.translations.curveEffectiveRoughnessEmpty = res;
        });
    }

    public ngOnInit() {
        this.checkForEdits();
        const pageNameHint = this.domOperationUtilsService.selectedHintPageName.subscribe((pageName: string) => {
            this.selectedHintPageName = pageName;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
        this.subscriptions.add(pageNameHint);

        this.viewDataService.loadScatterGraphToolData.subscribe((data) => {
            if (data && !this.loadingSavedSettings) {
                this.loadingSavedSettings = true;
                this.setLoading.emit(true);
                if (typeof this.selectedCurve === 'string' ||
                    (typeof this.selectedCurve !== 'string' && data['sCurve'] && data['sCurve'].shortName !== this.selectedCurve.shortName)) {
                    this.selectedCurve = data['sCurve'];
                    this.onCurveChanged();
                }
                if (data['sTolerance'] && data['sTolerance'] !== this.selected) {
                    this.selected = Number(data['sTolerance']);
                }
                if (Number(data['sRange']) && data['sRange'] !== this.viewDataService.scatterToleranceRangeValue) {
                    if (Number(data['sRange'])) {
                        setTimeout(() => {
                            this.onRangeChanged({ value: data['sRange'] });
                        }, 0);

                    }
                }
                if (data['sGenerationOptions'] && data['sGenerationOptions'] !== this.generationOption) {
                    this.generationOption = data['sGenerationOptions'];
                    this.emitGenerationOptionsChange.emit(this.generationOption);
                }
                if (data['sSyncZoom'] && data['sSyncZoom'] !== this.syncZoomWithHg) {
                    this.syncZoomWithHg = data['sSyncZoom'];
                    this.onSyncZoomChange({ checked: data['sSyncZoom'] } as MatSlideToggleChange);
                }
                this.updateToolData();
                this.loadingSavedSettings = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            }
        });
        this.dataEditService.onEditsChanged.next(true);

        if (this.annotationSettings && (this.annotationSettings.isManualLinear || this.annotationSettings.isManualSmooth)) {
            this.manualCurveAdd();
        }
    }

    public isString(value: string | ScatterCurve): value is string {
        return typeof value === 'string';
    }

    public isScatterCurve(curve: string | ScatterCurve): curve is ScatterCurve {
        return (curve as ScatterCurve).shortName !== undefined;
    }

    private checkForEdits() {
        const subs = this.dataEditService.onEditsChanged.subscribe(() => {
            const { currentEditTimestamp: currentTs, storedEdits: edits } = this.dataEditService;

            this.disableUndo = edits.length === 0 || currentTs === null;
            this.disableRedo = (edits.length === 0 || edits[edits.length -1].ts === currentTs);
        });

        this.subscriptions.add(subs);
    }

    public manualCurveClear() {
        this.viewDataService.scatterMenuItem.next('activeClearFlag');
        this.graphBuilder.clearManualCurvePoints();
        this.viewDataService.scatterMenuItem.next(MANUAL_CURVE);
        this.viewDataService.scatterToleranceRangeValue = 0;
        this.onRangeChanged();
    }
    public manualCurveAdd() {
        // If this is already selected, de-toggle back to zoom
        // Otherwise go into add mode
        if (this.viewDataService.scatterMenuItem.getValue() === MANUAL_CURVE) {
            this.viewDataService.scatterMenuItem.next(ACTIVE_ZOOM);
        } else {
            this.viewDataService.scatterMenuItem.next(MANUAL_CURVE);
        }
    }

    public onSavedCurveSelect(curve: string) {
        this.selectedCurve = curve;
        this.savedCurve = curve;
        this.viewDataService.isCurveEditable = false;
        this.onCurveChanged();
        this.emitSavedCurveSelected.emit(curve);

        if (this.generationOption !== 0) {
            this.generationOption = 0;
            this.onGenerationOptionsChanged({ value: this.generationOption });
        }
    }

    public curveNameCompare(a, b) {
        const aAsString = typeof a === 'string' ? a : a.shortName;
        const bAsString = typeof b === 'string' ? b : b.shortName;

        return aAsString === bAsString;
    }

    public onDefaultCurveSelect(curve) {
        this.selectedCurve = curve;
        this.onCurveChanged();
    }

    public deleteSavedCurve(curve: string) {
        this.dialog
            .open(ConfirmationDialogComponent, {
                data: {
                    cancelText: 'Cancel',
                    okText: 'Delete',
                    message: `Are you sure you want to delete the "${curve}" curve?`,
                    title: 'Delete Curve',
                },
            })
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (result && result.whichButtonWasPressed === 'ok') {
                    this.viewDataService
                        .deleteSavedCurve(this.customerId, this.locationId, curve)
                        .subscribe(() => {
                            this.emitSavedCurveDeleted.emit(curve);
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                        });
                }
            });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.customerId) {
            const temp = this.customerService.userCustomers
                .getValue()
                .find((v) => v.customer.customerID === changes.customerId.currentValue);
            this.currentCustomer = temp ? temp.customer : null;

            if (this.currentCustomer) {
                this.customerHasBasicEditPermisstion = this.userService.isBasicDataEditingAllowed.getValue();
                this.customerHasAdvancedEditPermission = this.userService.isAdvancedDataEditingAllowed.getValue().value;
            }

            this.viewDataService.isCurveEditable = false;
            if(this.annotationSettings) {
                if (this.annotationSettings.isBestFit) {
                    this.selectedCurve = this.curves[DataEditingCurveTypesUIIndexes.BestFit];
                } else if (this.annotationSettings.isCWRcurve) {
                    this.selectedCurve = this.curves[DataEditingCurveTypesUIIndexes.Colebrook];
                } else if (this.annotationSettings.isManningDesign) {
                    this.selectedCurve = this.curves[DataEditingCurveTypesUIIndexes.ManningDesign];
                } else if (this.annotationSettings.isLanfearColl) {
                    this.selectedCurve = this.curves[DataEditingCurveTypesUIIndexes.LanfearColl];
                } else if (this.annotationSettings.isSSCurve) {
                    this.selectedCurve = this.curves[DataEditingCurveTypesUIIndexes.StevensSchutzbach];
                } else if (this.annotationSettings.isManualLinear) {
                    this.selectedCurve = this.curves[DataEditingCurveTypesUIIndexes.ManualLinear];
                    this.viewDataService.isCurveEditable = true;
                } else if (this.annotationSettings.isManualSmooth) {
                    this.selectedCurve = this.curves[DataEditingCurveTypesUIIndexes.ManualSmooth];
                    this.viewDataService.isCurveEditable = true;
                }
            }
        }

        if(changes.customerId || changes.locationid || changes.validPipeTable || changes.locationDetails) {
            /**
             * Reference user story 20107 for this functionality
             * All functionality related to manual curves is hidden by simply taking the curves
             * out of the dropdown for users. To enable the manual curve functionality, allow
             * (select) users to see the full this.curves options by replacing this line of code with
             * the if statement below
             */
             // Manual curves only if customer has advanced editing AND
            // user has data editing
            if (!this.customerHasAdvancedEditPermission || !this.userHasEditPermission) {
                this.displayedListOfCurves = JSON.parse(JSON.stringify(this.curves.slice(0,6))); // Don't show manual options
            } else {
                this.displayedListOfCurves = this.curves;
            }

            this.displayedListOfCurves = this.displayedListOfCurves.map((c, index) => {
                let isDisabled = !this.validPipeTable;
                let disableReason =  isDisabled ? this.translations.curvePipeTableInvalid : null;
                if(!isDisabled && index === DataEditingCurveTypesUIIndexes.Colebrook) {
                    if(!this.locationDetails.designSlope) {
                        isDisabled = true;
                        disableReason = this.translations.curveSlopeEmpty;
                    } else if(!this.locationDetails.effectiveRoughness) {
                        isDisabled = true;
                        disableReason = this.translations.curveEffectiveRoughnessEmpty;
                    }
                }
                if(!isDisabled && index === DataEditingCurveTypesUIIndexes.ManningDesign) {
                    if(!this.locationDetails.designSlope) {
                        isDisabled = true;
                        disableReason = this.translations.curveSlopeEmpty;
                    } else if(!this.locationDetails.designRoughness) {
                        isDisabled = true;
                        disableReason = this.translations.curveRoughnessEmpty;
                    }
                }
                return {
                    ...c,
                    disabled: isDisabled,
                    disableReason: disableReason
                }
            })

            if(this.selectedCurve) {
                const name = this.selectedCurve && this.selectedCurve.hasOwnProperty('name') ? this.selectedCurve['name'] : this.selectedCurve;
                const selectedCurve = this.displayedListOfCurves.find((c) => c.name === name);

                if(selectedCurve && selectedCurve.disabled) {
                    if(this.selectedCurve !== this.curves[DataEditingCurveTypesUIIndexes.None]) this.originalSelectedCurve = this.selectedCurve;
                    this.selectedCurve = this.curves[DataEditingCurveTypesUIIndexes.None];
                } else if(this.originalSelectedCurve && this.originalSelectedCurve !== this.selectedCurve) {
                    const originalName = this.originalSelectedCurve && this.selectedCurve.hasOwnProperty('name') ? this.originalSelectedCurve['name'] : this.originalSelectedCurve;
                    const originalSelectedCurve = this.displayedListOfCurves.find((c) => c.name === originalName);

                    if(originalSelectedCurve && !originalSelectedCurve.disabled) {
                        this.selectedCurve = this.originalSelectedCurve;
                        this.originalSelectedCurve = null;
                    }
                }
            }

        }


        // If location will change, we need to take care of additional manual curve settings
        if (changes.locationId && changes.annotationSettings && changes.annotationSettings.currentValue) {
            const cur = changes.annotationSettings.currentValue;
            this.viewDataService.isCurveEditable = false;
            if  (cur.isManualLinear) {
                this.viewDataService.isCurveEditable = true;
            } else if (cur.isManualSmooth) {
                this.viewDataService.isCurveEditable = true;
            }
        }

        if(changes.selectedCurve && changes.selectedCurve.currentValue
            && changes.selectedCurve.currentValue.name && changes.selectedCurve.currentValue.name === 'None') {
                this.viewDataService.isCurveEditable = false;
            }

        if (changes.isDataEditingModeEnabled && changes.isDataEditingModeEnabled.currentValue) {
            this.onRangeChanged();
        }
    }

    // #33902 tolerance lines position dropdown needs to be updated when invert axis toggled
    public updateValuesOnInvertAxis() {
        setTimeout(() => {
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }, 0);
    }

    public onSyncZoomChange(event: MatSlideToggleChange) {
        const userSettings = this.userService.userSettings.getValue();

        this.userService.updateUserSettings({ ...userSettings, syncZoom: event.checked }).subscribe(() => {
            this.userService.userSettings.next({ ...userSettings, syncZoom: event.checked });
        });
    }

    public selectData() {
        this.emitSelectData.emit();
    }

    public clearSelection() {
        this.emitClearSelection.emit();
    }

    public disableZoom(resetColors: boolean) {
        this.emitDisableZoom.emit(resetColors);
    }

    public applySnapToCurve() {
        this.emitApplySnapToCurve.emit();
    }

    public saveCurve() {
        this.dialog
            .open(SaveCurveDialogComponent)
            .afterClosed()
            .pipe(first())
            .subscribe((result: string) => {
                if (result) {
                    this.emitSaveCurve.emit(result.trim());
                }
            });
    }

    public openCurveOptions() {
      this.dialog.open(CurveOptionsDialogComponent, {
        disableClose: false,
        panelClass: 'no-padding-nor-overflow-dialog',
        hasBackdrop: true,
        data: { curves: this.data.curves, location: this.locationDetails, isMetric: this.isMetric}
      });
    }

    private updateToolData() {
        this.viewDataService.scatterGraphToolData.next({
            sCurve: this.selectedCurve,
            sRange: this.viewDataService.scatterToleranceRangeValue,
            sTolerance: this.selected,
            sGenerationOptions: this.generationOption,
            sSyncZoom: this.syncZoomWithHg,
        });

        this.setLoading.emit(false);
    }

    public onCurveChanged() {
        if (!this.annotationSettings) {
            return;
        }
        // Firstly clear curve
        this.viewDataService.scatterManualCurveSelectedPoints.next([]);

        this.showCurveOptions = this.selectedCurve === this.curves[DataEditingCurveTypesUIIndexes.StevensSchutzbach];
        this.updateCurveValue.emit(this.selectedCurve);
        const changes = { annotationSettings: {} };
        changes.annotationSettings['previousValue'] = { ...this.annotationSettings };

        this.annotationSettings.isBestFit = false;
        this.annotationSettings.isSSCurve = false;
        this.annotationSettings.isManualLinear = false;
        this.annotationSettings.isManualSmooth = false;
        this.annotationSettings.isCWRcurve = false;
        this.annotationSettings.isManningDesign = false;
        this.annotationSettings.isLanfearColl = false;
        if (typeof this.selectedCurve !== 'string') {
            switch (this.selectedCurve.shortName) {
                case 'SS': {
                    this.annotationSettings.isSSCurve = true;
                    this.graphBuilder.clearManualCurvePoints();
                    break;
                }
                case 'CWR': {
                    this.annotationSettings.isCWRcurve = true;
                    this.graphBuilder.clearManualCurvePoints();
                    break;
                }
                case DataEditingCurveTypesShortcuts.ManningDesign: {
                    this.annotationSettings.isManningDesign = true;
                    this.graphBuilder.clearManualCurvePoints();
                    break;
                }
                case DataEditingCurveTypesShortcuts.LanfearColl: {
                    this.annotationSettings.isLanfearColl = true;
                    this.graphBuilder.clearManualCurvePoints();
                    break;
                }
                case 'BF': {
                    this.annotationSettings.isBestFit = true;
                    this.graphBuilder.clearManualCurvePoints();
                    break;
                }
                case 'MAL': {
                    this.viewDataService.scatterToleranceRangeValue = 0;
                    this.annotationSettings.isManualLinear = true;
                    this.graphBuilder.clearDistances();
                    this.manualCurveAdd();
                    break;
                }
                case 'MAS': {
                    this.viewDataService.scatterToleranceRangeValue = 0;
                    this.annotationSettings.isManualSmooth = true;
                    this.graphBuilder.clearDistances();
                    this.manualCurveAdd();
                    break;
                }
            }

            // Show buttons for manual curve; otherwise ensure zoom button is defaulted
            if (this.selectedCurve.shortName === 'MAL' || this.selectedCurve.shortName === 'MAS') {
                this.viewDataService.isCurveEditable = true;
                this.viewDataService.scatterMenuItem.next(MANUAL_CURVE);
            } else {
                this.viewDataService.isCurveEditable = false;
                this.viewDataService.scatterMenuItem.next(ACTIVE_ZOOM);
            }
        }

        changes.annotationSettings['currentValue'] = this.annotationSettings;
        this.updateAnnotationSettings.emit(this.annotationSettings);
        this.emitChange.emit(changes);
        if (!this.loadingSavedSettings) {
            this.updateToolData();
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public onRangeChanged(event?) {
        if (!this.loadingSavedSettings) {
            this.setLoading.emit(true);
        }
        if (event) {
            // fix for Bug #11314 updated to fetch exact selected range value from event
            if (event && event.value && event.value > 1) {
                this.viewDataService.scatterToleranceRangeValue = 1;
            } else if (event && event.value && event.value < 0) {
                this.viewDataService.scatterToleranceRangeValue = 0;
            } else {
                this.viewDataService.scatterToleranceRangeValue = Number.parseFloat(event.value);
            }
        }
        this.viewDataService.scatterToleranceRangeValue = Number.parseFloat(
            this.viewDataService.scatterToleranceRangeValue.toFixed(2),
        );
        this.rangeChanged.emit();
        if (!this.loadingSavedSettings) {
            this.updateToolData();
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }
    }

    public onGenerationOptionsChanged(event?) {
        this.setLoading.emit(true);
        if (event) {
            this.generationOption = Number.parseInt(event.value, 10);
        }
        this.emitGenerationOptionsChange.emit(this.generationOption);
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public hasPermission(type: 'basic' | 'advanced') {
        if (!this.userHasEditPermission || !this.currentCustomer || !this.customerHasBasicEditPermisstion) {
            return false;
        }

        if (type === 'basic') {
            return true;
        }

        return this.customerHasAdvancedEditPermission;
    }

    public onLockCurveChange(isLocked: boolean) {
        if (!isLocked) {
            this.updateCurve.emit(null);
        }
    }

    public ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }
}
