import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import {  FormArray, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'app/pages/admin/users.service';
import { LocationDetails } from 'app/shared/models/location-details';
import { TritonConfiguration } from 'app/shared/models/monitorsetting';
import {
    ForeSITEFlexSensors,
    NoneSensor,
} from '../../location-card.constants';
import { LocationCardService } from '../../services/location-card.service';
import { LocationCardData, SensorElement, TritonMonitor } from '../../models/location-card.model';

@Component({
    selector: 'app-foreSITEFlex-info',
    templateUrl: './foreSITEFlex-info.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})

export class foreSITEFlexInfoComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() data: LocationCardData;
    @Input() monitorConfigs: TritonConfiguration;
    @Input() details: LocationDetails[];
    @Output() changeSensor = new EventEmitter();
    @Output() foreSITEFlexSensorRemove = new EventEmitter();

    public foreSITEFlexSensors: SensorElement[] = [NoneSensor, ...JSON.parse(JSON.stringify(ForeSITEFlexSensors)).sort((a, b) => a.text.localeCompare(b.text))];

    public userHasPermissionOnEdit: boolean;
    public customerHasBasicEditPermission: boolean;
    public isMonitorEditor = false;
    public isMetric: boolean;

    constructor(
        private usersService: UsersService,
        public locationCardService: LocationCardService,
        private translateService: TranslateService,
    ) {}

    ngOnInit() {
        this.getMonitors().clearValidators();

        this.isMetric = this.locationCardService.isMetric;
        this.isMonitorEditor = this.usersService.isMonitorEditor.getValue();
        if (this.data.editMode && this.monitorConfigs && this.monitorConfigs.devices) {
            this.populateForm();
        }
    }

    private populateForm() {
        const sensorsList = this.foreSITEFlexSensors;
        let devices = this.monitorConfigs.devices.filter((v) => sensorsList.find((i) => v.name === i.value || v.name === i.altValue));

        const namesSensor = [];
        const altValueSensor = [];
        sensorsList.forEach(s => {
            namesSensor[s.value] = s.value;
            altValueSensor[s.altValue] = s.value;
        });

        devices = devices.map((v) => ({ ...v, name: namesSensor[v.name] || altValueSensor[v.name] }));
        
        this.form.patchValue({
            ...this.monitorConfigs,
            monitors: devices.map((v) => ({ ...v, sensor: v.name })),
        });

        const monitors = this.getMonitors();

        const monitorValues = monitors.getRawValue();
        monitorValues.forEach((v: TritonMonitor, i: 0 | 1) => {
            if (v.mp !== null) {
                const device = devices.find((x) => x.name === v.sensor);
                this.sensorChangeEvent(v.sensor, i, v.mp, true, device);
            }
        });
    }

    public sensorChangeEvent(name: string, monitorId: 0 | 1, mp: number, fromEdit = false, device = null) {

        if (!name) {
            this.onNoneSensorSelect(monitorId);
            return;
        }

        mp = mp === null || mp === undefined ? monitorId : mp;
        const list = this.foreSITEFlexSensors ;
        const sensor = list.find((v) => v.value === name);

        if (!sensor) return;

        this.changeSensor.emit({ name: sensor.text, monitorId, mp, device });
    }

    public getMonitors() {
        return this.form.get('monitors') as FormArray;
    }

    private onNoneSensorSelect(monitorId: 0 | 1) {
        this.foreSITEFlexSensorRemove.emit(monitorId);
        this.getMonitors().controls[monitorId].patchValue({ mp: null, channel: null });
    }
}