import { ElementRef, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocationCosmosDetails, LocationUIPipeTable } from '../models/location-details';
import { InstallationTypes } from '../models/locations';
import { PercentFullData } from '../models/percent-full';
import { ApiUnitsRow, UnitCategoryType } from '../models/units';
import { cloneDeep } from 'lodash';

const DRAW_PIPE_BACKGROUND_COLOR = 'White';
const DRAW_PIPE_DARK_BACKGROUND_COLOR = 'Black';

const DRAW_PIPE_COLOR = '#000000';
const DRAW_PIPE_DARK_COLOR = '#EEEEEE';

const DRAW_PIPE_SHAPE_STROKE = '#FF0000'
const DRAW_PIPE_FILL = '#0000FF66'

const DRAW_PIPE_NO_VOLUME_COLOR = '#0000FF';
const DRAW_PIPE_DARK_NO_VOLUME_COLOR = '#000066';

@Injectable()
export class DrawPipeHelper {

    translations = {
        unitText: null,
        noVolumeData: null
    }

    constructor(
        public translate: TranslateService
    ) { }

    public applyTranslations() {
        if(this.translations.unitText && this.translations.noVolumeData) return;

        this.translate.get([
            'COMMON.UNITS',
            'COMMON.NO_PIPE_VOLUME_DATA'
        ]).subscribe((translateValues) => {
            this.translations.unitText = translateValues['COMMON.UNITS'];
            this.translations.noVolumeData = translateValues['COMMON.NO_PIPE_VOLUME_DATA'];
        });
    }

    private backgroundColor(isDarkMode: boolean) {
        return isDarkMode ? DRAW_PIPE_DARK_BACKGROUND_COLOR : DRAW_PIPE_BACKGROUND_COLOR;
    }

    private foregroundColor(isDarkMode: boolean) {
        return isDarkMode ? DRAW_PIPE_DARK_COLOR : DRAW_PIPE_COLOR;
    }

    public drawPipe(canvasElement: ElementRef<HTMLCanvasElement>, res: LocationCosmosDetails, units: ApiUnitsRow[], isDarkMode: boolean, percentFull?: PercentFullData) {

        if (!canvasElement) return;
        this.applyTranslations();

        const linearUnitMetric = units.find(x => x.unitCategory === UnitCategoryType.Linear);
        const areaUnitMetric = units.find(x => x.unitCategory === UnitCategoryType.Area);

        const fontSize = 12; // font size
        const marginX = 50; // total margin
        const marginLeftX = 40; // margin at left of X axis
        const marginRightX = marginX - marginLeftX; // margin at right of X axis
        const textPadddingX = 5; // distance between text and grid on Y scale
        const marginY = 40; // total margin
        const marginBottomY = 30; // margin at top of Y axis
        const stepsCountY = 10; // number of value lines at Y axis
        const stepsCountX = 5; // number of HALF count of value lines at X axis
        const marginTopY = marginY - marginBottomY;
        const backgroundColor = this.backgroundColor(isDarkMode);
        const foregroundColor = this.foregroundColor(isDarkMode);

        const pipeDetails: LocationUIPipeTable[] = cloneDeep(res?.pipe?.e);

        const canvas = canvasElement.nativeElement;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = backgroundColor;
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.fillStyle = foregroundColor;
        ctx.font = `${fontSize}px serif`;
        const w = pipeDetails ? 400 : 0;
        const h = w;

        ctx.canvas.width = w;
        ctx.canvas.height = h;

        // We cannot ngIf pipeCanvas, otherwise it won't be accesible. Instead we change CSS dimensions.
        canvasElement.nativeElement.style.width = `${w}px`;
        canvasElement.nativeElement.style.height = `${h}px`;

        if(!pipeDetails) {
            return;
        }

        const cX = marginLeftX + (w - marginX) / 2;

        let maxX = Number.MIN_SAFE_INTEGER;
        let minX = Number.MAX_SAFE_INTEGER;
        let maxY = Number.MIN_SAFE_INTEGER;
        let minY = Number.MAX_SAFE_INTEGER;
        for(const e of pipeDetails) {
            /** They are stored in Feet, we have to multiply them by 12 first to convert it to inches.
                We need CHORDS to be in same metrics as DEPTHS to display them correctly. */
            e.x = (e.c * 12 * (linearUnitMetric.factor ? linearUnitMetric.factor : 1)) / 2;

            // Convert to customer metrics
            e.d = e.d * (linearUnitMetric.factor ? linearUnitMetric.factor : 1);
            e.a = e.a * (areaUnitMetric.factor ? areaUnitMetric.factor : 1);
            e.p = e.p * 12 * (linearUnitMetric.factor ? linearUnitMetric.factor : 1);
            e.c = e.c * 12 * (linearUnitMetric.factor ? linearUnitMetric.factor : 1);

            e.y = e.d;

            const x = e.x;
            const y = e.y;

            if(x > maxX) maxX = x;
            if(x < minX) minX = x;

            if(y > maxY) maxY = y;
            if(y < minY) minY = y;
        }

        maxX = maxX * 1.1;
        maxY = maxY * 1.1;

        // Margin for values, so they are not drawn on edge of chart
        const xValueMargin = ((w/2 - marginX) / maxX) / 10;
        const yValueMargin = ((h - marginY) / maxY) / 10;

        // Max value that should be draw on chart axis
        let maxYwithScale = 0;
        let maxXwithScale = 0;

        // Compute factors VALUE * FACTOR = PIXELS
        let factorX = ((w/2 - xValueMargin - marginX)/ maxX);
        let factorY = ((h - yValueMargin - marginY)/ maxY);
        if(factorX > factorY) {
            maxXwithScale = maxX * (factorX/factorY);
            maxYwithScale = maxY;

            factorX = factorY;
        } else {
            maxXwithScale = maxX;
            maxYwithScale = maxY * (factorY/factorX);

            factorY = factorX;
        }

        // Draw Y axis
        ctx.textAlign = 'end';
        const stepY = (h - marginY) / stepsCountY;
        const stepValueY = (maxYwithScale / stepsCountY);
        for(let y = 0; y <= stepsCountY; y++) {
            const val = y * stepValueY;
            const valStr = val > 100 ? Math.round(val) : Math.round(val * 10) / 10
            ctx.beginPath();
            ctx.fillStyle = foregroundColor;
            ctx.strokeStyle = foregroundColor;
            ctx.fillText(`${valStr}`, marginLeftX - textPadddingX, h - marginBottomY - y * stepY + fontSize / 3);
            ctx.moveTo(marginLeftX, h - marginBottomY - y * stepY);
            ctx.lineTo(w - marginRightX, h - marginBottomY - y * stepY);
            ctx.stroke();
            ctx.closePath();
        }

        // Draw X axis
        ctx.textAlign = 'center';
        const stepX = ((w - marginX) / 2) / stepsCountX;
        const stepValueX = stepValueY;
        ctx.beginPath();
        ctx.fillStyle = foregroundColor;
        ctx.strokeStyle = foregroundColor;
        ctx.fillText('0', cX, h - marginBottomY / 2);
        ctx.moveTo(cX, marginTopY);
        ctx.lineTo(cX, h - marginBottomY);
        ctx.stroke();
        ctx.closePath();
        for(let x = 1; x <= stepsCountX; x++) {
            const val = x * stepValueX;
            const valStr = val > 100 ? Math.round(val) : Math.round(val * 10) / 10;
            ctx.beginPath();
            ctx.fillStyle = foregroundColor;
            ctx.strokeStyle = foregroundColor;
            ctx.fillText(`-${valStr}`, cX - x * stepX, h - marginBottomY / 2);
            ctx.moveTo(cX - x * stepX, marginTopY);
            ctx.lineTo(cX - x * stepX, h - (marginY - marginTopY));
            ctx.stroke();
            ctx.closePath();

            ctx.beginPath();
            ctx.fillStyle = foregroundColor;
            ctx.strokeStyle = foregroundColor;
            ctx.fillText(`${valStr}`, cX + x * stepX, h - marginBottomY / 2);
            ctx.moveTo(cX + x * stepX, marginTopY);
            ctx.lineTo(cX + x * stepX, h - (marginY - marginTopY));
            ctx.stroke();
            ctx.closePath();
        }

        this.drawVolume(
            ctx,
            pipeDetails,
            factorX,
            factorY,
            cX,
            h,
            marginBottomY,
            isDarkMode,
            percentFull
        );


        // Draw Shape
        let lastX = pipeDetails[0].x;
        let lastY = pipeDetails[0].y;
        ctx.lineWidth = 2;
        ctx.strokeStyle = DRAW_PIPE_SHAPE_STROKE;

        // #24668, #25193 have to open and close shape
        ctx.beginPath();
        ctx.moveTo(cX - lastX * factorX, h - marginBottomY - lastY * factorY);
        ctx.lineTo(cX + lastX * factorX, h - marginBottomY - lastY * factorY);
        ctx.stroke();
        ctx.closePath();

        for(const e of pipeDetails) {
            const x = e.x;
            const y = e.y;

            ctx.beginPath();
            ctx.moveTo(cX - lastX * factorX, h - marginBottomY - lastY * factorY);
            ctx.lineTo(cX - x * factorX, h - marginBottomY - y * factorY);
            ctx.stroke();
            ctx.closePath();

            ctx.beginPath();
            ctx.moveTo(cX + lastX * factorX, h - marginBottomY - lastY * factorY);
            ctx.lineTo(cX + x * factorX, h - marginBottomY - y * factorY);
            ctx.stroke();
            ctx.closePath();


            lastX = x;
            lastY = y;

        }

        // #24668, #25193 have to open and close shape
        ctx.beginPath();
        ctx.moveTo(cX - lastX * factorX, h - marginBottomY - lastY * factorY);
        ctx.lineTo(cX + lastX * factorX, h - marginBottomY - lastY * factorY);
        ctx.stroke();
        ctx.closePath();

        if(this.isNoVolumeData(percentFull)) {
            this.drawNoVolumeData(ctx, cX, h, this.translations.noVolumeData, isDarkMode);
        }

        this.drawUnit(
            ctx,
            this.translations.unitText,
            linearUnitMetric,
            marginLeftX,
            marginTopY,
            isDarkMode
        )

        return pipeDetails;
    }

    public isNoVolumeData(percentFull: PercentFullData) {
        return percentFull && !percentFull.daysSpan && !percentFull.percentFullValues;
    }

    public averageVolumeData(percentFull): number {
        const percentSpans = (percentFull && percentFull.percentFullValues && percentFull.percentFullValues.length) ?
            percentFull.percentFullValues[percentFull.percentFullValues.length - 1]?.spans : null;
        const percentSpan = percentSpans ? percentSpans.find((ps) => ps.name === 'Custom') : null;
        return percentSpan ? percentSpan.average : null;
    }

    private drawVolume(
        ctx: CanvasRenderingContext2D,
        pipeDetails: LocationUIPipeTable[],
        factorX: number,
        factorY: number,
        cX: number,
        h: number,
        marginBottomY: number,
        isDarkMode: boolean,
        percentFull?: PercentFullData
    ) {
        if(!percentFull) return;

        if(!percentFull.daysSpan && !percentFull.percentFullValues) {
            return;
        }

        ctx.lineWidth = 2;
        ctx.strokeStyle = DRAW_PIPE_SHAPE_STROKE;
        ctx.fillStyle = DRAW_PIPE_FILL;

        let vol = 0;

        let lastX = pipeDetails[0].x;
        let lastY = pipeDetails[0].y;

        // Compute pipe volume
        for(const e of pipeDetails) {
            const x = e.x;
            const y = e.y;

            const rowVol = 2 * x * (y - lastY);

            vol += rowVol;

            lastX = x;
            lastY = y;
        }

        const totalVol = vol;

        const percentValue = this.averageVolumeData(percentFull);
        const curVol = percentValue ? (percentValue / 100) * totalVol : 0;
        vol = 0;

        lastX = pipeDetails[0].x;
        lastY = pipeDetails[0].y;

        // Fill pipe
        ctx.beginPath();
        ctx.moveTo(cX - lastX * factorX, h - marginBottomY - lastY * factorY);
        let i = 0;
        for(; i<pipeDetails.length; i++) {
            const e = pipeDetails[i];
            const x = e.x;
            const y = e.y;

            const rowVol = 2 * x * (y - lastY);
            vol += rowVol;
            if(vol < curVol) {
                ctx.lineTo(cX - x * factorX, h - marginBottomY - y * factorY);
            } else {
                break;
            }
            lastX = x;
            lastY = y;
        }
        for(; i>=0; i--) {
            const e = pipeDetails[i];
            const x = e.x;
            const y = e.y;

            ctx.lineTo(cX + x * factorX, h - marginBottomY - y * factorY);
            lastX = x;
            lastY = y;
        }
        ctx.fill();
        ctx.closePath();
    }

    private drawNoVolumeData(
        ctx: CanvasRenderingContext2D,
        cX: number,
        h: number,
        text: string,
        isDarkMode: boolean
    ) {

        const noDataFontSize = 20; // font size to print NO DATA text
        ctx.font = `${noDataFontSize}px serif`;

        ctx.lineWidth = 2;

        const w = ctx.measureText(text).width + 10;
        const fh = noDataFontSize * 1.5;

        ctx.fillStyle = this.backgroundColor(isDarkMode);
        ctx.strokeStyle = "#666666";
        ctx.fillRect(cX - w / 2, h / 2 - fh / 1.5, w, fh);
        ctx.strokeRect(cX - w / 2, h / 2 - fh / 1.5, w, fh);

        ctx.fillStyle = isDarkMode ? DRAW_PIPE_DARK_NO_VOLUME_COLOR : DRAW_PIPE_NO_VOLUME_COLOR;
        ctx.fillText(text, cX, h / 2);
    }

    private drawUnit(
        ctx: CanvasRenderingContext2D,
        labelText: string,
        linearUnitMetric: ApiUnitsRow,
        marginLeftX: number,
        marginTopY: number,
        isDarkMode: boolean
    ) {
        const unitFontSize = 16;
        ctx.font = `${unitFontSize}px serif`;

        ctx.lineWidth = 2;

        const text = labelText + ': ' + linearUnitMetric.unitSuffix;

        const textW = ctx.measureText(text).width + 10;
        const fh = unitFontSize * 1.5;

        ctx.fillStyle = this.backgroundColor(isDarkMode);
        ctx.strokeStyle = "#666666";
        ctx.fillRect(marginLeftX, marginTopY, textW, fh);
        ctx.strokeRect(marginLeftX, marginTopY, textW, fh);

        ctx.fillStyle = this.foregroundColor(isDarkMode);
        ctx.fillText(text, marginLeftX + textW / 2, marginTopY  + fh / 1.5);
    }
}
