import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';
@Injectable()
/**
 * Custom service for formatting datepicker input display values
 */
export class DateFormat extends NativeDateAdapter {
    public format(date: Date, displayFormat: Object): string {
        if (displayFormat === 'YYYY/MM/DD') {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return this._to2digit(year) + '/' + this._to2digit(month) + '/' + day;
        } else {
            return date.toDateString();
        }
    }

    /**
     * method for formatting datepicker parse values to check date validations
     */
    public parse(value: string): Date | null {
        if (value !== '') {
            const year = Number(value.split(' ')[3]);
            const month = this.getMonthInNumber(value.split(' ')[1]);
            const date = Number(value.split(' ')[2]);
            return new Date(year, month - 1, date);
        }
    }

    /**
     * Method used temporarily for getting month number from month name to support current date format like 'MON JAN 22 2018'
     * To be removed in future
     */
    private getMonthInNumber(month: string): number {
        switch (month) {
            case 'Jan':
                return 1;
            case 'Feb':
                return 2;
            case 'Mar':
                return 3;
            case 'Apr':
                return 4;
            case 'May':
                return 5;
            case 'Jun':
                return 6;
            case 'Jul':
                return 7;
            case 'Aug':
                return 8;
            case 'Sep':
                return 9;
            case 'Oct':
                return 10;
            case 'Nov':
                return 11;
            case 'Dec':
                return 12;

            default:
                return -1;
        }
    }

    private _to2digit(n: number) {
        return ('00' + n).slice(-2);
    }
}
