import { DataSource } from '@angular/cdk/collections';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { BehaviorSubject, merge } from 'rxjs';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Data source to provide what data should be rendered in the table. Note that the data source
 * can retrieve its data in any way. In this case, the data source is provided a reference
 * to a common data base, FilterDataSource. It is not the data source's responsibility to manage
 * the underlying data. Instead, it only needs to take the data and send the table exactly what
 * should be rendered.
 */
export class FilterDataSource extends DataSource<Object> {
    public filterChange = new BehaviorSubject('');
    public renderedData: Object[] = [];
    private subscriptions = new Array<Subscription>();

    /*
     * @sortedDataTable varibale to store current sorted data of table.
     */
    public sortedDataTable = new Array<Object>();

    constructor(
        private dataChange: BehaviorSubject<Object[]>,
        public dataTable: Object[],
        private paginator: MatPaginator,
        private sort: MatSort = new MatSort(),
        private columns: string[],
        private enableServerSidePaging = false,
        private dateFormat = '',
    ) {
        super();
        const filterChangeSuscription = this.filterChange.subscribe(() => {
            if (this.paginator && this.paginator.pageIndex === undefined) {
                this.paginator.pageIndex = 0;
            }
        });
        this.subscriptions.push(filterChangeSuscription);

        // Add subscription to dataChange to update dataTable
        const dataChangeSubscription = this.dataChange.subscribe(newData => {
            this.dataTable = newData;
        });
        this.subscriptions.push(dataChangeSubscription);
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    public connect(): Observable<Object[]> {
        const displayDataChanges = [
            this.dataChange,
            this.sort.sortChange,
            this.filterChange,
            this.paginator ? this.paginator.page : 0,
        ].filter((x) => x); // force no undefined in the merge

        return merge(...displayDataChanges).pipe(
            map(() => {
                // Sort filtered data
                const sortedData = this.sortDataTable(this.dataTable.slice(), this.columns);

                // Storing sorted data of table
                this.sortedDataTable = sortedData.slice();

                // Grab the page's slice of the filtered sorted data.
                if (!this.enableServerSidePaging && this.paginator) {
                    const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
                    this.renderedData = sortedData.splice(startIndex, this.paginator.pageSize);
                } else {
                    this.renderedData = sortedData;
                }
                return this.renderedData;
            }),
        );
    }

    public disconnect() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    /** Returns a sorted copy of the database data. */
    private sortDataTable(data: Object[], columns: string[]): Object[] {
        if (!this.sort.active || this.sort.direction === '') {
            return data;
        }
        let sortColumnName = '';
        if (this.sort.active) {
            const columnName = this.sort.active;
            columns.forEach(function (value) {
                if (value === columnName) {
                    sortColumnName = value;
                }
            });
        }

        return data.sort((a, b) => {
            const sort = this.sort.direction === 'asc' ? 1 : -1;
            let propertyA: number | string = '';
            let propertyB: number | string = '';
            if (this.sort.active) {
                [propertyA, propertyB] = [a[sortColumnName], b[sortColumnName]];
            }
            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            if (!valueA && !valueB) {
                return 0;
            } else if (!valueA && valueB) {
                return 1 * sort;
            } else if (valueA && !valueB) {
                return -1 * sort;
            }

            if (typeof valueA === 'string' && typeof valueB === 'string') {
                if (this.checkDates(valueA, valueB)) {
                    return this.sortDates(valueA, valueB) * sort;
                }
                if (sortColumnName === 'file_size') {
                    // Getting file size in bytes
                    const propertyAInBytes = this.sizeIntoBytes(propertyA);
                    const propertyBInBytes = this.sizeIntoBytes(propertyB);

                    // Comparing Bytes
                    return (propertyAInBytes < propertyBInBytes ? -1 : 1) * sort;
                }

                return (valueA.toUpperCase() < valueB.toUpperCase() ? -1 : 1) * sort;
            }

            return (valueA < valueB ? -1 : 1) * sort;
        });
    }

    private checkDates(a: string, b: string) {
        if (!this.dateFormat) return false;

        const monthDayFirstFormat = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
        const yearFirstFormat = /^\d{4}\/\d{1,2}\/\d{1,2}$/;

        if (
            (monthDayFirstFormat.test(a) && monthDayFirstFormat.test(b)) ||
            (yearFirstFormat.test(a) && yearFirstFormat.test(b))
        ) {
            return true;
        }

        return false;
    }

    private sortDates(a: string, b: string) {
        const aDate = new Date();
        const bDate = new Date();

        this.dateFormat.split('/').forEach((v, i) => {
            const aItem = Number(a.split('/')[i]);
            const bItem = Number(b.split('/')[i]);

            if (v === 'MM') {
                aDate.setMonth(aItem - 1);
                bDate.setMonth(bItem - 1);
            }

            if (v === 'dd') {
                aDate.setDate(aItem);
                bDate.setDate(bItem);
            }

            if (v === 'yyyy') {
                aDate.setFullYear(aItem);
                bDate.setFullYear(bItem);
            }
        });

        return aDate.getTime() - bDate.getTime();
    }

    /** Convert file size into bytes. */
    private sizeIntoBytes(sizeString) {
        // Split file size
        const s1Splitted = sizeString.split(' ');

        // Converting file size into bytes from KB, MB, GB or TB
        if (s1Splitted[1] === 'KB') {
            return s1Splitted[0] * 1024;
        } else if (s1Splitted[1] === 'MB') {
            return s1Splitted[0] * 1024 * 1024;
        } else if (s1Splitted[1] === 'GB') {
            return s1Splitted[0] * 1024 * 1024 * 1024;
        } else if (s1Splitted[1] === 'TB') {
            return s1Splitted[0] * 1024 * 1024 * 1024 * 1024;
        }
    }
}
