<app-loader [isLoading]="isLoading"></app-loader>

<div class="export-storm">
    <div class="export-item">
        <div class="export-desc">
            {{'SLIICER_TABLE.SLICER_SUMMARY.EXPORT_GRAPHS_DATA_DESCRIPTION' | translate}}
        </div>
        <div class="export-title">
            <button
                    class="mat-button min-width"
                    (click)="exportGraphsData()"
                    matTooltip="{{ 'SLIICER_TABLE.SLICER_SUMMARY.EXPORT_GRAPHS_DATA_TOOLTIP' | translate }}"
                    mat-button
                >
                    {{ 'SLIICER_TABLE.SLICER_SUMMARY.EXPORT_BTN' | translate }}
            </button>
        </div>
    </div>

</div>
