import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SliicerCaseStudy, BasinStormSettings } from 'app/shared/models/sliicer';
import { SNACK_BAR_NOTIFICATION_TIMEOUT } from 'app/shared/models/sliicer-data';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SelectionModel } from '@angular/cdk/collections';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { filter } from 'rxjs/operators';
import { DateutilService } from 'app/shared/services/dateutil.service';

const METRIC_UNITS = ['ha', 'm', 'mm-km'];
const OTHER_UNITS = ['ac', 'ft', ' in-mi'];

@Component({
    selector: 'ads-export-storm',
    templateUrl: './export-storm.component.html',
    styleUrls: ['./export-storm.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ExportStormComponent implements OnInit {
    private caseStudyId: string;
    private customerId: number;

    // constants
    public eliipsisTextLimit = 20;
    public stormColumns = ['stormId', 'stormDate', 'hasBasinExclusions'];

    public moreOrLessTxt: string;
    public isLoading: boolean;
    public stormToExport: Array<number> = [];
    public selection = new SelectionModel<any>(true, []);
    public stormDataSource = new MatTableDataSource([]);

    // translations
    public moreTxt: string;
    public lessTxt: string;
    private dismissMessage: string;
    public studyVaultName: string;
    public dismissText: string;
    public areaHeader: string;
    public lengthHeader: string;
    public footprintHeader: string;
    public latitudeHeader: string;
    public longitutdeHeader: string;
    public basinPropHeader: string;
    public basinRainfallDisHeader: string;
    public isMetric: boolean;
    public areaUnit: string;
    public lengthUnit: string;
    public footprintUnit: string;
    public geometryPrecision: number;
    public translateKeys: Array<string> = [
        'COMMON.DISMISS_TEXT',
        'VAULT.VAULT_TELEMETRY.EXPORT.EXPORT_ERR_SNACKBAR_MSG',
        'HOME.BASIN_PROPERTIES.AREA',
        'HOME.BASIN_PROPERTIES.SEWER_LENGTH',
        'HOME.BASIN_PROPERTIES.FOOT_PRINT',
        'HOME.BASIN_PROPERTIES.CENTROID_LATITUDE',
        'HOME.BASIN_PROPERTIES.CENTROID_LONGITUDE',
        'HOME.BASIN_PROPERTIES.BASIN_PROPERTIES',
        'HOME.BASIN_PROPERTIES.BASIN_RAINFALL_DISTRIBUTION',
    ];

    constructor(
        private sliicerService: SliicerService,
        private translate: TranslateService,
        private dateutilService: DateutilService,
        private snackBarNotificationService: SnackBarNotificationService,
        private translateService: TranslateService,
    ) {

        translate.get(this.translateKeys).subscribe((translateValues) => {
            this.dismissText = translateValues['COMMON.DISMISS_TEXT'];
            this.areaHeader = translateValues['HOME.BASIN_PROPERTIES.AREA'];
            this.lengthHeader = translateValues['HOME.BASIN_PROPERTIES.SEWER_LENGTH'];
            this.footprintHeader = translateValues['HOME.BASIN_PROPERTIES.FOOT_PRINT'];
            this.latitudeHeader = translateValues['HOME.BASIN_PROPERTIES.CENTROID_LATITUDE'];
            this.longitutdeHeader = translateValues['HOME.BASIN_PROPERTIES.CENTROID_LONGITUDE'];
            this.basinPropHeader = translateValues['HOME.BASIN_PROPERTIES.BASIN_PROPERTIES'];
            this.basinRainfallDisHeader = translateValues['HOME.BASIN_PROPERTIES.BASIN_RAINFALL_DISTRIBUTION'];

        });

    }

    public ngOnInit() {
        this.applyTranslations();
        this.moreOrLessTxt = this.moreTxt;

        this.isMetric = this.dateutilService.isCustomerUnitsMetric.getValue();
        this.areaUnit = this.isMetric ? METRIC_UNITS[0] : OTHER_UNITS[0];
        this.lengthUnit = this.isMetric ? METRIC_UNITS[1] : OTHER_UNITS[1];
        this.footprintUnit = this.isMetric ? METRIC_UNITS[2] : OTHER_UNITS[2];
        this.geometryPrecision = this.isMetric ? 1 : 2;


        this.sliicerService.caseStudyDetails
            .pipe(filter((data) => !!data))
            .subscribe((caseStudyDetails: SliicerCaseStudy) => {
                this.caseStudyId = caseStudyDetails.id;
                this.studyVaultName = caseStudyDetails.meta.studyVaultName;
                this.customerId = caseStudyDetails.customerId;
                const bss: BasinStormSettings[] =
                    caseStudyDetails.overrides && caseStudyDetails.overrides.basinStormSettings
                        ? caseStudyDetails.overrides.basinStormSettings
                        : [];
                this.loadStorms(caseStudyDetails.customerId, caseStudyDetails.id, bss);
            });
    }

    private loadStorms(customerId: number, caseStudyId: string, basinStormSettings: BasinStormSettings[]) {
        this.sliicerService.getStorms(customerId, caseStudyId).subscribe(
            (result) => {
                if (result && result.storms) {
                    const dataSource = result.storms.map((item) => {
                        const basinsName = this.getExcludedBasin(item.stormId, basinStormSettings);
                        return {
                            stormId: item.stormId,
                            stormDate: item.stormStartTime,
                            excludedBasin: basinsName,
                            excludedBasinCount: basinsName !== '' ? basinsName.split(',').length : 0,
                        };
                    });
                    this.stormDataSource = new MatTableDataSource(dataSource);
                } else {
                    this.stormDataSource = new MatTableDataSource([]);
                }
            },
            (error) => {
                this.stormDataSource = new MatTableDataSource([]);
            },
        );
    }

    // This method will return excluded basin name for specific storm id
    private getExcludedBasin(id: number, basinSettings: BasinStormSettings[]): string {
        let excludedBasin = [];
        if (basinSettings) {
            excludedBasin = basinSettings.filter((bs) => bs.stormId == id && bs.exclude).map((bs) => bs.basinName);
        }
        return excludedBasin.join(', ');
    }

    // This method set translation keywords
    private applyTranslations(): void {
        const translateKeys: Array<string> = [
            'COMMON.DISMISS_TEXT',
            'SLIICER_TABLE.SLICER_SUMMARY.RESULTS.EXPORT_STORMS_SAVE_MESSAGE',
            'SLIICER_TABLE.SLICER_SUMMARY.RESULTS.EXPORT_BASIN_SAVE_MESSAGE',
            'SLIICER_TABLE.SLICER_SUMMARY.RESULTS.EXPORT_BASIN_PROPS_MESSAGE',
            'SLIICER_TABLE.SLICER_SUMMARY.RESULTS.EXPORT_RAINFALL_DIS_MESSAGE',
            'SLIICER_TABLE.SLICER_SUMMARY.RESULTS.EXPORT_DRYDAYSTATS_SAVE_MESSAGE',
            'SLIICER_TABLE.SLICER_SUMMARY.RESULTS.EXPORT_DRY_DAY_HG_DATA_SAVE_MESSAGE',
            'SLIICER_TABLE.SLICER_SUMMARY.RESULTS.EXPORT_STORMS_ERROR_MESSAGE',
            'SLIICER_TABLE.SLICER_SUMMARY.RESULTS.MORE_TEXT',
            'SLIICER_TABLE.SLICER_SUMMARY.RESULTS.LESS_TEXT',
        ];

        this.translateService.get(translateKeys).subscribe((values) => {
            this.dismissMessage = values['COMMON.DISMISS_TEXT'];
            this.moreTxt = values['SLIICER_TABLE.SLICER_SUMMARY.RESULTS.MORE_TEXT'];
            this.lessTxt = values['SLIICER_TABLE.SLICER_SUMMARY.RESULTS.LESS_TEXT'];
        });
    }

    // selecting export event date on checkbox click
    public selectStormDateToExport(event, storm): void {
        if (event['checked']) {
            this.stormToExport.push(storm.stormId);
        } else {
            if (this.stormToExport && this.stormToExport.indexOf(storm.stormId) !== -1) {
                this.stormToExport.splice(this.stormToExport.indexOf(storm.stormId), 1);
            }
        }
    }

    public isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.stormDataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    public masterToggle(event) {
        if (!event) {
            return;
        }
        if (this.isAllSelected()) {
            this.selection.clear();
            this.stormToExport.length = 0;
        } else {
            if (this.stormDataSource && this.stormDataSource.data) {
                this.stormDataSource.data.forEach((row) => {
                    this.selection.select(row);
                    if (this.stormToExport && this.stormToExport.indexOf(row.stormId) === -1) {
                        this.stormToExport.push(row.stormId);
                    }
                });
            }
        }
    }

    /** The label for the checkbox on the passed row */
    public checkboxLabel(row): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.stormId + 1}`;
    }

    public exportGraphsData() {
        const successMessage = this.translate.instant('SLIICER_TABLE.SLICER_SUMMARY.RESULTS.EXPORT_GRAPHS_DATA_SAVE_MESSAGE');
        const errorMessage = this.translate.instant('SLIICER_TABLE.SLICER_SUMMARY.RESULTS.EXPORT_GRAPHS_DATA_ERROR_MESSAGE')

        this.isLoading = true;
        this.sliicerService.exportGraphsData(this.customerId, this.caseStudyId).subscribe(
            (results) => {
                this.isLoading = false;
                this.snackBarNotificationService.raiseNotification(
                    successMessage,
                    this.dismissMessage,
                    {
                        duration: SNACK_BAR_NOTIFICATION_TIMEOUT,
                    },
                    true,
                );
            },
            (err) => {
                this.isLoading = false;
                this.snackBarNotificationService.raiseNotification(
                    errorMessage,
                    this.dismissMessage,
                    {
                        panelClass: 'custom-error-snack-bar',
                    },
                    false,
                );
            },
        );

    }

    public toggleEllipses(evt): void {
        evt.stopPropagation();
        const elipsisTextEle = evt.currentTarget.previousElementSibling.previousElementSibling;
        const moreTextEle = evt.currentTarget.previousElementSibling;
        if (elipsisTextEle && elipsisTextEle.className.indexOf('ellipsis-text') !== -1) {
            elipsisTextEle.className = '';
            this.moreOrLessTxt = this.lessTxt;
            moreTextEle.className = 'hide-count';
        } else {
            elipsisTextEle.className = 'ellipsis-text';
            this.moreOrLessTxt = this.moreTxt;
            moreTextEle.className = 'show-count';
        }
    }
}
