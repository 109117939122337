import { DOCUMENT } from '@angular/common';
import { Injectable, ChangeDetectorRef, Inject } from '@angular/core';
import { ViewRef } from '@angular/core/';
import { LocationGroupSelector } from '../models/location-group';

@Injectable()
export class UiUtilsService {
    public locationGroupID = 0;

    constructor(@Inject(DOCUMENT) private document: Document) {}

    public reserveColors = ['#FFAD26', '#FFFF00', '#FF0000', '#1F1F9C'];

    private isCdRefSetUp(cdRef: ChangeDetectorRef) {
        return cdRef !== null && cdRef !== undefined && !(cdRef as ViewRef).destroyed;
    }
    /**
     * This method is used to reolved 'ViewDestroyedError' issue
     * @param cdRef
     */
    public safeChangeDetection(cdRef: ChangeDetectorRef) {
        // Programmatically run change detection to fix issue in Safari
        if (this.isCdRefSetUp(cdRef)) {
            cdRef.detectChanges();
        }
    }

    public safeMarkForCheck(cdRef: ChangeDetectorRef) {
        if (this.isCdRefSetUp(cdRef)) {
            cdRef.markForCheck();
        }
    }

    public sortSimilarLocations(location1, location2) {
        if (location1.locationName && location2) {
            if (
                typeof (location1.locationName || location1.locname || location1.name) === 'string' &&
                typeof (location2.locationName || location2.locname || location2.name) === 'string'
            ) {
                return (location1.locationName.toLowerCase() ||
                    location1.locname.toLowerCase() ||
                    location1.name.toLowerCase()) <
                    (location2.locationName.toLowerCase() ||
                        location2.locname.toLowerCase() ||
                        location2.name.toLowerCase())
                    ? -1
                    : 1;
            } else {
                return 0;
            }
        }
    }

    public sortLocations = (location1, location2) => {
        const locName1 = (location1.locationName || location1.locname || location1.name || '').toLowerCase();
        const locName2 = (location2.locationName || location2.locname || location2.name || '').toLowerCase();
        if (locName1 > locName2) {
            return 1;
        } else if (locName1 < locName2) {
            return -1;
        } else {
            return this.sortSimilarLocations(location1, location2);
        }
    };

    public calculateFontColor(color: string) {
        if(!color || color.length !== 7) return 'white';

        // Randomly update colors
        const rgb = [
            parseInt(color.substring(1,3),16),
            parseInt(color.substring(3,5),16),
            parseInt(color.substring(5,7),16)
        ];

        // https://www.w3.org/TR/AERT/#color-contrast
        const brightness =
            Math.round((
                (rgb[0] * 299) +
                (rgb[1] * 587) +
                (rgb[2] * 114)
            ) / 1000);
        return (brightness > 125) ? 'black' : 'white';
    }

    scrollEnable() {
        this.document.documentElement.style.setProperty('overflow', 'auto');
    }

    scrollDisable() {
        this.document.documentElement.style.setProperty('overflow', 'hidden');
    }
}
