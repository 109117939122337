<div class="storm-expanded-table-container loaderContainer">
    <app-loader [isLoading]="isLoading"></app-loader>
    <div class="storm-header">
        <span>
            <mat-icon>thunderstorm</mat-icon>
        </span>
        <span>
            {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_EVENTS' | translate }}
        </span>
        <span>
            <mat-form-field>
                <mat-select
                    [(ngModel)]="QvsiGroup"
                    [disabled]="!isOwnStudy || isStudyLocked"
                    (selectionChange)="qvsiGroupSelect(QvsiGroup, true)"
                >
                    <mat-option value="All Storms">All Storms</mat-option>
                    <mat-option
                        *ngFor="let item of originalQvsIConfigurations"
                        [value]="item.name"
                    >{{item.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </span>
        <span>
            <i
                class="material-icons inverted-icon zoom-out"
                (click)="expandChooserFull()"
            >
                fullscreen_exit
            </i>
        </span>
    </div>
    <div id="stormTable">

        <mat-table
            #table
            [dataSource]="stormDataSource"
            matSort
        >
            <ng-container matColumnDef="color">
                <mat-header-cell *matHeaderCellDef>{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.COLOR' | translate }}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    <div *ngIf="!element.stormExists"
                        class="not-existing-storm-box"
                    ></div>
                    <div *ngIf="element.stormExists && element.isExcluded"
                        class="excluded-storm-box"
                    ></div>
                    <div *ngIf="element.stormExists && !element.isExcluded"
                        class="box"
                        [ngStyle]="{'background-color': element.color}"
                    ></div>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="group">
                <mat-header-cell *matHeaderCellDef>{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.QvsiGroup' | translate }}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    <span *ngIf="element.stormExists && !element.isExcluded">{{element.qvsiGroup}}</span>

                    <span
                        *ngIf="!element.stormExists"
                        matTooltip="{{ 'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.REMOVED_STORM_TOOLTIP' | translate }}"
                        matTooltipPosition="above"
                        matTooltipClass="removed-storm-tooltip"><b>{{ 'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.REMOVED' | translate }}</b></span>
                    </mat-cell>
            </ng-container>
            <ng-container matColumnDef="stormStartTime">
                <mat-header-cell *matHeaderCellDef>
                    <!-- <div class="headerContent withIcon right">
                         {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_EVENT' | translate }}
                        <mat-icon
                            [disabled]="isStudyLocked"
                            [ngClass]="{ disabledAction: isStudyLocked || !stormEvents || stormEvents.length == 0 }"
                            [matTooltip]="'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.BUTTON_TOOLTIP' | translate"
                            (click)="!stormEvents || stormEvents.length == 0 || editStormEvents()"
                        >create</mat-icon>
                    </div> -->
                    {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.Storm_Date_Time' | translate }}
                </mat-header-cell>
                <mat-cell
                    (click)="!element.stormId || setSelectedStorm(element.stormId)"
                    class="noWrap"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                    *matCellDef="let element"
                >
                    {{ element.stormStartTime | date: customDateFormat }}
                    <!-- {{ element.labelExtra }} -->
                    <!-- <ng-container *ngIf="element.statusTag">
                        <span>{{
                            'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.STORM_EVENT_LABELS.' + element.statusTag.toUpperCase()
                            | translate
                            }}</span>
                        <span
                            class="unsaved"
                            *ngIf="!element.saved"
                        >({{ 'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.STORM_EVENT_LABELS.UNSAVED' | translate }})</span>
                    </ng-container> -->
                </mat-cell>
            </ng-container>
            <!-- US #30401 TASK #30466 SLiiCER - Deleting and adding a storm features - DELETE this functionality from the UI -->
            <!-- <mat-header-cell *matHeaderCellDef class="custom-strom"></mat-header-cell> -->
            <!-- <ng-container matColumnDef="stormId">
                <mat-header-cell *matHeaderCellDef>X</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    <mat-checkbox
                        [checked]="element.isExcluded"
                        [disabled]="isStudyLocked || !element.stormId"
                        (change)="toggleStormBasinExclusion($event, element.stormId)"
                    >
                    </mat-checkbox>
                </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="RainPK">
                <mat-header-cell *matHeaderCellDef>{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.RainPK' | translate }} ({{unitPerHour}})</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.RainPK | number: unitsPrecision[units.depthUnit]}}
                </mat-cell>
            </ng-container>

            <!-- coluns are hidden by US 31871 -->
            <!-- <ng-container matColumnDef="rollingPeakRain">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.rollingPeakRain' | translate }} {{units.depthUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.rollingPeakRain}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="rollingRainPeakTime">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.rollingRainPeakTime' | translate }}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.rollingRainPeakTime | date: customDateFormat }}
                </mat-cell>
            </ng-container> -->

            <ng-container matColumnDef="rainTotal">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.totalRain' | translate }} {{units.depthUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.rainTotal | number: unitsPrecision[units.depthUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="preCtype">
                <mat-header-cell *matHeaderCellDef>{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.preCtype' | translate }}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.preCtype}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="preComp">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.precompAdjust' | translate }} {{units.sliicerFlowUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    <span *ngIf="element.preCtype === PrecompensationType.None">0</span>
                    <span *ngIf="element.preCtype === PrecompensationType.Constant">{{ element.preComp | number: unitsPrecision[units.sliicerFlowUnit] }}</span>
                    <span *ngIf="element.preCtype !== PrecompensationType.Constant && element.preCtype !== PrecompensationType.None">-</span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="durStorm">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.stormDuration' | translate }} (min)</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.durStorm}}
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="stormRain">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.stormRain' | translate }} {{units.depthUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.rainStorm | number: unitsPrecision[units.depthUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="groQPkSto">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.grossQPeakStorm' | translate }} {{units.sliicerFlowUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.groQPkSto | number: unitsPrecision[units.sliicerFlowUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="groQVolSto">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.grossQVolumeStorm' | translate }} {{units.sliicerVolumeUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.groQVolSto | number: unitsPrecision[units.sliicerVolumeUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="groIIPkSto">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.grossIIPeakStorm' | translate }} {{units.sliicerFlowUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.groIIPkSto | number: unitsPrecision[units.sliicerFlowUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="groIIVolSto">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.grossIIVolumeStorm' | translate }} {{units.sliicerVolumeUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.groIIVolSto | number: unitsPrecision[units.sliicerVolumeUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="netIIPkSto">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.netIIPeakStorm' | translate }} {{units.sliicerFlowUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.netIIPkSto | number: unitsPrecision[units.sliicerFlowUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="netIIVolSto">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.netIIVolumeStorm' | translate }}{{units.sliicerVolumeUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.netIIVolSto | number: unitsPrecision[units.sliicerVolumeUnit]}}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="eventDuration">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.rainEventDuration' | translate }} (min)</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.eventDuration}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="eventRainfall">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.eventRain' | translate }} {{units.depthUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.eventRainfall | number: unitsPrecision[units.depthUnit]}}
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="groQPkEvt">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.grossQPeakEvent' | translate }} {{units.sliicerFlowUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.groQPkEvt | number: unitsPrecision[units.sliicerFlowUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="groQVolEvt">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.grossQVolumeEvent' | translate }} {{units.sliicerVolumeUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.groQVolEvt | number: unitsPrecision[units.sliicerVolumeUnit]}}
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="groIIPkEvt">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.grossIIPeakEvent' | translate }} {{units.sliicerFlowUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.groIIPkEvt | number: unitsPrecision[units.sliicerFlowUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="groIIVolEvt">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.grossIIVolumeEvent' | translate }} {{units.sliicerVolumeUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.groIIVolEvt | number: unitsPrecision[units.sliicerVolumeUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="netIIPkEvt">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.netIIPeakEvent' | translate }} {{units.sliicerFlowUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.netIIPkEvt | number: unitsPrecision[units.sliicerFlowUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="netIIVolEvt">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.netIIVolumeEvent' | translate }} {{units.sliicerVolumeUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.netIIVolEvt | number: unitsPrecision[units.sliicerVolumeUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="groQPkR1">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.grossQPeakR1' | translate }}{{units.sliicerFlowUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.groQPkR1 | number: unitsPrecision[units.sliicerFlowUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="groQVolR1">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.grossQVolumeR1' | translate }} {{units.sliicerVolumeUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.groQVolR1 | number: unitsPrecision[units.sliicerVolumeUnit]}}
                </mat-cell>
            </ng-container>


            <ng-container matColumnDef="groIIPkR1">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.grossIIPeakR1' | translate }}{{units.sliicerFlowUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.groIIPkR1 | number: unitsPrecision[units.sliicerFlowUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="groIIVolR1">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.grossIIVolumeR1' | translate }} {{units.sliicerVolumeUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.groIIVolR1 | number: unitsPrecision[units.sliicerVolumeUnit]}}
                </mat-cell>
            </ng-container>



            <ng-container matColumnDef="netIIPkR1">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.netIIPeakR1' | translate }} {{units.sliicerFlowUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.netIIPkR1 | number: unitsPrecision[units.sliicerFlowUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="netIIVolR1">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.netIIVolumeR1' | translate }} {{units.sliicerVolumeUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.netIIVolR1 | number: unitsPrecision[units.sliicerVolumeUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="groQPkR2">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.grossQPeakR2' | translate }} {{units.sliicerFlowUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.groQPkR2 | number: unitsPrecision[units.sliicerFlowUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="groQVolR2">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.grossQVolumeR2' | translate }} {{units.sliicerVolumeUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.groQVolR2 | number: unitsPrecision[units.sliicerVolumeUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="groIIPkR2">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.grossIIPeakR2' | translate }} {{units.sliicerFlowUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.groIIPkR2 | number: unitsPrecision[units.sliicerFlowUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="groIIVolR2">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.grossIIVolumeR2' | translate }} {{units.sliicerVolumeUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.groIIVolR2 | number: unitsPrecision[units.sliicerVolumeUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="netIIPeakR2">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.netIIPeakR2' | translate }} {{units.sliicerFlowUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.netIIPeakR2 | number: unitsPrecision[units.sliicerFlowUnit]}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="netIIVolR2">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_CHOOSER.netIIVolumeR2' | translate }} {{units.sliicerVolumeUnit}}</mat-header-cell>
                <mat-cell
                    *matCellDef="let element"
                    [class.activeStormDateRow]="element.stormId == selectedStorm.stormId"
                >
                    {{element.netIIVolR2 | number: unitsPrecision[units.sliicerVolumeUnit]}}
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="stormColumns"></mat-header-row>
            <mat-row
                *matRowDef="let row; columns: stormColumns"
                (click)="!row.stormId || setSelectedStorm(row.stormId)"
                [ngClass]="row.statusTag ? row.statusTag + 'StormEvent' : ''"
            >
            </mat-row>
        </mat-table>
    </div>
    <div class="auto-margin">
        <mat-paginator
            [hidden]="paginationMaxLength > pageSize "
            [length]="paginationMaxLength"
            [pageSize]="pageSize"
            [pageSizeOptions]="[5, 10, 15]"
            showFirstLastButtons
        ></mat-paginator>
    </div>
</div>
