<div class="location-files">
    <app-loader [isLoading]="isLoading"></app-loader>
    <div class="dateRangeHeader">
        <div class="dateRangeText auto-margin" *ngIf="!(breadcrumbDirectories && breadcrumbDirectories.length > 0)">
            {{ filesText }}
        </div>
        <div
            class="dateRangeText breadcrumb auto-margin"
            *ngIf="breadcrumbDirectories && breadcrumbDirectories.length > 0"
        >
            <span class="cursor-pointer" (click)="selectEachDirectory(rootPath)">{{ fileText }}/</span>
            <ng-container *ngFor="let file of breadcrumbDirectories">
                <span class="cursor-pointer" (click)="selectEachDirectory(file?.id)">{{ file.name }}/</span>
            </ng-container>
        </div>

        <div class="app-flex-filler"></div>
        <div class="auto-margin custom-flat-button">
            <button class="edit upload-file-hint" mat-button (click)="openUploadDialog($event)">
                {{ 'CUSTOMER_SUPPORT.UPLOAD_FILE' | translate }}
            </button>
        </div>
    </div>
    <mat-card-content>
        <div class="vault-folder-file">
            <div class="vault-create-folder">
                <button class="vaultCreateFolderHint create-folder-hint" mat-button (click)="createFolder()">
                    {{ 'VAULT.CREATE_FOLDER_BUTTON' | translate }}
                </button>
            </div>
            <mat-accordion class="folder-accordion">
                <mat-expansion-panel
                    [expanded]="!containingSubDirectories || containingSubDirectories.length === 0"
                    class="show-hide-subfolders-hint"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{ 'COMMON.FOLDERS_TEXT' | translate }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="vault-folder-list">
                        <div class="each-file" *ngFor="let file of containingSubDirectories">
                            <button mat-flat-button class="folder-name" (click)="selectEachDirectory(file?.id)">
                                <mat-icon>folder</mat-icon> {{ file.name }}
                            </button>
                            <button
                                mat-button
                                [disabled]="!isVaultDeletePermission"
                                matTooltip="{{ 'COMMON.DELETE_TOOLTIP' | translate }}"
                                (click)="deleteFile($event, file?.id, file.name, true)"
                            >
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                        <div
                            *ngIf="!containingSubDirectories || containingSubDirectories.length === 0"
                            class="notabledataFound"
                        >
                            <span>{{ 'VAULT.VAULT_DASHBOARD.NO_FOLDER_EXISTS' | translate }}</span>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <div class="vault-file-list">
                <div class="header-divider margin-b-10">
                    <div class="auto-margin header-text">{{ 'COMMON.FILES_TEXT' | translate }}</div>
                    <div class="auto-margin app-flex-filler"></div>
                </div>
                <div class="vault-file-preview">
                    <div class="each-file" *ngFor="let file of vaultDirectoryData; let i = index">
                        <div class="file-preview" matTooltip="{{ file.fileName }}">
                            <div
                                class="file-type-big-icon-default file-type-big-icon-{{
                                    file.fileExtension.toString().toLowerCase()
                                }} cursor-not-allowed"
                                *ngIf="file.previewType === 'default'"
                            ></div>
                            <div
                                class="file-type-big-icon-default file-type-big-icon-{{
                                    file.fileExtension.toString().toLowerCase()
                                }} cursor-pointer"
                                (click)="fileSelected($event, file.fileExtension, file.fileName, file.uri, true)"
                                *ngIf="file.previewType === 'txt'"
                            ></div>
                            <div
                                class="file-type-big-icon-default file-type-big-icon-{{
                                    file.fileExtension.toString().toLowerCase()
                                }} cursor-pointer"
                                (click)="
                                    fileSelected(
                                        $event,
                                        file.fileExtension,
                                        file.fileName,
                                        file.uri,
                                        true,
                                        file.absoluteUri
                                    )
                                "
                                *ngIf="file.previewType === 'img'"
                            >
                            </div>
                        </div>

                        <div class="file-name">
                            <span class="name-text">{{ file.fileName }} </span>
                        </div>
                        <div class="file-action">
                            <button
                                mat-button
                                [ngClass]="{ 'file-share-hint': i === 0 }"
                                matTooltip="{{ 'VAULT.SHARE_TEXT' | translate }}"
                                (click)="shareFile($event, file.uri, file.fileName)"
                            >
                                <mat-icon>share</mat-icon>
                            </button>
                            <button
                                mat-button
                                [ngClass]="{ 'file-download-hint': i === 0 }"
                                matTooltip="{{ 'COMMON.DOWNLOAD_BTN' | translate }}"
                                (click)="fileDownload([file.uri])"
                            >
                                <mat-icon>file_download</mat-icon>
                            </button>
                            <button
                                mat-button
                                disabled
                                [ngClass]="{ 'file-import-hint': i === 0 }"
                                matTooltip="{{ 'VAULT.IMPORT_TEXT' | translate }}"
                                class="import-option"
                                *ngIf="!file.isPotentialTelemetryFile && !file.isTelogFile && !file.isExcelFile"
                            >
                                <mat-icon>import_export</mat-icon>
                            </button>
                            <button
                                mat-button
                                matTooltip="{{ 'VAULT.IMPORT_TEXT' | translate }}"
                                class="import-option"
                                [ngClass]="{ 'file-import-hint': i === 0 }"
                                *ngIf="file.isPotentialTelemetryFile && !file.isTelogFile"
                                (click)="importTelemetryData($event, file)"
                            >
                                <mat-icon>import_export</mat-icon>
                            </button>
                            <button
                                mat-button
                                matTooltip="{{ 'VAULT.IMPORT_TEXT' | translate }}"
                                class="import-option"
                                [ngClass]="{ 'file-import-hint': i === 0 }"
                                *ngIf="!file.isPotentialTelemetryFile && file.isTelogFile"
                                (click)="importTelogData($event, file)"
                            >
                                <mat-icon>import_export</mat-icon>
                            </button>
                            <button
                                mat-button
                                matTooltip="{{ 'VAULT.IMPORT_TEXT' | translate }}"
                                [ngClass]="{ 'file-import-hint': i === 0 }"
                                class="import-option"
                                *ngIf="file.isExcelFile"
                                (click)="importCustomPipe($event, file)"
                            >
                                <mat-icon>import_export</mat-icon>
                            </button>
                            <button
                                mat-button
                                [ngClass]="{ 'file-delete-hint': i === 0 }"
                                [disabled]="!isVaultDeletePermission"
                                matTooltip="{{ 'COMMON.DELETE_TOOLTIP' | translate }}"
                                (click)="deleteFile($event, file.uri, file.fileName, false)"
                            >
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="!vaultDirectoryData || vaultDirectoryData.length === 0" class="notabledataFound">
                        <span>{{ 'VAULT.VAULT_DASHBOARD.NO_FILE_EXISTS' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</div>
