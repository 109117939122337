import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { DateutilService } from 'app/shared/services/dateutil.service';

/*
 * Raise the date user preferably
 * Takes an argument i.e; dateformat ('YYYY/MM/DD', 'YYYY/DD/MM')
 * Usage:
 *   value | anyDate
 * Example:
 *   {{ 2019-05-08T00:00:00 | 'YYYY/MM/DD' }}
 *   formats to user preference (DD/MM/YYYY): 08/05/2019
 */
@Pipe({
    name: 'adsDate',
})
export class AdsDatePipe implements PipeTransform {
    constructor(private dateutil: DateutilService) {}

    public transform(value: number | string | Date | moment.Moment, currentFormat: string) {
        const date = '';
        if (value && currentFormat) {
            const dateInTimeStamp = moment(value, currentFormat).valueOf();
            return this.dateutil.getSystemPreferenceDate(dateInTimeStamp);
        }
        return date;
    }
}
