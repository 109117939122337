<div class="edit-graph-filter-section" *ngIf="!isPrinting && data && (isDataEditingModeEnabled || annotationSettings?.isToolbarEnabled)">
    <div class="bestfit-option">
        <div class="pre-edit-controls auto-margin-t-b" [ngClass]="{ 'items-on-hint': selectedHintPageName === 'locationDashboardSecondHint' || selectedHintPageName === 'location-dashBoard-edit-sg-hint'}">
            <span matTooltip="{{ 'COMMON.SELECT_DATA' | translate }}">
                <button
                    mat-button
                    class="lightBackGround icon-border select-data-hint sg-select-hint"
                    type="button"
                    (click)="viewDataService.scatterMenuItem.next('activeDataFlag')"
                    [ngClass]="{ active: (viewDataService.scatterMenuItem | async) === 'activeDataFlag' }"
                    [disabled]="viewDataService.scatterToleranceRangeValue === 0 || !isDataEditingModeEnabled ||
                    (
                        (annotationSettings.isManualLinear || annotationSettings.isManualSmooth)
                        && (viewDataService.scatterManualCurveSelectedPoints | async)?.length < 2
                        && !annotationSettings.isBestFit && !annotationSettings.isSSCurve
                    )"
                >
                    <mat-icon class="mat-18 icon-rotate">arrow_back</mat-icon>
                </button>
            </span>
            <span matTooltip="{{ 'COMMON.CLEAR_DATA' | translate }}">
                <button
                    mat-button
                    class="lightBackGround custom-eraser scattergraph-clear-selection-hint sg-clear-hint"
                    [ngClass]="{ active: (viewDataService.scatterMenuItem | async) === 'activeClearFlag' }"
                    type="button"
                    [disabled]="!isDataSelected || !isDataEditingModeEnabled"
                    (click)="viewDataService.scatterMenuItem.next('activeClearFlag')"
                ></button>
            </span>
            <button
                mat-button
                class="lightBackGround zoom-data-hint sg-zoom-hint"
                type="button"
                matTooltip="{{ 'COMMON.ZOOM' | translate }}"
                (click)="viewDataService.scatterMenuItem.next('activeZoomFlag')"
                [ngClass]="{ active: (viewDataService.scatterMenuItem | async) === 'activeZoomFlag' }"
            >
                <mat-icon class="mat-18">zoom_in</mat-icon>
            </button>
            <button
                mat-button
                class="lightBackGround scattergrph-flag-data-hint sg-flag-hint"
                type="button"
                [disabled]="viewDataService.isCurveEditable"
                [ngClass]="{ active: (viewDataService.scatterMenuItem | async) === 'activeFlagPoint' }"
                matTooltip="{{ 'LOCATION_DASHBOARD.SCATTERGRAPH_DETAIL_VIEW.FLAG_POINT' | translate }}"
                (click)="viewDataService.scatterMenuItem.next('activeFlagPoint')"
            >
                <mat-icon class="mat-18">visibility_off</mat-icon>
            </button>
            <button
                mat-button
                class="lightBackGround snap-select-hint sg-unflag-hint"
                type="button"
                [disabled]="viewDataService.isCurveEditable"
                [ngClass]="{ active: (viewDataService.scatterMenuItem | async) === 'activeUnflagPoint' }"
                matTooltip="{{ 'LOCATION_DASHBOARD.SCATTERGRAPH_DETAIL_VIEW.UNFLAG_POINT' | translate }}"
                (click)="viewDataService.scatterMenuItem.next('activeUnflagPoint')"
            >
                <mat-icon class="mat-18">visibility</mat-icon>
            </button>
            <button
                *ngIf="viewDataService.lockSGCurve as isLocked"
                mat-button
                class="lightBackGround scattergrph-flag-data-hint sg-block-hint"
                type="button"
                [disabled]="viewDataService.isCurveEditable"
                [matTooltip]="isLocked.value ? 'Click to regenerate curve' : 'Click to lock this curve'"
                (click)="isLocked.next(!isLocked.value); onLockCurveChange(isLocked.value)"
            >
                <img [src]="isLocked.value ? '/assets/images/icons/lock.svg' : '/assets/images/icons/lock_open.svg'" />
            </button>
            <span matTooltip="{{ 'COMMON.SNAP_SELECT_CURVE' | translate }}">
                <button
                    mat-button
                    class="lightBackGround custom-apply snap-curve-hint sg-snap-hint"
                    type="button"
                    (click)="applySnapToCurve()"
                    [disabled]="
                        !isDataEditingModeEnabled ||
                        !isDataSelected ||
                        viewDataService.scatterToleranceRangeValue === 0 ||
                        !hasPermission('advanced')
                    "
                ></button>
            </span>
        </div>
        <div class="divider margin-r-10"></div>
        <!-- Buttons for manual curve options here. Only show when manual curve options selected -->
        <div *ngIf="viewDataService.isCurveEditable" class="pre-edit-controls auto-margin-t-b">
            <span matTooltip="{{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SG_MANUAL_CURVE_ADD_POINTS' | translate }}">
                <button mat-button type="button"
                    class="lightBackGround icon-border select-data-hint sg-select-hint"
                    [ngClass]="{ active: (viewDataService.scatterMenuItem | async) === 'manualCurveAdd' }"
                    (click)="manualCurveAdd()"
                >
                    <mat-icon class="mat-18">insights</mat-icon>
                </button>
            </span>
            <span matTooltip="{{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.SG_MANUAL_CURVE_CLEAR_POINTS' | translate }}">
                <button mat-button type="button"
                    class="lightBackGround icon-border select-data-hint sg-select-hint"
                    (click)="manualCurveClear()"
                >
                    <mat-icon class="mat-18">remove_circle_outline</mat-icon>
                </button>
            </span>
        </div>
        <div *ngIf="viewDataService.isCurveEditable" class="divider margin-r-10"></div>
        <!-- End manual curve options buttons -->
        <div class="auto-margin-t-b margin-r-10 pad-t-5"
            [ngClass]="{ 'ml-200': ((selectedHintPageName === 'locationDashboardSecondHint' || selectedHintPageName === 'location-dashBoard-edit-sg-hint') && sepWinHG.isNewWindowOpened) }" style="width: 60px !important">
            <mat-form-field class="customFormWidth sg-curve-hint" style="width: 60px !important">
                <mat-select placeholder="{{ 'COMMON.CURVE' | translate }}" [value]="selectedCurve" [compareWith]="curveNameCompare">
                    <mat-select-trigger>{{
                        !isString(selectedCurve) ? selectedCurve.shortName : selectedCurve
                    }}</mat-select-trigger>
                    <mat-option
                        *ngFor="let curve of displayedListOfCurves"
                        (click)="!curve.disabled && onDefaultCurveSelect(curve)"
                        [value]="curve"
                        [disabled]="curve.disabled"
                        [matTooltipDisabled]="!curve.disabled"
                        [matTooltip]="curve.disableReason"
                    >
                        {{ curve?.displayName }}
                    </mat-option>
                    <ng-container *ngIf="savedCurves.length && customerHasAdvancedEditPermission">
                        <div class="divider"></div>
                        <mat-option
                            *ngFor="let curve of savedCurves"
                            (click)="onSavedCurveSelect(curve)"
                            [value]="curve"
                        >
                            <div class="saved-curve-option">
                                <p>{{ curve && curve.length >= 20 ? curve.slice(0, 20) + ' ...' : curve }}</p>
                                <mat-icon (click)="$event.stopPropagation(); deleteSavedCurve(curve)">delete</mat-icon>
                            </div>
                        </mat-option>
                    </ng-container>
                    <div class="divider" *ngIf="customerHasAdvancedEditPermission"></div>
                    <div class="save-curve-container">
                        <button
                            mat-button
                            type="button"
                            *ngIf="customerHasAdvancedEditPermission && selectedCurve !== curves[0]"
                            [disabled]="!(isScatterCurve(selectedCurve) && selectedCurve.shortName) || savedCurves.length >= 5"
                            (click)="saveCurve()"
                        >
                            Save Curve
                        </button>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="auto-margin-t-b bestFitRange margin-r-10">
            <div class="tolerance-control-container">
                <button
                    class="tolerance-control-button"
                    [disabled]="isLoading || viewDataService.scatterToleranceRangeValue === 0"
                    (click)="onRangeChanged({ value: (viewDataService.scatterToleranceRangeValue * 100 - 10) / 100 })"
                >
                    -
                </button>
                <input
                    type="number"
                    class="tolerance-control-input sg-tolerance-hint"
                    [(ngModel)]="viewDataService.scatterToleranceRangeValue"
                    (change)="onRangeChanged($event.target)"
                    [disabled]="isLoading"
                />
                <button
                    class="tolerance-control-button"
                    [disabled]="isLoading || viewDataService.scatterToleranceRangeValue === 1"
                    (click)="onRangeChanged({ value: (viewDataService.scatterToleranceRangeValue * 100 + 10) / 100 })"
                >
                    +
                </button>
            </div>
        </div>
        <div class="auto-margin-t-b margin-r-10 pad-t-5">
            <mat-form-field class="tolerance-field sg-tol-select-hint">
                <mat-select
                    placeholder="{{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.BEST_FIT_TOLERANCE_POSITION' | translate }}"
                    [(ngModel)]="selected"
                    (ngModelChange)="onRangeChanged()"
                    class="tolerance-selector"
                >
                    <mat-option [value]="1">
                        {{ annotationSettings && annotationSettings.isScatterInvert ? ('LOCATION_DASHBOARD.DASHBOARD_FILTER.BEST_FIT_BELOW' | translate)
                            : ('LOCATION_DASHBOARD.DASHBOARD_FILTER.BEST_FIT_ABOVE' | translate) }}
                    </mat-option>
                    <mat-option [value]="2">
                        {{ annotationSettings && annotationSettings.isScatterInvert ? ('LOCATION_DASHBOARD.DASHBOARD_FILTER.BEST_FIT_ABOVE' | translate)
                        : ('LOCATION_DASHBOARD.DASHBOARD_FILTER.BEST_FIT_BELOW' | translate) }}
                    </mat-option>
                    <mat-option [value]="3">
                        {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.BEST_FIT_BOTH' | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="customerHasAdvancedEditPermission && !viewDataService.isCurveEditable && !savedCurve" class="auto-margin-t-b margin-r-10 pad-t-5">
            <mat-form-field class="generation-option-field sg-generation-hint">
                <span matTooltip="{{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.GENERATION_OPTIONS_TOOLTIP' | translate }}">
                    <mat-select
                        placeholder="{{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.GENERATION_OPTIONS' | translate }}"
                        [(ngModel)]="generationOption"
                        (ngModelChange)="onGenerationOptionsChanged()"
                        class="generation-option-selector"
                    >
                        <mat-option [value]="0">
                            {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.CURRENT' | translate }}
                        </mat-option>
                        <mat-option [value]="1">
                            {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.LAST_30' | translate }}
                        </mat-option>
                        <mat-option [value]="2">
                            {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.LAST_60' | translate }}
                        </mat-option>
                        <mat-option [value]="3">
                            {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.LAST_90' | translate }}
                        </mat-option>
                    </mat-select>
                </span>
            </mat-form-field>
        </div>
        <div class="auto-margin-t-b margin-r-10 pad-t-5 sg-sync-hint">
            <p class="toolbar-label">Sync Zoom</p>
            <mat-slide-toggle
                style="padding-top: 5px; padding-left: 5px"
                [checked]="syncZoomWithHg"
                (change)="onSyncZoomChange($event)"
            ></mat-slide-toggle>
        </div>
        <div class="auto-margin-t-b margin-r-10 pad-t-5" *ngIf="false && showCurveOptions && data && data.curves && data.curves.length">
            <button
                mat-icon-button
                type="button"
                class="curve-options-btn"
                matTooltip="{{ 'LOCATION_DASHBOARD.SCATTERGRAPH_DETAIL_VIEW.CURVE_OPTIONS' | translate }}"
                (click)="openCurveOptions()"
              >
                <mat-icon>more_vert</mat-icon>
          </button>
        </div>
        <!--                        <button class="auto-margin-t-b adjustLine"  mat-button [matMenuTriggerFor]="menu"-->
        <!--                                [disabled]="!isDataEditingModeEnabled">-->
        <!--                                <div class="adjust-line-tool-header">-->
        <!--                                        {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.TOLERANCE_EDITING' | translate }}-->
        <!--                                </div>-->
        <!--                                <div  class="adjust-line-tool-content" [ngClass]="lineCss">-->
        <!--                                        {{ linetext | translate }} <mat-icon>keyboard_arrow_down</mat-icon>-->
        <!--                                </div>-->
        <!--                        </button>-->
        <!--                        <mat-menu #menu="matMenu">-->
        <!--                            <button mat-menu-item class="custom-in" type="button" [disabled]='selected !== 3'-->
        <!--                                (click)="adjustPointsToBestFit(2)">-->
        <!--                                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.INSIDE_LINES' | translate }}-->
        <!--                            </button>-->
        <!--                            <button mat-menu-item class="custom-out" type="button" [disabled]='selected !== 3'-->
        <!--                                (click)="adjustPointsToBestFit(1)">-->
        <!--                                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.OUTSIDE_LINES' | translate }}-->
        <!--                            </button>-->
        <!--                            <button mat-menu-item class="custom-up" type="button" [disabled]='selected !== 1'-->
        <!--                                (click)="adjustPointsToBestFit(3)">-->
        <!--                                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.ABOVE_LINES' | translate }}-->
        <!--                            </button>-->
        <!--                            <button mat-menu-item class="custom-down" type="button" [disabled]='selected !== 2'-->
        <!--                                (click)="adjustPointsToBestFit(4)">-->
        <!--                                {{ 'LOCATION_DASHBOARD.DASHBOARD_FILTER.BELOW_LINES' | translate }}-->
        <!--                            </button>-->
        <!--                        </mat-menu>-->
    </div>

    <div *ngIf="isDataEditingModeEnabled" class="undo-redo-container">
        <span matTooltip="{{ 'LOCATION_DASHBOARD.DATA_EDITING.UNDO_TOOLTIP' | translate }}">
            <button mat-button class="undo-redo-button" type="button"
                [disabled]="disableUndo || (dataEditService.editRequestLoading | async)" (click)="dataEditService.undoChanges(customerId)"
                [ngClass]="{ 'active': !disableUndo }">
                <mat-icon>undo</mat-icon>
            </button>
        </span>

        <span matTooltip="{{ 'LOCATION_DASHBOARD.DATA_EDITING.REDO_TOOLTIP' | translate }}">
            <button mat-button type="button" class="undo-redo-button"
                [disabled]="disableRedo || (dataEditService.editRequestLoading | async)" (click)="dataEditService.redoChanges(customerId)"
                [ngClass]="{ 'active': !disableRedo }">
                <mat-icon>redo</mat-icon>
            </button>
        </span>
    </div>
</div>
