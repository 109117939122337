import { Pipe, PipeTransform } from '@angular/core';
import { NotificationLocationOptions } from '../models/notifications';


export interface Checkable {
    id: number;
    checked: boolean;
}

@Pipe({
    name: 'onlyCheckedOptions',
})
export class OnlyCheckedOptionsPipe implements PipeTransform {
    transform(items: Array<Checkable>): Array<Checkable> {
        return items.filter((v, i) => i === items.findIndex(x => x.id === v.id) && v.checked === true);
    }
}
