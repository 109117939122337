import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the date user preferably
 * Takes an argument i.e; dateformat ('YYYY/MM/DD', 'YYYY/DD/MM')
 * Usage:
 *   value | anyDate
 * Example:
 *   {{ 2019-05-08T00:00:00 | 'YYYY/MM/DD' }}
 *   formats to user preference (DD/MM/YYYY): 08/05/2019
 */
@Pipe({
    name: 'customDatePipe',
})
export class AdsCustomDatePipe implements PipeTransform {
    public transform(value: string, currentFormat: string) {
        if (value && currentFormat) {
            const capsFormat = currentFormat.toUpperCase();
            const formats = capsFormat.split('/');
            const dates = value.split('/');
            const dayIndex = formats.indexOf('DD');
            const monthIndex = formats.indexOf('MM');
            const yearIndex = formats.indexOf('YYYY');

            let date = `${dates[yearIndex]}/${dates[monthIndex]}/${dates[dayIndex]}`;
            if (capsFormat === 'DD/MM/YYYY') {
                date = `${dates[dayIndex]}/${dates[monthIndex]}/${dates[yearIndex]}`;
            } else if (capsFormat === 'MM/DD/YYYY') {
                date = `${dates[monthIndex]}/${dates[dayIndex]}/${dates[yearIndex]}`;
            }
            return date;
        }
    }
}
