<div id="markerLocationClusterId">
    <app-loader [isLoading]="isCollecting"></app-loader>
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'HOME.MAP.CLUSTER_LOCATION.TITLE' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <div mat-dialog-content id="locationListId">
        <mat-list>
            <mat-list-item *ngFor="let location of clusterLocations" (click)="emitOpenLocationDetailsPopUp(location)">
                <div class="locationTypeIcon">
                    <img [src]="checkImage(location)" />
                </div>

                <div class="locationNameMarker">{{ location.locationName }}</div>
            </mat-list-item>
        </mat-list>
    </div>
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="emitCloseMapLocationsPopup()">{{ 'COMMON.CLOSE' | translate }}</button>
        </div>
        <!-- <div class="auto-margin">
      <button mat-button (click)="collectLocations(clusterLocations)" [disabled]="isCollecting">{{ 'HOME.MAP.CLUSTER_LOCATION.COLLECT_BTN' | translate }}</button>
    </div> -->
    </div>
</div>
