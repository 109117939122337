<mat-tree
    id="feat-prop-list"
    class="features-tree"
    [dataSource]="feat_dataSource"
    [treeControl]="feat_treeControl"
    [ngClass]="{ 'hint-background': availablePageHint }"
>
    <mat-tree-node
        *matTreeNodeDef="let node"
        matTreeNodeToggle
        matTreeNodePadding
        (click)="nodeClick(node, 0)"
        class="property-list-tree-node"
        [ngClass]="{ active: node.item === activeNodeItem }"
    >
        <div class="name-icon">
            <div
                class="icon"
                [ngClass]="{
                    alarm: node.alarmstatus === 2,
                    warning: node.alarmstatus === 1,
                    normal: node.alarmstatus === 0
                }"
            ></div>
            {{ node.item }}
        </div>
        <div class="icons" *ngIf="node.layername === 'Monitor' && (node.item === activeNodeItem || node.item === 'DEMO_MONITOR')">
            <mat-icon class="pencil edit-mon-hint" (click)="nodeClick(node, 2)" matTooltip="{{ 'GIS_TOOLTIPS.VIEW_LOCATION_CARD' | translate }}">edit</mat-icon>
            <mat-icon class="child-search-hint" (click)="nodeClick(node, 1)" matTooltip="{{ 'GIS_TOOLTIPS.ZOOM_TO_THIS_FEATURE' | translate }}">search</mat-icon>
            <mat-icon
                class="add-mon-hint"
                matTooltip="{{ 'COMMON.MORE_OPTIONS_TEXT' | translate }}"
                [matMenuTriggerFor]="monitorMenu"
                #monitorMenuButton="matMenuTrigger"
            >
                east</mat-icon
            >
            <mat-menu
                #monitorMenu="matMenu"
                class="add-location-menu"
                yPosition="above"
                xPosition="after"
                [overlapTrigger]="false"
            >
                <button mat-menu-item (click)="clickMonitorLocationDashboard(node)">
                    <mat-icon>dashboard</mat-icon>
                    <span>{{ 'COMMON.LOCATION_DASHBOARD' | translate }}</span>
                </button>
                <button mat-menu-item (click)="clickMonitorLocationDetails(node)">
                    <mat-icon>info</mat-icon>
                    <span>{{ 'COMMON.LOCATION_DETAILS' | translate }}</span>
                </button>
                <button mat-menu-item (click)="clickMonitorDataExport(node)">
                    <mat-icon>file_download</mat-icon>
                    <span>{{ 'COMMON.DATA_EXPORT' | translate }}</span>
                </button>
            </mat-menu>
        </div>
    </mat-tree-node>

    <mat-tree-node
        *matTreeNodeDef="let node; when: feat_hasChild"
        matTreeNodePadding
        class="header-tree-node"
        [ngClass]="{ active: node.item === activeNodeItem }"
    >
        <div class="info">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.filename">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{ feat_treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
                </mat-icon>
            </button>
            <div class="monitor-name-hint">{{ node.item }}</div>
        </div>
        <div>
            <mat-icon
                class="add-mon-hint"
                *ngIf="node.item == 'Monitor'"
                matTooltip="{{ 'GIS_TOOLTIPS.PLUS_ICON' | translate }}"
                [matMenuTriggerFor]="menu"
                #addNewLocationTrigger="matMenuTrigger"
            >
                add</mat-icon
            >
            <mat-menu
                #menu="matMenu"
                class="add-location-menu"
                yPosition="above"
                xPosition="after"
                [overlapTrigger]="false"
            >
                <button mat-menu-item (click)="addCompositeLocation()">
                    <mat-icon>add_circle</mat-icon>
                    <span>{{ 'HOME.MAP.LEGEND.ADD_MAP_COMPOSITE_LOCATION' | translate }}</span>
                </button>
                <button mat-menu-item (click)="addEditLocation()">
                    <mat-icon>add_location</mat-icon>
                    <span>{{ 'HOME.MAP.LEGEND.ADD_NEW_LOCATION' | translate }}</span>
                </button>
            </mat-menu>
            <mat-icon class="mon-search-hint" (click)="nodeClick(node, 1)" matTooltip="{{ 'GIS_TOOLTIPS.ZOOM_SELECTED_LOCATIONS' | translate }}">search</mat-icon>
        </div>
    </mat-tree-node>
</mat-tree>
