import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { IComponentDialog, IComponentDialogConfirmationResult } from 'app/shared/models/comopnent-cofirmation';

export enum ConfirmationButton {
    cancel = 'cancel',
    ok = 'ok'
}

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
    public title: string;
    public message: string;
    public messageClass: string;
    public cancelText: string;
    public okText: string;
    public id: number;
    public hidebackContent: boolean;
    public okDisabled: boolean;
    public dangerText: string;
    public isStormDelete: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) data: IComponentDialog,
        public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    ) {
        this.title = data.title;
        this.message = data.message;
        this.cancelText = data.cancelText;
        this.okText = data.okText;
        this.hidebackContent = data.hidebackContent;
        this.okDisabled = data.okDisabled;
        this.dangerText = data.dangerText;
        this.messageClass = data.messageClass;
        this.isStormDelete = data.isStormDelete;
    }

    public cancelAndClose() {
        this.dialogRef.close(<IComponentDialogConfirmationResult>{
            whichButtonWasPressed: ConfirmationButton.cancel,
        });
    }

    public okAndClose() {
        this.dialogRef.close(<IComponentDialogConfirmationResult>{
            whichButtonWasPressed: ConfirmationButton.ok,
        });
    }
}
