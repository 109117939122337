<div class="modem-setup">
    <app-loader [isLoading]="isLoading"></app-loader>
    <div class="popupHeader" mat-dialog-title>
        <div class="auto-margin pad-l-15">
            <mat-icon class="backIcon" (click)="close()">arrow_back</mat-icon>
        </div>
        <span class="header-with-icon">{{ 'COMMON.MODEM_SETUP' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <div mat-dialog-content>
        <div class="search-section pad-t-12">
            <mat-form-field class="full-width">
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <input
                    type="text"
                    [formControl]="searchBox"
                    autocomplete="false"
                    tabindex="-1"
                    matInput
                    placeholder="{{ 'COMMON.SEARCH' | translate }} "
                />
            </mat-form-field>
        </div>
        <!-- modem setup table -->
        <div id="modem-setup-table">
            <mat-table [hidden]="!paginationLength" #table [dataSource]="modemSetupSource" matSort>
                <!-- Type-->
                <ng-container matColumnDef="authenticationType">
                    <mat-header-cell *matHeaderCellDef>{{ 'COMMON.TYPE_COLUMN_TITLE' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{
                        getAuthenticationType(element.authenticationType)
                    }}</mat-cell>
                </ng-container>
                <!-- Description -->
                <ng-container matColumnDef="provider">
                    <mat-header-cell *matHeaderCellDef
                        >{{ 'CALCULATED_ENTITY_DIALOG.FORM_DESCRIPTION_PLACEHOLDER' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.provider }} </mat-cell>
                </ng-container>
                <!-- Username / Carrier Code -->
                <ng-container matColumnDef="username">
                    <mat-header-cell *matHeaderCellDef>{{
                        'COMMON.USERNAME_CARRIER_CODE' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.username }}</mat-cell>
                </ng-container>
                <!-- Password -->
                <ng-container matColumnDef="password">
                    <mat-header-cell *matHeaderCellDef>{{ 'ADD_EDIT.PASSWORD' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <div class="d-flex">
                            <input
                                [type]="showPasswordChars ? 'password' : 'text'"
                                readonly="true"
                                matInput
                                value="{{ element.password }}"
                                type="password"
                                name="confirmPassword"
                                class="password-txt-width"
                            />
                            <mat-icon matSuffix (click)="showPasswordChars = !showPasswordChars">
                                {{ showPasswordChars ? 'visibility' : 'visibility_off' }}</mat-icon
                            >
                        </div>
                    </mat-cell>
                </ng-container>
                <!-- Action -->
                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef class="fixCellWidth"></mat-header-cell>
                    <mat-cell *matCellDef="let element" class="text-right">
                        <button
                            mat-icon-button
                            [disabled]="element['predefined'] == true"
                            matTooltip="Edit"
                            (click)="addModemSetup(element)"
                        >
                            <mat-icon>mode_edit</mat-icon>
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="tableColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
            </mat-table>
            <div class="notabledataFound custom-table" *ngIf="!paginationLength">
                {{ 'ADMIN.NO_DATA_AVAILABLE' | translate }}
            </div>
            <div class="auto-margin" [hidden]="paginationLength <= pageSize">
                <mat-paginator
                    [length]="paginationLength"
                    [pageIndex]="pageIndex"
                    [pageSize]="pageSize"
                    (page)="changePage($event)"
                    [pageSizeOptions]="[10, 20, 100]"
                >
                </mat-paginator>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="auto-margin">
            <button
                (click)="addModemSetup()"
                mat-fab
                color="accent"
                id="addNewCustomer"
                class="fab-button-Style"
                matTooltip=" {{ 'COMMON.ADD_MODEM_SETUP' | translate }}"
                matTooltipPosition="above"
            >
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="close()">{{ 'COMMON.CLOSE' | translate }}</button>
        </div>
    </div>
</div>
