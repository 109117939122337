<div style="margin-bottom: 15px">
    <form #widgetFiltersForm="ngForm" name="widgetFiltersForm" id="widgetFiltersForm" novalidate>
        <div class="flex-container" id="widgetFilters" style="justify-content: flex-start">
            <div id="widgetStatusId" class="alarm-status-hint flex-item-al-e-ju-s" *ngIf="filterSettings.displayAlarmStatus">
                <app-multi-select-auto-complete
                    [items]="alarmStatuses"
                    [preselectedItems]="preselectedStatus"
                    (currentlySelectedItems)="selectedAlarmStatus($event)"
                    placeholder="{{ 'COMMON.STATUS_COLUMN_TITLE' | translate }}"
                ></app-multi-select-auto-complete>
            </div>
            <div class="spacer" *ngIf="filterSettings.displayAlarmStatus && filterSettings.displayAlarmType"></div>

            <div id="widgetTypeId" class="alarm-type-hint flex-item-al-e-ju-s" *ngIf="filterSettings.displayAlarmType">
                <app-multi-select-auto-complete
                    [items]="alarmTypes"
                    (currentlySelectedItems)="selectedAlarmType($event)"
                    placeholder="{{ 'COMMON.TYPE_COLUMN_TITLE' | translate }}"
                ></app-multi-select-auto-complete>
            </div>

            <div
                class="spacer"
                *ngIf="!toggleState && filterSettings.displayAlarmType && filterSettings.displayDateRanges"
            ></div>

            <div id="widgetDateId" class="custom-error data-comm-date-hint audit-date-hint percent-date-hint">
                <div class="noFlexWrap">
                    <div class="flex-item-al-e-ju-s eachDateSection blockage-filter-start-hint ar-start-hint" *ngIf="filterSettings.displayDateRanges">
                        <mat-form-field class="full-width">
                            <input
                                #startDateInput
                                readonly
                                matInput
                                name="startDate"
                                placeholder="Start Date"
                                (change)="onStartDateChanged($event)"
                                [value]="widgetFilterData.startDate | date: customerDateFormat"
                                tabindex="-1"
                                class="datepickerStyles"
                                appDatetimepickerPosition
                                [matDatetimepickerComp]="startDte"
                            />
                            <input
                                matInput
                                hidden
                                name="startDate"
                                [(ngModel)]="widgetFilterData.startDate"
                                [matDatetimepicker]="startDte"
                                [max]="widgetFilterData.endDate ? widgetFilterData.endDate : limitStartDate ? maxDate : null"
                                (dateInput)="onStartDateChanged($event)"
                            />
                            <mat-datetimepicker-toggle matSuffix [for]="startDte"></mat-datetimepicker-toggle>
                            <mat-datetimepicker #startDte [type]="datePickerType"></mat-datetimepicker>
                            <mat-icon
                                class="clickable"
                                matTooltip="Clear"
                                matSuffix
                                (click)="clearInputField('startDate')"
                                *ngIf="widgetFilterData.startDate"
                                >clear
                            </mat-icon>
                        </mat-form-field>
                        <div
                            *ngIf="
                                isStartDateInvalid &&
                                filterSettings.displayDateRanges &&
                                !filterSettings.dateRangeOptional
                            "
                            class="danger"
                        >
                            {{ 'HOME.WIDGET_FILTER.INVALID' | translate }} {{ 'COMMON.START_DATE' | translate }}
                        </div>
                    </div>

                    <div class="spacer alarm-date-hint" *ngIf="filterSettings.displayDateRanges"></div>

                    <div class="flex-item-al-e-ju-c eachDateSection blockage-filter-end-hint ar-end-hint" *ngIf="filterSettings.displayDateRanges">
                        <mat-form-field class="full-width">
                            <input
                                #endDateInput
                                readonly
                                matInput
                                name="enDate"
                                placeholder="End Date"
                                (change)="endDateChange($event)"
                                tabindex="-1"
                                [value]="widgetFilterData.endDate | date: customerDateFormat"
                                appDatetimepickerPosition
                                [matDatetimepickerComp]="endDte"
                            />
                            <input
                                matInput
                                hidden
                                name="enDate"
                                [(ngModel)]="widgetFilterData.endDate"
                                [matDatetimepicker]="endDte"
                                (dateInput)="endDateChange($event)"
                                [min]="widgetFilterData.startDate"
                                required
                                class="datefilters"
                            />
                            <mat-datetimepicker-toggle matSuffix [for]="endDte"></mat-datetimepicker-toggle>
                            <mat-datetimepicker #endDte disabled="false" [type]="datePickerType"></mat-datetimepicker>
                            <mat-icon
                                class="clickable"
                                matTooltip="Clear"
                                matSuffix
                                (click)="clearInputField('endDate')"
                                *ngIf="widgetFilterData.endDate"
                                >clear</mat-icon
                            >
                        </mat-form-field>
                        <div
                            *ngIf="
                                isEndDateInvalid &&
                                filterSettings.displayDateRanges &&
                                !filterSettings.dateRangeOptional
                            "
                            class="danger"
                        >
                            {{ 'HOME.WIDGET_FILTER.INVALID' | translate }} {{ 'COMMON.END_DATE' | translate }}
                        </div>
                    </div>
                    <div
                        class="spacer"
                        *ngIf="
                            filterSettings.displayDateRanges &&
                            filterSettings.displayLocations &&
                            filterSettings.singleLocationSelect &&
                            !toggleState
                        "
                    ></div>
                </div>

                <div class="noFlexWrap custom-error-message">
                    <div *ngIf="!isDaySpanValid && filterSettings.displayDateRanges" class="danger">
                        {{ daySpanErrorMessage }}
                    </div>
                </div>
            </div>

            <!--    ENTITIES FORM CONTROL   -->
            <div class="spacer" *ngIf="filterSettings.displayDateRanges && filterSettings.displayEntities"></div>

            <div
                class="flex-item-al-s-ju-s entitySelector"
                style="justify-content: space-between"
                *ngIf="filterSettings.displayEntities"
                id="entities"
            >
                <app-multi-select-group
                    #entitiesGroupMultiselect
                    [isShowAll]="
                        filterSettings.displayAllEntities !== undefined ? filterSettings.displayAllEntities : true
                    "
                    [items]="entities"
                    [preselectedItems]="selectedEntities"
                    (currentlySelectedItems)="selectedEntitiesList($event)"
                    placeholder="{{ 'COMMON.ENTITY' | translate }}"
                    [minDisplayLimit]="filterSettings.minDisplayLimit"
                    [maxDisplayLimit]="filterSettings.maxDisplayLimit"
                >
                </app-multi-select-group>
            </div>

            <div
                class="alarm-location-hint location-selected flex-item-al-e-ju-s blockage-filter-locations-hint"
                [ngClass]="{ 'full-width': toggleState, 'width-500-px': !toggleState }"
                *ngIf="filterSettings.displayLocations && !filterSettings.singleLocationSelect"
            >
                <app-multi-select-auto-complete
                    [items]="locations"
                    (currentlySelectedItems)="selectedLocations($event)"
                    placeholder=" {{ 'COMMON.LOCATIONS' | translate }}"
                >
                </app-multi-select-auto-complete>
            </div>
            <div
                id="widgetLocationId"
                class="flex-item-al-e-ju-s data-comm-series-hint"
                [ngClass]="{ 'full-width': toggleState, width25percent: !toggleState }"
                *ngIf="filterSettings.singleMonitorSelect && !toggleState"
            >
                <app-auto-complete
                    [items]="monitorSeries"
                    [isShowAll]="false"
                    (selectedItems)="selectedMonitors($event)"
                    placeholder="{{ 'COMMON.MONITOR_SERIES' | translate }}"
                >
                </app-auto-complete>
            </div>

            <div
                class="location-selected flex-item-al-e-ju-s blockage-filter-status-hint"
                [ngClass]="{ 'full-width': toggleState, 'width-500-px': !toggleState }"
                *ngIf="filterSettings.displayblockStatus"
            >
                <app-multi-select-auto-complete
                    [items]="blockStatus"
                    [isShowAll]="false"
                    (currentlySelectedItems)="selectedBlockStatus($event)"
                    placeholder=" {{ 'COMMON.BLOCK_STATUS' | translate }}"
                ></app-multi-select-auto-complete>
            </div>
            <div class="spacer" *ngIf="!toggleState && filterSettings.singleMonitorSelect"></div>
            <div
                id="widgetLocationId"
                class="
                    flex-item-al-e-ju-s
                    data-comm-locations-hint
                    ar-locations-hint
                    battery-locations-filter-hint
                    battery-sm-locations-filter-hint
                    audit-locations-hint
                    percent-locations-hint
                    battery-sm-location-filter-hint
                "
                [ngClass]="{ 'full-width': toggleState, width25percent: !toggleState }"
                *ngIf="filterSettings.displayLocations && filterSettings.singleLocationSelect"
            >
                <app-auto-complete
                    [items]="locations"
                    [isShowAll]="false"
                    [strict]="true"
                    [focusOnClear]="false"
                    (selectedItems)="selectedLocations($event)"
                    placeholder="{{ 'COMMON.LOCATION_COLUMN_TITLE' | translate }}"
                ></app-auto-complete>
            </div>
            <div class="spacer" *ngIf="!toggleState"></div>
            <div
                class="flex-item-al-e-ju-s audit-loc-group-hint"
                [ngClass]="{ 'full-width': toggleState, width25percent: !toggleState }"
                *ngIf="filterSettings.displayLocationGroup && filterSettings.singleLocationGroupSelect"
            >
                <app-auto-complete
                    [items]="locationGroupList"
                    [isShowAll]="false"
                    [focusOnClear]="false"
                    (selectedItems)="selectedLocationsGroup($event)"
                    placeholder="{{ 'CUSTOMER_EDITOR.ASSIGN_LOCATIONS.LOCATION_GROUP' | translate }}"
                ></app-auto-complete>
            </div>

            <div class="spacer" *ngIf="!toggleState && filterSettings.displayAnomaliesReasons"></div>
            <div
                class="flex-item-al-e-ju-s ar-reasons-hint"
                [ngClass]="{ 'full-width': toggleState, width25percent: !toggleState }"
                *ngIf="filterSettings.displayAnomaliesReasons"
            >
                <app-auto-complete
                    *ngIf="anomaliesReasons?.length > 0"
                    [items]="anomaliesReasons"
                    [isShowAll]="false"
                    (selectedItems)="selectAnomalyReason($event)"
                    placeholder="{{ 'HOME.WIDGET_FILTER.ANOMALY_REASON' | translate }}"
                ></app-auto-complete>
            </div>

            <div
                class="spacer"
                *ngIf="
                    filterSettings.displayUsers ||
                    (filterSettings.displayLocations &&
                        (filterSettings.displayBatteryStatusItems || filterSettings.displayErrorThreshold))
                "
            ></div>

            <div
                id="widget-battery-status-id"
                class="flex-item-al-e-ju-s battery-filter-status-hint battery-sm-status-filter-hint"
                *ngIf="filterSettings.displayBatteryStatusItems"
            >
                <app-auto-complete
                    [items]="batteryStatuses"
                    [preselectedItem]="preselectedBatteryStatus"
                    [isShowAll]="false"
                    (selectedItems)="selectBatteryStatus($event)"
                    placeholder="{{ 'COMMON.STATUS_COLUMN_TITLE' | translate }}"
                ></app-auto-complete>
            </div>

            <div
                class="flex-item-al-e-ju-s"
                [ngClass]="{
                    widgetInputWidthExpanded: filterSettings.removeFullWidthFromLocationInput && !toggleState,
                    widgetInputWidth: filterSettings.removeFullWidthFromLocationInput && toggleState
                }"
                *ngIf="filterSettings.displayUsers"
            >
                <app-multi-select-auto-complete
                    [items]="users"
                    (currentlySelectedItems)="selectedUsers($event)"
                    placeholder="{{ 'COMMON.WIDGET_FILTER.USERS' | translate }}"
                ></app-multi-select-auto-complete>
            </div>

            <div class="flex-item-al-e-ju-c noFlexWrap" *ngIf="filterSettings.displayErrorThreshold">
                <label class="label">{{ 'COMMON.ERROR_COLUMN_TITLE' | translate }}</label>
                <mat-radio-group [(ngModel)]="widgetFilterData.errThreshold" name="errorThreshold">
                    <mat-radio-button *ngFor="let ET of errThresholds" [value]="ET.value">{{
                        ET.text
                    }}</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="flex-container" style="justify-content: flex-start"></div>
    </form>
</div>
