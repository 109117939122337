<div class="silt-eidtor" id="gainEditorDialog">
    <app-loader [isLoading]="isLoading"></app-loader>
    <!-- add graph header -->
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'COMMON.VELOCITY_GAIN_EDITOR' | translate | uppercase }}</span>
        <span>|</span>
        <span>{{dialogData.locationDetails.locationName}}</span>
        <span class="app-flex-filler"></span>
    </div>
    <!-- content -->
    <div mat-dialog-content class="full-width" *ngIf="!isLoading">
        <mat-table
            #table
            class="gain-table"
            [dataSource]="gainEditorDataSource"
            matSort
            matSortDisabled
            matSortDisableClear
            matSortActive="timestamp"
            matSortDirection="asc"
        >
            <ng-container matColumnDef="timestamp">
                <mat-header-cell class="mat-column-date"  *matHeaderCellDef mat-sort-header matSortStart="asc">{{
                    'COMMON.DATE_TIME' | translate
                }}</mat-header-cell>
                    <mat-cell class="mat-column-date" *matCellDef="let element">{{ element.timestamp | date: customerDateFormat }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="editedValue">
                <mat-header-cell class="mat-column-gain"  *matHeaderCellDef mat-sort-header
                    >{{ 'COMMON.GAIN' | translate }} </mat-header-cell
                >
                <mat-cell class="mat-column-gain" *matCellDef="let element">{{ element.editedValue | number: gainValuePrecision }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="applyTo">
                <mat-header-cell class="mat-column-date" *matHeaderCellDef mat-sort-header
                    >{{ 'COMMON.APPLY_TO' | translate }}</mat-header-cell
                >
                <mat-cell class="mat-column-date" *matCellDef="let element">{{ element.applyTo }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="user">
                <mat-header-cell class="mat-column-user"  *matHeaderCellDef mat-sort-header>{{
                    'COMMON.MODIFIED_BY_HEADER' | translate
                }}</mat-header-cell>
                <mat-cell class="mat-column-user"  *matCellDef="let element">{{ element.user }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="editDate">
                <mat-header-cell class="mat-column-date" *matHeaderCellDef mat-sort-header>{{
                    'COMMON.LAST_MODIFIED_ON' | translate
                }}</mat-header-cell>
                <mat-cell class="mat-column-date" *matCellDef="let element"
                    >{{ element.editDate ? (element.editDate | date: customerDateFormat) : ' - ' }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action" *ngIf="userHasPermissionOnEdit">
                <mat-header-cell *matHeaderCellDef class="fixCellWidth110"></mat-header-cell>
                <mat-cell *matCellDef="let element; let j = index" class="text-right">
                    <button mat-icon-button [disabled]="isEditMode || element.isOlderRecord" (click)="editItem(element, j)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button [disabled]="isEditMode || element.isOlderRecord" (click)="deleteItem(element, j); formChanged = true">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="GainEditorDescriptionColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: GainEditorDescriptionColumns"> </mat-row>
        </mat-table>

        <!-- separator -->

        <div [formGroup]="form" *ngIf="addMore">
            <div class="custom-50p">
                <div>
                    <mat-form-field class="full-width">
                        <input
                            #selectedDateInput
                            matInput
                            type="datetime"
                            name="selectedDate"
                            [value]="form.controls['timestamp'].value | date: customerDateFormat"
                            placeholder="{{ 'COMMON.DATE_TIME' | translate }}"
                            (change)="inputCheckStartDateChange($event)"
                            (focusout)="inputCheckStartDateChange($event)"
                            tabindex="-1"
                            class="datepickerStyles"
                            appDatetimepickerPosition
                            [matDatetimepickerComp]="selectedDte"
                        />
                        <input
                            matInput
                            hidden
                            type="datetime"
                            name="selectedDate"
                            [matDatetimepicker]="selectedDte"
                            formControlName="timestamp"
                            [value]="form.controls['timestamp'].value | date: customerDateFormat"
                            required
                            tabindex="-1"
                            (dateInput)="calendarCheckStartDateChange($event)"
                            (dateChange)="calendarCheckStartDateChange($event)"
                        />
                        <mat-datetimepicker-toggle
                            matSuffix
                            [for]="selectedDte"
                        ></mat-datetimepicker-toggle>
                        <mat-datetimepicker #selectedDte type="datetime"></mat-datetimepicker>
                    </mat-form-field>
                </div>
                <div class="w-35">
                    <mat-form-field>
                        <input
                            matInput
                            type="number"
                            formControlName="editedValue"
                            step="0.01"
                            (input)="formChanged = true"
                            [pattern]="numericWith2DecimalPlaces"
                            placeholder="{{ 'COMMON.GAIN' | translate }} "
                        />
                    </mat-form-field>
                    <ng-container *ngIf="form.get('editedValue').errors as valueError">
                        <div class="mat-error" *ngIf="valueError.pattern || valueError.max">
                            {{ gainValuePatternError }}
                        </div>
                    </ng-container>
                </div>
                <div>
                    <mat-form-field>
                        <mat-select
                            placeholder="{{ 'COMMON.APPLY_TO' | translate }}"
                            [(ngModel)]="selectedEntity"
                            (ngModelChange)="onEntityChange($event)"
                            (selectionChange)="entityWasEdited = true; formChanged = true"
                            [(value)]="selectedEntity" formControlName="applyTo">
                            <mat-option *ngFor="let entity of entityList" [value]="entity.text">{{ entity.text
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="flex-container" *ngIf="userHasPermissionOnEdit">
            <div class="app-flex-filler">
                <div class="mat-error"  *ngIf="errorMsg!=''">
                    {{ errorMsg }}
                </div>
            </div>
            <div class="auto-margin">
                <button mat-button *ngIf="addMore" (click)="cancel()">
                    {{ 'COMMON.CANCEL' | translate | uppercase }}
                </button>
                <button mat-button *ngIf="addMore" [disabled]="!form.valid" (click)="addRow()">
                    {{ 'COMMON.ADD_TEXT' | translate | uppercase }}
                </button>
            </div>
            <div class="auto-margin" *ngIf="!addMore">
                <button mat-button (click)="addMoreOpen()" [disabled]="isEditMode">
                    {{ 'COMMON.ADD_MORE' | translate | uppercase }}
                </button>
            </div>
        </div>
    </div>
    <div mat-dialog-actions class="border-top">
        <button mat-icon-button matTooltip="{{ 'COMMON.DOWNLOAD_GAIN_TABLE' | translate }}" matTooltipPosition="right" (click)="exportDataToCsv()">
            <mat-icon>file_download</mat-icon>
        </button>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="cancelClose(false)">
                {{ 'COMMON.CANCEL' | translate | uppercase }}
            </button>
        </div>
        <div class="auto-margin">
            <button mat-button matTooltip="{{ 'COMMON.RECAL_MSG' | translate }}" matTooltipPosition="above" [disabled]="enableSave || addMore || isEditMode || !gainDataExists" (click)="recalculateOpen()">
                {{ 'COMMON.RECALCULATE' | translate | uppercase }}
            </button>
        </div>
        <div class="auto-margin">
            <button mat-button [disabled]="!enableSave" (click)="saveGainValues()">
                {{ 'COMMON.APPLY_BTN' | translate | uppercase }}
            </button>
        </div>
    </div>
</div>
