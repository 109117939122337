<form #stormDefnitionForm="ngForm" novalidate>
    <div class="custom-50p">
        <div class="custom-error input-text-width">
            <mat-form-field class="full-width">
                <input
                    type="number"
                    matInput
                    placeholder="{{
                        'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.MINIMUM_RAINFALL_THRESHOLD' | translate
                    }}"
                    [(ngModel)]="formValues.stormValue"
                    (ngModelChange)="modelChange()"
                    required
                    name="stormValue"
                    [disabled]="isStudyLocked"
                    [pattern]="isMetric?numericWith1DecimalPlaces:numericWith2DecimalPlaces"
                />
                <span matSuffix>{{ unitValue }}</span>
            </mat-form-field>
            <div
                class="mat-error"
                *ngIf="stormDefnitionForm?.controls?.stormValue?.errors"
            >
                {{ isMetric ? ('COMMON.NUMERIC_PRECISION_ERROR_1_DECIMAL' | translate) : ('COMMON.NUMERIC_PRECISION_ERROR_2_DECIMALS' | translate) }}
            </div>
        </div>
    </div>
    <div class="custom-50p">
        <div class="custom-error input-text-width">
            <mat-form-field class="full-width">
                <input
                    type="number"
                    matInput
                    placeholder="{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.MINIMUM_TIME' | translate }}"
                    [(ngModel)]="formValues.minTime"
                    (ngModelChange)="modelChange()"
                    required
                    name="minTime"
                    [disabled]="isStudyLocked"
                    [pattern]="isPositiveInteger"
                />
                <span matSuffix>min</span>
            </mat-form-field>
            <div
                class="mat-error"
                *ngIf="stormDefnitionForm?.controls?.minTime?.errors"
            >
                {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.INTEGER_VALIDATION_ERROR' | translate }}
            </div>
        </div>
    </div>
    <div class="summary-details">
        <p class="red">{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.STORM_EVENT_WARNING_INFO' | translate }}</p>
        <p>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.STORM_EVENT.STORM_EVENT_LIST' | translate }}</p>
        <ul class="list">
            <li>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.STORM_EVENT.NOTE_1' | translate }}
                <i>
                    <b>"{{ formValues.stormValue }} {{ unitText }}"</b>
                </i>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.STORM_EVENT.RAINFALL_SIGNIFICANT' | translate }}
            </li>
            <li>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.STORM_EVENT.NOTE_2' | translate }}
                <i>
                    <b>"<span>{{ formValues.minTime }}</span>{{ 'SLIICER_TABLE.SLICER_SUMMARY.STORM_EVENT.MINUTES' | translate }}"</b>
                </i>
            </li>
        </ul>
    </div>
</form>
