import { Component, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-save-curve-dialog',
    templateUrl: './save-curve-dialog.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})
export class SaveCurveDialogComponent {
    public nameControl = new FormControl('', [Validators.required, Validators.maxLength(35)]);
    constructor(public dialogRef: MatDialogRef<SaveCurveDialogComponent>) {}
}
