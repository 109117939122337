export class WidgetFilterData {
    public customerID: number;
    public locationIDs?: Array<number>;
    public locationGroupId?: Array<number>;
    public monitorIds?: Array<number>;
    public startDate?: Date;
    public endDate?: Date;
    public entityIDs?: Array<number>;
    public batteryStatus?: string;
    public batteryVoltage?: number;
    public alarmStatus?: Array<number>;
    public users?: Array<number>;
    public errThreshold?: number;
    public alarmType?: Array<number>;
    public anomalyReason?: number;
    public blockageStatus?: Array<string>;
}

export class FilterSettings {
    public displayLocations?: boolean;
    public displayInactiveLocations?: boolean;
    public displayLocationGroup?: boolean;
    public displayEntities?: boolean;
    public displayDateRanges?: boolean;
    public dateRangeOptional?: boolean;
    public singleLocationSelect?: boolean;
    public singleLocationGroupSelect?: boolean;
    public singleMonitorSelect?: boolean;
    public removeFullWidthFromLocationInput?: boolean;
    public displayBatteryStatusItems?: boolean;
    public preselectedBatteryStatus?: string;
    public displayAlarmStatus?: boolean;
    public displayUsers?: boolean;
    public dateSpanPastWeek?: boolean;
    public displayErrorThreshold?: boolean;
    public displayAlarmType?: boolean;
    public skipInitialCall?: boolean;
    public displayAllEntities?: boolean;
    public minDisplayLimit?: number;
    public maxDisplayLimit?: number;
    public setAlarmEmptyDate?: boolean;
    public isEntitiesRequired?: boolean;
    public displayAnomaliesReasons?: boolean;
    public displayblockStatus?: boolean;
    public singleBlockageSelect?: boolean;
    public onlyDisplayMP1Locations?: boolean;
}
