export class AddLocationPipes {
    public hc: number;
    public roughness: number;
    public slope: number;
    public frictionFactor: number;
    public nominalDiameter: number;
    public capacity: number;
    public height: number;
    public width: number;
    public startDate: Date;
    public installationID: number;
    public installationType = 'Pipe';
    public shapeType: string;
    public installationDesc1: string;
    public installationDesc2: string;
    public installationDesc3: string;
    public installationDesc4: string;
    public installationDesc5: string;
    public lookupDesc: string;
    public MonitoringPoint: number;
    public wasCreatedFromImport: boolean;

    constructor() {
        this.hc = 0;
        this.roughness = 0;
        this.slope = 0;
        this.frictionFactor = 3;
        this.nominalDiameter = 0;
        this.startDate = new Date();
        this.installationID = 0;
        this.installationType = 'Pipe';
        this.shapeType = 'Round';
        this.installationDesc1 = '';
        this.installationDesc2 = '';
        this.installationDesc3 = '';
        this.installationDesc4 = '';
        this.installationDesc5 = '';
        this.lookupDesc = '';
        this.wasCreatedFromImport = false;
    }
}
