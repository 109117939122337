import { Component, OnInit, ChangeDetectorRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Subscription } from 'rxjs';
import { OrderByPipe } from 'app/shared/pipes/order-by-pipe';
import { FilterDataSource } from 'app/shared/components/paging/filter-data-source';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { BehaviorSubject } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { StringUtils } from 'app/shared/utils/string-utils';
import { FormControl } from '@angular/forms';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { LocationService } from 'app/shared/services/location.service';
import { SharedService } from 'app/shared/services/shared.service';
import { LocationGroup, LocationGroupEditorData } from 'app/shared/models/location-group';
import { LocalLocationGroup, Locations } from 'app/shared/models/locations';
import {
    activeInactiveLocationQueryParam,
    AppQueryParams,
    customerLocationGroupQueryParam,
    customerQueryParam,
} from 'app/shared/models/customer';
import { SelectItem } from 'app/shared/models/selected-Item';
import {
    IComponentCustomizedConfirmation,
    IComponentCustomizedConfirmationResult,
} from 'app/shared/models/customized-confirmation-box';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-location-group-editor',
    templateUrl: './location-group-editor.component.html',
    styleUrls: ['./location-group-editor.component.scss'],
})
export class LocationGroupEditorComponent implements OnInit, OnDestroy {
    public locationGroups: Array<LocationGroup>;
    public locations: Array<Locations>;
    public groupPagedItems: Array<LocalLocationGroup>;
    public isValidForCheckboxList: boolean;
    public hideGroupSection: boolean;
    public locationGroupName: string;
    public locationGroupDescription: string;
    public locationGroupIdUpdate: number;
    public customerId: number;
    public localLocationGroups: Array<LocalLocationGroup> = [];
    public locationGroupHeader = 'LOCATION GROUP EDITOR';
    public actionSaveEdit: string;
    public isSelected: boolean;
    public hideLocationMsg: boolean;
    public hideCreateButton: boolean;
    public disableUpdate: boolean;
    public setFirstPage: boolean;
    public validateGroupName = false;
    public oldLocationGroupName: string;
    public oldLocationGroupDescription: string;
    public filterLocations: Array<number> = [];
    public oldFilterLocations: Array<number> = [];
    private subscriptions = new Array<Subscription>();
    public locationGLoadingState: boolean;
    public selectedLocation: Array<SelectItem> = [];
    private oldSelectedLocation: Array<SelectItem> = [];
    public filteredLocations: Array<SelectItem> = [];
    private customerID: number;
    private validateLocation: boolean;

    public locationGroupDisplayedColumns = ['name', 'editDeleteLocation'];
    public locationGroupFilterColumns = ['name'];
    public locationGroupDataSource: FilterDataSource | null;
    @ViewChild(MatPaginator, {static: true}) public locationGroupPaginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) public locationGroupSort: MatSort;
    public locationGroupDataChange: BehaviorSubject<LocalLocationGroup[]> = new BehaviorSubject<LocalLocationGroup[]>(
        [],
    );
    public get locationGroupData(): LocalLocationGroup[] {
        return this.locationGroupDataChange.value;
    }
    public totalPaginationLength: number;

    /**
     * Reg Ex. Pattern for alpha numeric
     */
    public alphaNumericPattern = REGEX_CONFIG.alphaNumericPattern;

    /**
     * Represents inpus for Confirmation Box
     */
    public conformationDataInput: IComponentCustomizedConfirmation;

    /**
     * Represents show Confirmation box flag
     */
    public showConfirmation: boolean;

    /**
     * Represents Selected Location Group
     */
    private selectedLocationGroup: LocalLocationGroup;

    /**
     * Represents Selected Location Group Details
     */
    private selectedLocationGroupDetails: LocationGroup;

    /*
     * flag to check duplicate location name
     */
    public isDuplicateLocationName: boolean;

    /**
     * checks confirmation Type i.e Update , Delete etc
     */
    private confirmationType: string;

    /**
     * Reprsents customer search input
     */
    public searchString = new FormControl();

    /**
     * Represents saved initial list of location groups
     */
    public backUpLocationGroups = new Array<LocalLocationGroup>();

    /**
     * Represents selected location group
     */
    private selectedLocationGroupID: number;

    public locationGroupHeaderText: string;
    public addLocationGroupHeader: string;
    public locationGroupCreateSuccess: string;
    public locationGroupUpdateSuccess: string;
    public dismissText: string;
    public editLocationHeader: string;
    public deleteDialogTitle: string;
    public deleteDialogMsg: string;
    public locationGroupDeleteMsg: string;
    public proceedBtn: string;
    public cancelBtn: string;
    public deleteBtn: string;
    public updateDialogTitle: string;
    public updateConfirmationMsg: string;
    public updateSuccessfullyMsg: string;
    private locationGroupID: number;

    constructor(
        private locationGroupService: LocationGroupService,
        private cdr: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) private data: LocationGroupEditorData,
        public dialogRef: MatDialogRef<LocationGroupEditorComponent>,
        private locationService: LocationService,
        private activatedRoute: ActivatedRoute,
        private sharedService: SharedService,
        private uiUtilsService: UiUtilsService,
        private translate: TranslateService,
        public router: Router,
        private statusCodeService: StatusCodeService,
    ) {
        this.searchString.valueChanges
            .pipe(debounceTime(400))
            .pipe(distinctUntilChanged())
            .subscribe((res: string) => {
                this.filterLocationGroups(res);
            });
    }

    public ngOnInit() {
        this.translate.get('LOCATION_GROUP_EDITOR.LOCATION_GROUP_HEADER').subscribe((res: string) => {
            this.locationGroupHeaderText = res;
        });
        this.translate.get('LOCATION_GROUP_EDITOR.ADD_LOCATION_GROUP').subscribe((res: string) => {
            this.addLocationGroupHeader = res;
        });
        this.translate.get('LOCATION_GROUP_EDITOR.LOCATION_GROUP_CREATE_SUCCESS').subscribe((res: string) => {
            this.locationGroupCreateSuccess = res;
        });
        this.translate.get('LOCATION_GROUP_EDITOR.LOCATION_GROUP_UPDATE_SUCCESS').subscribe((res: string) => {
            this.locationGroupUpdateSuccess = res;
        });
        this.translate.get('LOCATION_GROUP_EDITOR.EDIT_LOCATION_GROUP_HEADER').subscribe((res: string) => {
            this.editLocationHeader = res;
        });
        this.translate.get('LOCATION_GROUP_EDITOR.LOCATION_GROUP_DELETE_TITLE').subscribe((res: string) => {
            this.deleteDialogTitle = res;
        });
        this.translate.get('LOCATION_GROUP_EDITOR.DELETE_CONFIRM_MSG').subscribe((res: string) => {
            this.deleteDialogMsg = res;
        });
        this.translate.get('LOCATION_GROUP_EDITOR.LOCATION_GROUP_DELETE_MSG').subscribe((res: string) => {
            this.locationGroupDeleteMsg = res;
        });
        this.translate.get('LOCATION_GROUP_EDITOR.UPDATE_CONFIRMATION_MSG').subscribe((res: string) => {
            this.updateConfirmationMsg = res;
        });
        this.translate.get('LOCATION_GROUP_EDITOR.UPDATED_SUCCESSFULLY_MSG').subscribe((res: string) => {
            this.updateSuccessfullyMsg = res;
        });
        this.translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismissText = res;
        });
        this.translate.get('COMMON.PROCEED_BTN').subscribe((res: string) => {
            this.proceedBtn = res;
        });
        this.translate.get('COMMON.DELETE_TOOLTIP').subscribe((res: string) => {
            this.deleteBtn = res;
        });
        this.translate.get('COMMON.CANCEL_BUTTON').subscribe((res: string) => {
            this.cancelBtn = res;
        });
        this.translate.get('COMMON.UPDATE_CONFIRMATION_TITLE').subscribe((res: string) => {
            this.updateDialogTitle = res;
        });

        const customerIDSuscription = this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            // get updated customer id
            this.customerID = Number(params.get(customerQueryParam));
            this.locationGroupID = Number(params.get(customerLocationGroupQueryParam));
        });
        this.subscriptions.push(customerIDSuscription);

        this.locationGroupHeader = this.locationGroupHeaderText;
        this.actionSaveEdit = 'Save';
        this.hideGroupSection = false;
        this.locationGroups = this.data.locationGroups;
        const filterPipe = new OrderByPipe();
        this.localLocationGroups = this.backUpLocationGroups = this.locationGroups;
        this.localLocationGroups = this.createLocationGroups(this.locationGroups);
        this.localLocationGroups = filterPipe.transform(this.localLocationGroups, 'name', false);
        this.locations = this.data.locations;
        this.locations = filterPipe.transform(this.locations, 'locationName', false);
        this.locations.forEach((location) => {
            this.filteredLocations.push({
                id: location.locationId,
                text: location.locationName,
                name: location.locationName
            });
        });
        this.generateLocationGroupTable();
        if (this.data.selectedGroupId) {
            this.updateLocationGroup(
                this.data.locationGroups.find((group) => group.locationGroupID === this.data.selectedGroupId),
            );
        }
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public emitCloseLocationGroupPopup() {
        this.dialogRef.close({ success: false });
    }

    public showGroupPageRecords(pagedItems: LocalLocationGroup[]) {
        this.groupPagedItems = pagedItems;
    }

    public createLocationGroup() {
        this.locationGroupHeader = this.addLocationGroupHeader;
        this.hideGroupSection = true;
        this.locationGroupName = '';
        this.locationGroupDescription = '';
        this.actionSaveEdit = 'Save';
        this.isValidForCheckboxList = true;
        this.hideLocationMsg = false;
        this.selectedLocation = [];
        // clear the previously selected while creating the location group
        this.resetLocations();
        // Setting Old location group to null when creating a new
        this.oldLocationGroupName = null;
    }

    public saveLocationGroup() {
        // capitalized the string value with maximum one space in between word
        this.locationGroupName = StringUtils.capitalizedStringWithSpace(this.locationGroupName);
        this.locationGroupDescription = StringUtils.capitalizedStringWithSpace(this.locationGroupDescription);

        // setup location groups
        const locationGroup = <LocationGroup>{
            // customer id
            customerID: this.customerID,

            // location group name
            name: this.locationGroupName,

            // location description
            description: this.locationGroupDescription,

            // collection of selected groups
            locations: this.selectedLocation.map((element) => element.id),
        };

        if (this.actionSaveEdit === 'Update') {
            this.isValidForCheckboxList = false;
            locationGroup.locationGroupID = this.locationGroupIdUpdate;
            this.showEditConfirmationLocationGroup(this.customerID, locationGroup);
        } else if (this.actionSaveEdit === 'Save') {
            this.locationGLoadingState = true;

            locationGroup.locationGroupID = 0;
            //  Below will check if it is to add or update location group and show messsage accordingly
            const displayedMessage =
                this.actionSaveEdit === 'Save'
                    ? `${locationGroup.name} ${this.locationGroupCreateSuccess}`
                    : this.locationGroupUpdateSuccess;
            const subscription1 = this.locationGroupService.postLocationGroup(this.customerID, locationGroup).subscribe(
                (result) => {
                    this.locationGLoadingState = false;
                    this.snackBar.open(displayedMessage, this.dismissText, {
                        duration: 10000,
                    });
                    this.locationGLoadingState = false;
                    this.selectedLocationGroupID = result.locationGroupID;

                    this.statusCodeService.isLocationGroupsModified.next(true);
                    this.sharedService.closeLocationGroup(true);
                    this.resetLocations();
                    this.loadLocationGroups();
                },
                (error: any) => {
                    this.locationGLoadingState = false;

                    if (typeof error.json() === 'object' && error.json().hasOwnProperty('value')) {
                        this.snackBar.open(error.json().value, this.dismissText, {
                            panelClass: 'custom-error-snack-bar',
                        });
                    }
                    this.locationGLoadingState = false;
                    this.sharedService.closeLocationGroup(false);
                },
            );

            this.subscriptions.push(subscription1);
        }
        this.selectedLocationGroupID = locationGroup.locationGroupID;
        this.locationGroupHeader = this.locationGroupHeader;
    }

    /**
     * Reset previously selected locations
     */
    private resetLocations() {
        // clear the previously selected while creating the location group
        if (this.filteredLocations.length > 0) {
            this.filteredLocations.forEach((x) => {
                if (x.isChecked) {
                    x.isChecked = false;
                }
            });
        }
    }

    public loadLocationGroups() {
        this.locationGLoadingState = true;
        const subscription3 = this.locationGroupService.getLocationGroups(this.customerID).subscribe(
            (result) => {
                this.locationGroups = new Array<LocationGroup>();
                if (result && result.locationGroups && result.locationGroups.length > 0) {
                    this.locationGroups = result.locationGroups;
                    this.data.locationGroups = result.locationGroups;
                } else {
                    this.locationGroups = [];
                }
                this.locationGLoadingState = false;
                this.localLocationGroups = this.createLocationGroups(this.locationGroups);
                const filterPipe = new OrderByPipe();
                this.localLocationGroups = filterPipe.transform(this.localLocationGroups, 'name', false);
                this.hideGroupSection = false;
                this.generateLocationGroupTable();
                this.isValidForCheckboxList = false;
                this.setFirstPage = true;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => (this.locationGLoadingState = false),
        );
        this.subscriptions.push(subscription3);
    }

    /**
     * Method takes a sting as an argument and filters out customers whose names include the string
     * @param searchString - string to search for customer
     */
    public filterLocationGroups(searchString: string) {
        this.localLocationGroups = this.data.locationGroups;
        searchString = searchString.trim();
        this.localLocationGroups = this.localLocationGroups.filter((location) =>
            location.name.toLowerCase().includes(searchString.toLowerCase()),
        );
        this.locationGroupPaginator.pageIndex = 0;

        this.generateLocationGroupTable();
    }

    public generateLocationGroupTable() {
        this.locationGroupDataSource = null;
        this.locationGroupDataChange = new BehaviorSubject<LocalLocationGroup[]>([]);
        if (this.localLocationGroups.length > 0) {
            // for (let locationGroup of this.localLocationGroups) {
            this.localLocationGroups.forEach((locationGroup, index) => {
                const locationGroupCopiedData = this.locationGroupData.slice();

                // Flag to show the edited row
                let highLight = false;

                if (this.locationGroupName !== null && this.locationGroupName === locationGroup.name) {
                    const pageIndex = index / this.locationGroupPaginator.pageSize;
                    const finalPageIndex = +pageIndex.toString().split('.')[0];
                    this.locationGroupPaginator.pageIndex = finalPageIndex;
                    highLight = true;
                }
                const data = {
                    name: locationGroup.name,
                    customerID: locationGroup.customerID,
                    locationGroupID: locationGroup.locationGroupID,
                    description: locationGroup.description,
                    locations: locationGroup.locations,
                    isSelected: highLight,
                };
                locationGroupCopiedData.push(data);
                this.locationGroupDataChange.next(locationGroupCopiedData);
            });
            this.backUpLocationGroups = this.locationGroupData;
            this.locationGroupDataSource = new FilterDataSource(
                this.locationGroupDataChange,
                this.locationGroupData,
                this.locationGroupPaginator,
                this.locationGroupSort,
                this.locationGroupFilterColumns,
            );
            this.totalPaginationLength = this.locationGroupData.length;
        } else {
            this.totalPaginationLength = 1;
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public exitLocationGroup(evt) {
        this.isDuplicateLocationName = false;
        this.locationGroupHeader = this.locationGroupHeader;
        this.hideGroupSection = false;
        this.hideLocationMsg = false;
        this.isValidForCheckboxList = false;
        this.hideCreateButton = false;
        this.setFirstPage = true;
        // clear the previously selected locations
        this.resetLocations();
        evt.preventDefault();
    }

    public updateLocationGroup(locationGroups: any) {
        this.hideGroupSection = true;
        this.hideLocationMsg = true;
        this.hideCreateButton = true;
        this.locationGroupName = locationGroups.name;
        this.oldLocationGroupName = this.locationGroupName;
        this.locationGroupDescription = locationGroups.description;
        this.oldLocationGroupDescription = this.locationGroupDescription;
        this.locationGroupIdUpdate = locationGroups.locationGroupID;
        this.selectedLocationGroupID = locationGroups.locationGroupID;
        this.locationGroupHeader = this.editLocationHeader;
        this.actionSaveEdit = 'Update';
        this.isValidForCheckboxList = true;
        this.disableUpdate = true;
        this.validateGroupName = true;
        // Binding the location from  updating location group to selected location group field
        this.selectedLocation = [];
        this.filteredLocations = [];
        // store selected location and marked as isSelected true;
        locationGroups.locations.forEach((location: { locationID: number; name: string }) => {
            this.selectedLocation.push({
                id: location.locationID,
                text: location.name,
                isChecked: true,
            });
        });
        this.locations.forEach((location) => {
            this.filteredLocations.push({
                id: location.locationId,
                text: location.locationName,
                name: location.locationName,
                serie: location.series,
            });
        });
        this.uiUtilsService.safeChangeDetection(this.cdr);

        this.oldSelectedLocation = JSON.parse(JSON.stringify(this.selectedLocation));
    }

    public validateUpdateBtnForGroupField() {
        this.locationGroupName = this.locationGroupName.trim();

        if (this.locationGroupName.length > 0) {
            if (
                (this.locationGroupData.find((x) => x.name.toLowerCase() === this.locationGroupName.toLowerCase()) &&
                    this.oldLocationGroupName !== this.locationGroupName) ||
                this.locationGroupName.toLowerCase() === 'all locations'
            ) {
                this.isDuplicateLocationName = true;
            } else {
                this.locationGroupName = StringUtils.capitalizedStringWithSpace(this.locationGroupName);
                this.isDuplicateLocationName = false;
            }
        }
        this.filterLocations = this.selectedLocation
            .sort((a: SelectItem, b: SelectItem) => {
                return a.id - b.id;
            })
            .map((element) => element.id);
        this.oldFilterLocations = this.oldSelectedLocation
            .sort((a: SelectItem, b: SelectItem) => {
                return a.id - b.id;
            })
            .map((element) => element.id);

        if (this.oldFilterLocations.length >= this.filterLocations.length) {
            this.validateLocation = this.oldFilterLocations.every((el, ix) => el === this.filterLocations[ix]);
        } else {
            this.validateLocation = this.filterLocations.every((el, ix) => el === this.oldFilterLocations[ix]);
        }
        if (
            this.validateLocation &&
            this.oldLocationGroupName === this.locationGroupName &&
            this.oldLocationGroupDescription === this.locationGroupDescription
        ) {
            this.disableUpdate = true;
        } else {
            this.disableUpdate = false;
        }
    }

    /**
     * Represents the Delete Action of Location Group
     * @param locationGroups -selected locationGroup
     */
    public showDeleteConfirmationLocationGroup(locationGroups: LocalLocationGroup) {
        this.selectedLocationGroup = locationGroups;
        this.conformationDataInput = {
            title: this.deleteDialogTitle,
            message: this.deleteDialogMsg,
            okText: this.deleteBtn,
            cancelText: this.cancelBtn,
        };
        this.showConfirmation = true;
        this.confirmationType = 'Delete';
    }

    /**
     * Represents the Update Action of Location Group
     * @param customerId - customerId
     * @param locationGroup -selected locationGroup
     */
    public showEditConfirmationLocationGroup(customerId: number, locationGroup: LocationGroup) {
        this.selectedLocationGroupDetails = locationGroup;
        this.hideLocationMsg = true;
        this.isValidForCheckboxList = true;
        this.conformationDataInput = {
            title: this.updateDialogTitle,
            message: this.updateConfirmationMsg,
            okText: this.proceedBtn,
            cancelText: this.cancelBtn,
        };
        this.showConfirmation = true;
        this.confirmationType = 'Update';
    }

    /**
     * Represents the Confirmation Action of Confirmation Box
     * @param confirmation -confirmation response
     */
    public listenConfirmation(confirmation: IComponentCustomizedConfirmationResult) {
        if (confirmation.whichButtonPressed === 'ok') {
            this.showConfirmation = false;
            // Represents the Confirmation Action of Delete Location Group
            if (this.confirmationType === 'Delete') {
                // Represents the Confirmation Action of Delete Location Group
                this.deleteLocationGroup();
            } else if (this.confirmationType === 'Update') {
                // Represents the Confirmation Action of Update Location Group
                this.editLocationGroup();
            }
        } else {
            if (this.confirmationType === 'Delete') {
                this.showConfirmation = false;
            } else if (this.confirmationType === 'Update') {
                this.locationGroupHeader = this.locationGroupHeader;
                this.showConfirmation = false;
                this.hideGroupSection = false;
                this.hideLocationMsg = false;
                this.isValidForCheckboxList = false;
                this.hideCreateButton = false;
                this.setFirstPage = true;
                if (event !== undefined) {
                    event.preventDefault();
                }
            }
        }
    }

    /**
     * Represents the Delete Location Group Action
     */
    public deleteLocationGroup() {
        this.locationGLoadingState = true;

        const subscription4 = this.locationGroupService
            .deleteLocationGroups(this.selectedLocationGroup.locationGroupID)
            .subscribe(
                (result) => {
                    this.localLocationGroups.splice(this.localLocationGroups.indexOf(this.selectedLocationGroup), 1);
                    this.locationGroups = <LocationGroup[]>this.localLocationGroups;
                    this.snackBar.open(
                        this.selectedLocationGroup.name + ' ' + this.locationGroupDeleteMsg,
                        this.dismissText,
                        {
                            duration: 10000,
                        },
                    );
                    this.locationGLoadingState = false;
                    this.sharedService.closeLocationGroup(true);
                    this.loadLocationGroups();
                    if (this.locationGroupID === this.selectedLocationGroup.locationGroupID) {
                        // get current query params for active/inactive locations
                        const activeAllFlag = Number(
                            this.activatedRoute.snapshot.queryParamMap.get(activeInactiveLocationQueryParam),
                        );

                        this.router.navigate([], {
                            queryParams: <AppQueryParams>{
                                c: this.customerID,
                                lg: 0,
                                lt: activeAllFlag || undefined,
                            },
                            relativeTo: this.activatedRoute,
                        });
                    }
                    this.statusCodeService.isLocationGroupsModified.next(true);
                },
                (error) => {
                    this.locationGLoadingState = false;
                    this.sharedService.closeLocationGroup(false);
                },
            );
        this.subscriptions.push(subscription4);
    }

    /**
     * Represents the Delete Location Group Action
     */
    public editLocationGroup() {
        this.locationGLoadingState = true;
        const subscription2 = this.locationGroupService
            .postLocationGroup(this.customerID, this.selectedLocationGroupDetails)
            .subscribe(
                (result) => {
                    this.locationGLoadingState = false;

                    this.snackBar.open(
                        this.selectedLocationGroupDetails.name + ' ' + this.updateSuccessfullyMsg,
                        this.dismissText,
                        {
                            duration: 10000,
                        },
                    );

                    this.hideGroupSection = false;
                    this.hideLocationMsg = false;
                    this.hideCreateButton = false;
                    this.loadLocationGroups();
                    this.locationGLoadingState = false;
                    this.sharedService.closeLocationGroup(true);
                    this.statusCodeService.isLocationGroupsModified.next(true);
                },
                (error) => {
                    this.locationGLoadingState = false;
                    if (typeof error.json() === 'object' && error.json().hasOwnProperty('responseMessage')) {
                        this.snackBar.open(error.json().responseMessage, this.dismissText, {
                            panelClass: 'custom-error-snack-bar',
                        });
                    }
                    this.locationGLoadingState = false;
                    this.sharedService.closeLocationGroup(false);
                },
            );
        this.subscriptions.push(subscription2);
        this.locationGroupHeader = this.locationGroupHeader;
    }

    public createLocationGroups(locationGroups: LocationGroup[]) {
        const tempLocationGroups: LocalLocationGroup[] = [];
        if (this.locationGroups !== null && this.locationGroups !== undefined) {
            for (let counter = 0; counter < this.locationGroups.length; counter++) {
                tempLocationGroups.push({
                    customerID: locationGroups[counter].customerID,
                    locationGroupID: locationGroups[counter].locationGroupID,
                    name: locationGroups[counter].name,
                    description: locationGroups[counter].description,
                    locations: locationGroups[counter].locations,
                });
            }
        }
        return tempLocationGroups;
    }

    public selectedNewLocations(locations) {
        this.selectedLocation = locations.filter((x) => x.isChecked);
        if (this.selectedLocation.length > 1) {
            this.isValidForCheckboxList = true;
            this.hideLocationMsg = true;
            if (this.actionSaveEdit === 'Update') {
                this.validateUpdateBtnForGroupField();
            }
        } else {
            this.isValidForCheckboxList = false;
            this.hideLocationMsg = false;
        }
    }

    // Refresh selected location value while searching from searching text field
    public refreshValue(locations: SelectItem[]): void {
        for (const location of locations) {
            if (!this.locationService.containsLocation(location, this.selectedLocation)) {
                this.selectedLocation.push(location);
            }
        }
    }

    /**
     * //checks if the name length is more than specific length
     */
    public validateLocationgroupNameLength(str: string, strLength: number): boolean {
        if ((str + '').length > strLength) {
            return true;
        } else {
            return false;
        }
    }
}
