import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { HttpClient } from '@angular/common/http';
import { Config } from 'app/shared/services/config';


export interface AnnouncementDetails {
    id: number;
    modifiedOn: string;
    modifiedByUserId: string;
    isActive: boolean;
    text: string;
    start: Date;
    end: Date;
}

export interface AnnouncementRequest {
    start: string;
    end: string;
    isActive: boolean;
    text: string;
}



@Injectable()
export class AnnouncementService {

    constructor(
        private http: HttpClient,
    ) {}

    public getActiveAnnouncement(){
        const path = Config.urls.announcement + '/active';
        return this.http.get<string>(path);
    }

    public setAnnouncement(announcement: AnnouncementRequest){
        const path = Config.urls.announcement;
        return this.http.put<AnnouncementDetails>(path, announcement); 
    }

    public getAnnouncement(){
        const path = Config.urls.announcement;
        return this.http.get<AnnouncementDetails>(path);
    }
    
}
