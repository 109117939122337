<div id="edit-admin-user-dialog">
    <div class="popupHeader" mat-dialog-title>
        <span>Permission Denied </span>
        <span class="app-flex-filler"></span>
    </div>
    <div mat-dialog-content>
        <p>Please contact Administrator to view the details.</p>
    </div>
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button type="submit" (click)="dialogRef.close()">ok</button>
        </div>
    </div>
</div>
