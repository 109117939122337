import {
    Component,
    OnInit,
    Inject,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    ChangeDetectorRef,
    OnDestroy,
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBarRef as MatSnackBarRef, LegacySimpleSnackBar as SimpleSnackBar, MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { Subscription } from 'rxjs';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { GetPermissionsResponseCustomer } from 'app/shared/models/users-permission';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { StringUtils } from 'app/shared/utils/string-utils';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { EmailService } from 'app/shared/services/email.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { AuthService } from 'app/shared/services/auth.service';
import { customerQueryParam } from 'app/shared/models/customer';
import { AssistanceCenter } from 'app/shared/models/assistance-center';
import { ApplicationSurvey, EmailContents } from 'app/shared/models/email-contents';
import { Attachment, Email } from 'app/shared/models/email';
import { map, startWith } from 'rxjs/operators';
import { UsersService } from 'app/pages/admin/users.service';
import { CountryISO, SearchCountryField } from "ngx-intl-tel-input-gg";

@Component({
    selector: 'app-assistance-center',
    templateUrl: './assistance-center.component.html',
    styleUrls: ['./assistance-center.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class AssistanceCenterComponent implements OnInit, OnDestroy {
    public selectedTab: string;
    public selectedTabTitel: string;
    public emailContent: {
        customerName: string;
        phoneNumber;
        reasonForCall: string;
        userName: string;
        emailPrimary: string;
        emailSecondary: string;
    };
    public emailContents = new EmailContents();
    private subscriptions = new Array<Subscription>();
    public reportImage: any;

    /**
     * applicationSurvey will use to bind values on UI.
     */

    public applicationSurvey = new ApplicationSurvey();

    /**
     * Valid file extensions
     */
    private validFileExtensions = new Array<string>();

    /**
     * validExtensions to keep file extension.
     */
    public validExtensions: boolean;
    /**
     * Reg Ex. Pattern for alpha numeric
     */
    public alphaNumericPattern = REGEX_CONFIG.alphaNumericPattern;

    /**
     * Reg Ex. Pattern for alpha numeric with spaces and - and _
     */
    public alphaNumericWith_Pattern = REGEX_CONFIG.alphaNumericWith_Pattern;
    /**
     * Reg Ex. Pattern for space arround
     */
    public noSpaceArround = REGEX_CONFIG.noSpaceArround;

    /**
     * Reg Ex Pattern for email
     */
    public emailPattern = REGEX_CONFIG.strictEmailPattern;

    public numericOnlyPattern = REGEX_CONFIG.numericOnlyPattern;
    /**
     * Reg Ex Pattern for is Empty
     */
    public isEmptyPattern = REGEX_CONFIG.isEmpty;

    /**
     * Attachment file-Name.
     */
    private fileName: string;

    /**
     * loader flag @reportDefectLoadingState
     */
    public reportDefectLoadingState: boolean;
    public isRequestDone: boolean;

    /**
     * loader flag @appSurveyReportState
     */
    public appSurveyReportState: boolean;

    /**
     * File-size limit 20 MB.
     */
    public maxFileSize = 20 * 1024 * 1024;
    public exceedFileSize: boolean;

    /**
     * flag to check if customer name or reason for call is empty (contains only spaces)
     */
    public isEmpty = false;

    /**
     * flag to check if customer name is valid
     */
    public validCustomerName = false;

    /**
     * flag to check if customer name is invalid
     */
    public invalidCustomerName: boolean;
    /**
     * @surveyRating will use to show message at UI.
     */

    public surveyRating: boolean;

    /**
     * @howUseFulAppRating will use to show message at UI.
     */
    public howUseFulAppRating: boolean;

    /**
     * @featureMakeAppEasyRating will use to show message at UI.
     */
    public featureMakeAppEasyRating: boolean;

    /**
     * @navigationSatisficationRating will use to show message at UI.
     */
    public navigationSatisficationRating: boolean;

    private customerID: number;
    public filteredassignedCustomers: Observable<Array<GetPermissionsResponseCustomer>>;
    public customers = new Array<GetPermissionsResponseCustomer>();
    public assignedCustomerCtrl = new FormControl();

    public callSentSuccessMsg: string;
    public defectReportSuccess: string;
    public surveyReportSuccess: string;
    public dismissText: string;

    public SearchCountryField = SearchCountryField;
    public CountryISO = CountryISO;
    public preferredCountries: CountryISO[] = [
      CountryISO.UnitedStates,
      CountryISO.UnitedKingdom
    ];

    constructor(
        private emailService: EmailService,
        private snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<AssistanceCenterComponent>,
        @Inject(MAT_DIALOG_DATA) private data: AssistanceCenter,
        private cdr: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private customerService: CustomerService,
        private uiUtilsService: UiUtilsService,
        private translate: TranslateService,
        private authService: AuthService,
        private usersService: UsersService
    ) {}

    public ngOnInit() {
        this.translate.get('CUSTOMER_SUPPORT.CALL_REQUEST_SENT_SUCCESS').subscribe((res: string) => {
            this.callSentSuccessMsg = res;
        });
        this.translate.get('CUSTOMER_SUPPORT.DEFECT_REPORT_SENT_SUCCESS').subscribe((res: string) => {
            this.defectReportSuccess = res;
        });
        this.translate.get('CUSTOMER_SUPPORT.SURVEY_REPORT_SENT_SUCCESS').subscribe((res: string) => {
            this.surveyReportSuccess = res;
        });
        this.translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismissText = res;
        });

        // make the default selection No at survey form
        this.applicationSurvey.featureMakeAppEasy = 'No';

        this.validFileExtensions = ['JPEG', 'JPG', 'GIF', 'PNG', 'PDF'];
        this.validExtensions = true;
        this.exceedFileSize = true;
        this.selectedTab = this.data.selectedTab;
        this.selectedTabTitel = this.data.selectedTabName;
        this.emailContent = { customerName: '', phoneNumber: '', reasonForCall: '', userName: '', emailPrimary: '', emailSecondary: '' };
        this.emailContents.emailAddress = this.user.email;

        // subscribe to route params to get customerID
        this.activatedRoute.queryParamMap.subscribe(
            ((params: ParamMap) => {
                this.customerID = Number(params.get(customerQueryParam))
            }),
        );

        // If selecting Customer Support, get the list of all assigned customers.
        if (this.selectedTab === '0') {
            this.getAssignedUsers();
        }

        this.translate.get(`TOP_NAV.${this.selectedTabTitel.replace(/ /g, '_')}`).subscribe((res: string) => {
            this.selectedTabTitel = res;
        });

        this.usersService.userInfo$.subscribe(userInfo => {
            this.emailContent.userName = (userInfo.firstName || '') + ' ' + (userInfo.lastName || '');
            this.emailContent.phoneNumber = userInfo.phoneNumber || '';
            this.emailContent.emailPrimary = userInfo.userEmail || '';
            this.emailContent.emailSecondary = userInfo.secondaryEmail || '';
            this.emailContents.emailAddress = this.emailContent.emailPrimary;
        });
    }

    /**
     * Gets the user object
     */
    private get user() {
        return this.authService.user;
    }

    private getAssignedUsers() {
        this.customers = new Array<GetPermissionsResponseCustomer>();

        // get list of assigned customers.
        this.subscriptions.push(
            this.customerService.userCustomersWithLocations.subscribe((customers) => {
                if (customers && customers.length > 0) {
                    customers.forEach((customer) => {
                        this.customers.push(customer);
                        if(customer.customer && customer.customer.customerID === this.customerID) {
                            this.emailContent.customerName = customer.customer.customerName;
                            this.checkCustomerNameValidation();
                        }
                    });
                }

                // filter assigned customers on selection
                this.filteredassignedCustomers = this.assignedCustomerCtrl.valueChanges.pipe(startWith(null)).pipe(
                    map((customerName) => {
                        return customerName ? this.filterCustomers(customerName) : this.customers.slice();
                    }),
                );
            }),
        );
    }

    /**
     * Filter the customerName based on selection.
     * @param customerName
     */
    private filterCustomers(customerName: string) {
        return this.customers.filter(
            (customer) => customer.customer.customerName.toLowerCase().indexOf(customerName.toLowerCase()) === 0,
        );
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public emitAssistanceCenter() {
        this.dialogRef.close({ success: false });
    }

    public onChange(event: any, input: HTMLInputElement) {
        const files = [].slice.call(event.target.files);
        input.value = files.map((f) => f.name).join(', ');
    }

    public trimCustomerName() {
        this.emailContent.customerName = this.emailContent.customerName.trim();
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * Envoked when a selection event occurs on the customer AutoComplete.
     * @param event An instance of MatAutocompleteSelectedEvent.
     */
    public customerSelectedHandler(event: MatAutocompleteSelectedEvent) {
        this.validCustomerName = true;
        this.invalidCustomerName = false;
    }

    /**
     * Checks the customer name exist in customer list
     * @param selectedCustomerName - selected customer name
     */
    public checkValidCustomer(selectedCustomerName: string) {
        return this.customers.find((x) => x.customer.customerName === selectedCustomerName) ? true : false;
    }

    public sendCallRequest() {
        // checks if  customerName is valid
        if (!this.checkValidCustomer(this.emailContent.customerName)) {
            this.validCustomerName = false;
            this.invalidCustomerName = true;
            return;
        }

        this.isEmpty = false;

        const email = <Email>{
            ToAddress: ['ADSSupportCntr@idexcorp.com'],
            Subject: 'Call request',
            Content: ''.concat(
                'Project name: ',
                this.emailContent.customerName,
                '<br>User name: ',
                this.emailContent.userName,
                '<br>Phone number: ',
                this.emailContent.phoneNumber?.number || '',
                '<br>Primary email: ',
                this.emailContent.emailPrimary,
                '<br>Secondary email: ',
                this.emailContent.emailSecondary || '',
                '<br><br>Reason for call: ',
                this.emailContent.reasonForCall,
            ),
        };

        this.isRequestDone = true;

        this.emailService.sendEmail(email).subscribe((response) => {
            if (response) {
                this.isRequestDone = false;
                const snackbarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(
                    this.callSentSuccessMsg,
                    this.dismissText,
                );

                setTimeout(() => {
                    snackbarRef.dismiss.bind(snackbarRef),
                        setTimeout(() => {
                            this.emitAssistanceCenter();
                        }, 2000);
                }, 10000);
            }
        });
    }

    /**
     * check  customer name Validation
     */
    public checkCustomerNameValidation() {
        // check if customer name is valid

        this.validCustomerName = this.checkValidCustomer(this.emailContent.customerName);
        this.invalidCustomerName = !this.validCustomerName;
    }

    /**
     * check  Phone Number Validation
     */
    public checkPhoneNumberValidation() {
        this.emailContent.phoneNumber = StringUtils.removeExtraSpace(this.emailContent.phoneNumber);
    }

    /**
     * This function capitalized the string value with maximum one space in between word
     */
    public capitalizedStringWithSpace() {
        this.emailContents.fullName = StringUtils.capitalizedStringWithSpace(this.emailContents.fullName);
    }

    /**
     * This function capitalized the string value with maximum one space in between word
     */
    public stepsToReproduceValidation() {
        this.emailContents.stepsToReproduce = StringUtils.capitalizedStringWithSpace(
            this.emailContents.stepsToReproduce,
        );
    }

    /*
     * Below function is used to send email for any defect report.
     */
    public sendDefectReport() {
        // if stepsToReproduce only contained whitespace (ie. spaces, tabs or line breaks)
        this.isEmpty = StringUtils.isStringEmpty(this.emailContents.stepsToReproduce);

        if (this.isEmpty) {
            return;
        }
        this.isEmpty = false;

        // creating attachment contents
        const attachment: Attachment = {
            attachmentName: this.fileName,
            attachment: this.emailContents.reportImage,
        };

        this.emailContents.fullName.trim();

        const email = <Email>{
            FromAddress: 'adsprismhelprequests@idexcorp.com',
            CcAddress: [this.emailContents.emailAddress],
            ToAddress: ['ADSSupportCntr@idexcorp.com'],
            Subject: 'Report Defect',
            Content: ''.concat(
                'Full name: ',
                this.emailContents.fullName,
                '<br>Steps To Reproduce: ',
                this.emailContents.stepsToReproduce,
                '<br>Contact Number: ',
                this.emailContents.contactNumber,
            ),
        };

        this.reportDefectLoadingState = true;

        const subscription = this.emailService.sendEmail(email, attachment).subscribe(
            (response) => {
                if (response) {
                    this.reportDefectLoadingState = false;
                    const snackbarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(
                        this.defectReportSuccess,
                        this.dismissText,
                    );
                    this.emitAssistanceCenter();
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                    setTimeout(snackbarRef.dismiss.bind(snackbarRef), 10000);
                }
            },
            (error) => {
                this.reportDefectLoadingState = false;
            },
        );
        this.subscriptions.push(subscription);
    }

    /*
     * Below function is used read the image content.
     */
    public loadImage(event: any, input: HTMLInputElement) {
        const fileReader = new FileReader();

        const files = [].slice.call(event.target.files);
        this.fileName = input.value = files.map((f) => f.name).join(', ');

        // Get the file extension from uploaded file.
        const uploadedFileExtension = this.fileName.toUpperCase().split('.').pop() || this.fileName;
        // validate if extension exists
        this.validExtensions = this.validFileExtensions.find((x) => x === uploadedFileExtension) ? true : false;

        // validate file file size w.r.t requirement 20 MB.
        if (event.target.files[0].size > this.maxFileSize) {
            this.exceedFileSize = false;
        } else {
            this.exceedFileSize = true;
        }

        // validate file extension as well as size to upload
        if (this.validExtensions && this.exceedFileSize) {
            fileReader.addEventListener(
                'load',
                () => {
                    this.emailContents.reportImage = fileReader.result;
                },
                false,
            );
            // use below readAsDataURL for all file type.
            fileReader.readAsDataURL(event.target.files[0]);
        } else {
            input.value = '';
        }
    } // end of function.

    /**
     * validate the how useful app
     */
    public checkHowUseFulAppRating(event: MatRadioChange) {
        // validate the how useful app survey form rating selection.
        this.howUseFulAppRating = event.value.length > 0 ? false : true;

        // validate the survey form rating selection.
        this.surveyRating =
            this.navigationSatisficationRating || this.featureMakeAppEasyRating || this.navigationSatisficationRating
                ? true
                : false;
    }

    /**
     * validate the satisfication level
     */
    public checkFeatureMakeAppEasyRating(event: MatRadioChange) {
        // validate the satisfication level survey form rating selection.
        this.featureMakeAppEasyRating = event.value.length > 0 ? false : true;

        // validate the survey form rating selection.
        this.surveyRating =
            this.navigationSatisficationRating || this.featureMakeAppEasyRating || this.navigationSatisficationRating
                ? true
                : false;
    }

    /**
     * validate the navigation satisfication
     */
    public checkNavigationSatisfication(event: MatRadioChange) {
        // validate the navigation satisfication survey form rating selection.
        this.navigationSatisficationRating = event.value.length > 0 ? false : true;

        // validate the survey form rating selection.
        this.surveyRating =
            this.navigationSatisficationRating || this.featureMakeAppEasyRating || this.navigationSatisficationRating
                ? true
                : false;
    }

    /**
     * validate the Survey Rating
     */
    private checkSurveyRating() {
        // validate the how useful app survey form rating selection.
        this.howUseFulAppRating = this.applicationSurvey.howUseFulApp === '' ? true : false;

        // validate the satisfication level survey form rating selection.
        this.featureMakeAppEasyRating = this.applicationSurvey.satisficationLevel === '' ? true : false;

        // validate the navigation satisfication survey form rating selection.
        this.navigationSatisficationRating = this.applicationSurvey.navigationSatisfication === '' ? true : false;

        this.surveyRating =
            this.navigationSatisficationRating || this.featureMakeAppEasyRating || this.navigationSatisficationRating
                ? false
                : true;
    }

    /**
     * Function sendAppSurveyReport will use to send application survey email.
     */
    public sendAppSurveyReport() {
        // validate the survey form rating selection.
        this.checkSurveyRating();

        if (!this.surveyRating) {
            this.surveyRating = true;
            return;
        }

        const email = <Email>{
            ToAddress: ['ADSSupportCntr@idexcorp.com'],
            Subject: 'ADSCore Survey',
            Content: ''.concat(
                'What is your departmental role: ',
                this.applicationSurvey.departmentalRole,
                '<br>How useful is this application in your day to day operations: ',
                this.applicationSurvey.howUseFulApp,
                '<br>Is there any feature that should be changed to make the application easier to use: ',
                this.applicationSurvey.featureMakeAppEasy,
                '<br>If so, please elaborate: ',
                this.applicationSurvey.featureDescMakeAppEasy,
                '<br>Overall how satisfied or dissatisfied are you with PRISM: ',
                this.applicationSurvey.satisficationLevel,
                '<br>Please tell us why: ',
                this.applicationSurvey.satisficationLevelWhy,
                '<br>Navigation is consistent and easy to use: ',
                this.applicationSurvey.navigationSatisfication,
                '<br>Please tell us why: ',
                this.applicationSurvey.navigationSatisficationWhy,
                '<br>Any final comments: ',
                this.applicationSurvey.finalComments,
            ),
        };

        this.appSurveyReportState = true;

        const subscription = this.emailService.sendEmail(email).subscribe(
            (response) => {
                if (response) {
                    this.appSurveyReportState = false;
                    const snackbarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(
                        this.surveyReportSuccess,
                        this.dismissText,
                    );
                    this.emitAssistanceCenter();
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                    setTimeout(snackbarRef.dismiss.bind(snackbarRef), 10000);
                }
            },
            (error) => {
                this.appSurveyReportState = false;
            },
        );
        this.subscriptions.push(subscription);
    }
}
