import { SliicerCosmosDoc } from './cosmos-document';

export interface StormDefinition extends SliicerCosmosDoc {
    minRainfallThreshold: string;
    minimumTimeBetweenEventsMinutes: number;
}

export interface DryDayDefinition extends SliicerCosmosDoc {
    dailyMaximum: DailyMaximum[];
    maxFlowPercentage: number;
    minFlowPercentage: number;
}

export interface DepthDurationFrequency extends SliicerCosmosDoc {
    ddfTable: DdfTable[];
}

export enum BaseInfiltrationMethod {
    PercentMinimum = 'PercentMinimum',
    StevensSchutzbach = 'StevensSchutzbach',
    WastewaterProduction = 'WastewaterProduction',
}

export const defaultBaseInfiltrationConstant = {
    [BaseInfiltrationMethod.PercentMinimum]: 80,
    [BaseInfiltrationMethod.StevensSchutzbach]: 0,
    [BaseInfiltrationMethod.WastewaterProduction]: 88,
};

export interface WasteWaterBaseInfiltration extends SliicerCosmosDoc {
    method: BaseInfiltrationMethod;
    constant: number;
}

export interface DailyMaximum {
    accumulation: number;
    dayCount: number;
}

export interface DdfTable {
    month1: number;
    month2: number;
    month3: number;
    month4: number;
    month6: number;
    month9: number;
    year1: number;
    year2: number;
    year5: number;
    year10: number;
    year25: number;
    year50: number;
    year100: number;
    year200: number;
    year500: number;
    year1000: number;
    duration: string;
    durationvalue: number;
    durationMinutes: number;
}

// TODO: WPS - get rid of this. It's bad.
export class DryDefinitionDetails {
    public minRainfallPrevOneDay: number;
    public minRainfallPrevThreeDays: number;
    public minRainfallPrevFiveDays: number;
    public maximumFlowPercentage: number;
    public minimumFlowPercentage: number;

    public minRainPrevOneDay : string;
    public minRainPrevThreeDays : string;
    public minRainPrevFiveDays : string;
    public id?: string;
}
