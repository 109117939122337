import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'underscore';
import { Cacheable, CacheBuster } from 'ts-cacheable';

import {
    StormDefinition,
    DryDayDefinition,
    WasteWaterBaseInfiltration,
    DepthDurationFrequency,
} from 'app/shared/models/sliicer/customer-rainfall-profile';
import {
    LocationGroupMonitorSummary,
    SeriesData,
    MultiSeriesData,
    SNACK_BAR_NOTIFICATION_TIMEOUT,
    MonitorName,
} from 'app/shared/models/sliicer-data';
import {
    SliicerCaseStudy,
    WeekGroup,
    FilterSeasons,
    BasinDefinition,
    Settings,
    Overrides,
    PrecompensationType,
    RainfallMonitorBlockDays,
    BasinStormResult,
    StormEvents,
    DryDayData,
    RainfallEvents,
    SeasonType,
    StormEvent,
} from 'app/shared/models/sliicer';

import { BasinFiltersRequest, RainfallDistributionPreview } from 'app/shared/models/sliicer/basins';
import { Config } from './config';
import { DateutilService, DATE_MDY, DATE_YMD, DATE_DMY } from './dateutil.service';
import { SnackBarNotificationService } from './snack-bar-notification.service';
import { StatusCodeService } from './status-code.service';
import { OverridesPartialResponse, StormEventAdjustment } from '../models/sliicer/overrides';
import { DesignStorm } from '../models/sliicer/design-storm';
import { ExpandedStormEvent, QvsIConfigurations } from '../models/sliicer/results/storm-events';
import { SeasonDefinitionDto } from '../models/sliicer/settings';
import { Regime } from '../models/sliicer/settings';
import { BasinQvi } from 'app/pages/sliicer/sliicer-case-study-details/flow-monitor/basin-qvi-stats/basin-qvi-model';
import {
    getSeasons,
    Season,
} from 'app/pages/sliicer/sliicer-case-study-details/study-settings/seasons-settings/seasons-settings.utils';
import { SelectItem } from '../models/selected-Item';
import { UsersInfo } from '../models/users';
import { tap, filter } from 'rxjs/operators';
import { CustomerUnits } from '../models/location-details';
import { StudyCalcState } from '../models/sliicer/metadata';

export const CUSTOMER_SLIICER_FEATURE_ID = 110;
export const USER_SLIICER_FEATURE_ID = 111;
export const MINUTES_IN_A_DAY = 1440;

export const DEFAULT_PRECOMPENSATION_LENGTH_MINUTES = MINUTES_IN_A_DAY;
export const DEFAULT_STORM_PERIOD_LENGTH_MINUTES = MINUTES_IN_A_DAY;
export const DEFAULT_RECOVERY1_PERIOD_LENGTH_MINUTES = MINUTES_IN_A_DAY;
export const DEFAULT_RECOVERY2_PERIOD_LENGTH_MINUTES = MINUTES_IN_A_DAY;

export const STEP_LENGTH_OPTIONS: Array<SelectItem> = [
    /*
    { text: '1 min', id: 1 },
    { text: '2 mins', id: 2 },
    { text: '5 mins', id: 5 },
    { text: '10 mins', id: 10 },
    { text: '15 mins', id: 15 },
    { text: '20 mins', id: 20 },
    */
    { text: '30 mins', id: 30 },
    { text: '1 Hour', id: 60 },
    // { text: '2 Hours', id: 120 },
    // { text: '1 Day', id: MINUTES_IN_A_DAY },
];
export const DEFAULT_STEP_LENGTH = '1 Hour';

const cacheBuster$ = new Subject<void>();
const clearStormDataCache$ = new Subject<void>();
const clearQviDataCache$ = new Subject<void>();

@Injectable()
export class SliicerService {
    public static serviceUrl = Config.serviceUrl + 'sliicer';
    public static legacyServiceUrl = Config.legacySliicerImportUrl + 'flowload';
    public static powerBiVisualUrl = Config.powerBIServiceUrl + 'visual';

    private dateFormatPatttern = /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;

    public caseStudyDetails = new BehaviorSubject<SliicerCaseStudy>(null);
    // #32963 as a fix we push null value to caseStudyDetails, so we need null check on all of its subscribers
    public studyDetailsData$ = this.caseStudyDetails.pipe(filter(v => !!v));

    public caseStudyEditable = new BehaviorSubject<boolean>(true);

    public qvsiSelectedConfig = new BehaviorSubject<{conf: QvsIConfigurations, afterupdate: boolean}>(null);

    private hasEditPermissions = false;

    private dismiss: string;

    private cachedSeasons: Season[] = null;

    public units = new BehaviorSubject<CustomerUnits>(null);
    public unitsPrecision = new BehaviorSubject<unknown>(null);

    public filterInfo$ = new BehaviorSubject<FilterSeasons>({
        seasons: [],
        years: [],
        regime: [],
    });

    public basinQVIResult$ = new BehaviorSubject<BasinQvi[]>(null);

    /** #34116 Rememebers what is selected and what is not */
    public flowMonitorHydrographNotSelectedItems: {[key: string]: boolean} = {};

    // used to preselect newly created storm
    public newlyCreatedStormStartTime: string;

    public basinHgFlowData: BehaviorSubject<SeriesData> = new BehaviorSubject(null);

    constructor(
        private http: HttpClient,
        private statusCodeService: StatusCodeService,
        private datePipe: DatePipe,
        private dateUtilService: DateutilService,
        private snackBarNotificationService: SnackBarNotificationService,
        public translate: TranslateService,
    ) {
        translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismiss = res;
        });
    }

    public flowMonitorHydrographClear() {
        this.flowMonitorHydrographNotSelectedItems = {};
    }

    public flowMonitorHydrographSetSelected(itemName: string, visible: boolean) {
        this.flowMonitorHydrographNotSelectedItems[itemName] = visible;
    }

    public dateFormatStormDecompositionHydrograph(): string {
        const customerDateFormat = this.dateUtilService.dateFormat.getValue();
        if (customerDateFormat === DATE_DMY) {
            return '%e/%m/%Y';
        }
        if (customerDateFormat === DATE_MDY) {
            return '%m/%e/%Y';
        }
        return '%Y/%m/%e';
    }

    private dateFormatWithoutTime(): string {
        const customerDateFormat = this.dateUtilService.dateFormat.getValue();
        let adjustedDateFormat = '';
        for (let i = 0; i < customerDateFormat.length; i++) {
            if (customerDateFormat.charAt(i) === 'D' || customerDateFormat.charAt(i) === 'Y') {
                adjustedDateFormat += customerDateFormat.charAt(i).toLowerCase();
            } else {
                adjustedDateFormat += customerDateFormat.charAt(i);
            }
        }
        return adjustedDateFormat;
    }

    public dateFormatEditStorms(): string {
        const adjustedDateFormat = this.dateFormatWithoutTime();
        const timeFormat = this.dateUtilService.getTimeFormat();
        return `${adjustedDateFormat} ${timeFormat}`;
    }

    public dateTimeFormatEditStorms(): string {
        const adjustedDateFormat = this.dateFormatWithoutTime();
        const timeFormat = this.dateUtilService.getTimeFormatWithoutSeconds();
        return `${adjustedDateFormat} ${timeFormat}`;
    }

    public getGraphTimeFormat() {
        const is12HourFormat = this.dateUtilService.timeFormat.getValue() !== 'hh:mm:ss';

        if (is12HourFormat) {
            return '%I %M %p';
        }

        return '%H %M';
    }

    public getGraphDateFormat(includeYears: boolean) {
        const customerDateFormat = this.dateUtilService.dateFormat.getValue();

        if (!includeYears) {
            switch (customerDateFormat) {
                case DATE_MDY:
                case DATE_YMD:
                default:
                    return '%b %e';
                case DATE_DMY:
                    return '%e %b';
            }
        }

        switch (customerDateFormat) {
            case DATE_MDY:
                return '%b %e %Y';
            case DATE_YMD:
            default:
                return '%Y %b %e';
            case DATE_DMY:
                return '%e %b %Y';
        }
    }

    public dateFormatFullStudy(): {} {
        const customerDateFormat = this.dateUtilService.dateFormat.getValue();
        const dayDateFormat = SliicerService.GetGraphUserDateFormatDay(customerDateFormat);
        const monthDateFormat = SliicerService.GetGraphUserDateFormatMonth(customerDateFormat);
        return {
            millisecond: '%H:%M:%S.%L',
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%H:%M',
            day: dayDateFormat,
            week: dayDateFormat,
            month: monthDateFormat,
            year: monthDateFormat,
        };
    }

    public hasSeasons(): boolean {
        const details = this.caseStudyDetails.getValue();
        if (!details) {
            return false;
        }
        return details.settings.seasonType && details.settings.seasonType !== SeasonType.None;
    }

    public hasRegimes(): boolean {
        const details = this.caseStudyDetails.getValue();
        if (!details) {
            return false;
        }
        return details.settings.regimes && details.settings.regimes.length > 1;
    }

    public hasYears(): boolean {
        const details = this.caseStudyDetails.getValue();
        if (!details) {
            return false;
        }
        const startDate = new Date(details.config.startDate);
        const endDate = new Date(details.config.endDate);

        return startDate.getFullYear() !== endDate.getFullYear();
    }

    /**
     * This will return an array with all elements in left array that are changed or not found in the right array
     *
     * TODO: WPS - I don't think I'm not a fan of using _
     * @param left
     * @param right
     * @constructor
     */
    public static FindDifferentElements(left: Array<any>, right: Array<any>): Array<any> {
        return left.filter((l) => !right.find((r) => {
            const leftKeys = Object.keys(l).filter(k => l[k] !== undefined);
            const rightKeys = Object.keys(r).filter(k => r[k] !== undefined);

            return leftKeys.length === rightKeys.length && leftKeys.every(k => rightKeys.includes(k) && l[k] === r[k]);
        }));
    }

    public seasonFromDate(date: Date): string {
        const d = date;
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);

        if (this.cachedSeasons === null) {
            const details = this.caseStudyDetails.getValue();
            this.cachedSeasons = getSeasons(details);
        }
        if (this.cachedSeasons.length === 0) {
            return '';
        }
        const seasonIndex = this.cachedSeasons.findIndex((s) => d >= s.periodStart && d <= s.periodEnd);
        if (seasonIndex >= 0) {
            return this.cachedSeasons[seasonIndex].name;
        }
        return '';
    }

    private regimeFromDate(date: Date): string {
        const details = this.caseStudyDetails.getValue();
        const regimes = details.settings.regimes;
        if (regimes) {
            const regimeIndex = regimes.findIndex((r) => {
                const periodStart = new Date(r.periodStart);
                const periodEnd = new Date(r.periodEnd);
                return date >= periodStart && date < periodEnd;
            });
            if (regimeIndex >= 0) {
                return regimes[regimeIndex].name;
            }
        }
        return '';
    }

    public yearFromDate(date: Date): string {
        return date.getFullYear().toString();
    }

    public stormLabelExtras(stormStartTime: string, override?: { season?: string; regime?: string }): string {
        const date = new Date(stormStartTime);
        const extras = [];

        if (override && override.season) {
            extras.push(override.season);
        } else if (this.hasSeasons()) {
            extras.push(this.seasonFromDate(date));
        }

        if (override && override.regime) {
            extras.push(override.regime);
        } else if (this.hasRegimes()) {
            extras.push(this.regimeFromDate(date));
        }

        if (this.hasYears() && extras.length > 0) {
            const year = this.yearFromDate(date);
            extras.push(year);
        }

        return this.formatStormLabel(extras);
    }

    public formatStormLabel(extras?: string[]): string {
        if (extras && extras.length > 0) {
            return '[' + extras.join(',') + ']';
        }
        return '';
    }

    // This will generate adjusted storm events array based on the added and removed storm events arrays
    // that are not saved and will add labels and sort the storm events as well
    public getAdjustedStormEvents(
        stormEvents: Array<StormEvent> = [],
        addedStormEvents: Array<StormEventAdjustment> = [],
        removedStormEvents: Array<StormEventAdjustment> = [],
    ): Array<ExpandedStormEvent> {
        // get originals
        const study = this.caseStudyDetails.getValue();
        const originalAddedStormEvents =
            study.overrides && study.overrides.addedStormEvents ? study.overrides.addedStormEvents : [];
        const originalRemovedStormEvents =
            study.overrides && study.overrides.removedStormEvents ? study.overrides.removedStormEvents : [];

        // get storm events into expanded storm events
        let adjustedStormEvents: Array<ExpandedStormEvent> = stormEvents.map<ExpandedStormEvent>((s) => {
            const stormStartDate = new Date(s.stormStartTime);
            const expanded: ExpandedStormEvent = {
                stormId: s.stormId,
                rainStartTime: s.rainStartTime,
                rainEndTime: s.rainEndTime,
                stormStartTime: s.stormStartTime,
                stormPeriodLength: s.stormPeriodLength,
                recovery1PeriodLength: s.recovery1PeriodLength,
                recovery2PeriodLength: s.recovery2PeriodLength,
                statusTag: '',
                saved: false,
                labelExtra: this.stormLabelExtras(s.stormStartTime),
                totalStormDuration: 0, // Used & initialized elsewhere
                season: this.hasSeasons ? this.seasonFromDate(stormStartDate) : '',
                regime: this.hasRegimes ? this.regimeFromDate(stormStartDate) : '',
                configurationGroups: s.configurationGroups ? s.configurationGroups : [],
                manuallyAdded: s.manuallyAdded
            };
            if (this.stormEventExists(originalAddedStormEvents, s)) {
                expanded.statusTag = 'added';
                expanded.saved = true;
            } else if (this.stormEventExists(originalRemovedStormEvents, s)) {
                expanded.statusTag = 'removed';
                expanded.saved = true;
            } else if (this.stormEventExists(addedStormEvents, s)) {
                expanded.statusTag = 'added';
            } else if (this.stormEventExists(removedStormEvents, s)) {
                expanded.statusTag = 'removed';
            }
            return expanded;
        });

        // Get items that are new and not in yet in the results
        const newNotInResults = addedStormEvents
            .filter((s) => !this.stormEventExists(stormEvents, s))
            .map((n) => {
                const stormStartDate = new Date(n.stormStartTime);
                const expanded: ExpandedStormEvent = {
                    stormId: 0,
                    rainStartTime: n.stormStartTime,
                    // TODO: The below should be fixed. Elsewhere it is set to be 1/2 of the storm period length I think.
                    rainEndTime: n.stormStartTime,
                    stormStartTime: n.stormStartTime,
                    stormPeriodLength: DEFAULT_STORM_PERIOD_LENGTH_MINUTES,
                    recovery1PeriodLength: DEFAULT_RECOVERY1_PERIOD_LENGTH_MINUTES,
                    recovery2PeriodLength: DEFAULT_RECOVERY2_PERIOD_LENGTH_MINUTES,
                    statusTag: '',
                    saved: false,
                    labelExtra: this.stormLabelExtras(n.stormStartTime),
                    totalStormDuration: 0, // Used & initialized elsewhere
                    season: this.hasSeasons ? this.seasonFromDate(stormStartDate) : '',
                    regime: this.hasRegimes ? this.regimeFromDate(stormStartDate) : '',
                };
                return expanded;
            });

        adjustedStormEvents = adjustedStormEvents.concat(newNotInResults);
        return adjustedStormEvents.sort((a, b) => {
            return a.stormStartTime.localeCompare(b.stormStartTime);
        });
    }

    // This will check weather or not a storm exists in an array matched by the stormStartTime
    public stormEventExists(stormEvents: Array<any>, stormData: any): boolean {
        return stormEvents.find((storm) => new Date(storm['stormStartTime']).getTime() === new Date(stormData['stormStartTime']).getTime());
    }

    public bustCache() {
        cacheBuster$.next();
    }

    public bustStormCache() {
        clearStormDataCache$.next();
    }

    public bustQviCache() {
        clearQviDataCache$.next();
    }

    public showToast(message: string, failure = false) {
        const options = {
            duration: SNACK_BAR_NOTIFICATION_TIMEOUT,
        };
        if (failure) {
            options['panelClass'] = 'custom-error-snack-bar';
        }
        this.snackBarNotificationService.raiseNotification(message, this.dismiss, options, failure);
    }

    /*****************************************************************************
     * API Calls to manipulate case studies
     *****************************************************************************/

    /**
     * Get a list of case studies for the specified customerId. The call is
     *    GET api/sliicer/{customerId}/dashboard
     * @param customerId
     * @returns Array of case study summaries for the dashboard
     */
    @CacheBuster({
        cacheBusterNotifier: cacheBuster$,
    })
    public getCaseStudySummary(customerId: number) {
        const path = `${SliicerService.serviceUrl}/${customerId}/dashboard`;
        return this.http.get(path);
    }

    /**
     * Get the specified case study for the specified customerId. The call is
     *    GET api/sliicer/{customerId}/study/{caseStudyId}
     * @param customerId
     * @param caseStudyId
     * @returns Array of case study summaries for the dashboard
     */
    @Cacheable({
        cacheBusterObserver: cacheBuster$,
    })
    public getCaseStudy(customerId: number, caseStudyId: string) {
        return this.http.get<SliicerCaseStudy>(SliicerService.studyUrl(customerId, caseStudyId));
    }

    public saveDepthDurationForStudy(customerId: number, caseStudyId: string, settings: Settings) {
        const path = `${SliicerService.serviceUrl}/${customerId}/study/${caseStudyId}/settings`;
        return this.http.put<Settings>(path, settings);
    }

    public loadCaseStudy(customerId: number, caseStudyId: string): Subject<boolean> {
        if(this.caseStudyDetails?.getValue() && (
            customerId !== this.caseStudyDetails.getValue().customerId
            || caseStudyId !== this.caseStudyDetails.getValue()?.id
        )) {
            this.flowMonitorHydrographClear();
        }

        const subject = new Subject<boolean>();
        this.http
            .get<SliicerCaseStudy>(SliicerService.studyUrl(customerId, caseStudyId))
            .subscribe((res: SliicerCaseStudy) => {
                this.cachedSeasons = null;
                this.hasEditPermissions = res.editable && !res.meta.locked;
                this.caseStudyEditable.next(this.hasEditPermissions);
                this.caseStudyDetails.next(res);
                this.bustCache();
                subject.next(true);
            });
        return subject;
    }

    /** Change editable based on study status, won't change anything if user do not have permissions to study */
    public changeEditableBasedOnStatus(isEditable: boolean) {
        // Do not change editable based on status if user do not have edit permission to case study
        if (this.hasEditPermissions) {
            this.caseStudyEditable.next(isEditable);
        }
    }

    /**
     * Create a new case study with the specified metadata. The call is
     *    POST api/sliicer/{customerId}/study
     *    with meta sent in the body of the message.
     * @param customerId
     * @param meta
     */
    public createCaseStudy(customerId: number, meta: any) {
        const path = `${SliicerService.serviceUrl}/${customerId}/study`;
        return this.http.post<SliicerCaseStudy>(path, meta);
    }

    /**
     * Clone a case study. The call is
     *    POST api/sliicer/{customerId}/study/{caseStudyId}/clone`
     * @param customerId
     * @param caseStudyId
     */
    public cloneCaseStudy(customerId: number, caseStudyId: string) {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + '/clone';
        return this.http.post<SliicerCaseStudy>(path, null);
    }

        /**
     * Claim a case study
     *    POST api/sliicer/{customerId}/study/{caseStudyId}/takeOwnership`
     * @param customerId
     * @param caseStudyId
     */
        public claimCaseStudy(customerId: number, caseStudyId: string) {
            const path = SliicerService.studyUrl(customerId, caseStudyId) + '/takeOwnership';
            return this.http.put<SliicerCaseStudy>(path, null);
        }

    /**
     * Delete a caseStudy
     * @param customerId customer id
     * @param caseStudyId the id of the study to delete
     * @param telemetryDatabaseName the telemetry database to remove
     */
    public deleteCaseStudy(customerId: number, caseStudyId: string, telemetryDatabaseName: string) {
        const query = `telemetryDatabaseName=${telemetryDatabaseName}`;
        const path = SliicerService.studyUrl(customerId, caseStudyId);
        return this.http.delete(`${path}?${query}`);
    }

    /**
     * Update a case study's config
     * @param customerId
     * @param caseStudyId
     * @param caseStudy
     */
    @CacheBuster({
        cacheBusterNotifier: cacheBuster$,
    })
    public putStudyConfig(customerId: number, caseStudyId: string, caseStudy: SliicerCaseStudy) {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + '/config';
        return this.http.put<SliicerCaseStudy>(path, caseStudy).pipe(
            tap(() => {
                this.loadCaseStudy(customerId, caseStudyId);
            }),
        );
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$,
    })
    public putStudy(customerId: number, caseStudyId: string, overrides: Overrides, settings: Settings, basins: BasinDefinition) {
        const path = SliicerService.studyUrl(customerId, caseStudyId);
        return this.http.put<SliicerCaseStudy>(path, {settings: settings, overrides: overrides, basinDefinition: basins});
    }

    public putBasinChanges(customerId: number, caseStudyId: string, overrides: Overrides, basinName: string): Observable<OverridesPartialResponse> {
        const path = SliicerService.basinChangeUrl(customerId, caseStudyId, basinName);

        return this.http.put<OverridesPartialResponse>(path, overrides);
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$,
    })
    public putStudyOverrides(customerId: number, caseStudyId: string, overrides: Overrides, delayCalculation = false) {
        let path = SliicerService.studyUrl(customerId, caseStudyId) + '/overrides';
        if(delayCalculation) path += '?delayCalculation=true';
        return this.http.put<SliicerCaseStudy>(path, overrides);
    }

    /**
     * Update a case study's settings
     * This is actually a generic funciton for `updateCaseStudyWasteWater` and the like that
     * update a _part_ of a study's settings
     * @param customerId
     * @param caseStudyId
     * @param settings
     */
    @CacheBuster({
        cacheBusterNotifier: cacheBuster$,
    })
    public putStudySettings(customerId: number, caseStudyId: string, settings: Settings, delayCalculation = false) {
        let path = SliicerService.studyUrl(customerId, caseStudyId) + '/settings';
        if(delayCalculation) path += '?delayCalculation=true';
        if (settings.seasons) {
            this.cachedSeasons = null;
        }
        return this.http.put<SliicerCaseStudy>(path, settings);
    }

    public putBasinSettings(customerId: number, caseStudyId: string, settings: Settings) {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + '/basin/settings';

        if (settings.seasons) {
            this.cachedSeasons = null;
        }

        return this.http.put<SliicerCaseStudy>(path, settings);
    }

    public updateCaseStudyDesignStorms(customerId: number, caseStudyId: string, storms: DesignStorm[]) {
        const settings: Settings = {
            designStorms: storms,
        };
        return this.putStudySettings(customerId, caseStudyId, settings);
    }

    public updateCaseStudyDayGroups(customerId: number, caseStudyId: string, weekGroup: Array<WeekGroup>) {
        const settings: Settings = {
            dayGroups: weekGroup,
        };
        return this.putStudySettings(customerId, caseStudyId, settings);
    }

    public updateCaseStudyWasteWater(
        customerId: number,
        caseStudyId: string,
        wasteWaterBaseInfiltration: WasteWaterBaseInfiltration,
    ) {
        const settings: Settings = {
            wastewaterBaseInfiltration: wasteWaterBaseInfiltration,
        };
        return this.putStudySettings(customerId, caseStudyId, settings);
    }

    public updateCaseStudyStormDefinition(customerId: number, caseStudyId: string, stormDefinition: StormDefinition) {
        const settings: Settings = {
            stormEventDefinition: stormDefinition,
        };
        return this.putStudySettings(customerId, caseStudyId, settings);
    }

    public updateCaseStudyDryDayDefinition(customerId: number, caseStudyId: string, dryDefinition: DryDayDefinition) {
        const settings: Settings = {
            dryDayDefinition: dryDefinition,
        };
        return this.putStudySettings(customerId, caseStudyId, settings);
    }

    public rainfallDistributionMethodPreview(customerId: number, caseStudyId: string, method: string): Observable<RainfallDistributionPreview> {
        const url = SliicerService.rainfallDistributionsUrl(customerId, caseStudyId, method);

        return this.http.get<RainfallDistributionPreview>(url);
    }

    /*
    public updateCaseStudyDepthDurationFrequency(customerId: number, caseStudyId: string, depthDurationFrequency: DepthDurationFrequency) {
        let settings: Settings = {
            ddfData: depthDurationFrequency
        };
        return this.putStudySettings(customerId, caseStudyId, settings);
    }
    */

    public updatePrecompositionMethod(customerId: number, caseStudyId: string, compositionMethod: PrecompensationType) {
        const settings: Settings = {
            defaultPrecompType: compositionMethod,
        };
        return this.putStudySettings(customerId, caseStudyId, settings);
    }

    public updateRegimes(customerId: number, caseStudyId: string, regimes: Regime[]) {
        const settings: Settings = {
            regimes: regimes,
        };
        return this.putStudySettings(customerId, caseStudyId, settings);
    }

    public updateSeasons(
        customerId: number,
        caseStudyId: string,
        seasonType: SeasonType,
        seasons: SeasonDefinitionDto[],
    ) {
        const settings: Settings = {
            seasonType: seasonType,
        };
        // we only need to pass in seasons if the seasonType is custom
        if (settings.seasonType === SeasonType.Custom) {
            settings.seasonDefinitions = seasons;
        } else if (settings.seasonType === SeasonType.None) {
            settings.seasonType = null;
            settings.seasonDefinitions = null;
        }
        return this.putStudySettings(customerId, caseStudyId, settings);
    }

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$,
    })
    public putBasinDefinition(customerId: number, caseStudyId: string, basinDefinition: BasinDefinition, delayCalculation = false) {
        let path = SliicerService.studyUrl(customerId, caseStudyId) + '/basins';
        if(delayCalculation) path += '?delayCalculation=true';
        return this.http.put<Array<string>>(path, basinDefinition);
    }

    public putBasinFilterDefinition(customerId: number, caseStudyId: string, request: BasinFiltersRequest, delayCalculation = false) {
        let path = SliicerService.studyUrl(customerId, caseStudyId) + '/basin/filters';
        if(delayCalculation) path += '?delayCalculation=true';
        return this.http.put<Array<string>>(path, request);
    }

    public lockUnlockStudy(customerId: number, caseStudyId: string, locked: boolean) {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + '/lock/' + locked;
        return this.http.put(path, null);
    }

    public markStudyReadonly(customerId: number, caseStudyId: string, allowRead: boolean) {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + '/allowRead/' + allowRead;
        return this.http.put(path, null);
    }

    public markStudyPreliminary(customerId: number, caseStudyId: string, preliminary: boolean) {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + '/preliminary/' + preliminary;
        return this.http.put(path, null);
    }

    /**
     * Refresh the study's telemetry
     * @param customerId
     * @param caseStudyId
     */
    public refreshStudyTelemetryData(customerId: number, caseStudyId: string) {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + '/refresh';
        return this.http.post(path, null);
    }

    public vaultExportDryDayStatistics(customerId: number, caseStudyId: string) {
        const baseURI = SliicerService.exportUrl(customerId, caseStudyId);
        return this.http.post<any>(baseURI, null);
    }

    public vaultExportBasicQvsiStatistics(customerId: number, caseStudyId: string) {
        const baseURI = SliicerService.exportbasinUrl(customerId, caseStudyId);
        return this.http.post<any>(baseURI, null);
    }

    public vaultExportBasinProperties(customerId: number, caseStudyId: string) {
        const baseURI = SliicerService.exportBasinPropertiesUrl(customerId, caseStudyId);
        return this.http.post(baseURI, null);
    }

    public vaultExportRainfallDistribution(customerId: number, caseStudyId: string) {
        const baseURI = SliicerService.exportRainfallDistributionUrl(customerId, caseStudyId);
        return this.http.post(baseURI, null);
    }

    public vaultDryDayHgData(customerId: number, caseStudyId: string) {
        const baseURI = SliicerService.exportDryDayHgData(customerId, caseStudyId);
        return this.http.post<any>(baseURI, null);
    }

    /**
     * Update block days for Rainfall Monitors
     * @param customerId
     * @param caseStudyId
     * @param blockDays array of RainfallMonitorBlockDays
     */
    public putBlockRainDays(customerId: number, caseStudyId: string, blockDays: RainfallMonitorBlockDays[]) {
        const overrides: Overrides = {
            rainfallMonitorBlockDays: blockDays,
        };
        return this.putStudyOverrides(customerId, caseStudyId, overrides);
    }

    /**
     * Fetch basin storm results
     * @param customerId The customer id
     * @param caseStudyId The case study id
     * @param stormId The storm id
     * @param basinName The name of the basin for which to fetch data
     *
     * [Route("{customerId}/study/{caseStudyId}/results/storm/{stormId}/basinStats/{basinName}")]
     */
    @Cacheable({
        cacheBusterObserver: combineLatest([cacheBuster$, clearStormDataCache$]),
        maxCacheCount: 100,
    })
    public getStormBasinResults(
        customerId: number,
        caseStudyId: string,
        stormId: number,
        basinName: string,
    ): Observable<BasinStormResult> {
        const path =
            SliicerService.studyUrl(customerId, caseStudyId) + `/results/storm/${stormId}/basinStats/${basinName}`;
        return this.http.get<BasinStormResult>(path);
    }

    public getBasinCalcTime(customerId: number, caseStudyId: string, basinName: string, calcRunId: string) {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + `/results/calculationRunTime/${basinName}/?calculationRunId=${calcRunId}`;

        return this.http.get<string>(path);
    }

    public getStudyCalcState(customerId: number, caseStudyId: string, ) {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + `/calcstate`;

        return this.http.get<StudyCalcState>(path);
    }

    /**
     * Fetch all storm results for a basin
     * @param customerId The customer id
     * @param caseStudyId The case study id
     * @param basinName The name of the basin for which to fetch data
     *
     * [Route("{customerId}/study/{caseStudyId}/results/allStorms/basinStats/{basinName}")]
     */
    @Cacheable({
        cacheBusterObserver: combineLatest([cacheBuster$, clearQviDataCache$]),
        maxCacheCount: 100,
    })
    public getAllStormBasinResults(
        customerId: number,
        caseStudyId: string,
        basinName: string,
    ): Observable<Array<BasinStormResult>> {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + `/results/allStorms/basinStats/${basinName}`;
        return this.http.get<Array<BasinStormResult>>(path);
    }


    public getAllStormResults(
        customerId: number,
        caseStudyId: string,
    ): Observable<string> {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + `/results/allStorms/basinStats`;
        return this.http.get<string>(path);
    }

    public getStudyDryDayData(customerId: number, caseStudyId: string) {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + `/results/allBasins/dryDayStats`;

        return this.http.post<string>(path, {});
    }

    /**
     * Fetch all Qvi results for a basin
     * @param customerId The customer id
     * @param caseStudyId The case study id
     * @param basinName The name of the basin for which to fetch data
     *
     * [Route("{customerId}/study/{caseStudyId}/results/basinQvi/{basinName}
     */
    @Cacheable({
        cacheBusterObserver: combineLatest([cacheBuster$, clearQviDataCache$]),
        maxCacheCount: 100,
    })
    private requestQviBasinResults(customerId: number, caseStudyId: string, basinName: string): Observable<Array<BasinQvi>> {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + `/results/basinQvi/${basinName}`;
        return this.http.get<Array<BasinQvi>>(path);
    }
    
    public getQviBasinResults(customerId: number, caseStudyId: string, basinName: string): Observable<Array<BasinQvi>> {
        return this.requestQviBasinResults(customerId, caseStudyId, basinName)
            .pipe(tap((res) => { this.basinQVIResult$.next(res) }));
    }

    public getAllQviBasinResults(customerId: number, caseStudyId: string): Observable<string> {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + `/results/allBasins/qvsiStats`;
        return this.http.post<string>(path, {});
    }

    public getDryDayHGpivotResults(customerId: number, caseStudyId: string): Observable<string> {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + `/results/allBasins/dryDayHydrographs`;
        return this.http.post<string>(path, {});
    }

    /*****************************************************************************
     * Direct calls to orchestration layer
     *****************************************************************************/

    @CacheBuster({
        cacheBusterNotifier: cacheBuster$,
    })
    public performAllCalculations(customerId: number, caseStudyId: string) {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + '/calc/auto';
        return this.http.put(path, '');
    }
    public exportCaseStudy(customerId: number, caseStudyId: string) {
        const path = SliicerService.exportStudyUrl(customerId, caseStudyId);
        return this.http.get(path);
    }

    public importCaseStudy(customerId: number, caseStudyId: string, result: any) {
        const path = SliicerService.importStudyUrl(customerId, caseStudyId);
        return this.http.post(path, result);
    }

    /**
     * Update the dry day plot config for specified basin.
     * @param customerId The customer id
     * @param dryDaysData The dry days data
     *
     * [Route("sliicer/{customerId}/study/update/dryDays")]
     */
    public updateDryDayBasin(customerId: number, basinDryDays: DryDayData, delayCalculation = false) {
        const path = SliicerService.updateDryDayUrl(customerId, delayCalculation);
        return this.http.post(path, basinDryDays);
    }

    /*****************************************************************************
     * Power BI
     *****************************************************************************/

    public getPBIStudyConfig(caseStudyId: string) {
        const path = `${SliicerService.powerBiVisualUrl}/${caseStudyId}`;
        return this.http.get(path);
    }

    /*****************************************************************************
     * SLiiCER calls into regular apis
     *****************************************************************************/

    public getVaultFiles(customerId: number, path: string) {
        const query = `cid=${customerId}&path=${path}`;
        return this.http.get(`${Config.urls.vaultStructure}?${query}`);
    }

    public getMonitorNames(customerId: number, locationGroups: Array<number>, shouldIncludeInactiveLocations = false) {
        const query = `cid=${customerId}&includeInactive=${shouldIncludeInactiveLocations}`;
        return this.http.post(`${Config.urls.monitorNames}?${query}`, locationGroups);
    }

    public getMonitorCounts(customerId: number, locationGroups: Array<number>) {
        const query = `cid=${customerId}`;
        return this.http.post(`${Config.urls.monitorCounts}?${query}`, locationGroups);
    }

    /*****************************************************************************
     * Legacy APIs
     *****************************************************************************/

    public getFlowLoadConfig(customerVault: string, filename: string) {
        const query = `customerVault=${customerVault}&fileUri=${filename}`;
        const path = SliicerService.legacyServiceUrl + '/config';
        return this.http.get(`${path}?${query}`);
    }

    public getFlowLoadImport(customerVault: string, filename: string, customerId: number, documentId: string) {
        const query = `customerVault=${customerVault}&fileUri=${filename}&customerId=${customerId}&docId=${documentId}`;
        const path = SliicerService.legacyServiceUrl + '/copy';
        return this.http.get(`${path}?${query}`);
    }

    public legacyCopyTelemetry(customerVault: string, filename: string, customerId: number, documentId: string) {
        const query = `customerVault=${customerVault}&customerId=${customerId}&fileUri=${filename}&docId=${documentId}`;
        const path = SliicerService.legacyServiceUrl + '/copytel';
        return this.http.get(`${path}?${query}`);
    }

    // Export Graphs Data
    public exportGraphsData(customerId: number, caseStudyId: string) {
        const baseURI = SliicerService.exportGraphDataUrl(customerId, caseStudyId);
        return this.http.post<any>(baseURI, null);
    }

    // Export Storm event
    public exportStorms(customerId: number, caseStudyId: string) {
        const baseURI = SliicerService.exportStormUrl(customerId, caseStudyId);
        return this.http.post<any>(baseURI, null);
    }

    public getDirectUploadUrl(customerId: number, filename: string) {
        const url = `${Config.serviceUrl}api/vault/uploadSliicer?cid=${customerId}&fileName=${filename}`;
        return this.http.post(url, null);
    }

    /*****************************************************************************
     * Charts
     *****************************************************************************/

    /**
     * Get Rainfall data for provided rainfall monitors
     * @param customerId The customer id
     * @param caseStudyId The case study id
     * @param telemetryKey The database where the data is stored
     * @param rainfallMonitorName The name of the rainfall monitor for which to fetch data
     * @param startDate The start date for the data
     * @param endDate The end date for the data
     *
     * [Route("{customerId}/study/{caseStudyId}/chart/hyetograph/{rainfallMonitorName}")]
     */
    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxCacheCount: 100,
    })
    public getRainfall(
        customerId: number,
        caseStudyId: string,
        telemetryKey: string,
        rainfallMonitorName: string,
        startDate: string,
        endDate: string,
    ): Observable<SeriesData> {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + `/chart/hyetograph/${rainfallMonitorName}`;
        const query = `telemetryDatabaseName=${telemetryKey}&startDate=${startDate}&endDate=${endDate}`;
        return this.http.get<SeriesData>(`${path}?${query}`);
    }

    /**
     * Fetch the dry day data for the specified basin.
     * @param customerId The customer id
     * @param caseStudyId The case study id
     * @param basinName The name of the basin
     *
     * [Route("{customerId}/study/{caseStudyId}/results/dryDays/{basinName}")]
     */

    public getBasinDryDays(customerId: number, caseStudyId: string, basinName: string): Observable<DryDayData> {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + `/results/dryDays/${basinName}`;
        return this.http.get<DryDayData>(path);
    }

    /**
     * Fetch all the storms.
     * @param customerId The customer id
     * @param caseStudyId The case study id
     *
     * [Route("{customerId}/study/{caseStudyId}/results/storms")]
     */
    @Cacheable({
        cacheBusterObserver: combineLatest([cacheBuster$, clearStormDataCache$]),
        maxCacheCount: 100,
    })
    public getStorms(customerId: number, caseStudyId: string): Observable<StormEvents> {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + `/results/storms`;
        return this.http.get<StormEvents>(path);
    }

    /**
     * Fetch all the rainfall events.
     * @param customerId The customer id
     * @param caseStudyId The case study id
     *
     * [Route("{customerId}/study/{caseStudyId}/results/rainfalls")]
     */
    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxCacheCount: 100,
    })
    public getRainfallEvents(customerId: number, caseStudyId: string): Observable<RainfallEvents> {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + `/results/rainfalls`;
        return this.http.get<RainfallEvents>(path);
    }

    /**
     * Fetch all the series that we use in the basin hydrograph with rainfall.
     * @param customerId The customer id
     * @param caseStudyId The case study id
     * @param basinName The name of the basin for which to fetch data
     * @param fetchUpstreams Should we fetch upstream basin flow?
     * @param calculateNet Should we calculate net flow?
     *
     * [Route("{customerId}/study/{caseStudyId}/chart/basinHydrograph/{basinName}")]
     */
    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxCacheCount: 100,
    })
    public getBasinHydrographWithRainfall(
        customerId: number,
        caseStudyId: string,
        basinName: string,
        fetchUpstreams: boolean,
        calculateNet: boolean,
    ): Observable<MultiSeriesData> {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + `/chart/basinHydrograph/${basinName}`;
        const query = `fetchUpstreamBasins=${fetchUpstreams}&calculateNet=${calculateNet}`;
        return this.http.get<MultiSeriesData>(`${path}?${query}`);
    }

    /**
     * Get day traces for a flow monitor on provided dates
     * @param customerId The customer id
     * @param caseStudyId The case study id
     * @param telemetryKey The database where the data is stored
     * @param flowMonitorName The name of the flow monitor for which to fetch data
     * @param daysToFetch An array of "yyyy-MM-dd" date strings
     *
     * [Route("{customerId}/study/{caseStudyId}/chart/dirunal/{flowMonitorName}")]
     */
    @Cacheable({
        cacheBusterObserver: cacheBuster$,
        maxCacheCount: 100,
    })
    public getWeekDayTracesData(
        customerId: number,
        caseStudyId: string,
        telemetryKey: string,
        flowMonitorName: string,
        daysToFetch: Array<string>,
    ): Observable<MultiSeriesData> {
        const path = SliicerService.studyUrl(customerId, caseStudyId) + `/chart/dirunal/${flowMonitorName}`;
        const query = `telemetryDatabaseName=${telemetryKey}`;

        return this.http.post<MultiSeriesData>(`${path}?${query}`, daysToFetch);
    }

    /*****************************************************************************
     * Convenience functions
     *****************************************************************************/

    /**
     * Method to get days in study from start date and date to be displayed on multiple sliicer dashboards
     * @param caseStudyStartDate
     * @param caseStudyEndDate
     */
    public static GetDaysInStudy(caseStudyStartDate: string, caseStudyEndDate: string): number {
        if (caseStudyStartDate && caseStudyEndDate) {
            const a = moment(caseStudyStartDate).startOf('day');
            const b = moment(caseStudyEndDate).endOf('day');
            return b.isSameOrAfter(a) ? b.diff(a, 'days') + 1 : 0;
        } else {
            return 0;
        }
    }

    /**
     * Method to return formatted study date with provided start date and end date
     * @param caseStudystartDate
     * @param caseStudyendDate
     * @param customeDateFormat format to customer preferrred date format
     */
    public getStudyDate(caseStudystartDate, caseStudyendDate, customeDateFormat) {
        if (caseStudystartDate && caseStudyendDate) {
            return `${this.datePipe.transform(caseStudystartDate, customeDateFormat)} to ${this.datePipe.transform(
                caseStudyendDate,
                customeDateFormat,
            )}`;
        } else {
            return '-';
        }
    }

    public getImportedStatus() {
        const study = this.caseStudyDetails.getValue();
        if (study && study.meta) {
            return !!study.meta.flowLoadImportFile;
        }
        return false;
    }

    /**
     * Method to ensure that we the monitors in a location group are valid for a SLiiCER Study
     * @param locationGroupSummary
     */
    public static CheckMonitorsValid(locationGroupSummary: LocationGroupMonitorSummary): boolean {
        const validFlow =
            locationGroupSummary.flowMonitorNames !== null && locationGroupSummary.flowMonitorNames.length > 0;
        const validRain =
            locationGroupSummary.rainfallMonitorNames !== null && locationGroupSummary.rainfallMonitorNames.length > 0;
        return validFlow && validRain;
    }

    /**
     * Ensure that a date attempting to be entered in a text field is valid
     * @param date
     * @param dateString
     */
    public validateDate(date: Date, dateString: string): Date {
        if (!date) {
            return null;
        }

        const newDate = new Date(dateString);
        if (!newDate || isNaN(newDate.getTime())) {
            return null;
        }
        if (newDate > this.dateUtilService.maxDate || date < this.dateUtilService.minDate) {
            return null;
        }
        return newDate;
    }

    // to get earliest dates for location group monitors
    public static getEarliestDate(monitors: MonitorName[] = []) {
        return monitors.reduce((acc: number, curr) => {
            if (acc === null) {
                return new Date(curr.earliestReading).getTime();
            }

            const current = new Date(curr.earliestReading).getTime();

            return Math.min(acc, current);
        }, null);
    }

    // to get latest dates for location group monitors
    public static getLatestDate(monitors: MonitorName[] = []) {
        return monitors.reduce((acc: number, curr) => {
            if (acc === null) {
                return new Date(curr.latestReading).getTime();
            }

            const current = new Date(curr.latestReading).getTime();

            return Math.max(acc, current);
        }, null);
    }


    private static studyUrl(customerId: number, caseStudyId: string): string {
        return `${SliicerService.serviceUrl}/${customerId}/study/${caseStudyId}`;
    }

    private static basinChangeUrl(customerId: number, caseStudyId: string, basinName: string): string {
        return `${SliicerService.serviceUrl}/${customerId}/study/${caseStudyId}/basin/overrides?basinName=${basinName}`;
    }

    private static exportStudyUrl(customerId: number, caseStudyId: string): string {
        return `${SliicerService.serviceUrl}/${customerId}/exportStudy/${caseStudyId}`;
    }

    private static importStudyUrl(customerId: number, caseStudyId: string) {
        return `${SliicerService.serviceUrl}/${customerId}/importStudy/${caseStudyId}`;
    }

    private static updateDryDayUrl(customerId: number, delayCalculation: boolean) {
        if (!delayCalculation) {
            return `${SliicerService.serviceUrl}/${customerId}/study/update/dryDays`;
        }

        return `${SliicerService.serviceUrl}/${customerId}/study/update/dryDays?delayCalculation=${delayCalculation}`;
    }

    private static exportUrl(customerId: number, caseStudyId: string): string {
        return `${SliicerService.serviceUrl}/${customerId}/study/${caseStudyId}/export/basinDryDayStats`;
    }

    private static exportbasinUrl(customerId: number, caseStudyId: string): string {
        return `${SliicerService.serviceUrl}/${customerId}/study/${caseStudyId}/export/basinQVsIStats`;
    }

    private static exportBasinPropertiesUrl(customerId: number, caseStudyId: string): string {
        return `${SliicerService.serviceUrl}/${customerId}/study/${caseStudyId}/export/basinProperties`;
    }

    private static exportRainfallDistributionUrl(customerId: number, caseStudyId: string): string {
        return `${SliicerService.serviceUrl}/${customerId}/study/${caseStudyId}/export/rainfallDistribution`;
    }

    private static exportDryDayHgData(customerId: number, caseStudyId: string): string {
        return `${SliicerService.serviceUrl}/${customerId}/study/${caseStudyId}/export/basinDryDayHydrograph`;
    }

    private static exportStormUrl(customerId: number, caseStudyId: string): string {
        return `${SliicerService.serviceUrl}/${customerId}/study/${caseStudyId}/export/storms`;
    }

    private static exportGraphDataUrl(customerId: number, caseStudyId: string): string {
        return `${SliicerService.serviceUrl}/${customerId}/study/${caseStudyId}/export/sbrReport`;
    }

    private static rainfallDistributionsUrl(customerId: number, caseStudyId: string, method: string) {
        return `${SliicerService.serviceUrl}/${customerId}/study/${caseStudyId}/distributions?method=${method}`;
    }

    private static GetGraphUserDateFormatDay(dateFormat: string) {
        switch (dateFormat) {
            case DATE_MDY:
            case DATE_YMD:
            default:
                return '%m/%d';
            case DATE_DMY:
                return '%d/%m';
        }
    }

    private static GetGraphUserDateFormatMonth(dateFormat: string) {
        switch (dateFormat) {
            case DATE_MDY:
            default:
                return '%m/%Y';
            case DATE_YMD:
                return '%Y/%m/';
            case DATE_DMY:
                return '%d/%Y';
        }
    }
}
