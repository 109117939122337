import { OnInit, Component, Input, SimpleChange } from '@angular/core';
import { REGEX_CONFIG } from '../../../../utils/regex-utils';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef, LegacySimpleSnackBar as SimpleSnackBar } from '@angular/material/legacy-snack-bar';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LocationCardService } from 'app/shared/components/location-card/services/location-card.service';
import { TritonConfiguration } from 'app/shared/models/monitorsetting';
import { UsersService } from 'app/pages/admin/users.service';
import { MaxRangeElement } from 'app/shared/components/location-card/models/location-card.model';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';
import { FormControl } from '@angular/forms';
@Component({
    selector: 'add-add-foreSITEFlex-radar-sensor',
    templateUrl: './add-foreSITEFlex-radar-sensor.component.html',
    styleUrls: ['./add-foreSITEFlex-radar-sensor.component.scss'],
})
export class AddForeSITEFlexRadarSensorComponent implements OnInit {
    @Input() public index: 0 | 1;
    @Input() form: FormGroup;
    @Input() series: number;
    @Input() monitorConfigs: TritonConfiguration;

    public isMonitorEditor = false;
    public ranges: Array<MaxRangeElement> = [];
    public geometryPrecision: number;
    public dismissText: string;
    public maxRange: string;
    public maxMeasurementRange: number;
    public physicalOffsetLabel : string;
    public rangeText : string;
    public stepRange: number;
    public minValue: number;
    public maxValue: number;
    public disabled: boolean = false;
    public maxRangeError: boolean = false;
    public normalRangeText : string = 'NORMAL (0 - 19.5 ft)';
    public highRangeText : string = 'HIGH (19.6 - 39.3 ft)';
    // Reg Ex. Pattern for numeric -+ Decimal value 2Place
    public negativeWithTwoDecimalPattern = REGEX_CONFIG.negativeWithTwoDecimalPattern;
    public isMetric: boolean;

    constructor(
        public translateService: TranslateService,
        private snackBar: MatSnackBar,
        public locationCardService: LocationCardService,
        private usersService: UsersService,
        private translate: TranslateService,
    ) {}

    public ngOnInit() {
        this.isMetric = this.locationCardService.isMetric;
        this.maxMeasurementRange = 6000;
        this.stepRange = 6000;
        this.minValue = 6000;
        this.maxValue = 12000;
        this.isMonitorEditor = this.usersService.isMonitorEditor.getValue();

        this.translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismissText = res;
        });

        this.physicalOffsetLabel = this.locationCardService.addDepthUnitToPlaceholder(
            this.translate.instant('ADD_EDIT.INPUT_PLACEHOLDER_PHYSICAL')
        )


        const maxRange = this.form.get('maxMeasurementRange').value;
        if(maxRange){
            this.maxMeasurementRange = maxRange;
        }
        this.maxRangeText(this.maxMeasurementRange);
    }

    public onPhysicalOffsetChanged(event: SimpleChange) {
        this.form.markAsDirty();
    }

    public onRangeChanged(event : MatSliderChange) {
        if (event) {
            this.maxMeasurementRange = event.value;
            this.form.get('maxMeasurementRange').patchValue(this.maxMeasurementRange);
            this.maxRangeError = event.value === 6000 ? false : true;
            this.maxRangeText(this.maxMeasurementRange);
        }
        this.form.markAsDirty();
    }

    public maxRangeText(rangeVal : number)
    {
        this.rangeText = rangeVal === 6000 ? this.normalRangeText : this.highRangeText ;
    }
}
