
import * as moment from 'moment';

export interface ConfirmationReadingEntry {
    ts: Date;
    dr: number;
    dd: number;
    vr: number;
    vd: number;
    cg: number;
    qr: number;
    qd: number;
}


export enum ConfirmationReportTypes {
    Default = 0,
    DepthVel,
    Final
}

export interface ConfirmationElement {
    id: number;
    value: number;
    text: string;
}



export interface ConfirmationReadingResponse {
    before: ConfirmationReadingEntry;
    closest: ConfirmationReadingEntry;
    after: ConfirmationReadingEntry;
}

export interface ConfirmationReadingEntryUI extends ConfirmationReadingEntry {
    name: string;
}

export interface ConfirmationPoint {
    id?: string;
    depth: number;
    velocity: number;
    flow: number;
    comment: string;
    confirmationDate: Date | string;
    calculatedFlow: number;
    depthAccuracy: number;
    velocityAccuracy: number;
    silt: number;
    averageToPeak: number;
    ignore: boolean;
    monitors?: ConfirmationReadingResponse;
}

export interface ConfirmationPointCreateResponse {
    isError: boolean;
    message: string;
    fieldErrors: string[];
    payload: string;
    count: number;
}
