import {
    Component,
    OnInit,
    ViewEncapsulation,
    Input,
    ViewChild,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { EntitySelectorObject } from 'app/shared/models/entities';
import { UptimeReportItem } from 'app/shared/models/uptime';
import { LocationData } from 'app/shared/models/locations-entities-data';
import { HydrographTickInterval } from 'app/shared/models/view-data';
import { MultiSelectAutoCompleteComponent } from 'app/shared/components/multi-select/multi-select-auto-complete/multi-select-auto-complete.component';

@Component({
    selector: 'app-uptime-filters',
    templateUrl: './uptime-filters.component.html',
    styleUrls: ['./uptime-filters.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UptimeFiltersComponent implements OnInit, OnChanges {
    @ViewChild(MultiSelectAutoCompleteComponent) locationsSelect: MultiSelectAutoCompleteComponent;
    @Output() dateRangeChange = new EventEmitter();
    @Output() locationSelected = new EventEmitter();
    @Output() removeLocation = new EventEmitter();
    @Output() entitiesChange = new EventEmitter();
    @Output() sampleRateChange = new EventEmitter();
    @Output() applyFilters = new EventEmitter();
    @Input() availableEntities: EntitySelectorObject[];
    @Input() defaultEntities: EntitySelectorObject[];
    @Input() uptimeData: UptimeReportItem[];
    @Input() startDate: Date;
    @Input() endDate: Date;
    @Input() selectedEntityIds: number[] = [];
    @Input() selectedLocations: any[] = [];
    @Input() selectedSampleRate: any;
    @Input() locationsList: LocationData[];
    @Input() sampleRateList: any;
    public locationsAutoComplete: string;
    public filteredLocations: any[] = [];
    public sampleRList: any;
    public isSearching = false;
    public tickInterval: HydrographTickInterval;
    public separatorKeysCodes: number[] = [ENTER, COMMA];

    public selected: number = 2;

    public maxDate = new Date();
    constructor() {}

    ngOnInit() {
        this.filteredLocations = [...this.locationsList];
        this.sampleRList = [...this.sampleRateList];
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.locationsList) {
            this.filteredLocations = [...changes.locationsList.currentValue];
            this.selectedLocations = this.selectedLocations.filter((v) =>
                this.filteredLocations.find((i) => v.lid === i.lid),
            );

            if (this.locationsSelect) {
                this.locationsSelect.items = this.filteredLocations;
                this.locationsSelect.preselectedItems = this.selectedLocations;
                this.locationsSelect.updateOnSelectedChange();
            }
        }
    }

    public selectSearchBox() {
        this.locationsAutoComplete = '';
        this.filterEntities();
        this.isSearching = true;
    }

    public searchBoxStringEntered() {
        this.filterEntities(this.locationsAutoComplete.toLowerCase());
    }

    public onDatePickerClose() {
        this.dateRangeChange.emit({ startDate: this.startDate, endDate: this.endDate });
    }

    public selectedEntities(event: any[]) {
        this.entitiesChange.emit(event.map((v) => v.id));
    }

    public selectedSampleR(sampleRate: any){
        this.sampleRateChange.emit(sampleRate);
    }


    public handleSelectedLocations(event) {
        const selected = event.filter((v) => v.isChecked);
        this.locationSelected.emit(selected);
    }

    private filterEntities(searchString?: string) {
        if (!searchString) {
            this.filteredLocations = [...this.locationsList];
            return;
        }

        this.filteredLocations = this.locationsList.filter((v) =>
            v.n.toLowerCase().includes(searchString.toLowerCase()),
        );
        this.filteredLocations = this.filteredLocations.filter(
            (v) => !this.selectedLocations.find((i) => i.lid === v.lid),
        );
    }

    public btnApplyFilters() {
        this.applyFilters.emit(true);
    }
}
