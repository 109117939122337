import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, ViewChild, OnDestroy } from '@angular/core';
import { FlexmonsterPivot } from 'ngx-flexmonster';
import Flexmonster, { Toolbar } from 'flexmonster';
import { STORM_CHART_MEASURES, STORM_END_COLUMNS, STORM_SLICE_MEASURES, StormProperties, createPivotMapping, csvToJSON, getKeyByProp, getStormPivotOptions, getStormPivotSlice, removeUnits } from './storms-pivot-table.constants'
import { environment } from 'app/environments/environment';
import { Subscription } from 'rxjs';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { BasinStormResult, SliicerCaseStudy } from 'app/shared/models/sliicer';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { DatePipe } from '@angular/common';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { EXCLUDED_CHART_ITEMS, EXPORT_CSV_ID, EXPORT_EXCEL_ID, EXPORT_HTML_ID, EXPORT_IMG_ID, EXPORT_PDF_ID, EXPORT_VAULT_ID, STORM_EVENTS_EXPORT_NAME, TOOLBAR_CHARTS_TAB_ID, TOOLBAR_EXPORT_TAB_ID, TOOLBAR_FIELDS_TAB_ID, TOOLBAR_FORMAT_TAB_ID, TOOLBAR_FULLSCREEN_TAB_ID, TOOLBAR_GRID_TAB_ID, TOOLBAR_OPTIONS_TAB_ID, VAULT_ICON } from '../flexmonster.constant';
import { SNACK_BAR_NOTIFICATION_TIMEOUT } from 'app/shared/models/sliicer-data';
const blankText = '(blank)';

@Component({
	selector: 'app-storms-pivot-table',
	templateUrl: './storms-pivot-table.component.html',
	styleUrls: ['./storms-pivot-table.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class StormsPivotTableComponent implements OnInit, OnDestroy {
	public license = environment.flexmonsterLicense;
	public report: Object;
	@ViewChild('pivot') private pivotTable: FlexmonsterPivot;

	public isLoading = true;

	private customDateFormat: string;
	private caseStudyId: string;
	private customerId: number;

	private data: BasinStormResult[];
    public noData = false;

	private studyDateForExportNaming: string;

	private initialExportTabs: Flexmonster.ToolbarTab[];
	private subscriptions: Subscription[] = [];
	constructor(
		private sliicerService: SliicerService,
		private dateutilService: DateutilService,
		private datePipe: DatePipe,
		private snackBarNotificationService: SnackBarNotificationService,
		private translate: TranslateService
	) { }

	ngOnInit(): void {
		const dateFormat = this.dateutilService.getFormat();
		const is12HourFormat = this.dateutilService.timeFormat.getValue() !== 'hh:mm:ss';
		this.customDateFormat = is12HourFormat ? `${dateFormat}, ${'hh:mm tt'}` : `${dateFormat}, ${'HH:mm'}`;

		this.subscriptions.push(this.sliicerService.studyDetailsData$.subscribe((study: SliicerCaseStudy) => {
			this.caseStudyId = study.id;
			this.customerId = study.customerId;

			const format = this.dateutilService.getFormat().replace(/\//g, '');
			const start = new Date(study.config.startDate);
			const end = new Date(study.config.endDate);

			this.studyDateForExportNaming = `${this.datePipe.transform(start, format)}-${this.datePipe.transform(end, format)}`;

			this.getStormsData();
		}));
	}

	public customizeToolbar(toolbar: Toolbar) {
		toolbar.showShareReportTab = true;

		this.pivotTable.flexmonster.on('reportchange', () => {
			const report = this.pivotTable.flexmonster.getReport() as Flexmonster.Report;
			const slices = (report.slice as Flexmonster.Slice);

			if (!slices || !slices.measures) {
				return;
			}

			const startColumns: Flexmonster.MeasureObject[] = [];
			const endColumns: Flexmonster.MeasureObject[] = [];

			slices.measures.forEach((measure) => {
				if (STORM_END_COLUMNS.includes(removeUnits(measure.uniqueName))) {
					endColumns.push(measure);
				} else {
					startColumns.push(measure);
				}
			});

			report.slice.measures = startColumns.concat(endColumns);
			this.pivotTable.flexmonster.setReport(report);
		});

		const tabs = this.getTabs(toolbar);

		toolbar.getTabs = function () {
			return tabs;
		}
	}

	private getTabs(toolbar: Toolbar, isGridView = false) {
		const tabs = toolbar.getTabs();

		const exportTab = tabs.find(v => v.id === TOOLBAR_EXPORT_TAB_ID);

		if (!this.initialExportTabs) {
			this.initialExportTabs = exportTab.menu;
		}

		const chartsTab = tabs.find(v => v.id === TOOLBAR_CHARTS_TAB_ID);
		const gridTab = tabs.find(v => v.id === TOOLBAR_GRID_TAB_ID);

		const formatTab = tabs.find(v => v.id === TOOLBAR_FORMAT_TAB_ID);
		const optionsTab = tabs.find(v => v.id === TOOLBAR_OPTIONS_TAB_ID);
		const fieldsTab = tabs.find(v => v.id === TOOLBAR_FIELDS_TAB_ID);
		const fullScreenTab = tabs.find(v => v.id === TOOLBAR_FULLSCREEN_TAB_ID);

		this.formatExportsTab(exportTab, isGridView);

		gridTab.handler = this.onGridView.bind(this);

		chartsTab.menu = chartsTab.menu.filter(v => !EXCLUDED_CHART_ITEMS.includes(v.id));
		chartsTab.menu.forEach((item) => {
			item.handler = this.onChartView.bind(this);
		});


		return [
			exportTab,
			{ divider: true } as any,
			chartsTab,
			gridTab,
			formatTab,
			optionsTab,
			fieldsTab,
			{ divider: true } as any,
			fullScreenTab
		];
	}

	private getStormsData() {
		this.isLoading = true;

		this.sliicerService.getAllStormResults(this.customerId, this.caseStudyId).subscribe(csv => {
			if (!csv) {
				this.isLoading = false;
				return;
			}

			const json: BasinStormResult[] = csvToJSON(csv);
			if (!json || !json.length) {
				this.isLoading = false;
				return;
			}

			// need to remove extra ""
			const data = json.map(item => {
				return Object.keys(item).reduce((acc, k) => {
					const key = k.replace(/"/g, '');

					acc[key] = item[k];

					return acc;
				}, {});
			});

			this.data = data.slice(0, data.length - 1) as BasinStormResult[];

            if(!this.data || !this.data.length) {
                this.isLoading = false;
                this.noData = true;
            } else {
                this.noData = false;
                this.generateTable(this.data);
            }

		},
			() => { this.isLoading = false; }
		)
	}

	private onChartView(type: string) {
		this.pivotTable.flexmonster.showCharts(type, true);

		const report = this.pivotTable.flexmonster.getReport() as Flexmonster.Report;
		(report.slice as Flexmonster.Slice).measures = STORM_CHART_MEASURES.map(prop => ({ uniqueName: getKeyByProp(this.data[0], prop) }));
		this.pivotTable.flexmonster.setReport(report);

		const tabs = this.getTabs(this.pivotTable.flexmonster.toolbar, false);
		this.pivotTable.flexmonster.toolbar.getTabs = function () { return tabs; }
		this.pivotTable.flexmonster.toolbar['create']();
	}

	private onGridView() {
		this.pivotTable.flexmonster.showGrid();

		const report = this.pivotTable.flexmonster.getReport() as Flexmonster.Report;
		(report.slice as Flexmonster.Slice).measures = STORM_SLICE_MEASURES.map(prop => ({ uniqueName: getKeyByProp(this.data[0], prop) }));
		this.pivotTable.flexmonster.setReport(report);

		const tabs = this.getTabs(this.pivotTable.flexmonster.toolbar, true);
		this.pivotTable.flexmonster.toolbar.getTabs = function () { return tabs; }
		this.pivotTable.flexmonster.toolbar['create']();
	}

	private formatExportsTab(tab: Flexmonster.ToolbarTab, isGridView: boolean) {
		let exportTypes: string[] = [];

		if (isGridView) {
			exportTypes = [EXPORT_HTML_ID, EXPORT_CSV_ID, EXPORT_EXCEL_ID];
		} else {
			exportTypes = [EXPORT_HTML_ID, EXPORT_CSV_ID, EXPORT_EXCEL_ID, EXPORT_IMG_ID, EXPORT_PDF_ID];
		}

		tab.menu = this.initialExportTabs.filter(menuItem => {
			if (!exportTypes.includes(menuItem.id)) {
				return false;
			}

			this.setExportHandler(menuItem);

			return true;
		});

		if (isGridView) {
			tab.menu.unshift({
				title: 'To Vault(Raw)',
				id: EXPORT_VAULT_ID,
				icon: VAULT_ICON,
				handler: () => this.exportVaultHandler()
			});
		}
	}

	private setExportHandler(menuItem: Flexmonster.ToolbarTab) {
		switch (menuItem.id) {
			case EXPORT_HTML_ID: {
				menuItem.handler = this.exportToHTMLhandler();
				break;
			}
			case EXPORT_EXCEL_ID: {
				menuItem.handler = this.exportToExcelHandler()
				break;
			}
			case EXPORT_CSV_ID: {
				menuItem.handler = this.exportToCsvHandler();
				break;
			}
			case EXPORT_IMG_ID: {
				menuItem.handler = this.exportToImageHandler();
				break;
			}
			case EXPORT_PDF_ID: {
				menuItem.handler = this.exportToPdfHandler();
				break;
			}
			default: break;
		}
	}

	private removeBlanks() {
		this.pivotTable.flexmonster.customizeCell((cell, data) => {
		  if (data.label === blankText) {
			cell.text = '';
		  }
		});
	}

	private exportToExcelHandler() {
		this.removeBlanks();
		return () => this.pivotTable.flexmonster.exportTo('excel', {
			filename: `${STORM_EVENTS_EXPORT_NAME}-${this.studyDateForExportNaming}`,
			excelSheetName: `${STORM_EVENTS_EXPORT_NAME}-${this.studyDateForExportNaming}`,
		});

	}

	private exportToCsvHandler() {
		this.removeBlanks();
		return () => this.pivotTable.flexmonster.exportTo('csv', {
			filename: `${STORM_EVENTS_EXPORT_NAME}-${this.studyDateForExportNaming}`,
		});
	}

	private exportToHTMLhandler() {
		return () => this.pivotTable.flexmonster.exportTo('html', {
			filename: `${STORM_EVENTS_EXPORT_NAME}-${this.studyDateForExportNaming}`,
		});
	}

	private exportToImageHandler() {
		return () => this.pivotTable.flexmonster.exportTo('image', {
			filename: `${STORM_EVENTS_EXPORT_NAME}-${this.studyDateForExportNaming}`,
		});
	}

	private exportToPdfHandler() {
		return () => this.pivotTable.flexmonster.exportTo('pdf', {
			filename: `${STORM_EVENTS_EXPORT_NAME}-${this.studyDateForExportNaming}`,
		});
	}

	private exportVaultHandler() {
		const successMessage = this.translate.instant('SLIICER_TABLE.SLICER_SUMMARY.RESULTS.EXPORT_STORMS_SAVE_MESSAGE');
		const dismissText = this.translate.instant('COMMON.DISMISS_TEXT');
		const errorText = this.translate.instant('SLIICER_TABLE.SLICER_SUMMARY.RESULTS.EXPORT_STORMS_ERROR_MESSAGE');

		this.isLoading = true;
		this.sliicerService.exportStorms(this.customerId, this.caseStudyId).subscribe(
			(results) => {
				this.isLoading = false;
				this.snackBarNotificationService.raiseNotification(
					successMessage,
					dismissText,
					{
						duration: SNACK_BAR_NOTIFICATION_TIMEOUT,
					},
					true,
				);
			},
			(err) => {
				this.isLoading = false;
				this.snackBarNotificationService.raiseNotification(
					errorText,
					dismissText,
					{
						panelClass: 'custom-error-snack-bar',
					},
					false,
				);
			},
		);

	}

	private generateTable(data) {
		const mapping = createPivotMapping(data[0], this.customDateFormat);

		this.report = {
			options: getStormPivotOptions(this.customDateFormat, getKeyByProp(data[0], StormProperties.eventNet2Vol)),
			dataSource: { data, mapping },
			slice: getStormPivotSlice(data[0])
		};

		const format = {
			name: "",
			thousandsSeparator: "",
		}

		this.isLoading = false;
		this.pivotTable.flexmonster.setReport(this.report);
		this.pivotTable.flexmonster.setFormat(format);
		this.pivotTable.flexmonster.refresh();
	}

	ngOnDestroy() {
		this.subscriptions.forEach(v => v.unsubscribe());
	}
}
