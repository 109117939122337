import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from 'app/shared/services/config';

@Injectable({
    providedIn: 'root',
})
export class FTPService {
    constructor(private http: HttpClient) {}

    public getFtpServers(cid) {
        return this.http.get(`${Config.serviceUrl}${Config.urls.ftpsweep.allServers}/?cid=${cid}`);
    }

    public getFtpServer(cid, guid) {
        return this.http.get(`${Config.serviceUrl}${Config.urls.ftpsweep.server}/?cid=${cid}&guid=${guid}`);
    }
    public deleteFtpServer(cid, guid) {
        return this.http.delete(`${Config.serviceUrl}${Config.urls.ftpsweep.server}/?cid=${cid}&guid=${guid}`);
    }
    public updateFtpServer(cid, serverOpts) {
        return this.http.post(`${Config.serviceUrl}${Config.urls.ftpsweep.server}/?cid=${cid}`, serverOpts);
    }
}
