import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[anchor]',
    host: {
        '(window:scroll)': 'checkAnchor()',
    },
})
export class AnchorDirective implements AfterViewInit {
    @Input() anchor: number;
    private offsetTop: number;
    private element;
    constructor(private elRef: ElementRef) {
        this.element = this.elRef.nativeElement;
    }

    ngAfterViewInit() {
        this.offsetTop = this.getOffsetTop(this.element);
        this.checkAnchor();
    }

    checkAnchor(): void {
        const doc = document.documentElement;
        const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (top + this.anchor >= this.offsetTop) {
            this.element.classList.add('anchored');
        } else if (this.element.classList.contains('anchored')) this.element.classList.remove('anchored');
    }

    public getOffsetTop(element): number {
        let offsetTop = 0;
        do {
            if (!isNaN(element.offsetTop)) {
                offsetTop += element.offsetTop;
            }
        } while ((element = element.offsetParent));
        return offsetTop;
    }
}
