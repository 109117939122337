<div id="vaultShareDialog" class="modal-dialog vault-share-dialog">
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'VAULT.SHARE_TEXT' | translate }} '{{ file.name }}'</span>
        <span class="app-flex-filler"></span>
    </div>
    <mat-dialog-content>
        <p class="sub-title">{{ 'VAULT.VAULT_ITEM_SHARE.SUBTITLE' | translate }}</p>

        <app-loader [isLoading]="isLoading"></app-loader>
        <mat-form-field class="full-width">
            <mat-label>{{ 'VAULT.VAULT_ITEM_SHARE.TIME_PERIOD_PLACEHOLDER' | translate }}</mat-label>
            <mat-select
                (selectionChange)="shareTimePeriodChange($event)"
                name="timePeriodsSelect"
                class="full-width"
                [(ngModel)]="selectedPeriod"
            >
                <mat-option *ngFor="let timePeriod of timePeriods" [value]="timePeriod.value">
                    {{ timePeriod.text }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="pad-b-20">
            <div class="shareLink">
                <div class="shareLinkContent">
                    <mat-form-field>
                        <input class="share-link" matInput value="{{ uri }}" readonly />
                        <mat-hint align="end" *ngIf="!(uri == undefined)">
                            {{ isValueCopied ? 'Copied' : 'Copy' }}</mat-hint
                        >
                        <button
                            mat-button
                            class="icon-raised-button shareLinkButton"
                            (click)="copyShareLink($event)"
                            [disabled]="uri == undefined"
                            [matTooltip]="!(uri == undefined) ? 'Copy' : ''"
                        >
                            <mat-icon>content_copy</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button mat-dialog-close>{{ 'COMMON.CLOSE' | translate }}</button>
        </div>
    </mat-dialog-actions>
</div>
