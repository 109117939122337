import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Inject,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Observable, Subscription } from 'rxjs';
import { UiUtilsService } from '../../../shared/utils/ui-utils.service';
import { VaultService } from '../vault.service';
import { SnackBarNotificationService } from '../../../shared/services/snack-bar-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { FileNode, FlatNode, VaultDirectory } from 'app/shared/models/vault';

@Component({
    selector: 'app-vault-files-move-action',
    templateUrl: './vault-files-move-action.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VaultFilesMoveActionComponent implements OnInit {
    public vault: Observable<VaultDirectory>;
    // Repreents a value indicating whether the root directory is selected.
    public isActive: boolean;

    // Represents the subscriptions to be disposed.
    public nodeId: string;
    private subscriptions = new Array<Subscription>();

    public vaultTreeData = [];

    public selectedFiles = [];

    public nestedNodeMap = new Map<FileNode, FlatNode>();
    public treeControl: FlatTreeControl<FlatNode>;
    public treeFlattener: MatTreeFlattener<FileNode, FlatNode>;
    public dataSource: MatTreeFlatDataSource<FileNode, FlatNode>;
    public isLoading = true;
    public dismissText: string;
    public successMessage: string;
    public failedMessage: string;
    public translateKeys: Array<string> = ['COMMON.DISMISS_TEXT', 'COMMON.MOVE_SUCCESS', 'COMMON.MOVE_ERROR'];
    constructor(
        private dialogRef: MatDialogRef<VaultFilesMoveActionComponent>,
        @Inject(MAT_DIALOG_DATA) private data,
        private vaultService: VaultService,
        private chagneDetector: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private snackBarNotificationService: SnackBarNotificationService,
        private translate: TranslateService,
    ) {
        translate.get(this.translateKeys).subscribe((translateValues) => {
            this.dismissText = translateValues['COMMON.DISMISS_TEXT'];
            this.successMessage = translateValues['COMMON.MOVE_SUCCESS'];
            this.failedMessage = translateValues['COMMON.MOVE_ERROR'];
        });
    }

    private getLevel = (node: FlatNode) => node.level;

    private isExpandable = (node: FlatNode) => node.expandable;

    private getChildren = (node: FileNode): FileNode[] => node.sub_directories;

    public hasChild = (_: number, nodeData: FlatNode) => nodeData.expandable;

    public hasNoContent = (_: number, nodeData: FlatNode) => nodeData.name === '';

    // Transformer to convert nested node to flat node. Record the nodes in maps for later use.
    private transformer = (node: FileNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.name === node.name ? existingNode : <FlatNode>{};
        flatNode.name = node.name;
        flatNode.id = node.id;
        flatNode.level = level;
        flatNode.expandable = !!node.sub_directories && node.sub_directories.length > 0;
        return flatNode;
    };

    public ngOnInit() {
        this.vault = this.data.vaultTreeData;
        this.selectedFiles = this.data.selectedFiles;
        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl<FlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

        this.nodeId = '/';
        this.vault.subscribe((res) => {
            if (res) {
                res['id'] = '/';
                res['name'] = 'Root';
                this.vaultTreeData.length = 0;
                this.vaultTreeData.push(res);
                this.dataSource.data = this.vaultTreeData;
                this.expandDefultNode();
            }
        });

        this.vaultService.selectedDirectory.subscribe(
            (selectedNodeId: string) => {
                this.nodeId = selectedNodeId;
                this.uiUtilsService.safeChangeDetection(this.chagneDetector);
            },
            (error) => {
                // Error Block to handle errors
            },
        );
        this.vaultService.BreadcrumbDirectories.subscribe(
            (directories) => {
                this.expandDefultNode();
                if (directories) {
                    directories.forEach((eachItem, index) => {
                        this.expandSelectedNodes(eachItem.id);
                    });
                }
            },
            (error) => {},
        );
        this.isLoading = false;
        this.uiUtilsService.safeChangeDetection(this.chagneDetector);
    }
    public expandDefultNode() {
        if (this.treeControl && this.treeControl.dataNodes) {
            this.treeControl.collapseAll();
            this.treeControl.expand(this.treeControl.dataNodes.find((d) => d.id === '/'));
            this.nodeId = '/';
        }
        this.uiUtilsService.safeChangeDetection(this.chagneDetector);
    }
    public expandSelectedNodes(id: string) {
        if (this.treeControl && this.treeControl.dataNodes) {
            this.treeControl.expand(this.treeControl.dataNodes.find((d) => d.id === id));
            this.nodeId = id;
        }
        this.uiUtilsService.safeChangeDetection(this.chagneDetector);
    }
    /**
     * Handles the response for the click event of the selected directory.
     * @param id Represents the surrogate id of the selected diretory.
     */
    public selectDirectory(id: string): void {
        this.nodeId = id;
        this.uiUtilsService.safeChangeDetection(this.chagneDetector);
    }

    public move() {
        // to do
        this.isLoading = true;
        this.uiUtilsService.safeChangeDetection(this.chagneDetector);
        let currentlySelectedDirectory = '';
        this.vaultService.CurrentDirectory.subscribe(
            (directory: string) => (currentlySelectedDirectory = directory),
            (error) => {
                // Error Block to handle errors
            },
        ).unsubscribe();

        if (this.selectedFiles.length > 0) {
            const movingFile = [];
            this.selectedFiles.forEach((file, index) => {
                const data = {
                    sourceUri: currentlySelectedDirectory + file,
                    destinationUri: this.nodeId + file,
                };
                movingFile.push(data);
            });
            const filesMove = { files: movingFile };
            this.vaultService.moveFile(filesMove).subscribe(
                (res) => {
                    this.snackBarNotificationService.raiseNotification(this.successMessage, this.dismissText, {
                        duration: 10000,
                        panelClass: '',
                    });
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.chagneDetector);
                    this.dialogRef.close({ success: true, response: res });
                },
                (error) => {
                    // Error Block to handle errors
                    this.snackBarNotificationService.raiseNotification(this.failedMessage, this.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    }, false);
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.chagneDetector);
                    this.dialogRef.close({ success: false });
                },
            );
        }
    }
    public close() {
        this.dialogRef.close({ success: false });
    }
}
