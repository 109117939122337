import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';
import { Config } from 'app/shared/services/config';
import { CompositeLocationItem } from 'app/shared/models/CompositeLocationItem';
import { LocationStatus } from '../models/locations';
import { map } from 'rxjs/operators';

@Injectable()
export class CompositeLocationService {
    constructor(public http: HttpClient, public activatedRoute: ActivatedRoute) {}

    public createCompositeLocation(
        customerId: number,
        locationName: string,
        locationAddress: string,
        lat: number,
        long: number,
        status: LocationStatus,
        compLocations: Array<CompositeLocationItem>,
    ) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                charset: 'utf-8',
            }),
        };

        const content = {
            locationName: locationName,
            description: locationAddress,
            status: status,
            latitude: lat,
            longitude: long,
            components: compLocations,
        };

        return this.http
            .post<any>(Config.serviceUrl + Config.urls.createCompositeLocation + `${customerId}`, content, options)
            .pipe(
                map((res) => {
                    if (res) {
                        return res;
                    }
                }),
            );
    }

    public updateCompositeLocation(
        customerId: number,
        locationName: string,
        locationAddress: string,
        lat: number,
        long: number,
        status: LocationStatus,
        locationId: number,
        compLocations: Array<CompositeLocationItem>,
    ) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                charset: 'utf-8',
            }),
        };

        const content = {
            locationName: locationName,
            locationID: locationId,
            description: locationAddress,
            status: status,
            latitude: lat,
            longitude: long,
            components: compLocations,
        };

        return this.http
            .put<any>(Config.serviceUrl + Config.urls.createCompositeLocation + `${customerId}`, content, options)
            .pipe(
                map((res) => {
                    if (res) {
                        return res;
                    }
                }),
            );
    }
}
