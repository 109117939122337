import { MapRain } from './map-view';
import { Selectable } from './selectable';

export interface AddEventDialogData {
    customerId: number;
    includeInactiveLocations: boolean;
    locationGroupId: number;
    events: EventModel[];
    event?: EventModel;
    hasPermission?: boolean;
}

export enum EventTypes {
    General = 0,
    Rain = 1,
    Hydraulic = 2,
    SiteCondition = 3,
    WorkOrder = 4,
    CSO = 5
}

export const RainEventSymbolColors = {
    0: '#ffffff',
    1: '#DAEED8',
    2: '#45A065',
    3: '#09662B',
    4: '#FFF987',
    5: '#F9CB52',
    6: '#FD8C34',
    7: '#FB4F2E',
    8: '#A40D0D',
    9: '#4D0072',
    10: '#FDDDF9'
}

export const EVENT_TYPES: Selectable[] = [
    { id: EventTypes.General, name: "General" },
    { id: EventTypes.Rain, name: "Rain Event" },
    { id: EventTypes.Hydraulic, name: "Hydraulic Event" },
    { id: EventTypes.SiteCondition, name: "Site Conditions" },
    { id: EventTypes.WorkOrder, name: "Work Order" },
    { id: EventTypes.CSO, name: "CSO Event" },
];

export interface EventModel {
    guid?: string;
    etype?: number;
    desc?: string;
    start?: string | Date;
    end?: string | Date;
    lids?: number[];
    parent?: string;
    children?: string[] | EventModel[];
    duration?: string;
    res?: {
        locs: EventRain[];
    };
}

export interface EventRain extends MapRain {
    lid: number;
    res: number;
    rdesc?: string;
    rtype: number;
    runit?: string;
}

export interface EventTree extends EventModel {
    level?: number;
    expandable?: boolean;
}

export interface EventFilters {
    selectedLocations?: Selectable[];
    startDate?: Date;
    endDate?: Date;
    selectedTypes?: Selectable[];
    types?: Selectable[];
    durationStartHours?: number | string;
    durationStartMinutes?: number | string;
    durationEndHours?: number | string;
    durationEndMinutes?: number | string;
    /** #41031 Whenever date range was changed. Used to make API call ONLY when dates was changed */
    datesChange?: boolean;
}
