import { Injectable } from '@angular/core';

@Injectable()
export class UiService {
    constructor() {}

    private _isDisabled = false;
    private _isMapDisabled = false;

    public isMapDisabled() {
        return this._isMapDisabled;
    }

    public isDisabled() {
        return this._isDisabled;
    }

    public disable() {
        this._isDisabled = true;
        this._isMapDisabled = true;
    }

    public enable() {
        this._isDisabled = false;
        this._isMapDisabled = false;
    }

    public disableMap() {
        this._isMapDisabled = true;
    }

    public enableMap() {
        this._isMapDisabled = false;
    }
}
