
export enum DryDayProperties {
    basin = "Basin",
    regime = "Regime",
    season = "Season",
    dowGroup = "Day of Week Group",
    numOfDays = "Number of Days",
    alt = "Alternate",
    altRegime = "Alternate Regime",
    altSeason = "Alternate Season",
    altDowGroup = "Alternate Day Group",
    grossMinFlow = "Gross Minimum Flow",
    grossAvgFlow = "Gross Average Flow",
    grossMaxFlow = "Gross Maximum Flow",
    grossPeakFac = "Gross Peaking Factor",
    grossWasteProd = "Gross Wastewater Production",
    grossBaseInfl = "Gross Base Infiltration",
    netMinFlow = "Net Minimum Flow",
    netAvgFlow = "Net Average Flow",
    netMaxFlow = "Net Maximum Flow",
    netWasteProd = "Net Wastewater Production",
    netBaseInfl = "Net Base Infiltration",
    netWasteProdLength = "Net Wastewater Production/Length",
    traceMaxDate = "Trace Maximum Date",
    traceMax = "Trace Maximum",
    year = 'Year',
    altYear = 'Alternate Year'
}

const DRY_DAY_DATE_KEYS = [DryDayProperties.traceMaxDate];
const STRING_KEYS = [DryDayProperties.basin, DryDayProperties.year, DryDayProperties.altYear];

const DRY_DAY_SLICE_ROWS = [DryDayProperties.basin];
const DRY_DAY_SLICE_COLUMNS = [DryDayProperties.dowGroup, DryDayProperties.regime, DryDayProperties.season];

export const DRY_DAY_GRID_MEASURES = [
    DryDayProperties.basin, DryDayProperties.regime, DryDayProperties.season, DryDayProperties.dowGroup, DryDayProperties.numOfDays,
    DryDayProperties.alt, DryDayProperties.altRegime, DryDayProperties.altSeason, DryDayProperties.altDowGroup, DryDayProperties.grossMinFlow,
    DryDayProperties.grossAvgFlow, DryDayProperties.grossMaxFlow, DryDayProperties.grossPeakFac, DryDayProperties.grossWasteProd,
    DryDayProperties.grossBaseInfl, DryDayProperties.netMinFlow, DryDayProperties.netAvgFlow, DryDayProperties.netMaxFlow,
    DryDayProperties.netWasteProd, DryDayProperties.netBaseInfl, DryDayProperties.netWasteProdLength, DryDayProperties.traceMaxDate, DryDayProperties.traceMax,
];

export const DRY_DAY_CHART_MEASURES = [
    DryDayProperties.grossMinFlow, DryDayProperties.grossAvgFlow, DryDayProperties.grossMaxFlow, DryDayProperties.grossPeakFac,
    DryDayProperties.grossWasteProd, DryDayProperties.grossBaseInfl, DryDayProperties.netMinFlow, DryDayProperties.netAvgFlow,
    DryDayProperties.netMaxFlow, DryDayProperties.netWasteProd, DryDayProperties.netBaseInfl, DryDayProperties.netWasteProdLength
];


export const getDryDayPivotSlice = (item) => {
    return {
        rows: getExistingProps(DRY_DAY_SLICE_ROWS, item).map(prop => ({ uniqueName: getKeyByProp(item, prop) })),
        columns: getExistingProps(DRY_DAY_SLICE_COLUMNS, item).map(prop => ({ uniqueName: getKeyByProp(item, prop) })),
        measures: getExistingProps(DRY_DAY_CHART_MEASURES, item).map(prop => ({ uniqueName: getKeyByProp(item, prop) }))
    };
}

export const getDryDayPivotOptions = (dateFormat: string, chartMeasure: string) => {
    return {
        showAggregationLabels: false,
        showAggregations: false,
        datePattern: dateFormat,
        viewType: "charts",
        grid: {
            type: 'flat',
            showEmptyValues: true,
            showGrandTotals: "off",
            showTotals: "off",
        },
        chart: {
            multipleMeasures: true,
            activeMeasure: { uniqueName: chartMeasure },
        },
    }
}

export const createPivotMapping = (data: Object, dateFormat: string): { [key: string]: { type: string; format?: string; interval?: string } } => {
    return Object.keys(data).reduce((result, key) => {
        const value = data[key];

        let type = typeof value === 'number' ? 'number' : 'string';
        const isString = STRING_KEYS.includes(key as DryDayProperties);

        if (isString) {
            type = 'string';
        }

        const isDate = DRY_DAY_DATE_KEYS.includes(key as DryDayProperties);


        if (isDate) {
            result[key] = {
                type: "date string",
                format: dateFormat,
                interval: '30s'
            };
        } else {
            result[key] = { type };
        }

        return result;
    }, {});
}


export const csvToJSON = (text: string, quoteChar = '"', delimiter = ',') => {
    const rows = text.split("\n");
    const headers = rows[0].split(",");

    const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, 'gs');

    const match = line => [...line.matchAll(regex)]
        .map(m => m[2])
        .slice(0, -1);

    let lines = text.split('\n');
    const heads = headers ?? match(lines.shift());
    lines = lines.slice(1);

    return lines.map(line => {
        return match(line).reduce((acc, cur, i) => {
            // replace blank matches with `null`
            const val = cur.length <= 0 ? null : Number(cur) || cur;
            const key = heads[i] ?? `{i}`;
            return { ...acc, [key]: val };
        }, {});
    });
}

export const getExistingProps = (props: DryDayProperties[], item) => {
    return props.filter(v => getKeyByProp(item, v));
}

// accepts the property name and returns entry key that came from API (entry key includes units)
export const getKeyByProp = (item, prop: DryDayProperties) => {
    // #38610 Cannot use pure include because strings can start with same prefix
    const textWithMetrics = prop + ' (';
    return Object.keys(item).find(v => v === prop || v.includes(textWithMetrics));
}
