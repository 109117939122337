import { formatDate } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BillingReportsService } from 'app/shared/services/billing-reports.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import saveAs from 'file-saver';
import { Subscription } from 'rxjs';

 // 2 years of data allowed at a time. 
const MAX_DAYS: number = 730;
const today = new Date();

@Component({
  selector: 'app-admin-billing-metrics',
  templateUrl: './admin-billing-metrics.component.html',
  styleUrls: ['./admin-billing-metrics.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminBillingMetricsComponent implements OnInit, OnDestroy {
    // Do not allow billng reports before this date.
    public minDate = new Date('March 1, 2024');
    public startDate = new Date(
        today.getFullYear() - (today.getMonth() > 0 ? 0 : 1),
        (today.getMonth() - 1 + 12) % 12,
        1,
    );
    public endDate = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, -1);

    public maxDaysAllowed = MAX_DAYS;

    public dateRangePlaceholder: string;
    public dismissText: string;
    public generatingReportText: string;
    public noUsageText: string;
    public startDateErrorMsg: string;

    subscriptions: Subscription[] = [];
    constructor(
        @Inject(LOCALE_ID) private locale: string,
        private snackBar: MatSnackBar,
        private snackBarService: SnackBarNotificationService,
        private billingReportService: BillingReportsService,
        public dialogRef: MatDialogRef<AdminBillingMetricsComponent>,
        private translate: TranslateService,
    ) { 

    }

    ngOnInit(): void {
        this.subscriptions.push(this.translate.get('ADMIN.ADMIN_USAGE.GENERATING_REPORT').subscribe((res: string) => {
            this.generatingReportText = res;
        }));
        
        this.subscriptions.push(this.translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismissText = res;
        }));

        this.subscriptions.push(this.translate.get('ADMIN.ADMIN_USAGE.NO_USAGE').subscribe((res: string) => {
            this.noUsageText = res;
        }));

        this.subscriptions.push(this.translate.get(['LOCATION_DASHBOARD.DATE_RANGE_SPAN', 'ADMIN.ADMIN_USAGE.USAGE_START']).subscribe((res) => {
            this.dateRangePlaceholder = `${res['LOCATION_DASHBOARD.DATE_RANGE_SPAN']} ${res['ADMIN.ADMIN_USAGE.USAGE_START']}`;
        }));

        this.subscriptions.push(this.translate.get('ADMIN.ADMIN_USAGE.START_DATE_ERROR').subscribe((res: string) => {
            this.startDateErrorMsg = res;
        }));
    }

    public onStartDateChange(event: Date) {
        this.startDate = event;
    }

    public onEndDateChange(event: Date) {
        this.endDate = event;
    }

    public generateReport() {
        this.snackBar.open(this.generatingReportText, this.dismissText, {
            duration: 5000,
        });

        this.billingReportService.getBillingReportForTimeSpan(this.startDate, this.endDate).subscribe((file) => {
            if (file) {
                var blob = new Blob([file], {type: 'text/csv' })
                saveAs(blob, "Billing_Report.csv");
            }
            else {
                const start = formatDate(this.startDate, 'yyyy-MM-dd', this.locale);
                const end = formatDate(this.endDate, 'yyyy-MM-dd', this.locale);
                this.snackBarService.raiseNotification(
                    `${this.noUsageText} ${start} and ${end}.`,
                    this.dismissText,
                    null,
                    false,
                );
            }

        });

        this.dialogRef.close();
    }

    public cancel() {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }
}
