import {
    BaseInfiltrationMethod,
    defaultBaseInfiltrationConstant,
    WasteWaterBaseInfiltration,
} from 'app/shared/models/sliicer/customer-rainfall-profile';
import { Option, isSome, some, none } from 'fp-ts/es6/Option';

export type BaseInfiltrationValue = {
    method: BaseInfiltrationMethod;
    constant: number;
};

export type State = {
    savedBaseInfiltration: WasteWaterBaseInfiltration;
    method: BaseInfiltrationMethod;
    constant: number;
};

export function initState(data: WasteWaterBaseInfiltration): State {
    if (data !== null && data !== undefined) {
        return {
            savedBaseInfiltration: { ...data },
            method: data.method,
            constant: data.method === BaseInfiltrationMethod.StevensSchutzbach ? null : data.constant,
        };
    } else {
        return {
            savedBaseInfiltration: null,
            method: BaseInfiltrationMethod.StevensSchutzbach,
            constant: null,
        };
    }
}

export const constantChanged: (constant: number) => (state: State) => State = (constant) => (state) => ({
    ...state,
    constant: constant,
});

export const toResult = (state: State): WasteWaterBaseInfiltration => ({
    method: state.method,
    constant: state.constant ? state.constant : 0,
});
