import { Injectable } from '@angular/core';
import { Config } from './config';
import { CustomerService } from 'app/shared/services/customer.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MapView } from '../models/map-view';
import { LocationArgs } from '../models/locations';
import { MapType } from '../models/map-type';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LocationDashboardService {
    constructor(public http: HttpClient, public customerService: CustomerService) {}

    public getMapViews() {
        return this.http.get<MapView>(Config.urls.mapviews);
    }

    public getMapTypes() {
        return this.http.get<Array<MapType>>(Config.urls.maptypes);
    }

    /**
     * Below method would be used to fetch the inactive and active locations
     * @param params represents the location service paramaters
     */
    public getLocations(params: LocationArgs) {
        // creating HttpParams for HttpClient request

        let httpParams: HttpParams = new HttpParams();

        // setting all the existing key value of param to HttpParams
        Object.keys(params).forEach(function (key) {
            httpParams = httpParams.append(key, params[key]);
        });
        return this.http.get<any>(`${Config.urls.locations}/${params.customerId}`, { params: httpParams });
    }

    /**
     * Below method would be used to fetch the inactive and active locations
     * @param params represents the location service paramaters
     */
    public getLocationsList(params: LocationArgs) {
        // creating HttpParams for HttpClient request

        let httpParams: HttpParams = new HttpParams();

        // setting all the existing key value of param to HttpParams
        Object.keys(params).forEach(function (key) {
            httpParams = httpParams.append(key, params[key]);
        });
        return this.http.get<any>(`${Config.urls.locations}/${params.customerId}`, { params: httpParams });
    }

    /*
     * @getWidgetsPermission is used to get the permission corresponding to user.
     */
    public getWidgetsPermission() {
        return this.http.get<any>(Config.urls.permission);
    }
}
