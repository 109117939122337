import { SelectionModel } from '@angular/cdk/collections';
import { DOCUMENT } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { TranslateService } from '@ngx-translate/core';

import { StatusCodeService } from 'app/shared/services/status-code.service';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { DataEditType } from 'app/shared/constant';
import { Q_FINAL_ENTITY } from 'app/shared/constant';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { DataEditService } from 'app/shared/services/data-edit.service';
import { ViewDataService } from 'app/shared/services/view-data.service';
import {
    DataEditingData,
    DataEditingReasons,
    DataEditingRow,
    DataEditingSelectedTimeStamps,
    DepthPoint,
    EditedData,
    EntitySeries,
    OptimizedViewData,
} from 'app/shared/models/view-data';
import { Selectable } from 'app/shared/models/selectable';
import { IComponentDialog } from 'app/shared/models/comopnent-cofirmation';
import {
    IComponentCustomizedConfirmation,
    IComponentCustomizedConfirmationResult,
} from 'app/shared/models/customized-confirmation-box';
import { DataEditPreviewParams } from 'app/shared/models/data-edit';
import { DataEditingParams, HydrographArgs } from 'app/shared/models/hydrograph';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';

const CHAR_E = 69;
const other = 'Other';

const VELOCITY_ENTITY = 4202;
const RAIN_ENTITY = 2123;
const VELOCITY = 'Velocity';
const RAIN = 'Rain';
const UNIDEPTH = 'UniDepth';
const QCONTINUITY = 'QContinuity';

@Component({
    selector: 'app-data-editing',
    templateUrl: './data-editing.component.html',
    styleUrls: ['./data-editing.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataEditingComponent implements OnInit, OnDestroy {
    @Output() public isLoading: boolean;
    @Input() public graphData: OptimizedViewData;
    @Input() public allowServerSidePaging = true;
    @Input() public enablePreview: boolean;
    @Input() public enableAccept: boolean;
    // subscriptions to unsubcribe on component destroy
    private subscriptions = new Array<Subscription>();
    public dataEditingArray = new Array<DataEditingData>();
    public selectedTimeStamps: DataEditingSelectedTimeStamps;
    public customerId: number;
    public locationId: number;
    public getHydrographParams: HydrographArgs;
    public noDataAvailable = false;
    public compactEnitiyDataArray: any[];
    public compactAutoCorrectedDataArray: any[];
    // boolean flag to display Auto Corrected Data
    public isDisplayAutoCorrectedData: boolean;
    // Stores the Information for Plot Bands
    public plotBands = [];

    // string for "Corrected" text on tooltip based on translate
    public correctedText: string;

    public editdataTableFlag = false;
    public entityList = new Array<Selectable>();

    public editMultipleDataFlag = false;
    public showFiltersFlag = false;

    public displayedColumns = ['dateTime', 'entity', 'rawData', 'modifiedValue'];
    public dataEditingTableDataSource: MatTableDataSource<DataEditingData>;
    @ViewChild(MatPaginator) public dataEditingTablePaginator: MatPaginator;
    @ViewChild(MatSort) public dataEditingTableSort: MatSort;
    public dataEditingTableData = new Array<DataEditingData>();
    public totalPaginationLength = 0;
    public selection = new SelectionModel<DataEditingData>(true, []);

    public advanceGraphData = new Array<DataEditingData>();
    public originalAdvanceGraphData = new Array<DataEditingData>();
    public selectedDataEditingData: DataEditingData[] = [];
    public isAutoCorrectEnabled: boolean;
    public selectedDataEditingRow: DataEditingRow;
    public previewLoader = false;
    public enableUndoPoints: boolean;
    public isClearEnabled: boolean;
    public editedDataArray = new Array<EditedData>();
    public datesArray = [];
    public selectedRow: DataEditingData;
    public entitySelectValidationError: string;
    public dateErrMsg: string;
    public startGreaterThanTodayErr: string;
    public autoCorrectedData = [];
    public isPreviewClicked = false;

    // Variable which displays the state of entity validation
    public displayEntityError: boolean;

    // Variable which represents the entity error validation message
    public entityError: string;
    public maxDisplayLimit: number;

    public maxDate = new Date();
    public minDate = new Date();

    /* Variable initilization section */
    public startDate: Date;
    public endDate: Date;
    public otherReason: string;
    public dayErrorMessage: string;
    public isDaySpanValid: boolean;
    public is12HourFormat = false;
    public dateFormat: string;
    /*End section */

    public showOnGraphCount: number;

    // Represnts the end date form value.
    public endDateReason: Date;

    // Represents the start date form value.
    public startDateReason: Date;
    public reasons;
    public entitiesEdited = new Array<string>();
    public showResonForEdit = false;
    public reasonsSelector = new Array<Array<Selectable>>();
    public selectedReason = '';
    public isReasonSelected = true;
    public hideCommentSection = new Array<boolean>();

    public startDateInterval: Date;
    public endDateInterval: Date;

    public startDateDataGraph = new Array<FormControl>();
    public endDateDataGraph = new Array<FormControl>();
    public hideFilters = false;

    public numberOfEntries: number;

    //  array to loop input values for timespan depending upon numberOfEntries
    public numberOfTimes: Array<number>;

    // Represents show Confirmation box flag
    public showConfirmation: boolean;

    public customerDateFormat: string;

    // Represents inpus for Confirmation Box
    public conformationDataInput: IComponentCustomizedConfirmation;
    public isStartIntervalIncluded: boolean;
    public isEndIntervalIncluded: boolean;
    public isStartDateValid = new Array<boolean>();
    public isEndDateValid = new Array<boolean>();
    public isStartDateValidRelativelyED = new Array<boolean>();
    public isEndDateValidRelativelyED = new Array<boolean>();
    public selectedEntitiesList = new FormControl();
    public isEntitiesSelectionChange = false;
    public flaggedPoints = [];
    public isCopyPasteOperationEnabled = false;
    private isRequestProcessing = false;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private uiUtilService: UiUtilsService,
        private viewDataService: ViewDataService,
        public dataEditService: DataEditService,
        public dateutilService: DateutilService,
        public statusCodeService: StatusCodeService,
        @Inject(DOCUMENT) private angularDocument: Document,
        private translate: TranslateService,
        private matDialog: MatDialog,
        private domOperationUtilsService: DomOperationUtilsService,
    ) {
        translate.get('COMMON.ENTITY_SELECT_VALIDATION').subscribe((res: string) => {
            this.entitySelectValidationError = res;
        });
        this.translate.get('COMMON.DATE_ERROR').subscribe((res: string) => {
            this.dateErrMsg = res;
        });
        this.translate.get('COMMON.START_DATE_GREATER_TODAY').subscribe((res: string) => {
            this.startGreaterThanTodayErr = res;
        });
        translate.get('COMMON.CORRECTED_TEXT').subscribe((res: string) => {
            this.correctedText = res;
        });
    }

    public ngOnInit() {
        this.dateutilService.dateFormat.subscribe(() => {
            this.customerDateFormat = this.dateutilService.getFormat();
        });
        this.startDateDataGraph[0] = new FormControl(
            new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        );
        this.endDateDataGraph[0] = new FormControl(
            new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
        );
        this.startDateDataGraph[0].disable();
        this.endDateDataGraph[0].disable();

        this.showOnGraphCount = 0;
        this.numberOfEntries = 1;
        this.numberOfTimes = Array(+this.numberOfEntries).fill(0);

        this.reasonsSelector[0] = new Array<Selectable>();
        this.viewDataService.DataEditReasons.subscribe(
            (resonsResponse: DataEditingReasons) => {
                if (resonsResponse) {
                    this.reasons = resonsResponse;
                    this.reasons.forEach((entity: DataEditingReasons, index) => {
                        this.reasonsSelector[0].push({
                            name: entity.reason,
                            id: entity.id,
                        });
                    });
                }
            },
            (error) => {
                // empty block
            },
        );

        this.viewDataService.updatedPoints.subscribe(
            (updatedElements: Array<Object>) => {
                if (updatedElements) {
                    this.advanceGraphData.map((x) => x.modifiedValue === null);
                    const filteredItem = this.advanceGraphData.filter((x) => x.id === VELOCITY_ENTITY);
                    if (filteredItem && filteredItem.length > 0) {
                        updatedElements.forEach((element) => {
                            this.advanceGraphData
                                .filter(
                                    (x) =>
                                        x.id === VELOCITY_ENTITY &&
                                        x.dateTime.getTime() === new Date(element['dateTime']).getTime(),
                                )
                                .forEach((x) => {
                                    x.modifiedValue = isNaN(element['x']) ? '1' : element['x'].toFixed(2);
                                    this.notifyAdvGraphWithEditedValues(x, false);
                                });
                        });
                    }
                    // Asjad : 13629 uncomment below line once data editing enable
                    // this.setPagination(updatedElements.length);
                    // this.generateDataEditingTable(this.advanceGraphData);
                    this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
                }
            },
            (error) => {
                // empty block
            },
        );

        this.viewDataService.flaggedPoints.subscribe(
            (flaggedPoints: Array<Object>) => {
                if (flaggedPoints) {
                    if (this.graphData) {
                        this.displayFlaggedChanges(flaggedPoints);
                    }

                    flaggedPoints.forEach((flaggedEntities) => {
                        this.dataEditingTableData
                            .filter((x) => x.id === flaggedEntities['id'])
                            .forEach((x) => {
                                flaggedEntities['points'].forEach((element) => {
                                    if (x.dateTime.getTime() === new Date(element).getTime()) {
                                        x.isFlagged = true;
                                        this.editedDataArray.push({
                                            whichPoint: x,
                                            originalModifiedValue: x.modifiedValue,
                                            pointExists: true,
                                            isFlagged: x.isFlagged,
                                        });
                                    }
                                });
                            });
                    });
                    if (this.editedDataArray && this.editedDataArray.length > 0) {
                        this.enablePreview = false;
                        this.enableAccept = true;
                    }
                    // this.setPagination(flaggedPoints.length);
                    this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
                }
            },
            (error) => {
                // empty block
            },
        );

        this.viewDataService.unFlaggedPoints.subscribe(
            (unFlaggedPoints: Array<Object>) => {
                if (unFlaggedPoints) {
                    if (this.graphData) {
                        this.displayFlaggedChanges(unFlaggedPoints);
                    }
                    unFlaggedPoints.forEach((flaggedEntities) => {
                        this.dataEditingTableData
                            .filter((x) => x.id === flaggedEntities['id'])
                            .forEach((x) => {
                                flaggedEntities['points']
                                    .filter((element) => x.dateTime.getTime() === new Date(element).getTime())
                                    .forEach((element) => {
                                        x.isFlagged = false;
                                        this.editedDataArray.push({
                                            whichPoint: x,
                                            originalModifiedValue: x.modifiedValue,
                                            pointExists: true,
                                            isFlagged: x.isFlagged,
                                        });
                                    });
                            });
                    });
                    if (this.editedDataArray && this.editedDataArray.length > 0) {
                        this.enablePreview = false;
                        this.enableAccept = true;
                    }
                    // this.setPagination(this.dataEditingTablePaginator.length);
                    this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
                }
            },
            (error) => {
                // empty block
            },
        );

        this.hideCommentSection[0] = true;
        this.isDaySpanValid = true;
        this.enableUndoPoints = true;
        this.isClearEnabled = false;
        this.advanceGraphData.length = this.originalAdvanceGraphData.length = 0;
        this.selectedDataEditingRow = null;
        this.viewDataService.selectedScatterPoints.next(null);
        this.isStartIntervalIncluded = true;
        this.isEndIntervalIncluded = true;
        this.isStartDateValid[0] = true;
        this.isEndDateValid[0] = true;
        this.isStartDateValidRelativelyED[0] = true;
        this.isEndDateValidRelativelyED[0] = true;

        const launchDataEditingTableSubscription = this.viewDataService.launchDataEditingTable.subscribe((response) => {
            if (response) {
                this.customerId = response.customerId;
                this.getHydrographParams = response.hydrographParams;
                this.isDisplayAutoCorrectedData = response.isDisplayAutoCorrectedData;

                // make api call
                // this.getHydroGraphData(this.getHydrographParams);
                this.getDataEditing(this.customerId, <DataEditingParams>{
                    start: this.getHydrographParams.start,
                    end: this.getHydrographParams.end,
                    eids: this.getHydrographParams.entityIds,
                    locationId: this.getHydrographParams.locationId,
                });
            }
        });

        this.subscriptions.push(launchDataEditingTableSubscription);

        const copiedPointsUpdateTableSubscription = this.viewDataService.updateDataTableWithCopiedPoints.subscribe(
            (copiedPoints) => {
                if (copiedPoints && copiedPoints['points'] && copiedPoints['points'].length > 0) {
                    this.updateDataTableWithCopiedPoints(copiedPoints);
                } else {
                    this.isCopyPasteOperationEnabled = false;
                    this.changeDetectorRef.detectChanges();
                }
            },
        );
        this.subscriptions.push(copiedPointsUpdateTableSubscription);
        /* For editable entities unidepth, velocity, rain, temp update data table with multiplepoints edits*/
        const multiPointEditSubscription = this.viewDataService.updatedMultiPointEditValues.subscribe(
            (selectedPoints) => {
                if (selectedPoints && selectedPoints['points'] && selectedPoints['points'].length > 0) {
                    this.dataEditingTableData
                        .filter((x) => x.id === selectedPoints['id'])
                        .forEach((x) => {
                            selectedPoints['points']
                                .filter((element) => x.dateTime.getTime() === element.dateTime)
                                .forEach((element) => {
                                    x.modifiedValue = element.reading;
                                    this.editedDataArray.push({
                                        whichPoint: x,
                                        originalModifiedValue: x.modifiedValue,
                                        pointExists: true,
                                        dateTime: element.x,
                                    });
                                    if (this.entitiesEdited.indexOf(x.entity) < 0) {
                                        this.entitiesEdited.push(x.entity);
                                    }
                                });
                        });
                    if (this.editedDataArray && this.editedDataArray.length > 0) {
                        this.startDateInterval = selectedPoints['startDate'];
                        this.endDateInterval = selectedPoints['endDate'];
                        this.startDateDataGraph[0].setValue(this.startDateInterval);
                        this.endDateDataGraph[0].setValue(this.endDateInterval);
                        this.enablePreview = true;
                        this.isPreviewClicked = true;
                        this.enableAccept = false;
                        this.isCopyPasteOperationEnabled = true;
                    }
                    // this.setPagination(this.totalPaginationLength);
                    this.changeDetectorRef.detectChanges();
                }
            },
        );
        this.subscriptions.push(multiPointEditSubscription);
    }

    public epochToJsDate(ts) {
        // ts = epoch timestamp
        // get current date obj
        const currentDate = new Date(ts);
        const localOffset = currentDate.getTimezoneOffset() * 60000;
        // date without offset
        const newDate = new Date(ts + localOffset);
        return newDate;
    }

    public displayFlaggedChanges(flaggedPoints: any) {
        let entityName;
        if (flaggedPoints[0].id === 4122) {
            entityName = UNIDEPTH;
        } else if (flaggedPoints[0].id === 4202) {
            entityName = VELOCITY;
        } else if (flaggedPoints[0].id === 2123) {
            entityName = RAIN;
        } else {
            entityName = QCONTINUITY;
        }
        const editedPoints = flaggedPoints[0].points;
        if (this.entitiesEdited && this.entitiesEdited.indexOf(entityName) < 0) {
            this.entitiesEdited.push(entityName);
        }

        // set date intervals for flagged points
        const startDateFlagged = this.epochToJsDate(editedPoints[0]);
        const endDateFlagged = this.epochToJsDate(editedPoints[editedPoints.length - 1]);
        if (this.startDateInterval && !this.statusCodeService.clearFlaggedPointsLable.getValue()) {
            this.startDateInterval =
                startDateFlagged < this.startDateInterval ? startDateFlagged : this.startDateInterval;
        } else {
            this.startDateInterval = startDateFlagged;
            this.statusCodeService.clearFlaggedPointsLable.next(false);
        }

        if (this.endDateInterval && !this.statusCodeService.clearFlaggedPointsLable.getValue()) {
            this.endDateInterval = endDateFlagged > this.endDateInterval ? endDateFlagged : this.endDateInterval;
        } else {
            this.endDateInterval = endDateFlagged;
            this.statusCodeService.clearFlaggedPointsLable.next(false);
        }
    }

    /**
     * Retrieves hydro graph data for the current location dashboard filters.
     */
    private getHydroGraphData(params: HydrographArgs): void {
        this.isLoading = true;
        this.changeDetectorRef.detectChanges();
        this.noDataAvailable = false;
        this.graphData = null;
        this.locationId = params.locationId;
        const subscription = this.viewDataService.getHydrograph(this.customerId, this.locationId, params).subscribe(
            (hydroResult: OptimizedViewData) => {
                this.graphData = <OptimizedViewData>hydroResult;
                this.displayHydroGraph();
                this.isLoading = false;
                this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
            },
            (error) => {
                this.isLoading = false;
                this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
            },
        );
        this.subscriptions.push(subscription);
    }

    // Displays the Hydrograph from entity information
    // tslint:disable-next-line:cyclomatic-complexity
    public displayHydroGraph() {
        const entitiesData = new Array<EntitySeries>();
        this.dataEditingArray.length = 0;
        let correctedSeries;

        if (this.selectedTimeStamps) {
            this.selectedTimeStamps.minValue = 0;
            this.selectedTimeStamps.maxValue = 0;
        }

        for (const displayGroup of this.graphData.displayGroups) {
            for (const entityInformation of displayGroup.entities) {
                if (entitiesData.findIndex((k) => k.name === entityInformation.name) === -1) {
                    /* The Corrected Series represents the series of Auto-Corrected Points*/

                    correctedSeries = <EntitySeries>{
                        id: entityInformation.id + 10000,
                        name: this.correctedText + ' ' + entityInformation.name,
                        showInLegend: false,
                        displayGroupID: displayGroup.id,
                        unitOfMeasure: displayGroup.unit,
                        style: displayGroup.style,
                        data: [],
                        color: '#DAA520',
                        precision: displayGroup.precision,
                        axis: {
                            minimum: 0,
                            maximum: 10,
                        },
                        type: 'line',
                        dashStyle: 'longdash',
                        dataGrouping: { enabled: false },
                        zIndex: 5,
                        lineWidth: 1.5,
                        yAxis: 0,
                        step: false,
                        connectNulls: false,
                        plotBands: new Array<Object>(),
                        turboThreshold: 1000000000,
                    };

                    const entityInformationData = <EntitySeries>{
                        id: entityInformation.id,
                        name: entityInformation.name,
                        showInLegend: null,
                        displayGroupID: displayGroup.id,
                        unitOfMeasure: displayGroup.unit,
                        style: displayGroup.style,
                        data: [],
                        precision: displayGroup.precision,
                        axis: {
                            minimum: 0,
                            maximum: 10,
                            label: `${displayGroup.label} (${displayGroup.unit})`,
                        },
                        type:
                            entityInformation.id === RAIN_ENTITY ||
                            entityInformation.id === 4902 ||
                            displayGroup.id === 8
                                ? 'column'
                                : 'coloredline',
                        dataGrouping: { enabled: false },
                        zIndex: 5,
                        lineWidth: 1.5,
                        yAxis: 0,
                        step: false,
                        connectNulls: false,
                        correctedData: correctedSeries,
                    };

                    const entityDataObject = this.computeEntityData(entityInformation.id);

                    for (const prop in entityDataObject) {
                        if (prop === 'Entites') {
                            entityInformationData.data = entityDataObject[prop];
                            this.compactEnitiyDataArray = entityInformationData.data;
                        } else {
                            correctedSeries.data = entityDataObject[prop];
                            this.compactAutoCorrectedDataArray = correctedSeries.data;
                        }
                    }

                    if (
                        entityInformationData.name === 'UniDepth' ||
                        entityInformationData.name === 'Velocity' ||
                        entityInformationData.name === 'Rain' ||
                        entityInformationData.name === 'Temp'
                    ) {
                        for (const actualData of entityInformationData.data) {
                            const rawDate = new Date(actualData.x);
                            const rawDateUTC = new Date(rawDate.getTime() + rawDate.getTimezoneOffset() * 60000);

                            this.dataEditingArray.push({
                                dateTime: rawDate,
                                dateTimeUTC: rawDateUTC,
                                entity: entityInformationData.name,
                                rawData: Number(actualData.y.toFixed(displayGroup.precision)),
                                modifiedValue: '',
                                originalModifiedValue: '',
                                id: entityInformationData.id,
                                displayGroupId: displayGroup.id,
                                displayUnit: displayGroup.unit,
                            });
                        }
                    }

                    if (
                        this.isDisplayAutoCorrectedData &&
                        (entityInformationData.name === 'UniDepth' ||
                            entityInformationData.name === 'Raw Velocity' ||
                            entityInformationData.name === 'Rain' ||
                            entityInformationData.name === 'Temp')
                    ) {
                        correctedSeries.data
                            .filter((item) => item.y > 0)
                            .forEach((correctedData) => {
                                const rawDate = new Date(correctedData.x);
                                const rawDateUTC = new Date(rawDate.getTime() + rawDate.getTimezoneOffset() * 60000);

                                this.dataEditingArray.push({
                                    dateTime: rawDate,
                                    dateTimeUTC: rawDateUTC,
                                    entity: entityInformation.name,
                                    suggestedData: correctedData.y.toFixed(displayGroup.precision),
                                    modifiedValue: '',
                                    originalModifiedValue: '',
                                    id: entityInformation.id,
                                    displayGroupId: displayGroup.id,
                                    displayUnit: displayGroup.unit,
                                });
                            });
                    }
                }
            }
        }

        if (this.dataEditingArray.length > 0) {
            this.advanceGraphData = this.originalAdvanceGraphData = this.dataEditingArray;
            this.advanceGraphData
                .filter((row) => row.suggestedData)
                .forEach((row) => {
                    this.autoCorrectedData.push(row);
                });
        } else {
            this.noDataAvailable = true;
            this.hideFilters = true;
            this.isLoading = false;
            this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
            return;
        }

        const closeDataEditingModeSubscription = this.viewDataService.closeDataEditingMode.subscribe((response) => {
            if (response) {
                this.emitDataEditingDialog(false);
                this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
            }
        });

        this.subscriptions.push(closeDataEditingModeSubscription);

        const previewDataEditingSubscription = this.viewDataService.previewDataEditing.subscribe((response) => {
            if (response) {
                this.saveEditedData();
            }
        });

        this.subscriptions.push(previewDataEditingSubscription);
        const acceptAllAutoCorrectedValSubscription = this.viewDataService.acceptAllAutoCorrectedVal.subscribe(
            (response) => {
                if (response) {
                    this.acceptAllAutoCorrectedVal();
                }
            },
        );

        this.subscriptions.push(acceptAllAutoCorrectedValSubscription);
        const acceptDataEditingSubscription = this.viewDataService.acceptDataEditing.subscribe((response) => {
            if (response) {
                this.emitDataEditingDialog(true);
            }
        });

        this.subscriptions.push(acceptDataEditingSubscription);
        const clearFilterSubscription = this.viewDataService.clearFilter.subscribe((response) => {
            if (response) {
                this.clearFilter();
            }
        });

        this.subscriptions.push(clearFilterSubscription);
        const notifyDataEditingTableSubscription = this.viewDataService.notifyDataEditingTable.subscribe((response) => {
            if (response) {
                if (response.entity.indexOf('Modified') !== -1) {
                    response.entity = response.entity.replace(/Modified /g, '');
                }

                if (response.entity.indexOf('Corrected') !== -1) {
                    response.entity = response.entity.replace(/Corrected /g, '');
                }

                this.selectedDataEditingData.length = 0;
                this.selectedDataEditingRow = response;
                this.selectDataEditingRow(this.selectedDataEditingRow);
            }
        });

        this.subscriptions.push(notifyDataEditingTableSubscription);
        // Subscribe to get selected Data from hydrograph for Data Editing Table
        const selectedTimeStampsSubscription = this.viewDataService.selectedTimeStamps.subscribe((response) => {
            this.selectedDataEditingData.length = 0;
            if (response) {
                this.isLoading = true;
                if (this.isClearEnabled) {
                    this.clearFilter();
                }
                const selectedTimeStampsRange = response;
                if (this.dataEditingTableDataSource) {
                    this.dataEditingTableDataSource.paginator.pageIndex = 0;
                }
                if (selectedTimeStampsRange) {
                    this.advanceGraphData
                        .filter(
                            (row) =>
                                new Date(row.dateTime).getTime() >= selectedTimeStampsRange['minValue'] &&
                                new Date(row.dateTime).getTime() <= selectedTimeStampsRange['maxValue'],
                        )
                        .forEach((row) => {
                            this.selectedDataEditingData.push(row);
                        });
                    // Asjad : 13629 uncomment below line once data editing enable
                    // this.generateDataEditingTable(this.selectedDataEditingData);
                    this.isClearEnabled = true;
                    this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
                } else {
                    this.isClearEnabled = false;
                }
            }
        });

        this.subscriptions.push(selectedTimeStampsSubscription);
        const selectedScatterPointsSubscription = this.viewDataService.selectedScatterPoints.subscribe((response) => {
            if (response) {
                this.selectedDataEditingData.length = 0;
                this.isLoading = true;
                if (this.dataEditingTableDataSource) {
                    this.dataEditingTableDataSource.paginator.pageIndex = 0;
                }
                if (response) {
                    this.advanceGraphData
                        .filter((row) => response.indexOf(new Date(row.dateTime).getTime()) > -1)
                        .forEach((row) => this.selectedDataEditingData.push(row));
                    // Asjad : 13629 uncomment below line once data editing enable
                    // this.generateDataEditingTable(this.selectedDataEditingData);
                    this.isClearEnabled = false;
                    this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
                } else {
                    this.isClearEnabled = false;
                }
            }
        });

        this.subscriptions.push(selectedScatterPointsSubscription);

        const resetZoomClickedSubscription = this.viewDataService.resetZoomClicked.subscribe((response) => {
            if (response) {
                this.clearFilter();
            }
        });

        this.subscriptions.push(resetZoomClickedSubscription);
        // this.generateDataEditingTable();
    }

    // tslint:disable-next-line:cyclomatic-complexity
    private computeEntityData(entityId) {
        const selectedEntity = this.graphData.entityIds.indexOf(entityId);
        const autoCorrectedEntity = this.graphData.entityIds.indexOf(entityId + 10000);
        const entities = [];
        const autoCorrectedData = [];
        let depthPoint: DepthPoint;
        let correctedPoint: DepthPoint;
        let compactEntityData: string[];
        let compactAutoCorrectedData: string[];
        let index = 0;

        const plotband = {
            color: '',
            fxgrom: null,
            to: null,
            id: 'plotBandAnnomalies',
        };
        // tslint:disable-next-line:forin
        for (const prop in this.graphData.compactData) {
            /* Hydrograph Optimization - Get the Response String having the same entity as display group selected */
            compactEntityData = String(this.graphData.compactData[prop])
                .slice(1, String(this.graphData.compactData[prop]).length - 1)
                .split(';')
                .filter((element) => element.split(':')[0] === selectedEntity.toString());

            /* Hydrograph Optimization - Get the Response String having the same entity as display group selected */
            if (this.isDisplayAutoCorrectedData) {
                compactAutoCorrectedData = String(this.graphData.compactData[prop])
                    .slice(1, String(this.graphData.compactData[prop]).length - 1)
                    .split(';')
                    .filter((element) => element.split(':')[0] === autoCorrectedEntity.toString());
            }

            if (compactEntityData && compactEntityData.length > 0) {
                let compactValue: string;
                let QValue: any;

                /* Means that the entity is having Q- Value not Good */
                if (
                    compactEntityData &&
                    String(compactEntityData).split(':')[1] &&
                    String(compactEntityData).split(':')[1].indexOf(',') > 0
                ) {
                    compactValue = String(compactEntityData)
                        .split(':')[1]
                        .split(',')[0]
                        .slice(1, String(compactEntityData).split(',')[0].length);
                    QValue = String(compactEntityData).split(':')[1].split(',')[1];
                } else {
                    compactValue = String(compactEntityData).split(':')[1];
                    QValue = 15;
                }

                if (compactEntityData && String(compactEntityData).split(':')[2]) {
                    depthPoint = null;
                    this.flaggedPoints.push(new Date(Number(prop)).getTime() * 1000);
                } else {
                    depthPoint = {
                        x: new Date(Number(prop)).getTime() * 1000,
                        y: this.correctedData(String(compactEntityData).split(':')[1])
                            ? parseFloat(this.correctedData(String(compactEntityData).split(':')[1]))
                            : 0,
                    };
                }

                if (this.isDisplayAutoCorrectedData) {
                    if (compactAutoCorrectedData) {
                        let compactValue: string;
                        let QValue: any;

                        /* Means that the entity is having Q- Value not Good */
                        if (
                            compactAutoCorrectedData &&
                            String(compactAutoCorrectedData).split(':')[1] &&
                            String(compactAutoCorrectedData).split(':')[1].indexOf(',') > 0
                        ) {
                            compactValue = String(compactAutoCorrectedData)
                                .split(':')[1]
                                .split(',')[0]
                                .slice(1, String(compactAutoCorrectedData).split(',')[0].length);
                            QValue = String(String(compactAutoCorrectedData).split(':')[1].split(',')[2]).slice(0, -1);
                        } else {
                            compactValue = String(compactAutoCorrectedData).split(':')[1];
                            QValue = 15;
                        }

                        correctedPoint = {
                            x: new Date(Number(prop)).getTime() * 1000,
                            y: this.correctedData(String(compactAutoCorrectedData).split(':')[1])
                                ? parseFloat(this.correctedData(String(compactAutoCorrectedData).split(':')[1]))
                                : 0,
                        };
                    }

                    depthPoint['index'] = index;

                    /* If the entity data is equal to the Auto Corrected data - means no correction is required */

                    if (depthPoint['y'] === correctedPoint['y'] || correctedPoint['y'] === 0) {
                        correctedPoint['y'] =
                            autoCorrectedData.length > 0 &&
                            autoCorrectedData[index - 1] &&
                            autoCorrectedData[index - 1]['marker']['enabled']
                                ? depthPoint['y']
                                : null;
                        correctedPoint['marker'] = {
                            enabled: false,
                            states: {
                                hover: {
                                    enabled: false,
                                },
                            },
                        };
                        correctedPoint['hideToolTip'] = true;
                        correctedPoint['locationName'] = '';
                        correctedPoint['index'] = index;
                    } else {
                        /* If the entity data is not equal to the Auto Corrected data - means correction is required */
                        depthPoint['marker'] = {
                            symbol: 'circle',
                            radius: 4,
                            enabled: true,
                        };

                        correctedPoint['marker'] = {
                            symbol: 'circle',
                            radius: 4,
                            enabled: true,
                        };
                        correctedPoint['index'] = index;
                        correctedPoint['locationName'] = '';

                        /*check if previous point was Depth Point then replace its null value from the series to the actual value*/
                        if (
                            autoCorrectedData.length > 0 &&
                            autoCorrectedData[index - 1] &&
                            !autoCorrectedData[correctedPoint['index'] - 1]['marker']['enabled']
                        ) {
                            plotband['from'] = autoCorrectedData[correctedPoint['index'] - 1]['x'];
                            autoCorrectedData[correctedPoint['index'] - 1]['y'] =
                                entities[correctedPoint['index'] - 1]['y'];
                        }
                    }
                }

                if (depthPoint) {
                    entities.push(depthPoint);
                }

                if (correctedPoint) {
                    autoCorrectedData.push(correctedPoint);
                }
                index++;
            }
        }
        this.statusCodeService.refreshHydrographOnEditWindow.next(true);
        return { Entites: entities, AutoCorrectedData: autoCorrectedData };
    }

    private correctedData(data) {
        return data && data.indexOf(',') > 0 ? data.split(',')[0].slice(1, data.split(',')[0].length) : data;
    }

    public generateDataEditingTable(
        dataEditingItems: Array<DataEditingData> = [],
        dataEditingRowSelected?: DataEditingData,
    ) {
        this.dataEditingTableDataSource = null;
        this.datesArray.length = 0;
        this.entityList.length = 0;
        this.dataEditingTableData = [];

        if (dataEditingItems && dataEditingItems.length > 0) {
            for (let data of dataEditingItems) {
                // logic for retaining the value of editedData when the user zoom on hydrograph
                if (this.editedDataArray.length > 0) {
                    this.editedDataArray
                        .filter((x) => x.whichPoint.dateTime === data.dateTime && x.whichPoint.entity === data.entity)
                        .forEach((y) => (data = y.whichPoint));
                }

                const dataEditingItem =
                    dataEditingRowSelected && dataEditingRowSelected.dateTime === data.dateTime
                        ? dataEditingRowSelected
                        : data;

                const editData = {
                    dateTime: dataEditingItem.dateTime,
                    dateTimeUTC: dataEditingItem.dateTimeUTC,
                    entity:
                        dataEditingItem.entity.indexOf('(') > -1
                            ? dataEditingItem.entity.substring(0, dataEditingItem.entity.indexOf('(')).trim()
                            : dataEditingItem.entity,
                    rawData: dataEditingItem.rawData,
                    suggestedData: dataEditingItem.suggestedData,
                    modifiedValue: dataEditingItem.modifiedValue,
                    originalModifiedValue: dataEditingItem.modifiedValue,
                    id: dataEditingItem.id,
                    displayGroupId: dataEditingItem.displayGroupId,
                    displayUnit: dataEditingItem.displayUnit,
                    isSelected:
                        dataEditingRowSelected && dataEditingRowSelected.dateTime === data.dateTime
                            ? dataEditingRowSelected.isSelected
                            : false,
                    isFlagged: dataEditingItem.isFlagged,
                };

                this.datesArray.push(new Date(data.dateTimeUTC));
                this.entityList.push({
                    id: data.id,
                    name:
                        data.entity.indexOf('(') > -1
                            ? data.entity.substring(0, data.entity.indexOf('(')).trim()
                            : data.entity,
                    isChecked: true,
                });

                this.dataEditingTableData.push(editData);
            }

            this.dataEditingTableDataSource = new MatTableDataSource(this.dataEditingTableData);
            this.dataEditingTableDataSource.paginator = this.dataEditingTablePaginator;

            this.entityList = _.uniq(this.entityList, 'name');
            this.maxDisplayLimit = this.entityList.length;

            this.minDate = new Date(Math.min(...this.datesArray));
            this.maxDate = new Date(Math.max(...this.datesArray));

            this.endDate = new Date(this.maxDate);
            this.startDate = new Date(this.minDate);
            this.isDaySpanValid = true;

            // set time format to UI
            this.dateFormat = this.dateutilService.getFormat();
            this.is12HourFormat = this.dateutilService.timeFormat.getValue() !== 'hh:mm:ss';
            this.dateFormat = this.dateFormat + ' ' + this.is12HourFormat ? 'hh:mm a' : 'HH:mm';
            this.dataEditingTableSort.sort(<MatSortable>{
                id: 'dateTime',
                start: 'asc',
            });
            this.dataEditingTableSort.direction = 'asc';
            this.dataEditingTableDataSource.sort = this.dataEditingTableSort;
            this.retainFlaggedPointOnGrid();

            this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
        } else {
            this.totalPaginationLength = 1;
        }
        this.selectedEntitiesList.setValue(this.entityList);
        this.isLoading = false;
        this.viewDataService.previewLoader.next(false);
    }

    public setPagination(rowCount: number) {
        this.totalPaginationLength = rowCount || 10;
        this.dataEditingTablePaginator.length = this.totalPaginationLength;
        this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
    }

    // Below function would sort the data editing table
    public sortData() {
        this.dataEditingTableDataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'dateTime':
                    return item.dateTime;
                case 'entity':
                    return item.entity;
                default:
                    return item[property];
            }
        };
    }

    public UndoDataPoints() {
        let lastAddedDataPoint: DataEditingData;
        if (this.editedDataArray.length > 0 && !lastAddedDataPoint) {
            lastAddedDataPoint = this.editedDataArray[this.editedDataArray.length - 1].whichPoint;
            this.editedDataArray.pop();
        }
        lastAddedDataPoint = null;
    }

    public acceptCorrectedValue(element, whichActionToPerform) {
        if (element && !whichActionToPerform) {
            if (element.suggestedData === element.modifiedValue) {
                // To Do show this alert in a nice information popup box
                this.matDialog.open(ConfirmationDialogComponent, {
                    disableClose: true,
                    data: <IComponentDialog>{
                        title: 'INFORMATION',
                        message: `Suggested Data already added.`,
                        cancelText: '',
                        okText: 'OK',
                    },
                });
                return;
            } else {
                element.modifiedValue = element.suggestedData;
                this.notifyAdvGraphWithEditedValues(element, whichActionToPerform);
            }
        }
    }

    public acceptAllAutoCorrectedVal() {
        if (this.autoCorrectedData.length > 0) {
            this.notifyAdvGraphWithAllEditedValues(this.autoCorrectedData, false);
        }
    }

    public selectDataEditingRow(selectedRow?: DataEditingRow) {
        if (!selectedRow) {
            return;
        }

        if (!this.dataEditingTableDataSource) {
            this.isLoading = true;
            // Asjad : 13629 uncomment below line once data editing enable
            // this.generateDataEditingTable(this.advanceGraphData);
        } else {
            this.dataEditingTableDataSource.data = this.dataEditingTableDataSource.data.filter(
                (x) => x.entity === selectedRow.entity,
            );
            this.selectedDataEditingData.length = 0;
            this.advanceGraphData = this.dataEditingTableDataSource.data;
            this.dataEditingTableDataSource.data.forEach((row, index) => {
                row.isSelected = false;
                if (selectedRow.timeStampSelected === new Date(row.dateTime).getTime()) {
                    const pageIndex = index / this.dataEditingTablePaginator.pageSize;
                    const finalPageIndex = +pageIndex.toString().split('.')[0];
                    this.dataEditingTablePaginator.pageIndex = finalPageIndex;
                    this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
                    row.isSelected = true;
                    this.selectedRow = row;
                    // Asjad : 13629 uncomment below line once data editing enable
                    // this.generateDataEditingTable(this.advanceGraphData, row);
                }
            });
        }
        this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
        this.isClearEnabled = true;
    }

    // tslint:disable-next-line:cyclomatic-complexity
    public notifyAdvGraphWithEditedValues(whichPoint: DataEditingData, whichActionToPerform: boolean) {
        if (whichPoint) {
            if (this.editedDataArray.length > 0) {
                this.UndoDataPoints();
                if (whichPoint.modifiedValue === null) {
                    this.enablePreview = true;
                    return;
                }
            }
            if (!whichActionToPerform) {
                whichPoint.originalModifiedValue = whichPoint.modifiedValue;
                this.editedDataArray.push({
                    whichPoint: whichPoint,
                    originalModifiedValue: whichPoint.modifiedValue,
                });

                if (this.compactEnitiyDataArray.length > 0) {
                    this.compactEnitiyDataArray
                        .filter((previewData) => previewData.x === whichPoint.dateTime.getTime())
                        .forEach((previewData) => {
                            previewData.y = whichPoint.modifiedValue;
                            previewData.edited = 1;
                        });
                }

                const compactData = [];
                for (let i = 0; i < this.editedDataArray.length; i++) {
                    for (const displayGroup of this.graphData.displayGroups) {
                        displayGroup.entities
                            .filter(
                                (entityInformation) => entityInformation.id === this.editedDataArray[i].whichPoint.id,
                            )
                            .forEach(() => {
                                for (const prop in this.graphData.compactData) {
                                    if (Number(prop) * 1000 === this.editedDataArray[i].whichPoint.dateTime.getTime()) {
                                        compactData.push(this.getCompactData(this.editedDataArray[i].whichPoint));
                                    }
                                }
                            });
                    }
                }

                for (let j = 0; j < compactData.length; j++) {
                    for (const prop in this.graphData.compactData) {
                        if (Number(prop) * 1000 === whichPoint.dateTime.getTime()) {
                            this.graphData.compactData[prop] = compactData[j];
                        }
                    }
                }
            }

            if (whichActionToPerform) {
                whichPoint.modifiedValue = '';
            }

            if (whichPoint.modifiedValue || this.editedDataArray.length > 0) {
                this.enablePreview = false;
            } else {
                this.enablePreview = true;
                this.enableAccept = true;
            }
            this.startDateInterval =
                this.startDateInterval < whichPoint.dateTimeUTC ? this.startDateInterval : whichPoint.dateTimeUTC;
            this.endDateInterval =
                this.endDateInterval > whichPoint.dateTimeUTC ? this.endDateInterval : whichPoint.dateTimeUTC;
            this.startDateDataGraph[0].setValue(this.startDateInterval);
            this.endDateDataGraph[0].setValue(this.endDateInterval);
            if (this.entitiesEdited.indexOf(whichPoint.entity) < 0) {
                this.entitiesEdited.push(whichPoint.entity);
            }
            this.viewDataService.syncScatterChartWithHydrographs({
                editedTimestamp: whichPoint.dateTime.getTime(),
                editedValue: +whichPoint.modifiedValue,
                editedEntity: +whichPoint.id,
            });
        }
    }

    public notifyAdvGraphWithAllEditedValues(whichAutoCorrectArray: DataEditingData[], whichActionToPerform: boolean) {
        if (whichAutoCorrectArray) {
            whichAutoCorrectArray.forEach((element) => {
                if (!whichActionToPerform) {
                    this.editedDataArray.push({
                        whichPoint: element,
                        originalModifiedValue: element.modifiedValue,
                        pointExists: true,
                    });
                    element.modifiedValue = element.suggestedData;
                }

                if (element.modifiedValue || this.editedDataArray.length > 0) {
                    this.enablePreview = false;
                } else {
                    this.enablePreview = true;
                    this.enableAccept = true;
                }

                this.startDateInterval =
                    this.startDateInterval < element.dateTimeUTC ? this.startDateInterval : element.dateTimeUTC;
                this.endDateInterval =
                    this.endDateInterval > element.dateTimeUTC ? this.endDateInterval : element.dateTimeUTC;
                this.startDateDataGraph[0].setValue(this.startDateInterval);
                this.endDateDataGraph[0].setValue(this.endDateInterval);
                if (this.entitiesEdited.indexOf(element.entity) < 0) {
                    this.entitiesEdited.push(element.entity);
                }

                this.viewDataService.notifyAdvGraphWithAllEditedValues.next({
                    whichPoint: element,
                    whichActionToPerform: whichActionToPerform,
                    enablePreview: this.enablePreview,
                });
            });
        }
    }

    public unSelectRowSelected(element) {
        if (this.selectedRow) {
            if (element.isSelected === this.selectedRow.isSelected) {
                return;
            }
            this.selectedRow.isSelected = false;
            // Asjad : 13629 uncomment below line once data editing enable
            // this.generateDataEditingTable(this.selectedDataEditingData, this.selectedRow);
            this.selectedRow = null;
        }

        if (element) {
            this.viewDataService.unSelectPointOnHydrograph.next(element);
        }
    }

    public selectPointOnHydrograph(element) {
        if (this.selectedRow) {
            if (element.isSelected === this.selectedRow.isSelected) {
                return;
            }
            this.selectedRow.isSelected = false;
            // Asjad : 13629 uncomment below line once data editing enable
            // this.generateDataEditingTable(this.selectedDataEditingData, this.selectedRow);
            this.selectedRow = null;
        }
        if (element) {
            this.viewDataService.selectPointOnHydrograph.next(element);
        }
    }

    /** close About Us popup */
    public emitDataEditingDialog(isSubmitClicked?: boolean) {
        this.statusCodeService.refreshHydrographOnEditWindow.next(false);
        if (isSubmitClicked) {
            this.showResonForEdit = true;
            this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
            this.isStartIntervalIncluded = false;
            this.isEndIntervalIncluded = false;

            this.startDateDataGraph
                .filter(
                    (startDate) => new Date(startDate.value).getTime() === new Date(this.startDateInterval).getTime(),
                )
                .forEach((x) => (this.isStartIntervalIncluded = true));

            this.endDateDataGraph
                .filter((endDate) => new Date(endDate.value).getDate() === new Date(this.endDateInterval).getDate())
                .forEach((x) => (this.isEndIntervalIncluded = true));

            if (!this.isStartIntervalIncluded) {
                return;
            }

            if (!this.isEndIntervalIncluded) {
                return;
            }
        }
        if (this.editedDataArray.length > 0 && !isSubmitClicked) {
            this.conformationDataInput = {
                title: 'INFORMATION',
                message: 'Are you sure you want to cancel your editing changes? All modified points will be lost.',
                okText: 'OK',
                cancelText: 'CANCEL',
            };
            this.showConfirmation = true;
            this.showResonForEdit = false;
            this.hideFilters = false;
            this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
        } else {
            if (!isSubmitClicked) {
                this.viewDataService.isDataEditingOpen.next(false);
                this.viewDataService.closeDataEditingMode.next(false);
            }
            this.viewDataService.selectedTimeStamps.next(null);
            this.viewDataService.selectedScatterPoints.next(null);
            this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
        }
        this.statusCodeService.enableGlobalDropDowns.next(false);
    }

    public dataEditingSubmit(isSubmitClicked?: boolean) {
        if (isSubmitClicked) {
            this.submitTelemetryData();
        }
    }

    /**
     * Represents the Confirmation Action of Confirmation Box
     * @param confirmation -confirmation response
     */
    public listenConfirmation(confirmation: IComponentCustomizedConfirmationResult) {
        if (confirmation.whichButtonPressed === 'ok' && !this.isPreviewClicked) {
            this.showConfirmation = false;
            this.viewDataService.isDataEditingOpen.next(false);
            this.viewDataService.reloadLocationDashboard.next(true);
            this.viewDataService.selectedTimeStamps.next(null);
            this.viewDataService.selectedScatterPoints.next(null);
            this.viewDataService.copiedPoints.next(null);
            this.viewDataService.updateDataTableWithCopiedPoints.next(null);
            this.viewDataService.collectiveFlaggedPoints.next(null);
        } else if (confirmation.whichButtonPressed === 'ok' && this.isPreviewClicked) {
            this.showConfirmation = false;
            this.removeTempEditedPoints();
            this.viewDataService.selectedTimeStamps.next(null);
            this.viewDataService.selectedScatterPoints.next(null);
            this.viewDataService.copiedPoints.next(null);
            this.viewDataService.updateDataTableWithCopiedPoints.next(null);
            this.viewDataService.collectiveFlaggedPoints.next(null);
        } else {
            this.showConfirmation = false;
        }
    }

    public startDateChange(event: MatDatepickerInputEvent<Date>, index: number) {
        if (!event.value) {
            return;
        }
        this.isStartDateValid[index] = !(new Date(event.value).getTime() < new Date(this.startDateInterval).getTime());
        this.isStartDateValidRelativelyED[index] = !(
            this.endDateDataGraph[index].value &&
            new Date(this.endDateDataGraph[index].value).getTime() <
                new Date(this.startDateDataGraph[index].value).getTime()
        );
        this.startDateDataGraph[index] = new FormControl();
        this.startDateDataGraph[index].setValue(event.value);
    }

    public endDateChange(event: MatDatepickerInputEvent<Date>, index: number) {
        if (!event.value) {
            return;
        }
        if (new Date(event.value).getTime() > new Date(this.endDateInterval).getTime()) {
            this.isEndDateValid[index] = false;
        } else {
            this.isEndDateValid[index] = true;
        }
        if (
            this.startDateDataGraph[index].value &&
            new Date(this.startDateDataGraph[index].value).getTime() >
                new Date(this.endDateDataGraph[index].value).getTime()
        ) {
            this.isEndDateValidRelativelyED[index] = false;
        } else {
            this.isEndDateValidRelativelyED[index] = true;
        }
        this.endDateDataGraph[index] = new FormControl();
        this.endDateDataGraph[index].setValue(event.value);
    }

    /** Whether the number of selected elements matches the total number of rows. */
    public isAllSelected() {
        return this.selection.selected.length === this.dataEditingTableDataSource.data.length;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    public masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataEditingTableDataSource.data.forEach((row) => this.selection.select(row));
    }

    public editMultipleData() {
        this.editMultipleDataFlag = true;
    }

    public applyMultipleData() {
        this.editMultipleDataFlag = false;
    }

    public editTableData() {
        this.editdataTableFlag = true;
        this.displayedColumns = ['dateTime', 'entity', 'rawData', 'modifiedValue'];
    }

    public close() {
        this.editdataTableFlag = false;
        this.displayedColumns = ['dateTime', 'entity', 'rawData', 'modifiedValue'];
    }

    public saveEditedData() {
        this.isLoading = true;
        this.isPreviewClicked = true;

        const submitData = [];
        if (this.editedDataArray.length > 0) {
            submitData.push(
                ...this.editedDataArray.map((editedData) => {
                    return {
                        timeStamp: editedData.whichPoint.dateTime,
                        eid: editedData.whichPoint.id,
                        reading: editedData.whichPoint.modifiedValue,
                        reason: this.selectedReason,
                        ignore: editedData.whichPoint.isFlagged,
                    };
                }),
            );
        }

        this.viewDataService.previewLoader.next(true);

        const previewData = {} as DataEditPreviewParams;
        previewData.dataEditType = DataEditType.EntityUpdate;
        previewData.updatePoints = submitData;

        const subscription = this.dataEditService
            .dataEditPreview(this.customerId, this.locationId, previewData)
            .subscribe(
                () => {
                    this.isLoading = false;
                    this.viewDataService.previewLoader.next(false);
                    this.enablePreview = true;
                    this.graphData.enablePreview = this.enablePreview;
                },
                (error) => {
                    this.isLoading = false;
                    this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
                },
            );

        this.subscriptions.push(subscription);
        this.enablePreview = true;
        this.enableAccept = false;
    }

    public showFilters() {
        this.domOperationUtilsService.scrollToTop('#dataEditingContaintId');
        this.showFiltersFlag = !this.showFiltersFlag;
    }

    // On changing start date time event
    public onChangeStartDateTime() {
        this.isDaySpanValid = true;
        this.dateValidation();
    }

    // On changing end date time event
    public onChangeEndDateTime() {
        this.isDaySpanValid = true;
        this.dateValidation();
    }

    // Use to Validate Dates(start/End)
    private dateValidation() {
        this.dayErrorMessage = '';
        if (this.startDate && this.endDate) {
            this.isDaySpanValid = true;
            if (this.startDate > this.endDate) {
                this.isDaySpanValid = false;
                this.dayErrorMessage = this.dateErrMsg;
            }
        }
    }

    public filterData() {
        if (this.advanceGraphData.length > 0 && this.startDate && this.endDate) {
            this.isLoading = true;
            this.advanceGraphData = this.advanceGraphData.filter(
                (m) =>
                    new Date(m.dateTimeUTC) >= new Date(this.startDate) &&
                    new Date(m.dateTimeUTC) <= new Date(this.endDate),
            );
            this.generateDataEditingTable();
        }
    }

    public clearFilter() {
        if (
            this.originalAdvanceGraphData.length === this.advanceGraphData.length &&
            this.selectedDataEditingData === this.advanceGraphData
        ) {
            return;
        } else if (this.originalAdvanceGraphData.length > 0) {
            this.isLoading = true;
            this.selectedDataEditingData.length = 0;
            this.advanceGraphData = this.originalAdvanceGraphData;
            this.isClearEnabled = false;
            setTimeout(() => {
                this.generateDataEditingTable();
            }, 500);
            this.dataEditingTablePaginator.pageIndex = 0;
        }
    }

    public addNewTimeSpan() {
        this.startDateDataGraph[this.numberOfEntries] = new FormControl();
        this.startDateDataGraph[this.numberOfEntries].disable();
        this.endDateDataGraph[this.numberOfEntries] = new FormControl();
        this.endDateDataGraph[this.numberOfEntries].disable();
        this.hideCommentSection[this.numberOfEntries] = true;
        this.reasonsSelector[this.numberOfEntries] = new Array<Selectable>();
        this.isStartDateValid[this.numberOfEntries] = true;
        this.isEndDateValid[this.numberOfEntries] = true;
        this.isStartDateValidRelativelyED[this.numberOfEntries] = true;
        this.isEndDateValidRelativelyED[this.numberOfEntries] = true;
        this.reasons.forEach((entity: DataEditingReasons, index) => {
            this.reasonsSelector[this.numberOfEntries].push({
                name: entity.reason,
                id: entity.id,
            });
        });
        this.numberOfEntries++;
        this.numberOfTimes = Array(+this.numberOfEntries).fill(0);
    }

    public currentlySelectedReasons(reasons: Array<Selectable>, index: number) {
        const filterReasons = reasons.filter((item) => item.isChecked);
        this.isReasonSelected = filterReasons.length > 0 && filterReasons[0].name === other;
        const reasonNameArray = new Array<string>();
        filterReasons.forEach((selectableitem: Selectable) => {
            reasonNameArray.push(selectableitem.name);
            this.selectedReason = selectableitem.name;
        });
        this.hideCommentSection[index] = !(reasonNameArray.indexOf('Other') > -1);
    }

    public otherReasonState(reasonText) {
        this.isReasonSelected = !(reasonText.currentTarget.value.length > 0);
    }

    public handleSubmitButtonState(commentText) {
        this.isReasonSelected = !(commentText.currentTarget.value.length > 0);
    }

    public entitiesSelectionChange($event) {
        this.isEntitiesSelectionChange = true;
    }

    public currentlySelectedEntitiesChange($event) {
        if (!this.isEntitiesSelectionChange) {
            return;
        }
        const selectEntities = this.selectedEntitiesList.value;
        this.displayEntityError = false;
        if (selectEntities.length < 1) {
            this.displayEntityError = true;
            this.entityError = this.entitySelectValidationError;
            return;
        }
        if (this.advanceGraphData.length > 0 && selectEntities.length >= 1) {
            this.isLoading = true;
            this.advanceGraphData = this.advanceGraphData.filter((x) => selectEntities.some((y) => y.id === x.id));
            setTimeout(() => {
                // Asjad : 13629 uncomment below line once data editing enable
                // this.generateDataEditingTable();
            }, 500);
            this.isEntitiesSelectionChange = false;
        }
    }
    /**
     * This function will be invoked when any item will be selected from multi select auto complete field.
     * @param entities -> indicates the list of entities
     */
    public currentlySelectedEntities(entities: Array<Selectable>) {
        const filterEntities = entities.filter((item) => item.isChecked);
        if (filterEntities.length < 1) {
            this.displayEntityError = true;
            this.entityError = this.entitySelectValidationError;
            return;
        }
        this.displayEntityError = false;
        if (this.advanceGraphData.length > 0 && filterEntities.length >= 1) {
            this.isLoading = true;
            this.advanceGraphData = this.advanceGraphData.filter((x) => filterEntities.some((y) => y.id === x.id));
            setTimeout(() => {
                // Asjad : 13629 uncomment below line once data editing enable
                // this.generateDataEditingTable(this.advanceGraphData);
            }, 500);
        }
    }

    public ngOnDestroy(): void {
        if (this.subscriptions) {
            this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        }
    }

    public showPointOnAdvGraph(whichPoint, whichActionToPerform) {
        if (whichPoint) {
            this.selectPointOnHydrograph(whichPoint);
        }
    }

    public getCompactData(whichPoint: DataEditingData) {
        let editedEntityData: string[];
        let editedEntityDataIndex: number;
        let miniEditedEntityData: string[];
        let compactEntityData: string[];
        const selectedEntity = whichPoint.displayGroupId;
        const selectedEntityId = whichPoint.id;
        let updateRawData: string[];
        let updateRawDataStr: string;
        let updatedRawData: string;
        let updatedCompactData: string;

        this.graphData.entityIds.forEach((entityId, index) => {
            if (entityId === selectedEntityId) {
                editedEntityDataIndex = index;
            }
        });
        // tslint:disable-next-line:forin
        for (const prop in this.graphData.compactData) {
            if (Number(prop) * 1000 === whichPoint.dateTime.getTime()) {
                /* Hydrograph Optimization - Get the Response String having the same entity as display group selected */
                // tslint:disable-next-line:max-line-length
                compactEntityData = String(this.graphData.compactData[prop])
                    .slice(1, String(this.graphData.compactData[prop]).length - 1)
                    .split(';');

                // tslint:disable-next-line:max-line-length
                editedEntityData = compactEntityData.filter(
                    (element) => element.split(':')[0] === editedEntityDataIndex.toString(),
                );

                compactEntityData.forEach((data, index) => {
                    if (data === editedEntityData[0]) {
                        if (editedEntityData[0].indexOf('{') !== -1) {
                            miniEditedEntityData = editedEntityData[0].split('{');
                            updateRawData = String(miniEditedEntityData[1]).split(',');
                            updateRawData[0] = String(miniEditedEntityData[0] + '{' + whichPoint.modifiedValue);
                            updatedRawData = updateRawData.join() + ':1';
                            compactEntityData[index] = updatedRawData;
                        } else {
                            miniEditedEntityData = editedEntityData[0].split(':');
                            updateRawDataStr = String(miniEditedEntityData[0] + ':' + whichPoint.modifiedValue);
                            updatedRawData = updateRawDataStr + ':1';
                            compactEntityData[index] = updatedRawData;
                        }
                    }
                });

                updatedCompactData = '[' + compactEntityData.join(';') + ']';
            }
        }

        return updatedCompactData;
    }

    public submitTelemetryData() {
        this.viewDataService.previewLoader.next(true);

        let submitData = [];
        if (this.editedDataArray.length > 0) {
            submitData = this.editedDataArray.map((editedData) => {
                return {
                    timeStamp: editedData.whichPoint.dateTime,
                    eid: editedData.whichPoint.id,
                    reading: editedData.whichPoint.modifiedValue,
                    reason: this.selectedReason,
                    ignore: editedData.whichPoint.isFlagged,
                };
            });
        } else if (this.viewDataService.updateDataTableWithCopiedPoints.getValue()) {
            const data = this.viewDataService.updateDataTableWithCopiedPoints.getValue();
            if (data && data['points'] && data['points'].length > 0) {
                submitData = data['points'].map((element) => {
                    return {
                        timeStamp: element.dateTimeUTC,
                        eid: element['id'],
                        reading: element.y,
                        reason: this.selectedReason,
                        ignore: false,
                    };
                });
            }
        }

        if (submitData.length > 0 && submitData[0].reason === other) {
            submitData[0].reason = this.otherReason;
        }

        const subscription = this.dataEditService
            .dataEditSubmit(this.customerId, this.locationId, this.selectedReason)
            .subscribe(
                () => {
                    this.viewDataService.previewLoader.next(false);
                    this.viewDataService.isDataEditingOpen.next(false);
                    this.getHydrographParams.start = new Date(new Date(this.startDate).setHours(0, 0, 0));
                    this.viewDataService.dataEditingSubmitOnHydrograph.next(this.getHydrographParams);
                    this.viewDataService.enableAccept.next(true);
                    this.viewDataService.collectiveFlaggedPoints.next(null);
                    this.viewDataService.flaggedPoints.next(null);
                    this.viewDataService.unFlaggedPoints.next(null);
                    this.viewDataService.copiedPoints.next(null);
                    this.viewDataService.updateDataTableWithCopiedPoints.next(null);
                },
                (error) => {
                    this.isLoading = false;
                    this.viewDataService.previewLoader.next(false);
                    this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
                },
            );
        this.subscriptions.push(subscription);
    }

    public removeTempEditedPoints() {
        this.viewDataService.previewLoader.next(true);
        this.statusCodeService.clearFlaggedPointsLable.next(true);

        let removeData = [];
        if (this.editedDataArray.length > 0) {
            removeData = this.editedDataArray.map((editedData) => {
                return {
                    timeStamp: editedData.whichPoint.dateTime,
                    eid: editedData.whichPoint.id,
                    reading: editedData.whichPoint.modifiedValue,
                    reason: this.selectedReason,
                };
            });
        }

        const subscription = this.viewDataService
            .DataEditRemove(this.customerId, this.locationId, removeData)
            .subscribe(
                () => {
                    this.viewDataService.previewLoader.next(false);
                    this.viewDataService.isDataEditingOpen.next(false);
                    this.getHydrographParams.start = new Date(new Date(this.startDate).setHours(0, 0, 0));
                    this.viewDataService.dataEditingSubmitOnHydrograph.next(this.getHydrographParams);
                    this.viewDataService.enableAccept.next(true);
                },
                (error) => {
                    this.isLoading = false;
                    this.viewDataService.previewLoader.next(false);
                    this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
                },
            );
        this.subscriptions.push(subscription);
    }

    public numberOnly(val): boolean {
        const charCode = val.which ? val.which : val.keyCode;
        return !(charCode === CHAR_E);
    }

    public retainFlaggedPointOnGrid() {
        // logic for retaining the value of flagged Data when the user zoom on hydrograph
        const collectedPoints = this.viewDataService.collectiveFlaggedPoints.getValue();
        if (collectedPoints && collectedPoints.length > 0) {
            collectedPoints.forEach((point) => {
                point['points'].forEach((element) => {
                    this.dataEditingTableData
                        .filter((x) => x.id === point['id'] && x.dateTime.getTime() === new Date(element).getTime())
                        .forEach((x) => (x.isFlagged = true));
                });
            });
        }
    }

    public updateDataTableWithCopiedPoints(copiedPoints) {
        if (copiedPoints && copiedPoints['points'] && copiedPoints['points'].length > 0) {
            const isNewPointsCreated = copiedPoints['points'].some((x) => x['pointExists'] === false);
            // handle for flow entities copy/paste to qfinal and return
            if (copiedPoints['id'] === Q_FINAL_ENTITY) {
                this.startDateInterval = copiedPoints['startDate'];
                this.endDateInterval = copiedPoints['endDate'];
                this.startDateDataGraph[0].setValue(this.startDateInterval);
                this.endDateDataGraph[0].setValue(this.endDateInterval);
                copiedPoints['points'].forEach((element) => {
                    const editData = {
                        dateTime: element.dateTimeUTC,
                        dateTimeUTC: new Date(
                            element.dateTimeUTC.getTime() + element.dateTimeUTC.getTimezoneOffset() * 60000,
                        ),
                        rawData: element.y,
                        suggestedData: '',
                        modifiedValue: element.y,
                        displayGroupId: element.displayGroup,
                        id: copiedPoints['id'],
                        entity: element['entityName'],
                    };
                    this.editedDataArray.push({
                        whichPoint: editData,
                        originalModifiedValue: editData.modifiedValue,
                        pointExists: true,
                        dateTime: element.dateTimeUTC,
                    });
                    if (this.entitiesEdited.indexOf(element.entityName) < 0) {
                        this.entitiesEdited.push(element.entityName);
                    }
                });
                this.enablePreview = true;
                this.isPreviewClicked = true;
                this.enableAccept = false;
                this.isCopyPasteOperationEnabled = true;
                this.changeDetectorRef.detectChanges();
                return;
            } else if (isNewPointsCreated) {
                // handling for newly created points
                this.startDateInterval = copiedPoints['startDate'];
                this.endDateInterval = copiedPoints['endDate'];
                this.startDateDataGraph[0].setValue(this.startDateInterval);
                this.endDateDataGraph[0].setValue(this.endDateInterval);
                copiedPoints['points'].forEach((element) => {
                    const editData = {
                        dateTime: element.dateTimeUTC,
                        dateTimeUTC: new Date(
                            element.dateTimeUTC.getTime() + element.dateTimeUTC.getTimezoneOffset() * 60000,
                        ),
                        rawData: element.y,
                        suggestedData: '',
                        modifiedValue: element.y,
                        displayGroupId: element.displayGroup,
                        id: copiedPoints['id'],
                        entity: element['entityName'],
                    };
                    if (!element['pointExists']) {
                        this.advanceGraphData.push(editData);
                    }
                    this.editedDataArray.push({
                        whichPoint: editData,
                        originalModifiedValue: editData.modifiedValue,
                        pointExists: true,
                        dateTime: element.dateTimeUTC,
                    });
                    if (this.entitiesEdited.indexOf(element.entityName) < 0) {
                        this.entitiesEdited.push(element.entityName);
                    }
                });
                // Asjad : 13629 uncomment below line once data editing enable
                // this.generateDataEditingTable(this.advanceGraphData);
                this.enablePreview = true;
                this.isPreviewClicked = true;
                this.enableAccept = false;
                this.isCopyPasteOperationEnabled = true;
                this.changeDetectorRef.detectChanges();
                return;
            }
            // for editable entities unidepth, velocity, rain
            this.dataEditingTableData
                .filter((x) => x.id === copiedPoints['id'])
                .forEach((x) => {
                    copiedPoints['points']
                        .filter((element) => element.x === x.dateTime.getTime())
                        .forEach((element) => {
                            x.modifiedValue = element.y;
                            this.editedDataArray.push({
                                whichPoint: x,
                                originalModifiedValue: x.modifiedValue,
                                pointExists: true,
                                dateTime: element.dateTimeUTC,
                            });
                            if (this.entitiesEdited.indexOf(x.entity) < 0) {
                                this.entitiesEdited.push(x.entity);
                            }
                        });
                });
            if (
                this.editedDataArray &&
                this.editedDataArray.length > 0 &&
                copiedPoints['points'] &&
                copiedPoints['points'].length > 0
            ) {
                this.startDateInterval = copiedPoints['startDate'];
                this.endDateInterval = copiedPoints['endDate'];
                this.startDateDataGraph[0].setValue(this.startDateInterval);
                this.endDateDataGraph[0].setValue(this.endDateInterval);
                this.enablePreview = true;
                this.isPreviewClicked = true;
                this.enableAccept = false;
            }
            // this.setPagination(this.totalPaginationLength);
            this.isCopyPasteOperationEnabled = true;
            this.changeDetectorRef.detectChanges();
        }
    }

    public getDataEditing(customerId: number, params: DataEditingParams) {
        if (this.isRequestProcessing) {
            return;
        }
        this.isLoading = true;
        this.isRequestProcessing = true;
        this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
        this.viewDataService.fetchDataEditingPoints(customerId, params).subscribe(
            (data) => {
                if (data && data.pageData && data.pageData.length > 0) {
                    const dataEditingItems: Array<DataEditingData> = data.pageData.map((item) => {
                        const dataEditingItem: DataEditingData = {
                            dateTime: item.dt,
                            dateTimeUTC: new Date(item.dt),
                            timeStamp: item.ts,
                            entity: String(data.entities[item.id]),
                            rawData: item.r,
                            id: item.id,
                        };

                        return dataEditingItem;
                    });
                    this.isRequestProcessing = false;
                    this.setPagination(data.count);
                    this.generateDataEditingTable(dataEditingItems);
                    this.displayHydroGraph();
                    this.isLoading = false;
                    this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
                }
            },
            (error) => {
                this.isRequestProcessing = false;
                this.isLoading = false;
                this.uiUtilService.safeChangeDetection(this.changeDetectorRef);
            },
        );
    }

    public onPageChange(event) {
        this.getDataEditing(this.customerId, <DataEditingParams>{
            start: this.getHydrographParams.start,
            end: this.getHydrographParams.end,
            eids: this.getHydrographParams.entityIds,
            locationId: this.getHydrographParams.locationId,
            pageSize: event.pageSize,
            startPage: event.pageIndex + 1,
        });
    }
}
