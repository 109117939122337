<div class="user-idle" id="userIdelDialog">
    <!-- <app-loader [isLoading]="isLoading"></app-loader> -->
    <!-- add graph header -->
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'TOP_NAV.IDLE_WINDOW.STILL_THERE' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <!-- content -->
    <div mat-dialog-content>
        <span>{{ 'TOP_NAV.IDLE_WINDOW.REASON' | translate }}</span>
        <span> {{ counter }} </span>
        <span>{{ 'COMMON.SECONDS' | translate }}</span>
    </div>
    <div mat-dialog-actions>
        <div class="auto-margin">
            <button mat-button (click)="logOut()">{{ 'TOP_NAV.IDLE_WINDOW.LOGOUT' | translate }}</button>
        </div>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="close()">{{ 'TOP_NAV.IDLE_WINDOW.LOGIN' | translate }}</button>
        </div>
    </div>
</div>
