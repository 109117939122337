<div class="timeline-container">
    <div class="timeline-title">{{ 'SLIICER.EDIT_SETTINGS.REGIMES.REGIME_TIMELINE' | translate }}</div>
    <mat-card class="timeline" [ngClass]="{ empty: timelineElements.length == 0 }">
        <div class="timeline-element" *ngIf="timelineElements.length == 0">
            {{ 'SLIICER.EDIT_SETTINGS.REGIMES.NO_REGIMES_DEFINED' | translate }}
        </div>
        <ng-container>
            <div
                class="timeline-element"
                *ngFor="let timelineElement of timelineElements; let i = index"
                [ngStyle]="{ width: timelineElement.size + '%', 'max-width': timelineElement.size + '%' }"
            >
                <span [matTooltip]="timelineElement.name">
                    {{ timelineElement.name || ('SLIICER.EDIT_SETTINGS.REGIMES.REGIME_NO_NAME' | translate) }}
                </span>
                <div class="timeline-date" *ngIf="i < timelineElements.length - 1">
                    {{ timelineElement.periodEnd | date: dateFormat }}
                </div>
            </div>
        </ng-container>
    </mat-card>
    <div class="timeline-labels">
        <div class="timeline-label">{{ caseStudyStartDate | date: dateFormat }}</div>
        <div class="timeline-label">{{ caseStudyEndDate | date: dateFormat }}</div>
    </div>
</div>
<div class="regimes-container">
    <div class="regimes-element">
        <div class="regimes-title">{{ 'SLIICER.EDIT_SETTINGS.REGIMES.REGIMES_TITLE' | translate }}</div>
        <div class="regimes">
            <ng-container *ngIf="regimes.length > 0">
                <form novalidate #regimesForm="ngForm">
                    <div class="regime-holder" *ngFor="let regime of regimes; let i = index">
                        <div class="regime">
                            <div class="regime-field">
                                <div class="custom-error">
                                    <mat-form-field
                                    [disabled]="isStudyLocked"
                                        class="custom-error"
                                        [ngClass]="{ 'mat-form-field-invalid': errors[i + '.name'] }"
                                    >
                                        <input
                                            matInput
                                            [disabled]="isStudyLocked"
                                            matTooltip="{{ 'COMMON.ADD_REGIME_NAME_TOOLTIP' | translate }}"
                                            [placeholder]="'SLIICER.EDIT_SETTINGS.REGIMES.REGIME_NAME' | translate"
                                            [(ngModel)]="regime.name"
                                            name="{{ 'name' + i }}"
                                            maxlength="24"
                                            (ngModelChange)="regimeNameChange(i)"
                                            [debounce]="debounceData"
                                            (debounced)="onDebounce()"
                                            required
                                        />
                                    </mat-form-field>
                                    <div class="custom-error-message" *ngIf="errors[i + '.name']">
                                        {{ errors[i + '.name'] | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="regime-field">
                                {{ 'SLIICER.COMMON.START' | translate }}: {{ regime.periodStart | date: dateFormat }}
                            </div>
                            <div class="regime-field">
                                <div class="custom-error">
                                    <mat-form-field [ngClass]="{ 'mat-form-field-invalid': errors[i + '.periodEnd'] }">
                                        <input
                                            [disabled]="isStudyLocked"
                                            matTooltip="{{ 'COMMON.REGIME_END_DATE_TOOLTIP' | translate }}"
                                            matInput
                                            [(ngModel)]="regime.periodEnd"
                                            (ngModelChange)="regimePeriodEndChange(i)"
                                            [debounce]="debounceData"
                                            name="{{ 'endDate' + i }}"
                                            (debounced)="onDebounce()"
                                            [matDatepicker]="startDate"
                                            [min]="regime.minPeriodStart ? regime.minPeriodStart : regime.periodStart"
                                            [max]="
                                                i < regimes.length - 1
                                                ? regime.periodEnd || regimes[i + 1].periodStart
                                                : caseStudyEndDate
                                            "
                                            [placeholder]="'SLIICER.EDIT_SETTINGS.REGIMES.REGIME_END_DATE' | translate"
                                            required
                                        />
                                        <mat-datepicker-toggle matSuffix [for]="startDate"> </mat-datepicker-toggle>
                                        <mat-datepicker #startDate></mat-datepicker>
                                    </mat-form-field>
                                    <div class="custom-error-message" *ngIf="errors[i + '.periodEnd']">
                                        {{ errors[i + '.periodEnd'] | translate }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <mat-icon [ngClass]="{'sliicer-regime-delete' : isStudyLocked }" [disabled]="isStudyLocked" matTooltip="{{ 'COMMON.DELETE_REGIME' | translate }}" (click)="removeRegime(i)">close</mat-icon>
                    </div>
                </form>
            </ng-container>
            <div class="regime-holder" *ngIf="regimes.length == 0">
                <div class="regime">
                    <div class="regime-field">
                        {{ 'SLIICER.COMMON.DEFAULT' | translate }}
                    </div>
                    <div class="regime-field">
                        {{ 'SLIICER.COMMON.START' | translate }}: {{ caseStudyStartDate | date: dateFormat }}
                    </div>
                    <div class="regime-field">
                        {{ 'SLIICER.COMMON.END' | translate }}: {{ caseStudyEndDate | date: dateFormat }}
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <div class="add">
                <button mat-fab matTooltip="{{ 'COMMON.ADD_NEW_REGIME' | translate }}" [disabled]="isStudyLocked" (click)="addRegime()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
        </div>

        <div class="error" *ngIf="error">
            <div *ngIf="errorType.regimeComplete">{{ 'COMMON.REGIME_INCOMPLETE_DEF'  | translate }}</div>
        </div>
    </div>


</div>
