import { Pipe, PipeTransform } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Pipe({
    name: 'customErrorCount',
    pure: false,
})
export class ErrorCountPipe implements PipeTransform {
    // Accepts formgroup object and returns the count of formcontrols required
    public transform(form: FormGroup): number {
        if (!form || form.valid) return 0;

        return Object.keys(form.controls).reduce((acc, curr) => {
            const control = form.get(curr);

            if (control instanceof FormControl) {
                return control.errors && control.errors.required ? acc + 1 : acc;
            }

            if (control instanceof FormGroup) {
                return (acc += this.transform(control));
            }

            if (control instanceof FormArray) {
                return (acc += control.controls.reduce((acc, curr) => (acc += this.transform(curr as FormGroup)), 0));
            }
        }, 0);
    }
}
