import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Config } from './config';

import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CompositeLocComponent } from '../components/map/composite-loc/composite-loc.component';
import { Observable, of } from 'rxjs';
import { MarkerLocationDetailsComponent } from '../components/map/marker-location-details/marker-location-details.component';
import { LocationDetails } from '../models/location-details';
import { TritonLocationDialogComponent } from '../components/location-card/components/triton-location-dialog/triton-location-dialog.component';
import { tap } from 'rxjs/operators';

@Injectable()
export class MapService {
    public addEditLocationDialog: MatDialogRef<TritonLocationDialogComponent, any>;
    public addEditCompositeDialog: MatDialogRef<CompositeLocComponent>;
    public addEditMarkerDialog: MatDialogRef<MarkerLocationDetailsComponent>;

    public isMapLocationEdit = false;
    public isSaveOnly = false;

    constructor(public http: HttpClient, public dialog: MatDialog) {}

    public getMarkerLocationDetails(locationId: number, customerId: number) {
        return this.http.get<LocationDetails>(Config.urls.markerLocationDetails + customerId + '/' + locationId);
    }

    public closeDialog() {
        if (this.addEditLocationDialog) this.addEditLocationDialog.close();
        if (this.addEditCompositeDialog) this.addEditCompositeDialog.close();
        if (this.addEditMarkerDialog) this.addEditMarkerDialog.close();
    }

    public openEditCompositeDialog(dialogOptions: MatDialogConfig, close = false): Observable<any> {
        if (close) this.closeDialog();

        this.addEditCompositeDialog = this.dialog.open(CompositeLocComponent, dialogOptions);
        return this.addEditCompositeDialog.afterClosed();
    }

    public openEditMarkerDialog(dialogOptions: MatDialogConfig, close = false): Observable<any> {
        if (close) this.closeDialog();

        this.addEditMarkerDialog = this.dialog.open(MarkerLocationDetailsComponent, dialogOptions);
        return this.addEditMarkerDialog.afterClosed();
    }
}
