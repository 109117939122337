<div>
    <div id="confirmation-dialog">
        <div class="popupHeader" mat-dialog-title>
            <span>{{ 'COMMON.SAVECONFIG' | translate }}</span>
            <span class="app-flex-filler"></span>
        </div>
        <div mat-dialog-content>
            <p>{{ 'FLOW_MONITORING.CONFIGURATIONS.NAME_CONFIGURATION' | translate }}</p>
            <mat-form-field class="eachItem" class="topPadding">
                <input
                    matInput
                    type="text"
                    maxlength="256"
                    [(ngModel)]="name"
                />
            </mat-form-field>
        </div>
        <div mat-dialog-actions>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button type="submit" (click)="onCancel()">{{ 'COMMON.CANCEL' | translate }}</button>
            </div>
            <div class="auto-margin">
                <button mat-button type="submit" (click)="onSave()">{{ 'COMMON.SAVE' | translate }}</button>
            </div>
        </div>
    </div>
</div>
