import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { VautImportFileSummary } from 'app/shared/models/telemetry';

@Component({
    selector: 'app-vault-import-file-summary',
    templateUrl: './vault-import-file-summary.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VaultImportFileSummaryComponent {
    @Input() public file: VautImportFileSummary;
    constructor() {}
}
