<div id="vaultTelemetryExportDialog" class="modal-dialog vault-telemetry-export">
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'VAULT.VAULT_TELEMETRY.EXPORT.TITLE' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <form (ngSubmit)="onExportSubmit($event)" #vaultTelemetryExportForm="ngForm" novalidate>
        <app-loader [isLoading]="isLoading"></app-loader>
        <mat-dialog-content>
            <div class="file-upload-dialog">
                <div class="top custom-error">
                    <app-multi-select-auto-complete
                        #multiSelectAutoCompleteLocations
                        [items]="locations"
                        [preselectedItems]="selectedLocations"
                        (currentlySelectedItems)="handleSelectedLocations($event)"
                        placeholder="{{ 'COMMON.LOCATIONS' | translate }} *"
                    ></app-multi-select-auto-complete>
                    <div class="custom-error-message" *ngIf="locationsInvalid()">
                        <span>{{ locationsValidationMessage }}</span>
                    </div>
                </div>
                <div class="custom-error">
                    <app-multi-select-group
                        #entitiesGroupMultiselect
                        [items]="entities"
                        [preselectedItems]="selectedEntities"
                        [isShowAll]="true"
                        (currentlySelectedItems)="handleSelectedEntities($event)"
                        placeholder="{{ 'COMMON.ENTITIES_TEXT' | translate }} *"
                    >
                    </app-multi-select-group>

                    <div class="custom-error-message" *ngIf="isEntitiesInvalid">
                        <span>{{ entitiesValidationMessage }}</span>
                    </div>
                </div>
                <p *ngIf="false">
                    <span class="validation-error" *ngIf="datesValidationMessage">{{ datesValidationMessage }}</span>
                </p>
                <div class="full-width">
                    <mat-form-field>
                        <input
                            matInput
                            readonly
                            placeholder="{{ 'COMMON.START_DATE' | translate }}"
                            name="startDate"
                            [(ngModel)]="startDate"
                            [matDatetimepicker]="dtStartDate"
                            (dateTimeChange)="onChangeStartDateTime()"
                            appDatetimepickerPosition
                            [matDatetimepickerComp]="dtStartDate"
                        />
                        <mat-datetimepicker-toggle matSuffix [for]="dtStartDate"></mat-datetimepicker-toggle>
                        <mat-datetimepicker #dtStartDate timeInterval="1" type="datetime"></mat-datetimepicker>
                    </mat-form-field>
                </div>
                <div class="full-width">
                    <mat-form-field>
                        <input
                            matInput
                            readonly
                            placeholder="{{ 'COMMON.END_DATE' | translate }}"
                            name="endDate"
                            [(ngModel)]="endDate"
                            [matDatetimepicker]="dtEndDate"
                            (dateTimeChange)="onChangeEndDateTime()"
                            appDatetimepickerPosition
                            [matDatetimepickerComp]="dtEndDate"
                        />
                        <mat-datetimepicker-toggle matSuffix [for]="dtEndDate"></mat-datetimepicker-toggle>
                        <mat-datetimepicker #dtEndDate timeInterval="1" type="datetime"></mat-datetimepicker>
                    </mat-form-field>
                </div>
                <div class="mat-error" *ngIf="!isDaySpanValid">
                    {{ datesValidationMessage }}
                </div>
                <div class="custom-error" [ngClass]="{ 'pad-b-10': isDateInvalid && invalidDateRange }" *ngIf="false">
                    <div
                        class="flex-container-space-between date-range-section"
                        style="flex-direction: column; display: flex"
                    >
                        <div class="eachDateSection" style="flex-direction: row; display: flex; width: 100%">
                            <mat-form-field style="flex: 1; margin-right: 40px">
                                <input
                                    matInput
                                    disabled
                                    [(ngModel)]="startDate"
                                    name="startDate"
                                    #startDte="ngModel"
                                    [matDatetimepicker]="startDatePicker"
                                    [max]="maxDate"
                                    placeholder="{{ 'COMMON.START_DATE' | translate }}"
                                    (ngModelChange)="onDateChanged()"
                                    required
                                    appDatetimepickerPosition
                                    [matDatetimepickerComp]="startDatePicker"
                                />
                                <mat-datetimepicker-toggle
                                    matSuffix
                                    [for]="startDatePicker"
                                ></mat-datetimepicker-toggle>
                                <mat-datetimepicker
                                    #startDatePicker
                                    disabled="false"
                                    timeInterval="1"
                                    type="datetime"
                                ></mat-datetimepicker>
                            </mat-form-field>
                            <!-- <app-timepicker #timepickerStartTime [timeFormat]="timeFormat" (notifyTimeChange)="onNotifyTimeChange('start', $event)"></app-timepicker> -->
                        </div>
                        <div class="eachDateSection" style="flex-direction: row; display: flex; width: 100%">
                            <mat-form-field style="flex: 1; margin-right: 40px">
                                <input
                                    matInput
                                    disabled
                                    [(ngModel)]="endDate"
                                    name="endDate"
                                    [matDatetimepicker]="endDatePicker"
                                    #endDte="ngModel"
                                    [max]="maxDate"
                                    placeholder="{{ 'COMMON.END_DATE' | translate }}"
                                    (ngModelChange)="onDateChanged()"
                                    required
                                    appDatetimepickerPosition
                                    [matDatetimepickerComp]="endDatePicker"
                                />
                                <mat-datetimepicker-toggle matSuffix [for]="endDatePicker"></mat-datetimepicker-toggle>
                                <mat-datetimepicker
                                    #endDatePicker
                                    disabled="false"
                                    timeInterval="1"
                                    type="datetime"
                                ></mat-datetimepicker>
                            </mat-form-field>
                            <!-- <app-timepicker #timepickerEndTime [timeFormat]="timeFormat" (notifyTimeChange)="onNotifyTimeChange('end', $event)"></app-timepicker> -->
                        </div>
                    </div>
                    <div class="custom-error-message">
                        <span *ngIf="isDateInvalid">{{ 'COMMON.DATE_ERROR' | translate }}</span>
                        <span *ngIf="invalidDateRange">{{ 'COMMON.START_END_DATE_DIFF_ERROR' | translate }}</span>
                    </div>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button mat-dialog-close>{{ 'COMMON.CLOSE' | translate }}</button>
            </div>
            <div class="auto-margin">
                <button type="submit" mat-button [disabled]="checkTelemetryExportValidity() || !isDaySpanValid">
                    {{ 'COMMON.EXPORT_TEXT' | translate }}
                </button>
            </div>
        </mat-dialog-actions>
    </form>
</div>
