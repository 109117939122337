import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';

import { Config } from 'app/shared/services/config';
import { HttpClient } from '@angular/common/http';
import { Units, ApiResponse, EntityUnit, DisplayGroupModel } from '../models/units';
import { CalculatedBaseEntity } from 'app/pages/view-data/calculated-entity/calculated-entity.model';
import { map } from 'rxjs/operators';

@Injectable()
export class CalculatedEntitiesService {
    /**
     * Represents a placeholder for subscriptions.
     */
    private subscriptions = new Array<Subscription>();

    constructor(private http: HttpClient) {}

    public getUnits(): Observable<Array<Units>> {
        const getAllUnits = Config.getUrl(Config.urls.getUnits);
        return this.http
            .get<ApiResponse<Array<Units>>>(getAllUnits)
            .pipe(map((response: ApiResponse<Array<Units>>) => response.payload));
    }

    public getPreDefinedEntities(customerID: number): Observable<Array<EntityUnit>> {
        const getEntitiesExceptCE = Config.getUrl(Config.urls.getPreDefinedEntitiesOnly);
        return this.http
            .get<ApiResponse<Array<EntityUnit>>>(`${getEntitiesExceptCE}?cid=${customerID}`)
            .pipe(map((response: ApiResponse<Array<EntityUnit>>) => response.payload));
    }

    public getDisplayGroupsByUnitId(unitId: number): Observable<Array<DisplayGroupModel>> {
        const getDisplayGroups = Config.getUrl(Config.urls.getDisplayGroups);
        return this.http
            .get<ApiResponse<Array<DisplayGroupModel>>>(getDisplayGroups + `?unitId=${unitId}`)
            .pipe(map((response: ApiResponse<Array<DisplayGroupModel>>) => response.payload));
    }

    public validateEquation(equation: string, equationVariable: string[]): Observable<ApiResponse<any>> {
        // Setting the Parameters.
        const params = {
            expression: equation,
            variables: equationVariable,
        };

        const parseEquationURI = Config.getUrl(Config.urls.parseEquation);
        return this.http
            .post<ApiResponse<any>>(parseEquationURI, params)
            .pipe(map((response: ApiResponse<any>) => response));
    }

    public saveCalculatedEntityWithEquation(
        validatedEntity: CalculatedBaseEntity,
        customerId: number,
        locationId: number,
    ): Observable<ApiResponse<any>> {
        const parseEquationURI = Config.getUrl(
            Config.urls.createCalculatedEntity + `?cid=${customerId}` + `&lid=${locationId}`,
        );
        return this.http
            .post<ApiResponse<any>>(parseEquationURI, validatedEntity)
            .pipe(map((response: ApiResponse<any>) => response));
    }

    public getCalculatedEntitiesDetails(
        cid: number,
        lid: number,
        startPage?: number,
        pageSize?: number,
        serachValue?: string,
    ): Observable<Array<any>> {
        const url = '&PageSize=' + pageSize + '&StartPage=' + startPage;
        let postFixUrl;
        serachValue ? (postFixUrl = '&SearchValue=' + serachValue) : (postFixUrl = '');
        const getAllcalculatedEntities = Config.getUrl(Config.urls.getCalculatedEntities);
        return this.http
            .get<any>(getAllcalculatedEntities + `?cid=${cid}` + `&lid=${lid}` + url + postFixUrl)
            .pipe(map((response: ApiResponse<Array<any>>) => response.payload));
    }

    public getCalculatedEntitiesById(ceid: number, cid: number, lid: number): Observable<any> {
        const getcalculatedEntityById = Config.getUrl(Config.urls.getCalculatedEntityById);
        return this.http
            .get<any>(getcalculatedEntityById + `?ceid=${ceid}` + `&cid=${cid}` + `&lid=${lid}`)
            .pipe(map((response: ApiResponse<any>) => response.payload));
    }

    public updateCalculatedEntity(
        validatedEntity: CalculatedBaseEntity,
        customerId: number,
        locationId: number,
    ): Observable<any> {
        const updatecalculatedBaseURI = Config.getUrl(Config.urls.updateCalculatedEntity);
        const updateCalcEntityURI = updatecalculatedBaseURI + `?cid=${customerId}` + `&lid=${locationId}`;
        return this.http
            .put<ApiResponse<any>>(updateCalcEntityURI, validatedEntity)
            .pipe(map((response: ApiResponse<any>) => response));
    }

    public mapEntityToLocation(ceid: number, cid: number, lid: number, newlid: number): Observable<any> {
        const mapcalculatedEntityByLocationId = Config.getUrl(Config.urls.mapCalculatedEntityByLocation);
        return this.http
            .put<any>(
                mapcalculatedEntityByLocationId + `?ceid=${ceid}` + `&cid=${cid}` + `&lid=${lid}` + `&newlid=${newlid}`,
                null,
            )
            .pipe(map((response: ApiResponse<any>) => response.payload));
    }

    public deleteCalculatedEntity(
        calculatedEntityId: number,
        locationId: number,
        customerId: number,
    ): Observable<boolean> {
        return this.http.delete<boolean>(
            Config.getUrl(Config.urls.deleteCalculatedEntity) +
                `?ceid=${calculatedEntityId}` +
                `&lid=${locationId}` +
                `&cid=${customerId}`,
        );
    }
}
