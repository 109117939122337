import { Directive, ElementRef, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { MatDatetimepickerComponent } from '@mat-datetimepicker/core';
import { Subscription } from 'rxjs';

@Directive({
	selector: '[appDatetimepickerPosition]'
})
export class DatetimepickerPositionDirective implements AfterViewInit, OnDestroy {
	@Input('matDatetimepickerComp') datetimepicker: MatDatetimepickerComponent<any>;
	private subscription: Subscription;

	constructor(private elementRef: ElementRef) { }

	ngAfterViewInit() {
		if (this.datetimepicker) {
			this.subscription = this.datetimepicker.openedStream.subscribe(() => {
				this.hideOverlay();
				setTimeout(() => {
					this.setPosition();
				}, 0);
			});
		}
	}

	private hideOverlay() {
		const overlayPane: HTMLDivElement = document.querySelector('.cdk-overlay-pane.mat-datetimepicker-popup');
		if (overlayPane) {
			overlayPane.style.visibility = 'hidden';
		}
	}

	private setPosition() {
		const rect = this.elementRef.nativeElement.getBoundingClientRect();
		const overlayPane: HTMLDivElement = document.querySelector('.cdk-overlay-pane.mat-datetimepicker-popup');
		if (overlayPane) {
			const overlayRect = overlayPane.getBoundingClientRect();
			const viewportWidth = window.innerWidth;
			const viewportHeight = window.innerHeight;

			let top = rect.bottom;
			let left = rect.left;

			if (top + overlayRect.height > viewportHeight) {
				top = rect.top - overlayRect.height;
			}

			if (left + overlayRect.width > viewportWidth) {
				left = viewportWidth - overlayRect.width;
			}

			top = Math.max(0, top);
			left = Math.max(0, left);

			overlayPane.style.visibility = 'hidden';
			overlayPane.setAttribute('style', `top: ${top}px !important; left: ${left}px !important;`);

			setTimeout(() => {
				overlayPane.style.visibility = 'visible';
			}, 0);
		}
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}
}
