import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from './config';
import Any = jasmine.Any;

import { ConfirmationPoint, ConfirmationPointCreateResponse, ConfirmationReadingResponse } from 'app/shared/models/confirmation-points';
import { Observable } from 'rxjs';
import { ConfirmationReportGetParameters, ConfirmationReportResponse, ConfirmationReportResponseRowMonitorReading, ConfirmationReportUIFormatted, ConfirmationReportUIRow, ConfirmationReportUIRowDetails } from '../models/location-details';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class ConfirmationService {
    constructor(public http: HttpClient) {}

    public index = (params) => {
        return this.http.get<any>(
            `${Config.serviceUrl}${Config.urls.confirmation}?cid=${params.cid}&lid=${params.lid}&PageSize=${params.pageSize}&StartPage=${params.startPage}`,
        );
    };

    public create(params: {cid: string, lid: string}, data: ConfirmationPoint): Observable<ConfirmationPointCreateResponse> {
        return this.http.post<ConfirmationPointCreateResponse>(
            `${Config.serviceUrl}${Config.urls.confirmation}?cid=${params.cid}&lid=${params.lid}`,
            data,
        );
    };

    public delete = (id) => {
        return this.http.delete<any>(`${Config.serviceUrl}${Config.urls.confirmation}/?id=${id}`);
    };

    public update = (data) => {
        return this.http.put<any>(`${Config.serviceUrl}${Config.urls.confirmation}`, data);
    };

    public calculateFlow = ({ cid, lid, depth, velocity, silt, averageToPeak }) => {
        return this.http.post(
            `${Config.serviceUrl}${Config.urls.confirmation}/Calculate/?cid=${cid}&lid=${lid}&depth=${depth}&velocity=${velocity}&silt=${silt}&averageToPeak=${averageToPeak}`,
            {},
        );
    };

    public getConfirmationReport(customerId: number, body: ConfirmationReportGetParameters): Observable<ConfirmationReportResponse> {
        return this.http.post<ConfirmationReportResponse>(`${Config.serviceUrl}${Config.urls.confirmationReport}/?cid=${customerId}`, body);
    }

    public generateConfirmationReport(customerId: number, reportQuery: ConfirmationReportGetParameters): Observable<string> {
        return this.http.post(`${Config.urls.confirmationExport}/?cid=${customerId}`, reportQuery, {
            responseType: 'text',
        });
    }


    public getConfirmationATP(customerId: number, lid: number): Observable<number> {
        return this.http.get<number>(`${Config.serviceUrl}${Config.urls.confirmationATP}/?cid=${customerId}&lid=${lid}`);
    }
    public getConfirmationReadings(customerId: number, locationId: string, datetime: string,
        depth: number, velocity: number, silt: number, averageToPeak: number
        ): Observable<ConfirmationReadingResponse> {
            let paramUrl = `?cid=${customerId}&lid=${locationId}&dateTime=${decodeURI(datetime)}`;
            paramUrl += `&depth=${depth}`;
            paramUrl += `&velocity=${velocity}`;
            paramUrl += `&silt=${silt}`;
            paramUrl += `&averageToPeak=${averageToPeak}`;

            return this.http.post<ConfirmationReadingResponse>(`${Config.serviceUrl}${Config.urls.confirmationReadings}/${paramUrl}`, {});
        }


    public getFormattedConfirmationReport(customerId: number, body: ConfirmationReportGetParameters,
        translations: {before: string, after: string, closest: string; avg: string}): Observable<ConfirmationReportUIFormatted> {
        return this.getConfirmationReport(customerId, body).pipe(
            map((res) => {
                const confirmationAvgAndUnits = {
                    dp: res.depthPrecision,
                    du: res.depthUnit,
                    fp: res.flowPrecision,
                    fu: res.flowUnit,
                    vp: res.velocityPrecision,
                    vu: res.velocityUnit,
                    avgdd: res.avgdd,
                    avgcg: res.avgcg,
                    avgs: res.avgs
                };
                const confirmationTableData = [];

                res.c.forEach((row) => {
                    const r: ConfirmationReportUIRow = {
                        data: []
                    }

                    const main: ConfirmationReportUIRowDetails = {
                        id: row.id,
                        time: new Date(row.time),
                        type: (row.vel && row.depth) ? 'VC' : (row.flow && row.depth) ? 'QC' : row.depth ? 'DC' : '-',
                        depth: row.depth,
                        depthacc: row.depthacc,
                        peak_velocity: row.vel,
                        velacc: row.velacc,
                        ap_ratio: row.atp,
                        calculated_avg_velocity: row.vel * row.atp,
                        silt: row.silt,
                        measured_flow: row.flow,
                        calculated_flow: row.calcflow,
                        ignore: row.ignore,
                        comment: row.comment
                    }
                    r.data.push(main);
                    for(const field of ['Before', 'Closest', 'After']) {
                        const trans = translations && translations[field.toLowerCase()] ? translations[field.toLowerCase()] : '';
                        const val: ConfirmationReportResponseRowMonitorReading =
                            row.monitor && row.monitor[field.toLowerCase()] ? row.monitor[field.toLowerCase()] : null;

                        if(!val) continue;

                        const fieldRow: ConfirmationReportUIRowDetails = {
                            time: new Date(val.ts),
                            type: trans,
                            depth: val.dr,
                            depth_diff: val.dd,
                            calculated_avg_velocity: val.vr,
                            velocity_diff: val.vd,
                            calculatedGain: val.cg,
                            calculated_flow: val.qr,
                            quantity_diff: val.qd
                        }
                        r.data.push(fieldRow);
                    }

                    const avgRow: ConfirmationReportUIRowDetails = {
                        type: translations.avg,
                        depth: row.monitor?.avgd,
                        depth_diff: row.monitor?.avgdd,
                        calculated_avg_velocity: row.monitor?.avgv,
                        velocity_diff: row.monitor?.avgvd,
                        calculatedGain: row.monitor?.avgcg,
                        calculated_flow: null
                    }
                    r.data.push(avgRow);
                    confirmationTableData.push(r);
                });

                const out: ConfirmationReportUIFormatted = {
                    data: confirmationTableData,
                    unitsAndAverages: confirmationAvgAndUnits,
                    count: res.count
                }

                return out;
            })
        )
    }
}

export const CONFIRMATION_ROWS_IN_SUMMARY = 5;
