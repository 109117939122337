import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TimeSpanNumeric } from '../models/locations';
import { QUICK_DATE_RANGES } from '../models/view-data';
import { UserPreferencesDateQuickSpan } from '../models/user-settings';

const TIME_MILISECONDS = 1000;
const TIME_SECONDS = 60;
const TIME_MINUTES = 60;
const TIME_HOURS = 24;
export const MINUTE_TO_MS = 60000;

@Injectable()
export class DateService {
    private missingDate: string;
    private invalidDate: string;
    constructor(private translateService: TranslateService) {
        this.applyTranslations();
    }

    public formatDate(date) {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1),
            day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        return [year, month, day].join('-');
    }

    public customeDateFormat(date) {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1),
            day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        return [month, day, year].join('-');
    }

    public validateDate(date: any, maxDate: Date): DateValidator {
        const validator: DateValidator = {};
        if (!date) {
            validator.hasError = true;
            validator.message = this.missingDate;
            return validator;
        }

        // condition for checking input date greater than minimum date (Jan 1, 2000) and smaller than maximum date (current date)
        if (date > maxDate || date < maxDate) {
            validator.hasError = true;
            validator.message = this.invalidDate;
        }

        return validator;
    }

    private applyTranslations() {
        const keys: Array<string> = [
            'DATE_VALIDATOR.MISSING_DATE',
            'DATE_VALIDATOR.INVALID_DATE_RANGE',
            'DATE_VALIDATOR.INVALID_DATE',
        ];

        this.translateService.get(keys).subscribe((values) => {
            this.missingDate = values['DATE_VALIDATOR.MISSING_DATE'];
            this.invalidDate = values['DATE_VALIDATOR.INVALID_DATE'];
        });
    }

    public getTimespanSetting(start: Date, end: Date) {
        let timeSpan = Math.ceil(Math.abs(end.getTime() - start.getTime()) / (TIME_MILISECONDS * TIME_SECONDS * TIME_MINUTES * TIME_HOURS));

        if (
            start.getFullYear() === end.getFullYear() &&
            start.getMonth() === end.getMonth() &&
            start.getDate() === 1 &&
            end.getDate() === this.getDaysInMonth(end.getMonth(), end.getFullYear())
        ) {
            timeSpan = TimeSpanNumeric.PreviousMonth;
        } else if (
            start.getFullYear() === end.getFullYear() &&
            start.getMonth() === end.getMonth() &&
            start.getDate() === end.getDate()
        ) {
            timeSpan = TimeSpanNumeric.Today;
        } else {
            let months = (end.getFullYear() - start.getFullYear()) * 12;
            months += -start.getMonth() + end.getMonth();
            const diffTime = Math.abs(end.getTime() - start.getTime());
            const minuteTime = 1000 * 60 * 60;
            const dayTime = minuteTime * 24;
            const firstDay = start.getDate();
            const lastDay = end.getDate();

            // Week here is last week + today, so 8 days
            if (diffTime > 8 * dayTime - minuteTime * 10 && diffTime < 8 * dayTime + minuteTime * 10) {
                timeSpan = TimeSpanNumeric.LastWeek;
            } else if (firstDay === lastDay) {
                if (months === 1) {
                    timeSpan = TimeSpanNumeric.LastMonth;
                } else if (months === 3) {
                    timeSpan = TimeSpanNumeric.LastThreeMonths;
                } else if (months === 6) {
                    timeSpan = TimeSpanNumeric.LastSixMonths;
                } else if (months === 12) {
                    timeSpan = TimeSpanNumeric.LastYear;
                }
            }
        }

        return timeSpan;
    }


    public setTimespanSetting(timeSpan: number): {startDate: Date, endDate: Date} {
        const out = {
            startDate: null,
            endDate: null
        }
        const now = new Date();
        if (timeSpan < 0) {
            out.endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
            switch (timeSpan) {
                case TimeSpanNumeric.Today:
                    out.startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0);
                    out.endDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59);
                    break;
                case TimeSpanNumeric.LastWeek:
                    out.startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7, 0, 0, 0);
                default:
                    break;
                case TimeSpanNumeric.PreviousMonth:
                    out.startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1, 0, 0, 0);
                    out.endDate = new Date(
                        now.getFullYear(),
                        now.getMonth() - 1,
                        this.getDaysInMonth(now.getMonth()-1, now.getFullYear()),
                        23, 59, 59
                    );
                    break;
                case TimeSpanNumeric.LastMonth:
                    out.startDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate(), 0, 0, 0);
                    break;
                case TimeSpanNumeric.LastThreeMonths:
                    out.startDate = new Date(now.getFullYear(), now.getMonth() - 3, now.getDate(), 0, 0, 0);
                    break;
                case TimeSpanNumeric.LastSixMonths:
                    out.startDate = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate(), 0, 0, 0);
                    break;
                case TimeSpanNumeric.LastYear:
                    out.startDate = new Date(now.getFullYear(), now.getMonth() - 12, now.getDate(), 0, 0, 0);
                    break;
            }
        } else {
            out.startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - timeSpan, 0, 0, 0);
            out.endDate = now;
        }

        return out;
    }

    public getDaysInMonth(m: number, y: number) {
        return /8|3|5|10/.test(m.toString()) ? 30 : m == 1 ? ((!(y % 4) && y % 100) || !(y % 400) ? 29 : 28) : 31;
    }

    public quickSpanUserPreferencesToQuickDateRange(quickSpanSetting: UserPreferencesDateQuickSpan): QUICK_DATE_RANGES {
        let selectedTimeSpan: QUICK_DATE_RANGES;
        switch(quickSpanSetting) {
            case UserPreferencesDateQuickSpan.OneDay: selectedTimeSpan = QUICK_DATE_RANGES.TODAY; break;
            case UserPreferencesDateQuickSpan.OneWeek: selectedTimeSpan = QUICK_DATE_RANGES.LAST_WEEK; break;
            default: case UserPreferencesDateQuickSpan.OneMonth: selectedTimeSpan =  QUICK_DATE_RANGES.LAST_MONTH; break;
            case UserPreferencesDateQuickSpan.LastMonth: selectedTimeSpan =  QUICK_DATE_RANGES.PREVIOUS_MONTH; break;
            case UserPreferencesDateQuickSpan.ThreeMonths: selectedTimeSpan =  QUICK_DATE_RANGES.LAST_THREE_MONTHS; break;
            case UserPreferencesDateQuickSpan.SixMonths: selectedTimeSpan =  QUICK_DATE_RANGES.LAST_SIX_MONTHS; break;
            case UserPreferencesDateQuickSpan.OneYear: selectedTimeSpan =  QUICK_DATE_RANGES.LAST_YEAR; break;
        }
        return selectedTimeSpan;
    }

    public quickSpanDescriptionForQuickSpan(quickSpanSetting: UserPreferencesDateQuickSpan): string {
        const selectedTimeSpan = this.quickSpanUserPreferencesToQuickDateRange(quickSpanSetting);

        return this.quickSpanDescription(selectedTimeSpan);
    }

    public quickSpanDescription(selectedTimeSpan: QUICK_DATE_RANGES): string {
        switch(selectedTimeSpan) {
            case QUICK_DATE_RANGES.TODAY: return this.translateService.instant('LOCATION_DASHBOARD.ONE_DAY_BTN');
            case QUICK_DATE_RANGES.LAST_WEEK: return  this.translateService.instant('LOCATION_DASHBOARD.ONE_WEEK_BTN');
            case QUICK_DATE_RANGES.PREVIOUS_MONTH: return  this.translateService.instant('LOCATION_DASHBOARD.LAST_MONTH_BTN');
            case QUICK_DATE_RANGES.LAST_MONTH: return  this.translateService.instant('LOCATION_DASHBOARD.ONE_MONTH_BTN');
            case QUICK_DATE_RANGES.LAST_THREE_MONTHS: return  this.translateService.instant('LOCATION_DASHBOARD.THREE_MONTHS_BTN');
            case QUICK_DATE_RANGES.LAST_SIX_MONTHS: return  this.translateService.instant('LOCATION_DASHBOARD.SIX_MONTHS_BTN');
            case QUICK_DATE_RANGES.LAST_YEAR: return  this.translateService.instant('LOCATION_DASHBOARD.ONE_YEAR_BTN');
            default:
                return null;
        }
    }

}

export class DateValidator {
    public hasError?: boolean;
    public message?: string;
}
