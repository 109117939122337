import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewEncapsulation,
    ElementRef,
    ChangeDetectorRef,
    OnDestroy,
    OnInit,
} from '@angular/core';

import { ActivatedRoute, ParamMap } from '@angular/router';

import { Subscription } from 'rxjs';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';

const highcharts = require('highcharts');

import * as Highcharts from 'highcharts';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { StringUtils } from 'app/shared/utils/string-utils';
import { ViewDataService } from 'app/shared/services/view-data.service';
import { Annotations, EntityData, IsoQLines, ScatterData } from 'app/shared/models/scatter-data';
import { customerLocationGroupQueryParam, customerQueryParam } from 'app/shared/models/customer';
import { AnnotationSettings, BestFitCurve, EntitySeries } from 'app/shared/models/view-data';
import { LocationDashboardFilterData } from 'app/shared/models/location-dashboard-filter-data';

@Component({
    selector: 'app-scattergraph',
    templateUrl: './scattergraph.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class ScattergraphComponent implements OnChanges, OnInit, OnDestroy {
    @Input() public data: ScatterData;
    @Input() public isLoading: boolean;
    @Input() public dateFormat: string;
    /**
     * Represents Date Format For Scatter Graph
     */
    @Input() public scatterDateFormat: string;

    /**
     * Represents Tooltip Date Format
     */
    @Input() public tooltipTimeFormat: string;
    @Input() public annotationSettings: AnnotationSettings;

    public scatterChart: any;

    public scatterOptions: any;

    public showScattergraphOptions: boolean;

    public xAxisLabel: string;
    public yAxisLabel: string;

    /**
     * Represents hydrograph chart index which would be used for co-relation of hydro and scatter chart
     */
    private hydrographChartIndex = 0;

    private customerId: number;
    private locationGroupId: number;
    private subscriptions = new Array<Subscription>();
    private locationId: number;
    public scatterId = 'scattergraph-chart';
    public isoQlines = [];
    public pipeColor: string;
    /**
     * filter values from dashboard filters
     */
    @Input() public filterValues: LocationDashboardFilterData;
    public pipeGraph: any;
    public chart: Highcharts.ChartObject;
    public pipeOverlaySettings;
    public xAxisMax: number;
    public isPipeHeight;
    public isManholeDepth;
    public xUnit: string;
    public yUnit: string;

    /**
     *  10551 - Tolerance Lines - Slider range variables
     */
    public rangeValue: number;
    public minValue: number;
    public maxValue: number;
    public selected: number;
    public stepRange: number;
    public bestFitCurve: BestFitCurve;
    public isZoomedInWithBestFitTolerance: boolean;

    constructor(
        private viewDataService: ViewDataService,
        private element: ElementRef,
        private cdr: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        private uiUtilsService: UiUtilsService,
        private statusCodeService: StatusCodeService,
    ) {
        this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            this.customerId = Number(params.get(customerQueryParam));
            this.locationGroupId = Number(params.get(customerLocationGroupQueryParam));
        });
    }

    public ngOnInit() {
        window.addEventListener('resize', () => {
            this.isoQlines.length = 0;
            this.bestFitCurve = null;
            this.scatterId = 'scattergraph-chart' + '_' + Date.now();
            this.displayScatterGraph();
        });
        this.bestFitCurve = null;
        this.isoQlines.length = 0;
        this.minValue = 0;
        this.maxValue = 1;
        this.rangeValue = 0;
        this.selected = 3;
        this.stepRange = 0.1;
        this.isZoomedInWithBestFitTolerance = false;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.isoQlines.length = 0;
        this.bestFitCurve = null;

        // ensure data property
        if (changes.data && !changes.data.currentValue) {
            return;
        }

        // Hiding filters on location change
        this.showScattergraphOptions = false;
        this.minValue = 0;
        this.maxValue = 1;
        this.rangeValue = 0;
        this.selected = 3;
        this.stepRange = 0.1;
        this.isZoomedInWithBestFitTolerance = false;

        if (this.data) {
            this.xAxisMax = this.data.xAxis.maximum;
            this.displayScatterGraph();
        }
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /**
     * Initializes the Scattergraph.
     * @param depthVelocityData - Data Array for depth and velocity series in scattergraph.
     * @param yAxisAnnotations - Represents the annotations in scattergraph.
     * @param scatterDateFormat - Represents the date format in scattergraph which will be come from customer API .
     * @param tooltipTimeFormat - Represents the tooltip format in scattergraph.
     */
    // tslint:disable-next-line:cyclomatic-complexity
    public initScatterChart(
        depthVelocityData,
        yAxisAnnotations: Array<Annotations>,
        scatterDateFormat: string,
        tooltipTimeFormat: string,
    ) {
        if (!this.data) {
            this.isLoading = false;
            return;
        }

        this.statusCodeService.userInfoThemeBS.subscribe((response: boolean) => {
            if (response) {
                StringUtils.setHighChartTheme();
                this.pipeColor = 'silver';
            } else {
                StringUtils.setHighchartWhiteTheme();
                this.pipeColor = 'black';
            }
        });

        this.scatterOptions = {
            global: {
                useUTC: false,
            },
            lang: {
                contextButtonTitle: 'Print/Download',
            },
            exporting: {
                enabled: false,
                buttons: {
                    contextButton: {
                        symbol: 'url(assets/images/baseline-print-24px.svg)',
                        x: 20,
                        symbolSize: 24,
                        theme: {
                            states: {
                                hover: {
                                    fill: '#fff',
                                },
                                select: {
                                    fill: '#fff',
                                },
                            },
                        },
                    },
                },
            },
            navigation: {
                buttonOptions: {
                    verticalAlign: 'top',
                },
            },
            credits: false,
            legend: {
                enabled: false,
            },
            tooltip: {
                hideDelay: 0,
                enabled: true,
                shared: true,
                crosshairs: false,
                headerFormat: '',
                formatter: function () {
                    if (this.points) {
                        const tooltipFormatter = new Array<string>();
                        tooltipFormatter.push(
                            Highcharts.dateFormat(scatterDateFormat + tooltipTimeFormat, this.points[0].point.dateTime),
                        );
                        for (const seriesPoint of this.points) {
                            if (
                                seriesPoint.color &&
                                seriesPoint.color !== 'lightgreen' &&
                                seriesPoint.color !== '#00CC6A'
                            ) {
                                const point = `<br/>${StringUtils.upperCase(seriesPoint.point.yAxisEntity)}: <b>
                ${this.y.toFixed(seriesPoint.point.yPrecision)} </b>
                    ${seriesPoint.point.yUnit}<br />${StringUtils.upperCase(seriesPoint.point.xAxisEntity)}: <b>
                      ${this.x.toFixed(seriesPoint.point.xPrecision)} </b>${seriesPoint.point.xUnit}`;
                                tooltipFormatter.push(point);
                            } else if (seriesPoint.color && seriesPoint.color === '#00CC6A') {
                                const point = `${StringUtils.upperCase(seriesPoint.series.name)}<br/>
                ${this.x.toFixed(2)} ${seriesPoint.series.data[0].xUnits} ,
                ${this.y.toFixed(2)} ${seriesPoint.series.data[0].yUnits}`;
                                tooltipFormatter.push(point);
                                if (tooltipFormatter && tooltipFormatter.length > 1) {
                                    break;
                                }
                            } else {
                                const point = `${StringUtils.upperCase(seriesPoint.series.name)}<br/>
                ${this.x.toFixed(2)} ${seriesPoint.series.data[0].xUnits} ,
                      ${this.y.toFixed(2)} ${seriesPoint.series.data[0].yUnits}`;
                                tooltipFormatter.push(point);
                                if (tooltipFormatter && tooltipFormatter.length > 1) {
                                    break;
                                }
                            }
                        }
                        return tooltipFormatter;
                    } else {
                        const dateFormat = Highcharts.dateFormat(
                            scatterDateFormat + tooltipTimeFormat,
                            this.point.dateTime,
                        );
                        const scatterTooltipFormatter = `${dateFormat}<br/>${StringUtils.upperCase(
                            this.point.yAxisEntity,
                        )}:<b>
                ${this.y.toFixed(this.point.yPrecision)} </b>${this.point.yUnit}<br/>
                ${StringUtils.upperCase(this.point.xAxisEntity)}:<b>${this.x.toFixed(this.point.xPrecision)} </b>
                ${this.point.xUnit}`;
                        return scatterTooltipFormatter;
                    }
                },
            },
            chart: {
                renderTo: this.scatterId,
                zoomType: 'xy',
                height: 450,
                events: {
                    selection: (event) => {
                        // handles setting of isZoomedInWithBestFitTolerance required to avoid re creation of entire chart on range selection
                        if (
                            this.annotationSettings.isBestFit &&
                            this.annotationSettings.isToleranceLines &&
                            !event.resetSelection
                        ) {
                            this.isZoomedInWithBestFitTolerance = true;
                        }
                    },
                },
            },
            plotOptions: {
                scatter: {
                    turboThreshold: 100000,
                    point: {
                        events: {
                            mouseOver: (event) => {
                                this.viewDataService.corelateGraphs(
                                    event.target.series.chart.container,
                                    event.target.dateTime,
                                );
                            },
                            mouseOut: (event) => {
                                this.viewDataService.setChartParameter(
                                    event.target.dateTime,
                                    this.hydrographChartIndex,
                                );
                            },
                        },
                    },
                    allowPointSelect: true,
                },
                line: {
                    marker: {
                        enabled: false,
                    },
                },
            },
            title: {
                text: '',
            },
            xAxis: {
                gridLineWidth: 1, // Bug#10327 Scattergraph:  Enable the side graph lines
                title: {
                    enabled: true,
                    text: this.xAxisLabel,
                },
                events: {
                    setExtremes: (e) => {
                        if (typeof e.min === 'undefined' && typeof e.max === 'undefined') {
                            this.viewDataService.resetZoomClicked.next(false);
                            // handles reset zoom event on scatter graph to set isZoomedInWithBestFitTolerance
                            if (this.annotationSettings.isBestFit && this.annotationSettings.isToleranceLines) {
                                this.isZoomedInWithBestFitTolerance = false;
                            }
                        } else {
                            this.viewDataService.resetZoomClicked.next(false);
                        }
                    },
                },
                ordinal: false,
                min: 0,
                // max: this.xAxisMax,
                endOnTick: true,
                alignTicks: true,
            },
            yAxis: {
                title: {
                    text: this.yAxisLabel,
                },
                min: 0,
                offset:
                    !this.annotationSettings.isScatterInvert &&
                    this.annotationSettings.isPipeOverlay &&
                    this.data &&
                    this.data.pipeOverlay
                        ? 70
                        : 0,
                lineWidth: 0.2,
                tickPositioner: undefined,
            },
            scrollbar: {
                enabled: false,
                liveRedraw: false,
            },
            navigator: { enabled: false, margin: 0 },
            series: [
                {
                    type: 'scatter',
                    zIndex: 5,
                    allowPointSelect: true,
                    marker: {
                        radius: 4,
                        symbol: 'diamond',
                        states: {
                            hover: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)',
                            },
                            select: {
                                enabled: true,
                                lineColor: 'rgb(100,100,100)',
                                lineWidth: 0.1,
                                fillColor: undefined,
                            },
                        },
                    },
                    column: { groupPadding: 0, pointPadding: 0, pointWidth: 1, borderWidth: 0, shadow: false },
                    line: { lineWidth: 0.5 },
                    scatter: {
                        marker: {
                            radius: 5,
                            enabled: true,
                        },
                    },
                    color: 'rgba(119, 152, 191, .5)',
                    data: depthVelocityData,
                },
            ],
            rangeSelector: {
                enabled: false,
            },
        };

        highcharts.setOptions({
            global: {
                useUTC: false,
            },
        });

        // (id === 100 && this.annotationSettings.isPipeHeight) ||
        //     (id === 104 && this.annotationSettings !== undefined && this.annotationSettings.isManholeDepth)

        // if user has selected pipeHeight or manholeDepth
        if (yAxisAnnotations.length > 0) {
            this.setAnnotations(yAxisAnnotations);
        }

        if (this.annotationSettings.isIsoQ) {
            for (let i = 0; i < this.isoQlines.length; i++) {
                this.scatterOptions.series.push(this.isoQlines[i]);
                this.uiUtilsService.safeChangeDetection(this.cdr);
            }

            if (
                !(
                    this.annotationSettings.isPipeHeight &&
                    (yAxisAnnotations[0].name.indexOf('Pipe') >= -1 || yAxisAnnotations[1].name.indexOf('Pipe') >= -1)
                ) ||
                !(
                    this.annotationSettings.isManholeDepth &&
                    (yAxisAnnotations[0].name.indexOf('Manhole') >= -1 ||
                        yAxisAnnotations[1].name.indexOf('Manhole') >= -1)
                )
            ) {
                this.scatterOptions.xAxis.max = Math.max(...depthVelocityData.map((x) => x['x']));
                this.scatterOptions.yAxis.max = Math.max(...depthVelocityData.map((x) => x['y']));
            }
        }

        if (this.annotationSettings.isBestFit) {
            if (this.data && this.data.curves) {
                const clonedBestFitLines = JSON.parse(JSON.stringify(this.data.curves));

                for (let i = 0; i < clonedBestFitLines.length; i++) {
                    if (clonedBestFitLines[i]['name'] === 'Bestfit') {
                        this.xUnit = clonedBestFitLines[i]['data'][0]['xUnits'] = depthVelocityData[0].xUnit;
                        this.yUnit = clonedBestFitLines[i]['data'][0]['yUnits'] = depthVelocityData[0].yUnit;

                        this.bestFitCurve = {
                            name: clonedBestFitLines[i]['name'],
                            type: 'line',
                            data: !this.annotationSettings.isScatterInvert
                                ? clonedBestFitLines[i]['data']
                                : this.invertIsoQLines(clonedBestFitLines[i]['data']),
                            color: '#00CC6A',
                            width: 1.5,
                        };
                        this.scatterOptions.series.push(this.bestFitCurve);
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    }
                }
            }

            if (this.annotationSettings.isToleranceLines) {
                this.plotToleranceLines(this.rangeValue, this.selected);
            }
        }

        // if (this.annotationSettings.isIsoQ) {

        //   let maxYAxis = [];

        //   for (let i = 0; i < this.isoQlines.length; i++) {
        //     this.scatterOptions.series.push(this.isoQlines[i]);
        //     maxYAxis.push(Math.max(...this.isoQlines[i].data.map(x => x['y'])));
        //   }

        //   this.scatterOptions.yAxis.endOnTick = false;
        //   this.scatterOptions.yAxis.max = Math.ceil(Math.max(...maxYAxis));
        //   this.scatterOptions.xAxis.max = Math.max(...depthVelocityData.map(x => x['x']));

        // }

        if (this.annotationSettings.isPipeOverlay && this.data && this.data.pipeOverlay) {
            if (this.data && this.data.pipeOverlay && !this.annotationSettings.isScatterInvert) {
                if (this.data.pipeOverlay['maximumSurchargeY'] % 1 === 0) {
                    this.scatterOptions.yAxis.max = this.data.pipeOverlay['maximumSurchargeY'];
                } else {
                    this.scatterOptions.yAxis.max = Math.ceil(this.data.pipeOverlay['maximumSurchargeY'] * 100) / 100;
                }

                if (this.annotationSettings.isIsoQ) {
                    let totalTicks = 0;

                    for (let r = 7; r > 2; r--) {
                        if (this.data.pipeOverlay['maximumSurchargeY'] % r === 0) {
                            totalTicks = r;
                            break;
                        }
                    }
                    const data = this.data;
                    this.scatterOptions.yAxis.tickPositioner = function () {
                        const positions = [];
                        let tick = Math.floor(0);
                        const increment = Math.ceil((data.pipeOverlay['maximumSurchargeY'] - 0) / totalTicks);
                        if (this.dataMax !== null && this.dataMin !== null) {
                            for (tick; tick - increment <= this.dataMax; tick += increment) {
                                positions.push(tick);
                            }
                        }
                        return positions;
                    };
                    this.scatterOptions.yAxis.endOnTick = false;
                }
            }
        }

        this.uiUtilsService.safeChangeDetection(this.cdr);
        // Redraws existing chart with current zoom level on change in range when bestfit and tolerance annotations chosen
        if (
            this.isZoomedInWithBestFitTolerance &&
            this.annotationSettings.isBestFit &&
            this.annotationSettings.isToleranceLines
        ) {
            // updating previous chart instance with current scatteroptions without rendering entire chart when user zoomed in
            this.chart.update(this.scatterOptions, false, true);
            const toleranceSeries = this.chart.series.filter((seriesObj) => {
                if (seriesObj.name === 'Tolerance Lines') {
                    return seriesObj;
                }
            });
            const scatterOptionSeries = this.scatterOptions.series.filter((seriesObj) => {
                if (seriesObj.name === 'Tolerance Lines') {
                    return seriesObj;
                }
            });
            /* adds or removes additional tolerance series plot data and redraws chart if user toggles between selected
      //  values of above, below, both */
            if (toleranceSeries.length > scatterOptionSeries.length) {
                toleranceSeries[toleranceSeries.length - 1].remove(true);
            } else if (toleranceSeries.length < scatterOptionSeries.length) {
                this.chart.addSeries(this.scatterOptions.series[this.scatterOptions.series.length - 1]);
                toleranceSeries.push(this.scatterOptions.series[this.scatterOptions.series.length - 1]);
            }
            toleranceSeries.forEach((seriesObj, index) => {
                if (!(seriesObj.remove == null)) {
                    toleranceSeries[index].update(scatterOptionSeries[index]);
                }
            });

            return;
        }
        // sets the hydrograph chart options in service variable
        // so that we can use it to co-relate between hydrograph and scattergraph charts
        if (this.element.nativeElement.querySelector('#' + this.scatterId)) {
            this.chart = this.viewDataService.charts[1] = new Highcharts.Chart(this.scatterOptions);
        }

        if (
            this.annotationSettings.isPipeOverlay &&
            this.data &&
            this.data.pipeOverlay &&
            !this.annotationSettings.isScatterInvert
        ) {
            this.pipeOverlaySettings = {
                maximumY: this.data.pipeOverlay['maximumY'],
                minimumY: this.data.pipeOverlay['minimumY'],
                pipeHeight: this.data.pipeOverlay['pipeHeight'],
                xForMaximumY: this.data.pipeOverlay['xForMaximumY'],
                xForMinimumY: this.data.pipeOverlay['xForMinimumY'],
                maximumSurchargeY: this.data.pipeOverlay['maximumSurchargeY'],
            };

            this.plotScatterGraphRenderPipe(
                this.pipeOverlaySettings.pipeHeight,
                this.pipeOverlaySettings.minimumY,
                this.pipeOverlaySettings.maximumY,
                this.pipeOverlaySettings.xForMinimumY,
                this.pipeOverlaySettings.xForMaximumY,
                this.pipeColor,
            );
        }
    }

    public displayScatterGraph() {
        if (!this.data) {
            this.isLoading = false;

            return;
        }

        const depthVelocityData = new Array<EntityData>();

        for (const data of this.data.d) {
            const strElements = data.toString().split(':');
            const millisecondsElement = strElements[2];
            const milliseconds = Number(millisecondsElement.substr(0, millisecondsElement.length - 1));

            const xElement = strElements[0];
            const xValue = Number(xElement.substr(1, xElement.length));
            const yValue = Number(strElements[1]);

            let xAxisEntity, yAxisEntity;

            let depthPoint;
            if (this.annotationSettings.isScatterInvert) {
                this.xAxisLabel = this.data.yAxis.label;
                this.yAxisLabel = this.data.xAxis.label;

                xAxisEntity = this.data.yAxis.label.split(' ')[0];
                yAxisEntity = this.data.xAxis.label.split(' ')[0];

                depthPoint = <EntityData>{
                    dateElement: xValue + ':' + yValue + ':' + milliseconds,
                    x: yValue,
                    y: xValue,
                    xUnit: this.data.yAxis.unit,
                    yUnit: this.data.xAxis.unit,
                    xPrecision: this.data.yAxis.precision,
                    yPrecision: this.data.xAxis.precision,
                    xAxisEntity: xAxisEntity,
                    yAxisEntity: yAxisEntity,
                    dateTime: milliseconds,
                };
            } else {
                this.xAxisLabel = this.data.xAxis.label;
                this.yAxisLabel = this.data.yAxis.label;

                xAxisEntity = this.data.xAxis.label.split(' ')[0];
                yAxisEntity = this.data.yAxis.label.split(' ')[0];

                depthPoint = <EntityData>{
                    dateElement: xValue + ':' + yValue + ':' + milliseconds,
                    x: xValue,
                    y: yValue,
                    xUnit: this.data.xAxis.unit,
                    yUnit: this.data.yAxis.unit,
                    xPrecision: this.data.xAxis.precision,
                    yPrecision: this.data.yAxis.precision,
                    xAxisEntity: xAxisEntity,
                    yAxisEntity: yAxisEntity,
                    dateTime: milliseconds,
                };
            }
            depthVelocityData.push(depthPoint);
        }

        if (this.annotationSettings.isIsoQ) {
            if (this.data && this.data.isoQ) {
                const clonedISOQLines = JSON.parse(JSON.stringify(this.data.isoQ));
                for (const data of clonedISOQLines) {
                    const isoQLine = <IsoQLines>{
                        name: data['name'],
                        type: 'line',
                        data: !this.annotationSettings.isScatterInvert
                            ? data['data']
                            : this.invertIsoQLines(data['data']),
                        color: 'lightgreen',
                        dashStyle: 'shortDash',
                        label: data['label'],
                    };

                    isoQLine.data[0]['xUnits'] = depthVelocityData[0].xUnit;
                    isoQLine.data[0]['yUnits'] = depthVelocityData[0].yUnit;

                    isoQLine.data.forEach(function (v) {
                        delete v['dataLabels'];
                    });

                    if (
                        !this.annotationSettings.isPipeOverlay ||
                        (this.annotationSettings.isPipeOverlay && this.annotationSettings.isScatterInvert)
                    ) {
                        if (!this.annotationSettings.isScatterInvert) {
                            this.markLabelsforIsoQLines(isoQLine, Math.max(...depthVelocityData.map((x) => x['y'])));
                        } else {
                            this.markLabelsforIsoQLines(isoQLine, Math.max(...depthVelocityData.map((x) => x['x'])));
                        }
                    } else {
                        isoQLine.data[0]['dataLabels'] = {
                            formatter: function () {
                                return data['label'];
                            },
                            enabled: true,
                            align: 'top',
                            color: 'black',
                            shadow: false,
                            x: -30,
                            y: -15,
                            style: { fontSize: '10px', textShadow: '0px' },
                        };
                    }

                    this.isoQlines.push(isoQLine);
                }
            }
        }

        if (this.annotationSettings.isPipeOverlay && this.data && this.data.pipeOverlay) {
            this.pipeOverlaySettings = {
                maximumY: this.data.pipeOverlay['maximumY'],
                minimumY: this.data.pipeOverlay['minimumY'],
                pipeHeight: this.data.pipeOverlay['pipeHeight'],
                xForMaximumY: this.data.pipeOverlay['xForMaximumY'],
                xForMinimumY: this.data.pipeOverlay['xForMinimumY'],
            };
        }

        // condition to set tooltip format as per highchart configuration

        let timeFormat;
        if (this.tooltipTimeFormat === 'h:mm:ss tt') {
            timeFormat = ' %l:%M %p';
        } else {
            timeFormat = ' %H:%M';
        }

        this.initScatterChart(depthVelocityData, this.data.yAxis.annotations, this.scatterDateFormat, timeFormat);

        this.isLoading = false;
    }

    /**
     * saves the chart instance to a chart variable.
     * @param chart - instance of scatter chart.
     */
    public saveScatterChart(chart) {
        this.scatterChart = chart;
    }

    /**
     * Retrieves scattergraph data for the current location dashboard filters.
     */
    private plotScatterGraphRenderPipe(pipeDiam, minY, maxY, xMinY, xMaxY, myColor): void {
        const self = this,
            chart = this.chart;

        const pipeTopPix = chart.yAxis[0].toPixels(pipeDiam);
        const pipeBottomPix = chart.yAxis[0].toPixels(0);

        const diam = pipeBottomPix - pipeTopPix;
        const r = diam / (4 * Math.sin(Math.PI / 6));
        const xOffset = r - r * Math.cos(Math.PI / 6);

        const yExtremes = chart.yAxis[0].getExtremes();
        const xExtremes = chart.xAxis[0].getExtremes();

        const xmin = xExtremes.min;
        const xmax = xExtremes.max;
        const myTop = chart.yAxis[0].toPixels(yExtremes.max);
        const myBottom = chart.yAxis[0].toPixels(yExtremes.min);
        const myLeft = chart.xAxis[0].toPixels(xmin);
        const myRight = chart.xAxis[0].toPixels(xmax);

        const pipeLowerY = chart.yAxis[0].toPixels(0);
        const pipeUpperY = pipeLowerY - diam;

        const pipeLeftX = myLeft + xOffset;
        const pipeRightX = chart.xAxis[0].toPixels(xmax) - xOffset;

        const centerUpperY = diam - r * Math.sin(Math.PI / 6) + pipeUpperY;
        const centerLowerY = diam - 3 * (r * Math.sin(Math.PI / 6)) + pipeUpperY;
        const centerLeftX = myLeft - r + 2 * xOffset;
        const centerRightX = myRight - r;
        const rPix = r;

        const angle = Math.atan((diam / 2 - myBottom) / r);

        this.pipeGraph = chart.renderer.g().add();

        const width = 2;
        chart.renderer
            .arc(centerRightX, centerLowerY, rPix, rPix, -Math.PI / 6, Math.PI / 6)
            .attr({
                fill: '#FCFFC5',
                stroke: myColor,
                'stroke-width': width,
            })
            .add(this.pipeGraph);

        chart.renderer
            .arc(centerRightX, centerUpperY, rPix, rPix, -Math.PI / 6, Math.PI / 6)
            .attr({
                fill: '#FCFFC5',
                stroke: myColor,
                'stroke-width': width,
            })
            .add(this.pipeGraph);

        chart.renderer
            .arc(centerRightX, centerUpperY, rPix, rPix, Math.PI / 6, -Math.PI / 6)
            .attr({
                fill: '#FCFFC5',
                stroke: myColor,
                'stroke-width': width,
            })
            .add(this.pipeGraph);

        chart.renderer
            .arc(centerLeftX, centerLowerY, rPix, rPix, -Math.PI / 6, Math.PI / 6)
            .attr({
                fill: '#FCFFC5',
                stroke: myColor,
                'stroke-width': width,
            })
            .add(this.pipeGraph);

        chart.renderer
            .arc(centerLeftX, centerUpperY, rPix, rPix, Math.PI / 6, -Math.PI / 6)
            .attr({
                fill: '#FCFFC5',
                stroke: myColor,
                'stroke-width': width,
            })
            .add(this.pipeGraph);

        chart.renderer
            .arc(centerLeftX, centerLowerY, rPix, rPix, Math.PI / 6, -Math.PI / 6)
            .attr({
                fill: '#FCFFC5',
                stroke: myColor,
                'stroke-width': width,
            })
            .add(this.pipeGraph);

        // outer
        const outerX = pipeLeftX;
        const outerY = pipeLowerY - diam / 2;
        const outerR = diam / 2;
        chart.renderer
            .arc(outerX, outerY, outerR, outerR, Math.PI / 2, -Math.PI / 2)
            .attr({
                fill: '#FCFFC5',
                stroke: myColor,
                'stroke-width': width,
            })
            .add(this.pipeGraph);

        chart.renderer
            .path(['M', pipeLeftX, pipeLowerY, 'L', pipeRightX, pipeLowerY])
            .attr({
                'stroke-width': width,
                stroke: myColor,
            })
            .add(this.pipeGraph);

        chart.renderer
            .path(['M', pipeLeftX, pipeUpperY, 'L', pipeRightX, pipeUpperY])
            .attr({
                'stroke-width': width,
                stroke: myColor,
            })
            .add(this.pipeGraph);

        // min
        const centerMinMax = myLeft - 20;
        const minYPix = chart.yAxis[0].toPixels(minY);
        const minXYPix = Math.abs(chart.xAxis[0].toPixels(xMinY));
        const myline = myLeft - minXYPix;
        chart.renderer
            .path(['M', centerMinMax, minYPix, 'L', myLeft, minYPix])
            .attr({
                'stroke-width': 1,
                stroke: '#006AC2',
            })
            .add(this.pipeGraph);

        chart.renderer
            .path([
                'M',
                centerMinMax - 5,
                minYPix - 10,
                'L',
                centerMinMax + 5,
                minYPix - 10,
                centerMinMax,
                minYPix,
                centerMinMax - 5,
                minYPix - 10,
            ])
            .attr({
                'stroke-width': 1,
                stroke: '#006AC2',
                fill: '#006AC2',
            })
            .add(this.pipeGraph);

        chart.renderer
            .path(['M', centerMinMax - 5, minYPix + 3, 'L', centerMinMax + 5, minYPix + 3])
            .attr({
                'stroke-width': 1,
                stroke: '#006AC2',
            })
            .add(this.pipeGraph);

        chart.renderer
            .path(['M', centerMinMax - 3, minYPix + 6, 'L', centerMinMax + 3, minYPix + 6])
            .attr({
                'stroke-width': 1,
                stroke: '#006AC2',
            })
            .add(this.pipeGraph);

        chart.renderer
            .text('min', centerMinMax - 30, minYPix - 5)
            .css({
                color: '#006AC2',
                fontSize: '10px',
            })
            .add(this.pipeGraph);

        // max
        const maxYPix = chart.yAxis[0].toPixels(maxY);
        chart.renderer
            .path(['M', centerMinMax, maxYPix, 'L', myLeft, maxYPix])
            .attr({
                'stroke-width': 1,
                stroke: '#006AC2',
            })
            .add(this.pipeGraph);

        chart.renderer
            .path([
                'M',
                centerMinMax - 5,
                maxYPix - 10,
                'L',
                centerMinMax + 5,
                maxYPix - 10,
                centerMinMax,
                maxYPix,
                centerMinMax - 5,
                maxYPix - 10,
            ])
            .attr({
                'stroke-width': 1,
                stroke: '#006AC2',
                fill: '#006AC2',
            })
            .add(this.pipeGraph);

        chart.renderer
            .path(['M', centerMinMax - 5, maxYPix + 3, 'L', centerMinMax + 5, maxYPix + 3])
            .attr({
                'stroke-width': 1,
                stroke: '#006AC2',
            })
            .add(this.pipeGraph);

        chart.renderer
            .path(['M', centerMinMax - 3, maxYPix + 6, 'L', centerMinMax + 3, maxYPix + 6])
            .attr({
                'stroke-width': 1,
                stroke: '#006AC2',
            })
            .add(this.pipeGraph);

        chart.renderer
            .text('max', centerMinMax - 30, maxYPix - 5)
            .css({
                color: '#006AC2',
                fontSize: '10px',
            })
            .add(this.pipeGraph);
    }

    /**
     * Sets the annotations for the chart
     * @param chart - instance of Y-Axis Annotations
     */
    private setAnnotations(yAxisAnnotations) {
        // Creating empty array to store plotlines
        this.scatterOptions.yAxis.plotLines = [];

        // Setting plotlines for each annotation
        for (let i = 0; i < yAxisAnnotations.length; i++) {
            // remove this code if correct color code comes from Hydrograph API
            let lineColor = '';
            if (i % 2 === 0) {
                lineColor = '#FF3D00';
            } else {
                lineColor = 'blue';
            }

            // Getting value of anotation
            const value = yAxisAnnotations[i].value;

            // Getting name of anotation
            const name = yAxisAnnotations[i].name;

            // Searching same value data in plotLines
            const searchedData = this.scatterOptions.yAxis.plotLines.find(function (ele) {
                return ele.value === value;
            });

            if (this.annotationSettings.isPipeHeight && yAxisAnnotations[i].name.indexOf('Pipe') >= 0) {
                this.isPipeHeight = true;
                this.scatterOptions.yAxis.max = value;
            } else {
                this.isPipeHeight = false;
            }

            if (this.annotationSettings.isManholeDepth && yAxisAnnotations[i].name.indexOf('Manhole') >= 0) {
                this.isManholeDepth = true;
                this.scatterOptions.yAxis.max = value;
            } else {
                this.isManholeDepth = false;
            }

            if (!searchedData) {
                if (this.annotationSettings && (this.isPipeHeight || this.isManholeDepth)) {
                    // Same value line does not exist in PlotLines
                    this.scatterOptions.yAxis.plotLines.push({
                        color: lineColor,
                        width: 2,
                        value: value,
                        dashStyle: 'shortdash',
                        zIndex: 4,
                        label: {
                            text: name,
                            align: 'center',
                            y: 15,
                            style: {
                                color: lineColor,
                            },
                        },
                    });
                }
            } else {
                // Appending new line's data on existing
                const text = searchedData.label.text;
                searchedData.label.text = text + ', ' + name;
            }
        }
    }

    /**
     * Sets the labels for ISO-Q Lines
     * @param maxLabelScatterData - maximum value of scatter data
     */
    private markLabelsforIsoQLines(isoQLine, maxLabelScatterData) {
        let selectedData;

        if (!this.annotationSettings.isScatterInvert) {
            selectedData = isoQLine.data.filter((x) => x['y'] <= maxLabelScatterData)[0];
        } else {
            selectedData = isoQLine.data.filter((x) => x['x'] <= maxLabelScatterData)[
                isoQLine.data.filter((x) => x['x'] <= maxLabelScatterData).length - 1
            ];
        }

        if (selectedData) {
            selectedData['dataLabels'] = {
                formatter: function () {
                    return isoQLine['label'];
                },
                enabled: true,
                align: !this.annotationSettings.isScatterInvert ? 'top' : 'left',
                color: !this.pipeColor || this.pipeColor === 'black' ? 'black' : 'silver',
                shadow: false,
                x: -20,
                y: -5,
                style: {
                    fontSize: '10px',
                    textOutline: false,
                },
            };
        }
    }

    /**
     * Inverts the data for ISO-Q Lines
     * @param data - scatter data
     */
    private invertIsoQLines(data: Array<Object>) {
        for (let i = 0; i < data.length; i++) {
            const b = data[i]['y'];
            data[i]['y'] = data[i]['x'];
            data[i]['x'] = b;
        }
        if (this.annotationSettings.isBestFit) {
            const updatedData = data.reverse();
            updatedData[0]['xUnits'] = this.xUnit;
            updatedData[0]['yUnits'] = this.yUnit;
            return updatedData;
        }
        return data.reverse();
    }

    /**
  * Plots the Tolerance Lines for Scatter Data
  * @param toleranceFactor - toleranceFactor Value that variates the lines
  @param linesPosition - Tolerance Lines Position - Above , Below or Both
  */
    private plotToleranceLines(toleranceFactor, linesPosition) {
        switch (linesPosition) {
            case 3:
                this.drawToleranceLines(this.addToleranceFactor(toleranceFactor));
                this.drawToleranceLines(this.addToleranceFactor(-toleranceFactor));
                break;
            case 1:
                this.drawToleranceLines(this.addToleranceFactor(toleranceFactor));
                break;
            case 2:
                this.drawToleranceLines(this.addToleranceFactor(-toleranceFactor));
                break;
            default:
                break;
        }
    }

    /**
     * Draws the Tolerance Lines for Scatter Data
     * @param toleranceLines - data for Tolerance Lines
     */
    private drawToleranceLines(toleranceLine) {
        const entityInformationData = <EntitySeries>{
            id: 0,
            name: 'Tolerance Lines',
            displayGroupID: 0,
            type: 'line',
            data: toleranceLine,
            showInLegend: true,
            color: 'red',
            dashStyle: 'longdash',
            dataGrouping: { enabled: false },
            zIndex: 5,
            lineWidth: 1.5,
            unitOfMeasure: '',
            precision: 2,
            axis: { minimum: 0, maximum: 0 },
            yAxis: 0, // TODO Axis Decision
            turboThreshold: 10000,
            step: false,
            connectNulls: false,
            locationName: '',
            startDate: '',
            isMultipleOverlayEnabled: false,
            marker: {
                enabled: false,
                states: {
                    hover: {
                        enabled: false,
                    },
                },
            },
            tooltip: {
                enabled: false,
            },
        };
        this.scatterOptions.series.push(entityInformationData);
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * Adds the Tolerance Factor to the best fit curve
     * @param toleranceFactor - toleranceFactor Value that variates the lines
     */
    private addToleranceFactor(toleranceFactor) {
        if (this.bestFitCurve && this.bestFitCurve.data) {
            const clonedBestFitLines = JSON.parse(JSON.stringify(this.bestFitCurve.data));
            for (let i = 0; i < clonedBestFitLines.length; i++) {
                clonedBestFitLines[i]['y'] = clonedBestFitLines[i]['y'] + toleranceFactor;
            }
            return clonedBestFitLines;
        }
    }

    public onRangeChanged(event?) {
        if (event) {
            // fix for Bug #11314 updated to fetch exact selected range value from event
            this.rangeValue = event.value;
        }
        this.displayScatterGraph();
    }
}
