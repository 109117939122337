import { Component, Inject, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { SliicerCaseStudy } from 'app/shared/models/sliicer';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { AddNewStudyDialogData } from 'app/shared/models/sliicer/case-study';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'ads-sliicer-study-dialog',
    templateUrl: './sliicer-study-dialog.component.html',
    styleUrls: ['./sliicer-study-dialog.component.scss'],
})
export class SliicerStudyDailogComponent {
    @ViewChild('addNewStudyForm') private createStudyForm: NgForm;
    public isLoadingState: boolean;
    public existingStudyNameError: boolean;
    private dialogDismisString: string;
    constructor(
        public slicerdialog: MatDialogRef<SliicerStudyDailogComponent>,
        private translateService: TranslateService,
        private sliicerService: SliicerService,
        private snackBarNotificationService: SnackBarNotificationService,
        @Inject(MAT_DIALOG_DATA) public data: AddNewStudyDialogData,
        public router: Router,
    ) {}

    /** To create case study */
    public initializeCaseStudy(newStudyData) {
        const translateKeys: Array<string> = ['COMMON.DISMISS_TEXT'];

        this.translateService.get(translateKeys).subscribe((values) => {
            this.dialogDismisString = values['COMMON.DISMISS_TEXT'];
        });

        this.isLoadingState = true;
        const meta = {
            name: newStudyData.name,
            desc: newStudyData.description ? newStudyData.description : '',
        };
        this.sliicerService.createCaseStudy(this.data.customerId, meta).subscribe(
            (result: SliicerCaseStudy) => {
                if (result) {
                    const caseStudy = {
                        id: result.id,
                        name: result.meta.name,
                        description: result.meta.desc ? result.meta.desc : '',
                        createdBy: result.meta.authors[0].name,
                        StudyDate: undefined,
                        customerId: this.data.customerId,
                        createdOn: result.meta.created,
                    };
                    this.slicerdialog.close({ success: true, caseStudyDetails: caseStudy });
                }
            },
            (error) => {
                this.snackBarNotificationService.raiseNotification(
                    error.error,
                    this.dialogDismisString,
                    {
                        panelClass: 'custom-error-snack-bar',
                    },
                    false
                );
                this.slicerdialog.close({ success: false });
            },
        );
    }

    public studyNameChange(name: string) {
        this.existingStudyNameError = this.data.existingStudies.some(v => name && v.name && v.name.toLowerCase() === name.toLowerCase().trim());
    }
}
