<div class="locationDashbordGraphHeader">
    <mat-card-header>
        <div class="auto-margin">
            <span class="uptimeGraphHint uptime-hint">{{ 'COMMON.UPTIME_TEXT' | translate }} - </span>
            <span style="font-size: 13px">
                {{ oneDayStartDate | adsDate: customerDateFormat }} -
                {{ oneDayEndDate | adsDate: customerDateFormat }}</span
            >
        </div>
        <div class="app-flex-filler"></div>
    </mat-card-header>
</div>
<app-loader [isLoading]="isLoading"></app-loader>

<mat-card-content>
    <div *ngIf="data && uptimeChartData; else noChartData">
        <div class="dynamicGraph" [id]="uptimeChartContainer" (click)="navigateToReport('uptime')">
            <div #chartElement [chart]="uptimeChartOptions"></div>
        </div>
    </div>
    <ng-template #noChartData>
        <div class="notabledataFound custom-table print-nodata-space">
            <span>{{ 'LOCATION_DASHBOARD.NO_DATA_IN_GRAPH_MSG' | translate }}</span>
        </div>
    </ng-template>
</mat-card-content>
