import { Pipe, PipeTransform } from '@angular/core';
import { InstallationType } from 'app/shared/models/location-details';

/*
 * Filter out values that aren't currently selected and has unpickable set to true
 */
@Pipe({
    name: 'filterByUnpickable',
})
export class FilterByUnpickablePipe implements PipeTransform {
    public transform(installationTypeList: Array<InstallationType>, selectedValue = null): any[] {
        if (installationTypeList) {
            return installationTypeList.filter(
                ({ unpickable, value }: InstallationType) =>
                    !(unpickable && selectedValue && value !== selectedValue.value),
            );
        }
    }
}
