import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Config } from './config';
import { LocationGroup, LocationGroupSelector } from '../models/location-group';
import { Observable, of, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class LocationGroupService {
    public locationGroupChange = new EventEmitter<number>();

    public locationGroupEdited = new BehaviorSubject<number>(null);
    public locationGroupDeleted = new BehaviorSubject<number>(null);

    public locationGroupSelected = new BehaviorSubject<{ locationGroups: LocationGroup[] }>(null);

    public globalSelectableLocationGroups: Array<LocationGroupSelector> = [];

    /**
     * Represents the state of the location group selector in top-nav
     */
    private locationGroupSelectStatus = new BehaviorSubject<boolean>(false);

    private _locationGroupID: number;
    // triggered on create location group or update existing, subscribers will update location group lists
    public locGroupListUpdate$ = new Subject();
    constructor(public http: HttpClient) {}

    /**
     * Gets the location group disabled state.
     */
    public get isLocationGroupDisabled(): BehaviorSubject<boolean> {
        return this.locationGroupSelectStatus;
    }

    /**
     * Sets the location group disabled state.
     */
    public set locationGroupDisabledStatus(isDisabled: boolean) {
        this.locationGroupSelectStatus.next(isDisabled);
    }

    // getter and setter for locationGroupId
    public set locationGroupID(locationGroupID: number) {
        this._locationGroupID = locationGroupID;
        this.locationGroupChange.emit(locationGroupID);
    }

    public get locationGroupID(): number {
        return this._locationGroupID;
    }

    // Add & update location Group
    public postLocationGroup(customerId: number, locationGroup: LocationGroup) {
        const postUrl = Config.getUrl(Config.urls.locationGroup.postUrl);

        if (!locationGroup.locationGroupID) {
            return this.http.post<LocationGroup>(postUrl, locationGroup).pipe(
                map((res) => {
                    this.locationGroupCache = null;
                    this.locGroupListUpdate$.next(null);
                    this.locationGroupEdited.next(locationGroup.locationGroupID);
                    return res;
                }),
            );
        } else {
            return this.http.put<LocationGroup>(postUrl, locationGroup).pipe(
                map((response) => {
                    this.locationGroupCache = null;
                    this.locGroupListUpdate$.next(null);
                    this.updateCachedLocationGroup(locationGroup);
                    this.locationGroupEdited.next(locationGroup.locationGroupID);
                    return response;
                }),
            );
        }
    }

    private locationGroupCache?: {
        customerId: number;
        res$?: Observable<{ locationGroups: LocationGroup[] }>;
        res?: { locationGroups: LocationGroup[] }
    }
    public getLocationGroups(customerId: number): Observable<any> {
        const getURL = Config.getUrl(Config.urls.locationGroup.getUrl + '?customerId=' + customerId);
        if(this.locationGroupCache && this.locationGroupCache.customerId === customerId) {
            if(this.locationGroupCache.res) {
                return of(this.locationGroupCache.res);
            } else {
                return this.locationGroupCache.res$;
            }
        } else {
            if(!this.locationGroupCache) {
                this.locationGroupCache = {
                    customerId: customerId,
                }
            } else if(this.locationGroupCache.customerId !== customerId) {
                this.locationGroupCache.customerId = customerId;
                this.locationGroupCache.res = null;
            }

            const getLocGroups$ = this.http.get<{ locationGroups: LocationGroup[] }>(getURL).pipe(
                tap((response) => {
                    if (response.locationGroups) {
                        this.clearCachedLocationGroups();
                        response.locationGroups.forEach(v => this.addCachedLocationGroup(v));
                    }
                    this.locationGroupCache.res = response;
                    this.locationGroupSelected.next(response);
                }),
            );

            this.locationGroupCache.res$ = getLocGroups$;
            
            return getLocGroups$;
        }
    }

    public getRainLocationGroups(customerId: number) {
        const getURL = Config.urls.rainLocGroups + '?cid=' + customerId;
        return this.http.get<{ locationGroups: LocationGroup[] }>(getURL);
    }

    public deleteLocationGroups(locationGroupID: number): Observable<any> {
        this.locationGroupCache = null;
        this.removeCachedLocationGroup(locationGroupID);
        return this.http
            .delete<LocationGroup>(Config.getUrl(Config.urls.locationGroup.deleteUrl + locationGroupID))
            .pipe(map((response) => {
                this.locationGroupCache = null;
                this.locGroupListUpdate$.next(null);
                this.locationGroupDeleted.next(locationGroupID);
                return <LocationGroup>response;
            }));
    }

    public getLocationGroupDetails(customerId: number, locationGroupId: number): Observable<{}> {
        const getURL = Config.getUrl(
            Config.urls.locationGroup.getUrl + '/' + locationGroupId + '?customerId=' + customerId,
        );
        return this.http.get<LocationGroup>(getURL).pipe(map((response) => <LocationGroup>response));
    }

    public markActiveLocationGroup(locationGroupId: number, locationGroups: Array<LocationGroup>): void {
        if (!locationGroupId) {
            return;
        }

        // data.locationGroups.forEach((group: LocationGroup) => {
        for (let i = 0; i < locationGroups.length; i++) {
            const group = locationGroups[i];

            if (group.locationGroupID === locationGroupId) {
                group.isCurrentlySelected = true;

                break;
            }
        }
    }

    private createLocationGroupSelector(locationGroup: LocationGroup) {
        return {
            id: locationGroup.locationGroupID,
            locations: locationGroup.locations,
            name: locationGroup.name,
            description: locationGroup.description,
            isChecked: false,
        };
    }

    private addCachedLocationGroup(locationGroup: LocationGroup) {
        this.globalSelectableLocationGroups.push(this.createLocationGroupSelector(locationGroup));
    }

    private removeCachedLocationGroup(locationGroupID: number) {
        const groupIndex = this.globalSelectableLocationGroups.findIndex(lg => lg.id === locationGroupID);
        this.globalSelectableLocationGroups.splice(groupIndex, 1);
    }

    private updateCachedLocationGroup(locationGroup: LocationGroup) {
        const groupIndex = this.globalSelectableLocationGroups.findIndex(lg => lg.id === locationGroup.locationGroupID);
        const lg = this.createLocationGroupSelector(locationGroup);
        this.globalSelectableLocationGroups[groupIndex] = lg;
    }

    private clearCachedLocationGroups() {
        this.globalSelectableLocationGroups = new Array<LocationGroupSelector>();
    }

    public setLocationGroupsGlobalSelectable(locationGroups: LocationGroup[]) {
        this.clearCachedLocationGroups();

        this.globalSelectableLocationGroups.push({
            id: 0,
            locations: [],
            name: 'All',
            description: '',
            isChecked: false,
        });
        if (locationGroups) {
            locationGroups.forEach((locationGroup, index) => {
                this.addCachedLocationGroup(locationGroup);
            });
        }
    }

}
