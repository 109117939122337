import { Component, ViewEncapsulation, ChangeDetectorRef, Inject, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GISService } from 'app/shared/services/gis-service';
import { GisServiceList, ServiceTypes } from 'app/shared/models/gis-service-list';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UiUtilsService } from '../../../shared/utils/ui-utils.service';
import { Subscription } from 'rxjs';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { customerEditorQueryParam } from 'app/shared/models/customer';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';

@Component({
    selector: 'ads-prism-gis-add-edit',
    templateUrl: './ads-prism-gis-add-edit.component.html',
    styleUrls: ['./ads-prism-gis-add-edit.component.scss'],
    encapsulation: ViewEncapsulation.None,
    //changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdsPrismGISAddEditComponent implements OnDestroy {
    public PAGE_TEXT: any;
    public hasBaseDropZoneOver: boolean;
    public hasError: boolean;
    public errorMessage: string;
    public cid: string;
    public uploading: boolean;
    public editMode: boolean;
    public validated = false;
    public gisService: GisServiceList;
    public gisServiceurl: string;
    public gisServiceAuthurl: string;
    public gisServiceauth = '';
    public gisServicetype: ServiceTypes;
    public gisServicemethod: 'basic' | 'custom' = null;
    public gisServiceusername = '';
    public gisServicepassword = '';

    public urlPattern = REGEX_CONFIG.urlPattern;

    // TODO: Bug #19937 - getCapabilities. Code generated by old team for WMS or WFS service types. They are not used, if will be needed then rewrite this.
    // public servicetypelist = [{ name: ServiceTypes.WMS, text: 'WMS' }, { name: ServiceTypes.WFS, text: 'WFS' }, { name: ServiceTypes.MAPS, text: 'MapServer' }, { name: ServiceTypes.FEATURE, text: 'FeatureServer' }];
    public servicetypelist = [
        { name: ServiceTypes.MAPS, text: 'MapServer' },
        { name: ServiceTypes.FEATURE, text: 'FeatureServer' },
    ];

    public authMethodlist = [
        { name: 'basic', text: 'BASIC' },
        { name: 'custom', text: 'CUSTOM' },
    ];
    @ViewChild('servicePassword') public el: ElementRef;

    public customerId = 0;
    private subscriptions = new Subscription();
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public refDialog: MatDialogRef<AdsPrismGISAddEditComponent>,
        public cdr: ChangeDetectorRef,
        public translateService: TranslateService,
        public activatedRoute: ActivatedRoute,
        public snackBar: MatSnackBar,
        public GISService: GISService,
        public uiUitlsService: UiUtilsService,
        public matDialog: MatDialog,
        private domOperationUtilsService: DomOperationUtilsService
    ) {
        this.subscriptions.add(
            this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
                this.customerId = Number(params.get(customerEditorQueryParam));
                this.setData();
            }),
        );

        this.translateService.get('GIS_ADD_EDIT').subscribe((res: string) => {
            this.PAGE_TEXT = res;
        });

        this.subscriptions.add(
            this.domOperationUtilsService.gisDialogAuthMethod.subscribe((method: 'basic' | 'custom') => this.gisServicemethod = method)
        );
    }

    /**
     * Closing the Popup window
     */
    public close(servicesaved: boolean) {
        this.refDialog.close({ success: servicesaved });
    }

    /**
     * setting editing mode and Data for editing
     */
    public setData() {
        if (this.data) {
            this.gisService = this.data;
            this.gisServicetype = this.gisService.serviceType;
            this.gisServiceurl = this.gisService.url;
            this.gisServiceAuthurl = this.gisService.authURL;
            this.gisServicemethod = (this.gisService.authMethod as 'basic' | 'custom');
            this.domOperationUtilsService.gisDialogAuthMethod.next(this.gisServicemethod);
            this.gisServiceusername = this.gisService.userName;
            this.gisServicepassword = this.gisService.password;
            this.gisServiceauth = this.gisService.authParams;
            this.editMode = true;
            /*this.gisServiceurl = this.data.url;
            this.gisServiceauth = this.data.authparam;
            this.gisServicetype = this.data.type;*/
        }
    }

    public uiSave() {
        if (this.gisServiceurl && this.gisServiceurl.trim().startsWith('https://')) {
            this.doSave();
        } else {
            this.matDialog
                .open(ConfirmationDialogComponent, {
                    disableClose: true,
                    data: {
                        title: this.PAGE_TEXT.WARNING,
                        message: this.PAGE_TEXT.URL_IS_NOT_HTTPS,
                        okText: this.PAGE_TEXT.OK,
                        cancelText: this.PAGE_TEXT.CANCEL,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.whichButtonWasPressed === 'ok') {
                        this.doSave();
                    }
                });
        }
    }

    /**
     * Saving service Data to Server
     */
    public doSave() {
        if (this.editMode) {
            this.gisService.serviceType = this.gisServicetype;
            this.gisService.url = this.gisServiceurl;
            this.gisService.authURL = this.gisServiceAuthurl;
            this.gisService.customerId = this.customerId;
            this.gisService.authparam = this.gisServiceauth;
            this.gisService.authMethod = this.gisServicemethod;
            this.gisService.userName = this.gisServiceusername;
            this.gisService.password = this.gisServicepassword;
            this.gisService.authParams = this.gisServiceauth;
        } else {
            this.gisService = {
                serviceType: this.gisServicetype,
                url: this.gisServiceurl,
                authURL: this.gisServiceAuthurl,
                authparam: this.gisServiceauth,
                customerId: this.customerId,
                authParams: this.gisServiceauth,
                authMethod: this.gisServicemethod,
                userName: this.gisServiceusername,
                password: this.gisServicepassword,
            };
        }
        if (this.editMode) {
            this.GISService.updateGISService(this.gisService).subscribe((res) => {
                this.snackBar.open('Success!', 'Service Updated Successfully.', {
                    duration: 10000,
                    panelClass: 'custom-success-snack-bar',
                });
                // #23237 TODO: workaround for GET after POST return HTTP 204 NO CONTENT
                setTimeout(() => {
                    this.close(true);
                }, 1000);
            });
        } else {
            this.GISService.addGISService(this.gisService).subscribe((res) => {
                this.snackBar.open('Success!', 'Service Added Successfully.', {
                    duration: 10000,
                    panelClass: 'custom-success-snack-bar',
                });
                // #23237 TODO:workaround for GET after POST return HTTP 204 NO CONTENT
                setTimeout(() => {
                    this.close(true);
                }, 1000);
            });
        }
    }

    public authMethodChange(event) {
        this.gisServicemethod = event.name;
        this.domOperationUtilsService.gisDialogAuthMethod.next(this.gisServicemethod);
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
    /*
     * Function for Validating service
     */
    /*public validateService(serviceDetail: GisServiceList) {
      if ( serviceDetail.type === 'wms') {
          return this.GISService.getLayerList(serviceDetail).subscribe((res) => {
              if (res) {
                return 'true';
              } else { return 'false';}
          })
      }
    }*/
}
