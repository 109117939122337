import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild } from '@angular/core';
import { FlexmonsterPivot } from 'ngx-flexmonster';
import Flexmonster, { Toolbar } from 'flexmonster';
import { environment } from 'app/environments/environment';
import { Subscription } from 'rxjs';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerCaseStudy } from 'app/shared/models/sliicer';
import { DRY_DAY_CHART_MEASURES, DRY_DAY_GRID_MEASURES, DryDayProperties, createPivotMapping, csvToJSON, getDryDayPivotOptions, getDryDayPivotSlice, getKeyByProp, getExistingProps } from './dry-day-pivot-table.constants';
import { DRY_DAY_STATS_EXPORT_NAME, EXCLUDED_CHART_ITEMS, EXPORT_CSV_ID, EXPORT_EXCEL_ID, EXPORT_HTML_ID, EXPORT_IMG_ID, EXPORT_PDF_ID, EXPORT_VAULT_ID, TOOLBAR_CHARTS_TAB_ID, TOOLBAR_EXPORT_TAB_ID, TOOLBAR_FIELDS_TAB_ID, TOOLBAR_FORMAT_TAB_ID, TOOLBAR_FULLSCREEN_TAB_ID, TOOLBAR_GRID_TAB_ID, TOOLBAR_OPTIONS_TAB_ID, VAULT_ICON } from '../flexmonster.constant';
import { DatePipe } from '@angular/common';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { SNACK_BAR_NOTIFICATION_TIMEOUT } from 'app/shared/models/sliicer-data';
import { TranslateService } from '@ngx-translate/core';
const blankText = '(blank)';

@Component({
	selector: 'app-dry-day-pivot-table',
	templateUrl: './dry-day-pivot-table.component.html',
	styleUrls: ['./dry-day-pivot-table.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DryDayPivotTableComponent implements OnInit, OnDestroy {
	public license = environment.flexmonsterLicense;
	public report: Object;
	@ViewChild('pivot') private pivotTable: FlexmonsterPivot;

	public isLoading = true;

	private customDateFormat: string;
	private caseStudyId: string;
	private customerId: number;

	private data: any[];

	private studyDateForExportNaming: string;

	private initialExportTabs: Flexmonster.ToolbarTab[];
	private subscriptions: Subscription[] = [];
	constructor(
		private sliicerService: SliicerService,
		private dateutilService: DateutilService,
		private datePipe: DatePipe,
		private snackBarNotificationService: SnackBarNotificationService,
		private translate: TranslateService
	) { }


	ngOnInit(): void {
		const dateFormat = this.dateutilService.getFormat();
		const is12HourFormat = this.dateutilService.timeFormat.getValue() !== 'hh:mm:ss';
		this.customDateFormat = is12HourFormat ? `${dateFormat}, ${'hh:mm tt'}` : `${dateFormat}, ${'HH:mm'}`;

		this.subscriptions.push(this.sliicerService.studyDetailsData$.subscribe((study: SliicerCaseStudy) => {
			this.caseStudyId = study.id;
			this.customerId = study.customerId;

			const format = this.dateutilService.getFormat().replace(/\//g, '');
			const start = new Date(study.config.startDate);
			const end = new Date(study.config.endDate);

			this.studyDateForExportNaming = `${this.datePipe.transform(start, format)}-${this.datePipe.transform(end, format)}`;

			this.getDryDayData();
		}));
	}

	public customizeToolbar(toolbar: Toolbar) {
		toolbar.showShareReportTab = true;

		const tabs = this.getTabs(toolbar);

		toolbar.getTabs = function () {
			return tabs;
		}
	}

	private getTabs(toolbar: Toolbar, isGridView = false) {
		const tabs = toolbar.getTabs();

		const exportTab = tabs.find(v => v.id === TOOLBAR_EXPORT_TAB_ID);

		if (!this.initialExportTabs) {
			this.initialExportTabs = exportTab.menu;
		}

		const chartsTab = tabs.find(v => v.id === TOOLBAR_CHARTS_TAB_ID);
		const gridTab = tabs.find(v => v.id === TOOLBAR_GRID_TAB_ID);

		const formatTab = tabs.find(v => v.id === TOOLBAR_FORMAT_TAB_ID);
		const optionsTab = tabs.find(v => v.id === TOOLBAR_OPTIONS_TAB_ID);
		const fieldsTab = tabs.find(v => v.id === TOOLBAR_FIELDS_TAB_ID);
		const fullScreenTab = tabs.find(v => v.id === TOOLBAR_FULLSCREEN_TAB_ID);

		this.formatExportsTab(exportTab, isGridView);

		gridTab.handler = this.onGridView.bind(this);

		chartsTab.menu = chartsTab.menu.filter(v => !EXCLUDED_CHART_ITEMS.includes(v.id));
		chartsTab.menu.forEach((item) => {
			item.handler = this.onChartView.bind(this);
		});

		return [
			exportTab,
			{ divider: true } as any,
			chartsTab,
			gridTab,
			formatTab,
			optionsTab,
			fieldsTab,
			{ divider: true } as any,
			fullScreenTab
		];
	}

	private getDryDayData() {
		this.sliicerService.getStudyDryDayData(this.customerId, this.caseStudyId).subscribe(csv => {
			if (!csv) {
				this.isLoading = false;
				return;
			}

			const json = csvToJSON(csv);

			// need to remove extra ""
			const data = json.map(item => {
				return Object.keys(item).reduce((acc, k) => {
					const key = k.replace(/"/g, '').trim();

					acc[key] = item[k];

					return acc;
				}, {});
			});

			this.data = data.slice(0, data.length - 1);

			this.generateTable(this.data);
		},
			() => { this.isLoading = false; }
		)
	}

	private onChartView(type: string) {
		this.pivotTable.flexmonster.showCharts(type, true);

		const report = this.pivotTable.flexmonster.getReport() as Flexmonster.Report;
		const item = this.data[0];

		(report.slice as Flexmonster.Slice).measures = getExistingProps(DRY_DAY_CHART_MEASURES, item).map(prop => ({ uniqueName: getKeyByProp(item, prop) }));
		this.pivotTable.flexmonster.setReport(report);

		const tabs = this.getTabs(this.pivotTable.flexmonster.toolbar, false);
		this.pivotTable.flexmonster.toolbar.getTabs = function () { return tabs; }
		this.pivotTable.flexmonster.toolbar['create']();
	}

	private onGridView() {
		this.pivotTable.flexmonster.showGrid();

		const report = this.pivotTable.flexmonster.getReport() as Flexmonster.Report;
		const item = this.data[0];

		(report.slice as Flexmonster.Slice).measures = getExistingProps(DRY_DAY_GRID_MEASURES, item).map(prop => ({ uniqueName: getKeyByProp(item, prop) }));
		this.pivotTable.flexmonster.setReport(report);

		const tabs = this.getTabs(this.pivotTable.flexmonster.toolbar, true);
		this.pivotTable.flexmonster.toolbar.getTabs = function () { return tabs; }
		this.pivotTable.flexmonster.toolbar['create']();
	}

	private formatExportsTab(tab: Flexmonster.ToolbarTab, isGridView: boolean) {
		let exportTypes: string[] = [];

		if (isGridView) {
			exportTypes = [EXPORT_HTML_ID, EXPORT_CSV_ID, EXPORT_EXCEL_ID];
		} else {
			exportTypes = [EXPORT_HTML_ID, EXPORT_CSV_ID, EXPORT_EXCEL_ID, EXPORT_IMG_ID, EXPORT_PDF_ID];
		}

		tab.menu = this.initialExportTabs.filter(menuItem => {
			if (!exportTypes.includes(menuItem.id)) {
				return false;
			}

			this.setExportHandler(menuItem);

			return true;
		});

		if (isGridView) {
			tab.menu.unshift({
				title: 'To Vault(Raw)',
				id: EXPORT_VAULT_ID,
				icon: VAULT_ICON,
				handler: () => this.exportVaultHandler()
			});
		}
	}

	private setExportHandler(menuItem: Flexmonster.ToolbarTab) {
		switch (menuItem.id) {
			case EXPORT_HTML_ID: {
				menuItem.handler = this.exportToHTMLhandler();
				break;
			}
			case EXPORT_EXCEL_ID: {
				menuItem.handler = this.exportToExcelHandler()
				break;
			}
			case EXPORT_CSV_ID: {
				menuItem.handler = this.exportToCsvHandler();
				break;
			}
			case EXPORT_IMG_ID: {
				menuItem.handler = this.exportToImageHandler();
				break;
			}
			case EXPORT_PDF_ID: {
				menuItem.handler = this.exportToPdfHandler();
				break;
			}
			default: break;
		}
	}

	private exportToExcelHandler() {
		this.removeBlanks();
		return () => this.pivotTable.flexmonster.exportTo('excel', {
			filename: `${DRY_DAY_STATS_EXPORT_NAME}-${this.studyDateForExportNaming}`,
			excelSheetName: `${DRY_DAY_STATS_EXPORT_NAME}-${this.studyDateForExportNaming}`,
		});

	}

	private exportToCsvHandler() {
		this.removeBlanks();
		return () => this.pivotTable.flexmonster.exportTo('csv', {
			filename: `${DRY_DAY_STATS_EXPORT_NAME}-${this.studyDateForExportNaming}`,
		});
	}

	private exportToHTMLhandler() {
		return () => this.pivotTable.flexmonster.exportTo('html', {
			filename: `${DRY_DAY_STATS_EXPORT_NAME}-${this.studyDateForExportNaming}`,
		});
	}

	private exportToImageHandler() {
		return () => this.pivotTable.flexmonster.exportTo('image', {
			filename: `${DRY_DAY_STATS_EXPORT_NAME}-${this.studyDateForExportNaming}`,
		});
	}

	private exportToPdfHandler() {
		return () => this.pivotTable.flexmonster.exportTo('pdf', {
			filename: `${DRY_DAY_STATS_EXPORT_NAME}-${this.studyDateForExportNaming}`,
		});
	}

	private exportVaultHandler() {
		const successMessage = this.translate.instant('SLIICER_TABLE.SLICER_SUMMARY.RESULTS.EXPORT_DRYDAYSTATS_SAVE_MESSAGE');
		const dismissText = this.translate.instant('COMMON.DISMISS_TEXT');
		const errorText = this.translate.instant('VAULT.VAULT_TELEMETRY.EXPORT.EXPORT_ERR_SNACKBAR_MSG');

		this.isLoading = true;
		this.sliicerService
			.vaultExportDryDayStatistics(this.customerId, this.caseStudyId)
			.subscribe(
				(res) => {
					this.isLoading = false;
					this.snackBarNotificationService.raiseNotification(
						successMessage,
						dismissText,
						{
							duration: SNACK_BAR_NOTIFICATION_TIMEOUT,
						},
						true,
					);
				},
				(err) => {
					this.snackBarNotificationService.raiseNotification(errorText, dismissText, {
						panelClass: 'custom-error-snack-bar',
					}, false);
					this.isLoading = false;
				},
			);

	}

	private removeBlanks() {
		this.pivotTable.flexmonster.customizeCell((cell, data) => {
		  if (data.label === blankText) {
			cell.text = '';
		  }
		});
	}

	private generateTable(data) {
		const mapping = createPivotMapping(data[0], this.customDateFormat);

		this.report = {
			options: getDryDayPivotOptions(this.customDateFormat, getKeyByProp(data[0], DryDayProperties.netBaseInfl)),
			dataSource: { data, mapping },
			slice: getDryDayPivotSlice(data[0])
		};

		this.isLoading = false;
		this.pivotTable.flexmonster.setReport(this.report);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(v => v.unsubscribe());
	}
}
