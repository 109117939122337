export interface UpdateInfo {
    title: string;
    values: string[];
    appendTitle?: string;
}

export interface ChangesAction {
    action: UpdatesAction;
}

export enum UpdatesAction {
    applyChanges = 'applyChanges',
    undoChanges = 'undoChanges'
}
