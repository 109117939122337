import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, ViewEncapsulation, Input, SimpleChanges, OnChanges, OnDestroy, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { IComponentDialog } from 'app/shared/models/comopnent-cofirmation';
import { AddEventDialogData, EventFilters, EventModel, EventTree, EVENT_TYPES } from 'app/shared/models/event';
import { LocationArgs } from 'app/shared/models/locations';
import { Selectable } from 'app/shared/models/selectable';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { EventService } from 'app/shared/services/event.service';
import { Subscription } from 'rxjs';
import { AddEventDialogComponent } from '../add-event-dialog/add-event-dialog.component';
import { UsersService } from '../admin/users.service';
import { TranslateService } from '@ngx-translate/core';

export interface EventRangeFilters {
    startDate?: Date;
    endDate?: Date;
    fromEdit?: boolean;
}

@Component({
    selector: 'app-event-widget',
    templateUrl: './event-widget.component.html',
    styleUrls: ['./event-widget.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EventWidgetComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @Input() locationGroupID: number;
    @Input() events: EventModel[] = [];
    @Input() customerID: number;
    @Input() toggleState: boolean;
    @Input() includeInactiveLocations: boolean;
    @Input() showEventSearch: boolean;
    @Input() userPermission: boolean;
    @Input() availablePageHint: boolean;

    @Output() updateEvents = new EventEmitter<EventRangeFilters>();
    @Output() GISevent = new EventEmitter<EventModel>();

    public customerDateFormat: string;
    public tileColumns = ['description', 'locations', 'startedAt', 'duration', 'gis'];
    public reportColumns = ['description', 'type', 'locations', 'startedAt', 'duration', 'gis'];

    public treeData: EventTree[] = [];
    public locations: Selectable[] = [];
    public filterOptions: EventFilters = {
        startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate(), 0, 0, 0),
        endDate: new Date(new Date().setHours(23,59,59,999)),
        selectedTypes: EVENT_TYPES.map(v => ({ ...v, isChecked: true })),
        types: EVENT_TYPES,
        durationStartHours: '00',
        durationStartMinutes: '00'
    };
    public gisEventGuid: string;
    public highlightedEventGuid: string;

    public treeControl: FlatTreeControl<EventTree>;
    public dataSource: MatTreeFlatDataSource<FlatTreeControl<EventTree>, MatTreeFlattener<EventModel, EventTree, () => boolean> | EventTree>;

    public paginatedEvents: EventModel[] = [];
    public filteredEvents: EventModel[] = [];
    public paginatedTree: EventTree[] = [];
    public filteredTree: EventTree[] = [];

    // used to generate filter options
    public eventsForFilters: EventModel[] = [];

    private treeFlattener: MatTreeFlattener<EventModel, EventTree, () => boolean>;
    private subscriptions = new Subscription();
    constructor(
        private usersService: UsersService,
        private dateutilService: DateutilService,
        private dialog: MatDialog,
        private eventService: EventService,
        private translateService: TranslateService,
    ) {

        this.treeControl = new FlatTreeControl<EventTree>(
            node => node.level, node => node.expandable);

        this.treeFlattener = new MatTreeFlattener(
            this.transformer, node => node.level,
            node => node.expandable, node => node.children as EventModel[]);

        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener as any);
    }

    ngOnInit(): void {
        this.setDateFormat();
        this.setDefaultDurations(this.events);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.locationGroupID || changes.customerID || changes.includeInactiveLocations) {
            this.getLocations();
        }

        if ((changes.events && changes.events.currentValue) || changes.toggleState) {
            const events = changes.events;
            this.formatEvents(events ? events.currentValue : this.events);
        }
    }

    public emitUpdateEvents(fromEdit = false) {
        const filters: EventRangeFilters = {
            fromEdit: fromEdit
        }

        if(this.filterOptions.startDate) {
            filters.startDate = this.filterOptions.startDate;
        }
        if(this.filterOptions.endDate) {
            filters.endDate = this.filterOptions.endDate;
        }
        this.updateEvents.emit(filters);
    }

    public editEvent(event: EventModel) {
        this.setHighlightedEventGuid(event.guid);
        const editSubs = this.dialog.open(AddEventDialogComponent, {
            data: ({
                customerId: this.customerID,
                includeInactiveLocations: this.includeInactiveLocations,
                locationGroupId: this.locationGroupID,
                events: this.events,
                event,
                hasPermission: this.userPermission,
            } as AddEventDialogData),
            disableClose: true
        }).afterClosed().subscribe((result: boolean) => {
            this.setHighlightedEventGuid(this.gisEventGuid);
            if (result) {
                this.emitUpdateEvents(true);
            }
        });

        this.subscriptions.add(editSubs);
    }

    public deleteEvent(event: EventModel) {
        this.setHighlightedEventGuid(event.guid);
        const confirmationData: IComponentDialog = {
            cancelText: this.translateService.instant('COMMON.CANCEL'),
            okText: this.translateService.instant('COMMON.CONFIRM_BTN'),
            message: this.translateService.instant('HOME.EVENT_WIDGET.DELETE_EVENT_MESSAGE'),
            title: this.translateService.instant('HOME.EVENT_WIDGET.DELETE_EVENT_TITLE')
        }

        const deleteSubs = this.dialog.open(ConfirmationDialogComponent, {
            data: confirmationData
        }).afterClosed().subscribe((result) => {
            this.setHighlightedEventGuid(this.gisEventGuid);
            if (result && result.whichButtonWasPressed === 'ok') {
                this.eventService.deleteEvent(this.customerID, event.guid).subscribe(() => this.emitUpdateEvents(false));
            }
        });

        this.subscriptions.add(deleteSubs);
    }

    public setGISevent(event: EventModel) {
        if (!event || this.gisEventGuid === event.guid) {
            this.gisEventGuid = null;
            this.GISevent.emit(null);

            return;
        }

        this.gisEventGuid = event.guid;
        this.GISevent.emit(event);
        this.setHighlightedEventGuid(this.gisEventGuid);
    }

    public optionsChange(event: EventFilters) {
        this.filterOptions = { ...this.filterOptions, ...event };

        if(event.datesChange) {
            this.emitUpdateEvents(false);
        }

        this.paginator.firstPage();
        this.formatEvents(this.events);
    }

    public pageEvent(event: PageEvent) {
        this.paginatedEvents = this.getPaginatedData(this.filteredEvents, event.pageIndex, event.pageSize);
        this.paginatedTree = this.getPaginatedData(this.filteredTree, this.paginator.pageIndex, this.paginator.pageSize);
        this.dataSource.data = this.paginatedTree as any;
    }

    public setDefaultDurations(events: EventModel[]) {
        const maxDuration = events.reduce((acc, curr) => {
            const formatted = curr.duration.match(/(\d+)\:(\d+)/);

            if (!formatted) {
                return acc;
            }
            const [d, hours, mins] = formatted;

            if (Number(hours) > acc.hours) {
                acc.hours = Number(hours);
                acc.minutes = Number(mins);
            } else if (Number(hours) === acc.hours && Number(mins) > acc.minutes) {
                acc.minutes = Number(mins);
            }

            return acc;
        }, { hours: 0, minutes: 0 });

        this.filterOptions = {
            ...this.filterOptions,
            durationEndHours: this.eventService.formatSingleCharNumber(String(maxDuration.hours)),
            durationEndMinutes: this.eventService.formatSingleCharNumber(String(maxDuration.minutes))
        }
    }

    public sortData(event: Sort) {
        if (event.active === "startedAt") {
            this.events.sort((a, b) => {
                if (event.direction === 'asc' || !event.direction) {
                    return (new Date(a.start).getTime() > new Date(b.start).getTime()) ? -1 : 1;
                }

                return (new Date(a.start).getTime() < new Date(b.start).getTime()) ? -1 : 1;
            });
        }

        if (event.active === 'duration') {
            this.events.sort((a, b) => {
                if (a.duration.includes('NaN') || b.duration.includes('NaN')) return 1;
                // get hours and minutes from duration, ah = hours in "a" element
                const [d, ah, am] = a.duration.match(/(\d+)\:(\d+)/);
                const [v, bh, bm] = b.duration.match(/(\d+)\:(\d+)/);

                if (Number(ah) === Number(bh)) {
                    return (event.direction === 'asc' || !event.direction) ? (Number(am) - Number(bm)) : (Number(bm) - Number(am));
                }

                return (event.direction === 'asc' || !event.direction) ? (Number(ah) - Number(bh)) : (Number(bh) - Number(ah));
            });
        }

        this.formatEvents(this.events);
    }

    private transformer = (node: EventModel, level: number): EventTree => ({
        expandable: !!node.children && node.children.length > 0,
        ...node,
        level: level,
    });

    private setDateFormat() {
        const dateSubs = this.dateutilService.dateFormat.subscribe(() => {
            // Getting customer dateformat from dateUtil Service
            this.customerDateFormat =
                this.dateutilService.getFormat() + ' ' + this.dateutilService.getTimeFormatWithoutSeconds();
        });

        this.subscriptions.add(dateSubs);
    }

    private formatEvents(events: EventModel[]) {
        this.events = events.map(v => ({ ...v, duration: this.eventService.getDuration(v), displayType: EVENT_TYPES.find(i => i.id === v.etype).name }));
        this.treeData = this.eventService.buildEventsTree(this.events);
        const { selectedLocations: locs, selectedTypes: types, startDate, endDate } = this.filterOptions;

        // used to generate filter options
        this.eventsForFilters = this.events.filter((event: EventModel) => {
            const withinDateRange = this.eventService.checkIfEventWithinDateRange(event, startDate, endDate);
            const withinDuration = this.eventService.checkIfEventWithinDuration(event, this.filterOptions);

            return withinDateRange && withinDuration;
        });
        this.filteredEvents = this.eventsForFilters.filter((event: EventModel) => {
            const locSelected = !locs || locs.some(v => event.lids.includes(v.id));
            const typeSelected = types.some(v => event.etype === v.id);

            return locSelected && typeSelected;
        });

        this.filteredTree = this.eventService.buildEventsTree(this.filteredEvents);

        if (!this.paginator) {
            this.paginatedEvents = this.getPaginatedData(this.filteredEvents, 0, 5);
            this.paginatedTree = this.getPaginatedData(this.filteredTree, 0, 5);
        } else {
            this.paginatedEvents = this.getPaginatedData(this.filteredEvents, this.paginator.pageIndex, this.paginator.pageSize);
            this.paginatedTree = this.getPaginatedData(this.filteredTree, this.paginator.pageIndex, this.paginator.pageSize);
        }

        this.dataSource.data = this.paginatedTree as any;
    }

    private getPaginatedData(events: EventModel[], pageIndex: number, pageSize: number): EventModel[] {
        return events.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize);
    }

    private getLocations() {
        const locationArgs = <LocationArgs>{
            customerId: this.customerID,
            IncludeInactiveLocations: this.includeInactiveLocations,
            locationGroupId: this.locationGroupID
        };

        this.usersService.getLocationsList(locationArgs).subscribe(data => {
            this.locations = data.map(v => ({ name: v.locationName, id: v.locationId, isChecked: true }));
            this.filterOptions = { ...this.filterOptions, selectedLocations: [...this.locations] };

            this.formatEvents(this.events);
        });
    }

    private setHighlightedEventGuid(guid: string | null) {
        this.highlightedEventGuid = guid;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
