import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { UsersService } from 'app/pages/admin/users.service';

@Component({
    selector: 'app-other-monitors-info',
    templateUrl: './other-monitors-info.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherMonitorsInfoComponent implements OnInit {
    @Input() monitorSeries: string;

    public isMonitorEditor = false;
    constructor(private usersService: UsersService) {}

    ngOnInit() {
        this.isMonitorEditor = this.usersService.isMonitorEditor.getValue();
    }
}
