<div class="setUpStudy">
    <div class="custom-vertical-tab">
        <mat-tab-group class="ink-bar-none">
            <mat-tab label="{{ 'SLIICER.RAINFALL_TAB.HYETOGRAPH.TAB_TITLE' | translate }}" [disabled]="isUpdateAvailable || !ddfTableIsValid">
                <app-rainfall-hytograph></app-rainfall-hytograph>
            </mat-tab>
            <mat-tab label="{{ 'SLIICER.RAINFALL_TAB.RAINFALL_EVENTS.TAB_TITLE' | translate }}" [disabled]="isUpdateAvailable || !ddfTableIsValid">
                <app-rain-table></app-rain-table>
            </mat-tab>
            <mat-tab label="{{ 'SLIICER.RAINFALL_TAB.DDF.TAB_TITLE' | translate }}" [disabled]="isUpdateAvailable || !ddfTableIsValid">
                <app-rainfall-ddf
                    #rainfallDdfComponent
                    [customerId]="customerId"
                    (ddfTableChange)="ddfTableChange($event)"
                ></app-rainfall-ddf>
            </mat-tab>
            <mat-tab label="{{ 'SLIICER.RAINFALL_TAB.MAX_RETURN.TAB_TITLE' | translate }}" [disabled]="isUpdateAvailable || !ddfTableIsValid">
                <app-rainfall-max-return></app-rainfall-max-return>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
