import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { Config } from './config';
import { BatteryStatus, BatteryStatusList, BatteryStatusReportArgs } from '../models/battery-status';
import { BatteryTotalCount } from '../models/battery-total-count';
import { map } from 'rxjs/operators';

@Injectable()
export class BatteryStatusService {
    public batteryTotalCount: BatteryTotalCount;
    public batteryStatusData = new Array<BatteryStatus>();

    constructor(public http: HttpClient, public authService: AuthService) {}

    public getBatteryStatus(params: BatteryStatusReportArgs) {
        // creating HttpParams for HttpClient request
        let httpParams: HttpParams = new HttpParams();
        // setting all the existing key value of param to HttpParams
        Object.keys(params).forEach(function (key) {
            if (key === 'Sort' || key === 'StartPage' || key === 'PageSize' || key === 'SearchValue') {
                httpParams = httpParams.append('Paging.' + key, params[key].toString());
                return;
            }
            if (key !== 'LocationGroupID') {
                httpParams = httpParams.append(key, params[key]);
            }
        });

        if (params.LocationGroupID > 0) {
            httpParams = httpParams.append('LocationGroupID', params.LocationGroupID.toString());
        }

        // if (params.LocationID && params.LocationID.length > 0) {
        //     httpParams = httpParams.append('LocationID', params.LocationID.toString());
        // }

        return this.http.get(Config.urls.batteryStatusUrl, { params: httpParams }).pipe(
            map((res) => {
                if (res) {
                    return <BatteryStatusList>{
                        batteryStatusList: res['listBatteries'],
                        batteryGraphStatusList: res['listBatteriesStatus'],
                        count: res['count'],
                    };
                } else {
                    return null;
                }
            }),
        );
    }

    public getBatteryStatusTotal(params: BatteryStatusReportArgs): Observable<BatteryTotalCount> {
        // creating HttpParams for HttpClient request

        let httpParams: HttpParams = new HttpParams();

        // setting all the existing key value of param to HttpParams

        Object.keys(params).forEach(function (key) {
            if (key !== 'LocationGroupID') {
                httpParams = httpParams.append(key, params[key]);
            }
        });

        if (params.LocationGroupID > 0) {
            // options.params['LocationGroupID'] = params.LocationGroupID;
            httpParams = httpParams.append('LocationGroupID', params.LocationGroupID.toString());
        }

        return this.http.get<BatteryTotalCount>(Config.urls.batteryStatusTotalUrl, { params: httpParams }).pipe(
            map((res) => {
                this.batteryTotalCount = res;
                return this.batteryTotalCount;
            }),
        );
    }
}
