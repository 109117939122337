import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { DATA_QUALITY_COLORS } from 'app/shared/constant';
import { ChangeEventVisibility, ChangeSerieVisibility, LegendEventDefinition, LegendSeriesDefinition } from '../lightning-chart-builder/lightning-chart-data-model';

@Component({
    selector: 'app-hydrograph-legend',
    templateUrl: './hydrograph-legend.component.html',
    styleUrls: ['./hydrograph-legend.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HydrographLegendComponent{

    @Input() public isDataQuality = false;
    @Input() public legendSeriesDefinition: LegendSeriesDefinition[];
    @Input() public legendEventDefinition: LegendEventDefinition[];

    @Output() public serieVisibility = new EventEmitter<ChangeSerieVisibility>();
    @Output() public eventVisibility = new EventEmitter<ChangeEventVisibility>();

    public dataQualityItems: {
        value: number,
        color: string
    }[] = [];

    constructor() {
        let counter = 1;
        for(const color of DATA_QUALITY_COLORS) {
            this.dataQualityItems.push({
                color: color,
                value: counter
            });
            counter++;
        }
    }

    public changeSeriesVisibility(name: string, entityId: number) {
        const entityDef = this.legendSeriesDefinition.find(e => e.entityId === entityId && e.name === name);
        entityDef.visible = !entityDef.visible;

        this.serieVisibility.emit({entityId: entityId, name: name, isVisible: entityDef.visible});
    }


    public changeEventVisibility(etype: number) {
        const entityDef = this.legendEventDefinition.find(e => e.etype === etype);
        entityDef.visible = !entityDef.visible;

        this.eventVisibility.emit({etype: etype, isVisible: entityDef.visible});
    }
}
