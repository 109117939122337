import { Component, ElementRef, OnInit, ViewEncapsulation, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationCancel, NavigationStart, NavigationEnd } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { NavigationService } from 'app/navigation/navigation.service';
import * as Highcharts from 'highcharts';
const highchartsExporting = require('highcharts/modules/exporting.js');
highchartsExporting(Highcharts);

import { OrderByPipe } from 'app/shared/pipes/order-by-pipe';
import { StatusCodeService } from '../shared/services/status-code.service';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { UiUtilsService } from '../shared/utils/ui-utils.service';
import { GISService } from 'app/shared/services/gis-service';
import { gisUserSettings } from 'app/shared/models/gis-service-list';
import { debounceTime, first, map } from 'rxjs/operators';
import {
    activeInactiveLocationQueryParam,
    AppCustomer,
    AppQueryParams,
    Customer,
    customerLocationGroupQueryParam,
    customerQueryParam,
} from 'app/shared/models/customer';
import { GetPermissionsResponseCustomer } from 'app/shared/models/users-permission';
import { IComponentDialog } from 'app/shared/models/comopnent-cofirmation';
import { LocationGroup, LocationGroupSelector } from 'app/shared/models/location-group';
import { LocationGroupArgs } from 'app/shared/models/navigation';
import { AuthService } from 'app/shared/services/auth.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { PreLoaderService } from 'app/shared/services/pre-loader.service';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit, AfterViewInit {
    private gisUserSettings: gisUserSettings;
    public currentRoute: string;

    /**
     * Represents the status of the preloader.
     */
    public isLoading: Observable<boolean>;

    /**
     * Represents the page title.
     */
    public pageTitle: string;

    /**
     * Represents the collection of customers.
     */
    public customers: Array<Customer>;

    /**
     * Represents the currently selected customer.
     */
    public currentCustomerID: number;

    /**
     * Represents a collection of location groups.
     */
    public locationGroups: Array<LocationGroup>;
    public showHintOverlay: boolean;
    public hintClass: string;

    constructor(
        public navigation: NavigationService,
        public router: Router,
        public elementRef: ElementRef,
        public authService: AuthService,
        private activatedRoute: ActivatedRoute,
        private customerService: CustomerService,
        private preloaderService: PreLoaderService,
        private locationGroupService: LocationGroupService,
        private cdr: ChangeDetectorRef,
        private matDialog: MatDialog,
        private statusCodeService: StatusCodeService,
        private domOperationUtilsService: DomOperationUtilsService,
        private uiUtilsService: UiUtilsService,
        private gisService: GISService,
    ) {}

    public ngAfterViewInit() {
        // This part of code was generating NG0100: Expression has changed after it was checked Angular error on Vault while in ngOnInit
        // subscribe to changes in customers lists
        this.customerService.getCustomers(false).pipe(map((customers: Customer[]) => customers.filter(v => v.isActive)))
            .subscribe((values) => {
                if (values && values.length > 0) {
                    this.customers = values;
                    this.customers = this.customers.map(v => ({ ...v, name: v.customerName }));
                    const currentCustomerId = Number(
                        this.activatedRoute.snapshot.queryParamMap.get(customerQueryParam),
                    );
                    this.customerService.markActiveCustomer(currentCustomerId, this.customers);
                }
            });
    }

    /**
     * Framework level hook.
     */
    public async ngOnInit() {
        this.gisUserSettings = await this.gisService.gisUserSettingsGet();
        this.setupAppData();


        // subscribe on page hint Action
        this.domOperationUtilsService.showpageHint.subscribe((result: boolean) => {
            this.showHintOverlay = result;
        });

        this.domOperationUtilsService.selectedHintPageName.subscribe((result: string) => {
            this.hintClass = result;
        });

        this.activatedRoute.queryParamMap.subscribe((params) => {
            this.currentCustomerID = Number(params.get(customerQueryParam));
        });

        this.isLoading = this.preloaderService.loadingEmitter;

        this.router.events
            .subscribe((event: Event) => {
                if (event instanceof NavigationStart) {
                    this.navigation.setIsRouteLoading(true);

                    this.navigation.setBreadcrumbs(null); // Reset breadcrumbs before route change

                    this.navigation.setPageTitle(null); // Reset page title before route change

                    this.preloaderService.start();

                    const currentRoutingEvent: NavigationStart = event;

                    this.setupRoute(currentRoutingEvent.url);
                } else if (event instanceof NavigationEnd) {
                    this.currentRoute = (<NavigationEnd>event).urlAfterRedirects;

                    this.navigation.setCurrentRoute((<NavigationEnd>event).urlAfterRedirects);

                    this.navigation.setIsRouteLoading(true);

                    this.preloaderService.stop();

                    const routerOutletComponent: HTMLElement =
                        this.elementRef.nativeElement.getElementsByTagName('app-topnav')[0];

                    if (routerOutletComponent) {
                        routerOutletComponent.scrollIntoView(); // Scroll back to top after route change
                    }
                } else if (event instanceof NavigationCancel) {
                    // stop the preloader in case of cancelation
                    this.preloaderService.stop();
                } else if (event['error'] !== undefined) {
                    // reset loading on error

                    this.preloaderService.stop();
                }
                this.uiUtilsService.safeChangeDetection(this.cdr);
            })
            .unsubscribe();

        this.navigation.getPageTitle.subscribe((pageTitle: string) => (this.pageTitle = pageTitle));
    }

    /**
     * Below method will handle global location selection used by top-nav.component
     * @param activeLocationArgs will represents the argument used by top-nav.component
     * to emit as an event during global location change.
     */
    public activeInactiveLocationHandler(activeLocationArgs: LocationGroupArgs) {
        const queryParams: AppQueryParams = {
            ...(activeLocationArgs.params ? activeLocationArgs.params : {}),
            c: activeLocationArgs.customerId,
        };

        queryParams.lg = activeLocationArgs.locationGroupId ? activeLocationArgs.locationGroupId : undefined;
        queryParams.lt = activeLocationArgs.activeAllFlag ? activeLocationArgs.activeAllFlag : undefined;

        this.router.navigate([], {
            queryParams: queryParams,
            relativeTo: this.activatedRoute,
        });
    }

    public customerChangeHandler(customerId: number): void {
        // LEGACY: do not use the customer service to get current customer id, use route this is here to support the older components
        this.customerService.selectCustomer(customerId);
        // END LEGACY

        this.locationGroupService
            .getLocationGroups(customerId)
            .pipe(debounceTime(1000))
            .subscribe((locationGroups: { [key: string]: Array<LocationGroup> }) => {
                if (locationGroups) {
                    this.locationGroups = locationGroups.locationGroups;
                    const filterPipe = new OrderByPipe();
                    this.locationGroups = filterPipe.transform(this.locationGroups, 'name', false);
                } else {
                    this.locationGroups = [];
                }
                this.setLocationGroupsGlobalSelectable(this.locationGroups);
            });
        
        //Updating the user settings with the correct last viewed Location Group ID 
        const gisUserSetting = this.gisService.gisUserSettingsSubject$.getValue(); 
        const lastViewedLoc = gisUserSetting.customerSettings.find(x => x.cid === customerId)?.lastViewedLocationGroup ?? 0;
        const locationGroupId = lastViewedLoc;
        const activeAllFlag = Number(this.activatedRoute.snapshot.queryParamMap.get(activeInactiveLocationQueryParam));
        
        this.router.navigate([], {
            queryParams: <AppQueryParams>{
                lg: locationGroupId,
                c: customerId,
                lt: activeAllFlag,
            },
            relativeTo: this.activatedRoute,
        });
        this.currentCustomerID = customerId;
    }

    public setLocationGroupsGlobalSelectable(locationGroups) {
        this.locationGroupService.setLocationGroupsGlobalSelectable(locationGroups);
    }

    public async locationChangeHandler(locationGroupArgs: LocationGroupArgs) {
        

        const userSettings = await this.gisService.gisUserSettingsGet();
        const lastViewedCustomer = userSettings.lastViewedCustomerId;
        const lastViewedWasActive = userSettings.locationsIsActive;
        const lastViewedCustomerInformation = userSettings.customerSettings
            ? userSettings.customerSettings.find((x) => x.cid === lastViewedCustomer)
            : null;
        let lastViewedLocationGroup = undefined;
        if (!locationGroupArgs.customerId && lastViewedCustomer && lastViewedCustomerInformation) {
            lastViewedLocationGroup = lastViewedCustomerInformation.lastViewedLocationGroup;
        }

        this.router.navigate([], {
            queryParams: <AppQueryParams>{
                c: locationGroupArgs.customerId ? locationGroupArgs.customerId : lastViewedCustomer,
                lg: locationGroupArgs.locationGroupId
                    ? locationGroupArgs.locationGroupId
                    : lastViewedLocationGroup || undefined,
                lt: +lastViewedWasActive || undefined,
            },
            relativeTo: this.activatedRoute,
            queryParamsHandling: 'merge',

        });
    }

    private setupAppData(): void {
        // setup main app data - customer - location groups
        const appData: AppCustomer = this.activatedRoute.snapshot.data['app-data'];
        if (appData) {
            if (!appData.customers || appData.customers.length === 0) {
                this.matDialog
                    .open(ConfirmationDialogComponent, {
                        disableClose: true,
                        data: <IComponentDialog>{
                            title: 'ATTENTION',
                            message: `No assigned Customers found.
                    Please contact ADS Support Center 256-430-3366 (inside the U.S.), 877-237-9585 (toll-free).`,
                            cancelText: '',
                            okText: 'OK',
                        },
                    })
                    .afterClosed()
                    .subscribe(() => {
                        this.authService.logout();
                    });

                return;
            }

            // get data out of route
            this.customers = appData.customers.map(v => ({ ...v.customer, name: v.customer.customerName }));
            this.locationGroups = appData.locationGroups;
            this.setLocationGroupsGlobalSelectable(this.locationGroups);
            this.statusCodeService.userInfo.next(appData);

            // get current query params for customer
            let currentCustomerId = Number(this.activatedRoute.snapshot.queryParamMap.get(customerQueryParam));

            // get current query params for location group
            const locationGroupId =
                Number(this.activatedRoute.snapshot.queryParamMap.get(customerLocationGroupQueryParam)) || undefined;

            // get current query params for active/inactive locations
            // let activeAllFlag = Number(this.activatedRoute.snapshot.queryParamMap.get(activeInactiveLocationQueryParam));

            // validate selected customer against permission
            // this check is necessary here in case someone manually updates the URL to use a bad customer id
            const isSelectedCustomerValid = this.customers.some(
                (customer: GetPermissionsResponseCustomer) => customer.customerID === currentCustomerId,
            );

            if (currentCustomerId > 0 && isSelectedCustomerValid) {
                this.customerService.markActiveCustomer(currentCustomerId, appData.customers);
                this.locationGroupService.markActiveLocationGroup(locationGroupId, this.locationGroups);
                return;
            } else {
                currentCustomerId = 0;
            }

            const lastViewedCustomer = this.gisUserSettings.lastViewedCustomerId;
            const lastViewedWasActive = this.gisUserSettings.locationsIsActive;
            const lastViewedCustomerInformation = this.gisUserSettings.customerSettings
                ? this.gisUserSettings.customerSettings.find((x) => x.cid === lastViewedCustomer)
                : null;
            let lastViewedLocationGroup = undefined;
            if (!currentCustomerId && lastViewedCustomer && lastViewedCustomerInformation) {
                lastViewedLocationGroup = lastViewedCustomerInformation.lastViewedLocationGroup;
            }

            this.router.navigate([], {
                queryParams: <AppQueryParams>{
                    // set current query param or if none selected, use default first customer
                    c: currentCustomerId
                        ? currentCustomerId
                        : lastViewedCustomer || appData.customers[0].customer.customerID || 0,

                    // set current query para or default to none selected
                    lg: locationGroupId ? locationGroupId : lastViewedLocationGroup,

                    // set activeAllFlag to 1 (true) as default value, (0) will be false
                    lt: +lastViewedWasActive || undefined,
                },
                relativeTo: this.activatedRoute,
            });
        }
    }

    private setupRoute(path: string): void {
        // get current query params for customer
        const currentCustomerId = Number(this.activatedRoute.snapshot.queryParamMap.get(customerQueryParam));

        // get current query params for location group
        const locationGroupId =
            Number(this.activatedRoute.snapshot.queryParamMap.get(customerLocationGroupQueryParam)) || undefined;

        // get current query params for active/inactive locations
        const activeAllFlag = Number(this.activatedRoute.snapshot.queryParamMap.get(activeInactiveLocationQueryParam));

        // get global query params
        const appQueryParams = <AppQueryParams>{
            c: currentCustomerId,
            lg: locationGroupId || undefined,
            lt: activeAllFlag || undefined,
        };

        // merge with items query params
        const queryParams = Object.assign(this.activatedRoute.queryParams, appQueryParams);

        // navigate
        this.router.navigate([path], {
            queryParams,
            relativeTo: this.activatedRoute,
        });
    }
}
