<div id="dataDeleteDialog">
    <app-customized-confirmation-box
        *ngIf="showConfirmation"
        [confirmationData]="conformationDataInput"
        (listenConfirmation)="listenConfirmation($event)"
    ></app-customized-confirmation-box>

    <div>
        <div class="popupHeader" mat-dialog-title>
            <span>{{ 'LOCATION_DASHBOARD.DELETE_DATA.DELETEDATA' | translate }}</span>
            <span class="app-flex-filler"></span>
        </div>

        <div mat-dialog-content>
            <mat-form-field>
                <input
                    matInput
                    disabled
                    name="startDate"
                    [matDatetimepicker]="startDte"
                    placeholder="{{ 'COMMON.START_DATE' | translate }}"
                    required
                    appDatetimepickerPosition
                    [matDatetimepickerComp]="startDte"
                />
                <mat-datetimepicker-toggle matSuffix [for]="startDte"></mat-datetimepicker-toggle>
                <mat-datetimepicker #startDte disabled="false"></mat-datetimepicker>
            </mat-form-field>

            <mat-form-field>
                <input
                    matInput
                    disabled
                    name="endDate"
                    [matDatetimepicker]="endDte"
                    placeholder="{{ 'COMMON.END_DATE' | translate }}"
                    required
                    appDatetimepickerPosition
                    [matDatetimepickerComp]="endDte"
                />

                <mat-datetimepicker-toggle matSuffix [for]="endDte"></mat-datetimepicker-toggle>
                <mat-datetimepicker #endDte disabled="false"></mat-datetimepicker>
            </mat-form-field>
            <div>
                <mat-checkbox class="entityname">{{ 'COMMON.ENTITIES_TEXT' | translate }}</mat-checkbox>
                <mat-checkbox class="entity">Entity 1</mat-checkbox>
                <mat-checkbox class="entity">Entity 2</mat-checkbox>

                <mat-checkbox class="entity">Entity 3</mat-checkbox>
                <mat-checkbox class="entity">Entity 4</mat-checkbox>
            </div>
        </div>

        <div mat-dialog-actions>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button (click)="cancel(false)">
                    {{ 'LOCATION_DASHBOARD.DELETE_DATA.CANCEL' | translate }}
                </button>
            </div>
            <div class="auto-margin">
                <button mat-button (click)="delete()">
                    {{ 'LOCATION_DASHBOARD.DELETE_DATA.DELETESELECTED' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
