import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { Audit, AuditPaging, AuditReportParams } from 'app/shared/models/audit';
import { AuditService } from 'app/shared/services/audit.service';
import { UsersService } from 'app/pages/admin/users.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { USER_ROLES } from '../admin/users.service';
import { FilterSettings, WidgetFilterData } from 'app/shared/models/widget-filter-data';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { CustomerService } from 'app/shared/services/customer.service';
import {
    activeInactiveLocationQueryParam,
    customerLocationGroupQueryParam,
    customerQueryParam,
    userSearchPageIndexParam,
    userSearchPageSizeParam,
    userSearchParam,
} from 'app/shared/models/customer';
import { WidgetFiltersComponent } from 'app/shared/components/widget-filters/widget-filters.component';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


@Component({
    templateUrl: './audit.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit, OnDestroy {
    public searchString = new FormControl();
    private subscriptions = new Array<Subscription>();
    public audits = new Array<Audit>();
    public auditDataSource: MatTableDataSource<Audit>;
    public auditSummary = new Array<Audit>();
    public auditDataChange: BehaviorSubject<Array<Audit>> = new BehaviorSubject<Array<Audit>>([]);
    @ViewChild(MatPaginator) public userPaginator: MatPaginator;
    @ViewChild(MatSort) public customerSort: MatSort;
    @ViewChild(MatInput) public searchBox: MatInput;
    public totalPaginationLength: number;
    public datePickerType = 'datetime';
    public auditColumns = ['timestamp', 'action', 'modifiedby', 'locationname'];
    public customerId: number;
    public locationId: number;
    public locationGroupId: number;
    public dateFormat: string;
    public auditLogParams: AuditReportParams;
    public startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7, 0, 0, 0);
    public endDate = new Date();
    public auditFilterSettings: FilterSettings;
    public isLoading: boolean;
    public isApiProcessing: boolean;
    public includeInactiveLocations: boolean;
    public displayFilters = false;
    public searchValue: string;
    public pageSize: number;
    public pageIndex: number;
    public rowCount: number;
    public dateHeader: string;
    public actionHeader: string;
    public performedByHeader: string;
    public locationHeader: string;
    public isAudit = false;
    public sortColumn: string;
    public sortDirection = 'desc';
    public title: string;
    public downloadMessage: string;
    public confirm: any;
    public cancel: any;
    public to: string;
    public translateKeys: Array<string> = [
        'COMMON.LOCATION_COLUMN_TITLE',
        'ADMIN.AUDIT_LOG.PERFORM_BY_LABEL',
        'COMMON.DATE_HEADER',
        'COMMON.ACTION_HEADER',
        'ADMIN.AUDIT_LOG.EXPORT_CONFIRM_MESSAGE',
        'COMMON.CONFIRM_BTN',
        'COMMON.CANCEL',
        'COMMON.AUDIT_LOG_REPORT',
        'COMMON.TO',
    ];
    /**
     * Adding dependencies in component
     * @param auditService
     * @param changeDetectorRef
     * @param uiUtilsService
     */
    @ViewChild('widgetFilter') public widgetFilterComponent: WidgetFiltersComponent;

    constructor(
        private auditService: AuditService,
        private uiUtilsService: UiUtilsService,
        private changeDetector: ChangeDetectorRef,
        private dateUtilService: DateutilService,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private statusCodeService: StatusCodeService,
        private usersService: UsersService,
        private router: Router,
        private matDialog: MatDialog,
    ) {
        this.translate.get(this.translateKeys).subscribe((translateValues) => {
            this.locationHeader = translateValues['COMMON.LOCATION_COLUMN_TITLE'];
            this.actionHeader = translateValues['COMMON.ACTION_HEADER'];
            this.performedByHeader = translateValues['ADMIN.AUDIT_LOG.PERFORM_BY_LABEL'];
            this.dateHeader = translateValues['COMMON.DATE_HEADER'];
            this.downloadMessage = translateValues['ADMIN.AUDIT_LOG.EXPORT_CONFIRM_MESSAGE'];
            this.confirm = translateValues['COMMON.CONFIRM_BTN'];
            this.cancel = translateValues['COMMON.CANCEL'];
            this.title = translateValues['COMMON.AUDIT_LOG_REPORT'];
            this.to = translateValues['COMMON.TO'];
        });
        /*  Search by user name & roles  */
        this.subscriptions.push (
            this.searchString.valueChanges
            .pipe(debounceTime(400))
            .pipe(distinctUntilChanged())
            .subscribe((res: string) => {
                this.filterAudits(res);
            })
        );
    }

    public ngOnInit() {
        this.setAuditFilterSettings();
        // get logs
        const routeSubscription = this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            const newSearchValue = params.get(userSearchParam);
            if (newSearchValue) {
                this.searchValue = newSearchValue;
            }
            this.pageIndex = Number(params.get(userSearchPageIndexParam));
            this.pageSize = Number(params.get(userSearchPageSizeParam));
            this.locationGroupId = Number(params.get(customerLocationGroupQueryParam));
            this.customerId = Number(params.get(customerQueryParam));
            this.includeInactiveLocations = Boolean(Number(params.get(activeInactiveLocationQueryParam)));

            // #28026 Angular changes won't detect argument change, has to reassign them.
            this.auditFilterSettings = {...this.auditFilterSettings, displayInactiveLocations: this.includeInactiveLocations};

            this.onCustomerChange();
            this.checkPermission();


        });
        this.subscriptions.push(routeSubscription);

        // subscribe to changes in dateformat
        const subscription = this.dateUtilService.dateFormat.subscribe(() => {
            // Getting customer dateformat from dateUtil Service
            this.dateFormat = `${this.dateUtilService.getFormat()} ${this.dateUtilService.getTimeFormat()}`;
        });
        this.subscriptions.push(subscription);
    }

    private checkPermission() {
        const isAdvancedReportsAllowed = this.usersService.isAdvancedReportsAllowed.getValue().value;
        const userRole = this.usersService.userRoles.getValue()[0];

        const isAdmin = userRole === USER_ROLES.ADMIN;
        if (!isAdmin && !isAdvancedReportsAllowed) {
            this.router.navigate(['/'], {
                queryParams: {
                    c: this.customerId,
                },
                relativeTo: this.activatedRoute,
            });
        }
    }

    // Audit Filter Settings
    private setAuditFilterSettings() {
        this.generateReportWithDefaultParams();
        this.auditFilterSettings = {
            skipInitialCall: true,
            displayDateRanges: true,
            dateSpanPastWeek: true,
            displayLocations: true,
            singleLocationSelect: true,
            displayLocationGroup: true,
            singleLocationGroupSelect: true,
            displayInactiveLocations: true,
        };
    }

    private generateReportWithDefaultParams() {
        this.endDate = new Date();
        this.endDate.setHours(23);
        this.endDate.setMinutes(59);
        this.endDate.setSeconds(59);

        this.startDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7, 0, 0, 0);
    }

    // Filtering audit record
    public toggleFilters() {
        this.displayFilters = !this.displayFilters;
    }

    // reload report based on selected filter.
    public notifyReport(filtersData: WidgetFilterData) {
        this.isLoading = true;
        // setting to filter for search todays date include search
        this.startDate = filtersData.startDate;
        this.endDate = filtersData.endDate;
        this.locationId = filtersData.locationIDs && filtersData.locationIDs.length ? filtersData.locationIDs[0] : null;
        this.locationGroupId =
            filtersData.locationGroupId && filtersData.locationGroupId.length ? filtersData.locationGroupId[0] : null;

        this.uiUtilsService.safeChangeDetection(this.changeDetector);
        this.generateAuditLog();
    }

    // Method generates a table of AuditLog
    public generateAuditTable() {
        const auditCopiedData = [];
        this.auditDataSource = null;
        this.auditSummary = [];
        this.auditDataChange = new BehaviorSubject<Audit[]>([]);
        this.audits.forEach((audit) => {
            auditCopiedData.push(<Audit>{
                action: audit.action,
                timestamp: audit.timestamp,
                modifiedby: audit.modifiedby,
                username: audit.username,
                customerid: audit.customerid,
                customername: audit.customername,
                locationid: audit.locationid,
                locationname: audit.locationname,
            });
        });

        this.auditDataChange.next(auditCopiedData);
        this.auditDataSource = new MatTableDataSource(this.audits);
        this.auditDataSource.sort = this.customerSort;
        this.totalPaginationLength = this.rowCount;
        this.isLoading = false;
        this.uiUtilsService.safeChangeDetection(this.changeDetector);
    }

    /**
     * Method takes a sting as an argument and filters out customers whose name include the string
     * @param searchString - string to search for in customer name
     */
    public filterAudits(searchString: string) {
        this.searchValue = searchString;
        this.generateAuditLog();
    }

    // Destroy the subscription data
    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public setAuditLogParam() {
        const pagings: AuditPaging = {
            startPage: !!this.pageIndex ? Number(this.pageIndex) : 1,
            pageSize: !!this.pageSize ? Number(this.pageSize) : 100,
            searchValue: this.searchValue !== undefined ? this.searchValue : '',
            sort: this.sortColumn,
            sortDirection: this.sortDirection,
        };

        this.auditLogParams = {
            customerId: this.customerId,
            locationId: this.locationId,
            locationGroupId: this.locationGroupId,
            startTime: this.dateUtilService.getStartDateAsTimeZone(this.startDate),
            endTime: this.dateUtilService.getStartDateAsTimeZone(this.endDate),
            paging: pagings,
            isIncludeInactiveLocation: this.includeInactiveLocations,
        };
    }

    public onCustomerChange() {
        this.generateReportWithDefaultParams();
        this.generateAuditLog();
    }

    public generateAuditLog() {
        if (this.isApiProcessing) {
            return;
        }

        this.isApiProcessing = true;
        this.isLoading = true;
        this.setAuditLogParam();
        this.uiUtilsService.safeChangeDetection(this.changeDetector);
        this.auditService.getAuditDetails(this.auditLogParams).subscribe(
            (data: any) => {
                this.audits = this.auditSummary = [];
                this.isAudit = false;
                this.rowCount = 0;
                this.totalPaginationLength = 0;
                this.auditDataChange.next(null);
                if (data) {
                    this.isAudit = data.count > 0;
                    this.audits = this.auditSummary = data.details;
                    this.rowCount = data.count;
                    this.totalPaginationLength = data.count;
                    this.auditDataChange.next(this.audits);
                }

                this.generateAuditTable();
                this.isLoading = false;
                this.isApiProcessing = false;
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            () => {
                this.isLoading = false;
                this.isApiProcessing = false;
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
        );
    }

    public changePage(page) {
        this.pageSize = page.pageSize;
        this.pageIndex = page.pageIndex + 1;
        this.searchValue = this.searchString.value;
        this.startDate = this.widgetFilterComponent.widgetFilterData.startDate;
        this.endDate = this.widgetFilterComponent.widgetFilterData.endDate;
        this.generateAuditLog();
    }

    public sortAuditData(sort: Sort) {
        this.sortDirection = sort.direction;
        switch (sort.active) {
            case 'timestamp':
                this.sortColumn = 'DateRecorded';
                this.sortDirection = !sort.direction ? 'desc' : sort.direction;
                break;
            case 'locationname':
                this.sortColumn = 'LocationName';
                this.sortDirection = !sort.direction ? 'asc' : sort.direction;
                break;
            default:
                this.sortColumn = 'DateRecorded';
                this.sortDirection = !sort.direction ? 'desc' : sort.direction;
                break;
        }

        this.generateAuditLog();
    }

    public download() {
        const csvExportData = [];
        this.matDialog
            .open(ConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    title: this.title,
                    message: this.downloadMessage,
                    okText: this.confirm,
                    cancelText: this.cancel,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.whichButtonWasPressed === 'ok') {
                    this.isLoading = true;
                    const csvHeaders = [
                        this.dateHeader,
                        this.actionHeader,
                        this.performedByHeader,
                        this.locationHeader,
                    ];
                    const dateFormat = `${String(
                        this.dateUtilService.getFormat(),
                    ).toUpperCase()} ${this.dateUtilService.getTimeFormat()}`;
                    if (this.audits && this.audits.length > 0) {
                        this.audits.map((arrayItem) => {
                            if (arrayItem) {
                                csvExportData.push([
                                    moment(new Date(arrayItem.timestamp)).format(dateFormat),
                                    arrayItem.action,
                                    arrayItem.modifiedby,
                                    arrayItem.locationname || '',
                                ]);
                            }
                        });
                    }
                    const options = {
                        showLabels: true,
                        headers: csvHeaders,
                        title: `${this.title} :- ${moment(this.startDate).format(dateFormat)} ${this.to} ${moment(
                            this.endDate,
                        ).format(dateFormat)}`,
                        showTitle: true,
                    };

                    // download to csv
                    const exported = new AngularCsv(csvExportData, this.title, options);
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.changeDetector);
                }
            });
    }
}
