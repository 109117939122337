export class rainFallStormsFreq {
    public start?: string;
    public end?: string;
    public guid?: string;
}

export class rainFallStormsFreqReport {
    public name: string;
    public start: string;
    public end: string;
    public hrs: number;
    public mins: number;
    public total: number;
    public avgint: number;
    public int1: number;
    public int2: number;
    public int5: number;
    public int10: number;
    public int25: number;
    public int50: number;
    public int100: number;
    public int200: number;
    public int500: number;
    public int1000: number;
    public rtnfreq: string;
}

export class rainFallStormsFreqReportResponse {
    public averageIntensityUnit: string;
    public locations: Array<rainFallStormsFreqReport>;
    public totalRainfallUnit: string;
}