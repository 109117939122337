import { Component, OnInit, ViewEncapsulation, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FLOW_DISPLAY_GROUP, QCONTINUITY, QCONTINUITY_ENTITY, QFLUME, QFLUME_ENTITY, QLOOKUP, QLOOKUP_ENTITY, QWEIR, QWEIR_ENTITY } from 'app/shared/constant';
import { InstallationSeries } from 'app/shared/models/location-details';
import { MONITOR_SERIES_TYPES } from 'app/shared/models/monitor-series-types';
import { TritonConfiguration } from 'app/shared/models/monitorsetting';
import { Subject } from 'rxjs';


@Component({
    selector: 'app-flow-form',
    templateUrl: './flow-form.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})

export class FlowFormComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() monitorConfigs: TritonConfiguration;
    @Input() public installationSeriesChange: Subject<number>;
    @Input() isMp2: boolean;

    private installationSeries: InstallationSeries;

    public series: string;
    flowEntities = [];

    constructor() { }

    ngOnInit(): void {
        this.installationSeriesChange.subscribe((aType: InstallationSeries) => {
            this.installationSeries = aType;
            this.filterFlowEntities();
        })
    }

    filterFlowEntities() {
        switch(this.installationSeries) {
            case InstallationSeries.Pipe:
                this.flowEntities = [{ id: QCONTINUITY_ENTITY, groupId: FLOW_DISPLAY_GROUP, name: QCONTINUITY, isChecked: true, isANSR: false },];
                break;
            case InstallationSeries.Channel:
                this.flowEntities = [{ id: QLOOKUP_ENTITY, groupId: FLOW_DISPLAY_GROUP, name: QLOOKUP, isChecked: true, isANSR: false },];
                break;
            case InstallationSeries.Flume:
                this.flowEntities = [{ id: QFLUME_ENTITY, groupId: FLOW_DISPLAY_GROUP, name: QFLUME, isChecked: true, isANSR: false },];
                break;
            case InstallationSeries.Weir:
                this.flowEntities = [{ id: QWEIR_ENTITY, groupId: FLOW_DISPLAY_GROUP, name: QWEIR, isChecked: true, isANSR: false },];
                break;
            case InstallationSeries.Elevation:
            case InstallationSeries.Rain:
                // #40988 Ignore them
                // jwright says: You cannot select those Installation Types for a TRITON+. The Rain is limited to the RAIII series. The Elevation is limited to ForeSITE.
                this.flowEntities = [];
                break;
        }
        if(this.flowEntities && this.flowEntities.length) {
            this.form.patchValue({'flowType': this.flowEntities[0].id});
        }
    }
}
