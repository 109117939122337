import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './data-collection.component.html',
})
export class DataCollectionComponent implements OnInit {
    /* Variable initilization section */
    private today = new Date();
    public startDate: Date;
    public endDate: Date;
    public maxDate = new Date();
    public dayErrorMessage: string;
    public isDaySpanValid: boolean;
    public is12HourFormat = false;
    public dateFormat: string;

    public dateErrMsg: string;
    public startGreaterThanTodayErr: string;
    public endGreaterThanTodayErr: string;
    // varibale to hold customer date format
    public customDateFormat: string;
    /*End section */

    /**
     * Component constructor
     * @param data
     * @param dialogRef
     * @param dateutilService
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Date,
        public dialogRef: MatDialogRef<DataCollectionComponent>,
        private dateutilService: DateutilService,
        private translate: TranslateService,
    ) {}

    /**
     * Init Life cycle event.
     */
    public ngOnInit() {
        this.translate.get('COMMON.DATE_ERROR').subscribe((res: string) => {
            this.dateErrMsg = res;
        });
        this.translate.get('COMMON.START_DATE_GREATER_TODAY').subscribe((res: string) => {
            this.startGreaterThanTodayErr = res;
        });
        this.translate.get('COMMON.END_DATE_GREATER_TODAY').subscribe((res: string) => {
            this.endGreaterThanTodayErr = res;
        });
        this.endDate = new Date(
            this.today.getFullYear(),
            this.today.getMonth(),
            this.today.getDate(),
            this.today.getHours() + 24,
            this.today.getMinutes(),
            this.today.getSeconds(),
        );

        // get last collected date
        this.isDaySpanValid = true;
        // added condition to check invalid date
        if (this.data && !isNaN(this.data.valueOf())) {
            const startDate = this.data;
            this.startDate = new Date(startDate.setSeconds(startDate.getSeconds()));
            this.dateValidation();
        } else {
            // #10711
            // If no collection data found for the location set it to last 30 days in start date to collect=
            this.startDate = new Date(
                this.today.getFullYear(),
                this.today.getMonth() - 1,
                this.today.getDate() - 1,
                0,
                0,
                0,
            );
        }
        // set time format to UI
        this.dateFormat = this.dateutilService.getFormat();
        this.is12HourFormat = this.dateutilService.timeFormat.getValue() !== 'hh:mm:ss';
        this.customDateFormat = this.is12HourFormat
            ? `${this.dateFormat}, ${'hh:mm a'}`
            : `${this.dateFormat}, ${'HH:mm'}`;
    }

    /**
     * Close Dialog event
     */
    public close() {
        this.dialogRef.close({ success: false });
    }

    /**
     * On changing start date time event
     */
    public onChangeStartDateTime($event: any) {
        this.isDaySpanValid = true;
        this.startDate = $event.target.value;
        this.dateValidation();
    }

    /**
     * On changing end date time event
     */
    public onChangeEndDateTime($event: any) {
        this.isDaySpanValid = true;
        this.endDate = $event.target.value;
        this.dateValidation();
    }

    /**
     * Use to close dialog event and send data to back to the calling component.
     */
    public Collect() {
        this.dialogRef.close({
            success: true,
            startDate: this.startDate,
            endDate: this.endDate,
        });
    }

    /**
     * Handle Start Date change event on calender popup
     * @param event
     */
    public onStartDateChanged(event) {
        this.startDate = event.value;
        this.dateValidation();
    }

    /**
     * Handle end Date change event on calender popup
     * @param event
     */
    public onEndDateChanged(event) {
        this.endDate = event.value;
        this.dateValidation();
    }

    /**
     * Use to Validate Dates(start/End)
     */
    private dateValidation() {
        this.dayErrorMessage = '';
        const dateCheck = new Date(
            this.today.getFullYear(),
            this.today.getMonth(),
            this.today.getDate(),
            this.today.getHours() + 24,
            this.today.getMinutes(),
            this.today.getSeconds(),
        );
        if (this.startDate && this.endDate) {
            const startDate = this.startDate;
            const endDate = this.endDate;
            this.isDaySpanValid = true;
            if (startDate > endDate) {
                this.isDaySpanValid = false;
                this.dayErrorMessage = this.dateErrMsg;
            }
            // commented due to 10981

            /*if (this.today < startDate) {
        this.isDaySpanValid = false;
        this.dayErrorMessage = this.dayErrorMessage + this.startGreaterThanTodayErr;
      }
      if (dateCheck < endDate) {
        this.isDaySpanValid = false;
        this.dayErrorMessage = this.dayErrorMessage + this.endGreaterThanTodayErr;
      }
      */
        }
    }
}
