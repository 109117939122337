import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventFilters } from 'app/shared/models/event';

@Component({
    selector: 'app-duration-picker',
    templateUrl: './duration-picker.component.html',
    styleUrls: ['./duration-picker.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DurationPickerComponent implements OnInit, OnChanges {
    @Input() filterOptions: EventFilters;
    @Output() emitClose = new EventEmitter();

    public form: FormGroup;

    constructor(private formBuilder: FormBuilder) { }

    ngOnInit(): void {
        this.createForm();
        this.form.patchValue(this.filterOptions);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.filterOptions && changes.filterOptions.currentValue && this.form) {
            this.form.patchValue(changes.filterOptions.currentValue);
        }
    }

    public format(key: string) {
        const value = this.form.get(key).value;

        if (!value) {
            this.form.patchValue({ [key]: '00' });
        }

        this.form.patchValue({ [key]: String(value).length > 1 ? value : '0' + String(value) });
    }

    public durationClose() {
        if (this.form.valid) {
            this.emitClose.emit(this.form.getRawValue());
        }
    }

    public setDurationFromQuickRanges(hours: number) {
        this.form.patchValue({
            durationStartHours: 0,
            durationStartMinutes: 0,
            durationEndHours: hours,
            durationEndMinutes: 0
        });
    }

    private createForm() {
        this.form = this.formBuilder.group({
            durationStartHours: [null, Validators.min(0)],
            durationStartMinutes: [null, [Validators.maxLength(2), Validators.max(59), Validators.min(0)]],
            durationEndHours: [null, Validators.min(0)],
            durationEndMinutes: [null, [Validators.maxLength(2), Validators.max(59), Validators.min(0)]],
        });

        this.form.setValidators(this.durationValidator.bind(this));
    }

    private durationValidator() {
        if (!this.form) return null;

        const { durationStartHours, durationStartMinutes, durationEndHours, durationEndMinutes } = this.form.value;
        const sh = Number(durationStartHours);
        const sm = Number(durationStartMinutes);
        const eh = Number(durationEndHours);
        const em = Number(durationEndMinutes);
        if (sh > eh || (sh === eh && sm > em)) {
            return { diff: true };
        }

        return null;
    }

    public onKeyPressDigit(event) {
        const result = (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 44 || event.charCode == 46);
        return result;
    }
}
