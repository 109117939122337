import {
    Component,
    OnInit,
    Injectable,
    EventEmitter,
    Output,
    Input,
    ChangeDetectionStrategy,
    OnDestroy,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { of as ofObservable, Observable, BehaviorSubject, Subscription } from 'rxjs';
import { gisUserSettings, mapLayerList } from 'app/shared/models/gis-service-list';
import { ActivatedRoute } from '@angular/router';
import { CompositeLocComponent } from 'app/shared/components/map/composite-loc/composite-loc.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { GISService, LegendType } from 'app/shared/services/gis-service';
import { filter, first } from 'rxjs/operators';
import { TritonLocationDialogComponent } from 'app/shared/components/location-card/components/triton-location-dialog/triton-location-dialog.component';
import { Locations } from 'app/shared/models/locations';
import { MapService } from 'app/shared/services/map.service';
import { AddLocationModalData } from 'app/shared/models/add-location';
import { LocationCardService } from 'app/shared/components/location-card/services/location-card.service';

/**
 * Node for to-do item
 */
export class TodoItemNode {
    children: TodoItemNode[];
    item: string;
    checked: boolean;
    legendInfo: { label: string; url?: string; color?: string; height?: string }[];
    showLegend: boolean;
    selection: boolean;
    opacity: number;
}

/** Flat to-do item node with expandable and level information */
export class TodoItemFlatNode {
    item: string;
    level: number;
    expandable: boolean;
    checked: boolean;
    legendInfo: { label: string; url?: string; color?: string; height?: string }[];
    showLegend: boolean;
    selection: boolean;
    opacity: number;
}

/**
 * The Json object for to-do list data.
 */
let TREE_DATA: any = [];

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */
@Injectable({ providedIn: 'root' })
export class ChecklistDatabase {
    dataChange: BehaviorSubject<TodoItemNode[]> = new BehaviorSubject<TodoItemNode[]>([]);

    get data(): TodoItemNode[] {
        return this.dataChange.value;
    }

    constructor() {
        this.initialize();
    }

    initialize() {
        // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
        //     file node as children.
        this.dataupdate(TREE_DATA);
    }
    dataupdate(datas: any) {
        const data = this.buildTree(datas, 0);
        this.dataChange.next(data);
        //const data = this.buildFileTree(datas, 0);
        // Notify the change.

        //console.log('updating data');
    }

    /**
     * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
     * The return value is the list of `TodoItemNode`.
     */
    buildTree(value: any, level: number) {
        const data: any[] = [];
        if (value.length > 0) {
            value.forEach((lyr) => {
                const dataitem = this.buildFileTree(lyr, level);
                data.push(dataitem);
            });
        }
        return data;
    }

    buildFileTree(value: any, level: number) {
        const node = new TodoItemNode();
        node.item = value.name;
        node.checked = value.checked;
        node.showLegend = value.showLegend;
        node.legendInfo = value.legendInfo;
        node.selection = value.selection;
        node.opacity = value.opacity;
        if (value.child.length > 0) {
            node.children = this.buildTree(value.child, 1);
        }

        return node;
    }

    /** Add an item to to-do list */
    insertItem(parent: TodoItemNode, name: string) {
        const child = <TodoItemNode>{ item: name };
        if (parent.children) {
            parent.children.push(child);
            this.dataChange.next(this.data);
        }
    }

    updateItem(node: TodoItemNode, name: string) {
        node.item = name;
        this.dataChange.next(this.data);
    }
}

/**
 * @title Tree with checkboxes
 */

@Component({
    selector: 'app-location-layer-list',
    templateUrl: './location-layer-list.component.html',
    styleUrls: ['./location-layer-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationLayerListComponent implements OnInit, OnDestroy {
    public LegendType = LegendType;
    private subscriptions = new Subscription();
    @ViewChild('treeNode') public firstNode: ElementRef<HTMLDivElement>;
    @Input() public locations = new Array<Locations>();
    public assignedRainGauge = new Array<Locations>();
    customerId: number;
    @Output() public layerSelection = new EventEmitter<mapLayerList[]>();
    @Output() public labelsChanged = new EventEmitter();
    @Output() public opacityChange = new EventEmitter<mapLayerList[]>();
    @Output() public zoomtolayer = new EventEmitter<mapLayerList[]>();
    @Output() public refreshMap = new EventEmitter();
    @Input() public mapLayerslist = {};
    @Input() public availablePageHint: boolean;
    /* const layerlist = [{
         visible: false,
         name:'Monitor',
         selection:false
     }]*/
    /** Map from flat node to nested node. This helps us finding the nested node to be modified */
    flatNodeMap: Map<TodoItemFlatNode, TodoItemNode> = new Map<TodoItemFlatNode, TodoItemNode>();

    /** Map from nested node to flattened node. This helps us to keep the same object for selection */
    nestedNodeMap: Map<TodoItemNode, TodoItemFlatNode> = new Map<TodoItemNode, TodoItemFlatNode>();

    /** A selected parent node to be inserted */
    selectedParent: TodoItemFlatNode | null = null;

    /** The new item's name */
    newItemName = '';

    treeControl: FlatTreeControl<TodoItemFlatNode>;

    treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;

    dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

    /** The selection for checklist */
    checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);
    userSettings: gisUserSettings;
    constructor(
        private database: ChecklistDatabase,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private gisService: GISService,
        private mapService: MapService,
        private locationCardService: LocationCardService
    ) {}

    ngOnInit() {
        const routeParamsSubs = this.activatedRoute.queryParams.subscribe((data) => {
            if (!data) return;

            this.customerId = Number(data.c);
        });

        const settingSubs = this.gisService.gisUserSettingsSubject$.pipe(filter(v => !!v))
            .subscribe((userSettings: gisUserSettings) => {
                this.userSettings = userSettings;
            });
        this.subscriptions.add(settingSubs);
        this.subscriptions.add(routeParamsSubs);
        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
        //this.TREE_DATA = this.mapLayerList;
        this.database.dataChange.subscribe((data) => {
            this.dataSource.data = data;
            this.checkAll();
        });
    }
    getLevel = (node: TodoItemFlatNode) => {
        return node.level;
    };

    isExpandable = (node: TodoItemFlatNode) => {
        return node.expandable;
    };

    isChecked = (node: TodoItemFlatNode) => {
        return node.checked;
    };

    getChildren = (node: TodoItemNode): Observable<TodoItemNode[]> => {
        return ofObservable(node.children);
    };

    hasChild = (_: number, _nodeData: TodoItemFlatNode) => {
        return _nodeData.expandable;
    };

    hasNoContent = (_: number, _nodeData: TodoItemFlatNode) => {
        return _nodeData.item === '';
    };

    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    transformer = (node: TodoItemNode, level: number) => {
        const flatNode =
            this.nestedNodeMap.has(node) && this.nestedNodeMap.get(node)!.item === node.item
                ? this.nestedNodeMap.get(node)!
                : new TodoItemFlatNode();
        flatNode.item = node.item;
        flatNode.checked = node.checked;
        flatNode.showLegend = node.showLegend;
        flatNode.legendInfo = node.legendInfo;
        flatNode.selection = node.selection;
        flatNode.opacity = node.opacity;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    };

    checkAll() {
        for (const node of this.treeControl.dataNodes) {
            if (!this.checklistSelection.isSelected(node) && node.checked) {
                this.checklistSelection.toggle(node);
            }
        }
    }

    /** Whether all the descendants of the node are selected */
    descendantsAllSelected(node: TodoItemFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        return descendants.every((child) => child.checked);
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some((child) => child.checked);
        return result && !this.descendantsAllSelected(node);
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    selectAllChildren(node: TodoItemFlatNode): void {
        // First verify whether it should be towards checked or unchecked
        const currentState = this.descendantsAllSelected(node);
        const nodeChildren = this.treeControl.getDescendants(node);

        // Then move all of the children boxes to the correct direction
        const layerList = [];
        for (const child of nodeChildren) {
            child.checked = !currentState;
            layerList.push({
                checked: child.checked,
                name: child.item,
                selection: child.selection,
                legendInfo: child.legendInfo,
                showLegend: child.showLegend,
                opacity: child.opacity,
            });
        }

        // Finally emit changes back to parent component to actually affect visibility
        this.layerSelection.emit(layerList);
    }

    public toggleNode(node, actionType: number) {
        const layerlist = [
            {
                checked: node.checked,
                name: node.item,
                selection: node.selection,
                showLegend: node.showLegend,
                legendInfo: node.legendInfo,
                opacity: node.opacity,
            },
        ];
        switch (actionType) {
            case 0: {
                // change node
                this.checklistSelection.toggle(node);
                this.layerSelection.emit(layerlist);
                break;
            }
            case 1: {
                // zoom layer
                this.zoomtolayer.emit(layerlist);
                break;
            }
            case 2: {
                // opacity change
                this.opacityChange.emit(layerlist);
                break;
            }
        }
    }

    public async addCompositeLocation() {
        // ensure that rain gauge collection is present
        this.setupRainGaugeLocations();

        const dialogOptions: MatDialogConfig = {
            disableClose: true,
            data: {
                customerId: this.customerId,
                enableSelectOnMap: true
            },
            hasBackdrop: false
        };

        const userSettings = await this.gisService.gisUserSettingsGet();

        if (this.gisService.locationCardPosition) {
            dialogOptions.position = this.locationCardService.checkLocationCardPosition(false, this.gisService.locationCardPosition);
        } else if (userSettings && userSettings.locationPositionX && userSettings.locationPositionY) {
            dialogOptions.position = this.locationCardService.getLocationCardPosition(false, userSettings);
        }

        this.dialog
            .open(CompositeLocComponent, dialogOptions)
            .afterClosed()
            .subscribe((res) => {
                // if user add new location successfully, then refresh map.
                if (res && res.success) {
                    // commented the line seems its not required
                    // this.resetMap.emit(<LocationDetailsModel>{
                    //   isRefreshLocations: true
                    // });
                }
            });
    }

    public async addEditLocation(isEditMode = false, locationDetails = {}) {
        // ensure that rain gauge collection is present
        this.setupRainGaugeLocations();
        const editTritonLocationComponentMapData = <AddLocationModalData>{
            rainGaugeLocations: this.assignedRainGauge,
            customerId: this.customerId,
            editMode: isEditMode,
            isFromCustomerEditor: false,
            locationDetails: locationDetails,
            enableSelectOnMap: true
        };

        const dialogOptions: MatDialogConfig = {
            disableClose: true,
            data: editTritonLocationComponentMapData,
            hasBackdrop: false,
        };

        const userSettings = await this.gisService.gisUserSettingsGet();

        if (this.gisService.locationCardPosition) {
            dialogOptions.position = this.locationCardService.checkLocationCardPosition(true, this.gisService.locationCardPosition);
        } else if (userSettings && userSettings.locationPositionX && userSettings.locationPositionY) {
            dialogOptions.position = this.locationCardService.getLocationCardPosition(true, userSettings);
        }

        if (this.mapService.addEditLocationDialog) {
            this.mapService.addEditLocationDialog.close();
        }

        this.mapService.addEditLocationDialog = this.dialog.open(TritonLocationDialogComponent, dialogOptions);
        this.mapService.addEditLocationDialog
            .afterClosed()
            .pipe(first())
            .subscribe((result: boolean) => {
                this.mapService.addEditLocationDialog = null;
                if (result) {
                    this.refreshMap.emit(true);
                }
            });

        // this.mapService.addEditLocationDialog.componentInstance.refreshLocations
        //     .subscribe((location: LocationDetailsModel) => {
        //         // this.refreshLocations(location);
        //     });
        // this.mapService.addEditLocationDialog.afterClosed().subscribe(res => {
        //     // if user add new location successfully, then refresh map.
        //     if (res && res.success) {
        //         // commented the line seems its not required
        //         // this.resetMap.emit(<LocationDetailsModel>{
        //         //   isRefreshLocations: true
        //         // });
        //     }
        // });
    }

    public labelsClick() {
        const { showLocationLabels } = this.userSettings;
        this.userSettings.showLocationLabels = !showLocationLabels;
        this.gisService.updateGisUserSettings({...this.userSettings, showLocationLabels: !showLocationLabels}).subscribe(() => {
            this.labelsChanged.emit(null);
        });
    }

    private setupRainGaugeLocations() {
        // check if rain guage locations are already filled and if so exit immediately
        if (this.assignedRainGauge && this.assignedRainGauge.length > 0) {
            return;
        }

        // check if locations exist
        if (this.locations) {
            // make the filtered array for assignedRainGauge.
            this.assignedRainGauge = this.locations.filter((location) => {
                if (location && location.series && location.series.toLowerCase().indexOf('rainalert') > -1) {
                    return location;
                }
            });
        }
    }

    loaddata(layer: any) {
        TREE_DATA = layer;
        this.database.dataupdate(layer);
    }

    ngOnDestroy() {
        if (this.mapService.addEditLocationDialog) {
            this.mapService.addEditLocationDialog.close();
            this.mapService.addEditLocationDialog = null;
        }
        this.subscriptions.unsubscribe();
    }
}
