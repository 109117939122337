<div class="summary-details weekgroup-table">
    <div id="dayweekGroupPageTable">
        <mat-table #weekgrouptable [dataSource]="weekgroupDataSource" matSort>
            <ng-container matColumnDef="selection">
                <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                <mat-cell *matCellDef="let weekelement; let i = index">
                    <mat-checkbox
                        [disabled]="isStudyLocked"
                        [(ngModel)]="weekelement.selection"
                        (change)="rowSelection(i, weekelement, weekelement.selection)"
                    ></mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER_TABLE.SLICER_SUMMARY.WEEKDAYS_NAME' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let weekelement; let i = index">
                    <mat-form-field floatLabel="never">
                        <input
                            matInput
                            required
                            placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.WEEKDAYS_NAME' | translate }}"
                            [disabled]="isStudyLocked"
                            [(ngModel)]="weekelement.name"
                            (input)="nameChanged()"
                        />
                    </mat-form-field>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="sunday">
                <mat-header-cell *matHeaderCellDef>{{ dayStrings[0] }}</mat-header-cell>
                <mat-cell *matCellDef="let weekelement; let i = index">
                    <mat-checkbox
                        [disabled]="isStudyLocked"
                        [(ngModel)]="weekelement.days[0]"
                        (change)="dayGroupSelection(0, i)"
                    ></mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="monday">
                <mat-header-cell *matHeaderCellDef>{{ dayStrings[1] }}</mat-header-cell>
                <mat-cell *matCellDef="let weekelement; let i = index">
                    <mat-checkbox
                        [disabled]="isStudyLocked"
                        [(ngModel)]="weekelement.days[1]"
                        (change)="dayGroupSelection(1, i)"
                    ></mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="tuesday">
                <mat-header-cell *matHeaderCellDef>{{ dayStrings[2] }}</mat-header-cell>
                <mat-cell *matCellDef="let weekelement; let i = index">
                    <mat-checkbox
                        [disabled]="isStudyLocked"
                        [(ngModel)]="weekelement.days[2]"
                        (change)="dayGroupSelection(2, i)"
                    ></mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="wednesday">
                <mat-header-cell *matHeaderCellDef>{{ dayStrings[3] }}</mat-header-cell>
                <mat-cell *matCellDef="let weekelement; let i = index">
                    <mat-checkbox
                        [disabled]="isStudyLocked"
                        [(ngModel)]="weekelement.days[3]"
                        (change)="dayGroupSelection(3, i)"
                    ></mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="thursday">
                <mat-header-cell *matHeaderCellDef>{{ dayStrings[4] }}</mat-header-cell>
                <mat-cell *matCellDef="let weekelement; let i = index">
                    <mat-checkbox
                        [disabled]="isStudyLocked"
                        [(ngModel)]="weekelement.days[4]"
                        (change)="dayGroupSelection(4, i)"
                    ></mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="friday">
                <mat-header-cell *matHeaderCellDef>{{ dayStrings[5] }}</mat-header-cell>
                <mat-cell *matCellDef="let weekelement; let i = index">
                    <mat-checkbox
                        [disabled]="isStudyLocked"
                        [(ngModel)]="weekelement.days[5]"
                        (change)="dayGroupSelection(5, i)"
                    ></mat-checkbox>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="saturday">
                <mat-header-cell *matHeaderCellDef>{{ dayStrings[6] }}</mat-header-cell>
                <mat-cell *matCellDef="let weekelement; let i = index">
                    <mat-checkbox
                        [disabled]="isStudyLocked"
                        [(ngModel)]="weekelement.days[6]"
                        (change)="dayGroupSelection(6, i)"
                    ></mat-checkbox>
                </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="weekgroupColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: weekgroupColumns"></mat-row>
        </mat-table>
        <div class="error" *ngIf="error">
            <div>{{ 'COMMON.ERRORS' | translate }}:</div>
            <div></div>
            <div *ngIf="errorType.allDays">{{ 'SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.SAVE_ERROR_ALL_DAYS_NEED_TO_BE_SET' | translate }}</div>
            <div *ngIf="errorType.uniqueNames">{{ 'SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.SAVE_ERROR_GROUP_NAME_UNIQUE' | translate }}</div>
            <div *ngIf="errorType.needsDay">{{ 'SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.SAVE_ERROR_GROUP_NEEDS_ONE_DAY' | translate }}</div>
            <div *ngIf="errorType.emptyName">{{ 'SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.SAVE_ERROR_GROUP_NEEDS_NAME' | translate }}</div>
        </div>
        <div class="custom-stepper">
            <div class="stepper-action save-button">
                <div class="auto-margin" *ngIf="!isStudyLocked">
                    <button class="mat-button" (click)="addNewRow()">
                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.ADD_DAYS' | translate }}
                    </button>
                    <button class="mat-button" (click)="deleteDayGroup()" *ngIf="!showRemoveSelectedItems">
                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.REMOVE_GROUP' | translate }}
                    </button>
                    <button class="mat-button" (click)="deleteGroup()" *ngIf="showRemoveSelectedItems">
                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.REMOVE_SELECTED_GROUPS' | translate }}
                    </button>
                </div>
                <div class="app-flex-filler"></div>
            </div>
        </div>
    </div>
</div>
