export enum DryDayHydrographProperties {
    basin = 'Basin',
    regime = 'Regime',
    season = 'Season',
    year = 'Year',
    dayGroup = 'Day Group',
    hour = 'Hour',
    flowRate = 'Flow Rate'
}

const DryDayHydrographNumberProperties = [DryDayHydrographProperties.flowRate];

export const csvToJSON = (text: string, quoteChar = '"', delimiter = ',') => {
    const rows = text.split("\n");
    const headers = rows[0].split(",");

    const regex = new RegExp(`\\s*(${quoteChar})?(.*?)\\1\\s*(?:${delimiter}|$)`, 'gs');

    const match = line => [...line.matchAll(regex)]
        .map(m => m[2])
        .slice(0, -1);

    let lines = text.split('\n');
    const heads = headers ?? match(lines.shift());
    lines = lines.slice(1);

    return lines.map(line => {
        return match(line).reduce((acc, cur, i) => {
            // replace blank matches with `null`
            const val = cur.length <= 0 ? null : Number(cur) || cur;
            const key = heads[i] ?? `{i}`;
            return { ...acc, [key]: val };
        }, {});
    });
}

export const createPivotMapping = (data: Object): { [key: string]: { type: string; format?: string; interval?: string } } => {

    return Object.keys(data).reduce((result, key) => {
        const value = data[key];

        const isNumber = DryDayHydrographNumberProperties.includes(key as DryDayHydrographProperties);

        const type = isNumber ? 'number' : 'string';
        result[key] = { type };

        return result;
    }, {});
}

export const getPivotOptions = () => {
    return {
        showAggregationLabels: false,
        showAggregations: false,
        expandAll: true,
        grid: {
            type: "flat",
            showGrandTotals: "off",
            showTotals: "off",
        }
    }
}

export const getKeyByProp = (dryDay, prop: DryDayHydrographProperties) => {
    return Object.keys(dryDay).find(v => v.includes(prop));
}


const DRY_DAY_HG_SLICE_ROWS = [
    DryDayHydrographProperties.basin
];

const DRY_DAY_COLUMNS = [];

const DRY_DAY_MEASURES = [
    DryDayHydrographProperties.regime,
    DryDayHydrographProperties.season,
    DryDayHydrographProperties.year,
    DryDayHydrographProperties.dayGroup,
    DryDayHydrographProperties.hour,
    DryDayHydrographProperties.flowRate,
];

export const getPivotSlice = (dryDay) => {
    return {
        rows: DRY_DAY_HG_SLICE_ROWS.map(prop => ({ uniqueName: getKeyByProp(dryDay, prop) })),
        columns: [{uniqueName: "[Measures]"}, ...(DRY_DAY_COLUMNS.map(prop => ({ uniqueName: getKeyByProp(dryDay, prop) })))],
        measures: DRY_DAY_MEASURES.map(prop => ({ uniqueName: getKeyByProp(dryDay, prop) })),
        expandAll: true
    };
}
