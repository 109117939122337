import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ChartMouseDragFinish, ChartMouseDragSettings } from './chart-mouse-drag.models';
import Highcharts from 'highcharts';

@Directive({
    selector: '[chartMouseDrag]',
    host: {
        '(mousedown)': 'disableDrag||onMouseDown($event)',
        '(mousemove)': 'disableDrag||onMouseMove($event)',
        '(mouseup)': 'disableDrag||dragFinished()',
        '(mouseleave)': '!isMouseDown||dragFinished()',
    },
})
export class ChartMouseDragDirective {
    @Input() public disableDrag: boolean;
    @Input() private dragSettings: ChartMouseDragSettings;
    @Input() private chartElement: Highcharts.ChartObject;
    @Output('chartMouseDrag') chartMouseDragFinish: EventEmitter<ChartMouseDragFinish> = new EventEmitter();
    public isMouseDown: boolean;
    private dragStart: number;
    private dragEnd: number;
    constructor() { }

    //This method will handle the left mouse click
    public onMouseDown(e): void {
        if (e.which === 1 && !e.target.classList.value.includes('navigator')) {
            this.isMouseDown = true;
            this.dragStart = Math.round(this.chartElement.xAxis[0].toValue(e.chartX));
        }
    }

    //This method will handle the mouse drag when it's clicked and draw the plot on the chart
    public onMouseMove(e): void {
        if (this.isMouseDown) {
            this.dragEnd = Math.round(this.chartElement.xAxis[0].toValue(e.chartX));
            this.addPlotBand(this.dragStart, this.dragEnd);
        }
    }

    //This method will handle the drag finish and can be trigger with mouse up or when the mouse leaves the chart
    public dragFinished(): void {
        this.isMouseDown = false;
        this.chartMouseDragFinish.emit({ dragStart: this.dragStart, dragEnd: this.dragEnd });
        this.dragStart = null;
        this.dragEnd = null;
    }

    //This method will draw the selected plot on the chart
    private addPlotBand(start: number, end: number): void {
        this.removePlotBand();
        this.chartElement.xAxis[0].addPlotBand({
            id: this.dragSettings['plotId'],
            from: start,
            to: end,
            color: this.dragSettings['color'],
            zIndex: this.dragSettings['zIndex'],
        });
    }

    //This method will remove the drawn plot on the chart
    private removePlotBand(): void {
        this.chartElement.xAxis[0].removePlotBand(this.dragSettings['plotId']);
    }
}
