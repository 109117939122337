import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { RAIN_DISPLAY_GROUP } from 'app/shared/constant';
import { ManualScale } from 'app/shared/models/hydrograph';
import { MAX_RAIN_IN, MAX_RAIN_MM } from 'app/shared/models/units';

@Component({
    selector: 'app-hydrograph-custom-ranges',
    templateUrl: './hydrograph-custom-ranges.component.html',
    styleUrls: ['./hydrograph-custom-ranges.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HydrographCustomRangesComponent {

    @Input() public showCustomRanges: boolean;
    @Input() public customRanges: ManualScale[] = [];
    @Input() public isCustomerMetric: boolean;

    @Output() public customRangesChange = new EventEmitter<ManualScale[]>();

    translations = {
        MIN_SCALE_PLACEHOLDER: '',
        MAX_SCALE_PLACEHOLDER: '',
        RAIN_AUTO_TEXT: '',
        INVALID_SCALE: ''
    }

    constructor(
        private translate: TranslateService
    ) {
        this.translations.MIN_SCALE_PLACEHOLDER = this.translate.instant('HYDROGRAPH_NEW.RANGES.MIN_SCALE_PLACEHOLDER');
        this.translations.MAX_SCALE_PLACEHOLDER = this.translate.instant('HYDROGRAPH_NEW.RANGES.MAX_SCALE_PLACEHOLDER');
        this.translations.RAIN_AUTO_TEXT = this.translate.instant('HYDROGRAPH_NEW.RANGES.RAIN_AUTO_TEXT');
        this.translations.INVALID_SCALE = this.translate.instant('HYDROGRAPH_NEW.RANGES.INVALID_SCALE');
    }

    public validateCustomRanges(opt?: ManualScale): boolean {
        if(!this.customRanges) return true;

        let valid = true;

        if (opt && opt.autoOpt) return true;

        if (opt) {
            if (opt.min !== null && opt.max !== null && opt.min >= opt.max) {
                valid = false;
            }
        } else {
            valid = this.customRanges.every(v => {
                return (v.min === 0 && v.max === 0) || ((v.min === null || v.min === undefined) && v.max !== null && v.max !== undefined) || ((v.min === null || v.min === undefined) && (v.max === null || v.max === undefined)) || v.max > v.min;
            });
        }
        return valid;
    }

    public changeAutoOptSlider(displayGroupId: number, index: number, event: MatSlideToggleChange) {
        this.customRanges[index].autoOpt = event.checked;

        if (event.checked) {
            this.customRanges[index].min = null;
            this.customRanges[index].max = null;
        } else {
            if(this.customRanges[index].displayGroupId === RAIN_DISPLAY_GROUP) {
                this.customRanges[index].min = 0;
                this.customRanges[index].max = this.isCustomerMetric ? MAX_RAIN_MM : MAX_RAIN_IN;
            } else {
                this.customRanges[index].min = null;
                this.customRanges[index].max = null;
            }
        }
    }

    public uiReset() {
        for(const customRange of this.customRanges) {
            if(customRange.displayGroupId === RAIN_DISPLAY_GROUP) {
                customRange.min = 0;
                customRange.max = this.isCustomerMetric ? MAX_RAIN_MM : MAX_RAIN_IN;
            } else {
                customRange.min = undefined;
                customRange.max = undefined;
            }
        }
        this.customRangesChange.emit(this.customRanges);
    }

    public uiApply() {
        this.customRangesChange.emit(this.customRanges);
    }


}
