import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ADS_REFRESH_TOKEN_KEY, AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class LoginGuard  {
    constructor(private authService: AuthService, private router: Router) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
        if (localStorage.getItem(ADS_REFRESH_TOKEN_KEY) && !this.authService.idleLogout) {
            this.router.navigate(['dashboard']);

            return false;
        }

        return true;
    }

}
