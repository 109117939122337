<div class="silt-eidtor" id="siltEditorDialog">
    <app-loader [isLoading]="isLoading"></app-loader>
    <!-- add graph header -->
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'COMMON.SILT_EDITOR' | translate | uppercase }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <!-- content -->
    <div mat-dialog-content class="full-width" *ngIf="!isLoading">
        <mat-table
            #table
            class="silt-table"
            [dataSource]="siltEditorDataSource"
            matSort
            matSortDisabled
            matSortDisableClear
            matSortActive="timestamp"
            matSortDirection="asc"
        >
            <ng-container matColumnDef="timestamp">
                <mat-header-cell *matHeaderCellDef mat-sort-header matSortStart="asc">{{
                    'COMMON.DATE_TIME' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.timestamp | date: customerDateFormat }} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="editedValue">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                    >{{ 'COMMON.VALUE' | translate }} {{ unit }}</mat-header-cell
                >
                <mat-cell *matCellDef="let element">{{ element.editedValue | number: '1.0-3' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="user">
                <mat-header-cell class="flex-2" *matHeaderCellDef mat-sort-header>{{
                    'COMMON.CREATED_BY' | translate
                }}</mat-header-cell>
                <mat-cell class="flex-2" *matCellDef="let element">{{ element.user }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="editDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                    'COMMON.LAST_APPLIED' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let element"
                    >{{ element.editDate ? (element.editDate | date: customerDateFormat) : ' - ' }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="action" *ngIf="userHasPermissionOnEdit">
                <mat-header-cell *matHeaderCellDef class="fixCellWidth110"></mat-header-cell>
                <mat-cell *matCellDef="let element; let j = index" class="text-right">
                    <button mat-icon-button (click)="editItem(element, j)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button (click)="deleteItem(element, j)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="siltEditorDescriptionColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: siltEditorDescriptionColumns"> </mat-row>
        </mat-table>

        <!-- separator -->

        <div [formGroup]="form" *ngIf="addMore">
            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            #selectedDateInput
                            matInput
                            type="datetime"
                            name="selectedDate"
                            [value]="form.controls['timeStamp'].value | date: customerDateFormat"
                            placeholder="{{ 'COMMON.DATE_TIME' | translate }}"
                            (change)="checkDateChange($event)"
                            tabindex="-1"
                            class="datepickerStyles"
                            appDatetimepickerPosition
                            [matDatetimepickerComp]="selectedDte"
                        />
                        <input
                            matInput
                            hidden
                            type="datetime"
                            name="selectedDate"
                            [matDatetimepicker]="selectedDte"
                            formControlName="timeStamp"
                            [value]="form.controls['timeStamp'].value | date: customerDateFormat"
                            (dateInput)="checkDateChange($event)"
                            (dateChange)="checkDateChange($event)"
                            placeholder="{{ 'COMMON.FROM' | translate }}"
                            required
                            tabindex="-1"
                        />
                        <mat-datetimepicker-toggle matSuffix [for]="selectedDte"></mat-datetimepicker-toggle>
                        <mat-datetimepicker #selectedDte type="datetime"></mat-datetimepicker>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            type="number"
                            formControlName="reading"
                            step="0.001"
                            pattern="^\d*(\.\d{0,3})?$"
                            placeholder="{{ 'COMMON.VALUE' | translate }} "
                        />
                        <div class="unit">{{ unit }}</div>
                    </mat-form-field>
                    <ng-container *ngIf="form.get('reading').errors as errors">

                        <div class="mat-error" *ngIf="errors.pattern && !errors.min">
                            {{ isMetric ? ('COMMON.OFFSET_METRIC_ERROR' | translate) : ('COMMON.OFFSET_NON_METRIC_ERROR' | translate) }}
                        </div>

                        <div class="mat-error" *ngIf="errors.min">
                            {{ 'COMMON.MUST_BE_POSITIVE_INCLUDING_ZERO' | translate }}
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="flex-container" *ngIf="userHasPermissionOnEdit">
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button *ngIf="addMore" (click)="addRow()" [disabled]="!form.valid">
                    {{ 'COMMON.ADD_TEXT' | translate | uppercase }}
                </button>
                <button mat-button (click)="addMoreOpen()" *ngIf="!addMore">
                    {{ 'COMMON.ADD_MORE' | translate | uppercase }}
                </button>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-icon-button (click)="exportSilt()">
            <mat-icon>file_download</mat-icon>
        </button>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="cancelClose(false)">
                {{ 'COMMON.CANCEL_BUTTON' | translate | uppercase }}
            </button>
        </div>
        <div class="auto-margin">
            <button mat-button [disabled]="!enableSave" (click)="saveSiltValues()">
                {{ 'COMMON.APPLY_BTN' | translate | uppercase }}
            </button>
        </div>
    </div>
</div>
