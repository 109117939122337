import { DateutilService } from 'app/shared/services/dateutil.service';
import {
    Component,
    Output,
    Input,
    EventEmitter,
    ChangeDetectorRef,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Observable, Subject, Subscription } from 'rxjs';
import { TopnavComponent } from 'app/navigation/topnav/topnav.component';
import { SliicerCaseStudy } from 'app/shared/models/sliicer';
import { LocationGroupMonitorSummary, MonitorCounts, MonitorName } from 'app/shared/models/sliicer-data';
import { CustomerService } from 'app/shared/services/customer.service';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { SliicerService, STEP_LENGTH_OPTIONS } from 'app/shared/services/sliicer.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { AdsDetailsDialogComponent } from '../details-dialog/details-dialog.component';
import { BasinStateEnum, StudyState } from 'app/shared/models/sliicer/metadata';
import { SelectItem } from 'app/shared/models/selected-Item';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { UpdatesWidgetService } from '../../shared/components/updates-widget/updates-widget.service';
import { UpdateInfo } from '../../shared/components/updates-widget/updates-widget.models';
import * as _ from 'underscore';
import { SliicerCaseStudyConfigForm } from 'app/shared/models/sliicer/case-study';
import { data } from 'jquery';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { AppQueryParams, customerQueryParam } from 'app/shared/models/customer';
import { ActivatedRoute, Router } from '@angular/router';
import { Monitor } from 'app/shared/models/sliicer/config';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { HTTP_STATUS_CODE_CONFLICT } from 'app/shared/constant';

const UPDATES_MODEL = 'studyConfig';

@Component({
    selector: 'ads-sliicer-edit-config',
    templateUrl: './edit-config.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SliicerCaseStudyEditConfigComponent implements OnInit, OnDestroy {
    @Input() public isStudyLocked: boolean;
    @Input() public inStudyWizard: boolean;
    @Output() public editComplete = new EventEmitter();
    @Output() public configurationFormValidation = new EventEmitter<boolean>(true);
    @Output() public recalculateStudy = new EventEmitter();

    private caseStudyId: string;
    private locationGroupMonitorSummaries: Array<LocationGroupMonitorSummary> = [];
    private subscriptions = new Array<Subscription>();
    private locationGroupId: number;
    // TODO: WPS - translate
    private allLocationsString = 'All Locations';
    private customerId: number;
    private studyVaultName: string;
    private flowLoadImportFile: string;
    private telemetryImportFile: string;

    public loadingLocations = false;
    public isLoading = false;
    public studyName: string;
    public studyDescription: string;
    public locationGroups: Array<SelectItem> = [];
    public locationGroupsUnavilable = false;

    public studyLocationGroupSummary: LocationGroupMonitorSummary;
    public currentLocationGroupSummary: LocationGroupMonitorSummary;
    public areLocationsChangeInsideGroup = false;

    public invalidMonitors: boolean;
    public startDate: Date;
    public endDate: Date;
    public daysInStudy: number;
    public stepLength: number;
    public maxDate: Date = new Date();
    public studyStepLengthOptions: Array<SelectItem> = STEP_LENGTH_OPTIONS;
    public isImportedStudy: boolean;

    public dateFormat: string;
    public dateFormatUppercase: string;
    public invalidStartDate = false;
    public invalidEndDate = false;
    public invalidDates: boolean;
    public datesTouched = false;

    public deleteCaseStudyText: string;
    public deleteCaseStudySuccessfulTxt: string;
    public deleteCaseStudyFailureTxt: string;

    // translated strings
    private importedStudyDataRefreshSuccessText: string;
    private importedStudyDataRefreshFailedText: string;
    private studyDataRefreshFailedText: string;
    private configurationSaveSuccessText: string;
    private configurationSaveFailText: string;
    private duplicateNameSaveFailText: string;
    author: string;
    createDate: string;
    modifiedDate: string;
    public shouldIncludeInactiveLocations: boolean;
    private caseStudyOriginalData: SliicerCaseStudy;
    public caseStudyData: SliicerCaseStudyConfigForm = {};
    private caseStudyDataCopy: SliicerCaseStudyConfigForm;
    openDialog: boolean;
    cancelText: any;
    okText: any;
    titleText: any;
    lockedErrorMessage: string;
    telemetryDatbaseName: string;
    flowMonitors
    rainMonitors

    public earliestAllowedDate: Date;
    public latestAllowedDate: Date;
    public isDatesOutside = false;
    public dateOutsideErrorParams: { startDate?: string, endDate?: string } = {};

    public noDataFlowMonitorError: boolean;
    public noDataRainMonitorError: boolean;

    public shouldRefreshStudy$: Observable<boolean>;

    // #38612 Check existing study name
    public existingStudyNameError: boolean;
    private studyCheckNameAssoc: {[key: string]:boolean};
    public studyCheckNameLoaded: boolean;
    private caseStudyObservable: Subscription;

    constructor(
        private topNav: TopnavComponent,
        private sliicerService: SliicerService,
        private dialog: MatDialog,
        private locationGroupService: LocationGroupService,
        private utilService: UiUtilsService,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef,
        private customerService: CustomerService,
        private statusCodeService: StatusCodeService,
        private dateUtilService: DateutilService,
        public updatesWidgetService: UpdatesWidgetService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private datePipe: DatePipe
    ) { }

    public ngOnInit() {
        this.shouldRefreshStudy$ = this.sliicerService.studyDetailsData$.pipe(
            map((study: SliicerCaseStudy) => {
                if (!study.meta || !study.meta.basinState) {
                    return false;
                }

                return study.meta.basinState !== BasinStateEnum.ready;
            })
        );
        this.caseStudyDataCopy = this.caseStudyData = {};

        this.subscriptions.push(
            this.updatesWidgetService.updatesAction.subscribe((action) => {
                if (this.updatesWidgetService.updatesModel === UPDATES_MODEL) {
                    switch (action) {
                        default:
                            break;
                        case 'undoChanges':
                            this.undoChanges();
                            break;
                        case 'applyChanges':
                            this.updateStudy();
                            break;
                    }
                }
            }),
        );

        this.subscriptions.push(
            this.sliicerService.caseStudyDetails
                .pipe(filter((data) => !!data))
                .subscribe((caseStudyData: SliicerCaseStudy) => {
                    this.caseStudyOriginalData = caseStudyData;
                    this.caseStudyId = caseStudyData.id;
                    this.customerId = caseStudyData.customerId;
                    if (caseStudyData.meta) {
                        this.author = caseStudyData.meta.authors ? caseStudyData.meta.authors[0].name : '';
                        this.createDate = caseStudyData.meta.created ? caseStudyData.meta.created.toString() : '';
                        this.modifiedDate = caseStudyData.meta.lastModified ? caseStudyData.meta.lastModified.toString() : '';
                        this.studyName = caseStudyData.meta.name;
                        this.studyDescription = caseStudyData.meta.desc;
                        this.telemetryDatbaseName = caseStudyData.meta.telemetryDatabaseName;
                        this.isStudyLocked = caseStudyData.meta.locked;
                    }


                    if(caseStudyData.config) {
                        this.studyLocationGroupSummary = {
                            fetched: true,
                            locationGroupId: caseStudyData.config.locationGroup?.id,
                            locationGroupName: caseStudyData.config.locationGroup?.name,
                            flowMonitorNames: caseStudyData.config.flowMonitors,
                            rainfallMonitorNames: caseStudyData.config.rainfallMonitors,
                            levelMonitorNames: null
                        }

                        this.rainMonitors = [];
                        this.flowMonitors = [];
                        this.updateCurrentLocationGroupSummary();
                    }

                    this.isImportedStudy = this.sliicerService.getImportedStatus();

                    if (caseStudyData.config) {
                        this.locationGroupId =
                            caseStudyData.config && caseStudyData.config.locationGroup !== null
                                ? caseStudyData.config.locationGroup.id
                                : 0;
                        this.caseStudyData.selectedLocationGroupId = this.locationGroupId;

                        this.caseStudyData.name = this.studyName;
                        this.caseStudyData.desc = this.studyDescription;

                        this.caseStudyData.startDate = new Date(caseStudyData.config.startDate);
                        this.caseStudyData.endDate = new Date(caseStudyData.config.endDate);

                        this.daysInStudy = SliicerService.GetDaysInStudy(
                            caseStudyData.config.startDate,
                            caseStudyData.config.endDate,
                        );
                        this.caseStudyData.stepLength = caseStudyData.config.stepLengthMinutes;
                        this.caseStudyData.includeInactiveLocations = caseStudyData.includeInactiveLocations;

                        this.getLocationDetails(this.customerId, this.studyLocationGroupSummary, false);
                    }
                    this.caseStudyDataCopy = { ...this.caseStudyData }

                    this.fetchLocationGroups(this.customerId);

                    this.dateUtilService.dateFormat.subscribe((newDateFormat) => {
                        this.dateFormat = this.dateUtilService.getStringFormat(newDateFormat);
                        this.dateFormatUppercase = this.dateFormat.toUpperCase();
                    });

                    this.locationGroupService.locationGroupEdited.subscribe((lid) => {
                        if (lid !== null) this.fetchLocationGroups(this.customerId);
                    });

                    this.locationGroupService.locationGroupDeleted.subscribe((lid) => {
                        if (lid !== null) this.fetchLocationGroups(this.customerId);
                    });

                    this.utilService.safeChangeDetection(this.cdr);
                }),
        );
        // subscribe to changes in location groups so new location groups added can be used for case study
        this.subscriptions.push(
            this.statusCodeService.isLocationGroupsModified.subscribe((result) => {
                if (result) {
                    this.fetchLocationGroups(this.customerId);
                }
            }),
        );

        const translateKeys: Array<string> = [
            'SLIICER_TABLE.TELEMETRY.IMPORTED_STUDY_DATA_REFRESH_SUCCESS',
            'SLIICER_TABLE.TELEMETRY.STUDY_DATA_REFRESH_FAILED',
            'SLIICER_TABLE.TELEMETRY.STUDY_DATA_REFRESH_SUCCESS',
            'SLIICER.EDIT_CONFIG.SAVE_SUCCESS',
            'SLIICER.EDIT_CONFIG.SAVE_FAIL',
            'SLIICER.EDIT_CONFIG.DUPLICATE_NAME_SAVE_FAIL',
            'SLIICER.EDIT_CONFIG.SLIICER_STUDY_UPDATE_LOCKED',
            'SLIICER_TABLE.SLIICER_DELETE_DIALOG_CONTENT',
            'SLIICER_TABLE.SLIICER_DELETE_SUCCESS_TEXT',
            'SLIICER_TABLE.SLIICER_DELETE_FAILURE_TEXT',
            'SLIICER.COMMON.CANCEL_BUTTON_TITLE',
            'SLIICER.COMMON.DELETE_BUTTON_TITLE',
            'SLIICER_TABLE.SLIICER_DIALOG_TITLE'
        ];
        this.translate.get(translateKeys).subscribe((translateValues) => {
            this.importedStudyDataRefreshSuccessText =
                translateValues['SLIICER_TABLE.TELEMETRY.IMPORTED_STUDY_DATA_REFRESH_SUCCESS'];
            this.importedStudyDataRefreshFailedText =
                translateValues['SLIICER_TABLE.TELEMETRY.STUDY_DATA_REFRESH_FAILED'];
            // TODO: WPS - this is less than awesome
            this.studyDataRefreshFailedText = this.importedStudyDataRefreshFailedText;
            this.configurationSaveSuccessText = translateValues['SLIICER.EDIT_CONFIG.SAVE_SUCCESS'];
            this.configurationSaveFailText = translateValues['SLIICER.EDIT_CONFIG.SAVE_FAIL'];
            this.duplicateNameSaveFailText = translateValues['SLIICER.EDIT_CONFIG.DUPLICATE_NAME_SAVE_FAIL'];
            this.deleteCaseStudyText = translateValues['SLIICER_TABLE.SLIICER_DELETE_DIALOG_CONTENT'];
            this.deleteCaseStudySuccessfulTxt = translateValues['SLIICER_TABLE.SLIICER_DELETE_SUCCESS_TEXT'];
            this.deleteCaseStudyFailureTxt = translateValues['SLIICER_TABLE.SLIICER_DELETE_FAILURE_TEXT'];
            this.cancelText = translateValues['SLIICER.COMMON.CANCEL_BUTTON_TITLE'];
            this.okText = translateValues['SLIICER.COMMON.DELETE_BUTTON_TITLE'];
            this.titleText = translateValues['SLIICER_TABLE.SLIICER_DIALOG_TITLE'];
            this.lockedErrorMessage = translateValues['SLIICER.EDIT_CONFIG.SLIICER_STUDY_UPDATE_LOCKED'];
        });

    }

    public ngOnDestroy() {
        this.rainMonitors = null;
        this.flowMonitors = null;
        this.subscriptions.forEach((subscripton) => subscripton.unsubscribe());
    }

    public onLocationGroupSummaryChange() {
        if(!this.studyLocationGroupSummary
            || !this.currentLocationGroupSummary
            || !this.currentLocationGroupSummary.fetched
        ) {
            this.areLocationsChangeInsideGroup = false;
            return;
        }

        // #32831 Cannot just do _.isEqual() - they do not equal, they just do have same ID
        const studyFlowLocations = [];
        const studyRainLocations = [];
        for(const l of this.studyLocationGroupSummary?.flowMonitorNames) studyFlowLocations[l.id] = true;
        for(const l of this.studyLocationGroupSummary?.rainfallMonitorNames) studyRainLocations[l.id] = true;

        this.areLocationsChangeInsideGroup = !(
            this.studyLocationGroupSummary?.flowMonitorNames?.length === this.currentLocationGroupSummary?.flowMonitorNames?.length
            && this.studyLocationGroupSummary?.rainfallMonitorNames?.length === this.currentLocationGroupSummary?.rainfallMonitorNames?.length
            && this.currentLocationGroupSummary?.flowMonitorNames?.every(l => studyFlowLocations[l.id])
            && this.currentLocationGroupSummary?.rainfallMonitorNames?.every(l => studyRainLocations[l.id])
        );
    }

    public updateCurrentLocationGroupSummary() {
        this.currentLocationGroupSummary = this.locationGroupMonitorSummaries.find(
            (summ) => summ.locationGroupId === this.caseStudyData.selectedLocationGroupId,
        );
        if(this.currentLocationGroupSummary) {
            this.getLocationDetails(this.customerId, this.currentLocationGroupSummary, true, false);
        }

        this.onLocationGroupSummaryChange();
    }


    /**
     * Method triggered when the user changes the location group to use for the study
     * @param event
     */
    public onLocationGroupSelected(event, update = false) {

        // tslint:disable-next-line:max-line-length
        this.studyLocationGroupSummary = this.locationGroupMonitorSummaries.find(
            (summ) => summ.locationGroupId === this.caseStudyData.selectedLocationGroupId,
        );

        if (!this.studyLocationGroupSummary) {
            // Happens after currently selected location group was deleted
            this.locationGroupId = 0;
            this.caseStudyData.selectedLocationGroupId = 0;
            this.caseStudyDataCopy = { ... this.caseStudyData };
            this.studyLocationGroupSummary = this.locationGroupMonitorSummaries.find(
                (summ) => summ.locationGroupId === 0);
        } else {
            this.locationGroupId = this.caseStudyData.selectedLocationGroupId;

            if (this.studyLocationGroupSummary.fetched && !update) {
                this.invalidMonitors = !SliicerService.CheckMonitorsValid(this.studyLocationGroupSummary);
                this.setAllowedDatesForLocationGroup(this.studyLocationGroupSummary, true);
                this.utilService.safeChangeDetection(this.cdr);
                this.onAfterRemoteChanges();
                return;
            }
        }

        this.getLocationDetails(this.customerId, this.studyLocationGroupSummary);
        this.updateCurrentLocationGroupSummary();
    }

    public studyNameChange(name: string) {
        // #38612 Check for existing names
        if(!this.studyCheckNameAssoc) {
            if(this.caseStudyObservable) {
                // #38612 just ignore, will be checked after subscription will resolve
            } else {
                this.studyCheckNameLoaded = false;
                this.caseStudyObservable = this.sliicerService.getCaseStudySummary(this.customerId).subscribe(
                    (result: SliicerCaseStudy[]) => {
                        const studyAssoc: {[key: string]:boolean} = {};
                        result.forEach((study) => studyAssoc[study.meta.name.toLowerCase()] = true);
                        this.studyCheckNameAssoc = studyAssoc;
                        this.studyCheckNameLoaded = true;

                        this.existingStudyNameError = !!this.studyCheckNameAssoc[this.caseStudyData.name.toLowerCase()];
                        this.onInstantChanges();
                        this.caseStudyObservable.unsubscribe();
                        delete this.caseStudyObservable;
                    }
                );
            }
        } else {
            this.existingStudyNameError = !!this.studyCheckNameAssoc[name.toLowerCase()];
            this.onInstantChanges();
        }
    }

    /**
     * Method triggered when user clicks "Details" to find out about the flow and rainfall monitors currently selected
     */
    public showMonitorDetails() {

        if (this.studyLocationGroupSummary.flowMonitorNames && this.studyLocationGroupSummary.flowMonitorNames.length > 0) {
            this.studyLocationGroupSummary.flowMonitorNames.sort();
        }

        if (this.studyLocationGroupSummary.rainfallMonitorNames && this.studyLocationGroupSummary.rainfallMonitorNames.length > 0) {
            this.studyLocationGroupSummary.rainfallMonitorNames.sort();
        }


        this.dialog
            .open(AdsDetailsDialogComponent, {
                disableClose: true,
                data: {
                    study: this.studyLocationGroupSummary,
                }
            })
            .afterClosed();
    }

    public showMonitorCompareDetails() {
        if (this.studyLocationGroupSummary.flowMonitorNames && this.studyLocationGroupSummary.flowMonitorNames.length > 0) {
            this.studyLocationGroupSummary.flowMonitorNames.sort();
        }
        if (this.studyLocationGroupSummary.rainfallMonitorNames && this.studyLocationGroupSummary.rainfallMonitorNames.length > 0) {
            this.studyLocationGroupSummary.rainfallMonitorNames.sort();
        }
        if (this.currentLocationGroupSummary.flowMonitorNames && this.currentLocationGroupSummary.flowMonitorNames.length > 0) {
            this.currentLocationGroupSummary.flowMonitorNames.sort();
        }
        if (this.currentLocationGroupSummary.rainfallMonitorNames && this.currentLocationGroupSummary.rainfallMonitorNames.length > 0) {
            this.currentLocationGroupSummary.rainfallMonitorNames.sort();
        }

        this.dialog
            .open(AdsDetailsDialogComponent, {
                disableClose: true,
                data: {
                    study: this.studyLocationGroupSummary,
                    current: this.currentLocationGroupSummary
                }

            })
            .afterClosed()
            .subscribe(updateStudyLocations => {
                if(updateStudyLocations) {
                    this.flowMonitors = this.currentLocationGroupSummary.flowMonitorNames;
                    this.rainMonitors = this.currentLocationGroupSummary.rainfallMonitorNames;
                    this.updateCaseStudyLocations();
                }
            })
    }

    public switchInactive() {
        this.fetchLocationGroups(this.customerId)
        this.onInstantChanges();
    }

    private isFormCorrect() {
        return !this.existingStudyNameError && !this.noDataRainMonitorError && !this.noDataFlowMonitorError;
    }

    private checkUpdatesInfo() {
        if (this.isFormCorrect() && this.getChangeValue()) {
            this.configurationFormValidation.emit(true);
            const updatesInfo: UpdateInfo[] = [];
            SliicerCaseStudyEditConfigComponent.AddUpdateInfo(updatesInfo, this.getInfo(this.caseStudyData));
            this.updatesWidgetService.setUpdatesInfo(updatesInfo, UPDATES_MODEL);
        } else {
            this.configurationFormValidation.emit(this.isFormCorrect());
            this.clearUpdatesInfo();
        }
    }

    private clearUpdatesInfo() {
        this.updatesWidgetService.updatesLoader = false;
        this.updatesWidgetService.setUpdatesInfo([], '');
    }

    public onInstantChanges() {
        this.checkUpdatesInfo();
        this.checkIfStudyDatesOutsideFinalData();
    }

    public onRemoteChanges() {
        this.clearUpdatesInfo();
        this.checkIfStudyDatesOutsideFinalData();
    }

    public onAfterRemoteChanges() {
        this.checkUpdatesInfo();
    }

    public somethingChanged() {
        this.checkIfStudyDatesOutsideFinalData();
    }

    /**
     * Method to validate case study start date on keyboard date entry or from datepicker
     * @param event containing startdate entered
     */
    public onStartDateChanged(event) {
        this.datesTouched = true;
        let val = event.value ? event.value : event.target.value;
        let valStr = event.targetElement ? event.targetElement.value : event.target.value;
        if (this.dateFormatUppercase == "DD/MM/YYYY" && !event.targetElement) {
            const dateMomentObject = moment(valStr, "DD/MM/YYYY");
            valStr = val = new Date(dateMomentObject.toString());
        }
        const newDate = this.sliicerService.validateDate(val, valStr);

        this.invalidStartDate = !newDate;

        if (newDate) {
            this.caseStudyData.startDate = newDate;
            this.validateDates();
            this.checkIfStudyDatesOutsideFinalData();
        }
        if (!this.invalidDates && !this.invalidStartDate) {
            this.onInstantChanges();
        } else {
            this.configurationFormValidation.emit(false);
            this.clearUpdatesInfo();
        }
        this.utilService.safeChangeDetection(this.cdr);
    }

    /**
     * Method to validate case study end date on keyboard date entry or from datepicker
     * @param event containing enddate entered
     */
    public onEndDateChanged(event) {
        this.datesTouched = true;
        let val = event.value ? event.value : event.target.value;
        let valStr = event.targetElement ? event.targetElement.value : event.target.value;
        if (this.dateFormatUppercase == "DD/MM/YYYY" && !event.targetElement) {
            const dateMomentObject = moment(valStr, "DD/MM/YYYY");
            valStr = val = new Date(dateMomentObject.toString());
        }
        const newDate = this.sliicerService.validateDate(val, valStr);

        this.invalidEndDate = !newDate;

        if (newDate) {
            this.caseStudyData.endDate = newDate;
            this.validateDates();
            this.checkIfStudyDatesOutsideFinalData();
        }
        if (!this.invalidDates && !this.invalidEndDate) {
            this.onInstantChanges();
        } else {
            this.configurationFormValidation.emit(false);
            this.clearUpdatesInfo();
        }
        this.utilService.safeChangeDetection(this.cdr);
    }

    private validateDates() {
        if (this.caseStudyData.startDate && this.caseStudyData.endDate && !this.invalidStartDate && !this.invalidEndDate) {
            this.daysInStudy = SliicerService.GetDaysInStudy(this.caseStudyData.startDate.toString(), this.caseStudyData.endDate.toString());
            this.invalidDates = this.daysInStudy < 1;
        } else {
            this.invalidDates = !this.invalidStartDate && !this.invalidEndDate && !this.caseStudyData.endDate && !this.caseStudyData.startDate;
            this.daysInStudy = 0;
        }
    }

    /**
     * Method to re-import the flow-load file
     */
    public reloadImportedTelemetryData() {
        this.customerService.getCustomerById(this.customerId).subscribe((currentCustomer) => {
            if (currentCustomer && currentCustomer.shortName) {
                const fileUri = this.studyVaultName + '/' + this.flowLoadImportFile;
                // TODO: WPS - I am pretty sure this is NOT always correct. Confirm with the API
                //      code and find a way to have a the API provides this information to the
                //      front end.
                const customerVaultName = currentCustomer.shortName.toLowerCase();
                this.runFlowLoadImport(this.customerId, customerVaultName, fileUri, this.caseStudyId);
            }
        });
    }

    /**
     * Method to re-import the telemetry data for all the flow and rainfall monitors
     * NOTE: This _only_ gets triggered when editing the configuration
     */
    public refreshTelemetryData() {
        this.isLoading = true;

        this.rainMonitors = [];
        this.flowMonitors = [];
        this.sliicerService.refreshStudyTelemetryData(this.customerId, this.caseStudyId).subscribe(
            () => {
                this.isLoading = false;
            },
            () => {
                this.isLoading = false;
                this.sliicerService.showToast(this.studyDataRefreshFailedText, true);
            },
        );
    }

    /**
     * Update a study with new information
     */
    public updateStudy() {
        this.updateCaseStudy();
    }

    public undoChanges() {
        this.caseStudyData = { ...this.caseStudyDataCopy }
        this.fetchLocationGroups(this.customerId);
        const updatesInfo: UpdateInfo[] = [];
        this.updatesWidgetService.setUpdatesInfo(updatesInfo, UPDATES_MODEL);
        this.validateDates();
        this.checkIfStudyDatesOutsideFinalData();
    }

    private generateUpdateCaseStudyRequest() {
        // TODO: WPS - modify this to send only the config since that is what we are changing
        const dataToSend = { ...this.caseStudyOriginalData }
        dataToSend.meta.name = this.caseStudyData.name;
        dataToSend.meta.desc = this.caseStudyData.desc;
        // all locations will not include locationGroup bug:22910
        if (dataToSend.config.locationGroup) {
            dataToSend.config.locationGroup.id = this.caseStudyData.selectedLocationGroupId;
        }
        else if (this.caseStudyData.selectedLocationGroupId) {
            dataToSend.config['locationGroup'] = { id: this.caseStudyData.selectedLocationGroupId, name: '' };
        }
        dataToSend.config.stepLengthMinutes = this.caseStudyData.stepLength;
        dataToSend.includeInactiveLocations = this.caseStudyData.includeInactiveLocations;
        dataToSend.config.startDate = this.dateUtilService.getStartDateAsTimeZone(this.caseStudyData.startDate);
        dataToSend.config.endDate = this.dateUtilService.getEndDateAsTimeZone(this.caseStudyData.endDate);
        dataToSend.config.flowMonitors = this.flowMonitors as Array<Monitor>;
        dataToSend.config.rainfallMonitors = this.rainMonitors as Array<Monitor>;
        const updatesInfo: UpdateInfo[] = [];
        this.updatesWidgetService.setUpdatesInfo(updatesInfo, UPDATES_MODEL);
        return this.sliicerService.putStudyConfig(this.customerId, this.caseStudyId, dataToSend).pipe(
            tap((result) => {
                if (result) {
                    this.caseStudyDataCopy = { ...this.caseStudyData };
                    this.caseStudyOriginalData = result;
                    this.sliicerService.caseStudyDetails.next(result);
                    this.sliicerService.showToast(this.configurationSaveSuccessText);
                    this.editComplete.emit();
                } else {
                    const updatesInfo: UpdateInfo[] = [];
                    SliicerCaseStudyEditConfigComponent.AddUpdateInfo(updatesInfo, this.getInfo(this.caseStudyData));
                    this.updatesWidgetService.setUpdatesInfo(updatesInfo, UPDATES_MODEL);
                }
            })
        );
    }

    private generateUpdateCaseStudyLocationsRequest() {
        const dataToSend = { ...this.caseStudyOriginalData }
        dataToSend.config.flowMonitors = this.flowMonitors as Array<Monitor>;
        dataToSend.config.rainfallMonitors = this.rainMonitors as Array<Monitor>;
        dataToSend.includeInactiveLocations = this.caseStudyData.includeInactiveLocations;
        return this.sliicerService.putStudyConfig(this.customerId, this.caseStudyId, dataToSend).pipe(
            tap((result) => {
                if (result) {
                    this.caseStudyDataCopy = { ...this.caseStudyData };
                    this.caseStudyOriginalData = result;
                    this.sliicerService.caseStudyDetails.next(result);
                    this.sliicerService.showToast(this.configurationSaveSuccessText);
                    this.editComplete.emit();
                } else {
                }
            })
        );
    }

    private updateCaseStudy() {
        this.updateCaseStudyRaw(this.generateUpdateCaseStudyRequest())
    }

    private updateCaseStudyLocations() {
        this.updateCaseStudyRaw(this.generateUpdateCaseStudyLocationsRequest())
    }

    private updateCaseStudyRaw(request: Observable<SliicerCaseStudy>) {
        this.isLoading = true;
        const dataToSend = { ...this.caseStudyOriginalData };

        if (dataToSend && dataToSend.meta && this.isStudyLocked) {
            this.isLoading = false;
            this.sliicerService.showToast(this.lockedErrorMessage, true);
            this.undoChanges();
        } else {
            request.subscribe(
                (ignoreResult) => {
                    this.sliicerService.showToast(this.configurationSaveSuccessText);
                    this.isLoading = false;


                    delete this.studyCheckNameAssoc;
                    delete this.studyCheckNameLoaded;
                    delete this.caseStudyObservable;
                },
                (error: HttpErrorResponse) => {
                    this.isLoading = false;

                    const errorStatus = error.status;
                    if (errorStatus === HTTP_STATUS_CODE_CONFLICT) {
                        this.sliicerService.showToast(this.duplicateNameSaveFailText, true);
                    } else {
                        this.sliicerService.showToast(this.configurationSaveFailText, true);
                    }

                    this.undoChanges();
                },
            );
        }
    }

    public clearLocationGroups() {
        this.locationGroups = [{ id: 0, text: this.allLocationsString }];
        this.locationGroupMonitorSummaries = this.locationGroups.map((lg) => {
            return {
                locationGroupId: lg.id,
                locationGroupName: lg.text,
                fetched: false,
                rainfallMonitorNames: null,
                flowMonitorNames: null,
                levelMonitorNames: null,
            };
        });
    }

    private fetchLocationGroups(customerId: number) {
        if (!customerId) return;

        this.clearLocationGroups();

        this.subscriptions.push(
            this.locationGroupService.getLocationGroups(customerId).subscribe(
                (result: any) => {

                    this.locationGroupsUnavilable = false;

                    if (result.locationGroups && result.locationGroups.length) {
                        this.clearLocationGroups();
                        result.locationGroups.forEach((lg) => {
                            this.locationGroups.push({ id: lg.locationGroupID, text: lg.name });

                            const index = this.locationGroupMonitorSummaries.findIndex(
                                (summ) => summ.locationGroupId === lg.locationGroupID,
                            );
                            if (index < 0) {
                                this.locationGroupMonitorSummaries.push({
                                    locationGroupId: lg.locationGroupID,
                                    locationGroupName: lg.name,
                                    fetched: false,
                                    rainfallMonitorNames: null,
                                    flowMonitorNames: null,
                                    levelMonitorNames: null,
                                });
                            } else {
                                this.locationGroupMonitorSummaries[index].locationGroupName = lg.name;
                            }
                        });
                    }
                    if(!this.studyLocationGroupSummary) {
                        // #32831 If study is not set up, then set up it with default location group
                        this.onLocationGroupSelected(this.locationGroupId, true)
                    } else {
                        this.updateCurrentLocationGroupSummary();
                    }
                    this.utilService.safeChangeDetection(this.cdr);
                },
                (error) => {
                    this.locationGroupsUnavilable = true;
                    this.utilService.safeChangeDetection(this.cdr);
                },
            ),
        );
    }

    private checkIfStudyDatesOutsideFinalData() {
        this.isDatesOutside = new Date(this.caseStudyData.startDate).getTime() < new Date(this.earliestAllowedDate).getTime()
            || new Date(this.caseStudyData.endDate).getTime() > new Date(this.latestAllowedDate).getTime();
    }

    private setAllowedDatesForLocationGroup(monitor: MonitorCounts, isFromCache = false) {
        this.checkRequiredMonitors(monitor, isFromCache);
        this.updatesWidgetService.updateApplyStatus.next(false);
        if (this.invalidMonitors || this.noDataFlowMonitorError) {
            this.updatesWidgetService.updateApplyStatus.next(true);
            return;
        }
        // we have different prop names when its from API and cache
        const monitorKeys = isFromCache ? ['flowMonitorNames', 'levelMonitorNames', 'rainfallMonitorNames'] : ['flowMonitors', 'levelMonitors', 'rainMonitors'];

        // set earliest allowed date for study
        const earliestDates = monitorKeys.map((key: string) => SliicerService.getEarliestDate(monitor[key]));
        this.earliestAllowedDate = new Date(new Date(Math.min(...earliestDates.filter(v => !!v))).setHours(0, 0, 0, 0));

        // set latest allowed date for study
        const latestDates = monitorKeys.map((key: string) => SliicerService.getLatestDate(monitor[key]));
        this.latestAllowedDate = new Date(new Date(Math.max(...latestDates.filter(v => !!v))).setHours(23, 59, 59));

        this.dateOutsideErrorParams = {
            startDate: this.datePipe.transform(this.earliestAllowedDate, this.dateFormat),
            endDate: this.datePipe.transform(this.latestAllowedDate, this.dateFormat)
        }

        this.checkIfStudyDatesOutsideFinalData();
        if (!this.invalidDates && !this.invalidStartDate && !this.inStudyWizard) {
            this.onInstantChanges();
        }
    }

    private getLocationDetails(customerId: number, locationGroupSummary: LocationGroupMonitorSummary, overrideLocationGroupSummary = true, checkForm = true) {
        if (!customerId || (this.flowMonitors && this.flowMonitors.length && this.rainMonitors && this.rainMonitors.length)) return;

        const locationGroupArray =
            locationGroupSummary.locationGroupId !== 0 ? [locationGroupSummary.locationGroupId] : null;
        this.loadingLocations = true;
        this.subscriptions.push(
            this.sliicerService.getMonitorNames(customerId, locationGroupArray, this.caseStudyData.includeInactiveLocations).subscribe(
                (counts: MonitorCounts) => {
                    this.loadingLocations = false;
                    if (counts) {
                        if(overrideLocationGroupSummary) {
                            this.flowMonitors = counts.flowMonitors;
                            this.rainMonitors = counts.rainMonitors;

                            locationGroupSummary.fetched = true;

                            locationGroupSummary.levelMonitorNames = counts.levelMonitors !== null ? counts.levelMonitors : [];
                            locationGroupSummary.flowMonitorNames = counts.flowMonitors !== null ? counts.flowMonitors : [];
                            locationGroupSummary.rainfallMonitorNames = counts.rainMonitors !== null ? counts.rainMonitors : [];

                            // if we are fetching the location group details for the location group id that is specified in the summary
                            // we want to see if there are differences in what is in the study and what is being returned to use from
                            // the API. That way we can indicate to the user that things in the location group are different than what
                            // was originally imported in the study.
                            if (locationGroupSummary.locationGroupId === this.locationGroupId) {
                                locationGroupSummary.flowMonitorNames = counts.flowMonitors !== null ? counts.flowMonitors : [];
                                locationGroupSummary.rainfallMonitorNames = counts.rainMonitors !== null ? counts.rainMonitors : [];
                                // TODO: WPS - make the necessary checks here
                            } else {
                                locationGroupSummary.flowMonitorNames = counts.flowMonitors !== null ? counts.flowMonitors : [];
                                locationGroupSummary.rainfallMonitorNames = counts.rainMonitors !== null ? counts.rainMonitors : [];
                            }
                            this.invalidMonitors = !SliicerService.CheckMonitorsValid(this.studyLocationGroupSummary);
                            this.onLocationGroupSummaryChange();
                        } else {
                            // #32831 we rely on original study locations, just update theirs info here
                            const flowMonitors = [];
                            const rainMonitors = [];

                            if(counts.flowMonitors) for(const m of counts.flowMonitors) flowMonitors[m.id] = m;
                            if(counts.rainMonitors) for(const m of counts.rainMonitors) rainMonitors[m.id] = m;

                            locationGroupSummary.levelMonitorNames = counts.levelMonitors !== null ? counts.levelMonitors : [];
                            locationGroupSummary.flowMonitorNames = locationGroupSummary.flowMonitorNames.map(m => flowMonitors[m.id] || m);
                            locationGroupSummary.rainfallMonitorNames = locationGroupSummary.rainfallMonitorNames.map(m => rainMonitors[m.id] || m);
                        }

                        if(checkForm) {
                            // #32831 Check study monitors, not location group monitors
                            const filteredCounts: MonitorCounts = {
                                locationGroupId: counts.locationGroupId,
                                locationGroupName: counts.locationGroupName,
                                levelMonitors: counts.levelMonitors,
                                flowMonitors: locationGroupSummary.flowMonitorNames,
                                rainMonitors: locationGroupSummary.rainfallMonitorNames
                            }
                            this.setAllowedDatesForLocationGroup(filteredCounts);
                        }
                    } else {
                        // TODO: WPS - not sure what to do here. Why would this not return an error?
                    }
                    this.onAfterRemoteChanges();
                    this.utilService.safeChangeDetection(this.cdr);
                },
                (error) => {
                    this.loadingLocations = false;
                    this.onAfterRemoteChanges();
                },
            ),
        );
    }

    public deleteCaseStudy() {
        this.openDialog = true;
        this.dialog
            .open(ConfirmationDialogComponent, {
                data: {
                    title: this.titleText,
                    message: this.deleteCaseStudyText + ' ' + this.caseStudyData.name + '?',
                    cancelText: this.cancelText,
                    okText: this.okText,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                this.openDialog = false;
                if (res.whichButtonWasPressed === 'ok') {
                    this.isLoading = true;
                    // tslint:disable-next-line:max-line-length
                    this.sliicerService
                        .deleteCaseStudy(this.customerId, this.caseStudyId, this.telemetryDatbaseName)
                        .subscribe(
                            (result) => {
                                this.isLoading = false;
                                this.navigateToSliicerStudyDashoard(this.customerId)
                                this.utilService.safeChangeDetection(this.cdr);
                                this.sliicerService.showToast(this.deleteCaseStudySuccessfulTxt);
                            },
                            (error) => {
                                this.isLoading = false;
                                this.utilService.safeChangeDetection(this.cdr);
                                this.sliicerService.showToast(this.deleteCaseStudyFailureTxt, true);
                            },
                        );
                }
            });
    }

    private navigateToSliicerStudyDashoard(customerId: number) {
        const appQueryParams = <AppQueryParams>{
            [customerQueryParam]: customerId,
        };
        // TODO: WPS - There is a warning here about an unused promise. Suggests that there might be some states that we need to handle.
        //       If we can ignore the different states then add a TS-lint ignore statement here.

        const redirectOptions = {
            queryParams: appQueryParams,
            relativeTo: this.activatedRoute,
        };

        // Its done to be sure, that angular will destoy sliicer studies list component, (it always keeps last visited components alive,
        // default behaviour of angular)
        this.router.navigateByUrl('/', { ...redirectOptions, skipLocationChange: true }).then(() => {
            this.router.navigate(['/pages/sliicer'], redirectOptions);
        });
    }

    private runFlowLoadImport(customerId: number, customerVaultName: string, fileUri: string, caseStudyId: string) {
        this.isLoading = true;
        this.sliicerService.getFlowLoadImport(customerVaultName, fileUri, customerId, caseStudyId).subscribe(
            (result) => {
                if (result && result['status'] === 'success') {
                    const telemFileUri = this.studyVaultName + '/' + this.telemetryImportFile;
                    this.sliicerService
                        .legacyCopyTelemetry(customerVaultName, telemFileUri, customerId, caseStudyId)
                        .subscribe(
                            (r2) => {
                                this.isLoading = false;
                                this.sliicerService.showToast(this.importedStudyDataRefreshSuccessText);
                            },
                            () => {
                                this.isLoading = false;
                                this.sliicerService.showToast(this.importedStudyDataRefreshFailedText, true);
                            },
                        );
                } else {
                    this.isLoading = false;
                    this.sliicerService.showToast(this.importedStudyDataRefreshFailedText, true);
                    if (result && result['detail']) {
                        const message: string = result['detail'];
                        if (
                            message.startsWith(
                                "\"System.InvalidOperationException: The 'Microsoft.Jet.OLEDB.4.0' provider is not registered on the local machine.",
                            )
                        ) {
                            // TODO: WPS - send a message to engineers that the SLiiCER import docker container needs
                            //      to have the Jet driver re-installed. Sometimes during the build process the Jet
                            //      installer is not run :(
                        }
                    }
                }
            },
            () => {
                this.isLoading = false;
                this.sliicerService.showToast(this.importedStudyDataRefreshFailedText, true);
            },
        );
    }

    public getChangeValue(): boolean {
        // hide save changes popup if there is a date error
        if (this.isDatesOutside) return null;
        return Object.entries(this.caseStudyData).toString() !== Object.entries(this.caseStudyDataCopy).toString();
    }

    // function checks if location group has flow/rain monitors with valid data
    private checkRequiredMonitors(monitor: MonitorCounts, isFromCache = false) {
        const flowKey = isFromCache ? 'flowMonitorNames' : 'flowMonitors';
        const rainKey = isFromCache ? 'rainfallMonitorNames' : 'rainMonitors';
        this.noDataFlowMonitorError = monitor[flowKey].some((m: MonitorName) => m.earliestReading === null && m.latestReading === null);
        this.noDataRainMonitorError = monitor[rainKey].some((m: MonitorName) => m.earliestReading === null && m.latestReading === null);
    }

    /**
     * This will structure and return the dry days update info
     * @param configSettings
     */
    private getInfo(info): UpdateInfo {
        return { title: 'Settings', values: [] };
    }
    /********************************************************************************/
    /* PRIVATE STATIC METHODS                                                       */
    /********************************************************************************/

    /**
     * This will handle the update to the updatesInfo array
     * since the updateInfo can be Object or Array depending on what is updated
     * @param updatesInfo
     * @param updateInfo
     * @constructor
     */
    private static AddUpdateInfo(updatesInfo: UpdateInfo[], updateInfo: UpdateInfo | UpdateInfo[]) {
        if (updateInfo instanceof Array) {
            for (let i = 0; i < updateInfo.length; i++) {
                updatesInfo.push(updateInfo[i]);
            }
        } else {
            updatesInfo.push(updateInfo);
        }
    }
}
