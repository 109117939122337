import { Injectable } from '@angular/core';
import { StormPeriodAdjustment } from 'app/pages/sliicer/sliicer-case-study-details/flow-monitor/flow-monitor.model';
import * as _ from 'underscore';


const MS_PER_MINUTE = 60000;
const ONE_DAY = 24 * 60 * MS_PER_MINUTE;

@Injectable({
    providedIn: 'root',
})
export class DataHelperService {
    constructor() { }

    /**
     * This method will deep strip the data with any structure
     * @param data
     */
    public deepStripEmptyKeys(data: Object | any[]): Object | any[] {
        if (_.isArray(data)) {
            return this.deepStripArray(data);
        } else if (_.isObject(data)) {
            return this.deepStripObject(data);
        }
        return data;
    }

    /********************************************************************************/
    /* PRIVATE METHODS                                                              */
    /********************************************************************************/

    /**
     * This method will deep strip array data
     * @param data
     */
    private deepStripArray(data: any[]): any[] {
        const strippedArray = [];
        for (let i = 0; i < data.length; i++) {
            const strippedValue = this.deepStrip(data[i]);
            if (strippedValue !== null) {
                strippedArray.push(strippedValue);
            }
        }
        if (!_.isEmpty(strippedArray)) {
            return strippedArray;
        }
        return null;
    }

    /**
     * This method will deep strip object data
     * @param data
     */
    private deepStripObject(data: Object): any {
        const strippedObject = {};
        for (const key in data) {
            const strippedValue = this.deepStrip(data[key]);
            if (strippedValue !== null) {
                strippedObject[key] = strippedValue;
            }
        }
        if (!_.isEmpty(strippedObject)) {
            return strippedObject;
        }
        return null;
    }

    /**
     * This method will check and deep strip any data
     * @param data
     */
    private deepStrip(data: Object | any[] | any): Object | any[] | any {
        if (_.isArray(data)) {
            return this.deepStripArray(data);
        } else if (_.isObject(data)) {
            return this.deepStripObject(data);
        } else if (data !== undefined && data !== null && data !== '') {
            return data;
        }
        return null;
    }

    public ChartStartDate(stormPeriods: StormPeriodAdjustment): Date {
        let chartStartDate
        if (stormPeriods.deattachPrecomp) {
            chartStartDate = new Date(stormPeriods.stormStartTime);
            chartStartDate.setTime(chartStartDate.getTime() - stormPeriods.precompPeriodLength * MS_PER_MINUTE);
            chartStartDate.setHours(0, 0, 0);
        } else {
            chartStartDate = new Date(stormPeriods.altPrecompStart);
            chartStartDate.setTime(chartStartDate.getTime());
            chartStartDate.setHours(0, 0, 0);
        }
        return chartStartDate;
    }

    public ChartEndDate(stormPeriods: StormPeriodAdjustment): Date {
        const chartEndDate = new Date(stormPeriods.stormStartTime);
        const totalMins =
            stormPeriods.stormPeriodLength + stormPeriods.recovery1PeriodLength + stormPeriods.recovery2PeriodLength;
        chartEndDate.setTime(chartEndDate.getTime() + totalMins * MS_PER_MINUTE + ONE_DAY);
        return chartEndDate;
    }
}
