import { Injectable } from '@angular/core';
import { Config } from './config';
import { EMPTY, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EchoConfiguration, MonitorSetting, RainAlertConfiguration } from '../models/monitorsetting';
import { catchError, map, retry } from 'rxjs/operators';

@Injectable()
export class MonitorSettingService {
    constructor(public http: HttpClient) {}

    public rainAlert(rainAlertConfiguration: RainAlertConfiguration, customerID: number) {
        return this.http
            .post<RainAlertConfiguration>(Config.urls.configureRainAlert + `${customerID}`, rainAlertConfiguration)
            .pipe(
                map(
                    (result) => {
                        return result;
                    },
                    (error) => {
                        return EMPTY;
                    },
                ),
            );
    }

    public foreSiteUl(configuration, customerID: number) {
        return this.http
            .post<RainAlertConfiguration>(Config.urls.configureForeSiteUl + `${customerID}`, configuration)
            .pipe(
                map(
                    (result) => {
                        return result;
                    },
                    (error) => {
                        return EMPTY;
                    },
                ),
            );
    }

    public foreSITEFlex(configuration, customerID: number) {
        return this.http
            .post<RainAlertConfiguration>(Config.urls.configureforeSITEFlex + `${customerID}`, configuration)
            .pipe(
                map(
                    (result) => {
                        return result;
                    },
                    (error) => {
                        return EMPTY;
                    },
                ),
            );
    }

    public activateLocation(customerId: number, locationid: number, serialnumber: string, ipaddress: string) {
        return this.http
            .post<Object>(
                Config.serviceUrl +
                    Config.urls.activatedMonitor +
                    `${customerId}&LocationID=${locationid}&SerialNumber=${serialnumber}&IPAddress=${ipaddress}`,
                {},
            )
            .pipe(
                catchError((error) => {
                    // console.dir(error.json());
                    return throwError(error);
                }),
                retry(3),
            );
    }

    public echoMonitorConfiguration(echoConfiguration: EchoConfiguration, customerID: number) {
        return this.http.post<MonitorSetting>(Config.urls.configureEchoAlert + `${customerID}`, echoConfiguration).pipe(
            map(
                (result) => {
                    return result;
                },
                (error) => {
                    return EMPTY;
                },
            ),
        );
    }

    public getRainAlertMonitorConfiguration(customerID: number, locationId: number) {
        return this.http
            .get<Object>(Config.urls.configureRainAlert + `${customerID}` + `&locationId=${locationId}`)
            .pipe(
                map(
                    (result) => {
                        return result;
                    },
                    (errror) => {
                        return EMPTY;
                    },
                ),
            );
    }

    public getEchoMonitorConfiguration(customerID: number, locationId: number) {
        return this.http
            .get<Object>(Config.urls.configureEchoAlert + `${customerID}` + `&locationId=${locationId}`)
            .pipe(
                map(
                    (result) => {
                        return result;
                    },
                    (errror) => {
                        return EMPTY;
                    },
                ),
            );
    }

    public foreSiteUlConfiguration(customerID: number, locationId: number) {
        return this.http
            .get<Object>(Config.urls.configureForeSiteUl + `${customerID}` + `&locationId=${locationId}`)
            .pipe(
                map(
                    (result) => {
                        return result;
                    },
                    (errror) => {
                        return EMPTY;
                    },
                ),
            );
    }

    public getForeSITEFlexConfiguration(customerID: number, locationId: number) {
        return this.http
            .get<Object>(Config.urls.configureforeSITEFlex + `${customerID}` + `&locationId=${locationId}`)
            .pipe(
                map(
                    (result) => {
                        return result;
                    },
                    (errror) => {
                        return EMPTY;
                    },
                ),
            );
    }
}
