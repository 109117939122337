import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef, LegacySimpleSnackBar as SimpleSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { ConfirmationDialogComponent } from '../../shared/components/confirmation-dialog/confirmation-dialog.component';
import { UUID } from 'angular2-uuid';
import { Subscription } from 'rxjs';
import { CustomerService } from 'app/shared/services/customer.service';
import { FilterDataSource } from 'app/shared/components/paging/filter-data-source';
import { BehaviorSubject } from 'rxjs';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { StringUtils } from 'app/shared/utils/string-utils';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { FormControl } from '@angular/forms';
import { UsersInfo, UsersDetailInfo } from 'app/shared/models/users';
import { StatusCodeService } from '../../shared/services/status-code.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'app/environments/environment';
import { IDLE_MESSAGE_MINUTES } from 'app/shared/constant';
import { LocationDashboardService } from 'app/shared/services/location-dashboard.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { AppQueryParams, CollectProcessType, Customer, SUBSCRIPTION_LEVELS, SubscriptionLevel, customerQueryParam } from 'app/shared/models/customer';
import { LocationArgs, Locations } from 'app/shared/models/locations';
import { AddLocationModalData } from 'app/shared/models/add-location';
import { AddLocationComponent } from 'app/shared/components/map/add-location/add-location.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-customer-editor',
    templateUrl: './customer-editor.component.html',
    styleUrls: ['./customer-editor.component.scss'],
})
export class CustomerEditorComponent implements OnInit, OnDestroy {
    public showUpdateCustomerEditor: boolean;
    public showNewCustomerEditor: boolean;
    public customers: UsersDetailInfo[];
    public customer: Customer;
    public isValidCustomerName: boolean;
    public isCustomerNameErrorDisplay: boolean;
    public isValidCustomerShortName: boolean;
    public customerName: string;
    public shortName: string;
    public flowViewFlag: boolean;
    public units: number;
    public customerID: number;
    public dataTTL = 12;
    public isActive: boolean;
    public unitsofMeasure: any[];
    public dateFormatType: any[];
    public timeFormatType: any[];
    public timeZoneType: Array<Object>;
    public dateFormat: string;
    public timeFormat: string;
    public timeZone: any;
    public workOrderLink = environment.workOrderLink;
    public idleTimeOut: number = IDLE_MESSAGE_MINUTES;
    public daylightSavingFlag: boolean;
    public headerName = 'CUSTOMER EDITOR';
    public uuid: UUID;
    public pagedItems = new Array<Customer>();
    public setFirstPage: boolean;
    public showPagination = true;
    public apiError: string;
    private subscriptions = new Array<Subscription>();
    public showNewLocationEditor: boolean;
    public disableAddLocationBtn: boolean;
    public customerNameLengthIsInvalid: boolean;
    public customerShortNameLengthIsInvalid: boolean;
    public customersLoadingState: boolean;
    public customerEditorDisplayedColumns = ['customerName', 'editDeleteCustomerEditor'];
    public customerEditorFilterColumns = ['customerName'];
    public customerEditorDataSource: FilterDataSource | null;
    @ViewChild(MatPaginator) public customerEditorPaginator: MatPaginator;
    @ViewChild(MatSort) public customerEditorSort: MatSort;
    public customerEditorDataChange: BehaviorSubject<UsersDetailInfo[]> = new BehaviorSubject<UsersDetailInfo[]>([]);

    public get customerEditorData(): UsersDetailInfo[] {
        return this.customerEditorDataChange.value;
    }
    public totalPaginationLength: number;
    public currentCustomerID: number;

    /**
     * Reg Ex. Pattern for alpha numeric with -, _ and at least 2 letters and first character must be a letter or number
     */
    public customerNamePattern = REGEX_CONFIG.customerNamePattern;

    /**
     * Reg Ex. Pattern for alpha numeric without space
     */
    public alphaNumericWithoutSpacePattern = REGEX_CONFIG.alphaNumericWithoutSpacePattern;

    /**
     * Reg Ex. Pattern for alpha numeric without space
     */
    public numeric3_36Pattern = REGEX_CONFIG.numeric3_36Pattern;

    /**
     * Reg Ex. Pattern for alpha numeric without space
     */
    public validLinkPattern = REGEX_CONFIG.validLinkPattern;

    /**
     * Reprsents customer search input
     */
    public searchString = new FormControl();

    /**
     * Represents saved list of customers
     */
    public initialCustomersData = new Array<UsersDetailInfo>();

    /**
     * Represents the last edited customer
     */
    public selectedCustomerID: number;

    public isApiKeyEnabled: boolean;

    public isCustomAlarmingEnabled: boolean;

    public dismissText: string;
    public customerEditorHeader: string;
    public addCustomerHeader: string;
    public customerUpdateSuccess: string;
    public customerUpdateErr: string;
    public customerCreateErr: string;
    public customerShortNameApiErr: string;
    public archiveDialogTitle: string;
    public archieveDialogMsgStart: string;
    public archieveDialogMsgEnd: string;
    public successfullyText: string;
    public createdMsg: string;
    public updatedMsg: string;
    public archiveBtn: string;
    public cancelBtn: string;
    public customerText: string;
    public archivedSuccessMsg: string;

    public displayFilters: boolean;
    public customerListColumns = [
        'customerName',
        'activeLocation',
        'inactiveLocation',
        'assignedFeatures',
        'active',
        'editCustomer',
    ];
    public customerListDataSource = new MatTableDataSource(ELEMENT_DATA);
    public customerNameList: any[];
    public activeLocationList: any[];
    public inactiveLocationList: any[];
    public assignedFeatureList: any[];
    public activeInactiveList: any[];
    public subscriptionLevelAlternatives = SUBSCRIPTION_LEVELS;
    public contractNumber: string;
    public subscriptionLevel: number;
    constructor(
        private domOperationUtilsService: DomOperationUtilsService,
        private customerService: CustomerService,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog,
        private locationDashboardSvc: LocationDashboardService,
        private dateutilService: DateutilService,
        private statusCodeService: StatusCodeService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private uiUtilsService: UiUtilsService,
        private translate: TranslateService,
    ) {
        /*  Search by user email address changes  */
        const searchStrgSubscription = this.searchString.valueChanges
            .pipe(debounceTime(400))
            .pipe(distinctUntilChanged())
            .subscribe((res: string) => {
                this.filterCustomers(res);
            });
        this.subscriptions.push(searchStrgSubscription);
    }

    public ngOnInit() {
        this.translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismissText = res;
        });
        this.translate.get('CUSTOMER_EDITOR.CUSTOMER_EDITOR_HEADING').subscribe((res: string) => {
            this.customerEditorHeader = res;
            this.headerName = res;
        });
        this.translate.get('CUSTOMER_EDITOR.ADD_CUSTOMER_HEADING').subscribe((res: string) => {
            this.addCustomerHeader = res;
            this.headerName = res;
        });
        this.translate.get('CUSTOMER_EDITOR.CUSTOMER_UPDATE_SUCCESS').subscribe((res: string) => {
            this.customerUpdateSuccess = res;
        });
        this.translate.get('CUSTOMER_EDITOR.CUSTOMER_UPDATE_ERR').subscribe((res: string) => {
            this.customerUpdateErr = res;
        });
        this.translate.get('CUSTOMER_EDITOR.CUSTOMER_CREATE_ERR').subscribe((res: string) => {
            this.customerCreateErr = res;
        });
        this.translate.get('CUSTOMER_EDITOR.APIERROR_CUSTOMER_SHORT_NAME').subscribe((res: string) => {
            this.customerShortNameApiErr = res;
        });
        this.translate.get('CUSTOMER_EDITOR.ARCHIVE_DIALOG_TITLE').subscribe((res: string) => {
            this.archiveDialogTitle = res;
        });
        this.translate.get('CUSTOMER_EDITOR.ARCHIVE_DIALOG_MSG_START').subscribe((res: string) => {
            this.archieveDialogMsgStart = res;
        });
        this.translate.get('CUSTOMER_EDITOR.ARCHIVE_DIALOG_MSG_END').subscribe((res: string) => {
            this.archieveDialogMsgEnd = res;
        });
        this.translate.get('COMMON.SUCCESSFULLY_TEXT').subscribe((res: string) => {
            this.successfullyText = res;
        });
        this.translate.get('COMMON.CREATED_TEXT').subscribe((res: string) => {
            this.createdMsg = res;
        });
        this.translate.get('COMMON.UPDATED_TEXT').subscribe((res: string) => {
            this.updatedMsg = res;
        });
        this.translate.get('COMMON.ARCHIVE_BTN').subscribe((res: string) => {
            this.archiveBtn = res;
        });
        this.translate.get('COMMON.CANCEL_BUTTON').subscribe((res: string) => {
            this.cancelBtn = res;
        });
        this.translate.get('COMMON.CUSTOMER_TEXT').subscribe((res: string) => {
            this.customerText = res;
        });
        this.translate.get('CUSTOMER_EDITOR.ARCHIVED_SUCCESS').subscribe((res: string) => {
            this.archivedSuccessMsg = res;
        });

        this.customersLoadingState = true;
        this.isApiKeyEnabled = false;
        this.isCustomAlarmingEnabled = false;

        // get current query params for customer
        this.currentCustomerID = Number(this.activatedRoute.snapshot.queryParamMap.get(customerQueryParam));

        this.loadCustomers(true);

        // Subscribing to the result value from permission API

        /* let userInfoSubscription = this.statusCodeService.userInfo.subscribe((result: UsersInfo) => {
      if (result.customers) {
        this.customers = result.customers.map(x => x.customer);
      }
      this.initialCustomersData = this.customers;

      this.generateCustomerEditorTable();
      if (this.searchString.value !== '') {
        this.filterCustomers(this.searchString.value);
      }
      this.customersLoadingState = false;
      // this.cdr.detectChanges();

    }, error => {

      this.customersLoadingState = false;
      // this.cdr.detectChanges();

    });

    this.subscriptions.push(userInfoSubscription); */

        this.unitsofMeasure = [
            { value: '1', text: 'US Standard CFS' },
            { value: '3', text: 'US Standard MGD' },
            { value: '2', text: 'Metric' },
        ];
        this.dateFormatType = [
            { value: 'MM/DD/YYYY', text: 'MM/DD/YYYY' },
            { value: 'DD/MM/YYYY', text: 'DD/MM/YYYY' },
            { value: 'YYYY/MM/DD', text: 'YYYY/MM/DD' },
        ];
        this.timeFormatType = [
            { value: 'h:mm:ss tt', text: '12 hours' },
            { value: 'hh:mm:ss', text: '24 hours' },
        ];

        const timeZoneSubscription = this.customerService.getTimeZone().subscribe((res) => {
            this.timeZoneType = res;
        });
        this.subscriptions.push(timeZoneSubscription);

        this.showNewLocationEditor = false;
        this.generateCustomerEditorTable();

        // Called when user edits customer on customer editor screen.
        /* this.statusCodeService.customerIdEdited.subscribe((customerID: number) => {
      if (customerID > 0) {
        this.getCustomerPermission(customerID);
      }
    }); */

        this.customerNameList = [
            { value: '1', text: 'customer1' },
            { value: '3', text: 'customer3' },
            { value: '2', text: 'customer2' },
        ];
        this.activeLocationList = [
            { value: '1', text: '10' },
            { value: '3', text: '5' },
            { value: '2', text: '0' },
        ];
        this.inactiveLocationList = [
            { value: '1', text: '11' },
            { value: '3', text: '15' },
            { value: '2', text: '25' },
        ];
        this.assignedFeatureList = [
            { value: '1', text: 'list1' },
            { value: '3', text: 'List3' },
            { value: '2', text: 'List2' },
        ];
        this.activeInactiveList = [
            { value: '1', text: 'Active' },
            { value: '2', text: 'Inactive' },
        ];
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public scrollTop() {
        this.domOperationUtilsService.scrollToTop('#customerGroupEditorTable');
    }

    public onPaginateChange() {
        this.scrollTop();
    }

    public openCreateCustomerEditor() {
        this.statusCodeService.addNewCustomer.next(true);
        this.scrollTop();
        this.headerName = this.addCustomerHeader;
        this.showNewCustomerEditor = true;
        this.showUpdateCustomerEditor = false;
        this.resetCustomerForm();
    }

    public loadCustomers(addToSubscriptions: boolean) {
        this.customersLoadingState = true;
        const customersSubscription = this.customerService.getAllCustomers(false).subscribe((customers) => {
            if (!customers) {
                this.customersLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
                return;
            }
            this.initialCustomersData = customers;
            this.generateCustomerEditorTable();
            if (this.searchString.value !== '') {
                this.filterCustomers(this.searchString.value);
            }
            this.customersLoadingState = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
        if (addToSubscriptions) {
            this.subscriptions.push(customersSubscription);
        }
    }

    /**
     * Method takes a sting as an argument and filters out customers whose names include the string
     * @param searchString - string to search for customer
     */
    public filterCustomers(searchString: string) {
        if (!searchString || searchString.trim() === '') {
            this.customers = this.initialCustomersData;
        } else {
            searchString = searchString.trim();
            this.customers = this.initialCustomersData.filter((x) =>
                x.customerName.toLowerCase().includes(searchString.toLowerCase()),
            );
            this.customerEditorPaginator.pageIndex = 0;
        }
        this.generateCustomerEditorTable();
    }

    public showCustomerPageRecords(pagedItems: Customer[]) {
        this.pagedItems = pagedItems;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public validateDuplicateCustomerName() {
        this.customerName = this.customerName.trim();
        this.customerName = StringUtils.capitalizedStringWithSpace(this.customerName);
        this.customerNameLengthIsInvalid = this.customerName.length < 6;
        if (this.customerName !== null) {
            if (this.customers.find((x) => x.customerName.toLowerCase() === this.customerName.toLowerCase())) {
                if (this.showUpdateCustomerEditor && this.customer.customerName === this.customerName) {
                    this.isValidCustomerName = true;
                } else {
                    this.isValidCustomerName = false;
                }
            } else {
                this.customerName = this.customerName.charAt(0).toUpperCase() + this.customerName.slice(1);
                this.isValidCustomerName = true;
            }
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public validateShortDuplicateCustomerName() {
        this.shortName = this.shortName.trim();
        this.customerShortNameLengthIsInvalid = this.shortName.length < 3;
        if (this.customers.find((x) => x.shortName && x.shortName.toLowerCase() === this.shortName.toLowerCase())) {
            this.isValidCustomerShortName = false;
        } else {
            this.isValidCustomerShortName = true;
        }
    }

    public addCustomer() {
        this.apiError = null;
        this.customersLoadingState = true;
        this.headerName = this.customerEditorHeader;
        const newCustomer = new Customer();
        newCustomer.customerName = this.customerName;
        if (!this.showUpdateCustomerEditor) {
            newCustomer.shortName = this.shortName;
        } else {
            newCustomer.customerID = this.customer.customerID;
            newCustomer.shortName = this.customer.shortName;
        }
        newCustomer.dataTTL = this.dataTTL;
        newCustomer.isActive = true;
        newCustomer.isNewCollect = false;
        newCustomer.collectProcessType = this.flowViewFlag
            ? CollectProcessType.FlowViewAndCore
            : CollectProcessType.Core;
        newCustomer.unitsType = this.units;
        newCustomer.dateFormat = this.dateFormat;
        newCustomer.timeFormat = this.timeFormat;
        newCustomer.timeZone = this.timeZone;
        newCustomer.workOrderLink = this.workOrderLink;
        newCustomer.idleTimeOut = this.idleTimeOut || IDLE_MESSAGE_MINUTES;
        newCustomer.isDaylightSavings = this.daylightSavingFlag || false;
        newCustomer.contractNumber = this.contractNumber;
        newCustomer.subscriptionLevel = this.subscriptionLevel;

        let message = null;

        if (this.showUpdateCustomerEditor) {
            message = this.updatedMsg;
            const subscription = this.customerService.updateCustomer(newCustomer, this.customer.customerID).subscribe(
                () => {
                    this.customersLoadingState = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);

                    // display toast message on successfully customer updation
                    this.snackBar.open(this.customerName + this.customerUpdateSuccess, this.dismissText, {
                        duration: 10000,
                    });

                    const event = new Event('click');
                    this.exitCustomerEditor(event);
                    this.dateutilService.dateFormat.next(newCustomer.dateFormat);
                    this.dateutilService.timeFormat.next(newCustomer.timeFormat);
                    this.selectedCustomerID = newCustomer.customerID;
                    this.loadCustomers(false);
                },
                (error) => {
                    this.customersLoadingState = false;
                    // this.cdr.detectChanges();
                    this.apiError = error._body;
                    /* let snackbarRef: MatSnackBarRef<SimpleSnackBar> =
            this.snackBar.open('Error occured in updating customer details', 'Dismiss');
            setTimeout(snackbarRef.dismiss.bind(snackbarRef), 3000); */
                    this.snackBar.open(this.customerUpdateErr, this.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    });
                },
            );
            this.subscriptions.push(subscription);
        } else if (!this.showUpdateCustomerEditor) {
            message = this.createdMsg;

            const addCustomerSubscription = this.customerService.addCustomer(newCustomer).subscribe(
                (response) => {
                    this.customersLoadingState = false;
                    // this.cdr.detectChanges();

                    if (response.customerID > 0) {
                        this.customer = response;

                        const snackbarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(
                            this.customerName + ' ' + this.customerText + message + this.successfullyText,
                            this.dismissText,
                        );
                        setTimeout(snackbarRef.dismiss.bind(snackbarRef), 10000);
                        const event = new Event('click');
                        this.exitCustomerEditor(event);
                        this.showNewCustomerEditor = false;
                        this.selectedCustomerID = response.customerID;
                        this.loadCustomers(false);
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                        this.searchString.setValue('');
                    } else {
                        /* let snackbarRef: MatSnackBarRef<SimpleSnackBar> =
              this.snackBar.open('Something went wrong creating ' + this.customerName + '.', 'Dismiss');
            setTimeout(snackbarRef.dismiss.bind(snackbarRef), 3000); */
                        this.snackBar.open(this.customerCreateErr + this.customerName + '.', this.dismissText, {
                            panelClass: 'custom-error-snack-bar',
                        });
                    }
                },
                (error) => {
                    this.apiError = error?.error || this.customerShortNameApiErr;
                    /* let snackbarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(this.apiError, 'Dismiss');
            setTimeout(snackbarRef.dismiss.bind(snackbarRef), 3000); */
                    this.snackBar.open(this.apiError, this.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    });
                    this.customersLoadingState = false;
                    // this.cdr.detectChanges();
                },
            );

            this.subscriptions.push(addCustomerSubscription);
        }
    }

    public emitCustomerEditor() {
        // this.dialogRef.close({ success: false });
        this.searchString.setValue('');
    }

    public updateCustomer(customerId: number) {
        // set customer id for editing
        this.statusCodeService.customerIdEdited.next(customerId);

        this.selectedCustomerID = customerId;
        this.customerID = customerId;
        this.openCreateCustomerEditor();
        this.statusCodeService.addNewCustomer.next(false);
        this.showUpdateCustomerEditor = true;
        this.scrollTop();

        this.router.navigate(['/pages/customerEditor/details'], {
            queryParams: <AppQueryParams>{
                c: this.currentCustomerID,
                cid: customerId,
            },
            relativeTo: this.activatedRoute,
        });
    }

    private setSelectedCustomer(customerID: number) {
        if (customerID !== undefined) {
            this.customers.forEach((element) => {
                element.isSelected = element.customerID === customerID;
            });
            this.initialCustomersData.forEach((element) => {
                element.isSelected = element.customerID === customerID;
            });
            this.customerEditorData.forEach((element) => {
                element.isSelected = element.customerID === customerID;
            });
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }
    }

    public archiveCustomer(customerId: number, customerName: string) {
        const dialogSubsription = this.dialog
            .open(ConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    title: this.archiveDialogTitle,
                    message: this.archieveDialogMsgStart + customerName + this.archieveDialogMsgEnd,
                    okText: this.archiveBtn,
                    cancelText: this.cancelBtn,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                if (res.whichButtonWasPressed === 'ok') {
                    this.customersLoadingState = true;
                    const subscription = this.customerService.archiveCustomer(customerId).subscribe(
                        (response) => {
                            this.customer = response;
                            this.customersLoadingState = false;
                            this.loadCustomers(false);
                        },
                        (error) => {
                            this.customersLoadingState = false;
                            // this.cdr.detectChanges();
                        },
                    );
                    this.subscriptions.push(subscription);

                    const snackbarRef: MatSnackBarRef<SimpleSnackBar> = this.snackBar.open(
                        customerName + this.archivedSuccessMsg,
                        this.dismissText,
                    );
                    setTimeout(snackbarRef.dismiss.bind(snackbarRef), 10000);
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }
            });
        this.subscriptions.push(dialogSubsription);
    }

    public exitCustomerEditor(event) {
        // navigate
        this.router.navigate(['/pages/customers'], {
            queryParams: <AppQueryParams>{
                c: this.currentCustomerID,
            },
            relativeTo: this.activatedRoute,
        });

        this.scrollTop();
        this.headerName = this.customerEditorHeader;
        this.showNewCustomerEditor = false;
        this.showUpdateCustomerEditor = false;
        this.setFirstPage = true;
        this.isValidCustomerName = null;
        this.isValidCustomerShortName = null;
        this.apiError = null;
        this.resetCustomerForm();
        this.setSelectedCustomer(this.selectedCustomerID);
    }

    public resetCustomerForm() {
        this.customerNameLengthIsInvalid = false;
        this.customerShortNameLengthIsInvalid = false;

        if (this.showUpdateCustomerEditor) {
            this.customerName = this.customer.customerName;
            this.flowViewFlag = false;
            this.units = this.customer.unitsType;
            this.dateFormat = this.customer.dateFormat;
            this.timeFormat = this.customer.timeFormat;
            this.timeZone = this.customer.timeZone;
            this.workOrderLink = this.customer.workOrderLink;
            this.daylightSavingFlag = this.customer.isDaylightSavings;
            this.isValidCustomerName = true;
            this.dataTTL = this.customer.dataTTL;
            this.idleTimeOut = this.customer.idleTimeOut || IDLE_MESSAGE_MINUTES;
        } else {
            this.customerName = null;
            this.shortName = null;
            this.flowViewFlag = false;
            this.units = null;
            this.dateFormat = null;
            this.timeFormat = null;
            this.timeZone = null;
            this.workOrderLink = null;
            this.idleTimeOut = IDLE_MESSAGE_MINUTES;
            this.daylightSavingFlag = false;
            this.dataTTL = 12;
        }
    }

    //
    public openNewLocationEditor() {
        //  disable "add new location" button on customer editor to prevent multiple dialogs being open
        this.disableAddLocationBtn = true;

        const getLocationsSubscription = this.locationDashboardSvc
            .getLocations(<LocationArgs>{ customerId: this.customerID })
            .subscribe((locations: Array<Locations>) => {
                // Variable to store rain gauge locations
                let rainGaugeLocations = new Array<Locations>();

                // check if locations exist
                if (locations) {
                    // Filter and set rain gauge locations
                    rainGaugeLocations =
                        locations.filter(
                            (location) => location.series && location.series.toLowerCase().indexOf('rainalert') > -1,
                        ) || new Array<Locations>();
                    rainGaugeLocations.sort(this.uiUtilsService.sortLocations);
                }

                // open add location dialog
                const addDialogSubscription = this.dialog
                    .open(AddLocationComponent, <MatDialogConfig>{
                        data: <AddLocationModalData>{
                            rainGaugeLocations: rainGaugeLocations,
                            customerId: this.customerID,
                            isFromCustomerEditor: true,
                        },
                        //  enable "add new location" button
                    })
                    .afterClosed()
                    .subscribe(() => {
                        this.disableAddLocationBtn = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    });
                this.subscriptions.push(addDialogSubscription);
            });
        this.subscriptions.push(getLocationsSubscription);
    }

    public closeNewLocationEditor() {
        this.showNewLocationEditor = false;
    }

    public generateCustomerEditorTable() {
        this.customerEditorDataSource = null;
        this.customerEditorDataChange = new BehaviorSubject<Customer[]>([]);
        if (this.customers) {
            for (const customer of this.customers) {
                const customerEditorCopiedData = this.customerEditorData.slice();
                const data = {
                    customerName: customer.customerName,
                    customerID: customer.customerID,
                };
                customerEditorCopiedData.push(data);
                this.customerEditorDataChange.next(customerEditorCopiedData);
            }

            this.customerEditorDataSource = new FilterDataSource(
                this.customerEditorDataChange,
                this.customerEditorData,
                this.customerEditorPaginator,
                this.customerEditorSort,
                this.customerEditorFilterColumns,
            );
            this.totalPaginationLength = this.customerEditorData.length;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        } else {
            this.totalPaginationLength = 1;
        }

        this.setSelectedCustomer(this.selectedCustomerID);
    }

    private getCustomerPermission(customerId: number) {
        // Subscribe to the emitted value of result

        this.statusCodeService.userInfo.subscribe((result: UsersInfo) => {
            // Check if value exist or not
            const widgetsPermission = result.customers.find((customer) => customer.customer.customerID === customerId);
            // Set permission for Auto Review and Blockage Predictions
            if (widgetsPermission.customer.features.length !== 0) {
                if (widgetsPermission.customer.features.find((feature) => feature.name === 'ApiKeys')) {
                    // set api key status for customer
                    this.isApiKeyEnabled = true;
                } else {
                    this.isApiKeyEnabled = false;
                }
                if (widgetsPermission.customer.features.find((feature) => feature.name === 'WetDryOverflow')) {
                    // set custom alarm status for customer
                    this.isCustomAlarmingEnabled = true;
                } else {
                    this.isCustomAlarmingEnabled = false;
                }
            } else {
                this.isApiKeyEnabled = false;
                this.isCustomAlarmingEnabled = false;
            }
        });
    }
}
export interface Element {
    id: number;
    customerName: string;
    activeLocation: string;
    inactiveLocation: string;
    assignedFeatures: string;
    active: boolean;
}

const ELEMENT_DATA: Element[] = [
    {
        id: 1,
        customerName: 'Customer1',
        activeLocation: '2042',
        inactiveLocation: '529',
        assignedFeatures: 'test Description',
        active: true,
    },
    {
        id: 21,
        customerName: 'Customer21',
        activeLocation: '2042',
        inactiveLocation: '529',
        assignedFeatures: 'test Description',
        active: false,
    },
    {
        id: 31,
        customerName: 'Customer31',
        activeLocation: '2042',
        inactiveLocation: '529',
        assignedFeatures: 'test Description',
        active: true,
    },
    {
        id: 41,
        customerName: 'Customer41',
        activeLocation: '2042',
        inactiveLocation: '529',
        assignedFeatures: 'test Description',
        active: false,
    },
    {
        id: 51,
        customerName: 'Customer51',
        activeLocation: '2042',
        inactiveLocation: '529',
        assignedFeatures: 'test Description',
        active: true,
    },
    {
        id: 61,
        customerName: 'Customer61',
        activeLocation: '2042',
        inactiveLocation: '529',
        assignedFeatures: 'test Description',
        active: false,
    },
    {
        id: 71,
        customerName: 'Customer71',
        activeLocation: '2042',
        inactiveLocation: '529',
        assignedFeatures: 'test Description',
        active: true,
    },
    {
        id: 81,
        customerName: 'Customer81',
        activeLocation: '2042',
        inactiveLocation: '529',
        assignedFeatures: 'test Description',
        active: false,
    },
    {
        id: 91,
        customerName: 'Customer91',
        activeLocation: '2042',
        inactiveLocation: '529',
        assignedFeatures: 'test Description',
        active: true,
    },
];
