<div
    id="vaultFileUploadDialog"
    class="modal-dialog 1"
>
    <div
        class="popupHeader"
        mat-dialog-title
    >
        <span>{{'VAULT.VAULT_UPLOAD_DIALOG.FILE_UPLOAD_TITLE' | translate}}</span>
        <span class="app-flex-filler"></span>
    </div>
    <mat-dialog-content>
        <div class="file-upload-dialog">
            <div
                ng2FileDrop
                [ngClass]="{'nv-file-over': hasBaseDropZoneOver }"
                (fileOver)="fileOverBase($event)"
                (onFileDrop)="fileDrop($event)"
                (click)="resetState();"
                [uploader]="uploader"
                class="my-drop-zone"
            >
                <input
                    id="files"
                    name="files"
                    type="file"
                    (change)="fileSelectChangeHandler($event)"
                    multiple
                />
                <label for="files">
                    <div *ngIf="!hasFileDropped">
                        <div class="uploadIcon">
                            <mat-icon>cloud_upload</mat-icon>
                        </div>
                        <div class="uploadMessage">{{displayMessage}}</div>
                        <span class="smallLink">{{'VAULT.CLICK_BROWSE' | translate}}</span>
                    </div>
                    <div
                        class="error-upload-box"
                        *ngIf="isWrongFileTypeCSVCopy"
                    >
                        <p>{{'VAULT.VAULT_UPLOAD_DIALOG.FILE_PATTERN_ERR' | translate}}</p>
                    </div>
                    <div
                        class="error-upload-box"
                        *ngIf="isWrongFileTypeCopy"
                    >
                        <p>{{'VAULT.VAULT_UPLOAD_DIALOG.FILE_TYPE_ERR1' | translate}}</p>
                        <p>{{'VAULT.VAULT_UPLOAD_DIALOG.FILE_TYPE_ERR2' | translate}}</p>
                    </div>
                    <div class="error-upload-box">
                        <p>{{'VAULT.FILE_SIZE_ERR' | translate}}</p>
                    </div>
                </label>
            </div>
        </div>
        <app-loader [isLoading]="showProgress"></app-loader>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button
                mat-button
                mat-dialog-close
            >{{'COMMON.CLOSE' | translate}}</button>
            <button
                *ngIf="overwritableFiles && overwritableFiles.length > 0"
                mat-button
            >
                {{'COMMON.DONE_BTN' | translate}}
            </button>
        </div>
    </mat-dialog-actions>
</div>
