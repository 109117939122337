import './polyfills.ts';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './app/environments/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
    enableProdMode();
}

if (environment.clarityKey.length !== 0){
    window.onload = function () {
        (function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", environment.clarityKey )
    }
}

platformBrowserDynamic().bootstrapModule(AppModule);
