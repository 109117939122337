import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'app/pages/admin/users.service';
import { SiltEditorComponent } from 'app/pages/silt-editor/silt-editor.component';
import { TritonConfiguration } from 'app/shared/models/monitorsetting';
import { CustomerService } from 'app/shared/services/customer.service';
import { LocationCardService } from '../../services/location-card.service';
import { LocationCardData } from '../../models/location-card.model';

@Component({
    selector: 'app-echo-info',
    templateUrl: './echo-info.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EchoInfoComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() data: LocationCardData;
    @Input() monitorConfigs: TritonConfiguration;

    public userHasPermissionOnEdit: boolean;
    public customerHasBasicEditPermission: boolean;
    public siltPlaceholder: string;
    public isMonitorEditor = false;

    constructor(
        public translateService: TranslateService,
        private usersService: UsersService,
        private customerService: CustomerService,
        private locationCardService: LocationCardService,
        private dialog: MatDialog,
    ) {}

    ngOnInit() {
        this.isMonitorEditor = this.usersService.isMonitorEditor.getValue();
        this.siltPlaceholder = `${this.translateService.instant('ADD_EDIT.INPUT_PLACEHOLDER_SILT')} (${
            this.locationCardService.depthUnit
        })`;
        this.populatePermissions();
        const longRangeSensor = this.translateService.instant('ADD_EDIT.LONG_RANGE_DEPTH');
        this.form.patchValue({ sensor: longRangeSensor });

        if (this.data.editMode && this.monitorConfigs) {
            if (this.monitorConfigs['hc']) {
                this.monitorConfigs['hcValue'] = this.monitorConfigs['hc'];
            }
            this.form.patchValue(this.monitorConfigs);
        }
    }

    public openSiltEditor() {
        const data = {
            cid: this.data.customerId,
            lid: this.data.locationDetails.locationID,
            user: this.usersService.userName,
        };

        const dialogRef = this.dialog.open(SiltEditorComponent, {
            disableClose: true,
            data,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result.updated) {
                this.form.patchValue({ silt: result.lastValue.editedValue });
            }
        });
    }

    private populatePermissions() {
        this.userHasPermissionOnEdit = this.usersService.isBasicDataEditingAllowed.getValue();
        this.customerHasBasicEditPermission = this.usersService.isBasicDataEditingAllowed.getValue();
    }
}
