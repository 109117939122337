<form [formGroup]="form">
    <mat-expansion-panel [ngClass]="{ 'alarms-config-section-error': form.invalid }">
        <mat-expansion-panel-header>
            <mat-panel-title class="input-text">
                {{ 'ADD_EDIT.ADD_EDIT_CONFIGURE' | translate }}
            </mat-panel-title>
            <mat-panel-description *ngIf="form.invalid && (form | customErrorCount)">
                <mat-icon>warning</mat-icon>
                <span>
                    {{ form | customErrorCount }}
                    {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.TRITON_REQUIRED_ERRORS_TXT' | translate }}
                </span>
            </mat-panel-description>
            <mat-panel-description *ngIf="form.invalid && !(form | customErrorCount)">
                <mat-icon>warning</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div [class.disable-field]="!isMonitorEditor">
            <div class="custom-50p">
                <div class="each-Item item-flex-center">
                    <mat-slide-toggle
                        class="custom-slide-toggle"
                        formControlName="alarmEnable"
                        (change)="onRainAlarmChange($event)"
                    >
                        {{ 'ADD_EDIT.RAIN_ALARMS' | translate }}
                    </mat-slide-toggle>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            type="number"
                            placeholder="{{ 'ADD_EDIT.RAIN_THRESHOLD' | translate }}"
                            formControlName="alarmThreshold"
                        />
                    </mat-form-field>
                </div>
            </div>
            <div class="custom-50p">
                <div class="each-Item item-flex-center">
                    {{ 'ADD_EDIT.RETURN_TO_NORMAL' | translate }}
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            type="number"
                            formControlName="alarmReturnToNormal"
                            placeholder="{{ 'ADD_EDIT.THRESHOLD' | translate }}"
                        />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</form>
