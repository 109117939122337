import { Component, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { StringUtils } from 'app/shared/utils/string-utils';
import Highcharts from 'highcharts';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-diurnal-graph',
    templateUrl: './diurnal-graph.component.html',
    styles: [],
})
export class DiurnalGraphComponent implements OnDestroy {
    @ViewChild('diurnalGraph', { static: true }) public diurnalGraphElement: ElementRef;

    public diurnalGraph: Highcharts.ChartObject;

    private subscriptions: Array<Subscription> = [];

    constructor(
        private statusCodeService: StatusCodeService
    ) {
        const themeSub = this.statusCodeService.userInfoThemeBS.subscribe((response: boolean) => {
            if (response) {
                StringUtils.setHighChartTheme(this.diurnalGraph);
            } else {
                StringUtils.setHighchartWhiteTheme(this.diurnalGraph);
            }
        });
        this.subscriptions.push(themeSub);
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions = [];
    }

    public addSeries(seriesData, redraw = true) {
        if (this.diurnalGraph) {
            this.diurnalGraph.addSeries(seriesData, redraw);
        }
    }

    public plotDirunalGraph(chartData) {
        if (this.diurnalGraphElement && this.diurnalGraphElement.nativeElement) {
            chartData.chart = {
                type: 'line',
                renderTo: this.diurnalGraphElement.nativeElement,
                zoomType: 'xy',
                reflow: true,
            };
            this.diurnalGraph = new Highcharts.Chart(chartData);
        }
    }
}
