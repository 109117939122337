<div id="calculatedEntityDialog">
    <div class="dateRangeHeader bottom-border pad-l-0">
        <!-- <div class="auto-margin">
      <button mat-button (click) ="close()">
          <mat-icon class="entity-arrow-align">arrow_back</mat-icon>
      </button>
    </div> -->
        <div class="auto-margin">
            <mat-icon class="backIcon" (click)="close()">arrow_back</mat-icon>
        </div>
        <div class="dateRangeText auto-margin pad-l-0">
            {{ 'COMMON.CREATE_ENTITY' | translate }}
        </div>
        <div class="app-flex-filler"></div>
    </div>
    <div>
        <form #calculatedEntityForm="ngForm">
            <mat-card-content *ngIf="!hideCreateArea">
                <app-loader [isLoading]="isLoading"></app-loader>
                <div class="formula-containt pad-t-10">
                    <div class="formula-selection">
                        <mat-form-field class="full-width">
                            <mat-select
                                name="calculationTypeCollection"
                                placeholder="{{
                                    'CALCULATED_ENTITY_DIALOG.FORMULA_CALCULATION_PLACEHOLDER' | translate
                                }}"
                                [(ngModel)]="selected"
                                [disabled]="isEditScreen"
                            >
                                <mat-option
                                    *ngFor="let calculationType of calculationTypeCollection"
                                    [value]="calculationType.id"
                                    (click)="calculation(calculationType.name)"
                                    >{{ calculationType.text }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <button
                        matTooltip="{{ 'CALCULATED_ENTITY_DIALOG.CALCULATION_TYPE_TOOLTIP' | translate }}"
                        matTooltipPosition="above"
                        (click)="downloadMyFile()"
                        mat-icon-button
                        class="formula-info"
                    >
                        <mat-icon>info</mat-icon>
                    </button>
                </div>
                <div *ngIf="showFormula">
                    <p class="details-text">
                        <span class="details-title">{{ 'CALCULATED_ENTITY_DIALOG.DETAIL' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.FORMULA_CALCULATION_DETAIL' | translate }}
                    </p>
                </div>
                <div *ngIf="showCumulative">
                    <p class="details-text">
                        <span class="details-title">{{ 'CALCULATED_ENTITY_DIALOG.DETAIL' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.CUMULATIVE_CALCULATION_DETAIL' | translate }}.
                    </p>
                    <p class="details-text">
                        <span class="details-title"> {{ 'CALCULATED_ENTITY_DIALOG.PURPOSE' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.CUMULATIVE_CALCULATION_PURPOSE' | translate }}.
                    </p>
                </div>
                <div *ngIf="showManning">
                    <p class="details-text">
                        <span class="details-title">{{ 'CALCULATED_ENTITY_DIALOG.DETAIL' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.MANNING_CALCULATION_DETAIL' | translate }}.
                    </p>
                </div>
                <!--Sum calculation-->
                <div *ngIf="showSum">
                    <p class="details-text">
                        <span class="details-title">{{ 'CALCULATED_ENTITY_DIALOG.DETAIL' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.SUM_CALCULATION_DETAIL' | translate }}
                    </p>
                    <p class="details-text">
                        <span class="details-title"> {{ 'CALCULATED_ENTITY_DIALOG.PURPOSE' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.SUM_CALCULATION_PURPOSE' | translate }}
                    </p>
                </div>
                <!-- Rolling sum calculation -->
                <div *ngIf="showRolling">
                    <p class="details-text">
                        <span class="details-title">{{ 'CALCULATED_ENTITY_DIALOG.DETAIL' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.ROLLING_CALCULATION_DETAIL' | translate }}
                    </p>
                    <p class="details-text">
                        <span class="details-title"> {{ 'CALCULATED_ENTITY_DIALOG.PURPOSE' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.ROLLING_CALCULATION_PURPOSE' | translate }}
                    </p>
                </div>
                <!-- delta claculation -->
                <div *ngIf="showDelta">
                    <p class="details-text">
                        <span class="details-title">{{ 'CALCULATED_ENTITY_DIALOG.DETAIL' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.DELTA_CALCULATION_DETAIL' | translate }}
                    </p>
                    <p class="details-text">
                        <span class="details-title"> {{ 'CALCULATED_ENTITY_DIALOG.PURPOSE' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.DELTA_CALCULATION_PURPOSE' | translate }}
                    </p>
                </div>
                <!-- repeat calculation -->
                <div *ngIf="showRepeat">
                    <p class="details-text">
                        <span class="details-title">{{ 'CALCULATED_ENTITY_DIALOG.DETAIL' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.REPEAT_CALCULATION_DETAIL' | translate }}
                    </p>
                    <p class="details-text">
                        <span class="details-title"> {{ 'CALCULATED_ENTITY_DIALOG.PURPOSE' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.REPEAT_CALCULATION_PURPOSE' | translate }}
                    </p>
                </div>
                <!-- discrete calculation -->
                <div *ngIf="showDiscrete">
                    <p class="details-text">
                        <span class="details-title">{{ 'CALCULATED_ENTITY_DIALOG.DETAIL' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.DISCRETE_CALCULATION_DETAIL' | translate }}
                    </p>
                    <p class="details-text">
                        <span class="details-title"> {{ 'CALCULATED_ENTITY_DIALOG.PURPOSE' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.DISCRETE_CALCULATION_PURPOSE' | translate }}
                    </p>
                </div>
                <!-- interpolate calculation -->
                <div *ngIf="showInterpolate">
                    <p class="details-text">
                        <span class="details-title">{{ 'CALCULATED_ENTITY_DIALOG.DETAIL' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.INTERPOLATE_CALCULATION_DETAIL' | translate }}
                    </p>
                    <p class="details-text">
                        <span class="details-title"> {{ 'CALCULATED_ENTITY_DIALOG.PURPOSE' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.INTERPOLATE_CALCULATION_PURPOSE' | translate }}
                    </p>
                </div>
                <!-- Rolling Average calculation -->
                <div *ngIf="showAverage">
                    <p class="details-text">
                        <span class="details-title">{{ 'CALCULATED_ENTITY_DIALOG.DETAIL' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.AVERAGE_CALCULATION_DETAIL' | translate }}
                    </p>
                </div>
                <!--Median claculation  -->
                <div *ngIf="showMedian">
                    <p class="details-text">
                        <span class="details-title">{{ 'CALCULATED_ENTITY_DIALOG.DETAIL' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.MEDIAN_CALCULATION_DETAIL' | translate }}
                    </p>
                    <p class="details-text">
                        <span class="details-title"> {{ 'CALCULATED_ENTITY_DIALOG.PURPOSE' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.MEDIAN_CALCULATION_PURPOSE' | translate }}
                    </p>
                </div>

                <!-- periodic constant calculation -->
                <div *ngIf="showConstant">
                    <p class="details-text">
                        <span class="details-title">{{ 'CALCULATED_ENTITY_DIALOG.DETAIL' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.CONSTANT_CALCULATION_DETAIL' | translate }}
                    </p>
                </div>
                <!-- Minimum calculation -->
                <div *ngIf="showMinimum">
                    <p class="details-text">
                        <span class="details-title">{{ 'CALCULATED_ENTITY_DIALOG.DETAIL' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.MINIMUM_CALCULATION_DETAIL' | translate }}
                    </p>
                    <p class="details-text">
                        <span class="details-title"> {{ 'CALCULATED_ENTITY_DIALOG.PURPOSE' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.MINIMUM_CALCULATION_PURPOSE' | translate }}
                    </p>
                </div>
                <!-- Maximum calculation -->
                <div *ngIf="showMaximum">
                    <p class="details-text">
                        <span class="details-title">{{ 'CALCULATED_ENTITY_DIALOG.DETAIL' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.MAXIMUM_CALCULATION_DETAIL' | translate }}
                    </p>
                    <p class="details-text">
                        <span class="details-title"> {{ 'CALCULATED_ENTITY_DIALOG.PURPOSE' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.MAXIMUM_CALCULATION_PURPOSE' | translate }}
                    </p>
                </div>
                <!-- Shift calculation -->
                <div *ngIf="showShift">
                    <p class="details-text">
                        <span class="details-title">{{ 'CALCULATED_ENTITY_DIALOG.DETAIL' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.SHIFT_CALCULATION_DETAIL' | translate }}
                    </p>
                </div>
                <!-- Step calculation -->
                <div *ngIf="showStep">
                    <p class="details-text">
                        <span class="details-title">{{ 'CALCULATED_ENTITY_DIALOG.DETAIL' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.STEP_CALCULATION_DETAIL' | translate }}
                    </p>
                    <p class="details-text">
                        <span class="details-title"> {{ 'CALCULATED_ENTITY_DIALOG.PURPOSE' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.STEP_CALCULATION_PURPOSE' | translate }}
                    </p>
                </div>
                <!-- Time-Weighted Average Calculation -->
                <div *ngIf="showWeighted">
                    <p class="details-text">
                        <span class="details-title">{{ 'CALCULATED_ENTITY_DIALOG.DETAIL' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.TIME_AVERAGE_CALCULATION_DETAIL' | translate }}
                    </p>
                    <p class="details-text">
                        <span class="details-title"> {{ 'CALCULATED_ENTITY_DIALOG.PURPOSE' | translate }}: </span>
                        {{ 'CALCULATED_ENTITY_DIALOG.TIME_AVERAGE_CALCULATION_PURPOSE' | translate }}
                    </p>
                </div>
                <div class="custom-33p-group">
                    <div class="each-containt">
                        <mat-form-field class="full-width">
                            <mat-select
                                placeholder=" {{
                                    'CALCULATED_ENTITY_DIALOG.FORM_MEASUREMENTS_PLACEHOLDER' | translate
                                }}"
                                name="units"
                                [(ngModel)]="calcEntity.unitId"
                                (selectionChange)="onUnitsTypeChange($event)"
                                [disabled]="disableUnits"
                                required
                            >
                                <mat-option *ngFor="let unit of unitsType" [value]="unit.unitId">
                                    {{ unit.unitName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="each-containt">
                        <mat-form-field class="full-width">
                            <mat-select
                                placeholder=" {{ 'CALCULATED_ENTITY_DIALOG.FORM_GROUP_PLACEHOLDER' | translate }}"
                                name="displayGroups"
                                [(ngModel)]="calcEntity.displayGroupId"
                                [disabled]="displayGroupsList.length === 0 || disableDisplayGroups"
                                required
                            >
                                <mat-option *ngFor="let displayGroup of displayGroupsList" [value]="displayGroup.id">
                                    {{ displayGroup.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="each-containt">
                        <mat-form-field class="full-width">
                            <input
                                matInput
                                (ngModelChange)="onNameChange($event)"
                                [(ngModel)]="calcEntity.name"
                                name="name"
                                placeholder="{{ 'CALCULATED_ENTITY_DIALOG.FORM_NAME_PLACEHOLDER' | translate }}"
                                required
                            />
                        </mat-form-field>
                        <div class="mat-error" *ngIf="existingEntityNameError">
                            <span>{{ 'CALCULATED_ENTITY_DIALOG.EXISTING_ENTITY_NAME_ERROR' | translate }}</span>
                        </div>
                    </div>
                    <div class="each-containt">
                        <mat-form-field class="full-width">
                            <input
                                matInput
                                [(ngModel)]="calcEntity.description"
                                name="description"
                                placeholder="{{ 'CALCULATED_ENTITY_DIALOG.FORM_DESCRIPTION_PLACEHOLDER' | translate }}"
                            />
                        </mat-form-field>
                    </div>
                    <div
                        class="each-containt"
                        *ngIf="
                            showCumulative ||
                            showSum ||
                            showRolling ||
                            showDelta ||
                            showRepeat ||
                            showDiscrete ||
                            showInterpolate ||
                            showAverage ||
                            showMedian ||
                            showMinimum ||
                            showMaximum ||
                            showShift ||
                            showStep ||
                            showWeighted ||
                            showManning
                        "
                    >
                        <ads-prism-single-select-group
                            #entitiesGroupSingleselect
                            [items]="singleSelectEntityList"
                            [preselectedItem]="selectedItems[0].entityId"
                            (selectedItems)="selectedGroupEntity($event)"
                            (isEntityInValid)="handleEntityInput($event)"
                            [displayGroupFilterList]="filterDisplayGroups"
                            placeholder="{{ 'CALCULATED_ENTITY_DIALOG.SELECT_ENTITY' | translate }}"
                        >
                        </ads-prism-single-select-group>
                        <div class="mat-error" *ngIf="invalidEntitySelected">
                            <span>{{ 'CALCULATED_ENTITY_DIALOG.INVALID_ENTITY_SELCTED_MESSAGE' | translate }}</span>
                        </div>
                    </div>
                    <div class="each-containt" *ngIf="showConstant">
                        <mat-form-field class="full-width">
                            <input matInput placeholder="Constant Value" />
                        </mat-form-field>
                    </div>
                    <div class="each-containt" *ngIf="showManning">
                        <mat-form-field class="full-width">
                            <input
                                matInput
                                name="RoughnessValue"
                                (ngModelChange)="validateManningRoughness($event)"
                                type="number"
                                [(ngModel)]="selectedItems[0].manningRoughness"
                                placeholder="{{ 'CALCULATED_ENTITY_DIALOG.ROUGHNESS_CONSTANT_VALUE' | translate }}"
                                required
                            />
                        </mat-form-field>
                        <div class="mat-error" *ngIf="invalidManningRoughness">
                            {{ 'CALCULATED_ENTITY_DIALOG.INVALID_MANNING_ROUGHNESS_CONSTANT_MESSAGE' | translate }}
                        </div>
                    </div>
                    <div class="each-containt" *ngIf="showManning">
                        <mat-form-field class="full-width">
                            <input
                                matInput
                                #manningSlopConstant="ngModel"
                                name="Slope"
                                (ngModelChange)="validateManningSlope($event)"
                                type="number"
                                [(ngModel)]="selectedItems[0].manningSlope"
                                placeholder="{{ 'CALCULATED_ENTITY_DIALOG.SLOPE_VALUE' | translate }}"
                                required
                            />
                        </mat-form-field>
                        <div class="mat-error" *ngIf="invalidManningSlope">
                            {{ 'CALCULATED_ENTITY_DIALOG.INVALID_MANNING_SLOPE_CONSTANT_MESSAGE' | translate }}
                        </div>
                    </div>
                    <!-- <div class="each-containt" *ngIf="showCumulative || showFormula || showSum || showRolling || showDelta || showRepeat || showDiscrete || showInterpolate || showAverage ||showMedian || showConstant || showMinimum || showMaximum || showShift || showWeighted">
            <button class="customDateRangeSelector full-width" mat-button (menuClosed)="checkDateVaidation()"
              [matMenuTriggerFor]="dateRangeMenu" #trigger="matMenuTrigger">
              <span class="date-range-content">{{'LOCATION_DASHBOARD.DATE_RANGE_SPAN' | translate}}</span>
              <span class="date-range-hint date-value"> {{startDate | adsDate: customerDateFormat}} {{dashText}}
                {{endDate | adsDate: customerDateFormat}}
              </span>
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #dateRangeMenu="matMenu" xPosition="before" yPosition="below" [overlapTrigger]="false"
              class="customDateMenuNewEntity">

            <div class="customDateMenuContaint">
              <div class="dateAreaFull" (click)="$event.stopPropagation();">
                <div class="header">{{'LOCATION_DASHBOARD.CUSTOM_DATE' | translate}} - {{customerDateFormat}}
                </div>
                <div>
                  <mat-form-field class="full-width">
                    <input #startDateInput matInput name="strtDate" placeholder="{{ 'COMMON.FROM' | translate }}" (change)="checkStartDateChange($event)" [value]="calcEntity.startDate | adsDate: customerDateFormat"
                      tabindex="-1" class="datepickerStyles">
                    <input matInput hidden name="startDate" [(ngModel)]="calcEntity.startDate" [matDatetimepicker]="startDte" [max]="maxDate"
                      (dateChange)="checkStartDateChange($event)">
                    <mat-datetimepicker-toggle matSuffix [for]="startDte"></mat-datetimepicker-toggle>
                    <mat-datetimepicker #startDte></mat-datetimepicker>
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field class="full-width">
                    <input #endDateInput matInput name="enDate" placeholder="{{'COMMON.TO' | translate}}" (change)="checkEndDateChange($event)" tabindex="-1"
                      [value]="calcEntity.endDate | adsDate: customerDateFormat">
                    <input matInput hidden name="endDate" [(ngModel)]="calcEntity.endDate" [matDatetimepicker]="endDte" (dateChange)="checkEndDateChange($event)">
                    <mat-datetimepicker-toggle matSuffix [for]="endDte"></mat-datetimepicker-toggle>
                    <mat-datetimepicker #endDte disabled="false"></mat-datetimepicker>
                  </mat-form-field>
                </div>
                <div class="mat-error full-width">
                  <span *ngIf="displayEndDateErrMsg && !invalidEndDate && !invalidDateRange">{{'COMMON.INVALID_TO_DATE_FORMAT'
                    | translate}}</span>
                  <span *ngIf="invalidEndDate">{{'COMMON.FROM_TO_DATE_ERROR' | translate}}</span>
                  <span *ngIf="invalidStartDate">{{'COMMON.INVALID_START_DATE' | translate}}</span>
                  <span *ngIf="displayStartDateErrMsg && !invalidStartDate && !invalidDateRange">{{'COMMON.INVALID_FROM_DATE_FORMAT'
                    | translate}}
                  </span>

                </div>
              </div>
            </div>

          </mat-menu>
        </div> -->
                </div>
                <div *ngIf="showFormula">
                    <h4>{{ 'CALCULATED_ENTITY_DIALOG.CREATE_VARIABLES' | translate }}</h4>
                    <div class="entityVariableContainer">
                        <div class="eachRow bottom-border">
                            <div class="containtCell header">
                                <span>
                                    {{ 'CALCULATED_ENTITY_DIALOG.HEADER_1' | translate }}
                                </span>
                            </div>
                            <div class="containtCell header">
                                <span>
                                    {{ 'CALCULATED_ENTITY_DIALOG.HEADER_2' | translate }}
                                </span>
                            </div>
                            <div class="containtCell header">
                                <span>
                                    {{ 'CALCULATED_ENTITY_DIALOG.HEADER_3' | translate }}
                                </span>
                            </div>
                            <div class="actionCell header"></div>
                        </div>
                        <div *ngFor="let item of selectedItems; let i = index" class="eachRow">
                            <div class="containtCell leftBorder">
                                <mat-form-field class="full-width">
                                    <mat-select
                                        placeholder=" {{
                                            'CALCULATED_ENTITY_DIALOG.LOCATION_VALUE_PLACEHOLDER' | translate
                                        }}"
                                        name="locations{{ i }}"
                                        (selectionChange)="equationChanged()"
                                        [(ngModel)]="selectedItems[i].locationId"
                                        (selectionChange)="enableAddMore()"
                                        required
                                    >
                                        <mat-option *ngFor="let location of locationList" [value]="location.locationId">
                                            {{ location.locationName }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="containtCell">
                                <app-entity-selector
                                    *ngIf="availableEntities$ | async as availableEntities"
                                    (focusout)="equationChanged()"
                                    [single]="true"
                                    [availableEntities]="availableEntities"
                                    [selectedEntitiyIds]="[item.entityId]"
                                    (selectedEntities)="selectEntities($event, i)"
                                ></app-entity-selector>
                            </div>
                            <div class="containtCell">
                                <mat-form-field class="full-width">
                                    <input
                                        name="entityDialog{{ i }}"
                                        #entityDialog="ngModel"
                                        matInput
                                        placeholder="{{ 'CALCULATED_ENTITY_DIALOG.Alias' | translate }}"
                                        (blur)="enableAddMore()"
                                        [(ngModel)]="selectedItems[i].variableName"
                                        class="details-title"
                                        [pattern]="'^[a-zA-Z0-9]+$'"
                                        (keypress)="equationChanged()"
                                        (change)="equationChanged()"
                                        required
                                    />
                                </mat-form-field>
                            </div>
                            <div class="actionCell rightBorder">
                                <button
                                    *ngIf="i >= 1"
                                    type="button"
                                    mat-button
                                    class="lightBackGround"
                                    (click)="removeRow(i)"
                                >
                                    <mat-icon class="mat-24">delete</mat-icon>
                                </button>
                            </div>
                        </div>
                        <!-- add variables -->
                        <div class="eachRow">
                            <div class="app-flex-filler"></div>
                            <div class="auto-margin pad-b-15">
                                <button
                                    mat-fab
                                    color="accent"
                                    class="add-icon-position"
                                    (click)="addMoreRow()"
                                    [disabled]="!isRowFilled"
                                    matTooltip="{{ 'CALCULATED_ENTITY_DIALOG.ADDMORE_BUTTON' | translate }}"
                                    matTooltipPosition="above"
                                >
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- create variables -->

                    <div class="variables-container definie-equation pad-t-20">
                        <div class="custom-50p">
                            <mat-form-field class="full-width">
                                <input
                                    name="entityEquation"
                                    matInput
                                    placeholder="{{ 'CALCULATED_ENTITY_DIALOG.INPUT_PLACEHOLDER' | translate }}"
                                    [(ngModel)]="calcEntity.equation"
                                    class="details-title"
                                    (keypress)="equationChanged()"
                                    (change)="equationChanged()"
                                />
                            </mat-form-field>
                        </div>
                        <div mat-dialog-actions class="form-validate">
                            <div class="app-flex-filler"></div>
                            <div class="auto-margin custom-flat-button">
                                <button mat-button [disabled]="isFormulaValidated" (click)="validateEquation()">
                                    {{ 'CALCULATED_ENTITY_DIALOG.VALIDATE_BUTTON' | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- all calculation time interval-->
                <div>
                    <h4 *ngIf="showCumulative">
                        {{ 'CALCULATED_ENTITY_DIALOG.CUMULATIVE_TIME_INTERVAL' | translate }}
                    </h4>
                    <h4 *ngIf="showSum">{{ 'CALCULATED_ENTITY_DIALOG.SUM_TIME_INTERVAL' | translate }}</h4>
                    <h4 *ngIf="showRolling">{{ 'CALCULATED_ENTITY_DIALOG.ROLLING_TIME_INTERVAL' | translate }}</h4>
                    <h4 *ngIf="showRepeat">
                        {{ 'CALCULATED_ENTITY_DIALOG.REPEAT_TIME_INTERVAL' | translate }}
                        <small class="small-text"
                            >({{ 'CALCULATED_ENTITY_DIALOG.REPEAT_TIME_SMALL_TEXT' | translate }})</small
                        >
                    </h4>
                    <h4 *ngIf="showDiscrete">{{ 'CALCULATED_ENTITY_DIALOG.DISCRETE_TIME_INTERVAL' | translate }}</h4>
                    <h4 *ngIf="showInterpolate">
                        {{ 'CALCULATED_ENTITY_DIALOG.INTERPOLATE_TIME_INTERVAL' | translate }}
                    </h4>
                    <h4 *ngIf="showAverage">{{ 'CALCULATED_ENTITY_DIALOG.AVERAGE_TIME_INTERVAL' | translate }}</h4>
                    <h4 *ngIf="showMedian">{{ 'CALCULATED_ENTITY_DIALOG.MEDIAN_TIME_INTERVAL' | translate }}</h4>
                    <h4 *ngIf="showConstant">{{ 'CALCULATED_ENTITY_DIALOG.CONSTANT_TIME_INTERVAL' | translate }}</h4>
                    <h4 *ngIf="showMinimum">{{ 'CALCULATED_ENTITY_DIALOG.MINIMUM_TIME_INTERVAL' | translate }}</h4>
                    <h4 *ngIf="showMaximum">{{ 'CALCULATED_ENTITY_DIALOG.MAXIMUM_TIME_INTERVAL' | translate }}</h4>
                    <h4 *ngIf="showShift">{{ 'CALCULATED_ENTITY_DIALOG.SHIFT_TIME_INTERVAL' | translate }}</h4>
                    <h4 *ngIf="showStep">{{ 'CALCULATED_ENTITY_DIALOG.STEP_TIME_INTERVAL' | translate }}</h4>
                    <h4 *ngIf="showWeighted">
                        {{ 'CALCULATED_ENTITY_DIALOG.TIME_AVERAGE_CALCULATION_INTERVAL' | translate }}
                    </h4>
                    <div
                        class="custom-33p"
                        *ngIf="
                            showSum ||
                            showRolling ||
                            showDiscrete ||
                            showCumulative ||
                            showInterpolate ||
                            showAverage ||
                            showMedian ||
                            showConstant ||
                            showMinimum ||
                            showMaximum ||
                            showShift ||
                            showWeighted
                        "
                    >
                        <div class="each-Item">
                            <mat-form-field class="full-width">
                                <input
                                    name="timeInterval"
                                    matInput
                                    type="number"
                                    onkeypress="return event.charCode >= 48"
                                    min="0"
                                    #daysOfInterval="ngModel"
                                    [(ngModel)]="selectedItems[0].timeInterval.days"
                                    placeholder="{{ 'CALCULATED_ENTITY_DIALOG.DAYS' | translate }}"
                                />
                            </mat-form-field>
                        </div>
                        <div class="each-Item">
                            <mat-form-field class="full-width">
                                <input
                                    name="timeIntervals"
                                    matInput
                                    type="number"
                                    onkeypress="return event.charCode >= 48"
                                    min="0"
                                    #hoursOfInterval="ngModel"
                                    [(ngModel)]="selectedItems[0].timeInterval.hours"
                                    placeholder="{{ 'CALCULATED_ENTITY_DIALOG.HOURS' | translate }}"
                                />
                            </mat-form-field>
                        </div>
                        <div class="each-Item">
                            <mat-form-field class="full-width">
                                <input
                                    name="timeInterval2"
                                    matInput
                                    type="number"
                                    onkeypress="return event.charCode >= 48"
                                    min="0"
                                    #minutesOfInterval="ngModel"
                                    [(ngModel)]="selectedItems[0].timeInterval.minutes"
                                    placeholder="{{ 'CALCULATED_ENTITY_DIALOG.MINUTES' | translate }}"
                                />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="custom-33p" *ngIf="showStep">
                        <div class="each-Item">
                            <mat-form-field class="full-width">
                                <input
                                    name="timeInterval3"
                                    matInput
                                    #stepMinutesOfInterval="ngModel"
                                    [(ngModel)]="selectedItems[0].timeInterval.minutes"
                                    placeholder="{{ 'CALCULATED_ENTITY_DIALOG.MINUTES' | translate }}"
                                    (ngModelChange)="validateStepMinutesInterval($event)"
                                />
                            </mat-form-field>
                            <div class="mat-error" *ngIf="invalidStepMinutesInterval">
                                <span>{{
                                    'CALCULATED_ENTITY_DIALOG.INVALID_STEP_INTERVAL_MINUTES_MESSAGE' | translate
                                }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Repeat calculation -->
                <div class="custom-50p-group" *ngIf="showRepeat">
                    <div class="each-containt">
                        <mat-form-field class="full-width">
                            <input
                                name="timeInput"
                                matInput
                                placeholder="{{ 'CALCULATED_ENTITY_DIALOG.REPEAT_TIME_INPUT_PLACEHOLDER' | translate }}"
                            />
                        </mat-form-field>
                    </div>
                </div>
                <!-- shift calculation -->
                <div class="custom-50p-group" *ngIf="showShift">
                    <div class="each-containt">
                        <mat-form-field class="full-width">
                            <mat-select
                                #location1
                                placeholder="{{ 'CALCULATED_ENTITY_DIALOG.REPEAT_TIME_INPUT_PLACEHOLDER' | translate }}"
                            >
                                <mat-option>Option</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-content>
        </form>
        <app-loader [isLoading]="isloaderForMappingEntity"></app-loader>
        <div class="dialogue-box" *ngIf="isEntitySaved">
            <div class="dialogue">
                <div class="check-button">
                    <button mat-fab color="accent">
                        <mat-icon>check</mat-icon>
                    </button>
                </div>
                <div class="message">
                    <b> {{ successMessage }}</b>
                </div>
            </div>
            <div class="dropdown">
                <p>{{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.SELECT_OPTIONS' | translate }}</p>
                <div class="eachSection">
                    <mat-form-field class="full-width">
                        <mat-select
                            multiple
                            placeholder="{{
                                'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.SEARCH_LOCATIONS' | translate
                            }}"
                            (selectionChange)="getLocationIdForMap($event)"
                        >
                            <mat-option *ngFor="let mapLocation of locationList" [value]="mapLocation.locationId">
                                {{ mapLocation.locationName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <mat-card-actions class="top-border">
        <div class="app-flex-filler"></div>
        <div class="auto-margin custom-flat-button">
            <button mat-button (click)="close(false)">{{ 'COMMON.CLOSE' | translate }}</button>
        </div>
        <div class="auto-margin custom-flat-button">
            <button mat-button (click)="applyFormula()" [disabled]="(form && form.pristine) || !isFormulaValidated || !calculatedEntityForm.valid || existingEntityNameError">
                {{ 'COMMON.APPLY_BTN' | translate }}
            </button>
        </div>
    </mat-card-actions>
</div>
