<div class="storm">
    <div
        class="full-container"
        [ngClass]="{hide: expandedChooser}"
    >
        <div class="property-holder">
            <div class="property-width">
                <div class="horizontal-spacing"></div>
                <div
                    [ngClass]="adjHyClass"
                    id="storm-graph-wrapper"
                >
                    <mat-card class="sub-property-width loaderContainer">
                        <app-loader [isLoading]="isLoading || loadingBasinStormResults"></app-loader>
                        <mat-card-header class="card-custom-header-se">
                            <h4 class="graph-heading">
                                {{
                                'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_DECOMPOSITION_HYDROGRAPH'
                                | translate
                                }}
                            </h4>
                            <i
                                class="material-icons inverted-icon zoom-out"
                                (click)="expandCollapseAdj()"
                            >
                                {{ adjHyClass === 'show-half' ? 'fullscreen' : 'fullscreen_exit' }}
                            </i>
                        </mat-card-header>
                        <mat-card-content class="full-height-content">
                            <ads-storm-decomposition-hydrograph
                                #hydroGraphChart
                                [isLoading]="isLoading || loadingBasinStormResults"
                                [showStormPlotBands]="hydrographStormPeriodsEnabled"
                                [showTooltips]="hydrographTooltipEnabled"
                                [showModeledPrecompensation]="hydrographModeledPrecompensation"
                                [stormPeriods]="stormPeriods"
                                [basinStormData]="(stormEvents && stormEvents.length) ? selectedBasinStorm : null"
                                [weekGroups]="dayOfWeekGroups"
                                [basinDryDays]="basinDryDays"
                                [stepLength]="stepLength"
                                [isRainOnTop]="isRainOnTop"
                                (xAxisMinMaxEmitter)="onMinMaxChanged($event)"
                                (isZoomed)="hgZoomChange($event)"
                            >
                            </ads-storm-decomposition-hydrograph>
                            <div class="hgsliderspacer"></div>
                            <ads-adjust-storm-event-periods
                                *ngIf="stormDecompositionFullScreen"
                                [xAxisMinMaxPoint]="xAxisMinMaxPoint"
                                [showTooltips]="hydrographTooltipEnabled"
                                [stormPeriods]="stormPeriods"
                                [hideSliders]="isHGZoomed"
                                (periodAdjustedEmitter)="onSliderChanged($event)"
                            >
                            </ads-adjust-storm-event-periods>
                        </mat-card-content>
                        <div class="hgslidertooltip toggle-container">
                            <div>
                                <mat-checkbox
                                    class="pad-l-15 pad-r-15 custom-chk-margin"
                                    [checked]="hydrographModeledPrecompensation"
                                    (change)="onHydrographModeledPrecompensation()"
                                >
                                    <span>{{ 'SLIICER.COMMON.MODELED_PRECOMPENSATION' | translate }}</span>
                                </mat-checkbox>
                                <mat-checkbox
                                    class="pad-l-15 pad-r-15 custom-chk-margin"
                                    [checked]="hydrographTooltipEnabled"
                                    (change)="onHydrographTooltipEnabledChanged()"
                                >
                                    <span>{{ 'SLIICER.COMMON.SHOW_TOOLTIPS' | translate }}</span>
                                </mat-checkbox>
                                <mat-checkbox
                                    class="pad-l-15 pad-r-15 custom-chk-margin"
                                    [checked]="hydrographStormPeriodsEnabled"
                                    (change)="onHydrographStormPeriodsEnabledChanged()"
                                >
                                    <span>{{ 'SLIICER.COMMON.SHOW_STORM_PERIODS' | translate }}</span>
                                </mat-checkbox>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="horizontal-spacing"></div>
                <div [ngClass]="qviClass">


                    <mat-card class="sub-property-width-qvi">
                        <mat-card-header class="card-custom-header-se">

                            <div class="pr-10">
                                {{ 'COMMON.TYPE_COLUMN_TITLE' | translate }}:
                            </div>
                            <mat-form-field
                                floatLabel="never"
                                class="graph-heading-select"
                                [matTooltip]="getHeadingText(true, qviChartInfos[currentChartIndex]) | translate"
                            >
                                <mat-placeholder class="custom-placeholder">{{ getHeadingText(true) }}</mat-placeholder>
                                <mat-select panelClass="custom-select-panel">
                                    <mat-option
                                        *ngFor="let info of qviChartInfos; index as i"
                                        [value]="getHeadingText(false, info) | translate"
                                        (click)="updateChartIndex($event, i)"
                                    >
                                        <h4 class="graph-heading">{{ getHeadingText(true, info) | translate }}</h4>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="pad-15 full-width qvi">
                                {{ 'COMMON.QVI' | translate }}
                            </div>
                            <div class="pr-10">
                                {{ 'COMMON.MODE_COLUMN_TITLE' | translate }}:
                            </div>
                            <mat-slide-toggle
                                disableRipple="true"
                                [(ngModel)]="isGross"
                                [ngClass]="getColor(isGross)"
                                [disabled]="false"
                                (change)="sliderChanged()"
                            >
                                {{ isGross ? ('COMMON.GROSS' | translate) : ('COMMON.NET' | translate) }}
                            </mat-slide-toggle>

                            <!-- US #31979: Temporary remove Design Storm for Sliicer version v.1 -->
                            <!-- <div
                                class="filter-item setting-button"
                                #toggleButton
                                *ngIf="qviFullScreen"
                            >
                                <button
                                    mat-button
                                    (click)="showHideAnnotation('qvi', true)"
                                    matTooltip="{{ 'COMMON.SETTINGS' | translate }}"
                                >
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                            </div> -->
                            <!-- Annotations -->
                            <!-- <div
                                id="locationDashboardAnnotation"
                                *ngIf="qviFullScreen && displayAnnotations.qvi"
                            >
                                <div class="anotationBorder">
                                    <button
                                        class="full-width"
                                        mat-button
                                        (click)="qviSettingsMenu()"
                                    >
                                        <span>{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.MENU' | translate }}</span>
                                    </button>
                                </div>
                                <div class="anotationBorder close">
                                    <button
                                        class="full-width"
                                        mat-button
                                        (click)="showHideAnnotation('qvi', false)"
                                    >
                                        {{ 'COMMON.CLOSE' | translate }}
                                    </button>
                                </div>
                            </div> -->
                            <i
                                class="material-icons inverted-icon zoom-out"
                                (click)="expandCollapseQvI()"
                            >
                                {{ qviClass === 'show-half' ? 'fullscreen' : 'fullscreen_exit' }}
                            </i>
                        </mat-card-header>
                        <mat-card-content class="split-height-content">
                            <ads-q-i-graph
                                #qviComponent
                                [customerId]="customerId"
                                [caseStudyId]="caseStudyId"
                                [isGross]="isGross"
                                [xFieldAccessor]="currentXFieldAccessor"
                                [yFieldAccessor]="currentYFieldAccessor"
                                [showRegressionLine]="showRegressionLine"
                                [showDates]="showDates"
                                [showTooltips]="qiGraphTooltipEnabled"
                                [showStormHighlight]="showStormHighlight"
                                [selectedBasin]="selectedBasin"
                                [selectedStormId]="selectedStormId"
                                [xAxisLabel]="currentXAxisLabel"
                                [yAxisLabel]="currentYAxisLabel"
                                [volumePerTime]="currentVolumePerTime"
                                [measurePerHour]="currentMeasurePerHour"
                                [comparisonType]="currentComparisonType"
                                [isLoading]="isLoading"
                                [overrideUseAltReg]="useAltReg"
                                [designStorms]="designStorms"
                                [excludedStormIds]="excludedStormIds"
                                [qviChartIndex]="currentChartIndex"
                                [excludeStormEvent]="excludeStormSubject"
                            >
                            </ads-q-i-graph>
                        </mat-card-content>
                        <div class="toggle-container">
                            <div>
                                <mat-checkbox
                                    class="custom-chk-margin"
                                    [(ngModel)]="showStormHighlight"
                                >
                                    <span>{{
                                        'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.HIGHLIGHT_SELECTED_STORM' | translate
                                        }}</span>
                                </mat-checkbox>
                                <mat-checkbox
                                    class="pad-l-15 custom-chk-margin"
                                    [(ngModel)]="showDates"
                                >
                                    <span>{{ 'COMMON.DATE_TEXT' | translate }}</span>
                                </mat-checkbox>
                                <mat-checkbox
                                    class="pad-l-15 custom-chk-margin"
                                    [(ngModel)]="showRegressionLine"
                                >
                                    <span>{{
                                        'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.SHOW_REGRESSION_LINE' | translate
                                        }}</span>
                                </mat-checkbox>
                                <mat-checkbox
                                    class="pad-l-15 custom-chk-margin"
                                    (change)="onQIGraphTooltipEnabledChanged()"
                                    [checked]="qiGraphTooltipEnabled"
                                >
                                    <span>{{ 'SLIICER.COMMON.SHOW_TOOLTIPS' | translate }}</span>
                                </mat-checkbox>
                                <mat-checkbox
                                    *ngFor="let ds of designStorms"
                                    class="pad-l-15 pad-r-15 custom-chk-margin"
                                    [checked]="ds.selected"
                                    (change)="designStormSelectedChanged(ds, $event.checked)"
                                >
                                    <span>{{ ds.name }}</span>
                                </mat-checkbox>
                                <mat-checkbox
                                    *ngIf="showAltRegCheckbox"
                                    class="pad-l-15 pad-r-15 custom-chk-margin"
                                    [checked]="useAltReg"
                                    [disabled]="altRegDisabled"
                                    (change)="useAlternateReg()"
                                >
                                    <span>{{'SLIICER.COMMON.ALTERNATE' | translate }}</span>
                                </mat-checkbox>
                            </div>
                        </div>
                    </mat-card>
                </div>
                <div class="horizontal-spacing"></div>
            </div>
            <div class="selected-storm-stats loaderContainer">
                <div class="sli-header">
                    <h4 class="graph-heading">
                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_STATISTICS' | translate }}
                    </h4>
                    <span class="storm-table-time" *ngIf="selectedStormId"> {{ stormStartTime | date: customDateFormat }} </span>
                    <span class="storm-table-time" *ngIf="selectedStormId"> {{ stormLabel }} </span>
                </div>
                <ads-sliicer-storm-stats
                    [selectedBasin]="selectedBasin"
                    [showOnlySelectedStorm]="true"
                    [selectedStormId]="selectedStormId"
                    [isLoading]="isLoading"
                    [refreshStorm]="refreshStorm"
                >
                </ads-sliicer-storm-stats>
            </div>
        </div>
        <ads-sliicer-storm-chooser
            [selectedBasin]="selectedBasin"
            [customerId]="customerId"
            [caseStudyId]="caseStudyId"
            [stormEvents]="stormEvents"
            [stormBasinResults]="stormBasinResults"
            [basinStormSettings]="basinStorms"
            [stormSettings]="stormSettings"
            [isLoading]="isLoading || loadingBasinStormResults"
            [addedStormEvents]="addedStormEvents"
            [removedStormEvents]="removedStormEvents"
            [basinStormResult]="selectedBasinStorm"
            [qvsIConfigurations]="qvsIConfigurations"
            [originalQvsIConfigurations]="originalQvsIConfigurations"
            [regimes]="regimes"
            [availableSeasons]="availableSeasons"
            [availableYears]="availableYears"
            [hasYears]="hasYears"
            [selectedStormId]="selectedStormId"
            [excludedStormIds]="excludedStormIds"
            (stormEventsEdited)="setStormEventsEdited($event)"
            (excludedStormToggled)="toggleStormBasinExclusion($event)"
            (stormSelected)="stormSelected($event)"
            (onEditStorm)="stormSelected($event, true)"
            (onStormDelete)="deleteStorm.emit($event)"
            (qvsiConfChanged)="qvsiConfUpdated($event)"
            (expandChooser)="expandChooser($event)"
        >
        </ads-sliicer-storm-chooser>
    </div>
    <div
        class="full-container-expanded"
        [ngClass]="{hide: !expandedChooser}"
    >
        <app-expanded-storm-chooser
            #expandedStormChooserComponent
            [defaultPrecompType]="defaultPrecompType"
            [selectedBasin]="selectedBasin"
            [customerId]="customerId"
            [caseStudyId]="caseStudyId"
            [basinStormSettings]="basinStorms"
            [stormSettings]="stormSettings"
            [isLoading]="isLoading || loadingBasinStormResults"
            [addedStormEvents]="addedStormEvents"
            [removedStormEvents]="removedStormEvents"
            [basinStormResult]="selectedBasinStorm"
            [stormBasinResults]="stormBasinResults"
            [qvsIConfigurations]="qvsIConfigurations"
            [originalQvsIConfigurations]="originalQvsIConfigurations"
            [regimes]="regimes"
            [availableSeasons]="availableSeasons"
            (stormEventsEdited)="setStormEventsEdited($event)"
            (excludedStormToggled)="toggleStormBasinExclusion($event)"
            (stormSelected)="stormSelected($event)"
            (qvsiConfChanged)="qvsiConfUpdated($event)"
            (expandChooser)="expandChooser($event)"
        >
        </app-expanded-storm-chooser>
    </div>

</div>
