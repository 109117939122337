import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnDestroy,
} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
    customerQueryParam,
    customerLocationGroupQueryParam,
    locationIdQueryParam,
    activeInactiveLocationQueryParam,
} from 'app/shared/models/customer';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ScheduleCollectionService } from 'app/shared/services/schedule-collection.service';
import { Subscription } from 'rxjs';
import { AdsPrismAddScheduleComponent } from '../ads-prism-add-schedule/ads-prism-add-schedule.component';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'app/shared/services/shared.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { ListItem } from 'app/shared/models/selected-Item';
import { IComponentCustomizedConfirmation } from 'app/shared/models/customized-confirmation-box';
import { ScheduleCollection } from 'app/shared/models/schedule-collection';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { delay, first } from 'rxjs/operators';
const SUNDAY = 'Sunday';
const MONDAY = 'Monday';
const TUESDAY = 'Tuesday';
const WEDNESDAY = 'Wednesday';
const THRUSDAY = 'Thrusday';
const FRIDAY = 'Friday';
const SATURDAY = 'Saturday';

@Component({
    selector: 'ads-prism-schedule-collect-dashboard',
    templateUrl: './schedule-collect-dashboard-overview.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})
export class ScheduleCollectOverviewComponent implements OnInit, OnDestroy {
    public isLoading: boolean;
    public includeActiveLocation: number;
    public frequenciesList: Array<Object>;
    public minutesText: string;
    public hourlyText: string;
    public hourText: string;
    public customerID: number;
    public locationID: number;
    public locationGroupId: number;
    public displayFilters: boolean;
    public locationsName = [];
    public daysSelected = [];
    public isDashboardCommunication = false;
    private subscriptions = new Array<Subscription>();
    private addDialog: MatDialogRef<AdsPrismAddScheduleComponent>;
    // tslint:disable-next-line:variable-name
    public listItem: Array<ListItem>;
    public filterDayList: Array<ListItem>;
    public schedulingDescriptionColumns = ['name', 'locationCount', 'days', 'frequency', 'eventFrequency', 'action'];
    public SCHEDULING_ELEMENT_DATA: SchedulingElement[] = [];
    public FILTER_DATA_LIST: SchedulingElement[];
    public dataLength = 0;
    public schedulingDataSource = new MatTableDataSource(this.SCHEDULING_ELEMENT_DATA);
    public conformationDataInput: IComponentCustomizedConfirmation;
    public deleteTitle: string;
    public cancelBtn: string;
    public deleteBtn: string;
    public deleteConfirmMsg: string;
    public showConfirmation: boolean;
    public confirmationType: string;
    public dismissBtn: string;
    public scheduleDeleteSuccess: string;
    public selectedSchedule: ScheduleCollection;
    public redirectSourceUrl: string;
    public currentUrl: string;
    public previousUrl: string;
    // public filterLocationsList: Array<Selectable>;
    public allSchedules: Array<ScheduleCollection>;
    public filterArray: FilterData;
    public translateKeys: Array<string> = [
        'COMMON.DELETE_TITLE',
        'COMMON.CANCEL_BUTTON',
        'COMMON.DELETE_TOOLTIP',
        'HOME.WIDGET_SCHEDULE.DELETE_CONFIRM_MSG',
        'COMMON.DISMISS_TEXT',
        'HOME.WIDGET_SCHEDULE.SCHEDULE_DELETE_SUCCESS',
        'HOME.WIDGET_SCHEDULE.HOURLY_TEXT',
        'VAULT.VAULT_ITEM_SHARE.HOURS_TEXT',
        'HOME.WIDGET_SCHEDULE.MINUTES_TEXT',
    ];

    constructor(
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private uiUtilsService: UiUtilsService,
        private translate: TranslateService,
        public scheduleCollectionService: ScheduleCollectionService,
        private sharedService: SharedService,
        private snackBarNotificationService: SnackBarNotificationService,
        public cdr: ChangeDetectorRef,
        public router: Router,
        private domOperationUtilsService: DomOperationUtilsService,
    ) {
        translate.get(this.translateKeys).subscribe((translateValues) => {
            this.deleteTitle = translateValues['COMMON.DELETE_TITLE'];
            this.cancelBtn = translateValues['COMMON.CANCEL_BUTTON'];
            this.deleteBtn = translateValues['COMMON.DELETE_TOOLTIP'];
            this.deleteConfirmMsg = translateValues['HOME.WIDGET_SCHEDULE.DELETE_CONFIRM_MSG'];
            this.dismissBtn = translateValues['COMMON.DISMISS_TEXT'];
            this.scheduleDeleteSuccess = translateValues['HOME.WIDGET_SCHEDULE.SCHEDULE_DELETE_SUCCESS'];
            this.hourlyText = translateValues['HOME.WIDGET_SCHEDULE.HOURLY_TEXT'];
            this.hourText = translateValues['VAULT.VAULT_ITEM_SHARE.HOURS_TEXT'];
            this.minutesText = translateValues['HOME.WIDGET_SCHEDULE.MINUTES_TEXT'];
        });
    }

    public ngOnInit() {
        const hintSubs = this.domOperationUtilsService.openCloseScheduleDialog.subscribe((action: boolean) => {
            if (action) {
                this.addEditSchedule();
                this.addDialog
                    .afterOpened()
                    .pipe(first(), delay(1000))
                    .subscribe(() => this.domOperationUtilsService.showPageHintContent());
            } else if (this.addDialog) {
                this.addDialog.close();
                this.scheduleCollectionService.addDialogIsOpened = false;
                this.domOperationUtilsService.showPageHintContent();
            }
        });
        this.subscriptions.push(hintSubs);
        this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            this.includeActiveLocation = Number(params.get(activeInactiveLocationQueryParam));
            const customerId = Number(params.get(customerQueryParam));
            this.locationGroupId = Number(params.get(customerLocationGroupQueryParam));

            this.redirectSourceUrl = this.previousUrl;

            const locationId = Number(params.get(locationIdQueryParam));
            this.locationID = locationId;
            if (locationId) {
                // api call to get schedule id related to this location
                const scheduleSubscription = this.scheduleCollectionService
                    .getScheduleByLocationId(customerId, locationId)
                    .subscribe(
                        (response) => {
                            if (response && response.scheduleId) {
                                this.addEditSchedule(response.scheduleId);
                            } else {
                                this.addEditSchedule();
                            }
                        },
                        () => {
                            this.addEditSchedule();
                        },
                    );
                this.subscriptions.push(scheduleSubscription);
            }
            // fetch new customers for change
            if (customerId !== this.customerID) {
                this.customerID = customerId;
                this.onCustomerChange();
            }
        });
    }

    public onCustomerChange() {
        this.isLoading = true;
        this.resetFilter();
        this.initializeObjects();
        this.loadScheduleData();
    }

    public resetFilter() {
        this.filterArray = {
            name: '',
            day: [],
            frequency: [],
        };
    }
    private initializeObjects() {
        this.resetDayList();
        this.frequenciesList = [
            { text: this.hourlyText, value: 1 },
            { text: `${4} ${this.hourText}`, value: 4 },
            { text: `${6} ${this.hourText}`, value: 6 },
            { text: `${8} ${this.hourText}`, value: 8 },
            { text: `${12} ${this.hourText}`, value: 12 },
            { text: `${24} ${this.hourText}`, value: 24 },
        ];
        this.initializeFilterData();
    }

    public resetDayList() {
        this.listItem = [
            { id: 0, text: 'Su', checked: false },
            { id: 1, text: 'M', checked: false },
            { id: 2, text: 'Tu', checked: false },
            { id: 3, text: 'W', checked: false },
            { id: 4, text: 'Th', checked: false },
            { id: 5, text: 'F', checked: false },
            { id: 6, text: 'Sa', checked: false },
        ];
    }
    private initializeFilterData() {
        this.filterDayList = [
            { id: 0, text: SUNDAY, checked: false },
            { id: 1, text: MONDAY, checked: false },
            { id: 2, text: TUESDAY, checked: false },
            { id: 3, text: WEDNESDAY, checked: false },
            { id: 4, text: THRUSDAY, checked: false },
            { id: 5, text: FRIDAY, checked: false },
            { id: 6, text: SATURDAY, checked: false },
        ];
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public applyFilter() {
        this.FILTER_DATA_LIST = this.SCHEDULING_ELEMENT_DATA;

        if (this.filterArray.name) {
            this.filterArray.name = this.filterArray.name.toLowerCase();
            this.FILTER_DATA_LIST = this.FILTER_DATA_LIST.filter((x) => {
                return x.name.toLowerCase().includes(this.filterArray.name);
            });
        }

        if (this.filterArray.day && this.filterArray.day.length > 0) {
            this.FILTER_DATA_LIST = this.FILTER_DATA_LIST.filter((f) =>
                this.filterArray.day.some((l) => f.collectionDays.some((x) => +x === l)),
            );
        }
        if (this.filterArray.frequency && this.filterArray.frequency.length > 0) {
            if (this.filterArray.frequency.includes(this.hourlyText)) {
                this.filterArray.frequency[this.filterArray.frequency.indexOf(this.hourlyText)] = '1 Hours';
            }
            this.FILTER_DATA_LIST = this.FILTER_DATA_LIST.filter((f) =>
                this.filterArray.frequency.some((fre) => fre === f.frequency),
            );
        }
        this.schedulingDataSource = new MatTableDataSource(this.FILTER_DATA_LIST);
        this.dataLength = this.FILTER_DATA_LIST.length;
    }

    public showHideFilter() {
        this.displayFilters = !this.displayFilters;
    }

    public addEditSchedule(selectedScheduleid?: number) {
        if (this.addDialog) {
            return;
        }
        this.scheduleCollectionService.addDialogIsOpened = true;
        this.addDialog = this.dialog.open(AdsPrismAddScheduleComponent, {
            disableClose: true,
            data: {
                schedules: this.allSchedules,
                customerId: this.customerID,
                locationId: this.locationID,
                selectedScheduleId: selectedScheduleid,
                isEditMode: selectedScheduleid ? true : false,
            },
            autoFocus: false,
            hasBackdrop: false,
        });

        const editSubscription = this.addDialog.afterClosed().subscribe((res) => {
            this.scheduleCollectionService.addDialogIsOpened = false;
            this.addDialog = null;
            if (res.success) {
                this.resetFilter();
                this.loadScheduleData();
            }
        });
        this.subscriptions.push(editSubscription);
    }

    /**
     * Represents the Delete Action of Schedule Collection
     * @param scheduleCollection -selected Schedule Collection
     */
    public showDeleteConfirmationScheduleCollection(scheduleCollection: ScheduleCollection) {
        this.selectedSchedule = scheduleCollection;
        this.dialog
            .open(ConfirmationDialogComponent, {
                data: {
                    title: this.deleteTitle,
                    message: this.deleteConfirmMsg,
                    cancelText: this.cancelBtn,
                    okText: this.deleteBtn,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                if (res.whichButtonWasPressed === 'ok') {
                    this.deleteScheduleCollection(scheduleCollection);
                }
            });
    }

    /**
     * Represents the Delete Action of Schedule Collection
     */
    private deleteScheduleCollection(scheduleCollection: ScheduleCollection) {
        const deleteSubscription = this.scheduleCollectionService
            .deleteScheduleCollection(scheduleCollection.scheduleId)
            .subscribe(
                (result) => {
                    const displayedMessage = scheduleCollection.name + ' ' + this.scheduleDeleteSuccess;
                    this.snackBarNotification(displayedMessage, '', true);
                    this.resetFilter();
                    this.loadScheduleData();
                },
                (error) => {
                    if (typeof error.json() === 'object' && error.json().hasOwnProperty('responseMessage')) {
                        this.snackBarNotification(error.json().responseMessage, 'custom-error-snack-bar', false);
                    }
                    this.sharedService.setCloseFlagValue(false);
                },
            );
        this.subscriptions.push(deleteSubscription);
    }
    public snackBarNotification(message: string, notificationClass, auto) {
        this.snackBarNotificationService.raiseNotification(message, this.dismissBtn, {
            panelClass: notificationClass,
        }, auto);
    }
    public loadScheduleData() {
        this.isLoading = true;
        this.daysSelected = [];
        this.locationsName = [];
        this.SCHEDULING_ELEMENT_DATA = [];
        const loadSubscription = this.scheduleCollectionService.getLiteScheduledCollections(this.customerID).subscribe(
            (schedules: any[]) => {
                if (schedules) {
                    this.allSchedules = schedules.map(
                        (schedule) =>
                            <ScheduleCollection>{
                                scheduleId: schedule.scheduleId,
                                name: schedule.name,
                                collectTime: schedule.collectTime,
                            },
                    );
                    schedules.forEach((schedule, index) => {
                        if (schedule.alarmingFrequency) {
                            if (schedule.alarmingFrequency.includes('15mins')) {
                                schedule.alarmingFrequency = '15 ' + this.minutesText;
                            }
                            if (schedule.alarmingFrequency.includes('Hrs')) {
                                schedule.alarmingFrequency =
                                    parseInt(schedule.alarmingFrequency.split('Hrs')[0], 10) + ' Hours';
                            }
                        }
                        const data: SchedulingElement = {
                            index: index,
                            name: schedule.name,
                            locationCount: schedule.locationCount,
                            collectionDays: schedule.collectionDays.split(','),
                            frequency: parseInt(schedule.frequency.split('Hrs')[0], 10) + ' Hours',
                            alarmingFrequency: schedule.alarmingFrequency || '',
                            scheduleId: schedule.scheduleId,
                        };
                        // this.locationsName.push(schedule.locations.map((itm) => itm.name).join(', '));
                        this.SCHEDULING_ELEMENT_DATA.push(data);
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                        data.collectionDays.forEach((element) => {
                            const day: ListItem = this.listItem.find((item) => item.id === +element);
                            if (day) {
                                this.listItem.filter((item) => {
                                    if (item.id === day.id) {
                                        item.checked = true;
                                    }
                                });
                            }
                        });
                        const dayArray = this.listItem;
                        this.resetDayList();
                        this.daysSelected.push(dayArray);
                    });
                }
            },
            (error) => {
                this.isLoading = false;
            },
            () => {
                this.schedulingDataSource = new MatTableDataSource(this.SCHEDULING_ELEMENT_DATA);
                this.dataLength = this.SCHEDULING_ELEMENT_DATA.length;
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
        this.subscriptions.push(loadSubscription);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((v) => v.unsubscribe());
        this.scheduleCollectionService.addDialogIsOpened = false;
        if (this.addDialog) {
            this.addDialog.close();
        }
    }
}

export interface SchedulingElement {
    index: number;
    name: string;
    locationCount: number;
    collectionDays: number[];
    frequency: string;
    alarmingFrequency: number;
    scheduleId: number;
}

export interface FilterData {
    name?: string;
    day?: number[];
    frequency: string[];
}
