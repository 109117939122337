import { Injectable } from '@angular/core';
import { Config } from './config';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class MessageService {
    constructor(public http: HttpClient) {}

    public postMessage(type: number, cid: number, message: string) {
        return this.http.post(Config.serviceUrl + Config.urls.message, {
            type,
            message,
            cid,
        });
    }
}
