<div id="stormSettingsDialog" *ngIf="form">
    <app-loader [isLoading]="isLoading"></app-loader>
    <!-- add graph header -->
    <div
        class="popupHeader"
        mat-dialog-title
    >
        <span *ngIf="!data.isCreateMode">{{ 'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.TITLE' | translate }}</span>
        <span *ngIf="data.isCreateMode">{{ 'SLIICER.FLOW_TAB.EDIT_STORM_EVENTS.CREATE_TITLE' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <!-- content -->
    <div mat-dialog-content>
        <form #stormForm="ngForm">
            <div class="section">
                <div class="sectionTitle">{{ 'SLIICER.FLOW_TAB.STORM_SETTINGS_DIALOG.STORM_PERIODS' | translate }}</div>
                <div class="sectionInner">
                    <div class="items">
                        <div class="item four">
                            <mat-form-field class="full-width">
                                <input
                                    matInput
                                    placeholder="{{ 'SLIICER.COMMON.STORM_EVENT.START_DATE_LABEL' | translate }}"
                                    (change)="eventStartChanged($event)"
                                    (input)="eventStartInput($event)"
                                    [value]="form.eventStartDate.raw | date: customDateFormat"
                                    class="datepickerStyles"
                                    appDatetimepickerPosition
                                    [matDatetimepickerComp]="eventStartDatePicker"
                                />
                                <input
                                    hidden
                                    [matDatetimepicker]="eventStartDatePicker"
                                    name="eventStart"
                                    [ngModel]="form.eventStartDate.raw"
                                    (dateChange)="eventStartChanged($event)"
                                />
                                <mat-datetimepicker-toggle matSuffix [for]="eventStartDatePicker">
                                </mat-datetimepicker-toggle>
                                <mat-datetimepicker
                                    #eventStartDatePicker
                                    [type]="'datetime'"
                                >
                                </mat-datetimepicker>
                                <mat-error *ngIf="form.eventStartDate.val | isLeft">
                                    {{ form.eventStartDate.val.value | translate }}</mat-error>
                                </mat-form-field>
                                <div *ngIf="stormStartTimeExistsError" class="mat-error">
                                    <span>
                                        {{ 'SLIICER.COMMON.STORM_EVENT.START_DATE_ERROR' | translate }}
                                    </span>
                                </div>
                        </div>
                        <div class="item four">
                            <mat-form-field class="full-width">
                                <input
                                    matInput
                                    disabled
                                    placeholder="{{ 'SLIICER.COMMON.STORM_EVENT.END_DATE_LABEL' | translate }}"
                                    (change)="eventEndChanged($event)"
                                    [value]="form.eventEndDate.raw | date: customDateFormat"
                                    class="datepickerStyles"
                                    appDatetimepickerPosition
                                    [matDatetimepickerComp]="eventEndDatePicker"
                                />
                                <input
                                    hidden
                                    disabled
                                    [matDatetimepicker]="eventEndDatePicker"
                                    name="eventEnd"
                                    [ngModel]="form.eventEndDate.raw"
                                    (dateChange)="eventEndChanged($event)"
                                />
                                <mat-datetimepicker-toggle matSuffix [for]="eventEndDatePicker">
                                </mat-datetimepicker-toggle>
                                <mat-datetimepicker
                                    #eventEndDatePicker
                                    [type]="'datetime'"
                                >
                                </mat-datetimepicker>

                                <mat-error *ngIf="form.eventEndDate.val | isLeft">
                                    {{ form.eventEndDate.val.value | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="item four">
                            <mat-form-field class="full-width">
                                <input
                                    matInput
                                    [(disabled)]="form.deattachPrecomp || data.isCreateMode"
                                    placeholder="{{ 'SLIICER.COMMON.STORM_EVENT.PRE_START_DATE_LABEL' | translate }}"
                                    (change)="preStartChangedInput($event)"
                                    [value]="form.preStartDate.raw | date: customDateFormat"
                                    class="datepickerStyles"
                                    appDatetimepickerPosition
                                    [matDatetimepickerComp]="preStartDatePicker"
                                />
                                <input
                                    hidden
                                    [(disabled)]="form.deattachPrecomp || data.isCreateMode"
                                    [matDatetimepicker]="preStartDatePicker"
                                    name="precompStart"
                                    [ngModel]="form.preStartDate.raw"
                                    (dateChange)="preStartChangedDatePicker($event)"
                                />
                                <mat-datetimepicker-toggle matSuffix [for]="preStartDatePicker">
                                </mat-datetimepicker-toggle>
                                <mat-datetimepicker
                                    #preStartDatePicker
                                    [type]="'datetime'"
                                >
                                </mat-datetimepicker>

                                <mat-error *ngIf="form.preStartDate.val | isLeft">
                                    {{ form.preStartDate.val.value | translate }}</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="item four">
                            <mat-form-field class="full-width">
                                <input
                                    matInput
                                    disabled
                                    placeholder="{{ 'SLIICER.COMMON.STORM_EVENT.PRE_END_DATE_LABEL' | translate }}"
                                    [value]="form.preEndDate.raw | date: customDateFormat"
                                    class="datepickerStyles"
                                    appDatetimepickerPosition
                                    [matDatetimepickerComp]="preEndDatePicker"
                                />
                                <input
                                    hidden
                                    disabled
                                    [matDatetimepicker]="preEndDatePicker"
                                    name="precompEnd"
                                    [ngModel]="form.preEndDate.raw"
                                />
                                <mat-datetimepicker-toggle matSuffix [for]="preEndDatePicker">
                                </mat-datetimepicker-toggle>
                                <mat-datetimepicker
                                    #preEndDatePicker
                                    [type]="'datetime'"
                                >
                                </mat-datetimepicker>

                                <mat-error *ngIf="form.preEndDate.val | isLeft">
                                    {{ form.preEndDate.val.value | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="items">
                        <div class="item four">
                            <mat-form-field class="full-width">
                                <mat-label>
                                    {{ 'SLIICER.COMMON.STORM_EVENT.PRECOMPENSATION_DURATION_LABEL' | translate }}
                                </mat-label>
                                <input
                                    [disabled]="data.isCreateMode"
                                    matInput
                                    type="number"
                                    [ngModel]="form.precompPeriod.raw"
                                    name="precompDuration"
                                    (ngModelChange)="precompPeriodChanged($event)"
                                    (blur)="periodsBlur(durationKeys.precomp)"
                                    [eitherValidation]="form.precompPeriod.val"
                                />
                                <mat-error *ngIf="form.precompPeriod.val | isLeft">
                                    {{ form.precompPeriod.val.value | translate }}
                                </mat-error>
                                <span matSuffix>{{ 'SLIICER.COMMON.STORM_EVENT.MIN_LABEL' | translate }}</span>
                            </mat-form-field>
                        </div>
                        <div class="item four">
                            <mat-form-field class="full-width">
                                <mat-label>
                                    {{ 'SLIICER.COMMON.STORM_EVENT.STORM_PERIOD_DURATION_LABEL' | translate }}
                                </mat-label>
                                <input
                                    [disabled]="data.isCreateMode"
                                    matInput
                                    type="number"
                                    [ngModel]="form.stormPeriod.raw"
                                    name="stormDuration"
                                    (ngModelChange)="stormPeriodChanged($event)"
                                    (blur)="periodsBlur(durationKeys.storm)"
                                    [eitherValidation]="form.stormPeriod.val"
                                />
                                <mat-error *ngIf="form.stormPeriod.val | isLeft">
                                    {{ form.stormPeriod.val.value | translate }}
                                </mat-error>
                                <span matSuffix>{{ 'SLIICER.COMMON.STORM_EVENT.MIN_LABEL' | translate }}</span>
                            </mat-form-field>
                        </div>
                        <div class="item four">
                            <mat-form-field class="full-width">
                                <mat-label>
                                    {{ 'SLIICER.COMMON.STORM_EVENT.RECOVERY_1_DURATION_LABEL' | translate }}
                                </mat-label>
                                <input
                                    [disabled]="data.isCreateMode"
                                    matInput
                                    type="number"
                                    [ngModel]="form.recoveryOne.raw"
                                    name="recoveryOneDuration"
                                    (ngModelChange)="recoveryOneChanged($event)"
                                    (blur)="periodsBlur(durationKeys.recoveryOne)"
                                    [eitherValidation]="form.recoveryOne.val"
                                />
                                <mat-error *ngIf="form.recoveryOne.val | isLeft">
                                    {{ form.recoveryOne.val.value | translate }}
                                </mat-error>
                                <span matSuffix>{{ 'SLIICER.COMMON.STORM_EVENT.MIN_LABEL' | translate }}</span>
                            </mat-form-field>
                        </div>
                        <div class="item four">
                            <mat-form-field class="full-width">
                                <mat-label>
                                    {{ 'SLIICER.COMMON.STORM_EVENT.RECOVERY_2_DURATION_LABEL' | translate }}
                                </mat-label>
                                <input
                                    [disabled]="data.isCreateMode"
                                    matInput
                                    type="number"
                                    [ngModel]="form.recoveryTwo.raw"
                                    (blur)="periodsBlur(durationKeys.recoveryTwo)"
                                    name="recoveryTwoDuration"
                                    (ngModelChange)="recoveryTwoChanged($event)"
                                    [eitherValidation]="form.recoveryTwo.val"
                                    placeholder="{{ 'SLIICER.COMMON.STORM_EVENT.RECOVERY_2_DURATION_LABEL' | translate }}"
                                />
                                <mat-error *ngIf="form.recoveryTwo.val | isLeft">
                                    {{ form.recoveryTwo.val.value | translate }}
                                </mat-error>
                                <span matSuffix>{{ 'SLIICER.COMMON.STORM_EVENT.MIN_LABEL' | translate }}</span>
                            </mat-form-field>
                        </div>
                    </div>
                    <p class="round-label">{{ 'SLIICER.COMMON.STORM_EVENT.ROUND_NOTIFICATION' | translate }}</p>
                    <div class="form-checkbox-wrapper">
                        <mat-checkbox
                            [disabled]="data.isCreateMode"
                            class="custom-chk-margin"
                            [checked]="form.deattachPrecomp"
                            [ngModel]="form.deattachPrecomp"
                            name="deattachPrecomp"
                            (change)="deattachPrecompChanged($event)"
                        >
                            <span>{{ 'SLIICER.COMMON.STORM_EVENT.ATTACH_PRECOMP' | translate }}</span>
                        </mat-checkbox>

                        <mat-checkbox
                            [disabled]="data.isCreateMode"
                            class="custom-chk-margin exclude-storm-check"
                            [checked]="form.exclude"
                            name="excludeStorm"
                            [ngModel]="form.exclude"
                            (change)="excludeStormChanged($event)"
                        >
                            <span>{{ 'SLIICER.COMMON.STORM_EVENT.EXCLUDE_STORM' | translate }}</span>
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <!-- #33070 SOFT REMOVE - will be used in future -->
            <!-- <div class="section">
                <div class="sectionTitle">{{ 'SLIICER.FLOW_TAB.STORM_SETTINGS_DIALOG.SEASONS_REGIMES' | translate }}</div>
                <div class="sectionInner">
                    <div class="items">
                        <div class="item four">
                            <mat-form-field class="full-width">
                                <mat-select
                                    placeholder="{{ 'SLIICER.COMMON.STORM_EVENT.SEASON_LABEL' | translate }}"
                                    [(ngModel)]="form.season"
                                    [disabled]="seasons.length == 0"
                                >
                                    <mat-option
                                        *ngFor="let season of seasons"
                                        [value]="season.name"
                                    >
                                        {{ season.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="item four">
                            <mat-form-field class="full-width">
                                <mat-select
                                    placeholder="{{ 'SLIICER.COMMON.STORM_EVENT.REGIME_LABEL' | translate }}"
                                    [(ngModel)]="form.regime"
                                    [disabled]="regimes.length == 0"
                                >
                                    <mat-option
                                        *ngFor="let regime of regimes"
                                        [value]="regime.name"
                                    >
                                        {{ regime.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="section">
                <div class="sectionTitle">{{ 'SLIICER.FLOW_TAB.STORM_SETTINGS_DIALOG.OTHER_SETTINGS' | translate }}</div>
                <div class="sectionInner">
                    <div class="items">
                        <div class="item four">
                            <mat-form-field class="full-width">
                                <mat-select
                                    [disabled]="data.isCreateMode"
                                    placeholder="{{
                                        'SLIICER.COMMON.STORM_EVENT.PRECOMPENSATION_METHOD_LABEL' | translate
                                    }}"
                                    [ngModel]="form.precompType.raw"
                                    name="precompType"
                                    [eitherValidation]="form.precompType.val"
                                    (ngModelChange)="precompTypeChanged($event)"
                                >
                                    <mat-option
                                        *ngFor="let each of precompTypes"
                                        [value]="each.value"
                                    >
                                        {{ each.value }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="form.precompType.val | isLeft">
                                    {{ form.precompType.val.value | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div mat-dialog-actions>
        <span
            class="mat-error pad-l-15 w-70"
            [ngClass]="{hide:form.deattachPrecomp || (this.form.preEndDate.raw && this.form.preEndDate.raw.getTime() < this.form.rainStartTime.raw.getTime())}"
        >
            {{ 'SLIICER.COMMON.STORM_EVENT.PRECOMP_ERROR_1' | translate }}
            <b>{{ 'SLIICER.COMMON.STORM_EVENT.PRECOMP_ERROR_2' | translate }}</b>
            {{ 'SLIICER.COMMON.STORM_EVENT.PRECOMP_ERROR_3' | translate }}
            <b>{{ 'SLIICER.COMMON.STORM_EVENT.PRECOMP_ERROR_4' | translate }}</b>
            {{ 'SLIICER.COMMON.STORM_EVENT.PRECOMP_ERROR_5' | translate }}
            <b>{{ 'SLIICER.COMMON.STORM_EVENT.PRECOMP_ERROR_6' | translate }}</b>
        </span>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button
                mat-button
                (click)="cancelClose()"
            >{{ 'COMMON.CANCEL_BUTTON' | translate }}</button>
        </div>
        <div class="auto-margin">
            <button *ngIf="!data.isCreateMode"
            [disabled]="stormStartTimeExistsError || (!stormForm || stormForm.pristine) || (!form.deattachPrecomp && this.form.preEndDate.raw && this.form.preEndDate.raw.getTime() >= this.form.rainStartTime.raw.getTime())"
            mat-button
            (click)="applyChanges()"
            >{{ 'COMMON.OK' | translate }}</button>
            <button *ngIf="data.isCreateMode"
                mat-button
                [disabled]="stormDateInputChange || stormStartTimeExistsError"
                (click)="applyChanges()"
            >{{ 'COMMON.ADD_BUTTON' | translate | uppercase }}</button>
        </div>
        <div class="auto-margin">
            <button
                mat-button
                (click)="resetChanges()"
            >{{ 'COMMON.RESET_BTN' | translate }}</button>
        </div>
    </div>
</div>
