<div
    [ngClass]="{
        'loader-position': isPositionAbsolute,
        overlay: isPositionAbsolute,
        'loader-small-padding': !isPositionAbsolute
    }"
    *ngIf="isLoading"
>
    <mat-progress-spinner diameter="25" mode="indeterminate" color="primary"></mat-progress-spinner>
</div>
