import {
    Component,
    ViewEncapsulation,
    Input,
    ViewChild,
    SimpleChanges,
    Output,
    EventEmitter,
    OnInit,
    OnChanges
} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource, MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { ChangesAction } from 'app/pages/sliicer/shared/components/updates-widget/updates-widget.models';
import { WeekGroup } from 'app/shared/models/sliicer';
import { SliicerService } from 'app/shared/services/sliicer.service';
import * as _ from 'underscore';

@Component({
    selector: 'ads-week-groups-settings',
    templateUrl: './week-groups-settings.component.html',
    styleUrls: ['./week-groups-settings.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class WeekGroupsSettingsComponent implements OnInit, OnChanges {
    @Input() public customerId: number;
    @Input() public caseStudyId: string;
    @Input() public isStudyLocked: boolean;
    @Input() public shouldUpdate: boolean;
    @Input() public changesAction: ChangesAction;
    @Input() public originalDayGroups: WeekGroup[];
    @Output() public checkChanges: EventEmitter<string> = new EventEmitter();
    @ViewChild('weekgrouptable', { read: MatTable }) public weekGroupTable: MatTable<any>;

    // Constants
    public weekgroupColumns: Array<string> = [
        'name',
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
    ];
    private selectionColumn = 'selection';

    // Public variables
    public weekgroupDataSource: MatTableDataSource<any>;
    public dirty = false;
    public error = false;
    public errorType = {
        uniqueNames: false,
        allDays: false,
        emptyName: false,
        needsDay: false
    }
    public showRemoveSelectedItems: boolean;
    public formValues;
    public initialFormValues;

    // Private variables
    private selectedWeekGroup: Array<WeekGroup> = [];

    // Translated strings
    private dayNonSelectionErrorMsg: string;
    private saveErrorGroupDays: string;
    private saveErrorGroupName: string;
    public dayStrings: string[] = [];

    constructor(private translate: TranslateService, private sliicerService: SliicerService) {}

    public ngOnInit() {
        this.initializeTranslations();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.shouldUpdate) {
            this.restorePreviousValue();
        } else if (changes.changesAction && this.getChangeValue()) {
            if (this.changesAction.action === 'undoChanges') {
                this.restorePreviousValue();
                this.dirty = false;
                this.checkChanges.emit('');
            }
        }
    }

    /**
     * Add or remove items from this.selectedWeekGroup based
     * on checkbox activity
     */
    public rowSelection(i: number, weekGroup: WeekGroup, value) {
        if (value) {
            this.selectedWeekGroup[i] = weekGroup;
        } else {
            this.selectedWeekGroup[i] = undefined;
        }
        this.checkChanges.emit('');
    }

    /**
     * Show the week group checkbox column
     */
    public deleteDayGroup() {
        this.weekgroupColumns.unshift(this.selectionColumn);
        this.showRemoveSelectedItems = true;
    }

    /**
     * Hide the week group checkbox column
     */
    private hideDeleteDayGroup() {
        this.weekgroupColumns = this.weekgroupColumns.filter((item) => item !== this.selectionColumn);
        this.showRemoveSelectedItems = false;
    }

    /**
     * User has clicked the delete selected groups button... do it.
     */
    public deleteGroup() {
        this.dirty = true;

        const filteredData = this.weekgroupDataSource.data.filter((el, index) => !this.selectedWeekGroup[index]);
        this.weekgroupDataSource.data = filteredData;
        this.formValues = filteredData;

        this.weekGroupTable.renderRows();

        // clear out selected week groups
        this.selectedWeekGroup = [];

        this.updateWeeksError();
        this.updateNameError();

        this.hideDeleteDayGroup();

        this.checkChanges.emit();
    }

    /**
     * User has clicked a checkbox in the table for a day to belong
     * to a day group.
     * Note: A day can only belong to a single Day Group. Handle it.
     */
    public dayGroupSelection(column: number, index: number) {
        this.dirty = true;
        const groupvalues = this.weekgroupDataSource.data;
        if (groupvalues[index].days[column]) {
            for (let i = 0; i < groupvalues.length; i++) {
                groupvalues[i].days[column] = false;
            }
            groupvalues[index].days[column] = true;
        }

        this.updateWeeksError();

        this.weekGroupTable.renderRows();

        this.checkChanges.emit();
    }

    private updateWeeksError() {
        const groupvalues = this.weekgroupDataSource.data;
        const allRows = [false, false, false, false, false, false, false];
        let hasAnyDay = true;
        for (let i = 0; i < groupvalues.length; i++) {
            const v = groupvalues[i].days;
            let anyDayTrue = false;
            for(let j = 0; j < v.length; j++) {
                allRows[j] = allRows[j] || v[j];
                anyDayTrue = anyDayTrue || v[j]
            }

            hasAnyDay = hasAnyDay && anyDayTrue;
        }

        this.errorType.allDays = !allRows.every(r => r);
        this.errorType.needsDay = !hasAnyDay;

        this.updateError();
    }

    private updateNameError() {
        let areNamesUnique = true;

        const weekNames = [];
        let emptyName = false;
        for(let i = 0; i < this.weekgroupDataSource.data.length; i++) {
            const row = this.weekgroupDataSource.data[i];
            const name = row.name;
            emptyName = emptyName || name === '' || !name;
            if(weekNames[name.toLowerCase()]) {
                areNamesUnique = false;
                break;
            } else {
                weekNames[name.toLowerCase()] = true;
            }
        }

        this.errorType.uniqueNames = !areNamesUnique;
        this.errorType.emptyName = emptyName;

        this.updateError();
    }

    private updateError() {
        this.error =
            this.errorType.allDays
            || this.errorType.emptyName
            || this.errorType.needsDay
            || this.errorType.uniqueNames;
    }

    /**
     * Add a new row to the table
     */
    public addNewRow() {
        this.dirty = true;
        this.weekgroupDataSource.data.push({ name: '', days: [false, false, false, false, false, false, false] });
        this.weekGroupTable.renderRows();
        this.updateNameError();
        this.updateWeeksError();
    }

    public nameChanged() {
        this.dirty = true;

        this.updateNameError();

        this.checkChanges.emit();
    }

    public getChangeValue(): boolean {
        const withoutSelection = this.formValues.map(el => el);
        withoutSelection.forEach(el => { delete el.selection;});
        return !_.isEqual(withoutSelection, this.initialFormValues) && !this.error;
    }

    /**
     * Get the week group data
     */
    public getChanges(): WeekGroup[] {
        const groupErrors = this.getGroupErrors(this.weekgroupDataSource.data);
        if (groupErrors.length > 0) {
            // TODO: WPS - how about having a message that shows _all_ issues found?
            this.sliicerService.showToast(groupErrors[0], true);
            return null;
        }

        const nonSelectedDays = this.getDaysUnaccountedFor(this.weekgroupDataSource.data);
        if (nonSelectedDays.length > 0) {
            const days = nonSelectedDays.join(', ');
            const message = this.dayNonSelectionErrorMsg.replace('{{dayName}}', days);
            this.sliicerService.showToast(message, true);
            return null;
        }

        const weekGroups = this.weekGroupsFromDataSource(this.weekgroupDataSource.data);
        this.originalDayGroups = weekGroups;
        this.dirty = false;
        return weekGroups;
    }

    /********************************************************************************/
    /* PRIVATE METHODS                                                              */
    /********************************************************************************/

    private restorePreviousValue() {
        this.setWeekGroupData(this.originalDayGroups);
    }

    private initializeTranslations() {
        const translateKeys = [
            'SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.SAVE_ERROR_DAY_NEEDS_GROUP',
            'SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.WEEKDAY_SUN',
            'SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.WEEKDAY_MON',
            'SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.WEEKDAY_TUE',
            'SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.WEEKDAY_WED',
            'SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.WEEKDAY_THU',
            'SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.WEEKDAY_FRI',
            'SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.WEEKDAY_SAT',
            'SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.SAVE_ERROR_GROUP_NEEDS_DAY',
            'SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.SAVE_ERROR_GROUP_NEEDS_NAME',
        ];
        this.translate.get(translateKeys).subscribe((values: Array<string>) => {
            this.dayNonSelectionErrorMsg =
                values['SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.SAVE_ERROR_DAY_NEEDS_GROUP'];
            this.dayStrings = [
                values['SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.WEEKDAY_SUN'],
                values['SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.WEEKDAY_MON'],
                values['SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.WEEKDAY_TUE'],
                values['SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.WEEKDAY_WED'],
                values['SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.WEEKDAY_THU'],
                values['SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.WEEKDAY_FRI'],
                values['SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.WEEKDAY_SAT'],
            ];
            this.saveErrorGroupDays = values['SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.SAVE_ERROR_GROUP_NEEDS_DAY'];
            this.saveErrorGroupName = values['SLIICER.EDIT_SETTINGS.DAY_OF_WEEK_GROUPS.SAVE_ERROR_GROUP_NEEDS_NAME'];
        });
    }

    /**
     * Build the data source from the study week groups
     * @param wg The study week groups
     */
    private setWeekGroupData(wg: WeekGroup[]) {
        const weekGroups = [];
        if (wg) {
            wg.forEach((group) => {
                const daysValues = [false, false, false, false, false, false, false];
                group.days.forEach((value) => {
                    daysValues[value] = true;
                });
                weekGroups.push({
                    name: group.name,
                    days: daysValues,
                });
            });
        }
        this.weekgroupDataSource = new MatTableDataSource(weekGroups);
        this.formValues = weekGroups;
        this.initialFormValues = JSON.parse(JSON.stringify(weekGroups));
    }

    private weekGroupsFromDataSource(dataSource: any[]): WeekGroup[] {
        const weekGroups: WeekGroup[] = [];
        for (let i = 0; i < dataSource.length; i++) {
            const dayItems: Array<number> = [];
            for (let j = 0; j < this.weekgroupDataSource.data[i].days.length; j++) {
                if (this.weekgroupDataSource.data[i].days[j]) {
                    dayItems.push(j);
                }
            }
            weekGroups.push({
                name: this.weekgroupDataSource.data[i].name,
                days: dayItems,
            });
        }
        return weekGroups;
    }

    private getGroupErrors(weekGroups: any[]): string[] {
        const messages: string[] = [];
        let foundMissingName = false;
        weekGroups.forEach((group) => {
            const numbers: boolean[] = group['days'];
            if (numbers.findIndex((n) => n === true) === -1) {
                messages.push(this.saveErrorGroupDays.replace('{{groupName}}', group['name']));
            }
            if (!foundMissingName && group['name'] === '') {
                foundMissingName = true;
                messages.push(this.saveErrorGroupName);
            }
        });
        return messages;
    }

    private getDaysUnaccountedFor(data: any[]): string[] {
        const nonSelectedDayList = [0, 1, 2, 3, 4, 5, 6];
        data.filter((dayGroup) => {
            const days: boolean[] = dayGroup['days'];
            days.filter((daySelected, index) => {
                if (daySelected) {
                    const existingIndex = nonSelectedDayList.indexOf(index);
                    if (existingIndex >= 0) {
                        nonSelectedDayList.splice(existingIndex, 1);
                    }
                }
            });
        });

        const missingDayNames = [];
        nonSelectedDayList.forEach((dayIndex) => {
            if (dayIndex <= this.dayStrings.length) {
                missingDayNames.push(this.dayStrings[dayIndex]);
            }
        });
        return missingDayNames;
    }

}
