<div id="WelcomeContactUsDialog">
    <div class="popupHeader" mat-dialog-title>
        <span>CONTACT US</span>
        <span class="app-flex-filler"></span>
    </div>
    <div mat-dialog-content id="contactUsContainerId">
        <div class="contactUsContainer">
            <div class="contactDetails">
                <div class="eachContactDetails">
                    <div class="icon">
                        <button mat-fab color="accent" class="mat-fabIcon-40">
                            <mat-icon>location_on</mat-icon>
                        </button>
                    </div>
                    <div class="details">
                        <h3>Visit Us</h3>
                        <a href="https://goo.gl/maps/9vaEzRc9YH92" target="_blank" rel="noopener"
                            >340 The Bridge Street, Suite 204, Huntsville, AL 35806
                        </a>
                    </div>
                </div>
                <div class="eachContactDetails">
                    <div class="icon">
                        <button mat-fab class="mat-fabIcon-40 b-blue">
                            <mat-icon>call</mat-icon>
                        </button>
                    </div>
                    <div class="details">
                        <h3>Call Us</h3>
                        256-430-3366 (inside the U.S.), 877-237-9585 (toll-free)
                    </div>
                </div>
                <div class="eachContactDetails">
                    <div class="icon">
                        <button mat-fab class="mat-fabIcon-40 b-orange">
                            <mat-icon>print</mat-icon>
                        </button>
                    </div>
                    <div class="details">
                        <h3>Fax</h3>
                        256-430-6633
                    </div>
                </div>
                <div class="eachContactDetails">
                    <div class="icon">
                        <button mat-fab class="mat-fabIcon-40 b-pink">
                            <mat-icon>email</mat-icon>
                        </button>
                    </div>
                    <div class="details">
                        <h3>Write to Us</h3>
                        <a href="mailto:ADSSupportCenter@IDEXCorp.com">ADSSupportCenter@IDEXCorp.com </a>
                    </div>
                </div>
            </div>
            <div class="contactMap">
                <a class="contactMapImg" href="https://goo.gl/maps/9vaEzRc9YH92" target="_blank" rel="noopener"></a>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="emitContactUs()">close</button>
        </div>
    </div>
</div>
