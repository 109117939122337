import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef, ViewChild, LOCALE_ID } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { isRight } from 'fp-ts/es6/Either';
import { PRECOMP_TYPES, Regime } from 'app/shared/models/sliicer/settings';
import * as SSD from './storm-settings-dialog.utils';
import { Season } from '../../../study-settings/seasons-settings/seasons-settings.utils';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { NgForm } from '@angular/forms';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { SliicerCaseStudy } from 'app/shared/models/sliicer';
import { MatDatetimepickerInputEvent } from '@mat-datetimepicker/core';
import * as moment from 'moment';
import { formatDate } from '@angular/common';

enum DurationKeys {
    precomp = 'precompPeriod',
    storm = 'stormPeriod',
    recoveryOne = 'recoveryOne',
    recoveryTwo = 'recoveryTwo'
}

@Component({
    selector: 'app-storm-settings-dialog',
    templateUrl: './storm-settings-dialog.component.html',
    styleUrls: ['./storm-settings-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StormSettingsDialogComponent implements OnInit {
    @ViewChild('stormForm') public stormForm: NgForm;
    public isLoading = false;
    public form: SSD.Form;

    public precompTypes = PRECOMP_TYPES;

    public seasons: Season[] = [];
    public regimes: Regime[] = [];

    public stormStartTimeExistsError = false;
    public stormDateInputChange = false;
    private study: SliicerCaseStudy;

    public customDateFormat: string;

    durationKeys = DurationKeys;
    constructor(
        @Inject(LOCALE_ID) private locale: string,
        @Inject(MAT_DIALOG_DATA) public data: SSD.StormSettingsDialogData,
        private dialogRef: MatDialogRef<StormSettingsDialogComponent, SSD.StormSettingsDialogData>,
        private uiUtilsService: UiUtilsService,
        private changeDetector: ChangeDetectorRef,
        private dateUtilService: DateutilService,
        private sliicerService: SliicerService
    ) { }

    public ngOnInit() {
        const dateFormat = this.dateUtilService.getFormat();
        const is12HourFormat = this.dateUtilService.timeFormat.getValue() !== 'hh:mm:ss';
        this.customDateFormat = is12HourFormat ? `${dateFormat}, ${'hh:mm a'}` : `${dateFormat}, ${'HH:mm'}`;

        this.study = this.sliicerService.caseStudyDetails.getValue();
        this.form = SSD.init(this.data);

        if (this.data.seasons) {
            this.seasons = this.data.seasons;
        }
        if (this.data.regimes) {
            this.regimes = this.data.regimes;
        }
    }
    public eventStartInput(event) {
        this.stormDateInputChange = true;
    }
    public applyChanges() {
        const formRes = SSD.result(this.form);
        if (isRight(formRes)) {
            this.dialogRef.close(formRes.value);
        } else {
            this.form = { ...this.form, isDirty: true };
        }
    }

    public resetChanges() {
        this.form = SSD.init(this.data);

        this.stormStartTimeExistsError = this.data.existingStormsStartDates.includes(new Date(this.form.eventStartDate.raw).getTime());
    }

    public cancelClose() {
        this.dialogRef.close();
    }

    public rainStartChanged(date: Date) {
        this.form = SSD.updateRainStart(this.form, date);
    }

    public rainEndChanged(date: Date) {
        this.form = SSD.updateRainEnd(this.form, date);
    }

    public eventStartChanged(event: MatDatetimepickerInputEvent<Date> | Event) {
        let date: Date;
        // if no event value, then user changed the date input manually
        // date value will be provided as string, so we need momentJS to convert it to Date
        if (event instanceof Event) {
            const input = event.target as HTMLInputElement;
            const momentFormat = `${String(this.dateUtilService.getFormat()).toUpperCase()}, ${this.dateUtilService.getTimeFormatWithoutSeconds()}`;
            const momentDate = moment(input.value, momentFormat, true);

            if(momentDate.isValid()) {
                date = new Date(momentDate.toDate());
            } else {
                date = undefined;
            }
        } else {
            date = event.value;
        }

        const isDateValid = date && date instanceof Date && !isNaN(date.getTime());
        this.stormDateInputChange = false;

        if(isDateValid) {
            const studyStepLength = this.study.config.stepLengthMinutes;
            const isHourlyStep = studyStepLength === 60;

            if (isHourlyStep) {
                date = this.dateUtilService.roundToNearestHour(new Date(date));
            } else {
                date = this.dateUtilService.roundToNearest30(new Date(date));
            }

            // #39446 need to null the form for a moment, so date picker component in html file will lose its referrence and will show the right value
            // if not, it will update the value, but UI will still display old value on the date picker
            const temp = this.form;
            this.form = null;
            this.uiUtilsService.safeChangeDetection(this.changeDetector);

            this.form = SSD.updateEventStart(temp, date);
            this.stormStartTimeExistsError = this.data.existingStormsStartDates.includes(new Date(this.form.eventStartDate.raw).getTime());

            this.uiUtilsService.safeChangeDetection(this.changeDetector);
            
            setTimeout(() => {
                this.stormForm.form.markAsDirty();
                this.stormForm.form.updateValueAndValidity();
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            }, 0);
        } else {
            const input = event.target as HTMLInputElement;
            if(input) {
                input.value = formatDate(this.form.eventStartDate.raw, this.customDateFormat, this.locale);
            }
            this.stormForm.form.markAsDirty();
            this.stormForm.form.updateValueAndValidity();
            this.uiUtilsService.safeChangeDetection(this.changeDetector);
        }
    }

    public eventEndChanged(event: MatDatetimepickerInputEvent<Date>) {
        const date = event.value;
        this.form = SSD.updateEventEnd(this.form, date);

        this.stormDateInputChange = false;
        this.stormForm.form.markAsDirty();
        this.stormForm.form.updateValueAndValidity()
    }

    public deattachPrecompChanged(bool) {
        this.form = SSD.updateDeattachPrecomp(this.form, bool.checked);
        this.uiUtilsService.safeChangeDetection(this.changeDetector);
    }

    public excludeStormChanged(bool) {
        this.form = SSD.updateExcludeStorm(this.form, bool.checked);
        this.uiUtilsService.safeChangeDetection(this.changeDetector);
    }

    public preStartChangedInput(event: Event) {
        const input = event.target as HTMLInputElement;
        const momentFormat = `${String(this.dateUtilService.getFormat()).toUpperCase()}, ${this.dateUtilService.getTimeFormatWithoutSeconds()}`;
        const momentDate = moment(input.value, momentFormat, true);
        let date: Date;
        if(momentDate.isValid()) {
            date = new Date(momentDate.toDate());
        } else {
            date = undefined;
        }
        this.preStartChangedUpdate(date);
    }

    public preStartChangedDatePicker(event: MatDatetimepickerInputEvent<Date>) {
        const date = event.value;
        this.preStartChangedUpdate(date);
    }

    public preStartChangedUpdate(date: Date) {
        if (date) {
            const studyStepLength = this.study.config.stepLengthMinutes;
            const isHourlyStep = studyStepLength === 60;

            if (isHourlyStep) {
                date = this.dateUtilService.roundToNearestHour(new Date(date));
            } else {
                date = this.dateUtilService.roundToNearest30(new Date(date));
            }
        }

        // #39446 need to null the form for a moment, so date picker component in html file will lose its referrence and will show the right value
        // if not, it will update the value, but UI will still display old value on the date picker
        const temp = this.form;
        this.form = null;
        this.uiUtilsService.safeChangeDetection(this.changeDetector);
        this.form = SSD.updatePreStart(temp, date);

        this.uiUtilsService.safeChangeDetection(this.changeDetector);
        
        setTimeout(() => {
            this.preEndChanged(date);
            this.stormForm.form.markAsDirty();
            this.stormForm.form.updateValueAndValidity();
            this.uiUtilsService.safeChangeDetection(this.changeDetector);
        }, 0);
    }

    public preEndChanged(date: Date) {
        this.form = SSD.updatePreEnd(this.form, date);
    }

    public precompPeriodChanged(str: string) {
        this.form = SSD.updatePrecompPeriod(this.form, str);
    }

    public stormPeriodChanged(str: string) {
        this.form = SSD.updateStormPeriod(this.form, str);
    }

    public recoveryOneChanged(str: string) {
        this.form = SSD.updateRecoveryOne(this.form, str);
    }

    public recoveryTwoChanged(str: string) {
        this.form = SSD.updateRecoveryTwo(this.form, str);
    }

    public precompTypeChanged(str: string) {
        this.form = SSD.updatePrecompType(this.form, str);
    }

    public periodsBlur(key: DurationKeys) {
        const value = parseInt(this.form[key].raw);

        const studyStepLength = this.study.config.stepLengthMinutes;
        const isHourlyStep = studyStepLength === 60;
        
        const roundedValue = this.roundMinutes(value, isHourlyStep);
        switch(key) {
            case DurationKeys.precomp: {
                this.precompPeriodChanged(String(roundedValue));
                break;
            }
            case DurationKeys.storm: {
                this.stormPeriodChanged(String(roundedValue));
                break;
            }
            case DurationKeys.recoveryOne: {
                this.recoveryOneChanged(String(roundedValue));
                break;
            }
            case DurationKeys.recoveryTwo: {
                this.recoveryTwoChanged(String(roundedValue));
                break;
            }
            default: break;
        }

    }

    private roundMinutes(minutes: number, isHourlyStep: boolean): number {
        const step = isHourlyStep ? 60 : 30;

        return Math.round(minutes / step) * step;
    }
}
