import { Injectable } from '@angular/core';
import { Config } from './config';
import { EMPTY, Observable } from 'rxjs';
import { ScheduleCollection } from 'app/shared/models/schedule-collection';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DEFAULT_START_PAGE, DEFAULT_PAGE_SIZE } from '../constant';
import {
    CollectionDetails,
    CollectionHistory,
    GetCollectDetailsArgs,
    GetCollectHistoryArgs,
} from '../models/collection-history';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ScheduleCollectionService {
    public addDialogIsOpened = false;
    public collectionHistory: Array<CollectionHistory> = [];

    constructor(public http: HttpClient) {}

    public getFailedCollectsCount(customerId: number, locationGroupId?: number) {
        // creating HttpParams for HttpClient request
        let httpParams: HttpParams = new HttpParams();
        // setting all the existing key value of param to HttpParams
        Object.keys(customerId).forEach(function (key) {
            httpParams = httpParams.append(key, customerId[key]);
        });

        if (locationGroupId) {
            httpParams.append['locationGroupId'] = locationGroupId;
        }

        return this.http.get(Config.urls.collectsFailedCount, { params: httpParams }).pipe(
            map((res) => {
                if (res) {
                    return res['failedcollections'];
                } else {
                    return null;
                }
            }),
        );
    }

    public getUpcomingCollection(customerId: number) {
        // creating HttpParams for HttpClient request
        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.append('customerId', customerId.toString());

        return this.http.get(Config.urls.collectsUpcoming, { params: httpParams }).pipe(
            map((res) => {
                if (res) {
                    return res['collectionstart'];
                } else {
                    return null;
                }
            }),
        );
    }

    public getFailedAndUpcomingCollection(customerId: number) {
        return this.http.get(Config.serviceUrl + Config.urls.collectionCountForCustomer + `${customerId}`).pipe(
            map((res) => {
                return res;
            }),
        );
    }
    public getScheduleCollection(customerId: number): Observable<any> {
        return this.http.get<any>(Config.serviceUrl + Config.urls.collectSchedules + `${customerId}`).pipe(
            map((response) => {
                if (response) {
                    return <ScheduleCollection>response.schedules;
                } else {
                    EMPTY;
                }
            }),
        );
    }
    public getLiteScheduledCollections(customerId) {
        return this.http.get<any>(Config.serviceUrl + Config.urls.liteScheduledCollections + `${customerId}`);
    }

    public getAvailableLocations(customerId: number, scheduleId?: number) {
        let uri: string;
        if (scheduleId) {
            uri = `${Config.urls.availableLocations}?cid=${customerId}` + `&scheduleId=${scheduleId}`;
        } else {
            uri = `${Config.urls.availableLocations}?cid=${customerId}`;
        }
        const result = this.http.get<any>(uri);
        return result;
    }

    public getSelectedScheduleCollection(scheduleId) {
        return this.http.get<any>(Config.serviceUrl + Config.urls.scheduledCollections + `${scheduleId}`);
    }
    public getCollectionHistory(params: GetCollectHistoryArgs) {
        // Converting Dates into string
        let startDate = null;
        let endDate = null;
        if (params.StartTime) {
            startDate = [
                params.StartTime.getMonth() + 1,
                params.StartTime.getDate(),
                params.StartTime.getFullYear(),
            ].join('-');
            params.StartTime = startDate;
        }
        if (params.EndTime) {
            endDate = [params.EndTime.getMonth() + 1, params.EndTime.getDate(), params.EndTime.getFullYear()].join('-');
            endDate = endDate + ' 23:59:59';
            params.EndTime = endDate;
        }
        // creating HttpParams for HttpClient request
        let httpParams: HttpParams = new HttpParams();

        // setting all the existing key value of param to HttpParams
        Object.keys(params).forEach(function (key) {
            if (key !== 'LocationGroupID' && params[key] != null) {
                httpParams = httpParams.append(key, params[key]);
            }
        });

        if (params.LocationGroupID > 0) {
            httpParams = httpParams.append('LocationGroupID', params.LocationGroupID.toString());
        }

        if (params.LocationIDs) {
            httpParams = httpParams.append('LocationIDs', params.LocationIDs.toString());
        }

        // add fixed param: includeTcpConnections=true
        httpParams = httpParams.append('includeTcpConnections', 'true');

        return this.http.get<Array<CollectionHistory>>(Config.urls.collectsHistory, { params: httpParams }).pipe(
            map((response) => {
                return response;
            }),
        );
    }
    public getCollectionDetails(startPage, pageSize, params: GetCollectDetailsArgs) {
        let startDate = null;
        let endDate = null;
        params.StartPage = startPage || DEFAULT_START_PAGE;
        params.PageSize = pageSize || DEFAULT_PAGE_SIZE;
        if (params.StartTime) {
            const m = params.StartTime.getMonth() + 1 > 9 ? params.StartTime.getMonth() + 1 : '0' + (params.StartTime.getMonth() + 1);
            const d = params.StartTime.getDate() > 9 ? params.StartTime.getDate() : '0' + params.StartTime.getDate();
            startDate = [params.StartTime.getFullYear(), m, d].join('-');
            startDate = startDate + 'T00:00:00';
            params.StartTime = startDate;
        }
        if (params.EndTime) {
            const m = params.EndTime.getMonth() + 1 > 9 ? params.EndTime.getMonth() + 1 : '0' + (params.EndTime.getMonth() + 1);
            const d = params.EndTime.getDate() > 9 ? params.EndTime.getDate() : '0' + params.EndTime.getDate();
            endDate = [params.EndTime.getFullYear(), m, d].join('-');
            endDate = endDate + 'T23:59:59';
            params.EndTime = endDate;
        }
        // creating HttpParams for HttpClient request
        let httpParams: HttpParams = new HttpParams();
        // setting all the existing key value of param to HttpParams
        Object.keys(params).forEach(function (key) {
            if (key !== 'LocationGroupID' && params[key] != null) {
                httpParams = httpParams.append(key, params[key]);
            }
        });
        if (params.LocationGroupID > 0) {
            httpParams = httpParams.append('LocationGroupID', params.LocationGroupID.toString());
        }
        if (params.LocationIDs) {
            httpParams = httpParams.append('LocationIDs', params.LocationIDs.toString());
        }
        if (params.monitorIds) {
            httpParams = httpParams.append('MonitorIds', params.monitorIds.toString());
        }
        return this.http.get<Array<CollectionDetails>>(Config.urls.collectsDetails, { params: httpParams }).pipe(
            map((response) => {
                return response;
            }),
        );
    }

    public postScheduleCollection(customerId: number, scheduleCollection: ScheduleCollection) {
        const params = <ScheduleCollection>{
            alarmingFrequency: scheduleCollection.alarmingFrequency,
            daysToCollect: scheduleCollection.collectionDays,
            frequency: scheduleCollection.frequency,
            locations: scheduleCollection.locations,
            name: scheduleCollection.name,
            collectTime: scheduleCollection.collectTime,
        };

        // below is api url for add collection schedule
        const res = this.http.post(Config.serviceUrl + Config.urls.collectSchedules + `${customerId}`, params);

        res.pipe(
            catchError((error) => {
                console.dir(error.json());
                return res;
            }),
        );
        return res;
    }

    public updateScheduleCollection(
        scheduleId: number,
        locations: Array<number>,
        scheduleName: string,
        frequency: number,
        alarmingFrequency: number,
        collectionDays: number[],
        collectTime: string,
    ) {
        const url = `api/collections/schedules/${scheduleId}`;

        const params: ScheduleCollection = new ScheduleCollection();
        params.name = scheduleName;
        params.frequency = frequency;
        params.locations = locations;
        params.daysToCollect = collectionDays;
        params.collectTime = collectTime;
        params.alarmingFrequency = alarmingFrequency || undefined;

        const res = this.http.put(`${Config.serviceUrl}${url}`, params).pipe(
            catchError((error) => {
                console.dir(error.json());
                return res;
            }),
        );

        return res;
    }

    public deleteScheduleCollection(scheduleId: number): Observable<any> {
        return this.http
            .delete(Config.serviceUrl + Config.urls.scheduledCollections + `${scheduleId}`)
            .pipe(map((response) => <ScheduleCollection>response));
    }

    public getScheduleByLocationId(customerId, locationId): Observable<ScheduleCollection> {
        return this.http
            .get(Config.serviceUrl + Config.urls.scheduleByLocationId + `${customerId}` + `&locationId=${locationId}`)
            .pipe(map((response) => <ScheduleCollection>response));
    }
}
