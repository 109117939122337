<div>
    <app-view-data-filter
        class="hide"
        #dataFilter
        [editingDisabled]="true"
        (notifyViewData)="notifyGraph($event)"
        (locationChanged)="locationChangeHandler($event)"
        (scatterConfirmationData)="onScatterConfirmationDataChange($event)"
        [defaultFilterSettings]="filterSettings"
        [locationGroupId]="locationGroupID"
        [includeInactiveLocations]="false"
        [noDataForScatterChart]="noDataForScatterChart"
        [noDataForHydrographChart]="noDataForHydrographChart"
        [entityData]="locationEntityData"
        [showScatterGraph]="showScatterGraph"
        [isDataEditingEnabled]="false"
        (hideScattergraph)="hideScattergraphs()"
        (scatterEntityBlur)="scatterEntityBlur()"
        (scatterEntitiesChange)="onScatterEntitiesChange($event)"
        [isUSGS]="isUSGS$"
    >
    </app-view-data-filter>
    <div
        class="hydrograph-container"
        (mouseleave)="mouseOutFromHydro()"
    >
        <app-loader [isLoading]="hydroGraphLoading$ | async"></app-loader>

        <div
            class="dont-print no-data-found-section"
            *ngIf="!(!noDataForHydrographChart || (hydroGraphLoading$ | async))"
        >
            <div class="notabledataFound custom-table print-nodata-space">
                <p class="no-data-found-img"></p>
                <p>{{ 'COMMON.NO_DATA_IN_GRAPH_MSG' | translate }}</p>
            </div>
        </div>

        <app-hydrograph-tracer-panel
            *ngIf="(dataEditService.newHydroData$ | async)"
            (zoomButtonClicked)="lcChart.zoomButtonClicked($event)"
            (tracerButtonClicked)="lcChart.tracerButtonClicked($event)"
        ></app-hydrograph-tracer-panel>
        <div [id]="chartId" class="lc-chart" [hidden]="!(dataEditService.newHydroData$ | async)"></div>

        <app-hydrograph-legend
            [legendSeriesDefinition]="lcChart?.legendSeriesDefinition"
            (serieVisibility)="lcChart?.updateSeriesVisibility($event)"
        ></app-hydrograph-legend>
        <div
            class="missingParameters"
            [ngClass]="{ 'dont-print': !missingRain && !missingLevel && !noDataForHydrographChart }"
            [hidden]="!missingRain && !missingLevel && !noDataForHydrographChart"
        >
            <span *ngIf="missingParameters !== ''">No data available for the following parameters: {{ missingParameters }}</span>
            <span *ngIf="(dataEditService.newHydroData$ | async) && missingRain">{{
                'COMMON.NO_RAIN_DATA_IN_GRAPH_MSG' | translate
                }}</span>
            <span *ngIf="(dataEditService.newHydroData$ | async) && missingLevel">{{
                'COMMON.NO_LEVEL_DATA_IN_GRAPH_MSG' | translate: unit
                }}</span>
        </div>
    </div>
    <div
        class="scattergraphGraphDetailsContainer"
        [ngClass]="{ hide: hideScatterGraph }"
    >
        <app-advance-scattergraph-container
            #advanceScatterGraph
            [isLoading]="scattergraphLoading"
            [scatterGraphConfirmationData]="scatterGraphConfirmationData"
            [locationId]="locationId"
            [data]="scatterGraphData"
            [showCustomRangesForScattergraph]="false"
            [scatterDateFormat]="scatterDateFormat"
            [tooltipTimeFormat]="scatterTimeFormat"
            [selectedEntityIds]="selectedSGEntityIds"
            [isDataEditingModeEnabled]="false"
            [hideMenu]="true"
            (scatterResponse)="onScatterResponse($event)"
        >
        </app-advance-scattergraph-container>
    </div>
    <hr />
    <div class="pipeDiagram">
        <div class="pipeTitle">
            <span>
                {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.AVERAGE_PERCENT_FULL' | translate }}
            </span>
        </div>
        <div
            *ngIf="!noPipeVolumeData"
            class="percentFull"
        >{{pipeVolumePercent}}%</div>
        <div *ngIf="pipeDetails">
            <div class="pipeDateRange">
                <app-date-range-picker
                    [(startDate)]="pipeStartDate"
                    [(endDate)]="pipeEndDate"
                    [maxDate]="pipeMaxDate"
                    dateRangeType="date"
                    [(tickInterval)]="pipeTickInterval"
                    (emitClose)="pipeDateRangeClosed()"
                    (emitOpen)="pipeDateRangeOpened()"
                    (emitDateChanged)="pipeUpdateDateChange()"
                ></app-date-range-picker>
            </div>
        </div>
        <div
            *ngIf="!pipeDetails"
            class="nopipe"
        >
            {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.NO_PIPE_DATA' | translate }}
        </div>
        <canvas #pipeCanvas></canvas>
    </div>
</div>
