import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
    ViewChild,
    ViewChildren,
    QueryList,
    ChangeDetectorRef,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { MatLegacyListOption as MatListOption } from '@angular/material/legacy-list';
import { Observable } from 'rxjs';

import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { Selectable } from 'app/shared/models/selectable';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import _ from 'lodash';

@Component({
    selector: 'app-multi-select-auto-complete',
    templateUrl: './multi-select-auto-complete.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class MultiSelectAutoCompleteComponent implements OnInit, OnChanges {
    /**
     * Represents the collection of entities.
     */
    @Input() public items: Array<Selectable>;

    /**
     * Defines the placeholder string value for the control.
     */
    @Input() public placeholder: string;

    /**
     * Defines if input is disabled.
     */
    @Input() public disabled: boolean;

    /**
     * Represents original placholder string value
     */
    public initialPlaceholder: string;

    /**
     * Indicates whether to show the "All" feature. Enabled by default.
     */
    @Input() public isShowAll = false;

    /**
     * Represents the minimu number that can be selected.
     */
    @Input() public minDisplayLimit = 0;

    /**
     * Represents the maximum numbers that can be selected.
     */
    @Input() public maxDisplayLimit: number;

    /**
     * Preselected items
     */
    @Input() public preselectedItems: Array<Selectable>;

    /**
     * Represents the collection of entities.
     */
     @Input() public dclass = '';

    /**
     * Emits the currently selected items.
     */
    @Output() public currentlySelectedItems = new EventEmitter<Array<Selectable>>();

    /**
     * Represents the colleciton of material check boxes
     */

    @ViewChildren(MatListOption) private options: QueryList<MatListOption>;

    /**
     * Reprensets an instance of the input search field.
     */
    @ViewChild(MatInput) public searchInput: MatInput;

    /**
     * Represents an observable of filtered selectable items.
     */
    public filteredItems: Observable<Array<Selectable>>;

    /**
     * Represents a value indicating whether the selection list is visible.
     */
    public isResultsVisible = false;

    /**
     * Represents teh search term control.
     */
    public searchTermCtrl = new FormControl();

    /**
     * Reresents a random number value that will be added to the data-id of the
     * element and will serve as a poor mans unique identifier.
     */
    public surrogateIdentifier = Math.ceil(Math.random() * Number.MAX_VALUE);

    /**
     * Represents a value indicating if an option was changed.
     */
    public isOptionsTouched = false;

    /**
     * Represents a value indicating if all the options option was selected.
     */
    public isAllSelected = false;

    /**
     * Indicates a state of validity for the input.
     */
    public isValid = true;

    /**
     * Represents a value indicating whether the selection list has focus.
     */
    private isSelectionListFocused = false;

    /**
     * Represents a value indicating whether the display limit has been reached.
     */
    private get isUpperLimitReached(): boolean {
        return this.items.filter((selectable) => selectable.isChecked).length >= this.maxDisplayLimit;
    }
    public inputPlaceholderText = '';
    public initPlaceholderText = '';

    constructor(
        private element: ElementRef,
        private domOperationUtilsService: DomOperationUtilsService,
        public cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
    ) {}

    /**
     * Framework level hook.
     */
    public ngOnInit(): void {
        this.initialPlaceholder = this.placeholder;

        this.filteredItems = this.searchTermCtrl.valueChanges
            .pipe(debounceTime(300))
            .pipe(startWith(''))
            .pipe(map((term: string) => (term ? this.filterItems(term) : this.items)))
            .pipe(map((r) => r.sort((a,b) => a.name > b.name ? 1 : -1)))
            .pipe(tap(() => this.uiUtilsService.safeChangeDetection(this.cdr)));

        if (this.preselectedItems && this.preselectedItems.length > 0) {
            for (const item of this.preselectedItems) {
                const foundItem = this.items.find((selectable: Selectable) => selectable.id === item.id);
                if (foundItem) {
                    foundItem.isChecked = true;
                }
            }
            const preselectedItemsList = this.items.filter((selectable: Selectable) => selectable.isChecked).sort();
            this.initPlaceholderText = preselectedItemsList.map((itm) => itm.name).join(', ');
        } else {
            this.initPlaceholderText =
                this.initialPlaceholder.toUpperCase().indexOf('ENTITY') > -1
                    ? 'UNIDEPTH, VELOCITY, QCONTINUITY, RAIN'
                    : '';
        }
        // set placeholder
        this.setSelectedPlaceholderValue();
    }

    public updateOnSelectedChange() {
        if (this.preselectedItems && this.preselectedItems.length > 0) {
            for (const item of this.preselectedItems) {
                const foundItem = this.items.find((selectable: Selectable) => selectable.id === item.id);
                if (foundItem) {
                    foundItem.isChecked = true;
                }
            }
            const preselectedItemsList = this.items.filter((selectable: Selectable) => selectable.isChecked).sort();
            this.initPlaceholderText = preselectedItemsList.map((itm) => itm.name).join(', ');
        }

        this.setSelectedPlaceholderValue();
    }

    public clearSelected() {
        this.inputPlaceholderText = '';
        this.items.forEach(v => v.isChecked = false);
        this.setSelectedPlaceholderValue();
        this.searchTermCtrl.setValue('');
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.disabled) {
            changes.disabled.currentValue ? this.searchTermCtrl.disable() : this.searchTermCtrl.enable();
        }
        if (changes && changes.items && changes.items.currentValue && changes.items.previousValue) {
            if(!_.isEqual(changes.items.currentValue, changes.items.previousValue)) {
                this.clearSelected();
                this.updateOnSelectedChange();
            }
        }
        if (Object.keys(changes).length === 1 && Object.keys(changes)[0] === 'preselectedItems') {
            if (this.preselectedItems && this.preselectedItems.length > 0) {
                for (const item of this.preselectedItems) {
                    const foundItem = this.items.find((selectable: Selectable) => selectable.id === item.id);
                    if (foundItem) {
                        foundItem.isChecked = true;
                    }
                }
                const preselectedItemsList = this.items.filter((selectable: Selectable) => selectable.isChecked).sort();
                this.initPlaceholderText = preselectedItemsList.map((itm) => itm.name).join(', ');
                this.setSelectedPlaceholderValue();
            }
        }
    }
    /**
     * Updates the current placeholder value to the first element selected.
     * This is to ensure that the user sees that some kind of selection has been made.
     */
    private setSelectedPlaceholderValue(): void {
        if (this.items && this.items.length === 0) {
            this.placeholder =
                this.maxDisplayLimit === 10000
                    ? `${this.initialPlaceholder} : 1 selected`
                    : `${this.initialPlaceholder} (${this.minDisplayLimit} to ${this.maxDisplayLimit}): 4 selected`;
            return;
        }

        if (this.items) {
            // get just the items that are checked
            // const checkedItems = this.items.filter((selectable: Selectable) => selectable.isChecked);
            const checkedItems = this.items.filter((selectable: Selectable) => selectable.isChecked).sort();
            let placeholderText = '';
            if (checkedItems.length > 0) {
                // placeholderText = checkedItems[0].name + '...';
                placeholderText = checkedItems.map((itm) => itm.name).join(', ');
            }

            if (!this.minDisplayLimit && !this.maxDisplayLimit) {
                // set place holder
                this.placeholder = `${this.initialPlaceholder}: ${checkedItems.length} selected`;

                return;
            }
            this.inputPlaceholderText = placeholderText;
            // set place holder
            //  this.placeholder = `${this.initialPlaceholder} (${this.minDisplayLimit} to ${this.maxDisplayLimit}): ${checkedItems.length} selected`;
            this.placeholder =
                this.maxDisplayLimit === 10000
                    ? `${this.initialPlaceholder} : ${checkedItems.length} selected`
                    : `${this.initialPlaceholder} (${this.minDisplayLimit} to ${this.maxDisplayLimit}): ${checkedItems.length} selected`;
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * Used to filter the location names for the auto-complete.
     * @param searchName Represents the searched for location name.
     */
    public filterItems(searchName: string): Array<Selectable> {
        // ensure args
        if (!searchName) {
            return this.items;
        }

        return this.items.filter((a) => a.name.toLowerCase().includes(searchName.toLowerCase()));
    }

    /**
     * Updates selected status of items.
     * @param event Represents the mouse event that triggered the listener.
     */
    public onSelectionChange(event: MouseEvent): void {
        // get the data attribute of the clicked on target
        // workaround due to Angular Material select list bug:
        // https://github.com/angular/material2/pull/6901
        const itemDataset = (<HTMLElement>event.currentTarget).dataset;

        // if select all is specified, mark all as checked
        if (itemDataset.selectall) {
            // mark option as touched
            this.isOptionsTouched = true;

            // mark all selected as the reverse of what it was prior
            this.isAllSelected = !this.isAllSelected;

            // mark all items as selected based on the all selected status
            this.items.forEach((selectable: Selectable) => (selectable.isChecked = this.isAllSelected));

            // mark all options as selected based on the all selected status
            this.options.forEach((option: MatListOption) => (option.selected = this.isAllSelected));

            // set placeholder
            this.setSelectedPlaceholderValue();

            // exit immeidately
            return;
        }

        const itemId = Number(itemDataset.item);

        // ensure id
        if (itemId !== 0 && !itemId) {
            return;
        }

        this.isOptionsTouched = true;

        // tslint:disable-next-line:no-any - HACK: must allow any to access JavaScript property that TypeScript hides
        const selectedOption = this.options.find(
            (option: any) => Number(option._element.nativeElement.dataset.id) === itemId,
        );

        // find associated data item
        const foundItem = this.items.find((selectable: Selectable) => selectable.id === itemId);

        // set checked
        foundItem.isChecked = selectedOption.selected;

        // set if upper limit is reached
        const isUpperLimitReached = this.isUpperLimitReached;

        // set validation state based on either lower or upper limit being reached
        this.isValid =
            !isUpperLimitReached &&
            !(this.items.filter((selectable) => selectable.isChecked).length < this.minDisplayLimit);

        // ensure that item can be selected
        if (isUpperLimitReached) {
            if (selectedOption.selected) {
                // deselect item
                selectedOption.selected = false;

                // set placeholder
                this.setSelectedPlaceholderValue();
            }

            // set the entity list scroll to top to display the entity limit validation error message
            this.domOperationUtilsService.scrollToTop('#entityLimit');

            // exit immediately
            return;
        }

        // set placeholder
        this.setSelectedPlaceholderValue();
    }

    /**
     * Determines whether to disable an option.
     * @param selectable Represents the selctable item.
     */
    public isOptionDisabled(selectable: Selectable): boolean {
        // ensure display limit is set
        if (this.maxDisplayLimit < 1) {
            return;
        }

        // get index of selected item
        const isChecked = this.items.find((item) => item.id === selectable.id).isChecked;

        // if item is selected exit immediatly to allow it to be enabled
        if (isChecked) {
            return false;
        }

        // item is not already selected, check to see if limit is reached
        return this.isUpperLimitReached;
    }

    /**
     * Handles focus event on input search field.
     * @param event Reprsents the focus event.
     */
    public onInputFocusIn(event: FocusEvent) {
        // get target element
        const inputEl = <HTMLInputElement>event.target;

        // set vvalue to nothing
        inputEl.value = '';

        // enable selection menu
        this.isResultsVisible = true;
    }

    /**
     * Handles focusout event on input search field.
     * @param event Reprsents the focus event.
     */
    public onInputFocusOut(event: FocusEvent) {
        // if list visible but is not focused
        if (
            this.isSameElementClicked(<HTMLElement>event.relatedTarget) &&
            (this.isResultsVisible || this.isSelectionListFocused)
        ) {
            return;
        }

        // get target element
        const inputEl = <HTMLInputElement>event.target;

        // set vvalue to nothing
        inputEl.value = '';

        if (this.isOptionsTouched) {
            // emit items
            this.currentlySelectedItems.emit(this.items);
        }

        // hide results visible
        this.isOptionsTouched = this.isResultsVisible = false;
        if (!this.isResultsVisible) {
            inputEl.value = this.inputPlaceholderText === '' ? this.selectedEntityNames() : this.inputPlaceholderText;
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public selectedEntityNames() {
        if (this.items && this.items.length === 0) {
            return this.initPlaceholderText;
        }

        if (this.items) {
            // get just the items that are checked
            const checkedItems = this.items.filter((selectable: Selectable) => selectable.isChecked).sort();
            let placeholderText = '';
            if (checkedItems.length > 0) {
                // placeholderText = checkedItems[0].name + '...';
                placeholderText = checkedItems.map((itm) => itm.name).join(', ');
            }
            return placeholderText;
        }
    }

    /**
     * Handles focus event on selection drop down.
     * @param event Reprsents the focus event.
     */
    public onSelectionListFocused(event: FocusEvent): void {
        this.isSelectionListFocused = true;
    }

    /**
     * Handles focusout event on selection drop down.
     * @param event Reprsents the focus event.
     */
    public onSelectionListNotFocused(event: FocusEvent): void {
        // if list visible but is not focused
        if (
            this.isSameElementClicked(<HTMLElement>event.relatedTarget) &&
            (this.isResultsVisible || this.isSelectionListFocused)
        ) {
            return;
        }

        // tslint:disable-next-line:no-any
        if (event.relatedTarget && (<any>event.relatedTarget).localName === 'mat-list-option') {
            return;
        }

        // if a sibling element (i.e, input) is not the focus than click outside occured and so notify
        if (!this.isSameElementClicked(<HTMLElement>event.relatedTarget) && this.isOptionsTouched) {
            // emit items
            this.currentlySelectedItems.emit(this.items);
        }

        // clear search input
        this.searchInput.value = '';

        // reset state
        this.isResultsVisible = this.isOptionsTouched = this.isSelectionListFocused = false;
        if (!this.isResultsVisible) {
            this.searchInput.value =
                this.inputPlaceholderText === '' ? this.selectedEntityNames() : this.inputPlaceholderText;
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * Attempts to capture the passed in elements identifier and compare it to this one.
     * Returns true if the this elements and the passed in elements identifiers are equal;
     * otherwise false.
     * @param element Represents the element to be used for comparion.
     */
    private isSameElementClicked(element: HTMLElement): boolean {
        // ensure args
        if (!element) {
            return;
        }

        // ensure property
        if (!element.dataset.hasOwnProperty('identifier')) {
            return;
        }

        // determine if items are the same
        const result = Number(element.dataset.identifier) === this.surrogateIdentifier;

        // return result
        return result;
    }
}
