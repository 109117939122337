import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UpdatesWidgetService } from '../../pages/sliicer/shared/components/updates-widget/updates-widget.service';
import { SliicerCaseStudyDetailsComponent } from '../../pages/sliicer/sliicer-case-study-details/case-study-details.component'
import { ConfirmationButton } from '../components/confirmation-dialog/confirmation-dialog.component';
@Injectable()

export class SliicerDeactivateGuard  {
    private isDialogOpened = false;
    constructor(private updatesWidgetService: UpdatesWidgetService) { }

    canDeactivate(component: SliicerCaseStudyDetailsComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot | undefined): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.isDialogOpened) return false;

        if (this.updatesWidgetService.warningDialogContainErrors()) {
            this.isDialogOpened = true;
            return this.updatesWidgetService.warningDialogOpen().pipe(
                tap(() => this.isDialogOpened = false),
                map((result: { whichButtonWasPressed: ConfirmationButton }) => result.whichButtonWasPressed === ConfirmationButton.ok)
            )
        }
        return true;
    }
}
