<div id="vaultContainerPage">
    <app-loader [isLoading]="isLoading || vaultService.isVaultLoading"></app-loader>

    <div
        class="vault"
        [ngClass]="{ 'hide-folder-nav': navClosed }"

        (mousemove)="resizeTick($event)"
        (mouseup)="resizeEnd($event)"
    >
        <div class="vault-toggle-sidenav flex-container"
        >
            <div
                class="folders-text vault-folders-hint"
                [hidden]="navClosed"
            >{{ 'COMMON.FOLDERS_TEXT' | translate }}</div>

            <button
                mat-button
                (click)="vaultSidenavToggle()"
            >
                <mat-icon
                    class="backIcon vault-hide-folders-hint"
                    matTooltip="{{ 'COMMON.COLLAPSE' | translate }}"
                    *ngIf="!navClosed"
                >
                    navigate_before</mat-icon>
                <mat-icon
                    class="backIcon"
                    matTooltip="{{ 'COMMON.EXPAND' | translate }}"
                    *ngIf="navClosed"
                >navigate_next
                </mat-icon>
            </button>
        </div>
        <div
            #resizable
            class="file-tree-container"
            [hidden]="navClosed"
            [directions]="['right']" [rFlex]="true"
            (scroll)="onDrawerScroll($event)"

        >
            <div class="file-tree">
                <ads-prism-vault-tree
                    *ngIf="!isLoading && !vaultService.isVaultLoading"
                    [vault]="vaultData"
                    (sendIdSelected)="selectEachDirectory($event)"
                >
                </ads-prism-vault-tree>
            </div>
        </div>
        <div class="grabber" (mousedown)="resizeStart($event)"><p>||</div>
        <div
            class="vault-list"
            #vaultList
        >
            <div
                class="vault-file-upload-container margin-20"
                *ngIf="showLocationDetails"
            >
                <div class="contentLayout vault-file-container">
                    <div class="vault-header">
                        <div class="breadcrumb">{{ 'VAULT.VAULT_DASHBOARD.TITLE' | translate }}</div>
                        <div class="app-flex-filler"></div>
                        <div class="auto-margin">
                            <div class="seriesType">
                                <mat-form-field
                                    class="full-width auto-file-select"
                                    floatLabel="never"
                                >
                                    <mat-select
                                        placeholder="{{ 'COMMON.SEARCH_TEXT' | translate }}"
                                        name="MonitorSeries"
                                        class="full-width"
                                        [(ngModel)]="fileUploadMonitorSeries"
                                        (ngModelChange)="onChangeMonitorSeries($event)"
                                    >
                                        <mat-option [value]="'All'">{{ 'COMMON.ALL_TEXT' | translate }}</mat-option>
                                        <mat-option
                                            *ngFor="let series of monitorSeriesUI"
                                            [value]="series.value"
                                        >
                                            {{ series.text }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <form #locationUploadForm="ngForm">
                    <mat-accordion class="vault-file-upload-containt">
                        <mat-expansion-panel
                            *ngFor="let panel of uploadedLocations; let i = index"
                            (opened)="hideLocationName = false"
                            (closed)="hideLocationName = true"
                        >
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon
                                        *ngIf="panel.validation !== 'Successful' || panel.isExistingLocation"
                                        class="danger"
                                    >error</mat-icon>
                                    <mat-icon
                                        *ngIf="panel.validation === 'Successful' && !panel.isExistingLocation"
                                        class="success"
                                    >check_circle</mat-icon>
                                    <span>{{ panel.fileName }}</span>
                                    <span
                                        *ngIf="hideLocationName && panel.locationName"
                                        class="light"
                                    >
                                        {{ 'COMMON.LOCATION_NAME' | translate }}:</span>
                                    <span *ngIf="hideLocationName">{{ panel.locationName }}</span>
                                    <span
                                        class="error"
                                        *ngIf="panel.isExistingLocation"
                                    >{{ 'COMMON.LOCATION_NAME' | translate }}
                                        {{ 'COMMON.EXISTS_ALREADY' | translate }}</span>
                                    <span
                                        class="error"
                                        *ngIf="panel.validation !== 'Successful'"
                                    >{{
                                        panel.validation
                                        }}</span>
                                </mat-panel-title>
                                <mat-panel-description *ngIf="hideLocationName">
                                    <div
                                        class="locationUploadSection"
                                        *ngIf="hideLocationName"
                                    >
                                        <span>{{ 'COMMON.REJECT_TEXT' | translate }}</span>
                                        <span *ngIf="panel.isExistingLocation">{{
                                            'COMMON.OVERWRITE_TEXT' | translate
                                            }}</span>
                                    </div>
                                </mat-panel-description>
                            </mat-expansion-panel-header>

                            <div class="custom-50p">
                                <div class="each-Item">
                                    <mat-form-field class="full-width">
                                        <input
                                            matInput
                                            placeholder="{{ 'COMMON.LOCATION_NAME' | translate }}"
                                            [(ngModel)]="panel.locationName"
                                            name="locationName{{ i }}"
                                            readonly
                                        />
                                    </mat-form-field>
                                </div>
                                <div class="each-Item">
                                    <mat-form-field class="full-width">
                                        <input
                                            matInput
                                            placeholder="{{ 'COMMON.SERIAL_NUMBER' | translate }}"
                                            [(ngModel)]="panel.serialNumber"
                                            name="serialNumber{{ i }}"
                                            readonly
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="custom-50p">
                                <div class="each-Item">
                                    <mat-form-field class="full-width">
                                        <input
                                            matInput
                                            placeholder="{{ 'COMMON.IP_ADDRESS_PLACEHOLDER' | translate }}"
                                            [(ngModel)]="panel.ipAddress"
                                            name="ipAddress{{ i }}"
                                            readonly
                                        />
                                    </mat-form-field>
                                </div>
                                <div class="each-Item">
                                    <mat-form-field class="full-width">
                                        <input
                                            matInput
                                            placeholder="{{ 'COMMON.MONITOR_SERIES_PLACEHOLDER' | translate }}"
                                            [(ngModel)]="panel.monitorSeries"
                                            name="monitorSeries{{ i }}"
                                            readonly
                                        />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="custom-50p custom-error">
                                <div class="each-Item">
                                    <mat-form-field class="full-width">
                                        <input
                                            matInput
                                            placeholder="{{ 'COMMON.LATITUDE_LABEL' | translate }}"
                                            matTooltip="{{ 'COMMON.LATITUDE_TOOLTIP' | translate }}"
                                            id="addLocationLatitudeInput"
                                            name="Latitude{{ i }}"
                                            #addLocationLatitudeInput="ngModel"
                                            [pattern]="latitudePattern"
                                            [(ngModel)]="panel.latitude"
                                        />
                                    </mat-form-field>
                                </div>
                                <div class="each-Item">
                                    <mat-form-field class="full-width">
                                        <input
                                            matTooltip="{{ 'COMMON.LONGITUDE_TOOLTIP' | translate }}"
                                            matInput
                                            placeholder="{{ 'COMMON.LONGITUDE_LABEL' | translate }}"
                                            [(ngModel)]="panel.longitude"
                                            [pattern]="longitudePattern"
                                            id="addLocationLongitudeInput"
                                            name="Longitude{{ i }}"
                                            #addLocationLongitudeInput="ngModel"
                                        />
                                    </mat-form-field>
                                </div>
                                <div
                                    class="custom-error-message"
                                    *ngIf="
                                        addLocationLatitudeInput?.errors?.pattern ||
                                        addLocationLongitudeInput?.errors?.pattern
                                    "
                                >
                                    {{ 'COMMON.LONGITUDE_ERROR' | translate }}
                                </div>
                            </div>
                            <div class="custom-50p">
                                <div class="each-Item">
                                    <mat-form-field class="full-width">
                                        <mat-select placeholder="{{ 'VAULT.VAULT_DASHBOARD.DEVICES_TEXT' | translate }}">
                                            <mat-option
                                                *ngFor="let device of panel.devices"
                                                [value]="device"
                                                disabled
                                            >
                                                {{ device }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="each-Item">
                                    <mat-form-field class="full-width">
                                        <input
                                            matInput
                                            placeholder="{{ 'COMMON.RAIN_GAUAGE' | translate }}"
                                            aria-label="Assigned Rain Gauge"
                                            maxlength="50"
                                            [matAutocomplete]="auto"
                                            [(ngModel)]="panel.assignedRainGaugeName"
                                            name="assignedRainGaugeName{{ i }}"
                                            [formControl]="assignedRainGaugeCtrl"
                                        />
                                        <mat-autocomplete #auto="matAutocomplete">
                                            <mat-option
                                                *ngFor="let rainGauge of filteredassignedRainGauge"
                                                [value]="rainGauge.locationName"
                                            >
                                                <span>{{ rainGauge.locationName }}</span>
                                            </mat-option>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="custom-50p">
                                <div class="each-Item custom-error">
                                    <mat-form-field class="full-width">
                                        <input
                                            matInput
                                            placeholder="{{ 'COMMON.LOCATION_DESCRIPTION' | translate }}"
                                            id="addLocationDescriptionInput"
                                            #addLocationDescriptionInput="ngModel"
                                            [pattern]="alphaNumericPattern"
                                            name="Description{{ i }}"
                                            maxlength="256"
                                            [(ngModel)]="panel.locationDescription"
                                        />
                                    </mat-form-field>
                                    <div
                                        class="custom-error-message"
                                        *ngIf="addLocationDescriptionInput?.errors?.pattern"
                                    >
                                        {{ 'COMMON.ADDRESS_ERROR' | translate }}
                                    </div>
                                </div>
                                <div class="each-Item custom-error">
                                    <mat-form-field class="full-width">
                                        <input
                                            matInput
                                            id="addLocationManholeDepthInput"
                                            placeholder="{{ manholeTextWithUnit }}"
                                            name="manholedepth{{ i }}"
                                            #addLocationManholeDepthInput="ngModel"
                                            [(ngModel)]="panel.manholeDepth"
                                            maxlength="50"
                                            [pattern]="installationValuePattern"
                                        />
                                    </mat-form-field>
                                    <div
                                        class="custom-error-message"
                                        *ngIf="addLocationManholeDepthInput?.errors?.pattern"
                                    >
                                        {{ 'COMMON.NUMERIC_PRECISION_ERROR' | translate }}
                                    </div>
                                </div>
                            </div>

                            <div class="custom-50p">
                                <div class="each-Item custom-error">
                                    <mat-form-field class="full-width">
                                        <input
                                            matInput
                                            placeholder="{{ 'COMMON.MANHOLE_ADDRESS_TEXT' | translate }}"
                                            id="addLocationManholeAddressInput"
                                            #addLocationManholeAddressInput="ngModel"
                                            [pattern]="alphaNumericPattern"
                                            name="ManholeAddress{{ i }}"
                                            [(ngModel)]="panel.manholeAddress"
                                        />
                                    </mat-form-field>
                                    <div
                                        class="custom-error-message"
                                        *ngIf="addLocationManholeAddressInput?.errors?.pattern"
                                    >
                                        {{ 'COMMON.ADDRESS_ERROR' | translate }}
                                    </div>
                                </div>
                            </div>
                            <mat-action-row>
                                <button
                                    mat-button
                                    (click)="removeLocation(i)"
                                >
                                    {{ 'COMMON.REJECT_TEXT' | translate }}
                                </button>
                                <button
                                    *ngIf="panel.isExistingLocation"
                                    mat-button
                                    (click)="overWriteLocation(panel)"
                                >
                                    {{ 'COMMON.OVERWRITE_TEXT' | translate }}
                                </button>
                            </mat-action-row>
                        </mat-expansion-panel>
                    </mat-accordion>
                </form>
                <div class="contentLayout vault-file-upload-footer">
                    <button
                        mat-button
                        (click)="cancelImport()"
                    >{{ 'COMMON.CANCEL_TEXT' | translate }}</button>
                    <button
                        mat-button
                        (click)="importLocations()"
                        [disabled]="disableImport || !locationUploadForm.valid"
                    >
                        {{ 'COMMON.IMPORT_HEADING' | translate }}
                    </button>
                </div>
            </div>
            <div
                class="contentLayout vault-file-container margin-20"
                [hidden]="showLocationDetails"
            >
                <div class="vault-header">
                    <div
                        class="breadcrumb vault-breadcrumb-hint"
                        *ngIf="!(breadcrumbDirectories && breadcrumbDirectories.length > 0)"
                    >
                        {{ currentlySelectedDirectoryId | async }}
                    </div>
                    <div
                        class="breadcrumb vault-breadcrumb-hint"
                        *ngIf="breadcrumbDirectories && breadcrumbDirectories.length > 0"
                    >
                        <span
                            class="cursor-pointer"
                            (click)="selectEachDirectory('/')"
                        >Root/</span>
                        <ng-container *ngFor="let file of breadcrumbDirectories">
                            <span
                                class="cursor-pointer"
                                (click)="selectEachDirectory(file?.id)"
                            >{{ file.name }}/</span>
                        </ng-container>
                    </div>

                    <div class="app-flex-filler"></div>

                    <div
                        class="auto-margin"
                        [ngClass]="{'mr-150': availablePageHint}"
                    >
                        <button
                            class="vault-upload-hint"
                            mat-button
                            [matMenuTriggerFor]="menu"
                            [disabled]="availablePageHint || !isUploadEnabled"
                        >
                            {{ 'COMMON.UPLOAD_BTN' | translate }}
                        </button>
                        <button
                            class="vault-export-hint"
                            mat-button
                            [matMenuTriggerFor]="menu2"
                            [disabled]="availablePageHint"
                        >
                            {{ 'COMMON.EXPORT_TEXT' | translate }}
                        </button>
                    </div>
                </div>
                <mat-menu
                    #menu="matMenu"
                    xPosition="before"
                    yPosition="below"
                    [overlapTrigger]="false"
                >
                    <button
                        mat-menu-item
                        (click)="openUploadDialog($event)"
                    >
                        <mat-icon>insert_drive_file</mat-icon>
                        <span>{{ 'COMMON.FILES_TEXT' | translate }}</span>
                    </button>
                    <button
                        [disabled]="!isMonitorCanAddEdit"
                        mat-menu-item
                        (click)="openLocationUploadDialog($event)"
                        *ngIf="isMonitorCanAddEdit"
                    >
                        <mat-icon>location_on</mat-icon>
                        <span>{{ 'COMMON.LOCATION_COLUMN_TITLE' | translate }}</span>
                    </button>
                    <button
                        mat-menu-item
                        (click)="openUploadDialog($event, 'csv')"
                    >
                        <mat-icon>file_upload</mat-icon>
                        <span>{{ 'COMMON.DATA_TEXT' | translate }}</span>
                    </button>
                </mat-menu>
                <mat-menu
                    #menu1="matMenu"
                    class="export-trigger"
                    xPosition="before"
                    yPosition="below"
                    [overlapTrigger]="false"
                >
                    <button
                        mat-menu-item
                        routerLink="./telemetry-export"
                        queryParamsHandling="preserve"
                    >
                        <mat-icon>insert_drive_file</mat-icon>
                        <span>{{ 'COMMON.CSV' | translate }}</span>
                    </button>
                    <button
                        mat-menu-item
                        (click)="exportLocation()"
                    >
                        <mat-icon>location_on</mat-icon>
                        <span>{{ 'COMMON.LOCATION_COLUMN_TITLE' | translate }}</span>
                    </button>
                </mat-menu>
                <!--    export new location -->
                <mat-menu
                    #menu2="matMenu"
                    class="mat-menu-export"
                    xPosition="before"
                    yPosition="below"
                    [overlapTrigger]="false"
                >
                    <button
                        mat-menu-item
                        class="bottom-border"
                        (click)="exportLocation()"
                    >
                        <span>{{ 'COMMON.NEW_EXPORT' | translate }}</span>
                    </button>
                    <button
                        mat-menu-item
                        class="bottom-border"
                        (click)="loadSetting()"
                    >
                        <span>{{ 'COMMON.LOAD_SETTING' | translate }}</span>
                    </button>
                    <button
                        mat-menu-item
                        [matMenuTriggerFor]="recentExportSetting"
                    >
                        {{ 'COMMON.RECENT_EXPORT' | translate }}
                    </button>
                </mat-menu>

                <mat-menu
                    #recentExportSetting="matMenu"
                    [overlapTrigger]="false"
                    class="recent-export-settings"
                >
                    <button
                        mat-menu-item
                        *ngFor="let eachSettings of recentExportSettings"
                        (click)="getSelectedExportSetting(eachSettings)"
                    >
                        {{ eachSettings.name }}
                    </button>
                </mat-menu>
                <div class="flex-container">
                    <div class="file-selected">
                        <mat-form-field class="full-width auto-file-select vault-search-hint">
                            <mat-icon
                                class="vaultFileSearch"
                                matPrefix
                            >search</mat-icon>
                            <input
                                matInput
                                placeholder="{{ 'VAULT.VAULT_DASHBOARD.SEARCH_FILE_PLACEHOLDER' | translate }}"
                                #searchCtrl
                                aria-label="Files"
                                [matAutocomplete]="auto"
                                [formControl]="fileCtrl"
                            />
                            <mat-icon
                                class="clickable"
                                matTooltip="{{ 'COMMON.CLEAR_TOOLTIP' | translate }}"
                                matSuffix
                                (click)="searchClear()"
                                *ngIf="searchCtrl.value"
                            >clear</mat-icon>
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option
                                    *ngFor="let file of filteredFiles | async"
                                    [value]="file.directoryAndFileName"
                                    (onSelectionChange)="selectedFile(file, $event)"
                                >
                                    <span>
                                        <b>{{ file.fileName }}</b> - {{ file.filePath }}</span>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="view-toggle auto-margin-t-b">
                        <mat-button-toggle-group
                            #group="matButtonToggleGroup"
                            [value]="selectedValue"
                            class="vault-toggle-hint"
                            (change)="onViewChange(group.value)"
                        >
                            <mat-button-toggle
                                value="1"
                                matTooltip="{{ 'COMMON.GRID_VIEW' | translate }}"
                            >
                                <mat-icon>view_module</mat-icon>
                            </mat-button-toggle>
                            <mat-button-toggle
                                value="0"
                                matTooltip="{{ 'COMMON.LIST_VIEW' | translate }}"
                            >
                                <mat-icon>view_list</mat-icon>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>

                <div
                    class="vault-folder-file"
                    [ngClass]="{ 'list-view': isListMode }"
                >
                    <mat-accordion class="folder-accordion">
                        <mat-expansion-panel
                            #panel
                            [expanded]="!containingSubDirectories || containingSubDirectories.length === 0"
                        >
                            <mat-expansion-panel-header [ngClass]="{'expansion-on-hint': availablePageHint}">
                                <mat-panel-title>
                                    {{ 'COMMON.FOLDERS_TEXT' | translate }}
                                </mat-panel-title>
                                <div class="vault-create-folder">
                                    <button
                                        class="vaultCreateFolderHint vault-create-hint vault-hide-sub-hint"
                                        mat-button
                                        (click)="createFolder()"
                                        [disabled]="availablePageHint || disableFolderEditing"
                                    >
                                        {{ 'VAULT.CREATE_FOLDER_BUTTON' | translate }}
                                    </button>
                                </div>
                            </mat-expansion-panel-header>
                            <div
                                class="vault-folder-list"
                                *ngIf="panel.expanded && (!isLoading && !vaultService.isVaultLoading)"
                            >
                                <div
                                    class="each-file"
                                    *ngFor="let file of subDirectories; let i = index"
                                >
                                    <button
                                        mat-flat-button
                                        class="folder-name vault-list-hint"
                                        (click)="selectEachDirectory(file?.id)"
                                    >
                                        <mat-icon>folder</mat-icon> {{ file.name }}
                                    </button>
                                    <button
                                        mat-button
                                        [ngClass]="{'vault-delete-hint': i === 0}"
                                        [disabled]="!isVaultDeletePermission || disableFolderEditing"
                                        matTooltip="{{ 'COMMON.DELETE_TOOLTIP' | translate }}"
                                        (click)="deleteFile($event, file?.id, file.name, true)"
                                    >
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                                <div
                                    *ngIf="!containingSubDirectories || containingSubDirectories.length === 0"
                                    class="notabledataFound"
                                >
                                    <span>{{ 'VAULT.VAULT_DASHBOARD.NO_FOLDER_EXISTS' | translate }}</span>
                                </div>
                                <div
                                    class="vaultPaginator"
                                    [hidden]="!(containingSubDirectories?.length > minViewSize) || !isListMode"
                                >
                                    <mat-paginator
                                        [length]="containingSubDirectories?.length"
                                        [pageIndex]="pageIndex"
                                        [pageSize]="pageSize"
                                        [pageSizeOptions]="[10, 25, 100]"
                                        (page)="onPageChange($event)"
                                    >
                                    </mat-paginator>
                                </div>

                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                    <div class="vault-file-list">
                        <div class="header-divider margin-b-10 pad-b-10">
                            <div class="auto-margin header-text select-all-header">
                                <mat-checkbox *ngIf="!isListMode"
                                    class="checkbox-space"
                                    [checked]="selectAllFiles"
                                    name="fileSelect"
                                    (click)="$event.stopPropagation()"
                                    (change)="selectAllChangeHandler($event)"
                                ></mat-checkbox>
                                <span>
                                    {{ 'COMMON.FILES_TEXT' | translate }}
                                </span>
                            </div>
                            <div class="auto-margin app-flex-filler"></div>
                            <ng-container *ngIf="enableMultiAction || availablePageHint">
                                <div class="auto-margin custom-flat-button">
                                    <button
                                        mat-button
                                        (click)="multiFileMove()"
                                        class="vault-top-move-hint"
                                    >
                                        <mat-icon class="vault-action-icon">low_priority</mat-icon>{{ 'COMMON.MOVE_TXT' | translate }}
                                    </button>
                                </div>
                                <div class="auto-margin custom-flat-button">
                                    <button
                                        mat-button
                                        class="vault-top-delete-hint"
                                        (click)="multiFileDelete()"
                                        [disabled]="!isVaultDeletePermission"
                                    >
                                        <mat-icon class="vault-action-icon">delete</mat-icon>{{ 'COMMON.DELETE_TOOLTIP' | translate }}
                                    </button>
                                </div>
                                <div class="auto-margin custom-flat-button">
                                    <button
                                        mat-button
                                        (click)="multiFileDownload()"
                                        class="vault-top-download-hint"
                                    >
                                        <mat-icon class="vault-action-icon">file_download</mat-icon>{{ 'COMMON.DOWNLOAD_BTN' | translate }}
                                    </button>
                                </div>
                                <div
                                    class="auto-margin custom-flat-button"
                                    *ngIf="enableMultiImportAction || availablePageHint"
                                >
                                    <button
                                        mat-button
                                        (click)="multiFileImport()"
                                        class="vault-top-import-hint"
                                    >
                                        <mat-icon class="vault-action-icon">swap_vert</mat-icon>{{ 'VAULT.IMPORT_TEXT' | translate }}
                                    </button>
                                </div>
                            </ng-container>
                        </div>
                        <div
                            [hidden]="isListMode || isLoading || vaultService.isVaultLoading"
                            *ngIf="!isListMode"
                            [ngClass]="{'mt-60': availablePageHint}"
                        >
                            <div
                                #filesContainer
                                class="vault-file-preview"
                                infiniteScroll
                                [infiniteScrollDistance]="2"
                                [infiniteScrollThrottle]="50"
                                (scrolled)="onScrollDown()"
                                [fromRoot]="false"
                                [infiniteScrollContainer]="vaultList"
                                *ngIf="vaultDirectoryDataSource && vaultDirectoryDataSource.data.length > 0"
                            >
                                <div
                                    class="each-file"
                                    *ngFor="let file of paginatedFiles"
                                    [ngClass]="{ 'vault-highlight-bg': file.highLight === true }"
                                >
                                    <div
                                        class="file-preview"
                                        matTooltip="{{ file.fileName }}"
                                    >
                                        <mat-checkbox
                                            class="checkbox-space vault-file-check-hint"
                                            [checked]="file.isChecked"
                                            name="fileSelect"
                                            (change)="fileSelectChangeHandler($event, file.uri)"
                                        ></mat-checkbox>
                                        <div
                                            class="file-type-big-icon-default vault-file-icon-hint file-type-big-icon-{{
                                                file.fileExtension.toString().toLowerCase()
                                            }} cursor-not-allowed"
                                            *ngIf="file.previewType === 'default'"
                                        ></div>
                                        <div
                                            class="file-type-big-icon-default vault-file-icon-hint file-type-big-icon-{{
                                                file.fileExtension.toString().toLowerCase()
                                            }} cursor-pointer"
                                            (click)="
                                                fileSelected($event, file.fileExtension, file.fileName, file.uri, true)
                                            "
                                            *ngIf="file.previewType === 'txt'"
                                        ></div>
                                        <div
                                            class="file-type-big-icon-default vault-file-icon-hint file-type-big-icon-{{
                                                file.fileExtension.toString().toLowerCase()
                                            }} cursor-pointer"
                                            (click)="
                                                fileSelected(
                                                    $event,
                                                    file.fileExtension,
                                                    file.fileName,
                                                    file.uri,
                                                    true,
                                                    file.absoluteUri
                                                )
                                            "
                                            *ngIf="file.previewType === 'img'"
                                        >
                                        </div>
                                    </div>
                                    <div class="file-name">
                                        <span class="name-text">{{ file.fileName }} </span>
                                    </div>
                                    <div class="file-action">
                                        <button
                                            mat-button
                                            class="vault-file-share-hint"
                                            matTooltip="{{ 'VAULT.SHARE_TEXT' | translate }}"
                                            (click)="shareFile($event, file.uri, file.fileName)"
                                        >
                                            <mat-icon>share</mat-icon>
                                        </button>
                                        <button
                                            mat-button
                                            class="vault-file-download-hint"
                                            matTooltip="{{ 'COMMON.DOWNLOAD_BTN' | translate }}"
                                            (click)="fileDownload([file.uri])"
                                        >
                                            <mat-icon>file_download</mat-icon>
                                        </button>
                                        <button
                                            mat-button
                                            disabled
                                            matTooltip="{{ 'VAULT.IMPORT_TEXT' | translate }}"
                                            class="import-option vault-file-import-hint"
                                            *ngIf="
                                                !file.isPotentialTelemetryFile &&
                                                !file.isTelogFile &&
                                                file.fileExtension.toString().toLowerCase() !== 'xml'
                                            "
                                        >
                                            <mat-icon>import_export</mat-icon>
                                        </button>
                                        <button
                                            mat-button
                                            [disabled]="!isMonitorCanAddEdit"
                                            matTooltip="{{ 'VAULT.IMPORT_TEXT' | translate }}"
                                            class="import-option vault-file-import-hint"
                                            *ngIf="file.fileExtension.toString().toLowerCase() === 'xml'"
                                            (click)="importLocationData($event, file)"
                                        >
                                            <mat-icon>import_export</mat-icon>
                                        </button>
                                        <button
                                            mat-button
                                            matTooltip="{{ 'VAULT.IMPORT_TEXT' | translate }}"
                                            class="import-option vault-file-import-hint"
                                            *ngIf="file.isPotentialTelemetryFile && !file.isTelogFile"
                                            (click)="importTelemetryData($event, file)"
                                        >
                                            <mat-icon>import_export</mat-icon>
                                        </button>
                                        <button
                                            mat-button
                                            matTooltip="{{ 'VAULT.IMPORT_TEXT' | translate }}"
                                            class="import-option vault-file-import-hint"
                                            *ngIf="!file.isPotentialTelemetryFile && file.isTelogFile"
                                            (click)="importTelogData($event, file)"
                                        >
                                            <mat-icon>import_export</mat-icon>
                                        </button>
                                        <button
                                            [disabled]="!isVaultDeletePermission"
                                            mat-button
                                            class="vault-file-delete-hint"
                                            matTooltip="{{ 'COMMON.DELETE_TOOLTIP' | translate }}"
                                            (click)="deleteFile($event, file.uri, file.fileName, false)"
                                        >
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div
                            [hidden]="vaultDirectoryData.length == 0 || !isListMode || isLoading || vaultService.isVaultLoading"
                            id="valutItemDataLeaderBoardTable"
                            [ngClass]="{'mt-60': availablePageHint}"
                        >
                            <mat-table
                                #table
                                [dataSource]="vaultDirectoryDataSource"
                                matSort
                            >
                                <!-- Name Column -->
                                <ng-container matColumnDef="fileName">
                                    <mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header
                                    >
                                        <div class="select-all-header">
                                            <mat-checkbox
                                                class="checkbox-space"
                                                [checked]="selectAllFiles"
                                                name="fileSelect"
                                                (click)="$event.stopPropagation()"
                                                (change)="selectAllChangeHandler($event)"
                                            ></mat-checkbox>
                                            <span>
                                                {{ 'VAULT.VAULT_DASHBOARD.FILE_NAME_TEXT' | translate }}
                                            </span>
                                        </div>
                                    </mat-header-cell>
                                    <mat-cell
                                        *matCellDef="let file; let i = index"
                                        [ngClass]="{ 'vault-highlight-bg': file.highLight === true }"
                                    >
                                        <div class="files-location">
                                            <mat-checkbox
                                                [ngClass]="{'vault-file-check-hint': i === 0}"
                                                class="checkbox-space"
                                                [checked]="file.isChecked"
                                                name="fileSelect"
                                                (change)="fileSelectChangeHandler($event, file.uri)"
                                            ></mat-checkbox>

                                            <div class="fileTypeIcon_default fileTypeIcon_{{
                                                    file.fileExtension.toString().toLowerCase()
                                                }}"></div>
                                            <a
                                                [ngClass]="{'vault-file-icon-hint': i === 0}"
                                                class="file-link"
                                                (click)="
                                                    fileSelected(
                                                        $event,
                                                        file.fileExtension,
                                                        file.fileName,
                                                        file.uri,
                                                        false,
                                                        file.absoluteUri
                                                    )
                                                "
                                            >{{ file.fileName }}</a>
                                        </div>
                                    </mat-cell>
                                </ng-container>

                                <!-- Type Column -->
                                <ng-container matColumnDef="fileExtension">
                                    <mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header
                                    >
                                        {{ 'COMMON.TYPE_COLUMN_TITLE' | translate }}
                                    </mat-header-cell>
                                    <mat-cell
                                        *matCellDef="let file"
                                        class="lowerCaseText"
                                        [ngClass]="{ 'vault-highlight-bg': file.highLight === true }"
                                    >
                                        {{ file.fileExtension }}
                                    </mat-cell>
                                </ng-container>

                                <!-- Size Column -->
                                <ng-container matColumnDef="file_size">
                                    <mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header
                                    >
                                        {{ 'VAULT.VAULT_DASHBOARD.SIZE_TEXT' | translate }}
                                    </mat-header-cell>
                                    <mat-cell
                                        *matCellDef="let file"
                                        [ngClass]="{ 'vault-highlight-bg': file.highLight === true }"
                                    >
                                        {{ file.file_size }} {{ file.file_ext }}</mat-cell>
                                </ng-container>

                                <!-- Modified Date Column -->

                                <ng-container matColumnDef="modifiedDate">
                                    <mat-header-cell
                                        *matHeaderCellDef
                                        mat-sort-header
                                        class="text-right"
                                    >
                                        {{ 'VAULT.VAULT_DASHBOARD.UPLOAD_DATE' | translate }}
                                    </mat-header-cell>
                                    <mat-cell
                                        *matCellDef="let file"
                                        class="text-right"
                                        [ngClass]="{ 'vault-highlight-bg': file.highLight === true }"
                                    >
                                        {{ file.modifiedDate }} {{ file.active }}</mat-cell>
                                </ng-container>

                                <!-- Actions Column -->
                                <ng-container matColumnDef="actions">
                                    <mat-header-cell
                                        *matHeaderCellDef
                                        class="vaultActionsColumnHeader"
                                    >
                                    </mat-header-cell>
                                    <mat-cell
                                        *matCellDef="let file; let i = index"
                                        class="vaultActionsColumnCell"
                                        [ngClass]="{ 'vault-highlight-bg': file.highLight === true }"
                                    >
                                        <button
                                            [ngClass]="{'vault-file-share-hint': i === 0}"
                                            mat-button
                                            matTooltip="{{ 'VAULT.SHARE_TEXT' | translate }}"
                                            (click)="shareFile($event, file.uri, file.fileName)"
                                        >
                                            <mat-icon>share</mat-icon>
                                        </button>
                                        <button
                                            [ngClass]="{'vault-file-download-hint': i === 0}"
                                            mat-button
                                            matTooltip="{{ 'COMMON.DOWNLOAD_BTN' | translate }}"
                                            (click)="fileDownload([file.uri])"
                                        >
                                            <mat-icon>file_download</mat-icon>
                                        </button>
                                        <button
                                            mat-button
                                            disabled
                                            matTooltip="{{ 'VAULT.IMPORT_TEXT' | translate }}"
                                            class="import-option"
                                            [ngClass]="{'vault-file-import-hint': i === 0}"
                                            *ngIf="
                                                !file.isPotentialTelemetryFile &&
                                                !file.isTelogFile &&
                                                file.fileExtension.toString().toLowerCase() !== 'xml'
                                            "
                                        >
                                            <mat-icon>import_export</mat-icon>
                                        </button>
                                        <button
                                            mat-button
                                            [ngClass]="{'vault-file-import-hint': i === 0}"
                                            [disabled]="!isMonitorCanAddEdit"
                                            matTooltip="{{ 'VAULT.IMPORT_TEXT' | translate }}"
                                            class="import-option"
                                            *ngIf="file.fileExtension.toString().toLowerCase() === 'xml'"
                                            (click)="importLocationData($event, file)"
                                        >
                                            <mat-icon>import_export</mat-icon>
                                        </button>
                                        <button
                                            mat-button
                                            [ngClass]="{'vault-file-import-hint': i === 0}"
                                            matTooltip="{{ 'VAULT.IMPORT_TEXT' | translate }}"
                                            class="import-option"
                                            *ngIf="file.isPotentialTelemetryFile && !file.isTelogFile"
                                            (click)="importTelemetryData($event, file)"
                                        >
                                            <mat-icon>import_export</mat-icon>
                                        </button>
                                        <button
                                            mat-button
                                            [ngClass]="{'vault-file-import-hint': i === 0}"
                                            matTooltip="{{ 'VAULT.IMPORT_TEXT' | translate }}"
                                            class="import-option"
                                            *ngIf="!file.isPotentialTelemetryFile && file.isTelogFile"
                                            (click)="importTelogData($event, file)"
                                        >
                                            <mat-icon>import_export</mat-icon>
                                        </button>
                                        <button
                                            [ngClass]="{'vault-file-delete-hint': i === 0}"
                                            mat-button
                                            [disabled]="!isVaultDeletePermission"
                                            matTooltip="{{ 'COMMON.DELETE_TOOLTIP' | translate }}"
                                            (click)="deleteFile($event, file.uri, file.fileName, false)"
                                        >
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </mat-cell>
                                </ng-container>

                                <mat-header-row *matHeaderRowDef="vaultDirectoryDisplayedColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: vaultDirectoryDisplayedColumns"></mat-row>
                            </mat-table>
                        </div>
                        <div
                            *ngIf="totalPaginationLength === 0 || vaultDirectoryData.length == 0 || !vaultDirectoryDataSource"
                            class="notabledataFound"
                        >
                            <span>{{ 'VAULT.VAULT_DASHBOARD.NO_FILE_EXISTS' | translate }}</span>
                        </div>
                        <div
                            class="valutItemDataLeaderBoardPaginator"
                            [hidden]="!(totalPaginationLength > minViewSize) || !isListMode || !vaultDirectoryDataSource"
                        >
                            <mat-paginator
                                #vaultDirectoryPaginator
                                [length]="totalPaginationLength"
                                [pageIndex]="pageIndex"
                                [pageSize]="pageSize"
                                [pageSizeOptions]="[10, 25, 100]"
                                (page)="onPaginateChange($event)"
                            >
                            </mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <router-outlet></router-outlet>
</div>
