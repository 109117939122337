<div>
    <div class="tooltip-toggle-container">
        <div class="tooltip-toggle pad-t-10 pad-r-15 show-tooltips-button edit-cus-ddf-graph-tooltip-hint">
            <span class="pad-r-15">{{ 'SLIICER.COMMON.SHOW_TOOLTIPS' | translate }}</span>
            <mat-slide-toggle (change)="onTooltipEnabledChanged()" [checked]="tooltipEnabled"></mat-slide-toggle>
        </div>
        <div
            class="chart-cont edit-cus-ddf-graph-chart-hint"
            #chart
            type="StockChart"
            [chart]="chartOptions"
            style="min-width: 310px; height: 434px; max-width: 1200px; margin: 0 auto"
        ></div>
    </div>
</div>
