import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { CacheEntry, MAX_CACHE_AGE } from 'app/shared/models/cache-entry';
import { Config } from './config';

@Injectable()
export class CacheService {
    public cacheMap = new Map<string, CacheEntry>();
    public get(req: HttpRequest<any>): HttpResponse<any> | null {
        const hashForUrl = `${req.urlWithParams}-${JSON.stringify(req.body)}`;
        const entry = this.cacheMap.get(hashForUrl);
        if (!entry) {
            return null;
        }
        const isExpired = Date.now() - entry.entryTime > MAX_CACHE_AGE;
        return isExpired ? null : entry.response;
    }
    public put(req: HttpRequest<any>, res: HttpResponse<any>): void {
        const hashForUrl = `${req.urlWithParams}-${JSON.stringify(req.body)}`;
        const entry: CacheEntry = { url: hashForUrl, response: res, entryTime: Date.now() };
        this.cacheMap.set(hashForUrl, entry);
        this.deleteExpiredCache();
    }

    public removeFromCache(url: string) {
        this.cacheMap.forEach((entry) => {
            if (entry.url.indexOf(url) > -1) {
                this.cacheMap.delete(entry.url);
            }
        });
    }

    private deleteExpiredCache() {
        this.cacheMap.forEach((entry) => {
            if (Date.now() - entry.entryTime > MAX_CACHE_AGE) {
                this.cacheMap.delete(entry.url);
            }
        });
    }
}
