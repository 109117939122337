export interface Author {
    id: string;
    name: string;
}

export enum StudyState {
    FirstSave = 'FirstSave',
    TelemetryCopyStarted = 'TelemetryCopyStarted',
    TelemetryCopyFailed = 'TelemetryCopyFailed',
    // Indicates that calculations are complete for the
    // given set of inputs.
    Ready = 'Ready',
    // Indicates that the caulcations have been triggered.
    CalculationStarted = 'CalculationStarted',
    // Indicates that the calculations have failed.
    CalculationFailed = 'CalculationFailed',
}

export interface Metadata {
    name: string;
    desc?: string;
    created: string;
    lastModified: string;
    telemetryDatabaseName: string;
    studyVaultName: string;
    flowLoadImportFile: string;
    profileImportFile: string;
    preliminaryResults: boolean;
    locked: boolean;
    allowRead: boolean;
    studyState: StudyState;
    authors: Author[];
    basinState?: BasinStateEnum;
    calculationRunId: string;
    calculationRunTime: string;
}

export enum BasinStateEnum {
    ready = 'Ready',
    storms = 'UserDefinedStorms'
}

export interface StudyCalcState {
    basinState: BasinStateEnum;
    calculationRunTime: string;
    calculationRunId: string;
}
