import { Injectable } from '@angular/core';
import {
    SLI_WEEKDAY_GROUP_NAME,
    SLI_WEEKEND_GROUP_NAME,
    SLI_OCOLOR_WEEKGROUP_WEEKDAY,
    SLI_OCOLOR_WEEKGROUP_WEEKEND,
    SLI_COLOR_WEEKGROUP_WEEKDAY,
    SLI_COLOR_WEEKGROUP_WEEKEND,
    SLI_WEEKGROUP_COLORS,
    SLI_COLOR_WEEKGROUP_WEEKDAY_TRACES,
    SLI_COLOR_WEEKGROUP_WEEKEND_TRACES,
} from 'app/shared/constant';
import { DayOfWeekGroupDays } from 'app/shared/models/sliicer';

const DEFAULT_SEASON_NAME = 'Default';
const DEFAULT_YEAR_NAME = 'All';
const DEFAULT_REGIME_NAME = 'Default';

@Injectable()
export class SliicerUtils {
    public static determineDayGroupLegendIndices(
        weekGroupDays: DayOfWeekGroupDays[],
        useSeasons: boolean,
        useRegimes: boolean,
        useYears: boolean,
    ): Map<string, number> {
        const nameIndexMap = new Map<string, number>();
        weekGroupDays.forEach((wg, index) => {
            const name = SliicerUtils.weekGroupName(wg, useSeasons, useRegimes, useYears);
            nameIndexMap[name] = index;
        });
        return nameIndexMap;
    }

    public static weekGroupName(
        weekGroup: DayOfWeekGroupDays,
        useSeasons: boolean,
        useRegimes: boolean,
        useYears: boolean,
    ): string {
        const extras = [];
        if (useSeasons && weekGroup.season !== DEFAULT_SEASON_NAME) {
            extras.push(weekGroup.season);
        }
        if (useYears && weekGroup.year !== DEFAULT_YEAR_NAME) {
            extras.push(weekGroup.year);
        }
        if (useRegimes && weekGroup.regime !== DEFAULT_REGIME_NAME) {
            extras.push(weekGroup.regime);
        }
        let name = weekGroup.dayOfWeekGroupName;
        if (extras.length > 0) {
            name += ' [' + extras.join(',') + ']';
        }
        return name;
    }

    public static weekGroupTracesColor(weekGroupName: string, index: number): string {
        if (weekGroupName === SLI_WEEKDAY_GROUP_NAME) {
            return SLI_COLOR_WEEKGROUP_WEEKDAY_TRACES;
        } else if (weekGroupName === SLI_WEEKEND_GROUP_NAME) {
            return SLI_COLOR_WEEKGROUP_WEEKEND_TRACES;
        } else {
            return SLI_WEEKGROUP_COLORS[index % SLI_WEEKGROUP_COLORS.length].lineColor;
        }
    }

    public static weekGroupColor(weekGroupName: string, index: number): string {
        if (weekGroupName === SLI_WEEKDAY_GROUP_NAME) {
            return SLI_COLOR_WEEKGROUP_WEEKDAY;
        } else if (weekGroupName === SLI_WEEKEND_GROUP_NAME) {
            return SLI_COLOR_WEEKGROUP_WEEKEND;
        } else {
            return SLI_WEEKGROUP_COLORS[index % SLI_WEEKGROUP_COLORS.length].lineColor;
        }
    }

    public static weekGroupOverlayColor(weekGroupName: string, index: number): string {
        if (weekGroupName === SLI_WEEKDAY_GROUP_NAME) {
            return SLI_OCOLOR_WEEKGROUP_WEEKDAY;
        } else if (weekGroupName === SLI_WEEKEND_GROUP_NAME) {
            return SLI_OCOLOR_WEEKGROUP_WEEKEND;
        } else {
            return SLI_WEEKGROUP_COLORS[index % SLI_WEEKGROUP_COLORS.length].overlayColor;
        }
    }

    public static rainAxisMax(unitOfMeasure: string): number {
        return unitOfMeasure === 'mm' ? 30 : 1;
    }
}
