import { Entities, ReplaceEntity } from 'app/shared/models/entities';
import * as Highcharts from 'highcharts';
import uniqBy from 'lodash/uniqBy';
import { QUANTITY_ENTITY, RAIN_ENTITY, VELOCITY_ENTITY, DEPTH_ENTITY } from '../constant';
import { EntityResponseItem } from '../models/view-data';
import { ADS_REFRESH_TOKEN_KEY, ADS_TOKEN_KEY } from '../services/auth.service';
export class StringUtils {
    // Represents low level (which has only 0 value) precendence entities
    private lowPrecendenceEntities = new Array<Entities>();
    public static locationColors = new Array<string>();

    public static isEmpty(str: string): boolean {
        return StringUtils.isNull(str) || str === '';
    }

    public defaultSelectedEntityNames() {
        const defaultEntityNames = 'UNIDEPTH, VELOCITY, QCONTINUITY, RAIN';

        return defaultEntityNames;
    }

    public static isNull(obj: any, properties?: string): boolean {
        if (properties === undefined || properties === null || StringUtils.isNull(obj)) {
            return obj === undefined || obj === null;
        }
        const split: string[] = properties.split('.');
        for (let i = 0; i < split.length; i++) {
            obj = obj[split[i]];
            if (obj === undefined || obj === null) {
                return true;
            }
        }
        return false;
    }

    public static isNumeric(str: any): boolean {
        if (this.isEmpty(str)) {
            return false;
        }
        // From jQuery (https://github.com/jquery/jquery/blob/25d8ccd1112d75394b91071ff7eba13283aaf898/src/core.js#L223)
        return !isNaN(str - parseFloat(str));
    }

    public static startsWith(str: string, search: string): boolean {
        if (StringUtils.isNull(str) || StringUtils.isNull(search)) {
            return false;
        }
        if (search.length > str.length) {
            return false;
        }
        return str.toLowerCase().substring(0, search.length) === search.toLowerCase();
    }

    public static coalesce(...strings: string[]): string {
        for (let i = 0; i < strings.length; i++) {
            if (!StringUtils.isEmpty(strings[i])) {
                return strings[i];
            }
        }
        return null;
    }

    public static search(keyword: string, ...strings: string[]): boolean {
        if (StringUtils.isEmpty(keyword)) {
            return false;
        }
        for (const str of strings) {
            if (str.toLowerCase().indexOf(keyword.toLowerCase()) > -1) {
                return true;
            }
        }
        return false;
    }

    public static cleanIconName(icon: string): string {
        if (icon !== null && icon.indexOf(' ') > -1) {
            icon = icon.replace(/\s/g, '_');
        }
        return icon;
    }

    public static replaceBetween(str: string, replaceWith: string, start: number, end: number): string {
        return str.substring(0, start) + replaceWith + str.substring(end);
    }

    public static toCamelCase(str: string): string {
        return str
            .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => {
                return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
            })
            .replace(/\s+/g, '');
    }

    public static toTitleCase(str: string): string {
        return str.replace(/\w\S*/g, (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    public static cleanLinkString(linkStr: string) {
        return linkStr ? (linkStr.includes('?') ? linkStr.split('?')[0] : linkStr) : linkStr;
    }

    // http://stackoverflow.com/questions/1068834/object-comparison-in-javascript/1144249#1144249
    // tslint:disable-next-line:cyclomatic-complexity
    public static deepCompare(x, y): boolean {
        let p;
        const leftChain = [],
            rightChain = [];

        // remember that NaN === NaN returns false
        // and isNaN(undefined) returns true
        if (isNaN(x) && isNaN(y) && typeof x === 'number' && typeof y === 'number') {
            return true;
        }

        // Compare primitives and functions.
        // Check if both arguments link to the same object.
        // Especially useful on the step where we compare prototypes
        if (x === y) {
            return true;
        }

        // Works in case when functions are created in constructor.
        // Comparing dates is a common scenario. Another built-ins?
        // We can even handle functions passed across iframes
        if (
            (typeof x === 'function' && typeof y === 'function') ||
            (x instanceof Date && y instanceof Date) ||
            (x instanceof RegExp && y instanceof RegExp) ||
            (x instanceof String && y instanceof String) ||
            (x instanceof Number && y instanceof Number)
        ) {
            return x.toString() === y.toString();
        }

        // At last checking prototypes as good as we can
        if (!(x instanceof Object && y instanceof Object)) {
            return false;
        }

        if (x.isPrototypeOf(y) || y.isPrototypeOf(x)) {
            return false;
        }

        if (x.constructor !== y.constructor) {
            return false;
        }

        if (x.prototype !== y.prototype) {
            return false;
        }

        // Check for infinitive linking loops
        if (leftChain.indexOf(x) > -1 || rightChain.indexOf(y) > -1) {
            return false;
        }

        // Quick checking of one object being a subset of another.
        // todo: cache the structure of arguments[0] for performance
        for (p in y) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            } else if (typeof y[p] !== typeof x[p]) {
                return false;
            }
        }

        // tslint:disable-next-line:forin
        for (p in x) {
            if (y.hasOwnProperty(p) !== x.hasOwnProperty(p)) {
                return false;
            } else if (typeof y[p] !== typeof x[p]) {
                return false;
            }

            switch (typeof x[p]) {
                case 'object':
                case 'function':
                    leftChain.push(x);
                    rightChain.push(y);

                    if (!StringUtils.deepCompare(x[p], y[p])) {
                        return false;
                    }

                    leftChain.pop();
                    rightChain.pop();
                    break;

                default:
                    if (x[p] !== y[p]) {
                        return false;
                    }
                    break;
            }
        }
        return true;
    }
    public static validTwoDecimalPlaces(value?: any) {
        return Math.round(value.replace(/[^\d.-]/g, '') * 100) / 100;
    }

    /**
     * capitalized the string value with one space in between word
     * @param str :string
     */
    public static capitalizedStringWithSpace(str: string) {
        str = str + '';
        str = str.trim();
        str = str.replace(/\s+/g, ' ');
        str = str.charAt(0).toUpperCase() + str.slice(1);
        return str;
    }

    /**
     * Remove Extra Space
     * @param str :string
     */
    public static removeExtraSpace(str: string) {
        str = str + '';
        str = str.trim();
        str = str.replace(/\s+/g, ' ');
        return str;
    }

    /**
     * Used to check if only contained whitespace (ie. spaces, tabs or line breaks)
     * @param str -string containt
     */
    public static isStringEmpty(str: string) {
        return str.replace(/\s/g, '').length > 0 ? false : true;
    }

    /**
     * sort the entites based on displayGroupPrecendece and then sort alphabetically based on entity name
     * zero level precedence entities will be shown at last
     * entites having display group precedence greater than 0 will be listed in increasing order
     * @param entities represents entities details
     */
    public sortEntities(entities: Array<Entities>): Array<Entities> {
        if (entities) {
            // tslint:disable-next-line:no-any
            entities.sort((first: any, next: any) => {
                // sort entities based on display group an then entity name
                if (first.displayGroupPrecendece < next.displayGroupPrecendece) {
                    return -1;
                } else if (first.displayGroupPrecendece > next.displayGroupPrecendece) {
                    return 1;
                } else {
                    if (typeof first.entityName === 'string' && typeof next.entityName === 'string') {
                        return first.entityName.toLowerCase() < next.entityName.toLowerCase() ? -1 : 1;
                    } else {
                        return 0;
                    }
                }
            });
        }
        // store all displaygroup precendence having zero value
        this.lowPrecendenceEntities = entities.filter((entity: Entities) => entity.displayGroupPrecendece === 0);

        // return entities having display group precedence other than 0 (i.e. Flow, Velocity and Depth etc.)
        return entities.filter((entity: Entities) => entity.displayGroupPrecendece > 0);
    }

    /**
     * This function sets the default selected entities in multi select auto complete field
     * @param entity -> indicates individual entity
     */
    public defaultSelectedEntities(entity: Entities | EntityResponseItem, replaceEntities: Array<ReplaceEntity> = []) {
        // 4122 represents UNIDEPTH entity Id
        // 4202 represents VELOCITY entity Id
        // 3303 represents QUANTITY entity Id
        // 2123 represents RAIN entity Id
        const defaultEntityIds = [DEPTH_ENTITY, VELOCITY_ENTITY, QUANTITY_ENTITY, RAIN_ENTITY];

        if (replaceEntities && replaceEntities.length) {
            replaceEntities.forEach((replace) => {
                const replaceTo = defaultEntityIds.findIndex((e) => e === replace.oldEntity);
                if (replaceTo > -1) {
                    defaultEntityIds[replaceTo] = replace.newEntity;
                }
            });
        }
        if (defaultEntityIds.indexOf(entity.entityId) > -1) {
            return true;
        } else {
            return false;
        }
    }

    public static setHighChartTheme(chart?) {
        const fontColor = '#E0E0E3';
        const theme = {
            ads: {
                debugtooltipcolor: '#e0e0e0',
                fillselected: '#5459fe32'
            },
            colors: [
                '#2b908f',
                '#90ee7e',
                '#f45b5b',
                '#7798BF',
                '#aaeeee',
                '#ff0066',
                '#eeaaee',
                '#55BF3B',
                '#DF5353',
                '#7798BF',
                '#aaeeee',
            ],
            chart: {
                backgroundColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
                    stops: [
                        [0, '#212121'],
                        [1, '#212121'],
                    ],
                },
                style: {
                    fontFamily: "'Unica One', sans-serif",
                },
                plotBorderColor: '#606063',
            },
            title: {
                style: {
                    color: fontColor,
                    textTransform: 'uppercase',
                    fontSize: '20px',
                },
            },
            subtitle: {
                style: {
                    color: fontColor,
                    textTransform: 'uppercase',
                },
            },
            xAxis: {
                gridLineColor: '#707073',
                labels: {
                    style: {
                        color: fontColor,
                    },
                },
                lineColor: '#707073',
                minorGridLineColor: '#505053',
                tickColor: '#707073',
                title: {
                    style: {
                        color: '#A0A0A3',
                    },
                },
            },
            yAxis: {
                gridLineColor: '#707073',
                labels: {
                    style: {
                        color: fontColor,
                    },
                },
                lineColor: '#707073',
                minorGridLineColor: '#505053',
                tickColor: '#707073',
                tickWidth: 1,
                title: {
                    style: {
                        color: '#A0A0A3',
                    },
                },
            },
            tooltip: {
                backgroundColor: '#000000',
                style: {
                    color: '#FFFFFF',
                },
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        color: '#B0B0B3',
                    },
                    marker: {
                        lineColor: '#333',
                    },
                },
                boxplot: {
                    fillColor: '#505053',
                },
                candlestick: {
                    lineColor: 'white',
                },
                errorbar: {
                    color: 'white',
                },
            },
            legend: {
                itemStyle: {
                    color: fontColor,
                },
                itemHoverStyle: {
                    color: '#FFF',
                },
                itemHiddenStyle: {
                    color: '#606063',
                },
            },
            credits: {
                style: {
                    color: '#666',
                },
            },
            labels: {
                style: {
                    color: '#707073',
                },
            },

            drilldown: {
                activeAxisLabelStyle: {
                    color: '#F0F0F3',
                },
                activeDataLabelStyle: {
                    color: '#F0F0F3',
                },
            },

            navigation: {
                buttonOptions: {
                    symbolStroke: '#DDDDDD',
                    theme: {
                        fill: '#505053',
                    },
                },
            },

            // scroll charts
            rangeSelector: {
                buttonTheme: {
                    fill: '#424242',
                    stroke: 'none',
                    'stroke-width': 0,
                    r: 2,
                    style: {
                        color: '#e0e0e0',
                        fontWeight: 'bold',
                    },
                    states: {
                        hover: {
                            fill: '#616161',
                        },
                        disabled: {
                            fill: '#000000',
                            style: '#a0a0a0',
                        },
                        select: {
                            fill: '#9cf299',
                            style: {
                                color: '#212121',
                            },
                        },
                    },
                },
                inputBoxBorderColor: '#505053',
                inputStyle: {
                    backgroundColor: '#333',
                    color: 'silver',
                },
                labelStyle: {
                    color: 'silver',
                },
            },

            navigator: {
                handles: {
                    backgroundColor: '#666',
                    borderColor: '#AAA',
                },
                outlineColor: '#CCC',
                maskFill: 'rgba(255,255,255,0.1)',
                series: {
                    color: '#7798BF',
                    lineColor: '#A6C7ED',
                },
                xAxis: {
                    gridLineColor: '#505053',
                },
            },

            scrollbar: {
                barBackgroundColor: '#808083',
                barBorderColor: '#808083',
                buttonArrowColor: '#CCC',
                buttonBackgroundColor: '#606063',
                buttonBorderColor: '#606063',
                rifleColor: '#FFF',
                trackBackgroundColor: '#404043',
                trackBorderColor: '#404043',
            },

            // special colors for some of the
            legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
            background2: '#505053',
            dataLabelsColor: '#B0B0B3',
            textColor: '#C0C0C0',
            contrastTextColor: '#F0F0F3',
            maskColor: 'rgba(255,255,255,0.3)',

        };

        StringUtils.setChartOptions(theme, chart, {fontColor: fontColor});
    }

    public static setHighchartWhiteTheme(chart?) {
        const fontColor = '#000000';
        const theme = {
            ads: {
                debugtooltipcolor: '#212121',
                fillselected: '#0409fe22'
            },
            colors: [
                '#2b908f',
                '#90ee7e',
                '#f45b5b',
                '#7798BF',
                '#aaeeee',
                '#ff0066',
                '#eeaaee',
                '#55BF3B',
                '#DF5353',
                '#7798BF',
                '#aaeeee',
            ],
            chart: {
                backgroundColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
                    stops: [
                        [0, '#ffffff'],
                        [1, '#ffffff'],
                    ],
                },
                style: {
                    fontFamily: "'Unica One', sans-serif",
                },
                plotBorderColor: '#606063',
            },
            title: {
                style: {
                    color: fontColor,
                    textTransform: 'uppercase',
                    fontSize: '20px',
                },
            },
            subtitle: {
                style: {
                    color: fontColor,
                    textTransform: 'uppercase',
                },
            },
            xAxis: {
                gridLineColor: 'silver',
                labels: {
                    style: {
                        color: fontColor,
                    },
                },
                lineColor: '#707073',
                minorGridLineColor: '#00505053',
                tickColor: '#000000',
                title: {
                    style: {
                        color: fontColor,
                    },
                },
            },
            yAxis: {
                gridLineColor: 'silver',
                labels: {
                    style: {
                        color: fontColor,
                    },
                },
                lineColor: '#707073',
                minorGridLineColor: '#00505053',
                tickColor: '#00000000',
                tickWidth: 1,
                title: {
                    style: {
                        color: fontColor,
                    },
                },
            },
            tooltip: {
                backgroundColor: '#FFFFFF',
                style: {
                    color: fontColor,
                },
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        color: '#B0B0B3',
                    },
                    marker: {
                        lineColor: '#333',
                    },
                },
                boxplot: {
                    fillColor: '#505053',
                },
                candlestick: {
                    lineColor: 'white',
                },
                errorbar: {
                    color: 'white',
                },
            },
            legend: {
                itemStyle: {
                    color: fontColor,
                },
                itemHoverStyle: {
                    color: fontColor,
                },
                itemHiddenStyle: {
                    color: '#606063',
                },
            },
            credits: {
                style: {
                    color: '#666',
                },
            },
            labels: {
                style: {
                    color: '#707073',
                },
            },

            drilldown: {
                activeAxisLabelStyle: {
                    color: '#F0F0F3',
                },
                activeDataLabelStyle: {
                    color: '#F0F0F3',
                },
            },

            navigation: {
                buttonOptions: {
                    symbolStroke: '#DDDDDD',
                    theme: {
                        fill: '#505053',
                    },
                },
            },

            // scroll charts
            rangeSelector: {
                buttonTheme: {
                    fill: '#e0e0e0',
                    stroke: 'none',
                    'stroke-width': 0,
                    r: 2,
                    style: {
                        color: '#424242',
                    },
                    states: {
                        hover: {
                            fill: '#c6c6c6',
                        },
                        disabled: {
                            fill: '#e6e6e6',
                            style: '#616161',
                        },
                        select: {
                            fill: '#5cb259',
                            style: {
                                color: 'white',
                            },
                        },
                    },
                },
                inputBoxBorderColor: '#505053',
                inputStyle: {
                    backgroundColor: '#333',
                    color: 'silver',
                },
                labelStyle: {
                    color: fontColor,
                },
            },

            navigator: {
                handles: {
                    backgroundColor: '#666',
                    borderColor: '#AAA',
                },
                outlineColor: '#CCC',
                maskFill: 'rgba(255,255,255,0.1)',
                series: {
                    color: '#7798BF',
                    lineColor: '#A6C7ED',
                },
                xAxis: {
                    gridLineColor: '#505053',
                },
            },

            scrollbar: {
                barBackgroundColor: '#808083',
                barBorderColor: '#808083',
                buttonArrowColor: '#CCC',
                buttonBackgroundColor: '#606063',
                buttonBorderColor: '#606063',
                rifleColor: '#FFF',
                trackBackgroundColor: '#404043',
                trackBorderColor: '#404043',
            },

            // special colors for some of the
            legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
            background2: '#505053',
            dataLabelsColor: '#B0B0B3',
            textColor: '#C0C0C0',
            contrastTextColor: '#F0F0F3',
            maskColor: 'rgba(255,255,255,0.3)',
        };

        StringUtils.setChartOptions(theme, chart, {fontColor: fontColor});
    }

    private static setChartOptions(theme, chart, options: {fontColor: string}) {
        Highcharts.setOptions(theme);
        if(chart) {
            chart.update(theme);
            for(const axis of chart.yAxis) {
                axis.update(
                    {
                        title: {style: {color: options.fontColor}},
                        labels: {style: {color: options.fontColor}},
                    }
                );
            }
        }
    }

    public static addDataGaps(entities: any, index: number): any[] {
        let previousItem: Object;
        const maxGapSizeInMilliseconds = 7200000; // 2 Hours in Milliseconds
        let gapSizeInMilliseconds = 0;
        let previousItemTimetamp: number;
        const currentItem = entities[index];
        if (index > 0) {
            previousItem = entities[index - 1];
        }

        /* Find the gap between previous and current item based on time in millisecond
      and fill the gap with null value so that chart will render the gap more than 2 hours
    */

        if (previousItem && currentItem) {
            /* Hydrograph Optimizations - Current date/time depth point milliseconds*/
            previousItemTimetamp = new Date(Number(previousItem['x'])).getTime();
            gapSizeInMilliseconds = new Date(Number(currentItem['x'])).getTime() - previousItemTimetamp;
            if (previousItemTimetamp && gapSizeInMilliseconds > maxGapSizeInMilliseconds) {
                entities.splice(index, 0, {
                    x: previousItemTimetamp + 300000,
                    y: null,
                });
            }
        }
        return entities;
    }

    public static clearSessionStorage() {
        sessionStorage.removeItem('dynamicDashboardStartDate');
        sessionStorage.removeItem('dynamicDashboardEndDate');
        sessionStorage.removeItem('dynamicDashboardChartType');
        sessionStorage.removeItem('dynamicDashboardChartLocation');
        sessionStorage.removeItem('dynamicDashboardCustomerId');
        sessionStorage.removeItem('dynamicDashboardLocationId');
        sessionStorage.removeItem('dynamicDashboardDateFormat');
        sessionStorage.removeItem('dynamicDashboardTimeFormat');
        sessionStorage.removeItem('dynamicDashboardHighChartIndex');
        sessionStorage.removeItem('hydrographTimeSlabType');
    }

    public static clearSessionUser(route) {
        sessionStorage.removeItem('firstName');
        sessionStorage.removeItem('lastName');
        sessionStorage.removeItem('userEmail');
        sessionStorage.removeItem('adsRole');
        sessionStorage.removeItem('isActive');
        sessionStorage.removeItem(ADS_TOKEN_KEY);
        localStorage.removeItem(ADS_REFRESH_TOKEN_KEY);
        sessionStorage.setItem('savedRoute', route);
    }

    public static getLocationColors() {
        return (this.locationColors = [
            '#AA00FF',
            '#82B1FF',
            '#0097A7',
            '#69F0AE',
            '#827717',
            '#F57F17',
            '#FF9E80',
            '#A1887F',
            '#D50000',
            '#757575',
            '#37474F',
            '#37286b',
            '#8d1096',
            '#9daf7b',
            '#f9e104',
        ]);
    }

    public static upperCase(value: string) {
        return value ? value.toUpperCase() : value;
    }

    public static applyUniqueAndSort<T>(
        items: Array<any>,
        sortingColumn: string,
        uniqueByColumn: string,
        applyUnique: boolean,
    ) {
        const sortedItems = applyUnique ? uniqBy(items, uniqueByColumn) : items;
        sortedItems.sort((first, second) => {
            if (first[sortingColumn] > second[sortingColumn]) {
                return 1;
            }
            if (first[sortingColumn] < second[sortingColumn]) {
                return -1;
            }
            return 0;
        });
        return sortedItems;
    }

    public static caseInsensitiveSort(a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
    }

    public static removeWhitespace(str) {
        return str.replace(/\s/g, '');
    }
}
