import {
    decodePositiveNumber,
    FormField,
    NonEmptyString,
    PositiveNumber,
    updateFormField,
    validNonEmptyString,
    validNonNegInt,
    validPositiveNumber,
} from 'app/pages/sliicer/shared/utils/composable-validation';
import { isSome, none, Option, some } from 'fp-ts/es6/Option';
import { DesignStorm, DesignStormItem } from 'app/shared/models/sliicer/design-storm';
import { either, Either, right } from 'fp-ts/es6/Either';
import * as E from 'fp-ts/es6/Either';
import * as A from 'fp-ts/es6/Array';
import { sequenceS } from 'fp-ts/es6/Apply';

export type DesignStormsDialogData = DesignStorm[];
export type DesignStormD = DesignStorm & { localId: number };
export type DesignStormItemD = DesignStormItem & { localId: number };

export type ListStateData = {
    storms: DesignStormItemD[];
    someSelected: boolean;
};
export type EditStateData = {
    localId: Option<number>;
    storms: DesignStormD[];
    name: FormField<string, NonEmptyString>;
    peakRain: FormField<string, PositiveNumber>;
    cumulativePeakRain: FormField<string, PositiveNumber>;
    totalStormRain: FormField<string, PositiveNumber>;
    totalEventRain: FormField<string, PositiveNumber>;
};

export type ListState = { view: 'list'; data: ListStateData };
export type EditState = { view: 'edit'; data: EditStateData };

export type State = ListState | EditState;

export const nameChanged: (_: string) => (_: EditState) => EditState = (str) => (state) => ({
    ...state,
    data: { ...state.data, name: updateFormField(str, validNonEmptyString) },
});

export const peakRainChanged: (_: string) => (_: EditState) => EditState = (str) => (state) => ({
    ...state,
    data: { ...state.data, peakRain: updateFormField(str, decodePositiveNumber) },
});

export const cumulativePeakRainChanged: (_: string) => (_: EditState) => EditState = (str) => (state) => ({
    ...state,
    data: { ...state.data, cumulativePeakRain: updateFormField(str, decodePositiveNumber) },
});

export const totalStormRainChanged: (_: string) => (_: EditState) => EditState = (str) => (state) => ({
    ...state,
    data: { ...state.data, totalStormRain: updateFormField(str, decodePositiveNumber) },
});

export const totalEventRainChanged: (_: string) => (_: EditState) => EditState = (str) => (state) => ({
    ...state,
    data: { ...state.data, totalEventRain: updateFormField(str, decodePositiveNumber) },
});

export const stormSelectedChanged: (_: DesignStormItemD, __: boolean) => (_: ListState) => ListState =
    (item, val) => (state) => {
        const storms = A.map((x: DesignStormItemD) =>
            x.localId === item.localId ? { ...x, selected: val } : { ...x },
        )(state.data.storms);
        return { ...state, data: { ...state.data, storms: storms, someSelected: storms.some((x) => x.selected) } };
    };

export function deleteSelectedStorms(state: ListState): ListState {
    return {
        ...state,
        data: {
            ...state.data,
            storms: A.filter<DesignStormItemD>((s) => !s.selected)(state.data.storms),
            someSelected: false,
        },
    };
}

export function initState(storms: DesignStormD[]): State {
    return storms.length > 0 ? initListState(storms) : initEditState(none, storms);
}

export function initListState(storms: DesignStormD[]): ListState {
    return {
        view: 'list',
        data: { storms: storms.map((s) => ({ ...s, selected: false })), someSelected: false },
    };
}

const initNumFormField = (n: number) => ({ raw: n.toString(), val: validPositiveNumber(n) });
const initNameFormField = (name: string) => ({ raw: name, val: validNonEmptyString(name) });

export function initEditState(storm: Option<DesignStormD>, allStorms: DesignStormD[]): EditState {
    if (isSome(storm)) {
        return {
            view: 'edit',
            data: {
                localId: some(storm.value.localId),
                storms: [...allStorms],
                name: initNameFormField(storm.value.name),
                peakRain: initNumFormField(storm.value.peakRain),
                cumulativePeakRain: initNumFormField(storm.value.cumulativePeakRain),
                totalStormRain: initNumFormField(storm.value.totalStormRain),
                totalEventRain: initNumFormField(storm.value.totalEventRain),
            },
        };
    } else {
        return {
            view: 'edit',
            data: {
                localId: none,
                storms: [...allStorms],
                name: initNameFormField(''),
                peakRain: initNumFormField(0),
                cumulativePeakRain: initNumFormField(0),
                totalStormRain: initNumFormField(0),
                totalEventRain: initNumFormField(0),
            },
        };
    }
}

export function stormEditResult(state: EditState): Either<string, DesignStormD[]> {
    const getNewLocalId = (storms: DesignStormD[]): number =>
        storms.reduce((maxId, s) => Math.max(maxId, s.localId), 0) + 1;

    const stormResult: Either<string, DesignStormD> = sequenceS(either)({
        localId: right<string, number>(
            isSome(state.data.localId) ? state.data.localId.value : getNewLocalId(state.data.storms),
        ),
        name: state.data.name.val,
        peakRain: state.data.peakRain.val,
        cumulativePeakRain: state.data.cumulativePeakRain.val,
        totalStormRain: state.data.totalStormRain.val,
        totalEventRain: state.data.totalEventRain.val,
    });

    const mapStormResult = isSome(state.data.localId)
        ? (allStorms: DesignStormD[]) => (storm: DesignStormD) =>
              allStorms.map((s) => (s.localId === storm.localId ? storm : s))
        : (allStorms: DesignStormD[]) => (storm: DesignStormD) => [...allStorms, storm];

    return E.map(mapStormResult(state.data.storms))(stormResult);
}

export function tagDesignStorms(storms: DesignStorm[]): DesignStormD[] {
    return storms.map((x, i) => ({ ...x, localId: i }));
}
