import { OnInit, Component, Input } from '@angular/core';
import { REGEX_CONFIG } from '../../../../utils/regex-utils';
import { FormGroup } from '@angular/forms';
import { VelocityGainTableComponent } from 'app/shared/components/velocity-gain-table/velocity-gain-table.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TritonConfiguration } from 'app/shared/models/monitorsetting';
import {
    BlankingList,
    DirectionList,
    MaxVelocityMetricValues,
    MaxVelocityNonMetricValues,
    MetricBlankingList,
    ModeList,
    PrimarySensors,
    PVTransmitFrequencyList,
    SensitivityList,
    SensorsList,
    SVDurationList,
    SVTransmitFrequencyList,
    VelocityList,
} from 'app/shared/components/location-card/location-card.constants';
import { TranslateService } from '@ngx-translate/core';
import { LocationFormBuilder } from 'app/shared/components/location-card/services/location-form-builder';
import { LocationCardService } from 'app/shared/components/location-card/services/location-card.service';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { UsersService } from 'app/pages/admin/users.service';

@Component({
    selector: 'app-add-edit-location-surface-combo-sensor',
    templateUrl: './add-triton-location-surface-combo-sensor.component.html',
    styleUrls: ['./add-triton-location-surface-combo-sensor.component.scss'],
})
export class AddTritonLocationSurfaceComboSensorComponent implements OnInit {
    @Input() public monitoringPoint;
    @Input() public index: 0 | 1;
    @Input() form: FormGroup;
    @Input() monitorConfigs: TritonConfiguration;

    public modeList = ModeList;
    public sensorsList = SensorsList;
    public blankingList = [];
    public sVTransmitFrequencyList = SVTransmitFrequencyList;
    public svDirectionList = DirectionList(true);
    public sVDurationList = SVDurationList;
    public sensitivityList = SensitivityList;
    public velocityList = VelocityList;
    public pVTransmitFrequencyList = PVTransmitFrequencyList;
    public pvDirectionList = DirectionList(false);
    // Reg Ex. Pattern for numeric -+ Decimal value 2Place
    public negativeWithTwoDecimalPattern = REGEX_CONFIG.negativeWithTwoDecimalPattern;
    public isMonitorEditor = false;
    public isMetric: boolean;
    public maxVelocityValues = [];
    public surfaceMaxVelocityValues = [];

    private formDefaultValues = {
        pressureElectronicOffset: 0,
        pressureCalibration: true,
        pressureStoreData: true,
        velocityFrequency: 0,
        velocitySensitivity: 10,
        velocityDirection: 1,
        velocityGain: 0.9,
        velocityStoreData: true,
        surfaceComboPVmaxVelocity: 0,
        velocityMaximum: 0,
        surfaceVelocityFrequency: 0,
        surfaceVelocitySensitivity: 10,
        surfaceVelocityDirection: 1,
        surfaceVelocityGain: 0.9,
        surfaceVelocityStoreData: true,
        surfaceVelocityScrubbing: true,
        surfaceVelocityDuration: true,
        surfaceComboSVmaxVelocity: 0,
        surfaceVelocityMaximum: 0,
    };
    constructor(
        private matDialog: MatDialog,
        public locationCardService: LocationCardService,
        public translateService: TranslateService,
        private locationFormBuilder: LocationFormBuilder,
        private usersService: UsersService,
    ) {}

    public ngOnInit() {
        this.isMonitorEditor = this.usersService.isMonitorEditor.getValue();
        this.isMetric = this.locationCardService.isMetric;
        this.blankingList = this.isMetric ? MetricBlankingList : BlankingList;
        this.maxVelocityValues = this.isMetric ? MaxVelocityMetricValues() : MaxVelocityNonMetricValues();
        this.surfaceMaxVelocityValues = this.isMetric ? MaxVelocityMetricValues(true) : MaxVelocityNonMetricValues(true);

        const formValue = this.form.getRawValue();

        if (!this.surfaceMaxVelocityValues.find((v) => v.value === formValue.surfaceVelocityMaximum)) {
            formValue.surfaceVelocityMaximum = 0;
        }
        if (!this.maxVelocityValues.find((v) => v.value === formValue.velocityMaximum)) {
            formValue.velocityMaximum = 0;
        }

        if (this.monitorConfigs) {
            const offsetRounder = this.isMetric ? 10 : 100; // for offset values we allow 1 decimal for Metric and 2 for non metric

            formValue.physicalOffset = Math.round(formValue.physicalOffset * offsetRounder) / offsetRounder;
            formValue.pressureElectronicOffset = Math.round(formValue.pressureElectronicOffset * offsetRounder) / offsetRounder;
            formValue.ultrasonicElectronicOffset = Math.round(formValue.ultrasonicElectronicOffset * offsetRounder) / offsetRounder;
        }

        this.form.patchValue({ ...formValue });
    }

    public onToggleChange(event: MatSlideToggleChange, keys: string[]) {
        this.locationFormBuilder.enableDisableControls(this.form, !event.checked, keys);

        if (!event.checked) {
            keys.forEach((v) => {
                this.form.patchValue({ [v]: this.formDefaultValues[v] });
            });
        }
    }
}
