import { Component, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
    templateUrl: './terms-dialog.component.html',
    styleUrls: ['./terms-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsDialogComponent {
    public termsForm = new FormGroup({
        userAgreement: new FormControl(false, Validators.requiredTrue),
        privacyPolicy: new FormControl(false, Validators.requiredTrue),
    });

    constructor(public termsDialog: MatDialogRef<TermsDialogComponent>) {}

    public onClose() {
        this.termsDialog.close({ success: true });
    }
}
