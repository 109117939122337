import { Mode, Type } from 'app/pages/sliicer/sliicer-case-study-details/flow-monitor/basin-qvi-stats/basin-qvi-model';
import { PrecompensationType } from './settings';
import { StormPeriodAdjustment } from 'app/pages/sliicer/sliicer-case-study-details/flow-monitor/flow-monitor.model';

export interface Overrides {
    rainfallMonitorBlockDays?: RainfallMonitorBlockDays[];
    basinStormSettings?: BasinStormSettings[];
    stormSettings?: StormSettings[];
    basinOutliers?: BasinOutlier[];
    addedStormEvents?: StormEventAdjustment[];
    removedStormEvents?: StormEventAdjustment[];
    basinQVsISettings?: BasinQvsISettingsOverrides[];
    basinOverrideArea?: BasinOverrideArea;

    delayNotification?: boolean;
}

export interface BasinQvsISettingsOverrides {
    mode: Mode;
    type: Type;
    useAlt: boolean;
    basinName: string;
    configurationGroup: string;
    configuration: string;
}


export interface RainfallMonitorBlockDays {
    name: string;
    days: string[];
}

export interface BasinStormSettings {
    basinName: string;
    stormId: number;
    precompLength?: number;
    exclude?: boolean;
    precompType?: PrecompensationType;
    season?: string;
    regime?: string;
    altPrecompStart?: string;
    altPrecompEnd?: string;
    deattachPrecomp?: boolean;
}

export interface StormSettings {
    stormId: number;
    stormStartTime?: string;
    stormPeriodLength?: number;
    recovery1PeriodLength?: number;
    recovery2PeriodLength?: number;
    altPrecompStart?: string;
    altPrecompEnd?: string;
    deattachPrecomp?: boolean;
    rainEndTime?: string;
    rainStartTime?: string;
}

export interface StormEventAdjustment {
    stormStartTime: string;
    stormId?: number;
}

export enum OutlierType {
    DRY = 'DRY',
    NONE = 'NONE',
}

export interface OutlierDay {
    date: string;
    type: OutlierType;

    dayOfWeekGroupName?: string;
}

export interface BasinOutlier {
    basinName: string;
    days: OutlierDay[];
}

export enum BasinOverrideArea {
    dryDays = 'BasinOutliers',
    storms = 'BasinStormEvents'
}

export interface OverridesPartialResponse {
    addedStormsAdd: OverridesStormEntry[];
    addedStormsRemove: OverridesStormEntry[];
    basinOutliersAdd: OutlierDay[];
    basinOutliersRemove: OutlierDay[];
    basinQvsISettingsAdd: [];
    basinQvsISettingsRemove: [];
    basinStormSettingsAdd: BasinStormSettings[];
    basinStormSettingsRemove: BasinStormSettings[];
    dryDayOverridesAdd: [];
    dryDayOverridesRemove: [];
    removedStormsAdd: OverridesStormEntry[];
    removedStormsRemove: OverridesStormEntry[];
    stormSettingsAdd: StormPeriodAdjustment[];
    stormSettingsRemove: [];
}

export interface OverridesStormEntry {
    stormStartTime: string;
}