import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Config } from './config';
import { EventFilters, EventModel, EventTree } from '../models/event';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })

export class EventService {
    constructor(public http: HttpClient) {}

    public getAllEvents(cid: number, start: string, end: string): Observable<EventModel[]> {
        return this.http.get<EventModel[]>(Config.urls.getAllEventsbyDateRange + `?cid=${cid}&start=${start}&end=${end}`).pipe(
            map((events) =>
             events ? events.map((ev) => {return {...ev, duration: this.getDuration(ev)}}) : []
             )
        );
    }

    public createEvent(cid: number, event: EventModel) {
        return this.http.post<null>(Config.urls.eventDefinition + '?cid=' + cid, event);
    }

    public deleteEvent(cid: number, guid: string) {
        return this.http.delete(Config.urls.eventDefinition + `?cid=${cid}&guid=${guid}`);
    }

    public getDuration(event: EventModel): string {
        const oneMinute = 1000 * 60;
        const oneHour = oneMinute * 60;

        const { start, end } = event;
        const diff = new Date(end).getTime() - new Date(start).getTime();
        let diffHours = String(Math.floor(diff / oneHour));
        let diffMinutes = String(Math.round((diff - (Number(diffHours) * oneHour)) / oneMinute));

        if (diffMinutes === '60') {
            diffHours = String(Number(diffHours) + 1);
            diffMinutes = '00';
        }

        return `${this.formatSingleCharNumber(diffHours)}:${this.formatSingleCharNumber(diffMinutes)}`;
    }

    public buildEventsTree(events: EventModel[], parentId: string = null): EventTree[] {
        if (parentId) {
            return events.filter(v => v.parent === parentId);
        }

        events.forEach((event: EventModel) => {
            if (event.children) {
                event.children = this.buildEventsTree(events, event.guid);
            }
        });

        return events.filter(v => !v.parent);
    }

    public getParentsForEvent(event: EventModel, allEvents: EventModel[], result: EventModel[] = []) {
        if (!event.parent) {
            return result;
        }

        const parent = allEvents.find(v => v.guid === event.parent);

        return parent ? this.getParentsForEvent(parent, allEvents, [...result, parent]) : result;
    }

    public getAllRelatedEventsToCurrent(event: EventModel, allEvents: EventModel[], eventTree: EventTree[]): EventModel[] {
        let resultEvents: EventModel[] = [];
        const parents = this.getParentsForEvent(event, allEvents);
        const rootEvent = parents.length ? parents[parents.length - 1] : event;
        const rootFromTree = eventTree.find(v => v.guid === rootEvent.guid);

        if (!rootFromTree) {
            resultEvents = [...parents, event];
        } else {
            resultEvents = [rootEvent, ...this.getEventChildrenFromTree(rootFromTree, allEvents)]
        }

        resultEvents = resultEvents.filter((event: EventModel, index: number) => index === resultEvents.findIndex(v => event.guid === v.guid));

        return resultEvents;
    }

    public getEventChildrenFromTree(event: EventTree, allEvents: EventModel[], result: EventModel[] = []): EventModel[] {
        if (!event.children || event.children.length === 0) {
            return result;
        }

        event.children.forEach((child: EventModel | string) => {
            let childFromList: EventModel;

            if (typeof child === 'string') {
                childFromList = allEvents.find(v => v.guid === child);
            } else {
                childFromList = allEvents.find(v => v.guid === child.guid);
            }

            if (!childFromList) return;

            result.push(childFromList);

            if (childFromList.children && childFromList.children.length > 0) {
                result.push(...this.getEventChildrenFromTree(childFromList, allEvents, result));
            }
        });

        return result;
    }

    public formatSingleCharNumber(value: string) {
        return value.length > 1 ? value : '0' + value;
    }

    public checkIfEventWithinDateRange(event: EventModel, start: Date, end: Date): boolean {
        return new Date(event.start).getTime() >= new Date(start).getTime()
        && new Date(event.end).getTime() <= new Date(end).getTime();
    }

    public checkIfEventWithinDuration(event: EventModel, filterOptions: EventFilters): boolean {
        const { durationEndHours: eh, durationEndMinutes: em, durationStartHours: sh, durationStartMinutes: sm } = filterOptions;
        const duration = event.duration ? event.duration : this.getDuration(event);

        if(!duration || duration === 'NaN:NaN') return true;
        if(eh && eh === '00' && em && em === '00' && sh && sh === '00' && sm && sm === '00') return true;

        const [d, hours, mins] = duration.match(/(\d+)\:(\d+)/);

        if (eh === undefined) {
            return true;
        }

        return ((+hours < +eh && +hours > +sh) || (+hours === +sh && +mins >= +sm) || (+hours === +eh && +mins <= +em));
    }
}
