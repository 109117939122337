import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    OnChanges,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    ViewEncapsulation,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatLegacyMenu as MatMenu, MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { StatusCodeService } from '../../../shared/services/status-code.service';
import moment from 'moment';
import { combineLatest } from 'rxjs';
import { QUICK_DATE_RANGES } from 'app/shared/models/view-data';

// TODO: We have two very similar components - other one is date-range-picker.component. UNIFY THEM IN FUTURE ! Both marked with TODO:
@Component({
    selector: 'ads-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class DatePickerComponent implements OnInit, OnChanges {
    @Input() public label: string;
    @Input() public single = false;
    @Input() public datePickerType = 'date';
    @Input() public buttonClasses = [];
    @Input() public type = 'date';
    @Input() public hideInput: boolean;
    @Input() public disableInputs = false;
    @Input() public disabled: boolean;
    @Input() public startFromMonth: boolean;
    @Input() public defaultTimeSpan?: QUICK_DATE_RANGES;

    @Input() public minDate: Date;
    @Input() public maxDate: Date;

    @Input() public showQuickRanges = true;
    @Input() public quickOneDay = true;
    @Input() public quickOneWeek = true;
    @Input() public quickLastMonth = true;
    @Input() public quickOneMonth = true;
    @Input() public quickThreeMonths = true;
    @Input() public quickSixMonths = true;
    @Input() public quickOneYear = true;
    @Input() public oneWeekDoNotIncludeToday = false;

    /** Maximum range in days, that is allowed */
    @Input() public maxDayRange?: number = null;

    @Input() public selectedTimespan: QUICK_DATE_RANGES = QUICK_DATE_RANGES.LAST_WEEK;
    @Output() public selectedTimespanChange = new EventEmitter<QUICK_DATE_RANGES>();

    @Output() public selectedStartDate = new EventEmitter<Date>();
    @Output() public selectedEndDate = new EventEmitter<Date>();
    @Output() public isValidDateRange = new EventEmitter<boolean>();
    @Output() public isDateChanged = new EventEmitter<boolean>();
    @Output() public selectionChange = new EventEmitter<any>();
    @Output() public emitClose = new EventEmitter();
    @ViewChild('trigger') public menuTrigger: MatMenuTrigger;
    @ViewChild('dateRangeMenu', { static: true }) public dateRangeMenu: MatMenu;

    @Input() public startDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 7,
        0,
        0,
        0,
    );
    @Input() public endDate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        new Date().getHours(),
        new Date().getMinutes(),
        new Date().getSeconds(),
    );

    public displayStartDateErrMsg: boolean;
    public displayEndDateErrMsg: boolean;
    public invalidDateRange: boolean;
    public isCustomDateTouched: boolean;
    public invalidStartDate: boolean;
    public invalidEndDate: boolean;
    public invalidRange: boolean;
    public customerDateFormat: string;
    public customerMomentDateFormat: string;
    public entitySelectValidationError: string;
    public QUICK_DATE_RANGES = QUICK_DATE_RANGES;

    public oneDayAvailable = false;
    public oneWeekAvailable = false;
    public lastMonthAvailable = false;
    public oneMonthAvailable = false;
    public threeMonthsAvailable = false;
    public sixMonthsAvailable = false;
    public oneYearAvailable = false;

    /** Start date displayed in component view */
    public displayStartDate = this.startDate;
    /** End date displayed in component view */
    public displayEndDate = this.endDate;
    /** Old value of start date */
    public oldStartDate = this.startDate;
    /** Old value of end date */
    public oldEndDate = this.endDate;

    constructor(
        private cdr: ChangeDetectorRef,
        private uiUtilService: UiUtilsService,
        private translate: TranslateService,
        private dateutilService: DateutilService,
        private statusCodeService: StatusCodeService,
    ) {
        translate.get('COMMON.ENTITY_SELECT_VALIDATION').subscribe((res: string) => {
            this.entitySelectValidationError = res;
        });
    }
    /**
     * Component life cycle hook
     */
    public ngOnInit() {
        this.dateRangeMenu.closed.subscribe(() => {
            this.emitClose.emit();
        });

        if (this.hideInput) {
            this.openMenu();
        }

        if (this.defaultTimeSpan) {
            this.getDataForSetTimeSpan(this.defaultTimeSpan);
        } else {
            this.displayStartDate = this.startDate;
            this.displayEndDate = this.endDate;
        }

        this.setAvailableQuickOptions();

        combineLatest([this.dateutilService.dateFormat, this.dateutilService.timeFormat]).subscribe(
            ([newDateFormat, newTimeFormat]) => {
                const is12HourFormat = this.dateutilService.timeFormat.getValue() !== 'hh:mm:ss';
                // Getting customer dateformat from dateUtil Service
                this.customerDateFormat =
                    this.type === 'datetime'
                        ? `${String(this.dateutilService.getFormat())} ${is12HourFormat ? 'hh:mm:ss a' : 'HH:mm:ss'}`
                        : this.dateutilService.getFormat();

                this.customerMomentDateFormat =
                    this.type === 'datetime'
                        ? `${String(this.dateutilService.getFormat().toUpperCase())} ${
                              is12HourFormat ? 'hh:mm:ss a' : 'HH:mm:ss'
                          }`
                        : this.dateutilService.getFormat().toUpperCase();
                this.uiUtilService.safeChangeDetection(this.cdr);
            },
        );
        this.onLoadDateSet();
    }

    private setAvailableQuickOptions() {
        const isDataInRange = ({ startDate, endDate }) => {
            return (
                !this.minDate ||
                !this.maxDate ||
                moment(this.minDate).isBetween(startDate, endDate, undefined, '[]') ||
                moment(this.maxDate).isBetween(startDate, endDate, undefined, '[]') ||
                (moment(startDate).isBetween(this.minDate, this.maxDate, undefined, '[]') &&
                    moment(endDate).isBetween(this.minDate, this.maxDate, undefined, '[]'))
            );
        };

        // Only enable ranges where there's some data
        this.oneDayAvailable = this.quickOneDay && isDataInRange(this.getTimespan(QUICK_DATE_RANGES.TODAY));
        this.oneWeekAvailable = this.quickOneWeek && isDataInRange(this.getTimespan(QUICK_DATE_RANGES.LAST_WEEK));
        this.lastMonthAvailable =
            this.quickLastMonth && isDataInRange(this.getTimespan(QUICK_DATE_RANGES.PREVIOUS_MONTH));
        this.oneMonthAvailable = this.quickOneMonth && isDataInRange(this.getTimespan(QUICK_DATE_RANGES.LAST_MONTH));
        this.threeMonthsAvailable =
            this.quickThreeMonths && isDataInRange(this.getTimespan(QUICK_DATE_RANGES.LAST_THREE_MONTHS));
        this.sixMonthsAvailable =
            this.quickSixMonths && isDataInRange(this.getTimespan(QUICK_DATE_RANGES.LAST_SIX_MONTHS));
        this.oneYearAvailable = this.quickOneYear && isDataInRange(this.getTimespan(QUICK_DATE_RANGES.LAST_YEAR));
    }

    public openMenu() {
        this.menuTrigger.openMenu();
        this.maxDate = new Date();
    }

    public closeMenu() {
        this.menuTrigger.closeMenu();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.defaultTimeSpan && this.defaultTimeSpan) {
            if (this.defaultTimeSpan) {
                this.getDataForSetTimeSpan(this.defaultTimeSpan);
            }
        }

        this.onLoadDateSet();
    }
    public onLoadDateSet() {
        this.maxDate = new Date();
        if (!this.startDate) {
            this.startDate = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate() - 7,
                0,
                0,
                0,
            );
            this.selectedStartDate.emit(this.startDate);
        }
        if (!this.endDate) {
            this.endDate = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                new Date().getHours(),
                new Date().getMinutes(),
                new Date().getSeconds(),
            );
            this.selectedEndDate.emit(this.endDate);
        }

        this.uiUtilService.safeChangeDetection(this.cdr);
    }

    private dateCheck = (d: Date) => d instanceof Date && !isNaN(d.getTime());

    public monthDifference(startDate: Date, endDate: Date): void {
        if (!this.dateCheck(startDate)) {
            this.invalidStartDate = true;
            this.selectedStartDate.emit(null);
            this.isDateChanged.emit(true);
            return;
        } else {
            this.displayStartDate = startDate;
        }
        this.invalidStartDate = false;

        if (!this.dateCheck(endDate)) {
            this.invalidEndDate = true;
            this.selectedEndDate.emit(null);
            this.isDateChanged.emit(true);
            return;
        } else {
            this.displayEndDate = endDate;
        }
        this.invalidEndDate = false;

        // ensure end date is greater
        if (endDate < startDate) {
            this.invalidEndDate = true;
            this.isValidDateRange.emit(false);
            return;
        }

        const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        if (this.maxDayRange) {
            if (diffDays > this.maxDayRange) {
                this.invalidRange = true;
                return;
            } else {
                this.invalidRange = false;
            }
        }

        this.startDate = startDate;
        this.selectedStartDate.emit(this.startDate);

        this.endDate = endDate;
        this.selectedEndDate.emit(this.endDate);

        this.isDateChanged.emit(true);
        this.isValidDateRange.emit(true);

        this.oldStartDate = this.startDate;
        this.oldEndDate = this.endDate;
    }

    /** Method to validate start date from datepicker and validate months Differnce */
    public checkStartDateChange(val: Date) {
        this.displayStartDateErrMsg = false;

        this.monthDifference(val, this.displayEndDate);

        this.isCustomDateTouched = true;
    }

    public inputCheckStartDateChange(event: Event) {
        if (!(event.target as HTMLInputElement).value) {
            this.displayStartDateErrMsg = true;
            this.isValidDateRange.emit(false);
            this.uiUtilService.safeChangeDetection(this.cdr);
            return;
        }

        const date = moment((event.target as HTMLInputElement).value, this.customerMomentDateFormat);

        this.checkStartDateChange(date.isValid ? date.toDate() : new Date('Invalid date'));
    }

    public calendarCheckStartDateChange(event: MatDatepickerInputEvent<Date>) {
        if (!event.value) {
            this.displayStartDateErrMsg = true;
            this.isValidDateRange.emit(false);
            this.uiUtilService.safeChangeDetection(this.cdr);
            return;
        }
        const date = moment(event.value, this.customerMomentDateFormat);
        this.checkStartDateChange(date.toDate());
    }

    /** Method to validate end date from datepicker and validate months Differnce */
    public checkEndDateChange(val: Date) {
        this.displayEndDateErrMsg = false;

        this.monthDifference(this.displayStartDate, val);

        this.isCustomDateTouched = true;
    }

    public inputCheckEndDateChange(event: Event) {
        if (!(event.target as HTMLInputElement).value) {
            this.displayEndDateErrMsg = true;
            return;
        }

        const date = moment((event.target as HTMLInputElement).value, this.customerMomentDateFormat);

        this.checkEndDateChange(date.isValid ? date.toDate() : new Date('Invalid date'));
    }

    public calendarCheckEndDateChange(event: MatDatepickerInputEvent<Date>) {
        if (!event.value) {
            this.displayEndDateErrMsg = true;
            this.isValidDateRange.emit(false);
            this.uiUtilService.safeChangeDetection(this.cdr);
            return;
        }
        const date = moment(event.value, this.customerMomentDateFormat);
        this.checkEndDateChange(date.toDate());
    }

    public checkDateValidation() {
        if (this.isCustomDateTouched) {
            // this.notifyParentComponent();
            this.selectionChange.emit({
                startDate: this.startDate,
                endDate: this.endDate,
            });
            this.uiUtilService.safeChangeDetection(this.cdr);
            this.isCustomDateTouched = false;
        }
    }

    private getTimespan(timespan: QUICK_DATE_RANGES) {
        const today = new Date();
        switch (timespan) {
            case 'today': {
                return {
                    startDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 0, 0, 0),
                    endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 23, 59, 59),
                };
            }
            case 'lastWeek': {
                return {
                    startDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7, 0, 0, 0),
                    endDate: new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate() - (this.oneWeekDoNotIncludeToday ? 1 : 0),
                        23,
                        59,
                        59,
                    ),
                };
            }
            case 'lastMonth': {
                return {
                    startDate: new Date(today.getFullYear(), today.getMonth() - 1, 1, 0, 0, 0),
                    endDate: new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        this.getDaysInMonth(today.getMonth() + 1, today.getFullYear()),
                        23,
                        59,
                        59,
                    ),
                };
            }
            case 'lastMonth': {
                return {
                    startDate: new Date(today.getFullYear(), today.getMonth() - 1, today.getDate(), 0, 0, 0),
                    endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59),
                };
            }
            case 'previousMonth': {
                return {
                    startDate: new Date(
                        today.getFullYear() - (today.getMonth() > 0 ? 0 : 1),
                        (today.getMonth() - 1 + 12) % 12,
                        1,
                    ),
                    endDate: new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, -1),
                };
            }
            case 'lastThreeMonths': {
                return {
                    startDate: new Date(today.getFullYear(), today.getMonth() - 3, today.getDate(), 0, 0, 0),
                    endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59),
                };
            }
            case 'lastSixMonths': {
                return {
                    startDate: new Date(today.getFullYear(), today.getMonth() - 6, today.getDate(), 0, 0, 0),
                    endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59),
                };
            }
            case 'lastYear': {
                return {
                    startDate: new Date(today.getFullYear(), today.getMonth() - 12, today.getDate(), 0, 0, 0),
                    endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59),
                };
            }
            default: {
                break;
            }
        }
    }

    private getDaysInMonth(m: number, y: number) {
        return /8|3|5|10/.test(m.toString()) ? 30 : m == 1 ? ((!(y % 4) && y % 100) || !(y % 400) ? 29 : 28) : 31;
    }

    public getDataForSetTimeSpan(timespan: QUICK_DATE_RANGES, event?: Event) {
        if (this.hideInput && event) {
            event.stopPropagation();
        }
        const today = new Date();
        this.endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
        if (timespan === QUICK_DATE_RANGES.TODAY) {
            this.statusCodeService.setQuickRangeInDateFilter.next(0);
            this.startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 0, 0, 0);
            this.endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 23, 59, 59);
            this.statusCodeService.timeInterval.next(QUICK_DATE_RANGES.TODAY);
        } else if (timespan === QUICK_DATE_RANGES.LAST_WEEK) {
            this.statusCodeService.setQuickRangeInDateFilter.next(1);
            this.startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7, 0, 0, 0);
            if (this.oneWeekDoNotIncludeToday) {
                this.endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 23, 59, 59);
            }
            this.statusCodeService.timeInterval.next(QUICK_DATE_RANGES.LAST_WEEK);
        } else if (timespan === QUICK_DATE_RANGES.LAST_MONTH) {
            this.statusCodeService.setQuickRangeInDateFilter.next(2);
            this.startDate = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate(), 0, 0, 0);
            this.statusCodeService.timeInterval.next(QUICK_DATE_RANGES.LAST_MONTH);
        } else if (timespan === QUICK_DATE_RANGES.PREVIOUS_MONTH) {
            this.statusCodeService.setQuickRangeInDateFilter.next(2);
            this.startDate = new Date(
                today.getFullYear() - (today.getMonth() > 0 ? 0 : 1),
                (today.getMonth() - 1 + 12) % 12,
                1,
            );
            this.endDate = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, -1);
            this.statusCodeService.timeInterval.next(QUICK_DATE_RANGES.PREVIOUS_MONTH);
        } else if (timespan === QUICK_DATE_RANGES.LAST_THREE_MONTHS) {
            this.statusCodeService.setQuickRangeInDateFilter.next(3);
            this.startDate = new Date(today.getFullYear(), today.getMonth() - 3, today.getDate(), 0, 0, 0);
            this.statusCodeService.timeInterval.next(QUICK_DATE_RANGES.LAST_THREE_MONTHS);
        } else if (timespan === QUICK_DATE_RANGES.LAST_SIX_MONTHS) {
            this.statusCodeService.setQuickRangeInDateFilter.next(4);
            this.startDate = new Date(today.getFullYear(), today.getMonth() - 6, today.getDate(), 0, 0, 0);
            this.statusCodeService.timeInterval.next(QUICK_DATE_RANGES.LAST_SIX_MONTHS);
        } else if (timespan === QUICK_DATE_RANGES.LAST_YEAR) {
            this.statusCodeService.setQuickRangeInDateFilter.next(5);
            this.startDate = new Date(today.getFullYear(), today.getMonth() - 12, today.getDate(), 0, 0, 0);
            this.statusCodeService.timeInterval.next(QUICK_DATE_RANGES.LAST_YEAR);
        }

        this.invalidRange = false;
        this.invalidEndDate = false;
        this.invalidStartDate = false;

        this.selectedTimespan = timespan;
        this.selectedTimespanChange.emit(this.selectedTimespan);

        this.displayStartDate = this.startDate;
        this.displayEndDate = this.endDate;

        this.invalidDateRange = false;
        this.invalidStartDate = false;
        this.invalidEndDate = false;

        this.selectedStartDate.emit(this.startDate);
        this.selectedEndDate.emit(this.endDate);
        this.isDateChanged.emit(true);
        this.isValidDateRange.emit(true);
        this.selectionChange.emit({
            startDate: this.startDate,
            endDate: this.endDate,
        });

        if (event) {
            this.closeMenu();
        }
    }
}
