import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';
import { SharedService } from 'app/shared/services/shared.service';
import { VaultUpload, VaultUploadResult } from 'app/shared/models/vault';
import { IDataTransfer } from 'app/shared/models/modal';

/**
 * Represnts a reusable upload dialog component.
 * @description
 * This component requires the IUploadDatum to be populated with
 * the URI of where the uploads are to go.
 *
 * It is to be invoked by using the Dialog Reference:
 * {@example
 * this.dialog.open(FileUploadDialogComponent, {
 *     data: <IUploadDatum>{
 *       uri: Config.urls.vaultUpload
 *     }
 *  });
 * }
 * See {@linkDocs https://material.angular.io/components/dialog/overview}
 */
@Component({
    selector: 'app-vault-upload-dialog-local',
    templateUrl: './vault-upload-dialog.component-local.html',
    styleUrls: ['./vault-upload-dialog.component-local.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class VaultUploadDialogLocalComponent implements OnInit {
    private static TextCSV = 'text/csv';
    private static Excel = 'application/vnd.ms-excel';

    /**
     * Represents the third party uploading library.
     */
    public uploader: FileUploader;

    /**
     * Indicates whether a file over event has been performed.
     */
    public hasBaseDropZoneOver = false;

    /**
     * Indicates whether a file drop event has been performed.
     */
    public hasFileDropped = false;

    /**
     * Flag to check if uploaded file type is accepted CSV or not
     */
    public isWrongFileTypeCSV = false;

    /**
     * Flag to check if uploaded file type is accepted or not
     */
    public isWrongFileType = false;

    /*
     * Represents whether progress bar is showing or not.
     */
    public showProgress = false;

    /**
     * Represents the collection of files that will be uploaded.
     */
    public overwritableFiles: Array<VaultUploadResult>;

    /**
     * file Upload display message on popup
     */
    public displayMessage: string;

    public isWrongFileTypeCSVCopy = false;

    /**
     * Flag to check if uploaded file type is accepted or not
     */
    public isWrongFileTypeCopy = false;
    public response: string;

    private duration = {
        '5-min': 5,
        '10-min': 10,
        '15-min': 15,
        '20-min': 20,
        '30-min': 30,
        '60-min': 60,
        '2-hr': 120,
        '3-hr': 180,
        '6-hr': 360,
        '12-hr': 720,
        '24-hr': 1440,
        '2-day': 2880,
        '3-day': 4320,
        '4-day': 5760,
        '7-day': 10080,
        '10-day': 14400,
        '20-day': 28800,
        '30-day': 43200,
        '45-day': 63000,
        '60-day': 86400,
    };

    constructor(
        private dialogRef: MatDialogRef<VaultUploadDialogLocalComponent>,
        @Inject(MAT_DIALOG_DATA) private dialogData: IDataTransfer,
        private sharedService: SharedService,
    ) {
        // by default set to empty the uploaded popup text message
        this.sharedService.setFileUploadMessage('');
    }

    /**
     * Framework level licecycle hook.
     */
    public ngOnInit(): void {
        const uri = '/'; // this.dialogData.id;

        this.uploader = new FileUploader(<FileUploaderOptions>{
            url: uri,
        });

        // set file upload message
        this.sharedService.currentMessage.subscribe((response) => (this.displayMessage = response)).unsubscribe();
    }

    /**
     * Method to check file extension and showing error on wrong file type
     * @param item file to be uploaded
     * @param filter file uploader filters
     */
    public onWhenAddingFileFailed(item: any, filter: any) {
        if (filter.name === 'mimeType') {
            this.isWrongFileTypeCSV = true;
        } else if (filter.name === 'fileType') {
            this.isWrongFileType = true;
        }
        this.hasFileDropped = false;
        return;
    }

    /**
     * Handles the drag-over event for the target area.
     * @param isFileOver Indicates whether a file is over the drop zone.
     */
    public fileOverBase(isFileOver: boolean): void {
        // set the state of hover
        this.hasBaseDropZoneOver = isFileOver;
    }

    /**
     * Handles the drop files event.
     * @param files Represents the dropped files.
     */
    public fileDrop(files: FileList): void {
        if (files == null || this.isWrongFileTypeCSV || this.isWrongFileType) {
            this.isWrongFileTypeCSVCopy = this.isWrongFileTypeCSV;
            this.isWrongFileTypeCSV = false;
            this.isWrongFileTypeCopy = this.isWrongFileType;
            this.isWrongFileType = false;
            return;
        }

        // reset the current state
        this.resetState();

        // set a value indicating whether
        const hasFiles = files.length > 0;

        // set files dropped
        this.hasFileDropped = hasFiles;

        const filesColleciton = new Array<File>();

        // convert FileList to Array<File> so that uploader can use
        for (let i = 0; i < files.length; i++) {
            filesColleciton.push(files.item(i));
        }

        // start handling overwrites
        this.handleOverwrite(files);
    }

    /**
     * Handles the change event for manually selecting files.
     * @param event Represents the input elemetn change event.
     */
    public fileSelectChangeHandler(event: MouseEvent): void {
        // reset the current state
        this.resetState();

        // set files dropped since we have files that were added
        this.hasFileDropped = true;

        // get the reference to the target element
        const inputElement = <HTMLInputElement>event.target;

        const files: FileList = inputElement.files;

        // ensure that there are files, if not, reset state and exit immediatly
        if (files.length < 1) {
            return this.resetState();
        }

        this.handleOverwrite(files);

        // reset the value of the input element so as to allow for retry of the same file upload
        inputElement.value = null;
    }

    /**
     * Resets the common state of the file upload variables.
     */
    public resetState(): void {
        // reset drop state
        this.hasFileDropped = false;
    }

    private isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    private handleOverwrite(files: FileList): void {
        // make sure we only handle the type of file that we have
        // been asked to.
        if (this.dialogData.fileType !== files[0].type) {
            // if we are being asked for CSVs then we can make an extra check
            if (this.dialogData.fileType === VaultUploadDialogLocalComponent.TextCSV) {
                // HACK: If the file type is Excel, then let's go ahead and treat
                // as CSV if the name ends in '.csv'
                if (files[0].type === VaultUploadDialogLocalComponent.Excel && files[0].name.endsWith('.csv')) {
                } else {
                    this.isWrongFileTypeCSVCopy = true;
                    this.isWrongFileTypeCSV = true;
                    this.resetState();
                    return;
                }
            } else {
                this.isWrongFileTypeCopy = true;
                this.isWrongFileType = true;
                this.resetState();
                return;
            }
        }

        const that = this;
        const reader = new FileReader();
        reader.readAsText(files[0], 'UTF-8');
        reader.onload = function () {
            const str = reader.result;
            const lines = (str as string)
                .replace(/[,]{2,}/g, '')
                .trim()
                .split('\n');

            const result = [];

            for (let i = 0; i < lines.length; i++) {
                if (that.isNumeric(lines[i].charAt(0))) {
                    const obj = {};
                    const currentline = lines[i].split(',');
                    (obj['durationMinutes'] = that.duration[currentline[0].slice(0, -1)]), (obj['month1'] = null);
                    obj['month2'] = null;
                    obj['month3'] = null;
                    obj['month4'] = null;
                    obj['month6'] = null;
                    obj['month9'] = null;
                    obj['year1'] = Number(currentline[1]).toFixed(2);
                    obj['year2'] = Number(currentline[2]).toFixed(2);
                    obj['year5'] = Number(currentline[3]).toFixed(2);
                    obj['year10'] = Number(currentline[4]).toFixed(2);
                    obj['year25'] = Number(currentline[5]).toFixed(2);
                    obj['year50'] = Number(currentline[6]).toFixed(2);
                    obj['year100'] = Number(currentline[7]).toFixed(2);
                    obj['year200'] = Number(currentline[8]).toFixed(2);
                    obj['year500'] = Number(currentline[9]).toFixed(2);
                    obj['year1000'] = Number(currentline[10]).toFixed(2);

                    result.push(obj);
                }
            }

            that.response = JSON.stringify({
                ddfTable: result,
            });
            that.handleModalClose();
        };
    }

    /**
     * Hanldes the cleanup of the dialog window.
     * @param files the file collection that was submitted for uploading.
     */
    private handleModalClose(): void {
        // close the dialog
        const vaultUpload: VaultUpload = {
            response: this.response,
        };
        this.dialogRef.close(vaultUpload);

        this.resetState();
    }
}
