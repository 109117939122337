<div id="addschedule">
    <app-loader [isLoading]="collectionScheduleLoadingState"></app-loader>
    <div class="popupHeader" mat-dialog-title>
        <span>{{ (data.isEditMode ? 'COMMON.EDIT_SCHEDULE_TEXT' : 'COMMON.ADD_SCHEDULE_TEXT') | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <form #scheduleCollectionForm="ngForm">
        <!-- content -->
        <div mat-dialog-content [ngClass]="{ 'hint-dialog-background': showpageHint }">
            <div class="custom-error">
                <mat-form-field class="full-width top">
                    <input
                        matInput
                        type="name"
                        id="scheduleName"
                        placeholder="{{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.NAME' | translate }}"
                        maxlength="100"
                        [(ngModel)]="scheduleName"
                        name="scheduleName"
                        #addScheduleNameInput="ngModel"
                        (ngModelChange)="validateScheduleNameField()"
                        required
                        [disabled]="showpageHint"
                        [ngClass]="{ 'dialog-name-hint': showpageHint }"
                    />
                </mat-form-field>
                <div class="custom-error-message">
                    <span *ngIf="isDuplicateScheduleName">{{
                        'HOME.WIDGET_SCHEDULE.SCHEDULE_NAME_EXISTS' | translate
                    }}</span>
                    <span *ngIf="addScheduleNameInput?.errors?.maxlength">{{
                        'HOME.WIDGET_SCHEDULE.SCHEDULE_NAME_LENGTH_VALIDATION' | translate
                    }}</span>
                </div>
            </div>
            <div class="location-group-space" [ngClass]="{ 'hide-scroll-on-hint': showpageHint }">
                <div class="search-section">
                    <mat-form-field class="full-width" [ngClass]="{ 'dialog-search-hint': showpageHint }">
                        <mat-icon matPrefix class="search-icon">search</mat-icon>
                        <input
                            matInput
                            [disabled]="showpageHint"
                            type="text"
                            (input)="locationSetSearchAndGroup()"
                            [(ngModel)]="searchText"
                            name="search"
                            placeholder="{{ 'COMMON.SEARCH_LOC_PLACEHOLDER' | translate }}"
                        />
                    </mat-form-field>
                </div>
                <div [ngClass]="{ 'dialog-locations-hint': showpageHint }">
                    <div class="select-all pad-l-5">
                        <mat-checkbox
                            [checked]="selectAll"
                            [disabled]="areAllLocationsScheduled || showpageHint"
                            class="eachItem auto-margin"
                            ngDefaultControl
                            (change)="toggleSelectAll()"
                            name="selectAll"
                        >
                            {{ 'COMMON.SELECT_All_OPTION' | translate }}
                        </mat-checkbox>
                    </div>
                    <div class="selection-options">
                        <div class="select-choices" *ngFor="let location of filteredLocations" [value]="location.id">
                            <mat-checkbox
                                (change)="changeSelecteState(location)"
                                [disabled]="showpageHint"
                                [checked]="location.isChecked"
                                >{{ location.name }}</mat-checkbox
                            >
                        </div>
                    </div>
                </div>
            </div>
            <!-- select days -->
            <h4 class="no-margin text-top-space">{{ 'LOCATION_DASHBOARD.ADD_SCHEDULE.SELECT_DAYS' | translate }} *</h4>
            <div class="days-container" [ngClass]="{ 'dialog-days-hint': showpageHint }">
                <mat-checkbox
                    *ngFor="let day of listOfDays"
                    [disabled]="showpageHint"
                    [checked]="day.isSelected"
                    (change)="validateDateSelection(day, day.isSelected)"
                    name="chkboxday"
                    required
                >
                    <div class="each-day">{{ day.text }}</div>
                </mat-checkbox>
            </div>
            <div class="custom-50p-group">
                <div class="each-containt custom-error">
                    <mat-form-field class="full-width" [ngClass]="{ 'dialog-collect-hint': showpageHint }">
                        <span>
                            <input
                                matInput
                                [value]="collectTime"
                                [disabled]="showpageHint"
                                (change)="checkTimeValue($event)"
                                placeholder="{{ 'LOCATION_DASHBOARD.ADD_SCHEDULE.COLLECT_TIME' | translate }}"
                                tabindex="-1"
                                class="datepickerStyles"
                            />
                        </span>
                        <input
                            matInput
                            hidden
                            [matDatetimepicker]="collectTimePicker"
                            [value]="collectTime"
                            (dateInput)="onDateInput($event)"
                            (dateChange)="onDateChange($event)"
                            placeholder="{{ 'LOCATION_DASHBOARD.ADD_SCHEDULE.COLLECT_TIME' | translate }}"
                            tabindex="-1"
                            appDatetimepickerPosition
                            [matDatetimepickerComp]="collectTimePicker"
                        />
                        <mat-datetimepicker-toggle
                            matSuffix
                            [for]="collectTimePicker"
                            [disabled]="showpageHint"
                        ></mat-datetimepicker-toggle>
                        <mat-datetimepicker
                            #collectTimePicker
                            type="time"
                            [disabled]="showpageHint"
                        ></mat-datetimepicker>
                    </mat-form-field>
                    <div class="custom-error-message" style="margin-left: 15px">
                        <span *ngIf="timeError">{{ 'HOME.WIDGET_SCHEDULE.INVALID_TIME' | translate }}</span>
                    </div>
                </div>
                <div class="each-containt">
                    <mat-form-field class="full-width" [ngClass]="{ 'dialog-frequency-hint': showpageHint }">
                        <mat-select
                            [disabled]="showpageHint"
                            [(ngModel)]="frequency"
                            name="frequencyDropdown"
                            placeholder="{{ 'LOCATION_DASHBOARD.ADD_SCHEDULE.FREQUENCIES' | translate }}"
                            required
                        >
                            <mat-option *ngFor="let frequency of frequenciesList" [value]="frequency.value">
                                {{ frequency.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-containt">
                    <app-auto-complete
                        [ngClass]="{ 'dialog-event-hint': showpageHint }"
                        [items]="eventFrequenciesList"
                        [disabled]="showpageHint"
                        [strict]="true"
                        [preselectedItem]="collectionAlarmingFrequency"
                        placeholder="{{ 'LOCATION_DASHBOARD.ADD_SCHEDULE.EVENT_FREQUENCIES' | translate }}"
                        [isShowAll]="false"
                        (selectedItems)="alarmingFrequencySelected($event)"
                    >
                    </app-auto-complete>
                </div>
            </div>
        </div>
    </form>
    <!-- add schdule button -->
    <div mat-dialog-actions [ngClass]="{ 'hint-dialog-background': showpageHint }">
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button
                mat-button
                [disabled]="showpageHint"
                [ngClass]="{ 'dialog-cancel-hint': showpageHint }"
                (click)="close(false)"
            >
                {{ 'COMMON.CANCEL_BUTTON' | translate }}
            </button>
        </div>
        <div class="auto-margin">
            <button
                mat-button
                (click)="saveScheduleCollection()"
                [ngClass]="{ 'dialog-save-hint': showpageHint }"
                [disabled]="
                    showpageHint ||
                    isDuplicateScheduleName ||
                    disableSave ||
                    !scheduleCollectionForm.valid ||
                    (scheduleCollectionForm.pristine && isFormPristine) ||
                    timeError
                "
            >
                {{ 'COMMON.SAVE_BUTTON' | translate }}
            </button>
        </div>
    </div>
</div>
