import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'addUnits',
    pure: false,
})
export class AddUnitsPipe implements PipeTransform {
    // Accepts formgroup object and returns the count of formcontrols required
    public transform(placeholder: string, units: string) {
        return `${placeholder} (${units})`;
    }
}
