<div id="uptimeReportId" class="contentLayout">
    <mat-card>
        <app-loader [isLoading]="uptimeLoadingState"></app-loader>

        <mat-card-header>
            <div class="auto-margin">
                <mat-icon
                    class="backIcon"
                    [routerLink]="['/pages/report/uptime']"
                    [queryParams]="{ c: customerID, lg: locationGroupID, lt: includeInactiveLocations ? 1 : 0 }"
                    >arrow_back</mat-icon
                >
            </div>
            <div class="auto-margin">
                <span>{{ 'REPORT.UPTIME.DETAILS_TITLE' | translate }} </span>
                <span *ngIf="locationName">for </span>
                <span>{{ locationName }}</span>
                <span *ngIf="startDate && endDate && dateFormat"
                    >: <span class="strong">{{ startDate | date: dateFormat }}</span> -
                    <span class="strong">{{ endDate | date: dateFormat }}</span>
                </span>
            </div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin custom-flat-button">
                <button
                    class="uptimeDetailsDownloadHint"
                    (click)="downloadCSV()"
                    mat-button
                    [disabled]="!uptimeData || !uptimeData.uptimeDays || uptimeData?.uptimeDays?.length === 0"
                >
                    {{ 'COMMON.DOWNLOAD_BTN' | translate }}
                </button>
            </div>
            <div class="auto-margin pad-r-15">
                <mat-icon
                    matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                    class="backIcon uptimeDetailsFilterHint"
                    [ngClass]="{ active: displayFilters }"
                    (click)="showUptimeReportFilter()"
                    >filter_list</mat-icon
                >
            </div>
        </mat-card-header>

        <div id="uptimeFilterId">
            <app-widget-filters
                (sendDataToWidget)="notifyReport($event)"
                [filterByMonitor]="true"
                [customerID]="customerID"
                [locationGroupID]="locationGroupID"
                [filterSettings]="{
                    skipInitialCall: true,
                    displayEntities: true,
                    displayAllEntities: false,
                    displayDateRanges: true,
                    dateSpanPastWeek: true,
                    isEntitiesRequired: true,
                    minDisplayLimit: 1,
                    maxDisplayLimit: 6
                }"
                [hidden]="!displayFilters"
            >
            </app-widget-filters>
        </div>
        <mat-card-content *ngIf="uptimeData && uptimeData?.uptimeDays?.length > 0; else noDataFound">
            <div class="tableScrollWraper uptimeDetailsTable">
                <table
                    class="
                        light-table light-table-bordered light-table-hover light-table-responsive-vertical
                        black-theme-table
                    "
                >
                    <thead>
                        <tr>
                            <th class="text-right">{{ 'COMMON.DATE_TEXT' | translate }}</th>
                            <th *ngFor="let ent of pageData[0].uptimeValues" class="text-right">
                                {{ ent.entityName }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of pageData">
                            <td class="text-right">{{ item.day }}</td>
                            <td *ngFor="let entity of item.uptimeValues" class="text-right">
                                {{ entity.uptime === '-' ? '-' : (entity.uptime | number: '1.2-2') }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <mat-paginator
                [length]="uptimeData?.uptimeDays?.length"
                [pageIndex]="pageIndex"
                [pageSize]="10"
                (page)="onPaginateChange($event)"
                [pageSizeOptions]="[10, 25, 100]"
                *ngIf="uptimeData?.uptimeDays?.length > 10"
            >
            </mat-paginator>
        </mat-card-content>

        <ng-template #noDataFound>
            <mat-card-content *ngIf="!uptimeLoadingState">
                <div class="notabledataFound custom-table">
                    <span>{{ 'REPORT.NO_DATA_MESSAGE' | translate }}</span>
                </div>
            </mat-card-content>
        </ng-template>
    </mat-card>
</div>
