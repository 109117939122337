import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
    transform(value: any, isMetric?: boolean): any {
        let formattedValue = null;
        if (value) {
            formattedValue = value.toString();
            if (!isMetric) {
                const valueSections = formattedValue.split('.');
                valueSections[0] = valueSections[0].replace(/,/g, '').replace(/\B(?=(\d{3})+\b)/g, ',');
                formattedValue = valueSections.length > 1 ? valueSections.join('.') : valueSections[0];
            }
        }
        return formattedValue;
    }
}
