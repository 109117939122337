<div id="timeContainer" [ngClass]="{ width100: timeFormat === 12, width60: timeFormat === 24 }">
    <div class="flexRow">
        <div class="flex1">
            <button mat-button (click)="upHours($event)" class="arrowBtn">
                <span class="material-icons material-icons-21"> keyboard_arrow_up </span>
            </button>
        </div>
        <div class="flex1"></div>
        <div class="flex1">
            <button mat-button (click)="upMinutes($event)" class="arrowBtn">
                <span class="material-icons material-icons-21"> keyboard_arrow_up </span>
            </button>
        </div>
        <div class="flex1" *ngIf="timeFormat === 12"></div>
        <div class="flex1" *ngIf="timeFormat === 12">
            <button mat-button class="mat-button-24" (click)="switchAMPM($event)" class="arrowBtn">
                <span class="material-icons"> keyboard_arrow_up </span>
            </button>
        </div>
    </div>

    <div *ngIf="!allowKeyboardInput" class="flexRow">
        <div class="timeText leftPadding3">{{ ('0' + time.hour).slice(-2) }}</div>
        <div class="flex1" style="text-align: center">:</div>
        <div class="timeText">{{ ('0' + time.minute).slice(-2) }}</div>
        <div class="flex1" *ngIf="timeFormat === 12"></div>
        <div class="timeText" *ngIf="timeFormat === 12">{{ dayPeriod }}</div>
    </div>

    <div *ngIf="allowKeyboardInput" class="flexRow">
        <div class="timeText leftPadding3">
            <input
                matInput
                type="number"
                [(ngModel)]="time.hour"
                (change)="updateHour()"
                [min]="0"
                (click)="$event.target.select()"
                [max]="timeFormat === 12 ? 12 : 24"
                maxlength="2"
            />
        </div>
        <div class="flex1" style="text-align: center">:</div>
        <div class="timeText">
            <input
                matInput
                type="number"
                [(ngModel)]="time.minute"
                (change)="updateMinute()"
                (click)="$event.target.select()"
                [min]="0"
                [max]="59"
                maxlength="2"
            />
        </div>
        <div class="flex1" *ngIf="timeFormat === 12"></div>
        <div class="timeText" *ngIf="timeFormat === 12">{{ dayPeriod }}</div>
    </div>

    <div class="flexRow">
        <div class="flex1">
            <button mat-button (click)="downHours($event)" class="arrowBtn">
                <span class="material-icons material-icons-21"> keyboard_arrow_down </span>
            </button>
        </div>
        <div class="flex1"></div>
        <div class="flex1">
            <button mat-button (click)="downMinutes($event)" class="arrowBtn">
                <span class="material-icons material-icons-21"> keyboard_arrow_down </span>
            </button>
        </div>
        <div class="flex1" *ngIf="timeFormat === 12"></div>
        <div class="flex1" *ngIf="timeFormat === 12">
            <button mat-button (click)="switchAMPM($event)" class="arrowBtn">
                <span class="material-icons"> keyboard_arrow_down </span>
            </button>
        </div>
    </div>
</div>
