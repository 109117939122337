import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    ViewChild,
} from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { ModemSetupService } from 'app/shared/services/modem-setup.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { Subscription } from 'rxjs';

import { FilterDataSource } from '../../paging/filter-data-source';
import { AddModemSetupComponent } from './add-modem-setup/add-modem-setup.component';
import { ModemSetup, ModemSetupItem, AuthenticationType } from './modem-setup.model';
import { BehaviorSubject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'app-modem-setup',
    templateUrl: './modem-setup.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModemSetupComponent implements OnInit, OnDestroy {
    public searchBox: FormControl = new FormControl();
    public modemItems: Array<ModemSetupItem> = [];
    public filterModemItems: Array<ModemSetupItem> = [];
    public isLoading = false;
    public showPasswordChars = true;
    public paginationLength = 0;
    public pageIndex = 0;
    public pageSize = 10;
    public tableColumns = [
        // 'predefined', // This is system provider, and can't be edited or deleted
        'provider',
        'username',
        'password',
        'authenticationType',
        'action',
    ];

    public filterColumns = ['provider'];
    public modemSetupSource: FilterDataSource;
    public modemItemsChange: BehaviorSubject<Array<ModemSetupItem>> = new BehaviorSubject<Array<ModemSetupItem>>([]);
    @ViewChild(MatPaginator) public modemPaginator: MatPaginator;
    @ViewChild(MatSort) public modemSorting: MatSort;
    // private members
    private dismissMessageLink: string;
    private failureMessage: string;
    private isRequestProcessing: boolean;
    private pageSubscriptions = new Array<Subscription>(0);
    constructor(
        private dialogRef: MatDialogRef<ModemSetupComponent>,
        private dialog: MatDialog,
        private modemSetupService: ModemSetupService,
        private notificationService: SnackBarNotificationService,
        private translateService: TranslateService,
        private uiUtilsService: UiUtilsService,
        private cdr: ChangeDetectorRef,
    ) {
        this.searchBox.valueChanges
            .pipe(debounceTime(400))
            .pipe(distinctUntilChanged())
            .subscribe(() => {
                this.search(this.searchBox.value);
            });
    }

    public ngOnInit() {
        this.applyTranslations();
        this.loadModems();
    }

    public ngOnDestroy() {
        if (this.pageSubscriptions && this.pageSubscriptions.length) {
            this.pageSubscriptions.forEach((s) => s.unsubscribe());
        }
    }

    public loadModems() {
        if (this.isRequestProcessing) {
            return;
        }
        this.isRequestProcessing = true;
        this.isLoading = true;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        const subs = this.modemSetupService.getAll().subscribe(
            (resp) => {
                if (resp && resp.length) {
                    this.modemItems = resp;
                    this.filterModemItems = this.modemItems;
                    this.paginationLength = this.modemItems.length;
                    this.modemSetupSource = new FilterDataSource(
                        this.modemItemsChange,
                        this.modemItems,
                        this.modemPaginator,
                        this.modemSorting || <MatSort>{},
                        this.filterColumns,
                        false,
                    );
                    this.isRequestProcessing = false;
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }
            },
            () => {
                this.notificationService.raiseNotification(this.failureMessage, this.dismissMessageLink, {
                    panelClass: 'custom-error-snack-bar',
                }, false);
            },
        );
        this.isRequestProcessing = false;
        this.isLoading = false;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        this.pageSubscriptions.push(subs);
    }

    public addModemSetup(element?: ModemSetup) {
        this.dialog
            .open(AddModemSetupComponent, {
                disableClose: true,
                data: {
                    modem: element,
                    availableProviders: (this.modemItems || []).map((m) => m.provider),
                },
            })
            .afterClosed()
            .subscribe(() => {
                this.loadModems();
            });
    }

    public close() {
        this.dialogRef.close({ success: false });
    }

    public search(search: string) {
        if (!search) {
            this.paginationLength = this.modemItems.length;
            this.filterModemItems = this.modemItems;
            this.modemSetupSource = new FilterDataSource(
                this.modemItemsChange,
                this.modemItems,
                this.modemPaginator,
                this.modemSorting,
                this.filterColumns,
                false,
            );
            this.uiUtilsService.safeChangeDetection(this.cdr);
            return;
        }
        search = search.toLocaleLowerCase();
        this.filterModemItems = (this.modemItems || []).filter(
            (x) =>
                x.provider.toLocaleLowerCase().startsWith(search) ||
                x.provider.toLocaleLowerCase().endsWith(search) ||
                x.provider.toLocaleLowerCase().indexOf(search) > -1,
        );

        this.paginationLength = !this.filterModemItems ? 0 : this.filterModemItems.length;
        this.modemSetupSource = new FilterDataSource(
            this.modemItemsChange,
            this.filterModemItems,
            this.modemPaginator,
            this.modemSorting,
            this.filterColumns,
            false,
        );
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public changePage(page) {
        this.pageSize = page.pageSize;
        this.pageIndex = page.pageIndex + 1;
    }

    public getAuthenticationType(type) {
        let value = 'PAP';
        switch (type) {
            case AuthenticationType.None:
                value = 'None';
                break;
            case AuthenticationType.PAP:
                value = 'PAP';
                break;
            case AuthenticationType.CHAP:
                value = 'CHAP';
                break;
            default:
                value = 'PAP';
                break;
        }

        return value;
    }

    private applyTranslations() {
        const translateKeys: Array<string> = ['COMMON.DISMISS_TEXT', 'COMMON.API_FAILURE_MESSAGE'];

        this.translateService.get(translateKeys).subscribe((translateValues) => {
            this.dismissMessageLink = translateValues['COMMON.DISMISS_TEXT'];
            this.failureMessage = translateValues['COMMON.API_FAILURE_MESSAGE'];
        });
    }
}
