import { Injectable } from '@angular/core';
import { Config } from '../../../shared/services/config';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class QstartDataService {
    constructor(public http: HttpClient) {}
    // tslint:disable-next-line:member-ordering
    public items: Array<{ name: string; id: number }> = [
        {
            name: 'Huntsville',
            id: 1,
        },
        {
            name: 'Birmingham',
            id: 2,
        },
    ];

    public getItems() {
        return this.items;
    }

    // Get monitor status
    public getMonitorStatus(id: number) {
        return this.http.get(Config.urls.qstartBaseUrl + id + '/status');
    }

    // Get monitor configuration
    public getMonitorConfiguration(id: number) {
        return this.http.get(Config.urls.qstartBaseUrl + id + '/configuration');
    }

    // Disconnect from monitor
    public disconnectMonitor(id: number) {
        return this.http.get(Config.urls.qstartBaseUrl + id + '/disconnect');
    }

    // Get power configuration for monitor
    public getModemPowerConfig(id: number) {
        return this.http.get(Config.urls.qstartBaseUrl + id + '/modempower');
    }

    // Read ECHO long range depth sensor
    public getLongRangeDepth(id: number) {
        return this.http.get<any>(Config.urls.qstartBaseUrl + id + '/echodepth');
    }

    // Read rain sensor
    public getRain(id: number) {
        return this.http.get(Config.urls.qstartBaseUrl + id + '/echorain');
    }
}
