<div class="notification-dashboard">
    <div class="notification-details mat-box-shadow">
        <div class="title-bar alarm-title-bar">
            <mat-icon class="backIcon" (click)="backToAllNotifications()">arrow_back</mat-icon>
            <div *ngIf="notificationId" class="title">
                {{ 'COMMON.EDIT' | translate }} - {{ PAGE_TEXT.ALARM_TYPES_TEXT.ANSR }}&trade;{{
                    PAGE_TEXT.ALARM_TYPES_TEXT.ALARM
                }}
            </div>
            <div *ngIf="!notificationId" class="title">
                {{ 'COMMON.ADD_TEXT' | translate }} - {{ PAGE_TEXT.ALARM_TYPES_TEXT.ANSR }}&trade;{{
                    PAGE_TEXT.ALARM_TYPES_TEXT.ALARM
                }}
            </div>
        </div>

        <div class="alarm-title-bar">
            <mat-form-field class="full-width">
                <input
                    #nameField
                    matInput
                    (focusout)="checkName()"
                    placeholder="{{ PAGE_TEXT.COLUMN_NAMES.NAME }}"
                    [disabled]="subscribeOnly || selectedIndex === 4"
                    [(ngModel)]="notificationObject.name"
                    maxlength="25"
                    ngDefaultControl
                    required
                />
            </mat-form-field>
            <mat-form-field class="full-width nd-standard-description">
                <input
                    #descField
                    matInput
                    placeholder="{{ PAGE_TEXT.COLUMN_NAMES.DESCRIPTION }}"
                    [disabled]="subscribeOnly"
                    [(ngModel)]="notificationObject.desc"
                    maxlength="100"
                    ngDefaultControl
                />
            </mat-form-field>
            <div class="input-design">
                <span>{{ PAGE_TEXT.COLUMN_NAMES.ENABLE }}</span>
                <mat-slide-toggle
                    class="nd-standard-enable"
                    #enabledToggle
                    [checked]="notificationObject.enabled"
                    [disabled]="subscribeOnly"
                    (change)="toggleNotificationEnabled($event)"
                ></mat-slide-toggle>
            </div>
        </div>
        <mat-tab-group
            *ngIf="notificationObject && subscribeOnly !== undefined"
            [selectedIndex]="selectedIndex"
            (selectedTabChange)="tabChanged($event)"
        >
            <mat-tab label="{{ PAGE_TEXT.ALARM_PAGES_TABS.LOCATIONS }}" [disabled]="disableTab(0)">
                <div *ngIf="!loadingItems.locations" class="location-tab">
                    <div class="tab-header">
                        <div class="min-width">
                            <mat-checkbox [checked]="allLocationsChecked" (change)="checkAllLocations()"></mat-checkbox>
                            {{ 'COMMON.SELECT_ALL' | translate }}
                        </div>
                        <mat-form-field class="full-width">
                            <mat-icon matPrefix class="search-icon">search</mat-icon>
                            <input
                                matInput
                                type="text"
                                placeholder="{{ 'COMMON.SEARCH_TEXT' | translate }}"
                                [(ngModel)]="locationSearchString"
                                (input)="locationSetSearchAndGroup()"
                                ngDefaultControl
                            />
                        </mat-form-field>
                        <mat-form-field class="min-width nd-location-select">
                            <mat-select
                                [(ngModel)]="selectedLocationGroup"
                                value="[2]"
                                (ngModelChange)="locationSetSearchAndGroup()"
                                ngDefaultControl
                                placeholder="{{ 'COMMON.LOCATION_GROUP' | translate }}"
                            >
                                <mat-option *ngFor="let customer of locationGroupOptions" [value]="customer">
                                    {{ customer }}</mat-option
                                >
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="location-list">
                        <div class="loc" *ngFor="let location of dispLocationsOptions">
                            <mat-checkbox [checked]="location.checked" (change)="updateLocation(location)">
                            </mat-checkbox>
                            {{ location.name }}
                        </div>
                        <div *ngIf="dispLocationsOptions.length === 0">{{ PAGE_TEXT.NO_LOCATIONS }}</div>
                    </div>
                </div>
                <div *ngIf="loadingItems.locations" class="main-section">
                    <mat-progress-spinner diameter="25" mode="indeterminate" color="primary"></mat-progress-spinner>
                </div>
            </mat-tab>
            <mat-tab label="{{ PAGE_TEXT.ALARM_PAGES_TABS.ALARM }}" [disabled]="disableTab(1)">
                <div class="alarm-tab-ansr">
                    <div class="tab-header">
                        <div class="header-input">
                            <mat-form-field class="min-width nd-ANSR-source">
                                <mat-select
                                    [(ngModel)]="notificationObject.aids[0]"
                                    name="alarmType"
                                    (selectionChange)="formulaChange()"
                                    placeholder="{{ 'COMMON.SOURCE_ALARM' | translate }}"
                                >
                                    <ng-container *ngFor="let sAlarm of alarmOptions">
                                        <mat-option [value]="sAlarm.id">{{ sAlarm.name }} </mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="tab-content">
                        <div>{{ 'CALCULATED_ENTITY_DIALOG.CREATE_VARIABLES' | translate }}</div>
                        <div class="variable-content add-graph">
                            <div class="entity-container margin-b-10">
                                <div class="composite-list">
                                    <div class="each-item">
                                        {{ 'COMMON.LOCATION' | translate }}
                                    </div>
                                    <div class="each-item">{{ 'COMMON.ENTITY' | translate }}</div>
                                    <div class="each-item">
                                        {{ 'CALCULATED_ENTITY_DIALOG.VARIABLE_NAME_3' | translate }}
                                    </div>
                                    <div class="each-item text-center">{{ 'COMMON.REMOVE' | translate }}</div>
                                </div>
                            </div>
                            <!-- ALARM -->
                            <div
                                class="entity-container margin-b-10"
                                *ngFor="let item of notificationObject.ansr.ents; let i = index; let first = first"
                            >
                                <!-- first row -->
                                <div class="composite-list">
                                    <!-- *ngFor="let item of alarmList; let i = index" -->
                                    <div class="each-item pad-l-15">
                                        <mat-form-field class="full-width">
                                            <mat-select
                                                name="locations"
                                                [(ngModel)]="item.locationId"
                                                (selectionChange)="formulaChange()"
                                                placeholder="{{
                                                    'CALCULATED_ENTITY_DIALOG.LOCATION_VALUE_PLACEHOLDER' | translate
                                                }}"
                                                name="locations"
                                            >
                                                <ng-container *ngFor="let location of locationsOptions">
                                                    <mat-option
                                                        [value]="location.id"
                                                        [class.inactive-location]="!location.isActiveLocation"
                                                    >
                                                        {{ location.name }}
                                                    </mat-option>
                                                </ng-container>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="each-item nd-ANSR-entity">
                                        <app-entity-selector
                                            [disabled]="oneLocationSelected && !ansrEntitiesByLocation[item.locationId]"
                                            (focusout)="formulaChange()"
                                            [single]="true"
                                            [availableEntities]="
                                                oneLocationSelected ? entitiesByLocation[item.locationId] : entitiesAll
                                            "
                                            [availableANSREntities]="
                                                oneLocationSelected
                                                    ? ansrEntitiesByLocation[item.locationId]
                                                    : ansrEntitiesAll
                                            "
                                            [selectedEntitiyIds]="[item.entityId]"
                                            (selectedEntities)="selectedGroupEntity($event, item)"
                                        >
                                        </app-entity-selector>
                                        <div class="mat-error" *ngIf="false">
                                            <span>{{
                                                'CALCULATED_ENTITY_DIALOG.INVALID_ENTITY_SELCTED_MESSAGE' | translate
                                            }}</span>
                                        </div>
                                    </div>
                                    <div class="each-item auto-margin-t-b">
                                        <mat-form-field class="full-width nd-ANSR-variable">
                                            <input
                                                matInput
                                                placeholder="{{ 'COMMON.ALIAS' | translate }}"
                                                (input)="formulaChange()"
                                                [(ngModel)]="item.variableName"
                                                maxlength="100"
                                                ngDefaultControl
                                            />
                                        </mat-form-field>
                                    </div>
                                    <div class="auto-margin-t-b each-item text-center">
                                        <button
                                            type="button"
                                            mat-button
                                            class="lightBackGround"
                                            (click)="removeRow(i)"
                                            *ngIf="!first"
                                        >
                                            <mat-icon class="mat-24">delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="composite-list top-border padding-center relative">
                                <mat-form-field class="full-width nd-ANSR-equation">
                                    <input
                                        matInput
                                        placeholder="{{ 'COMMON.DEFINE_EQUATION' | translate }}"
                                        (input)="formulaChange()"
                                        [(ngModel)]="notificationObject.ansr.equation"
                                        ngDefaultControl
                                    />
                                </mat-form-field>
                                <div class="app-flex-filler"></div>
                                <div class="auto-margin custom-flat-button add-position nd-ANSR-add">
                                    <button mat-fab color="accent" id="addNewVariable" (click)="addMoreRow()">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="{{ PAGE_TEXT.ALARM_PAGES_TABS.NOTIFICATION_TREE }}" [disabled]="disableTab(2)">
                <div *ngIf="!loadingItems.users" class="tree-tab">
                    <div class="new-button">
                        <button mat-button (click)="addNotificationLevel()" class="std-add-level">
                            <mat-icon>add</mat-icon>{{ PAGE_TEXT.ADD_LEVEL }}
                        </button>
                    </div>
                    <table
                        *ngIf="notificationObject.levels.length > 0"
                        mat-table
                        [dataSource]="notificationObject.levels"
                    >
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef class="std-name">{{ PAGE_TEXT.COLUMN_NAMES.NAME }}</th>
                            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
                        </ng-container>
                        <ng-container matColumnDef="delay">
                            <th mat-header-cell *matHeaderCellDef class="std-delay">{{ PAGE_TEXT.COLUMN_NAMES.DELAY }}</th>
                            <td mat-cell *matCellDef="let element">{{ element.delay }}</td>
                        </ng-container>
                        <ng-container matColumnDef="userCount">
                            <th mat-header-cell *matHeaderCellDef  class="std-users">{{ PAGE_TEXT.COLUMN_NAMES.USER_COUNT }}</th>
                            <td mat-cell *matCellDef="let element">{{ element.users.length }}</td>
                        </ng-container>
                        <ng-container matColumnDef="enable">
                            <th mat-header-cell *matHeaderCellDef class="std-enable">{{ PAGE_TEXT.COLUMN_NAMES.ENABLE }}</th>
                            <td mat-cell *matCellDef="let element">
                                <mat-slide-toggle
                                    #enabledLevelToggle
                                    [checked]="element.enabled"
                                    (change)="toggleNotificationLevelEnabled($event, element)"
                                ></mat-slide-toggle>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="options">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">
                                <button mat-icon-button (click)="editNotificationLevel(element)" class="std-edit">
                                    <mat-icon matTooltip="Edit">mode_edit</mat-icon>
                                </button>
                                <button mat-icon-button (click)="deleteNotificationLevel(element)" class="std-delete">
                                    <mat-icon matTooltip="Delete">delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="notificationTreeColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: notificationTreeColumns"></tr>
                    </table>
                    <div *ngIf="notificationObject.levels.length === 0">{{ PAGE_TEXT.NO_LEVELS }}</div>
                </div>
                <div *ngIf="loadingItems.users" class="main-section">
                    <mat-progress-spinner diameter="25" mode="indeterminate" color="primary"></mat-progress-spinner>
                </div>
            </mat-tab>
            <mat-tab label="{{ PAGE_TEXT.ALARM_PAGES_TABS.VALIDATION }}" [disabled]="disableTab(3)">
                <div *ngIf="!loadingItems.validations" class="validation-tab">
                    <div *ngIf="validationInfo.errors.length > 0 || validationInfo.fieldErrors.length > 0">
                        <div class="tab-header">
                            <div><mat-icon class="error">cancel</mat-icon>{{ PAGE_TEXT.ERRORS }}</div>
                            <mat-icon *ngIf="!showErrors" (click)="showErrors = true">arrow_drop_down</mat-icon>
                            <mat-icon *ngIf="showErrors" (click)="showErrors = false">arrow_drop_up</mat-icon>
                        </div>
                        <div *ngIf="showErrors && validationInfo.fieldErrors.length > 0" class="text">
                            <ul>
                                <li *ngFor="let item of validationInfo.fieldErrors">{{ item.message }}</li>
                            </ul>
                        </div>
                        <div *ngIf="showErrors && validationInfo.errors.length > 0">
                            <div class="items" *ngFor="let item of validationInfo.errors">
                                <a class="loc nd-errors" (click)="openLocationCard(item.lid)">{{ item.location }}</a>
                                <div class="text nd-text">
                                    <ul>
                                        <li *ngFor="let itm of item.items">{{ itm.text }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="validationInfo.warnings.length > 0 || validationInfo.fieldWarnings.length > 0">
                        <div class="tab-header">
                            <div>
                                <mat-icon class="warning" fontSet="material-icons-round">warning</mat-icon
                                >{{ PAGE_TEXT.WARNINGS }}
                            </div>
                            <mat-icon *ngIf="!showWarnings" (click)="showWarnings = true">arrow_drop_down</mat-icon>
                            <mat-icon *ngIf="showWarnings" (click)="showWarnings = false">arrow_drop_up</mat-icon>
                        </div>
                        <div *ngIf="showWarnings && validationInfo.fieldWarnings.length > 0" class="text">
                            <ul>
                                <li *ngFor="let item of validationInfo.fieldWarnings">{{ item.message }}</li>
                            </ul>
                        </div>
                        <div *ngIf="showWarnings && validationInfo.warnings.length > 0">
                            <div class="items" *ngFor="let item of validationInfo.warnings">
                                <div class="loc">{{ item.location }}</div>
                                <div class="text">
                                    <ul>
                                        <li *ngFor="let itm of item.items">{{ itm.text }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="
                            validationInfo.warnings.length === 0 &&
                            validationInfo.fieldWarnings.length === 0 &&
                            validationInfo.errors.length === 0 &&
                            validationInfo.fieldErrors.length === 0
                        "
                    >
                        {{ PAGE_TEXT.NO_ERRORS_WARNINGS }}
                    </div>
                </div>
                <div *ngIf="loadingItems.validations" class="main-section">
                    <mat-progress-spinner diameter="25" mode="indeterminate" color="primary"></mat-progress-spinner>
                </div>
            </mat-tab>
            <mat-tab label="{{ PAGE_TEXT.ALARM_PAGES_TABS.CONFIRM_CONFIGURATIONS }}" [disabled]="disableTab(4)">
                <div class="confirmation-tab">
                    <div class="col">
                        <p class="nd-summary-location">{{ PAGE_TEXT.CONFIRMATION_TEXT.THESE_LOCATIONS }}</p>
                        <mat-selection-list class="confirm-list" *ngIf="locationsOptions">
                            <mat-list-option
                                *ngFor="let loc of locationsOptions | onlyCheckedOptions"
                                [disabled]="true"
                                [selected]="loc.checked"
                                >{{ loc.name }}</mat-list-option
                            >
                        </mat-selection-list>
                    </div>
                    <div class="col">
                        <p class="center nd-summary-alarm">{{ PAGE_TEXT.CONFIRMATION_TEXT.CONFIGURED_ALARMS }}</p>
                        <div class="items">
                            <span class="field">{{ 'COMMON.SOURCE_ALARM' | translate }}:</span>
                            <span>{{ getAlarmTypeName() }}</span>
                        </div>
                        <mat-selection-list class="confirm-list" [disabled]="true">
                            <mat-list-option
                                *ngFor="let alm of alarmOptions | onlyCheckedOptions"
                                [selected]="alm.checked"
                                >{{ alm.name }}</mat-list-option
                            >
                        </mat-selection-list>

                        <div class="table-wrap">
                            <table>
                                <tr>
                                    <th *ngIf="oneLocationSelected">{{ 'COMMON.LOCATION' | translate }}</th>
                                    <th>{{ 'COMMON.ENTITY' | translate }}</th>
                                    <th>{{ 'CALCULATED_ENTITY_DIALOG.VARIABLE_NAME_3' | translate }}</th>
                                </tr>
                                <ng-container *ngFor="let alm of notificationObject.ansr.ents; index as i">
                                    <tr>
                                        <td *ngIf="oneLocationSelected" class="user-col">
                                            {{ filterLocation(alm) }}
                                        </td>
                                        <td>
                                            {{ filterEntities(alm) }}
                                        </td>
                                        <td>{{ alm.variableName }}</td>
                                    </tr>
                                </ng-container>
                            </table>
                            <mat-form-field class="full-width">
                                <input
                                class="nd-ANSR-equation"
                                    matInput
                                    readonly
                                    [(ngModel)]="notificationObject.ansr.equation"
                                    ngDefaultControl
                                />
                            </mat-form-field>
                            <div class="note">{{ PAGE_TEXT.NOTIFICATION_SENT_ON_EQUATION }}</div>
                        </div>
                    </div>
                    <div class="col">
                        <p class="nd-summary-levels">{{ PAGE_TEXT.CONFIRMATION_TEXT.ESCALATION }}</p>
                        <div class="table-wrap">
                            <table>
                                <tr>
                                    <th>{{ PAGE_TEXT.COLUMN_NAMES.NAME }}</th>
                                    <th>{{ PAGE_TEXT.COLUMN_NAMES.DELAY }}</th>
                                    <th>{{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.USERS }}</th>
                                    <th *ngIf="subscribeOnly">{{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.ALARM }}</th>
                                    <th *ngIf="subscribeOnly">{{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.RTN }}</th>
                                </tr>
                                <ng-container *ngFor="let level of notificationObject.levels; index as i">
                                    <tr>
                                        <td class="user-col">
                                            <mat-icon
                                                *ngIf="
                                                    !subscribeOnly &&
                                                    level.users.length > 0 &&
                                                    !confirmationShowUsers[i]
                                                "
                                                (click)="confirmationShowUsers[i] = true"
                                                >arrow_right</mat-icon
                                            >
                                            <mat-icon
                                                *ngIf="
                                                    !subscribeOnly && level.users.length > 0 && confirmationShowUsers[i]
                                                "
                                                (click)="confirmationShowUsers[i] = false"
                                                >arrow_drop_down</mat-icon
                                            >
                                            {{ level.name }}
                                        </td>
                                        <td>{{ level.delay }}</td>
                                        <td>{{ level.users.length }}</td>
                                        <td *ngIf="subscribeOnly">
                                            <mat-icon
                                                *ngIf="!subscribeUser[i].alarm"
                                                matTooltip="Subscribe"
                                                (click)="updateSubscription(i, true)"
                                                >email</mat-icon
                                            >
                                            <mat-icon
                                                *ngIf="subscribeUser[i].alarm"
                                                matTooltip="Unsubscribe"
                                                (click)="promptToUpdateSubscription(i, true)"
                                                >unsubscribe</mat-icon
                                            >
                                        </td>
                                        <td *ngIf="subscribeOnly">
                                            <mat-icon
                                                *ngIf="!subscribeUser[i].rtn"
                                                matTooltip="Subscribe"
                                                (click)="updateSubscription(i, false)"
                                                >email</mat-icon
                                            >
                                            <mat-icon
                                                *ngIf="subscribeUser[i].rtn"
                                                matTooltip="Unsubscribe"
                                                (click)="promptToUpdateSubscription(i, false)"
                                                >unsubscribe</mat-icon
                                            >
                                        </td>
                                    </tr>
                                    <tr *ngIf="confirmationShowUsers[i]">
                                        <td class="user-row" colspan="3">
                                            {{ PAGE_TEXT.NOTIFICATION_LEVEL_MODAL.USERS }}:
                                            <p *ngFor="let usr of convertIDtoUserNames(level.users)">{{ usr }}</p>
                                        </td>
                                    </tr>
                                </ng-container>
                            </table>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
        <div *ngIf="loadingItems.page" class="main-section">
            <mat-progress-spinner diameter="25" mode="indeterminate" color="primary"></mat-progress-spinner>
        </div>

        <div class="alarm-footer-bar" *ngIf="!subscribeOnly">
            <button class="footer-back" (click)="uiChangeTab(true, false)" *ngIf="selectedIndex !== 0" mat-button>
                {{ 'COMMON.BACK_TEXT' | translate }}
            </button>
            <button class="footer-next"
                (click)="uiChangeTab(true, true)"
                *ngIf="selectedIndex !== 4"
                [disabled]="!allowNext(selectedIndex)"
                mat-button
            >
                {{ 'COMMON.NEXT_BTN' | translate }}
            </button>
            <button (click)="confirm()" *ngIf="selectedIndex === 4" mat-button>
                {{ 'COMMON.CONFIRM_BTN' | translate }}
            </button>
        </div>
    </div>
</div>
