<div>
    <div class="fmr-dialog">
        <app-loader [isLoading]="isLoading"></app-loader>
        <div class="popupHeader title" mat-dialog-title>
            <span> {{ 'COMMON.CONFIGURATIONS' | translate }}</span>
        </div>
        <div mat-dialog-content>
            <table mat-table [dataSource]="dataSource">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.NAME' | translate }}</th>
                    <td mat-cell *matCellDef="let row" class="w-150 breakall" (click)="selection.toggle(row)">{{ row.name }}</td>
                </ng-container>

                <ng-container matColumnDef="createdBy">
                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.CREATED_BY' | translate }}</th>
                    <td mat-cell *matCellDef="let row" class="w-150" (click)="selection.toggle(row)">{{ row.createdBy }}</td>
                </ng-container>

                <ng-container matColumnDef="editedBy">
                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.EDITED_BY' | translate }}</th>
                    <td mat-cell *matCellDef="let row" class="w-150" (click)="selection.toggle(row)">{{ row.editedBy }}</td>
                </ng-container>

                <ng-container matColumnDef="lastEdited">
                    <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.EDITED_ON' | translate }}</th>
                    <td mat-cell *matCellDef="let row" class="nowrap w-150" (click)="selection.toggle(row)">{{ row.lastEdited | date: customerDateFormat }}</td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element; let j = index" class="text-right w-100">
                        <button
                            mat-icon-button
                            [satPopoverAnchor]="popover"
                            (click)="popover?.open()"
                        >
                            <mat-icon>edit</mat-icon>
                        </button>
                        <sat-popover #popover hasBackdrop>
                            <inline-edit [closeOnSave]="false" (save)="editConfig(popover, element, $event)" [value]="element?.name" maxlength="256" title="{{ 'COMMON.CHANGE_NAME' | translate }}"></inline-edit>
                        </sat-popover>
                        <button mat-icon-button (click)="deleteConfig(element)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="configurationColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: configurationColumns"
                    [ngClass]="{hover: row.hovered, selection: selection.isSelected(row)}"
                    (mouseover)="row.hovered = true" (mouseout)="row.hovered = false">
                ></tr>
            </table>
            <mat-paginator
                #paginator
                [pageSize]="10"
                [pageSizeOptions]="[10, 25, 100]"
                [length]="length"
            ></mat-paginator>
            <div class="no-rows" *ngIf="dataSource?.data?.length === 0">
                <span>{{ 'FLOW_MONITORING.CONFIGURATIONS.NO_CONFIGURATIONS' | translate }}</span>
            </div>
        </div>
        <div mat-dialog-actions class="actions">
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button type="submit" (click)="onCancel()">{{ 'COMMON.CANCEL' | translate }}</button>
            </div>
            <div class="auto-margin">
                <button mat-button [disabled]="!selection.hasValue()" type="submit" (click)="onLoad()">{{ 'COMMON.LOAD' | translate }}</button>
            </div>
        </div>
    </div>
</div>
