import {
    Component,
    OnInit,
    ViewEncapsulation,
    OnDestroy,
    ChangeDetectionStrategy,
    ViewChild,
    ChangeDetectorRef,
    Inject,
    Input,
    Optional,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from 'app/pages/admin/users.service';
import { StringUtils } from 'app/shared/utils/string-utils';
import { MultiSelectGroupComponent } from '../../../../shared/components/multi-select/multi-select-group/multi-select-group.component';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { environment } from 'app/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { DailySummaryReportService } from '..';
import { saveAs as importedSaveAs } from 'file-saver';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { VaultService } from 'app/pages/vault';
import { MultiSelectAutoCompleteComponent } from 'app/shared/components/multi-select/multi-select-auto-complete/multi-select-auto-complete.component';
import { customerQueryParam } from 'app/shared/models/customer';
import { DailyReportExport, DailyReportDisplayModel } from 'app/shared/models/daily-summary';
import { EntitySelectorObject, EntitySelectorEntity } from 'app/shared/models/entities';
import { LocationGroupSelector } from 'app/shared/models/location-group';
import { LocationArgs } from 'app/shared/models/locations';
import { SelectableGroup } from 'app/shared/models/selectable';
import { EntityResponseItem } from 'app/shared/models/view-data';
import { EntityService } from 'app/shared/services/entity.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import {
    DataExportType,
    DataExportFile,
    LocationType,
    EntityExportType,
    ExportPerSheet,
} from 'app/shared/services/telemetry.service';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { LocationGroupService } from 'app/shared/services/location-group.service';

export interface DailySummaryExportComponentData {
    preSelectedLocationId: number;
    preSelectedEntityIds: number[];
    allowedEntityGroups: number[];
    startDate: string;
    endDate: string;
    includeInactiveLocations: boolean;
}

@Component({
    selector: 'ads-daily-summary-export',
    templateUrl: './daily-summary-export.component.html',
    styleUrls: ['./daily-summary-export.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DailySummaryExportComponent implements OnInit, OnDestroy {
    @ViewChild('multiSelectAutoCompleteLocations')
    private multiSelectAutoCompleteLocations: MultiSelectAutoCompleteComponent;
    @ViewChild('entitiesGroupMultiselect') public entitiesGroupMultiselect: MultiSelectGroupComponent;

    public dailyExport: DailyReportExport = {
        fileType: DataExportType.Excel,
        singleOrMultiple: DataExportFile.Single,
        locationType: LocationType.Location,
        startDate: this.convertDate(
            new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7, 0, 0, 0).toString(),
        ),
        endDate: this.convertDate(
            new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1, 0, 0, -1).toString(),
            true,
        ),
    };
    public filteredLocations = new Array<SelectableGroup>();
    private subscriptions = new Array<Subscription>();
    public locationTypeArray = new Array<DailyReportDisplayModel>();
    public fileTypeArray = new Array<DailyReportDisplayModel>();
    public csvFileArray = new Array<DailyReportDisplayModel>();
    public entityPerColumnRowArray = new Array<DailyReportDisplayModel>();
    public dataToBeexportedPerSheet = new Array<DailyReportDisplayModel>();
    public isLoading: boolean;
    public selectedLocations = new Array<SelectableGroup>();
    public entityList = new Array<SelectableGroup>();
    public locationGroups = new Array<LocationGroupSelector>();
    private stringUtils = new StringUtils();
    public selectedEntitySet = new Set();
    public selectedEntityIds = new Array<number>();
    public savedSettingEntitieIds = [];
    public savedSettingLocationIds = [];
    public isEntitiesInvalid = false;
    public isLocationGroupInvalid = false;
    public preSelectedLocationGroup: SelectableGroup;
    public preSelectedLocation = new Array<SelectableGroup>();
    public translateKeys: Array<string> = [
        'COMMON.NONE',
        'COMMON.TWO_MIN',
        'COMMON.FIVE_MIN',
        'COMMON.FIFTEEN_MIN',
        'COMMON.ONE_HOUR',
        'COMMON.ONE_DAY',
        'COMMON.ONE_WEEK',
        'COMMON.ONE_MONTH',
        'COMMON.LOCATION',
        'COMMON.LOCATION_GROUP',
        'COMMON.EXL_EXPORT_SUCCESS',
        'COMMON.CSV',
        'COMMON.EXCEL',
        'COMMON.ENTITY',
        'COMMON.SINGLE',
        'COMMON.MULTIPLE',
        'COMMON.ROW',
        'COMMON.COLUMN',
        'COMMON.CSV_EXPORT_SUCCESS',
        'COMMON.DISMISS_TEXT',
        'COMMON.SAVE_SETTING',
        'COMMON.UPDATE_SETTING',
    ];

    public PAGE_TEXT: any;
    public COMMON_TEXT: any;

    public locationGroupId: string;
    public isValidDateRange = true;
    public isStatusCodeFlagValue = false;
    public showSaveSetting = false;

    public preStartDate: Date;
    public preEndDate: Date;
    public readonly apiUri = environment.serviceUrl;
    public entitySelectorObjects$ = new BehaviorSubject<EntitySelectorObject[]>(undefined);

    public minDate: Date;
    public maxDate: Date;

    public settingText: string;

    public showpageHint: boolean;
    constructor(
        public refDialog: MatDialogRef<DailySummaryExportComponent>,
        private usersService: UsersService,
        @Inject(MAT_DIALOG_DATA) public data: DailySummaryExportComponentData,
        private activatedRoute: ActivatedRoute,
        private entityService: EntityService,
        private uiUtilsService: UiUtilsService,
        private locationGroupService: LocationGroupService,
        private changeDetectorReference: ChangeDetectorRef,
        private translate: TranslateService,
        private snackBarNotificationService: SnackBarNotificationService,
        private dailyReportService: DailySummaryReportService,
        private vaultService: VaultService,
        private domOperationService: DomOperationUtilsService,
    ) {
        this.translate.get('REPORT.DAILY_SUMMARY.EXPORT').subscribe((res: string) => {
            this.PAGE_TEXT = res;
        });
        this.translate.get('COMMON').subscribe((res: string) => {
            this.COMMON_TEXT = res;
        });

        this.dailyExport.startDate = this.convertDate(data.startDate);
        this.dailyExport.endDate = this.convertDate(data.endDate, true);
        this.preStartDate = new Date(data.startDate);
        this.preEndDate = new Date(data.endDate);
    }
    public ngOnInit() {
        const hintSubs = this.domOperationService.showpageHint.subscribe((data) => {
            this.showpageHint = data;
            this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
        });
        this.subscriptions.push(hintSubs);
        this.isLoading = true;

        this.locationTypeArray = [
            { id: LocationType.Location, value: this.COMMON_TEXT.LOCATION },
            { id: LocationType.LocationGroup, value: this.COMMON_TEXT.LOCATION_GROUP },
        ];
        this.fileTypeArray = [
            { id: DataExportType.AdsCsv, value: this.COMMON_TEXT.CSV },
            { id: DataExportType.Excel, value: this.COMMON_TEXT.EXCEL },
        ];
        this.csvFileArray = [
            { id: DataExportFile.Single, value: this.COMMON_TEXT.SINGLE },
            { id: DataExportFile.Multiple, value: this.COMMON_TEXT.MULTIPLE },
        ];
        this.entityPerColumnRowArray = [
            { id: EntityExportType.Row, value: this.COMMON_TEXT.ROW },
            { id: EntityExportType.Column, value: this.COMMON_TEXT.COLUMN },
        ];
        this.dataToBeexportedPerSheet = [
            { id: ExportPerSheet.Location, value: this.COMMON_TEXT.LOCATION },
            { id: ExportPerSheet.Entity, value: this.COMMON_TEXT.ENTITY },
        ];
        this.activatedRoute.queryParamMap.subscribe((params) => {
            this.dailyExport.cid = Number(params.get(customerQueryParam));
        });
        this.locationGroups = this.locationGroupService.globalSelectableLocationGroups;
        const locationArgs = <LocationArgs>{
            customerId: this.dailyExport.cid,
            IncludeInactiveLocations: this.data.includeInactiveLocations,
        };
        this.preSelectedLocation = [];
        const locationSubscription = this.usersService.getLocationsList(locationArgs).subscribe(
            (locations) => {
                if (locations) {
                    locations.forEach((item) => {
                        const isChecked =
                            this.data &&
                            this.data.preSelectedLocationId &&
                            item.locationId === this.data.preSelectedLocationId;
                        this.filteredLocations.push({
                            name: item.locationName,
                            id: item.locationId,
                            isChecked: isChecked,
                        });
                    });
                    this.filteredLocations.sort(function (a, b) {
                        return a.name.localeCompare(b.name);
                    });
                    this.preSelectedLocation = this.filteredLocations.filter((item) => item.isChecked);
                    this.getSelectedEntities({ selectedLocations: this.preSelectedLocation });
                    this.selectedLocations = this.filteredLocations.filter((item) => item.isChecked);
                }

                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
            },
            (error) => {},
        );
        this.subscriptions.push(locationSubscription);
    }

    public restrictKeys(event): boolean {
        if (event.keyCode === 222 || event.keyCode === 188 || event.keyCode === 190 || event.keyCode === 220) {
            return false;
        }
        return true;
    }
    public handleSelectedLocationsGroup(locationGroup): void {
        this.isLocationGroupInvalid = !(locationGroup.length > 0);
        if (locationGroup.length > 0) {
            this.isLoading = true;
            this.locationGroupId = locationGroup[0].id;
            this.savedSettingEntitieIds = [];
            this.getSelectedEntities({ locationGroupId: Number.parseInt(this.locationGroupId, 10) });
        }
    }
    public handleSelectedLocations(locations: Array<SelectableGroup>): void {
        this.selectedLocations = [];
        this.isLoading = true;
        this.selectedLocations = locations.filter((location: SelectableGroup) => location.isChecked);
        if (this.selectedLocations.length > 0) {
            this.savedSettingEntitieIds = [];
            this.getSelectedEntities({ selectedLocations: this.selectedLocations });
        } else {
            this.isLoading = false;
            this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
        }
    }
    public handleSelectedEntities(entities: EntitySelectorEntity[]): void {
        this.selectedEntityIds = [];
        this.selectedEntityIds = entities
            .filter((entity: SelectableGroup) => entity.isChecked)
            .map((entity) => entity.id);
        this.isEntitiesInvalid = !(this.selectedEntityIds.length > 0);
        this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
    }

    public getSelectedEntities({
        selectedLocations,
        locationGroupId,
    }: {
        selectedLocations?: SelectableGroup[];
        locationGroupId?: number;
    }) {
        if (selectedLocations && selectedLocations.length === 0) {
            return;
        }
        this.selectedEntityIds = [];
        const entitiesSubscription = this.entityService
            .getEntitiesByLocation(
                this.dailyExport.cid,
                selectedLocations ? selectedLocations.map(({ id }) => id) : undefined,
                locationGroupId,
            )
            .subscribe(
                (entities: Array<EntityResponseItem>) => {
                    this.isLoading = false;
                    const filteredEntities = new Array<SelectableGroup>();
                    this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
                    entities.forEach((entity: EntityResponseItem) => {
                        if (
                            !this.data ||
                            !this.data.allowedEntityGroups ||
                            this.data.allowedEntityGroups.includes(entity.displayGroup)
                        ) {
                            filteredEntities.push({
                                name: entity.entityName,
                                id: entity.entityId,
                                groupName: entity.displayGroupName,
                                groupId: entity.displayGroup,
                            });
                        }
                    });
                    const entitySelectorObjects =
                        this.entityService.vaultSelectableToEntitySelectorObject(filteredEntities);
                    this.entitySelectorObjects$.next(entitySelectorObjects);
                    this.entityList = filteredEntities;
                    this.selectedEntityIds =
                        this.data && this.data.preSelectedEntityIds
                            ? this.data.preSelectedEntityIds
                            : this.entityList.filter((item) => item.isChecked).map((entity) => entity.id);
                    this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
                },
                () => {
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
                },
            );
        this.subscriptions.push(entitiesSubscription);
    }
    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
    public close() {
        this.refDialog.close();
    }
    public locationsInvalid() {
        return (
            this.multiSelectAutoCompleteLocations &&
            this.selectedLocations &&
            this.selectedLocations.length < 1 &&
            this.multiSelectAutoCompleteLocations.searchInput.ngControl.touched
        );
    }
    public validDateRange($event) {
        this.isValidDateRange = $event;
    }
    public selectedStartDate($event) {
        this.dailyExport.startDate = this.convertDate($event);
    }
    public selectedEndDate($event) {
        this.dailyExport.endDate = this.convertDate($event, true);
    }
    public convertDate(str, endTime?: boolean) {
        const date = new Date(str),
            mnth = ('0' + (date.getMonth() + 1)).slice(-2),
            day = ('0' + date.getDate()).slice(-2);
        let res = [date.getFullYear(), mnth, day].join('-');
        if (endTime) {
            res = res + ' 23:59:59';
        } else {
            res = res + ' 00:00:00';
        }
        return res;
    }

    public onExportSubmit() {
        const entitiesSelected = this.selectedEntityIds;
        let locationSelected = this.selectedLocations.map((location: SelectableGroup) => location.id);
        if (this.dailyExport.locationType === LocationType.Location) {
            this.locationGroupId = '';
        } else {
            locationSelected = [];
        }
        this.isLoading = true;

        this.dailyReportService
            .export(this.dailyExport, entitiesSelected, locationSelected, this.locationGroupId)
            .subscribe(
                (res) => {
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
                    this.fileDownload(res);
                },
                (err) => {
                    this.snackBarNotificationService.raiseNotification(
                        this.PAGE_TEXT.EXPORT_ERR_SNACKBAR_MSG,
                        this.COMMON_TEXT.DISMISS_TEXT,
                        {
                            panelClass: 'custom-error-snack-bar',
                        },
                        false
                    );
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
                },
            );

        this.close();
    }

    public fileDownload(files) {
        if (files && files.length > 0) {
            this.isLoading = true;
            const selectedFiles = [];

            if (Array.isArray(files)) {
                files.forEach((file) => {
                    selectedFiles.push('/' + file);
                });
            } else {
                selectedFiles.push('/' + files);
            }

            const splitFile = selectedFiles[0].split('/');
            const fileName = selectedFiles.length === 1 ? splitFile.find((x) => x.includes('.')) : 'Files';
            this.isLoading = false;

            this.vaultService.downloadFile(selectedFiles).subscribe((res) => {
                importedSaveAs(res, fileName);
            });
        }
    }

    public dataExport() {
        // TODO: Finish this one
    }
    public saveSetting() {
        this.showSaveSetting = true;
    }
    public closeExportSetting() {
        this.showSaveSetting = false;
    }
}
