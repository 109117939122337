import {
    Component,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
    ViewChild,
    ViewEncapsulation,
    ElementRef,
    ViewChildren,
    QueryList,
} from '@angular/core';
import { MatLegacyTabChangeEvent as MatTabChangeEvent, MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { MatLegacySlideToggle as MatSlideToggle } from '@angular/material/legacy-slide-toggle';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfirmationButton, ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { SignalRMessageType, SliicerSignalRMessage, SliicerMessageType, VAULT_MOVE_SUCCESS_MESSAGE, VAULT_MOVE_FAIL_MESSAGE } from 'app/shared/models/signalr-message';
import { UsersInfo } from 'app/shared/models/users';
import { TopnavComponent } from 'app/navigation/topnav/topnav.component';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { SignalRService } from 'app/shared/services/signalr.service';
import { SliicerService, CUSTOMER_SLIICER_FEATURE_ID } from 'app/shared/services/sliicer.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { StudySettingsComponent } from './study-settings/study-settings.component';
import * as pbi from 'powerbi-client';
import * as pbm from 'powerbi-models';
import { saveAs as importedSaveAs } from "file-saver";
import { UpdatesWidgetService } from '../shared/components/updates-widget/updates-widget.service';
import { FlowloadFileUploadService } from './flowload-file-upload/flowload-file-upload.service';
import { mergeMap, take } from 'rxjs/operators';
import { BasinStateEnum, StudyState } from 'app/shared/models/sliicer/metadata';
import { AppQueryParams, Customer, caseStudyQueryParam, customerQueryParam } from 'app/shared/models/customer';
import { ConfirmDialogComponent, ConfirmDialogData } from 'app/shared/components/confirm-dialog.component';
import moment from 'moment';
import { IComponentDialog } from 'app/shared/models/comopnent-cofirmation';
import { environment } from 'app/environments/environment';
import { SliicerCaseStudyEditConfigComponent } from './edit-config/edit-config.component';
import { SliicerCaseStudy } from 'app/shared/models/sliicer';
import { CustomerService } from 'app/shared/services/customer.service';

const MAX_POWER_BI_ATTEMPTS = 2;
const dateFormat = 'YYYY-MM-DD';

@Component({
    selector: 'ads-sliicer-case-study-details',
    templateUrl: './case-study-details.component.html',
    styleUrls: ['./case-study-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [TopnavComponent],
})
export class SliicerCaseStudyDetailsComponent implements OnInit, OnDestroy {
    @ViewChild('tabs') public tabs: MatTabGroup;
    @ViewChild(StudySettingsComponent) public settings: StudySettingsComponent;
    @ViewChild(SliicerCaseStudyEditConfigComponent) private configComponent: SliicerCaseStudyEditConfigComponent;
    @ViewChildren('reportContainer') public reportContainer: QueryList<ElementRef>;

    /** Index of PowerBI Tab */
    readonly TAB_RESULTS_POWERBI = 5;

    private subscriptions: Subscription[] = [];

    private routeCustomerId: number;
    public caseStudyCustomerId: number;
    public caseStudyId: string;

    public powerBiConfig: any;
    public active;
    private powerBiRetryCount = 0;
    private powerBILazyLoaded = false;
    private powerBILazyLoadedCount = 0;

    public editConfig = false;
    public inStudyWizard = false;
    public caseStudyName: string;
    public readOnlyAccess: boolean;
    public preliminary: boolean;
    public isLoadingState = false;
    public isLocked = false;
    public isOptionsLocked = false;
    public isBetaFlag: boolean;
    public studyVaultName: string;
    public isOptionVisible: boolean;
    private telemetryDatbaseName: string;

    public StudyState = StudyState;
    public studyState?: StudyState;

    private copyStudyIdText: string;

    // translated strings
    public readonlyPermissionFailedMessage: string;
    public readonlyPermissionMessage: string;
    public preliminaryFailedMessage: string;
    public preliminaryMessage: string;
    public failedToRunCalculations: string;
    public failedToExportCaseStudy: string;
    public failedToImportCaseStudy: string;
    public successExportCaseStudy: string;
    public successImportCaseStudy: string;
    public successfullyRanCalculations: string; // TODO: WPS - make use of this
    public studyFailed: string;
    public lockStatus: string;
    public unlockStatus: string;
    private titleText: string;
    private deleteCaseStudyText: string;
    private deleteCaseStudySuccessfulText: string;
    private deleteCaseStudyFailureText: string;
    private deleteCancelText: string;
    private deleteOkayText: string;

    // Variables for HTML interaction
    public optionsToggleExpanded = false;
    public powerBIEnabled = false;
    public powerBIError = false;
    public inResultsTab = false;
    powerbi: pbi.service.Service;
    reportElement: HTMLElement;
    report: pbi.Embed;

    private studyDataRefreshSuccessText: string;
    public isConfigurationFormValid = true;
    public resultsSubTab: number = 0;

    constructor(
        private sliicerService: SliicerService,
        private signalRService: SignalRService,
        private locationGroupService: LocationGroupService,
        private utilService: UiUtilsService,
        private translate: TranslateService,
        public cdr: ChangeDetectorRef,
        private statusCodeService: StatusCodeService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        public updatesWidgetService: UpdatesWidgetService,
        public flowloadFileUploadService: FlowloadFileUploadService,
    ) { }

    public ngOnInit() {

        const PROPERTY_NAME_IMPORT_BUTTONS = 'showImportExportButtons'
        this.isOptionVisible = environment.hasOwnProperty(PROPERTY_NAME_IMPORT_BUTTONS) ? environment[PROPERTY_NAME_IMPORT_BUTTONS] : false;

        this.updatesWidgetService.errors.settings = false;
        this.setupSignalR();
        this.applyTranslation();

        const routeSubs = this.activatedRoute.queryParamMap
            .pipe(
                mergeMap((params: ParamMap) => {
                    this.listenForCustomerChange();
                    const customerId = Number(params.get(customerQueryParam));
                    const caseStudyId = params.get(caseStudyQueryParam);
                    this.routeCustomerId = customerId;
                    this.setBetaFlag(this.routeCustomerId);
                    this.isLoadingState = true;
                    return this.sliicerService.loadCaseStudy(customerId, caseStudyId);
                }),
                take(1),
            )
            .subscribe((success) => {
                this.isLoadingState = false;
                if (!success) {
                    this.dialog
                        .open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, {
                            data: {
                                title: 'SLIICER.COMMON.STUDY_LOAD_ERROR_TITLE',
                                message: 'SLIICER.COMMON.STUDY_LOAD_ERROR_MESSAGE',
                            },
                            disableClose: true,
                        })
                        .afterClosed()
                        .subscribe(() => {
                            this.navigateToDashboard();
                        });
                    return;
                }

                this.subscriptions.push(
                    this.sliicerService.studyDetailsData$.subscribe((caseStudyData) => {
                        this.caseStudyCustomerId = caseStudyData.customerId;
                        this.validateCustomerStudyToCustomerId(this.routeCustomerId, this.caseStudyCustomerId);

                        if (this.caseStudyId !== caseStudyData.id) {
                            this.inStudyWizard = !caseStudyData.config;
                        }

                        this.caseStudyId = caseStudyData.id;
                        this.caseStudyName = caseStudyData.meta.name;
                        this.readOnlyAccess = caseStudyData.meta.allowRead;
                        this.preliminary = caseStudyData.meta.preliminaryResults;
                        this.studyVaultName = caseStudyData.meta.studyVaultName;
                        this.isLocked = caseStudyData.meta.locked;
                        this.telemetryDatbaseName = caseStudyData.meta.telemetryDatabaseName;
                        this.studyState = caseStudyData.meta ? caseStudyData.meta.studyState : null;
                        this.isOptionsLocked = !caseStudyData.editable && caseStudyData.meta.allowRead ? true : false;
                    }),
                );

                this.sliicerService.changeEditableBasedOnStatus(this.isEditableBasedOnStatus());

                this.subscriptions.push(
                    this.sliicerService.caseStudyEditable.subscribe((editable: boolean) => {
                        this.isLocked = !editable;
                    }),
                );

                // PowerBI code is temporarly commented out
                // if (!this.inStudyWizard) {
                //     this.fetchPBIToken();
                // }
            });

        this.subscriptions.push(routeSubs);
        // disable location group dropdown when cast study dashboard enabled
        this.locationGroupService.locationGroupDisabledStatus = true;
    }

    /** Whenever it is editable based on status. It do not check if user has permissions to a study ! */
    public isEditableBasedOnStatus(): boolean {
        return (
            !this.studyState ||
            (this.studyState !== StudyState.CalculationStarted && this.studyState !== StudyState.TelemetryCopyStarted)
        );
    }

    private listenForCustomerChange() {
        const routeSubs = this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            const customerId = Number(params.get(customerQueryParam));

            if (this.routeCustomerId && customerId !== this.routeCustomerId) {
                this.navigateToSliicerStudyDashoard(customerId);
            }
        });
        this.subscriptions.push(routeSubs);
    }

    private checkIfNeedToRecalc(study: SliicerCaseStudy) {
        if (study && study.meta.basinState !== BasinStateEnum.ready) {
            this.sliicerService.performAllCalculations(this.caseStudyCustomerId, study.id).subscribe();
        }
    }

    public ngOnDestroy() {
        this.checkIfNeedToRecalc(this.sliicerService.caseStudyDetails.getValue());
        this.beforeDestroy();
    }

    private beforeDestroy() {
        // enabled location group dropdown when cast study dashboard is destroyed
        this.locationGroupService.locationGroupDisabledStatus = false;
        this.sliicerService.caseStudyDetails.next(null);
        this.sliicerService.qvsiSelectedConfig.next(null);
        this.subscriptions.forEach((subscripton) => subscripton.unsubscribe());
        this.subscriptions = [];

        if (this.configComponent) {
            this.configComponent.clearLocationGroups();
        }
    }

    public applyTranslation() {
        const translateKeys: Array<string> = [
            'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_LOCKSTATUS',
            'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_UNLOCK_STATUS',
            'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_STUDY_FAILED',
            'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_RUNALL_FAILED',
            'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_EXPORT_FAILED',
            'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_EXPORT_SUCCESS',
            'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_IMPORT_FAILED',
            'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_IMPORT_SUCCESS',
            'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_RUNALL_SUCCESS',
            'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_STUDY_READONLY_MESSAGE',
            'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_STUDY_READONLY_FAILED_MESSAGE',
            'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_STUDY_PRELIMINARY_MESSAGE',
            'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_STUDY_PRELIMINARY_FAILED_MESSAGE',
            'SLIICER_TABLE.SLIICER_DIALOG_TITLE',
            'SLIICER_TABLE.SLIICER_DELETE_DIALOG_CONTENT',
            'SLIICER_TABLE.SLIICER_DELETE_SUCCESS_TEXT',
            'SLIICER_TABLE.SLIICER_DELETE_FAILURE_TEXT',
            'SLIICER.COMMON.DELETE_BUTTON_TITLE',
            'SLIICER.COMMON.CANCEL_BUTTON_TITLE',
            'SLICER_PAGE.CASE_STUDY_ID_COPIED',
        ];
        this.translate.get(translateKeys).subscribe((translateValues) => {
            this.lockStatus = translateValues['SLIICER_TABLE.SLICER_SUMMARY.SLIICER_LOCKSTATUS'];
            this.unlockStatus = translateValues['SLIICER_TABLE.SLICER_SUMMARY.SLIICER_UNLOCK_STATUS'];
            this.studyFailed = translateValues['SLIICER_TABLE.SLICER_SUMMARY.SLIICER_STUDY_FAILED'];
            this.failedToRunCalculations = translateValues['SLIICER_TABLE.SLICER_SUMMARY.SLIICER_RUNALL_FAILED'];
            this.failedToExportCaseStudy = translateValues['SLIICER_TABLE.SLICER_SUMMARY.SLIICER_EXPORT_FAILED'];
            this.successExportCaseStudy = translateValues['SLIICER_TABLE.SLICER_SUMMARY.SLIICER_EXPORT_SUCCESS'];
            this.failedToImportCaseStudy = translateValues['SLIICER_TABLE.SLICER_SUMMARY.SLIICER_IMPORT_FAILED'];
            this.successImportCaseStudy = translateValues['SLIICER_TABLE.SLICER_SUMMARY.SLIICER_IMPORT_SUCCESS'];
            this.successfullyRanCalculations = translateValues['SLIICER_TABLE.SLICER_SUMMARY.SLIICER_RUNALL_SUCCESS'];
            this.readonlyPermissionMessage =
                translateValues['SLIICER_TABLE.SLICER_SUMMARY.SLIICER_STUDY_READONLY_MESSAGE'];
            this.readonlyPermissionFailedMessage =
                translateValues['SLIICER_TABLE.SLICER_SUMMARY.SLIICER_STUDY_READONLY_FAILED_MESSAGE'];
            this.preliminaryMessage = translateValues['SLIICER_TABLE.SLICER_SUMMARY.SLIICER_STUDY_PRELIMINARY_MESSAGE'];
            this.preliminaryFailedMessage =
                translateValues['SLIICER_TABLE.SLICER_SUMMARY.SLIICER_STUDY_PRELIMINARY_FAILED_MESSAGE'];
            this.titleText = translateValues['SLIICER_TABLE.SLIICER_DIALOG_TITLE'];
            this.deleteCaseStudyText = translateValues['SLIICER_TABLE.SLIICER_DELETE_DIALOG_CONTENT'];
            this.deleteCaseStudySuccessfulText = translateValues['SLIICER_TABLE.SLIICER_DELETE_SUCCESS_TEXT'];
            this.deleteCaseStudyFailureText = translateValues['SLIICER_TABLE.SLIICER_DELETE_FAILURE_TEXT'];
            this.deleteOkayText = translateValues['SLIICER.COMMON.DELETE_BUTTON_TITLE'];
            this.deleteCancelText = translateValues['SLIICER.COMMON.CANCEL_BUTTON_TITLE'];
            this.copyStudyIdText = translateValues['SLICER_PAGE.CASE_STUDY_ID_COPIED'];
        });

        this.studyDataRefreshSuccessText = this.translate.instant('SLIICER_TABLE.TELEMETRY.STUDY_DATA_REFRESH_SUCCESS');
    }

    // This method copies value to the clipboard
    public copyStudyId(value: string): void {
        SliicerCaseStudyDetailsComponent.copyToClipboard(value);
        this.sliicerService.showToast(this.copyStudyIdText);
    }

    public editConfiguration() {
        this.editConfig = true;
        this.utilService.safeChangeDetection(this.cdr);
    }

    public editComplete() {
        this.editConfig = false;
        this.utilService.safeChangeDetection(this.cdr);
    }

    public wizardComplete() {
        this.inStudyWizard = false;
        this.utilService.safeChangeDetection(this.cdr);
    }

    public exportCaseStudy() {
        this.sliicerService.exportCaseStudy(this.caseStudyCustomerId, this.caseStudyId).subscribe(
            (result) => {
                if (result) {
                    const theJSON = JSON.stringify(result);
                    const blob = new Blob([theJSON], { type: 'json' });

                    importedSaveAs(blob, this.caseStudyId + '.json');

                    this.isLoadingState = false;
                    this.sliicerService.showToast(this.successExportCaseStudy, false);
                    this.utilService.safeChangeDetection(this.cdr);
                }
            },
            () => {
                this.isLoadingState = false;
                this.utilService.safeChangeDetection(this.cdr);
                this.sliicerService.showToast(this.failedToExportCaseStudy, true);
            },
        );
    }

    public importCaseStudy() {
        const fileInput = document.getElementById('fileInput');
        fileInput.click();
    }

    public uploadFile(fileList: FileList) {

        const file = fileList[0];
        const fileReader: FileReader = new FileReader();
        let fileresult: any;
        const that = this;
        fileReader.onload = function (event) {
            fileresult = event.target.result;
            that.sliicerService.importCaseStudy(that.caseStudyCustomerId, that.caseStudyId, fileresult).subscribe(
                (result) => {
                    that.isLoadingState = false;
                    that.utilService.safeChangeDetection(that.cdr);
                    that.sliicerService.showToast(that.successImportCaseStudy, false);

                },
                () => {
                    that.isLoadingState = false;
                    that.utilService.safeChangeDetection(that.cdr);
                    that.sliicerService.showToast(that.failedToImportCaseStudy, true);
                },
            );
        }

        fileReader.readAsText(file);
    }

    /**
     * Handles deleting a case study
     */
    public deleteCaseStudy() {
        this.dialog
            .open(ConfirmationDialogComponent, {
                data: {
                    title: this.titleText,
                    message: this.deleteCaseStudyText + ' ' + this.caseStudyName + '?',
                    cancelText: this.deleteCancelText,
                    okText: this.deleteOkayText,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                if (res.whichButtonWasPressed === 'ok') {
                    this.isLoadingState = true;
                    this.sliicerService
                        .deleteCaseStudy(this.caseStudyCustomerId, this.caseStudyId, this.telemetryDatbaseName)
                        .subscribe(
                            () => {
                                this.isLoadingState = false;
                                this.utilService.safeChangeDetection(this.cdr);
                                this.sliicerService.showToast(this.deleteCaseStudySuccessfulText);
                                this.navigateToSliicerStudyDashoard(this.caseStudyCustomerId);
                            },
                            () => {
                                this.isLoadingState = false;
                                this.utilService.safeChangeDetection(this.cdr);
                                this.sliicerService.showToast(this.deleteCaseStudyFailureText, true);
                            },
                        );
                }
            });
    }

    /**
     * This method is triggered by the UI when the user asks to perform all calculations
     */
    public runAutoCalculations() {
        this.isLoadingState = true;
        this.powerBIEnabled = false;
        this.powerBIError = false;
        this.powerBiConfig = null;

        this.sliicerService.performAllCalculations(this.caseStudyCustomerId, this.caseStudyId).subscribe(
            () => {
                this.isLoadingState = false;
                this.utilService.safeChangeDetection(this.cdr);
                this.sliicerService.bustCache();
            },
            () => {
                this.isLoadingState = false;
                this.utilService.safeChangeDetection(this.cdr);
                this.sliicerService.showToast(this.failedToRunCalculations, true);
            },
        );
    }

    /**
     * Method to lock/unlock study
     * @param toggle
     */
    public lockstudy(toggle: MatSlideToggle) {
        this.isLoadingState = true;
        this.sliicerService.lockUnlockStudy(this.caseStudyCustomerId, this.caseStudyId, toggle.checked).subscribe(
            () => {
                this.isLoadingState = false;
                this.isLocked = toggle.checked;
                this.utilService.safeChangeDetection(this.cdr);
                this.sliicerService.caseStudyEditable.next(!this.isLocked);
                const lockStatusMessage = this.isLocked ? this.lockStatus : this.unlockStatus;
                this.sliicerService.showToast(lockStatusMessage);
            },
            () => {
                this.isLoadingState = false;
                this.utilService.safeChangeDetection(this.cdr);
                this.sliicerService.showToast(this.studyFailed, true);
            },
        );
    }

    /**
     * Method to mark a study as read-only
     * @param toggle
     */
    public markStudyReadonly(toggle: MatSlideToggle) {
        this.isLoadingState = true;
        this.sliicerService.markStudyReadonly(this.caseStudyCustomerId, this.caseStudyId, toggle.checked).subscribe(
            () => {
                this.isLoadingState = false;
                this.utilService.safeChangeDetection(this.cdr);
                this.sliicerService.showToast(this.readonlyPermissionMessage);
            },
            () => {
                this.isLoadingState = false;
                this.utilService.safeChangeDetection(this.cdr);
                this.sliicerService.showToast(this.readonlyPermissionFailedMessage, true);
            },
        );
    }

    public markStudyPreliminary(toggle: MatSlideToggle) {
        this.isLoadingState = true;
        const checked = toggle.checked;
        this.sliicerService.markStudyPreliminary(this.caseStudyCustomerId, this.caseStudyId, checked).subscribe(
            () => {
                this.preliminary = checked;
                this.isLoadingState = false;
                this.utilService.safeChangeDetection(this.cdr);
                this.sliicerService.showToast(this.preliminaryMessage);
            },
            () => {
                this.isLoadingState = false;
                this.utilService.safeChangeDetection(this.cdr);
                this.sliicerService.showToast(this.preliminaryFailedMessage, true);
            },
        );
    }

    public navigateToDashboard() {
        this.navigateToSliicerStudyDashoard(this.routeCustomerId);
    }

    private static copyToClipboard(value: string) {
        const el = document.createElement('textarea');
        el.setAttribute('readonly', '');
        el.style.opacity = '0';
        el.value = value;
        document.body.appendChild(el);
        el.select();
        el.setSelectionRange(0, 99999);
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    private setupSignalR() {
        const signalRSubs = this.signalRService.messageReceived.subscribe(async (res) => {
            // we only care about very specific circumstances
            if (res.message !== SliicerSignalRMessage.SLIICER_MESSAGE) {
                return;
            }
            if (res.type !== SignalRMessageType.User) {
                return;
            }

            if (res.message === VAULT_MOVE_SUCCESS_MESSAGE || res.message === VAULT_MOVE_FAIL_MESSAGE) {
                return;
            }
            const sliicerMsg = res as SliicerSignalRMessage;

            if ((<any>res).caseStudyId !== this.caseStudyId) {
                return;
            }

            if (sliicerMsg.messageType === SliicerMessageType.PowerBiUpdateComplete) {
                if (sliicerMsg.success) {
                // PowerBI code is temporarly commented out
                    // this.fetchPBIToken();
                } else {
                    // this.powerBIError = true;
                    // this.utilService.safeChangeDetection(this.cdr);
                }
            } else if (sliicerMsg.messageType === SliicerMessageType.CalculationsComplete) {
                this.sliicerService.bustCache();

                if (sliicerMsg.success) {
                    this.studyState = StudyState.Ready;
                    this.sliicerService.changeEditableBasedOnStatus(this.isEditableBasedOnStatus());
                    this.sliicerService.showToast(this.studyDataRefreshSuccessText);
                } else {
                    this.studyState = StudyState.CalculationFailed;
                    this.sliicerService.changeEditableBasedOnStatus(this.isEditableBasedOnStatus());
                }

                this.sliicerService.loadCaseStudy(this.routeCustomerId, this.caseStudyId);
            } else if (
                sliicerMsg.messageType === SliicerMessageType.CalculationsStarted ||
                sliicerMsg.messageType === SliicerMessageType.SingleTelemetryStart ||
                sliicerMsg.messageType === SliicerMessageType.SingleTelemetryEnd ||
                sliicerMsg.messageType === SliicerMessageType.TelemetryCopyComplete
            ) {
                this.studyState = StudyState.CalculationStarted;
                this.sliicerService.changeEditableBasedOnStatus(this.isEditableBasedOnStatus());
            }
        });

        this.subscriptions.push(signalRSubs);
    }

    private isTabChangeConfirmationShown = false;
    private oldTabIndex = 0;
    public tabChange(event: MatTabChangeEvent, tabGroup: MatTabGroup) {
        if (this.isTabChangeConfirmationShown) return;

        if (this.updatesWidgetService.warningDialogContainErrors()) {
            this.isTabChangeConfirmationShown = true;
            tabGroup.selectedIndex = this.oldTabIndex;

            this.updatesWidgetService.warningDialogOpen().subscribe((data: { whichButtonWasPressed: ConfirmationButton }) => {
                this.isTabChangeConfirmationShown = false;

                if (data && data.whichButtonWasPressed === ConfirmationButton.ok) {
                    this.updatesWidgetService.warningDialogClearErrors();
                    tabGroup.selectedIndex = event.index;
                    this.tabChangeHandler(event);
                }
            });
        } else {
            this.tabChangeHandler(event);
        }
    }

    private tabChangeHandler(event: MatTabChangeEvent) {
        this.oldTabIndex = event.index;
        if (event.index === this.TAB_RESULTS_POWERBI) {
            // PowerBI code is temporarly commented out
            // this.renderPowerBI();
            this.inResultsTab = true;
            this.checkStudyState();
        } else {
            this.inResultsTab = false;
            // if (this.powerbi) {
            //     this.powerbi.reset(this.reportElement);
            // }
        }
    }

    private checkStudyState() {
        const study = this.sliicerService.caseStudyDetails.getValue();
        const shouldRefresh = study.meta.basinState !== BasinStateEnum.ready;

        if (shouldRefresh) {
            this.runAutoCalculations();
        }
    }

    // PowerBI code is temporarly commented out

    // private renderPowerBI() {
    //     if (!this.reportContainer.last) {
    //         // this happens when the study is first being created
    //         return;
    //     }
    //     this.reportElement = this.reportContainer.last.nativeElement;

    //     if (!this.powerbi)
    //         this.powerbi = new pbi.service.Service(
    //             pbi.factories.hpmFactory,
    //             pbi.factories.wpmpFactory,
    //             pbi.factories.routerFactory,
    //         );
    //     this.report = this.powerbi.embed(this.reportElement, this.powerBiConfig);
    //     this.report.off('loaded');
    //     this.report.on('loaded', () => {
    //         console.log('Loaded');
    //     });
    //     this.report.on('error', () => {
    //         console.log('error !');
    //         if (++this.powerBiRetryCount > MAX_POWER_BI_ATTEMPTS) {
    //             // TODO: WPS logging. Does this even happen?
    //             // console.log("error: "+e);
    //             this.powerBILazyLoaded = false;
    //             return;
    //         }
    //         this.fetchPBIToken();

    //         this.report.off('error');
    //     });
    // }

    // private fetchPBIToken() {
    //     if (!this.powerBiConfig) {
    //         this.powerBIEnabled = false;
    //         this.powerBIError = false;
    //         this.sliicerService.getPBIStudyConfig(this.caseStudyId).subscribe(
    //             (result) => {
    //                 this.powerBIEnabled = true;
    //                 this.powerBiRetryCount = 0;
    //                 this.utilService.safeChangeDetection(this.cdr);

    //                 // TODO: WPS may want to cache the config so that we don't call this
    //                 // every time since the config lasts hours.
    //                 this.powerBiConfig = result;
    //                 this.powerBiConfig.tokenType = pbm.TokenType.Embed;
    //                 this.powerBiConfig.permissions = pbm.Permissions.All;
    //                 this.powerBiConfig.settings = {
    //                     filterPaneEnabled: true,
    //                     navContentPaneEnabled: true,
    //                 };
    //                 this.renderPowerBI();
    //             },
    //             () => {
    //                 this.powerBIError = true;
    //                 this.utilService.safeChangeDetection(this.cdr);
    //             },
    //         );
    //     } else {
    //         this.renderPowerBI();
    //     }
    // }

    // public powerBiFullscreen() {
    //     this.report.fullscreen();
    // }

    // need this prop because on customer change this method will be called twice
    private isWarningDialogOpened = false;
    private navigateToSliicerStudyDashoard(customerId: number) {
        if (this.updatesWidgetService.warningDialogContainErrors()) {

            if (this.isWarningDialogOpened) return;

            this.isWarningDialogOpened = true;
            const dialogSubs = this.updatesWidgetService.warningDialogOpen().subscribe((data) => {
                this.isWarningDialogOpened = false;
                if (data && data.whichButtonWasPressed === ConfirmationButton.ok) {
                    this.updatesWidgetService.warningDialogClearErrors();
                    this.navigateToSliicerStudyDashoard(this.routeCustomerId);
                }
            })

            this.subscriptions.push(dialogSubs);
            return;
        }
        const appQueryParams = <AppQueryParams>{
            [customerQueryParam]: customerId,
        };
        // TODO: WPS - There is a warning here about an unused promise. Suggests that there might be some states that we need to handle.
        //       If we can ignore the different states then add a TS-lint ignore statement here.

        const redirectOptions = {
            queryParams: appQueryParams,
            relativeTo: this.activatedRoute,
        };

        this.checkIfNeedToRecalc(this.sliicerService.caseStudyDetails.getValue());
        this.beforeDestroy();
        // Its done to be sure, that angular will destoy sliicer studies list component, (it always keeps last visited components alive,
        // default behaviour of angular)
        this.router.navigateByUrl('/', { ...redirectOptions, skipLocationChange: true }).then(() => {
            this.router.navigate(['/pages/sliicer'], redirectOptions);
        });
    }

    private validateCustomerStudyToCustomerId(routeCustomerId: number, caseStudyCustomerId: number) {
        if (routeCustomerId !== caseStudyCustomerId) {
            this.navigateToSliicerStudyDashoard(routeCustomerId);
        }
    }

    public onConfigurationFormValidation(isValid) {
        this.isConfigurationFormValid = isValid;
    }

    private setBetaFlag(routeCustomerId: number) {
        const userInfoSubs = this.statusCodeService.userInfo.subscribe((result: UsersInfo) => {
            if (!result.customers) {
                // TODO: WPS - I am most certain that this is broken. because this.isBetaFlag will never be set
                return;
            }

            const cutomerSelected = result.customers.find(
                (customer) => customer.customer.customerID === routeCustomerId,
            );
            if (
                (cutomerSelected.customer || result.features) &&
                cutomerSelected.customer.features &&
                (cutomerSelected.customer.features.length !== 0 || result.features.length !== 0)
            ) {
                const customerFeature = cutomerSelected.customer.features.find(
                    (features) => features.id === CUSTOMER_SLIICER_FEATURE_ID,
                );
                if (customerFeature) {
                    this.isBetaFlag = customerFeature.isbeta;
                }
            }
        });

        this.subscriptions.push(userInfoSubs);
    }
}
