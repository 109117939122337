<div id="hyetograph">
    <mat-table #table [dataSource]="blockDaysTableDataSource" matSort>
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.RAINFALL_MONITOR' | translate
            }}</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.name }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="days">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.BLOCK_DAYS' | translate
            }}</mat-header-cell>
            <mat-cell class="block-day-row" *matCellDef="let element">
                <ng-container *ngIf="element.days && element.days.length > 0">
                    <span class="block-day-box-cont green-box" *ngFor="let day of element.days">
                        <span class="date-formate">
                            {{ day | date: dateFormat }}
                        </span>
                        <i
                            class="material-icons"
                            [disabled]="isStudyLocked"
                            [class.disabled-link]="isStudyLocked"
                            (click)="deleteClickedDate(day, element.name)"
                        >
                            close
                        </i>
                    </span>
                </ng-container>
                <ng-container *ngIf="element.days && element.days.length === 0">
                    <span class="block-day-box-cont no-hover">&nbsp;</span>
                </ng-container>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="Action">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.ACTION' | translate
            }}</mat-header-cell>
            <mat-cell *matCellDef="let element">
                <mat-icon
                    *ngIf="element.days.length > 0"
                    [disabled]="isStudyLocked"
                    [class.disabled-link]="isStudyLocked"
                    (click)="deleteAllRowDaysOfRainfallMonitor(element.name)"
                    >delete</mat-icon
                >
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="blockDaysTableColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: blockDaysTableColumns"></mat-row>
    </mat-table>
</div>
