import {
    Component,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Inject,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BuildNumbers } from 'app/shared/models/build-numbers';
import { AboutUsService } from 'app/shared/services/about-us.service';
import { Subscription } from 'rxjs';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';

@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AboutUsComponent implements OnInit, OnDestroy {
    public buildNumbers: BuildNumbers;
    private subscription = new Array<Subscription>();
    public buildLoadingState: boolean;

    constructor(
        public aboutUsDialog: MatDialogRef<AboutUsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private aboutUsService: AboutUsService,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
    ) {}

    public ngOnInit() {
        this.buildLoadingState = true;
        const buildNumbersSubscription = this.aboutUsService.getBuildNumbers().subscribe(
            (response) => {
                this.buildNumbers = <BuildNumbers>response;
                this.buildLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            () => {
                this.buildLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
        this.subscription.push(buildNumbersSubscription);
    }

    public ngOnDestroy() {
        this.subscription.forEach((subscription) => subscription.unsubscribe());
    }

    /**
     * close About Us popup
     */
    public emitAboutUs() {
        this.aboutUsDialog.close({ success: false });
    }
}
