export type ValidationData = { rule: 'validString'; value: string } | { rule: 'validDate'; value: string | Date };

function isValidString(value: string): boolean {
    return value.length > 0;
}
function isValidDate(date: string | Date): boolean {
    return new Date(date).toString() != 'Invalid Date';
}
function ruleValidation(validationData: ValidationData): string {
    switch (validationData.rule) {
        case 'validString': {
            return isValidString(validationData.value) ? '' : 'COMMON.VALIDATION.REQUIRED';
        }
        case 'validDate': {
            return isValidDate(validationData.value) ? '' : 'COMMON.VALIDATION.INVALID_DATE';
        }
    }
}

export function validateRule(validationData: ValidationData): string {
    return ruleValidation(validationData);
}
