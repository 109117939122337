import {
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    OnDestroy,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { VaultDirectory } from 'app/shared/models/vault';

@Component({
    selector: 'app-sliicer-import-vault-study',
    templateUrl: './sliicer-import-vault-study.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliicerImportVaultStudyComponent implements OnInit, OnDestroy {
    public sliicerVaultColumns = ['fileName', 'fileSize', 'modifiedDate'];
    public slicerVaultDataSource = new MatTableDataSource([]);
    private vaultObservable;
    private configObservable;
    private subscriptions: Subscription[] = [];
    public disabledNext = true;
    public showImport: boolean;
    private selectedFileUri: string;
    private customerVaultName: string;
    public flowMonitorCount: number;
    public flowMonitorString: string;
    public rainfallMonitorCount: number;
    public rainfallMonitorString: string;
    public studyDate: string;
    public studyPeriod: number;
    public stepLength: number;
    public disabledImport = true;
    public customDateFormat: string;
    public noFilesExist: string;
    public unableToRetrieveFiles: string;
    public unableToGetAPIResponse: string;
    private dismiss: string;
    public duration = 3000;
    public isLoadingState: boolean;
    public isLoadingImport: boolean;
    public caseStudyConfigCreationSuccessfulTxt: string;
    public telemetryErrorMsg: string;

    private locationsSingular;
    private locationsPlural;

    constructor(
        private cdr: ChangeDetectorRef,
        private dialogRef: MatDialogRef<SliicerImportVaultStudyComponent>,
        private sliicerService: SliicerService,
        private utilService: UiUtilsService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(MAT_DIALOG_DATA) public caseStudyDetails,
        private translate: TranslateService,
        private dateUtilService: DateutilService,
        private snackBarNotificationService: SnackBarNotificationService,
    ) {
        this.dateUtilService.dateFormat.subscribe((newDateFormat) => {
            this.customDateFormat = this.dateUtilService.getStringFormat(newDateFormat);
        });
    }

    public ngOnInit() {
        this.applyTranslation();
        if (this.data && this.data.fileUri && this.data.vaultName) {
            this.selectedFileUri = this.data.fileUri;
            this.customerVaultName = this.data.vaultName;
            this.loadConfigData();
        } else {
            this.getSliicerVaultFiles();
        }
    }

    private applyTranslation() {
        const translateKeys: Array<string> = [
            'COMMON.LOCATION',
            'COMMON.LOCATIONS',
            'VAULT.VAULT_DASHBOARD.NO_FILE_EXISTS',
            'COMMON.DISMISS_TEXT',
            'VAULT.UNABLE_TO_RETRIEVE_FILE',
            'COMMON.API_FAILURE_MESSAGE',
            'SLIICER_TABLE.SLIICER_CONFIGURATION_COMPLETE',
            'VAULT.VAULT_TELEMETRY.IMPORT.TELEMENTRY_MSG_ERR',
        ];
        this.translate.get(translateKeys).subscribe((translateValues) => {
            if (!translateValues) {
                return;
            }
            this.locationsSingular = translateValues['COMMON.LOCATION'];
            this.locationsPlural = translateValues['COMMON.LOCATIONS'];
            this.noFilesExist = translateValues['VAULT.VAULT_DASHBOARD.NO_FILE_EXISTS'];
            this.unableToRetrieveFiles = translateValues['VAULT.UNABLE_TO_RETRIEVE_FILE'];
            this.dismiss = translateValues['COMMON.DISMISS_TEXT'];
            this.unableToGetAPIResponse = translateValues['COMMON.API_FAILURE_MESSAGE'];
            this.caseStudyConfigCreationSuccessfulTxt = translateValues['SLIICER_TABLE.SLIICER_CONFIGURATION_COMPLETE'];
            this.telemetryErrorMsg = translateValues['VAULT.VAULT_TELEMETRY.IMPORT.TELEMENTRY_MSG_ERR'];
        });
    }

    /** Method to retrieve all sliicer vault files for the customer **/
    private getSliicerVaultFiles() {
        this.isLoadingState = true;
        this.vaultObservable = this.sliicerService
            .getVaultFiles(this.data.customerID, this.data.studyVaultName)
            .subscribe(
                (result: VaultDirectory) => {
                    this.isLoadingState = false;
                    if (
                        !result ||
                        result.sub_directories === null ||
                        result.sub_directories[0].files.length === 0 ||
                        result.sub_directories.length === 0
                    ) {
                        this.snackBarNotificationService.raiseNotification(this.noFilesExist, this.dismiss, {
                            panelClass: 'custom-error-snack-bar',
                        }, false);
                        this.close(false);
                    } else {
                        this.customerVaultName = result.customerVaultName;
                        this.slicerVaultDataSource = new MatTableDataSource(result.sub_directories[0].files);
                    }
                    this.utilService.safeChangeDetection(this.cdr);
                },
                () => {
                    this.isLoadingState = false;
                    this.snackBarNotificationService.raiseNotification(this.unableToRetrieveFiles, this.dismiss, {
                        panelClass: 'custom-error-snack-bar',
                    }, false);
                    this.close(false);
                },
            );
        this.subscriptions.push(this.vaultObservable);
    }

    public fileSelected(event: MatRadioChange) {
        this.selectedFileUri = event.value;
        this.disabledNext = false;
    }

    /** Method to retrieve config data from Legacy API **/
    public loadConfigData() {
        this.isLoadingState = true;
        let fileUri = this.selectedFileUri;
        if (fileUri[0] === '/') {
            fileUri = fileUri.substring(1, fileUri.length);
        }
        this.configObservable = this.sliicerService.getFlowLoadConfig(this.customerVaultName, fileUri).subscribe(
            (result) => {
                this.isLoadingState = false;
                if (result['status'] === 'success') {
                    this.parseConfig(result['config']);
                } else {
                    this.sliicerService.showToast(this.unableToGetAPIResponse, true);
                    this.close(false);
                }
                this.utilService.safeChangeDetection(this.cdr);
            },
            (result) => {
                this.isLoadingState = false;
                this.utilService.safeChangeDetection(this.cdr);
                this.sliicerService.showToast(this.unableToGetAPIResponse, true);
                this.close(false);
            },
        );
        this.showImport = true;
        this.subscriptions.push(this.configObservable);
    }

    private parseConfig(config: any) {
        const flowMonitors = config['flowMonitors'];
        this.flowMonitorCount = flowMonitors.length;
        this.flowMonitorString = `${this.flowMonitorCount} ${
            this.flowMonitorCount === 1 ? this.locationsSingular : this.locationsPlural
        }`;

        const rainfallMonitors = config['rainfallMonitors'];
        this.rainfallMonitorCount = rainfallMonitors.length;
        this.rainfallMonitorString = `${this.rainfallMonitorCount} ${
            this.rainfallMonitorCount === 1 ? this.locationsSingular : this.locationsPlural
        }`;

        this.studyDate = this.sliicerService.getStudyDate(
            config['startDate'],
            config['endDate'],
            this.customDateFormat,
        );

        this.studyPeriod = SliicerService.GetDaysInStudy(config['startDate'], config['endDate']);

        this.stepLength = config['stepLengthMinutes'];

        this.disabledImport = this.flowMonitorCount === 0 || this.rainfallMonitorCount === 0;
    }

    /** Method to retrieve all sliicer vault files for the customer **/
    public importCaseStudy() {
        this.isLoadingImport = true;
        let fileUri = this.selectedFileUri;
        if (fileUri[0] === '/') {
            fileUri = fileUri.substring(1, fileUri.length);
        }
        this.sliicerService
            .getFlowLoadImport(this.customerVaultName, fileUri, this.data.customerID, this.data.id)
            .subscribe((result) => {
                if (result && result['status'] === 'success' && result['profileFilename']) {
                    const filePath = fileUri.split('/');
                    if (filePath && filePath.length > 2) {
                        fileUri = filePath[0] + '/' + filePath[1] + '/' + result['profileFilename'];
                        this.sliicerService
                            .legacyCopyTelemetry(this.customerVaultName, fileUri, this.data.customerID, this.data.id)
                            .subscribe((telResult) => {
                                this.isLoadingImport = false;
                                if (telResult === null || telResult['status'] === 'error') {
                                    this.snackBarNotificationService.raiseNotification(
                                        this.telemetryErrorMsg,
                                        this.dismiss,
                                        {
                                            panelClass: 'custom-error-snack-bar',
                                        },
                                        false
                                    );
                                } else {
                                    this.snackBarNotificationService.raiseNotification(
                                        this.caseStudyConfigCreationSuccessfulTxt,
                                        this.dismiss,
                                        { duration: this.duration },
                                    );
                                }
                                this.close(true);
                                this.utilService.safeChangeDetection(this.cdr);
                            });
                    } else {
                        this.isLoadingImport = false;
                        this.snackBarNotificationService.raiseNotification(this.unableToGetAPIResponse, this.dismiss, {
                            panelClass: 'custom-error-snack-bar',
                        }, false);
                        this.close(false);
                    }
                } else {
                    this.isLoadingImport = false;
                    this.snackBarNotificationService.raiseNotification(this.unableToGetAPIResponse, this.dismiss, {
                        panelClass: 'custom-error-snack-bar',
                    }, false);
                    this.close(false);
                }
            });
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscripton) => subscripton.unsubscribe());
    }

    /** Close Dialog */
    public close(isSuccess: boolean) {
        this.dialogRef.close({ success: isSuccess });
    }
}
