import { Component, OnInit, ViewEncapsulation, Inject, Optional, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'app/pages/admin/users.service';
import { HTTP_STATUS_CODE_CONCURRENCY, HTTP_STATUS_CODE_CONFLICT, TRITON_SAMPLER} from 'app/shared/constant';
import { ECHO_SERIES, RAINALERT_SERIES } from 'app/shared/models/TritonLocation';
import { TritonLocationService } from 'app/shared/services/tritonLocation.service';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { MonitorSeriesNames, TritonSensorNames, ForeSITESensors, MonitorSeries } from '../../location-card.constants';
import { assembleFormValues, assembleTritonMonitoringPoints } from '../../models/triton-location-assembler';
import { LocationCardService } from '../../services/location-card.service';
import { Series} from 'app/shared/constant';
import { LocationFormBuilder } from '../../services/location-form-builder';
import { ConfirmationType } from 'app/shared/models/monitorsetting';
import { DialogActionsComponent } from '../dialog-actions/dialog-actions.component';
import { MONITOR_SERIES_TYPES } from 'app/shared/models/monitor-series-types';
import { GISService } from 'app/shared/services/gis-service';
import { first } from 'rxjs/operators';
import { gisUserSettings } from 'app/shared/models/gis-service-list';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LocationService } from 'app/shared/services/location.service';
import { LocationDashboardService } from 'app/shared/services/location-dashboard.service';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MonitorSettingService } from 'app/shared/services/monitor-setting.service';
import { LocationArgs, Locations, LocationStatus } from 'app/shared/models/locations';
import { LocationDetails, MonitorSeriesUI } from 'app/shared/models/location-details';
import { Customer, customerLocationGroupQueryParam } from 'app/shared/models/customer';
import { IComponentCustomizedConfirmationResult } from 'app/shared/models/customized-confirmation-box';
import { TritonBasicInfoComponent } from '../triton-basic-info/triton-basic-info.component';
import { CustomerService } from 'app/shared/services/customer.service';
import { UiService } from 'app/shared/services/ui.service';
import { HttpErrorResponse } from '@angular/common/http';
import { LocationCardData } from '../../models/location-card.model';

interface ConfirmationInput {
    title: string;
    message: string;
    okText: string;
    cancelText: string;
}

@Component({
    selector: 'app-triton-location-dialog',
    templateUrl: './triton-location-dialog.component.html',
    styleUrls: ['./triton-location-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TritonLocationDialogComponent implements OnInit {
    @ViewChild(TritonBasicInfoComponent) basicInfo: TritonBasicInfoComponent;
    @ViewChild(DialogActionsComponent) actions: DialogActionsComponent;
    @ViewChild('dialog') dialogDiv: ElementRef<HTMLDivElement>;
    public locationForm: FormGroup;
    public sensorNames = TritonSensorNames;
    public foreSITESensorNames = ForeSITESensors;
    public locations: Locations[] = [];

    public includeActiveLocation: number;
    public locationGroupId: number;
    public conformationDataInput: ConfirmationInput;
    public showConfirmation = false;

    public locationDetails: LocationDetails[] = [];
    public monitorConfigs;
    public series: number;
    public alarmConfigs: Array<MonitorSeriesUI>;
    public timezone: { value: string, text: string; utcValue: string };

    public monitorSeriesChange$ = new Subject<string>();
    public installationSeriesChange$ = new Subject<number>();
    public mp2InstallationSeriesChange$ = new Subject<number>();

    private dismissButton: string;
    private locationAddedSuccessMessage: string;
    private locationAddedErrorMessage: string;
    private confirmationAction: ConfirmationType;
    private shouldPopulateMp2 = false;

    public defaultRoughness = 0.013;

    public customProperties: string[] = [];

    public MonitorSeriesNames = MonitorSeriesNames;

    constructor(
        public dialogRef: MatDialogRef<TritonLocationDialogComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: LocationCardData,
        public locationFormBuilder: LocationFormBuilder,
        private uiUtilsService: UiUtilsService,
        private locationDashboardService: LocationDashboardService,
        private snackbar: MatSnackBar,
        private userService: UsersService,
        private locationCardService: LocationCardService,
        private monitorSettingService: MonitorSettingService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        public translateService: TranslateService,
        private gisService: GISService,
        private customerService: CustomerService,
        private locationService: LocationService,
        private tritonLocationService: TritonLocationService,
        private uiService: UiService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.dialogRef.afterOpened().subscribe(() => {this.uiService.disable()});
        this.dialogRef.afterClosed().subscribe(() => {this.uiService.enable()});

        this.getUserTimezone();
        this.setConfirmationInput();
        this.locationCardService.getCustomerSetting();

        if (this.data.editMode) {
            this.populateFormValues();
        } else {
            this.customerService.getCustomerById(this.data.customerId).subscribe((customer) => {
                this.defaultRoughness = customer.roughness;
            });
            this.createForm();
        }

        this.getCustomProperties();

        this.includeActiveLocation = Number(this.activatedRoute.snapshot.queryParamMap.get('lt'));
        this.locationGroupId = Number(this.activatedRoute.snapshot.queryParamMap.get(customerLocationGroupQueryParam));

        this.dismissButton = this.translateService.instant('COMMON.DISMISS_TEXT');
        this.locationAddedSuccessMessage = this.translateService.instant('COMMON.LOCATION_ADDED_MESSAGE');
        this.locationAddedErrorMessage = this.translateService.instant('COMMON.LOCATION_ADDED_ERROR');

        this.locationService.getMonitorSeries().subscribe(
            (monitors: Array<MonitorSeriesUI>)=> {
                this.alarmConfigs = monitors;
            }
        );
    }

    createForm() {
        this.locationForm = this.locationFormBuilder.createTritonForm(this.locationCardService.isMetric);

        this.getLocations();

        for (const locDetails of this.locationDetails) {
            // #15820 set default if not exist
            this.series = locDetails.installationShapeTypeID ;
            if (locDetails && locDetails.installationShapeTypeID === 200) {
                if (locDetails.roughness === undefined || locDetails.roughness === null) locDetails.roughness = this.defaultRoughness;
                if (locDetails.effectiveRoughness === undefined || locDetails.effectiveRoughness === null) locDetails.effectiveRoughness = this.defaultRoughness;
            }
        }

        const isBasicDataEditingAllowed = this.userService.isBasicDataEditingAllowed.getValue();
        if (!isBasicDataEditingAllowed) {
            this.locationForm.get('basicInfo').get('qFinalEntityID').disable();

            const mp2BasicInfo = this.locationForm.get('mp2BasicInfo');
            if (mp2BasicInfo) {
                mp2BasicInfo.get('qFinalEntityID').disable();
            }
        }
    }

    getMonitorSeries() {
        if (!this.locationForm) return '';
        return this.locationForm.get('basicInfo').get('series').value;
    }

    isOtherMonitorSeriesSelected() {
        const ms = this.getMonitorSeries();

        return (
            ms &&
            ms !== MonitorSeriesNames.triton &&
            ms !== MonitorSeriesNames.rainAlert &&
            ms !== MonitorSeriesNames.echo &&
            ms?.toUpperCase() !== MonitorSeriesNames.ForeSITE_UL &&
            ms?.toUpperCase() !== MonitorSeriesNames.ForeSITE_Flex
        );
    }

    onMonitorSeriesChange(monitorName: string) {
        this.monitorSeriesChange$.next(monitorName);

        this.checkFlowForm();
        this.resetForm();
        if (monitorName === MonitorSeriesNames.triton) {
            this.locationForm.addControl(
                'tritonInfo',
                this.locationFormBuilder.generateTritonInfo(this.locationCardService.isMetric),
            );
            this.locationForm.addControl('tritonSensors', this.locationFormBuilder.generateBlankSensors());
        } else if (monitorName?.toUpperCase() === MonitorSeriesNames.ForeSITE_Flex) {
            this.locationForm.addControl(
                'foreSITEFlexInfo',
                this.locationFormBuilder.generateForeSITEFlexInfo(this.locationCardService.isMetric),
            );
            this.locationForm.addControl('foreSITEFlexSensors', this.locationFormBuilder.generateBlankForeSITEFlexSensors());
        }
        else if (monitorName === MonitorSeriesNames.echo) {
            this.locationForm.addControl(
                'echoInfo',
                this.locationFormBuilder.generateEchoInfo(this.locationCardService.isMetric),
            );
            this.locationForm.addControl(
                'echoLongRange',
                this.locationFormBuilder.generateEchoLongRange(this.locationCardService.isMetric),
            );
            this.locationForm
                .get('echoLongRange')
                .patchValue({ manholedepth: this.locationForm.value.basicInfo.manholedepth });
        } else if (monitorName === MonitorSeriesNames.rainAlert) {
            this.locationForm.addControl('rainalertInfo', this.locationFormBuilder.generateRainalertInfo());
            this.locationForm.addControl(
                'rainSensor',
                this.locationFormBuilder.generateRainSensorInfo(this.locationCardService.isMetric),
            );
        }
        this.setDataDeliveryForm(monitorName);
        this.checkAlarmInfoOnMonitorSeries(
            monitorName === MonitorSeriesNames.rainAlert,
            monitorName === MonitorSeriesNames.echo,
        );
    }

    public onSensorRemove(index: 0 | 1) {
        const tritonSensors: FormArray = this.locationForm.get('tritonSensors') as FormArray;
        tritonSensors.removeAt(index);

        const shouldKeepMp2 = (this.locationForm.get('tritonSensors') as FormArray).value.every((v) => v && v.mp === 1);
        if (!shouldKeepMp2) {
            this.locationForm.removeControl('mp2BasicInfo');
            this.locationForm.removeControl('mp2AlarmsInfo');
        }

        this.checkFlowForm();
    }

    public onTritonSensorChange(event: { name: string; monitorId: 0 | 1; mp: 0 | 1; device }) {
        const { name, monitorId, mp, device } = event;
        const tritonSensors: FormArray = this.locationForm.get('tritonSensors') as FormArray;

        const existingSensor = tritonSensors.value.findIndex((v) => v && v.index === monitorId);
        if (existingSensor !== -1) {
            tritonSensors.removeAt(existingSensor);
            tritonSensors.insert(
                existingSensor,
                this.locationFormBuilder.getSensorFormByName(
                    name,
                    existingSensor,
                    mp,
                    this.locationCardService.isMetric,
                ),
            );
        } else {
            tritonSensors.removeAt(monitorId);
            tritonSensors.insert(
                monitorId,
                this.locationFormBuilder.getSensorFormByName(name, monitorId, mp, this.locationCardService.isMetric),
            );
        }

        if (device) {
            tritonSensors.controls[monitorId].patchValue({ ...device, mp, name });
        }
        if (name === TritonSensorNames.longRange) {
            this.locationForm
                .get('basicInfo')
                .get('manholedepth')
                .setValidators([
                    Validators.required,
                    Validators.pattern(
                        this.locationCardService.isMetric
                            ? REGEX_CONFIG.numericDecimal1PlacePattern
                            : REGEX_CONFIG.numeric0_100000With2DecimalPlacesPattern,
                    ),
                    Validators.maxLength(50),
                ]);
        } else {
            this.locationForm
                .get('basicInfo')
                .get('manholedepth')
                .setValidators([
                    Validators.pattern(
                        this.locationCardService.isMetric
                            ? REGEX_CONFIG.numericDecimal1PlacePattern
                            : REGEX_CONFIG.numeric0_100000With2DecimalPlacesPattern,
                    ),
                    Validators.maxLength(50),
                ]);
        }

        this.locationForm.get('basicInfo').get('manholedepth').updateValueAndValidity();

        const shouldAddMp2 = tritonSensors.value.some((v) => v && v.mp === 1);

        if (shouldAddMp2) {
            const isInactive = this.locationForm.get('basicInfo').get('status').value === LocationStatus.Inactive;
            const monitorName = this.locationForm.get('basicInfo').get('series').value;
            this.locationFormBuilder.addMp2(this.locationForm, this.locationCardService.isMetric, isInactive);

            const isRainAlertPicked =
                monitorName.toUpperCase() === RAINALERT_SERIES || monitorName.toUpperCase() === 'RAINALERT II';

            if (isRainAlertPicked) {
                this.locationForm.get('mp2BasicInfo').get('assignedRainGauge').disable();
            }

            if (this.shouldPopulateMp2) {
                this.shouldPopulateMp2 = false;
                const installationDate = this.locationDetails[1].installationDate
                    ? new Date(this.locationDetails[1].installationDate)
                    : null;
                this.locationForm.patchValue({ mp2BasicInfo: { ...this.locationDetails[1], installationDate } });
            }
        }

        this.checkFlowForm();
    }

    public onForeSITESensorRemove(index: 0 | 1) {
        const foreSITEFlexSensors: FormArray = this.locationForm.get('foreSITEFlexSensors') as FormArray;
        foreSITEFlexSensors.removeAt(index);
    }

    public onForeSITESensorChange(event: { name: string; monitorId: 0 | 1; mp: 0 | 1; device }) {
        const { name, monitorId, mp, device } = event;
        const foreSITEFlexSensors: FormArray = this.locationForm.get('foreSITEFlexSensors') as FormArray;

        const existingSensor = foreSITEFlexSensors.value.findIndex((v) => v && v.index === monitorId);
        if (existingSensor !== -1) {
            foreSITEFlexSensors.removeAt(existingSensor);
            foreSITEFlexSensors.insert(
                existingSensor,
                this.locationFormBuilder.getSensorFormByName(
                    name,
                    existingSensor,
                    mp,
                    this.locationCardService.isMetric,
                ),
            );
        } else {
            foreSITEFlexSensors.removeAt(monitorId);
            foreSITEFlexSensors.insert(
                monitorId,
                this.locationFormBuilder.getSensorFormByName(name, monitorId, mp, this.locationCardService.isMetric),
            );
        }

        if (device) {
            if(this.locationCardService.isMetric)
            {
                device.maxMeasurementRange = Math.round(device.maxMeasurementRange);
                device.physicalOffset = Number(device.physicalOffset);
            }
            foreSITEFlexSensors.controls[monitorId].patchValue({ ...device, mp, name });
        }
    }


    public onMonitorPointChange(event: { monitors; index: number; mp: number }) {
        const { monitors, index, mp } = event;
        const tritonSensors: FormArray = this.locationForm.get('tritonSensors') as FormArray;
        const mp2Sensor = monitors.find((v) => v.mp === 1);
        const mp2Form = this.locationForm.get('mp2BasicInfo');

        if (!mp2Sensor) {
            this.locationForm.removeControl('mp2BasicInfo');
            this.locationForm.removeControl('mp2AlarmsInfo');
            this.locationForm.removeControl('flowInfo2');
        }

        if (mp2Sensor && !mp2Form) {
            const isInactive = this.locationForm.get('basicInfo').get('status').value === LocationStatus.Inactive;
            this.locationFormBuilder.addMp2(this.locationForm, this.locationCardService.isMetric, isInactive);
        }

        this.checkFlowForm();
        tritonSensors.controls[index].patchValue({ mp });
    }

    public onManholeDepthChange() {
        const lrd = this.locationForm.get('echoLongRange');
        if (!lrd) {
            return;
        }

        const manholeDepth = this.locationForm.get('basicInfo').get('manholedepth').value;
        lrd.patchValue({ manholedepth: manholeDepth });
    }

    public onLocationDashboardClicked() {
        if (this.locationForm.dirty) {
            this.confirmationAction = ConfirmationType.LocationDashboard;
            this.showConfirmation = true;
        } else {
            this.closeDialog(false);
            this.openLocationDashboard();
        }
    }

    public onComportChange(event: MatSelectChange) {
        if (event.value === TRITON_SAMPLER) {
            this.locationForm.addControl('samplerInfo', this.locationFormBuilder.generateSamplerInfo());
        } else {
            this.locationForm.removeControl('samplerInfo');
        }
    }

    public handleConfirmationDialogResult(event: IComponentCustomizedConfirmationResult) {
        this.showConfirmation = false;

        if (event.whichButtonPressed === 'ok') {
            switch (this.confirmationAction) {
                case ConfirmationType.Close: {
                    this.closeDialog(false);
                    break;
                }
                case ConfirmationType.LocationDashboard: {
                    this.closeDialog(false);
                    this.openLocationDashboard();
                    break;
                }
                case ConfirmationType.DataCollection: {
                    this.actions.onCollectLocation();
                    break;
                }
                case ConfirmationType.ScheduleCollection: {
                    this.actions.navigateToScheduleCollectDashboard();
                    break;
                }
                case ConfirmationType.LocationExport: {
                    this.onSaveClicked({ shouldClose: false, isActivationRequired: false });
                    break;
                }
            }
        }

        if (event.whichButtonPressed === 'cancel') {
            if (this.confirmationAction === ConfirmationType.LocationExport) {
                this.actions.exportLocationXml();
                this.conformationDataInput.message = this.translateService.instant('ADD_EDIT.EDIT_CONFIRM_MSG');
            } else if (this.confirmationAction === ConfirmationType.Close) {
                this.showConfirmation = false;
            }
        }
    }

    public onCollectLocationClicked() {
        if (this.locationForm.dirty) {
            this.confirmationAction = ConfirmationType.DataCollection;
            this.showConfirmation = true;
        } else {
            this.actions.onCollectLocation();
        }
    }

    public onScheduleCollectClicked() {
        if (this.locationForm.dirty) {
            this.confirmationAction = ConfirmationType.ScheduleCollection;
            this.showConfirmation = true;
        } else {
            this.actions.navigateToScheduleCollectDashboard();
        }
    }

    public onExportLocationClicked() {
        if (this.locationForm.dirty) {
            this.conformationDataInput.message = this.translateService.instant('ADD_EDIT.EDIT_CONFIRM_CONTENT');
            this.confirmationAction = ConfirmationType.LocationExport;
            this.showConfirmation = true;
        } else {
            this.actions.exportLocationXml();
        }
    }

    public onCancelClicked() {
        if (this.locationForm.dirty) {
            this.confirmationAction = ConfirmationType.Close;
            this.showConfirmation = true;
        } else {
            this.closeDialog(false);
        }
    }

    public onMp1StatusChange(event: MatSelectChange) {
        const mp2 = this.locationForm.get('mp2BasicInfo');
        if (event.value === LocationStatus.Inactive && mp2) {
            mp2.patchValue({ status: LocationStatus.Inactive });
        }

        if (event.value === LocationStatus.Maintenance) {
            this.locationForm.get('basicInfo').patchValue({ maintenanceEnd: null, maintenanceInterval: 2 });
        }
    }

    public onSaveClicked(event: { shouldClose: boolean; isActivationRequired: boolean }) {
        const { shouldClose, isActivationRequired } = event;
        if (this.data.editMode) {
            this.updateLocation(shouldClose, isActivationRequired);
        } else {
            this.saveLocation(shouldClose, isActivationRequired);
        }
    }

    public dragEvent() {
        const { x: locationPositionX, y: locationPositionY } = this.dialogDiv.nativeElement.getClientRects()[0] as any;

        this.gisService.gisUserSettingsSubject$.pipe(first()).subscribe((currentSettings: gisUserSettings) => {
            this.gisService
                .updateGisUserSettings({ ...currentSettings, locationPositionX, locationPositionY })
                .subscribe(() => {
                    this.gisService.locationCardPosition = this.locationCardService.getLocationCardPosition(true, { locationPositionX, locationPositionY });
                });
        });
    }

    public onPipeHeightChange(event: { value: number; isMp2: boolean }) {
        const { value, isMp2 } = event;
        const alarms = isMp2 ? this.locationForm.get('mp2AlarmsInfo') : this.locationForm.get('alarmsInfo');

        alarms.patchValue({ fullPipethreshold: value });
    }

    public onInstallationSeriesChange(event: { value: number }){
        const foreSITESensors = this.locationForm.get('foreSITEFlexSensors') as FormArray;
        if(event.value === Series.ChannelStandard)
        {
            foreSITESensors?.controls[0]?.get('physicalOffset')?.disable();
            foreSITESensors?.controls[0]?.get('physicalOffset')?.patchValue(0);
        }
        else{
            foreSITESensors?.controls[0]?.get('physicalOffset')?.enable();
        }

        this.installationSeriesChange$.next(event.value);
    }

    public onMp2InstallationSeriesChange(event: {value: number}) {
        this.mp2InstallationSeriesChange$.next(event.value);
    }

    public activateMonitor(customerId: number, locationId: number, shouldClose: boolean, formResult = false) {
        this.tritonLocationService.activateLocation(customerId, locationId).subscribe(
            (result) => {
                this.snackbar.open(result['responseMessage'], this.dismissButton, {
                    duration: 10000
                });

                if (shouldClose) {
                    this.closeDialog(formResult);
                }
            },
            (error) => {
                if (error && error._body && JSON.parse(error._body) && JSON.parse(error._body).responseMessage) {
                    this.snackbar.open(JSON.parse(error._body).responseMessage, this.dismissButton, {
                        panelClass: 'custom-error-snack-bar',
                    });
                } else {
                    const locationName = this.locationForm.get('basicInfo').get('locationName').value;
                    this.snackbar.open(
                        `${locationName} ${this.translateService.instant('ADD_EDIT.ACTIVATION_HAS_FAILED')}`,
                        this.dismissButton,
                        { panelClass: 'custom-error-snack-bar' },
                    );
                }
            },
        );
    }

    public activateLocation() {
        // #22579 inform user that activation was initiated
        this.snackbar.open(
            this.translateService.instant('COMMON.MONITOR_ACTIVATE_REQUEST_INITIATED'),
            this.dismissButton, {
                duration: 3000,
            }
        );

        const lon = this.locationForm.get('basicInfo').get('longitude').value;
        const lat = this.locationForm.get('basicInfo').get('latitude').value;
        this.locationForm.get('basicInfo').patchValue({ longitude: lon ? lon : 1, latitude: lat ? lat : 1 });

        if (this.data.editMode && this.locationForm.dirty) {
            this.onSaveClicked({ shouldClose: true, isActivationRequired: true });
        } else {
            const formValue = this.locationForm.getRawValue();
            const mps = assembleTritonMonitoringPoints(formValue, this.data);
            this.activateConfig(formValue.basicInfo.series, true, { ...mps }, true);
        }
    }

    public openLocationDashboard() {
        this.router.navigate(['/pages/menuDashboard/viewData'], {
            queryParams: {
                c: this.data.customerId,
                lg: this.locationGroupId,
                lid: this.data.locationDetails.locationID,
                lt: this.includeActiveLocation,
            },
            relativeTo: this.activatedRoute,
        });
    }

    private getUserTimezone() {
        this.customerService.getTimeZone().subscribe((timezones) => {
            this.timezone = timezones.find((v) => v.value === this.customerService.customer.timeZone);
        });
    }

    public updateLocation(shouldClose: boolean, isActivationRequired: boolean, isFromRecall = false) {
        const formValue = this.locationForm.getRawValue();
        const basicInfo = assembleFormValues(formValue, this.data, this.locationDetails);
        const mps = assembleTritonMonitoringPoints(formValue, this.data);

        if (mps) {
            mps.locationid = this.data.locationDetails.locationID;
        }
        this.tritonLocationService.updateLocation(this.data.customerId, basicInfo).subscribe(() => {
            const isInactive = this.locationForm.get('basicInfo').get('status').value === LocationStatus.Inactive;
            this.activateConfig(formValue.basicInfo.series, shouldClose, { ...mps }, isActivationRequired, isInactive);
            this.snackbar.open(`${basicInfo[0].locationName} updated successfully`, this.dismissButton, {
                duration: 10000,
            });
            if (shouldClose) {
                this.closeDialog(true);
            }
        },
        (error: HttpErrorResponse) => {
            if (error.status === HTTP_STATUS_CODE_CONCURRENCY && isFromRecall === false) {
                this.updateLocation(shouldClose, isActivationRequired, true);
            } else if (error.status === HTTP_STATUS_CODE_CONFLICT) {
                this.snackbar.open(error.error, this.dismissButton, {
                    panelClass: 'custom-error-snack-bar',
                });
            } else {
                this.snackbar.open(
                    this.translate.instant('COMMON.LOCATION_UPDATED_ERROR'),
                    this.translateService.instant('COMMON.CLOSE'),
                    {
                    panelClass: 'custom-error-snack-bar',
                });
            }
        });
    }

    public saveLocation(shouldClose: boolean, isActivationRequired: boolean) {
        const formValue = this.locationForm.getRawValue();
        const basicInfo = assembleFormValues(formValue, this.data);
        const mps = assembleTritonMonitoringPoints(formValue, this.data);

        this.tritonLocationService.addLocation(this.data.customerId, basicInfo).subscribe(
            (data) => {
                if (basicInfo.length > 1) {
                    basicInfo[0].locationid = data - 1;
                    basicInfo[1].locationid = data;
                } else {
                    basicInfo[0].locationid = data;
                }

                this.locationDetails = basicInfo.map((v) => ({ ...v, ['locationID']: v.locationid }));

                this.snackbar.open(basicInfo[0].locationName + this.locationAddedSuccessMessage, this.dismissButton, {
                    duration: 3000,
                });
                this.data = { ...this.data, locationDetails: { locationID: data } as any, editMode: true };
                this.activateConfig(
                    formValue.basicInfo.series,
                    shouldClose,
                    { ...mps, locationid: data },
                    isActivationRequired,
                );
            },
            (error: HttpErrorResponse) => {
                if (error.status === HTTP_STATUS_CODE_CONFLICT) {
                    this.snackbar.open(error.error, this.dismissButton, {
                        panelClass: 'custom-error-snack-bar',
                    });
                } else {
                    this.snackbar.open(this.locationAddedErrorMessage, this.dismissButton, {
                            panelClass: 'custom-error-snack-bar',
                    });
                }

            },
        );
    }

    private activateConfig(series: string, shouldClose: boolean, formValue, isActivationRequired: boolean, isInactive = false) {
        let activationRequest;

        formValue.timeZone = this.timezone.text;
        const isFSUL = series.toUpperCase() === MonitorSeriesNames.ForeSITE_UL;
        const isFSFlex = series.toUpperCase() === MonitorSeriesNames.ForeSITE_Flex;
        if (isInactive) {
            activationRequest = of(null);
        } else if (series.toUpperCase() === RAINALERT_SERIES) {
            activationRequest = this.monitorSettingService.rainAlert(formValue, this.data.customerId);
        } else if (series.toUpperCase() === ECHO_SERIES) {
            activationRequest = this.monitorSettingService.echoMonitorConfiguration(formValue, this.data.customerId);
        } else if (series.toUpperCase() === MONITOR_SERIES_TYPES.TRITON_PLUS) {
            activationRequest = this.tritonLocationService.tritonMonitorConfiguration(formValue, this.data.customerId);
        } else if (isFSUL) {
            activationRequest = this.monitorSettingService.foreSiteUl(formValue, this.data.customerId);
        } else if (isFSFlex) {
            activationRequest = this.monitorSettingService.foreSITEFlex(formValue, this.data.customerId);
        }

        if (activationRequest) {
            activationRequest.subscribe(() => {
                if (isActivationRequired && !isInactive && !isFSUL && !isFSFlex) {
                    this.activateMonitor(this.data.customerId, this.data.locationDetails.locationID, shouldClose, true);
                } else if (shouldClose) {
                    this.closeDialog(true);
                }
            });
        }
    }

    private setDataDeliveryForm(series: string) {
        if (series?.toUpperCase() === MonitorSeriesNames.ForeSITE_UL || series?.toUpperCase() === MonitorSeriesNames.ForeSITE_Flex) {
            this.locationForm.setControl('dataDelivery', this.locationFormBuilder.generateFSdataDelivery());
        } else {
            this.locationForm.setControl('dataDelivery', this.locationFormBuilder.generateTritonDataDelivery());
        }
    }

    private checkAlarmInfoOnMonitorSeries(isRainSelected: boolean, isEchoSelected: boolean) {
        if (isRainSelected) {
            this.locationForm.removeControl('alarmsInfo');
            this.locationForm.addControl('rainAlarms', this.locationFormBuilder.generateRainAlarmInfo());

            return;
        }
        this.locationForm.removeControl('rainAlarms');

        if (!this.locationForm.get('alarmsInfo')) {
            this.locationForm.addControl(
            'alarmsInfo',
            this.locationFormBuilder.generateConfigureAlarms(false, this.locationCardService.isMetric),
            );
        }

        const alarmsInfo = this.locationForm.get('alarmsInfo') as FormGroup;
        if (isEchoSelected && !alarmsInfo.get('tiltEnable')) {
            alarmsInfo.addControl('tiltEnable', new FormControl(false));
            alarmsInfo.addControl(
                'tiltThreshold',
                new FormControl({ value: 5, disabled: true }, [
                    Validators.required,
                    Validators.pattern(REGEX_CONFIG.installationValuePattern),
                ]),
            );
        } else if (!isEchoSelected) {
            alarmsInfo.removeControl('tiltThreshold');
            alarmsInfo.removeControl('tiltEnable');
        }
    }

    private getLocations() {
        const requestParams = <LocationArgs>{
            customerId: this.data.customerId,
            IncludeInactiveLocations: true,
        };

        this.locationDashboardService.getLocationsList(requestParams).subscribe((locations: Locations[]) => {
            if (!locations) return;

            this.locations = locations;
            this.locations.sort(this.uiUtilsService.sortLocations);

            this.locationForm
                .get('basicInfo')
                .get('locationName')
                .setValidators([
                    this.locationNameValidator.bind(this),
                    Validators.required,
                    Validators.pattern(REGEX_CONFIG.alphaNumericWitSpecialSymbols_Pattern),
                    Validators.maxLength(63),
                ]);
        });
    }

    private locationNameValidator(control: AbstractControl): { [key: string]: boolean } | null {
        const locationName = control.value;
        if (!locationName) return null;

        const isDuplicate = !!this.locations.find((v) => {
            if (!this.data.editMode) {
                return v.locationName.toLowerCase() === locationName.toLowerCase();
            }

            return (
                v.locationName.toLowerCase() === locationName.toLowerCase() &&
                !this.locationDetails.some((i) => i.locationID === v.locationId)
            );
        });

        if (isDuplicate) {
            return { dupName: true };
        }

        return null;
    }

    private closeDialog(result: boolean) {
        this.dialogRef.close({ result });
    }

    private resetForm() {
        const keys = [
            'tritonInfo',
            'tritonSensors',
            'foreSITEFlexSensors',
            'echoInfo',
            'foreSITEFlexInfo',
            'echoLongRange',
            'rainalertInfo',
            'rainSensor',
            'mp2BasicInfo',
            'mp2AlarmsInfo',
            'samplerInfo',
        ];
        keys.forEach((v) => this.locationForm.removeControl(v));
    }

    private checkFlowForm() {
        const series = this.getMonitorSeries();
        const isTriton = series === MonitorSeriesNames.triton;
        const alreadyExistFlowInfo = this.locationForm.get('flowInfo');
        const alreadyExistFlowInfo2 = this.locationForm.get('flowInfo2');
        const mp2BasicInfo = this.locationForm.get('mp2BasicInfo');

        if (isTriton) {
            if (!alreadyExistFlowInfo) {
                this.locationForm.addControl('flowInfo', this.locationFormBuilder.generateFlowInfo());
            }
            if (mp2BasicInfo && !alreadyExistFlowInfo2) {
                this.locationForm.addControl('flowInfo2', this.locationFormBuilder.generateFlowInfo());
            } else if (!mp2BasicInfo && alreadyExistFlowInfo2) {
                this.locationForm.removeControl('flowInfo2');
            }
        } else {
            this.locationForm.removeControl('flowInfo');
            this.locationForm.removeControl('flowInfo2');
        }
    }

    private setConfirmationInput() {
        this.conformationDataInput = {
            title: this.translateService.instant('ADD_EDIT.EDIT_CONFIRM_DIALOG'),
            message: this.translateService.instant('ADD_EDIT.EDIT_CONFIRM_MSG'),
            cancelText: this.translateService.instant('COMMON.TEST_NO'),
            okText: this.translateService.instant('COMMON.TEST_YES'),
        };
    }

    private getCustomProperties() {
        this.locationService.getCustomPropNames(this.data.customerId).subscribe((data) => this.customProperties = data);
    }

    private populateFormValues() {
        const locationDetails = this.locationService.getLocationDetailsV2(
            this.data.customerId,
            this.data.locationDetails.locationID,
        );
        const { series } = this.data.locationDetails;
        let monitorConfigs: Observable<Object>;
        let alarmConfigs;

        if (series.toUpperCase() === ECHO_SERIES) {
            monitorConfigs = this.monitorSettingService.getEchoMonitorConfiguration(
                this.data.customerId,
                this.data.locationDetails.locationID,
            );
        } else if (series.toUpperCase() === 'TRITON+') {
            monitorConfigs = this.tritonLocationService.getTritonMonitorConfiguration(
                this.data.locationDetails.locationID,
                this.data.customerId,
            );
        } else if (series.toUpperCase() === RAINALERT_SERIES) {
            monitorConfigs = this.monitorSettingService.getRainAlertMonitorConfiguration(
                this.data.customerId,
                this.data.locationDetails.locationID,
            );
        } else if(series.toUpperCase() === (MonitorSeriesNames.ForeSITE_UL).toUpperCase()) {
            monitorConfigs = this.monitorSettingService.foreSiteUlConfiguration(
                this.data.customerId,
                this.data.locationDetails.locationID,
            );
        } else if(series.toUpperCase() === (MonitorSeriesNames.ForeSITE_Flex).toUpperCase()) {
            monitorConfigs = this.monitorSettingService.getForeSITEFlexConfiguration(
                this.data.customerId,
                this.data.locationDetails.locationID,
            );
        } else {
            monitorConfigs = of(null);
        }

        combineLatest([
            locationDetails,
            monitorConfigs,
            this.customerService.getCustomerById(this.data.customerId),
        ]).subscribe(
            ([details, monitor, customer]: [LocationDetails[], any, Customer]) => {

                if (!details) {
                    this.snackbar.open(
                        this.translateService.instant('COMMON.LOCATION_FETCHED_ERROR'),
                        this.dismissButton
                    );
                    this.closeDialog(false);
                    return;
                }
                details = details.map((v) => ({
                    ...v,
                    monitorSeries: v.series,
                    manholeaddress: v.manholeAddress,
                    manholedepth: v.manholeDepth,
                    series: v.series.toUpperCase(),
                    installationType: `${v.installationType}: ${v.installationShapeTypeID}`,
                    ipaddress: v.connectionString,
                    serialnumber: v.serialNumber,
                }));

                this.locationDetails = details;
                this.defaultRoughness = customer.roughness;
                this.createForm();

                if (monitor) {
                    this.monitorConfigs = monitor;
                    if (monitor.compensatedTemperatureMarch) {
                        this.monitorConfigs.compensatedTemperatureMarch =
                            Math.round(this.monitorConfigs.compensatedTemperatureMarch * 10) / 10;
                        this.monitorConfigs.compensatedTemperatureSeptember =
                            Math.round(this.monitorConfigs.compensatedTemperatureSeptember * 10) / 10;
                    }

                    if (monitor.highlevelthreshold) {
                        this.monitorConfigs.highlevelthreshold = Math.round(this.monitorConfigs.highlevelthreshold * 10) / 10;
                        this.monitorConfigs.highhighthreshold = Math.round(this.monitorConfigs.highhighthreshold * 10) / 10;
                        this.monitorConfigs.lowlevelthreshold = Math.round(this.monitorConfigs.lowlevelthreshold * 10) / 10;
                    }

                    if (monitor.rainpertip) {
                        // #34544 rainpertip should have 0.01 precision for US and 3 decimal places for Metric
                        if (this.locationCardService.isMetric) {
                            this.monitorConfigs.rainpertip = Math.round(this.monitorConfigs.rainpertip * 1000) / 1000;
                        } else {
                            this.monitorConfigs.rainpertip = Math.round(this.monitorConfigs.rainpertip * 100) / 100;
                        }
                        this.monitorConfigs.alarmthreshold = this.locationCardService.isMetric ? Number(this.monitorConfigs.alarmthreshold).toFixed(1): Number(this.monitorConfigs.alarmthreshold).toFixed(2)|| 0;
                        this.monitorConfigs.alarmreturntonormal = this.locationCardService.isMetric ? Number(this.monitorConfigs.alarmreturntonormal).toFixed(1): Number(this.monitorConfigs.alarmreturntonormal).toFixed(2) || 0;
                    }

                    if (typeof this.monitorConfigs.comport === 'number') {
                        this.monitorConfigs.comport =
                            this.locationCardService.comportValues[this.monitorConfigs.comport];
                    }

                    if (this.monitorConfigs.fastRate) {
                        this.monitorConfigs.fastrate = this.monitorConfigs.fastRate;
                    }

                    if (this.monitorConfigs.apn) {
                        this.monitorConfigs.apnProvider = this.monitorConfigs.apn;
                    }
                }

                const [mp1BasicInfo, mp2BasicInfo] = details;
                if (mp1BasicInfo.externalVendorID === 1) {
                    mp1BasicInfo.series = 'USGS';
                }

                let installationDate = null;
                if (mp1BasicInfo.installationDate) {
                    installationDate = new Date(mp1BasicInfo.installationDate);
                }

                this.locationForm.patchValue({
                    basicInfo: { ...mp1BasicInfo, installationDate, ...this.monitorConfigs },
                });

                if (this.basicInfo) {
                    this.basicInfo.onCommunicationTypeChange({ value: mp1BasicInfo.communicationType } as any);
                }
                if (mp2BasicInfo) {
                    this.shouldPopulateMp2 = true;
                }
            },
            () => {
                this.closeDialog(false);
                this.snackbar.open(this.translateService.instant('COMMON.LOCATION_FETCHED_ERROR'), this.dismissButton);
            },
        );
    }
}
