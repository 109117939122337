import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    OnDestroy,
    SimpleChanges,
    OnChanges,
    ChangeDetectorRef,
} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { SliicerCaseStudy } from 'app/shared/models/sliicer';
import { QVI_GROUP_ALL_STORMS, QVI_GROUP_ALL_STORMS_CONFIG } from 'app/shared/models/sliicer/basins';
import { BasinQvsISettingsOverrides } from 'app/shared/models/sliicer/overrides';
import { QvsIConfigurations } from 'app/shared/models/sliicer/results/storm-events';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BasinQvi, Type } from './basin-qvi-model'

@Component({
    selector: 'app-basin-qvi-stats',
    templateUrl: './basin-qvi-stats.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasinQviStatsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public selectedBasin: string;
    @Input() public basinQvsISettings: BasinQvsISettingsOverrides[];
    public basinQviDataSource = new MatTableDataSource([]);
    public basinQviLoader = false;
    public basinQviColumns: string[] = basinQviColumns;
    private customerId: number;
    private caseStudyId: string;
    public decimal: string;
    public unitMGD: string;
    public slopePrecision: string;
    public unitOfMeasure: string;
    public genericPrecision: string;
    private subscriptions = new Array<Subscription>();
    public typeMap = [];
    private qviData: BasinQvi[] = [];
    private qvsiGroup = null;

    public BasinType = Type;

    constructor(
        private sliicerService: SliicerService,
        private uiUtilsService: UiUtilsService,
        private dateutilService: DateutilService,
        private cdr: ChangeDetectorRef,
        private translate: TranslateService
    ) {
        const translateKeys: Array<string> = [
            'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_PERIOD_GROSS_WO_GROSS',
            'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.TOTAL_EVENT_WO_GROSS',
            'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.PEAK_VS_ROLLING_WO_GROSS',
            'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.PEAK_VS_RAIN_WO_GROSS'
        ];

        this.typeMap['VolumeStorm'] = '';
        this.typeMap['VolVol'] = '';
        this.typeMap['PeakPeak'] = '';
        this.typeMap['PeakVolToPeak'] = '';

        this.translate.get(translateKeys).subscribe((translateValues) => {
            if (!translateValues) {
                return;
            }

            this.typeMap['VolumeStorm'] = translateValues['SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_PERIOD_GROSS_WO_GROSS'];
            this.typeMap['VolVol'] = translateValues['SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.TOTAL_EVENT_WO_GROSS'];
            this.typeMap['PeakPeak'] = translateValues['SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.PEAK_VS_ROLLING_WO_GROSS'];
            this.typeMap['PeakVolToPeak'] = translateValues['SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.PEAK_VS_RAIN_WO_GROSS'];
        });


    }

    ngOnInit(): void {

        const isMetric = this.dateutilService.isCustomerUnitsMetric.getValue();
        this.unitOfMeasure = isMetric ? 'mm' : 'in';
        this.decimal = isMetric ? '1.1-1' : '1.2-2';
        this.unitMGD = isMetric ?  '1.1-1' : '1.3-3';
        this.genericPrecision = '1.2-2';

        // #36585 Different Precision for Slope based on type. Look at HTML template
        // #38703 Mike Roberman confirmed that all slope should be 4 decimal precision regardless of customer unit and regardless of storm type.
        this.slopePrecision = '1.4-4';''

        this.subscriptions.push(
            this.sliicerService.studyDetailsData$.subscribe((caseStudyDetails: SliicerCaseStudy) => {
                this.customerId = caseStudyDetails.customerId;
                this.caseStudyId = caseStudyDetails.id;
                this.checkInputData();
            }),
        );

        this.subscriptions.push(
            this.sliicerService.basinQVIResult$.subscribe((qviResults: BasinQvi[]) => this.refershTable(qviResults))
        );

        this.subscriptions.push(
            this.sliicerService.qvsiSelectedConfig.subscribe((qvsiConfig) => {
                if(qvsiConfig && qvsiConfig.afterupdate) {
                    return;
                }

                const conf = qvsiConfig ? qvsiConfig.conf : null;

                this.onQvsIconfigChange(conf);
            })
        );
    }

    public applyOverridesResult(updates: BasinQvsISettingsOverrides[]) {
        this.basinQviDataSource.data.forEach((row) => {
            const s = updates.find((r) => r.type === row.type && r.mode === row.mode);

            if (s) {
                row.result.useAlt = s.useAlt;
            }
        });

        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public getYIntPrecision(stormType: Type): string {
        let precisionToReturn: string;
        switch (stormType) {
            case Type.PeakPeak:
            case Type.PeakVolToPeak:
                precisionToReturn = '1.1-1';
                break;
            case Type.VolVol:
            case Type.VolumeStorm:
                precisionToReturn = '1.3-3';
                break;
        }
        return precisionToReturn;
    }


    private qvsiConfigApply() {
        if (this.qviData.length === 0) {
            return;
        }

        const group = !this.qvsiGroup || this.qvsiGroup === QVI_GROUP_ALL_STORMS ? '': this.qvsiGroup;
        const formattedData = this.qviData.filter(v => v.configuration === group);
        this.basinQviDataSource = new MatTableDataSource(JSON.parse(JSON.stringify(formattedData)));
        this.applyBasinQvsISettings();

        this.uiUtilsService.safeChangeDetection(this.cdr);
    }
    private onQvsIconfigChange(qvsiConfig: QvsIConfigurations) {
        this.qvsiGroup = qvsiConfig ? qvsiConfig.name : '';
        this.qvsiConfigApply();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscripton) => subscripton.unsubscribe());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedBasin && !changes.selectedBasin.firstChange) {
            this.checkInputData();
        }

        if (changes.basinQvsISettings) {
            this.applyBasinQvsISettings();
        }
    }

    private applyBasinQvsISettings() {
        this.basinQviDataSource.data.forEach((row) => {
            if (row && row.result && row.result.coeff1 >= 0) {
                const s = this.basinQvsISettings.find((r) => r.type === row.type && r.mode === row.mode);

                if(s) {
                    row.result.useAlt = s.useAlt;
                }
            }
        })
    }

    // This method will check if required input data is set in order to get storm stats
    private checkInputData(): void {
        if (this.selectedBasin) {
            this.getBasinQviData(this.customerId, this.caseStudyId, this.selectedBasin);
        }
    }

    private refershTable(qviResults) {
        if (qviResults) {
            this.qviData = qviResults;
            const conf = this.sliicerService.qvsiSelectedConfig.getValue();
            const currentGroup = conf ? conf.conf : null;
            const currentGroupName = currentGroup ? currentGroup.name : '';

            const formattedData = this.qviData.filter(v => v.configuration === currentGroupName);
            this.basinQviDataSource = new MatTableDataSource(JSON.parse(JSON.stringify(formattedData)));
            this.qvsiConfigApply();
        } else {
            this.basinQviDataSource = new MatTableDataSource(null);
        }
    }

    // This method displays the selected storm statistics data table.
    private getBasinQviData(customerId: number, caseStudyId: string, selectedBasin: string): void {
        if (customerId === 0 || !caseStudyId || !selectedBasin) {
            this.basinQviDataSource = new MatTableDataSource(null);
            return;
        }
        this.basinQviLoader = true;
        this.sliicerService
            .getQviBasinResults(customerId, caseStudyId, selectedBasin)
            .pipe(
                finalize(() => {
                    this.basinQviLoader = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }),
            )
            .subscribe(
                (qviResults) => {
                    this.refershTable(qviResults);
                },
                (error) => {
                    this.basinQviDataSource = new MatTableDataSource(null);
                },
            );
    }
}

const basinQviColumns: string[] = [
    // "configuration" All values are null ,
    'group',
    'mode',
    'useAlternate',
    'type',
    'N',
    'R2',
    'Slope',
    'YIntercept'
];

// #28793 Since the slicer community may want to see more stats later, make the change requested in such a way that if needed we can bring back the list that's currently displayed.
// const basinQviColumns: string[] = [
//     // "configuration" All values are null ,
//     'group',
//     'mode',
//     'useAlternate',
//     'zeros',
//     'type',
//     'regressionType',
//     'sumQ',
//     'sumR',
//     'sumQR',
//     'sumQ2',
//     'sumR2',
//     'N',
//     'averageQ',
//     'averageR',
//     'coefficient1',
//     'coefficient2',
//     'altSlope',
//     'altR2',
//     'altT',
//     'R',
//     'R2',
//     'T',
// ];
