import { Component, ViewChild, ElementRef, Input, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
// import 'highcharts/adapters/standalone-framework';
import { BlockDaysElement } from 'app/shared/models/sliicer-data';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { StringUtils } from 'app/shared/utils/string-utils';

// const HighCharts = require('highcharts/highstock');
const noData = require('highcharts/modules/no-data-to-display');
const multicolorSeries = require('highcharts-multicolor-series/js/multicolor_series');
multicolorSeries(Highcharts);

@Component({
    selector: 'app-graph',
    templateUrl: './graph.component.html',
    styles: [],
})
export class GraphComponent implements OnInit, OnChanges {
    @ViewChild('chart', { static: true }) public chartElement: Highcharts.ChartObject;
    @Input('chartOptions') public chartOptions: any;
    @Input('initChart') public initChart: boolean;
    public chart: any;
    public noDataAvailable: string;
    public isChartRendered = false;
    private selectedDateRange: number[] = [];
    private shownDateRange: number[] = [];

    constructor(
        private translate: TranslateService,
        private sliicerService: SliicerService,
        private statusCodeService: StatusCodeService
    ) {
        this.statusCodeService.userInfoThemeBS.subscribe((response: boolean) => {
            if (response) {
                StringUtils.setHighChartTheme(this.chart);
            } else {
                StringUtils.setHighchartWhiteTheme(this.chart);
            }
        });
    }

    public ngOnInit() {
        this.translate.get('COMMON.NO_DATA_AVAILABLE').subscribe((res: string) => {
            this.noDataAvailable = res;
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (this.chartOptions && Object.keys(this.chartOptions).length > 0) {
            this.plotHyetoGraph();
        }
        setTimeout(() => {
            if (this.chart) {
                this.chart.reflow();
            }
        }, 1);
    }

    public plotHyetoGraph() {
        const self = this;
        this.selectedDateRange = [this.chartOptions.xAxis.min, this.chartOptions.xAxis.max];
        this.shownDateRange = [this.chartOptions.xAxis.min, this.chartOptions.xAxis.max];
        this.chartOptions.xAxis.events = {
                setExtremes: function (event) {
                    const newRanges = [event.min, event.max];
                    if (event.trigger === 'navigator') {
                        setTimeout(() => self.useNavigator(newRanges), 0);
                    }
                    if (event.trigger === 'zoom') {
                        self.shownDateRange = newRanges;
                    }
                    if (event.trigger === 'rangeSelectorButton') {
                        event.preventDefault()
                    }
                },
        }
        if (this.chartElement && this.chartElement.nativeElement) {
            if ('chart' in this.chartOptions) {
                this.chartOptions.chart.renderTo = this.chartElement.nativeElement
                    ? this.chartElement.nativeElement
                    : this.chartOptions.chart.renderTo;
            } else {
                this.chartOptions.chart = {
                    type: 'line',
                    renderTo: this.chartElement.nativeElement,
                    reflow: true,
                };
            }


            this.chartOptions.rangeSelector = {
                enabled: true,
                buttonTheme: {
                    // styles for the zoom buttons
                    fill: '#e0e0e0',
                    stroke: 'none',
                    'stroke-width': 0,
                    r: 2,
                    style: {
                        color: '#0e0e0e',
                        fontWeight: 'bold',
                    },
                    states: {
                        select: {
                            fill: '#5cb259',
                            style: { color: 'white' },
                        },
                    },
                },
                buttons: [
                    {
                        type: 'day',
                        count: 1,
                        text: '1d',
                        events: {
                            click: function () {
                                setTimeout(() => self.clickZoomButton(0), 0);
                            },
                        },
                    },
                    {
                        type: 'week',
                        count: 1,
                        text: '1w',
                        events: {
                            click: function () {
                                setTimeout(() => self.clickZoomButton(1), 0);
                            },
                        },

                    },
                    {
                        type: 'month',
                        count: 1,
                        text: '1m',
                        events: {
                            click: function () {
                                setTimeout(() => self.clickZoomButton(2), 0);
                            },
                        },
                    },
                    {
                        type: 'all',
                        text: 'Off',
                        events: {
                            click: function () {
                                setTimeout(() => self.clickZoomButton(3), 0);
                            },
                        },
                    },
                ],
                selected: 3, // Default to 'all' or 'Off' button selected
            }


            if (!('xAxis' in this.chartOptions)) {
                this.chartOptions.xAxis = {
                    type: 'datetime',
                    dateTimeLabelFormats: this.sliicerService.dateFormatFullStudy(),
                };
            }

            if (!('tooltip' in this.chartOptions)) {
                this.chartOptions.tooltip = {
                    pointFormat:
                        '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.change}%)<br/>',
                    valueDecimals: 2,
                    split: true,
                };
            }

            if (!('legend' in this.chartOptions)) {
                this.chartOptions.legend = {
                    enabled: true,
                    align: 'right',
                };
            }

            if (!('lang' in this.chartOptions)) {
                this.chartOptions.lang = {
                    noData: this.noDataAvailable,
                };
            }

            if (!('noData' in this.chartOptions)) {
                this.chartOptions.noData = {
                    style: {
                        fontWeight: 'bold',
                        fontSize: '15px',
                        color: '#303030',
                    },
                };
            }

            this.chartOptions.exporting = {
                enabled: true,
                buttons: {
                    forward: {
                        enabled: true,
                        align: 'right',
                        verticalAlign: 'bottom',
                        x: 0,
                        y: -55,
                        className: 'jump-scroll-btn',
                        text: '>',
                        onclick: function () {
                            self.jumpScroll(true);
                        },
                    },
                    back: {
                        enabled: true,
                        align: 'left',
                        verticalAlign: 'bottom',
                        x: 30,
                        y: -55,
                        className: 'jump-scroll-btn',
                        text: '<',
                        onclick: function () {
                            self.jumpScroll(false);
                        },
                    },
                }
            }

            this.chartOptions.navigator = {
                enabled: true,
                xAxis: { width: '100%' },
            }

            this.chartOptions.credits = {
                enabled: false,
            };

            this.chartOptions.chart.marginRight = 45;

            if (this.initChart) {
                this.createChart();
                this.checkJumpScrollButtonsEnabled();
            }
        }
    }

    public addData(data, colors) {
        data.filter((x) => x.data && x.data.length).forEach((x) => {
            this.chart.addSeries(x);
        });
        this.chart.series.forEach((element, i) => {
            element.options.color = colors[i];
            element.update(element.options);
        });
    }

    public removeSeriesData(dataNameToBeRemoved) {
        const indexOfSeries = this.chart.series.map((a) => a.name).indexOf(dataNameToBeRemoved);
        if (indexOfSeries > -1) {
            this.chart.series[indexOfSeries].remove();
        }
    }

    public createChart() {
        Highcharts.setOptions({
            global: {
                useUTC: false,
            },
        });
        // this.chartOptions.yAxis.opposite = true
        this.chart = new Highcharts.stockChart(this.chartOptions);
        if (this.chartOptions && !this.chartOptions['series']) {
            noData(Highcharts);
        }

        this.initChart = true;
        this.isChartRendered = true;
        this.chart.reflow();
    }

    public addPlotBand(plotBand: BlockDaysElement) {
        if (plotBand && Object.keys(plotBand).length > 0 && this.chart && this.chart.xAxis.length > 0) {
            this.chart.xAxis[0].addPlotBand(plotBand);
        }
    }

    public removePlotBand(plotBandsId) {
        if (plotBandsId) {
            this.chart.xAxis[0].removePlotBand(plotBandsId);
        }
    }

    private jumpScroll(direction: boolean) {
        const shownTimeSpan = this.shownDateRange[1] - this.shownDateRange[0];
        let newRange: number[];

        if (direction) {
            // Jump forward
            newRange = [this.shownDateRange[1], this.shownDateRange[1] + shownTimeSpan];
            if (newRange[0] > new Date(this.selectedDateRange[1]).getTime()) {
                return;
            }
        } else {
            // Jump backward
            newRange = [this.shownDateRange[0] - shownTimeSpan, this.shownDateRange[0]];
            if (newRange[1] < new Date(this.selectedDateRange[0]).getTime()) {
                return;
            }
        }
        this.useNavigator(newRange);
    }

    private checkJumpScrollButtonsEnabled() {
        const shownTimeSpan = this.shownDateRange[1] - this.shownDateRange[0];
        const newForwardRange = [this.shownDateRange[1] + 1, this.shownDateRange[1] + 1 + shownTimeSpan];
        const newBackRange = [this.shownDateRange[0] - 1 - shownTimeSpan, this.shownDateRange[0] - 1];

        const defaultClass = 'jump-scroll-btn';
        const disabledClass = ' disabled';

        // Set to required class first
        let chartOptions = {
            exporting : {
                xAxis: {
                    min: this.shownDateRange[0],
                    max: this.shownDateRange[1]
                },
                buttons: {
                    forward: {
                        className: defaultClass
                    },
                    back: {
                        className: defaultClass
                    }
                }

            }
        };

        // Add disabled class if needed
        if (newForwardRange[0] > this.selectedDateRange[1]) {
            chartOptions.exporting.buttons.forward.className += disabledClass;
        }
        if (newBackRange[1] < this.selectedDateRange[0]) {
            chartOptions.exporting.buttons.back.className += disabledClass;
        }
        setTimeout(() => {
            // Need to ensure DOM is loaded before sending graph to it
            this.chart.update(chartOptions);
        }, 0);
    }

    private useNavigator(newRanges: number[]) {
        this.shownDateRange = newRanges;
        this.checkJumpScrollButtonsEnabled();
        this.chart.xAxis[0].setExtremes(newRanges[0], newRanges[1])
    }

    private clickZoomButton(buttonId: number) {
        // Default zoom button action takes time from end of chart
        const min = this.selectedDateRange[0];
        let max = this.selectedDateRange[1];
        const oneDay = 60 * 60 * 24 * 1000;

        const minDate = new Date(min);
        const numberOfDaysForCurrentMonth = new Date(minDate.getFullYear(), minDate.getMonth() + 1, 0).getDate();
        const self = this;

        switch (buttonId) {
            case 0: {
                // 1 day
                max = min + oneDay;
                this.chartOptions.rangeSelector.selected = 0;
                break;
            }
            case 1: {
                // 1 week
                max = min + oneDay * 7;
                this.chartOptions.rangeSelector.selected = 1;
                break;
            }
            case 2: {
                // 1 month
                max = min + oneDay * numberOfDaysForCurrentMonth;
                this.chartOptions.rangeSelector.selected = 2;
                break;
            }
            case 3:
            default: {
                this.chartOptions.rangeSelector.selected = 3;
                break;
            }
        }
        this.shownDateRange = [min, max];
        this.useNavigator([min, max])
        this.checkJumpScrollButtonsEnabled();
    }
}
