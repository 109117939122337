import { Injectable } from '@angular/core';
import { AxisOptions } from 'highcharts';
import sortBy from 'lodash/sortBy';
import { DataEditOriginalValue, DataEditStoreActionTypes, UpdatePoints } from '../models/data-edit';
import { BasicSeriesData, HGGraphData, ManipulationActions } from '../models/hydrographNEW';
import { DataEditService } from './data-edit.service';

@Injectable()
export class HydrographDataEditingService {
    constructor(private dataEditService: DataEditService) {}
    selection: HGGraphData[];

    private manipulateSeries(
        action: ManipulationActions,
        value: number,
        editingData: HGGraphData[],
        editedPointsList: UpdatePoints[],
        manipulateFn: (point: HGGraphData) => void,
    ) {
        editingData.forEach((point) => {
            const isPointDefinedAndNotNull = point.correctedY !== undefined && point.correctedY !== null;
            switch (action) {
                case ManipulationActions.Add: {
                    if (isPointDefinedAndNotNull) {
                        point.correctedY = point.correctedY + value;
                    } else {
                        point.correctedY = point.y + value;
                    }
                    break;
                }
                case ManipulationActions.Subtract: {
                    if (isPointDefinedAndNotNull) {
                        point.correctedY = point.correctedY - value;
                    } else {
                        point.correctedY = point.y - value;
                    }
                    break;
                }
                case ManipulationActions.Multiply: {
                    if (isPointDefinedAndNotNull) {
                        point.correctedY = point.correctedY * value;
                    } else {
                        point.correctedY = point.y * value;
                    }
                    break;
                }
                case ManipulationActions.Divide: {
                    if (isPointDefinedAndNotNull) {
                        point.correctedY = point.correctedY / value;
                    } else {
                        point.correctedY = point.y / value;
                    }
                    break;
                }
                case ManipulationActions.Equal: {
                    if (isPointDefinedAndNotNull) {
                        point.correctedY = point.correctedY = value;
                    } else {
                        point.correctedY = point.y = value;
                    }
                    break;
                }
                default:
                    break;
            }

            manipulateFn(point);
        });

        return editedPointsList;
    }

    public multiPointEdit(
        dates: number[],
        rangeValues: number[],
        entity: BasicSeriesData,
        action: ManipulationActions,
        value: number,
        seriesData: BasicSeriesData[],
        editedPointsList: UpdatePoints[],
    ) {
        const editingData = seriesData.find((x) => x.entityId === entity.entityId && x.lid === entity.lid).data.filter((point) => point.selected);

        if (editingData.length === 0) {
            // No points in the specified range
            return seriesData;
        }

        const originalValuesMap = editingData.reduce((acc, curr) => {
            acc.set(curr.x, { x: curr.x, eid: entity.entityId, y: curr.y, correctedY: curr.correctedY, flagged: curr.flagged, dateTime: curr.x, action: DataEditStoreActionTypes.HGedit });
            return acc;
        }, new Map<number, DataEditOriginalValue>());

        this.manipulateSeries(action, value, editingData, editedPointsList, (point) => {
            editedPointsList.push({
                timeStamp: new Date(point.x),
                dateTime: point.x,
                eid: entity.entityId,
                reading: point.correctedY !== null && point.correctedY !== undefined ? point.correctedY : point.y,
                reason: null,
                ignore: point.flagged,
            });
        });

        editedPointsList.forEach(v => {
            v.originalValue = originalValuesMap.get(v.dateTime);
        });
        return [seriesData, editedPointsList];
    }

    public copyPaste(
        sourceDates: number[],
        sourceEntity: BasicSeriesData,
        destinationEntityId: number,
        destinationStartDate: Date,
        seriesData: BasicSeriesData[],
        editedPointsList: UpdatePoints[],
        action?: ManipulationActions,
        actionValue?: number,
    ) {
        // #28981 need to reversely convert destination start date to match needed graph points
        destinationStartDate = new Date(destinationStartDate.getTime() - destinationStartDate.getTimezoneOffset() * 60000);
        const { entityId, lid } = sourceEntity;
        const sourceEntityData = seriesData.find(v => v.entityId === entityId && v.lid === lid);
        // IMPORTANT: There are virutal points with y===0 at end of series.
        // Those should be ignored as they do not exists! So that's why condition `&& point.y`
        const sourceData = sourceEntityData
            .data.filter((point) => point.x >= sourceDates[0] && point.x <= sourceDates[1] && point.y !== undefined && point.y !== null);
        let destData: HGGraphData[] = [];

        // If the first destination point found isn't within 2 minutes of the selected destination
        const entityData = seriesData.find((x) => x.entityId === destinationEntityId && x.lid === undefined);
        const destinationPoint = entityData.data.find((point) => point.x >= destinationStartDate.getTime());
        let firstDest = destinationPoint ? destinationPoint.x : entityData.data[entityData.data.length - 1].x;

        if (Math.abs(firstDest - destinationStartDate.getTime()) > 120000) {
            // Make sure its rounded to the nearest minute
            firstDest = destinationStartDate.getTime();
            firstDest = Math.floor(firstDest / 1000) * 1000;
        }

        const timeDiff: number = firstDest - sourceData[0].x;

        // cache original values
        // getting timestamps of original points that will be pasted over, used in undo/redo
        const origTimestamps = sourceData.map(v => v.x + timeDiff);
        const origData = entityData.data.filter(v => origTimestamps.includes(v.x));
        const originalValuesMap = origData.reduce((acc, curr) => {
            acc.set(curr.x, { x: curr.x, eid: destinationEntityId, y: curr.y, correctedY: curr.correctedY, flagged: curr.flagged, dateTime: curr.x, action: DataEditStoreActionTypes.HGedit });
            return acc;
        }, new Map<number, DataEditOriginalValue>());


        this.manipulateSeries(
            action,
            actionValue,
            JSON.parse(JSON.stringify(sourceData)),
            editedPointsList,
            (point) => {
                const destX = point.x + timeDiff;
                destData.push({
                    ...point,
                    x: point.x + timeDiff,
                    selected: entityId === destinationEntityId && sourceData.some((x) => x.x === destX),
                    correctedY: point.correctedY ? point.correctedY : point.y,
                });

                editedPointsList.push({
                    timeStamp: new Date(point.x + timeDiff),
                    dateTime: destX,
                    eid: destinationEntityId,
                    reading: point.correctedY ? point.correctedY : point.y,
                    reason: null,
                    ignore: point.flagged,
                });
            },
        );

        // set original values to edited points
        editedPointsList.forEach(v => {
            v.originalValue = originalValuesMap.get(v.dateTime);
        });
        // Find data to be removed
        // Compare timestamps of destination data to remove data
        // So we can flag out any data in destination that isn't overwritten
        const removeData = seriesData
            .find((x) => x.entityId === destinationEntityId && x.lid === undefined)
            .data.filter(
                (point) => point.x >= (sourceDates[0] + timeDiff) && point.x <= (sourceDates[1] + timeDiff) && typeof point.y === 'number',
            );

        const removeDataX = removeData.map((point) => point.x);
        const destDataX = destData.map((point) => point.x);

        const pointsToKeepButFlag = removeDataX.filter((e) => !destDataX.includes(e));

        pointsToKeepButFlag.forEach((ts) => {
            const point = removeData.find((point) => point.x === ts);
            const pointOriginalFlag = point.flagged;
            point.flagged = true;

            editedPointsList.push({
                timeStamp: new Date(ts),
                eid: destinationEntityId,
                dateTime: ts,
                reading: point.correctedY ? point.correctedY : point.y,
                reason: null,
                ignore: point.flagged,
                originalValue: { dateTime: ts, x: ts, y: point.y, correctedY: point.correctedY, flagged: pointOriginalFlag, eid: destinationEntityId, action: DataEditStoreActionTypes.HGedit }
            });
            destData.push(point);
        });
        destData = sortBy(destData, ['x']);

        seriesData.forEach((seri) => {
            if (seri.entityId === destinationEntityId && seri.lid === undefined) {
                // Cache points that will be pasted over
                const toCache = destData.filter(v => !removeDataX.includes(v.x));
                this.dataEditService.lastCopyCache = toCache;
            }
            return seri;
        });

        return [seriesData, editedPointsList, removeDataX];
    }
    public updateFlags(
        addFlags: boolean,
        entity: BasicSeriesData,
        dates: number[],
        values: number[],
        seriesData: BasicSeriesData[],
        editedPointsList: UpdatePoints[],
    ) {
        const updatedData = seriesData
            .find((x) => x.entityId === entity.entityId && x.lid === entity.lid)
            .data.filter((point) => point.x >= dates[0] && point.x <= dates[1] && point.y !== null)
            .filter(
                (point) =>
                    (point.correctedY && point.correctedY >= values[0] && point.correctedY <= values[1]) ||
                    (!point.correctedY && point.y >= values[0] && point.y <= values[1]),
            );

        updatedData.forEach((point) => {
            const originalFlagged = point.flagged;
            point.flagged = addFlags;

            editedPointsList.push({
                timeStamp: new Date(point.x),
                dateTime: point.x,
                eid: entity.entityId,
                reading: point.correctedY ? point.correctedY : point.y,
                reason: null,
                ignore: addFlags,
                originalValue: {
                    x: point.x, eid: entity.entityId, y: point.y, correctedY: point.correctedY, flagged: originalFlagged, dateTime: point.x,
                    action: addFlags ? DataEditStoreActionTypes.Flag : DataEditStoreActionTypes.Unflag
                }
            });
        });
        return [seriesData, editedPointsList];
    }

    public clearAll(seriesData: BasicSeriesData[]) {
        seriesData.forEach((x) => {
            if (x.data) {
                x.data.forEach((p) => (p.selected = false));
            }
        });

        return [seriesData];
    }

    public clearSelection(entityId: number, seriesData: BasicSeriesData[]) {
        const sd = seriesData
            .find((x) => x.entityId === entityId);

        const selection: HGGraphData[] =
            sd.data.map((p, i) => { return {...p, index: i}})
            .filter((p) => p.selected);
        selection.forEach(p => p.selected = false);

        sd.data.filter((p) => p.selected).forEach((p) => (p.selected = false));

        return {s:selection, sd: seriesData};
    }

    public updateSelection(
        entity: BasicSeriesData,
        dates: number[],
        values: number[],
        seriesData: BasicSeriesData[],
        editedPointsList: UpdatePoints[],
    ) {
        const selectionRemove = this.clearSelection(entity.entityId, seriesData);

        // #38848 Need to include locationID (lid) to filter out entities from other locations
        const sd = seriesData
            .find((x) => x.entityId === entity.entityId && (!entity.lid || x.lid === entity.lid));

        sd.data.filter((point) => point.x >= dates[0] && point.x <= dates[1] && point.y !== null && point.y !== undefined)
            .filter(
                (point) =>
                    !values ||
                    (point.correctedY && point.correctedY >= values[0] && point.correctedY <= values[1]) ||
                    (!point.correctedY && point.y >= values[0] && point.y <= values[1]),
            ).forEach((point) => {
                point.selected = true;
            });

        const selectionAdd: HGGraphData[] =
            sd.data.map((p, i) => { return {...p, index: i}})
            // Filter out last virtual point, Also fix for #21537
            .filter((point) => point.x >= dates[0] && point.x <= dates[1] && point.y !== null && point.y !== undefined)
            .filter(
                (point) =>
                    !values ||
                    (point.correctedY && point.correctedY >= values[0] && point.correctedY <= values[1]) ||
                    (!point.correctedY && point.y >= values[0] && point.y <= values[1]),
            );

        return {s:[...selectionRemove.s, ...selectionAdd], sd: seriesData, e: editedPointsList};
    }

    public updateSelectionToEntity(prevEntity: BasicSeriesData, currentEntity: BasicSeriesData, seriesData: BasicSeriesData[]) {
        const oldSelectedSeries = seriesData.find((x) => x.entityId === prevEntity.entityId && x.lid === prevEntity.lid);
        const selectedSeries = seriesData.find((x) => x.entityId === currentEntity.entityId && x.lid === currentEntity.lid);

        if (!oldSelectedSeries || !selectedSeries || !oldSelectedSeries.data.length || !selectedSeries.data.length) return {os:[], s:[]};
        const { data: oldSeriesData } = oldSelectedSeries;
        const { data: newSeriesData } = selectedSeries;

        const oldSelection = oldSeriesData.filter((point) => point.selected);
        const selection = newSeriesData.filter((point) => oldSelection.some((oldpoint) => oldpoint.x === point.x));

        const retOldSelection = oldSelectedSeries.data.map((p, i) => {return {...p, index: i}}).filter((point) => point.selected);
        const retSelection = selectedSeries.data.map((p, i) => {return {...p, index: i}}).filter((point) => oldSelection.some((oldpoint) => oldpoint.x === point.x));

        oldSelection.forEach((point) => point.selected = false);
        selection.forEach((point) => point.selected = true);

        retOldSelection.forEach((point) => point.selected = false);
        retSelection.forEach((point) => point.selected = true);

        return {os: retOldSelection, s: retSelection, sd: seriesData};
    }

    public interpolatePoints(entity: BasicSeriesData, seriesData: BasicSeriesData[], dates?: number[]) {
        let isPointSelected = false;

        const entityData = seriesData.find((x) => entity && x.entityId === entity.entityId && x.lid === entity.lid)?.data;

        // Logic for #22343
        const updatedData = [];
        let subSelectionLength = 0;
        let prevInsert = false;
        let lastInsertedExtraPointIndex = null;
        for(let curIndex = 0; curIndex < entityData.length; curIndex++) {
            const point = entityData[curIndex];
            const shouldInsert = dates
                ? point.x >= dates[0] && point.x <= dates[1]
                : point.selected;

            if(shouldInsert) {
                updatedData.push(point);
                subSelectionLength++;
            }

            if(!prevInsert && shouldInsert && curIndex > 0) {
                const prevPoint = entityData[curIndex - 1];
                updatedData.push(prevPoint);
                lastInsertedExtraPointIndex = updatedData.length - 1;
            }

            if(prevInsert && !shouldInsert) {
                if(subSelectionLength >= 3 && lastInsertedExtraPointIndex !== null) {
                    updatedData.splice(lastInsertedExtraPointIndex, 1);
                    lastInsertedExtraPointIndex = null;
                } else {
                    updatedData.push(point);
                    subSelectionLength = 0;
                }
            }

            prevInsert = shouldInsert;
        }

        updatedData.forEach((point) => {
            isPointSelected = true;
            point.interpolated = true;
        });

        return isPointSelected;
    }

    public applyDragAndDrop(
        entity: BasicSeriesData,
        xValue: number,
        seriesData: BasicSeriesData[],
        calculatedDragValue: number,
        editedPointsList: UpdatePoints[],
    ) {
        // Need a function for this so that you can drag anywhere but the active edit entity
        // is the one that actually gets adjusted
        const draggedSeries = seriesData.find((x) => x.entityId === entity.entityId && x.lid === entity.lid);

        const draggedPoint = draggedSeries.data.find((point) => point.x === xValue);
        const pointY = draggedPoint.y;
        const pointCorrectedY = draggedPoint.correctedY;
        draggedPoint.correctedY = calculatedDragValue;

        editedPointsList.push({
            timeStamp: new Date(xValue),
            dateTime: xValue,
            eid: entity.entityId,
            reading: calculatedDragValue,
            reason: null,
            ignore: draggedPoint.flagged,
            originalValue: {
                x: xValue, eid: entity.entityId, y: pointY, correctedY: pointCorrectedY, flagged: draggedPoint.flagged, dateTime: xValue, action: DataEditStoreActionTypes.HGedit
            }
        });

        return [seriesData, editedPointsList];
    }
}
