<div class="hg-tracer-panel highcharts-range-selector-group">
    <span class="hg-date-ranges">
        <span class="title">Zoom:</span>
        <button
            mat-stroked-button
            class="button-small"
            *ngFor="let btn of rangeButtons"
            [ngClass]="{'button-selected': btn.id === selectedRangeButton}"
            [disabled]="btn.disabled"
            (click)="clickZoomButton(btn.id)"
            >{{btn.text}}</button>
    </span>

    <span class="hg-tracer">
        <span class="title">Tracer:</span>
        <button
            mat-stroked-button
            [ngClass]="{'button-selected': tracerButtonOn}"
            (click)="clickTracerButton()"
        >Static</button>
    </span>
</div>
