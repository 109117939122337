<div class="vault-move-action">
    <div class="popupHeader" mat-dialog-title>
        <span
            >{{ 'COMMON.MOVE_TXT' | translate }}:{{ this.selectedFiles.length }} {{ 'COMMON.FILE_S' | translate }}</span
        >
        <span class="app-flex-filler"></span>
    </div>
    <app-loader [isLoading]="isLoading"></app-loader>
    <div mat-dialog-content>
        <h4>{{ 'COMMON.FILE_TREE_TXT' | translate }}</h4>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
            <!-- This is the tree node template for leaf nodes -->
            <mat-tree-node
                *matTreeNodeDef="let node"
                matTreeNodePadding
                [ngClass]="{ active: node.id === nodeId }"
                matTooltip="{{ node.name }}"
                matTooltipPosition="right"
            >
                <!-- use a disabled button to provide padding for tree leaf -->
                <button mat-icon-button disabled><mat-icon>folder</mat-icon></button>
                <button mat-flat-button (click)="selectDirectory(node?.id)">
                    {{ node.name }}
                </button>
            </mat-tree-node>
            <!-- This is the tree node template for expandable nodes -->
            <mat-tree-node
                *matTreeNodeDef="let node; when: hasChild"
                matTreeNodePadding
                [ngClass]="{ active: node.id === nodeId }"
                matTooltip="{{ node.name }}"
                matTooltipPosition="right"
            >
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{ treeControl.isExpanded(node) ? 'folder_open' : 'folder' }}
                    </mat-icon>
                </button>
                <button mat-flat-button (click)="selectDirectory(node?.id)">
                    {{ node.name }}
                </button>
            </mat-tree-node>
        </mat-tree>
    </div>
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="close()">{{ 'COMMON.CANCEL' | translate }}</button>
        </div>
        <div class="auto-margin">
            <button mat-button (click)="move()">{{ 'COMMON.MOVE_TXT' | translate }}</button>
        </div>
    </div>
</div>
