<div class="add-graph">
    <app-loader [isLoading]="isLoading"></app-loader>

    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'LOCATION_DASHBOARD.OTHER_DATA_DIALOG_TITLE' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>

    <div mat-dialog-content>
        <h4>{{ 'DASHBOARDS.MULTI_LOCATION_DASHBOARD.GRAPH_SERIES' | translate }}</h4>
        <div class="entity-container margin-tb-30">
            <div class="settings-item" *ngFor="let item of selectedItems; let i = index">
                <div class="composite-list">
                    <div class="each-hydroph-item pad-l-15">
                        <mat-form-field class="full-width">
                            <mat-icon class="locationSearch" matPrefix>search</mat-icon>
                            <input
                                type="text"
                                placeholder="{{ 'CALCULATED_ENTITY_DIALOG.LOCATION_VALUE_PLACEHOLDER' | translate }}"
                                matInput
                                [(ngModel)]="autocompleteData[i].text"
                                (ngModelChange)="filterLocations(i, autocompleteData[i]); formChanged = true"
                                [matAutocomplete]="matLocationSearch"
                                name="locations"
                            />
                            <mat-autocomplete
                                #matLocationSearch="matAutocomplete"
                                (optionSelected)="locationPicked(i, $event.option.value); formChanged = true"
                                [displayWith]="locationSearchDisplay"
                            >
                                <mat-option
                                    *ngFor="let option of (autocompleteData[i].locations)"
                                    [value]="option"
                                    [class.inactive-location]="!option.isActiveLocation"
                                >
                                    {{ option.locationName }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="each-hydroph-item">
                        <app-entity-selector
                            *ngIf="entitiesByLocation[selectedItems[i].lid] && ansrEntitiesByLocation[selectedItems[i].lid]"
                            [single]="true"
                            [availableEntities]="entitiesByLocation[selectedItems[i].lid]"
                            [availableANSREntities]="ansrEntitiesByLocation[selectedItems[i].lid]"
                            [selectedEntitiyIds]="[selectedItems[i].eid]"
                            (selectedEntities)="selectedGroupEntity($event, item)"
                        ></app-entity-selector>
                        <div class="mat-error" *ngIf="invalidEntitySelected && selectedItems[i].eid === -1">
                            <span>{{ 'CALCULATED_ENTITY_DIALOG.INVALID_ENTITY_SELCTED_MESSAGE' | translate }}</span>
                        </div>
                    </div>
                    <div class="color-picker-container each-hydroph-item auto-margin-t-b">
                        <span>{{ 'DASHBOARDS.MULTI_LOCATION_DASHBOARD.SELECT_COLOR' | translate }}</span>
                        <div class="color-picker">
                            <mcc-color-picker
                                usedSizeColors="7"
                                (selected)="selectedColorPicker($event, item); formChanged = true"
                                [selectedColor]="selectedItems[i].color"
                                (change)="checkFormValidity();"
                            ></mcc-color-picker>
                        </div>
                    </div>
                    <div class="auto-margin-t-b">
                        <button type="button" mat-button class="lightBackGround" (click)="removeRow(i)">
                            <mat-icon class="mat-24">delete</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="date-range-container margin-lr-20">
                    <app-date-range-picker
                        class="full-width"
                        [buttonClasses]="['full-width', 'datepicker-escape-margin']"
                        [defaultTimeSpan]="QUICK_DATE_RANGES.PREVIOUS_MONTH"
                        [startDate]="item.startDate"
                        [endDate]="item.endDate"
                        [minDate]="data.startDate"
                        [maxDate]="data.endDate"
                        [doNotCalculateQuickRangesAvailability]="true"
                        [showAvailableDates]="false"
                        (startDateChange)="selectedStartDate($event, i); formChanged = true"
                        (endDateChange)="selectedEndDate($event, i); formChanged = true"
                    ></app-date-range-picker>
                    <div class="mat-error" *ngIf="invalidDate">
                        <span>{{ 'COMMON.INVALID_DATE_TEXT_MESSAGE' | translate }}</span>
                    </div>
                </div>
            </div>

            <div class="composite-list top-border height-50">
                <div class="app-flex-filler"></div>
                <div class="auto-margin custom-flat-button">
                    <button mat-button class="add-button" (click)="addMoreRow(); formChanged = true" [disabled]="!enableAddNewRow || invalidDate">
                        {{ 'CALCULATED_ENTITY_DIALOG.ADDMORE_BUTTON' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <span class="mat-error" *ngIf="!enableAddNewRow">{{ 'LOCATION_DASHBOARD.COLOR_NEED_SELECTED' | translate }}</span>
        <div class="auto-margin">
            <button mat-button (click)="close()">{{ 'COMMON.CANCEL_BUTTON' | translate }}</button>
        </div>
        <div class="auto-margin">
            <button mat-button (click)="save()" [disabled]="!enableAddNewRow || invalidDate || !formChanged || (selectedItems.length === 0 && !data.prevData)">
                {{ 'COMMON.SAVE_BUTTON' | translate }}
            </button>
        </div>
    </div>
</div>