<div class="location-diagnostics">
    <app-loader [isLoading]="false"></app-loader>
    <div class="header">
        <div class="title">
            {{ 'COMMON.ADVANCED_TEXT' | translate }}
        </div>
        <div class="app-flex-filler"></div>
        <div class="auto-margin pad-r-15">
            <mat-icon
                matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                (click)="toggleFilters()"
                class="backIcon advanced-filter-hint"
            >
                filter_list</mat-icon
            >
        </div>
        <app-diagnostics-menu
            [customerId]="customerId"
            [locationId]="locationId"
            [deviceTypes]="deviceTypes"
            class="diagnostics-hint"
            [monitorSeriesType]="monitorSeriesType"
            >{{ 'DIAGNOSTICS.DIAGNOSTICS' | translate }}</app-diagnostics-menu
        >
    </div>
    <div *ngIf="showFilters" class="location-diagnostics-filter">
        <div class="filter-operation">
            <div class="each-filter">
                <app-date-range-picker #adsDatePicker type="datetime" (selectionChange)="getLogs()"> </app-date-range-picker>
            </div>
        </div>
    </div>
    <app-loader [isLoading]="isLoading"></app-loader>
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef><span class="date-time-hint">Date and Time</span></th>
            <td mat-cell *matCellDef="let row">{{ row.status.currentMonitorTime | date: dateFormat }}</td>
        </ng-container>
        <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef><span class="user-name-hint">User Name</span></th>
            <td mat-cell *matCellDef="let row">{{ row.user }}</td>
        </ng-container>
        <ng-container matColumnDef="result">
            <th mat-header-cell *matHeaderCellDef><span class="result-hint"> Result </span></th>
            <td mat-cell *matCellDef="let row">
                <button mat-button (click)="showResult(row)">
                    <mat-icon>remove_red_eye</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons></mat-paginator>
</div>
