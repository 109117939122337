import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Config } from 'app/shared/services/config';
import { HttpClient } from '@angular/common/http';
import {formatDate} from '@angular/common';
import { Observable } from 'rxjs';

@Injectable()
export class BillingReportsService {

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    public http: HttpClient) { }

  public getBillingReportForTimeSpan(startDate: Date, endDate: Date): Observable<string> {
    const start = formatDate(startDate, 'yyyy-MM-dd', this.locale);
    const end = formatDate(endDate, 'yyyy-MM-dd', this.locale);

    const url = `${Config.urls.billingMetricsForTimeSpan}?start=${start}&end=${end}`;

    // We do not use generic here
    // https://github.com/angular/angular/issues/18586
    return this.http.get(url, { 'responseType': 'text'});
  }
}
