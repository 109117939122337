import { Injectable } from '@angular/core';
import { Config } from 'app/shared/services/config';
import { Entities, EntitySelectorObject } from 'app/shared/models/entities';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
    AQUIFER_LEVEL_ENTITY,
    FEET_DISPLAY_GROUP,
    LEVEL_ENTITY,
    RAIN_DISPLAY_GROUP,
    RAIN_ENTITY,
    R_FINAL_ENTITY,
    SILT_ENTITY,
} from '../constant';
import {
    AnsrEntityGroup,
    EntityAttributeData,
    LocationEntitiesData,
    SeriesEntityGroup,
} from '../models/locations-entities-data';
import { VaultEntityParmas } from '../models/telemetry';
import { EntityResponseItem } from '../models/view-data';
import { VaultSelectable } from '../models/vault';
import { EntityUnit } from '../models/units';

type SeriesEntityGroupAndAnsrEntityGroupUnion = SeriesEntityGroup | AnsrEntityGroup;
@Injectable()
export class EntityService {
    public isSiltAvailable: boolean;

    constructor(private http: HttpClient) {}

    public getEntities() {
        return this.http.get<Array<Entities>>(Config.serviceUrl + Config.urls.entities);
    }

    public getEntitiesByCustomer(customerId: number, locationId: number) {
        return this.http.get<Array<Entities>>(
            Config.serviceUrl +
                Config.urls.entitiesByCustomer +
                `?cid=${customerId}&lid=${locationId}&locationOnly=true`,
        );
    }
    public getEntitiesByLocation(customerId: number, lids?: number[], lgid?: number): Observable<EntityResponseItem[]> {
        const vaultEntityParam: VaultEntityParmas = {
            lids,
            lgid,
        };
        const baseURI = Config.serviceUrl + Config.urls.entitiesBylocations + `?cid=${customerId}`;
        return this.http.post<any>(baseURI, vaultEntityParam);
    }

    public ansrEntityGroupToEntitySelectorObject(
        seriesEntityGroups: AnsrEntityGroup[],
        selected: { id: number }[] = [],
        isANSR = false,
    ): EntitySelectorObject[] {
        return this.mapToEntitySelectorObject(seriesEntityGroups, selected, isANSR);
    }

    public seriesEntityGroupToEntitySelectorObject(
        seriesEntityGroups: SeriesEntityGroup[],
        selected: { id: number }[] = [],
        isANSR = false,
    ): EntitySelectorObject[] {
        return this.mapToEntitySelectorObject(seriesEntityGroups, selected, isANSR);
    }

    public mapToEntitySelectorObject(
        seriesEntityGroups: SeriesEntityGroupAndAnsrEntityGroupUnion[],
        selected: { id: number }[] = [],
        isANSR = false,
    ): EntitySelectorObject[] {
        const entitySelectorObjects = [];
        if (!seriesEntityGroups || seriesEntityGroups.length < 1) {
            return [];
        }

        this.isSiltAvailable = false;
        seriesEntityGroups.forEach((x: SeriesEntityGroupAndAnsrEntityGroupUnion): void => {
            if(x) {
                const entGroup: EntitySelectorObject = {
                    groupName: x.name,
                    groupId: x.id,
                    entities: x.entities
                        .filter((opt) => opt.id !== SILT_ENTITY)
                        .map((y) => ({
                            id: y.id,
                            groupId: x.id,
                            name: y.e,
                            isChecked: selected.findIndex((z) => z.id === y.id) !== -1,
                            isANSR,
                        })),
                };
                if (!this.isSiltAvailable) {
                    this.isSiltAvailable = x.entities.find((opt) => opt.id === SILT_ENTITY) !== undefined ? true : false;
                }
                entitySelectorObjects.push(entGroup);
            }
        });

        return entitySelectorObjects;
    }

    public selectableModelToEntitySelectorObject(entities: EntityUnit[]) {
        return entities.reduce((acc: EntitySelectorObject[], val) => {
            let group: EntitySelectorObject = acc.find(({ groupId }) => groupId === val.displayGroupId);

            if (group === undefined) {
                group = {
                    groupName: val.displayGroupName,
                    groupId: val.displayGroupId,
                    entities: [],
                };
                acc.push(group);
            }

            group.entities.push({
                id: val.id,
                name: val.name,
                isChecked: false,
                groupId: val.displayGroupId,
                isANSR: false,
            });
            return acc;
        }, [] as EntitySelectorObject[]);
    }

    public vaultSelectableToEntitySelectorObject(entities: VaultSelectable[], isANSR = false) {
        return entities.reduce((acc: EntitySelectorObject[], val) => {
            let group: EntitySelectorObject = acc.find(({ groupId }) => groupId === val.groupId);

            if (group === undefined) {
                group = {
                    groupName: val.groupName,
                    groupId: val.groupId,
                    entities: [],
                };
                acc.push(group);
            }

            group.entities.push({
                id: val.id,
                name: val.name,
                isChecked: false,
                groupId: val.groupId,
                isANSR,
            });
            return acc;
        }, [] as EntitySelectorObject[]);
    }

    public entityResponseItemToEntitySelectorObject(entities: EntityResponseItem[]) {
        return entities.reduce((acc: EntitySelectorObject[], val) => {
            let group: EntitySelectorObject = acc.find(({ groupId }) => groupId === val.displayGroup);

            if (group === undefined) {
                group = {
                    groupName: val.displayGroupName,
                    groupId: val.displayGroup,
                    entities: [],
                };
                acc.push(group);
            }

            group.entities.push({
                id: val.entityId,
                name: val.entityName,
                isChecked: false,
                groupId: val.displayGroup,
                isANSR: !val.isPreDefined,
            });
            return acc;
        }, [] as EntitySelectorObject[]);
    }

    public getSelectedDevices(data: LocationEntitiesData, series: string, locationType: number): EntityAttributeData[] {
        if (!series) {
          return;
        }
        // filter the entities based on the locations device
        let selectedDevices = data.d.filter((attribute) => attribute.s.toLowerCase() === series.toLowerCase());

        // if selected device not found, try using other
        if (!selectedDevices || selectedDevices.length < 1) {
            selectedDevices = data.d.filter((attribute) => attribute.s.toLowerCase() === 'other');
        }

        // TODO: Move that logic into API. Contact @David with this one
        if (series === 'USGS') {
            const rainGroup = selectedDevices[0].g.find((x) => x.id === RAIN_DISPLAY_GROUP);
            const feetGroup = selectedDevices[0].g.find((x) => x.id === FEET_DISPLAY_GROUP);
            if(rainGroup) rainGroup.entities = rainGroup.entities.filter((x) => x.id === RAIN_ENTITY);
            if(feetGroup) feetGroup.entities = feetGroup.entities.filter((x) => x.id === LEVEL_ENTITY || x.id === AQUIFER_LEVEL_ENTITY);
            return [
                {
                    g: [rainGroup, feetGroup],
                    s: 'USGS',
                },
            ];
        }

        // if a selected location is of type 3 then it is a composite location; add the current entitie to it
        if (locationType === 3) {
            selectedDevices.push(...data.d.filter((attribute) => attribute.s.toLowerCase() === 'composite'));
        }

        return selectedDevices;
    }
}
