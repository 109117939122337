import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';

import { ActivatedRoute, Router, NavigationExtras, ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { VaultTelemetryExportComponent } from '../vault-telemetry-export/vault-telemetry-export.component';
import { forkJoin, Observable } from 'rxjs';
import { Entities } from 'app/shared/models/entities';
import { Subscription } from 'rxjs';
import { EntityService } from 'app/shared/services/entity.service';
import {
    activeInactiveLocationQueryParam,
    customerLocationGroupQueryParam,
    customerQueryParam,
} from 'app/shared/models/customer';
import { UsersService } from 'app/pages/admin/users.service';
import { LocationArgs, Locations, LocationStatus } from 'app/shared/models/locations';
import { Selectable } from 'app/shared/models/selectable';
import { TelemetryExportDialog } from 'app/shared/models/telemetry';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-telemetry-export-modal',
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class VaultTelemetryExportModalComponent implements OnInit, OnDestroy {
    private customerId: number;
    private subscriptions = new Array<Subscription>();
    public includeInactiveLocations: boolean;
    public locationGroupID: number;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        private usersService: UsersService,
        private entityService: EntityService,
    ) {
        this.customerId = this.activatedRoute.snapshot.data['vault-telemetry-export-resolver'];
    }

    public ngOnInit() {
        // get location details and create
        // this should be getting created in the resolver but for some reason resolver is having
        // issues subscsribing to behvaior subject and then flattening with Observables. I tried
        // .asObservable but it just sits in a loop... this should be fixed by converting the
        // whole site to use routing

        //  subscribe to customer change
        this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            // get updated customer id
            const currentCustomerID = Number(params.get(customerQueryParam));
            this.locationGroupID = Number(params.get(customerLocationGroupQueryParam));

            // get updated locations
            this.includeInactiveLocations = Boolean(Number(params.get(activeInactiveLocationQueryParam)));

            // initialize location parameter
            const locationArgs = <LocationArgs>{
                customerId: this.customerId,
                locationGroupID: this.locationGroupID,
                IncludeInactiveLocations: this.includeInactiveLocations,
            };

            const locationsObservable: Observable<Selectable[]> = this.usersService
                .getLocationsList(locationArgs)
                .pipe(
                    map((visibleLocations: Array<Locations>) =>
                        this.includeInactiveLocations
                            ? visibleLocations.filter((x) => x.viewable)
                            : visibleLocations.filter(
                                  (x) =>
                                      x.viewable &&
                                      (x.status === LocationStatus.Active || x.status === LocationStatus.Maintenance),
                              ),
                    ),
                )
                .pipe(
                    map((location: Array<Locations>) =>
                        location.map((loc: Locations) => <Selectable>{ id: loc.locationId, name: loc.locationName }),
                    ),
                );

            const entitiesObservable: Observable<Array<Entities>> = this.entityService.getEntities();
            const locationsAndEntitiesSubscription = forkJoin(locationsObservable, entitiesObservable).subscribe(
                (result: [Selectable[], Array<Entities>]) => {
                    const dialogSubscription = this.dialog
                        .open(VaultTelemetryExportComponent, {
                            data: <TelemetryExportDialog>{
                                entities: result[1],
                                locations: result[0],
                            },
                            disableClose: true,
                        })
                        .afterClosed()
                        .subscribe(() => {
                            this.router.navigate(['../'], <NavigationExtras>{
                                queryParams: { exported: true },
                                queryParamsHandling: 'preserve',
                                relativeTo: this.activatedRoute,
                            });
                        });
                    this.subscriptions.push(dialogSubscription);
                },
                (error) => {
                    // error block
                },
            );
            this.subscriptions.push(locationsAndEntitiesSubscription);
        });
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
