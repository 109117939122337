export interface RegexPattern {
    latitudePattern: string;
    longitudePattern: string;
    latitudeZeroPattern: string;
    longitudeZeroPattern: string;
    alphaNumericPattern: string;
    alphaNumericWith_Pattern: string;
    alphaNumericWithoutSpacePattern: string;
    alphaNumericWithoutSpace_16CharsPattern: string;
    alphaNumericWithSpecialChars: string;
    channelEntriesLimit: string;
    numeric3_36Pattern: string;
    noSpaceArround: string;
    ipaddressPattern: string;
    numericDecimal1PlacePattern: string;
    numericDecimal2PlacePattern: string;
    numericDecimal3PlacePattern: string;
    installationValuePattern: string;
    installationMetricValuePattern: string;
    numeric0_1440Pattern: string;
    numeric1_1440Pattern: string;
    numeric1_24Pattern: string;
    numeric1_60Pattern: string;
    numeric1_72Pattern: string;
    alphaNumericWitHyphen_Pattern: string;
    alphaNumericWitSpecialSymbols_Pattern: string;
    customerNamePattern: string;
    vaultFolderNamePattern: string;
    rainPerTip: string;
    numeric0_100With6DecimalPlacesPattern: string;
    numeric0_10000With1DecimalPlacePattern: string;
    numeric0_10000With0DecimalPlacePattern: string;
    firstCharacterNoSpace: string;
    isEmpty: string;
    strictEmailPattern: RegExp;
    emailPattern: string;
    urlPattern: string;
    firstNamePattern: string;
    lastNamePattern: string;
    minScaleValuePattern: string;
    maxScaleValuePattern: string;
    hcValuePattern: string;
    numericGeneralPattern: string;
    numericWith1DecimalPlaces: string;
    numericWith2DecimalPlaces: string;
    numericWith3DecimalPlaces: string;
    numericWith5DecimalPlaces: string;
    numericOnlyPattern: string;
    numeric0_100With2DecimalPlacesPattern: string;
    numeric0_100000With2DecimalPlacesPattern: string;
    numericGreaterThanZeroPattern: string;
    negativeWithTwoDecimalPattern: string;
    negativeWithThreeDecimalPattern: string;
    validLinkPattern: string;
    negativeWithOneDecimalPattern: string;
    containLowercasePattern: string;
    containUppercasePattern: string;
    containDigitPattern: string;
    containNonWordPattern: string;
    isPositiveInteger : string;
    numeric0_99999with2DecimalPlacesPattern: string;
    numeric0_99999with1DecimalPlacePattern: string;
    negative0_9999with2DecimalPlacesPattern: string;
    negative0_9999with1DecimalPlacePattern: string;
}

export const REGEX_CONFIG: RegexPattern = {
    // @latitudePattern to validate the latitude pattern.
    latitudePattern:
        '^(\\+|-)?(?:90(?:(?:\\.0{0,8})?)|(?:[0-9]|[0-8][0-9])(?:(?:\\.[0-9]{1,8})?)|(?:(?:\\.[0-9]{1,8})?)|(0.0*[1-9]{1,8}))$',

    // @longitudePattern to validate the longitude pattern.
    longitudePattern:
        '^(\\+|-)?(?:180(?:(?:\\.0{0,8})?)|(?:[0-9]|[0-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,8})?)|(?:(?:\\.[0-9]{1,8})?)|(0.0*[1-9]{1,8}))$',

    // @latitudePattern to validate the latitude pattern.
    latitudeZeroPattern:
        '^(\\+|-)?(?:90(?:(?:\\.0{1,8})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,8})?)|(?:(?:\\.[0-9]{1,8})?)|(0.0*[1-9]{1,8}))$',

    // @longitudePattern to validate the longitude pattern.
    longitudeZeroPattern:
        '^(\\+|-)?(?:180(?:(?:\\.0{1,8})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,8})?)|(?:(?:\\.[0-9]{1,8})?)|(0.0*[1-9]{1,8}))$',

    // @alphaNumericPattern to validate the alpha numeric pattern.
    alphaNumericPattern: '^[a-zA-Z0-9]+(\\s+[a-zA-Z0-9]+)*$',

    // @alphaNumericWith_Pattern to validate the alpha numeric - and _ pattern.
    alphaNumericWith_Pattern: '[A-Z-a-z-0-9 ,_-]*',

    // @alphaNumericWithoutSpacePattern to validate the alpha numeric without space pattern.
    alphaNumericWithoutSpacePattern: '[A-Z-a-z-0-9]{1,32}$',

    alphaNumericWithoutSpace_16CharsPattern: '[A-Z-a-z-0-9]{1,16}$',

    // @alphaNumericWithSpecialCharacters
    alphaNumericWithSpecialChars: "^[A-Z-a-z-0-9,&:_'-]+(\\s+[A-Z-a-z-0-9,&:_'-]+)*$",

    // @numeric3_36Pattern to validate the numeric value between 3 to 36  pattern.
    numeric3_36Pattern: '([3-9]|[12][0-9]|3[0-6])',

    // @noSpaceArround to validate the no space arround pattern.
    noSpaceArround: '^[^\\s]+(\\s+[^\\s]+)*$',

    // @ipaddressPattern to validate ip address with range 0-255.
    ipaddressPattern: '^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$',

    // @numericDecimal2PlacePattern to validate the numeric value with one decimal places  pattern.
    numericDecimal1PlacePattern: '^[0-9]+(\\.[0-9])?$',

    // @numericDecimal2PlacePattern to validate the numeric value with two decimal places  pattern.
    numericDecimal2PlacePattern: '^[0-9]+(\\.[0-9]{1,2})?$',

    // @numericDecimal3PlacePattern to validate the numeric value with two decimal places  pattern.
    numericDecimal3PlacePattern: '^[0-9]+(\\.[0-9]{1,3})?$',

    // @installationValuePattern to validate the numeric value between 0 to 100000  pattern.
    // tslint:disable-next-line:max-line-length
    installationValuePattern:
        '^(?:100000(?:(?:\\.0{1,2})?)|(?:[0-9]?|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9]|[1-9][0-9][0-9][0-9][0-9])(?:(?:\\.[0-9]{1,2})?))$',

    // @installationValuePattern to validate the numeric value between 0 to 100000  pattern.
    // tslint:disable-next-line:max-line-length
    installationMetricValuePattern:
        '^(?:100000(?:(?:\\.0{1,2})?)|(?:[0-9]?|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9]|[1-9][0-9][0-9][0-9][0-9])(?:(?:\\.[0-9]{1})?))$',

    // @numeric1_1440Pattern to validate numeric value between 1 to 1440.
    numeric1_1440Pattern: '^(?:14(?:40|[0-3][0-9])|[1][0-3][0-9][0-9]|[1-9][0-9]{2}|[1-9][0-9]|[1-9])$',

    // @numeric1_1440Pattern to validate numeric value between 1 to 1440.
    numeric0_1440Pattern: '^(?:14(?:40|[0-3][0-9])|[1][0-3][0-9][0-9]|[1-9][0-9]{2}|[1-9][0-9]|[0-9])$',

    // @numeric1_24Pattern to validate the numeric value between 1 to 24  pattern.
    numeric1_24Pattern: '^([1-9]|[1][0-9]|[2][0-4])$',

    // @numeric1_60Pattern to validate the numeric value between 1 to 60  pattern.
    numeric1_60Pattern: '^([1-9]|[1][0-9]|[2][0-9]|[3][0-9]|[4][0-9]|[5][0-9]|[6][0])$',

    // @numeric1_72Pattern to validate the numeric value between 1 to 72  pattern.
    numeric1_72Pattern: '^([1-9]|[1-6][0-9]|7[0-2])$',

    // @alphaNumericWitHyphen_Pattern to validate the alpha numeric hyphen and _ pattern.
    alphaNumericWitHyphen_Pattern: '^[a-zA-Z0-9-_]+$',

    // @alphaNumericWitHyphen_Pattern to validate the alpha numeric hyphen and _ pattern.
    // tslint:disable-next-line:quotemark
    alphaNumericWitSpecialSymbols_Pattern: "^[a-zA-Z0-9:, '&_\\-.#]*$",

    // tslint:disable-next-line:max-line-length
    // @customerNamePatternReg to validate alpha numeric with -., _ # & () and at least 2 letters and first character must be a letter or number pattern.
    // customerNamePattern: '^[a-zA-Z0-9]*(?:[ ]*[a-zA-Z][a-zA-Z0-9 */*&#() ,._-]*){2,64}$',
    customerNamePattern: '^.{1,64}$',

    // @vaultFolderNamePattern to validate the alpha numeric and hyphen pattern.
    vaultFolderNamePattern: '^[a-zA-Z0-9- ]+$',

    // @rainPerTip to validate the numeric value between 0 to 10000 with 6 decimal places pattern.
    rainPerTip:
        '^(?:10000(?:(?:\\.0{1,6})?)|(?:[0]*|[0-9]|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9])(?:(?:\\.[0-9]{1,6})?))$',

    // @channelEntriesLimit to validate that channel entries do not get input out of the range 2 to 257
    channelEntriesLimit: '^([2-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-7])$',

    // @numeric1_100With6DecimalPlacesPattern to validate the numeric value between 0 to 100 with 6 decimal places pattern.
    numeric0_100With6DecimalPlacesPattern: '^[0-9]?[0-9]?(\\.[0-9]{1,6})?$|^(100)(\\.[0]{1,6})?$',

    // @numeric1_10000With6DecimalPlacesPattern to validate the numeric value between 0 to 10000 with 1 decimal place pattern.
    numeric0_10000With1DecimalPlacePattern:'^(?:[1-9][0-9]{0,3}(?:\\.\\d{1,1})?|10000|10000\\.0|0|(?:0\\.\\d{1,1}))$',

    numeric0_10000With0DecimalPlacePattern:'^(?:[1-9][0-9]{0,3}?|10000|10000\)$',

    // @firstCharacterNoSpace to validate first character should not be a space
    firstCharacterNoSpace: '^\\S.*$',

    // @isEmpty checks only space or a empty string
    isEmpty: '.*\\S.*|\\r\\n?|\\n',

    // stricter pattern that will not allow test@123
    strictEmailPattern: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z]{2,}$/i,

    // @emailPattern validates email address
    // emailPattern: '^[a-zA-Z0-9._]+@[a-zA-Z0-9]+[.]+([a-zA-Z0-9])+(?:\.[a-zA-Z0-9-]+)*$',
    // tslint:disable-next-line:max-line-length
    emailPattern:
        '^[a-zA-Z0-9.!#$%&\'*+\=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$',

    urlPattern: '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',

    // @firstNamePattern validates first name
    firstNamePattern: '^[a-zA-Z]{2,35}$',

    // @lastNamePattern validates last name
    lastNamePattern: '^[a-zA-Z]{2,50}$',

    // @minScaleValuePattern to validate the numeric value between 0 to 1000  pattern.
    minScaleValuePattern: '^(?:1000(?:(?:\\.0{1,2})?)|(?:[0-9]?|[1-9][0-9]|[1-9][0-9][0-9])(?:(?:\\.[0-9]{1,2})?))$',

    // @maxScaleValuePattern to validate the numeric value between 0 to 10000  pattern.
    maxScaleValuePattern:
        '^(?:10000(?:(?:\\.0{1,2})?)|(?:[0-9]?|[1-9][0-9]|[1-9][0-9][0-9]|[1-9][0-9][0-9][0-9])(?:(?:\\.[0-9]{1,2})?))$',

    // @hcValuePattern to validate the numeric value between 0 to 1000  pattern and support 4 decimal point.
    hcValuePattern: '^(?:1000(?:(?:\\.0{1,4})?)|(?:[0-9]?|[1-9][0-9]|[1-9][0-9][0-9])(?:(?:\\.[0-9]{1,4})?))$',

    // @numeric1GeneralPattern to validate number greater than 0 and optional and valid decimals
    numericGeneralPattern: '^[0-9]+(\\.[0-9]+)?$',

    numericWith1DecimalPlaces: '^[0-9]*(\\.[0-9]{0,1})?$',
    numericWith2DecimalPlaces: '^[0-9]*(\\.[0-9]{0,2})?$',
    numericWith3DecimalPlaces: '^[0-9]*(\\.[0-9]{0,3})?$',
    numericWith5DecimalPlaces: '^[0-9]*(\\.[0-9]{0,5})?$',

    // @numeric1GeneralPattern to validate number greater than 0
    numericOnlyPattern: '^[0-9]*$',

    // @numeric0_100With6DecimalPlacesPattern to validate the numeric value greater than 0 to 100 with 2 decimal places pattern.
    numeric0_100With2DecimalPlacesPattern: '^[0-9]?[0-9]?(\\.[0-9]{1,2})?$|^(100)(\\.[0]{1,2})?$',

    // @numeric0_100000With6DecimalPlacesPattern to validate the numeric value greater than 0 to 100 with 2 decimal places pattern.
    numeric0_100000With2DecimalPlacesPattern:
     '^[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?(\\.[0-9]{1,2})?$|^(100000)(\\.[0]{1,2})?$',

     numeric0_99999with2DecimalPlacesPattern:
     '^[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?(\\.[0-9]{1,2})?$',

     numeric0_99999with1DecimalPlacePattern:
     '^[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?(\\.[0-9]{0,1})?$',

     numericGreaterThanZeroPattern: '^(0*[1-9][0-9]*(.[0-9]+)?|0+.[0-9]*[1-9][0-9]*)$',

     negative0_9999with2DecimalPlacesPattern:  '-?[0-9][0-9]?[0-9]?[0-9]?(\\.[0-9]{1,2})?$',
     
     negative0_9999with1DecimalPlacePattern: '-?[0-9][0-9]?[0-9]?[0-9]?(\\.[0-9]{0,1})?$',

    negativeWithTwoDecimalPattern: '^[-+]?[0-9]+(\\.[0-9]{1,2})?$',

    negativeWithOneDecimalPattern: '^[-+]?[0-9]+(\\.[0-9]{1})?$',

    negativeWithThreeDecimalPattern: '^[-+]?[0-9]+(\\.[0-9]{1,3})?$',

    validLinkPattern: '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?',

    containLowercasePattern: '(?=.*[a-z])',
    containUppercasePattern: '(?=.*[A-Z])',
    containDigitPattern: '(?=.*[0-9])',
    containNonWordPattern: '(?=.*[\\W])',

    // @isInteger is to validate if a value is a positive Integer
    isPositiveInteger: '^[1-9][0-9]*$',
};
