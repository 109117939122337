import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class PreLoaderService {
    /**
     * Represents the emitter which will emit a loading state.
     */
    public loadingEmitter = new BehaviorSubject<boolean>(false);

    /**
     * Marks the loader as loading.
     */
    public start(): void {
        this.loadingEmitter.next(true);
    }

    /**
     * Marks the loader as not loading.
     */
    public stop(): void {
        this.loadingEmitter.next(false);
    }
}
