import { SeasonType, SliicerCaseStudy } from 'app/shared/models/sliicer';
import { DayOfYearDto, Meteorological, SeasonDefinitionDto, Settings } from 'app/shared/models/sliicer/settings';
import moment, { max, min } from 'moment';
import * as O from 'fp-ts/es6/Option';
import * as E from 'fp-ts/es6/Either';
import * as A from 'fp-ts/es6/Array';
import { pipe } from 'fp-ts/es6/pipeable';
import { Option, some, none, isSome, isNone, option } from 'fp-ts/es6/Option';
import {
    FormField,
    NonEmptyString,
    safeDate,
    SafeDate,
    validNonEmptyString,
} from 'app/pages/sliicer/shared/utils/composable-validation';
import { Either, either, isRight, right, left } from 'fp-ts/es6/Either';
import { sequenceS, sequenceT } from 'fp-ts/es6/Apply';
import { flow, identity } from 'fp-ts/es6/function';
import { assertNever } from 'app/pages/sliicer/shared/utils/general-utils';

type IterableSeasonType = SeasonType.Year | SeasonType.Quarter | SeasonType.Month | SeasonType.Meteorological;
export interface Season {
    name: string;
    year: number;
    order: number;
    periodStart: Date;
    periodEnd: Date;
}

export interface DisplaySeason {
    season: string;
    periodStart: string;
    periodEnd: string;
    startDiffers: boolean;
    endDiffers: boolean;
}

export type CustomSeasonFormItem = {
    name: FormField<string, NonEmptyString>;
    startDate: FormField<Date, SafeDate>;
};

export type State = {
    seasonType: SeasonType;
    dateFormat: string;
    studyStartDate: Option<Date>;
    studyEndDate: Option<Date>;
    seasons: Option<Season[]>;
    displaySeasons: Option<DisplaySeason[]>;
    customSeasonsForm: Option<SeasonDefinitionItem[]>;
};

export type CaseStudyDates = Option<{ startDate: Date; endDate: Date }>;

export type SeasonsInitData =
    | { seasonType: SeasonType.Custom; seasons: SeasonDefinition[] }
    | { seasonType: IterableSeasonType | SeasonType.None };

export const daysInAMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

const defaultSeasonsDefinition: { [key in IterableSeasonType]: SeasonDefinition[] } = {
    [SeasonType.Year]: [{ name: <NonEmptyString>'Year', start: <DayOfYear>{ month: 0, day: 1 } }],
    [SeasonType.Quarter]: [
        { name: <NonEmptyString>'Quarter1', start: <DayOfYear>{ month: 0, day: 1 } },
        { name: <NonEmptyString>'Quarter2', start: <DayOfYear>{ month: 3, day: 1 } },
        { name: <NonEmptyString>'Quarter3', start: <DayOfYear>{ month: 6, day: 1 } },
        { name: <NonEmptyString>'Quarter4', start: <DayOfYear>{ month: 9, day: 1 } },
    ],
    [SeasonType.Meteorological]: [
        { name: <NonEmptyString>'Spring', start: <DayOfYear>{ month: 2, day: 1 } },
        { name: <NonEmptyString>'Summer', start: <DayOfYear>{ month: 5, day: 1 } },
        { name: <NonEmptyString>'Fall', start: <DayOfYear>{ month: 8, day: 1 } },
        { name: <NonEmptyString>'Winter', start: <DayOfYear>{ month: 11, day: 1 } },
    ],
    [SeasonType.Month]: [
        { name: <NonEmptyString>'Jan', start: <DayOfYear>{ month: 0, day: 1 } },
        { name: <NonEmptyString>'Feb', start: <DayOfYear>{ month: 1, day: 1 } },
        { name: <NonEmptyString>'Mar', start: <DayOfYear>{ month: 2, day: 1 } },
        { name: <NonEmptyString>'Apr', start: <DayOfYear>{ month: 3, day: 1 } },
        { name: <NonEmptyString>'May', start: <DayOfYear>{ month: 4, day: 1 } },
        { name: <NonEmptyString>'Jun', start: <DayOfYear>{ month: 5, day: 1 } },
        { name: <NonEmptyString>'Jul', start: <DayOfYear>{ month: 6, day: 1 } },
        { name: <NonEmptyString>'Aug', start: <DayOfYear>{ month: 7, day: 1 } },
        { name: <NonEmptyString>'Sep', start: <DayOfYear>{ month: 8, day: 1 } },
        { name: <NonEmptyString>'Oct', start: <DayOfYear>{ month: 9, day: 1 } },
        { name: <NonEmptyString>'Nov', start: <DayOfYear>{ month: 10, day: 1 } },
        { name: <NonEmptyString>'Dec', start: <DayOfYear>{ month: 11, day: 1 } },
    ],
};
export interface DayOfYearBrand {
    readonly DayOfYear: unique symbol;
}

export type DayOfYear = DayOfYearDto & DayOfYearBrand;

/// day of year validation
function isValidDayOfYear(doy: DayOfYearDto): doy is DayOfYear {
    return (
        doy !== null &&
        doy !== undefined &&
        doy.month !== null &&
        doy.month !== undefined &&
        !isNaN(doy.month) &&
        doy.day !== null &&
        doy.day !== undefined &&
        !isNaN(doy.day) &&
        doy.month >= 0 &&
        doy.month <= 11 &&
        doy.day >= 1 &&
        doy.day <= daysInAMonth[doy.month]
    );
}

export function dayOfYear(doy: DayOfYearDto): Either<string, DayOfYear> {
    return isValidDayOfYear(doy) ? right(doy) : left('COMMON.VALIDATION.INVALID_DAY');
}
//////

export type SeasonDefinition = {
    name: NonEmptyString;
    start: DayOfYear;
};

export type SeasonDefinitionItem = {
    name: FormField<string, NonEmptyString>;
    start: FormField<DayOfYearDto, DayOfYear>;
};

export const seasonDefinition = (dto: SeasonDefinitionDto): Either<string, SeasonDefinition> =>
    sequenceS(either)({
        name: validNonEmptyString(dto.name),
        start: dayOfYear(dto.start),
    });

const seasonDefinitionItem = (dto: SeasonDefinitionDto): SeasonDefinitionItem => ({
    name: { raw: dto.name, val: validNonEmptyString(dto.name) },
    start: { raw: dto.start, val: dayOfYear(dto.start) },
});

const setMonth = (month: number, formItem: FormField<DayOfYearDto, DayOfYear>): FormField<DayOfYearDto, DayOfYear> => {
    const next: DayOfYearDto = { month: month, day: formItem.raw.day };
    return { raw: next, val: dayOfYear(next) };
};

const setDay = (day: number, formItem: FormField<DayOfYearDto, DayOfYear>): FormField<DayOfYearDto, DayOfYear> => {
    const next: DayOfYearDto = { month: formItem.raw.month, day: day };
    return { raw: next, val: dayOfYear(next) };
};

const setName = (name: string): FormField<string, NonEmptyString> => ({ raw: name, val: validNonEmptyString(name) });

const newSeason = (): SeasonDefinitionItem => {
    const doy = { month: null, day: null };
    return {
        name: { raw: '', val: validNonEmptyString('') },
        start: { raw: doy, val: dayOfYear(doy) },
    };
};

const applySeasonsFormChange = (change: (_: SeasonDefinitionItem[]) => SeasonDefinitionItem[]) => (state: State) => {
    if (isSome(state.customSeasonsForm) && isSome(state.studyStartDate) && isSome(state.studyEndDate)) {
        const startDate = state.studyStartDate.value;
        const endDate = state.studyEndDate.value;

        const nextForm = pipe(state.customSeasonsForm.value, change, validateSeasonDefinitions);

        const nextSeasons = pipe(nextForm, seasonDefinitionsResult, E.map(buildSeasons(startDate, endDate)));

        const nextDisplaySeasons = E.map(displaySeasonsFromData(state.dateFormat, startDate, endDate))(nextSeasons);

        const nextState: State = {
            ...state,
            customSeasonsForm: some(nextForm),
            seasons: isRight(nextSeasons) ? some(nextSeasons.value) : state.seasons,
            displaySeasons: isRight(nextDisplaySeasons) ? some(nextDisplaySeasons.value) : state.displaySeasons,
        };
        return nextState;
    } else {
        return state;
    }
};

export const seasonAdded = applySeasonsFormChange((form) => [...form, newSeason()]);
export const seasonRemoved = (item: SeasonDefinitionItem) => applySeasonsFormChange(A.filter((x) => x !== item));
export const seasonStartDayChanged = (value: number, item: SeasonDefinitionItem) =>
    applySeasonsFormChange(A.map((x) => (x === item ? { ...item, start: setDay(value, item.start) } : x)));
export const seasonStartMonthChanged = (value: number, item: SeasonDefinitionItem) =>
    applySeasonsFormChange(A.map((x) => (x === item ? { ...item, start: setMonth(value, x.start) } : x)));
export const seasonNameChanged = (value: string, item: SeasonDefinitionItem) =>
    applySeasonsFormChange(A.map((x) => (x === item ? { ...item, name: setName(value) } : x)));

const isAfter = (x: DayOfYear, y: DayOfYear) => {
    if (x.month > y.month) {
        return true;
    } else if (x.month === y.month && x.day > y.day) {
        return true;
    } else {
        return false;
    }
};

const checkAfterPrev = (prev: Option<DayOfYear>) => {
    if (isSome(prev)) {
        return E.filterOrElse(
            (x: DayOfYear) => isAfter(x, prev.value),
            () => 'SLIICER.EDIT_SETTINGS.SEASONS.SHOULD_START_AFTER_PREVIOUS_SEASON',
        );
    } else {
        return identity;
    }
};

const getPreviousStartDayIfDefined = (prev: SeasonDefinitionItem | null | undefined) =>
    pipe(
        O.fromNullable(prev),
        O.chain((prev) => O.fromEither(prev.start.val)),
    );

const validateSeasonDefinitions = (form: SeasonDefinitionItem[]): SeasonDefinitionItem[] =>
    form.map((item, index, all) => ({
        ...item,
        start: {
            raw: item.start.raw,
            val: checkAfterPrev(getPreviousStartDayIfDefined(all[index - 1]))(item.start.val),
        },
    }));

const seasonDefinitionsResult = (form: SeasonDefinitionItem[]): Either<string, SeasonDefinition[]> =>
    pipe(
        form,
        A.map((item) => sequenceS(either)({ name: item.name.val, start: item.start.val })),
        A.array.sequence(either),
        E.mapLeft(() => 'COMMON.VALIDATION.VALIDATION_FAILED'),
        E.filterOrElse(
            (defs) => defs.length > 1,
            () => 'SLIICER.EDIT_SETTINGS.SEASONS.AT_LEAST_2_SEASONS_REQUIRED',
        ),
    );

function enumerateYears(startDate: Date, endDate: Date): number[] {
    return A.range(startDate.getFullYear() - 1, endDate.getFullYear());
}

const buildSeasons =
    (startDate: Date, endDate: Date, onePerYear = false) =>
    (seasonDefs: SeasonDefinition[]): Season[] => {
        // const seasonDefs: SeasonDefinition[] = seasonsDefinition[seasonType];
        if (seasonDefs.length < 1) {
            return [];
        }

        type ExpandedSeasonDef = {
            name: string;
            year: number;
            order: number;
            start: Date;
            end: Date;
        };

        const intersectsWithStudyDates = (def: ExpandedSeasonDef) => def.end >= startDate && def.start <= endDate;

        const mapSeasons = (year: number): Season[] =>
            pipe(
                seasonDefs,
                // construct seasons backwards, where endDate of the current season is startDate of the next minus 1 day
                // initialize with an empty array and the startDate of the first season of the next year
                A.reduceRight(
                    {
                        seasons: <ExpandedSeasonDef[]>[],
                        nextSeasonStartDate: new Date(year + 1, seasonDefs[0].start.month, seasonDefs[0].start.day),
                    },
                    (curr, res) => {
                        const start = new Date(year, curr.start.month, curr.start.day);
                        res.seasons.unshift({
                            name: curr.name,
                            year: year,
                            order: seasonDefs.indexOf(curr),
                            start: start,
                            end: moment(res.nextSeasonStartDate).subtract(1, 'day').toDate(),
                        });

                        return { seasons: res.seasons, nextSeasonStartDate: start };
                    },
                ),
                (res) => res.seasons,
                A.filter(intersectsWithStudyDates),
                A.map((x) => ({
                    name: x.name,
                    year: x.year,
                    order: x.order,
                    periodStart: x.start,
                    periodEnd: x.end,
                })),
            );

        const res = onePerYear
            ? pipe(enumerateYears(startDate, startDate), A.map(mapSeasons), A.flatten)
            : pipe(enumerateYears(startDate, endDate), A.map(mapSeasons), A.flatten);
        return res;
    };

const displaySeasonsFromData: (
    dateFormat: string,
    startDate: Date,
    endDate: Date,
) => (seasonsData: Season[]) => DisplaySeason[] = (dateFormat, startDate, endDate) => (seasonsData) => {
    const startDateM = moment(startDate).startOf('day');
    const endDateM = moment(endDate).endOf('day');

    return seasonsData.map((s) => {
        const periodStartM = moment(s.periodStart).startOf('day');
        const periodEndM = moment(s.periodEnd).endOf('day');

        const adjustedPeriodStartM = max(periodStartM, startDateM);
        const adjustedPeriodEndM = min(periodEndM, endDateM);

        return {
            season: `${s.name} - ${s.year}`,
            periodStart: adjustedPeriodStartM.format(dateFormat),
            periodEnd: adjustedPeriodEndM.format(dateFormat),
            startDiffers: adjustedPeriodStartM != periodStartM,
            endDiffers: adjustedPeriodEndM != periodEndM,
        };
    });
};

export function caseStudyDates(caseStudyDetails: SliicerCaseStudy): CaseStudyDates {
    return pipe(
        caseStudyDetails.config,
        O.fromNullable,
        O.map((config) => ({
            startDate: moment(config.startDate).toDate(),
            endDate: moment(config.endDate).toDate(),
        })),
    );
}

export function caseStudySeasonsInitData(caseStudyDetails: SliicerCaseStudy): SeasonsInitData {
    const seasonType =
        caseStudyDetails.settings && caseStudyDetails.settings.seasonType
            ? caseStudyDetails.settings.seasonType
            : SeasonType.None;
    const seasons = pipe(
        O.fromNullable(caseStudyDetails.settings),
        O.chain((settings) => O.fromNullable(settings.seasonDefinitions)),
        O.chain((defs) => pipe(defs, A.map(seasonDefinition), A.array.sequence(either), O.fromEither)),
    );

    return seasonType === SeasonType.Custom
        ? { seasonType, seasons: isSome(seasons) ? seasons.value : [] }
        : { seasonType };
}

export function caseStudySeasonDefinitions(caseStudyDetails: SliicerCaseStudy): SeasonDefinition[] {
    const defs = pipe(
        caseStudyDetails.settings,
        E.fromNullable(''),
        E.chain((settings) => E.fromNullable('')(settings.seasonDefinitions)),
        E.chain(flow(A.map(seasonDefinition), A.array.sequence(either))),
    );
    return isRight(defs) ? defs.value : [];
}

export function initSeasonsState(dateFormat: string, dates: CaseStudyDates, seasonsInit: SeasonsInitData): State {
    if (isNone(dates)) {
        return {
            dateFormat: dateFormat,
            seasonType: seasonsInit.seasonType,
            studyStartDate: none,
            studyEndDate: none,
            seasons: none,
            displaySeasons: none,
            customSeasonsForm: none,
        };
    } else {
        const { startDate, endDate } = dates.value;
        if (seasonsInit.seasonType === SeasonType.None) {
            return {
                dateFormat: dateFormat,
                seasonType: seasonsInit.seasonType,
                studyStartDate: some(startDate),
                studyEndDate: some(endDate),
                seasons: none,
                displaySeasons: none,
                customSeasonsForm: none,
            };
        } else if (seasonsInit.seasonType === SeasonType.Custom) {
            const seasons = pipe(
                seasonsInit.seasons,
                A.map(seasonDefinition),
                A.array.sequence(either),
                E.map(buildSeasons(startDate, endDate)),
            );

            return {
                dateFormat: dateFormat,
                seasonType: seasonsInit.seasonType,
                studyStartDate: some(startDate),
                studyEndDate: some(endDate),
                seasons: some(isRight(seasons) ? seasons.value : []),
                displaySeasons: some(
                    isRight(seasons) ? displaySeasonsFromData(dateFormat, startDate, endDate)(seasons.value) : [],
                ),
                customSeasonsForm: some(seasonsInit.seasons.map(seasonDefinitionItem)),
            };
        } else {
            const seasons = buildSeasons(startDate, endDate)(defaultSeasonsDefinition[seasonsInit.seasonType]);
            return {
                dateFormat: dateFormat,
                seasonType: seasonsInit.seasonType,
                studyStartDate: some(startDate),
                studyEndDate: some(endDate),
                seasons: some(seasons),
                displaySeasons: some(displaySeasonsFromData(dateFormat, startDate, endDate)(seasons)),
                customSeasonsForm: none,
            };
        }
    }
}

export function toSettingsResult(state: State): Either<string, Settings> {
    if (state.seasonType === SeasonType.Custom) {
        return pipe(
            sequenceS(either)({
                endDate: E.fromOption('SLIICER.EDIT_SETTINGS.SEASONS.INVALID_STUDY_DATES')(state.studyEndDate),
                form: E.fromOption('SLIICER.EDIT_SETTINGS.SEASONS.SEASON_REQUIRED')(state.customSeasonsForm),
            }),
            E.chain(({ endDate, form }) => seasonDefinitionsResult(form)),
            E.map((seasons) => ({
                seasonType: state.seasonType,
                seasonDefinitions: seasons,
            })),
        );
    } else if (state.seasonType === SeasonType.None) {
        return right({
            seasonType: null,
            seasonDefinitions: null,
        });
    } else {
        return right({
            seasonType: state.seasonType,
        });
    }
}

const getSeasonDefinitionsOrDefault = (caseStudyDetails: SliicerCaseStudy) =>
    pipe(
        O.fromNullable(caseStudyDetails.settings),
        O.chain((settings) => O.fromNullable(settings.seasonType)),
        O.map((seasonType) => {
            switch (seasonType) {
                case SeasonType.None:
                    return [];
                case SeasonType.Custom:
                    return caseStudySeasonDefinitions(caseStudyDetails);
                case SeasonType.Year:
                case SeasonType.Quarter:
                case SeasonType.Meteorological:
                case SeasonType.Month:
                    return defaultSeasonsDefinition[seasonType];
                default:
                    assertNever(seasonType);
            }
        }),
    );

const generateSeasons = (caseStudyDetails: SliicerCaseStudy, onePerYear = false) =>
    pipe(
        sequenceT(option)(caseStudyDates(caseStudyDetails), getSeasonDefinitionsOrDefault(caseStudyDetails)),
        O.map(([{ startDate, endDate }, seasonDefs]) => buildSeasons(startDate, endDate, onePerYear)(seasonDefs)),
    );

export function getSeasons(caseStudyDetails: SliicerCaseStudy, onePerYear = false): Season[] {
    const result = generateSeasons(caseStudyDetails, onePerYear);
    return isSome(result) ? result.value : [];
}

export function getYears(caseStudyDetails: SliicerCaseStudy): number[] {
    const d = new Date(caseStudyDetails.config.startDate);
    const first = d.getFullYear();

    const s = new Date(caseStudyDetails.config.endDate);
    const second = s.getFullYear();
    const years: number[] = [];

    for (let i = first; i <= second; i++) years.push(i);

    return years;
}
