/**
 * Represents the argument used by top-nav.component
 * to emit as an event during location group change.
 */
export interface LocationGroupArgs {
    customerId: number;
    locationGroupId: number;
    activeAllFlag?: number;
    params?: { [key: string]: string };
}

/**
 * Represents the argument used by top-nav.component
 * All represents active and inactive locations tooltip
 * Active represents active locations tooltip
 */
export enum LocationToolTip {
    Active = 'TOP_NAV.LOCATION_ACTIVE_LOCATIONS_TOOLTIP',
    All = 'TOP_NAV.LOCATION_ALL_LOCATIONS_TOOLTIP',
}

/**
 * Represents the argument used by top-nav.component
 * All represents active and inactive locations
 * Active represents only active locations
 */
export enum ToggleLocation {
    Active,
    All,
}
