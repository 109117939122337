import  SLIICER  from 'assets/i18n/en-us.json';

export enum BasinQviGroup {
    AllStorms = 'AllStorms',
    Seasons = 'Seasons',
    Years = 'Years',
    Regimes = 'Regimes'
}

export interface BasinQvi {
    configurationGroup?: any;
    basinName: string;
    configuration: string;
    group: BasinQviGroup | string;
    mode: Mode;
    zeros: boolean;
    type: Type;
    regressionType: RegressionType;
    result: BasinQviResult;
    projections: QviStormProjection[];
}

export interface QviStormProjection {
    altProjection: number;
    designStormName: string;
    projection: number;
    rain: number;
}

export interface BasinQviResult {
    sumQ: number;
    sumR: number;
    sumQR: number;
    sumR2: number;
    sumQ2: number;
    n: number;
    avgQ: number;
    avgR: number;
    coeff1: number;
    coeff2: number;
    altSlope: number;
    altR2: number;
    altT: number;
    r: number;
    r2: number;
    t: number;
    useAlt: boolean;
}

export enum Mode {
    Net = 'Net',
    Gross = 'Gross',
}

export enum Type {
    PeakPeak = 'PeakPeak',
    PeakVolToPeak = 'PeakVolToPeak',
    VolumeStorm = 'VolumeStorm',
    VolVol = 'VolVol',
}

export enum RegressionType {
    Linear = 'Linear',
    Power = 'Power',
}

export const QVI_CHART_INFO = [
    {
        // Total Event Gross RDII vs. Rainfall Depth
        /**
         * From SLiiCER 2.0 (Legacy) User Manual:
         * Viewing Rain Total vs. Volume (Total Event) Q vs. i (Page 72)
         *    correlates the total rainfall with the net I/I volume for the complete storm event
         *    (usually 72 hours).
         */
        heading: SLIICER.SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.TOTAL_EVENT,
        comparisonType: 'VolVol',
        xFieldGrossAccessor: 'eventRain',
        yFieldGrossAccessor: 'grossIIVolumeEvent',
        yFieldNetAccessor: 'netIIVolumeEvent',
        volumePerTime: false,
        measurePerHour: false,
    },
    {
        // Storm Period Gross RDII vs. Rainfall Depth
        /**
         * From SLiiCER 2.0 (Legacy) User Manual:
         * Viewing Rain Total vs. Volume (Storm Period) Q vs. i (Page 72)
         *    correlates the total rainfall with the net I/I volume for only the Storm period
         *    (usually the first 24 hours) of the event.
         */
        heading: SLIICER.SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_PERIOD_GROSS,
        comparisonType: 'VolumeStorm',
        xFieldGrossAccessor: 'stormRain',
        yFieldGrossAccessor: 'grossIIVolumeStorm',
        yFieldNetAccessor: 'netIIVolumeStorm',
        volumePerTime: false,
        measurePerHour: false,
    },
    {
        // Peak Gross I/I vs. Rainfall to Time of Peak Gross I/I
        /**
         * From SLiiCER 2.0 (Legacy) User Manual:
         * Viewing Rain Total to Peak vs. Peak Q vs. i (Page 72)
         *    correlates the rain total to the time of the peak net I/I with the peak net I/I.
         */
        heading: SLIICER.SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.PEAK_VS_RAIN,
        comparisonType: 'PeakVolToPeak',
        xFieldGrossAccessor: 'rainToPeakGrossII',
        xFieldNetAccessor: 'rainToPeakNetII',
        yFieldGrossAccessor: 'grossIIPeakEvent',
        yFieldNetAccessor: 'netIIPeakEvent',
        volumePerTime: true,
        measurePerHour: false,
    },
    {
        // Peak Gross I/I vs. Rolling Peak Rainfall Intensity
        /**
         * From SLiiCER 2.0 (Legacy) User Manual:
         * Viewing Peak vs. Peak Q vs. i (Page 71)
         *    correlates the peak rainfall with the peak net I/I.
         */
        heading: SLIICER.SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.PEAK_VS_ROLLING,
        comparisonType: 'PeakPeak',
        xFieldGrossAccessor: 'rainPeak',
        xFieldNetAccessor: 'rainPeak',
        yFieldGrossAccessor: 'grossIIPeakEvent',
        yFieldNetAccessor: 'netIIPeakEvent',
        volumePerTime: true,
        measurePerHour: true,
    },
];
