<mat-card-content
    id="blockagePredictionWidgetCardContentId"
    [ngClass]="{ 'widgetFixPagination': totalPaginationLength > 10 }"
>
    <div class="scrollOverlay"></div>
    <app-widget-filters
        (sendDataToWidget)="notifyWidget($event)"
        [hidden]="!showBlockagePredictionSearch"
        [filterSettings]="{
            displayLocations: true,
            singleLocationSelect: false,
            displayDateRanges: true,
            skipInitialCall: true,
            displayblockStatus: true
        }"
        [customerID]="customerID"
        [locationGroupID]="locationGroupID"
        [toggleState]="true"
        [includeInactiveLocations]="includeInactiveLocations"
    >
    </app-widget-filters>

    <app-loader [isLoading]="blockagePredictionLoadingState"></app-loader>

    <div *ngIf="blockagePredictionData?.length === 0" class="notabledataFound custom-table">
        <span
            >{{ 'COMMON.NO_TEXT' | translate }}
            {{ 'HOME.BLOCKAGE_PREDICTION_WIDGET.BLOCKAGE_FOUND_TEXT' | translate }}</span
        >
    </div>
    <div [hidden]="blockagePredictionData?.length === 0" id="blockageLeaderBoardTable" [ngClass]="{'filtersOff': !showBlockagePredictionSearch, 'filtersOn': showBlockagePredictionSearch }"
        class="widget-table">
        <mat-table #table [dataSource]="blockagePredictionDataSource" matSort>
            <ng-container matColumnDef="locationName">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    class="blockageLocationMinWidth blockage-location-hint"
                    (click)="loadSparkLine()"
                    >{{ 'COMMON.LOCATION_COLUMN_TITLE' | translate }}</mat-header-cell
                >
                <mat-cell *matCellDef="let blockagePredictionDetail">
                    <a
                        class="nowrap-white-space"
                        [ngClass]="{
                            customLongName: validateLocationNameLength(blockagePredictionDetail?.locationName, 10)
                        }"
                        [title]="
                            validateLocationNameLength(blockagePredictionDetail.locationName, 10)
                                ? blockagePredictionDetail?.locationName
                                : ''
                        "
                        (click)="getMarkerLocationDetails(blockagePredictionDetail.locationId)"
                        >{{ blockagePredictionDetail?.locationName }}</a
                    >
                </mat-cell>
            </ng-container>

            <!-- Blockage Prediction Date -->
            <ng-container matColumnDef="date">
                <mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header
                    (click)="loadSparkLine()"
                    class="blockageDateMinWidth blockage-date-hint"
                >
                    {{ 'COMMON.TIMESTAMP_COLUMN_TITLE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let blockagePredictionDetail">{{ blockagePredictionDetail.date }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="blockStatus">
                <mat-header-cell *matHeaderCellDef mat-sort-header class="text-left blockage-status-hint">{{
                    'COMMON.STATUS_COLUMN_TITLE' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let blockagePredictionDetail">
                    <div style="padding-right: 20px">
                        <mat-icon
                            class="grey-icon"
                            matTooltip="{{ unknown }}"
                            *ngIf="blockagePredictionDetail.blockStatus === 0"
                            >remove_circle</mat-icon
                        >
                        <mat-icon
                            class="green-icon"
                            matTooltip="{{ noBlockage }}"
                            *ngIf="blockagePredictionDetail.blockStatus === 1"
                            >check_circle</mat-icon
                        >
                        <span
                            class="yellow-icon"
                            matTooltip="{{ developing }}"
                            *ngIf="blockagePredictionDetail.blockStatus === 2"
                        ></span>
                        <mat-icon
                            class="red-icon"
                            matTooltip="{{ advanced }}"
                            *ngIf="blockagePredictionDetail.blockStatus === 3"
                            >clear</mat-icon
                        >
                    </div>
                </mat-cell>
            </ng-container>

            <!-- DEPTH TREND -->
            <ng-container matColumnDef="depthTrend">
                <mat-header-cell *matHeaderCellDef (click)="loadSparkLine()" class="blockageDepthTrendFixWidth blockage-dtrend-hint">{{
                    'HOME.BLOCKAGE_PREDICTION_WIDGET.DEPTH_TREND_COLUMN_TITLE' | translate
                }}</mat-header-cell>
                <mat-cell *matCellDef="let blockagePredictionDetail">
                    <a
                        *ngIf="rawDataActive"
                        matTooltip="{{ 'COMMON.GENERATE_GRAPH_TOOLTIP' | translate }}"
                        (click)="
                            openHydrograph(
                                blockagePredictionDetail.locationId,
                                blockagePredictionDetail.locationName,
                                blockagePredictionDetail.date
                            )
                        "
                    >
                        <span [attr.data-sparkline]="blockagePredictionDetail.depthTrend"></span>
                    </a>
                </mat-cell>
            </ng-container>

            <!-- GRAPH -->
            <!-- <ng-container matColumnDef="graph">
                <mat-header-cell *matHeaderCellDef>{{ 'COMMON.GRAPH_COLUMN_TITLE' | translate}} </mat-header-cell>
                <mat-cell *matCellDef="let blockagePredictionDetail">
                    <a (click)="openHydrograph(blockagePredictionDetail.locationId, blockagePredictionDetail.locationName, blockagePredictionDetail.date)">
                        <mat-icon matTooltip="{{'COMMON.GENERATE_GRAPH_TOOLTIP' | translate }}">show_chart</mat-icon>
                    </a>
                </mat-cell>
            </ng-container> -->

            <mat-header-row *matHeaderRowDef="blockagePredictionDisplayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: blockagePredictionDisplayedColumns"></mat-row>
        </mat-table>
    </div>
    <div [hidden]="!isPaginationRequired">
        <div class="widgetFooter">
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <mat-paginator
                    class="blockage-paginator-hint"
                    #batteryWidgetPaginator
                    [length]="totalPaginationLength"
                    (page)="onPageChange($event)"
                    [pageIndex]="0"
                    [pageSize]="10"
                    [pageSizeOptions]="[10, 25, 100]"
                >
                </mat-paginator>
            </div>
        </div>
    </div>
</mat-card-content>
