import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DesignStorm, DesignStormItem } from 'app/shared/models/sliicer/design-storm';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { isRight } from 'fp-ts/es6/Either';
import { none, some } from 'fp-ts/es6/Option';
import * as Utils from './design-storms-dialog.utils';

@Component({
    selector: 'app-design-storms-dialog',
    templateUrl: './design-storms-dialog.component.html',
    styleUrls: ['./design-storms-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesignStormsDialogComponent implements OnInit {
    public columns: Array<keyof DesignStormItem> = [
        'selected',
        'name',
        'totalStormRain',
        'totalEventRain',
        'cumulativePeakRain',
        'peakRain',
    ];
    public dataSource = new MatTableDataSource<DesignStorm>();
    public state: Utils.State;
    public unitOfMeasure: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: DesignStorm[],
        private dialogRef: MatDialogRef<DesignStormsDialogComponent, DesignStorm[]>,
        private dateutilService: DateutilService,
    ) {}

    public ngOnInit() {
        this.state = Utils.initState(Utils.tagDesignStorms(this.data));
        const isMetric = this.dateutilService.isCustomerUnitsMetric.getValue();
        this.unitOfMeasure = isMetric ? 'mm' : 'in';
    }

    public delete() {
        if (this.state.view === 'list') {
            this.state = Utils.deleteSelectedStorms(this.state);
        }
    }

    public new() {
        this.state = Utils.initEditState(none, this.state.data.storms);
    }

    public save() {
        this.dialogRef.close(this.state.data.storms);
    }

    public close() {
        this.dialogRef.close();
    }

    public editStorm(item: Utils.DesignStormItemD) {
        this.state = Utils.initEditState(some(item), this.state.data.storms);
    }

    public cancelEditStorm() {
        if (this.state.data.storms.length !== 0) {
            this.state = Utils.initListState(this.state.data.storms);
        } else {
            this.close();
        }
    }

    public saveStorm() {
        if (this.state.view === 'edit') {
            const result = Utils.stormEditResult(this.state);

            if (isRight(result)) {
                this.state = Utils.initListState(result.value);
            }
        }
    }

    public stormSelectedChanged(checked: boolean, storm: Utils.DesignStormItemD) {
        if (this.state.view === 'list') {
            this.state = Utils.stormSelectedChanged(storm, checked)(this.state);
        }
    }

    public nameChanged(str: string) {
        if (this.state.view === 'edit') {
            this.state = Utils.nameChanged(str)(this.state);
        }
    }

    public peakRainChanged(str: string) {
        if (this.state.view === 'edit') {
            this.state = Utils.peakRainChanged(str)(this.state);
        }
    }

    public cumulativePeakRainChanged(str: string) {
        if (this.state.view === 'edit') {
            this.state = Utils.cumulativePeakRainChanged(str)(this.state);
        }
    }

    public totalStormRainChanged(str: string) {
        if (this.state.view === 'edit') {
            this.state = Utils.totalStormRainChanged(str)(this.state);
        }
    }

    public totalEventRainChanged(str: string) {
        if (this.state.view === 'edit') {
            this.state = Utils.totalEventRainChanged(str)(this.state);
        }
    }
}
