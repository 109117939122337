import { Component, OnInit, ViewEncapsulation} from '@angular/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AnnouncementRequest, AnnouncementService } from 'app/shared/services/announcement.service';
import { DateutilService } from 'app/shared/services/dateutil.service';



//
@Component({
    templateUrl: './Alert.component.html',
    styleUrls: ["./Alert.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class AnnouncementComponent implements OnInit{
    constructor(
        private dialogRef: MatDialogRef<AnnouncementComponent>,
        private announcementService: AnnouncementService,
        private dateutilService: DateutilService
    ) {}

    public activeCheckbox: boolean;
    public alertText: string;
    public alertStartDate: Date;
    public alertEndDate: Date;

    public isOriginalValuesModified = false;
    private originalValues = {
        activeCheckbox: null,
        alertText: null,
        alertStartDate: null,
        alertEndDate: null
    }

    public customerDateFormat: string;
    public maxNumberOfCharacters = 2048;
    public counter = true;
    public isMandatoryFilled: boolean;
    public returnedText: string;
    public endDateBfrStartDate: boolean;
    public startDateAfterEndDate: boolean;
    public startDateBfrToday: boolean;
    public endDateBfrToday: boolean;
    public showpageHint: boolean;
    private todayDate: Date;
    public numberOfCharacters2 = 0;

    onModelChange(textValue: string): void {
        this.numberOfCharacters2 = textValue.length;
        this.updateForm();
    }

    ngOnInit(): void {
        this.dateutilService.dateFormat.subscribe(() => {
            this.customerDateFormat = this.dateutilService.getFormat();
        });
        this.todayDate = new Date();
        this.todayDate.setHours(0);
        this.todayDate.setMinutes(0);
        this.todayDate.setSeconds(1);
        this.updateAnnouncement();
    }

    public setAlertStartDate(event: MatDatepickerInputEvent<Date | string>) {
        if (event.target && event.target.value && new Date(event.target.value) >= this.todayDate &&
            (this.alertEndDate >= new Date(event.target.value) || this.alertEndDate == null)) {
            if (typeof event.target.value === 'string' && !Number.isNaN(Date.parse(event.target.value))) {
                this.alertStartDate = new Date(event.target.value);
            } else if (!isNaN((event.target.value as Date).getTime())) {
                    this.alertStartDate = event.target.value as Date;
            }
            this.startDateAfterEndDate = false;
            this.startDateBfrToday = false;
        }
        else {
            this.alertStartDate = null;
            if(new Date(event.target.value) < this.todayDate){
                this.startDateBfrToday = true;
                this.startDateAfterEndDate = false;
            }
            else{
                this.startDateBfrToday = false;
                this.startDateAfterEndDate = true;
            }
        }

        this.updateForm();
    }

    public setAlertEndDate(event: MatDatepickerInputEvent<Date | string>) {
        if (event.target && event.target.value && new Date(event.target.value) >= this.todayDate &&
            (this.alertStartDate <= new Date(event.target.value) || this.alertStartDate == null)) {
            if (typeof event.target.value === 'string' && !Number.isNaN(Date.parse(event.target.value))) {
                this.alertEndDate = new Date(event.target.value);
            } else if (!isNaN((event.target.value as Date).getTime())) {
                this.alertEndDate = event.target.value as Date;
            }
            this.endDateBfrStartDate = false;
            this.endDateBfrToday = false;
        }
        else {
            this.alertEndDate = null;
            if(new Date(event.target.value) < this.todayDate)
                this.endDateBfrToday = true;
            else
                this.endDateBfrStartDate = true;
        }
        this.updateForm();
    }

    // updates form to check if all the fields are filled
    public updateForm(){
        if(this.alertStartDate && this.alertEndDate && this.alertText){
            this.isMandatoryFilled = true;
        }
        else if(!this.activeCheckbox){
            this.isMandatoryFilled = true;
        }
        else{
            this.isMandatoryFilled = false;
        }

        this.checkOriginalValues();
    }

    public close() {
        this.dialogRef.close({ success: false });
    }

    public saveAnnouncement() {
        const startDate = this.dateutilService.getLocalDateFromUTCDate(this.alertStartDate);
        const endDate = this.dateutilService.getLocalDateFromUTCDate(this.alertEndDate);
        const startStr = startDate ? startDate.toISOString().split('.')[0] : null;
        const endStr = endDate ? endDate.toISOString().split('.')[0] : null;

        const request: AnnouncementRequest = {
            start: startStr,
            end: endStr,
            isActive: this.activeCheckbox,
            text: this.alertText
        };
        const announce = this.announcementService.setAnnouncement(request);
        announce.subscribe((result)=>{});
        this.close();
    }

    private updateOriginalValues() {
        this.originalValues = {
            activeCheckbox: this.activeCheckbox,
            alertText: this.alertText,
            alertStartDate: this.alertStartDate,
            alertEndDate: this.alertEndDate
        }
        this.isOriginalValuesModified = false;
    }

    public checkOriginalValues() {
        this.isOriginalValuesModified =
            this.activeCheckbox !== this.originalValues.activeCheckbox
            || this.alertText !== this.originalValues.alertText
            || this.alertStartDate !== this.originalValues.alertStartDate
            || this.alertEndDate !== this.originalValues.alertEndDate;
    }

    private updateAnnouncement(){
        const announce = this.announcementService.getAnnouncement();

        announce.subscribe((result)=>{
            this.alertText = result.text;
            this.alertStartDate = new Date(result.start);
            this.alertEndDate = new Date(result.end);
            this.activeCheckbox = result.isActive;

            this.updateOriginalValues();

            this.onModelChange(this.alertText);
        });
    }
}

