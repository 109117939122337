import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

import { FileUploader, FileUploaderOptions, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';

import { VaultService } from '../vault.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { SharedService } from 'app/shared/services/shared.service';
import { VaultDirectory, VaultFile, VaultUpload, VaultUploadResult } from 'app/shared/models/vault';
import { CSV } from 'app/shared/models/customer';
import { IDataTransfer } from 'app/shared/models/modal';

/**
 * Represnts a reusable upload dialog component.
 * @description
 * This component requires the IUploadDatum to be populated with
 * the URI of where the uploads are to go.
 *
 * It is to be invoked by using the Dialog Reference:
 * {@example
 * this.dialog.open(FileUploadDialogComponent, {
 *     data: <IUploadDatum>{
 *       uri: Config.urls.vaultUpload
 *     }
 *  });
 * }
 * See {@linkDocs https://material.angular.io/components/dialog/overview}
 */
@Component({
    selector: 'app-vault-upload-dialog',
    templateUrl: './vault-upload-dialog.component.html',
    styleUrls: ['./vault-upload-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class VaultUploadDialogComponent implements OnInit {
    /**
     * Represents the third party uploading library.
     */
    public uploader: FileUploader;

    /**
     * Indicates whether a file over event has been performed.
     */
    public hasBaseDropZoneOver = false;

    /**
     * Indicates whether the maximum amount of files has been exceeded
     */
    public maxFilesExceeded = false;

    /**
     * Indicates whether a file drop event has been performed.
     */
    public hasFileDropped = false;

    /**
     * Indicates how many files were
     */
    public selectedFileCount = 0;

    /**
     * Indicates how many failed uploads occured.
     */
    public uploadFailCount = 0;

    /**
     * Indicates how many files were uploaded successfully.
     */
    public uploadedFileCount = 0;

    /**
     * Indicates whether all uploads have finished.
     */
    public hasFinished = false;

    /**
     * Indicates whether the overwriting procedure is taking place.
     */
    public isOverwriting = false;

    /**
     * Represnts a colleciton of uploads that failed.
     */
    public failedUploads = new Array<string>();

    /**
     * Represents the collection of files that will be uploaded.
     */
    public overwritableFiles: Array<VaultUploadResult>;

    /**
     * Represents the results of the upload. This is used to track the files
     * that are going to be uploaded and their state post upload attempt.
     */
    private uploadResults = new Array<VaultUploadResult>();

    /**
     * List of uploaded files
     */
    public uploadFiles = new Array<File>();

    /**
     * Flag to check if uploaded file type is accepted CSV or not
     */
    public isWrongFileTypeCSV = false;

    /**
     * Flag to check if uploaded file type is accepted or not
     */
    public isWrongFileType = false;

    /*
     * Represents whether progress bar is showing or not.
     */
    public showProgress = false;

    /**
     * file Upload display message on popup
     */
    public displayMessage: string;

    /**
     * Indicates whether uploaded file size is greater than 20 MB or not
     */
    public isMaxFileSize = false;

    public isWrongFileTypeCSVCopy = false;

    /**
     * Flag to check if uploaded file type is accepted or not
     */
    public isWrongFileTypeCopy = false;
    public response: string;

    public isCsvUpload: boolean;

    constructor(
        private dialogRef: MatDialogRef<VaultUploadDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private dialogData: IDataTransfer,
        private changeDetector: ChangeDetectorRef,
        private vaultService: VaultService,
        private sharedService: SharedService,
        private uiUtilsService: UiUtilsService,
    ) {
        // by default set to empty the uploaded popup text message
        this.sharedService.setFileUploadMessage('');
        this.setupUploadingHandlers();
    }

    /**
     * Framework level licecycle hook.
     */
    public ngOnInit(): void {
        // set file upload message
        this.sharedService.currentMessage.subscribe((response) => (this.displayMessage = response)).unsubscribe();
        let uri = this.dialogData.id;

        // HACK: remove slash, this should be removed in API during GET
        if (uri.endsWith('/')) {
            uri = uri.substr(0, uri.length - 1);
        }

        // getting ads token from session storage which was set by main resolver
        const adsToken = 'Bearer ' + sessionStorage.getItem('adsToken');

        // setup the uploader object for the third party library
        if (this.dialogData.fileType) {
            this.isCsvUpload = this.dialogData.fileType.toUpperCase() === CSV.toUpperCase();

            this.uploader = new FileUploader(<FileUploaderOptions>{
                allowedMimeType: ['application/csv', 'text/csv', 'application/vnd.ms-excel'],
                maxFileSize: 2048 * 1024 * 1024, // 20 MB
                autoUpload: false,
                disableMultipart: false,
                itemAlias: 'files',
                url: uri,
                removeAfterUpload: true,
                authToken: adsToken,
                authTokenHeader: 'Authorization',
            });
        } else {
            this.uploader = new FileUploader(<FileUploaderOptions>{
                allowedFileType: ['image', 'video', 'audio', 'pdf', 'compress', 'doc', 'xls', 'ppt'],
                maxFileSize: 2048 * 1024 * 1024, // 20 MB
                autoUpload: false,
                disableMultipart: false,
                itemAlias: 'files',
                url: uri,
                removeAfterUpload: true,
                authToken: adsToken,
                authTokenHeader: 'Authorization',
            });
        }

        // File uploader method to handle error before file upload
        this.uploader.onWhenAddingFileFailed = (item: any, filter: any) => this.onWhenAddingFileFailed(item, filter);
    }

    /**
     * Method to check file extension and showing error on wrong file type
     * @param item file to be uploaded
     * @param filter file uploader filters
     */
    public onWhenAddingFileFailed(item: any, filter: any) {
        if (filter.name === 'mimeType') {
            this.isWrongFileTypeCSV = true;
        } else if (filter.name === 'fileType') {
            this.isWrongFileType = true;
        } else if (filter.name === 'fileSize') {
            this.isMaxFileSize = true;
        }
        this.hasFileDropped = false;
        this.hasFinished = true;
        return;
    }

    /**
     * Handles the drag-over event for the target area.
     * @param isFileOver Indicates whether a file is over the drop zone.
     */
    public fileOverBase(isFileOver: boolean): void {
        // set the state of hover
        this.hasBaseDropZoneOver = isFileOver;
    }

    /**
     * Handles the drop files event.
     * @param files Represents the dropped files.
     */
    public fileDrop(files: FileList): void {
        if (files == null || this.isWrongFileTypeCSV || this.isWrongFileType) {
            this.isWrongFileTypeCSVCopy = this.isWrongFileTypeCSV;
            this.isWrongFileTypeCSV = false;
            this.isWrongFileTypeCopy = this.isWrongFileType;
            this.isWrongFileType = false;
            return;
        }
        // reset error message for maximum number of files limit
        this.resetErrorMsg(false);

        // reset the current state
        this.resetState();

        // set a value indicating whether
        const hasFiles = files.length > 0;

        if (hasFiles && files.length > 25) {
            this.resetErrorMsg(true);
            return this.resetState();
        }

        // set files dropped
        this.hasFileDropped = hasFiles;

        const filesColleciton = new Array<File>();

        // convert FileList to Array<File> so that uploader can use
        for (let i = 0; i < files.length; i++) {
            filesColleciton.push(files.item(i));
        }
        // store all selected files and return back to dashbord.
        this.uploadFiles = filesColleciton;

        // start handling overwrites
        this.handleOverwrite(files);
    }

    /**
     * Handles the change event for manually selecting files.
     * @param event Represents the input elemetn change event.
     */
    public fileSelectChangeHandler(event: MouseEvent): void {
        // reset error message for maximum number of files limit
        this.resetErrorMsg(false);

        // reset the current state
        this.resetState();

        // set files dropped since we have files that were added
        this.hasFileDropped = true;

        // get the reference to the target element
        const inputElement = <HTMLInputElement>event.target;

        const files: FileList = inputElement.files;

        // ensure that there are files, if not, reset state and exit immediatly
        if (files.length < 1) {
            return this.resetState();
        }

        if (files && files.length > 25) {
            this.resetErrorMsg(true);
            return this.resetState();
        }

        const filesColleciton = new Array<File>();

        // convert FileList to Array<File> so that uploader can use
        for (let i = 0; i < files.length; i++) {
            filesColleciton.push(files.item(i));
        }
        // store all selected files and return back to dashbord.
        this.uploadFiles = filesColleciton;
        // add files to queue
        this.uploader.addToQueue(filesColleciton);

        this.handleOverwrite(files);

        // reset the value of the input element so as to allow for retry of the same file upload
        inputElement.value = null;
    }

    /**
     * Resets the error message for maximum files exceeded
     */
    public resetErrorMsg(isMaximumFilesExceeded: boolean): void {
        this.maxFilesExceeded = isMaximumFilesExceeded;
    }
    /**
     * Resets the common state of the file upload variables.
     */
    public resetState(): void {
        // reset drop state
        this.hasFileDropped = false;

        // reset finished state to
        this.hasFinished = false;

        // reset the error state
        this.failedUploads = new Array<string>();

        // reset file count states
        this.selectedFileCount = this.uploadedFileCount = this.uploadFailCount = 0;

        // repoint array
        this.uploadResults = new Array<VaultUploadResult>();
    }

    /**
     * handler for done button on file overwrite dialog
     * @param files files checked for overwrite
     */
    public uploadeOverwriteComplete(files: Array<VaultUploadResult>): void {
        this.overwritableFiles = files;
    }

    public filesMarkedForOverwrite(): void {
        this.showProgress = true;

        // only show the files that are existing but NOT overwritable
        const excludeList = this.overwritableFiles.filter((f) => f.isExistingFile && !f.isOverwritable);

        if (excludeList.length > 0 && this.uploader.queue.length > 0) {
            excludeList.forEach((file: VaultUploadResult) => {
                const queuedFile = this.uploader.queue.find((fileItem: FileItem) => {
                    return fileItem.file.name.toLowerCase() === file.fileName.toLowerCase();
                });

                this.uploader.removeFromQueue(queuedFile);
            });
        }

        // set the selected files to the amount of files contained within target element
        this.selectedFileCount = this.uploader.queue.length;

        // ensure there are files to uplaod
        if (this.selectedFileCount < 1) {
            // close the dialog
            const vaultUpload: VaultUpload = {
                directoryAndFileName: this.dialogData.currentlySelectedDirectory + this.uploadFiles[0].name,
                fileName: this.uploadFiles[0].name,
                uploadedFileCount: this.uploadedFileCount,
            };
            return this.dialogRef.close(vaultUpload);
        }

        this.uploader.uploadAll();
    }

    private handleOverwrite(files: FileList): void {
        if (files == null || this.isWrongFileTypeCSV || this.isWrongFileType) {
            this.isWrongFileTypeCSVCopy = this.isWrongFileTypeCSV;
            this.isWrongFileTypeCSV = false;
            this.isWrongFileTypeCopy = this.isWrongFileType;
            this.isWrongFileType = false;
            return;
        }

        // subscribe to overwritable files
        this.vaultService
            .markExistingFiles(files)
            .subscribe((overwritableFiles: Array<VaultUploadResult>) => {
                if (overwritableFiles.length < 1) {
                    this.selectedFileCount = this.uploader.queue.length;
                    return this.uploader.uploadAll();
                }

                this.overwritableFiles = overwritableFiles;

                // iterate over all overwritable files
                this.overwritableFiles.forEach((file: VaultFile) => {
                    // Find the overwritable file in uploader queue
                    const queuedFile = this.uploader.queue.find((fileItem: FileItem) => {
                        return fileItem.file.name.toLowerCase() === file.fileName.toLowerCase();
                    });

                    // Setting existing file name on overwritable file
                    if (queuedFile) {
                        queuedFile.file.name = file.fileName;
                    }
                });

                this.isOverwriting = true;

                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            })
            .unsubscribe();
    }

    /**
     * Handles setting up handlers for the file uploader.
     */
    private setupUploadingHandlers(): void {
        // set context of this as hooks will be processed in the context of the ng2-file-upload library
        // and so this will point to that instance
        const that = this;

        // set hook for erroring on an upload of an item
        FileUploader.prototype.onErrorItem = (
            item: FileItem,
            response: string,
            status: number,
            headers: ParsedResponseHeaders,
        ): void => {
            // increment the failed amount of uploads
            that.uploadFailCount = that.uploadFailCount + 1;

            that.updateUploadAttempt(item, Number(status));
        };

        // set hook for notification of upload completion
        FileUploader.prototype.onCompleteAll = (): void => {
            // sett the uploaded file count to be the difference of the selected files and the actual uploaded files
            that.uploadedFileCount = that.selectedFileCount - that.uploadFailCount;

            // set upload finished state to true
            that.hasFinished = true;

            // check if errors exist
            that.failedUploads = that.uploadResults
                .filter((result: VaultUploadResult) => !result.isSuccess)
                .map((result: VaultUploadResult) => {
                    if (!result.isSuccess) {
                        return result.fileName;
                    }
                });

            this.uiUtilsService.safeChangeDetection(this.changeDetector);

            // if there are errors
            if (that.failedUploads.length > 0) {
                return;
            }

            // finish upload
            that.handleModalClose();
        };

        // set hoook for updating each file pre-upload
        FileUploader.prototype.onBeforeUploadItem = (item: FileItem) => {
            // don't send credentials as angular framework will take care of this
            item.withCredentials = false;
        };

        // set hook for the completion of an item
        FileUploader.prototype.onCompleteItem = (
            item: FileItem,
            response: string,
            status: number,
            headers: ParsedResponseHeaders,
        ): void => {
            this.response = response;
            this.updateUploadAttempt(item, Number(status));
        };
    }

    /**
     * Updates a collection of upload attempts and sets their success rate.
     * @param fileItem The file item to use for updating the upload attempt.
     * @param httpStatusResult The HTTP status code returned during the upload attempt.
     */
    private updateUploadAttempt(fileItem: FileItem, httpStatusResult: number): void {
        // iterate over all of the attempted uploads
        for (let i = 0; i < this.uploadResults.length; i++) {
            // get attepmted file reference
            const file: VaultUploadResult = this.uploadResults[i];

            // if the names of the completed item don't match, move on
            if (file.fileName.toLowerCase() !== fileItem.file.name.toLowerCase()) {
                continue;
            }

            // set success rate
            file.isSuccess = httpStatusResult < 299;
        }
    }

    /**
     * Hanldes the cleanup of the dialog window.
     * @param files the file collection that was submitted for uploading.
     */
    private handleModalClose(): void {
        // close the dialog
        const uploadedFiles = [];
        if (this.uploadFiles && this.uploadFiles.length > 0) {
            this.uploadFiles.forEach((file) => {
                uploadedFiles.push(this.dialogData.currentlySelectedDirectory + file.name);
            });
        }
        const vaultUpload: VaultUpload = {
            directoryAndFileName: this.dialogData.currentlySelectedDirectory + this.uploadFiles[0].name,
            fileName: this.uploadFiles[0].name,
            uploadedFileCount: this.uploadedFileCount,
            response: this.response,
            uploadedFiles: uploadedFiles,
            directoryName: this.dialogData.currentlySelectedDirectory,
        };
        this.dialogRef.close(vaultUpload);

        this.resetState();
    }
}
