import { Validators } from '@angular/forms';
import { DEPTH, FLOW, HIGH, LOW, MEDIUM } from 'app/shared/constant';
import { LocationStatus } from 'app/shared/models/locations';
import { MonitorAddLocationSelectable, MonitorIdentifier } from 'app/shared/models/monitorsetting';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { DepthDeviceName, DepthDeviceType, VelocityDeviceName, VelocityDeviceType } from 'app/shared/enums/device-type-code';
import { SensorElement, MonitoringPointElement, SampleRateElement, MaxVelocityElement, SensorPreference, ChannelElement } from 'app/shared/components/location-card/models/location-card.model'

export const BATTERY_LOW_THRESHOLD_DEFAULT_VALUE = 7.5;

export const InstallationTypeValidators = function (isMetric: boolean) {
    return [
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern(
            isMetric ? REGEX_CONFIG.installationMetricValuePattern : REGEX_CONFIG.installationValuePattern,
        ),
    ];
};
export const NoneSensor: SensorElement = { id: 0, value: '', text: 'None' };

enum FlowLossType {
    Flow = 0,
    Depth = 1,
}

enum FlowLossSensitivity {
    Low = 0,
    Medium = 1,
    High = 2,
}

export enum LocationCommunicationType
{
	Default = 0,
    Landline = 1,
    DMI = 2,
    Serial = 3,
    Wireless = 4,
    FTP = 5,
	Bluetooth = 6,
    External = 7,
}

const MP_ONE: MonitoringPointElement = { id: 1, value: 0, text: 'MP1' };
const MP_TWO: MonitoringPointElement = { id: 2, value: 1, text: 'MP2' };

const CHANNEL_ONE = { id: 1, value: 1, text: 'CH1' };
const CHANNEL_TWO = { id: 2, value: 2, text: 'CH2' };

export const VELOCITY_AUTO = { id: 1, value: 0, text: 'Auto' };
export const VELOCITY_MANUAL = { id: 2, value: 1, text: 'Manual' };
export const CUSTOM_TYPE_VALUE = 'Pipe: 211';

export enum MonitorSeriesNames {
    triton = 'TRITON+',
    echo = 'ECHO',
    rainAlert = 'RAINALERT III',
    ForeSITE_UL = 'FORESITE-UL',
    ForeSITE_Flex = 'FORESITE-FLEX',
    usgs = 'USGS'
}

export enum TritonSensorNames {
    peakCombo = 'Peak Combo Sensor',
    avMax = 'AV|Max / Peak Combo Sensor',
    avGated = 'AV|Gated Sensor',
    smartDepth = 'Smart Depth Sensor',
    paraDepth = 'ParaDepth / Smart Depth Sensor',
    surfaceCombo = 'Surface Combo Sensor',
    paraFlow = 'ParaFlow / Surface Combo Sensor',
    longRange = 'Long Range Depth sensor',
}

export enum ForeSITESensors {
    radar = 'Radar'
}

export const MonitorSeries =  <Array<MonitorAddLocationSelectable>>[
    {
        monitorSeriesID: MonitorIdentifier.TritonPlus,
        value: 'TRITON+',
        text: 'TRITON+',
    },
    {
        monitorSeriesID: MonitorIdentifier.Echo,
        value: 'ECHO',
        text: 'ECHO',
    },
    {
        monitorSeriesID: MonitorIdentifier.RainAlertIII,
        value: 'RAINALERT III',
        text: 'RainAlert III',
    },
    {
        monitorSeriesID: MonitorIdentifier.ForeSITE_UL,
        value: 'FORESITE-UL',
        text: 'ForeSITE-UL',
    },
    {
        monitorSeriesID: MonitorIdentifier.ForeSITE_Flex,
        value: 'FORESITE-FLEX',
        text: 'ForeSITE-Flex',
    },
    {
        monitorSeriesID: MonitorIdentifier.USGS,
        value: 'USGS',
        text: 'USGS',
    }
]

export const FlowList = <Array<SampleRateElement>>[
    { id: 1, value: 0, text: 'Typical' },
    { id: 3, value: 2, text: 'Usually Dry' },
    { id: 2, value: 1, text: 'Wet/Stagnant' },
];

export const TritonSensorTemp = [
    { id: 1, value: 0, text: 'Compensated' },
    { id: 2, value: 1, text: 'Sensor' },
];

export const SensitivityList = <Array<SampleRateElement>>[
    { id: 1, value: 4, text: '4' },
    { id: 2, value: 5, text: '5' },
    { id: 3, value: 6, text: '6' },
    { id: 4, value: 7, text: '7' },
    { id: 5, value: 8, text: '8' },
    { id: 6, value: 9, text: '9' },
    { id: 7, value: 10, text: '10' },
    { id: 8, value: 11, text: '11' },
    { id: 9, value: 12, text: '12' },
    { id: 10, value: 13, text: '13' },
    { id: 11, value: 14, text: '14' },
    { id: 12, value: 15, text: '15' },

    { id: 13, value: 17, text: '17' },
    { id: 14, value: 20, text: '20' },
    { id: 15, value: 25, text: '25' },
    { id: 16, value: 50, text: '50' },
    { id: 17, value: 100, text: '100' },
    { id: 18, value: 200, text: '200' },
];

export const DeadBandList = (isMetric: boolean) => {
    return isMetric
        ? [
              { id: 2, value: 1, text: '25.4' },
              { id: 3, value: 2, text: '50.8' },
              { id: 4, value: 3, text: '76.2' },
              { id: 5, value: 4, text: '101.6' },
              { id: 6, value: 5, text: '127' },
              { id: 1, value: 0, text: 'Auto' },
          ]
        : [
              { id: 2, value: 1, text: '1' },
              { id: 3, value: 2, text: '2' },
              { id: 4, value: 3, text: '3' },
              { id: 5, value: 4, text: '4' },
              { id: 6, value: 5, text: '5' },
              { id: 1, value: 0, text: 'Auto' },
          ];
};

export const TritonSensorPower = (isTriton: boolean) => {
    const powerList = [
        { id: 1, value: 0, text: 'Auto' },
        { id: 2, value: 1, text: '1' },
        { id: 3, value: 2, text: '2' },
        { id: 4, value: 3, text: '3' },
        { id: 5, value: 4, text: '4' },
        { id: 6, value: 5, text: '5' },
        { id: 7, value: 6, text: '6' },
        { id: 8, value: 7, text: '7' },
        { id: 9, value: 8, text: '8' },
        { id: 10, value: 9, text: '9' },
        { id: 11, value: 10, text: '10' },
    ];

    return isTriton ? [...powerList, { id: 12, value: 11, text: '11' }] : powerList;
};

export const DIRECTION_LIST_DEFAULT_VALUE = 1;
export const MIN_MEASUREMENT_RANGE = 6000 ;

export const DirectionList = (twoDirectional: boolean) => {
    if (twoDirectional) {
        return [
            { id: 1, text: 'Forward', value: 1 },
            { id: 2, text: 'Backward', value: 2 },
        ];
    }

    return <Array<SampleRateElement>>[
        { id: 2, value: 1, text: 'Forward' },
        { id: 4, value: 3, text: 'Backward Bidirectional' },
        { id: 3, value: 2, text: 'Backward' },
        { id: 1, value: 0, text: 'Bidirectional' },
    ];
};

export const MinVelocityList = <Array<MaxVelocityElement>>[
    { id: 1, value: 0.02, text: '0.02' },
    { id: 2, value: 0.05, text: '0.05' },
    { id: 3, value: 0.1, text: '0.10' },
    { id: 4, value: 0.2, text: '0.20' },
];

export const VelocityList = <Array<SampleRateElement>>[VELOCITY_AUTO, VELOCITY_MANUAL];

export const PrimarySensors = <Array<SensorElement>>[
    { id: 1, value: 'AV|Max / Peak Combo 1', text: 'AV|Max / Peak Combo Sensor', altValue: 'Peak Combo 1' },
    { id: 2, value: 'AV|Gated 1', text: 'AV|Gated Sensor' },
    { id: 3, value: 'ParaDepth / Smart Depth 1', text: 'ParaDepth / Smart Depth Sensor', altValue: 'Smart Depth 1' },
    { id: 4, value: 'ParaFlow / Surface Combo 1', text: 'ParaFlow / Surface Combo Sensor', altValue: 'Surface Combo 1' },
    { id: 5, value: 'Long Range Depth 1', text: 'Long Range Depth sensor' },
];

export const ForeSITEFlexSensors = <Array<SensorElement>>[
    { id: 1, value: 'Radar', text: 'Radar', altValue: 'Radar' },
];

export const SecondarySensors = <Array<SensorElement>>[
    { id: 1, value: 'AV|Max / Peak Combo 2', text: 'AV|Max / Peak Combo Sensor', altValue: 'Peak Combo 2' },
    { id: 2, value: 'AV|Gated 2', text: 'AV|Gated Sensor' },
    { id: 3, value: 'ParaDepth / Smart Depth 2', text: 'ParaDepth / Smart Depth Sensor', altValue: 'Smart Depth 2' },
    { id: 4, value: 'ParaFlow / Surface Combo 2', text: 'ParaFlow / Surface Combo Sensor', altValue: 'Surface Combo 2' },
    { id: 5, value: 'Long Range Depth 2', text: 'Long Range Depth sensor' },
];

export const DepthPreferences: Map<string, SensorPreference> = new Map([
    [DepthDeviceName.Auto, { id: 1, value: DepthDeviceType.Auto, text: 'Auto' }],
    [DepthDeviceName.UpwardCh1, { id: 2, value: DepthDeviceType.UpwardCh1, text: 'Upward Ultrasonic' }],
    [DepthDeviceName.UpwardCh2, { id: 3, value: DepthDeviceType.UpwardCh2, text: 'Upward Ultrasonic' }],
    [DepthDeviceName.PressureCh1, { id: 4, value: DepthDeviceType.PressureCh1, text: 'Pressure' }],
    [DepthDeviceName.PressureCh2, { id: 5, value: DepthDeviceType.PressureCh2, text: 'Pressure' }],
    [DepthDeviceName.UltrasonicCh1, { id: 6, value: DepthDeviceType.UltrasonicCh1, text: 'Surface Depth' }],
    [DepthDeviceName.UltrasonicCh2, { id: 7, value: DepthDeviceType.UltrasonicCh2, text: 'Surface Depth' }],
    [DepthDeviceName.LongRangeCh1, { id: 8, value: DepthDeviceType.LongRangeCh1, text: 'Long Range' }],
    [DepthDeviceName.LongRangeCh2, { id: 9, value: DepthDeviceType.LongRangeCh2, text: 'Long Range' }],
]);

export const VelocityPreferences: Map<string, SensorPreference> = new Map([
    [VelocityDeviceName.Auto, { id: 1, value: VelocityDeviceType.Auto, text: 'Auto' }],
    [VelocityDeviceName.AvGatedCh1, { id: 2, value: VelocityDeviceType.AvGatedCh1, text: 'AV|GATED Velocity' }],
    [VelocityDeviceName.AvGatedCh2, { id: 3, value: VelocityDeviceType.AvGatedCh2, text: 'AV|GATED Velocity' }],
    [VelocityDeviceName.PeakCh1, { id: 4, value: VelocityDeviceType.PeakCh1, text: 'Peak Velocity' }],
    [VelocityDeviceName.PeakCh2, { id: 5, value: VelocityDeviceType.PeakCh2, text: 'Peak Velocity' }],
    [VelocityDeviceName.SurfaceCh1, { id: 6, value: VelocityDeviceType.SurfaceCh1, text: 'Surface Velocity' }],
    [VelocityDeviceName.SurfaceCh2, { id: 7, value: VelocityDeviceType.SurfaceCh2, text: 'Surface Velocity' }],
]);

export const ModeList = <Array<MonitoringPointElement>>[
    { id: 1, value: 0, text: 'Normal' },
    { id: 2, value: 1, text: 'High Power' },
];

export const BlankingList = <Array<MonitoringPointElement>>[
    { id: 1, value: 0, text: 'Auto' },
    { id: 2, value: 2, text: '2' },
    { id: 3, value: 3, text: '3' },
    { id: 4, value: 4, text: '4' },
    { id: 5, value: 5, text: '5' },
    { id: 6, value: 6, text: '6' },
    { id: 7, value: 7, text: '7' },
    { id: 8, value: 8, text: '8' },
    { id: 9, value: 9, text: '9' },
    { id: 10, value: 10, text: '10' },
    { id: 11, value: 11, text: '11' },
    { id: 12, value: 12, text: '12' },
];

export const MetricBlankingList = <Array<MonitoringPointElement>>[
    { id: 1, value: 0, text: 'Auto' },
    { id: 2, value: 25.4, text: '25.4' },
    { id: 3, value: 50.8, text: '50.8' },
    { id: 4, value: 76.2, text: '76.2' },
    { id: 5, value: 101.6, text: '101.6' },
    { id: 6, value: 127, text: '127' },
    { id: 7, value: 152.4, text: '152.4' },
    { id: 8, value: 177.8, text: '177.8' },
    { id: 9, value: 203.2, text: '203.2' },
    { id: 10, value: 228.6, text: '228.6' },
    { id: 11, value: 254, text: '254' },
    { id: 12, value: 279.4, text: '279.4' },
    { id: 13, value: 304.8, text: '304.8' },
];

export const SVTransmitFrequencyList = [
    { id: 1, text: 'Low', value: 0 },
    { id: 2, text: 'Medium', value: 1 },
    { id: 3, text: 'High', value: 2 },
];

export const PVTransmitFrequencyList = [
    { id: 1, text: 'Normal', value: 0 },
    { id: 1, text: 'High', value: 1 },
];

export const VelocityAcquisition = <Array<MonitoringPointElement>>[
    { id: 1, value: 0, text: 'Auto' },
    { id: 4, value: 3, text: 'High' },
    { id: 2, value: 1, text: 'Low' },
    { id: 3, value: 2, text: 'Medium' },
];

export const MonitoringPoints = <Array<MonitoringPointElement>>[MP_ONE, MP_TWO];

export const ChannelList = <Array<ChannelElement>>[CHANNEL_ONE, CHANNEL_TWO];

export const SensorsList = [
    { id: 1, value: 0, text: 'Monitor' },
    { id: 2, value: 1, text: 'Channel 1' },
    { id: 3, value: 2, text: 'Channel 2' }
]

export const CommunicationTypes = <Array<MonitorAddLocationSelectable>>[
    { monitorSeriesID: 4, value: '4', text: 'TCP/IP' },
    { monitorSeriesID: 1, value: '1', text: 'Serial' },
    { monitorSeriesID: 6, value: '6', text: 'Bluetooth' },
];

export const SVDurationList = [
    { id: 1, text: '1 second', value: 1 },
    { id: 2, text: '2 seconds', value: 2 },
    { id: 3, text: '3 seconds', value: 3 },
    { id: 4, text: '4 seconds', value: 4 },
    { id: 5, text: '5 seconds', value: 5 },
];

export const SampleRateList = <Array<SampleRateElement>>[
    { id: 1, value: 60, text: '1 minute' },
    { id: 2, value: 120, text: '2 minutes' },
    { id: 3, value: 300, text: '5 minutes' },
    { id: 4, value: 900, text: '15 minutes' },
    { id: 5, value: 1800, text: '30 minutes' },
    { id: 6, value: 3600, text: '1 hour' },
    { id: 7, value: 7200, text: '2 hours' },
    { id: 8, value: 43200, text: '12 hours' },
    { id: 9, value: 86400, text: '24 hours' },
];

export const ForeSiteSampleRateList = <Array<SampleRateElement>>[
    { id: 3, value: 300, text: '5 minutes' },
    { id: 4, value: 900, text: '15 minutes' },
    { id: 5, value: 1800, text: '30 minutes' },
    { id: 6, value: 3600, text: '1 hour' },
    { id: 7, value: 7200, text: '2 hours' },
    { id: 8, value: 14400, text: '4 hours' },
];

export const LocationStatuses = [
    { key: LocationStatus.Active, name: 'Active' },
    { key: LocationStatus.Maintenance, name: 'Maintenance' },
    { key: LocationStatus.Inactive, name: 'Inactive' },
    // TODO: Temporary not visible at UI by #18809
    // {key: LocationStatus.InactiveRemoved, name: this.PAGE_TEXT.LOCATION_STATUSES.INACTIVE_REMOVED},
];

export const FlowLossAlarmTypes = [
    { id: FlowLossType.Flow, name: FLOW },
    { id: FlowLossType.Depth, name: DEPTH },
];

export const FlowLossSensitivityList = [
    { id: FlowLossSensitivity.Low, name: LOW, isChecked: true },
    { id: FlowLossSensitivity.Medium, name: MEDIUM },
    { id: FlowLossSensitivity.High, name: HIGH },
];

export const MaxVelocityMetricValues = (isParaFlowSurface = false) => {
    if (isParaFlowSurface) {
        return [
            { value: 0, text: 'Auto' },
            { value: 0.6096, text: '0.6096' },
            { value: 0.9144, text: '0.9144' },
            { value: 1.2192, text: '1.2192' },
            { value: 1.524, text: '1.524' },
            { value: 1.8288, text: '1.8288' },
            { value: 2.286, text: '2.286' },
            { value: 3.048, text: '3.048' },
            { value: 3.6576, text: '3.6576' },
            { value: 4.572, text: '4.572' },
            { value: 9.144, text: '9.144' },
        ];
    }

    return [
        { value: 0, text: 'Auto' },
        { value: 0.6096, text: '0.6096' },
        { value: 0.9144, text: '0.9144' },
        { value: 1.2192, text: '1.2192' },
        { value: 1.524, text: '1.524' },
        { value: 1.8288, text: '1.8288' },
        { value: 2.286, text: '2.286' },
        { value: 3.048, text: '3.048' },
        { value: 3.81, text: '3.81' },
        { value: 4.572, text: '4.572' },
        { value: 6.096, text: '6.096' },
        { value: 7.62, text: '7.62' },
        { value: 9.144, text: '9.144' },
    ];
}

export const MaxVelocityNonMetricValues = (isParaFlowSurface = false) => {
    if (isParaFlowSurface) {
        return [
            { value: 0, text: 'Auto' },
            { value: 2, text: '2' },
            { value: 3, text: '3' },
            { value: 4, text: '4' },
            { value: 5, text: '5' },
            { value: 6, text: '6' },
            { value: 7.5, text: '7.5' },
            { value: 10, text: '10' },
            { value: 12, text: '12' },
            { value: 15, text: '15' },
            { value: 30, text: '30' },
        ];
    }

    return [
        { value: 0, text: 'Auto' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 6, text: '6' },
        { value: 7.5, text: '7.5' },
        { value: 10, text: '10' },
        { value: 12.5, text: '12.5' },
        { value: 15, text: '15' },
        { value: 20, text: '20' },
        { value: 25, text: '25' },
        { value: 30, text: '30' },
    ];
}

export const BatteryVoltage = [
    { value: 0, text: 'Store at Sample Rate' },
    { value: 1, text: 'Store at Midnight' },
]
