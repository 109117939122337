import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Subscription } from 'rxjs';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { TranslateService } from '@ngx-translate/core';
import { GetCustomerFeatureResponse } from 'app/shared/models/customer';
import { CustomerService } from 'app/shared/services/customer.service';

@Component({
    selector: 'app-customer-feature',
    templateUrl: './customer-feature.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerFeatureComponent implements OnInit, OnDestroy {
    private subscriptions = new Array<Subscription>();
    public isFeatureSelected = false;
    public featureList: GetCustomerFeatureResponse[] = [];
    public customerFeatureList: GetCustomerFeatureResponse[] = [];
    public customerFeatureLoadingState: boolean;

    public snackbarSuccessMsg: string;
    public snackbarErrMsg: string;
    public dismissBtnText: string;

    constructor(
        private customerService: CustomerService,
        public customerAssociationDialog: MatDialogRef<CustomerFeatureComponent>,
        @Inject(MAT_DIALOG_DATA) public customerData: any,
        private statusCodeService: StatusCodeService,

        private snackBar: MatSnackBar,
        private translate: TranslateService,
    ) {
        translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismissBtnText = res;
        });
        translate.get('ADMIN.CUSTOMER_FEATURES.FEATURE_ADDED_SUCCESS_MESSAGE').subscribe((res: string) => {
            this.snackbarSuccessMsg = res;
        });
        translate.get('ADMIN.CUSTOMER_FEATURES.FEATURE_EDIT_ERROR_MESSAGE').subscribe((res: string) => {
            this.snackbarErrMsg = res;
        });
    }

    /**
     * Get data from admin customer component for selected customer
     */
    public ngOnInit() {
        if (this.customerData) {
            this.customerData.featureList.forEach((item) => {
                if (item.canassigncustomer) {
                    this.featureList.push(item);
                }
            });
            // set pre assigned feature to checked in UI
            this.featureList.forEach((list) => {
                if (this.customerData.customer.features.findIndex((x) => x.id === list.id) === -1) {
                    list.isChecked = false;
                } else {
                    list.isChecked = true;
                }
            });
            // create duplicate copy for feature list
            this.customerFeatureList = JSON.parse(JSON.stringify(this.featureList));
        }
    }

    /**
     * Close dialoge box method
     * @param permissionsUpdated
     */
    public close(permissionsUpdated?: boolean) {
        this.customerAssociationDialog.close({ success: permissionsUpdated });
    }
    /**
     * handle the feature list change event from UI
     * @param event
     * @param feature
     */
    public featureChangeHandler(event: any, selectedFeature: GetCustomerFeatureResponse) {
        this.isFeatureSelected = true;
        // Marked as ischecked to true which will be assigned to customer.
        for (const list of this.customerFeatureList) {
            if (list.name === selectedFeature.name) {
                list.isChecked = event.checked;
            }
        }
    }

    /**
     * Update the customer feature association.
     */
    public saveCustomerAssignedFeatures() {
        this.customerFeatureLoadingState = true;
        // select only those features from the customerFeatureList(old) which ischecked property marked to true
        const updatedFeatureModel = [];
        for (const feature of this.customerFeatureList) {
            if (feature.isChecked) {
                updatedFeatureModel.push({ featureID: feature.id, customerID: this.customerData.customer.id });
            }
        }

        // when no feature is assigned, this logic is to remove customer feature from the DB.
        if (updatedFeatureModel.length === 0) {
            updatedFeatureModel.push({ customerID: this.customerData.customer.id });
        }
        // call permission service to update customer feature
        this.customerService.updateCustomerFeature(updatedFeatureModel).subscribe(
            (result) => {
                // snak bar message
                const simpleSnackBarRef = this.snackBar.open(this.snackbarSuccessMsg, this.dismissBtnText);
                setTimeout(simpleSnackBarRef.dismiss.bind(simpleSnackBarRef), 10000);
                // Set value for customer page
                this.statusCodeService.customerIdInEditScreen.next(this.customerData.customer.id);

                // hide the loader
                this.customerFeatureLoadingState = false;
                // close the dialog
                this.close(true);
            },
            (error) => {
                // snak bar message when error occured
                /* let simpleSnackBarRef = this.snackBar.open('Error occured in saving customer features', 'Dismiss');
      setTimeout(simpleSnackBarRef.dismiss.bind(simpleSnackBarRef), 3000); */
                this.snackBar.open(this.snackbarErrMsg, this.dismissBtnText, {
                    panelClass: 'custom-error-snack-bar',
                });
            },
        );
    }

    /**
     * Destroy the subscription data
     */
    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
