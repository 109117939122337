import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocationDashboardFilterData } from 'app/shared/models/location-dashboard-filter-data';
import { CollectionHistory } from '../models/collection-history';

@Injectable()
export class FilterWidgetService {
    public collectionFilterData = new BehaviorSubject<CollectionHistory>(new CollectionHistory());
    public locationDashboardFilterData = new BehaviorSubject<LocationDashboardFilterData>(
        new LocationDashboardFilterData(),
    );

    public setCollectionFilterData(collectHistory: CollectionHistory) {
        this.collectionFilterData.next(collectHistory);
    }

    public setLocationDashboardFilterData(locationDashboardFilterData: LocationDashboardFilterData) {
        this.locationDashboardFilterData.next(locationDashboardFilterData);
    }

    public getLocationDashboardFilterData() {
        return this.locationDashboardFilterData;
    }
}
