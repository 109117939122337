import { Injectable, Optional, Inject } from '@angular/core';
import {
    PeakComboSensor,
    SufraceComboSensor,
    SmartDepthSensor,
    AVGAtedSensor,
    ControlType,
    LongRangeDepthSensor,
    LongRangeDepthTritonSensor,
} from 'app/shared/models/TritonLocation';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AddLocationWeir } from 'app/shared/models/add-location-weir';
import { AddTritonLocationLongRangeDepthTritonSensorComponent } from './longRangeDepthTritonSensor/add-triton-location-long-range-depth-triton-sensor.component';
import { AddLocationPipes } from 'app/shared/models/add-location-pipes';
import { MaxVelocityElement, MonitoringPointElement, SampleRateElement, TritonSensorGainControlList, TritonSensorPowerList, TritonTemperatureSensorList } from '../../location-card/models/location-card.model';

const ECHO_GAIN_VALUE = 840;
const AUTO = 'Auto';
const MANUAL = 'MANUAL';
export const PEAK_COMBO_SENSOR_VELOCITY_AUTO = { id: 1, value: 0, text: 'Auto' };
export const PEAK_COMBO_SENSOR_VELOCITY_MANUAL = { id: 2, value: 1, text: 'Manual' };
export const MAX_VELOCITY_METRIC_PATTERN = '^((0.[5-9]*)|(([1-8])(.[0-9]*)?)|9(.0)?)$';
export const MAX_VELOCITY_METRIC_DEFAULT_VALUE = 0.5;
export const MAX_VELOCITY_NON_METRIC_PATTERN = '^([2-9]|[1-2][0-9]|30)$';
export const MAX_VELOCITY_NON_METRIC_DEFAULT_VALUE = 2;

interface TritonSensor {
    tritonName: string;
    tritonMonitoringPoint: 1 | 0;
    tritonChannel: number;
    tritonGain: number | string;
    tritonSilt: number;
}

@Injectable()
export class AddTritonLocationService {
    public numericGeneralPattern = REGEX_CONFIG.numericOnlyPattern;
    public numericGeneralPatternWithDecimals = REGEX_CONFIG.numericGreaterThanZeroPattern;
    // Reg Ex. Pattern for numeric Decimal value 2Place
    public numericDecimal2PlacePattern = REGEX_CONFIG.numericDecimal2PlacePattern;
    public signedNumericDecimal2PlacePattern = REGEX_CONFIG.negativeWithTwoDecimalPattern;
    public metricVelocityErrorFromJson: string;
    public usVelocityErrorFromJson: string;

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any;

    public isLoading: boolean;

    public isMetricUnit: boolean;

    public showPeakCombo1: boolean;
    public showPeakCombo2: boolean;

    public showSmartDepth1: boolean;
    public showSmartDepth2: boolean;

    public showAVGated1: boolean;
    public showAVGated2: boolean;

    public showSurfaceCombo1: boolean;
    public showSurfaceCombo2: boolean;

    public showLongRangeDepth1: boolean;

    public showLongRangeTriton1: boolean;
    public showLongRangeTriton2: boolean;

    public showTriton: boolean;

    public showEcho: boolean;

    public isMonitorCanAddEdit: boolean;

    public selectedInstalationType: string;

    public physicalOffSetPlaceholder: string;
    public electronicOffSetPlaceholder: string;
    public maxVelocityInputPattern: string;
    public maxVelocityDefaultValue: number;

    /* peakCombo*/

    public peakComboFlowList: Array<SampleRateElement>;
    public peakComboBandList: Array<SampleRateElement>;
    public peakComboSensitivityList: Array<SampleRateElement>;
    public peakComboSensorDirectionList: Array<SampleRateElement>;
    public peakComboSensorVelocityList: Array<SampleRateElement>;
    public deadBandList: Array<SampleRateElement>;

    /*surfaceCombo*/
    public surfaceComboSensitivityList: Array<SampleRateElement>;
    public surfaceComboVelocityList: Array<SampleRateElement>;
    public surfaceComboChannelList: Array<SampleRateElement>;
    public surfaceComboFlowList: Array<SampleRateElement>;
    public surfaceComboBlankingList: Array<SampleRateElement>;
    public surfaceComboSVTransmitFrequencyList: Array<SampleRateElement>;
    public surfaceComboPVTransmitFrequencyList: Array<SampleRateElement>;
    public surfaceComboSVDirectionList: Array<SampleRateElement>;
    public surfaceComboSVDurationList: Array<SampleRateElement>;
    public surfaceComboPVDirectionList: Array<SampleRateElement>;

    /*SMART DEPTH*/
    public smartDepthModeList: Array<SampleRateElement>;
    public smartDepthBlankingList: Array<SampleRateElement>;

    /*AvGated*/
    public avGatedFlowList: Array<SampleRateElement>;
    public avGatedVelocityList: Array<SampleRateElement>;
    public avGatedDirectionList: Array<SampleRateElement>;
    public velocityAcquisition: Array<SampleRateElement>;
    public minVelocityList: Array<SampleRateElement>;
    public avGatedBandList: Array<SampleRateElement>;

    public minVelocityRange: number;
    public maxVelocityRange: number;
    public avGatedPattern: string;
    public avGatedPatternVeocityUS: string;
    public avGatedPatternVeocityMetric: string;
    public avGatedMaxVelocityPatternError: string;
    public velocityMaxError: string;

    /*LONG RANGE*/
    public longRangeDepthSensorTemp: Array<ControlType>;
    public longRangeDepthSensorPower: Array<ControlType>;
    public longRangeDepthSensorGainControl: Array<ControlType>;
    public gainControlLongRangeDepthTritonSensor: string;
    public longRangeDepthTritonSensorTemp: Array<TritonTemperatureSensorList>;
    public longRangeDepthTritonSensorPower: Array<TritonSensorPowerList>;
    public longRangeDepthTritonSensorGainControl: Array<TritonSensorGainControlList>;
    public isGainControlManual: boolean;

    public tritonManholeDepth?: number;
    public manholeDepthPlaceHolder: string;
    public manholeDepthPlaceholderFromJson: string;
    public depthUnit: string;
    public tempUnit: string;
    public isManholeDepthInvalid: boolean;

    public pipe = new AddLocationPipes();
    public weir = new AddLocationWeir();
    public weir2 = new AddLocationWeir();

    public qFinalEntityID: number;
    public qFinalMp2EntityID: number;
    private avGatedSensor: any;
    private peakComboSensor: PeakComboSensor;
    private smartDepthSensor: any;
    private surfaceComboSensor: any;
    private longRangeDepth: AddTritonLocationLongRangeDepthTritonSensorComponent;
    private longRangeDepthTriton: AddTritonLocationLongRangeDepthTritonSensorComponent;

    constructor(private dateutilService: DateutilService) {}

    public setLongRangeDepth(
        sensor: LongRangeDepthTritonSensor,
        longRangeDepthTriton: AddTritonLocationLongRangeDepthTritonSensorComponent,
    ) {
        // longRangeDepthTriton.longRangeDepthTritonSensor.physicalOffset = sensor.physicalOffset;
        // longRangeDepthTriton.longRangeDepthTritonSensor.storeData = sensor.storeData;
        // longRangeDepthTriton.longRangeDepthTritonSensor.blanking = sensor.blanking;
        // longRangeDepthTriton.longRangeDepthTritonSensor.temperatureSensor = sensor.temperatureSensor;
        // longRangeDepthTriton.longRangeDepthTritonSensor.longRangeDepthTritonSensorPowerModel =
        //     (sensor.power === 0 || !sensor.power)
        //         ? AUTO : sensor.power.toString();
        // longRangeDepthTriton.longRangeDepthTritonSensor.electronicOffset = sensor.electronicOffset;
        // this.gainControlLongRangeDepthTritonSensor = sensor.gainControl === ECHO_GAIN_VALUE ?
        //     AUTO :
        //     MANUAL;
        // longRangeDepthTriton.isGainControlManualTriton = sensor.gainControl !== ECHO_GAIN_VALUE;
        // longRangeDepthTriton.longRangeDepthTritonSensor.gainControl = sensor.gainControl;
        // longRangeDepthTriton.longRangeDepthTritonSensor.compensatedTemperatureMarch =
        //     sensor.compensatedTemperatureMarch;
        // longRangeDepthTriton.longRangeDepthTritonSensor.compensatedTemperatureSeptember =
        //     sensor.compensatedTemperatureSeptember;
        // longRangeDepthTriton.longRangeDepthTritonSensor.disableCapacitance = sensor.disableCapacitance;
        // longRangeDepthTriton.longRangeDepthTritonSensor.logCapacitance = sensor.logCapacitance;
        // longRangeDepthTriton.longRangeDepthTritonSensor.surchargeThreshold = sensor.surchargeThreshold;
        // this.longRangeDepthTriton = longRangeDepthTriton;
    }

    public setPeakComboSensorDetails(deviceDate, peakComboSensor: PeakComboSensor) {
        const peakComboSensorData: PeakComboSensor = deviceDate;
        peakComboSensor.physicalOffset = deviceDate.physicalOffset;
        peakComboSensor.ultrasonicEnable = peakComboSensorData.ultrasonicEnable;
        peakComboSensor.ultrasonicElectronicOffset = deviceDate.ultrasonicElectronicOffset;
        peakComboSensor.ultrasonicDeadband = peakComboSensorData.ultrasonicDeadband;
        peakComboSensor.velocityFlowType = peakComboSensor.ultrasonicFlowType = peakComboSensorData.ultrasonicFlowType;
        peakComboSensor.ultrasonicStoreData = peakComboSensorData.ultrasonicStoreData;

        peakComboSensor.pressureEnable = peakComboSensorData.pressureEnable;
        peakComboSensor.pressureElectronicOffset = peakComboSensorData.pressureElectronicOffset;
        peakComboSensor.pressureCalibration = peakComboSensorData.pressureCalibration;
        peakComboSensor.pressureStoreData = peakComboSensorData.pressureStoreData;
        peakComboSensor.velocityEnable = peakComboSensorData.velocityEnable;
        peakComboSensor.velocitySensitivity = peakComboSensorData.velocitySensitivity;
        peakComboSensor.velocityDirection = peakComboSensorData.velocityDirection;
        peakComboSensor.velocityGain = peakComboSensorData.velocityGain;
        peakComboSensor.velocityMaximum = peakComboSensorData.velocityMaximum;
        peakComboSensor.peakComboMaxVelocityInput =
            peakComboSensor.velocityMaximum === 0
                ? PEAK_COMBO_SENSOR_VELOCITY_AUTO.value
                : PEAK_COMBO_SENSOR_VELOCITY_MANUAL.value;

        peakComboSensor.velocityScrubbing = peakComboSensorData.velocityScrubbing;
        peakComboSensor.pressureStoreData = peakComboSensorData.pressureStoreData;
        peakComboSensor.velocityStoreData = peakComboSensorData.velocityStoreData;
        // peakComboSensor.temperatureEnable = peakComboSensorData.temperatureEnable;
        peakComboSensor.temperatureStoreData = peakComboSensorData.temperatureStoreData;

        peakComboSensor.type = peakComboSensorData.type;
        peakComboSensor.name = peakComboSensorData.name;
        peakComboSensor.physicalOffset = peakComboSensorData.physicalOffset;
        peakComboSensor.temperatureSensor = peakComboSensorData.temperatureSensor;
        peakComboSensor.channel = peakComboSensorData.channel;
        peakComboSensor.installationNumber = peakComboSensorData.installationNumber;
        peakComboSensor.active = peakComboSensorData.active;
        this.peakComboSensor = peakComboSensor;
    }

    public setAvGatedSensorDetails(aVGatedSensorData, avGatedSensor) {
        avGatedSensor.ultrasonicElectronicOffset = aVGatedSensorData.ultrasonicElectronicOffset;
        avGatedSensor.ultrasonicDeadband = aVGatedSensorData.ultrasonicDeadband;
        avGatedSensor.ultrasonicFlowType = aVGatedSensorData.ultrasonicFlowType;
        avGatedSensor.ultrasonicDacGain = aVGatedSensorData.ultrasonicDacGain;
        avGatedSensor.ultrasonicPingAmplitude = aVGatedSensorData.ultrasonicPingAmplitude;
        avGatedSensor.ultrasonicStoreData = aVGatedSensorData.ultrasonicStoreData;
        avGatedSensor.ultrasonicEnable = aVGatedSensorData.ultrasonicEnable;

        avGatedSensor.velocityFlowType = aVGatedSensorData.velocityFlowType;
        avGatedSensor.velocitySensitivity = aVGatedSensorData.velocitySensitivity;
        avGatedSensor.velocityDirection = aVGatedSensorData.velocityDirection;
        avGatedSensor.velocityMaximum = aVGatedSensorData.velocityMaximum;
        avGatedSensor.velocityMinimum = aVGatedSensorData.velocityMinimum;
        avGatedSensor.velocityAcquisition = aVGatedSensorData.velocityAcquisition;
        avGatedSensor.velocityDacGain = aVGatedSensorData.velocityDacGain;
        avGatedSensor.velocityPingAmplitude = aVGatedSensorData.velocityPingAmplitude;
        avGatedSensor.velocityFrequency = aVGatedSensorData.velocityFrequency;
        avGatedSensor.velocityStoreData = aVGatedSensorData.velocityStoreData;
        avGatedSensor.velocityEnable = aVGatedSensorData.velocityEnable;
        avGatedSensor.velocityGain = aVGatedSensorData.velocityGain;
        avGatedSensor.velocityScrubbing = aVGatedSensorData.velocityScrubbing;
        avGatedSensor.velocityMaximumAVGatedValue =
            avGatedSensor.velocityMaximum === 0 ? this.avGatedVelocityList[0].value : this.avGatedVelocityList[1].value;

        avGatedSensor.pressureElectronicOffset = aVGatedSensorData.pressureElectronicOffset;
        avGatedSensor.pressureStoreData = aVGatedSensorData.pressureStoreData;
        avGatedSensor.pressureEnable = aVGatedSensorData.pressureEnable;
        avGatedSensor.pressureCalibration = aVGatedSensorData.pressureCalibration;
        avGatedSensor.temperatureStoreData = aVGatedSensorData.temperatureStoreData;
        avGatedSensor.temperatureEnable = aVGatedSensorData.temperatureEnable;

        avGatedSensor.type = aVGatedSensorData.type;
        avGatedSensor.name = aVGatedSensorData.name;
        avGatedSensor.physicalOffset = aVGatedSensorData.physicalOffset;
        avGatedSensor.temperatureSensor = aVGatedSensorData.temperatureSensor;
        avGatedSensor.channel = aVGatedSensorData.channel;
        avGatedSensor.installationNumber = aVGatedSensorData.installationNumber;
        avGatedSensor.active = aVGatedSensorData.active;
        this.avGatedSensor = avGatedSensor;
    }

    public setSmartDepthSensorDetails(smartDepthSensorData, smartDepthSensor) {
        smartDepthSensor.ultrasonicElectronicOffset = smartDepthSensorData.ultrasonicElectronicOffset;
        smartDepthSensor.ultrasonicDisableSurchargeDetection = smartDepthSensorData.ultrasonicDisableSurchargeDetection;
        smartDepthSensor.ultrasonicMode = smartDepthSensorData.ultrasonicMode;
        smartDepthSensor.ultrasonicBlanking = smartDepthSensorData.ultrasonicBlanking;
        smartDepthSensor.ultrasonicDacGain = smartDepthSensorData.ultrasonicDacGain;
        smartDepthSensor.ultrasonicStoreData = smartDepthSensorData.ultrasonicStoreData;
        smartDepthSensor.ultrasonicEnable = smartDepthSensorData.ultrasonicEnable;
        smartDepthSensor.temperatureStoreData = smartDepthSensorData.temperatureStoreData;
        smartDepthSensor.temperatureEnable = smartDepthSensorData.temperatureEnable;
        smartDepthSensor.type = smartDepthSensorData.type;
        smartDepthSensor.name = smartDepthSensorData.name;
        smartDepthSensor.physicalOffset = smartDepthSensorData.physicalOffset;
        smartDepthSensor.temperatureSensor = smartDepthSensorData.temperatureSensor;
        smartDepthSensor.channel = smartDepthSensorData.channel;
        smartDepthSensor.installationNumber = smartDepthSensorData.installationNumber;
        smartDepthSensor.active = smartDepthSensorData.active;
        this.smartDepthSensor = smartDepthSensor;
    }

    public setSurfaceComboSensorDetails(surfaceComboData, surfaceComboSensor) {
        surfaceComboSensor.ultrasonicElectronicOffset = surfaceComboData.ultrasonicElectronicOffset;
        surfaceComboSensor.ultrasonicDisableSurchargeDetection = surfaceComboData.ultrasonicDisableSurchargeDetection;
        surfaceComboSensor.ultrasonicBlanking = surfaceComboData.ultrasonicBlanking;
        surfaceComboSensor.ultrasonicDacGain = surfaceComboData.ultrasonicDacGain;
        surfaceComboSensor.ultrasonicStoreData = surfaceComboData.ultrasonicStoreData;
        surfaceComboSensor.ultrasonicMode = surfaceComboData.ultrasonicMode;

        surfaceComboSensor.velocitySensitivity = surfaceComboData.velocitySensitivity;
        surfaceComboSensor.velocityDirection = surfaceComboData.velocityDirection;
        surfaceComboSensor.velocityMaximum = surfaceComboData.velocityMaximum;
        surfaceComboSensor.velocityDacGain = surfaceComboData.velocityDacGain;
        surfaceComboSensor.velocityPingAmplitude = surfaceComboData.velocityPingAmplitude;
        surfaceComboSensor.velocityFrequency = surfaceComboData.velocityFrequency;
        surfaceComboSensor.velocityStoreData = surfaceComboData.velocityStoreData;
        surfaceComboSensor.velocityEnable = surfaceComboData.velocityEnable;
        surfaceComboSensor.velocityGain = surfaceComboData.velocityGain;
        surfaceComboSensor.velocityScrubbing = surfaceComboData.velocityScrubbing;

        surfaceComboSensor.surfaceVelocityFrequency = surfaceComboData.surfaceVelocityFrequency;
        surfaceComboSensor.surfaceVelocitySensitivity = surfaceComboData.surfaceVelocitySensitivity;
        surfaceComboSensor.surfaceVelocityDirection = surfaceComboData.surfaceVelocityDirection;
        surfaceComboSensor.surfaceVelocityMaximum = surfaceComboData.surfaceVelocityMaximum;
        surfaceComboSensor.surfaceVelocityDuration = surfaceComboData.surfaceVelocityDuration;
        surfaceComboSensor.surfaceVelocityGain = surfaceComboData.surfaceVelocityGain;
        surfaceComboSensor.surfaceVelocityDacGain = surfaceComboData.surfaceVelocityDacGain;
        surfaceComboSensor.surfaceVelocityPingAmplitude = surfaceComboData.surfaceVelocityPingAmplitude;
        surfaceComboSensor.surfaceVelocityStoreData = surfaceComboData.surfaceVelocityStoreData;
        surfaceComboSensor.surfaceVelocityEnable = surfaceComboData.surfaceVelocityEnable;
        surfaceComboSensor.surfaceVelocityScrubbing = surfaceComboData.surfaceVelocityScrubbing;

        surfaceComboSensor.surfaceComboSVmaxVelocity =
            surfaceComboSensor.surfaceVelocityMaximum === 0
                ? this.surfaceComboVelocityList[0].value
                : this.surfaceComboVelocityList[1].value;
        surfaceComboSensor.surfaceComboPVmaxVelocity =
            surfaceComboSensor.velocityMaximum === 0
                ? this.surfaceComboVelocityList[0].value
                : this.surfaceComboVelocityList[1].value;

        surfaceComboSensor.pressureElectronicOffset = surfaceComboData.pressureElectronicOffset;
        surfaceComboSensor.pressureStoreData = surfaceComboData.pressureStoreData;
        surfaceComboSensor.pressureEnable = surfaceComboData.pressureEnable;
        surfaceComboSensor.pressureCalibration = surfaceComboData.pressureCalibration;
        surfaceComboSensor.temperatureStoreData = surfaceComboData.temperatureStoreData;

        surfaceComboSensor.type = surfaceComboData.type;
        surfaceComboSensor.name = surfaceComboData.name;
        surfaceComboSensor.physicalOffset = surfaceComboData.physicalOffset;
        surfaceComboSensor.temperatureSensor = surfaceComboData.temperatureSensor;
        surfaceComboSensor.channel = surfaceComboData.channel;
        surfaceComboSensor.installationNumber = surfaceComboData.installationNumber;
        surfaceComboSensor.active = surfaceComboData.active;

        this.surfaceComboSensor = surfaceComboSensor;
    }

    public initPeakComboSensor(): PeakComboSensor {
        const peakComboSensor: PeakComboSensor = new PeakComboSensor();
        this.peakComboFlowList = <Array<SampleRateElement>>[
            { id: 1, value: 0, text: 'Typical' },
            { id: 3, value: 2, text: 'Usually Dry' },
            { id: 2, value: 1, text: 'Wet/Stagnant' },
        ];
        peakComboSensor.physicalOffset = Number(0).toFixed(2);
        peakComboSensor.ultrasonicEnable = true;
        peakComboSensor.ultrasonicElectronicOffset = Number(0).toFixed(2);
        this.peakComboBandList = <Array<SampleRateElement>>[
            { id: 1, value: 0, text: 'Typical' },
            { id: 3, value: 2, text: 'Usually Dry' },
            { id: 2, value: 1, text: 'Wet/Stagnant' },
        ];
        peakComboSensor.ultrasonicDeadband = 0;
        peakComboSensor.ultrasonicStoreData = true;
        peakComboSensor.pressureElectronicOffset = Number(0).toFixed(2);
        peakComboSensor.pressureCalibration = true;
        peakComboSensor.velocitySensitivity = 10;
        peakComboSensor.velocityDirection = 0;
        peakComboSensor.velocityGain = Number(0.9).toFixed(2);
        peakComboSensor.velocityMaximum = 0;
        peakComboSensor.velocityScrubbing = true;
        peakComboSensor.velocityStoreData = true;
        peakComboSensor.temperatureStoreData = true;
        peakComboSensor.ultrasonicFlowType = 0;
        peakComboSensor.pressureStoreData = true;

        this.peakComboSensitivityList = <Array<SampleRateElement>>[
            { id: 1, value: 4, text: '4' },
            { id: 2, value: 5, text: '5' },
            { id: 3, value: 6, text: '6' },
            { id: 4, value: 7, text: '7' },
            { id: 5, value: 8, text: '8' },
            { id: 6, value: 9, text: '9' },
            { id: 7, value: 10, text: '10' },
            { id: 8, value: 11, text: '11' },
            { id: 9, value: 12, text: '12' },
            { id: 10, value: 13, text: '13' },
            { id: 11, value: 14, text: '14' },
            { id: 12, value: 15, text: '15' },

            { id: 13, value: 17, text: '17' },
            { id: 14, value: 20, text: '20' },
            { id: 15, value: 25, text: '25' },
            { id: 16, value: 50, text: '50' },
            { id: 17, value: 100, text: '100' },
            { id: 18, value: 200, text: '200' },
        ];

        this.peakComboSensorDirectionList = <Array<SampleRateElement>>[
            { id: 2, value: 1, text: 'Forward' },
            { id: 4, value: 3, text: 'Backward Bidirectional' },
            { id: 3, value: 2, text: 'Backward' },
            { id: 1, value: 0, text: 'Bidirectional' },
        ];
        this.peakComboSensorVelocityList = <Array<SampleRateElement>>[
            PEAK_COMBO_SENSOR_VELOCITY_AUTO,
            PEAK_COMBO_SENSOR_VELOCITY_MANUAL,
        ];

        this.deadBandList = this.getIsMetric()
            ? [
                  { id: 2, value: 1, text: '25.4' },
                  { id: 3, value: 2, text: '50.8' },
                  { id: 4, value: 3, text: '76.2' },
                  { id: 5, value: 4, text: '101.6' },
                  { id: 6, value: 5, text: '127' },
                  { id: 1, value: 0, text: 'Auto' },
              ]
            : [
                  { id: 2, value: 1, text: '1' },
                  { id: 3, value: 2, text: '2' },
                  { id: 4, value: 3, text: '3' },
                  { id: 5, value: 4, text: '4' },
                  { id: 6, value: 5, text: '5' },
                  { id: 1, value: 0, text: 'Auto' },
              ];

        peakComboSensor.peakComboMaxVelocityInput = PEAK_COMBO_SENSOR_VELOCITY_AUTO.value;
        peakComboSensor.ultrasonicEnable = true;
        peakComboSensor.velocityEnable = true;
        peakComboSensor.pressureEnable = true;

        if (this.peakComboSensor) {
            Object.assign(peakComboSensor, this.peakComboSensor);
            this.peakComboSensor = null;
        }

        return peakComboSensor;
    }

    public initSurfaceComboSensor(): SufraceComboSensor {
        const surfaceComboSensor: SufraceComboSensor = new SufraceComboSensor();
        surfaceComboSensor.channel = 1;

        surfaceComboSensor.physicalOffset = +Number(0).toFixed(2);
        this.surfaceComboVelocityList = <Array<SampleRateElement>>[
            { id: 1, value: 0, text: 'Auto' },
            { id: 2, value: 1, text: 'Manual' },
        ];

        this.surfaceComboSensitivityList = <Array<SampleRateElement>>[
            { id: 1, value: 4, text: '4' },
            { id: 2, value: 5, text: '5' },
            { id: 3, value: 6, text: '6' },
            { id: 3, value: 7, text: '7' },
            { id: 3, value: 8, text: '8' },
            { id: 3, value: 9, text: '9' },
            { id: 3, value: 10, text: '10' },
            { id: 3, value: 11, text: '11' },
            { id: 3, value: 12, text: '12' },
            { id: 3, value: 13, text: '13' },
            { id: 3, value: 14, text: '14' },
            { id: 3, value: 15, text: '15' },

            { id: 3, value: 17, text: '17' },
            { id: 3, value: 20, text: '20' },
            { id: 3, value: 25, text: '25' },
            { id: 3, value: 50, text: '50' },
            { id: 3, value: 100, text: '100' },
            { id: 3, value: 200, text: '200' },
        ];

        surfaceComboSensor.ultrasonicElectronicOffset = +Number(0).toFixed(2);

        this.surfaceComboFlowList = [
            { id: 1, text: 'High Power', value: 1 },
            { id: 1, text: 'Normal', value: 0 },
        ];
        surfaceComboSensor.ultrasonicMode = 1;

        this.surfaceComboBlankingList = [
            { id: 1, text: 'Auto', value: 0 },
            { id: 2, text: '2', value: 2 },
            { id: 3, text: '3', value: 3 },
            { id: 4, text: '4', value: 4 },
            { id: 5, text: '5', value: 5 },
            { id: 6, text: '6', value: 6 },
            { id: 7, text: '7', value: 7 },
            { id: 8, text: '8', value: 8 },
            { id: 9, text: '9', value: 9 },
            { id: 10, text: '10', value: 10 },
            { id: 11, text: '11', value: 11 },
            { id: 12, text: '12', value: 12 },
        ];
        surfaceComboSensor.ultrasonicBlanking = 0;

        surfaceComboSensor.ultrasonicDisableSurchargeDetection = true;
        surfaceComboSensor.ultrasonicStoreData = true;

        // surface velocity
        this.surfaceComboSVTransmitFrequencyList = [
            { id: 1, text: 'Low', value: 0 },
            { id: 2, text: 'Medium', value: 1 },
            { id: 3, text: 'High', value: 2 },
        ];
        surfaceComboSensor.surfaceVelocityFrequency = 0;
        surfaceComboSensor.surfaceVelocitySensitivity = 10;
        this.surfaceComboSVDirectionList = [
            { id: 1, text: 'Forward', value: 1 },
            { id: 2, text: 'Backward', value: 2 },
        ];
        surfaceComboSensor.surfaceVelocityDirection = 1;
        surfaceComboSensor.surfaceVelocityGain = Number(0.9).toFixed(2);
        surfaceComboSensor.surfaceVelocityStoreData = true;
        surfaceComboSensor.surfaceVelocityScrubbing = false;
        this.surfaceComboSVDurationList = [
            { id: 1, text: '1 second', value: 1 },
            { id: 2, text: '2 seconds', value: 2 },
            { id: 3, text: '3 seconds', value: 3 },
            { id: 4, text: '4 seconds', value: 4 },
            { id: 5, text: '5 seconds', value: 5 },
        ];
        surfaceComboSensor.surfaceVelocityDuration = 3;

        // peak velocity
        this.surfaceComboPVTransmitFrequencyList = [
            { id: 1, text: 'Normal', value: 0 },
            { id: 1, text: 'High', value: 1 },
        ];
        surfaceComboSensor.velocityFrequency = 0;
        surfaceComboSensor.velocitySensitivity = 10;
        this.surfaceComboPVDirectionList = [
            { id: 1, text: 'Bidirectional', value: 0 },
            { id: 1, text: 'Forward', value: 1 },
            { id: 1, text: 'Backward', value: 2 },
            { id: 1, text: 'Backward Bidirectional', value: 3 },
        ];
        surfaceComboSensor.velocityDirection = 1;
        surfaceComboSensor.velocityGain = Number(0.9).toFixed(2);
        surfaceComboSensor.velocityStoreData = true;

        // temparature
        surfaceComboSensor.temperatureStoreData = true;

        // pressure depth measurement
        surfaceComboSensor.pressureElectronicOffset = +Number(0).toFixed(2);
        surfaceComboSensor.pressureCalibration = true;
        surfaceComboSensor.pressureStoreData = true;

        surfaceComboSensor.surfaceComboSVmaxVelocity = 0;
        surfaceComboSensor.surfaceComboPVmaxVelocity = 0;
        surfaceComboSensor.surfaceVelocityMaximum = surfaceComboSensor.surfaceComboSVmaxVelocity;
        surfaceComboSensor.velocityMaximum = surfaceComboSensor.surfaceComboPVmaxVelocity;

        if (this.surfaceComboSensor) {
            Object.assign(surfaceComboSensor, this.surfaceComboSensor);
            this.surfaceComboSensor = null;
        }

        return surfaceComboSensor;
    }

    public initSmartDepthSensor() {
        const smartDepthSensor = new SmartDepthSensor();
        this.smartDepthModeList = <Array<MonitoringPointElement>>[
            { id: 1, value: 0, text: 'Normal' },
            { id: 2, value: 1, text: 'High Power' },
        ];

        this.smartDepthBlankingList = <Array<MonitoringPointElement>>[
            { id: 1, value: 0, text: 'Auto' },
            { id: 2, value: 2, text: '2' },
            { id: 3, value: 3, text: '3' },
            { id: 4, value: 4, text: '4' },
            { id: 5, value: 5, text: '5' },
            { id: 6, value: 6, text: '6' },
            { id: 7, value: 7, text: '7' },
            { id: 8, value: 8, text: '8' },
            { id: 9, value: 9, text: '9' },
            { id: 10, value: 10, text: '10' },
            { id: 11, value: 11, text: '11' },
            { id: 12, value: 12, text: '12' },
        ];

        smartDepthSensor.physicalOffset = Number(0).toFixed(2);
        smartDepthSensor.ultrasonicElectronicOffset = Number(0).toFixed(2);
        smartDepthSensor.ultrasonicMode = 0;
        smartDepthSensor.ultrasonicBlanking = 0;
        smartDepthSensor.ultrasonicDisableSurchargeDetection = true;
        smartDepthSensor.temperatureStoreData = true;
        smartDepthSensor.ultrasonicDacGain = 0;
        smartDepthSensor.ultrasonicStoreData = true;

        if (this.smartDepthSensor) {
            Object.assign(smartDepthSensor, this.smartDepthSensor);
            this.surfaceComboSensor = null;
        }

        return smartDepthSensor;
    }

    public initAVGatedSensor(): AVGAtedSensor {
        const avGatedSensor = new AVGAtedSensor();
        avGatedSensor.ultrasonicFlowType = 0;
        avGatedSensor.channel = 1;
        avGatedSensor.physicalOffset = Number(0).toFixed(2);
        avGatedSensor.ultrasonicElectronicOffset = Number(0).toFixed(2);
        avGatedSensor.ultrasonicDeadband = 0;
        avGatedSensor.ultrasonicStoreData = true;
        avGatedSensor.pressureElectronicOffset = Number(0).toFixed(2);
        avGatedSensor.pressureStoreData = true;
        avGatedSensor.pressureCalibration = true;
        avGatedSensor.velocityDirection = 0;
        avGatedSensor.velocityMaximum = 0;
        avGatedSensor.velocityAcquisition = 0;
        avGatedSensor.velocityStoreData = true;
        avGatedSensor.velocityScrubbing = true;
        avGatedSensor.temperatureStoreData = true;

        this.avGatedFlowList = <Array<SampleRateElement>>[
            { id: 1, value: 0, text: 'Typical' },
            { id: 3, value: 2, text: 'Usually Dry' },
            { id: 2, value: 1, text: 'Wet/Stagnant' },
        ];

        this.avGatedVelocityList = <Array<SampleRateElement>>[
            { id: 1, value: 0, text: 'Auto' },
            { id: 2, value: 1, text: 'Manual' },
        ];

        this.avGatedDirectionList = <Array<SampleRateElement>>[
            { id: 2, value: 1, text: 'Forward' },
            { id: 4, value: 3, text: 'Backward Bidirectional' },
            { id: 3, value: 2, text: 'Backward' },
            { id: 1, value: 0, text: 'Bidirectional' },
        ];

        this.velocityAcquisition = <Array<MonitoringPointElement>>[
            { id: 1, value: 0, text: 'Auto' },
            { id: 4, value: 3, text: 'High' },
            { id: 2, value: 1, text: 'Low' },
            { id: 3, value: 2, text: 'Medium' },
        ];

        this.avGatedBandList = this.getIsMetric()
            ? [
                  { id: 2, value: 1, text: '25.4' },
                  { id: 3, value: 2, text: '50.8' },
                  { id: 4, value: 3, text: '76.2' },
                  { id: 5, value: 4, text: '101.6' },
                  { id: 6, value: 5, text: '127' },
                  { id: 1, value: 0, text: 'Auto' },
              ]
            : [
                  { id: 2, value: 1, text: '1' },
                  { id: 3, value: 2, text: '2' },
                  { id: 4, value: 3, text: '3' },
                  { id: 5, value: 4, text: '4' },
                  { id: 6, value: 5, text: '5' },
                  { id: 1, value: 0, text: 'Auto' },
              ];

        avGatedSensor.velocityMaximumAVGatedValue = this.avGatedVelocityList[0].value;

        avGatedSensor.velocityAcquisition = 0;
        if (this.getIsMetric()) {
            this.minVelocityRange = 0.5;
            this.maxVelocityRange = 9;
            this.avGatedPattern = this.numericDecimal2PlacePattern;
            this.velocityMaxError = this.metricVelocityErrorFromJson;
            this.avGatedMaxVelocityPatternError = this.avGatedPatternVeocityMetric;
            this.minVelocityList = <Array<MaxVelocityElement>>[
                { id: 1, value: 0.01, text: '0.01' },
                { id: 2, value: 0.02, text: '0.02' },
                { id: 3, value: 0.05, text: '0.05' },
            ];
            avGatedSensor.velocityMinimum = 0.02;
        } else {
            this.minVelocityRange = 2;
            this.maxVelocityRange = 30;
            this.avGatedPattern = this.numericGeneralPattern;
            this.velocityMaxError = this.usVelocityErrorFromJson;
            this.avGatedMaxVelocityPatternError = this.avGatedPatternVeocityUS;
            this.minVelocityList = <Array<MaxVelocityElement>>[
                { id: 1, value: 0.02, text: '0.02' },
                { id: 2, value: 0.05, text: '0.05' },
                { id: 3, value: 0.1, text: '0.10' },
                { id: 4, value: 0.2, text: '0.20' },
            ];
            avGatedSensor.velocityMinimum = 0.05;
        }

        avGatedSensor.ultrasonicEnable = true;
        avGatedSensor.pressureEnable = true;
        avGatedSensor.velocityEnable = true;
        if (this.avGatedSensor) {
            Object.assign(avGatedSensor, this.avGatedSensor);
            this.avGatedSensor = null;
        }
        return avGatedSensor;
    }

    public initLongRangeDepth(isMetric: boolean): LongRangeDepthSensor {
        this.longRangeDepthSensorTemp = [
            { value: 0, text: 'Compensated' },
            { value: 1, text: 'Sensor' },
        ];
        this.longRangeDepthSensorPower = [
            { value: 0, text: 'Auto' },
            { value: 1, text: '1' },
            { value: 2, text: '2' },
            { value: 3, text: '3' },
            { value: 4, text: '4' },
            { value: 5, text: '5' },
            { value: 6, text: '6' },
            { value: 7, text: '7' },
            { value: 8, text: '8' },
            { value: 9, text: '9' },
            { value: 10, text: '10' },
        ];
        this.longRangeDepthSensorGainControl = [
            { value: 0, text: 'Manual' },
            { value: 1, text: 'Auto' },
        ];
        const longRangeDepthSensor: LongRangeDepthSensor = {
            physicalOffset: isMetric ? Number(355.6).toFixed(2) : 14,
            storeData: true,
            blanking: +Number(0).toFixed(2),
            temperature: this.longRangeDepthSensorTemp[0].text,
            power: this.longRangeDepthSensorPower[0].text,
            electronicOffset: +Number(0).toFixed(2),
            gainControlSelection: this.longRangeDepthSensorGainControl[1].text,
            manualGain: ECHO_GAIN_VALUE,
            unidepthFiltering: true,
            storePressure: true,
            MarchTemperature: isMetric ? 12.78 : 55,
            SeptemberTemperature: isMetric ? 22.78 : 73,
        };

        return longRangeDepthSensor;
    }

    public initLongRangeDepthTriton(isMetric: boolean) {
        // const longRangeDepthTritonSensor = new LongRangeDepthTritonSensor();
        // this.longRangeDepthTritonSensorPower = [
        //     { id: 1, value: 0, text: 'Auto' },
        //     { id: 2, value: 1, text: '1' },
        //     { id: 3, value: 2, text: '2' },
        //     { id: 4, value: 3, text: '3' },
        //     { id: 5, value: 4, text: '4' },
        //     { id: 6, value: 5, text: '5' },
        //     { id: 7, value: 6, text: '6' },
        //     { id: 8, value: 7, text: '7' },
        //     { id: 9, value: 8, text: '8' },
        //     { id: 10, value: 9, text: '9' },
        //     { id: 11, value: 10, text: '10' },
        //     { id: 12, value: 11, text: '11' },
        // ];
        // this.longRangeDepthTritonSensorGainControl = [
        //     { id: 1, value: 0, text: 'Manual' },
        //     { id: 2, value: 1, text: 'Auto' }
        // ];
        // this.longRangeDepthTritonSensorTemp = [
        //     { id: 1, value: 0, text: 'Compensated' },
        //     { id: 2, value: 1, text: 'Sensor' }
        // ];
        // longRangeDepthTritonSensor.physicalOffset = isMetric ? Number(355.6).toFixed(2) : 14;
        // longRangeDepthTritonSensor.blanking = +Number(0).toFixed(2);
        // longRangeDepthTritonSensor.longRangeDepthTritonSensorPowerModel = this.longRangeDepthTritonSensorPower[0].text;
        // longRangeDepthTritonSensor.power = this.longRangeDepthTritonSensorPower[0].value;
        // longRangeDepthTritonSensor.gainControl = ECHO_GAIN_VALUE;
        // this.gainControlLongRangeDepthTritonSensor = this.longRangeDepthTritonSensorGainControl[1].text;
        // longRangeDepthTritonSensor.compensatedTemperatureMarch = isMetric ? 12.78 : 55;
        // longRangeDepthTritonSensor.compensatedTemperatureSeptember = isMetric ? 22.78 : 73;
        // longRangeDepthTritonSensor.pressureStore = false;
        // longRangeDepthTritonSensor.storeData = true;
        // longRangeDepthTritonSensor.frequency = 0;
        // longRangeDepthTritonSensor.temperatureSensor = this.longRangeDepthTritonSensorTemp[0].value;
        // longRangeDepthTritonSensor.unidepthStore = true;
        // longRangeDepthTritonSensor.electronicOffset = 0;
        // longRangeDepthTritonSensor.disableCapacitance = false;
        // longRangeDepthTritonSensor.logCapacitance = false;
        // longRangeDepthTritonSensor.surchargeThreshold = 85;
        // longRangeDepthTritonSensor.type = 249;
        // longRangeDepthTritonSensor.name = 'Long Range Depth 1';
        // longRangeDepthTritonSensor.channel = 1;
        // longRangeDepthTritonSensor.installationNumber = 0;
        // longRangeDepthTritonSensor.active = true;
        // longRangeDepthTritonSensor.disableCapacitance = true;
        // longRangeDepthTritonSensor.logCapacitance = false;
        // longRangeDepthTritonSensor.surchargeThreshold = 85;
        // if (this.longRangeDepthTriton) {
        //     Object.assign(longRangeDepthTritonSensor, this.longRangeDepthTriton.longRangeDepthTritonSensor);
        //     this.longRangeDepthTriton = null;
        // }
        // return longRangeDepthTritonSensor;
    }

    /**
     * Returns an indicator of if the customer units are metric values based on the way the component was opened.
     * If it was opened from a customer edit form then the customer details value is used otherwise the dateutilService is used.
     */
    public getIsMetric(): boolean {
        return this.data.isFromCustomerEditor
            ? this.isMetricUnit
            : !!this.dateutilService.isCustomerUnitsMetric.getValue();
    }
}
