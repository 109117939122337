import { Customer } from './customer';
import { LocationData } from './locations';
import { ILocationFeature } from './users';

export interface User {
    userID: string;
    firstName: string;
    lastName: string;
    userEmail: string;
    userName?: string;
    lastLogin?: string;
    role: string;
    features: Array<string>;
    customers: string;
    locations: string;
    isSelected?: boolean;
    isActive: boolean;
}

export class GetUsersResponse {
    public isError: boolean;
    public message: string;
    public fieldErrors = new Array<string>();
    public payload = new Array<User>();
    public count: number;
}

/**
 * Temporary model for synchronizing users.
 */
export interface UserSync {
    permittedUsers: Array<string>;
}

/**
 * Represents the user permission respnose.
 */
export interface UserPermissionResponse {
    success: boolean;
    permissionsUpdated: boolean;
}

export interface GetPermissionsResponseUser {
    userID: string;
    firstName: string;
    lastName: string;
    userEmail: string;
    userName: string;
    secondaryEmail: string;
    phoneNumber?: string;
    lastLogin?: string;
    roles: Array<string>;
    features: Array<GetPermissionsResponseFeature>;
    customers: Array<GetPermissionsResponseCustomer>;
    locations: Array<LocationData>;
    theme: string;
    isActive: boolean;
    payload?: GetPermissionsResponseUser;
    isIdex: boolean;
}

export interface UserInfo {
    userID: string;
    firstName: string;
    lastName: string;
    userEmail: string;
    secondaryEmail?: string;
    phoneNumber?: string;
    isIdex: boolean;
}

export interface GetPermissionsResponseFeature {
    id: number;
    cid: number;
    name: string;
    viewable: boolean;
    isbeta?: boolean;
}

export interface GetPermissionsResponseCustomer {
    customerID: number;
    permissionID?: string;
    defaultCustomer?: boolean;
    customer?: Customer;
    isCurrentlySelected?: boolean;
}

export interface GetPermissionsResponseCustomerPermissions {
    customerID: number;
    customerName: string;
    features: Array<GetPermissionsResponseFeature>;
    locations: Array<GetPermissionsResponseLocation>;
}

export interface GetPermissionsResponseLocation {
    id: number;
    name: string;
    viewable: boolean;
}

export const THEMES = {
    darkTheme: 'dark',
    lightTheme: 'light',
    themeColorDark: 'BLACK',
    themeColorLight: 'WHITE',
};

export interface GetPermissionUserFeature {
    id: number;
    name: string;
    viewable: boolean;
}
