import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { Either, isLeft } from 'fp-ts/es6/Either';

@Directive({
    selector: '[eitherValidation][ngModel]',
})
export class EitherValidationDirective {
    // don't care about the second type parameter. NOT using it
    @Input() set eitherValidation(value: Either<string, unknown>) {
        // for some reason angular doesn't apply the errors in UI when form control is added dynamically until the second "ngOnChanges" call
        // would be nice to figure out a proper way to resolve it without using "setTimeout"
        setTimeout(() => {
            if (isLeft(value)) {
                this.control.setErrors([{ eitherValidation: value }]);
            } else {
                this.control.setErrors(null);
            }
        }, 0);
    }

    private control: AbstractControl = null;

    constructor(el: ElementRef, c: NgControl) {
        this.control = c.control;
    }
}
