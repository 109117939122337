import {
    Component,
    Input,
    ViewChild,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    OnInit,
    OnChanges,
    SimpleChanges,
    OnDestroy,
} from '@angular/core';
import Highcharts from 'highcharts';
// According to https://github.com/highcharts/highcharts/issues/4994
window.Highcharts = Highcharts;
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { StormPeriodAdjustment } from '../flow-monitor.model';
import {
    SLI_COLOR_DECOMP_GROSS_II,
    SLI_COLOR_DECOMP_GROSS_Q,
    SLI_COLOR_DECOMP_NET_II,
    SLI_COLOR_MODELEDPRECOMP,
    SLI_COLOR_RAINFALL,
    SLI_OCOLOR_PRECOMP,
    SLI_OCOLOR_RECOVERY1,
    SLI_OCOLOR_RECOVERY2,
    SLI_OCOLOR_STORM,
} from 'app/shared/constant';
import { BasinStormResult, DayOfWeekGroupDays, DryDayData, PrecompensationType, SliicerCaseStudy, WeekGroup } from 'app/shared/models/sliicer';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { SliicerUtils } from 'app/shared/utils/sliicer-utils';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { UnitOfMeasureType } from 'app/shared/constant';
import { StringUtils } from 'app/shared/utils/string-utils';
import { Subscription } from 'rxjs';
import { DataHelperService } from 'app/pages/sliicer/shared/services/dataHelper/data-helper.service';
import { Season, caseStudyDates, caseStudySeasonsInitData, initSeasonsState } from '../../study-settings/seasons-settings/seasons-settings.utils';
import { Option } from 'fp-ts/es6/Option';
import { Regime } from 'app/shared/models/sliicer/settings'

require('highcharts-multicolor-series')(Highcharts);
require('highcharts/modules/series-label')(Highcharts);

const MS_PER_MINUTE = 60000;
const ONE_DAY = 24 * 60 * MS_PER_MINUTE;
const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const WEEKDAYS = 'Weekdays';
const NONE_SEASON_TYPE = 'None';

const MILLIMETERS = 'mm';
const LITRES_PER_SECOND = 'l/s';
const INCHES = 'in';
const MIL_GAL_DAY = 'mgd';
const CFS = 'cfs';

@Component({
    selector: 'ads-storm-decomposition-hydrograph',
    templateUrl: './storm-decomposition-hydrograph.component.html',
    styleUrls: ['./storm-decomposition-hydrograph.component.scss'],
})
export class StormDecompositionHydrographComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public isLoading: boolean;
    @Input() public showStormPlotBands = true;
    @Input() public showTooltips = true;
    @Input() public showModeledPrecompensation = false;
    @Input() public stormPeriods: StormPeriodAdjustment;
    @Input() public basinStormData: BasinStormResult;
    @Input() public weekGroups: WeekGroup[] = [];
    @Input() public basinDryDays: DryDayData;
    @Input() public stepLength: number;
    @Input() public isRainOnTop: boolean;
    @Output() public xAxisMinMaxEmitter: EventEmitter<number[]> = new EventEmitter();
    @Output() public isZoomed: EventEmitter<boolean> = new EventEmitter();
    @ViewChild('chart') public chartElement: Highcharts.ChartObject;

    // needed by HTML
    public haveData = false;
    public haveStorms = false;
    public chartData: Highcharts.Chart;

    // more subscribed data... translations
    private rainfallLabel: string;
    private yAxisLeftLabel: string;
    private yAxisRightLabel: string;
    private xAxisLabel: string;
    private grossIILabel: string;
    private netIILabel: string;
    private grossQLabel: string;
    private dryDayFlowLabel: string;
    private modeledPrecompensationLabel: string;

    private chartStartDate: Date;
    private chartEndDate: Date;

    private subscriptions: Array<Subscription> = [];

    private precompType: PrecompensationType;

    private studySeasons: Option<Season[]>;
    private studyRegimes: Regime[];
    private isMetric: boolean;

    constructor(
        private dateUtilService: DateutilService,
        private dataHelper: DataHelperService,
        private translateService: TranslateService,
        private customerService: CustomerService,
        private utilService: UiUtilsService,
        private cdr: ChangeDetectorRef,
        private sliicerService: SliicerService,
        private statusCodeService: StatusCodeService
    ) { }

    public ngOnInit() {
        const translateKeys: Array<string> = [
            'COMMON.FLOW_RATE',
            'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.RAINFALL_DEPTH',
            'COMMON.RAINFALL',
            'COMMON.GROSS_II',
            'COMMON.NET_II',
            'COMMON.GROSS_Q',
            'COMMON.DRY_DAY_FLOW',
            'SLIICER.COMMON.MODELED_PRECOMPENSATION',
            'COMMON.DATE_TEXT'
        ];
        this.translateService.get(translateKeys).subscribe((values) => {
            this.yAxisLeftLabel = values['COMMON.FLOW_RATE'];
            this.yAxisRightLabel = values['SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.RAINFALL_DEPTH'];
            this.rainfallLabel = values['COMMON.RAINFALL'];
            this.grossIILabel = values['COMMON.GROSS_II'];
            this.netIILabel = values['COMMON.NET_II'];
            this.grossQLabel = values['COMMON.GROSS_Q'];
            this.dryDayFlowLabel = values['COMMON.DRY_DAY_FLOW'];
            this.modeledPrecompensationLabel = values['SLIICER.COMMON.MODELED_PRECOMPENSATION'];
            this.xAxisLabel = values['COMMON.DATE_TEXT'];
        });

        const themeSub = this.statusCodeService.userInfoThemeBS.subscribe(theme => {
            if (theme) {
                StringUtils.setHighChartTheme(this.chartData);
            } else {
                StringUtils.setHighchartWhiteTheme(this.chartData);
            }
        });

        this.isMetric = this.customerService.customer.unitsType === UnitOfMeasureType.METRIC;

        const dateFormat = this.dateUtilService.dateFormat.getValue();
        const studySubs = this.sliicerService.studyDetailsData$.subscribe((studyData: SliicerCaseStudy) => {
            const dates = caseStudyDates(studyData);
            const originalSeasonsData = caseStudySeasonsInitData(studyData);

            if (!originalSeasonsData || originalSeasonsData.seasonType === NONE_SEASON_TYPE) {
                this.studySeasons = null;
            } else {
                const state = initSeasonsState(dateFormat, dates, originalSeasonsData);
                this.studySeasons = state.seasons;
            }

            this.studyRegimes = studyData.settings.regimes;
        });

        this.subscriptions.push(themeSub, studySubs);
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions = [];
    }

    public ngOnChanges(changes: SimpleChanges) {
        if ((changes.showStormPlotBands && !changes.showStormPlotBands.firstChange && changes.showStormPlotBands) || (changes.stormPeriods && this.chartData && !changes.stormPeriods.firstChange)) {
            this.rebuildPlotBands();
        }
        if (changes.showTooltips && this.chartData) {
            const value = { tooltip: { enabled: this.showTooltips } };
            this.chartData.update(value);
        }
        if (changes.basinStormData || changes.weekGroups || changes.basinDryDays) {
            if (this.basinStormData) {
                this.precompType = this.basinStormData.preCompType;
            }

            this.chartData = this.buildChart();
        }

        if (changes.showModeledPrecompensation && this.chartData) {
            if (this.showModeledPrecompensation) {
                this.chartData.get('modeledPrecomp').show();
            } else {
                this.chartData.get('modeledPrecomp').hide();
            }
        }

        // NOT USED: Update chart range. Currently issue with ngx-slider so it is NOT USED.
        // if(changes.stormPeriods && changes.stormPeriods.currentValue && !changes.isLoading) {
        //     const stormPeriods = changes.stormPeriods.currentValue;
        //     this.updateRange(stormPeriods);
        // }
    }

    // Update chart range. Currently issue with ngx-slider so it is NOT USED.
    public updateRange(stormPeriods) {
        this.chartStartDate = this.dataHelper.ChartStartDate(stormPeriods);
        this.chartEndDate = this.dataHelper.ChartEndDate(stormPeriods);

        const startTS = this.chartStartDate.getTime();
        const endTS = this.chartEndDate.getTime();

        const xAxis = this.chartData.xAxis[0];
        const extremes = xAxis.getExtremes();

        const min = extremes[0];
        const max = extremes[1];


        const flowUnit = this.dateUtilService.volumnePerTimeUnit.getValue();
        const days = this.basinDryDays ? this.basinDryDays.dayOfWeekGroupDays : [];

        const useSeasons = this.sliicerService.hasSeasons();
        const useRegimes = this.sliicerService.hasRegimes();
        const useYears = this.sliicerService.hasYears();


        const seriesNew = StormDecompositionHydrographComponent.BuildDryDaySeries(
            this.sliicerService,
            this.chartStartDate,
            this.chartEndDate,
            days,
            this.weekGroups,
            this.stepLength,
            this.dryDayFlowLabel,
            flowUnit, this.studySeasons, this.studyRegimes,
            useSeasons, useRegimes, useYears
        );


        const chartSeries = this.chartData.series;

        const ddfSeries = chartSeries.find((s) => s.name === this.dryDayFlowLabel);


        const seriesData = [];
        for (const d of seriesNew) {
            seriesData.push(d.data);
        }
        ddfSeries.setData(seriesData);

        if (startTS !== min || endTS !== max) {
            xAxis.setExtremes(startTS, endTS);
        }

        this.chartData.redraw();
    }

    public redraw() {
        if (!this.chartData) {
            return;
        }
        setTimeout(() => {
            this.chartData.reflow();
        }, 0);
    }

    public updateSeries(name: string, data: any[]) {
        if (!this.chartData || !this.chartData.series) {
            return;
        }
        const index = this.chartData.series.findIndex((s) => s.name === name);
        this.chartData.series[index].setData(data);
    }

    /**
     * This is triggered when the user is adjusting the storm periods.
     * Do not use ngOnChanges for this change because we have to zero
     * out I/I prior to storm start.
     * @param stormPeriods
     */
    public refreshStormPeriods(stormPeriods: StormPeriodAdjustment) {
        if (!this.chartElement) {
            return;
        }

        if(!this.chartStartDate || !this.chartEndDate) return;

        const chartStartDate = this.chartStartDate;
        const chartEndDate = this.chartEndDate;
        const chartStartTs = this.chartStartDate.getTime();
        const chartEndTs = this.chartEndDate.getTime();

        // Make sure the I/I and precomp are zeroed prior to the storm period if necessary
        const stormBounds = StormDecompositionHydrographComponent.getStormBoundariesFromStormPeriod(stormPeriods);

        if (this.basinStormData.grossIIHydrograph && this.basinStormData.grossIIHydrograph.length > 0) {
            let data = (StormDecompositionHydrographComponent.GetComputedData(
                this.basinStormData.grossIIHydrograph,
                chartStartDate,
                chartEndDate,
                this.stepLength,
                stormBounds.start,
                stormBounds.end,
            ) as Array<Array<number>> );
            data = data.filter((d) => d[0] > chartStartTs && d[0] < chartEndTs);
            this.updateSeries(this.grossIILabel, data);
            this.chartData.xAxis[0].setExtremes(null, null);
        }
        if (this.basinStormData.netIIHydrograph && this.basinStormData.netIIHydrograph.length > 0) {
            let data = (StormDecompositionHydrographComponent.GetComputedData(
                this.basinStormData.netIIHydrograph,
                chartStartDate,
                chartEndDate,
                this.stepLength,
                stormBounds.start,
                stormBounds.end,
            ) as Array<Array<number>> );
            data = data.filter((d) => d[0] > chartStartTs && d[0] < chartEndTs);
            this.updateSeries(this.netIILabel, data);
            this.chartData.xAxis[0].setExtremes(null, null);
        }
    }

    /**
     * Event triggered when the chart gets rendered
     * @param event
     */
    public chartRenderCallback(event: Highcharts.ChartRenderCallbackFunction) {
        if (event.type === 'render') {
            this.xAxisMinMaxEmitter.emit([event.target.xAxis[0].min, event.target.xAxis[0].max]);
        }
    }

    private static getStormBoundariesFromStormPeriod(stormPeriods: StormPeriodAdjustment) {
        const stormStart = moment(stormPeriods.stormStartTime);
        const startTimestamp = stormStart.valueOf();
        const endTimestamp = stormStart
            .add(
                stormPeriods.stormPeriodLength +
                stormPeriods.recovery1PeriodLength +
                stormPeriods.recovery2PeriodLength,
                'minutes',
            )
            .valueOf();
        return { start: startTimestamp, end: endTimestamp };
    }

    private rebuildPlotBands() {
        if (this.chartData) {
            const bandData = this.showStormPlotBands
                ? StormDecompositionHydrographComponent.PlotBands(this.stormPeriods)
                : [];
            const update = {
                xAxis: {
                    plotBands: bandData,
                },
            };
            this.chartData.update(update);
        }
    }

    private onXAxisZoomChange(event, self: StormDecompositionHydrographComponent): void {
        if (event && event.min && event.max) {
            const startDate = new Date(event.min);
            const endDate = new Date(event.max);
            const settings = self.prepareXAxisLabels(startDate, endDate);
            if (settings) {
                event.target.options.categories = settings.categories;
                event.target.options.tickInterval = settings.interval;
            }
        }

        this.isZoomed.emit(event && event.min && event.max);
    }

    private applyPrecompAdjToDryDay(stormData: BasinStormResult, series) {
        const precompSeries = series.find(v => v.name === this.modeledPrecompensationLabel);
        const dryDaySeries = series.filter(v => v.seriesName && v.seriesName.includes(this.dryDayFlowLabel));

        if (!precompSeries || !precompSeries.data.length || !dryDaySeries.length || dryDaySeries.every(v => v.data.length === 0)) {
            return;
        }

        const precompDataMap = precompSeries.data.reduce((acc, curr) => {
            const [ts, value] = curr;

            return acc.set(ts, value);
        }, new Map());

        const isPositiveAdjustment = stormData.precompAdjust >= 0;

        dryDaySeries.forEach((ddSeries) => {
            ddSeries.data = ddSeries.data.map((p, i) => {
                if (p.y === null) {
                    return p;
                }
                const currentAdjustmentValue = precompDataMap.get(p.x) ? precompDataMap.get(p.x) : 0;
                const adjustment = isPositiveAdjustment ? currentAdjustmentValue : (currentAdjustmentValue * -1);

                return ({ ...p, y: p.y + adjustment });
            });
        })
    }

    private buildChart(): Highcharts.Chart {
        if (!this.basinStormData || !this.stormPeriods) {
            this.haveStorms = false;

            if (this.chartData) {
                this.chartData.destroy();
            }
            return null;
        }


        this.haveStorms = true;
        const chartStartDate = this.dataHelper.ChartStartDate(this.stormPeriods);
        const chartEndDate = this.dataHelper.ChartEndDate(this.stormPeriods);
        this.chartStartDate = chartStartDate;
        this.chartEndDate = chartEndDate;

        const rainUnit = this.dateUtilService.unitOfMeasure.getValue();
        const flowUnit = this.isMetric ? this.dateUtilService.customerUnit.getValue():  this.dateUtilService.volumnePerTimeUnit.getValue();

        const days = this.basinDryDays ? this.basinDryDays.dayOfWeekGroupDays : [];

        // TODO: get the chart to failed to load data when filter all storms out.
        // if(!days.length) {
        //     this.haveData = false;
        //     this.utilService.safeChangeDetection(this.cdr);
        //     return null;
        // }

        // #29252 If precomp is constant, then apply precompAdjust values over what will be plotted.
        if (this.precompType === PrecompensationType.Constant) {
            const precompAdjustAbsValue = Math.abs(this.basinStormData.precompAdjust);
            this.basinStormData.modeledPrecomp = this.basinStormData.modeledPrecomp.map((v) => precompAdjustAbsValue);
        }

        const useSeasons = this.sliicerService.hasSeasons();
        const useRegimes = this.sliicerService.hasRegimes();
        const useYears = this.sliicerService.hasYears();

        const seriesData = StormDecompositionHydrographComponent.BuildSeries(
            this.sliicerService,
            this.basinStormData,
            rainUnit,
            flowUnit,
            chartStartDate,
            chartEndDate,
            this.stormPeriods,
            this.rainfallLabel,
            this.grossIILabel,
            this.grossQLabel,
            this.netIILabel,
            this.modeledPrecompensationLabel,
            this.showModeledPrecompensation,
            this.dryDayFlowLabel,
            this.stepLength,
            this.weekGroups,
            days,
            this.studySeasons, this.studyRegimes,
            useSeasons, useRegimes, useYears
        );
        this.haveData = seriesData.length > 0;
        if (!this.haveData) {
            return null;
        }

        this.applyPrecompAdjToDryDay(this.basinStormData, seriesData)
        const self = this;
        const xAxisPlotBands = StormDecompositionHydrographComponent.PlotBands(this.stormPeriods);
        const tooltipFormat = this.dateUtilService.getGraphDateFormat() + ' %H:%M';
        const xAxisSettings = this.prepareXAxisLabels(chartStartDate, chartEndDate);
        const dateFormat = this.sliicerService.dateFormatStormDecompositionHydrograph();

        const precompSeriesName = this.modeledPrecompensationLabel;
        const isPositivePrecomp = this.basinStormData.precompAdjust >= 0;

        return new Highcharts.StockChart(<any>{
            chart: {
                renderTo: 'storm-decomp-chart',
                events: {
                    render: self.chartRenderCallback.bind(self),
                },
                type: 'line',
                ignoreHiddenSeries: false,
                zoomType: 'xy',
                marginBottom: 60,
                marginTop: 30,
                reflow: true,
                // IMPORTANT: For "line" series all need to have threshold set to zero for it to work !
                alignThresholds: true
            },
            plotOptions: {
                line: {
                    lineWidth: 1,
                    marker: {
                        enabled: false,
                    },
                },
                series: {
                    dataGrouping: {
                        enabled: false,
                    },
                    marker: {
                        enabled: false,
                    },
                    pointWidth: 3,
                    animation: false,
                },
            },
            title: { text: '' },
            exporting: { enabled: false },
            rangeSelector: {
                enabled: false,
            },
            tooltip: {
                enabled: this.showTooltips,
                shared: true,
                useHTML: true,
                formatter: function () {
                    if (this.points) {
                        const points = this.points.filter(a => a.point && a.point.options && (a.color === a.point.options.segmentColor || !a.point.options.hasOwnProperty('segmentColor')));
                        return points.map((point, index: number) => {
                            const isPrecompSeries = point.series && point.series.name === precompSeriesName;
                            const units = point.series.userOptions.plotUnit;
                            const decimals = self.getDecimals(units);

                            let yPoint = Number(
                                StormDecompositionHydrographComponent.ApplyUnitRounding(point.y, decimals),
                            );

                            // for Precompensation series we display positive values even for negative precomp, and in case of negative precomp, we show it on tooltip
                            if (isPrecompSeries && !isPositivePrecomp) {
                                yPoint = yPoint * -1;
                            }

                            let dateStr = '';
                            if (index === 0) {
                                dateStr = Highcharts.dateFormat(tooltipFormat, new Date(point.x).getTime()) + ' <br/>';
                            }
                            // tslint:disable-next-line:max-line-length
                            return `${dateStr}<span style ='color:${point.series.color}'>\u25CF</span> ${point.series.name} : <b>${yPoint}</b> ${point.series.userOptions.plotUnit} <br/>`;
                        }, '<b>' + this.x + '</b>');
                    } else {
                        return false;
                    }
                },
                positioner() {
                    const horizontalOffset = 10;
                    const verticalOffset = 5;

                    return {
                        x: horizontalOffset,
                        y: verticalOffset,
                    };
                },
                shape: 'rect', // make sure the line (present for the default type 'callout') is not shown
                split: false, // for some reason without this option (although it's false by default) the positioner doesn't work
            },
            credits: { enabled: false },
            navigator: {
                enabled: false,
            },
            scrollbar: {
                enabled: false,
            },
            legend: {
                enabled: false,
                layout: 'horizontal',
                align: 'right',
                verticalAlign: 'bottom',
            },
            series: seriesData,
            responsive: {
                rules: [
                    {
                        condition: {
                            maxWidth: 3000,
                        },
                    },
                ],
            },
            xAxis: {
                categories: xAxisSettings.categories,
                crosshair: true,
                type: 'datetime',
                tickInterval: xAxisSettings.interval,
                startOnTick: false,
                endOnTick: false,
                title: {
                    text: this.xAxisLabel,
                },
                labels: {
                    autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
                },
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: dateFormat, // '%e/%m/%Y',
                    week: dateFormat,
                    month: dateFormat,
                    year: dateFormat,
                },
                plotBands: xAxisPlotBands,
                events: {
                    setExtremes: function (event) {
                        self.onXAxisZoomChange(event, self);
                    },
                },
            },
            yAxis: [
                {
                    title: {
                        text: `${this.yAxisLeftLabel} (${flowUnit})`,
                    },
                    opposite: false,
                    startOnTick: true,
                    endOnTick: true,
                    softMin: 0,
                    showFirstLabel: true,
                    showLastLabel: true,
                },
                {
                    title: {
                        text: `${this.yAxisRightLabel} (${rainUnit})`,
                    },
                    opposite: true,
                    reversed: this.isRainOnTop,
                    max: SliicerUtils.rainAxisMax(rainUnit),
                    startOnTick: true,
                    endOnTick: true,
                    showFirstLabel: true,
                    showLastLabel: true,
                },
            ],
        });
    }

    private getDecimals(unit: string) {
        unit = unit.toLowerCase();

        if (unit === MILLIMETERS || unit === LITRES_PER_SECOND) {
            return 1;
        }

        if (unit === MIL_GAL_DAY || unit === CFS) {
            return 3;
        }

        return 2;
    }

    private prepareXAxisLabels(startDate: Date, endDate: Date) {
        const totalDays = this.dateUtilService.differenceInDays(startDate, endDate);
        let xAxisCategories = [];
        let tickInterval: number;
        let dateTimeLabelFormat = {};
        if (totalDays > 61) {
            // month wise
            xAxisCategories = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            tickInterval = 24 * 3600 * 1000 * 30;
            dateTimeLabelFormat = {
                month: "%b '%y",
            };
        } else if (totalDays > 31 && totalDays <= 61) {
            // week wise if date range more than month and max two month only
            const weekCount = this.dateUtilService.weekCount(startDate);
            for (let i = 1; i <= weekCount; i++) {
                xAxisCategories.push(`Week ${i}`);
            }
            tickInterval = 24 * 3600 * 1000 * 7;
            dateTimeLabelFormat = {
                week: '%e. %b, %Y',
            };
        } else {
            // date wise if date range equal or less than a month
            xAxisCategories = this.dateUtilService.getDateRange(startDate, endDate);
            tickInterval = 24 * 3600 * 1000;
            dateTimeLabelFormat = {
                day: '%m/%d/%Y',
            };
        }
        return {
            categories: xAxisCategories,
            interval: tickInterval,
            dateFormat: dateTimeLabelFormat,
            startOnTick: false,
            endOnTick: false,
        };
    }

    private static ApplyUnitRounding(value: number, decimal: number): any {
        return Number(value).toFixed(decimal);
    }



    private static PlotBands(stormPeriods: StormPeriodAdjustment) {
        if (!stormPeriods) {
            return [];
        }
        const stormStart = new Date(stormPeriods.stormStartTime);
        const detached = !stormPeriods.deattachPrecomp
        let preCompEnd, preCompStart
        if (detached) {
            preCompEnd = new Date(stormPeriods.altPrecompEnd);
            preCompStart = new Date(stormPeriods.altPrecompStart);
        }
        const xAxisPlotBands = [];
        xAxisPlotBands.push({
            color: SLI_OCOLOR_PRECOMP,
            from: !detached ? stormStart : preCompEnd,
            to: !detached ? moment(stormStart).add(-stormPeriods.precompPeriodLength, 'minutes') : preCompStart,
            id: 'precomp',
        });
        const stormEnd = moment(stormStart).add(stormPeriods.stormPeriodLength, 'minutes');
        xAxisPlotBands.push({
            color: SLI_OCOLOR_STORM,
            from: stormStart,
            to: stormEnd,
            id: 'storm',
        });
        const recovery1End = moment(stormStart).add(
            stormPeriods.stormPeriodLength + stormPeriods.recovery1PeriodLength,
            'minutes',
        );
        xAxisPlotBands.push({
            color: SLI_OCOLOR_RECOVERY1,
            from: stormEnd,
            to: recovery1End,
            id: 'recovery1',
        });
        const recovery2End = moment(stormStart).add(
            stormPeriods.stormPeriodLength + stormPeriods.recovery1PeriodLength + stormPeriods.recovery2PeriodLength,
            'minutes',
        );
        xAxisPlotBands.push({
            color: SLI_OCOLOR_RECOVERY2,
            from: recovery1End,
            to: recovery2End,
            id: 'recovery2',
        });
        return xAxisPlotBands;
    }

    private static BuildSeries(
        sliicerService: SliicerService,
        basinStormResult: BasinStormResult,
        rainUnitOfMeasure: string,
        flowUnitOfMeasure: string,
        chartStartDate: Date,
        chartEndDate: Date,
        stormPeriods: StormPeriodAdjustment,
        rainfallLabel: string,
        grossIILabel: string,
        grossQLabel: string,
        netIILabel: string,
        modeledPrecompensationLabel: string,
        modeledPrecompensationVisible: boolean,
        dryDayFlowLabel: string,
        stepLength: number,
        dayOfWeekGroups: WeekGroup[],
        dayOfWeekGroupDays: DayOfWeekGroupDays[],
        studySeasons: Option<Season[]>,
        studyRegimes: Regime[],
        hasSeasons: boolean, hasRegimes: boolean, hasYears: boolean
    ): {}[] {


        const seriesData = [];
        if (basinStormResult.hyetograph && basinStormResult.hyetograph.length > 0) {
            seriesData.push({
                name: rainfallLabel,
                type: 'column',
                yAxis: 1,
                threshold: 0,
                data: StormDecompositionHydrographComponent.GetComputedData(
                    basinStormResult.hyetograph,
                    chartStartDate,
                    chartEndDate,
                    stepLength,
                ),
                color: SLI_COLOR_RAINFALL,
                plotUnit: rainUnitOfMeasure,
            });
        }

        const stormBounds = StormDecompositionHydrographComponent.getStormBoundariesFromStormPeriod(stormPeriods);
        if (basinStormResult.grossIIHydrograph && basinStormResult.grossIIHydrograph.length > 0) {
            seriesData.push({
                name: grossIILabel,
                type: 'line',
                yAxis: 0,
                threshold: 0,
                data: StormDecompositionHydrographComponent.GetComputedData(
                    basinStormResult.grossIIHydrograph,
                    chartStartDate,
                    chartEndDate,
                    stepLength,
                    stormBounds.start,
                    stormBounds.end,
                ),
                color: SLI_COLOR_DECOMP_GROSS_II,
                plotUnit: flowUnitOfMeasure,
            });
        }

        if (basinStormResult.grossQHydrograph && basinStormResult.grossQHydrograph.length > 0) {
            seriesData.push({
                name: grossQLabel,
                type: 'line',
                yAxis: 0,
                threshold: 0,
                data: StormDecompositionHydrographComponent.GetComputedData(
                    basinStormResult.grossQHydrograph,
                    chartStartDate,
                    chartEndDate,
                    stepLength,
                ),
                color: SLI_COLOR_DECOMP_GROSS_Q,
                plotUnit: flowUnitOfMeasure,
            });
        }

        if (basinStormResult.netIIHydrograph && basinStormResult.netIIHydrograph.length > 0) {
            seriesData.push({
                name: netIILabel,
                type: 'line',
                yAxis: 0,
                threshold: 0,
                data: StormDecompositionHydrographComponent.GetComputedData(
                    basinStormResult.netIIHydrograph,
                    chartStartDate,
                    chartEndDate,
                    stepLength,
                    stormBounds.start,
                    stormBounds.end,
                ),
                color: SLI_COLOR_DECOMP_NET_II,
                plotUnit: flowUnitOfMeasure,
            });
        }

        if (basinStormResult.modeledPrecomp && basinStormResult.modeledPrecomp.length > 0) {
            seriesData.push({
                id: 'modeledPrecomp',
                name: modeledPrecompensationLabel,
                type: 'line',
                yAxis: 0,
                threshold: 0,
                data: StormDecompositionHydrographComponent.GetComputedData(
                    basinStormResult.modeledPrecomp,
                    chartStartDate,
                    chartEndDate,
                    stepLength,
                    stormBounds.start,
                    stormBounds.end,
                ),
                color: SLI_COLOR_MODELEDPRECOMP,
                visible: modeledPrecompensationVisible,
                plotUnit: flowUnitOfMeasure,
            });
        }

        // need to null last day dry day values, to have empty graph for last day
        // as starting points for null days we use grossQ and gross I/I series values
        let maxDate: number;
        const grossIISeries = seriesData.find(v => v.name === grossIILabel);
        const grossQSeries = seriesData.find(v => v.name === grossQLabel);

        if (grossIISeries && grossIISeries.data && grossIISeries.data.length) {
            maxDate = grossIISeries.data[grossIISeries.data.length - 1][0];     // data is [timestamp, value] format
        }

        if (grossQSeries && grossQSeries.data && grossQSeries.data.length) {
            const max = grossQSeries.data[grossQSeries.data.length - 1][0];     // data is [timestamp, value] format

            maxDate = Math.max(maxDate, max);
        }

        if (dayOfWeekGroupDays && dayOfWeekGroupDays.length > 0) {
            const series = StormDecompositionHydrographComponent.BuildDryDaySeries(
                sliicerService,
                chartStartDate,
                chartEndDate,
                dayOfWeekGroupDays,
                dayOfWeekGroups,
                stepLength,
                dryDayFlowLabel,
                flowUnitOfMeasure,
                studySeasons,
                studyRegimes,
                hasSeasons, hasRegimes, hasYears
            );

            // actual filtering of dry days data as mentioned above
            series.forEach(serie => {
                if (!series || !serie.data || !serie.data.length) {
                    return;
                }

                serie.data = serie.data.map(v => {
                    if (v.x > maxDate) {
                        v.y = null;
                    }

                    return v;
                });
            });

            for (const s of series) {
                seriesData.push(s);
            }
        }

        return seriesData;
    }

    private static getDryDayGroupByDay(
        day: string,
        weekGroupDays: WeekGroup[],
        dryDayGroups: DayOfWeekGroupDays[],
        studySeasons: Option<Season[]>,
        studyRegimes: Regime[],
        hasSeasons: boolean, hasRegimes: boolean, hasYears: boolean
    ) {
        const dayIndex = moment(day).day();
        const weekGroup = weekGroupDays.find((dowg) => dowg.days.indexOf(dayIndex) !== -1);

        let filteredGroups = dryDayGroups.filter(group => group.dayOfWeekGroupName === weekGroup.name);

        if (hasYears) {
            const currentYear = new Date(day).getFullYear();
            filteredGroups = filteredGroups.filter(v => v.year === 'All' || v.year === String(currentYear));
        }

        if (hasRegimes) {
            const currentTs = new Date(day).getTime();
            const currentRegime = studyRegimes.find(v => currentTs >= new Date(v.periodStart).getTime() && currentTs <= new Date(v.periodEnd).getTime());

            filteredGroups = filteredGroups.filter(v => v.regime === 'All' || (currentRegime && v.regime === currentRegime.name));
        }

        if (hasSeasons && studySeasons) {
            const currentTs = new Date(day).getTime();
            const currentSeason = studySeasons['value'].find((season: Season) => currentTs >= new Date(season.periodStart).getTime() && currentTs <= new Date(season.periodEnd).getTime());

            filteredGroups = filteredGroups.filter(v => v.season === 'All' || v.season === currentSeason.name);
        }

        return filteredGroups;
    }

    private static BuildDryDaySeries(
        sliicerService: SliicerService,
        chartStartDate: Date,
        chartEndDate: Date,
        dayOfWeekGroupDays: DayOfWeekGroupDays[],
        dayOfWeekGroups: WeekGroup[],
        stepLength: number,
        dryDayFlowLabel: string,
        flowUnitOfMeasure: string,
        studySeasons: Option<Season[]>,
        studyRegimes: Regime[],
        hasSeasons: boolean, hasRegimes: boolean, hasYears: boolean
    ) {
        const dateStartEndRange = StormDecompositionHydrographComponent.GetDatesBetween(
            chartStartDate as any,
            chartEndDate as any,
        );

        let serieName = '';

        const useSeasons = sliicerService.hasSeasons();
        const useRegimes = sliicerService.hasRegimes();
        const useYears = sliicerService.hasYears();

        const allData: {}[][] = [];
        const allDataColor = [];

        let lastPoint = null;
        let lastSerieName = null;

        dateStartEndRange.forEach((day) => {
            const dayIndex = moment(day).day();
            const weekGroup = dayOfWeekGroups.find((dowg) => dowg.days.indexOf(dayIndex) !== -1);
            if (!weekGroup) {
                return;
            }

            const dryDayGroups = this.getDryDayGroupByDay(day, dayOfWeekGroups, dayOfWeekGroupDays, studySeasons, studyRegimes, hasSeasons, hasRegimes, hasYears);

            if (!dryDayGroups || dryDayGroups.length === 0) {
                return;
            }

            let dayOfWeekGroup = dryDayGroups[0];

            const allKeys = Object.keys(dayOfWeekGroup);
            const dayOfWeekGroupIndex = dayOfWeekGroupDays.findIndex(v => allKeys.every(k => v[k] === dayOfWeekGroup[k]));
            const useAlt = dayOfWeekGroup.useAlt;


            const weekDayGroup = dayOfWeekGroupDays[dayOfWeekGroupIndex];
            const weekGroupName = SliicerUtils.weekGroupName(weekDayGroup, useSeasons, useRegimes, useYears);
            const color = SliicerUtils.weekGroupColor(weekGroupName, dayOfWeekGroupIndex);

            if (useAlt) {
                const altDayType = dayOfWeekGroup.altDayType;
                const altRegime = dayOfWeekGroup.altRegime;
                const altSeason = dayOfWeekGroup.altSeason;
                const altYear = dayOfWeekGroup.altYear;

                const index = dayOfWeekGroupDays.findIndex((d) =>
                    d.dayOfWeekGroupName === altDayType
                    && d.regime === altRegime
                    && d.season === altSeason
                    && d.year === altYear
                );

                if (index < 0) {
                    console.error(`Cannot find alternate dry days for ${day} --> alternate day=${altDayType} regime=${altRegime} season=${altSeason} year=${altYear}`);
                    return;
                }

                serieName = `${altRegime && altRegime !== 'All' ? altRegime : ''} ${altYear && altYear !== 'All' ? altYear : ''} ${altSeason && altSeason !== 'All' ? altSeason : ''} ${altDayType && altDayType !== 'All' ? altDayType : ''} (Alt)`;
                dayOfWeekGroup = dayOfWeekGroupDays[index];
            } else {
                if(weekDayGroup.year === 'All' && weekDayGroup.regime === 'All' && weekDayGroup.season === 'All') {
                    // #33043 When no year, seasons or regime selected or available to select, we should not display "All" in the label
                    serieName = weekGroup.name;
                } else {
                    // #33652 Label should point to day group with seasons/regimes/years
                    serieName = weekGroupName;
                }
            }

            const currentDay = moment(day).toDate();
            const nextDate = moment(currentDay).add(1, 'day');
            const nextDay = moment(nextDate).toDate();
            const data = (StormDecompositionHydrographComponent.GetComputedData(
                dayOfWeekGroup.stats.grossDiurnalCurveData,
                currentDay,
                nextDay,
                stepLength,
                0,
                Number.MAX_SAFE_INTEGER,
                color,
            ) as { x: number; y: number; segmentColor: string }[]);

            if (!allData[serieName]) {
                allData[serieName] = [];
                allDataColor[serieName] = color;
            }

            if (lastPoint) {
                // if we have breaks in the line, we need to fill the break with null Y values, so it will not connect the ends
                const currentSeriesData = allData[serieName];
                if (currentSeriesData.length) {
                    const stepLengthInMin = stepLength * 1000 * 60;
                    const lastPointOfCurrentSeries = currentSeriesData[currentSeriesData.length - 1].x;

                    // we recognize the breaks if the difference is more than study step length
                    const diff = data[0].x - lastPointOfCurrentSeries;

                    if (diff > stepLengthInMin) {
                        // we populate null for Y value for each step within the break
                        const diffInStepLength = diff / stepLengthInMin;

                        const nullValuesToInsert = [];
                        const pointCopy = { ...data[0], y: null };
                        let currentTs = currentSeriesData[currentSeriesData.length - 1].x + stepLengthInMin;
                        for(let i = 0; i < diffInStepLength; i ++) {
                            nullValuesToInsert.push({ ...pointCopy, x: currentTs });
                            currentTs += stepLengthInMin;
                        }

                        allData[serieName].push(...nullValuesToInsert);
                    }
                }

                if (serieName === lastSerieName) {
                    allData[serieName].push(lastPoint);
                } else {
                    allData[lastSerieName].push(data[0]);
                }
            }
            allData[serieName].push(...data);

            lastPoint = data[data.length - 1];
            lastSerieName = serieName;
        });

        const returnSeries = [];
        for (const k in allData) {
            const d = allData[k];
            const c = allDataColor[k];
            const s = {
                name: k,
                seriesName: dryDayFlowLabel + ' ' + k,
                color: c,
                type: 'line',
                yAxis: 0,
                threshold: 0,
                data: d,
                plotUnit: flowUnitOfMeasure,
                lineWidth: 2,
                label: {
                    // #33313 we outline Weekdays label for readability purposes
                    useHTML: this.name === WEEKDAYS,
                    formatter: function() {
                        if (this.name === WEEKDAYS) {
                            return `<span style ='text-shadow: -0.75px -0.75px 0 #000, 0.75px -0.75px 0 #000, -0.75px 0.75px 0 #000, 0.75px 0.75px 0 #000'>${this.name}</span>`
                        }

                        return this.name;
                    }
                }
            };

            returnSeries.push(s);
        }
        return returnSeries;
    }

    private static GetDatesBetween(startDt: string, endDt: string): Array<string> {
        const dateList = [];
        let currDate = +moment(startDt).valueOf();
        const lastDate = +moment(endDt).valueOf();
        while (currDate < lastDate) {
            dateList.push(moment(currDate).format('YYYY-MM-DDTHH:mm:ss'));
            currDate = +moment(currDate).add(1, 'day').valueOf();
        }
        return dateList;
    }

    private static GetComputedData(
        data: number[],
        chartStartDate: Date,
        chartEndDate: Date,
        stepLength: number,
        zeroBeforeTimestamp = 0,
        zeroAfterTimestamp = Number.MAX_SAFE_INTEGER,
        withColor = '',
    ): Array<Array<number>> | { x: number; y: number; segmentColor: string }[] {
        const chartData = [];
        let startTimeStamp = moment(chartStartDate).valueOf();
        const endTimeStamp = moment(chartEndDate).valueOf();
        if (data && data.length > 0) {
            data.forEach((val) => {
                if (startTimeStamp >= endTimeStamp) {
                    return;
                }
                const y = startTimeStamp < zeroBeforeTimestamp ? 0 : startTimeStamp > zeroAfterTimestamp ? 0 : val;
                if (withColor !== '') {
                    chartData.push({
                        x: startTimeStamp,
                        y: y,
                        segmentColor: withColor,
                    });
                } else {
                    chartData.push([startTimeStamp, y]);
                }
                startTimeStamp = +moment(startTimeStamp).add(stepLength, 'minutes');
            });
        }
        return chartData;
    }
}
