import { Coordinate } from './coordinate';
import { Entries } from 'app/shared/models/add-location-channel';
import { LocationStatus } from './locations';
import { DepthDeviceType, VelocityDeviceType } from '../enums/device-type-code';
import { ConfirmationReportTypes } from './confirmation-points';

export interface InstallationType {
    series?: number;
    text: string;
    value: string;
    unpickable?: boolean;
}

export interface LocationDetailsModel {
    locationDetails: LocationDetails;
    visibleLocations: Array<number>;
    isRefreshLocations?: boolean;
}

export interface SlimLocationDetails {
    assignedRgID: number;
    customerID: number;
    installationType: number;
    locationId: number;
    locationName: string;
    locationType: number;
    qFinalEntity: number;
}

export interface LocationDetails {
    assignedRainGaugeLocationId?: number;
    connectionString?: string;
    description: string;
    series: string;
    serialNumber: string;
    isActive?: boolean; // TODO: REMOVEIT: it is here ONLY because the MAP's property inspector. Once this flag will be removed - delete it from here
    status: LocationStatus;
    maintenanceInterval?: number;
    maintenanceEnd?: string;
    manholeAddress: string;
    coordinate: Coordinate;
    communicationType?: number;
    locationID: number;
    locationName: string;
    ipaddress: string;
    monitorDeploymentID?: number;
    referencePoint?: number;
    datum?: string;
    physicalOffset?: number;
    installationId?: number;
    installationHeight?: number;
    installationWidth?: number;
    installationType?: string;
    installationShape?: string;
    range?: number;
    capacity?: number;
    width?: number;
    installationShapeTypeID?: number;
    manholedepth?: number;
    length?: number;
    breadth?: number;
    coefficient?: number;
    assignedRainGaugeLocationName?: string;
    entries?: Entries[];
    depthUnit?: string;
    flowUnit?: string;
    locationType?: number;
    components?: Array<CompositeLocationComponentModel>;
    lastCollectedDate: string;
    qFinalEntityId?: number;
    height?: number;
    pipe?: any;
    flume?: any;
    weir?: any;
    locationid?: number;
    locationname?: string;
    installationid?: number;
    installationtypeid?: number;
    MonitoringPoint?: number;
    modem?: boolean;
    stevensSchutzbachHC?: number;
    drain?: number;
    r2?: number;
    qFinalEntityID?: number;
    telemetryStart?: Date;
    telemetryEnd?: Date;
    longitude?: number;
    latitude?: number;
    installationID?: number;
    manholeDepth?: number;
    installationDate?: string | Date;
    usgsid?: string;
    externalVendorID?: number;

    hc?: number;
    designHC?: number; // designHC for read, hc for write
    slope?: number;
    designSlope?: number; // designSlope for read, slope for write
    roughness?: number;
    designRoughness?: number; // designRoughness for read, roughness for write
    effectiveRoughness?: number;

    enableSelectOnMap?: boolean;

    customProperties?: { key: string; value: string }[];

    depthSensor?: DepthDeviceType;
    velocitySensor?: VelocityDeviceType;
}
export interface FlowBalanceDetails {
    flowBalanceLocation: string;
    flowBalanceLocationId: number;
    flowType: number;
    order?: number;
}
export interface MonitorSeriesUI {
    monitorSeriesID: number;
    modelName: string;
    series: number;
    model: number;
    dataCollectRetries: number;
    connectWaitPeriod: number;
    isDefault: boolean;
    batteryLowInt: number;
    batteryCriticalInt: number;
    batteryLowExternal: number;
    batteryCriticalExternal: number;
    batteryCriticalTcpIp: number;
    tempLow: number;
    tempHigh: number;
    signalLowTcpIp: number;
    ftpFileType: number;
}

/**
 * create the enum to get installationTypeId based on installationType
 */
export enum DefinedInstallationType {
    Pipe = 1,
    Flume = 2,
    Weir = 3,
    Channel = 4,
    RainGauge = 5,
    Composite = 6,
    WetWell = 7,
    Pseudo = 8,
    General = 9,
    Elevation = 10,
    Unsupported = 99,
}

/**
 * Mapings of Monitor Series identifier and the allowed installation type series.
 */
export interface MonitorInstallationMap {
    /**
     * Represents the monitor series identifier.
     */
    monitorSeriesId: number;

    /**
     * Represents a collection of installation type series identifiers.
     */
    installationTypeSeries: Array<number>;
}

export interface CompositeLocationComponentModel {
    locationID: number;
    multiplier?: number;
    operator?: number;
}

export interface CustomerUnits {
    depthUnit: string;
    flowUnit: string;
    volumeUnit: string;
    sliicerVolumeUnit: string;
    sliicerFlowUnit: string;
    velocityUnit: string;
    flowSmallerUnit: string;
}

export interface ConfirmationResponsePayload {
    c: Confirmation[];
    dp: number;
    du: string;
    fp: number;
    fu: string;
    vp: number;
    vu: string;
    avgdd: number;
    avgcg: number;
    avgs: number;
}

export interface Confirmation {
    calculatedFlow: number;
    comment: string;
    confirmationDate: string;
    depth: number;
    flow: number;
    id?: string;
    velocity: number;
}

export const INSTALLATION_TYPE = {
    CHANNEL: 'Channel',
    FLUME: 'Flume',
    WEIR: 'Weir',
    RAIN: 'Rain',
    PIPE: 'Pipe',
    ELEVATION: 'Elevation',
};

export enum InstallationSeries {
    Pipe = 200,
    Weir = 300,
    Flume = 400,
    Channel = 500,
    Rain = 800,
    Elevation = 1000
};


export interface LocationCosmosPipeTable {
    d: number;
    a: number;
    p: number;
    c: number;
}

export interface LocationUIPipeTable extends LocationCosmosPipeTable {
    x?: number;
    y?: number;
}

export interface LocationCosmosPipe {
    e: LocationCosmosPipeTable[];
    r: number;
    s: number;
    er: number;
    hc: number;
    ff: number;
    ht: number;
    w: number;
    dr: number;
    r2: number;
    sshc: number;
}

export interface LocationCosmosDetails {
    type: string;
    cid: number;
    pk: string;
    lid: number;
    id: string;
    series: string;
    sn: string;
    status: number;
    desc: string;
    a1: string;
    lat: number;
    lon: number;
    md: number;
    name: string;
    ct: number;
    cs: string;
    lt: number;
    it: number;
    ist: number;
    lcd: Date;
    c: boolean;
    qeid: number;
    elev: number;
    msmid: number;
    cfi: boolean;
    mp: number;
    pipe: LocationCosmosPipe;
    devs: number[];
    _etag: string;
    ttl: number;
    _ts: number;
}

export interface ConfirmationReportGetParameters {
    locationIds: number[];
    includeInactiveLocations?: boolean;
    startTime?: string;
    endTime?: string;
    paging?: {
        pageSize?: number;
        startPage?: number;
    }
    reportType?: ConfirmationReportTypes
    includeIgnored?: boolean;
}

export interface ConfirmationReportResponseRowMonitorReading {
    ts: string;
    dr: number;
    dd: number;
    vr: number;
    vd: number;
    cg: number;
    qr: number;
    qd: number;
}

export interface ConfirmationReportResponseRowMonitor {
    before: ConfirmationReportResponseRowMonitorReading;
    closest: ConfirmationReportResponseRowMonitorReading;
    after: ConfirmationReportResponseRowMonitorReading;
    avgd: number;
    avgdd: number;
    avgv: number;
    avgvd: number;
    avgcg: number;
}

export interface ConfirmationReportResponseRow {
    id: string;
    name: string;
    time: string;
    depth: number;
    depthacc: number;
    vel: number;
    velacc: number;
    flow: number;
    calcflow: number;
    comment: string;
    silt: number;
    atp: number;
    ignore: boolean;
    monitor: ConfirmationReportResponseRowMonitor;
}

export interface ConfirmationReportResponse {
    depthEntity: string;
    depthUnit: string;
    depthPrecision: number;
    velocityEntity: string;
    velocityUnit: string;
    velocityPrecision: number;
    flowEntity: string;
    flowUnit: string;
    flowPrecision: number;
    avgdd: number;
    avgs: number;
    avgcg: number;
    c: ConfirmationReportResponseRow[];
    count: number;
}

export interface ConfirmationReportUIRow {
    data?: ConfirmationReportUIRowDetails[];
    atp?: number;
    units?: ConfirmationUnitsAndAverages;
}

export interface ConfirmationReportUIRowDetails {
    id?: string;
    time?: Date;
    type: string;
    depth: number;
    depth_diff?: number;
    depthacc?: number;
    peak_velocity?: number;
    ap_ratio?: number;
    calculated_avg_velocity?: number;
    velocity_diff?: number;
    velacc?: number;
    calculatedGain?: number;
    silt?: number;
    measured_flow?: number;
    calculated_flow?: number;
    quantity?: number;
    quantity_diff?: number;
    ignore?: boolean;
    comment?: string;
}

export interface ConfirmationUnitsAndAverages {
    dp: number;
    du: string;
    fp: number;
    fu: string;
    vp: number;
    vu: string;
    avgdd: number;
    avgcg: number;
    avgs: number;
}

export interface ConfirmationReportUIFormatted {
    data: ConfirmationReportUIRow[];
    unitsAndAverages: ConfirmationUnitsAndAverages;
    count: number;
}

export const DEFAULT_AVERAGE_TO_PEAK = 0.9;

export interface CumulativeRainRequest {
    customerId: number;
    locationIds: number[];
    start: string;
    end: string;
    includeInactiveLocations: boolean;
    entityIds?: number[];
}

export interface CumulativeRainResponseLocation {
    locationId: number;
    locationName: string;
    rain: number;
    isActive: boolean;
}


export interface CumulativeRainResponse {
    unitFactor: number;
    unitTitle: string;
    unitPrecision: number;
    count: number;
    locations: CumulativeRainResponseLocation[];
}
INSTALLATION_TYPE
