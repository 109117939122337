export class CreateVariable {
    public locationId: number;
    public entityId: number;
    public variableName: string;
    public orderId: number;
    public manningRoughness?: number;
    public manningSlope?: number;
    public depthEntityId?: number;
    public timeInterval?: TimeInterval;
}

export class CalculatedBaseEntity {
    public formulaEntities: Array<CreateVariable>;
    public displayGroupId: number;
    public locationId: number;
    public equation: string;
    public unitId: number;
    public colorPicker: string;
    public name: string;
    public description: string;
    public startDate?: Date;
    public endDate?: Date;
    public formulaType: string;
    public calculatedEntityId: number;
}

export class TimeInterval {
    public days?: number;
    public hours?: number;
    public minutes?: number;
}

export class CalculatedEntityDetails {
    public calculatedEntityId: number;
    public name: string;
    public description: string;
}

export enum CALCULATION_TYPE_DEFAULTS {
    DEFAULT_MANNING_ROUGHNESS = 0.013,
    MANNING_ROUGHNESS_START_RANGE = 0.009,
    MANNING_ROUGHNESS_END_RANGE = 0.015,
}

export interface Element {
    id: number;
    name: string;
    location_name: string;
    description: string;
}

export enum CalculationType {
    FORMULA = 'FORMULA',
    CUMULATIVE = 'CUMULATIVE',
    MANNING = 'MANNING',
    SUM = 'SUM',
    ROLLING = 'ROLLINGSUM',
    DELTA = 'DELTA',
    REPEAT = 'REPEAT',
    DISCRETE = 'DISCRETE',
    INTERPOLATE = 'INTERPOLATE',
    AVERAGE = 'ROLLINGAVERAGE',
    MEDIAN = 'MEDIAN',
    CONSTANT = 'CONSTANT',
    MINIMUM = 'MINIMUM',
    MAXIMUM = 'MAXIMUM',
    SHIFT = 'SHIFT',
    STEP = 'STEP',
    WEIGHTED = 'WEIGHTED',
}

export const ELEMENT_DATA: Element[] = [
    {
        id: 1,
        name: 'Entity 1',
        location_name: 'location 1',
        description: 'Lorem Ipsum is simply dummy text of the printing',
    },
    {
        id: 1,
        name: 'Entity 2',
        location_name: 'location 2',
        description: 'Lorem Ipsum is simply dummy text',
    },
    {
        id: 1,
        name: 'Entity 3',
        location_name: 'location 3',
        description: 'Lorem Ipsum is simply dummy text',
    },
    {
        id: 1,
        name: 'Entity 4',
        location_name: 'location 4',
        description: 'Lorem Ipsum is simply dummy text of the printing',
    },
    {
        id: 1,
        name: 'Entity 5',
        location_name: 'location 4',
        description: 'Lorem Ipsum is simply dummy text of the printing',
    },
    {
        id: 1,
        name: 'Entity 6',
        location_name: 'location 4',
        description: 'Lorem Ipsum is simply dummy text of the printing',
    },
    {
        id: 1,
        name: 'Entity 7',
        location_name: 'location 4',
        description: 'Lorem Ipsum is simply dummy text of the printing',
    },
];
