import { assertNever } from 'app/pages/sliicer/shared/utils/general-utils';
import { SeasonType } from 'app/shared/models/sliicer';
import { none, Option, some } from 'fp-ts/es6/Option';
import moment, { max, min } from 'moment';
import { Season } from '../seasons-settings.utils';

export type SeasonItem = { name: string; tag: string; size: number; offset: number };
export type YearMark = { year: string; offset: number };
export type StudyDateMark = { date: string; offset: number };
export type TimelineState = {
    items: SeasonItem[];
    years: YearMark[];
    start: Option<StudyDateMark>;
    end: Option<StudyDateMark>;
};

const millisecondsInDay = 86400000;

// converts milliseconds to days, rounding to the nearest full day
function getDays(milliseconds: number): number {
    return Math.round(milliseconds / millisecondsInDay);
}

function enumerateYears(from: moment.Moment, to: moment.Moment): moment.Moment[] {
    const years: moment.Moment[] = [];

    const currDate = moment(from).startOf('year');
    while (currDate.add(1, 'year') < to) {
        years.push(currDate.clone());
    }

    return years;
}

export function buildTimeline(
    seasonType: SeasonType,
    startDate: string,
    endDate: string,
    seasons: Season[],
): TimelineState {
    const startDateM = moment(startDate).startOf('day');
    const endDateM = moment(endDate).endOf('day');

    const firstSeasonStartM = seasons.length > 0 ? moment(seasons[0].periodStart).startOf('day') : startDateM;
    const lastSeasonEndM = seasons.length > 0 ? moment(seasons[seasons.length - 1].periodEnd).endOf('day') : endDateM;

    const totalLength = getDays(max(endDateM, lastSeasonEndM).valueOf() - min(startDateM, firstSeasonStartM).valueOf());
    const yearMarks: YearMark[] = enumerateYears(min(startDateM, firstSeasonStartM), max(endDateM, lastSeasonEndM)).map(
        (date) => {
            const offsetLength = getDays(date.valueOf() - min(startDateM, firstSeasonStartM).valueOf());

            return {
                year: date.format('YYYY'),
                offset: (offsetLength * 100) / totalLength,
            };
        },
    );

    const startDateMark: Option<StudyDateMark> =
        startDateM > firstSeasonStartM
            ? some({
                  date: startDate,
                  offset: (getDays(startDateM.valueOf() - firstSeasonStartM.valueOf()) * 100) / totalLength,
              })
            : none;

    const endDateMark: Option<StudyDateMark> =
        endDateM < lastSeasonEndM
            ? some({
                  date: endDate,
                  offset: ((totalLength - getDays(lastSeasonEndM.valueOf() - endDateM.valueOf())) * 100) / totalLength,
              })
            : none;

    const r = seasons.reduce(
        ({ result, year, tagIndex, m_date }, x) => {
            const nextTagIndex = seasonType === SeasonType.Year || x.year === year ? (tagIndex % 12) + 1 : 1;
            const periodLength = getDays(
                moment(x.periodEnd).endOf('day').valueOf() - moment(x.periodStart).startOf('day').valueOf(),
            );
            const offsetLength = getDays(moment(x.periodStart).startOf('day').valueOf() - m_date.valueOf());

            const tagFor = (st: SeasonType, x: Season) => {
                switch (st) {
                    case SeasonType.Month:
                    case SeasonType.Quarter:
                    case SeasonType.Meteorological:
                    case SeasonType.Custom:
                        return `season-${x.order}`;
                    case SeasonType.Year:
                        return `season-${x.order} season-year`;
                    case SeasonType.None:
                        return '';
                    default:
                        assertNever(st);
                }
            };

            return {
                result: [
                    ...result,
                    {
                        name: seasonType === SeasonType.Year ? x.year.toString() : x.name,
                        tag: tagFor(seasonType, x),
                        size: (periodLength * 100) / totalLength,
                        offset: (offsetLength * 100) / totalLength,
                    },
                ],
                year: x.year,
                tagIndex: nextTagIndex,
                m_date: moment(x.periodEnd).endOf('day'),
            };
        },
        { result: <SeasonItem[]>[], year: <number>null, tagIndex: 0, m_date: firstSeasonStartM },
    );

    return {
        items: r.result,
        years: yearMarks,
        start: startDateMark,
        end: endDateMark,
    };
}
