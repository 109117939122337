import { Component, ChangeDetectorRef, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { SliicerCaseStudy } from 'app/shared/models/sliicer';
import { LocationGroupMonitorSummary, MonitorCounts } from 'app/shared/models/sliicer-data';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { AdsDetailsDialogComponent } from '../details-dialog/details-dialog.component';
import { filter } from 'rxjs/operators';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { Monitor } from 'app/shared/models/sliicer/config';
import { MonitorName } from 'app/shared/models/sliicer-data';

@Component({
    selector: 'ads-sliicer-study-config',
    templateUrl: './config.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SliicerCaseStudyConfigComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    private locationGroupId: number;

    public dateFormat: string;
    public author: string;
    public createDate: string;
    public modifiedDate: string;
    public studyName: string;
    public studyDescription: string;
    public locationGroupName: string;
    public flowMonitorNames: Array<string> = [];
    public flowMonitorDetails: Array<MonitorName> = [];
    public rainflowMonitorDetails: Array<MonitorName> = [];
    public flowMonitorCount = 0;
    public rainfallMonitorNames: Array<string> = [];
    public rainfallMonitorCount = 0;
    public startDate: string;
    public endDate: string;
    public daysInStudy: number;
    public stepLength: number;

    constructor(
        private sliicerService: SliicerService,
        private dialog: MatDialog,
        private dateUtilService: DateutilService,
        private utilService: UiUtilsService,
        private locationGroupService: LocationGroupService,
        public cdr: ChangeDetectorRef,
    ) {}

    public ngOnInit() {
        this.subscriptions.push(
            this.dateUtilService.dateFormat.subscribe((newDateFormat) => {
                this.dateFormat = this.dateUtilService.getStringFormat(newDateFormat);
            }),
        );

        this.subscriptions.push(
            this.sliicerService.caseStudyDetails
                .pipe(filter((data) => !!data))
                .subscribe((caseStudyData: SliicerCaseStudy) => {
                    if (caseStudyData.meta) {
                        this.author = caseStudyData.meta.authors? caseStudyData.meta.authors[0].name : '';
                        this.createDate = caseStudyData.meta.created ? caseStudyData.meta.created.toString() : '';
                        this.modifiedDate = caseStudyData.meta.lastModified ? caseStudyData.meta.lastModified.toString() : '';
                        this.studyName = caseStudyData.meta.name;
                        this.studyDescription = caseStudyData.meta.desc;
                    }

                    if (caseStudyData.config) {
                        this.locationGroupId =
                            caseStudyData.config && caseStudyData.config.locationGroup !== null
                                ? caseStudyData.config.locationGroup.id
                                : 0;
                        this.setLocationName(caseStudyData.customerId, this.locationGroupId);

                        this.flowMonitorNames =
                            caseStudyData.config.flowMonitors !== null
                                ? caseStudyData.config.flowMonitors.map((fm) => fm.name)
                                : [];

                        this.flowMonitorDetails = caseStudyData.config.flowMonitors !== null ? caseStudyData.config.flowMonitors : [];
                        this.flowMonitorCount = this.flowMonitorNames.length;
                        // tslint:disable-next-line: max-line-length
                        this.rainfallMonitorNames =
                            caseStudyData.config.rainfallMonitors !== null
                                ? caseStudyData.config.rainfallMonitors.map((rm) => rm.name)
                                : [];

                        this.rainflowMonitorDetails = caseStudyData.config.rainfallMonitors !== null ? caseStudyData.config.rainfallMonitors : [];
                        this.rainfallMonitorCount = this.rainfallMonitorNames.length;

                        this.startDate = caseStudyData.config.startDate.toString();
                        this.endDate = caseStudyData.config.endDate.toString();

                        this.daysInStudy = SliicerService.GetDaysInStudy(
                            caseStudyData.config.startDate,
                            caseStudyData.config.endDate,
                        );
                        this.stepLength = caseStudyData.config.stepLengthMinutes;
                    }
                    this.utilService.safeChangeDetection(this.cdr);
                }),
        );
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscripton) => subscripton.unsubscribe());
    }

    /**
     * Method triggered when user clicks "Details" to find out about the flow and rainfall monitors included in the study
     */
    public showMonitorDetails() {

        if(this.flowMonitorNames && this.flowMonitorNames.length > 0)
        {
            this.flowMonitorNames.sort();
        }

        if(this.rainfallMonitorNames && this.rainfallMonitorNames.length > 0)
        {
            this.rainfallMonitorNames.sort();
        }

        const summary: LocationGroupMonitorSummary = {
            fetched: false,
            locationGroupId: this.locationGroupId,
            locationGroupName: this.locationGroupName,
            flowMonitorNames: this.flowMonitorDetails,
            rainfallMonitorNames: this.rainflowMonitorDetails,
            levelMonitorNames: null,
        };
        this.dialog
            .open(AdsDetailsDialogComponent, {
                disableClose: true,
                data: summary,
            })
            .afterClosed();
    }

    private setLocationName(customerId: number, locationGroupId: number) {
        if (locationGroupId === 0) {
            this.locationGroupName = 'All Locations';
            return;
        } else {
            this.subscriptions.push(
                this.locationGroupService.getLocationGroups(customerId).subscribe((result) => {
                    if (result && result.locationGroups) {
                        const loc = result.locationGroups.find((x) => x.locationGroupID === locationGroupId);
                        if (loc) {
                            this.locationGroupName = loc.name;
                            this.utilService.safeChangeDetection(this.cdr);
                        }
                    }
                }),
            );
        }
    }
}
