<div id="sliicerMarketingDialog">
    <div class="marketingImg">
        <div class="marketingHeader">
            <div class="big">
                {{ 'SLICER_PAGE.SLIICER_WELCOME_MESSAGE' | translate }} <b> {{ 'SLICER_PAGE.SLIICER' | translate }}</b
                >&reg;.
            </div>
            <div class="normal">
                {{ 'SLICER_PAGE.WORKING_HARD_SLIICER_PRISM' | translate }}
            </div>
        </div>
    </div>
    <div mat-dialog-content>
        <h3>{{ 'SLICER_PAGE.SLIICER_ENABLE_USERS' | translate }}</h3>
        <ul>
            <li>{{ 'SLICER_PAGE.SLIICER_CHARACTERIZE_WEATHER' | translate }}</li>
            <li>{{ 'SLICER_PAGE.SLIICER_TARGET_INFILTRATION' | translate }}</li>
            <li>{{ 'SLICER_PAGE.SLIICER_HYDRAULIC_MODELING' | translate }}</li>
        </ul>
        {{ 'SLICER_PAGE.CONTACT_ADS' | translate }}
    </div>
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="close(false)">{{ 'COMMON.CLOSE' | translate }}</button>
        </div>
    </div>
</div>
