import { AddLocationPipes } from 'app/shared/models/add-location-pipes';
import { AddLocationRain } from 'app/shared/models/add-location-rain';
import { AddLocationFlume } from 'app/shared/models/add-location-flume';
import { AddLocationWeir } from 'app/shared/models/add-location-weir';
import { AddLocationChannel } from 'app/shared/models/add-location-channel';
import { Locations, LocationStatus } from 'app/shared/models/locations';
import { LocationDetails } from './location-details';
import { AddLocationElevation } from './add-location-elevation';

export class AddLocation {
    public locationName: string;
    public description?: string;
    public latitude?: number;
    public longitude?: number;
    public isActive?: boolean; // TODO: CURRENTUS: Remove it
    public status?: LocationStatus;
    public manholeAddress?: string;
    public manholedepth?: number;
    public series: string;
    public serialNumber: string;
    public IPAddress: number;
    public pipe?: AddLocationPipes;
    public rain?: AddLocationRain;
    public flume?: AddLocationFlume;
    public weir?: AddLocationWeir;
    public channel?: AddLocationChannel;
    public assignedRainGaugeId: number;
    public communicationType?: number;
    public installationDate?: Date | string;
}

/**
 * Represents a data model passed into Add Location modal.
 */
export interface AddLocationModalData {
    /**
     * Represents the collection of rain gauge locations.
     */
    rainGaugeLocations: Array<Locations>;

    /**
     * Represents customer id.
     */
    customerId: number;

    /**
     * Represents add location opened from customer editor or not.
     */
    isFromCustomerEditor: boolean;

    isFromNotificationDash?: boolean;

    editMode?: boolean;

    locationDetails?: LocationDetails;

    enableSelectOnMap?: boolean;
}

export interface LocationUpdate {
    locationname: string;
    description: string;
    manholeaddress: string;
    latitude: number;
    longitude: number;
    manholedepth: number;
    isactive: boolean;
    series: string;
    serialnumber: string;
    ipaddress: string;
    locationid: number;
    assignedRainGaugeLocationId?: number;
    assignedraingaugeid?: number;
    communicationType?: number;
    installationtypeid?: number;
    qFinalEntityId?: number;
}

export interface PipeLocationUpdate extends LocationUpdate {
    pipe: {
        hc?: number;
        roughness?: number;
        slope?: number;
        frictionFactor?: number;
        nominalDiameter?: number;
        capacity?: number;
        height?: number;
        width?: number;
        startDate?: Date;
        installationID?: number;
        installationType?: 'Pipe';
        shapeType?: number;
        installationDesc1?: string;
        installationDesc2?: string;
        installationDesc3?: string;
        installationDesc4?: string;
        installationDesc5?: string;
        installationId?: number;
        lookupDesc?: string;
        monitoringPoint?: number;
        wasCreatedFromImport?: true;
        installationtypeid?: number;
        qFinalEntityId?: number;
    };
}

export interface RainLocationUpdate extends LocationUpdate {
    rain: {
        installationID?: number;
        installationType?: 'Pipe';
        shapeType?: number;
        installationDesc1?: string;
        installationDesc2?: string;
        installationDesc3?: string;
        installationDesc4?: string;
        installationDesc5?: string;
        installationId?: number;
        lookupDesc?: string;
        monitoringPoint?: 0;
        wasCreatedFromImport?: true;
        installationtypeid?: number;
    };
}

export interface WeirLocationUpdate extends LocationUpdate {
    weir: {
        range?: number;
        capacity?: number;
        length?: number;
        breadth?: number;
        height?: number;
        coefficient?: number;
        installationID?: number;
        installationType?: 'Pipe';
        shapeType?: number;
        installationDesc1?: string;
        installationDesc2?: string;
        installationDesc3?: string;
        installationDesc4?: string;
        installationDesc5?: string;
        installationId?: number;
        lookupDesc?: string;
        monitoringPoint?: number;
        wasCreatedFromImport?: true;
        installationtypeid?: number;
    };
}

export interface FlumeLocationUpdate extends LocationUpdate {
    flume: {
        range?: number;
        capacity?: number;
        width?: number;
        installationID?: number;
        installationType?: 'Pipe';
        shapeType?: number;
        installationDesc1?: string;
        installationDesc2?: string;
        installationDesc3?: string;
        installationDesc4?: string;
        installationDesc5?: string;
        installationId?: number;
        lookupDesc?: string;
        monitoringPoint?: number;
        wasCreatedFromImport?: true;
        installationtypeid?: number;
    };
}

export interface ChannelLocationUpdate extends LocationUpdate {
    channel?: AddLocationChannel;
}

export interface ElevationLocationUpdate extends LocationUpdate {
    elevation?: AddLocationElevation;
}
