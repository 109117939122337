<div class="rollback-editor-dialog">
    <app-customized-confirmation-box *ngIf="showConfirmation"></app-customized-confirmation-box>
    <app-loader [isLoading]="isLoading"></app-loader>
    <div class="popupHeader title" mat-dialog-title>
        <span> {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.TITLE' | translate }}</span>
    </div>
    <div mat-dialog-content id="rollback-content" class="rollback-content">
        <div class="filter-wrapper">
            <div class="info-text">
                <span>Click the checkboxes located on the left side to revert multiple edits or revert individual edits
                    by selecting <mat-icon>replay</mat-icon> from the right side of each edit</span
                >

                <div class="rawvel-warn" *ngIf="isRawvelSelected()">
                    <b> {{ 'COMMON.WARNING' | translate }}: </b>
                    <span> {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.RAWVEL_WARNING' | translate }}</span>
                </div>
                <button mat-button (click)="domOpsService.rollbackFilterOpened = !domOpsService.rollbackFilterOpened; filterAccordion.toggle()">
                    <mat-icon>filter_list</mat-icon>
                </button>
            </div>
            <mat-accordion class="filters" hideToggle="true">
                <mat-expansion-panel [expanded]="true" #filterAccordion class="filters">
                    <app-multi-select-auto-complete
                        class="entities-filter"
                        [items]="entities"
                        [preselectedItems]="entities"
                        placeholder="{{ 'COMMON.ENTITY' | translate }}"
                        (currentlySelectedItems)="setCurrentlySelectedgroupEntities($event)"
                    ></app-multi-select-auto-complete>
                    <app-date-range-picker
                        class="date-range-filter"
                        [(startDate)]="startDate"
                        [(endDate)]="endDate"
                        [showQuickRanges]="false"
                        (emitDateChanged)="onDateChanged($event)"
                        (emitClose)="onCloseDateMenu()"
                    ></app-date-range-picker>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="rollback-table-wrapper">
            <div class="rollback-table">
                <table mat-table [dataSource]="availablePageHint ? sliceItemsOnHint(dataSource) : dataSource">
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="isAllSelected">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox
                                (click)="$event.stopPropagation()"
                                (change)="$event ? selection.toggle(stringifyDataEditLog(row)) : null"
                                [checked]="selection.isSelected(stringifyDataEditLog(row))"
                            >
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="timestamp">
                        <th mat-header-cell *matHeaderCellDef>{{ 'COMMON.TIMESTAMP' | translate }}</th>
                        <td mat-cell *matCellDef="let row">{{ row.timestamp | customDatePipe: customerDateFormat }}</td>
                    </ng-container>
                    <ng-container matColumnDef="originalValueDepth">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.ORIGINAL_VALUE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="row[depthEntityId]">{{ row[depthEntityId].originalValue }}</span>
                    </td>
                    </ng-container>
                    <ng-container matColumnDef="assignedValueDepth">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.ASSIGNED_VALUE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="row[depthEntityId]">{{ row[depthEntityId].editedValue }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="originalValueVelocity">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.ORIGINAL_VALUE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="row[velocityEntityId]">{{ row[velocityEntityId].originalValue }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="assignedValueVelocity">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.ASSIGNED_VALUE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="row[velocityEntityId]">{{ row[velocityEntityId].editedValue }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="originalValueQuantity">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.ORIGINAL_VALUE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="row[quantityEntityId]">{{ row[quantityEntityId].originalValue | number : (isMetric ? '1.1-1' : '1.3-3') }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="assignedValueQuantity">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.ASSIGNED_VALUE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="row[quantityEntityId]">{{ row[quantityEntityId].editedValue | number : (isMetric ? '1.1-1' : '1.3-3') }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="originalValueRain">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.ORIGINAL_VALUE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="row[rainEntityId]">{{ row[rainEntityId].originalValue }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="assignedValueRain">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.ASSIGNED_VALUE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="row[rainEntityId]">{{ row[rainEntityId].editedValue }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="originalValueElevation">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.ORIGINAL_VALUE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="row[elevationEntityId]">{{ row[elevationEntityId].originalValue }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="assignedValueElevation">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.ASSIGNED_VALUE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="row[elevationEntityId]">{{ row[elevationEntityId].editedValue }}</span>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="originalRawVelocity">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.ORIGINAL_VALUE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="row[rawVelocityEntityId]">{{ row[rawVelocityEntityId].originalValue }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="assignedRawVelocity">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.ASSIGNED_VALUE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="row[rawVelocityEntityId]">{{ row[rawVelocityEntityId].editedValue }}</span>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="originalQA1">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.ORIGINAL_VALUE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="row[entitiesIds.qa1]">{{ row[entitiesIds.qa1].originalValue }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="assignedQA1">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.ASSIGNED_VALUE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="row[entitiesIds.qa1]">{{ row[entitiesIds.qa1].editedValue }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="originalQA2">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.ORIGINAL_VALUE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="row[entitiesIds.qa2]">{{ row[entitiesIds.qa2].originalValue }}</span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="assignedQA2">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.ASSIGNED_VALUE' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <span *ngIf="row[entitiesIds.qa2]">{{ row[entitiesIds.qa2].editedValue }}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="performedBy">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.PERFORMED_BY' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.user }}</td>
                    </ng-container>
                    <ng-container matColumnDef="comments">
                        <th mat-header-cell *matHeaderCellDef>
                            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.COMMENTS' | translate }}
                        </th>
                        <td mat-cell *matCellDef="let row">{{ row.reason }}</td>
                    </ng-container>
                    <ng-container matColumnDef="revert">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef="let row">
                            <button (click)="revertRow(row)" mat-button>
                                <mat-icon>replay</mat-icon>
                            </button>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="empty">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2"></th>
                    </ng-container>
                    <ng-container matColumnDef="depth">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                            {{ 'COMMON.DEPTH' | translate }}
                        </th>
                    </ng-container>
                    <ng-container matColumnDef="velocity">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                            {{ 'COMMON.VELOCITY' | translate }}
                        </th>
                    </ng-container>
                    <ng-container matColumnDef="quantity">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                            {{ 'COMMON.QUANTITY' | translate }}
                        </th>
                    </ng-container>
                    <ng-container matColumnDef="rain">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                            {{ 'COMMON.RAIN' | translate }}
                        </th>
                    </ng-container>
                    <ng-container matColumnDef="elevation">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                            {{ 'COMMON.ElEVATION' | translate }}
                        </th>
                    </ng-container>
                    <ng-container matColumnDef="rawVelocity">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                            {{ 'COMMON.RAW_VELOCITY' | translate }}
                        </th>
                    </ng-container>
                    <ng-container matColumnDef="qa1">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                            {{ 'COMMON.Q_A1' | translate }}
                        </th>
                    </ng-container>
                    <ng-container matColumnDef="qa2">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="2">
                            {{ 'COMMON.Q_A2' | translate }}
                        </th>
                    </ng-container>

                    <ng-container matColumnDef="empty2">
                        <th mat-header-cell *matHeaderCellDef [attr.colspan]="3"></th>
                    </ng-container>
                    <tr class="first-row" mat-header-row *matHeaderRowDef="displayedColumnsFirstRow; sticky: true"></tr>
                    <tr mat-header-row *matHeaderRowDef="displayedColumnsSecondRow; sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsSecondRow" class></tr>
                </table>
            </div>
            <div>
                <div class="mat-paginator-position" [ngClass]="{ 'paginator-on-hint': availablePageHint }">
                    <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 25, 100]" [length]="length" (page)="fetchDataAndUpdateTable()"></mat-paginator>
                    <div [ngClass]="{ 'paginator-hint': availablePageHint }"></div>
                </div>
                <div class="no-rows" *ngIf="dataSource?.data?.length === 0">
                    <span>{{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.NO_ROWS' | translate }}</span>
                </div>
            </div>
        </div>
        <div mat-dialog-actions class="actions">
            <button class="close-btn" mat-button (click)="cancel()">{{ 'COMMON.CLOSE' | translate }}</button>
            <button class="revert-btn" mat-button (click)="revertSelected()" [disabled]="!selection.selected || selection.selected.length === 0">
            {{ 'LOCATION_DASHBOARD.ROLL_BACK_EDITOR.REVERT_SELECTED' | translate }}
        </button>
        </div>
    </div>
