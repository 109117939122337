import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LocationDetails } from 'app/shared/models/location-details';
import { CurveData } from 'app/shared/models/scatter-data';
import { STEVENS_CURVE } from '../advance-scattergraph/scatter-graph-constants';

@Component({
  selector: 'app-curve-options-dialog',
  templateUrl: './curve-options-dialog.component.html',
  styleUrls: ['./curve-options-dialog.component.scss']
})
export class CurveOptionsDialogComponent implements OnInit {
  public form: FormGroup;
  constructor(public dialogRef: MatDialogRef<CurveOptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { location: LocationDetails, curves: CurveData[], isMetric: boolean }, private formBuilder: FormBuilder ) { }

  ngOnInit(): void {
	if (!this.data.curves.some(v => v.name === STEVENS_CURVE)) {
		this.dialogRef.close();
	}

    this.createForm();
  }

  private createForm() {
    this.form = this.formBuilder.group({
      designSlope: null,
      designRoughness: null,
      effectiveRoughness: null,
      stevensSchutzbachHC: null,
      drain: null,
      r2: null
    });

	const ssCurveData = this.data.curves.find(v => v.name === STEVENS_CURVE);

    const stevensSchutzbachHC = ssCurveData.hc.toFixed(5);
    const drain = this.data['isMetric'] ? (Number(ssCurveData.drain) * 25.4).toFixed(1) : ssCurveData.drain.toFixed(2);
    const r2 = ssCurveData.error.toFixed(2);
    this.form.patchValue({ ...this.data.location, stevensSchutzbachHC, drain, r2 });
  }

}
