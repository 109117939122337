import { SafeUrl } from '@angular/platform-browser';

/**
 * Represents the model that is passed into the FileViewComponent.
 */
export interface IFileView {
    extension: string;

    hostUri: string;

    name: string;

    type: FileType;

    url?: URL;
    uri?: string;
    absoluteUri?: string;
}

/**
 * Represents the common types of files.
 */
export enum FileType {
    Unknown = 0,
    Image,
    Text,
}
