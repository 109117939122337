import { combineLatest } from 'rxjs';
/* Component that contains the notification dashboard */

import { Component, QueryList, ViewChildren } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NotificationDashboardService } from 'app/shared/services/notification.service';
import { Subscription } from 'rxjs';
import { UsersService, USER_ROLES } from 'app/pages/admin/users.service';
import flatMap from 'lodash/flatMap';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { LocationService } from 'app/shared/services/location.service';
import { CustomerService } from 'app/shared/services/customer.service';
import {
    NotificationCategory,
    NotificationDetailsLite,
    NotificationRouteAlarmType,
    NotificationRouteType,
    NotificationSummary,
    NotificationType,
} from 'app/shared/models/notifications';
import { Selectable } from 'app/shared/models/selectable';
import { AppQueryParams, customerQueryParam } from 'app/shared/models/customer';
import { MatLegacySlideToggle as MatSlideToggle, MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';

enum UISeparator {
    SEPARATOR = 9999,
}

type UINotificationCategoryType = NotificationCategory | UISeparator;

const UINotificationCategory = { ...NotificationCategory, ...UISeparator };

interface UINotification {
    category: UINotificationCategoryType;
    value?: NotificationRouteType | NotificationRouteAlarmType;
}

@Component({
    selector: 'notification-dashboard',
    templateUrl: './notification-dashboard.component.html',
    styleUrls: ['./notification-dashboard.component.scss'],
})
export class NotificationDashboardComponent {
    @ViewChildren('enabledAlarmToggle') public enabledAlarmToggle: QueryList<MatSlideToggle>;

    // Variables for specific page functionality
    public pageLoading = true;
    private subscriptions = [new Subscription()];
    private customerId: number;
    private userID?: string;
    public showAdminUserOpts = false;
    public alarmTypes: UINotification[];
    public PAGE_TEXT: any;

    // Items for paging
    public totalPaginationLength: number;
    public pageSize = 50;
    public pageIndex = 0;

    // Items for filtering menu
    public showFilterMenu = false;
    public showLocationOptions = true;
    public filterName = '';
    public locationTypeOptions: { id: number; name: string }[];
    public selectedLocationType: number = null;
    public locationGroupOptions: Selectable[];
    public locationOptions: Selectable[];
    public userOptions: Selectable[];
    public alarmTypeOptions: Selectable[];
    private filterObject: { users?: string[]; alarmTypes?: number[]; locations?: string[]; ansrOnly?: boolean } = {};

    // For table specifically
    public displayedColumns: string[] = ['name', 'description', 'type', 'enable', 'options'];
    public tableDataSource; // The actual table on the UI
    public tableBackupData: NotificationDetailsLite[]; // The data returned from the API
    public tableData: NotificationSummary[];
    public showSubscribeElement: string;

    public UINotificationCategory = UINotificationCategory;
    public NotificationRouteAlarmType = NotificationRouteAlarmType;

    constructor(
        private translate: TranslateService,
        private notificationService: NotificationDashboardService,
        private locationGroupService: LocationGroupService,
        private locationService: LocationService,
        private usersService: UsersService,
        private customerService: CustomerService,
        private matDialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) {
        this.translate.get('NOTIFICATION_DASHBOARD').subscribe((res: string) => {
            this.PAGE_TEXT = res;
        });
        this.locationTypeOptions = [
            { id: 0, name: this.PAGE_TEXT.LOCATION_GROUPS },
            { id: 1, name: this.PAGE_TEXT.ALARM_PAGES_TABS.LOCATIONS },
        ];

        // Set current customer
        this.subscriptions.push(
            this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
                const customerId = Number(params.get(customerQueryParam));

                if (this.customerId !== customerId) {
                    // Only need to update if customer has changed
                    this.customerId = customerId;

                    // Clear any set filters and reset pagination
                    this.filterName = '';
                    this.showLocationOptions = true;
                    this.selectedLocationType = null;
                    this.locationGroupOptions = undefined;
                    this.locationOptions = undefined;
                    this.userOptions = undefined;
                    this.alarmTypeOptions = undefined;
                    this.pageIndex = 0;

                    this.getNotifications();
                    this.populateFilteringOptions();
                }
            }),
        );

        this.subscriptions.push(
            this.usersService.isANSREngine.subscribe((isAllowed) => {
                const notificationAlarmTypes = Object.values(NotificationRouteAlarmType)
                    .filter((x) => x !== NotificationRouteAlarmType.ANSR || isAllowed.value)
                    .map<UINotification>((x) => {
                        return { category: UINotificationCategory.ALARM, value: x };
                    });
                const notificationTypes = Object.values(NotificationRouteType).map<UINotification>((x) => {
                    return { category: UINotificationCategory.NOTIFICATION, value: x as NotificationRouteType };
                });
                this.alarmTypes = [
                    ...notificationAlarmTypes,
                    { category: UINotificationCategory.SEPARATOR },
                    ...notificationTypes,
                ];
            }),
        );

        // Change options if user is admin

        this.subscriptions.push(
            combineLatest([
                this.usersService.userRoles,
                this.usersService.userInfo$,
                this.usersService.userID,
            ]).subscribe(([roles, info, userID]) => {
                if (roles && info && userID) {
                    this.showAdminUserOpts = roles.some(
                        (x) => x === USER_ROLES.ADMIN || x === USER_ROLES.CUSTOMER_ADMIN || x === USER_ROLES.CUSTOMER_USER_MANAGER,
                    );
                    if (!this.showAdminUserOpts) {
                        this.userOptions = [
                            {
                                id: 0,
                                isChecked: false,
                                name: info.firstName.concat(' ', info.lastName),
                                backupData: [userID],
                            },
                        ];
                    }
                    this.getUsers();
                }
            }),
        );
    }

    public getNotifications() {
        this.tableData = undefined;
        this.tableBackupData = undefined;
        this.totalPaginationLength = undefined;
        this.pageLoading = true;

        const tableDataSubscription = combineLatest([
            this.usersService.userID,
            this.notificationService.getAllNotificationDetails(
                this.customerId,
                this.pageSize,
                this.pageIndex + 1, // API counts from 1 instead of 0
                this.filterName,
                this.filterObject,
            ),
        ]).subscribe(
            ([userID, res]) => {
                this.userID = userID;
                const userIdUppercase = this.userID ? this.userID.toUpperCase() : null;
                if (res && res.payload) {
                    this.totalPaginationLength = res.count;
                    this.tableBackupData = res.payload;
                    this.tableData = res.payload.map((r: NotificationDetailsLite) => {
                        return {
                            id: r.guid,
                            name: r.name,
                            description: r.desc,
                            type:
                                r.type === NotificationType.ALARM
                                    ? r.ansr
                                        ? NotificationRouteAlarmType.ANSR
                                        : this.notificationService.convertAlarmTypeToGeneral(r.aids[0])
                                    : NotificationRouteType.DAILY,
                            enabled: r.enabled,
                            subscribed: r.levels.some(
                                (level) =>
                                    level.users &&
                                    level.users.some(
                                        (user) => user.id && user.id === userIdUppercase && (user.alarm || user.rtn),
                                    ),
                            ),
                        };
                    });
                }
                this.tableDataSource = new MatTableDataSource(this.tableData);
                this.pageLoading = false;
            },
            (error) => {
                this.pageLoading = false;
            },
        );
        this.subscriptions.push(tableDataSubscription);
    }

    // POPULATE FILTERS OPTIONS
    public populateFilteringOptions() {
        // All items you need for the filter menu
        this.getLocationGroups();
        this.getLocations();
        this.getAlarmTypes();
    }
    public getLocationGroups() {
        this.locationGroupOptions = undefined;
        const locationGroupSubscription = this.locationGroupService.getLocationGroups(this.customerId).subscribe(
            (res) => {
                if (res && res.locationGroups) {
                    this.locationGroupOptions = res.locationGroups.map((x) => {
                        return {
                            id: x.locationGroupID,
                            name: x.name,
                            isChecked: false,
                            backupData: x.locations.map((loc) => loc.name),
                        };
                    });
                } else {
                    // If there are no location groups we shoudn't let the user select that as an option for type
                    // and instead just show the location dropdown
                    this.selectedLocationType = 1;
                    this.showLocationOptions = false;
                }
            },
            (error) => {},
        );
        this.subscriptions.push(locationGroupSubscription);
    }
    public getLocations() {
        this.locationOptions = undefined;
        const locationsSubscription = this.locationService.getSlimLocations(this.customerId, false).subscribe(
            (res) => {
                if (res) {
                    this.locationOptions = res.map((x) => {
                        return { id: x.locationId, name: x.locationName, isChecked: false };
                    });
                }
            },
            (error) => {},
        );
        this.subscriptions.push(locationsSubscription);
    }
    public getUsers() {
        this.userOptions = undefined;
        if (this.showAdminUserOpts) {
            // Load users if admin
            const usersSubscription = this.usersService
                .getCustomerUsers(this.customerId, null, null, null, null, false)
                .subscribe(
                    (res) => {
                        if (res && res.payload) {
                            this.userOptions = res.payload.map((x, i) => {
                                return {
                                    id: i,
                                    name: x.firstName.concat(' ', x.lastName),
                                    isChecked: false,
                                    backupData: [x.userID],
                                };
                            });
                        }
                    },
                    (error) => {},
                );
            this.subscriptions.push(usersSubscription);
        }
    }
    public getAlarmTypes() {
        const opts = this.notificationService.getAllAlarmTypes();
        this.alarmTypeOptions = opts.map((x) => {
            return { id: x.id, name: x.name, isChecked: false };
        });
    }
    // END POPULATE FILTERS OPTIONS

    // INTERACT WITH THE FILTER MENU FUNCTIONS
    public resetFilter() {
        // Set everything blank / unchecked then API call to make sure we have everything
        this.filterName = '';
        this.selectedLocationType = this.locationGroupOptions ? null : 1;
        this.filterObject = {};

        // Need to hard reset these to make select boxes refresh
        const users = !this.userOptions
            ? []
            : this.userOptions.map((x) => {
                  return { ...x, isChecked: false };
              });
        const alarms = !this.alarmTypeOptions
            ? []
            : this.alarmTypeOptions.map((x) => {
                  return { ...x, isChecked: false };
              });
        const groups = !this.locationGroupOptions
            ? []
            : this.locationGroupOptions
            ? this.locationGroupOptions.map((x) => {
                  return { ...x, isChecked: false };
              })
            : undefined;
        const locations = !this.locationOptions
            ? []
            : this.locationOptions.map((x) => {
                  return { ...x, isChecked: false };
              });

        this.userOptions = undefined;
        this.alarmTypeOptions = undefined;
        this.locationGroupOptions = undefined;
        this.locationOptions = undefined;

        // Repopulate the select boxes
        setTimeout(() => {
            this.userOptions = users;
            this.alarmTypeOptions = alarms;
            this.locationGroupOptions = groups;
            this.locationOptions = locations;
        }, 100);

        this.getNotifications();
    }
    public applyFilter() {
        // Build the object to send as request body to API
        // Don't need to take filterName into account here since its a query string not query body
        this.filterObject = {};
        if (
            this.selectedLocationType === 0 &&
            this.locationGroupOptions &&
            this.locationGroupOptions.some((x) => x.isChecked)
        ) {
            // Create array of locations included in location groups
            this.filterObject.locations = flatMap(
                this.locationGroupOptions.filter((x) => x.isChecked),
                (x) => x.backupData,
            );
        } else if (
            this.selectedLocationType === 1 &&
            this.locationOptions &&
            this.locationOptions.some((x) => x.isChecked)
        ) {
            this.filterObject.locations = this.locationOptions.filter((x) => x.isChecked).map((x) => x.name);
        }

        if (this.userOptions && this.userOptions.some((x) => x.isChecked)) {
            this.filterObject.users = flatMap(
                this.userOptions.filter((x) => x.isChecked),
                (x) => x.backupData,
            );
        }
        if (this.alarmTypeOptions && this.alarmTypeOptions.some((x) => x.isChecked)) {
            if (this.alarmTypeOptions.some((x) => x.isChecked && x.id === 0)) {
                this.filterObject.ansrOnly = true;
            }

            this.filterObject.alarmTypes = this.alarmTypeOptions
                .filter((x) => x.isChecked && x.id !== 0)
                .map((x) => x.id);
        }
        this.getNotifications();
    }
    // END INTERACT WITH THE FILTER MENU FUNCTIONS

    // INTERACT WITH THE NOTIFICATION TABLE FUNCTIONS
    public addNotification(type: UINotification) {
        if (type.category === UINotificationCategory.SEPARATOR) return;

        const route: string = (type.value as string).toLowerCase();
        const appQueryParams: AppQueryParams = {
            c: this.customerId,
        };

        this.router.navigate([route], {
            queryParams: appQueryParams,
            relativeTo: this.activatedRoute,
        });
    }

    public editNotification(element: NotificationSummary) {
        const route: string = element.type.toLowerCase();
        const appQueryParams: AppQueryParams = {
            c: this.customerId,
            id: element.id,
        };

        this.router.navigate([route], {
            queryParams: appQueryParams,
            relativeTo: this.activatedRoute,
        });
    }
    public requestDeleteNotification(element: NotificationSummary) {
        this.matDialog
            .open(ConfirmationDialogComponent, {
                disableClose: true,
                data: {
                    title: this.PAGE_TEXT.DELETE_HEADER,
                    message:
                        this.PAGE_TEXT.DELETE_FIRST +
                        element.name +
                        this.PAGE_TEXT.DELETE_NOTIFICATION +
                        this.PAGE_TEXT.DELETE_SECOND,
                    okText: this.PAGE_TEXT.CONFIRM,
                    cancelText: this.PAGE_TEXT.CANCEL,
                },
            })
            .afterClosed()
            .subscribe((result) => {
                if (result.whichButtonWasPressed === 'ok') {
                    this.actuallyDeleteNotification(element);
                }
            });
    }
    public actuallyDeleteNotification(element: NotificationSummary) {
        const deleteSubscription = this.notificationService.deleteNotification(this.customerId, element.id).subscribe(
            () => {
                this.tableData = this.tableData.filter((x) => x.id !== element.id);
                this.tableDataSource = new MatTableDataSource(this.tableData);
            },
            (error) => {},
        );
        this.subscriptions.push(deleteSubscription);
    }
    public flipEnable(event: MatSlideToggleChange, element: NotificationSummary) {
        const affectedInd = this.tableBackupData.findIndex((x) => x.guid === element.id);

        if (event.checked === false) {
            // Currenty enabled, want to disable
            this.matDialog
                .open(ConfirmationDialogComponent, {
                    disableClose: true,
                    data: {
                        title: this.PAGE_TEXT.DISABLE_HEADER,
                        message: this.PAGE_TEXT.DISABLE_NOTIFICATION_CONFIRM,
                        okText: this.PAGE_TEXT.CONFIRM,
                        cancelText: this.PAGE_TEXT.CANCEL,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.whichButtonWasPressed !== 'ok') {
                        const affectedToggle = this.enabledAlarmToggle.filter((e, i) => i === affectedInd)[0];
                        affectedToggle.checked = true; // To visually force it back to the previous state
                    } else {
                        this.saveNotificationEnabled(affectedInd, false);
                    }
                });
        } else {
            this.saveNotificationEnabled(affectedInd, true);
        }
    }
    private saveNotificationEnabled(ind: number, enabld: boolean) {
        const backupElement = this.tableBackupData[ind];
        this.subscriptions.push(
            this.notificationService
                .updateNotification(this.customerId, { ...backupElement, enabled: enabld })
                .subscribe(
                    (res) => {
                        if (res) {
                            this.tableData = this.tableData.map((x) => {
                                if (x.id === backupElement.guid) {
                                    x.enabled = enabld;
                                }
                                return x;
                            });
                        }
                    },
                    (error) => {},
                ),
        );
    }
    public changePage(event: PageEvent) {
        this.pageSize = event.pageSize;
        this.pageIndex = event.pageIndex;
        this.getNotifications();
    }
    // END INTERACT WITH THE NOTIFICATION TABLE FUNCTIONS
}
