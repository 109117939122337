import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    Inject,
    ViewEncapsulation,
    ChangeDetectorRef,
    OnDestroy,
    ViewChild,
    ElementRef,
    AfterViewInit,
    HostListener,
    Sanitizer,
} from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarRef as MatSnackBarRef, LegacySimpleSnackBar as SimpleSnackBar } from '@angular/material/legacy-snack-bar';
import { FormControl } from '@angular/forms';

import {
    UsersData,
    IAdminDetails,
    IFeatures,
    UsersInfo,
    UsersDetailInfo,
    IRoles,
    UserFeature,
    UserPermissionInEdit,
    ILocations,
    UserAdminData,
} from 'app/shared/models/users';
import { Permission } from 'app/shared/models/permission';
import { UsersService, USER_ROLES, FEATURE_IDS } from 'app/pages/admin/users.service';
import { UpdatePermission } from 'app/shared/models/update-permission';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { of, Subscription, throwError } from 'rxjs';

import { Observable } from 'rxjs';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { DefaultResponse } from 'app/shared/models/default-response';
import { environment } from 'app/environments/environment.prod';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, forkJoin } from 'rxjs';
import { IComponentCustomizedConfirmation } from 'app/shared/models/customized-confirmation-box';
import {
    activeInactiveLocationQueryParam,
    AppQueryParams,
    Customer,
    customerEditorQueryParam,
    customerLocationGroupQueryParam,
    customerQueryParam,
    CustomerUserAssociactionData,
    userEmailFromRoute,
    userSearchPageIndexParam,
    userSearchPageSizeParam,
    userSearchParam,
} from 'app/shared/models/customer';
import { LocationArgs, LocationData, Locations, LocationStatus } from 'app/shared/models/locations';
import { UserSettingsAdminUpdate } from 'app/shared/models/user-preferences';
import { CustomerService } from 'app/shared/services/customer.service';
import { SharedService } from 'app/shared/services/shared.service';
import { LocationService } from 'app/shared/services/location.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { catchError, concatMap, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { CountryISO, SearchCountryField, ChangeData } from "ngx-intl-tel-input-gg";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { GetPermissionUserFeature } from 'app/shared/models/users-permission';
import { LocationEntitiesData, LocationListArgs } from 'app/shared/models/locations-entities-data';

const REGISTER_USER_ID = 106;
const EDITING_AUDIT_ALLOWED = 119;
const APPROVED_FILTER = 'ApprovedDataOnly';
const REGISTER_USER = 'registeruser';

@Component({
    selector: 'app-customer-user-association',
    templateUrl: './customer-user-association.component.html',
    styleUrls: ['./customer-user-association.component.scss'],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
    ],
    encapsulation: ViewEncapsulation.None,
})
export class CustomerUserAssociationComponent implements OnInit, OnDestroy, AfterViewInit {
    // public customers = new Array<UserPreferencesCustomer>();
    public customers = new Array<UsersDetailInfo>();
    public initialCustomers = new Array<UsersDetailInfo>();
    public administration: boolean;
    public viewer: boolean;
    public isZeroCustomer = false;
    public selectAll = false;
    public selectAllLocations = false;
    public selectedCustomer: number;
    public isSaved = false;
    public customerSearch = new FormControl();
    public permissions = new Array<Permission>();
    private subscriptions = new Array<Subscription>();
    public associationLoadingState: boolean;
    public updatePermissions: UpdatePermission;
    public initialCase = new Array<UsersDetailInfo>();
    public userCtrl = new FormControl();
    // public selectedUser: User;
    public selectedUser: UsersInfo;
    public conformationDataInput: IComponentCustomizedConfirmation;
    public showConfirmation: boolean;
    public isAtleastOneCustomerSelected: boolean;
    // public availableFeatures: Array<AvailableFeatures>;
    public availableFeatures: Array<IFeatures>;
    public currentStep: number;
    public userEmail: string;
    public selectedCustomers: Array<UsersDetailInfo>;
    public showErrorMessage = true;
    public customerSelectionErrorMessage = false;
    public guidQueryParam: string;
    public customerID: number;
    public userRole = USER_ROLES.NONE.toLowerCase();
    public disableSelectionForAdmin: boolean;
    public disableCustomerAdminCheckbox: boolean;
    public locationsSelected: boolean;
    public customerSelected: boolean;
    public disableCustomerLocationTab = true;
    public disableCustomersForAdminRole = false;
    public disableNoneCheckbox: boolean;
    public isUserRegistered: boolean;
    public roles: Array<IRoles> = [];
    public userFeature: Array<UserFeature>;
    public allAdminFeatures: Array<IFeatures>;
    public selectedFeatures: Array<IFeatures>;
    public loggedInUserRole: string;
    public loggedInUserFeatures: GetPermissionUserFeature[];
    public loggedInUserLocations: LocationData[];
    public disableInputFields = true;
    public disableOnEditFields = true;
    public disableUsrName = false;
    public disableEditIcon = false;
    public userInformation: UsersData = {};
    public userOrgEmail: string[];
    public userNotification: UserSettingsAdminUpdate = {
        notificationPrimaryEmail: true,
        notificationSecondaryEmail: true,
        notificationSMS: true,
    };
    public userEmailFromRoute: string;
    public loggedInUserEmail: string;
    public userPermissionInEdit = new UserPermissionInEdit();
    public selectedCustomerIdForLocation: number;
    public simpleSnackBarRefLongDuration: MatSnackBarRef<SimpleSnackBar>;
    public isAADDisabled = false;
    public userLocations: LocationData[];

    @ViewChild('secondaryEmailInput') public secondaryEmailInput: ElementRef;

    // All used for positioning phone number input dropdown
    @ViewChild('phoneNumberInput', {read: ElementRef}) public phoneNumberInput: ElementRef;
    @HostListener('window:resize', ['$event']) onResize() { this.setPhoneNumberDropdownLocation(); }
    @HostListener('window:scroll', ['$event']) onScroll() { this.setPhoneNumberDropdownLocation(); }

    /**
     * Reg Ex. Pattern for email validation
     */
    public emailPattern = REGEX_CONFIG.strictEmailPattern;

    /**
     * Reg Ex. Pattern for First Name
     */
    public firstNamePattern = REGEX_CONFIG.firstNamePattern;

    /**
     * Reg Ex. Pattern for Last Name
     */
    public lastNamePattern = REGEX_CONFIG.lastNamePattern;
    /**
     * Reg Ex. Pattern for Phone Number
     */
     public dropdownLocation: SafeHtml;
     public phoneNumberObject;
     public SearchCountryField = SearchCountryField;
     public CountryISO = CountryISO;
     public preferredCountries: CountryISO[] = [
       CountryISO.UnitedStates,
       CountryISO.UnitedKingdom
     ];

    /**
     * Indicates the loading state of the component.
     */
    public isLoading = false;
    public initialSelectedCustomers: UsersDetailInfo[];
    public originalRole: string;

    /**
     * Indicates the locations
     */

    public locations: Array<Locations & { disabled?: boolean; isChecked?: boolean }>;

    public customersWithLocs: number[] = [];

    /**
     * Indicates the location object
     */
    public locationObject: ILocations;

    /**
     * Snackbar Messages for translate
     */
    public longDurationSnackbarMsg: string;
    public customerAssociationUpdateFor: string;
    public customerAssociationUpdateFailed: string;
    public userRegisterSuccess: string;
    public userRegisterFailed: string;
    public userCreateSuccess: string;
    public userCreateFailed: string;
    public emailAlreadyExistSnackMsg: string;
    public updateSuccessfully: string;
    public updateFailed: string;
    public userInfoValidation: string;
    public dismissBtnText: string;
    public isUnique = true;
    public isUniqueEmail = true;
    public selectedAllCustomers = [];
    public translateKeys: Array<string> = [
        'COMMON.DISMISS_TEXT',
        'ADMIN.CUSTOMER_ACTIVATED_INACTIVATED_SUCCESS_MESSAGE',
        'ADMIN.CUSTOMER_ACTIVATED_INACTIVATED_ERROR_MESSAGE',
        'ADMIN.ADMIN_USERS.USER_CONFIRM_INACTIVATION_TEXT',
        'ADMIN.ADMIN_USERS.USER_INACTIVATION_MESSAGE',
        'ADMIN.CONFIRM',
        'ADMIN.CANCEL',
        'ADMIN.CUSTOMER_USER_ASSOCIATION.LONG_DURATION_SNACKBAR_MSG',
        'ADMIN.CUSTOMER_USER_ASSOCIATION.UPDATE_CUSTOMER_ASSOCIATION_FOR',
        'ADMIN.CUSTOMER_USER_ASSOCIATION.CUSTOMER_ASSOCIATION_UPDATE_FAILED',
        'ADMIN.CUSTOMER_USER_ASSOCIATION.USER_REGISTER_SUCCESS',
        'ADMIN.CUSTOMER_USER_ASSOCIATION.USER_REGISTER_FAILED',
        'ADMIN.CUSTOMER_USER_ASSOCIATION.USER_CREATE_SUCCESS',
        'ADMIN.CUSTOMER_USER_ASSOCIATION.USER_CREATE_FAILED',
        'ADMIN.CUSTOMER_USER_ASSOCIATION.EMAIL_ID_EXISTS',
        'ADMIN.CUSTOMER_USER_ASSOCIATION.USER_INFO_VALIDATION',
        'COMMON.IS_UPDATED_SUCCESSFULLY',
        'COMMON.IS_UPDATE_FAILED',
    ];
    public snackbarErrMsg: string;
    public inActivationHeader: string;
    public confirm: string;
    public snackbarSuccessMsg: string;
    public cancel: string;
    public isUserStatusChanged: boolean;
    public isUser = false;
    public userName: string;
    public inActivateMessage: string;
    public navigationConfirmationMsg: string;
    public dateFormat: string;
    public timeZone: string;

    public PAGE_TEXT: any;
    public showpageHint: boolean;
    private initialUserName: string;

    public USER_ROLES = USER_ROLES;
    public includeInactiveLocations: boolean;
    public isFormChanged = false;

    constructor(
        public customerAssociationDialog: MatDialogRef<CustomerUserAssociationComponent>,
        private cdr: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: CustomerUserAssociactionData,
        private usersService: UsersService,
        private customerService: CustomerService,
        private locationService: LocationService,
        private sharedService: SharedService,
        private activatedRoute: ActivatedRoute,
        private statusCodeService: StatusCodeService,
        private router: Router,
        private dateUtilService: DateutilService,
        private uiUtilsService: UiUtilsService,
        private translate: TranslateService,
        private matDialog: MatDialog,
        public domOperationUtilsService: DomOperationUtilsService,
        public domSanitizer: DomSanitizer
    ) {
        this.customerSearch.valueChanges
            .pipe(debounceTime(400))
            .pipe(distinctUntilChanged())
            .pipe(switchMap((customerSearch) => this.filterCustomers(customerSearch)))
            // .subscribe((res: GetPermissionsResponseCustomer) => {
            .subscribe((res: Customer) => {
                this.uiUtilsService.safeChangeDetection(this.cdr);
            });

        this.translate.get('ADMIN.CUSTOMER_USER_ASSOCIATION').subscribe((res: string) => {
            this.PAGE_TEXT = res;
        });

        translate.get(this.translateKeys).subscribe((translateValues) => {
            this.dismissBtnText = translateValues['COMMON.DISMISS_TEXT'];
            this.snackbarErrMsg = translateValues['ADMIN.CUSTOMER_ACTIVATED_INACTIVATED_ERROR_MESSAGE'];
            this.inActivationHeader = translateValues['ADMIN.ADMIN_USERS.USER_CONFIRM_INACTIVATION_TEXT'];
            this.inActivateMessage = translateValues['ADMIN.ADMIN_USERS.USER_INACTIVATION_MESSAGE'];
            this.confirm = translateValues['ADMIN.CONFIRM'];
            this.cancel = translateValues['ADMIN.CANCEL'];
            this.snackbarSuccessMsg = translateValues['ADMIN.CUSTOMER_ACTIVATED_INACTIVATED_SUCCESS_MESSAGE'];
            this.longDurationSnackbarMsg =
                translateValues['ADMIN.CUSTOMER_USER_ASSOCIATION.LONG_DURATION_SNACKBAR_MSG'];
            this.customerAssociationUpdateFor =
                translateValues['ADMIN.CUSTOMER_USER_ASSOCIATION.UPDATE_CUSTOMER_ASSOCIATION_FOR'];
            this.customerAssociationUpdateFailed =
                translateValues['ADMIN.CUSTOMER_USER_ASSOCIATION.CUSTOMER_ASSOCIATION_UPDATE_FAILED'];
            this.userRegisterSuccess = translateValues['ADMIN.CUSTOMER_USER_ASSOCIATION.USER_REGISTER_SUCCESS'];
            this.userRegisterFailed = translateValues['ADMIN.CUSTOMER_USER_ASSOCIATION.USER_REGISTER_FAILED'];
            this.userCreateSuccess = translateValues['ADMIN.CUSTOMER_USER_ASSOCIATION.USER_CREATE_SUCCESS'];
            this.userCreateFailed = translateValues['ADMIN.CUSTOMER_USER_ASSOCIATION.USER_CREATE_FAILED'];
            this.emailAlreadyExistSnackMsg = translateValues['ADMIN.CUSTOMER_USER_ASSOCIATION.EMAIL_ID_EXISTS'];
            this.userInfoValidation = translateValues['ADMIN.CUSTOMER_USER_ASSOCIATION.USER_INFO_VALIDATION'];
            this.updateSuccessfully = translateValues['COMMON.IS_UPDATED_SUCCESSFULLY'];
            this.updateFailed = translateValues['COMMON.IS_UPDATE_FAILED'];
        });
    }
    public ngOnInit() {
        this.dropdownLocation = this.domSanitizer.bypassSecurityTrustHtml("<style></style");
        this.domOperationUtilsService.editUserSelectedTab = 0;
        this.isLoading = true;

        const hintSubs = this.domOperationUtilsService.showpageHint.subscribe((v: boolean) => {
            this.showpageHint = v;
        });
        this.subscriptions.push(hintSubs);
        const userEmailFromRouteSubscription = this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            // get updated customer id
            this.userEmailFromRoute = params.get(userEmailFromRoute);
            this.selectedCustomer = +params.get(customerEditorQueryParam);
            const newInactiveLocations = Boolean(
                Number(params.get(activeInactiveLocationQueryParam)) || 0,
            );

            if (this.includeInactiveLocations !== undefined && this.includeInactiveLocations !== newInactiveLocations) {
                this.includeInactiveLocations = newInactiveLocations;
                this.setSelectedLocations(this.selectedCustomerIdForLocation);
            } else {
                this.includeInactiveLocations = newInactiveLocations;
            }
            if (this.selectedCustomer) {
                this.subscriptions.push(
                    combineLatest([
                        this.customerService.getCustomerById(this.selectedCustomer),
                        this.customerService.getTimeZone(),
                    ]).subscribe(([res, timeZoneTypes]) => {
                        this.dateFormat =
                            this.dateUtilService.getStringFormat(res.dateFormat) +
                            ' ' +
                            this.dateUtilService.getTimeFormatFor(res.timeFormat);

                        const timeZoneType = timeZoneTypes.find((x) => x.value === res.timeZone);
                        this.timeZone = timeZoneType.utcValue;
                    }),
                );
            } else {
                this.subscriptions.push(
                    combineLatest([
                        this.dateUtilService.dateFormat,
                        this.dateUtilService.timeFormat,
                        this.dateUtilService.timeZone,
                        this.customerService.getTimeZone(),
                    ]).subscribe(([newDateFormat, newTimeFormat, newTimeZone, timeZoneTypes]) => {
                        this.dateFormat =
                            this.dateUtilService.getStringFormat(newDateFormat) +
                            ' ' +
                            this.dateUtilService.getTimeFormatFor(newTimeFormat);
                        const timeZoneType = timeZoneTypes.find((x) => x.value === newTimeZone);
                        if (timeZoneType) {
                            this.timeZone = timeZoneType.utcValue;
                        }
                    }),
                );
            }
        });

        this.subscriptions.push(userEmailFromRouteSubscription);

        // get updated customer id
        this.guidQueryParam = sessionStorage.getItem('adsGuid');

        if (this.userEmailFromRoute === REGISTER_USER) {
            this.disableInputFields = false;
            this.disableOnEditFields = false;
        }

        this.subscriptions.push(
            this.statusCodeService.userInfo.subscribe((res) => {
                this.loggedInUserEmail = res.userEmail;
                this.loggedInUserFeatures = res.features;
                this.loggedInUserLocations = res.locations;

                res.userRole ? (this.loggedInUserRole = res.userRole[0]) : (this.loggedInUserRole = '');

                // 16280 - allow to edit fields by admin or customer admin
                if (
                    this.userEmailFromRoute === REGISTER_USER ||
                    this.loggedInUserRole === USER_ROLES.ADMIN ||
                    this.loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN ||
                    this.loggedInUserRole === USER_ROLES.CUSTOMER_USER_MANAGER
                ) {
                    this.disableInputFields = false;
                }

                res.customers
                    ? (this.customers = res.customers.map((x) => {
                          return { customer: x.customer, defaultCustomer: x.defaultCustomer };
                      }))
                    : (this.customers = []);

                this.getUserInformation();
            }),
        );
        this.currentStep = 0; // Sets the initial default step value to 0
    }
    public ngAfterViewInit(){
        this.setPhoneNumberDropdownLocation();
    }
    private setPhoneNumberDropdownLocation() {
        // The international phone number component doesn't play nicely with our page and overflows hidden etc
        // Only way to avoid rewriting portions of their component, scroll bars, or changing page size is hacking the css
        // The dropdown gets popped out from its position, put in fixed position on page, and manually put in place

        // Get the location of the phone number input on the page
        const currentTop = Math.round((this.phoneNumberInput.nativeElement).getBoundingClientRect().top);
        const currentLeft =  Math.round((this.phoneNumberInput.nativeElement).getBoundingClientRect().left);

        // Drop down and over a bit from the parent component
        const newTop = 'top:' + (currentTop + 64) + 'px !important;';
        const newLeft = 'left:' + (currentLeft + 16) + 'px !important;';
        const position = 'position: fixed !important;';
        const maxWidth = 'max-width: 40vw !important;';

        // Generate new values for stylesheet
        // Add style to the page to affect the 'country-dropdown' class
        const newHtml = '.country-dropdown{' + newTop + newLeft + position + maxWidth + '}';
        this.dropdownLocation = this.domSanitizer.bypassSecurityTrustHtml("<style>" + newHtml + "</style>");
    }

    public removeError() {
        this.isUniqueEmail = true;
        this.showErrorMessage = this.isAtleastOneCustomerSelected;
    }

    public onUsernameBlur() {
        if (
            !this.userInformation.userName ||
            this.userInformation.userName.length < 4 ||
            this.userInformation.userName === this.initialUserName
        ) {
            this.isUnique = true;
            return;
        }

        this.usersService.checkUsernameExist(this.userInformation.userName).subscribe((isExists: boolean) => {
            this.isUnique = !isExists;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
    }

    public onEmailChange() {
        if (!this.userInformation.userEmail) {
            this.isAADDisabled = false;
            this.assignEmailToUserName();
            return;
        }

        // TODO: Cannot move onto API until we'll know how we want to flag users as IDEX
        this.isAADDisabled = this.userInformation.userEmail.includes('@idexcorp.com');

        if (this.isAADDisabled) {
            this.userInformation.isAAD = true;
        }

        this.assignEmailToUserName();
        if(this.userOrgEmail?.join('') != this.userInformation.userEmail) {
            this.usersService.checkUserEmailExists(this.userInformation.userEmail).subscribe((isExists: boolean) => {
                this.isUniqueEmail = !isExists;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            });
        }

    }

    private assignEmailToUserName(){
        if(this.disableUsrName){
            this.userInformation.userName = this.userInformation.userEmail ;
        }
    }

    public canDeactivate(): Observable<boolean> | boolean {
        if (this.isUser) {
            return this.checkPageLocationChange();
        }
        return true;
    }

    private checkPageLocationChange(): Observable<boolean> {
        const confirmation = window.confirm('Are you sure want to leave this page?');
        if (confirmation) {
            this.router.navigate(['/pages/admin']);
        }
        return of(confirmation);
    }
    /**
     * Function to get the user information
     */

    public getUserInformation() {
        if (this.userEmailFromRoute !== REGISTER_USER) {
            // Subscribing to the value of user information passed from the user list screen, as well as to other 2 sources to get all the needed data
            const combineSourcesSubscription = combineLatest([
                this.sharedService.adminUserData,
                this.usersService.adminGetUserSettings(this.guidQueryParam),
                this.usersService.adminGetUser(this.guidQueryParam),
                this.usersService.getUsrPermissions(this.guidQueryParam)
            ]).subscribe(
                ([adminUserDataRes, userSettingsRes, userRes, userPermission]) => {
                    // For some reason result of a this.sharedService.adminUserData is Object so we have to cast it!
                    const data = adminUserDataRes as IAdminDetails;
                    this.userLocations = userPermission.locations;
                    this.userNotification.notificationPrimaryEmail = userSettingsRes.notificationPrimaryEmail;
                    this.userNotification.notificationSecondaryEmail = userSettingsRes.notificationSecondaryEmail;
                    this.userNotification.notificationSMS = userSettingsRes.notificationSMS;

                    this.userInformation.phoneNumber = userRes.phoneNumber;
                    if (userRes.phoneNumber) {
                        this.phoneNumberObject = userRes.phoneNumber;
                    }

                    if (Object.keys(data).length !== 0 && data.constructor === Object) {
                        this.selectedUser = data;
                        this.isUser = Boolean(data.user.userID.toString());
                        this.userInformation.userID = data.user.userID;
                        localStorage.setItem('userId', data.user.userID);
                        this.userInformation.userEmail = data.user.userEmail;
                        this.userInformation.firstName = data.user.firstName;
                        this.userInformation.lastName = data.user.lastName;
                        this.userInformation.secondaryEmail = data.user.secondaryEmail;
                        this.userInformation.lastLogin = userRes.lastLogin
                            ? new Date(userRes.lastLogin + (this.selectedCustomer ? 'Z' : ''))
                            : null;
                        this.userInformation.userName = data.user.userName;
                        this.userInformation.isActive = data.user.isActive;
                        this.userOrgEmail = Object.assign([], this.userInformation.userEmail);

                        this.userRole = data.user.roles[0];
                        this.originalRole = data.user.roles[0];
                        this.userFeature = data.user.features.map((features) => features);
                        this.getRoles(this.userRole);
                        this.getFeatures(this.userFeature);
                        if (this.selectedUser.customers) {
                            for (let i = 0; i < this.selectedUser.customers.length; i++) {
                                this.selectedUser.customers[i] = {
                                    customer: this.selectedUser.customers[i],
                                };
                            }
                            this.setSelectedCustomers(this.selectedUser);
                        }

                        this.selectedUser.customers?.forEach(element => {
                            element.customer?.locations?.forEach(loc => {
                                loc.disabled = this.isLocationDisabled(element.customer.customerID , loc.id, loc.viewable);
                            });
                        });

                    } else {
                        // handling refresh case
                        this.getDataFromService();
                    }
                },
                (err) => {
                    const msg = err.error || err;
                    this.snackBar.open(`${msg}`, `${this.dismissBtnText}`, {
                        panelClass: 'custom-error-snack-bar',
                    });
                    this.router.navigate(['/pages/admin']);
                },
            );
            this.subscriptions.push(combineSourcesSubscription);
        } else {
            this.getRoles(undefined);
            this.getFeatures('');
            this.selectedUser = this.userPermissionInEdit;
            this.setSelectedCustomers();
            this.disableUserName();
        }
    }

    private disableUserName()
    {
        this.disableUsrName = !(this.loggedInUserRole === USER_ROLES.ADMIN && (this.userRole === USER_ROLES.CUSTOMER_ADMIN || this.userRole === USER_ROLES.ADMIN));
    }

    public activateUser(user: UsersData, event: any) {
        if (!user.userID) {
            user.userID = localStorage.getItem('userId');
        }
        this.userName = user.userName;
        this.isUser = Boolean(user.userID);
        this.uiUtilsService.safeChangeDetection(this.cdr);
        if (user.isActive && !this.isSaved) {
            this.matDialog
                .open(ConfirmationDialogComponent, {
                    disableClose: true,
                    data: {
                        title: this.inActivationHeader,
                        message: this.inActivateMessage,
                        okText: this.confirm,
                        cancelText: this.cancel,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.whichButtonWasPressed === 'ok') {
                        this.isLoading = true;
                        this.changeState(user.userID, false);
                    } else if (result.whichButtonWasPressed === 'cancel') {
                        event.source.checked = user.isActive;
                    }
                });
        } else {
            this.isLoading = true;
            this.changeState(user.userID, true);
        }
    }
    public changeState(userID: string, status: boolean) {
        this.userName = this.userInformation.userName;
        this.usersService.activateStatus(userID, status).subscribe(
            (response) => {
                this.isUserStatusChanged = true;
                this.getActiveStatus();
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                // snak bar message when error occured
                this.snackBar.open(`${this.snackbarErrMsg} ${this.userName}`, `${this.dismissBtnText}`, {
                    panelClass: 'custom-error-snack-bar',
                });
            },
        );
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * Function to get all the roles
     */
    private getRoles(userRole: string) {
        if (userRole == undefined) {
            userRole = USER_ROLES.NONE;
            this.userRole = USER_ROLES.NONE;
        }

        if (userRole === USER_ROLES.ADMIN) {
            this.disableSelectionForAdmin = true;
        } else {
            this.disableSelectionForAdmin = false;
        }

        const rolesSubscription = this.usersService.getAllRoles().subscribe((roles: Array<IRoles>) => {
            this.roles = roles;
            let defaultSelectionRequired = true;
            for (let i = 0; i < this.roles.length; i++) {
                if (this.roles[i].name === userRole) {
                    this.roles[i].isSelected = true;
                    defaultSelectionRequired = false;
                } else {
                    this.roles[i].isSelected = false;
                }
            }
            for (let i = 0; i < this.roles.length; i++) {
                if (this.roles[i].name === USER_ROLES.NONE && defaultSelectionRequired) {
                    this.roles[i].isSelected = true;
                }
            }
            this.isLoading = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });

        this.subscriptions.push(rolesSubscription);
    }

    /**
     * Function to get the available locations if the logged in user is a customer user manager, these must be limited.
     */
    private getLocations() {
        if (this.loggedInUserRole === USER_ROLES.CUSTOMER_USER_MANAGER) {
            this.locationService.getLocationData(<LocationListArgs>{
                cid: this.customers[0].customer.customerID,
                IncludeInactiveLocations: false
            }).subscribe((entityData: LocationEntitiesData) => {
                this.locations = entityData.l.map(v => ({
                    customerID: this.customers[0].customer.customerID,
                    locationId: v.lid,
                    id: v.lid,
                    locationName: v.n,
                    name: v.n,
                    isChecked: true }));
            });
        }
    }

    /**
     * Function to get all the features
     */
    private getFeatures(userFeature) {
        const featuresSubscription = this.usersService.getAllFeatures().subscribe((features: Array<IFeatures>) => {
            this.allAdminFeatures = features.filter((x) => !x.canassigncustomer);
            this.availableFeatures =
                this.userRole === USER_ROLES.NONE
                    ? this.allAdminFeatures.filter(y => {
                        return y.id !== REGISTER_USER_ID && y.id !== FEATURE_IDS.CUSTOMER_EDITOR
                    })
                    : this.allAdminFeatures;

            // hide approved data till feature is ready. remove the line below when ready.
            this.availableFeatures = this.availableFeatures.filter((feature) => feature.name !== APPROVED_FILTER);

            this.setFeatures();

            if (this.userRole === USER_ROLES.CUSTOMER_USER_MANAGER) {
                this.availableFeatures.forEach((item) => { if(item.id === REGISTER_USER_ID) item.disabled = true; });
            }


            this.removeCustEditorFeature();

            for (let i = 0; i < this.availableFeatures.length; i++) {
                for (let j = 0; j < userFeature.length; j++) {
                    if (this.availableFeatures[i].id === userFeature[j].id) {
                        this.availableFeatures[i].viewable = true;
                    }
                }
            }
            this.uiUtilsService.safeChangeDetection(this.cdr);
            this.subscriptions.push(featuresSubscription);
        });
    }

    private setFeatures(){
        if(this.loggedInUserRole === USER_ROLES.CUSTOMER_USER_MANAGER || this.loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN)
            {
                const featuresAssoc = {};
                this.loggedInUserFeatures.forEach(feature => featuresAssoc[feature.id] = true);
                this.availableFeatures.forEach(item => item.disabled = !featuresAssoc[item.id]);
            }

    }

    public removeCustEditorFeature()
    {
        if (this.userRole === USER_ROLES.CUSTOMER_USER_MANAGER || this.userRole === USER_ROLES.NONE) {
            this.availableFeatures = this.availableFeatures.filter(x => x.id !== FEATURE_IDS.CUSTOMER_EDITOR);
        }
    }

    private getActiveStatus() {
        if (this.isUserStatusChanged) {
            this.isSaved = true;
            // snack bar message
            const simpleSnackBarRef = this.snackBar.open(
                `${this.userName} ${this.snackbarSuccessMsg}`,
                `${this.dismissBtnText}`,
            );
            setTimeout(simpleSnackBarRef.dismiss.bind(simpleSnackBarRef), 10000);
        }
        this.isUserStatusChanged = false;
        this.isSaved = false;
        this.getDataFromService();
    }

    /**
     * Function to get the information in case of explicit refresh
     */
    private getDataFromService() {
        //  get all the customers
        const customersSubscription = this.usersService.getUserPermissions(this.guidQueryParam).subscribe(
            (result) => {
                this.selectedUser = result;
                this.isUser = true;
                this.userInformation.userID = result.userID;
                this.userInformation.userEmail = result.userEmail;
                this.userInformation.firstName = result.firstName;
                this.userInformation.lastName = result.lastName;
                this.userInformation.lastLogin = result.lastLogin
                    ? new Date(result.lastLogin + (this.selectedCustomer ? 'Z' : ''))
                    : null;
                this.userInformation.secondaryEmail = result.secondaryEmail;
                this.userInformation.userName = result.userName;
                this.initialUserName = result.userName;
                this.userInformation.isActive = result.isActive;
                this.userRole = result.roles[0];
                this.userFeature = result.features;
                this.getRoles(this.userRole);
                this.getFeatures(this.userFeature);
                this.uiUtilsService.safeChangeDetection(this.cdr);
                if (this.selectedUser.customers) {
                    this.setSelectedCustomers();
                }
                // this.getPhoneAndNotifications();
            },
            () => {
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
        this.subscriptions.push(customersSubscription);
    }

    /**
     * check associated customers
     */
    private setSelectedCustomers(val?) {
        this.initialCase = new Array<Customer>();
        if (this.customers) {
            for (const customer of this.selectedUser.customers) {
                this.initialCase.push(customer);
            }
        }
        if (this.customers !== undefined) {
            for (const customer of this.customers) {
                if (this.initialCase[0]) {
                    customer.isSelected = this.initialCase.some(
                        (x) => x.customer.customerID === customer.customer.customerID,
                    );
                } else {
                    this.showErrorMessage = false;
                }
            }
            this.selectedCustomers = this.customers.filter((x) => x.isSelected);
            this.customerSelected = !!this.selectedCustomers?.length;
            this.initialSelectedCustomers = this.selectedCustomers;
            if (this.selectedCustomers.length > 0) {
                this.selectedCustomerIdForLocation = this.selectedCustomers[0].customer.customerID;
            }
            this.isLoading = false;
            if (this.selectedCustomerIdForLocation) {
                this.setSelectedLocations(this.selectedCustomerIdForLocation);
            }
            this.customers.sort((c1, c2) =>
                c1.isSelected && !c2.isSelected ? -1
                : c2.isSelected && !c1.isSelected ? 1
                : c1.customerName?.localeCompare(c2.customerName));

            this.initialCustomers = this.customers;

            if(this.customers && this.loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN){
                this.customerSelectionErrorMessage = true;
                this.disableEditIcon = this.userRole === USER_ROLES.CUSTOMER_ADMIN;
            }

            this.checkSelectAll('');
        }
    }

    private sortLocationsByActive = (location1, location2) => {
        if (location1.viewable && !location2.viewable) {
            return -1;
        } else if (!location1.viewable && location2.viewable) {
            return 1;
        } else {
            return this.uiUtilsService.sortLocations(location1, location2);
        }
    };

    private isLocationDisabled(selectedCustomerIdForLocation: number, locationID: number, viewable : boolean): boolean {
        if (this.loggedInUserLocations && this.loggedInUserLocations.some(loc => loc.customerID === selectedCustomerIdForLocation && loc.locationId === locationID)) {
            if (!viewable || (viewable && (!this.userLocations || !this.userLocations?.some(a=>a.customerID === selectedCustomerIdForLocation && a.locationId === locationID)))) {
                return false;
            }
            else {
                return true;
            }
        } else {
            return false;
        }
    }

    /**
     * Select the locations based on customers
     */

    public setSelectedLocations(selectedCustomerIdForLocation) {
        this.locationsSelected = false;
        let locationObject = this.selectedUser.customers.filter(
            (x) => x.customer.customerID === selectedCustomerIdForLocation,
        );
        if (locationObject.length > 0) {
            if (locationObject[0].customer.locations.length > 0 && this.includeInactiveLocations === false) {
                this.locationObject = this.selectedUser.customers
                    .filter((x) => x.customer.customerID === selectedCustomerIdForLocation)
                    .map((x) => {
                        return {
                            locations: x.customer.locations,
                            customerId: x.customer.customerID,
                        };
                    })[0];
                this.locations = this.locationObject.locations;
                this.locations.sort(this.sortLocationsByActive);

                if(!this.customersWithLocs?.includes(selectedCustomerIdForLocation)){
                    this.customersWithLocs.push(selectedCustomerIdForLocation);
                }

                this.locations = this.locations.map(v => ({
                        customerID: selectedCustomerIdForLocation,
                        locationId: v.id,
                        id: v.id,
                        locationName: v.name,
                        name: v.name,
                        disabled : this.isLocationDisabled(selectedCustomerIdForLocation, v.id, v.viewable),
                        viewable: v.viewable,
                        isChecked: v.viewable && this.loggedInUserLocations && this.loggedInUserLocations.some(loc => loc.customerID === selectedCustomerIdForLocation && loc.locationId === v.id) &&
                        (!this.userLocations || !this.userLocations?.some(a=>a.customerID === selectedCustomerIdForLocation && a.locationId === v.id))}));

                if (this.userRole === USER_ROLES.ADMIN) {
                    this.toggleSelectAllLocations();
                } else {
                    this.toggleSelectAllLocationsForDifferentRole();
                }
            } else {
                this.isLoading = true;
                locationObject = this.selectedUser.customers.filter(
                    (x) => x.customer.customerID === selectedCustomerIdForLocation,
                );
                const locationArgs = <LocationArgs>{
                    customerId: selectedCustomerIdForLocation,
                    IncludeInactiveLocations: this.includeInactiveLocations,
                    uid: this.guidQueryParam,
                };

                const subscription = this.usersService
                    .getLocationsList(locationArgs)
                    .subscribe((response: Array<Locations>) => {
                        if (response) {
                            response.forEach((res) => {
                                res.name = res.locationName;
                                res.id = res.locationId;
                            });
                            // to visible only assigned location to customer admin
                            if (this.customerService.loggedInUserRole.getValue().includes(USER_ROLES.ADMIN)) {
                                this.locations = response;
                            }
                            else {
                                this.locations = response.filter(
                                    (x) =>
                                        x.status === LocationStatus.Active || x.status === LocationStatus.Maintenance,
                                );

                                if(this.locations != null && this.locations.length > 0){
                                    if(!this.customersWithLocs?.includes(selectedCustomerIdForLocation)){
                                        this.customersWithLocs.push(selectedCustomerIdForLocation);
                                    }
                                }
                            }
                        } else {
                            this.locations = [];
                            this.locationsSelected = true;
                            //Remove Customer with no locations if added earlier when the locations were actually present
                            if(this.customersWithLocs?.includes(selectedCustomerIdForLocation)){
                                this.removeCustIfNoLocations(selectedCustomerIdForLocation);
                            }
                        }

                        if (this.userRole === USER_ROLES.ADMIN) {
                            this.toggleSelectAllLocations();
                        } else {
                            this.toggleSelectAllLocationsForDifferentRole();
                        }

                        this.isLoading = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                    });

                this.subscriptions.push(subscription);
            }
        } else {
            this.isLoading = true;
            locationObject = this.selectedUser.customers.filter(
                (x) => x.customer.customerID === selectedCustomerIdForLocation,
            );
            const locationArgs = <LocationArgs>{
                customerId: selectedCustomerIdForLocation,
                IncludeInactiveLocations: this.includeInactiveLocations,
                uid: this.guidQueryParam,
            };

            const subscription = this.usersService
                .getLocationsList(locationArgs)
                .subscribe((response: Array<Locations>) => {
                    if (response) {
                        response.forEach((res) => {
                            res.name = res.locationName;
                            res.id = res.locationId;
                        });

                        this.locations = response.sort(this.sortLocationsByActive);

                        this.locations = this.locations.map(v => ({
                            customerID: selectedCustomerIdForLocation,
                            locationId: v.locationId,
                            id: v.locationId,
                            locationName: v.locationName,
                            name: v.locationName,
                            disabled : this.loggedInUserLocations ? this.loggedInUserLocations?.some(loc => loc.customerID === v.customerID && loc.locationId === v.locationId) : false,
                            viewable: false }));

                        if(!this.customersWithLocs?.includes(selectedCustomerIdForLocation)){
                            this.customersWithLocs.push(selectedCustomerIdForLocation);
                        }

                    } else {
                        this.locations = [];
                        this.locationsSelected = true;

                        if(this.customersWithLocs?.includes(selectedCustomerIdForLocation)){
                            this.removeCustIfNoLocations(selectedCustomerIdForLocation);
                        }
                    }

                    if (this.userRole === USER_ROLES.ADMIN) {
                        this.toggleSelectAllLocations();
                    } else {
                        this.toggleSelectAllLocationsForDifferentRole();
                    }

                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                });
            this.subscriptions.push(subscription);
        }
    }
    /**
     * uncheck all customers on popup open and when no associated customers found
     */
    private uncheckAllCustomers() {
        if (this.customers !== undefined) {
            for (const customer of this.customers) {
                customer.isSelected = false;
            }
            this.initialCustomers = this.customers;
            this.customerSelectionErrorMessage = true;
        }
    }

    private removeCustIfNoLocations(customerID: number) {
        var index = this.customersWithLocs.indexOf(customerID);
            if (index !== -1) 
            this.customersWithLocs.splice(index, 1);
    }

    public ngOnDestroy() {
        this.domOperationUtilsService.editUserSelectedTab = 0;
        this.uncheckAllCustomers();
        if (this.simpleSnackBarRefLongDuration !== undefined) {
            this.simpleSnackBarRefLongDuration.dismiss();
        }
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
    // select or un-select all check-boxes.
    public toggleSelectAll() {
        this.selectAll = !this.selectAll;
        if (this.loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN) {
            this.customerSelectionErrorMessage = true;
        }

        this.selectedCustomers = this.customers;
        for (const customer of this.selectedCustomers) {
            customer.isSelected = this.selectAll;
        }

        this.toggleErrorMessage();
        this.isCustomerSelected();
    }

    /**
     * Make the the customer filter serch
     * @param customerSearch serch parameter
     */
    private filterCustomers(customerSearch: string) {
        this.selectAll = false;
        this.customers = this.initialCustomers.filter((x) =>
            x.customer.customerName.toLowerCase().includes(customerSearch.toLowerCase()),
        );

        // checks all customer selected or not
        if (customerSearch.trim() === '') {
            if (
                this.customers.some((x) => x.isSelected) &&
                this.customers.filter((x) => x.isSelected).length === this.customers.length
            ) {
                this.selectAll = true;
            } else {
                this.selectAll = false;
            }
        }
        // checkes atleast one customer is selecte or not
        this.isAtleastOneCustomerSelected = this.customers.some((x) => x.isSelected);
        return this.customers;
    }

    /**
     * Function to handle the click on save button and post the data to the API
     */

    public saveUserInformation() {
        this.userInformation.phoneNumber = this.phoneNumberObject?.e164Number;

        if (this.userEmailFromRoute === REGISTER_USER) {
            if (this.secondaryEmailInput['valid']) {
                this.isLoading = true;
                const checkUserAction = this.checkUserAction();
                this.configureApiCall(checkUserAction);
            }
        } else {
            this.isLoading = true;
            const checkUserAction = this.checkUserAction();
            this.configureApiCall(checkUserAction);
        }
    }

    public onPhoneNumberChange(){
        if(this.phoneNumberObject && this.phoneNumberObject.e164Number && this.phoneNumberObject.e164Number !== this.userInformation.phoneNumber){
            this.isFormChanged = true;
        }
    }

    /**
     * Function to check which action is user performing
     */

    public checkUserAction() {
        const keysToCheck = ['firstName', 'lastName', 'userEmail'];

        const checkUserInfo = keysToCheck.every((val, index) => {
            return (
                this.userInformation.hasOwnProperty(val) &&
                this.userInformation[val] !== '' &&
                new RegExp(this.firstNamePattern).test(this.userInformation.firstName) &&
                new RegExp(this.lastNamePattern).test(this.userInformation.lastName) &&
                new RegExp(this.emailPattern).test(this.userInformation.userEmail)
            );
        });

        if (this.userRole.toLocaleLowerCase() === USER_ROLES.NONE.toLowerCase()) {
            this.userInformation.userRole = this.userRole;
        }

        if (this.userEmailFromRoute !== REGISTER_USER) {
            if (this.loggedInUserRole === USER_ROLES.ADMIN || this.loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN || this.loggedInUserRole === USER_ROLES.CUSTOMER_USER_MANAGER) {
                return 'callAdminEditFunctionality';
            } else {
                return 'callEditFunctionality';
            }
        } else {
            if (!checkUserInfo) {
                return 'saveuserInfoFirst';
            } else if (
                checkUserInfo &&
                ((this.userRole !== undefined && this.userRole.toLocaleLowerCase() !== USER_ROLES.NONE.toLowerCase()) ||
                    (typeof this.selectedCustomers !== 'undefined' && this.selectedCustomers.length > 0) ||
                    (typeof this.selectedFeatures !== 'undefined' && this.selectedFeatures.length > 0))
            ) {
                return 'callBothAPI';
            } else {
                return 'callRegisterFunctionality';
            }
        }
    }

    /**
     * Function to configure the API call
     */
    public configureApiCall(checkUserAction) {
        switch (checkUserAction) {
            case 'callAdminEditFunctionality':
                this.adminEditUser(this.createPostObject());
                break;
            case 'callEditFunctionality':
                this.editUser(this.createPostObject());
                break;
            case 'callRegisterFunctionality':
                this.registerUser();
                break;
            case 'callBothAPI':
                this.callBothAPI();
                break;
            default:
                this.saveuserInfoFirst();
                break;
        }
    }

    /**
     * Function to ask user to save user info before clicking on save
     */

    private saveuserInfoFirst() {
        this.isLoading = false;
        this.showSnackBar(this.userInfoValidation);
    }

    /**
     * Function to edit the user and save the info
     */

    public editUser(createPostObject) {
        // Edit functionality
        const createAssociationSubscription = this.usersService
            .postDataToServer(this.guidQueryParam, createPostObject)
            .subscribe(
                (res: Array<DefaultResponse<object>>) => {
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                    if (res && res.length > 0) {
                        this.showSnackBar(
                            `${this.customerAssociationUpdateFor} ${this.userInformation.userEmail} ${this.updateSuccessfully}`,
                        );
                        this.setupAdminRoute();
                    } else {
                        this.showSnackBar(`${this.customerAssociationUpdateFailed} ${this.userInformation.userEmail}`);
                    }
                },
                () => {
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                    this.sharedService.closeUserAssociation(false);
                },
            );
        this.subscriptions.push(createAssociationSubscription);
    }

    public adminEditUser(createPostObject) {
        const userAdminUpdate: UserAdminData = this.getAdminUpdateData(this.userInformation);
        const sub = this.usersService.postDataToServer(this.guidQueryParam, createPostObject).subscribe(
            (userRes) => {
                if (userRes && userRes.length > 0 && !userRes[0].isError) {
                    forkJoin([
                        this.usersService.adminSaveUserPreferenceNotification(
                            this.guidQueryParam,
                            this.userNotification,
                        ),
                        this.usersService.adminUpdateUser(this.guidQueryParam, userAdminUpdate),
                    ]).subscribe(
                        () => {
                            this.showSnackBar(
                                `${this.customerAssociationUpdateFor} ${this.userInformation.userEmail} ${this.updateSuccessfully}`,
                            );
                            this.setupAdminRoute();
                            this.isLoading = false;
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                        },
                        (err) => {
                            this.isLoading = false;
                            this.showSnackBar(
                                `${this.customerAssociationUpdateFailed} ${this.userInformation.userEmail}`,
                            );
                            this.isLoading = false;
                            this.uiUtilsService.safeChangeDetection(this.cdr);
                        },
                    );
                }
                else if (userRes && userRes[0].isError) {
                    this.showSnackBar(userRes[0].message);
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }
                else {
                    this.showSnackBar(`${this.customerAssociationUpdateFailed} ${this.userInformation.userEmail}`);
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }
            },
            () => {
                this.showSnackBar(`${this.customerAssociationUpdateFailed} ${this.userInformation.userEmail}`);
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
                this.sharedService.closeUserAssociation(false);
            },
        );

        this.subscriptions.push(sub);
    }

    private getAdminUpdateData(data: UsersData): UserAdminData {
        // Fill also empty strings here - user may want to remove secondary email
        return {
            firstName: data.firstName,
            lastName: data.lastName,
            userEmail: data.userEmail,
            secondaryEmail: data.secondaryEmail,
            phoneNumber: data.phoneNumber,
        };
    }

    /**
     * Function to register the user
     */
    private registerUser() {
        this.isLoading = true;

        if (environment.disableTestUser) {
            this.userInformation.testUser = false;
        } else {
            this.userInformation.testUser = true;
        }

        const userAdminUpdate: UserAdminData = this.getAdminUpdateData(this.userInformation);

        const registerUserSubscription = this.usersService
            .registerUser(this.userInformation)
            .pipe(
                concatMap((res) =>
                    forkJoin([
                        this.usersService.adminSaveUserPreferenceNotification(res, this.userNotification),
                        this.usersService.adminUpdateUser(res, userAdminUpdate),
                    ]),
                ),
            )
            .subscribe(
                () => {
                    this.isLoading = false;
                    this.isUserRegistered = true;
                    this.showSnackBar(this.userRegisterSuccess);
                },
                () => {
                    this.isLoading = false;

                    this.snackBar.open(this.userRegisterFailed, this.dismissBtnText, {
                        panelClass: 'custom-error-snack-bar',
                    });
                },
            );
        this.subscriptions.push(registerUserSubscription);
    }

    /**
     * Function to register and save the user
     */

    private callBothAPI() {
        this.isLoading = true;
        if (this.isUserRegistered) {
            const createPostObject = this.createPostObject();
            this.editUser(createPostObject);
        } else {
            const subscriptionForBothAPI = this.usersService.registerUser(this.userInformation).subscribe(
                (res) => {
                    if (res) {
                        forkJoin([
                            this.usersService.postDataToServer(res, this.createPostObject()),
                            this.usersService.adminSaveUserPreferenceNotification(res, this.userNotification),
                            this.usersService.adminUpdateUser(res, this.getAdminUpdateData(this.userInformation)),
                        ]).subscribe(
                            () => {
                                this.isLoading = false;
                                this.showSnackBar(this.userCreateSuccess);
                                this.setupAdminRoute(true);
                            },
                            () => {
                                this.isLoading = false;
                                this.uiUtilsService.safeChangeDetection(this.cdr);
                                this.snackBar.open(this.userCreateFailed, this.dismissBtnText, {
                                    panelClass: 'custom-error-snack-bar',
                                });
                            },
                        );
                    } else {
                        this.isLoading = false;
                        this.uiUtilsService.safeChangeDetection(this.cdr);
                        this.snackBar.open(this.userCreateFailed, this.dismissBtnText, {
                            panelClass: 'custom-error-snack-bar',
                        });
                    }
                },
                (error) => {
                    this.isLoading = false;
                    this.showErrorMessage = false;
                    if (error.error === 'User name is not unique') {
                        this.isUnique = false;
                    } else {
                        this.isUniqueEmail = false;
                    }
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                    this.snackBar.open(error.error, this.dismissBtnText, {
                        panelClass: 'custom-error-snack-bar',
                    });
                },
            );
            this.subscriptions.push(subscriptionForBothAPI);
        }
    }

    public allowFeatureEdit() {
        return this.loggedInUserRole === USER_ROLES.ADMIN || this.loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN ||
            this.loggedInUserRole === USER_ROLES.CUSTOMER_USER_MANAGER;
    }

    /**
     * Function to create the object to be sent to server
     */

    private createPostObject() {
        this.selectedCustomers.forEach((x) => {
            x.customer.defaultCustomer = x.defaultCustomer;
        });
        const originalObject: {
            roles: IRoles[],
            selectedCustomers: UsersDetailInfo[],
            selectedLocations: UsersInfo,
            availableFeatures?: IFeatures[]
        } = {
            roles: this.roles,
            selectedCustomers: this.initialCustomers,
            selectedLocations: this.selectedUser,
        };
        const objectToSend: {
            role: string,
            customers: {
                objectType: string,
                objectID: number,
                customerID: number,
                defaultCustomer: boolean
            }[],
            locations: {
                objectType: string,
                viewable: boolean,
                objectID: number,
                customerID: number,
                defaultCustomer: false,
            }[],
            features?: {featureID: number}[]
        } = {
            role: '',
            customers: [],
            locations: [],
        };
        objectToSend.role = this.userRole;

        if(this.allowFeatureEdit() || this.loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN) {
            originalObject.availableFeatures =  this.availableFeatures;
            objectToSend.features = [];
        }

        if(this.loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN || this.loggedInUserRole === USER_ROLES.CUSTOMER_USER_MANAGER ){
            originalObject.selectedLocations?.customers?.forEach(customer => {
                customer?.customer?.locations?.forEach(element => {
                    if(element.viewable && !element.disabled)
                        {
                            element.viewable = true;
                        }
                        else
                        {
                            element.viewable = false;
                        }
                });
            });
        }


        const customers = [];
        const locations = [];

        if (this.loggedInUserRole === USER_ROLES.CUSTOMER_USER_MANAGER) {
            originalObject.selectedCustomers = this.customers;
        }

        if(this.allowFeatureEdit() || this.loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN) {
            const features = [];
            const availableFeatures = originalObject.availableFeatures.filter((x) => x.viewable).map((x) => x.id);
            for (let i = 0; i < availableFeatures.length; i++) {
                features.push({
                    featureID: availableFeatures[i]
                });
            }

            objectToSend.features = features;
        }

        for (let i = 0; i < originalObject.selectedCustomers.length; i++) {
            if (originalObject.selectedCustomers[i].isSelected) {
                customers.push({
                    objectType: 'Customer',
                    objectID: originalObject.selectedCustomers[i].customer.customerID,
                    customerID: originalObject.selectedCustomers[i].customer.customerID,
                    defaultCustomer: originalObject.selectedCustomers[i].defaultCustomer,
                });
            }
        }
        for (let i = 0; i < originalObject.selectedLocations.customers.length; i++) {
            for (let j = 0; j < originalObject.selectedLocations.customers[i].customer.locations.length; j++) {
                if (objectToSend.customers.includes(originalObject.selectedLocations.customers[i].customer.customerID)) {
                locations.push({
                    objectType: 'Location',
                    viewable: originalObject.selectedLocations.customers[i].customer.locations[j].viewable,
                    objectID: originalObject.selectedLocations.customers[i].customer.locations[j].id,
                    customerID: originalObject.selectedLocations.customers[i].customer.customerID,
                    defaultCustomer: false,
                });
            }
            }
        }
        // if seclect all locations chosen for existing customers send objectId as -1 for specific customer
        // changes done for bug #13009
        if (this.selectedAllCustomers && this.selectedAllCustomers.length > 0) {
            this.selectedAllCustomers.forEach((customerId) => {
                locations.push({
                    objectType: 'Location',
                    viewable: true,
                    objectID: -1,
                    customerID: customerId,
                    defaultCustomer: false,
                });
            });
        }

        objectToSend.customers = customers;
        objectToSend.locations = this.userRole === USER_ROLES.ADMIN ? [] : locations;

        // update features for loggedInUser
        this.statusCodeService.userInfo.subscribe((loggedInUser) => {
            if (loggedInUser.userEmail === this.userInformation.userEmail) {
                // condition to check if customerEditor feature is allowed or not, 102 is id for customer feature in feature list
                if (objectToSend.features && objectToSend.features.some((x) => x.featureID === 102)) {
                    this.usersService.isCustomerEditor.next(true);
                } else {
                    this.usersService.isCustomerEditor.next(false);
                }
                // condition to check if schedule collect feature allowed or not, 103 is id for schedule collect in feature list
                if (objectToSend.features && objectToSend.features.some((x) => x.featureID === 103)) {
                    this.usersService.isScheduleCollectAllowed.next(true);
                } else {
                    this.usersService.isScheduleCollectAllowed.next(false);
                }
                // condition to check if alarm maintenace feature allowed or not, 104 is id for  alarm maintenace in feature list
                if (objectToSend.features && objectToSend.features.some((x) => x.featureID === 104)) {
                    this.usersService.isAlarmMaintenanceAllowed.next(true);
                } else {
                    this.usersService.isAlarmMaintenanceAllowed.next(false);
                }
                // condition to check if location import is allowed or not, 105 is id for location import in feature list
                if (objectToSend.features && objectToSend.features.some((x) => x.featureID === 105)) {
                    this.usersService.isLocationImportAllowed.next(true);
                } else {
                    this.usersService.isLocationImportAllowed.next(false);
                }
                // condition to check if registeruser feature is allowed or not, 106 is id for register user in feature list
                if (objectToSend.features && objectToSend.features.some((x) => x.featureID === 106)) {
                    this.usersService.isRegisterUserFeatureAllowed.next(true);
                } else {
                    this.usersService.isRegisterUserFeatureAllowed.next(false);
                }
                // condition to check if basic data editing feature is allowed or not, 109 is id for basic data editing in feature list
                if (objectToSend.features && objectToSend.features.some((x) => x.featureID === 109)) {
                    this.usersService.isBasicDataEditingAllowed.next(true);
                } else {
                    this.usersService.isBasicDataEditingAllowed.next(false);
                }
                // condition to check if data editing audit report feature allowed or not, 119 is id for data editing audit report in feature list
                if (
                    objectToSend.features &&
                    objectToSend.features.findIndex((x) => x.featureID === EDITING_AUDIT_ALLOWED) > -1
                ) {
                    this.usersService.isDataEditingAuditReportAllowed.next(true);
                } else {
                    this.usersService.isDataEditingAuditReportAllowed.next(false);
                }
            }
        });
        return objectToSend;
    }

    /**
     * Function to setup the admin route in case of success
     */
    public setupAdminRoute(routedFrom?) {
        this.statusCodeService.directUser = true;
        if (routedFrom === false) {
            this.statusCodeService.userEmailInEditScreen.next(null);
        } else {
            this.statusCodeService.userEmailInEditScreen.next(this.userInformation.userEmail);
        }
        const currentCustomerId = Number(this.activatedRoute.snapshot.queryParamMap.get(customerQueryParam));

        // get current query params for location group
        const locationGroupId = Number(this.activatedRoute.snapshot.queryParamMap.get(customerLocationGroupQueryParam));

        // get current query params for showing all locations
        const isShowAllLocations = Number(
            this.activatedRoute.snapshot.queryParamMap.get(activeInactiveLocationQueryParam),
        );

        // get search value
        const userSearchParamValue = this.activatedRoute.snapshot.queryParamMap.get(userSearchParam);

        // get search value
        const userSearchPageIndexParamValue = Number(
            this.activatedRoute.snapshot.queryParamMap.get(userSearchPageIndexParam),
        );

        // get search value
        const userSearchPageSizeParamValue = Number(
            this.activatedRoute.snapshot.queryParamMap.get(userSearchPageSizeParam),
        );

        // get global query params
        let appQueryParams: AppQueryParams;
        let route: string;
        if (this.selectedCustomer) {
            route = '/pages/customerEditor/details';
            appQueryParams = {
                c: currentCustomerId,
                cid: +this.selectedCustomer,
                lg: locationGroupId || undefined,
                lt: isShowAllLocations || undefined,
                s: userSearchParamValue,
                pi: userSearchPageIndexParamValue,
                ps: userSearchPageSizeParamValue,
            };
        } else {
            route = '/pages/admin';
            appQueryParams = {
                c: currentCustomerId,
                lg: locationGroupId || undefined,
                lt: isShowAllLocations || undefined,
                s: userSearchParamValue,
                pi: userSearchPageIndexParamValue,
                ps: userSearchPageSizeParamValue,
            };
        }
        // Navigating with required params
        this.router.navigate([route], {
            queryParams: appQueryParams,
            relativeTo: this.activatedRoute,
        });
    }

    /**
     * Global function to handle all snackbar events
     */
    private showSnackBar(message) {
        const simpleSnackBarRef = this.snackBar.open(message, this.dismissBtnText);
        setTimeout(simpleSnackBarRef.dismiss.bind(simpleSnackBarRef), 10000);
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }
    /**
     * Global function to handle snackbar events which need to display for longer duration.
     */
    private showSnackBarForLongDuration(message) {
        this.simpleSnackBarRefLongDuration = this.snackBar.open(message, this.dismissBtnText);
        setTimeout(this.simpleSnackBarRefLongDuration.dismiss.bind(this.simpleSnackBarRefLongDuration), 10000);
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * Customer Selecte State Change
     * @param selectedCustomer
     */
    public changeSelecteState(selectedCustomer: Customer) {
        selectedCustomer.isSelected = !selectedCustomer.isSelected;
        this.checkSelectAll();

        const selectedCustomers: UsersDetailInfo[] = this.initialCustomers.filter((x) => x.isSelected);
        this.selectedCustomers = selectedCustomers;
        this.isAtleastOneCustomerSelected = selectedCustomers.length > 0;
        this.showErrorMessage = this.isAtleastOneCustomerSelected;
        if(this.loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN){
        this.customerSelectionErrorMessage = !this.isAtleastOneCustomerSelected;
        }
        if (this.selectedCustomers.length > 0) {
            this.customerID = this.selectedCustomerIdForLocation = selectedCustomer.isSelected ? selectedCustomer.customer.customerID : this.selectedCustomers[0].customer.customerID;
            // update locations list for selected customer on change of customers in assign customer tab
            if (this.selectedCustomerIdForLocation) {
                this.setSelectedLocations(this.selectedCustomerIdForLocation);
            }
        } else {
            // set locations list to empty if no customers assigned
            this.locations = [];
            this.selectAllLocations = false;
        }
        this.isCustomerSelected();
        this.toggleErrorMessage();
    }

    /**
     * Function to handle role changes
     */

    public changeSelectedRole(selectedRole: IRoles) {
        this.userRole = selectedRole.name;
        if (this.userInformation) {
            this.userInformation.userRole = this.userRole;
        }

        this.disableUserName();
        /** #9727 - All the customers should be selected by default at the time of new user creation for Admin */
        if (this.userRole === USER_ROLES.ADMIN) {
            this.customers.forEach((a) => (a.isSelected = true));
            this.selectAll = true;
            this.selectedCustomers = this.customers;
            this.availableFeatures = this.allAdminFeatures;
            this.availableFeatures.forEach(v => v.viewable = true);
            this.selectedFeatures = this.availableFeatures.filter(v => v.viewable);
            this.disableSelectionForAdmin = true;
            this.showErrorMessage = true;
            this.customerSelected = !!this.selectedCustomers?.length;
            this.locationsSelected = true;
        } else {
            if (this.userEmailFromRoute === REGISTER_USER) {
                this.availableFeatures.forEach(v => { v.viewable = false; v.disabled = false});
                this.selectedFeatures = this.availableFeatures.filter(v => v.viewable);
            }
            /** If the role is None or Customer Admin */
            let selectedCustomers = new Array<UsersDetailInfo>();

            if (!(this.userEmailFromRoute === REGISTER_USER || this.originalRole === USER_ROLES.ADMIN)) {
                if (this.initialSelectedCustomers.length !== 0) {
                    selectedCustomers = this.initialSelectedCustomers;
                }
            }

            this.customers.forEach((a) => (a.isSelected = false));
            selectedCustomers?.forEach((a) => (a.isSelected = true));
            this.selectAll = false;
            this.selectedCustomers = selectedCustomers;
            this.availableFeatures =
                this.userRole === USER_ROLES.NONE
                    ? this.allAdminFeatures.filter((y) => y.id !== REGISTER_USER_ID)
                    : this.allAdminFeatures;
            this.disableSelectionForAdmin = false;
            if (
                (this.userRole === USER_ROLES.CUSTOMER_ADMIN || this.userRole === USER_ROLES.NONE || this.userRole === USER_ROLES.CUSTOMER_USER_MANAGER) &&
                selectedCustomers.length > 0
            ) {
                this.showErrorMessage = true;
            } else {
                this.showErrorMessage = false;
            }
            this.setFeatures();

            if(this.userRole === USER_ROLES.CUSTOMER_USER_MANAGER)
            {
                this.availableFeatures.forEach((item) => { if(item.id === REGISTER_USER_ID) { item.viewable = true; item.disabled = true; }});
            }

            this.removeCustEditorFeature();
        }
    }

    /**
     * Customer Selected State Change
     */

    public changeSelectedState(feature) {
        feature.viewable = !feature.viewable;
        this.selectedFeatures = this.availableFeatures.filter((x) => x.viewable);
    }

    /**
     * Toggle Error Message of 'At least one customer should be associated' based on whether one checkbox is selected or not
     */
    public toggleErrorMessage() {
        // Based on Checkbox ischecked or not the showErrorMessageOnInputChange property is filtered out
        const showErrorMessageOnSelectionChange = this.initialCustomers.filter((customer) => customer.isSelected);

        showErrorMessageOnSelectionChange.length > 0 ? (this.showErrorMessage = true) : (this.showErrorMessage = false);

        if (this.userRole === USER_ROLES.ADMIN) {
            this.showErrorMessage = true;
        }

        if(showErrorMessageOnSelectionChange.length > 0){
            this.customerSelectionErrorMessage = false;
        }
    }

    /**
     * check Select All
     */
    public checkSelectAll(val?) {
        if (
            this.initialCustomers.filter((x) => x.isSelected).length > 0 &&
            this.initialCustomers.filter((x) => x.isSelected).length === this.customers.length
        ) {
            this.selectAll = true;
            this.customerSelectionErrorMessage = false;
        } else {
            this.toggleErrorMessage();
            this.selectAll = false;
        }
        this.isAtleastOneCustomerSelected = this.selectAll;
    }

    /**
     * Change the state of locations checkbox
     */
    public onLocationChange(selectedLocation, selectedCustomerIdForLocation) {
        const locationObject = this.selectedUser.customers.find(
            (x) => x.customer.customerID === selectedCustomerIdForLocation,
        );
        if (locationObject) {
            this.selectedUser.customers.forEach((element) => {
                if (element.customer.customerID === selectedCustomerIdForLocation) {
                    selectedLocation.viewable = !selectedLocation.viewable;
                    element.customer.locations = this.locations;
                }
            });
            
            this.selectAllLocations = locationObject.customer.locations.filter(location => !location.disabled).every((x) => x.viewable);
        } else {
            this.newSelectedLocationData(selectedLocation, selectedCustomerIdForLocation);
        }

        this.isLocationsSelected();
    }

    /**
     * Set locations for the new selected customer
     */
    private newSelectedLocationData(selectedLocation, selectedCustomerIdForLocation) {
        selectedLocation.viewable = !selectedLocation.viewable;
        const customerExists = this.selectedUser.customers.some(
            (x) => x.customer.customerID === selectedCustomerIdForLocation,
        );

        if (!customerExists) {
            this.selectedUser.customers.push({
                defaultCustomer: false,
                customer: {
                    customerID: selectedCustomerIdForLocation,
                    locations: this.locations,
                },
            });
        }

        this.selectAllLocations = this.selectedUser.customers.find(
            (x) => x.customer.customerID === selectedCustomerIdForLocation).customer.locations.filter(location => !location.disabled).every((x) => x.viewable) ;
    }

    /**
     * Set the state of select all checkbox for location tab
     */
    public toggleSelectAllLocations() {
        if (this.userRole === USER_ROLES.ADMIN) {
            this.selectAllLocations = true;
            for (const locations of this.locations) {
                locations.viewable = this.selectAllLocations;
            }
            this.locationsSelected = true;
        } else {
            this.selectAllLocations = !this.selectAllLocations;

            if(this.selectAllLocations){
                const customerExists = this.selectedUser.customers.some(
                    (x) => x.customer.customerID === this.selectedCustomerIdForLocation,
                );

                if (!customerExists) {
                    this.selectedUser.customers.push({
                        defaultCustomer: false,
                        customer: {
                            customerID: this.selectedCustomerIdForLocation,
                            locations: this.locations,
                        },
                    });
                }
            }

            for (const locations of this.locations) {
                if (!locations.isChecked) {
                    locations.viewable = this.selectAllLocations;
                }
            }
            // if selectall chosen for existing customer push customerId to created Array used for API call on save
            if (this.selectAllLocations) {
                if(this.loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN || this.loggedInUserRole === USER_ROLES.CUSTOMER_USER_MANAGER || this.loggedInUserRole === USER_ROLES.ADMIN){
                    this.selectedUser.customers.forEach(element => {
                        if(element.customer.customerID === this.selectedCustomerIdForLocation){
                            element.customer.locations = this.locations;
                            }
                    });
                }
                else{
                    this.selectedAllCustomers.push(this.selectedCustomerIdForLocation);
                }
            } else {
                const selectedCustomer = this.selectedAllCustomers.find(
                    (x) => x === this.selectedCustomerIdForLocation,
                );
                if (selectedCustomer) {
                    this.selectedAllCustomers.splice(
                        this.selectedAllCustomers.indexOf(this.selectedCustomerIdForLocation),
                        1,
                    );
                }
            }

            this.isLocationsSelected();
        }
    }

    /**
     * Set the state of select all checkbox for location tab for CA and None role
     */
    private toggleSelectAllLocationsForDifferentRole() {
        this.selectAllLocations = this.locations.every((val) => val.viewable);
        this.isLocationsSelected();
    }

    private isLocationsSelected(){
        this.locationsSelected = true;
        // TODO - Reduce to linear one
        let customersWithAtleastOneLoc = false;

        this.customers?.forEach((customer) => {
            if(customer.isSelected && (this.selectedUser?.customers.length === 0 || (this.customersWithLocs?.includes(customer.customer.customerID) 
                && (!this.selectedUser?.customers?.some(a => a.customer.customerID === customer.customer.customerID)
                || !this.selectedUser?.customers?.filter(a => a.customer.customerID === customer.customer.customerID)[0].customer.locations?.some(a=>a.viewable))))) {
                this.locationsSelected = false;
            }
            if(customer.isSelected && this.customersWithLocs?.includes(customer.customer.customerID)) {
                customersWithAtleastOneLoc = true;
            }
        });

        if(!customersWithAtleastOneLoc){
            this.locationsSelected = true;
        }
    }

    private isCustomerSelected() {
        this.customerSelected = this.customers.length > 0 ? this.customers.some(c => c.isSelected) : false;
    }

    public resetPassword(user) {
        if (!user.userID) {
            user.userID = localStorage.getItem('userId');
        }

        this.userName = user.userName;
        this.isUser = Boolean(user.userID);
        this.uiUtilsService.safeChangeDetection(this.cdr);

        if (user.isActive) {
            const msg = this.PAGE_TEXT.ARE_YOU_SURE.replaceAll('%', this.userName);
            this.matDialog
                .open(ConfirmationDialogComponent, {
                    disableClose: true,
                    data: {
                        title: this.PAGE_TEXT.CONFIRMATION,
                        message: msg,
                        okText: this.confirm,
                        cancelText: this.cancel,
                    },
                })
                .afterClosed()
                .subscribe((result) => {
                    if (result.whichButtonWasPressed === 'ok') {
                        this.usersService
                            .resetUserPassword(this.userName)
                            .pipe(catchError((err) => throwError(null)))
                            .subscribe(
                                (res) => {
                                    this.showSnackBar(
                                        `${this.PAGE_TEXT.RESET_PASSWORD_FOR} ${this.userName} ${this.updateSuccessfully}`,
                                    );
                                    this.setupAdminRoute();
                                },
                                (err) => {
                                    this.showSnackBar(
                                        `${this.PAGE_TEXT.RESET_PASSWORD_FOR} ${this.userName} ${this.updateFailed}`,
                                    );
                                },
                            );
                    } else if (result.whichButtonWasPressed === 'cancel') {
                    }
                });
        } else {
            this.isLoading = true;
            this.changeState(user.userID, true);
        }
    }
}
