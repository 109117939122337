<div id="customerDetailUserEditorId">
    <mat-card-header>
        <div class="auto-margin search-section">
            <mat-form-field class="full-width edit-cus-users-search-hint">
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <input
                    matInput
                    type="text"
                    placeholder="{{ 'COMMON.SEARCH_TEXT' | translate }}"
                    [formControl]="searchString"
                />
            </mat-form-field>
        </div>
        <div class="app-flex-filler"></div>
        <div class="auto-margin pad-r-25">
            <span class="textIntial">{{ 'COMMON.VIEW_ASSIGNED' | translate }}</span>
            <mat-slide-toggle
                [checked]="viewAssigned"
                [(ngModel)]="viewAssigned"
                (ngModelChange)="onChangeviewAssigned($event)"
                class="pad-l-10 edit-cus-users-assigned-hint"
            ></mat-slide-toggle>
        </div>
    </mat-card-header>
    <mat-card-content>
        <app-loader [isLoading]="isLoading"></app-loader>
        <div id="customersPageTable" [hidden]="!isMatTableVisible">
            <mat-table #table [dataSource]="userDataSource" matSort>
                <ng-container matColumnDef="isSelected">
                    <mat-header-cell *matHeaderCellDef class="fixCellWidth30">
                        <mat-checkbox
                            class="edit-cus-users-check-hint"
                            [checked]="isAllSelected"
                            (change)="toggleAllDisplayedUsersSelection($event)"
                        ></mat-checkbox>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-checkbox
                            [checked]="element.isSelected"
                            [disabled]="!element.isActive"
                            (change)="toggleUserSelection(element, $event)"
                        ></mat-checkbox>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="username">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="edit-cus-users-username-hint">{{
                        'CUSTOMER_EDITOR.ASSIGN_USERS.USER_NAME' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.username }} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="userEmail">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="edit-cus-users-email-hint">{{
                        'CUSTOMER_EDITOR.ASSIGN_USERS.USER_EMAIL' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.userEmail }} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="edit-cus-users-name-hint">{{
                        'CUSTOMER_EDITOR.ASSIGN_USERS.NAME' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.name }} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="role">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="edit-cus-users-role-hint">{{
                        'CUSTOMER_EDITOR.ASSIGN_USERS.ROLE' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.role }} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="lastLogin">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="edit-cus-users-login-hint">{{
                        'CUSTOMER_EDITOR.ASSIGN_USERS.LAST_LOGIN' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.lastLogin | date: dateFormat:timeZone }} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="features">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="edit-cus-users-feats-hint">{{
                        'CUSTOMER_EDITOR.ASSIGN_USERS.ASSIGNED_FEATURES' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element" class="assign-user-feature-cell">
                        <ng-container *ngFor="let eachFeature of element.features">
                            <span>{{ eachFeature }}</span> <span class="comma-space">, </span>
                        </ng-container>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="isActive">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="edit-cus-users-status-hint">{{
                        'CUSTOMER_EDITOR.ASSIGN_USERS.ACTIVE_INACTIVE' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element"
                        >{{ (element.isActive ? 'COMMON.YES_TEXT' : 'COMMON.NO_TEXT') | translate }}
                    </mat-cell>
                </ng-container>
                <div class="loc">
                    <ng-container matColumnDef="editLoc">
                        <mat-header-cell *matHeaderCellDef></mat-header-cell>
                        <mat-cell *matCellDef="let element" class="text-right">
                            <mat-icon class="edit-cus-users-edit-hint" (click)="locationAssociation(element)">mode_edit</mat-icon>
                        </mat-cell>
                    </ng-container>
                </div>
                <mat-header-row *matHeaderRowDef="customerDtlUserEdtrColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: customerDtlUserEdtrColumns"></mat-row>
            </mat-table>
        </div>
        <div class="notabledataFound custom-table" *ngIf="!isMatTableVisible">
            {{ 'ADMIN.NO_DATA_AVAILABLE' | translate }}
        </div>
    </mat-card-content>

    <mat-card-actions>
        <div class="auto-margin">
            <button
                class="edit-cus-users-add-hint"
                [disabled]="!addNewUserVisible"
                (click)="setupAdminDetailRoute('')"
                mat-fab
                color="accent"
                matTooltip="{{ 'COMMON.ADD_NEW_USER' | translate }}"
                matTooltipPosition="above"
            >
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <div class="app-flex-filler"></div>
        <div class="auto-margin edit-cus-users-paginator-hint" [hidden]="totalPaginationLength <= 10">
            <mat-paginator
                #batteryWidgetPaginator
                [length]="totalPaginationLength"
                [pageIndex]="0"
                [pageSize]="25"
                [hidden]="!isMatTableVisible"
                [pageSizeOptions]="[10, 25, 100]"
                (page)="changePage($event)"
            >
            </mat-paginator>
        </div>
        <button mat-button *ngIf="isMatTableVisible" [disabled]="!isSaveEnabled" class="edit-cus-users-save-hint" (click)="saveUserInformation()">
            {{ 'COMMON.SAVE_BUTTON' | translate }}
        </button>
    </mat-card-actions>
</div>
