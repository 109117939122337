import {
    Component,
    OnInit,
    ViewEncapsulation,
    OnDestroy,
    ChangeDetectionStrategy,
    ViewChild,
    ChangeDetectorRef,
    Inject,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { StringUtils } from 'app/shared/utils/string-utils';
import { MultiSelectGroupComponent } from 'app/shared/components/multi-select/multi-select-group/multi-select-group.component';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { VaultService } from 'app/pages/vault/vault.service';
import { environment } from 'app/environments/environment';
import { saveAs as importedSaveAs } from 'file-saver';
import { BehaviorSubject } from 'rxjs';
import {
    DataExportFile,
    DataExportType,
    EntityExportType,
    ExportDataAveraging,
    ExportPerSheet,
    LocationType,
    TelemetryService,
} from 'app/shared/services/telemetry.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { EntityService } from 'app/shared/services/entity.service';
import { VaultDisplayModel, VaultLocationExport, VaultSelectable } from 'app/shared/models/vault';
import { Selectable } from 'app/shared/models/selectable';
import { LocationGroupSelector } from 'app/shared/models/location-group';
import { BLANK, CUSTOM, customerQueryParam, NONE, OMIT, ONE_HOUR } from 'app/shared/models/customer';
import { EntitySelectorEntity, EntitySelectorObject } from 'app/shared/models/entities';
import { ExportSetting } from 'app/shared/models/telemetry';
import { EntityResponseItem } from 'app/shared/models/view-data';
import { MultiSelectAutoCompleteComponent } from 'app/shared/components/multi-select/multi-select-auto-complete/multi-select-auto-complete.component';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { LocationService } from 'app/shared/services/location.service';
import { LocationData, LocationListArgs } from 'app/shared/models/locations-entities-data';
import { LocationGroupService } from 'app/shared/services/location-group.service';

@Component({
    selector: 'ads-prism-vault-location-export',
    templateUrl: './vault-location-export.component.html',
    styleUrls: ['./vault-location-export.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdsPrismVaultLocationExportComponent implements OnInit, OnDestroy {
    @ViewChild('multiSelectAutoCompleteLocations')
    private multiSelectAutoCompleteLocations: MultiSelectAutoCompleteComponent;
    @ViewChild('entitiesGroupMultiselect') public entitiesGroupMultiselect: MultiSelectGroupComponent;
    public vaultExport: VaultLocationExport = {
        fileType: DataExportType.AdsCsv,
        singleOrMultiple: DataExportFile.Multiple,
        entityRowColumn: EntityExportType.Column,
        dataAverage: ExportDataAveraging.OneHour,
        isRoundingEnabled: false,
        timeStampType: OMIT,
        exportedPerSheet: ExportPerSheet.Location,
        startDate: this.convertDate(
            new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7, 0, 0, 0).toString(),
        ),
        endDate: this.convertDate(
            new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1, 0, 0, -1).toString(),
            true,
        ),
    };
    public filteredLocations = new Array<Selectable>();
    public dataAveraging = new Array<VaultDisplayModel>();
    private subscriptions = new Array<Subscription>();
    public locationTypeArray = new Array<VaultDisplayModel>();
    public fileTypeArray = new Array<VaultDisplayModel>();
    public csvFileArray = new Array<VaultDisplayModel>();
    public entityPerColumnRowArray = new Array<VaultDisplayModel>();
    public dataToBeexportedPerSheet = new Array<VaultDisplayModel>();
    public isLoading: boolean;
    public selectedLocations = new Array<VaultSelectable>();
    public entityList = new Array<VaultSelectable>();
    private backupAnsrInfo: {name: string, id: number}[];
    public locationGroups = new Array<LocationGroupSelector>();
    public selectedAverage = ONE_HOUR;
    private stringUtils = new StringUtils();
    public selectedEntitySet = new Set();
    public selectedEntityIds = new Array<number>();
    public savedSettingEntitieIds = [];
    public savedSettingLocationIds = [];
    public isEntitiesInvalid = false;
    public isLocationGroupInvalid = false;
    public preSelectedLocationGroup: VaultSelectable;
    public preSelectedLocation = new Array<VaultSelectable>();
    public translateKeys: Array<string> = [
        'COMMON.NONE',
        'COMMON.TWO_MIN',
        'COMMON.FIVE_MIN',
        'COMMON.FIFTEEN_MIN',
        'COMMON.ONE_HOUR',
        'COMMON.ONE_DAY',
        'COMMON.ONE_WEEK',
        'COMMON.ONE_MONTH',
        'COMMON.LOCATION',
        'COMMON.LOCATION_GROUP',
        'COMMON.EXL_EXPORT_SUCCESS',
        'COMMON.CSV',
        'COMMON.EXCEL',
        'COMMON.ENTITY',
        'COMMON.SINGLE',
        'COMMON.MULTIPLE',
        'COMMON.ROW',
        'COMMON.COLUMN',
        'COMMON.CSV_EXPORT_SUCCESS',
        'VAULT.VAULT_TELEMETRY.EXPORT.NO_DATA_EXPORT_ERR',
        'COMMON.DISMISS_TEXT',
        'VAULT.VAULT_CREATE_FOLDER.DATAEXPORTS',
        'VAULT.DATAEXPORTS',
        'VAULT.VAULT_TELEMETRY.EXPORT.EXPORT_ERR_SNACKBAR_MSG',
        'COMMON.SAVE_SETTING',
        'COMMON.UPDATE_SETTING',
    ];
    public none: string;
    public twoMin: string;
    public fiveMin: string;
    public fifteenMin: string;
    public oneHour: string;
    public oneDay: string;
    public oneWeek: string;
    public oneMonth: string;
    public location: string;
    public locationGroup: string;
    public entity: string;
    public csv: string;
    public single: string;
    public multiple: string;
    public row: string;
    public column: string;
    public isValidDateRange = true;
    public locationGroupId: string;
    public isStatusCodeFlagValue = false;
    public exportSuccessMsg: string;
    public dismissText: string;
    public noDataMsg: string;
    public dataExports: string;
    public exportErrMsg: string;
    public saveSettingText: string;
    public updateSettingText: string;
    public settingText: string;
    public excel: string;
    public showSaveSetting = false;
    public exportSettingName: string;
    public validSettingName: boolean;
    public settingNameReadonly: boolean;
    public preStartDate: Date;
    public preEndDate: Date;
    public exportExcelSuccessMsg: string;
    public isExportDownload: boolean;
    public readonly apiUri = environment.serviceUrl;
    public entitySelectorObjects$ = new BehaviorSubject<EntitySelectorObject[]>(undefined);
    public entitySelectorANSRObjects$ = new BehaviorSubject<EntitySelectorObject[]>(undefined);
    public includeInactiveLocations = false;
    public showpageHint: boolean;
    /** Whenever settings should be applied not matter if it is in edit mode (for external use of this popup) */
    public forceApplySettings: boolean;

    constructor(
        public refDialog: MatDialogRef<AdsPrismVaultLocationExportComponent>,
        @Inject(MAT_DIALOG_DATA) private savedSettingData: ExportSetting,
        private activatedRoute: ActivatedRoute,
        private entityService: EntityService,
        private uiUtilsService: UiUtilsService,
        private changeDetectorReference: ChangeDetectorRef,
        private telemetryService: TelemetryService,
        private translate: TranslateService,
        private locationService: LocationService,
        private locationGroupService: LocationGroupService,
        private statusCodeService: StatusCodeService,
        private domOperationService: DomOperationUtilsService,
        private vaultService: VaultService,
        private snackBarNotificationService: SnackBarNotificationService,
    ) {
        translate.get(this.translateKeys).subscribe((translateValues) => {
            this.none = translateValues['COMMON.NONE'];
            this.twoMin = translateValues['COMMON.TWO_MIN'];
            this.fiveMin = translateValues['COMMON.FIVE_MIN'];
            this.fifteenMin = translateValues['COMMON.FIFTEEN_MIN'];
            this.oneHour = translateValues['COMMON.ONE_HOUR'];
            this.oneDay = translateValues['COMMON.ONE_DAY'];
            this.oneWeek = translateValues['COMMON.ONE_WEEK'];
            this.oneMonth = translateValues['COMMON.ONE_MONTH'];
            this.location = translateValues['COMMON.LOCATION'];
            this.entity = translateValues['COMMON.ENTITY'];
            this.locationGroup = translateValues['COMMON.LOCATION_GROUP'];
            this.csv = translateValues['COMMON.CSV'];
            this.excel = translateValues['COMMON.EXCEL'];
            this.single = translateValues['COMMON.SINGLE'];
            this.multiple = translateValues['COMMON.MULTIPLE'];
            this.row = translateValues['COMMON.ROW'];
            this.column = translateValues['COMMON.COLUMN'];
            this.exportSuccessMsg = translateValues['COMMON.CSV_EXPORT_SUCCESS'];
            this.exportExcelSuccessMsg = translateValues['COMMON.EXL_EXPORT_SUCCESS'];
            this.noDataMsg = translateValues['VAULT.VAULT_TELEMETRY.EXPORT.NO_DATA_EXPORT_ERR'];
            this.dismissText = translateValues['COMMON.DISMISS_TEXT'];
            this.dataExports = translateValues['VAULT.VAULT_CREATE_FOLDER.DATAEXPORTS'];
            this.exportErrMsg = translateValues['VAULT.VAULT_TELEMETRY.EXPORT.EXPORT_ERR_SNACKBAR_MSG'];
            this.saveSettingText = translateValues['COMMON.SAVE_SETTING'];
            this.updateSettingText = translateValues['COMMON.UPDATE_SETTING'];
        });
    }
    public ngOnInit() {
        if(this.savedSettingData) {
            this.forceApplySettings = this.savedSettingData.forceApplySettings;
        }
        const hintSubs = this.domOperationService.showpageHint.subscribe((data) => {
            this.showpageHint = data;
            this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
        });
        this.subscriptions.push(hintSubs);
        this.isLoading = true;
        this.isExportDownload = false;
        this.exportSettingName = '';
        this.settingText = this.saveSettingText;
        this.dataAveraging = [
            { id: ExportDataAveraging.None, value: this.none },
            { id: ExportDataAveraging.FiveMinute, value: this.fiveMin },
            { id: ExportDataAveraging.Fifteenminute, value: this.fifteenMin },
            { id: ExportDataAveraging.OneHour, value: this.oneHour },
            { id: ExportDataAveraging.OneDay, value: this.oneDay },
            { id: ExportDataAveraging.OneWeek, value: this.oneWeek },
            { id: ExportDataAveraging.OneMonth, value: this.oneMonth },
            { id: ExportDataAveraging.Twominute, value: this.twoMin },
        ];

        this.locationTypeArray = [
            { id: LocationType.Location, value: this.location },
            { id: LocationType.LocationGroup, value: this.locationGroup },
        ];
        this.fileTypeArray = [
            { id: DataExportType.AdsCsv, value: this.csv },
            { id: DataExportType.Excel, value: this.excel },
        ];
        this.csvFileArray = [
            { id: DataExportFile.Single, value: this.single },
            { id: DataExportFile.Multiple, value: this.multiple },
        ];
        this.entityPerColumnRowArray = [
            { id: EntityExportType.Row, value: this.row },
            { id: EntityExportType.Column, value: this.column },
        ];
        this.dataToBeexportedPerSheet = [
            { id: ExportPerSheet.Location, value: this.location },
            { id: ExportPerSheet.Entity, value: this.entity },
        ];
        this.activatedRoute.queryParamMap.subscribe((params) => {
            this.vaultExport.cid = Number(params.get(customerQueryParam));
        });

        if (this.savedSettingData && (this.savedSettingData.forceApplySettings || this.telemetryService.openExistingExport)) {
            this.loadSavedSetting();
        }

        this.locationGroups = this.locationGroupService.globalSelectableLocationGroups;
        this.loadLocations();
    }

    loadLocations(assignCurrent = false) {
        const locationArgs = <LocationListArgs> {
            cid: this.vaultExport.cid,
            IncludeInactiveLocations: this.includeInactiveLocations,
        };
        this.preSelectedLocation = [];
        const locationAndEntitiesSubscription = this.locationService.getLocationData(locationArgs).subscribe(
            (resp) => {
                if (!resp || !resp.l) { return;}

                this.filteredLocations = [];
                const locations = resp.l.sort((a, b) =>
                    a.n && b.n
                        ? a.n.localeCompare(b.n)
                        : a.n
                        ? 1
                        : -1,
                );

                this.backupAnsrInfo = [];
                locations.forEach( item => {
                    let isChecked = assignCurrent ? !!this.selectedLocations.find(x => x.id === item.lid) : false;
                    if (this.savedSettingData && (this.savedSettingData.forceApplySettings || this.telemetryService.openExistingExport)) {
                        isChecked = this.savedSettingLocationIds.indexOf(item.lid) !== -1
                    }

                    item.ae.forEach(entType => {
                        entType.entities.forEach(ent => {
                            this.backupAnsrInfo.push({name: ent.e, id: ent.id});
                        });
                    });
                    this.filteredLocations.push({
                        name: item.n,
                        id: item.lid,
                        isChecked: isChecked
                    });
                });
                this.preSelectedLocation = this.filteredLocations.filter((item) => item.isChecked);
                this.getSelectedEntities({ selectedLocations: this.preSelectedLocation });
                this.selectedLocations = this.filteredLocations.filter((item) => item.isChecked);

                if (this.savedSettingData && (this.savedSettingData.forceApplySettings || this.telemetryService.openExistingExport)) {
                    this.loadSavedSettingLocationsEntities();
                }
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
            }
        );
        this.subscriptions.push(locationAndEntitiesSubscription);
    }

    public changeDataToBeExported() {
        if (
            this.vaultExport.fileType !== DataExportType.AdsCsv &&
            this.vaultExport.singleOrMultiple === DataExportFile.Multiple
        ) {
            this.vaultExport.exportedPerSheet = ExportPerSheet.Location;
        }
    }

    public loadSavedSettingLocationsEntities() {
        if (this.savedSettingData.lids.length > 0) {
            this.vaultExport.locationType = LocationType.Location;
            this.getSelectedEntities({ selectedLocations: this.filteredLocations.filter((item) => item.isChecked) });
        } else {
            const preSelectedLocationGroup = this.savedSettingData.locationGroupId
                ? this.locationGroups.filter((item) => item.id === this.savedSettingData.locationGroupId)
                : this.locationGroups.filter((item) => item.id === 0);
            this.locationGroupId = this.savedSettingData.locationGroupId
                ? String(this.savedSettingData.locationGroupId)
                : '0';
            this.preSelectedLocationGroup = preSelectedLocationGroup[0];
            this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);

            this.vaultExport.locationType = LocationType.LocationGroup;
            this.getSelectedEntities({ locationGroupId: this.savedSettingData.locationGroupId || 0 });
        }
    }

    public loadSavedSetting() {
        this.settingNameReadonly = true;
        this.settingText = this.updateSettingText;
        this.exportSettingName = this.savedSettingData.name;
        this.validateSettingName();

        if(this.savedSettingData.exportType) {
            this.vaultExport.fileType = this.savedSettingData.exportType;
        }
        if(this.savedSettingData.singleFile) {
            this.vaultExport.singleOrMultiple = this.savedSettingData.singleFile
                ? DataExportFile.Single
                : DataExportFile.Multiple;
        }
        if(this.savedSettingData.interval !== null && this.savedSettingData.interval !== undefined) {
            this.vaultExport.dataAverage = this.savedSettingData.interval;
        }
        if(this.savedSettingData.useRounding) {
            this.vaultExport.isRoundingEnabled = this.savedSettingData.useRounding;
        }
        this.savedSettingEntitieIds = this.savedSettingData.eids;
        this.savedSettingLocationIds = this.savedSettingData.lids;

        if (this.savedSettingData.exportType === 0) {
            this.vaultExport.entityRowColumn = this.savedSettingData.entityPerColumn
                ? EntityExportType.Column
                : EntityExportType.Row;
        } else {
            this.vaultExport.exportedPerSheet = this.savedSettingData.excelStyle;
        }
        this.vaultExport.missingTimestampValue = this.savedSettingData.missingTimestampValue;
        if (this.savedSettingData.includeMissingTimestamps) {
            if (this.savedSettingData.missingTimestampValue) {
                this.vaultExport.timeStampType = CUSTOM;
            } else {
                this.vaultExport.timeStampType = BLANK;
            }
        } else {
            this.vaultExport.timeStampType = OMIT;
        }
        if(this.savedSettingData.endDate && this.savedSettingData.startDate) {
            const timeDiff = Math.abs(
                new Date(this.savedSettingData.endDate.substr(0, this.savedSettingData.endDate.indexOf('T'))).getTime() -
                    new Date(this.savedSettingData.startDate).getTime(),
            );
            const dateNow = new Date().getTime();
            this.preStartDate = new Date(dateNow - timeDiff);
            const startDate = this.preStartDate.toISOString();
            this.vaultExport.startDate = startDate.substr(0, startDate.indexOf('T'));
        }
        this.preEndDate = new Date();
        this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
    }

    public missingTimestamp(timeStamp) {
        this.vaultExport.missingTimestampValue = timeStamp;
    }
    public restrictKeys(event): boolean {
        if (event.keyCode === 222 || event.keyCode === 188 || event.keyCode === 190 || event.keyCode === 220) {
            return false;
        }
        return true;
    }
    public handleSelectedLocationsGroup(locationGroup): void {
        this.isLocationGroupInvalid = !(locationGroup.length > 0);
        if (locationGroup.length > 0) {
            this.isLoading = true;
            this.locationGroupId = locationGroup[0].id;
            this.savedSettingEntitieIds = [];
            this.getSelectedEntities({ locationGroupId: Number.parseInt(this.locationGroupId, 10) });
        }
    }
    public handleSelectedLocations(locations: Array<VaultSelectable>): void {
        this.selectedLocations = [];
        this.isLoading = true;
        this.selectedLocations = locations.filter((location: VaultSelectable) => location.isChecked);
        if (this.selectedLocations.length > 0) {
            this.savedSettingEntitieIds = [];
            this.getSelectedEntities({ selectedLocations: this.selectedLocations });
        } else {
            this.isLoading = false;
            this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
        }
    }
    public handleSelectedEntities(entities: EntitySelectorEntity[]): void {
        const addAnsrIds: number[] = [];
        this.selectedEntityIds = [];
        this.selectedEntityIds = entities
            .filter((entity: VaultSelectable) => entity.isChecked)
            .map((entity) => {
                /**
                 * Since we're only showing ANSR entities once by name no matter
                 * how many entity IDs there are associated with that name,
                 * if any ANSR entities are selected, we need the selectedEntityIds array
                 * to include all ANSR ids for that given name.
                 *
                 * If not ANSR entity, just add to the list of IDs
                */
                if (entity.isANSR) {
                    this.backupAnsrInfo.map(x => {
                        if (x.name === entity.name && x.id !== entity.id) {
                            addAnsrIds.push(x.id);
                        }
                    });
                }
                return entity.id;
            });
        // Now add all missing ANSR from dup names to the other entity list
        this.selectedEntityIds = this.selectedEntityIds.concat(addAnsrIds);

        this.isEntitiesInvalid = !(this.selectedEntityIds.length > 0);
        this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
    }

    public getSelectedEntities({
        selectedLocations,
        locationGroupId,
    }: {
        selectedLocations?: VaultSelectable[];
        locationGroupId?: number;
    }) {
        if (selectedLocations && selectedLocations.length === 0) {
            return;
        }
        const entitiesSubscription = this.entityService
            .getEntitiesByLocation(
                this.vaultExport.cid,
                selectedLocations ? selectedLocations.map(({ id }) => id) : undefined,
                locationGroupId,
            )
            .subscribe(
                (entities: Array<EntityResponseItem>) => {
                    this.isLoading = false;
                    const filteredEntities = new Array<VaultSelectable>();
                    const filteredANSREntities = new Array<VaultSelectable>();

                    const listOfAnsrIds = this.backupAnsrInfo.map(x => x.id);
                    entities.forEach((entity: EntityResponseItem) => {
                        let isChecked = false;
                        if (this.savedSettingEntitieIds && this.savedSettingEntitieIds.length > 0) {
                            isChecked = this.savedSettingEntitieIds.indexOf(entity.entityId) !== -1;
                        } else {
                            isChecked = this.stringUtils.defaultSelectedEntities(entity);
                        }
                        if (listOfAnsrIds.includes(entity.entityId)) {
                            /**
                             * To ensure we're only showing 1 listing per ANSR entity
                             * name, don't add to the list if that name already exists
                            */
                            if(!filteredANSREntities.find(x => x.name === entity.entityName)) {
                                filteredANSREntities.push({
                                    name: entity.entityName,
                                    id: entity.entityId,
                                    groupName: entity.displayGroupName,
                                    groupId: entity.displayGroup,
                                    isChecked: isChecked,
                                });
                            }
                        } else {
                            filteredEntities.push({
                                name: entity.entityName,
                                id: entity.entityId,
                                groupName: entity.displayGroupName,
                                groupId: entity.displayGroup,
                                isChecked: isChecked,
                            });
                        }
                    });
                    const entitySelectorObjects =
                        this.entityService.vaultSelectableToEntitySelectorObject(filteredEntities);
                    this.entitySelectorObjects$.next(entitySelectorObjects);


                    const ansrSelectorObjects =
                        this.entityService.vaultSelectableToEntitySelectorObject(filteredANSREntities, true);
                    this.entitySelectorANSRObjects$.next(ansrSelectorObjects);

                    this.entityList = filteredEntities;
                    if(!this.selectedEntityIds.length) {
                        this.selectedEntityIds = this.entityList
                            .filter((item) => item.isChecked)
                            .map((entity) => entity.id)
                            .concat(filteredANSREntities // Add in selected ANSR entities
                                .filter((item) => item.isChecked)
                                .map((entity) => entity.id));
                    } else {
                        // #30297 Filter them based on previous selection
                        this.selectedEntityIds = this.selectedEntityIds.filter(
                            id => this.entityList.find(e => e.id === id) || filteredANSREntities.find(e => e.id === id)
                        );
                    }
                    this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
                },
                () => {
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
                },
            );
        this.subscriptions.push(entitiesSubscription);
    }
    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
    public close(permissionsUpdated?: boolean) {
        this.refDialog.close({ success: permissionsUpdated });
    }
    public locationsInvalid() {
        return (
            this.multiSelectAutoCompleteLocations &&
            this.selectedLocations &&
            this.selectedLocations.length < 1 &&
            this.multiSelectAutoCompleteLocations.searchInput.ngControl.touched
        );
    }
    public validDateRange($event) {
        this.isValidDateRange = $event;
    }
    public selectedStartDate($event) {
        this.vaultExport.startDate = this.convertDate($event);
    }
    public selectedEndDate($event) {
        this.vaultExport.endDate = this.convertDate($event, true);
    }
    public convertDate(str, endTime?: boolean) {
        const date = new Date(str),
            mnth = ('0' + (date.getMonth() + 1)).slice(-2),
            day = ('0' + date.getDate()).slice(-2);
        let res = [date.getFullYear(), mnth, day].join('-');
        if (endTime) {
            res = res + 'T23:59:59';
        }
        return res;
    }
    public changeMultiple() {
        this.vaultExport.singleOrMultiple = DataExportFile.Multiple;
        if (this.vaultExport.fileType === DataExportType.Excel) {
            this.vaultExport.dataAverage = NONE;
            this.vaultExport.singleOrMultiple = DataExportFile.Single;
        }
        return (
            this.vaultExport.fileType === DataExportType.AdsCsv &&
            this.vaultExport.singleOrMultiple === DataExportFile.Multiple
        );
    }
    public onExportSubmit() {
        const entitiesSelected = this.selectedEntityIds;
        let locationSelected = this.selectedLocations.map((location: Selectable) => location.id);
        if (this.vaultExport.locationType === LocationType.Location) {
            this.locationGroupId = '';
        } else {
            locationSelected = [];
        }
        this.isLoading = true;
        if (this.vaultExport.fileType === DataExportType.AdsCsv) {
            this.telemetryService
                .vaultExportLocation(this.vaultExport, entitiesSelected, locationSelected, this.locationGroupId)
                .subscribe(
                    (res) => {
                        // #23724 - do not display anything on success - we have signalR for this
                        if (res && res.length && this.isExportDownload) {
                            this.fileDownload(res);
                        }
                        if(!res) {
                            // #23725 'No data' toast if failed. Returns HTTP 200 on success and HTTP 204 on no data
                            this.snackBarNotificationService.raiseNotification(this.noDataMsg, this.dismissText, {
                                panelClass: 'custom-error-snack-bar',
                            }, false);
                        }
                        this.vaultDataExport();
                    },
                    (err) => {
                        this.snackBarNotificationService.raiseNotification(this.exportErrMsg, this.dismissText, {
                            panelClass: 'custom-error-snack-bar',
                        }, false);
                        this.isLoading = false;
                        this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
                    },
                );
        } else {
            this.telemetryService
                .vaultExcleExportLocation(this.vaultExport, entitiesSelected, locationSelected, this.locationGroupId)
                .subscribe(
                    (result) => {
                        if (result && result.length && this.isExportDownload) {
                            this.fileDownload(result);
                        }
                        this.statusCodeService.statusCodeFlag.subscribe((statusCodeFlagValue) => {
                            this.isStatusCodeFlagValue = statusCodeFlagValue;
                            if (statusCodeFlagValue === false) {
                                this.snackBarNotificationService.raiseNotification(
                                    this.exportExcelSuccessMsg,
                                    this.dismissText,
                                    { duration: 10000 },
                                );
                            } else {
                                this.snackBarNotificationService.raiseNotification(this.noDataMsg, this.dismissText, {
                                    panelClass: 'custom-error-snack-bar',
                                }, false);
                            }
                        });
                        this.vaultDataExport();
                    },
                    (err) => {
                        this.snackBarNotificationService.raiseNotification(this.exportErrMsg, this.dismissText, {
                            panelClass: 'custom-error-snack-bar',
                        }, false);
                        this.isLoading = false;
                        this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
                    },
                );
        }
    }

    public fileDownload(files) {
        if (files && files.length > 0) {
            this.isLoading = true;
            const selectedFiles = [];
            files.forEach((file) => {
                selectedFiles.push('/' + file);
            });
            const splitFile = selectedFiles[0].split('/');
            const fileName = selectedFiles.length === 1 ? splitFile.find((x) => x.includes('.')) : 'Files';
            this.vaultService.downloadFile(selectedFiles).subscribe((res) => {
                importedSaveAs(res, fileName);
            });
            this.isLoading = false;
        }
    }

    public validateSettingName() {
        this.exportSettingName = (this.exportSettingName || '').trim();
        this.validSettingName = this.exportSettingName.length > 0;
    }
    public vaultDataExport() {
        this.vaultService
            .getRootVaultListForCustomer()
            .subscribe(() => {
                this.isLoading = false;
            })
            .unsubscribe();
        const dataExportPath = '/' + this.dataExports + '/';
        this.refDialog.close();
        if (this.isStatusCodeFlagValue === false) {
            this.vaultService.getFilesForDirectory(dataExportPath);
        }
    }
    public saveSetting() {
        this.showSaveSetting = true;
    }
    public closeExportSetting() {
        this.showSaveSetting = false;
    }
    public saveExportSetting() {
        this.exportSettingName = (this.exportSettingName || '').trim();
        const entitiesSelected = this.selectedEntityIds;
        let locationSelected = this.selectedLocations.map((location: Selectable) => location.id);
        if (this.vaultExport.locationType === LocationType.Location) {
            this.locationGroupId = '';
        } else {
            locationSelected = [];
        }
        this.isLoading = true;
        this.telemetryService
            .saveExportSetting(
                this.exportSettingName,
                this.vaultExport,
                entitiesSelected,
                locationSelected,
                this.locationGroupId,
            )
            .subscribe(
                (result) => {
                    this.showSaveSetting = false;
                    this.isLoading = false;
                    this.settingNameReadonly = true;
                    this.telemetryService.settingChanged.next('yes');
                    this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
                },
                (err) => {
                    this.snackBarNotificationService.raiseNotification(err.error, this.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    }, false);
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
                },
            );
    }
}
