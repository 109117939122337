<div id="addAnnouncement">
    <!-- manage alert header -->
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'ADMIN.MANAGE_ALERT_HEADER' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <!-- content -->

    <div mat-dialog-content>
        <!-- Checkbox -->
        <div class="pad-b-20">
            <mat-checkbox class="active-checkbox"
                name="activeCheckbox"
                [(ngModel)]="activeCheckbox"
                (ngModelChange)="checkOriginalValues()">
                {{ 'ADMIN.MANAGE_ALERT_CHECKBOX' | translate }}
            </mat-checkbox>
        </div>

        <!-- Alert Text Entry Field -->
        <mat-form-field class="full-width">
            <textarea
                matInput
                [readonly]="!activeCheckbox"
                placeholder="{{'ADMIN.ALERT_TEXT_HEADER' | translate}}"
                [(ngModel)]="alertText"
                (ngModelChange)="onModelChange($event)"
                [attr.maxlength]="counter ? maxNumberOfCharacters : ''"
                maxlength="2048"
                rows="10" cols="80"
                autofocus>
            </textarea>
            <div>
                {{numberOfCharacters2}} / {{maxNumberOfCharacters}}
            </div>
        </mat-form-field>


        <!-- Start and End Dates Pickers -->


        <mat-form-field class="full-width start-date">
            <input
                matInput
                [readonly]="true"
                placeholder="{{'ADMIN.ALERT_START_DATE' | translate}}"
                [value]="alertStartDate | date: customerDateFormat"
                (change)="setAlertStartDate($event)"
                tabindex="-1"
                class="datepickerStyles"
                appDatetimepickerPosition
                [matDatetimepickerComp]="originalAlertStartPicker"
            />
            <input
                hidden
                [matDatetimepicker]="originalAlertStartPicker"
                (dateInput)="setAlertStartDate($event)"
                [disabled]="!activeCheckbox"
            />
            <mat-datetimepicker-toggle matSuffix [for]="originalAlertStartPicker">
            </mat-datetimepicker-toggle>
            <mat-datetimepicker
                #originalAlertStartPicker
                timeInterval="1"
                type="date"
                max="alertEndDate"
            >
            </mat-datetimepicker>
        </mat-form-field>

        <mat-form-field class="full-width end-date">
            <input
                matInput
                [readonly]="true"
                placeholder="{{'ADMIN.ALERT_END_DATE' | translate}}"
                [value]="alertEndDate | date: customerDateFormat"
                (change)="setAlertEndDate($event)"
                tabindex="-1"
                class="datepickerStyles"
                appDatetimepickerPosition
                [matDatetimepickerComp]="originalAlertEndPicker"
            />
            <input
                hidden
                [matDatetimepicker]="originalAlertEndPicker"
                (dateInput)="setAlertEndDate($event)"
                [disabled]="!activeCheckbox"
            />
            <mat-datetimepicker-toggle matSuffix [for]="originalAlertEndPicker">
            </mat-datetimepicker-toggle>
            <mat-datetimepicker
                #originalAlertEndPicker
                timeInterval="1"
                type="date"
            >
            </mat-datetimepicker>
        </mat-form-field>

        <!-- Error Handler -->
        <div class="mat-error full-width">
            <div *ngIf="endDateBfrToday">{{ 'COMMON.END_DATE_BEFORE_TODAY' | translate }}</div>
            <div *ngIf="endDateBfrStartDate">{{ 'COMMON.END_DATE_BEFORE_START_ERROR' | translate }}</div>
            <div *ngIf="startDateBfrToday">{{ 'COMMON.START_DATE_BEFORE_TODAY' | translate }}</div>
            <div *ngIf="startDateAfterEndDate ">{{ 'COMMON.START_DATE_AFTER_END_ERROR' | translate }}</div>
        </div>

    </div>
    <!-- add alert action -->
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="close()">
                {{ 'COMMON.CANCEL_BUTTON' | translate }}
            </button>
        </div>
        <div class="auto-margin">
            <button mat-button
                (click)="saveAnnouncement()"
                [disabled]="!isMandatoryFilled || !isOriginalValuesModified"
                [ngClass]="{'cd-dialog-save-hint': showpageHint}">
                {{ 'COMMON.SAVE_BUTTON' | translate }}
            </button>
        </div>
    </div>
</div>
