<div id="createFolderDialog">
    <app-loader [isLoading]="isCreatingFolder"></app-loader>
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'VAULT.CREATE_FOLDER_BUTTON' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <div mat-dialog-content>
        <div class="custom-error">
            <mat-form-field class="full-width top">
                <input
                    matInput
                    name="folderNameInput"
                    id="folderNameInput"
                    #folderNameInput="ngModel"
                    placeholder="{{ 'VAULT.VAULT_CREATE_FOLDER.FOLDER_NAME_PLACEHOLDER' | translate }}"
                    [(ngModel)]="folderName"
                    required
                    maxlength="254"
                    [pattern]="vaultFolderNamePattern"
                    (focus)="isFolderNameExist = false"
                    (change)="capitalizedStringWithSpace()"
                />
            </mat-form-field>
            <div class="custom-error-message">
                <span *ngIf="folderNameInput?.errors?.pattern">{{
                    'VAULT.VAULT_CREATE_FOLDER.FOLDER_NAME_PATTERN_VALIDATION' | translate
                }}</span>
                <span
                    *ngIf="isFolderNameExist && !folderNameInput?.errors?.pattern && !folderNameInput?.errors?.required"
                    >{{ 'VAULT.VAULT_CREATE_FOLDER.FOLDER_NAME_EXISTS_VALIDATION' | translate }}</span
                >
            </div>
        </div>
        <mat-form-field class="full-width">
            <input
                matInput
                name="folderLocation"
                placeholder="{{ 'VAULT.VAULT_CREATE_FOLDER.FOLDER_LOCATION_PLACEHOLDER' | translate }}"
                readonly
                value="{{ currentlySelectedDirectoryId }}"
            />
        </mat-form-field>
    </div>
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="close()">{{ 'COMMON.CLOSE' | translate }}</button>
        </div>
        <div class="auto-margin">
            <button
                mat-button
                (click)="createFolder()"
                [disabled]="folderNameInput?.errors?.pattern || folderNameInput?.errors?.required"
            >
                {{ 'COMMON.CREATE_BTN' | translate }}
            </button>
        </div>
    </div>
</div>
