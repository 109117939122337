<app-loader [isLoading]="isLoading || stormBasinResultsLoader"></app-loader>
<div id="stormStatsTable">
    <mat-table
        #table
        [dataSource]="stormStatsDataSource"
        matSort
    >
        <ng-container
            *ngIf="isStormDateRequired"
            matColumnDef="stormDate"
        >
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_DATE' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.stormStartTime | date: dateFormat }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="rainPeak">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.PEAK_RAINFALL' | translate }}
                ({{ unitOfMeasure }}{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.HOUR_SUFIX' | translate }})</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.rainPeak | number: decimal }} </mat-cell>
        </ng-container>
        <!-- columns are hidden by US 31871 -->
        <!-- <ng-container matColumnDef="rollingRainPeak">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.ROLLING_PEAK_RAINFALL' | translate }} ({{
                unitOfMeasure
                }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.rollingRainPeak ? (element.rollingRainPeak | number: decimal) : '-' }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="rollingRainPeakTime">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.TIME_OF_ROLLING_PEAK_RAINFALL' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{
                element.rollingRainPeakTime !== '0001-01-01T00:00:00'
                ? (element.rollingRainPeakTime | date: 'MM/dd/yy, h:mm a')
                : '-'
                }}
            </mat-cell>
        </ng-container> -->
        <ng-container matColumnDef="totalRain">
            <mat-header-cell *matHeaderCellDef>{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.TOTAL_RAIN' | translate }} ({{
                unitOfMeasure
                }})</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.totalRain | number: decimal }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="preCompType">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.PRECOMPENSATION_TYPE' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.preCompType }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="precompAdjust">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.PRECOMPENSATION_ADJUSTMENT' | translate }}
                ({{ customerUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <span *ngIf="element.preCompType === PrecompensationType.None">0</span>
                <span *ngIf="element.preCompType === PrecompensationType.Constant">{{ element.precompAdjust | number: unitMGD }}</span>
                <span *ngIf="element.preCompType !== PrecompensationType.Constant && element.preCompType !== PrecompensationType.None">-</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="stormDuration">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_DURATION' | translate }}
                (min)</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.stormDuration }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="stormRain">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_RAINFALL' | translate }}
                ({{ unitOfMeasure }})</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.stormRain | number : decimal }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="grossQPeakStorm">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_GROSS_Q_PEAK' | translate }} ({{ customerUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.grossQPeakStorm | number: unitMGD }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="grossQVolumeStorm">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_GROSS_Q_VOLUME' | translate }} ({{ volumeUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.grossQVolumeStorm | number: unitVolume }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="grossIIPeakStorm">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_GROSS_II_PEAK' | translate }} ({{ customerUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.grossIIPeakStorm | number: unitMGD }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="grossIIVolumeStorm">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_GROSS_II_VOLUME' | translate }} ({{ volumeUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.grossIIVolumeStorm | number: unitVolume }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="netIIPeakStorm">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_NET_II_PEAK' | translate }} ({{ customerUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.netIIPeakStorm | number: unitMGD }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="netIIVolumeStorm">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.STORM_NET_II_VOLUME' | translate }} ({{ volumeUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.netIIVolumeStorm | number: unitVolume }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="eventDuration">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.EVENT_DURATION' | translate }}
                (min)</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.eventDuration }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="eventRain">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.EVENT_RAINFALL' | translate }}
                ({{ unitOfMeasure }})</mat-header-cell>
            <mat-cell *matCellDef="let element">
                {{ element.stormRain < element.eventRain ? 0 : element.eventRain | number: decimal}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="grossQPeakEvent">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.EVENT_GROSS_Q_PEAK' | translate }} ({{ customerUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.grossQPeakEvent | number: unitMGD }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="grossEventPeakingFactor">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.GROSS_EVENT_PEAKING_FACTOR' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.grossEventPeakingFactor | number: unitGrossEventPeakingFactor }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="grossQVolumeEvent">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.EVENT_GROSS_Q_VOLUME' | translate }} ({{ volumeUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.grossQVolumeEvent | number: unitVolume }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="grossIIPeakEvent">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.EVENT_GROSS_II_PEAK' | translate }} ({{ customerUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.grossIIPeakEvent | number: unitMGD }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="grossIIVolumeEvent">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.EVENT_GROSS_II_VOLUME' | translate }} ({{ volumeUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.grossIIVolumeEvent | number: unitVolume }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="netIIPeakEvent">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.EVENT_NET_II_PEAK' | translate }} ({{ customerUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.netIIPeakEvent | number: unitMGD }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="netIIVolumeEvent">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.EVENT_NET_II_VOLUME' | translate }} ({{ volumeUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.netIIVolumeEvent | number: unitVolume }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="grossQPeakR1">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.RECOVERY_1_GROSS_Q_PEAK' | translate }}
                ({{ customerUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.grossQPeakR1 | number: unitMGD }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="grossQVolumeR1">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.RECOVERY_1_GROSS_Q_VOLUME' | translate }}
                ({{ volumeUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.grossQVolumeR1 | number: unitVolume }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="grossIIPeakR1">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.RECOVERY_1_GROSS_II_PEAK' | translate }}
                ({{ customerUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.grossIIPeakR1 | number: unitMGD }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="grossIIVolumeR1">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.RECOVERY_1_GROSS_II_VOLUME' | translate }}
                ({{ volumeUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.grossIIVolumeR1 | number: unitVolume }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="netIIPeakR1">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.RECOVERY_1_NET_II_PEAK' | translate }} ({{
                customerUnit
                }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.netIIPeakR1 | number: unitMGD }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="netIIVolumeR1">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.RECOVERY_1_NET_II_VOLUME' | translate }} ({{
                volumeUnit
                }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.netIIVolumeR1 | number: unitVolume }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="grossQPeakR2">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.RECOVERY_2_GROSS_Q_PEAK' | translate }}
                ({{ customerUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.grossQPeakR2 | number: unitMGD }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="grossQVolumeR2">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.RECOVERY_2_GROSS_Q_VOLUME' | translate }}
                ({{ volumeUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.grossQVolumeR2 | number: unitVolume }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="grossIIPeakR2">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.RECOVERY_2_GROSS_II_PEAK' | translate }}
                ({{ customerUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.grossIIPeakR2 | number: unitMGD }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="grossIIVolumeR2">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.RECOVERY_2_GROSS_II_VOLUME' | translate }}
                ({{ volumeUnit }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.grossIIVolumeR2 | number: unitVolume }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="netIIPeakR2">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.RECOVERY_2_NET_II_PEAK' | translate }} ({{
                customerUnit
                }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.netIIPeakR2 | number: unitMGD }} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="netIIVolumeR2">
            <mat-header-cell *matHeaderCellDef>
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.RECOVERY_2_NET_II_VOLUME' | translate }} ({{
                volumeUnit
                }})
            </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.netIIVolumeR2 | number: unitVolume }} </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="stormStatsColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: stormStatsColumns"></mat-row>
    </mat-table>
</div>
