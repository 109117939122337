import {
    Component,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import { TopnavComponent } from 'app/navigation/topnav/topnav.component';
import { SignalRMessageType, SliicerSignalRMessage, SliicerMessageType, VAULT_MOVE_SUCCESS_MESSAGE, VAULT_MOVE_FAIL_MESSAGE } from 'app/shared/models/signalr-message';
import {
    LocationGroupMonitorSummary,
    MonitorCounts,
    SNACK_BAR_NOTIFICATION_TIMEOUT,
    MonitorName,
} from 'app/shared/models/sliicer-data';
import { SliicerCaseStudy } from 'app/shared/models/sliicer/case-study';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { LocationGroupService } from 'app/shared/services/location-group.service';
import { SignalRService } from 'app/shared/services/signalr.service';
import { SliicerService, STEP_LENGTH_OPTIONS, DEFAULT_STEP_LENGTH } from 'app/shared/services/sliicer.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { AdsDetailsDialogComponent } from '../details-dialog/details-dialog.component';
import { SliicerImportVaultStudyComponent } from '../../sliicer-import-vault-study/sliicer-import-vault-study.component';
import { SelectItem } from 'app/shared/models/selected-Item';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'ads-sliicer-wizard',
    templateUrl: './wizard.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SliicerCaseStudyWizardComponent implements OnInit, OnDestroy {
    @Output() public wizardComplete = new EventEmitter();

    // TODO: WPS - translate
    private allLocationsString = 'All Locations';
    private subscriptions = [];
    private locationGroupMonitorSummaries: Array<LocationGroupMonitorSummary> = [];

    private caseStudyId: string;
    private customerId: number;
    private studyVaultName: string;

    public loadingLocations = false;
    public isLoading = false;
    public locationGroups: Array<SelectItem> = [];
    public selectedLocationGroupId = 0;
    public locationGroupsUnavailable: boolean;
    public currentLocationGroupSummary: LocationGroupMonitorSummary;
    public invalidMonitors: boolean;
    public noDataFlowMonitorError: boolean;
    public noDataRainMonitorError: boolean;
    public invalidStartDate = false;
    public invalidEndDate = false;
    public invalidDates: boolean;
    public datesTouched = false;
    public daysInStudy: number;
    public startDate: Date = null;
    public endDate: Date = null;
    public stepLength: number;
    public studyStepLengthOptions: Array<SelectItem> = STEP_LENGTH_OPTIONS;

    public createStudyComplete = false;
    public isCaseStudyCreated = false;
    public copyTelemetryComplete = false;
    public copyTelemetrySuccess = false;
    public initialCalculationsComplete = false;
    public initialCalculationsSuccess = false;
    public maxDate: Date = new Date();
    public dateFormat: string;
    public dateFormatUppercase: string;

    public locationCompleteStatus = false;
    public periodCompleteStatus = false;
    public stepLengthStatus = false;

    private caseStudyCreationFailureString: string;
    private dialogDismisString: string;

    private copyStatusMap = new Map<string, string>();
    public includeInactiveLocations = true;

    public earliestAllowedDate: Date;
    public latestAllowedDate: Date;
    public isDatesOutside = false;
    public dateOutsideErrorParams: { startDate?: string, endDate?: string } = {};
    constructor(
        private topNav: TopnavComponent,
        private locationGroupService: LocationGroupService,
        private sliicerService: SliicerService,
        private signalRService: SignalRService,
        private utilService: UiUtilsService,
        private cdr: ChangeDetectorRef,
        private dialog: MatDialog,
        private dateUtilService: DateutilService,
        private snackBarNotificationService: SnackBarNotificationService,
        private translateService: TranslateService,
        private datePipe: DatePipe
    ) {}

    public ngOnInit() {
        this.setupSignalR();

        this.stepLength = STEP_LENGTH_OPTIONS.find((o) => o.text === DEFAULT_STEP_LENGTH).id;

        const translateKeys: Array<string> = ['SLIICER.WIZARD.SLIICER_ADD_FAILURE_TEXT', 'COMMON.DISMISS_TEXT'];

        this.translateService.get(translateKeys).subscribe((values) => {
            this.caseStudyCreationFailureString = values['SLIICER.WIZARD.SLIICER_ADD_FAILURE_TEXT'];
            this.dialogDismisString = values['COMMON.DISMISS_TEXT'];
        });

        this.subscriptions.push(
            this.dateUtilService.dateFormat.subscribe((newDateFormat) => {
                this.dateFormat = this.dateUtilService.getStringFormat(newDateFormat);
                this.dateFormatUppercase = this.dateFormat.toUpperCase();
            }),
        );

        this.subscriptions.push(
            this.sliicerService.studyDetailsData$.subscribe((caseStudyData: SliicerCaseStudy) => {
                this.caseStudyId = caseStudyData.id;
                this.customerId = caseStudyData.customerId;
                this.studyVaultName = caseStudyData.meta.studyVaultName;
            }),
        );

        this.subscriptions.push(
            this.locationGroupService.locationGroupEdited.subscribe((lid) => {
                if(lid !== null) this.fetchLocationGroups(this.customerId);
            })
        );
        this.subscriptions.push(
            this.locationGroupService.locationGroupDeleted.subscribe((lid) => {
                if(lid !== null) this.fetchLocationGroups(this.customerId);
            })
        );
        this.fetchLocationGroups(this.customerId);

        this.validateDates();
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscripton) => subscripton.unsubscribe());
    }

    /**
     * Method triggered when the user changes the location group to use for the study
     * @param event
     */
    public onLocationGroupSelected(event, update = false) {
        this.currentLocationGroupSummary = this.locationGroupMonitorSummaries.find(
            (summ) => summ.locationGroupId === this.selectedLocationGroupId,
        );
        if (!this.currentLocationGroupSummary) {
            // Happens after currently selected location group was deleted
            this.selectedLocationGroupId = 0;
            this.currentLocationGroupSummary = this.locationGroupMonitorSummaries.find(
                (summ) => summ.locationGroupId === 0);
            this.getLocationDetails(this.customerId, this.currentLocationGroupSummary);
            return;
        }
        if (this.currentLocationGroupSummary.fetched && !update) {
            this.setAllowedDatesForLocationGroup(this.currentLocationGroupSummary, true);
            this.utilService.safeChangeDetection(this.cdr);
            return;
        }
        this.getLocationDetails(this.customerId, this.currentLocationGroupSummary);
    }

    /**
     * Method triggered when user clicks "Details" to find out about the flow and rainfall monitors currently selected
     */
    public showMonitorDetails() {

        if(this.currentLocationGroupSummary.flowMonitorNames && this.currentLocationGroupSummary.flowMonitorNames.length > 0)
        {
            this.currentLocationGroupSummary.flowMonitorNames.sort();
        }

        if(this.currentLocationGroupSummary.rainfallMonitorNames && this.currentLocationGroupSummary.rainfallMonitorNames.length > 0)
        {
            this.currentLocationGroupSummary.rainfallMonitorNames.sort();
        }

        this.dialog
            .open(AdsDetailsDialogComponent, {
                disableClose: true,
                data: {
                    study: this.currentLocationGroupSummary
                }
            })
            .afterClosed();
    }


    /**
     * Method to validate case study start date on keyboard date entry or from datepicker
     * @param event containing startdate entered
     */
    public onStartDateChanged(event) {
        this.datesTouched = true;
        const val = event.value ? event.value : event.target.value;
        const valStr = event.targetElement ? event.targetElement.value : event.target.value;
        const newDate = this.sliicerService.validateDate(val, valStr);

        this.invalidStartDate = !newDate;

        if (newDate) {
            this.startDate = newDate;
            this.validateDates();
            this.checkIfStudyDatesOutsideFinalData();
        }

        this.utilService.safeChangeDetection(this.cdr);
    }

    /**
     * Method to validate case study end date on keyboard date entry or from datepicker
     * @param event containing enddate entered
     */
    public onEndDateChanged(event) {
        this.datesTouched = true;
        const val = event.value ? event.value : event.target.value;
        const valStr = event.targetElement ? event.targetElement.value : event.target.value;
        const newDate = this.sliicerService.validateDate(val, valStr);

        this.invalidEndDate = !newDate;

        if (newDate) {
            this.endDate = newDate;
            this.validateDates();
            this.checkIfStudyDatesOutsideFinalData();
        }

        this.utilService.safeChangeDetection(this.cdr);
    }

    /**
     * Method to upload flow load and profile databases from the vault
     * NOTE: This is _only_ triggered while in the wizard
     * @param emittedValue
     */
    public flowLoadFileUploaded(emittedValue) {
        if (emittedValue) {
            const response = JSON.parse(emittedValue);
            if (response && response.length > 1 && response[0]) {
                this.importVaultStudy(response[0], response[1]);
            }
        }
    }

    public importVaultStudy(fileUri: string, customerVaultName: string) {
        this.dialog
            .open(SliicerImportVaultStudyComponent, {
                disableClose: true,
                data: {
                    customerID: this.customerId,
                    studyVaultName: this.studyVaultName,
                    id: this.caseStudyId,
                    fileUri: fileUri,
                    vaultName: customerVaultName,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                if (res && res.success) {
                    this.isLoading = true;
                    this.sliicerService.bustCache();
                    this.sliicerService.getCaseStudy(this.customerId, this.caseStudyId).subscribe(
                        (result: SliicerCaseStudy) => {
                            this.isLoading = false;
                            if (result) {
                                this.sliicerService.caseStudyDetails.next(result);
                                this.wizardComplete.emit();
                            }
                        },
                        () => {
                            this.isLoading = false;
                        },
                    );
                }
            });
        this.utilService.safeChangeDetection(this.cdr);
    }

    /**
     * Method to pop out of the wizard and start looking at results
     */
    public beginAnalysis() {
        this.wizardComplete.emit();
    }

    private toDateString(date: Date) {
        let day: string = '' + date.getDate();
        if (day.length < 2) day = '0' + day;
        let month = '' + (date.getMonth() + 1);
        if (month.length < 2) month = '0' + month;
        const year = date.getFullYear();
        return month + '/' + day + '/' + year;
    }

    /**
     * Method to create case study with valid location groups, start date, end date
     * and step length. Calls sliicerservice to create case study.
     * @param stepper reference to manually trigger setup complete step
     */
    public createCaseStudy(stepper: MatStepper) {
        this.isCaseStudyCreated = true;

        const caseStudy: SliicerCaseStudy = {
            id: null,
            customerId: this.customerId,
            meta: null,
            includeInactiveLocations: this.includeInactiveLocations,
            config: {
                locationGroup: {
                    name: this.currentLocationGroupSummary.locationGroupName,
                    id: this.currentLocationGroupSummary.locationGroupId,
                },
                flowMonitors: null, // the API will fill these in
                rainfallMonitors: null, // the API will fill these in
                startDate: this.toDateString(this.startDate),
                endDate: this.toDateString(this.endDate),
                stepLengthMinutes: this.stepLength,
            },
        };

        this.sliicerService.putStudyConfig(this.customerId, this.caseStudyId, caseStudy).subscribe(
            (result: SliicerCaseStudy) => {
                if (result && !_.isEmpty(result)) {
                    this.createStudyComplete = true;
                    this.sliicerService.caseStudyDetails.next(result);
                }
            },
            () => {
                this.isCaseStudyCreated = false;
                if (stepper) {
                    stepper.previous();
                }
                this.snackBarNotificationService.raiseNotification(
                    this.caseStudyCreationFailureString,
                    this.dialogDismisString,
                    {
                        panelClass: 'custom-error-snack-bar',
                    },
                    false
                );
            },
        );
    }

    private fetchLocationGroups(customerId: number) {
        this.locationGroups = [{ id: 0, text: this.allLocationsString }];
        this.locationGroupMonitorSummaries = this.locationGroups.map((lg) => {
            return {
                locationGroupId: lg.id,
                locationGroupName: lg.text,
                fetched: false,
                rainfallMonitorNames: null,
                flowMonitorNames: null,
                levelMonitorNames: null,
            };
        });

        this.isLoading = true;
        this.subscriptions.push(
            this.locationGroupService.getLocationGroups(customerId).subscribe(
                (result: any) => {
                    this.isLoading = false;
                    this.locationGroupsUnavailable = false;

                    if (result.locationGroups && result.locationGroups.length) {
                        result.locationGroups.forEach((lg) => {
                            this.locationGroups.push({ id: lg.locationGroupID, text: lg.name });

                            const index = this.locationGroupMonitorSummaries.findIndex(
                                (summ) => summ.locationGroupId === lg.locationGroupID,
                            );
                            if (index < 0) {
                                this.locationGroupMonitorSummaries.push({
                                    locationGroupId: lg.locationGroupID,
                                    locationGroupName: lg.name,
                                    fetched: false,
                                    rainfallMonitorNames: null,
                                    flowMonitorNames: null,
                                    levelMonitorNames: null,
                                });
                            } else {
                                this.locationGroupMonitorSummaries[index].locationGroupName = lg.name;
                            }
                        });
                    }
                    this.onLocationGroupSelected(null, true)
                    this.utilService.safeChangeDetection(this.cdr);
                },
                () => {
                    this.isLoading = false;
                    this.locationGroupsUnavailable = true;
                    this.utilService.safeChangeDetection(this.cdr);
                },
            ),
        );
    }

    public switchInactive() {
        this.fetchLocationGroups(this.customerId)
    }

    private validateDates() {
        if (this.startDate && this.endDate && !this.invalidStartDate && !this.invalidEndDate) {
            this.daysInStudy = SliicerService.GetDaysInStudy(this.startDate.toString(), this.endDate.toString());
            this.invalidDates = this.daysInStudy < 1;
        } else {
            this.invalidDates = !this.invalidStartDate || !this.invalidEndDate || !this.endDate || !this.startDate;
            this.daysInStudy = 0;
        }
    }

    /**
     * Determine the suggested start and end dates for the study based on the earliest and latest
     * readings for all the flow and rainfall monitors and put those values into the provided
     * LocationGroupMonitorsummary
     * @param flowMonitors
     * @param rainMonitors
     * @param summary
     */
    private static summarizeDates(
        flowMonitors: MonitorName[],
        rainMonitors: MonitorName[],
        summary: LocationGroupMonitorSummary,
    ) {
        // The suggested start date will be the date that the last monitor came online (earliestReading).
        // Sorting gives earliest date to latest date so we want the last item in the list.
        // Remove null values.
        const allEarliestReadings = [
            ...flowMonitors.map((fm) => fm.earliestReading),
            ...rainMonitors.map((rm) => rm.earliestReading),
        ]
            .filter((s) => s !== null)
            .sort();
        summary.suggestedStartDate =
            allEarliestReadings.length > 0 ? allEarliestReadings[allEarliestReadings.length - 1] : null;

        // The suggested end date will be the date that the first monitor went offline (latestReading).
        // Sorting gives earliest date to latest date so we want the first item in the list.
        // Remove null values.
        const allLatestReadings = [
            ...flowMonitors.map((fm) => fm.latestReading),
            ...rainMonitors.map((rm) => rm.latestReading),
        ]
            .filter((s) => s !== null)
            .sort();
        summary.suggestedEndDate = allLatestReadings.length > 0 ? allLatestReadings[0] : null;
    }

    private checkIfStudyDatesOutsideFinalData() {
        this.isDatesOutside = new Date(this.startDate).getTime() < new Date(this.earliestAllowedDate).getTime()
            || new Date(this.endDate).getTime() > new Date(this.latestAllowedDate).getTime();
    }

    // function checks if location group has flow/rain monitors with valid data
    private checkRequiredMonitors(monitor: MonitorCounts, isFromCache = false) {
        const rainKey = isFromCache ? 'rainfallMonitorNames' : 'rainMonitors';
        const flowKey = isFromCache ? 'flowMonitorNames' : 'flowMonitors';

        // there should be at least 1 rain monitor and at least 1 flow monitor
        this.invalidMonitors = !monitor[rainKey] || monitor[rainKey].length === 0 || !monitor[flowKey] || monitor[flowKey].length === 0;

        if (this.invalidMonitors) {
            return;
        }

        this.noDataFlowMonitorError = monitor[flowKey].some((m: MonitorName) => m.earliestReading === null && m.latestReading === null);
        this.noDataRainMonitorError = monitor[rainKey].some((m: MonitorName) => m.earliestReading === null && m.latestReading === null);
    }

    private setAllowedDatesForLocationGroup(monitor: MonitorCounts, isFromCache = false) {
        this.checkRequiredMonitors(monitor, isFromCache);
        if (this.invalidMonitors || this.noDataFlowMonitorError) {
            return;
        }

        // we have different prop names when its from API and cache
        const monitorKeys = isFromCache ? ['flowMonitorNames', 'levelMonitorNames', 'rainfallMonitorNames'] : ['flowMonitors', 'levelMonitors', 'rainMonitors'];

        // set earliest and latest allowed date for study
        const earliestDates = monitorKeys.map((key: string) => SliicerService.getEarliestDate(monitor[key])).filter(v => !!v);
        const latestDates = monitorKeys.map((key: string) => SliicerService.getLatestDate(monitor[key])).filter(v => !!v);

        this.earliestAllowedDate = new Date(new Date(Math.min(...earliestDates)).setHours(0, 0, 0, 0));
        this.latestAllowedDate = new Date(new Date(Math.max(...latestDates)).setHours(23, 59, 59));

        this.dateOutsideErrorParams = {
            startDate: this.datePipe.transform(this.earliestAllowedDate, this.dateFormat),
            endDate: this.datePipe.transform(this.latestAllowedDate, this.dateFormat)
        }

        this.checkIfStudyDatesOutsideFinalData();
    }

    private getLocationDetails(customerId: number, locationGroupSummary: LocationGroupMonitorSummary) {
        const locationGroupArray =
            locationGroupSummary.locationGroupId !== 0 ? [locationGroupSummary.locationGroupId] : null;
        this.loadingLocations = true;
        this.subscriptions.push(
            this.sliicerService.getMonitorNames(customerId, locationGroupArray, this.includeInactiveLocations).subscribe(
                (counts: MonitorCounts) => {
                    this.loadingLocations = false;
                    if (counts) {
                        this.setAllowedDatesForLocationGroup(counts);

                        locationGroupSummary.fetched = true;

                        const flowMonitors = counts.flowMonitors !== null ? counts.flowMonitors : [];
                        const rainMonitors = counts.rainMonitors !== null ? counts.rainMonitors : [];

                        locationGroupSummary.levelMonitorNames = counts.levelMonitors!== null ? counts.levelMonitors : [];
                        locationGroupSummary.flowMonitorNames = counts.flowMonitors!== null ? counts.flowMonitors : [];
                        locationGroupSummary.rainfallMonitorNames = counts.rainMonitors!== null ? counts.rainMonitors : [];

                        SliicerCaseStudyWizardComponent.summarizeDates(
                            flowMonitors,
                            rainMonitors,
                            locationGroupSummary,
                        );
                        if (locationGroupSummary.suggestedStartDate) {
                            this.startDate = new Date(locationGroupSummary.suggestedStartDate);
                        }
                        if (
                            locationGroupSummary.suggestedStartDate &&
                            locationGroupSummary.suggestedEndDate &&
                            locationGroupSummary.suggestedStartDate < locationGroupSummary.suggestedEndDate
                        ) {
                            this.endDate = new Date(locationGroupSummary.suggestedEndDate);
                        }
                        if (this.startDate && this.endDate) {
                            this.validateDates();
                        }
                    } else {
                        // TODO: WPS - not sure what to do here. Why would this not return an error?
                    }
                    this.utilService.safeChangeDetection(this.cdr);
                },
                () => {
                    this.loadingLocations = false;
                },
            ),
        );
    }

    private setupSignalR() {
        this.signalRService.messageReceived.subscribe(async (res: SliicerSignalRMessage) => {
            // we only care about very specific circumstances
            if (res.message !== SliicerSignalRMessage.SLIICER_MESSAGE) {
                return;
            }
            if (res.type !== SignalRMessageType.User) {
                return;
            }
            if (res.message === VAULT_MOVE_SUCCESS_MESSAGE || res.message === VAULT_MOVE_FAIL_MESSAGE) {
                return;
            }
            if (res.caseStudyId !== this.caseStudyId) {
                return;
            }
            // TODO: WPS - Make sure copyStatusMap is represented in HTML somewhere so
            //       user knows what is going on.
            switch (res.messageType) {
                default:
                    break;
                case SliicerMessageType.TelemetryCopyComplete:
                    this.copyTelemetryComplete = true;
                    this.copyTelemetrySuccess = res.success;
                    this.utilService.safeChangeDetection(this.cdr);
                    break;
                case SliicerMessageType.CalculationsComplete:
                    this.initialCalculationsComplete = true;
                    this.initialCalculationsSuccess = res.success;
                    this.utilService.safeChangeDetection(this.cdr);
                    this.sliicerService.bustCache();
                    break;
                case SliicerMessageType.SingleTelemetryStart:
                    this.copyStatusMap.set(res.detail, 'Started');
                    this.utilService.safeChangeDetection(this.cdr);
                    break;
                case SliicerMessageType.SingleTelemetryEnd:
                    this.copyStatusMap.set(res.detail, res.success ? 'Succeeded' : 'Failed');
                    this.utilService.safeChangeDetection(this.cdr);
                    break;
            }
        });
    }
}
