import { Inject, Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { MenuItem } from './menu-item';
import { StringUtils } from '../shared/utils/string-utils';
import { menuItemSetup } from './menu-setup';
import { AppConfigOptions } from 'app/app.config.options';
import { take } from 'rxjs/operators';

@Injectable()
export class NavigationService {
    public static smallViewportWidth = 600;
    public static largeViewportWidth = 992;

    private menuItems: Subject<MenuItem[]> = new BehaviorSubject(menuItemSetup);
    private menuItemsTemp: Subject<MenuItem[]> = new BehaviorSubject(null);
    private activeMenuItem: Subject<MenuItem> = new BehaviorSubject(null);
    private pageTitle: Subject<string> = new BehaviorSubject('ADS PRISM');
    private appTitle: Subject<string> = new BehaviorSubject('ADS PRISM');
    private browserTitle: Subject<string> = new BehaviorSubject(null);
    private titleSeparator: Subject<string> = new BehaviorSubject('|');
    private currentRoute: Subject<string> = new BehaviorSubject(null);
    private breadCrumbs: Subject<Array<{ title: string; link: Array<string> | string }>> = new BehaviorSubject([]);
    private windowSize: Subject<number> = new BehaviorSubject(NavigationService.largeViewportWidth);
    private isRouteLoading: Subject<boolean> = new BehaviorSubject(true);

    constructor(public dialog: MatDialog, @Inject('AppConfigOptions') private coreOptions: AppConfigOptions) {
        if (!StringUtils.isNull(coreOptions.appTitle)) {
            this.appTitle.next(coreOptions.appTitle);
        }

        if (!StringUtils.isNull(coreOptions.titleSeparator)) {
            this.titleSeparator.next(coreOptions.titleSeparator);
        }
    }

    public get getMenuItems(): Subject<MenuItem[]> {
        return this.menuItems;
    }

    public get tempMenuItems(): Subject<MenuItem[]> {
        return this.menuItemsTemp;
    }

    public get getActiveMenuItem(): Subject<MenuItem> {
        return this.activeMenuItem;
    }

    public setMenuItems(): void {
        this.menuItems.next(null);
    }

    public setActiveMenuItem(menuItem: MenuItem): void {
        this.activeMenuItem.next(menuItem);
    }

    public modifyMenuItem(original: MenuItem | string, replacement: MenuItem): MenuItem[] {
        let menuItems: MenuItem[] = [];
        this.menuItems.pipe(take(1)).subscribe((currentMenuItems) => {
            if (typeof original === 'string') {
                original = this.findMenuItem(original, currentMenuItems);
            }
            if (original !== undefined && original !== null) {
                currentMenuItems = this.findAndModifyMenuItem(<MenuItem>original, replacement, currentMenuItems);
            }
            menuItems = currentMenuItems;
        });
        this.menuItems.next(menuItems);
        return menuItems;
    }

    private findAndModifyMenuItem(original: MenuItem, replacement: MenuItem, menuItems: MenuItem[]): MenuItem[] {
        const foundItem = this.findMenuItem(original, menuItems, false);
        if (foundItem !== null) {
            const index: number = menuItems.indexOf(foundItem);
            menuItems.splice(index, 1, replacement);
            return menuItems;
        } else {
            menuItems.forEach((menuItem: MenuItem, index: number) => {
                if (this.findMenuItem(original, menuItems[index].children) !== null) {
                    menuItems[index].children = this.findAndModifyMenuItem(
                        original,
                        replacement,
                        menuItems[index].children,
                    );
                    return;
                }
            });
        }
        return menuItems;
    }

    public removeMenuItem(menuItem: MenuItem | string): MenuItem[] {
        let menuItems: MenuItem[] = [];
        this.menuItems.pipe(take(1)).subscribe((currentMenuItems) => {
            if (typeof menuItem === 'string') {
                menuItem = this.findMenuItem(menuItem, currentMenuItems);
            }
            if (menuItem !== undefined && menuItem !== null) {
                currentMenuItems = this.findAndRemoveMenuItem(<MenuItem>menuItem, currentMenuItems);
            }
            menuItems = currentMenuItems;
        });
        this.menuItems.next(menuItems);
        return menuItems;
    }

    private findAndRemoveMenuItem(menuItem: MenuItem, menuItems: MenuItem[]): MenuItem[] {
        const index = menuItems.indexOf(this.findMenuItem(menuItem, menuItems));
        if (index >= 0) {
            menuItems.splice(index, 1);
        } else {
            menuItems.forEach((mItem: MenuItem, i: number) => {
                const item: MenuItem = this.findMenuItem(menuItem, menuItems[i].children);
                if (item !== null) {
                    menuItems[i].children = this.findAndRemoveMenuItem(menuItem, menuItems[i].children);
                    return;
                }
            });
        }
        return menuItems;
    }

    public removeMenuItems(menuItems: MenuItem[]): void {
        for (const item of menuItems) {
            this.removeMenuItem(item);
        }
    }

    public showOnly(menuItem: MenuItem | string): void {
        this.menuItems.pipe(take(1)).subscribe((items) => {
            if (typeof menuItem === 'string') {
                menuItem = this.findMenuItem(menuItem, items);
            }
            if (menuItem === null) {
                return;
            }
            const showAllButton = new MenuItem({
                title: 'Show All Menus',
                icon: 'keyboard return',
                clickHandler: () => {
                    this.menuItemsTemp.next([]);
                    return false;
                },
            });
            this.menuItemsTemp.next([showAllButton, menuItem]);
        });
    }

    public getAutoPageTitle(route: string): string {
        let pageTitle = '';
        this.menuItems.pipe(take(1)).subscribe((items) => {
            pageTitle = this.createPageTitle(this.findMenuItem(route, items));
        });
        return pageTitle === 'NAVIGATION.ADMIN' ? '' : pageTitle;
    }

    public get getPageTitle(): Subject<string> {
        return this.pageTitle;
    }

    public setPageTitle(title: string): void {
        title = title || 'ADS PRISM';
        this.pageTitle.next(title);
    }

    public get getAppTitle(): Subject<string> {
        return this.appTitle;
    }

    public setAppTitle(appTitle: string): void {
        this.appTitle.next(appTitle);
    }

    public get getTitleSeparator(): Subject<string> {
        return this.titleSeparator;
    }

    public setTitleSeparator(titleSeparator: string): void {
        this.titleSeparator.next(titleSeparator);
    }

    public get getBrowserTitle(): Subject<string> {
        return this.browserTitle;
    }

    public setBrowserTitle(browserTitle: string): void {
        alert(browserTitle);
        this.browserTitle.next(browserTitle);
    }

    public getAutoBrowserTitle(pageTitle: string): string {
        const hasPageTitle = !StringUtils.isEmpty(pageTitle);
        let title = '';
        let separator;
        this.appTitle.pipe(take(1)).subscribe((appTitle) => {
            if (appTitle !== null) {
                this.titleSeparator.pipe(take(1)).subscribe((titleSeparator) => {
                    separator = titleSeparator !== null ? titleSeparator : '';
                    title += appTitle + (hasPageTitle ? ' ' + separator + ' ' : '');
                });
            }
        });
        title += hasPageTitle ? pageTitle : '';
        return title;
    }

    public get getCurrentRoute(): Subject<string> {
        return this.currentRoute;
    }

    public setCurrentRoute(route: string): void {
        this.currentRoute.next(route);
    }

    public getAutoBreadcrumbs(route: string): Array<{ title: string; link: Array<string> | string }> {
        let breadcrumbs: Array<{ title: string; link: Array<string> | string }> = [];
        this.menuItems.pipe(take(1)).subscribe((items) => {
            breadcrumbs = this.createBreadcrumb(this.findMenuItem(route, items));
        });
        return breadcrumbs;
    }

    public get getBreadCrumbs(): Subject<Array<{ title: string; link: Array<string> | string }>> {
        return this.breadCrumbs;
    }

    public setBreadcrumbs(breadcrumbs: Array<{ title: string; link: Array<string> | string }>): void {
        this.breadCrumbs.next(breadcrumbs);
    }

    public get getWindowSize(): Subject<number> {
        return this.windowSize;
    }

    public get getIsRouteLoading(): Subject<boolean> {
        return this.isRouteLoading;
    }

    public setIsRouteLoading(isRouteLoading: boolean): void {
        this.isRouteLoading.next(isRouteLoading);
    }

    public get mediumScreenAndDown(): boolean {
        return window !== undefined
            ? window.matchMedia(`(max-width: ${NavigationService.largeViewportWidth}px)`).matches
            : false;
    }

    public get mediumScreenAndUp(): boolean {
        return window !== undefined
            ? window.matchMedia(`(min-width: ${NavigationService.smallViewportWidth}px)`).matches
            : false;
    }

    public get smallScreen(): boolean {
        return window !== undefined
            ? window.matchMedia(`(max-width: ${NavigationService.smallViewportWidth}px)`).matches
            : false;
    }

    public get mediumScreen(): boolean {
        return window !== undefined ? !this.smallScreen && !this.largeScreen : false;
    }

    public get largeScreen(): boolean {
        return window !== undefined
            ? window.matchMedia(`(min-width: ${NavigationService.largeViewportWidth}px)`).matches
            : false;
    }

    private createPageTitle(item: MenuItem): string {
        let title = '';
        if (item !== null && item.title !== null) {
            title += item.title;
        }
        return title;
    }

    private createBreadcrumb(item: MenuItem): Array<{ title: string; link: Array<string> | string }> {
        let breadcrumbs: Array<{ title: string; link: Array<string> | string }> = [];
        while (item !== null && item.parent !== null && item.parent !== undefined) {
            breadcrumbs = [{ title: item.parent.title, link: item.parent.link }, ...breadcrumbs];
            item = item.parent;
        }
        return breadcrumbs;
    }

    public findMenuItem(link: string | MenuItem, items: MenuItem[], deepCheck = true): MenuItem {
        if (typeof link === 'string') {
            link = StringUtils.cleanLinkString(<string>link);
        }
        let menuItem: MenuItem = null;

        items.forEach((mItem: MenuItem, i: number) => {
            if (link instanceof MenuItem) {
                if (items[i].link === (<MenuItem>link).link && items[i].title === (<MenuItem>link).title) {
                    menuItem = items[i];
                    return;
                } else if (items[i].children.length > 0 && deepCheck) {
                    menuItem = this.findMenuItem(link, items[i].children);
                    if (menuItem !== null) {
                        return;
                    }
                }
            } else {
                if (items[i].link === <string>link) {
                    menuItem = items[i];
                    return;
                } else if (items[i].children.length > 0 && deepCheck) {
                    menuItem = this.findMenuItem(link, items[i].children);
                    if (menuItem !== null) {
                        return;
                    }
                } else if (items[i].pathMatch === 'partial' && StringUtils.startsWith(link, items[i].link)) {
                    menuItem = items[i];
                    return;
                }
            }
        });

        return menuItem;
    }
}
