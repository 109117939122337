import {
    Component,
    EventEmitter,
    Output,
    Input,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    OnDestroy,
} from '@angular/core';
import { Locations } from '../../../models/locations';
import { CollectService } from '../../../services/collect.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { StatusCodeService } from '../../../services/status-code.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { TranslateService } from '@ngx-translate/core';
import { MONITOR_SERIES_TYPES } from 'app/shared/models/monitor-series-types';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'map-cluster-locations',
    templateUrl: './cluster-locations.component.html',
    providers: [CollectService, MatSnackBar],
    styleUrls: ['./cluster-locations.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClusterLocationsComponent implements OnDestroy {
    @Output() public closeMapLocationsPopup: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() public clusterLocations: any;
    @Input() public customerId;
    @Output() public openLocationDetailsPopUp: EventEmitter<Locations> = new EventEmitter<Locations>();
    private subscriptions = new Array<Subscription>();
    public isCollecting: boolean;
    public message = 'Collect has been initiated successfully.';
    public actionLabel = 'Dismiss';
    public flowMonitorSeries: string[] = [
        'TRITON+',
        'Triton',
        'Triton IM',
        'FlowHawk',
        'FlowShark',
        'FlowShark IS',
        'FlowShark RT',
        'Gateway',
        'iCE3',
        '1502',
        '1502EM',
        '1506',
        '2000',
        '3000',
        '3500',
        '3500S',
        '3600',
        '4000',
        '4500',
        '4500S',
        '5500',
        '5600',
        '7510',
        '7510',
    ];
    public levelMonitorSeries: string[] = [MONITOR_SERIES_TYPES.ECHO, 'FlowAlert'];
    public rainFallMonitorSeries: string[] = ['RainAlert III', 'RainAlert II'];
    public snackBarMessage: string;
    public dismissText: string;
    public translateKeys: Array<string> = ['HOME.COLLECTION_WIDGET.SNACKBAR_TEXT', 'COMMON.DISMISS_TEXT'];
    constructor(
        private collectService: CollectService,
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        private statusCodeService: StatusCodeService,
        private uiUtilsService: UiUtilsService,
        private translate: TranslateService,
    ) {
        translate.get(this.translateKeys).subscribe((translateValues) => {
            this.snackBarMessage = translateValues['HOME.COLLECTION_WIDGET.SNACKBAR_TEXT'];
            this.dismissText = translateValues['COMMON.DISMISS_TEXT'];
        });
    }

    public checkImage(location) {
        if (location.locationType === 3) {
            return '../../assets/images/locationMarkerWithoutShadow/GreenComposite_withoutshadow.png';
        }
        if (this.rainFallMonitorSeries.indexOf(location.series) >= 0) {
            if (location.isActiveLocation) {
                if (location.alarmingState === 0) {
                    return '../../assets/images/locationMarkerWithoutShadow/triangle_red_withoutshadow.png';
                } else if (location.alarmingState === 1) {
                    return '../../assets/images/locationMarkerWithoutShadow/triangle_orange_withoutshadow.png';
                } else {
                    return '../../assets/images/locationMarkerWithoutShadow/triangle_green_withoutshadow.png';
                }
            } else {
                return '../../assets/images/locationMarkerWithoutShadow/triangle_grey_withoutshadow.png';
            }
        } else if (this.levelMonitorSeries.indexOf(location.series) >= 0) {
            if (location.isActiveLocation) {
                if (location.alarmingState === 0) {
                    return '../../assets/images/locationMarkerWithoutShadow/square_red_withoutshadow.png';
                } else if (location.alarmingState === 1) {
                    return '../../assets/images/locationMarkerWithoutShadow/square_orange_withoutshadow.png';
                } else {
                    return '../../assets/images/locationMarkerWithoutShadow/square_green_withoutshadow.png';
                }
            } else {
                return '../../assets/images/locationMarkerWithoutShadow/square_grey_withoutshadow.png';
            }
        } else if (this.flowMonitorSeries.indexOf(location.series) >= 0) {
            if (location.isActiveLocation) {
                if (location.alarmingState === 0) {
                    return '../../assets/images/locationMarkerWithoutShadow/circle_red_withoutshadow.png';
                } else if (location.alarmingState === 1) {
                    return '../../assets/images/locationMarkerWithoutShadow/circle_orange_withoutshadow.png';
                } else {
                    return '../../assets/images/locationMarkerWithoutShadow/circle_green_withoutshadow.png';
                }
            } else {
                return '../../assets/images/locationMarkerWithoutShadow/circle_darkgrey_withoutshadow.png';
            }
        } else {
            if (location.alarmingState === 0) {
                return '../../assets/images/locationMarkerWithoutShadow/circle_red_withoutshadow.png';
            } else if (location.alarmingState === 1) {
                return '../../assets/images/locationMarkerWithoutShadow/circle_orange_withoutshadow.png';
            } else {
                return '../../assets/images/locationMarkerWithoutShadow/circle_green_withoutshadow_exclamation.png';
            }
        }
    }
    public emitCloseMapLocationsPopup() {
        this.closeMapLocationsPopup.emit(true);
    }

    public emitOpenLocationDetailsPopUp(location: Locations) {
        this.openLocationDetailsPopUp.emit(location);
    }

    /**
     * Framework level hook.
     */
    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
