<div class="customDialogLayer">
	<div class="customDialog">
		<div class="customDialogPosition">
			<div id="resonForEditId">
				<div class="popupHeaderWithButton" mat-dialog-title>
					<div class="auto-margin pad-l-15">
						{{ 'LOCATION_DASHBOARD.DATA_EDITING.CURVE_OPTIONS' | translate }}
					</div>
					<div class="app-flex-filler"></div>
				</div>
				<div mat-dialog-content class="eachTabContent">
					<form [formGroup]="form">
						<div class="location-info">
							<div><b>{{ 'COMMON.LOCATION' | translate }}</b></div>
							<mat-form-field>
								<input matInput type="text" readonly formControlName="designSlope" placeholder="{{ 'COMMON.SLOPE' | translate }}" />
							</mat-form-field>
							<mat-form-field>
								<input matInput type="text" readonly formControlName="designRoughness"
									placeholder="{{ 'COMMON.ROUGHNESS' | translate }}" />
							</mat-form-field>
							<mat-form-field>
								<input matInput type="text" readonly formControlName="effectiveRoughness"
									placeholder="{{ 'COMMON.EFFECTIVE_ROUGHNESS' | translate }}" />
							</mat-form-field>
						</div>

						<mat-accordion class="example-headers-align" multi>
							<mat-expansion-panel expanded="true">
								<mat-expansion-panel-header>
									<mat-panel-title><b>{{ 'COMMON.SS_CURVE' | translate }}</b></mat-panel-title>
								</mat-expansion-panel-header>

								<div class="location-info">
									<mat-form-field [matTooltip]="'COMMON.HC_TOOLTIP' | translate">
										<mat-label><span>C</span><sub>ss</sub></mat-label>
										<input matInput type="text" readonly formControlName="stevensSchutzbachHC"/>
									</mat-form-field>
									<mat-form-field [matTooltip]="'COMMON.D_DOG_TOOLTIP' | translate">
										<mat-label><span>d</span><sub>dog</sub><span>({{ data?.location?.depthUnit }})</span></mat-label>
										<input matInput type="text" readonly formControlName="drain"/>
									</mat-form-field>
									<mat-form-field [matTooltip]="'COMMON.R2_TOOLTIP' | translate">
										<mat-label><span>R</span><sup>2</sup></mat-label>
										<input matInput type="text" readonly formControlName="r2"/>
									</mat-form-field>
								</div>
							</mat-expansion-panel>
						</mat-accordion>
					</form>
				</div>
				<div mat-dialog-actions>
					<div class="app-flex-filler"></div>
					<div class="auto-margin">
						<button mat-dialog-close mat-button>{{ 'COMMON.CLOSE' | translate }}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>