import { Component, ViewEncapsulation, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FlowTabDialogData } from '../flow-monitor.model';

@Component({
    selector: 'ads-flow-monitor-changes-dialog',
    templateUrl: './flow-monitor-changes-dialog.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowMonitorChangesDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<FlowMonitorChangesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: FlowTabDialogData,
    ) {}

    public close(name: string) {
        this.dialogRef.close({ success: false, value: name });
    }
}
