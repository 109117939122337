import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    Output,
    EventEmitter,
} from '@angular/core';
import {
    VaultUploadDialogComponent,
    VaultService,
    VaultItemShareComponent,
    TelemetryImportComponent,
} from 'app/pages/vault';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { UiUtilsService } from '../../../shared/utils/ui-utils.service';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'app/environments/environment';
import { VaultCreateFolderComponent } from '../../vault/vault-create-folder/vault-create-folder.component';
import { UsersService } from 'app/pages/admin/users.service';
import { saveAs as importedSaveAs } from 'file-saver';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { SharedService } from 'app/shared/services/shared.service';
import { FileService } from 'app/shared/services/file.service';
import { Config } from 'app/shared/services/config';
import {
    TelemetryImportDatum,
    TelemetryImportDialogData,
    TelemetryFiles,
    TelemetryImportResult,
    VaultDirectory,
    VaultFile,
    VaultShareDialogData,
    VaultSubDirectory,
    VaultUpload,
} from 'app/shared/models/vault';
import { customerQueryParam, locationIdQueryParam, TELEMETRY } from 'app/shared/models/customer';
import { IDataTransfer } from 'app/shared/models/modal';
import { FileType, IFileView } from 'app/shared/models/file-view';
import { IComponentDialog, IComponentDialogConfirmationResult } from 'app/shared/models/comopnent-cofirmation';
import { FileViewComponent } from 'app/shared/components/file-view/file-view.component';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';

const EXCEL_FILE_EXTENSION = 'xlsx';
const ACTIVE = 'active';
const CSV = 'csv';

@Component({
    selector: 'ads-prism-vault-location',
    templateUrl: './ads-prism-vault-location.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class AdsPrismVaultLocationComponent implements OnInit {
    @Input() public locationName: string;
    @Output() public locationDetailsUpdated = new EventEmitter<void>();

    // Represents the selected customer;
    public selectedCustomerId: number;

    public selectedLocationId: number;

    public navigationConfirmationMsg: string;
    public dismissText: string;
    public noFileMsg: string;
    public csvUploadFilesErr: string;
    public filesUploadErr: string;
    public filesUploadSuccessMsg: string;
    public chooseCsvFileMsg: string;
    public chooseFilesMsg: string;
    public xmlUploadErrMsg: string;
    public invalidXmlErr: string;
    public removedSuccessText: string;
    public deleteDialogTitle: string;
    public deleteDialogMsg: string;
    public deleteDialogFolderMsg: string;
    public importCustomPipeDialogTitle: string;
    public importCustomPipeDialogMsg: string;
    public importCustomPipeDialogOk: string;
    public importSuccessMsg: string;
    public importErrMsg: string;
    public cancelDialogTitle: string;
    public cancelBtn: string;
    public deleteBtn: string;
    public cancelText: string;
    public discardText: string;
    public fileText: string;
    public folderText: string;
    public filesText: string;
    public commonServerErrorMessage: string;

    public vaultData: Observable<VaultDirectory>;
    public vaultDirectoryData: VaultFile[] = [];
    public subDirectories = new Array<VaultSubDirectory>();
    public breadcrumbDirectories: Array<VaultSubDirectory>;
    public readonly apiUri = environment.serviceUrl;
    public rootPath = '';
    public currentFilePath = '';
    public containingSubDirectories = new Array<VaultSubDirectory>();
    public isVaultDeletePermission: boolean;

    public uploadVaultFile: VaultFile;
    // Indicates that a loading event is in progress.
    public isLoading = true;

    // Represents the subscription to the Customer Change observable.
    private subscriptions = new Array<Subscription>();

    /**
     * Represents the collection of files that are contained in the currently selected directory.
     * For the initial state, this will be root, so files belonging to the root folder will be
     * contained within this collection.
     */
    public containingFiles = new Observable<Array<VaultFile>>();

    public translateKeys: Array<string> = [
        'VAULT.VAULT_DASHBOARD.NAVIGATION_MSG',
        'VAULT.VAULT_DASHBOARD.NO_FILE_SELECTED',
        'VAULT.VAULT_DASHBOARD.CSV_FILE_UPLOAD_ERR',
        'VAULT.VAULT_DASHBOARD.FILE_UPLOAD_NUMBER_ERR',
        'VAULT.VAULT_DASHBOARD.FILES_UPLOAD_SUCCESS',
        'VAULT.VAULT_DASHBOARD.CHOOSE_CSV_MSG',
        'VAULT.VAULT_DASHBOARD.CHOOSE_FILES_MSG',
        'VAULT.VAULT_DASHBOARD.XML_FILES_ERR',
        'VAULT.VAULT_DASHBOARD.INVALID_XML',
        'VAULT.VAULT_DASHBOARD.REMOVED_SUCCESSFULY',
        'VAULT.VAULT_DASHBOARD.DELETE_DIALOG_TITLE',
        'VAULT.VAULT_DASHBOARD.DELETE_DIALOG_MSG',
        'VAULT.VAULT_DASHBOARD.DELETE_DIALOG_FOLDER_MSG',
        'VAULT.VAULT_DASHBOARD.IMPORT_SUCCESS',
        'VAULT.VAULT_DASHBOARD.IMPORT_FAILED',
        'VAULT.VAULT_DASHBOARD.CANCEL_DIALOG_TITLE',
        'VAULT.VAULT_DASHBOARD.IMPORT_CUSTOM_PIPE_DIALOG_TITLE',
        'COMMON.DISMISS_TEXT',
        'COMMON.CANCEL_BUTTON',
        'COMMON.DELETE_TOOLTIP',
        'COMMON.CANCEL_TEXT',
        'COMMON.DISCARD_TEXT',
        'COMMON.FILE_TEXT',
        'COMMON.FOLDER_TEXT',
        'COMMON.FILES_TEXT',
        'COMMON.COMMON_SERVER_ERROR_MESSAGE',
        'COMMON.OK',
    ];

    constructor(
        private activatedRoute: ActivatedRoute,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private vaultService: VaultService,
        private fileService: FileService,
        private changeDetector: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private sharedService: SharedService,
        private snackBarNotificationService: SnackBarNotificationService,
        private usersService: UsersService,
    ) {
        translate.get(this.translateKeys).subscribe((translateValues) => {
            this.navigationConfirmationMsg = translateValues['VAULT.VAULT_DASHBOARD.NAVIGATION_MSG'];
            this.noFileMsg = translateValues['VAULT.VAULT_DASHBOARD.NO_FILE_SELECTED'];
            this.csvUploadFilesErr = translateValues['VAULT.VAULT_DASHBOARD.CSV_FILE_UPLOAD_ERR'];
            this.filesUploadErr = translateValues['VAULT.VAULT_DASHBOARD.FILE_UPLOAD_NUMBER_ERR'];
            this.filesUploadSuccessMsg = translateValues['VAULT.VAULT_DASHBOARD.FILES_UPLOAD_SUCCESS'];
            this.chooseCsvFileMsg = translateValues['VAULT.VAULT_DASHBOARD.CHOOSE_CSV_MSG'];
            this.chooseFilesMsg = translateValues['VAULT.VAULT_DASHBOARD.CHOOSE_FILES_MSG'];
            this.xmlUploadErrMsg = translateValues['VAULT.VAULT_DASHBOARD.XML_FILES_ERR'];
            this.invalidXmlErr = translateValues['VAULT.VAULT_DASHBOARD.INVALID_XML'];
            this.removedSuccessText = translateValues['VAULT.VAULT_DASHBOARD.REMOVED_SUCCESSFULY'];
            this.deleteDialogTitle = translateValues['VAULT.VAULT_DASHBOARD.DELETE_DIALOG_TITLE'];
            this.deleteDialogMsg = translateValues['VAULT.VAULT_DASHBOARD.DELETE_DIALOG_MSG'];
            this.deleteDialogFolderMsg = translateValues['VAULT.VAULT_DASHBOARD.DELETE_DIALOG_FOLDER_MSG'];
            this.importSuccessMsg = translateValues['VAULT.VAULT_DASHBOARD.IMPORT_SUCCESS'];
            this.importErrMsg = translateValues['VAULT.VAULT_DASHBOARD.IMPORT_FAILED'];
            this.cancelDialogTitle = translateValues['VAULT.VAULT_DASHBOARD.CANCEL_DIALOG_TITLE'];
            this.importCustomPipeDialogTitle = translateValues['VAULT.VAULT_DASHBOARD.IMPORT_CUSTOM_PIPE_DIALOG_TITLE'];
            this.dismissText = translateValues['COMMON.DISMISS_TEXT'];
            this.cancelBtn = translateValues['COMMON.CANCEL_BUTTON'];
            this.deleteBtn = translateValues['COMMON.DELETE_TOOLTIP'];
            this.cancelText = translateValues['COMMON.CANCEL_TEXT'];
            this.discardText = translateValues['COMMON.DISCARD_TEXT'];
            this.fileText = translateValues['COMMON.FILE_TEXT'];
            this.folderText = translateValues['COMMON.FOLDER_TEXT'];
            this.filesText = translateValues['COMMON.FILES_TEXT'];
            this.commonServerErrorMessage = translateValues['COMMON.COMMON_SERVER_ERROR_MESSAGE'];
            this.importCustomPipeDialogOk = translateValues['COMMON.OK'];
        });

        this.activatedRoute.queryParamMap.subscribe(
            (params: ParamMap) => {
                const selectedCustomerIdentifier = Number(params.get(customerQueryParam));
                const selectedLocationIdentifier = Number(params.get(locationIdQueryParam));

                this.selectedCustomerId = selectedCustomerIdentifier;
                this.selectedLocationId = selectedLocationIdentifier;
            },
            (error) => {
                // Error Block to handle errors
            },
        );
    }

    public ngOnInit() {
        // check permission for Vault Delete feature
        this.subscriptions.push(
            this.usersService.isVaultDelete.subscribe((response: boolean) => (this.isVaultDeletePermission = response)),
        );
        // set root as current directory
        this.vaultService.setCurrentDirectory(`/SiteDocuments/${this.selectedLocationId}/`);
        this.vaultService.isLocationVault = true;
        this.vaultService.locationVaultID = this.selectedLocationId;
        // initate the preloader
        this.isLoading = true;
        // set root as current directory
        this.rootPath = `/SiteDocuments/${this.selectedLocationId}/`;
        this.currentFilePath = this.rootPath;
        // setup initial data load
        // get reference to retrieving the potential customers
        this.vaultService
            .getRootVaultListForCustomer()
            .subscribe(
                () => {
                    this.isLoading = false;
                },
                (error) => {
                    // Error Block to handle errors
                },
            )
            .unsubscribe(); // immediately unsubscribe

        this.vaultData = this.vaultService.Vault;
        this.subscriptions.push(
            this.vaultData.subscribe(
                (vault: VaultDirectory) => {
                    // ensure args
                    this.isLoading = this.vaultService.isVaultLoading;
                    if (this.vaultService.isServerError) {
                        this.snackBarNotificationService.raiseNotification(
                            this.commonServerErrorMessage,
                            this.dismissText,
                            {
                                panelClass: 'custom-error-snack-bar',
                            },
                            false,
                        );
                    }
                },
                (error) => {
                    // Error Block to handle errors
                    this.isLoading = false;
                },
            ),
        );

        this.vaultService.BreadcrumbDirectories.subscribe(
            (directories) => {
                if (directories) {
                    this.breadcrumbDirectories = directories;
                }
            },
            (error) => {
                // Error Block to handle errors
                this.isLoading = false;
            },
        );

        this.containingFiles = this.vaultService.CurrentFiles;
        this.vaultService.CurrentFiles.subscribe(
            (files: Array<VaultFile>) => {
                this.generateValutItemTable(files);
            },
            (error) => {
                // Error Block to handle errors
                this.isLoading = false;
            },
        );
        this.vaultService.currentSubDirectory.subscribe(
            (subDirectors: Array<VaultSubDirectory>) => {
                this.containingSubDirectories = subDirectors;
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            (error) => {
                // Error Block to handle errors
                this.isLoading = false;
            },
        );

        this.translate
            .get('VAULT.VAULT_DASHBOARD.IMPORT_CUSTOM_PIPE_DIALOG_MSG', {
                locationName: this.locationName,
            })
            .subscribe((translation) => {
                this.importCustomPipeDialogMsg = translation;
            });
    }

    /**
     * Handles the response for the click event of the selected directory.
     * @param id Represents the surrogate id of the selected diretory.
     */
    public selectEachDirectory(id: string): void {
        this.vaultService.getFilesForDirectory(id);
        this.vaultService.selectedDirectory.next(id);
        this.uiUtilsService.safeChangeDetection(this.changeDetector);
    }

    public generateValutItemTable(files: VaultFile[]) {
        if (files) {
            files.sort(function (a, b) {
                const firstDate = new Date(a.modifiedDate);
                const secondDate = new Date(b.modifiedDate);
                return secondDate.getTime() - firstDate.getTime();
            });
            const highLight = false;

            this.vaultDirectoryData = files.map((file) => {
                let isPotentialTelemetryFile: boolean;
                let isTelogFile: boolean;
                let fileExtension: string;
                let isExcelFile: boolean;
                if (file == null || file.file_size == undefined) {
                    fileExtension = '';
                    isPotentialTelemetryFile = false;
                    isTelogFile = false;
                } else {
                    fileExtension = this.fileService.getFileExtension(file.fileName);
                    isPotentialTelemetryFile = fileExtension === CSV;
                    isTelogFile =
                        fileExtension === EXCEL_FILE_EXTENSION && file.fileName.toLowerCase().includes(ACTIVE);
                    isExcelFile = fileExtension === EXCEL_FILE_EXTENSION;
                }

                const previewType = this.fileService.isImageExtension(fileExtension)
                    ? 'img'
                    : this.fileService.isTextExtension(fileExtension)
                    ? 'txt'
                    : 'default';

                // tslint:disable-next-line:variable-name
                const [file_size, file_ext] = file.file_size.split(' ');
                const { fileName, uri, modifiedDate } = file;
                return {
                    file_size,
                    file_ext,
                    fileName,
                    uri,
                    modifiedDate,
                    fileExtension,
                    isPotentialTelemetryFile,
                    isTelogFile,
                    highLight,
                    previewType,
                    isExcelFile,
                    import: file.import,
                    absoluteUri: previewType === 'img' ? file.absoluteUri : file.uri,
                };
            });

            this.uiUtilsService.safeChangeDetection(this.changeDetector);
        } else {
            this.vaultDirectoryData.length = 0;
        }
    }

    /**
     * open the crate folder dialog
     */
    public createFolder() {
        this.dialog.open(VaultCreateFolderComponent, {
            disableClose: true,
        });
    }
    /**
     * Represents the handler for opening the Upload window.
     * @param Represents the MouseEvent.
     */
    public openUploadDialog($event: MouseEvent, fileType?: string): void {
        // check if telemetry  dialog is not open, then open export dialog
        if (this.activatedRoute.snapshot.firstChild) {
            return;
        }

        // setup initial url
        let url = `${Config.urls.vaultUpload}?cid=${this.selectedCustomerId}`;

        let currentlySelectedDirectory = '';

        this.vaultService.CurrentDirectory.subscribe(
            (directory: string) => (currentlySelectedDirectory = directory),
            (error) => {
                // Error Block to handle errors
                this.isLoading = false;
            },
        ).unsubscribe();

        if (currentlySelectedDirectory.length > 0) {
            if (currentlySelectedDirectory === `/SiteDocuments/${this.selectedLocationId}/`) {
                url = `${url}&uri=/SiteDocuments/${this.selectedLocationId}`;
            } else {
                url = `${url}&uri=${currentlySelectedDirectory}`;
            }
        }

        const uploadDialog = this.dialog.open(VaultUploadDialogComponent, {
            data: <IDataTransfer>{
                id: url,
                currentlySelectedDirectory: currentlySelectedDirectory,
                fileType: fileType,
            },
            panelClass: 'file-upload-background-panel',
            disableClose: true,
        });

        // setup dialog close handler subscription
        const postUploadSubscription = uploadDialog.afterClosed().subscribe(
            (vaultUpload: VaultUpload | string) => {
                // ensure args

                // if upload fails
                if (typeof vaultUpload === 'string') {
                    return;
                }

                if (vaultUpload) {
                    // if files have errors or no file selected
                    if (vaultUpload.uploadedFileCount === undefined || vaultUpload.uploadedFileCount === 0) {
                        const message = this.noFileMsg;
                        this.snackBar.open(message, this.dismissText, {
                            panelClass: 'custom-error-snack-bar',
                        });
                        return;
                    }

                    // if files have errors
                    if (vaultUpload.uploadedFileCount > 25) {
                        let message;
                        if (fileType === CSV) {
                            message = this.csvUploadFilesErr;
                        } else {
                            message = this.filesUploadErr;
                        }

                        this.snackBar.open(message, this.dismissText, {
                            panelClass: 'custom-error-snack-bar',
                        });
                        return;
                    }

                    // display message for user when files uploaded successfully
                    this.snackBar.open(
                        `${vaultUpload.uploadedFileCount} ${this.filesUploadSuccessMsg}`,
                        this.dismissText,
                        {
                            duration: 10000,
                        },
                    );

                    // initiate pre-loader
                    this.isLoading = true;

                    if (vaultUpload && vaultUpload.uploadedFileCount === 1) {
                        const uploadFile: VaultFile = {
                            directoryAndFileName: vaultUpload.directoryAndFileName,
                            fileName: vaultUpload.fileName,
                        };
                        this.uploadVaultFile = uploadFile;
                    }

                    // get the latest vault data for current customer and stop preloader
                    this.vaultService
                        .getRootVaultListForCustomer()
                        .subscribe(
                            () => {
                                this.isLoading = false;
                            },
                            (error) => {
                                // Error Block to handle errors
                                this.isLoading = false;
                            },
                        )
                        .unsubscribe(); // immediately unsubscribe
                }
            },
            (error) => {
                // Error Block to handle errors
                this.isLoading = false;
            },
        );

        // add subscription to subscription collection for cleanup
        this.subscriptions.push(postUploadSubscription);

        if (fileType === CSV) {
            this.sharedService.setFileUploadMessage(this.chooseCsvFileMsg);
        } else {
            this.sharedService.setFileUploadMessage(this.chooseFilesMsg);
        }
    }

    /**
     * Represents the file share handler.
     * @param id The vault file surrogate identifier.
     * @param name The vault file name.
     */
    public shareFile(fileShareData: VaultShareDialogData, id: string, name: string) {
        fileShareData.id = id;
        fileShareData.name = name;
        this.dialog.open(VaultItemShareComponent, {
            data: fileShareData,
            panelClass: 'file-upload-background-panel',
        });
    }
    public fileDownload(files: string[]) {
        if (files && files.length > 0) {
            const splitFile = files[0].split('/');
            const fileName = splitFile.find((x) => x.includes('.'));
            this.vaultService.downloadFile(files).subscribe((res) => {
                importedSaveAs(res, fileName);
            });
            this.isLoading = false;
        }
    }
    /**
     * Handles file link click event.
     * @param event Represents the mouse click event.
     */
    public fileSelected(
        event: MouseEvent,
        fileExtension: string,
        selectedFileName: string,
        uri: string,
        isBoxPriview: boolean,
        absoluteUri = '',
    ): void {
        // determine if the file is an image
        const isImage = this.fileService.isImageExtension(fileExtension);

        let isText: boolean;

        if (!isImage) {
            // it it is not an image, determine if it is a text file
            isText = this.fileService.isTextExtension(fileExtension);
        }

        // if file is neither text or image, exit immediatly
        if (!isImage && !isText) {
            this.fileDownload([uri]);
            return;
        }
        // setup the file view model to pass to the file view component
        const fileViewModel: IFileView = {
            extension: fileExtension,
            hostUri: this.apiUri,
            name: selectedFileName,
            type: isImage ? FileType.Image : FileType.Text,
            uri: uri,
            absoluteUri: absoluteUri,
        };

        // prevent file from downloading by blocking default behavior
        event.preventDefault();

        // open image in dialog
        this.dialog.open(FileViewComponent, {
            data: fileViewModel,
            panelClass: 'vault-file-view-background-panel',
        });
    }

    /**
     * Handles the click event for importing telemetry data from a viable data file.
     * @param event Represents the click event.
     */
    public importTelemetryData(event: MouseEvent, file: VaultFile): void {
        const telemetryFile: TelemetryFiles = {
            fileName: file.fileName,
            id: file.uri,
            import: file.import,
        };
        // setup dialog
        const telemetryImportDialog = this.dialog.open(TelemetryImportComponent, {
            data: <TelemetryImportDialogData>{
                files: [telemetryFile],
                importFileType: TELEMETRY,
                isFileUploaded: false,
            },
            panelClass: 'telemetry-import-background-panel',
        });

        // setup subscription for telemetry import dialog close
        const telemetryDialogSubscription = telemetryImportDialog.beforeClosed().subscribe(
            (telemetryImportResult: TelemetryImportResult) => {
                // if no result, just exit as dialog was just closed
                if (!telemetryImportResult) {
                    // change loading state
                    this.isLoading = false;

                    // detect changes and exit immediately
                    return this.uiUtilsService.safeChangeDetection(this.changeDetector);
                }

                // if there is an object then we have something to say to the user
                // so we display in a snackbar
                this.snackBar.open(telemetryImportResult.message, this.dismissText, {
                    duration: 10000,
                });

                this.isLoading = false;

                // detect changes and exit immediately
                return this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            (error) => {
                // Error Block to handle errors
                this.isLoading = false;
            },
        );

        this.subscriptions.push(telemetryDialogSubscription);
    }

    public importTelogData(event: MouseEvent, file: VaultFile): void {
        // setup dialog
        const telogImportDialog = this.dialog.open(TelemetryImportComponent, {
            data: <TelemetryImportDatum>{
                id: file.uri,
                fileName: file.fileName,
                import: file.import,
            },
            panelClass: 'telemetry-import-background-panel',
        });

        // setup subscription for telemetry import dialog close
        const telogDialogSubscription = telogImportDialog.beforeClosed().subscribe(
            (telogImportResult: TelemetryImportResult) => {
                // if no result, just exit as dialog was just closed
                if (!telogImportResult) {
                    // change loading state
                    this.isLoading = false;

                    // detect changes and exit immediately
                    return this.uiUtilsService.safeChangeDetection(this.changeDetector);
                }

                // if there is an object then we have something to say to the user
                // so we display in a snackbar
                this.snackBar.open(telogImportResult.message, this.dismissText, {
                    duration: 10000,
                });

                this.isLoading = false;

                // detect changes and exit immediately
                return this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            (error) => {
                // Error Block to handle errors
                this.isLoading = false;
            },
        );

        this.subscriptions.push(telogDialogSubscription);
    }

    public importCustomPipe(_: MouseEvent, file: VaultFile): void {
        const customPipeImportDialog = this.dialog.open<
            ConfirmationDialogComponent,
            IComponentDialog,
            IComponentDialogConfirmationResult
        >(ConfirmationDialogComponent, {
            data: <IComponentDialog>{
                title: this.importCustomPipeDialogTitle,
                message: this.importCustomPipeDialogMsg,
                cancelText: this.cancelBtn,
                okText: this.importCustomPipeDialogOk,
            },
        });

        const customPipeImportDialogSubscription = customPipeImportDialog.beforeClosed().subscribe(
            (customPipeImportResult) => {
                if (!customPipeImportResult) {
                    return this.uiUtilsService.safeChangeDetection(this.changeDetector);
                }

                if (customPipeImportResult && customPipeImportResult.whichButtonWasPressed === 'ok') {
                    this.isLoading = true;
                    this.vaultService
                        .importCustomPipe(file.fileName, this.selectedCustomerId, this.selectedLocationId)
                        .subscribe(
                            () => {
                                this.isLoading = false;
                                this.locationDetailsUpdated.emit();
                                this.uiUtilsService.safeChangeDetection(this.changeDetector);
                            },
                            () => {
                                this.isLoading = false;
                                this.uiUtilsService.safeChangeDetection(this.changeDetector);
                            },
                        );
                }
                return this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            () => {
                this.isLoading = false;
            },
        );

        this.subscriptions.push(customPipeImportDialogSubscription);
    }

    /**
     * Delete click handler.
     * @param event Represents an instance of the MouseEvent.
     * @param fileId Represents the file surrogate identifier.
     * @param fileName Represents the name of file.
     */
    public deleteFile(event: MouseEvent, fileId: string, fileName: string, isDeleteFolder?: boolean): void {
        const deleteMessageText = isDeleteFolder ? this.deleteDialogFolderMsg : this.deleteDialogMsg;

        // open delete confirmation dialog
        const deleteDialog = this.dialog.open(ConfirmationDialogComponent, {
            data: <IComponentDialog>{
                title: this.deleteDialogTitle,
                message: deleteMessageText,
                cancelText: this.cancelBtn,
                okText: this.deleteBtn,
            },
            panelClass: 'vault-file-delete-background-panel',
        });

        // listen for backdrop click which would indicate cancellation of delete
        const deleteDialogBackDropClickSubscription = deleteDialog.backdropClick().subscribe(
            () => {
                // detect changes
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            (error) => {
                // Error Block to handle errors
                this.isLoading = false;
            },
        );

        this.subscriptions.push(deleteDialogBackDropClickSubscription);

        // listen for close event
        const deleteDialogBeforeCloseSubscription = deleteDialog.beforeClosed().subscribe(
            (result: IComponentDialogConfirmationResult) => {
                if (result && result.whichButtonWasPressed === 'ok') {
                    this.removeFile(fileId, isDeleteFolder, fileName);
                }

                // detect changes
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            (error) => {
                // Error Block to handle errors
                this.isLoading = false;
            },
        );

        this.subscriptions.push(deleteDialogBeforeCloseSubscription);
    }

    /**
     * Represents the file remove handler.
     * @param id The vault file surrogate identifier.
     */
    public removeFile(id: string, isDeleteFolder: boolean, name: string) {
        // diplaying loader before service call
        this.isLoading = true;
        const indexedArray = `{"${id}": ${isDeleteFolder}}`;
        const deleteSubscription = this.vaultService.deleteFiles(indexedArray).subscribe(
            (result: boolean) => {
                // get the latest vault data for current customer and stop preloader
                const getSubscription = this.vaultService
                    .getRootVaultListForCustomer()
                    .subscribe(
                        () => {
                            this.isLoading = false;
                        },
                        (error) => {
                            // Error Block to handle errors
                            this.isLoading = false;
                        },
                    )
                    .unsubscribe(); // immediately unsubscribe for preventing duplicate calls

                this.containingFiles
                    .subscribe(
                        (files: Array<VaultFile>) => {
                            // find the file by id
                            const foundFile = files.find((f: VaultFile) => f.uri === id);

                            // set name for file
                            const fileName = isDeleteFolder ? name : foundFile ? foundFile.fileName : id;

                            const typeName = isDeleteFolder ? this.folderText : this.fileText;
                            // display snackbar with deletion message
                            const snackBarRef = this.snackBar.open(
                                `${typeName} "${fileName}"  ${this.removedSuccessText}`,
                                this.dismissText,
                                <MatSnackBarConfig>{ duration: 10000 },
                            );
                            snackBarRef.afterDismissed().subscribe(
                                () => {},
                                (error) => {
                                    // Error Block to handle errors
                                    this.isLoading = false;
                                },
                            );
                            // force detect changes
                            this.uiUtilsService.safeChangeDetection(this.changeDetector);
                        },
                        (error) => {
                            // Error Block to handle errors
                            this.isLoading = false;
                        },
                    )
                    .unsubscribe();
            },
            (error) => {
                // Error Block to handle errors
                this.isLoading = false;
            },
        );

        this.subscriptions.push(deleteSubscription);
    }
}
