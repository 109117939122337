<div class="qvi-stats">
    <app-loader [isLoading]="basinQviLoader"></app-loader>
    <div
        class="qvi-no-results"
        *ngIf="!basinQviLoader && (!basinQviDataSource.data || basinQviDataSource.data.length == 0)"
    >
        {{ 'SLIICER.FLOW_TAB.QVI_STATS.NO_RESULTS' | translate }}
    </div>
    <div id="qvi-table" *ngIf="basinQviDataSource.data && basinQviDataSource.data.length > 0">
        <mat-table #table [dataSource]="basinQviDataSource" matSort>
            <ng-container matColumnDef="group">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.GROUP' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.configurationGroup ? element.configurationGroup : element.group }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mode">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.MODE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.mode }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="useAlternate">
                <mat-header-cell *matHeaderCellDef>
                    {{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.USE_ALTERNATE_REGRESSION' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ !element.result ? ('SLIICER.COMMON.NO' | translate) : element.result.useAlt ? ('SLIICER.COMMON.YES' | translate) : ('SLIICER.COMMON.NO' | translate) }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.TYPE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ typeMap[element.type] }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="N">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.N' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.result && element.result.n }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="R2">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.R_2' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ !element.result ? '' : element.result.useAlt ? (element.result.altR2 | number : genericPrecision) : (element.result.r2 | number: genericPrecision) }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="Slope">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.SLOPE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span>
                        {{ !element.result ? '' : element.result.useAlt ? (element.result.altSlope | number: slopePrecision) : (element.result.coeff2 | number: slopePrecision) }}
                    </span>
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="YIntercept">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.Y_INTERCEPT' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <span *ngIf="element.type === BasinType.PeakPeak || element.type === BasinType.PeakVolToPeak">
                        {{ !element.result ? '' : element.result.useAlt ? 0 : element.result.coeff1 | number: getYIntPrecision(element.type) }}
                    </span>
                    <span *ngIf="element.type !== BasinType.PeakPeak && element.type !== BasinType.PeakVolToPeak">
                        {{ !element.result ? '' : element.result.useAlt ? 0 : element.result.coeff1 | number: getYIntPrecision(element.type) }}
                    </span>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="basinQviColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: basinQviColumns"></mat-row>
        </mat-table>


        <!-- #28793 Since the slicer community may want to see more stats later, make the change requested in such a way that if needed we can bring back the list that's currently displayed. -->
        <!-- <mat-table #table [dataSource]="basinQviDataSource" matSort>
            <ng-container matColumnDef="group">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.GROUP' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.group }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="mode">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.MODE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.mode }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="useAlternate">
                <mat-header-cell *matHeaderCellDef>
                    {{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.USE_ALTERNATE_REGRESSION' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.useAlt ? ('SLIICER.COMMON.YES' | translate) : ('SLIICER.COMMON.NO' | translate) }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="zeros">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.ZEROS' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.zeros ? ('SLIICER.COMMON.YES' | translate) : ('SLIICER.COMMON.NO' | translate) }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="type">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.TYPE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.type }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="regressionType">
                <mat-header-cell *matHeaderCellDef>
                    {{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.REGRESSION_TYPE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.regressionType }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="sumQ">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.SUM_Q' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.result && element.result.sumQ }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="sumR">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.SUM_R' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.result && element.result.sumR }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="sumQR">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.SUM_QR' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.result && element.result.sumQR }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="sumQ2">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.SUM_Q2' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.result && element.result.sumQ2 }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="sumR2">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.SUM_R2' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.result && element.result.sumR2 }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="N">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.N' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.result && element.result.n }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="averageQ">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.AVERAGE_Q' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.result && element.result.avgQ }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="averageR">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.AVERAGE_R' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.result && element.result.avgR }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="coefficient1">
                <mat-header-cell *matHeaderCellDef>
                    {{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.COEFFICIENT_1' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.result && element.result.coeff1 }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="coefficient2">
                <mat-header-cell *matHeaderCellDef>
                    {{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.COEFFICIENT_2' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.result && element.result.coeff2 }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="altSlope">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.ALT_SLOPE' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.result && element.result.altSlope }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="altR2">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.ALT_R2' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.result && element.result.altR2 }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="altT">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.ALT_T' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.result && element.result.altT }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="R">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.R' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.result && element.result.r }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="R2">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.R_2' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.result && element.result.r2 }} </mat-cell>
            </ng-container>
            <ng-container matColumnDef="T">
                <mat-header-cell *matHeaderCellDef
                    >{{ 'SLIICER.FLOW_TAB.QVI_STATS.TABLE_HEADERS.T' | translate }}
                </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.result && element.result.t }} </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="basinQviColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: basinQviColumns"></mat-row>
        </mat-table> -->
    </div>
</div>
