import { Injectable, EventEmitter, Output } from '@angular/core';
import { UsersInfo } from 'app/shared/models/users';
import { Subject, BehaviorSubject } from 'rxjs';
import { GetCustomerSummary } from '../models/customer';
import { ViewData } from '../models/view-data';
import { ScatterData } from '../models/scatter-data';
@Injectable({ providedIn: 'root' })
export class StatusCodeService {
    // track http calls
    private cancelPendingRequests$ = new Subject<void>();
    // track global customer dropdown change
    public isCustomerChanged = new BehaviorSubject<boolean>(false);
    public isLocationGroupChanged = new BehaviorSubject<boolean>(false);
    public enableGlobalDropDowns = new BehaviorSubject<boolean>(false);
    public editedCustomerData = new BehaviorSubject<GetCustomerSummary>(<GetCustomerSummary>{});
    public directUser: boolean;
    /**
     * Use to persist current location from hydrograph details page to view-data filter page
     */
    public currentLocationId = new BehaviorSubject<number>(null);
    /**
     * Handle the changed customer by other user
     */
    public currentCustomerId = new BehaviorSubject<number>(0);
    /**
     * Represents a placeholder for HTTP response code.
     */
    public statusCodeFlag = new BehaviorSubject<boolean>(false);
    /**
     * Handle status code 409
     */
    public statusCode409 = new BehaviorSubject<boolean>(false);

    /**
     * Represents the recently edited user.
     */
    public userEmailInEditScreen = new BehaviorSubject<string>('');
    public customerStatus = new BehaviorSubject<boolean>(false);
    /**
     * Represents the recently edited customer.
     */
    public customerIdInEditScreen = new BehaviorSubject<number>(-1);

    /**
     * Represents the recently edited customer.
     */
    public customerIdInEditName = new BehaviorSubject<string>('');
    /**
     * Represents the route from where its redirected.
     */
    public redirectedFrom = new BehaviorSubject<string>('');
    /**
     * Represents the recently edited custom alarm location.
     */
    public customAlarmIdInEditScreen = new BehaviorSubject<number>(0);

    public setQuickRangeInDateFilter = new BehaviorSubject<number>(1);

    public customerIdEdited = new BehaviorSubject<number>(0);

    public isCustomAlarmEnabled = new BehaviorSubject<boolean>(false);

    public userInfo = new BehaviorSubject<UsersInfo>({});

    @Output() public userInfoDarkTheme: EventEmitter<boolean> = new EventEmitter();

    public isEnable = false;

    public userInfoThemeBS = new BehaviorSubject<boolean>(false);

    public isThemeChanged = new BehaviorSubject<boolean>(false);

    public addNewCustomer = new BehaviorSubject<boolean>(false);

    public hydroGraphData = new BehaviorSubject<ViewData>(null);

    public scatterGraphData = new BehaviorSubject<ScatterData>(null);

    public scatterGraphExpand = new BehaviorSubject<boolean>(false);

    public timeInterval = new BehaviorSubject<any>(null);

    public isVisibleCollection = new BehaviorSubject<boolean>(null);

    public activeInactiveHandler = new BehaviorSubject<boolean>(false);

    public isLocationGroupsModified = new BehaviorSubject<boolean>(false);

    public isLocationCreated = new BehaviorSubject<boolean>(false);

    public selectedMapTypeId = new BehaviorSubject<number>(2);
    public refreshHydrographOnEditWindow = new BehaviorSubject<boolean>(false);
    public clearFlaggedPointsLable = new BehaviorSubject<boolean>(false);

    /**
     * Below function will set the initial value for the statusCodeFlag
     */
    public setInitialValue() {
        this.statusCodeFlag = new BehaviorSubject<boolean>(false);
    }

    public toggleTheme() {
        this.isEnable = !this.isEnable;
        this.userInfoDarkTheme.emit(this.isEnable);
    }

    /** Cancels all pending Http requests. */
    public cancelPendingRequests() {
        this.cancelPendingRequests$.next();
    }

    public onCancelPendingRequests() {
        return this.cancelPendingRequests$.asObservable();
    }
}
