import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Output, EventEmitter, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { UsersService } from 'app/pages/admin/users.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { Subject, Subscription } from 'rxjs';

const DAY = 24 * 60 * 60 * 1000;
const WEEK = 7 * DAY;

export enum RANGE_BUTTONS {
    Day = 0,
    Week = 1,
    Month = 2,
    All = 3,
    None = 4
}

@Component({
    selector: 'app-hydrograph-tracer-panel',
    templateUrl: './hydrograph-tracer-panel.component.html',
    styleUrls: ['./hydrograph-tracer-panel.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HydrographTracerPanelComponent implements OnInit, OnDestroy, OnChanges {

    @Input() public maximumDateRange: Date[];
    @Input() public selectedDateRange: number[];

    @Output() public zoomButtonClicked = new EventEmitter<RANGE_BUTTONS>();
    @Output() public tracerButtonClicked = new EventEmitter<boolean>();

    public rangeButtons: {id: number, text: string, disabled: boolean}[] = [
        {
            id: RANGE_BUTTONS.Day,
            disabled: false,
            text: '1d',
        },
        {
            id: RANGE_BUTTONS.Week,
            disabled: false,
            text: '1w',
        },
        {
            id: RANGE_BUTTONS.Month,
            disabled: false,
            text: '1m',
        },
        {
            id: RANGE_BUTTONS.All,
            disabled: false,
            text: 'Off',
        },
    ];

    public selectedRangeButton = RANGE_BUTTONS.All;
    public tracerButtonOn = false;
    private maximumRange: number = WEEK;

    private subscriptions: Array<Subscription> = [];

    constructor(
        private usersService: UsersService,
        private dateUtilService: DateutilService
    ) { }

    ngOnInit(): void {
        if (this.maximumDateRange) {
            const [start, end] = this.maximumDateRange;
            // #39549 adjust dalight saving time change
            this.maximumRange = this.dateUtilService.calculateDiff(start, end);

            const days = this.maximumRange / DAY;
            this.adjustTimeRange(days, start);
        }

        if (this.selectedDateRange) {
            const [start, end] = this.selectedDateRange;
            const timerange = end - start;

            this.adjustSelected(timerange, start);
        }

        this.tracerButtonOn = this.usersService.staticTracerSetting.getValue();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.maximumDateRange && changes.maximumDateRange.currentValue) {
            const [start, end] = changes.maximumDateRange.currentValue;
            this.maximumRange = this.dateUtilService.calculateDiff(start, end);

            const days = this.maximumRange / DAY;
            this.adjustTimeRange(days, start);
        }

        if (changes.selectedDateRange && changes.selectedDateRange.currentValue) {
            const [start, end] = changes.selectedDateRange.currentValue;
            const timerange = end - start;
            this.adjustSelected(timerange, start);
        }
    }

    public adjustSelected(diffMs: number, startTS: number) {
        const currentMonthDaysCount = 31;

        if(diffMs === DAY) this.selectedRangeButton = RANGE_BUTTONS.Day;
        else if(diffMs === WEEK) this.selectedRangeButton = RANGE_BUTTONS.Week;
        else if(diffMs === currentMonthDaysCount * DAY) this.selectedRangeButton = RANGE_BUTTONS.Month;
        else if(diffMs === this.maximumRange) this.selectedRangeButton = RANGE_BUTTONS.All;
        else this.selectedRangeButton = RANGE_BUTTONS.None;
    }

    public adjustTimeRange(days: number, start: Date) {
        const currentMonthDaysCount = 31;

        this.rangeButtons[RANGE_BUTTONS.Day].disabled = days < 1;
        this.rangeButtons[RANGE_BUTTONS.Week].disabled = days < 7;
        this.rangeButtons[RANGE_BUTTONS.Month].disabled = days < currentMonthDaysCount;
    }

    public clickZoomButton(buttonId: RANGE_BUTTONS) {
        this.selectedRangeButton = buttonId;
        this.zoomButtonClicked.emit(buttonId);
    }

    public clickTracerButton() {
        this.tracerButtonOn = !this.tracerButtonOn;
        this.usersService.updateStaticTracerSetting(this.tracerButtonOn).subscribe(() => {
            this.tracerButtonClicked.emit(this.tracerButtonOn);
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
        this.subscriptions = [];
    }
}
