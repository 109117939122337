<form name="dryDefinitionForm" #dryDefinitionForm="ngForm" novalidate>
    <div class="custom-50p">
        <div class="custom-error input-text-width">
            <mat-form-field class="full-width">
                <input
                    class="form-control"
                    type="number"
                    matInput
                    required
                    placeholder="{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.MINIMUM_RAINFALL_1DAY' | translate }}"
                    [disabled]="isStudyLocked"
                    [(ngModel)]="formValues.minRainfallPrevOneDay"
                    (ngModelChange)="checkForm(); checkChanges.emit('')"
                    name="minRainfallPrevOneDay"
                    [pattern]="isMetric?numericWith1DecimalPlaces:numericWith2DecimalPlaces"
                />
                <span matSuffix>{{ unitValue }}</span>
            </mat-form-field>
            <div
                class="mat-error"
                *ngIf="dryDefinitionForm?.controls?.minRainfallPrevOneDay?.errors"
            >
                {{ isMetric ? ('COMMON.NUMERIC_PRECISION_ERROR_1_DECIMAL' | translate) : ('COMMON.NUMERIC_PRECISION_ERROR_2_DECIMALS' | translate) }}
            </div>
        </div>
    </div>
    <div class="custom-50p">
        <div class="custom-error input-text-width">
            <mat-form-field class="full-width">
                <input
                    class="form-control"
                    type="number"
                    matInput
                    required
                    placeholder="{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.MAXIMUM_RAINFALL_3DAYS' | translate }}"
                    [disabled]="isStudyLocked"
                    [(ngModel)]="formValues.minRainfallPrevThreeDays"
                    (ngModelChange)="checkForm(); checkChanges.emit('')"
                    name="minRainfallPrevThreeDays"
                    [pattern]="isMetric?numericWith1DecimalPlaces:numericWith2DecimalPlaces"
                />
                <span matSuffix>{{ unitValue }}</span>
            </mat-form-field>
            <div
                class="mat-error"
                *ngIf="dryDefinitionForm?.controls?.minRainfallPrevThreeDays?.errors"
            >
                {{ isMetric ? ('COMMON.NUMERIC_PRECISION_ERROR_1_DECIMAL' | translate) : ('COMMON.NUMERIC_PRECISION_ERROR_2_DECIMALS' | translate) }}
            </div>
        </div>
    </div>
    <div class="custom-50p">
        <div class="custom-error input-text-width">
            <mat-form-field class="full-width">
                <input
                    class="form-control"
                    type="number"
                    matInput
                    required
                    placeholder="{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.MAXIMUM_RAINFALL_5DAYS' | translate }}"
                    [disabled]="isStudyLocked"
                    [(ngModel)]="formValues.minRainfallPrevFiveDays"
                    (ngModelChange)="checkForm(); checkChanges.emit('')"
                    name="minRainfallPrevFiveDays"
                    [pattern]="isMetric?numericWith1DecimalPlaces:numericWith2DecimalPlaces"
                />
                <span matSuffix>{{ unitValue }}</span>
            </mat-form-field>
            <div
                class="mat-error"
                *ngIf="dryDefinitionForm?.controls?.minRainfallPrevFiveDays?.errors"
            >
                {{ isMetric ? ('COMMON.NUMERIC_PRECISION_ERROR_1_DECIMAL' | translate) : ('COMMON.NUMERIC_PRECISION_ERROR_2_DECIMALS' | translate) }}
            </div>
        </div>
    </div>
    <div class="custom-50p">
        <div class="custom-error input-text-width">
            <mat-form-field class="full-width">
                <input
                    class="form-control"
                    type="number"
                    required
                    matInput
                    placeholder="{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.MINIMUM_AVERAGE_FLOW' | translate }}"
                    [disabled]="isStudyLocked"
                    [(ngModel)]="formValues.minFlow"
                    (ngModelChange)="checkForm(); checkChanges.emit('')"
                    name="minFlow"
                    [pattern]="isPositiveInteger"
                />
                <span matSuffix>%</span>
            </mat-form-field>
            <div
                class="mat-error"
                *ngIf="dryDefinitionForm?.controls?.minFlow?.errors"
            >
                {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.INTEGER_VALIDATION_ERROR' | translate }}
            </div>
        </div>
    </div>
    <div class="custom-50p">
        <div class="custom-error input-text-width">
            <mat-form-field class="full-width">
                <input
                    class="form-control"
                    type="number"
                    matInput
                    required
                    placeholder="{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.MAXIMUM_AVERAGE_FLOW' | translate }}"
                    [disabled]="isStudyLocked"
                    [(ngModel)]="formValues.maxFlow"
                    (ngModelChange)="checkForm(); checkChanges.emit('')"
                    name="maxFlow"
                    [pattern]="isPositiveInteger"
                />
                <span matSuffix>%</span>
            </mat-form-field>
            <div
                class="mat-error"
                *ngIf="dryDefinitionForm?.controls?.maxFlow?.errors"
            >
                {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.INTEGER_VALIDATION_ERROR' | translate }}
            </div>
        </div>
    </div>
    <div class="summary-details">

        <p>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.DRY_DAY_LIST' | translate }}</p>

        <ul class="list">
            <li>
                {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.RAINFALL_LIST1' | translate }}
                <b>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.1_DAY' | translate }}</b>
                {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.LESS_THAN' | translate }}
                <i>
                    <b>
                        <span>"{{ formValues.minRainfallPrevOneDay }} {{ unitValue }}".</span>
                    </b>
                </i>
            </li>

            <li>
                {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.RAINFALL_LIST1' | translate }}
                <b>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.3_DAYS' | translate }}</b>
                {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.LESS_THAN' | translate }}
                <i>
                    <b><span>"{{ formValues.minRainfallPrevThreeDays }} {{ unitValue }}".</span></b>
                </i>
            </li>

            <li>
                {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.RAINFALL_LIST1' | translate }}
                <b>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.5_DAYS' | translate }}</b>
                {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.LESS_THAN' | translate }}
                <i>
                    <b><span>"{{ formValues.minRainfallPrevFiveDays }} {{ unitValue }}".</span></b>
                </i>
            </li>
            <li>
                {{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.AVERAGE_FLOW' | translate }}
                <i>
                    <b>"{{ formValues.minFlow }}
                        <span>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.PERCENT' | translate }}"
                            <b>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.GREATER_THAN' | translate }} "</b>
                            {{ formValues.maxFlow }} </span>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.PERCENT' | translate }}"
                    </b> </i>{{ 'CUSTOMER_EDITOR.RAIN_PROFILE.DRY_DAY.DRY_DAYS' | translate }}
            </li>
        </ul>
    </div>
</form>
