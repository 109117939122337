import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface ConfirmDialogData {
    title: string;
    message: string;
    link?: {
        title: string,
        action: () => void
    }
}
@Component({
    selector: 'app-confirm-dialog',
    template: '\
<div>\
    <div mat-dialog-title>{{data.title | translate}}</div>\
    <div mat-dialog-content>{{data.message | translate}}</div>\
    <div *ngIf="data.link"><a (click)="data.link.action()">{{data.link.title}}</a></div>\
</div>\
<mat-dialog-actions>\
    <div style="margin-left: auto"><button mat-button (click)="close()">{{"COMMON.OK" | translate}}</button></div>\
</mat-dialog-actions>\
'
})
export class ConfirmDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData,
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    ) {}

    public close() {
        this.dialogRef.close();
    }
}
