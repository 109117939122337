import {
    Component,
    OnInit,
    OnDestroy,
    ViewEncapsulation,
    Input,
    ViewChild,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
} from '@angular/core';
import { MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription } from 'rxjs';
import * as _ from 'underscore';
import {
    SliicerCaseStudy,
    StormEvent,
    BasinStormSettings,
    StormSettings,
    DryDayData,
    BasinStormResult,
    WeekGroup,
    PrecompensationType,
    Overrides,
} from 'app/shared/models/sliicer';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerService, MINUTES_IN_A_DAY } from 'app/shared/services/sliicer.service';
import { SLIICER_TABLE } from 'assets/i18n/en-us.json';
import {
    StormPeriodAdjustment,
    ExcludeStormChanged,
    PrecompensationChanged,
    SeasonChange,
    RegimeChange,
} from '../flow-monitor.model';
import { QIGraphComponent } from '../q-i-graph/q-i-graph.component';
import { QvIChartInfo } from '../q-i-graph/q-i-graph.model';
import { StormDecompositionHydrographComponent } from '../storm-decomposition-hydrograph/storm-decomposition-hydrograph.component';
import { StormSettingsDialogComponent } from './storm-settings-dialog/storm-settings-dialog.component';
import { filter } from 'rxjs/operators';
import { StormSettingsDialogData } from './storm-settings-dialog/storm-settings-dialog.utils';
import { fromNullable } from 'fp-ts/es6/Option';
import { BasinQvsISettingsOverrides, StormEventAdjustment } from 'app/shared/models/sliicer/overrides';
import { DesignStormsDialogComponent } from './design-storms-dialog/design-storms-dialog.component';
import { DesignStormsDialogData } from './design-storms-dialog/design-storms-dialog.utils';
import { DesignStormItem } from 'app/shared/models/sliicer/design-storm';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { Season } from '../../study-settings/seasons-settings/seasons-settings.utils';
import { StormHelperService } from 'app/pages/sliicer/shared/services/stormHelper/storm-helper.service';
import { QvsIConfigurations, StormChooserModel } from 'app/shared/models/sliicer/results/storm-events';

import { ExpandedStormChooserComponent } from './expanded-storm-chooser/expanded-storm-chooser.component';
import { BasinQvi, BasinQviGroup, Mode, QVI_CHART_INFO, Type } from '../basin-qvi-stats/basin-qvi-model';
import { QVI_GROUP_ALL_STORMS } from 'app/shared/models/sliicer/basins';
import { StormDefinition } from 'app/shared/models/sliicer/customer-rainfall-profile';
import { CustomerService } from 'app/shared/services/customer.service';
import { BasinStateEnum } from 'app/shared/models/sliicer/metadata';
import { SliicerStormStatsComponent } from './storm-stats/storm-stats.component';

const showFullClass = 'show-full';
const showHalfClass = 'show-half';
const hideGraphClass = 'hide-graph';
const lengthKeys = ['precompPeriodLength', 'recovery1PeriodLength', 'recovery2PeriodLength'];
const PRECOMP_PERIOD_KEY = 'precompPeriodLength';
const RECOVERY_1_PERIOD_KEY = 'recovery1PeriodLength';
const RECOVERY_2_PERIOD_KEY = 'recovery2PeriodLength';
const STORM_PERIOD_KEY = 'stormPeriodLength';
const STORM_START_TIME_KEY = 'stormStartTime';
const DEATTACH_PRECOMP_KEY = 'deattachPrecomp';
const EXCLUDE_KEY = 'exclude';

@Component({
    selector: 'ads-storm-events',
    templateUrl: './storm-events.component.html',
    styleUrls: ['./storm-events.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AdsStormEventsComponent implements OnInit, OnChanges, OnDestroy {
    @Output() public excludedStormToggled = new EventEmitter<ExcludeStormChanged>();
    @Output() public updatedStormEventsPeriods = new EventEmitter<StormPeriodAdjustment>();
    @Output() public precompensationMethodChanged = new EventEmitter<PrecompensationChanged>();
    @Output() public stormEventsEdited = new EventEmitter<Overrides>();
    @Output() public manualStormEdited = new EventEmitter<{ storm: StormPeriodAdjustment, oldStartTime: string }>();
    @Output() public seasonChanged = new EventEmitter<SeasonChange>();
    @Output() public regimeChanged = new EventEmitter<RegimeChange>();
    @Output() public qvsiConfChanged = new EventEmitter<QvsIConfigurations[]>();
    @Output() public selectedStormIdChanged = new EventEmitter<number>();
    @Output() public basinQvsIEdited = new EventEmitter<BasinQvsISettingsOverrides>();
    @Output() public deleteStorm = new EventEmitter<StormChooserModel>();
    @Output() public recallBasinOverrides = new EventEmitter();
    @Output() private refreshStormBasinResults = new EventEmitter<BasinStormResult[]>()

    @Input() public defaultPrecompType: PrecompensationType;
    @Input() public customerId: number;
    @Input() public caseStudyId: string;
    @Input() public dayOfWeekGroups: WeekGroup[];
    @Input() public stepLength: number;
    @Input() public selectedBasin: string;
    @Input() public stormEvents: StormEvent[];
    @Input() public stormBasinResults: BasinStormResult[];
    @Input() public qvsIConfigurations: QvsIConfigurations[];
    @Input() public originalQvsIConfigurations: QvsIConfigurations[];
    @Input() public basinStorms: BasinStormSettings[];
    @Input() public stormSettings: StormSettings[];
    @Input() public basinDryDays: DryDayData;
    @Input() public seasons: Season[];
    @Input() public regimes: [];
    @Input() public availableSeasons: [];
    @Input() public addedStormEvents: StormEventAdjustment[] = [];
    @Input() public removedStormEvents: StormEventAdjustment[] = [];
    @Input() public basinQvsiOverride: BasinQvsISettingsOverrides[] = [];
    @Input() public isLoading: boolean;
    @Input() public availableYears: number[];
    @Input() public hasYears: boolean;
    @Input() public refreshStorm: Observable<void>;
    @ViewChild('hydroGraphChart') public hydroGraphChart: StormDecompositionHydrographComponent;
    @ViewChild('qviComponent') public qviComponent: QIGraphComponent;
    @ViewChild('expandedStormChooserComponent') public expandedStormChooserComponent: ExpandedStormChooserComponent;
    @ViewChild(SliicerStormStatsComponent) public stormStatsComponent: SliicerStormStatsComponent;

    private subscriptions: Subscription[] = [];

    // translations
    private rainfallLabel: string;
    private yAxisLeftLabel: string;
    private yAxisRightLabel: string;
    private grossIILabel: string;
    private netIILabel: string;
    private grossQLabel: string;
    private dryDayFlowLabel: string;

    public stormPeriods: StormPeriodAdjustment;
    public stormStartTime: string;
    public stormLabel: string;
    public selectedStormId: number;
    public selectedBasinStorm: BasinStormResult;
    public tooltipTimeFormat = '';
    public xAxisDateFormat = '';
    public unitOfMeasure = 'in';
    public xAxisMinMaxPoint: number[] = [];

    public isStudyLocked: boolean;
    public isRainOnTop: boolean;

    public customDateFormat: string;
    public customerUnit = '';

    public stormDecompositionFullScreen = false;
    public qviFullScreen = false;
    public isGross = true;
    public qviChartInfos: QvIChartInfo[] = [];
    public currentChartIndex = 0;
    public adjHyClass = 'show-half';
    public qviClass = 'show-half';
    public currentXFieldAccessor: string;
    public currentYFieldAccessor: string;
    public currentXAxisLabel: string;
    public currentYAxisLabel: string;
    public currentVolumePerTime: boolean;
    public currentMeasurePerHour: boolean;
    public currentComparisonType: string;
    public currentInfo: QvIChartInfo;
    public showRegressionLine = true;
    public showDates = true;
    public showStormHighlight = true;
    public hydrographTooltipEnabled = true;
    public hydrographStormPeriodsEnabled = true;

    public expandedChooser = false;

    public hydrographModeledPrecompensation = true;
    public qiGraphTooltipEnabled = true;
    public selectedPrecompType: PrecompensationType = null;
    public designStorms: DesignStormItem[] = [];
    public excludedStormIds: { [key: string]: number[] } = {};
    private selectedStorm: StormEvent;
    public useAltReg = true;
    public altRegDisabled = false;
    public showUseAltReg = true;
    public basinQVIs: BasinQvi[] = [];

    public displayAnnotations: { hydrograph: boolean; qvi: boolean } = { hydrograph: false, qvi: false };
    public loadingBasinStormResults = false;

    public isHGZoomed = false;
    public showAltRegCheckbox = true;

    public excludeStormSubject = new Subject<void>();
    private qvsiGroupName: string;

    constructor(
        private dateutilService: DateutilService,
        private translateService: TranslateService,
        private matDialog: MatDialog,
        private sliicerService: SliicerService,
        private uiUtilsService: UiUtilsService,
        private changeDetector: ChangeDetectorRef,
        private stormHelper: StormHelperService,
        private customerService: CustomerService
    ) { }

    public ngOnInit() {

        this.initQvIAccessors();

        this.customerUnit = this.dateutilService.customerUnit.getValue();
        this.tooltipTimeFormat = this.dateutilService.timeFormat.getValue();
        this.unitOfMeasure = this.dateutilService.isCustomerUnitsMetric.getValue() ? 'mm' : 'in';
        this.customDateFormat = this.sliicerService.dateTimeFormatEditStorms();
        this.xAxisDateFormat = this.dateutilService.getGraphDateFormat();

        this.applyTranslations();

        this.subscriptions.push(
            this.sliicerService.studyDetailsData$.subscribe((caseStudy: SliicerCaseStudy) => {
                this.isRainOnTop = caseStudy.rainfallGraphFlag;
                if (caseStudy.settings && caseStudy.settings.designStorms) {
                    this.designStorms = caseStudy.settings.designStorms.map((s) => ({ ...s, selected: false }));
                }
            }),
        );

        this.subscriptions.push(
            this.sliicerService.caseStudyEditable.subscribe((editable: boolean) => {
                this.isStudyLocked = !editable;
            }),
        );

        this.subscriptions.push(
            this.sliicerService.basinQVIResult$.subscribe((res) => {
                this.basinQVIs = res;
                this.applyUseAltReg();
            })
        );

        this.subscriptions.push(
            this.sliicerService.qvsiSelectedConfig.subscribe((config: { conf: QvsIConfigurations; afterupdate?: boolean }) => {
                if (config && config.afterupdate) {
                    return;
                }

                const isAllStromsGroupSelected = !config || !config.conf || config.conf.name === QVI_GROUP_ALL_STORMS;

                if (config) {
                    this.qvsiGroupName = config.conf.name;
                }

                if (!isAllStromsGroupSelected) {
                    this.useAltReg = false;
                    this.showAltRegCheckbox = false;
                } else {
                    this.applyUseAltReg();
                    this.showAltRegCheckbox = true;
                }
            })
        );

        if(this.refreshStorm) {
            this.refreshStorm.subscribe(() => this.fetchBasinStormResults(this.selectedStorm, this.selectedBasin, false, false))
        }

    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscripton) => subscripton.unsubscribe());
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.defaultPrecompType && changes.defaultPrecompType.currentValue) {
            this.selectedPrecompType = this.defaultPrecompType;
        }

        let isNewStormChange = false;
        if (changes.stormEvents && changes.stormEvents.currentValue) {
            if (this.stormEvents.length > 0) {
                this.stormEvents.sort((a, b) => a.stormId < b.stormId ? -1 : a.stormId > b.stormId ? 1 : 0);

                if (this.sliicerService.newlyCreatedStormStartTime) {
                    const newlyCreatedStorm = this.stormEvents.find(v => new Date(v.stormStartTime).getTime() === new Date(this.sliicerService.newlyCreatedStormStartTime).getTime());
                    this.selectedStormId = newlyCreatedStorm ? newlyCreatedStorm.stormId : this.stormEvents[0].stormId;
                    this.sliicerService.newlyCreatedStormStartTime = null;

                    isNewStormChange = true;
                } else {
                    this.selectedStormId = this.selectedStormId ? this.selectedStormId : this.stormEvents[0].stormId;
                }
                this.selectedStormIdChanged.emit(this.selectedStormId);
            } else {
                this.selectedStormId = null;
                this.selectedStormIdChanged.emit(null);
            }
        }

        let selectedStorm = null;
        if (this.stormEvents && this.stormEvents.length) {
            if (this.selectedStormId === 0) {
                selectedStorm = this.stormEvents[0];
                this.selectedStormId = selectedStorm.stormId;
                this.selectedStormIdChanged.emit(this.selectedStormId);
            } else {
                selectedStorm = this.stormEvents.find((s) => s.stormId === this.selectedStormId);
            }
        }

        if (changes.stormEvents && selectedStorm && this.selectedBasin) {
            this.fetchBasinStormResults(selectedStorm, this.selectedBasin, false, isNewStormChange);
        }

        if (changes.basinStorms || changes.stormSettings) {
            if (this.basinStorms) {
                this.excludedStormIds = {};
                for (const storm of this.basinStorms) {
                    if (storm.exclude) {
                        if (!this.excludedStormIds[storm.basinName]) {
                            this.excludedStormIds[storm.basinName] = [];
                        }
                        this.excludedStormIds[storm.basinName].push(storm.stormId);
                    }
                }
            }

            if (selectedStorm !== null) {
                this.stormPeriods = AdsStormEventsComponent.applyStorm(
                    this.selectedBasin,
                    selectedStorm,
                    this.selectedBasinStorm,
                    this.basinStorms,
                    this.stormSettings,
                );
                // #38167 Refresh chart
                this.hydroGraphChart?.refreshStormPeriods(this.stormPeriods);
            }
            if (this.qviComponent && this.qviComponent.qviResults && this.qviComponent.qviResults.length) {
                this.showUseAltReg = this.qviComponent.qviResults[0].result.coeff1 > 0;
            }

            this.applyPrecomp();
        }
    }

    public hgZoomChange(isZoomed) {
        this.isHGZoomed = isZoomed;
    }

    // Refresh the chart if we toggled the slider
    public sliderChanged() {
        this.updateAccessors();
        this.applyUseAltReg();
    }

    public showHideAnnotation(target: 'qvi' | 'hydropraph', value: boolean) {
        this.displayAnnotations[target] = value;
    }

    public stormSettingsMenu() {
        if (this.isStudyLocked) {
            return;
        }
        const precompType = this.stormBasinResults? this.stormBasinResults.find((v) => v.stormId == this.selectedStormId).preCompType: this.selectedPrecompType;
        this.displayAnnotations.hydrograph = false;
        const stormStartTimes = this.stormEvents.filter(v => v.stormId !== this.stormPeriods.stormId).map(v => new Date(v.stormStartTime).getTime());

        const stormExclude =  this.stormBasinResults?.find((v) => v.stormId == this.selectedStormId)?.exclude ;

        this.matDialog
            .open<StormSettingsDialogComponent, StormSettingsDialogData, StormSettingsDialogData>(
                StormSettingsDialogComponent,
                {
                    data: {
                        adjustment: { ...this.stormPeriods },
                        precompType: fromNullable(precompType),
                        season: this.getStormSeason(this.selectedStormId),
                        regime: this.getStormRegime(this.selectedStormId),
                        seasons: this.seasons,
                        regimes: this.regimes,
                        exclude: stormExclude,
                        existingStormsStartDates: stormStartTimes
                    },
                },
            )
            .afterClosed()
            .pipe(filter((x) => !!x))
            .subscribe((res) => {
                const editedKeys = this.checkStormChanges(res.adjustment, this.stormPeriods).filter(v => !!v);

                const overrides: Overrides = {
                    basinStormSettings: this.basinStorms,
                    stormSettings: this.stormSettings,
                };
                this.stormHelper.applyStormEdit(overrides, this.stormPeriods, res, this.selectedBasin);

                if (editedKeys.includes(STORM_START_TIME_KEY)) {
                    this.checkAddedStormsStartTime(overrides, res);
                }

                this.stormEventsEdited.emit(overrides);
            });
    }

    // #39125 when added storm start time is changed, we need to update it in overrides also
    private checkAddedStormsStartTime(overrides: Overrides, res: StormSettingsDialogData) {
        if (!this.addedStormEvents || !this.addedStormEvents.length) {
            return;
        }

        const manualStormIndex = this.addedStormEvents.findIndex(v => new Date(v.stormStartTime).getTime() === new Date(this.stormPeriods.stormStartTime).getTime());

        if (manualStormIndex === -1) return;

        this.addedStormEvents[manualStormIndex].stormStartTime = res.adjustment.stormStartTime;

        overrides.addedStormEvents = this.addedStormEvents;

        if (this.stormEvents && this.stormEvents.length) {
            this.stormEvents.forEach(s => {
                if (new Date(this.stormPeriods.stormStartTime).getTime() === new Date(s.stormStartTime).getTime()) {
                    s.stormStartTime = res.adjustment.stormStartTime;
                }
            });
        }

        if (this.stormBasinResults && this.stormBasinResults.length) {
            this.stormBasinResults.forEach(s => {
                if (new Date(this.stormPeriods.stormStartTime).getTime() === new Date(s.stormStartTime).getTime()) {
                    s.stormStartTime = res.adjustment.stormStartTime;
                }
            });
        }
    }

    public qviSettingsMenu() {
        this.displayAnnotations.qvi = false;
        this.matDialog
            .open<DesignStormsDialogComponent, DesignStormsDialogData, DesignStormsDialogData>(
                DesignStormsDialogComponent,
                { data: this.designStorms, disableClose: true },
            )
            .afterClosed()
            .subscribe((storms) => {
                if (storms) {
                    this.sliicerService
                        .updateCaseStudyDesignStorms(this.customerId, this.caseStudyId, storms)
                        .subscribe(() => {
                            this.designStorms = storms.map((s) => ({ ...s, selected: false }));
                        });
                }
            });
    }

    public onChangePreCompType(preCompValue) {
        const precompChanged: PrecompensationChanged = {
            stormId: this.selectedStormId,
            precompType: preCompValue,
        };
        this.precompensationMethodChanged.emit(precompChanged);
    }

    public getHeadingText(isPlaceholder = false, info?: QvIChartInfo): string {
        // If this is the placeholder, replace the first option also
        if (isPlaceholder) {
            let placeholderText = info
                ? info.heading.replace(/ Net/g, '')
                : this.qviChartInfos[0].heading.replace(/ Net/g, '');
            placeholderText = placeholderText.replace(/ Gross/g, '');
            return placeholderText;
        } else {
            if (info) {
                return this.isGross ? info.heading.replace(/Net/g, 'Gross') : info.heading.replace(/Gross/g, 'Net');
            }
        }
    }

    // Changes color on slider toggle click
    public getColor(state: boolean): string {
        return state ? 'slider-toggle-gross' : 'slider-toggle-net';
    }

    public expandCollapseQvI() {
        this.displayAnnotations.qvi = false;
        if (this.qviClass === showHalfClass) {
            this.qviClass = showFullClass;
            this.adjHyClass = hideGraphClass;
            this.qviFullScreen = true;
            this.resizeQvIChart();
        } else {
            this.qviClass = showHalfClass;
            this.adjHyClass = showHalfClass;
            this.qviFullScreen = false;
            this.resizeQvIChart();
            this.resizeHydroGraphChart();
        }
    }

    public expandCollapseAdj() {
        this.displayAnnotations.hydrograph = false;
        if (this.adjHyClass === showHalfClass) {
            this.adjHyClass = showFullClass;
            this.qviClass = hideGraphClass;
            this.stormDecompositionFullScreen = true;
            this.resizeHydroGraphChart();
        } else {
            this.adjHyClass = showHalfClass;
            this.qviClass = showHalfClass;
            this.stormDecompositionFullScreen = false;
            this.resizeHydroGraphChart();
            this.resizeQvIChart();
        }
    }

    /**
     * Emitted from the storm chooser when the user has toggled the exclude button for a storm
     */
    public toggleStormBasinExclusion(toggleEvent: ExcludeStormChanged): void {
        if (!this.excludedStormIds[this.selectedBasin]) {
            this.excludedStormIds[this.selectedBasin] = [];
        }

        if (toggleEvent.excluded === false) {
            this.excludedStormIds[this.selectedBasin] = this.excludedStormIds[this.selectedBasin].filter(s => s !== toggleEvent.stormId)
        }
        else {
            this.excludedStormIds[this.selectedBasin].push(toggleEvent.stormId);
        }

        this.excludeStormSubject.next();

        this.excludedStormToggled.emit(toggleEvent);
        this.hydrographStormPeriodsEnabled = true;
        this.selectedStormId = toggleEvent.stormId;
        this.selectedStormIdChanged.emit(this.selectedStormId);
        this.selectedStorm = this.stormEvents.find((s) => s.stormId === toggleEvent.stormId);
        this.applyPrecomp();
        this.fetchBasinStormResults(this.selectedStorm, this.selectedBasin);
        this.showUseAltReg = this.qviComponent.qviResults[0] && this.qviComponent.qviResults[0].result.coeff1 > 0;
    }

    public setStormEventsEdited(stormEventsEdited: Overrides): void {
        this.stormEventsEdited.emit(stormEventsEdited);
    }

    /**
     * Emitted from the storm chooser when the user has clicked on a storm
     */
    public stormSelected(stormId: number, isEdit = false) {
        if (this.selectedStormId === stormId && isEdit === false) {
            return;
        }
        this.hydrographStormPeriodsEnabled = true;
        this.selectedStormId = stormId;
        this.selectedStormIdChanged.emit(this.selectedStormId);
        this.selectedStorm = this.stormEvents.find((s) => s.stormId === stormId);
        this.applyPrecomp();
        this.fetchBasinStormResults(this.selectedStorm, this.selectedBasin, isEdit);
        this.showUseAltReg = this.qviComponent.qviResults[0].result.coeff1 > 0;
    }

    public onMinMaxChanged(minMaxArray: number[]) {
        if (minMaxArray && minMaxArray.length) {
            if (minMaxArray[0] !== this.xAxisMinMaxPoint[0] || minMaxArray[1] !== this.xAxisMinMaxPoint[1]) {
                this.xAxisMinMaxPoint = minMaxArray;
            }
        }
    }

    /**
     * Tooltip enabled button event handler.
     */
    public onQIGraphTooltipEnabledChanged() {
        this.qiGraphTooltipEnabled = !this.qiGraphTooltipEnabled;
    }

    public onHydrographModeledPrecompensation() {
        this.hydrographModeledPrecompensation = !this.hydrographModeledPrecompensation;
    }

    /**
     * Tooltip enabled button event handler.
     */
    public onHydrographTooltipEnabledChanged() {
        this.hydrographTooltipEnabled = !this.hydrographTooltipEnabled;
    }

    /**
     * Storm Decomposition Hydrograph toggle show storm periods
     */
    public onHydrographStormPeriodsEnabledChanged() {
        this.hydrographStormPeriodsEnabled = !this.hydrographStormPeriodsEnabled;
    }

    /**
     * Event emitted from the storm period slider when values change
     * @param stormPeriod the new StormPeriodAdjustment
     */
    public onSliderChanged(stormPeriod: StormPeriodAdjustment) {
        const prevStart = this.stormPeriods.stormStartTime
        const newStart = stormPeriod.stormStartTime;

        const isStartTimeChanged = new Date(prevStart).getTime() !== new Date(newStart).getTime();
        const isManual = this.addedStormEvents && this.addedStormEvents.length && this.addedStormEvents.find(v => new Date(v.stormStartTime).getTime() === new Date(prevStart).getTime());

        this.stormPeriods = stormPeriod;

        if (isStartTimeChanged && isManual) {
            this.updatedStormEventsPeriods.emit({...stormPeriod, previousStartTime: prevStart});
        } else {
            this.updatedStormEventsPeriods.emit(stormPeriod);
        }

        // update the chart
        this.hydroGraphChart.refreshStormPeriods(stormPeriod);
    }

    public updateChartIndex(event: Event, index: number) {
        if (event) {
            this.currentChartIndex = index;
            this.updateAccessors();
            this.currentInfo = this.qviChartInfos[this.currentChartIndex];

            this.applyUseAltReg();
        }
    }

    public designStormSelectedChanged(storm: DesignStormItem, selected: boolean) {
        this.designStorms = this.designStorms.map((s) => (s === storm ? { ...s, selected } : s));
    }

    public useAlternateReg() {
        this.useAltReg = !this.useAltReg;
        const qviUseAlt = this.useAltReg;
        const qviType = this.qviChartInfos[this.currentChartIndex].comparisonType;
        const qviMode = this.isGross ? Mode.Gross : Mode.Net;
        const basinQvsI = this.basinQVIs.find(
            (qvi) =>
                qvi.group === BasinQviGroup.AllStorms
                && (qviMode ? qvi.mode === Mode.Gross : qvi.mode === Mode.Net)
                && qvi.type === qviType && qvi.basinName === this.selectedBasin);
        const basinName = basinQvsI.basinName;
        const configurationGroup = basinQvsI.configurationGroup;
        const configuration = basinQvsI.configuration;

        this.basinQvsIEdited.emit({
            mode: qviMode,
            type: Type[qviType],
            useAlt: qviUseAlt,
            basinName: basinName,
            configurationGroup: configurationGroup,
            configuration: configuration
        });
    }

    private applyUseAltReg() {
        if (!this.currentInfo || !this.basinQVIs) {
            this.useAltReg = false;
            return;
        }
        const mode = this.isGross;
        const type = this.currentInfo.comparisonType;

        const isAllStorms = this.qvsiGroupName === BasinQviGroup.AllStorms || this.qvsiGroupName === QVI_GROUP_ALL_STORMS;

        const basinQvi = this.basinQVIs.find(
            (qvi) =>
                (isAllStorms ? (qvi.group === BasinQviGroup.AllStorms || qvi.group === QVI_GROUP_ALL_STORMS) : qvi.configuration === this.qvsiGroupName)
                && (mode ? qvi.mode === Mode.Gross : qvi.mode === Mode.Net)
                && qvi.type === type && qvi.basinName === this.selectedBasin
        );
        const basinQvis = this.basinQVIs.filter(
            (qvi) =>
                (isAllStorms ? (qvi.group === BasinQviGroup.AllStorms || qvi.group === QVI_GROUP_ALL_STORMS) : qvi.configuration === this.qvsiGroupName)
                && (mode ? qvi.mode === Mode.Gross : qvi.mode === Mode.Net)
                && qvi.type === type && qvi.basinName === this.selectedBasin
        );
        this.altRegDisabled = basinQvi ? !basinQvi.result.useAlt : false;

        if (!this.altRegDisabled) {
            const overrideQvi = this.basinQvsiOverride.find(
                (qvi) =>
                    (mode ? qvi.mode === Mode.Gross : qvi.mode === Mode.Net)
                    && qvi.type === type && qvi.basinName === this.selectedBasin) ;

            this.useAltReg = overrideQvi ? overrideQvi.useAlt : !this.altRegDisabled;
        } else {
            this.useAltReg = false;
        }
    }

    public qvsiConfUpdated(res) {
        this.qvsiConfChanged.emit(res);
    }

    public expandChooser(state) {
        this.expandedChooser = state;
        this.uiUtilsService.safeChangeDetection(this.changeDetector);

    }

    /********************************************************************************/
    /* PRIVATE METHODS                                                              */
    /********************************************************************************/

    /**
     * This method will return edited storm properties array
     */
    private checkStormChanges(newSettings: StormPeriodAdjustment, oldSettings: StormPeriodAdjustment) {
        const lengthKeys = [PRECOMP_PERIOD_KEY, RECOVERY_1_PERIOD_KEY, RECOVERY_2_PERIOD_KEY, STORM_PERIOD_KEY];
        const booleanKeys = [DEATTACH_PRECOMP_KEY, EXCLUDE_KEY];

        const editedLengthKeys = lengthKeys.filter(v => newSettings[v] !== oldSettings[v]);
        const editedBoolKeys = booleanKeys.filter(v => Boolean(newSettings[v]) !== Boolean(oldSettings[v]));
        const isStartTimeChanged = new Date(newSettings.stormStartTime).getTime() !== new Date(oldSettings.stormStartTime).getTime();

        return [...editedLengthKeys, ...editedBoolKeys, isStartTimeChanged ? STORM_START_TIME_KEY : ''];
    }

    /**
     * This method will return the season value for the specified storm
     */
    private getStormSeason(stormId: number): string {
        const storm = this.basinStorms.find((s) => s.stormId === stormId);
        if (storm && storm.season) {
            return storm.season;
        }
        return '';
    }

    /**
     * This method will return the regime value for the specified storm
     */
    private getStormRegime(stormId: number): string {
        const storm = this.basinStorms.find((s) => s.stormId === stormId);
        if (storm && storm.regime) {
            return storm.regime;
        }
        return '';
    }

    /**
     * This method will emit change on season value
     */
    private seasonChange(season: string): void {
        const seasonChange: SeasonChange = {
            stormId: this.selectedStormId,
            season: season,
        };
        this.seasonChanged.emit(seasonChange);
    }

    /**
     * This method will emit change on regime value
     */
    private regimeChange(regime: string): void {
        const regimeChange: RegimeChange = {
            stormId: this.selectedStormId,
            regime: regime,
        };
        this.regimeChanged.emit(regimeChange);
    }

    private fetchBasinStormResults(storm: StormEvent, basinName: string, isEdit = false, isNewStormChange = false) {
        if (!storm) return;

        this.loadingBasinStormResults = true;
        this.sliicerService.getStormBasinResults(this.customerId, this.caseStudyId, storm.stormId, basinName).subscribe(
            (result) => {
                this.loadingBasinStormResults = false;
                if (result) {

                    if (isNewStormChange) {
                        result.manuallyAdded = true;

                        this.stormBasinResults = [...this.stormBasinResults, result];
                        this.refreshStormBasinResults.emit(this.stormBasinResults);

                        this.expandedStormChooserComponent.stormEvents = [...this.stormBasinResults];
                        this.expandedStormChooserComponent.stormBasinResults = [...this.stormBasinResults];
                        this.expandedStormChooserComponent.refreshTable();

                        if (this.qviComponent) {
                            const qviComp = this.qviComponent;

                            qviComp.basinStats = qviComp.groupBasinStats(this.stormBasinResults);
                            qviComp.fetchBasinQvIDataAndPlot();
                        }
                    }

                    this.selectedBasinStorm = result;
                    this.stormStartTime = result.stormStartTime;
                    this.stormLabel = this.sliicerService.stormLabelExtras(result.stormStartTime);

                    this.stormPeriods = AdsStormEventsComponent.applyStorm(
                        this.selectedBasin,
                        storm,
                        this.selectedBasinStorm,
                        this.basinStorms,
                        this.stormSettings,
                    );

                    if (isEdit) {
                        this.stormSettingsMenu();
                    }

                    if (!isNewStormChange) {
                        this.expandedStormChooserComponent.loadStormEvents(basinName);
                    }
                } else {
                    this.selectedBasinStorm = null;
                    this.altRegDisabled = true;
                }
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            () => {
                this.selectedBasinStorm = null;
                this.loadingBasinStormResults = false;
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
                // TODO: WPS - clear out invalid results, show error
            },
        );
    }

    private applyPrecomp() {
        const basinStormOverrides = this.basinStorms.find(
            (b) => b.stormId === this.selectedStormId && b.basinName === this.selectedBasin,
        );
        if (basinStormOverrides && basinStormOverrides.precompType !== null) {
            this.selectedPrecompType = basinStormOverrides.precompType;
        } else {
            this.selectedPrecompType = this.defaultPrecompType;
        }
    }

    private initQvIAccessors(): void {
        // Always reset
        if (this.qviChartInfos.length > 0) {
            this.qviChartInfos = [];
        }

        this.currentComparisonType = 'VolVol';
        this.qviChartInfos = QVI_CHART_INFO;

        this.currentXFieldAccessor = this.qviChartInfos[0].xFieldGrossAccessor;
        this.currentYFieldAccessor = this.qviChartInfos[0].yFieldGrossAccessor;
        this.currentYAxisLabel = this.getHeadingText(false, this.qviChartInfos[0]).split(' vs. ')[0];
        this.currentXAxisLabel = this.getHeadingText(false, this.qviChartInfos[0]).split(' vs. ')[1];
        this.currentInfo = this.qviChartInfos[0];
        this.currentVolumePerTime = false;
        this.currentMeasurePerHour = false;
    }

    private applyTranslations(): void {
        const translateKeys: Array<string> = [
            'COMMON.FLOW_RATE',
            'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.RAINFALL_DEPTH',
            'COMMON.RAINFALL',
            'COMMON.GROSS_II',
            'COMMON.NET_II',
            'COMMON.GROSS_Q',
            'COMMON.DRY_DAY_FLOW',
        ];

        this.translateService.get(translateKeys).subscribe((values) => {
            this.yAxisLeftLabel = values['COMMON.FLOW_RATE'];
            this.yAxisRightLabel = values['SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.RAINFALL_DEPTH'];
            this.rainfallLabel = values['COMMON.RAINFALL'];
            this.grossIILabel = values['COMMON.GROSS_II'];
            this.netIILabel = values['COMMON.NET_II'];
            this.grossQLabel = values['COMMON.GROSS_Q'];
            this.dryDayFlowLabel = values['COMMON.DRY_DAY_FLOW'];
        });
    }

    private resizeHydroGraphChart() {
        if (this.hydroGraphChart) {
            this.hydroGraphChart.redraw();
        }
    }

    private resizeQvIChart() {
        if (this.qviComponent) {
            this.qviComponent.redraw();
        }
    }

    private updateAccessors() {
        this.currentXFieldAccessor = this.isGross
            ? this.qviChartInfos[this.currentChartIndex].xFieldGrossAccessor
            : this.qviChartInfos[this.currentChartIndex].xFieldNetAccessor
                ? this.qviChartInfos[this.currentChartIndex].xFieldNetAccessor
                : this.qviChartInfos[this.currentChartIndex].xFieldGrossAccessor;
        this.currentYFieldAccessor = this.isGross
            ? this.qviChartInfos[this.currentChartIndex].yFieldGrossAccessor
            : this.qviChartInfos[this.currentChartIndex].yFieldNetAccessor
                ? this.qviChartInfos[this.currentChartIndex].yFieldNetAccessor
                : this.qviChartInfos[this.currentChartIndex].yFieldGrossAccessor;
        this.currentYAxisLabel = this.getHeadingText(false, this.qviChartInfos[this.currentChartIndex]).split(
            ' vs. ',
        )[0];
        this.currentXAxisLabel = this.getHeadingText(false, this.qviChartInfos[this.currentChartIndex]).split(
            ' vs. ',
        )[1];
        this.currentVolumePerTime = this.qviChartInfos[this.currentChartIndex].volumePerTime;
        this.currentMeasurePerHour = this.qviChartInfos[this.currentChartIndex].measurePerHour;
        this.currentComparisonType = this.qviChartInfos[this.currentChartIndex].comparisonType;
    }

    /********************************************************************************/
    /* PRIVATE STATIC METHODS                                                       */
    /********************************************************************************/

    private static applyStorm(
        selectedBasin: string,
        selectedStorm: StormEvent,
        selectedBasinStorm: BasinStormResult,
        basinStorms: BasinStormSettings[],
        stormSettings: StormSettings[],
    ): StormPeriodAdjustment {
        if (!selectedStorm) {
            return null;
        }
        const precomp = selectedBasinStorm ? selectedBasinStorm.precompDuration : MINUTES_IN_A_DAY;

        const stormPeriods = {
            stormId: selectedStorm.stormId,
            stormStartTime: selectedStorm.stormStartTime,
            precompPeriodLength: precomp,
            stormPeriodLength: selectedStorm.stormPeriodLength,
            recovery1PeriodLength: selectedStorm.recovery1PeriodLength,
            recovery2PeriodLength: selectedStorm.recovery2PeriodLength,
            rainStartTime: selectedStorm.rainStartTime,
            rainEndTime: selectedStorm.rainEndTime,
            altPrecompStart: undefined,
            altPrecompEnd: undefined,
            deattachPrecomp: undefined
        };
        if (basinStorms) {
            const bs = basinStorms.find(
                (bsfilter) => bsfilter.basinName === selectedBasin && bsfilter.stormId === selectedStorm.stormId,
            );
            if (bs && bs.precompLength) {
                stormPeriods.precompPeriodLength = bs.precompLength;
            }
            // #30152 temporary condition with date. Cannot be null at API. Should be removed when #30259 will be done
            if (bs && bs.altPrecompStart && bs.altPrecompStart !== '0001-01-01T00:00:00') {
                stormPeriods.altPrecompStart = bs.altPrecompStart;
                stormPeriods.altPrecompEnd = bs.altPrecompEnd;
                stormPeriods.deattachPrecomp = bs.deattachPrecomp;
            } else {
                stormPeriods.deattachPrecomp = true;
            }
        }
        if (stormSettings) {
            const ss = stormSettings.find((ssfilter) => ssfilter.stormId === selectedStorm.stormId);
            if (ss) {
                if (ss.stormStartTime) {
                    stormPeriods.stormStartTime = ss.stormStartTime;
                }
                if (ss.stormPeriodLength) {
                    stormPeriods.stormPeriodLength = ss.stormPeriodLength;
                }
                if (ss.recovery1PeriodLength) {
                    stormPeriods.recovery1PeriodLength = ss.recovery1PeriodLength;
                }
                if (ss.recovery2PeriodLength) {
                    stormPeriods.recovery2PeriodLength = ss.recovery2PeriodLength;
                }
            }
        }
        return stormPeriods;
    }
}
