import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    SimpleChanges,
    ViewEncapsulation,
    ChangeDetectorRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import * as Highcharts from 'highcharts';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { StringUtils } from '../../../../shared/utils/string-utils';
import { StatusCodeService } from '../../../../shared/services/status-code.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { IDailySummaryLocationDetail } from 'app/shared/models/daily-summary';

@Component({
    selector: 'app-daily-summary',
    templateUrl: './daily-summary.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class DailySummaryComponent implements OnChanges, OnInit {
    @Input() public data: IDailySummaryLocationDetail;

    @Input() public locationId: number;

    @Input() public reportLocationName: string;

    @Input() public isLoading: boolean;

    @Input() public dateFormat: string;

    @Input() public oneDayStartDate: Date;

    @Input() public oneDayEndDate: Date;

    @ViewChild('chartElement') public chartElement: Highcharts.stockChart;

    public customerDateFormat: string;

    public dailySummaryChartData: Object;

    public dailySummaryChartOptions: any;
    public dailySummaryChartContainer: string;
    constructor(
        private router: Router,
        private changeDetectorReference: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private dateutilService: DateutilService,
        private statusCodeService: StatusCodeService,
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        // ensure data property
        if (this.data) {
            this.initDailySummaryChart();
            this.isLoading = false;
            this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
        }
    }

    public ngOnInit() {
        this.dailySummaryChartContainer = 'dailySummarygraph-chart' + '_' + Date.now();
        // subscribe to changes in dateFormat
        this.dateutilService.dateFormat.subscribe((newDateFormat) => {
            this.customerDateFormat = newDateFormat;
        });
    }

    /**
     * Initializes daily summary boxplot chart
     **/
    public initDailySummaryChart() {
        if (!this.data) {
            this.isLoading = false;
            return;
        }

        this.isLoading = true;
        this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
        const entities = new Array<string>();
        this.dailySummaryChartData = null;

        if (
            this.data &&
            this.data['days'] &&
            this.data['days'].length > 0 &&
            this.data['entities'] &&
            this.data['entities'].length > 0 &&
            this.data['days'][0].statistics
        ) {
            this.dailySummaryChartData = this.data;
            const series = new Array<Object>();

            this.dailySummaryChartData['days'][0].statistics.forEach((element) => {
                if (element.values) {
                    const formattedValues = new Array<number>();
                    element.values.forEach((item, index) => {
                        const numberStr = item.value.toFixed(this.dailySummaryChartData['precisions'][index]);
                        formattedValues.push(Number(numberStr));
                    });
                    series.push({ name: element.name, data: formattedValues });
                }
            });

            this.uiUtilsService.safeChangeDetection(this.changeDetectorReference);
            this.statusCodeService.userInfoThemeBS.subscribe((response: boolean) => {
                if (response) {
                    StringUtils.setHighChartTheme(this.dailySummaryChartOptions);
                } else {
                    StringUtils.setHighchartWhiteTheme(this.dailySummaryChartOptions);
                }
            });

            const self = this;
                this.dailySummaryChartOptions = new Highcharts.Chart({
                    exporting: {
                        enabled: false,
                    },
                    colors: ['rgb(0, 204, 106)', 'rgb(0, 99, 177)', 'rgb(177, 70, 194)'],
                    chart: {
                        renderTo: !this.chartElement.nativeElement
                            ? this.dailySummaryChartOptions.chart.renderTo
                            : this.chartElement.nativeElement,
                        type: 'column',
                        height: 220,
                    },
                    credits: {
                        enabled: false,
                    },
                    title: {
                        text: null,
                    },
                    xAxis: {
                        categories: this.data['entities'],
                        title: null,
                    },
                    yAxis: {
                        title: {
                            text: null,
                        },
                    },
                    legend: {
                        align: 'right',
                        verticalAlign: 'top',
                        layout: 'vertical',
                        x: 0,
                        y: 100,
                    },
                    tooltip: {
                        headerFormat: '<b>{point.x}</b><br/>',
                        pointFormatter: function () {
                            const point = this;
                            const tooltipFormatter = `${point.series.name}: ${point.y.toFixed(
                                self.dailySummaryChartData['precisions'][point.x],
                            )}`;
                            return tooltipFormatter;
                        },
                    },
                    plotOptions: {},
                    series: series,
                });
        }
    }

    /**
     * Navigate to the location details page for uptime report
     * @param event
     * @param id    - location ID
     */

    public navigateToReport(reportName: string) {
        this.router.navigate(['/pages/report/' + reportName + '/' + this.locationId + '/details'], {
            queryParams: {
                ln: this.reportLocationName,
            },
            queryParamsHandling: 'merge',
        });
    }
}
