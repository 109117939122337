import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../../shared/services/config';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { DailyRainfallReportModel, DailyRainfallSettings } from './daily-rainfall-report.models'
import { Observable } from 'rxjs';


@Injectable()
export class DailyRainfallReportService {
    constructor(private http: HttpClient, private dateutilService: DateutilService) {}


    public getReportData(cid: number, lgId: number, locationIds: number[], start: Date, end: Date, includeInactiveLocations: boolean, entityIds: number[]) {
        const startStr = this.dateutilService.formatDateToUTC(start);
        const endStr = this.dateutilService.formatDateToUTC(end);

        const url = Config.urls.rainfallTabular;
        
        return this.http.post<DailyRainfallReportModel>(url, { 
                CustomerId: cid, 
                LocationGroupId: lgId, 
                LocationIds: locationIds, 
                Start: startStr, 
                End: endStr, 
                ExportReport: false,
                includeInactiveLocations,
                entityIds
            });
    }

    public saveSettings(settings: DailyRainfallSettings) {
        return this.http.post(Config.urls.rainfallReportUserSettings, settings);
    }

    public loadSettings(): Observable<DailyRainfallSettings> {
        return this.http.get<DailyRainfallSettings>(Config.urls.rainfallReportUserSettings);
    }

}