import { Injectable } from '@angular/core';
import { SignalRService, DIAGNOSTICS_COMPLETE } from 'app/shared/services/signalr.service';
import { SignalRMessageType } from 'app/shared/models/signalr-message';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DiagnosticService {
    public diagnosesRunning = new BehaviorSubject<Object>({});

    constructor(private signalRService: SignalRService) {
        this.signalRService.messageReceived.subscribe(async (res) => {
            if (res && res.cid && res.type === SignalRMessageType.Info && res.message) {
                if (res.message.startsWith(DIAGNOSTICS_COMPLETE)) {
                    this.diagnosesRunning['id' + res.lid + res.cid] = false;
                    this.diagnosesRunning.next(this.diagnosesRunning);
                }
            }
        });
    }
}
