import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import {
    StormDefinition,
    DryDayDefinition,
    WasteWaterBaseInfiltration,
} from 'app/shared/models/sliicer/customer-rainfall-profile';
import { SliicerCaseStudy, WeekGroup, PrecompensationType, Settings } from 'app/shared/models/sliicer';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { DryDaySettingsComponent } from './dry-day-settings/dry-day-settings.component';
import { SeasonsSettingsComponent } from './seasons-settings/seasons-settings.component';
import { StormSettingsComponent } from './storm-settings/storm-settings.component';
import { WasteWaterSettingsComponent } from './waste-water-settings/waste-water-settings.component';
import { WeekGroupsSettingsComponent } from './week-groups-settings/week-groups-settings.component';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { PrecompensationSettingsComponent } from './precompensation-settings/precompensation-settings.component';
import { ChangesAction, UpdateInfo, UpdatesAction } from '../../shared/components/updates-widget/updates-widget.models';
import { RegimeSettingsComponent } from './regime-settings/regime-settings.component';
import { Regime, SeasonType } from 'app/shared/models/sliicer/settings';
import { UpdatesWidgetService } from '../../shared/components/updates-widget/updates-widget.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { Season } from './seasons-settings/seasons-settings.utils';
import { SettingsChanges } from './study-settings.models';
import { finalize } from 'rxjs/operators';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DD';
const UPDATES_MODEL = 'studySettings';

@Component({
    selector: 'app-study-settings',
    templateUrl: './study-settings.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})
export class StudySettingsComponent implements OnInit, OnDestroy {
    @ViewChild('tabs') public tabs: MatTabGroup;
    @ViewChild(DryDaySettingsComponent) public dryDaysSettings: DryDaySettingsComponent;
    @ViewChild(StormSettingsComponent) public stormSettings: StormSettingsComponent;
    @ViewChild(WasteWaterSettingsComponent) public wasteWaterSettings: WasteWaterSettingsComponent;
    @ViewChild(WeekGroupsSettingsComponent) public weekGroupsSettings: WeekGroupsSettingsComponent;
    @ViewChild(SeasonsSettingsComponent) public seasonsSettings: SeasonsSettingsComponent;
    @ViewChild(PrecompensationSettingsComponent) public precompositionSettings: PrecompensationSettingsComponent;
    @ViewChild(RegimeSettingsComponent) public regimesSettings: RegimeSettingsComponent;

    // Variables used by HTML
    public customerId: number;
    public isStudyLocked: boolean;
    public isTabLocked: boolean;
    public originalPrecompType: PrecompensationType;
    public deleteButton = false;
    public caseStudyDetails: SliicerCaseStudy;

    public tabsError = {
        dayOfWeekGroups: false,
        regimeSettings: false,
        stormSettings: false,
        seasonsSettings: false,
    }

    // Private variables
    private subscriptions: Array<Subscription> = [];

    // Public variables
    public caseStudyId: string;
    public originalDryDayDefinition: DryDayDefinition;
    public originalStormDefinition: StormDefinition;
    public originalWastewaterBaseInfiltration: WasteWaterBaseInfiltration;
    public originalDayGroups: WeekGroup[];
    // Any for now
    public originalSeasonsDefinition: any;
    public shouldUpdate = false;
    public changesAction: ChangesAction = { action: null };

    // Translated strings
    public updatedText: string;
    public updateFailureText: string;

    constructor(
        private sliicerService: SliicerService,
        private translate: TranslateService,
        private utilService: UiUtilsService,
        public cdr: ChangeDetectorRef,
        public updatesWidgetService: UpdatesWidgetService,
    ) {}

    private initializeTranslations(): void {
        const translateKeys = ['SLIICER.EDIT_SETTINGS.SAVE_SUCCESS', 'SLIICER.EDIT_SETTINGS.SAVE_FAIL'];
        this.translate.get(translateKeys).subscribe((values: Array<string>) => {
            this.updatedText = values['SLIICER.EDIT_SETTINGS.SAVE_SUCCESS'];
            this.updateFailureText = values['SLIICER.EDIT_SETTINGS.SAVE_FAIL'];
        });
    }

    public undoChanges() {
        this.changesAction = { action: UpdatesAction.undoChanges };
        this.utilService.safeChangeDetection(this.cdr);
    }

    public saveChanges(): void {
        const settingsChanges: SettingsChanges = {
            dryDays: this.dryDaysSettings.getChangeValue(),
            wasteWater: this.wasteWaterSettings.getChangeValue(),
            weekGroups: this.weekGroupsSettings.getChangeValue(),
            stormSettings: this.stormSettings.getChangeValue(),
            precompensation: this.precompositionSettings.getChangeValue(),
            seasons: this.seasonsSettings.getChangeValue(),
            regimes: this.regimesSettings.getChangeValue(),
        };
        this.updateStudySettings(settingsChanges);
    }

    public ngOnInit() {
        this.initializeTranslations();

        this.subscriptions.push(
            this.updatesWidgetService.updatesAction.subscribe((action) => {
                if (this.updatesWidgetService.updatesModel === UPDATES_MODEL) {
                    switch (action) {
                        default:
                            break;
                        case 'undoChanges':
                            this.undoChanges();
                            break;
                        case 'applyChanges':
                            this.saveChanges();
                            break;
                    }
                }
            }),
        );

        this.subscriptions.push(
            this.sliicerService.caseStudyEditable.subscribe((editable: boolean) => {
                this.isStudyLocked = !editable;
            }),
        );

        this.subscriptions.push(
            this.sliicerService.studyDetailsData$.subscribe((caseStudyDetails: SliicerCaseStudy) => {
                this.customerId = caseStudyDetails.customerId;
                this.caseStudyId = caseStudyDetails.id;
                this.originalDryDayDefinition = caseStudyDetails.settings.dryDayDefinition;
                this.originalStormDefinition = caseStudyDetails.settings.stormEventDefinition;
                this.originalWastewaterBaseInfiltration = caseStudyDetails.settings.wastewaterBaseInfiltration;
                this.originalDayGroups = caseStudyDetails.settings.dayGroups;

                // Updates the children components
                this.shouldUpdate = true;

                this.originalPrecompType = caseStudyDetails.settings.defaultPrecompType;

                this.caseStudyDetails = caseStudyDetails;
            }),
        );
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public updateCaseStudyDryDayDefinition(dryDayDefinition: DryDayDefinition) {
        this.dryDaysSettings.initialFormValues = JSON.parse(JSON.stringify(this.dryDaysSettings.formValues));
        if (this.caseStudyDetails) {
            this.caseStudyDetails.settings.dryDayDefinition = dryDayDefinition;
            this.sliicerService.caseStudyDetails.next(this.caseStudyDetails);
        }
        this.dryDaysSettings.dryDefinitionForm.form.markAsUntouched();
        this.dryDaysSettings.dryDefinitionForm.form.markAsPristine();
    }

    public updateCaseStudyStormDefinition(stormDefinition: StormDefinition) {
        this.stormSettings.initialFormValues = JSON.parse(JSON.stringify(this.stormSettings.formValues));
        if (this.caseStudyDetails) {
            this.caseStudyDetails.settings.stormEventDefinition = stormDefinition;
            this.sliicerService.caseStudyDetails.next(this.caseStudyDetails);
        }
        // FIXME: SPEIR - WHY?
        this.showTimeoutNotification();
        this.stormSettings.stormDefnitionForm.form.markAsUntouched();
        this.stormSettings.stormDefnitionForm.form.markAsPristine();
    }

    public updateCaseStudyWasteWaterDefinition(wasteWater: WasteWaterBaseInfiltration) {
        this.wasteWaterSettings.originalWastewaterBaseInfiltration = wasteWater;
        if (this.caseStudyDetails) {
            this.caseStudyDetails.settings.wastewaterBaseInfiltration = wasteWater;
            this.sliicerService.caseStudyDetails.next(this.caseStudyDetails);
        }
    }

    public updateCaseStudyPrecompensationDefinition(preCompValue: PrecompensationType) {
        this.precompositionSettings.initialFormValues = JSON.parse(
            JSON.stringify(this.precompositionSettings.formValues),
        );
        if (this.caseStudyDetails) {
            this.caseStudyDetails.settings.defaultPrecompType = preCompValue;
            this.sliicerService.caseStudyDetails.next(this.caseStudyDetails);
        }
    }

    public updateCaseStudyWeekGroupDefinition(weekGroups: WeekGroup[]) {
        this.weekGroupsSettings.initialFormValues = JSON.parse(JSON.stringify(this.weekGroupsSettings.formValues));
        this.caseStudyDetails.settings.dayGroups = weekGroups;
        this.sliicerService.caseStudyDetails.next(this.caseStudyDetails);
    }

    public updateCaseStudySeasonsDefinition(settings: Settings) {
        this.seasonsSettings.initialFormValues = JSON.parse(JSON.stringify(this.seasonsSettings.formValues));
        if (this.caseStudyDetails) {
            this.caseStudyDetails.settings.seasonDefinitions = settings.seasonDefinitions;
            this.caseStudyDetails.settings.seasonType = settings.seasonType;
            this.sliicerService.caseStudyDetails.next(this.caseStudyDetails);
        }
    }

    public updateCaseStudyRegimesDefinition(regimes: Regime[]) {
        if (this.caseStudyDetails) {
            this.caseStudyDetails.settings.regimes = regimes;
            this.sliicerService.caseStudyDetails.next(this.caseStudyDetails);

            this.isTabLocked = false;

            if(regimes && regimes.length > 0)
            {
                if(regimes.find(a=> moment(a.periodEnd).format(dateFormat) == moment(this.caseStudyDetails.config.endDate).format(dateFormat)))
                {
                    this.isTabLocked = false;
                }
                else
                {
                    this.isTabLocked = true;
                }
            }
        }
    }

    public showTimeoutNotification(showFailMessage = false) {
        const message = showFailMessage ? this.updateFailureText : this.updatedText;
        this.sliicerService.showToast(message, showFailMessage);
    }

    /**
     * This will check all changes and structure udpates info data if any change is detected
     */
    public setUpdatesInfo() {
        this.tabsError.dayOfWeekGroups = this.weekGroupsSettings && this.weekGroupsSettings.error;
        this.tabsError.regimeSettings = this.regimesSettings && this.regimesSettings.error;
        this.tabsError.stormSettings = this.stormSettings && this.stormSettings.error;
        this.tabsError.seasonsSettings = this.seasonsSettings && this.seasonsSettings.error;

        const updatesInfo: UpdateInfo[] = [];
        if (this.dryDaysSettings && this.dryDaysSettings.getChangeValue()) {
            StudySettingsComponent.AddUpdateInfo(updatesInfo, this.getDryDaysUpdateInfo(this.dryDaysSettings));
        }
        if (this.wasteWaterSettings && this.wasteWaterSettings.getChangeValue()) {
            StudySettingsComponent.AddUpdateInfo(updatesInfo, this.getWasteWatterUpdateInfo(this.wasteWaterSettings));
        }
        if (this.weekGroupsSettings && this.weekGroupsSettings.getChangeValue()) {
            StudySettingsComponent.AddUpdateInfo(updatesInfo, this.getWeekGroupsUpdateInfo(this.wasteWaterSettings));
        }
        if (this.stormSettings && this.stormSettings.getChangeValue()) {
            StudySettingsComponent.AddUpdateInfo(updatesInfo, this.getStormsUpdateInfo(this.stormSettings));
        }
        if (this.precompositionSettings && this.precompositionSettings.getChangeValue()) {
            StudySettingsComponent.AddUpdateInfo(
                updatesInfo,
                this.getPrecompositionUpdateInfo(this.precompositionSettings),
            );
        }
        if (this.seasonsSettings && this.seasonsSettings.getChangeValue()) {
            const originalSeasonType = this.caseStudyDetails.settings && this.caseStudyDetails.settings.seasonType;
            const originalSeasons = (this.caseStudyDetails.settings && this.caseStudyDetails.settings.seasons) || [];
            StudySettingsComponent.AddUpdateInfo(
                updatesInfo,
                this.getSeasonsUpdateInfo(this.seasonsSettings, originalSeasonType, originalSeasons),
            );
        }
        if (this.regimesSettings && this.regimesSettings.getChangeValue()) {
            const originalRegimes = (this.caseStudyDetails.settings && this.caseStudyDetails.settings.regimes) || [];
            StudySettingsComponent.AddUpdateInfo(
                updatesInfo,
                this.getRegimesUpdateInfo(this.regimesSettings, originalRegimes),
            );
        }

        let hasError = false;

        if (this.weekGroupsSettings && this.weekGroupsSettings.error) {
            hasError = true;
        }
        if (this.dryDaysSettings && this.dryDaysSettings.formInvalid) {
            hasError = true;
        }
        if (this.seasonsSettings && this.stormSettings.error) {
            hasError = true;
        }
        if (this.regimesSettings && this.regimesSettings.error) {
            hasError = true;
        }
        if (this.stormSettings && this.stormSettings.error) {
            hasError = true;
        }
        if (this.seasonsSettings && this.seasonsSettings.error) {
            hasError = true;
        }
        this.updatesWidgetService.errors.settings = hasError;

        this.updatesWidgetService.setUpdatesInfo(updatesInfo, UPDATES_MODEL);
    }

    /********************************************************************************/
    /* PRIVATE                                                                      */
    /********************************************************************************/

    /**
     * This will structure and settings data and send request to update the settings data
     * @param settingsChanges
     */
    private updateStudySettings(settingsChanges: SettingsChanges): void {
        const isBasinsCall = this.checkIfBasinsCall(settingsChanges);
        const settings: Settings = this.getChangedSettings(settingsChanges);
        this.updatesWidgetService.updatesLoader = true;

        let updatesCall: Observable<any>;

        if (isBasinsCall) {
            updatesCall = this.sliicerService.putBasinSettings(this.customerId, this.caseStudyId, settings);
        } else {
            updatesCall = this.sliicerService.putStudySettings(this.customerId, this.caseStudyId, settings);
        }
        updatesCall.pipe(
                finalize(() => {
                    this.updatesWidgetService.updatesLoader = false;
                    this.setUpdatesInfo();
                }),
            )
            .subscribe(() => {
                if (settingsChanges.dryDays) {
                    this.updateCaseStudyDryDayDefinition(settings.dryDayDefinition);
                }
                if (settingsChanges.wasteWater) {
                    this.updateCaseStudyWasteWaterDefinition(settings.wastewaterBaseInfiltration);
                }
                if (settingsChanges.weekGroups) {
                    this.updateCaseStudyWeekGroupDefinition(settings.dayGroups);
                }
                if (settingsChanges.stormSettings) {
                    this.updateCaseStudyStormDefinition(settings.stormEventDefinition);
                }
                if (settingsChanges.precompensation) {
                    this.updateCaseStudyPrecompensationDefinition(settings.defaultPrecompType);
                }
                if (settingsChanges.seasons) {
                    this.updateCaseStudySeasonsDefinition(settings);
                }
                if (settingsChanges.regimes) {
                    this.updateCaseStudyRegimesDefinition(settings.regimes);
                }
                this.showTimeoutNotification();
            }),
            (err) => {
                this.showTimeoutNotification(true);
            };
    }

    /**
     * This will structure and settings data and send request to update the settings data
     * @param settingsChanges
     */
    private getChangedSettings(settingsChanges: SettingsChanges): Settings {
        const settings: Settings = {};
        if (settingsChanges.dryDays) {
            const dryDayDefinition = this.dryDaysSettings.getChanges();
            if (dryDayDefinition !== null) {
                settings.dryDayDefinition = dryDayDefinition;
            } else {
                settingsChanges.dryDays = false;
            }
        }
        if (settingsChanges.wasteWater) {
            const wasteWaterInfiltration = this.wasteWaterSettings.getChanges();
            if (wasteWaterInfiltration !== null) {
                settings.wastewaterBaseInfiltration = wasteWaterInfiltration;
            } else {
                settingsChanges.wasteWater = false;
            }
        }
        if (settingsChanges.weekGroups) {
            const weekGroups = this.weekGroupsSettings.getChanges();
            if (weekGroups !== null) {
                settings.dayGroups = weekGroups;
            } else {
                settingsChanges.weekGroups = false;
            }
        }
        if (settingsChanges.stormSettings) {
            const stormSettings = this.stormSettings.getChanges();
            if (stormSettings !== null) {
                settings.stormEventDefinition = stormSettings;
            } else {
                settingsChanges.stormSettings = false;
            }
        }
        if (settingsChanges.precompensation) {
            const precompType = this.precompositionSettings.getChanges();
            if (precompType !== null) {
                settings.defaultPrecompType = precompType;
            } else {
                settingsChanges.precompensation = false;
            }
        }
        if (settingsChanges.seasons) {
            const seasonSettings = this.seasonsSettings.getChanges();
            if (seasonSettings !== null) {
                settings.seasonType = seasonSettings.seasonType;
                if (settings.seasonType === SeasonType.Custom) {
                    settings.seasonDefinitions = seasonSettings.seasonDefinitions;
                } else if (settings.seasonType === SeasonType.None || settings.seasonType === null) {
                    settings.seasonType = SeasonType.None;
                    settings.seasonDefinitions = null;
                }
            } else {
                settingsChanges.seasons = false;
            }
        }
        if (settingsChanges.regimes) {
            const regimes = this.regimesSettings.getChanges();
            if (regimes !== null) {
                settings.regimes = regimes;
            } else {
                settingsChanges.regimes = false;
            }
        }
        return settings;
    }

    private checkIfBasinsCall(settingsChanges: SettingsChanges) {
        return Object.keys(settingsChanges).filter(v => settingsChanges[v]).every(v => v === 'regimes' || v === 'seasons');
    }

    /**
     * This will structure and return the dry days update info
     * @param dryDaysSettings
     */
    private getDryDaysUpdateInfo(dryDaysSettings: DryDaySettingsComponent): UpdateInfo {
        return { title: 'DRY_DAYS_PARAMETERS', values: [] };
    }

    /**
     * This will structure and return the waste water update info
     * @param wasteWaterSettings
     */
    private getWasteWatterUpdateInfo(wasteWaterSettings: WasteWaterSettingsComponent): UpdateInfo {
        return { title: 'BASE_INFILTRATION', values: [] };
    }

    /**
     * This will structure and return the week groups update info
     * @param weekGroupsSettings
     */
    private getWeekGroupsUpdateInfo(weekGroupsSettings: WasteWaterSettingsComponent): UpdateInfo {
        return { title: 'WEEK_GROUPS_DAYS', values: [] };
    }

    /**
     * This will structure and return the week groups update info
     * @param stormSettings
     */
    private getStormsUpdateInfo(stormSettings: StormSettingsComponent): UpdateInfo {
        return { title: 'STORM_EVENTS', values: [] };
    }

    /**
     * This will structure and return the week groups update info
     * @param precompositionSettings
     */
    private getPrecompositionUpdateInfo(precompositionSettings: PrecompensationSettingsComponent): UpdateInfo {
        return { title: 'PRECOMPOSITION_METHOD', values: [] };
    }

    /**
     * This will structure and return the seasons update info
     * @param seasonsSettings
     * @param originalSeasonType
     * @param originalSeasons
     */
    private getSeasonsUpdateInfo(
        seasonsSettings: SeasonsSettingsComponent,
        originalSeasonType: SeasonType,
        originalSeasons: Season[],
    ): UpdateInfo {
        return { title: 'SEASONS', values: [] };
    }
    /**
     * This will structure and return the seasons update info
     * @param regimesSettings
     * @param originalRegimes
     */
    private getRegimesUpdateInfo(regimesSettings: RegimeSettingsComponent, originalRegimes: Regime[]): UpdateInfo {
        return { title: 'REGIMES', values: [] };
    }

    /********************************************************************************/
    /* PRIVATE STATIC METHODS                                                       */
    /********************************************************************************/

    /**
     * This will handle the update to the updatesInfo array
     * since the updateInfo can be Object or Array depending on what is updated
     * @param updatesInfo
     * @param updateInfo
     * @constructor
     */
    private static AddUpdateInfo(updatesInfo: UpdateInfo[], updateInfo: UpdateInfo | UpdateInfo[]) {
        if (updateInfo instanceof Array) {
            for (let i = 0; i < updateInfo.length; i++) {
                updatesInfo.push(updateInfo[i]);
            }
        } else {
            updatesInfo.push(updateInfo);
        }
    }
}
