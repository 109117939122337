<mat-expansion-panel [ngClass]="{ 'data-delivery-section-error': form.invalid }">
    <mat-expansion-panel-header class="mat-expasion-header-background">
        <mat-panel-title class="input-text" *ngIf="!isMp2">
            {{ 'ADD_EDIT.FLOW_FORM' | translate }}
        </mat-panel-title>
        <mat-panel-title class="input-text" *ngIf="isMp2">
            {{ 'ADD_EDIT.FLOW_FORM_2' | translate }}
        </mat-panel-title>
        <mat-panel-description *ngIf="form.invalid && (form | customErrorCount)">
            <mat-icon>warning</mat-icon>
            <span>
                {{ form | customErrorCount }}
                {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.TRITON_REQUIRED_ERRORS_TXT' | translate }}
            </span>
        </mat-panel-description>
        <mat-panel-description *ngIf="form.invalid && !(form | customErrorCount)">
            <mat-icon>warning</mat-icon> <span> </span>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <form [formGroup]="form">
        <div>
            <div class="each-Item">
                <mat-form-field class="full-width">
                    <mat-select placeholder="{{ 'ADD_EDIT.PARAMETERS' | translate }}" formControlName="flowType">
                        <mat-option *ngFor="let f of flowEntities" [value]="f.id">{{ f.name }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-expansion-panel>
