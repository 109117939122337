import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'replace',
})
export class ReplacePipe implements PipeTransform {
    public transform(value: string, re: string, val: string | number): string {
        return value.replace(re, String(val));
    }
}
