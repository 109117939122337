export class CollectionHistory {
    public locationid: number;
    public locationname: string;
    public lastcollecteddate: Date;
    public failedcollects30days: number;
    public collectinprogress: boolean;
    public startDate?: any;
    public endDate?: any;
    public selectedLocation?: Array<number>;
}

export interface GetFailedCollectsArgs {
    customerId: number;
    locationGroupId?: number;
}

export interface GetCollectHistoryArgs {
    customerID: number;
    LocationGroupID?: number;
    LocationIDs?: Array<number>;
    StartTime?: Date;
    EndTime?: Date;
    Status?: number;
    PageSize: number;
    StartPage: number;
    IncludeInactiveLocations?: boolean;
    monitorIds?: Array<number>;
    includeTcpConnections?: boolean;
}
export interface DataCommunicationDetails {
    dataCommunicationDetails: Array<CollectionDetails>;
    count: number;
}
export class CollectionDetails {
    public locationId: number;
    public locationName: string;
    public lastCollectedDate: Date;
    public startDate?: any;
    public endDate?: any;
    public monitorSeries?: string;
    public totalAttempts?: number;
    public lastManualCollection?: Date;
    public successPercent?: string;
    public lastScheduledCollection?: Date;
    public nextScheduledCollection?: Date;
    public failureAttempts: number;
    public lastFailedCollection: Date;
    public collectInProgress: boolean;
}
export interface GetCollectDetailsArgs {
    customerID: number;
    LocationGroupID?: number;
    LocationIDs?: Array<number>;
    monitorIds?: Array<number>;
    StartTime?: Date;
    EndTime?: Date;
    Status?: number;
    PageSize: number;
    Sort?: string;
    StartPage: number;
    SearchValue?: string;
    IncludeInactiveLocations?: boolean;
}
export interface AvailableLocations {
    id: number;
    name: string;
}
