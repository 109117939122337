<app-loader
    [isPositionAbsolute]="true"
    [isLoading]="isLoading"
></app-loader>
<div class="edit-configuration pad-l-25 pad-r-25">
    <div class="custom-stepper">
        <div class="stepper-action border-t">
            <div class="auto-margin">
                <span class="border pad-l-10">{{ 'SLIICER_TABLE.SLIICER_AUTHOR' | translate }}: {{ author }}</span>
                <span class="border pad-l-5">{{ 'SLIICER.CONFIG_TAB.DATE_CREATED' | translate }}: {{ createDate | date: dateFormat }}</span>
                <span class="pad-l-5">{{ 'SLIICER.CONFIG_TAB.DATE_MODIFIED' | translate }}: {{ modifiedDate | date: dateFormat }}</span>
            </div>

            <div *ngIf="!(shouldRefreshStudy$ | async)" class="app-flex-filler"></div>
            <div *ngIf="(shouldRefreshStudy$ | async)" class="recalc-warning">
                <div class="mat-content-text sliicer-error-text monitor-warning">
                    <mat-icon class="sliicer-check-icone">error</mat-icon>
                    {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_RECALC_WARNING' | translate }}
                </div>
                <div class="custom-flat-button">
                    <button
                        class="mat-button"
                        (click)="recalculateStudy.emit()"
                    >
                        {{ 'SLIICER_TABLE.STUDY_CONFIGURATION_TABLE.STUDY_DESCRIPTION_RUN_CALCULATIONS' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <table class="sliicer-configuration-table summary2_table">
        <tr>
            <td class="table-width padding-l-r">
                <div class="custom-error">
                    <table><tr><td class="pad0">
                        <mat-form-field class="width-285-px">
                            <input
                                matInput
                                placeholder="{{ 'SLIICER_TABLE.SLIICER_STUDY_NAME' | translate }}"
                                [disabled]="isStudyLocked"
                                maxlength="64"
                                [(ngModel)]="caseStudyData.name"
                                (ngModelChange)="studyNameChange($event)"
                            />
                        </mat-form-field>
                    </td><td>
                        <app-loader [isLoading]="studyCheckNameLoaded === false" [isPositionAbsolute]="false"></app-loader>
                    </td></tr></table>
                    <div class="custom-error-message" *ngIf="existingStudyNameError">
                        {{ 'SLICER_PAGE.STUDY_NAME_EXISTS_ERROR' | translate }}
                    </div>
                </div>
            </td>
            <td colspan="4">
                <mat-form-field class="width-285-px">
                    <input
                        matInput
                        placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_DESCRIPTION' | translate }}"
                        [disabled]="isStudyLocked"
                        maxlength="64"
                        [(ngModel)]="caseStudyData.desc"
                        (ngModelChange)="onInstantChanges()"
                    />
                </mat-form-field>
            </td>
        </tr>
        <tr>
            <td
                class="table-width padding-l-r"
                style="vertical-align: top"
            >
                <div class="custom-50p">
                    <div class="custom-error mat-content-text study-start-content pad-t-0">
                        <mat-form-field class="width-285-px">
                            <!--
                                #33547 temporary disable ability to change location group: original disabled condition here:
                                [disabled]="locationGroups.length < 1 || isImportedStudy || isStudyLocked"
                            -->
                            <mat-select
                                #locationGroupSelect="ngModel"
                                [(ngModel)]="caseStudyData.selectedLocationGroupId"
                                (ngModelChange)="onRemoteChanges()"
                                name="selectedLocationGroupId"
                                [disabled]="true"
                                placeholder=" {{
                                    'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_LOCATION_PLACEHOLDER' | translate
                                }}"
                                (selectionChange)="onLocationGroupSelected($event)"
                            >
                                <mat-option
                                    *ngFor="let locationGroup of locationGroups"
                                    [value]="locationGroup.id"
                                >{{
                                    locationGroup.text
                                    }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="custom-error-message">
                            <span *ngIf="locationGroupsUnavilable">{{
                                'SLIICER_TABLE.SLICER_SUMMARY.LOCATIONS_GROUPS-UNAVILABLE' | translate
                                }}</span>
                        </div>
                    </div>
                </div>
            </td>
            <td colspan="4">
                <div class="case-study-fields margin-b-30">
                    <span class="study-button-background">
                        <app-loader
                            style="float: left"
                            [isPositionAbsolute]="false"
                            [isLoading]="loadingLocations"
                        ></app-loader>
                        <span class="border-right">
                            <span class="online-offline-dates">{{
                                studyLocationGroupSummary?.flowMonitorNames?.length
                                }}</span>
                            <ng-container *ngIf="studyLocationGroupSummary?.flowMonitorNames?.length != 1">
                                {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_FLOW_MONITORS' | translate }}
                            </ng-container>
                            <ng-container *ngIf="studyLocationGroupSummary?.flowMonitorNames?.length == 1">
                                {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_FLOW_MONITOR' | translate }}
                            </ng-container>
                        </span>
                        <span class="padding-le border-right">
                            <span class="online-offline-dates">{{
                                studyLocationGroupSummary?.rainfallMonitorNames?.length
                                }}</span>
                            <ng-container *ngIf="studyLocationGroupSummary?.rainfallMonitorNames?.length != 1">
                                {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_RAINFALL_MONITORS' | translate }}
                            </ng-container>
                            <ng-container *ngIf="studyLocationGroupSummary?.rainfallMonitorNames?.length == 1">
                                {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_RAINFALL_MONITOR' | translate }}
                            </ng-container>
                        </span>
                        <span class="online-offline-dates padding-le">
                            <a
                                class="normal-link"
                                (click)="showMonitorDetails()"
                            >{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_DETAILS' | translate }}
                            </a>
                        </span>
                    </span>

                    <span class="each-Item padding-l includeLocations">
                        <span>Include Inactive Locations</span>
                        <mat-slide-toggle
                            class="toggle padding-l"
                            [disabled]="isStudyLocked"
                            [(ngModel)]="caseStudyData.includeInactiveLocations"
                            (ngModelChange)="switchInactive()"
                        >
                        </mat-slide-toggle>
                    </span>
                    <div
                        class="mat-content-text sliicer-error-text monitor-warning"
                        *ngIf="invalidMonitors"
                    >
                        <mat-icon class="sliicer-check-icone">error</mat-icon>
                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_TABLE2_TEXT_WARNING' | translate }}
                    </div>
                    <div
                        class="sliicer-error-text  monitor-warning"
                        *ngIf="noDataFlowMonitorError"
                    >
                        <mat-icon class="sliicer-check-icone">error</mat-icon>
                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_TABLE2_NO_DATA_FLOW_MONITOR_ERROR' | translate }}
                    </div>

                    <div class="sliicer-error-text  monitor-warning" *ngIf="noDataRainMonitorError">
                        <mat-icon class="sliicer-check-icone">error</mat-icon>
                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_TABLE2_NO_DATA_RAIN_MONITOR_ERROR' | translate }}
                    </div>
                    <div
                        class="mat-content-text sliicer-error-text monitor-warning"
                        *ngIf="studyLocationGroupSummary?.levelMonitorNames?.length > 0"
                    >
                        <mat-icon class="sliicer-check-icone">error</mat-icon>
                        {{ studyLocationGroupSummary?.levelMonitorNames?.length }}
                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_LEVEL_MONITORS_EXCLUDING_TXT' | translate }}
                    </div>
                    <div
                        class="sliicer-warning-text  monitor-warning"
                        *ngIf="areLocationsChangeInsideGroup"
                    >
                        <mat-icon class="sliicer-check-icone">warning</mat-icon>
                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_TABLE2_LOCATION_MONITORS_DO_NOT_MATCH' | translate }}
                        <a
                            class="normal-link"
                            (click)="showMonitorCompareDetails()"
                        >{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_DETAILS' | translate }}
                        </a>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td class="table-width padding-l-r">
                <mat-form-field class="width-128-px margin-r-15">
                    <input
                        matInput
                        [value]="caseStudyData.startDate | date: dateFormat"
                        [max]="latestAllowedDate"
                        [min]="earliestAllowedDate"
                        [disabled]="isStudyLocked"
                        placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_TABLE2_STUDY_START' | translate }}"
                        (change)="onStartDateChanged($event)"
                        [disabled]="isImportedStudy"
                        required
                    />
                    <input
                        matInput
                        hidden
                        [(ngModel)]="caseStudyData.startDate"
                        #startdte="ngModel"
                        [matDatepicker]="caseStudyStartDate"
                        [max]="latestAllowedDate"
                        [min]="earliestAllowedDate"
                        [disabled]="isStudyLocked"
                        placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_TABLE2_STUDY_START' | translate }}"
                        (dateInput)="onStartDateChanged($event)"
                        (dateChange)="onStartDateChanged($event)"
                        name="startDate"
                        [disabled]="isImportedStudy"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="caseStudyStartDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #caseStudyStartDate></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="width-128-px margin-l-15">
                    <input
                        matInput
                        [value]="caseStudyData.endDate | date: dateFormat"
                        [max]="latestAllowedDate"
                        [min]="earliestAllowedDate"
                        [disabled]="isStudyLocked"
                        placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_TABLE2_STUDY_END' | translate }}"
                        (change)="onEndDateChanged($event)"
                        [disabled]="isImportedStudy"
                        required
                    />
                    <input
                        matInput
                        hidden
                        [(ngModel)]="caseStudyData.endDate"
                        #enddte="ngModel"
                        [min]="earliestAllowedDate"
                        [disabled]="isStudyLocked"
                        [matDatepicker]="caseStudyEndDate"
                        [max]="latestAllowedDate"
                        placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_TABLE2_STUDY_END' | translate }}"
                        (dateInput)="onEndDateChanged($event)"
                        (dateChange)="onEndDateChanged($event)"
                        name="endDate"
                        [disabled]="isImportedStudy"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="caseStudyEndDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #caseStudyEndDate></mat-datepicker>
                </mat-form-field>
                <div class="custom-error">
                    <div class="custom-error-message top-15">
                        <span *ngIf="invalidDates">{{ 'COMMON.START_END_DATE_DIFF_ERROR' | translate }}</span>
                        <span *ngIf="isDatesOutside">{{ 'COMMON.SLIICER_DATE_OUTSIDE_ERROR' | translate : dateOutsideErrorParams }}</span>
                        <span *ngIf="invalidStartDate">{{
                            'SLIICER_TABLE.SLICER_SUMMARY.SLICER_INVALID_START_DATE'
                            | translate
                            | replace: '%':dateFormatUppercase
                            }}</span>
                        <span *ngIf="invalidEndDate">{{
                            'SLIICER_TABLE.SLICER_SUMMARY.SLICER_INVALID_END_DATE'
                            | translate
                            | replace: '%':dateFormatUppercase
                            }}</span>
                    </div>
                </div>
            </td>
            <td colspan="4">
                <div
                    class="case-study-fields margin-b-15"
                    *ngIf="!invalidStartDate && !invalidEndDate"
                >
                    <span class="study-button-background label-font-weight">
                        {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_DURATION' | translate }}:
                        <span class="online-offline-dates">
                            {{ daysInStudy }} {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_DAYS' | translate }}
                        </span>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td
                colspan="5"
                class="pad-l-r"
            >
                <div class="custom-error">
                    <mat-form-field class="width-285-px">
                        <mat-select
                            #caseStudyStepLength="ngModel"
                            [(ngModel)]="caseStudyData.stepLength"
                            [disabled]="isStudyLocked"
                            (ngModelChange)="onInstantChanges()"
                            name="studyStepLength"
                            placeholder=" {{
                                'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_STEP_PLACEHOLDER' | translate
                            }}"
                        >
                            <mat-option
                                *ngFor="let studyLengthOption of studyStepLengthOptions"
                                [value]="studyLengthOption.id"
                            >{{ studyLengthOption.text }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </td>
        </tr>
        <tr>
            <td
                colspan="5"
                class="pad-l-r refresh-study-data-container"
            >
                <div
                    *ngIf="isImportedStudy"
                    class="custom-flat-button top-border"
                >
                    <button
                        class="mat-button"
                        (click)="reloadImportedTelemetryData()"
                    >
                        {{ 'SLIICER_TABLE.TELEMETRY.IMPORTED_STUDY_TELEMETRY_TEXT' | translate }}
                    </button>
                </div>
                <div
                    *ngIf="!isImportedStudy"
                    class="custom-flat-button top-border"
                >
                    <button
                        class="mat-button"
                        (click)="refreshTelemetryData()"
                        [disabled]="isStudyLocked || updatesWidgetService.updates"
                    >
                        {{ 'SLIICER_TABLE.TELEMETRY.MANUAL_STUDY_TELEMETRY_TEXT' | translate }}
                    </button>
                </div>
            </td>
        </tr>
    </table>
    <!-- <div class="button-row top-border custom-flat-button">
        <div class="right-align">
            <button class="mat-button black" (click)="cancel()">{{ 'COMMON.CANCEL_TEXT' | translate }}</button>
            <button
                class="mat-button"
                (click)="updateStudy()"
                [disabled]="!(studyName.length && locationGroupSelect.valid && !invalidMonitors && !invalidDates)"
            >
                {{ 'SLIICER_TABLE.UPDATE_CONFIGURATION' | translate }}
            </button>
        </div>
    </div> -->
</div>
