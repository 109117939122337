<mat-card-content id="autoReviewWidgetId" class="hideScroll"
    [ngClass]="{ widgetFixPagination: totalPaginationLength > 10, 'mt-margin-on-hint': availablePageHint && !showAutoReviwSearch, 'mt-margin-on-hint-filters-on': availablePageHint && showAutoReviwSearch}"
    >
    <div class="scrollOverlay"></div>
    <div [ngClass]="{'filters-on-hint': availablePageHint}">
        <app-widget-filters
            (sendDataToWidget)="notifyReport($event)"
            [hidden]="!showAutoReviwSearch"
            [filterSettings]="filterSettings"
            [customerID]="customerID"
            [locationGroupID]="locationGroupID"
            [toggleState]="toggleState"
            [includeInactiveLocations]="includeInactiveLocations"
        >
        </app-widget-filters>
    </div>

    <div id="autoScrubSummaryTbl">
        <app-loader [isLoading]="autoReviewWidgetLoadingState"></app-loader>
        <div *ngIf="autoReviewData?.length == 0 || autoReviewData == null" class="notabledataFound custom-table">
            <span>{{ 'HOME.AUTO_REVIEW_WIDGET.ANOMOLIES_FOUND_MESSAGE' | translate }}</span>
        </div>
        <div [hidden]="autoReviewData.length == 0" id="autoReviewLeaderBoardTable"
            [ngClass]="{'filtersOff': !showAutoReviwSearch, 'filtersOn': showAutoReviwSearch }"
            class="widget-table">
            <mat-table #table [dataSource]="autoReviewDataSource" matSort>
                <!-- Location Column -->
                <ng-container matColumnDef="locationName">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="locationName ar-lc-hint">
                        {{ 'COMMON.LOCATION_COLUMN_TITLE' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let autoScrubSummaryDetail">
                        <span
                            class="fullScreenShow"
                            [ngClass]="{
                                customLongName: validateLocationNameLength(autoScrubSummaryDetail.locationName, 48)
                            }"
                            [title]="
                                validateLocationNameLength(autoScrubSummaryDetail.locationName, 48)
                                    ? autoScrubSummaryDetail.locationName
                                    : ''
                            "
                            >{{ autoScrubSummaryDetail.locationName }}</span
                        >
                        <a
                            class="fullScreenHide"
                            [ngClass]="{
                                customLongName: validateLocationNameLength(autoScrubSummaryDetail.locationName, 15)
                            }"
                            [title]="
                                validateLocationNameLength(autoScrubSummaryDetail.locationName, 15)
                                    ? autoScrubSummaryDetail.locationName
                                    : ''
                            "
                            (click)="getMarkerLocationDetails(autoScrubSummaryDetail.locationId)"
                            >{{ autoScrubSummaryDetail.locationName }}</a
                        >
                    </mat-cell>
                </ng-container>

                <!-- Date/Time Column -->
                <ng-container matColumnDef="dateTime">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="ar-date-hint">
                        {{ 'COMMON.TIMESTAMP_COLUMN_TITLE' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let autoScrubSummaryDetail">
                        {{ autoScrubSummaryDetail.dateTime }}
                    </mat-cell>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="displayStatus">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="ar-status-hint">
                        {{ 'COMMON.STATUS_COLUMN_TITLE' | translate }}
                    </mat-header-cell>
                    <mat-cell
                        *matCellDef="let autoScrubSummaryDetail"
                        [style.color]="autoScrubSummaryDetail?.status?.color"
                    >
                        {{ autoScrubSummaryDetail?.status?.text }}
                    </mat-cell>
                </ng-container>

                <!-- Error Column -->
                <ng-container matColumnDef="error">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="ar-error-hint" style="min-width: 40px">
                        {{ 'COMMON.ERROR_COLUMN_TITLE' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let autoScrubSummaryDetail">
                        {{ 100 * autoScrubSummaryDetail.error | number: '1.0-0' }} %</mat-cell
                    >
                </ng-container>

                <!-- Reason Column -->
                <ng-container matColumnDef="reason">
                    <mat-header-cell *matHeaderCellDef mat-sort-header class="ar-reason-hint">
                        {{ 'HOME.AUTO_REVIEW_WIDGET.REASON_COLUMN_TITLE' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let autoScrubSummaryDetail">
                        {{ autoScrubSummaryDetail.status.reason.reason }}
                    </mat-cell>
                </ng-container>

                <!-- Graph Column -->
                <ng-container matColumnDef="graph">
                    <mat-header-cell *matHeaderCellDef class="ar-graph-hint">
                        {{ 'COMMON.GRAPH_COLUMN_TITLE' | translate }}</mat-header-cell>
                    <mat-cell *matCellDef="let autoScrubSummaryDetail; let i = index">
                        <a
                            *ngIf="rawDataActive"
                            (click)="
                                openHydrograph(
                                    autoScrubSummaryDetail.locationId,
                                    autoScrubSummaryDetail.locationName,
                                    autoScrubSummaryDetail.dateTime,
                                    i
                                )
                            "
                        >
                            <mat-icon matTooltip="{{ 'COMMON.GENERATE_GRAPH_TOOLTIP' | translate }}"
                                >show_chart</mat-icon
                            >
                        </a>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="autoReviewDisplayedColumns; sticky: true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: autoReviewDisplayedColumns"></mat-row>
            </mat-table>
        </div>
    </div>
</mat-card-content>
<div class="widgetFooter" [hidden]="!(totalPaginationLength > 10)"
    [ngClass]="{ 'ar-footer-on-hint': availablePageHint, 'ar-footer-collapsed-hint': availablePageHint && toggleState, 'ar-footer-expanded-hint': availablePageHint && !toggleState}">
    <div class="app-flex-filler"></div>
    <div class="auto-margin">
        <mat-paginator
            #paginator
            class="ar-paginator-hint"
            [length]="totalPaginationLength"
            [pageIndex]="0"
            [pageSize]="10"
            (page)="onPaginateChange()"
            [pageSizeOptions]="[10, 25, 100]"
        >
        </mat-paginator>
    </div>
</div>
