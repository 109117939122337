<div [ngClass]="{ hidebackContent: hidebackContent, 'w-650': isStormDelete }">
    <div id="confirmation-dialog">
        <div class="popupHeader" mat-dialog-title [ngClass]="{ 'default-popup-header': isStormDelete }">
            <span>{{ title }}</span>
            <span class="app-flex-filler"></span>
        </div>
        <div mat-dialog-content id="contactUsContainerId" [ngClass]="{ 'centered-content': isStormDelete }">
            <p class="{{ messageClass }}">{{ message }}</p>

            <div *ngIf="dangerText">
                <p class="danger">{{ dangerText }}</p>
            </div>
        </div>
        <div mat-dialog-actions>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button type="submit" (click)="cancelAndClose()">{{ cancelText }}</button>
            </div>
            <div class="auto-margin">
                <button [disabled]="okDisabled" mat-button type="submit" (click)="okAndClose()">{{ okText }}</button>
            </div>
        </div>
    </div>
</div>
