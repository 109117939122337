<div id="AuditPageId" class="contentLayout">
    <mat-card>
        <mat-card-header>
            <div class="auto-margin audit-title-hint">{{ 'ADMIN.AUDIT_LOG.TITLE' | translate }}</div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin custom-flat-button">
                <button class="audit-download-hint" (click)="download()" [disabled]="!isAudit" mat-button>
                    {{ 'COMMON.DOWNLOAD_BTN' | translate }}
                </button>
            </div>
            <div class="auto-margin pad-r-15">
                <mat-icon
                    matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                    class="backIcon audit-filter-hint"
                    [ngClass]="{ active: displayFilters }"
                    (click)="toggleFilters()"
                    >filter_list</mat-icon
                >
            </div>
        </mat-card-header>
        <div id="auditLogWidgetId">
            <app-widget-filters
                #widgetFilter
                (sendDataToWidget)="notifyReport($event)"
                [customerID]="customerId"
                [includeInactiveLocations]="includeInactiveLocations"
                [enableDateRangeLimit]="false"
                [datePickerType]="datePickerType"
                [filterSettings]="auditFilterSettings"
                [hidden]="!displayFilters"
            >
            </app-widget-filters>
        </div>
        <mat-card-content>
            <app-loader [isLoading]="isLoading"></app-loader>
            <mat-form-field class="userSearch audit-search-hint">
                <mat-icon matPrefix class="search-icon">search</mat-icon>
                <input
                    matInput
                    type="text"
                    placeholder="{{ 'ADMIN.AUDIT_LOG.USER_SEARCH_PLACEHOLDER' | translate }}"
                    [formControl]="searchString"
                />
            </mat-form-field>
            <div [hidden]="!isAudit">
                <div id="auditPageTable">
                    <mat-table #table [dataSource]="auditDataSource" matSort (matSortChange)="sortAuditData($event)">
                        <!-- AUDIT username Column -->
                        <ng-container matColumnDef="username">
                            <mat-header-cell *matHeaderCellDef>{{
                                'ADMIN.AUDIT_LOG.PERFORM_ON_LABEL' | translate
                            }}</mat-header-cell>
                            <mat-cell *matCellDef="let audit"> {{ audit.username }} </mat-cell>
                        </ng-container>
                        <!-- AUDIT customername Column -->
                        <ng-container matColumnDef="customername">
                            <mat-header-cell *matHeaderCellDef>{{
                                'COMMON.CUSTOMER_NAME_TEXT' | translate
                            }}</mat-header-cell>
                            <mat-cell *matCellDef="let audit"> {{ audit.customername }} </mat-cell>
                        </ng-container>
                        <!-- AUDIT timestamp Column -->
                        <ng-container matColumnDef="timestamp">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                                'COMMON.TIMESTAMP_COLUMN_TITLE' | translate
                            }}</mat-header-cell>
                            <mat-cell *matCellDef="let audit; let i = index">
                                <span [ngClass]="{ 'audit-item-hint': i === 0 }">{{
                                    audit.timestamp | date: dateFormat
                                }}</span>
                            </mat-cell>
                        </ng-container>
                        <!-- AUDIT action Column -->
                        <ng-container matColumnDef="action">
                            <mat-header-cell *matHeaderCellDef>{{
                                'ADMIN.AUDIT_LOG.ACTION_TEXT' | translate
                            }}</mat-header-cell>
                            <mat-cell *matCellDef="let audit" class="wrap-text">
                                {{ audit.action }}
                            </mat-cell>
                        </ng-container>
                        <!-- AUDIT modifiedby Column -->
                        <ng-container matColumnDef="modifiedby">
                            <mat-header-cell *matHeaderCellDef>{{
                                'ADMIN.AUDIT_LOG.PERFORM_BY_LABEL' | translate
                            }}</mat-header-cell>
                            <mat-cell *matCellDef="let audit"> {{ audit.modifiedby }} </mat-cell>
                        </ng-container>
                        <!-- AUDIT locationname Column -->
                        <ng-container matColumnDef="locationname">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                                'COMMON.LOCATION_COLUMN_TITLE' | translate
                            }}</mat-header-cell>
                            <mat-cell *matCellDef="let audit"> {{ audit.locationname }} </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="auditColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: auditColumns"></mat-row>
                    </mat-table>
                </div>
            </div>
            <div class="notabledataFound custom-table" *ngIf="!isAudit">
                {{ 'ADMIN.NO_DATA_AVAILABLE' | translate }}
            </div>
        </mat-card-content>
        <mat-card-actions>
            <div class="app-flex-filler"></div>
            <div class="auto-margin" [hidden]="totalPaginationLength <= 10">
                <mat-paginator
                    [length]="totalPaginationLength"
                    [pageIndex]="0"
                    [pageSize]="100"
                    (page)="changePage($event)"
                    [pageSizeOptions]="[100, 200, 500, 1000]"
                >
                </mat-paginator>
            </div>
        </mat-card-actions>
    </mat-card>
</div>