import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent {
    constructor(public dialogRef: MatDialogRef<ContactUsComponent>) {}

    public emitContactUs() {
        this.dialogRef.close({ success: false });
    }
}
