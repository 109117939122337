export class EmailContents {
    public fullName: string;
    public stepsToReproduce: string;
    public reportImage?: any;
    public emailAddress: string;
    public contactNumber: string;

    constructor() {
        this.fullName = '';
        this.stepsToReproduce = '';
        this.reportImage = '';
        this.emailAddress = '';
        this.contactNumber = '';
    }
}

export class ApplicationSurvey {
    public departmentalRole: string;
    public howUseFulApp: string;
    public featureMakeAppEasy: string;
    public featureDescMakeAppEasy: string;
    public satisficationLevel: string;
    public satisficationLevelWhy: string;
    public navigationSatisfication: string;
    public navigationSatisficationWhy: string;
    public finalComments: string;

    constructor() {
        this.departmentalRole = '';
        this.howUseFulApp = '';
        this.featureMakeAppEasy = '';
        this.featureDescMakeAppEasy = '';
        this.satisficationLevel = '';
        this.satisficationLevelWhy = '';
        this.navigationSatisfication = '';
        this.navigationSatisficationWhy = '';
        this.finalComments = '';
    }
}
