<div id="percentFullReportId" class="contentLayout">
    <mat-card>
        <app-loader [isLoading]="percentFullLoading"></app-loader>

        <div class="reportHeader">
            <div>
                <mat-icon
                    [routerLink]="['/pages/report/percent-full']"
                    [queryParams]="{ c: customerID, lg: locationGroupID }"
                    >arrow_back</mat-icon
                >
            </div>
            <div>
                <span>{{ 'REPORT.PERCENT_FULL.DETAILS_TITLE' | translate }} {{ locationName }}</span>
                <span *ngIf="dateFormat"
                    >:
                    <span class="strong">{{ startDate | date: dateFormat }}</span>
                    <span>-</span>
                    <span class="strong">{{ endDate | date: dateFormat }}</span>
                </span>
            </div>
            <div class="app-flex-filler"></div>
            <div>
                <mat-icon
                    matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                    [ngClass]="{ active: displayFilters }"
                    (click)="showReportFilter()"
                    >filter_list</mat-icon
                >
            </div>
        </div>

        <div id="percentFullReportFilterId">
            <app-widget-filters
                (sendDataToWidget)="notifyReport($event)"
                [customerID]="customerID"
                [locationGroupID]="locationGroupID"
                [filterSettings]="{ displayDateRanges: true, skipInitialCall: true, dateSpanPastWeek: true }"
                [hidden]="!displayFilters"
            >
            </app-widget-filters>
        </div>

        <mat-card-content *ngIf="percentFullDetailsData; else noDataFound">
            <div class="tableScrollWraper">
                <table class="light-table light-table-bordered light-table-hover light-table-responsive-vertical">
                    <thead>
                        <tr>
                            <th class="text-right">{{ 'REPORT.PERCENT_FULL.AVERAGE_TEXT' | translate }}</th>
                            <th class="text-right">{{ 'REPORT.PERCENT_FULL.MAXIMUM_TEXT' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-right">{{ percentFullDetailsData.average }}</td>
                            <td class="text-right">{{ percentFullDetailsData.maximum }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </mat-card-content>

        <ng-template #noDataFound>
            <mat-card-content>
                <div class="notabledataFound custom-table">
                    <span>{{ 'REPORT.NO_DATA_MESSAGE' | translate }}</span>
                </div>
            </mat-card-content>
        </ng-template>
    </mat-card>
</div>
