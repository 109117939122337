export interface GisServiceList {
    serviceId?: number;
    serviceType: ServiceTypes;
    url: string;
    authURL?: string;
    authparam: string;
    customerId: number;
    authParams: string;
    authMethod: string;
    userName: string;
    password: string;
}

export interface GISServiceLayerList {
    name: string;
    title: string;
    customerId: number;
}

export interface GISLayerMapping {
    id: number;
    customerId: number;
    structureType: string;
    service: string;
    layer: string;
    attribute1: string;
    attribute2: string;
}

export interface BaseMapListItem {
    baseMapId: string;
    url: string;
    name: string;
    imageURL: string;
    mapKey: string;
    isActive: boolean;
}

export interface gisUserSettings {
    id: number;
    userId: number;
    locationsIsActive: boolean;
    languageSelection: string;
    sidebarIsOn: boolean;
    baseMapId: string;
    isMapPanelOn: boolean;
    sidebarIsOn2: boolean;
    isLayersOn: boolean;
    isActive: boolean;
    selectedLocationGroupId: number;
    locationPositionX: string;
    locationPositionY: string;
    isLayersSelectable: boolean;
    activeLayersTabNo: number;
    isSidebarPinOn: boolean;
    isSidebarPinOn2: boolean;
    layersParameterJson: string;
    showLocationLabels: boolean;
    customerSettings: userSettingCustomerDetails[];
    lastViewedCustomerId: number;
    _etag?: string; // returned from server but need to be able to access and delete it!
}

export interface locationSearchOption {
    id: number;
    name: string;
}

export interface mapLayerList {
    checked: boolean;
    name: string;
    selection: boolean;
    showLegend: boolean;
    legendInfo: { label: string; url: string }[];
    opacity: number;
}

export interface featureSelList {
    feature: any;
    name: string;
    layername: string;
}

export interface userSettingCustomerDetails {
    cid: number;
    lastViewedExtents: number[];
    lastViewedDashboardConfig?: string;
    lastViewedLocationGroup: number;
    layers: userSettingLayerDetails[];
}

export interface userSettingLayerDetails {
    name: string;
    isVisible: boolean;
    isSelectable: boolean;
}
export enum ServiceTypes {
    WMS = 'wms',
    WFS = 'wfs',
    MAPS = 'maps',
    FEATURE = 'feat',
}

export interface GisToken {
    access_token: string;
    expires_in: number;
}