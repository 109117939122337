<div class="contentLayout">
    <div id="SlicerDashboardId" *ngIf="!slicerPagePermission">
        <mat-card>
            <mat-card-header>
                <div class="auto-margin">{{ 'SLICER_PAGE.SLIICER' | translate }}&reg;</div>
                <div class="app-flex-filler"></div>
                <div *ngIf="isBetaFlag" class="betaLabel">{{ 'COMMON.BETA_LABEL' | translate }}</div>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="slicer-marketing-content">
                <app-loader [isLoading]="isLoading"></app-loader>
                <div class="top"></div>
                <h3>
                    {{ 'SLICER_PAGE.SLIICER_WELCOME_MESSAGE' | translate }}
                    {{ 'SLICER_PAGE.SLIICER' | translate }}&reg;.
                    {{ 'SLICER_PAGE.WORKING_HARD_SLIICER_PRISM' | translate }}
                </h3>
                <P>{{ 'SLICER_PAGE.SLIICER_ENABLE_USERS' | translate }}:</P>
                <ul>
                    <li>{{ 'SLICER_PAGE.SLIICER_CHARACTERIZE_WEATHER' | translate }}</li>
                    <li>{{ 'SLICER_PAGE.SLIICER_TARGET_INFILTRATION' | translate }}</li>
                    <li>{{ 'SLICER_PAGE.SLIICER_HYDRAULIC_MODELING' | translate }}</li>
                </ul>
                <p>
                    {{ 'SLICER_PAGE.CONTACT_ADS' | translate }}
                </p>
            </mat-card-content>
        </mat-card>
    </div>
    <ng-tempate *ngIf="slicerPagePermission">
        <ads-sliicer-study [customerId]="customerID" [isBetaFlag]="isBetaFlag"></ads-sliicer-study>
    </ng-tempate>
</div>
