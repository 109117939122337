import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    OnDestroy,
    ChangeDetectorRef,
    ElementRef,
    Inject,
} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { ApiKeyService } from '../../../shared/services/api-key.service';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { REGEX_CONFIG } from '../../../shared/utils/regex-utils';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';

@Component({
    selector: 'app-generate-api-key',
    templateUrl: './generate-api-key.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenerateApiKeyComponent implements OnInit, OnDestroy {
    public isValueCopied: boolean;
    public selectedCustomerID: number;
    public dateExperationList?: Object;
    public dateExperation: string;
    private subscriptions = new Array<Subscription>();
    public showKeyDetailSection = false;
    public apiKeyNew: string;
    public isLoading: boolean;
    public description: string;
    public isDescEmpty: boolean;
    public invalidDesc: boolean;

    constructor(
        public dialogRef: MatDialogRef<GenerateApiKeyComponent>,
        public route: ActivatedRoute,
        @Inject(DOCUMENT) private angularDocument: Document,
        private elements: ElementRef,
        private cdr: ChangeDetectorRef,
        public apiKeyService: ApiKeyService,
        private uiUtilsService: UiUtilsService,
    ) {}

    public ngOnInit() {
        this.isDescEmpty = true;
        this.dateExperationList = [{ value: 0, text: 'Never' }];

        this.route.queryParams.subscribe((params) => {
            this.selectedCustomerID = params['c'];
        });
        this.showKeyDetailSection = false;
    }

    /**
     * Function to close the dialog box.
     */

    public emitGeneateApiKey() {
        if (this.showKeyDetailSection) {
            this.dialogRef.close({ success: true });
        } else {
            this.dialogRef.close({ success: false });
        }
    }

    /**
     * Function to create a new api key.
     */

    public geneateApiKey() {
        if (this.description != null && this.description.length > 0) {
            if (this.description.length < 100 && this.description.match(REGEX_CONFIG.alphaNumericPattern)) {
                this.isDescEmpty = false;
                this.isLoading = true;
                // get currently selected customer id from urls
                this.route.queryParams.subscribe((params) => {
                    this.selectedCustomerID = params['cid'];
                });
                this.setCustomerApiKey();
            } else if (!this.description.match(REGEX_CONFIG.alphaNumericPattern)) {
                this.invalidDesc = true;
            }
        }
    }

    public setCustomerApiKey() {
        const addApiKeySubscription = this.apiKeyService.addApiKey(this.selectedCustomerID, this.description).subscribe(
            (response) => {
                if (response) {
                    this.showKeyDetailSection = true;
                    this.isLoading = false;
                    this.apiKeyNew = response.key;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                } else {
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }
            },
            (error) => {
                this.showKeyDetailSection = true;
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );

        this.subscriptions.push(addApiKeySubscription);
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /**
     * Function to copey the content of key.
     */

    public copyApiKey(event: MouseEvent): void {
        // get reference to text area element
        const textAreaElement: HTMLTextAreaElement = this.elements.nativeElement.querySelector('.share-key');

        // ensure that text element was found
        if (textAreaElement == null || this.apiKeyNew === undefined || this.apiKeyNew === '') {
            return;
        }

        // select the textarea content
        textAreaElement.select();

        // copy to clipboard
        this.isValueCopied = this.angularDocument.execCommand('copy');

        // clear selection - needed this to make material button toggle work after initial selection
        textAreaElement.selectionStart = textAreaElement.selectionEnd = 0;
    }
}
