import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
    OnInit,
    OnDestroy,
    ViewChild,
    Input,
    ChangeDetectorRef,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {
    MonitorDiagnosticsService,
    MonitorDiagnosticLog as MonitorDiagnosticLog,
} from 'app/shared/services/monitor-diagnostics.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { LocationDiagnosticsResultsComponent } from './location-diagnostics-results/location-diagnostics-results.component';
import { DeviceTypeCode } from 'app/shared/enums/device-type-code';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { SignalRService, DIAGNOSTICS_COMPLETE } from 'app/shared/services/signalr.service';
import { SignalRMessageType } from 'app/shared/models/signalr-message';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { MONITOR_SERIES_TYPES } from 'app/shared/models/monitor-series-types';
import { DatePickerComponent } from 'app/shared/components/date-picker/date-picker.component';
import { DiagnosticService } from './location-diagnostics-service';
import { TritonLocationService } from 'app/shared/services/tritonLocation.service';

@Component({
    selector: 'app-location-diagnostics',
    templateUrl: './location-diagnostics.component.html',
    styleUrls: ['./location-diagnostics.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationDiagnosticsComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('adsDatePicker') public adsDatePicker: DatePickerComponent;
    @Input()
    public deviceTypes: DeviceTypeCode[];
    @Input()
    public monitorSeriesType: MONITOR_SERIES_TYPES;
    @Input()
    public customerId: number;
    @Input()
    public locationId: number;

    public dataSource: MatTableDataSource<MonitorDiagnosticLog>;
    public displayedColumns: string[] = ['date', 'user', 'result'];
    public dateFormat: string;
    public isLoading: boolean;

    private subscriptions = [];

    @ViewChild(MatPaginator) public paginator: MatPaginator;
    public showFilters: boolean;
    public diagnosesRunning: Object;

    constructor(
        private monitorDiagnosticsService: MonitorDiagnosticsService,
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private signalRService: SignalRService,
        private dateUtilService: DateutilService,
        private snackBar: MatSnackBar,
        private diagnosticService: DiagnosticService,
        private tritonLocationService: TritonLocationService
    ) {
        this.dateFormat = `${this.dateUtilService.getFormat()} ${this.dateUtilService.getTimeFormatWithoutSeconds()}`;
    }

    public ngOnInit() {
        const diagnosesServ = this.diagnosticService.diagnosesRunning.subscribe((diagnoses) => {
            this.diagnosesRunning = diagnoses ? diagnoses : {};
        });
        const signalRSub = this.signalRService.messageReceived.subscribe(async (res) => {
            if (res && res.cid && res.type === SignalRMessageType.Info && res.message && res.cid === this.customerId) {
                if (res.message.startsWith(DIAGNOSTICS_COMPLETE)) {
                    this.diagnosticService.diagnosesRunning.next(this.diagnosesRunning);
                    this.getLogs();
                }
            }
        });

        const activationSubs = this.tritonLocationService.activationComplete$.subscribe(() => this.getLogs());

        this.subscriptions.push(signalRSub);
        this.subscriptions.push(diagnosesServ);
        this.subscriptions.push(activationSubs);
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (
            (changes.locationId && changes.locationId.currentValue !== changes.locationId.previousValue) ||
            (changes.customerId && changes.customerId.currentValue !== changes.customerId.previousValue)
        ) {
            this.getLogs();
        }
    }

    public showResult(data: MonitorDiagnosticLog) {
        this.dialog.open(LocationDiagnosticsResultsComponent, {
            data,
            panelClass: 'location-diagnostics-result-dialog',
        });
    }

    public toggleFilters() {
        this.showFilters = !this.showFilters;
    }

    public getLogs() {
        this.isLoading = true;
        let startTime = new Date(2019, 1);
        let endTime = new Date();
        if (this.adsDatePicker) {
            startTime = new Date(
                Date.UTC(
                    this.adsDatePicker.startDate.getFullYear(),
                    this.adsDatePicker.startDate.getMonth(),
                    this.adsDatePicker.startDate.getDate(),
                    this.adsDatePicker.startDate.getHours(),
                    this.adsDatePicker.startDate.getMinutes(),
                    0,
                ),
            );
            endTime = new Date(
                Date.UTC(
                    this.adsDatePicker.endDate.getFullYear(),
                    this.adsDatePicker.endDate.getMonth(),
                    this.adsDatePicker.endDate.getDate(),
                    this.adsDatePicker.endDate.getHours(),
                    this.adsDatePicker.endDate.getMinutes(),
                    0,
                ),
            );
        }
        const logsSub = this.monitorDiagnosticsService
            .getDiagnosticLogs(this.customerId, this.locationId, {
                startTime: startTime,
                endTime: endTime,
            })
            .subscribe(
                (logs) => {
                    this.dataSource = new MatTableDataSource<MonitorDiagnosticLog>(logs);
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
                (err) => {
                    this.snackBar.open(`there was an error requesting logs`, err);
                },
            );

        this.subscriptions.push(logsSub);
    }
}
