import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';

@Component({
    selector: 'app-approve-data-dialog',
    templateUrl: './approve-data-dialog.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApproveDataDialogComponent implements OnInit {
    public approvalDateControl = new FormControl();
    public today = new Date();
    constructor(
        public dialogRef: MatDialogRef<ApproveDataDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private matDialog: MatDialog,
    ) {}

    ngOnInit() {
        this.approvalDateControl.setValue(this.data.endDate);
    }

    public approvalDateChange(event) {
        if (!event.value) return;

        const current = new Date(event.value).getTime();
        const startDate = new Date(this.data.startDate).getTime();

        if (current > startDate) return;

        const dialogData = {
            title: 'Confirm date',
            message: 'Selected date is outside of shown date range, are you sure to set this date?',
            okText: 'Confirm',
            cancelText: 'Cancel',
        };
        const dialogRef = this.matDialog.open(ConfirmationDialogComponent, { data: dialogData });
        dialogRef
            .afterClosed()
            .pipe(first())
            .subscribe((result) => {
                if (!result || result.whichButtonWasPressed === 'cancel') {
                    this.approvalDateControl.setValue(this.data.endDate);

                    return;
                }
            });
    }

    public submit() {
        this.dialogRef.close(new Date(new Date(this.approvalDateControl.value).getTime() - 1));
    }
}
