<form [formGroup]="form">
    <mat-expansion-panel [ngClass]="{ 'surface-combo-section-error': form.invalid }">
        <mat-expansion-panel-header>
            <mat-panel-title class="input-text">
                {{ 'ADD_EDIT.ADD_EDIT_LONG_RANGE' | translate }}
            </mat-panel-title>
            <mat-panel-description *ngIf="form.invalid && (form | customErrorCount)">
                <mat-icon>warning</mat-icon>
                <span>
                    {{ form | customErrorCount }}
                    {{ 'HOME.MAP.MARKER_LOCATION_DETAIL.TRITON_REQUIRED_ERRORS_TXT' | translate }}
                </span>
            </mat-panel-description>
            <mat-panel-description *ngIf="form.invalid && !(form | customErrorCount)">
                <mat-icon>warning</mat-icon> <span> </span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div [class.disable-field]="!isMonitorEditor">
            <div class="custom-width">
                <mat-form-field class="full-width">
                    <input
                        matInput
                        formControlName="manholedepth"
                        readonly
                        [placeholder]="
                            locationCardService.addDepthUnitToPlaceholder(
                                translateService.instant('HOME.MAP.MARKER_LOCATION_DETAIL.MANHOLE_DEPTH')
                            )
                        "
                    />
                </mat-form-field>
            </div>
            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            [placeholder]="
                                locationCardService.addDepthUnitToPlaceholder(
                                    translateService.instant('ADD_EDIT.PHYSICAL_OFFSET')
                                )
                            "
                            formControlName="physicalOffset"
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('physicalOffset').errors?.pattern">
                        {{ isMetric ? ('COMMON.OFFSET_METRIC_ERROR' | translate) : ('COMMON.OFFSET_NON_METRIC_ERROR' | translate) }}
                    </div>
                </div>
                <div class="each-Item">
                    <div class="full-width">
                        <span class="toggle toggle-text">{{ 'ADD_EDIT.STORE_DATA' | translate }} </span>
                        <mat-slide-toggle formControlName="storeData"> </mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            [placeholder]="
                                locationCardService.addDepthUnitToPlaceholder(
                                    translateService.instant('ADD_EDIT.BLANKING')
                                )
                            "
                            formControlName="blanking"
                            required
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('blanking').errors?.pattern">
                        {{ isMetric ? ('COMMON.OFFSET_METRIC_ERROR' | translate) : ('COMMON.OFFSET_NON_METRIC_ERROR' | translate) }}
                    </div>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <mat-select
                            placeholder="{{ 'LOCATION_DASHBOARD.TEMPERATURE_SCALE_AXIS' | translate }}"
                            formControlName="temperatureSensor"
                        >
                            <mat-option *ngFor="let temperature of tritonSensorTemp" [value]="temperature.value">
                                {{ temperature.text }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <mat-select formControlName="power" placeholder="{{ 'ADD_EDIT.POWER' | translate }}">
                            <mat-option *ngFor="let power of powerList" [value]="power.value"
                                >{{ power.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            [placeholder]="
                                locationCardService.addDepthUnitToPlaceholder(
                                    translateService.instant('ADD_EDIT.ELECTRONIC_OFFSET')
                                )
                            "
                            formControlName="electronicOffset"
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('electronicOffset').errors?.pattern">
                        {{ isMetric ? ('COMMON.OFFSET_METRIC_ERROR' | translate) : ('COMMON.OFFSET_NON_METRIC_ERROR' | translate) }}
                    </div>
                </div>
            </div>
            <div class="custom-50p">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <mat-select
                            formControlName="gainControlSelection"
                            placeholder="{{ 'ADD_EDIT.GAIN_CONTROL' | translate }}"
                            (selectionChange)="onChangeGainControl($event)"
                        >
                            <mat-option *ngFor="let gaincontrol of gainControlList" [value]="gaincontrol.text">
                                {{ gaincontrol.text }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="each-Item">
                  <mat-form-field class="full-width">
                    <mat-select
                        formControlName="gainControl"
                        placeholder="{{ 'ADD_EDIT.GAIN_VALUE' | translate }} (db)"
                    >
                        <mat-option *ngFor="let value of gainValues" [value]="value">
                            {{ value }}
                        </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="each-Item">
                    <div class="full-width">
                        <span class="toggle toggle-text">{{ 'ADD_EDIT.UNIDEPTH_FILTERING' | translate }}</span>
                        <mat-slide-toggle formControlName="unidepthFiltering"> </mat-slide-toggle>
                    </div>
                </div>
                <div class="each-Item">
                    <div class="full-width">
                        <span class="toggle toggle-text">{{ 'ADD_EDIT.STORE_PRESSURE' | translate }}</span>
                        <mat-slide-toggle formControlName="pressureStore"> </mat-slide-toggle>
                    </div>
                </div>
            </div>
            <mat-panel-subtitle class="input-text">
                {{ 'ADD_EDIT.COMPENSATED_TEMPERATURE' | translate }}
            </mat-panel-subtitle>
            <div class="custom-50p text-space">
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            [placeholder]="
                                locationCardService.addTempUnitToPlaceholder(translateService.instant('ADD_EDIT.MARCH'))
                            "
                            formControlName="compensatedTemperatureMarch"
                            required
                        />
                    </mat-form-field>
                </div>
                <div class="each-Item">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            [placeholder]="
                                locationCardService.addTempUnitToPlaceholder(
                                    translateService.instant('ADD_EDIT.SEPTEMBER')
                                )
                            "
                            formControlName="compensatedTemperatureSeptember"
                            required
                        />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </mat-expansion-panel>
</form>
