<div id="apiKeyDashbordId">
    <mat-card-content>
        <app-loader [isLoading]="isLoading"></app-loader>
        <div>
            <div id="customersPageTable">
                <mat-table #table [dataSource]="apiKeyDataSource" matSort>
                    <!-- CUSTOMER NAME Column -->
                    <ng-container matColumnDef="description">
                        <mat-header-cell *matHeaderCellDef>{{ 'ADMIN.DESCRIPTION_TEXT' | translate }}</mat-header-cell>
                        <mat-cell *matCellDef="let apiKey">{{ apiKey.description }} </mat-cell>
                    </ng-container>

                    <!-- CUSTOMER ACTIVE LOCATIONS Column -->
                    <ng-container matColumnDef="key">
                        <mat-header-cell *matHeaderCellDef>{{
                            'ADMIN.API_KEY_DASHBOARD.KEY_TEXT' | translate
                        }}</mat-header-cell>
                        <mat-cell *matCellDef="let apiKey"> {{ apiKey.key }} </mat-cell>
                    </ng-container>

                    <!-- CUSTOMER ACTIVE LOCATIONS Column -->
                    <ng-container matColumnDef="lastUsed">
                        <mat-header-cell *matHeaderCellDef>{{
                            'ADMIN.API_KEY_DASHBOARD.LAST_USED' | translate
                        }}</mat-header-cell>
                        <mat-cell *matCellDef="let apiKey">{{ apiKey.lastUsed }}</mat-cell>
                    </ng-container>

                    <!-- CUSTOMER ACTIVE LOCATIONS Column
          commented for now as this column can be used in future-->

                    <!-- <ng-container matColumnDef="expire" [hidden]="true">
            <mat-header-cell *matHeaderCellDef>Expiration</mat-header-cell>
            <mat-cell *matCellDef="let apiKey"> {{apiKey.expire}} </mat-cell>
          </ng-container> -->

                    <!-- CUSTOMER ACTIVE LOCATIONS Column -->
                    <ng-container matColumnDef="active">
                        <mat-header-cell *matHeaderCellDef>{{
                            'ADMIN.API_KEY_DASHBOARD.ACTIVE_INACTIVE' | translate
                        }}</mat-header-cell>
                        <mat-cell *matCellDef="let apiKey" class="custom-disabled-View">
                            <mat-slide-toggle
                                [color]="'accent'"
                                [checked]="apiKey.active"
                                (change)="onChange($event, apiKey)"
                            >
                                {{ apiKey.active ? 'Active' : 'Inactive' }}
                            </mat-slide-toggle>
                        </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="deleteApiKey">
                        <mat-header-cell *matHeaderCellDef class="fixCellWidth"></mat-header-cell>
                        <mat-cell *matCellDef="let apiKey" class="text-right">
                            <mat-icon
                                matTooltip="{{ 'COMMON.DELETE_BUTTON' | translate }}"
                                matTooltipPosition="left"
                                (click)="deleteApiKey(apiKey)"
                                >delete</mat-icon
                            >
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="apiKeyFilterColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: apiKeyFilterColumns"></mat-row>
                </mat-table>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions>
        <div class="auto-margin">
            <button
                (click)="createApiKey()"
                id="addApiKey"
                mat-fab
                color="accent"
                matTooltip="{{ 'ADMIN.API_KEY_DASHBOARD.CREATE_API_KEY' | translate }}"
                matTooltipPosition="above"
            >
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div class="app-flex-filler"></div>
        <div class="auto-margin" [hidden]="totalPaginationLength <= 10">
            <mat-paginator
                [length]="totalPaginationLength"
                [pageIndex]="0"
                [pageSize]="10"
                [pageSizeOptions]="[10, 25, 100]"
            >
            </mat-paginator>
        </div>
    </mat-card-actions>
</div>
