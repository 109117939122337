// import { AuthService } from 'app/shared/services';
import { Injectable, isDevMode } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpEvent,
    HttpErrorResponse,
    HttpResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { StatusCodeService } from './../services/status-code.service';
import { StringUtils } from '../utils/string-utils';
import { Router } from '@angular/router';
import { ADS_REFRESH_TOKEN_KEY, ADS_TOKEN_KEY, AuthService } from '../services/auth.service';
import { catchError, filter, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Config } from '../services/config';
import { TokenResponse } from '../models/adsToken';
import { GISService } from '../services/gis-service';

const BASEMAPS_ENDPOINT = 'basemapstyles-api.arcgis.com';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {
    // Variable to check the value of statusCodeFlag
    public statusCodeFlag = false;
    public error = 'error';

    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(
        public authService: AuthService, 
        public statusCodeService: StatusCodeService, 
        public router: Router,
        private gisService: GISService
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // serialaize dates without timezone

        if (request.body && request.body.startTime && request.body.startTime instanceof Date) {
            request.body.startTime = request.body.startTime.toJSON().slice(0, -5);
        }

        if (request.body && request.body.endTime && request.body.endTime instanceof Date) {
            request.body.endTime = request.body.endTime.toJSON().slice(0, -5);
        }

        if (request.body && request.body.start && request.body.start instanceof Date) {
            request.body.start = request.body.start.toJSON().slice(0, -5);
        }

        if (request.body && request.body.end && request.body.end instanceof Date) {
            request.body.end = request.body.end.toJSON().slice(0, -5);
        }

        if (request.body && request.body.editedStartTime && request.body.editedStartTime instanceof Date) {
            request.body.editedStartTime = request.body.editedStartTime.toJSON().slice(0, -5);
        }

        if (request.body && request.body.editedEndTime && request.body.editedEndTime instanceof Date) {
            request.body.editedEndTime = request.body.editedEndTime.toJSON().slice(0, -5);
        }

        if(request.body && request.body.config && request.body.config.startDate && request.body.config.startDate.length === 24) {
            request.body.config.startDate = request.body.config.startDate.slice(0, 19);
        }

        if(request.body && request.body.config && request.body.config.endDate && request.body.config.endDate.length === 24) {
            request.body.config.endDate = request.body.config.endDate.slice(0, 19);
        }

        if(request.body && request.body.start && request.body.start.length === 24) {
            request.body.start = request.body.start.slice(0, 19);
        }

        if(request.body && request.body.end && request.body.end.length === 24) {
            request.body.end = request.body.end.slice(0, 19);
        }

        if (request.body && request.body.updatePoints) {
            request.body.updatePoints.forEach((x) => {
                if (x.timeStamp instanceof Date) {
                    x.timeStamp = x.timeStamp.toJSON().slice(0, -5);
                }
            });
        }

        // getting ads token from session storage which was set by main resolver
        const token = sessionStorage.getItem(ADS_TOKEN_KEY); 

        // if token exists
        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }
        // check for content-type.
        if (!request.headers.has('Content-Type')) {
            request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        }

        // setting the accept header
        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        // handle http error as well as response.
        return next.handle(request).pipe(
            takeUntil(this.statusCodeService.onCancelPendingRequests()),
            tap(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        // console.log('Response Status     :' + event.status);
                        if (event.status === 204) {
                            this.statusCodeFlag = true;
                            this.statusCodeService.statusCodeFlag.next(this.statusCodeFlag);
                        } else {
                            this.statusCodeService.setInitialValue();
                        }
                    }
                }
            ),
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401 && request.url.includes(BASEMAPS_ENDPOINT)) {
                        return this.handleBasemap401(request, next);
                    }
                    if (error.status === 401 && !request.url.includes(Config.urls.refreshToken) && localStorage.getItem(ADS_REFRESH_TOKEN_KEY)) {
                        return this.handle401(request, next);
                    } else if (error.status === 403 || error.status === 401) {
                        // handle status code 401 && 403 both codes correspond to UnAuthenticated. due to wrong or expired key.
                        this.handleError();
                    } else if (error.status === 409) {
                        // handle status code 409
                        this.statusCodeService.statusCode409.next(true);
                    } else {
                        if (isDevMode()) {
                            console.log(error);
                        }
                        return throwError(error);
                    }
                    // return the error to the method that called it
                    return throwError(error);
                }
            }),
        );
    }

    private handle401(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);

            return this.authService.callRefreshToken().pipe(
                switchMap((token: TokenResponse) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(token.token);
                    return next.handle(request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token.token) }));
                }),

            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter((token) => token !== null),
                take(1),
                switchMap((jwt) => {
                    return next.handle(request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + jwt) }));
                })
            );
        }
    }

    private handleBasemap401(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.gisService.getGisToken().pipe(
            switchMap((token) => {
                const url = this.replaceGISToken(request.url, token.access_token);
                return next.handle(request.clone({ url }));
            })
        );
    }

     private handleError() {
        this.isRefreshing = false;
        this.authService.setErrorCountFlag = true;
        StringUtils.clearSessionStorage();
        StringUtils.clearSessionUser(this.router.url);
        this.router.navigate(['welcome']);
    }

    private replaceGISToken(url: string, newToken: string) {
        const regex = /token=([^&]*)/;
        return url.replace(regex, `token=${newToken}`);
    }
}
