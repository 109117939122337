import {
    Component,
    OnInit,
    Injectable,
    EventEmitter,
    Output,
    ChangeDetectionStrategy,
    Input,
    ChangeDetectorRef,
    OnDestroy,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { of as ofObservable, Observable, BehaviorSubject, Subscription } from 'rxjs';
import { featureSelList } from 'app/shared/models/gis-service-list';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CompositeLocComponent } from 'app/shared/components/map/composite-loc/composite-loc.component';
import { GISService } from 'app/shared/services/gis-service';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { MapService } from 'app/shared/services/map.service';
import { Locations } from 'app/shared/models/locations';
import { AddLocationModalData } from 'app/shared/models/add-location';
import { LocationDetailsModel } from 'app/shared/models/location-details';
import { TritonLocationDialogComponent } from 'app/shared/components/location-card/components/triton-location-dialog/triton-location-dialog.component';
import { sortBy } from 'lodash';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { AdsPrismVaultLocationExportComponent } from 'app/pages/vault/vault-location-export/vault-location-export.component';
import { LayerNameOpts } from 'app/shared/models/map-type';
import { activeInactiveLocationQueryParam } from 'app/shared/models/customer';
import { LocationCardService } from 'app/shared/components/location-card/services/location-card.service';

export class feat_TodoItemNode {
    children: feat_TodoItemNode[];
    item: string;
    feature: any;
    layername: string;
    alarmstatus: number;
}

/** Flat to-do item node with expandable and level information */

export class feat_TodoItemFlatNode {
    item: string;
    level: number;
    expandable: boolean;
    feature: any;
    layername: string;
    alarmstatus: number;
}

/**
 * The Json object for to-do list data.
 */
let feat_TREE_DATA: any = [];

/**
 * Checklist database, it can build a tree structured Json object.
 * Each node in Json object represents a to-do item or a category.
 * If a node is a category, it has children items and new items can be added under the category.
 */

@Injectable({ providedIn: 'root' })
export class FeaturelistDatabase {
    feat_dataChange: BehaviorSubject<feat_TodoItemNode[]> = new BehaviorSubject<feat_TodoItemNode[]>([]);

    get data(): feat_TodoItemNode[] {
        return this.feat_dataChange.value;
    }

    constructor() {
        this.initialize();
    }

    initialize() {
        // Build the tree nodes from Json object. The result is a list of `feat_TodoItemNode` with nested
        //     file node as children.
        this.dataupdate(feat_TREE_DATA);
    }

    dataupdate(datas: any) {
        const data = this.feat_selparent(datas, 0);
        this.feat_dataChange.next(data);
    }

    feat_selparent(value: any, level: number) {
        const data: any[] = [];
        Object.keys(value).forEach((key) => {
            const samplevalue = value[key];
            const dataitem = this.buildFileTree(value[key], level);
            data.push(dataitem);
            // do something with obj[key]
        });
        return data;
    }

    feat_buildTree(value: any, level: number) {
        const data: any[] = [];
        if (value.length > 0) {
            value.forEach((lyr) => {
                const dataitem = this.buildFileTree(lyr, level);
                data.push(dataitem);
            });
        }
        return data;
    }

    buildFileTree(value: any, level: number) {
        let alarmStatus = 0;
        if (value.feature && value.feature.values_) {
            const vals = value.feature.values_;
            if (vals.AlarmId && !vals.AcknowledgedBy) {
                alarmStatus = 2; // actively in alarm and not acknowledged
            } else if (vals.AlarmId) {
                alarmStatus = 1; // actively in alarm but acknowledged
            }
        }

        const node = new feat_TodoItemNode();
        node.item = value.name;
        node.feature = value.feature;
        node.layername = value.layername;
        node.alarmstatus = alarmStatus;
        if (value.child.length > 0) {
            node.children = this.feat_buildTree(value.child, 1);
        }

        return node;
    }

    insertItem(parent: feat_TodoItemNode, name: string) {
        const child = <feat_TodoItemNode>{ item: name };
        if (parent.children) {
            parent.children.push(child);
            this.feat_dataChange.next(this.data);
        }
    }

    updateItem(node: feat_TodoItemNode, name: string) {
        node.item = name;
        this.feat_dataChange.next(this.data);
    }
}

/**
 * @title Tree with checkboxes
 */

@Component({
    selector: 'app-location-feature-property-list',
    templateUrl: './location-feature-property-list.component.html',
    styleUrls: ['./location-feature-property-list.component.scss'],
})
export class locationFeaturePropertyListComponent implements OnInit, OnDestroy {
    @Input() public activeNodeItem: string;
    @Input() public availablePageHint: boolean;
    @Output() public featureselected = new EventEmitter<featureSelList[]>();
    @Output() public featureLocationEdit = new EventEmitter<featureSelList[]>();
    @Output() public featureLocationZoom = new EventEmitter<featureSelList[]>();
    @Output() public refreshMap = new EventEmitter();

    public prevTreeValues = [];
    /* @Input() public mapLayerslist = {};*/
    flatNodeMap: Map<feat_TodoItemFlatNode, feat_TodoItemNode> = new Map<feat_TodoItemFlatNode, feat_TodoItemNode>();

    feat_nestedNodeMap: Map<feat_TodoItemNode, feat_TodoItemFlatNode> = new Map<
        feat_TodoItemNode,
        feat_TodoItemFlatNode
    >();

    feat_selectedParent: feat_TodoItemFlatNode | null = null;

    feat_newItemName = '';

    feat_treeControl: FlatTreeControl<feat_TodoItemFlatNode>;

    feat_treeFlattener: MatTreeFlattener<feat_TodoItemNode, feat_TodoItemFlatNode>;

    feat_dataSource: MatTreeFlatDataSource<feat_TodoItemNode, feat_TodoItemFlatNode>;

    feat_checklistSelection = new SelectionModel<feat_TodoItemFlatNode>(true /* multiple */);

    private compositeLocationDialog: MatDialogRef<any>;

    public assignedRainGauge = new Array<Locations>();
    customerId: number;
    @Input() public locations = new Array<Locations>();
    public isDemoDataPopulated = false;
    private subscriptions = new Subscription();
    constructor(
        public feat_database: FeaturelistDatabase,
        private gisService: GISService,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private mapService: MapService,
        private dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private locationCardService: LocationCardService
    ) {
        this.ngOnInit();
    }
    ngOnInit() {
        const routeParamsSubs = this.activatedRoute.queryParams.subscribe((data) => {
            if (!data) return;

            this.customerId = Number(data.c);
        });
        this.subscriptions.add(routeParamsSubs);
        this.feat_treeFlattener = new MatTreeFlattener(
            this.feat_transformer,
            this.feat_getLevel,
            this.feat_isExpandable,
            this.feat_getChildren,
        );
        this.feat_treeControl = new FlatTreeControl<feat_TodoItemFlatNode>(this.feat_getLevel, this.feat_isExpandable);
        this.feat_dataSource = new MatTreeFlatDataSource(this.feat_treeControl, this.feat_treeFlattener);
        this.feat_database.feat_dataChange.subscribe((data) => {
            // #20575 we don't want measurements do display on Tools tab
            this.feat_dataSource.data = data.filter(x => x.item !== LayerNameOpts.measurement);
        });
    }

    feat_getLevel = (node: feat_TodoItemFlatNode) => {
        return node.level;
    };

    feat_isExpandable = (node: feat_TodoItemFlatNode) => {
        return node.expandable;
    };

    //feat_isChecked = (node: feat_TodoItemFlatNode) => { return node.checked; };

    feat_getChildren = (node: feat_TodoItemNode): Observable<feat_TodoItemNode[]> => {
        return ofObservable(node.children);
    };

    feat_hasChild = (_: number, _nodeData: feat_TodoItemFlatNode) => {
        return _nodeData.expandable;
    };

    hasNoContent = (_: number, _nodeData: feat_TodoItemFlatNode) => {
        return _nodeData.item === '';
    };

    feat_transformer = (node: feat_TodoItemNode, level: number) => {
        const flatNode =
            this.feat_nestedNodeMap.has(node) && this.feat_nestedNodeMap.get(node)!.item === node.item
                ? this.feat_nestedNodeMap.get(node)!
                : new feat_TodoItemFlatNode();
        flatNode.item = node.item;
        flatNode.feature = node.feature;
        flatNode.layername = node.layername;
        flatNode.alarmstatus = node.alarmstatus;
        flatNode.level = level;
        flatNode.expandable = !!node.children;
        this.flatNodeMap.set(flatNode, node);
        this.feat_nestedNodeMap.set(node, flatNode);
        return flatNode;
    };

    feat_loaddata(layer: any) {
        const keys = Object.keys(layer);
        keys.forEach((x) => {
            layer[x].child = sortBy(layer[x].child, (y) => y.name);
        });

        feat_TREE_DATA = layer;
        this.feat_database.dataupdate(layer);
        if (this.feat_treeControl.dataNodes[1] && this.feat_treeControl.dataNodes[1].feature) {
            this.feat_treeControl.expand(this.feat_treeControl.dataNodes[0]);
            this.nodeClick(this.feat_treeControl.dataNodes[1], 0);
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }
    }

    public nodeClick(node, clickType) {
        const layerList = [
            {
                feature: node.feature,
                name: node.item,
                layername: node.layername,
                alarmstatus: node.alarmstatus,
            },
        ];
        this.activeNodeItem = node.item;

        switch (clickType) {
            case 1: {
                // Click on the zoom button
                this.featureLocationZoom.emit(layerList);
                break;
            }
            case 2: {
                // Click on the edit button
                this.featureLocationEdit.emit(layerList);
                break;
            }
            case 0: {
                // Click on the node
                this.featureselected.emit(layerList);
                break;
            }
        }
    }

    public clickMonitorLocationDashboard(node) {
        const locationID = node?.feature?.get('LocationID');

        if(locationID) {
            const activeAllFlag = Number(
                this.activatedRoute.snapshot.queryParamMap.get(activeInactiveLocationQueryParam),
            );
            this.router.navigate(['/pages/menuDashboard/viewData'], {
                queryParams: {
                    c: this.customerId,
                    lid: locationID,
                    lt: activeAllFlag || undefined,
                },
                relativeTo: this.activatedRoute,
            });
        }
    }

    public clickMonitorLocationDetails(node) {
        const locationID = node?.feature?.get('LocationID');

        if(locationID) {
            const activeAllFlag = Number(
                this.activatedRoute.snapshot.queryParamMap.get(activeInactiveLocationQueryParam),
            );
            
            this.router.navigate(['/pages/viewLocationDetails'], {
                queryParams: {
                    c: this.customerId,
                    lid: locationID,
                    lt: activeAllFlag || undefined,
                },
                relativeTo: this.activatedRoute,
            });
        }
    }

    public clickMonitorDataExport(node) {
        const locationID = node?.feature?.get('LocationID');

        const settings = {
            lids: [locationID],
            forceApplySettings: true
        }

        this.dialog
            .open(AdsPrismVaultLocationExportComponent, {
                disableClose: true,
                data: settings
            })
            .afterClosed()
            .subscribe((result) => {});
    }

    public async addEditLocation(isEditMode = false, locationDetails = {}) {
        // ensure that rain gauge collection is present
        this.setupRainGaugeLocations();
        const editTritonLocationComponentMapData = <AddLocationModalData>{
            rainGaugeLocations: this.assignedRainGauge,
            customerId: this.customerId,
            editMode: isEditMode,
            isFromCustomerEditor: false,
            locationDetails: locationDetails,
        };

        const dialogOptions: MatDialogConfig = {
            disableClose: true,
            data: editTritonLocationComponentMapData,
            hasBackdrop: false,
        };

        const userSettings = await this.gisService.gisUserSettingsGet();

        if (this.gisService.locationCardPosition) {
            dialogOptions.position = this.locationCardService.checkLocationCardPosition(true, this.gisService.locationCardPosition);
        } else if (userSettings && userSettings.locationPositionX && userSettings.locationPositionY) {
            dialogOptions.position = this.locationCardService.getLocationCardPosition(true, userSettings);
        }

        if (this.mapService.addEditLocationDialog) {
            this.mapService.addEditLocationDialog.close();
        }

        this.mapService.addEditLocationDialog = this.dialog.open(TritonLocationDialogComponent, dialogOptions);
        this.mapService.addEditLocationDialog
            .afterClosed()
            .pipe(first())
            .subscribe((result: boolean) => {
                this.mapService.addEditLocationDialog = null;
                if (result) {
                    this.refreshMap.emit(true);
                }
            });

        // this.mapService.addEditLocationDialog.componentInstance.refreshLocations
        //     .subscribe((location: LocationDetailsModel) => {
        //         // this.refreshLocations(location);
        //     });
        // this.mapService.addEditLocationDialog.afterClosed().subscribe(res => {
        //     // if user add new location successfully, then refresh map.
        //     if (res && res.success) {
        //         // commented the line seems its not required
        //         // this.resetMap.emit(<LocationDetailsModel>{
        //         //   isRefreshLocations: true
        //         // });
        //     }
        // });
    }

    public async addCompositeLocation() {
        // ensure that rain gauge collection is present
        this.setupRainGaugeLocations();

        const dialogOptions: MatDialogConfig = {
            disableClose: true,
            data: this.customerId,
            hasBackdrop: false,
        };

        const userSettings = await this.gisService.gisUserSettingsGet();

        if (this.gisService.locationCardPosition) {
            dialogOptions.position = this.locationCardService.checkLocationCardPosition(false, this.gisService.locationCardPosition);
        } else if (userSettings && userSettings.locationPositionX && userSettings.locationPositionY) {
            dialogOptions.position = this.locationCardService.getLocationCardPosition(false, userSettings);
        }

        if (this.compositeLocationDialog) {
            this.compositeLocationDialog.close();
        }
        this.compositeLocationDialog = this.dialog.open(CompositeLocComponent, dialogOptions);

        this.compositeLocationDialog.afterClosed().subscribe((res) => {
            // if user add new location successfully, then refresh map.
            if (res && res.success) {
                // commented the line seems its not required
                // this.resetMap.emit(<LocationDetailsModel>{
                //   isRefreshLocations: true
                // });
            }
        });
    }

    private setupRainGaugeLocations() {
        // check if rain guage locations are already filled and if so exit immediately
        if (this.assignedRainGauge && this.assignedRainGauge.length > 0) {
            return;
        }

        // check if locations exist
        if (this.locations) {
            // make the filtered array for assignedRainGauge.
            this.assignedRainGauge = this.locations.filter((location) => {
                if (location && location.series && location.series.toLowerCase().indexOf('rainalert') > -1) {
                    return location;
                }
            });
        }
    }

    ngOnDestroy() {
        if (this.mapService.addEditLocationDialog) {
            this.mapService.addEditLocationDialog.close();
            this.mapService.addEditLocationDialog = null;
        }
        this.subscriptions.unsubscribe();
    }
}
