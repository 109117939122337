import { Component, Input } from '@angular/core';
import { UsersService } from 'app/pages/admin/users.service';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import * as Highcharts from 'highcharts';
import { UserSubscription } from 'app/shared/models/notifications';
import { AlarmService } from 'app/shared/services/alarm.service';
import { ActiveAlarm } from 'app/shared/models/active-alarm';
import { map } from 'rxjs/operators';

const highchartsExporting = require('highcharts/modules/exporting.js');
highchartsExporting(Highcharts);
const highchartsExportingOffLine = require('highcharts/modules/offline-exporting.js');
highchartsExportingOffLine(Highcharts);

@Component({
    selector: 'app-alarm-notification',
    templateUrl: 'alarm-notification.component.html',
})
export class AlarmNotificationComponent {
    /**
     * Current customer ID
     */
    @Input() public customerID: number;

    /**
     * A list of user subscriptions to alarm events
     */
    public eventSubscriptions = new Array<UserSubscription>();
    /**
     * A list of subscriptions
     */
    private subscriptions = new Array<Subscription>();

    /**
     * Timer subscription
     */
    private timerSubscription: Subscription;

    constructor(private usersService: UsersService, private alarmService: AlarmService) {}

    /**
     * Method stops subscriptions, including the subscription
     * to the timer to check for active alarms, and clears the lists.
     */
    public stopSubscriptions() {
        if (this.subscriptions && this.subscriptions.length > 0) {
            this.subscriptions.forEach((subscription) => {
                subscription.unsubscribe();
            });
        }
        this.subscriptions = new Array<Subscription>();

        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
    }

    /**
     * Method get notifications subscriptions for a particular customer
     * and calls the method to start receiving new alarms.
     * @param customerId - customer ID
     */
    public getCustomerNotifications(customerId: number): Observable<Array<UserSubscription>> {
        return this.usersService.getAlarmUserPreferenceNotification(customerId).pipe(
            map((response: UserSubscription[]) => {
                if (!response) {
                    return new Array<UserSubscription>();
                }
                return response;
            }),
        );
    }

    /**
     * Method sets active alarm's notification status as processed.
     * @param alarm - active alarm
     */
    private updateAlalrmNotificationStatus(alarm: ActiveAlarm) {
        const updateAlarmStatusSubscription = this.alarmService.updateAlarmNotificationStatus(alarm.alarmID).subscribe(
            () => {
                // To do nothing
            },
            () => {
                this.updateAlalrmNotificationStatus(alarm);
            },
        );
        this.subscriptions.push(updateAlarmStatusSubscription);
    }
}
