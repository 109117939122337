<div>
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.TITLES.' + state.view | translate }}</span>
    </div>
    <div mat-dialog-content>
        <div *ngIf="state.view === 'list' ? state.data : null; let data" class="column">
            <mat-table [dataSource]="data.storms">
                <ng-container matColumnDef="selected">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <mat-checkbox
                            [checked]="element.selected"
                            (change)="stormSelectedChanged($event.checked, element)"
                        ></mat-checkbox>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>{{
                        'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.DESIGN_STORM_NAME' | translate
                    }}</mat-header-cell>
                    <mat-cell *matCellDef="let element" (click)="editStorm(element)" class="storm-item">{{
                        element.name
                    }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="peakRain">
                    <mat-header-cell *matHeaderCellDef
                        >{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.PEAK_RAIN' | translate }} ({{ unitOfMeasure
                        }}{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.HOUR_SUFIX' | translate }})</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">{{ element.peakRain }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="cumulativePeakRain">
                    <mat-header-cell *matHeaderCellDef
                        >{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.CUMULATIVE_PEAK_RAIN' | translate }} ({{
                            unitOfMeasure
                        }})</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">{{ element.cumulativePeakRain }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="totalStormRain">
                    <mat-header-cell *matHeaderCellDef
                        >{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.TOTAL_STORM_RAIN' | translate }} ({{
                            unitOfMeasure
                        }})</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">{{ element.totalStormRain }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="totalEventRain">
                    <mat-header-cell *matHeaderCellDef
                        >{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.TOTAL_EVENT_RAIN' | translate }} ({{
                            unitOfMeasure
                        }})</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">{{ element.totalEventRain }}</mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: columns"></mat-row>
            </mat-table>
        </div>

        <form
            *ngIf="state.view === 'edit' ? state.data : null; let data"
            class="column"
            id="stormEditForm"
            (ngSubmit)="saveStorm()"
        >
            <mat-form-field>
                <input
                    matInput
                    [ngModelOptions]="{ standalone: true }"
                    [ngModel]="data.name.raw"
                    (ngModelChange)="nameChanged($event)"
                    [eitherValidation]="data.name.val"
                    placeholder="{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.DESIGN_STORM_NAME' | translate }}"
                />
                <mat-error *ngIf="data.name.val | asLeft; let error">{{ error.value | translate }}</mat-error>
            </mat-form-field>
            <div class="row items-row">
                <div class="item">
                    <mat-form-field>
                        <input
                            matInput
                            type="number"
                            [ngModelOptions]="{ standalone: true }"
                            [ngModel]="data.peakRain.raw"
                            (ngModelChange)="peakRainChanged($event)"
                            [eitherValidation]="data.peakRain.val"
                            placeholder="{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.PEAK_RAIN' | translate }} ({{
                                unitOfMeasure
                            }}{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.HOUR_SUFIX' | translate }})"
                        />
                        <mat-error *ngIf="data.peakRain.val | asLeft; let error">{{
                            error.value | translate
                        }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="item">
                    <mat-form-field>
                        <input
                            matInput
                            type="number"
                            [ngModelOptions]="{ standalone: true }"
                            [ngModel]="data.cumulativePeakRain.raw"
                            (ngModelChange)="cumulativePeakRainChanged($event)"
                            [eitherValidation]="data.cumulativePeakRain.val"
                            placeholder="{{
                                'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.CUMULATIVE_PEAK_RAIN' | translate
                            }} ({{ unitOfMeasure }})"
                        />
                        <mat-error *ngIf="data.cumulativePeakRain.val | asLeft; let error">{{
                            error.value | translate
                        }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row items-row">
                <div class="item">
                    <mat-form-field>
                        <input
                            matInput
                            type="number"
                            [ngModelOptions]="{ standalone: true }"
                            [ngModel]="data.totalStormRain.raw"
                            (ngModelChange)="totalStormRainChanged($event)"
                            [eitherValidation]="data.totalStormRain.val"
                            placeholder="{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.TOTAL_STORM_RAIN' | translate }} ({{
                                unitOfMeasure
                            }})"
                        />
                        <mat-error *ngIf="data.totalStormRain.val | asLeft; let error">{{
                            error.value | translate
                        }}</mat-error>
                    </mat-form-field>
                </div>
                <div class="item">
                    <mat-form-field>
                        <input
                            matInput
                            type="number"
                            [ngModelOptions]="{ standalone: true }"
                            [ngModel]="data.totalEventRain.raw"
                            (ngModelChange)="totalEventRainChanged($event)"
                            [eitherValidation]="data.totalEventRain.val"
                            placeholder="{{ 'SLIICER.FLOW_TAB.DESIGN_STORMS_DIALOG.TOTAL_EVENT_RAIN' | translate }} ({{
                                unitOfMeasure
                            }})"
                        />
                        <mat-error *ngIf="data.totalEventRain.val | asLeft; let error">{{
                            error.value | translate
                        }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>

    <div mat-dialog-actions *ngIf="state.view === 'list' ? state.data : null; let data">
        <button mat-button (click)="close()">{{ 'SLIICER.COMMON.CANCEL_BUTTON_TITLE' | translate }}</button>
        <button mat-button (click)="new()">{{ 'SLIICER.COMMON.NEW_BUTTON_TITLE' | translate }}</button>
        <button mat-button [disabled]="!data.someSelected" (click)="delete()">
            {{ 'SLIICER.COMMON.DELETE_BUTTON_TITLE' | translate }}
        </button>
        <button mat-button (click)="save()">{{ 'SLIICER.COMMON.SAVE_BUTTON_TITLE' | translate }}</button>
    </div>

    <div mat-dialog-actions *ngIf="state.view === 'edit'">
        <button mat-button (click)="cancelEditStorm()">{{ 'SLIICER.COMMON.CANCEL_BUTTON_TITLE' | translate }}</button>
        <button mat-button type="submit" form="stormEditForm">
            {{ 'SLIICER.COMMON.SAVE_BUTTON_TITLE' | translate }}
        </button>
    </div>
</div>
