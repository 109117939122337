import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'numDisplay',
    pure: false,
})
export class NumDisplayPipe implements PipeTransform {
    // Accepts formgroup object and returns the count of formcontrols required
    public transform(value: number | string) {
        if (!value) {
            return '00';
        }

        return String(value).length > 1 ? value : '0' + String(value);
    }
}
