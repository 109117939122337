import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { UsersService } from 'app/pages/admin/users.service';
import { DEFAULT_FLOW_LOSS_PERCENTAGE,UnitOfMeasureType, Series } from 'app/shared/constant';
import { CustomerService } from 'app/shared/services/customer.service';
import { TritonConfiguration } from 'app/shared/models/monitorsetting';
import { Selectable } from 'app/shared/models/selectable';
import { ECHO_SERIES } from 'app/shared/models/TritonLocation';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { FlowLossAlarmTypes, FlowLossSensitivityList, MonitorSeriesNames } from '../../location-card.constants';
import { LocationCardService } from '../../services/location-card.service';
import { LocationFormBuilder } from '../../services/location-form-builder';
import { MONITOR_SERIES_TYPES } from 'app/shared/models/monitor-series-types';
import { Subject, Subscription } from 'rxjs';
import { HIGH_FLOW_ENABLE, HIGH_FLOW_THRESHOLD } from '../../models/location-card.model';
import { DateutilService } from 'app/shared/services/dateutil.service';

@Component({
    selector: 'app-triton-configure-alarms',
    templateUrl: './triton-configure-alarms.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})
export class TritonConfigureAlarmsComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() isMp2: boolean;
    @Input() monitorSeries: string;
    @Input() monitorConfigs: TritonConfiguration;
    @Input() installationShapeType: any;
    @Input() public monitorSeriesChange: Subject<string>;
    @Input() public installationSeriesChange: Subject<number>;
    @Input() alarmConfigs;

    public flowLossAlarmTypeList: Array<Selectable> = FlowLossAlarmTypes;
    public flowLossAlarmPercentageList: Array<Selectable> = [];
    public flowLossAlarmSensitivityList: Array<Selectable> = FlowLossSensitivityList;
    public isMonitorEditor = false;
    public negativeWithThreeDecimalPattern = REGEX_CONFIG.negativeWithThreeDecimalPattern;
    public numericWith2DecimalPlaces = REGEX_CONFIG.numericWith2DecimalPlaces;
    public numericWith1DecimalPlaces = REGEX_CONFIG.numericWith1DecimalPlaces;
    public IsUnitMetric = true;
    public isElevationInstallation: boolean = false;
    private previousInstallationType: number;

    private feetToInches = 12;
    private metersToMillimeters = 1000;
    public MonitorSeriesNames = MonitorSeriesNames;
    private subscriptions = new Array<Subscription>();

    public CONSTANTS = {
        HIGH_FLOW_ENABLE: HIGH_FLOW_ENABLE,
        HIGH_FLOW_THRESHOLD: HIGH_FLOW_THRESHOLD
    }

    private toggleToInputs: { [key: string]: string[] } = {
        lowLevelEnable: ['lowLevelThreshold'],
        fullPipeEnable: ['fullPipethreshold'],
        highLevelEnable: ['highLevelThreshold'],
        highHighEnable: ['highHighThreshold'],
        highFlowEnable: [HIGH_FLOW_THRESHOLD],
        batteryLowEnable: ['batteryLowThreshold'],
        flowLossAlarm: ['flowLossType', 'percent', 'sensitivity', 'clearPatternHistory'],
        tiltEnable: ['tiltThreshold'],
    };

    public flowUnit: string;
    constructor(
        private locationFormBuilder: LocationFormBuilder, 
        private usersService: UsersService, 
        public locationCardService: LocationCardService, 
        public customerService: CustomerService,
        private dateutilService: DateutilService,
    ) {}

    ngOnInit() {
        this.flowUnit = this.locationCardService.isMetric ? this.dateutilService.peakVolumeUnit.getValue() : this.dateutilService.volumnePerTimeUnit.getValue();
        this.isMonitorEditor = this.usersService.isMonitorEditor.getValue();
        this.setAlarmPercentages();

        const { unitsType } = this.customerService.customer;
        this.IsUnitMetric = unitsType === UnitOfMeasureType.METRIC;

        // Grabbing this for initial load of page, updates after come from the installationSeriesChange subscription.
        this.isElevationInstallation = this.installationShapeType === "Elevation: 1000";
        if (!this.isMp2) {
            this.subscriptions.push(
                this.installationSeriesChange.subscribe((installationType) => {
                    this.onInstallationSeriesChange(installationType);
                })
            );
        }

        this.subscriptions.push(
            this.monitorSeriesChange.subscribe((series) => {
                this.onMonitorSeriesChange(series);
            })
        )

        if (this.monitorConfigs && this.alarmConfigs) {
            this.populateForm();
        }
    }

    onSlideChange(event: MatSlideToggleChange, keys: string[]) {
        this.locationFormBuilder.enableDisableControls(this.form, !event.checked, keys);
    }

    private onInstallationSeriesChange(installationType: number) {
        if (this.alarmConfigs && this.previousInstallationType && installationType !== this.previousInstallationType) {
            installationType === Series.Elevation ? this.isElevationInstallation = true : this.isElevationInstallation = false;

            // if this.isElevationInstallation, convert from in to ft if standard, convert from mm to m if metric
            // if NOT this.isElevationInstallation AND this.previousInstallationType IS 1000 (elevation), then convert from ft to in if standard, m to mm if metric
            if (this.isElevationInstallation && this.previousInstallationType !== Series.Elevation) {

                this.form.patchValue({
                    highHighThreshold: Number((this.form.get('highHighThreshold').value / (this.IsUnitMetric ? this.metersToMillimeters : this.feetToInches)).toFixed(2)),
                    highLevelThreshold: Number((this.form.get('highLevelThreshold').value / (this.IsUnitMetric ? this.metersToMillimeters : this.feetToInches)).toFixed(2)),
                    lowLevelThreshold: Number((this.form.get('lowLevelThreshold').value / (this.IsUnitMetric ? this.metersToMillimeters : this.feetToInches)).toFixed(2)),
                });
            }
            else if (!this.isElevationInstallation && this.previousInstallationType === Series.Elevation) {
                this.form.patchValue({
                    highHighThreshold: Number((this.form.get('highHighThreshold').value * (this.IsUnitMetric ? this.metersToMillimeters : this.feetToInches)).toFixed(2)),
                    highLevelThreshold: Number((this.form.get('highLevelThreshold').value * (this.IsUnitMetric ? this.metersToMillimeters : this.feetToInches)).toFixed(2)),
                    lowLevelThreshold: Number((this.form.get('lowLevelThreshold').value * (this.IsUnitMetric ? this.metersToMillimeters : this.feetToInches)).toFixed(2)),
                });
            }
        }
        this.previousInstallationType = installationType;
    }

    private onMonitorSeriesChange(monitorName: string) {
        if (this.alarmConfigs) {

            if(this.monitorConfigs)
            {
                const value = this.monitorConfigs.devices?.map(a => a.batteryLowThreshold)?.filter(value => value !== undefined);
                if(value && value.length > 0)
                {
                    this.form.patchValue({
                        batteryLowThreshold: value[0],
                    });
                }
            }
            else
            {
                const value = this.alarmConfigs.find(s => s.modelName.toUpperCase() === monitorName.toUpperCase())?.batteryLowInt;
                if(value)
                {
                    this.form.patchValue({
                        batteryLowThreshold: value,
                    });
                }
            }

        }
    }

    private populateForm() {
        Object.keys(this.toggleToInputs).forEach((key: string) => {
            this.locationFormBuilder.enableDisableControls(this.form, false, this.toggleToInputs[key]);
        });
        if (this.monitorConfigs.devices && this.monitorConfigs.devices.length > 0 && this.monitorSeries?.toUpperCase() !== MONITOR_SERIES_TYPES.FORE_SITE_FLEX.toUpperCase()) {
            const deviceName = `Alarm ${this.isMp2 ? 2 : 1}`;
            const flowLossAlarmName = `Flow Loss ${this.isMp2 ? 2 : 1}`;
            const alarmInfo = this.monitorConfigs.devices.find((v) => v.name === deviceName);
            const flowLossInfo = this.monitorConfigs.devices.find((v) => v.name === flowLossAlarmName);

            if (alarmInfo) {
                this.form.patchValue({
                    ...alarmInfo,
                    highLevelThreshold: Math.round(alarmInfo.highLevelThreshold * 10) / 10,
                    lowLevelThreshold: Math.round(alarmInfo.lowLevelThreshold * 10) / 10,
                    fullPipethreshold: alarmInfo.fullPipeThreshold,
                    highHighThreshold: this.addZeroes(alarmInfo.highHighThreshold.toString()),
                });
            }

            if (flowLossInfo) {
                this.form.patchValue({ ...flowLossInfo, flowLossAlarm: true });
            }
        } else {
            const assembledData = this.assembleDataToPopulate(this.monitorConfigs);
            let highHighThreshold: number;

            if (assembledData && assembledData['highHighThreshold']) {
                highHighThreshold = Number(this.addZeroes(assembledData['highHighThreshold'].toString()));
            } else {
                highHighThreshold = this.isMp2 ? 0 : null;
            }
            this.form.patchValue({ ...assembledData, highHighThreshold });

            if (this.monitorSeries === ECHO_SERIES && !this.form.get('tiltEnable')) {
                this.form.addControl('tiltEnable', new FormControl(assembledData['tiltEnable']));
                this.form.addControl(
                    'tiltThreshold',
                    new FormControl({ value: assembledData['tiltThreshold'], disabled: true }, [
                        Validators.required,
                        Validators.pattern(REGEX_CONFIG.installationValuePattern),
                    ]),
                );
            }
        }

        Object.keys(this.toggleToInputs).forEach((key: string) => {
            this.locationFormBuilder.enableDisableControls(this.form, !this.form.value[key], this.toggleToInputs[key]);
        });
    }

    public addZeroes(num) {
        const value = Number(num);
        let returnVal: string;
        const res = num.split(".");
        if(res.length >= 1) {
            returnVal = this.IsUnitMetric ? value.toFixed(1) : value.toFixed(2);
        }

        return returnVal;
    }

    private assembleDataToPopulate(config: TritonConfiguration) {
        const keys = [
            'lowLevelEnable',
            'lowLevelThreshold',
            'fullPipeEnable',
            'highLevelEnable',
            'highLevelThreshold',
            'highHighEnable',
            'highHighThreshold',
            HIGH_FLOW_ENABLE,
            HIGH_FLOW_THRESHOLD,
            'overflowEnable',
            'tiltEnable',
            'tiltThreshold',
            'batteryLowEnable',
            'batteryLowThreshold',
            'tiltEnable',
            'tiltThreshold',
        ];

        return keys.reduce((acc, curr) => ({ ...acc, [curr]: config[curr.toLowerCase()] }), { ...config });
    }

    private setAlarmPercentages() {
        for (let i = 20; i <= 80; i = i + 5) {
            this.flowLossAlarmPercentageList.push({
                id: i,
                name: String(i),
                isChecked: i === DEFAULT_FLOW_LOSS_PERCENTAGE,
            });
        }
    }
}
