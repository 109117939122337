import { Component, ViewEncapsulation, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';
import { Subscription } from 'rxjs';

import { VaultService } from '../vault.service';

import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { StringUtils } from 'app/shared/utils/string-utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-vault-create-folder',
    templateUrl: './vault-create-folder.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})
export class VaultCreateFolderComponent implements OnInit, OnDestroy {
    /**
     * Represents the selected directory
     */
    public currentlySelectedDirectoryId: string;

    /**
     * Represents the input folder name from UI
     */
    public folderName: string;

    /**
     * Represents the preloader display
     */
    public isCreatingFolder: boolean;

    /**
     * Represents the subscription to the Change observables.
     */
    private subscriptions = new Array<Subscription>();

    /**
     * Reg Ex. Pattern for alpha numeric with -
     */
    public vaultFolderNamePattern = REGEX_CONFIG.vaultFolderNamePattern;

    /**
     * Represents new folder name to be created exist or not
     */
    public isFolderNameExist = false;

    public createdSuccessfullyMsg: string;
    public folderString: string;
    public dismissBtnText: string;

    constructor(
        private dialogRef: MatDialogRef<VaultCreateFolderComponent>,
        private dialog: MatDialog,
        private vaultService: VaultService,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
    ) {
        translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismissBtnText = res;
        });
        translate.get('VAULT.VAULT_CREATE_FOLDER.Folder_TEXT').subscribe((res: string) => {
            this.folderString = res;
        });
        translate.get('VAULT.VAULT_CREATE_FOLDER.CREATED_SUCCESSFULLY').subscribe((res: string) => {
            this.createdSuccessfullyMsg = res;
        });
    }

    /**
     * Lifecycle hook that is called after data-bound properties of a directive are initialized
     */
    public ngOnInit() {
        // setup observable for directory change notifications
        const subscription = this.vaultService.CurrentDirectory.subscribe((result: string) => {
            this.currentlySelectedDirectoryId = result;
        });
        this.subscriptions.push(subscription);
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /**
     * Close Dialog
     */
    public close() {
        this.dialogRef.close({ success: false });
    }

    /**
     * capitalized the string value with one space in between word
     */
    public capitalizedStringWithSpace() {
        this.folderName = StringUtils.capitalizedStringWithSpace(this.folderName);
    }

    /**
     * Event Handler for Create Folder Button in Modal
     */
    public createFolder() {
        if (!this.folderName) {
            return;
        }

        this.folderName = this.folderName.charAt(0) === '/' ? this.folderName.substr(1) : this.folderName;
        const folderName: string = this.currentlySelectedDirectoryId + this.folderName;

        this.isFolderNameExist = this.vaultService.isFolderNameExist(folderName);
        if (this.isFolderNameExist) {
            return;
        }
        this.isCreatingFolder = true;
        const createSubscription = this.vaultService.createNewFolder(folderName.substr(1)).subscribe({
            next: () => {
                // get the latest vault data for current customer and stop preloader
                const rootVaultSubscription = this.vaultService.getRootVaultListForCustomer().subscribe(() => {
                    this.isCreatingFolder = false;
                });
                this.subscriptions.push(rootVaultSubscription);

                // display snackbar with success message
                const msg = this.folderString + folderName.substr(1) + this.createdSuccessfullyMsg;
                this.snackBar.open(msg, this.dismissBtnText, <MatSnackBarConfig>{ duration: 10000 });

                this.dialogRef.close({ success: false });
            },
            error: (err) => {
                this.isCreatingFolder = false;
            },
        });

        this.subscriptions.push(createSubscription);
    }
}
