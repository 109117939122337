import { Injectable } from '@angular/core';
import { Config } from 'app/shared/services/config';
import { EMPTY, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Attachment, Email } from '../models/email';
import { map } from 'rxjs/operators';

@Injectable()
export class EmailService {
    constructor(private http: HttpClient) {}

    public sendEmail(email: Email, attachment?: Attachment) {
        const params = <Email>{
            FromAddress: email.FromAddress,
            ToAddress: email.ToAddress,
            BccAddress: email.BccAddress,
            CcAddress: email.CcAddress,
            Subject: email.Subject,
            Content: email.Content,
        };

        // creating HttpParams for HttpClient request
        let httpParams: HttpParams = new HttpParams();

        // setting all the existing key value of param to HttpParams
        Object.keys(params).forEach(function (key) {
            if (params[key] !== undefined) {
                httpParams = httpParams.append(key, params[key]);
            }
        });

        return this.http.post(Config.urls.email, attachment, { params: httpParams }).pipe(
            map(
                (result) => {
                    return result;
                },
                (error) => {
                    return EMPTY;
                },
            ),
        );
    }
}
