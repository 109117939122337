import { Injectable } from '@angular/core';
import { Config } from './config';

import { DateutilService } from './dateutil.service';
import { HttpClient } from '@angular/common/http';
import { BlockagePredictionResults, BlockagePredictionSpanArgs } from '../models/blockage-prediction';
import { map } from 'rxjs/operators';

@Injectable()
export class BlockagePredictionService {
    public widgetDetails: BlockagePredictionResults;
    public tilePredictionCount: number;

    constructor(public http: HttpClient, private dateutilService: DateutilService) {}

    public getWidgetBlockagePredictionResults(params: BlockagePredictionSpanArgs) {
        const postData: any = {};

        postData.start = !params.start ? this.dateutilService.getPreviousDate(new Date(), 1) : params.start;
        postData.end = !params.end ? this.dateutilService.getPreviousDate(new Date(), 1) : params.end;
        postData.index = !params.index ? 0 : params.index;
        postData.pageSize = !params.pageSize ? 10 : params.pageSize;

        if (params.locationId && params.locationId[0] > 0) {
            postData.locationId = params.locationId;
        }

        if (params.locationGroupId && params.locationGroupId > 0) {
            postData.locationGroupId = params.locationGroupId;
        }

        if (params.blockageStatus !== undefined && params.blockageStatus.length > 0) {
            postData.blockStatus = params.blockageStatus;
        }

        postData['includeInactiveLocations'] = !!params.includeInactiveLocations;

        const url = Config.urls.blockagePrediction + '?customerId=' + params.customerId;

        return this.http.post<BlockagePredictionResults>(url, postData).pipe(
            map((res) => {
                if (res) {
                    this.widgetDetails = res;
                    return this.widgetDetails;
                }
                return [];
            }),
        );
    }

    // get the tile count for blockage-prediction.
    // This is for the tile which _always_ shows the probable blockages for yesterday
    // (local time)
    public getTileBlockagePredictionCount(customerId: number, locationGroupId?: number) {
        const yesterday = this.dateutilService.getPreviousDate(new Date(), 1);
        let url = Config.urls.blockagePredictionCount + '?customerId=' + customerId + '&date=' + yesterday;
        if (locationGroupId) {
            url += '&locationGroupId=' + locationGroupId;
        }

        return this.http.get<any>(url).pipe(
            map((res) => {
                this.tilePredictionCount = res;
                return this.tilePredictionCount;
            }),
        );
    }
}
