<div class="setUpStudy study-tab">
    <div class="custom-vertical-tab setting-page">
        <mat-tab-group class="ink-bar-none" #tabs>
            <mat-tab
                [disabled]="updatesWidgetService.updates|| isTabLocked"
                label="{{ 'SLIICER_TABLE.SLICER_SUMMARY.DRY_DAYS' | translate }}"
            >
                    <ads-dry-day-settings
                        #dryDaysSettings
                        [customerId]="customerId"
                        [isStudyLocked]="isStudyLocked"
                        [shouldUpdate]="shouldUpdate"
                        [changesAction]="changesAction"
                        [originalDryDayDefinition]="originalDryDayDefinition"
                        (checkChanges)="setUpdatesInfo()"
                    >
                    </ads-dry-day-settings>
            </mat-tab>
            <mat-tab
                [disabled]="updatesWidgetService.updates|| isTabLocked"
                label="{{ 'SLIICER_TABLE.SLICER_SUMMARY.BASE_INFILTRATION' | translate }}"
            >
                    <ads-waste-water-settings
                        #wasteWaterSettings
                        [customerId]="customerId"
                        [isStudyLocked]="isStudyLocked"
                        [shouldUpdate]="shouldUpdate"
                        [changesAction]="changesAction"
                        [originalWastewaterBaseInfiltration]="originalWastewaterBaseInfiltration"
                        (checkChanges)="setUpdatesInfo()"
                    >
                    </ads-waste-water-settings>
            </mat-tab>
            <mat-tab
                [disabled]="updatesWidgetService.updates|| isTabLocked"
            >
                <ng-template mat-tab-label>
                    <span [ngClass]="{'tab-error': tabsError.dayOfWeekGroups}">{{ 'SLIICER_TABLE.SLICER_SUMMARY.DAY_OF_WEEK_GROUPS' | translate }}</span>
                </ng-template>
                    <ads-week-groups-settings
                        #weekGroupsSettings
                        [customerId]="customerId"
                        [caseStudyId]="caseStudyId"
                        [isStudyLocked]="isStudyLocked"
                        [shouldUpdate]="shouldUpdate"
                        [changesAction]="changesAction"
                        [originalDayGroups]="originalDayGroups"
                        (checkChanges)="setUpdatesInfo()"
                    >
                    </ads-week-groups-settings>
            </mat-tab>
            <mat-tab
                [disabled]="updatesWidgetService.updates|| isTabLocked"
            >
                <ng-template mat-tab-label>
                    <span [ngClass]="{'tab-error': tabsError.stormSettings}">{{ 'SLIICER_TABLE.SLICER_SUMMARY.STORM_EVENTS' | translate }}</span>
                </ng-template>
                    <ads-storm-settings
                        #stormSettings
                        [customerId]="customerId"
                        [isStudyLocked]="isStudyLocked"
                        [shouldUpdate]="shouldUpdate"
                        [changesAction]="changesAction"
                        [originalStormDefinition]="originalStormDefinition"
                        (checkChanges)="setUpdatesInfo()"
                    >
                    </ads-storm-settings>
            </mat-tab>
            <mat-tab
                [disabled]="updatesWidgetService.updates|| isTabLocked"
                class="ink-bar-none"
                label="{{ 'SLIICER_TABLE.SLICER_SUMMARY.DEFAULT_PRECOMPOSITION' | translate }}"
            >
                    <ads-precompensation-settings
                        #precompositionSettings
                        [isStudyLocked]="isStudyLocked"
                        [shouldUpdate]="shouldUpdate"
                        [changesAction]="changesAction"
                        [selectedPrecompType]="originalPrecompType"
                        [originalPrecompType]="originalPrecompType"
                        (checkChanges)="setUpdatesInfo()"
                    >
                    </ads-precompensation-settings>
            </mat-tab>
            <mat-tab
                [disabled]="updatesWidgetService.updates || isTabLocked"
            >
                <ng-template mat-tab-label>
                    <span [ngClass]="{'tab-error': tabsError.seasonsSettings}">{{ 'SLIICER_TABLE.SLICER_SUMMARY.SEASONS' | translate }}</span>
                </ng-template>
                <ads-seasons-settings
                    #seasonsSettings
                    [isStudyLocked]="isStudyLocked"
                    [shouldUpdate]="shouldUpdate"
                    [changesAction]="changesAction"
                    [caseStudyDetails]="caseStudyDetails"
                    [originalSeasonsDefinition]="originalSeasonsDefinition"
                    (checkChanges)="setUpdatesInfo()"
                >
                </ads-seasons-settings>
            </mat-tab>
            <mat-tab
                [disabled]="updatesWidgetService.updates"

            >
            <ng-template mat-tab-label>
                <span [ngClass]="{'tab-error': tabsError.regimeSettings}">{{ 'SLIICER.EDIT_SETTINGS.REGIMES.TAB_TITLE' | translate }}</span>
            </ng-template>

                <app-regime-settings
                    [isStudyLocked]="isStudyLocked"
                    [caseStudyDetails]="caseStudyDetails"
                    [changesAction]="changesAction"
                    (checkChanges)="setUpdatesInfo()"
                >
                </app-regime-settings>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
