import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface YesNoDialogData {
    title: string;
    message: string;
}
@Component({
    selector: 'app-confirm-dialog',
    template:
        '<div style="max-width: 55vw;"><div mat-dialog-title>{{data.title | translate}}</div><div mat-dialog-content>{{data.message | translate}}</div></div><mat-dialog-actions><button mat-button (click)="close(true)">{{"COMMON.YES" | translate}}</button><button mat-button (click)="close(false)">{{"COMMON.NO" | translate}}</button></mat-dialog-actions>',
})
export class YesNoDialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: YesNoDialogData,
        public dialogRef: MatDialogRef<YesNoDialogComponent>,
    ) {}

    public close(ok: boolean) {
        this.dialogRef.close(ok);
    }
}
