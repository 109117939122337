import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GraphScale } from 'app/pages/view-data/graphs/graph.model';
import { HydroGraphComponent } from 'app/shared/components/hydro-graph/hydro-graph.component';
import { LocationService } from 'app/shared/services/location.service';
import * as Highcharts from 'highcharts';
import { Subscription } from 'rxjs';

import { StatusCodeService } from '../../../shared/services/status-code.service';
import { UiUtilsService } from '../../../shared/utils/ui-utils.service';
import { UnitOfMeasureType } from 'app/shared/constant';
import { StringUtils } from 'app/shared/utils/string-utils';
import { CustomerService } from 'app/shared/services/customer.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { FlowBalanceDetails } from 'app/shared/models/location-details';
import { ListItem } from 'app/shared/models/selected-Item';
import { AppQueryParams, Customer, customerQueryParam, locationIdQueryParam, customerLocationGroupQueryParam } from 'app/shared/models/customer';
import { MathUtils } from 'app/shared/utils/math-utils';

const TOTAL = 'Total Rain';
const RAIN = 'Rain';
const QFINAL = 'QFinal';
const COMPOSITES = 'Composite';
const NET = 'Net';
const DS = 'D/S';
const AVERAGES = 'Averages';
const MINIMUMS = 'Minimums';
const MAXIMUMS = 'Maximums';
const DATE = 'Date';
const METRIC = 2;
const MGD_METRIC = 3;
const MISSING_DATA = -99999;
const MISSING_DATA_DECIMAL = -99999.0;

@Component({
    selector: 'app-location-flow-balance-report',
    templateUrl: './location-flow-balance-report.component.html',
    styleUrls: ['location-flow-balance-report.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationFlowBalanceReportComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('hydroGraphChart') public hydroGraphChart: HydroGraphComponent;
    public startDate: Date;
    public endDate: Date;
    public displayStartDateErrMsg: boolean;
    public displayEndDateErrMsg: boolean;
    public invalidDateRange: boolean;
    public invalidStartDate: boolean;
    public invalidEndDate: boolean;
    public isCustomDateTouched: boolean;
    public customerId: number;
    public locationId: number;
    public locationGroupId : number;
    public locationName: string;
    public flowBalanceData = new Array<FlowBalanceDetails>();
    public isLoading = true;
    public graphLoadingState: boolean;
    public flowBalanceReportData: any;
    public url: string;
    public isPrismLogoVisible: boolean;
    public printContents: string;
    public tooltipTimeFormat = '';
    public xAxisDateFormat = '';
    public unitOfMeasure = 'in';
    public metricUnitsFromJson: string;
    public usUnitsFromJson: string;
    public reportData = new Array<any>();
    public flowBalanceColumns = new Array<ListItem>();
    public rowColumns = new Array<string>();
    public flowBalanceDataSource: MatTableDataSource<any>;
    public isDataAvailable = true;
    public showGraph = true;
    private customerUnitType = 0;
    private isMetric = true;
    public minYAxis: number;
    // subscriptions to unsubcribe on component destroy
    private subscriptions: Array<Subscription> = [];
    public translateKeys: Array<string> = [
        'HOME.MAP.MARKER_LOCATION_DETAIL.METRIC_UNITS',
        'HOME.MAP.MARKER_LOCATION_DETAIL.FLOW_UNITS',
        'HOME.MAP.MARKER_LOCATION_DETAIL.US_UNITS',
        'HOME.MAP.MARKER_LOCATION_DETAIL.QFLOW_UNITS',
        'HOME.MAP.MARKER_LOCATION_DETAIL.MGD_FLOW_UNITS',
        'HOME.MAP.MARKER_LOCATION_DETAIL.CFS_TRACER_UNITS',
        'HOME.MAP.MARKER_LOCATION_DETAIL.MGD_TRACER_UNITS',
        'HOME.MAP.MARKER_LOCATION_DETAIL.FLOW_TRACER_UNITS',
    ];
    public customerUnit: string;
    public flowUnitsFromJson: string;
    public qfinalUnitsFromJson: string;
    public mgdFinalUnitsFromJson: string;
    public cfsUnitsFromJson: string;
    public mgdUnitsFromJson: string;
    public lsUnitsFromJson: string;
    public tracerUnits: string;
    public utcGraphDate: number;
    public graphEndDate: Date;
    public range: GraphScale = new GraphScale();
    public maxXAxis: number;

    constructor(
        private uiUtilsService: UiUtilsService,
        private cdr: ChangeDetectorRef,
        private customerService: CustomerService,
        private translate: TranslateService,
        private dateutilService: DateutilService,
        private locationService: LocationService,
        private activatedRoute: ActivatedRoute,
        private statusCodeService: StatusCodeService,
        private router: Router
    ) {
        this.translate.get(this.translateKeys).subscribe((translateValues) => {
            this.metricUnitsFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.METRIC_UNITS'];
            this.flowUnitsFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.FLOW_UNITS'];
            this.usUnitsFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.US_UNITS'];
            this.qfinalUnitsFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.QFLOW_UNITS'];
            this.mgdFinalUnitsFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.MGD_FLOW_UNITS'];
            this.cfsUnitsFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.CFS_TRACER_UNITS'];
            this.mgdUnitsFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.MGD_TRACER_UNITS'];
            this.lsUnitsFromJson = translateValues['HOME.MAP.MARKER_LOCATION_DETAIL.FLOW_TRACER_UNITS'];
        });
    }

    public ngOnInit() {
        this.setStartEndDatesDefaultValues();
        this.activatedRoute.queryParamMap.subscribe(
            (params: ParamMap) => {
                this.customerId = Number(params.get(customerQueryParam));
                this.locationId = Number(params.get(locationIdQueryParam));
                this.locationGroupId = Number(params.get(customerLocationGroupQueryParam));
                this.graphEndDate = new Date(new Date(this.endDate).setHours(23, 59, 59));
                const convertedGraphEndDate = new Date(
                    this.graphEndDate.getTime() - this.graphEndDate.getTimezoneOffset() * 60000,
                );
                this.utcGraphDate = Date.UTC(
                    convertedGraphEndDate.getFullYear(),
                    convertedGraphEndDate.getMonth(),
                    convertedGraphEndDate.getDate(),
                    convertedGraphEndDate.getHours(),
                    convertedGraphEndDate.getMinutes(),
                    convertedGraphEndDate.getSeconds(),
                );

                this.customerService.getCustomerById(this.customerId).subscribe((response: Customer) => {
                    // checking units are metric or non metric
                    if (Number(response.unitsType) === MGD_METRIC) {
                        this.customerUnit = `(${this.mgdFinalUnitsFromJson})`;
                        this.tracerUnits = `(${this.mgdUnitsFromJson})`;
                        this.unitOfMeasure = `(${this.usUnitsFromJson})`;
                    } else {
                        this.isMetric = response.unitsType && Number(response.unitsType) === METRIC;
                        this.customerUnit = `(${this.isMetric ? this.flowUnitsFromJson : this.qfinalUnitsFromJson})`;
                        this.tracerUnits = `(${this.isMetric ? this.lsUnitsFromJson : this.cfsUnitsFromJson})`;
                        this.unitOfMeasure = `(${this.isMetric ? this.metricUnitsFromJson : this.usUnitsFromJson})`;
                    }
                    this.customerUnitType = Number(response.unitsType);
                });
                this.statusCodeService.enableGlobalDropDowns.next(true);
            },
            (error) => {
                // error block
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
        // subscribe to changes in dateFormat
        const subscription = this.dateutilService.dateFormat.subscribe(() => {
            // Getting customer dateformat from dateUtil Service
            this.xAxisDateFormat = this.dateutilService.getGraphDateFormat();
            const timeSubscription = this.dateutilService.timeFormat.subscribe((value) => {
                this.tooltipTimeFormat = value;
            });
            this.subscriptions.push(timeSubscription);
        });
        this.subscriptions.push(subscription);
    }

    private setStartEndDatesDefaultValues() {
        const oneDay = 1000 * 60 * 60 * 24;

        this.startDate = new Date(new Date().getTime() - oneDay * 7);
        this.startDate.setHours(0, 0, 0, 0);

        this.endDate = new Date();
        this.endDate.setHours(23, 59, 59);
    }

    public ngAfterViewInit() {
        this.getFlowBalanceData();
    }

    public ngOnDestroy() {
        this.statusCodeService.enableGlobalDropDowns.next(false);
    }

    public dateChanged($event) {
        if ($event) {
            this.startDate = new Date($event.startDate);
            this.graphEndDate = new Date(new Date($event.endDate).setHours(23, 59, 59));

            this.getFlowBalanceData();
        }
    }
    public redirectToFlowBalance() {
        this.statusCodeService.redirectedFrom.subscribe((response: string) => {
            this.url =
                response && response === 'flowBalance' ? '/pages/report/flowBalance' : '/pages/viewLocationDetails';
        });
        this.router.navigate([this.url], {
            queryParams: <AppQueryParams>{
                c: this.customerId,
                lid: this.locationId,
                lg : this.locationGroupId
            },
            relativeTo: this.activatedRoute,
        });
    }

    // Used to get flow balance data from the api
    public getFlowBalanceData() {
        this.setFlagVariables(true);
        this.locationService.getFlowBalance(this.customerId, this.locationId).subscribe(
            (result) => {
                if (result) {
                    this.locationName = result.find((x) => x.flowType === 0).flowBalanceLocation.toString();
                    this.flowBalanceData = result.filter((x) => x.flowType !== 0);
                    this.getFlowBalanceReport();
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }
            },
            (error) => {
                // empty block
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
    }

    public selectedStartDate($event) {
        this.startDate = $event;
    }

    public selectedEndDate($event) {
        this.graphEndDate = new Date(new Date($event).setHours(23, 59, 59));
    }

    // Used to get flow balance report data from the api
    public getFlowBalanceReport() {
        const flowBalances = [];
        this.flowBalanceData.forEach((location) => {
            flowBalances.push({
                locationId: location.flowBalanceLocationId,
                flowType: location.flowType,
            });
        });

        const flowBalanceReportArgs = {
            parentLocationId: this.locationId,
            flowBalances: [...flowBalances],
            startDate: this.dateutilService.getEndDateAsTimeZone(this.startDate),
            endDate: this.dateutilService.getEndDateAsTimeZone(this.graphEndDate),
        };

        this.locationService.getFlowBalanceReport(this.customerId, flowBalanceReportArgs).subscribe(
            (result) => {
                if (result && result.data.length) {
                    this.isLoading = false;
                    this.isDataAvailable = true;
                    this.flowBalanceReportData = Object.assign({}, result);

                    this.flowBalanceColumns = [];
                    this.flowBalanceColumns.push(
                        { id: 'date', text: DATE },
                        { id: 'rain', text: `${RAIN} ${this.unitOfMeasure}` },
                        { id: 'ds', text: `${DS} ${this.customerUnit}` },
                        { id: 'net', text: `${NET} ${this.customerUnit}` },
                        { id: 'composite', text: `${COMPOSITES} ${this.customerUnit}` },
                    );

                    this.flowBalanceReportData.sites.forEach((site) => {
                        let siteHeader: string;
                        this.flowBalanceData.forEach((location) => {
                            if (location.flowBalanceLocation === site) {
                                siteHeader =
                                    location.flowType === 1
                                        ? `${site} (+) ${this.customerUnit}`
                                        : `${site} (-) ${this.customerUnit}`;
                            }
                        });
                        this.flowBalanceColumns.push({
                            id: site,
                            text: siteHeader,
                        });
                    });

                    const sitesData = this.flowBalanceReportData.siteData;
                    const reportSummary = this.flowBalanceReportData.dataSummary;
                    const rainPrecision = this.unitOfMeasure === `(${this.usUnitsFromJson})` ? 2 : 1;
                    const cubicFeetPrecision = this.customerUnitType === UnitOfMeasureType.CFS ? 0 : 3;
                    // Flow Balance Report Data Grid Code
                    const dates = StringUtils.applyUniqueAndSort<any>(reportSummary, 'date', 'date', true);
                    const source = dates.map((element) => {
                        const items =
                            reportSummary.filter(
                                (x) => x.date === element.date && !this.isValueMissing(element.value),
                            ) || [];

                        const item = {
                            date: this.dateutilService.getUTCDate(new Date(element.date)).toDateString(),
                            rain: "-",
                            ds: "-",
                            net: "-",
                            composite: "-",
                        };
                        items.forEach((i) => {
                            if (!this.isValueMissing(i.value)) {
                                if (i.seriesName === DS) {
                                    item['ds'] = Number(i.value || 0).toFixed(cubicFeetPrecision);
                                } else if (i.seriesName === NET) {
                                    item['net'] = Number(i.value || 0).toFixed(cubicFeetPrecision);
                                } else if (i.seriesName === COMPOSITES) {
                                    item['composite'] = Number(i.value || 0).toFixed(cubicFeetPrecision);
                                } else if (i.seriesName === RAIN) {
                                    item['rain'] = Number(i.value || 0).toFixed(rainPrecision);
                                }
                            }
                        });

                        this.flowBalanceReportData.sites.forEach((site) => {
                            const siteData = sitesData.find(
                                (x) =>
                                    x.timeStamp === element.date &&
                                    x.locationName === site &&
                                    x.locationId !== this.locationId &&
                                    !this.isValueMissing(x.reading),
                            );
                            if (siteData) {
                                item[site] =
                                    siteData.reading === 0
                                        ? siteData.reading
                                        : siteData.reading.toFixed(cubicFeetPrecision);
                            } else {
                                item[site] = "-";
                            }
                        });
                        return item;
                    });

                    // prepare aggregate summary for mat result
                    this.rowColumns = this.flowBalanceColumns.map((m) => String(m.id));
                    const commonItem = { date: '', rain: '', ds: '', net: '', composite: '' };
                    const totalItem = Object.create(commonItem);
                    const averageItem = Object.create(commonItem);
                    const minimumItem = Object.create(commonItem);
                    const maximumItem = Object.create(commonItem);

                    const siteAggregate = this.flowBalanceReportData.aggregateData;
                    totalItem.rain = (0).toFixed(rainPrecision);

                    const dsConverted = DS.toUpperCase();
                    const netConverted = NET.toUpperCase();
                    const compConverted = COMPOSITES.toUpperCase();
                    const rainConverted = RAIN.toUpperCase();
                    // tslint:disable-next-line: cyclomatic-complexity
                    siteAggregate.forEach((aggr) => {
                        const aggrSeries = aggr.series.toUpperCase();
                        if (aggr.series && aggrSeries === dsConverted) {
                            averageItem.ds =
                                aggr.average === 0 || this.isValueMissing(aggr.average)
                                    ? 0
                                    : aggr.average.toFixed(cubicFeetPrecision);
                            minimumItem.ds =
                                aggr.minimum === 0 || this.isValueMissing(aggr.minimum)
                                    ? 0
                                    : aggr.minimum.toFixed(cubicFeetPrecision);
                            maximumItem.ds =
                                aggr.maximum === 0 || this.isValueMissing(aggr.maximum)
                                    ? 0
                                    : aggr.maximum.toFixed(cubicFeetPrecision);
                        } else if (aggr.series && aggrSeries === netConverted) {
                            averageItem.net =
                                aggr.average === 0 || this.isValueMissing(aggr.average)
                                    ? 0
                                    : aggr.average.toFixed(cubicFeetPrecision);
                            minimumItem.net =
                                aggr.minimum === 0 || this.isValueMissing(aggr.minimum)
                                    ? 0
                                    : aggr.minimum.toFixed(cubicFeetPrecision);
                            maximumItem.net =
                                aggr.maximum === 0 || this.isValueMissing(aggr.maximum)
                                    ? 0
                                    : aggr.maximum.toFixed(cubicFeetPrecision);
                        } else if (aggr.series && aggrSeries === compConverted) {
                            averageItem.composite =
                                aggr.average === 0 || this.isValueMissing(aggr.average)
                                    ? 0
                                    : aggr.average.toFixed(cubicFeetPrecision);
                            minimumItem.composite =
                                aggr.minimum === 0 || this.isValueMissing(aggr.minimum)
                                    ? 0
                                    : aggr.minimum.toFixed(cubicFeetPrecision);
                            maximumItem.composite =
                                aggr.maximum === 0 || this.isValueMissing(aggr.maximum)
                                    ? 0
                                    : aggr.maximum.toFixed(cubicFeetPrecision);
                        } else if (aggr.series && aggrSeries === rainConverted) {
                            totalItem.rain =
                                aggr.total === 0 || this.isValueMissing(aggr.total)
                                    ? 0
                                    : aggr.total.toFixed(rainPrecision);
                        }
                    });
                    this.flowBalanceReportData.siteAggregateData.forEach((site) => {
                        if (site.series !== this.locationName) {
                            totalItem[site.series] = '';
                            averageItem[site.series] =
                                site.average === 0 ? site.average : site.average.toFixed(cubicFeetPrecision);
                            minimumItem[site.series] =
                                site.minimum === 0 ? site.minimum : site.minimum.toFixed(cubicFeetPrecision);
                            maximumItem[site.series] =
                                site.maximum === 0 ? site.maximum : site.maximum.toFixed(cubicFeetPrecision);
                        }
                    });

                    // aggregate values
                    totalItem.date = TOTAL;
                    averageItem.date = AVERAGES;
                    minimumItem.date = MINIMUMS;
                    maximumItem.date = MAXIMUMS;

                    source.push(totalItem, averageItem, minimumItem, maximumItem);
                    this.flowBalanceDataSource = new MatTableDataSource(source);
                    this.uiUtilsService.safeChangeDetection(this.cdr);

                    // plot flow balance hydrograph
                    this.plotFlowBalanceHydroGraph(
                        this.flowBalanceReportData,
                        this.xAxisDateFormat,
                        this.chartToolTipTimeFormat(),
                        this.tracerUnits,
                    );
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                } else {
                    this.setFlagVariables(false);
                }
            },
            () => {
                this.setFlagVariables(false);
            },
        );
    }

    public generatePDF() {

        setTimeout(() => {
            this.hydroGraphChart.chartElement.setSize(1400, 400, false);
        }, 1000);

        setTimeout(() => window.print(), 1000);
        setTimeout(() => this.hydroGraphChart.redrawChart(), 1000);
        
    }

    private setFlagVariables(flag: boolean) {
        this.isLoading = flag;
        this.showGraph = flag;
        this.isDataAvailable = flag;
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    private plotFlowBalanceHydroGraph(
        graphData,
        xAxisDateFormat: string,
        tooltipTimeFormat: string,
        tracerUnits: string,
    ) {
        const seriesData = [];
        const startOffDate = this.dateutilService.getStartDateAsTimeZone(this.startDate);
        const endOffDate = this.dateutilService.getStartDateAsTimeZone(this.graphEndDate);
        const xAxisSettings = this.prepareXAxisLabels(new Date(startOffDate), new Date(endOffDate));
        graphData.series
            .filter((s) => s !== DS && s !== NET)
            .forEach((s) => {
                let points = graphData.data
                    .filter((d) => d.seriesName && d.seriesName === s)
                    .map((m) => {
                        if (m.ignore) return undefined;
                        return [
                            new Date(m.timeStamp).getTime(),
                            m.reading,
                        ];
                    })
                    .filter(d => d); // Take out any undefined values (aka ignored points)
                // adding first point as 0 to make sure chart starts with correct start date and not the first point in case of missing data
                const startTimestamp = this.startDate.getTime();
                if (points.length > 0 && points[0][0] >= startTimestamp) {
                    const p = [
                        [this.startDate.getTime(), 0],
                        [points[0][0] + 1, 0],
                    ];
                    points = p.concat(points);
                }

                seriesData.push({
                    name: s === QFINAL ? this.locationName : s,
                    type: s === RAIN ? 'column' : 'line',
                    data: points || [],
                    gapUnit: 'value',
                    gapSize: 1000 * 60 * 60 * 2, // 2 hours
                    yAxis: s === RAIN ? 1 : 0,
                    color: s === RAIN ? '#ffde03' : s === COMPOSITES ? '#9C27B0' : '#0083ff',
                    unit: s === RAIN ? this.unitOfMeasure : tracerUnits,
                    precisions: this.unitOfMeasure === `(${this.usUnitsFromJson})` ? 2 : 0,
                    precision:
                        tracerUnits === `(${this.mgdUnitsFromJson})`
                            ? 3
                            : tracerUnits === `(${this.cfsUnitsFromJson})`
                            ? 3
                            : tracerUnits === `(${this.lsUnitsFromJson})`
                            ? 1
                            : 2,
                });
            });
        const self = this;
        this.minYAxis = MathUtils.getMin([...graphData.data.map((x) => x.reading)]);
        this.maxXAxis = MathUtils.getMax(
            [...graphData.data.map((x) => new Date(this.dateutilService.getStartDateAsTimeZone(x.timeStamp)).getTime())],
        );

        // chart configuration
        this.hydroGraphChart.chartType = 'line';
        this.hydroGraphChart.chartSeriesData = seriesData;
        this.hydroGraphChart.chartRangeSelectorOption = {
            selected: false,
            inputEnabled: false,
            allButtonsEnabled: true,
            buttons: [
                {
                    type: 'day',
                    count: 1,
                    text: '1d',
                    events: {
                        click: function () {
                            self.onXAxisZoomChange(event, self, false);
                        },
                    },
                    tooltip: '1 Day',
                },
                {
                    type: 'week',
                    count: 1,
                    text: '1w',
                    events: {
                        click: function () {
                            self.onXAxisZoomChange(event, self, false);
                        },
                    },
                    tooltip: '1 Week',
                },
                {
                    type: 'month',
                    count: 1,
                    text: '1m',
                    events: {
                        click: function () {
                            self.onXAxisZoomChange(event, self, false);
                        },
                    },
                    tooltip: '1 Month',
                },
                {
                    type: 'all',
                    text: 'All',
                    events: {
                        click: function () {
                            self.range.min = +new Date();
                            self.range.max = self.utcGraphDate.valueOf();
                            self.onXAxisZoomChange(event, self, false);
                        },
                    },
                },
            ],
        };
        this.hydroGraphChart.chartLegend.align = 'center';
        this.hydroGraphChart.chartLegend.layout = 'horizontal';
        this.hydroGraphChart.chartLegend.verticalAlign = 'bottom';
        this.hydroGraphChart.chartLegend.enabled = true;
        this.hydroGraphChart.showNavigator = false;
        this.hydroGraphChart.chartXAxis = [
            {
                min: this.startDate.getTime(),
                max: this.maxXAxis,
                ordinal: false,
                categories: xAxisSettings.categories,
                crosshair: false,
                type: 'datetime',
                zoomEnabled: true,
                zoomType: 'x',
                endOnTick: false,
                startOnTick: false,
                tickInterval: xAxisSettings.interval,
                events: {
                    setExtremes: function (e) {
                        self.onXAxisZoomChange(e, self, false);
                    },
                },
                labels: {
                    useHTML: true,
                    enabled: true,
                    y: 40,
                    formatter: function () {
                        return Highcharts.dateFormat(this.dateTimeLabelFormat, this.value);
                    },
                },
                dateTimeLabelFormats: xAxisSettings.dateFormat,
            },
        ];

        this.hydroGraphChart.chartYAxis = [
            {
                min: this.minYAxis,
                startOnTick: true,
                endOnTick: true,
                labels: {
                    style: {
                        color: '#333333',
                    },
                },
                title: {
                    text: `Flow ${this.tracerUnits}`,
                },
                opposite: false,
                height: '100%',
                lineWidth: 2,
                resize: {
                    enabled: false,
                },
            },
            {
                startOnTick: true,
                endOnTick: true,
                labels: {
                    x: 24,
                    format: '{value:.1f}',
                    style: {
                        color: '#333333',
                    },
                },
                title: {
                    x: 16,
                    text: `${RAIN} ${this.unitOfMeasure}`,
                },
                gridLineColor: 'transparent',
                lineWidth: 2,
                opposite: true,
            },
        ];

        this.hydroGraphChart.chartToolTipOption = {
            hideDelay: 0,
            enabled: true,
            shared: true,
            crosshairs: true,
            useHTML: true,
            split: false,
            formatter: function () {
                const tooltipFormatter: Array<string> = [];
                tooltipFormatter.push(
                    Highcharts.dateFormat(xAxisDateFormat + tooltipTimeFormat, new Date(this.points[0].x).getTime()),
                );
                this.points.map((point) => {
                    if (Number(point.y) !== 0) {
                        const plot = `<br/><span style ='color: ${point.series.color}'>\u25CF</span> ${
                            point.series.name
                        } : <b>
            ${
                point.series.name === RAIN
                    ? point.y % 1 === 0
                        ? point.y
                        : point.y.toFixed(point.series.userOptions.precisions)
                    : point.y % 1 === 0
                    ? point.y
                    : point.y.toFixed(point.series.userOptions.precision !== 0 ? point.series.userOptions.precision : 2)
            }
              </b> ${point.series.userOptions.unit}`;

                        tooltipFormatter.push(plot);
                    }
                });
                return tooltipFormatter;
            },
            style: {
                zIndex: 100,
            },
        };

        this.hydroGraphChart.chartPlotOptions = {
            line: {
                lineWidth: 2,
                dataLabels: {
                    enabled: false,
                },
                label: {
                    enabled: false,
                },
            },
            column: {
                pointWidth: 2,
            },
            series: {
                label: {
                    enabled: false,
                },
                clip: true,
                dataGrouping: {
                    enabled: false,
                    forced: false,
                },
            },
        };
        // render chart
        this.hydroGraphChart.reloadChart();
    }

    private chartToolTipTimeFormat() {
        return this.tooltipTimeFormat === 'h:mm:ss tt' ? ' %l:%M %p' : ' %H:%M';
    }

    private onXAxisZoomChange(event, self, zoomEnabled: boolean) {
        if (zoomEnabled) {
            if (event && event.target.xAxis[0].min && event.target.xAxis[0].max) {
                const startDate = new Date(event.target.xAxis[0].min);
                const endDate = new Date(event.target.xAxis[0].max);
                const startOffDate = startDate.getTime() - startDate.getTimezoneOffset() * 60000;
                const endOffDate = endDate.getTime() - endDate.getTimezoneOffset() * 60000;
                const settings = self.prepareXAxisLabels(new Date(startOffDate), new Date(endOffDate));
                if (settings) {
                    event.target.options.tickInterval = settings.interval;
                    event.target.options.dateTimeLabelFormats = settings.dateFormat;
                }
            }
        } else if (event && event.min && event.max) {
            const startDate = new Date(event.min);
            const endDate = new Date(event.max);
            const startOffDate = startDate.getTime() - startDate.getTimezoneOffset() * 60000;
            const endOffDate = endDate.getTime() - endDate.getTimezoneOffset() * 60000;
            const settings = self.prepareXAxisLabels(new Date(startOffDate), new Date(endOffDate));
            if (settings) {
                event.target.options.tickInterval = settings.interval;
                event.target.options.dateTimeLabelFormats = settings.dateFormat;
            }
        } else {
            event.target.options.tickInterval = 24 * 60 * 60 * 1000;
            event.target.options.dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
            };
        }
    }

    // tslint:disable-next-line: cyclomatic-complexity
    private prepareXAxisLabels(startDate: Date, endDate: Date) {
        const xAxisCategories = [];
        let dateTimeLabelFormats = {};

        const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * miliseconds
        const oneHour = 3600000;
        const diffDays = Math.abs((startDate.getTime() - endDate.getTime()) / oneDay);
        const diffHours = Math.abs((startDate.getTime() - endDate.getTime()) / oneHour);
        let tickInterval = oneDay;
        if (diffDays > 92) {
            // greater then 3 months
            tickInterval = 24 * 3600 * 1000 * 30;
            dateTimeLabelFormats = {
                month: '%b',
            };
        } else if (diffDays > 31 && diffDays <= 92) {
            // greater then a month and less then 3 months
            tickInterval = 24 * 3600 * 1000 * 7;
            dateTimeLabelFormats = {
                week: '%e. %b',
            };
        } else if (diffDays >= 7 && diffDays <= 31) {
            // more then a week, less then a month
            dateTimeLabelFormats = {
                week: '%e. %b',
            };
        } else if (diffDays > 3.75 && diffDays < 7) {
            tickInterval = 12 * 3600 * 1000;
            dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
            };
        } else if (diffDays < 3.75 && diffDays > 2.75) {
            tickInterval = 8 * 3600 * 1000;
            dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
            };
        } else if (diffDays < 2.75 && diffDays > 1.75) {
            tickInterval = 6 * 3600 * 1000;
            dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
            };
        } else if (diffDays < 1.75 && diffDays > 1.25) {
            tickInterval = 4 * 3600 * 1000;
            dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
            };
        } else if (diffDays < 1.25 && diffDays >= 1) {
            tickInterval = 3 * 3600 * 1000;
            dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
            };
        } else if (diffDays < 1 && diffHours < 23 && diffHours > 14) {
            tickInterval = 2 * 3600 * 1000;
            dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
            };
        } else if (diffDays < 1 && diffHours < 14 && diffHours > 6.75) {
            tickInterval = 3600 * 1000;
            dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
            };
        } else if (diffDays < 1 && diffHours < 6.75 && diffHours > 3.5) {
            tickInterval = 1800 * 1000;
            dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
                minute: '%H:%M',
            };
        } else if (diffDays < 1 && diffHours < 3.5) {
            tickInterval = 900 * 1000;
            dateTimeLabelFormats = {
                day: '%e. %b',
                hour: '%H:%M',
                minute: '%H:%M',
            };
        }
        return {
            categories: xAxisCategories,
            interval: tickInterval,
            dateFormat: dateTimeLabelFormats,
        };
    }

    private isValueMissing(val): boolean {
        return val === MISSING_DATA_DECIMAL || val === MISSING_DATA;
    }
}
