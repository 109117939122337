<mat-list-item [ngClass]="{ active: isActive }" (click)="selectDirectory(directory?.id)" class="sub-item">
    <mat-icon mat-list-icon>{{ directory?.sub_directories.length > 0 ? 'folder_open' : 'folder' }}</mat-icon>
    <p mat-line>
        {{ directory.name }}
        <span *ngIf="">*</span>
    </p>
</mat-list-item>
<mat-divider *ngIf="directory?.sub_directories.length > 0"></mat-divider>
<mat-list *ngIf="directory?.sub_directories.length > 0" class="sub-item">
    <app-vault-nav-item *ngFor="let dir of directory?.sub_directories" [directory]="dir"></app-vault-nav-item>
</mat-list>
