import { DOCUMENT, DatePipe } from '@angular/common';
import {
    Component,
    Input,
    ViewChild,
    ElementRef,
    ChangeDetectionStrategy,
    HostListener,
    OnDestroy,
    OnInit,
    Inject,
} from '@angular/core';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { StringUtils } from 'app/shared/utils/string-utils';
import * as Highcharts from 'highcharts';
import { Subscription } from 'rxjs';
import { ChartLegend } from '.';
const multicolorSeries = require('highcharts-multicolor-series/js/multicolor_series');
multicolorSeries(Highcharts);

@Component({
    selector: 'app-hydro-graph',
    templateUrl: './hydro-graph.component.html',
    styleUrls: ['./hydro-graph.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HydroGraphComponent implements OnInit, OnDestroy {
    @Input() public containerCssClass = 'graph-container';
    @Input() public chartId: any = 'chart-div-id';
    @Input() public chartTitle = '';
    @Input() public chartType = 'line';
    @Input() public chartLegend: ChartLegend = new ChartLegend();
    @Input() public showZoom = true;
    @Input() public showRangeSelector = false;
    @Input() public showCredits = false;
    @Input() public chartXAxis: any;
    @Input() public chartYAxis: any;
    @Input() public chartRangeSelectorOption: any;
    @Input() public chartToolTipOption: any;
    @Input() public chartSeriesData: any | Array<any>;
    @Input() public chartPlotOptions: any;
    @Input() public showGraph: boolean;
    @Input() public enableExport = false;
    @Input() public showNavigator = true;
    @HostListener('window:resize', ['$event']) public onResize(event) {
        const newWidth = this.getWidth() - 140;
        this.chartSWidth = newWidth;
        this.chartElement.setSize(newWidth);
        this.chartElement.reflow();
    }
    @HostListener('window:beforeprint', ['$event']) public onBeforePrint(event) {
        // Printing takes a screenshot of the screen to render
        // Since highcharts is not straight SVG, you need to get the chart to pixel width
        // 850px is ideal for letter sized paper correct chart rendering
        this.document.body.classList.add('flow-balance-report-print-page-sizing');
        this.chartScreenWidth = this.chartElement.chartWidth;
        setTimeout(() => {
            this.chartElement.setSize(1000, 400, false);
            this.chartElement.reflow();
        }, 0);
    }
    @HostListener('window:afterprint', ['$event']) public onAfterPrint(event) {
        // After printing the chart should be resized back to correct view size
        document.body.className = document.body.className.replace('flow-balance-report-print-page-sizing', '');
        setTimeout(() => {
            this.chartElement.setSize(this.chartSWidth);
            this.chartElement.reflow();
        }, 0);
    }

    // @ViewChild('chart') public chartElement: ElementRef;
    @ViewChild('chart') public chartElement: Highcharts.stockChart;
    @Input() public isLoading = false;
    @Input() public showCustomLegend = false;
    @Input() public chartCustomLegend: any;
    @Input() public chartScrollBarOptions: any;
    @Input() public chartHeightInPixel = 400;
    @Input() public chartEvents: any;
    @Input() public isDarkTheme = false;

    public chartOptions: any;
    public xAxisExtreams: number[];
    private chartScreenWidth = 0;
    private chartSWidth = 0;
    public chartObject: any;

    private subscriptions: Array<Subscription> = [];
    public customDateFormat: string;

    constructor(
        private el: ElementRef,
        private statusCodeService: StatusCodeService,
        public dateutilService: DateutilService,
        @Inject(DOCUMENT) private document: Document,
        private datePipe: DatePipe
    ) { }

    public ngOnInit(): void {
        this.subscriptions.push(this.dateutilService.dateFormat.subscribe(() => {
            const dateFormat = this.dateutilService.getFormat();
            const is12HourFormat = this.dateutilService.timeFormat.getValue() !== 'hh:mm:ss';
            this.customDateFormat = is12HourFormat ? `${dateFormat}, ${'hh:mm a'}` : `${dateFormat}, ${'HH:mm'}`;
        }));

        const isDarkMode = this.statusCodeService.userInfoThemeBS.getValue();
        if (isDarkMode) {
            StringUtils.setHighChartTheme();
        } else {
            StringUtils.setHighchartWhiteTheme();
        }

        const themeSub = this.statusCodeService.userInfoThemeBS.subscribe((response: boolean) => {
            if (response) {
                StringUtils.setHighChartTheme(this.chartObject);
            } else {
                StringUtils.setHighchartWhiteTheme(this.chartObject);
            }
        });
        this.subscriptions.push(themeSub);
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.subscriptions = [];
    }

    // tslint:disable-next-line: cyclomatic-complexity
    public renderChart() {

        this.showGraph = !!this.chartSeriesData || false;
        const chartRenderId = !this.chartElement.nativeElement
            ? this.chartOptions.chart.renderTo
            : this.chartElement.nativeElement;

        this.chartOptions = {
            chart: {
                type: this.chartType,
                ignoreHiddenSeries: false,
                zoomType: !!this.showZoom ? 'xy' : '',
                renderTo: chartRenderId,
                height: this.chartHeightInPixel || 400,
            },
            title: {
                text: !!this.chartTitle ? this.chartTitle : '',
            },
            exporting: {
                enabled: this.enableExport || false,
            },
            rangeSelector: {
                enabled: true,
                inputEnabled: false,
                buttons: [
                    {
                        type: 'day',
                        count: 1,
                        text: '1d',
                    },
                    {
                        type: 'week',
                        count: 1,
                        text: '1w',
                    },
                    {
                        type: 'month',
                        count: 1,
                        text: '1m',
                    },
                    {
                        type: 'all',
                        text: 'All',
                    },
                ],
                selected: 3,
            },
            navigator: {
                selector: false,
                enabled: this.showNavigator || false,
            },
            tooltip: this.chartToolTipOption,
            credits: {
                enabled: this.showCredits,
            },
            series: this.chartSeriesData || [],
        };

        const that = this;
        // chart x-axis
        if (this.chartXAxis) {
            this.chartOptions.xAxis = this.chartXAxis;
            this.chartOptions.xAxis[0].labels.formatter = function () {
                return that.datePipe.transform(this.value, that.customDateFormat);
            }
        }

        // chart y-axis
        if (this.chartYAxis) {
            this.chartOptions.yAxis = this.chartYAxis;
        }

        // chart plot options
        if (this.chartPlotOptions) {
            this.chartOptions.plotOptions = this.chartPlotOptions;
        }

        // chart scroll bar options
        if (this.chartScrollBarOptions) {
            this.chartOptions.scrollbar = this.chartScrollBarOptions;
        }

        // chart id
        if (this.chartId) {
            this.chartOptions.chart.renderTo = this.chartId;
        }

        // chart events
        if (this.chartEvents) {
            this.chartOptions.chart.events = this.chartEvents;
        }
        // chart legend
        if (this.showCustomLegend) {
            this.chartOptions.legend = this.chartCustomLegend;
        } else if (this.chartLegend) {
            this.chartOptions.legend = {
                enabled: this.chartLegend.enabled || false,
                layout: this.chartLegend.layout || 'horizontal',
                align: this.chartLegend.align || 'right',
                verticalAlign: this.chartLegend.verticalAlign || 'bottom',
            };
        } else {
            this.chartOptions.legend = new ChartLegend();
        }
        Highcharts.setOptions({
            time: {
                useUTC: false,
            },
        });
        if (this.chartElement && this.chartSeriesData) {
            this.chartElement = this.chartObject = new Highcharts.stockChart(this.chartOptions);
        }

        this.isLoading = false;
        // this.uiUtilService.safeChangeDetection(this.cdr);
    }

    public reloadChart() {
        this.renderChart();
    }

    public getWidth() {
        if (self.innerWidth) {
            return self.innerWidth;
        }
        if (this.el.nativeElement && this.el.nativeElement.offsetWidth) {
            return this.el.nativeElement.offsetWidth;
        }
    }

    public redrawChart() {
        if (this.chartObject) {
            this.chartObject.reflow();
        }
        this.renderChart();
    }

}
