import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ViewChild,
    ChangeDetectorRef,
    OnDestroy,
    Output,
    EventEmitter,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyInput as MatInput } from '@angular/material/legacy-input';
import { MatSort } from '@angular/material/sort';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UsersService, USER_ROLES } from 'app/pages/admin/users.service';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription, BehaviorSubject, combineLatest } from 'rxjs';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { TranslateService } from '@ngx-translate/core';
import { FilterDataSource } from 'app/shared/components/paging/filter-data-source';
import { CustomerUser } from 'app/shared/models/customer-user';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SharedService } from 'app/shared/services/shared.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { Locations } from 'app/shared/models/locations';
import { GetPermissionsResponseUser, GetUsersResponse, User } from 'app/shared/models/users-permission';
import { UsersDataDisplay, UsersInfo } from 'app/shared/models/users';
import {
    AppQueryParams,
    customerEditorQueryParam,
    customerQueryParam,
    userSearchPageIndexParam,
    userSearchPageSizeParam,
} from 'app/shared/models/customer';
import { AddLocationModalData } from 'app/shared/models/add-location';
import { DefaultResponse } from 'app/shared/models/default-response';
import { AddLocationComponent } from 'app/shared/components/map/add-location/add-location.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
@Component({
    selector: 'app-customer-detail-user-editor',
    templateUrl: './customer-detail-user-editor.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerDetailUserEditorComponent implements OnInit, OnDestroy {
    @Output() public usersSelected = new EventEmitter<boolean>();
    public assignedRainGauge = new Array<Locations>();
    public isLoading: boolean;
    public loggedInUserRole: string;

    public customerDtlUserEdtrColumns = [
        'isSelected',
        'username',
        'userEmail',
        'name',
        'role',
        'lastLogin',
        'features',
        'isActive',
        'editLoc'
    ];
    public usernamelist: any[];
    public namelist: any[];
    public rolelist: any[];
    public assignedfeatureslist: any[];
    public searchString = new FormControl();
    public users = new Array<User>();
    public initialUsersData = new Array<User>();
    private subscriptions = new Array<Subscription>();
    public userDataChange: BehaviorSubject<UsersDataDisplay[]> = new BehaviorSubject<UsersDataDisplay[]>([]);
    public userDataSource: FilterDataSource | null;
    public get userData(): UsersDataDisplay[] {
        return this.userDataChange.value;
    }
    public totalPaginationLength: number;
    private selectedCustomerId: number;
    private selectedUsers: Array<string> = [];
    private unselectedUsers: string[] = [];
    private previousSelectedUsers: Array<string> = [];
    private assignedUsers: Array<string> = [];
    public isFetchingUser = false;
    public data: any;
    public customerForUser: any;
    public dismissBtnText: string;
    public updateSuccessfully: string;
    public customerAssociationUpdateFor: string;
    public customerAssociationUpdateFailed: string;
    public isRegisterUser: boolean;
    public addNewUserVisible: boolean;
    public isSaveEnabled = false;
    public isAllSelected = false;
    public customerEditedName: string;
    public isMatTableVisible = true;
    public viewAssigned: boolean;
    public dateFormat: string;
    public timeZone: string;
    public isIdexAccount?: boolean;

    @ViewChild(MatPaginator) public userPaginator: MatPaginator;
    @ViewChild(MatInput) public searchBox: MatInput;
    @ViewChild(MatSort) public userSort: MatSort;
    public userFilterColumns = ['username', 'userEmail', 'name','role','lastLogin', 'features', 'isActive'];
    constructor(
        private translate: TranslateService,
        private dialog: MatDialog,
        private customerService: CustomerService,
        private usersService: UsersService,
        private activatedRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private sharedService: SharedService,
        private uiUtilsService: UiUtilsService,
        private snackBar: MatSnackBar,
        private router: Router,
        private dateUtilService: DateutilService,
        private statusCodeService: StatusCodeService,
    ) {
        /*  Search by user email address changes  */
        this.searchString.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe(
            (res: string) => {
                this.filterUsers(res);
            },
            (error) => {
                // error block
            },
        );

        this.translate.get('COMMON.DISMISS_TEXT').subscribe((res: string) => {
            this.dismissBtnText = res;
        });

        this.translate.get('COMMON.IS_UPDATED_SUCCESSFULLY').subscribe((res: string) => {
            this.updateSuccessfully = res;
        });
        this.translate
            .get('ADMIN.CUSTOMER_USER_ASSOCIATION.UPDATE_CUSTOMER_ASSOCIATION_FOR')
            .subscribe((res: string) => {
                this.customerAssociationUpdateFor = res;
            });
        this.translate
            .get('ADMIN.CUSTOMER_USER_ASSOCIATION.CUSTOMER_ASSOCIATION_UPDATE_FAILED')
            .subscribe((res: string) => {
                this.customerAssociationUpdateFailed = res;
            });
    }

    public ngOnInit() {
        this.isLoading = true;
        this.usersService.isRegisterUserFeatureAllowed.subscribe(
            (response) => {
                this.isRegisterUser = response;
            },
            (error) => {
                // error block
            },
        );
        this.statusCodeService.userInfo.subscribe((result: UsersInfo) => {
            if (result) {
                this.loggedInUserRole = result.userRole.toString();
                this.isIdexAccount = result.isIdex;

                this.addNewUserVisible =
                    this.loggedInUserRole === USER_ROLES.ADMIN ||
                    this.loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN && this.isRegisterUser;
                    // #22179 have to rollback - all Customer Admins can create new users !
                    // (this.loggedInUserRole === USER_ROLES.CUSTOMER_ADMIN && this.isRegisterUser && this.isIdexAccount);
            }
        });
        this.statusCodeService.customerIdInEditName.subscribe((customerName: string) => {
            this.customerEditedName = customerName;
        });

        // subscribe to route change
        const searchValue = this.searchString.value;
        let pageSize: number = this.userPaginator ? this.userPaginator.pageSize : 50;
        let pageIndex: number = this.userPaginator ? this.userPaginator.pageIndex : 0;

        this.subscriptions.push(
            combineLatest([this.dateUtilService.dateFormat, this.dateUtilService.timeFormat]).subscribe(
                ([newDateFormat, newTimeFormat]) => {
                    this.dateFormat =
                        this.dateUtilService.getStringFormat(newDateFormat) +
                        ' ' +
                        this.dateUtilService.getTimeFormat();
                },
            ),
        );

        this.activatedRoute.queryParamMap.subscribe(
            (params: ParamMap) => {
                this.selectedCustomerId = Number(params.get(customerEditorQueryParam));
                pageIndex = Number(params.get(userSearchPageIndexParam));
                pageSize = Number(params.get(userSearchPageSizeParam));

                if (this.selectedCustomerId) {
                    this.subscriptions.push(
                        combineLatest([
                            this.customerService.getCustomerById(this.selectedCustomerId),
                            this.customerService.getTimeZone(),
                        ]).subscribe(([res, timeZoneTypes]) => {
                            this.dateFormat =
                                this.dateUtilService.getStringFormat(res.dateFormat) +
                                ' ' +
                                this.dateUtilService.getTimeFormatFor(res.timeFormat);

                            const timeZoneType = timeZoneTypes.find((x) => x.value === res.timeZone);
                            this.timeZone = timeZoneType.utcValue;
                        }),
                    );
                } else {
                    this.subscriptions.push(
                        combineLatest([
                            this.dateUtilService.dateFormat,
                            this.dateUtilService.timeFormat,
                            this.dateUtilService.timeZone,
                            this.customerService.getTimeZone(),
                        ]).subscribe(([newDateFormat, newTimeFormat, newTimeZone, timeZoneTypes]) => {
                            this.dateFormat =
                                this.dateUtilService.getStringFormat(newDateFormat) +
                                ' ' +
                                this.dateUtilService.getTimeFormatFor(newTimeFormat);
                            const timeZoneType = timeZoneTypes.find((x) => x.value === newTimeZone);
                            this.timeZone = timeZoneType.utcValue;
                        }),
                    );
                }
            },
            (error) => {
                // error block
            },
        );
        if (!!searchValue) {
            this.searchString.setValue(searchValue);
        }
        this.loadUserList(pageSize, pageIndex);
    }
    public onChangeviewAssigned(event: any) {
        this.userPaginator.pageSize = 25;
        this.userPaginator.pageIndex = 0;
        this.loadUserList(25, 0);
    }
    public loadUserList(pageSize, pageIndex) {
        if (this.viewAssigned) {
            this.getAllActiveUsers(pageSize, pageIndex, this.searchString.value, true);
        } else {
            this.getUsers(pageSize, pageIndex, this.searchString.value, true);
        }
    }
    public addNewLocation() {
        this.dialog
            .open(AddLocationComponent, {
                disableClose: true,
                data: <AddLocationModalData>{
                    rainGaugeLocations: this.assignedRainGauge,
                },
            })
            .afterClosed()
            .subscribe((res) => {
                // to do after close
            });
    }
    public getAllActiveUsers(pageSize: number, pageIndex: number, searchValue: string, redrawTable = true) {
        if (this.isFetchingUser) {
            return;
        }
        if (pageIndex) {
            pageIndex += 1;
        } else {
            pageIndex = 1;
        }
        //  get all the users
        this.disableSearchBox();
        this.isFetchingUser = true;
        this.isLoading = true;
        const allActiveUsersSubscription: Subscription = this.usersService
            .getAllUsers(pageSize, pageIndex, searchValue, this.selectedCustomerId)
            .subscribe(
                (response: GetUsersResponse) => {
                    console.log('get users', response);
                    if (response && response.payload && response.payload.length > 0) {
                        this.isMatTableVisible = true;
                        this.users = this.initialUsersData = response.payload;
                        this.totalPaginationLength = Math.round(response.count);
                        this.userPaginator.length = Math.round(response.count);
                        this.selectedUsers = [];
                        // get customer users and mark them selected
                        this.users.forEach((user) => {
                            user.isSelected = true;
                            this.selectedUsers.push(user.userID);
                            this.previousSelectedUsers.push(...this.selectedUsers);
                        });
                    } else {
                        this.isMatTableVisible = false;
                        this.users = this.initialUsersData = [];
                        this.totalPaginationLength = 0;
                        this.userPaginator.length = 0;
                    }
                    if (redrawTable) {
                        this.generateUserTable(true);
                    }
                    // Hiding loader
                    this.isFetchingUser = this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                    this.disableSearchBox(false);
                },
                (error) => {
                    // error block
                    this.isFetchingUser = this.isLoading = false;
                    this.disableSearchBox(false);
                },
            );
        this.subscriptions.push(allActiveUsersSubscription);
    }

    public getUsers(pageSize: number, pageIndex: number, searchValue: string, redrawTable = true) {
        if (this.isFetchingUser) {
            return;
        }
        if (pageIndex) {
            pageIndex += 1;
        } else {
            pageIndex = 1;
        }
        //  get all the users
        this.disableSearchBox();
        this.isFetchingUser = true;
        this.isLoading = true;

        const customerusersSubscription: Subscription = combineLatest([
            this.usersService.getAllUsers(pageSize, pageIndex, searchValue, null),
            this.usersService.getAllUsersWithoutPagination(searchValue, this.selectedCustomerId),
        ]).subscribe(
            ([users, assignedUsers]) => {
                if (users && users.payload && users.payload.length > 0) {
                    this.isMatTableVisible = true;
                    this.users = this.initialUsersData = users.payload;
                    this.totalPaginationLength = Math.round(users.count);
                    this.userPaginator.length = Math.round(users.count);
                    this.selectedUsers = [];
                    // get customer users and mark them selected
                    if (assignedUsers) {
                        assignedUsers.payload.forEach((customeruser) => {
                            const user = this.users.find(
                                (u) => u.userID.toUpperCase() === customeruser.userID.toUpperCase(),
                            );
                            if (user) {
                                user.isSelected = true;
                                this.selectedUsers.push(user.userID);
                                this.previousSelectedUsers.push(...this.selectedUsers);
                            }
                        });
                    }
                } else {
                    this.isMatTableVisible = false;
                    this.users = this.initialUsersData = [];
                    this.totalPaginationLength = 0;
                    this.userPaginator.length = 0;
                }
                if (redrawTable) {
                    this.generateUserTable(true);
                }
                // Hiding loader
                this.isFetchingUser = this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
                this.disableSearchBox(false);
            },
            (error) => {
                // error block
                this.isFetchingUser = this.isLoading = false;
                this.disableSearchBox(false);
            },
        );
        this.subscriptions.push(customerusersSubscription);
    }

    public toggleUserSelection(user: User, event: any) {
        this.isSaveEnabled = true;
        this.usersSelected.emit(this.isSaveEnabled);
        if (!event.checked) {
            this.unselectedUsers.push(user.userID);
        } else {
            const index = this.unselectedUsers.findIndex((item) => item === user.userID);
            if (index > -1) {
                this.unselectedUsers.splice(index, 1);
            }
        }
        const userIndex = this.selectedUsers.findIndex((u) => u.toUpperCase() === user.userID.toUpperCase());
        if (userIndex > -1) {
            const removedValue = this.selectedUsers[userIndex];
            this.selectedUsers.splice(userIndex, 1);
            this.previousSelectedUsers = this.previousSelectedUsers.filter((item) => item !== removedValue);
        } else {
            this.selectedUsers.push(user.userID);
        }
        this.previousSelectedUsers.push(...this.selectedUsers);
        this.assignedUsers = this.previousSelectedUsers.filter(function (elem, index, self) {
            return index === self.indexOf(elem);
        });
        this.isAllSelected = this.selectedUsers.length === this.userPaginator.pageSize ? true : false;
    }

    public toggleAllDisplayedUsersSelection(event: any) {
        this.isSaveEnabled = true;
        this.usersSelected.emit(this.isSaveEnabled);
        this.users.forEach((element) => {
            element.isSelected = element.isActive ? event.checked : element.isSelected;
            // checkbox all selcted
            if (event.checked === true) {
                // if element is not already in selected user list
                let searchedElementIndex = this.selectedUsers.findIndex(
                    (u) => u.toUpperCase() === element.userID.toUpperCase(),
                );
                if (searchedElementIndex < 0) {
                    this.selectedUsers.push(element.userID);
                }
                searchedElementIndex = this.unselectedUsers.findIndex((u) => u === element.userID);
                if (searchedElementIndex > -1) {
                    this.unselectedUsers.splice(searchedElementIndex, 1);
                }
            } else {
                const searchedElementIndex = this.selectedUsers.findIndex(
                    (u) => u.toUpperCase() === element.userID.toUpperCase(),
                );
                // if element in select list remove it
                if (searchedElementIndex > -1) {
                    const removedValue = this.selectedUsers[searchedElementIndex];
                    this.selectedUsers.splice(searchedElementIndex, 1);
                    this.previousSelectedUsers = this.previousSelectedUsers.filter((item) => item !== removedValue);
                }
                const index = this.unselectedUsers.indexOf(element.userID);
                if (index === -1) {
                    this.unselectedUsers.push(element.userID);
                }
            }
            this.previousSelectedUsers.push(...this.selectedUsers);
            this.assignedUsers = this.previousSelectedUsers.filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            });
        });
        this.generateUserTable(true);
    }

    /**
     * Method brings up a pop up window with user's name and customers menu
     * @param user the user data object.
     */

    public locationAssociation(user: User) {
        this.isLoading = true;
        const locationAssociation = this.usersService.getUserPermissions(user.userID).subscribe(
            (userData: GetPermissionsResponseUser) => {
                const userFullData = this.users.find((x) => x.userID === user.userID);
                this.customerForUser = userData.customers.map((x) => x.customer);
                if (user.userEmail === this.usersService.userName) {
                    const defaultCustomer = this.usersService.defaultCustomer.getValue();
                    userData.customers.forEach(
                        (customer) => {
                            if (defaultCustomer && customer.customer.customerID === defaultCustomer) {
                                customer.defaultCustomer = true;
                            } else {
                                customer.defaultCustomer = false;
                            }
                        },
                        (error) => {
                            // error block
                        },
                    );
                }
                // storing data in an object to send to the detail screen
                this.data = {
                    user: userData,
                    customers: this.customerForUser,
                };

                // setup the route for admin detail screen
                this.setupAdminDetailRoute(userFullData);
            },
            (error) => {
                // error block
            },
        );
        this.subscriptions.push(locationAssociation);
    }

    public setupAdminDetailRoute(userFullData) {
        const currentCustomerId = Number(this.activatedRoute.snapshot.queryParamMap.get(customerQueryParam));
        if (userFullData === '') {
            // Using Behaviour Subjects next method to emit the data
            this.sharedService.adminUserData.next(null);
        } else {
            sessionStorage.setItem('adsGuid', userFullData.userID);
            this.data.user.userID = userFullData.userID;
            // Using Behaviour Subjects next method to emit the data
            this.sharedService.adminUserData.next(this.data);
        }
        // get global query params
        const appQueryParams = <AppQueryParams>{
            c: currentCustomerId,
            cid: this.selectedCustomerId,
            lg: undefined,
            lt: undefined,
            u: userFullData === '' ? 'registeruser' : userFullData.userEmail,
            s: !!this.searchString.value ? this.searchString.value : undefined,
            pi: !this.userPaginator.pageIndex ? 0 : Number(this.userPaginator.pageIndex),
            ps: this.userPaginator.pageSize || undefined,
        };

        // Navigating with required params

        this.isLoading = false;

        this.router.navigate(['/pages/admin/details'], {
            queryParams: appQueryParams,
            relativeTo: this.activatedRoute,
        });
    }
    /**
     * Method generates a table of users
     */
    public generateUserTable(enableServerSidePaging = false) {
        this.userDataSource = null;
        this.userDataChange = new BehaviorSubject<UsersDataDisplay[]>([]);
        this.users.forEach((user, index) => {
            // Flag to show the edited row
            const userCopiedData = this.userData.slice();
            const data = {
                userID: user.userID,
                userEmail: user.userEmail,
                firstName: user.firstName,
                lastName: user.lastName,
                username: user.userName,
                name: [user.firstName, user.lastName].join(' '),
                role: user.role,
                lastLogin: user.lastLogin ? new Date(user.lastLogin + 'Z') : '',
                locations: user.locations,
                customers: user.customers,
                features: user.features.sort(),
                isSelected: user.isSelected,
                isActive: user.isActive,
            };

            userCopiedData.push(data);
            this.userDataChange.next(userCopiedData);
        });
        const filterData = this.userData.filter(function (data) {
            return data.userEmail !== '';
        });
        this.isAllSelected = this.selectedUsers.length === this.userPaginator.pageSize ? true : false;
        this.userDataSource = new FilterDataSource(
            this.userDataChange,
            this.userData,
            this.userPaginator,
            this.userSort,
            this.userFilterColumns,
            enableServerSidePaging,
        );
    }

    public changePage(page) {
        this.loadUserList(page.pageSize, page.pageIndex);
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
    /**
     * Method takes a sting as an argument and filters out users whose email addresses include the string
     * @param searchString - string to search for in email addresses
     */
    public filterUsers(searchString: string) {
        const pageSize: number = this.userPaginator.pageSize;
        const pageIndex: number = this.userPaginator.pageIndex;
        this.loadUserList(pageSize, pageIndex);
    }
    public disableSearchBox(isDisable = true) {
        if (this.searchBox) {
            this.searchBox.disabled = isDisable;
        }
    }
    public saveUserInformation() {
        this.isLoading = true;
        this.AssignUsers();
    }

    /**
     * Function to assign the users to a customer
     */

    public AssignUsers() {
        // Edit functionality
        const users: CustomerUser = { addedUsers: this.assignedUsers, removedUsers: this.unselectedUsers };
        const createAssociationSubscription = this.usersService.assignUsers(this.selectedCustomerId, users).subscribe(
            (res: Array<DefaultResponse<object>>) => {
                this.isLoading = false;
                if (res && res.length > 0) {
                    this.showSnackBar(`${this.customerEditedName}${this.updateSuccessfully}`, true);
                } else {
                    this.showSnackBar(`${this.customerAssociationUpdateFailed}`, false);
                }
                this.unselectedUsers = [];
                if (this.viewAssigned) {
                    this.userPaginator.pageSize = 25;
                    this.userPaginator.pageIndex = 0;
                    this.loadUserList(25, 0);
                }
            },
            (error) => {
                this.isLoading = false;
                this.showSnackBar(`${this.customerAssociationUpdateFailed}`, false);
            },
        );

        this.subscriptions.push(createAssociationSubscription);
    }
    /**
     * Global function to handle all snackbar events
     */
    private showSnackBar(message, success) {
        const simpleSnackBarRef = this.snackBar.open(message, this.dismissBtnText);
        if(success) {
            setTimeout(simpleSnackBarRef.dismiss.bind(simpleSnackBarRef), 10000);
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }
}
export interface Element {
    id: number;
    username: string;
    name: string;
    role: string;
    features: string;
}
