<app-diurnal-graph (click)="onChartClick($event)"></app-diurnal-graph>
<div class="toggle-container" *ngIf="weekGroupCount > 0">
    <div>
        <button
            mat-button
            (click)="removeSelectedDayTraces()"
            class="pad-r-15 custom-chk-margin"
            [disabled]="!isDryDaysEditActive || isStudyLocked || selectedDryDaysLength === 0"
            matTooltip=""
            [disableRipple]="true"
        >
            <mat-icon>delete</mat-icon>
            <span>{{ 'SLIICER.COMMON.REMOVE_SELECTED' | translate }}</span>
        </button>
        <mat-checkbox
            class="pad-r-15 custom-chk-margin"
            [checked]="tooltipEnabled"
            (change)="onTooltipEnabledChanged()"
        >
            <span>{{ 'SLIICER.COMMON.SHOW_TOOLTIPS' | translate }}</span>
        </mat-checkbox>
    </div>
</div>
