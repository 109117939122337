import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, SimpleChanges } from '@angular/core';
import { DeviceTypeCode } from 'app/shared/enums/device-type-code';
import { MONITOR_SERIES_TYPES } from 'app/shared/models/monitor-series-types';
import { FormGroup, FormControl, ValidationErrors } from '@angular/forms';
// import { MonitorDiagnosticsService, SensorType } from 'app/shared/services';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { finalize } from 'rxjs/operators';
import { DiagnosticService } from 'app/pages/view-data/view-data-location-details/location-diagnostics/location-diagnostics-service';
import { MonitorDiagnosticsService, SensorType } from 'app/shared/services/monitor-diagnostics.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-diagnostics-menu',
    templateUrl: './diagnostics-menu.component.html',
    styleUrls: ['./diagnostics-menu.component.scss'],
})
export class DiagnosticsMenuComponent implements OnInit, OnDestroy {
    @Input()
    public customerId: number;
    @Input()
    public locationId: number;
    @Input()
    private deviceTypes: DeviceTypeCode[];
    @Input()
    private monitorSeriesType: MONITOR_SERIES_TYPES;

    @ViewChild(MatMenuTrigger) private trigger: MatMenuTrigger;

    public diagnosticsGroup: FormGroup;

    private subscriptions = [];
    public diagnosesRunning: Object;

    public PAGE_TEXT: any;

    constructor(
        private monitorDiagnosticsService: MonitorDiagnosticsService,
        private cdr: ChangeDetectorRef,
        public uiUtilsService: UiUtilsService,
        private diagnosticService: DiagnosticService,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
    ) {}

    public ngOnInit() {
        this.translate.get('LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS').subscribe((res: string) => {
            this.PAGE_TEXT = res;
        });

        const diagnosesServ = this.diagnosticService.diagnosesRunning.subscribe((diagnoses) => {
            this.diagnosesRunning = diagnoses ? diagnoses : {};
        });
        this.diagnosticsGroup = new FormGroup(
            {
                ultrasonicDepth: new FormControl(true),
                pressureDepth: new FormControl(true),
                velocity: new FormControl(true),
                battery: new FormControl({ value: true, disabled: true }),
                firmware: new FormControl({ value: true, disabled: true }),
                accelerometer: new FormControl({
                    value: this.shouldShowAccelerometer,
                    disabled: !this.shouldShowAccelerometer,
                }),
            },
            { validators: this.customValidator },
        );
        this.subscriptions.push(diagnosesServ);
        this.resetForm();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.monitorSeriesType && changes.monitorSeriesType.currentValue) {
            this.resetForm();
        }
    }

    private resetForm() {
        if (this.diagnosticsGroup) {
            this.diagnosticsGroup.controls['accelerometer'].setValue(this.shouldShowAccelerometer);
            if (!this.shouldShowAccelerometer) {
                this.diagnosticsGroup.controls['accelerometer'].disable();
            } else {
                this.diagnosticsGroup.controls['accelerometer'].enable();
            }
            if (this.monitorSeriesType === MONITOR_SERIES_TYPES.ECHO) {
                this.diagnosticsGroup.controls['velocity'].disable();
                this.diagnosticsGroup.controls['velocity'].setValue(false);
            } else {
                this.diagnosticsGroup.controls['velocity'].enable();
                this.diagnosticsGroup.controls['velocity'].setValue(true);
            }
            this.diagnosticsGroup.controls['battery'].disable();
            this.diagnosticsGroup.controls['firmware'].disable();
        }
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    private customValidator(group: FormGroup): ValidationErrors | null {
        return group.controls.ultrasonicDepth.value ||
            group.controls.pressureDepth.value ||
            group.controls.velocity.value ||
            group.controls.accelerometer.value
            ? null
            : { gottaPickOne: true };
    }

    private get shouldShowAccelerometer(): boolean {
        return (
            this.monitorSeriesType === MONITOR_SERIES_TYPES.ECHO ||
            this.deviceTypes.includes(DeviceTypeCode.AVGated1) ||
            this.deviceTypes.includes(DeviceTypeCode.AVGated2)
        );
    }

    public startDiagnostics() {
        this.diagnosticsGroup.disable();
        this.diagnosesRunning['id' + this.locationId + this.customerId] = true;
        this.diagnosticService.diagnosesRunning.next(this.diagnosesRunning);

        const sensors = [];
        if (this.diagnosticsGroup.controls.ultrasonicDepth.value) {
            sensors.push(SensorType.ULTRASONIC);
        }
        if (this.diagnosticsGroup.controls.pressureDepth.value) {
            sensors.push(SensorType.PRESSURE);
        }
        if (this.diagnosticsGroup.controls.velocity.value) {
            sensors.push(SensorType.VELOCITY);
            sensors.push(SensorType.SURFACE_VELOCITY);
        }
        if (this.diagnosticsGroup.controls.accelerometer.value) {
            sensors.push(SensorType.ACCELEROMETER);
        }

        const readings: { device: number; sensor: number }[] = this.deviceTypes.reduce(
            (acc, device) => [
                ...acc,
                ...sensors.map((sensor) => ({
                    device,
                    sensor,
                })),
            ],
            [],
        );
        const diagnosticSubscription = this.monitorDiagnosticsService
            .requestDiagnostics({
                customerID: this.customerId,
                locationID: this.locationId,
                readings,
            })
            .pipe(
                finalize(() => {
                    this.diagnosticsGroup.enable();
                    this.resetForm();
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                }),
            )
            .subscribe(
                () => {
                    this.trigger.closeMenu();
                    this.diagnosticsGroup.enable();
                    this.resetForm();
                    this.snackBar.open(this.PAGE_TEXT.DIAGNOSTICS.DIAGNOSTICS_REQUESTED, this.PAGE_TEXT.DISMISS, {
                        duration: 10000,
                    });
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                },
                (err) => {
                    this.snackBar.open(this.PAGE_TEXT.DIAGNOSTICS.DIAGNOSTICS_REQUESTED, this.PAGE_TEXT.DISMISS);
                },
            );

        this.subscriptions.push(diagnosticSubscription);
    }
}
