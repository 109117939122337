<div id="customerDetailFeatureId">
    <mat-card-content>
        <app-loader [isLoading]="customerFeatureLoadingState"></app-loader>
        <div class="leftrightcontainer">
            <div class="grow margin-t-15">
                <div class="stitle">{{translations.addons}}</div>
                <div class="assignEachFeature edit-cus-feats-hint" *ngFor="let feature of adonsFeatureList">
                    <mat-checkbox
                        [checked]="subscriptionLevel !== SubscriptionLevel.Inactive && feature.isChecked"
                        name="feature"
                        [disabled]="subscriptionLevel === SubscriptionLevel.Inactive"
                        (change)="featureChangeHandler($event, feature)"
                        ><strong>{{ feature.displayname }}</strong></mat-checkbox
                    >
                    <div class="desc desc-ml">{{ feature.desc }}</div>
                </div>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button class="edit-cus-feats-apply-hint" (click)="saveCustomerAssignedFeatures()" [disabled]="!isFeatureSelected">
                {{ 'COMMON.APPLY_BTN' | translate }}
            </button>
        </div>
    </mat-card-actions>
</div>
