<div id="DetailsDialog" [ngClass]="{'popup-comparision-on': isComparisionMode, 'popup-comparision-off': !isComparisionMode}">
    <div class="popupHeader" mat-dialog-title>
        <span *ngIf="!isComparisionMode">{{ 'SLIICER_TABLE.SLICER_SUMMARY.LOCATION_TEST_DEMO' | translate }} "{{ location }}"</span>
        <span *ngIf="isComparisionMode">"{{ location }}" {{ 'SLIICER_TABLE.SLICER_SUMMARY.LOCATION_COMPARISON' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <br />
    <div class="tables">
        <div class="table-scroll" [ngClass]="{'half-width': isComparisionMode, 'full-width': !isComparisionMode}">
            <table class="table-container table-width">
                <thead>
                    <th>{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_FLOW_MONITORS' | translate }}</th>
                    <th>{{ 'COMMON.START_DATE' | translate }}</th>
                    <th>{{ 'COMMON.END_DATE' | translate }}</th>
                </thead>
                <tbody *ngFor="let monitor of flowMonitors">
                    <tr>
                        <td>{{ monitor.name }}</td>
                        <td>{{ monitor.earliestReading }}</td>
                        <td>{{ monitor.latestReading }}</td>
                    </tr>
                </tbody>
            </table>
            <br />
            <table class="table-container table-width">
                <thead>
                    <th>{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_RAINFALL_MONITORS' | translate }}</th>
                    <th>{{ 'COMMON.START_DATE' | translate }}</th>
                    <th>{{ 'COMMON.END_DATE' | translate }}</th>
                </thead>
                <tbody *ngFor="let rainMonitor of rainMonitors">
                    <tr>
                        <td>{{ rainMonitor.name }}</td>
                        <td>{{ rainMonitor.earliestReading }}</td>
                        <td>{{ rainMonitor.latestReading  }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="table-scroll half-width" *ngIf="isComparisionMode">
            <table class="table-container table-width">
                <thead>
                    <th>{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_FLOW_MONITORS' | translate }}</th>
                    <th>{{ 'COMMON.START_DATE' | translate }}</th>
                    <th>{{ 'COMMON.END_DATE' | translate }}</th>
                </thead>
                <tbody *ngFor="let monitor of comparedFlowMonitors">
                    <tr>
                        <td>{{ monitor.name }}</td>
                        <td>{{ monitor.earliestReading }}</td>
                        <td>{{ monitor.latestReading }}</td>
                    </tr>
                </tbody>
            </table>
            <br />
            <table class="table-container table-width">
                <thead>
                    <th>{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_RAINFALL_MONITORS' | translate }}</th>
                    <th>{{ 'COMMON.START_DATE' | translate }}</th>
                    <th>{{ 'COMMON.END_DATE' | translate }}</th>
                </thead>
                <tbody *ngFor="let rainMonitor of comparedRainMonitors">
                    <tr>
                        <td>{{ rainMonitor.name }}</td>
                        <td>{{ rainMonitor.earliestReading }}</td>
                        <td>{{ rainMonitor.latestReading  }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div *ngIf="isComparisionMode" class="comparisionInfo">
        {{ 'SLIICER_TABLE.SLICER_SUMMARY.LOCATION_COMPARISON_INFO' | translate }}
    </div>
    <div mat-dialog-actions class="clearfix">
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button *ngIf="isComparisionMode" mat-button (click)="closeUseNew()">{{ 'SLIICER_TABLE.SLICER_SUMMARY.USE_NEW' | translate }}</button>
            <button mat-button (click)="close()">{{ 'COMMON.CLOSE' | translate }}</button>
        </div>
    </div>
</div>
