<mat-expansion-panel>
    <mat-expansion-panel-header class="mat-expasion-header-background">
        <mat-panel-title class="input-text">
            {{ monitorSeries }}
        </mat-panel-title>
    </mat-expansion-panel-header>

    <form>
        <div [class.disable-field]="!isMonitorEditor">
            <div class="triton-border text-bottom-space display-content">
                <div class="toggle-text">
                    <mat-icon class="clear-icon">clear</mat-icon>
                </div>
                <div>
                    <div class="input-text">{{ 'ADD_EDIT.NOT_SUPPORTED' | translate }}</div>
                    <p class="cutomer-text">
                        {{ 'ADD_EDIT.UNFORTUNATELY_ACTIVATION' | translate }}

                        {{ 'ADD_EDIT.SUPPORTED_IN_PRISM' | translate }}
                    </p>
                </div>
            </div>
            <div>
                <h3>{{ 'ADD_EDIT.CUSTOMER_INFORMATION' | translate }}</h3>
                <div class="display-content">
                    <button mat-button class="phone-icon customer-details">
                        <mat-icon>phone</mat-icon>256-430-3366<span class="cutomer-text"
                            >({{ 'ADD_EDIT.INSIDE_US' | translate }})</span
                        >
                    </button>
                    <button mat-button class="phone-icon">
                        <mat-icon>phone</mat-icon>877-237-9585<span class="cutomer-text"
                            >({{ 'ADD_EDIT.TOLL_FREE' | translate }})</span
                        >
                    </button>
                </div>
            </div>
        </div>
    </form>
</mat-expansion-panel>
