import { Component, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

import { DailySummaryReportService } from '../daily-summary-report.service';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import {
    RAIN_ENTITY,
    VELOCITY_ENTITY,
    DEPTH_ENTITY,
    QUANTITY_ENTITY,
    Q_FINAL_ENTITY,
    DFINAL_ENTITY,
    V_FINAL_ENTITY,
    R_FINAL_ENTITY,
} from 'app/shared/constant';
import * as moment from 'moment';
import { UsersService } from 'app/pages/admin/users.service';
import {
    DailySummaryData,
    DailySummaryReport,
    DailySummaryDetailsReportArgs,
    Header,
    Paging,
} from 'app/shared/models/daily-summary';
import {
    activeInactiveLocationQueryParam,
    customerLocationGroupQueryParam,
    customerQueryParam,
} from 'app/shared/models/customer';

@Component({
    templateUrl: './daily-summary-overview.component.html',
    styleUrls: ['./daily-summary-overview.component.scss'],
})
export class DailySummaryOverviewComponent implements OnInit, OnDestroy {
    /**
     * Represents the daily summary table representation.
     */
    // public dailySummaryData: DailySummaryReport;

    private customerOrLocationChange = new Array<Subscription>();

    public dataSummaryOverviewLoadingState: boolean;

    public fullData: DailySummaryReport;
    private startDate: Date;
    private endDate: Date;
    public pageData = new Array<Array<DailySummaryData>>();
    private customerID: number;
    private locationGroupId: number;
    private activeInactiveLocation: boolean;

    private subscriptions = new Array<Subscription>();

    /**
     * Represents default starting index for pagination
     */
    private startIndex = 0;

    /**
     * Represents default pagination size
     */
    private pageSize = 10;

    /**
     * Represents date format
     */
    private dateFormat: string;

    /**
     * Represents time format
     */
    private timeFormat: string;

    /**
     * Represents page length
     */
    public totalPaginationLength: number;

    /**
     * Below variable will be used for sorted daily summary overview data
     */
    private tempData: DailySummaryReport;
    private searchValue: string;
    private isRequestProcessing: boolean;
    public userHasRawDataPermission: boolean;
    constructor(
        private domOperationUtilsService: DomOperationUtilsService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private reportService: DailySummaryReportService,
        private changeDetector: ChangeDetectorRef,
        private dateUtilService: DateutilService,
        private uiUtilsService: UiUtilsService,
        private userService: UsersService,
    ) {}

    public ngOnInit() {
        const globalSubscription = combineLatest(
            this.activatedRoute.queryParamMap,
            this.dateUtilService.dateFormat,
            this.dateUtilService.timeFormat,
        ).subscribe((data) => {
            if (data[1] !== this.dateFormat || data[2] !== this.timeFormat) {
                this.dateFormat = data[1];
                this.timeFormat = data[2];

                const customerSubscription = this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
                    // get updated customer id
                    this.customerID = Number(params.get(customerQueryParam));
                    this.locationGroupId = Number(params.get(customerLocationGroupQueryParam));
                    // get updated locations
                    this.activeInactiveLocation = Boolean(Number(params.get(activeInactiveLocationQueryParam)));
                    this.onCustomerChange();
                });
                this.subscriptions.push(customerSubscription);
            }
        });
        this.subscriptions.push(globalSubscription);
    }

    public ngOnDestroy() {
        this.changeDetector.detach();
        this.subscriptions.forEach((s) => s.unsubscribe());
    }

    public downloadCSV() {
        const csvData = new Array<Object>();

        const csvHeaders = new Array<string>();
        let counter = 0;
        let entity = 1;
        let entityIndicators;
        const dateFormat = `${String(this.dateUtilService.getFormat()).toUpperCase()}`;
        const startDate = this.dateUtilService.getPreviousDate(new Date(), 1);

        if (this.fullData && this.fullData.data && this.fullData.data.length > 0) {
            this.fullData.data.forEach((arrayItem) => {
                const newItem = new Array<string>();

                if (arrayItem.length > 0 && arrayItem[0].name) {
                    newItem.push(arrayItem[0].name);
                    arrayItem.forEach((value, valueIndex) => {
                        if (valueIndex !== 0) {
                            if (value.value.includes('\n')) {
                                const parsedValue = value.value.split('\n');

                                parsedValue.forEach((element, elementIndex) => {
                                    if (elementIndex === 0) {
                                        if (element.includes(' ')) {
                                            // newItem.push(element.split(' ')[0]);
                                            newItem.push(element);
                                        } else {
                                            newItem.push(element);
                                        }
                                    } else {
                                        newItem.push(element);
                                    }
                                });
                            } else {
                                if (value.value.includes(' ')) {
                                    newItem.push(value.value);
                                    // newItem.push(value.value.split(' ')[0]);
                                } else {
                                    newItem.push(value.value);
                                }
                                // remove Avrage and Total Time column
                                // newItem.push('-');
                            }
                        }
                    });
                    csvData.push(newItem);
                }
            });

            if (this.fullData.headers && this.fullData.headers.length === 2) {
                this.fullData.headers[1].forEach((header, index) => {
                    if (index === 0) {
                        csvHeaders.push('Location');
                    } else {
                        entityIndicators = this.fullData.headers[0][entity]['colspan'];
                        if (entityIndicators === counter) {
                            entity++;
                            counter = 0;
                        }
                        csvHeaders.push(
                            `${this.fullData.headers[0][entity]['name']} ${header.name} ${
                                header.totalSuffix ? header.totalSuffix : header.suffix
                            }`,
                        );
                        // remove Avrage and toatal Time column
                        if (
                            counter < 2 &&
                            (this.fullData.headers[0][entity]['name'] !== 'RAIN' ||
                                this.fullData.headers[0][entity]['name'] !== 'RAINFINAL')
                        ) {
                            csvHeaders.push(`${this.fullData.headers[0][entity]['name']} ${header.name} Time`);
                        }
                        counter++;
                    }
                });
            }

            const options = {
                showLabels: true,
                headers: csvHeaders,
                title: `Daily Summary ${moment(startDate).format(dateFormat)}`,
                showTitle: true,
            };

            const result = new AngularCsv(csvData, 'Daily Summary report', options);
        }
    }

    public itemSelected(event: MouseEvent, id: number, locationName: string): void {
        this.changeDetector.detach();
        // navigate to details route
        this.router.navigate(['/pages/report/daily-summary/' + id + '/details'], {
            queryParams: {
                ln: locationName,
            },
            queryParamsHandling: 'merge',
        });
    }
    /**
     * Handles setup of data upon customer change.
     */
    private onCustomerChange(): void {
        if (this.isRequestProcessing) {
            return;
        }
        if (!this.startDate) {
            this.startDate = new Date();
        }
        if (!this.endDate) {
            this.endDate = new Date();
        }
        const start = `${this.dateUtilService.getPreviousDate(this.startDate, 1)}`;
        const end = `${this.dateUtilService.getPreviousDate(this.endDate, 1)}` + ` 23:59:59`;
        this.userHasRawDataPermission = this.userService.isRawDataEditingAllowed.getValue();
        let entityIDs = [QUANTITY_ENTITY, RAIN_ENTITY, VELOCITY_ENTITY, DEPTH_ENTITY];
        if (!this.userHasRawDataPermission) {
            entityIDs = [Q_FINAL_ENTITY, DFINAL_ENTITY, V_FINAL_ENTITY, RAIN_ENTITY];
        }

        const pagings: Paging = {
            startPage: !!this.startIndex ? Number(this.startIndex) : 1,
            pageSize: !!this.pageSize ? Number(this.pageSize) : 100,
            searchValue: this.searchValue !== undefined ? this.searchValue : '',
        };
        const args = <DailySummaryDetailsReportArgs>{
            paging: pagings,
            CustomerId: this.customerID,
            LocationGroupId: this.locationGroupId || 0,
            EntityIds: entityIDs,
            Start: start,
            End: end,
            LocationIds: null,
            includeInactiveLocations: this.activeInactiveLocation,
        };
        this.isRequestProcessing = true;
        this.dataSummaryOverviewLoadingState = true;
        this.uiUtilsService.safeChangeDetection(this.changeDetector);

        // initate the preloader
        this.fullData = <DailySummaryReport>{ headers: [], data: [] };

        // initialize temporary data for sorting the daily summary overview
        this.tempData = <DailySummaryReport>{ data: [] };

        // retrieve data from customer
        const dailySummaryReportSubscription = this.reportService.getDailySummaryReport(args).subscribe(
            (result: DailySummaryReport) => {
                this.fullData = result;
                this.fullData.data.sort((n1, n2) => {
                    if (n1[0].name.toLowerCase() > n2[0].name.toLowerCase()) {
                        return 1;
                    }
                    if (n1[0].name.toLowerCase() < n2[0].name.toLowerCase()) {
                        return -1;
                    }
                    return 0;
                });
                this.loadDataTable();
                this.isRequestProcessing = false;
                this.dataSummaryOverviewLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
            (error) => {
                this.isRequestProcessing = false;
                this.dataSummaryOverviewLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.changeDetector);
            },
        );

        this.subscriptions.push(dailySummaryReportSubscription);
    }

    public onPaginateChange(event) {
        this.pageSize = event.pageSize;
        this.startIndex = event.pageIndex + 1;
        this.searchValue = event.searchValue;
        this.onCustomerChange();
    }

    private loadDataTable() {
        const tempSubHeader = new Array<Header>();

        // collects the index where the name is Time
        const tempDateIndex = new Array<number>();

        // collects the index where the name is not Time, Max, Min i.e Avg , Total etc
        const tempSingleDataIndex = new Array<number>();

        if (
            this.fullData &&
            this.fullData &&
            this.fullData.headers[0] &&
            this.fullData.headers[1] &&
            this.fullData.data
        ) {
            const tempHeadIndex = {};
            let tempIndex = 0;
            this.fullData.headers[0].forEach((data, index) => {
                const tempMIndex = data.colspan !== undefined ? 4 + (data.colspan - 2) : tempIndex + 1;
                tempHeadIndex[data.name] = [tempIndex, tempIndex + tempMIndex];
                tempIndex = tempIndex + tempMIndex;
                if (data.name === 'RAIN' || data.name === 'RAINFINAL') {
                    data.colspan = 1;
                }
            });

            this.fullData.headers[1].forEach((data, index) => {
                if (
                    tempHeadIndex.hasOwnProperty('RAIN') &&
                    index >= tempHeadIndex['RAIN'][0] &&
                    index < tempHeadIndex['RAIN'][1]
                ) {
                    tempSingleDataIndex.push(tempHeadIndex['RAIN'][1] - 1);
                    if (index === tempHeadIndex['RAIN'][1] - 1) {
                        tempSubHeader.push(data);
                    }
                } else if (
                    tempHeadIndex.hasOwnProperty('RAINFINAL') &&
                    index >= tempHeadIndex['RAINFINAL'][0] &&
                    index < tempHeadIndex['RAINFINAL'][1]
                ) {
                    tempSingleDataIndex.push(tempHeadIndex['RAINFINAL'][1] - 1);
                    if (index === tempHeadIndex['RAINFINAL'][1] - 1) {
                        tempSubHeader.push(data);
                    }
                } else {
                    if (data.name !== 'Time' && data.name !== 'Max' && data.name !== 'Min') {
                        tempSingleDataIndex.push(index);
                    }

                    if (data.name !== 'Time') {
                        tempSubHeader.push(data);
                    } else {
                        tempDateIndex.push(index);
                    }
                }
            });
            this.fullData.headers[1] = tempSubHeader;

            this.fullData.data.forEach((data, dataIndex) => {
                const tempDataItem = new Array<DailySummaryData>();

                data.forEach((item: DailySummaryData, index: number) => {
                    // matches the index where the value are not for Time, Max, Min i.e Avg , Total etc
                    if (tempSingleDataIndex.indexOf(index) >= 0) {
                        tempDataItem.push(item);
                    } else if (tempDateIndex.indexOf(index) >= 0) {
                        const values = item.value.split(':');
                        let value = '';
                        if (values && values.length > 2) {
                            // setting time in HH:MM AM/PM format for 12 hours and HH:MM for 24 hours
                            const time = values[2].split(' ')[1]
                                ? values[0] + ':' + values[1] + ' ' + values[2].split(' ')[1]
                                : values[0] + ':' + values[1];
                            value = data[index + 1].value + '\n' + time;
                        } else {
                            value = data[index + 1].value + '\n' + item.value;
                        }
                        item.value = value;
                        tempDataItem.push(item);
                    }
                });

                if (tempDataItem.length > 0) {
                    this.fullData.data[dataIndex] = tempDataItem;
                }
            });

            // store full data on temporary data for sorting
            this.tempData.data = this.fullData.data;

            this.pageData = this.fullData.data;

            this.totalPaginationLength = this.fullData.totalRows;
        }
    }

    /**
     * Below function would sort the daily summary overview data
     * @param sort represents materail sort event
     */
    public sortDailySummaryData(sort: Sort) {
        // exit immediately
        if (!this.fullData || !this.fullData.data) {
            return;
        }

        const data = this.fullData.data.slice();
        if (!sort.active || sort.direction === '') {
            this.tempData.data = data;
            this.pageData = data.slice(this.startIndex, this.startIndex + this.pageSize);
            return;
        }

        // sort daily summary overview data based on location field
        const sortedData = data.sort((currentItem: Array<DailySummaryData>, nextItem: Array<DailySummaryData>) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'Location':
                    return this.compare(currentItem[0].name.toLowerCase(), nextItem[0].name.toLowerCase(), isAsc);
                default:
                    return 0;
            }
        });

        this.tempData.data = sortedData;

        this.pageData = sortedData.slice(this.startIndex, this.startIndex + this.pageSize);
    }

    /**
     * Compare the default elements
     * @param current represents first element of array
     * @param next represents next element of array
     * @param isAsc represents ascending order
     */

    private compare(current: string, next: string, isAsc: boolean) {
        return (current < next ? -1 : 1) * (isAsc ? 1 : -1);
    }
}
