<div id="alramGraphDialog">
    <div class="popupHeader" mat-dialog-title>
        <span>{{ hydroLocationName }}</span>
        <span class="app-flex-filler"></span>
        <!-- <span>
            <mat-icon matTooltip="Filter"  (click)="showHydrographParmeter()">filter_list</mat-icon>
        </span> -->
    </div>
    <div [ngClass]="{ hideGraphTab: !showMultiGraph }">
        <mat-tab-group [selectedIndex]="selectedTabIndex" (selectedIndexChange)="selectedIndexChange($event)">
            <mat-tab>
                <ng-template mat-tab-label>
                    <div>
                        {{ 'COMMON.HYDROGRAPH_TEXT' | translate }}
                    </div>
                </ng-template>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>
                    <div>
                        {{ 'COMMON.SCATTERGRAPH_TEXT' | translate }}
                    </div>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div mat-dialog-content class="alramGraphContainer">
        <div class="graphPopupContainer">
            <div *ngIf="showMultiGraph" class="previousButton">
                <button
                    mat-button
                    class="lightBackGround"
                    matTooltip="{{ 'COMMON.PREVIOUS_TOOLTIP' | translate }}"
                    matTooltipPosition="right"
                    (click)="previousLocationGraph()"
                    [disabled]="noPrevData"
                >
                    <mat-icon>keyboard_arrow_left</mat-icon>
                </button>
            </div>
            <app-loader [isLoading]="graphLoadingState"></app-loader>
            <div [hidden]="selectedTabIndex !== 0 || graphLoadingState">
                <!-- <div class="WidgetFilter" id="annotations" *ngIf="showHydrographOptions">

                    <mat-card-subtitle>Alarm Thresholds</mat-card-subtitle>
                    <section>
                        <mat-checkbox class="print-inline" [checked]="isLowDepth" (change)="getLowDepth($event)">Low Depth</mat-checkbox>
                        <mat-checkbox class="print-inline" [checked]="isHighLevel" (change)="getHighLevel($event)">High Level</mat-checkbox>
                        <mat-checkbox class="print-inline" [checked]="isHighHigh" (change)="getHighHigh($event)">High High</mat-checkbox>
                    </section>
                </div> -->
                <div id="highChartIdAuto" [hidden]="noHydroDataFound">
                    <div
                        type="StockChart"
                        [chart]="options"
                        (selection)="onChartSelection($event)"
                        (load)="saveChart($event.context)"
                    ></div>
                </div>
                <div *ngIf="noHydroDataFound" class="notabledataFound">
                    <span>{{ 'HOME.ALARM_GRAPH.NO_DATA_FOUND' | translate }}</span>
                </div>
            </div>
            <div [hidden]="selectedTabIndex !== 1 || graphLoadingState">
                <div id="scatterChartIdAuto" [hidden]="noScatterDataFound">
                    <div [chart]="scatterOptions" (load)="saveScatterChart($event.context)"></div>
                </div>
                <div *ngIf="noScatterDataFound" class="notabledataFound">
                    <span>{{ 'HOME.ALARM_GRAPH.NO_DATA_FOUND' | translate }}</span>
                </div>
            </div>

            <div *ngIf="showMultiGraph" class="nextButton">
                <button
                    mat-button
                    class="lightBackGround"
                    matTooltip="{{ 'COMMON.NEXT_BTN' | translate }}"
                    matTooltipPosition="left"
                    (click)="nextLocationGraph()"
                    [disabled]="noNextData"
                >
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="auto-margin custom-flat-button" *ngIf="workOrderLink">
            <button
                class="external-link"
                mat-button
                matTooltip="Link to external Work Order application"
                (click)="openCustomerLink()"
            >
                <img src="../../assets/images/construction_worker.png" alt="Link to external Work Order application" />
            </button>
        </div>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="emitGraph()">{{ 'COMMON.CLOSE' | translate }}</button>
        </div>
    </div>
</div>
