import { Circle as CircleStyle, Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { getLength, getArea } from 'ol/sphere';
import { LineString, Point } from 'ol/geom';
import { transform } from 'ol/proj';
import { OLMAPPROJECTION } from '../constant';
import { LocationStatuses, MonitorSeries } from '../components/location-card/location-card.constants';
import { BehaviorSubject } from 'rxjs';
import { gisUserSettings } from '../models/gis-service-list';
import { Feature } from 'ol';
import { EventRain, RainEventSymbolColors } from '../models/event';
import Chart from '../utils/Chart';
import { MapRain, MapRainColors, StormYearsColors } from '../models/map-view';
import { IN_TO_MM_FACTOR, MM_TO_MM_FACTOR } from '../models/units';
import { LocationStatus } from '../models/locations';

export const lightThemeColors = {
    green: '#3fff00',
    orange: '#F79325',
    red: '#F2453D',
    grey: '#6F6E6E',
    maintenance: '#ffdf5b',
}
export const darkThemeColors = {
    green: '#24c200',
    orange: '#F79325',
    red: '#F2453D',
    grey: '#6F6E6E',
    maintenance: '#ffdf5b',
}

// used to create blank storm data if there was none coming from backend
const BLANK_STORM_DATA_DESCRIPTION = 'Under 1 year storm';

//var wgs84Sphere = new olSphere(6378137);
const styleCache = {};
const lengthRoundUp = (length, unit) => {
    let lengthtext = '';
    //length = length*111000;
    if (unit == 'metric') {
        if (length > 100) {
            lengthtext = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
        } else {
            lengthtext = Math.round(length * 100) / 100 + ' ' + 'm';
        }
    } else {
        length = length * 3.28084; // Puts length in feet
        if (length < 2640) {
            // Length up to 1/2 mile
            lengthtext = Math.round(length) + ' ' + 'ft';
        } else if (length < 26400) {
            // Length over 1/2 mile and under 5 miles
            // Show 2 decimal places on total length
            lengthtext = Math.round(length / 52.8) / 100 + ' ' + 'mi';
        } else {
            // Length 5 miles and above
            lengthtext = Math.round(length / 5280) + ' ' + 'mi';
        }
    }
    return lengthtext;
};

const areaRoundUp = (area, unit) => {
    let output;
    if (unit == 'metric') {
        if (area > 10000) {
            output = Math.round((area / 10000) * 100) / 100 + ' ' + 'sq. km';
        } else {
            output = Math.round(area * 100) / 100 + ' ' + 'sq. m';
        }
    } else {
        area = area * 10.76391; // Puts area in sq feet
        if (area < 6969600) {
            // Area up to 1/4 sq mile
            output = Math.round(area) + ' ' + 'sq. ft';
        } else if (area < 139392000) {
            // Area over 1/4 sq mile and under 5 sq miles
            // Show 2 decimal places on total area
            output = Math.round(area / 278784) / 100 + ' ' + 'sq. mi';
        } else {
            // Area over 5 sq miles
            output = Math.round(area / 27878400) + ' ' + 'sq. mi';
        }
    }
    return output;
};

const linemeasurement = (feature, unit) => {
    if (feature.getGeometry().getType() === 'LineString') {
        const stylearray = [];
        feature.getGeometry().forEachSegment((start, end) => {
            const midpoint1 = (start[0] + end[0]) / 2;
            const midpoint2 = (start[1] + end[1]) / 2;
            const linestring = new LineString([start, end]);
            const segmentDistance = lengthRoundUp(getLength(linestring), unit);
            // return new MultiPoint(start);
            const sgmentstyle = new Style({
                geometry: new Point([midpoint1, midpoint2]),
                text: new Text({
                    font: '12px Calibri,sans-serif',
                    overflow: true,
                    fill: new Fill({
                        color: '#000',
                    }),
                    stroke: new Stroke({
                        color: '#fff',
                        width: 3,
                    }),
                    text: segmentDistance,
                    textAlign: 'left',
                    offsetX: 0,
                }),
            });
            stylearray.push(sgmentstyle);
        });
        return stylearray;
    }
};

export const generateLinePointPolygonPopupTemplate = function (feature) {
    const toShowKeyValues = {};
    const type = feature.getGeometry().getType();
    const props = feature.getProperties();
    const propKeys = Object.keys(props);

    if (propKeys.some((v) => v.toLowerCase().includes('name'))) {
        propKeys.forEach((v) => {
            if (v.toLowerCase().includes('name') && props[v]) {
                toShowKeyValues[v] = props[v];
            }
        });
    } else if (propKeys.includes('ID') && props.ID) {
        toShowKeyValues['ID'] = props.ID;
    } else if (propKeys.includes('OBJECTID') && props.OBJECTID) {
        toShowKeyValues['OBJECTID'] = props.OBJECTID;
    }

    if (type === 'Point') {
        propKeys.forEach((v) => {
            if (v.toLowerCase().includes('depth') && props[v]) {
                toShowKeyValues[v] = props[v];
            }
        });
    }

    if (type === 'Line' || type === 'LineString') {
        propKeys.forEach((v) => {
            if (v.toLowerCase().includes('length') && props[v] !== null) {
                toShowKeyValues[v] = Math.round(props[v] * 100) / 100;
            }
        });

        propKeys.forEach((v) => {
            if (v.toLowerCase().includes('material') && props[v]) {
                toShowKeyValues[v] = props[v];
            }
        });
    }

    if (!Object.keys(toShowKeyValues).length) {
        return null;
    }
    const template = Object.keys(toShowKeyValues)
        .map((v) => {
            return `
      <div style="display: flex; align-items: center; justify-content: space-between; margin-top: 5px">
        <p style="margin: 0; color: #bbb; font-size: 10px">${v}: </p>
        <p style="margin: 0; font-weight: 400; margin-left: 15px"> ${toShowKeyValues[v]} </p>
      </div>
    `;
        })
        .join('');

    return `
    <div style="min-width: 200px; padding: 10px">
      ${template}
    </div>
  `;
};

export const pickSingleMonitorPopupTemplate = function(feature: Feature, isDarkTheme: boolean, rainEvent: EventRain[] | null) {
    if (!rainEvent || !rainEvent.length) {
        return generateSingleMonitorPopupTemplate(feature, isDarkTheme);
    }

    const stormData = getStormDataForMonitor(feature, rainEvent)
    return generateSingleMonitorStormPopup(feature, stormData);
}

const generateSingleMonitorStormPopup = function (feature: Feature, rainEvent: EventRain) {
    const color = RainEventSymbolColors[rainEvent.rtype];

    return `
    <div style="min-width: 200px;">
        <div style="background-color: ${color}; border-bottom: 1px solid #969696; color: rgba(0, 0, 0, 0.87); padding: 5px; text-align: center; font-weight: 500">${feature.get('Name')}</div>
        <div style="padding: 10px">

        <div style="display: flex; align-items: center; justify-content: space-between">
            <div style="min-width: 50%">
                <p style="margin: 0; font-size: 9px; color: #bbb">Rain Total: </p>
                <p style="margin: 0"> ${ rainEvent.res } ${rainEvent.runit} </p>
            </div>
            <div>
                <p style="margin: 0"> ${rainEvent.rdesc} </p>
            </div>
        </div>

        </div>
    </div>
    `
}

export const generateSingleMonitorPopupTemplate = function (feature: Feature, isDarkTheme: boolean) {
    const locationStatus = LocationStatuses.find((v) => v.key === feature.get('Status'));
    const monitorSeries = MonitorSeries.find(a => a.value === feature.get('monitorSeriesModelName'))?.text;
    return `
    <div style="min-width: 200px;">
      <div style="background-color: ${getMonitorColor(
          feature, isDarkTheme
      )}; padding: 5px; text-align: center; font-weight: 500">${feature.get('Name')}</div>
      <div style="padding: 10px">

        <div style="display: flex; align-items: center; justify-content: space-between">
          <div>
            <p style="margin: 0; font-size: 9px; color: #bbb">Monitor Series: </p>
            <p style="margin: 0"> ${
                monitorSeries ? monitorSeries : feature.get('monitorSeriesModelName')
            } </p>
          </div>
          <div>
            <p style="margin: 0; font-size: 9px; color: #bbb">Serial Number: </p>
            <p style="margin: 0"> ${feature.get('SerialNumber') ? feature.get('SerialNumber') : ''} </p>
          </div>
        </div>

        <div style="display: flex; align-items: center; justify-content: space-between">
          <div>
            <p style="margin: 0; font-size: 9px; color: #bbb">Status: </p>
            <p style="margin: 0"> ${locationStatus ? locationStatus.name : ''} </p>
          </div>

          ${
              feature.get('ConnectionString')
                  ? `
          <div>
            <p style="margin: 0; font-size: 9px; color: #bbb">IP Address: </p>
            <p style="margin: 0"> ${feature.get('ConnectionString')} </p>
          </div>
          `
                  : ''
          }
        </div>

        ${
            feature.get('ManholeAddress')
                ? `
        <div>
          <p style="margin: 0; font-size: 9px; color: #bbb">Manhole Address: </p>
          <p style="margin: 0"> ${feature.get('ManholeAddress')} </p>
        </div>
        `
                : ''
        }

      </div>
    </div>
  `;
};

export const generateCompositeLocationPopupTemplate = function (name: string, status: number) {
    const locationStatus: string = status ? 'Active' : 'Inactive';

    return `
  <div style="min-width: 200px;">
    <div style="background-color: #3fff00; padding: 5px; text-align: center; font-weight: 500">COMPOSITE LOCATION</div>
    <div style="padding: 10px">

      <div style="display: flex; align-items: center; justify-content: space-between">
        <div>
          <p style="margin: 0; font-size: 9px; color: #bbb">Name: </p>
          <p style="margin: 0"> ${name} </p>
        </div>
        <div>
          <p style="margin: 0; font-size: 9px; color: #bbb">Status: </p>
          <p style="margin: 0"> ${locationStatus} </p>
        </div>
      </div>

    </div>
  </div>
  `;
};

export const generateLocationsClusterPopupTemplate = function (features: Feature[], isDarkTheme: boolean, rainEvents: EventRain[] | null) {
    if (features.length === 2) {
        const [first, second] = features;
        if (first.get('Name').replace('(2)', '') === second.get('Name').replace('(2)', '')) {
            // check for 2mps on one location
            return mmpPopupTemplate(features, isDarkTheme);
        }
    }

    features.sort(function (a, b) {
        if (a.get('Name') < b.get('Name')) {
            return -1;
        }
        if (a.get('Name') > b.get('Name')) {
            return 1;
        }
        return 0;
    });

    if (rainEvents && rainEvents.length) {
        const clusterColor = RainEventSymbolColors[Math.max(...features.map(v => getStormDataForMonitor(v, rainEvents).rtype))];
        const stormsBlock = features
            .map((v) => {
                const stormData = getStormDataForMonitor(v, rainEvents);

                return `
                <div style="display: flex; align-items: end;">
                    ${stormMonitorDot(stormData.rtype)}
                    <div style="margin-left: 10px; width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">${v.get('Name')}</div>
                    <div style="margin-left: 15px; width: 70px">
                        <p style="margin: 0; font-size: 9px; color: #bbb">Rain Total: </p>
                        <p style="margin: 0"> ${ stormData.res } ${stormData.runit} </p>
                    </div>
                    <div>
                        <p style="margin: 0; margin-left: 20px"> ${stormData.rdesc} </p>
                    </div>
                </div>
                `;
            })
            .join('');

        return `
            <div style="min-width: 200px;">
            <div style="background-color: ${clusterColor}; padding: 5px; text-align: center; font-weight: 500">CLUSTER</div>
            <div style="padding: 10px">
                ${stormsBlock}
            </div>
            </div>
            `;
    }
    const featuresBlock = features
        .map((v) => {
            return `
      <div style="display: flex; align-items: center;">
        ${clusterLocationDot(v, isDarkTheme)}
        <div style="margin-left: 10px">${v.get('Name')}</div>
      </div>
    `;
        })
        .join('');

    return `
  <div style="min-width: 200px;">
    <div style="background-color: ${getClusterHeaderColor(
        features, isDarkTheme
    )}; padding: 5px; text-align: center; font-weight: 500">CLUSTER</div>
    <div style="padding: 10px">
        ${featuresBlock}
    </div>
  </div>
  `;
};

const mmpPopupTemplate = function (features, isDarkTheme) {
    const first = features.find((v) => !v.get('Name').includes('(2)'));
    const second = features.find((v) => v.get('Name').includes('(2)'));
    const firstLocationStatus = LocationStatuses.find((v) => v.key === first.get('Status'));
    const secondLocationStatus = LocationStatuses.find((v) => v.key === second.get('Status'));
    return `
    <div style="min-width: 200px;">
      <div style="background-color: ${getClusterHeaderColor(
          features, isDarkTheme
      )}; padding: 5px; text-align: center; font-weight: 500">${first.get('Name')}, ${second.get('Name')}</div>
      <div style="padding: 10px">

        <div style="display: flex; align-items: center; justify-content: space-between">
          <div>
            <p style="margin: 0; font-size: 9px; color: #bbb">Monitor Series: </p>
            <p style="margin: 0"> ${
                features[0].get('monitorSeriesModelName') ? features[0].get('monitorSeriesModelName') : ''
            } </p>
          </div>
          <div>
            <p style="margin: 0; font-size: 9px; color: #bbb">Serial Number: </p>
            <p style="margin: 0"> ${features[0].get('SerialNumber') ? features[0].get('SerialNumber') : ''} </p>
          </div>
        </div>

        <div style="display: flex; align-items: center; justify-content: space-between">
          <div>
            <p style="margin: 0; font-size: 9px; color: #bbb">${first.get('Name')} Status: </p>
            <p style="margin: 0"> ${firstLocationStatus ? firstLocationStatus.name : ''} </p>
          </div>

          <div>
            <p style="margin: 0; font-size: 9px; color: #bbb">${second.get('Name')} Status: </p>
            <p style="margin: 0"> ${secondLocationStatus ? secondLocationStatus.name : ''} </p>
          </div>
        </div>

        ${
            features[0].get('ConnectionString')
                ? `
          <div>
            <p style="margin: 0; font-size: 9px; color: #bbb">IP Address: </p>
            <p style="margin: 0"> ${features[0].get('ConnectionString')} </p>
          </div>
        `
                : ''
        }

        ${
            features[0].get('ManholeAddress')
                ? `
        <div>
          <p style="margin: 0; font-size: 9px; color: #bbb">Manhole Address: </p>
          <p style="margin: 0"> ${features[0].get('ManholeAddress')} </p>
        </div>
        `
                : ''
        }

      </div>
    </div>
  `;
};

const clusterLocationDot = function (feature, isDarkTheme) {
    return `<div style="width: 8px; height: 8px; background: ${getMonitorColor(feature, isDarkTheme)}; border-radius: 50%;"></div>`;
};

const stormMonitorDot = function (type: number) {
    return `<div style="width: 8px; height: 8px; background: ${RainEventSymbolColors[type]}; border-radius: 50%; margin-bottom: 7px;
        margin-left: 5px; border: 1px solid"></div>`;
};

const getStormDataForMonitor = function(feature: Feature, rainEvent: EventRain[]) {
    let stormData = rainEvent.find(v => v.lid === feature.get('LocationID'));

    // if there is no storm data, diplay minimum values
    if (!stormData) {
        stormData = {
            lid: feature.get('LocationId'),
            rdesc: BLANK_STORM_DATA_DESCRIPTION,
            res: 0,
            rtype: 0,
            runit: rainEvent[0].runit
        };
    }

    return stormData;
}
const getMonitorColor = function (feature, isDarkTheme: boolean) {
    const properties = feature.getProperties();

    let colors = isDarkTheme ? darkThemeColors.green : lightThemeColors.green;

    if (properties.Status === LocationStatus.Inactive || properties.Status === LocationStatus.InactiveRemoved) {
        colors = isDarkTheme ? darkThemeColors.grey : lightThemeColors.grey;
    } else if (properties.Status === LocationStatus.Maintenance) {
        colors = isDarkTheme ? darkThemeColors.maintenance : lightThemeColors.maintenance;
    }

    if (properties.AlarmId) {
        if (properties.AcknowledgedBy) {
            colors = isDarkTheme ? darkThemeColors.orange : lightThemeColors.orange;
        } else {
            colors = isDarkTheme ? darkThemeColors.red : lightThemeColors.red;
        }
    }

    return colors;
};

const getClusterHeaderColor = function (features, isDarkTheme) {
    const props = features.map((v) => v.getProperties());

    if (props.some((v) => v.AlarmId && !v.AcknowledgedBy)) {
        return isDarkTheme ? darkThemeColors.red : lightThemeColors.red;
    }

    if (props.some((v) => v.AlarmId && v.AcknowledgedBy)) {
        return isDarkTheme ? darkThemeColors.orange : lightThemeColors.orange;
    }

    if (props.some((v) => v.IsActiveLocation)) {
        return isDarkTheme ? darkThemeColors.green : lightThemeColors.green;
    }

    return isDarkTheme ? darkThemeColors.grey : lightThemeColors.grey;
};

export const highlightLineOrPoint = (feature, unit, color) => {
  const geom = feature.getGeometry();
  const style = new Style({
      fill: new Fill({
          color,
      }),
      stroke: new Stroke({
          color,
          lineDash: null,
          width: 4,
      }),
  });
  if (feature.getGeometry().getType() === 'LineString') {
      //let pointlabel = [];
      const pointlabel = highlightLine(feature, unit, color);
      pointlabel.push(style);
      return pointlabel;
  } else {
      // Projection is default but good to make sure it uses here too. Returned area is in sq meters
      return new Style({
          fill: new Fill({
              color,
          }),
          stroke: new Stroke({
              color,
              lineDash: null,
              width: 4,
          }),
          image: new CircleStyle({
              radius: 8,
              stroke: new Stroke({
                  color,
              }),
              fill: new Fill({
                  color,
              }),
          }),
      });
  }
}

export const highlightLine = (feature, unit, color) => {
  if (feature.getGeometry().getType() === 'LineString') {
    const stylearray = [];
    feature.getGeometry().forEachSegment((start, end) => {
        const midpoint1 = (start[0] + end[0]) / 2;
        const midpoint2 = (start[1] + end[1]) / 2;
        // return new MultiPoint(start);
        const sgmentstyle = new Style({
            geometry: new Point([midpoint1, midpoint2]),
        });
        stylearray.push(sgmentstyle);
    });
    return stylearray;
}
}

export const measureStyle = (feature, unit) => {
    const geom = feature.getGeometry();
    const output = lengthRoundUp(getLength(geom), unit);
    const style = new Style({
        fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.5)',
            lineDash: [10, 10],
            width: 2,
        }),
    });
    const endlabel = new Style({
        geometry: new Point(geom.getLastCoordinate()),
        text: new Text({
            font: '12px Calibri,sans-serif',
            overflow: true,
            fill: new Fill({
                color: '#000',
            }),
            stroke: new Stroke({
                color: '#fff',
                width: 3,
            }),
            text: output,
            textAlign: 'left',
            offsetX: 0,
        }),
    });
    if (feature.getGeometry().getType() === 'LineString') {
        //let pointlabel = [];
        const pointlabel = linemeasurement(feature, unit);
        pointlabel.push(style);
        pointlabel.push(endlabel);
        return pointlabel;
    } else {
        // Projection is default but good to make sure it uses here too. Returned area is in sq meters
        const area = getArea(geom, { projection: OLMAPPROJECTION });
        return new Style({
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)',
            }),
            stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0.5)',
                lineDash: [10, 10],
                width: 2,
            }),
            image: new CircleStyle({
                radius: 5,
                stroke: new Stroke({
                    color: 'rgba(0, 0, 0, 0.7)',
                }),
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)',
                }),
            }),
            text: new Text({
                font: '12px Calibri,sans-serif',
                overflow: true,
                fill: new Fill({
                    color: '#000',
                }),
                stroke: new Stroke({
                    color: '#fff',
                    width: 3,
                }),
                text: areaRoundUp(area, unit),
                textAlign: 'left',
                offsetX: 0,
            }),
        });
    }
};

export const measureDrawStyle = () => {
    return new Style({
        fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
        }),
        stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.5)',
            lineDash: [10, 10],
            width: 2,
        }),
        image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
                color: 'rgba(0, 0, 0, 0.7)',
            }),
            fill: new Fill({
                color: 'rgba(255, 255, 255, 0.2)',
            }),
        }),
    });
};

export const positionFeatureStyle = () => {
    return new Style({
        image: new CircleStyle({
            radius: 6,
            fill: new Fill({
                color: '#3399CC',
            }),
            stroke: new Stroke({
                color: '#fff',
                width: 2,
            }),
        }),
    });
};

export const accuracyFeatureStyle = () => {
    return new Style({
        image: new CircleStyle({
            radius: 6,
            fill: new Fill({
                color: '#3399CC',
            }),
            stroke: new Stroke({
                color: '#fff',
                width: 2,
            }),
        }),
    });
};

/*
 * Monitor Location Style
 */

const imagepath = '../../assets/images/icons/';
const thirdpartyImg = '3rdparty.png';
const flowImg = 'flow.png';
const levelImg = 'level.png';
const rainImg = 'rain.png';

export const monitorLocation = (
    feature: Feature,
    origCcolor: string,
    showLabels: BehaviorSubject<gisUserSettings>,
    barChart: MapRain[],
    symbolColor = null,
    whiteLocations = false
) => {
    const properties = feature.getProperties();
    let imagename = imagepath + thirdpartyImg;
    if (properties.symbolType == 'FlowMonitor') {
        imagename = imagepath + flowImg;
    } else if (properties.symbolType == 'LevelMonitor') {
        imagename = imagepath + levelImg;
    } else if (properties.symbolType == 'RainMonitor') {
        imagename = imagepath + rainImg;
    }

    let color = origCcolor;
    const style = [];
    if (barChart) {
        const currentMonitorRainData = barChart.find(v => v.lid === properties.LocationID);
        const max = Math.max(...barChart.map(v => v.res));

        if (currentMonitorRainData) {
            const graphValue = currentMonitorRainData.showBar !== false ? currentMonitorRainData.res * 30 / max : null;
            color = currentMonitorRainData.rtype !== undefined ? RainEventSymbolColors[currentMonitorRainData.rtype] : color;
            style.push(generateBarChartForMonitor(currentMonitorRainData, graphValue, color));
        } else {
            const graphValue = 0;
            color = RainEventSymbolColors[0];
            style.push(generateBarChartForMonitor({ rtype: 0, res: 0, lid: properties.LocationID, showBar: false, showEmpty: false}, graphValue, color));
        }
    }

    const featureColor = whiteLocations || !origCcolor ? color : origCcolor;

    if (showLabels.getValue().showLocationLabels) {
      style.push(new Style({
          image: new Icon({
              scale: 0.5,
              color: symbolColor ? symbolColor : featureColor,
              src: imagename,
          }),
          text: new Text({
            overflow: true,
            fill: new Fill({
                color: featureColor,
            }),
            stroke: new Stroke({
              color: '#000',
              width: 3,
            }),
            text: properties.Name,
            textAlign: 'center',
            offsetY: 20,
            offsetX: 0
        }),
      }));
    } else {
        style.push(new Style({
          image: new Icon({
              scale: 0.5,
              color: symbolColor ? symbolColor : featureColor,
              src: imagename,
          }),
        }));
    }

    return style;
};

function generateBarChartForMonitor(rainData: MapRain, graphValue: number, symbolColor: string) {
    const precision = rainData.runit === 'mm' ? 1 : 2;
    const rainValue = Number(rainData.res);
    const rainDisplayValue = rainValue.toFixed(precision);
    const rainRoundedValue = Number(rainDisplayValue);
    const rainFactor = rainData.runit === 'mm' ? IN_TO_MM_FACTOR : MM_TO_MM_FACTOR;

    const options = {
        zIndex: 999,
        image: null,
        text: null
    };

    if(rainData.showEmpty !== false || (rainData.res !== null && rainData.res !== undefined)) {
        let backgroundColor = '#fff';
        let textColor = '#000';
        // #33578 if rainData contains field rtype then it is a EVENT
        if(rainData.rtype !== undefined && rainData.rtype !== null) {
            const colorDef = StormYearsColors.find(c => c.rtype === rainData.rtype)
            if(colorDef) {
                backgroundColor = colorDef.color;
                textColor = colorDef.textColor;
            }
        } else {
            const colors = MapRainColors;
            for(const c of colors) {
                const lower = Number((c.range[0]*rainFactor).toFixed(precision));
                const upper = Number((c.range[1]*rainFactor).toFixed(precision));
                if(rainRoundedValue >= lower && rainRoundedValue <= upper) {
                    backgroundColor = c.color;
                    textColor = c.textColor;
                    break;
                }
            }
        }

        options.text = new Text({
            font: '12px Arial',
            overflow: true,
            backgroundFill: new Fill({
                color: backgroundColor
            }),
            fill: new Fill({
                color: textColor,
            }),
            text: rainDisplayValue,
            textAlign: 'center',
            offsetY: 0,
            offsetX: 25
        });
    }

    if(graphValue !== undefined && graphValue !== null) {
        options.image = new Chart({
            type: 'bar',
            data: [graphValue],
            colors: ['#006ac2'],
            rotateWithView: true,
            radius: graphValue,
            offsetY: -graphValue,
            stroke: new Stroke({
                color: '#006ac2',
                width: 4
            })
        });
    }

    return new Style(options);
}

export const monitorLegendOptions = () => {
    return [
        { type: 'FlowMonitor', label: 'Flow Monitor', url: getLegendIconUrl(flowImg) },
        { type: 'LevelMonitor', label: 'Level Monitor', url: getLegendIconUrl(levelImg) },
        { type: 'RainMonitor', label: 'Rain Gauge', url: getLegendIconUrl(rainImg) },
        { type: 'Other', label: 'Other', url: getLegendIconUrl(thirdpartyImg) },
    ];
};
function getLegendIconUrl(imgName: string) {
    // Map icons are solid filled, ones in the legend are not
    // But want to keep same URL for cleanliness
    return imagepath + imgName.slice(0, -4) + "monitor" + imgName.slice(-4, imgName.length);
}

export const monitorStyleFilter = (
    currentLocations: BehaviorSubject<number[]>,
    barChart: BehaviorSubject<MapRain[]>,
    showLabels: BehaviorSubject<gisUserSettings>,
    pickColor: (feat: Feature) => string,
    whiteLocations: BehaviorSubject<boolean>,
    isDarkTheme: BehaviorSubject<boolean>
) => (feature: Feature) => {
    const features: Feature[] = feature.get('features');

    const isDisplayRains = barChart.getValue() !== null;

    if (!isDisplayRains && !currentLocations.getValue().length) {
        return monitorLocationStyle(showLabels, features, pickColor, barChart.getValue(), whiteLocations.getValue(), isDarkTheme.getValue());
    } else {
        const filteredFeatures = features.filter(v => currentLocations.getValue().includes(v.get('LocationID')));
        return monitorLocationStyle(showLabels, filteredFeatures, pickColor, barChart.getValue(), whiteLocations.getValue(), isDarkTheme.getValue());
    }
}

export const monitorLocationStyle = (
    showLabels: BehaviorSubject<gisUserSettings>,
    features: Feature[],
    pickColor: (feat: Feature) => string,
    barChart: MapRain[] = null,
    whiteLocations: boolean = null,
    isDarkTheme: boolean = false
) => {
    const size = features.length;
    const isDoubleMonitor = size === 2 && features[0].get('Name').replace('(2)', '').trim() === features[1].get('Name').replace('(2)', '').trim();
    let style;

    const isInactive = features.every(v => !v.get('IsActiveLocation'));
    const colors: string[] = features.map(v => pickColor(v));

    let color;

    if(isDarkTheme) {
        color =
            colors.includes(darkThemeColors.red) ? darkThemeColors.red
            : colors.includes(darkThemeColors.orange) ? darkThemeColors.orange
            : colors.includes(darkThemeColors.maintenance) ? darkThemeColors.maintenance
            : darkThemeColors.green;
    } else {
        color =
            colors.includes(lightThemeColors.red) ? lightThemeColors.red
            : colors.includes(lightThemeColors.orange) ? lightThemeColors.orange
            : colors.includes(lightThemeColors.maintenance) ? lightThemeColors.maintenance
            : lightThemeColors.green;
    }

    if (isInactive) {
        color = isDarkTheme ? darkThemeColors.grey : lightThemeColors.grey;
    }

    if (whiteLocations && barChart && barChart.length) {
        // need to pick color of highest frequency storm data
        color = RainEventSymbolColors[Math.max(...barChart.filter(v => features.find(i => v.lid === i.get('LocationID'))).map(v => v.rtype))];
    }

    if (size > 1) {
        if (!style) {
            style = new Style({
                image: new CircleStyle({
                    radius: 10,
                    stroke: new Stroke({
                        color: '#000',
                        width: 3,
                    }),
                    fill: new Fill({
                        color,
                    }),
                }),
                text: new Text({
                    text: size.toString(),
                    fill: new Fill({
                        color: '#000',
                    }),
                }),
            });
        }
    } else if (size === 1) {
        const originalFeature = features[0];
        style = monitorLocation(originalFeature, color, showLabels, barChart, null, whiteLocations);
    }

    if (isDoubleMonitor && showLabels.getValue().showLocationLabels) {
      const textStyle = new Style({
        text: new Text({
          font: '12px Arial',
          overflow: true,
          fill: new Fill({
              color,
          }),
          stroke: new Stroke({
              color: '#000',
              width: 3,
          }),
          text: features[0].get('Name').replace(' (2)', '') + ' 1/2',
          textAlign: 'center',
          offsetY: 20,
          offsetX: 0
        })
      });

      return [style, textStyle];
    }
    return style;
};

export const featureSelectionStyle = () => {
    return new Style({
        fill: new Fill({
            color: 'yellow',
        }),
        stroke: new Stroke({
            color: 'yellow',
            lineDash: [10, 10],
            width: 5,
        }),
        image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
                color: 'rgba(255, 255, 255, 0.7)',
            }),
            fill: new Fill({
                color: 'yellow',
            }),
        }),
    });
};
