<div>
    <p class="panel-error-style" *ngIf="!file.isValidationSuccessful">
        <mat-icon>error</mat-icon>
        <span *ngIf="file.validation === 'notfound'"> {{ 'COMMON.FILE_NOT_FOUND' | translate }}. </span>
        <span *ngIf="file.validation === 'invaliddateformat'"> {{ 'COMMON.INVALID_DATE_FORMAT' | translate }}. </span>
        <span *ngIf="file.validation === 'invalidlocationname'">{{ 'VAULT.VAULT_TELEMETRY.IMPORT.LOCATION_DOES_NOT_EXIST' | translate }}. </span>
        <span>{{ 'VAULT.VAULT_TELEMETRY.IMPORT.FAILED_VALIDATION_DIALOG_CONTENT' | translate }}</span>
    </p>
    <mat-form-field class="full-width">
        <input
            matInput
            name="filePath"
            placeholder="{{ 'VAULT.VAULT_TELEMETRY.IMPORT.FILE_PATH_PLACEHOLDER' | translate }}"
            readonly
            value="{{ file.id }}"
        />
    </mat-form-field>
    <mat-form-field class="full-width">
        <input
            matInput
            name="customer"
            placeholder="{{ 'COMMON.CUSTOMER_TEXT' | translate }}"
            readonly
            value="{{ file.customerFullName }}"
        />
    </mat-form-field>
    <mat-form-field class="full-width">
        <input
            matInput
            name="date"
            placeholder="{{ 'COMMON.DATE_RANGE' | translate }}"
            readonly
            value="{{ file.startDate }} - {{ file.endDate }}"
        />
    </mat-form-field>
    <mat-form-field class="full-width">
        <input
            matInput
            name="entities"
            placeholder="{{ 'COMMON.ENTITIES_TEXT' | translate }}"
            readonly
            value="{{ file.entities }}"
        />
    </mat-form-field>
    <mat-form-field class="full-width">
        <input
            matInput
            name="location"
            placeholder="{{ 'COMMON.LOCATION_COLUMN_TITLE' | translate }}"
            readonly
            value="{{ file.locationName }}"
        />
    </mat-form-field>
</div>
