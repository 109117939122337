import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    ViewChild,
    OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FTPService } from 'app/shared/services/ftp-service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { FtpSweepModel } from 'app/shared/models/ftp-sweep';
import { AdsPrismFtpAddEditComponent } from '../ads-prism-ftp-add-edit/ads-prism-ftp-add-edit.component';
import { customerEditorQueryParam } from 'app/shared/models/customer';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-ads-prism-ftp-sweep',
    templateUrl: './ads-prism-ftp-sweep.component.html',
    styleUrls: ['./ads-prism-ftp-sweep.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdsPrismFtpSweepComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator) public paginator: MatPaginator;
    @ViewChild(MatSort) public sort: MatSort;
    public ftpDataSource;
    public ftpDescriptionColumns = ['protocol', 'server', 'action'];

    public protocols = ['SFTP', 'FTP', 'FTPS'];

    public customerId = 0;

    public loadingState = false;

    public showpageHint = false;
    private subscriptions = new Subscription();
    private addDialogRef: MatDialogRef<AdsPrismFtpAddEditComponent>;
    constructor(
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        private uiUtilsService: UiUtilsService,
        private activeRoute: ActivatedRoute,
        private ftpService: FTPService,
        private domOperationUtilsService: DomOperationUtilsService
    ) { }

    ngOnInit() {
        this.subscriptions.add(this.domOperationUtilsService.ftpDialogOpened.subscribe((shouldOpen: boolean) => {
            if (shouldOpen && !this.addDialogRef) {
                this.addNewFTPServer();
            } else if (!shouldOpen && this.addDialogRef) {
                this.addDialogRef.close({});
                this.addDialogRef = null;
            }

            this.uiUtilsService.safeChangeDetection(this.cdr);
        }));
        // get current query params for customer
        this.customerId = Number(this.activeRoute.snapshot.queryParamMap.get(customerEditorQueryParam));
        this.refreshTable();
    }

    public addNewFTPServer() {
        this.addDialogRef = this.dialog
            .open(AdsPrismFtpAddEditComponent, {
                disableClose: true,
                hasBackdrop: false
            });

        this.domOperationUtilsService.ftpDialogOpened.next(true);
        
        this.addDialogRef.afterClosed().subscribe((result) => {
            this.addDialogRef = null;
            this.domOperationUtilsService.ftpDialogOpened.next(false);
            if (result.success) {
                this.refreshTable();
            }
        });
    }

    public deleteFTPServer(guid) {
        this.ftpService.deleteFtpServer(this.customerId, guid).subscribe((res) => {
            if (res && res['isError']) {
                this.snackBar.open('Error!', 'Technical error', {
                    panelClass: 'custom-error-snack-bar',
                });
            } else {
                this.snackBar.open('Success!', 'Service Deleted.', {
                    duration: 10000,
                    panelClass: 'custom-success-snack-bar',
                });
                this.ftpDataSource.data = this.ftpDataSource.data.filter((o) => o.guid !== guid);
                this.uiUtilsService.safeChangeDetection(this.cdr);
            }
        });
    }

    public editFTPServer(server) {
        this.addDialogRef = this.dialog
            .open(AdsPrismFtpAddEditComponent, {
                disableClose: true,
                data: server,
                hasBackdrop: false
            });

        this.domOperationUtilsService.ftpDialogOpened.next(true);
        this.addDialogRef.afterClosed().subscribe((result) => {
            this.addDialogRef = null;
            this.domOperationUtilsService.ftpDialogOpened.next(false);
                if (result.success) {
                    this.refreshTable();
                }
        });
    }

    private refreshTable() {
        this.loadingState = true;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        this.ftpService.getFtpServers(this.customerId).subscribe(
            (data: Array<FtpSweepModel>) => {
                if (data) {
                    this.ftpDataSource = new MatTableDataSource(data);
                } else {
                    this.ftpDataSource = new MatTableDataSource([]);
                }
                this.ftpDataSource.paginator = this.paginator;
                this.ftpDataSource.sort = this.sort;
                this.loadingState = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (err) => {
                this.loadingState = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
