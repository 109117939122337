import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'secondsToSampleRatePipe',
})
export class SecondsToSampleRatePipe implements PipeTransform {
    public transform(value: number): string {
        if (value === 0) {
            return "OFF"
        }

        value = value / 60;
        if (value === 1)
        {
            return `${value} minute`;
        } else if (value < 60) {
            return `${value} minutes`; 
        }
        else {
            value = value / 60;
            if (value === 1) {
                return `${value} hour`;
            }

            return `${value} hours`;
        }
    }
}