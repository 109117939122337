import { Component, ViewEncapsulation } from '@angular/core';
import { AuthService } from './shared/services/auth.service';

// import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
    /**
     * Creates an instance of AppComonent.
     * @param authService Represents a reference to the Authentication Service.
     */

    constructor(private authService: AuthService) {
        // this is a hack fix to get rid of id_token in the URL
        // hopefully we can figure out why the token is there
        if (window.location.hash.search('id_token=') > 0) {
            window.location.hash = '';
        }

        // set default language
        // this.translate.setDefaultLang('en-us');

        // this.translate.use('en-us');
    }

    /**
     * Ensures authentication.
     */
    public get isUserAuthenticated() {
        return this.authService.isAuthenticated;
    }
}
