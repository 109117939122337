import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Inject,
    ChangeDetectorRef,
} from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { FormControl } from '@angular/forms';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { CustomerService } from 'app/shared/services/customer.service';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { Selectable } from 'app/shared/models/selectable';
import { UserPreference } from 'app/shared/models/user-preferences';
import {
    GetCustomAlarmsSummary,
    GetLocationsRainGaugeResponse,
    GetRainGaugeResponse,
} from 'app/shared/models/customer';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MM_TO_INCHES_CONVERSION } from 'app/shared/constant';
@Component({
    selector: 'ads-prims-location-wet-dry-overflow',
    templateUrl: './location-wet-dry-overflow.component.html',
    styleUrls: ['./location-wet-dry-overflow.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationWetDryOverflowComponent implements OnInit {
    public wetdryoverflowDataSource = new MatTableDataSource();
    public isEditButtonDisabled = true;
    public isLoading: boolean;
    public levellist: any[];
    // Holds all the updated locations with their added rain gauge ids
    private allUpdatedLocationsArray = new Array<any>();
    private checkedGauges = new Array<any>();
    public selectAll = false;
    public selectedRainGauges: Array<Selectable>;
    public userPreference = new UserPreference();
    public highLevelText: string;
    public middleLevelText: string;
    public lowLevelText: string;
    public rainGauge: any[];
    public gaugeErrMsg: string;
    public alarmUpdateSuccess: string;
    public alarmUpdateErr: string;
    public dismissText: string;
    public customerSearch = new FormControl();
    public filteredAssignedRainGauge: GetRainGaugeResponse;
    public filterdGauges: GetRainGaugeResponse;
    public cid: number;
    public alarmType = [];
    public rainGaugesList: GetLocationsRainGaugeResponse[] = [];
    public threshold: number;
    public isMetricUnitType = false;
    public period: number;
    public wetDryToggle: boolean;
    public isRaingaugeAvailable: boolean;
    private isValidMetric: boolean;
    public highLevel: string;
    public highHigh: string;
    public overFlow: string;
    public translateKeys: Array<string> = [
        'COMMON.DISMISS_TEXT',
        'CUSTOMER_EDITOR.EDIT_WET_DRY_OVERFLOW.CUSTOMER_ALARM_SUCCESS',
        'CUSTOMER_EDITOR.EDIT_WET_DRY_OVERFLOW.RAIN_GAUGE_ADD_ERR',
        'CUSTOMER_EDITOR.EDIT_WET_DRY_OVERFLOW.CUSTOMER_ALARM_ERR',
        'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.HIGHLEVEL',
        'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.MIDDLELEVEL',
        'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.LOWLEVEL',
        'COMMON.HIGH_LEVEL_ALARM',
        'COMMON.HIGH_HIGH_ALARAM',
        'COMMON.OVERFLOW_ALARM',
        'CUSTOMER_EDITOR.WET_DRY_OVERFLOW.TIME_UNIT',
    ];
    /**
     * Reg Ex. Pattern for only numeric values
     */
    public numericPattern3Decimal = REGEX_CONFIG.numericDecimal3PlacePattern;
    /**
     * Reg Ex. Pattern for only numeric values
     */
    public numericPattern = REGEX_CONFIG.numericDecimal2PlacePattern;
    /**
     * Reg Ex. Pattern for only numeric values
     */
    public numeric1_72Pattern = REGEX_CONFIG.numeric1_72Pattern;
    public numeric1_60Pattern = REGEX_CONFIG.numeric1_60Pattern;
    public alarmRainUnitValue: number;
    public eid: number;
    public alarmTypeID: number;
    public alarmPeriodValue: number;
    public alarmCollectionValue: number;
    public isWetDryAvailable: boolean;
    constructor(
        public wetdryDialog: MatDialogRef<LocationWetDryOverflowComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translate: TranslateService,
        private uiUtilsService: UiUtilsService,
        private cdr: ChangeDetectorRef,
        private customerService: CustomerService,
        private snackBar: SnackBarNotificationService,
    ) {
        translate.get(this.translateKeys).subscribe((translateValues) => {
            this.dismissText = translateValues['COMMON.DISMISS_TEXT'];
            this.gaugeErrMsg = translateValues['CUSTOMER_EDITOR.EDIT_WET_DRY_OVERFLOW.RAIN_GAUGE_ADD_ERR'];
            this.alarmUpdateSuccess = translateValues['CUSTOMER_EDITOR.EDIT_WET_DRY_OVERFLOW.CUSTOMER_ALARM_SUCCESS'];
            this.alarmUpdateErr = translateValues['CUSTOMER_EDITOR.EDIT_WET_DRY_OVERFLOW.CUSTOMER_ALARM_ERR'];
            this.highLevelText = translateValues['CUSTOMER_EDITOR.WET_DRY_OVERFLOW.HIGHLEVEL'];
            this.middleLevelText = translateValues['CUSTOMER_EDITOR.WET_DRY_OVERFLOW.MIDDLELEVEL'];
            this.lowLevelText = translateValues['CUSTOMER_EDITOR.WET_DRY_OVERFLOW.LOWLEVEL'];
            this.highLevel = translateValues['COMMON.HIGH_LEVEL_ALARM'];
            this.highHigh = translateValues['COMMON.HIGH_HIGH_ALARAM'];
            this.overFlow = translateValues['COMMON.OVERFLOW_ALARM'];
        });
        this.customerSearch.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe((res: string) => {
            this.filterCustomers(res);
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
    }
    public ngOnInit() {
        this.customerService.isUnitTypeMetric.subscribe((val) => {
            if (val) {
                // converting in to mm if metric
                const rainValue = this.alarmRainUnitValue;
                this.alarmRainUnitValue = Number((rainValue));
                this.isMetricUnitType = true;
                if (
                    (this.alarmRainUnitValue && Number(this.alarmRainUnitValue) < 0.254) ||
                    Number(this.alarmRainUnitValue) > 381 ||
                    Number(this.alarmRainUnitValue) === 0
                ) {
                    this.isEditButtonDisabled = true;
                    this.isValidMetric = false;
                } else {
                    this.isEditButtonDisabled = false;
                    this.isValidMetric = true;
                }
            } else if (
                (this.alarmRainUnitValue && Number(this.alarmRainUnitValue) < 0.01) ||
                Number(this.alarmRainUnitValue) > 15 ||
                Number(this.alarmRainUnitValue) === 0
            ) {
                this.alarmRainUnitValue = Number(this.alarmRainUnitValue.toFixed(2));
                this.isEditButtonDisabled = true;
                this.isValidMetric = false;
                this.isMetricUnitType = false;
            } else {
                this.isEditButtonDisabled = false;
                this.isValidMetric = true;
                this.isMetricUnitType = false;
            }
            this.isEditButtonDisabled = true;
        });

        this.isLoading = true;
        this.filteredAssignedRainGauge = JSON.parse(JSON.stringify(this.data));
        const customAlarmsRequest =
            this.data && this.data.overidecustomer
                ? this.customerService.getCustomAlarms(this.data.locationId)
                : this.customerService.getCustomAlarmsForCustomer(this.data.locationId, this.data.customerId);

        customAlarmsRequest.subscribe((rainGaugeData: GetCustomAlarmsSummary) => {
            if (rainGaugeData) {
                this.alarmRainUnitValue = rainGaugeData.threshold;
                this.alarmCollectionValue = rainGaugeData.rgint;
                this.alarmPeriodValue = rainGaugeData.period;
                this.alarmType.forEach((alarm) => {
                    if (alarm.value === rainGaugeData.eid) {
                        this.alarmTypeID = alarm.value;
                    }
                });
                rainGaugeData.locs.forEach((gauge) => {
                    if (
                        gauge.hasOwnProperty('adsrgids') &&
                        gauge.adsrgids.length > 0 &&
                        gauge.locname === this.data.locationName
                    ) {
                        this.wetDryToggle = gauge.enabled;
                        gauge.adsrgids.forEach((selectedgauge) => {
                            this.checkedGauges.push({
                                id: selectedgauge.rgid,
                                name: selectedgauge.rgname,
                                isChecked: true,
                            });
                        });
                    }
                });
                // set pre assigned rain gauges to checked in UI
                this.filteredAssignedRainGauge.rainGaugeList.forEach((list: GetLocationsRainGaugeResponse) => {
                    list.isChecked = !(this.checkedGauges.findIndex((x) => x.id === list.locationId) === -1);
                });
                this.isRaingaugeAvailable = this.isWetDryAvailable = rainGaugeData.locs.length > 0;
                if(this.alarmCollectionValue == 0)
                {
                    this.alarmCollectionValue = 60;
                }

                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            }
        });

        this.alarmType = [
            { value: 6610, text: this.highLevel },
            { value: 6609, text: this.highHigh },
            { value: 6631, text: this.overFlow },
        ];
    }
    public cancel(permisionsUpdated?: boolean) {
        this.wetdryDialog.close({ success: permisionsUpdated ? permisionsUpdated : true });
    }
    /**
     * Handles the custom alarm period change event.
     */
    public onCustomAlarmPeriodChange(unitValue: number) {
        this.alarmPeriodValue = Number(unitValue);
        // for edit button validation when time in not valid
        if (
            (this.alarmPeriodValue && this.alarmPeriodValue < 1) ||
            this.alarmPeriodValue > 72 ||
            this.alarmPeriodValue === 0 ||
            !this.isValidMetric
        ) {
            this.isEditButtonDisabled = true;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        } else {
            this.isEditButtonDisabled = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }
    }

    public onCustomAlarmCollectionChange(collectionInput: number) {
        this.alarmCollectionValue = Number(collectionInput);
        // for edit button validation when time in not valid
        if (
            (this.alarmCollectionValue && this.alarmCollectionValue < 1) ||
            this.alarmCollectionValue > 60 ||
            this.alarmCollectionValue === 0 ||
            !this.isValidMetric
        ) {
            this.isEditButtonDisabled = true;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        } else {
            this.isEditButtonDisabled = false;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }
    }
    // select or un-select all check-boxes.
    public toggleSelectAll() {
        this.isEditButtonDisabled = false;
        this.isWetDryAvailable = false;
        this.selectAll = !this.selectAll;
        for (const customer of this.filteredAssignedRainGauge.rainGaugeList) {
            customer.isChecked = this.selectAll;
        }
        this.isRaingaugeAvailable = this.isWetDryAvailable = this.filteredAssignedRainGauge.rainGaugeList.some(
            (x) => x.isChecked,
        );
    }
    /**
     * Handles the custom alarm rain unit change event.
     */
    public onCustomAlarmRainUnitChange(unitValue: number, metricType: string) {
        // metric
        if (metricType && metricType.toLowerCase() === 'metric') {
            if ((unitValue && Number(unitValue) < 0.254) || Number(unitValue) > 381 || Number(unitValue) === 0) {
                this.isEditButtonDisabled = true;
                this.isValidMetric = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            } else {
                this.isEditButtonDisabled = false;
                this.isValidMetric = true;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            }
        } else if (metricType && metricType.toLowerCase() === 'non-metric') {
            this.alarmRainUnitValue = this.alarmRainUnitValue;
            const convertedText = unitValue.toString();
            const index = convertedText.indexOf('.');
            const unitValueLength = convertedText.length - index - 1;

            if (
                (unitValue && Number(unitValue) < 0.01) ||
                Number(unitValue) > 15 ||
                Number(unitValue) === 0 ||
                unitValueLength > 2
            ) {
                this.isEditButtonDisabled = true;
                this.isValidMetric = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            } else {
                this.isEditButtonDisabled = false;
                this.isValidMetric = true;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            }
        }
    }
    /**
     * Make the the rain gauge filter serch
     * @param rain gauge  search parameter
     */
    private filterCustomers(customerSearch: string) {
        if (this.data) {
            if (!customerSearch || customerSearch.trim() === '') {
                this.filteredAssignedRainGauge.rainGaugeList = this.data.rainGaugeList;
            } else {
                this.filteredAssignedRainGauge.rainGaugeList = this.data.rainGaugeList.filter((x) =>
                    x.locationName.toLowerCase().includes(customerSearch.toLowerCase()),
                );
                this.uiUtilsService.safeChangeDetection(this.cdr);
            }
        }
    }
    /**
     * Make the checkboxe checked or not based on user selection
     * @param event and data of each check box
     */
    public changeSelecteState($event, data) {
        if (
            this.alarmRainUnitValue &&
            !(
                (this.alarmPeriodValue && this.alarmPeriodValue < 1) ||
                this.alarmPeriodValue > 72 ||
                this.alarmPeriodValue === 0
            ) &&
            this.isValidMetric
        ) {
            this.isEditButtonDisabled = false;
        } else {
            this.isEditButtonDisabled = true;
        }
        for (const list of this.filteredAssignedRainGauge.rainGaugeList) {
            if (list.locationId === data.locationId) {
                list.isChecked = $event.checked;
            }
        }
        this.isRaingaugeAvailable = this.isWetDryAvailable = this.filteredAssignedRainGauge.rainGaugeList.some(
            (x) => x.isChecked,
        );
    }
    /**
     * Make the wetdry over flow enabled or disabled based on toggle
     * @param event and data of toggle
     */
    public activeDeactiveWetDry(event: MatSlideToggleChange) {
        this.isLoading = true;
        this.wetDryToggle = event.checked;
        this.customerService.activeInactiveWetDry(this.data.customerId, this.data.locationId, event.checked).subscribe(
            () => {
                // snack bar message
                const simpleSnackBarRef = this.snackBar.raiseNotification(
                    `${this.alarmUpdateSuccess} ${this.data.locationName}`,
                    this.dismissText,
                );
                // hide the loader
                this.isLoading = false;
                this.isEditButtonDisabled = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                this.isLoading = false;
                // snack bar message when error occured
                this.snackBar.raiseNotification(`${this.alarmUpdateErr} ${this.data.locationName}`, this.dismissText, {
                    panelClass: 'custom-error-snack-bar',
                }, false);
            },
        );
    }
    /**
     * Handles the custom alarm type selection change event.
     * @param alarmType The surrogate identifier for a alarm type.
     */
    public onCustomAlarmTypeChange(alarmType: number) {
        this.isEditButtonDisabled = false;
        this.alarmTypeID = alarmType;
    }
    /**
     * updateLocationInfo method makes put call & updates the location info
     */
    public updateLocationInfo() {
        const uniqueSelectedGauges = [];
        for (const list of this.filteredAssignedRainGauge.rainGaugeList) {
            if (list.isChecked) {
                uniqueSelectedGauges.push(list.locationId);
            }
        }
        const data = {
            locationID: this.data.locationId,
            adsrgids: uniqueSelectedGauges,
        };
        this.allUpdatedLocationsArray.push(data);
        // Showing the Loader
        this.isLoading = true;

        const customAlarms = {
            enabled: this.wetDryToggle,
            eid: this.alarmTypeID,
            threshold: this.alarmRainUnitValue,
            period: this.alarmPeriodValue,
            rgint : this.alarmCollectionValue,
            locs: this.allUpdatedLocationsArray,
        };

        this.customerService.updateCustomAlarms(customAlarms, this.data.customerId).subscribe(
            () => {
                this.cancel(true);
                // snack bar message
                const simpleSnackBarRef = this.snackBar.raiseNotification(
                    `${this.alarmUpdateSuccess} ${this.data.locationName}`,
                    this.dismissText,
                );
                // hide the loader
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                this.isLoading = false;
                this.cancel(false);
                this.uiUtilsService.safeChangeDetection(this.cdr);
                // snack bar message when error occured
                this.snackBar.raiseNotification(`${this.alarmUpdateErr} ${this.data.locationName}`, this.dismissText, {
                    panelClass: 'custom-error-snack-bar',
                }, false);
            },
        );
    }
}
