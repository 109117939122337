<button class="date-range-picker-button" mat-button (menuClosed)="durationClose()" [matMenuTriggerFor]="durationMenu" #trigger="matMenuTrigger">
    <span class="date-range-content">{{ 'COMMON.DURATION' | translate }}</span>
    <span class="date-range-hint date-value">
        {{ filterOptions.durationStartHours | numDisplay }} : {{ filterOptions.durationStartMinutes | numDisplay }} -
        {{ filterOptions.durationEndHours | numDisplay }} : {{ filterOptions.durationEndMinutes | numDisplay }}
    </span>
    <mat-icon>arrow_drop_down</mat-icon>
</button>
<mat-menu class="date-range-picker-menu customDateMenu" #durationMenu="matMenu" xPosition="before" yPosition="below" [overlapTrigger]="false">

    <div class="custom-date-menu-containt customDateMenuContaint">
        <form [formGroup]="form" class="date-area" (click)="$event.stopPropagation()">
            <h5 class="header capitalize-text">{{ 'COMMON.DURATION' | translate }}</h5>
            <div class="flex-evenly top">
                <div class="duration-item-container">
                    <span class="duration-item-title">{{ 'COMMON.FROM' | translate }}:</span>
                    <div class="flex-evenly">
                        <mat-form-field id="inputField">
                            <input matInput (blur)="format('durationStartHours')" formControlName="durationStartHours" type="number" placeholder="hrs"
                                (keypress)="onKeyPressDigit($event)" />
                        </mat-form-field>
                        <mat-form-field id="inputField">
                            <input matInput (blur)="format('durationStartMinutes')" formControlName="durationStartMinutes" maxlength="2" max="59" type="number" placeholder="min"
                                (keypress)="onKeyPressDigit($event)" />
                        </mat-form-field>
                    </div>
                </div>
                <div class="duration-item-container">
                    <span class="duration-item-title">{{ 'COMMON.TO' | translate }}:</span>
                    <div class="flex-evenly">
                        <mat-form-field id="inputField">
                            <input matInput (blur)="format('durationEndHours')" formControlName="durationEndHours" type="number" placeholder="hrs"
                                (keypress)="onKeyPressDigit($event)" />
                        </mat-form-field>
                        <mat-form-field id="inputField">
                            <input matInput (blur)="format('durationEndMinutes')" formControlName="durationEndMinutes" maxlength="2" max="59" type="number" placeholder="min"
                                (keypress)="onKeyPressDigit($event)" />
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="mat-error full-width">
                <div *ngIf="form.errors?.diff">Difference error</div>
                <div *ngIf="!form.errors && form.invalid">Format error</div>
            </div>
        </form>
        <div class="date-selector-area">
            <div class="header capitalize-text">{{ 'LOCATION_DASHBOARD.QUICK_RANGES' | translate }}</div>
            <div class="quick-range-button">
                <button mat-button class="date-ranges" (click)="setDurationFromQuickRanges(1)">
                    1 Hour
                </button>
                <button mat-button class="date-ranges" (click)="setDurationFromQuickRanges(8)">
                    8 Hours
                </button>
                <button mat-button class="date-ranges" (click)="setDurationFromQuickRanges(2)">
                    2 Hours
                </button>
                <button mat-button class="date-ranges" (click)="setDurationFromQuickRanges(12)">
                    12 Hours
                </button>
                <button mat-button class="date-ranges" (click)="setDurationFromQuickRanges(4)">
                    4 Hours
                </button>
                <button mat-button class="date-ranges" (click)="setDurationFromQuickRanges(24)">
                    24 Hours
                </button>
            </div>
        </div>
    </div>
</mat-menu>
