import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    Output,
    ViewChildren,
    QueryList,
    EventEmitter,
    ChangeDetectorRef,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { MatLegacyListOption as MatListOption } from '@angular/material/legacy-list';
import { FormControl } from '@angular/forms';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { SelectableGroup } from 'app/shared/models/selectable';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
    selector: 'ads-prism-single-select-group',
    templateUrl: './ads-prism-single-select-group.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdsPrismSingleSelectGroupComponent implements OnInit, OnChanges {
    @Input() public excludeSelectedItem = true;

    // Represents the collection of entities.
    @Input() public items: Array<SelectableGroup>;

    // Defines the placeholder string value for the control.
    @Input() public placeholder: string;

    // Represents original placholder string value
    public initialPlaceholder: string;

    // Represents the minimu number that can be selected.
    @Input() public minDisplayLimit = 0;

    // Represents the maximum numbers that can be selected.
    @Input() public maxDisplayLimit: number;

    // Preselected items
    @Input() public preselectedItem: number;

    // Indicates whether to show the "All" feature. Enabled by default.
    @Input() public isShowAll = false;

    // Emits the currently selected items.
    @Output() public selectedItems = new EventEmitter<number>();

    @Output() public isEntityInValid = new EventEmitter<boolean>();

    @Output() public isSelectionChanged = new EventEmitter<boolean>();

    /**
     * Input Array to receive the displaygroup number list to be filtered from calculated entity
     * component for particular calculation types
     */
    @Input() public displayGroupFilterList: Array<number> = [];

    // Represents the colleciton of material check boxes
    @ViewChildren(MatListOption) private options: QueryList<MatListOption>;

    // Represents teh search term control.
    public searchString = new FormControl();

    // Represents a value indicating whether the selection list is visible.
    public isResultsVisible = false;

    /**
     * Reresents a random number value that will be added to the data-id of the
     * element and will serve as a poor mans unique identifier.
     */
    public surrogateIdentifier = Math.ceil(Math.random() * Number.MAX_VALUE);

    // Represents a value indicating if an option was changed.
    public isOptionsTouched = false;

    // Represents a value indicating if all the options option was selected.
    public isAllSelected = false;

    // Indicates a state of validity for the input.
    public isValid = true;

    // Represents a value indicating whether the selection list has focus.
    private isSelectionListFocused = false;
    public initPlaceholderText: string;

    public selectedItemId: number;
    public selectedItemText: string;

    public itemList: Array<SelectableGroup>;
    public orginalItemList: Array<SelectableGroup>;
    public itemGroupNameList: Array<string>;

    public itemGroupByList: SelectableGroup[][] = [];
    public isSearching: boolean;

    constructor(private cdr: ChangeDetectorRef, private uiUtilsService: UiUtilsService) {
        this.searchString.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe((res: string) => {
            this.filterEntity(res);
        });
    }

    public ngOnInit() {
        this.initialPlaceholder = this.placeholder;
        this.selectedItemId = this.preselectedItem;
        if (this.items) {
            // invoke filterItempsByDisplayGroup method to filter entities by displaygroup if passed any
            this.filterEntitiesByDisplayGroup();
            this.orginalItemList = this.items.length > 1 ? this.sortByName() : this.items;
            this.itemList = this.orginalItemList;
            this.loadGroupByEntity();
        }
    }
    /**
     * Method takes a sting as an argument and filters out customers whose name include the string
     * @param searchString - string to search for in customer name
     */
    public filterEntity(searchString: string) {
        if (!this.isResultsVisible) {
            return;
        }
        this.isSearching = false;

        if (!searchString || searchString.trim() === '') {
            this.itemList = this.orginalItemList;
        } else {
            searchString = searchString.trim();
            this.itemList = this.orginalItemList.filter((x) =>
                x.name.toLowerCase().includes(searchString.toLowerCase()),
            );
            this.isSearching = true;
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);
        this.loadGroupByEntity();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (this.items) {
            // invoke filterItempsByDisplayGroup method to filter entities by displaygroup if passed any
            this.filterEntitiesByDisplayGroup();
            this.selectedItemId = this.preselectedItem;
            this.orginalItemList = this.items.length > 1 ? this.sortByName() : this.items;
            this.itemList = this.orginalItemList;
            this.loadGroupByEntity();
        }
    }

    private loadGroupByEntity() {
        if (this.items) {
            this.itemGroupNameList = this.itemList
                .map((i: SelectableGroup) => i.groupName)
                .sort()
                .filter((el, i, a) => i === a.indexOf(el));
            this.itemGroupNameList.forEach((item) => {
                if (!this.excludeSelectedItem) {
                    this.itemGroupByList[item.toString()] = this.itemList.filter(
                        (a: SelectableGroup) => a.groupName === item,
                    );
                } else {
                    this.itemGroupByList[item.toString()] = this.itemList.filter(
                        (a: SelectableGroup) => a.groupName === item && a.isChecked !== true,
                    );
                }
            });
            if (!this.isResultsVisible) {
                this.setSelectedPlaceholderValue();
            }
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }
    }

    /**
     * Handles focus event on input search field.
     * @param event Reprsents the focus event.
     */
    public onInputFocusIn(event: FocusEvent) {
        // get target element
        // let inputEl = <HTMLInputElement>event.target;

        // set vvalue to nothing
        // inputEl.value = '';
        this.initPlaceholderText = '';

        // enable selection menu
        this.isResultsVisible = true;
    }

    /**
     * Handles focusout event on input search field.
     * @param event Reprsents the focus event.
     */
    public onInputFocusOut(event: FocusEvent) {
        if (this.initPlaceholderText && this.initPlaceholderText.length > 0) {
            this.onInputEntry(this.initPlaceholderText);
            return;
        }

        // if list visible but is not focused
        if (
            this.isSameElementClicked(<HTMLElement>event.relatedTarget) &&
            (this.isResultsVisible || this.isSelectionListFocused)
        ) {
            return;
        }

        // get target element
        const inputEl = <HTMLInputElement>event.target;

        // set vvalue to nothing
        // inputEl.value = '';

        if (this.isOptionsTouched) {
            // emit items
            this.selectedItems.emit(this.selectedItemId);
        }

        // hide results visible
        this.isOptionsTouched = this.isResultsVisible = false;
        if (!this.isResultsVisible) {
            this.setSelectedPlaceholderValue();
        }
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * Handles focus event on selection drop down.
     * @param event Reprsents the focus event.
     */
    public onSelectionListFocused(event: FocusEvent): void {
        this.isSelectionListFocused = true;
    }

    public onSelectionListNotFocused(event: FocusEvent): void {
        // if list visible but is not focused
        if (
            this.isSameElementClicked(<HTMLElement>event.relatedTarget) &&
            (this.isResultsVisible || this.isSelectionListFocused)
        ) {
            return;
        }

        // tslint:disable-next-line:no-any
        if (event.relatedTarget && (<any>event.relatedTarget).localName === 'mat-list-option') {
            return;
        }
        // tslint:disable-next-line:no-any
        if (event.relatedTarget && (<any>event.relatedTarget).localName === 'mat-selection-list') {
            return;
        }
        // tslint:disable-next-line:no-any
        if (event.relatedTarget && (<any>event.relatedTarget).localName === 'mat-expansion-panel-header') {
            return;
        }

        // if a sibling element (i.e, input) is not the focus than click outside occured and so notify
        if (!this.isSameElementClicked(<HTMLElement>event.relatedTarget) && this.isOptionsTouched) {
            // emit items
            this.selectedItems.emit(this.selectedItemId);
        }

        // clear search input
        //  this.searchInput.value = '';

        // reset state
        this.isResultsVisible = this.isOptionsTouched = this.isSelectionListFocused = false;
        if (!this.isResultsVisible) {
            this.setSelectedPlaceholderValue();
        }

        this.uiUtilsService.safeChangeDetection(this.cdr);
    }
    private setSelectedPlaceholderValue(): void {
        this.initPlaceholderText = '';
        const foundOrginalItem = this.orginalItemList.find((s: SelectableGroup) => s.id === this.selectedItemId);
        if (foundOrginalItem) {
            this.selectedItemText = foundOrginalItem.name;
            this.initPlaceholderText = this.selectedItemText;
            this.initialPlaceholder = '';
            this.initialPlaceholder = `${this.placeholder}`;
            this.selectedItems.emit(this.selectedItemId);
        }

        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public onSelectionChange(event: MouseEvent, selectedId): void {
        if (!(this.orginalItemList && this.orginalItemList.length > 0)) {
            this.isSelectionChanged.emit(false);
            return;
        }
        this.isOptionsTouched = true;
        this.orginalItemList.forEach((selectable: SelectableGroup) => (selectable.isChecked = false));

        const foundOrginalItem = this.orginalItemList.find((s: SelectableGroup) => s.id === selectedId);
        if (foundOrginalItem) {
            this.selectedItemId = foundOrginalItem.id;
            this.selectedItemText = foundOrginalItem.name;
            // set checked
            foundOrginalItem.isChecked = !foundOrginalItem.isChecked;
            this.isSelectionChanged.emit(true);
        }

        // ensure that item can be selected
        this.setSelectedPlaceholderValue();
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    /**
     * Attempts to capture the passed in elements identifier and compare it to this one.
     * Returns true if the this elements and the passed in elements identifiers are equal;
     * otherwise false.
     * @param element Represents the element to be used for comparion.
     */
    private isSameElementClicked(element: HTMLElement): boolean {
        // ensure args
        if (!element) {
            return;
        }

        // ensure property
        if (!element.dataset.hasOwnProperty('identifier')) {
            return;
        }

        // determine if items are the same
        const result = Number(element.dataset.identifier) === this.surrogateIdentifier;

        // return result
        return result;
    }

    private sortByName() {
        const sortedItems = this.items.sort((t1, t2) => {
            const name1 = t1.name.toLowerCase();
            const name2 = t2.name.toLowerCase();
            if (name1 > name2) {
                return 1;
            }
            if (name1 < name2) {
                return -1;
            }
            return 0;
        });
        return sortedItems;
    }

    public onInputEntry(entityName: string) {
        if (!entityName) {
            this.isEntityInValid.emit(false);
            return;
        }
        const entityAbstract: SelectableGroup = this.orginalItemList.find(
            (s: SelectableGroup) => s.name.toLocaleLowerCase() === entityName.toLocaleLowerCase(),
        );
        if (!entityAbstract) {
            this.initPlaceholderText = entityName;
            this.isEntityInValid.emit(true);
            return;
        }
    }

    // Method to filter entities by displaygroup if populated with any
    public filterEntitiesByDisplayGroup() {
        if (this.displayGroupFilterList && this.displayGroupFilterList.length > 0) {
            this.items = this.items
                .filter((x) => this.displayGroupFilterList.indexOf(x.groupId) > -1)
                .filter((elem1, pos, arr) => arr.findIndex((elem2) => elem2.id === elem1.id) === pos);
        }
    }
}
