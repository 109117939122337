import { ChartProperty } from 'app/shared/components/hydro-graph';
import { GraphChartConfiguration } from 'app/shared/components/hydrograph/hydrograph-data/hydrograph-data-model';
import { LightningChartObject } from 'app/shared/components/hydrograph/lightning-chart-object/lightning-chart-object';
import { BasicSeriesData } from 'app/shared/models/hydrographNEW';
import { OptimizedViewData } from 'app/shared/models/view-data';
import { BehaviorSubject, Observable } from 'rxjs';


export enum CustomDashboardGraphType {
    Hydrograph = 0,
    Scattergraph = 1
}

export interface CustomDashboardGraphObject {
    name: string;
    isExpand: boolean;
    graphId: string;
    originalData: BasicSeriesData[];
    config: GraphChartConfiguration,
    dates: number[];
    dataAverage: number,
    isLoading: BehaviorSubject<boolean>;
    graphType: CustomDashboardGraphType;
    headerDates: number[];
    groupings: number[][];
    index: number;
    noData:Array<GraphConfigurationNoData>;
    inactiveLocation?: boolean;
    chart?: LightningChartObject;
}

export class CustomGraphs {
    public customerId: number;
    public configurations: Array<GraphConfiguration>;
    constructor() {
        this.configurations = new Array<GraphConfiguration>(0);
    }
}

export class GraphConfiguration {
    public graphId: string;
    public name: string;
    public startDate: Date | string;
    public endDate: Date | string;
    public data: Array<SeriesProperty> | Array<any>;
    public dataLoading?: BehaviorSubject<boolean>;
    public series: Array<SeriesProperty> | Array<any>;
    public graphType: number | string;
    public dataAverage: number;
    public graphAccess: string;
    public users: Array<String>;
    public graphProperty?: ChartProperty;
    public isExpand?: boolean;
    public rolling?: boolean;
    public dateSpan?: number;
    public isLoading: boolean;
    public index?: number;
    public noData?: Array<GraphConfigurationNoData>;
    public inactiveLocation?: boolean;
}

export interface GraphConfigurationNoData {
    location: string;
    eidXName: string;
    eidYName?: string;
}

export class GraphOptions {
    public graphId: string;
    public name: string;
    public startDate: Date;
    public endDate: Date;
    public options?: ChartProperty;
}

export class SeriesProperty {
    public lid: number;
    public eid?: number;
    public color: string;
    public eidX?: number;
    public eidXName?: string;
    public eidY?: number;
    public eidYName?: string;
    public locationName?: string;
    public startDate?: Date;
    public endDate?: Date;
    public request?: Observable<OptimizedViewData>;
    public data?: OptimizedViewData;
    public entityName?: string;
    public originLocationId?: number;
    public id?: number;
}

export class SeriesData {
    public series: Array<string>;
}

export class TimeInterval {
    public days?: number;
    public hours?: number;
    public minutes?: number;
}

export enum AccessType {
    Public = '0',
    Private = '1',
}

export enum GraphType {
    Hydrograph = '0',
    Scattergraph = '1',
}
