import { Injectable, OnDestroy } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { HttpParams, HttpClient } from '@angular/common/http';
import { SharedService } from 'app/shared/services/shared.service';
import { Config } from 'app/shared/services/config';
import {
    VaultDirectory,
    VaultFile,
    VaultLinkShare,
    VaultLocationsWithXML,
    VaultSubDirectory,
    VaultUploadResult,
} from 'app/shared/models/vault';
import { DefaultResponse } from 'app/shared/models/default-response';
import { customerQueryParam } from 'app/shared/models/customer';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class VaultService implements OnDestroy {
    // Variable to check directory clicked or not
    public directoryFlag = false;
    /**
     * Represents an instance of the vault structure.
     */
    public vault = new BehaviorSubject<VaultDirectory>(null);
    public reportGenerated = new BehaviorSubject(null);

    /**
     * Represents the currently selected directory and defaults to root.
     */
    private currentlySelectedDirectory = new BehaviorSubject<string>('/');
    public breadcrumbDirectory = new BehaviorSubject<Array<VaultSubDirectory>>(null);

    public selectedDirectory = new BehaviorSubject<string>('/');

    /**
     * Represents the files of the currently selected directory.
     */
    private filesForCurrentlySelectedDirectory = new BehaviorSubject<Array<VaultFile>>(null);

    /**
     * Represents the files of the currently selected directory.
     */
    private subDirectoriesForCurrentlySelectedDirectory = new BehaviorSubject<Array<VaultSubDirectory>>(null);

    /**
     * Represents a placeholder for subscriptions.
     */
    private subscriptions = new Array<Subscription>();

    /**
     * Represents current customer ID
     */
    private customerID: number;

    public isLocationVault = false;
    public locationVaultID = 0;
    public isVaultLoading = true;
    public isServerError = false;
    public breadcrumbDirectories: Array<VaultSubDirectory>;

    constructor(
        private http: HttpClient,
        private sharedService: SharedService,
        private activatedRoute: ActivatedRoute,
    ) {
        const customerIDSuscription = this.activatedRoute.queryParamMap.subscribe(
            (params: ParamMap) => {
                this.customerID = Number(params.get(customerQueryParam));
            },
            (error) => {
                // Error Block to handle errors
            },
        );
        this.subscriptions.push(customerIDSuscription);
    }

    /**
     * Lifecycle hook that is called when a directive, pipe or service is destroyed.
     */
    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    /**
     * Retreives a readonly reference to the Vault's current state.
     */
    public get Vault(): BehaviorSubject<VaultDirectory> {
        return this.vault;
    }
    /**
     * Retreives a readonly reference to the current directories files.
     */
    public get CurrentFiles(): BehaviorSubject<Array<VaultFile>> {
        return this.filesForCurrentlySelectedDirectory;
    }
    /**
     * Retreives a readonly reference to the current directories files.
     */
    public get currentSubDirectory(): BehaviorSubject<Array<VaultSubDirectory>> {
        return this.subDirectoriesForCurrentlySelectedDirectory;
    }
    /**
     * Retrieves a readonly reference to the currently selected directory.
     */
    public get CurrentDirectory(): BehaviorSubject<string> {
        return this.currentlySelectedDirectory;
    }
    public get BreadcrumbDirectories(): BehaviorSubject<Array<VaultSubDirectory>> {
        return this.breadcrumbDirectory;
    }

    /**
     * Retrieves the files for the chosen directory.
     * @param id The surrogate id of the chosen directory.
     */
    public getFilesForDirectory(id?: string): void {
        // ensure args and default dir if none passed in
        if (id !== undefined || id === '') {
            this.currentlySelectedDirectory.next(id);
            this.directoryFlag = true;
            this.sharedService.changeMessage(this.directoryFlag);
        }

        this.vault
            .subscribe(
                (vaultDirectory: VaultDirectory) => {
                    this.subDirectoriesForCurrentlySelectedDirectory.next(new Array<VaultSubDirectory>());

                    if (vaultDirectory == undefined) {
                        return this.filesForCurrentlySelectedDirectory.next(new Array<VaultFile>());
                    }

                    const curSelDirSubsciption = this.currentlySelectedDirectory
                        .subscribe(
                            (path: string) => {
                                this.createBreadcrumb(path);
                                // handle root folder
                                if (path === '/') {
                                    // set files for root
                                    this.filesForCurrentlySelectedDirectory.next(vaultDirectory.files);
                                    this.searchSubDirectoriesForDirectory(vaultDirectory.sub_directories);
                                    // exit immediatly
                                    return;
                                }
                                if (this.isLocationVault && path === `/SiteDocuments/${this.locationVaultID}/`) {
                                    this.filesForCurrentlySelectedDirectory.next(vaultDirectory.files);
                                    this.searchSubDirectoriesForDirectory(vaultDirectory.sub_directories);
                                    return;
                                }

                                // handle sub folders
                                const foundFiles = this.searchVaultForDirectory(path, vaultDirectory.sub_directories);

                                // set found files to new directory
                                if (foundFiles) {
                                    this.filesForCurrentlySelectedDirectory.next(foundFiles);
                                } else {
                                    this.filesForCurrentlySelectedDirectory.next(new Array<VaultFile>());
                                }
                            },
                            (error) => {
                                // Error Block to handle errors
                            },
                        )
                        .unsubscribe();
                },
                (error) => {
                    // Error Block to handle errors
                },
            )
            .unsubscribe();
    }

    public createBreadcrumb(id: string) {
        if (this.breadcrumbDirectories && this.breadcrumbDirectories.length > 0) {
            this.breadcrumbDirectories.length = 0;
        }
        if (id === '/' || (this.isLocationVault && id === `/SiteDocuments/${this.locationVaultID}/`)) {
            this.breadcrumbDirectory.next(this.breadcrumbDirectories);
            // exit immediatly
            return;
        }
        const trimId = id.substring(1, id.length - 1);
        const breadcrumbList = this.isLocationVault
            ? trimId.split('/').splice(2, trimId.split('/').length - 2)
            : trimId.split('/');
        let path = this.isLocationVault ? `/SiteDocuments/${this.locationVaultID}/` : '/';
        if (breadcrumbList && breadcrumbList.length > 0) {
            this.breadcrumbDirectories = new Array<VaultSubDirectory>(breadcrumbList.length);
            breadcrumbList.forEach((file, index) => {
                path = `${path}${file}/`;
                const subDirectory = <VaultSubDirectory>{
                    id: path,
                    name: file,
                };
                this.breadcrumbDirectories[index] = subDirectory;
            });
            this.breadcrumbDirectory.next(this.breadcrumbDirectories);
        }
    }

    /**
     * Removes the file from the data store.
     * @param id the file identifier.
     */
    public removeFile(id: string, isDeleteFolder: boolean): Observable<boolean> {
        // setup options
        // creating HttpParams for HttpClient request

        // call delete API and retrieve the latest vault files following the delete
        return this.http.delete<boolean>(
            Config.urls.vaultDeleteFile +
            `?cid=${this.customerID.toString()}` +
            `&uri=${id}` +
            `&isDeleteFolder=${isDeleteFolder}`,
        );
    }

    public deleteFiles(item: any): Observable<boolean> {
        return this.http
            .request('delete', Config.urls.vaultDeleteLocationFile + `?cid=${this.customerID.toString()}`, { body: item })
            .pipe(
                map((response: any) => {
                    return response;
                }),
            )
            .pipe(
                catchError((err) => {
                    return err;
                }),
            );
    }

    /**
     * Retrieves all the locations for the given client for daily summary overview.
     * @returns An observable that emits a collection of IVaultItem.
     */
    public getRootVaultListForCustomer(): Observable<VaultDirectory> {
        this.isServerError = false;
        // setup request options
        // creating HttpParams for HttpClient request
        this.isVaultLoading = true;
        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.append('cid', this.customerID.toString());
        let potentialResult: Observable<VaultDirectory>;
        if (this.isLocationVault) {
            potentialResult = this.http.get<VaultDirectory>(
                Config.urls.vaultStructure + '/' + this.customerID + '/location/' + this.locationVaultID,
            );
        } else {
            potentialResult = this.http.get<VaultDirectory>(Config.urls.vaultStructure, { params: httpParams });
        }

        potentialResult.subscribe(
            (result: VaultDirectory) => {
                this.isVaultLoading = false;
                if (result == undefined) {
                    this.filesForCurrentlySelectedDirectory.next(new Array<VaultFile>());
                    this.vault.next(<VaultDirectory>{});
                    this.getFilesForDirectory();
                    return;
                }

                // set vault
                this.vault.next(result);
                // set current directory
                this.getFilesForDirectory();
            },
            (error) => {
                // Error Block to handle errors
                this.isVaultLoading = false;
                this.isServerError = true;
                this.filesForCurrentlySelectedDirectory.next(new Array<VaultFile>());
                this.vault.next(<VaultDirectory>{});
                this.getFilesForDirectory();
            },
        );

        return EMPTY;
    }

    /**
 * Retrieves all the locations for the given client for daily summary overview.
 * @returns An observable that emits a collection of IVaultItem.
 */
    public getRootVaultListForCustomerNoAPI(result): Observable<VaultDirectory> {
        this.isVaultLoading = false;
        if (result == undefined) {
            this.filesForCurrentlySelectedDirectory.next(new Array<VaultFile>());
            this.vault.next(<VaultDirectory>{});
            this.getFilesForDirectory();
            return;
        }

        // set vault
        this.vault.next(result);
        // set current directory
        this.getFilesForDirectory();


        return EMPTY;
    }

    /**
     * Retreives a shareable link.
     * @param hours Represnts the duration in hours that the link will expire in.
     * @param fileId Represnets the id of the file, currently its url.
     */
    public getSharedLink(hours: number, fileId: string): Observable<VaultLinkShare> {
        // setup request options
        // creating HttpParams for HttpClient request

        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.append('cid', this.customerID.toString());
        httpParams = httpParams.append('uri', fileId);
        httpParams = httpParams.append('expirationHours', hours.toString());

        return this.http.get<VaultLinkShare>(Config.urls.vaultLinkShare, { params: httpParams });
    }

    /**
     * Sets the current directory path.
     * @param id The file surrogate identifier
     */
    public setCurrentDirectory(id: string): void {
        this.currentlySelectedDirectory.next(id || '/');
    }

    /**
     * Retreives a collection of files to be uploaded, these files will be marked if they already exist.
     * @param files the collection of files to check.
     */
    public markExistingFiles(files: FileList): Observable<Array<VaultUploadResult>> {
        // iterate over fault to find files that already exist
        return this.Vault.pipe(
            map<VaultDirectory, Array<VaultUploadResult>>((vault: VaultDirectory): Array<VaultUploadResult> => {
                // setup model
                const overwritableFiles = new Array<VaultUploadResult>();

                this.filesForCurrentlySelectedDirectory.subscribe(
                    (filesInCurrentDir: Array<VaultFile>) => {
                        // iterate over the list of files args
                        for (let i = 0; i < files.length; i++) {
                            // set file
                            const file: File = files.item(i);

                            // variable to store file name for upload
                            let uploadedFileName;

                            // variable to store file exist or not
                            let fileExist = false;

                            let existingVaultFile = null;
                            // find file in current directory
                            if (filesInCurrentDir) {
                                existingVaultFile = filesInCurrentDir.find(
                                    (vaultFile: VaultFile) =>
                                        vaultFile.fileName.toLowerCase() === file.name.toLowerCase(),
                                );
                            }

                            if (existingVaultFile) {
                                // set existing file name for upload
                                uploadedFileName = existingVaultFile.fileName;

                                // set file exists in current directory
                                fileExist = true;
                            } else {
                                uploadedFileName = file.name;
                            }

                            // push wrapped file instance and mark if file exists
                            const vaultUploadResult = <VaultUploadResult>{
                                fileName: uploadedFileName,
                                isSuccess: false,
                                isExistingFile: fileExist,
                            };
                            overwritableFiles.push(vaultUploadResult);
                        }
                    },
                    (error) => {
                        // Error Block to handle errors
                    },
                );

                // return marked files
                return overwritableFiles.filter((fileResult: VaultUploadResult) => fileResult.isExistingFile);
            }),
        );
    }

    /**
     * Create folder in directories.
     * @param customerId The customer id of the chosen customer.
     * @param folderName The folder name with directory location where file has to be created.
     */
    public createNewFolder(folderName: string) {
        return this.http.post<DefaultResponse<Object>>(
            Config.urls.createFolder + '?cid=' + this.customerID + '&folderName=' + folderName,
            '',
        );
    }

    /**
     * Searches files in directories for files using depth-first strategy.
     * @param fileName The surrogate id of the chosen file.
     * @param directory The directory structure to parse.
     */
    private searchVaultForFile(fileName: string, directory: Array<VaultDirectory>): boolean {
        for (let i = 0; i < directory.length; i++) {
            // get reference to current directory iteration item
            const dirItem = directory[i];

            // get files if the directory was found
            if (dirItem.files.some((file: VaultFile) => file.fileName === fileName)) {
                // match found, return immediatly
                return true;
            }

            // no match and no sub-directories so continue on
            if (dirItem.sub_directories == null || dirItem.sub_directories.length < 1) {
                continue;
            }

            // sub-directorires exist so recursivelly call self in order to scan further directory
            const potentialFiles = this.searchVaultForFile(fileName, dirItem.sub_directories);

            // if nothing was found and no directories exist, move on
            if (potentialFiles === undefined || potentialFiles == null) {
                continue;
            }

            // match found in sub-directories, return immediatly
            return true;
        }
    }

    /**
     * Searches directories for files using depth-first strategy.
     * @param id The surrogate id of the chosen directory.
     * @param directory The directory structure to parse.
     */
    private searchVaultForDirectory(id: string, directory: Array<VaultDirectory>): Array<VaultFile> {
        if (directory) {
            for (let i = 0; i < directory.length; i++) {
                // get reference to current directory iteration item
                const dirItem = directory[i];

                // get files if the directory was found
                if (dirItem.id === id) {
                    this.searchSubDirectoriesForDirectory(dirItem.sub_directories);

                    // match found, return immediatly
                    return dirItem.files;
                }

                // no match and no sub-directories so continue on
                if (dirItem.sub_directories == null || dirItem.sub_directories.length < 1) {
                    continue;
                }

                // sub-directorires exist so recursivelly call self in order to scan further directory
                const potentialFiles = this.searchVaultForDirectory(id, dirItem.sub_directories);
                // if nothing was found and no directories exist, move on
                if (potentialFiles === undefined || potentialFiles == null || potentialFiles.length < 1) {
                    continue;
                }

                // match found in sub-directories, return immediatly
                return potentialFiles;
            }
        }
    }

    private searchSubDirectoriesForDirectory(directory) {
        if (directory && directory.length > 0) {
            const subDirectoriesData = new Array<VaultSubDirectory>();
            subDirectoriesData.length = 0;
            directory.forEach((file, index) => {
                const data = {
                    id: file.id,
                    name: file.name,
                };
                subDirectoriesData.push(data);
            });
            this.subDirectoriesForCurrentlySelectedDirectory.next(subDirectoriesData);
        }
    }

    /**
     * Checks if same name folder exist or not.
     * @param folderName The surrogate id of the chosen directory with folder name to be created.
     */
    public isFolderNameExist(folderName: string): boolean {
        let isFolderNameExist = false;
        // get the latest from the vault
        const vaultDirSubscription = this.vault.subscribe(
            (vaultDirecotry: VaultDirectory) => {
                if (vaultDirecotry.sub_directories === undefined) {
                    return false;
                }
                // handle sub folders
                isFolderNameExist = this.searchVaultForFolders(folderName, vaultDirecotry.sub_directories);

                return isFolderNameExist;
            },
            (error) => {
                // Error Block to handle errors
            },
        );

        this.subscriptions.push(vaultDirSubscription);
        return isFolderNameExist;
    }

    /**
     * Searches directories for files using depth-first strategy.
     * @param id The surrogate id of the chosen directory.
     * @param directory The directory structure to parse.
     */
    private searchVaultForFolders(id: string, directory: Array<VaultDirectory>): boolean {
        for (let i = 0; i < directory.length; i++) {
            // get reference to current directory iteration item
            const dirItem = directory[i];

            // get files if the directory was found
            if (dirItem.id.slice(0, -1).toUpperCase() === id.toUpperCase()) {
                // match found, return immediatly
                return true;
            }

            // no match and no sub-directories so continue on
            if (dirItem.sub_directories == null || dirItem.sub_directories.length < 1) {
                continue;
            }

            // sub-directorires exist so recursivelly call self in order to scan further directory
            const potentialFiles = this.searchVaultForFolders(id, dirItem.sub_directories);
            // if nothing was found and no directories exist, move on
            if (potentialFiles === undefined) {
                continue;
            }
            // match found in sub-directories, return immediatly
            return potentialFiles;
        }
    }

    public downloadFile(files: string[]): Observable<Blob> {
        return this.http.post(Config.urls.vaultStructure + '/' + this.customerID, files, {
            responseType: 'blob',
        });
    }
    public moveFile(files) {
        return this.http.put(Config.urls.vaultMoveFile + this.customerID, files);
    }
    public importLocations(uploadedLocations: Array<VaultLocationsWithXML>) {
        return this.http.post(Config.urls.locationsImport + '?cid=' + this.customerID, uploadedLocations);
    }
    public getLocationXmlFileDetails(filePath) {
        return this.http.post(
            Config.urls.locationsImportValidate + `?cid=${this.customerID}&importLocationUri=${filePath}`,
            null,
        );
    }
    public getLocationsXmlFileDetails(files) {
        return this.http.post(Config.urls.locationsImportValidates + `${this.customerID}`, files);
    }
    public importCustomPipe(fileName: string, cid: number, lid: number) {
        return this.http.post(
            `${Config.urls.customPipeImport}?filename=SiteDocuments/${this.locationVaultID}/${fileName}&cid=${cid}&lid=${lid}`,
            null,
        );
    }
    public download(files: string[]) {
        return this.http.post(Config.urls.vaultStructure + '/' + this.customerID, files, {
            responseType: 'blob',
        });
    }
}
