<div class="conformationContainer">
    <div class="conformationContaint">
        <div class="popupHeader" mat-dialog-title>
            <span>{{ title }}</span>
            <span class="app-flex-filler"></span>
        </div>
        <div mat-dialog-content>
            <p>{{ message }}</p>
            <p *ngIf="showAddComments">
                <mat-form-field class="full-width">
                    <textarea matInput placeholder="Leave a comment" [(ngModel)]="comments"></textarea>
                </mat-form-field>
            </p>
        </div>
        <div mat-dialog-actions>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button (click)="cancelAndClose()">{{ cancelText }}</button>
            </div>
            <div class="auto-margin">
                <button mat-button (click)="okAndClose()">{{ okText }}</button>
            </div>
        </div>
    </div>
</div>
