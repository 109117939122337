import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Audit, AuditReportParams, AuditLogsResponse } from 'app/shared/models/audit';
import { Config } from './config';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AuditService {
    constructor(public http: HttpClient) {}
    /**
     * Return Audit logs data from API
     */
    public getAuditDetails(auditParams: AuditReportParams): Observable<any> {
        return this.http
            .post<AuditLogsResponse>(Config.serviceUrl + Config.urls.auditReports, auditParams)
            .pipe(catchError((err, caught) => caught))
            .pipe(
                map((res) => {
                    if (res == null) {
                        return;
                    }
                    return {
                        count: res.count,
                        details: res.details.map((audit) => {
                            return {
                                customerid: audit['cid'],
                                customername: audit['cust_name'],
                                action: audit['action'],
                                locationid: audit['lid'],
                                locationname: audit['loc_name'],
                                timestamp: audit['timestamp'],
                                modifiedby: audit['modified_by'],
                                username: audit['user_name'],
                            };
                        }),
                    };
                }),
            );
    }
}
