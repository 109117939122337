import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';
import { Config } from './config';
import { AuthService } from './auth.service';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { SignalRMessage, SignalRMessageType } from '../models/signalr-message';

export const ALARM_ACKNOWLEDGED = 'ALARM_ACKNOWLEDGED';
export const ALARM_CLEARED = 'ALARM_CLEARED';
export const DIAGNOSTICS_COMPLETE = 'Diagnostics complete';
@Injectable({
    providedIn: 'root',
})
export class SignalRService implements OnDestroy {
    private notification: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    public messageReceived = new EventEmitter<SignalRMessage>();
    public collectComplete = new EventEmitter<SignalRMessage>();
    public collectFailed = new EventEmitter<SignalRMessage>();
    public alarmReceived = new EventEmitter<SignalRMessage>();
    public returnToNormal = new EventEmitter<SignalRMessage>();
    public alarmAcc = new EventEmitter<SignalRMessage>();
    public hubConnection: HubConnection;
    public notification$: Observable<string> = this.notification.asObservable();

    private shouldClose = false;
    private isRunning = false;

    private subscriptions: Subscription[] = [];

    constructor(public authService: AuthService) {
        this.createConnection();
        // #40642 Have to start connection here
        this.startConnection();
        this.subscriptions.push(
            this.authService.isUserLogged$.subscribe((isLogged) => {
                if(isLogged) {
                    this.shouldClose = false;
                    this.startConnection();
                } else {
                    this.shouldClose = true;
                    this.stopConnection();
                }
            })
        );
    }

    public stopConnection() {
        this.shouldClose = true;
        this.stopHubAndunSubscribeToServerEvents();
    }
    private createConnection() {
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(`${Config.serviceUrl}notify`)
            .configureLogging(signalR.LogLevel.Error)
            .build();
        this.hubConnection.onclose(() => {
            this.stopHubAndunSubscribeToServerEvents();
            this.isRunning = false;
            this.restartConnection(new Error('SignalR hub connection closed.'));
        });
    }
    private restartConnection(err: Error): void {
        if(!this.shouldClose) {
            setTimeout(() => this.startConnection(), 5000);
        }
    }
    private startConnection(): void {
        if(this.isRunning) return;

        this.hubConnection
            .start()
            .then(() => {
                this.isRunning = true;
                this.subscribeToServerEvents();
            })
            .catch((err) => {
                this.restartConnection(err);
            });
    }
    public publishMessage(message: string) {
        this.hubConnection.invoke('PublishMessage', message);
    }
    private subscribeToServerEvents(): void {
        this.hubConnection.on('MessageNotification', (data: SignalRMessage) => {
            this.messageReceived.emit(data);
        });
        this.hubConnection.on('BroadcastMessage', (payload: SignalRMessage) => {
            this.handleBroadcastMessage(payload);
        });
        this.hubConnection.on('PublishMessageAck', (data: SignalRMessage) => {
            this.messageReceived.emit(data);
        });
    }

    private stopHubAndunSubscribeToServerEvents(): void {
        this.hubConnection.off('MessageNotification');
        this.hubConnection.off('BroadcastMessage');
        this.hubConnection.off('PublishMessageAck');
        this.hubConnection.stop();
    }

    private handleBroadcastMessage(data: SignalRMessage): void {
        switch (data.type) {
            case SignalRMessageType.Alarm: {
                if (data.message.includes('Return To Normal')) {
                    this.returnToNormal.emit(data);
                } else {
                    this.alarmReceived.emit(data);
                }
                break;
            }
            case SignalRMessageType.Info: {
                if (data && data.message) {
                    if (data.message.startsWith(ALARM_ACKNOWLEDGED)) {
                        this.alarmAcc.emit(data);
                    } else if (data.message === 'Collect complete') {
                        this.collectComplete.emit(data);
                    } else if (data.message === 'Collect failed') {
                        this.collectFailed.emit(data);
                    }
                }
                break;
            }
            default: {
                // unhandled
            }
        }
        this.messageReceived.emit(data);
    }

    ngOnDestroy(): void {
        for(const sub of this.subscriptions) {
            sub.unsubscribe();
        }
        this.subscriptions = [];
    }
}
