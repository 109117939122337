<div class="customDialogLayer">
    <div class="customDialog">
        <div class="customDialogPosition">
            <div id="resonForEditId">
                <div class="popupHeaderWithButton" mat-dialog-title>
                    <div class="auto-margin pad-l-15">SAVE CURVE</div>
                    <div class="app-flex-filler"></div>
                </div>
                <div mat-dialog-content class="eachTabContent">
                    <mat-form-field class="full-width">
                        <mat-label>Name</mat-label>
                        <textarea
                            matInput
                            [formControl]="nameControl"
                            maxlength="35"
                            required
                            placeholder="Name"
                        ></textarea>
                    </mat-form-field>
                </div>
                <div mat-dialog-actions>
                    <div class="app-flex-filler"></div>
                    <div class="auto-margin">
                        <button mat-dialog-close mat-button>{{ 'COMMON.CANCEL_BUTTON' | translate }}</button>
                    </div>
                    <div class="auto-margin">
                        <button
                            [disabled]="nameControl.invalid"
                            (click)="dialogRef.close(nameControl.value)"
                            mat-button
                        >
                            SAVE
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
