import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/**
 * Responsible for handling operations on files and file types.
 */
@Injectable()
export class FileService {
    /**
     * Represents common browser supported image file types.
     */
    private commonImageEtensions = ['bmp', 'ico', 'gif', 'jpg', 'jpeg', 'png', 'tiff'];

    /**
     * Represents common browser supported text file types.
     */
    private commonTextExtensions = ['txt', 'csv'];

    constructor(private domSanitizer: DomSanitizer, private httpClient: HttpClient) {}

    /**
     * Determines if the passed in file extension is an image file type.
     * @param extension Represents a file extension.
     */
    public isImageExtension(extension: string): boolean {
        return this.checkExtension(extension, this.commonImageEtensions);
    }

    /**
     * Determines if the passed in file extension is a text type.
     * @param extension Represents the file extension to be checked.
     */
    public isTextExtension(extension: string): boolean {
        return this.checkExtension(extension, this.commonTextExtensions);
    }

    public getFileNameFromURL(url: URL): string {
        return url.search.split('=').pop();
    }

    public getSanitizedUrl(fileUrl: string): SafeUrl {
        return this.domSanitizer.bypassSecurityTrustUrl(fileUrl);
    }

    /**
     * Retrieves the contents of a file and returns it as a string.
     * @param fileUrl Represents the uri of the file to retrieve.
     */
    public getFileContent(fileUrl: string): Observable<string> {
        return this.httpClient
            .get(fileUrl)
            .pipe(map((response: HttpResponse<any>) => (<any>response).text()))
            .pipe(catchError(() => of('-- The file could not be retrieved at this time. --')));
    }

    /**
     * Retrieves the extension as a string of a given filename.
     * @param fileName The file name from which to retrieve the extension.
     * @returns the extension of the file as a string.
     */
    public getFileExtension(fileName: string): string {
        // get segments of file name
        const fileSegments = fileName.split('.');

        // ensure that there is more segments than just one which will indicate that the file name has no extension
        if (fileSegments.length < 2) {
            return '-';
        }

        // get the last segment which will be the extension
        const potentialExtension = fileSegments.pop();

        // set extension and return
        return potentialExtension === '' ? '-' : potentialExtension;
    }

    /**
     * Determine if the file extensions collection contains the target file extension.
     * @param target Represents the target file extension to be checked.
     * @param extensions Represents the file extensions collection that the target will be checked against.
     */
    private checkExtension(target: string, extensions: Array<string>): boolean {
        return extensions.some((extension: string) => extension.toLowerCase() === target.toLowerCase());
    }
}
