<form [formGroup]="form">
    <mat-expansion-panel [ngClass]="{ 'echo-section-error': form.invalid }">
        <mat-expansion-panel-header class="mat-expasion-header-background">
            <mat-panel-title class="input-text">
                {{ form.get('name').value | uppercase }}
            </mat-panel-title>
            <mat-panel-description *ngIf="form.invalid && (form | customErrorCount)">
                <mat-icon>warning</mat-icon>
                <span>
                    {{ form | customErrorCount
                    }}{{ 'HOME.MAP.MARKER_LOCATION_DETAIL.TRITON_REQUIRED_ERRORS_TXT' | translate }}
                </span>
            </mat-panel-description>
            <mat-panel-description *ngIf="form.invalid && !(form | customErrorCount)">
                <mat-icon>warning</mat-icon> <span> </span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div [class.disable-field]="!isMonitorEditor">
            <div class="custom-50p pad-b-15">
                <div class="each-Item">
                    <mat-label>{{ 'ADD_EDIT.MAX_RANGE' | translate }}</mat-label>
                    <div>
                        <mat-slider
                            [ngModelOptions]="{ standalone: true }"
                            [min]="minValue"
                            [max]="maxValue"
                            [(ngModel)]="maxMeasurementRange"
                            [step]="stepRange"
                            (input)="onRangeChanged($event)"
                        ></mat-slider>
                        {{ rangeText }}
                    </div>
                </div>
                <div class="each-Item">
                    <mat-label>{{ physicalOffsetLabel }}</mat-label>
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            formControlName="physicalOffset"
                            (input)="onPhysicalOffsetChanged($event)"
                        />
                    </mat-form-field>
                    <div class="mat-error" *ngIf="form.get('physicalOffset').errors?.pattern">
                        {{ isMetric ? ('COMMON.NUMERIC_PRECISION_ERROR_1_DECIMAL' | translate) : ('COMMON.NUMERIC_PRECISION_ERROR_2_DECIMALS' | translate) }}
                    </div>
                </div>
            </div>
            <div class="each-Item mat-error" *ngIf="maxRangeError">
                {{ 'COMMON.MAX_RANGE_SELECTED' | translate }}
            </div>
        </div>
    </mat-expansion-panel>
</form>
