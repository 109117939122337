import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
@Injectable()
export class SnackBarNotificationService {
    /**
     *
     */
    constructor(private snackBar: MatSnackBar) {}

    public raiseNotification(
        message: string,
        action?: string,
        options: any = {},
        autoDismiss = true,
        dismissTimeout = 10000,
    ) {
        const notification = this.snackBar.open(message, action, options);
        if (autoDismiss) {
            setTimeout(notification.dismiss.bind(notification), dismissTimeout);
        }
    }
}
