import {
    Component,
    ViewEncapsulation,
    Input,
    ViewChild,
    SimpleChanges,
    Output,
    EventEmitter,
    OnChanges,
    OnInit,
} from '@angular/core';
import * as _ from 'underscore';
import { DailyMaximum, DryDayDefinition } from 'app/shared/models/sliicer/customer-rainfall-profile';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { ChangesAction } from 'app/pages/sliicer/shared/components/updates-widget/updates-widget.models';
import { NgForm } from '@angular/forms';
import { REGEX_CONFIG } from 'app/shared/utils/regex-utils';

const metricUnits = ['mm', 'in'];
const metricvalues = ['mm', 'inches'];

interface DryDaySettingsModel {
    minRainfallPrevOneDay?: string;
    minRainfallPrevThreeDays?: string;
    minRainfallPrevFiveDays?: string;
    maxFlow?: number;
    minFlow?: number;
}

@Component({
    selector: 'ads-dry-day-settings',
    templateUrl: './dry-day-settings.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})
export class DryDaySettingsComponent implements OnInit, OnChanges {
    @Input() public customerId: number;
    @Input() public isStudyLocked: boolean;
    @Input() public shouldUpdate: boolean;
    @Input() public changesAction: ChangesAction;
    @Input() public originalDryDayDefinition: DryDayDefinition;
    @Output() public checkChanges: EventEmitter<string> = new EventEmitter();
    @ViewChild('dryDefinitionForm') public dryDefinitionForm: NgForm;

    // Variables used by the HTML
    public unitText: string;
    public unitValue: string;

    public formValues: DryDaySettingsModel = {};
    public initialFormValues: DryDaySettingsModel = {};

    public isMetric: boolean;
    public formInvalid = false;

    public numericWith2DecimalPlaces = REGEX_CONFIG.numericWith2DecimalPlaces;
    public numericWith1DecimalPlaces = REGEX_CONFIG.numericWith1DecimalPlaces;
    public isPositiveInteger = REGEX_CONFIG.isPositiveInteger;
    constructor(public dateUtilService: DateutilService) {}

    public ngOnInit() {
        this.isMetric = this.dateUtilService.isCustomerUnitsMetric.getValue();
        this.unitValue = this.isMetric ? metricUnits[0] : metricUnits[1];
        this.unitText = this.isMetric ? metricvalues[0] : metricvalues[1];
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.shouldUpdate) {
            this.getDrydayDefinition(this.originalDryDayDefinition);
        } else if (changes.changesAction && this.getChangeValue()) {
            if (this.changesAction.action === 'undoChanges') {
                this.getDrydayDefinition(this.originalDryDayDefinition);
                this.dryDefinitionForm.form.markAsUntouched();
                this.dryDefinitionForm.form.markAsPristine();
                this.checkChanges.emit('');
            }
        }
    }

    public getDrydayDefinition(dd: DryDayDefinition) {
        if (this.isMetric === undefined) {
            this.isMetric = this.dateUtilService.isCustomerUnitsMetric.getValue();
        }
        if (dd && dd.dailyMaximum) {
            dd.dailyMaximum.forEach((item: DailyMaximum) => {
                if (item.dayCount === 1) {
                    this.formValues.minRainfallPrevOneDay = this.isMetric ? item.accumulation.toFixed(1) : item.accumulation.toFixed(2);
                } else if (item.dayCount === 3) {
                    this.formValues.minRainfallPrevThreeDays = this.isMetric ? item.accumulation.toFixed(1) : item.accumulation.toFixed(2);
                } else if (item.dayCount === 5) {
                    this.formValues.minRainfallPrevFiveDays = this.isMetric ? item.accumulation.toFixed(1) : item.accumulation.toFixed(2);
                }
            });
            this.formValues.maxFlow = dd.maxFlowPercentage;
            this.formValues.minFlow = dd.minFlowPercentage;
        }

        this.initialFormValues = { ...this.formValues };

        this.checkForm();
    }

    public getChanges(): DryDayDefinition {
        const dayCounts = [1, 3, 5];
        const dailyMaximumItems: Array<DailyMaximum> = [
            {
                dayCount: dayCounts[0],
                accumulation: Number(this.formValues.minRainfallPrevOneDay),
            },
            {
                dayCount: dayCounts[1],
                accumulation: Number(this.formValues.minRainfallPrevThreeDays),
            },
            {
                dayCount: dayCounts[2],
                accumulation: Number(this.formValues.minRainfallPrevFiveDays),
            },
        ];
        const dryDayDefinition: DryDayDefinition = {
            dailyMaximum: dailyMaximumItems,
            maxFlowPercentage: this.formValues.maxFlow,
            minFlowPercentage: this.formValues.minFlow,
            pk: this.customerId,
            docType: 'dryDayDefinition',
            id: this.originalDryDayDefinition.id,
        };
        const dryDayEqual = _.isEqual(this.originalDryDayDefinition, dryDayDefinition);

        if (!dryDayEqual) {
            return this.saveDrydayDefinition();
        }
        return null;
    }

    public saveDrydayDefinition(): DryDayDefinition {
        const dayCounts = [1, 3, 5];
        const dailyMaximumItems: Array<DailyMaximum> = [
            {
                dayCount: dayCounts[0],
                accumulation: Number(this.formValues.minRainfallPrevOneDay),
            },
            {
                dayCount: dayCounts[1],
                accumulation: Number(this.formValues.minRainfallPrevThreeDays),
            },
            {
                dayCount: dayCounts[2],
                accumulation: Number(this.formValues.minRainfallPrevFiveDays),
            },
        ];
        const dryDayDefinition: DryDayDefinition = {
            dailyMaximum: dailyMaximumItems,
            maxFlowPercentage: this.formValues.maxFlow,
            minFlowPercentage: this.formValues.minFlow,
            pk: this.customerId,
        };
        return dryDayDefinition;
    }

    public checkForm() {
        if (!this.formValues || !this.dryDefinitionForm) return;

        this.formInvalid = this.dryDefinitionForm.invalid;
    }

    private updateErrors(controlName: string, error: {[key: string]: boolean} | null) {
        if (!this.dryDefinitionForm) return;

        this.dryDefinitionForm.controls[controlName].setErrors(error);
    }

    public getChangeValue(): boolean {
        if (this.formInvalid) return false;

        return this.compareForm();
    }

    private compareForm() {
        if (!this.formValues) return false;

        return !(Object.keys(this.formValues).every(k => Number(this.formValues[k]) === Number(this.initialFormValues[k])));
    }
}
