import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationButton, ConfirmationDialogComponent } from 'app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { IComponentDialog, IComponentDialogConfirmationResult } from 'app/shared/models/comopnent-cofirmation';
import { FlowMonitoringConfigResponse } from 'app/shared/models/flow-monitoring-config';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { ERROR_NAME_ALREADY_EXIST, FlowMonitoringService } from 'app/shared/services/flow-monitoring.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';


export interface FmrOpenConfigurationDialogComponentInput {
    customerId: number
}

interface ConfigurationkRow {
    name: string;
    createdBy: string;
    editedBy: string;
    lastEdited: string | Date;
    row: FlowMonitoringConfigResponse
}

export interface FmrOpenConfigurationDialogComponentResult {
    configuration?: FlowMonitoringConfigResponse;
    deletedConfigurationIds?: Array<string>;
}

@Component({
  selector: 'app-fmr-open-configuration-dialog',
  templateUrl: './fmr-open-configuration-dialog.component.html',
  styles: ['.fmr-dialog {width: 800px;} .w-100 { width: 100px; } .w-150 {width: 150px; padding-right: 10px !important} .breakall {word-break: break-all;} .nowrap {white-space: nowrap;}'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FmrOpenConfigurationDialogComponent implements OnInit {
    @ViewChild('paginator') paginator: MatPaginator;

    public configurationColumns: string[] = ['name', 'createdBy', 'editedBy', 'lastEdited', 'actions'];
    public isLoading = false;
    public data: FmrOpenConfigurationDialogComponentInput;
    public dataSource: MatTableDataSource<ConfigurationkRow>;
    public confData;
    public length: number;
    public selection: SelectionModel<ConfigurationkRow>;
    public customerDateFormat;
    private deletedConfigurationIds: Array<string> = [];

    translations = {
        dismissText: null,
        nameAlreadyExist: null,
        errorOnSave: null
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) data: FmrOpenConfigurationDialogComponentInput,
        private dialogRef: MatDialogRef<FmrOpenConfigurationDialogComponent>,
        private flowMonitoringService: FlowMonitoringService,
        private matDialog: MatDialog,
        private dateutilService: DateutilService,
        private uiUtilsService: UiUtilsService,
        private cdr: ChangeDetectorRef,
        private snackBarService: SnackBarNotificationService,
        private translate: TranslateService
    ) {
        this.data = data;
    }

    ngOnInit(): void {
        this.dataSource = new MatTableDataSource();
        this.selection = new SelectionModel<ConfigurationkRow>(false, []);

        this.dateutilService.dateFormat.subscribe((newDateFormat) => {
            // Getting customer dateformat from dateUtil Service
            this.customerDateFormat = `${String(this.dateutilService.getFormat())} ${this.dateutilService.getTimeFormat()}`;
        });

        this.applyTranslations();

        this.fetchData();
    }

    public applyTranslations() {
        const translateKeys: Array<string> = [
            'COMMON.DISMISS_TEXT',
            'COMMON.NAME_ALREADY_EXISTS',
            'COMMON.ERROR_ON_SAVE'
        ];

        this.translate.get(translateKeys).subscribe((translateValues) => {
            if (!translateValues) {
                return;
            }
            this.translations.dismissText = translateValues['COMMON.DISMISS_TEXT'];
            this.translations.nameAlreadyExist = translateValues['COMMON.NAME_ALREADY_EXISTS'];
            this.translations.errorOnSave = translateValues['COMMON.ERROR_ON_SAVE'];
        });
    }

    public fetchData() {
        this.isLoading = true;
        this.flowMonitoringService.configurationsGet(this.data.customerId)
        .subscribe((res) => {
            this.dataSource.paginator = this.paginator;

            const data: ConfigurationkRow[] = res.map((row) => {
                const r: ConfigurationkRow = {
                    name: row.name,
                    createdBy: row.userCreated,
                    editedBy: row.userLastEdited,
                    lastEdited: new Date(row.lastEdited+'Z'),
                    row: row
                };

                return r;
            });

            this.dataSource.data = data;
            this.length = res.length;
            this.sortData();

            this.isLoading = false;
        });
    }

    public sortData() {
        this.dataSource.data = this.dataSource.data.sort((a, b) => a.lastEdited > b.lastEdited ? -1 : a.lastEdited < b.lastEdited ? 1 : 0);
    }

    public onCancel() {
        this.dialogRef.close(<FmrOpenConfigurationDialogComponentResult>{
            deletedConfigurationIds: this.deletedConfigurationIds
        });
    }

    public onLoad() {
        if(this.selection.selected && this.selection.selected.length > 0) {
            const selected = this.selection.selected[0];

            this.dialogRef.close(<FmrOpenConfigurationDialogComponentResult>{
                configuration: selected.row,
                deletedConfigurationIds: this.deletedConfigurationIds
            });
        }
    }

    public editConfig(popover, config, name) {
        if (!name) {
            return;
        }

        const postConf = {
            configName: name,
            settings: config.row.settings,
            id: config.row.id
        }

        this.flowMonitoringService.configurationsPost(this.data.customerId, postConf).subscribe(
            (res) => {
                config.lastEdited = res.lastEdited;
                config.name = res.name;
                config.row.name = res.name;
                config.userLastEdited = res.userLastEdited;
                config.editedBy = res.userLastEdited;

                this.sortData();
                popover.close();

                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (err) => {
                if(err.error === ERROR_NAME_ALREADY_EXIST) {
                    this.snackBarService.raiseNotification(this.translations.nameAlreadyExist, this.translations.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    }, true, 5000);
                } else {
                    this.snackBarService.raiseNotification(this.translations.errorOnSave, this.translations.dismissText, {
                        panelClass: 'custom-error-snack-bar',
                    }, true, 5000);
                }
            }
        );
    }

    public deleteConfig(config) {
        this.selection.clear();
        this.matDialog.open(ConfirmationDialogComponent, {
            disableClose: true,
            data: <IComponentDialog>{
                title: 'CONFIRM',
                message: `Please confirm that you want to delete this config.`,
                cancelText: 'Cancel',
                okText: 'OK',
            },
        })
        .afterClosed()
        .subscribe((btn: IComponentDialogConfirmationResult) => {
            if(btn.whichButtonWasPressed === ConfirmationButton.ok) {
                this.flowMonitoringService.configurationsDelete(this.data.customerId, config.row.id)
                    .subscribe((res) => {
                        this.selection.clear();
                        this.deletedConfigurationIds.push(config.row.id)
                        this.fetchData();
                    })

            }
        });

    }
}
