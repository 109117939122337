import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { LocationGroupMonitorSummary,MonitorName } from 'app/shared/models/sliicer-data';
import { DateutilService } from 'app/shared/services/dateutil.service';
import moment from 'moment';

@Component({
    selector: 'ads-app-details-dialog',
    styleUrls: ['./details-dialog.component.scss'],
    templateUrl: './details-dialog.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})
export class AdsDetailsDialogComponent implements OnInit {

    public isComparisionMode = false;

    public flowMonitors: Array<MonitorName> = [];
    public rainMonitors: Array<MonitorName> = [];


    public comparedFlowMonitors: Array<MonitorName> = [];
    public comparedRainMonitors: Array<MonitorName> = [];

    public location: string;
    public is12HourFormat = false;
    public dateFormat: string;
    public customDateFormat: string;
    public customerDateFormat: string;
    constructor(
        public dialogRef: MatDialogRef<AdsDetailsDialogComponent>,
        private dateutilService: DateutilService,
        @Inject(MAT_DIALOG_DATA) public data: {
            study: LocationGroupMonitorSummary;
            current?: LocationGroupMonitorSummary
        }
    ) {}

    private formatSummaryLocations(monitors: MonitorName[]) {
        const nullDateYear = '0001-01-01T00:00:00';
        const maxDate = '9999-12-31T23:59:59.9999999';

        return monitors.map(
            o => ({ ...o,
                earliestReading: o.earliestReading && o.earliestReading!=null && o.earliestReading!=nullDateYear && o.earliestReading!=maxDate
                    ? moment(o.earliestReading).format(this.customerDateFormat) : '-',
                latestReading:o.latestReading && o.latestReading!=null && o.latestReading!=nullDateYear && o.latestReading!=maxDate
                    ? moment(o.latestReading).format(this.customerDateFormat) : '-' }));
    }

    public ngOnInit() {
        this.customDateFormat = "MM/DD/YYYY";

        this.dateutilService.dateFormat.subscribe(() => {

            this.customerDateFormat =
                this.dateutilService.getFormat().replace('dd','DD');

        });

        this.flowMonitors = this.formatSummaryLocations(this.data.study.flowMonitorNames);
        this.flowMonitors.sort((a,b) => a.name.localeCompare(b.name));
        
        this.rainMonitors = this.formatSummaryLocations(this.data.study.rainfallMonitorNames);
        this.rainMonitors.sort((a,b) => a.name.localeCompare(b.name));

        this.isComparisionMode = !!this.data.current;

        if(this.isComparisionMode) {
            this.comparedFlowMonitors = this.formatSummaryLocations(this.data.current.flowMonitorNames);
            this.comparedRainMonitors = this.formatSummaryLocations(this.data.current.rainfallMonitorNames);
        }

        this.location = this.data.study.locationGroupName;
    }

    public close() {
        this.dialogRef.close(false);
    }

    public closeUseNew() {
        this.dialogRef.close(true);
    }
}
