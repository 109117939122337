<div id="scheduling-dashboard" class="contentLayout">
    <app-customized-confirmation-box
        *ngIf="showConfirmation"
        [confirmationData]="conformationDataInput"
    ></app-customized-confirmation-box>
    <app-loader [isLoading]="isLoading"></app-loader>
    <mat-card>
        <!-- scheduling dashboard header -->
        <mat-card-header>
            <div class="auto-margin schedule-title-hint">
                {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.SCHEDULE_COLLECT_TITLE' | translate }}
            </div>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button (click)="addEditSchedule()" class="add-schedule-hint">
                    <mat-icon>add</mat-icon>{{ 'COMMON.ADD_SCHEDULE_TEXT' | translate }}
                </button>
            </div>
            <div class="auto-margin">
                <mat-icon
                    class="backIcon filter-hint"
                    matTooltip="{{ 'COMMON.FILTER_TOOLTIP' | translate }}"
                    [ngClass]="{ active: displayFilters }"
                    (click)="showHideFilter()"
                    >filter_list</mat-icon
                >
            </div>
        </mat-card-header>
        <mat-card-content>
            <!-- filter action -->
            <div *ngIf="displayFilters">
                <!-- Filter Section  -->
                <div class="filterSection margin-b-15">
                    <div class="report-text">
                        <mat-form-field class="full-width name-filter-hint">
                            <input
                                matInput
                                [(ngModel)]="filterArray.name"
                                (ngModelChange)="applyFilter()"
                                placeholder="{{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.NAME' | translate }}"
                            />
                        </mat-form-field>
                    </div>
                    <div class="report-text">
                        <mat-form-field class="full-width week-days-hint">
                            <mat-select
                                [(ngModel)]="filterArray.day"
                                (selectionChange)="applyFilter()"
                                placeholder="{{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.DAYS_OF_WEEK' | translate }}"
                                multiple
                            >
                                <mat-option *ngFor="let day of filterDayList" [value]="day.id"
                                    >{{ day.text }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="report-text">
                        <mat-form-field class="full-width frequency-hint">
                            <mat-select
                                [(ngModel)]="filterArray.frequency"
                                (selectionChange)="applyFilter()"
                                placeholder="{{ 'HOME.WIDGET_SCHEDULE.FREQUENCY_PLACEHOLDER' | translate }}"
                                multiple
                            >
                                <mat-option *ngFor="let frequency of frequenciesList" [value]="frequency.text"
                                    >{{ frequency.text }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- Scheduling Table -->
            <div id="schedulingdatatable">
                <mat-table #table [dataSource]="schedulingDataSource" matSort>
                    <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'LOCATION_DASHBOARD.VIEW_DATA_LOCATION_DETAILS.NAME' | translate }}</mat-header-cell
                        >
                        <mat-cell *matCellDef="let element">
                            <div class="schedule-name">{{ element.name }}</div>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="locationCount">
                        <mat-header-cell *matHeaderCellDef class="text-right">{{
                            'COMMON.LOCATION_COUNTS' | translate
                        }}</mat-header-cell>
                        <mat-cell *matCellDef="let element" class="text-right frequency-cell-width"
                            >{{ element.locationCount }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="days">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.DAYS_OF_WEEK' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <div class="days-container">
                                <mat-checkbox
                                    *ngFor="let day of daysSelected[element.index]"
                                    name="chkboxday"
                                    [checked]="day.checked"
                                    required="true"
                                    disabled
                                >
                                    <div class="each-day">{{ day.text }}</div>
                                </mat-checkbox>
                            </div>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="frequency">
                        <mat-header-cell *matHeaderCellDef
                            >{{ 'HOME.WIDGET_SCHEDULE.FREQUENCY_PLACEHOLDER' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="frequency-cell-width"
                            >{{ element.frequency }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="eventFrequency">
                        <mat-header-cell *matHeaderCellDef>
                            {{ 'COMMON.SCHEDULE_COLLECT_DASHBOARDS.EVENT_FREQUENCY' | translate }}
                        </mat-header-cell>
                        <mat-cell *matCellDef="let element" class="frequency-cell-width"
                            >{{ element.alarmingFrequency }}
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef class="fixCellWidth"></mat-header-cell>
                        <mat-cell *matCellDef="let element; let i = index" class="text-right schedule-action">
                            <button (click)="addEditSchedule(element.scheduleId)" mat-icon-button>
                                <mat-icon
                                    [ngClass]="{ 'edit-schedule-hint': i === 0 }"
                                    matTooltip="{{ 'COMMON.EDIT_TOOLTIP' | translate }}"
                                    >mode_edit</mat-icon
                                >
                            </button>
                            <button mat-icon-button (click)="showDeleteConfirmationScheduleCollection(element)">
                                <mat-icon
                                    [ngClass]="{ 'delete-hint': i === 0 }"
                                    matTooltip="{{ 'COMMON.DELETE_TOOLTIP' | translate }}"
                                    >delete</mat-icon
                                >
                            </button>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="schedulingDescriptionColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: schedulingDescriptionColumns"></mat-row>
                </mat-table>
                <ng-template [ngIf]="dataLength < 1">
                    <span class="notabledataFound">{{ 'HOME.WIDGET_SCHEDULE.NO_SCHEDULE_FOUND' | translate }}</span>
                </ng-template>
            </div>
        </mat-card-content>
    </mat-card>
</div>
