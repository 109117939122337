export class SignalRMessage {
    public type: SignalRMessageType;
    public message: string;
    public cid?: number;
    public lid?: number;
    public url?: string;
    public userName?: string;
    public userId?: string;
    public alarmType: number;
    public filePath?: string;
}

export class TopnavSignalRMessage extends SignalRMessage {
    public fileName?: string;
    public timestamp?: Date;
    public customerName?: string;
    public locationName?: string;
    public state?: NotificationState;
    public success?: boolean;
    public detail?: string;
    public caseStudyId?: string;
    public caseStudyName?: string;
}

export enum SliicerMessageType {
    SingleTelemetryStart,
    SingleTelemetryEnd,
    TelemetryCopyComplete,
    CalculationsStarted,
    CalculationsComplete,
    ExportComplete,
    PowerBiUpdateComplete,
}
export class SliicerSignalRMessage extends SignalRMessage {
    public static SLIICER_MESSAGE = 'SLIICER_MESSAGE';
    public static EXPORT_MESSAGE = 'Data export complete';
    public messageType: SliicerMessageType;
    public caseStudyId: string;
    public success: boolean;
    public detail: string;
    public caseStudyName?: string;
}

export enum SignalRMessageType {
    Alarm = 0,
    Info,
    Warning,
    Broadcast,
    Admin,
    User,
}

export enum NotificationState {
    READ = 0,
    UNREAD,
}

export const VAULT_MOVE_SUCCESS_MESSAGE = 'Vault move completed.';
export const VAULT_MOVE_FAIL_MESSAGE = 'Vault move failed.';
