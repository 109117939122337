import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { Config } from 'app/shared/services/config';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { customerQueryParam } from '../models/customer';

const SSO = 'sso';
const RAIN = 'rain';
const FLOWMETER = 'flow meter';

@Injectable()
export class TelogService {
    /**
     * Represents current customer ID
     */
    private customerID: number;

    /**
     * Represents a placeholder for subscriptions.
     */
    private subscriptions = new Array<Subscription>();

    constructor(private http: HttpClient, private activatedRoute: ActivatedRoute) {
        this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
            this.customerID = Number(params.get(customerQueryParam));
        });
    }

    public importTelogFile(id: string): Observable<boolean> {
        // HACK: remove slash, this should be removed in API during GET
        if (id.charAt(0) === '/') {
            id = id.substr(1, id.length);
        }

        // creating HttpParams for HttpClient request

        const params = {
            cid: this.customerID,
            uri: id,
        };

        if (id.toLowerCase().includes(SSO) && !id.toLowerCase().includes(FLOWMETER)) {
            return this.http.post<boolean>(Config.urls.telogImportSSO, params);
        } else if (id.toLowerCase().includes(RAIN)) {
            return this.http.post<boolean>(Config.urls.telogImportRain, params);
        } else {
            return this.http.post<boolean>(Config.urls.telogImportfm, params);
        }
    }
}
