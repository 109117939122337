import { Injectable } from '@angular/core';
import { Config } from './config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { NotificationRouteAlarmType, NotificationDetailsLite } from '../models/notifications';
import { AlarmTypes, DailyReportTypes } from '../models/alarms';
import { CustomerFeatures } from '../models/customer';
import { LocationConfigurations } from '../models/report';

@Injectable()
export class NotificationDashboardService {
    private TEXT: any;
    constructor(public http: HttpClient, private translate: TranslateService) {
        this.translate.get('COMMON').subscribe((res: string) => {
            this.TEXT = res;
        });
    }

    public getAllNotificationDetails(
        cid: number,
        pageSize: number,
        startPage: number,
        searchString: string,
        searchValue: {},
    ) {
        const reqUrl = `${Config.serviceUrl}${Config.urls.notificationDetailsAll}?cid=${cid}&PageSize=${pageSize}&StartPage=${startPage}&searchValue=${searchString}`;
        return this.http.post<any>(reqUrl, searchValue);
    }
    public getSingleNotificationDetails(cid: number, guid: string): Observable<NotificationDetailsLite> {
        const reqUrl = `${Config.serviceUrl}${Config.urls.notificationDetails}?cid=${cid}&guid=${guid}`;
        return this.http.get<NotificationDetailsLite>(reqUrl);
    }
    public deleteNotification(cid: number, guid: string) {
        const reqUrl = `${Config.serviceUrl}${Config.urls.notificationDetails}?cid=${cid}&guid=${guid}`;
        return this.http.delete(reqUrl);
    }
    public updateNotification(cid: number, body) {
        const reqUrl = `${Config.serviceUrl}${Config.urls.notificationDetails}?cid=${cid}`;
        return this.http.post(reqUrl, body);
    }
    public validateNotification(cid: number, body: NotificationDetailsLite) {
        const reqUrl = `${Config.serviceUrl}${Config.urls.notificationValidate}?cid=${cid}&validateAlarmConfiguration=true`;
        return this.http.post(reqUrl, body);
    }
    public convertAlarmTypeToGeneral(specificAlarmType: number) {
        // TODO AMP: This needs to handle ANSR too
        const generalType: NotificationRouteAlarmType = NotificationRouteAlarmType.Standard;
        return generalType;
    }
    public convertAlarmTypeToSpecific(generalAlarmType: NotificationRouteAlarmType) {
        const allAlarms = this.getAllAlarmTypes();
        return allAlarms.filter((x) => x.type === generalAlarmType).map((x) => x.id);
    }
    public getSpecificDailyAlarmType(id: number): { name: string; id: DailyReportTypes } | null {
        const allAlarms = this.getAllAlarmTypesDaily();
        return allAlarms.find((x) => x.id === id);
    }
    public getSpecificAlarmType(id: number) {
        const allAlarms = this.getAllAlarmTypes();
        return allAlarms.find((x) => x.id === id);
    }
    public getWDONotConfigured() {
        return this.TEXT.WDO_NOT_CONFIGURED;
    }
    public getAllAlarmTypes() {
        const alarmTypes = [
            {
                name: this.TEXT.LOW_BATTERY_ALARM,
                id: AlarmTypes.LOW_BATTERY_ALARM,
                type: NotificationRouteAlarmType.Standard,
            },
            {
                name: this.TEXT.HIGH_HIGH_ALARAM,
                id: AlarmTypes.HIGH_HIGH_ALARM,
                type: NotificationRouteAlarmType.Standard,
            },
            {
                name: this.TEXT.HIGH_FLOW_ALARM,
                id: AlarmTypes.HIGH_FLOW_ALARM,
                type: NotificationRouteAlarmType.Standard,
            },
            {
                name: this.TEXT.HIGH_LEVEL_ALARM,
                id: AlarmTypes.HIGH_LEVEL_ALARM,
                type: NotificationRouteAlarmType.Standard,
            },
            {
                name: this.TEXT.LOW_LEVEL_ALARM,
                id: AlarmTypes.LOW_LEVEL_ALARM,
                type: NotificationRouteAlarmType.Standard,
            },
            { name: this.TEXT.RAIN_ALARM, id: AlarmTypes.RAIN_ALARM, type: NotificationRouteAlarmType.Standard },
            {
                name: this.TEXT.LOW_FLOW_ALARM,
                id: AlarmTypes.LOW_FLOW_ALARM,
                type: NotificationRouteAlarmType.Standard,
            },
            {
                name: this.TEXT.SUBMERGED_ALARM,
                id: AlarmTypes.SUBMERGED_ALARM,
                type: NotificationRouteAlarmType.Standard,
            },
            { name: this.TEXT.TILT_ALARM, id: AlarmTypes.TILT_ALARM, type: NotificationRouteAlarmType.Standard },
            {
                name: this.TEXT.FULL_PIPE_ALARM,
                id: AlarmTypes.FULL_PIPE_ALARM,
                type: NotificationRouteAlarmType.Standard,
            },
            {
                name: this.TEXT.OVERFLOW_ALARM,
                id: AlarmTypes.OVERFLOW_ALARM,
                type: NotificationRouteAlarmType.Standard,
            },
            {
                name: this.TEXT.WET_OVERFLOW_ALARM,
                id: AlarmTypes.WET_OVERFLOW_ALARM,
                type: NotificationRouteAlarmType.Standard,
                featureCondition: CustomerFeatures.WetDryOverflow,
            },
            {
                name: this.TEXT.DRY_OVERFLOW_ALARM,
                id: AlarmTypes.DRY_OVERFLOW_ALARM,
                type: NotificationRouteAlarmType.Standard,
                featureCondition: CustomerFeatures.WetDryOverflow,
            },
            { name: 'ANSR', id: 0, type: NotificationRouteAlarmType.ANSR },
        ];

        alarmTypes.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });

        return alarmTypes;
    }
    public getAllAlarmTypesAnsr() {
        const ansrAlarmTypes = [
            {
                name: this.TEXT.LOW_BATTERY_ALARM,
                id: AlarmTypes.LOW_BATTERY_ALARM,
                type: NotificationRouteAlarmType.Standard,
            },
            {
                name: this.TEXT.HIGH_HIGH_ALARAM,
                id: AlarmTypes.HIGH_HIGH_ALARM,
                type: NotificationRouteAlarmType.Standard,
            },
            {
                name: this.TEXT.HIGH_FLOW_ALARM,
                id: AlarmTypes.HIGH_FLOW_ALARM,
                type: NotificationRouteAlarmType.Standard,
            },
            {
                name: this.TEXT.HIGH_LEVEL_ALARM,
                id: AlarmTypes.HIGH_LEVEL_ALARM,
                type: NotificationRouteAlarmType.Standard,
            },
            {
                name: this.TEXT.LOW_LEVEL_ALARM,
                id: AlarmTypes.LOW_LEVEL_ALARM,
                type: NotificationRouteAlarmType.Standard,
            },
            {
                name: this.TEXT.SUBMERGED_ALARM,
                id: AlarmTypes.SUBMERGED_ALARM,
                type: NotificationRouteAlarmType.Standard,
            },
            { name: this.TEXT.RAIN_ALARM, id: AlarmTypes.RAIN_ALARM, type: NotificationRouteAlarmType.Standard },
            { name: this.TEXT.TILT_ALARM, id: AlarmTypes.TILT_ALARM, type: NotificationRouteAlarmType.Standard },
            {
                name: this.TEXT.LOW_FLOW_ALARM,
                id: AlarmTypes.LOW_FLOW_ALARM,
                type: NotificationRouteAlarmType.Standard,
            },
            {
                name: this.TEXT.FULL_PIPE_ALARM,
                id: AlarmTypes.FULL_PIPE_ALARM,
                type: NotificationRouteAlarmType.Standard,
            },
            {
                name: this.TEXT.OVERFLOW_ALARM,
                id: AlarmTypes.OVERFLOW_ALARM,
                type: NotificationRouteAlarmType.Standard,
            },
        ];

        ansrAlarmTypes.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });

        return ansrAlarmTypes;
    }
    public getAllAlarmTypesDaily(): { name: string; id: DailyReportTypes }[] {
        const dailyAlarmTypes = [
            { name: this.TEXT.COMMUNICATION_ALARM, id: DailyReportTypes.COMMUNICATION },
            { name: this.TEXT.LOW_BATTERY_ALARM, id: DailyReportTypes.LOW_BATTERY },
        ];
        return dailyAlarmTypes;
    }

    public getCustomAlarms(cid: number) {
        const httpParams: HttpParams = new HttpParams();
        return this.http.get(Config.serviceUrl + Config.urls.customAlarms + '?customerId=' + cid, {
            params: httpParams,
        });
    }

    public getNotificationConfiguration(cid: number, sort: string, sortdirection: string, pageIndex?: number, pageSize?: number) {
        let url = `${Config.urls.locationNotificationsConfiguration}?cid=${cid}&sort=${sort}&sortdirection=${sortdirection}`;
        if(pageSize) {
            url += `&pageSize=${pageSize}&StartPage=${pageIndex}`;
        }
        return this.http.get<LocationConfigurations>(url);
    }
}
