import moment from 'moment';
import { StormPeriodAdjustment } from '../../flow-monitor.model';
import * as E from 'fp-ts/es6/Either';
import { Either, isRight, left, right, either } from 'fp-ts/es6/Either';
import { Option, some, none, fold } from 'fp-ts/es6/Option';
import { pipe } from 'fp-ts/es6/pipeable';
import { sequenceS } from 'fp-ts/es6/Apply';
import {
    decodeDate,
    FormField,
    NonNegInt,
    PositiveInt,
    validNonNegInt,
    validPositiveInt,
    decodeNumber,
    safeDate,
    SafeDate,
} from 'app/pages/sliicer/shared/utils/composable-validation';
import { PrecompensationType, Regime } from 'app/shared/models/sliicer/settings';
import { Season } from '../../../study-settings/seasons-settings/seasons-settings.utils';

// API format contains seconds, this has to reflect it.
const dateFormat = 'YYYY-MM-DDTHH:mm:ss';
const ONE_MINUTE = 1000 * 60;

export type StormSettingsDialogData = {
    adjustment: StormPeriodAdjustment;
    precompType: Option<PrecompensationType>;
    season: string;
    regime: string;
    seasons?: Season[];
    regimes?: Regime[];
    altPrecompStart?: string;
    altPrecompEnd?: string;
    deattachPrecomp?: boolean;
    exclude?: boolean;
    isCreateMode?: boolean;
    existingStormsStartDates?: number[];
};

export type Form = {
    stormId: number;
    rainStartTime: FormField<Date, SafeDate>;
    rainEndTime: FormField<Date, SafeDate>;
    eventStartDate: FormField<Date, SafeDate>;
    eventEndDate: FormField<Date, SafeDate>;
    preStartDate: FormField<Date, SafeDate>;
    preEndDate: FormField<Date, SafeDate>;
    deattachPrecomp: boolean;
    exclude: boolean;
    isCreateMode: boolean;
    precompPeriod: FormField<string, NonNegInt>;
    stormPeriod: FormField<string, PositiveInt>;
    recoveryOne: FormField<string, NonNegInt>;
    recoveryTwo: FormField<string, NonNegInt>;
    precompType: FormField<string, Option<PrecompensationType>>;
    season: string;
    regime: string;
    isDirty: boolean;
};

const decodeMinutes = (str: string) => pipe(str, decodeNumber, E.chain(validNonNegInt));
const decodeMinutesP = (str: string) => pipe(str, decodeNumber, E.chain(validPositiveInt));
function decodePrecompType(str: string): Either<string, Option<PrecompensationType>> {
    if (str === '') {
        return right(none);
    } else if (
        Object.values(PrecompensationType)
            .map((x) => x.toString())
            .includes(str)
    ) {
        return right(some(<PrecompensationType>str));
    } else {
        return left('Incorrect value');
    }
}

const initDateFormField = (e: Either<string, SafeDate>) =>
    <FormField<Date, SafeDate>>{ raw: isRight(e) ? e.value : null, val: e };

const addMinutes = (minutes: number) => (d: Date) => moment(d).add(minutes, 'minutes').toDate();

const subtractMinutes = (minutes: number) => (d: Date) => moment(d).subtract(minutes, 'minutes').toDate();
const optionToStr = fold(
    () => '',
    (val: string) => val,
);

export function init(data: StormSettingsDialogData): Form {
    const stormStartTime = decodeDate(data.adjustment.stormStartTime);

    const minute = 1000 * 60;
    const altPrecompInitDate = new Date(new Date(data.adjustment.stormStartTime).getTime() - minute * data.adjustment.precompPeriodLength);
    const altPrecompInitUTC = new Date(altPrecompInitDate.getTime() - altPrecompInitDate.getTimezoneOffset() * 60000);
    const altPrecompinit = altPrecompInitUTC.toISOString().slice(0,-5);

    const altPrecompStart = (data.adjustment.altPrecompStart && !data.adjustment.deattachPrecomp) ? decodeDate(data.adjustment.altPrecompStart) : decodeDate(altPrecompinit);

    const out: Form = {
        stormId: data.adjustment.stormId,
        rainStartTime: initDateFormField(stormStartTime),
        rainEndTime: pipe(stormStartTime, E.map(addMinutes(data.adjustment.stormPeriodLength)), initDateFormField),
        eventStartDate: pipe(stormStartTime, initDateFormField),
        eventEndDate: pipe(stormStartTime,
            E.map(
                addMinutes(
                    data.adjustment.stormPeriodLength +
                    data.adjustment.recovery1PeriodLength +
                    data.adjustment.recovery2PeriodLength
                )
            ),
            initDateFormField
        ),
        precompPeriod: {
            val: validNonNegInt(data.adjustment.precompPeriodLength),
            raw: data.adjustment.precompPeriodLength.toString(),
        },
        stormPeriod: {
            val: validPositiveInt(data.adjustment.stormPeriodLength),
            raw: data.adjustment.stormPeriodLength.toString(),
        },
        recoveryOne: {
            val: validNonNegInt(data.adjustment.recovery1PeriodLength),
            raw: data.adjustment.recovery1PeriodLength.toString(),
        },
        recoveryTwo: {
            val: validNonNegInt(data.adjustment.recovery2PeriodLength),
            raw: data.adjustment.recovery2PeriodLength.toString(),
        },
        precompType: { val: right(data.precompType), raw: optionToStr(data.precompType) },
        season: data.season,
        regime: data.regime,
        isDirty: false,
        isCreateMode: data.isCreateMode,
        deattachPrecomp: data.adjustment.deattachPrecomp,
        exclude: data.exclude,
        preStartDate: pipe(altPrecompStart, initDateFormField),
        preEndDate: pipe(
            altPrecompStart,
            E.map(
                addMinutes(
                    data.adjustment.precompPeriodLength
                )
            ),
            initDateFormField
        )
    };

    return out;
}

const formatDate = (format: string) => (m: Date) => moment(m).format(format);

const dateDiffMinutes = (m1: Date) => (m2: Date) => moment(m1).diff(moment(m2), 'minutes');

const dateAddMinutes = (format: string) => (m: Date) => (mm: number) => moment(m).add(mm, 'minutes').format(format);

export const result = (form: Form): Either<string, StormSettingsDialogData> => {
    const adjustment: Either<string, StormPeriodAdjustment> = sequenceS(either)({
        stormId: right<string, number>(form.stormId),
        precompPeriodLength: form.precompPeriod.val,
        stormStartTime: E.map(formatDate(dateFormat))(form.rainStartTime.val),
        stormPeriodLength: form.stormPeriod.val,
        recovery1PeriodLength: form.recoveryOne.val,
        recovery2PeriodLength: form.recoveryTwo.val,
        altPrecompStart: E.map(formatDate(dateFormat))(form.preStartDate.val),
        altPrecompEnd: E.map(formatDate(dateFormat))(form.preEndDate.val),
        deattachPrecomp: right(form.deattachPrecomp),
        exclude: right(form.exclude),
        isCreateMode: right(form.isCreateMode)
    });

    return sequenceS(either)({
        adjustment: adjustment,
        precompType: form.precompType.val,
        season: right(form.season),
        regime: right(form.regime),
        altPrecompStart: E.map(formatDate(dateFormat))(form.preStartDate.val),
        altPrecompEnd: E.map(formatDate(dateFormat))(form.preEndDate.val),
        deattachPrecomp: right(form.deattachPrecomp),
        exclude: right(form.exclude),
        isCreateMode: right(form.isCreateMode)
    });
};

export function updateRainEnd(form: Form, date: Date): Form {
    const rainEndRes = safeDate(date);
    const res = sequenceS(either)({
        rainStart: form.rainStartTime.val,
        rainEnd: rainEndRes,
        eventEnd: form.eventEndDate.val,
        recoveryTwo: form.recoveryTwo.val,
        data: result(form),
    });
    if (isRight(res)) {
        return init({
            adjustment: {
                ...res.value.data.adjustment,
                stormPeriodLength: dateDiffMinutes(res.value.rainEnd)(res.value.rainStart),
                recovery1PeriodLength: dateDiffMinutes(res.value.eventEnd)(res.value.rainEnd) - res.value.recoveryTwo,
            },
            precompType: res.value.data.precompType,
            season: res.value.data.season,
            regime: res.value.data.regime,
        });
    } else {
        return { ...form, rainEndTime: { raw: date, val: rainEndRes } };
    }
}

export function updateRainStart(form: Form, date: Date): Form {
    const rainStartRes = safeDate(date);
    const res = sequenceS(either)({
        rainEnd: form.rainEndTime.val,
        rainStart: rainStartRes,
        eventStart: form.eventStartDate.val,
        data: result(form),
    });

    if (isRight(res)) {
        return init({
            adjustment: {
                ...res.value.data.adjustment,
                stormStartTime: formatDate(dateFormat)(res.value.rainStart),
                stormPeriodLength: dateDiffMinutes(res.value.rainEnd)(res.value.rainStart),
                precompPeriodLength: dateDiffMinutes(res.value.rainStart)(res.value.eventStart),
            },
            precompType: res.value.data.precompType,
            season: res.value.data.season,
            regime: res.value.data.regime,
        });
    } else {
        return { ...form, rainStartTime: { raw: date, val: rainStartRes } };
    }
}

export function updateEventStart(form: Form, date: Date): Form {
    const eventStartRes = safeDate(date);
    const res = sequenceS(either)({
        rainStart: form.rainStartTime.val,
        eventStart: eventStartRes,
        data: result(form),
    });

    if (isRight(res)) {
        // if it's minus then do not invalidate form, instead move date
        const diff = dateDiffMinutes(res.value.rainStart)(res.value.eventStart);

        if (diff >= 0) {
            return init({
                adjustment: {
                    ...res.value.data.adjustment,
                    stormStartTime: formatDate(dateFormat)(res.value.eventStart),
                    // precompPeriodLength: dateDiffMinutes(res.value.rainStart)(res.value.eventStart)
                },
                precompType: res.value.data.precompType,
                season: res.value.data.season,
                regime: res.value.data.regime,
                isCreateMode: res.value.data.isCreateMode,
            });
        } else {
            return init({
                adjustment: {
                    ...res.value.data.adjustment,
                    // stormStartTime: dateAddMinutes(dateFormat)(res.value.eventStart)(res.value.data.adjustment.precompPeriodLength),
                    stormStartTime: formatDate(dateFormat)(res.value.eventStart),
                },
                precompType: res.value.data.precompType,
                season: res.value.data.season,
                regime: res.value.data.regime,
                isCreateMode: res.value.data.isCreateMode,
            });
        }
    } else {
        return { ...form, eventStartDate: { raw: date, val: eventStartRes } };
    }
}

export function updateEventEnd(form: Form, date: Date): Form {
    const eventEndRes = safeDate(date);
    const res = sequenceS(either)({
        rainEnd: form.rainEndTime.val,
        recoveryOne: form.recoveryOne.val,
        eventEnd: eventEndRes,
        data: result(form),
    });

    if (isRight(res)) {
        return init({
            adjustment: {
                ...res.value.data.adjustment,
                recovery2PeriodLength: dateDiffMinutes(res.value.eventEnd)(res.value.rainEnd) - res.value.recoveryOne,
            },
            precompType: res.value.data.precompType,
            season: res.value.data.season,
            regime: res.value.data.regime,
        });
    } else {
        return { ...form, eventEndDate: { raw: date, val: eventEndRes } };
    }
}

export function updatePreStart(form: Form, date: Date): Form {
    const eventStartRes = safeDate(date);
    return { ...form, preStartDate: { raw: date, val: eventStartRes } };
}

export function updatePreEnd(form: Form, date: Date): Form {
    const eventEndRes = safeDate(moment(date).add(form.precompPeriod.raw, 'minutes').toDate());
    if(eventEndRes.isRight()) {
        return { ...form, preEndDate: { raw: eventEndRes.value, val: eventEndRes } };
    } else {
        return { ...form, preEndDate: { raw: date, val: eventEndRes } };
    }
}

export function updateDeattachPrecomp(form: Form, bool: boolean): Form {
    if (bool) {
        // need to ensure that precomp start is attached to storm start time
        const precompDuration = parseInt(form.precompPeriod.raw);
        const newPrecompStart = safeDate(new Date(form.eventStartDate.raw.getTime() - (precompDuration * ONE_MINUTE)));

        return { 
            ...form, deattachPrecomp: bool,
            preStartDate: pipe(newPrecompStart, initDateFormField),
            preEndDate: pipe(
                newPrecompStart,
                E.map(addMinutes(precompDuration)),
                initDateFormField
            ),
        };
    }

    return { ...form, deattachPrecomp: bool };
}

export function updateExcludeStorm(form: Form, bool: boolean): Form {
    return { ...form, exclude: bool };
}

export function updatePrecompPeriod(form: Form, str: string): Form {
    const precompRes = decodeMinutes(str);
    const res = sequenceS(either)({
        precomp: precompRes,
        data: result(form),
    });

    return isRight(res)
        ? init({
            ...res.value.data,
            adjustment: { ...res.value.data.adjustment, precompPeriodLength: res.value.precomp },
        })
        : { ...form, precompPeriod: { raw: str, val: precompRes } };
}

export function updateStormPeriod(form: Form, str: string): Form {
    const stormPeriodRes = decodeMinutesP(str);
    const res = sequenceS(either)({
        stormPeriod: stormPeriodRes,
        data: result(form),
    });

    return isRight(res)
        ? init({
            ...res.value.data,
            adjustment: { ...res.value.data.adjustment, stormPeriodLength: res.value.stormPeriod },
        })
        : { ...form, stormPeriod: { raw: str, val: stormPeriodRes } };
}

export function updateRecoveryOne(form: Form, str: string): Form {
    const recoveryOneRes = decodeMinutes(str);
    const res = sequenceS(either)({
        recoveryOne: recoveryOneRes,
        data: result(form),
    });

    return isRight(res)
        ? init({
            ...res.value.data,
            adjustment: { ...res.value.data.adjustment, recovery1PeriodLength: res.value.recoveryOne },
        })
        : { ...form, recoveryOne: { raw: str, val: recoveryOneRes } };
}

export function updateRecoveryTwo(form: Form, str: string) {
    const recoveryTwoRes = decodeMinutes(str);
    const res = sequenceS(either)({
        recoveryTwo: recoveryTwoRes,
        data: result(form),
    });

    return isRight(res)
        ? init({
            ...res.value.data,
            adjustment: { ...res.value.data.adjustment, recovery2PeriodLength: res.value.recoveryTwo },
        })
        : { ...form, recoveryTwo: { raw: str, val: recoveryTwoRes } };
}

export function updatePrecompType(form: Form, str: string): Form {
    return { ...form, precompType: { raw: str, val: decodePrecompType(str) } };
}
