import { TranslateService } from "@ngx-translate/core";
import { EDIT_COLOR, HG_COLOR_PIPE_HEIGHT, HG_COLOR_SILT, PRESSK_ENTITY, RAIN_DISPLAY_GROUP, RAIN_HEXCODE_UNFLAGGED, RAW_VELOCITY_ENTITY, RED_COLOR_HEX, VELOCITY_ENTITY, QUANTITY_ENTITY } from "app/shared/constant";
import { ManualScale } from "app/shared/models/hydrograph";
import { AnnotationOptions, AnnotationPipeHeights, AnnotationStringType, AnnotationType, BasicSeriesData, DataType, GraphExtents, HGEditParams } from "app/shared/models/hydrographNEW";
import { HydrographNEWService } from "app/shared/services/hydrographNEW.service";
import _, { isInteger } from "lodash";
import { ChartDashStyle, ChartSerieType, GraphAnnotationSerieConfiguration, GraphChartConfiguration, GraphDataConfiguration, GraphEventSerieConfiguration, GraphSerieConfiguration, GraphYAxisConfiguration, HydrographBuildDataConfig, ChartPointShape } from "./hydrograph-data-model"
import { EventTypes } from "app/shared/models/event";
import { Injectable } from "@angular/core";
import { DisplayGroupScales } from "app/shared/models/user-settings";
import { MAX_RAIN_IN, MAX_RAIN_MM } from "app/shared/models/units";
import { ConfirmationEntitiesEnum } from "app/shared/models/view-data-filter";

/**
 * Private interface, used by HydrographDataBuilder for build data purpose.
 * Class returns chartOptions: GraphChartConfiguration as a result
 */
interface BuildGraphContext {
    seriesVisibility: [];
    populatedGraphGroups: number[][];
    axisIncludedEntities: Map<GraphYAxisConfiguration, number[]>;
    shownDateRange: number[];
    customRanges: ManualScale[];

    chartOptions: GraphChartConfiguration;
}


const SERIES_COLUMN_DATATYPE = [DataType.Rain, DataType.RainIntensity, DataType.Voltage];

interface HG_TRANSLATIONS {
    EVENTS: {
        TYPES: {
            GENERAL: string;
            RAIN_EVENT: string;
            HYDRAULIC_EVENT: string;
            SITE_CONDITION: string;
            WORK_ORDER: string;
            PUBLIC_EVENT: string;
        }
    }
}

/**
 *
 * Angular Singleton
 * IMPORTANT: IT SHOULD NOT CONTAIN ANY ATTRIBUTES, any states
 *
 * Based on config it does produce chart configuration that is not related to any chart solution.
 *
 * This class contains old hydrograph data logic, some things may be improved or removed.
 *
 */
@Injectable({
    providedIn: 'root'
  })
export class HydrographDataBuilder {
    private hgTranslations: HG_TRANSLATIONS;

    constructor(
        private hydroService: HydrographNEWService,
        private translate: TranslateService
    ) {
        this.translate.get('HYDROGRAPH_NEW').subscribe((res: HG_TRANSLATIONS) => {
            this.hgTranslations = res;
        });
    }

    public prepareData(config: HydrographBuildDataConfig): GraphChartConfiguration {
        const buildGraphContext: BuildGraphContext = {
            seriesVisibility: [],
            populatedGraphGroups: null,
            axisIncludedEntities: new Map(),
            shownDateRange: config.selectedDateRange,
            customRanges: [],
            chartOptions: {
                yAxis: [],
                series: [],
                graphGrouping: []
            }
        }

        this.computeChart(buildGraphContext, config);

        return buildGraphContext.chartOptions;
    }

    public computeChart(buildGraphContext: BuildGraphContext, config: HydrographBuildDataConfig) {
        if (!buildGraphContext.chartOptions || !config.seriesData || !config.seriesData.length) {
            return;
        }

        buildGraphContext.chartOptions.yAxis = [];
        buildGraphContext.axisIncludedEntities.clear();

        this.setXAxis(buildGraphContext);

        buildGraphContext.populatedGraphGroups = config.graphGrouping.filter((x) => x.length > 0);
        buildGraphContext.populatedGraphGroups.forEach((group, i) => {
            const yAxisArr = this.generateYAxesByGrouping(buildGraphContext, config, group, i)
            buildGraphContext.chartOptions.yAxis.push(...yAxisArr);
            buildGraphContext.chartOptions.graphGrouping[i] = yAxisArr;
        });

        this.convertSeriesToGraphSeries(buildGraphContext, config);
        this.handleAnnotations(buildGraphContext, config);
    }

    public setXAxis(buildGraphContext: BuildGraphContext, hardLimits?: boolean) {
        const min = buildGraphContext.shownDateRange[0];
        const max = buildGraphContext.shownDateRange[1];
        const xAxis = {
            max: max,
            min: min,
            ceiling: hardLimits ? max : undefined,
            floor: hardLimits ? min : undefined
        };

        buildGraphContext.chartOptions.xAxis = xAxis;
    }

    private convertSeriesToGraphSeries(buildGraphContext: BuildGraphContext, config: HydrographBuildDataConfig) {
        buildGraphContext.chartOptions.series = [];
        const markers = this.hydroService.getMarkerTypes();

        let minPointY = Number.MAX_SAFE_INTEGER;
        let maxPointY = Number.MIN_SAFE_INTEGER;

        let seriesData = [...config.seriesData];
        // filter confirmations
        seriesData = config.seriesData.filter(series => {
            if (series.entityId > 0 || series.entityId % 1 !== 0) {
                return true;
            }

            if (Math.abs(series.entityId) === RAW_VELOCITY_ENTITY && config.selectedConfirmationEntity !== ConfirmationEntitiesEnum.Peak) {
                return false;
            }

            if (Math.abs(series.entityId) === VELOCITY_ENTITY && config.selectedConfirmationEntity !== ConfirmationEntitiesEnum.Average) {
                return false;
            }

            return true;
        });

        seriesData.filter(item => item.originLocationId === undefined || item.originLocationId === config.locationId).forEach((series) => {
            const isConfirmationSerie = (series.entityId < 0 && isInteger(series.entityId));
            const isOriginalEditSerie = (series.entityId < 0 && !isInteger(series.entityId));

            // #37627 all edited data should be colored Pink except flagged data which should be colored Red
            if(isOriginalEditSerie && series.entityId) {
                const formatted = String(series.entityId).match(/\-\d+\.(\d+)/);
                if (formatted && Number(formatted[1]) === 2) {
                    series.color = RED_COLOR_HEX;
                } else {
                    series.color = EDIT_COLOR;
                }
            }

            if (!series.data || (config.showEditMenu() && series.entityId % 1 !== 0)) {
                // Don't compute series if no data is available
                // Also don't include series if it is 'original data' AND we're in edit mode
                return;
            }
            const formattedData: GraphDataConfiguration[] = [];
            const seriesColor = this.getSeriesColor(series);

            // Logic to flatten diagnal lines into flat lines like silt is done
            if (series.entityId === PRESSK_ENTITY) {
                const newSeriesData: { x: number; y: number }[] = [];
                series.data.forEach((point, index) => {
                    if (index > 0) {
                        // First push x value with previous y value
                        newSeriesData.push({ x: point.x, y: series.data[index - 1].y });
                    }
                    newSeriesData.push({ x: point.x, y: point.y });
                });
                series.data = newSeriesData;
            }

            let shape = undefined;
            // TODO: LC: BEFOREMERGE: This whole logic related to markerType and segmentColor most likely should be removed and cleaned up, together with this disable tslint
            // tslint:disable-next-line: cyclomatic-complexity
            series.data.forEach((point) => {
                let segmentColor = seriesColor;
                if (config.annotationOptions.showDataQuality) {
                    segmentColor = this.hydroService.getDataQualityColor(point.quality);
                }
                const isConfirmation = series.entityId < 0 && series.entityId % 1 === 0;
                let markerType;
                if (series.entityId < 0) {
                    // Use dataType of non-annotation equal series
                    const data = config.seriesData.find((x) => x.entityId === Math.round(series.entityId));
                    const dataType = series.entityId % 1 === 0 ? data.dataType : 4; // Type 4 will create circles for original data
                    if (isConfirmation && point.flagged) {
                        markerType = markers.flaggedAnnotation[dataType];
                    } else {
                        markerType = markers.annotation[dataType];
                        const formatted = String(series.entityId).match(/\-\d+\.(\d+)/);

                        if (series.entityId % 1 !== 0) {
                            markerType.fillColor = (formatted && Number(formatted[1]) === 2) ? RED_COLOR_HEX : EDIT_COLOR;
                        }
                    }

                    shape = series.entityId % 1 === 0 ? data.dataType : 4; // Type 4 will create circles for original data

                } else if ((point.interpolated || point.interpolationAccepted) && !point.flagged) {
                    markerType = markers.interpolated;
                    segmentColor = markers.interpolated.fillColor;
                } else if (config.showEditMenu() && point.correctedY !== undefined && point.correctedY !== null && !point.flagged) {
                    markerType = markers.corrected;
                } else {
                    if (point.flagged && series.dataType === DataType.Rain) {
                        segmentColor = markers.flagged.fillColor;
                    } else if (point.flagged) {
                        markerType = markers.flagged;
                    } else if ((series.dataType !== DataType.Quantity && point.snapped)
                        || (series.dataType === DataType.Rain && config.showEditMenu() && point.snapped)) {
                        markerType = markers.snapped;
                    } else if (point.selected) {
                        markerType = markers.selected;
                        segmentColor = markers.selected.fillColor;
                    } else if (config.showAllMarkers) {
                        markerType = markers.marked;
                    }
                }

                const yValue = point.correctedY !== undefined && point.correctedY !== null ? point.correctedY : point.y;
                if (yValue !== undefined && yValue !== null && point.x > buildGraphContext.shownDateRange[0] && point.x < buildGraphContext.shownDateRange[1]) {
                    if (yValue < minPointY) minPointY = yValue;
                    if (yValue > maxPointY) maxPointY = yValue;
                }

                // Display all points (edited, flagged) if user is on DE mode, if not, display all but flagged
                if (config.showEditMenu() || !point.flagged || (isConfirmation && point.flagged)) {
                    formattedData.push({
                        x: point.x,
                        y: yValue,
                        flagged: point.flagged,
                        edited: point.correctedY !== undefined && point.correctedY !== null,
                        segmentColor: segmentColor,
                        color: segmentColor,
                        quality: point.quality
                    });
                }
            });

            if(series.entityId < 0) {
                const data = config.seriesData.find((x) => x.entityId === Math.round(series.entityId));
                shape = series.entityId % 1 === 0 ? data.dataType : ChartPointShape.Circle; // Type will create circles for original data
            }

            // #37896 If additional series (show edits) does not have data, do not display them
            if(series.entityId > 0 || (formattedData && formattedData.length)) {
                const formattedSeries: GraphSerieConfiguration = {
                    id: series.entityId,

                    displayGroupId: series.displayGroupId,
                    name: series.entityName,
                    serieType: this.seriesTypeForDef(series),

                    color: seriesColor,
                    unitOfMeasure: series.unitOfMeasure,
                    data: formattedData,
                    precision: series.precision,
                    lid: series.lid,
                    shape: shape,
                    yAxis: Array.isArray(buildGraphContext.chartOptions.yAxis)
                        ? buildGraphContext.chartOptions.yAxis.findIndex((axis) =>
                            buildGraphContext.axisIncludedEntities.get(axis)?.includes(series.entityId),
                        )
                        : buildGraphContext.chartOptions.yAxis,

                    showInLegend: false,
                    hasTooltip: isConfirmationSerie || isOriginalEditSerie || (series.entityId > 0 || series.entityId % 1 !== 0),
                    hasLegend:
                        isOriginalEditSerie ||
                        ((series.entityId > 0 || series.entityId % 1 !== 0) && !config.annotationOptions.showDataQuality),
                    visible: series.entityName in buildGraphContext.seriesVisibility ? buildGraphContext.seriesVisibility[series.entityName] : true,
                };
                buildGraphContext.chartOptions.series.push(formattedSeries);
            }
        });

        this.createEventSeries(buildGraphContext, config, minPointY, maxPointY);
    }


    private createEventSeries(buildGraphContext: BuildGraphContext, config: HydrographBuildDataConfig, minPointY: number, maxPointY: number) {
        if (config.events) {
            const eventRangeSeries: GraphEventSerieConfiguration[] = [];
            for (const item in EventTypes) {
                if (isNaN(Number(item))) continue;
                const it = Number(item);
                let name = '';
                switch (it) {
                    default:
                    case EventTypes.General: name = this.hgTranslations.EVENTS.TYPES.GENERAL; break;
                    case EventTypes.Rain: name = this.hgTranslations.EVENTS.TYPES.RAIN_EVENT; break;
                    case EventTypes.Hydraulic: name = this.hgTranslations.EVENTS.TYPES.HYDRAULIC_EVENT; break;
                    case EventTypes.SiteCondition: name = this.hgTranslations.EVENTS.TYPES.SITE_CONDITION; break;
                    case EventTypes.WorkOrder: name = this.hgTranslations.EVENTS.TYPES.WORK_ORDER; break;
                    case EventTypes.CSO: name = this.hgTranslations.EVENTS.TYPES.PUBLIC_EVENT; break;
                }

                const eventTheme = this.hydroService.getEventsTheme(it);
                const color = eventTheme.color;
                const symbol = eventTheme.eventSymbol;

                eventRangeSeries[it] = {
                    name: name,
                    fillColor: color,
                    color: color,
                    eventSymbol: symbol,
                    showInLegend: false,
                    eventRange: true,
                    etype: it,
                    data: []
                }
            }

            const minPointOrZero = minPointY >= 0 ? 0 : minPointY - 0.5;

            config.events.forEach(e => {
                const dateOffset = new Date().getTimezoneOffset() * 60000;
                const from = new Date(e.start).getTime() - dateOffset;
                const to = new Date(e.end).getTime() - dateOffset;

                const timeDiff = to - from;

                const fromChartBounds = config.selectedDateRange[0] <= from
                    ? from
                    : config.selectedDateRange[0];
                const toChartBounds = config.selectedDateRange[1] >= to
                    ? to
                    : config.selectedDateRange[1];

                const ev = {
                    x: fromChartBounds,
                    y: minPointOrZero,
                    etype: e.etype,
                    edata: {
                        ev: e,
                        duration: e.duration
                    },
                }

                if (timeDiff > 2 * 60 * 60 * 1000) {
                    eventRangeSeries[e.etype].data.push({ guid: e.guid, start: from, x: fromChartBounds, xto: toChartBounds, low: minPointOrZero, high: maxPointY, description: e.desc, duration: e.duration });
                    eventRangeSeries[e.etype].data.push({ guid: e.guid, start: from, x: toChartBounds, low: minPointOrZero, high: maxPointY, description: e.desc, duration: e.duration });
                    eventRangeSeries[e.etype].data.push({ guid: e.guid, start: from, x: toChartBounds, low: null, high: null, description: e.desc, duration: e.duration });
                }
            });

            for (const ecs of eventRangeSeries) {
                if (ecs.data && ecs.data.length) {
                    if(!buildGraphContext.chartOptions.eventSeries) buildGraphContext.chartOptions.eventSeries = [];
                    buildGraphContext.chartOptions.eventSeries.push(ecs);
                }
            }
        }
    }

    public createCustomRanges(isCustomerMetric: boolean, seriesData: BasicSeriesData[], displayGroupScales: DisplayGroupScales[]): ManualScale[] {
        if (!seriesData) {
            return null;
        }

        const customRanges = _(seriesData)
            .filter((x) => !!x.data)
            .groupBy('axisName')
            .map((x, axisName) => ({
                name: axisName,
                displayGroupId: x[0].displayGroupId,
                entities: x.map((y) => y.entityId),
                min: null,
                max: null,
            }))
            .value();

        // Take any saved special options into account. Add rain if none exist
        // (can be updated in the future if more are required to be saved)
        // let displayGroupScales = this.usersService.userSettings.getValue().displayGroupScales;
        if (!displayGroupScales || displayGroupScales.length === 0) {
            const series = seriesData.find(x => x.displayGroupId === RAIN_DISPLAY_GROUP);
            displayGroupScales = [{
                displayGroupId: RAIN_DISPLAY_GROUP,
                autoScale: false,
                manualMax: isCustomerMetric ? MAX_RAIN_MM : MAX_RAIN_IN,
                manualMin: 0
            }];
        }
        displayGroupScales.forEach(x => {
            const affected: ManualScale = customRanges.find(y => y.displayGroupId === x.displayGroupId);
            if (affected) {
                if(x.manualMax === 0 && x.manualMin === 0)
                {
                    affected.min = null;
                    affected.max = null;
                }
                else
                {
                    affected.min = x.manualMin;
                    affected.max = x.manualMax;
                }

                affected.autoOpt = x.autoScale;
            }
        });

        return customRanges;
    }

    private handleAnnotations(buildGraphContext: BuildGraphContext, config: HydrographBuildDataConfig) {
        if (!config.annotationOptions) {
            return;
        }

        buildGraphContext.chartOptions.yAxis.forEach((axis) => {if(axis) axis.plotLines = []});
        Object.keys(config.annotationOptions).forEach((x) => {
            if (
                config.annotationOptions[x] &&
                (x === AnnotationStringType.ManholeDepth ||
                    x === AnnotationStringType.PipeHeight ||
                    x === AnnotationStringType.Silt ||
                    x === AnnotationStringType.HighLevel ||
                    x === AnnotationStringType.HighHigh ||
                    x === AnnotationStringType.HighFlow ||
                    x === AnnotationStringType.LowDepth
                )
            ) {
                this.createAnnotationSerie(buildGraphContext, config, AnnotationType[x.charAt(0).toUpperCase() + x.slice(1)]);
            }
        });
    }

    private createAnnotationSerie(buildGraphContext: BuildGraphContext, config: HydrographBuildDataConfig, annot: AnnotationType) {
        if (!buildGraphContext.chartOptions || !buildGraphContext.chartOptions.series) {
            return;
        }

        const seriesNeedingLines: BasicSeriesData[] = [];
        config.seriesData.forEach((x) => {
            // #38811 If it is Pipe height that we also check for replacements
            const isPipeHeight = annot === AnnotationType.PipeHeight;

            if (x.annotations.filter((y) => isPipeHeight ? AnnotationPipeHeights.includes(y.id) : y.id === annot).length > 0) {
                seriesNeedingLines.push(x);
            }
        });

        seriesNeedingLines.forEach((x) => {
            const yAxisId = buildGraphContext.chartOptions.series.find((y) => +y.id === x.entityId).yAxis;

            // #38811 If it is Pipe height that we also check for replacements
            const isPipeHeight = annot === AnnotationType.PipeHeight;
            const annotation = x.annotations.find((y) => isPipeHeight ? AnnotationPipeHeights.includes(y.id) : y.id === annot);

            // #37309 Annotation series are always displayed in tooltip
            const annotationLine: GraphAnnotationSerieConfiguration = {
                name: annotation.name,
                color: HG_COLOR_PIPE_HEIGHT,
                yAxis: yAxisId,
                precision: annotation.precision,
                unitOfMeasure: annotation.unitOfMeasure,
                hasTooltip: true,
                dashStyle: ChartDashStyle.Dash,
                data: [],
                displayGroupId: x.displayGroupId
            };
            if (annot === AnnotationType.Silt) {
                annotationLine.color = HG_COLOR_SILT;
                annotationLine.dashStyle = ChartDashStyle.Dash;
            }
    
            let minX = Infinity;
            let maxX = -Infinity;
        
            // Find minX and maxX in one pass
            config.seriesData.forEach(sd => {
                sd.data.forEach(point => {
                    if (point.x < minX) minX = point.x;
                    if (point.x > maxX) maxX = point.x;
                });
            });
        
            // Add the starting point of the annotation line
            annotationLine.data.push({ x: minX, y: annotation.data[0].y });
    
            annotation.data.forEach((point, index) => {
                // Force flat lines between each x value
                // So you have horizontal lines with vertical jumps at times things changed
                // Rather than diagonal lines across the graph

                if (index > 0) {
                    // First push x value with previous y value
                    annotationLine.data.push({ x: point.x, y: annotation.data[index - 1].y });
                }

                annotationLine.data.push({ x: point.x, y: point.y });
            });

            // Add the ending point of the annotation line
            annotationLine.data.push({ x: maxX, y: annotation.data[annotation.data.length - 1].y });
    
            // Fix for 21437, remove extra label if same series with exact same data already plotted
            const sameSeries = buildGraphContext.chartOptions.series.find((v) => v.name === annotationLine.name);
            if (
                sameSeries &&
                sameSeries.data.length === annotationLine.data.length &&
                sameSeries.data.every((v, i) => annotationLine.data[i].x === v.x && annotationLine.data[i].y === v.y)
            ) {
                annotationLine.name = '';
            }

            if (!buildGraphContext.chartOptions.annotationSeries) buildGraphContext.chartOptions.annotationSeries = [];

            buildGraphContext.chartOptions.annotationSeries.push(annotationLine);
        });

        if (buildGraphContext.chartOptions.annotationSeries) {
            // #38587, if several Depth group entities selected, API will return annotations for all, need to remove duplicates
            const nameToIndexMap: Map<string, number> = buildGraphContext.chartOptions.annotationSeries.reduce((acc: Map<string, number>, curr, index) => acc.set(curr.name, index), new Map());

            buildGraphContext.chartOptions.annotationSeries = buildGraphContext.chartOptions.annotationSeries.filter((item, i) => i === nameToIndexMap.get(item.name));
        }
    }


    private getSeriesColor(series: BasicSeriesData) {
        if (series.lid !== undefined) return series.color;

        if (series.dataType === DataType.Rain) {
            // TODO: Handle this case
            // && this.overrideRainColor) {
            return RAIN_HEXCODE_UNFLAGGED;
        }

        if (series.entityId % 1 === 0) {
            return series.color;
        }

        const formatted = String(series.entityId).match(/\-\d+\.(\d+)/);

        if (formatted && Number(formatted[1]) === 2) {
            return RED_COLOR_HEX;
        }

        return EDIT_COLOR;
    }

    private generateYAxesByGrouping(buildGraphContext: BuildGraphContext, config: HydrographBuildDataConfig, group: number[], chartIndex: number) {
        const includedSeries = config.seriesData.filter((x) => group.includes(Math.abs(Math.round(x.entityId))));

        let allGroupingUnits = [];

        // Unique array of units to be shown on this grouping
        includedSeries.forEach((series) => allGroupingUnits.push(series.unitOfMeasure));

        allGroupingUnits = Array.from(new Set(allGroupingUnits));

        const yAxisArr = [];
        // Actually put together each Y axis for the chart
        for (let i = 0; i < allGroupingUnits.length; i++) {
            const axisSeries = includedSeries.filter((x) => x.unitOfMeasure === allGroupingUnits[i]);
            const extents = this.calculateYAxisRange(buildGraphContext, config.annotationOptions, axisSeries);
            const includedEntities = axisSeries.map((series) => series.entityId);

            let yAxis = {
                title: '',
                chartIndex: chartIndex
            };

            if (!axisSeries || !extents || !includedEntities || !axisSeries[0].data) {
                yAxis = { ...yAxis, title: axisSeries[0].axisName };
            } else {
                yAxis = {
                    ...yAxis,
                    title: axisSeries[0].axisName + ' (' + axisSeries[0].unitOfMeasure + ')'
                };
            }

            yAxisArr.push(yAxis);
            buildGraphContext.axisIncludedEntities.set(yAxis, includedEntities);
        }

        return yAxisArr;
    }

    private calculateYAxisRange(buildGraphContext: BuildGraphContext, annotationOptions: AnnotationOptions, includedSeries: BasicSeriesData[]) {
        if (!includedSeries || includedSeries.length === 0 || !includedSeries[0].data) {
            return { min: 0, max: 0 };
        }

        if (!includedSeries[0].data.find((val) => val.y != null || val.correctedY != null)) {
            // If there is a series, even if no values are found, Y axis still needs to be present
            // to avoid highcharts error #18
            return { min: 0, max: 0 };
        }

        const startXVal = buildGraphContext.chartOptions.xAxis.floor || buildGraphContext.chartOptions.xAxis.min;
        const endXVal = buildGraphContext.chartOptions.xAxis.ceiling || buildGraphContext.chartOptions.xAxis.max;

        const extents: GraphExtents = { min: 0, max: 0 };
        includedSeries.forEach((series) => {
            // Only calculate ranges for included date values
            const inclData = series.data.filter((d) => d.x >= startXVal && d.x <= endXVal);
            extents.min = inclData.reduce(
                (min, p) =>
                    p.correctedY ? (p.correctedY < min ? p.correctedY : min) : p.y !== null && p.y < min ? p.y : min,
                extents.min,
            );
            extents.max = inclData.reduce(
                (max, p) =>
                    p.correctedY ? (p.correctedY > max ? p.correctedY : max) : p.y !== null && p.y > max ? p.y : max,
                extents.max,
            );

            // Adjust based on shown annotations
            series.annotations.forEach((annot) => {
                if (
                    (annot.id === AnnotationType.ManholeDepth && annotationOptions.manholeDepth) ||
                    (annot.id === AnnotationType.PipeHeight && annotationOptions.pipeHeight) ||
                    (annot.id === AnnotationType.Silt && annotationOptions.silt) ||
                    (annot.id === AnnotationType.HighHigh && annotationOptions.highHigh) ||
                    (annot.id === AnnotationType.HighLevel && annotationOptions.highLevel) ||
                    (annot.id === AnnotationType.LowDepth && annotationOptions.lowDepth)
                ) {
                    extents.min = annot.data.reduce((min, p) => (p.y !== null && p.y < min ? p.y : min), extents.min);
                    extents.max = annot.data.reduce((max, p) => (p.y !== null && p.y > max ? p.y : max), extents.max);
                }
            });

            // Shift calculated points by 5% to avoid top and bottom of axis
            extents.min > 0 ? (extents.min = extents.min * 0.95) : (extents.min = extents.min * 1.05);
            extents.max > 0 ? (extents.max = extents.max * 1.05) : (extents.max = extents.min * 0.95);

            if (extents.max - extents.min > 1) {
                // If spread is greater than 1, round to nice whole numbers
                extents.min = Math.floor(extents.min);
                extents.max = Math.ceil(extents.max);
            } else {
                // Otherwise round to 2 decimal places for min and max
                extents.min = Math.floor(extents.min * 100) / 100;
                extents.max = Math.ceil(extents.max * 100) / 100;
            }
        });
        return extents;
    }

    public seriesTypeForDef(seriesDef: BasicSeriesData): ChartSerieType {
        return this.seriesTypeFor(seriesDef.entityId, seriesDef.dataType);
    }

    public seriesTypeFor(entityId: number, dataType: DataType): ChartSerieType {
        if(entityId < 0)
            return isInteger(entityId) ? ChartSerieType.ScatterConfirmation : ChartSerieType.ScatterOiriginalEdits;

        return SERIES_COLUMN_DATATYPE.includes(dataType)
            ? ChartSerieType.Column
            : ChartSerieType.Line;
    }
}
