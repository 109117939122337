import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomOperationUtilsService } from '../shared/utils/dom-operation-utils.service';
import { Router } from '@angular/router';
import { LocationDashboardService } from 'app/shared/services/location-dashboard.service';
import { ScheduleCollectionService } from 'app/shared/services/schedule-collection.service';
import { WIDGET_PARAM } from 'app/shared/models/customer';
import { retry } from 'rxjs/operators';

@Component({
    selector: 'app-page-hint',
    templateUrl: './page-hint.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
})
export class PageHintComponent implements OnInit {
    public showHintOverlay: boolean;

    public nextPreviousPage: boolean;
    public hintClass: string;
    public hintPageName: string;

    public scrolled: boolean;

    public widgetOptions = WIDGET_PARAM;

    constructor(
        public domOperationUtilsService: DomOperationUtilsService,
        private router: Router,
        public locationDashboardService: LocationDashboardService,
        public scheduleCollectionService: ScheduleCollectionService,
    ) {
        this.router.events.subscribe((event) => {
            // Empty Block
        });
    }

    public ngOnInit() {
        // subscribe on page hint Action
        this.domOperationUtilsService.showpageHint.subscribe((result: boolean) => {
            this.showHintOverlay = result;
        });
        this.domOperationUtilsService.selectedHintPageName.subscribe((result: string) => {
            this.hintClass = result;
            this.hintPageName = this.domOperationUtilsService.hintPageName;
        });
    }
    public hideHintOverlay() {
        this.domOperationUtilsService.selectedHintPageName.next('noHintclass');
        this.domOperationUtilsService.updateHintPageHeight('#hintOverlay', 'hide');
        this.domOperationUtilsService.resetHintPageName();
    }

    public vaultOverlayChange(isNext: boolean) {
        const current = this.domOperationUtilsService.vaultOverlayNumber.getValue()
        this.domOperationUtilsService.vaultOverlayNumber.next(isNext ? (current + 1) : (current - 1));
    }

    public onLDoverlayChange(isNext: boolean) {
        if (isNext) {
            this.domOperationUtilsService.selectedHintPageName.next('locationDashboardSecondHint');
            this.domOperationUtilsService.locationDashboardSecondHint();
        } else {
            this.domOperationUtilsService.locationDashBoardHint();
            this.domOperationUtilsService.selectedHintPageName.next('locationDashboardHint');
        }
    }

    public scrollDataComm(dir) {
        this.scrolled = dir;
        this.domOperationUtilsService.scrollDataComm(dir);
    }

    get isScrollable() {
        return this.domOperationUtilsService.isScrollable();
    }

    public homeDashboardChangeOverlay(changeValue: -1 | 1) {
        const currentTab = this.domOperationUtilsService.selectedGisTab.getValue();
        const isWidgetToggled = this.domOperationUtilsService.isHomePageWidgetToggled;
        const firstTabHint = this.domOperationUtilsService.firstTabHint;
        const selectedWidget = this.domOperationUtilsService.homePageSelectedWidget;

        let newTabValue = currentTab + changeValue;
        if (currentTab === 0 && changeValue === 1 && isWidgetToggled && firstTabHint === true &&
            (selectedWidget !== WIDGET_PARAM.EVENT && selectedWidget !== WIDGET_PARAM.BLOCKAGE_PREDICT && selectedWidget !== WIDGET_PARAM.ALL)) {

            // If you're on the first tab and click next
            // Need a way to show widget hints before changing tabs
            newTabValue = currentTab;
            this.domOperationUtilsService.firstTabHint = false;
        } else if (currentTab === 0 && changeValue === -1 && isWidgetToggled && firstTabHint === false) {
            newTabValue = currentTab;
            this.domOperationUtilsService.firstTabHint = true;
        } else {
            this.domOperationUtilsService.homeDashboardChangeOverlay.next(changeValue);
        }

        if (newTabValue > 3) {
            newTabValue = 0;
            this.domOperationUtilsService.firstTabHint = true;
        } else if (newTabValue < 0) {
            newTabValue = 3;
            this.domOperationUtilsService.firstTabHint = false;
        }

        this.domOperationUtilsService.pickHomepageHintOverlay(newTabValue);
        this.domOperationUtilsService.homeDashboardTabChangeComplete.next(null);
        this.domOperationUtilsService.selectedHintPageName.next(
            this.domOperationUtilsService.homePageTabOverlays[newTabValue],
        );
    }

    public previousHintOverlay() {
        this.domOperationUtilsService.selectedHintPageName.next('homePageIconHint');
    }
    public nextHintOverlay() {
        this.domOperationUtilsService.selectedHintPageName.next('homePageTileHint');
    }

    public deOverlayChange(isNext: boolean) {
        const currentPage = this.domOperationUtilsService.selectedHintPageName.getValue();

        if (currentPage === 'location-dashBoard-edit-hint' || currentPage === 'de-dialog-hint') {
            this.domOperationUtilsService.openDEDialog.next(false);
            this.domOperationUtilsService.selectedHintPageName.next('location-dashBoard-edit-sg-hint');
            this.domOperationUtilsService.locationDashBoardEditScattergraphHint();
        } else if (isNext) {
            this.domOperationUtilsService.openDEDialog.next(true);
            this.domOperationUtilsService.selectedHintPageName.next('de-dialog-hint');
            this.domOperationUtilsService.locationDashboardAcceptEditdialog();
        } else {
            this.domOperationUtilsService.openDEDialog.next(false);
            this.domOperationUtilsService.selectedHintPageName.next('location-dashBoard-edit-hint');
            this.domOperationUtilsService.locationDashBoardEditHint();
        }
    }
    public nextLocationDashboardDetailHintOverlay(isNext: boolean) {
        this.domOperationUtilsService.locationDashboardDetailHintOverlay(isNext);
    }

    public editUserTabChange(changeValue: -1 | 1) {
        this.domOperationUtilsService.editUserSelectedTab =
            this.domOperationUtilsService.editUserSelectedTab + changeValue;
        this.domOperationUtilsService.pickEditUserHint();
    }

    public customDashboardHintChange(changeValue: -1 | 1) {
        const graphType = this.domOperationUtilsService.customDashboardDialogGraphType.getValue();
        if (this.hintClass === 'custom-dashboard-hint') {
            this.domOperationUtilsService.openCustomDashboardDialog.next(true);

            this.domOperationUtilsService.selectedHintPageName.next('custom-dashboard-dialog-hint');
            this.domOperationUtilsService.customDashboardDialog();
        } else if (graphType === '0' && changeValue === -1) {
            this.domOperationUtilsService.openCustomDashboardDialog.next(false);

            this.domOperationUtilsService.selectedHintPageName.next('custom-dashboard-hint');
            this.domOperationUtilsService.customDashboard();
        } else if (graphType === '0' && changeValue === 1) {
            this.domOperationUtilsService.customDashboardDialogGraphType.next('1');
        } else {
            this.domOperationUtilsService.customDashboardDialogGraphType.next('0');
        }
    }

    public editCustomerLocationOverlayChange(isNext: boolean) {
        if (isNext) {
            this.domOperationUtilsService.locationFiltersOn.next(true);
            this.domOperationUtilsService.selectedHintPageName.next('edit-cus-locaitons-filters');
            this.domOperationUtilsService.editCusLocationsFilters();
        } else {
            this.domOperationUtilsService.locationFiltersOn.next(false);
            this.domOperationUtilsService.selectedHintPageName.next('edit-cus-locations');
            this.domOperationUtilsService.editCusLocations();
        }
    }

    public editCustomerFTPChange(isNext: boolean) {
        if (isNext) {
            this.domOperationUtilsService.ftpDialogOpened.next(true);
            this.domOperationUtilsService.selectedHintPageName.next('edit-cus-ftp-add');
            this.domOperationUtilsService.editCusFtpAdd();
        } else {
            this.domOperationUtilsService.ftpDialogOpened.next(false);
            this.domOperationUtilsService.selectedHintPageName.next('edit-cus-ftp');
            this.domOperationUtilsService.editCusFTP();
        }
    }

    public editCustomerGISchange(isNext: boolean) {
        const { gisDialogOpened, gisDialogAuthMethod, selectedHintPageName } = this.domOperationUtilsService;

        if (!gisDialogOpened.getValue() || (!isNext && gisDialogAuthMethod.getValue() === 'custom')) {
            gisDialogOpened.next(true);
            gisDialogAuthMethod.next('basic');
            selectedHintPageName.next('edit-cus-gis-basic');
            this.domOperationUtilsService.editCusGISbasic();
        } else if (isNext) {
            gisDialogAuthMethod.next('custom');
            selectedHintPageName.next('edit-cus-gis-custom');
            this.domOperationUtilsService.editCusGIScustom();
        } else {
            gisDialogOpened.next(false);
            selectedHintPageName.next('edit-cus-gis');
            this.domOperationUtilsService.editCusGIS();
        }
    }

    public editCustomerRainfallChange(changeValue: -1 | 1) {
        const { rainfallTabIndex, ddfTabIndex } = this.domOperationUtilsService;
        const mainTab = rainfallTabIndex.getValue();
        const ddfTab = ddfTabIndex.getValue();

        if (mainTab === 3 && changeValue === 1) {
            ddfTabIndex.next(1);
        } else if (mainTab === 3 && ddfTab === 1 && changeValue === -1) {
            ddfTabIndex.next(0);
        } else {
            rainfallTabIndex.next(mainTab + changeValue);
        }

        this.domOperationUtilsService.pickCustomerEditorOverlay();
    }
}
