import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ConfigTemplate, GetConfigTemplate } from '../models/data-edit';
import { SavedConfiguration } from '../models/view-data-filter';
import { Config } from './config';

export interface IdConfig {
    id: number;
}

@Injectable({ providedIn: 'root' })
export class ConfigService {
    public loadLocationId = new BehaviorSubject(0);

    private cachedDashboardConfig?: {
        customerId: number;
        response: SavedConfiguration[];
    }

    private cachedMruSettings?: {
        customerId: number,
        mru?: any[],
        subscription?: Subject<any>
    }

    constructor(public http: HttpClient) {}

    public getMostRecent(customerId, forceReload = true) {
        if(!forceReload && this.cachedMruSettings && this.cachedMruSettings.customerId === customerId) {
            if(this.cachedMruSettings.mru) {
                return of(this.cachedMruSettings.mru);
            } else {
                return this.cachedMruSettings.subscription;
            }
        } else {
            this.cachedMruSettings = {
                customerId: customerId,
                subscription: new Subject()
            };

            return this.http.get<SavedConfiguration[]>(Config.urls.LocationConfigMru + `/${customerId}?count=10`).pipe(
                tap(mru => {
                    if(!this.cachedMruSettings) {
                        this.cachedMruSettings = {
                            customerId: customerId,
                            mru: mru
                        };
                    } else {
                        this.cachedMruSettings.mru = mru;
                        if(this.cachedMruSettings.subscription) {
                            this.cachedMruSettings.subscription.next(mru);
                        }
                    }
                })
            );
        }
    }

    public clearCachedMruSettings() {
        this.cachedMruSettings = null;
    }

    public updateMostRecent(customerId, id) {
        this.clearCachedMruSettings();
        return this.http.post(Config.urls.LocationConfigMru + `/${customerId}/${id}`, null).pipe(
            map((res) => {
                return res;
            }),
        );
    }

    public deleteDashboardConfig(customerId, id) {
        this.clearCachedDashboardConfig();
        return this.http.delete(Config.urls.LocationConfig + `/${customerId}/${id}`).pipe(
            map((res) => {
                return res;
            }),
        );
    }

    public clearCachedDashboardConfig() {
        this.cachedDashboardConfig = null;
    }

    public getDashboardConfig(customerId: number) {
        if(this.cachedDashboardConfig && this.cachedDashboardConfig.customerId === customerId) {
            return of(this.cachedDashboardConfig.response);
        }

        return this.http.get<SavedConfiguration[]>(Config.urls.LocationConfig + `/${customerId}?count=25000`).pipe(
            tap(res => {
                this.cachedDashboardConfig = {
                    customerId: customerId,
                    response: res
                }
            })
        );
    }

    public updateDashboardConfig(config) {
        this.clearCachedDashboardConfig();
        return this.http.post(Config.urls.LocationConfig, config).pipe(
            map((res) => {
                return res;
            }),
        );
    }

    public saveConfig<T>(config: ConfigTemplate<T>, customerId: number) {
        return this.http.post(Config.getUrl(Config.urls.configs) + customerId, config);
    }

    public getAllConfigs<T>(customerId: number, configType: 'be' | 'fmr', partitionSuffixFilter: string) {
        return this.http.get<GetConfigTemplate<T>[]>(Config.getUrl(Config.urls.configs) + `${customerId}/${configType}/${partitionSuffixFilter}`);
    }

    public deleteConfig(customerId: number, configType: 'be' | 'fmr', partitionSuffixFilter: string, configId: string) {
        return this.http.delete(Config.getUrl(Config.urls.configs) + `${customerId}/${configType}/${partitionSuffixFilter}/${configId}`)
    }

    public getRecentConfigs<T>(customerId: number, configType: 'be' | 'fmr', partitionSuffixFilter: string) {
        return this.http.get(Config.getUrl(Config.urls.configs) + `${customerId}/${configType}/${partitionSuffixFilter}/recent`);
    }

    public onRecentConfigLoaded(customerId: number, configType: 'be' | 'fmr', partitionSuffixFilter: string, configId: string) {
        return this.http.post(Config.getUrl(Config.urls.configs) + `${customerId}/${configType}/${partitionSuffixFilter}/recent/${configId}`, {})
    }
}
