<div id="generateApiKeyDialog">
    <div class="popupHeader" mat-dialog-title>
        <span>{{ showKeyDetailSection ? 'API KEY CREATED' : 'CREATE API KEY' }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <div mat-dialog-content>
        <app-loader [isLoading]="isLoading"></app-loader>
        <div [hidden]="showKeyDetailSection">
            <span class="danger rangeError" *ngIf="invalidDesc">{{
                'ADMIN.GENERATE_API_KEY.DESC_ERROR' | translate
            }}</span>

            <mat-form-field class="full-width top">
                <input
                    matInput
                    placeholder="{{ 'ADMIN.DESCRIPTION_TEXT' | translate }}"
                    [(ngModel)]="description"
                    required
                    maxlength="99"
                />
            </mat-form-field>
            <mat-form-field class="full-width">
                <mat-select
                    placeholder="{{ 'ADMIN.GENERATE_API_KEY.EXPIRATION_TEXT' | translate }}"
                    name="dateExperation"
                    [(ngModel)]="dateExperation"
                    class="full-width"
                >
                    <mat-option *ngFor="let range of dateExperationList" [value]="range.value">{{
                        range.text
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div [hidden]="!showKeyDetailSection">
            <mat-form-field class="full-width">
                <input class="share-key" matInput value="{{ apiKeyNew }}" readonly />
                <mat-icon matSuffix (click)="copyApiKey($event)">content_copy</mat-icon>
                <mat-hint align="end" *ngIf="!(apiKeyNew == undefined)">{{
                    isValueCopied ? 'Copied' : 'Copy'
                }}</mat-hint>
            </mat-form-field>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="emitGeneateApiKey()">{{ showKeyDetailSection ? 'Done' : 'cancel' }}</button>
        </div>
        <div class="auto-margin" [hidden]="showKeyDetailSection">
            <button mat-button (click)="geneateApiKey()">{{ 'COMMON.CREATE_BTN' | translate }}</button>
        </div>
    </div>
</div>
