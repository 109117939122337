export class AddLocationFlume {
    public range: number;
    public capacity: number;
    public width: number;
    public installationID: number;
    public installationType: string;
    public shapeType: string;
    public installationDesc1: string;
    public installationDesc2: string;
    public installationDesc3: string;
    public installationDesc4: string;
    public installationDesc5: string;
    public lookupDesc: string;
    public MonitoringPoint: number;
    public wasCreatedFromImport: boolean;

    constructor() {
        this.installationID = 0;
        this.installationType = 'Flume';
        this.shapeType = 'Parshall1Inch';
        this.installationDesc1 = '';
        this.installationDesc2 = '';
        this.installationDesc3 = '';
        this.installationDesc4 = '';
        this.installationDesc5 = '';
        this.lookupDesc = 'Flume';
        this.wasCreatedFromImport = false;
    }
}
