import { Injectable, EventEmitter } from '@angular/core';
import { Config } from 'app/shared/services/config';
import { Observable, of, throwError } from 'rxjs';
import {
    MonitorInstallationMap,
    LocationDetails,
    FlowBalanceDetails,
    INSTALLATION_TYPE,
    SlimLocationDetails,
    InstallationType,
    MonitorSeriesUI,
    LocationCosmosDetails,
    CumulativeRainResponse,
    CumulativeRainRequest,
} from '../models/location-details';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { CacheService } from './cache.service';
import { EMPTY, BehaviorSubject } from 'rxjs';
import {
    AddLocation,
    ChannelLocationUpdate,
    ElevationLocationUpdate,
    FlumeLocationUpdate,
    LocationUpdate,
    PipeLocationUpdate,
    RainLocationUpdate,
    WeirLocationUpdate,
} from '../models/add-location';
import { SelectItem } from '../models/selected-Item';
import { LocationEntitiesData, LocationListArgs } from '../models/locations-entities-data';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { LocationNote } from 'app/shared/models/location-notes';
import { MonitorConfigurationReport } from '../models/monitorConfiguration';

@Injectable()
export class LocationService {
    public locationsChange: EventEmitter<number[]> = new EventEmitter<number[]>();
    public locationChange: EventEmitter<number> = new EventEmitter<number>();
    private _locationId: number;
    private _locationIDs: number[];
    public disableHeaderButtons$ = new BehaviorSubject<boolean>(false);
    public updateMonitorLayerSource = new BehaviorSubject(null);
    private isNotesDialogOpen = false;

    constructor(public http: HttpClient, public cacheService: CacheService) {}

    // getter and setter for location Id
    public set locationId(locationId: number) {
        this._locationId = locationId;
        this.locationChange.emit(locationId);
    }

    public get locationId(): number {
        return this._locationId;
    }

    public set locationIDs(locationIDs: number[]) {
        this._locationIDs = locationIDs;
        this.locationsChange.emit(locationIDs);
    }

    public get locationIDs(): number[] {
        return this._locationIDs;
    }

    /**
     * Get the list of locations with default entity and device information
     * @param params The arguments for the service.
     */
    public getLocationData(params: LocationListArgs): Observable<LocationEntitiesData> {
        // ensure args
        if (!params) {
            return EMPTY;
        }

        let httpParams: HttpParams = new HttpParams();

        // setting all the existing key value of param to HttpParams
        Object.keys(params).forEach((key) => (httpParams = httpParams.append(key, params[key])));

        return this.http
            .get<LocationEntitiesData>(`${Config.serviceUrl}${Config.urls.entitiesAndLocations}`, {
                params: httpParams,
            });
    }

    public addLocation(customerId: number, addLocation: AddLocation, installationType?: string) {
        let serviceUrl;
        if (installationType === INSTALLATION_TYPE.PIPE) {
            serviceUrl = Config.urls.apiInstallationType.pipe;
        } else if (installationType === INSTALLATION_TYPE.RAIN) {
            serviceUrl = Config.urls.apiInstallationType.rain;
        } else if (installationType === INSTALLATION_TYPE.FLUME) {
            serviceUrl = Config.urls.apiInstallationType.flume;
        } else if (installationType === INSTALLATION_TYPE.WEIR) {
            serviceUrl = Config.urls.apiInstallationType.weir;
        } else if (installationType === INSTALLATION_TYPE.CHANNEL) {
            serviceUrl = Config.urls.apiInstallationType.channel;
        } else if (installationType === INSTALLATION_TYPE.ELEVATION) {
            serviceUrl = Config.urls.apiInstallationType.elevation;
        } else {
            serviceUrl = Config.urls.apiInstallationType.other;
        }
        const res = this.http.post<number>(Config.serviceUrl + serviceUrl + `${customerId}`, addLocation);
        catchError((error) => {
            console.dir(error.json());
            return res;
        });
        this.cacheService.removeFromCache(Config.urls.locationsList);
        return res;
    }

    public updatePipeLocation(customerId: number, location: PipeLocationUpdate) {
        // creating HttpParams for HttpClient request
        let httpParams: HttpParams = new HttpParams();

        httpParams = httpParams.append('customerId', customerId.toString());

        this.cacheService.removeFromCache(Config.urls.locationsList);

        return this.http.put<AddLocation>(Config.urls.locationPipe, location, { params: httpParams });
    }

    public updateChannelLocation(customerId: number, location: ChannelLocationUpdate) {
        // creating HttpParams for HttpClient request
        let httpParams: HttpParams = new HttpParams();

        httpParams = httpParams.append('customerId', customerId.toString());

        this.cacheService.removeFromCache(Config.urls.locationsList);

        return this.http.put<LocationUpdate>(Config.urls.locationChannel, location, { params: httpParams });
    }

    public updateElevationLocation(customerId: number, location: ElevationLocationUpdate) {
        // creating HttpParams for HttpClient request
        let httpParams: HttpParams = new HttpParams();

        httpParams = httpParams.append('customerId', customerId.toString());

        this.cacheService.removeFromCache(Config.urls.locationsList);

        return this.http.put<LocationUpdate>(Config.urls.locationElevation, location, { params: httpParams });
    }

    public updatedRainLocation(customerId: number, location: RainLocationUpdate) {
        // creating HttpParams for HttpClient request
        let httpParams: HttpParams = new HttpParams();

        httpParams = httpParams.append('customerId', customerId.toString());

        return this.http
            .put<LocationUpdate>(Config.urls.locationRain, location, {
                params: httpParams,
                headers: new HttpHeaders({ type: 'application/json' }),
                observe: 'response',
            })
            .pipe(
                map((response) => {
                    if (response.status !== 200) {
                        throw new Error('This request has failed ' + response.status);
                    } else {
                        return response;
                    }
                }),
            )
            .pipe(catchError(this.handleError));
    }

    private handleError(error: any) {
        return throwError(error.message);
    }

    public updatedFlumeLocation(customerId: number, location: FlumeLocationUpdate) {
        // creating HttpParams for HttpClient request
        let httpParams: HttpParams = new HttpParams();

        httpParams = httpParams.append('customerId', customerId.toString());

        this.cacheService.removeFromCache(Config.urls.locationsList);

        return this.http.put<LocationUpdate>(Config.urls.locationFlume, location, { params: httpParams });
    }

    public updatedWeirLocation(customerId: number, location: WeirLocationUpdate) {
        // creating HttpParams for HttpClient request
        let httpParams: HttpParams = new HttpParams();

        httpParams = httpParams.append('customerId', customerId.toString());

        this.cacheService.removeFromCache(Config.urls.locationsList);

        return this.http.put<LocationUpdate>(Config.urls.locationWeir, location, { params: httpParams });
    }

    public containsLocation(location: SelectItem, selectedLocations: SelectItem[]) {
        for (let i = 0; i < selectedLocations.length; i++) {
            if (selectedLocations[i].id === location.id) {
                return true;
            }
        }
        return false;
    }

    /**
     * By default this retrieves a collection of installation types. If the monitor series identifier
     * is specified, it will retrieve the installation types available to that monitor series type
     * based on the mappings found in 'monitor-installation-type-mappings.json'.
     * @param monitorSeriesId optional: optional monitor series identifier
     */
    public getInstallationType(monitorSeriesId?: number): Observable<Array<InstallationType>> {
        // get installation types from data store
        const installationTypes = this.http.get<Array<InstallationType>>(Config.urls.installationType);

        // if no args passed in, get all
        if (!monitorSeriesId) {
            return installationTypes;
        }

        // get installation types
        return installationTypes.pipe(
            mergeMap((installs: Array<InstallationType>) => {
                // get installation to series mapping
                return (
                    this.http
                        .get<Array<MonitorInstallationMap>>(Config.urls.monitorInstallationMapping)

                        // get the mapping for the currently requested series id
                        .pipe(
                            map((mapping: Array<MonitorInstallationMap>) => {
                                const filteredMapping = mapping.find(
                                    (x: MonitorInstallationMap) => x.monitorSeriesId === monitorSeriesId,
                                );

                                // filtered installation types placeholder
                                const filteredInstalls = new Array<InstallationType>();

                                // grab only installations that match the series type
                                filteredMapping.installationTypeSeries.forEach((n) =>
                                    installs.filter((x) => x.series === n).forEach((x) => filteredInstalls.push(x)),
                                );

                                // return installation types
                                return filteredInstalls;
                            }),
                        )
                );
            }),
        );
    }

    /**
     * get monitor-series api.
     */
    public getMonitorSeries(): Observable<Array<MonitorSeriesUI>> {
        return this.http.get<Array<MonitorSeriesUI>>(Config.urls.monitorSeries);
    }
    /**
     * Method returns flow balance details for a particular location for a customer
     * @param customerID - customer ID
     * @param locationID - locationg ID
     */
    public getFlowBalance(customerID: number, locationID: number) {
        return this.http.get<FlowBalanceDetails[]>(
            `${Config.urls.flowBalanceDetails}?cid=${customerID}&lid=${locationID}`,
        );
    }
    /**
     * Method returns flow balance report for a particular location for a customer
     * @param customerID - customer ID
     * @param locationID - locationg ID
     */
    public getFlowBalanceReport(customerID: number, flowBalanceReportArgs) {
        return this.http.post<any>(`${Config.urls.flowBalanceReport}?customerId=${customerID}`, flowBalanceReportArgs);
    }
    /**
     * Method returns flow balance details for a particular location for a customer
     * @param customerID - customer ID
     * @param locationID - locationg ID
     */
    public getLocationConfigurationReport(
            customerID: number,
            locationGroupId?: number,
            includeInactiveLocations?: boolean,
            locationIds?: number[],
            pageSize?: number,
            pageIndex?: number
        ) {

        const params = {
            customerId: customerID,
            locationGroupId,
            locationIds,
            includeInactiveLocations,
            paging: {
                pageSize: pageSize,
                startPage: pageIndex
            }
        };

        return this.http.post<MonitorConfigurationReport>(Config.urls.locationConfigurationReport, params);
    }
    /**
     * Method returns location details for a particular location for a customer
     * @param customerID - customer ID
     * @param locationID - locationg ID
     */
    public getLocationDetails(customerID: number, locationID: number) {
        return this.http.get<LocationDetails>(`${Config.urls.locationDetails}/${customerID}/${locationID}`);
    }

    public getSlimLocations(customerID: number, includeInactive: boolean) {
        return this.http.get<SlimLocationDetails[]>(
            `${Config.urls.locationDetailsSlim}/?customerId=${customerID}&IncludeInactiveLocations=${includeInactive}`,
        );
    }
    // TODO: Think if merge this with getSlimLocations by inserting ttype parameter
    public getRainGaugesLocations(customerID: number, includeInactive: boolean) {
        return this.http.get<LocationDetails[]>(
            `${Config.urls.locationsList}/?CID=${customerID}&IncludeInactiveLocations=${includeInactive}&IType=RainGauge`,
        );
    }
    cachedLocationDetailsV2: {
        customerID: number;
        locationID: number;
        res: LocationDetails[];
    }
    public getLocationDetailsV2(customerID: number, locationID: number, reloadCache = true) {
        if(!reloadCache
            && this.cachedLocationDetailsV2
            && this.cachedLocationDetailsV2.customerID === customerID
            && this.cachedLocationDetailsV2.locationID === locationID
            ) {
                return of(this.cachedLocationDetailsV2.res);
        } else {
            return this.http.get<LocationDetails[]>(
                `${Config.urls.locationDetails}/v2?cid=${customerID}&lid=${locationID}`,
            ).pipe(
                tap((res) => {
                    this.cachedLocationDetailsV2 = {
                        customerID: customerID,
                        locationID: locationID,
                        res: res
                    }
                })
            )
        }
    }

    public getCustomPropNames(cid: number) {
        return this.http.get<string[]>(`${Config.urls.customPropNames}?cid=${cid}`)
    }

    public getMonitoringPoints(customerID: number, locationID: number) {
        return this.http.get<LocationDetails[]>(`${Config.urls.monitoringPoints}?cid=${customerID}&lid=${locationID}`);
    }

    public locationsExport(customerID: number, locationID: number): Observable<string> {
        return this.http.get<string>(`${Config.urls.locationsExport}?cid=${customerID}&lid=${locationID}`);
    }

    public locationCosmoDetails(customerID: number, locationID: number) {
        return this.http.get<LocationCosmosDetails>(`${Config.urls.LocationCosmoDetails}?cid=${customerID}&lid=${locationID}`);
    }

    public locationNotesGET(customerID: number, locationID: number) {
        return this.http.get<LocationNote[]>(`${Config.urls.locationNotes.get}?cid=${customerID}&lid=${locationID}`);
    }
    public locationNotesPOST(customerID: number, locationID: number, text: string) {
        return this.http.post(`${Config.urls.locationNotes.post}?cid=${customerID}&lid=${locationID}`, {details: text} );
    }
    public locationNotesPUT(customerID: number, locationID: number, noteID: string, text: string) {
        return this.http.post(`${Config.urls.locationNotes.put}?cid=${customerID}&lid=${locationID}&noteId=${noteID}`, {details: text});
    }
    public locationNotesDELETE(customerID: number, locationID: number, noteID: string) {
        return this.http.delete(`${Config.urls.locationNotes.delete}?cid=${customerID}&lid=${locationID}&noteId=${noteID}`);
    }

    public setOpenNotesDialog(): boolean {
        if(this.isNotesDialogOpen) return false;

        this.isNotesDialogOpen = true;
        return true;
    }

    public setCloseNotesDialog(): boolean {
        if(!this.isNotesDialogOpen) return false;

        this.isNotesDialogOpen = false;
        return true;
    }

    public rainCumulativeGet(req: CumulativeRainRequest) {
        return this.http.post<CumulativeRainResponse>(`${Config.urls.rainCumulative}`, req);
    }

}
