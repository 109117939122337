<div>
    <div class="sli-header">
        <h4 class="graph-heading">
            {{ 'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.RAINFALL_HYETOGRAPH' | translate }}:
            {{ startDateString }} - {{ endDateString }}
        </h4>
        <div class="spacer"></div>
        <div>
            <span class="pad-r-15">{{ 'SLIICER_TABLE.SLICER_SUMMARY.FLOW_MONITOR.EDIT_BLOCK_DAYS' | translate }}</span>
            <mat-slide-toggle (change)="editBlockDaysToggle($event)" [disabled]="isStudyLocked"></mat-slide-toggle>
        </div>
    </div>
    <div id="sli-hyetograph-container">
        <div id="sli-spinner" *ngIf="isLoadingState">
            <mat-progress-spinner diameter="25" mode="indeterminate" color="primary"></mat-progress-spinner>
        </div>
        <app-rainfall-graph
            #rainfallGraph
            [graphFlag]="rainFallsDown"
            [rainMonitorList]="rainfallMonitors"
            [stormEvents]="stormEvents"
            [currentBlockDayOverlays]="currentBlockDayOverlays"
            [start]="start"
            [end]="end"
            (addedOrRemovedBlockDays)="addedOrRemovedBlockDays($event)"
            (selectedMonitorChanged)="selectedMonitorChanged($event)"
        >
        </app-rainfall-graph>
        <div class="toggle-container">
            <div>
                <mat-checkbox
                    class="custom-chk-margin"
                    [checked]="rainfallGraph.showStormsValue"
                    (change)="showStormsToggle($event)"
                >
                    <span>{{ 'SLIICER.COMMON.SHOW_STORMS' | translate }}</span>
                </mat-checkbox>
                <mat-checkbox
                    class="pad-l-15 pad-r-15 custom-chk-margin"
                    [checked]="tooltipEnabled"
                    (change)="onTooltipEnabledChanged()"
                >
                    <span>{{ 'SLIICER.COMMON.SHOW_TOOLTIPS' | translate }}</span>
                </mat-checkbox>
            </div>
        </div>
    </div>
    <div class="sli-header">
        <h4 class="graph-heading">{{ 'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.BLOCK_DAYS' | translate }}</h4>
        <div class="spacer"></div>
        <div class="header-button">
            <span class="custom-btn-color" (click)="clearAll()" [class.disabled]="!currentBlockDays?.length || isStudyLocked"
                [class.disabled-link]="!currentBlockDays?.length || isStudyLocked"
                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.RAINFALL_DEFINITON.CLEAR_ALL' | translate }}
            </span>
        </div>
    </div>

    <app-rainfall-block-days
        #blockDaysTable
        [rainfallMonitorList]="rainfallMonitors"
        [currentBlockDays]="currentBlockDays"
        (removeBlockDate)="removeBlockDate($event)"
    >
    </app-rainfall-block-days>
</div>
