import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from 'app/shared/services/config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IUptimeOverviewReportArgs, UptimeDetailsReportArgs, UptimeReportItem } from 'app/shared/models/uptime';
import { map } from 'rxjs/operators';

@Injectable()
export class UptimeReportService {
    public uptimeOverviewData: Array<UptimeReportItem>;
    public uptimeOverviewCustomerID: number;
    public uptimeOverviewLocationGroupID: number;
    public uptimeOverviewInactiveLocation: boolean;

    public selectedEntities: number[] = [];
    constructor(private http: HttpClient) {}

    public getUptimeReport(
        customerId: number,
        sampleRate: number,
        locationGroupId?: number,
        includeInactiveLocations?: boolean,
        startDate?: Date,
        endDate?: Date,
        entityIds?: number[],
        locationsIds?: number[],
        pageSize?: number,
        pageIndex?: number,
    ): Observable<UptimeReportItem[]> {
        const params = <IUptimeOverviewReportArgs>{
            CustomerId: customerId,
            ReadingInterval: sampleRate,
            LocationGroupId: locationGroupId,
            IncludeInactiveLocations: includeInactiveLocations,
            Start: [startDate.getMonth() + 1, startDate.getDate(), startDate.getFullYear()].join('-'),
            End: [endDate.getMonth() + 1, endDate.getDate(), endDate.getFullYear()].join('-') + ' 23:59:59',
            PageSize: pageSize,
            StartPage: pageIndex,
        };

        // creating HttpParams for HttpClient request
        let httpParams: HttpParams = new HttpParams();

        // setting all the existing key value of param to HttpParams

        Object.keys(params).forEach(function (key) {
            if (params[key] !== undefined) {
                httpParams = httpParams.append(key, params[key]);
            }
        });

        entityIds.forEach((v) => {
            httpParams = httpParams.append('EntityIds', String(v));
        });
        if (locationsIds) {
            locationsIds.forEach((v) => {
                httpParams = httpParams.append('LocationIds', String(v));
            });
        }

        return this.http.get<UptimeReportItem[]>(Config.urls.uptime, { params: httpParams }).pipe(
            map((res) => {
                this.uptimeOverviewData = res;
                this.uptimeOverviewCustomerID = customerId;
                this.uptimeOverviewLocationGroupID = locationGroupId || 0;
                this.uptimeOverviewInactiveLocation = includeInactiveLocations || false;
                if (res) {
                    return res;
                } else {
                    return null;
                }
            }),
        );
    }

    public exportUptimeReport(
        customerId: number,
        sampleRate: number,
        locationGroupId?: number,
        includeInactiveLocations?: boolean,
        startDate?: Date,
        endDate?: Date,
        entityIds?: number[],
        locationIds?: number[]
    ): Observable<string> {
        return this.http.post<string>(Config.urls.uptimeExport, {
            customerId, 
            locationGroupId, 
            locationIds, 
            entityIds,
            start: [startDate.getMonth() + 1, startDate.getDate(), startDate.getFullYear()].join('-'),
            end: [endDate.getMonth() + 1, endDate.getDate(), endDate.getFullYear()].join('-') + ' 23:59:59',
            includeInactiveLocations,
            exportReport: true,
            readingInterval: sampleRate
        });
    }

    public getUptimeReportForLocation(params: UptimeDetailsReportArgs, entityIds: number[] = []): Observable<Array<Object>> {
        if (params.End) {
            params.End += ' 23:59:59';
        }
        // creating HttpParams for HttpClient request
        let httpParams: HttpParams = new HttpParams();

        // setting all the existing key value of param to HttpParams

        Object.keys(params).forEach(function (key) {
            if (params[key] !== undefined && key !== 'EntityIds') {
                httpParams = httpParams.append(key, params[key]);
            }
        });

        entityIds.forEach((v) => {
            httpParams = httpParams.append('EntityIds', String(v));
        });

        if (params.EntityIds && params.EntityIds.length > 0) {
            params.EntityIds.forEach((entity) => {
                httpParams = httpParams.append('EntityIds', entity.toString());
            });
        }

        return this.http.get<Object[]>(Config.urls.uptime, { params: httpParams }).pipe(
            map((res) => {
                if (res) {
                    return res;
                } else {
                    return null;
                }
            }),
        );
    }
}
