import { SliicerService } from 'app/shared/services/sliicer.service';
import { Component, Input, OnChanges, Output, EventEmitter, ChangeDetectorRef, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import * as moment from 'moment';
import { ChangeContext, LabelType, Options } from '@angular-slider/ngx-slider';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { AdjustStormPeriodsModel, StormEventPeriodsChanges } from './adjust-storm-event-periods.model';
import { StormPeriodAdjustment, StormPeriodsMinMax } from '../flow-monitor.model';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DateutilService } from 'app/shared/services/dateutil.service';

const EMPTY_TEXT = '';
enum STORM_LABELS {
    Precomp = 0,
    Storm = 1,
    Recovery1 = 2,
    Recovery2 = 3
}

// #31983 Visual Length of drawn label texts mapped to a timestamp range of periods
const STORM_LABELS_LENGTH = {
    Precomp: 600 * 60 * 1000,
    Storm: 320 * 60 * 1000,
    Recovery1: 450 * 60 * 1000,
    Recovery2: 450 * 60 * 1000
}

@Component({
    selector: 'ads-adjust-storm-event-periods',
    templateUrl: './adjust-storm-event-periods.component.html',
    styleUrls: ['./adjust-storm-event-periods.component.scss'],
})
export class AdsAdjustStormEventPeriodsComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('slidercontainer') sliderContainer: ElementRef<HTMLDivElement>;
    @Input() public xAxisMinMaxPoint: number[];
    @Input() public stormPeriods: StormPeriodAdjustment;
    @Input() public showTooltips: boolean;
    @Input() public hideSliders: boolean;
    @Output() public periodAdjustedEmitter = new EventEmitter<StormPeriodAdjustment>();

    public stormPeriodsMinMax: StormPeriodsMinMax;

    public isStudyLocked = true;

    /**
     * IMPORTANT !!!
     *
     * TODO: ngx-slider has issues with dynamic updates of options. Floor and ceil do not update
     * Currently only way to force redraw and refresh options is to use ngIf and set up isRefreshing flag
     */
    public isRefreshing = false;

    public model: AdjustStormPeriodsModel = {
        precompStart: 0,
        precompEnd: 0,
        stormStart: 0,
        recovery1Start: 0,
        recovery2Start: 0,
        recovery2End: 0,
    };
    public modelLast: AdjustStormPeriodsModel = this.model;

    // #27413 force ngslider to draw combined label
    private static longDotText = new Array(1024).join('.');

    private static staticOptions: Options  = {
        ceil: 10,
        floor: 0,
        showOuterSelectionBars: true,
        onlyBindHandles: true,
        noSwitching: true,
        hideLimitLabels: false,
        // #39925 Defining empty ticksArray to avoid freezing issue during slider handling
        ticksArray: [],
        // #27413 force ngslider to draw combined label
        translate: (value: number, label: LabelType): string => {
            return AdsAdjustStormEventPeriodsComponent.longDotText;
        }
    };

    public optionsPrecomp: Options = { ...AdsAdjustStormEventPeriodsComponent.staticOptions};
    public optionsStorm: Options = { ...AdsAdjustStormEventPeriodsComponent.staticOptions };
    public optionsRecovery1: Options = { ...AdsAdjustStormEventPeriodsComponent.staticOptions };
    public optionsRecovery2: Options = { ...AdsAdjustStormEventPeriodsComponent.staticOptions };

    public subscriptions: Array<Subscription> = [];

    public ngsliderCombineLabels(label: STORM_LABELS, text: string) {
        // #31983 - Default client width, we have to set it up because sliderContainer is not available from beginning
        let clientWidth = 1000;
        if(this.sliderContainer && this.sliderContainer.nativeElement) {
            clientWidth = this.sliderContainer.nativeElement.clientWidth;
        }
        const proportion = 1600 / clientWidth;


        // #31983 do not display labels if there is no space
        switch(label) {
            case STORM_LABELS.Precomp: if(this.model.stormStart - this.model.precompStart < proportion * STORM_LABELS_LENGTH.Precomp) return EMPTY_TEXT; break;
            case STORM_LABELS.Storm: if(this.model.recovery1Start - this.model.stormStart < proportion * STORM_LABELS_LENGTH.Storm) return EMPTY_TEXT; break;
            case STORM_LABELS.Recovery1: if(this.model.recovery2Start - this.model.recovery1Start < proportion * STORM_LABELS_LENGTH.Recovery1) return EMPTY_TEXT; break;
            case STORM_LABELS.Recovery2: if(this.model.recovery2End - this.model.recovery2Start < proportion * STORM_LABELS_LENGTH.Recovery2) return EMPTY_TEXT; break;
        }

        if(this.showTooltips) return text;

        return EMPTY_TEXT;
    }

    constructor(
        private utilService: UiUtilsService,
        private cdr: ChangeDetectorRef,
        private sliicerService: SliicerService,
        public translate: TranslateService,
        private dateUtilService: DateutilService
    ) {
        const precompTxt = this.translate.instant('SLIICER.COMMON.STORM_EVENT.PRECOMP');
        const stormTxt = this.translate.instant('SLIICER.COMMON.STORM_EVENT.STORM');
        const recovery1Txt = this.translate.instant('SLIICER.COMMON.STORM_EVENT.RECOVERY1');
        const recovery2Txt = this.translate.instant('SLIICER.COMMON.STORM_EVENT.RECOVERY2');

        this.optionsPrecomp.combineLabels = ((minLabel: string, maxLabel: string) => this.ngsliderCombineLabels(STORM_LABELS.Precomp, precompTxt));
        this.optionsStorm.combineLabels = ((minLabel: string, maxLabel: string) => this.ngsliderCombineLabels(STORM_LABELS.Storm, stormTxt));
        this.optionsRecovery1.combineLabels = ((minLabel: string, maxLabel: string) => this.ngsliderCombineLabels(STORM_LABELS.Recovery1, recovery1Txt));
        this.optionsRecovery2.combineLabels = ((minLabel: string, maxLabel: string) => this.ngsliderCombineLabels(STORM_LABELS.Recovery2, recovery2Txt));

    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.sliicerService.caseStudyEditable.subscribe((editable: boolean) => {
                this.isStudyLocked = !editable;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        this.subscriptions = [];
    }

    public ngOnChanges(changes: StormEventPeriodsChanges) {
        this.isRefreshing = true;

        if (changes.xAxisMinMaxPoint && changes.xAxisMinMaxPoint.currentValue) {
            const options: Options = {};
            options.floor = this.xAxisMinMaxPoint[0];
            options.ceil = this.xAxisMinMaxPoint[1];
            options.maxLimit = options.ceil;
            this.updateOptions(options);
        }

        if (changes.stormPeriods) {
            if (!this.stormPeriods) {
                this.model = {
                    precompStart: 0,
                    precompEnd: 0,
                    stormStart: 0,
                    recovery1Start: 0,
                    recovery2Start: 0,
                    recovery2End: 0,
                };

                return;
            };

            const minmax = AdsAdjustStormEventPeriodsComponent.calculateStormPeriodsMinMax(this.stormPeriods);
            const model = this.createModel(minmax);
            this.updateLimits(model);
            this.stormPeriodsMinMax = minmax;
            this.model = model;
            this.modelLast = { ...model };

            setTimeout(() => {
                this.utilService.safeChangeDetection(this.cdr);
                this.isRefreshing = false;
            });
        } else if(!changes.showTooltips) {
            this.isRefreshing = false;
        }

        if(changes.showTooltips) {
            setTimeout(() => {
                this.isRefreshing = false;
                this.utilService.safeChangeDetection(this.cdr);
            }, 100);
        }
    }

    public processDiff() {
        if (this.model.stormStart !== this.modelLast.stormStart && !this.stormPeriods.deattachPrecomp) {
            const diff = this.model.stormStart - this.modelLast.stormStart;
            this.model.precompStart += diff;
        }

        if (this.model.recovery1Start !== this.modelLast.recovery1Start) {
            const diff = this.model.recovery1Start - this.modelLast.recovery1Start;
            this.model.recovery2Start += diff;
            this.model.recovery2End += diff;
        }
    }

    /**
     * This event is fired when the user finishes dragging the storm period
     */
    public emitValues(event: ChangeContext, key: string, shouldUpdate: boolean) {
        if (shouldUpdate) {
            this.model[key] = event.value;
        }
        this.roundSliderValuesToStepLength();
        // TODO: ngx-slider maxLimit seems to be broken for dynamic updates. Fix it by adjusting here
        if (this.model.precompStart > this.model.stormStart) this.model.precompStart = this.model.stormStart;
        if (this.model.stormStart > this.model.recovery1Start) this.model.stormStart = this.model.recovery1Start;
        if (this.model.recovery1Start > this.model.recovery2Start)
            this.model.recovery1Start = this.model.recovery2Start;
        if (this.model.recovery2Start > this.model.recovery2End) this.model.recovery2Start = this.model.recovery2End;

        this.processDiff();

        this.updateMinMax(this.model);

        const stormPeriods = {
            stormId: this.stormPeriods.stormId,
            altPrecompStart: moment(this.stormPeriodsMinMax.precompDurationMin).format('YYYY-MM-DD HH:mm:ss'),
            altPrecompEnd: moment(this.stormPeriodsMinMax.precompDurationMax).format('YYYY-MM-DD HH:mm:ss'),
            deattachPrecomp: this.stormPeriods.deattachPrecomp,
            precompPeriodLength: AdsAdjustStormEventPeriodsComponent.convertTimeToMin(
                this.stormPeriods.deattachPrecomp ? this.stormPeriodsMinMax.stormStartMin : this.stormPeriodsMinMax.precompDurationMax,
                this.stormPeriodsMinMax.precompDurationMin,
            ),
            stormStartTime: moment(this.stormPeriodsMinMax.stormStartMin).format('YYYY-MM-DD HH:mm:ss'),
            stormPeriodLength: AdsAdjustStormEventPeriodsComponent.convertTimeToMin(
                this.stormPeriodsMinMax.stormStartMax,
                this.stormPeriodsMinMax.stormStartMin,
            ),
            recovery1PeriodLength: AdsAdjustStormEventPeriodsComponent.convertTimeToMin(
                this.stormPeriodsMinMax.recovery1DurationMax,
                this.stormPeriodsMinMax.stormStartMax,
            ),
            recovery2PeriodLength: AdsAdjustStormEventPeriodsComponent.convertTimeToMin(
                this.stormPeriodsMinMax.recovery2DurationMax,
                this.stormPeriodsMinMax.recovery1DurationMax,
            ),
        };

        this.periodAdjustedEmitter.emit(stormPeriods);
    }

    private roundSliderValuesToStepLength() {
        const study = this.sliicerService.caseStudyDetails.getValue();
        const studyStepLength = study.config.stepLengthMinutes;
        const isHourlyStep = studyStepLength === 60;

        Object.keys(this.model).forEach((k: string) => {
            if (!this.model[k]) return;

            const date = new Date(this.model[k]);

            if (isHourlyStep) {
                this.model[k] = this.dateUtilService.roundToNearestHour(date).getTime();
            } else {
                this.model[k] = this.dateUtilService.roundToNearest30(date).getTime();
            }
        });
    }

    private static convertTimeToMin(...params): number {
        let difference = params[0];
        for (let i = 1; i < params.length; i++) {
            difference = difference - params[i];
        }
        return Math.ceil(difference / 60 / 1000);
    }

    private static calculateStormPeriodsMinMax(stormPeriods: StormPeriodAdjustment): StormPeriodsMinMax {
        let precompStart, precompEnd
        if (stormPeriods.deattachPrecomp) {
            precompStart = +moment(stormPeriods.stormStartTime).subtract(stormPeriods.precompPeriodLength, 'minutes');
        } else {
            precompStart = +moment(stormPeriods.altPrecompStart);
            precompEnd = +moment(stormPeriods.altPrecompEnd);
        }
        const stormStart = +moment(stormPeriods.stormStartTime);
        const stormEnd = +moment(stormPeriods.stormStartTime).add(stormPeriods.stormPeriodLength, 'minutes');
        const recovery1End = +moment(stormPeriods.stormStartTime)
            .add(stormPeriods.stormPeriodLength, 'minutes')
            .add(stormPeriods.recovery1PeriodLength, 'minutes');
        const recovery2End = +moment(stormPeriods.stormStartTime)
            .add(stormPeriods.stormPeriodLength, 'minutes')
            .add(stormPeriods.recovery1PeriodLength, 'minutes')
            .add(stormPeriods.recovery2PeriodLength, 'minutes');

        const options: StormPeriodsMinMax = {
            precompDurationMin: precompStart,
            precompDurationMax: precompEnd,

            stormStartMin: stormStart,
            stormStartMax: stormEnd,

            recovery1DurationMin: stormEnd,
            recovery1DurationMax: recovery1End,

            recovery2DurationMin: recovery1End,
            recovery2DurationMax: recovery2End,
        };

        return options;
    }

    private createModel(minmax: StormPeriodsMinMax) {
        return {
            precompStart: minmax.precompDurationMin,
            precompEnd: minmax.precompDurationMax,
            stormStart: minmax.stormStartMin,
            recovery1Start: minmax.recovery1DurationMin,
            recovery2Start: minmax.recovery2DurationMin,
            recovery2End: minmax.recovery2DurationMax,
        };
    }

    private updateOptions(options: Options) {
        for (const [key, val] of Object.entries(options)) {
            this.optionsPrecomp[key] = val;
            this.optionsStorm[key] = val;
            this.optionsRecovery1[key] = val;
            this.optionsRecovery2[key] = val;
        }
    }
    private updateLimits(m: AdjustStormPeriodsModel) {
        this.optionsPrecomp.maxLimit = m.precompEnd ? m.stormStart : m.recovery1Start;

        this.optionsStorm.minLimit = m.precompEnd ? m.precompEnd : m.precompStart;
        this.optionsStorm.maxLimit = m.recovery2Start;

        this.optionsRecovery1.minLimit = m.stormStart;
        this.optionsRecovery1.maxLimit = m.recovery2End;

        this.optionsRecovery2.minLimit = m.recovery1Start;
    }

    private updateMinMax(m: AdjustStormPeriodsModel) {
        this.stormPeriodsMinMax.precompDurationMin = m.precompStart;
        this.stormPeriodsMinMax.precompDurationMax = m.precompEnd ? m.precompEnd : m.stormStart;

        this.stormPeriodsMinMax.stormStartMin = m.stormStart;
        this.stormPeriodsMinMax.stormStartMax = m.recovery1Start;

        this.stormPeriodsMinMax.recovery1DurationMin = m.recovery1Start;
        this.stormPeriodsMinMax.recovery1DurationMax = m.recovery2Start;

        this.stormPeriodsMinMax.recovery2DurationMin = m.recovery2Start;
        this.stormPeriodsMinMax.recovery2DurationMax = m.recovery2End;
    }
}
