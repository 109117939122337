import { DepthDeviceType, SensorNames, VelocityDeviceType } from "app/shared/enums/device-type-code";
import { InstallationType, LocationDetails } from "app/shared/models/location-details";

export interface SensorPreference {
    id: number;
    value: number;
    text: string;
}

export interface SensorElement {
    id: number;
    value: string;
    text: string;
    altValue?: string;
}

export interface SampleRateElement {
    id: number;
    value: number;
    text: string;
}

export interface MonitoringPointElement {
    id: number;
    value: 0 | 1;
    text: string;
}

export interface MaxVelocityElement {
    id: number;
    value: number;
    text: string;
}

export interface ChannelElement {
    id: number;
    value: number;
    text: string;
}

export interface LocationCardData {
    rainGaugeLocations: RainGaugeLocation[];
    customerId: number;
    editMode: boolean;
    isFromCustomerEditor: boolean;
    locationDetails: LocationDetails;
    isFromNotificationDash?: boolean;
    enableSelectOnMap?: boolean;
}

export interface MaxRangeElement {
    id: number;
    value: number;
    text: string;
}

export interface RainGaugeLocation {
    assignedRainGaugeId: number;
    customerID: number;
    dataCollectTaskID: number;
    exportDataToFtp: boolean;
    geographicLoc: { elevation: number; latitude: number; longitude: number };
    installationHeight: number;
    installationId: number;
    installationLoadCustom: boolean;
    installationShape: string;
    installationType: number;
    lastCollectedDate: string | Date;
    locationId: number;
    locationName: string;
    locationType: number;
    monitorDeploymentID: number;
    monitoringPoint: number;
    publicationGroupID: number;
    serialNumber: number;
    series: string;
    status: number;
    wasCreatedFromImport: boolean;
}

export interface TritonSensorGainControlList {
    id: number;
    value: number;
    text: string;
}

export interface TritonSensorPowerList {
    id: number;
    value: number;
    text: string;
}

export interface TritonTemperatureSensorList {
    id: number;
    value: number;
    text: string;
}

export interface BasicInfo {
    locationName: string;
    locationid: number;
    description: string;
    fastrate: number;
    ipaddress: string;
    installationType: InstallationType;
    installationShapeType: string
    latitude: number;
    longitude: number;
    manholeaddress: string;
    manholedepth: number;
    series: string;
    samplerate: number;
    serialnumber: number;
    assignedRainGauge: string;
    communicationType: string;
    apnProvider: string;
    status: number;
    maintenanceInterval: number;
    qFinalEntityID: number;
    installationDate: string;
    synctime: boolean;
    usedaylightsaving: boolean;
    maintenanceEnd: string;
    customProperties: { key: string; value: string }[];
}

export interface TritonMonitor {
    sensor: SensorNames,
    channel: 1 | 2;
    mp: 0 | 1;
    silt: number;
    depthSensor: DepthDeviceType;
    velocitySensor: VelocityDeviceType;
}

export const HIGH_FLOW_ENABLE = 'highFlowEnable';
export const HIGH_FLOW_THRESHOLD = 'highFlowThreshold';
