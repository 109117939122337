import { environment } from 'app/environments/environment';
import { MenuItem } from './menu-item';

// TODO AMP: quick dirty way to pull 'data_editing_audit_report' from menu in prod
// delete bottom function and uncomment this to restore

/*export const menuItemSetup: MenuItem[] =
  environment.showOnlyCrowdcore === true ?

    [new MenuItem({ title: 'CrowdCore', link: '/crowdcore', icon: 'poll' })]
    :
    [
      new MenuItem({ title: 'NAVIGATION.HOME', link: '/', icon: 'home' }),
      new MenuItem('NAVIGATION.DASHBOARDS', null, [
        new MenuItem({ title: 'NAVIGATION.CUSTOM_DASHBOARD', link: '/pages/menuDashboard/custom-dashboard', icon: '' }),
        new MenuItem({ title: 'NAVIGATION.LOCATIONS', link: '/pages/menuDashboard', icon: '' }),
        new MenuItem({ title: 'NAVIGATION.NOTIFICATION_DASHBOARD', link: '/pages/menuDashboard/notifications', icon: '' }),
        new MenuItem({ title: 'NAVIGATION.SCHEDULE_COLLECT', link: '/pages/menuDashboard/schedule-collect/overview', icon: '' }),
      ], null, null, 'description'),
      new MenuItem({ title: 'NAVIGATION.VAULT', link: '/pages/vault/list', icon: 'folder' }),
      new MenuItem('NAVIGATION.REPORTS', null, [
        new MenuItem({ title: 'NAVIGATION.AUDIT_LOG', link: '/pages/report/audit', icon: '' }),
        new MenuItem({ title: 'NAVIGATION.DAILY_SUMMARY', link: '/pages/report', icon: '' }),
        new MenuItem({ title: 'NAVIGATION.DATA_COMMUNICATION', link: '/pages/datacomm/report', icon: '' }),
        new MenuItem({ title: 'NAVIGATION.DATA_EDITING_AUDIT_REPORT', link: '/pages/report/data-editing-audit', icon: '' }),
        new MenuItem({ title: 'FLOW_MONITORING.TITLE', link: '/pages/report/flowMonitoringReport', icon: '' }),
        new MenuItem({ title: 'NAVIGATION.PERCENT_FULL', link: '/pages/report/percent-full/overview', icon: '' }),
        new MenuItem({ title: 'NAVIGATION.UPTIME', link: '/pages/report/uptime/overview', icon: '' })
      ], null, null, 'description'),
      new MenuItem('NAVIGATION.ANALYTICS', null, [
        new MenuItem({ title: 'NAVIGATION.SLICER', link: '/pages/sliicer', icon: '' })
      ], null, null, ''),
      new MenuItem('NAVIGATION.ADMIN', null, [
        new MenuItem({ title: 'NAVIGATION.USERS', link: '/pages/admin', icon: '' }),
      ], null, null, 'supervisor account')
    ];
*/
export const menuItemSetup: MenuItem[] = environment.disableTestItems
    ? // Hide a few options in production
      environment.showOnlyCrowdcore === true
        ? [new MenuItem({ title: 'CrowdCore', link: '/crowdcore', icon: 'poll' })]
        : [
              new MenuItem({ title: 'NAVIGATION.HOME', link: '/', icon: 'home' }),
              new MenuItem(
                  'NAVIGATION.DASHBOARDS',
                  null,
                  [
                      new MenuItem({
                          title: 'NAVIGATION.CUSTOM_DASHBOARD',
                          link: '/pages/menuDashboard/custom-dashboard',
                          icon: '',
                      }),
                      new MenuItem({ title: 'NAVIGATION.LOCATIONS', link: '/pages/menuDashboard', icon: '' }),
                      new MenuItem({
                          title: 'NAVIGATION.NOTIFICATION_DASHBOARD',
                          link: '/pages/menuDashboard/notifications',
                          icon: '',
                      }),
                      new MenuItem({
                          title: 'NAVIGATION.SCHEDULE_COLLECT',
                          link: '/pages/menuDashboard/schedule-collect/overview',
                          icon: '',
                      }),
                  ],
                  null,
                  null,
                  'description',
              ),
              new MenuItem({ title: 'NAVIGATION.VAULT', link: '/pages/vault/list', icon: 'folder' }),
              new MenuItem(
                  'NAVIGATION.REPORTS',
                  null,
                  [
                      new MenuItem({ title: 'NAVIGATION.AUDIT_LOG', link: '/pages/report/audit', icon: '' }),
                      new MenuItem({ title: 'NAVIGATION.DAILY_SUMMARY', link: '/pages/report', icon: '' }),
                      new MenuItem({
                        title: 'NAVIGATION.RAIN_TOTAL_REPORT',
                        link: '/pages/report/daily-rain-report',
                        icon: '',
                      }),
                      new MenuItem({
                          title: 'NAVIGATION.DATA_EDITING_AUDIT_REPORT',
                          link: '/pages/report/data-editing-audit',
                          icon: '',
                      }),
                      new MenuItem({
                          title: 'FLOW_MONITORING.TITLE',
                          link: '/pages/report/flowMonitoringReport',
                          icon: '',
                      }),
                      new MenuItem({ title: 'NAVIGATION.LOCATION_NOTIFICATION_CONFIGURATION', link: '/pages/report/notificationConfiguration', icon: '' }),
                      new MenuItem({
                        title: 'NAVIGATION.LOCATION_CONFIGURATION_REPORT',
                        link: '/pages/report/locationConfiguration',
                        icon: '',
                      }),
                      new MenuItem({
                          title: 'NAVIGATION.PERCENT_FULL',
                          link: '/pages/report/percent-full/overview',
                          icon: '',
                      }),
                      new MenuItem({
                        title: 'NAVIGATION.RAIN_FALL_FREQUENCY',
                        link: '/pages/report/rainFallReport',
                        icon: '',
                      }),
                      new MenuItem({ title: 'NAVIGATION.UPTIME', link: '/pages/report/uptime/overview', icon: '' }),
                  ],
                  null,
                  null,
                  'description',
              ),
              new MenuItem(
                  'NAVIGATION.ANALYTICS',
                  null,
                  [new MenuItem({ title: 'NAVIGATION.SLICER', link: '/pages/sliicer', icon: '' })],
                  null,
                  null,
                  '',
              ),
              new MenuItem(
                  'NAVIGATION.ADMIN',
                  null,
                  [ 
                    new MenuItem({ title: 'NAVIGATION.USERS', link: '/pages/admin', icon: '' }),
                    new MenuItem({ title: 'NAVIGATION.ALERTS', link: '/pages/adminalert', icon: '' }),
                    new MenuItem({ title: 'NAVIGATION.USAGE', link: '/', queryParams: '{dialog: true}', icon: ''})
                  ],
                  null,
                  null,
                  'supervisor account',
              ),
              new MenuItem({ title: 'NAVIGATION.CUSTOMER_USER_MANAGER', link: '/pages/customerUserManager', icon: 'group' }),
          ]
    : environment.showOnlyCrowdcore === true
    ? [new MenuItem({ title: 'CrowdCore', link: '/crowdcore', icon: 'poll' })]
    : [
          new MenuItem({ title: 'NAVIGATION.HOME', link: '/', icon: 'home' }),
          new MenuItem(
              'NAVIGATION.DASHBOARDS',
              null,
              [
                  new MenuItem({
                      title: 'NAVIGATION.CUSTOM_DASHBOARD',
                      link: '/pages/menuDashboard/custom-dashboard',
                      icon: '',
                  }),
                  new MenuItem({ title: 'NAVIGATION.LOCATIONS', link: '/pages/menuDashboard', icon: '' }),
                  new MenuItem({
                      title: 'NAVIGATION.NOTIFICATION_DASHBOARD',
                      link: '/pages/menuDashboard/notifications',
                      icon: '',
                  }),
                  new MenuItem({
                      title: 'NAVIGATION.SCHEDULE_COLLECT',
                      link: '/pages/menuDashboard/schedule-collect/overview',
                      icon: '',
                  }),
              ],
              null,
              null,
              'description',
          ),
          new MenuItem({ title: 'NAVIGATION.VAULT', link: '/pages/vault/list', icon: 'folder' }),
          new MenuItem(
              'NAVIGATION.REPORTS',
              null,
              [
                  new MenuItem({ title: 'NAVIGATION.AUDIT_LOG', link: '/pages/report/audit', icon: '' }),
                  new MenuItem({ title: 'NAVIGATION.DAILY_SUMMARY', link: '/pages/report', icon: '' }),
                  new MenuItem({
                    title: 'NAVIGATION.RAIN_TOTAL_REPORT',
                    link: '/pages/report/daily-rain-report',
                    icon: '',
                  }),
                  new MenuItem({
                      title: 'NAVIGATION.DATA_EDITING_AUDIT_REPORT',
                      link: '/pages/report/data-editing-audit',
                      icon: '',
                  }),
                  new MenuItem({
                      title: 'FLOW_MONITORING.TITLE',
                      link: '/pages/report/flowMonitoringReport',
                      icon: '',
                  }),
                  new MenuItem({ title: 'NAVIGATION.LOCATION_NOTIFICATION_CONFIGURATION', link: '/pages/report/notificationConfiguration', icon: '' }),
                  new MenuItem({
                    title: 'NAVIGATION.LOCATION_CONFIGURATION_REPORT',
                    link: '/pages/report/locationConfiguration',
                    icon: '',
                  }),
                  new MenuItem({
                      title: 'NAVIGATION.PERCENT_FULL',
                      link: '/pages/report/percent-full/overview',
                      icon: '',
                  }),
                  new MenuItem({
                    title: 'NAVIGATION.RAIN_FALL_FREQUENCY',
                    link: '/pages/report/rainFallReport',
                    icon: '',
                  }),
                  new MenuItem({ title: 'NAVIGATION.UPTIME', link: '/pages/report/uptime/overview', icon: '' }),
              ],
              null,
              null,
              'description',
          ),
          new MenuItem(
              'NAVIGATION.ANALYTICS',
              null,
              [new MenuItem({ title: 'NAVIGATION.SLICER', link: '/pages/sliicer', icon: '' })],
              null,
              null,
              '',
          ),
          new MenuItem(
              'NAVIGATION.ADMIN',
              null,
              [ 
                new MenuItem({ title: 'NAVIGATION.USERS', link: '/pages/admin', icon: '' }),
                new MenuItem({ title: 'NAVIGATION.ALERTS', link: '/pages/adminalert', icon: '' }),
                new MenuItem({ title: 'NAVIGATION.USAGE', link: '/', queryParams: {dialog: true}, icon: ''})
              ],
              null,
              null,
              'supervisor account',
          ),
          new MenuItem({ title: 'NAVIGATION.CUSTOMER_USER_MANAGER', link: '/pages/customerUserManager', icon: 'group' }),
      ];
