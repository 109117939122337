import {
    Component,
    OnInit,
    OnDestroy,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
    ViewEncapsulation,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { UptimeReportService } from '../uptime-report.service';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { Subscription } from 'rxjs';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { SharedService } from 'app/shared/services/shared.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { UptimeDays, UptimeDetailsReportArgs, UptimeReportItem } from 'app/shared/models/uptime';
import {
    activeInactiveLocationQueryParam,
    customerLocationGroupQueryParam,
    customerLocationNameQueryParam,
    customerQueryParam,
} from 'app/shared/models/customer';
import { WidgetFilterData } from 'app/shared/models/widget-filter-data';
import { EntityService } from 'app/shared/services/entity.service';
import { DEPTH_ENTITY, ELEVATION_ENTITY, QUANTITY_ENTITY, RAIN_ENTITY, VELOCITY_ENTITY } from 'app/shared/constant';

@Component({
    selector: 'app-uptime-report-details',
    templateUrl: './uptime-details.component.html',
    styleUrls: ['./uptime-details.component.scss'],
    providers: [DatePipe],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class UptimeDetailsComponent implements OnInit, OnDestroy {
    public uptimeData: UptimeReportItem;
    public uptimeLoadingState: boolean;
    public startDate: Date;
    public endDate: Date;
    public displayFilters: boolean;
    public locationName: string;
    private flag = true;

    private locationID: number;
    public customerID: number;
    public locationGroupID: number;
    private subscriptions = new Array<Subscription>();

    /**
     * date format on x axis label
     */
    public dateFormat: string;

    /**
     * filtered data as per page size for uptime details table
     */
    public pageData = new Array<UptimeDays>();

    /**
     * represents page index
     */
    public pageIndex = 0;

    /**
     * Represents the state of active and inactive locations
     * True will represent the both (active and inactive) locations
     * False will represent only active locations
     */
    public includeInactiveLocations: boolean;
    allowedEntities: any;

    constructor(
        private uptimeReportService: UptimeReportService,
        private route: ActivatedRoute,
        private datePipe: DatePipe,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private sharedService: SharedService,
        private dateUtilService: DateutilService,
        private domOperationUtilsService: DomOperationUtilsService,
        private uiUtilsService: UiUtilsService,
        private entityService: EntityService,
    ) {
        this.uptimeLoadingState = false;
    }

    public ngOnInit() {
        const routeSubscription = this.route.params.subscribe((params) => {
            this.locationID = +params['id'];
        });

        this.subscriptions.push(routeSubscription);

        this.subscriptions.push(
            this.route.queryParamMap.subscribe((params: ParamMap) => {
                this.locationGroupID = Number(params.get(customerLocationGroupQueryParam));
                this.locationName = params.get(customerLocationNameQueryParam);

                // get updated locations
                this.includeInactiveLocations = Boolean(Number(params.get(activeInactiveLocationQueryParam)));

                if (!this.customerID) {
                    this.customerID = Number(params.get(customerQueryParam));
                    this.subscriptions.push(
                        this.entityService
                            .getEntitiesByCustomer(this.customerID, this.locationID)
                            .subscribe((entities) => {
                                this.allowedEntities = entities;
                                this.generateReportWithDefaultParams();
                            }),
                    );

                    // subscribe to changes in dateformat
                    const subscription = this.dateUtilService.dateFormat.subscribe(() => {
                        // Getting customer dateformat from dateUtil Service
                        this.dateFormat = this.dateUtilService.getFormat();
                    });
                    this.subscriptions.push(subscription);
                } else {
                    this.router.navigate(['../../'], {
                        queryParamsHandling: 'preserve',
                        relativeTo: this.route,
                    });
                }
            }),
        );
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((x) => x.unsubscribe());
    }

    private generateReportWithDefaultParams() {
        this.endDate = new Date();
        this.endDate.setDate(this.endDate.getDate() - 1);
        this.startDate = new Date();
        this.startDate.setDate(this.startDate.getDate() - 7);

        const startDay = this.startDate.getDate();
        const startMonth = this.startDate.getMonth();
        const startYear = this.startDate.getFullYear();

        const endDay = this.endDate.getDate();
        const endMonth = this.endDate.getMonth();
        const endYear = this.endDate.getFullYear();

        const args = <UptimeDetailsReportArgs>{
            CustomerId: this.customerID,
            LocationGroupId: this.locationGroupID,
            LocationIds: [this.locationID],
            EntityIds: this.allowedEntities
                .filter(
                    (entity) =>
                        entity.entityId === VELOCITY_ENTITY ||
                        entity.entityId === DEPTH_ENTITY ||
                        entity.entityId === QUANTITY_ENTITY ||
                        entity.entityId === RAIN_ENTITY ||
                        entity.entityId === ELEVATION_ENTITY,
                )
                .map((e) => e.entityId),
            Start: [startMonth + 1, startDay, startYear].join('-'),
            End: [endMonth + 1, endDay, endYear].join('-'),
            IncludeInactiveLocations: this.includeInactiveLocations,
        };
        this.generateUptimeDetailsReport(args);
    }

    public notifyReport(filtersData: WidgetFilterData) {
        if (filtersData.entityIDs.length < 1) {
            return;
        }

        let startDate = null;
        let endDate = null;
        if (filtersData.startDate) {
            startDate = [
                filtersData.startDate.getMonth() + 1,
                filtersData.startDate.getDate(),
                filtersData.startDate.getFullYear(),
            ].join('/');
        }
        if (filtersData.endDate) {
            endDate = [
                filtersData.endDate.getMonth() + 1,
                filtersData.endDate.getDate(),
                filtersData.endDate.getFullYear(),
            ].join('/');
        }

        const args = <UptimeDetailsReportArgs>{
            CustomerId: filtersData.customerID,
            LocationGroupId: this.locationGroupID,
            EntityIds: filtersData.entityIDs,
            Start: startDate,
            End: endDate,
            LocationIds: [this.locationID],
            IncludeInactiveLocations: this.includeInactiveLocations,
        };
        this.startDate = startDate;
        this.endDate = endDate;
        this.generateUptimeDetailsReport(args);
    }

    public showUptimeReportFilter() {
        this.displayFilters = !this.displayFilters;
        if (this.displayFilters && this.flag) {
            this.sharedService.isEntityRequired.next(this.flag);
            this.flag = false;
        } else {
            this.sharedService.isEntityRequired.next(this.flag);
        }
    }

    /**
     * method to handle page change event
     */
    public onPaginateChange(event) {
        this.domOperationUtilsService.scrollToTop('html');
        if (this.uptimeData && this.uptimeData.uptimeDays && this.uptimeData.uptimeDays) {
            const datatable = this.uptimeData.uptimeDays.slice();
            // Grab the page's slice of data.
            const startIndex = event.pageIndex * event.pageSize;
            this.pageIndex = event.pageIndex;
            this.pageData = datatable.splice(startIndex, event.pageSize);
        }
    }

    private generateUptimeDetailsReport(args: UptimeDetailsReportArgs) {
        if (this.uptimeReportService.selectedEntities.length && !args.EntityIds) {
            args.EntityIds = this.uptimeReportService.selectedEntities;
        }
        this.uptimeLoadingState = true;

        this.uptimeReportService.getUptimeReportForLocation(args).subscribe(
            (response: Array<UptimeReportItem>) => {
                this.uptimeData = null;

                if (response && response.length > 0 && response[0].uptimeDays && response[0].uptimeDays.length > 0) {
                    this.uptimeData = response[0];

                    for (const day of this.uptimeData.uptimeDays) {
                        for (const item of day.uptimeValues) {
                            if (!item.uptime) {
                                item.uptime = '-';
                            }
                        }
                    }
                    this.pageData = this.uptimeData.uptimeDays.slice(0, 10);
                    this.pageIndex = 0;
                }
                this.uptimeLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
            (error) => {
                this.uptimeLoadingState = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
    }

    public downloadCSV() {
        const csvData = new Array<Object>();

        const csvHeaders = new Array<string>();
        csvHeaders.push('Date');

        if (this.uptimeData && this.uptimeData.uptimeDays && this.uptimeData.uptimeDays.length > 0) {
            this.uptimeData.uptimeDays.forEach((day, dayIndex) => {
                const newItem = new Array<string>();
                newItem.push(day.day);

                if (day.uptimeValues && day.uptimeValues.length > 0) {
                    day.uptimeValues.forEach((value, valueIndex) => {
                        if (dayIndex === 0) {
                            csvHeaders.push(value.entityName);
                        }
                        if (value.uptime === '-') {
                            newItem.push('-');
                        } else {
                            newItem.push(Number(value.uptime).toFixed(2).toString());
                        }
                    });
                }
                csvData.push(newItem);
            });

            const options = {
                showLabels: true,
                headers: csvHeaders,
                // tslint:disable-next-line:max-line-length
                title: `${this.locationName} Uptime ${this.datePipe.transform(
                    this.startDate,
                    this.dateFormat,
                )} - ${this.datePipe.transform(this.endDate, this.dateFormat)}`,
                showTitle: true,
            };

            const result = new AngularCsv(csvData, `${this.locationName} Uptime report`, options);
        }
    }
}
