<div id="dataEditingId">
    <app-customized-confirmation-box
        *ngIf="showConfirmation"
        [confirmationData]="conformationDataInput"
        (listenConfirmation)="listenConfirmation($event)"
    ></app-customized-confirmation-box>
    <mat-card>
        <mat-card-header>
            <!-- <div class="auto-margin pad-l-15">{{ 'LOCATION_DASHBOARD.DATA_EDITING.EDIT_DATA_TEXT' | translate }}</div> -->
            <div class="app-flex-filler"></div>
            <div class="auto-margin custom-flat-button" [hidden]="hideFilters">
                <button mat-button [disabled]="!isClearEnabled" (click)="clearFilter()">
                    {{ 'COMMON.RESET_BTN' | translate }}
                </button>
            </div>
            <div class="auto-margin custom-flat-button" [hidden]="hideFilters">
                <mat-icon (click)="showFilters()" class="backIcon">filter_list</mat-icon>
            </div>
            <div class="auto-margin custom-flat-button" [hidden]="!hideFilters">
                <button *ngIf="showResonForEdit" (click)="emitDataEditingDialog()" mat-button>
                    {{ 'COMMON.CANCEL_BUTTON' | translate }}
                </button>
                <button mat-button *ngIf="showResonForEdit" (click)="dataEditingSubmit(true)">
                    {{ 'COMMON.SUBMIT_BTN' | translate }}
                </button>
            </div>
        </mat-card-header>

        <app-loader [isLoading]="isLoading"></app-loader>
        <div [hidden]="showResonForEdit">
            <mat-card-content id="dataEditingContaintId">
                <div class="multipleEditorSection" *ngIf="showFiltersFlag">
                    <div class="filterContent">
                        <div class="dateRangeSection custom-error pad-t-15">
                            <div class="eachDateSection">
                                <mat-form-field class="full-width">
                                    <input
                                        matInput
                                        readonly
                                        placeholder="{{
                                            'LOCATION_DASHBOARD.DATA_EDITING.START_DATE_TIME_PLACEHOLDER' | translate
                                        }}"
                                        name="startDate"
                                        [(ngModel)]="startDate"
                                        [min]="minDate"
                                        [max]="maxDate"
                                        [matDatetimepicker]="dtStartDate"
                                        (dateChange)="onChangeStartDateTime()"
                                        appDatetimepickerPosition
                                        [matDatetimepickerComp]="dtStartDate"
                                    />
                                    <mat-datetimepicker-toggle
                                        matSuffix
                                        [for]="dtStartDate"
                                    ></mat-datetimepicker-toggle>
                                    <mat-datetimepicker
                                        #dtStartDate
                                        timeInterval="1"
                                        type="datetime"
                                    ></mat-datetimepicker>
                                </mat-form-field>
                            </div>
                            <div class="eachDateSection">
                                <mat-form-field class="full-width">
                                    <input
                                        matInput
                                        readonly
                                        placeholder="{{
                                            'LOCATION_DASHBOARD.DATA_EDITING.END_DATE_TIME_PLACEHOLDER' | translate
                                        }}"
                                        name="endDate"
                                        [(ngModel)]="endDate"
                                        [min]="minDate"
                                        [max]="maxDate"
                                        [matDatetimepicker]="dtEndDate"
                                        (dateChange)="onChangeEndDateTime()"
                                        appDatetimepickerPosition
                                        [matDatetimepickerComp]="dtEndDate"
                                    />
                                    <mat-datetimepicker-toggle matSuffix [for]="dtEndDate"></mat-datetimepicker-toggle>
                                    <mat-datetimepicker
                                        #dtEndDate
                                        timeInterval="1"
                                        type="datetime"
                                    ></mat-datetimepicker>
                                </mat-form-field>
                            </div>
                            <div class="custom-error-message" *ngIf="!isDaySpanValid">
                                {{ dayErrorMessage }}
                            </div>
                        </div>
                        <div class="entitySection custom-error pad-t-15">
                            <mat-form-field class="full-width multiselect">
                                <mat-select
                                    class="multiselectOption"
                                    placeholder="{{ 'COMMON.ENTITY' | translate }}"
                                    (selectionChange)="entitiesSelectionChange($event)"
                                    (openedChange)="currentlySelectedEntitiesChange($event)"
                                    [formControl]="selectedEntitiesList"
                                    multiple
                                >
                                    <mat-option class="customCheck" *ngFor="let topping of entityList" [value]="topping"
                                        >{{ topping.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="custom-error-message" *ngIf="displayEntityError">{{ entityError }}</div>
                        </div>
                    </div>
                    <div class="multiEditAction">
                        <div class="app-flex-filler"></div>
                        <div class="auto-margin">
                            <button
                                mat-button
                                (click)="filterData()"
                                [disabled]="!isDaySpanValid || displayEntityError"
                            >
                                {{ 'LOCATION_DASHBOARD.DATA_EDITING.FILTER_BUTTON_TEXT' | translate }}
                            </button>
                            <button mat-button [disabled]="displayEntityError" (click)="clearFilter()">
                                {{ 'LOCATION_DASHBOARD.DATA_EDITING.CLEAR_BUTTON_TEXT' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="dont-print" *ngIf="noDataAvailable">
                    <div class="notabledataFound custom-table print-nodata-space">
                        <span>No data available for the selected entity.</span>
                    </div>
                </div>
                <div [hidden]="dataEditingTableData?.length === 0 || !dataEditingTableData" id="dataEditingTableId">
                    <mat-table
                        #table
                        [dataSource]="dataEditingTableDataSource"
                        matSort
                        (matSortChange)="sortData()"
                        matSortActive="dateTime"
                        matSortStart="desc"
                    >
                        <ng-container matColumnDef="dateTime">
                            <mat-header-cell *matHeaderCellDef mat-sort-header
                                >{{ 'LOCATION_DASHBOARD.DATA_EDITING.DATE_TIME_TEXT' | translate }}
                            </mat-header-cell>
                            <mat-cell
                                *matCellDef="let element"
                                [ngClass]="{ selected: element.isSelected, flagged: element.isFlagged }"
                            >
                                {{ element.dateTimeUTC | date: dateFormat }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="entity">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                                'COMMON.ENTITY' | translate
                            }}</mat-header-cell>
                            <mat-cell
                                *matCellDef="let element"
                                [ngClass]="{ selected: element.isSelected, flagged: element.isFlagged }"
                            >
                                {{ element.entity }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="rawData">
                            <mat-header-cell *matHeaderCellDef mat-sort-header
                                >{{ 'LOCATION_DASHBOARD.DATA_EDITING.RAW_DATA_TEXT' | translate }}
                            </mat-header-cell>
                            <mat-cell
                                *matCellDef="let element"
                                [ngClass]="{ selected: element.isSelected, flagged: element.isFlagged }"
                            >
                                {{ element.rawData }}
                            </mat-cell>
                        </ng-container>

                        <ng-container *ngIf="isAutoCorrectEnabled" matColumnDef="suggestedData">
                            <mat-header-cell *matHeaderCellDef mat-sort-header>{{
                                'LOCATION_DASHBOARD.DATA_EDITING.SUGGESTED_DATA_TEXT' | translate
                            }}</mat-header-cell>
                            <mat-cell
                                *matCellDef="let element"
                                [ngClass]="{ selected: element.isSelected, flagged: element.isFlagged }"
                            >
                                {{ element.suggestedData }}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="modifiedValue">
                            <mat-header-cell *matHeaderCellDef
                                >{{ 'LOCATION_DASHBOARD.DATA_EDITING.MODIFIED_VALUE_TEXT' | translate }}
                            </mat-header-cell>
                            <mat-cell
                                *matCellDef="let element"
                                [ngClass]="{ selected: element.isSelected, flagged: element.isFlagged }"
                            >
                                <mat-form-field class="full-width no-padding">
                                    <input
                                        type="number"
                                        name="modifiedValue"
                                        (keydown)="numberOnly($event)"
                                        (focus)="showPointOnAdvGraph(element, false)"
                                        (change)="notifyAdvGraphWithEditedValues(element, false)"
                                        [(ngModel)]="element.modifiedValue"
                                        matInput
                                        placeholder="{{
                                            'LOCATION_DASHBOARD.DATA_EDITING.ENTER_VALUE_PLACEHOLDER' | translate
                                        }}"
                                        required
                                    />
                                </mat-form-field>
                            </mat-cell>
                        </ng-container>
                        <ng-container *ngIf="isAutoCorrectEnabled" matColumnDef="acceptAutoCorrect">
                            <mat-header-cell *matHeaderCellDef
                                >{{ 'LOCATION_DASHBOARD.DATA_EDITING.ACCEPT_SUGGESTED_DATA_TEXT' | translate }}
                            </mat-header-cell>
                            <mat-cell
                                *matCellDef="let element"
                                class="text-align-center"
                                [ngClass]="{ selected: element.isSelected, flagged: element.isFlagged }"
                            >
                                <button
                                    (click)="acceptCorrectedValue(element, false)"
                                    [disabled]="!element.suggestedData"
                                    mat-icon-button
                                >
                                    <mat-icon>spellcheck</mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)">
                        </mat-row>
                    </mat-table>
                </div>
            </mat-card-content>
            <mat-card-actions>
                <div class="app-flex-filler"></div>
                <div class="auto-margin" [hidden]="!(totalPaginationLength > 10)">
                    <mat-paginator
                        #dataEditingTablePaginator
                        [length]="totalPaginationLength"
                        (page)="onPageChange($event)"
                        [pageIndex]="0"
                        [pageSize]="10"
                        [pageSizeOptions]="[10, 25, 100]"
                    >
                    </mat-paginator>
                </div>
            </mat-card-actions>
        </div>
    </mat-card>
    <div class="customDialogLayer" *ngIf="showResonForEdit">
        <div class="customDialog">
            <div class="customDialogPosition">
                <div id="resonForEditId">
                    <div class="popupHeaderWithButton" mat-dialog-title>
                        <div class="auto-margin pad-l-15">
                            {{ 'LOCATION_DASHBOARD.DATA_EDITING.EDIT_DATA_TEXT' | translate }}
                        </div>
                        <div class="app-flex-filler"></div>
                    </div>
                    <mat-tab-group>
                        <mat-tab label="ENTER BY DATE">
                            <div mat-dialog-content class="eachTabContent">
                                <div class="impact-space">
                                    {{ 'LOCATION_DASHBOARD.DATA_EDITING.APPLY_INFO_BY_DATE_TEXT' | translate }}
                                </div>
                                <div class="impact-space">
                                    {{ 'LOCATION_DASHBOARD.DATA_EDITING.IMPACTED_ENTITIES_TEXT' | translate }}
                                    <span class="eachEntityEdited" *ngFor="let entity of entitiesEdited">
                                        {{ entity }} <span class="listComma">, </span>
                                    </span>
                                </div>

                                <div class="impact-space">
                                    {{ 'LOCATION_DASHBOARD.DATA_EDITING.IMPACTED_TIMESPAN_TEXT' | translate
                                    }}{{ startDateInterval | date: dateFormat }} -
                                    {{ endDateInterval | date: dateFormat }}
                                </div>
                                <div *ngFor="let num of numberOfTimes; let i = index" [attr.data-index]="i">
                                    <div class="eachEditComment">
                                        <div class="custom-50p">
                                            <div class="custom-field custom-error each-Item">
                                                <mat-form-field class="full-width">
                                                    <input
                                                        #startDateInput
                                                        matInput
                                                        name="startDate"
                                                        placeholder="Start Date"
                                                        (change)="startDateChange($event, i)"
                                                        [value]="startDateDataGraph[i].value | date: customerDateFormat"
                                                        tabindex="-1"
                                                        class="datepickerStyles"
                                                        appDatetimepickerPosition
                                                        [matDatetimepickerComp]="startDte"
                                                    />
                                                    <input
                                                        matInput
                                                        hidden
                                                        name="startDateReason"
                                                        [matDatetimepicker]="startDte"
                                                        [max]="maxDate"
                                                        placeholder="{{
                                                            'LOCATION_DASHBOARD.DATA_EDITING.START_DATE_TIME_PLACEHOLDER'
                                                                | translate
                                                        }}"
                                                        required
                                                        [formControl]="startDateDataGraph[i]"
                                                        (dateChange)="startDateChange($event, i)"
                                                        class="datepickerStyles"
                                                    />
                                                    <mat-datetimepicker-toggle
                                                        matSuffix
                                                        [for]="startDte"
                                                    ></mat-datetimepicker-toggle>
                                                    <mat-datetimepicker
                                                        #startDte
                                                        disabled="false"
                                                        [disabled]="isCopyPasteOperationEnabled"
                                                    >
                                                    </mat-datetimepicker>
                                                </mat-form-field>
                                            </div>
                                            <div class="custom-field custom-error each-Item">
                                                <mat-form-field class="full-width">
                                                    <input
                                                        matInput
                                                        disabled
                                                        name="endDateReason"
                                                        [matDatetimepicker]="endDte"
                                                        [max]="maxDate"
                                                        placeholder="{{
                                                            'LOCATION_DASHBOARD.DATA_EDITING.END_DATE_TIME_PLACEHOLDER'
                                                                | translate
                                                        }}"
                                                        required
                                                        [formControl]="endDateDataGraph[i]"
                                                        (dateChange)="endDateChange($event, i)"
                                                        appDatetimepickerPosition
                                                        [matDatetimepickerComp]="endDte"
                                                    />
                                                    <mat-datetimepicker-toggle
                                                        matSuffix
                                                        [for]="endDte"
                                                    ></mat-datetimepicker-toggle>
                                                    <mat-datetimepicker
                                                        #endDte
                                                        disabled="false"
                                                        [disabled]="isCopyPasteOperationEnabled"
                                                    ></mat-datetimepicker>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="mat-error">
                                            <span *ngIf="!isStartDateValid[i]">{{
                                                'LOCATION_DASHBOARD.DATA_EDITING.START_DATE_INVALID_MSG' | translate
                                            }}</span>
                                            <span *ngIf="!isEndDateValid[i]">{{
                                                'LOCATION_DASHBOARD.DATA_EDITING.END_DATE_INVALID_MSG' | translate
                                            }}</span>
                                            <span *ngIf="!isStartDateValidRelativelyED[i]">{{
                                                'LOCATION_DASHBOARD.DATA_EDITING.START_DATE_RELATIVE_INVALID_MSG'
                                                    | translate
                                            }}</span>
                                            <span *ngIf="!isEndDateValidRelativelyED[i]">{{
                                                'LOCATION_DASHBOARD.DATA_EDITING.END_DATE_RELATIVE_INVALID_MSG'
                                                    | translate
                                            }}</span>
                                        </div>

                                        <div class="full-width">
                                            <app-multi-select-auto-complete
                                                placeholder="{{
                                                    'LOCATION_DASHBOARD.DATA_EDITING.REASON_COLUMN_TITLE' | translate
                                                }}"
                                                name="reason"
                                                [items]="reasonsSelector[i]"
                                                [minDisplayLimit]="1"
                                                [maxDisplayLimit]="11"
                                                (currentlySelectedItems)="currentlySelectedReasons($event, i)"
                                            >
                                            </app-multi-select-auto-complete>
                                        </div>
                                        <div [hidden]="hideCommentSection[i]">
                                            <mat-form-field class="full-width">
                                                <textarea
                                                    required
                                                    [(ngModel)]="otherReason"
                                                    matInput
                                                    placeholder="{{
                                                        'LOCATION_DASHBOARD.DATA_EDITING.REASON_OTHER' | translate
                                                    }}"
                                                    (change)="otherReasonState($event)"
                                                    matTextareaAutosize
                                                    maxlength="60"
                                                    matAutosizeMinRows="1"
                                                    matAutosizeMaxRows="5"
                                                ></textarea>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <div class="csvHeader" *ngIf="false">
                                    <button
                                        mat-button
                                        class="exportCSVButton"
                                        (click)="addNewTimeSpan()"
                                        [disabled]="isCopyPasteOperationEnabled"
                                    >
                                        {{ 'LOCATION_DASHBOARD.DATA_EDITING.ADD_NEW_TIMESPAN_BUTTON' | translate }}
                                    </button>
                                </div>
                            </div>
                        </mat-tab>
                        <mat-tab label="ENTER BY ENTITY">
                            <div mat-dialog-content class="eachTabContent">
                                <div class="impact-space">
                                    {{ 'LOCATION_DASHBOARD.DATA_EDITING.APPLY_INFO_BY_ENTITY_TEXT' | translate }}
                                </div>
                                <div class="impact-space">
                                    {{ 'LOCATION_DASHBOARD.DATA_EDITING.IMPACTED_ENTITIES_TEXT' | translate }}
                                    <span class="eachEntityEdited" *ngFor="let entity of entitiesEdited">
                                        {{ entity }} <span class="listComma">, </span>
                                    </span>
                                </div>

                                <div class="impact-space">
                                    {{ 'LOCATION_DASHBOARD.DATA_EDITING.IMPACTED_TIMESPAN_TEXT' | translate
                                    }}{{ startDateInterval | date: 'yyyy/MM/dd HH:mm:ss' }} -
                                    {{ endDateInterval | date: 'yyyy/MM/dd HH:mm:ss' }}
                                </div>
                                <div *ngFor="let entity of entitiesEdited">
                                    <div class="eachEditComment">
                                        <h3>{{ entity }}</h3>
                                        <mat-form-field class="full-width">
                                            <textarea
                                                name="comment"
                                                matInput
                                                placeholder="{{
                                                    'LOCATION_DASHBOARD.DATA_EDITING.COMMENT_TEXT' | translate
                                                }}"
                                                matTextareaAutosize
                                                maxlength="128"
                                                matAutosizeMinRows="1"
                                                matAutosizeMaxRows="5"
                                                (change)="handleSubmitButtonState($event)"
                                            ></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                    <div mat-dialog-actions>
                        <div class="app-flex-filler"></div>
                        <div class="auto-margin">
                            <button (click)="emitDataEditingDialog()" mat-button>
                                {{ 'COMMON.CANCEL_BUTTON' | translate }}
                            </button>
                        </div>
                        <div class="auto-margin">
                            <button mat-button (click)="dataEditingSubmit(true)" [disabled]="isReasonSelected">
                                {{ 'COMMON.SUBMIT_BTN' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
