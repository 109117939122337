<div id="appPageContentId" [ngClass]="{ showTopHint: showHintOverlay }">
    <div class="print-page">
        <div [ngClass]="hintClass + ' main-wrapper'">
            <header>
                <app-topnav
                    [currentRoute]="currentRoute"
                    [customers]="customers"
                    [locationGroups]="locationGroups"
                    (locationEmitter)="activeInactiveLocationHandler($event)"
                    (customerChange)="customerChangeHandler($event)"
                    (locationGroupChange)="locationChangeHandler($event)"
                ></app-topnav>
            </header>
            <app-alarm-notification [customerID]="currentCustomerID"></app-alarm-notification>
            <main>
                <router-outlet></router-outlet>
            </main>

            <footer>
                <c-footer></c-footer>
            </footer>
            <app-page-hint></app-page-hint>
        </div>
    </div>
</div>
<div class="overlay" *ngIf="isLoading | async">
    <mat-progress-spinner class="overlay-content" mode="indeterminate" color="primary"></mat-progress-spinner>
</div>
