<div class="add-modem-setup">
    <app-loader [isLoading]="isLoading"></app-loader>
    <div class="popupHeader" mat-dialog-title>
        <div class="auto-margin pad-l-15">
            <mat-icon class="backIcon" (click)="close()">arrow_back</mat-icon>
        </div>
        <span *ngIf="!isEditMode" class="header-with-icon"> {{ 'COMMON.ADD_MODEM_SETUP' | translate }}</span>
        <span *ngIf="isEditMode" class="header-with-icon"> {{ 'COMMON.EDIT_MODEM_SETUP' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <form #modemSetupForm="ngForm" autocomplete="off">
        <div mat-dialog-content>
            <div class="custom-50p pad-t-12">
                <div class="each-Item">
                    <mat-form-field class="full-width required">
                        <mat-select
                            placeholder="{{ 'COMMON.AUTHENTICATION' | translate }}"
                            name="modemAuthenticationType"
                            [(ngModel)]="modemSetup.authenticationType"
                        >
                            <mat-option
                                *ngFor="let authenticationType of modemAuthenticationTypes"
                                [value]="authenticationType.id"
                            >
                                {{ authenticationType.name }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="custom-error">
                <mat-form-field class="full-width">
                    <input
                        matInput
                        name="provider"
                        [pattern]="alphaNumericWithSpecialSymbolsPattern"
                        [disabled]="isEditMode"
                        [(ngModel)]="modemSetup.provider"
                        (input)="validateProvider($event.target.value)"
                        placeholder="{{ 'COMMON.PROVIDER_NAME' | translate }}"
                        maxlength="20"
                        #providerInput="ngModel"
                        required
                    />
                </mat-form-field>
                <div class="custom-error-message">
                    <span *ngIf="isDuplicateProvider">
                        {{ modemSetup.provider }} {{ 'COMMON.EXISTS_ALREADY' | translate }}
                    </span>
                    <span *ngIf="providerInput?.errors?.pattern">{{ 'COMMON.ADDRESS_ERROR' | translate }}</span>
                </div>
            </div>

            <!-- type APN -->
            <div class="custom-50p">
                <div class="each-Item custom-error">
                    <mat-form-field class="full-width">
                        <input
                            matInput
                            name="username"
                            [pattern]="alphaNumericPattern"
                            [(ngModel)]="modemSetup.username"
                            autocomplete="off"
                            tabindex="-1"
                            type="text"
                            placeholder="{{ 'COMMON.USERNAME' | translate }}"
                            maxlength="19"
                            #userNameInput="ngModel"
                        />
                    </mat-form-field>
                    <div class="custom-error-message">
                        <span *ngIf="userNameInput?.errors?.pattern">{{ 'COMMON.ADDRESS_ERROR' | translate }}</span>
                    </div>
                </div>
                <div class="each-Item text-position">
                    <mat-form-field class="full-width">
                        <input
                            [type]="showPasswordChars ? 'password' : 'text'"
                            matInput
                            placeholder="{{ 'ADD_EDIT.PASSWORD' | translate }}"
                            type="password"
                            name="password"
                            autocomplete="off"
                            tabindex="-1"
                            [(ngModel)]="modemSetup.password"
                        />
                        <mat-icon matSuffix (click)="showPasswordChars = !showPasswordChars">
                            {{ showPasswordChars ? 'visibility' : 'visibility_off' }}</mat-icon
                        >
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>
    <div mat-dialog-actions>
        <div class="auto-margin"></div>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="close()">{{ 'COMMON.CLOSE' | translate }}</button>
        </div>
        <div class="auto-margin">
            <button
                mat-button
                [disabled]="!modemSetupForm.valid || modemSetupForm.pristine || isDuplicateProvider"
                (click)="save()"
            >
                {{ 'COMMON.SAVE_BUTTON' | translate }}
            </button>
        </div>
    </div>
</div>
