import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ModemSetup, ModemSetupItem } from '../components/map/modem-setup/modem-setup.model';
import { Config } from './config';

@Injectable({
    providedIn: 'root',
})
export class ModemSetupService {
    constructor(private httpClient: HttpClient) {}

    public getAll(): Observable<Array<ModemSetupItem>> {
        return this.httpClient.get<Array<ModemSetupItem>>(`${Config.serviceUrl}${Config.urls.modemSetup.allModems}`);
    }

    public getByProvider(provider: string): Observable<ModemSetupItem> {
        return this.httpClient.get<ModemSetupItem>(
            `${Config.serviceUrl}${Config.urls.modemSetup.allModems}?provider=${provider}`,
        );
    }

    public create(modem: ModemSetup) {
        return this.httpClient.post(`${Config.serviceUrl}${Config.urls.modemSetup.updateModem}`, modem);
    }

    public update(provider: string, modem: ModemSetup) {
        return this.httpClient.put(
            `${Config.serviceUrl}${Config.urls.modemSetup.updateModem}?provider=${provider}`,
            modem,
        );
    }

    public delete(provider: string) {
        return this.httpClient.delete(`${Config.serviceUrl}${Config.urls.modemSetup.deleteModem}?provider=${provider}`);
    }
}
