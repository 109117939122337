<div class="body-content pad-l-15 pad-r-15">
    <div class="custom-stepper">
        <div class="stepper-action border-t">
            <div class="auto-margin">
                <span class="border pad-l-10">{{ 'SLIICER_TABLE.SLIICER_AUTHOR' | translate }}: {{ author }}</span>
                <span class="border pad-l-5"
                    >{{ 'SLIICER.CONFIG_TAB.DATE_CREATED' | translate }}: {{ createDate | date: dateFormat }}</span
                >
                <span class="pad-l-5"
                    >{{ 'SLIICER.CONFIG_TAB.DATE_MODIFIED' | translate }}: {{ modifiedDate | date: dateFormat }}</span
                >
            </div>
            <div class="app-flex-filler"></div>
        </div>
    </div>
    <table class="sliicer-configuration-table">
        <tr>
            <td class="table-width">
                <div class="head">{{ 'SLIICER_TABLE.SLIICER_STUDY_NAME' | translate }}</div>
                <div class="case-study-fields">{{ studyName }}</div>
            </td>
            <td colspan="4">
                <div class="head">{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_DESCRIPTION' | translate }}</div>
                <div class="case-study-fields">{{ studyDescription }}</div>
            </td>
        </tr>
        <tr>
            <td class="table-width">
                <div class="head">
                    {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_LOCATION_PLACEHOLDER' | translate }}
                </div>
                <div class="case-study-fields">{{ locationGroupName }}</div>
            </td>
            <td colspan="4">
                <div class="case-study-fields">
                    <span class="study-button-background">
                        <span class="border-right">
                            <span class="online-offline-dates">{{ flowMonitorCount }}</span>
                            <ng-container *ngIf="flowMonitorCount !== 1">
                                {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_FLOW_MONITORS' | translate }}
                            </ng-container>
                            <ng-container *ngIf="flowMonitorCount === 1">
                                {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_FLOW_MONITOR' | translate }}
                            </ng-container>
                        </span>
                        <span class="padding-le border-right">
                            <span class="online-offline-dates">{{ rainfallMonitorCount }}</span>
                            <ng-container *ngIf="rainfallMonitorCount !== 1">
                                {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_RAINFALL_MONITORS' | translate }}
                            </ng-container>
                            <ng-container *ngIf="rainfallMonitorCount === 1">
                                {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_RAINFALL_MONITOR' | translate }}
                            </ng-container>
                        </span>
                        <span class="padding-le online-offline-dates">
                            <a class="normal-link" (click)="showMonitorDetails()"
                                >{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_DETAILS' | translate }}
                            </a>
                        </span>
                    </span>
                </div>
            </td>
        </tr>
        <tr>
            <td>
                <div class="head">{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_TABLE2_STUDY_START' | translate }}</div>
                <div class="case-study-fields">{{ startDate | date: dateFormat }}</div>
            </td>
            <td>
                <div class="head">{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_TABLE2_STUDY_END' | translate }}</div>
                <div class="case-study-fields">{{ endDate | date: dateFormat }}</div>
            </td>
            <td>
                <div class="head">{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_TABLE_HEADER_4' | translate }}</div>
                <div colspan="4" class="case-study-fields">
                    {{ daysInStudy }} {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_DAYS' | translate }}
                </div>
            </td>
        </tr>
        <tr>
            <td colspan="5">
                <div class="head">{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_TABLE_HEADER_5' | translate }}</div>
                <div class="case-study-fields">{{ stepLength | customTimePipe }}</div>
            </td>
        </tr>
    </table>
</div>
