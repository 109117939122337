import { Component, OnInit, ViewEncapsulation, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Observable, Subscription } from 'rxjs';
import { PrecompensationType, SliicerCaseStudy } from 'app/shared/models/sliicer';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { finalize } from 'rxjs/operators';
import { UNITS } from 'app/shared/models/units';

@Component({
    selector: 'ads-sliicer-storm-stats',
    templateUrl: './storm-stats.component.html',
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
})
export class SliicerStormStatsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public selectedBasin: string;
    @Input() public selectedStormId: number;
    @Input() public showOnlySelectedStorm = false;
    @Input() public isLoading: string;
    @Input() public refreshStorm: Observable<void>;

    private customerId = 0;
    private caseStudyId: string;

    public PrecompensationType = PrecompensationType;
    public precompType: PrecompensationType;
    public isStormDateRequired = true;

    public dateFormat: string;
    public decimal: string;
    public unitMGD: string;
    public unitGrossEventPeakingFactor: string;
    public unitVolume: string;

    public unitOfMeasure: string;
    public customerUnit: string;
    public volumeUnit: string;
    public stormStatsColumns = [
        'stormDate',
        'rainPeak',
        // 'rollingRainPeak',       columns are hidden by US 31871
        // 'rollingRainPeakTime',
        'totalRain',
        'preCompType',
        'precompAdjust',
        'stormDuration',
        'stormRain',
        'grossQPeakStorm',
        'grossQVolumeStorm',
        'grossIIPeakStorm',
        'grossIIVolumeStorm',
        'netIIPeakStorm',
        'netIIVolumeStorm',
        'eventDuration',
        'eventRain',
        'grossQPeakEvent',
        'grossEventPeakingFactor',
        'grossQVolumeEvent',
        'grossIIPeakEvent',
        'grossIIVolumeEvent',
        'netIIPeakEvent',
        'netIIVolumeEvent',
        'grossQPeakR1',
        'grossQVolumeR1',
        'grossIIPeakR1',
        'grossIIVolumeR1',
        'netIIPeakR1',
        'netIIVolumeR1',
        'grossQPeakR2',
        'grossQVolumeR2',
        'grossIIPeakR2',
        'grossIIVolumeR2',
        'netIIPeakR2',
        'netIIVolumeR2',
    ];
    public stormStatsDataSource = new MatTableDataSource([]);

    public stormBasinResultsLoader = false;

    private subscriptions: Subscription[] = [];

    constructor(private dateutilService: DateutilService, private sliicerService: SliicerService) { }

    public ngOnInit() {
        this.customerUnit = this.dateutilService.customerUnit.getValue() || '';
        this.dateFormat = this.dateutilService.getFormat() || '';
        this.dateFormat += ' ' + this.dateutilService.getTimeFormatWithoutSeconds();
        const isMetric = this.dateutilService.isCustomerUnitsMetric.getValue();
        this.unitOfMeasure = isMetric ? 'mm' : 'in';
        this.decimal = isMetric ? '1.1-1' : '1.2-2';
        this.unitMGD = isMetric ? '1.1-1' : '1.3-3';
        this.unitGrossEventPeakingFactor = '1.2-2';
        this.unitVolume = this.customerUnit === UNITS.CFS ? '1.0-0' : '1.3-3';
        this.volumeUnit = this.dateutilService.volumneUnit.getValue();

        this.subscriptions.push(
            this.sliicerService.studyDetailsData$.subscribe((caseStudyDetails: SliicerCaseStudy) => {
                this.customerId = caseStudyDetails.customerId;
                this.caseStudyId = caseStudyDetails.id;
                this.checkInputData();
            }),
        );
        this.sliicerService.filterInfo$.subscribe(() => {
            this.checkInputData();
        });

        this.subscriptions.push(
            this.sliicerService.studyDetailsData$.subscribe((res) => {
                this.precompType = res.settings.defaultPrecompType;
            })
        );

        if (this.refreshStorm) {
            this.refreshStorm.subscribe(() => this.checkInputData());
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedStormId || (changes.selectedBasin && !changes.selectedBasin.firstChange)) {
            this.checkInputData();
        }
    }

    public ngOnDestroy() {
        this.subscriptions.forEach((subscripton) => subscripton.unsubscribe());
    }

    /**
     * This method will check if required input data is set in order to get storm stats
     */
    private checkInputData(): void {
        if (this.showOnlySelectedStorm) {
            this.bindSelectedStormStats(
                this.customerId,
                this.caseStudyId,
                this.selectedBasin,
                this.selectedStormId,
            );
        } else if (this.selectedBasin) {
            this.bindAllStormStats(this.customerId, this.caseStudyId, this.selectedBasin);
        }
    }

    /**
     * This method displays storm statistics data table.
     */
    private bindAllStormStats(customerId: number, caseStudyId: string, selectedBasin: string) {
        if (customerId === 0 || !caseStudyId || !selectedBasin) {
            this.stormStatsDataSource = new MatTableDataSource(null);
            return;
        }
        this.stormBasinResultsLoader = true;
        this.sliicerService
            .getAllStormBasinResults(customerId, caseStudyId, selectedBasin)
            .pipe(
                finalize(() => {
                    this.stormBasinResultsLoader = false;
                }),
            )
            .subscribe(
                (results) => {
                    if (results && results.length > 0) {
                        const filterInfo = this.sliicerService.filterInfo$.getValue();
                        if (filterInfo.seasons.length || filterInfo.years.length) {
                            if (filterInfo.seasons.length) {
                                results = results.filter(
                                    (x) =>
                                        filterInfo.seasons.indexOf(
                                            this.sliicerService.seasonFromDate(new Date(x.stormStartTime)),
                                        ) !== -1,
                                );
                            }
                            if (filterInfo.years.length) {
                                results = results.filter(
                                    (x) => filterInfo.years.indexOf(new Date(x.stormStartTime).getFullYear()) !== -1,
                                );
                            }
                        }
                        this.stormStatsDataSource = new MatTableDataSource(results);
                    } else {
                        this.stormStatsDataSource = new MatTableDataSource(null);
                    }
                },
                () => {
                    this.stormStatsDataSource = new MatTableDataSource(null);
                },
            );
    }

    /**
     * This method displays the selected storm statistics data table.
     */
    private bindSelectedStormStats(customerId: number, caseStudyId: string, selectedBasin: string, stormId: number) {
        if (customerId === 0 || !caseStudyId || !selectedBasin || !stormId) {
            this.stormStatsDataSource = new MatTableDataSource(null);
            return;
        }
        this.isStormDateRequired = false;
        this.stormStatsColumns = this.stormStatsColumns.filter(function (item) { return item.toString() != "stormDate" })
        this.stormBasinResultsLoader = true;
        this.sliicerService
            .getStormBasinResults(customerId, caseStudyId, stormId, selectedBasin)
            .pipe(
                finalize(() => {
                    this.stormBasinResultsLoader = false;
                }),
            )
            .subscribe(
                (result) => {
                    if (result) {
                        this.stormStatsDataSource = new MatTableDataSource([result]);
                    } else {
                        this.stormStatsDataSource = new MatTableDataSource(null);
                    }
                },
                () => {
                    this.stormStatsDataSource = new MatTableDataSource(null);
                },
            );
    }
}
