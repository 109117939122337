import {
    Component,
    OnInit,
    Input,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    EventEmitter,
    Output,
} from '@angular/core';
import { Time } from 'app/shared/models/time';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';

@Component({
    selector: 'app-timepicker',
    templateUrl: './timepicker.component.html',
    styleUrls: ['./timepicker.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class TimePickerComponent implements OnInit {
    /**
     * Represents the time value
     */
    public time: Time;

    /**
     * Represents the maximum hours value
     */
    public upperHourLimit: number;

    /**
     * Represents the minimum hours value
     */
    public lowerHourLimit: number;

    /**
     * Represents the period of the day AM/PM
     */
    public dayPeriod = 'AM';

    /** Whenever user should be able to input time with keyboard */
    @Input() public allowKeyboardInput = false;

    /**
     * Event emmiter for time value change to the parent component
     */
    @Output() public notifyTimeChange = new EventEmitter<Time>();

    /**
     * Represents customer's time format - 12 hour or 24 hour
     */
    @Input() public timeFormat: number;

    public timeAMPM: 'AM' | 'PM';

    constructor(private uiUtilsService: UiUtilsService, private cdr: ChangeDetectorRef) {}

    public ngOnInit() {
        this.time = <Time>{
            hour: this.timeFormat === 12 ? 12 : 0,
            minute: 0,
        };

        //  set hour limits depending if the format is 12 or 24 hours
        this.upperHourLimit = this.timeFormat === 12 ? 12 : 23;
        this.lowerHourLimit = this.timeFormat === 12 ? 1 : 0;
    }

    /**
     * Method increases the hours value
     * @param event - click event
     */
    public upHours(event) {
        event.preventDefault();
        this.time.hour = this.time.hour === this.upperHourLimit ? this.lowerHourLimit : this.time.hour + 1;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        this.transmitTimeChange();
    }

    /**
     * Method increases the minutes value
     * @param event - click event
     */
    public upMinutes(event) {
        event.preventDefault();
        this.time.minute = this.time.minute === 59 ? 0 : this.time.minute + 1;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        this.transmitTimeChange();
    }

    /**
     * Method decreases hours value
     * @param event - click event
     */
    public downHours(event) {
        event.preventDefault();
        this.time.hour = this.time.hour === this.lowerHourLimit ? this.upperHourLimit : this.time.hour - 1;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        this.transmitTimeChange();
    }

    /**
     * Method decreases minutes value
     * @param event - click event
     */
    public downMinutes(event) {
        event.preventDefault();
        this.time.minute = this.time.minute === 0 ? (this.time.minute = 59) : this.time.minute - 1;
        this.uiUtilsService.safeChangeDetection(this.cdr);
        this.transmitTimeChange();
    }

    /**
     * Method updates day period value to AM or PM
     */
    public switchAMPM(event) {
        event.preventDefault();
        this.dayPeriod = this.dayPeriod === 'AM' ? 'PM' : 'AM';
        this.uiUtilsService.safeChangeDetection(this.cdr);
        this.transmitTimeChange();
    }

    public transmitTimeChange() {
        const timeParameters = <Time>{
            hour: this.time.hour,
            minute: this.time.minute,
        };

        if (this.timeFormat === 12) {
            if (this.time.hour === 12 && this.dayPeriod === 'AM') timeParameters.hour = 0;
            if (this.time.hour < 12 && this.dayPeriod === 'PM') timeParameters.hour = this.time.hour + 12;
        }

        this.notifyTimeChange.emit(timeParameters);
    }

    updateHour() {
        if (this.time.hour < this.lowerHourLimit) this.time.hour = this.lowerHourLimit;
        if (this.time.hour > this.upperHourLimit) this.time.hour = this.upperHourLimit;

        this.transmitTimeChange();
    }

    updateMinute() {
        if (this.time.minute < 0) this.time.minute = 0;
        if (this.time.minute > 59) this.time.minute = 59;

        this.transmitTimeChange();
    }
}
