import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Config } from 'app/shared/services/config';
import { PercentFullReport, PercentFullReportArgs } from 'app/shared/models/percentage-full-report';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PercentFullData } from 'app/shared/models/percent-full';
import { map } from 'rxjs/operators';

@Injectable()
export class PercentFullReportService {
    constructor(private http: HttpClient) {}

    public getPercentFullReport(params: PercentFullReportArgs, nullOnEmpty = true): Observable<PercentFullData> {
        if (params.End) {
            params.End += ' 23:59:59';
        }

        if (params.LocationIds.length === 0 || (params.LocationIds.length === 1 && !params.LocationIds[0])) {
            params.LocationIds = undefined;
        }
        params.IncludeInactiveLocations = true;

        // creating HttpParams for HttpClient request
        let httpParams: HttpParams = new HttpParams();

        // setting all the existing key value of param to HttpParams
        Object.keys(params).forEach(function (key) {
            if (params[key] !== undefined) {
                if (key === 'EntityIds') {
                    params.EntityIds.forEach((element) => {
                        httpParams = httpParams.append(key, element.toString());
                    });
                } else {
                    httpParams = httpParams.append(key, params[key]);
                }
            }
        });

        return this.http.get<PercentFullData>(Config.urls.percentfull, { params: httpParams }).pipe(
            map((res) => {
                if (res) {
                    return res;
                } else {
                    return nullOnEmpty ? null : {
                        customerId: params.CustomerId,
                        daysSpan: null,
                        percentFullValues: null
                    }
                }
            }),
        );
    }

    /**
     * Method which fetchs the percent full report data from API
     * @param params Represents Percent Full Report Arguments
     */
    public getPercentFullReportData(params: PercentFullReportArgs): Observable<PercentFullReport> {
        if (params.End) {
            params.End += ' 23:59:59';
        }
        if (params.LocationIds.length === 0 || (params.LocationIds.length === 1 && !params.LocationIds[0])) {
            params.LocationIds = undefined;
        }

        // creating HttpParams for HttpClient request
        let httpParams: HttpParams = new HttpParams();

        // setting all the existing key value of param to HttpParams
        Object.keys(params).forEach(function (key) {
            if (params[key] !== undefined) {
                if (key === 'EntityIds') {
                    params.EntityIds.forEach((element) => {
                        httpParams = httpParams.append(key, element.toString());
                    });
                } else {
                    httpParams = httpParams.append(key, params[key]);
                }
            }
        });
        return this.http.get<PercentFullReport>(Config.urls.percentfull, { params: httpParams }).pipe(
            map((res) => {
                if (res) {
                    return res;
                } else {
                    return null;
                }
            }),
        );
    }
}
