import { AddLocationRain } from './add-location-rain';
import { AddLocationFlume } from './add-location-flume';
import { AddLocationChannel } from 'app/shared/models/add-location-channel';
import { AddLocationWeir } from './add-location-weir';
import { DeviceTypeCode } from '../enums/device-type-code';
import { LocationStatus } from './locations';
import { AddLocationPipes } from './add-location-pipes';
import { SampleRateElement, SensorElement } from '../components/location-card/models/location-card.model';

export class TritonLocation {
    public ultrasonicElectronicOffset: number;
    public ultrasonicDeadband: number;
    public ultrasonicFlowType: number;
    public ultrasonicDacGain: number;
    public ultrasonicStoreData: boolean;
    public ultrasonicEnable: boolean;
    public velocitySensitivity: number;
    public velocityDirection: number;
    public velocityMaximum: number;
    public velocityFlowType: number;
    public velocityDacGain: number;
    public velocityPingAmplitude: number;
    public velocityFrequency: number;
    public velocityStoreData: number;
    public velocityEnable: number;
    public velocityGain: number | string;
    public velocityScrubbing: number;
    public pressureElectronicOffset: number;
    public pressureStoreData: number;
    public pressureEnable: number;
    public pressureCalibration: number;
    public temperatureStoreData: number;
    public temperatureEnable: number;
    public type: number;
    public physicalOffset: number;
    public temperatureSensor: number;
    public installationNumber: 0 | 1;
    public active: boolean;
    public tritonSerialNumber: string;
    public locationDesc: string;
    public rainGauge: string;

    public locationName: string;
    public description?: string;
    public latitude?: number;
    public longitude?: number;
    public isActive: boolean; // TODO: CURRENTUS: REMOVEIT:
    public status: LocationStatus;
    public maintenanceInterval?: number;
    public manholeAddress?: string;
    public manholedepth?: number;
    public series: string;
    public serialNumber: string;
    public IPAddress: string;
    public pipe?: AddLocationPipes;
    public rain?: AddLocationRain;
    public flume?: AddLocationFlume;
    public weir?: AddLocationWeir;
    public channel?: AddLocationChannel;
    public assignedRainGaugeId: number;
    public communicationType?: number;
    public qFinalEntityId?: number;
    public installationtypeid?: number;
    public modem?: boolean;
    public installationDate?: Date | string;
}

export class PeakComboSensor {
    // peak combo parameters
    public peakComboFlowList: Array<SensorElement>;
    public peakComboFlow: string;
    public peakComboPhysicalOffset: string;
    public peakComboMeasurement: boolean;
    public peakComboElectronicOffset: string;
    public peakComboBandList: Array<SensorElement>;
    public peakComboStoreData: boolean;
    public peakComboStorePressureDepth: boolean;
    public peakComboPressureElectronicOffset: string;
    public peakComboAutoCaliber: boolean;
    public peakComboPressureStoreData: boolean;
    public peakComboVelocity: boolean;
    public peakComboSensitivity: number;
    public direction: string;
    public peakComboGain: string;
    public maxVelocity: string;
    public peakComboMaxVelocityInput: number;
    public scrubbling: boolean;
    public peakComboVelocityStoreData: boolean;
    public peakComboTempStoreData: boolean;

    public ultrasonicElectronicOffset: string;
    public ultrasonicDeadband: number;
    public ultrasonicFlowType: number;
    public ultrasonicDacGain: number;
    public ultrasonicStoreData: boolean;
    public ultrasonicEnable: boolean;
    public velocitySensitivity: number;
    public velocityDirection: number;
    public velocityMaximum: number;
    public velocityFlowType: number;
    public velocityDacGain: number;
    public velocityPingAmplitude: number;
    public velocityFrequency: number;
    public velocityStoreData: boolean;
    public velocityEnable: boolean;
    public velocityGain: number | string;
    public velocityScrubbing: boolean;
    public pressureElectronicOffset: string;
    public pressureStoreData: boolean;
    public pressureEnable: boolean;
    public pressureCalibration: boolean;
    public temperatureStoreData: boolean;
    public temperatureEnable: number;
    public type: number;
    public name: string;
    public physicalOffset: string;
    public temperatureSensor: number;
    public channel: number;
    public installationNumber: 0 | 1;
    public active: boolean;
    public tritonSerialNumber: string;
    public locationDesc: string;
    public rainGauge: string;
    public ultrasonicPingAmplitude: number;
}

export class SufraceComboSensor {
    public surfaceComboChannelList: Array<SampleRateElement>;
    public surfaceComboChannel: number;
    public surfaceComboPhysicalOffset: string;

    // Ultrasonic Depth Measurement
    public surfaceComboUltraDepthMeasurement: boolean;
    public surfaceComboUDMElectronicOffset: string;
    public surfaceComboFlowList: Array<SampleRateElement>;
    public surfaceComboFlow: number;
    public surfaceComboBlankingList: Array<{ id: number; text: string; value: number }>;
    public surfaceComboBlankingValue: number;
    public surfaceComboSurchargeDetection: boolean;
    public surfaceComboUDMStoreData: boolean;

    // surface velocity
    public surfaceComboSurfaceVelocity: boolean;
    public surfaceComboSVTransmitFrequencyList: Array<SampleRateElement>;
    public surfaceComboSVTransmitFrequency: number;
    public surfaceComboSvSensitivity: number;
    public surfaceComboSVDirectionList: Array<SampleRateElement>;
    public surfaceComboSVDirection: number;
    public surfaceComboSVmaxVelocity: number;
    public surfaceComboSVMaxVelocityInput: number;
    public surfaceComboSVGain: number;
    public surfaceComboSVStoreData: boolean;
    public surfaceComboSVEnableScrubbing: boolean;
    public surfaceComboSVDurationList: Array<SampleRateElement>;
    public surfaceComboSVDuration: number;

    // peak velocity
    public surfaceComboPeakVelocity: boolean;
    public surfaceComboPVTransmitFrequencyList: Array<SampleRateElement>;
    public surfaceComboPVTransmitFrequency: number;
    public surfaceComboPVSensitivity: number;
    public surfaceComboPVDirectionList: Array<SampleRateElement>;
    public surfaceComboPVDirection: number;
    public surfaceComboPVmaxVelocity: number;
    public surfaceComboPVMaxVelocityInput: number;
    public surfaceComboPVGain: number;
    public surfaceComboPVStoreData: boolean;

    public surfaceComboTemparatureStoreData: boolean;
    public surfaceComboTemparatureEnable: boolean;

    // Pressure Depth Measurement
    public surfaceComboPDMeasurement: boolean;
    public surfaceComboPDElectronicOffset: string;
    public surfaceComboPDAutomaticCalibration: boolean;
    public surfaceComboPDStoreData: boolean;

    // fields for API Call
    public ultrasonicElectronicOffset: number;
    public ultrasonicDisableSurchargeDetection: boolean;
    public ultrasonicMode: number;
    public ultrasonicBlanking: number;
    public ultrasonicDacGain: number;
    public ultrasonicStoreData: boolean;
    // public ultrasonicEnable: boolean;
    public velocitySensitivity: number;
    public velocityDirection: number;
    public velocityMaximum: number;
    // public velocityFlowType: number;
    public velocityDacGain: number;
    public velocityPingAmplitude: number;
    public velocityFrequency: number;
    public velocityStoreData: boolean;
    public velocityEnable: boolean;
    public velocityGain: number | string;
    public velocityScrubbing: boolean;
    public surfaceVelocityFrequency: number;
    public surfaceVelocitySensitivity: number;
    public surfaceVelocityDirection: number;
    public surfaceVelocityMaximum: number;
    public surfaceVelocityDuration: number;
    public surfaceVelocityGain: number | string;
    public surfaceVelocityDacGain: number;
    public surfaceVelocityPingAmplitude: number;
    public surfaceVelocityStoreData: boolean;
    public surfaceVelocityEnable: boolean;
    public surfaceVelocityScrubbing: boolean;
    public pressureElectronicOffset: number;
    public pressureStoreData: boolean;
    public pressureEnable: boolean;
    public pressureCalibration: boolean;
    public temperatureStoreData: boolean;
    // public temperatureEnable: boolean;
    public type: number;
    public name: string;
    public physicalOffset: number;
    public temperatureSensor: number;
    public channel: number;
    public installationNumber: 0 | 1;
    public active: boolean;

    constructor() {
        // this.ultrasonicEnable = false;
        this.ultrasonicDisableSurchargeDetection = false;
        this.ultrasonicStoreData = false;
        this.surfaceVelocityEnable = true;
        this.surfaceVelocityStoreData = false;
        this.velocityEnable = true;
        this.velocityStoreData = false;
        this.temperatureStoreData = false;
        this.pressureCalibration = false;
        this.pressureStoreData = false;
        this.pressureEnable = true;
        // this.temperatureEnable = false;
    }
}

export class SmartDepthSensor {
    public ultrasonicElectronicOffset: string;
    public ultrasonicDisableSurchargeDetection: boolean;
    public ultrasonicMode: number;
    public ultrasonicBlanking: number;
    public ultrasonicDacGain: number;
    public ultrasonicStoreData: boolean;
    public ultrasonicEnable: number;
    public temperatureStoreData: boolean;
    public temperatureEnable: boolean;
    public type: number;
    public name: string;
    public physicalOffset: string;
    public temperatureSensor: number;
    public channel: number;
    public installationNumber: 0 | 1;
    public active: boolean;
}

export class AVGAtedSensor {
    public ultrasonicElectronicOffset: string;
    public ultrasonicDeadband: number;
    public ultrasonicFlowType: number;
    public ultrasonicDacGain: number;
    public ultrasonicPingAmplitude: number;
    public ultrasonicStoreData: boolean;
    public ultrasonicEnable: boolean;
    public velocityFlowType: number;
    public velocitySensitivity: number;
    public velocityDirection: number;
    public velocityMaximum: number;
    public velocityMaximumAVGatedValue: number;
    public velocityMinimum: number;
    public velocityAcquisition: number;
    public velocityDacGain: number;
    public velocityPingAmplitude: number;
    public velocityFrequency: number;
    public velocityStoreData: boolean;
    public velocityEnable: boolean;
    public velocityGain: number | string;
    public velocityScrubbing: boolean;
    public pressureElectronicOffset: string;
    public pressureStoreData: boolean;
    public pressureEnable: boolean;
    public pressureCalibration: boolean;
    public temperatureStoreData: boolean;
    public temperatureEnable: boolean;
    public type: number;
    public name: string;
    public physicalOffset: string;
    public temperatureSensor: number;
    public channel: number;
    public installationNumber: 0 | 1;
    public active: boolean;
}

export interface LongRangeDepthSensor {
    physicalOffset: any;
    storeData?: boolean;
    blanking?: number;
    temperature: string;
    power: string;
    electronicOffset: number;
    gainControlSelection: string;
    manualGain: number;
    unidepthFiltering: boolean;
    storePressure?: boolean;
    MarchTemperature: number;
    SeptemberTemperature: number;
}

export interface Radar {
    maxMeasurementRange: number;
    physicalOffset: number;
}

export interface ControlType {
    value: number;
    text: string;
}

export interface RainSensor {
    rainPerTip: number;
    dataLogMode: ControlType;
}

export class LongRangeDepthTritonSensor {
    public physicalOffset: any;
    public blanking: number;
    public power: number;
    public gainControl: number;
    public compensatedTemperatureMarch: number;
    public compensatedTemperatureSeptember: number;
    public pressureStore: boolean;
    public storeData: boolean;
    public frequency: number;
    public temperatureSensor: number;
    public unidepthStore: boolean;
    public electronicOffset: any;
    public disableCapacitance: boolean;
    public logCapacitance: boolean;
    public surchargeThreshold: number;
    public type?: number;
    public name: string;
    public channel: number;
    public installationNumber: 0 | 1;
    public active: boolean;
    public longRangeDepthTritonSensorPowerModel: string;
}

export const RAINALERT_SERIES = 'RAINALERT III';
export const ECHO_SERIES = 'ECHO';
export const MONITORING_POINT = 'MP1';

export const SENSORS = [
    DeviceTypeCode.AVGated1,
    DeviceTypeCode.AVGated2,
    DeviceTypeCode.LongRangeDepth1,
    DeviceTypeCode.LongRangeDepth2,
    DeviceTypeCode.PeakCombo1,
    DeviceTypeCode.PeakCombo2,
    DeviceTypeCode.SurfaceCombo1,
    DeviceTypeCode.SurfaceCombo2,
    DeviceTypeCode.TritonSmartBat1,
    DeviceTypeCode.TritonSmartBat2,
];

export type Sensor =
    | LongRangeDepthTritonSensor
    | AVGAtedSensor
    | SmartDepthSensor
    | SufraceComboSensor
    | PeakComboSensor;

export function isSensor(input: any): input is Sensor {
    return input.type !== undefined && SENSORS.includes(input.type);
}
