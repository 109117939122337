import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { UsersService } from 'app/pages/admin/users.service';
import { GetUsersResponse } from 'app/shared/models/users-permission';

@Injectable()
export class AdminUsersResolverService  {
    private customerId: number;
    constructor(private usersService: UsersService) {}

    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GetUsersResponse> {
        this.customerId = Number(route.queryParams.c);
        return this.usersService.getAllUsers();
    }
}
