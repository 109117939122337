import { DEPTH_ENTITY, VELOCITY_ENTITY, RAW_VELOCITY, RAW_VELOCITY_ENTITY } from 'app/shared/constant';
import { DataEditPreview } from 'app/shared/models/data-edit';
import { CurveData, EntityData, GraphLine, HistoricalCurve, ScatterData, ScatterRanges } from 'app/shared/models/scatter-data';
import { AnnotationSettings, BestFitCurve } from 'app/shared/models/view-data';
import { StringUtils } from 'app/shared/utils/string-utils';
import Highcharts from 'highcharts';
import { DATA_QUALITY, bestfitColor } from './scatter-graph-constants';

export function getDataQualityColor(depthPoint: EntityData, qualityValue, isDataQuality: boolean) {
    if (isDataQuality) {
        return dataQualityColor(qualityValue);
    }
}

function dataQualityColor(value: number) {
    if (!value) {
        return;
    }

    const percentage = (value * 100) / 15;
    if (percentage < 11) {
        //  0 - 10 %
        return '#FF0000';
    } else if (percentage < 21) {
        //  11 - 20 %
        return '#FFFF00';
    } else if (percentage < 31) {
        //  21 - 30 %
        return '#FF00FF';
    } else if (percentage < 41) {
        //  31 - 40 %
        return '#0000ff';
    } else if (percentage < 51) {
        //  41 - 50 %
        return '#808000';
    } else if (percentage < 61) {
        //  51 - 60 %
        return '#008080';
    } else if (percentage < 71) {
        //  61 - 70 %
        return '#000080';
    } else if (percentage < 81) {
        //  71 - 80 %
        return '#800000';
    } else if (percentage < 91) {
        //  81 - 90 %
        return '#008000';
    } else if (percentage < 101) {
        //  91 - 100 %
        return '#00ff00';
    }
}

function getQValue(entityData: EntityData, isDataQuality: boolean) {
    if (isDataQuality) {
        const value = String(entityData).split(':')[3];
        const qualityValue = value ? value : DATA_QUALITY;
        return qualityValue;
    }
}

export function formatScattergraphPreviewResponse(response: DataEditPreview) {
    const stamps = Object.keys((response && response.d) || {});
    const resultMap = new Map<number, { id: number, value: number, stamp: number }[]>();
    resultMap.set(DEPTH_ENTITY, []);
    resultMap.set(VELOCITY_ENTITY, []);

    stamps.forEach((stamp: string) => {
        const stampData: string = response.d[stamp].substr(1, response.d[stamp].length - 2);

        stampData.split(';').forEach((item: string) => {
            const [id, value, flagged] = item.split(':');

            const currentEntity = resultMap.get(Number(id))

            if (currentEntity) {
                currentEntity.push({ id: Number(id), value: Number(value), stamp: Number(stamp) * 1000 });
            }

        });
    });

    return resultMap;
}

export function populateDepthVelocityData(initialData: ScatterData, isDataQuality: boolean,
    isScatterInvert: boolean, isEdit: boolean, color?: string, distances?: Map<number, number>, flaggedPoints = []) {
    return (initialData.d as EntityData[] )
        .map((data) => {
            const qualityValue = getQValue(data, isDataQuality);
            const depthPoint: EntityData = gatherDepthPointData(data, isScatterInvert, initialData, isEdit, distances, flaggedPoints);

            if (!depthPoint) {
                return null;
            }
            depthPoint.color = color? color : getDataQualityColor(depthPoint, qualityValue, isDataQuality);

            return depthPoint;
        })
        .filter((v) => !!v);
}

function gatherDepthPointData(pointData, isScatterInvert: boolean, initialData, isEdit: boolean, distances: Map<number, number>, flaggedPoints: EntityData[]) {
    const strElements = pointData.toString().split(':');

    const [xElement, yElement, millisecondsElement, dataQuality, flaggedElement, dis] = strElements;

    /* adding condition to remove ] from timestamp if the final element on the strelements array is of format 15239000]
  if not handled timestamps defaults to 1974 when it is of format 152390000 and last digit is removed*/
    const milliseconds =
        millisecondsElement.indexOf(']') > -1
            ? Number(millisecondsElement.substr(0, millisecondsElement.length - 1))
            : Number(millisecondsElement);

    const xValue = Number(xElement.substr(1, xElement.length));
    const yValue = Number(yElement);

    const firstAxis = isScatterInvert ? initialData.yAxis : initialData.xAxis;
    const secondAxis = isScatterInvert ? initialData.xAxis : initialData.yAxis;

    const firstAxisEntity = firstAxis.entity;
    const secondAxisEntity = secondAxis.entity;

    const distance = dis ? Number(dis.replace(']', '')) : null;
    const pointDistance = (isEdit && distances && distances.get(milliseconds * 1000) !== undefined) ? distances.get(milliseconds * 1000) : distance;

    const point = {
        dateElement: xValue + ':' + yValue + ':' + milliseconds,
        x: isScatterInvert ? yValue : xValue,
        y: isScatterInvert ? xValue : yValue,
        xId: isScatterInvert ? DEPTH_ENTITY : (firstAxisEntity === RAW_VELOCITY ? RAW_VELOCITY_ENTITY : VELOCITY_ENTITY),
        yId: isScatterInvert ? (secondAxisEntity === RAW_VELOCITY ? RAW_VELOCITY_ENTITY : VELOCITY_ENTITY) : DEPTH_ENTITY,
        xUnit: firstAxis.unit,
        yUnit: secondAxis.unit,
        xPrecision: firstAxis.precision,
        yPrecision: secondAxis.precision,
        xAxisEntity: firstAxisEntity,
        yAxisEntity: secondAxisEntity,
        dateTime: milliseconds * 1000,
        distance: pointDistance,
    };

    if (flaggedElement && Number(flaggedElement.replace(']', '')) === 1) {
        flaggedPoints.push(point);
        return null;
    }
    return point;
}

/**
 * Inverts the data for ISO-Q Lines
 * @param data - scatter data
 */
export function invertIsoQLines(data, annotationSettings, xUnit, yUnit) {
    data = optimizeScatterData(data, xUnit, yUnit);

    // code replaces x and y values
    let temp;
    for (let i = 0; i < data.length; i++) {
        temp = data[i]['y'];
        data[i]['y'] = data[i]['x'];
        data[i]['x'] = temp;
    }

    const updatedData = data.reverse();

    if (annotationSettings.isBestFit
        || annotationSettings.isSSCurve
        || annotationSettings.isManualLinear
        || annotationSettings.isManualSmooth
        || annotationSettings.isCWRcurve
        || annotationSettings.isLanfearColl
        || annotationSettings.isManningDesign
    ) {
        updatedData[0]['xUnits'] = xUnit;
        updatedData[0]['yUnits'] = yUnit;
    }

    return updatedData;
}

export function optimizeScatterData(scatterData, xUnit, yUnit, inverted = false) {
    if (typeof scatterData[0] === 'string' || scatterData[0] instanceof String) {
        scatterData.forEach(function (dataElement, index: number) {
            const strElements = dataElement.toString().split(':');

            const xElement = strElements[0];
            const xValue = Number(xElement.substr(1, xElement.length));
            const yElement = strElements[1];
            const yValue = Number(yElement.substr(0, yElement.length - 1));
            dataElement = {
                x: inverted ? yValue : xValue,
                y: inverted ? xValue : yValue,
            };

            if (index === 0) {
                dataElement['xUnits'] = inverted ? yUnit : xUnit;
                dataElement['yUnits'] = inverted ? xUnit : yUnit;
            }

            scatterData[index] = dataElement;
        });
    }

    return scatterData;
}

function generateIsoQLineObject(data) {
    return {
        name: data['name'],
        type: 'line',
        data: null,
        color: 'lightgreen',
        dashStyle: 'shortDash',
        label: data['label'],
        tooltip: {
            useHTML: true,
            headerFormat: '',
            pointFormatter: function () {
                const point = this;
                const tooltipFormatter = `${StringUtils.upperCase(point.series.name)}
        <br/>${point.x.toFixed(2)} ${point.series.data[0].xUnits} ,
      ${this.y.toFixed(2)} ${point.series.data[0].yUnits}`;
                return tooltipFormatter;
            },
        },
    };
}

function generateFroudeLineObject(data) {
    return {
        name: data['name'],
        type: 'line',
        data: null,
        color: 'darkgrey',
        dashStyle: 'dashdot',
        label: data['label'],
        tooltip: {
            useHTML: true,
            headerFormat: '',
            pointFormatter: function () {
                const point = this;
                const tooltipFormatter = `${StringUtils.upperCase(point.series.name)}
        <br/>${point.x.toFixed(2)} ${point.series.data[0].xUnits} ,
      ${this.y.toFixed(2)} ${point.series.data[0].yUnits}`;
                return tooltipFormatter;
            },
        },
    };
}

export function displayIsoQLines(
    initialData: ScatterData,
    depthVelocityData: EntityData[],
    ranges: ScatterRanges,
    annotationSettings: AnnotationSettings,
    xUnit,
    yUnit,
) {
    const { isPipeOverlay, isScatterInvert } = annotationSettings;
    if (!ranges) {
        return;
    }
    const isoQlines = [];
    for (const data of initialData.isoQ) {
        const isoQLine = generateIsoQLineObject(data);

        if (!isScatterInvert) {
            isoQLine.data = [...data['d']];
        } else {
            isoQLine.data = [...invertIsoQLines([...data['d']], annotationSettings, xUnit, yUnit)];
        }
        isoQLine.data = optimizeScatterData(isoQLine.data, xUnit, yUnit);
        isoQLine.data.forEach(function (v) {
            delete v['dataLabels'];
        });
        isoQLine.data = isoQLine.data.filter(v => v.x <= ranges.xMax && v.y <= ranges.yMax);

        const index = isScatterInvert ? isoQLine.data.length - 2 : 1;
        if (isoQLine.data.length && isoQLine.data[index]) {
            isoQLine.data[0]['xUnits'] = depthVelocityData[0].xUnit;
            isoQLine.data[0]['yUnits'] = depthVelocityData[0].yUnit;

            isoQLine.data[index]['dataLabels'] = isoQLineFormatter(data);
            isoQlines.push(isoQLine);
        }
    }

    return isoQlines;
}

export function displayFroudeLines(
    initialData: ScatterData,
    depthVelocityData: EntityData[],
    ranges: ScatterRanges,
    annotationSettings: AnnotationSettings,
    xUnit: string,
    yUnit: string,
) {
    if (!ranges) {
        return;
    }
    const { isScatterInvert, isIsoQ } = annotationSettings;
    const froudeLines = [];
    for (const data of initialData.froude) {
        const froudeLine = generateFroudeLineObject(data);
        if (!isScatterInvert) {
            froudeLine.data = [...data['d']];
        } else {
            froudeLine.data = [...invertIsoQLines([...data['d']], annotationSettings, xUnit, yUnit)];
        }
        froudeLine.data = optimizeScatterData(froudeLine.data, xUnit, yUnit);

        if (isScatterInvert) {
            froudeLine.data = froudeLine.data.filter(v => v.x <= ranges.xMax && v.y <= ranges.yMax);
        } else {
            const maxVisibleIndex = froudeLine.data.findIndex(v => v.x >= ranges.xMax || v.y >= ranges.yMax);
            froudeLine.data = froudeLine.data.slice(0, maxVisibleIndex);
        }

        const index = (isIsoQ && isScatterInvert) ? 5 : isScatterInvert ? 1 : froudeLine.data.length - 2;

        if (!froudeLine.data.length || !froudeLine.data[index]) {
            continue;
        }

        froudeLine.data.forEach(function (v) {
            delete v['dataLabels'];
        });

        // Add data units to first element.
        froudeLine.data[0]['xUnits'] = depthVelocityData[0].xUnit;
        froudeLine.data[0]['yUnits'] = depthVelocityData[0].yUnit;
        froudeLine.data[index]['dataLabels'] = froudeLineFormatter(data);

        froudeLines.push(froudeLine);
    }

    return froudeLines;
}

function isoQLineFormatter(data) {
    return {
        formatter: function () {
            return data['label'];
        },
        enabled: true,
        align: 'top',
        color: 'black',
        shadow: false,
        y: 5,
        style: { fontSize: '14px', textShadow: '0px' },
    };
}

function froudeLineFormatter(data) {
    return {
        formatter: function () {
            return data['label'];
        },
        enabled: true,
        align: 'top',
        color: 'black',
        shadow: false,
        y: 5,
        style: { fontSize: '14px', textShadow: '0px' },
    };
}

/**
 * Sets the labels for ISO-Q Lines
 * @param maxLabelScatterData - maximum value of scatter data
 */
function markLabelsforIsoQLines(isoQLine, maxLabelScatterData, isScatterInvert) {
    const filterKey = 'x';
    const filteredData = isoQLine.data.filter((x) => x[filterKey] <= maxLabelScatterData);

    const selectedData = isScatterInvert ? filteredData[filteredData.length - 1] : filteredData[0];

    if (selectedData) {
        selectedData['dataLabels'] = {
            formatter: function () {
                return isoQLine['label'];
            },
            enabled: true,
            align: 'top',
            color: 'black',
            shadow: false,
            x: -20,
            y: -5,
            style: {
                fontSize: '14px',
                textOutline: false,
            },
        };
    }
}

export function populateSavedCurveData(curveData: CurveData[] | HistoricalCurve[], depthOnY: boolean, isScatterInvert: boolean, xUnit: string, yUnit: string) {
    const { d: data } = curveData[0];
    const formattedData = data.reduce((acc, curr) => {

        const [x, y] = curr.substring(1, curr.length - 1).split(':');
        const velocity = depthOnY ? Number(x) : Number(y);
        const depth = depthOnY ? Number(y) : Number(x);

        return [...acc, { x: isScatterInvert ? depth : velocity, y: isScatterInvert ? velocity : depth }];
    }, []);

    const curve: BestFitCurve = {
        name: curveData[0].name,
        color: bestfitColor,
        label: { enabled: false },
        zIndex: 90,
        width: 1.5,
        type: (curveData[0] as HistoricalCurve).smoothCurve ? 'spline' : 'line',
        data: formattedData.filter((v, i) => formattedData.findIndex(x => v.x === x.x && v.y === x.y) === i),
        smoothCurve: !!(curveData[0] as HistoricalCurve).smoothCurve,
        tooltip: {
            useHTML: true,
            headerFormat: '',
            pointFormatter: function () {
                const point = this;
                const tooltipFormatter = `${StringUtils.upperCase(point.series.name)}<br/>
      ${point.x.toFixed(2)} ${point.series.data[0].xUnits} ,
    ${this.y.toFixed(2)} ${point.series.data[0].yUnits}`;
                return tooltipFormatter;
            },
        },
    }

    if (formattedData.length > 0) {
        formattedData[0].xUnits = xUnit;
        formattedData[0].yUnits = yUnit;
    }

    return curve;
}

export function populateBestFitSSCurves(
    initialData: ScatterData,
    depthVelocityData: EntityData[],
    annotationSettings: AnnotationSettings,
    xUnit: string,
    yUnit: string,
    slope?: number,
    effectiveRoughness?: number
) {
    if (!initialData || !initialData.curves || !depthVelocityData.length) return null;

    const clonedBestFitLines = JSON.parse(JSON.stringify(initialData.curves));
    let result: BestFitCurve;
    for (let i = 0; i < clonedBestFitLines.length; i++) {
        clonedBestFitLines[i]['d'] = optimizeScatterData(clonedBestFitLines[i]['d'], xUnit, yUnit);
        // Add data units to first element.
        if (clonedBestFitLines[i]['d'][0]) {
            clonedBestFitLines[i]['d'][0]['xUnits'] = depthVelocityData[0].xUnit;
            clonedBestFitLines[i]['d'][0]['yUnits'] = depthVelocityData[0].yUnit;
        }
        result = {
            name: clonedBestFitLines[i]['name'],
            type: 'line',
            data: !annotationSettings.isScatterInvert
                ? clonedBestFitLines[i]['d']
                : invertIsoQLines(clonedBestFitLines[i]['d'], annotationSettings, xUnit, yUnit),
            color: bestfitColor,
            label: { enabled: false },
            findNearestPointBy: 'xy',
            zIndex: 90,
            width: 1.5,
            tooltip: {
                useHTML: true,
                headerFormat: '',
                pointFormatter: function () {
                    const point = this;

                    const tooltipFormatter = `
                    ${StringUtils.upperCase(point.series.name)}<br/>
                    ${point.x.toFixed(2)} ${point.series.data[0].xUnits} ,
                    ${this.y.toFixed(2)} ${point.series.data[0].yUnits} <br />
                    ${ slope !== undefined ? `Slope: ${ slope.toFixed(4) }, Effective Roughness: ${ effectiveRoughness.toFixed(5) }` : '' }
                    `;

                    return tooltipFormatter;
                },
            },
        };

        if (clonedBestFitLines[i].smoothCurve) {
            result.type = 'spline';
            result.smoothCurve = true;
        }
    }
    return result;
}

export function createManualCurve(
    name: string,
    points: EntityData[],
    xUnit: string,
    yUnit: string,
    linearCurve: boolean
) {
    const curveObject: BestFitCurve = {
        name: name,
        type: linearCurve ? 'line' : 'spline',
        data: points,
        color: bestfitColor,
        label: { enabled: false },
        zIndex: 90,
        width: 1.5,
        tooltip: {
            useHTML: true,
            headerFormat: '',
            pointFormatter: function () {
                const point = this;
                const tooltipFormatter = `${StringUtils.upperCase(point.series.name)}<br/>
                    ${point.x} ${xUnit} ,
                    ${this.y} ${yUnit}`;
                return tooltipFormatter;
            },
        }
    };
    return curveObject;
}

export function deepCloneMap<Key, Value>(map: Map<Key, Value>) {
    const newMap = new Map<Key, Value>();

    map.forEach((value: Value, key: Key) => {
        const clonedValue = (value instanceof Map) ? deepCloneMap(value) : JSON.parse(JSON.stringify(value));
        newMap.set(key, clonedValue);
    });

    return newMap;
}
