<div class="dialog-container">
    <div class="popupHeader" mat-dialog-title>
        <div class="auto-margin pad-l-15">
            {{ 'COMMON.SAVECONFIG' | translate }}
        </div>
        <div class="app-flex-filler"></div>
    </div>
    <div mat-dialog-content class="eachTabContent p-20">
        <mat-form-field class="full-width">
            <textarea
                class="textarea-max-height"
                required
                matInput
                placeholder="{{ 'COMMON.NAME' | translate }}"
                [(ngModel)]="configName"
                (input)="onNameInput()"
                maxlength="255"
                rows="3"
            ></textarea>
        </mat-form-field>
        <div class="mat-error" *ngIf="showDuplicateNameError">
            {{ 'COMMON.CONFIG_DUPLICATE_NAME_ERROR' | translate }}
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button (click)="close()" mat-button>
                {{ 'COMMON.CANCEL_BUTTON' | translate }}
            </button>
        </div>
        <div class="auto-margin">
            <button mat-button [disabled]="!configName || showDuplicateNameError" (click)="save()">
                {{ 'COMMON.SAVE_BUTTON' | translate }}
            </button>
        </div>
    </div>
</div>