import { Directive, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { distinctUntilChanged } from 'rxjs/operators';

export interface Debounce {
    value: number;
    initial: boolean;
}
@Directive({
    selector: '[debounce]',
})
export class DebounceDirective implements OnInit {
    public debounceTime: number;
    @Output() public debounced = new EventEmitter<any>();
    @Input() set debounce(debounceData: Debounce) {
        this.debounceTime = debounceData['value'] || 0;
        this.initalIn = debounceData['initial'];
    }
    private initalIn = true;
    constructor(public model: NgControl) {}

    ngOnInit() {
        this.model.valueChanges
            .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
            .subscribe((modelValue) => {
                if (!this.initalIn) this.debounced.emit(modelValue);
                else this.initalIn = false;
            });
    }
}
