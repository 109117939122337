<div id="daily-summary-export-dialog">
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'COMMON.EXPORT_LOCATION' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <div mat-dialog-content [ngClass]="{ 'hint-dialog-background': showpageHint }">
        <!-- dialog content -->
        <mat-form-field class="full-width" [ngClass]="{ 'daily-type-hint': showpageHint }">
            <mat-select
                placeholder="{{ 'COMMON.SELECT_TYPE' | translate }}"
                [disabled]="showpageHint"
                [(ngModel)]="dailyExport.locationType"
                name="locationType"
            >
                <mat-option *ngFor="let option of locationTypeArray" [value]="option.id" required>
                    {{ option.value }}</mat-option
                >
            </mat-select>
        </mat-form-field>
        <div
            class="top custom-error"
            *ngIf="dailyExport.locationType == 0"
            [ngClass]="{ 'daily-dialog-locations-hint': showpageHint }"
        >
            <app-multi-select-auto-complete
                #multiSelectAutoCompleteLocations
                [items]="filteredLocations"
                [preselectedItems]="preSelectedLocation"
                [isShowAll]="true"
                (currentlySelectedItems)="handleSelectedLocations($event)"
                placeholder="{{ 'COMMON.LOCATIONS' | translate }} *"
                [disabled]="showpageHint"
            ></app-multi-select-auto-complete>
            <div class="custom-error-message" *ngIf="locationsInvalid()">
                <span>{{ 'COMMON.LOCATIONS_VALIDATION_MESSSAGE' | translate }}</span>
            </div>
        </div>
        <div
            class="custom-error"
            *ngIf="dailyExport.locationType == 1"
            [ngClass]="{ 'daily-dialog-locations-hint': showpageHint }"
        >
            <app-auto-complete
                [disabled]="showpageHint"
                [items]="locationGroups"
                [isShowAll]="false"
                [preselectedItem]="preSelectedLocationGroup"
                (selectedItems)="handleSelectedLocationsGroup($event)"
                placeholder="{{ 'COMMON.SELECT_LOCATION_GROUP' | translate }}"
            >
            </app-auto-complete>
            <div class="custom-error-message" *ngIf="isLocationGroupInvalid">
                <span>{{ 'COMMON.LOCATION_GROUP_VALIDATION_MESSSAGE' | translate }}</span>
            </div>
        </div>
        <app-loader [isLoading]="isLoading"></app-loader>

        <div class="custom-50p-group flex-container">
            <div class="each-containt">
                <div [ngClass]="{ 'daily-dialog-entity-hint': showpageHint }">
                    <app-entity-selector
                        *ngIf="entitySelectorObjects$ | async as entitySelectorObjects"
                        [selectedEntitiyIds]="selectedEntityIds"
                        [disabled]="showpageHint"
                        [availableEntities]="entitySelectorObjects"
                        (selectedEntities)="handleSelectedEntities($event)"
                        (selectedEntitiesList)="selectedEntitySet = $event"
                    ></app-entity-selector>
                </div>
                <div class="custom-error">
                    <div class="custom-error-message left-15" *ngIf="isEntitiesInvalid">
                        <span>{{ 'COMMON.ENTITY_VALIDATION_MESSSAGE' | translate }}</span>
                    </div>
                </div>
            </div>

            <div class="each-containt custom-error daily-summary-export-dialog">
                <div [ngClass]="{ 'daily-dialog-date-hint': showpageHint }">
                    <app-date-range-picker
                        [startDate]="preStartDate"
                        [endDate]="preEndDate"
                        [minDate]="minDate"
                        [maxDate]="maxDate"
                        [maxDayRange]="93"
                        [quickOneYear]="false"
                        [quickSixMonths]="false"
                        [oneWeekDoNotIncludeToday]="true"
                        (startDateChange)="selectedStartDate($event)"
                        (endDateChange)="selectedEndDate($event)"
                        (isValidDateRange)="validDateRange($event)"
                        [disabled]="showpageHint"
                    ></app-date-range-picker>
                </div>
            </div>
        </div>
    </div>
    <!-- dialog actions -->
    <div mat-dialog-actions class="content-position" [ngClass]="{ 'hint-dialog-background': showpageHint }">
        <div class="app-flex-filler"></div>
        <div class="auto-margin" [ngClass]="{ 'disable-field': showSaveSetting }">
            <button
                mat-button
                [ngClass]="{ 'daily-dialog-cancel-hint': showpageHint }"
                [disabled]="showpageHint"
                (click)="close()"
            >
                {{ 'COMMON.CLOSE' | translate }}
            </button>
        </div>
        <div class="auto-margin" [ngClass]="{ 'disable-field': showSaveSetting }">
            <button
                mat-button
                [ngClass]="{ 'daily-dialog-export-hint': showpageHint }"
                [disabled]="!isValidDateRange || !(selectedEntityIds.length > 0) || showpageHint"
                (click)="onExportSubmit()"
            >
                {{ 'COMMON.EXPORT_BTN' | translate }}
            </button>
        </div>
    </div>
</div>
