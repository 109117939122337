<div *ngIf="!isLoading && !showGraph" class="notabledataFound custom-table print-nodata-space">
    <span>{{ 'COMMON.NO_DATA_IN_GRAPH_MSG' | translate }}</span>
</div>
<div
    *ngIf="showGraph"
    (chartMouseDrag)="mouseDragFinish($event)"
    [dragSettings]="mouseDragSettings"
    [disableDrag]="!isDryDaysEditActive"
    [chartElement]="chartElement"
    type="StockChart"
    [chart]="chartOptions"
    class="max-width-graph"
    [id]="graphElementId"
    #chart
></div>
