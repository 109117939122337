import {
    ChangeDetectionStrategy,
    Component,
    ChangeDetectorRef,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { VaultDirectory } from 'app/shared/models/vault';
import { VaultService } from '../vault.service';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';

@Component({
    selector: 'app-vault-nav-item',
    templateUrl: './vault-nav-item.component.html',
    styleUrls: ['./vault-nav-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class VaultNavItemComponent implements OnDestroy, OnInit {
    /**
     * Represents the collection of directories.
     */
    @Input() public directory: VaultDirectory;
    /**
     * Repreents a value indicating whether the current item is actively selected as the current directory.
     */
    public isActive: boolean;

    /**
     * Represents the subscriptions to be disposed.
     */
    private subscriptions = new Array<Subscription>();

    constructor(
        private domOperationUtilsService: DomOperationUtilsService,
        private vaultService: VaultService,
        private chagneDetector: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
    ) {}

    /**
     * Lifecycle hook that is called when a directive, pipe or service is destroyed.
     */
    public ngOnDestroy() {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }

    /**
     * Lifecycle hook that is called after data-bound properties of a directive are initialized.
     */
    public ngOnInit() {
        this.subscriptions.push(
            this.vaultService.CurrentDirectory.subscribe((id: string) => {
                // set active state
                this.isActive = this.directory.id === id;

                // update self
                this.uiUtilsService.safeChangeDetection(this.chagneDetector);
            }),
        );
    }

    /**
     * Handles the response for the click event of the selected directory.
     * @param id Represents the surrogate id of the selected diretory.
     */
    public selectDirectory(id: string): void {
        this.domOperationUtilsService.scrollToTop('html');
        this.vaultService.getFilesForDirectory(id);
    }
}
