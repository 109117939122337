import { Injectable } from '@angular/core';
import { Config } from './config';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ApiKey } from '../models/api-key';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ApiKeyService {
    constructor(public http: HttpClient, public activatedRoute: ActivatedRoute) {}

    public getApiKeySummary(customerId: number) {
        return this.http.get<ApiKey[]>(Config.serviceUrl + Config.urls.apiKeySummary + `${customerId}`).pipe(
            map((res) => {
                if (res) {
                    return res;
                }
            }),
        );
    }

    public addApiKey(customerId: number, description: string) {
        const postfixUri = '&description=';
        return this.http
            .post<ApiKey>(
                Config.serviceUrl + Config.urls.apiKeySummary + `${customerId}` + postfixUri + `${description}`,
                null,
            )
            .pipe(
                map((res: ApiKey) => {
                    return res;
                }),
            );
    }

    public editApiKey(customerId: number, keyDetail: ApiKey, activeState: boolean) {
        const postfixUriDesc = '&description=';
        const postfixUriKey = '&key=';
        const postfixUriActive = '&isActive=';

        const headers = new Headers();
        headers.append('Content-Type', 'charset=utf-8');

        const url =
            Number(customerId) +
            postfixUriKey +
            encodeURIComponent(keyDetail.key) +
            postfixUriDesc +
            encodeURIComponent(keyDetail.description) +
            postfixUriActive +
            activeState;

        const res = this.http.put<any>(Config.serviceUrl + Config.urls.apiKeySummary + url, { headers: headers });

        catchError((error) => {
            console.log(error.json());
            return res;
        });
        return res;
    }

    public deleteApiKey(customerId: number, keyDetail: ApiKey) {
        const postfixUriKey = '&key=';
        const headers = new Headers();
        const keyValue = encodeURIComponent(keyDetail.key);
        return this.http
            .delete<ApiKey>(
                Config.serviceUrl + Config.urls.apiKeySummary + `${Number(customerId)}` + postfixUriKey + `${keyValue}`,
            )
            .pipe(
                map((res: ApiKey) => {
                    return res;
                }),
            );
    }

    /**users => users.map(this.toUser)
     * Convert user info from the API to our standard/format
     */
    private toApiKeySummary(apikey): ApiKey {
        return {
            cid: apikey.cid,
            key: apikey.key,
            active: apikey.active,
            expire: apikey.expire,
            description: apikey.description,
        };
    }
}
