<div id="slicerImportVaultStudyDialog">
    <div class="popupHeader" mat-dialog-title>
        <span>{{ 'SLIICER_TABLE.STUDY_VAULT_DIALOG.STUDY_VAULT_DIALOG_TITLE' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <div mat-dialog-content>
        <!-- choose from vault -->
        <div class="content-space">
            <p class="text-style">{{ 'SLIICER_TABLE.STUDY_VAULT_DIALOG.VALUT_DIALOG_CONTENT' | translate }}</p>
            <div class="custom-50p">
                <div class="input-text-width">
                    <mat-form-field class="full-width">
                        <input
                            class="text-style"
                            matInput
                            placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_FLOW_MONITOR' | translate }}"
                            [(ngModel)]="flowMonitorCount"
                        />
                    </mat-form-field>
                </div>
                <div class="input-text-width">
                    <mat-form-field class="full-width">
                        <input
                            class="text-style"
                            matInput
                            placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_STUDY_TABLE_HEADER_2' | translate }}"
                            [(ngModel)]="rainMonitorCount"
                        />
                    </mat-form-field>
                </div>
            </div>
            <div>
                <mat-form-field class="full-width">
                    <input
                        class="text-style"
                        matInput
                        placeholder="{{ 'SLIICER_TABLE.STUDY_VAULT_DIALOG.VALUT_TABLE_STUDY_PERIOD' | translate }}"
                        [(ngModel)]="studyPeriod"
                    />
                </mat-form-field>
            </div>
            <div class="custom-50p">
                <div class="input-text-width">
                    <mat-form-field class="full-width">
                        <input
                            class="text-style"
                            matInput
                            placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_DAYSINSTUDY' | translate }}"
                            [(ngModel)]="daysInStudy"
                        />
                    </mat-form-field>
                </div>
                <div class="input-text-width">
                    <mat-form-field class="full-width">
                        <input
                            class="text-style"
                            matInput
                            placeholder="{{ 'SLIICER_TABLE.SLICER_SUMMARY.SLICER_LENGTH' | translate }}"
                            [(ngModel)]="stepLength"
                        />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div class="app-flex-filler"></div>
        <div class="auto-margin">
            <button mat-button (click)="close(0)">{{ 'SLIICER.COMMON.CANCEL_BUTTON_TITLE' | translate }}</button>
        </div>
        <div class="auto-margin">
            <button mat-button (click)="close(1)">
                {{ 'SLIICER_TABLE.SLICER_SUMMARY.SLIICER_IMPORT' | translate }}
            </button>
        </div>
    </div>
</div>
