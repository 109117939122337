import { Component, OnChanges, SimpleChanges, HostBinding } from '@angular/core';
import { Input } from '@angular/core';
import { ColorService } from '../../services/color.service';

@Component({
    selector: 'core-widget',
    template: `
        <mat-card class="flex-item widget" [ngStyle]="{ 'background-color': style, color: textStyle }">
            <mat-card-content class="layout-stretch-between layout-row">
                <mat-icon class="widget-icon" *ngIf="icon">{{ icon }}</mat-icon>
                <div class="layout-column">
                    <p [ngStyle]="{ 'background-color': style, color: textStyle }" class="counter">{{ count }}</p>
                    <p
                        [ngStyle]="{ 'background-color': style, color: textStyle }"
                        class="description"
                        *ngIf="description"
                    >
                        {{ description }}
                    </p>
                </div>
            </mat-card-content>
        </mat-card>
    `,
    styles: [],
})
export class WidgetComponent implements OnChanges {
    @HostBinding('class') public get class() {
        return 'flex-item';
    }

    @Input() public icon: string = null;
    @Input() public count = 0;
    @Input() public description: string = null;
    @Input() public palette: { palette: string; hue?: string; shade?: string } = null;
    @Input() public hexColor: string = null;
    @Input() public hexTextColor: string = null;

    private color = '#fff';
    private textColor = '#000';

    constructor(private colorService: ColorService) {}

    public get style() {
        return this.color;
    }

    public get textStyle() {
        return this.textColor;
    }

    public ngOnChanges(changes) {
        for (const propName of changes) {
            if (propName === 'hexColor' || propName === 'palette') {
                this.color =
                    this.hexColor !== null
                        ? this.hexColor
                        : this.palette !== null
                        ? this.colorService.getColor(this.palette.palette, this.palette.hue)
                        : 'white';
            }
            if (propName === 'hexTextColor' || propName === 'palette') {
                this.textColor =
                    this.hexTextColor !== null
                        ? this.hexTextColor
                        : this.palette !== null
                        ? this.colorService.getTextColor(this.palette.palette, this.palette.hue, this.palette.shade)
                        : 'black';
            }
        }
    }
}
