import {
    Component,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef,
    EventEmitter,
    Output,
    Input,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateutilService } from 'app/shared/services/dateutil.service';
import highCharts from 'highcharts';

const UNIT_MM = 'mm';
const UNIT_IN = 'in';

export const GRAPH_SYMBOL_CROSS = 'cross';

@Component({
    selector: 'ads-ddf-graph',
    templateUrl: './ddf-graph.component.html',
    styleUrls: ['./ddf-graph.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DdfGraphComponent {
    @ViewChild('chart', { static: true }) public chartEl: ElementRef<HTMLDivElement>;
    @Output() public legendClick: EventEmitter<any> = new EventEmitter<any>();
    @Input() public showLegend = true;
    public gaugeData = [];
    public ddfData = [];
    private xAxisChartLabel: string;
    private yAxisChartLabel: string;
    public chartOptions: any;
    public minText: string;
    public unit: string;
    public chart: any;
    public tooltipEnabled = true;
    public rainPrecision: number;

    constructor(private translate: TranslateService, private dateutilService: DateutilService) {
        if (this.dateutilService.isCustomerUnitsMetric.getValue()) {
            this.unit = 'mm';
            this.rainPrecision = 1;
        } else {
            this.unit = 'in';
            this.rainPrecision = 2;
        }
    }

    /** Tooltip enabled button event handler.  */
    public onTooltipEnabledChanged() {
        this.tooltipEnabled = !this.tooltipEnabled;

        if (this.chart) {
            this.chart.update({
                tooltip: {
                    enabled: this.tooltipEnabled,
                },
            });
        }
    }

    public generateDdfGraph(seriesData) {
        const translationKeys: Array<string> = [
            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.RAINFALL_DEPTH',
            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_1',
            'CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.MINUTE_SHORT',
        ];
        this.translate.get(translationKeys).subscribe((values) => {
            this.yAxisChartLabel = values['CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.RAINFALL_DEPTH'];
            this.xAxisChartLabel = values['CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.TABLE_HEADER_1'];
            this.minText = values['CUSTOMER_EDITOR.RAIN_PROFILE.RAINFALL_DURATION.MINUTE_SHORT'];
        });

        this.unit = this.dateutilService.isCustomerUnitsMetric.getValue() ? UNIT_MM : UNIT_IN;
        const tooltipTitle: string = this.xAxisChartLabel;
        const t = this;
        this.chartOptions = {
            chart: {
                type: 'line',
                height: '60%',
                marginRight: 300,
                renderTo: this.chartEl.nativeElement,
            },
            legend: {
                enabled: true,
                align: 'left',
                verticalAlign: 'bottom',
                layout: 'horizontal',
                symbolPadding: 0,
                symbolWidth: 0,
                symbolHeight: 0,
                itemDistance: 0,
                squareSymbol: false,
                useHTML: true,
                labelFormatter: function() {
                    if(!this.chart || !this.chart.series) return '';
                    if(this.name !== this.chart.series[0].name) return '';

                    let rainGaugeHtml = ``;
                    let ddfHtml = ``;
                    for (const serie of this.chart.series) {
                        if (t.gaugeData.find(g=> g.name === serie.name)) {
                            serie.isProcessed = true;
                            const visible = serie.visible;
                            const color = visible ? serie.color : '#888';
                            const symbol = `width: 12px; height: 12px; background-color: ${color}; border-radius: 50%; display: inline-block; margin-right: 2px;'`;
                            const serieHTML =
                                `<span tabindex='${serie.index}'>
                                    <span style='${symbol}'></span>
                                    <span style='padding-right: 16px;'>${serie.name}</span>
                                </span>
                                `;

                                rainGaugeHtml += serieHTML;
                        }
                    }

                    for(const serie of this.chart.series) {
                        if(t.ddfData.find(g=> g.name === serie.name)) {
                            serie.isProcessed = true;
                            const visible = serie.visible;
                            const color = visible ?  '#000000' : serie.color ;
                            const symbol = `width: 12px; height: 12px; background-color: ${color}; border-radius: 50%; display: inline-block; margin-right: 2px;'`;
                            const serieHTML =
                                `<span tabindex='${serie.index}'>
                                    <span style='${symbol}'></span>
                                    <span style='padding-right: 16px;'>${serie.name}</span>
                                </span>
                                `;

                                ddfHtml += serieHTML;
                        }
                    }


                    return `<div style='width: 60vw; margin-top: 20px; display: flex; flex-direction: column; align-items: flex-start; gap: 20px; text-align: center; flex-wrap: wrap;'>
                        <div style='display: flex;flex-wrap: wrap; gap: 5px;'> ${rainGaugeHtml} </div>
                        <div style='display: flex;flex-wrap: wrap; gap: 5px;'> ${ddfHtml} </div>
                    </div>`;
                },
            },
            exporting: { enabled: t.showLegend },
            plotOptions: {
                series: {
                    states: {
                        inactive: {
                            enabled: false
                          }
                    },
                    dataGrouping: {
                        enabled: false,
                    },
                    label: {
                        enabled: false
                    },
                    events: {
                        legendItemClick: function (event) {
                            t.legendClick.emit(event);
                        },
                    },
                    lineWidth: 1,
                    shadow: false,
                    marker: {
                        enabled: true,
                        symbol: 'circle',
                        fillColor: '#FFFFFF',
                        lineWidth: 2,
                        lineColor: null, // inherit from series,
                    },
                    dataLabels: {
                        enabled: true,
                        allowOverlap: true,
                        align: 'left',
                        verticalAlign: 'middle',
                        padding: 10,
                        color: '#000',
                        formatter: function () {
                            if (this.point.y !== this.point.series.dataMax) {
                                return null
                            }

                            if (this.point.series.symbol !== GRAPH_SYMBOL_CROSS) {
                                return `<span> ${this.series.name} </span>`;
                            }

                            const data = this.series.data;

                            if (!data || !data.length || data[data.length - 1] !== this.point) {
                                return null;
                            }
                            
                            // #38123 Rain Gauge series has CROSS symbol, display label with color
                            return `<span style='color: ${this.series.color}'> ${this.series.name} </span>`;                            
                        },
                        crop: false,
                        overflow: 'none',
                    },
                },
            },
            title: {
                text: '',
            },
            xAxis: {
                alignTicks: true,
                labels: {
                    autoRotation: [-10, -20, -30, -40, -50, -60, -70, -80, -90],
                    format: '{value:.0f}',
                },
                title: {
                    text: `${t.xAxisChartLabel} (${t.minText})`,
                },
                type: 'logarithmic',
                endOnTick: true,
                showLastLabel: true,
                startOnTick: true,
                minorTicks: true,
                minorTickPosition: 'inside',
                minorTickLength: 5,
                minorTickWidth: 1,
                minorGridLineWidth: 0,
            },
            yAxis: {
                alignTicks: true,
                title: {
                    text: `${t.yAxisChartLabel} (${t.unit})`,
                },
                minorTicks: true,
                minorTickPosition: 'inside',
                minorTickLength: 5,
                minorTickWidth: 1,
                minorGridLineWidth: 0,
                gridLineWidth: 0,
                lineWidth: 1,
            },
            credits: {
                enabled: false,
            },
            series: seriesData,
            tooltip: {
                valueDecimals: this.unit === 'mm' ? 1 : 2,
                formatter: function () {
                    const decimalVal = t.unit === 'mm' ? 1: 2;
                    return `${tooltipTitle}: <b> ${this.x} </b> (${t.minText})<br/>
                            <span style ='color:#000'>\u25CF</span> ${this.series.name} : <b>
                            ${this.y.toFixed(decimalVal)} (${t.unit})</b> `;
                },
                positioner(labelWidth: number, labelHeight: number, point: { plotX: number; plotY: number }) {
                    const horizontalOffset = 72;
                    const verticalOffset = 1;

                    return {
                        x: horizontalOffset,
                        y: verticalOffset,
                    };
                },
                split: false, // for some reason without this option (although it's false by default) the positioner doesn't work
                shape: 'rect', // make sure the line (present for the default type 'callout') is not shown
            },
        };

        if (this.chartEl && this.chartEl.nativeElement) {
            this.chart = new highCharts.Chart(this.chartOptions);

            this.chart.renderer.rect();

            setTimeout(() => {
                this.chartEl.nativeElement.style.height = '100%';
            }, 0);
        }
    }
}
