import { BehaviorSubject } from 'rxjs';
// tslint:disable-next-line: max-line-length
import {
    Component,
    ViewChild,
    Input,
    ChangeDetectionStrategy,
    OnChanges,
    SimpleChanges,
    SimpleChange,
    ChangeDetectorRef,
    OnInit,
    Output,
    EventEmitter,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { GraphComponent } from 'app/pages/sliicer/shared/components/graph/graph.component';
import { SLI_COLOR_RAINFALL_MONITORS, SLI_OCOLOR_STORM_PERIOD } from 'app/shared/constant';
import { RainfallMonitor, StormEvent } from 'app/shared/models/sliicer';
import { SeriesData, BlockDaysElement } from 'app/shared/models/sliicer-data';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SliicerService } from 'app/shared/services/sliicer.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { StatusCodeService } from 'app/shared/services/status-code.service';
import { StringUtils } from 'app/shared/utils/string-utils';

import * as Highcharts from 'highcharts';
const Boost = require('highcharts/modules/boost');
Boost(Highcharts);

@Component({
    selector: 'app-rainfall-graph',
    templateUrl: './rainfall-graph.component.html',
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RainfallGraphComponent implements OnInit, OnChanges {
    @Input() public graphFlag: boolean;
    @Input('rainMonitorList') public rainMonitorList: RainfallMonitor[];
    @Input('currentBlockDayOverlays') public currentBlockDayOverlays: BlockDaysElement[];
    @Input('stormEvents') public stormEvents: StormEvent[] = [];
    @Input('start') public start: Date;
    @Input('end') public end: Date;
    @Output('addedOrRemovedBlockDays') public addedOrRemovedBlockDays = new EventEmitter();
    @Output('selectedMonitorChanged') public selectedMonitorChanged = new EventEmitter();
    @ViewChild(GraphComponent) public graphComponentChild: GraphComponent;

    private seriesData$ = new BehaviorSubject<SeriesData>(null);

    public rainFallMonitorChartOption: object = {};
    private yAxisLabel: string;
    private unitOfMeasure = 'in';
    public editBlockDaysValue: boolean;
    public showStormsValue = true;
    public rainMonitorNames: Array<string> = [];
    public seriesIndex: number;
    public colors: Array<string> = [];
    public zoomStatus = false;
    public chart: any;
    public labelDecimal: string;
    private stormIdMap: any = {};

    public constructor(
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private translate: TranslateService,
        private dateutilService: DateutilService,
        private sliicerService: SliicerService,
        private statusCodeService: StatusCodeService
    ) {}

    public saveInstance(chartInstance: any) {
        this.chart = chartInstance;
    }

    public ngOnInit() {
        if (this.rainMonitorList && this.rainMonitorList.length > 0) {
            this.rainMonitorList.forEach((el) => {
                this.rainMonitorNames.push(el.name);
                const color = '#' + Math.random().toString(16).substr(-6);
                this.colors.push(color);
            });
        }
        this.initializeChartSettings();
        const translateKeys: Array<string> = ['LOCATION_DASHBOARD.RAIN_ENTITY'];

        this.translate.get(translateKeys).subscribe((values) => {
            this.yAxisLabel = `${values['LOCATION_DASHBOARD.RAIN_ENTITY']} (${this.unitOfMeasure})`;
        });

        this.buildChartOptions();

        this.statusCodeService.userInfoThemeBS.subscribe((response: boolean) => {
            if (response) {
                StringUtils.setHighChartTheme(this.chart);
            } else {
                StringUtils.setHighchartWhiteTheme(this.chart);
            }
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.currentBlockDayOverlays && changes.currentBlockDayOverlays.currentValue) {
            this.showPlotBands(changes.currentBlockDayOverlays);
        }
        if (changes.stormEvents && changes.stormEvents.currentValue) {
            this.addStorms();
        }
    }

    private seriesCount = 0;
    public addSeries(series: SeriesData) {
        const data = this.chartSeriesForRainData(series, this.seriesCount++);
        this.graphComponentChild.chart.addSeries(data, false);
    }

    public buildSeries(series: SeriesData[]) {
        const metric = this.dateutilService.isCustomerUnitsMetric.getValue();
        const unitOfMeasure = metric ? 'mm' : 'in';
        series.forEach(v => v.plotUnit = unitOfMeasure);

        if (this.graphComponentChild && series.length) {
            series.forEach(v => {
                const existingSeries = this.graphComponentChild.chart.series.find(i => i.name === v.seriesName);

                if (existingSeries) {
                    const data = this.chartSeriesForRainData(v, existingSeries.index);
                    existingSeries.setData(data.data);
                } else {
                    this.addSeries(v);
                }
            });
        }

        this.graphComponentChild.chart.redraw();
    }

    public removeAll() {
        // Refer to this: https://www.highcharts.com/forum/viewtopic.php?t=7048
        while (this.graphComponentChild.chart.series.length) {
            this.graphComponentChild.chart.series[0].remove();
        }
    }

    public editBlockDays(value: boolean) {
        this.editBlockDaysValue = value;
    }

    public showStorms(value: boolean) {
        this.showStormsValue = value;
        if (value) {
            this.addStorms();
        } else {
            this.hideStorms();
        }
    }

    /** Show storm event plot bands. */
    private addStorms() {
        if (
            !this.showStormsValue ||
            !this.graphComponentChild ||
            !this.graphComponentChild.chart ||
            !this.graphComponentChild.chart.xAxis
        ) {
            return;
        }

        this.stormEvents.forEach(
            ({ stormStartTime, stormPeriodLength, recovery1PeriodLength, recovery2PeriodLength }: StormEvent) => {
                if (!this.stormIdMap[stormStartTime]) {
                    this.graphComponentChild.chart.xAxis[0].addPlotBand({
                        id: stormStartTime,
                        from: moment(stormStartTime).valueOf(),
                        to: moment(stormStartTime)
                            .add(stormPeriodLength + recovery1PeriodLength + recovery2PeriodLength, 'minutes')
                            .valueOf(),
                        color: SLI_OCOLOR_STORM_PERIOD,
                    });
                    this.stormIdMap[stormStartTime] = stormStartTime;
                }
            },
        );
    }

    /** Hide storm event plot bands. */
    private hideStorms() {
        const stormIds = Object.keys(this.stormIdMap);

        stormIds.forEach((stormId) => {
            this.graphComponentChild.chart.xAxis[0].removePlotBand(stormId);
        });

        this.stormIdMap = {};
    }

    private renderAllPlotBands() {
        this.currentBlockDayOverlays.forEach((item) => {
            this.graphComponentChild.addPlotBand(item);
        });
    }

    private showPlotBands(change: SimpleChange) {
        if (change.firstChange) {
            // this is easy.. just add everything in current value
            // TODO: is this needed?
            // this.currentBlockDays = change.currentValue;
            this.renderAllPlotBands();
        } else {
            // remove anyting in previous values that are not in current values
            if (change.previousValue && change.previousValue.length > 0) {
                change.previousValue.forEach((item) => {
                    if (change.currentValue.map((x) => x.id).indexOf(item.id) < 0) {
                        this.graphComponentChild.removePlotBand(item.id);
                    }
                });
            }

            // add anything in current values that are not in previous values
            if (change.currentValue && change.currentValue.length > 0) {
                change.currentValue.forEach((item) => {
                    if (change.previousValue.map((x) => x.id).indexOf(item.id) < 0) {
                        this.graphComponentChild.addPlotBand(item);
                    }
                });
            }
        }
    }

    private chartSeriesForRainData(series: SeriesData, index: number) {
        const metric = this.dateutilService.isCustomerUnitsMetric.getValue();
        const unitOfMeasure = metric ? 'mm' : 'in';

        let reading = [];
        if (series.data != null && series.data.length > 0) {
            reading = series.data.map((datum) => [moment(datum.x).valueOf(), datum.y]);
        }
        return {
            color: SLI_COLOR_RAINFALL_MONITORS[index % SLI_COLOR_RAINFALL_MONITORS.length],
            data: reading,
            name: series.seriesName,
            plotUnit: unitOfMeasure
        };
    }

    /**
     * Emit a set of days that the user has clicked dragged over on the graph.
     * Dates emitted are set to the beginning of the day and are inclusive of
     * both start and end dates.
     * @param minDate timestamp of the minimum x value in the click & drag
     * @param maxDate timestamp of the maximum x value in the click & drag
     * TODO: WPS - Write tests that we are emitting the correct dates based
     *       on given inputs.
     */
    private selectPointsByDrag(minDate: number, maxDate: number) {
        let current = moment(minDate).utc().startOf('date');
        const max = moment(maxDate).utc().startOf('date');

        const addedDates = [];
        while (current <= max) {
            addedDates.push(current.valueOf());
            current = current.add(1, 'day');
        }
        this.addedOrRemovedBlockDays.emit(addedDates);
    }

    private buildChartOptions() {
        const self = this;
        
        const tooltipTimeFormat = this.dateutilService.getTimeFormat().replace(':ss', '');
        const valueDecimal = this.dateutilService.isCustomerUnitsMetric.getValue() ? 1 : 2;

        this.rainFallMonitorChartOption = {
            boost: {
                enable: true,
                seriesThreshold: 3
            },
            chart: {
                type: 'column',
                pointRange: 0,
                ignoreHiddenSeries: false,
                events: {
                    click: (event) => {
                        if (this.editBlockDaysValue) {
                            self.selectPointsByDrag(event.xAxis[0].value, event.xAxis[0].value);
                            return false;
                        }
                    },
                    selection: (event) => {
                        if (this.editBlockDaysValue) {
                            self.selectPointsByDrag(event.xAxis[0].min, event.xAxis[0].max);
                            return false;
                        } else if (
                            typeof event.min === 'undefined' &&
                            typeof event.max === 'undefined' &&
                            event.target &&
                            event.target.xAxis.length > 0
                        ) {
                            const xAxis = event.target.xAxis[0];
                            xAxis.update({
                                minRange: 3600 * 1000,
                            });
                        }
                    },
                },
                zoomType: 'x',
            },
            yAxis: {
                title: {
                    text: this.yAxisLabel,
                },
                opposite: false,
                reversed: this.graphFlag,
                endOnTick: true,
                showLastLabel: true,
                startOnTick: true,
                labels: {
                    format: this.labelDecimal,
                },
            },

            xAxis: {
                ordinal: false,
                labels: {
                    style: {
                        fontSize: '10px',
                    },
                    maxStaggerLines: 3,
                    rotation: -70,
                    formatter: function() {
                        const { min, max } = this.axis.getExtremes();
                        
                        const includeYearsInDateFormat = new Date(min).getFullYear() !== new Date(max).getFullYear();
                        const xAxisDateFormat = self.sliicerService.getGraphDateFormat(includeYearsInDateFormat);
                        const noYearDateformat = self.sliicerService.getGraphDateFormat(false);
                        const timeFormat = self.sliicerService.getGraphTimeFormat();

                        const oneDay = 1000 * 60 * 60 * 24;

                        // for zoomed graph we need to show hours format, for weekly/monthly date span we show day and the month, for year long we display the year also
                        if (max - min < (oneDay * 4)) {
                            return Highcharts.dateFormat(timeFormat, this.value);
                        } else if (max - min > (oneDay * 4) && !includeYearsInDateFormat) {
                            return Highcharts.dateFormat(noYearDateformat, this.value);
                        }
                        
                        return Highcharts.dateFormat(xAxisDateFormat, this.value);
                    }
                },
                endOnTick: true,
                startOnTick: true,
                type: 'datetime',
                min: moment(this.start).valueOf(),
                max: moment(this.end).valueOf(),
                dateTimeLabelFormats: this.sliicerService.dateFormatFullStudy(),
                events: {
                    afterSetExtremes: function (event) {
                        const min = event.min;
                        const max = event.max;
                        const interval = max - min;
                        const intervalDay = Math.floor(interval / 86400000);
                        if (intervalDay <= 7) {
                            this.chart.update({
                                plotOptions: {
                                    column: {
                                        pointRange: 100000000,
                                        pointPadding: 0.3,
                                        groupPadding: 1,
                                        pointWidth: 3,
                                    },
                                },
                            });
                        } else {
                            this.chart.update({
                                plotOptions: {
                                    column: {
                                        groupPadding: 0,
                                        pointWidth: 3,
                                        borderWidth: 0,
                                        shadow: false,
                                    },
                                },
                            });
                        }
                    },
                },
            },
            minRange: 30 * 24 * 3600 * 1000,
            exporting: { enabled: false },
            plotOptions: {
                column: {
                    groupPadding: 0,
                    pointWidth: 3,
                    borderWidth: 0,
                    shadow: false,
                    animation: false,
                },
                series: {
                    findNearestPointBy: 'x',
                    dataGrouping: {
                        enabled: false,
                    },
                    showInNavigator: true,
                    marker: {
                        enabled: false,
                    },
                    events: {
                        legendItemClick: function (event) {
                            const name = event.target.name;
                            self.selectedMonitorChanged.emit(name);
                        },
                    },
                },
            },
            tooltip: {
                valueDecimals: this.unitOfMeasure === 'mm' ? 1 : 2,
                formatter: function () {
                    const xAxisDateFormat = self.sliicerService.getGraphDateFormat(false);
                    const units = this.point.series.userOptions.plotUnit.toLowerCase();
                    const decimals = (units === 'mgd' || units === 'cfs') ? 3 : valueDecimal;

                    const yPoint = Number(this.point.y).toFixed(decimals);
                    // tslint:disable-next-line: max-line-length
                    const dateX = new Date(this.point.x).getTime();
                    const date =
                        Highcharts.dateFormat(xAxisDateFormat, dateX)
                        + ', '
                        + moment(dateX).format(tooltipTimeFormat);

                    const prefix = `${date}<br/>`;

                    return `${prefix}<span style ='color:${this.point.series.color}'>\u25CF</span> ${this.point.series.name} : <b>
                        ${yPoint} </b> ${this.point.series.userOptions.plotUnit}`;
                },
                positioner(labelWidth: number, labelHeight: number, point: { plotX: number; plotY: number }) {
                    const horizontalOffset = 72;
                    const verticalOffset = 1;

                    return {
                        x: horizontalOffset,
                        y: verticalOffset,
                    };
                },
                split: false, // for some reason without this option (although it's false by default) the positioner doesn't work
                shape: 'rect', // make sure the line (present for the default type 'callout') is not shown
            },

            navigator: {
                adaptToUpdatedData: false,
                enabled: false,
            },
            scrollbar: {
                enabled: false,
            },
            rangeSelector: {
                enabled: false,
            },
            colors: this.colors,
        };
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    private initializeChartSettings() {
        const isMetric = this.dateutilService.isCustomerUnitsMetric.getValue();
        this.labelDecimal = isMetric ? '{value:.1f}' : '{value:.2f}';
        this.unitOfMeasure = isMetric ? 'mm' : 'in';
    }
}
