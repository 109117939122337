export class ChartLegend {
    public enabled: boolean;
    public layout: string;
    public align: string;
    public verticalAlign: string;
    public title: string;
    constructor() {
        this.enabled = true;
        this.layout = 'horizontal';
        this.align = 'right';
        this.verticalAlign = 'bottom';
        this.title = '';
    }
}

export interface HydroGraph {
    series: Array<string>;
    data: Array<any>;
    reportSummary?: Array<any>;
    siteData?: Array<any>;
}

export class ChartProperty {
    public chartId: any;
    public chartTitle = '';
    public chartType?: string;
    public chartEvents?: any = {};
    public chartLegend?: ChartLegend = new ChartLegend();
    public showZoom = true;
    public showRangeSelector?: boolean;
    public showCredits = false;
    public chartXAxis: any;
    public chartYAxis: any;
    public chartRangeSelectorOption?: any;
    public chartToolTipOption: any;
    public chartSeriesData?: any;
    public chartPlotOptions: any;
    public enableExport = false;
    public showNavigator = true;
    public showCustomLegend = false;
    public chartCustomLegend: any;
    public chartScrollBarOptions?: any;
    constructor() {
        this.showRangeSelector = false;
        this.chartType = 'line';
    }
}
