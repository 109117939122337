<div id="qvsi-group-dialog">
    <app-loader [isLoading]="isLoading"></app-loader>
    <!-- add graph header -->
    <div
        class="popupHeader"
        mat-dialog-title
    >
        <span>{{ 'SLIICER.FLOW_TAB.QvsIGroup.TITLE' | translate }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <!-- content -->
    <div mat-dialog-content>
        <form #formhandle="ngForm">
        <div>
            <mat-form-field>
                <mat-select
                    placeholder="{{ 'SLIICER.FLOW_TAB.QvsIGroup.GROUPS' | translate }}"
                    name="groups"
                    matNativeControl
                    [disabled]="!qvsIConfigurations.length || (selectedQvsIConfiguration  && !selectedQvsIConfiguration.name) || duplicatedFilterName"
                    [(ngModel)]="selectedQvsIConfiguration"
                >
                    <mat-option
                        *ngFor="let config of qvsIConfigurations"
                        [value]="config"
                    >
                        {{ config.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <span class="custom-flat-button">
                <button
                    class="mat-button"
                    (click)="deleteGroup()"
                    [disabled]="(selectedQvsIConfiguration  && !selectedQvsIConfiguration.name) || !selectedQvsIConfiguration"
                >
                    {{ 'SLIICER.FLOW_TAB.QvsIGroup.DELETE' | translate }}
                </button>
            </span>
            <span class="custom-flat-button">
                <button
                    [disabled]="(selectedQvsIConfiguration  && !selectedQvsIConfiguration.name)  || duplicatedFilterName"
                    class="mat-button"
                    (click)="newGroup()"
                >
                    {{ 'SLIICER.FLOW_TAB.QvsIGroup.NEW' | translate }}
                </button>
            </span>
        </div>
        <div
            *ngIf="selectedQvsIConfiguration"
            class="description-text"
        >{{ selectedQvsIConfiguration.description }} </div>
        <div
            *ngIf="selectedQvsIConfiguration"
            class="custom-error"
        >
            <div>
                <mat-form-field class="full-width">
                    <input
                        matInput
                        [ngModel]="selectedQvsIConfiguration.name"
                        (input)="userInput()"
                        (ngModelChange)="selectedQvsIConfiguration.name = $event; checkName()"
                        [name]="NAME_FIELD"
                        type="name"
                        placeholder="{{ 'SLIICER.FLOW_TAB.QvsIGroup.GROUP_NAME' | translate }}"
                        required
                    />
                </mat-form-field>

                <div class="custom-error-message">
                    <span *ngIf="duplicatedName">name is required can't be empty or duplicated.</span>
                </div>
            </div>
            <div>
                <mat-form-field class="full-width">
                    <input
                        matInput
                        [ngModel]="selectedQvsIConfiguration.description"
                        (input)="userInput()"
                        (ngModelChange)="selectedQvsIConfiguration.description = $event; checkDescription()"
                        name="desc"
                        type="name"
                        placeholder="{{ 'SLIICER.FLOW_TAB.QvsIGroup.GROUP_DESCRIPTION' | translate }}"
                    />
                </mat-form-field>
            </div>
        </div>
        <div
            *ngIf="selectedQvsIConfiguration"
            class="config"
        >
            <div
                *ngFor="let filter of selectedQvsIConfiguration.groups; let index = index"
                class="series-border"
            >
                <div>
                    <mcc-color-picker
                        usedSizeColors="6"
                        [selectedColor]="filter.color"
                        (selected)="selectedColorPicker($event, index)"
                    ></mcc-color-picker>
                    &nbsp;
                    <mat-form-field class="top">
                        <input
                            matInput
                            [(ngModel)]="filter.name"
                            (input)="userInput()"
                            (ngModelChange)="checkFilterUpdate()"
                            [name]="'qviname'+index"
                            type="name"
                            placeholder="{{ 'SLIICER.FLOW_TAB.QvsIGroup.FILTER_NAME' | translate }}"
                            required
                        />
                    </mat-form-field>
                    <mat-icon
                        matTooltip="{{ 'COMMON.DELETE_BUTTON' | translate }}"
                        matTooltipPosition="left"
                        (click)="deleteGroupFilter(filter)"
                    >delete</mat-icon>

                </div>
                <div *ngFor="let query of filter.rawQuery; let i = index">
                    <mat-form-field class="margin-r-15">
                        <mat-select
                            placeholder="Variable"
                            [(ngModel)]="query.variable"
                            [name]="'variable'+index+'_'+i"
                            (ngModelChange)="groupSelected(filter)"
                            required
                        >
                            <mat-option
                                *ngFor="let filterOption of filterOptions"
                                [value]="filterOption"
                            >{{filterOption}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="margin-r-15">
                        <!-- #32479 temporary replace operations dropdown with disabled "=" option -->
                        <input
                            matInput
                            placeholder="Compare"
                            [name]="'compare'+index+'_'+i"
                            [(ngModel)]="query.compare"
                            (ngModelChange)="groupSelected(filter)"
                            disabled
                        />
                        <!-- <mat-select
                            placeholder="Compare"
                            [name]="'compare'+index+'_'+i"
                            [(ngModel)]="query.compare"
                            (ngModelChange)="groupSelected(filter, query)"
                            required
                        >
                            <mat-option value="=">=</mat-option>
                            <mat-option value="&gt;">&gt;</mat-option>
                            <mat-option value="&lt;">&lt;</mat-option>
                        </mat-select> -->
                    </mat-form-field>
                    <mat-form-field
                        class="margin-r-15"
                        *ngIf="query.variable === 'Year'"
                    >
                        <mat-select
                            placeholder="Value"
                            [name]="'yearvalue'+index+'_'+i"
                            [(ngModel)]="query.value"
                            (ngModelChange)="groupSelected(filter)"
                            required
                        >
                            <mat-option
                                *ngFor="let year of years"
                                [value]="year"
                            >{{year}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field
                        class="margin-r-15"
                        *ngIf="query.variable === 'Season'"
                    >
                        <mat-select
                            placeholder="Value"
                            [(ngModel)]="query.value"
                            [name]="'seasonvalue'+index+'_'+i"
                            (ngModelChange)="groupSelected(filter)"
                            required
                        >
                            <mat-option
                                *ngFor="let season of availableSeasons"
                                [value]="season"
                            >{{season}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field
                        class="margin-r-15"
                        *ngIf="query.variable === 'Regime'"
                    >
                        <mat-select
                            placeholder="Value"
                            [name]="'regimevalue'+index+'_'+i"
                            [(ngModel)]="query.value"
                            (ngModelChange)="groupSelected(filter)"
                            required
                        >
                            <mat-option
                                *ngFor="let regime of regimes"
                                [value]="regime.name"
                            >{{regime.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <span class="custom-flat-button or">
                        <button
                            class="mat-button"
                            (click)="addGroupFilterSeries(LOGICAL_CONDITION.OR, query, i, index)"
                        >
                            {{ 'SLIICER.FLOW_TAB.QvsIGroup.OR' | translate }}
                        </button>
                    </span>
                    <span class="custom-flat-button and">
                        <button
                            class="mat-button"
                            (click)="addGroupFilterSeries(LOGICAL_CONDITION.AND, query, i, index)"
                        >
                            {{ 'SLIICER.FLOW_TAB.QvsIGroup.AND' | translate }}
                        </button>
                    </span>
                    <button mat-icon-button [disabled]=" i === 0 && filter.rawQuery.length === 1">
                        <mat-icon
                            matTooltip="{{ 'COMMON.DELETE_BUTTON' | translate }}"
                            matTooltipPosition="left"
                            (click)="deleteGroupFilterSeries(i, index)"
                            [ngClass]="{'disabled': i === 0 && filter.rawQuery.length === 1}"
                        >delete</mat-icon>
                    </button>
                    <div
                        class="conditional"
                        [ngClass]="{'or': query.conditional === LOGICAL_CONDITION.OR}"
                    >
                        {{query.conditional}}
                    </div>
                </div>
                <div class="pad-b-10">{{filter.query}}</div>
            </div>
        </div>
        </form>
    </div>
    <div mat-dialog-actions>
        <div class="flex-container">
            <div class="custom-flat-button">
                <button
                    [disabled]="!qvsIConfigurations.length || ((selectedQvsIConfiguration && !selectedQvsIConfiguration.name) || duplicatedFilterName)"
                    class="mat-button"
                    (click)="newGroupFilter()"
                >
                    {{ 'SLIICER.FLOW_TAB.QvsIGroup.NEWGROUP' | translate }}
                </button>
            </div>
            <div class="app-flex-filler"></div>
            <div class="custom-flat-button">
                <button
                    class="mat-button"
                    (click)="close()"
                >
                    {{ 'SLIICER.FLOW_TAB.QvsIGroup.CLOSE' | translate }}
                </button>
            </div>
            <div class="custom-flat-button">
                <button
                    [disabled]="formhandle.invalid || duplicatedName || !updated || (!qvsIConfigurations.length && !updated) || duplicatedFilterName || (selectedQvsIConfiguration  && !selectedQvsIConfiguration.name)"
                    class="mat-button"
                    (click)="save()"
                >
                    {{ 'SLIICER.FLOW_TAB.QvsIGroup.SAVE' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
