import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { UsersService } from 'app/pages/admin/users.service';
import { MultiSelectAutoCompleteComponent } from 'app/shared/components/multi-select/multi-select-auto-complete/multi-select-auto-complete.component';
import {
  customerQueryParam, DdfTable, activeInactiveLocationQueryParam
} from 'app/shared/models/customer';
import { Locations } from 'app/shared/models/locations';
import { Selectable } from 'app/shared/models/selectable';
import { OrderByPipe } from 'app/shared/pipes/order-by-pipe';
import { CustomerService } from 'app/shared/services/customer.service';
import { DateutilService } from 'app/shared/services/dateutil.service';
import { SnackBarNotificationService } from 'app/shared/services/snack-bar-notification.service';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { rainFallStormsFreq, rainFallStormsFreqReport, rainFallStormsFreqReportResponse } from '../rainfall-freq.models';
import { RainfallFreqService } from '../rainfall-freq.service.ts.service';
import * as moment from 'moment';

@Component({
  templateUrl: './rain-fall-frequency-overview.component.html',
  styleUrls: ['./rain-fall-frequency-overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RainFallFrequencyOverviewComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) public rainFallPaginator: MatPaginator;
  @ViewChild(MatSort) public matTableSort: MatSort;
  @ViewChild(MultiSelectAutoCompleteComponent) public locationSelector: MultiSelectAutoCompleteComponent;
  private subscriptions = new Array<Subscription>();
  public rainFallLoading: boolean;
  public showrainFallReport: boolean;
  public customerID: number;
  public includeInactiveLocations: boolean;
  public filterLocations: Array<Selectable> = [];
  public storms: Array<rainFallStormsFreq> = [];
  public selectedStorm: string;


  public dateFormat: string;
  public startDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7, 0, 0, 0);
  public endDate: Date = new Date();

  public pageSize = 10;
  public pageIndex = 0;

  public unit: string;
  public unithr: string;

  // translation variables
  public apiErrorMessage: string;
  public errorActionTitle: string;
  public selectedLocationsArr: Selectable[] = [];
  public errorData: number;

  public reportDataSource: MatTableDataSource<rainFallStormsFreqReport>;
  public tableColumns = ['location', 'start', 'end', 'hours', 'minutes', 'total', 'avg', 'one', 'two', 'five', 'ten', 'twentyfive', 'fifty', 'onehundred', 'twohundred', 'fivehundred', 'thousand', 'freq'];
  public preSelected: Selectable[];
  public data: rainFallStormsFreqReport[];
  customDateFormat: string;
  customerDateFormat: string;
  customDateFormatCopy: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private customerService: CustomerService,
    private dateutilService: DateutilService,
    private userService: UsersService,
    private notificationService: SnackBarNotificationService,
    private translateService: TranslateService,
    private rainfallFreqService: RainfallFreqService,
    private uiUtilService: UiUtilsService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.errorData = 0;
    this.showrainFallReport = false;
    this.storms = [];
    this.selectedStorm = ''
    this.filterLocations = [];
    this.data = [];
    this.uiUtilService.safeChangeDetection(this.cdr);
    const customerIDSubscription = this.activatedRoute.queryParamMap.subscribe((params: ParamMap) => {
      // get updated customer id
      this.customerID = Number(params.get(customerQueryParam));
      this.includeInactiveLocations = Boolean(params.get(activeInactiveLocationQueryParam));

      // set default page size 10
      if (this.rainFallPaginator) {
        this.rainFallPaginator.pageSize = 10;
        this.rainFallPaginator.pageIndex = 0;
      }

      this.subscriptions.push(this.customerService.getCustomerById(this.customerID).subscribe(() => {
        this.applyTranslations();
      }));

      // subscribe to changes in dateFormat
      this.subscriptions.push(this.dateutilService.dateFormat.subscribe(() => {
        // Getting customer dateformat from dateUtil Service
        this.customerDateFormat =
          this.dateutilService.getFormat() + ' ' + this.dateutilService.getTimeFormatWithoutSeconds();
        const dateFormat = this.dateutilService.getFormat();
        const is12HourFormat = this.dateutilService.timeFormat.getValue() !== 'hh:mm:ss';
        this.customDateFormat = is12HourFormat ? `${dateFormat.toUpperCase()}, ${'hh:mm a'}` : `${dateFormat.toUpperCase()}, ${'HH:mm'}`;
        this.customDateFormatCopy = is12HourFormat ? `${dateFormat}, ${'hh:mm a'}` : `${dateFormat}, ${'HH:mm'}`;
      }));

      this.subscriptions.push(this.customerService.getDepthDuration(this.customerID).subscribe((result: DdfTable) => {
        if (result.id) {
          // Fetch the locations for selected customer
          const observableLocation = this.userService.getRainLocations(this.customerID,this.includeInactiveLocations);

          const makeApiCallSubscription = observableLocation.subscribe(
            (result: Array<Locations>) => {
              if (!result) {
                this.errorData = 2;
                this.showrainFallReport = true;
                return;
              }

              this.bindLocations(result);

              this.loadStorms();
              this.uiUtilService.safeChangeDetection(this.cdr);

            },
            () => {
              this.notificationService.raiseNotification(this.apiErrorMessage, this.errorActionTitle, {
                panelClass: 'custom-error-snack-bar',
              }, false);
            },
          );
          this.subscriptions.push(makeApiCallSubscription);
        } else {
          this.storms = [];
          this.selectedStorm = ''
          this.filterLocations = [];
          this.errorData = 3;
          this.showrainFallReport = true;
          this.uiUtilService.safeChangeDetection(this.cdr);
        }
      }));




    });
    this.subscriptions.push(customerIDSubscription);

  }

  private loadStorms() {
    this.errorData = 0;
    this.showrainFallReport = false;
    this.storms = [];
    this.selectedStorm = ''
    this.uiUtilService.safeChangeDetection(this.cdr);

    const params = {
      cid: this.customerID,
      start: this.startDate.toLocaleString('en-Us'),
      end: this.endDate.toLocaleString('en-Us')
    }

    this.subscriptions.push(this.rainfallFreqService.getRainFalls(params).subscribe((result: Array<rainFallStormsFreq>) => {
      if (!result.length) {
        this.errorData = 1;
        this.showrainFallReport = true;
        this.uiUtilService.safeChangeDetection(this.cdr);
        return
      }
      this.storms = result.sort((a, b) => {
        return this.compare(moment(a.start, this.customDateFormat).format('x'), moment(b.start, this.customDateFormat).format('x'), true);
      })
      this.selectedStorm = result[0].guid
      this.loadRainFallTable(this.selectedStorm);
    }))
  }

  private loadRainFallTable(guid) {
    this.data = [];
    this.errorData = 0;
    this.showrainFallReport = false;
    this.uiUtilService.safeChangeDetection(this.cdr);
    const params = {
      cid: this.customerID,
      guid: guid,
      lids: this.selectedLocationsArr.map(x => {
        if (x.isChecked) {
          return x.id
        }
      }).filter(Number)
    }
    if (!params.lids.length) {
      params.lids = this.filterLocations.map(x => {
        return x.id
      }).filter(Number)
    }

    this.subscriptions.push(this.rainfallFreqService.getRainFallReport(params).subscribe((result: rainFallStormsFreqReportResponse) => {
      this.unit = result.totalRainfallUnit;
      this.unithr = result.averageIntensityUnit;
      this.data = result.locations;
      this.reportDataSource = new MatTableDataSource(result.locations);
      this.reportDataSource.paginator = this.rainFallPaginator;
      this.reportDataSource.sort = this.matTableSort;
      this.showrainFallReport = true;
      this.iterator();
      this.uiUtilService.safeChangeDetection(this.cdr);
    }))
  }

  private bindLocations(locations: Array<Locations>) {
    this.filterLocations = [];

    if (locations && locations.length > 0) {
      this.filterLocations.push(
        ...locations.map((m) => {
          return <Selectable>{
            id: m.locationId,
            name: m.locationName,
            isChecked: true
          };
        }),
      );
      this.filterLocations = new OrderByPipe().transform(this.filterLocations, 'name', false);
      this.selectedLocationsArr = this.filterLocations;
      this.locationSelector.items = this.filterLocations;
      this.locationSelector.preselectedItems = [...this.filterLocations];
      this.locationSelector.updateOnSelectedChange();
    }
  }

  public sortData(sort: Sort) {
    this.pageIndex = 0;
    this.rainFallPaginator.pageIndex = 0;
    const data = this.data.slice();
    if (!sort.active || sort.direction === '') {
      this.reportDataSource.data = data.splice(0, this.pageSize);
      return;
    }

    this.reportDataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'location': return this.compare(a.name, b.name, isAsc);
        case 'total': return this.compare(a.total, b.total, isAsc);
        case 'avg': return this.compare(a.avgint, b.avgint, isAsc);
        case 'freq': return this.compare(a.rtnfreq, b.rtnfreq, isAsc);
        default: return 0;
      }
    }).splice(0, this.pageSize);
  }

  private compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  public pageChange(event) {
    if (event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
      if (this.rainFallPaginator) {
        this.rainFallPaginator.pageIndex = this.pageIndex;
        this.rainFallPaginator.pageSize = this.pageSize;
      }
      this.iterator();
    }
  }

  private iterator() {
    const end = (this.pageIndex + 1) * this.pageSize;
    const start = this.pageIndex * this.pageSize;
    const part = this.data.slice(start, end);
    this.reportDataSource.data = part;
  }

  public updateDateRange($event) {
    this.errorData = 0;
    this.startDate = $event.startDate.currentValue;
    this.endDate = $event.endDate.currentValue;
  }

  public datepickerOnClose() {
    this.errorData = 0;
    this.loadStorms();
    this.uiUtilService.safeChangeDetection(this.cdr);
  }

  public selectStorm(guid: string) {
    this.errorData = 0;
    this.showrainFallReport = false;
    this.selectedStorm = guid;
    this.loadRainFallTable(this.selectedStorm);
  }

  public selectedLocations(event: Array<Selectable>) {
    this.errorData = 0;
    this.showrainFallReport = false;
    this.selectedLocationsArr = event;
    this.loadRainFallTable(this.selectedStorm);
  }

  private applyTranslations() {
    const translateKeys: Array<string> = ['COMMON.API_FAILURE_MESSAGE', 'COMMON.ERROR_COLUMN_TITLE'];

    this.translateService.get(translateKeys).subscribe((values) => {
      this.apiErrorMessage = values['COMMON.API_FAILURE_MESSAGE'];
      this.errorActionTitle = values['COMMON.ERROR_COLUMN_TITLE'];
    });
  }

  public downloadCSV() {
    const csvData = new Array<Object>();
    const csvHeaders = new Array<string>();
    csvHeaders.push('Location');

    if (this.data && this.data.length > 0) {
      this.data.forEach((location, dataIndex) => {
        const newItem = new Array<string>();
        newItem.push(location.name);

        this.tableColumns.forEach((value) => {
          if (value !== 'location' && !dataIndex) {
            if (value === 'total') {
              csvHeaders.push(`total rain (${this.unit})`)
            } else if (value === 'avg') {
              csvHeaders.push(`avg intensity (${this.unithr})`)
            } else if (value === 'freq') {
              csvHeaders.push(`Return Frequency`)
            } else {
              let val = value
              if (['one', 'two', 'five', 'ten', 'twentyfive', 'fifty', 'onehundred', 'twohundred', 'fivehundred', 'thousand'].includes(val)) {
                val = `${val} (${this.unithr})`
              }
              csvHeaders.push(val)
            }
          }
          switch (value) {
            case 'location': break;
            case 'start':
              newItem.push(location.start.toString())
              break;
            case 'end':
              newItem.push(location.end.toString())
              break;
            case 'hours':
              newItem.push(location.hrs.toString())
              break;
            case 'minutes':
              newItem.push(location.mins.toString())
              break;
            case 'total':
              newItem.push(location.total.toString())
              break;
            case 'avg':
              newItem.push(location.avgint.toString())
              break;
            case 'one':
              newItem.push(location.int1.toString())
              break;
            case 'two':
              newItem.push(location.int2.toString())
              break;
            case 'five':
              newItem.push(location.int5.toString())
              break;
            case 'ten':
              newItem.push(location.int10.toString())
              break;
            case 'twentyfive':
              newItem.push(location.int25.toString())
              break;
            case 'fifty':
              newItem.push(location.int50.toString())
              break;
            case 'onehundred':
              newItem.push(location.int100.toString())
              break;
            case 'twohundred':
              newItem.push(location.int200.toString())
              break;
            case 'fivehundred':
              newItem.push(location.int500.toString())
              break;
            case 'thousand':
              newItem.push(location.int1000.toString())
              break;
            case 'freq':
              newItem.push(location.rtnfreq.toString())
              break;
            default: break;
          }
        });

        csvData.push(newItem);
      });
      const storm = this.storms.find(a => a.guid === this.selectedStorm);

      const options = {
        showLabels: true,
        headers: csvHeaders,
        // tslint:disable-next-line:max-line-length
        title: `Rainfall Frequency Report, ${storm.start} , ${storm.end}`,
        showTitle: true,
      };

      const result = new AngularCsv(csvData, `Rainfall Frequency Report`, options);
    }
  }

  public ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

}
