<div id="resetPasswordDialog">
    <div>
        <div class="popupHeader" mat-dialog-title>
            <span *ngIf="isForgotPassword">Forgot Password</span>
            <span *ngIf="!isForgotPassword">Reset Password</span>
            <span class="app-flex-filler"></span>
        </div>
        <form #resetPasswordForm="ngForm">
            <div mat-dialog-content>
                <app-loader [isLoading]="isLoading"></app-loader>
                <div [hidden]="!showUserInfo">
                    <p class="custom-header">
                        <span class="header-text"> Please enter your User Name or Registered Email ID. </span>
                        <span class="sub-header-text">
                            We will send a verification code to your registered email.
                        </span>
                    </p>

                    <mat-form-field class="full-width top">
                        <input
                            matInput
                            placeholder="User Name"
                            [(ngModel)]="userName"
                            name="userName"
                            (ngModelChange)="validateUserDetails()"
                        />
                    </mat-form-field>
                    <p class="or-devider">- OR -</p>
                    <div class="custom-error">
                        <mat-form-field class="full-width">
                            <input
                                matInput
                                placeholder="Email Id"
                                name="emailId"
                                [(ngModel)]="emailId"
                                #emailInput
                                [pattern]="emailPattern"
                                (ngModelChange)="validateUserDetails()"
                            />
                        </mat-form-field>
                        <div class="custom-error-message" *ngIf="emailInput?.errors?.pattern">
                            Please enter a valid email address.
                        </div>
                    </div>
                    <re-captcha
                        (resolved)="resolved($event)"
                        siteKey="6LfbMLsUAAAAAEbgbgGkWC7RlPYDPqia8ows041E"
                    ></re-captcha>
                </div>
                <div>
                    <div *ngIf="showVerificationCode">
                        <p class="custom-header">
                            <span class="header-text"> Please enter your Verification Code. </span>
                        </p>
                        <mat-form-field class="full-width top">
                            <input
                                matInput
                                placeholder="Verification Code"
                                maxlength="6"
                                [(ngModel)]="emailTokenCode"
                                name="emailTokenCode"
                                (ngModelChange)="validateEmailTokenCode()"
                            />
                        </mat-form-field>
                    </div>
                    <div *ngIf="showChangePassword || !isForgotPassword">
                        <ul>
                            <li><b>Minimum Password Length</b> - All passwords should be a minimum of 8 characters</li>
                            <li>
                                <b>Restrict Common Passwords</b> - Users should not be able to use any word from this
                                <a
                                    target="_blank"
                                    href="https://github.com/danielmiessler/SecLists/blob/master/Passwords/Common-Credentials/10k-most-common.txt"
                                    >List</a
                                >
                            </li>
                            <li>
                                <b>Required Characters</b>
                                <ul>
                                    <li>
                                        <b>Upper Case</b> - All passwords should require at least 1 Upper case character
                                    </li>
                                    <li><b>Digits</b> - All passwords should require at least 1 digit</li>
                                    <li>
                                        <b>Non-AlphaNumeric Character</b> - All passwords should require at least 1
                                        non-alpha-numeric character
                                    </li>
                                </ul>
                            </li>
                            <li><b>Expiration Time Frame</b> - All passwords should expire in 90 days</li>
                            <li>
                                <b>Non-Repeat Count</b> - Users should not be allowed to repeat use of a password within
                                8 tries.
                            </li>
                            <li>
                                <b>Not part of username</b> - Passwords should not contain any part of user's username,
                                first name, last name or the part of the email address that is prefix to the @ symbol.
                            </li>
                        </ul>
                        <p class="custom-header">
                            <span class="header-text"> Please enter a New Password.</span>
                        </p>
                        <p *ngIf="!isForgotPassword">
                            Your password has expired. You are required to update your password before you may continue
                            into PRISM.
                        </p>
                        <mat-form-field class="full-width top">
                            <input
                                [type]="hide ? 'password' : 'text'"
                                matInput
                                placeholder="New Password"
                                minlength="8"
                                #newPasswordInput="ngModel"
                                required
                                [(ngModel)]="newPassword"
                                (input)="clearError()"
                                name="newPassword"
                                (ngModelChange)="passwordChange()"
                            />
                            <mat-error
                                class="mat-error"
                                *ngIf="newPasswordInput.errors?.required && newPasswordInput.touched"
                            >
                                New Password is required
                            </mat-error>
                        </mat-form-field>
                        <div *ngIf="newPasswordInput.dirty || newPasswordInput.touched">
                            <mat-error class="mat-error" *ngIf="!newPasswordformErrors.containLowercasePattern">
                                Password should contain at least one lowercase letter
                            </mat-error>
                            <mat-error class="mat-error" *ngIf="!newPasswordformErrors.containUppercasePattern">
                                Password should contain at least one uppercase letter
                            </mat-error>
                            <mat-error class="mat-error" *ngIf="!newPasswordformErrors.containDigitPattern">
                                Password should contain at least one digit
                            </mat-error>
                            <mat-error class="mat-error" *ngIf="!newPasswordformErrors.containNonWordPattern">
                                Password should contain at least one non alpha numeric character
                            </mat-error>
                            <mat-error class="mat-error" *ngIf="newPasswordInput.hasError('minlength')">
                                New Password should contain 8 characters
                            </mat-error>
                        </div>
                        <mat-form-field class="full-width">
                            <input
                                matInput
                                [type]="hide ? 'password' : 'text'"
                                placeholder="Confirm Password"
                                minlength="8"
                                #confirmPasswordInput="ngModel"
                                required
                                [(ngModel)]="confirmPassword"
                                (input)="clearError()"
                                name="confirmPassword"
                                (ngModelChange)="confirmPasswordVerification()"
                            />
                            <mat-icon matSuffix (click)="hide = !hide">{{
                                hide ? 'visibility' : 'visibility_off'
                            }}</mat-icon>
                            <mat-error
                                class="mat-error"
                                *ngIf="confirmPasswordInput.errors?.required && confirmPasswordInput.touched"
                            >
                                Confirm Password is required
                            </mat-error>
                        </mat-form-field>
                        <div mat-error class="mat-error" *ngIf="isNotSamepassword">
                            New Password and Confirm Password should be same.
                        </div>
                    </div>
                </div>
            </div>
            <div mat-error class="mat-error" *ngIf="resetErrorMessage && Array.isArray(errorMessage)">
                <div *ngFor="let e of errorMessage">{{ e }}</div>
            </div>
            <div mat-error class="mat-error" *ngIf="resetErrorMessage && !Array.isArray(errorMessage)">
                {{ errorMessage }}
            </div>
        </form>
        <div mat-dialog-actions>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button (click)="cancel(false)">CANCEL</button>
            </div>
            <div class="auto-margin" *ngIf="!isForgotPassword">
                <button
                    mat-button
                    [disabled]="
                        !resetPasswordForm.valid ||
                        resetPasswordForm.pristine ||
                        isNotSamepassword ||
                        !isValidPassword()
                    "
                    (click)="resetPassword()"
                >
                    Reset
                </button>
            </div>
            <div class="auto-margin" *ngIf="showChangePassword">
                <button
                    mat-button
                    [disabled]="
                        !resetPasswordForm.valid ||
                        resetPasswordForm.pristine ||
                        isNotSamepassword ||
                        !isValidPassword()
                    "
                    (click)="nonAuthResetPassword()"
                >
                    SAVE
                </button>
            </div>
            <div class="auto-margin" *ngIf="showVerificationCode">
                <button mat-button (click)="verificationCode()" [disabled]="!validEmailTokenCode">NEXT</button>
            </div>
            <div class="auto-margin" *ngIf="showUserInfo">
                <button mat-button (click)="validateUser()" [disabled]="!validRecaptcha || !validUserDetails">
                    RESET
                </button>
            </div>
        </div>
    </div>
</div>
