import { combineLatest, Subscription } from 'rxjs';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AdsPrismGISAddEditComponent } from '../ads-prism-gis-add-edit/ads-prism-gis-add-edit.component';
import { GISService } from 'app/shared/services/gis-service';
import { GISLayerMapping, GisServiceList } from 'app/shared/models/gis-service-list';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import * as converter from 'xml-js';
import { UiUtilsService } from 'app/shared/utils/ui-utils.service';
import { customerEditorQueryParam } from 'app/shared/models/customer';
import { DomOperationUtilsService } from 'app/shared/utils/dom-operation-utils.service';
@Component({
    selector: 'ads-prism-gis-layers',
    templateUrl: './ads-prism-gis-layers.component.html',
    styleUrls: ['./ads-prism-gis-layers.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdsPrismGISLayersComponent implements OnInit, OnDestroy {
    // TODO: Bug #19937 - getCapabilities. Code generated by old team for WMS or WFS service types. They are not used, if will be needed then rewrite this.
    // public mapTypeDisplayNames = { wms: 'WMS', wfs: 'WFS', maps: 'MapServer', feat: 'FeatureServer' };
    public mapTypeDisplayNames = { maps: 'MapServer', feat: 'FeatureServer' };

    public assignedRainGauge = new Array<Location>();
    public searchString = new FormControl();
    public gisDescriptionColumns = [
        'type',
        'url',
        //'authDetails',
        'action',
    ];
    public gisDataSource;
    public gisManholeServiceSource: any;
    public gisManholeServiceId: any;
    public gisManholeServiceId1: any;
    public gisPipeServiceId: any;
    public gisBasinServiceId: any;

    public manholeLayerLists;
    public manholeLayer: string;
    public manholeAttrList;
    public manholeAttr: string;

    public pipeLayerList;
    public pipeLayer: string;
    public pipeAttrList;
    public pipeAttr: string;
    public pipeAttr1: string;

    public basinLayerList;
    public basinLayer: string;

    public customerId = 0;

    public gisLayerMapping: GISLayerMapping[];
    public gisServiceList: GisServiceList;

    private addDialogRef: MatDialogRef<AdsPrismGISAddEditComponent>;
    private subscriptions = new Subscription();
    constructor(
        private dialog: MatDialog,
        private GISServices: GISService,
        private activeRoute: ActivatedRoute,
        private cdr: ChangeDetectorRef,
        private snackBar: MatSnackBar,
        private uiUtilsService: UiUtilsService,
        private domOperationUtilsService: DomOperationUtilsService
    ) {}

    public ngOnInit() {
        this.subscriptions.add(this.domOperationUtilsService.gisDialogOpened.subscribe((shouldOpen: boolean) => {
            if (!this.addDialogRef && shouldOpen) {
                this.addNewGISService();
            } else if (this.addDialogRef && !shouldOpen) {
                this.addDialogRef.close();
                this.addDialogRef = null;
            }
        }));
        // get current query params for customer
        this.customerId = Number(this.activeRoute.snapshot.queryParamMap.get(customerEditorQueryParam));
        // this.gisDataSource = servicedata;
        this.getLayers();
    }

    public getLayers(searchString?: string) {
        this.GISServices.getGISServicesList(this.customerId).subscribe((res: any) => {
            if (res != null) {
                this.gisManholeServiceSource = res;
                this.gisDataSource = res;

                // TODO: Bug #19937 - getCapabilities. Code generated by old team for WMS or WFS service types. They are not used, if will be needed then rewrite this.
                // this.gisLayerMappingLoad();
            }
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
    }

    /*
     * 		Updating GIS services
     */

    public addNewGISService() {
        this.addDialogRef = this.dialog
            .open(AdsPrismGISAddEditComponent, {
                disableClose: true,
                hasBackdrop: false
            });

        this.domOperationUtilsService.gisDialogOpened.next(true);
        this.addDialogRef.afterClosed().subscribe((result) => {
            this.addDialogRef = null;
            this.domOperationUtilsService.gisDialogOpened.next(false);
            this.getLayers();
        });
    }

    public deleteGISService(id: number) {
        this.GISServices.deleteGISservice(this.customerId, id).subscribe((res) => {
            if (res && res['isError']) {
                this.snackBar.open('Error!', 'Technical error', {
                    panelClass: 'custom-error-snack-bar',
                });
            } else {
                this.snackBar.open('Success!', 'Service Deleted.', {
                    duration: 10000,
                    panelClass: 'custom-success-snack-bar',
                });
                this.gisDataSource = this.gisDataSource.filter((o) => o.serviceId !== id);
            }
            
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
    }

    public editGISService(element) {
        this.addDialogRef = this.dialog
            .open(AdsPrismGISAddEditComponent, {
                disableClose: true,
                hasBackdrop: false,
                data: element,
            });

        this.domOperationUtilsService.gisDialogOpened.next(true);
        this.addDialogRef.afterClosed().subscribe((result) => {
            this.addDialogRef = null;
            this.domOperationUtilsService.gisDialogOpened.next(false);
            this.getLayers(); // Re pull layers to make sure you display the updates
        });
    }

    // TODO: Bug #19937 - getCapabilities. Code generated by old team for WMS or WFS service types. They are not used, if will be needed then rewrite this.
    // /**
    //   *Loading Manhole layers based on user selected service
    //  */
    // public manholeLayerload() {
    //     this.layreListload(this.gisManholeServiceId, 'manhole');
    // }
    // /**
    //  * Loading Pipe layers based on selection of service for pipelayer
    //  */
    // public pipeLayerload() {
    //     this.layreListload(this.gisPipeServiceId, 'pipeline');
    // }
    // /**
    //  * Loadin basin layers on service selection.
    //  */
    // public basinLayerload() {
    //     this.layreListload(this.gisBasinServiceId, 'basin');
    // }
    // /**
    //  * Loading Layer list based on service selection.
    //  */
    // public layreListload(service: any, type: string) {
    //     var newlayerlist;
    //     const urltype = service.type;
    //     fetch(service.url + '?request=GetCapabilities', {
    //         method: 'GET',
    //         headers: {
    //             'Content-type': 'application/json',
    //             "Authorization": 'Basic SURFWDprM1IqdjJMeHEjRkQ='
    //         }
    //     })
    //         .then(function (response) {
    //             return response.text();
    //         })
    //         .then(res => {
    //             let dom;
    //             if (typeof DOMParser !== 'undefined') {
    //                 let parser = new DOMParser();
    //                 dom = parser.parseFromString(res, "text/xml");
    //                 let xmaloutput = converter.xml2json(res, { compact: true, spaces: 2 });
    //                 let layerlist;
    //                 if (urltype == 'wms') {
    //                     layerlist = JSON.parse(xmaloutput).WMS_Capabilities.Capability.Layer.Layer;
    //                 }
    //                 else {
    //                     layerlist = JSON.parse(xmaloutput)['wfs:WFS_Capabilities'].FeatureTypeList.FeatureType;
    //                 }
    //                 let layerlists = [];
    //                 layerlist.forEach(layer => {
    //                     var obj = { name: layer.Name._text, title: layer.Title._text };
    //                     layerlists.push(obj);
    //                 });
    //                 switch (type) {
    //                     case 'manhole':
    //                         this.manholeLayerLists = layerlists;
    //                         break;
    //                     case 'pipeline':
    //                         this.pipeLayerList = layerlists;
    //                         break;
    //                     case 'basin':
    //                         this.basinLayerList = layerlists;
    //                         break;
    //                 }
    //                 this.uiUtilsService.safeChangeDetection(this.cdr);
    //             }
    //         });
    // }

    /*
     * Attribute Loading for Layers
     */
    public manholeAttrLoad() {
        this.attrListLoad(this.gisServiceList, this.manholeLayer, 'manhole');
    }

    public pipeAttr1Load() {
        this.attrListLoad(this.gisServiceList, this.pipeLayer, 'pipeattr');
    }

    /*
     * Attribute List loading
     */
    public attrListLoad(service, layer, type) {
        this.GISServices.getAttribueList(service, layer).subscribe((res: any) => {
            const attributes = res.features[0].properties;
            const attrlist = [];
            Object.entries(attributes).forEach(([k, v]) => {
                attrlist.push({ name: k });
            });
            switch (type) {
                case 'manhole':
                    this.manholeAttrList = attrlist;
                    break;
                case 'pipeattr':
                    this.pipeAttrList = attrlist;
                    break;
            }
            this.uiUtilsService.safeChangeDetection(this.cdr);
        });
    }
    public saveGISLayerMappingData() {
        const listLayerMapping = [];
        const manholeItem: GISLayerMapping = {
            id: 0,
            customerId: this.customerId,
            structureType: 'Manhole',
            service: this.gisManholeServiceId.id,
            layer: this.manholeLayer != undefined ? this.manholeLayer : '',
            attribute1: this.manholeAttr != undefined ? this.manholeAttr : '',
            attribute2: '',
        };
        const pileItem: GISLayerMapping = {
            id: 0,
            customerId: this.customerId,
            structureType: 'Pipe',
            service: this.gisPipeServiceId.id,
            layer: this.pipeLayer != undefined ? this.pipeLayer : '',
            attribute1: this.pipeAttr != undefined ? this.pipeAttr : '',
            attribute2: this.pipeAttr1 != undefined ? this.pipeAttr1 : '',
        };
        const basin: GISLayerMapping = {
            id: 0,
            customerId: this.customerId,
            structureType: 'Basin',
            service: this.gisBasinServiceId.id,
            layer: this.basinLayer != undefined ? this.basinLayer : '',
            attribute1: '',
            attribute2: '',
        };

        listLayerMapping.push(manholeItem);
        listLayerMapping.push(pileItem);
        listLayerMapping.push(basin);

        this.gisLayerMapping = listLayerMapping;

        if (this.gisLayerMapping != undefined) {
            this.GISServices.saveGisLayerMapping(this.gisLayerMapping).subscribe((res: any) => {
                this.snackBar.open('Success!!!', 'Services Saved Successfully.', {
                    duration: 10000,
                    panelClass: 'custom-success-snack-bar',
                });
            });
        }
    }
    public compareCategoryObjects(object1: any, object2: any) {
        return object1 && object2 && object1.id == object2.id;
    }

    // TODO: Bug #19937 - getCapabilities. Code generated by old team for WMS or WFS service types. They are not used, if will be needed then rewrite this.
    // public gisLayerMappingLoad() {
    //     combineLatest([
    //         this.GISServices.getGISServicesList(this.customerId),
    //         this.GISServices.getGisLayerMapping(this.customerId)
    //     ]).subscribe(([gisServiceList, res]) => {
    //         // TODO: It can return more then one service - handle it
    //         if (gisServiceList != null) {
    //             this.gisServiceList = gisServiceList[0];
    //         }

    //         if (res != null) {
    //             this.gisLayerMapping = res;
    //             if (this.gisLayerMapping.length > 0) {
    //                 var manholeItem = this.gisLayerMapping.filter(f => f.structureType == 'Manhole');
    //                 var pileItem = this.gisLayerMapping.filter(f => f.structureType == 'Pipe');
    //                 var basinItem = this.gisLayerMapping.filter(f => f.structureType == 'Basin');

    //                 //Layer  Loading
    //                 this.gisDataSource.forEach((srv, i) => {
    //                     if (manholeItem != null && manholeItem.length > 0) {
    //                         if (srv.id == manholeItem[0].service) {
    //                             this.gisManholeServiceId = this.gisDataSource[i];
    //                             this.manholeLayerload();
    //                         }
    //                     }
    //                     if (pileItem != null && pileItem.length > 0) {
    //                         if (srv.id == pileItem[0].service) {
    //                             this.gisPipeServiceId = this.gisDataSource[i];
    //                             this.pipeLayerload();
    //                         }
    //                     }
    //                     if (basinItem != null && basinItem.length > 0) {
    //                         if (srv.id == basinItem[0].service) {
    //                             this.gisBasinServiceId = this.gisDataSource[i];
    //                             this.basinLayerload();
    //                         }
    //                     }
    //                 });

    //                 if (manholeItem != null) {
    //                     this.gisManholeServiceId = manholeItem[0].id;
    //                     this.manholeLayer = manholeItem[0].layer;
    //                     this.manholeAttrLoad();
    //                     this.manholeAttr = manholeItem[0].attribute1;
    //                 }

    //                 // Pile
    //                 if (pileItem != null) {
    //                     this.gisPipeServiceId = pileItem[0].id;
    //                     this.pipeLayer = pileItem[0].layer;
    //                     this.pipeAttr1Load();
    //                     this.pipeAttr = pileItem[0].attribute1;
    //                     this.pipeAttr1 = pileItem[0].attribute2;
    //                 }
    //                 // Basin
    //                 if (basinItem != null) {
    //                     this.basinLayer = basinItem[0].layer;
    //                 }
    //             }
    //         }
    //     });
    // }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
