
        <div class="customDialogPosition">
            <div id="resonForEditId">
                <div class="popupHeaderWithButton" mat-dialog-title>
                    <div class="auto-margin pad-l-15">{{ recalcConfirmationTitle }}</div>
                    <div class="app-flex-filler"></div>
                </div>
                <div mat-dialog-content class="eachTabContent">
                    <p>Select date range to recalculate:</p>
                    <app-date-range-picker
                        [(startDate)]="data.startDate"
                        [(endDate)]="data.endDate"
                        [minDate] = "minDate"
                        (emitDateChanged)="dateChange($event)"
                    ></app-date-range-picker>
                    <div class="flex-container" >
                        <div class="app-flex-filler">
                            <div class="mat-error" *ngIf="errorMsg!=''">
                                {{ errorMsg }}
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="app-flex-filler"></div>
                <div mat-dialog-actions>
                    <div class="app-flex-filler"></div>
                    <div class="auto-margin">
                        <button mat-dialog-close mat-button>{{ 'COMMON.CANCEL_BUTTON' | translate }}</button>
                    </div>
                    <div class="auto-margin">
                        <button [disabled]="minDateError" (click)="submit()" mat-button>Recalculate</button>
                    </div>
                </div>
            </div>
        </div>
