export interface MonitorConfigurationReport {
    customerId?: number;
    details?: MonitorConfigurationDetail[];
    detailsCount?: number;
}

export interface MonitorConfigurationDetail {
    locationName?: string;
    capacity?: number;
    communicationType?: string;
    compositeLocation?: boolean;
    elevation?: number;
    externalVendor? : string;
    height?: number;
    width?: number;
    hydraulicCoefficient?: number;
    isActive?: boolean;
    isOverflow?: boolean;
    latitude?: number;
    longitude?: number;
    locationId?: number;
    locationShape?: string;
    locationType?: string;
    monitorSeries?: string;
    monitoringPointNumber?: number;
    scheduledInterval?: string;
    sensors?: string;
    serialNumber?: string;
    sampleRateNormalSeconds?: number | string; 
    sampleRateFastSeconds ?: number | string;
    locationGroups?: { id: number, name: string }[];
    alarms: MonitorConfigurationAlarmModel
}

interface MonitorConfigurationAlarmModel {
    highDepthThresholdActivation?: string;
    highDepthThreshold?: number;
    highHighThresholdActivation?: string;
    highHighThreshold?: number;
    lowDepthThresholdActivationDate?: string;
    lowDepthThreshold?: number;
}

export interface MonitorConfigLocGroupModel {
    id?: number;
    name?: string;
    locations?: MonitorConfigLocationModel[];
}

export interface MonitorConfigLocationModel {
    id?: number;
    name?: string;
    isChecked?: boolean;
}

export const LocationType = {
    1: 'Physical',
    2: 'Computed',
    3: 'Composite'
};

export const MonitorSeriesModel = {
    1: 'Unknown',
    2: 'FlowAlert',
    3: 'RainAlertII',
    4: 'Gateway',
    5: 'FlowShark',
    6: 'FlowSharkIS',
    7: 'FlowSharkRT',
    8: 'Legacy1502',
    9: 'Legacy1502EM',
    10: 'Legacy1506',
    11: 'Legacy3500',
    12: 'Legacy3500S',
    13: 'Legacy3600',
    14: 'Legacy4000',
    15: 'Legacy4500',
    16: 'Legacy4500S',
    17: 'Legacy5500',
    18: 'Legacy5600',
    19: 'Legacy7510',
    20: 'Legacy7510Plus',
    21: 'Triton',
    22: 'FlowHawk',
    23: 'Legacy3000',
    24: 'Legacy2000',
    25: 'TritonIM',
    26: 'Triton+',
    27: 'RU33',
    28: 'LeakTest',
    29: 'RainAlertIII',
    30: 'Pulse',
    31: 'Echo',
    32: 'Ice3',
    33: 'Trimble',
    34: 'ForeSITE-UL',
    35: 'ForeSITE-Flex'
};

export const CommunicationType = {
    0: 'Default',
    1: 'Landline',
    2: 'DMI',
    3: 'Serial',
    4: 'Wireless',
    5: 'FTP',
    6: 'Bluetooth',
};

export const InstallationShapeType = {
    0: 'Unknown',
    200: 'Round',
    201: 'Rectangle',
    202: 'Elliptical',
    203: 'Egg',
    204: 'Oval',
    205: 'Ovoid',
    206: 'Horseshoe',
    207: 'HorseshoeType1',
    208: 'HorseshoeType2',
    209: 'SemiElliptical',
    210: 'BasketHandle',
    211: 'NonStandard',
    212: 'ManualEntry',
    213: 'Lookup',
    300: 'RectangularSuppressed',
    301: 'RectangularWithEndContractions',
    302: 'Cipoletti',
    303: 'VNotched22pt5Degree',
    304: 'VNotched30Degree',
    305: 'VNotched45Degree',
    306: 'VNotched60Degree',
    307: 'VNotched90Degree',
    308: 'VNotched120Degree',
    309: 'RectangleSuppressedConstantK',
    310: 'RectangleContractedConstantK',
    311: 'TrapezoidalConstantK',
    400: 'Parshall1Inch',
    401: 'Parshall2Inch',
    402: 'Parshall3Inch',
    403: 'Parshall6Inch',
    404: 'Parshall9Inch',
    405: 'Parshall1To8Ft',
    406: 'Parshall10To50Ft',
    500: 'Channel',
    700: 'WetWell',
    800: 'RainGauge',
    900: 'General',
    1000:'Elevation'
};
