<div id="fileViewDialog" class="modal-dialog file-view-container">
    <div class="popupHeader" mat-dialog-title>
        <span> {{ title }}</span>
        <span class="app-flex-filler"></span>
    </div>
    <div class="file-container">
        <mat-dialog-content>
            <app-loader [isLoading]="fileViewLoadingState"></app-loader>
            <div *ngIf="type == 1; else textFile">
                <ng-container *ngIf="showImg">
                    <img [src]="fileContent" />
                </ng-container>
            </div>
            <ng-template #textFile>
                <textarea readonly>{{ fileContent }}</textarea>
            </ng-template>
        </mat-dialog-content>
        <mat-dialog-actions>
            <div class="app-flex-filler"></div>
            <div class="auto-margin">
                <button mat-button mat-dialog-close>{{ 'COMMON.CLOSE' | translate }}</button>
            </div>
            <div class="auto-margin">
                <button mat-button (click)="download()">{{ 'COMMON.DOWNLOAD_BTN' | translate }}</button>
            </div>
        </mat-dialog-actions>
    </div>
</div>
