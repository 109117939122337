import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UiUtilsService } from '../../../shared/utils/ui-utils.service';
import { TelemetryService } from 'app/shared/services/telemetry.service';
import { ExportSetting } from 'app/shared/models/telemetry';
import { AdsPrismVaultLocationExportComponent } from '../vault-location-export/vault-location-export.component';
import { Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
@Component({
    selector: 'app-load-setting',
    templateUrl: './load-setting.component.html',
    styles: [],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadSettingComponent implements OnInit {
    public loadSettingData: ExportSetting[];
    public loadSettingDataSource;
    public allSettings = [];
    private subscriptions = new Array<Subscription>();
    public loadSettingDataSourceColumns = ['name', 'action'];
    public isLoading = true;
    public searchString = new FormControl();
    public noSetting: boolean;

    constructor(
        private dialogRef: MatDialogRef<LoadSettingComponent>,
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private uiUtilsService: UiUtilsService,
        private telemetryService: TelemetryService,
    ) {
        this.searchString.valueChanges
            .pipe(debounceTime(400))
            .pipe(distinctUntilChanged())
            .subscribe((res: string) => {
                this.filterSetting(res);
            });
    }

    public ngOnInit() {
        this.loadSettingDataSource = new MatTableDataSource(this.loadSettingData);
        this.getAllSettings();
    }
    public getAllSettings() {
        this.isLoading = true;
        const recentExportSettingsSubscribe = this.telemetryService.getAllLoadSetting().subscribe(
            (result) => {
                if (result) {
                    this.allSettings = result;
                    this.isLoading = false;
                    this.uiUtilsService.safeChangeDetection(this.cdr);
                    this.generateSettingTable(this.allSettings);
                }
            },
            (error) => {
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
        this.subscriptions.push(recentExportSettingsSubscribe);
    }

    public filterSetting(searchString: string) {
        searchString = searchString.trim();
        this.generateSettingTable(
            this.allSettings.filter((item) => item.name.toLowerCase().includes(searchString.toLowerCase())),
        );
    }
    public generateSettingTable(tableData) {
        this.loadSettingData = [];
        if (tableData) {
            this.noSetting = true;
            this.uiUtilsService.safeChangeDetection(this.cdr);
            tableData.forEach((item, index) => {
                const data: ExportSetting = {
                    name: item.name,
                    locationGroupId: item.locationGroupId || 0,
                    lids: item.lids || [],
                    eids: item.eids || [],
                    includeMissingTimestamps: item.includeMissingTimestamps,
                    missingTimestampValue: item.missingTimestampValue,
                    interval: item.interval,
                    entityPerColumn: item.entityPerColumn,
                    singleFile: item.singleFile,
                    quickSpan: item.quickSpan,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    exportType: item.exportType,
                    excelStyle: item.excelStyle,
                    useRounding: item.useRounding
                };
                this.loadSettingData.push(data);
                this.noSetting = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            });
        } else {
            this.noSetting = true;
            this.uiUtilsService.safeChangeDetection(this.cdr);
        }
        this.loadSettingDataSource = new MatTableDataSource(this.loadSettingData);
        this.uiUtilsService.safeChangeDetection(this.cdr);
    }

    public deleteExportSetting(name: string) {
        this.isLoading = true;
        const recentExportSettingsSubscribe = this.telemetryService.deleteExportSetting(name).subscribe(
            (result) => {
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
                this.getAllSettings();
                this.telemetryService.settingChanged.next('yes');
            },
            (error) => {
                this.isLoading = false;
                this.uiUtilsService.safeChangeDetection(this.cdr);
            },
        );
        this.subscriptions.push(recentExportSettingsSubscribe);
    }

    public openSetting(selectedSettingData) {
        this.telemetryService.openExistingExport = true;
        this.close();
        this.dialog
            .open(AdsPrismVaultLocationExportComponent, {
                disableClose: true,
                data: selectedSettingData,
            })
            .afterClosed()
            .subscribe((result) => {
                // to do
            });
    }

    public close() {
        this.dialogRef.close({ success: false });
    }
}
export interface Element {
    name: string;
    locations: string;
    entities: string;
    savedOn: string;
}
export interface SettingElement {
    name: string;
}
